import styled from "styled-components";
import { theme } from "../../../theme";

export const MultileQuestionContainer = styled.div`
  padding: 0 1.5rem 0 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.63rem;
`;

export const BranchName = styled.div`
  margin-left: 2rem;
  margin-top: 1rem;
  font-family: Primary Font-bold;
`;

export const CustomCheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CustomCheckboxInput = styled.input`
  display: none;
`;

export const CustomCheckbox = styled.span`
  width: 1rem;
  height: 1rem;
  border: 2px solid ${theme.dark.primary_text_color};
  background: linear-gradient(to bottom, #576371, #2c3239);
`;

export const CheckBox = styled.input`
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  background: linear-gradient(180deg, #2d3741 0%, #576371 100%),
    linear-gradient(0deg, #ffffff, #ffffff);

  appearance: none;
  border: 1px solid #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: background-color 0.3s ease;

  &:checked {
    background: linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%),
      linear-gradient(0deg, #5dd3b3, #5dd3b3);
    border: 1px solid #5dd3b3;
  }
`;

export const MultipleInputs = styled.input<{
  width: any;
  show: any;
  center: any;
  hasError?: boolean;
  color?: string;
  bgColor?: string;
  borderColor?: string;
}>`
  width: ${(props) => (props.width ? props.width : "")};
  height: 2.8125rem;
  border-radius: 0.625rem;
  border: 1px solid
    ${(props) => (props.show === "true" ? props.bgColor : props.borderColor)};
  border-color: ${(props) => (props.hasError ? "#D85D66" : "")};
  background: transparent;
  /* &:dir(ltr) {
    padding-left: ${(props) =>
      props.center === "yes" ? "1.88rem" : "1.25rem"};
  }
  &:dir(rtl) {
    padding-right: ${(props) =>
      props.center === "yes" ? "1.88rem" : "1.25rem"};
  } */
  padding: 0 ${(props) => props.center === "yes" ? "1.88rem" : "1.25rem"};
  font-family: "Primary Font-Light";
  color: ${(props) => props.color};
  background: ${(props) => (props.show === "true" ? props.bgColor : "")};
  opacity: 0.9;
  &&:focus {
    outline: none;
    color: ${(props) => props.color};
  }
  &::placeholder {
    opacity: 0.5;
  }
`;
