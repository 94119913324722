import styled from "styled-components";
import { theme } from "../../theme";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const Overlay = styled.div<{ isvisible: string }>`
  display: ${(props) => (props.isvisible ? "block" : "none")};
  opacity: ${(props) => (props.isvisible ? "1" : "0")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 300vh;
  transition: opacity 500ms;
  overflow: auto;
  z-index: 10;
`;
interface BackgroundOverlayProps {
  bgoverlaycolor: string;
  contenttype: string;
}
export const BackgroundOverlay = styled.div<BackgroundOverlayProps>`
  background: ${({ bgoverlaycolor, contenttype }) =>
    contenttype === "Assignment" ||
    contenttype === "Quiz" ||
    contenttype === "Survey" ||
    contenttype === "Forum"
      ? "none"
      : bgoverlaycolor};
  position: relative;
`;
export const BackgroundSecondaryOverlay = styled.div<BackgroundOverlayProps>`
  background: ${({ bgoverlaycolor, contenttype }) =>
    contenttype === "Assignment" ||
    contenttype === "Quiz" ||
    contenttype === "Survey" ||
    contenttype === "Forum"
      ? bgoverlaycolor
      : "none"};
  position: absolute;
  top: 0;
  left: 0;
  width: 120vw;
  min-height: 300vh;

  /* z-index: 10; */
`;

export const PopupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  color: white;
  min-height: 100vh;
  overflow: auto;
`;

export const PopupContentContainer = styled.div<{ backgroundcolor?: string }>`
  background-color: ${(props) => props.backgroundcolor || "#384450"};
  width: 60.184rem;
  &:dir(ltr) {
  padding: 1.69rem 0 1.69rem 1.87rem;
  border-radius: 0rem 0.625rem 0.625rem 0rem;
  }
  &:dir(rtl) {
    border-radius: 0.625rem 0rem 0rem 0.625rem;
    padding: 1.69rem 1.87rem 1.69rem 0rem;
  }
  position: relative;

  &:dir(ltr) {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -0.1875rem;
    width: 0.375rem;
    background: #5dd3b3;
    border-radius: 1.25rem;
  }
}
  &:dir(rtl) {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -0.1875rem;
    width: 0.375rem;
    background: #5dd3b3;
    border-radius: 1.25rem;
  }
}

`;
export const CloseDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 2.14rem 1.25rem 0;
  &:dir(rtl) {
    margin: 0 0 1.25rem 2.14rem;
    }
  & > :hover {
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
`;
export const TitleAndIcon = styled.div<StyleThemeInterface>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  color: ${props => props.themetextcolor};
`;
