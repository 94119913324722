import styled from "styled-components";
import { breakpoints } from "../../theme";
interface CourseMoreDetailsWrapperProps {
  isopen: string;
  bgcolor: string;
  boxshadow: string;
}

export const CourseMoreDetailsWrapper = styled.div<CourseMoreDetailsWrapperProps>`
  padding: 1.15rem 0.8rem 1.15rem 1.15rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
  background: ${({ bgcolor }) => bgcolor};
  box-shadow: ${({ boxshadow }) => boxshadow};
  height: fit-content;
  min-height: 4.3125rem;
  /* row-gap: 0.6rem; */
  @media (min-width: ${breakpoints.tablets}) {
    row-gap: 0.6rem;
  }
  @media (max-width: ${breakpoints.medium}) {
    grid-column: 3/6;
  }
  @media (max-width: ${breakpoints.tablets}) {
    grid-column: 1/6;
  }
  & .open {
    max-height: 5000px;
    opacity: 1;
    transform: translateY(0);
    transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
  }

  &.closed {
    max-height: 0;
    opacity: 0;
    transform: translateY(-20px);
    transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
  }
`;

export const TitleWithArrowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${breakpoints.tablets}) {
    cursor: pointer !important;
    row-gap: 1.32rem;
    grid-column: 1/6;
    margin-top: 0.3rem;
  }
`;

export const ArrowWrapper = styled.div`
  display: none;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in-out;
  }
  @media (max-width: ${breakpoints.tablets}) {
    display: block;
  }
`;

export const DividLine = styled.div`
  display: none;
  border-style: solid;
  border-width: 1px;
  border-color: #576474;
  width: 100%;
  margin-top: 1.25rem;
  margin-bottom: -0.07rem;
  @media (max-width: ${breakpoints.tablets}) {
    display: block;
  }
`;
export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpoints.tablets}) {
    row-gap: 0.4rem;
  }
  & .open {
    max-height: 5000px;
    opacity: 1;
    transform: translateY(0);
    transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
  }

  &.closed {
    max-height: 0;
    opacity: 0;
    transform: translateY(-20px);
    transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
  }
`;
export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  @media (min-width: ${breakpoints.tablets}) {
    margin-top: 0.3rem;
  }
`;
export const DisplayCoursTags = styled.div`
  display: none;
  @media (max-width: ${breakpoints.tablets}) {
    display: block;
    margin-top: 0.8rem;
  }
`;

export const ClassContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
