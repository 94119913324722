// export const ActivityNamesOptions = [
//   { name: "GO LEFT", graded: true },
//   { name: "GO RIGHT", graded: false },
//   { name: "Go Up", graded: true },
//   { name: "Go Down", graded: false },
// ];
export const ActivityNamesOptions = [
  {name: "GO LEFT", value: "GO LEFT", graded: true},
  {name: "GO RIGHT", value: "GO RIGHT", graded: false},
  { name: "Go Up", value: "GO Up", graded: true },
  { name: "Go Down", value: "GO Down", graded: false },
]

export const RoleGradedOptions = [
  {name: "Grade Scale", value: "Grade Scale",  },
  { name: "Submitted", value: "Submitted" },
];

export const ConditionPoorValues = [
  { name: "0 - 44 %", value: "0 - 44 %" },
  { name: "0 - 49 %", value: "0 - 49 %"},
  { name: "0 - 59 %", value: "0 - 59 %" },
];

export const ConditionIntermediateValues = [
  { name: "45 - 70 %",value: "45 - 70 %" },
  { name: "50 - 75 %",value: "50 - 75 %" },
  { name: "60 - 79 %",value: "60 - 79 %" },
];

export const ConditionAdvancedValues = [
  { name: "71- 100 %",value: "71- 100 %" },
  { name: "76 - 100 %",value: "76 - 100 %" },
  { name: "80 - 100 %",value: "80 - 100 %" },
];
