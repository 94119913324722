import React, { useEffect, useState } from "react";
import { QuizQuestionsAnalyticsInterfaceV2 } from "../../Tables/QuizQuestionsAnalytics/QuizQuestionsAnalyticsinterfaceV2";
import { AnalyticsSurveyPiesTwoValuesContainer } from "../../AnalyticsSurveyPies/AnalyticsSurveyPiesTwoValues/AnalyticsSurveyPiesTwoValues.style";
import PieWithText from "../../Charts/PieWithText/PieWithText";
import PieText from "../../Charts/PieText/PieText";
interface AnalyticQuizEssayPieInterface {
  data: QuizQuestionsAnalyticsInterfaceV2;
}
const AnalyticQuizEssayPie = ({ data }: AnalyticQuizEssayPieInterface) => {
  const [PieChartData, setPieChartData] = useState([
    {
      text: "Answered",
      val: 0,
      bgColor: "#5DD3B3",
    },
    {
      text: "Not Answered",
      val: 0,
      bgColor: "#8A2BE2",
    },
  ]);
  useEffect(() => {
    const AnsweredCount = data.students.filter(
      (item) => item.Answered !== undefined && item.Answered.trim() !== ""
    ).length;

    const NotAnsweredCount = data.students.filter(
      (item) => item.Answered === undefined || item.Answered.trim() === ""
    ).length;

    setPieChartData([
      {
        text: "Answered",
        val: AnsweredCount,
        bgColor: "#5DD3B3",
      },

      {
        text: "Not Answered",
        val: NotAnsweredCount,
        bgColor: "#8A2BE2",
      },
    ]);
  }, []);
  return (
    <AnalyticsSurveyPiesTwoValuesContainer>
      {/* PieWithText Component */}
      <PieWithText
        data={PieChartData}
        value={"val"}
        getColor={(entry) => entry.bgColor as string}
        Width="65%"
      />

      {/* PieText Component */}
      <PieText
        LoopName={PieChartData}
        ShowMiniInfo={false}
        title={data.QuestionText}
        description={data.QuestionDescription}
      />
    </AnalyticsSurveyPiesTwoValuesContainer>
  );
};

export default AnalyticQuizEssayPie;
