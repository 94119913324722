import styled from "styled-components";
import { StyledInputInterface } from "./StyledInputInterface";

export const InputContainer = styled.div`
  position: relative;

  &::after {
    content: attr(data-placeholder);
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 8px; /* Adjust padding as needed */
    color: gray; /* Placeholder text color */
    pointer-events: none; /* Ensure the pseudo-element does not interfere with input interaction */
  }
`;

export const Input = styled.input<StyledInputInterface>`
  width: ${(props) => props.width || "27.8125rem"};
  color: ${(props) => props.color || props.themetextcolor};
  border-radius: ${(props) => props.borderRadius || "0.625rem"};
  height: ${(props) => props.height || "2.8125rem"};
  background-color: transparent;
  padding: ${(props) => props.padding || "0.94rem 1.25rem"};
  border: 1px solid ${(props) => props.inputbordercolor};
  font-family: ${(props) => props.theme.font.primaryRegular};
  font-size: ${(props) => props.fontSize || "0.87rem"};
  text-align: ${(props) => props.textAlign};

  &::placeholder {
    color: ${(props) => props.themetextcolor};
    opacity: 0.5;
    font-size: 0.875rem;
    font-family: ${(props) => props.theme.font.primaryRegular};
  }
  &:focus {
    outline: none;
    caret-color: ${(props) => props.themetextcolor};
  }
`;
