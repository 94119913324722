import React, { FC } from "react";
import { ReactComponent as PinnedIcon } from "../../../assets/icons/pinned_2.svg";
import { ReactComponent as VideoIcon } from "../../../assets/icons/video_2.svg";
import { ReactComponent as VoiceIcon } from "../../../assets/icons/sound_3.svg";
import { Typography, LightTypography } from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import {
  CommentContainer,
  Comment,
  CommentTextAreaContainer,
  CommetnTextArea,
  IconsContainer,
} from "./AssignmentCommentSection.style";
import { AssignmentCommentSectionProps } from "./AssignmentCommentSectionInterface";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
const AssignmentCommentSection: FC<AssignmentCommentSectionProps> = ({
  showRating,
  onCommentChange,
  comment,
  quiz,
  forum,
}) => {
  const { t } = useTranslation();

  const handleCommentInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newComment = event.target.value;
    onCommentChange(newComment);
  };
  const { SecondaryWhiteTextColor, inputBorderColor } =
    useRecoilValue(ColorSelector);

  return (
    <CommentContainer showrating={showRating.toString()}>
      {quiz ? (
        <Typography
          text={t("quiz.QUIZ COMMENT")}
          fontSize="1rem"
          color={`${theme.dark.main_color}`}
        />
      ) : forum ? (
        <Typography
          text={t("grader.POST COMMENT")}
          fontSize="1rem"
          color={`${theme.dark.main_color}`}
        />
      ) : (
        <Typography
          text={t("assignment.ASSIGNMENT COMMENT")}
          fontSize="1rem"
          color={`${theme.dark.main_color}`}
        />
      )}

      <Comment style={{ marginTop: "0.8rem" }}>
        <div>
          <LightTypography
            text={t("assignment.Add a comment")}
            fontSize="0.875rem"
          />
          <IconsContainer>
            {/* <PinnedIcon />
            <VideoIcon />
            <VoiceIcon /> */}
          </IconsContainer>
        </div>
        <CommentTextAreaContainer>
          <CommetnTextArea
            placeholder={t("online session.Comment")}
            onChange={handleCommentInputChange}
            value={comment}
            color={SecondaryWhiteTextColor}
            bordercolor={inputBorderColor}
          />
        </CommentTextAreaContainer>
      </Comment>
      {/* <LightTypography text="Kindly click below to submit the grade" /> */}
    </CommentContainer>
  );
};

export default AssignmentCommentSection;
