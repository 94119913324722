import styled from "styled-components";
import { StyleProps } from "../../../App.style";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";
export const NormalProfileImgAPP = styled.img<StyleProps>`
  border-radius: 50%;
  object-fit: cover;
  width: ${({ ImgSize }) => ImgSize || "3.125rem"};
  height: ${({ ImgSize }) => ImgSize || "3.125rem"};
`;
export const NotificationsContainer = styled.div<StyleThemeInterface>`
  & > :nth-child(odd):hover {
    background: linear-gradient(50deg, #5dd3b3 3.83%, #2f6a5a 95.8%) !important;

    /* transition: all 0.2s ease-in; */
  }
  & > :nth-child(even):hover {
    background: linear-gradient(50deg, #5dd3b3 3.83%, #2f6a5a 95.8%) !important;

    /* transition: all 0.2s ease-in; */
  }

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)"
        : "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)"};
    border-radius: 0.625rem;
    box-shadow: ${(props) =>
      props.theme === "light"
        ? "none"
        : "-10px 0px 10px 0px rgba(0, 0, 0, 0.25)"};
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => (props.theme === "light" ? "#F7F7F8" : "#2d3741")};

    border-radius: 0.625rem;
  }
`;

export const NotificationsMainContainer = styled.div<{ bghelp?: string }>`
  padding: 1.3rem 0 1.99rem 0;
  &:dir(ltr) {
    border-right: 1px solid ${(props) => props.bghelp};
  }
  &:dir(rtl) {
    border-left: 1px solid ${(props) => props.bghelp};
  }
`;
export const TypographyWrapper = styled.div`
  &:dir(ltr) {
    padding-left: 2.56rem;
  }
  &:dir(rtl) {
    padding-right: 2.56rem;
  }
`;

export const SecondTypographyWrapper = styled.div`
  &:dir(ltr) {
    padding-left: 1.87rem;
  }
  &:dir(rtl) {
    padding-right: 1.87rem;
  }
`;

export const TypographyClockWrapper = styled.div`
  &:dir(ltr) {
    margin-right: 1.99rem;
  }
  &:dir(rtl) {
    margin-left: 1.99rem;
  }
`;

export const NotifyChildRender = styled.div<StyleThemeInterface>`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  &:dir(ltr) {
    padding: 0.22rem 1.99rem 0.22rem 1.87rem;
  }
  &:dir(rtl) {
    padding: 0.22rem 1.87rem 0.22rem 1.99rem;
  }
  &:nth-child(odd) {
    background: ${(props) => props.nth_child_bgcolor};
  }
`;

export const NotifyHolderDiv = styled.div<StyleThemeInterface>`
  width: 100%;
  height: 0.3rem;
  &:dir(ltr) {
    padding: 1.7rem 0 1.7rem 2.56rem !important;
  }
  &:dir(rtl) {
    padding: 1.7rem 2.56rem 1.7rem 0 !important;
  }

  display: flex;
  align-items: center;
  /* background: red !important; */
  cursor: pointer;
  /* & > :hover {
    color: white !important;

    transition: all 0.2s ease-in;
  } */
`;
