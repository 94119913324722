// Find the feedback of the instructor for the student's answer (short answer, Numerical and tru or false Questions)
 export const findAnswerFeedbackV1 = (studentResponse:any[],questionId:any,questionType:string)=>{
    if(studentResponse && studentResponse.length > 0){
      const studentAnswer = studentResponse.find((answer:any) => Number(answer.questionId) === Number(questionId) && answer.type === questionType);
      if(studentAnswer && studentAnswer.answers && studentAnswer.answers.length > 0 && studentAnswer.answers[0].feedback && studentAnswer.answers[0].feedback !== "pending"){
        return studentAnswer.answers[0].feedback
      }else {
        return "";
      }
    }else {
      return "";
    }
  }
// End Of Function

// Find the feedback on the student's answer (MCQ and Matching Questions)
  export const findAnswerFeedbackV2 = (studentResponse:any[],questionId:any,questionType:string)=>{
    if(studentResponse && studentResponse.length > 0){
      const studentAnswer = studentResponse.find((answer:any) => Number(answer.questionId) === Number(questionId) && answer.type === questionType);
      // Check if there are both correct and incorrect answers
      if(studentAnswer && studentAnswer.answers.length > 0 && studentAnswer.answers[0].feedback && studentAnswer.answers[0].feedback !== "pending") {
          const hasCorrectAnswer = studentAnswer.answers.some((answer: any) => answer.isCorrect);
          const hasIncorrectAnswer = studentAnswer.answers.some((answer: any) => !answer.isCorrect);
  
          if (hasCorrectAnswer && hasIncorrectAnswer) {
              return "Your answer is partially correct.";
          } else {
              // Return the feedback from the data
              const feedback = studentAnswer.answers[0].feedback;
              return feedback || ""; 
          }
      }else {
        return '';
      }   
  } else {
      return "";
  }
  }
// End of Function

// Find the comment of the instructor on the student's answer
export const findStudentComment = (studentResponse:any[],questionId: any, questionType: string) => {
  if (studentResponse && studentResponse.length > 0) {
    const studentAnswer = studentResponse.find((answer: any) => Number(answer.questionId) === Number(questionId) && answer.type === questionType);
    if (studentAnswer && studentAnswer.comment && studentAnswer.comment !== undefined && studentAnswer.comment !== null && studentAnswer.comment !== "pending") {
      return studentAnswer.comment
    } else {
      return "";
    }
  } else {
    return "";
  }
};
// End Of Function