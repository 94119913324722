import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";



export const  StudentSurveyLayout = styled.div`
width:100%;
display: flex;
flex-direction:column;
gap:1.25rem;
/* padding:1rem; */
`;

export const  StudentSurveyText = styled.div`
width:100%;
display: flex;
flex-direction:column;
gap:0.63rem;
`;

export const StudentSurveyBox = styled.div<{bgColor?:string}>`
background: var(--background-card, );
background:${props =>props.bgColor || "linear-gradient(180deg, #415160 0%, #293440 100%)"};
/* opacity: 0.5; */
display: flex;
flex-direction: column;
min-height:4rem;
padding: 1.55rem 1.25rem;
gap:1rem;
width:100%;
border-radius:1rem;
`;

export const StudentSurveyPagination = styled.div`
  width: 100%;
  margin-left: -2rem;
  display: flex;
  justify-content: flex-end;
  align-items:flex-end;
  flex-direction:column;
  margin-top:1rem;
  gap:0.5rem;
`;

// export const  StudentSurveyQuestionPart = styled.div`
// width:100%;
// display: flex;
// flex-direction:column;
// /* gap:0.63rem; */
// `;

export const  StudentSurveyInstructor= styled.div`
width:100%;
display: flex;
flex-direction:column;
gap:0.50rem;
`;
export const  StudentSurveyInstructorReply = styled.div<StyleThemeInterface>`
height: 3.3125rem;
flex-shrink: 0;
width:100%;
border-radius: 0.625rem;
background: ${props => props.theme === "light" ? "#E4E6E8" : "rgba(255, 255, 255, 0.03)"};
color: ${props => props.color};
padding:1.19rem 1.25rem;
`;

