import React, { useEffect, useState } from "react";
import {
  SurveyColScale,
  SurveyLineStyle,
  SurveyScaleAction,
  SurveyScaleActionPlace,
  SurveyScaleDote,
  SurveyScaleActiveDote,
  SurveyScaleLableAction,
} from "./SurveyLikertScale.style";
import { MediumTypography, TypographyRegularDescription } from "../fonts/Fonts";
import { PointsProData } from "./SurveyLikertScaleData";
import { SvgChecked } from "../Icons";
import {
  StudentSurveyInstructor,
  StudentSurveyInstructorReply,
} from "../../components/SurveyStudentView/main/StudentSurvey.style";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import { findStudentAnswer } from "../../components/SurveyStudentView/main/surveyStudentCompoUtils";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../utils/StringManipulation";
import { useParams } from "react-router-dom";

interface LikertComponent {
  PartTitle?: string;
  text?: string;
  onChange: (value: string) => void;
  showText?: boolean;
  disabled?: boolean;
  studentAnswer?: any;
  comment: string;
  question?:any;
  studentAnswerArray?:any
  id?:number
  setLineValues?:any
  setSurveyQuestionId?:any
}

export const SurveyLikertScale = ({
  PartTitle,
  text,
  showText,
  onChange,
  disabled,
  studentAnswer,
  comment,
  question,
  id,
  setLineValues,
  studentAnswerArray,
  setSurveyQuestionId
}: LikertComponent): JSX.Element => {
  const { t } = useTranslation();
  const [activeText, setActiveText] = useState<string | null>(
    studentAnswer || null
  );
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const params = useParams();
  const classId = params.classId;

  const handleScaleItemClick = (text: string) => {
    // Check if disabled or if studentAnswer is present
    if (!disabled) {
      setActiveText(text);
      onChange(text);
    }
  };

  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };

  useEffect(() => {
    if (studentAnswer || studentAnswer !== null) {
      setActiveText(studentAnswer);
    } else {
      setActiveText(null);
    }
  }, [studentAnswer]);

  useEffect(()=>{
    const key = `${question.title}`;
    const idKey = `${key}_ID`;
    setSurveyQuestionId((prevId:any) => ({
      ...prevId,
      [idKey]: question.SurveyQuestionsId || 0,
    }));
    if(studentAnswerArray && studentAnswerArray.length === 0 ){
      // Fetch and set the timer from local storage if it exists
      const savedStudentAnswer = JSON.parse(localStorage.getItem('localStudentSurveyResponse') || '[]');
      const currentQuizAnswer = savedStudentAnswer.find((item: any) =>
        item.classSourceId === classId &&
        item.subId === subIdSplitted &&
        item.refId === id
      );

      if (currentQuizAnswer &&
        question) {
      if(currentQuizAnswer && currentQuizAnswer.localStudentResponse && currentQuizAnswer.localStudentResponse[key]){
        setLineValues((prevLineValues:any) => ({
          ...prevLineValues,
          [key]: currentQuizAnswer.localStudentResponse[key],
        }));
        } else{
          setLineValues((prevLineValues:any) => ({
            ...prevLineValues,
            [key]: '',
          }));
        }
      }
      }
  },[question])
  const { bghelp, themeTextColor, theme } = useRecoilValue(ColorSelector);
  return (
    <div>
      <SurveyColScale>
        {showText && (
          <TypographyRegularDescription
            text={PartTitle || ""}
            color="#5DD3B3"
            fontSize="0.875rem"
          />
        )}
        {showText && <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              decodeHtml(text || '')
                            ),
                          }}
                          style={{
                            fontSize: "1rem"
                          }}
                        ></div>
        }

        <SurveyScaleAction>
          <SurveyLineStyle bgColor={bghelp} />
          <SurveyScaleLableAction>
            {PointsProData.map((item) => (
              <SurveyScaleActionPlace
                key={item.id}
                onClick={() => handleScaleItemClick(item.name)}
                aria-disabled={disabled || !!studentAnswer} // Disable if disabled or if studentAnswer is present
              >
                {activeText === item.name ? ( // Apply active style based on activeText only
                  <SurveyScaleActiveDote>
                    <SvgChecked />
                  </SurveyScaleActiveDote>
                ) : (
                  <SurveyScaleDote bgColor={bghelp} />
                )}
                <TypographyRegularDescription
                  text={t(item.title)}
                  fontSize="0.75rem"
                  lineHeight="1rem"
                />
              </SurveyScaleActionPlace>
            ))}
          </SurveyScaleLableAction>
        </SurveyScaleAction>
      </SurveyColScale>
      {comment && comment.trim() !== "No Comment" && (
        <StudentSurveyInstructor>
          <TypographyRegularDescription
            text={t("assignment.Instructor Comment")}
            fontSize="0.875rem"
          />
          <StudentSurveyInstructorReply color={themeTextColor} theme={theme}>
            <TypographyRegularDescription
              text={comment}
              fontSize="0.75rem"
            />
          </StudentSurveyInstructorReply>
        </StudentSurveyInstructor>
      )}
    </div>
  );
};
