import styled from "styled-components";
import { breakpoints } from "../../theme";

interface GroupCardRectangleProps {
  bordercolor: string;
  index: number;
  membersnumber: number;
  boxshadow?: string;
  boxShadowlightDark?: string;
  boxshadowGroup?: string;
  isOver: boolean;
  bgColor: string;
  theme?: string;
}

interface GroupCardHeaderRowProps {
  index: number;
}

export const GroupCardRectangle = styled.div<GroupCardRectangleProps>`
  position: relative;
  width: 27.5rem;
  height: 14.375rem;
  border-radius: 0.625rem;
  border: ${({ index }) => (index === 0 ? "none" : "2px solid")};
  border-color: ${({ bordercolor }) => bordercolor};
  background: ${(props) => props.bgColor};
  box-shadow: ${({ index, boxshadow, boxshadowGroup, boxShadowlightDark }) =>
    index === 0 ? boxShadowlightDark : boxshadowGroup};
  transition: box-shadow 0.2s ease-in;
  display: flex;
  flex-direction: column;
  padding: ${(props) =>
    props.index === 0 && props.membersnumber > 0
      ? "0.85rem"
      : props.index === 0 && props.membersnumber === 0
      ? "0"
      : "1.25rem"};
  @media (max-width: ${breakpoints.tablets}) {
    padding: ${(props) =>
      props.membersnumber === 0 && props.index === 0
        ? "0 0 0.85rem 0"
        : props.membersnumber > 0 && props.index === 0
        ? "0.85rem 1.56rem 0 1.56rem"
        : "1.25rem"};
  }
`;

export const GroupCardHeaderRow = styled.div<GroupCardHeaderRowProps>`
  display: ${(props) => (props.index === 0 ? "none" : "flex")};
  width: 100%;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.5rem;
  & > :last-child {
    cursor: pointer;
    margin-left: -0.25rem;
  }
  & > :last-child:hover {
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
`;
interface DivideLineProps {
  lineIconColor: string;
}
export const DivideLine = styled.div<DivideLineProps>`
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.lineIconColor};
  width: 100%;
`;

export const VDotsWrapper = styled.div`
  width: 1rem;
  height: 0.99813rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyGroupMembersTextWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 1.8rem;
`;

export const GroupMembersWrapper = styled.div<GroupCardRectangleProps>`
  margin: auto;
  width: ${(props) => (props.index === 0 ? "95%" : "100%")};
  padding: ${(props) =>
    props.index === 0 && props.membersnumber === 0
      ? "0 0.62rem"
      : props.index === 0 && props.membersnumber > 0
      ? "1.5rem "
      : "0 0.3rem"};
  height: ${(props) =>
    props.membersnumber > 0 && props.index === 0
      ? "95%"
      : props.membersnumber > 0 && props.index > 0
      ? "100%"
      : props.membersnumber === 0 && props.index === 0
      ? "98%"
      : "100%"};
  display: flex;
  justify-content: ${(props) => (props.index === 0 ? "center" : "flex-start")};
  align-items: ${(props) =>
    props.membersnumber > 0 && props.index === 0
      ? "flex-start"
      : props.membersnumber === 0 && props.index === 0
      ? "center"
      : "stretch"};
  align-content: ${(props) =>
    props.membersnumber > 0 && props.index === 0
      ? "flex-start"
      : props.membersnumber === 0 && props.index === 0
      ? "center"
      : "stretch"};
  margin-top: ${(props) =>
    props.membersnumber === 0 && props.index === 0 ? "0" : "1.25rem"};
  flex-wrap: wrap;
  column-gap: ${(props) =>
    props.membersnumber > 0 && props.index === 0 ? "1.56rem;" : "0.8rem"};
  row-gap: ${(props) =>
    props.membersnumber > 0 && props.index === 0 ? "1.56rem;" : "0.5rem"};
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)"
        : "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)"};

    border-radius: 0.625rem;
    box-shadow: ${(props) =>
      props.theme === "light"
        ? "none"
        : "-10px 0px 10px 0px rgba(0, 0, 0, 0.25)"};
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => (props.theme === "light" ? "#fff" : "#2d3741")};
    border-radius: 0.625rem;
  }

  @media (max-width: ${breakpoints.tablets}) {
    justify-content: ${({ index, membersnumber }) =>
      index === 0 && membersnumber > 0
        ? "flex-start"
        : index === 0 && membersnumber === 0
        ? "space-between"
        : "center"};
    align-items: ${({ index, membersnumber }) =>
      index === 0 ? "center" : "flex-start"};
    height: 90%;
    width: ${(props) =>
      props.membersnumber > 0 && props.index === 0
        ? "95%"
        : props.membersnumber === 0 && props.index === 0
        ? "100% !important"
        : "100%"};
    max-width: 100% !important;
    flex-wrap: ${({ index }) => (index === 0 ? "nowrap" : "wrap")};
    overflow-y: ${({ index }) => (index === 0 ? "hidden" : "auto")};
    overflow-x: ${({ index }) => (index === 0 ? "auto" : "hidden")};
    padding: ${({ index, membersnumber }) =>
      index === 0
        ? "1.88rem 1.56rem"
        : index === 0 && membersnumber === 0
        ? "0.5rem 0 0 0"
        : "0"};
    margin-top: ${(props) =>
      props.membersnumber > 0 && props.index === 0
        ? "0.95rem"
        : props.membersnumber === 0 && props.index === 0
        ? "0.95rem"
        : "1.25rem"};
    &::-webkit-scrollbar {
      height: 0.45rem;
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) =>
        props.theme === "light"
          ? "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)"
          : "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)"};

      border-radius: 0.625rem;
      box-shadow: ${(props) =>
        props.theme === "light"
          ? "none"
          : "-10px 0px 10px 0px rgba(0, 0, 0, 0.25)"};
    }

    &::-webkit-scrollbar-track {
      background: ${(props) => (props.theme === "light" ? "#fff" : "#2d3741")};
      border-radius: 0.625rem;
    }
  }
`;

export const GroupsMembersEmpty = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakpoints.tablets}) {
    flex-direction: row;
    padding-bottom: 0.85rem;
    height: 100%;
  }
`;

export const GroupsMembersEmptyTextWrapper = styled.div`
  text-align: center;
`;
