import styled from "styled-components";

export const TeacherLandingPageLayout = styled.div`
display: flex;
flex-direction:column;
margin-bottom:3rem;
gap:1.25rem;
`;

export const StyledHeader = styled.div<{ isExpanded?: boolean, isChild?: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
  background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
  height: ${({ isExpanded }) => (isExpanded ? "auto" : "3.875rem")};
  padding: ${({ isExpanded }) => (isExpanded ? "1.25rem" : "1.25rem 1.25rem 1.25rem 1.25rem")};
  margin-top: ${({ isChild }) => (isChild ? "0.62rem" : "0")};
  overflow: hidden;
  cursor: pointer;
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const FlexDiv = styled.div<{gap?: string}>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : "1.88rem")};
`;

export const ModuleExpandedDiv = styled.div<{isExpanded?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  border-top: ${({ isExpanded }) => (isExpanded ? "1px solid #576371" : "")};
  margin-top: 1.25rem;
  padding-top: 1.25rem;
`;
