import React, { FC, useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { OptionCard } from "../NotesCard/main/NotesCard.style";
import ButtonIconAndText from "../../elements/ButtonIconAndText/ButtonIconAndText";
import PopUpDelete from "../../elements/DeletePopUp/DeletePopUp";
import {
  AnnouncementsCardHolder,
  DateHolder,
  Description,
  NoteAddButtonClick,
  ProfileDateHolder,
  Title,
} from "./AnnouncementsCard.style";
import {
  LightTypography,
  PrimaryFontMediumText,
  Typography,
} from "../../elements/fonts/Fonts";
import ProfileNameEmail from "../../elements/profileNameEmail/ProfileNameEmail";
import { AnnouncementsCardInterface } from "./announcementsCardInterface";
import { SVGMenue, SvgDeleteIcon, SvgEditIcon, SvgCorrectTick } from "../../elements/Icons";
import { del } from "../../utils/AxiosRequests";
import { formatDate } from "../../utils/FormatDate";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import SelectedOptions from "../../elements/SelectedOptions/SelectedOptions";
import axios from "axios";

const AnnouncementsCard: FC<AnnouncementsCardInterface> = ({
  title,
  description,
  date,
  iconPath,
  iconBg,
  top,
  left,
  border,
  width,
  height,
  widthImgHolder,
  heightImgHolder,
  fullName,
  email,
  id,
  role,
  classId,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  fetchDataFromLocalStorage,
  announecmenttData,
  userId,
  isRead
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef: React.LegacyRef<HTMLDivElement> | undefined = useRef(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const loc = useLocation();
  const navigate = useNavigate();
  const tenantName = localStorage.getItem("tenant");
  const { t } = useTranslation();

  // Function to delete Announment
  const handleDeleteAnnouncement = async (announcementId: string | number) => {

    if (setCurrentPage) {
      if (
        announecmenttData.length <=
        (currentPage ?? 1 - 1) * (itemsPerPage ?? 1)
      ) {
        const newPage = (currentPage ?? 1) - 1;
        setCurrentPage(newPage > 0 ? newPage : 1);
      }
    }
    try {
      await axios.delete(`https://yhntwosoqe.execute-api.eu-west-1.amazonaws.com/dev/announcement/delete/${announcementId}/${tenantName}`);

    } catch (error) {
      console.log(error)
    }
    setShowDeletePopup(false);
    fetchDataFromLocalStorage();
  };

  // Toggle Dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOutsideClick = (e: { target: any }) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // Handle Navigate
  const handleNavigate = (id: string | number) => {
    const currentPath = loc.pathname;
    const newPath = currentPath.includes("/courseview/announcements")
      ? `/CourseViewLayout/${classId}/announcements/${id}`
      : `/announcements/${id}`;

    navigate(newPath);
  };
  const {
    backgroundColor,
    themeTextColor,
    bg_selectColor,
    ProfileGradient_BgColor_Both,
    boxShadowBanner,
  } = useRecoilValue(ColorSelector);

  return (
    <AnnouncementsCardHolder
      bgcolor={backgroundColor}
      boxshadow={boxShadowBanner}
    >
      {/* The Title Goes Here */}
      <Title>
        <Typography text={title} />

        {/* {loc.pathname === `/courseview/announcements/${classId}` ?
          role === "teacher" && ( // If the user is teacher show the Toggle option
            <SvgDeleteIcon style={{cursor:"pointer"}} onClick={() => setShowDeletePopup(true)} color="#D04B4B" />
          ): ""} */}
        {isRead ?
          // If the user is teacher show the Toggle option
          <SvgCorrectTick color="#5DD3B3" />
          : ''}
        {/* {role === "teacher" &&
          isDropdownOpen && ( // If the user is teacher show the delete and edit button
            <OptionCard ref={dropdownRef} bgColor={bg_selectColor}>
              <SelectedOptions
                FirstLabelClick={() =>
                  navigate(`/courseview/announcements/edit/${id}/${classId}`)
                }
                LastLabelClick={() => setShowDeletePopup(true)}
              />
            </OptionCard>
          )} */}
      </Title>
      {/* The Description Goes Here */}
      <Description onClick={() => handleNavigate(id)}>
        <LightTypography
          text={description}
          fontSize="0.875rem"
          lineHeight="1.375rem"
          color="rgba(255, 255, 255, 0.50)"
        />
      </Description>
      {/* The Profile of the user Goes Here */}
      <ProfileDateHolder onClick={() => handleNavigate(id)}>
        <ProfileNameEmail
          iconPath={iconPath}
          iconBg={iconBg}
          top={top}
          left={left}
          border={border}
          width={width}
          height={height}
          widthImgHolder={widthImgHolder}
          heightImgHolder={heightImgHolder}
          fullName={fullName}
          email={email}
          ImageBox_BgColor={"#707070"}
          userId={userId}
        />
        <DateHolder>
          <PrimaryFontMediumText
            text={formatDate(date, t)}
            fontSize="0.75rem"
          />
        </DateHolder>
      </ProfileDateHolder>
      {showDeletePopup && ( // Delete popup
        <PopUpDelete
          show={showDeletePopup}
          Subject={t(
            "course view.Are you sure you want to delete this announcement?"
          )}
          title={t("course view.Delete Announcement")}
          icon={<SvgDeleteIcon />}
          showline={false}
          onDelete={() => handleDeleteAnnouncement(id)}
          setShow={setShowDeletePopup}
        />
      )}
    </AnnouncementsCardHolder>
  );
};

export default AnnouncementsCard;
