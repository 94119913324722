import styled from 'styled-components'

export const QuesionContainer = styled.div`
display: flex;
align-items:end;
gap:0.5rem;
cursor: pointer;
`;

export const QuestionMenuContainer = styled.div`
display: flex;
align-items:center;
gap:1.19rem;
`;


export const PartContainer = styled.div`
display: flex;
width: auto;
align-items:center;
gap:0.62rem;
cursor: pointer;
`;

export const DropdownMenu = styled.div<{ isopen: string,bgColor?:string }>`
  position: absolute;
  background-color:${props => props.bgColor || "#1f2731" };
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  z-index: 1;
  display: ${(props) => (props.isopen === 'true' ? 'block' : 'none')};
`;
export const MenuContainer = styled.div`
  cursor: pointer;
`;
export const MenuItem = styled.div`
  cursor: pointer;
  display: flex;

  font-family: 'Primary Font-Bold';
  font-size:14px;
`;

export const MenuElementContainer = styled.div`
display: flex;
gap:0.62rem;
align-items:center;
padding: 10px 10px 10px 10px ;
&:first-child{
  border-radius:5px 5px 0 0 ;
  }
  &:last-child{
  border-radius:0 0 5px 5px  ;
  }
&:hover {
    background-color: #5dd3b3;
    color: #fff;
  }
`;

export const QuestionComposedQuestion = styled.div`
display: flex;
align-items:center;
justify-content:space-between;
`;
