import { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AnnouncementsCourseViewHolder,
  TitleWithIconsWrapper,
  IconsWrapper,
  AnnouncementsCardsHolder,
  FilterButtonsCont,
  FilterButton,
  FiltersContainer,
} from "./AnnouncementsCourseView.style";
import { PageHeader } from "../../../components/pageHeader/PageHeader";

import AnnouncementsCard from "../../../components/announcementsCard/AnnouncementsCard";
import IconWithText from "../../../elements/iconWithText/main/IconWithText";
import {
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import {
  SvgSortBy,
  SvgAddNew,
  SvgEmpty,
  SvgNoResult,
  SvgAddIcon,
  SvgPlus,
  SvgAdd,
} from "../../../elements/Icons";
// import Pagination from "../../../elements/reusablePagination/main/ReusablePagination";
import Pagination from "../../../elements/Pationation/Pagination";
import DropDownFilter from "../../../components/dropDownFilter/main/DropDownFilter";
import useClickOutside from "../../../hooks/useClickOutside";
import useToggle from "../../../hooks/useToggle";
import AnnouncementsCardsData from "../../../components/announcementsCards/announcementsCardsData";
import { CourseCardData } from "../../../components/courseCard/CourseCardData";
import Empty from "../../../components/courseCard/Empty";
import { ClassContext } from "../../dashboard/UnstructuredContext";
import { get } from "../../../utils/AxiosRequests";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../../utils/StringManipulation";
import axios from "axios";
import { useParentClass } from "../../../utils/getParentClass";
interface LocalAnnouncementData {
  id: string | number;
  title: string;
  content: any;
  iconPath: string;
  fullName: string;
  email: string;
  createdAt: string;
  path: string;
  userSourceId: string;
  classSourceId: string;
  isRead?: boolean;
  teacher: {
    sourceId: string;
    username: string;
    email: string;
    profilePicture: string;
  };
}

const AnnouncementsCourseView = (): JSX.Element => {
  const {
    role,
    getUnvewedAnnouncmentClassCount,
    AnnouncementsCurrentPage,
    setAnnouncementsCurrentPage,
  } = useContext(ClassContext);
  const { classId } = useParams();
  const [profilePictures, setProfilePictures] = useState<any>({});
  const [loading, setLoading] = useState<Boolean>(true);
  const imageGif = require("../../../assets/loadingGif.gif");
  const navigate = useNavigate();
  const { isOpen: isDropdownVisible, toggle: toggleDropdown } =
    useToggle(false);
  const dropdownRef = useClickOutside(isDropdownVisible, toggleDropdown);
  const [activeTab, setActiveTab] = useState(false);
  const itemsPerPage = 3;
  const [localData, setLocalData] = useState<LocalAnnouncementData[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const startIndex = (AnnouncementsCurrentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const loc = useLocation();
  const tenantName = localStorage.getItem("tenant");
  const { t } = useTranslation();
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const [sourceIdToFilter, setSourceIdToFilter] = useState<string>("");
  const [classNameToFilter, setClassNameToFilter] = useState<string>("");

  // get if the class is parent
  const { isParent, children } = useParentClass(classId);

  const handleFilterAnnouncByClass = (sourceId: string, className: string) => {
    setSourceIdToFilter((prevSourceId) => (prevSourceId === sourceId ? "" : sourceId));
    setClassNameToFilter((prevClassName) => (prevClassName === className ? "" : className));
  };
  
  const filtredDataAnnounc = sourceIdToFilter 
  ? localData.filter((item) => item.classSourceId === sourceIdToFilter)
  : localData;

const currentAnnouncementsCards =
  filtredDataAnnounc.length > 0 ? filtredDataAnnounc.slice(startIndex, endIndex) : [];

  // Fetch Annaouncment Data from the database
  const fetchAnnounecmenttData = async () => {
    try {
      const announcmentData = await axios.get(
        `https://yhntwosoqe.execute-api.eu-west-1.amazonaws.com/dev/announcement/class/${classId}/${tenantName}/${subIdSplitted}?provider=${provider}`
      );
      const sortedData = announcmentData.data.data.sort(
        (a: LocalAnnouncementData, b: LocalAnnouncementData) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      setLocalData(sortedData);
      setDataLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  //Call the fecthNotesData function when the component mount
  useEffect(() => {
    fetchAnnounecmenttData();
    if (classId) {
      getUnvewedAnnouncmentClassCount(classId);
    }
  }, []);

  useEffect(() => {
    // Fetch the profile picture of all the users
    if (localData && localData.length > 0) {
      const fetchProfilePictures = async () => {
        try {
          setLoading(true);
          const tenantName = localStorage.getItem("tenant");

          // Create an array of promises to fetch profile pictures for all students
          const promises = localData.map(async (announecment) => {
            const response = await get(
              `user/profilePicture/${tenantName}/${announecment.userSourceId}`
            );
            return {
              userId: announecment.userSourceId,
              profilePicture: response.data,
            };
          });
          // Wait for all promises to resolve
          const profilePics = await Promise.all(promises);
          setLoading(false);
          // Convert the array of profile pictures into an object
          const profilePictureObject = profilePics.reduce(
            (acc: any, pic: any) => {
              acc[pic.userId] = pic.profilePicture;
              return acc;
            },
            {}
          );
          setProfilePictures(profilePictureObject); // Set the profile pictures
        } catch (error) {
          console.error("Error fetching profile pictures:", error);
          // Handle error if needed
        }
      };
      fetchProfilePictures();
    }
  }, [localData]);

  if (
    role === "student" &&
    loc.pathname === `/courseView/announcements/${classId}`
  ) {
    navigate("/dashboard");
    return <></>;
  }

  const language = localStorage.getItem("language");

  return (
    <>
      <AnnouncementsCourseViewHolder>
        <TitleWithIconsWrapper>
          <TypographyRegularDescription
            text={t("course view.Announcements")}
            fontSize="1.75rem"
          />
          <IconsWrapper>
            <div
              style={{ color: `${activeTab ? "#5DD3B3" : "white"}` }}
              ref={dropdownRef}
              onClick={() => {
                toggleDropdown();
                setActiveTab((prev) => !prev);
              }}
            ></div>
            {loc.pathname === `/courseview/announcements/${classId}` &&
              role === "teacher" && ( // If the user is teacher he can add new announcements
                <Link
                  to={`/courseview/announcements/new/${classId}`}
                  state={{ some: classId }}
                >
                  <IconWithText
                    icon={SvgAdd}
                    textElement={
                      <MediumTypography
                        text={t("course view.Add New").toLocaleUpperCase(
                          language === "tr" ? "tr-TR" : "us-US"
                        )}
                        fontSize="0.75rem"
                      />
                    }
                    // margin="0.2rem 0 0 0"
                  />
                </Link>
              )}
          </IconsWrapper>
          {isDropdownVisible && <DropDownFilter />}
        </TitleWithIconsWrapper>
        <FiltersContainer>
        {isParent ? (
                <>
                <FilterButtonsCont>
                  {children.map((classTitle, index) => (
                    <FilterButton key={index} onClick={() => handleFilterAnnouncByClass(classTitle.sourceId, classTitle.className)} active={classTitle.className === classNameToFilter}>
                      {classTitle.className}
                    </FilterButton>
                  ))}
                </FilterButtonsCont>
                <Typography text={classNameToFilter} fontSize="1.75rem"/>
                </>
              ) : null}
        </FiltersContainer>
        {dataLoading && ( // If the data is loading
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SvgNoResult width={"10rem"} />
              <Typography text={`${t("general.loading")}...`} />
            </div>
          </>
        )}
        {currentAnnouncementsCards.length === 0 && !dataLoading ? ( // If the announcment data is empty
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SvgNoResult width={"10rem"} />
              <Typography text={t("course view.No Announcements Yet")} />
            </div>
          </>
        ) : (
          <AnnouncementsCardsHolder>
            <>
              {currentAnnouncementsCards && // mapping the announcement data here
                currentAnnouncementsCards.map((item, index) => (
                  // <Link to={item.path} key={index}>
                  <AnnouncementsCard
                    id={item.id}
                    title={item.title}
                    iconPath={
                      !loading ? profilePictures[item.userSourceId] : imageGif
                    }
                    fullName={item.teacher.username}
                    email={item.teacher.email}
                    userId={item.userSourceId}
                    date={item.createdAt}
                    role={role}
                    classId={classId}
                    currentPage={AnnouncementsCurrentPage}
                    setCurrentPage={setAnnouncementsCurrentPage}
                    itemsPerPage={itemsPerPage}
                    announecmenttData={currentAnnouncementsCards}
                    description=""
                    fetchDataFromLocalStorage={() => {
                      fetchAnnounecmenttData();
                    }}
                    isRead={item.isRead}
                  />
                  // </Link>
                ))}
            </>
          </AnnouncementsCardsHolder>
        )}
        {filtredDataAnnounc.length > itemsPerPage && ( // The Pagination of the Page Goes Here
          <Pagination
            currentPage={AnnouncementsCurrentPage}
            total={filtredDataAnnounc.length}
            limit={itemsPerPage}
            onPageChange={(page: number) => setAnnouncementsCurrentPage(page)}
          />
        )}
      </AnnouncementsCourseViewHolder>
    </>
  );
};

export default AnnouncementsCourseView;
