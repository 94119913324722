import React, { useCallback } from "react";
import DragAndDropZone from "./main/dropzone";
import { Typography } from "../../elements/fonts/Fonts";
import * as XLSX from "xlsx";
import { setParsedData } from "../manageWeight/GlobalVariable/GlobalVariable";

interface Prop {
  header: string;
  onFilesChange?: (newFiles: File[]) => void;
  selectedFiles?: File[]; 
  acceptedTypes?: {[key: string]: string[]};
  readonly?: boolean;
  setLoading?: (loading: boolean) => void;
}


export const DropZoneForm = ({ header,readonly, onFilesChange, selectedFiles,acceptedTypes,setLoading }: Prop): JSX.Element => {
  const handleDrop = useCallback((acceptedFiles: File[]) => {
    if (onFilesChange) {
      onFilesChange(acceptedFiles);
    }

    if (acceptedFiles.length === 1) {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const arrayBuffer = event.target?.result;
        const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        setParsedData(jsonData);
      };

      reader.readAsArrayBuffer(file);
    }
  }, [onFilesChange]);

  return (
    <div>
      <Typography fontSize="1.125rem" text={header} />
      <DragAndDropZone setLoading={setLoading} readonly={readonly} onDrop={handleDrop} initialSelectedFiles={selectedFiles} acceptedTypes={acceptedTypes}/>
    </div>
  );
};
