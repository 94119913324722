import React, { useState, useContext } from "react";
import {
  RubricRowTextModal,
  RubricSmallTextInput,
  RubricTabLayout,
  RubricTextAreaInput, // Correct import
  RubricTextInput,
  RubricTextModal,
  RubricTitletModal,
  RubricRowAdditionAndSUB,
  RubricButtonSpace,
} from "./RubricCreatorTab.style";
import {
  MediumTypography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import ButtonIconAndText from "../../elements/ButtonIconAndText/ButtonIconAndText";
import {
  AddSqIcon,
  SvgRefreshIcon,
  SvgRubric,
  SvgSubstract,
} from "../../elements/Icons";
import { RubricButtonFlexView } from "../../Views/RubricView/RibricView.style";
import SelectInput from "../../elements/SelectInput/SelectInput";
import "./InputNumber.css";
import { GlobalVariabels } from "../../UseContext";
import BlueButton from "../../elements/blueButton/BlueButton";
import { useRecoilValue } from "recoil";
import { ColorSelector, TableColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

interface RubricCreatorTabInterface {
  ColNum: number;
  setColNum: React.Dispatch<React.SetStateAction<number>>;
  RowsNum: number;
  setRowsNum: React.Dispatch<React.SetStateAction<number>>;
  setShowTableRubric: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  Description: string;
  settitle: React.Dispatch<React.SetStateAction<string>>;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
}
export const RubricCreatorTab = ({
  ColNum,
  setColNum,
  RowsNum,
  setRowsNum,
  setShowTableRubric,
  title,
  settitle,
  Description,
  setDescription,
}: RubricCreatorTabInterface): JSX.Element => {
  const ebrollmentOptions = [{ name: "Percentage" }, { name: "Points" }];
  const [titleErore, setEroretitle] = useState("");
  const [EroreDescribtion, setEroreDescribtion] = useState("");
  const [EroreRubricType, setEroreRubricType] = useState("");
  const [ErrorColumn, setErrorColumn] = useState("");
  const [ErrorRows, setErrorRows] = useState("");
  const { t } = useTranslation()

  const {
    boxShadow,
    Container_Gradient_BgColor,
    SecondaryTextColor,
    inputBorderColor,
    themeTextColor
  } = useRecoilValue(ColorSelector);
  // const context = useContext(GlobalVariabels);

  // const {
  //   ColNum,
  //   setColNum,
  //   RowsNum,
  //   setRowsNum,
  //   setShowTableRubric,
  //   Rubrictitle,
  //   setRubrictitle,
  //   RubricDescribtion,
  //   setRubricDescribtion,
  //   RubricSelect,
  //   setRubricSelect,
  // } = context;

  // const handleSelectChangeEnrollment = (selectedValue: string) => {
  //   setRubricSelect(selectedValue);
  // };

  const handelAddCulmnNb = () => {
    if (ColNum < 7) {
      setColNum(ColNum + 1);
    } else {
    }
  };

  const handelSubCulmnNb = () => {
    if (ColNum > 1) {
      setColNum(ColNum - 1);
    } else {
    }
  };
  const handelAddRowsmnNb = () => {
    if (RowsNum < 7) {
      setRowsNum(RowsNum + 1);
    } else {
    }
  };

  const handelSubRowsmnNb = () => {
    if (RowsNum > 1) {
      setRowsNum(RowsNum - 1);
    } else {
    }
  };

  const handelTablevalues = () => {
    setEroretitle("");
    // setEroreDescribtion("");
    setEroreRubricType("");
    setErrorColumn("");
    setErrorRows("");
    if (title.trim() === "") {
      setEroretitle("empty");
      return;
    } else if (ColNum < 1) {
      setErrorColumn("error");
      return;
    } else if (RowsNum < 1) {
      setErrorRows("error");
      return;
    } else {
      setShowTableRubric(true);
      // setShowTableRubric(true);
      // setRubrictitle(title);
      // setRubricDescribtion(Describtion);
      //   setShowSelect(false);
      //   console.log(RubricSelect);
      //   console.log("Rubrictitle:", Rubrictitle);
      //   console.log("RubricDescribtion:", RubricDescribtion);
      // }
    }
  };

  return (
    <RubricTabLayout boxshadow={boxShadow} bgcolor={Container_Gradient_BgColor}>
      <RubricTitletModal>
        <SvgRubric />{" "}
        <MediumTypography
          text={t("Rubric.Define Rubric")}
          fontSize="1.125rem"
          lineHeight="1.75rem"
        />{" "}
      </RubricTitletModal>

      <RubricTextModal>
        <TypographyRegularDescription text={t("Rubric.Title")} fontSize="0.875rem" />
        <RubricTextInput
          color={SecondaryTextColor}
          bordercolor={inputBorderColor}
          type="text"
          className={titleErore ? "required" : "normal"}
          placeholder={t("notes.Enter the Title")}
          style={{ borderColor: titleErore ? "#D04B4B" : "" }}
          value={title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            settitle(e.target.value)
          }
        />
      </RubricTextModal>
      <RubricTextModal>
        <TypographyRegularDescription text={t("forms.Description")} fontSize="0.875rem" />
        <RubricTextAreaInput
          color={SecondaryTextColor}
          bordercolor={inputBorderColor}
          // Use the correct component
          value={Description}
          // placeholder="Description"
          className={EroreDescribtion ? "required" : "normal"}
          placeholder={
            EroreDescribtion
              ? t("forms.Enter Description")
              : t("gradebook.Enter a short description, learning objectives or a topic for this rubric.")
          }
          style={{ borderColor: EroreDescribtion ? "#D04B4B" : "" }}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setDescription(e.target.value)
          }
        />
      </RubricTextModal>

      <RubricRowTextModal>
        <TypographyRegularDescription text={t("Rubric.Columns")} fontSize="0.875rem" />
        <RubricRowAdditionAndSUB>
          <RubricButtonSpace onClick={handelSubCulmnNb}>
            <SvgSubstract color={ColNum >= 1 ? themeTextColor : "" } style={{opacity: ColNum >= 1 ? "" : "0.5"}}/>
          </RubricButtonSpace>
          <RubricSmallTextInput
            type="number"
            style={{
              borderColor: ErrorColumn ? "#D04B4B" : inputBorderColor,
              color: ErrorColumn ? "#D04B4B" : SecondaryTextColor,
            }}
            value={ColNum}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = parseInt(e.target.value);
              if (!isNaN(value) && value > 0 && value <= 7) {
                setColNum(value);
              }
            }}
          />
          <RubricButtonSpace onClick={handelAddCulmnNb}>
            <AddSqIcon />
          </RubricButtonSpace>
        </RubricRowAdditionAndSUB>
      </RubricRowTextModal>

      <RubricRowTextModal>
        <TypographyRegularDescription text={t("Rubric.Rows")} fontSize="0.875rem" />
        <RubricRowAdditionAndSUB>
          <RubricButtonSpace onClick={handelSubRowsmnNb}>
            <SvgSubstract color={RowsNum >= 1 ? themeTextColor : "" } style={{opacity: RowsNum >= 1 ? "" : "0.5"}}/>
          </RubricButtonSpace>
          <RubricSmallTextInput
            type="number"
            style={{
              borderColor: ErrorRows ? "#D04B4B" : inputBorderColor,
              color: ErrorRows ? "#D04B4B" : SecondaryTextColor,
            }}
            value={RowsNum}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = parseInt(e.target.value);
              if (!isNaN(value) && value > 0 && value <= 7) {
                setRowsNum(value);
              }
            }}
          />
          <RubricButtonSpace onClick={handelAddRowsmnNb}>
            <AddSqIcon />
          </RubricButtonSpace>
        </RubricRowAdditionAndSUB>
      </RubricRowTextModal>

      <RubricButtonFlexView>
        <BlueButton
          text={t("Rubric.Generate")}
          Width="7.875rem"
          BackColor="#5DD3B3"
          OnClick={handelTablevalues}
          Height="2.3125rem"
        />
      </RubricButtonFlexView>
    </RubricTabLayout>
  );
};
