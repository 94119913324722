import React, { useState, useEffect } from "react";
import {
  OptionsDisplays,
  CategoriesDisplays,
  IconSpacing,
  FormDividLine,
  OneROW,
  TextPlace,
  MenuBody,
} from "./MenuItems.style";
import ProfileOptionsDataFields from "./ProfileOptionsData"; // Ensure this does not use hooks directly
import { Link } from "react-router-dom";
import { Typography } from "../../elements/fonts/Fonts";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { MenuItem } from "../sidebar/SidebarMenu/sidebarInterface";
import { useRoleProvider } from "../../contexts/RoleContext";

interface MenuItemsInterface {
  Height?: string;
  childContent?: any;
}

const MenuItemsV2 = ({
  Height = "42rem",
  childContent,
}: MenuItemsInterface): JSX.Element => {
  const { t } = useTranslation();
  const { textInputColorA } = useRecoilValue(ColorSelector);
  const savedSelectedIndex = localStorage.getItem("selectedIndex");
  const tenantName = localStorage.getItem("tenant");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(
    savedSelectedIndex ? parseInt(savedSelectedIndex) : 0
  );

  const currentPath = window.location.pathname.toLowerCase();
  const { roleProvider, isLoading } = useRoleProvider();

  const handleItemClick = (index: number) => {
    localStorage.setItem("selectedIndex", index.toString());
    setSelectedIndex(index);
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("selectedIndex");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Call ProfileOptionsDataFields here
  const profileOptionsData = ProfileOptionsDataFields();
  const [data, setData] = useState<any[]>(profileOptionsData);

  useEffect(() => {
    if (roleProvider === "student") {
      // Exclude the item at index 1
      const filteredData = profileOptionsData.filter((_, index) => index !== 1);
      setData(filteredData);
    } else {
      // Include all items
      setData(profileOptionsData);
    }
  }, []);

  return (
    <MenuBody>
      {tenantName !== "ISU" && (
        <OneROW>
          <OptionsDisplays>
            {data.map((item, index) => {
              const itemPath = item.path.toLowerCase();
              const isSelected = currentPath.includes(itemPath);

              return (
                <Link to={item.path} key={index}>
                  <CategoriesDisplays
                    style={
                      isSelected
                        ? { color: "#5DD3B3", borderColor: "#5DD3B3" }
                        : { borderColor: textInputColorA, opacity: 0.5 }
                    }
                    onClick={() => handleItemClick(index)}
                  >
                    <IconSpacing>
                      <item.icon />
                    </IconSpacing>
                    <TextPlace>
                      <Typography
                        text={t(item.title)}
                        fontSize="1rem"
                        fontWeight="400"
                      />
                    </TextPlace>
                  </CategoriesDisplays>
                </Link>
              );
            })}
          </OptionsDisplays>
          <FormDividLine Height={Height} />
        </OneROW>
      )}
      <div style={{ display: "flex", flex: "1" }}>{childContent}</div>
    </MenuBody>
  );
};

export default MenuItemsV2;
