import React, { useState } from "react";
import {
  ComplexSelectBodyTable,
  ComplexSelectTable,
  ComplexSelectTableHeaderRow,
  ComplexSelectTableBodyRow,
  SurveyComplexProfileTableContainer,
} from "../AnalyticsAllAssigment.style";
import { ScrumTableCell } from "./ScrumDetailTable.style";
import {
  CheckboxLabel,
  SeletectOptinToogle,
} from "../../ProfileCheckBoxOptions/NotificationSetting.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../../elements/fonts/Fonts";
import Each from "../../../utils/Each";
import {
  ScrumDetailActionData,
  ScrumDetailActionInterface,
} from "../../Tables/ScrumDetailTable/ScrumActionData";
import {
  ComplexSelectTableMessagePopup,
  PointerContainer,
} from "../SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableTable.style";
import { SvgEmail, SvgMore } from "../../../elements/Icons";
import { StudentCompletionTracingIMG } from "../../StudentCompletionTracing/StudentCompletionTracing.style";
import { formatDate } from "../../../utils/FormatedDATE";
import AanlyticsMenueThreeOptions from "../AssignmentSubmissionCompo/AanlyticsMenueThreeOptions";
import { GradeHandleColor, SubmissionDateColor } from "../../../utils/Function";
import { ForumAbsoluteDateContainer } from "../ForumParticipationDetails/ForumParticipationDetailsTable/ForumParticipationDetailsTable.style";
import DateBoxPopUp from "../../../elements/DateBoxPopUp/DateBoxPopUp";
import { stubArray } from "lodash";
import {
  handleItemToggle,
  handleSelectAllToggle,
} from "../../../hooks/TableSelectedOptions";
interface ScrumDetailTableInterface {
  Grade: boolean;
  filteredData: any[];
  MaxGrade?: number;
}
const ScrumDetailTable = ({
  Grade,
  filteredData,
  MaxGrade,
}: ScrumDetailTableInterface) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(
    null
  );
  const handleOptionButtonClick = (index: number) => {
    setSelectedOptionIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const [shapeDueDateToggles, setShapeDueDateToggles] = useState<
    { isOpen: boolean; selectedDate: Date | null }[]
  >(filteredData.map(() => ({ isOpen: false, selectedDate: null })));
  const [ShapeCutOffDateToggles, setShapeCutOffDateToggles] = useState<
    { isOpen: boolean; selectedDate: Date | null }[]
  >(filteredData.map(() => ({ isOpen: false, selectedDate: null })));

  const handleSaveDueDate = (index: number) => {
    setShapeDueDateToggles((prevToggles) => {
      const updatedToggles = [...prevToggles];
      updatedToggles[index] = {
        isOpen: !updatedToggles[index].isOpen,
        selectedDate: updatedToggles[index].selectedDate,
      };

      if (selectedOptionIndex !== null && selectedOptionIndex !== index) {
        // Close the previously opened action box
        updatedToggles[selectedOptionIndex] = {
          isOpen: false,
          selectedDate: null,
        };
        setSelectedOptionIndex(null);
      }

      setSelectedOptionIndex(updatedToggles[index].isOpen ? index : null);

      return updatedToggles;
    });
  };

  const handleSaveCutOffDate = (index: number) => {
    setShapeCutOffDateToggles((prevToggles) => {
      const updatedToggles = [...prevToggles];
      updatedToggles[index] = {
        isOpen: !updatedToggles[index].isOpen,
        selectedDate: updatedToggles[index].selectedDate,
      };

      if (selectedOptionIndex !== null && selectedOptionIndex !== index) {
        // Close the previously opened action box
        updatedToggles[selectedOptionIndex] = {
          isOpen: false,
          selectedDate: null,
        };
        setSelectedOptionIndex(null);
      }

      setSelectedOptionIndex(updatedToggles[index].isOpen ? index : null);

      return updatedToggles;
    });
  };

  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  // Initialize an array to track the checked state of each item
  const [checkedItems, setCheckedItems] = useState<boolean[]>(
    new Array(filteredData.length).fill(false)
  );

  const validateResponsesColor = (
    ResponsesDate: Date | string | undefined = undefined,
    status: string
  ): string => {
    return !ResponsesDate ||
      !status ||
      ["Incomplete", "InComplete", "Undefine"].includes(status)
      ? "#D85D66"
      : "#FFF";
  };

  return (
    <ComplexSelectTable>
      <ComplexSelectTableHeaderRow>
        <ScrumTableCell>
          <SeletectOptinToogle
            type="checkbox"
            id="selectAll" // Add optional chaining to handle potential undefined
            value="selectAll" // Add optional chaining to handle potential undefined
            checked={selectAllChecked} // Control the checked state of the "Select All" checkbox
            onChange={() =>
              handleSelectAllToggle(
                setSelectAllChecked,
                setCheckedItems,
                filteredData
              )
            }
          />
          <CheckboxLabel htmlFor="selectAll">
            <MediumTypography
              text={"Student"}
              fontSize="1rem"
              lineHeight="1.25rem"
            />
          </CheckboxLabel>
        </ScrumTableCell>
        <Each
          of={ScrumDetailActionData}
          render={(item: ScrumDetailActionInterface, index: number) => (
            <ScrumTableCell>
              <MediumTypography
                text={item.text}
                fontSize="1rem"
                lineHeight="1.25rem"
              />
            </ScrumTableCell>
          )}
        />
        <ScrumTableCell
          style={{
            justifyContent: Grade ? "flex-between" : "flex-end",
            flex: Grade ? "0.8" : "0.4",
          }}
        >
          {" "}
          {Grade && (
            <MediumTypography
              text="Grade"
              fontSize="1rem"
              lineHeight="1.25rem"
            />
          )}
          <PointerContainer>
            <SvgMore style={{ transform: "rotate(90deg)" }} />
          </PointerContainer>{" "}
        </ScrumTableCell>
      </ComplexSelectTableHeaderRow>
      <ComplexSelectBodyTable>
        {filteredData.map((val, index) => (
          <ComplexSelectTableBodyRow key={index}>
            <ScrumTableCell>
              <SeletectOptinToogle
                type="checkbox"
                id={val.StdCode}
                value={val.StdCode}
                checked={checkedItems[index]}
                onChange={() =>
                  handleItemToggle(index, checkedItems, setCheckedItems)
                }
              />
              <CheckboxLabel
                htmlFor={val.StdCode}
                style={{ marginTop: "1rem" }}
              >
                <SurveyComplexProfileTableContainer>
                  <StudentCompletionTracingIMG
                    src={
                      val.ProfilePic
                        ? val.ProfilePic
                        : require("../../../assets/userProfile.png")
                    }
                    alt="no img"
                  />
                  <Typography text={val.StdName || ""} fontSize="0.875rem" />
                </SurveyComplexProfileTableContainer>
              </CheckboxLabel>
            </ScrumTableCell>
            <ScrumTableCell>
              <LightTypography
                text={val.status || "UnSubmitted"}
                fontSize="0.75rem"
              />
            </ScrumTableCell>
            <ScrumTableCell>
              <LightTypography
                text={formatDate(val.ResponsesDate as Date) || "None"}
                fontSize="0.75rem"
                color={validateResponsesColor(val.ResponsesDate, val.status)}
              />
            </ScrumTableCell>
            <ScrumTableCell>
              <LightTypography
                text={formatDate(val.LastModification as Date) || "None"}
                fontSize="0.75rem"
              />
            </ScrumTableCell>
            <ScrumTableCell>
              <LightTypography
                text={`${val.TimeSpent?.toString()} min` || "0"}
                fontSize="0.75rem"
              />
            </ScrumTableCell>
            <ScrumTableCell
              style={{
                justifyContent: Grade ? "flex-between" : "flex-end",
                flex: Grade ? "0.8" : "0.4",
              }}
            >
              {Grade && (
                <LightTypography
                  text={
                    val.Grade !== undefined
                      ? `${val.Grade}/${MaxGrade}`
                      : `--/${MaxGrade}`
                  }
                  fontSize="0.75rem"
                  color={GradeHandleColor(val.Grade, MaxGrade)}
                />
              )}

              <PointerContainer onClick={() => handleOptionButtonClick(index)}>
                <SvgMore style={{ transform: "rotate(90deg)" }} />
                {selectedOptionIndex === index && (
                  <>
                    {Grade ? (
                      <AanlyticsMenueThreeOptions
                        handleSaveCutOffDate={() => handleSaveCutOffDate(index)}
                        handleSaveDueDate={() => handleSaveDueDate(index)}
                      />
                    ) : (
                      <ComplexSelectTableMessagePopup>
                        <SvgEmail width={"1.3125rem"} height={"1rem"} />
                        <MediumTypography
                          text="Message Student"
                          fontSize="0.75rem"
                        />
                      </ComplexSelectTableMessagePopup>
                    )}
                  </>
                )}
              </PointerContainer>
              {selectedOptionIndex === index &&
              shapeDueDateToggles[index].isOpen ? (
                <ForumAbsoluteDateContainer>
                  <DateBoxPopUp
                    TitleHeader="Extend Due Date"
                    ShowDateBox={shapeDueDateToggles[index].isOpen}
                    setShowDateBox={(value) => {
                      setShapeDueDateToggles((prevToggles) => {
                        const updatedToggles = [...prevToggles];
                        updatedToggles[index] = {
                          isOpen:
                            typeof value === "function"
                              ? value(prevToggles[index].isOpen)
                              : value,
                          selectedDate: prevToggles[index].selectedDate,
                        };
                        return updatedToggles;
                      });
                    }}
                    selectedDate={shapeDueDateToggles[index].selectedDate}
                    setSelectedDate={(date: any) => {
                      setShapeDueDateToggles((prevToggles) => {
                        const updatedToggles = [...prevToggles];
                        updatedToggles[index] = {
                          isOpen: prevToggles[index].isOpen,
                          selectedDate: date,
                        };
                        return updatedToggles;
                      });
                    }}
                  />
                </ForumAbsoluteDateContainer>
              ) : null}

              {selectedOptionIndex === index &&
                ShapeCutOffDateToggles[index].isOpen && (
                  <ForumAbsoluteDateContainer>
                    <DateBoxPopUp
                      TitleHeader="Cut Off Date"
                      ShowDateBox={ShapeCutOffDateToggles[index].isOpen}
                      setShowDateBox={(value) => {
                        setShapeCutOffDateToggles((prevToggles) => {
                          const updatedToggles = [...prevToggles];
                          updatedToggles[index] = {
                            isOpen:
                              typeof value === "function"
                                ? value(prevToggles[index].isOpen)
                                : value,
                            selectedDate: prevToggles[index].selectedDate,
                          };
                          return updatedToggles;
                        });
                      }}
                      selectedDate={ShapeCutOffDateToggles[index].selectedDate}
                      setSelectedDate={(date: any) => {
                        setShapeCutOffDateToggles((prevToggles) => {
                          const updatedToggles = [...prevToggles];
                          updatedToggles[index] = {
                            isOpen: prevToggles[index].isOpen,
                            selectedDate: date,
                          };
                          return updatedToggles;
                        });
                      }}
                    />
                  </ForumAbsoluteDateContainer>
                )}
            </ScrumTableCell>
          </ComplexSelectTableBodyRow>
        ))}
      </ComplexSelectBodyTable>
    </ComplexSelectTable>
  );
};

export default ScrumDetailTable;
