import styled from "styled-components";
import { breakpoints } from "../../theme";

export const DashboardLayout = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 0;
  /* grid-template-rows: 1fr 2.5fr 3fr 1.5fr; */
  /* row-gap: */

  & > :nth-child(2) {
    margin-top: 2rem;
  }
  & > :nth-child(4) {
    margin-top: 2rem;
  }
  padding-bottom: 2rem;
`;

export const MainTitles = styled.div`
  /* margin-top: 0.5rem; */
  display: flex;
  gap: 0.31rem;
`;
export const MainOnlineTitles = styled.div`
  display: flex;
  gap: 0.31rem;
  @media (max-width: calc(${breakpoints.mobile} - 100px)) {
    align-items: start;
    gap: 0.25rem;
    flex-wrap: wrap;
    max-width: 10rem;
  }
`;
export const CalendarAnnouncement = styled.div`
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  grid-gap: 1.88rem;
  /* @media (max-width: ${breakpoints.large}) {
    grid-template-columns: 1.3fr 1fr;
  } */
  @media (max-width: ${breakpoints.medium}) {
    display: flex;
    flex-direction: column;
    /* row-gap: 2.8rem; */
  }
`;

export const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
`;
export const AnnouncementDiv = styled.div`
  @media (max-width: ${breakpoints.large}) {
  }
`;

export const AnnouncementDivTitles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: calc(${breakpoints.mobile} - 100px)) {
    align-items: end;
  }
`;
interface ViewAllHolderProps {
  color?: string;
}
export const ViewAllHolder = styled.div<ViewAllHolderProps>`
  cursor: pointer;
  color: ${(props) => props.color};
  & > :hover {
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
`;
