import React from "react";
import { AnalyticBackIcon } from "./AnalyticForumIdShadowHeader.style";
import { SvgSimpleArrow } from "../../../elements/Icons";
import { LightTypography } from "../../../elements/fonts/Fonts";
interface AnalyticsBackPageinterface {
  onClick?: () => void;
  text?: string;
}
const AnalyticsBackPage = ({ onClick, text }: AnalyticsBackPageinterface) => {
  return (
    <AnalyticBackIcon onClick={onClick}>
      <SvgSimpleArrow style={{ transform: "rotate(180deg)" }} />

      <LightTypography text={text || "Back"} fontSize="1rem" />
    </AnalyticBackIcon>
  );
};

export default AnalyticsBackPage;
