import {
  SvgDocument,
  SvgSmalMp4media,
  SvgSmallPDF,
  SvgSmallPowerPoint,
  SvgUrl,
} from "../../../elements/Icons";
import { TypographyRegularDescription } from "../../../elements/fonts/Fonts";
import { uuidSplitter } from "../../../utils/StringManipulation";
import { PTLDisplays, PTLImg, PTLPdfDisplay } from "./PostsTimeLine.style";

export const DisplayContentDisplay = (fileName: any, fileNameText: any) => {
  const fileNameTextSPlittedt = uuidSplitter(fileNameText).replace(
    /^(forum\/submissions\/)/,
    ""
  );

  if (fileName.includes(".pdf") || fileName.includes(".docx")) {
    return (
      <PTLDisplays>
        <a
          href={fileName}
          target="_blank"
          rel="noopener noreferrer"
          download={fileName}
        >
          <PTLPdfDisplay>
            <SvgSmallPDF />
            <div>
              <TypographyRegularDescription
                text={fileNameTextSPlittedt}
                fontSize="0.75rem"
              />
            </div>
          </PTLPdfDisplay>
        </a>
      </PTLDisplays>
    );
  } else if (
    fileName.includes(".jpg") ||
    fileName.includes(".jpeg") ||
    fileName.includes(".png")
  ) {
    return (
      <PTLDisplays>
        <PTLImg src={fileName} />
      </PTLDisplays>
    );
  }

  // switch (extension) {
  //   case "jpg":
  //   case "jpeg":
  //   case "png":
  //     return (
  //       <PTLDisplays>
  //         <PTLImg src={fileName} />
  //       </PTLDisplays>
  //     );
  //   case "pdf":
  //     return (
  //       <PTLDisplays>
  //         <a
  //           href={fileName}
  //           target="_blank"
  //           rel="noopener noreferrer"
  //           download={fileName}
  //         >
  //           <PTLPdfDisplay>
  //             <SvgSmallPDF />
  //             <div>
  //               <TypographyRegularDescription
  //                 text={fileNameText}
  //                 fontSize="0.75rem"
  //               />
  //             </div>
  //           </PTLPdfDisplay>
  //         </a>
  //       </PTLDisplays>
  //     );

  //   case "doc":
  //   case "docx":
  //     return (
  //       <PTLDisplays>
  //         <a href={fileName} target="_blank" rel="noopener noreferrer">
  //           <PTLPdfDisplay>
  //             <SvgDocument />
  //             <div>
  //               <TypographyRegularDescription
  //                 text={fileNameText}
  //                 fontSize="0.75rem"
  //               />
  //             </div>
  //           </PTLPdfDisplay>
  //         </a>
  //       </PTLDisplays>
  //     );

  //   case "com":
  //   case "org":
  //     return (
  //       <PTLDisplays>
  //         <a href={fileName} target="_blank" rel="noopener noreferrer">
  //           <PTLPdfDisplay>
  //             <SvgUrl />
  //             <div>
  //               <TypographyRegularDescription
  //                 text={fileNameText}
  //                 fontSize="0.75rem"
  //               />
  //             </div>
  //           </PTLPdfDisplay>
  //         </a>
  //       </PTLDisplays>
  //     );
  //   case "ppt":
  //   case "pptx":
  //     return (
  //       <PTLDisplays>
  //         <a href={fileName} target="_blank" rel="noopener noreferrer">
  //           <PTLPdfDisplay>
  //             <SvgSmallPowerPoint />
  //             <div>
  //               <TypographyRegularDescription
  //                 text={fileNameText}
  //                 fontSize="0.75rem"
  //               />
  //             </div>
  //           </PTLPdfDisplay>
  //         </a>
  //       </PTLDisplays>
  //     );
  //   case "webp":
  //   case "mp4":
  //   case "mpeg":
  //   case "mov":
  //   case "m4v":
  //     return (
  //       <PTLDisplays>
  //         <a href={fileName} target="_blank" rel="noopener noreferrer">
  //           <PTLPdfDisplay>
  //             <SvgSmalMp4media />
  //             <div>
  //               <TypographyRegularDescription
  //                 text={fileNameText}
  //                 fontSize="0.75rem"
  //               />
  //             </div>
  //           </PTLPdfDisplay>
  //         </a>
  //       </PTLDisplays>
  //     );

  //   default:
  //     return <PTLDisplays> </PTLDisplays>;
  // }
};
