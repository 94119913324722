import styled from "styled-components";

export const AnalyticsUserViewCompoLayout =styled.div`
display: flex;
flex-direction:column;
gap:0.63rem;
width:100%;
`;
export const AnalyticsUserViewCompoTitle =styled.div`
display: flex;
width:100%;
margin-bottom:0.30rem;
justify-content:space-between;
`;

export const AnalyticsUserViewCompoTable =styled.div`
display: flex;
flex-direction:column;
gap:0.63rem;
width:100%;
position:relative;
border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
min-height:4rem;
`;

export const AnalyticsUserTableActions =styled.div`
display: flex;
justify-content:space-between;
width:100%;
padding: 1.25em;

`;
export const TableActionsProperties =styled.div`
display: flex;
flex-direction:column;
gap:1.60rem;
justify-content:flex-end;
align-items:flex-end;
cursor: pointer;
`;
export const AnalyticsUserViewCompoTableHeader =styled.div`
display: flex;
justify-content:space-between;
width:100%;
position:absolute;
top:5.8rem;
height: 3.625rem;
/* background-color:aqua; */
gap: 0.5rem;
/* opacity: 0.5; */
background: var(--Grey-Gradient, linear-gradient(90deg, #415160 0%, #293440 107.88%));
align-items:center;
`;

export const TableHeaderCell =styled.div`
display: flex;
flex:1;
/* background-color:yellow; */
min-width:3rem;
padding-left:0.5rem;
/* width:100%; */
/* max-width:5rem */
/* &:last-child{
  display: none;
} */
&:last-child{

flex:1;
padding-right:0.3rem;
}
`;

export const AnalyticsUserViewCompoTableBody=styled.div`
display: flex;
flex-direction:column;
width:100%;
margin-top:3rem;
min-height:5rem;
align-items:center;
`;
export const AnalyticsSearchSelect=styled.div`
display: flex;
gap:0.60rem;
align-items:center;
`;
export const AnalyticsUserViewCompoTableBodyRow=styled.div`
display: flex;
/* justify-content:space-between; */
width:100%;
/* justify-content:space-between;  */
/* background-color:red; */
/* align-items:center; */
/* margin-top:3rem;
min-height:5rem; */
/* gap:0.5rem; */
/* background-color:red; */
/* cursor: pointer; */
&:nth-child(even){
  /* opacity: 0.5; */
/* background: var(--Main-dark-grey, #364350); */
}




`;
export const AnalyticsUserViewCompoTableBodyRowChild=styled.div`
display: flex;
/* justify-content:space-between; */
width:160%;
justify-content:space-between; 
/* background-color:red; */
/* align-items:center; */
/* margin-top:3rem;
min-height:5rem; */
gap:0.5rem;
/* background-color:yellowgreen; */
cursor: pointer;
&:nth-child(even){
  /* opacity: 0.5; */
background: var(--Main-dark-grey, #364350);
}




`;

export const TableBodyCell =styled.div`
display: flex;
flex:1;
min-width:3rem;
/* background-color:black; */
min-width:5rem;
padding-left:0.5rem;
min-height:3.625rem;
align-items:center;

&:last-child{

  flex:1;
  padding-right:0.3rem;
}
`;