import React, { FC, useState, useEffect, DragEvent } from 'react';
import { EssayQuestionContainer, CheckBox, EnableAttachmentContainer } from './EssayQuestion.style';
import { EssayQuestionProps } from './EssayQuestionInterface';
import { Typography } from '../../../elements/fonts/Fonts';
import { theme } from '../../../theme';
import { ErrorDiv } from '../../../elements/ErrorDiv/Errordiv.style';
import { mapErrorType } from '../../../utils/QuizErrorTypes';
import { t } from 'i18next';


const EssayQuestion: FC<EssayQuestionProps> = ({ choice,
   qindex,
    handleChoiceMatchingQuestion, 
    showQuest, 
    handleChoiceChecked ,
    questionError
  }) => {
  const [isChecked, setIsChecked] = useState<boolean | undefined>(false);

  useEffect(() => {
    setIsChecked(choice?.isChecked);
  }, [choice]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    // handleChoiceChecked handleChoiceChecked(qindex, !isChecked);
  };
  const errorType = mapErrorType(questionError || "", t);



  return (
    <>
    <EssayQuestionContainer>
     
      {/* <EnableAttachmentContainer>
        {!showQuest?
 <CheckBox type='checkbox' checked={isChecked===true} name='isChecked' id="isChecked" onChange={handleCheckboxChange} />
        :
        <CheckBox type='checkbox' checked={isChecked===true} name='isChecked' id="isChecked" onChange={handleCheckboxChange} disabled/>
}
        <Typography text='Enable attachments' fontSize='0.875rem' color={theme.dark.primary_text_color} />
      </EnableAttachmentContainer> */}
    </EssayQuestionContainer>
    {(questionError  && qindex === 0) && <ErrorDiv style={{marginLeft:"1.5rem"}}>{errorType}</ErrorDiv>}
    </>
  );
}

export default EssayQuestion;
