import React, { useEffect, useState } from "react";
import {
  PRDivInput,
  PRFilePlace,
  PRIRepliesCol,
  PRInput,
  PRPost,
  PRProfilePic,
  PostReplyContainer,
  RepliedImgDeleted,
  RepliedImgReader,
} from "./PostReply.style";
import { TypographyRegularDescription } from "../../../elements/fonts/Fonts";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import { get } from "../../../utils/AxiosRequests";
import InputFileIconOnly from "../../../elements/InputFileIconOnly/InputFileIconOnly";
import {
  SvgAddsqNoBackground,
  SvgCancel,
  SvgloadingGroup,
} from "../../../elements/Icons";
import { PTLImg } from "../main/PostsTimeLine.style";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { LoadingContainerSvg } from "../../LoadingComp/LodingStyle";
import { t } from "i18next";

interface PostReplyInterface {
  onReplyChange: (value: string) => void;
  onPostReply: (file: FileList | null) => void;
  placeholderText: string;
  replyValue: string;
  replyLoading?: any;
}

const PostReply = ({
  onReplyChange,
  onPostReply,
  placeholderText,
  replyValue,
  replyLoading,
}: PostReplyInterface) => {
  const { themeTextColor, inputBorderColor } = useRecoilValue(ColorSelector);
  const [ProfilePic, setProfilePic] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<FileList | null>(null);
  const [fileDataURL, setFileDataURL] = useState<string | null>(null); // Add this state
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");

  useEffect(() => {
    const getuserProfileById = async () => {
      try {
        const userProfileToDisplay = await get(
          `user/profile/${subIdSplitted}auth0SubIdSplitter/${tenantName}`
        );
        setProfilePic(userProfileToDisplay.data.profilePicture);
      } catch (error) {
        console.error(error);
      }
    };
    getuserProfileById();
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      onPostReply(selectedFile);
    }
  };

  const handleFileChange = (files: FileList | null) => {
    setSelectedFile(files);

    if (files) {
      // Read the first file as a data URL
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const dataURL = e.target?.result;
        setFileDataURL(dataURL as string);
      };
      fileReader.readAsDataURL(files[0]);

      // Log the file names
      Array.from(files).forEach((file, index) => {});
    }
  };

  const handleDeleteImage = () => {
    // Function to handle deletion
    setFileDataURL(null);
    setSelectedFile(null);
  };

  return (
    <PostReplyContainer>
      <PRProfilePic
        src={
          ProfilePic
            ? ProfilePic
            : user?.picture || "/assets/images/profileImg.jpg"
        }
      />
      <PRIRepliesCol>
        <PRDivInput bordercolor={inputBorderColor}>
          <PRInput
            type="text"
            placeholder={placeholderText}
            value={replyValue}
            onChange={(e) => onReplyChange(e.target.value)}
            onKeyDown={handleKeyDown}
            color={themeTextColor}
          />
          {/* <PRFilePlace>
            <InputFileIconOnly
              icon={SvgAddsqNoBackground}
              acceptedFileTypes=".jpg, .jpeg, .png"
              onChange={handleFileChange}
            />
          </PRFilePlace> */}
          {replyLoading ? (
            <LoadingContainerSvg>
              <SvgloadingGroup />
            </LoadingContainerSvg>
          ) : (
            <PRPost
              onClick={() => onPostReply(selectedFile)}
              bordercolor={inputBorderColor}
            >
              <TypographyRegularDescription
                text={t("grader.Post")}
                fontSize="0.75rem"
              />
            </PRPost>
          )}
        </PRDivInput>
        {fileDataURL && (
          <RepliedImgReader>
            <PTLImg src={fileDataURL} alt="h" />
            <RepliedImgDeleted onClick={handleDeleteImage}>
              <SvgCancel style={{ margin: "-0.7rem" }} />
            </RepliedImgDeleted>
          </RepliedImgReader>
        )}
      </PRIRepliesCol>
    </PostReplyContainer>
  );
};

export default PostReply;
