import styled from 'styled-components';

export const ToggleContainer = styled.label`
  display: inline-block;
  cursor: pointer;
`;

export const ToggleInput = styled.input.attrs({ type: 'checkbox' })`
  display: none;
  
`;

export const ToggleSlider = styled.span`
  width: 2.25rem;
  height: 0.75rem;
  background: var(--Card-Gradient, linear-gradient(109deg, #415160 4.27%, #293440 95.56%));

  border-radius: 20px;
  position: relative;
  display: inline-block;
  transition: background-color 0.3s ease;

  &::before {
    content: '';
    width: 12px;
    height: 0.75rem;
    border-radius: 6.25rem;
    /* border: gray 0.1px solid; */
background: var(--Card-Gradient, linear-gradient(109deg, #415160 4.27%, #293440 95.56%));
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
    transition: transform 0.3s ease, background-color 0.3s ease;
  }

  ${ToggleInput}:checked + & {
    border: none;
    background: var(--Card-Gradient, linear-gradient(109deg, #415160 4.27%, #293440 95.56%));

  }

  ${ToggleInput}:checked + &::before {

    left: calc(100% - 20px);
    transform: translateY(-50%) translateX(100%);
    background: linear-gradient(to right, #5dd3b3, #2f6a5a);
  }
`;