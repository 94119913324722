export interface ScrumDetailActionInterface {
id:string;
text:string;
}

export const ScrumDetailActionData : ScrumDetailActionInterface[] =[
  // {
  //   id:"StdALL",
  //   text:"Student",
  // },
  {
    id:"SCDFCDS",
    text:"Status",
  },
  {
    id:"StdAsafsdgLL",
    text:"Responses Date",
  },
  {
    id:"StdAsaddsfasdfsdgLL",
    text:"Last Modified",
  },
  {
    id:"sadsadfds",
    text:"Time Spent",
  },
  // {
  //   id:"asdsfewsdfgh",
  //   text:"Grade",
  // },
]