import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled, { css } from "styled-components";

export const CustomLightToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    background-color: #ffffff; // Your custom background color
    color: #2d3741; // Your custom text color
    height: fit-content;
    font-family: "Primary Font";
  }

  /* Custom colors for close button based on toast type */
  .Toastify__toast--success .Toastify__close-button {
    color: #5dd3b3; // Custom progress bar color for success
  }

  .Toastify__toast--info .Toastify__close-button {
    color: #0b8fff; // Custom progress bar color for info
  }

  .Toastify__toast--error .Toastify__close-button {
    color: #d85d66; // Custom progress bar color for error
  }

  /* Custom colors for progress bar based on toast type */
  .Toastify__toast--success .Toastify__progress-bar--animated {
    background-color: #5dd3b3; // Custom progress bar color for success
  }

  .Toastify__toast--info .Toastify__progress-bar--animated {
    background-color: #0b8fff; // Custom progress bar color for info
  }

  .Toastify__toast--error .Toastify__progress-bar--animated {
    background-color: #d85d66; // Custom progress bar color for error
  }

  ${(props) => props.position === "bottom-left" && css``}

    /* Custom colors for success, info, and error types */
    .Toastify__toast--success {
    --toastify-icon-color-success: #5dd3b3; // Custom success color
  }

  .Toastify__toast--info {
    --toastify-icon-color-info: #0b8fff; // Custom info color
  }

  .Toastify__toast--error {
    --toastify-icon-color-error: #d85d66; // Custom error color
  }
`;
