import React, { FC, useState } from "react";
import {
  BackgroundOverlay,
  PopupContainer,
  PopupContentContainer,
  IconsContainer,
  HorizantalLine,
  CloseIconContainer,
} from "./RecordPopup.style.";
import { Typography } from "../../../../../elements/fonts/Fonts";
import { ButtonFLex, LineFLex, PopUplineStyle } from "./RecordPopup.style.";
import { SvgCloseIcon } from "../../../../../elements/Icons";
import VideoPlayer from "../../../../videoPlayer/VideoPlayer";
interface PopUp {
  show: boolean;
  title: string;
  path: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const RecordPopup: FC<PopUp> = ({ show, setShow, title, path }) => {
  return (
    <PopUplineStyle isvisible={show.toString()}>
      {show && (
        <BackgroundOverlay style={{ paddingTop: "2.4rem" }}>
          <PopupContainer>
            <PopupContentContainer>
              <IconsContainer>
                <Typography
                  fontSize="1rem"
                  fontWeight="400"
                  lineHeight="1.25rem"
                  text={title || ""}
                />

                <SvgCloseIcon
                  color="#5dd3b3"
                  cursor={"pointer"}
                  onClick={() => setShow(false)}
                />
              </IconsContainer>
              {/* <VideoPlayer videoUrl={path} stretch={false} /> */}
              <video controls controlsList="nodownload">
                <source src={path} type="video/mp4" />
              </video>
            </PopupContentContainer>
          </PopupContainer>
        </BackgroundOverlay>
      )}
    </PopUplineStyle>
  );
};

export default RecordPopup;
