import React, { FC } from "react";
import OpenMenu from "../OpenMenu/OpenMenu";
import SearchAndFilter from "../../GraderSearchAndFilter/main/SearchAndFilter";
import Comments from "../../GraderComments/main/Comments";
import NavBarIcons from "../NavBarIcons/NavBarIcons";
import { BlackLine, SideToolsContainer } from "./SideTools.style";
import { SideToolsProps } from "./SideToolsInterface";
import { useRecoilState, useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { adminState } from "../../../recoil/RecoilStates";
const SideTools: FC<SideToolsProps> = ({
  showComment,
  showSearch,
  setShowSearch,
  setShowComment,
  handleOpenComment,
  handleOpenSearch,
  setShowRubric,
  showRating,
  showSubmitedTime,
  showNavIcon,
  showLate,
  student,
  studentIndex,
  updateSubmittedByInstructor,
  allAssignment,
  questionIndex,
  questions,
  quiz,
  forum,
  fetchData,
  totalPoints,
  usingRubric,
  rubricId,
  selectedRubricCell,
  setShowTurnitinViewer,
  setTurnitinUrl,
  setTurnitinLoading,
  resourceR,
  isAssignmentGraded,
  handleChangeViewForAssignment,
}) => {
  const { backgroundColor } = useRecoilValue(ColorSelector);
  const [isAdmin] = useRecoilState(adminState)
  return (
    <SideToolsContainer bgColor={backgroundColor} isGraded={isAssignmentGraded} isadmin={isAdmin.toString()}>
      {showNavIcon ? (
        <>
          {/* <NavBarIcons
            handleOpenComment={handleOpenComment}
            handleOpenSearch={handleOpenSearch}
          /> */}
          {/* <BlackLine /> */}
        </>
      ) : (
        ""
      )}
      {showComment ? (
        <Comments
          setShowComment={setShowComment}
          setShowRubric={setShowRubric}
          showRating={showRating}
          showSubmitedTime={showSubmitedTime}
          showLate={showLate}
          student={student}
          studentIndex={studentIndex}
          allAssignment={allAssignment}
          questionIndex={questionIndex}
          questions={questions}
          quiz={quiz}
          forum={forum}
          fetchData={fetchData}
          totalPoints={totalPoints}
          usingRubric={usingRubric}
          rubricId={rubricId}
          selectedRubricCell={selectedRubricCell}
          setShowTurnitinViewer={setShowTurnitinViewer}
          setTurnitinUrl={setTurnitinUrl}
          setTurnitinLoading={setTurnitinLoading}
          resourceR={resourceR}
          isAssignmentGraded={isAssignmentGraded}
          handleChangeViewForAssignment={handleChangeViewForAssignment}
        // updateSubmittedByInstructor={updateSubmittedByInstructor}
        />
      ) : showSearch ? (
        <SearchAndFilter />
      ) : (
        <OpenMenu setShowComment={setShowComment} />
      )}
    </SideToolsContainer>
  );
};

export default SideTools;
