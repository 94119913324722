import { FC, useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  ModuleContainer,
  TopicsContainer,
  ModuleHeader,
  DivideLine,
  AddTopicIconWrapper,
  HDotsWrapper,
  EmptyTopicsTextWrapper,
  AddTopicActiveWrapper,
} from "./Module.style";
import LoaderText from "../../loader/loaderText/LoaderText";
import {
  ModuleInterface,
  ModuleProps,
  TopicProps,
} from "../../../Views/teacherView/Course-Creator/courseCreatorInterface";
import {
  Typography,
  PrimaryFontLightText,
} from "../../../elements/fonts/Fonts";

import {
  SvgAddTopicIcon,
  SvgMoreHorizontalIcon,
  SvgAddTopicActiveBgIcon,
} from "../../../elements/Icons";
import Topic from "../../topic/main/Topic";
import PopupDelete from "../../../elements/PopupDelete/PopupDelete";
import DropDownEditDelete from "../../dropDownEditDelete/main/DropDownEditDelete";
import useToggle from "../../../hooks/useToggle";
import useClickOutside from "../../../hooks/useClickOutside";
import ReusableInput from "../../../elements/moduleInputEdit/ReusableInput";
import { useStateContext } from "../../../contexts/ContextProvider";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useRecoilValue } from "recoil";
import { courseCreatorColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { toArabicDigits } from "../../../utils/formatDayArabic";

/**
 * Module component represents a single module within a course.
 * It facilitates the management of topics associated with the module,
 * allowing addition, deletion, and editing of topics.
 *
 * @param moduleIndex The index of the module in the course.
 * @param moduleId The unique identifier for the module.
 * @param moduleTitle The title of the module.
 * @param topicsArray The array of topics associated with the module.
 * @param module The object representing the module.
 * @param removeModule Function to remove the module.
 * @param setIsModuleIdInCourseCreator Function to set module ID in the course creator.
 * @param setIsModuleIndexInCourseCreator Function to set module index in the course creator.
 * @param isModuleDeleteClicked Boolean indicating whether module deletion is requested.
 * @param onAddOrDelete Function to reactivate the state of modules with all her new item updates.
 * @param editModule Function to edit the module.
 * @param setModuleTitleInCourseCreator Function to set the title of the module in the course creator.
 * @param newModuleTitle Title for a new module.
 * @param reorderedContentIndex Index of the reordered content.
 * @param activeGetModulesWithTopics Function to fetch modules with topics.
 */
const Module: FC<ModuleInterface> = ({
  moduleIndex,
  moduleId,
  moduleTitle,
  topicsArray,
  module,
  removeModule,
  setIsModuleIdInCourseCreator,
  setIsModuleIndexInCourseCreator,
  isModuleDeleteClicked,
  onAddOrDelete,
  editModule,
  setModuleTitleInCourseCreator,
  newModuleTitle,
  reorderedContentIndex,
  activeGetModulesWithTopics,
}) => {
  // Recoil state and selectors
  const {
    boxShadow,
    moduleBgColor,
    addTopicIconColor,
    lineIconColor,
    addModuleIconColor,
  } = useRecoilValue(courseCreatorColorSelector);
  const { t } = useTranslation();
  const language = localStorage.getItem("language");

  // State and context hooks
  const { getModules, isPopUpOpen, setIsPopUpOpen } = useStateContext();

  // Base URL for API requests
  const BaseURL =
    "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/class/module/topic";

  // Refs and state variables
  const { isOpen: isDropdownVisible, toggle: toggleDropdown } =
    useToggle(false);
  const dropdownRef = useClickOutside(isDropdownVisible, toggleDropdown);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  // Handlers for showing/hiding dropdown
  const handleShowDropDown = (showDropDown: boolean) => {
    setShowDropDown(showDropDown);
  };

  // State for module and topic IDs and indices
  const [isModuleId, setIsModuleId] = useState<number>();
  const [isTopicId, setIsTopicId] = useState<number>();
  const [isTopicIndex, setIsTopicIndex] = useState<number>();
  const [topics, setTopics] = useState<TopicProps[]>(module.topicsArray);
  const [isSetLoading, setIsSetLoading] = useState<boolean>(false);
  const [topicIndex, setTopicIndex] = useState<number>(0);

  const prevShowTopics = getModules();
  const [showTopic, setShowTopic] = useState<boolean>(
    module.moduleIndex === 1 || prevShowTopics.length != 0 ? true : false
  );

  // State for showing delete and edit module modals
  const [showDeleteModule, setShowDeleteModule] = useState(false);
  const [showEditModule, setShowEditModule] = useState(false);

  // Handlers for showing/hiding edit module modal
  const handleShowEditInModule = (showEditModule: boolean) => {
    setShowEditModule(showEditModule);
  };

  // Handlers for showing/hiding delete module modal
  const handleDeleteModule = (showDeleteModule: boolean) => {
    setShowDeleteModule(showDeleteModule);
    setIsPopUpOpen(true);
  };
  const handleEditModule = (showEditModule: boolean) => {
    setShowEditModule(showEditModule);
  };

  // Function to set topic ID in the module
  const setIsTopicIdInModule = (topicId: number) => {
    setIsTopicId(topicId);
  };

  // Function to set topic index in the module
  const setIsTopicIndexInModule = (topicIndex: number) => {
    setIsTopicIndex(topicIndex);
  };

  // State for new topic title
  const [newTopicTitle, setNewTopicTitle] = useState<string>(
    t("course view.Topic")
  );

  // Refs for module ID and index
  const isModuleIdRef = useRef<number | undefined>(undefined);
  const isModuleIndexRef = useRef<number | undefined>(undefined);

  // Effect to update module ID and index refs
  useEffect(() => {
    isModuleIdRef.current = moduleId;
    isModuleIndexRef.current = moduleIndex;
  }, [moduleId, moduleIndex]);

  // Function to generate a random topic ID
  const generateId = () => {
    return Math.floor(Math.random() * 10000) + 1;
  };

  // Function to create a new topic via API request
  const createTopic = async (path: string, body: any) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.post(`${BaseURL}/${path}/${tenantName}`, {
        tenantName: tenantName,
        data: body,
      });
      return response.data;
    } catch (error) {
      console.error("Error in POST request:", error);
    }
  };

  // Function to add a new topic to the module
  const addNewTopic = async (module: ModuleProps) => {
    setIsSetLoading(true);
    const topicId = generateId();
    let newIndex: number;
    if (isModuleId === module.moduleId) {
      newIndex = module.topicsArray[module.topicsArray.length - 1]?.topicIndex
        ? module.topicsArray[module.topicsArray.length - 1].topicIndex + 1
        : 1;
    } else {
      newIndex =
        module.moduleIndex === 1 && module.topicsArray.length == 1
          ? 2
          : module.topicsArray.length > 0
          ? module.topicsArray[module.topicsArray.length - 1].topicIndex + 1
          : 1;
    }

    const data = {
      topicIndex: newIndex,
      moduleRefId: module.moduleId,
      topicId: topicId,
      topicTitle: `${t("course view.Topic")} ${toArabicDigits((newIndex).toString())}`,
      contents: [],
    };
    await createTopic("create", data);
    await activeGetModulesWithTopics();
    setIsSetLoading(false);

    setTopicIndex(newIndex);
    setTopics([...topics, data]);
    module.topicsArray.push(data);
    onAddOrDelete();
  };

  // Function to edit the title of a topic via API request
  const editTopicTitle = async (path: string, body: any) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.patch(`${BaseURL}/${path}/${tenantName}`, {
        data: body,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PATCH request:", error);
      throw error; // Rethrow the error to be handled by the calling service
    }
  };

  // Function to edit a topic title
  const editTopic = async (topicId: number, newTitle: string) => {
    if (topicId !== undefined) {
      let newTopics = [...module.topicsArray];

      const data = {
        topicTitle: newTitle,
        topicId: topicId,
      };

      await editTopicTitle("title", data);

      const editedTopic = newTopics?.map((topic) =>
        topic?.topicId === topicId
          ? (topic.topicTitle = newTitle)
          : (topic.topicTitle = topic.topicTitle)
      );

      module.topicsArray = newTopics;
      setTopics(newTopics);
    }
    onAddOrDelete();
  };

  // Function to delete a topic via API request
  const deleteTopic = async (path: string, body: any) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.put(`${BaseURL}/${path}/${tenantName}`, {
        data: body,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
    }
  };

  // Function to remove a topic from the module
  const removeTopic = async (
    module: ModuleProps,
    topicId: number,
    topicIndex: number
  ) => {
    if (
      module.topicsArray[topicIndex - 1].topicIndex !==
      module.topicsArray.length
    ) {
      for (
        let i = module.topicsArray[topicIndex - 1].topicIndex;
        i < module.topicsArray.length;
        i++
      ) {
        module.topicsArray[i].topicIndex -= 1;
      }
    }

    if (topicId !== undefined) {
      const updatedTopics: TopicProps[] = module.topicsArray.filter(
        (topic) => topic.topicId !== topicId
      );

      const data = {
        idToDelete: topicId,
        payload: updatedTopics,
      };

      module.topicsArray = updatedTopics;
      await deleteTopic("delete", data);
      setTopics(updatedTopics);
    }
    onAddOrDelete();
  };

  // State for module title
  const [title, setTitle] = useState<string>(`${module.moduleTitle}`);

  // Handler for input change for module title
  const handleInputChange = (e: any) => {
    setTitle(e.target.value);
    setModuleTitleInCourseCreator(e.target.value);
  };

  // Function to set the topic title in the module
  const setTopicTitleInModule = (topicTitle: string) => {
    if (topicTitle.length === 0) {
      setNewTopicTitle(t("course view.Topic"));
    } else {
      setNewTopicTitle(topicTitle);
    }
  };

  // Effect to toggle edit module popup
  useEffect(() => {
    if (showEditModule) {
      setIsPopUpOpen(true);
    } else {
      setIsPopUpOpen(false);
    }
  }, [showEditModule]);

  return (
    <>
      {/* Draggable component for handling module drag and drop */}
      <Draggable
        draggableId={`Module-${moduleIndex}`}
        index={moduleIndex}
        isDragDisabled={isPopUpOpen} // Disables dragging when a popup is open
      >
        {/* Module container */}
        {(provided, snapshot) => (
          <ModuleContainer
            bgcolor={moduleBgColor}
            boxshadow={boxShadow}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              // Apply different styles when the item is being dragged
              border: snapshot.isDragging ? "1px solid #5dd3b3" : "none",
              boxShadow: snapshot.isDragging
                ? "0px 4px 20px 0px rgba(93, 211, 179, 0.25)"
                : boxShadow,
              ...provided.draggableProps.style,
            }}
          >
            {/* Module header */}
            <ModuleHeader>
              {showEditModule ? (
                // Editable input field for module title
                <ReusableInput
                  placeHolder={t("group.Enter Title")}
                  value={title}
                  handleInputChange={handleInputChange}
                  setShowEditInModule={handleShowEditInModule}
                  editModule={() => editModule(moduleId, newModuleTitle)}
                  moduleId={moduleId}
                  newModuleTitle={newModuleTitle}
                  setShowDropDown={handleShowDropDown}
                  toggleDropdown={toggleDropdown}
                />
              ) : (
                // Display module title with addTopic button and dropdown icon
                <>
                  <Typography
                    text={moduleTitle}
                    fontSize="0.875rem"
                    whiteSpace="nowrap"
                  />
                  <DivideLine lineiconcolor={lineIconColor}></DivideLine>

                  <AddTopicIconWrapper
                    color={addTopicIconColor}
                    disabled={isSetLoading}
                    onClick={() => {
                      setIsModuleId(isModuleIdRef.current);
                      setShowTopic(true);
                      addNewTopic(module);
                      if (isModuleIdRef.current !== undefined) {
                        setIsModuleIdInCourseCreator(isModuleIdRef.current);
                      }
                      if (isModuleIndexRef.current !== undefined) {
                        setIsModuleIndexInCourseCreator(
                          isModuleIndexRef.current + 1
                        );
                      }
                    }}
                  >
                    <SvgAddTopicIcon />
                  </AddTopicIconWrapper>
                  <HDotsWrapper
                    ref={dropdownRef}
                    onClick={() => {
                      toggleDropdown();
                      setShowDropDown(true);
                      setIsModuleId(module.moduleId);
                      if (isModuleIdRef.current !== undefined) {
                        setIsModuleIdInCourseCreator(isModuleIdRef.current);
                      }
                      if (isModuleIndexRef.current !== undefined) {
                        setIsModuleIndexInCourseCreator(
                          isModuleIndexRef.current + 1
                        );
                      }
                    }}
                  >
                    <SvgMoreHorizontalIcon />
                  </HDotsWrapper>
                </>
              )}
            </ModuleHeader>
            {/* Dropdown menu for editing or deleting module */}
            {isDropdownVisible && showDropDown && (
              <DropDownEditDelete
                setShowDeleteModule={handleDeleteModule}
                setShowEditModule={handleEditModule}
                setShowDropDown={handleShowDropDown}
              />
            )}
            {/* Confirmation popup for deleting module */}
            {showDeleteModule && removeModule && (
              <PopupDelete
                show={showDeleteModule}
                setShow={setShowDeleteModule}
                title={t(
                  "course view.Are you sure you want to delete this Module"
                )}
                handleSubmit={() => {
                  removeModule(moduleId, moduleIndex + 1);
                  setIsPopUpOpen(false);
                }}
              />
            )}

            {/* Wrapper for module topics */}
            <Droppable
              droppableId={`topicsContainer-${moduleIndex}`}
              type="topicsContainer"
              direction="vertical"
            >
              {(provided, snapshot) => (
                <TopicsContainer
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {module?.topicsArray?.length === 0 &&
                  isSetLoading === false ? (
                    <EmptyTopicsTextWrapper>
                      {language === "tr" ? (
                        <>
                          <PrimaryFontLightText
                            text={t("course creator.to add a topic")}
                            fontSize="0.75rem"
                          />
                          <AddTopicActiveWrapper>
                            <SvgAddTopicActiveBgIcon />
                            <SvgAddTopicIcon />
                          </AddTopicActiveWrapper>
                          <PrimaryFontLightText
                            text={t("course creator.Click on")}
                            fontSize="0.75rem"
                          />
                        </>
                      ) : (
                        <>
                          <PrimaryFontLightText
                            text={t("course creator.Click on")}
                            fontSize="0.75rem"
                          />
                          <AddTopicActiveWrapper>
                            <SvgAddTopicActiveBgIcon />
                            <SvgAddTopicIcon />
                          </AddTopicActiveWrapper>
                          <PrimaryFontLightText
                            text={t("course creator.to add a topic")}
                            fontSize="0.75rem"
                          />
                        </>
                      )}
                    </EmptyTopicsTextWrapper>
                  ) : (
                    <>
                      {module?.topicsArray
                        // Sort the topics by their topic index
                        ?.sort((a, b) => a.topicIndex - b.topicIndex)
                        .map((topic, index) => (
                          // Render each topic item within the module
                          <Topic
                            key={index}
                            topicIndex={index}
                            topicId={topic.topicId}
                            topicTitle={topic.topicTitle}
                            contents={topic.contents}
                            moduleRefId={module?.moduleId}
                            topic={topic}
                            topics={topics}
                            activeGetModulesWithTopics={
                              activeGetModulesWithTopics
                            }
                            reorderedContentIndex={reorderedContentIndex}
                            moduleIndex={moduleIndex}
                            removeTopic={() => {
                              if (
                                isTopicId !== undefined &&
                                isTopicIndex !== undefined
                              ) {
                                removeTopic(module, isTopicId, isTopicIndex);
                              }
                            }}
                            setTopics={setTopics}
                            setIsTopicIdInModule={setIsTopicIdInModule}
                            setIsTopicIndexInModule={setIsTopicIndexInModule}
                            module={module}
                            isModuleDeleteClicked={isModuleDeleteClicked}
                            onAddOrDelete={onAddOrDelete}
                            editTopic={() => {
                              if (isTopicId !== undefined) {
                                editTopic(isTopicId, newTopicTitle);
                              }
                            }}
                            newTopicTitle={newTopicTitle}
                            setTopicTitleInModule={setTopicTitleInModule}
                          />
                        ))}
                    </>
                  )}
                  {isSetLoading && <LoaderText />}
                  {provided.placeholder}
                </TopicsContainer>
              )}
            </Droppable>
          </ModuleContainer>
        )}
      </Draggable>
    </>
  );
};

export default Module;
