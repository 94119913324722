
export interface SurveyComplexStudent {
  studentId:string;
  StudentProfile?:string
  StudentName?:string
  Status:string;
  TimeSpent:string;
  Date?:Date;

}


interface SurveyComplexProfileTableInterface {
  Anonymous?:boolean;
  Students:{
  studentId:string;
  StudentProfile?:string
  StudentName?:string
  Status:string;
  TimeSpent:string;
  Date?:Date;
}[];
}

export const SurveyComplexProfileTableData: SurveyComplexProfileTableInterface =
{
  Anonymous:false,
  Students:[
  {studentId:"sfdg3456",
  StudentName:"Hadi Shouman",
  StudentProfile:require("../../../../assets/AliAhmad.png"),
  Status:"Responded",
  TimeSpent:"12 min",
  Date:new Date("2023-10-02T10:30:00Z"),
},
{studentId:"sfdr",
StudentName:"Hadi Sultan",
StudentProfile:require("../../../../assets/AliAhmad.png"),
Status:"Not Responded",
TimeSpent:"12 min",
// Date:new Date("2023-10-02T10:30:00Z"),
},
{studentId:"ssad",
StudentName:"Hadi Sultan",
StudentProfile:require("../../../../assets/AliAhmad.png"),
Status:"Responded",
TimeSpent:"12 min",
Date:new Date("2023-10-02T10:30:00Z"),
},
{studentId:"sfgthrh",
StudentName:"Hadi Sultan",
StudentProfile:require("../../../../assets/AliAhmad.png"),
Status:"Responded",
TimeSpent:"12 min",
// Date:new Date("2023-10-02T10:30:00Z"),
},
{studentId:"rgrvwetr",
StudentName:"Hadi Sultan",
StudentProfile:require("../../../../assets/AliAhmad.png"),
Status:"Not Responded",
TimeSpent:"12 min",
Date:new Date("2023-10-02T10:30:00Z"),
},
{studentId:"234432345432",
StudentName:"Hadi Sultan",
StudentProfile:require("../../../../assets/AliAhmad.png"),
Status:"Responded",
TimeSpent:"12 min",
Date:new Date("2023-10-02T10:30:00Z"),
},
{studentId:"wedrt5y65trfdsfcdsf",
StudentName:"Hassan Fawwaz",
StudentProfile:require("../../../../assets/AliAhmad.png"),
Status:"Not Responded",
TimeSpent:"12 min",
// Date:new Date("2023-10-02T10:30:00Z"),
},
{studentId:"ssasadasdfgtyhtr43ed",
StudentName:"Sultana Sultan",
StudentProfile:require("../../../../assets/AliAhmad.png"),
Status:"Responded",
TimeSpent:"12 min",
Date:new Date("2023-10-02T10:30:00Z"),
},
{studentId:"fgty654trdfcvgf",
StudentName:"Hadi Assaf",
StudentProfile:require("../../../../assets/AliAhmad.png"),
Status:"Responded",
TimeSpent:"12 min",
// Date:new Date("2023-10-02T10:30:00Z"),
},

]
};