import React, { useContext, useEffect, useState } from "react";
import {
  AdminCourseCardDetails,
  AdminCourseCardWrapper,
  AdminRoleButtons,
  CourseCardOverlay,
  TopHolder,
} from "./AdminCourseCard.style";
import { useRecoilState, useRecoilValue } from "recoil";
import { adminRoleState, languageState } from "../../../recoil/RecoilStates";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import {
  ColorSelector,
  IconsColorSelector,
} from "../../../recoil/ThemeSelectors";
import { fetchImage } from "../courseCardDetails/CourseCardDetailsFunctions";
import {
  CodeHolder,
  CourseHoverHolder,
  MaskGroup,
  MaskProfileDiv,
} from "../courseCardDetails/CourseCard.style";
import MainOnHover from "../courseCardHoverDetails/MainOnHover";
import { Link } from "react-router-dom";
import {
  MediumTypography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import { SvgAdminStudent, SvgAdminTeacher } from "../../../elements/Icons";
interface AdminCourseCardProps {
  id: string;
}
export default function AdminCourseCard({ id }: AdminCourseCardProps) {
  const { bData } = useContext(ClassContext);
  const [language] = useRecoilState(languageState);
  const [s3ImageUrl, setS3ImageUrl] = useState<string>("");
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [adminRole] = useRecoilState(adminRoleState);

  const {
    iconcolor,
    backgroundIconColor,
    iconHoveredcolor,
    backgroundIconHoveredColor,
  } = useRecoilValue(IconsColorSelector);
  const { backgroundLinearColor, ProfileGradient_BgColor_Both, theme } =
    useRecoilValue(ColorSelector);
  let course;
  course = bData.find((course: { id: any }) => course.id === id);
  const {
    title,
    courseCode,
    gradientColor,
    bgImage,
    teacherId,
    teacherProfile,
  } = course;
  useEffect(() => {
    const fetchDataAndImage = async () => {
      try {
        if (adminRole === "teacher") {
          const fetchedImage = await fetchImage(
            bgImage,
            setS3ImageUrl,
            setLoading
          );
          if (fetchedImage) {
            setS3ImageUrl(fetchedImage);
          }
        }
      } catch (error) {
        console.error("Error fetching data or image:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchDataAndImage();
  }, []);
  return (
    <AdminCourseCardDetails language={language}>
      {loading ? (
        <></>
      ) : (
        <CourseCardOverlay
          bgimage={s3ImageUrl || ""}
          bgcolor={backgroundLinearColor}
          role={adminRole}
          theme={theme}
          language={language}
        ></CourseCardOverlay>
      )}
      <AdminCourseCardWrapper role={adminRole} language={language}>
        <TopHolder>
          <Link to={`/courseView/about/${id}`}>
            <div style={{ height: "2rem" }}>
              <TypographyRegularDescription
                fontSize="1.125rem"
                text={title.length > 20 ? title.slice(0, 20) + ".." : title}
              />
            </div>
          </Link>
          {adminRole === "student" && (
            <div style={{ height: "2rem", cursor: "pointer" }}>
              {teacherId !== undefined &&
              teacherId !== null &&
              teacherId !== "none" &&
              teacherId !== "" ? (
                <MaskProfileDiv
                  bgcolor={ProfileGradient_BgColor_Both}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <MaskGroup
                    alt="Profile"
                    src={
                      teacherProfile !== undefined &&
                      teacherProfile !== null &&
                      teacherProfile !== ""
                        ? teacherProfile
                        : "./assets/images/userProfile.svg"
                    }
                    bordercolor={"#707070"}
                  />
                  <CourseHoverHolder
                    ishovered={isHovered.toString()}
                    language={language}
                  >
                    <MainOnHover
                      teacherId={teacherId}
                      profilePicture={
                        teacherProfile !== undefined &&
                        teacherProfile !== null &&
                        teacherProfile !== ""
                          ? teacherProfile
                          : "./assets/images/userProfile.svg"
                      }
                      classId={id}
                      isHovered={false}
                    />
                  </CourseHoverHolder>
                </MaskProfileDiv>
              ) : (
                <MaskProfileDiv
                  bgcolor={ProfileGradient_BgColor_Both}
                  style={{ cursor: "default" }}
                >
                  <MaskGroup
                    alt="Profile"
                    src={"./assets/images/userProfile.svg"}
                    bordercolor={"#707070"}
                  />
                </MaskProfileDiv>
              )}
            </div>
          )}
        </TopHolder>
        <Link to={`/courseView/about/${id}`}>
          {/* <div style={{ display: "flex", flexDirection: "column", }} > */}
          <div
            style={{
              display: "flex",
              // rowGap: "0.62rem",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CodeHolder
              gradientcolor={gradientColor || [""]}
              language={language}
            >
              <MediumTypography
                fontSize="0.75rem"
                fontWeight="400"
                text={courseCode}
                color="#fff"
              />
            </CodeHolder>

            {/* {adminRole === "student" && ( */}

            {/* )} */}
          </div>
          {/* {/* </div> */}
        </Link>
      </AdminCourseCardWrapper>
    </AdminCourseCardDetails>
  );
}
