import {
  ColorPickerHolder,
  ColorPickerSvgHolder,
  ColorPickerButton,
  ColorPickerLayer,
} from "./ColorPickerMenu.style";
import { ColorPickerData } from "./colorPickerData";
import { SvgColorPickerIcon } from "../../../elements/Icons";
import React, { useState, useRef, useEffect } from "react";
// import { useColor } from '../ColorContext';
// import {ColorPickerInterface} from "./colorPickerMenuInterface";
interface ColorPickerMenuProps {
  onColorChange: (color: string) => void;
  // selectedColor:string
}
const ColorPickerMenu: React.FC<ColorPickerMenuProps> = ({ onColorChange }) => {
  const [localSelectedColor, setLocalSelectedColor] = useState("");
  const [listColor, setListColor] = useState<number[]>([1, 2, 3, 4, 5]);
  const ref = useRef<HTMLDivElement | null>(null);
  const handleToggleClasslistRef = (ref: React.RefObject<HTMLDivElement>) => {
    if (!ref.current) {
      return;
    }
    if (!ref.current.classList.contains("big-border")) {
      ref.current.classList.add("big-border");
    } else {
      ref.current.classList.remove("big-border");
      // ref.current = null;
    }
  };
  // useEffect(() => {
  //   const handleOutsideClick = (event: MouseEvent) => {
  //     handleToggleClasslistRef(ref);
  //   };
  //   const element = document.getElementById("wrapper");
  //   element?.addEventListener("click", handleOutsideClick);
  //   return () => {
  //     element?.removeEventListener("click", handleOutsideClick);
  //   };
  // }, []);
  // const { borderColor, changeBorderColor } = useState('');
  // const handleChangeColor = (color:string) => {
  //   changeBorderColor(color);
  // };
  const handleRadioButtonClick = (color: string) => {
    setLocalSelectedColor(color);
    // Pass the selected color to the parent component via the onColorChange prop
    onColorChange(color);
  };
  return (
    <ColorPickerHolder>
      <ColorPickerButton className="color1">
        <input
          type="radio"
          name="color"
          value="#5DC5D3"
          onChange={() => handleRadioButtonClick("#5DC5D3")}
        />
        <ColorPickerLayer className="layer" />
        <span />
      </ColorPickerButton>

      <ColorPickerButton className="color2">
        <input
          type="radio"
          name="color"
          value="#3DA4FC"
          onChange={() => handleRadioButtonClick("#3DA4FC")}
        />
        <ColorPickerLayer className="layer" />
        <span />
      </ColorPickerButton>
      <ColorPickerButton className="color3">
        <input
          type="radio"
          name="color"
          value="#5D69FD"
          onChange={() => handleRadioButtonClick("#5D69FD")}
        />
        <ColorPickerLayer className="layer" />
        <span />
      </ColorPickerButton>
      <ColorPickerButton className="color4">
        <input
          type="radio"
          name="color"
          value="#8E5DFD"
          onChange={() => handleRadioButtonClick("#8E5DFD")}
        />
        <ColorPickerLayer className="layer" />
        <span />
      </ColorPickerButton>
      <ColorPickerButton className="color5">
        <input
          type="radio"
          name="color"
          value="#B757E2"
          onChange={() => handleRadioButtonClick("#B757E2")}
        />
        <ColorPickerLayer className="layer" />
        <span />
      </ColorPickerButton>
      <ColorPickerButton className="color6">
        <input
          type="radio"
          name="color"
          value="#DB56A3"
          onChange={() => handleRadioButtonClick("#DB56A3")}
        />
        <ColorPickerLayer className="layer" />
        <span />
      </ColorPickerButton>
      <ColorPickerButton className="color7">
        <input
          type="radio"
          name="color"
          value="#D04B4B"
          onChange={() => handleRadioButtonClick("#D04B4B")}
        />
        <ColorPickerLayer className="layer" />
        <span />
      </ColorPickerButton>
      <ColorPickerButton className="color8">
        <input
          type="radio"
          name="color"
          value="#D07E4B"
          onChange={() => handleRadioButtonClick("#D07E4B")}
        />
        <ColorPickerLayer className="layer" />
        <span />
      </ColorPickerButton>
      <ColorPickerButton className="color9">
        <input
          type="radio"
          name="color"
          value="#D0C64B"
          onChange={() => handleRadioButtonClick("#D0C64B")}
        />
        <ColorPickerLayer className="layer" />
        <span />
      </ColorPickerButton>
      <ColorPickerButton className="color10">
        <input
          type="radio"
          name="color"
          value="#58BE5B"
          onChange={() => handleRadioButtonClick("#58BE5B")}
        />
        <ColorPickerLayer className="layer" />
        <span />
      </ColorPickerButton>

      <label>
        <ColorPickerButton className="none">
          <input
            type="color"
            name="color"
            value=""
            onChange={(e) => handleRadioButtonClick(e.target.value)}
          />
          <ColorPickerSvgHolder>
            <SvgColorPickerIcon />
          </ColorPickerSvgHolder>
          <ColorPickerLayer className="layer" />
          <span />
        </ColorPickerButton>
      </label>
    </ColorPickerHolder>
  );
};

export default ColorPickerMenu;
