import {
  MainHolder,
  TitleWithIconsHolder,
  IconsWrapper,
  Description,
  ProfileDateHolder,
  DateHolder,
  DivideLine,
} from "./AnnouncementsCardDetail.style";
import { AnnouncementsCardDetailInterface } from "./AnnouncementsCardDetailInterface";
import { FC } from "react";
import {
  SvgSaveAnnouncements,
  SvgShareAnnouncements,
} from "../../elements/Icons";
import {
  Typography,
  LightTypography,
  PrimaryFontMediumText,
} from "../../elements/fonts/Fonts";
import ProfileNameEmail from "../../elements/profileNameEmail/ProfileNameEmail";
import DOMPurify from "dompurify";
import { formatDate } from "../announcementsCard/AnnouncmentCardUtils";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

const AnnouncementsCardDetail: FC<AnnouncementsCardDetailInterface> = ({
  title,
  description,
  date,
  fullName,
  email,
  iconPath,
  iconBg,
  top,
  left,
  border,
  width,
  height,
  widthImgHolder,
  heightImgHolder,
  userId
}) => {
  const { t } = useTranslation();

  // const formatDate = (createdDate: string) => {
  //   const currentDate = new Date();
  //   const announcementDate = new Date(createdDate);
  //   const timeDifference = currentDate.getTime() - announcementDate.getTime();
  //   const minutesDifference = Math.floor(timeDifference / (1000 * 60));

  //   if (minutesDifference < 1) {
  //     return "Now";
  //   } else if (minutesDifference < 60) {
  //     return `${minutesDifference} minute${minutesDifference > 1 ? 's' : ''} ago`;
  //   } else if (minutesDifference < 1440) { // 1440 minutes in a day (24 hours)
  //     const hoursDifference = Math.floor(minutesDifference / 60);
  //     return `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} ago`;
  //   } else if (minutesDifference < 2880) { // 2880 minutes in two days
  //     return `Yesterday at ${announcementDate.toLocaleTimeString("en-US", {
  //       hour: "2-digit",
  //       minute: "2-digit",
  //     })}`;
  //   } else {
  //     return announcementDate.toLocaleString();
  //   }
  // };

  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };

  const {
    SecondaryTextColor,
    bghelp,
    theme,
    SecondaryWhiteTextColor,
    ProfileGradient_BgColor_Both,
  } = useRecoilValue(ColorSelector);
  return (
    <MainHolder>
      <TitleWithIconsHolder>
        <Typography text={title} color={SecondaryWhiteTextColor} />
        {/* <IconsWrapper>
          <SvgSaveAnnouncements />
          <SvgShareAnnouncements />
        </IconsWrapper> */}
      </TitleWithIconsHolder>
      <Description
        style={{
          color: SecondaryWhiteTextColor,
          opacity: theme === "light" ? "0.8" : "1",
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(decodeHtml(description)),
          }}
          style={
            {
              // fontSize:"0.875rem",
              // lineHeight:"1.375rem",
              // color:"rgba(255, 255, 255, 0.50)"
            }
          }
        ></div>
        {/* <LightTypography
          text={description}
          fontSize="0.875rem"
          lineHeight="1.375rem"
          color="rgba(255, 255, 255, 0.50)"
        /> */}
      </Description>
      <ProfileDateHolder>
        <ProfileNameEmail
          ImageBox_BgColor={ProfileGradient_BgColor_Both}
          iconPath={iconPath}
          iconBg={iconBg}
          top={top}
          left={left}
          border={border}
          width={width}
          height={height}
          widthImgHolder={widthImgHolder}
          heightImgHolder={heightImgHolder}
          fullName={fullName ? fullName : ""}
          email={email ? email : ""}
          userId={userId}
        />
        <DateHolder>
          <PrimaryFontMediumText
            text={formatDate(date, t)}
            fontSize="0.75rem"
          />
        </DateHolder>
      </ProfileDateHolder>
      {/* <DivideLine></DivideLine> */}
    </MainHolder>
  );
};

export default AnnouncementsCardDetail;
