import { PointsPro } from "./SurverLikertInterface";

export const PointsProData: PointsPro[] = [
  {
    id: 1,
    name: 'Strongly Disagree',
    title: 'survey.Strongly Disagree',
  },
  {
    id: 2,
    name: 'Disagree',
    title: 'survey.Disagree',
  },
  {
    id: 3,
    name: 'Neutral',
    title: 'survey.Neutral',
  },
  {
    id: 4,
    name: 'Agree',
    title: 'survey.Agree',
  },
  {
    id: 5, 
    name: 'Strongly Agree',
    title: 'survey.Strongly Agree',
  },
];
