import React from "react";
import { FlexAppContainer } from "../../App.style";
import { SvgBackArrow, SvgSimpleArrowLeft } from "../Icons";
import { Typography } from "../fonts/Fonts"; // Assuming TextTransform is imported from "../fonts/Fonts"
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useStateContext } from "../../contexts/ContextProvider";
import { BackArrow } from "./TitleHeaderTextIconNavigator.style";
interface TitleHeaderTextIconNavigatorInterface {
  title: string;
  navigateTo?: any;
  textTransform?: any; // Change string to TextTransform
  fontSize?: string;
  svgWidth?: number;
  svgHeight?: number;
}

const TitleHeaderTextIconNavigator = ({
  title,
  navigateTo,
  textTransform = "capitalize", // Providing a default value is still okay
  fontSize = "1.75rem",
  svgWidth = 12,
  svgHeight = 18,
}: TitleHeaderTextIconNavigatorInterface) => {
  const navigate = useNavigate();
  const { setIsPopUpOpen } = useStateContext();

  const handleNavigate = async (navigateTo?: string) => {
    if (navigateTo) {
      await navigate(navigateTo);
      await setIsPopUpOpen(true);
    } else {
      navigate(-1);
    }
  };
  const { SecondaryTextColor, theme } = useRecoilValue(ColorSelector);
  return (
    <FlexAppContainer
      gap="0.63rem"
      alignItems="center"
      pointer={true}
      onClick={async () => {
        await handleNavigate(navigateTo);
      }}
    >
      <BackArrow>
        <SvgBackArrow color="#5dd3b3" width={svgWidth} height={svgHeight} />
      </BackArrow>
      <div style={{ textTransform: textTransform }}>
        <Typography
          text={title}
          fontSize={fontSize}
          lineHeight="1.75rem"
          color={SecondaryTextColor}
        />
      </div>
    </FlexAppContainer>
  );
};

export default TitleHeaderTextIconNavigator;
