import React, { useEffect, useState } from "react";
import { DataArrayProps } from "../../../interfaces/DataArray";
import { AnalyticsForumContainer } from "../AnalyticsForum.style";
import PieText from "../../Charts/PieText/PieText";
import PieWithText from "../../Charts/PieWithText/PieWithText";

const AnalyticsForumPieChart = ({ ArrayData }: DataArrayProps) => {
  const [PieChartData, setPieChartData] = useState([
    {
      text: "Attempted",
      attendance: 0,
      bgColor: "#5DD3B3",
    },
    {
      text: "Unattempted",
      attendance: 0,
      bgColor: "#0B8FFF",
    },
  ]);
  // useEffect to update PieChartData on component mount
  useEffect(() => {
    // Calculate attempted count
    const attemptedCount = ArrayData.filter(
      (item) => item.Posts && item.Posts > 0
    ).length;

    // Calculate unattempted count
    const unattemptedCount = ArrayData.filter(
      (item) => !item.Posts || item.Posts === 0
    ).length;

    // Update PieChartData state with new data
    setPieChartData([
      {
        text: "Attempted",
        attendance: attemptedCount,
        bgColor: "#5DD3B3",
      },
      {
        text: "Unattempted",
        attendance: unattemptedCount,
        bgColor: "#0B8FFF",
      },
    ]);
  }, []);
  return (
    <AnalyticsForumContainer style={{ flexDirection: "row" }}>
      <PieWithText
        data={PieChartData}
        value={"attendance"}
        getColor={(entry) => entry.bgColor as string}
        Width="63%"
        NormalTextDisplay={false}
        OuterRadius={130}
        InnerRadius={40}
      />

      {/* PieText Component */}
      <PieText LoopName={PieChartData} />
    </AnalyticsForumContainer>
  );
};

export default AnalyticsForumPieChart;
