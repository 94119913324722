import React, { Dispatch, SetStateAction, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DatePickerContainerStyled } from "./DateBoxPopUp.style";

interface CustomDatePickerInterface {
  selectedDate: Date | null;
  setSelectedDate: Dispatch<SetStateAction<Date | null>>;
  DefaultDate?: Date | null;
  setDefaultDate: Dispatch<SetStateAction<Date | null>>;
  height?: string;
  DataBaseStartDate?: Date;
}

const CustomDatePicker: React.FC<CustomDatePickerInterface> = ({
  selectedDate,
  DataBaseStartDate,
  setSelectedDate,
  DefaultDate,
  setDefaultDate,
  height = "fitContent",
}) => {

  return (
    <DatePickerContainerStyled>
      <DatePicker
        selected={selectedDate || DataBaseStartDate || null}
        onChange={(date: Date | null) =>
          setDefaultDate && setDefaultDate(date as Date | null)
        }
        minDate={DataBaseStartDate}
        open={true}
      />
    </DatePickerContainerStyled>
  );
};

export default CustomDatePicker;
