import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AnalyticsPageStylesLayout } from "../../AnalyticsPageStyles";
import AnalyticHeaderManyDisplays from "../../../../components/AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticHeaderManyDisplays";
import ForumParticipationDetailsMain from "../../../../components/AnalyticComplexSelectedTables/ForumParticipationDetails/ForumParticipationDetailsMain/ForumParticipationDetailsMain";
import { Typography } from "../../../../elements/fonts/Fonts";
import AnalyticOneTitleHeader from "../../../../components/AnalyticShadowHeaderFourDisplays/AnalyticOneTitleHeader";
import {
  ForumDetailsContentContainer,
  ForumDetailsRowContainer,
} from "./ForumDetails.styles";
import { FPDetailsTableData } from "../../../../components/AnalyticComplexSelectedTables/ForumParticipationDetails/ForumParticipationDetailsTable/ForumParticipationDetailsTableHeaderData";
import AnalyticsTopParticipantBox from "../../../../components/AnalyticsTopParticipantForum/AnalyticsTopParticipantBox";
import AnalyticsForumProgressBar from "../../../../components/AnalyticsForum/AnalyticsForumProgreesBar/AnalyticsForumProgreesBar";
import AnalyticsForumPieChart from "../../../../components/AnalyticsForum/AnalyticsForumPieChart/AnalyticsForumPieChart";
import { FlexAppContainer } from "../../../../App.style";
import AnalyticsAssignmentBarChart from "../../../../components/AnalyticsAssigmentResponseTraking/AnalyticsAssignmentBarChart/AnalyticsAssignmentBarChart";
import AnalyticsAssignmentGraphChart from "../../../../components/AnalyticsAssigmentResponseTraking/AnalyticsAssigmentGraphChart/AnalyticsAssigmentGraphChart";
import AnalyticsBackPage from "../../../../components/AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticsBackPage";

const ForumDetails = () => {
  // Extracting MainCode from URL parameters
  const { MainCode } = useParams<{ MainCode: string }>();

  // State variables to manage visibility of different components
  const [ShowParticipationTracking, setShowParticipationTracking] =
    useState(false);
  const [ShowParticipationDetailsTable, setShowParticipationDetailsTable] =
    useState(false);
  const [ShowAnalyticsFormGraphChart, setShowAnalyticsFormGraphChart] =
    useState(false);

  // Toggle functions to show/hide components
  const handleShowParticipationTracking = () => {
    setShowParticipationTracking(!ShowParticipationTracking);
  };

  const handleShowParticipationDetailsTable = () => {
    setShowParticipationDetailsTable(!ShowParticipationDetailsTable);
  };

  const handleShowAnalyticsFormGraphChart = () => {
    setShowAnalyticsFormGraphChart(!ShowAnalyticsFormGraphChart);
  };

  // Data related to students' participation
  const StudentsData = FPDetailsTableData.Students;
  const TotalNumberOfStudents = FPDetailsTableData.Students.length;

  const ParticipationStudents =
    FPDetailsTableData.Students?.filter(
      (item) => item.Last_Participation && item.Last_Participation !== null
    )?.length || 0;

  const UnParticipationStudents =
    FPDetailsTableData.Students?.filter(
      (item) => !item.Last_Participation || item.Last_Participation === null
    )?.length || 0;

  const PostedStudents = FPDetailsTableData.Students?.filter(
    (item) => item.Posts && item.Posts > 0
  ).length;

  const RepliedStudents = FPDetailsTableData.Students?.filter(
    (item) => item.Replies && item.Replies > 0
  ).length;

  const AverageParticipation = parseFloat(
    (PostedStudents / RepliedStudents).toFixed(2)
  );
  const Today = new Date();
  const navigate = useNavigate();
  const NavigatePage = () => {
    navigate(-1);
  };
  return (
    <AnalyticsPageStylesLayout>
      <AnalyticsBackPage onClick={NavigatePage} />
      {/* Title */}
      <Typography text="Forum Analytics" fontSize="1.75rem" />

      {/* Header with analytics data */}
      <AnalyticHeaderManyDisplays
        Posts={PostedStudents}
        Replies={RepliedStudents}
        Participation={`${ParticipationStudents} of ${TotalNumberOfStudents}`}
        AveragePostsPerStudent={AverageParticipation || 0.3}
        Anonymous={FPDetailsTableData.Anonymous || true}
        Graded={FPDetailsTableData.Graded || false}
      />

      {/* Conditional rendering based on ShowParticipationTracking state */}
      {ShowParticipationTracking ? (
        // Component with participation tracking details
        <FlexAppContainer gap="1.25rem" column={true}>
          {/* Toggle button */}
          <FlexAppContainer
            pointer={true}
            width="fit-content"
            onClick={handleShowParticipationTracking}
          >
            <Typography text="Participation Tracking" fontSize="1.5rem" />
          </FlexAppContainer>
          {/* Components for participation tracking */}
          <ForumDetailsRowContainer>
            <ForumDetailsContentContainer>
              <AnalyticsForumPieChart ArrayData={FPDetailsTableData.Students} />
            </ForumDetailsContentContainer>
            <ForumDetailsContentContainer>
              <AnalyticsForumProgressBar
                MaxNumber={TotalNumberOfStudents}
                PostsNumber={PostedStudents}
                ReliesNumber={RepliedStudents}
                NotParticipateNumber={UnParticipationStudents}
              />
            </ForumDetailsContentContainer>
            <ForumDetailsContentContainer>
              <AnalyticsTopParticipantBox />
            </ForumDetailsContentContainer>
          </ForumDetailsRowContainer>
        </FlexAppContainer>
      ) : (
        // Toggle button if participation tracking is not shown
        <AnalyticOneTitleHeader
          Title="Participation Tracking"
          onClick={handleShowParticipationTracking}
        />
      )}

      {/* Conditional rendering based on ShowParticipationDetailsTable state */}
      {ShowParticipationDetailsTable ? (
        // Component with participation details table
        <ForumParticipationDetailsMain
          ArrayData={StudentsData}
          Graded={FPDetailsTableData.Graded}
          Anonymous={FPDetailsTableData.Anonymous}
          HeaderTextClick={handleShowParticipationDetailsTable}
          ForumCutOffEDate={FPDetailsTableData.CutOffDate || Today}
          ForumDueDate={FPDetailsTableData.DueDate || Today}
        />
      ) : (
        // Toggle button if participation details table is not shown
        <AnalyticOneTitleHeader
          Title="Submission Tracking"
          onClick={handleShowParticipationDetailsTable}
        />
      )}

      {/* Conditional rendering based on ShowAnalyticsFormGraphChart state */}
      {FPDetailsTableData.Graded && (
        <>
          {ShowAnalyticsFormGraphChart ? (
            // Components for analytics form graph chart
            <FlexAppContainer column={true} gap="1.25rem" padding="0 0 2rem 0">
              <AnalyticsAssignmentBarChart
                Data={FPDetailsTableData.Students}
                title="Grade Distribution"
                titleClick={handleShowAnalyticsFormGraphChart}
              />
              <AnalyticsAssignmentGraphChart
                title="Grade Distribution"
                titleClick={handleShowAnalyticsFormGraphChart}
                Data={FPDetailsTableData.Students}
                MaxGrade={FPDetailsTableData.MaxGrade || 20}
              />
            </FlexAppContainer>
          ) : (
            // Toggle button if analytics form graph chart is not shown
            <AnalyticOneTitleHeader
              Title="Participation Tracking"
              onClick={handleShowAnalyticsFormGraphChart}
            />
          )}
        </>
      )}
    </AnalyticsPageStylesLayout>
  );
};

export default ForumDetails;
