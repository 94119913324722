import React, { useContext, useEffect, useRef, useState } from "react";
import {
  PageHeaderHolder,
  DropdownMenuPosition,
  TextHolder,
  ArrowHolder,
  ProfileHolder,
  MaskGroup,
  IconsHolder,
  ArrowStyle,
  MenuScreen,
  OverlapGroup,
  BigTitleStyle,
  SearchInput,
  TextHeader,
  LxpButton,
  PageHeaderPosition,
  SidebarIcon,
  Logomobile,
  SearchIconHolder,
  RightIconsHolder,
  NotificationPosition,
  SidebarMobilePosition,
  UserNameStyle,
  RedCircle,
} from "./PageHeader.style";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  TypographyRegularDescription,
  Typography,
} from "../../elements/fonts/Fonts";
import {
  SvgNotificationIcon,
  SvgSidebarIcon,
  SvgSearchIcon,
  SvgLxpIcon,
  SvgPolygonDown,
  SvgAdminStudent,
  SvgAdminTeacher,
} from "../../elements/Icons";
import { useLocation } from "react-router-dom";
import DropDownMenu from "../droprDownMenu/main/DropDownMenu";
import useClickOutside from "../../hooks/useClickOutside";
import useToggle from "../../hooks/useToggle";
import SearchElement from "../../elements/searchElement/SearchElement";
import SidebarMobile from "../sidebar/sidebarMobile/SidebarMobile";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0ProviderSplitter,
  auth0SubIdSplitter,
} from "../../utils/StringManipulation";
import { get } from "../../utils/AxiosRequests";
import PageHeaderMessage from "./PageHeaderMessage/PageHeaderMessage";
import { useRecoilState, useRecoilValue } from "recoil";
import { IconsColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import Notification from "../notificationDrop/main/Notification";
import LanguageComponent from "../languageComponent/LanguageComponent";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import axios from "axios";
import { WebsocketContext } from "../../contexts/notificationContext";
import { adminRoleState, adminState } from "../../recoil/RecoilStates";
import { toArabicDigits } from "../../utils/formatDayArabic";

interface Props {
  HeaderText1?: string;
  HeaderText2?: string;
  Profile?: string;
  openMessageComponent?: () => void;
  closeMessageComponent?: () => void;
}

export const PageHeader = ({
  HeaderText1,
  HeaderText2,
  Profile,
  openMessageComponent,
  closeMessageComponent,
}: Props): JSX.Element => {
  const { isOpen: isDropdownVisible, toggle: toggleDropdown } =
    useToggle(false);
  const { isOpen: isNotificationVisible, toggle: toggleNotification } =
    useToggle(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [ProfilePic, setProfilePic] = useState<string>("");
  const [ShowMessagePOPUP, setShowMessagePOPUP] = useState(false);
  const [showLanguage, setShowLanguage] = useState(false);
  const [isAdmin, setIsAdmin] = useRecoilState(adminState);
  const [adminRole, setAdminRole] = useRecoilState(adminRoleState);

  const getAdminState = async () => {
    try {
      const adminState = await axios.get(
        `https://9z6xeihi9l.execute-api.eu-west-1.amazonaws.com/dev/auth/isAdmin/${tenantName}/${subIdSplitted}?provider=${provider}`
      );
      localStorage.setItem("adminState", adminState.data.data);
      setIsAdmin(adminState.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const { setIsPopUpOpen } = useStateContext();
  const location = useLocation();
  const {
    iconcolor,
    backgroundIconColor,
    iconHoveredcolor,
    backgroundIconHoveredColor,
    backgroundImageColor,
  } = useRecoilValue(IconsColorSelector);

  const openSidebar = () => {
    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };
  const handleShowMessagePOPUP = () => {
    if (location.pathname !== "/messages") {
      // setShowMessagePOPUP(!ShowMessagePOPUP);
    }
  };

  const dropdownRef = useClickOutside(isDropdownVisible, toggleDropdown);
  const NotificationRef = useClickOutside(
    isNotificationVisible,
    toggleNotification
  );

  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const [FirstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const tenantName = localStorage.getItem("tenant");
  const [screenSize, setScreenSize] = useState<number>(window.innerWidth);
  const {
    unviewedNotificationData,
    setNotificationData,
    setUnviewedNotificationData,
    getUnvewedAnnouncmentCount,
  } = useContext(ClassContext);
  const { isReady, value, send } = useContext(WebsocketContext);

  const fetchUnviewedNotification = async () => {
    if (
      tenantName !== null &&
      tenantName !== undefined &&
      subIdSplitted !== null &&
      subIdSplitted !== undefined
    ) {
      try {
        const unviewedNotificationResponse = await axios.get(
          `https://nfo4qku4p7.execute-api.eu-west-1.amazonaws.com/dev/get/unviewed/${subIdSplitted}/${tenantName}?provider=${provider}`
        );
        setUnviewedNotificationData(unviewedNotificationResponse.data.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchAllNotifications = async () => {
    if (
      tenantName !== null &&
      tenantName !== undefined &&
      subIdSplitted !== null &&
      subIdSplitted !== undefined
    ) {
      try {
        let allNotification: any[] = [];
        let currentPage = 1;
        const limit = 50;

        while (true) {
          const response = await axios.get(
            `https://nfo4qku4p7.execute-api.eu-west-1.amazonaws.com/dev/get/all/${subIdSplitted}/${tenantName}/${currentPage}/${limit}?provider=${provider}`
          );

          // Append the users from the current page to the allUsers array
          allNotification = allNotification.concat(response.data.data.data);
          setNotificationData(allNotification);

          // Check if we have fetched all pages
          if (currentPage >= response.data.data.totalPages) {
            break;
          }

          currentPage++;
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    var data = JSON.parse(value);
    // console.log("Recieved Message---->",data)

    if (data && data.type && data.type === "announcement") {
      fetchUnviewedNotification();
      fetchAllNotifications();
      if (
        tenantName !== null &&
        tenantName !== undefined &&
        subIdSplitted !== null &&
        subIdSplitted !== undefined
      ) {
        getUnvewedAnnouncmentCount();
      }
    } else if (data && data.type && data.type === "content") {
      fetchUnviewedNotification();
      fetchAllNotifications();
    } else if (data && data.type && data.type === "missingAttendance") {
      fetchUnviewedNotification();
      fetchAllNotifications();
    } else if (data && data.type && data.type === "missingSubmit") {
      fetchUnviewedNotification();
      fetchAllNotifications();
    }
  }, [value]);

  useEffect(() => {
    fetchUnviewedNotification();
  }, [isNotificationVisible, tenantName, subIdSplitted]);
  useEffect(() => {
    fetchAllNotifications();
  }, [tenantName, subIdSplitted]);

  const updateScreenSize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    if (
      tenantName !== null &&
      tenantName !== undefined &&
      subIdSplitted !== null &&
      subIdSplitted !== undefined
    ) {
      getUnvewedAnnouncmentCount();
      getAdminState();
    }
  }, [tenantName, subIdSplitted]);
  // const { tenantName, updateTenantName } = useTenantContext();
  useEffect(() => {
    const getuserProfileById = async () => {
      if (tenantName) {
        if (tenantName !== null && tenantName !== "") {
          try {
            const userProfileToDisplay = await get(
              `user/profile/${subIdSplitted}/${tenantName}`,
              provider
            );
            if (userProfileToDisplay.data) {
              setProfilePic(userProfileToDisplay.data.profilePicture);
              setFirstName(userProfileToDisplay.data.fName);
              setLastName(userProfileToDisplay.data.lName);
              setLoading(false);
            }
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        }
      }
    };

    getuserProfileById();
    const handleResize = () => {
      updateScreenSize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const { t } = useTranslation();
  return (
    <PageHeaderPosition>
      <PageHeaderHolder>
        <TextHolder>
          <LxpButton>
            {" "}
            <SvgLxpIcon />{" "}
          </LxpButton>
          <SidebarMobilePosition>
            {isSidebarOpen && (
              <SidebarMobile
                closeSidebar={closeSidebar}
                closeB={true}
                bgColor={backgroundIconColor}
              />
            )}
          </SidebarMobilePosition>

          <SidebarIcon onClick={openSidebar}>
            <OverlapGroup
              color={iconcolor}
              bgcolor={backgroundIconColor}
              hovercolor={iconHoveredcolor}
              bghovercolor={backgroundIconHoveredColor}
            >
              <SvgSidebarIcon />
            </OverlapGroup>
          </SidebarIcon>

          <Logomobile src="/assets/icons/logo_Icon.svg" alt="" />
          <TextHeader>
            <BigTitleStyle>
              <TypographyRegularDescription
                whiteSpace="nowrap"
                fontSize="1.75rem"
                text={t("dashboard.Welcome")}
              />
            </BigTitleStyle>
            <UserNameStyle>
              {loading ? (
                <p>...</p>
              ) : (
                <Typography
                  fontSize="1.75rem"
                  lineHeight="2rem"
                  text={
                    `${FirstName} ${
                      window.innerWidth >= 918 ? lastName : ""
                    }` ||
                    user?.nickname ||
                    "User"
                  }
                />
              )}
            </UserNameStyle>
          </TextHeader>
        </TextHolder>

        <SearchInput>{/* <SearchElement /> */}</SearchInput>
        <RightIconsHolder>
          {(location.pathname === "/" ||
            location.pathname === "/dashboard" ||
            location.pathname === "/Allcourses") &&
            isAdmin === true && (
              <MenuScreen
                onClick={() => {
                  if (adminRole === "teacher") {
                    setAdminRole("student");
                    localStorage.setItem("adminRoleState", "student");
                  } else {
                    setAdminRole("teacher");
                    localStorage.setItem("adminRoleState", "teacher");
                  }
                }}
              >
                <OverlapGroup
                  color={iconcolor}
                  bgcolor={backgroundIconColor}
                  hovercolor={iconHoveredcolor}
                  bghovercolor={backgroundIconHoveredColor}
                >
                  {adminRole === "teacher" ? (
                    <SvgAdminTeacher />
                  ) : (
                    <SvgAdminStudent />
                  )}
                </OverlapGroup>
              </MenuScreen>
            )}
          {location.pathname === "/" ||
            (location.pathname === "/dashboard" && (
              <MenuScreen
                onClick={() => {
                  setShowLanguage(true);
                  setIsPopUpOpen(true);
                }}
              >
                <OverlapGroup
                  color={iconcolor}
                  bgcolor={backgroundIconColor}
                  hovercolor={iconHoveredcolor}
                  bghovercolor={backgroundIconHoveredColor}
                >
                  <Typography text={t("language.ln")} />
                </OverlapGroup>
              </MenuScreen>
            ))}
          {/* <LanguageToggle />  */}
          {/* <MenuScreen >
            <OverlapGroup
              // onClick={handleShowMessagePOPUP}
              color={iconcolor}
              bgColor={backgroundIconColor}
              hoverColor={iconHoveredcolor}
              bgHoverColor={backgroundIconHoveredColor}
            >
              <SvgMessageIcon onClick={openMessageComponent} />
            </OverlapGroup>
          </MenuScreen> */}

          {isAdmin === false && (
            <div ref={NotificationRef}>
              <IconsHolder>
                <MenuScreen ref={NotificationRef}>
                  <OverlapGroup
                    color={iconcolor}
                    bgcolor={backgroundIconColor}
                    hovercolor={iconHoveredcolor}
                    bghovercolor={backgroundIconHoveredColor}
                    onClick={toggleNotification}
                  >
                    <SvgNotificationIcon />
                    {unviewedNotificationData &&
                    unviewedNotificationData.length > 0 ? (
                      <RedCircle>
                        <Typography
                          color="#fff"
                          fontSize="0.5rem"
                          text={toArabicDigits(
                            unviewedNotificationData.length.toString()
                          )}
                        />
                      </RedCircle>
                    ) : (
                      ""
                    )}
                  </OverlapGroup>
                </MenuScreen>
              </IconsHolder>
              <NotificationPosition>
                {" "}
                {isNotificationVisible && (
                  <Notification
                    unviewedNotificationData={unviewedNotificationData}
                    setShowNotification={toggleNotification}
                  />
                )}{" "}
              </NotificationPosition>
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.25rem",
            }}
          >
            <DropdownMenuPosition>
              {isDropdownVisible && <DropDownMenu />}
            </DropdownMenuPosition>
            {/* <SearchIconHolder>
              <OverlapGroup
                color={iconcolor}
                bgColor={backgroundIconColor}
                hoverColor={iconHoveredcolor}
                bgHoverColor={backgroundIconHoveredColor}
              >
                <SvgSearchIcon />
              </OverlapGroup>
            </SearchIconHolder> */}
            <div
              ref={dropdownRef}
              onClick={toggleDropdown}
              style={{
                display: "flex",
                columnGap: "0.5rem",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <ProfileHolder bgcolor={backgroundImageColor}>
                <MaskGroup
                  alt="Profile Picture"
                  src={
                    ProfilePic
                      ? ProfilePic
                      : user?.picture || "/assets/images/profileImg.jpg"
                  }
                />
              </ProfileHolder>
              <ArrowHolder
                style={{
                  color: `${iconcolor}`,
                  transform: isDropdownVisible
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                  transition: "transform 0.3s ease-in-out",
                  width: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* ▲ */}
                <SvgPolygonDown />
              </ArrowHolder>
            </div>
          </div>
        </RightIconsHolder>
      </PageHeaderHolder>
      {/* {ShowMessagePOPUP && ( */}
      {showLanguage && (
        <LanguageComponent show={showLanguage} setShow={setShowLanguage} />
      )}
      <PageHeaderMessage
        ShowMessagePOPUP={ShowMessagePOPUP}
        setShowMessagePOPUP={setShowMessagePOPUP}
      />
      {/* )} */}
      {/* {ShowMessagePOPUP && <PageHeaderChats />} */}
    </PageHeaderPosition>
  );
};
