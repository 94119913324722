
export interface SurveyQuestionAnalyticsInterface {
  QuestionCode?:string;
  QuestionTitle?:string;
  QuestionText?:string;
  QuestionType?:string;
  students?:{
    StdCode?:string;
    StdName?:string;
TimeSpent?:number;
Answered?:any | any[];
  }[]};

  export const SurveyQuestionAnalyticsData : SurveyQuestionAnalyticsInterface[] =[
    {
      QuestionCode: "esdfc3453",
      QuestionTitle: "Question 1",
      QuestionText:"3XXX",
      QuestionType: "Yes/No", // Corrected spelling
  
      students:[
        {
          StdCode:"234rfsdfsdxasdfr43rwdfcvbfdxasfvsdvfdtgf-xdfvsdc", 
           StdName:"Hadi Souman",TimeSpent:2,Answered:"No",
        },
        {
          StdCode:"sdfdsf5s-d68syuicojef7-cguiefedcjxcsi-jksd",Answered:null,  StdName:"Jihad Semaan",
          TimeSpent:4,
        },
        {
          StdCode:"dsdfdsfsfds-fsd-fasdfdas-dfadscsssdf",  StdName:"Salim Bahsoun", TimeSpent:0,Answered:"Yes",
        },
        {
          StdCode:"d243r3dihsfsdfds-gas-fvsdfghadsf-sdds",  StdName:"Yassmina Dbouk", TimeSpent:6,Answered:"Yes",
        },
        {
          StdCode:"sdfdjsokflsd8fsdgfsdf-sdgbsdfhdgd-sgdsf",  StdName:"Rawad Shouman", TimeSpent:4,Answered:"Yes",
        },
        {
          StdCode:"safsd0df-sghdfh-fdgsdgfdsf-d-gdfsdsgdfg",  StdName:"Abdallah Zbib",TimeSpent:4,Answered:"No",
        },
      ]
    },
    
    {
      QuestionCode: "4rtgtrfgew",
      QuestionTitle: "Question 2",
      QuestionText:"How much you are happy with our UI",
      QuestionType: "Rating", // Corrected spelling
      
  
      students:[
        {
          StdCode:"234rfsdfsdxasdfr43rwdfcvbfdxasfvsdvfdtgf-xdfvsdc",  StdName:"Hadi Souman",TimeSpent:2,Answered:4,
        },
        {
          StdCode:"sdfdsf5s-d68syuicojef7-cguiefedcjxcsi-jksd",  StdName:"Jihad Semaan", TimeSpent:4,Answered:2,
        },
        {
          StdCode:"dsdfdsfsfds-fsd-fasdfdas-dfadscsssdf",  StdName:"Salim Bahsoun", TimeSpent:0,Answered:4,
        },
        {
          StdCode:"d243r3dihsfsdfds-gas-fvsdfghadsf-sdds",  StdName:"Yassmina Dbouk", TimeSpent:4,
        },
        {
          StdCode:"sdfdjsokflsd8fsdgfsdf-sdgbsdfhdgd-sgdsf",  StdName:"Rawad Shouman", TimeSpent:5,Answered:3,
        },
        {
          StdCode:"safsd0df-sghdfh-fdgsdgfdsf-d-gdfsdsgdfg",  StdName:"Abdallah Zbib", TimeSpent:4,Answered:5,
        },
      ]
    },
    {
      QuestionCode: "sdf435fsde",
      QuestionTitle: "Question 3",
      QuestionType: "Multiple Choice", // Corrected spelling
      QuestionText: "What is your favorite place to live ?",
  
      students:[
        {
          StdCode:"234rfsdfsdxasdfr43rwdfcvbfdxasfvsdvfdtgf-xdfvsdc",  StdName:"Hadi Souman", TimeSpent:4,Answered:["Beirut","Nabatieh"],
        },
        {
          StdCode:"sdfdsf5s-d68syuicojef7-cguiefedcjxcsi-jksd",  StdName:"Jihad Semaan",TimeSpent:4,Answered:["Beirut","Tyre "],
        },
        {
          StdCode:"dsdfdsfsfds-fsd-fasdfdas-dfadscsssdf",  StdName:"Salim Bahsoun", TimeSpent:0,Answered:["Zefta","Nabatieh"],
        },
        {
          StdCode:"d243r3dihsfsdfds-gas-fvsdfghadsf-sdds",  StdName:"Yassmina Dbouk", TimeSpent:4,Answered:["Tyre","Zefta","Beirut"],
        },
        {
          StdCode:"sdfdjsokflsd8fsdgfsdf-sdgbsdfhdgd-sgdsf",  StdName:"Rawad Shouman",Answered:["Nabatieh","Yohmor"],
        },
        {
          StdCode:"safsd0df-sghdfh-fdgsdgfdsf-d-gdfsdsgdfg",  StdName:"Abdallah Zbib", TimeSpent:4,
        },
      ]
    },
    {
      QuestionCode: "456gfdsd",
      QuestionTitle: "Question 6",
      QuestionType: "Likert Scale", // Corrected spelling
      QuestionText: "How Much do love Zidyia ?",
  
      students:[
        {
          StdCode:"234rfsdfsdxasdfr43rwdfcvbfdxasfvsdvfdtgf-xdfvsdc",  StdName:"Hadi Souman", TimeSpent:4,Answered:"Agree",
        },
        {
          StdCode:"sdfdsf5s-d68syuicojef7-cguiefedcjxcsi-jksd",  StdName:"Jihad Semaan", TimeSpent:5,Answered:"Strongly Agree",
        },
        {
          StdCode:"dsdfdsfsfds-fsd-fasdfdas-dfadscsssdf",  StdName:"Salim Bahsoun", TimeSpent:5,Answered:"Disagree",
        },
        {
          StdCode:"d243r3dihsfsdfds-gas-fvsdfghadsf-sdds",  StdName:"Yassmina Dbouk", TimeSpent:5,Answered:"Strongly Disagree",
        },
        {
          StdCode:"sdfdjsokflsd8fsdgfsdf-sdgbsdfhdgd-sgdsf",  StdName:"Rawad Shouman", TimeSpent:4,Answered:"Neutral",
        },
        {
          StdCode:"safsd0df-sghdfh-fdgsdgfdsf-d-gdfsdsgdfg",  StdName:"Abdallah Zbib", TimeSpent:5,Answered:"",
        },
      ]
    },
    
    {
      QuestionCode: "65uhbffds",
      QuestionTitle: "Question 7",
      QuestionType: "True/False", // Corrected spelling
      QuestionText:"Do You love Zidyia ?",
  
      students:[
        {
          StdCode:"234rfsdfsdxasdfr43rwdfcvbfdxasfvsdvfdtgf-xdfvsdc",  StdName:"Hadi Souman", TimeSpent:10,Answered:false,
        },
        {
          StdCode:"sdfdsf5s-d68syuicojef7-cguiefedcjxcsi-jksd",  StdName:"Jihad Semaan",TimeSpent:5,Answered:false,
        },
        {
          StdCode:"dsdfdsfsfds-fsd-fasdfdas-dfadscsssdf",  StdName:"Salim Bahsoun", TimeSpent:12,Answered:true,
        },
        {
          StdCode:"d243r3dihsfsdfds-gas-fvsdfghadsf-sdds",  StdName:"Yassmina Dbouk",TimeSpent:7,
        },
        {
          StdCode:"sdfdjsokflsd8fsdgfsdf-sdgbsdfhdgd-sgdsf",  StdName:"Rawad Shouman", TimeSpent:10,
        },
        {
          StdCode:"safsd0df-sghdfh-fdgsdgfdsf-d-gdfsdsgdfg",  StdName:"Abdallah Zbib", TimeSpent:5,Answered:false,
        },
      ]
    },
    {
      QuestionCode: "sdfte43fghgfh",
      QuestionTitle: "Question 8",
      QuestionType: "Multiple Choice", // Corrected spelling
      QuestionText:"Do You love Zidyia",
  
      students:[
        {
          StdCode:"234rfsdfsdxasdfr43rwdfcvbfdxasfvsdvfdtgf-xdfvsdc",  StdName:"Hadi Souman", TimeSpent:7,Answered:false,
        },
        {
          StdCode:"sdfdsf5s-d68syuicojef7-cguiefedcjxcsi-jksd",  StdName:"Jihad Semaan", TimeSpent:5,Answered:false,
        },
        {
          StdCode:"dsdfdsfsfds-fsd-fasdfdas-dfadscsssdf",  StdName:"Salim Bahsoun", TimeSpent:4,Answered:true,
        },
        {
          StdCode:"d243r3dihsfsdfds-gas-fvsdfghadsf-sdds",  StdName:"Yassmina Dbouk", TimeSpent:3,Answered:false,
        },
        {
          StdCode:"sdfdjsokflsd8fsdgfsdf-sdgbsdfhdgd-sgdsf",  StdName:"Rawad Shouman", TimeSpent:5,Answered:true,
        },
        {
          StdCode:"safsd0df-sghdfh-fdgsdgfdsf-d-gdfsdsgdfg",  StdName:"Abdallah Zbib", TimeSpent:5,Answered:true,
        },
      ]
    },
  ]
