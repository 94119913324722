import styled, { keyframes } from "styled-components";
import { breakpoints } from "../../theme";

interface MemberBoxInterface {
  bordercolor: string;
  boxshadow: string;
}

export const MemberBox = styled.div<MemberBoxInterface>`
  width: 7.3125rem;
  height: 5.4375rem;
  /* max-height: 6.375rem; */
  border-radius: 0.625rem;
  border: 2px solid ${(props) => props.bordercolor};
  box-shadow: ${(props) => props.boxshadow};
  cursor: grab;
  cursor: -webkit-grab;
  /* cursor: grabbing;
  cursor: -webkit-grabbing; */
  &:hover {
    border-color: ${({ theme }) => theme.dark.main_color};
    transition: border-color 0.2s ease-in;
  }
  /* overflow: hidden; */
`;

export const MemberBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.31rem;
  padding: 0.62rem 0.25rem;
  position: relative;
`;

export const MemberProfile = styled.img`
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  min-width: 1.875rem;
  min-height: 1.875rem;
`;

export const TypographyWrapper = styled.div`
  max-width: 100%;
  text-align: center;
  & > :first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* Set the number of lines to display before truncating */
  }
`;
export const IdCont = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #AAB1B9;
  font-size: 0.625rem;
  font-family: "Primary Font-Medium";
  cursor: pointer;
  margin-top: 0.31rem;
`;

const fadeInOut = keyframes`
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
`;
export const IdContHovered = styled.div<{show?: boolean, bgColor?:string, color?: string, boxShadow?: string, theme?: string}>`
position: absolute;
display: ${props => props.show ? "block" : "none"};
border-radius: 0.625rem;
background-color: ${props => props.bgColor};
color: ${props => props.color};
padding: 0.62rem;
width: 10rem;
cursor: copy;
z-index: 99;
box-shadow: ${props => props.theme === "light"
        ? "0px 4px 20px 0px rgba(192, 220, 214, 0.32)"
        : "0px 4px 20px 0px rgba(0, 0, 0, 0.25)"};

&.copied {
    animation: ${fadeInOut} 1.5s ease-in-out;
 }
`;
