import React, { useEffect, useState } from "react";
import {
  StudentLandingPageLayout,
  StudentLandingPageMain,
  StudentLandingPageMainObject,
  StudentLandingPageMainObjectTitle,
} from "./StudentLandingPagestyle";
import { StudentLandingPageData } from "./StudentLandingPageData";
import { LightTypography, Typography } from "../../../elements/fonts/Fonts";
import { StudentLandingTable } from "../../../elements/StudentLandingTable/StudentLandingTable";
import { CourseGeneralTracing } from "../../CourseGeneralTracing/CourseGeneralTracing";
import { StudentCompletionTracing } from "../../StudentCompletionTracing/StudentCompletionTracing";
import { CourseTraking } from "../../../elements/courseTraking/CourseTraking";
import { modules } from "../TeacherLandingPage/TeacherLandingPage.data";
import { useStateContext } from "../../../contexts/ContextProvider";
import AnalyticsAssignmentBarChart, {
  BarChartData,
} from "../../AnalyticsAssigmentResponseTraking/AnalyticsAssignmentBarChart/AnalyticsAssignmentBarChart";

interface StudentLandingInterface {
  student: any;
  ModelClick?: () => void;
}

export const StudentLandingPage = ({
  student,
  ModelClick,
}: StudentLandingInterface): JSX.Element => {
  const [isParentExpanded, setisParentExpanded] = useState(false);
  const [ShowResources, setShowResources] = useState(false);
  const [ShowActivities, setShowActivities] = useState(false);

  const [SearchText, setSearchText] = useState("");
  const { AnalyticsNumberOfUsersTable } = useStateContext();

  const handleShowResources = () => {
    setShowResources(!ShowResources);
    setShowActivities(false);
  };

  const handleShowActivities = () => {
    setShowResources(false);
    setShowActivities(!ShowActivities);
  };

  const handleModalClick = () => {
    if (isParentExpanded === false) {
      setisParentExpanded(!isParentExpanded);
    } else {
      setisParentExpanded(!isParentExpanded);
      setShowActivities(false);
      setShowResources(false);
      setSearchText("");
    }
  };
  const [ShowResourcesDown, setShowResourcesDown] = useState(false);
  const [ShowActivitiesDown, setShowActivitiesDown] = useState(false);
  const handleShowResourcesDown = () => {
    setShowResourcesDown(!ShowResourcesDown);
  };

  const handleShowActivitiesDown = () => {
    setShowActivitiesDown(!ShowActivitiesDown);
  };

  // const [StudentGraphData, setStudentGraphData] = useState<BarChartData[]>([]);

  const { StudentQuizCLassAverage, setStudentQuizCLassAverage } =
    useStateContext();
  const StudentGraphData: BarChartData[] =
    StudentLandingPageData[0]?.Course?.map((item, index) => ({
      id: index + 1,
      Name: item.name || "", // Use an empty string if item.name is undefined
      val: item.Grade,
      avg: StudentQuizCLassAverage,
    })) || [];
  return (
    <StudentLandingPageLayout>
      <CourseGeneralTracing
        UsersNumber={AnalyticsNumberOfUsersTable}
        ModelNumber="3"
        ActivitiesNumber="4"
        ResourcesNumber="5"
        DaysNumber="30"
        onClickModel={handleModalClick}
        onClickRecourses={handleShowResources}
        onClickActivities={handleShowActivities}
        ResourcesActive={ShowResources}
        UsersActive={false}
        ActivitiesActive={ShowActivities}
        ModalActive={isParentExpanded}
      />

      <CourseTraking
        module={modules}
        isParentExpanded={isParentExpanded}
        setisParentExpanded={setisParentExpanded}
        SearchText={SearchText}
        ActivitiesMode={ShowActivities}
        RearousesMode={ShowResources}
      />

      {StudentLandingPageData.map((item, index) => (
        <StudentLandingPageMain key={index}>
          {item.Course && (
            <StudentCompletionTracing StudentTracingData={item.Course} />
          )}
          <AnalyticsAssignmentBarChart
            Data={StudentGraphData}
            title="Scorm Distribution"
            teacherView={false}
            // titleClick={handleShowGrade}
          />

          {item.Recourses && (
            <StudentLandingPageMainObject>
              <StudentLandingPageMainObjectTitle
                onClick={handleShowResourcesDown}
              >
                <Typography text="Resources" fontSize="1.125rem" />
                <LightTypography
                  text={`${item.Recourses.length} Resources`}
                  fontSize=" 0.75rem"
                  color="#FAFAFA"
                />
              </StudentLandingPageMainObjectTitle>
              {ShowResourcesDown && (
                <StudentLandingTable LandingData={item.Recourses} />
              )}
            </StudentLandingPageMainObject>
          )}

          {item.Activities && (
            <StudentLandingPageMainObject>
              <StudentLandingPageMainObjectTitle
                onClick={handleShowActivitiesDown}
              >
                <Typography text="Activities" fontSize="1.125rem" />
                <LightTypography
                  text={`${item.Activities.length} Resources`}
                  fontSize=" 0.75rem"
                  color="#FAFAFA"
                />
              </StudentLandingPageMainObjectTitle>
              {ShowActivitiesDown && (
                <StudentLandingTable LandingData={item.Activities} />
              )}
            </StudentLandingPageMainObject>
          )}
        </StudentLandingPageMain>
      ))}
    </StudentLandingPageLayout>
  );
};
