import React, { Dispatch, useEffect, useState } from "react";
import {
  ButtonAdd,
  ButtonCancel,
  ButtonsHolder,
  FirstRow,
  InputTitle,
  TitleLabel,
} from "./AddOfflineActivity.style";
import { LightTypography, MediumTypography } from "../../elements/fonts/Fonts";
import SelectInput from "../../elements/SelectInput/SelectInput";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { formatOptionsForTranslation } from "../../utils/FormatOptionsForTranslation";
import SelectDropDown from "../../elements/selectDropDown/SelectDropDown";

interface Module {
  id: number;
  classSourceId: string;
  idx: number;
  title: string;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
}

interface Activity {
  [key: string]: {
    Weight: number;
  };
}

interface EditModalProps {
  savedOffline:boolean;
  SetsavedOffline:Dispatch<React.SetStateAction<boolean>>;
  toggleOfflineActivityModel: () => void;
}

const AddOfflineActivity = ({ toggleOfflineActivityModel,SetsavedOffline,savedOffline }: EditModalProps) => {
  const [module, setModule] = useState<Module[]>([]);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [activityName, setActivityName] = useState<string>("");
  const [maxGrade, setMaxGrade] = useState<number | string>("");
  const [selectedModule, setSelectedModule] =
    useState<string>("");
  const [selectedActivity, setSelectedActivity] =
    useState<string>("");
  const [isLoading, setIsLoading] = useState(false); // State variable for loading
  const tenantName = localStorage.getItem("tenant");
  const id = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchModuleData = async () => {
      try {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/class/modules/${tenantName}/${id.classId}`
        );
        setModule(response.data.data);
      } catch (error) {
        console.error("Error fetching module data:", error);
      }
    };

    fetchModuleData();

    return () => {
      // Cleanup function
    };
  }, [id, tenantName]);

  useEffect(() => {
    const fetchActivitiesData = async () => {
      try {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/activities/weight/${id.classId}/${tenantName}`
        );
        setActivities(response.data.data);
      } catch (error) {
        console.error("Error fetching activities data:", error);
      }
    };

    fetchActivitiesData();

    return () => {
      // Cleanup function
    };
  }, [id, tenantName]);

  const enrollmentOptions = module.map((mod) => ({
    name: mod.title,
    value: mod.title,
  }));

  const activityOptions = activities.map((activity) => {
    const activityName = Object.keys(activity)[0];
    return {
      name: `gradebook.${activityName}`,
      value: activityName,
    };
  });

  const handleSaveOffline = async () => {
    if (!activityName || !maxGrade || !selectedModule || !selectedActivity) {
      toast.error("Please fill all the fields !");
      return;
    }

    const selectedModuleData = module.find(
      (mod) => mod.title === selectedModule
    );
    const moduleIndex = selectedModuleData ? selectedModuleData.idx : 0;

    const dataToSend = {
      classSourceId: id.classId,
      activityTitle: activityName,
      activityType: selectedActivity,
      maxGrade,
      module: selectedModule,
      moduleIndex,
    };

    setIsLoading(true); // Set loading state to true

    try {
      // Send the data to the API endpoint
      const response = await axios.post(
        `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/offline/activity/create/${tenantName}`,
        { data: dataToSend }
      );
      toast.success("Data saved successfully");
      setActivityName("");
      setMaxGrade(0);
      setSelectedModule("");
      setSelectedActivity("");
      // Optionally close the modal
      toggleOfflineActivityModel();
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Failed to save data. Please try again later.");
    } finally {
      setIsLoading(false); // Reset loading state to false after API call completes
      SetsavedOffline(!savedOffline)
    }
  };
  const { SecondaryTextColor, bghelp, theme } = useRecoilValue(ColorSelector);
  const translatetActivitiesOptions = formatOptionsForTranslation(activityOptions, t)
  return (
    <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
      <TitleLabel>
        <LightTypography text={t("gradebook.Activity name")} fontSize="0.875rem" />
        <InputTitle
          color={SecondaryTextColor}
          bordercolor={bghelp}
          placeholder={t("gradebook.Activity name")}
          value={activityName}
          onChange={(e) => setActivityName(e.target.value)}
        />
      </TitleLabel>

      <TitleLabel>
        <LightTypography text={t("assignment.Maximum grade")} fontSize="0.875rem" />
        <InputTitle
          color={SecondaryTextColor}
          bordercolor={bghelp}
          type="number"
          placeholder={t("quiz.Enter Maximum Grade")}
          value={maxGrade === 0 ? "" : maxGrade}
          onChange={(e) => setMaxGrade(e.target.value === "" ? "" : parseInt(e.target.value))}
        />
      </TitleLabel>

      <TitleLabel>
        <LightTypography text={t("course view.Module")} fontSize="0.875rem" />
        <SelectDropDown
          placeholder={t("gradebook.Select Module")}
          value={selectedModule}
          options={enrollmentOptions}
          onChange={(newValue) => setSelectedModule(newValue)}
        />
      </TitleLabel>

    
        <TitleLabel>
          <LightTypography text={t("gradebook.Activity Category")} fontSize="0.875rem" />
          <SelectDropDown
            placeholder={t("gradebook.Select Activity Category")}
            value={selectedActivity}
            options={translatetActivitiesOptions}
            onChange={(newValue) => setSelectedActivity(newValue)}
          />
        </TitleLabel>
    

      <ButtonsHolder>
        <ButtonCancel onClick={toggleOfflineActivityModel}>
          <MediumTypography text={t("forms.Cancel")} color="#D04B4B" />
        </ButtonCancel>
        <ButtonAdd onClick={handleSaveOffline} disabled={isLoading}>
          <MediumTypography
            text={isLoading ? "loading ..." : t("gradebook.Generate")}
            color="#fff"
          />
        </ButtonAdd>
      </ButtonsHolder>
    </div>
  );
};

export default AddOfflineActivity;
