import React from "react";
import { FlexAppContainer } from "../../App.style";
import NotificationVerticalLine from "../../components/Natifications/NotificationVerticalLine/NotificationVerticalLine";
import { Typography } from "../../elements/fonts/Fonts";
import { useTranslation } from "react-i18next";

const NotificationPage = () => {
  const { t } = useTranslation()
  return (
    <FlexAppContainer column={true} gap="1.25rem">
      <FlexAppContainer justifyContent="space-between" gap="1.25rem">
        <Typography text={t("notification.Notification")} fontSize="1.75rem" />
      </FlexAppContainer>
      <NotificationVerticalLine />
    </FlexAppContainer>
  );
};

export default NotificationPage;
