import { FC, useState, useEffect, useRef, useContext } from "react";
import {
  SchemeGroupInterface,
  SchemeGroupGlobalInterface,
} from "./schemeGroupInterface";
import {
  SchemeGroupCardRectangle,
  SchemeGroupCardHeaderRow,
  VDotsWrapper,
  SchemeGroupDescriptionWrapper,
  PartitioningMainHolder,
  PartitioningHolderRow,
  UngroupedHolderRow,
  PartitioningNumWithLine,
  DivideLine,
  GroupsWrapper,
  UngroupedStudentsWrapper,
  UngroupedStudentsColumn,
  UngroupedStudentRow,
  TypographyWrapper,
} from "./SchemeGroup.style";
import {
  Typography,
  LightTypography,
  PrimaryFontMediumText,
} from "../../elements/fonts/Fonts";
import { SvgMore, SvgBgProfileSchemeGroup } from "../../elements/Icons";
import SingleGroupElement from "../../elements/singleGroupElement/SingleGroupElement";
import ReusableProfile from "../../elements/reusableProfile/ReusableProfile";
import DropDownEditDeleteScheme from "../dropDownEditDeleteScheme/main/DropDownEditDelete";
import useToggle from "../../hooks/useToggle";
import useClickOutside from "../../hooks/useClickOutside";
import { useStateContext } from "../../contexts/ContextProvider";
import profileDefaultPicture from "../../assets/userProfile.png";
import DOMPurify from "dompurify";
import PopupDelete from "../../elements/PopupDelete/PopupDelete";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  courseCreatorColorSelector,
} from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { toArabicDigits } from "../../utils/formatDayArabic";

const SchemeGroup: FC<SchemeGroupGlobalInterface> = ({
  groupSchemaId,
  titleScheme,
  description,
  groupsForScheme,
  ungroupedStudents,
  removeScheme,
  setIsSchemeIdInGroupSchemeView,
  setShowEditScheme,
  showEditScheme,
  scheme,
  selectedClassId
}) => {
  const { setScheme_ID } = useStateContext();
  const { role } = useContext(ClassContext);
  const { t } = useTranslation();

  const { isOpen: isDropdownVisible, toggle: toggleDropdown } =
    useToggle(false);
  const dropdownRef = useClickOutside(isDropdownVisible, toggleDropdown);

  const [showDeleteScheme, setShowDeleteScheme] = useState(false);
  const handleDeleteScheme = (showDeleteScheme: boolean) => {
    setShowDeleteScheme(showDeleteScheme);
  };

  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const handleShowDropDown = (showDropDown: boolean) => {
    setShowDropDown(showDropDown);
  };

  const isSchemeIdRef = useRef<number | string | undefined>(undefined);
  useEffect(() => {
    isSchemeIdRef.current = groupSchemaId;
  }, [groupSchemaId]);

  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };
  const { backgroundColor, boxShadow, theme } = useRecoilValue(ColorSelector);
  const { lineIconColor } = useRecoilValue(courseCreatorColorSelector);

  return (
    <SchemeGroupCardRectangle bgColor={backgroundColor} boxShadow={boxShadow}>
      <SchemeGroupCardHeaderRow>
        <Typography text={toArabicDigits(titleScheme)} whiteSpace="nowrap" />
        {role === "teacher" && (
          <VDotsWrapper
            ref={dropdownRef}
            onClick={() => {
              toggleDropdown();
              setShowDropDown(true);
              if (isSchemeIdRef.current !== undefined) {
                setIsSchemeIdInGroupSchemeView(isSchemeIdRef.current);
                setScheme_ID(isSchemeIdRef.current);
              }
            }}
          >
            <SvgMore />
          </VDotsWrapper>
        )}
      </SchemeGroupCardHeaderRow>
      {isDropdownVisible && showDropDown && (
        <DropDownEditDeleteScheme
          setShowDeleteScheme={handleDeleteScheme}
          setShowDropDown={handleShowDropDown}
          setShowEditScheme={setShowEditScheme}
          schemeId={groupSchemaId}
          scheme={scheme}
          selectedClassId={selectedClassId}
        />
      )}
      {showDeleteScheme && (
        <PopupDelete
          show={showDeleteScheme}
          setShow={setShowDeleteScheme}
          title={t("grader.Are you sure you want to delete this Scheme?")}
          handleSubmit={() => {
            removeScheme(groupSchemaId);
          }}
        />
      )}
      {description && (
        <SchemeGroupDescriptionWrapper>
          <LightTypography
            text={`${t("group.Group Description")} : `}
            fontSize="0.875rem"
            lineHeight="1.375rem"
            whiteSpace="nowrap"
            color={theme === "light" ? "#364350" : "#ffff"}
          />
          {"  "}
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(decodeHtml(` ${description}`)),
            }}
            style={{
              fontSize: "0.875rem",
              fontWeight: "400",
              fontFamily: "Primary Font-Light",
              display: "inline",
              color: theme === "light" ? "#364350" : "#ffff",
            }}
          ></p>
        </SchemeGroupDescriptionWrapper>
      )}
      {groupsForScheme.length > 0 && (
        <PartitioningMainHolder>
          <PartitioningHolderRow>
            <Typography
              text={t("group.Partitioning")}
              fontSize="0.875rem"
              whiteSpace="nowrap"
            />
            <PartitioningNumWithLine>
              <PrimaryFontMediumText
                text={`(${toArabicDigits((groupsForScheme.length).toString())})`}
                fontSize="0.75rem"
                color="#5DD3B3"
              />
              <DivideLine lineColor={lineIconColor}></DivideLine>
            </PartitioningNumWithLine>
          </PartitioningHolderRow>
          <GroupsWrapper>
            {groupsForScheme.map((group, index) => (
              <SingleGroupElement
                key={index}
                bordercolor={group.bordercolor}
                title={group.title}
                participantsNumber={group.students.length}
                students={group.students}
                groupId={group.groupId}
                groupSchemaId={group.groupSchemaId}
                indexGroup={group.indexGroup}
              />
            ))}
          </GroupsWrapper>
        </PartitioningMainHolder>
      )}
      {ungroupedStudents && ungroupedStudents.length > 0 && (
        <PartitioningMainHolder>
          <UngroupedHolderRow>
            <Typography
              text={t("group.Ungrouped")}
              fontSize="0.875rem"
              whiteSpace="nowrap"
            />
            <PartitioningNumWithLine>
              <PrimaryFontMediumText
                text={`(${ungroupedStudents.length})`}
                fontSize="0.75rem"
                color="#5DD3B3"
              />
              <DivideLine lineColor={lineIconColor}></DivideLine>
            </PartitioningNumWithLine>
          </UngroupedHolderRow>
          <UngroupedStudentsWrapper>
            <UngroupedStudentsColumn>
              {ungroupedStudents.map(
                (student, index) =>
                  index % 2 === 0 && (
                    <UngroupedStudentRow>
                      <ReusableProfile
                        key={student.studentId}
                        iconPath={
                          student.profilePicture
                            ? student.profilePicture
                            : profileDefaultPicture
                        }
                        width="1.875rem"
                        height="1.875rem"
                        widthImgHolder="1.875rem"
                        heightImgHolder="1.875rem"
                        top="0"
                        left="0"
                        iconBg={SvgBgProfileSchemeGroup}
                        border="none"
                      />
                      <TypographyWrapper>
                        <LightTypography
                          text={`${student.username}`}
                          fontSize="0.875rem"
                        />
                      </TypographyWrapper>
                    </UngroupedStudentRow>
                  )
              )}
            </UngroupedStudentsColumn>
            <UngroupedStudentsColumn>
              {ungroupedStudents.map(
                (student, index) =>
                  index % 2 !== 0 && (
                    <UngroupedStudentRow>
                      <ReusableProfile
                        key={student.studentId}
                        iconPath={
                          student.profilePicture
                            ? student.profilePicture
                            : profileDefaultPicture
                        }
                        width="1.875rem"
                        height="1.875rem"
                        widthImgHolder="1.875rem"
                        heightImgHolder="1.875rem"
                        top="0"
                        left="0"
                        iconBg={SvgBgProfileSchemeGroup}
                        border="none"
                      />
                      <TypographyWrapper>
                        <LightTypography
                          text={`${student.username}`}
                          fontSize="0.875rem"
                        />
                      </TypographyWrapper>
                    </UngroupedStudentRow>
                  )
              )}
            </UngroupedStudentsColumn>
          </UngroupedStudentsWrapper>
        </PartitioningMainHolder>
      )}
    </SchemeGroupCardRectangle>
  );
};

export default SchemeGroup;
