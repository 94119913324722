import styled from "styled-components";
import { breakpoints } from "../../theme";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const MessageViewLay = styled.div`
  display: flex;
  /* width:102%; */
  width: calc(100% + 1.51rem);
  gap: 1.5rem;
  position: relative;
  &:dir(ltr) {
    right: 0.18rem;
  }
  &:dir(rtl) {
    left: 0.18rem;
  }
  top: 0rem;
  /* background-color: cyan; */
  /* align-items:flex-end; */
  /* flex-wrap: nowrap; */
`;
export const MessageViewEXTRA = styled.div`
  display: flex;
  /* flex-wrap: nowrap; */
  flex: 9;
  min-height: 35rem;

  /* background-color:aqua; */
`;

export const ArrowStyleMessage = styled.div`
  cursor: pointer;
`;

export const MessageView = styled.div<{ bgColor: string }>`
  position: relative;
  border-radius: 1.25rem;
  background: ${(props) => props.bgColor};
  height: 100%;
  display: flex;
  width: 100%;

  /* flex-direction:column; */
  @media (max-width: calc(${breakpoints.mobile} + 25px)) {
    height: 100vh;
  }
`;
export const IconWrapper = styled.div`
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #5dd3b3;
  color: #fff;
  &:dir(rtl) {
    transform: rotateY(180deg);
  }
`;
export const TopHolder = styled.div`
  padding: 0.7rem 1.5rem 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const MessagesHolder = styled.div<{ bgColor?: string }>`
  border-radius: 0rem 0rem 1.25rem 1.25rem;
  width: 100%;
  height: 100%;
  background: ${(props) => props.bgColor};
  /* background: #434F5B; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MessagesHolderTop = styled.div<StyleThemeInterface>`
  padding: 1rem 1.5rem 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.bordercolor};

  width: 100%;
`;
export const SenderInfo = styled.div`
  display: flex;
  column-gap: 0.88rem;
  align-items: center;
`;
export const RightIconsHolder = styled.div`
  display: flex;
  column-gap: 0.94rem;
  /* background-color:red; */
  position: relative;
  /* background-color:aqua; */
  /* height:4rem; */
`;
export const OverlapGroup = styled.div<{ bgColor: string }>`
  /* background-color: #384450; */
  background: ${(props) => props.bgColor};
  color: white;
  cursor: pointer;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.7s ease-in-out, color 0.7s ease-in-out;
  &:nth-child(2) {
    position: absolute;
    background-color: transparent !important;
    &:dir(ltr) {
      right: 0.3rem;
    }
    &:dir(rtl) {
      left: 0.3rem;
    }
    top: 0.6rem;
  }
`;
export const MessagesWrapper = styled.div`
  display: flex;
  column-gap: 0.5rem;
  padding: 1rem 1.5rem 1rem 1.5rem;
`;

export const OnlyMessagesWrapper = styled.div`
  display: flex;
  margin-top: -2rem;
  padding: 1rem 1.5rem 1rem 1.5rem;
  &:dir(ltr) {
    margin-left: 3.2rem;
    margin-right: 1.9rem;
  }
  &:dir(rtl) {
    margin-right: 3.2rem;
    margin-left: 1.9rem;
  }
  margin-left: 3.2rem;
  margin-right: 1.9rem;
`;
export const OnlySenderMessagesWrapper = styled.div`
  display: flex;
  margin-top: -2.7rem;
  padding: 1rem 1.5rem 1rem 1.5rem;
  &:dir(ltr) {
    margin-left: 3.2rem;
    margin-right: 1.9rem;
  }
  &:dir(rtl) {
    margin-right: 3.2rem;
    margin-left: 1.9rem;
  }
`;

export const ShowForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 19rem;
`;
export const ProfileHolder = styled.div`
  border-radius: 50%;
  background: linear-gradient(180deg, #2d3741 0%, #576371 100%);
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.3rem;
`;

export const MaskGroup = styled.img`
  border-radius: 2.125rem;
  border: 1px solid #707070;
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const InfoSvg = styled.div`
  cursor: pointer;
  margin-top: -0.5rem;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background: #384450;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const MessageInput = styled.div`
  padding: 1rem 1.5rem 1rem 1.5rem;
  /* position:absolute; */
  bottom: 0;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: calc(${breakpoints.mobile} + 25px)) {
    gap: 0.25rem;
    padding-right: 00.25rem;
    padding-left: 0.25rem;
  }
`;
export const MessageInputField = styled.input<{
  color: string;
  themeColor?: string;
  EmojiOpen?: boolean;
}>`
  width: 100%;
  resize: none;
  /* min-height: 2.5rem; */
  color: ${(props) => props.color};
  padding: 1rem 1.25rem;
  font-size: 0.75rem;
  word-wrap: break-word;
  border: none;
  /* border:0.5px solid ; */
  outline: none;
  /* opacity: 0.5; */
  height: 3.7rem;
  /* max-height:6rem; */
  overflow-x: hidden;
  overflow-y: scroll;
  /* background-color:aqua; */
  &:dir(ltr) {
    padding-right: 1.69rem;
  }
  &:dir(rtl) {
    padding-left: 1.69rem;
  }
  border-radius: ${(props) =>
    props.EmojiOpen ? "0 0 1.25rem 1.25rem" : "1.25rem"};
  opacity: 0.8;
  background-color: ${(props) =>
    props.themeColor === "dark" ? "#3C4752" : "#F6F7F8"};
  font-family: ${(props) => props.theme.font.primaryRegular};

  &::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(
      --Green-Gradient,
      linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)
    );
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0.625rem;
  }
  &::placeholder {
    /* color: #FFF; */
    font-family: ${(props) => props.theme.font.primaryRegular};
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    color: ${(props) => props.color};
    opacity: 0.5;
  }
  &:focus {
    outline: none;
  }
  @media (max-width: ${breakpoints.tablets}) {
    width: 80%;
  }
  @media (max-width: calc(${breakpoints.mobile} + 25px)) {
    width: 65%;
  }
`;
export const RecentMessagesDiv = styled.div`
  width: 20rem;
  height: 100%;
  /* background-color:purple; */
  grid-column: 4/5;
  @media (max-width: calc(${breakpoints.tablets} + 25px)) {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const ReceivedMessageRow = styled.div`
  width: 100%;
  display: flex;
  /* column-gap: 0.5rem; */
  /* padding: 0.3rem; */
  width: 100%;
`;
export const ReceivedMessage = styled.div<{
  bgColor: string;
  theme: string;
  language?: string | null;
  margintop?: string;
}>`
  /* margin-top:1.125rem;  */
  min-width: 14.5rem;
  max-width: 17.5rem;
  /* height: 5.25rem; */
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding: 0.6rem;

  &:dir(ltr) {
    border-radius: 0rem 0.625rem 0.625rem 0.625rem;
  }
  &:dir(rtl) {
    border-radius: 0.625rem 0rem 0.625rem 0.625rem;
  }
  /* background: linear-gradient(90deg, #415160 0%, #293440 107.88%);    */

  background: ${(props) =>
    props.language === "ar" && props.theme === "light"
      ? "#AAB1B9"
      : props.language === "ar"
      ? " linear-gradient(90deg, #293440 0%,  #415160 107.88%)"
      : props.bgColor};

  /* background-color:yellow !important; */
  min-height: 3.25rem;
  margin-top: ${(props) => props.margintop};
`;
export const SenderMessageRow = styled.div`
  display: flex;
  /* flex-direction:row-reverse; */
  /* column-gap: 0.5rem; */
  /* padding: 0.3rem; */
  width: 106% !important;
  /* background-color:red; */
  justify-content: flex-end;
  /* background-color:aquamarine; */
  &:dir(ltr) {
    margin-right: -1.2rem;
  }
  &:dir(rtl) {
    margin-left: -1rem;
  }
`;

export const SenderMessage = styled.div<{
  bgColor: string;
  theme: string;
  language?: string | null;
  margintop?: string;
}>`
  /* margin-top:1.125rem;  */
  /* width: 18.5rem; */
  /* min-width: 14.5rem;
  max-width: 17.5rem; */
  min-height: 3.25rem;
  flex-shrink: 0;
  &:dir(ltr) {
    border-radius: 0.625rem 0rem 0.625rem 0.625rem;
  }
  &:dir(rtl) {
    border-radius: 0rem 0.625rem 0.625rem 0.625rem;
  }
  background: ${(props) =>
    props.language === "ar" && props.theme === "light"
      ? "#AAB1B9"
      : props.language === "ar"
      ? " linear-gradient(90deg, #415160  0%,  #293440  107.88%)"
      : props.bgColor};
  /* background: var(--Grey-Gradient, linear-gradient(90deg, #293440  0%, #415160  107.88%)); */
  border-radius: 0.625rem 0rem 0.625rem 0.625rem;
  line-break: auto;
  padding: 0.9rem;
  margin-top: ${(props) => props.margintop};
`;

export const ContactMessagesLayoutplace = styled.div`
  display: flex;
  /* flex:3; */
  height: 100%;

  position: relative;
  &:dir(ltr) {
    margin-right: -1.37rem;
  }
  &:dir(rtl) {
    margin-left: -1.37rem;
  }
`;

export const MessageAndEmoji = styled.div`
  display: flex;
  /* flex:3 */
  /* background-color:red; */
  position: relative;
  width: 100%;
  &:dir(ltr) {
    margin-right: 1.5rem;
  }
  &:dir(rtl) {
    margin-left: 1.5rem;
  }
`;

export const ChatContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  /* gap: 1.25rem; */
  height: 60vh;
  overflow-x: hidden;
  overflow-y: scroll;
  /* background-color:aqua; */
  /* new */
  padding-right: 1.69rem;
  /* new */
  padding: 2rem;
  gap: 0.62rem;
  /////
  &::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(
      --Green-Gradient,
      linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)
    );
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0.625rem;
  }
`;

export const MessageOpenOption = styled.div<{ bgColor: string }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 0.3rem;
  width: 12.8rem;
  /* height: 9.375rem; */
  flex-shrink: 0;
  border-radius: 0.3125rem;
  background: #1f2731;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  &:dir(ltr) {
    right: 1.7rem;
  }
  &:dir(rtl) {
    left: 1.7rem;
  }
  top: 1.3rem;
  background: ${(props) => props.bgColor};
`;

export const MessageOpenLabel = styled.div`
  display: flex;
  cursor: pointer;
  /* background-color:red; */
  position: relative;
  padding: 0.5rem;
  width: 100%;
  &:hover {
    background-color: #5dd3b3;
    color: #fff;
  }
  &:nth-child(1) {
    border-radius: 0.3125rem 0.3125rem 0 0;
  }
  &:last-child {
    /* border-radius: 0 0 0.3125rem  0.3125rem  ; */
    border-radius: 0.3125rem;
  }
`;

export const BlockContainer = styled.div`
  display: flex;
  border-radius: 0rem 0rem 1.25rem 1.25rem;
  opacity: 0.5;
  background: rgba(0, 0, 0, 0.2);
  padding: 1.62rem 0;
  justify-content: center;
  width: 100%;
`;

export const MessagesFlow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const MessagesLayout = styled.div`
  display: flex;
  width: 100%;
  /* background: yellow; */
  /* margin-top: -0.3rem; */
  /* flex-direction:column; */
`;

export const MessagesLayoutProfile = styled.img`
  /* flex-direction:column; */
`;

export const GroupSenderName = styled.div`
  margin-top: -0.5rem;
`;

export const MessageEmptyBox = styled.div<{ bgColor: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* opacity: 0.5; */
  border-radius: 0rem 0rem 1.25rem 1.25rem;
  /* opacity: 0.1; */
  background: #384450;
  background: #434f5b;
  background: ${(props) => props.bgColor};

  /* background-color:purple; */
`;
