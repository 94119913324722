import React from 'react'

export const FilterCourses = (selectedCourseFilters: any, filteredCourses: any[]) => {
    const filteredAndFilteredCourses = selectedCourseFilters && Object.keys(selectedCourseFilters).some( (key) => selectedCourseFilters[key].length > 0 ) && filteredCourses.filter((course: any) => {
        if (selectedCourseFilters["Status"].length > 0) {
          if ( selectedCourseFilters["Status"].includes("In Progress") && !(course.Progress > 0 && course.Progress < 100) ) {
            return false;
          }
          if ( selectedCourseFilters["Status"].includes("Not Started") && !(course.Progress === 0) ) {
            return false;
          }
          if ( selectedCourseFilters["Status"].includes("Mandatory") && !course.isMandatory ) {
            return false;
          }
          if ( selectedCourseFilters["Status"].includes("Completed") && !(course.Progress === 100) ) {
            return false;
          }
        }

        if ( selectedCourseFilters["Enrolled Within"].includes("Last 180 Days") ) {
          const last180Days = 180 * 24 * 60 * 60 * 1000; // milliseconds in 180 days
          const enrollmentDate = new Date(course.EnrollmentDate).getTime();
          const currentDate = new Date().getTime();
          if (currentDate - enrollmentDate > last180Days) {
            return false;
          }
        }

        if ( selectedCourseFilters["Enrolled Within"].includes("Last 365 Days") ) {
          const last365Days = 365 * 24 * 60 * 60 * 1000; // milliseconds in 180 days
          const enrollmentDate = new Date(course.EnrollmentDate).getTime();
          const currentDate = new Date().getTime();
          if (currentDate - enrollmentDate > last365Days) { 
            return false; 
          }
        }

        if (selectedCourseFilters["Due Within"].includes("Over Due")) {
          const dueDate = new Date(course.DueDate).getTime();
          const currentDate = new Date().getTime();
          if (!(currentDate > dueDate)) {
            return false;
          }
        }

        if (selectedCourseFilters["Due Within"].includes("Near Deadline")) {
          const dueDate = new Date(course.DueDate).getTime();
          const currentDate = new Date().getTime();
          const sevenDaysInMillis = 7 * 24 * 60 * 60 * 1000; // milliseconds in 7 days
          if ( !( currentDate <= dueDate && dueDate - currentDate <= sevenDaysInMillis ) ) {
            return false;
          }
        }

        // Check if there are selected "Academic Sessions"
        const academicSessionFilters = selectedCourseFilters["Academic Sessions"];
        const shouldFilterByAcademicSessions = academicSessionFilters.length > 0;

        // If there are selected academic sessions, check for a match
        if (shouldFilterByAcademicSessions) {
          const anyMatchesSession = academicSessionFilters.some((filter: any) => course.academicSessions.includes(filter) );
          if (!anyMatchesSession) {
            return false;
          }
        }

        // Check if all "Class Category" filters are matched
        const allMatchesCategory = selectedCourseFilters[ "Class Category" ].every((filter: any) => course.Category.includes(filter));
        return allMatchesCategory;
      })
      
      return filteredAndFilteredCourses;
};


export default FilterCourses