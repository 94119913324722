import { FC } from "react";
import {
  GroupCardDataInterface,
  GroupStudent,
} from "../../components/groupCardNew/groupCardNewInterface";
import {
  GroupCardRectangle,
  GroupTitleParticipantsHolder,
  ProfilesStudentsHolder,
} from "./SingleGroupElement.style";
import { Typography, TypographyRegularDescription } from "../fonts/Fonts";
import ReusableProfile from "../reusableProfile/ReusableProfile";
import { SvgBgProfileSchemeGroup } from "../Icons";
import profileDefaultPicture from "../../assets/userProfile.png";
import { useTranslation } from "react-i18next";
import { toArabicDigits } from "../../utils/formatDayArabic";

const SingleGroupElement: FC<GroupCardDataInterface> = ({
  bordercolor,
  title,
  // participantsNumber,
  students,
  groupId,
  groupSchemaId,
  indexGroup,
}) => {
  const firstThreeStudents = students.slice(0, 3);
  const { t } = useTranslation();
  return (
    <GroupCardRectangle
      index={indexGroup}
      bgcolor={bordercolor}
      membersnumber={students.length}
      color="#fff"
    >
      <GroupTitleParticipantsHolder>
        <Typography text={title} fontSize="0.75rem" />
        <TypographyRegularDescription
          text={`${toArabicDigits((students.length).toString())} ${t("group.Participants")}`}
          fontSize="0.625rem"
        />
      </GroupTitleParticipantsHolder>
      <ProfilesStudentsHolder>
        {students.length > 0 &&
          firstThreeStudents.map((student: GroupStudent, studentIndex) => (
            <div
              style={{
                marginRight: `${
                  firstThreeStudents.length === 1
                    ? "0"
                    : firstThreeStudents.length === 2 && studentIndex === 0
                    ? "-0.565rem"
                    : firstThreeStudents.length === 3 &&
                      (studentIndex === 1 || studentIndex === 0)
                    ? "-0.565rem"
                    : "0"
                }`,
                zIndex: `${studentIndex}`,
              }}
            >
              <ReusableProfile
                key={student.studentId}
                iconPath={
                  student.profilePicture
                    ? student.profilePicture
                    : profileDefaultPicture
                }
                width="1.875rem"
                height="1.875rem"
                widthImgHolder="1.875rem"
                heightImgHolder="1.875rem"
                top="0"
                left="0"
                iconBg={SvgBgProfileSchemeGroup}
              />
            </div>
          ))}
      </ProfilesStudentsHolder>
    </GroupCardRectangle>
  );
};

export default SingleGroupElement;
