import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const CalendarContainer = styled.div<StyleThemeInterface>`
  margin-top: 1.25rem;
  .rbc-month-view .rbc-button-link {
    /* font-size: 1rem; */
    color: ${(props) => props.theme === "light" ? "#AAB1B9" : "#fff"};
    background: transparent !important;
    font-family: "Primary Font-Medium";
    /* color: #5dd3b3 !important;
    :hover {
      color: #5dd3b3 !important;
    } */
  }
`;
