import styled from 'styled-components'
import { StyleThemeInterface } from '../../../interfaces/StyleThemeInterface';

export const QuestionCRUDContainer=styled.div<StyleThemeInterface>`
border-radius: 0.625rem;
background:${props => props.bgcolor || "linear-gradient(180deg, #415160 0%, #293440 100%)"};
/* background: aqua; */
box-shadow:${props => props.boxshadow || "0px 4px 20px 0px rgba(0, 0, 0, 0.25)"};
height: auto;
min-width:33rem;
width:100%;

/* overflow-y: scroll;

&::-webkit-scrollbar {
  width: 0.2rem;
}


&::-webkit-scrollbar-thumb {
  background-color: #5DD3B3;
  border-radius: 0.25rem; 
}


&::-webkit-scrollbar-track:hover {
  background-color: transparent;
} */
`;

export const ChoiceFlexContainer=styled.div`
display: flex;
flex-direction :column;
gap:0.62rem;
`;







