import styled from "styled-components";
import { breakpoints } from "../../../theme";

export const PPMainCols = styled.div`
  display: flex;
  gap: 1.25rem;
  width: 97%;
  flex-direction: column;
  /* margin-top: 1rem; */
`;

export const PPMainRows = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: auto;
  gap: 1.8rem;
  @media (max-width: ${breakpoints.tablets}) {
    flex-wrap: wrap !important;
  }
`;
export const PicAndButtons = styled.div`
  display: flex;
  /* width: 18.5rem; */
  /* min-width: 17rem; */
  flex-direction: column;
  /* background-color: red; */
  gap: 0.9rem;
  @media (max-width: ${breakpoints.tablets}) {
    width: 100%;
  }
`;
export const CommonCoursesStylee = styled.div`
  width: 100%;
  justify-content: center;
  padding-left: 4rem;
  gap: 1.25rem;
`;
export const DataExport = styled.div`
  display: flex;
  flex-direction: column;
  /* background: purple !important; */
`;
