import styled from "styled-components";
import { theme } from "../../../theme";
import CheckIcon from "../../../assets/icons/Group2199.svg";
import { StyledInputInterface } from "../../StyledInput/StyledInputInterface";
import { Styled } from "styled-components/dist/constructors/constructWithOptions";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";


export const StudentTruOrFalseContainerParent = styled.div`
display: flex;
width: 100%;
gap: 1rem;
align-items: center;
gap: 3.13rem;
/* padding: 0 1.5rem 0 1.5rem; */
`;

export const CustomCheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 1.25rem;
`;

export const CheckBox = styled.input<StyleThemeInterface>`
  width: 1.2rem;
  height: 1.2rem;
 background:${props =>props.bgcolor || "linear-gradient(180deg, #2d3741 0%, #576371 100%),linear-gradient(0deg, #ffffff, #ffffff)"};

  appearance: none;
  border: 1px solid ${theme.dark.primary_text_color};

  border-radius: 50%;
  transition: background-color 0.3s ease;

  &:checked {
    /* background: linear-gradient(180deg, #5DD3B3 0%, #2D3741 100%),
linear-gradient(0deg, #5DD3B3, #5DD3B3);
border: 1px solid #5DD3B3 */
    background-image: url(${CheckIcon}); 
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center;
    background: linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%);
    border: 1px solid #5dd3b3;
  }
`;
export const CommentContainer = styled.div`
width: 100%;
gap: 1rem;
padding:1.5rem ;
border-radius: 10px;
background: rgba(255, 255, 255, 0.03);
`;
