import {
  SvgAnnouncement,
  SvgAssignmentIcon,
  SvgCalendarIcon,
  SvgCoursesIcon,
  SvgDiscussion,
  SvgDocument,
  SvgFolderIcon,
  SvgLinkTree,
  SvgMedia,
  SvgMic,
  SvgMonitorIcon,
  SvgNews,
  SvgNotificationIcon,
  SvgOnlineSessionIcon,
  SvgQuiz,
  SvgSurveyIcon,
  SvgTeams,
  SvgUrl,
  SvgVideo2,
  SvgVideoNew,
} from "../Icons";

export const getIconBasedOnType = (type: string, refTable?: string) => {
  switch (type) {
    case "Document":
      return <SvgDocument />;
    case "Picture":
      return <SvgMedia />;
    case "Link":
      return <SvgUrl />;
    case "video":
      return <SvgVideoNew />;
    case "Assignment":
      return <SvgNews />;
    case "Forum":
      return <SvgDiscussion />;
    case "Quiz":
      return <SvgQuiz />;
    case "File":
      return <SvgCoursesIcon />;
    case "Link_Tree":
      return <SvgLinkTree />;
    case "Folder":
      return <SvgFolderIcon />;
    case "Html":
      return <SvgMonitorIcon />;
    case "Online_Session":
      return <SvgMic />;
    case "Teams":
      return <SvgTeams/>;
    case "Survey":
      return <SvgSurveyIcon />;
    case "Announcement":
    case "announcement":
      return <SvgAnnouncement />;
    case "Date":
    case "Calendar":
      return <SvgCalendarIcon />;
    case "missingSubmit":
      switch (refTable) {
        case "AssignmentActivities":
          return <SvgNews color="#D85D66" />;
        case "QuizActivities":
          return <SvgQuiz color="#D85D66" />;
        case "ForumActivities":
          return <SvgDiscussion color="#D85D66" />;
        case "SurveyActivities":
          return <SvgSurveyIcon color="#D85D66" />;
        default:
          return <SvgNotificationIcon />;
      }
    case "createGrade":
      switch (refTable) {
        case "AssignmentActivities":
          return <SvgNews color="#D85D66" />;
        case "QuizActivities":
          return <SvgQuiz color="#D85D66" />;
        case "ForumActivities":
          return <SvgDiscussion color="#D85D66" />;
        case "SurveyActivities":
          return <SvgSurveyIcon color="#D85D66" />;
        default:
          return <SvgNotificationIcon />;
      }
    default:
      return <SvgNotificationIcon />;
  }
};
