import styled from "styled-components";

export const ParticipantInstructorsTextPlace = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const ParticipantMainWrapper = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  gap: 5rem;
  padding-bottom: 1.3rem;
`;
