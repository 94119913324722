import React, { useContext, useEffect, useState } from "react";
import { Container, CourseCard, CourseCardDesc, CourseCardImage, CourseCategory, CourseCategoryCont, CourseCode, CoursesContainer, FlexDivCol, FlexDivRow, HeadPage, Sorting, Progress, ProgressBarCont, FlexBetween, ProgressBarLine, NoData, FilterButton, FiltersSection, FilterButtonsCont, } from "./ClassesInProgress.style";
import Loader from "../loader/Loader";
import CourseImage from "../../assets/Rectangle1177.png";
import CourseImageLight from "../../assets/noCourseLight.jpg";
import { SvgBackArrow, SvgClock, SvgEllipse23, SvgLocation, SvgSettingGroup4153, SvgSettingIcon, SvgvidSettings } from "../../elements/Icons";
import { LightTypography, MediumTypography, Typography, TypographyRegularDescription, } from "../../elements/fonts/Fonts";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../utils/StringManipulation";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import DOMPurify from "dompurify";
import { S3Get } from "../../services/S3Get";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as SvgCloseIcon } from "../../assets/icons/Group_2185.svg";
import { ReactComponent as SvgDoneIcon } from "../../assets/icons/Done.svg";
import { ReactComponent as SvgCredentialIcon } from "../../assets/icons/Credential.svg";
import { ReactComponent as SvgStarIcon } from "../../assets/icons/Path 4030.svg";
import { ReactComponent as SvgRefreshIcon } from "../../assets/icons/Group 2226.svg";
import { ReactComponent as SvgNoResultIcon } from "../../assets/icons/Group 3182.svg";
import { CourseData, OptionsFilterArchived } from "./ClassesInProgress.data";
import SearchWithFilter from "../../elements/searchWithFilter/SearchWithFilter";
import CourseFilter from "../courseFilters/CourseFilter";
import DropDownSelect from "../../elements/dropDownSelect/SelectInput";
import { OptionsFilter } from "./ClassesInProgress.data";
import FilterCourses from "./FilterCourses";
import SortCourses from "./SortCourses";
import { useRecoilState, useRecoilValue } from "recoil";
import { ColorSelector, MainColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { formatOptionsForTranslation } from "../../utils/FormatOptionsForTranslation";
import SelectDropDown from "../../elements/selectDropDown/SelectDropDown";
import TitleHeaderTextIconNavigator from "../../elements/TitleHeaderTextIconNavigator/TitleHeaderTextIconNavigator";
import { MyContextProvider, useMyContext } from "../../contexts/QuestionBanksContext";
import ClassInProgressFilter from "../classInProgressFilters/ClassInProgressFilter";
import PopUp from "../../elements/Popup/PopUp";
import FilterSelected from "../../elements/filterSelected/FilterSelected";
import { adminState } from "../../recoil/RecoilStates";
// import { adminState } from "../../recoil/RecoilStates";
import { getArabicHourLabel, toArabicDigits } from "../../utils/formatDayArabic";
import { FlexDiv } from "../../globalStyles";

export const ClassesInProgress = (): JSX.Element => {
  const { backgroundLinearColor, boxShadowAllClasses, themeTextColor } = useRecoilValue(ColorSelector);
  const navigate = useNavigate();
  const { user } = useAuth0();
  const theme = localStorage.getItem("theme");
  const language = localStorage.getItem("language")

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showFilterPopUp, setShowFilterPopup] = useState<boolean>(false);
  const [selectedIntructerStored, setSelectedIntructerStored] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [selectedClassFilter, setClassSelectedFilter] = useState<string>("");
  const [courseBackgroundImage, setCourseBackgroundImage] = useState("")
  const [classFilter, setClassFilter] = useState("Classes in Progress")
  // Arrays to store the academic session titles and course titles
  const [isAdmin] = useRecoilState(adminState);
  const [courseImage, setCourseImage] = useState<{ [key: string]: string; }>({});

  const [selectedCourseFilters, setSelectedCourseFilters] = useState<{
    [key: string]: string[];
  }>({
    "Academic Sessions": [],
    "Class Category": [],
  });
  const { t } = useTranslation();
  const { setAcademicSessionTitles, setCourseTitles, selectedAcademicSession, selectedCourse, setselectedAcademicSession, setselectedCourse } =
    useMyContext();

  useEffect(() => {
    setCourseBackgroundImage(theme === "dark" ? CourseImage : CourseImageLight)
  }, [theme])

  const handleFilterChange = (option: any) => {
    setSelectedFilter(option);
  };

  const handleClassFilterChange = (option: any) => {
    setClassFilter(option);
  };

  const getUserProfile = async (src: any, sourceId: string) => {
    const profile = await S3Get(src);
    setCourseImage &&
      setCourseImage((prevCourseImage) => ({
        ...prevCourseImage,
        [sourceId]: profile,
      }));
  };

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const tenantName = localStorage.getItem("tenant");
      try {
        const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
        const provider = auth0ProviderSplitter(user?.sub || "");
        let response;
        if (isAdmin === true) {
          response = await axios.get(
            `https://9z6xeihi9l.execute-api.eu-west-1.amazonaws.com/dev/admin/classes/${tenantName}?provider=${provider}`
          );
        } else {
          if (classFilter === "Archived Classes") {
            response = await axios.get(
              `https://dz0k14jayb.execute-api.eu-west-1.amazonaws.com/dev/classes/archived/${subIdSplitted}/${tenantName}?provider=${provider}`
            );
          } else if (classFilter === "completed classes") {
            response = await axios.get(
              `https://dz0k14jayb.execute-api.eu-west-1.amazonaws.com/dev/classes/done/${subIdSplitted}/${tenantName}?provider=${provider}`
            );
          } else {
            response = await axios.get(
              `https://dz0k14jayb.execute-api.eu-west-1.amazonaws.com/dev/classes/onGoing/${subIdSplitted}/${tenantName}?provider=${provider} `
            );
          }
        }
        // const response = await axios.get( `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/enrollments/byUser/all/${tenantName}/${subIdSplitted}?provider=${provider}` );
        const filteredData = response.data.data.filter((item: { structured: boolean }) => item.structured !== false);
        // Iterate through each course and fetch the profile image
        for (const course of filteredData) {
          if (course.bgImage) {
            await getUserProfile(course.bgImage, course.id);
          }
        }
        setData(filteredData);
        // console.log("data***", filteredData)
        setLoading(false);
      } catch (error) {
        console.error("Error fetching initial data:", error);
        setError("Error fetching initial data. Please try again.");
        setLoading(false);
      }
    };

    fetchData();
  }, [classFilter]);

  // useEffect(()=>{
  //   console.log("data**", data)
  // },[data])

  useEffect(() => {
    const sessionTitlesSet = new Set<string>();
    const courseTitlesSet = new Set<string>();

    data.forEach((classDetails) => {
      // const classDetails = classObject[0];
      if (classDetails?.academicSessionTitle) {
        sessionTitlesSet.add(classDetails.academicSessionTitle);
      }
      if (classDetails?.courseTitle && classDetails?.courseCode) {
        const courseTitleWithCode = `${classDetails.courseTitle} - ${classDetails.courseCode}`;
        courseTitlesSet.add(courseTitleWithCode);
      }
    });

    // Convert Sets to arrays
    const uniqueSessionTitles = Array.from(sessionTitlesSet);
    const uniqueCourseTitles = Array.from(courseTitlesSet);

    setAcademicSessionTitles(uniqueSessionTitles);
    setCourseTitles(uniqueCourseTitles);
  }, [data]);


  const handleClassFilter = (filterType: string) => {
    setShowFilterPopup(true)
    setClassSelectedFilter(filterType)
  }

  // filter function for search
  const filteredCourses = data.filter(
    (course) => course.title.toLowerCase().includes(searchTerm.toLowerCase()) || course.courseCode.toLowerCase().includes(searchTerm.toLowerCase())
  );
  useEffect(() => {
    Object.keys(selectedCourseFilters).forEach((key) => {
      const filtersForCurrentKey = selectedCourseFilters[key];
    });
  }, [selectedCourseFilters]);

  // filter courses
  const filteredAndFilteredCourses = selectedCourseFilters && Object.keys(selectedCourseFilters).some(
    (key) => selectedCourseFilters[key].length > 0
  ) ? FilterCourses(selectedCourseFilters, filteredCourses) : filteredCourses;

  // sort courses
  let sortedFilteredCourses = SortCourses(selectedFilter, filteredCourses, filteredAndFilteredCourses);

  const finalFilter = sortedFilteredCourses.filter((course) => {
    const hasAcademicSessionMatch = selectedAcademicSession
      ? course.academicSessionTitle.includes(selectedAcademicSession)
      : true;

    const hasCourseMatch = selectedCourse
      ? course.courseTitle.includes(selectedCourse.split(" ")[0])
      : true;

    return hasAcademicSessionMatch && hasCourseMatch;
  });

  // handle close filter popup
  const handleClosePopup = () => {
    setShowFilterPopup(false);
  };

  // handle apply filters from popup
  const handleApplyFilters = (filters: any) => {
    setSelectedCourseFilters(filters); // Save the selected filters in the state
  };

  // Call the function to format OptionsFilter
  const translatedOptions = formatOptionsForTranslation(OptionsFilter, t);
  const translatedOptionsFilterArchived = formatOptionsForTranslation(OptionsFilterArchived, t)

  if (loading)
    return <Loader margin="30vh 0 0 0" text={`${t("general.loading")}...`} />;

  return (
    <Container>
      {/* <Typography text={t('dashboard.classes in progress')} fontSize="1.75rem" /> */}
      {isAdmin === true ? (
        <TitleHeaderTextIconNavigator title={t('dashboard.classes in progress')} fontSize="1.75rem" navigateTo={-1} />
      ) : (
        <FlexDiv pointer="true">
          <SvgBackArrow color="#5dd3b3" width={12} height={18} onClick={() => navigate(-1)} style={{ transform: language === "ar" ? "rotate(180deg)" : "" }} />
          <SelectDropDown gap="1rem" titleWeight="bold" titleFontSize="1.75rem" color={themeTextColor} paddingRL="0" onChange={(option) => { handleClassFilterChange(option) }} options={translatedOptionsFilterArchived} value={classFilter} width="fit-content" border="none" />
        </FlexDiv>
      )}

      {/* search & filter */}
      <HeadPage>
        <SearchWithFilter
          placeholder={t('dashboard.Search')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          // onClick={() => setShowFilterPopup(true)}
          withFilter={false}
        />
        <SelectDropDown onChange={(option) => handleFilterChange(option)} placeholder={t('view all.Sorting')} fontSize="0.875rem" borderRadius="6.25rem" borderColor="#5DD3B3" color="#5DD3B3" width="14rem" height="3.125rem" options={translatedOptions} value={selectedFilter} />
        {selectedFilter && (
          <SvgRefreshIcon style={{ cursor: "pointer" }} onClick={() => { setSelectedFilter(""); }} />
        )}
      </HeadPage>

      {finalFilter && finalFilter.length > 0 ? (
        <FiltersSection>
          <MediumTypography text={t("dashboard.Filter")} />
          <FilterButtonsCont>
            <FilterButton onClick={() => handleClassFilter("Academic Session")}>{t("dashboard.Academic Session")}</FilterButton>
            <FilterButton onClick={() => handleClassFilter("Course")}>{t("dashboard.Course")}</FilterButton>
          </FilterButtonsCont>
          <FilterButtonsCont>
            {selectedAcademicSession && (
              <FilterSelected text={t("dashboard.Academic Session")} labelContext={selectedAcademicSession} labelContextClickFunction={() => { setselectedAcademicSession("") }} />
            )}
            {selectedCourse && (
              <FilterSelected text={t("dashboard.Course")} labelContext={selectedCourse} labelContextClickFunction={() => { setselectedCourse("") }} />
            )}
          </FilterButtonsCont>
        </FiltersSection>
      ) : ""}

      {/* no result found */}
      {finalFilter.length === 0 ? (
        <NoData>
          <div style={{ marginLeft: "4rem" }}> <SvgNoResultIcon /> </div>
          <Typography text={t('gradebook.no result found')} fontSize="1.375rem" />
          <LightTypography text={t('gradebook.Try again using another filter')} fontSize="1rem" />
        </NoData>
      ) : (
        <CoursesContainer>
          {finalFilter && finalFilter.map((val, index) => (
            <React.Fragment key={index}>
              {val && (
                <CourseCard key={index} theme={theme} boxShadow={boxShadowAllClasses}>
                  {/* course image & code */}
                  <CourseCardImage bgimage={courseImage[val.id] ? courseImage[val.id] : courseBackgroundImage} onClick={() => navigate(`/courseView/about/${val.id}`)} >
                    <CourseCode gradientColor={val.gradientColor[0]} >
                      {val.courseCode}
                    </CourseCode>
                  </CourseCardImage>
                  <CourseCardDesc>
                    {/* course name & duration */}
                    <FlexDivCol gap="0.62rem">
                      <Typography text={val.title || ""} fontSize="1rem" />
                      {val.numberOfHours ? (
                        <FlexDivRow>
                          <SvgCloseIcon />
                          <LightTypography
                            text={`${val.numberOfHours
                              ? language === "ar" ? `${t("course view.Duration")} ${toArabicDigits(val.numberOfHours.toString())} ${getArabicHourLabel(val.numberOfHours)}`
                                : `${val.numberOfHours} ${t("view all.Hours Duration")}`
                              : t("view all.No Duration")
                              }`} fontSize="0.625rem" />
                        </FlexDivRow>
                      ) : ("")}
                    </FlexDivCol>

                    {/* <CourseCategoryCont gap="0.62rem">
                        {val?.Category.map((cat: any, index: any) => (
                          <CourseCategory key={index}>{cat}</CourseCategory>
                        ))}
                      </CourseCategoryCont> */}

                    {/* {val?.Progress !== 100 && (
                        <Typography text={ val?.Progress === 0 ? "Not Started" : "In Progress" } fontSize="0.75rem" />
                      )} */}

                    {/* <FlexBetween>
                        {val?.Progress === 100 && new Date(val.DueDate) > new Date() ? (
                          <FlexDivRow gap="0.56rem">
                            <SvgDoneIcon />
                            <Typography text="Completed" fontSize="0.75rem" color="#fff" // Completed color />
                          </FlexDivRow>
                        ) : (
                          <Typography
                            text={ val?.DueDate && new Date(val.DueDate) < new Date() ? "Overdue" : "Due Date" }
                            fontSize="0.75rem"
                            color={ val?.DueDate && new Date(val.DueDate) < new Date() ? "#D85D66" // Overdue color : "#CED35D" // Default color }
                          />
                        )}

                        <Typography
                          text={val?.DueDate ?? ""}
                          fontSize="0.75rem"
                          color={ val?.Progress === 100 && new Date(val.DueDate) > new Date() ? "#5DD3B3" // Completed color : val?.DueDate && new Date(val.DueDate) < new Date() ? "#D85D66" // Overdue color : "#CED35D" // Default color }
                        />
                      </FlexBetween> */}

                    {/* {val?.Progress === 100 &&
                        new Date(val.DueDate) > new Date() && (
                          <>
                            <FlexDivRow gap="0.56rem">
                              <SvgCredentialIcon />
                              <Typography text="Credentials" fontSize="0.75rem" color="#fff" // Completed color />
                            </FlexDivRow>
                          </>
                        )} */}

                    {/* {val?.isMandatory && (
                        <>
                          <FlexDivRow gap="0.56rem">
                            <SvgStarIcon />
                            <Typography text="Mandatory" fontSize="0.75rem" />
                          </FlexDivRow>
                        </>
                      )} */}

                    {/* <ProgressBarCont onClick={() => navigate(`/completionTracking/${val[0].classData.classSourceId}`)}>
                        <Typography text={`${val?.Progress}%`} fontSize="0.625rem" color={val[0].classStructure.color} />

                        <ProgressBarLine>
                          <Progress width={`${val?.Progress}%`} backgroundColor={val[0].classStructure.color} />
                        </ProgressBarLine>
                      </ProgressBarCont> */}
                  </CourseCardDesc>
                </CourseCard>
              )}
            </React.Fragment>
          ))}
        </CoursesContainer>
      )}
      {showFilterPopUp && (
        <PopUp
          icon={<SvgSettingIcon fill={themeTextColor} />}
          title={t(`dashboard.${selectedClassFilter}`)}
          content={<ClassInProgressFilter selectedClassFilter={selectedClassFilter} />}
          showline={false}
          show={showFilterPopUp}
          setShow={setShowFilterPopup}
          showButton={false}
        />
      )}
    </Container>
  );
};
