import React, { FC, useEffect } from "react";
import {
  Overlay,
  BackgroundOverlay,
  BackgroundSecondaryOverlay,
  PopupContainer,
  PopupContentContainer,
  CloseDiv,
  TitleAndIcon,
} from "./CourseCreatorPopups.style";
import AssignmentForm from "../../components/AssignmentForm/AssignmentForm";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { SvgClose } from "../../elements/Icons";
import { useStateContext } from "../../contexts/ContextProvider";
import QuizForm from "../../components/quizForm/QuizForm";
import OnlineSessionForm from "../../components/OnlineSessionForm/OnlineSessionForm";
import SurveyForm from "../../components/surveyForm/SurveyForm";
import ForumForm from "../../components/forumForm/ForumForm";
import { useQuizMyContext } from "../../contexts/CourseCreatorQuizContext";
import { useMyContext } from "../../contexts/CourseCreatorContext";
import FileForm from "../../components/FileForm/FileFrom";
import DocumentForm from "../../components/DocumentForm/DocumentForm";
import FolderForm from "../../components/FolderForm/FolderForm";
import UrlLinkForm from "../../components/UrlLinkForm/UrlLinkFrom";
import LinkTreeForm from "../../components/LinkTreeForm/LinkTreeForm";
import VideoForm from "../../components/VideoForm/VideoForm";
import HtmlForm from "../../components/HtmlForm/HtmlForm";
import ImageForm from "../../components/ImageForm/ImageForm";
import TeamsForm from "../../components/teamsForm/TeamsForm";
import MediaForm from "../../components/mediaForm/MediaForm";
import { CourseCreatorPopupsProps } from "./CourseCreatorPopupsInterface";
import ScormForm from "../../components/ScormForm/ScormForm";
import { AssignmentContext } from "../../contexts/CourseCreatorAssignmentContext";
import { ForumContext } from "../../contexts/ForumContext";
import { PrimaryFontBoldText } from "../../elements/fonts/Fonts";
import { t } from "i18next";
import { getIconType } from "../../utils/getIconType";
import { FlexBetween } from "../../globalStyles";

const CourseCreatorPopups: FC<CourseCreatorPopupsProps> = ({
  show,
  title,
  setShow,
  handleSubmit,
  setShowEditContent,
  showEditContent,
  newContentTitle,
  contentId,
  editContent,
  setContentTitleInTopic,
  activeGetModulesWithTopics,
  topicRefId,
  removeContent,
  topic,
  contentIndex,
}) => {
  const { backgroundColor, iconCloseColor, overlayBgColor } =
    useRecoilValue(ColorSelector);
  const {
    setIsPopUpOpen,
    setGlobalShowEditContent,
    contentType,
    setContent_Type,
    setPopupOverlay,
    popupOverlay,
  } = useStateContext();
  const { setGlobalQuizShowPopup, setIsPopupAllDroped } = useQuizMyContext();
  const { setGlobalAssignmentShowPopup } = AssignmentContext();
  const { setGlobalShowPopup } = useMyContext();
  const { setGlobalForumShowPopup } = ForumContext();
  const {themeTextColor} = useRecoilValue(ColorSelector)

  const getFormComponent = () => {
    switch (contentType) {
      case "Assignment":
        return AssignmentForm;
      case "Quiz":
        return QuizForm;
      case "Online_Session":
        return OnlineSessionForm;
      case "Survey":
        return SurveyForm;
      case "Forum":
        return ForumForm;
      case "File":
        return FileForm;
      case "Document":
        return DocumentForm;
      case "Folder":
        return FolderForm;
      case "Link":
        return UrlLinkForm;
      case "Link_Tree":
        return LinkTreeForm;
      case "Video":
        return VideoForm;
      case "Scorm":
        return ScormForm;
      case "Html":
        return HtmlForm;
      case "Media":
        return MediaForm;
      case "Picture":
        return ImageForm;
      case "Teams":
        return TeamsForm;
    }
  };

  const FormComponent = getFormComponent();

  return (
    <>
      {FormComponent ? (
        <Overlay isvisible={show.toString()}>
          <BackgroundOverlay
            bgoverlaycolor={overlayBgColor}
            contenttype={contentType}
          >
            <PopupContainer>
              <PopupContentContainer backgroundcolor={backgroundColor}>
                <FlexBetween>
                <TitleAndIcon themetextcolor={themeTextColor}>
                  {getIconType(contentType)}
                <PrimaryFontBoldText
                  text={t(`notification.${contentType}`)}
                  fontSize="1.125rem"
                  color={themeTextColor}
                />
                </TitleAndIcon>
                <CloseDiv>
                  <SvgClose
                    color={iconCloseColor}
                    onClick={() => {
                      setShow(false);
                      setShowEditContent(false);
                      setIsPopUpOpen(false);
                      setGlobalShowEditContent(false);
                      setGlobalQuizShowPopup(false);
                      setGlobalShowPopup(false);
                      setGlobalAssignmentShowPopup(false);
                      setGlobalForumShowPopup(false);
                      setContent_Type(
                        `${
                          contentType === "Assignment" ||
                          contentType === "Quiz" ||
                          (contentType === "Survey" ? " " : contentType)
                        }`
                      );

                      // setIsPopupAllDroped(false);
                      window.localStorage.removeItem("currentFolderName");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </CloseDiv>
                </FlexBetween>

                <FormComponent
                  setShow={setShow}
                  handleSubmit={handleSubmit}
                  setShowEditContent={setShowEditContent}
                  setContentTitleInTopic={setContentTitleInTopic}
                  editContent={editContent}
                  contentId={contentId}
                  newContentTitle={newContentTitle}
                  showEditContent={showEditContent}
                  activeGetModulesWithTopics={activeGetModulesWithTopics}
                  newTopicRefId={topicRefId}
                  removeContent={() => {
                    if (contentId !== undefined && contentIndex !== undefined) {
                      removeContent &&
                        removeContent(topic, contentId, contentIndex);
                    }
                  }}
                  topic={topic}
                  contentIndex={contentIndex}
                />
              </PopupContentContainer>
            </PopupContainer>
            {/* <BackgroundSecondaryOverlay
              bgOverlayColor={overlayBgColor}
              contentType={contentType}
            ></BackgroundSecondaryOverlay> */}
          </BackgroundOverlay>
        </Overlay>
      ) : (
        ""
      )}
    </>
  );
};

export default CourseCreatorPopups;
