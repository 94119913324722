import styled from "styled-components";
import ReactQuill from "react-quill";
import { theme } from "../../../theme";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";
export const QuellStyle = styled.div`
  /* display: flex;
  width: 100%;
height: 13.25rem; */
  padding: 0 1.44rem 0rem 1.5rem;
  color: #fff;
  /* margin-bottom: 1.31rem; */
`;

export const StyledQuillEditor = styled(ReactQuill)<{
  show: any;
  hasError?: boolean;
}>`
  width: 100%;
  border-radius: 2rem !important;

  height: 100%;

  /* margin-bottom: 2rem; */

  .ql-container {
    border-radius: 0 0 1rem 1rem;
    min-height: 6rem !important;
    border-color: ${(props) => (props.hasError ? "#D85D66" : "#576371")};
    background: ${(props) => (props.show === "true" ? "#546270" : "")};
  }

  .ql-toolbar {
    border-radius: 1rem 1rem 0 0;
    border-color: ${(props) => (props.hasError ? "#D85D66" : "#576371")};
    background: ${(props) => (props.show === "true" ? "#546270" : "")};
    border-bottom: ${(props) =>
      props.show === "true" ? "2px solid #7f8993" : ""};
  }
  /* Target the icons in the main toolbar */
  .ql-toolbar .ql-formats span {
    color: #fff !important;
  }
  .ql-toolbar .ql-picker-item::before {
    color: #000 !important;
    width: 2rem;
  }
  .ql-snow .ql-stroke {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
  }
  .ql-snow .ql-even {
    color: #fff;
  }
  .ql-editor > * {
    font-family: "Primary Font-Light";
  }
  .ql-snow .ql-editor::before {
    /* content: attr(data-placeholder); */
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.875rem;
    font-family: "Primary Font-Light";
  }
`;

export const QuestionIconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.62rem;
  cursor: pointer;
`;

export const PointsInput = styled.input<{
  hasError: boolean;
  color?: string;
  borderColor?: string;
}>`
  background: transparent;
  border-radius: 0.625rem;
  border: 1px solid #57637180;
  border-color: ${(props) => (props.hasError ? "#D85D66" : props.borderColor)};
  width: 4.5625rem;
  height: 1.9375rem;
  padding: 0.94rem 0.44rem;
  color: ${(props) => props.color};
  font-family: "Primary Font-Light";
  font-size: 0.875rem;
  &&::placeholder {
    font-family: "Primary Font-Light";
    opacity: 0.5;
  }
  &&:focus {
    outline: none;
    color: ${(props) => props.color};
  }
`;

export const Points = styled.div`
  border-radius: 0.625rem;
  min-width: 4.5625rem;
  width: fit-content;
  height: 1.9375rem;
  padding: 0.44rem 0.5rem 0.44rem 0.5rem;
  background-color: ${theme.dark.main_color};
`;

export const QuestionHeaderContainer = styled.div`
  padding: 1.19rem 1.5rem 0.94rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const FeedBackContainer = styled.div`
  padding: 1.25rem 1.5rem 0.5rem 1.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.375rem;
`;

export const Tooltip = styled.span<StyleThemeInterface>`
  visibility: hidden;
  width: fit-content;
  height: 1.75rem;
  background: ${(props) => props.bgcolor || "#1F2731"};
  padding: 0.5rem 1.38rem 0.63rem 1.25rem;
  box-shadow: ${(props) => props.boxshadow};
  text-align: center;
  border-radius: 5px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0.55rem;
  /* right: -2rem; */
  top: 1.5rem;
  border-radius: 15px;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-family: "Primary Font-Medium";
  font-size: 0.625rem;
  z-index: 1;
`;

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    /* background-color: #5DD3B3; */
    ${Tooltip} {
      visibility: visible;
      opacity: 1;
    }
  }
`;
