import React, { useEffect, useState } from "react";
import {
  CalendarDiv,
  EventDiv,
  EventActions,
  EventText,
  InnerCalendarDiv,
  NavigationHolder,
  EventDescription,
  EmptyEventHolder,
} from "./CalendarHolder.style";
import TextElement from "../../elements/textElement/TextElement";
import DateHolder from "../../elements/DateHolder/DateHolder";
import {
  LightTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import ButtonElement from "../../elements/button/ButtonElement";
import {
  SvgCalendarNext,
  SvgCalendarPrev,
  SvgEvent,
  SvgMic,
  SvgNextIcon,
} from "../../elements/Icons";
import Calendar from "rc-calendar";
import { ButtonsDiv } from "../announcementCard/AnnouncementCard.style";
import moment, { Moment } from "moment";
import { get } from "../../utils/AxiosRequests";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../utils/StringManipulation";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  CalendarColorSelector,
  ColorSelector,
  MainColorSelector,
} from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { formatEventData } from "../../utils/FormatedDATE";
import "moment/locale/tr"; // Import Turkish locale data
import "moment/locale/en-gb"; // Import English locale data
import "moment/locale/fr";
import "moment/locale/ar";
import DatePicker from "react-datepicker";
import { enUS, tr, ar, fr } from "date-fns/locale"; // Import locales as needed
import { registerLocale, setDefaultLocale } from "react-datepicker";
import StyledDatePicker from "../../elements/TwoDatePicker/TwoDatePicker.style";
import { DatePickerWapper } from "./NewCalendar.style";
import { breakpoints } from "../../theme";
import DOMPurify from "dompurify";
import { formatDayContents, toArabicDigits } from "../../utils/formatDayArabic";
import axios from "axios";
import { adminState } from "../../recoil/RecoilStates";

export default function CalendarHolder() {
  const [isAdmin] = useRecoilState(adminState)
  const { t } = useTranslation();
  const { user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const [currentIndex, setCurrentIndex] = useState(0);
  const {
    specialColor2,
    themeTextColor,
    backgroundColor,
    boxShadowBanner,
    theme,
    inputBorderColor,
  } = useRecoilValue(ColorSelector);
  const { calendarBgColor, tilescolor, titlecolor, calendarDayBgColor } =
    useRecoilValue(CalendarColorSelector);
  const { mainBackgroundColor } = useRecoilValue(MainColorSelector);

  const [selectedDate, setSelectedDate] = useState<Moment>(moment(new Date()));
  const [selectedTasks, setSelectedTasks] = useState<any[]>([]);
  const [tasks, setTasks] = useState<any[]>([]);
  const [language, setLanguage] = useState<any>();
  const storedLanguage = localStorage.getItem("language")

  const customLocaleEn = {
    ...enUS,
    localize: {
      ...enUS.localize,
      day: (n: any) => {
        const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        return dayNames[n];
      },
    },
  };

  const customLocaleTr = {
    ...tr,
    localize: {
      ...tr.localize,
      day: (n: any) => {
        const dayNames = ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"];
        return dayNames[n];
      },
    },
  };

  const customLocaleAr = {
    ...ar,
    localize: {
      ...ar.localize,
      day: (n: any) => {
        const dayNames = [
          "أحـ",
          "إثنـ",
          "ثلثـ",
          "أربـ",
          "خميـ",
          "جمـ",
          "سبـ",
        ];
        return dayNames[n];
      },
    },
  };

  const customLocaleFr = {
    ...fr,
    localize: {
      ...fr.localize,
      day: (n: any) => {
        const dayNames = [
          "Dim",
          "Lun",
          "Mar",
          "Mer",
          "Jeu",
          "Ven",
          "Sam"
        ]
        return dayNames[n];
      },
    },
  };

  useEffect(() => {
    if (localStorage.getItem("language") === "tr") {
      setLanguage(customLocaleTr);
      moment.locale("tr");
    } else if (localStorage.getItem("language") === "ar") {
      setLanguage(customLocaleAr);
      moment.locale("ar");
    } else if (localStorage.getItem("language") === "fr") {
      setLanguage(customLocaleFr);
      moment.locale("fr");
    } else {
      setLanguage(customLocaleEn);
      moment.locale("en");
    }
  }, [t]);

  useEffect(() => {
    setDefaultLocale(language);
  }, [t]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let calendarData;
        if (isAdmin === true) {
          calendarData = await axios.get(`https://rpslsxq6sc.execute-api.eu-west-1.amazonaws.com/dev/calendar/${tenantName}?provider=${provider}`)
          setTasks(formatEventData(calendarData.data.data) || []);
        }
        else {
          calendarData = await get(`calendar/${subId}/${tenantName}`, provider);
          setTasks(formatEventData(calendarData.data) || []);
        }

      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setSelectedDate(moment());
    handleDateSelect(moment());
  }, [tasks]);
  useEffect(() => {
    setCurrentIndex(0);
  }, [selectedTasks]);
  const goToNextAnnouncement = () => {
    if (currentIndex < selectedTasks.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const goToPreviousAnnouncement = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleButtonClick = () => {
    // navigate("/to-do");
  };
  const handleJoinSession = (link: any) => {
    // navigate("/to-do");
    window.open(link, "_blank");
  };

  function handleDateSelect(date: Moment) {
    setCurrentIndex(0);
    setSelectedDate(date);
    // make always the day to reneder in eng before converting to arabic bu using locale('en')
    const selectedDateString = date.locale('en').format("YYYY-MM-DD");
    const selectedT = tasks.filter((task) => task.date === selectedDateString);

    setSelectedTasks(selectedT);
  }

  function customDateRender(current: Moment, value: Moment) {
    const currentDateString = current.format("YYYY-MM-DD");
    const isToday = current.isSame(moment(), "day");
    const hasTasks = tasks.some((task) => task.date === currentDateString);

    if (hasTasks) {
      return (
        <div
          id={currentDateString}
          className={`rc-calendar-populated-cell rc-calendar-selected-date rc-calendar-date`}
          style={{ backgroundColor: calendarDayBgColor, color: "#fff" }}
        >
          {current.date()}
        </div>
      );
    }
    // Return the default rendering for other dates
    return <div className="rc-calendar-date">{current.date()}</div>;
  }
  const isAtFirstEvent = currentIndex === 0;
  const isAtLastEvent = currentIndex === selectedTasks.length - 1;
  const eventData = selectedTasks[currentIndex];

  //status
  function getTaskStatus(task: any): string {
    if (task.type === "OnlineSession") {
      const currentDateTime = moment();
      const startDateTime = moment(task.startDate);
      const endDateTime = moment(task.endDate);

      if (endDateTime <= currentDateTime) {
        return "ended";
      } else if (startDateTime >= currentDateTime) {
        return "notStarted";
      } else if (
        startDateTime <= currentDateTime &&
        endDateTime >= currentDateTime
      ) {
        return "onGoing";
      }
    }
    return "default";
  }
  const direction = document.dir;

  function customDayClassName(date: any) {
    const momentDate = moment(date); // Convert JavaScript Date object to moment object
    // make always the day to reneder in eng before converting to arabic
    const dateStr = momentDate.locale('en').format("YYYY-MM-DD");
    const hasTasks = tasks.some((task) => task.date === dateStr);
    const isToday = momentDate.isSame(moment(), "day");

    if (isToday) {
      return "is-today";
    }
    return hasTasks ? "has-tasks" : "";
  }

  const renderCustomHeader = ({ date, decreaseMonth, increaseMonth }: any) => (
    <div className="custom-header">
      <button
        className="custom-header-arrow"
        onClick={decreaseMonth}
        style={{
          all: "unset",
          // display: "inline-block",

          cursor: " pointer",
          width: "1.3rem",
        }}
      >
        <SvgCalendarPrev color={theme === "light" ? "#364350" : "#fff"} />
      </button>
      <Typography text={moment(date).format("MMMM yyyy")} fontSize="1rem" />
      {/* <span>{moment(date).format('MMMM yyyy')}</span> */}
      <button
        className="custom-header-arrow"
        onClick={increaseMonth}
        style={{
          all: "unset",
          // display: "inline-block",
          cursor: " pointer",
          width: "1.5rem",
        }}
      >
        <SvgCalendarNext />
      </button>
    </div>
  );
  useEffect(() => {
    const updateColumnGap = () => {
      const maxWidth = 768; // maximum width in px where the column gap starts to change
      const maxGap = 3; // maximum column gap in rem
      const minGap = 0.1; // minimum column gap in rem
      const sensitivity = 300;
      const currentWidth = window.innerWidth;
      const minWidth = 400;
      if (currentWidth <= minWidth) {
        document.documentElement.style.setProperty(
          "--column-gap",
          `${minGap}rem`
        );
      } else if (currentWidth <= maxWidth) {
        const gap =
          maxGap -
          ((maxWidth - currentWidth) / sensitivity) * (maxGap - minGap);
        const clampedGap = Math.max(minGap, Math.min(maxGap, gap)); // ensure the gap stays within min and max bounds
        document.documentElement.style.setProperty(
          "--column-gap",
          `${clampedGap}rem`
        );
      } else {
        document.documentElement.style.setProperty(
          "--column-gap",
          `${maxGap}rem`
        );
      }
    };

    // Update the column gap on load and on window resize
    window.addEventListener("resize", updateColumnGap);
    updateColumnGap(); // Initial call to set the gap on load

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateColumnGap);
    };
  }, []);

  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };

  const newStoredLanguage = localStorage.getItem("language") || "en";
  const formattedDate = eventData ? moment(eventData.date).locale(newStoredLanguage).format("MMM D, YYYY") : '';
  const displayDate = newStoredLanguage === "ar" ? toArabicDigits(formattedDate) : formattedDate;


  return (
    <InnerCalendarDiv style={{ backgroundColor }} boxshadow={boxShadowBanner}>
      {/* <CalendarDiv
        titleColor={titlecolor}
        tilesColor={tilescolor}
        bgColor={calendarBgColor}
      >
        <Calendar
          selectedValue={selectedDate || moment()}
          onSelect={handleDateSelect}
          dateRender={customDateRender}
          defaultValue={moment()}
        />
      </CalendarDiv> */}
      <DatePickerWapper
        themetextcolor={themeTextColor}
        bgdatepicker={calendarBgColor}
        inputbordercolor={inputBorderColor}
        theme={theme}
      >
        <DatePicker
          readOnly
          inline
          locale={language}
          selected={selectedDate.toDate() || moment()}
          dayClassName={customDayClassName}
          onSelect={(date: Date) => handleDateSelect(moment(date))}
          onChange={() => { }}
          fixedHeight
          renderCustomHeader={renderCustomHeader}
          renderDayContents={localStorage.getItem("language") === "ar" ? (day) => formatDayContents(day) : (day) => day}
        // showWeekNumbers={selectedDate.clone().endOf("month").isoWeek() === 5}
        />
      </DatePickerWapper>
      {selectedTasks.length > 0 ? (
        <EventDiv>
          <EventText>
            <TextElement
              tag="p"
              text={eventData ? eventData.title : ""}
              fontSize="1rem"
              fontFamily="Primary Font"
              fontWeight="400"
            />
          </EventText>
          <EventDescription>
            {/* <TextElement
              tag="p"
              text={eventData ? eventData.task : ""}
              fontSize="0.875rem"
              fontFamily="Primary Font"
              fontWeight="400"
            /> */}
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  decodeHtml(eventData ? eventData.task : "")
                ),
              }}
              style={{
                fontSize: "0.875rem",
                fontWeight: "400",
                fontFamily: "Primary Font",
              }}
            ></p>
          </EventDescription>
          <EventActions>
            {selectedTasks.length > 1 && (
              <NavigationHolder>
                <LightTypography
                  fontSize="0.625rem"
                  fontWeight="400"
                  text={`${toArabicDigits((currentIndex + 1).toString())} / ${toArabicDigits(selectedTasks.length.toString())}`}
                />{" "}
                <ButtonsDiv>
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      transform: storedLanguage === "ar" ? "none" : "rotate(180deg)",
                      cursor: "pointer",
                      color: isAtFirstEvent ? "gray" : themeTextColor,
                      marginTop: storedLanguage === "ar" ? "0.3rem" : "-0.2rem",
                    }}
                    onClick={goToPreviousAnnouncement}
                    disabled={isAtFirstEvent}
                  >
                    <SvgNextIcon width={"1rem"} />
                  </button>
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      transform: storedLanguage === "ar" ? "rotate(180deg)" : "none",
                      cursor: "pointer",
                      marginTop: storedLanguage === "ar" ? "-0.2rem" : "0.3rem",
                      color: isAtLastEvent ? "gray" : themeTextColor,
                    }}
                    onClick={goToNextAnnouncement}
                    disabled={isAtLastEvent}
                  >
                    <SvgNextIcon width={"1rem"} />
                  </button>
                </ButtonsDiv>
              </NavigationHolder>
            )}
            <DateHolder date={eventData ? displayDate : ""} />
            {isAdmin === false &&
              <>
                {eventData.type === "OnlineSession" ? (
                  getTaskStatus(eventData) === "onGoing" ? (
                    <ButtonElement
                      disabledButton="onGoing"
                      text={t("dashboard.Join Class")}
                      iconPath={SvgMic}
                      clickEvent={() => handleJoinSession(eventData.link)}
                    />
                  ) : // <div>Online session ongoing</div>
                    getTaskStatus(eventData) === "ended" ? (
                      <ButtonElement
                        disabledButton="ended"
                        text={t("dashboard.Session Ended")}
                        iconPath={SvgMic}
                        clickEvent={handleButtonClick}
                      />
                    ) : getTaskStatus(eventData) === "notStarted" ? (
                      <ButtonElement
                        disabledButton="notStarted"
                        text="Session Not Yet Due"
                        iconPath={SvgMic}
                        clickEvent={handleButtonClick}
                      />
                    ) : (
                      // <div>Not yet</div>
                      <></>
                    )
                ) : (
                  <></>
                )}
              </>
            }
            {/* <ButtonElement
              text="Add New Task"
              iconPath={SvgAddTaskIcon}
              clickEvent={handleButtonClick}
            /> */}
          </EventActions>
        </EventDiv>
      ) : (
        <EmptyEventHolder>
          <SvgEvent width="4rem" height="4rem" color={specialColor2} />
          {moment(selectedDate).isSame(moment(), "day") ? (
            <LightTypography
              text={`${t("view all.There are no upcoming events today!")}`}
            />
          ) : (
            <LightTypography
              text={`${t(
                "view all.There are no upcoming events on this day!"
              )}`}
            />
          )}
        </EmptyEventHolder>
      )}
    </InnerCalendarDiv>
  );
}
