import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";
interface TimerContainerProps {
  attemptComment: boolean;
  children?: React.ReactNode; // Include children prop here
  // other props if any
}

export const StudentQuizViewLayout = styled.div<StyleThemeInterface>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 1.25rem;
  background: ${(props) =>
    props.bgcolor || "linear-gradient(180deg, #415160 0%, #293440 100%)"};
  border-radius: 0.625rem;
  box-shadow: ${(props) => props.boxshadow};
`;

export const StudentQuizPagination = styled.div`
  /* width: 100%; */
  &:dir(ltr) {
    margin-left: -2rem;
  }
  &:dir(rtl) {
    margin-right: -2rem;
  }
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  /* margin-top:1rem; */
  gap: 0.5rem;
`;

export const StudentQuizViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(
    --background-card,
    linear-gradient(180deg, #415160 0%, #293440 100%)
  );
  padding: 1.56rem 1.25rem;
  gap: 1.25rem;
`;

export const StudentFirstRender = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-bottom: 2rem;
  /* gap: 0.63rem;
margin-top:-0.62rem; */
`;

export const StudentSecondRender = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  /* gap: 0.63rem; */
  /* margin-top:-0.62rem; */
`;

export const StudentTextStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: column; */
  /* gap: 1.25rem; */
  /* gap: 0.63rem; */
  margin-top: -0.62rem;
`;

export const AttemptContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const AllAttemptContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const NumberContainer = styled.div`
  padding: 0.1rem 0.5rem;
  margin: 0.4rem;
  border: 1px solid #576371;
  color: #576371;
  border-radius: 5px;
  font-size: 1rem;
`;

export const IconContainer = styled.div`
  background-color: #5dd3b3;
  border-radius: 3px;
  margin: 0.4rem;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  text-align: center;
`;
export const TimerContainer = styled.div`
  position: absolute;
  &:dir(ltr) {
    right: 0;
  }
  &:dir(rtl) {
    left: 0;
  }
  top: -150px;
  display: flex;
  flex-direction: column;
  /* align-items: center;
justify-content:space-between;
width: 9rem; */
`;

export const GradeContainerAbsolute: React.FC<TimerContainerProps> = styled.div`
  position: absolute;

  top: ${(props) => (props.attemptComment ? "-260px" : "-140px")};
  display: flex;
  &:dir(ltr) {
    right: 0;
  }
  &:dir(rtl) {
    left: 0;
  }
`;
export const AttempContainerAbsolute: React.FC<TimerContainerProps> = styled.div`
  position: absolute;

  top: ${(props) => (props.attemptComment ? "-290px" : "-170px")};
  &:dir(ltr) {
    right: 0;
  }
  &:dir(rtl) {
    left: 0;
  }
`;

export const GradeContainer = styled.div`
  border: 0.15rem dashed #5dd3b3;
  width: fit-content;
  padding: 1rem;
`;

export const InsideTimerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem;
  /* width: 9rem; */
`;

export const CancelButtonContainer = styled.div`
  margin-right: 3rem;
  margin-top: 1rem;
`;
