import styled from "styled-components";
import { breakpoints } from "../../theme";

export const MainHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2.86rem;
`;

export const TitleWithIconsHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.62rem;
  @media (max-width: ${breakpoints.tablets}) {
    align-items: start;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  column-gap: 0.62rem;
  & > :is(:first-child, :last-child):hover {
    cursor: pointer;
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
`;

export const Description = styled.div`
  margin-bottom: 4.75rem;
`;

export const ProfileDateHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;
  row-gap: 1.25rem;
`;
export const DateHolder = styled.div``;

export const DivideLine = styled.div`
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: #576371;
  margin-top: 1.91rem;
  margin-bottom: 0.96rem;
`;
