import React, { FC } from 'react';
import { AssignementContainer } from './StatisticsAssignement.style';
import { Typography, LightTypography } from '../../../elements/fonts/Fonts';
import { SvgBackArrow } from '../../../elements/Icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFormattedDate } from '../../../utils/GetFormattedDate';

interface StatisticsAssignement {
    studentIndex:number;
    student:any[];
    allAssignment:any;
    forum?:boolean;
    quiz?:boolean;
} 

const StatisticsAssignement: FC<StatisticsAssignement> = ({
    studentIndex,
    student,
    allAssignment,
    forum,
    quiz
}:StatisticsAssignement) => {
    const { t } = useTranslation();
    // const getFormattedDate = (createdAt: string) => {
    //     const date = new Date(createdAt);
    //     const formattedDate = `${date.toDateString()} ${date.toLocaleTimeString()}`; // Change this according to your preferred date and time format
    //     return formattedDate;
    // };
    const language = localStorage.getItem("language")
    return (
        <>
            <AssignementContainer>   
            {quiz && allAssignment && allAssignment.titleActivity && (
                <Typography text={`${allAssignment.titleActivity}`} fontSize='1.125rem' fontWeight='400' lineHeight='normal' />
            )}
            {!quiz && allAssignment && allAssignment.title && (
                <Typography text={`${allAssignment.title}`} fontSize='1.125rem' fontWeight='400' lineHeight='normal' />
            )}
                {forum && !quiz ? 
                <div>  
                    <LightTypography
                    text={`${t('assignment.Submitted on').toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")} ${student && student.length > 0 && getFormattedDate(student[studentIndex].submissions[0].postTime)}`}
                    fontSize='0.75rem'
                    fontWeight='400'
                    lineHeight='normal'
                />
                </div>
                 : !quiz ?
                 <div>
                    <LightTypography
                        text={`${t('assignment.Submitted on').toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")} ${student && student.length > 0 && getFormattedDate(student[studentIndex].createdAt)}`}
                        fontSize='0.75rem'
                        fontWeight='400'
                        lineHeight='normal'
                    />
                 </div>
                : quiz ?
                <LightTypography
                    text={`${t('quiz.Total Number Of Attempts')}: ${allAssignment && allAssignment.numberOfAttempts !== -1 ? allAssignment.numberOfAttempts : `${t('quiz.Unlimited Attempts')}`}`}
                    fontSize='0.75rem'
                    fontWeight='400'
                    lineHeight='normal'
                />
                :""
                 }
            </AssignementContainer> 
        </>
    )
}
export default StatisticsAssignement;
