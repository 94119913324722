import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GradeItemDetail,
  GradingDeatils,
  HorDividLine,
  QuizCard,
  Quizes,
  RecordAttemptsWrapper,
  StudentDetailsWrapper,
  StudentQuizDetailsRight,
  StudentsDetailsLeft,
  StudentsHeaderContainer,
  StudentsQuizzes,
  TextIconStyling,
} from "../AssignmentInstructor.style";
import {
  SvgAttempt,
  SvgEdit,
  SvgEmpty,
  SvgGrade,
  SvgStar,
} from "../../../elements/Icons";
import { LightTypography, Typography } from "../../../elements/fonts/Fonts";
import ReusableProfile from "../../../elements/reusableProfile/ReusableProfile";
import ProgressBar from "../../../elements/progressBar/ProgressBar";
import { get } from "../../../utils/AxiosRequests";
import {
  calculateAssignmentGrades,
} from "../InstructorViewUtils";
import { getFormattedDate } from "../../../utils/GetFormattedDate";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import AssignmentGradesDetails from "./AssignmentGradesDetails";
import { RPProfilePic } from "../../PostsTimeLine/ViewPost/ViewPost.style";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  CourseColorSelector,
} from "../../../recoil/ThemeSelectors";
import { ResourceFrom } from "../../../Views/courseView/content/Content";
import { useTranslation } from "react-i18next";
import { toArabicDigits } from "../../../utils/formatDayArabic";
interface ActivityProps {
  id: any;
  resourceR: ResourceFrom;
}
const AssignmentInstructor = ({ id, resourceR }: ActivityProps) => {
  const [AssignmentsData, setAssignmentsData] = useState<any[]>([]);
  const [students, setStudents] = useState<any[]>([]);
  const [minimumGrade, setMinimumGrade] = useState<number>(0);
  const [maximumGrade, setMaximumGrade] = useState<number>(0);
  const [average, setAverage] = useState<number>(0);
  const [isGraded, setIsGraded] = useState<boolean>(false);
  const [gradedCount, setGradedCount] = useState<number>(0);
  const [maximumGradeOfAssignment, setMaximumGradeOfAssignment] =
    useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const image = require("../../../assets/userProfile.png");
  const navigate = useNavigate();
  const tenantName = localStorage.getItem("tenant");
  const params = useParams();
  const classId = params.classId;
  const { setUserSelectedIndex } = useContext(ClassContext);
  const { ClassGradientBox } = useRecoilValue(CourseColorSelector);
  const { themeProgressColor } = useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  // GEt the Assignment Submissions By It's ID Data
  useEffect(() => {
    const getAssignmentSubmissionsById = async () => {
      try {
        const assignmentSubmissions = await get(
          `assignment/get/submission/${tenantName}/${id}`
        );
        setAssignmentsData(assignmentSubmissions.data.assignmentSubmissions);
        setMaximumGradeOfAssignment(
          assignmentSubmissions.data.assignment.maxGrade
        );
        if(assignmentSubmissions.data.assignment.maxGrade === 0 && assignmentSubmissions.data.assignment.rubricId === null){
          setIsGraded(false)
        }else{
          setIsGraded(true)
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    getAssignmentSubmissionsById();
  }, [id]);

  // Call the calculateAssignmentGrades Function to update the grades and the statistics of the assignment
  useEffect(() => {
    if (AssignmentsData && AssignmentsData.length > 0 && isGraded) {
      setStudents(
        calculateAssignmentGrades(AssignmentsData, maximumGradeOfAssignment)
          .usersArray
      );
      setMinimumGrade(
        calculateAssignmentGrades(AssignmentsData, maximumGradeOfAssignment)
          .minGradeValue
      );
      setMaximumGrade(
        calculateAssignmentGrades(AssignmentsData, maximumGradeOfAssignment)
          .maxGradeValue
      );
      setAverage(
        calculateAssignmentGrades(AssignmentsData, maximumGradeOfAssignment)
          .avgGradeValue
      );
      setGradedCount(
        calculateAssignmentGrades(AssignmentsData, maximumGradeOfAssignment)
          .countGraded
      );
    }
  }, [AssignmentsData]);

  // If There are no assignments Submissions
  if (!loading && AssignmentsData && AssignmentsData.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <SvgEmpty />
        <Typography text={t("assignment.No submissions yet")} />
      </div>
    );
  } else if (loading) {
    // If The Data isn't Fetched Yet
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography text={`${t('general.loading')}...`} />
      </div>
    );
  }

  // Function to Navigate to The Grader
  const handleGraderNavigate = (userIndex: number) => {
    setUserSelectedIndex(userIndex);
    navigate(`/grader/${id}/${classId}`, {
      state: {
        activity: resourceR,
        moduleIndex: resourceR.moduleIndex,
        topicIndex: resourceR.topicIndex,
        contentIndex: resourceR.contentIndex,
      }
    });
  };

  return (
    <>
      <>
        {(() => {
          return (
            <>
            {isGraded ? (
              <GradingDeatils>
                {/* The Assignment Grades Details Mount Here */}
                <AssignmentGradesDetails
                  minimumGrade={minimumGrade}
                  maximumGrade={maximumGrade}
                  average={average}
                  gradedCount={gradedCount}
                  AssignmentsData={AssignmentsData}
                  loading={loading}
                />
              </GradingDeatils>
            ) : ""}
              
              <HorDividLine></HorDividLine>
              <StudentsQuizzes bgColor={ClassGradientBox}>
                <StudentsHeaderContainer>
                  <Typography text={t("online session.Students")} />
                </StudentsHeaderContainer>
                {/* Here We Are Mapping the submitted students */}
                {AssignmentsData &&
                  AssignmentsData.length > 0 &&
                  AssignmentsData.map((submission, index) => (
                    <div key={index}>
                      <>
                        <StudentDetailsWrapper>
                          <div
                            style={{ display: "flex", columnGap: "0.63rem" }}
                          >
                            {/* The Student Profile Picture Goes Here */}
                            <RPProfilePic
                              src={
                                submission.user.profilePicture
                                  ? submission.user.profilePicture
                                  : image
                              }
                            />
                            <StudentsDetailsLeft>
                              <div
                                style={{
                                  display: "flex",
                                  columnGap: "0.62rem",
                                  alignItems: "center",
                                }}
                              >
                                {/* Student Name Goes Here */}
                                <Typography
                                  text={
                                    submission.user.fName +
                                    " " +
                                    submission.user.lName
                                  }
                                  fontSize="0.875rem"
                                />
                              </div>
                              <div>
                                <LightTypography text={`ID: ${submission.user.sourceId}`} fontSize="0.75rem" color="#AAB1B9"/>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  columnGap: "0.62rem",
                                }}
                              >
                                {/* <SvgAttempt /> */}
                                {submission.createdAt && ( // Submission Date Goes Here
                                  <LightTypography
                                    text={`${getFormattedDate(
                                      submission.createdAt
                                    )}`}
                                    fontSize="0.75rem"
                                    color="#AAB1B9"
                                  />
                                )}
                              </div>
                              {submission.grade &&
                                submission.grade.comment && ( // Comment on the student submission goes here
                                  <LightTypography
                                    text={submission.grade.comment || ""}
                                    fontSize="0.75rem"
                                    color="#D85D66"
                                    fontStyle="italic"
                                  />
                                )}
                            </StudentsDetailsLeft>
                          </div>
                          <StudentQuizDetailsRight>
                            {/* {index === 1 && (
                                     <div
                                        style={{
                                            alignSelf: "end",
                                            textDecorationLine:
                                                "underline",
                                        }}
                                      >
                                        <LightTypography
                                            text="Plagiarism"
                                            fontSize="0.75rem"
                                        />
                                    </div>
                                )} */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  columnGap: "0.62rem",
                                  alignItems: "center",
                                }}
                              >
                                <LightTypography
                                  text={isGraded ? t("assignment.Student's grade") : "Student's answer"}
                                  fontSize="0.75rem"
                                />
                                {/* The Pen that navigate the instructor to the grader Goes here */}
                                <SvgEdit
                                  style={{
                                    cursor: "pointer",
                                    color: "#5dd3b3",
                                  }}
                                  onClick={() => handleGraderNavigate(index)}
                                  width="0.75rem"
                                />
                              </div>
                              {submission.grade && submission.grade.grade && isGraded ? ( // Grade on the student submission goes here
                                <Typography
                                  text={
                                    submission.grade &&
                                    submission.grade.grade &&
                                    toArabicDigits(Math.round(submission.grade.grade).toString())
                                  }
                                  fontSize="0.75rem"
                                  color="#6C9AF0"
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                            {submission.grade && submission.grade.grade && isGraded ? ( // Grade on the proccess Bar goes here
                              <ProgressBar
                                progress={submission.grade.grade || 0}
                                color="#6C9AF0"
                                // progressColor="#20272E"
                                maxprogress={maximumGradeOfAssignment !== 0 ? maximumGradeOfAssignment : 100}
                              />
                            ) : isGraded ? (
                              <ProgressBar
                                progress={0}
                                color="#6C9AF0"
                                // progressColor="#20272E"
                                maxprogress={maximumGradeOfAssignment !== 0 ? maximumGradeOfAssignment : 100}
                              />
                            ) : ''}

                            {/* {index === 0 && ( */}
                            <RecordAttemptsWrapper>
                              {/* <TextIconStyling>
                                                                                <SvgEyeIcon color="#6C9AF0" />
                                                                                <LightTypography
                                                                                    text={`Attempted: ${quiz.attemptedQuiz} out of 20`}
                                                                                    fontSize="0.75rem"
                                                                                />
                                                                            </TextIconStyling> */}
                            </RecordAttemptsWrapper>
                            {/* )} */}
                          </StudentQuizDetailsRight>
                        </StudentDetailsWrapper>
                      </>
                    </div>
                  ))}
              </StudentsQuizzes>
            </>
          );
        })()}
      </>
    </>
  );
};

export default AssignmentInstructor;
