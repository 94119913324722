import React, { FC, ChangeEvent, useState, useEffect, useContext } from "react";
import {
  NavBarItems,
  ContainerItems,
  Items,
  Tooltip,
  IconWrapper,
} from "./NavBar.style";
import { items } from "../NavBarData";
import { NavBarProps } from "../NavBarInterface";
import { MediumTypography } from "../../../../elements/fonts/Fonts";
import { theme } from "../../../../theme";
import { ShortAnswerInputs } from "../../../QuizCreatorQuestionCRUD/ShortAnswerQuestion/ShortAnswerQuestion.style";
import { ErrorDiv } from "../../../../elements/ErrorDiv/Errordiv.style";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../../../contexts/QuestionBanksContext";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  courseBannerColorSelector,
} from "../../../../recoil/ThemeSelectors";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../../../utils/StringManipulation";

const NavBar: FC<NavBarProps> = ({
  addMoreQuestion,
  setShowshowLoadTemplate,
  setShowQuestionBanksPopup,
  questions,
  showQuest,
  handleTemplateName,
  nameError,
  templateName,
  quest,
  setQuestionError,
  notValidate,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [questionBanksByClass, setQuestionBanksByClass] = useState<any[]>([]);
  const [questionBanksByUser, setQuestionBanksByUser] = useState<any[]>([]);
  const { t } = useTranslation();
  const tenantName = localStorage.getItem("tenant");
  const { user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const { setGlobalShowPopup, globalShowPopup } = useMyContext();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    handleTemplateName(value); // Pass the value to handleTemplateName
  };

  const fetchQuestionBanksByClass = async () => {
    try {
      const response = await axios.get(
        `https://0ddea1gj55.execute-api.eu-west-1.amazonaws.com/dev/question/bank/get/${id}/${tenantName}`
      );
      setQuestionBanksByClass(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchQuestionBanksByUser = async () => {
    try {
      const response = await axios.get(
        `https://0ddea1gj55.execute-api.eu-west-1.amazonaws.com/dev/question/bank/getBySubId/${subId}/${tenantName}?provider=${provider}`
      );
      setQuestionBanksByUser(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchQuestionBanksByClass();
    fetchQuestionBanksByUser();
  }, []);

  // Function to handle click on the Question Banks icon
  const handleClickQuestionBanks = () => {
    if (questionBanksByUser.length === 0 && questionBanksByClass.length === 0) {
      // Navigate to the Question Banks page with state
      navigate(`/questionBanks/${id}`, { state: { noData: true } });
    } else {
      // setShowQuestionBanksPopup && setShowQuestionBanksPopup(true);
      if (quest?.type !== "" && notValidate) {
        setQuestionError("Please Save the Question");
        return;
      }
      setGlobalShowPopup(true);
    }
  };

  const { bg_selectColor, SecondaryTextColor, bghelp } =
    useRecoilValue(ColorSelector);
  const { courseTitleBgColor, theme } = useRecoilValue(
    courseBannerColorSelector
  );

  return (
    <ContainerItems theme={theme} bgcolor={courseTitleBgColor}>
      <Items>
        <MediumTypography
          text={t("quiz.Create Your Template")}
          fontSize="0.875rem"
          lineHeight="1.375rem"
        // color={theme.dark.primary_text_color}
        />
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          {items.map((item, index) => (
            <NavBarItems key={index}>
              {item.id === 4 ? (
                <IconWrapper
                  onClick={() => {
                    addMoreQuestion("normal");
                  }}
                >
                  {" "}
                  {item.icon}
                  <Tooltip itemId={item.id} bgcolor={bg_selectColor}>
                    {t("quiz.Simple Question")}
                  </Tooltip>
                </IconWrapper>
              ) : item.id === 5 ? (
                <IconWrapper
                  onClick={() => {
                    addMoreQuestion("composed");
                  }}
                >
                  {" "}
                  {item.icon}
                  <Tooltip itemId={item.id} bgcolor={bg_selectColor}>
                    {t("quiz.Complex Question")}
                  </Tooltip>
                </IconWrapper>
              ) : item.id === 3 ? (
                <IconWrapper onClick={handleClickQuestionBanks}>
                  {" "}
                  {item.icon}
                  <Tooltip itemId={item.id} bgcolor={bg_selectColor}>
                    {t("quiz.Question Banks")}
                  </Tooltip>
                </IconWrapper>
              ) : item.id === 2 ? (
                <span onClick={() => setShowshowLoadTemplate(true)}>
                  {item.icon}
                </span>
              ) : (
                <span>{item.icon}</span>
              )}
            </NavBarItems>
          ))}
        </div>
      </Items>
      {/* {name.trim() === "" ? 
      <ShortAnswerInputs
      center="no"
      show={false.toString()}
      type="text"
      width="100%"
      placeholder="write Template name here"
      // readOnly={showQuest ? true : false}
      onChange={handleChange}
      id="name"
      hasError={!!nameError}
      />
       :  */}
      {nameError && nameError.trim() !== "" && <ErrorDiv>{nameError}</ErrorDiv>}
      <ShortAnswerInputs
        borderColor={bghelp}
        color={SecondaryTextColor}
        center="no"
        show={false.toString()}
        value={templateName || ""}
        type="text"
        width="100%"
        placeholder={t("quiz.Write template name here")}
        //  readOnly={showQuest ? true : false}
        onChange={handleChange}
        id="name"
        hasError={!!nameError}
      />
      {/* } */}
    </ContainerItems>
  );
};

export default NavBar;
