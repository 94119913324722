export interface MemberInfoBoxInterface {
  profilePicture: string;
  firstName: string;
  lastName: string;
}
export const GroupMemberData: MemberInfoBoxInterface[] = [
  {
    profilePicture: require("../../assets/KhaledInstructor.jpg"),
    firstName: `Khaled `,
    lastName: `Khaled`,
  },
  {
    profilePicture: require("../../assets/NourInstructor.jpg"),
    firstName: `Nour `,
    lastName: "Mohammad Mans",
  },
  {
    profilePicture: require("../../assets/AliAhmad.png"),
    firstName: `Ali `,
    lastName: "Ahmad",
  },
  {
    profilePicture: require("../../assets/ayaInstructor2.jpg"),
    firstName: `Aya `,
    lastName: "Ismail",
  },
  {
    profilePicture: require("../../assets/KhaledInstructor.jpg"),
    firstName: `Khaled`,
    lastName: "Khaled",
  },
  {
    profilePicture: require("../../assets/KhaledInstructor.jpg"),
    firstName: `Khaled`,
    lastName: "Khaled",
  },
  {
    profilePicture: require("../../assets/KhaledInstructor.jpg"),
    firstName: `Khaled`,
    lastName: "Khaled",
  },
  {
    profilePicture: require("../../assets/KhaledInstructor.jpg"),
    firstName: `Khaled`,
    lastName: "Khaled",
  },
  {
    profilePicture: require("../../assets/KhaledInstructor.jpg"),
    firstName: `Khaled`,
    lastName: "Khaled",
  },
  {
    profilePicture: require("../../assets/KhaledInstructor.jpg"),
    firstName: `Khaled`,
    lastName: "Khaled",
  },
  {
    profilePicture: require("../../assets/KhaledInstructor.jpg"),
    firstName: `Khaled`,
    lastName: "Khaled",
  },
  {
    profilePicture: require("../../assets/KhaledInstructor.jpg"),
    firstName: `Khaled`,
    lastName: "Khaled",
  },
];
