import React, { useState } from "react";
import { Navigate, Link } from "react-router-dom";
import {
  DivAction,
  FourOA,
  FourOB,
  FourOPageLayout,
  FourLogoPlace,
  FourText,
  FourOc,
} from "./ForOFourPage.style";
import {
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import { SvgFourOFour, SvgLogoFour, SvgZName } from "../../elements/Icons";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export const FourOFourPageCompo = (): JSX.Element => {
  const { theme } = useRecoilValue(ColorSelector);
  return (
    <FourOPageLayout>
      <FourOA theme={theme}>
        <FourLogoPlace>
          <SvgLogoFour />
          <SvgZName />
        </FourLogoPlace>

        <FourText>
          <Typography fontSize="5rem" lineHeight="5.500rem" text="404" />
          <TypographyRegularDescription
            fontSize="1.375"
            text={t("not found.This content is not available")}
            lineHeight="2rem"
          />
          <Link to="/dashboard">
            <DivAction>
              <Typography fontSize="0.875rem" text={t("not found.Go back to Main page")} color="#fff"/>
            </DivAction>{" "}
          </Link>
        </FourText>
      </FourOA>
      {/* <FourOB theme={theme} /> */}
      <FourOc style={{transform: "none"}}>
        <SvgFourOFour />
      </FourOc>
    </FourOPageLayout>
  );
};
