import { DropDownEditDeleteItem } from "./dropDownInterface";
import { SvgDeleteIcon, SvgEditIcon } from "../../elements/Icons";

export const DropDownDataInterface: DropDownEditDeleteItem[] = [
  {
    name: "dashboard.Edit",
    title: "Edit",
    icon: SvgEditIcon,
  },
  {
    name: "group.Delete",
    title: "Delete",
    icon: SvgDeleteIcon,
  },
];
