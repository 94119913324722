import { formatOptionsForTranslation } from "./FormatOptionsForTranslation";
import { useTranslation } from "react-i18next";

export const ViewsOptions = [
  { name: "View 5" },
  { name: "View 10" },
  { name: "View 50" },
  { name: "View All" },
];

export const ViewsOptionsTranslated = () => {
  const { t } = useTranslation();
  return [
    { name: t("gradebook.View 5"), value: "View 5" },
    { name: t("gradebook.View 10"), value: "View 10" },
    { name: t("gradebook.View 50"), value: "View 50" },
    { name: t("gradebook.View All"), value: "View All" },
  ];
};

export const handleNumberDisplays = (
  newValue: string,
  setViewText: React.Dispatch<React.SetStateAction<string>>,
  setLimit: React.Dispatch<React.SetStateAction<number>>,
  data: any[],
  setShowPagination?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (newValue === "View 5") {
    setLimit(5);
    setViewText("View 5");
    setShowPagination?.(true);
  } else if (newValue === "View 10") {
    setLimit(10);
    setViewText("View 10");
    setShowPagination?.(data.length > 10 ? true : false);
  } else if (newValue === "View 50") {
    setLimit(50);
    setViewText("View 50");
    setShowPagination?.(data.length > 50 ? true : false);
  } else {
    setLimit(data.length); // Display all objects
    setViewText("View All");
    setShowPagination?.(false);
  }
};

export const handleNumberDisplaysWithoutPagination = (
  newValue: string,
  setViewText: React.Dispatch<React.SetStateAction<string>>,
  setLimit: React.Dispatch<React.SetStateAction<number>>,
  data: any[],
) => {
  if (newValue === "View 5") {
    setLimit(5);
    setViewText("View 5");
  } else if (newValue === "View 10") {
    setLimit(10);
    setViewText("View 10");
  } else if (newValue === "View 50") {
    setLimit(50);
    setViewText("View 50");
  } else {
    setLimit(data.length); // Display all objects
    setViewText("View All");
  }
};
