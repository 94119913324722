import styled from "styled-components";
import { ProgressBarInterface } from "./ProgressBarInterface";

export const ProgressBarContainer = styled.div<{ progresscolor: string,maxprogress:number,height?: string }>`
  background-color: ${(props) => props.progresscolor};
  width: 100%;
  height:${(props) => props.height || "0.375rem"} ;
  border-radius: 6.25rem;
`;

export const ProgressBarStyle = styled.div<ProgressBarInterface>`
  height: 100%;
  border-radius: 6.25rem;
  background-color: ${(props) => props.color};
  width: ${(props) => ((props.maxprogress || 1) > 0 ? (props.progress / (props.maxprogress || 1)) * 100 : 0)}%; /* Calculate progress as a percentage */
max-width:100%;
`;
