import React, { useEffect, useState } from "react";
import { AnalyticsSurveyPiesTwoValuesContainer } from "./AnalyticsSurveyPiesTwoValues.style";
import PieWithText from "../../Charts/PieWithText/PieWithText";
import PieText from "../../Charts/PieText/PieText";

// Define interface for SurveyData
export interface SurveyData {
  QuestionCode: string;
  QuestionTitle: string;
  QuestionText: string;
  QuestionType: string;
  students: {
    StdCode: string;
    StdName: string;
    TimeSpent: number;
    Answered: boolean;
  }[];
}

// Define interface for AnalyticsSurveyPiesTwoValues
interface AnalyticsSurveyPiesTwoValuesInterfaceData {
  data: SurveyData; // Use the SurveyData interface here
}

// AnalyticsSurveyPiesTwoValues Component
const AnalyticsSurveyPiesTwoValues = ({
  data,
}: AnalyticsSurveyPiesTwoValuesInterfaceData) => {
  // useEffect to log data

  // State for PieChartData
  const [PieChartData, setPieChartData] = useState([
    {
      text: "Answered",
      val: 0,
      bgColor: "#5DD3B3",
    },
    {
      text: "Not Answered",
      val: 0,
      bgColor: "#0B8FFF",
    },
  ]);

  // useEffect to update PieChartData based on data
  useEffect(() => {
    // Calculate attempted count
    const attemptedCount = data.students.filter(
      (item) => item.Answered === true
    ).length;

    // Calculate unattempted count
    const unattemptedCount = data.students.filter(
      (item) => item.Answered === false
    ).length;
    const NoAnswerCount = data.students.filter(
      (item) =>
        (typeof item.Answered === "string" &&
          (item.Answered as string).trim() === "") ||
        (typeof item.Answered === "string" &&
          (item.Answered as string) === "") ||
        !item.Answered
    ).length;
    // Update PieChartData state with new data
    setPieChartData([
      {
        text: "Answered",
        val: attemptedCount,
        bgColor: "#5DD3B3",
      },
      {
        text: "Not Answered",
        val: unattemptedCount,
        bgColor: "#0B8FFF",
      },
    ]);
  }, [data]);

  return (
    <AnalyticsSurveyPiesTwoValuesContainer>
      {/* PieWithText Component */}
      <PieWithText
        data={PieChartData}
        value={"val"}
        getColor={(entry) => entry.bgColor as string}
        Width="65%"
      />

      {/* PieText Component */}
      <PieText
        LoopName={PieChartData}
        ShowMiniInfo={false}
        title={data.QuestionText}
      />
    </AnalyticsSurveyPiesTwoValuesContainer>
  );
};

export default AnalyticsSurveyPiesTwoValues;
