import React, { useState,useEffect, useRef } from "react";
import { MenuWrapper, Frame, IconHolder, Div } from "./DropDown.style";
import { PrimaryFontBoldText } from "../../../elements/fonts/Fonts";
import { SvgEdit, SvgDelete } from "../../../elements/Icons";
import EditModal from "../editModal/EditModal";
import ModalWrapper from "../editModal/modalOverlay/ModalOverlay";
import useClickOutside from "../../../hooks/useClickOutside";
// import useToggle from "../../../hooks/useToggle";


const DropDown = () => {
  // const { isOpen: isDropdownVisible, toggle: toggleDropdown } = useToggle(false);
  // const [selectedTitle, setSelectedTitle] = useState("");

  // const handleFrameClick = (title: string) => {
  //   if (title === "Edit") {
  //     setSelectedTitle(selectedTitle === title ? "" : title);
  //   } else {
  //     setSelectedTitle("");
  //   }
  // };

  // const dropdownRef = useClickOutside(isDropdownVisible, toggleDropdown);
  const menuRef = useRef<HTMLDivElement | null>(null);



  
const[modal, setModal] = useState<boolean>(false);
const toggleModel = () =>{
  setModal(!modal)
}

useEffect(() => {
  let handler = (e:any)=>{
      if (menuRef.current && !menuRef.current.contains(e.target)) {
      setModal(false);
    }   
  };

  document.addEventListener("mousedown", handler);
  

  return() =>{
    document.removeEventListener("mousedown", handler);
  }

});
// console.log(toggleModel)
  return (
    <>
    <MenuWrapper
    >
      {/* First Button */}
<Div

>
      <Frame
        style={{
          borderRadius: "0.3125rem 0.3125rem 0rem 0rem",
        }}
onClick={toggleModel}
        
      >
        
        <SvgEdit  
         />
        <PrimaryFontBoldText text="Edit" fontSize="0.875rem" />
      
      </Frame>
      </Div>

      {/* Second Button */}

      <Frame
        style={{
          borderRadius: "0rem 0rem 0.3125rem 0.3125rem",
        }}
        // onClick={() => handleFrameClick("Delete")}
      >
        <SvgDelete />
        <PrimaryFontBoldText text="Delete" fontSize="0.875rem" />
      </Frame>

      {/* Conditionally render the modal based on the selectedTitle */}
    </MenuWrapper>
      {modal && (
        <>
          <EditModal />
          </>
      )}
      </>
  );
};

export default DropDown;
