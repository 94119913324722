import styled from "styled-components";
import { StyledInput } from "./QuizFormInterface";
import { theme } from "../../theme";

export const QuizContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  height: 75vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 1.69rem;
  &::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(
      --Green-Gradient,
      linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)
    );
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #576371;
    border-radius: 0.625rem;
  }
`;

export const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* & > :last-child:hover {
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  } */
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const LabelDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.62rem;

  .input-container {
    display: flex;
    flex-direction: column;
    gap: 1.12rem;
  }
`;

export const QuizInput = styled.input<StyledInput>`
  width: ${(props) => props.width || "100%"}; // Default width or use the prop
  height: 2.8125rem;
  padding: 0.94rem 1.25rem;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  background-color: transparent;
  color: #fff;
  font-family: ${(props) => props.theme.font.primaryRegular};

  &::placeholder {
    color: #fff;
    opacity: 0.5;
    font-size: 0.75rem;
    font-family: "Primary Font-Light";
  }
  &:focus {
    outline: none;
    caret-color: white;
  }
`;
export const DateAndTimeDiv = styled.div`
  display: flex;
  justify-content: space-between;
  /* gap: 0.62rem; */
  flex-wrap: wrap;
`;

export const RadioDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 2.62rem;
`;
export const RadioDivLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.69rem;
`;
export const BtnsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.88rem;
`;

export const LabelBtnsDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 1rem;
`;

export const DeletePopupMainWrapper = styled.div`
  width: 100%;
  height: 2.125rem;
  display: flex;
  justify-content: end;
`;

export const DeleteButton = styled.button`
  width: 8.8125rem;
  height: 2.125rem;
  border: none;
  border-radius: 6.25rem;
  background: #5dd3b3;
  font-family: "Primary Font-Medium";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  cursor: pointer;
  color: ${theme.dark.primary_text_color};
`;

export const CancelButton = styled.button`
  width: 8.8125rem;
  height: 2.125rem;
  background: transparent;
  border-radius: 6.25rem;
  cursor: pointer;
  border: 1px solid #d04b4b;
  font-family: "Primary Font-Medium";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  color: #d04b4b;
`;

export const DeletePopupButtons = styled.div`
  margin-top: 1.87rem;
  margin-bottom: 1.56rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.63rem;
`;

export const FlexDiv = styled.div<{ gap?: string }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : "1.25rem")};
`;
