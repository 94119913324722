import styled from "styled-components";

export const TopicsHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.62rem;
  margin-top: 1.25rem;
  min-height: fit-content;
`;

interface TopicContainerProps {
  lineiconcolor: string;
}
export const TopicContainer = styled.div<TopicContainerProps>`
  width: 100%;
  height: fit-content;
  border-radius: 0.625rem;
  border: 1px solid ${(props) => props.lineiconcolor};
  min-height: 7.5rem;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  position: relative;
`;

export const ContentsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.62rem;
`;

export const TopicContainerDragging = styled.div`
  width: 100%;
  border-radius: 0.625rem;
  border: 1px solid #5dd3b3;
  min-height: 7.5rem;
  display: flex;
  flex-direction: column;
  /* background-color: transparent; */
  position: relative;
  opacity: 0.2;
  background: #000;
`;

interface TopicHeaderProps {
  bgheadercolor: string;
}
export const TopicHeader = styled.div<TopicHeaderProps>`
  border-radius: 0.625rem 0.625rem 0rem 0rem;
  /* opacity: 0.3; */
  background: ${(props) => props.bgheadercolor};
  width: 100%;
  /* min-height: 1.875rem; */
  min-height: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5rem 0 0.44rem 0;
  position: relative;
`;

export const DraggableArea = styled.div`
  width: 85%;
  height: 100%;
  position: absolute;
  top: 0rem;
  right: 2.6rem;
  cursor: -webkit-grabbing;
`;

export const VDotsWrapper = styled.div`
  position: absolute;
  top: 0.62rem;
  &:dir(ltr) {
    right: 1.875rem;
  }
  &:dir(rtl) {
    left: 1.875rem;
  }
  width: 0.625rem;
  height: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
`;

export const ActivitiesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.31rem;
  position: relative;
`;
export const EmptyActivitiesTextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.25rem;
`;
