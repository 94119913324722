import { StudentProgressTableHeaderInterFace, StudentProgressTableInterface } from "./StudentProgressTableInterface";

export const StudentProgressTableHeaderData :StudentProgressTableHeaderInterFace[] =[
  {
    HeaderId:"23refdgf",
    HeaderName:"Module",
  },
  {
    HeaderId:"sfe543fdg",
    HeaderName:"Activities / Resources",
  },
  {
    HeaderId:"fdg546gggj",
    HeaderName:"Status",
  },
  {
    HeaderId:"sf43523rfrdgf",
    HeaderName:"Time Spent",
  },
  {
    HeaderId:"rtry54tvg",
    HeaderName:"Grade",
  },

];

export const StudentProgressTableData : StudentProgressTableInterface[] = [
{  stdId:"guhisa8sa908dsadjklsanjdo",
  StdName:"Sultana Nasser",
  StdProfile:require("../../../assets/AyaInstructor.jpg"),
  StdOnline:true,
  Models:[
    {ModelName:"Modal 1",
    ModelData:[
      {
        ActivitiesResources:"Assignment 1",
        Status:"Submitted",
        TimeSpent:"20 min",
        Grade:"10/10",
      },
      {
        ActivitiesResources:"Quiz 1",
        Status:"Viewed",
        TimeSpent:"20 min",
        Grade:"10/10",
      },
      {
        ActivitiesResources: "Assignment 2",
        Status: "Submitted",
        TimeSpent: "15 min",
        Grade: "8/10",
      },
      {
        ActivitiesResources: "Quiz 2",
        Status: "Viewed",
        TimeSpent: "22 min",
        Grade: "8/10",
      },
      {
        ActivitiesResources: "Forum 2",
        Status: "Viewed",
        TimeSpent: "15 min",
        Grade: "8/10",
      },
    ]
  
  },
  {ModelName:"Modal 2",
  ModelData:[
    {
      ActivitiesResources:"Forum 1",
      Status:"Submitted",
      TimeSpent:"20 min",
      Grade:"10/10",
    },
    {
      ActivitiesResources:"Assignment 1",
      Status:"Viewed",
      TimeSpent:"20 min",
      Grade:"10/10",
    },
    {
      ActivitiesResources: "Assignment 2",
      Status: "Submitted",
      TimeSpent: "15 min",
      Grade: "8/10",
    },
    {
      ActivitiesResources: "Quiz 2",
      Status: "Viewed",
      TimeSpent: "22 min",
      Grade: "8/10",
    },
    {
      ActivitiesResources: "Forum 2",
      Status: "Viewed",
      TimeSpent: "15 min",
      Grade: "8/10",
    },
  ]

},
  {ModelName:"Modal 5",
  ModelData:[
    {
      ActivitiesResources:"Forum 1",
      Status:"Submitted",
      TimeSpent:"20 min",
      Grade:"10/10",
    },
    {
      ActivitiesResources:"Assignment 1",
      Status:"Viewed",
      TimeSpent:"20 min",
      Grade:"10/10",
    },
    {
      ActivitiesResources: "Assignment 2",
      Status: "Submitted",
      TimeSpent: "15 min",
      Grade: "8/10",
    },
    {
      ActivitiesResources: "Quiz 2",
      Status: "Viewed",
      TimeSpent: "22 min",
      Grade: "8/10",
    },
    {
      ActivitiesResources: "Forum 2",
      Status: "Viewed",
      TimeSpent: "15 min",
      Grade: "8/10",
    },
  ]

}
  ]
}
]