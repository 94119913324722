import styled from "styled-components";

export const CorseNotificationPopUpCardContainer =styled.div`
display: flex;
flex-direction:column;
gap: 1.25rem;
margin-top: 1.25rem;
`;

export const CorseNotificationPopUpGroup =styled.div`
display: flex;
flex-direction:column;
gap: 1.25rem;
`;

export const CorseNotificationPopUpMiniGroup =styled.div`
display: flex;
flex-direction:column;
gap: 0.65rem;
`;

export const CorseNotificationPopUpLine=styled.div`
display: flex;

gap: 0.65rem;
`;