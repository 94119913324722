import { TextFieldStyled } from "./TextField.style"
import { TypographyRegularDescription } from '../fonts/Fonts';

interface TextFieldInterface{
    text:string;
}
const TextField = ({text}:TextFieldInterface):JSX.Element => {
  return (
   <TextFieldStyled placeholder={text}/>
   
  )
}

export default TextField