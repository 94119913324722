
  // Return the date in a format way
  export const getFormattedDate = (createdAt: string) => {
    const date = new Date(createdAt);
    const formattedDate = `${date.toDateString()} ${date.toLocaleTimeString()}`; // Change this according to your preferred date and time format
    return formattedDate;
  };
  // End of function

  // Return the date in a format way
  export const formatDate = (createdDate: string) => {
    const currentDate = new Date();
    const announcementDate = new Date(createdDate);
    const timeDifference = currentDate.getTime() - announcementDate.getTime();
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));

    if (minutesDifference < 1) {
      return "Now";
    } else if (minutesDifference < 60) {
      return `${minutesDifference} minute${
        minutesDifference > 1 ? "s" : ""
      } ago`;
    } else if (minutesDifference < 1440) {
      const hoursDifference = Math.floor(minutesDifference / 60);
      return `${hoursDifference} hour${hoursDifference > 1 ? "s" : ""} ago`;
    } else if (minutesDifference < 2880) {
      return `Yesterday at ${announcementDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      })}`;
    } else {
      return announcementDate.toLocaleString();
    }
  };
  // End of function

  // Validation for the date of the forum
  export const dateValidation = (forumData:any) => {
    if (forumData.data &&forumData.data.forumActivitiesData.startDateTime &&forumData.data.forumActivitiesData.cutDateTime) {
        const currentDate = new Date();
        const startDateTime = new Date(forumData.data.forumActivitiesData.startDateTime);
        const dueDateTime = new Date(forumData.data.forumActivitiesData.cutDateTime);
        const isValid = currentDate >= startDateTime && currentDate <= dueDateTime;
        return isValid;
      } else if (forumData.data &&forumData.data.forumActivitiesData.startDateTime) {
        const currentDate = new Date();
        const startDateTime = new Date(
          forumData.data.forumActivitiesData.startDateTime
        );
        const isValid = currentDate >= startDateTime;
        return isValid;
        // setShowGrade(false);
      } else {
        return true;
      }
  }
  // End of function