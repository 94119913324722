import styled from 'styled-components'
import { theme } from '../../../theme';
import { StyleThemeInterface } from '../../../interfaces/StyleThemeInterface';

export const QuestionMain=styled.div`
padding: 1rem 1.5rem;
`;

export const QuestionCommentContainer=styled.div<StyleThemeInterface>`
border: 1px solid ${props => props.bordercolor};
padding:1.62rem 1.25rem 1.31rem 1.25rem;
`;
export const QuestionName=styled.div`
display: flex;
flex-direction:column;
gap:0.31rem;
`;

export const OverContainer=styled.div`
border: 1px dashed #0B8FFF;
width: 6.25rem;
height: 2.5rem;
display: flex;
align-items:center;
justify-content:center;
`;

export const HeaderQuestionContainer=styled.div`
display: flex;
justify-content:space-between;
`;

export const GradeInput=styled.input<StyleThemeInterface>`
border: none;
background: transparent;
width: 1.25rem;
color:${props => props.color};
font-family:'Primary Font-Light';
font-size:0.75rem;
line-height:1.375rem;
`;

export const QuestionAnswer=styled.div`
margin-top:0.62rem;
`;

export const CommentSection=styled.textarea<{color?:string}>`
padding: 1rem;
margin-top:0.62rem;
border: 1px dashed #5DD3B3;
height: 6.25rem;
width: 100%;
max-width: 100%;
min-width: 80%;
max-height: 10.25rem;
min-height: 6.25rem;
background: transparent;
font-size:0.875rem;
font-family:'Primary Font-Light';
/* color:${theme.dark.primary_text_color}; */
color:${props =>props.color};
resize:none;
&&::placeholder {
  color:${props =>props.color};
opacity:0.5;
    /* color:${theme.dark.primary_text_color} !important; */
  }
  &:focus {
    outline:none;
  }
`;

export const SaveButtonContainer = styled.div`
display: flex;
width: 100%;
justify-content: flex-end;
margin-top: .5rem;
`
export const Rubric = styled.div`
  color: #5dd3b3;
  font-family: "Primary Font-Bold";
  font-size: 0.875rem;
  text-decoration-line: underline;
  cursor: pointer;
`;