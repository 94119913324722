import React, { useState } from "react";
import {
  CourseGeneralTracingContainer,
  CourseGeneralTracingTextContainer,
} from "./CourseGeneralTracing.style";
import { Typography } from "../../elements/fonts/Fonts";
import { SvgRectangle899 } from "../../elements/Icons";
import { AnalyticsHeaderAtt } from "../../elements/AnalyticsHeaderAtt/AnalyticsHeaderAtt";

interface CourseGeneralTracingInterface {
  UsersNumber?: any;
  ModelNumber?: string;
  ActivitiesNumber?: string;
  ResourcesNumber?: string;
  DaysNumber?: string;
  onClickModel?: () => void;
  onClickActivities?: () => void;
  onClickRecourses?: () => void;
  onClickUsers?: () => void;
  UsersActive?: any;
  ModalActive?: any;
  ActivitiesActive?: any;
  ResourcesActive?: any;
}

export const CourseGeneralTracing = ({
  UsersNumber,
  ModelNumber,
  ActivitiesNumber,
  ResourcesNumber,
  DaysNumber,
  onClickUsers,
  onClickModel,
  onClickActivities,
  onClickRecourses,
  UsersActive,
  ModalActive,
  ActivitiesActive,
  ResourcesActive,
}: CourseGeneralTracingInterface): JSX.Element => {
  // const [activeElement, setActiveElement] = useState<string | null>(null);

  return (
    <>
      <CourseGeneralTracingContainer>
        <Typography text="Course General Info" fontSize="1.75rem" />
        <CourseGeneralTracingTextContainer>
          {UsersNumber && (
            <AnalyticsHeaderAtt
              number={UsersNumber || ""}
              text="Users"
              onClick={onClickUsers}
              isActive={UsersActive}
              // isActive={UsersNumber === activeElement}
              // // setActive={setActiveElement}
            />
          )}
          {ModelNumber && (
            <AnalyticsHeaderAtt
              number={ModelNumber || ""}
              text="Modules"
              // isActive={ModelNumber === activeElement}
              onClick={onClickModel}
              isActive={ModalActive}
              // // setActive={setActiveElement}
            />
          )}
          {ActivitiesNumber && (
            <AnalyticsHeaderAtt
              number={ActivitiesNumber || ""}
              text="Activities"
              // isActive={ActivitiesNumber === activeElement}
              onClick={onClickActivities}
              // // setActive={setActiveElement}
              isActive={ActivitiesActive}
            />
          )}
          {ResourcesNumber && (
            <AnalyticsHeaderAtt
              number={ResourcesNumber || ""}
              text="Resources"
              // isActive={ResourcesNumber === activeElement}
              onClick={onClickRecourses}
              isActive={ResourcesActive}
              // // setActive={setActiveElement}
            />
          )}
          {DaysNumber && (
            <AnalyticsHeaderAtt
              number={DaysNumber || ""}
              text="Days, before close date"
              // isActive={DaysNumber === activeElement}
              // // setActive={setActiveElement}
            />
          )}
        </CourseGeneralTracingTextContainer>
      </CourseGeneralTracingContainer>
    </>
  );
};
