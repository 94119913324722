import React, { useEffect, useState } from "react";
import { CheckBox } from "./CheckBox.style";
import { RadioInterface } from './CheckBoxInterface'
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

const CheckBoxInput = ({name, value, checked, onChange, width, height, background, border,id,defaultChecked}: RadioInterface) => {
  const { RadioButtonGradientColor } = useRecoilValue(ColorSelector);
  const [isChecked, setIsChecked] = useState<boolean>(false)
  useEffect(() => {
  setIsChecked(checked || false)
  }, [checked])
    return (
      <CheckBox
        type="radio"
        name={name}
        value={value}
        checked={isChecked}
        onChange={onChange}
        width={width}
        height={height}
        background={background}
        border={border}
        bgcolor={RadioButtonGradientColor}
        id={id}
        defaultChecked={defaultChecked}
      />
    );

};

export default CheckBoxInput;
