import React, { ReactNode, createContext, useContext, useState } from "react";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface QuizGraderContextProps {
  globalQuizShowPopup: boolean;
  isPopupAllDroped: boolean;
  setGlobalQuizShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPopupAllDroped: React.Dispatch<React.SetStateAction<boolean>>;
  globalQuizName: string;
  setGlobalQuizName: React.Dispatch<React.SetStateAction<string>>;
  globalQuizDescription: string;
  setGlobalQuizDescription: React.Dispatch<React.SetStateAction<string>>;
  globalQuizStartDate: Date | null;
  setGlobalQuizStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  globalQuizEndDate: Date | null;
  setGlobalQuizEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  globalQuizFromDate: Date | null;
  setGlobalQuizFromDate: React.Dispatch<React.SetStateAction<Date | null>>;
  globalQuizToDate: Date | null;
  setGlobalQuizToDate: React.Dispatch<React.SetStateAction<Date | null>>;
  globalQuizIsVisible: boolean;
  setGlobalQuizIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  globalQuizIsTimeVisible: boolean;
  setGlobalQuizIsTimeVisible: React.Dispatch<React.SetStateAction<boolean>>;
  globalSelectedQuizId: number;
  setGlobalSelectedQuizId: React.Dispatch<React.SetStateAction<number>>;
  globalSelectedQuiz: string;
  setGlobalSelectedQuiz: React.Dispatch<React.SetStateAction<string>>;
  editQuiz: boolean;
  setEditQuiz: React.Dispatch<React.SetStateAction<boolean>>;
  globalMaxGrade: string;
  setGlobalMaxGrade: React.Dispatch<React.SetStateAction<string>>;
  globalPassingGrade: string;
  setGlobalPassingGrade: React.Dispatch<React.SetStateAction<string>>;
  globalAttempt: string;
  setGlobalAttempt: React.Dispatch<React.SetStateAction<string>>;
  globalDuration: string;
  setGlobalDuration: React.Dispatch<React.SetStateAction<string>>;
  globalShowGrade: boolean;
  setGlobalShowGrade: React.Dispatch<React.SetStateAction<boolean>>;
  globalUnlimitedAttempts: boolean;
  setGlobalUnlimitedAttempts: React.Dispatch<React.SetStateAction<boolean>>;
  globalQuestionsArray: any[];
  setGlobalQuestionsArray: React.Dispatch<React.SetStateAction<any[]>>;
  notValidate: boolean;
  setNotValidate: React.Dispatch<React.SetStateAction<boolean>>;
  disableClick: boolean;
  setDisableClick: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRadio:string;
  setSelectedRadio: React.Dispatch<React.SetStateAction<string>>;
  selectedTitle:string;
  setSelectedTitle: React.Dispatch<React.SetStateAction<string>>;
  selectedRubricId:number;
  setSelectedRubricId:React.Dispatch<React.SetStateAction<number>>;
  editQuizButton:boolean;
  setEditQuizButton: React.Dispatch<React.SetStateAction<boolean>>;

}

// Create the context
const quizGraderContext = createContext<QuizGraderContextProps | undefined>(
  undefined
);

// Create a provider component
const CourseCreatorQuizProvider = ({ children }: { children: ReactNode }) => {
  const params = useParams();
  const classId = params.id;
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const [globalQuizShowPopup, setGlobalQuizShowPopup] =
    useState<boolean>(false);
  const [isPopupAllDroped, setIsPopupAllDroped] = useState<boolean>(false);
  const [globalQuizName, setGlobalQuizName] = useState("");
  const [globalQuizDescription, setGlobalQuizDescription] = useState("");
  const [globalQuizStartDate, setGlobalQuizStartDate] = useState<Date | null>(
    null
  );
  const [globalQuizEndDate, setGlobalQuizEndDate] = useState<Date | null>(null);
  const [globalQuizFromDate, setGlobalQuizFromDate] = useState<Date | null>(
    null
  );
  const [globalQuizToDate, setGlobalQuizToDate] = useState<Date | null>(null);
  const [globalQuizIsVisible, setGlobalQuizIsVisible] = useState(true);
  const [globalQuizIsTimeVisible, setGlobalQuizIsTimeVisible] = useState(false);
  const [globalSelectedQuizId, setGlobalSelectedQuizId] = useState(0);
  const [globalSelectedQuiz, setGlobalSelectedQuiz] = useState("");
  const [editQuiz, setEditQuiz] = useState<boolean>(false);
  const [editQuizButton, setEditQuizButton] = useState<boolean>(false);
  const [globalMaxGrade, setGlobalMaxGrade] = useState<string>("");
  const [globalPassingGrade, setGlobalPassingGrade] = useState<string>("");
  const [globalAttempt, setGlobalAttempt] = useState<string>("");
  const [globalDuration, setGlobalDuration] = useState<string>("");
  const [globalShowGrade, setGlobalShowGrade] = useState<boolean>(false);
  const [globalUnlimitedAttempts, setGlobalUnlimitedAttempts] =
    useState<boolean>(false);
  // These States is used in the quiz Creator
  const [notValidate, setNotValidate] = useState<boolean>(true);
  const [disableClick, setDisableClick] = useState(true);
    const feedbackRight = "quiz.Your Answer is correct."
  const feedbackWrong = "quiz.Your Answer is incorrect."
  const { t } = useTranslation();
  const [globalQuestionsArray, setGlobalQuestionsArray] = useState<any[]>([
    {
      question: "",
      questType: "normal",
      type: "",
      grade: "",
      icon: <></>,
      questPart: [],
      choices: [] as {
        name: string;
        percentage?: string;
        isChecked?: boolean;
      }[],
      feedRight: t(feedbackRight),
      feedWrong: t(feedbackWrong),
      subId: subIdSplitted,
      provider: provider,
      classSourceId: classId,
    },
  ]);
  const [selectedRadio, setSelectedRadio] = useState("Manually graded");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedRubricId, setSelectedRubricId] = useState(0);

  return (
    <quizGraderContext.Provider
      value={{
        globalQuizShowPopup,
        isPopupAllDroped,
        setGlobalQuizShowPopup,
        setIsPopupAllDroped,
        globalQuizName,
        setGlobalQuizName,
        globalQuizDescription,
        setGlobalQuizDescription,
        globalQuizStartDate,
        setGlobalQuizStartDate,
        globalQuizEndDate,
        setGlobalQuizEndDate,
        globalQuizFromDate,
        setGlobalQuizFromDate,
        globalQuizToDate,
        setGlobalQuizToDate,
        globalQuizIsVisible,
        setGlobalQuizIsVisible,
        globalQuizIsTimeVisible,
        setGlobalQuizIsTimeVisible,
        globalSelectedQuizId,
        setGlobalSelectedQuizId,
        globalSelectedQuiz,
        setGlobalSelectedQuiz,
        editQuiz,
        setEditQuiz,
        globalMaxGrade,
        setGlobalMaxGrade,
        globalPassingGrade,
        setGlobalPassingGrade,
        globalAttempt,
        setGlobalAttempt,
        globalDuration,
        setGlobalDuration,
        globalShowGrade,
        setGlobalShowGrade,
        globalUnlimitedAttempts,
        setGlobalUnlimitedAttempts,
        globalQuestionsArray,
        setGlobalQuestionsArray,
        notValidate,
        setNotValidate,
        disableClick,
        setDisableClick,
        selectedRadio,
        setSelectedRadio,
        selectedRubricId,
        setSelectedRubricId,
        selectedTitle,
        setSelectedTitle,
        editQuizButton,
        setEditQuizButton
      }}
    >
      {children}
    </quizGraderContext.Provider>
  );
};

// Create a custom hook to access the context values
const useQuizMyContext = () => {
  const context = useContext(quizGraderContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
};

export { CourseCreatorQuizProvider, useQuizMyContext };
