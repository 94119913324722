import styled from "styled-components"

export const BackToLoginLink = styled.div`
color: #576474;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    text-underline-offset: 15%;
`
export const InstructionTextRow = styled.div`
display: flex;
flex-direction: column;
    padding: 0rem 3rem 1.25rem 3rem;
    gap: 0.63rem;
color: #262626;
&>:last-child{
    color: #576474;
}

`
