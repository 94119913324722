import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 1.2rem;
`;
export const PaginationRender = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap:0.75rem;
`;
export const PageButton = styled.button<{ active: number,color:string,borderColor?:string }>`
  /* margin: 0 5px; */
  padding: 5px;
  width: 2.375rem;
height: 2.375rem;
  border-radius: 0.3125rem;
  border: ${({ active, borderColor }) =>
    active ? '1px solid #5DD3B3' : `1px solid ${borderColor}`};
  cursor: pointer;
  font-family: "Primary Font-Medium";
  font-size: 12px;
  color: ${({ active,color }) => active ? "#fff"  : color}  ;
 
       background: ${({ active }) =>
    active
      ? "#5DD3B3"
      : "transparent"};

  &:hover{
    border-color:#5DD3B3;
  }
`;

export const ArrowButton = styled.button`
  /* margin: 0 5px; */
  padding: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: transparent;
`;


export const PaginationBox = styled.div<StyleThemeInterface>`
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 20px; */
  width: 2.375rem;
height: 2.375rem;
/* margin: 0 5px; */
background-color:transparent;
border-radius: 0.3125rem;
border:1px solid${props =>props.bordercolor};
opacity: ${({ opacity }) => (opacity || "")};
cursor: ${({ cursor }) => (cursor || "")};
`;