import styled from "styled-components";

interface ContainerProps {
  scrollposition: number;
  bgcolor: string;
  // opacity: string;
}

export const Container = styled.div<ContainerProps>`
  min-width: 3rem;
  width: fit-content;
  background: ${(props) => props.bgcolor};
  height: auto;
  min-height: fit-content;
  padding: 0.63rem 0 0.63rem 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  &:dir(ltr) {
    left: 0;
    border-radius: 0rem 0.625rem 0.625rem 0rem;
  }
  &:dir(rtl) {
    right: 0;
    border-radius: 0.625rem 0rem 0rem 0.625rem;
  }
  top: 4.12rem;
  /* top: ${(props) =>
    props.scrollposition ? `${props.scrollposition}vh` : "4.12rem"}; */

  align-items: center;
`;

interface TooltipProps {
  bgcolor: string;
  textcolor: string;
  boxshadow: string;
}
export const Tooltip = styled.span<TooltipProps>`
  visibility: hidden;
  justify-content: center;
  border-radius: 1.25rem;
  background: ${(props) => props.bgcolor};
  color: ${(props) => props.textcolor};
  box-shadow: ${(props) => props.boxshadow};
  min-width: fit-content;
  width: 7.7rem;
  max-height: 1.875rem;
  padding: 0.5rem 1.38rem 0.63rem 1.25rem;
  text-align: center;
  position: absolute;
  display: flex;
  top: 0.23rem;
  &:dir(ltr) {
    left: 7.3rem;
  }
  &:dir(rtl) {
    right: -0.3rem;
  }
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 5;
  white-space: nowrap;
  height: 1.875rem;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  min-width: fit-content;
  width: 100%;
  height: 2.25rem;
  min-height: fit-content;
  /* padding: 0.63rem 1rem; */
  cursor: pointer;
  &:hover {
    ${Tooltip} {
      visibility: visible;
      opacity: 1;
    }
    background-image: linear-gradient(50deg, #5dd3b3 3.83%, #2f6a5a 95.8%);
    & .iconItem {
      color: #fff;
      transition: all 0.2s ease-in;
    }
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 0.63rem 1rem;
  &:hover {
  }
  /* background-color: aquamarine; */
`;
interface HDevideLineProps {
  lineiconcolor: string;
}
export const HDevideLine = styled.div<HDevideLineProps>`
  width: 1.875rem;
  height: 0.0625rem;
  background: ${(props) => props.lineiconcolor};
  margin: 0.62rem 0;
`;
