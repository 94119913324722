import styled from "styled-components";
interface FooterGradeChangerRowStyles{
  gap?:string;
}
export const FooterGradeChangerContainer = styled.div`
display:flex;
justify-content:space-between;
width:100%;
padding:1rem 1.50rem;
align-items:center;
`;

export const FooterGradeChangerRow = styled.div<FooterGradeChangerRowStyles>`
display:flex;
gap:${props => props.gap || "1rem"};
align-items:center;
`;

export const FooterGradeChangerTextWidth = styled.div<FooterGradeChangerRowStyles>`
display:flex;
width:2rem;
/* background-color:yellow; */
`;

export const FooterGradeChangerTextPointer = styled.div<FooterGradeChangerRowStyles>`
cursor: pointer;
`;