import React, { useEffect, useState } from "react";
import { ContentR } from "../../../CourseTimeline/utils/CourseResourcesInterface";
import axios from "axios";
import {
  Typography,
  TypographyRegularDescription,
} from "../../../../elements/fonts/Fonts";
import {
  SvgDotsIcon,
  SvgDownload,
  SvgExcel,
  SvgExe,
  SvgFolderD,
  SvgLink,
  SvgLinkTreeD,
  SvgPdf,
  SvgPptx,
  SvgWord,
  SvgYoutube,
  SvgZip,
} from "../../../../elements/Icons";
import {
  DotsStyle,
  IconsPosition,
  ResourceBottomHolderNoB,
  ResourceHolderStyle,
  ResourceUpperHolderNoB,
} from "./ResourceCard.style";
import { S3Get } from "../../../../services/S3Get";
import { Link } from "react-router-dom";
import ResourceLinkTreeComponent from "./ResourceLinkTreeComponent";
import { getFileName } from "./getFileName";
import ResourceHtmlComponent from "./ResourceHtmlComponent";
import ResourcePicVidComponent from "./ResourcePicVidComponent";
import { getResourceType } from "./getResourceType";
import { downloadFileFile, handleDownloadAll } from "./downloadFile";
import { ResourceCardProps } from "../../dashboardContentInterfaces";
import { useRecoilValue } from "recoil";
import { CourseColorSelector } from "../../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { isVisible } from "@testing-library/user-event/dist/utils";
// import { Overlay } from "../../CourseArct/CourseArct.style";

interface IconMap {
  [key: string]: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
}

const fileExtensionToIcon: IconMap = {
  doc: SvgWord,
  docx: SvgWord,
  xlsx: SvgExcel,
  xls: SvgExcel,
  pptx: SvgPptx,
  ppt: SvgPptx,
  pdf: SvgPdf,
  exe: SvgExe,
  rar: SvgZip,
  link: SvgLink,
  link_Tree: SvgLinkTreeD,
  Folder: SvgFolderD,
  linkYoutube: SvgYoutube,
  // mp4: SvgVideo,
  // png: SvgPicture,
  // jpg: SvgPicture,
  // jpeg: SvgPicture,
  // gif: SvgPicture,

  // Add more mappings as needed
};
const getIconForFile = (
  fileName: string,
  type?: string,
  isYoutube?: boolean
) => {
  if (type === "Link") {
    let fileExtension;
    if (isYoutube) fileExtension = "linkYoutube";
    else fileExtension = "link";
    return fileExtensionToIcon[fileExtension];
  } else if (type === "Link_Tree") {
    const fileExtension = "link_Tree";
    return fileExtensionToIcon[fileExtension];
  } else if (type === "Folder") {
    const fileExtension = "Folder";
    return fileExtensionToIcon[fileExtension];
  } else {
    const fileExtension = fileName.split(".").pop()?.toLowerCase();
    const defaultIcon = SvgDownload;

    return fileExtension
      ? fileExtensionToIcon[fileExtension] || defaultIcon
      : defaultIcon;
  }
};

const getResourceUrl = (resourceR: ContentR) => {
  const tenantName = localStorage.getItem("tenant");
  const baseUrl =
    "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/resource";
  switch (resourceR.type) {
    case "Document":
      return `${baseUrl}/document/${resourceR.refId}/${tenantName}`;
    case "Folder":
      return `${baseUrl}/folder/${resourceR.refId}/${tenantName}`;
    case "File":
      return `${baseUrl}/file/${resourceR.refId}/${tenantName}`;
    case "Video":
      return `${baseUrl}/video/${resourceR.refId}/${tenantName}`;
    case "Picture":
      return `${baseUrl}/image/${resourceR.refId}/${tenantName}`;
    case "Link":
      return resourceR.isYoutube
        ? `${baseUrl}/youtube/${resourceR.refId}/${tenantName}`
        : `${baseUrl}/link/${resourceR.refId}/${tenantName}`;
    case "Link_Tree":
      return `${baseUrl}/linkTree/${resourceR.refId}/${tenantName}`;
    case "Html":
      return `${baseUrl}/html/${resourceR.refId}/${tenantName}`;
    default:
      return "";
  }
};

export default function ResourceCard({
  resourceR,
  hovered,
  allResources,
  courseId,
  lastIndex,
}: ResourceCardProps) {
  const { t } = useTranslation();
  const [resourceData, setResourceData] = useState<any>();
  const [FolderData, setFolderData] = useState<any>();
  const [FolderFilesData, setFolderFilesData] = useState<Array<any>>([]);
  const [s3Url, setS3Url] = useState();

  const [title, setTitle] = useState<string>("");
  const [loading, setLoading] = useState<Boolean>(true);
  const [folderloading, setFolderLoading] = useState<Boolean>(true);

  const fileType = getResourceType(getFileName(resourceData?.path || ""));

  function getYoutubeVideoId(url: string) {
    const match = url.match(
      /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/
    );
    return match ? match[1] : null;
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(getResourceUrl(resourceR));
        if (response) {
          setResourceData(response.data.data);
          setLoading(false);
          if (resourceR.type === "Folder") {
            setFolderData(response.data.data.files);
            const files = response.data.data.files;
            const filePromises = files.map(async (file: any) => {
              try {
                const s3Document = await S3Get(file.filePath);

                return { ...file, s3Document };
              } catch (error) {
                console.log(error);
              }
            });

            const resolvedFiles = await Promise.all(filePromises);
            if (resolvedFiles) {
              setFolderFilesData(resolvedFiles);
              setFolderLoading(false);
            }
          } else if (
            resourceR.type === "File" ||
            resourceR.type === "Document"
          ) {
            const s3Document = await S3Get(response.data.data.path);
            setS3Url(s3Document);
            setFolderLoading(false);
          }
        }
      } catch (error) {
        console.error(`Error fetching ${resourceR.type} resource:`, error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [resourceR]);
  const { ContentDocBgColor, theme } = useRecoilValue(CourseColorSelector);
  return !loading ? (
    <>
      {resourceData &&
        (resourceR.type === "Link_Tree"
          ? resourceData.linkTree.isVisible === 1
          : resourceR.type === "Folder"
            ? resourceData.folder.isVisible
            : resourceData.isVisible === 1) && (
          <div style={{ width: "100%" }}>
            <div>
              {(resourceR.type === "Folder" ||
                fileType === "Doc" ||
                resourceR.type === "Link") && (
                  <a
                    href={
                      resourceR.type === "Link" ? resourceData.url : undefined
                    }
                    target={resourceR.type === "Link" ? "_blank" : undefined}
                    rel={
                      resourceR.type === "Link"
                        ? "noopener noreferrer"
                        : undefined
                    }
                  >
                    <ResourceHolderStyle
                      style={{
                        background: allResources
                          ? theme === "light"
                            ? "#F7F7F8"
                            : "linear-gradient(90deg, #415160 0%, #293440 107.88%)"
                          : ContentDocBgColor,
                      }}
                      className={hovered ? "transitioning" : ""}
                      hovered={hovered}
                      allResources={allResources}
                      bgImg={
                        resourceR.type === "Link" && resourceR.isYoutube === true
                          ? `https://img.youtube.com/vi/${getYoutubeVideoId(
                            resourceData.url
                          )}/default.jpg`
                          : ""
                      }
                    >
                      <ResourceUpperHolderNoB>
                        {/* <ResourceCenterHolder> */}
                        {React.createElement(
                          getIconForFile(
                            getFileName(resourceData?.path || ""),
                            resourceR.type,
                            resourceR.isYoutube
                          )
                        )}

                        {/* </ResourceCenterHolder> */}
                      </ResourceUpperHolderNoB>

                      <ResourceBottomHolderNoB hovered={hovered}>
                        <Typography
                          text={
                            resourceR.type !== "Link"
                              ? resourceData.title || resourceData.folder.title
                              : resourceData.title
                            // getFileName(resourceData?.path || "") ||
                          }
                          fontSize="0.75rem"
                        />
                        <IconsPosition>
                          {resourceR.type !== "Link" &&
                            folderloading === false && (
                              <SvgDownload
                                cursor={"pointer"}
                                color="#5dd3b3"
                                onClick={() => {
                                  if (resourceR.type === "Folder") {
                                    handleDownloadAll(FolderFilesData);
                                  } else if (fileType === "Doc") {
                                    downloadFileFile(s3Url, resourceData);
                                  }
                                }}
                              />
                            )}
                          <Link
                            to={`/courseView/content/${courseId}`}
                            state={{
                              activity: resourceR,
                              moduleIndex: resourceR.moduleIndex,
                              topicIndex: resourceR.topicIndex,
                              contentIndex: resourceR.contentIndex,
                              contentId: resourceR.contentId,
                              isVisible: resourceR.isVisible,
                            }}
                          >
                            <DotsStyle>
                              {" "}
                              <SvgDotsIcon color="#5dd3b3" width={"1rem"} />{" "}
                            </DotsStyle>
                          </Link>
                          {/* </Link> */}
                        </IconsPosition>
                      </ResourceBottomHolderNoB>
                    </ResourceHolderStyle>
                  </a>
                )}

              {(fileType === "Pic" || fileType === "Vid") && (
                <ResourcePicVidComponent
                  resourceData={resourceData}
                  resourceR={resourceR}
                  allResources={allResources}
                  hovered={hovered}
                  courseId={courseId}
                />
              )}
              {resourceR.type === "Html" && (
                <ResourceHtmlComponent
                  resourceData={resourceData}
                  resourceR={resourceR}
                  allResources={allResources}
                  hovered={hovered}
                  courseId={courseId}
                />
              )}

              {resourceR.type === "Link_Tree" && (
                <ResourceLinkTreeComponent
                  resourceData={resourceData}
                  resourceR={resourceR}
                  allResources={allResources}
                  hovered={hovered}
                  courseId={courseId}
                  lastIndex={lastIndex}
                />
              )}
            </div>
          </div>
        )}
    </>
  ) : (
    <div>{`${t("general.loading")}...`} </div>
  );
}
