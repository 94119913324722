import { FaqInterface } from "./faqInterface";

export const FaqData: FaqInterface[]= [
    {
       question: `help.What is ZIDYIA LMS?`,
        answer:`help.ZIDYIA is a Learning Management System, a software application designed to facilitate the administration, documentation, tracking, reporting, and delivery of educational courses or training programs.`
      },
      {
        question: `help.How do I log in to ZIDYIA?`  ,
        answer:`help.Go to the right URL (address) of the Zidyia site, login with your username and password, or enter as a Guest (if allowed). Most Zidyia sites will have a link to set up a new account or request forgotten details of your existing one.`
      },
      {
        question: `help.I forgot my password. What should I do`  ,
        answer:`help.Click the 'Forgot Password' link on the login page to reset your password. You may need to answer security questions or follow your organization's specific password recovery process.`
      },
      {
       question: `help.How do I enroll in a course?`  ,
     answer:`help.Depending on your ZIDYIA, you can typically enroll in courses through a course catalog, with an access code, or by request from an administrator.`
      },
      {
        question: `help.How do I access my course materials?`  ,
answer:`help.After logging in, navigate to your course dashboard or homepage to access materials, resources, and assignments for your enrolled courses.`
      },
      {
        question: `help.How do I submit assignments?`  ,
answer:`help.Most ZIDYIAs have a dedicated section within the course where you can upload or submit assignments. Follow the instructions provided by your instructor.`
      },
    
      {
        question: `help.What do I do if I encounter technical issues?`,
        answer: `help.Contact your institution's IT support or the ZIDYIA support team for technical assistance. Be sure to provide detailed information about the issue.`,
      },
      {
        question: `help.Can I access ZIDYIA on a mobile device?`,
        answer: `help.Yes, ZIDYIA have mobile-responsive website for learning on the go.`,
      },
      {
        question: `help.How do I track my progress and grades?`,
        answer: `help.Check the course dashboard or gradebook section in the ZIDYIA to view your progress and grades for each course.`,
      },
      {
        question: `help.What should I do if I'm unable to view course content?`,
        answer: `help.Ensure your browser and system meet the minimum requirements for ZIDYIA. If problems persist, contact technical support.`,
      },
      {
        question: `help.How can I communicate with instructors or classmates?`,
        answer: `help.Many ways are available. Some examples include Forums are great for extended conversations over time, Messages for private channels, Chat for synchronous conversations, and Comments for quick notices and remarks, also using Big Blue Button that is an open source web conferencing system for online classes. You can also exchange online feedback with Assignment, and essay questions in Quizzes.`,
      },
      {
        question: `help.How do I access certificates or completion records?`,
        answer: `help.Certificates and completion records are typically available in ZIDYIA upon successfully finishing a course.`,
      },
      {
        question: `help.Can I access ZIDYIA after I graduate or leave my organization?`,
        answer: `help.Access to ZIDYIA after graduation or departure depends on the policies of your institution or organization. Some provide access for alumni. You will still have access to ZIDYIA LXP.`,
      },
      {
        question: `help.What is the difference between synchronous and asynchronous learning?`,
        answer: `help.Synchronous learning involves real-time interaction with instructors and peers, while asynchronous learning allows you to access materials and complete coursework at your own pace.`,
      },
      {
        question: `help.How do I change my profile information or email address in ZIDYIA?`,
        answer: `help.Typically, you can edit your profile information in your account settings within ZIDYIA.`,
      },
      {
        question: `help.Where can I get support for my Zidyia site?`,
        answer: `help.Our community is here to help. Click the help Icon and choose chatbot, we will be ready to guide you.`,
      },
      {
        question: `help.About Zidyia`,
        answer: `help.Zidyia is a learning platform designed to provide educators, administrators, and learners with a single robust, secure, and integrated system to create personalized learning environments, and much more.`,
      },
      {
        question: `help.How to manage ZIDYIA courses?`,
        answer: `help.A course in ZIDYIA is an area where a teacher will add resources and activities for their students to complete. It might be a simple page with downloadable documents or it might be a complex set of tasks where learning progresses through interaction.`,
      },
      {
        question: `help.Difference between ZIDYIA LXP and LMS`,
        answer: `help.An LMS is a system through which admins can assign and track highly structured training content. Conversely, an LXP is a consumer-focused platform in which the user chooses their own learning from a diverse array of personalized content.`,
      },
      {
        question: `help.Is ZIDYIA just for online learning?`,
        answer: `help.It can be. However, in most cases ZIDYIA is used to support and combine face-to-face interaction with e-learning.`,
      },
      {
        question: `help.Does ZIDYIA use SAS? and what is SAS?`,
        answer: `help.Definitely. SAS is a software suite that can mine, alter, manage and retrieve data from a variety of sources and perform statistical analysis on it. SAS provides a graphical point-and-click user interface for non-technical users and more through the SAS language. SAS programs have DATA steps, which retrieve and manipulate data, PROC (procedures) which analyze the data, and may also have functions. Each step consists of a series of statements.`,
      },
      {
        question: `help.Are there any provisions in ZIDYIA for people with disabilities?`,
        answer: `help.Sure. ZIDYIA supports many assistive technologies such as screen-readers, screen-magnifiers, alternative mouse and key use.`,
      },
      {
        question: `help.How do I get around ZIDYIA?`,
        answer: `help.Use the Side bar on the left side of your page. From there you can go directly to any part of ZIDYIA you have access to.`,
      },
      {
        question: `help.Who can create and edit activities and other things in courses?`,
        answer: `help.Whoever has the editing capability. Usually, it is the Teacher who creates and edits courses and activities within courses.`,
      },
    ];