import React, { FC, useState } from "react";
import {
  QuestionCRUDContainer,
  ChoiceFlexContainer,
} from "./QuestionCRUD.style";
import { QuestionCRUDProps } from "../QuestionCRUDInterface";
import MatchingQuestion from "../MatchingQuestion/MatchingQuestion";
import QuestionCRUDTextEditor from "../QuestionCRUDTextEditor/QuestionCRUDTextEditor";
import QuestionCRUDFeedback from "../QuestionCRUDFeedBack/QuestionCRUDFeedback";
import MultipleQuestion from "../MultipleQuestion/MultipleQuestion";
import TrueFalseQuestion from "../TrueFalseQuestion/TrueFalseQuestion";
import ShortAnswerQuestion from "../ShortAnswerQuestion/ShortAnswerQuestion";
import NumericalQuestion from "../NumericalQuestion/NumericalQuestion";
import EssayQuestion from "../EssayQuestion/EssayQuestion";
import SurveyRating from "../../surveyRating/SurveyRating";
import { SurveyLikertScale } from "../../../elements/SurveyCreatorLikertScale/SurveyLikertScale";
import { Choice } from "../../generateSurvey/generateSurveyDataInterface";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
const QuestionCRUD: FC<QuestionCRUDProps> = ({
  questionError,
  typeError,
  questions,
  index,
  partIndex,
  addChoice,
  handleChoiceMatchingQuestion,
  // handleQuestion,
  handleQuillChange,
  choice,
  quest,
  showQuest,
  handleShowEditQuest,
  setShowPreviewPopup,
  handleChoiceChecked,
  setDeleteChoice,
  setChoiceIndex,
  // handleShuffle,
  // isShuffled
  FillBranch,
  changeBranchName,
  handleTrueOrFalseChecked,
  handleChoiceMultipleQuestionForBranching,
  handleQIndexFromChild,
  emptyBranch,
}) => {
  const [allChoices, setAllChoices] = useState<Choice[]>(choice);

  const { Container_Gradient_BgColor, boxShadow } =
    useRecoilValue(ColorSelector);
  return (
    <QuestionCRUDContainer
      bgcolor={Container_Gradient_BgColor}
    
    >
      <QuestionCRUDTextEditor
        questionError={questionError}
        typeError={typeError}
        handleQuillChange={handleQuillChange}
        addChoice={addChoice}
        // handleQuestion={handleQuestion}
        quest={quest}
        showQuest={showQuest}
        handleShowEditQuest={handleShowEditQuest}
        setShowPreviewPopup={setShowPreviewPopup}
        handleChoiceChecked={handleChoiceChecked}
        // handleShuffle={handleShuffle}
        // isShuffled={isShuffled}
      />
      <ChoiceFlexContainer
        style={{
          display: quest?.type === "BINARY" ? "flex" : "",
          width: "100%",
          justifyContent: "space-between",
          gap: "0.62rem",
        }}
      >
        {choice?.map((choices, qindex) =>
          quest?.type === "MCQ" || quest?.type === "MCQ" ? (
            <MultipleQuestion
              key={qindex}
              choice={choices}
              qindex={qindex}
              setDeleteChoice={setDeleteChoice}
              handleChoiceMatchingQuestion={handleChoiceMatchingQuestion}
              showQuest={showQuest}
              // handleChoiceChecked={handleChoiceChecked}
              allChoices={choice}
              setChoiceIndex={setChoiceIndex}
              questionError={questionError ?? ""}
              quest={quest}
              FillBranch={FillBranch}
              changeBranchName={changeBranchName}
              questions={questions}
              index={index}
              partIndex={partIndex}
              handleChoiceMultipleQuestionForBranching={
                handleChoiceMultipleQuestionForBranching
              }
              handleQIndexFromChild={handleQIndexFromChild}
              emptyBranch={emptyBranch}
            />
          ) : quest?.type === "BINARY" ? (
            <TrueFalseQuestion
              key={qindex}
              choice={choices}
              qindex={qindex}
              handleChoiceMatchingQuestion={handleChoiceMatchingQuestion}
              showQuest={showQuest}
              handleTrueOrFalseChecked={handleTrueOrFalseChecked}
              allChoices={choice}
              quest={quest}
              FillBranch={FillBranch}
              changeBranchName={changeBranchName}
              questions={questions}
              index={index}
              partIndex={partIndex}
              questionError={questionError ?? ""}
              addChoice={addChoice}
              handleChoiceMultipleQuestionForBranching={
                handleChoiceMultipleQuestionForBranching
              }
              emptyBranch={emptyBranch}
            />
          ) : quest?.type === "RATE" ? (
            <SurveyRating
              quest={quest}
              allChoices={choice}
              qindex={qindex}
              setAllChoices={setAllChoices}
              FillBranch={FillBranch}
              changeBranchName={changeBranchName}
              showQuest={showQuest}
              questions={questions}
              index={index}
              partIndex={partIndex}
              questionError={questionError ?? ""}
              addChoice={addChoice}
              handleChoiceMultipleQuestionForBranching={
                handleChoiceMultipleQuestionForBranching
              }
              emptyBranch={emptyBranch}
            />
          ) : quest?.type === "SCALE" ? (
            <div style={{ padding: "1.38rem" }}>
              <SurveyLikertScale
                onChange={(value: string) => {
                }}
                quest={quest}
                allChoices={choice}
                qindex={qindex}
                setAllChoices={setAllChoices}
                FillBranch={FillBranch}
                showQuest={showQuest}
                changeBranchName={changeBranchName}
                questions={questions}
                index={index}
                partIndex={partIndex}
                questionError={questionError ?? ""}
                addChoice={addChoice}
                handleChoiceMultipleQuestionForBranching={
                  handleChoiceMultipleQuestionForBranching
                }
                emptyBranch={emptyBranch}
              />
            </div>
          ) : (
            <EssayQuestion
              key={qindex}
              choice={choices}
              qindex={qindex}
              handleChoiceMatchingQuestion={handleChoiceMatchingQuestion}
              showQuest={showQuest}
              handleChoiceChecked={handleChoiceChecked}
              questionError={questionError ?? ""}
            />
          )
        )}
      </ChoiceFlexContainer>
      {/* <QuestionCRUDFeedback
        handleQuestion={handleQuestion}
        handleQuillChange={handleQuillChange}
        quest={quest}
        showQuest={showQuest}
      /> */}
    </QuestionCRUDContainer>
  );
};

export default QuestionCRUD;
