import { CardDetailsViewHeader } from "./AnnouncementsCardDetailsView.style";
import { PageHeader } from "../../../../components/pageHeader/PageHeader";
import AnnouncementsCardDetail from "../../../../components/announcementsCardDetail/AnnouncementsCardDetail";
import AnnouncementsCardsData from "./announcementsCardDetailsData";
import { SvgBackArrow, SvgBgProfiledIcon3 } from "../../../../elements/Icons";
import ProfileWithComment from "../../../../elements/profileWithComment/ProfileWithComment";
import ReplayComment from "../../../../components/replayComment/ReplayComment";
import { useParams } from "react-router";
import { useState, useEffect, useContext } from "react";
import { TypographyRegularDescription } from "../../../../elements/fonts/Fonts";
import { get } from "../../../../utils/AxiosRequests";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TitleHeaderTextIconNavigator from "../../../../elements/TitleHeaderTextIconNavigator/TitleHeaderTextIconNavigator";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../../../utils/StringManipulation";
import { ClassContext } from "../../../dashboard/UnstructuredContext";
import axios from "axios";

interface announcementDataInterface {
  id: string;
  title: string;
  content: any;
  iconPath: string;
  fullName: string;
  email: string;
  createdAt: string;
  updated_at: string;
  userSourceId: string;
  path: string;
  teacher: {
    sourceId: string;
    username: string;
    email: string;
    profilePicture: string;
  };
}

const AnnouncementsCardDetailsView = (): JSX.Element => {
  const [announcementData, setAnnouncementData] =
    useState<announcementDataInterface>();
  const [profilePicture, setProfilePicture] = useState<string>("");
  const [loading, setLoading] = useState<Boolean>(true);
  const [annLoading, setAnnLoading] = useState<boolean>(true);
  const imageGif = require("../../../../assets/loadingGif.gif");
  const params = useParams();
  const announcementID = params.announcementID;
  const tenantName = localStorage.getItem("tenant");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const { classId } = useParams();
  const { getUnvewedAnnouncmentCount, getUnvewedAnnouncmentClassCount } = useContext(ClassContext);

  // GEt the Announecement By It's ID Data
  useEffect(() => {
    const getAnnounecmentById = async () => {
      try {
        setAnnLoading(true);
        const AnnounecementToDisplay = await axios.get(
          `https://yhntwosoqe.execute-api.eu-west-1.amazonaws.com/dev/announcement/${announcementID}/${tenantName}/${subIdSplitted}?provider=${provider}`
        );
        setAnnLoading(false);
        setAnnouncementData(AnnounecementToDisplay.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    getAnnounecmentById();
  }, []);

  useEffect(() => {
    // Get the profile of the user's announcement
    if (announcementData) {
      const fetchProfilePictures = async () => {
        try {
          setLoading(true);
          const tenantName = localStorage.getItem("tenant");

          // Create an array of promises to fetch profile pictures for all students
          const userPicture = await get(
            `user/profilePicture/${tenantName}/${announcementData.userSourceId}`
          );
          setLoading(false);
          setProfilePicture(userPicture.data);
        } catch (error) {
          console.error("Error fetching profile pictures:", error);
        }
      };
      fetchProfilePictures();
      if(tenantName !== null && tenantName !== undefined && subIdSplitted !== null && subIdSplitted !== undefined){
        getUnvewedAnnouncmentCount()
      }
    }
  }, [announcementData,subIdSplitted,tenantName]);

  if (annLoading) {
    return <div>{`${t('general.loading')}...`} </div>;
  }

  if (!announcementData) {
    // Handle the case when the announcement data is not found
    return <div>Announcement not found</div>;
  }

  // Handle go backward navigation
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      {/* The Announcement Details Goes Here */}
      <CardDetailsViewHeader>
        <TitleHeaderTextIconNavigator title={t("course view.Announcement")} />
        <AnnouncementsCardDetail
          title={announcementData.title}
          description={announcementData.content}
          iconBg={SvgBgProfiledIcon3}
          top="0.31rem"
          left="0.31rem"
          border="1px solid #707070"
          width="3.125rem"
          height="3.125rem"
          widthImgHolder="3.6875rem"
          heightImgHolder="3.6875rem"
          iconPath={!loading ? profilePicture : imageGif}
          fullName={announcementData.teacher.username}
          email={announcementData.teacher.email}
          userId={announcementData.userSourceId}
          date={announcementData.updated_at}
        />
        {/* <ProfileWithComment
          iconPath={require("../../../../assets/updateProfileImg.jpg")}
          width="3.19375rem"
          height="3.19375rem"
          widthImgHolder="3.6875rem"
          heightImgHolder="3.6875rem"
          top="0.25rem"
          left="0.25rem"
          inputWidth="67.75%"
        /> */}
        {/* <ReplayComment
          iconBg={SvgBgProfiledIcon3}
          iconPath={require("../../../../assets/NourInstructor.jpg")}
          top="0.25rem"
          left="0.25rem"
          border="1px solid #707070"
          width="3.19375rem"
          height="3.19375rem"
          widthImgHolder="3.6875rem"
          heightImgHolder="3.6875rem"
          fullName="Nour Mohammad"
          comment="What is your favourite color of the year because I don't understand why it isn't green ?"
          clapsNumber={39}
        /> */}
      </CardDetailsViewHeader>
    </>
  );
};

export default AnnouncementsCardDetailsView;
