import styled from "styled-components";

export const InstructorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.96rem;
  /* margin-top: 2.83rem; */
`;
export const StudentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.96rem;
  /* margin-top: 1.25rem; */
  margin-bottom: 1.3rem;
`;
export const AuditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.96rem;
  /* margin-top: 1.25rem; */
`;
export const InstructorsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 2.44rem;
  row-gap: 1.25rem;
  /* margin-top: -0.5rem; */
`;
export const StudentsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 2.44rem;
  row-gap: 1.25rem;
`;
export const CommonCoursesHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18.125rem, 1fr));
  gap: 1.87rem 1.56rem;
  background-color: transparent;
`;

export const TextHolder = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;
// export const MiniText = styled.div`
//  color: #5DD3B3;
// `
