import styled from "styled-components";

interface ProfileImageHolderProps {
  bgBorderColor?: string;
}

export const ProfileImageHolder = styled.div<ProfileImageHolderProps>`
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${(props) => props.bgBorderColor || "#707070"};
  border-radius: 50%;
`;

export const BgProfile = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const MaskGroup = styled.img`
  position: absolute;
  top: 0.31rem;
  left: 0.31rem;
  border: 1px solid #707070;
  height: 5.625rem;
  width: 5.625rem;
  border-radius: 50%;
  z-index: 2;
  object-fit: cover;
`;
