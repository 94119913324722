import React from "react";
import {
  AnalyticFilterLine,
  AnalyticRefreshIcon,
  ComplexSelectGreenBox,
} from "../../AnalyticComplexSelectedTables/AnalyticsAllAssigment.style";
import { MediumTypography } from "../../../elements/fonts/Fonts";
import { SvgRefreshIcon } from "../../../elements/Icons";
interface AssignmentSubmissionDetailsHeaderProps {
  setText: React.Dispatch<React.SetStateAction<string>>;
}
const AssignmentSubmissionDetailsHeaderFilter = ({
  setText,
}: AssignmentSubmissionDetailsHeaderProps) => {
  const handleStatusSearchText = (text: string) => {
    if (text === "Submitted") {
      setText("Submitted");
    } else if (text === "Unattempted") {
      setText("Unattempted");
    } else if (text === "Submission after Due date") {
      setText("Submission after Due date");
    } else {
      setText("");
    }
  };
  return (
    <AnalyticFilterLine>
      <ComplexSelectGreenBox
        onClick={() => handleStatusSearchText("Submitted")}
      >
        <MediumTypography text="Submitted" />
      </ComplexSelectGreenBox>
      <ComplexSelectGreenBox
        onClick={() => handleStatusSearchText("Unattempted")}
      >
        <MediumTypography text="Unattempted" />
      </ComplexSelectGreenBox>
      <ComplexSelectGreenBox
        onClick={() => handleStatusSearchText("Submission after Due date")}
      >
        <MediumTypography text="Submission after Due date" />
      </ComplexSelectGreenBox>
      <AnalyticRefreshIcon onClick={() => handleStatusSearchText("")}>
        <SvgRefreshIcon />
      </AnalyticRefreshIcon>
    </AnalyticFilterLine>
  );
};

export default AssignmentSubmissionDetailsHeaderFilter;
