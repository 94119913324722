import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

interface Styles {
  color?: string;
  opacity?: string;
  theme?: string;
  type?:any;
}

export const NotificationMenuV2BtnChape = styled.div<Styles>`
  display: flex;
  align-items: center;
  border-radius: 1.5625rem;
  border: 1.7px solid ${({ color }) => color || ""};
  color: ${({ color }) => color || ""};
  border-radius: 1.5625rem;
  padding: 0.25rem 0.68rem;
  cursor: pointer;
  margin-right: -0.3rem;
  width: fit-content;
`;

export const TextColorDiv = styled.div<Styles>`
  display: flex;
color:#5DD3B3;
`;

export const TitleTextColorDiv = styled.div<Styles>`
  display: flex;
  color:${props => props.type === "missingSubmit" ?"#D85D66":props.color};
/* color:#5DD3B3; */
`;
export const TitleTextColorDivOpacity = styled.div<Styles>`
  display: flex;
  color : ${props =>props.type === "missingSubmit" ?"#D85D66": props.theme === "light" ?  "#AAB1B9": `#ffffff80`};
/* color:#5DD3B3; */
`;

export const TitleTextColorDivRed = styled.div<Styles>`
  display: flex;
  color : #D85D66;
/* color:#5DD3B3; */
`;
export const NotificationMenuIconSpaceContainer = styled.div<StyleThemeInterface>`
width: 2.25rem;
height: 2.25rem;
flex-shrink: 0;
  display: flex;
  background:${props =>props.bgcolor};
  border-radius: 50%;
  /* padding: 0.6rem; */
  justify-content: center;
  align-items: center;
  color:${props => props.color};
`;
export const NotificationMenuV2RenderedContainer = styled.div<StyleThemeInterface>`
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  padding: 0.5rem 0.57rem;
  color: ${({ color }) => color || ""};
  opacity: ${({ opacity }) => opacity || ""};
  cursor: pointer;
  transition: background-color 0.3s ease; /* Define transition for background color */
  /* Set initial background color */
  background-color: transparent;
&:last-child{
  border-radius: 0 0 0.625rem 0.625rem;
}
  &:hover {
    background-color: #5dd3b3; /* Change background color on hover */
  }

  &:hover  ${NotificationMenuV2BtnChape} {
    color:#fff !important ; /* Change color of NotificationMenuV2BtnChape on hover */
    border-color:#fff !important
  }
  &:hover  ${TextColorDiv} {
    color: #fff !important; /* Change color of NotificationMenuV2BtnChape on hover */
  
  }
  &:hover  ${TitleTextColorDiv} {
    color: #fff !important; /* Change color of NotificationMenuV2BtnChape on hover */
  
  };
  &:hover  ${NotificationMenuIconSpaceContainer} {
    background:transparent;
    color: #fff !important; /* Change color of NotificationMenuV2BtnChape on hover */
  border:1px solid ${props => props.bordercolor}
  };

  &:hover  ${TitleTextColorDivOpacity} {
    color: #fff !important; /* Change color of NotificationMenuV2BtnChape on hover */
  
  };
  &:hover  ${TitleTextColorDivRed} {
    color: #fff !important; /* Change color of NotificationMenuV2BtnChape on hover */
  
  };
`;


export const NotificationMenuV2MiniContainer =styled.div`
display: flex;
gap:0.48rem;
`;


export const NotificationMenuV2MiniCols =styled.div`
display: flex;
flex-direction: column;
/* background-color:yellow !important; */
  gap: 0.33rem;


`;
export const NotificationMenuV2MiniColsEnd =styled.div`
display: flex;
flex-direction: column;
align-items: end;
  justify-content: space-between;

`;


