import { toArabicDigits } from "../../utils/formatDayArabic";

// Initialize States Object with a key for each type of question
export const returnInitialiseStates = (StudentQuizViwData:any[]) => {
    const initialDescriptions: { [key: string]: string } = {};
    const initialShortAnswers: { [key: string]: string } = {};
    const initialNumerical: { [key: string]: string } = {};
    const initialFileDescriptions: { [key: string]: File[] } = {};
    const initialSelectedChoices: { [key: string]: any[] } = {};
    const initialSelectedMatchingChoices: { [key: string]: any[] } = {};
    const initialTrueOrFalseAnswers: { [key: string]: boolean | undefined } =
      {};

    StudentQuizViwData.forEach((question, questionIndex) => {
      if (
        question.type === "COMPOSITE" &&
        question.questionParts &&
        question.questionParts.lenght > 0
      ) {
        question.questionParts.forEach((part: any, partIndex: number) => {
          const key = `${StudentQuizViwData[questionIndex].title}_${
            question.questionParts && question.questionParts[partIndex].title
          }`;

          if (part.type === "ESSAY") {
            initialDescriptions[key] = "";
          } else if (part.type === "SHORT_ANSWER") {
            initialShortAnswers[key] = "";
          } else if (part.type === "NUMERIC") {
            initialNumerical[key] = "";
          } else if (part.type === "File Description") {
            initialFileDescriptions[key] = [];
          } else if (part.type === "TF") {
            const key = `${StudentQuizViwData[questionIndex].title}_${part.title}`;
            initialTrueOrFalseAnswers[key] = undefined;
          } else if (part.type === "MATCH") {
            initialSelectedMatchingChoices[key] = []; // Initialize the Matching type questions
          }
        });
      } else {
        const key = `${StudentQuizViwData[questionIndex].title}`;
        if (question.type === "ESSAY") {
          initialDescriptions[key] = "";
        } else if (question.type === "SHORT_ANSWER") {
          initialShortAnswers[key] = "";
        } else if (question.type === "Numerical") {
          initialNumerical[key] = "";
        } else if (question.type === "File Description") {
          initialFileDescriptions[key] = [];
        } else if (question.type === "TF") {
          const key = `${StudentQuizViwData[questionIndex].title}`;
          initialTrueOrFalseAnswers[key] = undefined;
        } else if (question.type === "MATCH") {
          initialSelectedMatchingChoices[key] = []; // Initialize the Matching type questions
        }
      }
    });

    return {
        initialSelectedMatchingChoices,
        initialNumerical,
        initialFileDescriptions,
        initialSelectedChoices,
        initialShortAnswers,
        initialTrueOrFalseAnswers,
        initialDescriptions
    }
}
//End Of Function

// This Function is Formating the answer Data of the student before sending it to the backend
export const formatingAnswerData = (selectedChoices:any, essayDescriptions:any, numerical:any,shortAnswer:any,selectedMatchingChoices:any,truOrFalseAnswer:any,questionId:any,quizTemplateQuestionsId:any,questionType:any,fileResponse:any) => {
     // Accumulated responses object
     let accumulatedResponses: Record<string, any> = {};

     // Function to merge logs into accumulated responses
     const mergeLogs = (logs: any) => {
       for (const log of logs) {
         if (typeof log === "object" && !Array.isArray(log)) {
           accumulatedResponses = { ...accumulatedResponses, ...log };
         } else if (typeof log === "string") {
           const key = log;
           const value = logs[logs.indexOf(log) + 1];
           if (value !== undefined && typeof value !== "string") {
             accumulatedResponses[key] = value;
           }
         }
       }
     };
         mergeLogs([selectedChoices, essayDescriptions, numerical,shortAnswer,selectedMatchingChoices,truOrFalseAnswer,questionId,quizTemplateQuestionsId,questionType,fileResponse]);
     // Sort keys in ascending order
     const sortedKeys = Object.keys(accumulatedResponses).sort((a, b) => {
       const keyA = a.toLowerCase();
       const keyB = b.toLowerCase();
       if (keyA < keyB) return -1;
       if (keyA > keyB) return 1;
       return 0;
     });

     // Create a new object with sorted keys
     const sortedResponses: Record<string, any> = {};
     sortedKeys.forEach((key) => {
       sortedResponses[key] = accumulatedResponses[key];
     });

             const transformObjectToArray = (responsesObject:any) => {
               const resultArray:any = [];
             
               Object.keys(responsesObject).forEach((key) => {
                 if (key.startsWith('Question ') && key.endsWith('_ID')) {
                   const questionNumber = key.replace('Question ', '').replace('_ID', '');
                   const questionKey = `Question ${questionNumber}`;
                   const idKey = `${questionKey}_ID`;
                   const typeKey = `${questionKey}Type`;
                   const templateKey = `${questionKey}_TEMPLATEKEY`;
             
                   // Check for the existence of the main keys for the composed question
                   if (idKey in responsesObject && typeKey in responsesObject) {
                     const question = responsesObject[questionKey];
                     const questionType = responsesObject[typeKey];
                     const questionTemplateId = responsesObject[templateKey]
             
                     // Initialize file variable for the part if it exists and is an essay
                     let file = null;
                     const fileKey = `${questionKey}_File`;
                     if (fileKey in responsesObject) {
                       file = responsesObject[fileKey];
                     }
             
                     resultArray.push({
                       questionId: responsesObject[idKey],
                       type: questionType,
                       answer: question,
                       quizTemplateQuestionId:questionTemplateId,
                       file: file, // Include file key if it's a composed question with an attachment for a part
                     });
             
                     // Handling additional parts if they exist for the composed question
                     const partKeys = Object.keys(responsesObject).filter(
                       (partKey) => partKey.startsWith(`${questionKey}_Part`)
                     );
                     partKeys.forEach((partKey) => {
                       const partTypeKey = `${partKey}Type`;
                       const partFileKey = `${partKey}_File`;
                       const partTemplateKey = `${partKey}_TEMPLATEKEY`;
             
                       if (partTypeKey in responsesObject) {
                         const partType = responsesObject[partTypeKey];
                         const partTemplateId = responsesObject[partTemplateKey]
                         let partFile = null;
             
                         if (partType === 'ESSAY' && partFileKey in responsesObject) {
                           partFile = responsesObject[partFileKey];
                         }
                         resultArray.push({
                           questionId: responsesObject[`${questionKey}_ID`],
                           type: partType,
                           answer: responsesObject[partKey],
                           quizTemplateQuestionId:partTemplateId,
                           file: partFile, // Include file key for the part if it's an essay type
                         });
                       }
                     });
                   }
                 }
               });
             
               return resultArray;
             };

             return transformObjectToArray(sortedResponses)
}
// End Of Function

 // Format time function
export const formatTime = (timeInSeconds:any) => {
   const minutes = Math.floor(timeInSeconds / 60);
   const seconds = timeInSeconds % 60;
   return `${toArabicDigits(minutes.toString())}:${seconds < 10 ? toArabicDigits('0') : ''}${toArabicDigits(seconds.toString())}`;
};
// End Of Function

// Find The Grade Of The Student For Each Question
export const findStudentGrade = (questionId: any, questionType: string,studentResponse:any[]) => {
    // Search for the Question by it's id and Type
    if (studentResponse && studentResponse.length > 0) {
      const studentAnswer = studentResponse.find((answer: any) => Number(answer.questionId) === Number(questionId) && answer.type === questionType);
      if (studentAnswer) {
        return studentAnswer.grade
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
// End Of Function
