import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { ResourcesSliderSettings } from "./ResourcesSliderSettings";
import { ResourcesTitleSliderStyle } from "../../main/DashboardContents.style";
import ResourceCard from "../resourceCard/ResourceCard";
import {
  ResourceDivHovered,
  ResourcesSliderStyle,
} from "./ResourceCardsHolder.style";
import { ResourceSliderProps } from "../../dashboardContentInterfaces";
import { useRecoilValue } from "recoil";
import { courseBannerColorSelector } from "../../../../recoil/ThemeSelectors";
import { Unhovered } from "../resourceCard/ResourceCard.style";
import { useStateContext } from "../../../../contexts/ContextProvider";

export const ResourceSlider: React.FC<ResourceSliderProps> = ({
  resources,
  background,
  title,
  allResources,
  courseId,
  searchTerm,
}) => {
  const set = ResourcesSliderSettings(resources.length, allResources);
  const [sliderKey, setSliderKey] = useState(0);
  const triggerRerender = () => {
    setSliderKey((prevKey) => prevKey + 1);
  };
  useEffect(() => {
    triggerRerender();
  }, [resources]);
  const resourcesLength = resources.length;
  const { courseTitleBgColor, theme, dashboardContentBack } = useRecoilValue(
    courseBannerColorSelector
  );
  const [StopHoveringAction, setStopHoveringAction] = useState<boolean>(true);
  const scrollTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
    }

    // Keep StopHoveringAction true while scrolling
    setStopHoveringAction(true);

    // Set StopHoveringAction to false after scrolling stops
    scrollTimeout.current = setTimeout(() => {
      setStopHoveringAction(false);
    }, 0); // Adjust the timeout duration as needed
  };

  console.log("StopHoveringAction", StopHoveringAction);

  return (
    <ResourcesTitleSliderStyle onWheel={handleWheel}>
      <ResourcesSliderStyle
        ResourceType={title}
        background={background}
        allResources={allResources}
        backgroundColor={
          allResources ? dashboardContentBack : courseTitleBgColor
        }
        // onWheel={handleWheel}
      >
        {/* {resources.length > 1 ? ( */}
        <Slider key={sliderKey} {...set}>
          {resources
            .filter((resource) =>
              resource.contentTitle
                .toLowerCase()
                .includes((searchTerm || "").toLowerCase())
            )
            .map((resource, indexR) => (
              <div key={resource.contentId}>
                <ResourceDivHovered
                  allResources={allResources}
                  style={{ cursor: "pointer" }}
                >
                  <ResourceCard
                    resourceR={resource}
                    hovered={StopHoveringAction === true ? false : true}
                    allResources={allResources}
                    courseId={courseId}
                    lastIndex={indexR === resourcesLength - 1}
                  />
                </ResourceDivHovered>
                <Unhovered allResources={allResources}>
                  <ResourceCard
                    resourceR={resource}
                    hovered={false}
                    allResources={allResources}
                    courseId={courseId}
                    lastIndex={indexR === resourcesLength - 1}
                  />
                </Unhovered>
              </div>
            ))}
        </Slider>
      </ResourcesSliderStyle>
    </ResourcesTitleSliderStyle>
  );
};
