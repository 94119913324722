import styled from "styled-components";
import { StyleThemeInterface } from "../../../../interfaces/StyleThemeInterface";

export const EditInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.63rem;
  flex: 1;
  min-width: 20rem;
`;

export const EditCompInput = styled.input<StyleThemeInterface>`
  display: flex;
  width: 100%;
  border-radius: 0.625rem;
  border: 1px solid ${(props) => props.bordercolor || "#576371"};
  color: ${(props) => props.color};
  flex-shrink: 0;
  padding: 0.87rem 1.25rem 0.81rem 1.25rem;
  background-color: transparent;
  &:focus {
    outline: none;
    /* caret-color: white; */
    caret-color: ${(FocusColor) => FocusColor.color};
  }
`;

export const EditCompTextArea = styled.textarea<StyleThemeInterface>`
  display: flex;
  width: 100%;
  border-radius: 0.625rem;
  border: 1px solid ${(props) => props.bordercolor || "#576371"};
  color: ${(props) => props.color};
  flex-shrink: 0;
  padding: 0.87rem 1.25rem 0.81rem 1.25rem;
  background-color: transparent;
  height: 12.5625rem;
  resize: none;
  &:focus {
    outline: none;
    /* caret-color: white; */
    caret-color: ${(FocusColor) => FocusColor.color};
  }
`;
