import {
  MenuWrapper,
  Frame,
  TextWithIconWrapper,
} from "./DropDownEditDelete.style";
import DropDownData from "../dropDownData";
import { PrimaryFontMediumText } from "../../../elements/fonts/Fonts";

import { useRecoilValue } from "recoil";
import { courseCreatorColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
interface DropDownEditDeleteProps {
  setShowDeleteModule: (showDeleteModule: boolean) => void;
  setShowEditModule: (showEditModule: boolean) => void;
  setShowDropDown: (showDropDown: boolean) => void;
}

const DropDownEditDelete = ({
  setShowDeleteModule,
  setShowEditModule,
  setShowDropDown,
}: DropDownEditDeleteProps): JSX.Element => {
  // Recoil state and selectors
  const { tooltipBgColor, tooltipTextColor, boxShadow } = useRecoilValue(
    courseCreatorColorSelector
  );
  const { t } = useTranslation();
  return (
    <MenuWrapper bgColor={tooltipBgColor} boxShadow={boxShadow}>
      {DropDownData.map((item, index) => (
        <Frame
          key={index}
          textColor={tooltipTextColor}
          style={{
            borderRadius: `${
              index === 0
                ? "0.3125rem 0.3125rem 0rem 0rem"
                : index === DropDownData.length - 1
                ? " 0rem 0rem 0.3125rem 0.3125rem"
                : ""
            }`,
          }}
        >
          <TextWithIconWrapper
            onClick={() => {
              if (index === 1) {
                setShowDeleteModule(true);
              } else if (index === 0) {
                setShowEditModule(true);
                setShowDropDown(false);
              }
            }}
          >
            <item.icon />
            <PrimaryFontMediumText text={t(item.title)} fontSize=" 0.875rem" />
          </TextWithIconWrapper>
        </Frame>
      ))}
    </MenuWrapper>
  );
};

export default DropDownEditDelete;
