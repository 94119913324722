import styled, { keyframes } from 'styled-components';

export const PaymentsFinallyRenderContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1.25rem;
  border-collapse: separate;
  border-spacing: 10px; /* Adjust the spacing value as needed */
  border: 2px dashed #5DD3B3; /* Adjust the border style as needed */
  min-width: 21rem;
  padding: 0.94rem;
  width: fit-content;
  max-width: 27rem;
  gap:1.25rem;
  flex:1;
  align-items: flex-start;
  &:hover{
    border: 2px solid #5DD3B3;
  }
`;

export const PaymentsFinallyRenderHeader=styled.div`
display: flex;
width: 100%;
flex-direction:column;
justify-content:center;
align-items: center;
text-transform:capitalize;
gap:0.38rem;
`;
export const PaymentsFinallyRenderPricingPerUser=styled.div`
display: flex;
align-items: center;
text-transform:capitalize;
gap:0.38rem;
gap:0.31rem;
`;

export const PaymentsFinallyRenderLine=styled.div`
display: flex;
width:100%;
height:1.1px;
background: #5DD3B3;
margin-top:-0.60rem;
`;

export const PaymentsFinallyRenderFeaturesContainer=styled.div`
display: flex;
flex-direction:column;
gap:1.25rem;
width:100%;
/* padding-bottom */
margin-bottom:3rem;
`;

export const PaymentsFinallyRenderFeaturesTitlesContainer=styled.div`
display: flex;
flex-direction:column;
gap:0.38rem;
`;

export const PaymentsFinallyRenderFeaturesLine=styled.div`
display: flex;
gap:0.75rem;
/* align-items: center; */
`;

export const PaymentsFinallyRenderFeaturesUl = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  padding-left: 1.62rem;
  ul {
    list-style: none; /* Remove default list style */
    padding-left: 0; /* Remove default padding */
  }
  li {
    display: flex; /* Ensure list items are flex items */

  }
  li:before {
    content: "•"; /* Use a smaller bullet character */
    font-size: 0.75rem; /* Adjust the font size of the bullet */
    margin-right: 0.5rem;
    margin-top:0.2rem /* Adjust spacing between bullet and text */
  }
`;


export const PaymentsFinallyTicCorrectPlace=styled.div`
display: flex;
width:0.875rem;
height:0.75rem;
margin-top:0.4rem;
`;
const waveAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const PaymentsFinallyTicChooseBtn = styled.div`
  display: flex;
  width: 12.75rem;
  height: 2.8125rem;
  flex-shrink: 0;
  border-radius: 62.5rem;
  background: #5DD3B3;
  color: #fff;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: flex-end; /* Align the button at the end of the flex container */
  margin-top: auto; /* Add margin-top auto to push it to the bottom */
  align-self: center;
  border:1px solid #fff;
  &:hover {
    background: #fff;
    color: #5DD3B3;
    border: 1px solid #5DD3B3;
    animation: ${waveAnimation} 0.5s ease-in-out; /* Apply the wave animation on hover */
  }
`;