import styled from "styled-components";
import { breakpoints } from "../../theme";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8%;
  height: 4.8125rem;

  @media (max-width: ${breakpoints.tablets}) {
    column-gap: 4%;
    display: block;
    margin-bottom: 3.5rem;
  }
  @media (max-width: ${breakpoints.mobile}) {
    column-gap: 0%;
  }
`;
export const MainWrapper = styled.div`
  /* @media (max-width: ${breakpoints.mobile}) {
    margin-left: -0.08rem;
  } */
`;

export const StatisticsWrapper = styled.div`
  height: 100%;
  display: flex;
  column-gap: 6.5rem;
  align-items: center;
  &:dir(ltr) {
    margin-right: 13.82%;
  }
  &:dir(rtl) {
    margin-left: 13.82%;
  }
  @media (max-width: ${breakpoints.extraLarge}) {
    column-gap: 4.5rem;
  }
  @media (max-width: ${breakpoints.large}) {
    column-gap: 0.5rem;
    justify-content: space-around;
    width: 100%;

    &:dir(ltr) {
      margin-right: 0;
    }
    &:dir(rtl) {
      margin-left: 0;
    }
  }
  @media (max-width: ${breakpoints.tablets}) {
    column-gap: 0.3rem;
    margin-top: 1.2rem;
    /* align-items: start; */
    justify-content: space-between;
  }
  @media (max-width: ${breakpoints.mobile}) {
    column-gap: 0.15rem;
  }
`;

interface DevideLineProps {
  lineiconcolor: string;
}
export const DevideLine = styled.div<DevideLineProps>`
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.lineiconcolor};
  height: 100%;
`;
