import React from "react";
import {
  GradeItemDetail,
  GradingDeatils,
  GradingDetalInstructor,
} from "../AssignmentInstructor.style";
import {
  SvgAttempt2,
  SvgCalendarIcon,
  SvgGrade,
  SvgGradesBannerIcon,
  SvgSimpleArrow,
  SvgStar,
} from "../../../elements/Icons";
import { LightTypography, Typography } from "../../../elements/fonts/Fonts";
import { useTranslation } from "react-i18next";
import { getFormattedDate } from "../../../utils/GetFormattedDate";
import { toArabicDigits } from "../../../utils/formatDayArabic";

interface QuizGradesDetailsProps {
  minimumGrade: any;
  average: any;
  maximumGrade: any;
  totalNumberOfAttempts: any;
  startDateTime: any;
  cutOfDateTime: any;
  loading: boolean;
  gradedCount?: any;
  NbOfSubbmition?: any;
}

const QuizGradesDetails = ({
  minimumGrade,
  maximumGrade,
  average,
  totalNumberOfAttempts,
  startDateTime,
  cutOfDateTime,
  loading,
  gradedCount,
  NbOfSubbmition,
}: QuizGradesDetailsProps) => {
  const { t } = useTranslation();
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography text={`${t("general.loading")}...`} />
      </div>
    );
  }
  return (
    <GradingDetalInstructor nbShows={NbOfSubbmition > 0 ? 4 : 3}>
      <GradeItemDetail>
        <SvgSimpleArrow style={{ transform: "rotate(90deg)" }} />
        {/* Minimum Grade between the students goes Here */}
        <LightTypography
          text={t("assignment.Minimum Grade")}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
        <Typography
          text={minimumGrade ? toArabicDigits(minimumGrade.toString()) : "-"}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
      </GradeItemDetail>
      <GradeItemDetail>
        <SvgGrade />
        {/* Class Average goes Here */}
        <LightTypography
          text={t("assignment.Class Average")}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
        <Typography
          text={average ? toArabicDigits(average.toString()) : "-"}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
      </GradeItemDetail>{" "}
      <GradeItemDetail>
        <SvgSimpleArrow style={{ transform: "rotate(-90deg)" }} />
        {/* Maximum Grade between the students goes Here */}
        <LightTypography
          text={t("assignment.Maximum grade")}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
        <Typography
          text={maximumGrade ? toArabicDigits(maximumGrade.toString()) : "-"}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
      </GradeItemDetail>{" "}
      {NbOfSubbmition > 0 && (
        <GradeItemDetail>
          <SvgGradesBannerIcon />

          <LightTypography
            text={t("assignment.Graded")}
            fontSize="0.75rem"
            color="#5dd3b3"
          />
          <Typography
            text={`${toArabicDigits(gradedCount.toString())} / ${toArabicDigits(
              NbOfSubbmition.toString()
            )}`}
            fontSize="0.75rem"
            color="#5dd3b3"
          />
        </GradeItemDetail>
      )}
      <GradeItemDetail style={{ width: "20rem" }}>
        <SvgCalendarIcon />
        {/* Dates of the Quiz Goes Here */}
        <LightTypography
          text={t("course view.Start Date")}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
        <Typography
          text={`${getFormattedDate(
            startDateTime && startDateTime.toLocaleString()
          )}`}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
      </GradeItemDetail>{" "}
      <GradeItemDetail style={{ width: "20rem" }}>
        {/* <SvgGrade />   */}
        <SvgCalendarIcon />

        <LightTypography
          text={t("forms.Cut Off Date")}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
        <Typography
          text={`${getFormattedDate(
            cutOfDateTime && cutOfDateTime.toLocaleString()
          )}`}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
      </GradeItemDetail>
      <GradeItemDetail>
        <SvgAttempt2 color="#5dd3b3" />
        {/* The Total Number of Attempt of the quiz goes Here */}
        <LightTypography
          text={t("quiz.Number of Attempts")}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
        {totalNumberOfAttempts !== -1 ? (
          <Typography
            text={`${toArabicDigits(totalNumberOfAttempts.toString())}`}
            fontSize="0.75rem"
            color="#5dd3b3"
          />
        ) : (
          <Typography
            text={t("quiz.Unlimited")}
            fontSize="0.75rem"
            color="#5dd3b3"
          />
        )}
      </GradeItemDetail>{" "}
    </GradingDetalInstructor>
  );
};

export default QuizGradesDetails;
