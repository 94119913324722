import React from "react";
import {
  SenderInfo,
  ProfileHolder,
  MaskGroup,
  OnlineDiv,
  GroupAttributes,
  ParticipantHolder,
} from "./MessageSenderInfo.style";
import { LightTypography, MediumTypography, Typography, TypographyRegularDescription } from "../fonts/Fonts";
import { useRecoilValue } from "recoil";
import { ChatsColorSelector } from "../../recoil/ThemeSelectors";

interface SenderData {
  onClick?: () => void
 participantNames?:boolean;
  profileImg?: string;
  online?: boolean;
  ProfileHolderH?: string;
  ProfileHolderW?: string;
  MaskGroupW?: string;
  MaskGroupH?: string; // Make it optional
  ShowOnline?: boolean;
  Name?: string;
  OnlineCircleWidth?: string;
  backGroundColor?: string;
  Description?: any | any[];
  participantInfo?:any[];
}

export default function MessamessageSenderInfoOnlyImgeSenderInfo({
  onClick,
  participantInfo,
participantNames,
  profileImg,
  ProfileHolderH,
  ProfileHolderW,
  MaskGroupW,
  MaskGroupH, // Make it optional or provide a default value
  online,
  ShowOnline = true,
  Name,
  Description,
  OnlineCircleWidth,
  backGroundColor,
}: SenderData) {
  const { FriendMessageBoxBG_Color } = useRecoilValue(ChatsColorSelector);
  return (
    <SenderInfo  onClick={onClick}>
      <ProfileHolder
        backGroundColor={backGroundColor || FriendMessageBoxBG_Color}
        style={{ width: ProfileHolderW, height: ProfileHolderH }}
      >
        <MaskGroup
          style={{ width: MaskGroupW, height: MaskGroupH }}
          alt="Profile Picture"
          src={profileImg}
        />
        {/* {ShowOnline && (
          <OnlineDiv
            online={online}
            OnlineCircleWidth={OnlineCircleWidth}
          ></OnlineDiv>
        )} */}
      </ProfileHolder>
      <GroupAttributes>
        <Typography
          text={Name || ""}
          fontSize="0.875rem"
          lineHeight="1.375rem"
        />
{
  participantInfo && (
<ParticipantHolder>

  {/* <TypographyRegularDescription 
    key="participants" 
    text={`You, ${participantInfo.map(info => info.username).join(', ')}`} 
    fontSize="0.75rem"
  /> */}

  {`You, ${participantInfo.map(info => info.username).join(', ')}`} 
</ParticipantHolder>

  )
}


        <div style={{ opacity: "0.5" }}>
          <TypographyRegularDescription
            text={Description || ""}
            fontSize="0.75rem"
            lineHeight="1.125rem"
          />
        </div>
      </GroupAttributes>
    </SenderInfo>
  );
}
