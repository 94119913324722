import React, { useState, useEffect } from "react";
import {
  Container,
  FlexContainer,
  LabelDiv,
  TaskTextArea,
  ButtonsDiv,
} from "./CreateTodo.style";
import {
  LightTypography,
  PrimaryFontBoldText,
  Typography,
} from "../../elements/fonts/Fonts";
import { ReactComponent as SvgToDoIcon } from "../../assets/icons/TodoList.svg";
import { ReactComponent as SvgCloseIcon } from "../../assets/icons/Close1.svg";
import StyledInput from "../../elements/StyledInput/StyledInput";
import SelectInput from "../../elements/SelectInput/SelectInput";
import { ReactComponent as MeduimIcon } from "../../assets/icons/Arrow 02.svg";
import { ReactComponent as HighIcon } from "../../assets/icons/Arrow 4.svg";
import { ReactComponent as LowIcon } from "../../assets/icons/Arrow 02 (copy).svg";
import SelectDate from "../../elements/DatePicker/inputDate";
import CancelButton from "../../elements/StyledButton/CancelButton";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import { CreateTodoProps } from "./CreateTodoInterface";
import axios from "axios";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { formatOptionsForTranslation } from "../../utils/FormatOptionsForTranslation";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import SelectDropDown from "../../elements/selectDropDown/SelectDropDown";

const CreateTodo: React.FC<CreateTodoProps> = ({ onClose, setTasks }) => {
  const [selectedEnrollement, setSelectedEnrollement] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("");
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [taskSummary, setTaskSummary] = useState("");
  const [webLink, setWebLink] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const { t } = useTranslation();
  const [EnrollmentData, setEnrollmentData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useAuth0();
  const tenantName = localStorage.getItem("tenant");
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");

  const priorityOptions = [
    // do not remove the space in medium and low
    { icon: <HighIcon />, value: "high", name: "to do list.High" },
    { icon: <MeduimIcon />, value: " medium", name: "to do list.Medium" },
    { icon: <LowIcon />, value: " low", name: "to do list.Low" },
  ];

  const translatedPriorityOptions = formatOptionsForTranslation(
    priorityOptions,
    t
  );
  const translatedEnrollmentData = formatOptionsForTranslation(
    EnrollmentData,
    t
  );

  const handleSelectEnrollment = (value: any) => {
    setSelectedEnrollement(value);
  };

  const handleSelectChangePriority = (value: any) => {
    setSelectedPriority(value);
  };

  const handleClose = () => {
    if (onClose) {
      onClose(); // Call the onClose callback to close the popup
    }
  };

  const handleNothing = () => {
  };

  // const isValidUrl = (url: string) => {
  //   // Regular expression for a simple URL validation
  //   const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  //   return urlRegex.test(url);
  // };

  const getEnrollmentData = async () => {
    try {
      const response = await axios.get(
        `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/enrollments/byUser/all/${tenantName}/${subIdSplitted}?provider=${provider}`
      );
      // Extracting only id and title keys from each object in the array
      const formattedData = response.data.data.map((enrollment: any) => ({
        id: enrollment.id,
        value: enrollment.title, // Assuming 'name' is meant to be 'title'
        name: enrollment.title, // Assuming 'name' is meant to be 'title'
      }));

      setEnrollmentData(formattedData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getEnrollmentData();
  }, []);

  const getTasks = async () => {
    try {
      const response = await axios.get(
        `https://0okzb6vum6.execute-api.eu-west-1.amazonaws.com/dev/all/${subIdSplitted}/${tenantName}?provider=${provider}`
      );
      setTasks(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async () => {
    // if (webLink && !isValidUrl(webLink)) {
    //   setUrlError(true);
    // } else {
    //   setUrlError(false);
    if (!taskSummary || !selectedPriority || !description) {
      setError(true);
    } else {
      setError(false);

      // Create a new task object
      const newTask = {
        title: taskSummary,
        enrollment: selectedEnrollement,
        classSourceId: EnrollmentData.find(
          (enrollment) => enrollment.value === selectedEnrollement
        )?.id,
        priority: selectedPriority,
        dueDate: dueDate,
        webLink: webLink,
        subId: subIdSplitted,
        description: description,
        stageId: 1,
        provider:provider,
      };


      try {
        setLoading(true);
        const createResponse = await axios.post(
          `https://0okzb6vum6.execute-api.eu-west-1.amazonaws.com/dev/create/${tenantName}`,
          {
            data: newTask,
          }
        );
        setLoading(false);
        // Reset the form fields
        setTaskSummary("");
        setSelectedEnrollement("");
        setSelectedPriority("");
        setDueDate(null);
        setWebLink("");
        setDescription("");
        handleClose();
        getTasks();
      } catch (err) {
        console.log(err);
      }
    }
  };
  const { themeTextColor, bghelp, theme, inputBorderColor } =
    useRecoilValue(ColorSelector);
  return (
    <Container color={themeTextColor} theme={theme}>
      <FlexContainer>
        <LabelDiv>
          <LightTypography
            text={`${t("to do list.Task summary")} *`}
            fontSize="1rem"
          />
          <StyledInput
            placeholder={t("to do list.Enter Task summary")}
            type="text"
            width="18rem"
            value={taskSummary}
            onChange={(e) => setTaskSummary(e.target.value)}
          />
          {!taskSummary && error && (
            <ErrorDiv>{`${t("forms.Please")} ${t(
              "to do list.Enter Task summary"
            )}`}</ErrorDiv>
          )}
        </LabelDiv>
        <LabelDiv>
          <LightTypography text={t("to do list.Enrollment")} fontSize="1rem" />
          <SelectDropDown
            onChange={handleSelectEnrollment}
            placeholder={t("to do list.Select Enrollment")}
            width="18rem"
            options={EnrollmentData}
            value={selectedEnrollement}
          />
          {error && <div></div>}
        </LabelDiv>
      </FlexContainer>

      <FlexContainer>
        <LabelDiv>
          <LightTypography
            text={`${t("to do list.Priority")} *`}
            fontSize="1rem"
          />
          <SelectDropDown
            onChange={handleSelectChangePriority}
            placeholder={t("to do list.Select Priority")}
            width="18rem"
            options={translatedPriorityOptions}
            value={selectedPriority}
          />
          {!selectedPriority && error && (
            <ErrorDiv>{`${t("forms.Please")} ${t(
              "to do list.Select Priority"
            )}`}</ErrorDiv>
          )}
        </LabelDiv>
        <LabelDiv>
          <LightTypography text={t("forms.Due Date")} fontSize="1rem" />
          <SelectDate
            showTime={false}
            width="18rem"
            value={dueDate}
            onChange={(date: Date | null) => setDueDate(date)}
          />
          {error && <div></div>}
        </LabelDiv>
      </FlexContainer>

      <LabelDiv>
        <LightTypography text={t("to do list.Add web link")} fontSize="1rem" />
        <StyledInput
          type="text"
          width="100%"
          value={webLink}
          onChange={(e) => setWebLink(e.target.value)}
        />
      </LabelDiv>
      {/* {urlError && <ErrorDiv>Please Enter a Valid URL</ErrorDiv>} */}
      <LabelDiv>
        <LightTypography text={t("forms.Description")} fontSize="1rem" />
        <TaskTextArea
          color={themeTextColor}
          bordercolor={inputBorderColor}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {!description && error && <ErrorDiv>{`${t("forms.Please")} ${t( "forms.Enter Description" )}`}</ErrorDiv>}
      </LabelDiv>
      <ButtonsDiv>
        <CancelButton
          name={t("forms.Cancel")}
          onClickFunction={handleClose}
          color="#D85D66"
          borderColor="#D85D66"
        />
        {loading ? (
          <CancelButton
            name={`${t('general.loading')}...`}
            onClickFunction={handleNothing}
            color="#fff"
            borderColor="#5DD3B3"
            backgroundColor="#5DD3B3"
          />
        ) : (
          <CancelButton
            name={t("forms.Save")}
            onClickFunction={handleSave}
            color="#fff"
            borderColor="#5DD3B3"
            backgroundColor="#5DD3B3"
          />
        )}
      </ButtonsDiv>
    </Container>
  );
};

export default CreateTodo;
