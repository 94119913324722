import React, { ReactNode, createContext, useContext, useState } from 'react';

interface SurveyGraderContextProps {
    answerId: number;
    setAnswerId: React.Dispatch<React.SetStateAction<number>>;
    questionComment: string;
    setQuestionComment: React.Dispatch<React.SetStateAction<string>>;
    commented:boolean;
    setCommented:React.Dispatch<React.SetStateAction<boolean>>;
    submissionDate:string;
    setSubmissionDate:React.Dispatch<React.SetStateAction<string>>;
  }

// Create the context
const surveyGraderContext = createContext<SurveyGraderContextProps | undefined>(undefined);

// Create a provider component
const MyContextProvider = ({ children }: { children: ReactNode }) => {
    const [answerId, setAnswerId] = useState<number>(0);
    const [questionComment, setQuestionComment] = useState<string>('');
    const [submissionDate, setSubmissionDate] = useState<string>('');
    const [commented, setCommented] = useState<boolean>(false);
  
    return (
      <surveyGraderContext.Provider value={{ answerId, setAnswerId,questionComment,setQuestionComment,commented,setCommented,submissionDate,setSubmissionDate }}>
        {children}
      </surveyGraderContext.Provider>
    );
  };
  
  // Create a custom hook to access the context values
const useMyContext = () => {
    const context = useContext(surveyGraderContext);
    if (!context) {
      throw new Error('useMyContext must be used within a MyContextProvider');
    }
    return context;
  };
  
  export { MyContextProvider, useMyContext };