import styled from "styled-components";



export const EmptyPostContainer = styled.div`

display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
padding:5rem 0;
gap:1.80rem

`;