import React, { ReactNode } from 'react';
import { ImageStyled, Container, WavesWrapper, SingleWaveWrapper, BottomWelcomeWrapper, WhiteWrapper, WhiteWrapperWelcome, BottomLeftLine, FormWrapper, BottomLogo } from "./LandingPageStatic.style";
import { SvgWavesIcon, SvgSingleWaveIcon, SvgZydiaSmallIcon,SvgZydiaLargeIcon  } from "../../elements/Icons";
import { Typography, TypographyRegularDescription } from "../../elements/fonts/Fonts";
// interface BackgroundImageInterface{
//     imgPath:string;
// }

interface LandingPageStaticInterface {
    children: ReactNode;
}
const LandingPageStatic = ({ children }: LandingPageStaticInterface): JSX.Element => {
    return (
        <Container>
            <WavesWrapper>
                <SvgWavesIcon />

            </WavesWrapper>
            <WhiteWrapperWelcome>
                <BottomWelcomeWrapper>
                    <Typography
                        text="Welcome to your lifelong learning Journey"
                        fontSize="3.75rem"
                        lineHeight="normal"
                        fontWeight="400"
                    />
                </BottomWelcomeWrapper>
                <WhiteWrapper>
                    <FormWrapper>
                        <SvgZydiaLargeIcon />
                        {children}

                    </FormWrapper>
                    <BottomLogo>
                        <TypographyRegularDescription
                            text="Powered by"
                            fontSize="0.75rem"
                            lineHeight="normal"
                            fontWeight="400"
                        />
                        <SvgZydiaSmallIcon />
                    </BottomLogo>
                    <SvgWavesIcon />

                </WhiteWrapper>

            </WhiteWrapperWelcome>

            <ImageStyled>

                <SingleWaveWrapper>
                    <SvgSingleWaveIcon />
                </SingleWaveWrapper>
            </ImageStyled>

            <BottomLeftLine />
        </Container>


    )
}

export default LandingPageStatic