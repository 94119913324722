import { QuizQuestionsAnalyticsInterfaceV2 } from "./QuizQuestionsAnalyticsinterfaceV2";

export const QuizQuestionsAnalyticsDataV2: QuizQuestionsAnalyticsInterfaceV2[] = [
  {
    QuestionCode: "esdfc3453",
    QuestionTitle: "Question 1",
    QuestionType: "Numerical", // Corrected spelling
    QuestionText:"What is the answer of 5/2",
    QuestionDescription: "The Correct Answer : 2.5    Error margin:  3",
    points: 3, // Corrected spelling
    students:[
      {
        StdCode:"34f32",  StdName:"Hadi Shouman", TimeSpent:3, Answered:"3.5", Grade:4 ,
      },
      {
        StdCode:"54fg45",  StdName:"Jihad Semaan", TimeSpent:5, Answered:"2.4", Grade:2,
      },
      {
        StdCode:"Salim32234",  StdName:"Salim Bahsoun", TimeSpent:3, Answered:null, Grade:4,
      },
      {
        StdCode:"3rfeg",  StdName:"Yassmina Dbouk", TimeSpent:0, Answered:"2.4", Grade:4,
      },
      {
        StdCode:"324fdfdgwq",  StdName:"Rawad Shouman", TimeSpent:3,  Answered:null, Grade:4,
      },
      {
        StdCode:"Walsdr435id",  StdName:"Abdallah Zbib", TimeSpent:4, Answered:"3", Grade:3,
      },
    ]
  },
  
  {
    QuestionCode: "4rtgtrfgew",
    QuestionTitle: "Question 2",
    QuestionType: "True or False", // Corrected spelling
    QuestionText: "Is 5 greater than or equal to 5",
    points: 2, // Corrected spelling
    students:[
      {
        StdCode:"34f32",  StdName:"Hadi Shouman", TimeSpent:3, Answered:false, Grade:3,
      },
      {
        StdCode:"54fg45",  StdName:"Jihad Semaan", TimeSpent:5, Answered:true, Grade:2,
      },
      {
        StdCode:"Salim32234",  StdName:"Salim Bahsoun", TimeSpent:3, Answered:true, Grade:0,
      },
      {
        StdCode:"3rfeg",  StdName:"Yassmina Dbouk", TimeSpent:0, Answered:null, Grade:3,
      },
      {
        StdCode:"324fdfdgwq",  StdName:"Rawad Shouman", TimeSpent:3, Answered:true, Grade:4,
      },
      {
        StdCode:"Walsdr435id",  StdName:"Abdallah Zbib", TimeSpent:4, Answered:false, Grade:7,
      },
    ]
  },
  {
    QuestionCode: "sdf435fsde",
    QuestionTitle: "Question 3",
    QuestionType: "Multiple Choice", // Corrected spelling
    QuestionText:"Selct the numbers that is greater than 10 and less than 15",
    points: 5, // Corrected spelling
    students:[
      {
        StdCode:"34f32",  StdName:"Hadi Shouman", TimeSpent:3,  Answered:["10","13","15"], Grade:3,
      },
      {
        StdCode:"54fg45",  StdName:"Jihad Semaan", TimeSpent:5, Answered:["11","13",], Grade:2,
      },
      {
        StdCode:"Salim32234",  StdName:"Salim Bahsoun", TimeSpent:3, Answered:["11","13",],  Grade:0,
      },
      {
        StdCode:"3rfeg",  StdName:"Yassmina Dbouk", TimeSpent:0, Answered:["11","13",], Grade:3,
      },
      {
        StdCode:"324fdfdgwq",  StdName:"Rawad Shouman", TimeSpent:3, Answered:["10","13","15"], Grade:4,
      },
      {
        StdCode:"Walsdr435id",  StdName:"Abdallah Zbib", TimeSpent:4, Answered:["10","13","15"], Grade:7,
      },
    ]
  },
  {
    QuestionCode: "dsghjuytrfdfdgfd",
    QuestionTitle: "Question 4",
    QuestionType: "Matching", // Corrected spelling
    points: 3, // Corrected spelling
    QuestionText:"What are the colors found in the Lebanon flag?",
    students:[
      {
        StdCode:"34f32",  StdName:"Hadi Shouman", TimeSpent:3, Answered:["White"], Grade:4,
      },
      {
        StdCode:"54fg45",  StdName:"Jihad Semaan", TimeSpent:5, Answered:["White","Green"], Grade:2,
      },
      {
        StdCode:"Salim32234",  StdName:"Salim Bahsoun", TimeSpent:3, Answered:["Red","Green"], Grade:4,
      },
      {
        StdCode:"3rfeg",  StdName:"Yassmina Dbouk", TimeSpent:0, Answered:["White","Red"], Grade:4,
      },
      {
        StdCode:"324fdfdgwq",  StdName:"Rawad Shouman", TimeSpent:3, Answered:["White","Green"], Grade:4,
      },
      {
        StdCode:"Walsdr435id",  StdName:"Abdallah Zbib", TimeSpent:4, Answered:["White","Green"], Grade:3,
      },
    ]
  },
  
  {
    QuestionCode: "65uhbffds",
    QuestionTitle: "Question 5",
    QuestionType: "Short answer", 
    points: 2, 
    students:[
      {
        StdCode:"34f32",  StdName:"Hadi Shouman", TimeSpent:3, Answered:"false", Grade:3,
      },
      {
        StdCode:"54fg45",  StdName:"Jihad Semaan", TimeSpent:5, Grade:2,
      },
      {
        StdCode:"Salim32234",  StdName:"Salim Bahsoun", TimeSpent:3, Answered:"false", Grade:0,
      },
      {
        StdCode:"3rfeg",  StdName:"Yassmina Dbouk", TimeSpent:0, Answered:"false", Grade:3,
      },
      {
        StdCode:"324fdfdgwq",  StdName:"Rawad Shouman", TimeSpent:3, Answered:"true", Grade:4,
      },
      {
        StdCode:"Walsdr435id",  StdName:"Abdallah Zbib", TimeSpent:4, Answered:null, Grade:7,
      },
    ]
  },
  {
    QuestionCode: "sdfte43fghgfh",
    QuestionTitle: "Question 6",
    QuestionType: "Essay", // Corrected spelling
    points: 5, // Corrected spelling
    students:[
      {
        StdCode:"34f32",  StdName:"Hadi Shouman", TimeSpent:3, Answered:"SAZFSDZFVSDGVBGFBVD", Grade:3,
      },
      {
        StdCode:"54fg45",  StdName:"Jihad Semaan", TimeSpent:5, Grade:2,
      },
      {
        StdCode:"Salim32234",  StdName:"Salim Bahsoun", TimeSpent:3, Answered:"true", Grade:0,
      },
      {
        StdCode:"3rfeg",  StdName:"Yassmina Dbouk", TimeSpent:0, Answered:"true", Grade:3,
      },
      {
        StdCode:"324fdfdgwq",  StdName:"Rawad Shouman", TimeSpent:3, Answered:"true", Grade:4,
      },
      {
        StdCode:"Walsdr435id",  StdName:"Abdallah Zbib", TimeSpent:4, Answered:"false", Grade:7,
      },
    ]
  },
];

