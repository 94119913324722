import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const QuestionType = styled.div<StyleThemeInterface>`
  width: 17.25rem;
  height: 36rem;
  border-radius: 0.625rem;
  background:${props =>props.bgcolor};
  color:${props =>props.color};
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const TypesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.69rem;
`;
