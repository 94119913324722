import {styled} from "styled-components"
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";
export const IdCols =styled.div`
display:flex;
flex-direction:column;
width:100%;
gap: 1.25rem;
`;
export const IdminiCols =styled.div`
display:flex;
flex-direction:column;
width:95%;
gap:0.62rem;
min-height:3rem;

`;

export const IdRows =styled.div`
display:flex;
gap:0.5rem;
width:100%;
justify-content: space-between;
`;
export const ButtonsContainer =styled.div`
display:flex;
gap:0.5rem;
width:100%;
justify-content: flex-end;
`;




export const IconsGroup =styled.div`
display:flex;
gap:1rem;
align-items:center;
cursor: pointer;
`;

export const TextLayout =styled.input<StyleThemeInterface>`
width:100%;
height: 2.8125rem;
padding:0.94rem 0.91rem;
background-color:transparent;
border-radius: 0.625rem;
border: 1px solid ${props => props.bordercolor};
margin-bottom:0.5rem;
color:${props =>props.color};
&:focus {
    outline: none;
    caret-color:${props =>props.color};
  }
`;
