import React, { useState, FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  uuidSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import axios from "axios";
import { Container } from "../../elements/StyledContainerForm/StyledContainerForm.style";
import {
  LightTypography,
  PrimaryFontBoldText,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import StyledInput from "../../elements/StyledInput/StyledInput";
import { TextEditor } from "../../elements/TextEditor/TextEditor";
import { ReactComponent as SvgCloseIcon } from "../../assets/icons/Close1.svg";
import Toggle from "../../elements/Toogle/toogle";
import TwoDatePicker from "../../elements/TwoDatePicker/TwoDatePicker";
import CancelButton from "../../elements/StyledButton/CancelButton";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import { useStateContext } from "../../contexts/ContextProvider";
import LoaderThreeDot from "../loader/loaderThreeDot/LoaderThreeDot";
import SelectDate from "../../elements/DatePicker/inputDate";
import {
  BtnsDiv,
  ButtonsDiv,
  DateAndTimeDiv,
  FlexBetween,
  FlexDiv,
  LabelBtnsDiv,
  LabelDiv,
  LimitInput,
} from "./PollForm.style";
import { SvgPoll } from "../../elements/Icons";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
interface ContentFormProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
  setShowEditContent: React.Dispatch<React.SetStateAction<boolean>>;
  showEditContent: boolean;
  setContentTitleInTopic: (contentTitle: string) => void;
  editContent: (contentId: number, newTitle: string) => void;
  newContentTitle: string | undefined;
  contentId: number | undefined;
  activeGetModulesWithTopics: () => void;
  newTopicRefId: number;
}

const PollForm = () => {
  const classSourceId = useParams();

  const setClassSourceId = classSourceId.id;

  const { isLoading, user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");

  const baseURL = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";
  const path = "resource/create";

  const tenantName = localStorage.getItem("tenant");

  const {
    setContent_Title,
    contentType,
    setContent_Data,
    getContent_Data,
    contentIdToEdit,
    topicRefId,
    content_Index,
  } = useStateContext();

  const [prevData, setPrevData] = useState<any>();
  const [readyToEdit, setReadyToEdit] = useState(true);
  const [foundRefIdState, setFoundRefIdState] = useState<number>();

  function isObjectEmpty(obj: any) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  const [pollName, setPollName] = useState("");
  const [description, setDescription] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [error, setError] = useState(false);
  const [visibleStudents, setVisibleStudents] = useState(true);
  const [showTimeVisibility, setShowTimeVisibility] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isSaveResume, setIsSaveResume] = useState(false);
  const [isLimitchoice, setIsLimitchoice] = useState(false);
  const [isShowPercentage, setIsShowPercentage] = useState(false);
  const [LimitchoiceNumber, setLimitchoiceNumber] = useState("");

  //   const updateLink = async (path: string, body: any) => {
  //     try {
  //       const response = await axios.put(
  //         `${baseURL}/${path}/${foundRefIdState}/${tenantName}`,
  //         {
  //           data: body,
  //         }
  //       );
  //       console.log(response.data);
  //       return response.data;
  //     } catch (error) {
  //       console.error("Error in PUT request:", error);
  //       throw error; // Rethrow the error to be handled by the calling service
  //     }
  //   };

  //   const getURLToEdit = async (path: string, urlId: number) => {
  //     try {
  //       const tenantName = localStorage.getItem("tenant");
  //       const res = await axios.get(`${baseURL}/${path}/${urlId}/${tenantName}`);
  //       setPrevData(res.data.data);
  //       console.log("Link data.data", res.data.data);
  //       setReadyToEdit(false);

  //       const toReturn: any[] = res.data.data;
  //       return toReturn;
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   useEffect(() => {
  //     if (showEditContent && urlName !== "") {
  //       setReadyToEdit(false);
  //     }
  //   }, [urlName]);

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       const yourArray = await activeGetModulesWithTopics();
  //       const solutionArray: any[] = Array.isArray(yourArray) ? yourArray : [];
  //       function findRefIdInModules(
  //         modulesArray: any[],
  //         targetContentId: number
  //       ) {
  //         console.log("You are in the main for loop", yourArray);
  //         for (let i = 0; i < modulesArray.length; i++) {
  //           const topicsArray = modulesArray[i].topicsArray;

  //           for (let j = 0; j < topicsArray.length; j++) {
  //             const contentsArray = topicsArray[j].contents;

  //             for (let k = 0; k < contentsArray.length; k++) {
  //               if (contentsArray[k].contentId === targetContentId) {
  //                 return contentsArray[k].refId;
  //               }
  //             }
  //           }
  //         }
  //         // Return a default value (e.g., null) if the contentId is not found
  //         return null;
  //       }
  //       function findIsYoutubeInModules(
  //         modulesArray: any[],
  //         targetContentId: number
  //       ) {
  //         console.log("You are in the main for loop", yourArray);
  //         for (let i = 0; i < modulesArray.length; i++) {
  //           const topicsArray = modulesArray[i].topicsArray;

  //           for (let j = 0; j < topicsArray.length; j++) {
  //             const contentsArray = topicsArray[j].contents;

  //             for (let k = 0; k < contentsArray.length; k++) {
  //               if (
  //                 contentsArray[k].contentId === targetContentId &&
  //                 contentsArray[k].type === "Link"
  //               ) {
  //                 return contentsArray[k].isYoutube;
  //               }
  //             }
  //           }
  //         }
  //         // Return a default value (e.g., null) if the contentId is not found
  //         return null;
  //       }
  //       if (contentIdToEdit) {
  //         const contentIdToFind = contentIdToEdit; // Replace with the specific contentId you're looking for
  //         const foundRefId = findRefIdInModules(solutionArray, contentIdToFind);
  //         setFoundRefIdState(foundRefId);
  //         const isYoutube = findIsYoutubeInModules(
  //           solutionArray,
  //           contentIdToFind
  //         );
  //         console.log(
  //           "******************8 Is Youtube ******************",
  //           isYoutube
  //         );

  //         if (foundRefId !== null) {
  //           console.log(
  //             `The refId corresponding to contentId ${contentIdToFind} is: ${foundRefId}`
  //           );
  //         } else {
  //           console.log(`ContentId ${contentIdToFind} not found in the array.`);
  //         }
  //         if (isYoutube) {
  //           await getURLToEdit("resource/youtube", foundRefId);
  //         } else {
  //           await getURLToEdit("resource/link", foundRefId);
  //         }
  //       }
  //     };
  //     if (showEditContent) {
  //       fetchData();
  //       // setReadyToEdit(false);
  //     }
  //   }, []);

  //   useEffect(() => {
  //     if (prevData && showEditContent) {
  //       setContent_Title(prevData.title);
  //       setUrlName(prevData.title);
  //       setDescription(prevData.description);
  //       setUrlLink(prevData.url);
  //       setYoutubeLink(prevData.youtubeLink);
  //       if(prevData.youtubeLink){
  //         setUrlType('youtube');
  //       }
  //       setVisibleStudents(prevData.isVisible);
  //       setShowTimeVisibility(
  //         prevData.visibleFrom === null || prevData.visibleTo === null
  //           ? false
  //           : true
  //       );
  //       setFromDate(prevData.visibleFrom ? new Date(prevData.visibleFrom) : null);
  //       setToDate(prevData.visibleTo ? new Date(prevData.visibleTo) : null);
  //     }
  //   }, [prevData]);

  const handleDescriptionChange = (value: any) => {
    setDescription(value);
  };

  const handleToggleChangeVisible = (isChecked: boolean) => {
    setVisibleStudents(isChecked);
  };
  const handleToggleAnonymous = (isChecked: boolean) => {
    setIsAnonymous(isChecked);
  };
  const handleToggleSaveResume = (isChecked: boolean) => {
    setIsSaveResume(isChecked);
  };
  const handleToggleShowPercentage = (isChecked: boolean) => {
    setIsShowPercentage(isChecked);
  };
  const handleToggleLimitchoice = (isChecked: boolean) => {
    setIsLimitchoice(isChecked);
  };

  const handleTwoDatePickerChange = (start: Date | null, end: Date | null) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleToggleChangeTime = (isChecked: boolean) => {
    if (isChecked) {
      setShowTimeVisibility(true); // Show Time Visibility when the toggle is on
    } else {
      setShowTimeVisibility(false);
      setToDate(null);
      setFromDate(null);
    }
  };

  const handleStartDate = (startDateTime: Date | null) => {
    setStartDate(startDateTime);
    setFromDate(startDateTime);
  };
  const handleEndDate = (endDateTime: Date | null) => {
    setEndDate(endDateTime);
    setToDate(endDateTime);
  };

  //Function to check if the value is positive
  const isPositiveNumber = (value: string) => {
    return /^\d+(\.\d+)?$/.test(value) && parseFloat(value) >= 0;
  };

  //Function to handle changes in the limit value.
  const handleLimitChange = (
    value: string,
    setStateFunction: (value: string) => void
  ) => {
    //Check if the value is a positive number or an empty string
    if (isPositiveNumber(value) || value === "") {
      setStateFunction(value);
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleContinueClick = async () => {
    if (
      !pollName ||
      !description ||
      (showTimeVisibility && (!fromDate || !toDate)) ||
      (isLimitchoice && !LimitchoiceNumber)
    ) {
      setError(true);
      //   setShow(true);
    } else {
      setError(false);
      //   if (contentId !== undefined && newContentTitle !== undefined)
      //     editContent(contentId, newContentTitle);
      //   setShowEditContent(false);
      //   setShow(false);
      //   if (!showEditContent) {
      //     handleSubmit();
    }

    const UrlLinkData: any = {
      _id: contentIdToEdit,
      // id: showEditContent ? foundRefIdState : null,
      classSourceId: setClassSourceId,
      userSourceId: subId,
      provider: provider,
      contentIndex: content_Index,
      // topicRefId: newTopicRefId,
      type: contentType,
      title: pollName,
      description: description,
      startDate: startDate,
      endDate: endDate,
      isAnonymous: isAnonymous,
      isSaveResume: isSaveResume,
      isLimitchoice: isLimitchoice,
      LimitchoiceNumber: LimitchoiceNumber,
      showTimeVisibility: showTimeVisibility,
      visibleStudents: visibleStudents,
      fromDate: fromDate,
      toDate: toDate,
    };

    try {
      // if (!showEditContent) {
      //   const response = await axios.post(
      //     `${baseURL}/${path}/${tenantName}`,
      //     {
      //       data: UrlLinkData,
      //     }
      //   );
      //   console.log("**link data to post**", UrlLinkData);
      //   console.log("**Axios Response**", response.data);
      // } else if (showEditContent) {
      //   await updateLink("resource/link", UrlLinkData);
      //   console.log("**Data to update**", UrlLinkData);
      // }
      // setShow(false);
      // await activeGetModulesWithTopics();
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };
  const { backgroundColor, themeTextColor, inputBorderColor } =
    useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  return (
    <Container
      themetextcolor={themeTextColor}
      backgroundcolor={backgroundColor}
      scrollbar={inputBorderColor}
    >
      <FlexBetween>
        <FlexDiv>
          <SvgPoll />
          <PrimaryFontBoldText text="Create Poll" fontSize="1.125rem" />
        </FlexDiv>
        {/* {showEditContent && readyToEdit && <LoaderThreeDot />} */}
        <SvgCloseIcon
          color="#5DD3B3"
          onClick={() => {
            // setShow(false);
            // setShowEditContent(false);
          }}
          style={{ cursor: "pointer" }}
        />
      </FlexBetween>

      <LabelDiv>
        <FlexDiv gap="0.25rem">
          <LightTypography text="Poll Name" fontSize="0.875rem" />
          <span>*</span>
        </FlexDiv>
        <StyledInput
          placeholder="Enter Poll Name"
          type="text"
          onChange={(e: any) => {
            setPollName(e.target.value);
            //   setContent_Title(e.target.value);
            //   setContentTitleInTopic(e.target.value);
          }}
          value={pollName}
        />
        {!pollName && error && <ErrorDiv>Please Enter URL Name</ErrorDiv>}
      </LabelDiv>

      <LabelDiv>
        <FlexDiv gap="0.25rem">
          <LightTypography text="Description" fontSize="0.875rem" />
          <span>*</span>
        </FlexDiv>
        <TinyEditor
          onChange={handleDescriptionChange}
          placeholder={t("general.Description")}
          //   initialValue={`${
          //     prevData && prevData.description ? prevData.description : ""
          //   }`}
          height="13.25rem"
        />
        {!description && error && (
          <ErrorDiv>{t("scorm.Please Enter Description")}</ErrorDiv>
        )}
      </LabelDiv>

      {/* Date and time section */}
      <DateAndTimeDiv>
        <LabelDiv>
          <LightTypography text={t("forms.Start Date")} fontSize="0.875rem" />
          <SelectDate
            width="30rem"
            value={startDate}
            onChange={handleStartDate}
          />
        </LabelDiv>
        <LabelDiv>
          <LightTypography text={t("forms.End Date")} fontSize="0.875rem" />
          <SelectDate width="30rem" value={endDate} onChange={handleEndDate} />
        </LabelDiv>
      </DateAndTimeDiv>

      <BtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleChangeVisible}
            checked={visibleStudents}
            id="visibleStudents"
          />
          <TypographyRegularDescription
            text="Make Visible for Students"
            fontSize="1rem"
          />
        </LabelBtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleAnonymous}
            checked={isAnonymous}
            id="isAnonymous"
          />
          <TypographyRegularDescription text="Anonymous" fontSize="1rem" />
        </LabelBtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleSaveResume}
            checked={isSaveResume}
            id="isSaveResume"
          />
          <TypographyRegularDescription text="Save/Resume" fontSize="1rem" />
        </LabelBtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleShowPercentage}
            checked={isShowPercentage}
            id="isShowPercentage"
          />
          <TypographyRegularDescription
            text="Show percentage of response"
            fontSize="1rem"
          />
        </LabelBtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleLimitchoice}
            checked={isLimitchoice}
            id="isLimitchoice"
          />
          <TypographyRegularDescription text="Limit choice" fontSize="1rem" />
          {isLimitchoice && (
            <>
              <LimitInput
                type="number"
                value={LimitchoiceNumber}
                onChange={(e) =>
                  handleLimitChange(e.target.value, setLimitchoiceNumber)
                }
              />
              {!LimitchoiceNumber && error && (
                <ErrorDiv>Please Enter a valid Limit choice Number</ErrorDiv>
              )}
            </>
          )}
        </LabelBtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleChangeTime}
            checked={showTimeVisibility}
            id="showTimeVisibility"
          />
          <TypographyRegularDescription
            text="Set Time Visibility Interval"
            fontSize="1rem"
          />
          {showTimeVisibility && (
            <>
              <TwoDatePicker
                onDateChange={handleTwoDatePickerChange}
                prevStartDate={fromDate}
                prevEndDate={toDate}
              />
              {(!fromDate || !toDate) && error && (
                <ErrorDiv>Please Set Time Visibility Interval</ErrorDiv>
              )}
            </>
          )}
        </LabelBtnsDiv>
      </BtnsDiv>

      <ButtonsDiv>
        <CancelButton
          name="Cancel"
          onClickFunction={() => {
            // setShow(false);
            // setShowEditContent(false);
          }}
          color="#D85D66"
          borderColor="#D85D66"
          backgroundColor="#384450"
        />
        <CancelButton
          name="Continue"
          onClickFunction={() => {
            handleContinueClick();
            setContent_Title(pollName);
          }}
          color="#fff"
          borderColor="#5DD3B3"
          backgroundColor="#5DD3B3"
        />
      </ButtonsDiv>
    </Container>
  );
};

export default PollForm;
