import React from "react";
import {
  GAAActions,
  GAAContainer,
  GAAContent,
  GAAGaps,
  GAALayout,
  GAAProfile,
  GAAdesc,
} from "./GraderAssignmentAnswer.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../../../elements/fonts/Fonts";
import { SvgDownload, SvgEye, SvgGroup3339 } from "../../../../elements/Icons";
import DOMPurify from "dompurify";
import { uuidSplitter } from '../../../../utils/StringManipulation';
import { useTranslation } from "react-i18next";
interface GraderAssignmentAnswerInterface {
  profilePic?: string;
  Name?: string;
  Subject?: string;
  EyeClick?: () => void;
  DownloadClick?: () => void;
  fileName?: string;
  filePath?: string;
}

const GraderAssignmentAnswer = ({
  profilePic,
  Name,
  Subject,
  EyeClick,
  DownloadClick,
  fileName,
  filePath,
}: GraderAssignmentAnswerInterface) => {
  const { t } = useTranslation();

  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };
  return (
    <GAALayout>
      <GAAProfile
        // src={require("../../../assets/AliAhmad.png")}
        src={profilePic}
        alt={Name || ""}
      />
      <GAAContainer>
        <Typography text={Name || ""} fontSize="1rem" />
        <GAAdesc>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(decodeHtml(Subject)),
            }}
            style={{ fontSize: "0.875rem", lineHeight: "1.375rem" }}
          />
        </GAAdesc>

        {/* her you must make the map render  */}
        {fileName?.trim() !== "" ? (
          <GAAContent>
            <GAAGaps>
              <SvgGroup3339 />
              <MediumTypography text={uuidSplitter(fileName || "")} />
            </GAAGaps>
            <GAAGaps>
              {/* {fileName?.endsWith(".pdf") ? (
              <GAAActions onClick={EyeClick} >
              <SvgEye />
              <LightTypography
                text={t('assignment.View')}
                fontSize="0.875rem"
                color="#5DD3B3"
              />
            </GAAActions>
            ) : (
              ""
            )} */}


              <a href={filePath} download={filePath}>
                <GAAActions>
                  <SvgDownload fill="#5DD3B3" />
                  <LightTypography
                    text={t('online session.Download')}
                    fontSize="0.875rem"
                    color="#5DD3B3"
                  />
                </GAAActions>
              </a>

            </GAAGaps>
          </GAAContent>
        ) : (
          <MediumTypography text={t("grader.No Files")}/>
        )}


      </GAAContainer>
    </GAALayout>
  );
};

export default GraderAssignmentAnswer;
