import React from "react";
import { PuplicProfileDtaInfo } from "./PuplicProfileDataInfo";
import {
  DataProfileMainCols,
  DataProfileRenderCols,
  DataProfileMiniRow,
  HeightSizeA,
  HeightSize,
  DataProfileMiniCols,
  GroupA,
} from "./PuplicProfileDataInfo.style";
import {
  Typography,
  LightTypography,
  TypographyRegularDescription,
  MediumTypography,
} from "../../elements/fonts/Fonts";
import { useTranslation } from "react-i18next";

interface ProfileInfo {
  // src: string;
  FullName: string;

  data: any;
}

export const PuplicProfileDataTsx = ({
  FullName,

  data,
}: ProfileInfo): JSX.Element => {
  const { t } = useTranslation();
  const tenantName = localStorage.getItem("tenant");
  console.log("FullName is :", FullName);

  return (
    <DataProfileMainCols>
      <GroupA>
        <HeightSize>
          <Typography
            // text={PuplicProfileDtaInfo.FullName}
            text={FullName}
            fontSize="1.75rem"
          />
          <TypographyRegularDescription
            text={data.headline}
            // text={major}/
            fontSize="0.875rem"
            color="rgba(255, 255, 255, 0.50)"
          />
        </HeightSize>
        <HeightSize>
          <TypographyRegularDescription
            text={tenantName || ""}
            fontSize="1rem"
            color="#5DD3B3"
          />
        </HeightSize>
      </GroupA>

      <HeightSizeA>
        <DataProfileMiniCols>
          {data.programLevel && data.programLevel.length > 0 && (
            <DataProfileRenderCols>
              <DataProfileMiniRow>
                <MediumTypography text="Program Level : " fontSize="0.938rem" />
              </DataProfileMiniRow>
              <Typography
                // text={PuplicProfileDtaInfo.programLevetl}
                text={
                  Array.isArray(data.programLevel)
                    ? data.programLevel.join(" - ")
                    : data.programLevel
                }
                fontSize="0.938rem"
                color="#5DD3B3"
              />
            </DataProfileRenderCols>
          )}
          {data.academicProgram && data.academicProgram.length > 0 && (
            <DataProfileRenderCols>
              <DataProfileMiniRow>
                <MediumTypography
                  text="Academic Program :"
                  fontSize="0.938rem"
                />
              </DataProfileMiniRow>
              <Typography
                // text={PuplicProfileDtaInfo.Academinprogram}
                text={
                  Array.isArray(data.academicProgram)
                    ? data.academicProgram.join(" - ")
                    : data.academicProgram
                }
                // text={AcademicProgram}
                fontSize="0.938rem"
                color="#5DD3B3"
              />
            </DataProfileRenderCols>
          )}
          {data.academicUnit && data.academicUnit.length > 0 && (
            <DataProfileRenderCols>
              <DataProfileMiniRow>
                <MediumTypography
                  text={`${t("general.Academic Unit")} :`}
                  fontSize="0.938rem"
                />
              </DataProfileMiniRow>
              <Typography
                text={
                  Array.isArray(data.academicUnit)
                    ? data.academicUnit.join(" - ")
                    : data.academicUnit
                } // Check if Faculty is an array
                fontSize="0.938rem"
                color="#5DD3B3"
              />
            </DataProfileRenderCols>
          )}
          {data.department && data.department.length > 0 && (
            <DataProfileRenderCols>
              <DataProfileMiniRow>
                <MediumTypography
                  text={`${t("User Profile.Department")} :`}
                  fontSize="0.938rem"
                />
              </DataProfileMiniRow>
              <Typography
                text={
                  Array.isArray(data.department)
                    ? data.department.join(" - ")
                    : data.department
                } // Check if Faculty is an array
                fontSize="0.938rem"
                color="#5DD3B3"
              />
            </DataProfileRenderCols>
          )}
        </DataProfileMiniCols>
      </HeightSizeA>
      {data.bio && (
        <HeightSize style={{ gap: "0.62rem" }}>
          <TypographyRegularDescription
            text={`${t("User Profile.Biography")} :`}
            fontSize="1rem"
          />

          <LightTypography
            // text={PuplicProfileDtaInfo.Biography}
            text={data.bio}
            fontSize="0.875rem"
            lineHeight="22px"
          />
        </HeightSize>
      )}
    </DataProfileMainCols>
  );
};
