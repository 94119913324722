import { FC } from "react";

import { GroupCardCompo } from "../../components/GroupsCard/GroupCardCompo";
import GroupSchemaView from "../../components/groupSchemeView/GroupSchemaView";

const GroupCardView: FC = () => {
  return (
    <>
      {/* <GroupCardCompo /> */}
      <GroupSchemaView />
    </>
  );
};
export default GroupCardView;
