import styled from "styled-components";

export const PdfViewrContainer = styled.div`
  margin-top: 3.12rem;
  height: fit-content !important;
  display: flex;
  flex-wrap: wrap;
  /* align-items:start; */
  /* background:green; */
  /* flex: 2 1; */
`;

export const Pdfcontainer = styled.div`
  min-width: 20rem;
  /* height: 100%; */
  /* margin-left: -0.5rem; */

  flex: 2.5;
  position: relative;
  #header-bar {
    height: 2.25rem;
    color: white;
    background-color: #384450;
    display: flex;
    justify-content: flex-end !important;
    padding-left: 5rem;
  }
  #pdf-controls {
    background-color: #384450;
    height: 2.75rem;
    /* display: flex;
    justify-content: space-between; */
  }
  #pdf-download {
    display: none;
  }
  #pdf-zoom-reset {
    /* display: none; */
  }
  #pdf-toggle-pagination {
    display: none;
  }
  #pdf-page-info {
    color: white;
  }
`;
export const SideToolsContainer = styled.div`
  display: flex;
  height: 100% !important;
  flex: 1;
  /* min-height: 32.8rem; */
  /* min-width: 25.9375rem; */
  box-shadow: -4px 0px 20px 0px rgba(0, 0, 0, 0.25);
  /* @media (max-width: 768px) {
    height: 100%;
  } */
`;

export const BottomMarging = styled.div`
  position: absolute;
  left: 0.5rem;
  display: flex;
  align-items: center;
  top: 1rem;
  gap: 0.5rem;
  z-index: 5;
  cursor: pointer;
  color: #5dd3b3;
  /* @media (max-width: 768px) {
    height: 100%;
  } */
`;
