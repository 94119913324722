import styled from "styled-components";
import { breakpoints } from "../../../theme";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const TimelineContentWrapper = styled.div`
  display: flex;
  gap: 1.75rem;
  flex-wrap: wrap;
  padding-bottom: 2rem;
`;
export const TimelineClosed = styled.div<StyleThemeInterface>`
  position: fixed;
  width: 1.875rem;
  height: 80%;
  max-height: 39.0625rem;
  flex-shrink: 0;

  background: ${(props) => props.bgcolor || "#384450"};
  /* left: -0.8rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  &:dir(ltr) {
    left: -0.2rem;
    border-radius: 0 0.625rem 0.625rem 0;
  }
  &:dir(rtl) {
    right: -0.2rem;
    border-radius: 0.625rem 0 0 0.625rem;
  }
  cursor: pointer;
  @media (max-width: calc(${breakpoints.mobile} + 25px)) {
    display: none;
  }
`;
export const TimeLineExist = styled.div<{ isopen: string }>`
  flex: ${(props: { isopen: string }) => (props.isopen === "true"  ? 3 : 0)};

  opacity: ${(props: { isopen: string }) => (props.isopen === "true"  ? 1 : 0)};
  &:dir(ltr) {
    transform: translate(
      ${(props: { isopen: string }) => (props.isopen === "true"  ? "0" : "-100%")}
    );
  }
  &:dir(rtl) {
    transform: translate(
      ${(props: { isopen: string }) => (props.isopen === "true"  ? "0" : "100%")}
    );
  }

  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  width: 100%;

  @media (max-width: calc(${breakpoints.medium} + 25px)) {
    flex: ${(props: { isopen: string }) => (props.isopen === "true"  ? 6 : 0)};
  }
`;

export const TimelineContainer = styled.div`
  width: 100%;

  /* min-width: 20rem; */
`;

export const ContentContainer = styled.div`
  flex: 9 !important;
  min-width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  flex-shrink: 0;
  @media (max-width: calc(${breakpoints.medium} + 25px)) {
    flex: 6;
  }
`;
export const Divider = styled.div`
  width: 100%;
  border: 1px solid #576371;
`;

export const ContentContainerB = styled.div`
  flex: 9;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  > * {
    margin-bottom: 0.5rem;
  }
`;
