import React, { FC, useState, useEffect, useContext } from "react";
import GraderStatistics from "../../components/SurveyGraderCom/GraderStatistics/main/GraderStatistics";
import { PageHeader } from "../../components/pageHeader/PageHeader";
import PdfViewer from "../../components/SurveyGraderCom/GraderTextEditor/main/PdfViewer";
import { Students } from "./SurveyGraderViewInterface";
import { HeaderContainer } from "./SurveyGraderView.Style";
import { get } from "../../utils/AxiosRequests";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Student from "../../components/SurveyGraderCom/GraderStatistics/Student/Student";
import { Typography } from "../../elements/fonts/Fonts";
import {
  MyContextProvider,
  useMyContext,
} from "../../contexts/surveyGraderContext";
import { ClassContext } from "../dashboard/UnstructuredContext";
import { SvgBackArrow } from "../../elements/Icons";
import { LoadingComp } from "../../components/LoadingComp/LoadingComp";
import { useTranslation } from "react-i18next";
import { FlexDiv } from "../../globalStyles";
import { ResourceFrom } from "../courseView/content/Content";
import GarderNavMenu from "./GarderNavMenu";
import * as XLSX from "xlsx";
const SurveyGraderView: FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const activityId = params.id;
  const classId = params.classId;
  const { userSelectedIndex, setUserSelectedIndex } = useContext(ClassContext);
  const [showQuiz, setShowQuiz] = useState<boolean>(true);
  const [showNavIcon, setShowNavIcon] = useState<boolean>(true);
  const [submission, setSubmission] = useState<any[]>([]);
  const [questions, setQuestion] = useState<any[]>([]);
  const [allAssignment, setAllAssignment] = useState<any>({});
  const [enrollements, setEnrollements] = useState<number>(0);
  const [gradedCount, setGradedCount] = useState<number>(0);
  const [studentIndex, setStudentIndex] = useState<number>(
    userSelectedIndex || 0
  );
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const tenantName = localStorage.getItem("tenant");
  const [totalSubmissions, setTotalSubmissions] = useState<number>(
    submission.length
  );
  const [avarage, setAvarage] = useState<string | number>(0);
  const [showSubmitedTime, setShowSubmitedTime] = useState<boolean>(true);
  const [showLate, setShowLate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  // const [submissions, setSubmissions] = useState<number>(student.length);

  const handleQuestionIndexChange = (index: number) => {
    setQuestionIndex(index);
  };

  // Thsi function get the number of students enrolled in the class
  const fetchParticipantData = async () => {
    try {
      const ParticipantData = await get(
        `participant/class/${classId}/${tenantName}`
      );
      // Filter participants based on role
      if (ParticipantData.data.length > 0) {
        const students = ParticipantData.data.filter(
          (participant: any) => participant.role === "Student"
        );
        setEnrollements(students.length);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch the survey Data
  useEffect(() => {
    const getStudentsData = async () => {
      try {
        const studentsData = await get(
          `survey/get/submitter/${activityId}/${tenantName}`
        );
        const questionData = await get(
          `survey/questions/${activityId}/${tenantName}`
        );
        setQuestion(questionData.data.questions);
        setSubmission(studentsData.data.students);
        setTotalSubmissions(studentsData.data.students.length);
        setAllAssignment(studentsData.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    getStudentsData();
    fetchParticipantData();
  }, [tenantName, studentIndex, questionIndex]); // Assuming tenantName might change and triggers fetching students

  const handleQuizView = () => {
    setShowQuiz(!showQuiz);
    setShowLate(!showLate);
    setShowNavIcon(!showNavIcon);
  };

  const navigateBack = () => {
    navigate(-1);
  };
  const [resourceR, setResourceR] = useState<ResourceFrom>();

  let { state } = useLocation();
  useEffect(() => {
    if (state) {
      const selectedResource: ResourceFrom = state.activity;
      setResourceR(selectedResource);
    }
  }, [state]);

  // This function return the text without html tags
  function exportUserInfo(text: any) {
    // Helper function to decode HTML entities
    function decodeHtml(html: string): string {
      const txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    }

    // Decode the HTML entities
    const decodedHtml = decodeHtml(text);

    // Use DOMParser to parse the HTML and extract the text content
    const parser = new DOMParser();
    const doc = parser.parseFromString(decodedHtml, "text/html");
    const purifiedText = doc.body.textContent || "";

    return purifiedText;
  }

  // This function download the answers of the students on the questions
  const handleDownload = () => {
    const convertToStars = (num: any) => "★".repeat(num) + "☆".repeat(5 - num);

    // Create worksheet data
    const worksheetData = [];

    // Add header row
    const headers = ["Student Name", ...questions.map((q) => q.title)];
    worksheetData.push(headers);

    // Add student data rows
    submission.forEach((student) => {
      const row = [student.fullName];
      questions.forEach((question) => {
        const answer = student.answers.find(
          (a: any) => a.SurveyQuestionsId === question.SurveyQuestionsId
        );
        if (answer) {
          const answerText = answer.answer
            .map((a: any) => {
              if (question.type === "RATE") {
                return convertToStars(Number(a.choice));
              } else if (question.type === "ESSAY") {
                return exportUserInfo(a.choice);
              }
              return a.choice;
            })
            .join(", ");
          row.push(answerText);
        } else {
          row.push("");
        }
      });
      worksheetData.push(row);
    });

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Survey Results");

    // Write the workbook to a file and download
    XLSX.writeFile(workbook, "survey_results.xlsx");
  };
  const language = localStorage.getItem("language");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // background: "black",
        height: "fit-content",
        paddingBottom: ".5rem",
      }}
    >
      {loading && <LoadingComp />}
      <MyContextProvider>
        <HeaderContainer>
          <FlexDiv gap="1.25rem">
            <Link
              to={`/courseView/content/${classId}`}
              style={{ display: "flex", gap: "0.62rem", alignItems: "center" }}
            >
              <div
                // onClick={navigateBack}
                style={{
                  marginTop: ".2rem",
                  cursor: "pointer",
                  transform: `${language === "ar" ? "rotate(180deg)" : ""}`,
                }}
              >
                <SvgBackArrow />
              </div>
              <Typography
                text={t("survey.Survey")}
                fontSize="1.75rem"
                fontWeight="400"
                lineHeight="normal"
              />
            </Link>
          </FlexDiv>
          {/* This is the above statistics data */}
          <GraderStatistics
            // setSubmitted={setSubmitted}
            // setGraded={setGraded}
            //setAvarage={setAvarage}
            totalSubmissions={totalSubmissions}
            enrollements={enrollements}
            // submitted={submitted}
            avarage={avarage}
            student={submission}
            // students={students}
            studentIndex={studentIndex}
            setStudentIndex={setStudentIndex}
            graded={gradedCount}
            allAssignment={allAssignment}
          />
          {/* Unused Component */}
        </HeaderContainer>
        <GarderNavMenu handleDownload={handleDownload} />
        {/* This component conatine the middle component with the side tool to the right */}
        <PdfViewer
          student={submission}
          studentIndex={studentIndex}
          questions={questions}
          showQuiz={showQuiz}
          showSubmitedTime={showSubmitedTime}
          setShowSubmitedTime={setShowSubmitedTime}
          showLate={showLate}
          showNavIcon={showNavIcon}
          allAssignment={allAssignment}
          quiz={true}
          handleQuestionIndexChange={handleQuestionIndexChange}
          resourceR={resourceR}
        // updateSubmittedByInstructor={updateSubmittedByInstructor}
        />
      </MyContextProvider>
    </div>
  );
};
export default SurveyGraderView;
