import { toArabicDigits } from "../../../utils/formatDayArabic";
import { isDateInRange } from "../../../utils/FormatedDATE";

export const getResourcesLenght = (data: any, role: string) => {
    const total = data.filter(
        (content: any) =>
            content.category === "Resource" &&
            ((role === "student" || role === "auditor") ? isDateInRange(
                content.visibleFrom,
                content.visibleTo,
                content.isVisible,
            ) : true)
    ).length;
    return toArabicDigits(total.toString());
};
export const getActivitiesLenght = (data: any, role: string) => {
    const total = data.filter(
        (content: any) =>
            content.category === "Activity" &&
            ((role === "student" || role === "auditor") ? isDateInRange(
                content.visibleFrom,
                content.visibleTo,
                content.isVisible,
            ) : true)
    ).length;
    return toArabicDigits(total.toString());
};

export const getContentDescription = (contents: any, role: any, t: any) => {
    const resourcesCount = contents ? getResourcesLenght(contents, role) : 0;
    const activitiesCount = contents ? getActivitiesLenght(contents, role) : 0;

    const resourceLabel = contents &&
        contents.filter(
            (content: any) => content.category === "Resource" &&
                ((role === "student" || role === "auditor")
                    ? isDateInRange(content.visibleFrom, content.visibleTo, content.isVisible)
                    : true)
        ).length === 1
        ? t("dashboard.Resource")
        : t("dashboard.Resources");

    const activityLabel = contents &&
        contents.filter(
            (content: any) => content.category === "Activity" &&
                ((role === "student" || role === "auditor")
                    ? isDateInRange(content.visibleFrom, content.visibleTo, content.isVisible)
                    : true)
        ).length === 1
        ? t("dashboard.Activity")
        : t("dashboard.Activities");

    return `${resourcesCount} ${resourceLabel} / ${activitiesCount} ${activityLabel}`;
};