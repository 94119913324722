import React, { useState, useEffect, useRef, useContext } from "react";
import {
  HeaderChildStyle,
  HeaderContent,
  HeaderHolder,
  NewAnnouncementsHolder,
} from "./NewAnnouncements.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../../../elements/fonts/Fonts";
import ReusableInput from "../../../../elements/reusableInput/ReusableInput";
import { TextEditor } from "../../../../elements/TextEditor/TextEditor";
import ReusableSubmitButton from "../../../../elements/reusableSubmitButton/ReusableSubmitButton";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { get, post } from "../../../../utils/AxiosRequests";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../../../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import { ButtonsContainer } from "../editAnnouncements/EditAnnouncements.style";
import TinyEditor from "../../../../elements/tinyEditor/TinyEditor";
import { useTranslation } from "react-i18next";
import { WebSocketConnector } from "../../../messages/webSocketConnection";
import { ClassContext } from "../../../dashboard/UnstructuredContext";
import { WebsocketContext } from "../../../../contexts/notificationContext";
import { CheckBox } from "../../../../components/manageWeight/ManageWeight.style";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../../recoil/ThemeSelectors";
import CheckboxSquare from "../../../../elements/checkboxSquare/CheckboxSquare";
import { FlexedDiv } from "../../../../components/attendanceAllSessions/AttendanceAllSessions.style";
import SelectInput from "../../../../elements/SelectInput/SelectInput";
import Select from "react-select";
import { colorStyles } from "./colorStyle";
import axios from "axios";
import DropDownSelect from "../../../../elements/dropDownSelect/SelectInput";
import SelectDropDown from "../../../../elements/selectDropDown/SelectDropDown";
import { formatOptionsForTranslation } from "../../../../utils/FormatOptionsForTranslation";
import { useParentClass } from "../../../../utils/getParentClass";
interface announcementProps {
  id?: string;
}
const NewAnnouncements = ({ id }: announcementProps): JSX.Element => {
  let { state } = useLocation();
  const [work, setWork] = useState<string>("");
  const [workError, setWorkError] = useState<string>("");
  const [inputData, setInputData] = useState<string>("");
  const [inputDataError, setInputDataError] = useState<string>("");
  const params = useParams();
  const classId = id ? id : params.classId;
  const tenantName = localStorage.getItem("tenant");
  const { user } = useAuth0();
  const navigate = useNavigate();
  const myEmail = user?.email || "";
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const [CheckBoxChecked, SetCheckBoxChecked] = useState<boolean>(false);
  const { isReady, value, send, connect } = useContext(WebsocketContext);
  const LeftOptions = [
    // { id: 'tenant', label: 'Tenant',name:"Tenant",value:'tenant' },
    { id: "users", label: "Users", name: "dashboard.Users", value: "users" },
    // { id: 'role', label: 'Roles',name:"Roles",value:'role' },
    { id: "class", label: "Class", name: "dashboard.Class", value: "class" },
  ];
  const [RightOptions, setRightOptions] = useState([]);
  const [rightSelectedOption, setRightSelectedOption] = useState<any[]>([]);

  // This 2 arrays are used to be sended in the data (array of string not array of object)
  const [selectedMode, setSelectedMode] = useState<any>("");
  const [rightSelectedStrings, setRightSelectedStrings] = useState<any[]>([]);
  const [AllUsers, SetAllUsers] = useState([]);
  const [modeError, setModeError] = useState("");

  useEffect(() => {
    if (
      !isReady &&
      tenantName !== null &&
      tenantName !== undefined &&
      subIdSplitted !== null &&
      subIdSplitted !== undefined
    ) {
      connect();
    }
  }, [tenantName, subIdSplitted]);

  // Set the content of the announcement
  const handleContentChange = (newWork: string) => {
    setWork(newWork);
    setWorkError("");
  };

  // Set the Title of the announcement
  const handleTitleChange = (data: string) => {
    setInputData(data);
    setInputDataError("");
  };

  // get if the class is parent
  const { isParent, children } = useParentClass(classId);

  // Handle Create Announcement
  const CreateAnnouncement = async () => {
    const dataObject = {
      title: inputData,
      content: work,
      subId: subIdSplitted,
      provider: provider,
      classSourceId: classId,
      targetType: selectedMode,
      targetSourceId: rightSelectedStrings,
      isEmailed: CheckBoxChecked,
      isAdmin: false,
    };

    if (!inputData) {
      setInputDataError("error");
      return;
    }

    if (!selectedMode) {
      setModeError("error");
      console.error("Please select");
      return;
    }

    if (!work) {
      setWorkError("error");
      return;
    }

    if (rightSelectedStrings.length === 0 && selectedMode !== "class") {
      console.error("Please select");
      return;
    }

    try {
      setLoading(true);
      const create = await axios.post(
        `https://yhntwosoqe.execute-api.eu-west-1.amazonaws.com/dev/announcement/create/${tenantName}`,
        {
          data: dataObject,
        }
      );
      console.log("announce to post", dataObject);
      if (isReady && send) {
        send(
          JSON.stringify({
            action: "sendPublic",
            data: {
              tenantName: tenantName,
              classSourceId: classId,
              status: "user",
              refId: create.data,
              subId: subIdSplitted,
              provider: provider,
              type: "announcement",
              description: inputData,
            },
          })
        );
      }

      setWork("");
      setInputData("");
      setSelectedMode("");
      setRightSelectedStrings([]);
      setLoading(false);
      navigate(`/courseview/announcements/${state.some}`);
    } catch (error) {
      console.log(error);
    }
  };

  // Handle go backward navigation
  const handleBack = () => {
    navigate(-1);
  };
  const handleCheckboxChange = () => {
    SetCheckBoxChecked(!CheckBoxChecked);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const [users, classes, roles] = await Promise.all([
        //   getAllUsers(),
        //   getAllClasses(),
        //   getAllRoles(),
        // ]);
        const ParticipantData = await get(
          `participant/class/${classId}/${tenantName}`
        );
        const participantWithoutMe = ParticipantData.data.filter(
          (user: any) => user.email.toUpperCase() !== myEmail.toUpperCase()
        );
        const transformedUsers = participantWithoutMe.map(
          (user: { sourceId: any; username: any; email: string }) => ({
            value: user.sourceId,
            label: `${user.username} - ${user.sourceId}`,
          })
        );
        SetAllUsers(transformedUsers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  function transformData(data: any) {
    return data.map((item: any) => ({
      label: item.className,
      value: item.sourceId,
    }));
  }

  const allClasses = transformData(children);

  useEffect(() => {
    const rightOptions: any = [];
    setRightSelectedOption([]);
    setRightSelectedStrings([]);
    if (selectedMode === "users") {
      rightOptions.push(...AllUsers);
    } else if (selectedMode === "class" && isParent === false) {
      setRightSelectedStrings([classId]);
    }

    setRightOptions(rightOptions);
  }, [selectedMode, AllUsers]);

  const {
    checkboxBgColor,
    checkboxBorderColor,
    checkboxBorderRadius,
    SecondaryTextColor,
    inputBorderColor,
    theme,
  } = useRecoilValue(ColorSelector);

  const translatedOptions = formatOptionsForTranslation(LeftOptions, t);
  return (
    <NewAnnouncementsHolder>
      {/* Create Announcement Form Goes Here */}
      <Typography
        text={t("course view.Announce something to your class")}
        fontSize="1.75rem"
      />

      <HeaderContent>
        <HeaderChildStyle>
          <LightTypography text={t("notes.Title")} fontSize="0.875rem" />
          <ReusableInput
            inputBorder={inputDataError ? `1px solid #D04B4B` : ""}
            onInputChange={handleTitleChange}
            inputBorderRadius="0.625rem"
            inputHeight=" 2.8125rem"
          />
        </HeaderChildStyle>
        <HeaderChildStyle>
          <LightTypography text={t("group.Group")} fontSize="0.875rem" />
          <SelectDropDown
            width="100%"
            value={selectedMode}
            options={translatedOptions}
            onChange={(option: string | number) => setSelectedMode(option)}
            placeholder={t("group.Select Group")}
          />
        </HeaderChildStyle>

        {selectedMode && selectedMode !== "class" ? (
          <HeaderChildStyle>
            <LightTypography
              text={t("course view.Recipients")}
              fontSize="0.875rem"
            />
            <Select
              options={RightOptions}
              onChange={(selectedOptions: any) => {
                const selectedRoles = selectedOptions.map(
                  (option: { value: any; label: any }) => ({
                    value: option.value,
                    label: option.label,
                  })
                );
                setRightSelectedOption(selectedRoles);
                const selectedRoleString = selectedOptions.map(
                  (option: any) => option.value
                );
                setRightSelectedStrings(selectedRoleString);
              }}
              value={rightSelectedOption}
              isMulti
              styles={colorStyles(
                SecondaryTextColor,
                theme === "light" ? "#fff" : "#1F2731",
                inputBorderColor,
                theme,
                "2.8125rem"
              )}
              placeholder={t("course view.Search Recipients")}
              className="custom-select"
            />
          </HeaderChildStyle>
        ) : isParent && selectedMode === "class" ? (
          <HeaderChildStyle>
            <LightTypography
              text={t("course view.Recipients")}
              fontSize="0.875rem"
            />
            <Select
              options={allClasses}
              onChange={(selectedOptions: any) => {
                const selectedClasses = selectedOptions.map(
                  (option: { value: any; label: any }) => ({
                    value: option.value,
                    label: option.label,
                  })
                );
                setRightSelectedOption(selectedClasses);
                const selectedRoleString = selectedOptions.map(
                  (option: any) => option.value
                );
                setRightSelectedStrings(selectedRoleString);
              }}
              value={rightSelectedOption}
              isMulti
              styles={colorStyles(
                SecondaryTextColor,
                theme === "light" ? "#fff" : "#1F2731",
                inputBorderColor,
                theme,
                "2.8125rem"
              )}
              placeholder={t("course view.Search Recipients")}
              className="custom-select"
            />
          </HeaderChildStyle>
        ) : (
          ""
        )}
      </HeaderContent>

      <TinyEditor onChange={handleContentChange} initialValue={work} />
      {workError ? (
        <LightTypography color="#D04B4B" text="Content is required" />
      ) : (
        ""
      )}

      <FlexedDiv style={{ gap: "0.3rem", marginTop: "1rem" }}>
        <CheckboxSquare
          bgcolor={checkboxBgColor}
          bordercolor={checkboxBorderColor}
          borderradius={checkboxBorderRadius}
          checked={CheckBoxChecked}
          name="isChecked"
          onChange={handleCheckboxChange}
        />
        <MediumTypography text={t("course view.Send By Email")} />
      </FlexedDiv>
      <ButtonsContainer>
        {!id && (
          <ReusableSubmitButton
            text={t("forms.Cancel")}
            width="8.8125rem"
            padding="0.56rem 2.12rem 0.52rem 2.25rem"
            onClick={handleBack}
            bgColor="transparent"
            border="1px solid #D85D66"
            color="#D85D66"
          />
        )}
        {loading ? (
          <ReusableSubmitButton
            text={`${t("general.loading")}...`}
            width="8.8125rem"
            padding="0.56rem 2.12rem 0.5rem 2.25rem"
          />
        ) : (
          <ReusableSubmitButton
            text={t("course view.Announce")}
            width="8.8125rem"
            padding="0.56rem 2.12rem 0.5rem 2.25rem"
            onClick={CreateAnnouncement}
          />
        )}
      </ButtonsContainer>
    </NewAnnouncementsHolder>
  );
};

export default NewAnnouncements;
