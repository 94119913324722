
import {ReactComponent as AddIcon} from '../../../assets/icons/Add (1).svg'
import {ReactComponent as ComposeIcon} from '../../../assets/icons/Compose.svg'
import {ReactComponent as RefreshIcon} from '../../../assets/icons/Refresh.svg'
import {ReactComponent as LoadIcon} from '../../../assets/icons/flat_1 (1).svg'
import {ReactComponent as FAQIcon} from '../../../assets/icons/FAQ.svg'
import {ReactComponent as RefreshTwoIcon} from '../../../assets/icons/refresh_3 (1).svg'
import {ReactComponent as ShareIcon} from '../../../assets/icons/Share.svg'
interface MenuItem {
    id: number;
    name: string;
    icon: React.ReactElement;
}

export const items: MenuItem[] = [
    // {
    //     id: 1,
    //     name: 'LOAD TEMPLATE',
    //     icon: <RefreshIcon />,
    // },
    // {
    //     id: 2,
    //     name: 'SAVE TEMPLATE',
    //     icon: <LoadIcon />,
    // },
    {
        id: 3,
        name: 'QUESTION BANK',
        icon: <FAQIcon/>,
    },
    {
        id: 4,
        name: 'ADD QUESTION',
        icon:<AddIcon/>,
    },
    {
        id: 5,
        name: 'ADD QUESTION',
        icon:<ComposeIcon/>,
    },
    // {
    //     id: 6,
    //     name: 'PREVIEW',
    //     icon: <RefreshTwoIcon />,
    // },
    // {
    //     id: 7,
    //     name: 'SHARE',
    //     icon: <ShareIcon/>,
    // },
];


