import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const FilteredButtonShapeContainer = styled.div<{minWidth?:string,hoveredColor?:string, active?:boolean}>`
  display: flex;
  height: 2.125rem;
  flex-shrink: 0;
  min-width: ${props => props.minWidth || "8.9375rem"};
  color: ${props => props.hoveredColor || "#5DD3B3"};
  border: 1.2px solid ${props => props.hoveredColor || "#5DD3B3"};
  background: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
  justify-content: center;
  align-items: center;
  text-align:center;
  text-transform: capitalize;
  cursor:pointer;
  border-radius: 0.3125rem;
  &:hover {
    border: 1.2px solid ${props => props.hoveredColor || "#5DD3B3"};
    background: ${props => props.hoveredColor || "#5DD3B3"};
    color: #fff;
    animation: ${fadeIn} 0.3s ease; /* Apply fade-in animation */
  }
  ${props => props.active && `
    background: ${props.hoveredColor || "#5DD3B3"};
    border: 1.2px solid ${props.hoveredColor || "#5DD3B3"};
    color:#fff;
  `}
`;