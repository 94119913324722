import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const StudentSelectContainerParent = styled.div`
display: flex;
flex-direction: column;
width: 100%;
gap: 1rem;
`;

export const StudentSelectContainer = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;
min-height: 4rem;

`;

export const StudentSelectColumn = styled.div`
display: flex;
flex-direction: column;
gap: 0.63rem;
width: 48%;
min-width: 14rem;
`;

export const StudentInput = styled.input<StyleThemeInterface>`
border-radius: 0.625rem;
background-color: transparent;
border: 1px solid ${props =>props.bordercolor};
width: 100%;
height: 2.8125rem;
padding: 0.88rem 1.25rem;
color:${props =>props.color};
font-size: 0.875rem;
font-family: "Primary Font-Light";
`;
export const CommentContainer = styled.div`
width: 100%;
gap: 1rem;
padding:1.5rem ;
border-radius: 10px;
background: rgba(255, 255, 255, 0.03);
`;