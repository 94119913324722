import React from 'react'
import BlueButton from '../../../elements/blueButton/BlueButton';
import { useTranslation } from 'react-i18next';

interface SurveyStudentBottom {
    ShowSave: boolean;
    handleClose:() => void;
    loading:boolean;
    valid:boolean;
    remainingDataCount:number;
    handelSave:() => void;
}

const SurveyStudentBottom = ({
    ShowSave,
    valid,
    loading,
    handelSave,
    handleClose,
    remainingDataCount
} : SurveyStudentBottom) => {
  const { t } = useTranslation();
  return (
    <>
    {!ShowSave ? (
              <BlueButton
                Width="8.8125rem"
                Height="2.1875rem"
                text={t("assignment.close")}
                Border="1px solid var(--Main-Green-color, #5DD3B3)"
                BackColor="transparent"
                Color="#5DD3B3"
                OnClick={handleClose}
              />
            ) :
            loading ?
            <BlueButton
                Width="8.8125rem"
                Height="2.1875rem"
                text={`${t('general.loading')}...`}
                Border="1px solid var(--Main-Green-color, #5DD3B3)"
                BackColor="transparent"
                Color="#5DD3B3"
              />
             :
             valid ?
             <BlueButton
              text={remainingDataCount > 0 ? t("quiz.Next") : t("forum.Submit")}
                OnClick={handelSave}
                Width="8.8125rem"
                Height="2.1875rem"
                BackColor="#5DD3B3"
              />
              :
              ""
             }
    </>
  )
}

export default SurveyStudentBottom