export const convertTo12Hour = (hours: number) => {
  let period = "AM";
  if (hours >= 12) {
    period = "PM";
    if (hours > 12) hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }
  return { hours, period };
};

function tConvert(time: any) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

// tConvert ('18:00:00');

export const TranslateTime = (time: string, t: any) => {
  if (time.includes("AM")) {
    return time.replace("AM", t("notification.AM"));
  } else if (time.includes("PM")) {
    return time.replace("PM", t("notification.PM"));
  } else {
    const [hours, minutes] = time.split(":");
    let hour = parseInt(hours);
    let period = t("notification.AM");
    if (hour > 12) {
      period = t("notification.PM");
      hour -= 12;
    } else if (hour === 12) {
      period = t("notification.PM");
    } else if (hour === 0) {
      hour = 12;
      period = t("notification.AM");
    }
    return `${hour}:${minutes} ${period}`;
  }
};
