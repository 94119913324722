import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CourseActivityStudentData } from "../CourseActivityStudentIData";
import { CourseActivityStudentInterface } from "../CourseActivityStudentIDInterface";
import {
  CASIDContainer,
  CASIDContainerHeader,
  CASIDProfilePic,
  CASIDContainerHeaderProfileInfo,
  CASIDContainerHeaderOptions,
  CASIDTable,
  CASIDTableHeader,
  CASIDTableHeaderCell,
  CASIDTableBody,
  CASIDTableBodyCell,
  CASIDTableViewLink,
} from "./CourseActivityStudentID.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../../elements/fonts/Fonts";
import { LogoAndAction } from "../../gradesItem/GradesItem.style";
import { SvgDownload, SvgMore, SvgViewEye } from "../../../elements/Icons";
import { CourseActivityStudentHeaderData } from "../CourseActivityStudentHeaderData";
import { IdHaveArrayOfData } from "../../../utils/ExcelGlobalFunction";
import { PropsIdParams } from "../../../interfaces/AnalyticsCommonInterfaces";
import { PointerContainer } from "../../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableTable.style";
import MappedIndexClickOutSide from "../../../hooks/MapedIndexClickOutSide";
import { formatDate } from "../../../utils/FormatedDATE";
import { useStateContext } from "../../../contexts/ContextProvider";

const CourseActivityStudentID = ({ id }: PropsIdParams) => {
  const [SelectedIndex, setSelectedIndex] = useState<number | null>(null);
  const currentDate = new Date();

  const handelDisplayViewPop = (
    infoIndex: number,
    activityName: string,
    CloseDate: Date
  ) => {
    if (activityName !== "Quiz") {
      if (SelectedIndex === infoIndex) {
        setSelectedIndex(null);
      } else {
        setSelectedIndex(infoIndex);
      }
    } else {
      if (currentDate > CloseDate) {
        if (SelectedIndex === infoIndex) {
          setSelectedIndex(null);
        } else {
          setSelectedIndex(infoIndex);
        }
      }
    }
  };
  const clickOutsideRef = MappedIndexClickOutSide(() => setSelectedIndex(null));
  const ActivityCode = CourseActivityStudentData[0].ActivityCode;
  // because ClassCode value is not read data i will the value from useContext
  const ClassCode = CourseActivityStudentData[0].ClassCode;
  const { GlobalClassId } = useStateContext();

  const navigate = useNavigate();
  const handleNavigate = (activityType: string) => {
    if (
      activityType === "Quiz" ||
      activityType === "Survey" ||
      activityType === "Forum"
    ) {
      navigate(`/grader${activityType}/${ActivityCode}/${GlobalClassId}`);
    } else {
      navigate(`/grader/${ActivityCode}/${GlobalClassId}`);
    }
  };

  return (
    <>
      {CourseActivityStudentData &&
        CourseActivityStudentData.map((val, index) => (
          <CASIDContainer>
            <CASIDContainerHeader>
              <CASIDContainerHeaderProfileInfo>
                <CASIDProfilePic src={val.profilePic || ""} />
                <Typography text={val.firstName || ""} />
                <Typography text={val.LastName || ""} />
              </CASIDContainerHeaderProfileInfo>
              <CASIDContainerHeaderOptions>
                <LogoAndAction
                  onClick={() =>
                    IdHaveArrayOfData(
                      CourseActivityStudentData,
                      "StudentCompletionTracking"
                    )
                  }
                >
                  <SvgDownload />
                  <Typography text="Download" fontSize="0.75rem" />
                </LogoAndAction>
                <CASIDContainerHeaderProfileInfo>
                  <Typography text="Overall Grade" fontSize="1rem" />
                  <Typography
                    text={val.OverallGrade || ""}
                    fontSize="0.875rem"
                    color="#5DD3B3"
                  />
                </CASIDContainerHeaderProfileInfo>
              </CASIDContainerHeaderOptions>
            </CASIDContainerHeader>

            <CASIDTable>
              <CASIDTableHeader>
                {CourseActivityStudentHeaderData.map((item) => (
                  <CASIDTableHeaderCell>
                    <MediumTypography
                      text={item.text || ""}
                      lineHeight="1.75rem"
                      fontSize="1rem"
                    />
                  </CASIDTableHeaderCell>
                ))}
              </CASIDTableHeader>

              {val.Data &&
                val.Data.map((info, infoIndex) => (
                  <CASIDTableBody>
                    <CASIDTableBodyCell>
                      <Typography
                        text={info.ActivityResourceName || "-"}
                        fontSize=" 0.875rem"
                      />
                    </CASIDTableBodyCell>
                    <CASIDTableBodyCell>
                      <LightTypography
                        text={formatDate(info.StartDate) || "-"}
                        fontSize="0.75rem"
                      />
                    </CASIDTableBodyCell>
                    <CASIDTableBodyCell>
                      <LightTypography
                        text={formatDate(info.DueDate) || "-"}
                        fontSize="0.75rem"
                      />
                    </CASIDTableBodyCell>
                    <CASIDTableBodyCell>
                      <LightTypography
                        text={formatDate(info.CloseDate) || "-"}
                        fontSize="0.75rem"
                      />
                    </CASIDTableBodyCell>
                    <CASIDTableBodyCell>
                      <LightTypography
                        text={info.Status || "-"}
                        fontSize="0.75rem"
                      />
                    </CASIDTableBodyCell>
                    <CASIDTableBodyCell>
                      <LightTypography
                        text={`${info.Grade || "-"}/100`}
                        color={info.Grade ? "#5DD3B3" : "#576474"}
                        fontSize="0.75rem"
                      />
                    </CASIDTableBodyCell>
                    <CASIDTableBodyCell>
                      <PointerContainer
                        onClick={() =>
                          handelDisplayViewPop(
                            infoIndex,
                            info.ActivityResourceName || "",
                            info.CloseDate || new Date()
                          )
                        }
                      >
                        <SvgMore
                          style={{
                            transform: `rotate(90deg)`,
                          }}
                        />
                        {SelectedIndex === infoIndex && (
                          <CASIDTableViewLink
                            ref={clickOutsideRef}
                            onClick={() =>
                              handleNavigate(info.ActivityResourceType || "")
                            }
                          >
                            <SvgViewEye />
                            <MediumTypography text="View" fontSize="0.75rem" />
                          </CASIDTableViewLink>
                        )}
                      </PointerContainer>
                    </CASIDTableBodyCell>
                  </CASIDTableBody>
                ))}
            </CASIDTable>
          </CASIDContainer>
        ))}
    </>
  );
};

export default CourseActivityStudentID;
function setOpenedIndex(arg0: null) {
  throw new Error("Function not implemented.");
}
