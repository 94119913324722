import styled from "styled-components";

export const ScormContainer=styled.div`


`

export const ScormIframe=styled.iframe`
border: 1px solid transparent;
border-bottom-right-radius:4px;
border-bottom-left-radius:4px;
box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
background-color:#384450;
`

export const ScormFile=styled.div`
width:80%;
background-color:#384450;
display:flex;
align-items:center;
padding:0.7rem;
height:10px;
border-top-right-radius:4px;
border-top-left-radius:4px;
box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);

`

export const Comments = styled.div`
  display: flex;
  justify-content: space-between;
`;


export const CommentTextAreaContainer = styled.div`
  width: 100%;
  height: 2.75rem;
  /* border: 1px solid #576371; */
  /* border-radius: 0.625rem; */
  display: flex;


`;

export const CommentTextArea = styled.input`
margin-left:0.3rem;
  background-color: transparent;
  border: 1px solid #576371;
  border-radius: 0.625rem  0  0 0.625rem;
  font-size: 0.875rem;
  width: 75%;
  height:70%;
  color:white;
  font-size:0.825rem;
  min-width: 70%;
  padding: 5px;
  font-family: "Primary Font-Light";
  outline: #576371;
 

  &::placeholder {
    color: grey;
    font-size: 0.875rem;
  }
`;


export const PostBtn=styled.button`
  width: 5%;
  height:70%;
  background-color: rgba(45, 55, 65, 1);
  border: 1px solid #576371;

  border-radius: 0 0.625rem   0.625rem 0;
`

export const FlexedDiv=styled.div`
display:flex;
justify-content: center;
align-items: center;

`


export const ProfileImg=styled.img`
border-radius:50px;
height:50px;
width:50px;
border:1px solid white;
margin-right:0.8rem;
`

export const CommentSection=styled.div`
margin:0;
`


export const ProfileHolder = styled.div`
  cursor: pointer;
  border-radius: 50%;
  background: linear-gradient(180deg, #2d3741 0%, #576371 100%);
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  /* margin-left: 0.8rem; */
`;

export const MaskGroup = styled.img`
  border-radius: 2.125rem;
  border: 1px solid #707070;
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 50%;
  object-fit: cover;
`;


export const Absolutediv=styled.div`
position:absolute;

`
export const CommentContainers=styled.div`
display:flex;
align-items:center;
gap:1rem;
margin-bottom:0.7rem;
`

export const ActionDiv=styled.div`
display:flex;
gap:0.25rem;


`

export const Actions=styled.div`
cursor:pointer;
`