import { InstructorAnalyticsUsersViewActionsInterface, InstructorAnalyticsUsersViewTableInterface } from "./InstructorAnalyticsUsersViewTableInterface";

export const InstructorAnalyticsUsersViewTableData: InstructorAnalyticsUsersViewTableInterface[] = [
  {
    id: "2345",
    FirstName: "Nour",
    LastName: "Mohammad",
    Img: require("../../../assets/NourInstructor.jpg"),
    Role: "Student",
    Email: "N.mohammad@Zidyia",
    LastAccess: "1 Aug, 2023 | 8:00 AM",
  },
  {
    id: "5678",
    FirstName: "John",
    LastName: "Doe",
    Img: require("../../../assets/AliAhmad.png"),
    Role: "Instructor",
    Email: "john.doe@example.com",
    LastAccess: "2 Aug, 2023 | 9:30 AM",
  },
  {
    id: "7890",
    FirstName: "Alice",
    LastName: "Smith",
    Img: require("../../../assets/AyaInstructor.jpg"),
    Role: "Student",
    Email: "alice.smith@example.com",
    LastAccess: "3 Aug, 2023 | 10:45 AM",
  },
  {
    id: "1234",
    FirstName: "Bob",
    LastName: "Johnson",
    Img: require("../../../assets/KhaledInstructor.jpg"),
    Role: "Instructor",
    Email: "bob.johnson@example.com",
    LastAccess: "4 Aug, 2023 | 11:15 AM",
  },
  {
    id: "5671",
    FirstName: "Eva",
    LastName: "Green",
    Img: require("../../../assets/NourInstructor.jpg"),
    Role: "Student",
    Email: "eva.green@example.com",
    LastAccess: "5 Aug, 2023 | 1:20 PM",
  },
  {
    id: "8901",
    FirstName: "Michael",
    LastName: "Clark",
    Img: require("../../../assets/KhaledInstructor.jpg"),
    Role: "Instructor",
    Email: "michael.clark@example.com",
    LastAccess: "6 Aug, 2023 | 3:45 PM",
  },
  {
    id: "2341",
    FirstName: "Sara",
    LastName: "Miller",
    Img: require("../../../assets/AyaInstructor.jpg"),
    Role: "Student",
    Email: "sara.miller@example.com",
    LastAccess: "7 Aug, 2023 | 5:10 PM",
  },
  {
    id: "3456",
    FirstName: "David",
    LastName: "White",
    Img: require("../../../assets/AliAhmad.png"),
    Role: "Instructor",
    Email: "david.white@example.com",
    LastAccess: "8 Aug, 2023 | 7:30 PM",
  },
  {
    id: "4567",
    FirstName: "Grace",
    LastName: "Anderson",
    Img: require("../../../assets/NourInstructor.jpg"),
    Role: "Student",
    Email: "grace.anderson@example.com",
    LastAccess: "9 Aug, 2023 | 9:15 PM",
  },
  {
    id: "6789",
    FirstName: "Daniel",
    LastName: "Taylor",
    Img: require("../../../assets/AliAhmad.png"),
    Role: "Instructor",
    Email: "daniel.taylor@example.com",
    LastAccess: "10 Aug, 2023 | 10:45 PM",
  },
];





export const InstructorAnalyticsUsersViewTableActionData: InstructorAnalyticsUsersViewActionsInterface[] = [
  // {text: "Name"},
  {text: "ID"},
  {text: "Role"},
  {text: "Email Address"},
  {text: "Last Access"},



]