import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
`;
export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.81rem;
`;
export const TitleAndSearch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const AddNew = styled.div<StyleThemeInterface>`
  display: flex;
  align-items: center;
  gap: 0.62rem;
  margin-top: 1.31rem;
  margin-bottom: 1.25rem;
  &:dir(ltr){
    margin-left: auto;
  }
  &:dir(rtl){
    margin-right: auto;
  }
  cursor: pointer;
  color: ${props => props.theme === "light" ? "#5DD3B3" : "#fff"};
`;
export const TitlesCont = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.62rem;
`;
export const Titles = styled.div<StyleThemeInterface>`
  width: 100%;
  /* padding: 1.19rem 1.25rem; */
  border-radius: 0.625rem;
  background: ${props => props.theme === "dark" ? "linear-gradient(180deg, #415160 0%, #293440 100%)" : "#fff"};
`;
export const Title = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding: 1.19rem 1.25rem;
`;
export const RubricTableLayout = styled.div<StyleThemeInterface>`
  display: flex;
  flex: 100%;
  flex-direction: column;
  /* gap:0.5rem; */
  align-content: flex-start;
  border-radius: 1.25rem;

  background: ${(props) => props.bgcolor};
  box-shadow: ${(props) => props.boxshadow};
`;

export const RubricTileRowEditLayout = styled.div`
  display: flex;
  flex: 100%;
  justify-content: space-between;
  gap: 0.5rem;

  padding-right: 1.3rem;
  /* background-color:yellow; */
`;

export const RubricTileLayout = styled.div`
  display: flex;
  flex: 95%;
  flex-direction: column;
  gap: 0.5rem;
  align-content: flex-start;
  padding: 1rem;
`;

export const OneRowHeaderDeleteDiv = styled.div`
  display: flex;
  cursor: pointer;
  width: 2rem;
  display: flex;
  justify-content: center;
`;

export const RubricTableLayoutRow = styled.div<StyleThemeInterface>`
  flex: 100%;
  display: flex;
  gap: 3rem;
  padding: 1.25rem;

  &:nth-child(even) {
    background: ${(props) => props.nth_child_bgcolor};
  }
  &:first-child {
    background: ${(props) => props.first_child_bgcolor};
  }
  &:last-child {
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
  }
`;

export const RubricTableLayoutColumn = styled.div`
  flex: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-right: 0.5rem;
`;

export const OneRowViewShapeDiv = styled.div`
  display: flex;
  height: 2rem;
  padding-left: 1rem;
  align-items: center;
`;

export const OneRowHeaderDiv = styled.div`
  display: flex;
  height: 2rem;
  justify-content: space-between;
  align-items: center;
  width: 90%;
`;

export const RuricCratieriaText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.63rem;
  padding-left: 1rem;
`;

export const RuricCratieriaMine = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ShapeDivB = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  flex: 100%;
`;

export const ShapeDivBTextFormat = styled.div`
  display: flex;
  gap: 0.6rem;
  width: 90%;
  gap: 0.7rem;
  justify-content: space-between;
`;

export const ShapeDivBTextFormatColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  width: 100%;
  justify-content: space-between;
`;
