import styled from "styled-components";
import { breakpoints } from "../../../theme";

export const AnnouncementsCourseViewHolder = styled.div`
  width: 100%;
  padding-bottom: 1.25rem;
  & > :last-child {
    margin-bottom: 2rem;
  }
`;

export const TitleWithIconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 0.19rem;
  padding-left: 0.06rem;
  align-items: center;
  margin-top: 0.3rem;
  margin-bottom: 0.63rem;
  @media (max-width: ${breakpoints.tablets}) {
    flex-direction: column;
    row-gap: 1.31rem;
    align-items: self-start;
  }
`;
export const DropDownContainer = styled.div`

`;

export const IconsWrapper = styled.div`
  position: relative;
  display: flex;
  column-gap: 1.25rem;
  /* Apply hover effect to first and last children except DropDownContainer */
  & > :not(${DropDownContainer}):is(:first-child, :last-child):hover {
    cursor: pointer;
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
  @media (max-width: ${breakpoints.tablets}) {
    align-self: flex-end;
  }
`;

export const AnnouncementsCardsHolder = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  margin-bottom: 1.25rem;
`;
export const FilterButtonsCont = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;
export const FilterButton = styled.div<{ active?: boolean }>`
  padding: 0.5rem 1.25rem;
  border-radius: 0.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Primary Font-Medium";
  font-size: 0.875rem;
  background-color: ${(props) => (props.active ? "#5DD3B3" : "transparent")};
  color: ${(props) => (props.active ? "#fff" : "#5DD3B3")};
  border: 1px solid #5dd3b3;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s ease;
  &:hover {
    box-shadow: 0 0 1rem rgba(42, 243, 156, 0.3);
  }
`;
export const FiltersContainer = styled.div`
display: flex;
flex-direction: column;
gap: 0.625rem;
margin-bottom: 1.25rem;
`;
