import { SetStateAction, useContext, useEffect, useRef, useState } from "react";
import React from "react";

import { ButtonFilledWrapper } from "../../elements/FiltersButtonFilled/FiltersButtonFilled.style";
import {
  SvgAdd,
  SvgArrowShock,
  SvgDataLines,
  SvgDownload,
  SvgEye,
  SvgGraduateHat,
  SvgManage,
  SvgRubric,
} from "../../elements/Icons";
import { ReactComponent as SvgUpload } from "../../assets/icons/upload_1.svg";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../elements/fonts/Fonts";
import ReusableInput from "../../elements/reusableInput/ReusableInput";
import {
  ActionsDiv,
  AlertPublishSearchRow,
  ArrowHolder,
  BackArrowNameHodler,
  ButtonAdd,
  ButtonCancel,
  ButtonsRow,
  CheckBox,
  FilterBtnsDiv,
  FiltersActionsRow,
  Input,
  LogoAndAction,
  ManageWeightWrapper,
  TableContent,
  TableContentHolder,
  TableHeaders,
  TableRowWrapper,
  TableSection,
  TableTbody,
  TableTd,
  TableTd1,
  TableTh,
  TableThead,
  TableTr,
  TableTr1,
} from "./ManageWeight.style";
import {
  ActivitiesData,
  AssigmentData,
  ForumData,
  QuizData,
  SegmentTitleData,
  TableHeadData,
} from "./manageWeightData";
import { useNavigate, useParams } from "react-router-dom";
import PopUp from "../../elements/Popup/PopUp";
import UploadGradePopup from "./UploadGradePopup";
import { getParsedData } from "./GlobalVariable/GlobalVariable";
import AddAttendancePopUp from "../addAttendancePopUp/AddAttendancePopUp";
import AssignmentsTable from "./AssignmentTable";
import QuizTable from "./QuizTable";
import ForumTable from "./ForumTable";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AttendaceTable from "./Attendance";
import FullCourseWeight from "./FullCourseWeight";
import AddOfflineActivity from "../addOfflineActivity/AddOfflineActivity";
import {
  DataItem,
  formInputHandler,
  handleActivityView,
  handleCheckboxChange,
  handleInputStateChange,
  handleMainActivityChange,
  handleSave,
  handleWeightChange,
  moduleView,
  toggleModel,
  toggleOfflineActivityModel,
} from "./ManageWeightFunctions";
import { set } from "lodash";
import ActivityTable from "./AssignmentTable";
import axios from "axios";
import ActivityModuleTable from "./ModuleManageWeight";
import TitleHeaderTextIconNavigator from "../../elements/TitleHeaderTextIconNavigator/TitleHeaderTextIconNavigator";
import { useRecoilValue } from "recoil";
import { ColorSelector, TableColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { WebSocketConnector } from "../../Views/messages/webSocketConnection";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import {
  ManageWeightHeaderCell,
  ManageWeightHeaderTr,
  ManageWeightTable,
} from "./SelectedOptions/ManagewieghtUpdatesStyle";
import { WebsocketContext } from "../../contexts/notificationContext";
const ManageWeight = () => {
  const [dropzoneData, setDropzoneData] = useState(getParsedData());
  const [showGradePopUp, SetshowGradePopup] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [offlineActivityModal, setOfflineActivityModal] =
    useState<boolean>(false);
  const [formInputValue, setFormInputValue] = useState("");

  const [activities, setActivities] = useState<any>([]);
  const [activities2, setActivities2] = useState<any>([]);
  const [Mainactivities, setMainActivities] = useState<any>([]);

  const [AssignmentWeight, setAssignmentWeight] = useState<number | null>(
    Number(TableHeadData[1].percentage)
  );
  const [filteredDataByModule, setFilteredDataByModule] = useState<{
    [key: string]: DataItem;
  }>({});
  const [shouldGroupPerModule, setShouldGroupPerModule] =
    useState<boolean>(false);
  const [shouldGroupPerActivity, setShouldGroupPerActivity] =
    useState<boolean>(true);
  const [isAssignmentCheckedArray, setIsAssignmentCheckedArray] = useState(
    Array(activities.length).fill(false)
  );
  const [attendanceWeight, SetAttendanceWeight] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [ShowSave, SetShowSave] = useState(false);
  const navigate = useNavigate();
  const handleSearchChange = (value: string) => {
    setSearchTerm(value);

    // Filter activities based on search term
    const filtered = activities.filter((activity: any) =>
      activity.content.contentTitle.toLowerCase().includes(searchTerm)
    );
    setActivities(filtered);
    if (searchTerm === "") {
      setActivities(activities2);
    }
  };

  const id = useParams();
  const { t } = useTranslation();

  const tenantName = localStorage.getItem("tenant");
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const { isReady, value, send, connect } = useContext(WebsocketContext);

  useEffect(() => {
    if (
      !isReady &&
      tenantName !== null &&
      tenantName !== undefined &&
      subIdSplitted !== null &&
      subIdSplitted !== undefined
    ) {
      connect();
    }
  }, [tenantName, subIdSplitted]);
  const [savedOffline, SetsavedOffline] = useState(false);
  const saveActivities = async () => {
    // Construct the activities array
    const activitiesArray = activities.map((activity: any) => ({
      activityType: activity.type ? activity.type : "",
      refId: activity.content.refId ? activity.content.refId : 0,
      title: activity.content.contentTitle ? activity.content.contentTitle : "",
      weight: activity.weight,
      isOffline: activity.isOffline,
      module: activity.moduleIndex,
      contentId: activity.content.contentId,
      publish: activity.publish,
    }));

    // Fetch the filters for each activity type
    const filtersResponse = await axios.get(
      `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/grades/filter/get/${id.classId}/${tenantName}`
    );
    const filters = filtersResponse.data.data;

    // Helper function to get filter type for a given activity type
    const getFilterType = (activityType: string) => {
      const filter = filters.find(
        (filter: any) => filter.activityType === activityType
      );
      return filter ? filter.filter : null;
    };

    // Check for negative weights in activitiesArray if filter is 'manual'
    const hasInvalidWeights = activitiesArray.some(
      (activity: { weight: number; activityType: string }) => {
        return (
          getFilterType(activity.activityType) === "manual" &&
          activity.weight < 0
        );
      }
    );
    if (hasInvalidWeights) {
      toast.error("There are negative numbers in your weights.");
      return;
    }

    // Initialize an object to store the weights of main activities
    const mainWeights: { [key: string]: number } = {};

    // Iterate through mainActivities to extract weights
    Mainactivities.forEach((mainActivity: any) => {
      const activityKey = Object.keys(mainActivity)[0];
      if (activityKey !== "Attendance") {
        // Skip "Attendance" activity
        mainWeights[activityKey] = mainActivity[activityKey]?.Weight || 0;
      }
    });

    // Check for negative weights in mainWeights if filter is 'manual'
    const hasInvalidMainWeights = Object.entries(mainWeights).some(
      ([activityType, weight]) => {
        return getFilterType(activityType) === "manual" && weight < 0;
      }
    );
    if (hasInvalidMainWeights) {
      toast.error("There are negative numbers in your main activity weights.");
      return;
    }

    // Validate if weights of main activities add up to 100 if filter is 'manual'
    const totalMainWeight = Object.entries(mainWeights).reduce(
      (total, [activityType, weight]) => {
        return getFilterType(activityType) === "manual"
          ? total + weight
          : total;
      },
      0
    );
    const totalWithAttendance = totalMainWeight + attendanceWeight;
    if (totalWithAttendance !== 100 && hasInvalidMainWeights) {
      toast.error("Weights of main activities must add up to 100.");
      return;
    }

    // Validate if weights of activities of the same type add up to 100 if filter is 'manual'
    const typeWeights: { [key: string]: number } = {};
    activities.forEach((activity: { type: any; weight: number }) => {
      const type = activity.type;
      typeWeights[type] = (typeWeights[type] || 0) + activity.weight;
    });

    // Check for negative weights in typeWeights if filter is 'manual'
    const hasInvalidTypeWeights = Object.entries(typeWeights).some(
      ([activityType, weight]) => {
        return getFilterType(activityType) === "manual" && weight < 0;
      }
    );
    if (hasInvalidTypeWeights) {
      toast.error("There are negative numbers in your type weights.");
      return;
    }

    // Check if any type's weights don't add up to 100 if filter is 'manual'
    const invalidTypes = Object.entries(typeWeights).filter(
      ([activityType, weight]) => {
        return getFilterType(activityType) === "manual" && weight !== 100;
      }
    );

    // If there are invalid types, show an error toast and return
    if (invalidTypes.length > 0) {
      toast.error("Weights of activities of the same type must add up to 100.");
      return;
    }

    // Construct the final data object
    const dataToLog = {
      data: {
        activities: activitiesArray,
        classSourceId: id.classId,
        ForumWeight: mainWeights["Forum"] || 0,
        AttendanceWeight: attendanceWeight || 0,
        QuizWeight: mainWeights["Quiz"] || 0,
        AssignmentWeight: mainWeights["Assignment"] || 0,
      },
    };

    try {
      // Send the data using Axios
      const response = await axios.post(
        `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/activity/weight/create/${tenantName}`,
        dataToLog
      );

      // Assuming successful response
      toast.success("Activities saved successfully");
      if (isReady && send) {
        send(
          JSON.stringify({
            action: "sendPublic",
            data: {
              tenantName: tenantName,
              subId: subIdSplitted,
              provider: provider,
              type: "createGrade",
              arrayData: dataToLog.data.activities,
              classSourceId: id.classId,
            },
          })
        );
      }
    } catch (error) {
      console.error("Error while saving activities:", error);
      toast.error("Failed to save activities. Please try again later.");
    }
  };

  const fetchMainActivityData = async () => {
    try {
      const response = await axios.get(
        `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/activities/weight/${id.classId}/${tenantName}`
      );

      // Filter main activities to include only "Attendance"
      const attendanceActivities = response.data.data.filter(
        (activity: {}) => Object.keys(activity)[0] === "Attendance"
      );
      const attendanceWeight =
        attendanceActivities.length > 0
          ? attendanceActivities[0].Attendance.Weight
          : 0;
      SetAttendanceWeight(attendanceWeight);
      // Sort main activities by type
      const sortedMainActivities = response.data.data
        .slice()
        .sort((a: any, b: any) => {
          const typeA = Object.keys(a)[0];
          const typeB = Object.keys(b)[0];

          // Ensure "Attendance" is always at the top
          if (typeA === "Attendance") return -1;
          if (typeB === "Attendance") return 1;

          return typeA.localeCompare(typeB);
        });

      setMainActivities(sortedMainActivities);
    } catch (error) {
      console.error("Error fetching main activity data:", error);
    }
  };

  const handleClick = () => {
    navigate(`/courseview/Gradebook/GradeItemView/${id.classId}`);
  };

  const handleImportGrade = () => {
    SetshowGradePopup(true);
  };

  const handlePopUpSubmit = () => {
    const updatedDropzoneData = getParsedData();
    setDropzoneData(updatedDropzoneData);
  };
  const { Table_Head_TR } = useRecoilValue(TableColorSelector);
  const { specialColor2 } = useRecoilValue(ColorSelector);

  const fetchManageWeightData = async () => {
    try {
      const response = await axios.get(
        `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/activity/weight/${tenantName}/${id.classId}`
      );

      // Sort assignments by type
      const sortedAssignments = response.data.data
        .slice()
        .sort((a: { type: number }, b: { type: number }) =>
          a.type > b.type ? 1 : -1
        );

      setActivities(sortedAssignments);
      setActivities2(sortedAssignments);
    } catch (error) {
      console.error("Error fetching sessions data:", error);
    }
  };

  useEffect(() => {
    fetchManageWeightData();
  }, []);
  useEffect(() => {
    fetchManageWeightData();
  }, [savedOffline]);

  useEffect(() => {
    fetchMainActivityData();
  }, []);

  const handleAttendanceChange = (value: any) => {
    // Handle the changed activities here
    SetAttendanceWeight(value);
  };
  const sortedActivities = shouldGroupPerModule
    ? activities
        .slice()
        .sort(
          (a: { moduleIndex: number }, b: { moduleIndex: number }) =>
            a.moduleIndex - b.moduleIndex
        )
    : activities;
  const [Filters, SetFilters] = useState([]);
  const getFilters = async () => {
    try {
      const response = await axios.get(
        `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/grades/filter/get/${id.classId}/${tenantName}`
      );
      SetFilters(response.data.data);

      // Handle the response data as needed
    } catch (error) {
      console.error("There was an error making the GET request!", error);
    }
  };
  useEffect(() => {
    getFilters();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1.25rem" }}>
      {/* <div> */}
      {modal && (
        <PopUp
          icon={<SvgAdd />}
          content={
            <AddAttendancePopUp
              toggleModel={() => toggleModel(modal, setModal)}
            />
          }
          showline={false}
          show={modal}
          setShow={setModal}
          title={t("gradebook.Add Attendance")}
          //  showButtonCancel={true}
          showButton={false}
          buttonName={t("forms.Save")}
          justifyContent="flex-end"
          //  handleSubmit={handleSaveOffline}
          // marginTop="1rem"
          // paddingTop="1.25rem"
          ContentWidth="37rem"
          ContentMinWidth="37rem"
        />
      )}
      {offlineActivityModal && (
        <PopUp
          icon={<SvgAdd />}
          content={
            <AddOfflineActivity
              toggleOfflineActivityModel={() =>
                toggleOfflineActivityModel(
                  offlineActivityModal,
                  setOfflineActivityModal
                )
              }
              savedOffline={savedOffline}
              SetsavedOffline={SetsavedOffline}
            />
          }
          showline={false}
          show={offlineActivityModal}
          setShow={setOfflineActivityModal}
          title={t("gradebook.Add Offline Activity")}
          //  showButtonCancel={true}
          showButton={false}
          buttonName={t("forms.Save")}
          justifyContent="flex-end"
          //  handleSubmit={handleSaveOffline}
          // marginTop="1rem"
          // paddingTop="1.25rem"
          ContentWidth="50%"
        />
      )}
      <ManageWeightWrapper>
        <AlertPublishSearchRow>
          <TitleHeaderTextIconNavigator
            navigateTo={`/courseview/Gradebook/${id.classId}`}
            title={t("gradebook.Manage Weight")}
          />
          <ReusableInput
            left="1.69rem"
            holderWidth="90%"
            placeHolder={t("dashboard.Search")}
            padingLeft="3.31rem"
            inputBorderRadius="6.25rem"
            inputWidth="23.625rem"
            inputHeight="3.125rem"
            display="block"
            onInputChange={(value: any) => handleSearchChange(value)} // Pass the input value to handleFormInputChange
            value={searchTerm}
          />
        </AlertPublishSearchRow>
        <FiltersActionsRow>
          <FilterBtnsDiv>
            <ButtonFilledWrapper
              onClick={() =>
                handleActivityView(
                  setShouldGroupPerActivity,
                  setShouldGroupPerModule
                )
              }
            >
              <LightTypography
                text={t("gradebook.Group Per Activity")}
                fontSize="1rem"
              />
            </ButtonFilledWrapper>
            <ButtonFilledWrapper
              onClick={() =>
                moduleView(
                  AssigmentData,
                  QuizData,
                  ForumData,
                  setFilteredDataByModule,
                  setShouldGroupPerModule,
                  setShouldGroupPerActivity
                )
              }
            >
              <LightTypography
                text={t("gradebook.Group Per Module")}
                fontSize="1rem"
              />
            </ButtonFilledWrapper>
          </FilterBtnsDiv>

          <ActionsDiv style={{ color: specialColor2 }}>
            <LogoAndAction
              onClick={() => {
                navigate(
                  `/courseview/Gradebook/AttendanceAllSessions/${id.classId}`
                );
              }}
            >
              <SvgEye />
              <Typography
                text={t("gradebook.View Attendances sheet")}
                fontSize="0.75rem"
              />
            </LogoAndAction>
            {/* <LogoAndAction onClick={handleImportGrade}>
              <SvgUpload color="#FFFFF" fill="white" />
              <Typography text="Import Grades" fontSize="0.75rem" />
            </LogoAndAction> */}

            {/* <LogoAndAction onClick={handleClick}>
              <SvgGraduateHat />
              <Typography text="View Student Activities" fontSize="0.75rem" />
            </LogoAndAction> */}
            <LogoAndAction
              onClick={() =>
                toggleOfflineActivityModel(
                  offlineActivityModal,
                  setOfflineActivityModal
                )
              }
            >
              <SvgAdd />
              <Typography
                text={t("gradebook.Add Offline Activity")}
                fontSize="0.75rem"
              />
            </LogoAndAction>

            <LogoAndAction onClick={() => toggleModel(modal, setModal)}>
              <SvgAdd />
              <Typography
                text={t("gradebook.Add Attendance")}
                fontSize="0.75rem"
              />
            </LogoAndAction>
          </ActionsDiv>
        </FiltersActionsRow>
      </ManageWeightWrapper>
      <ManageWeightTable>
        <ManageWeightHeaderTr bgcolor={Table_Head_TR}>
          {SegmentTitleData.map((item, index) => (
            <ManageWeightHeaderCell>
              <MediumTypography
                text={t(item.segmentTitle)}
                fontSize="1.125rem"
                lineHeight="1.75rem"
                whiteSpace="nowrap"
              />
            </ManageWeightHeaderCell>
          ))}
        </ManageWeightHeaderTr>

        <ManageWeightHeaderTr>
          {shouldGroupPerActivity && (
            <ActivityTable
              mainActivities={Mainactivities}
              SetShowSave={SetShowSave}
              shouldGroupPerModule={shouldGroupPerModule}
              isCheckedArray={isAssignmentCheckedArray}
              handleMainWeightChange={(type, event) =>
                handleMainActivityChange(
                  type,
                  event,
                  Mainactivities,
                  setMainActivities
                )
              }
              handleCheckboxChange={(id) =>
                handleCheckboxChange(
                  id,
                  setIsAssignmentCheckedArray,
                  isAssignmentCheckedArray,
                  activities,
                  setActivities
                )
              }
              AttendanceWeight={attendanceWeight ? attendanceWeight : 0}
              SetAttendanceWeight={SetAttendanceWeight}
              activities={activities ? sortedActivities : []}
              handleWeightChange={(id, event) =>
                handleWeightChange(id, event, setActivities, activities)
              }
              sendInputStateToParent={(percentage: number) =>
                handleInputStateChange(percentage, setAssignmentWeight)
              }
              onAttendanceChange={(value) => handleAttendanceChange(value)}
              fetchManageWeightData={fetchManageWeightData}
            />
          )}
          {shouldGroupPerModule && (
            <ActivityModuleTable
              mainActivities={Mainactivities}
              shouldGroupPerModule={shouldGroupPerModule}
              isCheckedArray={isAssignmentCheckedArray}
              handleMainWeightChange={(type, event) =>
                handleMainActivityChange(
                  type,
                  event,
                  Mainactivities,
                  setMainActivities
                )
              }
              handleCheckboxChange={(id) =>
                handleCheckboxChange(
                  id,
                  setIsAssignmentCheckedArray,
                  isAssignmentCheckedArray,
                  activities,
                  setActivities
                )
              }
              AttendanceWeight={attendanceWeight ? attendanceWeight : 0}
              SetAttendanceWeight={SetAttendanceWeight}
              activities={activities ? sortedActivities : []}
              handleWeightChange={(id, event) =>
                handleWeightChange(id, event, setActivities, activities)
              }
              sendInputStateToParent={(percentage: number) =>
                handleInputStateChange(percentage, setAssignmentWeight)
              }
            />
          )}
        </ManageWeightHeaderTr>
      </ManageWeightTable>
      {showGradePopUp && (
        <PopUp
          icon={<SvgUpload />}
          content={<UploadGradePopup />}
          showline={false}
          show={showGradePopUp}
          setShow={SetshowGradePopup}
          title={t("gradebook.Import Grade")}
          showButtonCancel={true}
          handleSubmit={handlePopUpSubmit}
          marginTop="1rem"
          paddingTop="1.25rem"
        />
      )}{" "}
      {ShowSave && (
        <ButtonsRow>
          <ButtonAdd
            onClick={() => {
              saveActivities();
              SetShowSave(false);
            }}
          >
            <Typography
              text={t("forms.Save")}
              color="#fff"
              fontSize="0.875rem"
              fontWeight="400"
              lineHeight="normal"
            />
          </ButtonAdd>
          <ButtonCancel onClick={() => SetShowSave(false)}>
            <Typography
              text={t("forms.Cancel")}
              color="#D85D66"
              fontSize="0.875rem"
              fontWeight="400"
              lineHeight="normal"
            />
          </ButtonCancel>
        </ButtonsRow>
      )}
    </div>
  );
};

export default ManageWeight;
