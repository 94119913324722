import React from "react";
import {
  AQHeadTableTr,
  AQTableCol,
  AQTableTbody,
  AQTableThead,
  AQTableTr,
  AnalyticsQuizzesScrollerContainer,
  AnalyticsQuizzesTable,
} from "../../AnalyticsQuizzes/AnalyticsQuizzes.style";
import {
  AQQTableTd1,
  AQQTableTh,
  AQQTableThData,
  AQQTableThDetails,
} from "./QuizQuestionsAnalytics.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../../elements/fonts/Fonts";
import { PointerContainer } from "../../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableTable.style";
import { SvgGrade } from "../../../elements/Icons";
import { SurveyhandleCorrectQuestionSvg } from "../../../utils/Function";
import { QuizQuestionsAnalyticsInterfaceV2 } from "./QuizQuestionsAnalyticsinterfaceV2";
interface AnalyticsQuizzesScrolledProps {
  NormalData: any[];

  filteredData: any[];
  QuestionsCartiristicsData: any[];
  setChosenQuestion?: any;
}
const AnalyticsQuizzesScrolled = ({
  NormalData,
  filteredData,
  QuestionsCartiristicsData,
  setChosenQuestion,
}: AnalyticsQuizzesScrolledProps) => {
  const handleQuestionObject = (
    question: QuizQuestionsAnalyticsInterfaceV2
  ) => {
    setChosenQuestion && setChosenQuestion(question);
  };
  return (
    <AnalyticsQuizzesScrollerContainer>
      <AnalyticsQuizzesTable>
        <AQTableThead>
          {/* Rendering table headers */}
          {NormalData &&
            NormalData.map((Qval, Qindex) => (
              <AQTableCol key={Qindex}>
                <AQQTableTh>
                  <AQQTableThData>
                    <MediumTypography
                      text={Qval.QuestionTitle || ""}
                      lineHeight="1.75rem"
                      fontSize="1.125rem"
                    />
                    <LightTypography
                      text={`( ${Qval.QuestionType} )` || ""}
                      lineHeight="1.75rem"
                      fontSize="1.125rem"
                    />
                    <PointerContainer
                      onClick={() => handleQuestionObject(Qval)}
                    >
                      <SvgGrade />
                    </PointerContainer>
                  </AQQTableThData>
                  <AQQTableThData>
                    <Typography
                      text={` ${Qval.points}  points` || ""}
                      color="#5DD3B3"
                      fontSize="0.625rem"
                    />
                  </AQQTableThData>
                </AQQTableTh>
                <AQHeadTableTr>
                  {/* Rendering subheadings */}
                  {QuestionsCartiristicsData.map((Pro) => (
                    <AQQTableThDetails
                      key={Pro.Text}
                      style={{ height: "3rem" }}
                    >
                      <Typography text={Pro.Text} fontSize="1rem" />
                    </AQQTableThDetails>
                  ))}
                </AQHeadTableTr>
              </AQTableCol>
            ))}
        </AQTableThead>
        <div style={{ display: "flex" }}>
          {/* Rendering table body */}
          {filteredData.map((val) => (
            <AQTableTbody key={val?.QuestionCode}>
              {val?.students &&
                val.students.map(
                  (
                    student: {
                      TimeSpent: { toString: () => any };
                      Answered: any;
                      Grade: { toString: () => any };
                    },
                    index: React.Key | null | undefined
                  ) => (
                    <AQTableTr key={index}>
                      <AQQTableTd1>
                        <LightTypography
                          text={student?.TimeSpent.toString() || ""}
                          fontSize="0.75rem"
                        />
                      </AQQTableTd1>
                      <AQQTableTd1>
                        <div>
                          {SurveyhandleCorrectQuestionSvg(student.Answered)}
                        </div>
                      </AQQTableTd1>
                      <AQQTableTd1>
                        <LightTypography
                          text={student?.Grade.toString() || "0"}
                          fontSize="0.75rem"
                        />
                      </AQQTableTd1>
                    </AQTableTr>
                  )
                )}
            </AQTableTbody>
          ))}
        </div>
      </AnalyticsQuizzesTable>
    </AnalyticsQuizzesScrollerContainer>
  );
};

export default AnalyticsQuizzesScrolled;
