import React, { useEffect, useState } from "react";
import ScrollLeftAndRight from "../../../../../elements/ScrollLeftAndRight/ScrollLeftAndRight";
import {
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../../../../elements/fonts/Fonts";
import {
  GradeBookColumns,
  GradeBookMainTableContainer,
  GradeBookStudentCell,
  GradeBookTableTdHeader,
  GradebookHeadercontainer,
  Input,
  TableContentHolder,
  TableTd,
  TableTdTop,
  TableTr,
} from "../../../GradeBookTable.style";
import { GradebookData } from "../NewGradebookData";
import {
  SvgDropDownArrow,
  SvgEyeIcon,
  SvgInsideout,
  SvgScale,
} from "../../../../../elements/Icons";
import {
  GradeColorPicker,
  publishGrade,
} from "../../../../../utils/GradePointToLetter";
import {
  StudenttransformData,
  calculateTotalGrade,
  calculateWeightedScore,
  handleAttendancePopup,
  transformData,
} from "../../GradebookFunctions";
import { FlexDiv } from "../../../../../globalStyles";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../../../../utils/StringManipulation";
import { useRecoilValue } from "recoil";
import { TableColorSelector } from "../../../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { EmptyView } from "../../../../questionBanks/selectedQuestion/SelectedQuestion.style";
import EmptyPost from "../../../../PostsTimeLine/EmptyPost/EmptyPost";
import { toArabicDigits } from "../../../../../utils/formatDayArabic";
interface TransformedData {
  students: any[];
  MainActivity: {
    id: string;
    name: string;
    weight: any;
  }[];
}
interface SimpleTableProps {
  allGradeData: any;
  allStudentData: TransformedData;
  GradeType: string;
  setSelectedStudentId: any;
  handleAttendancePopup: () => void;
  cardId?: string;
}
interface Student {
  id: string;
  name: string;
  activities: Activity[];
  attendance: {
    score: number;
    weight: number;
  }[];
}
interface Activity {
  id: string;
  name: string;
  grades: {
    name: string;
    id: string;
    score: number;
    weight: number;
  }[];
}
type ActivityVisibility = {
  [key: string]: boolean;
};

const NewStudentView: React.FC<SimpleTableProps> = ({
  allGradeData,
  allStudentData,
  cardId,
  GradeType,
  handleAttendancePopup,
  setSelectedStudentId,
}) => {
  const [activityViewModes, setActivityViewModes] = useState<{
    [key: string]: boolean;
  }>({});
  const [activityVisibility, setActivityVisibility] =
    useState<ActivityVisibility>({});

  const [gradeData, setGradeData] = useState<Student[] | null>(null);
  const [attendanceValues, setAttendanceValues] = useState<{
    [key: string]: number;
  }>({});
  const [Mainactivities, setMainActivities] = useState<any>([]);
  const [AttendanceWeight, SetAttendanceWeight] = useState(0);
  const [allData, setAllData] = useState<any>({
    students: [],
    MainActivity: [],
  });
  const [allStudentDatas, setAllStudentData] = useState<any>({
    students: [],
    MainActivity: [],
  });
  const [allAttendanceData, setallAttendanceData] = useState<any>({
    students: [],
    MainActivity: [],
  });
  const tenantName = localStorage.getItem("tenant");
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const id = useParams();
  const { t } = useTranslation();
  const [Averages, SetAverages] = useState<any[]>([]);

  // Filtered students array containing only one student with id "student1"
  const filteredStudents = allData.students;

  useEffect(() => {
    const fetchGradebookData = async () => {
      try {
        if (cardId !== null && cardId !== undefined) {
          const response = await axios.get(
            `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/grade/student/${tenantName}/${cardId}/${subIdSplitted}?provider=${provider}`
          );
          setGradeData(response.data.data);
        } else {
          const response = await axios.get(
            `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/grade/student/${tenantName}/${id.classId}/${subIdSplitted}?provider=${provider}`
          );
          setGradeData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching gradebook data:", error);
      }
    };

    const fetchMainActivityData = async () => {
      try {
        if (cardId !== null && cardId !== undefined) {
          const response = await axios.get(
            `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/activities/weight/${cardId}/${tenantName}`
          );
          setMainActivities(response.data.data);
          SetAttendanceWeight(response.data.data[3].Attendance.Weight);
        } else {
          const response = await axios.get(
            `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/activities/weight/${id.classId}/${tenantName}`
          );
          setMainActivities(response.data.data);
          SetAttendanceWeight(response.data.data[3].Attendance.Weight);
        }
      } catch (error) {
        console.error("Error fetching main activity data:", error);
      }
    };

    fetchGradebookData();
    fetchMainActivityData();
  }, [tenantName, id]);

  const [filters, SetFilters] = useState<any[]>([]);

  const getFilters = async () => {
    try {
      if (cardId !== null && cardId !== undefined) {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/grades/filter/get/${cardId}/${tenantName}`
        );
        SetFilters(response.data.data);
      } else {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/grades/filter/get/${id.classId}/${tenantName}`
        );
        SetFilters(response.data.data);
      }
      // Handle the response data as needed
    } catch (error) {
      console.error("There was an error making the GET request!", error);
    }
  };

  const toggleView = (activityId: string) => {
    setActivityViewModes((prevState) => ({
      ...prevState,
      [activityId]: !prevState[activityId],
    }));
    setActivityVisibility((prevState) => ({
      ...prevState,
      [activityId]: !prevState[activityId],
    }));
  };

  useEffect(() => {
    const transformedData = StudenttransformData(Mainactivities, gradeData);

    const AllTransformedData = transformData(Mainactivities, gradeData);
    setAllData(transformedData);
    setAllStudentData(AllTransformedData);
  }, [Mainactivities, gradeData]);

  const { theme, Table_Head_TR } = useRecoilValue(TableColorSelector);
  const language = localStorage.getItem("language");

  function getFilterType(activityId: any) {
    const filter = filters.find((f) => f.activityType === activityId);

    return filter && filter.filter ? filter.filter : "manual";
  }
  const checkGrades = (data: any) => {
    for (const student of data.students) {
      for (const activity of student.activities) {
        if (activity.grades.length > 0) {
          return true;
        }
      }
    }
    return false;
  };

  const calculatedGrade = (grade: number, maxGrade: number): number => {
    return grade * (100 / maxGrade);
  };

  const calculateAverageForActivity = (data: any[]) => {
    const activitySums: Record<
      string,
      {
        totalScore: number;
        count: number;
        activityType: string;
        activityName: string;
      }
    > = {};
    const activityOrder: string[] = [];
    const activityTypeTotals: Record<
      string,
      { totalScore: number; count: number }
    > = {};

    // Define the desired order of activity types, with "Attendance" as the last one
    const activityTypeOrder = ["Assignment", "Quiz", "Forum", "Attendance"];

    // Sort the data based on the desired activity type order
    data.sort((a, b) => {
      return (
        activityTypeOrder.indexOf(a.activityType) -
        activityTypeOrder.indexOf(b.activityType)
      );
    });

    data.forEach((activity) => {
      const filterType = getFilterType(activity.id);
      const isManual = filterType === "manual";

      const isAttendance = activity.activityType === "Attendance";
      const shouldProcessAttendance = isAttendance && AttendanceWeight > 0;
      const shouldProcessOther =
        !isAttendance &&
        activity.publish === true &&
        ((isManual && activity.weight > 0) || !isManual);

      if (shouldProcessAttendance || shouldProcessOther) {
        const key = activity.id;
        const activityType = activity.activityType;

        if (!activitySums[key]) {
          activitySums[key] = {
            totalScore: 0,
            count: 0,
            activityType,
            activityName: activity.activityName,
          };
          activityOrder.push(key);
        }

        if (!activityTypeTotals[activityType]) {
          activityTypeTotals[activityType] = { totalScore: 0, count: 0 };
        }

        // If it's attendance, don't check gradedByInstructor
        if (isAttendance || activity.gradedByInstructor) {
          activitySums[key].totalScore +=
            (activity.score * 100) / activity.maxGrade;
          activitySums[key].count += 1;
          activityTypeTotals[activityType].totalScore += activity.score;
          activityTypeTotals[activityType].count += 1;
        }
      }
    });

    const averages: any[] = [];
    let currentActivityType = "";
    let attendanceTotalAdded = false; // Flag to track if "Attendance Average Total" has been added

    activityOrder.forEach((activityId) => {
      const { totalScore, count, activityType, activityName } =
        activitySums[activityId];
      const average = count > 0 ? totalScore / count : "-";

      if (currentActivityType !== activityType) {
        currentActivityType = activityType;
      }

      // Don't push Attendance activities if AttendanceWeight <= 0
      if (activityType !== "Attendance" || AttendanceWeight > 0) {
        averages.push({
          activityId,
          activityType,
          activityName,
          average,
          Total: false,
        });
      }

      const nextActivityId =
        activityOrder[activityOrder.indexOf(activityId) + 1];
      const nextActivityType = nextActivityId
        ? activitySums[nextActivityId].activityType
        : null;

      // Add "Average Total" for each activity type only once
      if (nextActivityType !== activityType && !(activityType === "Attendance" && AttendanceWeight <= 0)) {
        const { totalScore, count } = activityTypeTotals[activityType];
        const activityTypeAverageTotal = count > 0 ? totalScore / count : "-";

        if (activityType !== "Attendance" || (AttendanceWeight > 0 && !attendanceTotalAdded)) {
          averages.push({
            activityId: `${activityType}AverageTotal`,
            activityType,
            activityName: `${activityType} Average Total`,
            average: activityTypeAverageTotal,
            Total: true,
          });
          if (activityType === "Attendance") attendanceTotalAdded = true; // Mark "Attendance Average Total" as added
        }
      }
    });

    // Handle cases where no activities were published for certain types
    const activityTypesProcessed = Object.keys(activityTypeTotals);
    const allActivityTypes = Array.from(
      new Set(data.map((activity) => activity.activityType))
    );

    allActivityTypes.forEach((activityType) => {
      if (!activityTypesProcessed.includes(activityType) && !(activityType === "Attendance" && AttendanceWeight <= 0)) {
        if (activityType !== "Attendance" || !attendanceTotalAdded) { // Check before adding "Attendance Average Total"
          averages.push({
            activityId: `${activityType}AverageTotal`,
            activityType,
            activityName: `${activityType} Average Total`,
            average: "-",
            Total: true,
          });
          if (activityType === "Attendance") attendanceTotalAdded = true; // Mark "Attendance Average Total" as added
        }
      }
    });

    // Sort the averages array based on the desired order
    averages.sort((a, b) => {
      return activityTypeOrder.indexOf(a.activityType) - activityTypeOrder.indexOf(b.activityType);
    });

    SetAverages(averages);
    console.log(averages, "averagez");
    return averages;
  };









  const calculateAverageTotalGrade = () => {
    const Transformed = transformData(Mainactivities, allGradeData);

    const Students = Transformed.students;
    const totalGrades = Students.map((student: { id: string }) => {
      const totalGrade = calculateTotalGrade(student.id, Transformed, filters);
      return totalGrade;
    });
    const sumOfTotalGrades = totalGrades.reduce(
      (sum: any, grade: any) => sum + grade,
      0
    );
    const averageTotalGrade = sumOfTotalGrades / Students.length;
    return averageTotalGrade.toFixed(2);
  };

  const calculatedClassAverage = (average: any) => {
    return average.toFixed(2);
  };

  useEffect(() => {
    calculateAverageForActivity(allGradeData);
  }, [AttendanceWeight]);

  useEffect(() => {
    getFilters();
    calculateAverageForActivity(allGradeData);
  }, []);

  return checkGrades(allData) ? (
    <>
      <GradeBookMainTableContainer>
        <GradeBookColumns>
          <GradeBookStudentCell theme={theme} language={language} />
          <GradeBookStudentCell theme={theme}>
            <Typography text={t("gradebook.Student Name")} />
          </GradeBookStudentCell>
          {filteredStudents?.map((student: any) => (
            <GradeBookStudentCell key={student.id} theme={theme}>
              <TypographyRegularDescription
                text={student.name}
                fontSize="1rem"
              />
            </GradeBookStudentCell>
          ))}

          <GradeBookStudentCell>
            <TypographyRegularDescription
              text={t("assignment.Class Average")}
              fontSize="1rem"
            />
          </GradeBookStudentCell>
        </GradeBookColumns>
        <ScrollLeftAndRight
          justifyContent={language === "ar" ? "left" : "right"}
          Width="83%"
          content={
            <TableContentHolder>
              <TableTr
                style={{
                  background:
                    theme === "light"
                      ? "#AAB1B940"
                      : language === "ar" && theme === "dark"
                        ? "linear-gradient(90deg, #293440 0%, #415160  107.88%"
                        : "linear-gradient(90deg, #415160 0%, #293440 107.88%",
                }}
              >
                {/* Generating headers for each activity */}
                {allData?.MainActivity.map(
                  (activity: { id: string; name: string; weight: number }) => {
                    if (activity.weight < 0) return null; // Skip if activity weight is not greater than 0

                    // Calculate the colspan based on the number of grades with weight > 0
                    const matchingActivity =
                      filteredStudents[0]?.activities.find(
                        (studentActivity: { id: string }) =>
                          studentActivity.id === activity.id
                      );

                    const filterType = getFilterType(activity.id);
                    const colspan = matchingActivity
                      ? matchingActivity.grades.filter(
                        (grade: { weight: number }) =>
                          (grade.weight > 0 && filterType === "manual") ||
                          (filterType !== "manual" && grade.weight > 0)
                      ).length + 1
                      : 1;

                    return (
                      <>
                        {activity.weight > 0 && filterType === "manual" && (
                          <GradeBookTableTdHeader
                            key={activity.id}
                            colSpan={
                              activityVisibility[activity.id] ? colspan : 1
                            }
                            theme={theme}
                          >
                            <GradebookHeadercontainer>
                              <MediumTypography
                                text={t(`gradebook.${activity?.name}`)}
                                fontSize="1rem"
                              />
                              {activityViewModes[activity.id] ? ( // Check if the view mode is Scale or Insideout for the current activity
                                <SvgInsideout
                                  onClick={() => toggleView(activity.id)}
                                />
                              ) : (
                                <SvgScale
                                  onClick={() => toggleView(activity.id)}
                                />
                              )}
                            </GradebookHeadercontainer>
                          </GradeBookTableTdHeader>
                        )}
                        {filterType !== "manual" && (
                          <GradeBookTableTdHeader
                            key={activity.id}
                            colSpan={
                              activityVisibility[activity.id] ? colspan : 1
                            }
                            theme={theme}
                          >
                            <GradebookHeadercontainer>
                              <MediumTypography
                                text={t(`gradebook.${activity?.name}`)}
                                fontSize="1rem"
                              />
                              {activityViewModes[activity.id] ? ( // Check if the view mode is Scale or Insideout for the current activity
                                <SvgInsideout
                                  onClick={() => toggleView(activity.id)}
                                />
                              ) : (
                                <SvgScale
                                  onClick={() => toggleView(activity.id)}
                                />
                              )}
                            </GradebookHeadercontainer>
                          </GradeBookTableTdHeader>
                        )}
                      </>
                    );
                  }
                )}
                {AttendanceWeight > 0 && (
                  <GradeBookTableTdHeader theme={theme}>
                    <MediumTypography text={t("gradebook.Attendance")} />
                  </GradeBookTableTdHeader>
                )}
                <TableTd>
                  <MediumTypography text={t("gradebook.Course Final Grade")} />
                </TableTd>
              </TableTr>
              {/* Rest of your table content */}
              <TableTr>
                {/* Mapping through activities */}
                {allData?.MainActivity.map(
                  (activity: {
                    id: React.Key | null | undefined;
                    name: any;
                    weight: number;
                  }) => {
                    if (activity.weight < 0) return null; // Skip if activity weight is not greater than 0
                    const filterType = getFilterType(activity.id);
                    return (
                      <React.Fragment key={activity.id}>
                        {/* Mapping through grades of each activity */}
                        {filteredStudents[0]?.activities
                          .find((act: { id: string }) => act.id === activity.id)
                          ?.grades.map(
                            (grade: {
                              id: React.Key | null | undefined;
                              name: string;
                              weight: number;
                              publish: boolean;
                            }) => {
                              // Skip if grade weight is not greater than 0

                              return (
                                (grade.weight > 0 &&
                                  filterType === "manual" && (
                                    <React.Fragment key={grade.id}>
                                      {activity.id &&
                                        activityVisibility[activity.id] && (
                                          <TableTd>
                                            <FlexDiv>
                                              <MediumTypography
                                                text={grade.name}
                                              />
                                            </FlexDiv>
                                          </TableTd>
                                        )}
                                    </React.Fragment>
                                  )) ||
                                (filterType !== "manual" &&
                                  grade.weight > 0 && (
                                    <React.Fragment key={grade.id}>
                                      {activity.id &&
                                        activityVisibility[activity.id] && (
                                          <TableTd>
                                            <FlexDiv>
                                              <MediumTypography
                                                text={grade.name}
                                              />
                                            </FlexDiv>
                                          </TableTd>
                                        )}
                                    </React.Fragment>
                                  ))
                              );
                            }
                          )}
                        {/* Total for the activity */}
                        {activity.weight > 0 && filterType === "manual" && (
                          <TableTdTop key={`total_${activity.id}`}>
                            <MediumTypography
                              text={`${t("gradebook.Total")} ${t(
                                `gradebook.${activity?.name}`
                              )}`}
                            />
                          </TableTdTop>
                        )}
                        {filterType !== "manual" && (
                          <TableTdTop key={`total_${activity.id}`}>
                            <MediumTypography
                              text={`${t("gradebook.Total")} ${t(
                                `gradebook.${activity?.name}`
                              )}`}
                            />
                          </TableTdTop>
                        )}
                      </React.Fragment>
                    );
                  }
                )}
                {AttendanceWeight > 0 && (
                  <TableTdTop>
                    <MediumTypography
                      text={t("gradebook.Attendance Total")}
                      fontSize="1rem"
                    />
                  </TableTdTop>
                )}
              </TableTr>

              {filteredStudents?.map((student: any) => (
                <TableTr theme={theme} key={student.id}>
                  {/* Mapping through each student's activities */}
                  {allData.MainActivity.map(
                    (activity: { id: string; weight: number }) => {
                      if (activity.weight <= 0) return null; // Skip if activity weight is not greater than 0
                      const filterType = getFilterType(activity.id);
                      const studentActivity = student.activities.find(
                        (act: { id: string }) => act.id === activity.id
                      );

                      return (
                        <React.Fragment key={`${student.id}_${activity.id}`}>
                          {/* Mapping through grades of each activity */}
                          {studentActivity &&
                            activityVisibility[activity.id] &&
                            studentActivity.grades.map(
                              (grade: {
                                maxGrade: number;
                                id: any;
                                score: number;
                                weight: number;
                                gradedByInstructor: boolean;
                              }) => {
                                // if (grade.weight <= 0) return null; // Skip if grade weight is not greater than 0

                                return (
                                  (grade.weight > 0 &&
                                    filterType === "manual" && (
                                      <TableTd
                                        key={`${student.id}_${grade.id}`}
                                      >
                                        <MediumTypography
                                          text={`${grade.gradedByInstructor
                                              ? GradeType === "Point"
                                                ? toArabicDigits(
                                                  calculatedGrade(
                                                    grade.score,
                                                    grade.maxGrade
                                                  )
                                                    .toFixed(2)
                                                    .toString()
                                                )
                                                : publishGrade(
                                                  grade.score *
                                                  (100 / grade.maxGrade)
                                                )
                                              : "-"
                                            }`}
                                          color={
                                            grade.gradedByInstructor
                                              ? GradeColorPicker(
                                                grade.score *
                                                (100 / grade.maxGrade)
                                              )
                                              : "gray"
                                          }
                                          fontSize="0.75rem"
                                        />
                                      </TableTd>
                                    )) ||
                                  (filterType !== "manual" &&
                                    grade.weight > 0 && (
                                      <TableTd
                                        key={`${student.id}_${grade.id}`}
                                      >
                                        <MediumTypography
                                          text={`${grade.gradedByInstructor
                                              ? GradeType === "Point"
                                                ? toArabicDigits(
                                                  calculatedGrade(
                                                    grade.score,
                                                    grade.maxGrade
                                                  )
                                                    .toFixed(2)
                                                    .toString()
                                                )
                                                : publishGrade(
                                                  grade.score *
                                                  (100 / grade.maxGrade)
                                                )
                                              : "-"
                                            }`}
                                          color={
                                            grade.gradedByInstructor
                                              ? GradeColorPicker(
                                                grade.score *
                                                (100 / grade.maxGrade)
                                              )
                                              : "gray"
                                          }
                                          fontSize="0.75rem"
                                        />
                                      </TableTd>
                                    ))
                                );
                              }
                            )}

                          {/* Calculate and display total for each activity for the student */}
                          {studentActivity && (
                            <TableTdTop
                              key={`${student.id}_total_${activity.id}`}
                            >
                              <TypographyRegularDescription
                                text={`${GradeType === "Point"
                                    ? toArabicDigits(
                                      calculateWeightedScore(
                                        student.id,
                                        activity.id,
                                        allData,
                                        filters
                                      )
                                        .toFixed(2)
                                        .toString()
                                    )
                                    : publishGrade(
                                      calculateWeightedScore(
                                        student.id,
                                        activity.id,
                                        allData,
                                        filters
                                      )
                                    )
                                  }`}
                                color={GradeColorPicker(
                                  studentActivity.grades.reduce(
                                    (total: any, grade: { score: any }) =>
                                      total + grade.score,
                                    0
                                  )
                                )}
                                fontSize="0.75rem"
                              />
                            </TableTdTop>
                          )}
                        </React.Fragment>
                      );
                    }
                  )}
                  {AttendanceWeight > 0 && (
                    <TableTdTop>
                      <Input
                        key={student.id}
                        value={filteredStudents[0].attendance || 0} // Assuming attendance score is available at index 0
                        disabled={true}
                        style={{
                          color: GradeColorPicker(
                            filteredStudents[0].attendance
                          ),
                        }}
                      />
                      {!cardId && (
                        <SvgEyeIcon
                          onClick={() => {
                            handleAttendancePopup();
                            setSelectedStudentId(student.id);
                          }}
                        />
                      )}
                    </TableTdTop>
                  )}

                  <TableTd>
                    <TypographyRegularDescription
                      text={`${GradeType === "Point"
                          ? toArabicDigits(
                            calculateTotalGrade(
                              student.id,
                              allData,
                              filters
                            ).toString()
                          )
                          : publishGrade(
                            calculateTotalGrade(student.id, allData, filters)
                          )
                        }`}
                      fontSize="0.75rem"
                      color={GradeColorPicker(
                        calculateTotalGrade(student.id, allData, filters)
                      )}
                    />
                  </TableTd>
                </TableTr>
              ))}
              {/*Class average */}
              <TableTr>
                {Averages.map((average, index) => {
                  if (average.Total === true) {
                    // Render totals inside TableTdTop
                    return (
                      <>
                        <TableTdTop key={index}>
                          <TypographyRegularDescription
                            text={
                              typeof average.average === "number"
                                ? toArabicDigits(
                                  calculatedClassAverage(
                                    average.average
                                  ).toString()
                                )
                                : "-"
                            }
                            fontSize="0.75rem"
                            color={GradeColorPicker(average.average)}
                          />
                        </TableTdTop>
                      </>
                    );
                  } else {
                    // Render regular activities inside TableTd
                    return (
                      <>
                        {activityVisibility[average.activityType] && (
                          <TableTd key={index}>
                            <TypographyRegularDescription
                              text={
                                typeof average.average === "number"
                                  ? toArabicDigits(
                                    calculatedClassAverage(
                                      average.average
                                    ).toString()
                                  )
                                  : "-"
                              }
                              fontSize="0.75rem"
                              color={GradeColorPicker(average.average)}
                            />
                          </TableTd>
                        )}
                      </>
                    );
                  }
                })}
                <TableTd>
                  <TypographyRegularDescription
                    text={calculateAverageTotalGrade().toString()}
                    fontSize="0.75rem"
                    color={GradeColorPicker(
                      parseFloat(calculateAverageTotalGrade())
                    )}
                  />
                </TableTd>
              </TableTr>
            </TableContentHolder>
          }
        />
      </GradeBookMainTableContainer>
    </>
  ) : (
    <>
      <EmptyPost text={t("view all.Nothing here yet")} />
    </>
  );
};

export default NewStudentView;
