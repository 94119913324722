import styled from 'styled-components'
import { StyleThemeInterface } from '../../../../interfaces/StyleThemeInterface';

// export const MenuContainer=styled.div`
// display: flex;
// justify-content:flex-end;
// height: 120vh;
// background: #2D3741;
// `;

// export const ContentContainer=styled.div`
// width: 1.875rem;
// height: 47.6875rem;
// background: #384450;
// display: flex;
// justify-content:center;
// align-items:center;
// `;




export const MenuContainer=styled.div<{bgColor?:string}>`
display: flex;
justify-content:flex-end;
height: 100%;
height: 60vh;
background:${props =>props.bgColor ||"#2D3741"};
`;

export const ContentContainer=styled.div<StyleThemeInterface>`
width: 1.875rem;
/* height: 47.6875rem; */
background:${props =>props.bgcolor || "#384450" };
display: flex;
justify-content:center;
align-items:center;
align-self:flex-end;
`;
