import React, { FC, useState, useEffect, DragEvent } from "react";
import {
  EssayQuestionContainer,
  CheckBox,
  EnableAttachmentContainer,
  RadioDivLabel,
  LabelDiv,
} from "./EssayQuestion.style";
import { EssayQuestionProps } from "./EssayQuestionInterface";
import { LightTypography, PrimaryFontBoldText, Typography } from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import { ErrorDiv } from "../../../elements/ErrorDiv/Errordiv.style";
import { useTranslation } from "react-i18next";
import { mapErrorType } from "../../../utils/QuizErrorTypes";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { FlexDiv } from "../../../globalStyles";
import CheckBoxInput from "../../../elements/checkbox/CheckBox";
import { SvgEyeIcon } from "../../../elements/Icons";
import NestedSelect from "../../../elements/nestedSelect/NestedSelect";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import CheckboxSquare from "../../../elements/checkboxSquare/CheckboxSquare";
import { useQuizMyContext } from "../../../contexts/CourseCreatorQuizContext";

const EssayQuestion: FC<EssayQuestionProps> = ({
  choice,
  qindex,
  handleChoiceMatchingQuestion,
  questionError,
  showQuest,
  handleChoiceChecked,
  handleSelectRubricId,
  quest,
  index
}) => {
  const [isChecked, setIsChecked] = useState<boolean | undefined>(false);
  const {
    selectedRadio,
    setSelectedRadio,
    selectedRubricId,
    setSelectedRubricId,
    selectedTitle,
    setSelectedTitle
  } = useQuizMyContext();
  const [rubricError, setRubricError] = useState(false);
  const [rubricOptions, setRubricOptions] = useState<
  { title: string; submenu?: any }[]
>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {id} = useParams();
  const tenantName = localStorage.getItem("tenant");

  useEffect(() => {
    setIsChecked(choice?.isChecked);
  }, [choice]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    handleChoiceChecked(qindex, !isChecked);
  };

  const handleToggleChangeRubric = (value: string) => {
    setSelectedRadio(value); // Update the selected radio
  };

  const handleSelectChange = (value: any) => {
    setSelectedTitle(value); // Update the selectedTitle state
    handleSelectRubricId && handleSelectRubricId(0)
  };
  const handleSelectrubricId = (value: any) => {
    setSelectedRubricId(value); // Update the selectedTitle state
    handleSelectRubricId && handleSelectRubricId(value)
  };

  const handleEditRubric = () => {
    if (!selectedTitle) {
      setRubricError(true);
    } else {
      setRubricError(false);
      navigate(`/courseView/Gradebook/${id}/RubricTableView/${selectedRubricId}`);
    }
  };

  useEffect(() => {
    const getQuizTemplate = async () => {
      try {
        const response = await axios.get(
          `https://0j157bzxcf.execute-api.eu-west-1.amazonaws.com/dev/get/all/${id}/${tenantName}`
        );
        const quizDataArray = response.data.data.rubricsData || [];

        // Transform the quiz data array into the format expected by SelectInput
        const transformedOptions: {
          title: any;
          value: string;
          submenu?: any;
        }[] = [
          {
            title: `${t("forum.Use Existing Rubric")}`,
            value: "Use Existing Rubric",
            submenu: quizDataArray.map((quiz: any) => ({
              title: quiz.title,
              id: quiz.id,
            })),
          },
          {
            title: `${t("forum.create a new one")}`,
            value: "Create a new one",
          },
        ];
        setRubricOptions(transformedOptions);
      } catch (error) {
        console.error("Axios Error:", error);
      }
    };

    getQuizTemplate();
  }, [id]);

  useEffect(() => {
    if(quest && quest.rubricId && quest.rubricId !==0 && quest.rubricId !== null && quest.rubricId !== ""){
      setSelectedRubricId(quest.rubricId);
      const foundRubric = rubricOptions.find(
        (rubric) =>
          rubric.submenu &&
          rubric.submenu.find((item: any) => item.id === quest.rubricId)
      );
      setSelectedRadio("rubric")

      if (foundRubric) {
        const selectedRubric = foundRubric.submenu.find(
          (item: any) => item.id === quest.rubricId
        );
        setSelectedTitle(selectedRubric.title);
      }
    }else{
      setSelectedRadio("Manually graded")
      handleSelectRubricId && handleSelectRubricId(0)
      setSelectedRubricId(0)
      setSelectedTitle('')
    }
  },[quest, rubricOptions, quest.rubricId, index, qindex])

  useEffect(()=>{
    if(selectedRubricId){
      handleSelectRubricId && handleSelectRubricId(selectedRubricId)
    }
  },[])

  const errorType = mapErrorType(questionError || "", t);

  const {
    checkboxBgColor,
    checkboxBorderColor,
    checkboxBorderRadius,
    SecondaryTextColor,
  } = useRecoilValue(ColorSelector);

  return (
    <EssayQuestionContainer>
      {/* {questionError && qindex === 0 && <ErrorDiv>{errorType}</ErrorDiv>} */}
      {/* Grading type */}
      <PrimaryFontBoldText
        text={t("assignment.Grading type")}
        fontSize="1rem"
      />
      <FlexDiv style={{pointerEvents: showQuest ? "none" : "auto"}}>
        <RadioDivLabel onClick={() => handleToggleChangeRubric("Manually graded")}>
          <CheckBoxInput
            name="gradeType"
            value="grade"
            checked={selectedRadio === "Manually graded"}
          />
          <LightTypography text={t("quiz.Manually graded")} fontSize="0.875rem" />
        </RadioDivLabel>
        <RadioDivLabel onClick={() => handleToggleChangeRubric("rubric")}>
          <CheckBoxInput
            name="gradeType"
            value="rubric"
            checked={selectedRadio === "rubric"}
          />
          <LightTypography text={t("assignment.Rubric")} fontSize="0.875rem" />
        </RadioDivLabel>
      </FlexDiv>
      {selectedRadio === "rubric" && (
          <LabelDiv>
            <FlexDiv gap="0.25rem">
              <LightTypography text={t("Rubric.Rubric")} fontSize="0.875rem" />
              <span>*</span>
              <SvgEyeIcon
                onClick={handleEditRubric}
                style={{ color: "#5DD3B3", cursor: "pointer" }}
              />
            </FlexDiv>
            <NestedSelect
              onChange={handleSelectChange}
              onIdChange={handleSelectrubricId}
              placeholder={t("course creator.Use Rubric")}
              fontSize="0.875rem"
              width="27.5rem"
              paddingRL="1.25rem"
              options={rubricOptions}
              value={selectedTitle}
              redirectUrl={`/courseView/Gradebook/${id}/Rubric`}
              disabled={showQuest ? true : false}
            />

            {selectedRadio === "rubric" &&
              !selectedTitle &&
              (rubricError) && (
                <ErrorDiv>{t("forms.Please select a rubric")}</ErrorDiv>
              )}
          </LabelDiv>
        )}
      <EnableAttachmentContainer>
        {!showQuest ? (
          <CheckboxSquare
            bgcolor={checkboxBgColor}
            bordercolor={checkboxBorderColor}
            borderradius={checkboxBorderRadius}
            checked={isChecked === true}
            name="isChecked"
            onChange={handleCheckboxChange}
          />
        ) : (
          <CheckboxSquare
            bgcolor={checkboxBgColor}
            bordercolor={checkboxBorderColor}
            borderradius={checkboxBorderRadius}
            checked={isChecked === true}
            name="isChecked"
            onChange={handleCheckboxChange}
            disabled
          />
        )}
        <Typography
          text={t("quiz.Enable attachments")}
          fontSize="0.875rem"
          // color={theme.dark.primary_text_color}
        />
      </EnableAttachmentContainer>
    </EssayQuestionContainer>
  );
};

export default EssayQuestion;
