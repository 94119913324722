import React, { FC, useState, useRef, useEffect } from "react";
import {
  ColorPickerMainHolder,
  ColorPickerHolder,
  ColorPickerButton,
  ColorPickerDiv,
} from "./ColorPickerMenu.style";
import { ColorPickerData } from "./colorPickerData";
import { useStateContext } from "../../contexts/ContextProvider";

import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

interface ColorPickerMenuNewInterface {
  onColorChange?: (color: string) => void;
  addNewGroup: (color: string) => void;
  position?: string;
  top?: string;
  left?: string;
}

const ColorPickerMenuNew: FC<ColorPickerMenuNewInterface> = ({
  onColorChange,
  addNewGroup,
  position,
  top,
  left,
}) => {
  const { setIsShownChangeColor, isChangeColorShown, setColorGroup } =
    useStateContext();

  // Recoil state and selectors
  const { backgroundColorPickerColor, boxShadow } =
    useRecoilValue(ColorSelector);

  const [isClicked, setIsClicked] = useState<boolean>(false);

  const handleButtonClick = (color: string) => {
    onColorChange && onColorChange(color);
    setColorGroup(color);
    setIsClicked(true);
    addNewGroup(color);
    // Pass the selected color to the parent component via the onColorChange prop
  };
  return (
    <ColorPickerHolder
      position={position || "absolute"}
      left={left || "0rem"}
      top={top || "0.81rem"}
      bgColor={backgroundColorPickerColor}
      boxShadow={boxShadow}
    >
      {ColorPickerData.map((color) => (
        <ColorPickerButton
          key={color.name}
          backgroundcolor={color.color}
          onClick={() => handleButtonClick(color.color)}
          className={isClicked ? "clicked" : ""}
        ></ColorPickerButton>
      ))}
      <ColorPickerDiv
        className={isChangeColorShown ? "rotated" : ""}
        onClick={() => {
          setIsShownChangeColor();
        }}
      ></ColorPickerDiv>
    </ColorPickerHolder>
  );
};

export default ColorPickerMenuNew;
