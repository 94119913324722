import React, { FC, useEffect, useState } from "react";
import { CalendarContainer } from "./TeacherCalendar.style";
import {
  Calendar,
  momentLocalizer,
  luxonLocalizer,
  DateLocalizer,
} from "react-big-calendar";
import moment from "moment";
import "moment/locale/tr"; // Import Turkish locale data
import "moment/locale/ar";
import "moment/locale/fr";
import { tz } from "moment-timezone";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./ReactBigCalendar.css";
import CustomCalendarToolBar from "../CustomCalendarToolBar/CustomCalendarToolBar";
import { TeacherCalendarProps } from "../TeacherCalendarInterface";
import TeacherCalendarCards from "../../TeacherCalendarCards/main/TeacherCalendarCards";
import PopUp from "../../../elements/Popup/PopUp";
import CalendarEventPopup from "./Popup/CalendarEventPopup";
import { Events } from "../../../Views/TeacherCalendarView/TeacherCalendarDataInterface";
import {
  SvgAssignmentIcon,
  SvgDiscussion,
  SvgEventLogo,
  SvgOnlineSessionIcon,
  SvgQuiz,
  SvgSurveyIcon,
  SvgTeams,
} from "../../../elements/Icons";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { LoadingComp } from "../../LoadingComp/LoadingComp";
// import { theme } from "../../../theme";

const TeacherCalendar: FC<TeacherCalendarProps> = ({ events, loading }) => {
  const { themeTextColor, theme } = useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  const [view, setView] = useState("month");
  const [ShowPopup, setShowPopup] = useState<boolean>(false);
  const [SelectedEvent, setSelectEvent] = useState<Events>();
  const [updatedLocalizer, setUpdatedLocalizer] = useState<DateLocalizer>();
  const [language, setLanguage] = useState<string>("");
  const localStorageLanguage = localStorage.getItem("language");

  useEffect(() => {
    localStorageLanguage === "tr"
      ? setLanguage("tr")
      : localStorageLanguage === "ar"
      ? setLanguage("ar")
      : localStorageLanguage === "fr"
      ? setLanguage("fr")
      : setLanguage("en");
  }, [t]);

  // Function to set the localizer based on the selected language
  useEffect(() => {
    const setLocalizer = () => {
      // Set moment locales
      moment.locale(language);
      // Return the appropriate momentLocalizer
      return momentLocalizer(moment);
    };

    // Update the localizer when the language changes in localStorage
    const localizer = setLocalizer();
    setUpdatedLocalizer(localizer);
  }, [language]);

  //handle the switch between week,day and month view
  const handleViewChange = (newView: string) => {
    setView(newView);
  };

  //on event click, shows the popup of the selected event
  const handleSelectedEvent = (event: Events) => {
    setShowPopup(true);
    setSelectEvent(event);
  };

  //on click on show more
  const OnShowMore: any = (events: any, date: any) => {
    return;
  };

  const today = new Date()

  const combinedData = [...events].map((item, index) => ({
    ...item,
    id: index,
    isLast: index === events.length - 1,
  }));

  const getEventIcon = (eventType: string) => {
    switch (eventType) {
      case "Survey":
        return <SvgSurveyIcon width={25} height={14} fill="white" />;
      case "OnlineSession":
        return <SvgOnlineSessionIcon width={25} height={14} fill="white" />;
      case "Quiz":
        return <SvgQuiz width={25} height={14} fill="white" />;
      case "Assignment":
        return <SvgAssignmentIcon width={25} height={14} fill="white" />;
      case "Forum":
        return <SvgDiscussion width={25} height={14} fill="white" />;
      case "Teams":
        return <SvgTeams width={25} height={14}/>;
      default:
        return <SvgEventLogo width={25} height={14} fill="white" />;
    }
  };

  if (!updatedLocalizer) return <div>Loading calendar...</div>;
  //   const  rbc_header_span_Color =() => {
  // if(theme ==="light")
  //   return
  //   }
  return (
    <CalendarContainer color={themeTextColor} theme={theme}>
      {loading ? (
        <LoadingComp />
      ) : (
        <Calendar
          // style={}
          className={theme === "light" ? "light-theme" : "dark-theme"}
          localizer={updatedLocalizer}
          events={combinedData}
          startAccessor="start"
          endAccessor="end"
          defaultDate={moment().toDate()}
          onShowMore={OnShowMore}
          onSelectEvent={handleSelectedEvent}
          onView={(newView) => {
            handleViewChange(newView);
          }}
          eventPropGetter={(event, start, end, isSelected) => {
            let backgroundColor = "";
            let textColor = { themeTextColor };
            let borderLeftColor = "";

            switch (event.type) {
              case "Survey":
               
                  backgroundColor =
                  theme === "light" ? "#E4E6E8" : "#D9E4E880";
                borderLeftColor = "#D9E4E8";
              
                break;
              case "OnlineSession":
            
                  backgroundColor =
                  theme === "light" ? "#E4E6E8" : "#0B8FFF80";
                borderLeftColor = "#0B8FFF";
             
                break;
              case "Teams":
          
                  backgroundColor =
                  theme === "light" ? "#E4E6E8" : "#4E5FBF80";
                borderLeftColor = "#4E5FBF";
              
                break;
              case "Quiz":
             
                  backgroundColor =
                    theme === "light" ? "#E4E6E8" : "#96B4E380";
                  borderLeftColor = "#96B4E3";
              
                break;
              case "Assignment":
          
                  backgroundColor =
                    theme === "light" ? "#E4E6E8" : " #5DD3B380";
                  borderLeftColor = " #5DD3B3";
              
                break;
              default:
                  backgroundColor =
                  theme === "light" ? "#E4E6E8" : "#8CEDEA80";
                borderLeftColor = "#8CEDEA";
                
                break;
            }

            let EventStyle: React.CSSProperties = {
              borderRadius: "2px",
              backgroundColor,
              color: themeTextColor,
              fontWeight: "bold",
              fontFamily: "Core Rhino 65 Bold",
              borderLeft: `${
                localStorageLanguage === "ar"
                  ? ""
                  : ` 5px solid ${borderLeftColor}`
              } `,
              borderRight: `${
                localStorageLanguage === "ar"
                  ? ` 5px solid ${borderLeftColor}`
                  : ""
              } `,
              marginBottom: "0px",
            };
            return {
              className: "",
              style: EventStyle,
            };
          }}
          components={{
            event: ({ event }) => (
              <TeacherCalendarCards events={event} index={event.id || 0} />
            ),
            toolbar: (props) => (
              <CustomCalendarToolBar
                label={props.label}
                onNavigate={props.onNavigate}
                onView={props.onView}
                view={view}
              />
            ),
          }}
        />
      )}
      {ShowPopup && SelectedEvent && (
        <PopUp
          icon={getEventIcon(SelectedEvent.type)}
          content={<CalendarEventPopup event={SelectedEvent} />}
          showline={false}
          show={ShowPopup}
          // paddingTop="1.5rem"
          setShow={setShowPopup}
          marginTop="0rem"
          showButtonCancel={false}
          showButton={false}
          BackGround="none"
          IconContainer="0"
        />
      )}
    </CalendarContainer>
  );
};

export default TeacherCalendar;
