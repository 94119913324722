import { StudentLandingPageInterface } from "./StudentLandingPageInterface";

export const TeacherLandingPageData : StudentLandingPageInterface[] = [

  {
    id: "1",

    Recourses: [
      {
        id: "1",
        type: "Files",
        titleB: "Average Time Spent 2 hours",
        totalView:21,
      Data: [
          {
            id: "1",
            title: "File 1",
            textA: "",
            textB: "Average Time Spent 2 hours",
            Viewed: 4,
          },
          {
            id: "2",
            title: "File 2",
            textA: "",
            textB: "Average Time Spent 2 hours",
            Viewed: 7,
          },
          {
            id: "3",
            title: "File 3",
            textA: "",
            textB: "Average Time Spent 2 hours",
            Viewed: 10,
          },
        ],
      },
      {
        id: "2",
        type: "HTML Links",
        titleB: "Average Time Spent 2 hours",
        totalView:28,
        Data: [
          {
            id: "1",
            title: "HTML Link 1",
            textA: "",
            textB: "Average Time Spent 2 hours",
            Viewed: 10,
          },
          {
            id: "2",
            title: "HTML Link 2",
            textA: "",
            textB: "Average Time Spent 2 hours",
            Viewed: 11,
          },
          {
            id: "3",
            title: "HTML Link 3",
            textA: "",
            textB: "Average Time Spent 2 hours",
            Viewed: 7,
          },
        ],
      },
      {
        id: "3",
        type: "Videos",
        titleB: "Average Time Spent 2 hours",
        totalView:37,
      Data: [
          {
            id: "1",
            title: "Video 1 1",
            textA: "",
            textB: "Average Time Spent 2 hours",
            Viewed: 10,
          },
          {
            id: "2",
            title: "Video 1 2",
            textA: "",
            textB: "Average Time Spent 2 hours",
            Viewed: 14,
          },
          {
            id: "3",
            title: "Video 1 3",
            textA: "",
            textB: "Average Time Spent 2 hours",
            Viewed: 13,
          },
        ],
      },
    ],

    Activities: [
      {
        id: "1",
        type: "Quizzes",
        titleA: "Average Time Spent 1 hour 10 min",
        titleB: "My Average Grade 71",
        totalSubmissions:46,
      Data: [
          {
            id: "1",
            title: "Quiz 1",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: 11,
          },
          {
            id: "2",
            title: "Quiz 2",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: 20,
          },
          {
            id: "3",
            title: "Quiz 3",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: 15,
          },
        ],
      },
      {
        id: "2",
        type: "Assignments",
        titleB: "Average Time Spent 2 hours",
        totalSubmissions:39,
        Data: [
          {
            id: "1",
            title: "Assignment 1",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: 13,
          },
          {
            id: "2",
            title: "Assignment 2",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: 12,
          },
          {
            id: "3",
            title: "Assignment 3",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: 14,
          },
        ],
      },
      {
        id: "3",
        type: "Forum",
        titleB: "Average Time Spent 2 hours",
        totalSubmissions:43,
        Data: [
          {
            id: "1",
            title: "Forum 1",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: 10,
          },
          {
            id: "2",
            title: "Forum 2",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: 12,
          },
          {
            id: "3",
            title: "Forum 3",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: 21,
          },
        ],
      },
      {
        id: "4",
        type: "Survey",
        titleB: "Average Time Spent 2 hours",
        totalSubmissions:66,
      Data: [
          {
            id: "1",
            title: "Survey 1",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: 12,
          },
          {
            id: "2",
            title: "Survey 2",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: 22,
          },
          {
            id: "3",
            title: "Survey 3",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: 32,
          },
        ],
      },
    ],
    
  },
]