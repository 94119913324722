import styled from "styled-components";

export const CustomVimeoViewerContainer = styled.div`
display:flex;
width: 100%;
height: 100%;
div{
    width: 100%;
}
iframe{
    width: 100%;
    height: 100%;
}

`