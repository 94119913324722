import React, { FC } from "react";
import {
  MenuContainer,
  MenuElementContainer,
  MenuItem,
  DropdownMenu,
} from "./FilterStatus.style";
import { FilterStatusProps } from "./FilterStatusInterface";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

export const FitlterStatus: FC<FilterStatusProps> = ({
  menuOpen,
  filterTasksByStatus,
  selectedStatus,
}) => {
  const { t } = useTranslation();
  const handleClick = (status: string) => {
    if (selectedStatus !== status) {
      // Only set the selected Status when it's different from the currently selected one
      filterTasksByStatus(status);
    }
  };
  const { bg_selectColor } = useRecoilValue(ColorSelector);
  return (
    <MenuContainer>
      <DropdownMenu
        style={{ background: bg_selectColor }}
        isopen={menuOpen.toString()}
      >
        <MenuElementContainer index={1} onClick={() => handleClick("Over Due")}>
          <MenuItem>{t("to do list.Over Due")}</MenuItem>
        </MenuElementContainer>
        <MenuElementContainer
          index={2}
          onClick={() => handleClick("Near Deadline")}
        >
          <MenuItem>{t("to do list.Near Deadline")}</MenuItem>
        </MenuElementContainer>
        <MenuElementContainer
          index={3}
          onClick={() => handleClick("No Deadline")}
        >
          <MenuItem>{t("to do list.No Deadline")}</MenuItem>
        </MenuElementContainer>
      </DropdownMenu>
    </MenuContainer>
  );
};
