import React, { useEffect, useState } from "react";
import {
  SERVERStarColumn,
  SERVERStarROw,
  SurveyStarTextSize,
  QADisplayPartsRowStart,
  QADisplaySVGHolder,
} from "./ServeyStarQuestion.style";
import { MediumTypography, TypographyRegularDescription } from "../fonts/Fonts";
import { SvgStayFav } from "../Icons";
import { Choice, Question } from "../../components/generateSurvey/generateSurveyDataInterface";

interface StarComponent {
  PartTitle?: string;
  text?: string;
  onChange: (value: number) => void;
  showStarDisplay?: boolean;
  quest?:Question;
  allChoices?:Choice[];
  qindex?:number;
}

export const SurveyStarQuestion = ({
  PartTitle,
  text,
  onChange,
  showStarDisplay,
  quest,
  allChoices,
  qindex
}: StarComponent): JSX.Element => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [clickedIndex, setClickedIndex] = useState<Record<number, number | null>>({});
  const [isBranched, setIsBranched] = useState<any>(false);

  useEffect(() => {
    if (allChoices && qindex !== undefined && allChoices[qindex]?.value !== undefined) {
      const updatedClickedIndexes = { ...clickedIndex };
      if((!qindex || qindex === 0) && isBranched){
        updatedClickedIndexes[qindex] = 1;
      }else{
        updatedClickedIndexes[qindex] = allChoices[qindex]?.value! - 1 || null;
        setClickedIndex(updatedClickedIndexes);
      }
      
    }
  }, [allChoices, qindex]);
  

  


  useEffect(()=>{
    setIsBranched(quest?.branching)
    
  },[quest?.branching]) 

  const handleHover = (index: number | null) => {
    setHoveredIndex(index);
  };

  const handleClick = (index: number) => {
    setClickedIndex((prevClickedIndexes) => {
      const updatedClickedIndexes = { ...prevClickedIndexes };
      updatedClickedIndexes[qindex || 0] = index ; // Add 1 because index is zero-based, but you may want a 1-based value
      return updatedClickedIndexes;
    });
    onChange(index + 1); // Add 1 because index is zero-based, but you may want a 1-based value
  };

  //console.log("Star Rating" ,quest?.branching)

  const ZidyiaInterface = () => {
    const svgArray = [];
    for (let i = 0; i < 5; i++) {
      const isHovered = hoveredIndex === i;
      const isActive = clickedIndex[qindex ?? 0] !== null && i <= (clickedIndex[qindex ?? 0] ?? 0);
      if(!isBranched){
        svgArray.push(
          <QADisplaySVGHolder
            key={i}
            color={isActive ? "#5DD3B3" : isHovered ? "#5DD3B3" : undefined}
          >
            <SvgStayFav />
          </QADisplaySVGHolder>
        );
      }else if(isBranched === true){
        svgArray.push(
          <QADisplaySVGHolder
            key={i}
            color={isActive ? "#5DD3B3" : isHovered ? "#5DD3B3" : undefined}
            onMouseEnter={() => handleHover(i)}
            onMouseLeave={() => handleHover(null)}
            onClick={() => handleClick(i)}
          >
            <SvgStayFav />
          </QADisplaySVGHolder>
        );
      }
      
    }
    return svgArray;
  };

  return (
    <>
    
    <SERVERStarColumn>
      {showStarDisplay && 
    <TypographyRegularDescription
      text={PartTitle || ""}
      color="rgba(255, 255, 255, 0.50);"
      fontSize="0.75rem"
      lineHeight="1.125rem"
    />
  }

    <SERVERStarROw>
      <>
      {showStarDisplay && 
      <SurveyStarTextSize>
        <MediumTypography text={text || ''} />
        
      </SurveyStarTextSize>
      }
      <QADisplayPartsRowStart>{ZidyiaInterface()}</QADisplayPartsRowStart>
      </>
    </SERVERStarROw>
  </SERVERStarColumn>
    </>
  );
};
