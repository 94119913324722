import React, { FC, useState } from 'react';
import { ReactComponent as Book } from '../../../assets/icons/book_2.svg'
import { ReactComponent as Download } from '../../../assets/icons/Download.svg'
import { Typography } from '../../../elements/fonts/Fonts';
import { NavContainer, ItemsContainer } from './GraderNavMenu.style';
import { GraderNavMenuProps } from '../GraderNavMenuInterface';
import JSZip from 'jszip';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
const GarderNavMenu: FC<GraderNavMenuProps> = ({ handleQuizView, student, studentIndex, allAssignment, forum, allPosts, quiz }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation();

  function addTextWithWrapping(doc:any, text:any, x:any, y:any, maxWidth:any) {
    const lines = doc.splitTextToSize(text, maxWidth);
    doc.text(lines, x, y);
  }

  function removeHtmlTags(str:any) {
    const div = document.createElement("div");
    div.innerHTML = str;
    return div.textContent || div.innerText || "";
  }

  const downloadAllFiles = async () => {
    setLoading(true)
    const zip = new JSZip();

    if (!forum) {
      // Assuming student is an array of objects with submissionFile property
      await Promise.all(
        student.map(async (item, index) => {
          if(item.textAnswer && item.submissionFile){ // Text and file answer assignment type
            const response = await fetch(item.submissionFile);
            const blob = await response.blob();
            // Adding each file to the zip file with a unique name
            if (item.submissionFile.includes('.pdf')) {
              zip.file(`${student && student.length > 0 && (student[index].user.fName + " " + student[index].user.lName)} (file answer).pdf`, blob);
            } else if (item.submissionFile.includes('.docx')) {
              zip.file(`${student && student.length > 0 && (student[index].user.fName + " " + student[index].user.lName)} (file answer).docx`, blob);
            } else if (item.submissionFile.includes('.doc')) {
              zip.file(`${student && student.length > 0 && (student[index].user.fName + " " + student[index].user.lName)} (file answer).doc`, blob);
            } else if (item.submissionFile.includes('.xlsx')) {
              zip.file(`${student && student.length > 0 && (student[index].user.fName + " " + student[index].user.lName)} (file answer).xlsx`, blob);
            }
            else {
              zip.file(`file_${index + 1}(file answer)`, blob);
            }
          // Remove HTML tags from the text answer
          const cleanText = removeHtmlTags(item.textAnswer);
          
          // Adding each file to the zip file with a unique name
          const studentName = `${student[index].user.fName} ${student[index].user.lName} (text answer)`;
          
          // Create a new PDF document
          const doc = new jsPDF();
          
          // Add text to the PDF with wrapping
          addTextWithWrapping(doc, cleanText, 10, 10, 180); // 180 is the maximum width of the text
          
          // Generate the PDF as a Blob
          const pdfBlob = doc.output('blob');
          
          // Add the PDF to the zip file
          zip.file(`${studentName}.pdf`, pdfBlob);

          
          }else if (item.textAnswer) { // Text answer assignment type
            // Remove HTML tags from the text answer
            const cleanText = removeHtmlTags(item.textAnswer);

            // Adding each file to the zip file with a unique name
            const studentName = `${student[index].user.fName} ${student[index].user.lName}`;
            
            // Create a new PDF document
            const doc = new jsPDF();
            
            // Add text to the PDF with wrapping
            addTextWithWrapping(doc, cleanText, 10, 10, 180); // 180 is the maximum width of the text
            
            // Generate the PDF as a Blob
            const pdfBlob = doc.output('blob');
            
            // Add the PDF to the zip file
            zip.file(`${studentName}.pdf`, pdfBlob);
          }else if (item.submissionFile) { // File answer assignment type
            const response = await fetch(item.submissionFile);
            const blob = await response.blob();
            // Adding each file to the zip file with a unique name
            if (item.submissionFile.includes('.pdf')) {
              zip.file(`${student && student.length > 0 && (student[index].user.fName + " " + student[index].user.lName)}.pdf`, blob);
            } else if (item.submissionFile.includes('.docx')) {
              zip.file(`${student && student.length > 0 && (student[index].user.fName + " " + student[index].user.lName)}.docx`, blob);
            } else if (item.submissionFile.includes('.doc')) {
              zip.file(`${student && student.length > 0 && (student[index].user.fName + " " + student[index].user.lName)}.doc`, blob);
            } else if (item.submissionFile.includes('.xlsx')) {
              zip.file(`${student && student.length > 0 && (student[index].user.fName + " " + student[index].user.lName)}.xlsx`, blob);
            }
            else {
              zip.file(`file_${index + 1}`, blob);
            }

          }
        })
      );
    } else if (forum && allPosts && allPosts.length > 0) {
      try {
        await Promise.all(
          allPosts.map(async (item, index) => {
            if (item.file && item.file !== "") {
              const response = await fetch(item.file);
              const blob = await response.blob();
              // Adding each file to the zip file with a unique name
              if (item.file.includes('.pdf')) {
                zip.file(`${item.posterName}.pdf`, blob);
              } else if (item.file.includes('.docx')) {
                zip.file(`${item.posterName}.docx`, blob);
              } else if (item.file.includes('.xlsx')) {
                zip.file(`${item.posterName}.xlsx`, blob);
              }
              else if (item.file.includes('.jpeg')) {
                zip.file(`${item.posterName}.jpeg`, blob);
              }
              else if (item.file.includes('.png')) {
                zip.file(`${item.posterName}.png`, blob);
              }
              else if (item.file.includes('.jpg')) {
                zip.file(`${item.posterName}.jpg`, blob);
              }
              else {
                zip.file(`${item.posterName}_${index + 1}`, blob);
              }

            }
          })
        );
      } catch (err) {
        console.log(err)
      }
      // Assuming student is an array of objects with submissionFile property

    }


    // Generate the zip file asynchronously
    zip.generateAsync({ type: 'blob' }).then((content) => {
      // Triggering the download of the zip file
      const link = document.createElement('a');
      link.href = URL.createObjectURL(content);
      link.download = `${allAssignment && allAssignment.title}.zip`;
      link.click();
    });
    setLoading(false);
  };

  return (
    <NavContainer>
      {/* <ItemsContainer
    //  onClick={handleQuizView}
     >
   <Book/>
   <Typography text='GRADEBOOK' fontSize='0.75rem' fontWeight='400' lineHeight='normal'/>
   </ItemsContainer> */}
      {student && !forum && !quiz && student.length > 0 && !loading && (
        <ItemsContainer onClick={downloadAllFiles}>
          <Download />
          <Typography text={t('assignment.Download all')} fontSize='0.75rem' fontWeight='400' lineHeight='normal' />
        </ItemsContainer>
      )}
      {allAssignment && forum && allAssignment.AllowAttachment && !loading && (
        <ItemsContainer onClick={downloadAllFiles}>
          <Download />
          <Typography text={t('assignment.Download all')} fontSize='0.75rem' fontWeight='400' lineHeight='normal' />
        </ItemsContainer>
      )}
      {loading && (
        <ItemsContainer>
          <Download />
          <Typography text={`${t('general.loading')}...`} fontSize='0.75rem' fontWeight='400' lineHeight='normal' />
        </ItemsContainer>
      )}

    </NavContainer>
  )
}

export default GarderNavMenu;