import React, { useState, FC, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  generateUUID,
  uuidSplitter,
  auth0ProviderSplitter
} from "../../utils/StringManipulation";
import axios from "axios";
import { Container } from "../../elements/StyledContainerForm/StyledContainerForm.style";
import {
  LightTypography,
  PrimaryFontBoldText,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  LabelDiv,
  BtnsDiv,
  LabelBtnsDiv,
  ButtonsDiv,
  FlexDiv,
  FlexDivInput,
  FlexBetween,
} from "./imageForm.style";
import StyledInput from "../../elements/StyledInput/StyledInput";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { DropZoneForm } from "../../elements/dropZone/DropZoneForm";
import { SvgImagesIcon, SvgClose, SvgAdd } from "../../elements/Icons";
import Toggle from "../../elements/Toogle/toogle";
import TwoDatePicker from "../../elements/TwoDatePicker/TwoDatePicker";
import CancelButton from "../../elements/StyledButton/CancelButton";
import StyledSelect from "../../elements/StyledSelect/StyledSelect";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import { useStateContext } from "../../contexts/ContextProvider";
import { S3Upload } from "../../services/S3Put";
import LoaderThreeDot from "../loader/loaderThreeDot/LoaderThreeDot";
import { ContentFormProps } from "../../interfaces/ContentFormProps";
import isObjectEmpty from "../../utils/IsObjectEmpty";
import { FileInterface } from "../../interfaces/ContentFormProps";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { WebSocketConnector } from "../../Views/messages/webSocketConnection";
import { WebsocketContext } from "../../contexts/notificationContext";

const ImageForm: FC<ContentFormProps> = ({
  topicContents,
  setShow,
  handleSubmit,
  setShowEditContent,
  showEditContent,
  setContentTitleInTopic,
  editContent,
  newContentTitle,
  contentId,
  activeGetModulesWithTopics,
  newTopicRefId,
  mediaName,
  mediaDescription,
  mediaFile
}) => {

  const { t } = useTranslation();
  const classSourceId = useParams();
  const setClassSourceId = classSourceId.id;
  const { isLoading, user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const imgPath = `class/${setClassSourceId}/resources/images`;
  const baseURL = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";
  const path = "resource/create";
  const tenantName = localStorage.getItem("tenant");
  const {
    setContent_Title,
    contentType,
    setContent_Data,
    getContent_Data,
    contentIdToEdit,
    topicRefId,
    content_Index,
    setIsContentDrop,
    setIsPopUpOpen,
  } = useStateContext();

  const [prevData, setPrevData] = useState<any>();
  const [prevDataAttachments, setPrevDataAttachments] = useState<any>();
  const [readyToEdit, setReadyToEdit] = useState(true);
  const [foundRefIdState, setFoundRefIdState] = useState<number>();
  const [visibleStudents, setVisibleStudents] = useState(true);
  const [showTimeVisibility, setShowTimeVisibility] = useState(false);
  const [isDownloadable, setIsDownloadable] = useState(false);
  const [altText, setAltText] = useState("");
  const [imageName, setImageName] = useState("");
  const [description, setDescription] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [error, setError] = useState(false);
  const [firstError, setFirstError] = useState(true);
  const [newFile, setNewFile] = useState<FileInterface>({ path: "" });
  const [isFormValid, setFormValid] = useState(false);
  const [fileUUId, setFileUUId] = useState("");
  const [readyToPost, setReadyToPost] = useState(true);
  const [isSetLoading, setIsSetLoading] = useState(false);

  const { isReady, value, send } = useContext(WebsocketContext);

  useEffect(()=>{
    setImageName(mediaName ? mediaName : "")
    setDescription(mediaDescription ? mediaDescription : "")
  }
  ,[mediaName, mediaDescription])

  // Function to update form
  const updateImage = async (
    path: string,
    body: any,
    imageId: number | undefined
  ) => {
    try {
      const response = await axios.put(
        `${baseURL}/${path}/${imageId}/${tenantName}`,
        { data: body }
      );
      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
      throw error; // Rethrow the error to be handled by the calling service
    }
  };

  // Function to get data
  const getImageToEdit = async (path: string, imageId: number) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `${baseURL}/${path}/${imageId}/${tenantName}`
      );
      setPrevData(res.data.data);
      const fileNameResolved = uuidSplitter(
        res.data.data.path?.split("images/")[1]
      );
      setPrevDataAttachments(fileNameResolved);
      setReadyToEdit(false);
      const toReturn: any[] = res.data.data;
      return toReturn;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (showEditContent && imageName !== "") {
      setReadyToEdit(false);
    }
  }, [imageName]);

  // This useEffect hook is used to fetch data when showEditContent is true.
  useEffect(() => {
    const fetchData = async () => {
      const yourArray = await activeGetModulesWithTopics();
      const solutionArray: any[] = Array.isArray(yourArray) ? yourArray : [];
      function findRefIdInModules(
        modulesArray: any[],
        targetContentId: number
      ) {
        for (let i = 0; i < modulesArray.length; i++) {
          const topicsArray = modulesArray[i].topicsArray;

          for (let j = 0; j < topicsArray.length; j++) {
            const contentsArray = topicsArray[j].contents;

            for (let k = 0; k < contentsArray.length; k++) {
              if (contentsArray[k].contentId === targetContentId) {
                return contentsArray[k].refId;
              }
            }
          }
        }
        // Return a default value (e.g., null) if the contentId is not found
        return null;
      }
      if (contentIdToEdit) {
        const contentIdToFind = contentIdToEdit; // Replace with the specific contentId you're looking for
        const foundRefId = findRefIdInModules(solutionArray, contentIdToFind);
        setFoundRefIdState(foundRefId);

        await getImageToEdit("resource/image", foundRefId);
      }
    };
    if (showEditContent) {
      fetchData();
      // setReadyToEdit(false);
    }
  }, []);

  // This useEffect hook is used to update the states with edit data (prevData)
  useEffect(() => {
    if (prevData && showEditContent) {
      setContent_Title(prevData.title);
      setImageName(prevData.title);
      setAltText(prevData.altText);
      setDescription(prevData.description);
      setVisibleStudents(prevData.isVisible);
      setShowTimeVisibility(
        prevData.visibleFrom === null || prevData.visibleTo === null
          ? false
          : true
      );
      setFromDate(prevData.visibleFrom ? new Date(prevData.visibleFrom) : null);
      setToDate(prevData.visibleTo ? new Date(prevData.visibleTo) : null);
      if (prevData && showEditContent && prevDataAttachments) {
        setNewFile(prevDataAttachments.attachmentUrl);
        // setReadyToEdit(false);
      }
      setIsDownloadable(prevData.downloadable ? true : false);
    }
  }, [prevData, prevDataAttachments]);

  // Function to handle description
  const handleDescriptionChange = (value: any) => {
    setDescription(value);
  };

  useEffect(()=>{
    // function to handle image and s3 upload
    const handleFile = async () => {
      if (!mediaFile) return; // Exit if mediaFile does not exist

      setReadyToPost(false);
      setNewFile(mediaFile);
      const fileNameUUid = `${generateUUID()}${mediaFile.path}`;
      const response = await S3Upload(imgPath, mediaFile.path, fileNameUUid, mediaFile);
      setFileUUId(fileNameUUid);
    };
    handleFile()
  }
  ,[mediaFile])

  // function to handle image and s3 upload
  const handleFile = async (value: any) => {
    setReadyToPost(false);
    setNewFile(value);
    const fileNameUUid = `${generateUUID()}${value.path}`;
    const response = await S3Upload(imgPath, value.path, fileNameUUid, value);
    setFileUUId(fileNameUUid);
  };

  // handle toggle change visiblity for students
  const handleToggleChangeVisible = (isChecked: boolean) => {
    setVisibleStudents(isChecked);
  };

  // handle toggle change for downloadable option
  const handleToggleDownloadable = (isChecked: boolean) => {
    setIsDownloadable(isChecked);
  };

  // function to set time visibility interval
  const handleTwoDatePickerChange = (start: Date | null, end: Date | null) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleToggleChangeTime = (isChecked: boolean) => {
    if (isChecked) {
      setShowTimeVisibility(true); // Show Time Visibility when the toggle is on
    } else {
      setShowTimeVisibility(false);
    }
  };

  useEffect(() => {
    if (fileUUId !== "") {
      setReadyToPost(true);
    }
  }, [fileUUId]);

  useEffect(() => {
    if (
      imageName ||
      description ||
      isObjectEmpty(newFile) === false ||
      (showTimeVisibility && (!fromDate || !toDate))
    ) {
      setFirstError(false);
    }
  }, [imageName, description, newFile, showTimeVisibility, fromDate, toDate]);

  // function to handle validation
  const handleFormValidation = async () => {
    if (
      !imageName ||
      !description ||
      isObjectEmpty(newFile) === true ||
      (showTimeVisibility && (!fromDate || !toDate))
    ) {
      if (!firstError) {
        setError(true);
      }
      setShow(true);
      return false;
    } else {
      setError(false);
      if (contentId !== undefined && newContentTitle !== undefined)
        editContent(contentId, newContentTitle);
      return true;
    }
  };

  useEffect(() => {
    // if (showEditContent && !readyToEdit)
    handleFormValidation();
  }, [imageName, description, newFile, showTimeVisibility, fromDate, toDate]);

  // handle save
  const handleContinueClick = async () => {
    const isValid = await handleFormValidation();
    if (isValid) {
      if (!showEditContent) {
        handleSubmit();
      }
      try {
        let fileToUpdate =
          newFile && newFile.path ? newFile.path : prevDataAttachments;
        // if (readyToPost && fileUUId) {
        const PictureData: any = {
          _id: contentIdToEdit,
          classSourceId: setClassSourceId,
          userSourceId: subId,
          provider:provider,
          contentIndex: content_Index,
          topicRefId: topicRefId,
          type: "Picture",
          title: imageName,
          altText: altText,
          description: description,
          file: fileToUpdate,
          path:
            newFile && newFile.path ? `${imgPath}/${fileUUId}` : prevData.path,
          showTimeVisibility: showTimeVisibility,
          visibleStudents: visibleStudents,
          fromDate: fromDate,
          toDate: toDate,
          downloadable: isDownloadable,
        };

        if (!showEditContent) {
          const response = await axios.post(
            `${baseURL}/${path}/${tenantName}`,
            { data: PictureData }
          );
          if (isReady && send) {

            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "create",
                  title: imageName,
                  contentType: "Picture",
                  refId: response.data.data.resourceId,
                  refTable: "Images",
                  subId: subId,
                  provider:provider,
                  type: "content",
                  description: `Title: ${imageName}`,
                },
              })
            );
          }
          setIsSetLoading(false);
        } else if (showEditContent) {
          await updateImage("resource/image", PictureData, foundRefIdState);
          if (isReady && send) {
            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "update",
                  title: imageName,
                  contentType: "Picture",
                  subId: subId,
                  provider:provider,
                  refId: foundRefIdState,
                  refTable: "Images",
                  type: "content",
                  description: `Title: ${imageName}`,
                },
              })
            );
          }
        }
        setShow(false);
        setIsPopUpOpen(false);
        await activeGetModulesWithTopics();
      } catch (error) {
        console.error("Axios Error:", error);
      } finally {
        await (activeGetModulesWithTopics && activeGetModulesWithTopics());
      }
    }
  };

  // function to set accepted type for image
  const accF: { [key: string]: string[] } = {
    "image/jpeg": [".jpg", ".jpeg"],
    "image/png": [".png"],
    "image/webp": [".webp"],
  };
  const { backgroundColor, themeTextColor, inputBorderColor, iconCloseColor } =
    useRecoilValue(ColorSelector);
  return (
    <Container
      themetextcolor={themeTextColor}
      backgroundcolor={backgroundColor}
      scrollbar={inputBorderColor}
    >
      {/* header */}
      {/* <FlexBetween>
        <FlexDiv>
          <SvgImagesIcon />
          <PrimaryFontBoldText text={t("Image.Image")} fontSize="1.125rem" />
        </FlexDiv> */}
        {showEditContent && readyToEdit && <LoaderThreeDot />}
        {/* <SvgClose
          color={iconCloseColor}
          onClick={() => {
            setShow(false);
            setIsPopUpOpen(false);
            setShowEditContent(false);
          }}
          style={{ cursor: "pointer" }}
        /> */}
      {/* </FlexBetween> */}

      {/* title */}
        <LabelDiv>
          <FlexDiv gap="0.25rem">
            <LightTypography text={t("forms.Title")} fontSize="0.875rem" />
            <span>*</span>
          </FlexDiv>
          <StyledInput
            placeholder={t("Image.Enter Image Title")}
            type="text"
            onChange={(e: any) => {
              setImageName(e.target.value);
              setContent_Title(e.target.value);
              setContentTitleInTopic(e.target.value);
            }}
            value={imageName}
            width="100%"
          />
          {!imageName && error && (
            <ErrorDiv>{`${t("forms.Please")} ${t(
              "Image.Enter Image Title"
            )}`}</ErrorDiv>
          )}
        </LabelDiv>

      {/* description */}
      <LabelDiv>
        <FlexDiv gap="0.25rem">
          <LightTypography text={t("forms.Description")} fontSize="0.875rem" />
          <span>*</span>
        </FlexDiv>
        <TinyEditor
          onChange={handleDescriptionChange}
          placeholder={t("forms.Description")}
          initialValue={`${
            prevData && prevData.description ? prevData.description : description
          }`}
          height="13.25rem"
        />
        {!description && error && (
          <ErrorDiv>{`${t("forms.Please")} ${t(
            "forms.Enter Description"
          )}`}</ErrorDiv>
        )}
      </LabelDiv>

      {/* dropzone */}
      <DropZoneForm
        text={`${t("Image.Click or Drag and Drop, to upload Image")} *`}
        testDrop={handleFile}
        acceptedTypes={accF}
        initialFile={mediaFile ? mediaFile.path : prevDataAttachments}
      />
      {isObjectEmpty(newFile) === true && error && (
        <ErrorDiv>{t("course view.Please Select a Image")}</ErrorDiv>
      )}

      {/* alt text */}
      <LabelDiv>
        <LightTypography text={t("Image.Alt Text")} fontSize="0.875rem" />
        <StyledInput
          placeholder={t("Image.Enter Alt Text")}
          type="text"
          onChange={(e) => setAltText(e.target.value)}
          value={altText}
          width="100%"
        />
      </LabelDiv>

      {/* toggles */}
      <BtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleDownloadable}
            checked={isDownloadable}
            id="isDownloadable"
          />
          <TypographyRegularDescription
            text={t("document.Downloadable")}
            fontSize="1rem"
          />
        </LabelBtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleChangeVisible}
            checked={visibleStudents}
            id="visibleStudents"
          />
          <TypographyRegularDescription
            text={t("forms.Make Visible for Students")}
            fontSize="1rem"
          />
        </LabelBtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleChangeTime}
            checked={showTimeVisibility}
            id="showTimeVisibility"
          />
          <TypographyRegularDescription
            text={t("forms.Set Time Visibility Interval")}
            fontSize="1rem"
          />
          {showTimeVisibility && (
            <>
              <TwoDatePicker
                onDateChange={handleTwoDatePickerChange}
                prevStartDate={fromDate}
                prevEndDate={toDate}
              />
              {(!fromDate || !toDate) && error && (
                <ErrorDiv>{`${t("forms.Please")} ${t(
                  "forms.Set Time Visibility Interval"
                )}`}</ErrorDiv>
              )}
            </>
          )}
        </LabelBtnsDiv>
      </BtnsDiv>

      {/* actions buttons */}
      <ButtonsDiv>
        <CancelButton
          name={t("forms.Cancel")}
          onClickFunction={() => {
            setShow(false);
            setIsPopUpOpen(false);
            setShowEditContent(false);
          }}
          color="#D85D66"
          borderColor="#D85D66"
          // backgroundColor="#384450"
        />
        {readyToPost ? (
          <CancelButton
            name={t("forms.Save")}
            onClickFunction={async () => {
              if (!error) {
                await setIsSetLoading(true);
                setShowEditContent(false);
                await handleContinueClick();
                setContent_Title(imageName);
              }
            }}
            disabled={isSetLoading}
            color="#fff"
            borderColor="#5DD3B3"
            backgroundColor="#5DD3B3"
          />
        ) : (
          <CancelButton
            name="Loading"
            display="block"
            onClickFunction={() => {
              handleFormValidation();
              setContent_Title(imageName);
            }}
            color="#fff"
            borderColor="#5DD3B3"
            backgroundColor="#5DD3B3"
          />
        )}
      </ButtonsDiv>
    </Container>
  );
};

export default ImageForm;
