import React, { useState, FC, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../utils/StringManipulation";
import axios from "axios";
import { Container } from "../../elements/StyledContainerForm/StyledContainerForm.style";
import {
  LightTypography,
  PrimaryFontBoldText,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  LabelDiv,
  BtnsDiv,
  LabelBtnsDiv,
  ButtonsDiv,
  DateAndTimeDiv,
  FlexDiv,
  FlexBetween,
} from "./TeamsForm.style";
import StyledInput from "../../elements/StyledInput/StyledInput";
import { SvgTeams, SvgClose } from "../../elements/Icons";
import Toggle from "../../elements/Toogle/toogle";
import TwoDatePicker from "../../elements/TwoDatePicker/TwoDatePicker";
import CancelButton from "../../elements/StyledButton/CancelButton";
import SelectDate from "../../elements/DatePicker/inputDate";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import { useStateContext } from "../../contexts/ContextProvider";
import LoaderThreeDot from "../loader/loaderThreeDot/LoaderThreeDot";
import { ContentFormProps } from "../../interfaces/ContentFormProps";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { CancelButtonStyle } from "../AssignmentForm/AssignmentForm.style";
import { useTranslation } from "react-i18next";
import { get } from "../../utils/AxiosRequests";
import { toast } from "react-toastify";
import { WebsocketContext } from "../../contexts/notificationContext";

const TeamsForm: FC<ContentFormProps> = ({
  setShow,
  handleSubmit,
  setShowEditContent,
  showEditContent,
  setContentTitleInTopic,
  editContent,
  newContentTitle,
  contentId,
  activeGetModulesWithTopics,
  newTopicRefId,
}) => {
  const { t } = useTranslation();
  const classSourceId = useParams();
  const setClassSourceId = classSourceId.id;
  const { isLoading, user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const baseURL = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";
  const path = "activity/create";
  const tenantName = localStorage.getItem("tenant");
  const {
    setContent_Title,
    contentID,
    contentType,
    setContent_Data,
    getContent_Data,
    contentData,
    contentIdToEdit,
    topicRefId,
    content_Index,
    setIsPopUpOpen,
  } = useStateContext();

  const [prevData, setPrevData] = useState<any>();
  const [readyToEdit, setReadyToEdit] = useState(true);
  const [foundRefIdState, setFoundRefIdState] = useState<number>();
  const [isSetLoading, setIsSetLoading] = useState(true);
  const [visibleStudents, setVisibleStudents] = useState(true);
  const [showTimeVisibility, setShowTimeVisibility] = useState(false);
  const [teamsName, setTeamsName] = useState("");
  const [description, setDescription] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [error, setError] = useState(false);
  const [sessionId, setSessionId] = useState<number>(0);
  const [calendarId, SetcalendarId] = useState("");
  const [teamLink, SetTeamLink] = useState("");
  const [eventId, SeteventId] = useState("")
  const myEmail = user?.email;
  const [UserMSid, SetUserMSid] = useState("")
  const [participantData, setParticipantData] = useState<any[]>([])
  const { isReady, value, send } = useContext(WebsocketContext);

  const formatDate = (dueDate: any) => {
    const date = new Date(dueDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const fetchParticipantData = async () => {
    try {
      const ParticipantData = await get(
        `participant/class/${setClassSourceId}/${tenantName}`
      );
      // Filter participants based on role
      if (ParticipantData.data.length > 0) {
        setParticipantData(ParticipantData.data);
      }
      setIsSetLoading(false);

    } catch (error) {
      console.error(error);
    }
  };

  const Attendees = participantData.filter(
    (participant) => participant.email !== myEmail
  );


  const getUserId = async () => {
    try {

      const response = await axios.get(
        `https://k9jjuskf3i.execute-api.eu-west-1.amazonaws.com/dev/ms/user/byEmail/${myEmail}/${tenantName}`,

      );
      SetUserMSid(response.data.data)
    } catch (err) {
      console.log(err);
    }
  };

  const getAccessToken = async () => {
    try {
      const response = await axios.get(
        `https://k9jjuskf3i.execute-api.eu-west-1.amazonaws.com/dev/ms/token`,

      );

      localStorage.setItem("MSToken", response.data.data);
      return response.data.data;
    } catch (err) {
      console.log(err);
    }
  };

  const [TeamsId, setTeamsId] = useState("")

  const fetchClassData = async () => {
    try {
      const classData = await get(`about/class/${setClassSourceId}/${tenantName}`);
      if (classData.data.length > 0) {
        setTeamsId(classData.data[0].classStructure.teamsId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // getAccessToken();
    getUserId();
    fetchClassData();
    fetchParticipantData()
  }, []);
  useEffect(() => {
    fetchCalendarId()
  }, [UserMSid])

  const createEvent2 = async (bearerToken: string) => {
    try {
      const response = await axios.post(
        `https://graph.microsoft.com/v1.0/me/calendars/${calendarId}/events`,
        {
          subject: description,
          body: {
            contentType: "HTML",
            content: "HI Please can you join?",
          },
          start: {
            dateTime: startDate,
            timeZone: "Arabian Standard Time",
          },
          end: {
            dateTime: endDate,
            timeZone: "Arabian Standard Time",
          },
          location: {
            displayName: teamsName,
          },
          attendees: [],
          isOnlineMeeting: true,
          onlineMeetingProvider: "teamsForBusiness",
        },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      // Handle the response
      const joinUrl = response.data.onlineMeeting.joinUrl;
      const eventId = response.data.id; // Assuming the event ID is in response.data.id
      return { url: joinUrl, eventId: eventId }; // Return the response data
    } catch (err) {
      console.log(err); // Log any errors
      return { url: null, eventId: null }; // Return nulls in case of an error
    }
  };

  const GetEventId = async (onlineSessionId: number) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `https://k9jjuskf3i.execute-api.eu-west-1.amazonaws.com/dev/teamsOnlineSession/${onlineSessionId}/${subId}/${classSourceId}/${tenantName}?provider=${provider}`
      );
      //https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/activity/onlineSession/${ReceivedActivityId}/${subIdSplitted}/${classId}/${tenantName}

      return res.data.data.online_session.eventId;
    } catch (error) {
      console.log(error);
    }
  };

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const createEvent = async (bearerToken: string) => {
    try {
      const CalendarId = await fetchCalendarId()
      const response = await axios.post(
        `https://graph.microsoft.com/v1.0/users/${UserMSid}/calendars/${CalendarId}/events`,
        {
          subject: teamsName,
          body: {
            contentType: "HTML",
            content: "HI Please can you join?",
          },
          start: {
            dateTime: startDate,
            // timeZone: "Arabian Standard Time",
            timeZone: timeZone,
          },
          end: {
            dateTime: endDate,
            timeZone: timeZone,
          },
          location: {
            displayName: teamsName,
          },
          attendees:
            // {
            //   emailAddress: {
            //     address:"zidyia.student@istinye.edu.tr",

            //   },
            //   type: "required"
            // }
            Attendees.map((attendee) => {
              return (
                {
                  emailAddress: {
                    address: attendee.email,
                  },
                  type: "required"
                }
              )
            })
          ,
          isOnlineMeeting: true,
          onlineMeetingProvider: "teamsForBusiness",
        },
        {
          headers: {
            Authorization: `${bearerToken}`,
          },
        }
      );
      // Handle the response
      const joinUrl = response.data.onlineMeeting.joinUrl;
      const eventId = response.data.id;  // Assuming the event ID is in response.data.id
      return { url: joinUrl, eventId: eventId }; // Return the response data
    } catch (err) {
      console.log(err); // Log any errors
      return { url: null, eventId: null }; // Return nulls in case of an error
    }
  };

  const fetchCalendarId = async () => {
    try {
      if (UserMSid) {
        const bearerToken = await getAccessToken();
        const response = await axios.get(`https://graph.microsoft.com/v1.0/users/${UserMSid}/calendars`, {
          headers: {
            Authorization: `${bearerToken}`,
          },
        });

        // Assuming you want the primary calendar
        // const primaryCalendar = response.data.value.find((calendar: { name: string; }) => calendar.name === 'Calendar');
        const primaryCalendar = response.data.value[0];

        return primaryCalendar ? primaryCalendar.id : null;
      }
      else {
        return;
      }
    } catch (error) {
      console.error('Error fetching calendar ID:', error);
      throw error;
    }
  };



  // Function to update form
  const updateOnlineSession = async (path: string, body: any) => {
    try {
      const response = await axios.put(`https://k9jjuskf3i.execute-api.eu-west-1.amazonaws.com/dev/teamsOnlineSession/update/${body.id}/${tenantName}`, {
        data: body,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
      throw error; // Rethrow the error to be handled by the calling service
    }
  };

  const UpdateEvent = async (bearerToken: string, EventId: string) => {
    try {
      const CalendarId = await fetchCalendarId()
      const response = await axios.patch(
        `https://graph.microsoft.com/v1.0/users/${UserMSid}/calendars/${CalendarId}/events/${EventId}`,
        {
          subject: teamsName,

          start: {
            dateTime: startDate,
            // timeZone: "Arabian Standard Time",
            timeZone: timeZone,
          },
          end: {
            dateTime: endDate,
            timeZone: timeZone,
          },
          location: {
            displayName: teamsName,
          },
          attendees:
            // {
            //   emailAddress: {
            //     address:"zidyia.student@istinye.edu.tr",

            //   },
            //   type: "required"
            // }
            Attendees.map((attendee) => {
              return (
                {
                  emailAddress: {
                    address: attendee.email,
                  },
                  type: "required"
                }
              )
            })
          ,
          // isOnlineMeeting: true,
          // onlineMeetingProvider: "teamsForBusiness",
        },
        {
          headers: {
            Authorization: `${bearerToken}`,
          },
        }
      );
      const joinUrl = response.data.onlineMeeting.joinUrl;
      const eventId = response.data.id;  // Assuming the event ID is in response.data.id
      return { url: joinUrl, eventId: eventId };
    } catch (err) {
      console.log(calendarId, "calendarId")
      console.log(err); // Log any errors
      // Return the response data // Return nulls in case of an error
    }
  };


  // Function to get data
  const getOnlineSessionToEdit = async (
    path: string,
    onlineSessionId: number
  ) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `https://k9jjuskf3i.execute-api.eu-west-1.amazonaws.com/dev/teamsOnlineSession/${onlineSessionId}/${subId}/${setClassSourceId}/${tenantName}?provider=${provider}`
      );
      //https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/activity/onlineSession/${ReceivedActivityId}/${subIdSplitted}/${classId}/${tenantName}

      setPrevData(res.data.data);
      setReadyToEdit(false);

      const toReturn: any[] = res.data.data;
      return toReturn;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (showEditContent && teamsName !== "") {
      setReadyToEdit(false);
    }
  }, [teamsName]);

  // This useEffect hook is used to fetch data when showEditContent is true.
  useEffect(() => {
    const fetchData = async () => {
      const yourArray = await activeGetModulesWithTopics();
      const solutionArray: any[] = Array.isArray(yourArray) ? yourArray : [];
      function findRefIdInModules(
        modulesArray: any[],
        targetContentId: number
      ) {
        for (let i = 0; i < modulesArray.length; i++) {
          const topicsArray = modulesArray[i].topicsArray;

          for (let j = 0; j < topicsArray.length; j++) {
            const contentsArray = topicsArray[j].contents;

            for (let k = 0; k < contentsArray.length; k++) {
              if (contentsArray[k].contentId === targetContentId) {
                return contentsArray[k].refId;
              }
            }
          }
        }
        // Return a default value (e.g., null) if the contentId is not found
        return null;
      }
      if (contentIdToEdit) {
        const contentIdToFind = contentIdToEdit; // Replace with the specific contentId you're looking for
        const foundRefId = findRefIdInModules(solutionArray, contentIdToFind);
        setFoundRefIdState(foundRefId);
        await getOnlineSessionToEdit("activity/onlineSession", foundRefId);
      }
    };
    if (showEditContent) {
      fetchData();
      // setReadyToEdit(false);
    }
  }, []);

  // This useEffect hook is used to update the states with edit data (prevData)
  useEffect(() => {
    if (prevData && showEditContent) {
      setContent_Title(prevData.session.title);
      setSessionId(prevData.session.id);
      setTeamsName(prevData.session.title);
      setDescription(prevData.online_session.description);
      setStartDate(
        prevData.session.startDateTime
          ? new Date(prevData.session.startDateTime)
          : null
      );
      setEndDate(
        prevData.session.endDateTime
          ? new Date(prevData.session.endDateTime)
          : null
      );
      setVisibleStudents(prevData.online_session.isVisible);
      setShowTimeVisibility(
        prevData.online_session.visibleFrom === null ||
          prevData.online_session.visibileTo === null
          ? false
          : true
      );
      setFromDate(prevData.online_session.visibleFrom ? new Date(prevData.online_session.visibleFrom) : null);
      setToDate(prevData.online_session.visibleTo ? new Date(prevData.online_session.visibleTo) : null);
      SeteventId(prevData.online_session.eventId)
      SetTeamLink(prevData.online_session.link)
    }
  }, [prevData]);

  // Function to handle description
  const handleDescriptionChange = (value: any) => {
    setDescription(value);
  };

  const handleToggleChangeVisible = (isChecked: boolean) => {
    setVisibleStudents(isChecked);
  };

  // Function to handle TwoDatePicker Change
  const handleTwoDatePickerChange = (start: Date | null, end: Date | null) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleToggleChangeTime = (isChecked: boolean) => {
    if (isChecked) {
      setShowTimeVisibility(true); // Show Time Visibility when the toggle is on
    } else {
      setShowTimeVisibility(false);
    }
  };

  const handleStartDate = (startDateTime: Date | null) => {
    setStartDate(startDateTime);
    setFromDate(startDateTime);
  };

  const handleEndDate = (endDateTime: Date | null) => {
    setEndDate(endDateTime);
    setToDate(endDateTime);
  };


  // function to handle validation and save
  const handleContinueClick = async () => {

    if (
      teamsName.trim() === "" ||
      !startDate ||
      !endDate ||
      (showTimeVisibility &&
        (!fromDate || !toDate) &&
        (!startDate || !endDate)) ||
      (startDate && endDate && startDate >= endDate)
    ) {
      setError(true);
    } else {
      setError(false);
      if (contentId !== undefined && newContentTitle !== undefined)
        editContent(contentId, newContentTitle);

      setShowEditContent(false);
      setShow(false);
      setIsPopUpOpen(false);

      if (!showEditContent) {
        handleSubmit();
      }
      // const EventId=await getOnlineSessionToEdit("activity/onlineSession", foundRefIdState)
      const tenantName = localStorage.getItem("tenant");
      // const EventId = await axios.get(
      //   `https://k9jjuskf3i.execute-api.eu-west-1.amazonaws.com/dev/teamsOnlineSession/${foundRefIdState}/${subId}/${setClassSourceId}/${tenantName}?provider=${provider}`
      // );
      // const NewEventId=EventId?.online_session.eventId
      const bearerToken = await getAccessToken();
      const eventDetails = showEditContent
        ? await UpdateEvent(bearerToken || "", eventId)
        : await createEvent(bearerToken || "");

      const teamsData: any = {
        _id: contentIdToEdit,
        id: showEditContent ? sessionId : null,
        classSourceId: setClassSourceId,
        userSourceId: subId,
        provider: provider,
        contentIndex: content_Index,
        topicRefId: newTopicRefId,
        type: contentType,
        title: teamsName,
        description: description,
        startDate: startDate,
        endDate: endDate,
        visibleStudents: visibleStudents,
        showTimeVisibility: showTimeVisibility,
        fromDate: fromDate,
        toDate: toDate,
        teamLink: eventDetails?.url, // Use the URL from createEvent or UpdateEvent
        eventId: eventDetails?.eventId, // Use the event ID from createEvent or UpdateEvent
      };
      try {

        if (!showEditContent && (teamsData.teamLink === "" || teamsData.teamLink === null || teamsData.teamLink === undefined)) {
          toast.error("error creating the meeting")
          return;
        }
        if (!showEditContent) {
          const response = await axios.post(
            `${baseURL}/${path}/${tenantName}`,
            { data: teamsData }
          );
          setIsSetLoading(false);
          if (isReady && send) {

            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "create",
                  title: teamsName,
                  contentType: "Teams",
                  refId: response.data.data,
                  refTable: "TeamsSession",
                  subId: subId,
                  provider:provider,
                  type: "content",
                  description: `Start Date: ${formatDate(startDate)}`,
                },
              })
            );
          }
        } else if (showEditContent) {
          try {
            await updateOnlineSession("activity/onlineSession/update", teamsData);
            if (isReady && send) {
              send(
                JSON.stringify({
                  action: "sendPublic",
                  data: {
                    tenantName: tenantName,
                    classSourceId: setClassSourceId,
                    method: "update",
                    title: teamsName,
                    contentType: "Teams",
                    subId: subId,
                    provider:provider,
                    refId: foundRefIdState,
                    refTable: "TeamsSession",
                    type: "content",
                    description: `Start Date: ${formatDate(startDate)}`,
                  },
                })
              );
            }
            toast.success("Team meeting successfuly updated")
          } catch (err) {
            toast.error("failed to update team meeting")
          }
        }
        // setIsSetLoading(false);
        setShow(false);
        setIsPopUpOpen(false);
        await activeGetModulesWithTopics();
      } catch (error) {
        console.error("Axios Error:", error);

      } finally {
        // setIsSetLoading(false);
        await (activeGetModulesWithTopics && activeGetModulesWithTopics());
      }
    }
  };

  const { backgroundColor, themeTextColor, inputBorderColor, iconCloseColor } =
    useRecoilValue(ColorSelector);
  return (
    <Container
      themetextcolor={themeTextColor}
      backgroundcolor={backgroundColor}
      scrollbar={inputBorderColor}
    >
      {/* Header */}
      {/* <FlexBetween>
        <FlexDiv>
          <SvgTeams />
          <PrimaryFontBoldText
            text={t("forms.MS Teams Call")}
            fontSize="1.125rem"
          />
        </FlexDiv> */}

        {/* <SvgClose
          color={iconCloseColor}
          onClick={() => {
            setShow(false);
            setIsPopUpOpen(false);
            setShowEditContent(false);
          }}
          style={{ cursor: "pointer" }}
        /> */}
      {/* </FlexBetween> */}

      {/* Title */}
      {showEditContent && readyToEdit && <LoaderThreeDot />}
      <LabelDiv>
        <FlexDiv gap="0.25rem">
          <LightTypography text={t("forms.Title")} fontSize="0.875rem" />
          <span>*</span>
        </FlexDiv>
        <StyledInput
          placeholder={t("forms.Enter MS Teams Call Title")}
          type="text"
          onChange={(e: any) => {
            setTeamsName(e.target.value);
            setContent_Title(e.target.value);
            setContentTitleInTopic(e.target.value);
          }}
          value={teamsName}
          width="100%"
        />
        {teamsName.trim() === "" && error && (
          <ErrorDiv>{`${t("forms.Please")} ${t(
            "forms.Enter MS Teams Call Title"
          )}`}</ErrorDiv>
        )}
      </LabelDiv>

      {/* Description */}
      <LabelDiv>
        <LightTypography text={t("forms.Description")} fontSize="0.875rem" />
        <TinyEditor
          onChange={handleDescriptionChange}
          placeholder={t("forms.Description")}
          initialValue={`${prevData && prevData.online_session.description
            ? prevData.online_session.description
            : ""
            }`}
          height="13.25rem"
        />
      </LabelDiv>

      {/* Date and time part */}
      <DateAndTimeDiv>
        <LabelDiv>
          <LightTypography text={t("forms.Start Date")} fontSize="0.875rem" />
          <SelectDate
            value={startDate}
            onChange={handleStartDate}
            width="27.5rem"
          />
        </LabelDiv>

        <LabelDiv>
          <LightTypography
            text={t("online session.End Date")}
            fontSize="0.875rem"
          />
          <SelectDate
            value={endDate}
            onChange={handleEndDate}
            width="27.5rem"
          />
        </LabelDiv>
      </DateAndTimeDiv>
      {startDate && endDate && startDate >= endDate && error && (
        <ErrorDiv>
          {t("course view.End date must be greater than the start date.")}
        </ErrorDiv>
      )}
      {(!startDate || !endDate) && error && (
        <ErrorDiv>{t("course view.Dates are mandatory")}</ErrorDiv>
      )}

      {/* Toggles */}
      <BtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleChangeVisible}
            checked={visibleStudents}
            id="visibleStudents"
          />
          <TypographyRegularDescription
            text={t("forms.Make Visible for Students")}
            fontSize="1rem"
          />
        </LabelBtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleChangeTime}
            checked={showTimeVisibility}
            id="showTimeVisibility"
          />
          <TypographyRegularDescription
            text={t("forms.Set Time Visibility Interval")}
            fontSize="1rem"
          />
          {showTimeVisibility && (
            <>
              <TwoDatePicker
                prevStartDate={fromDate}
                prevEndDate={toDate}
                onDateChange={handleTwoDatePickerChange}
              />
              {(!fromDate || !toDate) && !startDate && !endDate && error && (
                <ErrorDiv>{`${t("forms.Please")} ${t(
                  "forms.Set Time Visibility Interval"
                )}`}</ErrorDiv>
              )}
            </>
          )}
        </LabelBtnsDiv>
      </BtnsDiv>

      {/* Action Buttons */}
      <ButtonsDiv>
        <CancelButtonStyle
          name="Cancel"
          onClick={() => {
            setShow(false);
            setIsPopUpOpen(false);
            setShowEditContent(false);
          }}
        >
          {t("forms.Cancel")}
        </CancelButtonStyle>
        {isSetLoading ?
          <CancelButton
            name={`${t("general.loading")}...`}
            onClickFunction={() => { }}
            color="#fff"
            borderColor="#5DD3B3"
            backgroundColor="#5DD3B3"
          /> :
          <CancelButton
            name={t("forms.Save")}
            onClickFunction={() => {
              handleContinueClick();
              setContent_Title(teamsName);
            }}
            color="#fff"
            borderColor="#5DD3B3"
            backgroundColor="#5DD3B3"
          />}
      </ButtonsDiv>
    </Container>
  );
};

export default TeamsForm;
