import React,{FC} from 'react';
import { theme } from '../../../theme';
import { TypographyRegularDescription,Typography } from '../../../elements/fonts/Fonts';
import { ReactComponent as DeletePartIcon } from '../../../assets/icons/Delete 1 copy.svg'
import { ReactComponent as EditPartIcon } from '../../../assets/icons/pen_1.svg'
import {IconContainer, IconPointsContainer, QuestionFlexContainer, PartContainer } from './QuestionComposed.style'
import { QuestionComposedProps } from './QuestionComposedInterface';
 const QuestionComposed:FC<QuestionComposedProps> = ({question,setPartIndex,setIndex,index,handleShowEditQuest,handleShowQuest,setDeletePart,disableClick}) => {
    
    return( 
    <>{
                question?.questPart?.map((quest, pindex) => (
            <IconPointsContainer key={pindex}>
                {quest.icon}
                <QuestionFlexContainer>
                    {quest.type ?
                        <IconContainer >

                            {/* <PartContainer onClick={() => { setPartIndex(pindex); setIndex(index);handleShowQuest() }}> */}
                            <PartContainer>
                                <TypographyRegularDescription
                                    text={`Part ${String.fromCharCode(65 + pindex)}`}
                                    fontSize='0.75rem'
                                    lineHeight='1.125rem'
                                    color={theme.dark.primary_text_color}
                                />
                                <TypographyRegularDescription
                                    text={quest.type}
                                    fontSize='0.75rem'
                                    lineHeight='1.125rem'
                                    color='rgba(255, 255, 255, 0.50)'
                                />
                            </PartContainer>
                            <PartContainer>
                               
                                    <EditPartIcon onClick={()=>{handleShowEditQuest();setIndex(index);setPartIndex(pindex)}}/>
                                    <DeletePartIcon onClick={()=>{setDeletePart(true);setIndex(index);setPartIndex(pindex)}}/>
                                   
                                    
                               
                                {/* <Typography text={`${quest?.grade} pts`} fontSize='0.75rem' color={theme.dark.main_color} /> */}


                            </PartContainer>
                        </IconContainer>:''
                         
                    } 
                    <TypographyRegularDescription
                        text={quest?.name}
                        fontSize='0.75rem'
                        lineHeight='1.125rem'
                        color='rgba(255, 255, 255, 0.50)'
                    />
                </QuestionFlexContainer>
            </IconPointsContainer>

        ))
    }
    
    </>
    )
}
export default QuestionComposed;