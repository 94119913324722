// GroupGreator.tsx

import React, { useContext, useEffect, useRef, useState } from "react";
import { ContactMessagesLayout } from "../ContactMessages.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import { ShowForm } from "../../../Views/messages/Messages.style";
import {
  GCAllInfo,
  GCAllUsersRender,
  GCAllUsersRenderCell,
  GCBackAction,
  GCChosenAction,
  GCChosenContact,
  GCChosenContactName,
  GCChosenContactProfilePic,
  GCChosenContainer,
  GCCricleAction,
  GCLineAction,
  GCSearchItem,
  GCTitleRow,
} from "./GroupGreator.style";
import SearchElement from "../../../elements/searchElement/SearchElement";
import { SvgCloseIcon, SvgPrevNext } from "../../../elements/Icons";
// import { UserFriends } from "../ContactMessagesData";
import MessamessageSenderInfoOnlyImgeSenderInfo from "../../../elements/messageSenderInfo/messageSenderInfoOnlyImg";
import {
  CheckboxLabel,
  SeletectOptinToogle,
} from "../../ProfileCheckBoxOptions/NotificationSetting.style";
import AceptCreation from "./AceptCreation/AceptCreation";
import { useRecoilValue } from "recoil";
import {
  ChatsColorSelector,
  ColorSelector,
} from "../../../recoil/ThemeSelectors";
import { useAuth0 } from "@auth0/auth0-react";
import { get } from "../../../utils/AxiosRequests";
import { useParams } from "react-router-dom";
import { WebSocketConnector } from "../../../Views/messages/webSocketConnection";
import AcceptCreation from "./AceptCreation/AceptCreation";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";

interface GroupCreatorInterface {
  setShowCreateGroup: React.Dispatch<React.SetStateAction<boolean>>;
  fetchAllConv?: any;
  SetCreatedGroup: React.Dispatch<React.SetStateAction<boolean>>;
}

interface UserFriend {
  id: string;
  userName: string;
  profile: string;
}

const GroupCreator: React.FC<GroupCreatorInterface> = ({
  setShowCreateGroup,
  fetchAllConv,
  SetCreatedGroup,
}) => {
  const [ShowGoupContainer, setShowGoupContainer] = useState(true);
  const [searchText, setSearchText] = useState<string>("");
  const [chosenContacts, setChosenContacts] = useState<UserFriend[]>([]);
  const [Users, setUsers] = useState<any[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const tenantName = localStorage.getItem("tenant");
  const UrlId = useParams();
  const classId = UrlId.classId;
  const image = require("../../../assets/userProfile.png");
  const { user, getAccessTokenSilently } = useAuth0();
  const myEmail = user?.email?.toLowerCase();
  const connector = new WebSocketConnector();
  const webSocket = useRef(connector);
  const [mySourceId, setMySourceId] = useState<string>("");
  const { t } = useTranslation();

  const handleGoPrev = () => {
    setShowCreateGroup(false);
  };

  const handleGoNext = () => {
    if (chosenContacts.length > 0) {
      setShowGoupContainer(false);
    }
  };

  const handleCheckboxChange = (friendId: string) => {
    const existingContact = chosenContacts.find(
      (contact) => contact.id === friendId
    );

    if (existingContact) {
      // If the contact is already chosen, remove it
      const updatedContacts = chosenContacts.filter(
        (contact) => contact.id !== friendId
      );
      setChosenContacts(updatedContacts);
    } else {
      // If the contact is not chosen, add it to the array
      const friendToAdd = Users.find((friend) => friend.id === friendId);
      if (friendToAdd) {
        setChosenContacts([...chosenContacts, friendToAdd]);
      }
    }
  };

  const handleRemoveContact = (friendId: string) => {
    const updatedContacts = chosenContacts.filter(
      (contact) => contact.id !== friendId
    );
    setChosenContacts(updatedContacts);

    // Uncheck the corresponding checkbox
    const checkbox = document.getElementById(friendId.toString());
    if (checkbox) {
      (checkbox as HTMLInputElement).checked = false;
    }
  };
  const { backgroundColor } = useRecoilValue(ColorSelector);

  const fetchAllUsersData = async () => {
    try {
      const response = await get(`participant/class/${classId}/${tenantName}`);
      setDataLoading(false);
      const filteredData = response.data.filter(
        (user: any) => user.email.toLowerCase() !== myEmail
      );
      const modifiedData = filteredData.map((user: any) => ({
        ...user,
        id: user.sourceId,
      }));
      setUsers(modifiedData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllUsersData();
  }, []);
  const { ContactCardBgColor, GroupSelectedFriend } =
    useRecoilValue(ChatsColorSelector);
  const { checkboxBgColor, checkboxBorderColor, checkboxBorderRadius } =
    useRecoilValue(ColorSelector);

  return (
    <>
      {ShowGoupContainer ? (
        <ContactMessagesLayout
          bgcolor={backgroundColor}
          style={{
            paddingBottom: "1.25rem",
            gap: "1.25rem",
            minHeight: "35rem",
          }}
        >
          <GCTitleRow>
            <GCBackAction onClick={handleGoPrev}>
              <SvgPrevNext />
            </GCBackAction>
            <Typography
              text={t("Chat.Add Group Members")}
              fontSize="1.125rem"
            />
          </GCTitleRow>

          <ShowForm style={{ gap: "1.25rem" }}>
            <GCSearchItem>
              <SearchElement
                onChange={(e) => setSearchText(e.target.value)}
                leftPositionIcon="1.2rem"
                paddingLeftIcon="1.25rem"
                topPositionIcon="1rem"
              />
            </GCSearchItem>

            <GCChosenContainer>
              {chosenContacts.map((contact: any) => (
                <GCChosenContact key={contact.id} bgColor={GroupSelectedFriend}>
                  <GCChosenContactProfilePic
                    src={
                      contact.profilePicture ? contact.profilePicture : image
                    }
                  />
                  <GCChosenContactName>
                    <TypographyRegularDescription
                      text={
                        contact.username.slice(0, 6) +
                        (contact.username.length > 6 ? ".." : "")
                      }
                      fontSize="0.5rem"
                    />
                  </GCChosenContactName>
                  <GCChosenAction
                    onClick={() => handleRemoveContact(contact.id)}
                  >
                    <SvgCloseIcon width={"0.50rem"} />
                  </GCChosenAction>
                </GCChosenContact>
              ))}
            </GCChosenContainer>

            <GCAllUsersRender>
              {dataLoading ? (
                <LightTypography
                  text={`${t("general.loading")}...`}
                  fontSize="0.75rem"
                />
              ) : (
                Users.map((friend) => (
                  <GCAllUsersRenderCell
                    key={friend.id}
                    bgColor={ContactCardBgColor}
                  >
                    <SeletectOptinToogle
                      bgColor={checkboxBgColor}
                      borderColor={checkboxBorderColor}
                      borderRadius={checkboxBorderRadius}
                      type="checkbox"
                      id={friend.id.toString()}
                      value={friend.id}
                      onChange={() => handleCheckboxChange(friend.id)}
                      checked={chosenContacts.some(
                        (contact) => contact.id === friend.id
                      )}
                    />
                    <CheckboxLabel
                      bgColor={checkboxBgColor}
                      borderColor={checkboxBorderColor}
                      borderRadius={checkboxBorderRadius}
                      htmlFor={friend.id.toString()}
                      style={{ marginTop: "0.4rem" }}
                    >
                      <GCAllInfo>
                        <MessamessageSenderInfoOnlyImgeSenderInfo
                          profileImg={
                            friend.profilePicture
                              ? friend.profilePicture
                              : image
                          }
                        />
                        <MediumTypography
                          text={friend.username}
                          fontSize="0.875rem"
                        />
                      </GCAllInfo>
                    </CheckboxLabel>
                  </GCAllUsersRenderCell>
                ))
              )}
            </GCAllUsersRender>

            <GCLineAction>
              <GCCricleAction onClick={handleGoNext}>
                <SvgPrevNext color="#fff" />
              </GCCricleAction>
            </GCLineAction>
          </ShowForm>
        </ContactMessagesLayout>
      ) : (
        <AcceptCreation
          setShowGoupContainer={setShowGoupContainer}
          chosenContacts={chosenContacts}
          fetchAllConv={fetchAllConv}
          setShowCreateGroup={setShowCreateGroup}
          SetCreatedGroup={SetCreatedGroup}
        />
      )}
    </>
  );
};

export default GroupCreator;
