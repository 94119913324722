import styled from "styled-components";

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 0.625rem;
  background: rgba(255, 255, 255, 0.03);
  padding: 1.25rem;
  width: 100%;
`;
export const RightActionsHolder = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.25rem;
  color: #5dd3b3;
`;
export const ActionHolder = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  column-gap: 0.62rem;
`;
