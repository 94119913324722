import React from "react";
import { GradeItemDetail, GradingDeatils } from "../AssignmentInstructor.style";
import {
  SvgGrade,
  SvgStar,
  SvgSimpleArrow,
  SvgGradesBannerIcon,
} from "../../../elements/Icons";
import { LightTypography, Typography } from "../../../elements/fonts/Fonts";
import { useTranslation } from "react-i18next";
import { toArabicDigits } from "../../../utils/formatDayArabic";

interface AssignmentGradesDetailsProps {
  minimumGrade: any;
  average: any;
  maximumGrade: any;
  gradedCount: any;
  AssignmentsData: any[];
  loading: boolean;
}

const AssignmentGradesDetails = ({
  minimumGrade,
  maximumGrade,
  average,
  gradedCount,
  AssignmentsData,
  loading,
}: AssignmentGradesDetailsProps) => {
  const { t } = useTranslation();
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography text={`${t("general.loading")}...`} />
      </div>
    );
  }
  return (
    <GradingDeatils>
      <GradeItemDetail>
        <SvgSimpleArrow style={{ transform: "rotate(90deg)" }} />
        {/* Minimum Grade between the students goes Here */}
        <LightTypography
          text={t("assignment.Minimum Grade")}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
        <Typography
          text={toArabicDigits(minimumGrade.toString())}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
      </GradeItemDetail>
      <GradeItemDetail>
        <SvgGrade />
        {/* Class Average goes Here */}
        <LightTypography
          text={t("assignment.Class Average")}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
        <Typography
          text={toArabicDigits(average.toString())}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
      </GradeItemDetail>{" "}
      <GradeItemDetail>
        <SvgSimpleArrow style={{ transform: "rotate(-90deg)" }} />
        {/* Maximum Grade between the students goes Here */}
        <LightTypography
          text={t("assignment.Maximum grade")}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
        <Typography
          text={toArabicDigits(maximumGrade.toString())}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
      </GradeItemDetail>{" "}
      <GradeItemDetail>
        {/* <SvgStar color="#5dd3b3" /> */}
        <SvgGradesBannerIcon />
        {/*Count of Graded students goes Here */}
        <LightTypography
          text={t("assignment.Graded")}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
        <Typography
          text={`${toArabicDigits(gradedCount.toString())} / ${
            AssignmentsData &&
            AssignmentsData.length > 0 &&
            toArabicDigits(AssignmentsData.length.toString())
          }`}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
      </GradeItemDetail>
    </GradingDeatils>
  );
};

export default AssignmentGradesDetails;
