import { t } from "i18next";
import { toArabicDigits } from "./formatDayArabic";

export const getFormattedDate = (createdAt: string | undefined) => {
  const language = localStorage.getItem("language");
  if (!createdAt) {
    return language === "tr"
      ? "Yükleniyor..."
      : language === "ar"
      ? "...جارٍ التحميل"
      : language === "ar"
      ? ""
      : "Chargement...";
  }

  const date = new Date(createdAt);
  if (isNaN(date.getTime())) {
    return "Invalid date";
  }

  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  const formatter = new Intl.DateTimeFormat(
    language === "tr"
      ? "tr-TR"
      : language === "ar"
      ? "ar-AE"
      : language === "fr"
      ? "fr-FR"
      : "en-US",
    options
  );
  const parts = formatter.formatToParts(date);

  let dayName = parts.find((part) => part.type === "weekday")?.value;
  let day = parts.find((part) => part.type === "day")?.value;
  let monthName = parts.find((part) => part.type === "month")?.value;
  let year = parts.find((part) => part.type === "year")?.value;
  let hour = parts.find((part) => part.type === "hour")?.value;
  let minute = parts.find((part) => part.type === "minute")?.value;
  let second = parts.find((part) => part.type === "second")?.value;
  let dayPeriod = parts.find((part) => part.type === "dayPeriod")?.value;

  // Function to convert Arabic numerals to Eastern Arabic numerals
  const convertToArabicNumerals = (numStr: any) => {
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return numStr.replace(/\d/g, (digit: any) => arabicNumerals[digit]);
  };

  if (language === "ar") {
    day = convertToArabicNumerals(day);
    year = convertToArabicNumerals(year);
    hour = convertToArabicNumerals(hour);
    minute = convertToArabicNumerals(minute);
    second = convertToArabicNumerals(second);
  }

  const formattedDate = `${monthName} ${day}, ${year} | ${hour}:${minute} ${dayPeriod}`;
  return formattedDate;
};

// used for time like "12:00 AM"
export const getFormattedTime = (time: string): string => {
  // Split time to extract the period (AM/PM)
  const [timePart, period] = time.split(" ");

  // Return the time with the translated period
  return `${toArabicDigits(timePart)} ${t(`notification.${period}`)}`;
};

export const getFormattedTimeTwentyFourHour = (time: string): string => {
  // Split the time string into hours and minutes
  const [hoursStr, minutesStr] = time.split(":");
  let hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);

  // Determine AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12 || 12; // Convert '0' to '12' for midnight (12:00 AM)

  // Format time to 'hh:mm' format
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;

  // Return the time with Arabic digits (if needed) and the translated period
  return `${toArabicDigits(formattedTime)} ${t(`notification.${period}`)}`;
};
