import styled from "styled-components";

interface BlueButtonProps {
  width?: string;
  height?: string;
  padding?: string;
  BorderColor?:string;
  BKGColor?:string;
  Color?:string;
}

const defaultWidth = "20.6875rem";
const defaultHeight = "2.8125rem";
const defaultPadding = "0";
const defaultBkColor = "linear-gradient(136deg, #28EDB3 0%, #24DEE3 100%)";
const Boreder = "none";
const Color = "#FFF";


export const BlueButtonStyle = styled.button<BlueButtonProps>`
  width: ${(props) => props.width || defaultWidth};
  height: ${(props) => props.height || defaultHeight};
  padding: ${(props) => props.padding || defaultPadding};
  border: ${(props) => props.BorderColor || Boreder};
  border-radius: 62.5rem;
  background: ${(props) => props.BKGColor || defaultBkColor} ;
  /* border: none; */
  color: ${(props) => props.Color || Color};
  cursor: pointer;
`;
