import React from "react";
import { LightTypography, Typography } from "../fonts/Fonts";
import { capitalizeFirstLetter } from "../../utils/StringManipulation";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

interface DashboardTitleProps {
  text: string;
  fSize?: number | 1.125;
  color?: string;
}

export const DashboardTitle = ({ text, fSize, color }: DashboardTitleProps) => {
  // const text = t('dashboard.classes in progress');
  const lastSpaceIndex = text.lastIndexOf(" ");
  const firstWord = lastSpaceIndex >= 0 ? text.slice(0, lastSpaceIndex) : "";
  const secondWord =
    lastSpaceIndex >= 0 ? text.slice(lastSpaceIndex + 1) : text;
  // const { specialColor2 } = useRecoilValue(ColorSelector);
  return (
    <div style={{ display: "flex", gap: "0.25rem" }}>
      <LightTypography
        text={capitalizeFirstLetter(firstWord)}
        fontSize={`${fSize}rem `}
      />

      <Typography text={capitalizeFirstLetter(secondWord)} />
    </div>
  );
};
