import styled from "styled-components";
interface Styles {
  NormalWidth?: string;
MinWidth?: string;
}

export const AQSupperMainContainer = styled.div`
  width: 100%;
  display:flex;
  flex-direction:column;
  border-radius: 1.25rem 0rem 0rem 1.25rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
margin-bottom:3rem;
padding-bottom:1rem;

`;

export const AnalyticsQuizzesMainContainer = styled.div`
  width: 100%;
  display:flex;
  /* border-radius: 1.25rem 0rem 0rem 1.25rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25); */
`;


export const LineDivArrowsWrapper = styled.div`
  width: 100%;
  display:flex;
  justify-content:right;
  padding: 0 10rem 5rem 0;

`;

export const AnalyticsQuizzesScrollerContainer = styled.div`
  width: 100%;
  display:flex;
`;
export const AQColumn = styled.div`
  width: 100%;
  display:flex;
  flex-direction: column;
  gap:3rem;
  border-radius: 1.25rem 0rem 0rem 1.25rem;
`;

export const AnalyticsQuizzesTable  = styled.table`
  width: 100%;
  display:flex;
  flex-direction: column;
min-height:20rem;
`;


export const AQTableThead = styled.thead`
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  align-items: left;
  /* gap: 6.5rem; */
  border: none;
  white-space: nowrap;

position: relative;

`;

// export const DivArrowsWrapper = styled.thead`
//   display: flex;
//   justify-content: space-between;
//   /* width: 100%; */
//   align-items: center;
//   /* gap: 6.5rem; */
//   border: none;
//   white-space: nowrap;
//   opacity: 0.5;
// background: var(--Grey-Gradient, linear-gradient(90deg, #415160 0%, #293440 107.88%));
// position: relative;
// &:nth-child(3){
//       width:12rem;
//     }
// `;

export const AQTableCol = styled.thead`
  display: flex;
  flex-direction:column;
`;

export const AQSvgMoreType  = styled.div`
display: flex;
padding: 0 0.5rem;
cursor:pointer;
`;

export const AQTableTh = styled.th`
  display: flex;
  flex-direction: row;
  width: 58%;
align-self: center;
  padding: 0 2.5rem 0rem 1rem;
  /* gap: 25.5rem; */
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
  border-left: 1px solid #576371;
    min-width:16rem;
  /* text-align:left;   */
  justify-content:space-between;

`;





export const AQTableTbody = styled.tbody`
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
  display: flex;
  flex-direction:column ;
`;


export const AQTableTr = styled.tr`
  width: max-content;
  &:nth-child(odd) {
    background: rgba(255, 255, 255, 0.03);
  /* border: none; */
  }
`;

export const AQHeadTableTr = styled.tr`
  width: max-content;
  display: flex;
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
  align-items:center;

`;
export const AQTableThDetails = styled.th`
  height: 2.7rem;
  border-collapse: collapse;
  /* padding: 0rem 1rem 0rem 2rem; */
  padding: 0 2.5rem 0rem 1rem;
  display: flex;
  justify-content:left;
  align-items:center;
  width:16rem;

  &:nth-child(3){
      width:10rem;
      /* background-color:blue */
    }

`;

export const AQTableTd1 = styled.td`
  border: none;
height: 3.5625rem;
  border-collapse: collapse;
  /* padding: 0rem 1rem 0rem 2rem; */
  padding: 0 2.5rem 0rem 1rem;


  width:16rem;
    &:nth-child(3){
      width:10rem;
    }



/* 
  color: ${({ color }) => color}; */
`;

export const AQStudentCol = styled.div<Styles>`
  display: flex;
flex-direction:column;
min-width:${({ MinWidth }) => MinWidth || "12rem"};;
width:fit-content;
min-height:20rem;
width: ${({ NormalWidth }) => NormalWidth || "fit-content"};
/* background-color:blue; */
`;
export const AQStudentCells = styled.div`
  display: flex;
word-break:break-word;


height: 3.1rem;
  border-collapse: collapse;
  padding: 0 2.5rem 0rem 1rem;
  display: flex;
  justify-content:left;
  align-items:center;
  height: 3.55rem;
  border-collapse: collapse;
  padding: 0 2.5rem 0rem 1rem;
/* background-color:red; */
  &:nth-child(odd){
    background: rgba(255, 255, 255, 0.03);
  }
  &:nth-child(1){
    background-color:#3a4957;
    height: 3.33rem;
    padding: 0 2.5rem 0rem 1rem;
    /* background-color:red; */
  }

  &:nth-child(2){

    height: 2.7rem;
    /* background-color:blue; */
  }



`;

export const AqFirstRow = styled.div`
  width: 100%;
  display:flex;
padding:2rem;
justify-content:space-between;

`;

export const AqMiniCol = styled.div`
  /* width: 100%; */
  display:flex;
gap:1.30rem;
flex-direction:column;
margin-right:2rem;


`;

export const AqMINIRow = styled.div`
  
  display:flex;
  justify-content:flex-end;



`;