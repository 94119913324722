import React, { useEffect, useState } from "react";
import { FlexAppContainer } from "../../../../../App.style";
import {
  LightTypography,
  Typography,
  TypographyRegularDescription,
} from "../../../../../elements/fonts/Fonts";
import { CompetencyFrameworksInterface } from "../../ComopetencyFrameworksData/CompetencyFrameworksInterface";
import {
  CompetencyFrameworksHeaderRow,
  CompetencyFrameworksMoreOptionContainer,
  CompetencyFrameworksObjectContainer,
  CompetencyFrameworksSelectedContainer,
  CompetencyFrameworksTableActivityCell,
  CompetencyFrameworksTableActivityCols,
  CompetencyFrameworksTableActivityRow,
  CompetencyFrameworksTableBodyCell,
  CompetencyFrameworksTableBodyRow,
  CompetencyFrameworksTableCell,
  CompetencyFrameworksTableHeaderRow,
} from "./CompetencyFrameworks.styled";
import {
  SvgMore,
  SvgPieCharts,
  SvgPolygonDown,
  SvgVectorBrain,
} from "../../../../../elements/Icons";
import { PointerContainer } from "../../../../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableTable.style";
import { CompetencyHeaderData } from "./CompetencyHeaderData";
import { SelectedOptionDataInterface } from "../../../../../elements/SelectedOptions/SelectedOptionDataInterface";
import SelectedOptions from "../../../../../elements/SelectedOptions/SelectedOptions";
import { handleShowSelectedIndex } from "../../../../../utils/SaveSelectedIndex";
import MappedIndexClickOutSide from "../../../../../hooks/MapedIndexClickOutSide";

import CompetencyPopUp from "../../../CompetencyPopUp/CompetencyPopUp";
import PopUp from "../../../../../elements/Popup/PopUp";
import PopupDelete from "../../../../../elements/PopupDelete/PopupDelete";
import { getColorForLevel } from "./function";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { TableColorSelector } from "../../../../../recoil/ThemeSelectors";

interface CompetencyFrameworksTableInterface {
  Data: CompetencyFrameworksInterface[];
  FilteredText: string;
  TextSearch: string;
}

const CompetencyFrameworksTable = ({
  Data,
  FilteredText,
  TextSearch,
}: CompetencyFrameworksTableInterface) => {
  // Filter the data based on the TextSearch
  const FilteredData = TextSearch
    ? Data.filter((item) =>
        item.studentName.toLowerCase().includes(TextSearch.toLowerCase())
      )
    : Data;

  // Filter activities based on the TextSearch
  const FilterActivity = FilteredText
    ? FilteredData.map((student) =>
        student.competencies?.map((competency) =>
          competency.Activities?.filter(
            (activity) =>
              activity.ProficiencyLevel &&
              activity.ProficiencyLevel.toLowerCase().includes(
                FilteredText.toLowerCase()
              )
          )
        )
      )
    : FilteredData.map((student) =>
        student.competencies?.map((competency) => competency.Activities)
      );
  const [ShowEditPopUp, setShowEditPopUp] = useState<boolean>(false);
  const [ShowDeletePopUp, setShowDeletePopUp] = useState<boolean>(false);
  const [showDetailsOfSelectedIndex, setShowDetailsOfSelectedIndex] = useState<
    number | null
  >(null);

  const [showMenuOfSelectedIndex, setShowMenuOfSelectedIndex] = useState<
    number | null
  >(null);
  const [CompetenciesData, setStoreCompetencies] = useState<any>();

  /////////////
  const [CompetencyName, setCompetencyName] = useState<string>("");

  const [ErrorCompetencyName, setErrorCompetencyName] = useState("");

  const [description, setDescription] = useState("");
  const [StudentVisibility, setStudentVisibility] = useState(false);
  const [activityForms, setActivityForms] = useState([
    {
      ActivityNames: "",
      RoleValue: "",
      PoorValue: "",
      IntermediateValue: "",
      AdvancedValue: "",
    },
  ]);

  const handleStoreCompetenciesObject = (competencies: any) => {
    setStoreCompetencies && setStoreCompetencies(competencies);
    setShowEditPopUp(true);
    setShowMenuOfSelectedIndex(null);
  };

  const handleShowDeletePopUp = (competencies: any) => {
    setStoreCompetencies && setStoreCompetencies(competencies);
    setShowDeletePopUp(true);
    setShowMenuOfSelectedIndex(null);
  };

  const clickOutsideRef = MappedIndexClickOutSide(() =>
    setShowMenuOfSelectedIndex(null)
  );
  useEffect(() => {
    if (CompetenciesData) {
      setCompetencyName(CompetenciesData.CompetencyName || "");
      setDescription(CompetenciesData.CompetencyDescription || "");
      setStudentVisibility(CompetenciesData.StudentsVisibility || false);

      // Transform activities into the format expected by activityForms state
      const transformedActivities =
        CompetenciesData.Activities?.map(
          (activity: {
            activityName: any;
            Role: any;
            PoorRange: any;
            IntermediateRange: any;
            AdvanceRange: any;
          }) => ({
            ActivityNames: activity.activityName || "",
            RoleValue: activity.Role || "",
            PoorValue: activity.PoorRange || "",
            IntermediateValue: activity.IntermediateRange || "",
            AdvancedValue: activity.AdvanceRange || "",
          })
        ) || [];

      setActivityForms(transformedActivities);

    }
  }, [CompetenciesData]);

  const handleUpdateData = () => {
    if (!CompetencyName || CompetencyName.trim() === "") {
      setErrorCompetencyName &&
        setErrorCompetencyName("Competency Name is required ");
      return;
    }

    const DataSaved: any = {
      CompetencyName: CompetencyName,
      Description: description,
      activityForms: activityForms,
      StudentVisibility: StudentVisibility,
    };

  };
  const navigate = useNavigate();
  const handleNavigate = (id: string) => {
    navigate(`/Competency/${id}`);
  };
  const { Table_BgColor, Table_Head_TR, Table_BoxShadow, Table_nth_child } =
    useRecoilValue(TableColorSelector);
  return (
    <FlexAppContainer column={true} gap="1.25rem">
      <Typography text="List Of Students" fontSize="1rem" />
      <FlexAppContainer column={true} gap="0.6rem">
        {FilteredData.map((val, index) => (
          <CompetencyFrameworksObjectContainer
            key={index}
            bgcolor={Table_BgColor}
            boxshadow={Table_BoxShadow}
          >
            {/* Adding text to CompetencyFrameworksHeaderRow */}
            <CompetencyFrameworksHeaderRow
              onClick={() =>
                handleShowSelectedIndex(
                  index,
                  showDetailsOfSelectedIndex,
                  setShowDetailsOfSelectedIndex
                )
              }
            >
              <Typography text={val.studentName} fontSize="1rem" />
              <PointerContainer>
                <SvgPolygonDown
                  style={{
                    transform:
                      showDetailsOfSelectedIndex === index
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    transition: "transform 0.3s ease-in-out",
                  }}
                />
              </PointerContainer>
            </CompetencyFrameworksHeaderRow>
            {showDetailsOfSelectedIndex === index && (
              <FlexAppContainer column={true}>
                <CompetencyFrameworksTableHeaderRow bgcolor={Table_Head_TR}>
                  {CompetencyHeaderData.map((data) => (
                    <CompetencyFrameworksTableCell key={data.id}>
                      <Typography text={data.text} fontSize="0.875rem" />
                    </CompetencyFrameworksTableCell>
                  ))}
                </CompetencyFrameworksTableHeaderRow>
                {val.competencies &&
                  val.competencies.map((data, ink) => (
                    <CompetencyFrameworksTableBodyRow
                      nth_child_bgcolor={Table_nth_child}
                      key={ink}
                    >
                      <CompetencyFrameworksTableBodyCell>
                        <Typography
                          text={data.CompetencyName}
                          fontSize="0.875rem"
                        />
                        <LightTypography
                          text={data.CompetencyDescription}
                          fontSize="0.75rem"
                          lineHeight="1.375rem"
                        />
                      </CompetencyFrameworksTableBodyCell>
                      <CompetencyFrameworksTableActivityCols>
                        {FilterActivity[index]?.[ink]?.map((activity, inx) => (
                          <CompetencyFrameworksTableActivityRow key={inx}>
                            <CompetencyFrameworksTableActivityCell>
                              <TypographyRegularDescription
                                text={activity.activityName || ""}
                                fontSize="0.75rem"
                              />
                            </CompetencyFrameworksTableActivityCell>
                            <CompetencyFrameworksTableActivityCell>
                              <TypographyRegularDescription
                                text={
                                  activity.ProficiencyLevel
                                    ? activity.coverage + "%"
                                    : ""
                                }
                                fontSize="0.75rem"
                                color={getColorForLevel(
                                  activity.ProficiencyLevel || ""
                                )}
                              />
                            </CompetencyFrameworksTableActivityCell>
                            <CompetencyFrameworksTableActivityCell>
                              <TypographyRegularDescription
                                text={activity.ProficiencyLevel || ""}
                                fontSize="0.75rem"
                              />
                            </CompetencyFrameworksTableActivityCell>
                          </CompetencyFrameworksTableActivityRow>
                        ))}
                      </CompetencyFrameworksTableActivityCols>
                      <CompetencyFrameworksMoreOptionContainer>
                        <PointerContainer
                          onClick={() =>
                            handleShowSelectedIndex(
                              ink,
                              showMenuOfSelectedIndex,
                              setShowMenuOfSelectedIndex
                            )
                          }
                        >
                          <SvgMore
                            style={{
                              transform: "rotate(90deg)",
                            }}
                          />
                        </PointerContainer>
                        {showMenuOfSelectedIndex === ink && (
                          <CompetencyFrameworksSelectedContainer
                            ref={clickOutsideRef}
                          >
                            <SelectedOptions
                              width="12rem"
                              FirstText="Edit Competency"
                              FirstLabelClick={() =>
                                handleStoreCompetenciesObject(data)
                              }
                              LastText="Delete Competency"
                              LastLabelClick={() => handleShowDeletePopUp(data)}
                              SecondIcon={SvgPieCharts}
                              SecondText="Coverage"
                              SecondLabelClick={() =>
                                handleNavigate(data.CompetencyId)
                              }
                            />
                          </CompetencyFrameworksSelectedContainer>
                        )}
                      </CompetencyFrameworksMoreOptionContainer>
                    </CompetencyFrameworksTableBodyRow>
                  ))}
              </FlexAppContainer>
            )}
          </CompetencyFrameworksObjectContainer>
        ))}
      </FlexAppContainer>
      {ShowEditPopUp && (
        <PopUp
          content={
            <CompetencyPopUp
              CompetencyName={CompetencyName}
              setCompetencyName={setCompetencyName}
              description={description}
              setDescription={setDescription}
              ErrorCompetencyName={ErrorCompetencyName}
              setErrorCompetencyName={setErrorCompetencyName}
              activityForms={activityForms}
              setActivityForms={setActivityForms}
              StudentVisibility={StudentVisibility}
              setStudentVisibility={setStudentVisibility}
            />
          }
          title="Edit Competency"
          icon={<SvgVectorBrain />}
          showline={false}
          show={ShowEditPopUp}
          setShow={setShowEditPopUp}
          justifyContent="flex-end"
          handleSubmit={handleUpdateData}
        />
      )}

      {ShowDeletePopUp && (
        <PopupDelete
          show={ShowDeletePopUp}
          setShow={setShowDeletePopUp}
          title={`Are you sure you want to delete this ${CompetenciesData.CompetencyName}?`}
          handleSubmit={() => {
            // removeContent(topic, contentId, contentIndex);
          }}
        />
      )}
    </FlexAppContainer>
  );
};

export default CompetencyFrameworksTable;
