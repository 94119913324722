import styled from "styled-components";
import { breakpoints } from "../../../theme";

export const TimeLineTopic = styled.div<{
    bgColor?: string;
    borderColor?: string;
    lin?: boolean;
}>`
      cursor: pointer;
      background: ${(props) => props.bgColor || " #384450"};
      width: 100%;
      padding: 1rem 1rem;
      border-top: 2px solid ${(props) => props.borderColor || "#576371"};
      border-right: 2px solid ${(props) => props.borderColor || "#576371"};
      border-left: 2px solid ${(props) => props.borderColor || "#576371"};
      display: flex;
      flex-direction: column;
      @media (max-width: ${breakpoints.mobile}) {
        border-right: none;
        border-left: none;
        border-top: none;
        padding: 0.5rem 1rem 0.7rem 1rem;
      }
      border-bottom-left-radius:${(props) => props.lin === true ? "0.625rem" : ""};
      border-bottom-right-radius:${(props) => props.lin === true ? "0.625rem" : ""};
    `;

export const TimeLineTopicResources = styled.div<{
    bgColor?: string;
    borderColor?: string;
    lin?: boolean;
}>`
      display: flex;
      flex-direction: column;
      row-gap: 1.25rem;
      padding: 1rem 0 0 0;
      /* border-left: 2px solid ${(props) => props.borderColor || "#576371"};
      border-right: 2px solid ${(props) => props.borderColor || "#576371"}; */
      /* background-color: #384450; */
      /* background-color:blue; */
      background: ${(props) => props.bgColor || "#384450"};
      @media (max-width: ${breakpoints.mobile}) {
        border: none;
      }
      border-bottom-left-radius:${(props) => props.lin === true ? "0.625rem" : ""};
      border-bottom-right-radius:${(props) => props.lin === true ? "0.625rem" : ""};
    `;

export const TimeLineTopicTop = styled.div`
display: flex;
justify-content: space-between;
flex-direction:column;
height:2.53463rem;
@media (max-width: ${breakpoints.mobile}) {
  border-top: 1px solid #576474;
  padding-top: 1rem;
  align-items: center;}
`
export const TimeLineTopicTopTile = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
word-wrap: nowrap;

  /* margin-bottom: -0.5rem; */
  /* border-bottom: 1px solid white; */

`;

export const NoTopicAvailable = styled.div<{
    bgColor?: string;
    borderColor?: string;
}>`
    padding-top: 1rem;
  
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 10rem;
    gap: 1rem;
    align-items: center;
    /* border-radius: 0.625rem !important; */
    /* background-color: #384450; */
    background: ${(props) => props.bgColor || "#384450"};
    /* border-left: 2px solid ${(props) => props.borderColor || "#576371"};
    border-right: 2px solid ${(props) => props.borderColor || "#576371"}; */
    @media (max-width: ${breakpoints.mobile}) {
      border: none;
      margin-top: -0.9rem;
    }
  `;