import styled from "styled-components";
import { theme } from "../../../../theme";

export const CommentContainer = styled.div<{ showrating: string }>`
  display: flex;
  flex-direction: column;
  gap: 0.62rem;
  margin-top: ${(props) =>
    props.showrating === "true" ? "1.87rem" : "0.62rem"};
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  margin-top: 1.25rem;
`;
export const CommentTextAreaContainer = styled.div`
  width: 50%;
  height: 8.75rem;
  /* border: 1px solid #576371; */
  /* border-radius: 0.625rem; */
  display: flex;
  justify-content: center;
`;

export const CommetnTextArea = styled.textarea`
  background-color: transparent;
  border: 1px solid #576371;
  border-radius: 0.625rem;
  font-size: 0.875rem;
  width: 100%;
  max-height: 100%;
  min-height: 70%;
  min-width: 70%;
  padding: 0.63rem 1.25rem;
  font-family: "Primary Font-Light";
  outline: #576371;
  color: ${theme.dark.primary_text_color};
  &::placeholder {
    color: ${theme.dark.primary_text_color};
    font-size: 0.875rem;
    opacity: 0.5;
  }
`;

export const Comment = styled.div`
  display: flex;
  justify-content: space-between;
`;
