import React, { useEffect, useState } from "react";
import { TeacherLandingInterface } from "./TeacherLandingPageInterface";
import { CourseTraking } from "../../../elements/courseTraking/CourseTraking";
import { modules } from "./TeacherLandingPage.data";
import InstructorViewCompletion from "../../StudentCompletionTrackingInstrcuterView/InstructorViewCompletion";
import { CourseGeneralTracing } from "../../CourseGeneralTracing/CourseGeneralTracing";
import {
  StudentLandingPageMain,
  StudentLandingPageMainObject,
  StudentLandingPageMainObjectTitle,
} from "../StudentLandingPage/StudentLandingPagestyle";
import { StudentLandingPageData } from "../StudentLandingPage/StudentLandingPageData";
import { StudentCompletionTracing } from "../../StudentCompletionTracing/StudentCompletionTracing";
import { LightTypography, Typography } from "../../../elements/fonts/Fonts";
import { StudentLandingTable } from "../../../elements/StudentLandingTable/StudentLandingTable";
import AnalyticsGenerationPage from "../../../elements/AnalyticsGenerationText/AnalyticsGenerationText";
import { TeacherLandingPageLayout } from "./TeacherLandingPage.style";
import InstructorAnalyticsUsersView from "../../InstructorAnalyticsUsersView/InstructorAnalyticsUsersView";
import { FlexAppContainer } from "../../../App.style";
import { TeacherLandingPageData } from "../StudentLandingPage/TeacherLandingPageData";
import { TotalNumberOfView } from "./funtions";
import { useStateContext } from "../../../contexts/ContextProvider";

export const TeacherLandingPage = ({
  Teacher,
}: TeacherLandingInterface): JSX.Element => {
  const [isParentExpanded, setisParentExpanded] = useState(false);
  const [UserView, setUserView] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const [NumberOfUsers, setSetNumberOfUsers] = useState<number>(0);
  const [ActivitiesMode, setActivitiesMode] = useState<boolean>(false);
  const [ResoursesMode, setResoursesMode] = useState<boolean>(false);
  const [ToggleAll, setToggleAll] = useState<boolean>(false);

  const handleModalClick = () => {
    if (isParentExpanded === false) {
      setisParentExpanded(!isParentExpanded);
      setSearchText("");
      setToggleAll(true);
    } else {
      setisParentExpanded(!isParentExpanded);
      setResoursesMode(false);
      setActivitiesMode(false);
    }
  };

  const handleResoursesSearch = () => {
    setisParentExpanded(true);
    setResoursesMode(!ResoursesMode);
    setSearchText("resources");
    setActivitiesMode(false);
  };

  const handleActivitySearch = () => {
    setActivitiesMode(!ActivitiesMode);
    setisParentExpanded(true);
    setToggleAll(true);
    setSearchText("activities");
    setResoursesMode(false);
  };

  const handleChangeUsersView = () => {
    setUserView(!UserView);
    setisParentExpanded(false);
  };

  // 2nkndPart

  const [ShowResources, setShowResources] = useState(false);
  const [ShowActivities, setShowActivities] = useState(false);
  const handleShowResources = () => {
    setisParentExpanded(true);
    setShowResources(!ShowResources);
  };

  const handleShowActivities = () => {
    setisParentExpanded(true);
    setShowActivities(!ShowActivities);
  };

  return (
    <TeacherLandingPageLayout>
      <CourseGeneralTracing
        UsersNumber={NumberOfUsers.toString()} // Corrected typo here
        ModelNumber="3"
        ActivitiesNumber="3"
        ResourcesNumber="3"
        DaysNumber="30"
        onClickModel={handleModalClick}
        onClickRecourses={handleResoursesSearch}
        onClickActivities={handleActivitySearch}
        onClickUsers={handleChangeUsersView}
        ModalActive={isParentExpanded}
        ActivitiesActive={ActivitiesMode}
        UsersActive={UserView}
        ResourcesActive={ResoursesMode}
      />

      <CourseTraking
        module={modules}
        isParentExpanded={isParentExpanded}
        setisParentExpanded={setisParentExpanded}
        SearchText={SearchText}
        ActivitiesMode={ActivitiesMode}
        RearousesMode={ResoursesMode}
        ToggleAll={ToggleAll}
      />
      {!UserView ? (
        <InstructorViewCompletion setNumberOfUsers={setSetNumberOfUsers} />
      ) : (
        <InstructorAnalyticsUsersView />
      )}
      <FlexAppContainer gap="1.25rem" column={true}>
        <AnalyticsGenerationPage />

        {TeacherLandingPageData.map((item, index) => (
          <StudentLandingPageMain key={index}>
            {item.Recourses && (
              <StudentLandingPageMainObject>
                <StudentLandingPageMainObjectTitle
                  onClick={handleShowResources}
                >
                  <Typography text="Resources" fontSize="1.125rem" />
                  <LightTypography
                    text={`${item.Recourses.length} Resources`}
                    fontSize=" 0.75rem"
                    color="#FAFAFA"
                  />
                </StudentLandingPageMainObjectTitle>
                {ShowResources && (
                  <StudentLandingTable
                    role="teacher"
                    LandingData={item.Recourses}
                    LinkActions={false}
                    TotalView={TotalNumberOfView(item.Recourses)}
                  />
                )}
              </StudentLandingPageMainObject>
            )}

            {item.Activities && (
              <StudentLandingPageMainObject>
                <StudentLandingPageMainObjectTitle
                  onClick={handleShowActivities}
                >
                  <Typography text="Activities" fontSize="1.125rem" />
                  <LightTypography
                    text={`${item.Activities.length} Activities`}
                    fontSize=" 0.75rem"
                    color="#FAFAFA"
                  />
                </StudentLandingPageMainObjectTitle>
                {ShowActivities && (
                  <StudentLandingTable
                    LandingData={item.Activities}
                    role="teacher"
                    // TitleLink={item.Activities[index].type}
                    LinkActions={true}
                    TitleLink={item.Activities.map((val) => val.type)}
                    TotalView={TotalNumberOfView(item.Activities)}
                  />
                )}
              </StudentLandingPageMainObject>
            )}
          </StudentLandingPageMain>
        ))}
      </FlexAppContainer>
    </TeacherLandingPageLayout>
  );
};
