import styled from "styled-components";

// export const Survey =styled.div``;

export const SurveyColScale  = styled.div`
display: flex;
width: 100%;
flex-direction: column;
gap:0.62rem;
margin-bottom:0.5rem;
`;


export const SurveyScaleAction=styled.div`
display: flex;
width: 100%;
flex-direction: column;
/* gap:1.25rem; */
justify-content:space-evenly;
margin-top:0.60rem;
`;



export const SurveyLineStyle =styled.div<{bgColor?:string}>`
width: 100%;
height: 0.25rem;
background:${props =>props.bgColor};
`;
export const SurveyScaleActionPlace=styled.div`
display: flex;
/* width: 100%; */
flex-direction: column;

gap:1.2rem;
/* justify-content:space-evenly; */
/* margin-top:0.40rem; */
cursor: pointer;
align-items:center ;
/* background-color:aqua; */
/* align-items:center; */
/* & > :last-child {
  
    } */
    &:nth-child(1) {
      align-items:flex-start ;
    /* background-color:aqua !important;  */

}
&:nth-child(3) {
    margin-left:9%;
    margin-right:9%;

}
    &:nth-child(5) {
      align-items:flex-end ;
    /* background-color:aqua !important;  */

}

`;

export const SurveyScaleDote =styled.div<{bgColor?:string}>`
width: 0.875rem;
height: 0.875rem;
flex-shrink: 0;
background:${props =>props.bgColor};
border-radius:50%;

`;
export const SurveyScaleActiveDote =styled.div`
width: 1.50rem;
height: 1.50rem;
background-color:#5DD3B3;
border-radius:50%;
margin-top:-0.40rem;
display:flex;
color:#fff;
justify-content:center;
align-items:center;
margin-bottom:-0.2rem;
`;

export const SurveyScaleLable=styled.div``;


export const SurveyScaleLableAction=styled.div`
display: flex;
width: 100%;
/* flex-direction: column; */
/* gap:1.25rem; */
justify-content:space-between !important;
margin-top:-0.50rem;
/* background-color:red !important; */
`;