import React from "react";
import { AnalyticDefaultTable } from "../../../elements/AnalyticDefaultTable/AnalyticDefaultTable";
import { ForumDetailsInterfaceData } from "./ForumDetailsData";

const ForumDetails = () => {
  return (
    <div>
      <AnalyticDefaultTable
        title="Forum Details"
        DatabaseTableName={ForumDetailsInterfaceData}
        MappedHeadText={ForumDetailsInterfaceData.map((val) => val.Title)}
        LinkedText="ForumDetails"
      />
    </div>
  );
};

export default ForumDetails;
