import React, { useEffect, useState } from "react";
import { SurveyData } from "../AnalyticsSurveyPiesTwoValues/AnalyticsSurveyPiesTwoValues";
import { AnalyticsSurveyPiesTwoValuesContainer } from "../AnalyticsSurveyPiesTwoValues/AnalyticsSurveyPiesTwoValues.style";
import PieWithText from "../../Charts/PieWithText/PieWithText";
import PieText from "../../Charts/PieText/PieText";

// Interface for AnalyticsSurveyLikertScale
interface AnalyticsSurveyLikertScaleInterface {
  data: SurveyData; // Use the SurveyData interface here
}

// AnalyticsSurveyLikertScale Component
const AnalyticsSurveyLikertScale = ({
  data,
}: AnalyticsSurveyLikertScaleInterface) => {
  // State for PieChartData
  const [PieChartData, setPieChartData] = useState([
    {
      text: "Answered",
      val: 0,
      bgColor: "#5DD3B3",
    },
    {
      text: "Unattempted",
      val: 0,
      bgColor: "#0B8FFF",
    },
  ]);

  // useEffect to update PieChartData based on data
  useEffect(() => {
    const StronglyAgreeCount = data.students.filter(
      (item) =>
        typeof item.Answered === "string" &&
        (item.Answered as string) === "Strongly Agree"
    ).length;
    const AgreeCount = data.students.filter(
      (item) =>
        typeof item.Answered === "string" &&
        (item.Answered as string) === "Agree"
    ).length;
    const NeutralCount = data.students.filter(
      (item) =>
        typeof item.Answered === "string" &&
        (item.Answered as string) === "Neutral"
    ).length;
    const DisagreeCount = data.students.filter(
      (item) =>
        typeof item.Answered === "string" &&
        (item.Answered as string) === "Disagree"
    ).length;
    const StronglyDisagreeCount = data.students.filter(
      (item) =>
        typeof item.Answered === "string" &&
        (item.Answered as string) === "Strongly Disagree"
    ).length;
    const NoAnswerCount = data.students.filter(
      (item) =>
        (typeof item.Answered === "string" &&
          (item.Answered as string).trim() === "") ||
        (typeof item.Answered === "string" &&
          (item.Answered as string) === "") ||
        !item.Answered
    ).length;
    setPieChartData([
      {
        text: "Strongly Agree",
        val: StronglyAgreeCount,
        bgColor: "#5DD3B3",
      },
      {
        text: "Agree",
        val: AgreeCount,
        bgColor: "#0B8FFF",
      },
      {
        text: "Neutral",
        val: NeutralCount,
        bgColor: "#5DC5D3",
      },
      {
        text: "Disagree",
        val: DisagreeCount,
        bgColor: "#CBD35D",
      },
      {
        text: "Strongly Disagree",
        val: StronglyDisagreeCount,
        bgColor: "#D85D66",
      },
      {
        text: "No Answer",
        val: NoAnswerCount,
        bgColor: "#8A2BE2",
      },
    ]);
  }, [data]);

  return (
    <AnalyticsSurveyPiesTwoValuesContainer>
      {/* PieWithText Component */}
      <PieWithText
        data={PieChartData}
        value={"val"}
        getColor={(entry) => entry.bgColor as string}
        Width="65%"
      />

      {/* PieText Component */}
      <PieText
        LoopName={PieChartData}
        ShowMiniInfo={false}
        title={data.QuestionText}
        MinWidth="8.4rem"
      />
    </AnalyticsSurveyPiesTwoValuesContainer>
  );
};

export default AnalyticsSurveyLikertScale;
