import styled from "styled-components";
interface Styled{
  Content?:boolean;
  Border?:string;
  BKCOLOR?:string;
}

export const CheckBoxAfterCorrectionLine =styled.div`
display:flex;
gap:0.80rem;
width:100%;
/* background-color:aqua; */
margin-left:-1rem;
`;

export const CheckBoxAfterCorrectionSquare = styled.div<Styled>`
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  position: relative;

  &::before {
    content: "${props => (props.Content ? "\\2713" : "")}";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  border: 1.5px solid ${props => props.Border || "#fff"};
  background: ${props => props.BKCOLOR || "#343E49"};
  font-size: 1rem;
`;
