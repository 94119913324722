/**
 * Function: getColorBaseOnGrade
 * Description: This function takes a Grade as input and returns a color code based on the Grade.
 * @param {string | undefined} Grade - The grade for which color needs to be determined.
 * @returns {string} - The color code corresponding to the provided grade.
 */
export const getColorBaseOnGrade = (Grade: string | undefined) => {
  switch (Grade) {
    case "F":
      return "#576474"; // Return a specific color for grade "F".
    default:
      return "#5DD3B3"; // Provide a default color for undefined Grade.
  }
};

export const HandelPenalityYesNo = (value: boolean | undefined) => {
  if (value === true) {
    return "Yes";
  } else if (value === false) {
    return "No";
  } else {
    return "Undefined";
  }
};







/**
 * Function to handle saving date for a specific index in the forum participation details table.
 * @param index The index of the item in the filtered data array.
 * @param setShapeDateToggles React state setter function to update the shape date toggles array.
 * @param selectedOptionIndex The currently selected option index.
 * @param setSelectedOptionIndex React state setter function to update the selected option index.
 * @returns Void.
 */
export const handleDateChangeState = (
  index: number,
  setShapeDateToggles: React.Dispatch<React.SetStateAction<{ isOpen: boolean; selectedDate: Date | null }[]>>,
  selectedOptionIndex: number | null,
  setSelectedOptionIndex: React.Dispatch<React.SetStateAction<number | null>>,
  filteredData?: any[]
  
): void => {

  
  setShapeDateToggles((prevToggles) => {
    const updatedToggles = [...prevToggles];
    updatedToggles[index] = {
      isOpen: !updatedToggles[index].isOpen,
      selectedDate: updatedToggles[index].selectedDate,
    };

    if (selectedOptionIndex !== null && selectedOptionIndex !== index) {
      // Close the previously opened action box
      updatedToggles[selectedOptionIndex] = {
        isOpen: false,
        selectedDate: null,
      };
    }

    setSelectedOptionIndex(updatedToggles[index].isOpen ? index : null);
  

    return updatedToggles;
  });
};

