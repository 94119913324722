import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { themeState } from "../../recoil/RecoilStates";
import {
  ToggleSwitchWrapper,
  Switch,
  ThemeIcon,
  ThemeImage,
  Toggle,
} from "./ThemeSwitch.style";
import Moon from "./moon.png";
import Sun from "./sun.png";
import { TypographyRegularDescription } from "../fonts/Fonts";
import { Frame } from "../../components/droprDownMenu/dropDownItems/DropDownItems.style";
import { SvgMoon, SvgSun } from "../Icons";
import { useTranslation } from "react-i18next";

function ToggleSwitch() {
  // const [isToggled, setIsToggled] = useState(false);
  const [theme, setTheme] = useRecoilState(themeState);
  const { t } = useTranslation()

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme); // Set theme from localStorage
    }
  }, [setTheme]);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    // setIsToggled(!isToggled);
    localStorage.setItem("theme", newTheme); // Save theme preference to localStorage
  };

  return (
    // <ToggleSwitchWrapper >

    //     <Toggle light={theme === 'light' ? true : false} onClick={toggleTheme}>
    //         {theme === 'light' ? "🌜" : "🌞"}
    //     </Toggle>
    // </ToggleSwitchWrapper>

    <Frame onClick={toggleTheme}>
      {/* <item.icon /> */}
      {theme === "light" ? <SvgMoon /> : <SvgSun />}
      <TypographyRegularDescription
        fontSize="0.875rem"
        text={theme === "light" ? t("general.Dark") : t("general.Light")}
      />
    </Frame>
  );
}

export default ToggleSwitch;
