import React, { useEffect, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import { ReactComponent as SvgPlygonIcon } from "../../assets/icons/Arrow.svg";
import {
  Dropdown,
  Input,
  InputContainer,
  ItemWithArrow,
  OptionItem,
  PolygonDiv,
  StyledSvgPlygonIcon,
  SubMenu,
  SubMenuItem,
} from "./NestedSelect.style";
import { useStateContext } from "../../contexts/ContextProvider";
import { InputWithDropdownProps, Option } from "./NestedSelectInterface";
import { LightTypography } from "../fonts/Fonts";
import { FlexDiv } from "../../globalStyles";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { useQuizMyContext } from "../../contexts/CourseCreatorQuizContext";
import { useMyContext } from "../../contexts/CourseCreatorContext";

// React component
const NestedSelect: React.FC<InputWithDropdownProps> = ({
  value,
  placeholder,
  onChange,
  options,
  width,
  height,
  borderRadius,
  borderColor,
  color,
  fontSize,
  withPercentage = false,
  paddingRL,
  icon,
  border,
  redirectUrl,
  onIdChange,
  setGlobalAssignmentShowPopup,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setPathNameState, pathNameState, setIsPopUpOpen } = useStateContext();
  const [optionValue, setOptionValue] = useState<string | null>(value);
  const [selectedIcon, setSelectedIcon] = useState<React.ReactNode>(<></>);
  const [selectedSubmenu, setSelectedSubmenu] = useState<
    { title: string }[] | null
  >(null);
  const containerRef = useClickOutside(isOpen, () => setIsOpen(false));
  const navigate = useNavigate();
  const { setEditQuiz,setEditQuizButton } = useQuizMyContext();
  const { setEditSurvey } = useMyContext();

  useEffect(() => {
    setOptionValue(value);
    const selectedOptionObject = options?.find(
      (option) => option.value === value
    );
    setSelectedIcon(selectedOptionObject ? selectedOptionObject.icon : null);
  }, [value, options]);

  const handleItemClick = (option: any) => {
    if (option.value === "Create a new one") {
      // Delete the quizTemplate array from local storage
      localStorage.removeItem("quizTemplate");
      // Delete the name of the template
      localStorage.removeItem("quizTemplateName");
      // Delete the surveyTemplate array from local storage
      localStorage.removeItem("surveyTemplate");
      // Delete the name of the template
      localStorage.removeItem("surveyTemplateName");
      navigate(redirectUrl || "/");
      setEditQuiz(false);
      setEditQuizButton(false)
      setEditSurvey(false);
      setPathNameState("redirectUrl");
      setIsPopUpOpen(false);
      setGlobalAssignmentShowPopup && setGlobalAssignmentShowPopup(true);
    } else if (option.submenu) {
      // If the clicked option has a submenu, close the submenu if it's already open
      if (selectedSubmenu && selectedSubmenu === option.submenu) {
        setSelectedSubmenu(null);
      } else {
        // If not, open the submenu
        setSelectedSubmenu(option.submenu);
      }
    } else {
      // If not, select the option
      setOptionValue(option.title);
      if (onChange) {
        onChange(option.title);
      }
      if (onIdChange) {
        onIdChange(option.id);
      }
      setSelectedSubmenu(null);
      setIsOpen(false);
    }
  };

  const handleSubMenuClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const renderSubmenu = () => {
    if (selectedSubmenu) {
      return (
        <div>
          {selectedSubmenu.map((subOption, subIndex) => (
            <SubMenuItem
              key={subIndex}
              onClick={(e) => {handleItemClick(subOption);handleSubMenuClick(e);}}
              paddingrl={paddingRL}
            >
              {subOption.title}
            </SubMenuItem>
          ))}
        </div>
      );
    }
    return null;
  };

  const {
    themeTextColor,
    OpacitiesTextColor,
    bg_selectColor,
    inputBorderColor,
  } = useRecoilValue(ColorSelector);

  return (
    <InputContainer ref={containerRef}>
      <Input
        onClick={() => {
          setIsOpen(disabled ? false : !isOpen);
          setSelectedSubmenu(null);
        }}
        width={width}
        borderRadius={borderRadius}
        borderColor={borderColor}
        color={color}
        fontSize={fontSize}
        themetextcolor={themeTextColor}
        inputbordercolor={inputBorderColor}
        height={height}
        paddingrl={paddingRL}
        border={border}
      >
        {optionValue ? (
          <FlexDiv gap="0.5rem" pointer="true">
            {icon && icon} {selectedIcon} {optionValue}
          </FlexDiv>
        ) : (
          <LightTypography
            color={color ? color : OpacitiesTextColor}
            text={placeholder}
            fontSize={fontSize ? fontSize : "0.75rem"}
          />
        )}
        <FlexDiv gap="1.25rem">
          {withPercentage && (
            <LightTypography
              text="/ 100"
              color={color ? color : OpacitiesTextColor}
              fontSize={fontSize ? fontSize : "0.75rem"}
            />
          )}
          <PolygonDiv isopen={isOpen.toString()}>
            <SvgPlygonIcon
              color={
                disabled ? inputBorderColor : color ? color : themeTextColor
              }
            />
          </PolygonDiv>
        </FlexDiv>
      </Input>
      {isOpen && (
        <Dropdown
          width={width}
          fontSize={fontSize}
          bg_selectColor={bg_selectColor}
        >
          {options.map((option) => (
            <OptionItem
              key={option.value}
              onClick={() => handleItemClick(option)}
              paddingrl={paddingRL}
            >
              {option.submenu ? (
                <ItemWithArrow>
                  {option.title}
                  <StyledSvgPlygonIcon
                    color={color ? color : themeTextColor}
                  />
                </ItemWithArrow>
              ) : (
                <div>{option.title}</div>
              )}
            </OptionItem>
          ))}
          {selectedSubmenu && (
            <SubMenu bg_selectColor={bg_selectColor}>{renderSubmenu()}</SubMenu>
          )}
        </Dropdown>
      )}
    </InputContainer>
  );
};

export default NestedSelect;
