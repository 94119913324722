import { CourseLabel } from "../../../elements/courseLabel/main/CourseLabel";
import {
  Information,
  BannerWrapper,
  Group,
  Div,
  DoingGreat,
  GpaPosition,
} from "./banner.style";
import { GpaWrapper } from "../gpaWrapper/GpaWrapper";
import { LightTypography, Typography } from "../../../elements/fonts/Fonts";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

interface BannerProps {
  enrollments: any[];
}

export const Banner = ({ enrollments }: BannerProps): JSX.Element => {
  const { backgroundColor, themeTextColor, boxShadowBanner } =
    useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  const text = t("dashboard.overview");
  const [firstWord, ...rest] = text.split(" ");
  const secondWord = rest.join(" ");
  //banner
  return (
    <BannerWrapper>
      <Information
        boxshadow={boxShadowBanner}
        style={{ backgroundColor: backgroundColor }}
      >
        <Group>
          <Div style={{ color: themeTextColor }}>
            {/* <LightTypography fontWeight="300" fontSize="1.125rem" text={`${i18n.t('dashboard.Current Status')}`} /> */}
            {firstWord}{" "}
            <span>
              <b>{secondWord}</b>
            </span>
            {/* <Typography fontSize="1.125rem" fontWeight="600" text="Status" /> */}
          </Div>
          {/* <DoingGreat> <TypographyRegularDescription fontSize="0.75rem" fontWeight="400" text="You’re doing great, keep pushing!" /> </DoingGreat> */}
        </Group>

        {/* <GpaPosition> <GpaWrapper /> </GpaPosition> */}

        <CourseLabel justifyContent="space-evenly" enrollments={enrollments} />
      </Information>
    </BannerWrapper>
  );
};
