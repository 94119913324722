import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const ParticipantCardHolder = styled.div<{
  bgColor?: string;
  boxShadow?: string;
}>`
width: 100%;
  flex-shrink: 0;
  border-radius: 0.625rem;
  box-shadow: ${(props) => props.boxShadow};
  background: ${(props) => props.bgColor || "#384450"};
  padding: 0.62rem 1.25rem;
  display: flex;
  flex-direction: column;
`;
export const ProfileImageHolder = styled.div``;
export const ProfileImg = styled.div<{src?: any, bordercolor?: string, theme?:any}>`
  border: 1px solid ${({ bordercolor }) => bordercolor || "#384450"};
  width: 3.125rem;
  height: 3.125rem;
  filter: ${({ bordercolor }) =>
    bordercolor === "light"
      ? "drop-shadow(1px 1px 10px rgba(0, 0, 0, 0.25))"
      : "drop-shadow(1px 1px 10px rgba(0, 0, 0, 0.25))"};
  border-radius: 50%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
`;
export const ProfileNameEmailRow = styled.div`
  display: flex;
  gap: 0.75rem;
  /* margin-left: 0.5rem; */
  align-items: center;
`;
export const NameEmailColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.31rem;
`;
export const InputSendRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.44rem;
`;

export const ProfileImgHolder = styled.div`
  /* margin-left: 0.5rem; */
`;
export const DropDownsCont = styled.div`
  display: flex;
justify-content: space-between;
  gap: 0.5rem;
  & > * {
    flex: 1;
    width: 50%;
  }
`;
export const DropDownsContent = styled.div`
width: 50%;
`;
export const EmptyView = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin-top: 5rem;
`;
