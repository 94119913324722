import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const LinkContainer = styled.div<StyleThemeInterface>`
min-width:5rem;
  width: 5rem;
  background: ${(props) => props.bgcolor || "rgb(41, 52, 64)"};
  border-radius: 0.625rem;
  /* background: linear-gradient(180deg, #F7F7F8 0%, rgba(87, 100, 116, 0.00) 100%); */
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.5rem;
  border-radius: 0.625rem;
  box-shadow: ${(props) => props.boxshadow};
`;
export const LinkStyle = styled.a`
  display: flex;
  align-items: center;

  column-gap: 1.5rem;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
`;
export const LinkTreeContainer = styled.div<StyleThemeInterface>`
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  & > :last-child {
  }
`;
export const IframeStyled = styled.iframe`
  border: 0.25rem solid #576371;
`;
