import { FallingLines } from "react-loader-spinner";
import { MainWrapper } from "./LoaderText.style";
const LoaderText = () => {
  return (
    <MainWrapper>
      <FallingLines
        color="#5DD3B3"
        width="100"
        visible={true}
        // ariaLabel='falling-lines-loading'
      />
    </MainWrapper>
  );
};

export default LoaderText;
