import styled from "styled-components";

export const GradesModalWrapper = styled.div`
  width: 25.5625rem;
  height: 22.125rem;
  flex-shrink: 0;
  background: var(
    --background-card,
    linear-gradient(180deg, #415160 0%, #293440 100%)
  );
  padding: 1.87rem 1.25rem 1.63rem 1.88rem;
`;
export const ImgBtnRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const BtnHidden = styled.button`
  width: 5.625rem;
  height: 1.875rem;
  border-radius: 1.25rem;
  border: 1px solid #d85d66;
  background: transparent;
  outline: none;
`;
export const NameFirstArrowsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.63rem;
`;
export const FirstArrows = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.87rem;
`;

export const TitleSecondArrowsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.63rem;
`;
export const SecondArrows = styled.div`
  display: flex;
  align-items: center;
  gap: 1.87rem;
`;
export const DividingLine = styled.div`
  width: 22.4375rem;
  height: 0.0625rem;
  background: #576371;
  margin-top: 1.25rem;
`;
export const SpeedGraderLogoText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.62rem;
  margin-top: 1.25rem;
`;
export const GradesTitleWithInput = styled.div`
  display: flex;
  margin-top: 1.37rem;
  gap: 1.31rem;
`;
export const Label = styled.label`
  margin-bottom: 10px;
`;

export const Input = styled.input`
  flex: 1;
  appearance: none;
  -webkit-appearance: none;

  width: 50%;
  height: 2.8125rem;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  background: transparent;
  outline: none;
  color: #fff;
  &:dir(ltr) {
    padding-left: 1.25rem;
  }
  &:dir(rtl) {
    padding-right: 1.25rem;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #fff;
    font-family: Core Rhino 65 Bold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
