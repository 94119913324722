import React from "react";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { VerticalRadiusDot } from "./NotificationVerticalLine.styled";

const VerticalRadius = () => {
  const { theme } = useRecoilValue(ColorSelector);

  return (
    <div style={{ position: "relative" }}>
      {[...Array(5)].map((_, index) => (
        <VerticalRadiusDot key={index} theme={theme} top={`${index * 2}rem`} />
      ))}
    </div>
  );
};

export default VerticalRadius;
