// RoleContext.js
import { createContext, useContext, useState, ReactNode } from "react";

const RoleContext = createContext({
  userRole: "student",
  setRole: (newRole: string) => {},
 CalendarView: "month",
  setCalendarView: (newView: string) => {},
});

interface RoleProviderProps {
  children: ReactNode;
}

export function RoleProvider({ children }: RoleProviderProps) {
  const [userRole, setRole] = useState("student"); // Set the default role
const[CalendarView,setCalendarView]=useState<any>("month")
  return (
    <RoleContext.Provider value={{ userRole, setRole,CalendarView,setCalendarView }}>
      {children}
    </RoleContext.Provider>
  );
}

export function useRole() {
  return useContext(RoleContext);
}
