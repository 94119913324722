import React, { useEffect, useState } from "react";
import { RecordingInterface } from "../../../CourseTimeline/utils/CourseResourcesInterface";
import {
  ActionHolder,
  ListItem,
  RightActionsHolder,
} from "./RecordingBox.style";
import { LightTypography } from "../../../../elements/fonts/Fonts";
import { SvgDownload, SvgEyeIcon } from "../../../../elements/Icons";
import PopUpLine from "../../../../elements/PopupLine/PopupLine";
import RecordPopup from "./RecordPopup/RecordPopup";

interface RecordingProps {
  recording: string;
  index: number;
}

export default function RecordingBox({ recording, index }: RecordingProps) {
  const [isRecordingPopupOpen, setRecord] = useState<boolean>(false);
  useEffect(() => {
    setRecord(false);
  }, [recording, index]);
  const openRecordingPopup = () => {
    setRecord(true);
  };
  return (
    <>
      <ListItem>
        <div style={{ display: "flex", columnGap: "0.6rem" }}>
          <LightTypography text="Recording" fontSize="0.875rem" />
          <LightTypography text={`${index}`} fontSize="0.875rem" />
        </div>
        <RightActionsHolder>
          <ActionHolder onClick={openRecordingPopup}>
            <SvgEyeIcon />
            <LightTypography text="View Recording" fontSize="0.875rem" />
          </ActionHolder>
        </RightActionsHolder>
      </ListItem>
      {isRecordingPopupOpen && (
        <RecordPopup
          show={isRecordingPopupOpen}
          setShow={setRecord}
          title={`Recording ${index}`}
          path={recording}
        />
      )}
    </>
  );
}
