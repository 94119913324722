import React, { useState, FC, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import { BtnsDiv, ButtonsDiv, LabelBtnsDiv } from "./Uploadpopup.style";
import { CancelButton } from "../../../../quizForm/QuizForm.style";
import { generateUUID } from "../../../../../utils/StringManipulation";
import { S3Upload } from "../../../../../services/S3Put";
import { Container } from "../../../../loader/Loader.style";
import { DropZoneForm } from "../../../../../elements/dropZone/DropZoneForm";
import { t } from "i18next";

interface UploadPopupProps {
  SetDropZoneFile: (file: any) => void;
  DropZoneFile: any;
}

const UploadPopup: FC<UploadPopupProps> = ({ SetDropZoneFile, DropZoneFile }) => {
  const { isLoading, user } = useAuth0();

  const tenantName = localStorage.getItem("tenant");

  const [visibleStudents, setVisibleStudents] = useState(true);
  const [showTimeVisibility, setShowTimeVisibility] = useState(false);
  const [fileName, setFileName] = useState("");
  const [description, setDescription] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [error, setError] = useState(false);
  const [firstError, setFirstError] = useState(true);
  const [newFile, setNewFile] = useState({ path: "" });
  const [prevData, setPrevData] = useState<any>();
  const [prevDataAttachments, setPrevDataAttachments] = useState<any>();
  const [readyToEdit, setReadyToEdit] = useState(true);
  const [foundRefIdState, setFoundRefIdState] = useState<number>();
  const [FilesToEdit, SetFilesToEdit] = useState();
  const [fileUUId, setFileUUId] = useState("");
  const [readyToPost, setReadyToPost] = useState(true);
  const [isSetLoading, setIsSetLoading] = useState(false);

  // Function to handle upload file and s3upload
  const handleFile = async (value: any) => {
    setReadyToPost(false);
    setNewFile(value);
    SetDropZoneFile(value);
    const fileNameUUid = `${generateUUID()}${value.path}`;
    // const response = await S3Upload(filePath, value.path, fileNameUUid, value);
    setFileUUId(fileNameUUid);
    // console.log("response is", response?.status);
    // console.log(`${baseURL}/${path}/${tenantName}`);
  };

  // handle Toggle Change Visiblity for Students
  const handleToggleChangeVisible = (isChecked: boolean) => {
    setVisibleStudents(isChecked);
  };

  // function to set time visibility interval
  const handleTwoDatePickerChange = (start: Date | null, end: Date | null) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleToggleChangeTime = (isChecked: boolean) => {
    if (isChecked) {
      setShowTimeVisibility(true); // Show Time Visibility when the toggle is on
    } else {
      setShowTimeVisibility(false);
    }
  };

  const accF: { [key: string]: string[] } = {
    "*/*": ["*"],
  };

  return (
    <Container>
      {/* dropzone */}
      <DropZoneForm
        acceptedTypes={accF}
        text={t("gradebook.Upload Gradebook")}
        testDrop={handleFile}
        widthIcon={450}
      />

      {/* toggles */}
      <BtnsDiv>
        <LabelBtnsDiv>
          {/* Add toggle buttons here */}
        </LabelBtnsDiv>
      </BtnsDiv>

      {/* actions buttons */}
      {/* Add action buttons here */}
    </Container>
  );
};

export default UploadPopup;
