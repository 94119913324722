import React, { FC, useState, useEffect } from "react";
import { theme } from "../../theme";
import { Typography, LightTypography } from "../fonts/Fonts";
import ReusableSubmitButton from "../reusableSubmitButton/ReusableSubmitButton";
import { TextEditor } from "../TextEditor/TextEditor";
import { useStateContext } from "../../contexts/ContextProvider";
import axios from "axios";
import ReusableTitleInput from "../groupSchemeTitleInput/ReusableInput";
import { GroupCardDataInterface } from "../../components/groupCardNew/groupCardNewInterface";
import LoaderThreeDot from "../../components/loader/loaderThreeDot/LoaderThreeDot";

import {
  Overlay,
  BackgroundOverlay,
  PopupContainer,
  ButtonsHolder,
  PopupContentContainer,
  IconsWrapper,
  InputsHolder,
  TextEditorWrapper,
  InputWrapper,
} from "./ReusablePopUp.style";

import { SvgClose } from "../Icons";
import { AllGroupMembersData } from "../../components/allMembersGroups/allGroupMemberBarData";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import TinyEditor from "../tinyEditor/TinyEditor";
import { useTranslation } from "react-i18next";
import { SchemeGroupInterface } from "../../components/schemeGroup/schemeGroupInterface";
import { FlexDiv } from "../../globalStyles";

interface SchemePopUpProps {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title?: string;
  showComponent?: boolean;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
  setShowEditScheme: (showEditScheme: boolean) => void;
  showEditScheme: boolean;
  getCreatedScheme?: () => void;
  setClassSchemes: React.Dispatch<
    React.SetStateAction<{ [key: string]: SchemeGroupInterface[] }>
  >;
}

const SchemePopUp: FC<SchemePopUpProps> = ({
  show,
  showComponent,
  title,
  icon: IconComponent,
  setShow,
  handleSubmit,
  setShowEditScheme,
  showEditScheme,
  getCreatedScheme,
  setClassSchemes,
}) => {
  const {
    schemeTitle,
    schemeDescription,
    setSchemeDescription,
    setSchemeTitle,
    groups,
    setGroups,
    scheme_ID,
    schemes,
    setSchemes,
    setIsPopUpOpen,
  } = useStateContext();

  const BaseURL = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";
  const [readyToEdit, setReadyToEdit] = useState<boolean>(false);
  const { t } = useTranslation();

  const getSchemeToEdit = async () => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `${BaseURL}/groups/get/schema/${scheme_ID}/${tenantName}`
      );
      setSchemeTitle(res.data.data.titleScheme);
      setSchemeDescription(res.data.data.description);
      setReadyToEdit(true);
    } catch (error) {
      console.log(error);
    }
  };

  const updateScheme = async (body: any) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.put(
        `${BaseURL}/groups/update/schema/${tenantName}`,
        {
          data: body,
        }
      );

      // Destructure the data from the response or body
      const { groupSchemaId, titleScheme, description } = body;

      // Update the schemes object
      setClassSchemes((prevSchemes) => {
        // Iterate over each entry (classId, schemes array)
        return Object.fromEntries(
          Object.entries(prevSchemes).map(([classId, schemes]) => [
            classId,
            schemes.map((scheme) => {
              // If the scheme matches the updated scheme's groupSchemaId, update it
              if (scheme.groupSchemaId === groupSchemaId) {
                return {
                  ...scheme,
                  titleScheme,
                  description,
                };
              }
              // Return the original scheme if no match
              return scheme;
            }),
          ])
        );
      });

      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
      throw error; // Rethrow the error to be handled by the calling service
    }
  };

  useEffect(() => {
    if (showEditScheme) getSchemeToEdit();
  }, []);

  const data = {
    titleScheme: schemeTitle,
    description: schemeDescription,
    groupSchemaId: scheme_ID,
  };

  const handleSchemeTitleChange = (e: any) => {
    setSchemeTitle(e.target.value);
  };

  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const { backgroundColor, inputBorderColor, iconCloseColor } =
    useRecoilValue(ColorSelector);
  return (
    <Overlay isvisible={show.toString()}>
      <BackgroundOverlay>
        <PopupContainer>
          <PopupContentContainer bgColor={backgroundColor}>
            <IconsWrapper>
              <div style={{ display: "flex", gap: "0.62rem" }}>
                <IconComponent />
                <Typography
                  text={`${title}`}
                  fontSize="1rem"
                  // color={theme.dark.primary_text_color}
                />
              </div>

              {/* </div> */}
              {showEditScheme && !readyToEdit && <LoaderThreeDot />}
              <SvgClose
                color={iconCloseColor}
                onClick={() => {
                  setShow(false);
                  setShowEditScheme(false);
                  setIsPopUpOpen(false);
                }}
                // color="#5dd3b3"
              />
            </IconsWrapper>

            <InputsHolder display={showComponent || false}>
              <InputWrapper>
                <LightTypography
                  text={`${t("group.Scheme Name")} *`}
                  fontSize="0.875rem"
                />
                <ReusableTitleInput
                  inputHeight="2.8125rem"
                  placeHolder={t("group.Enter Scheme Name")}
                  inputBorderRadius="0.625rem"
                  value={schemeTitle}
                  inputBorder={
                    errorMessage
                      ? `1px solid #D85D66`
                      : `1px solid ${inputBorderColor}`
                  }
                  handleSchemeTitleChange={handleSchemeTitleChange}
                />
                {errorMessage && (
                  <LightTypography
                    text={`${t("grader.Scheme Name is required")}!`}
                    fontSize="0.875rem"
                    color="#D85D66"
                  />
                )}
              </InputWrapper>

              <TextEditorWrapper>
                <LightTypography
                  text={t("forms.Description")}
                  fontSize="0.875rem"
                />
                {/* <TextEditor
                  placeHolder="Type here your description"
                  initialValue={schemeDescription}
                  onChange={setSchemeDescription}
                  height={9}
                /> */}
                <TinyEditor
                  placeholder={t("forms.Enter Description")}
                  initialValue={schemeDescription}
                  onChange={setSchemeDescription}
                  height="13.25rem"
                />
              </TextEditorWrapper>
            </InputsHolder>
            <ButtonsHolder>
              <ReusableSubmitButton
                text={t("forms.Cancel")}
                color="#D85D66"
                bgColor="transparent"
                border="1px solid #D85D66"
                // padding="0.56rem 2.31rem 0.5rem 2.31rem"
                onClick={() => {
                  setShow(false);
                  setShowEditScheme(false);
                  setIsPopUpOpen(false);
                }}
              />
              <ReusableSubmitButton
                text={`${showEditScheme ? t("forms.Save") : t("group.Create")}`}
                width="7.625rem"
                height="2.125rem"
                // padding="0.56rem 2rem 0.5rem 2rem"
                onClick={async () => {
                  if (!schemeTitle) {
                    setErrorMessage(true);
                    setTimeout(() => {
                      setErrorMessage(false);
                    }, 3000);
                  } else {
                    if (!showEditScheme) {
                      handleSubmit();
                      setShow(false);
                      setShowEditScheme(false);
                    } else {
                      updateScheme(data);
                      setShow(false);
                      setIsPopUpOpen(false);
                      setShowEditScheme(false);
                    }
                  }
                }}
              />
            </ButtonsHolder>
          </PopupContentContainer>
        </PopupContainer>
      </BackgroundOverlay>
    </Overlay>
  );
};

export default SchemePopUp;
