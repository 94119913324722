import React, { useEffect, useState } from "react";
import {
  RemoveTextContainer,
  SearchContainer,
  SearchInput,
  MainHolder,
  SearchIconHolder,
} from "./SearchElement.style";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector, MainColorSelector } from "../../recoil/ThemeSelectors";
import { SvgClose, SvgSearchInput } from "../Icons";

interface SearchInputProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  backGroundPosition?: string;
  Placeholder?: string;
  PaddingLeft?: string;
  BgImage?: string;
  borderColor?: string;
  Close_RightPosition?: string;
  Close_TopPosition?: string;
  handleRemoveText?: () => void;
  showRemoveText?: boolean;
  display?: string;
  leftPositionIcon?: string;
  topPositionIcon?: string;
  paddingLeftIcon?: string;
  inputWidth?: string;
  height?:string;
}

export default function SearchElement({
  onChange,
  height,
  value,
  backGroundPosition,
  Placeholder = `${i18n.t("dashboard.Search")}...`,
  PaddingLeft,
  handleRemoveText,
  showRemoveText = false,
  BgImage,
  Close_RightPosition = "2.5%",
  Close_TopPosition = "15%",
  leftPositionIcon,
  paddingLeftIcon,
  display,
  inputWidth,
  topPositionIcon,
}: SearchInputProps) {
  const { themeTextColor, bghelp, inputBorderColor, theme } =
    useRecoilValue(ColorSelector);
  const [SearchPic, setSearchPic] = useState("");
  useEffect(() => {
    if (theme === "dark" || theme === "default")
      setSearchPic("/assets/icons/Search.svg");
    else setSearchPic("/assets/icons/SearchLight.svg");
  }, []);

  return (
    <SearchContainer height={height || "100%"}>
      <MainHolder inputwidth={inputWidth || "100%"}>
        <SearchInput
          value={value}
          type="text"
          placeholder={Placeholder}
          onChange={onChange}
          backGroundPosition={backGroundPosition}
          PaddingLeft={PaddingLeft}
          BgImage={BgImage || SearchPic}
          color={themeTextColor}
          borderColor={theme === "dark" ? "#576371" : "#AAB1B9"}
        />
        <SearchIconHolder
          // leftPositionIcon="1.75rem"
          // paddingLeftIcon="1.25rem"
          // topPositionIcon="0.9rem"
          left={leftPositionIcon || "1.75rem"}
          paddingLeft={paddingLeftIcon || "1.25rem"}
          top={topPositionIcon || "0.9rem"}
          display={display || "block"}
        >
          <SvgSearchInput />
        </SearchIconHolder>
        {showRemoveText && (
          <RemoveTextContainer
            onClick={handleRemoveText}
            theme={theme}
            Close_RightPosition={Close_RightPosition}
            Close_TopPosition={Close_TopPosition}
          >
            <SvgClose width={12} />
          </RemoveTextContainer>
        )}
      </MainHolder>
    </SearchContainer>
  );
}
