import React, { useState } from "react";
import {
  AccommodationsPopUpMainCheckLine,
  AccommodationsPopUpMainCheckText,
  AccommodationsPopUpMainContainer,
} from "./AccommodationsPopUpstyled";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../../elements/fonts/Fonts";
import { AccommodationsPopUpData } from "./AccommodationsPopUp.Data";
import {
  CheckboxLabel,
  SeletectOptinToogle,
} from "../../ProfileCheckBoxOptions/NotificationSetting.style";
import { handleItemToggle } from "../../../hooks/TableSelectedOptions";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
interface AccommodationsPopUpInterface {
  SelectedItems: any[];
  setSelectedItems: React.Dispatch<React.SetStateAction<any[]>>;
}
const AccommodationsPopUp = ({
  setSelectedItems,
}: AccommodationsPopUpInterface) => {
  // const [SelectedItems, setSelectedItems] = useState<any[]>([]);
  const [checkedItems, setCheckedItems] = useState<boolean[]>(
    new Array(AccommodationsPopUpData.length).fill(false)
  );
  const { checkboxBgColor,checkboxBorderColor,checkboxBorderRadius, CorrectTick_Content_Color } =
    useRecoilValue(ColorSelector);
  return (
    <AccommodationsPopUpMainContainer>
      {/* <Typography text="Accommodations" fontSize="1.125rem" /> */}
      <LightTypography
        text="Select accommodations for this student. When you create graded items."
        fontSize="0.75rem"
        lineHeight="1.375rem"
      />
      {AccommodationsPopUpData.map((data, index) => (
        <AccommodationsPopUpMainCheckLine>
          <SeletectOptinToogle
            
   bgColor={checkboxBgColor}
   borderColor={checkboxBorderColor}
   borderRadius={checkboxBorderRadius}
            contentColor={CorrectTick_Content_Color}
            type="checkbox"
            id={data.id?.toString()}
            value={data.id?.toString()}
            checked={checkedItems[index]}
            onChange={() =>
              handleItemToggle(
                index,
                checkedItems,
                setCheckedItems,
                setSelectedItems,
                AccommodationsPopUpData
              )
            }
          />
          <CheckboxLabel
            htmlFor={data.id.toString()}
            
   bgColor={checkboxBgColor}
   borderColor={checkboxBorderColor}
   borderRadius={checkboxBorderRadius}
          >
            <AccommodationsPopUpMainCheckText>
              <MediumTypography text={data.title} fontSize="0.875rem" />
              <LightTypography
                text={data.text}
                fontSize="0.75rem"
                lineHeight="1.375rem"
              />
            </AccommodationsPopUpMainCheckText>
          </CheckboxLabel>
        </AccommodationsPopUpMainCheckLine>
      ))}
    </AccommodationsPopUpMainContainer>
  );
};

export default AccommodationsPopUp;
