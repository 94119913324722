import React from "react";
import {
  SearchBarContainer,
  LeftIcon,
  SearchInput,
  RightIcon,
} from "./SearchWithFilter.style";
import { ReactComponent as SvgSearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as SvgSettingIcon } from "../../assets/icons/settings_1.svg";
import { Search } from "./SearchWithFilterInterface";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

const SearchWithFilter = ({
  width,
  height,
  placeholder,
  onChange,
  value,
  onClick,
  withFilter = true,
}: Search) => {
  const { themeTextColor, bghelp, theme } = useRecoilValue(ColorSelector);

  return (
    <SearchBarContainer width={width}>
      <LeftIcon>
        <SvgSearchIcon color={themeTextColor} opacity="0.4" />
      </LeftIcon>
      <SearchInput
        type="text"
        borderColor={theme === "dark" ? "#576371" : "#AAB1B9"}
        width={width}
        height={height}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        color={themeTextColor}
      />
      {withFilter && (
        <RightIcon onClick={onClick}>
          <SvgSettingIcon color={theme === "light" ? "#2D3741" : "#5DD3B3"} />
        </RightIcon>
      )}
    </SearchBarContainer>
  );
};

export default SearchWithFilter;
