import styled from "styled-components";

export const ADTSupperMainContainer = styled.div`
  width: 101.5%;
  display:flex;
  flex-direction:column;
  border-radius: 1.25rem 0rem 0rem 1.25rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
margin-bottom:3rem;
padding-bottom:1rem;

`;

export const ADTTableThDetails = styled.th`
  height: 2.7rem;
  border-collapse: collapse;
  /* padding: 0rem 1rem 0rem 2rem; */
  padding: 0 2.5rem 0rem 1rem;
  display: flex;
  justify-content:center;
  align-items:center;
/* background-color:aqua; */
text-align:center;
  width:16rem;
    &:nth-child(3){
      width:10rem;
      /* background-color:aqua; */
    }
`;

export const ADTTableThDetailsSmall = styled.th`
  height: 3rem;
  border-collapse: collapse;
  /* padding: 0rem 1rem 0rem 2rem; */
  padding: 0 2.5rem 0rem 1rem;
  display: flex;
  /* justify-content:left; */
  align-items:center;
  width:16rem;
    justify-content:center;

`;

export const ADTTableTd1 = styled.td`
  border: none;
height: 3.5625rem;
  border-collapse: collapse;
  /* padding: 0rem 1rem 0rem 2rem; */
  padding: 0 2.5rem 0rem 1rem;
text-align:center;
/* background-color:brown; */
  width:16rem;
    &:nth-child(3){
      width:10rem;
      /* background-color:aqua; */
    }

`;

export const ADTTableTdSmall = styled.td`
  border: none;
height: 3.5625rem;
  border-collapse: collapse;
  padding: 0 2.5rem 0rem 1rem;


      width:10rem;


`;