import React, { FC } from "react";
import { TeacherCalendarCardsProps } from "../TeacherCalendarCardsInterface";
import { DatesEventsStyles } from "./TeacherCalendarCards.style";
import KindsCard from "../KindsCard/KindsCard";
import EventCard from "../EventCard/EventCard";
import { Views } from "react-big-calendar";

const TeacherCalendarCards: FC<TeacherCalendarCardsProps> = ({
  events,
  index,

}) => {

  return (
    <>

        <EventCard events={events} index={index} />
     
  
    
    </>
  );
};

export default TeacherCalendarCards;
