import React, { ReactNode, createContext, useContext, useState } from 'react';

// Define the shape of the context
interface CourseCreatorForumProps {
  showTimeVisibility: boolean;
  setForumShowTimeVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  showGradingType: boolean;
  setForumShowGradingType: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRadio: string;
  setForumSelectedRadio: React.Dispatch<React.SetStateAction<string>>;
  forumName: string;
  setForumName: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setForumDescription: React.Dispatch<React.SetStateAction<string>>;
  startDate: Date | null;
  setForumStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  dueDate: Date | null;
  setForumDueDate: React.Dispatch<React.SetStateAction<Date | null>>;
  cutOffDate: Date | null;
  setForumCutOffDate: React.Dispatch<React.SetStateAction<Date | null>>;
  startTime: string;
  setForumStartTime: React.Dispatch<React.SetStateAction<string>>;
  endTime: string;
  setForumEndTime: React.Dispatch<React.SetStateAction<string>>;
  penalty: string;
  setForumPenalty: React.Dispatch<React.SetStateAction<string>>;
  maxGrade: string;
  setForumMaxGrade: React.Dispatch<React.SetStateAction<string>>;
  visibleStudents: boolean;
  setForumVisibleStudents: React.Dispatch<React.SetStateAction<boolean>>;
  fromDate: Date | null;
  setForumFromDate: React.Dispatch<React.SetStateAction<Date | null>>;
  toDate: Date | null;
  setForumToDate: React.Dispatch<React.SetStateAction<Date | null>>;
  attach: boolean;
  setForumAttach: React.Dispatch<React.SetStateAction<boolean>>;
  globalForumShowPopup: boolean;
  setGlobalForumShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context
const CourseCreatorForumContext = createContext<CourseCreatorForumProps | undefined>(undefined);

// Create a provider component
const CourseCreatorForumProvider = ({ children }: { children: ReactNode }) => {
  const [showTimeVisibility, setForumShowTimeVisibility] = useState(false);
  const [showGradingType, setForumShowGradingType] = useState(false);
  const [selectedRadio, setForumSelectedRadio] = useState("grade");
  const [globalForumShowPopup, setGlobalForumShowPopup] = useState<boolean>(false);
  const [forumName, setForumName] = useState("");
  const [description, setForumDescription] = useState("");
  const [startDate, setForumStartDate] = useState<Date | null>(null);
  const [dueDate, setForumDueDate] = useState<Date | null>(null);
  const [cutOffDate, setForumCutOffDate] = useState<Date | null>(null);
  const [startTime, setForumStartTime] = useState("");
  const [endTime, setForumEndTime] = useState("");
  const [penalty, setForumPenalty] = useState("");
  const [maxGrade, setForumMaxGrade] = useState("");
  const [visibleStudents, setForumVisibleStudents] = useState(true);
  const [fromDate, setForumFromDate] = useState<Date | null>(null);
  const [toDate, setForumToDate] = useState<Date | null>(null);
  const [attach, setForumAttach] = useState(false);

  return (
    <CourseCreatorForumContext.Provider
      value={{
        showTimeVisibility,
        setForumShowTimeVisibility,
        showGradingType,
        setForumShowGradingType,
        selectedRadio,
        setForumSelectedRadio,
        forumName,
        setForumName,
        description,
        setForumDescription,
        startDate,
        setForumStartDate,
        dueDate,
        setForumDueDate,
        cutOffDate,
        setForumCutOffDate,
        startTime,
        setForumStartTime,
        endTime,
        setForumEndTime,
        penalty,
        setForumPenalty,
        maxGrade,
        setForumMaxGrade,
        visibleStudents,
        setForumVisibleStudents,
        fromDate,
        setForumFromDate,
        toDate,
        setForumToDate,
        attach,
        setForumAttach,
        globalForumShowPopup,
        setGlobalForumShowPopup,
      }}
    >
      {children}
    </CourseCreatorForumContext.Provider>
  );
};

// Create a custom hook to access the context values
const ForumContext = () => {
  const context = useContext(CourseCreatorForumContext);
  if (!context) {
    throw new Error('ForumContext must be used within a CourseCreatorForumProvider');
  }
  return context;
};

export { CourseCreatorForumProvider, ForumContext };
