import styled, { keyframes } from "styled-components";

const flashingAnimation = (color: string) => keyframes`
  0%, 100% {
    box-shadow: none;
  }
  50% {
    box-shadow: 0 0 8px ${color};
  }
`;

export const CoursesNumberHolder = styled.div<{ color: string }>`
  border: 1px solid;
  border-color: ${(props) => props.color};
  border-radius: 50%;
  box-shadow: 0px 0px 4px ${(props) => props.color};
  padding: 0.3rem 0.3rem 0.3rem 0.3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  animation: ${({ color }) => flashingAnimation(color)} 3s infinite;
`;

export const OverlapGroup = styled.div<{ gradientcolor: string[] }>`
  background: linear-gradient(
    to right,
    ${(props) => props.gradientcolor[0]} 0%,
    ${(props) => props.gradientcolor[1]} 100%
  );
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Element = styled.div`
  color: #ffffff;
`;
