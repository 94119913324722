import React, { useContext, useEffect, useState } from "react";
import { ResourceFrom } from "../../../Views/courseView/content/Content";
import { LinkWrapper } from "./JoinSession.style";
import {
  LightTypography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../../utils/StringManipulation";
import { post } from "../../../utils/AxiosRequests";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
interface RecordingProps {
  ReceivedActivity: any;
  ReceivedActivityId: any;
  type: string;
}
export default function JoinSession({ ReceivedActivity, ReceivedActivityId, type }: RecordingProps) {
  const { progressChanged, setProgressChange } = useContext(ClassContext)

  const [ErrorSession, setErrorSession] = useState(String);
  const [issession, setSession] = useState("false");
  const params = useParams();
  const classId = params.classId;
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const { t } = useTranslation();

  useEffect(() => {
    setErrorSession("");
    setSession("false");
  }, [ReceivedActivity]);
  // console.log("ReceivedActivity is",ReceivedActivity);


  let startDateTimeUTC: Date | undefined;
  let startDateTimeString: string | number | Date;
  let today = new Date();
  if (ReceivedActivity && ReceivedActivity.session) {
    startDateTimeString = ReceivedActivity.session.startDateTime;

    // Create a Date object from the string (assumes the string is in UTC)
    const startDateTime = new Date(startDateTimeString);

    // Check if startDateTime is a valid Date object
    if (!isNaN(startDateTime.getTime())) {
      // Convert startDateTime to UTC to match the time zone of startDateTimeString
      startDateTimeUTC = new Date(startDateTime.toISOString());

      // Extract hours and minutes from startDateTimeUTC
      const hours = startDateTimeUTC.getUTCHours();
      const minutes = startDateTimeUTC.getUTCMinutes();

      // Use hours and minutes in your component logic
      startDateTimeUTC.setUTCHours(hours, minutes, 0, 0);

      // Ensure startDateTimeUTC is valid
      if (!isNaN(startDateTimeUTC.getTime())) {
        // console.log("startDateTimeUTC", startDateTimeUTC);
      } else {
        console.error("Invalid startDateTimeUTC:", startDateTimeUTC);
      }
    } else {
      console.error("Invalid startDateTime:", startDateTimeString);
    }
  }
  // console.log("ReceivedActivity.type----->", type)
  const handleOnclick = async () => {
    if (startDateTimeUTC) {
      if (startDateTimeUTC > today) {
        setErrorSession("This Session Has Not Started Yet.");
        setSession("true");
      } else {
        setSession("false");
        setErrorSession("");
        if (ReceivedActivity.path) {
          try {
            const trackingData = {
              classSourceId: classId,
              length: "",
              percentage: 100,
              refId: ReceivedActivityId,
              type: type,
              subId: subIdSplitted,
              provider:provider,
            }
            const trackinResponse = await post(`completionTracking/create/${tenantName}`, trackingData)
            setProgressChange(!progressChanged);

          } catch (error) {
            console.log(error)
          }

          window.open(ReceivedActivity.path, "_blank");
        }
      }
    }
  }
  const { SecondaryWhiteTextColor, SecondaryTextColor, bghelp, theme } = useRecoilValue(ColorSelector);
  return (
    <>
      <LinkWrapper onClick={handleOnclick} issession={issession} borderColor={bghelp}>
        <TypographyRegularDescription
          text={t("online session.Click here to join the session")}
          fontSize="0.75rem"
          color={issession === "false" ? SecondaryWhiteTextColor : "#d85d66"}
        />
      </LinkWrapper>{" "}
      {ErrorSession && (
        <LightTypography
          text={ErrorSession}
          fontSize="0.75rem"
          color="#d85d66"
        />
      )}
    </>
  );
}
