import React, { useContext, useEffect, useState } from "react";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import { CourseNotificationInterface } from "../CourseNotificationIterface";
import { CourseNotificationTeacherData } from "../CourseNotificationTeacher/CourseNotificationTeacherData";
import { CourseNotificationStdData } from "../NotificatinStudent/NotificatinStudentData";
import {
  CorseNotificationPopUpCardContainer,
  CorseNotificationPopUpGroup,
  CorseNotificationPopUpLine,
  CorseNotificationPopUpMiniGroup,
} from "./CorseNotificationPopUp.style";
import {
  LightTypography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import {
  CheckboxLabel,
  SeletectOptinToogle,
} from "../../ProfileCheckBoxOptions/NotificationSetting.style";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

interface CorseNotificationPopUpInterface {
  selectedOption: any[];
  setSelectedOption: React.Dispatch<React.SetStateAction<any[]>>;
}
const CorseNotificationPopUp = ({
  selectedOption,
  setSelectedOption,
}: CorseNotificationPopUpInterface) => {
  const { role } = useContext(ClassContext);
  const [compData, setCompData] = useState<CourseNotificationInterface[]>([]);
  // const [selectedOption, setSelectedOption] = useState<
  //   CourseNotificationInterface[]
  // >([]);

  useEffect(() => {
    if (role === "teacher") setCompData(CourseNotificationTeacherData);
    else setCompData(CourseNotificationStdData);
  }, [role]);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    title: string,
    titleValue: string,
    id: string,
    text: string,
    value: string
  ) => {
    const isChecked = event.target.checked;
    setSelectedOption((prevSelectedOption) => {
      // Find the index of the title in the selected options
      const titleIndex = prevSelectedOption.findIndex(
        (option) => option.titleValue === titleValue
      );
      // If title already exists
      if (titleIndex !== -1) {
        const newData = [...prevSelectedOption[titleIndex].Data];
        // If checkbox is checked, add the text to Data array, else remove it
        if (isChecked) {
          newData.push({ id, value });
        } else {
          const dataIndex = newData.findIndex((item) => item.id === id);
          if (dataIndex !== -1) {
            newData.splice(dataIndex, 1);
          }
        }
        return [
          ...prevSelectedOption.slice(0, titleIndex),
          { titleValue, Data: newData },
          ...prevSelectedOption.slice(titleIndex + 1),
        ];
      } else {
        // If title doesn't exist, create a new object
        const newData = isChecked ? [{ id, value }] : [];
        return [...prevSelectedOption, { titleValue, Data: newData }];
      }
    });
  };

  // Function to handle checkbox changes
  // const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { checked, value } = event.target;
  //   if (checked) {
  //     setSelectedOption((prevSelected) => [...prevSelected, value]); // Add selected text to the array
  //   } else {
  //     setSelectedOption((prevSelected) =>
  //       prevSelected.filter((text) => text !== value)
  //     ); // Remove deselected text from the array
  //   }
  // };

  const { checkboxBgColor, checkboxBorderColor, checkboxBorderRadius } =
    useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  return (
    <CorseNotificationPopUpCardContainer>
      {compData.map((item, index) => (
        <CorseNotificationPopUpGroup key={index}>
          <LightTypography text={t(item.title) || ""} fontSize="0.875rem" />
          <CorseNotificationPopUpMiniGroup>
            {item.Data &&
              item.Data.map((label) => (
                <CorseNotificationPopUpLine key={label.id}>
                  <SeletectOptinToogle
                    bgColor={checkboxBgColor}
                    borderColor={checkboxBorderColor}
                    borderRadius={checkboxBorderRadius}
                    type="checkbox"
                    id={label.id.toString()}
                    value={label.text}
                    onChange={(event) =>
                      handleCheckboxChange(
                        event,
                        item.title,
                        item.titleValue,
                        label.id,
                        label.text,
                        label.value
                      )
                    }
                  />
                  <CheckboxLabel
                    bgColor={checkboxBgColor}
                    borderColor={checkboxBorderColor}
                    borderRadius={checkboxBorderRadius}
                    htmlFor={label.id.toString()}
                  >
                    <TypographyRegularDescription
                      text={`${t(label.text)}`}
                      fontSize="1rem"
                    />
                  </CheckboxLabel>
                </CorseNotificationPopUpLine>
              ))}
          </CorseNotificationPopUpMiniGroup>
        </CorseNotificationPopUpGroup>
      ))}
    </CorseNotificationPopUpCardContainer>
  );
};

export default CorseNotificationPopUp;
