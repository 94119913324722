import styled from "styled-components";

export const Container = styled.div`
display: flex;
flex-direction: column;
gap: 1.12rem;
width: 21rem;
`
export const Title = styled.div`
display: flex;
align-items: center;
gap: 0.62rem;
`
