import styled from "styled-components";


export const TitlePageAndAddSeg = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
/* align-items: center; */
padding-bottom: 0.75rem;
`

export const GradeBookWrapper = styled.div`
max-width:100%;
width: auto;
/* min-height: auto; */
height: auto;
border-radius: 1.25rem;
display: flex;
flex-direction: column;
/* flex-wrap: wrap; */
/* opacity: 0.4;     */
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
padding-left: 1.25rem;
padding-right: 1.25rem;
padding-top: 1.25rem;
margin-left: 1.5rem;
gap: 1.25rem;

`

export const TitleIconRow = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
/* margin-bottom: 1.25rem; */
gap: 0.62rem;
`
export const TitlesWrapper = styled.div`
  display: flex;
  column-gap: 0.25rem;
  align-items: center;
  color: #fff;
  & :is(:first-child):hover {
    cursor: pointer;
    /* color: ${({ theme }) => theme.dark.main_color}; */
    transition: color 0.2s ease-in;
  }
`

export const TitleWithArrow = styled.div`
  display: flex;
  column-gap: 0.62rem;
  align-items: center;
  & :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
`;

export const SettingsDiv = styled.div`
display: flex;
gap: 0.62rem;
align-items: center;

`

export const AddNewSegRow = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
gap: 0.75rem;
/* padding-bottom: 1.25rem; */

`
export const AddNewSeg = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
gap: 0.75rem;
cursor: pointer;
`
export const SegementHolder = styled.div`
max-width: 77rem;
width: auto;
min-height: 15.9375rem;
max-height: auto;
border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));

padding-bottom: 2.69rem;
margin-bottom: 2.56rem;
padding-left: 1.25rem;
padding-right: 1rem;
padding-top: 1.25rem;
`
export const  SegmentFlex = styled.div`
display: grid;
 grid-template-columns: repeat(auto-fit , minmax(18.125rem, 1fr));
 grid-template-rows:3.75rem 3.75rem ;
 column-gap: 0.69rem;
row-gap: 0.94rem;
`

export const TitlePercentageIconsRow = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
gap: 0.75rem;
padding-bottom: 1.25rem;
padding-right: 1.25rem;
`
export const GradeBox = styled.div`
max-width: 18.75rem;
width: 10.75rem;
color: #FFF;
width: auto;
height: 3.75rem;
border-radius: 0.3125rem;
border: 1px solid #FFF;
display: flex;
justify-content: center;
align-items: center;
padding: 1rem;
`
export const SaveCancelButtonsRow = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 0.62rem;
margin-top: 1.25rem;
padding-bottom: 1.69rem;
`
export const ButtonCancel = styled.button`
width: 8.8125rem;
height: 2.125rem;

flex-shrink: 0;
border-radius: 1.25rem;
border: 1px solid #D85D66;
background:none;
cursor: pointer;
`
export const ButtonAdd = styled.button`
outline: none;
border: none;
width: 8.8125rem;
height: 2.125rem;

flex-shrink: 0;
border-radius: 1.25rem;
background: #5DD3B3;
cursor: pointer;
`
