// import { get } from "../utils/AxiosRequests";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
const BaseURL = "https://rpslsxq6sc.execute-api.eu-west-1.amazonaws.com/dev/";

const basePath = "tenant/data/1";
export const get = async (path: string) => {
  const response = await axios.get(`${BaseURL}${path}`);
  return response.data;
};
export const getLogo = async () => {
  try {
    const tenantName = localStorage.getItem("tenant");
    const response = await get(`/${basePath}/${tenantName}`);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
