import React, { FC, useEffect, useState } from "react";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit1.svg";
import { ReactComponent as ViewIcon } from "../../../assets/icons/Eye1.svg";
import { ReactComponent as AddIcon } from "../../../assets/icons/Add (2).svg";
import { ReactComponent as RefreshIcon } from '../../../assets/icons/Refresh.svg'
import {
  Typography,
  MediumTypography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import { QuestionCRUDTextEditorProps } from "./QuestionCRUDTextEditorInterface";
import {
  QuellStyle,
  StyledQuillEditor,
  QuestionIconsContainer,
  Icons,
  QuestionHeaderContainer,
  PointsInput,
  FeedBackContainer,
  Points,
  Tooltip,
  IconWrapper,
  CheckBox,
  CheckboxHolder,
} from "./QuestionCRUDTextEditor.style";
import { ErrorDiv } from "../../../elements/ErrorDiv/Errordiv.style";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";
const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "clean"],
    [{ header: [] }],
    [{ list: "ordered" }, { list: "bullet" }],

    [{ align: [] }],
    ["link", "code", "image"],
  ],
};

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "clean",
  "header",
  "header",
  "list",

  "align",
  "link",
  "code",
  "image",
];
const QuestionCRUDTextEditor: FC<QuestionCRUDTextEditorProps> = ({
  questionError,
  typeError,
  // handleQuestion,
  handleQuillChange,
  addChoice,
  quest,
  showQuest,
  handleShowEditQuest,
  setShowPreviewPopup,
  handleChoiceChecked,
  qindex,
  changeBranchName,
  allChoices
  // handleShuffle,
  // isShuffled
}) => {
  const { t } = useTranslation()
  const quillValue = `<p>${allChoices?.[qindex || 0]?.branch?.name}</p>`;

  // const [isShuffled, setIsShuffled] = useState<any>(false);
  const [isChecked, setIsChecked] = useState<any>(false);
  // Ensure that isShuffled state is properly initialized and updated
  // useEffect(() => {
  //   setIsShuffled(quest?.shuffle);
  // }, [quest?.shuffle]);

  useEffect(() => {
    setIsChecked(quest?.branching);
  }, [quest?.branching])

  // useEffect(() => {
  //   setIsChecked(isChecked);
  // }, []);


  return (
    <>
      {/* <QuestionHeaderContainer>
        <QuestionIconsContainer>
        <MediumTypography
            text="Create the set of questions when student chooses this answer"
            color={theme.dark.primary_text_color}
          />
        </QuestionIconsContainer>

      </QuestionHeaderContainer> */}

      <QuellStyle>
        {showQuest ? (
          <StyledQuillEditor
            show={showQuest.toString()}
            className="editor"
            // hasError={
            //   !!questionError &&
            //   allChoices &&
            //   (!allChoices[qindex || 0].branch?.name || allChoices[qindex || 0].branch?.name === '')
            // }
            theme="snow"
            modules={modules}
            formats={formats}
            value={quillValue}
            onChange={(value) =>
              changeBranchName && changeBranchName(qindex || 0, value.replace(/<\/?p>/g, ""))
            }
            placeholder={(t('course view.Type here your work'))}
            readOnly
          />
        ) : (
          <StyledQuillEditor
            show={showQuest?.toString()}
            className="editor"
            // hasError={
            //   !!questionError &&
            //   allChoices &&
            //   (!allChoices[qindex || 0].branch?.name || allChoices[qindex || 0].branch?.name === '')
            // }
            theme="snow"
            modules={modules}
            formats={formats}
            value={quillValue}
            onChange={(value) =>
              changeBranchName && changeBranchName(qindex || 0, value.replace(/<\/?p>/g, ""))
            }
            placeholder={(t('course view.Type here your work'))}
          />
        )}
      </QuellStyle>

      <FeedBackContainer>
        {quest?.type === "Short answer" ? (
          <MediumTypography
            text={(t('survey.Key Answers'))}
            fontSize="0.875rem"
            color={theme.dark.primary_text_color}
            lineHeight="1.375rem"
          />
        ) : quest?.type === "Scale" ||
          quest?.type === "Essay" ||
          quest?.type === "Rating" ||
          quest?.choices && quest.choices[qindex || 0]?.branch?.type ||
          quest?.type === "Yes-No" ? (
          ""
        ) : (
          <MediumTypography
            text="Choices*"
            fontSize="0.875rem"
            color={theme.dark.primary_text_color}
            lineHeight="1.375rem"
          />
        )}
        {quest?.type === "Scale" ||
          quest?.type === "Essay" ||
          quest?.type === "Rating" ||
          quest?.choices && quest.choices[qindex || 0]?.branch?.type ||
          quest?.type === "Yes-No" ? (
          ""
        ) : (
          <Icons>

            {quest?.type === "Short answer" ? (
              <Icons onClick={() => {
                if (!showQuest) {
                  addChoice && addChoice();
                }
              }}>
                <AddIcon />
                <Typography
                  text={(t('survey.Add Key Answers'))}
                  fontSize="0.875rem"
                  color={theme.dark.main_color}
                  lineHeight="1.375rem"
                />
              </Icons>
            ) : (
              <>
                {/* <IconWrapper>
              <RefreshIcon color={isShuffled ? "#5DD3B3" : ""} onClick={() => {
                const updatedShuffle = !isShuffled; 
                setIsShuffled(updatedShuffle); 
                handleShuffle && handleShuffle(updatedShuffle); 
              }}/>
              <Tooltip>Shuffle</Tooltip>
              </IconWrapper> */}
                <Icons onClick={() => {
                  if (!showQuest) {
                    addChoice && addChoice();
                  }
                }}>
                  <AddIcon />
                  <Typography
                    text="Add Choices"
                    fontSize="0.875rem"
                    color={theme.dark.main_color}
                    lineHeight="1.375rem"
                  />
                </Icons>
              </>
            )}
          </Icons>
        )}
      </FeedBackContainer>
    </>
  );
};

export default QuestionCRUDTextEditor;
