import React, { FC, useEffect, useRef, useState } from "react";
import {
  IconContainer,
  IconPointsContainer,
  QuestionFlexContainer,
} from "./QuestionContent.style";
import {
  TypographyRegularDescription,
  Typography,
  LightTypography,
} from "../../../elements/fonts/Fonts";
import { QuestionContentProps } from "./QuestionContentInterface";
import { theme } from "../../../theme";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import DOMPurify from "dompurify";
import StyledInput from "../../../elements/StyledInput/StyledInput";
import { useQuizMyContext } from "../../../contexts/CourseCreatorQuizContext";

const QuestionContent: FC<QuestionContentProps> = ({
  question,
  handleShowQuest,
  setIndex,
  index,
  handleGrade,
  quest,
  setQuest,
  onSave,
  setShowQuest,
  setNotValidate,
  notValidate
}) => {
  const { t } = useTranslation();
  const [questionGrade, setQuestionGrade] = useState<{ [key: string]: string }>(
    {}
  );
  const [showInput, setShowInput] = useState<{ [key: string]: boolean }>({})
  const {
    globalQuestionsArray,
    setGlobalQuestionsArray,
  } = useQuizMyContext();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const mapQuestionType = (questionType: string): string => {
    switch (questionType) {
      case "MCQ":
        return `${t("quiz.MULTIPLE CHOICE")}`;
      case "TF":
        return `${t("quiz.TRUE")} - ${t("quiz.FALSE")}`;
      case "SHORT_ANSWER":
        return `${t("quiz.SHORT ANSWER")}`;
      case "NUMERIC":
        return `${t("quiz.NUMERICAL")}`;
      case "ESSAY":
        return `${t("quiz.ESSAY")}`;
      case "MATCH":
        return `${t("quiz.Matching")}`;
      default:
        return questionType;
    }
  };

  const questionColor = (questionType: string): string => {
    switch (questionType) {
      case "MCQ":
        return `#0B8FFF`;
      case "TF":
        return `#8CEDEA`;
      case "SHORT_ANSWER":
        return `#5D69FD`;
      case "NUMERIC":
        return `#8E5DFD`;
      case "ESSAY":
        return `#B757E2`;
      case "MATCH":
        return `#5DD3B3`;
      default:
        return questionType;
    }
  };

  const questionType = mapQuestionType(question.type || "");
  const { OpacitiesTextColor, SecondaryTextColor, theme } =
    useRecoilValue(ColorSelector);
  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };
  const handleGradeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleGrade && handleGrade(e);
    const updatedQuest = { ...questionGrade };
    if (e.target.id === "grade") {
      updatedQuest.grade = e.target.value;
    }
    setQuestionGrade &&
      setQuestionGrade((prevId) => ({
        ...prevId,
        [`Question ${index}`]: updatedQuest.grade,
      }));
    let newQuestions = [...globalQuestionsArray];
    if (newQuestions[index].questType === "normal") {
      
        newQuestions[index].grade = e.target.value;
      
    } 
    const filteredQuestions = newQuestions.filter(
      (question) => question.type !== ""
    );
    
    setGlobalQuestionsArray(filteredQuestions);
  };

  useEffect(() => {
    setQuestionGrade &&
      setQuestionGrade((prevId) => ({
        ...prevId,
        [`Question ${index}`]: question && question.grade && question.grade,
      }));
  }, [question && question.grade && question.grade,notValidate]);

  const handleDoubleClick = ()=>{
    setShowInput &&
      setShowInput((prevId) => ({
        ...prevId,
        [`Question ${index}`]: true,
      }));
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setShowInput((prevId) => ({
          ...prevId,
          [`Question ${index}`]: false,
        }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef]);

  return (
    <IconPointsContainer>
      <div style={{ color: `${questionColor(question.type || "")}` }}>
        {question.icon}
      </div>
      <QuestionFlexContainer>
        <IconContainer>
          <TypographyRegularDescription
            text={questionType}
            fontSize="0.75rem"
            lineHeight="1.125rem"
            color={
              theme === "light" ? SecondaryTextColor : SecondaryTextColor + "80"
            }
          />
          <div
          ref={inputRef}
          onDoubleClick={handleDoubleClick}
         onClick={() => {
          // setShowQuest();
          setIndex(index);
          // setNotValidate && setNotValidate(true)
        }}
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            {showInput[`Question ${index}`] === true ?
            <StyledInput
            // borderColor="transparent"
            type="number"
            width="2.5rem"
            height="2.5rem"
            textAlign="center"
            color="#5DD3B3"
            value={questionGrade[`Question ${index}`]}
            id="grade"
            onChange={handleGradeChange}
            fontSize="0.75rem"
            padding={"0.94rem .25rem"}
          /> :
          <>
          <LightTypography  fontSize="0.75rem" text={questionGrade[`Question ${index}`]} color="#5DD3B3"/>
          <div style={{marginRight:"0.4rem"}}></div>
          </>
          
          }
            
            <Typography
              text={`${t("course view.pts")}`}
              fontSize="0.75rem"
              color="#5DD3B3"
            />
          </div>
        </IconContainer>
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(decodeHtml(question?.question)),
          }}
          style={{
            fontSize: "0.75rem",
            lineHeight: "1.125rem",
            color: theme === "light" ? SecondaryTextColor : SecondaryTextColor + "80",
          }}
        ></p>
      </QuestionFlexContainer>
    </IconPointsContainer>
  );
};

export default QuestionContent;
