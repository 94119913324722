import styled from "styled-components";

interface ModuleContainerProps {
  bgcolor: string;
  boxshadow: string;
}
export const ModuleContainer = styled.div<ModuleContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  width: 23.625rem !important;
  min-height: 10.8125rem;
  height: fit-content;
  border-radius: 0.625rem;
  background: ${(props) => props.bgcolor};
  box-shadow: ${(props) => props.boxshadow};
  padding: 1rem 1.44rem 1.44rem 1.44rem;
  margin-bottom: 2rem;
`;

export const TopicsContainer = styled.div`
  width: 100%;
`;

export const ModuleContainerDragging = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 23.625rem;
  /* min-height: 10.8125rem; */
  min-height: fit-content;
  border-radius: 0.625rem;
  border: solid 1px #5dd3b3;
  background: linear-gradient(180deg, #415160 0%, #293440 100%);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem 1.44rem 1.44rem 1.44rem;
  margin-bottom: 2rem;
  opacity: 0.3;
`;

export const ModuleHeader = styled.div`
  width: 100%;
  min-height: fit-content;
  display: flex;
  align-items: center;
  column-gap: 0.63rem;
  position: relative;
  /* background-color: cyan; */
  & :is(:nth-child(3), :nth-child(4)):hover {
    cursor: pointer;
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }

  //& :is(:nth-child(1), :nth-child(2)):hover {
  // cursor: grab;
  /* cursor: grabbing; */
  // }
`;

export const DivideLineContainer = styled.div`
  width: 65%;
  height: 200%;
  position: absolute;
  top: -0.5rem;
  right: 2.6rem;
  cursor: -webkit-grab;
`;

interface DivideLineProps {
  lineiconcolor: string;
}
export const DivideLine = styled.div<DivideLineProps>`
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.lineiconcolor};
  width: 100%;
  /* cursor: grab; */
`;

interface AddTopicIconWrapperProps {
  color: string;
}
export const AddTopicIconWrapper = styled.button<AddTopicIconWrapperProps>`
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  color: ${(props) => props.color};
`;

export const HDotsWrapper = styled.div`
  width: 0.625rem;
  height: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyTopicsTextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.63rem;
  margin-top: 2.81rem;
`;

export const AddTopicActiveWrapper = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
  & > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  & > :last-child {
    position: absolute;
    top: 0.31rem;
    left: 0.19rem;
    z-index: 3;
    color: #5dd3b3;
  }
`;
