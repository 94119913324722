import styled, { css } from "styled-components";
import { breakpoints } from "../../theme";

interface FaqRectangleHolderProps {
  expanded?: string;
  boxshadow?: string;
}
export const FaqRectangleHolder = styled.div<FaqRectangleHolderProps>`
  width: 100%; /* Set the width to 100% for responsiveness */
  /* max-width: 39.3125rem;  */
  height: 5.2rem;
  /* max-height: 5rem; */
  /* min-width: auto; */
  min-height: auto;
  /* border-radius: 0.625rem 0.625rem 0 0; */
  /* border: 1px solid #576371; */
  /* background: #2D3741; */
  box-shadow: ${(props) => props.boxshadow};
  display: flex;
  justify-content: space-between;
  column-gap: 7.69rem;
  align-items: center;
  padding-left: 1.87rem;
  padding-right: 2rem;
  cursor: pointer;
  /* background-color:aqua; */
  @media (max-width: ${breakpoints.mobile}) {
    /* Add styles for screens with a maximum width of tablets breakpoint */
    flex-direction: column;
    align-items: center;
    column-gap: 1rem;
    margin-top: 1rem;
  }
  ${(props) =>
    props.expanded === "true" &&
    css`
      /* border-radius: 0.625rem; */
    `}
`;
export const AnswerHolder = styled.div<FaqRectangleHolderProps>`
  width: 100%; /* Set the width to 100% for responsiveness */
  /* max-width: 39.3125rem;  */
  height: auto;
  /* max-height: 0px; */
  border-radius: 0rem 0rem 0.625rem 0.625rem;
  /* background: rgba(87, 99, 113, 0.72); */
  padding: 2.44rem 1.94rem 2.44rem 1.88rem;
  height: 8.5rem;
  /* transform-origin: top; 
  transform: scaleY(0); /*
  transition: transform 0.5s cubic-bezier(0, 1, 0, 1); 

${(props) =>
    props.expanded &&
    css`
      transform: scaleY(1);
    `} */
`;
export const FaqRectangleWrapper = styled.div`
  width: 100%;
  margin-bottom: 0.62rem;
  break-inside: avoid;
  border-radius: 0.625rem 0.625rem 0 0 !important;
`;

export const ToggleArrowButton = styled.button<FaqRectangleHolderProps>`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  ${(props) =>
    props.expanded &&
    css`
      transform: rotate(
        360deg
      ); /* Rotate the button when rotated state is true */
    `}
`;
