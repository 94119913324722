import { CertificateInterface } from "./certificateInterface";
import QrCode from "../../assets/Certificate QR.jpg";
import LeftSignature from "../../assets/Signature.jpg";
import UniLogo from "../../assets/uniLogo.jpeg";
import Stamp from "../../assets/stamp.jpg";
import { SvgBadge } from "../../elements/Icons";

export const CertificateData: CertificateInterface = {
  templateType: "one",
  qrCode: QrCode,
  studentName: "Student Name",
  courseName: "ppt test",
  certificateDate: "09 Jan, 2024",
  leftSignature: LeftSignature,
  leftName: "Juliana Silva",
  leftPosition: "Representative",
  rightSignature: LeftSignature,
  rightName: "Pedro Fernandes",
  rightPosition: "Representative",
  uniLogo: UniLogo,
  stamp: Stamp,
  badge: SvgBadge,
};
