import React from 'react'
import { SvgNoResult } from '../../../../elements/Icons'
import { Typography } from '../../../../elements/fonts/Fonts'
import { useTranslation } from 'react-i18next'

const LoadingGradebook = () => {
  const  {t} = useTranslation()
  return (
    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", }} >
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "20rem", textAlign: "center", }} >
        <SvgNoResult width={"10rem"} />
        <Typography text={`${t("general.loading your data")}...`} />
      </div>
    </div>
  )
}

export default LoadingGradebook