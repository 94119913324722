import styled from "styled-components";
import { breakpoints } from "../../../../theme";
// import DateP   ker from 'react-datepicker';
// import TimePicker from 'react-time-picker';

export const TextInputSpace = styled.input`
width:auto;  
display:flex;
justify-content:flex-end;
margin-top:0.31rem;
background-color: ${props => (props.disabled ? "red" : "transparent")};
border: 0px solid #576371;
color:#fff;
font-size: 0.875rem;
font-family: 'Primary Font-Medium';
outline: none;
`;
export const StyledInput = styled.div<{isopen:any, width?:any, border?:any, padding?:any, height?:any; background?: string}>`
 width: ${(props) => (props.width ? props.width : '18rem')};
height: ${(props) => (props.height ? props.height : '2.8125rem')}; 
border-radius: ${props=>props.isopen==='true'?'0.625rem 0.625rem 0rem 0rem':'0.625rem'};
border: ${(props) => (props.border ? props.border : '1px solid #576371')};

/* padding: 0.87rem 0.87rem 0.87rem 0.87rem; */
padding:${(props) => (props.padding ? props.padding : '0.87rem 0.87rem 0.87rem 0.87rem')};
border-radius: 0.625rem 0.625rem 0rem 0rem;
${(props) => (props.isopen === 'true' ? `
    background: ${props.background || 'var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%))'};
  ` : '')}


::placeholder{
font-family: 'Primary Font-Light';
font-size: 0.875rem;
color: gray;
font-style: normal;
font-weight: 400;
line-height: normal;
}
/* @media (max-width: ${breakpoints.large}) {
    width: 27.8125rem;
    height: 3.4375rem;
  }
  @media (max-width: ${breakpoints.small}) {
    width: 20.9925rem;
    height: 3.4375rem;
  } */
 
`;
export const SelectContainer = styled.div<{zIndex:number}>`
   position: relative; 
  cursor:pointer; 
  z-index: ${(props) => (props.zIndex ? props.zIndex : '99')};
  color:#fff;
font-size: 0.875rem;
font-family: 'Primary Font-Medium';

`;
export const Select = styled.div<{color:string}>`
display: flex;
justify-content: space-between;
align-items:center;
 &:hover{
  color:${(props) => (props.color ? props.color : '')} ;
} 
`;
export const Arrow = styled.div<{marginLeft:any}>`
  margin-right: 0.81rem;
  margin-left:${(props) => (props.marginLeft ? props.marginLeft : '')};
  margin-top: -0.4rem;
`;
// export const DatePickerStyle = styled(DatePicker)`
// background-color:transparent;
// border: 0px solid #576371;
// color:#fff;
// font-size: 0.875rem;
// font-family: 'Primary Font-Medium';
// font-size: 0.875rem;
// height: 1.2rem;
// min-width: 18rem;
// width: 100%;
// &:focus {
//     border: 0px solid #576371;
//     outline: none;
//   }
// `;
// export const TimePickerStyle= styled(TimePicker)`
// background-color:transparent;
// border: 0px solid #576371;
// color:#fff;
// font-size: 0.875rem;
// font-family: 'Primary Font-Medium';
// font-size: 0.875rem;
// height: 1.2rem;
// min-width: 18rem;
// width: 100%;
// &:focus {
//     border: 0px solid #576371;
//     outline: none;
//   }
// `;
// export const TextAreaLongTextAreaInput = styled.textarea`
//   border-radius: 0.6rem;
//   border: 1px solid #576371;
//   box-sizing: border-box;
//   background-color: transparent;
//   color:#fff;
//    min-height:11rem;
//   width:100%;
//   padding:1rem;
//   outline: none;
//   resize: none;
//   font-family: 'Primary Font-Medium';
// font-size: 0.875rem;
// font-style: normal;
// font-weight: 400;
// line-height: 1.375rem;
//   &::placeholder{
// font-family: 'Primary Font-Medium';
// font-size: 0.875rem;
// color: #FFF ;
// /* width: 11.23094rem; */
// font-style: normal;
// font-weight: 400;
// line-height: 1.375rem;
// }
//   @media (max-width: ${breakpoints.tablets}) { 
//     width:20rem;
//   }
// `;


export const OptionsContainer = styled.div<{optionWidth?:any}>`
position: relative;
top:0.8rem;
bottom:0;
right: 0.9rem;
width: ${(props) => (props.optionWidth ? props.optionWidth : '18rem')};


`;

export const Options=styled.div<{index:any,lastitem:any, display:string ,  optionIcon?: React.ReactNode | null}>`
  background: #1F2731; 
 font-size: 0.875rem;
 cursor: pointer;
  padding: 1rem;
  font-family: 'Primary Font-Bold';
  display:${(props) => (props.display ? props.display : 'flex')};
  gap:0.63rem;
  border-radius:${props=>(props.lastitem===props.index? '0rem 0rem 0.625rem 0.625rem':'')} ;
  &:hover {
    background: #5DD3B3;
  }
`;

export const MainOptionContainer=styled.div<{fontSize?:any}>`
display: flex;
gap:0.63rem;
font-size:${(props) => (props.fontSize ? props.fontSize : '0.875rem')};
`;