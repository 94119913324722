import DOMPurify from "dompurify";
import { v4 as uuidv4 } from "uuid";

export const generateUUID = (): string => {
  return uuidv4();
};
export const auth0SubIdSplitter = (subId: string): string => {
  return subId.split("|")[1];
};
export const auth0ProviderSplitter = (subId:string): string => {
  return subId.split("|")[0];
}
export const uuidSplitter = (fileName: string): string => {
  if (fileName) {
    return fileName.replace(
      /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}/,
      ""
    );
  }
  else {
    return ""
  }
};
const decodeHtml = (value: any) => {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = value;
  return textarea.value;
};
export const htmlDecoder = (desc: string): string => {
  // Decode HTML entities first
  const decodedDesc = decodeHtml(desc);

  // Check for the presence of an iframe tag
  const hasIframe = /<iframe.*?>.*?<\/iframe>/i.test(decodedDesc);

  // If there is an iframe, return the decoded description without sanitizing
  if (hasIframe) {
    return decodedDesc;
  }

  // Otherwise, sanitize the decoded description
  const sanitizedDescription = DOMPurify.sanitize(decodedDesc);
  return sanitizedDescription;
};
export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
