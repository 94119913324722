import React, { useEffect, useRef, FC, useState } from "react";

// import WebViewer from "@pdftron/webviewer";
import {
  PdfViewrContainer,
  Pdfcontainer,
  SideToolsContainer,
  BottomMarging,
} from "./PdfViewer.style";
import PopUp from "../../../../elements/Popup/PopUp";
import SideTools from "../SideTools/SideTools";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import GraderDiscussion from "../../GraderDiscussion/main/GraderDiscussion";
import { ReactComponent as CommentIcon } from "../../../../assets/icons/Messaging.svg";
import { ReactComponent as AssignmentIcon } from "../../../../assets/icons/Assignment.svg";
import {
  Discussion,
  Marks,
  AssignmentQuestions,
} from "../PdfViewerDataInterface";
import AssignmentRubric from "../../../AssignmentRubric/main/AssignmentRubric";
import { PdfViewerProps } from "../PdfViewerInterface";
import AssignmentQuiz from "../../../SurveyQuiz/main/SurveyQuiz";
import DocumentViewer from "../../../documentViewer/DocumentViewer";
import PdfViewerV2 from "./PdfViewerV2";
import GraderAssignmentAnswer from "../../GraderStatistics/GraderAssigmentAnswer/GraderAssigmentAnswer";
import { SvgSimpleArrowLeft } from "../../../../elements/Icons";
import { LightTypography } from "../../../../elements/fonts/Fonts";
import { uuidSplitter } from "../../../../utils/StringManipulation";
const PdfViewer: FC<PdfViewerProps> = ({
  student,
  studentIndex,
  showQuiz,
  showSubmitedTime,
  setShowSubmitedTime,
  showLate,
  showNavIcon,
  updateSubmittedByInstructor,
  allAssignment,
  quiz,
  questions,
  handleQuestionIndexChange,
  resourceR,
}) => {
  const viewer = useRef<HTMLDivElement | null>(null);
  const [showComment, setShowComment] = useState<boolean>(true);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [showDiscussion, setShowDiscussion] = useState<boolean>(false);
  const [showRubric, setShowRubric] = useState<boolean>(false);
  const [showRating, setShowRating] = useState<boolean>(false);
  const [documentName, setDocumentName] = useState<string | null>(null);
  const [showPdfViewer, setShowPdfViewer] = useState<boolean>(false);
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [doc, setDoc] = useState<string>("");
  const [resolvedDocumentType, setResolvedDocumentType] = useState<
    string | null
  >(null);
  const [discussionData, setDiscussionData] = useState<Discussion[]>([
    {
      image: "/assets/images/image Khaled Khaled.png",
      name: "Khaled Khaled",
      content:
        "Meeting Details dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      image: "/assets/images/image Khaled Khaled.png",
      name: "Khaled Khaled",
      content:
        "Meeting Details dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      image: "/assets/images/image Khaled Khaled.png",
      name: "Khaled Khaled",
      content:
        "Meeting Details dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  ]);
  const [documentstudent, setDocument] = useState<string>("");
  const [marks, setMarks] = useState<Marks[]>([
    {
      type: "structure",
      mark: 10,
      markStatus: "Full Marks",
    },
    {
      type: "structure",
      mark: 10,
      markStatus: "Full Marks",
    },
    {
      type: "structure",
      mark: 10,
      markStatus: "Full Mark",
    },

    {
      type: "structure",
      mark: 10,
      markStatus: "Full Mark",
    },
    {
      type: "structure",
      mark: 10,
      markStatus: "Full Mark",
    },
    {
      type: "content",
      mark: 10,
      markStatus: "Full Mark",
    },
    {
      type: "content",
      mark: 10,
      markStatus: "Full Mark",
    },
    {
      type: "content",
      mark: 10,
      markStatus: "Full Mark",
    },
    {
      type: "content",
      mark: 10,
      markStatus: "Full Mark",
    },
    {
      type: "content",
      mark: 10,
      markStatus: "Full Mark",
    },
  ]);

  const handleOpenSearch = () => {
    if (showComment) {
      setShowComment(false);
      setShowSearch(true);
    } else {
      setShowSearch(!showSearch);
    }
  };
  const handleOpenComment = () => {
    setShowDiscussion(true);
  };
  const handleRubric = () => {
    setShowRating(true);
    setShowSubmitedTime(false);
  };
  const ref = useRef();
  const [keyD, setKey] = useState(0);

  useEffect(() => {
    if (student && student.length > 0) {
      const newDoc = student[studentIndex].submissionFile;
      const documentName = student[studentIndex].fileName;
      setDocument(newDoc);
      setDocumentName(documentName);
      const documentstudent = newDoc;
      setKey((prevKey) => prevKey + 1);
      setDoc(newDoc);
    }
  }, [
    student,
    studentIndex,
    showQuiz,
    showComment,
    showDiscussion,
    showRubric,
    showSearch,
  ]);

  const handleChangeView = () => {
    setShowPdfViewer(!showPdfViewer);
  };

  const handelDownloadClick = () => {};

  return (
    <>
      <PdfViewrContainer>
        {quiz && quiz === true ? ( // This component will is for the survey and the quiz.
          <Pdfcontainer>
            <AssignmentQuiz
              submission={student}
              studentIndex={studentIndex}
              questions={questions || []}
              questionIndex={questionIndex}
              setQuestionIndex={setQuestionIndex}
              handleQuestionIndexChange={handleQuestionIndexChange}
            />
          </Pdfcontainer>
        ) : showPdfViewer ? ( // The PDF viewer will be not shown in the quiz and the survey.
          <Pdfcontainer>
            <BottomMarging onClick={handleChangeView}>
              <SvgSimpleArrowLeft />
              <LightTypography text="Back" />
            </BottomMarging>
            {/* The DocViewer is a library that render a viewer for the pdf */}
            <DocViewer
              key={keyD}
              theme={{
                textPrimary: "white",
                secondary: "#2d3741",
              }}
              config={{
                header: {
                  disableHeader: false,
                  disableFileName: false,
                  retainURLParams: false,
                },
                pdfVerticalScrollByDefault: true,
              }}
              documents={[
                {
                  uri: documentstudent,
                  fileType: resolvedDocumentType || "pdf",
                  fileName: uuidSplitter(documentName || "") || "",
                },
              ]}
              pluginRenderers={DocViewerRenderers}
              style={{
                height: "110vh",
                width: "100%",
                backgroundColor: "#384450",
              }}
            />
          </Pdfcontainer>
        ) : (
          // This component is the for the Forum and the quiz.
          <GraderAssignmentAnswer
            EyeClick={handleChangeView}
            DownloadClick={handelDownloadClick}
            Name={
              (student &&
                student.length > 0 &&
                student[studentIndex].user.fName +
                  " " +
                  student[studentIndex].user.lName) ||
              " "
            }
            Subject={
              (student &&
                student.length > 0 &&
                student[studentIndex].textAnswer) ||
              "No Answer"
            }
            fileName={
              (student &&
                student.length > 0 &&
                student[studentIndex].fileName) ||
              ""
            }
            profilePic=""
            filePath={
              (student &&
                student.length > 0 &&
                student[studentIndex].submissionFile) ||
              ""
            }
          />
        )}

        {/* This is the side tools component. (on the right) */}
        {/* <SideToolsContainer> */}
        <SideTools
          showComment={showComment}
          setShowComment={setShowComment}
          showSearch={showSearch}
          setShowSearch={setShowSearch}
          handleOpenSearch={handleOpenSearch}
          handleOpenComment={handleOpenComment}
          setShowRubric={setShowRubric}
          showRating={showRating}
          showSubmitedTime={showSubmitedTime}
          showNavIcon={showNavIcon}
          showLate={showLate}
          student={student}
          studentIndex={studentIndex}
          allAssignment={allAssignment}
          resourceR={resourceR}
          // updateSubmittedByInstructor={updateSubmittedByInstructor}
        />
        {/* </SideToolsContainer> */}
      </PdfViewrContainer>
      {showDiscussion ? (
        <PopUp
          content={<GraderDiscussion discussionData={discussionData} />}
          icon={<CommentIcon />}
          title="Discussion"
          showline={false}
          show={showDiscussion}
          setShow={setShowDiscussion}
          marginTop="5rem"
          showButton={false}
        />
      ) : showRubric ? (
        <PopUp
          content={<AssignmentRubric marks={marks} />}
          icon={<AssignmentIcon />}
          showline={false}
          show={showRubric}
          setShow={setShowRubric}
          title="Assignment Rubric"
          marginTop="8rem"
          handleSubmit={handleRubric}
          buttonName="Save"
        />
      ) : (
        ""
      )}
    </>
  );
};

export default PdfViewer;
