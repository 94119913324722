import React, { useEffect, useState } from "react";
import { FullMainRow } from "../../../Views/Analytics/AnalyticsPage/AnalyticsPage.style";
import ChartsHeadTitle from "../../Charts/ChartsHeadTitle/ChartsHeadTitle";
import { GraphExcelDownload } from "../../../utils/ExcelGlobalFunction";
import GraphChartCompo from "../../Charts/GraphChart/GraphChartCompo";
import FooterGradeChanger from "../../Charts/FooterGradeChanger/FooterGradeChanger";

interface AnalyticsAssignmentGraphChartProps {
  Data?: any[];
  MaxGrade: number;
  titleClick?: () => void;
  title?: string;
}

export interface GraphChartDataItem {
  val: number;
  name: string;
  avg: number;
}

const AnalyticsAssignmentGraphChart: React.FC<
  AnalyticsAssignmentGraphChartProps
> = ({
  Data,
  MaxGrade,
  titleClick,
  title,
}: AnalyticsAssignmentGraphChartProps) => {
  const [GraphChartData, setGraphChartData] = useState<GraphChartDataItem[]>(
    []
  );
  const [FirstAverage, setFirstAverageAverage] = useState<number>(0);
  const [GradeAdditionValue, setGradeAdditionValue] = useState<number>(0);
  const [ActiveNewGrade, setActiveNewGrade] = useState<boolean>(false);
  const [ChartData, setChartData] = useState<any[]>([]);

  const [Average, setAverage] = useState<number>(0);

  useEffect(() => {
    if (Data && Data.length > 0) {
      const gradeMap = new Map<number, number>();
      let totalVal = 0;

      Data.forEach((student) => {
        if (student.Grade !== undefined) {
          const existingCount = gradeMap.get(student.Grade) || 0;
          gradeMap.set(student.Grade, existingCount + 1);
          totalVal += student.Grade;
        }
      });

      const avg = totalVal / Data.length;

      const updatedGraphChartData: GraphChartDataItem[] = Array.from(
        gradeMap.entries()
      ).map(([val, count]) => ({
        val,
        name: count.toString(),
        avg: parseFloat(avg.toFixed(2)),
      }));

      setGraphChartData(updatedGraphChartData);
      setFirstAverageAverage(parseFloat(avg.toFixed(2)));
    }
  }, [Data]);

  useEffect(() => {
    // Ensure ChartData is updated only when necessary
    if (GraphChartData.length > 0) {
      const updatedChartData = GraphChartData.map((entry) => ({
        ...entry,
        NewAvg: ActiveNewGrade ? entry.avg : undefined, // Set NewAvg only if ActiveNewGrade is true
      }));
      setChartData(updatedChartData);
    }
  }, [GraphChartData, ActiveNewGrade]);

  useEffect(() => {
    // Update ChartData with GradeAdditionValue
    setChartData((prevData) =>
      prevData.map((entry, index) => {
        const newValC = entry.val + GradeAdditionValue; // Calculate ValC based on val and GradeAdditionValue
        if (
          newValC >= MaxGrade - 0.5 &&
          newValC >= (prevData[index]?.ValC || 0)
        ) {
          return { ...entry, ValC: prevData[index]?.ValC || entry.val }; // Set ValC based on the condition
        } else {
          return { ...entry, ValC: newValC };
        }
      })
    );
  }, [GradeAdditionValue, ActiveNewGrade, MaxGrade]);

  const UpdateGraph = () => {
    const updatedData = ChartData.map((entry) => ({
      ...entry,
      valA: entry.ValC,
    }));
    setActiveNewGrade(false);
    setGradeAdditionValue(0);
  };

  useEffect(() => {
    // This useEffect runs only once when the component mounts
    setChartData((prevData) =>
      prevData.map((entry) => ({
        ...entry,
        ValC: entry.ValC !== null ? entry.ValC : 0, // Ensure ValC is not null
      }))
    );
  }, []); // Empty dependency array ensures this effect runs only once
  useEffect(() => {
    const totalNewValC = ChartData.reduce(
      (sum, dataItem) => sum + dataItem.ValC,
      0
    );
    const calculatedAverage = totalNewValC / (ChartData.length + 1);
    setAverage(calculatedAverage);

    if (calculatedAverage <= MaxGrade - 0.5) {
      const newAvgValue = calculatedAverage; // Calculate the new average value
      setChartData((prevData) =>
        prevData.map((entry) => ({
          ...entry,
          NewAvg: newAvgValue, // Set the same new average value for all entries
        }))
      );
    }
  }, [GradeAdditionValue, ActiveNewGrade, MaxGrade, ChartData]);

  return (
    <div>
      <FullMainRow style={{ height: "30rem" }}>
        <ChartsHeadTitle
          Title={title || "Grade Distribution"}
          text="Graded"
          handleDownload={() =>
            GraphExcelDownload(
              GraphChartData,
              `${
                title
                  ? `${title} Bar Chart Download`
                  : "Grade Distribution Graph Download"
              }`
            )
          }
          titleClick={titleClick}
        />
        <div style={{ height: "20rem" }}>
          <GraphChartCompo
            dataKeyA={ActiveNewGrade ? "ValC" : "val"}
            dataKeyB={ActiveNewGrade ? "val" : ""}
            data={ChartData}
            Avg={ActiveNewGrade ? "NewAvg" : "avg"}
            ShowdataKeyB={true}
            dataKey="name"
          />
        </div>
        <FooterGradeChanger
          GradeAdditionValue={GradeAdditionValue}
          setGradeAdditionValue={setGradeAdditionValue}
          setActiveNewGrade={setActiveNewGrade}
          handleUpdateGraph={UpdateGraph}
        />
      </FullMainRow>
    </div>
  );
};

export default AnalyticsAssignmentGraphChart;
