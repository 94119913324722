import styled from "styled-components";

export const ModuleTopic = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 0.62rem;
`;
export const Description = styled.div`
  margin-top: 0.62rem;
`;

export const ScormViewCont = styled.div`
width: 100%;
margin-bottom: 1.25rem;
border-radius: 0.625rem;
position: relative;
`;
export const ScormFileName = styled.div`
width: 100%;
border-radius: 0.625rem 0.625rem 0rem 0rem;
background: #384450;
box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
padding: 1.25rem;
position: absolute;
top: 0;
`;
export const ScormBox = styled.div`
width: 100%;
background: #384450;
border-radius: 0.625rem;
height: 40.5rem;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 2.5rem;
`;
export const ProgressDiv = styled.div`
display: flex;
justify-content: flex-end;
margin: 1.25rem 0;
`;
