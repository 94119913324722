import { MenuItem } from "./sidebarInterface";
import {
  SvgDashboardIcon,
  SvgCoursesIcon,
  SvgCalendarIcon,
  SvgTodoListIcon,
  SvgMessageIcon,
  SvgNotesIcon,
  SvgAnalysisIcon,
  SvgProgressIcon,
  SvgCourses2Icon,
  SvgAnnouncement,
  SvgHelpIcon,
} from "../../../elements/Icons";

export const menuData: MenuItem[] = [
  {
    icon: SvgDashboardIcon,
    title: "sidebar.dashboard",
    path: "/dashboard",
  },
  {
    icon: SvgAnnouncement,
    title: "sidebar.announcement",
    path: "/announcements",
  },
  {
    icon: SvgCalendarIcon,
    title: "sidebar.calendar",
    path: "/calendar",
  },
  {
    icon: SvgTodoListIcon,
    title: "sidebar.to do list",
    path: "/to-do",
  },
  {
    icon: SvgNotesIcon,
    title: "sidebar.notes",
    path: "/Notes",
  },
  // {
  //   icon: SvgAnalysisIcon,
  //   title: "Analytics",
  //   path: "/Analytics",
  // },
  // {
  //   icon: SvgProgressIcon,
  //   title: "Progress",
  //   path: "/#",
  // },
  {
    icon: SvgHelpIcon,
    title: "sidebar.help",
    path: "/help",
  },
];
//   {
//     icon: SvgDashboardIcon,
//     title: "Dashboard",
//     path: "/dashboard",
//   },
//   {
//     icon: SvgAnnouncement,
//     title: "Announcements",
//     path: "/announcements",
//   },
//   {
//     icon: SvgCalendarIcon,
//     title: "Calendar",
//     path: "/calendar",
//   },
//   // {
//   //   icon: SvgTodoListIcon,
//   //   title: "To do List",
//   //   path: "/to-do",
//   // },
//   {
//     icon: SvgNotesIcon,
//     title: "Notes",
//     path: "/Notes",
//   },
//   // {
//   //   icon: SvgAnalysisIcon,
//   //   title: "Analytics",
//   //   path: "/Analytics",
//   // },
//   // {
//   //   icon: SvgProgressIcon,
//   //   title: "Progress",
//   //   path: "/#",
//   // },
//   {
//     icon: SvgHelpIcon,
//     title: "Help",
//     path: "/help",
//   },
// ];

// export default menuData;
