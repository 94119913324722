/**
 * Function to filter data based on search text and status search text,
 * and return the sliced portion of the filtered data.
 * @param {any[]} data - The array of data to be filtered.
 * @param {string} searchText - The text to be searched.
 * @param {string} StatusSearchText - The status search text.
 * @param {number} startIndex - The start index for slicing the filtered data.
 * @param {number} endIndex - The end index for slicing the filtered data.
 * @returns {any[]} - The filtered and sliced data.
 */
export const filteredDataGet = (
  data: any[],
  searchText: string,
  StatusSearchText: string,
  startIndex: number,
  endIndex: number
): any[] => {
  return data.filter((item) => {
    const matchesSearchText = Object.values(item)
      .join(" ")
      .toLowerCase()
      .includes(searchText.toLowerCase());
    let matchesSubmittedSearchText = true;

    if (StatusSearchText === "Participants") {
      matchesSubmittedSearchText =
        item.Last_Participation !== undefined &&
        item.Last_Participation !== null;
    } else if (StatusSearchText === "Not Participants") {
      matchesSubmittedSearchText =
        item.Last_Participation === undefined ||
        item.Last_Participation === null ||
        (Array.isArray(item.Last_Participation) &&
          item.Last_Participation.length === 0);
    } else if (StatusSearchText === "Participants after Date") {
      matchesSubmittedSearchText =
        item.Last_Participation !== undefined &&
        item.Last_Participation !== null &&
        item.DueDate !== undefined &&
        item.DueDate !== null &&
        item.Last_Participation > item.DueDate;
    }

    return matchesSubmittedSearchText && matchesSearchText;
  }).slice(startIndex, endIndex);
};


