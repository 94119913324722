import styled from "styled-components";
import { theme } from "../../../theme";
import CheckIcon from "../../../assets/icons/Group2199.svg";
export const CustomCheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: .62rem;
`;

export const BranchName = styled.div`
margin-left: 2rem;
margin-top: 1rem;
font-family: Primary Font-bold;
`

export const TrueFalseContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.62rem;
  padding: 0 1.5rem 0 1.5rem;
  width: 100%;
  opacity: 1;
  &:nth-child(1){
    /* opacity: .5; */
  }
`;

// export const SurveyChoicesDisplay = styled.div`
//   display: flex;
//   width: 100%;
//   gap: 0.62rem;
//   justify-content: flex-start;
// `;

export const TrueFalseInputs = styled.div<{
  width: any;
  show: any;
  center: any;
}>`
  width: ${(props) => (props.width ? props.width : "")};
  height: 2.8125rem;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  background: transparent;
  padding-left: ${(props) => (props.center === "yes" ? "1.88rem" : "1.25rem")};
  font-family: "Primary Font-Light";
  color: ${theme.dark.primary_text_color};
  background: ${(props) => (props.show === "true" ? "#546270" : "")};
  display: flex;
  align-items: center;
`;

export const CheckBox = styled.input`
  width: 1rem;
  height: 1rem;
  background: linear-gradient(180deg, #2d3741 0%, #576371 100%),
    linear-gradient(0deg, #ffffff, #ffffff);

  appearance: none;
  border: 1px solid ${theme.dark.primary_text_color};

  border-radius: 50%;
  transition: background-color 0.3s ease;

  &:checked {
    /* background: linear-gradient(180deg, #5DD3B3 0%, #2D3741 100%),
linear-gradient(0deg, #5DD3B3, #5DD3B3);
border: 1px solid #5DD3B3 */
    background-image: url(${CheckIcon}); /* Use the CheckIcon component as a URL */
    background-size: cover; /* Adjust this as needed */
    background-repeat: no-repeat;
    background-position: center;
    background: linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%);
    border: none;
  }
`;
