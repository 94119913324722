import React, { FC, useEffect, useState } from "react";
import { ShowTaskContainer, ShowTaskFlexContainer, ShowMainContainer, Line, DeadLineStatus, } from "./ToDoShow.style";
import { ToDoShowProps } from "../ToDoShowinterface";
import { LightTypography, Typography } from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import { useTranslation } from "react-i18next";
const ToDoShow: FC<ToDoShowProps> = ({ task }) => {
    const [timeDifferenceString, setTimeDifferenceString] = useState<string>("");
    const [isTaskOverdue, setIsTaskOverdue] = useState<boolean>(false);
    const [isTaskNearDeadline, setIsTaskNearDeadline] = useState<boolean>(false);
    const { t } = useTranslation();
    useEffect(() => {
        const taskDate = task.date ? new Date(task.date) : null;
        const currentDate = new Date();
    
        if (taskDate) {
          const timeDifferenceMs = +taskDate - +currentDate;
          const hoursDifference = Math.floor(timeDifferenceMs / 3600000);
          const minutesDifference = Math.floor(
            (timeDifferenceMs % 3600000) / 60000
          );
    
          if (timeDifferenceMs < 0) {
            setTimeDifferenceString(t("to do list.Over Due"));
          } else {
            if (hoursDifference >= 24) {
              const daysDifference = Math.floor(hoursDifference / 24);
              setTimeDifferenceString(
                `${daysDifference} ${t("calendar.Day")}${daysDifference > 1 && localStorage.getItem("language") === 'en' ? 's' : ''}`
              );
            } else {
              setTimeDifferenceString(
                `${hoursDifference} ${t("to do list.hours")} ${minutesDifference} ${t("to do list.minutes")}`
              );
            }
          }
    
          setIsTaskOverdue(taskDate < currentDate);
          setIsTaskNearDeadline(
            taskDate > currentDate && timeDifferenceMs <= 24 * 3600000
          );
        }
      }, [task.date, t]);
  return (
    <ShowTaskContainer>
      <ShowMainContainer>
        <ShowTaskFlexContainer>
          <LightTypography text={`${t("to do list.Enrollment")} :`} fontSize="1rem" color={theme.dark.primary_text_color} />
          <Typography text={task.enrollment === "Personal" ? t(`to do list.${task.enrollment}`) : (task.enrollment || "")} fontSize="0.875rem" color={theme.dark.primary_text_color} />
        </ShowTaskFlexContainer>

        <ShowTaskFlexContainer>
          <LightTypography text={`${t("forms.Due Date")} :`} fontSize="1rem" color={theme.dark.primary_text_color} />
        <Typography text={task.date ? `${task.date}` : t("to do list.No due date")} fontSize="0.875rem" color={theme.dark.primary_text_color} />
        </ShowTaskFlexContainer>

        <ShowTaskFlexContainer>
          <LightTypography text="Assignment By :" fontSize="1rem" color={theme.dark.primary_text_color} />
          <Typography text="Sultan Al Rajhi" fontSize="0.875rem" color={theme.dark.primary_text_color} />
        </ShowTaskFlexContainer>

        {(isTaskOverdue || isTaskNearDeadline) && (
        <ShowTaskFlexContainer>
          <LightTypography text={`${t("to do list.Task Status")} :`} fontSize="1rem" color={theme.dark.primary_text_color} />
          <DeadLineStatus>
            <Typography text={isTaskOverdue ? t("to do list.Over Due") : t("to do list.Near Deadline")} fontSize="0.875rem" color={theme.dark.primary_text_color} />
            <Line color={isTaskOverdue ? "#D85D66" : "#D07E4B"}/>
          </DeadLineStatus>
        </ShowTaskFlexContainer>
        )}

        {task.webLink && (
        <ShowTaskFlexContainer>
          <LightTypography text={`${t("to do list.Web Link")} :`}  fontSize="1rem" color={theme.dark.primary_text_color} />
          <Typography text={`${task.webLink}`} fontSize="0.875rem" color={theme.dark.primary_text_color} />
        </ShowTaskFlexContainer>
        )}

        {task.description && (
        <ShowTaskFlexContainer>
          <LightTypography text={`${t("forms.Description")} :`}  fontSize="1rem" color={theme.dark.primary_text_color} />
          <Typography text={`${task.description}`} fontSize="0.875rem" color={theme.dark.primary_text_color} />
        </ShowTaskFlexContainer>
        )}
      </ShowMainContainer>
    </ShowTaskContainer>
  );
};

export default ToDoShow;
