import React, { ReactElement, useState } from "react";
import SurveyReusableHeader from "./SurveyReusableHeader";
import SurveyComplexResponsesTable from "./SurveyResponsesTable";
import { AnalyticMainBoxContainer } from "../../AnalyticsAllAssigment.style";
import SurveyTableFilteredOptions from "./SurveyTableFilteredOptions";
import Pagination from "../../../../elements/Pationation/Pagination";

/**
 * Interface for the props of MainSurveyReusableTable component.
 */
interface MainSurveyReusableTableInterface {
  title?: string; // Title of the table (optional).
  onClickExcelDowload?: () => void; // Function triggered on Excel download button click (optional).
  data: any[]; // Array of data to display in the table.
  SelectedNameTitleLabel: string; // Label for the selected name/title.
  FistElementChanges: ReactElement[]; // Array of React elements for first element changes.
  marginTop?: string; // Top margin for the table (optional).
  titleClick?: () => void; // Function triggered on title click (optional).
}

/**
 * MainSurveyReusableTable Component
 * @param {MainSurveyReusableTableInterface} props - Props for MainSurveyReusableTable component.
 * @returns {ReactElement} - MainSurveyReusableTable component.
 */
const MainSurveyReusableTable = ({
  title,
  data,
  onClickExcelDowload,
  SelectedNameTitleLabel,
  FistElementChanges,
  marginTop,
  titleClick,
}: MainSurveyReusableTableInterface): ReactElement => {
  const [searchText, setSearchText] = useState(""); // State for search text.
  const [StatusSearchText, setStatusSearchText] = useState(""); // State for status search text.
  const [limit, setLimit] = useState(data.length); // State for limit.
  const itemsPerPage = limit; // Items per page.
  const [currentPage, setCurrentPage] = useState(1); // State for current page.
  const [ShowPagination, setShowPagination] = useState(false); // State for pagination visibility.
  const startIndex = (currentPage - 1) * itemsPerPage; // Start index for pagination.
  const endIndex = startIndex + itemsPerPage; // End index for pagination.

  // Filtered data limited to current limit.
  const filteredDataLimited = data.slice(0, limit);

  // Filtered data based on search text, status search text, and pagination.
  const filteredData = data
    .filter((item) => {
      const matchesSearchText = Object.values(item)
        .join(" ")
        .toLowerCase()
        .includes(searchText.toLowerCase());

      const matchesStatusSearchText =
        StatusSearchText === "" || item.Status === StatusSearchText;

      return matchesSearchText && matchesStatusSearchText;
    })
    .slice(startIndex, endIndex);

  return (
    <AnalyticMainBoxContainer>
      {/* SurveyReusableHeader Component */}
      <SurveyReusableHeader
        data={data}
        setShowPagination={setShowPagination}
        title={title || ""}
        onClickExcelDowload={onClickExcelDowload}
        setSearchText={setSearchText}
        setLimit={setLimit}
        HeaderTextClick={titleClick}
        FilteredOptions={
          <SurveyTableFilteredOptions
            setStatusSearchText={setStatusSearchText}
          />
        }
      />
      {/* SurveyComplexResponsesTable Component */}
      <SurveyComplexResponsesTable
        data={filteredData}
        SelectedNameTitleLabel={SelectedNameTitleLabel}
        FistElementChanges={FistElementChanges}
        marginTop={marginTop}
      />
      {/* Pagination Component */}
      {ShowPagination && (
        <Pagination
          currentPage={currentPage}
          total={data.length}
          limit={itemsPerPage}
          onPageChange={(page: number) => setCurrentPage(page)}
          MarginTop="1rem"
          justifyContent="left"
          Padding="0 0 0 5.5rem"
          BoxSize="1.85rem"
          ScrollTop={false}
        />
      )}
    </AnalyticMainBoxContainer>
  );
};

export default MainSurveyReusableTable;
