import { styled } from "styled-components";
import { breakpoints } from "../../theme";

interface justifyP {
  justifyContent?: string;
}
export const LineFLex = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.7rem;
  margin-bottom: 2.3rem;
`;

export const ButtonFLex = styled.div<justifyP>`
  display: flex;
  gap: 1rem;
  margin: 1rem 0 !important;
  padding-top:1rem;
  height:4rem;

  /* justify-content:flex-end; */
  justify-content: ${(props) => props.justifyContent || "flex-end"};
  padding-right: 2.5rem;
  @media screen and (max-width: ${breakpoints.tablets}) {
    flex-direction: column;
    gap: 0.5rem !important;
  }
`;

export const PopUplineStyle = styled.div<{ isvisible: string }>`
  display: ${(props) => (props.isvisible ? "block" : "none")};
  opacity: ${(props) => (props.isvisible ? "1" : "0")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: opacity 500ms;
  overflow: auto;
  z-index: 10;
`;


export const  ButtonFlex =styled.div`
margin-bottom:1rem;
display:flex;
`;

export const CloseIconContainerPopUpLine = styled.div`
 margin:-2.2rem 1rem 0 0  !important;
 cursor:pointer;
`;



