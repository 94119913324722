import React, { useContext, useEffect, useState } from 'react'
import { SvgInstructorEye, SvgInstructorEyeSlash } from '../../../elements/Icons';
import axios from 'axios';
import { ClassContext } from '../../../Views/dashboard/UnstructuredContext';
import { useRecoilState } from 'recoil';
import { visibilityChange } from '../../../recoil/RecoilStates';
interface VisibilityProps {
    isVisible: boolean;
    contentId: number;
    setVisible: any;
    // visibility: boolean;
    // handleVisibility: (isVisible: boolean, contentId: number) => Promise<void>;
}

export default function VisibilityComponent({ isVisible, contentId, setVisible }: VisibilityProps) {
    const [visibility, setVisibility] = useState<boolean>(isVisible)
    // const { setContentVisibility } = useContext(ClassContext)
    const tenantName = localStorage.getItem("tenant");
    // useEffect(() => {
    //     setVisible((prev: any) => !prev);
    // }, [visibility])
    const [, setContentVisibility] = useRecoilState(visibilityChange)
    const handleVisibility = async (isVisible: boolean, contentId: any) => {
        // setVisible((prev: any) => !prev);
        setVisibility(isVisible)
        try {
            const visibilityData = {
                contentId: contentId,
                isVisible: isVisible
            }
            const response = await axios.patch(`https://9ws5f31duk.execute-api.eu-west-1.amazonaws.com/dev/content/isVisible/${tenantName}`,
                { data: visibilityData }
            )
            setVisible((prev: any) => !prev);
            setContentVisibility((prev: any) => !prev);
            // setVisible((prev: any) => !prev);

        } catch (error) {
            console.log("error in posting visibility", error);
        }
    }
    return (
        visibility ?
            <SvgInstructorEye color="#5dd3b3" style={{ cursor: "pointer" }} onClick={() => {
                handleVisibility(false, contentId)
            }
            }
            /> :
            <SvgInstructorEyeSlash color="#5dd3b3" style={{ cursor: "pointer" }} onClick={() => {
                handleVisibility(true, contentId)
            }
            } />
    )
}
