import styled from "styled-components";
import { breakpoints } from "../../../theme";
interface CourseCardDetailHolderProps {
  language?: string | null;
}
interface CourseCardAdminWrapperProps {
  bgimage?: string;
  bgcolor?: string;
  role: string;
  theme?: string;
  language?: string | null;
}
export const AdminCourseCardDetails = styled.div<CourseCardDetailHolderProps>`

  position: relative;

  height: 11.5rem;
  direction: ${(props) => (props.language === "ar" ? "rtl" : "ltr")};
`;

export const CourseCardOverlay = styled.div<CourseCardAdminWrapperProps>`
  position: absolute;
  top: 0;
  right: ${(props) => (props.language === "ar" ? "0" : "")};
  opacity: ${(props) => (props.theme === "light" ? "0.35" : "0.25")};
  z-index: 1;
  /* background-color:blue; */
  /* height: 12.0625rem; */
  height: 9.35rem;
  width: 20.25rem;
  background: ${(props) => props.bgcolor};
  background: ${(props) =>
    props.bgimage
      ? `url(${props.bgimage}), ${props.bgcolor}`
      : `${props.bgcolor}`};
  background-size: cover;
  /* opacity: 0.2; */
  /* background-position: center; */
  background-repeat: no-repeat;
  border-radius: 1.88rem;
  box-shadow: 0px 3px 6px #00000029;
  border: 0px none;
  padding: 1.1rem 1.44rem;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  filter: brightness(110%);
  transition: filter 0.3s ease, box-shadow 0.3s ease; /* Combine transitions */

  &:hover {
    /* filter: brightness(100%);  */
    box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.15);
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 19rem;
  }
`;
export const AdminRoleButtons = styled.div`
display:flex;
column-gap:0.62rem;
`
export const TopHolder = styled.div`
  display: flex;
  width: 100%;
align-items:center;
  /* flex-direction: row-reverse; */
  justify-content: space-between;
  &:dir(ltr) {
    flex-direction: row;
  }
  &:dir(rtl) {
    flex-direction: row-reverse !important;
  }
`;
export const AdminCourseCardWrapper = styled.div<CourseCardAdminWrapperProps>`
  position: absolute;
  top: 0;
  right: ${(props) => (props.language === "ar" ? "0" : "")};
  z-index: 2;
  /* height: 12.0625rem; */
  height: 9.35rem;
  /* background-color:red; */
  width: 20.25rem;
  border-radius: 1.88rem;
  box-shadow: 0px 3px 6px #00000029;
  border: 0px none;
  padding: 1.38rem 1.3rem 1.19rem 1.5rem;

  display: flex;
  flex-direction: column;
  /* gap: 0.7rem; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  row-gap:0.5rem;

  &:hover {
    /* box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.15); */
    transition: box-shadow 0.4s ease-in;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 19rem;
  }
`;