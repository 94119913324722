import { useState, useEffect, useContext, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AnnouncementsCourseViewHolder,
  TitleWithIconsWrapper,
  IconsWrapper,
  AnnouncementsCardsHolder,
  DropDownContainer,
} from "./AnnouncementsCourseView.style";
import { PageHeader } from "../../../components/pageHeader/PageHeader";

import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import AnnouncementsCard from "../../../components/announcementsCard/AnnouncementsCard";
import IconWithText from "../../../elements/iconWithText/main/IconWithText";
import {
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import {
  SvgSortBy,
  SvgAddNew,
  SvgEmpty,
  SvgNoResult,
  SvgBgProfLight,
  SvgBgProfDark,
  SvgSettingIcon,
} from "../../../elements/Icons";
// import Pagination from "../../../elements/reusablePagination/main/ReusablePagination";
import Pagination from "../../../elements/Pationation/Pagination";
import DropDownFilter from "../../../components/dropDownFilter/main/DropDownFilter";
import useClickOutside from "../../../hooks/useClickOutside";
import useToggle from "../../../hooks/useToggle";
import AnnouncementsCardsData from "../../../components/announcementsCards/announcementsCardsData";
import { CourseCardData } from "../../../components/courseCard/CourseCardData";
import Empty from "../../../components/courseCard/Empty";
import { ClassContext } from "../../dashboard/UnstructuredContext";
import { get } from "../../../utils/AxiosRequests";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../../utils/StringManipulation";
import { useTranslation } from "react-i18next";
import { WebSocketConnector } from "../../messages/webSocketConnection";
import axios from "axios";
import { WebsocketContext } from "../../../contexts/notificationContext";
import SelectDropDown from "../../../elements/selectDropDown/SelectDropDown";
import { filterOption } from "./Annoucements.data";
import { formatOptionsForTranslation } from "../../../utils/FormatOptionsForTranslation";
import IconDropDown from "../../../elements/iconDropDown/IconDropDown";
interface LocalAnnouncementData {
  id: string;
  title: string;
  content: any;
  iconPath: string;
  fullName: string;
  email: string;
  createdAt: string;
  path: string;
  userSourceId: string;
  isRead: boolean;
  teacher: {
    sourceId: string;
    username: string;
    email: string;
    profilePicture: string;
  };
}

const AnnouncementsCourseView = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    role,
    getUnvewedAnnouncmentCount,
    DashBoardAnnouncementsCurrentPage,
    setDashBoardAnnouncementsCurrentPage,
  } = useContext(ClassContext);
  const { classId } = useParams();
  const { user } = useAuth0();
  const [profilePictures, setProfilePictures] = useState<any>({});
  const [loading, setLoading] = useState<Boolean>(true);
  const imageGif = require("../../../assets/loadingGif.gif");
  const navigate = useNavigate();
  const { isOpen: isDropdownVisible, toggle: toggleDropdown } =
    useToggle(false);
  const dropdownRef = useClickOutside(isDropdownVisible, toggleDropdown);
  const [activeTab, setActiveTab] = useState(false);
  const itemsPerPage = 3;

  const [localData, setLocalData] = useState<LocalAnnouncementData[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const startIndex = (DashBoardAnnouncementsCurrentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const loc = useLocation();
  const tenantName = localStorage.getItem("tenant");
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const { theme, themeTextColor } = useRecoilValue(ColorSelector);
  const { isReady, value, send, connect } = useContext(WebsocketContext);
  const [selectedFilter, setSelectedFilter] = useState<string>("Latest");

  // Fetch Announcment Data from the database
  const fetchAnnounecmenttData = async () => {
    try {
      const announcmentData = await axios.get(
        `https://yhntwosoqe.execute-api.eu-west-1.amazonaws.com/dev/announcement/all/${subIdSplitted}/${tenantName}?provider=${provider}`
      );
      const sortedData = announcmentData.data.data.sort(
        (a: LocalAnnouncementData, b: LocalAnnouncementData) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      setLocalData(sortedData);
      setDataLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      !isReady &&
      tenantName !== null &&
      tenantName !== undefined &&
      subIdSplitted !== null &&
      subIdSplitted !== undefined
    ) {
      connect();
    }
  }, [tenantName, subIdSplitted]);

  useEffect(() => {
    var data = JSON.parse(value);
    // console.log("Recieved Message---->",data)

    if (data && data.type && data.type === "announcement") {
      fetchAnnounecmenttData();
      getUnvewedAnnouncmentCount();
    }
  }, [value]);

  //Call the fetchAnnounecmenttData function when the component mount
  useEffect(() => {
    fetchAnnounecmenttData();
    getUnvewedAnnouncmentCount();
  }, []);

  useEffect(() => {
    // This useEffect is fetching the profile picture of all the users
    if (localData && localData.length > 0) {
      const fetchProfilePictures = async () => {
        try {
          setLoading(true);
          const tenantName = localStorage.getItem("tenant");

          // Create an array of promises to fetch profile pictures for all students
          const promises = localData.map(async (announecment) => {
            const response = await get(
              `user/profilePicture/${tenantName}/${announecment.userSourceId}`
            );
            return {
              userId: announecment.userSourceId,
              profilePicture: response.data,
            };
          });

          // Wait for all promises to resolve
          const profilePics = await Promise.all(promises);
          setLoading(false);

          // Convert the array of profile pictures into an object
          const profilePictureObject = profilePics.reduce(
            (acc: any, pic: any) => {
              acc[pic.userId] = pic.profilePicture;
              return acc;
            },
            {}
          );

          setProfilePictures(profilePictureObject); // Set the profile pictures
        } catch (error) {
          console.error("Error fetching profile pictures:", error);
          // Handle error if needed
        }
      };

      fetchProfilePictures();
    }
  }, [localData]);
  const [filtredDataAnnounc, setFiltredDataAnnounc] = useState<any[]>([]);

  const handleFilterChange = (option: any) => {
    setSelectedFilter(option)
  }

  useEffect(() => {
    const filteredData = [...localData]; // Create a copy of localData to avoid mutating the original array

    if (selectedFilter === 'Latest') {
      filteredData.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    } else if (selectedFilter === 'Earliest') {
      filteredData.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    }

    setFiltredDataAnnounc(filteredData);
  }, [selectedFilter, localData]);


  const currentAnnouncementsCards =
    filtredDataAnnounc?.length > 0 ? filtredDataAnnounc.slice(startIndex, endIndex) : [];

  const translatedOption = formatOptionsForTranslation(filterOption, t)

  if (
    role === "student" &&
    loc.pathname === `/courseView/announcements/${classId}`
  ) {
    navigate("/dashboard");
    return <></>;
  }

  return (
    <>
      <AnnouncementsCourseViewHolder>
        <TitleWithIconsWrapper>
          <TypographyRegularDescription
            text={t("dashboard.Announcements")}
            fontSize="1.75rem"
          />
          <IconsWrapper>
            <div
              style={{ color: `${activeTab ? "#5DD3B3" : "white"}` }}
              ref={dropdownRef}
              onClick={() => {
                toggleDropdown();
                setActiveTab((prev) => !prev);
              }}
            ></div>
            {loc.pathname === `/courseview/announcements/${classId}` ?
              role === "teacher" && (
                <Link
                  to={`/courseview/announcements/new/`}
                  state={{ some: classId }}
                >
                  <IconWithText
                    icon={SvgAddNew}
                    textElement={
                      <MediumTypography text="ADD NEW" fontSize="0.75rem" />
                    }
                    margin="0.2rem 0 0 0"
                  />
                </Link>
              ) : (
                <DropDownContainer>
                  <IconDropDown onChange={(option) => handleFilterChange(option)} placeholder={t('Sort By')} border="none" color={themeTextColor} width="fit-content" height="1.5rem" mainIcon={<SvgSettingIcon />} options={translatedOption} value={selectedFilter} paddingRL="0" />
                </DropDownContainer>
              )}
          </IconsWrapper>
          {isDropdownVisible && <DropDownFilter />}
        </TitleWithIconsWrapper>
        {dataLoading && ( // if data is Loading
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SvgNoResult width={"10rem"} />
              <Typography text={`${t("general.loading")}...`} />
            </div>
          </>
        )}
        {currentAnnouncementsCards.length === 0 && !dataLoading ? ( // if the announcements data is empty
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SvgNoResult width={"10rem"} />
              <Typography text={t("course view.No Announcements Yet")} />
            </div>
          </>
        ) : (
          <AnnouncementsCardsHolder>
            {currentAnnouncementsCards && // The announcements data is mapping Here
              currentAnnouncementsCards.map((item, index) => (
                // <Link to={item.path} key={index}>
                <AnnouncementsCard
                  key={index}
                  iconBg={theme === "light" ? SvgBgProfLight : SvgBgProfDark}
                  id={item.id}
                  title={item.title}
                  iconPath={
                    !loading ? profilePictures[item.userSourceId] : imageGif
                  }
                  fullName={item.teacher.username}
                  email={item.teacher.email}
                  userId={item.userSourceId}
                  date={item.createdAt}
                  role={role}
                  classId={classId}
                  currentPage={DashBoardAnnouncementsCurrentPage}
                  setCurrentPage={setDashBoardAnnouncementsCurrentPage}
                  itemsPerPage={itemsPerPage}
                  announecmenttData={currentAnnouncementsCards}
                  description=""
                  fetchDataFromLocalStorage={() => {
                    fetchAnnounecmenttData();
                  }}
                  isRead={item.isRead}
                />
                // </Link>
              ))}
          </AnnouncementsCardsHolder>
        )}
        {currentAnnouncementsCards.length > 0 && (
          <Pagination
            currentPage={DashBoardAnnouncementsCurrentPage}
            total={localData.length}
            limit={itemsPerPage}
            onPageChange={(page: number) =>
              setDashBoardAnnouncementsCurrentPage(page)
            }
          />
        )}
      </AnnouncementsCourseViewHolder>
    </>
  );
};

export default AnnouncementsCourseView;
