// ThemeSelectors.js

import { selector } from "recoil";
import { themeState } from "./RecoilStates";
const language = localStorage.getItem("language");
const themeColors = {
  dark: {
    primary_color: "#fff",
    primary_text_color: "#fff",
    secondary_text_color: "#D9E4E8",
    main_color: "#5dd3b3",
    secondary_color: "#364350",
    main_gradientColor: "linear-gradient(180deg, #2d3741 0%, #576371 100%)",
    gradient_color_submenu: ["#5DD3B3", "#2F6A5A"],
    gradient_color_courseLabel: ["#5DD3B3", "#3CA36B"],
    submenu_color: "#D9E4E8",
    submenu_hover_color: "#fff",
    bg_main_color: "#2d3741",
    bg_secondary_color: "#384450",
    bg_Dark_Gray: "#576371",
    bg_select: "#1F2731",
    box_shadow_allClasses: "0 0 1rem rgba(255, 255, 255, 0.3)",
    forum_dark_Grey: "#415160",
    Chats_dark_Grey: "#434F5B",
    toggle_gradientColor: "linear-gradient(135deg, #415160 0%, #293440 100%)",
    textHeaderForInput_Profile: "#fff",
    SearchCell_BgColor: "#FFFFFF80",
    borderColorA: "#576474",
    checkbox_gradientColor:
      "linear-gradient(180deg, #2d3741 0%, #576371 100%), linear-gradient(0deg, #ffffff, #ffffff)",
    //needs to update
    Checked_checkbox_gradientColor:
      "linear-gradient(180deg, #2d3741 0%, #576371 100%), linear-gradient(0deg, #ffffff, #ffffff)",
    Message_ChatContainerBgColor: "#37444f",
    progressColor: "#20272E",
    light_Gray_Opacities1: "#AAB1B910",
    ActiveContentTabBgColor: "linear-gradient(0deg, #2D3741 0%, #576371 100%)",
    ContentDocBgColor: "linear-gradient(180deg, #576371, #313c47)",
    gradientTable_BGColor: "linear-gradient(180deg, #415160 0%, #293440 100%)",
    bluish_gray: "#293440",
    boxShadowV2: "0 0 10px rgba(0, 0, 0, 0.2)",
    timePicker_FieldBgColor: "#323C4680",
    // sideBarIconTextColor:"#384450",
    // TL === "TimeLine"
    // TL_Modules_bgColor:"#576371",
    readOnly_BgColor: "#546270",
  },
  light: {
    primary_text_color: "#2D3741",
    secondary_text_color: "#D9E4E8",
    main_color: "#5dd3b3",
    main_gradientColor: "linear-gradient(180deg, #5DD3B3 0%, #2F6A5A 100%)",
    secondary_color: "#364350",
    gradient_color_submenu: ["#5DD3B3", "#2F6A5A"],
    gradient_color_courseLabel: ["#5DD3B3", "#3CA36B"],
    submenu_color: "#D9E4E8",
    submenu_hover_color: "#fff",
    bg_main_color: "#F6F6F6",
    bg_secondary_color: "#fff",
    bg_Light_Grey: "#e7e8ea",
    text_input_color_A: "#364350",
    box_shadow_allClasses: "0 0 1rem rgba(0, 0, 0, 0.3)",
    bg_Normal_Grey: "#E4E6E8",
    // lightGrey_input: "#AAB1B9",
    // toggle_gradientColor: "linear-gradient(to bottom, #F7F7F8 0%, rgba(87, 100, 116, 0.3) 50%, #F7F7F8 100%)",
    message_Solid_Grey: "#707070",
    lightGrey_Color: "#AAB1B9",
    VeryLightGrey_Color: "#F7F7F8",
    SearchCell_BgColor: "#FFFFFF",
    lightGrey_input: "#AAB1B980",
    toggle_gradientColor:
      "linear-gradient(to bottom, #F7F7F8 0%, rgba(87, 100, 116, 0.2) 40%, rgba(87, 100, 116, 0.2) 60%, #F7F7F8 100%)",
    lightGrey_ColorOpacity6: "#AAB1B960",
    progressColor: "#E7EAEB",
    NormalBoxShadow:
      "box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
    GradientDashBoardCardBgColor:
      // "linear-gradient(to bottom left, #2f6a5a, #5dd3b3)",
      "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)",
    sideBarIconTextColor: "#89949C",
    boxShadowV2: "0px 4px 14px 0px rgba(192, 220, 214, 0.32)",
    // File_Gradient_BgColor: " linear-gradient(180deg, #F7F7F8 0%, rgba(87, 100, 116, 0.00) 100%) ",
    timePicker_FieldBgColor: "rgba(170, 177, 185, 0.1)",
    readOnly_BgColor: "#F7F7F8",
  },
};
export const MainColorSelector = selector({
  key: "MainColors",
  get: ({ get }) => {
    const theme = get(themeState);
    const themeGlobal = get(themeState);
    const mainBackgroundColor =
      theme === "light"
        ? themeColors.light.bg_main_color
        : themeColors.dark.bg_main_color; // background colors based on theme
    const mainColor =
      theme === "light"
        ? themeColors.light.primary_text_color
        : themeColors.light.bg_Light_Grey; // colors based on theme

    const BodyTheme = theme;
    return {
      mainBackgroundColor,
      mainColor,
      theme,
      BodyTheme,
      themeGlobal,
    };
  },
});

export const ColorSelector = selector({
  key: "Colors",
  get: ({ get }) => {
    const theme = get(themeState);
    const iconCloseColor = theme === "light" ? "#2D3741" : "#5DD3B3";
    const boxShadow =
      theme === "light"
        ? "0px 4px 20px 0px rgba(192, 220, 214, 0.32)"
        : "0px 4px 20px 0px rgba(0, 0, 0, 0.25)";
    const overlayBgColor =
      theme === "light" ? "rgb(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0.4)";
    const boxShadowBanner =
      theme === "light" ? "0px 4px 14px 0px rgba(192, 220, 214, 0.32)" : "none";
    const backgroundColor =
      theme === "light"
        ? themeColors.light.bg_secondary_color
        : themeColors.dark.bg_secondary_color;
    const backgroundColorPickerColor =
      theme === "light"
        ? "#FFFFFF"
        : "linear-gradient(90deg, #576371 0%, #415160 0%, #293440 100%)";
    const backgroundLinearColor =
      theme === "light"
        ? "#fff"
        : "linear-gradient(to right, rgb(65, 81, 96) 0%,rgb(41, 52, 64) 100%)";
    const bgUnstructuredLinearColor =
      theme === "light"
        ? "#fff"
        : "linear-gradient(109deg, #415160 4.27%, #293440 95.56%)";
    const themeTextColor =
      theme === "light"
        ? themeColors.light.primary_text_color
        : themeColors.dark.primary_color;
    const backgroundSpecial =
      theme === "light"
        ? themeColors.light.main_color
        : themeColors.dark.bg_secondary_color;
    const specialColor =
      theme === "light" ? "#fff" : themeColors.dark.main_color;
    const specialColor2 =
      theme === "light"
        ? themeColors.light.main_color
        : themeColors.dark.primary_color;
    const bghelp =
      theme === "light"
        ? themeColors.light.bg_Light_Grey
        : themeColors.dark.bg_Dark_Gray;
    const bg_selectColor =
      theme === "light"
        ? themeColors.light.bg_secondary_color
        : themeColors.dark.bg_select;
    const textInputColorA =
      theme === "light" ? themeColors.light.text_input_color_A : "#fff";
    const FocusTextColor =
      theme === "light"
        ? themeColors.light.primary_text_color
        : themeColors.light.bg_Light_Grey;
    const checkboxBgColor =
      theme === "light"
        ? "transparent"
        : themeColors.dark.checkbox_gradientColor;
    const checkboxBorderColor = theme === "light" ? "#aab1b9" : "#fff";
    const checkboxBorderRadius = theme === "light" ? "3px" : "0";
    const RadioButtonGradientColor =
      theme === "light"
        ? themeColors.light.toggle_gradientColor
        : themeColors.dark.checkbox_gradientColor;

    const boxShadowAllClasses =
      theme === "light"
        ? themeColors.light.box_shadow_allClasses
        : themeColors.dark.box_shadow_allClasses;
    const SolidGrey_Forum =
      theme === "light" ? "#fff" : themeColors.dark.forum_dark_Grey;
    const inputBorderColor =
      theme === "light"
        ? themeColors.light.lightGrey_input
        : themeColors.dark.bg_Dark_Gray;
    const toggleGradientColor =
      theme === "light"
        ? themeColors.light.toggle_gradientColor
        : themeColors.dark.toggle_gradientColor;
    const headerTextInput_ProfileAndBorder =
      theme === "light"
        ? themeColors.light.lightGrey_Color
        : themeColors.dark.textHeaderForInput_Profile;
    const Pagination_Item_Color =
      theme === "light"
        ? themeColors.light.lightGrey_Color
        : themeColors.dark.primary_text_color;
    const SecondaryTextColor =
      theme === "light"
        ? themeColors.light.secondary_color
        : themeColors.dark.secondary_text_color;
    const SecondaryWhiteTextColor =
      theme === "light"
        ? themeColors.light.secondary_color
        : themeColors.dark.primary_text_color;
    const CorrectTick_Content_Color =
      theme === "light"
        ? themeColors.light.main_color
        : themeColors.dark.primary_text_color;
    const Profile_IMG_bgColor =
      theme === "light"
        ? themeColors.light.lightGrey_Color
        : "linear-gradient(180deg, #415160 0%, #293440 100%";
    const themeProgressColor =
      theme === "light"
        ? themeColors.light.progressColor
        : themeColors.dark.progressColor;
    const GrayBackGroup1 =
      theme === "light"
        ? themeColors.light.lightGrey_Color
        : themeColors.dark.light_Gray_Opacities1;
    const Lighted_BoxShadowDisplays =
      theme === "light" ? themeColors.light.NormalBoxShadow : "";
    const Third_BgColor =
      theme === "light"
        ? themeColors.light.bg_secondary_color
        : themeColors.dark.bg_main_color;
    const profileCardBorderColor = theme === "light" ? "#AAB1B9" : "#576371";
    const profileCardTextColor = theme === "light" ? "#364350" : "#D9E4E8";
    const Container_Gradient_BgColor =
      theme === "light"
        ? themeColors.light.bg_secondary_color
        : themeColors.dark.gradientTable_BGColor;
    const default_boxShadow =
      theme === "light" ? " 0px 4px 14px 0px rgba(192, 220, 214, 0.32)" : "";
    const Third_TextColor =
      theme === "light"
        ? themeColors.light.bg_secondary_color
        : "(255, 255, 255, 0.5)";

    const OpacitiesTextColor =
      theme === "light" ? themeColors.light.lightGrey_Color : "#FFFFFF80";
    const QuizCreator_ValidatesInput_bgColor =
      theme === "light" ? themeColors.light.lightGrey_ColorOpacity6 : "#546270";
    const To_Do_bgColor =
      theme === "light" ? "#fff" : themeColors.dark.main_gradientColor;
    const boxShadowV2 =
      theme === "light"
        ? themeColors.light.boxShadowV2
        : themeColors.dark.boxShadowV2;
    const Light_TextColor =
      theme === "light"
        ? themeColors.light.lightGrey_Color
        : themeColors.dark.primary_text_color;
    const NotifyBox_BgColor =
      theme === "light"
        ? themeColors.light.VeryLightGrey_Color
        : themeColors.dark.gradientTable_BGColor;
    const NotifyBox_BgColor_IconHolder =
      theme === "light"
        ? themeColors.light.VeryLightGrey_Color
        : themeColors.dark.main_gradientColor;
    const JustLightBoxShadow =
      theme === "light" ? "0px 4px 14px rgba(192, 220, 214, 0.32)" : "";
    const TableHeaderDefault_bgColor =
      theme === "light"
        ? `${themeColors.light.lightGrey_Color}70`
        : "linear-gradient(90deg, #415160 0%, #293440 107.88%)";
    const MainColoredColor =
      theme === "light"
        ? themeColors.light.lightGrey_Color
        : themeColors.dark.main_color;
    const Scroller_BgColor =
      theme === "light"
        ? themeColors.light.lightGrey_Color
        : themeColors.dark.secondary_color;
    const borderInputColor =
      theme === "light"
        ? themeColors.light.lightGrey_Color
        : themeColors.dark.bg_Dark_Gray;
    const EmailTextColor = theme === "light" ? "#AAB1B9" : "#D9E4E8";
    const ProfileGradient_BgColor_Both =
      theme === "light"
        ? "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)"
        : "linear-gradient(180deg, #2D3741 0%, #576371 100%)";
    const RatingRubricTrackColor =
      theme === "light"
        ? themeColors.light.lightGrey_input
        : themeColors.dark.bg_main_color;
    const timePickerFieldBgColor =
      theme === "light"
        ? themeColors.light.timePicker_FieldBgColor
        : themeColors.dark.timePicker_FieldBgColor;
    const readOnlyBgColor =
      theme === "light"
        ? themeColors.light.readOnly_BgColor
        : themeColors.dark.readOnly_BgColor;
    const bg_graderForumPost = theme === "light" ? themeColors.light.bg_secondary_color : themeColors.dark.secondary_color
    return {
      overlayBgColor,
      boxShadowBanner,
      backgroundColor,
      ProfileGradient_BgColor_Both,
      TableHeaderDefault_bgColor,
      iconCloseColor,
      Third_TextColor,
      Scroller_BgColor,
      borderInputColor,
      EmailTextColor,
      boxShadowV2,
      JustLightBoxShadow,
      NotifyBox_BgColor,
      MainColoredColor,
      specialColor2,
      default_boxShadow,
      OpacitiesTextColor,
      inputBorderColor,
      toggleGradientColor,
      Container_Gradient_BgColor,
      Light_TextColor,
      NotifyBox_BgColor_IconHolder,
      GrayBackGroup1,
      To_Do_bgColor,
      Third_BgColor,
      SecondaryWhiteTextColor,
      backgroundLinearColor,
      SecondaryTextColor,
      CorrectTick_Content_Color,
      Profile_IMG_bgColor,
      bgUnstructuredLinearColor,
      QuizCreator_ValidatesInput_bgColor,
      themeProgressColor,
      backgroundSpecial,
      specialColor,
      Lighted_BoxShadowDisplays,
      themeTextColor,
      bghelp,
      bg_selectColor,
      textInputColorA,
      FocusTextColor,
      boxShadowAllClasses,
      SolidGrey_Forum,
      headerTextInput_ProfileAndBorder,
      Pagination_Item_Color,
      theme,
      checkboxBgColor,
      backgroundColorPickerColor,
      boxShadow,
      profileCardBorderColor,
      profileCardTextColor,
      RadioButtonGradientColor,
      checkboxBorderRadius,
      checkboxBorderColor,
      RatingRubricTrackColor,
      timePickerFieldBgColor,
      readOnlyBgColor,
      bg_graderForumPost
    };
  },
});
export const TableColorSelector = selector({
  key: "TableColors",
  get: ({ get }) => {
    const theme = get(themeState);
    const Table_BgColor =
      theme === "light"
        ? "#fff"
        : "linear-gradient(180deg, #415160 0%, #293440 100%)";
    const Table_BoxShadow =
      theme === "light" ? "(0px 4px 14px rgba(192, 220, 214, 0.32))" : "";
    const ComplexTable_FirstHeadCell =
      theme === "light"
        ? "#AAB1B940"
        : "linear-gradient(90deg, #41516030 0%, #415160 107.88%)";
    const Table_Head_TR =
      theme === "light"
        ? "#AAB1B940"
        : "linear-gradient(90deg, #415160 0%, #293440 107.88%)";
    const Table_nth_child =
      theme === "light" ? "#AAB1B920" : "rgba(255, 255, 255, 0.03)";
    const Table_CellBorder =
      theme === "light" ? "#AAB1B9" : "rgba(255, 255, 255, 0.03)"; //need to update
    return {
      Table_BgColor,
      Table_BoxShadow,
      ComplexTable_FirstHeadCell,
      Table_Head_TR,
      Table_nth_child,
      Table_CellBorder,
      theme,
    };
  },
});

export const ChartsColorSelector = selector({
  key: "ChartsColors",
  get: ({ get }) => {
    const theme = get(themeState);
    const ChartsMainBox_BgColor =
      theme === "light"
        ? "#fff"
        : "linear-gradient(90deg, #415160 0%, #293440 107.88%)";
    const ChartsMainBox_BoxShadow =
      theme === "light" ? "(0px 4px 14px rgba(192, 220, 214, 0.32))" : "";
    return {
      theme,
      ChartsMainBox_BgColor,
      ChartsMainBox_BoxShadow,
    };
  },
});

export const CourseColorSelector = selector({
  key: "CourseViewColors",
  get: ({ get }) => {
    const theme = get(themeState);
    const ClassGradientBox =
      theme === "light"
        ? "#fff"
        : "linear-gradient(180deg, #415160 0%, #293440 100%)";
    const ActiveTabBgColor =
      theme === "light"
        ? themeColors.light.bg_secondary_color
        : themeColors.dark.ActiveContentTabBgColor;
    const ActiveContentTabBgColor =
      theme === "light"
        ? themeColors.light.bg_Normal_Grey
        : themeColors.dark.ActiveContentTabBgColor;
    const ContentDocBgColor =
      theme === "light"
        ? themeColors.light.bg_Normal_Grey
        : themeColors.dark.ContentDocBgColor;
    const FileBgColor =
      theme === "light" ? "#fff" : themeColors.dark.bluish_gray;
    const PicsHeaderTool_BgColor = theme === "light" ? "#fff" : "#384450";
    return {
      ActiveTabBgColor,
      ClassGradientBox,
      ActiveContentTabBgColor,
      PicsHeaderTool_BgColor,
      theme,
      ContentDocBgColor,
      FileBgColor,
    };
  },
});

export const DashBoardColorSelector = selector({
  key: "DashBoardColors",
  get: ({ get }) => {
    const theme = get(themeState);
    const AnnouncementCardBgColor =
      theme === "light"
        ? "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)"
        : themeColors.dark.bg_secondary_color;
    const AnnouncementColor = theme === "light" ? "#fff" : "#5dd3b3";
    const SideBarActiveBoxBgColor =
      theme === "light"
        ? "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)"
        : "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)";
    const sideBarIconTextColor =
      theme === "light"
        ? themeColors.light.sideBarIconTextColor
        : themeColors.dark.secondary_text_color;
    return {
      AnnouncementColor,
      AnnouncementCardBgColor,
      theme,
      SideBarActiveBoxBgColor,
      sideBarIconTextColor,
    };
  },
});

export const IconsColorSelector = selector({
  key: "IconsColors",
  get: ({ get }) => {
    const theme = get(themeState);
    const iconcolor =
      theme === "light"
        ? themeColors.light.main_color
        : themeColors.dark.primary_color;
    const iconCloseColor = theme === "light" ? "#2D3741" : "#5DD3B3";
    const backgroundIconColor =
      theme === "light" ? "#fff" : themeColors.dark.bg_secondary_color;
    const backgroundIconHoveredColor =
      theme === "light"
        ? themeColors.light.main_color
        : themeColors.dark.submenu_hover_color;
    const iconHoveredcolor =
      theme === "light"
        ? themeColors.light.bg_secondary_color
        : themeColors.dark.bg_secondary_color;
    const backgroundImageColor =
      theme === "light"
        ? themeColors.light.main_gradientColor
        : themeColors.dark.main_gradientColor;
    return {
      iconcolor,
      backgroundIconColor,
      iconHoveredcolor,
      backgroundIconHoveredColor,
      backgroundImageColor,
      iconCloseColor,
    };
  },
});
export const CalendarColorSelector = selector({
  key: "CalendarColors",
  get: ({ get }) => {
    const theme = get(themeState);
    const tilescolor =
      theme === "light"
        ? themeColors.light.secondary_color
        : themeColors.dark.primary_color;
    const titlecolor =
      theme === "light"
        ? themeColors.light.primary_text_color
        : themeColors.dark.primary_color;
    const calendarDayBgColor = theme === "light" ? "#576474" : "#384450";
    const calendarBgColor =
      theme === "light"
        ? themeColors.light.bg_secondary_color
        : themeColors.dark.bg_secondary_color;
    return { tilescolor, titlecolor, calendarBgColor, calendarDayBgColor };
  },
});

export const courseBannerColorSelector = selector({
  key: "CourseBannerColor",
  get: ({ get }) => {
    const theme = get(themeState);
    const boxShadow =
      theme === "light"
        ? "0px 4px 20px 0px rgba(192, 220, 214, 0.32)"
        : "0px 4px 20px 0px rgba(0, 0, 0, 0.25)";
    const courseBannerBgColor =
      theme === "light" ? "#FFFFFF" : themeColors.dark.bg_main_color;
    const courseTitleBgColor =
      theme === "light"
        ? "#AAB1B9"
        : "linear-gradient(90deg, #415160 0%, #293440 107.88%)";

    const courseBannerTextColor =
      theme === "light" ? themeColors.dark.bg_main_color : "#FFFFFF";
    const iconColor =
      theme === "light"
        ? themeColors.dark.bg_main_color
        : themeColors.dark.primary_color;
    const backgroundIconColor =
      theme === "light" ? "#fff" : themeColors.dark.bg_secondary_color;
    const backgroundIconHoveredColor =
      theme === "light"
        ? themeColors.dark.bg_main_color
        : themeColors.dark.submenu_hover_color;
    const iconHoveredColor =
      theme === "light"
        ? themeColors.light.bg_secondary_color
        : themeColors.dark.bg_secondary_color;
    const backgroundImageColor =
      theme === "light" ? themeColors.light.main_gradientColor : "#FFFFFF";
    const backgroundProfileColor =
      theme === "light" ? themeColors.light.main_gradientColor : "#707070";
    const textScheduleTimeColor = theme === "light" ? "#AAB1B9" : "#FFFFFF";
    const dashboardContentBack =
      theme === "light"
        ? "#E4E6E8"
        : "linear-gradient(90deg, #415160 0%, #293440 107.88%)";
    return {
      dashboardContentBack,
      theme,
      courseBannerBgColor,
      boxShadow,
      courseBannerTextColor,
      iconColor,
      backgroundIconColor,
      iconHoveredColor,
      backgroundIconHoveredColor,
      backgroundImageColor,
      backgroundProfileColor,
      textScheduleTimeColor,
      courseTitleBgColor,
    };
  },
});
export const Quiz_Survey_Creator_ColorSelector = selector({
  key: "Quiz_Survey_Creator_Color",
  get: ({ get }) => {
    const theme = get(themeState);
    const Quiz_Survey_MainContainer_BgColor =
      theme === "light" ? "rgba(170, 177, 185, 0.1)" : "rgba(2, 2, 2, 0.20)";
    return { theme, Quiz_Survey_MainContainer_BgColor };
  },
});

export const PopupSelectorColor = selector({
  key: "PopupColors",
  get: ({ get }) => {
    const theme = get(themeState);
    const CloseColor =
      theme === "light"
        ? themeColors.light.primary_text_color
        : themeColors.dark.main_color;
    const PageBgColor = theme === "light" ? "#00000040" : "rgba(0, 0, 0, 0.2)";
    const ContentContainer_BgColor = theme === "light" ? "#FFF" : "#384450";
    const Content_BoxShadow =
      theme === "light" ? "0px 4px 14px 0px rgba(192, 220, 214, 0.32)" : "";

    return {
      PageBgColor,
      ContentContainer_BgColor,
      CloseColor,
      Content_BoxShadow,
      theme,
    };
  },
});
export const courseCreatorColorSelector = selector({
  key: "CourseCreatorColor",
  get: ({ get }) => {
    const theme = get(themeState);
    const moduleContainerBgColor =
      theme === "light" ? "#FFFFFF" : themeColors.dark.bg_main_color;
    const moduleDragNDropBgColor =
      theme === "light" ? "#FFFFFF" : "transparent";
    const tooltipBgColor = theme === "light" ? "#FFFFFF" : "#1f2731";
    const tooltipTextColor = theme === "light" ? "#2D3741" : "#FFFFFF";
    const boxShadow =
      theme === "light"
        ? "0px 4px 20px 0px rgba(192, 220, 214, 0.32)"
        : "0px 4px 20px 0px rgba(0, 0, 0, 0.25)";
    const statisticsTextColor = theme === "light" ? "#364350" : "#D9E4E8";
    const miniSideBarBgColor =
      theme === "light" ? "rgb(170, 177, 185, 0.1)" : "rgba(2, 2, 2, 0.2)";
    const miniSideBarOpacity = theme === "light" ? "10%" : "100%";
    const surveyIconColor = theme === "light" ? "#2D3741" : "#D9E4E8";
    const addModuleIconColor = theme === "light" ? "#2D3741" : "#FFFFFF";
    const lineIconColor = theme === "light" ? "#AAB1B9" : "#576371";
    const borderModulesContainerColor = theme === "light" ? 0 : 1;
    const scrollingHbgColor = theme === "light" ? "#AAB1B9" : "#364350";
    const moduleBgColor =
      theme === "light"
        ? "#FFFFFF"
        : "linear-gradient(180deg, #415160 0%, #293440 100%)";
    const addTopicIconColor = theme === "light" ? "#2D3741" : "#FFFFFF";
    const topicHeaderBgColor =
      theme === "light" ? "rgb(170, 177, 185, 0.3)" : "rgb(0, 0, 0, 0.3)";

    return {
      moduleDragNDropBgColor,
      borderModulesContainerColor,
      lineIconColor,
      theme,
      moduleContainerBgColor,
      tooltipBgColor,
      tooltipTextColor,
      boxShadow,
      statisticsTextColor,
      miniSideBarBgColor,
      miniSideBarOpacity,
      surveyIconColor,
      addModuleIconColor,
      scrollingHbgColor,
      moduleBgColor,
      addTopicIconColor,
      topicHeaderBgColor,
    };
  },
});

export const unstructuredCoursePopup = selector({
  key: "unstructuredCoursePopupColor",
  get: ({ get }) => {
    const theme = get(themeState);
    const popUpBgColor = theme === "light" ? "#FFFFFF" : "#384450";
    const overlayBgColor =
      theme === "light" ? "rgb(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0.4)";

    return {
      popUpBgColor,
      overlayBgColor,
      theme,
    };
  },
});

export const ChatsColorSelector = selector({
  key: "ChatsColors",
  get: ({ get }) => {
    const theme = get(themeState);
    const header_BG_Color =
      theme === "light"
        ? themeColors.light.bg_Normal_Grey
        : themeColors.dark.bg_secondary_color;
    const MainChatBox_bgColor =
      theme === "light" ? "#fff" : themeColors.dark.Chats_dark_Grey;
    const UserMessageBoxBG_Color =
      theme === "light"
        ? themeColors.light.lightGrey_Color
        : "linear-gradient(90deg, #293440  0%, #415160  107.88%)";
    const FriendMessageBoxBG_Color =
      theme === "light"
        ? themeColors.light.lightGrey_Color
        : "linear-gradient(90deg, #415160 0%, #293440 107.88%)";
    const AddBox_BG_Color =
      theme === "light"
        ? themeColors.light.lightGrey_Color
        : themeColors.dark.bg_secondary_color;
    const ContactCardBgColor =
      theme === "light"
        ? themeColors.light.VeryLightGrey_Color
        : "rgba(255, 255, 255, 0.03)";
    const SearchCell_BgColor =
      theme === "light"
        ? themeColors.light.SearchCell_BgColor
        : themeColors.dark.SearchCell_BgColor;
    const InfoSelectBorderColor =
      theme === "light"
        ? themeColors.light.lightGrey_Color
        : themeColors.dark.borderColorA;
    const InfoSelectTextColor =
      theme === "light"
        ? themeColors.light.lightGrey_Color
        : themeColors.dark.primary_text_color;
    const GroupCellBgColor =
      theme === "light"
        ? themeColors.light.lightGrey_input
        : themeColors.dark.SearchCell_BgColor;
    const GroupSelectedFriend =
      theme === "light"
        ? themeColors.light.lightGrey_ColorOpacity6
        : "linear-gradient(90deg, #415160 0%, #293440 107.88%)";
    const PopUpChatMessageColor =
      theme === "light"
        ? themeColors.light.lightGrey_Color
        : "rgba(255, 255, 255, 0.03)";
    return {
      AddBox_BG_Color,
      PopUpChatMessageColor,
      GroupSelectedFriend,
      GroupCellBgColor,
      InfoSelectTextColor,
      InfoSelectBorderColor,
      SearchCell_BgColor,
      header_BG_Color,
      MainChatBox_bgColor,
      UserMessageBoxBG_Color,
      FriendMessageBoxBG_Color,
      ContactCardBgColor,
      theme,
    };
  },
});
