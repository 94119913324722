export const mapErrorType = (errorType: string, t: any): string => {
    switch (errorType) {
      case 'This field is mandatory':
        return `${t('quiz.This field is mandatory')}`;
      case "First two choices' names cannot be empty for MCQ questions.":
        return `${t("quiz.First two choices' names cannot be empty for MCQ questions.")}`;
      case "Total percentage of choices should be 100.":
        return `${t("quiz.Total percentage of choices should be 100.")}`;
      case "Percentage field cannot be empty.":
        return `${t("quiz.Percentage field cannot be empty.")}`;
      case "First Keyword cannot be empty for Short answer question":
        return `${t("quiz.First Keyword cannot be empty for Short answer question")}`;
      case "First two choices' names cannot be empty for Matching questions.":
        return `${t("quiz.First two choices' cannot be empty in Matching questions.")}`;
      case "Please select a question type for the question.":
        return `${t("quiz.Please select a question type for the question")}`;
      case "Please select a question type for the question part.":
        return `${t("quiz.Please select a question type for the question part.")}`;
      case "Please add at least two choices for Matching questions.":
        return `${t("quiz.Please add at least two choices for Matching questions.")}`;
      case "Please add at least two choices for MCQ questions.":
        return `${t("quiz.Please add at least two choices for MCQ questions.")}`;
      case "Choices cannot be empty.":
        return `${t("quiz.Choices cannot be empty")}`;
      case "Choices field cannot be empty.":
        return `${t("quiz.Choices field cannot be empty")}`;
      case "Fields is mandatory.":
        return `${t("quiz.These fields are mandatory")}`;
      case "Save Before adding a question":
        return `${t("quiz.Save Before adding a question")}`;
      case "Complex questions must have at least 2 parts.":
        return `${t("quiz.Complex questions must have at least 2 parts")}`;
      case "Duplicate ratings are not allowed.":
        return `${t("survey.Duplicate ratings are not allowed.")}`;
      case "Duplicate scale names are not allowed.":
        return `${t("survey.Duplicate scale names are not allowed.")}`;
      case "Duplicate values for Yes/No are not allowed.":
        return `${t("survey.Duplicate values for Yes/No are not allowed.")}`;
      case "Select a Type for the branch":
        return `${t("survey.Select a Type for the branch")}`;
      case "The Choices of Multiple Questions is mandatory":
        return `${t("survey.The Choices of Multiple Questions is mandatory")}`;
      case "The name of branches is mandatory":
        return `${t("survey.The name of branches is mandatory")}`;
      case "The rating value must be between 1 and 5":
        return `${t("survey.The rating value must be between 1 and 5")}`;
      case "The scale name cannot be empty.":
        return `${t("survey.The scale name cannot be empty.")}`;
      default:
        return errorType;
    }
  };