import React, { useEffect, useState } from "react";
import { FlexAppContainer } from "../../../App.style";
import TitleHeaderTextIconNavigator from "../../../elements/TitleHeaderTextIconNavigator/TitleHeaderTextIconNavigator";
import { CompetencyData } from "./CompentencyData";
import { MediumTypography, Typography } from "../../../elements/fonts/Fonts";
import { AnalyticsSurveyPiesTwoValuesContainer } from "../../AnalyticsSurveyPies/AnalyticsSurveyPiesTwoValues/AnalyticsSurveyPiesTwoValues.style";

import PieText from "../../Charts/PieText/PieText";
import PieWithText from "../../Charts/PieWithText/PieWithText";
import { useRecoilValue } from "recoil";
import { ChartsColorSelector } from "../../../recoil/ThemeSelectors";

const CompetencyPieChart = () => {
  // Update PieData with bgColor
  const updatedPieData = CompetencyData.PieData.map((item) => {
    let bgColor = "";
    switch (item.text) {
      case "Advanced":
        bgColor = "#5DD3B3";
        break;
      case "Intermediate":
        bgColor = "#CBD35D";
        break;
      case "Poor":
        bgColor = "#D85D66";
        break;
      default:
        bgColor = "";
    }
    return { ...item, bgColor };
  });

  // Update CompetencyData with updatedPieData
  const updatedCompetencyData = { ...CompetencyData, PieData: updatedPieData };
  const [PieChartData, setPieChartData] = useState<any>();
  useEffect(() => {
    // setPieChartData(updatedCompetencyData.PieData);
  }, [updatedCompetencyData]);
  const { ChartsMainBox_BgColor, ChartsMainBox_BoxShadow } =
    useRecoilValue(ChartsColorSelector);
  return (
    <FlexAppContainer column={true} gap="1.25rem">
      <TitleHeaderTextIconNavigator
        title={updatedCompetencyData.CompetencyName}
      />
      <FlexAppContainer
        padding="1.25rem"
        borderRadius="0.625rem"
        column={true}
        gap="1.25rem"
        backgroundColor={ChartsMainBox_BgColor}
        boxShadow={ChartsMainBox_BoxShadow}
      >
        <MediumTypography
          text={"Coverage"}
          fontSize="1.125rem"
          lineHeight="1.75rem"
        />
        <Typography text={updatedCompetencyData.decs || ""} fontSize="1" />

        <AnalyticsSurveyPiesTwoValuesContainer>
          {/* <div style={{ background: "green", height: "100%", width: "60%" }}> */}
          <PieWithText
            data={updatedCompetencyData.PieData}
            getColor={(entry) => entry.bgColor as string}
            Width="60%"
          />
          {/* </div> */}
          <PieText
            LoopName={updatedCompetencyData.PieData}
            ShowMiniInfo={false}
            title={""}
          />
        </AnalyticsSurveyPiesTwoValuesContainer>
      </FlexAppContainer>
    </FlexAppContainer>
  );
};

export default CompetencyPieChart;
