import React, { FC, useState } from "react";
import {
  ToDoContainer,
  CustomCheckbox,
  CustomCheckboxInput,
  CustomCheckboxWrapper,
  FilterContainer,
  Line,
  CreateButton,
  CancelButton,
  FlexDiv,
  FilterContainerRowDisplays,
} from "./ToDoFilters.style";
import {
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import { ReactComponent as EyeIcon } from "../../../assets/icons/NewEye.svg";
import { ReactComponent as FilterOne } from "../../../assets/icons/icon filter.svg";
import { ReactComponent as FilterTwo } from "../../../assets/icons/Filter.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/Arrow.svg";
import { ReactComponent as DownIcon } from "../../../assets/icons/Arrow-up-1.svg";
import { FitlterStatus } from "../FilterStatus/FitlterStatus";
import { FitlterPriority } from "../FilterStatus/FilterPriority";
import PopupCreateToDo from "../../CreateTodo/PopupToDo";
import useClickOutside from "../../../hooks/useClickOutside";
import { FilterProps } from "./ToDoFiltersInterface";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import PopUp from "../../../elements/Popup/PopUp";
import CreateTodo from "../../CreateTodo/CreateTodo";
import { ReactComponent as SvgToDoIcon } from "../../../assets/icons/TodoList.svg";
const ToDoFilters: FC<FilterProps> = ({
  setTasks,
  filterTasksByPriority,
  selectedPriority,
  filterTasksByStatus,
  selectedStatus,
  handleCancel,
  hasDataSelected,
}) => {
  const [isCheckedHidden, setIsCheckedHidden] = useState(false);
  const [isCheckedDelete, setIsCheckedDelete] = useState(false);
  const [statusMenuOpen, setStatusMenuOpen] = useState<boolean>(false);
  const [priorityMenuOpen, setPriorityMenuOpen] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState(false); // Local state to manage the popup
  const { t } = useTranslation();

  // Define a reference to detect clicks outside the status menu and close it accordingly
  const statusMenuRef = useClickOutside(statusMenuOpen, () => {
    setStatusMenuOpen(false);
  });

  // Define a reference to detect clicks outside the priority menu and close it accordingly
  const priorityMenuRef = useClickOutside(priorityMenuOpen, () => {
    setPriorityMenuOpen(false);
  });

  // Toggle the status menu open/close state and close the priority menu
  const toggleStatusMenu = () => {
    setStatusMenuOpen(!statusMenuOpen);
    setPriorityMenuOpen(false);
  };

  // Toggle the priority menu open/close state and close the status menu
  const togglePriorityMenu = () => {
    setPriorityMenuOpen(!priorityMenuOpen);
    setStatusMenuOpen(false);
  };

  // Handle the click event to show the task creation popup
  const handleCreateTaskClick = () => {
    setShowPopup(true);
  };
  const { bghelp } = useRecoilValue(ColorSelector);
  return (
    <ToDoContainer style={{ justifyContent: "space-between" }}>
      <Typography text={t("to do list.To Do")} fontSize="1.75rem" />
      <FilterContainerRowDisplays>
        <FilterContainer ref={statusMenuRef} onClick={toggleStatusMenu}>
          <FilterOne />
          <Typography
            text={t("to do list.FILTER BY STATUS")}
            fontSize="0.625rem"
            color="#5DD3B3"
          />
          {statusMenuOpen ? (
            <DownIcon color="#5DD3B3" />
          ) : (
            <ArrowIcon color="#5DD3B3" />
          )}
          <FitlterStatus
            menuOpen={statusMenuOpen}
            filterTasksByStatus={filterTasksByStatus}
            selectedStatus={selectedStatus}
          />
        </FilterContainer>

        {/* filter by priority */}
        <FilterContainer ref={priorityMenuRef} onClick={togglePriorityMenu}>
          <FilterTwo />
          <Typography
            text={t("to do list.FILTER BY PRIORITY")}
            fontSize="0.625rem"
            color="#5DD3B3"
          />
          {priorityMenuOpen ? (
            <DownIcon color="#5DD3B3" />
          ) : (
            <ArrowIcon color="#5DD3B3" />
          )}
          <FitlterPriority
            menuOpen={priorityMenuOpen}
            filterTasksByPriority={filterTasksByPriority}
            selectedPriority={selectedPriority}
          />
        </FilterContainer>

        {/* clear and create buttons */}
        <FlexDiv>
          {hasDataSelected && (
            <CancelButton onClick={handleCancel}>
              {t("to do list.Clear all")}
            </CancelButton>
          )}
          <CreateButton onClick={handleCreateTaskClick}>
            {t("to do list.Create Task")}
          </CreateButton>
        </FlexDiv>
      </FilterContainerRowDisplays>
      {showPopup && (
        <PopUp
          icon={<SvgToDoIcon />}
          title={t("to do list.Create Task")}
          show={showPopup}
          setShow={setShowPopup}
          showline={false}
          showButton={false}
          // paddingTop="1.25rem"
          // paddingRight="0"
          content={
            <CreateTodo
              onClose={() => {
                setShowPopup(false);
              }}
              setTasks={setTasks}
            />
          }
          // CloseRightPadding="1.7rem"
        />
      )}
    </ToDoContainer>
  );
};

export default ToDoFilters;
