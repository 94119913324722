import axios from "axios";

const BaseURLClassName =
  "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";

export const fetchData = async (classId: string | undefined) => {
  const tenantName = localStorage.getItem("tenant");
  let dataFetched;
  let isLoading = true;
  try {
    const response = await axios.get(
      `https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/class/content/${classId}/${tenantName}`
    );
    dataFetched = response.data.data;
    if (dataFetched) {
      isLoading = false;
    }
    return { dataFetched, isLoading };
  } catch (error) {
    console.error("Error fetching data:", error);
    return { dataFetched: null, isLoading: false }; // Handle error case
  }
};

export const getClassTitle = async (classSourceId: string | undefined) => {
  try {
    const tenantName = localStorage.getItem("tenant");
    const res = await axios.get(
      `${BaseURLClassName}/class/title/${tenantName}/${classSourceId}`
    );
    return res.data.data[0][0].title;
  } catch (error) {
    console.log(error);
  }
};
