import styled from "styled-components"
import { breakpoints } from "../../theme";

export const  DFLayout =  styled.div`
display:flex;
flex-direction:column;
gap:2.87rem;
width:100%;
margin-bottom:2rem;
`;

export const  DFTextFlex =  styled.div`
display:flex;
width:100%;
gap:0.62rem;
align-items:center;
@media screen and (max-width: ${breakpoints.tablets} ) {
  flex-direction:column;
  padding-left:1rem;
}
`;

export const  DFCardsLayout =  styled.div`
display:flex;
flex-direction:column;
gap:1.88rem;
`;


export const  DFCards =  styled.div`
display:flex;
flex-direction:column;
gap:2.45rem;
justify-content:center;
align-items:center;
border-radius: 1.25rem;
width:100%;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
min-height:27rem;
cursor: pointer;
`;
