import styled from "styled-components";

// export const InstructorViewCompletion = styled.div``;
interface StylesTextArea{
  RedColor?:boolean;
}
export const InstructorViewCompletionContainer = styled.div`
display: flex;
width:100%;
flex-direction: column;
border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));

`;

export const InstructorViewCompletionTitles = styled.div`
display: flex;
width:100%;
padding:1.25rem;
&:first-child{
justify-content:space-between;}

&:nth-child(2){
justify-content:flex-end;
gap:0.5rem;
margin-top:-0.7rem;
align-items:center;
/* background-color:aqua; */
height:1.5rem;
}
`;

export const InstructorViewCompletionSearchContainer = styled.div`
width: 23.625rem;
height: 3.125rem;
flex-shrink: 0;`;


export const InstructorViewCompletionTable = styled.div`
display: flex;
width:100%;
flex-direction: column;
`;

export const InstructorViewCompletionTableHeaderRow = styled.div`
display: flex;
width:100%;
gap:0.2rem;
/* background-color:red; */
padding: 0.7rem 1.25rem;
`;
export const InstructorViewCompletionTableHeaderCell = styled.div`
display: flex;
flex:1;
/* background-color:blue; */


&:first-child{
  flex:1.4;
}

&:last-child{
flex:0.6;
justify-content:flex-end
}
`;

export const InstructorViewCompletionTableBodyRow = styled.div`
display: flex;
width:100%;
gap:0.2rem;
/* margin-top:0.3rem; */
/* background-color:aqua; */
padding: 0.7rem 1.25rem;
&:nth-child(even){
  background: rgba(255, 255, 255, 0.03);
}
`;
export const InstructorViewCompletionTableBodyCell = styled.div`
display: flex;
flex:1;
/* background-color:yellow; */
position: relative;
align-items:center;

&:first-child{
  flex:1.4;
  gap:0.3rem;

}

&:last-child{
flex:0.6;
justify-content:flex-end
}

`;

export const InstructorViewCompletionProfile = styled.img`
width: 3.125rem;
height: 3.125rem;
flex-shrink: 0;
border-radius:50%;
`;

export const InstructorCompletionTextName = styled.div`
display: flex;
gap:0.2rem;
`;

export const InstructorViewCompletionOptionButton = styled.div`
padding:0.5rem 0.2rem;
cursor: pointer;
/* background-color:#000; */
`;

export const InstructorViewBox= styled.div`
width: 12.5rem;
height: 5.25rem;
flex-shrink: 0;
display:flex;
flex-direction:column;
border-radius: 0.3125rem;
background: #1F2731;
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
position:absolute;
top:2.5rem;
z-index:1;
`;

export const InstructorViewBoxButton= styled.div`
width: 100%;
height: 2.625rem;
flex-shrink: 0;
display:flex;
gap:0.5rem;
align-items:center;
padding:0 1.6rem;
cursor: pointer;
/* color:#fff; */
&:nth-child(1){
  border-radius: 0.3125rem 0.3125rem 0 0;
  /* background-color:aqua */
}
&:last-child{
  border-radius:0 0 0.3125rem 0.3125rem ;
  /* background-color:red */
}
&:hover{
  background: var(--Main-Green-color, #5DD3B3);
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);  
}
`;




export const InstructorMessageBox= styled.div`
width: 25.6875rem;
min-height: 3.4375rem;
flex-shrink: 0;
border-radius: 0.625rem;
background: #2D3741;
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.20);
display: flex;
flex-direction:column;
padding:1.20rem;
position:absolute;
gap:1.20rem;
z-index:12 !important;
`;

export const InstructorMessageBoxTitleRow= styled.div`
width: 100%;
justify-content:space-between;
display: flex;
flex-direction:row;
`;


export const InstructorMessageBoxInput= styled.input`
width: 100%;
border-radius: 0.625rem;
border: 1px solid #576371;
height: 2.8125rem;
flex-shrink: 0;
font-size:0.875rem;
color: #fff;
padding: 0.88rem 1.25rem 0.87rem 1.25rem;
background-color:transparent !important;

&:nth-child(3){
  margin-top:-0.50rem;
  /* background-color:red !important; */
}
&:focus {
  outline: none;
}
`;

export const InstructorMessageTextArea = styled.textarea<StylesTextArea>`
  width: 100%;
  border-radius: 0.625rem;
  border: 1px solid;
  border-color: ${({ RedColor }) => (RedColor ? "red" : "#576371")};
  height: 6rem;
  flex-shrink: 0;
  font-size: 0.875rem;
  color: #fff;
  padding: 0.88rem 1.25rem 0.87rem 1.25rem;
  background-color: transparent !important;
  resize: none !important;
  font-family: "Primary Font-Light";
  font-size: 0.875rem;
  &::placeholder {
    color: ${({ RedColor }) => (RedColor ? "red" : "#576371")};
  }
  &:focus {
    outline: none;
  }
`;

export const InstructorMessageAttachment= styled.div`
width: 100%;
text-decoration:underline !important;
color:#5DD3B3;
margin-top:-0.63rem;
display: flex;
gap: 0.4rem;
align-items:center;
cursor:pointer;
`;
export const InstructorMessageButton= styled.div`
width: 100%;
display:flex;
justify-content:flex-end !important;
`;

export const SelectedStudentsContainer = styled.div`
display: flex;
width:100%;
flex-wrap:wrap;
border:1.5px solid #576371;
border-radius: 0.625rem;
padding:0.50rem;
`;
