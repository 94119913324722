import axios from "axios";
import React, { useEffect, useState } from "react";
import Vimeo from '@u-wave/react-vimeo';
import { CustomVimeoViewerContainer } from "./VimeoViewer.Style";

interface VimeoViewerProps {
  linkId: string;
}


export default function VimeoViewer({ linkId }: VimeoViewerProps) {
  const tenantName = localStorage.getItem("tenant");
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);

  //FETCHING THE DATA
//   useEffect(() => {
//     const fetchDocument = async () => {
//       try {
//         const response = await axios.get(
//           `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/resource/youtube/${linkId}/${tenantName}`
//         );
//         console.log("response.data.data in file", response.data.data);
//         setData(response.data.data);
//       } catch (error) {
//         console.error("Error fetching document:", error);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchDocument();
//   }, [linkId, tenantName]);

//   if (!data || loading) {
//     return <div>Loading...</div>;
//   }

  try {
    return (
      <CustomVimeoViewerContainer>
        {/* {data && data.url && ( */}
        {/*IF THERE IS DATA AND URL VIMEO VIEWER WILL BE RENDERED*/ }
          <Vimeo
            video="https://vimeo.com/343699764"
          />
        {/* )} */}
      </CustomVimeoViewerContainer>
    );
  } catch (err) {
    console.log(err);
    return <div>Error loading Vimeo video.</div>;
  }
}
