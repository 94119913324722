import React, { useContext, useEffect, useState } from "react";
import { ContentR } from "../CourseTimeline/utils/CourseResourcesInterface";
import { LinkContainer, LinkStyle, LinkTreeContainer } from "./LinkTree.style";
import { SvgCourseIcon, SvgFile } from "../../elements/Icons";
import { LightTypography, Typography } from "../../elements/fonts/Fonts";
import { Divider } from "../../Views/courseView/content/Content.style";
import axios from "axios";
import DOMPurify from "dompurify";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../utils/StringManipulation";
import { post } from "../../utils/AxiosRequests";
import { useRecoilValue } from "recoil";
import {
  CourseColorSelector,
  ColorSelector,
} from "../../recoil/ThemeSelectors";
import YouTube from "react-youtube";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
interface LinksItem {
  links: ContentR[];
  linkTreeId: string;
}
export default function LinkTree({ links, linkTreeId }: LinksItem) {
  const { progressChanged, setProgressChange } = useContext(ClassContext)

  const { FileBgColor } = useRecoilValue(CourseColorSelector);
  const { default_boxShadow } = useRecoilValue(ColorSelector);
  const tenantName = localStorage.getItem("tenant");
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const classId = params.classId;
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const response = await axios.get(
          `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/resource/linkTree/${linkTreeId}/${tenantName}`
        );
        setData(response.data.data.result);
        const trackingData = {
          classSourceId: classId,
          length: "",
          percentage: 100,
          refId: linkTreeId,
          type: "Link_Tree",
          subId: subIdSplitted,
          provider: provider,
        };
        const trackinResponse = await post(
          `completionTracking/create/${tenantName}`,
          trackingData
        );
        setProgressChange(!progressChanged);
      } catch (error) {
        console.error("Error fetching document:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchDocument();
  }, [linkTreeId]);
  const extractVideoIdFromUrl = (url: string) => {
    const match = url.match(
      /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^&]+)/i
    );
    return match ? match[1] : null;
  };
  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;

  const isYouTubeLink = (url: string) => {
    return youtubeRegex.test(url);
  };

  return (
    <LinkTreeContainer>
      {data &&
        data.map(
          (item: any, index: any) => (
            <div style={{ display: "flex", flexDirection: "column", rowGap: "0.62rem", width: "100%" }} key={index}>
              {/* <div>{item.title}</div>
            <div>{item.path}</div>
            <div>{item.description}</div> */}
              <Typography text={item.title} fontSize="1rem" />

              <LinkStyle
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <LinkContainer
                  bgColor={FileBgColor}
                  boxShadow={default_boxShadow}
                >
                  <SvgFile width={"2rem"} height={"2rem"} />
                </LinkContainer> */}
                <LightTypography text={item.url} fontSize="0.875rem" color="#5dd3b3" />
              </LinkStyle>
              {isYouTubeLink(item.url) &&
                <div style={{ width: "100% " }}>
                  <YouTube
                    opts={{
                      width: "100%", height: "500"
                    }}
                    videoId={extractVideoIdFromUrl(item.url) || undefined}
                  /></div>}
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(decodeHtml(item.description)),
                }}
              // style={{ marginLeft: 5 }}
              />
              {/* <LightTypography text={item.description} fontSize="0.875rem" /> */}

            </div>
          )
        )}
    </LinkTreeContainer>
  );
}
