import styled from 'styled-components';

interface DarkBg{
 BackGround?: string;
 IconContainer?:string;
}
export const Overlay = styled.div<{ isvisible: string }>`
  display: ${(props) => (props.isvisible ? 'block' : 'none')};
  opacity: ${(props) => (props.isvisible ? '1' : '0')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height:300vh;
  transition: opacity 500ms;
  overflow: auto;
  z-index: 999;

`;

export const BackgroundOverlay = styled.div<DarkBg>`
  background: ${(props)=>props.BackGround || "rgba(0, 0, 0, 0.4)"};
`;

export const PopupContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
  border-radius: 5px;
  width: 100%;
  color: white;
  min-height: 100vh;
  overflow: auto;

  h2 {
    margin-top: 0;
    color: white;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: white;

    &:hover {
      cursor: pointer;
      color: #000;
    }
  }

  .content {
    margin-top: 3.5%;
    overflow: auto;
    margin-bottom:100px;
  }

  @media screen and (max-width: 700px) {
    width: 70%;
  }

`;

export const PopupContentContainer = styled.div`
  background-color: #384450;
  width: max-content;
  padding: 2.94rem 0rem 1.56rem 0rem;
  border-radius: 0rem 0.625rem 0.625rem 0rem;
  position: relative;
  /* background-color: #5dd3b3; */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0.375rem;
    background: #5dd3b3;
    border-radius: 1.25rem;
    z-index: 2;
  }
`;

export const IconsContainer = styled.div<DarkBg>`
 display:flex;
 justify-content:space-between;
 padding:   ${(props)=>props.IconContainer || "1.25rem"};
 align-items:center;
 /* padding-right: 0.5rem; */
 /* background-color: red; */
`;
export const CloseIconContainer = styled.div`
 cursor:pointer;
 margin: 0 2.06rem 1.25rem 0;
`;
export const HorizantalLine = styled.div<{ showline: string }>`
display: ${(props) => (props.showline==='false' ? 'none' : '')};
  border: 1px solid white;
  opacity:0.2;
  margin-top:1.5rem;

`;

export const PopupTitle = styled.div`
  color:white;
  font-family: 'Core Rhino 65 Bold', sans-serif;
  font-size:16px;
`;

export const IconAndTitleContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-end;
gap:10px;

`;

export const ButtonContainer=styled.div<{ justifyContent: string }>`
display: flex;
justify-content:${(props)=>props.justifyContent || "center"};
align-items:center;
gap:0.62rem;
`;