import React, { createContext, useState, ReactNode, useEffect } from "react";

interface SelectedFriendContextProps {
  SelectedFriend: string;
  setSelectedFriend: React.Dispatch<React.SetStateAction<string>>;
}

export const SelectedFriendContext = createContext<SelectedFriendContextProps>({
  SelectedFriend: "",
  setSelectedFriend: () => {}, // Initial value for the setter function
});

interface SelectedFriendProviderProps {
  children: ReactNode;
  fromNavigateId?: string; // New prop to accept fromNavigateId
}

export const SelectedFriendProvider = ({
  children,
  fromNavigateId,
}: SelectedFriendProviderProps) => {
  const [SelectedFriend, setSelectedFriend] = useState("");

  useEffect(() => {
    if (fromNavigateId) {
      setSelectedFriend(fromNavigateId);
    }
  }, [fromNavigateId]);

  return (
    <SelectedFriendContext.Provider
      value={{ SelectedFriend, setSelectedFriend }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          position: "relative",
          marginBottom: "1.3rem",
        }}
      >
        {children}
      </div>
    </SelectedFriendContext.Provider>
  );
};
