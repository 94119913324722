import React from "react";
import { ReactComponent as TrueFalse } from "../../assets/icons/Group-1157.svg";
import { ReactComponent as MultipleIcon } from "../../assets/icons/multiple.svg";
import { ReactComponent as ShortAnswer } from "../../assets/icons/share_chain_5.svg";
import { ReactComponent as Numerical } from "../../assets/icons/dice_6.svg";
import { ReactComponent as Essay } from "../../assets/icons/paper_1_text.svg";
import { ReactComponent as Matching } from "../../assets/icons/Group1.svg";
import { SideBarIcons } from "./ToolBarInterface";
import { SvgProgress, SvgRating } from "../../elements/Icons";

export const items: SideBarIcons[] = [
  {
    id: 1,
    name: "MCQ",
    icon: <MultipleIcon />,
    color: "#0B8FFF",
  },
  {
    id: 2,
    name: "BINARY",
    icon: <TrueFalse />,
    color: "#8CEDEA",
  },
  // {
  //     id:3,
  //     name:'Short answer',
  //     icon:<ShortAnswer/>,

  // },

  {
    id: 5,
    name: "ESSAY",
    icon: <Essay />,
    color: "#B757E2",
  },
  {
    id: 4,
    name: "RATE",
    icon: <SvgRating />,
    color: "#CED35D",
  },
  {
    id: 5,
    name: "SCALE",
    icon: <SvgProgress />,
    color: "#8CED99",
  },
];
