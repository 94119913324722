import styled from 'styled-components'
import { StyleThemeInterface } from '../../../interfaces/StyleThemeInterface';

export const PreviewMatchingContainer=styled.div`
padding-left:1.4rem ;
padding-right:1.4rem;
display: flex;
align-items:center;
justify-content:space-between;
`;

export const LeftChoiceContainer=styled.div<StyleThemeInterface>`
min-width: 18rem;
min-height: 2.8125rem;
border-radius: 0.625rem;
border: 1px solid ${props => props.bordercolor || "#576371"} ;
padding-left: 1.11rem;
display:flex;
align-items:center;
`;

