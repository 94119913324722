import React from "react";

import { SvgLandingPaymentWaves } from "../../../elements/Icons";
import { LandingPaymentWavesContainer } from "./LandingPaymentWaves.style";

const LandingPaymentWaves = () => {
  return (
    <LandingPaymentWavesContainer>
      <SvgLandingPaymentWaves />
    </LandingPaymentWavesContainer>
  );
};

export default LandingPaymentWaves;
