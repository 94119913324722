import React, { FC, useState, useEffect } from "react";
import TeacterCalendarEvents from "../../components/TeacherCalendarEvents/main/TeacterCalendarEvents";
import { MediumTypography, Typography } from "../../elements/fonts/Fonts";
import { theme } from "../../theme";
import { Events } from "./TeacherCalendarDataInterface";
import TeacherCalendar from "../../components/TeacherCalendar/main/TeacherCalendar";
import moment from "moment";
import { get } from "../../utils/AxiosRequests";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useRecoilState } from "recoil";
import { adminState } from "../../recoil/RecoilStates";
import { FilterButton, FilterButtonsCont, FiltersSection } from "./TeacherCalendarView.style";
import FilterSelected from "../../elements/filterSelected/FilterSelected";
import PopUp from "../../elements/Popup/PopUp";
import { SvgSettingIcon } from "../../elements/Icons";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import ClassInProgressFilter from "../../components/classInProgressFilters/ClassInProgressFilter";
import { useMyContext } from "../../contexts/QuestionBanksContext";
import { FlexDiv } from "../../globalStyles";

function formatEventData(data: any) {
  const events: any[] = [];

  if (data && data.length > 0) {

    data.forEach((item: any) => {
      if (item.assignment) {
        item.assignment.forEach((assignment: any) => {
          if (
            assignment.startDateTime &&
            assignment.title &&
            assignment.instructions
          ) {
            const event = {
              date: assignment.startDateTime.split("T")[0],
              title: assignment.title,
              description: `${assignment.instructions.replace(/<\/?p>/g, "")}`,
              type: "Assignment",
              start: new Date(assignment.startDateTime),
              end: new Date(assignment.cutOffDateTime),
              instructors: item.classes.teachers,
              class: item.classes.title,
            };
            events.push(event);
          }
        });
      }

      if (item.quiz) {
        item.quiz.forEach((quiz: any) => {
          if (quiz.startDateTime && quiz.title && quiz.description) {
            const event = {
              date: quiz.startDateTime.split("T")[0],
              title: quiz.title,
              description: `${quiz.description.replace(/<\/?p>/g, "")}`,
              type: "Quiz",
              start: new Date(quiz.startDateTime),
              end: quiz.cutOffDateTime ? new Date(quiz.cutOffDateTime) : null,
              instructors: item.classes.teachers,
              class: item.classes.title,
            };
            events.push(event);
          }
        });
      }

      if (item.session) {
        // console.log("session ", item.session)
        item.session.forEach((session: any) => {
          if (session.startDateTime && session.title) {
            const event = {
              date: session.startDateTime.split("T")[0],
              title: session.title,
              description: `${session.description.replace(/<\/?p>/g, "")}`,
              type: "OnlineSession",
              link: session.path,
              start: new Date(session.startDateTime),
              end: new Date(session.endDateTime),
              instructors: item.classes.teachers,
              class: item.classes.title,
            };
            events.push(event);
          }
        });
      }

      if (item.survey) {
        item.survey.forEach((survey: any) => {
          if (survey.startDateTime && survey.title && survey.description) {
            const event = {
              date: survey.startDateTime.split("T")[0],
              title: survey.title,
              description: `${survey.description.replace(/<\/?p>/g, "")}`,
              type: "Survey",
              start: new Date(survey.startDateTime),
              end: survey.endDateTime ? new Date(survey.endDateTime) : null,
              instructors: item.classes.teachers,
              class: item.classes.title,
            };
            events.push(event);
          }
        });
      }

      if (item.forum) {
        item.forum.forEach((forum: any) => {
          if (forum.startDateTime && forum.title && forum.description) {
            const event = {
              date: forum.startDateTime.split("T")[0],
              title: forum.title,
              description: `${forum.description.replace(/<\/?p>/g, "")}`,
              type: "Forum",
              start: new Date(forum.startDateTime),
              end: forum.cutDateTime ? new Date(forum.cutDateTime) : null,
              instructors: item.classes.teachers,
              class: item.classes.title,
            };
            events.push(event);
          }
        });
      }
    });

    return events;
  }
}
const TeacherCalendarView: FC = () => {
  const { user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const { t } = useTranslation();
  const [events, setEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isAdmin] = useRecoilState(adminState)
  const [showFilterPopUp, setShowFilterPopup] = useState<boolean>(false);
  const [selectedClassFilter, setClassSelectedFilter] = useState<string>("");
  const { themeTextColor } = useRecoilValue(ColorSelector);
  const { selectedClass, setselectedClass, setClassTitles } =
    useMyContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let calendarData;
        if (isAdmin !== null && isAdmin === true) {
          calendarData = await axios.get(`https://rpslsxq6sc.execute-api.eu-west-1.amazonaws.com/dev/calendar/${tenantName}`)
          setEvents(formatEventData(calendarData.data.data) || []);
        }
        else {
          calendarData = await get(`calendar/${subId}/${tenantName}`, provider);
          setEvents(formatEventData(calendarData.data) || []);
        }

        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const courseTitlesSet = new Set<string>();

    events.forEach((event) => {
      const classDetails = event;
      if (classDetails.class) {
        courseTitlesSet.add(classDetails.class);
      }
    });

    // Convert Sets to arrays
    const uniqueCourseTitles = Array.from(courseTitlesSet);

    setClassTitles(uniqueCourseTitles);
  }, [events]);

  const handleClassFilter = (filterType: string) => {
    setShowFilterPopup(true)
    setClassSelectedFilter(filterType)
  }

  const filteredEvents = events.filter((event) => event.class.includes(selectedClass))

  // useEffect(() => {
  //   console.log("filteredEvents", filteredEvents)
  // }, [filteredEvents])

  return (
    <div style={{ marginBottom: "5.62rem" }}>
      <div>
        <Typography text={t("calendar.Calendar")} fontSize="1.75rem" />

        {/* filter */}
        <FlexDiv style={{ marginTop: "1.25rem" }}>
          <MediumTypography text={t("dashboard.Filter")} />
          <FilterButtonsCont>
            <FilterButton onClick={() => handleClassFilter("Class")}>{t("dashboard.Class")}</FilterButton>
          </FilterButtonsCont>
          <FilterButtonsCont>
            {selectedClass && (
              <FilterSelected text={t("dashboard.Class")} labelContext={selectedClass} labelContextClickFunction={() => { setselectedClass("") }} />
            )}
          </FilterButtonsCont>
        </FlexDiv>

      </div>
      {/* <TeacterCalendarEvents events={events} /> */}
      <TeacherCalendar events={filteredEvents} loading={loading} />
      {showFilterPopUp && (
        <PopUp
          icon={<SvgSettingIcon fill={themeTextColor} />}
          title={t(`dashboard.${selectedClassFilter}`)}
          content={<ClassInProgressFilter selectedClassFilter={selectedClassFilter} />}
          showline={false}
          show={showFilterPopUp}
          setShow={setShowFilterPopup}
          showButton={false}
        />
      )}
    </div>
  );
};

export default TeacherCalendarView;
