import { GroupChatData, UserFriends, userChatData } from "../../components/ContactMessages/ContactMessagesData";


// Finds where the user ID is 1 in the messages data and the friend ID is equal to the selected friend 
// and then returns the chat between them. Note that 1 is the user ID that is using the application.
export const fetchFriendMessages = (friendId: string) => {
  const friendMessages =
    userChatData.find(
      (chat) =>
        (chat.UserId === "1" && chat.FriendId === friendId) ||
        (chat.UserId === friendId && chat.FriendId === "1")
    )?.Messages || [];
  return friendMessages;
}; 
// End of function


// Loads messages for the selected friend ID
export const loadSelectedFriendMessages = (friendId: string) => {
  const friendMessages = fetchFriendMessages(friendId);
}; 
// End of function


// Returns the display name of a user given their ID
export const getUserDisplayName = (senderId: string) => {
  const sender = UserFriends.find((friend) => friend.id === senderId);
  return sender ? sender.userName : "Unknown Sender";
}; 
// End of function


// Handles blocking a conversation with a friend or in a group
export const handleBlockConversation = (
  selectedFriendId: string | null,
  selectedGroupId: string | null
) => {
  if (selectedFriendId) {
    // console.log(`Blocking conversation with friend ID: ${selectedFriendId}`);
    // Add logic to block the conversation with selectedFriendId
  } else if (selectedGroupId) {
    // console.log(`Blocking conversation in group ID: ${selectedGroupId}`);
    // Add logic to block the conversation in the selected group
  }
  // Add additional logic if needed
}; 
// End of function


// Handles archiving a conversation with a friend or in a group
export const handleArchiveConversation = (
  selectedFriendId: string | null,
  selectedGroupId: string | null
) => {
  if (selectedFriendId) {
    // console.log(`Archiving conversation with friend ID: ${selectedFriendId}`);
  } else if (selectedGroupId) {
    // console.log(`Archiving conversation in group ID: ${selectedGroupId}`);
  }
}; 
// End of function


// Handles deleting a conversation with a friend or in a group
export const handleDeleteConversation = (
  selectedFriendId: string | null,
  selectedGroupId: string | null
) => {
  if (selectedFriendId) {
    // console.log(`Deleting conversation with friend ID: ${selectedFriendId}`);
  } else if (selectedGroupId) {
    // console.log(`Deleting conversation in group ID: ${selectedGroupId}`);
  }
}; 
// End of function


// Handles exiting a group
export const handleExitGroup = (selectedGroupId: string) => {
  // console.log(`Exiting group with ID: ${selectedGroupId}`);
}; 
// End of function


// Handles muting a conversation with a friend or in a group
export const handleMuteConversation = (
  selectedFriendId: string | null,
  selectedGroupId: string | null
) => {
  if (selectedFriendId) {
    // console.log(`Muting conversation with friend ID: ${selectedFriendId}`);
    // Add logic to mute the conversation with selectedFriendId
  } else if (selectedGroupId) {
    // console.log(`Muting conversation in group ID: ${selectedGroupId}`);
    // Add logic to mute the conversation in the selected group
  }
  // Add additional logic if needed
}; 
// End of function


// Handles showing or hiding chat options
export const handleShowChatOption = (
  setShowChatOption: React.Dispatch<React.SetStateAction<boolean>>,
  ShowChatOption: boolean
) => {
  setShowChatOption(!ShowChatOption);
}; 
// End of function


// Handles toggling the message div
export const handleMessageDiv = (
  setShowSearchPlace: React.Dispatch<React.SetStateAction<boolean>>,
  ShowSearchPlace: boolean
) => {
  setShowSearchPlace(!ShowSearchPlace);
}; 
// End of function


// Handles sending a message to a friend or a group
export const handleSendIconClick = (
  inputMessage: string,
  selectedFriendId: string | null,
  selectedGroupId: string | null,
  setInputMessage: React.Dispatch<React.SetStateAction<string>>
) => {
  if (inputMessage.trim() !== "" && selectedFriendId) {
    // console.log(`Message sent to ${selectedFriendId}:`, inputMessage);

    setInputMessage("");
  } else if (selectedGroupId) {
    // console.log(`Message sent to ${selectedGroupId}:`, inputMessage);
    setInputMessage("");
  }
}; 
// End of function


// Handles selecting a group
export const handleGroupSelect = (
  groupId: string,
  setSelectedGroupId: React.Dispatch<any>,
  setSelectedFriendId: React.Dispatch<React.SetStateAction<string | null>>,
  GroupChatData: any[],
  setSelectedGroupData: React.Dispatch<React.SetStateAction<any | null>>,
  setSelectedGroupChatData: React.Dispatch<React.SetStateAction<any[]>>
) => {
  // console.log(`Group selected with ID: ${groupId}`);
  setSelectedGroupId(groupId);
  setSelectedFriendId(null); // Reset selected friend ID

  const selectedGroup = GroupChatData.find((group) => group.id === groupId);
  setSelectedGroupData(selectedGroup || null);
  setSelectedGroupChatData(selectedGroup ? selectedGroup.ChatData : []);
}; 
// End of function


// Returns information about the selected friend
export const getSelectedFriendInfo = (selectedFriendId: string | null, UserFriends: any[]) => {
  if (selectedFriendId) {
    const selectedFriend = UserFriends.find(
      (friend) => friend.id === selectedFriendId
    );

    if (selectedFriend) {
      return {
        profileImg: selectedFriend.profile,
        senderName: selectedFriend.userName,
        // Add other friend details here if needed
      };
    }
  }

  // Return empty values if selectedFriendId is not available or not found
  return {
    profileImg: "",
    senderName: "",
    // Add default values for other details if needed
  };
}; 
// End of function


// Handles selecting a friend
export const handleFriendSelect = (
  friendId: any,
  setSelectedFriendId: React.Dispatch<React.SetStateAction<string | null>>,
  setSelectedGroupId: React.Dispatch<React.SetStateAction<string | null>>
) => {
  setSelectedFriendId(friendId);
  setSelectedGroupId(null);
}; 
// End of function


// Scrolls to a message in the chat container
export const scrollToMessage = (index: number, chatContainerRef: React.RefObject<HTMLDivElement>) => {
  // Scroll to the message based on its index
  // You can use the ref of the container where your messages are rendered
  if (chatContainerRef.current) {
    const messageElement = chatContainerRef.current.children[index];
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: "smooth" });
    }
  }
}; 
// End of function
