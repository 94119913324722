import styled from 'styled-components'


export const IconPointsContainer = styled.div`
display: flex;
align-items:start;
padding-left:2.88rem;
margin-top:1.06rem;
gap:0.62rem;
cursor: pointer;
`;
export const QuestionFlexContainer = styled.div`
width:100%;
display: flex;
flex-direction:column;
gap:0.31rem;
`;
export const IconContainer = styled.div`
display: flex;
justify-content:space-between;
align-items:center;

`;
