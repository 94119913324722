import React, { useState, useEffect } from "react";
import {
  StudentTruOrFalseContainerParent,
  CustomCheckboxWrapper,
  CheckBox,
  CommentContainer,
} from "./StudentQuizTrueOrFalse.style";
import { LightTypography, MediumTypography } from "../../fonts/Fonts";
import { findAnswerFeedbackV1, findStudentComment } from "../quizElementsUtils";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../../utils/StringManipulation";
import { useParams } from "react-router-dom";

interface StudentQuizTrueOrFalseProps {
  trueorfalsedata?: any[];
  partID?: any;
  allData?: any[];
  questionIndex?: number;
  partIndex?: number;
  setTruOrFalseAnswer?: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean | undefined }>
  >;
  studentResponse?: any;
  truOrFalseAnswerInitialValue?: { [key: string]: Boolean | undefined };
  setQuestionId?: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  setQuestionType?: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  started?: boolean;
  setIsCorrect?: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean | null | string }>
  >;
  showGrade?: boolean;
  showPublishGrade?: boolean;
  setQuizTemplateQuestionsId?:React.Dispatch<
  React.SetStateAction<{ [key: string]: number }>
>;
quizId:any;
previewMode:boolean;
}

function StudentQuizTrueOrFalse({
  studentResponse,
  trueorfalsedata,
  partID,
  allData,
  questionIndex,
  partIndex,
  setTruOrFalseAnswer,
  truOrFalseAnswerInitialValue,
  setQuestionId,
  setQuestionType,
  started,
  setIsCorrect,
  showGrade,
  showPublishGrade,
  setQuizTemplateQuestionsId,
  quizId,
  previewMode
}: StudentQuizTrueOrFalseProps) {
  const [selectedAnswer, setSelectedAnswer] = useState<boolean | null>(null);
  const [TruOrFalseResponseTrue, setTruOrFalseResponseTrue] = useState<
    boolean | null
  >(null);
  const [TruOrFalseResponseFalse, setTruOrFalseResponseFalse] = useState<
    boolean | null
  >(null);
  const { t } = useTranslation();
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const params = useParams();
  const classId = params.classId;

  // Find the answer of the student and return it
  const findStudentAnswer = (questionId: any, questionType: string) => {
    if (studentResponse && studentResponse.length > 0) {
      const studentAnswer = studentResponse.find(
        (answer: any) =>
          Number(answer.questionId) === Number(questionId) &&
          answer.type === questionType
      );
      if (
        studentAnswer &&
        studentAnswer.answers &&
        studentAnswer.answers.length > 0 &&
        studentAnswer.answers[0].answer
      ) {
        if (studentAnswer.answers[0].answer.answer === true) {
          setTruOrFalseResponseTrue(true);
          setTruOrFalseResponseFalse(false);
        } else {
          setTruOrFalseResponseFalse(true);
          setTruOrFalseResponseTrue(false);
        }
      } else {
        setTruOrFalseResponseFalse(null);
        setTruOrFalseResponseTrue(null);
      }
      if (
        questionIndex !== undefined &&
        studentAnswer &&
        studentAnswer.answers &&
        studentAnswer.answers.length &&
        allData
      ) {
        const correctKeyNormal = `${allData[questionIndex].questionId}_${allData[questionIndex].type}_isCorrect`;
        if (
          partIndex !== undefined &&
          allData &&
          allData[questionIndex].questionParts &&
          allData[questionIndex].questionParts[partIndex]
        ) {
          const correctKeyConposed = `${allData[questionIndex].questionId}_${allData[questionIndex].questionParts[partIndex].id}_${allData[questionIndex].questionParts[partIndex].type}_isCorrect`;
          setIsCorrect &&
            setIsCorrect((prevDescriptions) => ({
              ...prevDescriptions,
              [correctKeyConposed]: studentAnswer.answers[0].isCorrect,
            }));
        } else {
          setIsCorrect &&
            setIsCorrect((prevDescriptions) => ({
              ...prevDescriptions,
              [correctKeyNormal]: studentAnswer.answers[0].isCorrect,
            }));
        }
      } else {
        setIsCorrect && setIsCorrect({});
      }
    } else {
      setTruOrFalseResponseFalse(null);
      setTruOrFalseResponseTrue(null);
      setIsCorrect && setIsCorrect({});
    }
  };

  // Call the findStudentAnswer to show the answer to the student
  useEffect(() => {
    if (questionIndex !== undefined) {
      if (
        partIndex !== undefined &&
        allData &&
        allData[questionIndex].questionParts &&
        allData[questionIndex].questionParts[partIndex]
      ) {
        findStudentAnswer(
          allData[questionIndex].questionParts[partIndex].id,
          allData[questionIndex].questionParts[partIndex].type
        );
      } else {
        findStudentAnswer(
          allData && allData[questionIndex].questionId,
          allData && allData[questionIndex].type
        );
      }

      if(studentResponse && studentResponse.length === 0 && !previewMode){
// Fetch and set the timer from local storage if it exists
const savedStudentAnswer = JSON.parse(localStorage.getItem('localStudentResponse') || '[]');
const currentQuizAnswer = savedStudentAnswer.find((item: any) =>
  item.classSourceId === classId &&
  item.subId === subIdSplitted &&
  item.refId === quizId
);

if (currentQuizAnswer &&
  allData &&
  allData[questionIndex]) {
  let key = "";
if (
  (allData[questionIndex].composed ||
    allData[questionIndex].type === "COMPOSITE") &&
  partIndex !== undefined &&
  allData[questionIndex].questionParts
) {
  key = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}`;
} else {
  key = `${allData[questionIndex].title}`;
}

if(currentQuizAnswer && currentQuizAnswer.localStudentResponse && currentQuizAnswer.localStudentResponse[key] === true){
    setTruOrFalseResponseTrue(true);
    setTruOrFalseResponseFalse(false);
    setTruOrFalseAnswer &&
        setTruOrFalseAnswer((prevAnswers) => ({
          ...prevAnswers,
          [key]: currentQuizAnswer.localStudentResponse[key],
        }));
  } else if(currentQuizAnswer && currentQuizAnswer.localStudentResponse && currentQuizAnswer.localStudentResponse[key] === false){
    setTruOrFalseResponseFalse(true);
    setTruOrFalseResponseTrue(false);
    setTruOrFalseAnswer &&
        setTruOrFalseAnswer((prevAnswers) => ({
          ...prevAnswers,
          [key]: currentQuizAnswer.localStudentResponse[key],
        }));
  }
  
} else {
  setTruOrFalseResponseFalse(null);
  setTruOrFalseResponseTrue(null);
}
      }

      

    }
  }, [questionIndex, partIndex, allData, studentResponse]);

  // Update the type of the question and the id of the question depending on the dependencies
  useEffect(() => {
    if (questionIndex !== undefined) {
      if (
        partIndex !== undefined &&
        allData &&
        allData[questionIndex].questionParts &&
        allData[questionIndex].questionParts[partIndex]
      ) {
        // For composed questions
        const singleKey = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}_ID`;
        const typekey = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}Type`;
        const templateKey = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}_TEMPLATEKEY`;
        setQuestionType &&
          setQuestionType((prevDescriptions) => ({
            ...prevDescriptions,
            [typekey]: allData[questionIndex].questionParts[partIndex].type,
          }));
        setQuestionId &&
          setQuestionId((prevId) => ({
            ...prevId,
            [singleKey]: allData[questionIndex].questionParts[partIndex].id,
          }));
          setQuizTemplateQuestionsId &&
          setQuizTemplateQuestionsId((prevId) => ({
            ...prevId,
            [templateKey]: allData && allData[questionIndex].quizTemplateQuestionId,
          }));
      } else {
        // For non-composed questions
        const singleKey = `${allData && allData[questionIndex].title}_ID`;
        const typekey = `${allData && allData[questionIndex].title}Type`;
        const templateKey = `${allData && allData[questionIndex].title}_TEMPLATEKEY`;
        setQuestionId &&
          setQuestionId((prevId) => ({
            ...prevId,
            [singleKey]: allData && allData[questionIndex].questionId,
          }));
        setQuestionType &&
          setQuestionType((prevDescriptions) => ({
            ...prevDescriptions,
            [typekey]: allData && allData[questionIndex].type,
          }));
          setQuizTemplateQuestionsId &&
          setQuizTemplateQuestionsId((prevId) => ({
            ...prevId,
            [templateKey]: allData && allData[questionIndex].quizTemplateQuestionId,
          }));
      }
    }
  }, [questionIndex, allData]);

  // Set the answer of student
  const handleAnswerChange = (value: boolean) => {
    if (questionIndex !== undefined && allData) {
      let key = "";
      if (
        (allData[questionIndex].composed ||
          allData[questionIndex].type === "COMPOSITE") &&
        partIndex !== undefined &&
        allData[questionIndex].questionParts
      ) {
        key = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}`;
      } else {
        key = `${allData[questionIndex].title}`;
      }

      // Retrieve the quizAnswers array from local storage
let quizAnswers = JSON.parse(localStorage.getItem('localStudentResponse') || '[]');

// Find the index of the existing timer object
const index = quizAnswers.findIndex((item: any) =>
  item.classSourceId === classId &&
  item.subId === subIdSplitted &&
  item.refId === quizId
);

if (index !== -1) {
  // Merge the new key-value pair with the existing localStudentResponse
  let existingResponse = quizAnswers[index].localStudentResponse || {};
  existingResponse[key] = value;

  // Update the time of the existing object
  quizAnswers[index].localStudentResponse = existingResponse;
} else {
  // Add a new object if it doesn't exist
  const data = {
    subId: subIdSplitted,
    provider:provider,
    classSourceId: classId,
    refId: quizId,
    localStudentResponse: { [key]: value }
  };
  quizAnswers.push(data);
}

// Save the updated array back to local storage
localStorage.setItem('localStudentResponse', JSON.stringify(quizAnswers));
  
      setTruOrFalseAnswer &&
        setTruOrFalseAnswer((prevAnswers) => ({
          ...prevAnswers,
          [key]: value,
        }));
    }
  };

  const { checkboxBgColor,checkboxBorderColor,checkboxBorderRadius, RadioButtonGradientColor} =
    useRecoilValue(ColorSelector);

  return (
    <>
      {/* The Feedback goes Here */}
      <MediumTypography
        text={showPublishGrade && findAnswerFeedbackV1(
          studentResponse,
          questionIndex !== undefined &&
            partIndex !== undefined &&
            allData &&
            allData[questionIndex].questionParts
            ? allData[questionIndex].questionParts[partIndex].id
            : questionIndex !== undefined && allData
            ? allData[questionIndex].questionId
            : undefined,
          questionIndex !== undefined &&
            partIndex !== undefined &&
            allData &&
            allData[questionIndex].questionParts
            ? allData[questionIndex].questionParts[partIndex].type
            : questionIndex !== undefined && allData
            ? allData[questionIndex].type
            : undefined
        )}
        color="#0B8FFF"
        fontSize=".8rem"
      />
      <StudentTruOrFalseContainerParent>
        <>
          <CustomCheckboxWrapper>
            <CheckBox
              bgcolor={RadioButtonGradientColor}
              type="radio"
              name={`${
                (allData && allData[questionIndex || 0].composed) ||
                (allData && allData[questionIndex || 0].type === "COMPOSITE")
                  ? allData[questionIndex || 0].questionParts[partIndex || 0]
                      .title
                  : allData && allData[questionIndex || 0].title
              }`}
              checked = {
                truOrFalseAnswerInitialValue &&
                questionIndex !== undefined &&
                (partIndex !== undefined &&
                allData &&
                allData[questionIndex]?.questionParts
                  ? truOrFalseAnswerInitialValue[
                      `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}`
                    ]
                  : truOrFalseAnswerInitialValue[
                      `${allData?.[questionIndex]?.title}`
                    ]) === true
                  ? true
                  : truOrFalseAnswerInitialValue &&
                  questionIndex !== undefined &&
                  (partIndex !== undefined &&
                  allData &&
                  allData[questionIndex]?.questionParts
                    ? truOrFalseAnswerInitialValue[
                        `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}`
                      ]
                    : truOrFalseAnswerInitialValue[
                        `${allData?.[questionIndex]?.title}`
                      ]) === false ? false :
                  TruOrFalseResponseTrue &&
                    TruOrFalseResponseTrue !== undefined &&
                    TruOrFalseResponseTrue !== null
                    ? TruOrFalseResponseTrue
                    : false
              }
              id={`${
                (allData && allData[questionIndex || 0].composed) ||
                (allData && allData[questionIndex || 0].type === "COMPOSITE")
                  ? allData[questionIndex || 0].questionParts[partIndex || 0]
                      .title
                  : allData && allData[questionIndex || 0].title
              }`}
              onChange={() => {
                setSelectedAnswer(true);
                handleAnswerChange(true);
              }}
              disabled={
                 !started ? true : false
              }
            />
            <LightTypography fontSize="0.875rem" text={t("quiz.TRUE")} />
          </CustomCheckboxWrapper>
          <CustomCheckboxWrapper>
            <CheckBox
              bgcolor={RadioButtonGradientColor}
              type="radio"
              name={`${
                (allData && allData[questionIndex || 0].composed) ||
                (allData && allData[questionIndex || 0].type === "COMPOSITE")
                  ? allData[questionIndex || 0].questionParts[partIndex || 0]
                      .title
                  : allData && allData[questionIndex || 0].title
              }`}
              checked = {
                truOrFalseAnswerInitialValue &&
                questionIndex !== undefined &&
                (partIndex !== undefined &&
                allData &&
                allData[questionIndex]?.questionParts
                  ? truOrFalseAnswerInitialValue[
                      `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}`
                    ]
                  : truOrFalseAnswerInitialValue[
                      `${allData?.[questionIndex]?.title}`
                    ]) === false
                  ? true
                  : truOrFalseAnswerInitialValue &&
                  questionIndex !== undefined &&
                  (partIndex !== undefined &&
                  allData &&
                  allData[questionIndex]?.questionParts
                    ? truOrFalseAnswerInitialValue[
                        `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}`
                      ]
                    : truOrFalseAnswerInitialValue[
                        `${allData?.[questionIndex]?.title}`
                      ]) === true ? false 
                  : TruOrFalseResponseFalse &&
                    TruOrFalseResponseFalse !== undefined &&
                    TruOrFalseResponseFalse !== null
                  ? TruOrFalseResponseFalse
                  : false
              }    
              id={`${
                (allData && allData[questionIndex || 0].composed) ||
                (allData && allData[questionIndex || 0].type === "COMPOSITE")
                  ? allData[questionIndex || 0].questionParts[partIndex || 0]
                      .title
                  : allData && allData[questionIndex || 0].title
              }`}
              onChange={() => {
                setSelectedAnswer(false);
                handleAnswerChange(false);
              }}
              disabled={
                 !started ? true : false
              }
            />
            <LightTypography fontSize="0.875rem" text={t("quiz.FALSE")} />
          </CustomCheckboxWrapper>
        </>
      </StudentTruOrFalseContainerParent>
      {partIndex === undefined &&
      showPublishGrade &&
      allData &&
      allData[questionIndex || 0] &&
      findStudentComment(
        studentResponse,
        allData[questionIndex || 0].questionId,
        allData[questionIndex || 0].type
      ) !== "" ? (
        <>
          {/* Comment of the instructor on the answer for the normal question goes Here */}
          <MediumTypography text="Instructor Comment" />
          <CommentContainer>
            <LightTypography
              text={findStudentComment(
                studentResponse,
                questionIndex !== undefined &&
                  partIndex !== undefined &&
                  allData &&
                  allData[questionIndex].questionParts
                  ? allData[questionIndex].questionParts[partIndex].id
                  : questionIndex !== undefined && allData
                  ? allData[questionIndex].questionId
                  : undefined,
                questionIndex !== undefined &&
                  partIndex !== undefined &&
                  allData &&
                  allData[questionIndex].questionParts
                  ? allData[questionIndex].questionParts[partIndex].type
                  : questionIndex !== undefined && allData
                  ? allData[questionIndex].type
                  : undefined
              )}
              fontSize=".8rem"
            />
          </CommentContainer>
        </>
      ) : (
        ""
      )}
      {partIndex !== undefined &&
      showPublishGrade &&
      allData &&
      allData[questionIndex || 0] &&
      findStudentComment(
        studentResponse,
        allData[questionIndex || 0].questionParts[partIndex].id,
        allData[questionIndex || 0].questionParts[partIndex].type
      ) !== "" ? (
        <>
          {/* Comment of the instructor on the answer for the composed question goes Here */}
          <MediumTypography text="Instructor Comment" />
          <CommentContainer>
            <LightTypography
              text={findStudentComment(
                studentResponse,
                questionIndex !== undefined &&
                  partIndex !== undefined &&
                  allData &&
                  allData[questionIndex].questionParts
                  ? allData[questionIndex].questionParts[partIndex].id
                  : questionIndex !== undefined && allData
                  ? allData[questionIndex].questionId
                  : undefined,
                questionIndex !== undefined &&
                  partIndex !== undefined &&
                  allData &&
                  allData[questionIndex].questionParts
                  ? allData[questionIndex].questionParts[partIndex].type
                  : questionIndex !== undefined && allData
                  ? allData[questionIndex].type
                  : undefined
              )}
              fontSize=".8rem"
            />
          </CommentContainer>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default StudentQuizTrueOrFalse;
