import React, { useState, useEffect, useRef, useContext } from "react";
import { NewAnnouncementsHolder, ButtonsContainer } from "./DashboardAnnouncements.style";
import { Typography } from "../../elements/fonts/Fonts";
import ReusableInput from "../../elements/reusableInput/ReusableInput";
import ReusableSubmitButton from "../../elements/reusableSubmitButton/ReusableSubmitButton";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { post } from '../../utils/AxiosRequests';
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { useTranslation } from "react-i18next";
import { WebSocketConnector } from "../../Views/messages/webSocketConnection";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { useRecoilValue } from "recoil";
import { PopupSelectorColor } from "../../recoil/ThemeSelectors";
import { WebsocketContext } from "../../contexts/notificationContext";
import NewAnnouncements from "../../Views/courseView/announcements/newAnnouncement/NewAnnouncements";

interface AnnouncementProps {
  id: string;
}

const DashboardAnnouncements = ({ id }: AnnouncementProps): JSX.Element => {
  const { ContentContainer_BgColor, theme } = useRecoilValue(PopupSelectorColor)
  const [rerender, setRerender] = useState<boolean>(false); // State to trigger rerender
  return (
    <NewAnnouncementsHolder key={rerender.toString()} bgColor={ContentContainer_BgColor} style={{ boxShadow: theme === "light" ? "0px 4px 14px 0px rgba(192, 220, 214, 0.32)" : "" }}>
      <NewAnnouncements id={id} />
    </NewAnnouncementsHolder>
  );
};

export default DashboardAnnouncements;