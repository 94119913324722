import React, { useState, FC, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../utils/StringManipulation";
import axios from "axios";
import { Container } from "../../elements/StyledContainerForm/StyledContainerForm.style";
import {
  LightTypography,
  PrimaryFontBoldText,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  LabelDiv,
  BtnsDiv,
  LabelBtnsDiv,
  ButtonsDiv,
  FlexDiv,
  FlexBetween,
} from "./HtmlForm.style";
import StyledInput from "../../elements/StyledInput/StyledInput";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { SvgMonitorIcon } from "../../elements/Icons";
import ButtonElement from "../../elements/button/ButtonElement";
import Toggle from "../../elements/Toogle/toogle";
import TwoDatePicker from "../../elements/TwoDatePicker/TwoDatePicker";
import CancelButton from "../../elements/StyledButton/CancelButton";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import { useStateContext } from "../../contexts/ContextProvider";
import { ReactComponent as SvgCloseIcon } from "../../assets/icons/Close1.svg";
import LoaderThreeDot from "../loader/loaderThreeDot/LoaderThreeDot";
import { ContentFormProps } from "../../interfaces/ContentFormProps";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { WebSocketConnector } from "../../Views/messages/webSocketConnection";
import { WebsocketContext } from "../../contexts/notificationContext";

const HtmlForm: FC<ContentFormProps> = ({
  setShow,
  handleSubmit,
  setShowEditContent,
  showEditContent,
  setContentTitleInTopic,
  editContent,
  newContentTitle,
  contentId,
  activeGetModulesWithTopics,
  newTopicRefId,
}) => {
  const { t } = useTranslation();
  const classSourceId = useParams();
  const setClassSourceId = classSourceId.id;
  const { isLoading, user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const baseURL = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";
  const path = "resource/create";
  const tenantName = localStorage.getItem("tenant");
  const {
    setContent_Title,
    contentType,
    setContent_Data,
    getContent_Data,
    contentIdToEdit,
    topicRefId,
    content_Index,
    setIsPopUpOpen,
  } = useStateContext();

  const [prevData, setPrevData] = useState<any>();
  const [readyToEdit, setReadyToEdit] = useState(true);
  const [isSetLoading, setIsSetLoading] = useState(false);
  const [foundRefIdState, setFoundRefIdState] = useState<number>();
  const [visibleStudents, setVisibleStudents] = useState(true);
  const [showTimeVisibility, setShowTimeVisibility] = useState(false);
  const [htmlName, setHtmlName] = useState("");
  const [description, setDescription] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [error, setError] = useState(false);
  
  const { isReady, value, send } = useContext(WebsocketContext);

  // Function to update form
  const updateHTML = async (
    path: string,
    body: any,
    htmlId: number | undefined
  ) => {
    try {
      const response = await axios.put(
        `${baseURL}/${path}/${htmlId}/${tenantName}`,
        { data: body }
      );
      setShow(false);
      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
      throw error; // Rethrow the error to be handled by the calling service
    }
  };

  // Function to get data
  const getHtmlToEdit = async (path: string, htmlId: number) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(`${baseURL}/${path}/${htmlId}/${tenantName}`);
      setPrevData(res.data.data);
      setReadyToEdit(false);
      const toReturn: any[] = res.data.data;
      return toReturn;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (showEditContent && htmlName !== "") {
      setReadyToEdit(false);
    }
  }, [htmlName]);

  // This useEffect hook is used to fetch data when showEditContent is true.
  useEffect(() => {
    const fetchData = async () => {
      const yourArray = await activeGetModulesWithTopics();
      const solutionArray: any[] = Array.isArray(yourArray) ? yourArray : [];
      function findRefIdInModules(
        modulesArray: any[],
        targetContentId: number
      ) {
        for (let i = 0; i < modulesArray.length; i++) {
          const topicsArray = modulesArray[i].topicsArray;

          for (let j = 0; j < topicsArray.length; j++) {
            const contentsArray = topicsArray[j].contents;

            for (let k = 0; k < contentsArray.length; k++) {
              if (contentsArray[k].contentId === targetContentId) {
                return contentsArray[k].refId;
              }
            }
          }
        }
        // Return a default value (e.g., null) if the contentId is not found
        return null;
      }
      if (contentIdToEdit) {
        const contentIdToFind = contentIdToEdit; // Replace with the specific contentId you're looking for
        const foundRefId = findRefIdInModules(solutionArray, contentIdToFind);
        setFoundRefIdState(foundRefId);
        await getHtmlToEdit("resource/html", foundRefId);
      }
    };
    if (showEditContent) {
      fetchData();
    }
  }, []);

  // This useEffect hook is used to update the states with edit data (prevData)
  useEffect(() => {
    if (prevData && showEditContent) {
      setContent_Title(prevData.title);
      setHtmlName(prevData.title);
      setDescription(prevData.description);
      setVisibleStudents(prevData.isVisible);
      setShowTimeVisibility(prevData.visibleFrom === null ? false : true);
      setFromDate(prevData.visibleFrom ? new Date(prevData.visibleFrom) : null);
      setToDate(prevData.visibleTo ? new Date(prevData.visibleTo) : null);
    }
  }, [prevData]);

  // Function to handle description
  const handleDescriptionChange = (value: any) => {
    setDescription(value);
  };

  // handle toggle change visiblity for students
  const handleToggleChangeVisible = (isChecked: boolean) => {
    setVisibleStudents(isChecked);
  };

  // function to set time visibility interval
  const handleTwoDatePickerChange = (start: Date | null, end: Date | null) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleToggleChangeTime = (isChecked: boolean) => {
    if (isChecked) {
      setShowTimeVisibility(true); // Show Time Visibility when the toggle is on
    } else {
      setShowTimeVisibility(false);
      setFromDate(null);
      setToDate(null);
    }
  };

  // handle save
  const handleContinueClick = async () => {
    if (
      htmlName.trim() === "" ||
      description.trim() === "" ||
      (showTimeVisibility && (!fromDate || !toDate))
    ) {
      setError(true);
      setShow(true);
    } else {
      setError(false);
      if (contentId !== undefined && newContentTitle !== undefined)
        editContent(contentId, newContentTitle);
      setShowEditContent(false);
      setShow(false);
      setIsPopUpOpen(false);
      if (!showEditContent) handleSubmit();

      const htmlData: any = {
        _id: contentIdToEdit,
        classSourceId: setClassSourceId,
        userSourceId: subId,
        provider:provider,
        contentIndex: content_Index,
        topicRefId: topicRefId,
        type: contentType,
        title: htmlName,
        description: description,
        showTimeVisibility: showTimeVisibility,
        visibleStudents: visibleStudents,
        fromDate: fromDate,
        toDate: toDate,
      };

      try {
        if (!showEditContent) {
          const response = await axios.post(
            `${baseURL}/${path}/${tenantName}`,
            { data: htmlData }
          );
          if (isReady && send) {

            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "create",
                  title: htmlName,
                  contentType: "HtmlResources",
                  refId: response.data.data.resourceId,
                  refTable: "HtmlResources",
                  subId: subId,
                  provider:provider,
                  type: "content",
                  description: `Title: ${htmlName}`,
                },
              })
            );
          }
          setIsSetLoading(false);
        } else if (showEditContent) {
          await updateHTML("resource/html", htmlData, foundRefIdState);
          if (isReady && send) {
            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "update",
                  title: htmlName,
                  contentType: "HtmlResources",
                  subId: subId,
                  provider:provider,
                  refId: foundRefIdState,
                  refTable: "HtmlResources",
                  type: "content",
                  description: `Title: ${htmlName}`,
                },
              })
            );
          }
        }
        setShow(false);
        setIsPopUpOpen(false);
        await activeGetModulesWithTopics();
      } catch (error) {
        console.error("Axios Error:", error);
      } finally {
        await (activeGetModulesWithTopics && activeGetModulesWithTopics());
      }
    }
  };
  const { backgroundColor, themeTextColor, inputBorderColor, iconCloseColor } =
    useRecoilValue(ColorSelector);

  return (
    <Container
      themetextcolor={themeTextColor}
      backgroundcolor={backgroundColor}
      scrollbar={inputBorderColor}
    >
      {/* header */}
      {/* <FlexBetween>
        <FlexDiv>
          <SvgMonitorIcon />
          <PrimaryFontBoldText text={t("html.Html")} fontSize="1.125rem" />
        </FlexDiv> */}
        {showEditContent && readyToEdit && <LoaderThreeDot />}
        {/* <SvgCloseIcon
          color={iconCloseColor}
          onClick={() => {
            setShow(false);
            setIsPopUpOpen(false);
            setShowEditContent(false);
          }}
          style={{ cursor: "pointer" }}
        /> */}
      {/* </FlexBetween> */}

      {/* title */}
      <LabelDiv>
        <LightTypography text={t("forms.Title")} fontSize="0.875rem" />
        <StyledInput
          placeholder={t("html.Enter HTML Title")}
          type="text"
          onChange={(e: any) => {
            setHtmlName(e.target.value);
            setContent_Title(e.target.value);
            setContentTitleInTopic(e.target.value);
          }}
          value={htmlName}
          width="100%"
        />
        {htmlName.trim() === "" && error && (
          <ErrorDiv>{`${t("forms.Please")} ${t(
            "html.Enter HTML Title"
          )}`}</ErrorDiv>
        )}
      </LabelDiv>

      {/* description */}
      <LabelDiv>
        <LightTypography text={t("forms.Description")} fontSize="0.875rem" />
        <TinyEditor
          onChange={handleDescriptionChange}
          placeholder={t("html.Insert HTML Code Here")}
          initialValue={`${
            prevData && prevData.description ? prevData.description : ""
          }`}
          height="13.25rem"
        />
        {description.trim() === "" && error && (
          <ErrorDiv>{`${t("forms.Please")} ${t(
            "forms.Enter Description"
          )}`}</ErrorDiv>
        )}
      </LabelDiv>

      {/* toogles */}
      <BtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleChangeVisible}
            id="roleToggle1"
            checked={visibleStudents}
          />
          <TypographyRegularDescription
            text={t("forms.Make Visible for Students")}
            fontSize="1rem"
          />
        </LabelBtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleChangeTime}
            id="roleToggle2"
            checked={showTimeVisibility}
          />
          <TypographyRegularDescription
            text={t("forms.Set Time Visibility Interval")}
            fontSize="1rem"
          />
          {showTimeVisibility && (
            <>
              <TwoDatePicker
                onDateChange={handleTwoDatePickerChange}
                prevStartDate={fromDate}
                prevEndDate={toDate}
              />
              {(!fromDate || !toDate) && error && (
                <ErrorDiv>{`${t("forms.Please")} ${t(
                  "forms.Set Time Visibility Interval"
                )}`}</ErrorDiv>
              )}
            </>
          )}
        </LabelBtnsDiv>
      </BtnsDiv>

      {/* actions buttons */}
      <ButtonsDiv>
        <CancelButton
          name={t("forms.Cancel")}
          onClickFunction={() => {
            setShow(false);
            setIsPopUpOpen(false);
            setShowEditContent(false);
          }}
          color="#D85D66"
          borderColor="#D85D66"
          // backgroundColor="#384450"
        />
        <CancelButton
          name={t("forms.Save")}
          onClickFunction={() => {
            handleContinueClick();
            setContent_Title(htmlName);
          }}
          color="#fff"
          borderColor="#5DD3B3"
          backgroundColor="#5DD3B3"
        />
      </ButtonsDiv>
    </Container>
  );
};

export default HtmlForm;
