import styled from "styled-components";

export const NewAnnouncementsHolder = styled.div<{ bgColor: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
 row-gap:1.25rem;
  border-radius: 1.5625rem;
background:${props => props.bgColor};

padding: 1.25rem 1.25rem 0 1.25rem;
  `
  ;
export const ButtonsContainer = styled.div`
display: flex;
align-items: center;
gap: 0.62rem;
`