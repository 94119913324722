import { uuidSplitter } from "../../../../utils/StringManipulation";

export const getFileName = (fileName: string, maxLength: number = 20) => {
  const segments = fileName.split("/");
  const lastSegment = segments[segments.length - 1];
  const fileNmeSplitted = uuidSplitter(lastSegment);

  if (fileNmeSplitted.length > maxLength) {
    const fileNameWithoutExt = fileNmeSplitted.substring(0, maxLength);
    const fileExtension = lastSegment.split(".").pop();
    return `${fileNameWithoutExt}...${fileExtension}`;
  }

  return fileNmeSplitted;
};
