import React from "react";
import { DFCards, DFCardsLayout, DFLayout, DFTextFlex } from "./DiscussionForum.style";
import { Typography, TypographyRegularDescription } from "../../elements/fonts/Fonts";
import { SVGGraph, SvgCahrt } from "../../elements/Icons";


export const DiscussionForumCompo = (): JSX.Element => {


  return (
  <DFLayout>
    <DFTextFlex>
      <TypographyRegularDescription text="Intro to developer:" fontSize="1.125rem" />
      <Typography text="Assignment 1" fontSize="1.75rem" />
    </DFTextFlex>
    <DFCardsLayout>
      <DFCards>
        <SVGGraph />
        <TypographyRegularDescription text="Data not found" />
      </DFCards>
      <DFCards>
        <SvgCahrt />
        <TypographyRegularDescription text="Data not found" />
      </DFCards>
    </DFCardsLayout>
  </DFLayout>
  );
};
