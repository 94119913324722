import styled from "styled-components";


export  const AnalyticsTopParticipantBoxContainer = styled.div`
display: flex;
flex-direction:column;
flex:1;
max-width:100%;
height:100%;

gap: 1.25rem;
border-radius: 0.625rem;
height:100%;
/* background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
height: 17rem; */
`;

export  const AnalyticsTopParticipantStudentMainRow = styled.div`
display: flex;
width:100%;
position: relative;
justify-content:space-between;
`;
export  const AnalyticsTopParticipantStudentInfos = styled.div`
display: flex;
gap:0.65rem;
align-items:center;
`;

export  const AnalyticsTopParticipantStudentProfilePic = styled.img`
display: flex;
width: 3.125rem;
height: 3.125rem;
flex-shrink: 0;
border-radius:50%;
`;

export  const AnalyticsTopParticipantStudentCols = styled.div`
display: flex;
flex-direction:column;
gap: 0.19rem;
`;

export  const AnalyticsTopParticipantRenderedContainer = styled.div`
display: flex;
flex-direction:column;
width:100%;
gap: 1.25rem;

`;

export  const AnalyticsTopParticipantStudentNbPostReplyRow = styled.div`
display: flex;
gap: 1.25rem;
`;

export  const AnalyticsTopParticipantStudentNbPostMenuContainer = styled.div`
display: flex;
flex-direction:column;
position:absolute;
right:1.10rem;
top:1.25rem;
background-color:#1F2731;
border-radius: 0.3125rem;
z-index:1;
`;

export  const AnalyticsTopParticipantStudentNbPostMenuCell = styled.div`
display: flex;
width: 12.5rem;
gap:0.30rem;
padding:0.95rem 1.25rem 0.69rem 1.06rem;
align-items:center;
transition: background-color 0.3s ease;
border-radius:0.3125rem;
cursor: pointer;
/* &:first-child{
  border-radius: 0.3125rem 0.3125rem 0rem 0rem;
}
&:last-child{
  border-radius:  0rem 0rem 0.3125rem 0.3125rem;
} */
&:hover{
  background:#5DD3B3;
  color: #fff;
}

`;