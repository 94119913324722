import React from "react";
import {
  RubricEmptyCompoDiv,
  RubricEmptyDesription,
} from "./RubricEmptyCompo.style";
import { MediumTypography, Typography } from "../../elements/fonts/Fonts";
import { SvgRubricEmpty } from "../../elements/Icons";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

export const RubricEmptyCompo = (): JSX.Element => {
  const {
    boxShadow,
    Container_Gradient_BgColor,
    SecondaryTextColor,
    bghelp,
    theme,
  } = useRecoilValue(ColorSelector);
  const { t } = useTranslation()
  return (
    <RubricEmptyCompoDiv
      bgcolor={Container_Gradient_BgColor}
      boxshadow={boxShadow}
    >
      <MediumTypography text={t("Rubric.Rubric Preview")} fontSize="1rem" />
      <RubricEmptyDesription>
        <SvgRubricEmpty />
        <Typography fontSize="1.375rem" text={t("Rubric.Generate Rubric")} />
      </RubricEmptyDesription>
    </RubricEmptyCompoDiv>
  );
};
