import styled from "styled-components";
import { SeletectOptinToogleProps } from "../ProfileCheckBoxOptions/SeletOptionTisplaysInterface";
import { breakpoints } from "../../theme";

export const ChatCheckboxLabel = styled.label<SeletectOptinToogleProps>`
  display: flex;
  /* align-items: center; */
  cursor:pointer;
  margin-top:1.3rem;
  &::before{
    content:"";
    width:1em;
    min-width:1em;
    max-width:1em;
    height:1em;
    min-height:1em;
    max-height:1em;
    border: .05em solid #fff;
    border-radius: ${(props) => props.borderRadius || '.15rem'}; 
    margin-right:.5em;
    background:${props =>props.bgColor || "linear-gradient(to bottom, #2F353D, #545F6D)"};
    background:${props =>props.bgColor};
 border: 1px solid ${props =>props.borderColor};
  border-radius:${props =>props.borderRadius};
    @media (max-width: ${breakpoints.tablets}) {
      margin-top:0.2rem;
      margin-right:0.6rem
     }
  }
  &:hover:before{
  background-color:#35e07d;
  border: .05em solid #5DD3B3;
  background: linear-gradient(to bottom, #5dd3b3, ${props =>props.bgColor || "#2f3b40"} );
  /*   background-color:red; */
  }

`;

export const ChatSeletectOptinToogle = styled.input<SeletectOptinToogleProps>`
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
background:${props =>props.bgColor};
 border: 1px solid ${props =>props.borderColor};
  border-radius:${props =>props.borderRadius};
  margin-top:1.3rem;
  /* background-color: aqua; */
  /* color: black; */
  transition: background-color 0.3s ease;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  &:hover + ${ChatCheckboxLabel} ::before {
  
    background: linear-gradient(to bottom, #5dd3b3, ${props =>props.bgColor ||"#2f3b40"});
  }

  &:checked + ${ChatCheckboxLabel}::before {
    background: ${({ correct, bgColor }) =>
      correct === 'true'
        ? bgColor || 'linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%), linear-gradient(0deg, #5dd3b3, #5dd3b3)'
        : correct === 'false'
        ? '#D85D66'
        : bgColor || 'linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%), linear-gradient(0deg, #5dd3b3, #5dd3b3)'};
    /* background-color:red; */
    border: 1px solid ${({ correct }) => (correct === 'true' ? '#5dd3b3' : correct === 'false' ? '#D85D66' : '#5dd3b3')};
    display:flex;
    justify-content:center;
    align-items:center;
      color: ${props =>props.contentColor ||"#fff"};
    border: .05em solid #5DD3B3;
    content: "\\2713";
  
  }

  :focus + ${ChatCheckboxLabel}::before {
    /* background: linear-gradient(to bottom, #5dd3b3, #2f3b40); */
    background: linear-gradient(to bottom, #5dd3b3, ${props =>props.bgColor || "#2f3b40"});
    border: .05em solid #5DD3B3;
  }
`;