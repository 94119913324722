import React,{FC, useState} from 'react';
import {ReactComponent as Download} from '../../assets/icons/Download.svg'
import { Typography } from '../../elements/fonts/Fonts';
import { NavContainer,ItemsContainer } from './GraderNavMenu.style';
import { GraderNavMenuProps } from './GraderNavMenuInterface';
import { useTranslation } from 'react-i18next';
 const GarderNavMenu:FC<GraderNavMenuProps>= ({handleDownload}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false)
    
    
    return (
        <NavContainer>
          {/* <ItemsContainer
        //  onClick={handleQuizView}
         >
       <Book/>
       <Typography text='GRADEBOOK' fontSize='0.75rem' fontWeight='400' lineHeight='normal'/>
       </ItemsContainer> */}
          { !loading && (
            <ItemsContainer onClick={handleDownload}>
              <Download />
              <Typography text={t('assignment.Download all')} fontSize='0.75rem' fontWeight='400' lineHeight='normal' />
            </ItemsContainer>
          )}
          {loading && (
            <ItemsContainer>
              <Download />
              <Typography text={`${t('general.loading')}...`} fontSize='0.75rem' fontWeight='400' lineHeight='normal' />
            </ItemsContainer>
          )}
    
        </NavContainer>
      )
    }
    
    export default GarderNavMenu;