import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

interface DarkBg {
  background?: string;
  iconcontainer?: string;
  paddingright?: string;
}
export const Overlay = styled.div<{ isvisible: string }>`
  display: ${(props) => (props.isvisible ? "block" : "none")};
  opacity: ${(props) => (props.isvisible ? "1" : "0")};

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 300vh;
  transition: opacity 500ms;

  overflow: hidden;
  z-index: 10;
`;

export const BackgroundOverlay = styled.div<DarkBg>`
  display: flex;
  width: 100%;
  background: ${(props) => props.background || "rgba(0, 0, 0, 0.4)"};
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;

export const PopupContainer = styled.div<{ color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  width: 100%;
  color: ${(props) => props.color};
  min-height: 100vh;

  h2 {
    margin-top: 0;
    color: ${(props) => props.color};
  }

  .close {
    position: absolute;
    top: 20px;
    &:dir(ltr) {
      right: 30px;
    }
    &:dir(rtl) {
      left: 30px;
    }
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: ${(props) => props.color};

    &:hover {
      cursor: pointer;
      color: #000;
    }
  }

  .content {
    margin-top: 3.5%;
    overflow: auto;
    margin-bottom: 100px;
  }

  @media screen and (min-width: 700px) {
    width: 80%;
  }
`;

export const PopupContentContainer = styled.div<{
  color?: string;
  bgcolor?: string;
  width?: string;
  minwidth?: string;
}>`
  background-color: ${(props) => props.bgcolor || "#384450"};
  width: ${(props) => props.width || "fit-content"} !important ;
  min-width: ${(props) => props.minwidth} !important ;

  &:dir(ltr) {
    border-radius: 0rem 0.625rem 0.625rem 0rem;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: -0.1875rem;
      width: 0.375rem;
      background: #5dd3b3;
      border-radius: 1.25rem;
      z-index: 99;
    }
  }
  &:dir(rtl) {
    border-radius: 0.625rem 0 0 0.625rem;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: -0.1875rem;
      width: 0.375rem;
      background: #5dd3b3;
      border-radius: 1.25rem;
      z-index: 99;
    }
  }
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: center;
  padding-bottom: 1.88rem;
  /* background-color: #5dd3b3; */
`;

export const IconsContainer = styled.div<DarkBg>`
  &:dir(ltr) {
    padding-right: ${(props) => props.paddingright};
  }
  &:dir(rtl) {
    padding-left: ${(props) => props.paddingright};
  }
  display: flex;
  justify-content: space-between;
  padding: ${(props) => props.iconcontainer || "1.25rem"};
  align-items: flex-end;
  /* background: black; */
  margin-top: 1rem;
  position: relative;
  padding: 0 1.25rem 1.25rem 1.25rem;
  /* padding-right: 0.5rem; */
  /* background-color: red; */
`;
export const CloseIconContainer = styled.div<StyleThemeInterface>`
  cursor: pointer;
  position: absolute;
  z-index: 2;
  &:dir(ltr) {
    right: 1.2rem;
  }
  &:dir(rtl) {
    left: 1.2rem;
  }

  top: 0;
  opacity: ${(props) => (props.theme === "light" ? "0.9" : "1")};
`;

export const ContentScrolled = styled.div<{
  height?: string;
  theme?: string;
  paddingBottom?: string;
}>`
  display: flex;
  height: ${(props) => props.height || "fit-content"};
  /* background:yellow; */
  /* position:fixed; */
  position: relative;
  /* padding: 0 1.25rem 1.25rem 1.5rem; */
  padding-bottom: ${(props) => props.paddingBottom};
  width: 100%;
  /* background:yellow; */
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)"
        : "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)"};
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    /* background: ${(props) =>
      props.theme === "light" ? "#F7F7F8" : "#2d3741"}  ; */
    background: transparent;

    border-radius: 0.625rem;
  }
`;
export const HorizantalLine = styled.div<{
  showline: string;
  lineiconcolor?: string;
}>`
  display: ${(props) => (props.showline === "false" ? "none" : "")};
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.lineiconcolor};
  /* opacity: 0.2; */
  margin-top: 1.5rem;
`;

export const StringInfo = styled.div<{ gaps?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gaps};
`;

export const PopupTitle = styled.div`
  color: white;
  font-family: "Core Rhino 65 Bold", sans-serif;
  font-size: 16px;
`;

export const IconAndTitleContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-end;

  gap: 10px;
  /* margin-top: -0.6rem; */
`;

export const ButtonContainer = styled.div<{ justifycontent: string }>`
  display: flex;
  justify-content: ${(props) => props.justifycontent || "center"};
  align-items: center;
  gap: 0.62rem;
  /* background-color:aqua; */
  height: 3.2rem !important;
  /* padding-top:1rem !important; */
  padding: 1.2rem 1.25rem 0rem 1.5rem;
`;
export const IconCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
