import styled from "styled-components";

export const DescriptionMainWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
`;
export const DescriptionMainWrapperRenderText = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;
export const DescriptionWrapper = styled.div`
  margin-top: 0.62rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  &:nth-child(odd) {
    opacity: 0.8;
  }
`;

export const HtmlContentWrapper = styled.div`
  /* font-family: Core Rhino 35 Light; */
  /* font-size: 0.875rem; */
  line-height: 1.375rem;
`;
