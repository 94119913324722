import React, { FC } from "react";
import {
  MenuContainer,
  MenuElementContainer,
  MenuItem,
  DropdownMenu,
} from "./FilterStatus.style";
import { FilterPriorityProps } from "./FilterPriorityInterface";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

export const FitlterPriority: FC<FilterPriorityProps> = ({
  menuOpen,
  filterTasksByPriority,
  selectedPriority,
}) => {
  const { t } = useTranslation();
  const handleClick = (priority: string) => {
    if (selectedPriority !== priority) {
      // Only set the selected priority when it's different from the currently selected one
      filterTasksByPriority(priority);
    }
  };
  const { bg_selectColor } = useRecoilValue(ColorSelector);
  return (
    <MenuContainer>
      <DropdownMenu
        style={{ background: bg_selectColor }}
        isopen={menuOpen.toString()}
      >
        <MenuElementContainer index={1} onClick={() => handleClick("high")}>
          <MenuItem>{t("to do list.High")}</MenuItem>
        </MenuElementContainer>
        <MenuElementContainer index={2} onClick={() => handleClick(" medium")}>
          <MenuItem>{t("to do list.Medium")}</MenuItem>
        </MenuElementContainer>
        <MenuElementContainer index={3} onClick={() => handleClick(" low")}>
          <MenuItem>{t("to do list.Low")}</MenuItem>
        </MenuElementContainer>
      </DropdownMenu>
    </MenuContainer>
  );
};
