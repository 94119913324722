import React, { FC, useEffect, useState } from "react";
import {
  QuestionMain,
  QuestionCommentContainer,
  QuestionName,
  OverContainer,
  HeaderQuestionContainer,
  GradeInput,
  QuestionAnswer,
  CommentSection,
  SaveButtonContainer,
  Rubric,
} from "./QuestionComment.style";
import { QuestionCommentProps } from "./QuestionCommentInterface";
import {
  TypographyRegularDescription,
  Typography,
  LightTypography,
} from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import { useMyContext } from "../../../contexts/quizGraderContext";
import DOMPurify from "dompurify";
import { SvgLogoFour } from "../../../elements/Icons";
import { SubmitButton } from "../../GraderComments/main/Comments.style";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../../utils/StringManipulation";
import { put } from "../../../utils/AxiosRequests";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { FlexDiv } from "../../../globalStyles";
import RatingSection from "./RatingSection/RatingSection";
import { adminState } from "../../../recoil/RecoilStates";
const QuestionComment: FC<QuestionCommentProps> = ({
  questions,
  questionIndex,
  submission,
  studentIndex,
  handleQuestionIndexChange,
  fetchData,
  setShowRubric,
  showRating,
  selectedRubricCell,
  setShowRating,
}) => {
  const { t } = useTranslation();
  const [isAdmin] = useRecoilState(adminState)
  // Find the answer of the student and return it.
  const findStudentAnswer = (questionId: number, questionType: any) => {
    if (
      submission &&
      submission.length > 0 &&
      submission[studentIndex].lastAnswersOfStudent &&
      submission[studentIndex].lastAnswersOfStudent.length > 0 &&
      questions &&
      questions.length > 0
    ) {
      const studentAnswer = submission[studentIndex].lastAnswersOfStudent.find(
        (answer: any) =>
          answer.questionId.toString() === questionId.toString() &&
          answer.type === questionType
      );
      return studentAnswer
        ? studentAnswer.answers[0].answer.answer
        : "No Answer";
    } else {
      return "No Answer";
    }
  };

  // Find the grade of the student on the question and return it.
  const findStudentPoint = (questionId: number, questionType: any) => {
    if (
      submission &&
      submission.length > 0 &&
      submission[studentIndex].lastAnswersOfStudent &&
      submission[studentIndex].lastAnswersOfStudent.length > 0 &&
      questions &&
      questions.length > 0
    ) {
      const studentAnswer = submission[studentIndex].lastAnswersOfStudent.find(
        (answer: any) =>
          answer.questionId.toString() === questionId.toString() &&
          answer.type === questionType
      );
      if (
        questionType === "ESSAY" &&
        studentAnswer &&
        studentAnswer.grade === 0
      ) {
        setQuizGraded(false);
        return "";
      } else if (
        questionType !== "ESSAY" &&
        studentAnswer &&
        studentAnswer.comment &&
        studentAnswer.comment !== "" &&
        studentAnswer.comment !== "pending"
      ) {
        setQuizGraded(false);
      } else if (!studentAnswer) {
        setQuizGraded(true);
        return "";
      } else {
        setQuizGraded(false);
      }
      return studentAnswer ? studentAnswer.grade : 0;
    } else {
      setQuizGraded(true);
      return 0;
    }
  };

  // Find the comment of the student on the question and return it.
  const findStudentComment = (questionId: number, questionType: any) => {
    if (
      submission &&
      submission.length > 0 &&
      submission[studentIndex].lastAnswersOfStudent &&
      submission[studentIndex].lastAnswersOfStudent.length > 0 &&
      questions &&
      questions.length > 0
    ) {
      const studentAnswers = submission[studentIndex].lastAnswersOfStudent.find(
        (answer: any) =>
          answer.questionId.toString() === questionId.toString() &&
          answer.type === questionType
      );
      // Check if there are both correct and incorrect answers
      if (
        studentAnswers &&
        studentAnswers.comment &&
        studentAnswers.comment !== "" &&
        studentAnswers.comment !== "pending" &&
        studentAnswers.comment !== undefined &&
        studentAnswers.comment !== null
      ) {
        return studentAnswers.comment;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  // Find the answer id of the answer.
  const findAnswerId = (questionId: number, questionType: any) => {
    if (
      submission &&
      submission.length > 0 &&
      submission[studentIndex].lastAnswersOfStudent &&
      submission[studentIndex].lastAnswersOfStudent.length > 0 &&
      questions &&
      questions.length > 0
    ) {
      const studentAnswer = submission[studentIndex].lastAnswersOfStudent.find(
        (answer: any) =>
          answer.questionId.toString() === questionId.toString() &&
          answer.type === questionType
      );
      return studentAnswer ? studentAnswer.answerId : 0;
    } else {
      return 0;
    }
  };

  // Find the submitted Rubric Data for the question.
  const findSubmittedRibricData = (questionId: number, questionType: any) => {
    if (
      submission &&
      submission.length > 0 &&
      submission[studentIndex].lastAnswersOfStudent &&
      submission[studentIndex].lastAnswersOfStudent.length > 0 &&
      questions &&
      questions.length > 0
    ) {
      const studentAnswers = submission[studentIndex].lastAnswersOfStudent.find(
        (answer: any) =>
          answer.questionId.toString() === questionId.toString() &&
          answer.type === questionType
      );
      // Check if there are both correct and incorrect answers
      if (
        studentAnswers &&
        studentAnswers.rubricData &&
        studentAnswers.rubricData.length > 0
      ) {
        return studentAnswers.rubricData;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const [questionId, setquestionId] = useState<any>("");
  const [questionType, setquestionType] = useState<any>("");
  const [questionComment, setQuestionComment] = useState<string>("");
  const [questionGrade, setQuestionGrade] = useState<number>(0);
  const [answerId, setAnswerId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [quizGraded, setQuizGraded] = useState<boolean>(false);
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const [usingRubric, setUsingRubric] = useState<boolean>(false);
  const { themeTextColor, inputBorderColor } = useRecoilValue(ColorSelector);
  // Update the current questionId and questionType depending on the dependencies
  useEffect(() => {
    if (
      questions &&
      questions.length > 0 &&
      submission &&
      submission.length > 0
    ) {
      setquestionId(
        questions[questionIndex].questionId
          ? questions[questionIndex].questionId.toString()
          : questions[questionIndex].id.toString()
      );
      setquestionType(questions[questionIndex].type);
    }
  }, [submission, studentIndex, questions, questionIndex]);

  // Set the grade on question
  const handleGradeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    answerId: number
  ) => {
    const gradeValue = parseInt(e.target.value, 10);
    setQuestionGrade(gradeValue);
    setAnswerId(answerId);
  };

  // Set the comment on the question
  const handleCommentChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    answerId: number
  ) => {
    const commentValue = e.target.value;
    setQuestionComment(commentValue);
    setAnswerId(answerId);
  };

  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };

  // If the grade that the instructor put it greater than the max grade of the question, it will updated it on Blur event.
  const handleBlur = () => {
    if (
      questions &&
      questions.length > 0 &&
      questions[questionIndex] &&
      questions[questionIndex].points
    ) {
      const maxGrade = questions[questionIndex].points;
      if (
        questionGrade !== undefined &&
        maxGrade !== undefined &&
        questionGrade > maxGrade
      ) {
        setQuestionGrade(maxGrade);
      }
    }
  };

  useEffect(() => {
    setQuestionComment("");
    setQuestionGrade(0);
    setShowRating && setShowRating(false);
  }, [questions, questionIndex]);

  // Set the founded grade and comment for the student on the question
  useEffect(() => {
    setQuestionComment("");
    setQuestionGrade(0);
    setQuestionComment(findStudentComment(questionId, questionType));
    setQuestionGrade(findStudentPoint(questionId, questionType));
    handleQuestionIndexChange && handleQuestionIndexChange(questionIndex);
    setAnswerId(findAnswerId(questionId, questionType))
  }, [questions, questionIndex, studentIndex]);

  // Handle submit Button
  const handlSubmit = async () => {
    if (questionType === "ESSAY") {
      //COMMENT FOR THE ESSAY QUESTION WITH GRADE
      setLoading(true);
      const data = {
        answerId: answerId,
        comment: questionComment,
        grade: questionGrade,
        subId: subIdSplitted,
        provider: provider,
        rubricGrade:
          selectedRubricCell && selectedRubricCell?.length > 0
            ? selectedRubricCell
            : findSubmittedRibricData(questionId, questionType).length > 0 ?
              findSubmittedRibricData(questionId, questionType) : '',
        quizTemplateQuestionId: questions[questionIndex].quizTemplateQuestionId,
      };
      try {
        const response = await put(
          `quiz/update/answer/feedback/${tenantName}`,
          data
        );
        fetchData && fetchData();
        setLoading(false);
        setQuizGraded(true);
      } catch (err) {
        console.log(err);
      }
    } else {
      //COMMENT FOR THE OTHER TYPES OF QUESTIONS WITHOUT GRADE
      setLoading(true);
      const data = {
        answerId: answerId,
        comment: questionComment,
        subId: subIdSplitted,
        provider: provider,
      };
      try {
        const response = await put(
          `quiz/update/answer/feedback/${tenantName}`,
          data
        );
        fetchData && fetchData();
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchData && fetchData();
  }, []);

  const numberPart =
    questions &&
    questions.length > 0 &&
    questions[questionIndex].title.split(" ")[1];

  const handleRubric = () => {
    setShowRubric && setShowRubric(true);
  };

  useEffect(() => {
    if (findSubmittedRibricData(questionId, questionType).length > 0) {
      setShowRating && setShowRating(true);
    } else {
      setShowRating && setShowRating(false);
    }

    if (
      questions &&
      questions.length > 0 &&
      questions[questionIndex].rubricId &&
      questions[questionIndex].rubricId !== null &&
      questions[questionIndex].rubricId !== 0 &&
      questions[questionIndex].rubricId !== ""
    ) {
      setUsingRubric(true);
    } else {
      setUsingRubric(false);
    }
  }, [questions, questionIndex]);

  return (
    <QuestionMain>
      <QuestionCommentContainer bordercolor={inputBorderColor}>
        <HeaderQuestionContainer>
          <QuestionName>
            <TypographyRegularDescription
              text={`${t("quiz.Question")} ${numberPart}`}
              // color={theme.dark.primary_text_color}
              fontSize="0.875rem"
              lineHeight="1.375rem"
            />
            {/* <Typography
              text={
                (questions &&
                  questions.length > 0 &&
                  questions[questionIndex].question) ||
                ""
              }
              fontSize="1rem"
            /> */}
          </QuestionName>
          {/* {questions && questions.length > 0 && questions[questionIndex].type && questions[questionIndex].type === "ESSAY" && findStudentPoint(questionId,questionType) === 0 ? ( */}
          <FlexDiv gap="1.25rem">
            {usingRubric ? (
              <Rubric onClick={handleRubric}>{t("forum.Open Rubric")}</Rubric>
            ) : (
              ""
            )}
            <OverContainer>
              <GradeInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleGradeChange(e, findAnswerId(questionId, questionType))
                }
                type="number"
                max={
                  questions &&
                  questions.length > 0 &&
                  questions[questionIndex] &&
                  questions[questionIndex].points
                }
                onBlur={handleBlur}
                value={questionGrade}
                color={themeTextColor}
                disabled={isAdmin === false ?
                  (questionType !== "ESSAY" || usingRubric ? true : false) : true
                }
              />
              <LightTypography
                text={`/${questions &&
                  questions.length > 0 &&
                  questions[questionIndex] &&
                  questions[questionIndex].points
                  }`}
                // color={theme.dark.primary_text_color}
                fontSize="0.75rem"
                lineHeight="1.375rem"
              />
            </OverContainer>
          </FlexDiv>
          {/* ) : (
                    <OverContainer>
                    <GradeInput value={findStudentPoint(questionId,questionType)} disabled={true} type='number'/>
                 <LightTypography text={`/${questions && questions.length > 0 && questions[questionIndex] && questions[questionIndex].points}`} color={theme.dark.primary_text_color} fontSize='0.75rem' lineHeight='1.375rem'/>
                </OverContainer>
                )} */}
        </HeaderQuestionContainer>
        {showRating ? (
          <RatingSection
            selectedRubricCell={
              findSubmittedRibricData(questionId, questionType).length > 0
                ? findSubmittedRibricData(questionId, questionType)
                : selectedRubricCell || []
            }
          />
        ) : (
          ""
        )}
        <p
          style={{
            wordBreak: "break-all",
            fontSize: "1rem",
            fontFamily: "Primary Font-Bold",
          }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              decodeHtml(
                (questions &&
                  questions.length > 0 &&
                  questions[questionIndex].question) ||
                ""
              )
            ),
          }}
        ></p>
        <QuestionAnswer>
          {/* <LightTypography text={'Answer Goes Here'} color={theme.dark.primary_text_color} fontSize='0.875rem' lineHeight='1.375rem'/> */}
          {questions &&
            questions.length > 0 &&
            questions[questionIndex] &&
            questions[questionIndex].type === "ESSAY" && (
              <p
                style={{
                  wordBreak: "break-all",
                  fontFamily: "Primary Font-Light",
                  fontSize: "0.875rem",
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    decodeHtml(findStudentAnswer(questionId, questionType))
                  ),
                }}
              ></p>
            )}
        </QuestionAnswer>

        <CommentSection
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleCommentChange(e, findAnswerId(questionId, questionType))
          }
          placeholder={t("grader.Write your comment then click Save")}
          value={questionComment}
          color={themeTextColor}
          disabled={isAdmin === true ? true : false}
        />
        {isAdmin === false ?
          <SaveButtonContainer>
            {quizGraded && !loading ? (
              <SubmitButton style={{ opacity: 0.7, cursor: "default" }} disabled>
                {t("assignment.No Answer")}
              </SubmitButton>
            ) : !quizGraded && !loading ? (
              <SubmitButton onClick={() => handlSubmit()}>
                {t("survey.Save")}
              </SubmitButton>
            ) : loading ? (
              <SubmitButton style={{ opacity: 0.7, cursor: "default" }} disabled>
                {`${t("general.loading")}...`}
              </SubmitButton>
            ) : (
              ""
            )}
          </SaveButtonContainer> :
          ""
        }
      </QuestionCommentContainer>
    </QuestionMain>
  );
};

export default QuestionComment;
