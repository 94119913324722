import React, { useEffect, useState } from "react";
import { AnalyticsAssignmentResponseTrackingInterface } from "../../AnalyticsAssigmentResponseTraking/main/AnalyticsAssignmentResponseTracking";
import { StudentFinalGradeData } from "../../Tables/QuizQuestionsAnalytics/QuizFinalGradeData";
import { FlexAppContainer } from "../../../App.style";
import { Typography } from "../../../elements/fonts/Fonts";
import { AnalyticsPieChartContainer } from "../../../Views/Analytics/AnalyticsPageStyles";
import PieWithText from "../../Charts/PieWithText/PieWithText";
import PieText from "../../Charts/PieText/PieText";
import ProgressBarChart from "../../Charts/ProgressBarChart/ProgressBarChart";

const AnalyticQuizHeaderPieandProgressBar = ({
  onClick,
}: AnalyticsAssignmentResponseTrackingInterface) => {
  const [PieChartData, setPieChartData] = useState([
    {
      text: "Attempted",
      attendance: 0,
      bgColor: "#5DD3B3",
    },
    {
      text: "Unattempted",
      attendance: 0,
      bgColor: "#0B8FFF",
    },
  ]);

  useEffect(() => {
    const attemptedCount = StudentFinalGradeData.Students?.filter((item) =>
      item.Questions?.every(
        (question) =>
          question.StudentAnswer !== null && question.StudentAnswer !== ""
      )
    ).length;

    const unattemptedCount = StudentFinalGradeData.Students?.filter((item) =>
      item.Questions?.some(
        (question) =>
          question.StudentAnswer === null || question.StudentAnswer === ""
      )
    ).length;

    setPieChartData([
      {
        text: "Attempted",
        attendance: attemptedCount,
        bgColor: "#5DD3B3",
      },
      {
        text: "Unattempted",
        attendance: unattemptedCount,
        bgColor: "#0B8FFF",
      },
    ]);
  }, []);
  const StudentsNumber = StudentFinalGradeData.Students.length;
  return (
    <FlexAppContainer column={true} gap="1.25rem">
      <div style={{ cursor: "pointer" }} onClick={onClick}>
        <Typography text="Submission Tracking" fontSize="1.5rem" />
      </div>
      <FlexAppContainer gap="1.25rem" wrap={true}>
        <AnalyticsPieChartContainer style={{ height: "22rem" }}>
          {/* PieWithText Component */}
          <PieWithText
            data={PieChartData}
            value={"attendance"}
            getColor={(entry) => entry.bgColor as string}
            Width="60%"
            NormalTextDisplay={false}
          />

          {/* PieText Component */}
          <PieText LoopName={PieChartData} />
        </AnalyticsPieChartContainer>

        <AnalyticsPieChartContainer style={{ height: "22rem" }}>
          {/* ProgressBarChart Component */}
          <ProgressBarChart data={PieChartData} MaxNumber={StudentsNumber} />
        </AnalyticsPieChartContainer>
      </FlexAppContainer>
    </FlexAppContainer>
  );
};

export default AnalyticQuizHeaderPieandProgressBar;
