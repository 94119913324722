import styled from "styled-components";

export const BackArrow = styled.div`
display: flex;
justify-content: center;
align-items: center;
  &:dir(ltr) {
    transform: rotate(0deg);
  }
  &:dir(rtl) {
    transform: rotate(180deg);
  }
`;
