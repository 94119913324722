import { GroupStudent } from "../groupCardNew/groupCardNewInterface";
export const AllGroupMembersData: GroupStudent[] = [
  // {
  //   profilePicture: require("../../assets/KhaledInstructor.jpg"),
  //   username: "Ali Mans",
  //   studentId: 1,
  //   index: 0,
  //   groupId: -1,
  //   id: 1,
  // },
  // {
  //   profilePicture: require("../../assets/NourInstructor.jpg"),
  //   username: "Ali Mans",
  //   studentId: 2,
  //   index: 1,
  //   groupId: -1,
  //   id: 2,
  // },
  // {
  //   profilePicture: require("../../assets/AliAhmad.png"),
  //   username: "Ali Mans",
  //   studentId: 3,
  //   index: 2,
  //   groupId: -1,
  // },
  // {
  //   profilePicture: require("../../assets/ayaInstructor2.jpg"),
  //   username: "Ali Mans",
  //   studentId: 4,
  //   index: 3,
  //   groupId: -1,
  // },
  // {
  //   // profilePicture: require("../../assets/ayaInstructor2.jpg"),
  //   username: "Ali Mans",
  //   studentId: 5,
  //   index: 4,
  //   groupId: -1,
  // },
  // {
  //   profilePicture: require("../../assets/KhaledInstructor.jpg"),
  //   username: "Ali Mans",
  //   studentId: 6,
  //   groupId: -1,
  // },
  // {
  //   profilePicture: require("../../assets/KhaledInstructor.jpg"),
  //   username: "Ali Mans",
  //   studentId: 7,
  //   groupId: -1,
  // },
  // {
  //   profilePicture: require("../../assets/KhaledInstructor.jpg"),
  //   firstName: `Mohammad`,
  //   lastName: "Fawaz",
  //   studentId: 8,
  //   groupId: -1,
  // },
  // {
  //   profilePicture: require("../../assets/KhaledInstructor.jpg"),
  //   firstName: `Hadi`,
  //   lastName: "Shouman",
  //   studentId: 9,
  //   groupId: -1,
  // },
  // {
  //   profilePicture: require("../../assets/KhaledInstructor.jpg"),
  //   firstName: `Khaled`,
  //   lastName: "Khaled",
  //   studentId: 10,
  //   groupId: -1,
  // },
  // {
  //   profilePicture: require("../../assets/KhaledInstructor.jpg"),
  //   firstName: `Khaled`,
  //   lastName: "Khaled",
  //   studentId: 11,
  //   groupId: -1,
  // },
  // {
  //   profilePicture: require("../../assets/KhaledInstructor.jpg"),
  //   firstName: `Khaled`,
  //   lastName: "Khaled",
  //   studentId: 12,
  //   groupId: -1,
  // },
  // {
  //   profilePicture: require("../../assets/KhaledInstructor.jpg"),
  //   firstName: `Khaled`,
  //   lastName: "Khaled",
  //   studentId: 13,
  //   groupId: -1,
  // },
];
