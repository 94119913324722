import styled from "styled-components";

interface CreditNumbersMainHolderProps {
  bgbordercolor: string;
}
export const CreditNumbersMainHolder = styled.div<CreditNumbersMainHolderProps>`
  position: relative;
  /* width: 3.40681rem; */
  width: 3.438rem;
  /* height: 3.40681rem; */
  height: 3.438rem;
  background: ${(props) => props.bgbordercolor};
  border-radius: 50%;
`;
export const CreditNumbersHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 93%;
  height: 93%;
  border-radius: 50%;
  background-color: #5dd3b3;
  z-index: 2;
  position: absolute;
  left: 0.118rem;
  top: 0.122rem;
`;
export const CreditNumbersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.3rem;
`;

export const NumbersWrapper = styled.span`
  color: #fff;
`;

export const CreditsWrapper = styled.span``;
