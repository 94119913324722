import React, { FC } from "react";
import { QuizContainer } from "./AssignmentQuiz.style";
import QuestionNumber from "../QuestionNumber/QuestionNumber";
import { AssignmentQuizProps } from "../AssignmentQuizInterface";
import SubmittedQuestions from "../SubmittedQuestions/SubmittedQuestions";
import QuestionComment from "../QuestionComment/QuestionComment";
import AnswerCorrection from "../AnswerCorrection/AnswerCorrection";
const AssignmentQuiz: FC<AssignmentQuizProps> = ({
  questions,
  questionIndex,
  setQuestionIndex,
  submission,
  studentIndex,
  handleQuestionIndexChange,
  fetchData,
  setShowRubric,
  showRating,
  selectedRubricCell,
  setShowRating,
}) => {
  return (
    <QuizContainer>
      <QuestionNumber
        questions={questions}
        setQuestionIndex={setQuestionIndex}
      />
      <SubmittedQuestions
        studentIndex={studentIndex}
        submission={submission}
        questions={questions}
        questionIndex={questionIndex}
      />
      <QuestionComment
        fetchData={fetchData}
        handleQuestionIndexChange={handleQuestionIndexChange}
        studentIndex={studentIndex}
        submission={submission}
        questions={questions}
        questionIndex={questionIndex}
        setShowRubric={setShowRubric}
        selectedRubricCell={selectedRubricCell}
        showRating={showRating}
        setShowRating={setShowRating}
      />
      <div style={{ padding: "1.25rem" }}>
        <AnswerCorrection
          studentIndex={studentIndex}
          submission={submission}
          questions={questions}
          questionIndex={questionIndex}
        />
      </div>
    </QuizContainer>
  );
};

export default AssignmentQuiz;
