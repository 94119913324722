import React, { useState } from "react";
import SearchWithFilter from "../../elements/searchWithFilter/SearchWithFilter";
import {
  FilterMaps,
  FilterMapsLine,
  PageLayout,
  RadioLabel,
  SearchTab,
  SeletectOptinToogle,
} from "./ClassInProgressFilter.style";
import { useMyContext } from "../../contexts/QuestionBanksContext";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { t } from "i18next";
import { LightTypography } from "../../elements/fonts/Fonts";

interface ClassInProgressFilterInterface {
  selectedClassFilter: string;
}
const ClassInProgressFilter = ({ selectedClassFilter, }: ClassInProgressFilterInterface) => {
  const {
    academicSessionTitles,
    courseTitles,
    classTitles,
    setselectedAcademicSession,
    setselectedCourse,
    setselectedClass,
    selectedAcademicSession,
    selectedCourse,
    selectedClass,
  } = useMyContext();
  const { RadioButtonGradientColor } = useRecoilValue(ColorSelector);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const dataToMap =
    selectedClassFilter === "Academic Session"
      ? academicSessionTitles
      : selectedClassFilter === "Course"
      ? courseTitles
      : classTitles;

  const handleSelectUniqueFilter = (option: any) => {
    selectedClassFilter === "Academic Session"
      ? setselectedAcademicSession(option)
      : selectedClassFilter === "Course"
      ? setselectedCourse(option)
      : setselectedClass(option);
  };

  const filteredData = dataToMap.filter((data) =>
    data.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <PageLayout>
      <SearchTab>
        <SearchWithFilter
          withFilter={false}
          placeholder={t("dashboard.Search")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchTab>

      <FilterMaps>
        {filteredData.length === 0 ? (
          <LightTypography text={t("gradebook.no result found")} />
        ) : (
          <>
            {filteredData.map((data, index) => (
              <FilterMapsLine key={index}>
                <SeletectOptinToogle
                  type="radio"
                  id={data}
                  value={data}
                  name="option"
                  onChange={() => handleSelectUniqueFilter(data)}
                  checked={
                    selectedAcademicSession === data ||
                    selectedCourse === data ||
                    selectedClass === data
                  }
                />

                <RadioLabel bgcolor={RadioButtonGradientColor} htmlFor={data}>
                  {data}
                </RadioLabel>
              </FilterMapsLine>
            ))}
          </>
        )}
      </FilterMaps>
    </PageLayout>
  );
};

export default ClassInProgressFilter;
