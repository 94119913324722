import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const RubricPageeLayout = styled.div`
  display: flex;
  flex: 100%;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 3rem;
`;
export const RubricTableLayout = styled.div<StyleThemeInterface>`
  display: flex;
  flex: 100%;
  flex-direction: column;
  /* gap:0.5rem; */
  align-content: flex-start;
  border-radius: 1.25rem;

background: ${props =>props.bgcolor};
box-shadow: ${props =>props.boxshadow};
`;

export const RubricTileLayout = styled.div`
  display: flex;
  flex: 95%;
  flex-direction: column;
  gap: 0.5rem;
  align-content: flex-start;
  padding: 1rem;
`;
export const RubricTableLayoutRow = styled.div<StyleThemeInterface>`
  flex: 100%;
  display: flex;
  gap: 3rem;
  padding: 1.25rem;

  &:nth-child(even) {
    background: ${props =>props.nth_child_bgcolor};

  }
  &:first-child {
    background: ${props =>props.first_child_bgcolor};

  }

  &:last-child {
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
  }
`;

export const RubricTableLayoutColumn = styled.div`
  flex: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  &:dir(ltr) {
    padding-right: 0.5rem;
  }
  &:dir(ltr) {
    padding-left: 0.5rem;
  }

  /* min-height:5rem !important; */
  /* background-color:black; */
`;

export const ShapeDiv = styled.div`
  display: flex;
  /* background-color:aqua; */
  flex-direction: column;
  gap: 0.6rem;
  flex: 100%;
  /* width:5rem; */
`;

export const OneRowShapeDiv = styled.div`
  display: flex;
  /* background-color:yellow; */
  /* flex:1; */
  /* width:5rem; */
  height: 2rem;
  /* margin-top:0.5rem;
margin-left:0.5rem; */
  align-items: center;
  justify-content: center;
`;

export const MiniCellForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding:0.2rem; */
  gap: 0.6rem;
`;

export const MiniCellTextInput = styled.input<StyleThemeInterface>`
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.313rem;
  border: 1px solid #576371;
  height: 2rem;
  background-color: transparent;
  color: ${(props) => props.color};
  font-family: "Primary Font-Light";
  &:focus {
    outline: none;
    caret-color: ${(props) => props.color};
  }
  &::placeholder {
    color: ${(props) => props.color};
    opacity: 0.5;
  }
`;
export const MiniCellTextArea = styled.textarea<StyleThemeInterface>`
  width: 100%;

  resize: none;
  padding: 0.5rem;
  border-radius: 0.313rem;
  border: 1px solid #576371;
  min-height: 3.3125rem;
  background-color: transparent;
  color: ${(props) => props.color};
  font-family: "Primary Font-Light";
  &:focus {
    outline: none;
    caret-color: ${(props) => props.color};
  }
  &::placeholder {
    color: ${(props) => props.color};
    opacity: 0.5;
  }
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) =>
      props.themetextcolor === "#fff"
        ? "linear-gradient(180deg, #5DD3B3 0%, #2D3741 100%)"
        : "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)"};
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background-color: none;
    border-radius: 0.625rem;
  }
`;

export const MiniWeightCellForm = styled.div`
  display: flex;
  width: 100%;
  padding: 0.2rem;
  gap: 0.6rem;
  justify-content: space-between;
  align-items: center;
`;

export const WeightDiv = styled.div`
  width: 60%;
  padding: 0.5rem;
  border-radius: 0.313rem;
  border: 1px solid #576371;
  height: 1.6rem;
  background-color: transparent;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:dir(ltr) {
    padding-right: 0.5rem;
  }
  &:dir(ltr) {
    padding-left: 0.5rem;
  }
`;
export const WeightDivDeree = styled.div<StyleThemeInterface>`
  color: ${(props) => props.color};
  opacity: 0.5;
`;

export const WeightTextInput = styled.input<StyleThemeInterface>`
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.313rem;
  border: none;
  height: 1.6rem;
  background-color: transparent;
  /* background-color:red; */
  &:dir(ltr) {
    margin-left: -0.5rem;
  }
  &:dir(ltr) {
    margin-right: -0.5rem;
  }

  color: ${(props) => props.color};
  font-family: "Primary Font-Light";
  &:focus {
    outline: none;
    caret-color: ${(props) => props.color};
  }
  &::placeholder {
    color: ${(props) => props.color};
    opacity: 0.5;
  }
`;

export const RubricButtonsLayout = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
`;
export const MinErroreSum = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;
