import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { GroupStudent } from "../groupCardNew/groupCardNewInterface";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReusableSubmitButton from "../../elements/reusableSubmitButton/ReusableSubmitButton";
import LoaderCircle from "../loader/loaderCircle/LoaderCircle";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  courseCreatorColorSelector,
} from "../../recoil/ThemeSelectors";

import {
  MainHolder,
  DragDroppableGroupsWrapper,
  AddAndRandomizeWrapper,
  Tooltip,
  IconWrapper,
  GroupsHolder,
  LogoLabelEmptyGroups,
  SketchPickerHolder,
  SketchPickerClose,
  SketchPickerSave,
  PublishCancelWrapper,
  Overlay,
} from "./DroppableGroupsArea.style";
import {
  SvgAdd,
  SvgShuffle,
  SvgEllipse,
  SvgWarning,
  SvgClose,
  SvgCheckModuleTopic,
  SvgDeleteAll,
  SvgPublish,
} from "../../elements/Icons";
import PopupDelete from "../../elements/PopupDelete/PopupDelete";
import {
  MediumTypography,
  Typography,
  LightTypography,
} from "../../elements/fonts/Fonts";
import useToggle from "../../hooks/useToggle";
import useClickOutside from "../../hooks/useClickOutside";

import ColorPickerMenuNew from "../../elements/colorPickerMenu/ColorPickerMenu";
import { useStateContext } from "../../contexts/ContextProvider";

import GroupCardNew from "../groupCardNew/GroupCardNew";
import { GroupCardDataInterface } from "../groupCardNew/groupCardNewInterface";

import { AllGroupMembersData } from "../allMembersGroups/allGroupMemberBarData";
import ReusablePopUp from "../../elements/reusablePopUp/ReusablePopUp";
import { breakpoints } from "../../theme";
import { useTranslation } from "react-i18next";
import { toArabicDigits, toArabicLetter } from "../../utils/formatDayArabic";
const DroppableGroupsArea = () => {
  const {
    isChangeColorShown,
    setIsChangeColorShown,
    setColorGroup,
    colorGroup,
    subGroupNum,
    setSubGroupNum,
    studentPerSubGroup,
    setStudentPerSubGroup,
    schemes,
    setSchemes,
    groups,
    setGroups,
    ungrouped_students,
    setUngrouped_students,
    student_ID,
    student__ID,
    setIsPopUpOpen,

  } = useStateContext();

  const {
    tooltipBgColor,
    tooltipTextColor,
    boxShadow,
    moduleDragNDropBgColor,
    borderModulesContainerColor,
    theme,
  } = useRecoilValue(courseCreatorColorSelector);

  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const newClassId = location.state.classId
  console.log("from droppable", newClassId)

  const [isLoadingUngroupedStudents, setIsLoadingUngroupedStudents] =
    useState<boolean>(true);
  const [isLoadingCreatedGroups, setIsLoadingCreatedGroups] =
    useState<boolean>(true);

  const BaseURL = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";

  const getUngroupedStudents = async () => {
    try {
      setIsLoadingUngroupedStudents(true);
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `${BaseURL}/groups/get/all/users/${classId}/${schemeId}/${tenantName}`
      );
      setIsLoadingUngroupedStudents(false);
      setUngrouped_students(res.data.data);
      const toReturn: any[] = res.data.data;
      return toReturn;
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   const schemeDetected = schemes.find(
  //     (scheme) => scheme.groupSchemaId == schemeId
  //   );
  //   console.log("detectedScheme: ", schemeDetected);
  // }, []);

  const getCreatedGroups = async (schemaGroupId: string | number) => {
    try {
      setIsLoadingCreatedGroups(true);
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `${BaseURL}/groups/get/all/group/${schemaGroupId}/${tenantName}`
      );

      setIsLoadingCreatedGroups(false);
      const allStudentsUngrouped = await getUngroupedStudents();
      setGroups((prevGroups) => {
        return [
          {
            ...prevGroups[0],
            students: allStudentsUngrouped,
            groupId: -1,
            indexGroup: 0,
          },
          ...res.data.data,
        ];
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteStudentFromGroup = async (id: string | number) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.delete(
        `${BaseURL}/groups/delete/students/inGroup/${id}/${tenantName}`
      );
      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
      throw error; // Rethrow the error to be handled by the calling service
    }
  };

  const createStudentInGroup = async (path: string, body: any) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.post(`${BaseURL}/${path}/${tenantName}`, {
        tenantName: tenantName,
        data: { ...body, classSourceId: classId },
      });
      return response.data;
    } catch (error) {
      console.error("Error in POST request:", error);
    }
  };
  const updateStudentInGroup = async (path: string, body: any) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.put(`${BaseURL}/${path}/${tenantName}`, {
        data: body,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
      throw error; // Rethrow the error to be handled by the calling service
    }
  };

  const classId = params.classId;
  const schemeId = params.id;

  useEffect(() => {
    if (schemeId !== undefined) getCreatedGroups(schemeId);
  }, []);

  useEffect(() => {
    setGroups((prevGroups) => {
      return [
        {
          ...prevGroups[0],
          indexGroup: 0,
        },
        ...prevGroups.slice(1), // Keep the rest of the elements unchanged
      ];
    });
  }, []);

  const [selectedColor, setSelectedColor] = useState<string>("");
  const [newGroupTitle, setNewGroupTitle] = useState<string>(t("group.Group"));
  const { isOpen: isDropdownVisible, toggle: toggleDropdown } =
    useToggle(false);
  const dropdownRef = useClickOutside(isDropdownVisible, toggleDropdown);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const generateId = () => {
    // generate a random Id number between 1 and 10000
    return Math.floor(Math.random() * 10000) + 1;
  };

  const allStudentsInClass = ungrouped_students;
  const [showDeleteAllGroups, setShowDeleteAllGroups] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [showRandomizePopup, setShowRandomizePopup] = useState(false);

  const [isGroupId, setIsGroupId] = useState<number | string>();
  const setIsGroupIdInGroupCreator = (groupId: number | string) => {
    setIsGroupId(groupId);
  };

  // const addGroupsToScheme = (
  //   groups: GroupCardDataInterface[],
  //   ungroupedStudents: GroupStudent[],
  //   schemeId: number | string
  // ) => {
  //   const schemeFounded = schemes.find(
  //     (scheme) => scheme.groupSchemaId == schemeId
  //   );
  //   console.log("scheme found : ", schemeFounded);
  //   if (schemeFounded) {
  //     schemeFounded.groupsForScheme = groups;
  //     schemeFounded.ungroupedStudents = ungroupedStudents;
  //     setSchemes([...schemes]);
  //   }
  // };

  const createGroup = async (path: string, body: any) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.post(`${BaseURL}/${path}/${tenantName}`, {
        tenantName: tenantName,
        data: { ...body, classSourceId: classId },
      });
      return response.data;
    } catch (error) {
      console.error("Error in POST request:", error);
    }
  };

  const addNewGroup = async (borderColor: string) => {
    const groupId = generateId();
    const newSubTitle = String.fromCharCode(65 + groups.length - 1);

    const data = {
      groupId: groupId,
      title: `${t("group.Group")} ${toArabicLetter(newSubTitle)}`,
      index: groups.length,
      students: [],
      bordercolor: borderColor,
      groupSchemaId: schemeId,
    };

    await createGroup("groups/create/group", data);
    if (schemeId !== undefined) await getCreatedGroups(schemeId);
  };

  function distributeStudentsWithoutSubGroupsNum(
    allStudentsInClass: any[]
  ): any[][] {
    // Initialize an array to store the sub-groups
    const subGroups: any[][] = [];

    // Calculate the number of subgroups based on the square root of the number of students
    const subGroupNum = Math.ceil(Math.sqrt(allStudentsInClass.length));

    // Calculate the number of students per subgroup
    const studentsPerSubGroup = Math.ceil(
      allStudentsInClass.length / subGroupNum
    );

    // Distribute the students into sub-groups
    for (let i = 0; i < subGroupNum; i++) {
      subGroups.push([]); // Create a new array for each subgroup
    }

    for (let i = 0; i < allStudentsInClass.length; i++) {
      const subGroupIndex = i % subGroupNum;
      subGroups[subGroupIndex].push(allStudentsInClass[i]);
    }

    return subGroups;
  }

  function distributeStudents(
    allStudentsInClass: any[],
    subGroupNum: number
  ): any[][] {
    // Initialize an array to store the sub-groups
    const subGroups: any[][] = [];

    // Calculate the number of students per sub-group
    const studentsPerSubGroup = Math.ceil(
      allStudentsInClass.length / subGroupNum
    );

    // Distribute the students into sub-groups
    for (let i = 0; i < subGroupNum; i++) {
      subGroups.push([]); // Create a new array for each subgroup
    }

    for (let i = 0; i < allStudentsInClass.length; i++) {
      const subGroupIndex = i % subGroupNum;
      subGroups[subGroupIndex].push(allStudentsInClass[i]);
    }

    return subGroups;
  }

  // const result = distributeStudents(allStudentsInClass, 5);
  // console.log("result: ", result);

  function getRandomColor() {
    // first method
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;

    // // second method
    // // Generate a random number between 0 and 100000
    // const randomNum = Math.floor(Math.random() * 100000);

    // // Convert the decimal number to a hexadecimal string and pad with zeros
    // const colorHex = `#${randomNum.toString(16).padStart(3, "0")}`;

    // return colorHex;
  }

  const createShuffle = async (path: string, body: any) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.post(`${BaseURL}/${path}/${tenantName}`, {
        tenantName: tenantName,
        data: { ...body, classSourceId: classId },
      });

      // Assuming setGroups is a function to update groups state
      await setGroups((prevGroups: GroupCardDataInterface[]) => {
        // Clone the previous groups array
        const newGroups = [...prevGroups];
        // Check if there are any groups
        if (newGroups.length > 0) {
          // Update the students property of the first group to an empty array
          newGroups[0].students = [];
        }
        return newGroups;
      });
      if (schemeId !== undefined) await getCreatedGroups(schemeId);
      return response.data;
    } catch (error) {
      console.error("Error in POST request:", error);
    }
  };

  const randomizeGroups = async (
    subGroupNum: number,
    studentPerSubGroup?: number
  ) => {
    let updatedGroups = [...groups];
    const isNoAnyGroups: boolean = updatedGroups.length === 1;
    let randomDistributionResult: any[][] = [];
    if (subGroupNum && subGroupNum > 0) {
      randomDistributionResult = distributeStudents(
        allStudentsInClass,
        subGroupNum
      );
    }
    for (let i = 0; i < subGroupNum; i++) {
      const randomColor = getRandomColor();
      const groupId = generateId();
      const newSubTitle = String.fromCharCode(65 + updatedGroups.length - 1);

      const newGroupField = {
        groupId: groupId,
        title: `${t("group.Group")} ${newSubTitle}`,
        indexGroup: updatedGroups.length,
        groupSchemaId: schemeId,
        students: randomDistributionResult[i],
        bordercolor: randomColor,
      };

      updatedGroups.push(newGroupField);
      if (i === subGroupNum - 1) {
        setUngrouped_students([]);
        updatedGroups.forEach((groupMaping) => {
          // groupMaping.participantsNumber = groupMaping.students.length;
          groupMaping.students.forEach(
            (studentMapping, indexstudentMapping) => {
              studentMapping.index = indexstudentMapping;
              studentMapping.groupId = groupMaping.groupId;
            }
          );
        });
      }
    }
    setGroups(updatedGroups);
    const remainingGroups = updatedGroups.slice(1);
    const data = {
      createShuffles: remainingGroups,
    };
    if (!isNoAnyGroups) {
      await removeAllExistingGroup();
    }
    await createShuffle("groups/create/shuffle", data);
    // }
    setSubGroupNum(0);
    setStudentPerSubGroup(0);
  };

  const randomizeGroupsWithoutSubGroupsNum = async () => {
    let updatedGroups = [...groups];
    const isNoAnyGroups: boolean = updatedGroups.length === 1;

    let randomDistributionResult: any[][] = [];

    randomDistributionResult =
      distributeStudentsWithoutSubGroupsNum(allStudentsInClass);

    for (let i = 0; i < Math.ceil(Math.sqrt(allStudentsInClass.length)); i++) {
      const randomColor = getRandomColor();
      const groupId = generateId();
      const newSubTitle = String.fromCharCode(65 + updatedGroups.length - 1);

      const newGroupField = {
        groupId: groupId,
        title: `${t("group.Group")} ${newSubTitle}`,
        indexGroup: updatedGroups.length,
        groupSchemaId: schemeId,
        students: randomDistributionResult[i],
        bordercolor: randomColor,
      };

      updatedGroups.push(newGroupField);
      if (i === Math.ceil(Math.sqrt(allStudentsInClass.length)) - 1) {
        setUngrouped_students([]);
        updatedGroups.forEach((groupMaping) => {
          // groupMaping.participantsNumber = groupMaping.students.length;
          groupMaping.students.forEach(
            (studentMapping, indexstudentMapping) => {
              studentMapping.index = indexstudentMapping;
              studentMapping.groupId = groupMaping.groupId;
            }
          );
        });
      }
    }
    setGroups(updatedGroups);

    const remainingGroups = updatedGroups.slice(1);
    const data = {
      createShuffles: remainingGroups,
    };
    if (!isNoAnyGroups) {
      await removeAllExistingGroup();
    }
    await createShuffle("groups/create/shuffle", data);
    // }

    setSubGroupNum(0);
    setStudentPerSubGroup(0);
  };

  const removeAllExistingGroup = async () => {
    const remainingGroups = groups.slice(1);
    const updatedGroups = await Promise.all(
      remainingGroups.map(async (group) => {
        const groupId = group.groupId;
        const groupIndex = group.indexGroup;
        await deleteGroup(groupId);
        // const allStudentsUngrouped = await getUngroupedStudents();

        // if (allStudentsUngrouped === undefined) {
        //   // Handle the case where ungrouped students are undefined
        //   console.error("Error: Unable to fetch ungrouped students");
        //   return;
        // }
        return {
          ...group,
          students: [],
        };
      })
    );
    setGroups((prevGroups) => {
      return [{ ...prevGroups[0] }];
    });

    // const allStudentsUngrouped = await getUngroupedStudents();
    // if (allStudentsUngrouped === undefined) {
    //   // Handle the case where ungrouped students are undefined
    //   console.error("Error: Unable to fetch ungrouped students");
    //   return;
    // }

    // setGroups((prev: GroupCardDataInterface[]) => {
    //   // Keep the first group and update its students property
    //   const updatedGroups = [
    //     {
    //       ...prev[0],
    //       students: allStudentsUngrouped,
    //     },
    //     // Include other groups if needed
    //     // ...prev.slice(1),
    //   ];

    //   return updatedGroups;
    // });
  };

  const deleteGroup = async (id: number | string) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const updatedGroups = groups.filter((group) => group.groupId !== id);
      const response = await axios.delete(
        `${BaseURL}/groups/delete/group/${id}/${tenantName}`
      );
      setGroups(updatedGroups);
      return response.data;
    } catch (error) {
      console.error("Error in GET request:", error);
    }
  };

  const removeGroup = async (groupId: number | string, groupIndex: number) => {
    if (groupId !== undefined) {
      const updatedGroups = groups.filter((group) => group.groupId !== groupId);
      await deleteGroup(groupId);
      setGroups(updatedGroups);
    }
  };

  const updateUngroupStudents = async () => {
    const allStudentsUngrouped = await getUngroupedStudents();
    if (!allStudentsUngrouped) {
      // Handle the case where getUngroupedStudents() returns undefined
      console.error("Error: Unable to fetch ungrouped students");
      return;
    }

    setGroups((prevGroups: GroupCardDataInterface[]) => {
      return prevGroups.map((group, index) => {
        if (group.indexGroup == 0) {
          // Update the students property for the group with groupId equal to -1
          return {
            ...group,
            students: allStudentsUngrouped,
          };
        } else {
          // Keep other groups unchanged
          return group;
        }
      });
    });
  };

  const editGroupTitle = async (path: string, body: any) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.patch(`${BaseURL}/${path}/${tenantName}`, {
        data: body,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PATCH request:", error);
      throw error;
    }
  };

  const updateGroupTitle = async (
    groupId: number | string,
    newTitle: string
  ) => {
    if (groupId !== undefined) {
      let updatedGroups = [...groups];

      const data = {
        title: newTitle,
        groupId: groupId,
      };

      await editGroupTitle("groups/update/group", data);
      const editedGroup =
        updatedGroups &&
        updatedGroups.map((group) =>
          group.groupId === groupId
            ? (group.title = newTitle)
            : (group.title = group.title)
        );
      setGroups(updatedGroups);
    }
  };

  const setGroupTitleInGroupCreator = (groupTitle: string) => {
    if (groupTitle.length === 0) {
      setNewGroupTitle(t("group.Group"));
    } else {
      setNewGroupTitle(groupTitle);
    }
  };

  const handleDragEnd = async (results: any) => {
    const { source, destination, type } = results;

    // if (type === "groupMembersContainer") {
    if (!destination) return;
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    const sourceGroupIndex = source.droppableId.split("-")[1];
    const destinationGroupIndex = destination.droppableId.split("-")[1];
    if (
      sourceGroupIndex !== destinationGroupIndex &&
      destinationGroupIndex == 0
    ) {
      // console.log("this is studen_ID*/*/*/*/*/*/**************", student_ID);
      if (student_ID) await deleteStudentFromGroup(student_ID);
    }

    if (sourceGroupIndex == 0 && destinationGroupIndex !== 0) {
      const data = {
        studentId: student__ID,
        groupId: groups[destinationGroupIndex].groupId,
      };
      await createStudentInGroup("groups/create/students/inGroup", data);
    }

    if (sourceGroupIndex != 0 && destinationGroupIndex > 0) {
      const data = {
        id: student_ID,
        groupId: groups[destinationGroupIndex].groupId,
      };
      await updateStudentInGroup("groups/update/students/inGroup", data);
    }

    const newSourceMembers = [...groups[sourceGroupIndex].students];
    const newDestinationMembers =
      source.droppableId !== destination.droppableId
        ? [...groups[destinationGroupIndex].students]
        : newSourceMembers;

    const [deletedMember] = newSourceMembers.splice(source.index, 1);
    deletedMember.groupId = groups[destinationGroupIndex].groupId;
    newDestinationMembers.splice(destination.index, 0, deletedMember);
    const newGroups = [...groups];

    newGroups[sourceGroupIndex] = {
      ...groups[sourceGroupIndex],
      students: newSourceMembers,
    };

    newGroups[destinationGroupIndex] = {
      ...groups[destinationGroupIndex],
      students: newDestinationMembers,
    };

    newGroups.forEach((groupMapping) => {
      groupMapping.students.forEach((memberMapping, indexMemberMapping) => {
        memberMapping.index = indexMemberMapping;
      });
    });

    setGroups(newGroups);
    // }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <MainHolder>
        <DragDroppableGroupsWrapper
          moduleDragNDropBgColor={moduleDragNDropBgColor}
          borderModulesContainerColor={borderModulesContainerColor}
          boxShadow={boxShadow}
        >
          <AddAndRandomizeWrapper>
            <IconWrapper
              onClick={() => {
                navigate(`/courseView/groups/${newClassId}`);
              }}
            >
              <SvgPublish />
              <Tooltip
                bgColor={tooltipBgColor}
                textColor={tooltipTextColor}
                boxShadow={boxShadow}
              >
                <MediumTypography
                  text={t("gradebook.Publish")}
                  fontSize="0.625rem"
                  whiteSpace="nowrap"
                  color={tooltipTextColor}
                />
              </Tooltip>
            </IconWrapper>
            <IconWrapper
              ref={dropdownRef}
              onClick={() => {
                toggleDropdown();
              }}
            >
              <SvgAdd />

              <Tooltip
                bgColor={tooltipBgColor}
                textColor={tooltipTextColor}
                boxShadow={boxShadow}
              >
                <MediumTypography
                  text={t("grader.Add Sub-Group")}
                  fontSize="0.625rem"
                  whiteSpace="nowrap"
                  color={tooltipTextColor}
                />
              </Tooltip>
            </IconWrapper>
            <IconWrapper
              onClick={() => {
                setShowRandomizePopup(true);
                setIsPopUpOpen(true);
              }}
            >
              <SvgShuffle />
              <Tooltip
                bgColor={tooltipBgColor}
                textColor={tooltipTextColor}
                boxShadow={boxShadow}
              >
                <MediumTypography
                  text={t("group.Randomize")}
                  fontSize="0.625rem"
                  whiteSpace="nowrap"
                  color={tooltipTextColor}
                />
              </Tooltip>
            </IconWrapper>
            <IconWrapper
              onClick={() => {
                setShowDeleteAllGroups(true);
                setIsPopUpOpen(true);
              }}
            >
              <SvgDeleteAll />
              <Tooltip
                bgColor={tooltipBgColor}
                textColor={tooltipTextColor}
                boxShadow={boxShadow}
              >
                <MediumTypography
                  text={t("group.Delete All")}
                  fontSize="0.625rem"
                  whiteSpace="nowrap"
                  color={tooltipTextColor}
                />
              </Tooltip>
            </IconWrapper>
            {(isDropdownVisible || isChangeColorShown) && (
              <ColorPickerMenuNew top="2.25rem" addNewGroup={addNewGroup} />
            )}
          </AddAndRandomizeWrapper>
          {isChangeColorShown && (
            <SketchPickerHolder>
              <SketchPickerClose>
                <SvgClose
                  style={{ width: "12", height: "12", color: "5DD3B3" }}
                  onClick={() => setIsChangeColorShown(false)}
                />
              </SketchPickerClose>
              <SketchPicker
                color={selectedColor}
                disableAlpha
                onChange={(color) => setSelectedColor(color.hex)}
              />
              <SketchPickerSave>
                <SvgCheckModuleTopic
                  onClick={() => {
                    setIsChangeColorShown(false);
                    setColorGroup(selectedColor);
                    addNewGroup(selectedColor);
                  }}
                />
              </SketchPickerSave>
            </SketchPickerHolder>
          )}
          <GroupsHolder
            theme={theme}
            groupslength={groups.length}
            boxShadow={boxShadow}
          >
            {groups.length === 1 ? (
              <>
              
                {groups.map((group, index) => (
                  <Droppable
                    key={group.groupId}
                    droppableId={`Group-${index}`}
                    direction={`${
                      windowWidth <= parseInt(breakpoints.tablets)
                        ? "horizontal"
                        : "vertical"
                    }`}
                    type={`groupMembersContainer`}
                  >
                    {(provided, firstSnapshot) => (
                      <GroupCardNew
                        key={index}
                        isLoadingUngroupedStudents={isLoadingUngroupedStudents}
                        indexGroup={index}
                        title={group.title}
                        groupId={group?.groupId}
                        students={group.students}
                        bordercolor={group.bordercolor}
                        // participantsNumber={group.students.length}
                        provided={provided}
                        firstSnapshot={
                          firstSnapshot.isDraggingOver
                            ? "0px 4px 10px 0px rgba(93, 211, 179, 0.25)"
                            : boxShadow
                        }
                        isOver={firstSnapshot.isDraggingOver}
                        group={group}
                        removeGroup={deleteGroup}
                        updateUngroupStudents={updateUngroupStudents}
                        updateGroupTitle={() => {
                          if (isGroupId !== undefined)
                            updateGroupTitle(isGroupId, newGroupTitle);
                        }}
                        setGroupTitleInGroupCreator={
                          setGroupTitleInGroupCreator
                        }
                        setIsGroupIdInGroupCreator={setIsGroupIdInGroupCreator}
                        newGroupTitle={newGroupTitle}
                      />
                    )}
                  </Droppable>
                ))}
                {isLoadingCreatedGroups ? (
                  <LoaderCircle
                    width={50}
                    height={50}
                    color="#5DD3B3"
                    secondaryColor="#5DD3B3"
                  />
                ) : (
                  <LogoLabelEmptyGroups
                    groupslength={groups.length}
                    // style={{ background: "red" }}
                  >
                    <SvgEllipse color={tooltipTextColor} />
                    <Typography
                      text={t("grader.Add group, pick a color")}
                      fontSize="1.5rem"
                    />
                    <LightTypography
                      text={t(
                        "grader.Then drag and drop students into it, or you can just choose to randomize. Then click publish to save"
                      )}
                      lineHeight="1.375rem"
                    />
                  </LogoLabelEmptyGroups>
                )}
              </>
            ) : (
              <>
                {groups &&
                  groups.length > 1 &&
                  groups.map((group, index) => (
                    <Droppable
                      key={group.groupId}
                      droppableId={`Group-${index}`}
                      direction={`${
                        windowWidth <= parseInt(breakpoints.tablets)
                          ? "horizontal"
                          : "vertical"
                      }`}
                      type="groupContainer"
                    >
                      {(provided, snapshot) => (
                        <GroupCardNew
                          key={index}
                          isLoadingUngroupedStudents={
                            isLoadingUngroupedStudents
                          }
                          indexGroup={index}
                          title={group.title}
                          groupId={group?.groupId || ""}
                          students={group.students}
                          bordercolor={group.bordercolor}
                          // participantsNumber={group.students.length}
                          provided={provided}
                          group={group}
                          isOver={snapshot.isDraggingOver}
                          snapshot={
                            snapshot.isDraggingOver
                              ? "0px 4px 20px 0px rgba(93, 211, 179, 0.25)"
                              : boxShadow
                          }
                          removeGroup={deleteGroup}
                          updateUngroupStudents={updateUngroupStudents}
                          updateGroupTitle={() => {
                            if (isGroupId !== undefined)
                              updateGroupTitle(isGroupId, newGroupTitle);
                          }}
                          setGroupTitleInGroupCreator={
                            setGroupTitleInGroupCreator
                          }
                          setIsGroupIdInGroupCreator={
                            setIsGroupIdInGroupCreator
                          }
                          newGroupTitle={newGroupTitle}
                        />
                      )}
                    </Droppable>
                  ))}
              </>
            )}
          </GroupsHolder>
        </DragDroppableGroupsWrapper>
        {/* <PublishCancelWrapper>
          <ReusableSubmitButton
            text="Cancel"
            color="#D85D66"
            bgColor="transparent"
            border="1px solid #D85D66"
            borderRadius="6.25rem"
            width="13.4375rem"
            height="3.125rem"
            padding="0.88rem 0 0.87rem 0"
            onClick={() => {
              navigate(`/courseView/groups/${classId}`);
            }}
          />
          <ReusableSubmitButton
            text="Publish"
            borderRadius="6.25rem"
            width="13.4375rem"
            height="3.125rem"
            padding="0.88rem 0 0.87rem 0"
            bgColor="linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)"
            onClick={() => {
              navigate(`/courseView/groups/${classId}`);
            }}
          />
        </PublishCancelWrapper> */}
      </MainHolder>
      {showDeleteAllGroups && (
        <PopupDelete
          show={showDeleteAllGroups}
          setShow={setShowDeleteAllGroups}
          title={t("group.Are you sure you want to delete all Groups?")}
          handleSubmit={async () => {
            await removeAllExistingGroup();
            // await getUngroupedStudents();
            await updateUngroupStudents();
            setIsPopUpOpen(false);
          }}
        />
      )}
      {showWarningPopup && (
        <ReusablePopUp
          show={showWarningPopup}
          icon={SvgWarning}
          setShow={setShowWarningPopup}
          title="Warning"
          subtitle="Randomizing will delete all current sub-groups and recreate new ones with a shuffled set of students. Are you sure you want to proceed ?"
          fontSize="0.875rem"
          lineHeight="1.625rem"
          handleSubmit={() => {}}
          popUpType="Warning"
          setShowRandomizePopup={setShowRandomizePopup}
          allStudentsNumber={ungrouped_students.length}
          randomizeGroups={randomizeGroups}
          removeAllExistingGroup={removeAllExistingGroup}
          groups={groups}
          randomizeGroupsWithoutSubGroupsNum={
            randomizeGroupsWithoutSubGroupsNum
          }
        />
      )}
      {showRandomizePopup && (
        <ReusablePopUp
          show={showRandomizePopup}
          icon={SvgShuffle}
          setShow={setShowRandomizePopup}
          title={t("group.Type In The Numbers Needed")}
          subtitle={t(
            "group.Both fields are optional, adding the sub groups number alone will generate them automatically but empty, as for the number of students per sub group alone will evenly distribute students into sub groups"
          )}
          fontSize="0.75rem"
          lineHeight="1.375rem"
          popUpType="Randomize"
          handleSubmit={() => setIsPopUpOpen(false)}
          showInputs={true}
          setShowRandomizePopup={setShowRandomizePopup}
          allStudentsNumber={groups[0].students.length}
          randomizeGroups={randomizeGroups}
          removeAllExistingGroup={removeAllExistingGroup}
          groups={groups}
          randomizeGroupsWithoutSubGroupsNum={
            randomizeGroupsWithoutSubGroupsNum
          }
        />
      )}
    </DragDropContext>
  );
};

export default DroppableGroupsArea;
