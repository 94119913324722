import styled from "styled-components";

interface ButtonProps {
  width?: string;
  height?: string;
  borderradius?: string;
  padding?: string;
  bgcolor?: string;
  border?: string;
  color?: string;
}

export const InputHolder = styled.button<ButtonProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: ${({ borderradius }) => borderradius};
  padding: ${({ padding }) => padding};
  background: ${({ bgcolor }) => bgcolor};
  display: flex;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
  max-height: min-content;
  cursor: pointer;
  overflow: hidden;
  border: ${({ border }) => border};
  color: ${({ color }) => color};
  text-transform: capitalize;
`;
