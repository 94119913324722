import styled from "styled-components";

export  const AnalyticsAssignmentResponseTrackingMainContainer = styled.div`
display: flex;
flex-direction:column;
gap:1.25rem;
padding:1.25rem;
width:100%;
border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));

`;
export  const AnalyticsAssignmentResponseTrackingMainContainerRow = styled.div`
display: flex;
width:100%;
gap:1.25rem;

`;