import React, { useEffect, useMemo, useState } from "react";
import {
  CheckBox,
  FilterBtnsDiv,
  FlexDiv,
  Input,
  MainActivity,
  MainModule,
  TableSection,
  TableTbody,
  TableTd,
  TableTd1,
  TableTr,
  TableTr1,
  TableTrMainActivity,
  TableTrMainModule,
} from "./ManageWeight.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import { Activity } from "./interfaces";
import { ButtonFilledWrapper } from "../../elements/FiltersButtonFilled/FiltersButtonFilled.style";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ManageWeightAttendanceCell, ManageWeightBodyCellContents, ManageWeightBodyCellFirst, ManageWeightBodyCellSize, ManageWeightBodyTdCells, ManageWeightBodyTds, ManageWeightFlexTr, ManageWeightTable } from "./SelectedOptions/ManagewieghtUpdatesStyle";
import { useRecoilValue } from "recoil";
import { ColorSelector, TableColorSelector } from "../../recoil/ThemeSelectors";
interface Attendance {
  Attendance: {
    Weight: number;
  };
}

interface ActivityTableProps {
  activities: Activity[];
  isCheckedArray: boolean[];
  mainActivities: any[];
  handleMainWeightChange: (
    activityType: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleWeightChange: (
    contentId: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleCheckboxChange: (contentId: number) => void; // Define the prop for handleCheckboxChange
  sendInputStateToParent: (percentage: number) => void;
  shouldGroupPerModule: boolean;
   AttendanceWeight: number; // Define the prop for AttendanceWeight
  SetAttendanceWeight: React.Dispatch<React.SetStateAction<number>>; 
}

const ActivityModuleTable: React.FC<ActivityTableProps> = ({
  activities,
  mainActivities,
  isCheckedArray,
  SetAttendanceWeight,
  AttendanceWeight,
  handleWeightChange,
  handleCheckboxChange,
  handleMainWeightChange,
  sendInputStateToParent,
  shouldGroupPerModule,
}) => {
  const [percentage, setPercentage] = useState<number | null>(0);
  const [renderedMainActivities, setRenderedMainActivities] = useState<string[]>([]);
const [attendance,setAttendance]=useState<Attendance[]>([])
const tenantName=localStorage.getItem("tenant")
const id=useParams()
const { t } = useTranslation();
const {
  SecondaryTextColor,
  bghelp,
  theme,
  MainColoredColor,
  checkboxBgColor,checkboxBorderColor,checkboxBorderRadius
} = useRecoilValue(ColorSelector);
const { Table_nth_child } = useRecoilValue(TableColorSelector);

const groupActivitiesByModule = (activities: Activity[]) => {
    const groupedActivities: { [key: string]: Activity[] } = {};
    activities.forEach((activity) => {
      const moduleId = activity.moduleIndex || "Uncategorized";
      if (!groupedActivities[moduleId]) {
        groupedActivities[moduleId] = [];
      }
      groupedActivities[moduleId].push(activity);
    });
    return groupedActivities;
  };












  useEffect(() => {
    // Extract the main activity types and update renderedMainActivities
    const mainActivityTypes = mainActivities.map((activity) => Object.keys(activity)[0]);
    setRenderedMainActivities(mainActivityTypes);
  }, [mainActivities]);

  const handlePercentageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = Number(event.target.value);
    setPercentage(value);
    sendInputStateToParent(value);
  };

  const handleWeightInputChange = (
    contentId: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleWeightChange(contentId, event);
  };

  const MainWeightChange = (
    activityType: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleMainWeightChange(activityType, event);
  };

  const handleCheckboxToggle = (contentId: number) => {
    handleCheckboxChange(contentId);
  };

  if (activities.length === 0) {
    return null;
  }

  function AttendanceChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = Number(event.target.value);
    SetAttendanceWeight(value);
  }
  
 

return (
    <ManageWeightTable>
  <ManageWeightFlexTr>
  <ManageWeightAttendanceCell><TypographyRegularDescription text={`${AttendanceWeight}%`} fontWeight="1rem" /></ManageWeightAttendanceCell>
          <ManageWeightAttendanceCell> <MediumTypography
            text={t("gradebook.Attendance")}
            fontSize="0.875rem"
          /></ManageWeightAttendanceCell>
          <ManageWeightAttendanceCell></ManageWeightAttendanceCell>
          <ManageWeightAttendanceCell></ManageWeightAttendanceCell>
  </ManageWeightFlexTr>
  

        {/* Render activities */}
        {shouldGroupPerModule ? (
          // Group activities by module
          Object.entries(groupActivitiesByModule(activities)).map(([moduleId, moduleActivities]) => (
            <ManageWeightBodyCellFirst key={moduleId}>
                <ManageWeightBodyCellSize>
                  <div style={{margin:"1.1rem 1rem 1rem 1.5rem"}}>
                <MediumTypography
                text={`${t("course view.Module")} ${moduleId}`}
                fontSize="0.875rem"
              />
              </div>
                </ManageWeightBodyCellSize>
           
              <ManageWeightBodyCellSize style={{flexDirection:"column"}}>
                {/* Render activities for this module */}
                {moduleActivities.map((activity: Activity) => (
                  <ManageWeightFlexTr key={activity.content.contentId} >
                  {/* <TableTrMainModule key={activity.content.contentId} style={{background:"balck"}}> */}
                    {/* Render sub-activity */}
                    {/* <TableTd1 style={{background:"aqua"}}> */}
                    <ManageWeightBodyTds>
                      <LightTypography text={activity.content.contentTitle} /></ManageWeightBodyTds>
                   {/* </TableTd1> */}
                    {/* // <TableTd1 style={{background:"purple"}}> */}
                    <ManageWeightBodyTds>
                      <div style={{display:"flex",gap:"0.6rem"}}>
                      <Input
                        type="number"
                        value={activity.weight || ""}
                        onChange={(event) =>
                          handleWeightInputChange(activity.content.contentId, event)
                        }
                        color={SecondaryTextColor}
                            bordercolor={MainColoredColor}
                      />
                      %</div>
                      </ManageWeightBodyTds>
                    {/* // </TableTd1 > */}
                    {/* // <TableTd1 style={{background:"gray"}}> */}
                    <ManageWeightBodyTds>
                    <CheckBox
                     bgcolor={checkboxBgColor}
                     bordercolor={checkboxBorderColor}
                     borderradius={checkboxBorderRadius}
                      type="checkbox"
                      checked={isCheckedArray[activity.content.contentId]}
                      name={`status_${activity.content.contentId}`}
                      onChange={() =>
                        handleCheckboxToggle(activity.content.contentId)
                      }
                    />
                    </ManageWeightBodyTds>
                    {/* // </TableTd1> */}
                  {/* // </TableTrMainModule> */}
                  </ManageWeightFlexTr>
                ))}
              </ManageWeightBodyCellSize>
            </ManageWeightBodyCellFirst>
          ))
        ) : (
          // Render activities without grouping
          <TableSection>
            <TableTbody>
              {activities.map((activity: Activity) => (
                <TableTrMainModule key={activity.content.contentId}>
                  {/* Render sub-activity */}
                  <TableTd1>
                    <LightTypography text={activity.content.contentTitle} />
                  </TableTd1>
                  <TableTd1>
                  <div style={{display:"flex",gap:"0.6rem"}}>
                    <Input
                      type="number"
                      value={activity.weight || ""}
                      onChange={(event) =>
                        handleWeightInputChange(activity.content.contentId, event)
                      }
                    />
                    %
                    </div>
                  </TableTd1>
                  <TableTd1>
                    <CheckBox
                     bgcolor={checkboxBgColor}
                     bordercolor={checkboxBorderColor}
                     borderradius={checkboxBorderRadius}
                      type="checkbox"
                      checked={isCheckedArray[activity.content.contentId]}
                      name={`status_${activity.content.contentId}`}
                      onChange={() => handleCheckboxToggle(activity.content.contentId)}
                      disabled={activity.isOffline}
                    />
                  </TableTd1>
                </TableTrMainModule>
              ))}
            </TableTbody>
          </TableSection>
        )}
  
    </ManageWeightTable>
  );
}
  export default ActivityModuleTable;