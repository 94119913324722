import styled from "styled-components";

export const QuizContainer = styled.div`
  &:dir(ltr) {
    border-top-left-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    border-right: 0;
  }
  &:dir(rtl) {
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-left: 0;
    /* padding-right: 0%.8rem; */
  }
  /* border: 1px solid #576371; */
  padding-top: 1.88rem;
  /* background: green; */
  height: 100%;
`;
