import React, { useEffect, useState } from "react";
import {
  AppLayout,
  SidebarContainer,
  ContentContainer,
  MainContainer,
  PageheaderLayout,
  MessagesContainer,
  CourseViewContentContainer,
  MainViewContentContainer,
  LandingMainContainer,
} from "./App.style";
import { useParams } from "react-router-dom";
import GlobalStyle from "./globalStyles";
import { Dashboard } from "./Views/dashboard/Dashboard";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Sidebar } from "./components/sidebar/main/Sidebar";
import { theme } from "./theme";
import { CustomToastContainer } from "./CustomToastContainer .style";
import { CustomLightToastContainer } from "./CustomLightToastContainer.style";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStateContext } from "./contexts/ContextProvider";
import { PageHeader } from "./components/pageHeader/PageHeader";
import CourseView from "./Views/courseView/about/AboutCourseView";
import AnnouncementsCourseView from "./Views/courseView/announcements/AnnouncementsCourseView";
import AnnouncementsCardDetailsView from "./Views/courseView/announcements/announcementsCardDetailsView/AnnouncementsCardDetailsView";
import NewAnnouncements from "./Views/courseView/announcements/newAnnouncement/NewAnnouncements";
import TaskCard from "./elements/TaskCard/main/TaskCard";
import OngoingCommonCourses from "./components/ongoingCommonCourses/OngoingCommonCourses";
import CreateQuiz from "./Views/CreateQuiz/CreateQuiz";
import { LxpButton } from "./components/pageHeader/PageHeader.style";
import GraderView from "./Views/GraderView/GraderView";
import { ChangingPrimaryEmail } from "./Views/userProfile/changingEmail/ChangingPrimaryEmail";
import { NotificationSetting } from "./Views/userProfile/NotificationsSettings/NotificationSetting";
import { PrivacySetting } from "./Views/userProfile/PrivacySetting/PrivacySetting";
import { PaymentPage } from "./Views/userProfile/PayementProfileView/PaymentPage";
import { ProfileView } from "./Views/userProfile/ProfileView/ProfileView";
import SignInView from "./Views/SignInView/SignInView";
import ForgotPassword from "./Views/forgotPassword/ForgotPassword";
import ResetPassword from "./Views/resetPassword/ResetPasswordView";
import SuccessfulReset from "./Views/successfulReset/SuccessfulReset";
import Messages from "./Views/messages/Messages";
import RecentMessages from "./components/recentMessages/RecentMessages";
import { RubicTableView } from "./Views/Analytics/RubicTable/RubicTableView";
// import { AnalyticsPage } from "./Views/Analytics/AnalyticsPage/AnalyticsPage";
import { NotesCardMain } from "./Views/Notes/NotesCardMain";
import { NoteByIdView } from "./Views/NodetById/NodetById";
import FaqView from "./Views/FaqView/FaqView";
import Participants from "./Views/participants/Participants";
import AddGroup from "./Views/addGroup/AddGroup";
import ToDoView from "./Views/ToDoView/ToDoView";
import AssignmentQuiz from "./components/AssignmentQuiz/main/AssignmentQuiz";
import { Outlet } from "react-router-dom";
import SliderTabs from "./components/courseBanner/SliderTabs";
import Content from "./Views/courseView/content/Content";
import CreatingListingPage from "./Views/CreatingListingPage/CreatingListingPage";
import GroupCardView from "./Views/GroupCard/GroupCardView";
import { DiscussionForum } from "./Views/Analytics/DiscussionForum/DiscussionForum";
import TeacherDashboard from "./Views/teacherView/teacherDashboard/TeacherDashboard";
import GradeBook from "./components/gradeBook/GradeBook";
import CourseCreator from "./Views/teacherView/Course-Creator/main/CourseCreator";
import GradesInstructor from "./Views/gradesInstructor/GradesInstructor";
import { TeacherAnalyticsView } from "./Views/courseView/TeacherAnalytics/TeacherAnalytics";
import { TeacherAnalyticsDonatsChart } from "./Views/courseView/TeacherAnalyticsDonatsChart/TeacherAnalyticsDonatsChart";
import { StudentChartView } from "./Views/courseView/StutentChart/StutentChart";
import { StudentAllChartView } from "./Views/courseView/StudentAllChats/StudentAllChats";
import { NotesEditById } from "./components/NotesById/Edit";
import AnalyTicsTimeLine from "./Views/courseView/AnalyticsTimeLine/AnalyTicsTimeLine";
import { FouroFourPageView } from "./Views/404Page/404Page";
import TeacherCalendarView from "./Views/TeacherCalendarView/TeacherCalendarView";
import PdfViewer2 from "./components/GraderTextEditor/main/PdfViewer2";
import { RubricTableCompo } from "./components/RubricTable/RubricTable";
import { RubricView } from "./Views/RubricView/RubricView";
import SurveyForm from "./components/surveyForm/SurveyForm";
import { RubricTableView } from "./components/RubricTableView/RubricTableView";
import ForumForm from "./components/forumForm/ForumForm";
import ScormForm from "./components/ScormForm/ScormForm";
import EditAnnouncements from "./Views/courseView/announcements/editAnnouncements/EditAnnouncement";
import GradeBookTable from "./Views/gradeBook/GradeBookTableView";
import { UserView } from "./Views/userProfile/ProfileView/UserProfile";
import GradeItemView from "./Views/gradeBook/GradeItemView";
import GradeBookTableView from "./Views/gradeBook/GradeBookTableView";
import { UnstructuredCourseCard } from "./components/UnstructureCourseCard/UnstructureCourseCard";
import { ClassContextProvider } from "./Views/dashboard/UnstructuredContext";
import { FourOOneCompo } from "./components/FourOFour/FourOOneCompo";
import { FourOThreeCompo } from "./components/FourOFour/FourOThree";
import { ComingSoon } from "./components/FourOFour/ComingSoon";
import SurveyStudentCompo from "./components/SurveyStudentView/main/SurveyStudentCompo";
import CreateSurvey from "./Views/createSurveyView/CreateSurvey";
// import SurveyRating from "./components/surveyRating/SurveyRating";
import { useAuth0 } from "@auth0/auth0-react";
import LandingPage from "./Views/SignInView/LandingPage";
import Callback from "./Views/SignInView/callback";
import UnAuth from "./Views/UnAuth/UnAuth";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import LandingPageUni from "./components/LandingPageUni/LandingPageUni";
import ManageWeightView from "./Views/gradeBook/ManageWeightView";
import AttendanceAllSessions from "./components/attendanceAllSessions/AttendanceAllSessions";
import GenerateAttendance from "./components/generateAttendance/GenerateAttendance";
import StudentQuizView from "./components/StudentQuizView/StudentQuizView";
import AnnouncementsCourseViewForClass from "./Views/courseView/announcements/AnnouncementsCourseViewForClass";
import GenerateAttendanceView from "./Views/gradeBook/GenerateAttendanceView";
import FolderRecourses from "./components/FolderResourses/FolderRecourses";
import { RubricUpdateView } from "./Views/RubricUpdateView/RubricUpdateView";
import { AnalyticsUserView } from "./Views/AnalyticsView/AnalyticsView";
import { AnalyticsLandingPage } from "./components/AnalyticsLandingPage/AnalyticsLandingPage";
import CourseActivityStudentID from "./components/CourseActivityStudentID/main/CourseActivityStudentID";
import ForumTimeLine from "./Views/ForumTimeLine/ForumTimeLine";
import QuizGraderView from "./Views/QuizGraderView/QuizGraderView";
import SurveyGraderView from "./Views/SurveyGraderView/SurveyGraderView";
import SurveyInstructor from "./components/ActivitiesInstructorView/Survey/SurveyInstructor";
import { CourseCreatoProvider } from "./contexts/CourseCreatorContext";
import GroupCreator from "./Views/groupCreator/GroupCreator";
import AttendanceStudentID from "./components/gradeBook/AttendanceStudentID/AttendanceStudentID";
import { CourseCreatorQuizProvider } from "./contexts/CourseCreatorQuizContext";
import AnalyticsForum from "./components/Tables/AnalyticsForum/AnalyticsForum";
import ForumGraderView from "./Views/ForumGraderView/ForumGraderView";
import ForumInstructor from "./components/ActivitiesInstructorView/Forum/ForumInstructor";
import CompletionTrackingView from "./Views/completionTrackingView/CompletionTrackingView";
import DateBoxPopUp from "./elements/DateBoxPopUp/DateBoxPopUp";
import ManageSocialFeed from "./components/manageSocialFeed/ManageSocialFeed";
import ExpandedLeaderboard from "./components/expandedLeaderboard/ExpandedLeaderboard";
import Rulebook from "./components/rulebook/Rulebook";
import { AnalyticsPage } from "./Views/Analytics/AnalyticsPage/AnalyticsPage";
import ScormClassView from "./components/scormClassView/ScormClassView";
import AnalyticsForumPosts from "./Views/Analytics/AnalyticForumPosts/AnalyticsForum";
import VimeoViewer from "./components/VimeoViewer/VimeoViewer";
import PollForm from "./components/pollForm/PollForm";
import AnalyticsSurveyDefaultTable from "./Views/Analytics/AnalyticsSurvey/AnalyticsSurveyDefaultTable/AnalyticsSurveyDefaultTable";
import SurveyDetails from "./Views/Analytics/AnalyticsSurvey/SurveyDetails/SurveyDetails";
import AnalyticsAssignmentDefaultTable from "./Views/Analytics/AnalyticAssignment/AnalyticsAssignmentDefaultTable/AnalyticsAssignmentDefaultTable";
import AssignmentDetails from "./Views/Analytics/AnalyticAssignment/AssigmentDetails/AssigmentDetails";
import CreatePoll from "./Views/createPollView/CreatePoll";
import PollClassView from "./components/pollClassView/PollClassView";
import QuestionBanks from "./Views/questionBanks/QuestionBanks";
import AnalyticsForumDefaultTable from "./Views/Analytics/AnalyticsForum/AnalyticsForumDefaultTable/AnalyticsForumDefaultTable";
import ForumDetails from "./Views/Analytics/AnalyticsForum/ForumDetails/ForumDetails";
import AnalyticsScormDefaultTable from "./Views/Analytics/AnalyticsScorm/AnalyticsScormDefaultTable/AnalyticsScormDefaultTable";
import AnalyticsScormDetails from "./Views/Analytics/AnalyticsScorm/AnalyticsScormDetails/AnalyticsScormDetails";
import QuizAnalyticsDefaultTable from "./Views/Analytics/QuizAnalytics/QuizAnalyticsDefaultTable/QuizAnalyticsDefaultTable";
import QuizAnalyticsDetails from "./Views/Analytics/QuizAnalytics/QuizAnalyticsDetails/QuizAnalyticsDetails";
import StudentModals from "./Views/Analytics/QuizAnalytics/StudentModals/StudentModals";
import AnalyticsStudentCourseActivityView from "./Views/AnalyticsStudentCourseActivityView/AnalyticsStudentCourseActivityView";
import NotificationPage from "./Views/NotificationPage/NotificationPage";
import { InstructorInformation } from "./Views/userProfile/InstructorInformation/InstructorInformation";
import ParticipantInstructors from "./components/ParticipantProporties/main/ParticipantInstructors";
import LandingPageHeader from "./components/LandingPageHeader/LandingPageHeader";
import LandingPaymentWaves from "./components/LandingPageHeader/waves/LandingPaymentWaves";
import FinallyGroupDataPage from "./Views/PaymentLandingPages/FinallyGroupDataPage/FinallyGroupDataPage";
import TestPayment from "./components/TestPayment/TestPayment";
import { RecoilRoot, useRecoilState, useRecoilValue } from "recoil";
import { ColorSelector, MainColorSelector } from "./recoil/ThemeSelectors";
import i18n from "./i18n";
import { I18nextProvider, useTranslation } from "react-i18next";
import { MyContextProvider } from "./contexts/QuestionBanksContext";
import CompetencyFrameworks from "./Views/Competency/competencyFrameworks/CompetencyFrameworks";
import CompetencyPieChart from "./components/Competency/CompetencyPieChart/CompetencyPieChart";
import TeamsForm from "./components/teamsForm/TeamsForm";
import RubricContent from "./components/rubricContent/RubricContent";
import GradesSchema from "./components/GradesSchema/GradesSchema";
import TeamsView from "./Views/teamsView/TeamsView";
import SingleTeam from "./components/SingleTeam/SingleTeam";
import { CourseCreatorAssignmentProvider } from "./contexts/CourseCreatorAssignmentContext";
import RubricsList from "./Views/rubricsList/RubricsList";
import { RoleProvider } from "./contexts/RoleContext";
import { languageState } from "./recoil/RecoilStates";
import { WebsocketProvider } from "./contexts/notificationContext";
import EditProfileV2 from "./Views/UserProfileV2/EditProfileV2/EditProfileV2";
import { CourseCreatorForumProvider } from "./contexts/ForumContext";

const CourseViewLayout = () => (
  <>
    <CourseViewContentContainer>
      {/* <ClassContextProvider> */}
      <PageheaderLayout>
        <PageHeader
          HeaderText1="Welcome"
        // HeaderText2={user?.nickname || "User"}
        />
      </PageheaderLayout>
      <div
        style={{
          display: "flex",
          position: "relative",
          flexDirection: "column",
          padding: "0 1.5rem",
          rowGap: "3rem",
        }}
      >
        <SliderTabs />
        <Outlet />
      </div>
      {/* </ClassContextProvider> */}
    </CourseViewContentContainer>
  </>
);
const OriginalLayout = () => (
  <MainViewContentContainer>
    <PageheaderLayout>
      <PageHeader
      // HeaderText1="Welcome"
      //\ HeaderText2={user?.nickname || "User"}
      />
    </PageheaderLayout>
    <MainContainer>
      <SidebarContainer>
        <Sidebar sidebarMini={true} />
      </SidebarContainer>
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </MainContainer>
  </MainViewContentContainer>
);

const LandingLayout = () => (
  <>
    <LandingMainContainer>
      <LandingPageHeader />
      <LandingPaymentWaves />
      <div
        style={{
          display: "flex",
          position: "relative",
          padding: "0 1.5rem",
          marginTop: "9.3rem",
          width: "100%",
          // background: "yellow",
        }}
      >
        <Outlet />
      </div>
    </LandingMainContainer>
  </>
);
const App: React.FC = () => {
  const [language, setLanguage] = useRecoilState(languageState);
  const { contentType, pathNameState, setPathNameState, isPopUpOpen } =
    useStateContext();

  useEffect(() => {
    setPathNameState(window.location.pathname);
  }, [window.location.pathname]);
  const pathName = window.location.pathname;
  const includesCourseCreator = pathNameState.includes("course-creator");

  useEffect(() => {
    if (isPopUpOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    // Clean up on component unmount
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isPopUpOpen]);

  // Update the text direction based on the selected language
  useEffect(() => {
    document.documentElement.dir = language === "ar" ? "rtl" : "ltr";
  }, [language]);
  const { t } = useTranslation();
  const { isAuthenticated, isLoading, user } = useAuth0();
  const { mainBackgroundColor, mainColor, BodyTheme, themeGlobal } =
    useRecoilValue(MainColorSelector);
  const { overlayBgColor } = useRecoilValue(ColorSelector);
  // const themeBody = localStorage.getItem("theme");
  // const [themeBody, setthemeBody] = useState(BodyTheme);
  const storedLanguage = localStorage.getItem("language")

  useEffect(() => {
    if (BodyTheme === "dark") {
      document.body.classList.remove("light-mode");
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
      document.body.classList.add("light-mode");
    }
  }, [BodyTheme]);
  if (isLoading) {
    return <div>{`${t("general.loading")}...`} </div>;
  }
  return (
    // <div className={themeBody === "light" ? "light_Theme" : ""}>
    <div>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          {themeGlobal === "light" ? (
            <CustomLightToastContainer
              position={storedLanguage === "ar" ? "top-left" : "top-right"}
              rtl={storedLanguage === "ar"}
            />
          ) : (
            <CustomToastContainer
              position={storedLanguage === "ar" ? "top-left" : "top-right"}
              rtl={storedLanguage === "ar"}
            />
          )}

          <GlobalStyle
            bgcolor={mainBackgroundColor}
            dir={document.documentElement.dir}
          />
          <AppLayout
            contenttype={contentType}
            overlaybgcolor={overlayBgColor}
            backgroundcolor={mainBackgroundColor}
            color={mainColor}
            includescoursecreator={pathNameState
              .includes("course-creator")
              .toString()}
            pathnamestate={pathNameState}
          // style={{ backgroundColor: mainBackgroundColor, color: mainColor }}
          >
            {/* {isAuthenticated && (
            <PageheaderLayout>
              <PageHeader
                // HeaderText1="Welcome"
                // HeaderText2={user?.nickname || "User"}
              />
            </PageheaderLayout>
          )} */}
            <ClassContextProvider>
              <CourseCreatoProvider>
                <CourseCreatorQuizProvider>
                  <CourseCreatorForumProvider>
                    <MyContextProvider>
                      <RoleProvider>
                        <I18nextProvider i18n={i18n}>
                          <WebsocketProvider>
                            <Routes>
                              <Route path="/" element={<LandingPage />} />
                              <Route path="/callback" element={<Callback />} />

                              <Route
                                path="/selectTenant"
                                element={<LandingPageUni />}
                              />
                              <Route element={<CourseViewLayout />}>
                                {/* Just Analytic  Place Routes //////////////////////////////*/}
                                <Route
                                  path="/courseView/Analytics/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<AnalyticsLandingPage />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseView/Analytics/:classId/Survey"
                                  element={
                                    <ProtectedRoute
                                      component={
                                        <AnalyticsSurveyDefaultTable />
                                      }
                                    />
                                  }
                                />
                                <Route
                                  path="/courseView/Analytics/:classId/Survey/:MainCode"
                                  element={
                                    <ProtectedRoute
                                      component={<SurveyDetails />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseView/Analytics/:classId/Scorm"
                                  element={
                                    <ProtectedRoute
                                      component={<AnalyticsScormDefaultTable />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseView/Analytics/:classId/Scorm/:MainCode"
                                  element={
                                    <ProtectedRoute
                                      component={<AnalyticsScormDetails />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseView/Analytics/:classId/Assignments"
                                  element={
                                    <ProtectedRoute
                                      component={
                                        <AnalyticsAssignmentDefaultTable />
                                      }
                                    />
                                  }
                                />
                                <Route
                                  path="/courseView/Analytics/:classId/Assignments/:MainCode"
                                  element={
                                    <ProtectedRoute
                                      component={<AssignmentDetails />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseView/Analytics/:classId/Quizzes"
                                  element={
                                    <ProtectedRoute
                                      component={<QuizAnalyticsDefaultTable />}
                                    />
                                  }
                                />

                                <Route
                                  path="/courseView/Analytics/:classId/Quizzes/:MainCode"
                                  element={
                                    <ProtectedRoute
                                      component={<QuizAnalyticsDetails />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseView/Analytics/:classId/Quizzes/:MainCode/Student/:stdId"
                                  element={
                                    <ProtectedRoute
                                      component={<StudentModals />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseView/Analytics/:classId/Forum"
                                  element={
                                    <ProtectedRoute
                                      component={<AnalyticsForumDefaultTable />}
                                    />
                                  }
                                />

                                <Route
                                  path="/courseView/Analytics/:classId/Forum/:MainCode"
                                  element={
                                    <ProtectedRoute
                                      component={<ForumDetails />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseView/Analytics/:classId/Forum/:MainCode/Analytics/Posts/:PostId"
                                  element={
                                    <ProtectedRoute
                                      component={<AnalyticsForumPosts />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseView/Analytics/:classId/CourseActivityStudent/:stdId"
                                  element={
                                    <ProtectedRoute
                                      component={
                                        <AnalyticsStudentCourseActivityView />
                                      }
                                    />
                                  }
                                />
                                {/* <Route
                      path="/AnalyticsPageChartsViewAll"
                      element={<ProtectedRoute component={<AnalyticsPage />} />}
                    /> */}
                                {/* <Route
                      path="/AnalyticsView/content/TeacherAnalytics"
                      element={
                        <ProtectedRoute component={<TeacherAnalyticsView />} />
                      }
                    /> */}
                                {/* <Route
                      path="/AnalyticsView/content/StudentAllcharts"
                      element={
                        <ProtectedRoute component={<StudentAllChartView />} />
                      }
                    /> */}
                                {/* <Route
                      path="/AnalyticsView/content/TeacherPieData"
                      element={
                        <ProtectedRoute
                          component={<TeacherAnalyticsDonatsChart />}
                        />
                      }
                    /> */}

                                {/* Just Analytic Place Routes */}

                                <Route
                                  path="/courseView/about/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<CourseView />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseView/Participants/Instructor/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<ParticipantInstructors />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseView/content/:classId"
                                  element={
                                    <ProtectedRoute component={<Content />} />
                                  }
                                />

                                <Route
                                  path="/courseview/participants/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<Participants />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseview/announcements/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={
                                        <AnnouncementsCourseViewForClass />
                                      }
                                    />
                                  }
                                />
                                <Route
                                  path="courseview/announcements/new/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<NewAnnouncements />}
                                    />
                                  }
                                />
                                <Route
                                  path="courseview/announcements/edit/:id/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<EditAnnouncements />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseView/groups/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<GroupCardView />}
                                    />
                                  }
                                />
                                <Route
                                  path="/gradeBook/:classId"
                                  element={
                                    <ProtectedRoute component={<GradeBook />} />
                                  }
                                />

                                <Route
                                  path="/courseView/Gradebook/:id/Rubric"
                                  element={
                                    <ProtectedRoute
                                      component={<RubricView />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseview/Gradebook/:classId/RubricTableView/:id"
                                  element={
                                    <ProtectedRoute
                                      component={<RubricTableView />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseview/Gradebook/:classId/RubricsList"
                                  element={
                                    <ProtectedRoute
                                      component={<RubricsList />}
                                    />
                                  }
                                />
                                {/* needs to remove and delete from view after connected to database */}
                                <Route
                                  path="/courseView/ForumTimeLine"
                                  element={
                                    <ProtectedRoute
                                      component={<ForumTimeLine forumId={9} />}
                                    />
                                  }
                                />

                                <Route
                                  path="/courseview/Gradebook/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<GradeBookTableView />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseview/Gradebook/GradeSchema/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<GradesSchema />}
                                    />
                                  }
                                />
                                {/* <Route
                          path="/ForumInstructor"
                          element={
                            <ProtectedRoute
                              component={<ForumInstructor id={9} />}
                            />
                          }
                        /> */}

                                {/* 
                    //not needed
                    <Route
                      path="/gradeBookV2"
                      element={
                        <ProtectedRoute component={<GradeBookTable />} />
                      }
                    /> */}

                                <Route
                                  path="/courseview/Teams/:classId"
                                  element={
                                    <ProtectedRoute component={<TeamsView />} />
                                  }
                                />
                                <Route
                                  path="/courseview/Teams/:classId/SingleTeam/:teamId"
                                  element={
                                    <ProtectedRoute
                                      component={<SingleTeam />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseView/Chats/:classId"
                                  element={
                                    <ProtectedRoute component={<Messages />} />
                                  }
                                />
                                <Route
                                  path="/courseview/Gradebook/manageWeightView/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<ManageWeightView />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseview/Gradebook/attendanceAllSessions/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<AttendanceAllSessions />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseview/Gradebook/GradeItemView/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<GradeItemView />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseView/Gradebook/:classId/RubricUpdate/:id"
                                  element={
                                    <ProtectedRoute
                                      component={<RubricUpdateView />}
                                    />
                                  }
                                />
                                <Route
                                  path="/courseview/Gradebook/:classId/generateAttendance/:id"
                                  element={
                                    <ProtectedRoute
                                      component={<GenerateAttendanceView />}
                                    />
                                  }
                                />
                                <Route
                                  path="/gradeItem"
                                  element={
                                    <ProtectedRoute
                                      component={<GradeItemView />}
                                    />
                                  }
                                />

                                <Route
                                  path="/CourseViewLayout/:classId/announcements/:announcementID"
                                  element={
                                    <ProtectedRoute
                                      component={
                                        <AnnouncementsCardDetailsView />
                                      }
                                    />
                                  }
                                />
                              </Route>

                              <Route element={<OriginalLayout />}>
                                {/* <Route path="/signin" element={<SignInView />} /> */}
                                {/* <Route path="/login" element={<SignInView />} /> */}
                                <Route
                                  path="/dashboard"
                                  element={
                                    <ProtectedRoute component={<Dashboard />} />
                                  }
                                />

                                <Route
                                  path="/AttendanceStudentID"
                                  element={
                                    <ProtectedRoute
                                      component={<AttendanceStudentID />}
                                    />
                                  }
                                />
                                {/* <Route
                path="/pdfV2"
                element={<PdfViewer2 document={"document.pdf"} />}
              /> */}
                                <Route
                                  path="/announcements"
                                  element={
                                    <ProtectedRoute
                                      component={<AnnouncementsCourseView />}
                                    />
                                  }
                                />
                                <Route
                                  path="/StudentQuizView"
                                  element={
                                    <ProtectedRoute
                                      component={<StudentQuizView />}
                                    />
                                  }
                                />
                                <Route
                                  path="/announcements/:announcementID"
                                  element={
                                    <ProtectedRoute
                                      component={
                                        <AnnouncementsCardDetailsView />
                                      }
                                    />
                                  }
                                />
                                <Route
                                  path="/editProfile"
                                  element={
                                    <ProtectedRoute
                                      component={<EditProfileV2 />}
                                    />
                                  }
                                />

                                <Route
                                  path="/InstructorInformation"
                                  element={
                                    <ProtectedRoute
                                      component={<InstructorInformation />}
                                    />
                                  }
                                />
                                <Route
                                  path="/create-quiz/:id"
                                  element={
                                    <ProtectedRoute
                                      component={<CreateQuiz />}
                                    />
                                  }
                                />
                                {/* <Route path="/quiz-showmore" element={<TeacherDashboard />} /> */}
                                <Route
                                  path="/course-creator/:id"
                                  element={
                                    <ProtectedRoute
                                      component={<CourseCreator />}
                                    />
                                  }
                                />
                                <Route
                                  path="/changePassword"
                                  element={
                                    <ProtectedRoute
                                      component={<ChangingPrimaryEmail />}
                                    />
                                  }
                                />
                                <Route
                                  path="/CompetencyFrameworks"
                                  element={
                                    <ProtectedRoute
                                      component={<CompetencyFrameworks />}
                                    />
                                  }
                                />
                                <Route
                                  path="/Competency/:id"
                                  element={
                                    <ProtectedRoute
                                      component={<CompetencyPieChart />}
                                    />
                                  }
                                />
                                <Route
                                  path="/Notification"
                                  element={
                                    <ProtectedRoute
                                      component={<NotificationPage />}
                                    />
                                  }
                                />
                                {/* <Route path="/RubricTable" element={<RubricTableCompo />} /> */}
                                {/* <Route
                path="/NotificationSetting"
                element={<NotificationSetting />}
              /> */}
                                {/* <Route
                      path="/PrivacySetting"
                      element={
                        <ProtectedRoute component={<PrivacySetting />} />
                      }
                    /> */}
                                {/* <Route path="/Payment" element={<PaymentPage />} /> */}
                                {/* <Route
                      path="/ProfileView/user/:id"
                      element={<ProtectedRoute component={<ProfileView />} />}
                    /> */}
                                <Route
                                  path="/user/:id"
                                  element={
                                    <ProtectedRoute component={<UserView />} />
                                  }
                                />

                                <Route
                                  path="/Notes"
                                  element={
                                    <ProtectedRoute
                                      component={<NotesCardMain />}
                                    />
                                  }
                                />
                                <Route
                                  path="/Notes/:id"
                                  element={
                                    <ProtectedRoute
                                      component={<NoteByIdView />}
                                    />
                                  }
                                />
                                <Route
                                  path="/Notes/Edit/:id"
                                  element={
                                    <ProtectedRoute
                                      component={<NotesEditById />}
                                    />
                                  }
                                />
                                <Route
                                  path="/RubicTable"
                                  element={
                                    <ProtectedRoute
                                      component={<RubicTableView />}
                                    />
                                  }
                                />
                                {/* <Route
                      path="/TestPayment"
                      element={
                        <ProtectedRoute component={<TestPayment />} />
                      }
                    /> */}
                                <Route
                                  path="/TestForum"
                                  element={
                                    <ProtectedRoute
                                      component={<ForumTimeLine forumId={7} />}
                                    />
                                  }
                                />
                                <Route
                                  path="/to-do"
                                  element={
                                    <ProtectedRoute component={<ToDoView />} />
                                  }
                                />

                                <Route
                                  path="/CompetencyPopup"
                                  element={
                                    <ProtectedRoute
                                      component={<NotificationSetting />}
                                    />
                                  }
                                />
                                <Route
                                  path="/GradesInstructor"
                                  element={
                                    <ProtectedRoute
                                      component={<GradesInstructor />}
                                    />
                                  }
                                />
                                <Route
                                  path="/grader/:id/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<GraderView />}
                                    />
                                  }
                                />
                                <Route
                                  path="/graderQuiz/:id/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<QuizGraderView />}
                                    />
                                  }
                                />
                                <Route
                                  path="/graderSurvey/:id/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<SurveyGraderView />}
                                    />
                                  }
                                />
                                <Route
                                  path="/graderForum/:id/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<ForumGraderView />}
                                    />
                                  }
                                />
                                <Route
                                  path="/VimeoViewer"
                                  element={
                                    <ProtectedRoute
                                      component={<VimeoViewer linkId="2" />}
                                    />
                                  }
                                />
                                <Route
                                  path="/forgot-password"
                                  element={
                                    <ProtectedRoute
                                      component={<ForgotPassword />}
                                    />
                                  }
                                />
                                <Route
                                  path="/reset-password"
                                  element={
                                    <ProtectedRoute
                                      component={<ResetPassword />}
                                    />
                                  }
                                />
                                <Route
                                  path="/successful-reset"
                                  element={
                                    <ProtectedRoute
                                      component={<SuccessfulReset />}
                                    />
                                  }
                                />
                                <Route
                                  path="/help"
                                  element={
                                    <ProtectedRoute component={<FaqView />} />
                                  }
                                />
                                <Route
                                  path="/add-group"
                                  element={
                                    <ProtectedRoute component={<AddGroup />} />
                                  }
                                />
                                <Route
                                  path="/group-creator/:id/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<GroupCreator />}
                                    />
                                  }
                                />
                                <Route
                                  path="/Allcourses"
                                  element={
                                    <ProtectedRoute
                                      component={<CreatingListingPage />}
                                    />
                                  }
                                />
                                <Route
                                  path="/discuttion"
                                  element={
                                    <ProtectedRoute
                                      component={<DiscussionForum />}
                                    />
                                  }
                                />

                                <Route
                                  path="/calendar"
                                  element={
                                    <ProtectedRoute
                                      component={<TeacherCalendarView />}
                                    />
                                  }
                                />
                                {/* Analytic Needs */}
                                <Route
                                  path="/Analytics"
                                  element={
                                    <ProtectedRoute
                                      component={<AnalyticsUserView />}
                                    />
                                  }
                                />

                                {/* Analytic Needs */}

                                {/* <Route path="/surveyForm" element={<SurveyForm />} /> */}
                                {/* <Route path="/forumForm" element={<ForumForm />} /> */}
                                <Route
                                  path="/pollForm"
                                  element={<PollForm />}
                                />

                                <Route
                                  path="/CreateSurvey/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<CreateSurvey />}
                                    />
                                  }
                                />
                                <Route
                                  path="/completionTracking/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<CompletionTrackingView />}
                                    />
                                  }
                                />
                                <Route
                                  path="/manageSocialFeed/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<ManageSocialFeed />}
                                    />
                                  }
                                />
                                <Route
                                  path="/leaderboard/:classId"
                                  element={
                                    <ProtectedRoute
                                      component={<ExpandedLeaderboard />}
                                    />
                                  }
                                />
                                <Route
                                  path="/rulebook/:classId"
                                  element={
                                    <ProtectedRoute component={<Rulebook />} />
                                  }
                                />
                                <Route
                                  path="/rulebook/:classId"
                                  element={
                                    <ProtectedRoute component={<Rulebook />} />
                                  }
                                />
                                <Route
                                  path="/createPoll"
                                  element={
                                    <ProtectedRoute
                                      component={<CreatePoll />}
                                    />
                                  }
                                />
                                {/* <Route path="/scormForm" element={<ScormForm />} /> */}
                                <Route
                                  path="/scormClassView"
                                  element={<ScormClassView />}
                                />
                                <Route
                                  path="/pollClassView"
                                  element={<PollClassView />}
                                />
                                <Route
                                  path="/questionBanks/:id"
                                  element={
                                    <ProtectedRoute
                                      component={<QuestionBanks />}
                                    />
                                  }
                                />
                                {/* <Route
                            path="/RubricContent"
                            element={
                              <ProtectedRoute component={<RubricContent />} />
                            }
                          /> */}
                                {/* <Route
                          path="/TeamsForm"
                          element={
                            <ProtectedRoute component={<TeamsForm />} />
                          }
                        /> */}
                              </Route>

                              <Route element={<LandingLayout />}>
                                <Route
                                  path="/Payments/AllFinally"
                                  element={
                                    <ProtectedRoute
                                      component={<FinallyGroupDataPage />}
                                    />
                                  }
                                />
                              </Route>
                              <Route
                                path="/*"
                                element={<FouroFourPageView />}
                              />
                              <Route
                                path="/FourOThree"
                                element={<FourOThreeCompo />}
                              />
                              <Route
                                path="/unAuth"
                                element={<FourOOneCompo />}
                              />
                              {/* <Route path="/FourOOneCompo" element={<FourOOneCompo />} /> */}
                              {/* <Route path="/unAuth" element={<UnAuth />} /> */}
                              <Route
                                path="/ComingSoon"
                                element={<ComingSoon />}
                              />
                              <Route
                                path="/FinallyPayment"
                                element={<ComingSoon />}
                              />
                            </Routes>
                          </WebsocketProvider>
                        </I18nextProvider>
                      </RoleProvider>
                    </MyContextProvider>
                  </CourseCreatorForumProvider>
                </CourseCreatorQuizProvider>
              </CourseCreatoProvider>
            </ClassContextProvider>
          </AppLayout>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};

export default App;
