import { AssignmentDataInterface, Student, WeightDataInterface } from "./NewGradeBookTableInterface";



export const WeightData: WeightDataInterface[] = [
  {
    segmentTitle: "Assignment",
    segmentWeight: "40",
  },
  {
    segmentTitle: "Quiz",
    segmentWeight: "35",
  },
  {
    segmentTitle: "Forum",
    segmentWeight: "20",
  },
  {
    segmentTitle: "Attendance",
    segmentWeight: "5",
  },
  {
    segmentTitle: "Course Final Grade",
  },
];
export const AssigNamesRow: AssignmentDataInterface[] = [
  { AssigNames: "Assignment 1" },
  { AssigNames: "Assignment 2" },
  { AssigNames: "Assignment 3" },
  { AssigNames: "Assignment Total" },
  // Add more assignments as needed
];
export const QuizNamesRow: AssignmentDataInterface[] = [
  { QuizNames: "Quiz 1" },
  { QuizNames: "Quiz 2" },
  { QuizNames: "Quiz 3" },
  { QuizNames: "Quiz Total" },
  // Add more assignments as needed
];
export const ForumNamesRow: AssignmentDataInterface[] = [
  { ForumNames: "Forum 1" },
  { ForumNames: "Forum 2" },
  { ForumNames: "Forum 3" },
  { ForumNames: "Forum Total" },
  // Add more assignments as needed
];
export const AttendanceNamesRow: AssignmentDataInterface[] = [
  { AttendanceNames: "Attendance Total" },
 
  // Add more assignments as needed
];

export const AssignmentData: Student[] = [

  {
    StudentId: "1",
    Name: "Lana",
    Assignments: [
      {
        AssignmentName: "Assignment 1",
        AssignmentGrade: "35",
      },
      {
        AssignmentName: "Assignment 2",
        AssignmentGrade: "25",
      },
      {
        AssignmentName: "Assignment 3",
        AssignmentGrade: "100",
      },
      
    ],
  },
  // Add more students as needed
  {
    StudentId: "2",
    Name: "John",
    Assignments: [
      {
        AssignmentName: "Assignment 1",
        AssignmentGrade: "70",
      },
      {
        AssignmentName: "Assignment 2",
        AssignmentGrade: "30",
      },
      {
        AssignmentName: "Assignment 3",
        AssignmentGrade: "90",
      },
    
    ],
  },
  {
    StudentId: "3",
    Name: "Ali",
    Assignments: [
      {
        AssignmentName: "Assignment 1",
        AssignmentGrade: "20",
      },
      {
        AssignmentName: "Assignment 2",
        AssignmentGrade: "20",
      },
      {
        AssignmentName: "Assignment 3",
        AssignmentGrade: "20",
      },
    
    ],
  },
  {
    StudentId: "4",
    Name: "Abed",
    Assignments: [
      {
        AssignmentName: "Assignment 1",
        AssignmentGrade: "70",
      },
      {
        AssignmentName: "Assignment 2",
        AssignmentGrade: "70",
      },
      {
        AssignmentName: "Assignment 3",
        AssignmentGrade: "70",
      },
    
    ],
  },

];

export const QuizData: Student[] = [
  {
    StudentId: "1",
    Name: "Lana",
    Quizzes: [
      {
        QuizName: "Quiz 1",
        QuizGrade: "27",
      },
      {
        QuizName: "Quiz 2",
        QuizGrade: "25",
      },
      {
        QuizName: "Quiz 3",
        QuizGrade: "100",
      },
    ],
  },
  // Add more students as needed
  {
    StudentId: "2",
    Name: "John",
    Quizzes: [
      {
        QuizName: "Quiz 1",
        QuizGrade: "40",
      },
      {
        QuizName: "Quiz 2",
        QuizGrade: "30",
      },
      {
        QuizName: "Quiz 3",
        QuizGrade: "90",
      },
    ],
  },
  {
    StudentId: "3",
    Name: "Ali",
    Quizzes: [
      {
        QuizName: "Quiz 1",
        QuizGrade: "30",
      },
      {
        QuizName: "Quiz 2",
        QuizGrade: "30",
      },
      {
        QuizName: "Quiz 3",
        QuizGrade: "30",
      },
    ],
  },
  {
    StudentId: "4",
    Name: "Abed",
    Quizzes: [
      {
        QuizName: "Quiz 1",
        QuizGrade: "80",
      },
      {
        QuizName: "Quiz 2",
        QuizGrade: "80",
      },
      {
        QuizName: "Quiz 3",
        QuizGrade: "80",
      },
    ],
  },
];

export const ForumData: Student[] = [
  {
    StudentId: "1",
    Name: "Lana",
    Forums: [
      {
        ForumName: "Forum 1",
        ForumGrade: "40",
      },
      {
        ForumName: "Forum 2",
        ForumGrade: "25",
      },
      {
        ForumName: "Forum 3",
        ForumGrade: "100",
      },
    ],
  },
  
  // Add more students as needed
  {
    StudentId: "2",
    Name: "John",
    Forums: [
      {
        ForumName: "Forum 1",
        ForumGrade: "20",
      },
      {
        ForumName: "Forum 2",
        ForumGrade: "30",
      },
      {
        ForumName: "Forum 3",
        ForumGrade: "90",
      },
    ],
  },
  {
    StudentId: "3",
    Name: "Ali",
    Forums: [
      {
        ForumName: "Forum 1",
        ForumGrade: "40",
      },
      {
        ForumName: "Forum 2",
        ForumGrade: "40",
      },
      {
        ForumName: "Forum 3",
        ForumGrade: "40",
      },
    ],
  },
  {
    StudentId: "4",
    Name: "Abed",
    Forums: [
      {
        ForumName: "Forum 1",
        ForumGrade: "90",
      },
      {
        ForumName: "Forum 2",
        ForumGrade: "90",
      },
      {
        ForumName: "Forum 3",
        ForumGrade: "90",
      },
    ],
  },
];
export const AttendanceData: Student[] = [
  {
    StudentId: "1",
    Name: "Lana",
    Attendance: [
      {
        AttendanceName: "Attendance",
        AttendanceGrade: "25",
      },
    ],
  },
  // Add more students as needed
  {
    StudentId: "2",
    Name: "John",
    Attendance: [
      {
        AttendanceName: "Attendance",
        AttendanceGrade: "30",
      },
    
    ],
  },
  {
    StudentId: "3",
    Name: "Ali",
    Attendance: [
      {
        AttendanceName: "Attendance",
        AttendanceGrade: "40",
      },
    
    ],
  },
  {
    StudentId: "4",
    Name: "Abed",
    Attendance: [
      {
        AttendanceName: "Attendance",
        AttendanceGrade: "50",
      },
    
    ],
  },
];

export const ActivityWeight=[
  {
    Attendance:5,
    Assignment:25,
    Quiz:25,
    Forum:45,

  }
]



export const StudentData=[

{
  name:"moe",
  id:"1",
  Assigments:[
    {id:1,name:"assignment 1",score:33,weight:0.25,},
    {id:2,name:"assignment 2",score:27,weight:0.75},

  ],
  totalAssigment:78,
  Quizs:[
    {id:1,name:"Quiz 1",score:77,weight:0.55,},
    {id:2,name:"Quiz 2",score:15,weight:0.45,},
    {id:3,name:"Quiz 3",score:76,weight:0.15,},
  ],
  totalQuiz:76,
  Forums:[
    {id:1,name:"Forum 1",score:77,weight:0.25,},
    {id:2,name:"Forum 2",score:16,weight:0.65,},
    {id:3,name:"Forum 3",score:44,weight:0.1,},
  ],
  totalForums:45,
  Attendance:{id:1,score:80,weight:0.05}
},
{
  name:"abed",
  id:"2",
  Assigments:[
    {id:1,name:"assignment 1",score:76,weight:0.25,},
    {id:2,name:"assignment 2",score:55,weight:0.75,},

  ],
  totalAssigment:64,
  Quizs:[
    {id:1,name:"Quiz 1",score:44,weight:0.85,},
    {id:2,name:"Quiz 2",score:88,weight:0.15,},
    {id:3,name:"Quiz 3",score:11,weight:0.15,},
  ],  totalQuiz:55,
  Forums:[
    {id:1,name:"Forum 1",score:19,weight:0.25,},
    {id:2,name:"Forum 2",score:97,weight:0.65,},
    {id:3,name:"Forum 3",score:13,weight:0.1,},
  ],
  totalForums:34,
  Attendance:{id:1,score:15,weight:0.05}
}
,
{
  name:"hadi",
  id:"3",
  Assigments:[
    {id:1,name:"assignment 1",score:86,weight:0.25,},
    {id:2,name:"assignment 2",score:43,weight:0.75,},

  ],
  totalAssigment:56,
  Quizs:[
    {id:1,name:"Quiz 1",score:76,weight:0.85,},
    {id:2,name:"Quiz 2",score:43,weight:0.15,},
    {id:3,name:"Quiz 3",score:98,weight:0.15,},
  ],  totalQuiz:78,
  Forums:[
    {id:1,name:"Forum 1",score:45,weight:0.25,},
    {id:2,name:"Forum 2",score:67,weight:0.65,},
    {id:3,name:"Forum 3",score:13,weight:0.1,},
  ],
  totalForums:44,
  Attendance:{id:1,score:66,weight:0.05}
}

]

export const Activities = [
  {
    name: 'assignment',
    weight: 0.4,
  },
  {
    name: 'Quiz',
    weight: 0.3,
  },
  {
    name: 'Forum',
    weight: 0.2,
  },
  {
    name: 'Attendance',
    weight: 0.1,
  },
];