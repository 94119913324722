import AddGRoupMembersBar from "../../components/addGroupMembersBar/AddGRoupMembersBar"
import { MediumTypography, Typography } from "../../elements/fonts/Fonts";
import { PageTitle, AddGroupPageWrapper, ButtonCancel, GlobalWrapper, ButtonAdd, ButtonsHolder } from "./AddGroup.style";
import AddGroupDragDrop from "../../components/addGroupDragDrop/AddGroupDragDrop";
import EditModal from "../../components/groupCard/editModal/EditModal";
import ModalWrapper from "../../components/groupCard/editModal/modalOverlay/ModalOverlay";
import { useState } from "react";
// import { isVisible } from "@testing-library/user-event/dist/utils";

const AddGroup = () => {
  const [selectedcolor, setSelectedColor] = useState('');
  // const [isMembersBarVisible, setIsMembersBarVisible] = useState(false);

  // const toggleMembersBar = () => {
  //   setIsMembersBarVisible(!isMembersBarVisible);
  // };
  const [isMemberbarOpen, setIsMemberbarOpen] = useState(false);
  const toggleMemberBar = () => {
    setIsMemberbarOpen(!isMemberbarOpen);
  };
  return (<GlobalWrapper>
      <PageTitle>

        <Typography
          text="Group Creator"
          fontSize="1.75rem"
          fontWeight="400"
          lineHeight="normal"

        />
      </PageTitle>
    <AddGroupPageWrapper>

      <AddGRoupMembersBar isVisible={false} isOpen={true} />
      <AddGroupDragDrop toggleMemberbar={toggleMemberBar}/>
    {/* <ModalWrapper>
      <EditModal/>
    </ModalWrapper> */}
    </AddGroupPageWrapper>
    <ButtonsHolder>

    <ButtonAdd>
      <MediumTypography
      text="Save"
      color="#fff"
      />
    </ButtonAdd>
    <ButtonCancel>
      <MediumTypography
      text="Cancel"
      color="#D04B4B"
      />
    </ButtonCancel>
    </ButtonsHolder>

    </GlobalWrapper>
  )
}

export default AddGroup