import styled, { css, keyframes } from "styled-components";
import { StyleTextEditProps, TextEditProps } from './TinyEditorInterface';

// Custom CSS styles for the text area
export const customStyles = (theme: string, language: any) =>`
      body { 
        color: ${theme === 'light' ? '#2d3741' : '#fff'} !important;
        background-color: transparent !important;
        direction: ${language === 'ar' ? 'rtl' : 'ltr'} !important;
      }
      .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
        color: ${theme === 'light' ? '#2d3741' : '#fff'} !important;
        opacity: 0.5 !important;
        content: attr(data-mce-placeholder) !important;
    }
    `;

export const EditorWrapper = styled.div<StyleTextEditProps>`
${props => props.theme === "dark" &&`
*{
    font-family: "Primary Font-Medium"  !important;
}
/* menubar */
.tox .tox-menubar {
    background-color: transparent !important;
    color: #fff !important;
}

/* toolbar */
.tox .tox-toolbar__primary {
    background-color: transparent !important;
    color: #fff !important;
    border-bottom: 1px solid #576371 !important;
}

.tox .tox-editor-header {
    z-index: 0 !important;
}

/* select input dropdowns like paragraph and font size */
.tox .tox-tbtn--bespoke {
    background-color: transparent !important;
    color: #fff !important;
    border: 1px solid #576371 !important;
}

/* line ontop the menubar and below toolbar*/
.tox:not(.tox-tinymce-inline) .tox-editor-header {
    background-color: transparent !important;
    border-top: 1px solid #576371 !important;
    // box-shadow: 0 2px 2px -2px rgba(34, 47, 62, .1), 0 8px 8px -4px rgba(34, 47, 62, .07) !important;
    padding: 1px 0 !important;
}

/* text editor container */
.tox-tinymce {
    border: 1px solid #576371 !important;
    border-radius: 0.625rem !important;
    box-shadow: none !important;
    box-sizing: border-box !important;
    display: flex !important;
    flex-direction: column !important;
    font-family: 'Primary font-Light' !important;
    overflow: hidden !important;
    position: relative !important;
    visibility: inherit !important;
    border-top: none !important;
}

/* menu bar items "file/edit/view" */
.tox .tox-mbtn {
    color: #fff !important;
}

.tox .tox-mbtn:hover {
    background-color: #5DD3B3 !important;
}

.tox .tox-mbtn:hover:not(:disabled):not(.tox-mbtn--active) {
    background-color: #5DD3B3 !important;
}

.tox .tox-mbtn:focus:not(:disabled) {
    background-color: #5DD3B3 !important;
}

.tox .tox-mbtn--active {
    background: #384450 !important;
}

/* tool bar items "B/I/U" */
.tox .tox-tbtn {
    color: #fff !important;
}

.tox .tox-tbtn:focus {
    background: #5DD3B380 !important;
}

.tox .tox-tbtn--enabled,
.tox .tox-tbtn--enabled:hover {
    background: #5DD3B3 !important;
}

.tox .tox-tbtn--enabled:focus {
    background: #5DD3B3 !important;
}

.tox .tox-tbtn:hover {
    background-color: #5DD3B3 !important;
}

.tox .tox-tbtn--disabled:hover {
    background-color: transparent !important;
}

.tox .tox-tbtn svg {
    fill: #fff !important;
}

.tox .tox-tbtn--disabled svg,
.tox .tox-tbtn--disabled:hover svg,
.tox .tox-tbtn:disabled svg,
.tox .tox-tbtn:disabled:hover svg {
    fill: #fff !important;
    opacity: 0.5;
}

.tox .tox-menubar+.tox-toolbar,
.tox .tox-menubar+.tox-toolbar-overlord {
    border-top: none !important;
    border-bottom: none !important;
    margin-top: -1px !important;
    padding-bottom: 1px !important;
    padding-top: 1px !important;
}

/* toolbar */
.tox .tox-toolbar-overlord {
    background-color: transparent !important;
}

/* menu onClick item */
.tox .tox-menu {
    background-color: #384450 !important;
    border: 1px solid #384450 !important;
    border-radius: 6px !important;
    // box-shadow: 0 0 2px 0 rgba(34, 47, 62, .2), 0 4px 8px 0 rgba(34, 47, 62, .15) !important;
    display: inline-block !important;
    overflow: hidden !important;
    vertical-align: top !important;
    /* z-index: 1150 !important; */
}

.tox .tox-collection__item {
    align-items: center !important;
    border-radius: 3px !important;
    color: #fff !important;
}

.tox .tox-collection__item:hover {
    background-color: #5DD3B3 !important;
    color: #fff !important;
}

.tox .tox-collection--list .tox-collection__item--active {
    background-color: #576371 !important;
}

.tox .tox-collection__item-caret svg {
    fill: #fff !important;
}

.tox .tox-collection__item-accessory {
    color: #ffffff80 !important;
}

.tox .tox-collection--list .tox-collection__item--enabled {
    background-color: #576371 !important;
}

/* backdrop popup */
.tox .tox-dialog-wrap__backdrop {
    background-color: rgba(0, 0, 0, 0.4) !important;
}

.tox .tox-dialog__header {
    background-color: #384450 !important;
    color: #fff !important;
}

.tox .tox-button--icon .tox-icon svg,
.tox .tox-button.tox-button--icon .tox-icon svg,
.tox .tox-button.tox-button--secondary.tox-button--icon .tox-icon svg {
    fill: #5DD3B3 !important;
}

.tox .tox-dialog__body-content {
    background-color: #384450 !important;
}

.tox .tox-listboxfield .tox-listbox--select,
.tox .tox-textarea,
.tox .tox-textarea-wrap .tox-textarea:focus,
.tox .tox-textfield,
.tox .tox-toolbar-textfield {
    background-color: transparent !important;
    border-color: #576371 !important;
    color: #fff !important;
    font-family: 'Primary font-Light' !important;
}

.tox .tox-label,
.tox .tox-toolbar-label {
    color: #fff !important;
}

.tox .tox-listbox__select-label {
    color: #ffffff80 !important;
}

.tox .tox-listbox__select-chevron svg {
    fill: #ffffff80  !important;
}

.tox .tox-dialog__footer {
    background-color: #384450 !important;
}

.tox .tox-button {
    background-color: #5DD3B3 !important;
    border-color: #5DD3B3 !important;
    color: #fff !important;
    border-radius: 1.25rem !important;
}

.tox .tox-button--secondary {
    background-color: transparent !important;
    border-color: #D85D66 !important;
    color: #D85D66 !important;
    border-radius: 1.25rem !important;
}

.tox .tox-button--naked {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: unset !important;
    color: #5DD3B3 !important;
}

.tox .tox-dialog__body-nav {
    background-color: #384450 !important;
}

.tox .tox-dialog__body-nav-item {
    color: #fff !important;
}

.tox .tox-dialog__body-nav-item--active {
    border-bottom: 2px solid #5DD3B3 !important;
    color: #5DD3B3 !important;
}

.tox .tox-insert-table-picker .tox-insert-table-picker__selected {
    background-color: #5DD3B380 !important;
    border-color: #5DD3B380 !important;
}

.tox.tox-tinymce-aux .tox-toolbar__overflow {
    background-color: #384450 !important;
}

.tox .tox-edit-area__iframe {
    background-color: transparent !important;
    color: #fff;
  }

  .tox .tox-dropzone {
    background: transparent !important;
    border: 2px dashed #5DD3B3 !important;
}
.tox .tox-dropzone p {
    color: #fff !important;
}
.tox .tox-promotion {
    display: none !important;
}
.tox .tox-collection--list .tox-collection__item--active {
    background-color: #5DD3B380 !important;
  }
  .tox .tox-collection--toolbar .tox-collection__item--enabled {
    background-color: #5DD3B3 !important;
    color: #fff !important;
  }
  .tox .tox-collection--toolbar .tox-collection__item--active {
    background-color: #5DD3B380 !important;
  }
  .tox .tox-collection--grid .tox-collection__item--enabled {
    background-color: #5DD3B3 !important;
    color: #fff !important;
  }
  .tox .tox-collection--grid .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
    background-color: #5DD3B380 !important;
    color: #fff !important;
  }
  .tox .tox-pop__dialog {
    background-color: transparent !important;
}
.tox .tox-dialog__iframe.tox-dialog__iframe--opaque {
    background-color: transparent !important;
  }
  .tox .tox-textfield,
.tox .tox-toolbar-textfield,
.tox .tox-listboxfield .tox-listbox--select,
.tox .tox-textarea,
.tox .tox-textarea-wrap .tox-textarea:focus {
  background-color: transparent !important;
}
.tox .tox-textfield[disabled],
.tox .tox-textarea[disabled] {
  background-color: transparent !important;
}
.tox .tox-textfield:focus,
.tox .tox-listboxfield .tox-listbox--select:focus,
.tox .tox-textarea-wrap:focus-within,
.tox .tox-textarea:focus,
.tox .tox-custom-editor:focus-within {
  background-color: transparent !important;
  border-color: #5DD3B3 !important;
  box-shadow: 0 0 0 2px #5DD3B380 !important;
}`}

${props => props.theme === "light" &&`
*{
  font-family: "Primary Font-Medium"  !important;
}
/* menubar */
.tox .tox-menubar {
  background-color: transparent !important;
  color: #2d3741 !important;
}

/* toolbar */
.tox .tox-toolbar__primary {
  background-color: transparent !important;
  color: #2d3741 !important;
  border-bottom: 1px solid #AAB1B980 !important;
}

.tox .tox-editor-header {
  z-index: 0 !important;
}

/* select input dropdowns like paragraph and font size */
.tox .tox-tbtn--bespoke {
  background-color: #fff !important;
  color: #2d3741 !important;
  border: 1px solid #AAB1B980 !important;
}

/* line ontop the menubar and below toolbar*/
.tox:not(.tox-tinymce-inline) .tox-editor-header {
  background-color: transparent !important;
  border-top: 1px solid #AAB1B980 !important;
  // box-shadow: 0 2px 2px -2px rgba(34, 47, 62, .1), 0 8px 8px -4px rgba(34, 47, 62, .07) !important;
  padding: 1px 0 !important;
}

/* text editor container */
.tox-tinymce {
  border: 1px solid #AAB1B980 !important;
  border-radius: 0.625rem !important;
  box-shadow: none !important;
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: column !important;
  font-family: 'Primary font-Light' !important;
  overflow: hidden !important;
  position: relative !important;
  visibility: inherit !important;
  border-top: none !important;
}

/* menu bar items "file/edit/view" */
.tox .tox-mbtn {
  color: #2d3741 !important;
}

.tox .tox-mbtn:hover {
  background-color: #5DD3B3 !important;
}

.tox .tox-mbtn:hover:not(:disabled):not(.tox-mbtn--active) {
  background-color: #5DD3B3 !important;
}

.tox .tox-mbtn:focus:not(:disabled) {
  background-color: #5DD3B3 !important;
}

.tox .tox-mbtn--active {
  background: #fff !important;
}

/* tool bar items "B/I/U" */
.tox .tox-tbtn {
  color: #2d3741 !important;
}

.tox .tox-tbtn:focus {
  background: #5DD3B380 !important;
}

.tox .tox-tbtn--enabled,
.tox .tox-tbtn--enabled:hover {
  background: #5DD3B3 !important;
}

.tox .tox-tbtn--enabled:focus {
  background: #5DD3B3 !important;
}

.tox .tox-tbtn:hover {
  background-color: #5DD3B3 !important;
}

.tox .tox-tbtn--disabled:hover {
  background-color: transparent !important;
}

.tox .tox-tbtn svg {
  fill: #2d3741 !important;
}

.tox .tox-tbtn--disabled svg,
.tox .tox-tbtn--disabled:hover svg,
.tox .tox-tbtn:disabled svg,
.tox .tox-tbtn:disabled:hover svg {
  fill: #2d3741 !important;
  opacity: 0.5;
}

.tox .tox-menubar+.tox-toolbar,
.tox .tox-menubar+.tox-toolbar-overlord {
  border-top: none !important;
  border-bottom: none !important;
  margin-top: -1px !important;
  padding-bottom: 1px !important;
  padding-top: 1px !important;
}

/* toolbar */
.tox .tox-toolbar-overlord {
  background-color: transparent !important;
}

/* menu onClick item */
.tox .tox-menu {
  background-color: #fff !important;
  border: 1px solid #AAB1B980 !important;
  border-radius: 6px !important;
  // box-shadow: 0 0 2px 0 rgba(34, 47, 62, .2), 0 4px 8px 0 rgba(34, 47, 62, .15) !important;
  display: inline-block !important;
  overflow: hidden !important;
  vertical-align: top !important;
  z-index: 1150 !important;
}

.tox .tox-collection__item {
  align-items: center !important;
  border-radius: 3px !important;
  color: #2d3741 !important;
}

.tox .tox-collection__item:hover {
  background-color: #5DD3B3 !important;
  color: #2d3741 !important;
}

.tox .tox-collection--list .tox-collection__item--active {
  background-color: #576371 !important;
}

.tox .tox-collection__item-caret svg {
  fill: #2d3741 !important;
}

.tox .tox-collection__item-accessory {
  color: #ffffff80 !important;
}

.tox .tox-collection--list .tox-collection__item--enabled {
  background-color: #576371 !important;
}

/* backdrop popup */
.tox .tox-dialog-wrap__backdrop {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.tox .tox-dialog__header {
  background-color: #fff !important;
  color: #2d3741 !important;
}

.tox .tox-button--icon .tox-icon svg,
.tox .tox-button.tox-button--icon .tox-icon svg,
.tox .tox-button.tox-button--secondary.tox-button--icon .tox-icon svg {
  fill: #5DD3B3 !important;
}

.tox .tox-dialog__body-content {
  background-color: #fff !important;
}

.tox .tox-listboxfield .tox-listbox--select,
.tox .tox-textarea,
.tox .tox-textarea-wrap .tox-textarea:focus,
.tox .tox-textfield,
.tox .tox-toolbar-textfield {
  background-color: transparent !important;
  border-color: #AAB1B980 !important;
  color: #2d3741 !important;
  font-family: 'Primary font-Light' !important;
}

.tox .tox-label,
.tox .tox-toolbar-label {
  color: #2d3741 !important;
}

.tox .tox-listbox__select-label {
  color: #ffffff80 !important;
}

.tox .tox-listbox__select-chevron svg {
  fill: #ffffff80  !important;
}

.tox .tox-dialog__footer {
  background-color: #fff !important;
}

.tox .tox-button {
  background-color: #5DD3B3 !important;
  border-color: #AAB1B980 !important;
  color: #2d3741 !important;
  border-radius: 1.25rem !important;
}

.tox .tox-button--secondary {
  background-color: transparent !important;
  border-color: #D85D66 !important;
  color: #D85D66 !important;
  border-radius: 1.25rem !important;
}

.tox .tox-button--naked {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: unset !important;
  color: #5DD3B3 !important;
}

.tox .tox-dialog__body-nav {
  background-color: #fff !important;
}

.tox .tox-dialog__body-nav-item {
  color: #2d3741 !important;
}

.tox .tox-dialog__body-nav-item--active {
  border-bottom: 2px solid #5DD3B3 !important;
  color: #5DD3B3 !important;
}

.tox .tox-insert-table-picker .tox-insert-table-picker__selected {
  background-color: #5DD3B380 !important;
  border-color: #5DD3B380 !important;
}

.tox.tox-tinymce-aux .tox-toolbar__overflow {
  background-color: #fff !important;
}

.tox .tox-edit-area__iframe {
  background-color: transparent !important;
  color: #fff;
}

.tox .tox-dropzone {
  background: transparent !important;
  border: 2px dashed #5DD3B3 !important;
}
.tox .tox-dropzone p {
  color: #2d3741 !important;
}
.tox .tox-promotion {
  display: none !important;
}
.tox .tox-collection--list .tox-collection__item--active {
  background-color: #5DD3B380 !important;
}
.tox .tox-collection--toolbar .tox-collection__item--enabled {
  background-color: #5DD3B3 !important;
  color: #2d3741 !important;
}
.tox .tox-collection--toolbar .tox-collection__item--active {
  background-color: #5DD3B380 !important;
}
.tox .tox-collection--grid .tox-collection__item--enabled {
  background-color: #5DD3B3 !important;
  color: #2d3741 !important;
}
.tox .tox-collection--grid .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
  background-color: #5DD3B380 !important;
  color: #2d3741 !important;
}
.tox .tox-pop__dialog {
  background-color: transparent !important;
}
.tox .tox-dialog__iframe.tox-dialog__iframe--opaque {
  background-color: transparent !important;
}
.tox .tox-textfield,
.tox .tox-toolbar-textfield,
.tox .tox-listboxfield .tox-listbox--select,
.tox .tox-textarea,
.tox .tox-textarea-wrap .tox-textarea:focus {
background-color: transparent !important;
}
.tox .tox-textfield[disabled],
.tox .tox-textarea[disabled] {
background-color: transparent !important;
}
.tox .tox-textfield:focus,
.tox .tox-listboxfield .tox-listbox--select:focus,
.tox .tox-textarea-wrap:focus-within,
.tox .tox-textarea:focus,
.tox .tox-custom-editor:focus-within {
background-color: transparent !important;
border-color: #5DD3B3 !important;
box-shadow: 0 0 0 2px #5DD3B380 !important;
}
`}

${props => props.haserror==="true" &&`
.tox-tinymce {
    border-color: #D85D66 !important;
  }
.tox:not(.tox-tinymce-inline) .tox-editor-header {
  border-color: #D85D66 !important;
  }
  .tox .tox-toolbar__primary {
    border-bottom: 1px solid #D85D66 !important;
}
`}
${props => props.readonly && props.theme === "dark" && `
.tox .tox-menubar {
  background-color: #546270 !important;
}
.tox .tox-toolbar__primary {
  background-color: #546270 !important;
  border-bottom: 1px solid #fffff80 !important;
}
.tox:not(.tox-tinymce-inline) .tox-editor-header {
  background-color: #546270  !important;
}
`}

${props => props.readonly && props.theme === "light" && `
.tox .tox-menubar {
  background-color: #F7F7F8 !important;
}
.tox .tox-toolbar__primary {
  background-color: #F7F7F8 !important;
  border-bottom: 1px solid #fffff80 !important;
}
.tox:not(.tox-tinymce-inline) .tox-editor-header {
  background-color: #F7F7F8  !important;
}
`}
`
const loading = keyframes`
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
`;

export const EditorLoading = styled.div<TextEditProps>`
  border-radius: 0.625rem;
  height: ${({ height }) => (height ? height : "13.25rem")};
  width: 100%;
  background: ${(props) =>
    props.theme === 'light'
      ? 'linear-gradient(90deg, #e0e0e0 25%, #ffffff80 50%, #e0e0e0 75%)'
      : 'linear-gradient(90deg, #546270 25%, #ffffff80 50%, #546270 75%)'};
  background-size: 200% 100%;
  animation: ${loading} 2s infinite ease-in-out;
`;
