import React, { createContext, useContext, useState, ReactNode } from "react";
import { SchemeGroupInterface } from "../components/schemeGroup/schemeGroupInterface";
import {
  GroupCardDataInterface,
  GroupStudent,
} from "../components/groupCardNew/groupCardNewInterface";
import { AllGroupMembersData } from "../components/allMembersGroups/allGroupMemberBarData";
import { ContentProps } from "../Views/teacherView/Course-Creator/courseCreatorInterface";

interface ContextProps {
  contentID: number | undefined;
  moduleID: number | undefined;
  student_ID: number | string | undefined;
  student__ID: number | string | undefined;
  scheme_ID: number | string | undefined;
  topicRefId: number | undefined;
  content_Index: number | undefined;
  contentArrayLength: number;
  contentIdToEdit: number | undefined;
  contentTitle: string;
  schemeTitle: string;
  AnalyticsNumberOfUsersTable: number;
  SetAnalyticsNumberOfUsersTable: React.Dispatch<React.SetStateAction<number>>;
  schemeDescription: string;
  colorGroup: string;
  sourceIdContent: number | undefined;
  sourceIdTopic: number | undefined;
  contentData: any;
  contentType: any;

  bigLineColor: string;
  smallLineColor: string;
  rectangleColor: string;

  bigLineBorder: string;
  smallLineBorder: string;
  rectangleBorder: string;

  certificateDate: Date | null;
  studentName: string;
  pathNameState: string;
  leftName: string;
  rightName: string;
  leftPosition: string;
  rightPosition: string;
  eventContentId: string;
  SetEventContentId: React.Dispatch<React.SetStateAction<string>>;
  StudentQuizCLassAverage: number;
  setStudentQuizCLassAverage: React.Dispatch<React.SetStateAction<number>>;
  isPopUpOpen: boolean;
  popupOverlay: boolean;
  showEditContentPositionLoader: boolean;
  showEditTopicPositionLoader: boolean;
  courseName: string;
  subGroupNum: number | undefined;
  studentPerSubGroup: number | undefined;
  groups: GroupCardDataInterface[];
  schemes: SchemeGroupInterface[];
  GlobalClassId: any;
  SetGlobalClassId: React.Dispatch<React.SetStateAction<any>>;
  setSchemes: React.Dispatch<React.SetStateAction<SchemeGroupInterface[]>>;
  setPopupOverlay: React.Dispatch<React.SetStateAction<boolean>>;
  setGroups: React.Dispatch<React.SetStateAction<GroupCardDataInterface[]>>;
  globalShowEditContent: boolean;
  setIsPopUpOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEditContentPositionLoader: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setShowEditTopicPositionLoader: React.Dispatch<React.SetStateAction<boolean>>;
  isContentDrop: boolean;
  isChangeColorShown: boolean;
  isBigLineColorShown: boolean;
  isSmallLineColorShown: boolean;
  isRectangleColorShown: boolean;
  setIsContentDrop: React.Dispatch<React.SetStateAction<boolean>>;
  setIsChangeColorShown: React.Dispatch<React.SetStateAction<boolean>>;

  setIsBigLineColorShown: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSmallLineColorShown: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRectangleColorShown: React.Dispatch<React.SetStateAction<boolean>>;

  setBigLineColor: React.Dispatch<React.SetStateAction<string>>;
  setSmallLineColor: React.Dispatch<React.SetStateAction<string>>;
  setRectangleColor: React.Dispatch<React.SetStateAction<string>>;
  setSourceIdContent: React.Dispatch<React.SetStateAction<number | undefined>>;
  setSourceIdTopic: React.Dispatch<React.SetStateAction<number | undefined>>;

  setBigLineBorder: React.Dispatch<React.SetStateAction<string>>;
  setSmallLineBorder: React.Dispatch<React.SetStateAction<string>>;
  setRectangleBorder: React.Dispatch<React.SetStateAction<string>>;

  setCertificateDate: React.Dispatch<React.SetStateAction<Date | null>>;

  setStudentName: React.Dispatch<React.SetStateAction<string>>;
  setPathNameState: React.Dispatch<React.SetStateAction<string>>;
  setLeftName: React.Dispatch<React.SetStateAction<string>>;
  setRightName: React.Dispatch<React.SetStateAction<string>>;
  setLeftPosition: React.Dispatch<React.SetStateAction<string>>;
  setRightPosition: React.Dispatch<React.SetStateAction<string>>;
  DashboardAnnouncementsCurrentPage: number;
  setDashboardAnnouncementsCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;
  isTopicDragging: boolean;
  ungrouped_students: GroupStudent[];
  setUngrouped_students: React.Dispatch<React.SetStateAction<GroupStudent[]>>;
  setIsTopicDragging: React.Dispatch<React.SetStateAction<boolean>>;
  setContentID: React.Dispatch<React.SetStateAction<number | undefined>>;
  setModuleID: React.Dispatch<React.SetStateAction<number | undefined>>;
  setStudent_ID: React.Dispatch<
    React.SetStateAction<number | string | undefined>
  >;
  setStudent__ID: React.Dispatch<
    React.SetStateAction<number | string | undefined>
  >;
  setScheme_ID: React.Dispatch<
    React.SetStateAction<number | string | undefined>
  >;
  setSubGroupNum: React.Dispatch<React.SetStateAction<number | undefined>>;
  setStudentPerSubGroup: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  setTopicRefId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setContent_Index: React.Dispatch<React.SetStateAction<number | undefined>>;
  setContentArrayLength: React.Dispatch<React.SetStateAction<number>>;
  setContentIdToEdit: React.Dispatch<React.SetStateAction<number | undefined>>;
  setContentTitle: React.Dispatch<React.SetStateAction<string>>;
  setSchemeTitle: React.Dispatch<React.SetStateAction<string>>;
  setSchemeDescription: React.Dispatch<React.SetStateAction<string>>;
  setColorGroup: React.Dispatch<React.SetStateAction<string>>;
  setCourseName: React.Dispatch<React.SetStateAction<string>>;
  setContentType: React.Dispatch<React.SetStateAction<string>>;
  setContentData: React.Dispatch<React.SetStateAction<any>>;
  setContent_Id: (content_Id: number) => void;
  setContent_IdToEdit: (content_Id: number) => void;
  setContent_Title: (content_Title: string) => void;
  setContent_Type: (content_Title: string) => void;
  setIsShownChangeColor: () => void;
  setContent_Data: (content_Data: any, id: number) => void;
  getContent_Data: (id: number | undefined, type: string) => any;
  getStoredFileNames: (id: number | undefined, type: string) => any;
  getModules: () => any;
  handle_SelectFile: (files: FileList | null) => any;
  setGlobalShowEditContent: React.Dispatch<React.SetStateAction<boolean>>;
  setContentGlobalState: React.Dispatch<
    React.SetStateAction<ContentProps | null>
  >;
  contentGlobalState: ContentProps | null;
}

const StateContext = createContext<ContextProps | undefined>(undefined);

export const ContextProvider = ({ children }: { children: ReactNode }) => {
  const [contentID, setContentID] = useState<number | undefined>(undefined);
  const [popupOverlay, setPopupOverlay] = useState<boolean>(false);
  const [moduleID, setModuleID] = useState<number | undefined>(undefined);
  const [student_ID, setStudent_ID] = useState<number | string | undefined>(
    undefined
  );
  const [student__ID, setStudent__ID] = useState<number | string | undefined>(
    undefined
  );
  const [StudentQuizCLassAverage, setStudentQuizCLassAverage] =
    useState<number>(0);
  const [scheme_ID, setScheme_ID] = useState<number | string | undefined>(
    undefined
  );
  const [topicRefId, setTopicRefId] = useState<number | undefined>(undefined);
  const [content_Index, setContent_Index] = useState<number | undefined>(
    undefined
  );
  const [
    DashboardAnnouncementsCurrentPage,
    setDashboardAnnouncementsCurrentPage,
  ] = useState(1);
  const [showEditContentPositionLoader, setShowEditContentPositionLoader] =
    useState<boolean>(false);
  const [showEditTopicPositionLoader, setShowEditTopicPositionLoader] =
    useState<boolean>(false);
  const [sourceIdContent, setSourceIdContent] = useState<number | undefined>(
    undefined
  );
  const [sourceIdTopic, setSourceIdTopic] = useState<number | undefined>(
    undefined
  );

  const [contentArrayLength, setContentArrayLength] = useState<number>(0);
  const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);
  const [isContentDrop, setIsContentDrop] = useState<boolean>(false);
  const [isTopicDragging, setIsTopicDragging] = useState<boolean>(false);
  const [isChangeColorShown, setIsChangeColorShown] = useState<boolean>(false);

  const [isBigLineColorShown, setIsBigLineColorShown] =
    useState<boolean>(false);
  const [isSmallLineColorShown, setIsSmallLineColorShown] =
    useState<boolean>(false);
  const [isRectangleColorShown, setIsRectangleColorShown] =
    useState<boolean>(false);

  const [contentIdToEdit, setContentIdToEdit] = useState<number | undefined>(
    undefined
  );
  const [contentType, setContentType] = useState<string>("");
  const [courseName, setCourseName] = useState<string>("");
  const [contentTitle, setContentTitle] = useState<string>("");
  const [schemeTitle, setSchemeTitle] = useState<string>("");
  const [schemeDescription, setSchemeDescription] = useState<string>("");
  const [colorGroup, setColorGroup] = useState<string>("#5DD3B3");
  const [contentData, setContentData] = useState<any>({});

  const [subGroupNum, setSubGroupNum] = useState<number>();
  const [studentPerSubGroup, setStudentPerSubGroup] = useState<number>();
  const [groups, setGroups] = useState<GroupCardDataInterface[]>([]);

  const [schemes, setSchemes] = useState<SchemeGroupInterface[]>([]);
  const [ungrouped_students, setUngrouped_students] = useState<GroupStudent[]>(
    []
  );
  const [globalShowEditContent, setGlobalShowEditContent] =
    useState<boolean>(false);

  const [AnalyticsNumberOfUsersTable, SetAnalyticsNumberOfUsersTable] =
    useState<number>(0);
  const [GlobalClassId, SetGlobalClassId] = useState<any>();

  const [eventContentId, SetEventContentId] = useState<string>("");

  const setIsShownChangeColor = () => {
    setIsChangeColorShown(!isChangeColorShown);
  };

  const setContent_Title = (content_Title: string) => {
    setContentTitle(content_Title);
    localStorage.setItem("contentTitle", content_Title);
  };

  const setContent_Id = (content_Id: number) => {
    setContentID(content_Id);
    localStorage.setItem("contentId", JSON.stringify(content_Id));
  };

  const setContent_IdToEdit = (content_IdToEdit: number) => {
    setContentIdToEdit(content_IdToEdit);
    localStorage.setItem("contentIdToEdit", JSON.stringify(content_IdToEdit));
  };

  const setContent_Type = (content_Type: string) => {
    setContentType(content_Type);

    localStorage.setItem("contentType", content_Type);
  };

  const setContent_Data = (content_Data: any, id: number) => {
    // setContentData(JSON.stringify(content_Data));
    localStorage.setItem(`${id}`, JSON.stringify(content_Data));
  };

  const getContent_Data = (id: number | undefined, type: string) => {
    if (id === contentIdToEdit && type === contentType) {
      const storedData = localStorage.getItem(JSON.stringify(id));
      if (storedData) {
        return JSON.parse(storedData);
      }
    }
    return {};
  };

  const getModules = () => {
    const storedModules = localStorage.getItem("modules");
    if (storedModules) {
      return JSON.parse(storedModules);
    } else return [];
  };

  // When selecting a file, store its names in local storage
  const handle_SelectFile = (files: FileList | null) => {
    if (files) {
      const fileNames = Array.from(files).map((file) => file.name);
      localStorage.setItem("fileNames", JSON.stringify(fileNames));
    }
  };

  // When retrieving the file names
  const getStoredFileNames = (id: number | undefined, type: string) => {
    if (id === contentIdToEdit && type === contentType) {
      const storedFileNames = localStorage.getItem("fileNames");
      if (storedFileNames) {
        return JSON.parse(storedFileNames);
      }
    }
    return [];
  };

  const [bigLineColor, setBigLineColor] = useState<string>("#1C3643");
  const [smallLineColor, setSmallLineColor] = useState<string>("#BE852E");
  const [rectangleColor, setRectangleColor] = useState<string>("#BE852E");

  const [bigLineBorder, setBigLineBorder] = useState<string>(`20`);
  const [smallLineBorder, setSmallLineBorder] = useState<string>(`10`);
  const [rectangleBorder, setRectangleBorder] = useState<string>(`1`);

  const [certificateDate, setCertificateDate] = useState<Date | null>(null);

  const [studentName, setStudentName] = useState<string>(`Student Name`);
  const [pathNameState, setPathNameState] = useState<string>(
    window.location.pathname
  );
  const [leftName, setLeftName] = useState<string>(``);
  const [rightName, setRightName] = useState<string>(``);
  const [leftPosition, setLeftPosition] = useState<string>(``);
  const [rightPosition, setRightPosition] = useState<string>(``);

  const [contentGlobalState, setContentGlobalState] =
    useState<ContentProps | null>(null);

  return (
    <StateContext.Provider
      value={{
        eventContentId,
        pathNameState,
        setPathNameState,
        SetEventContentId,
        contentID,
        moduleID,
        contentTitle,
        schemeTitle,
        schemeDescription,
        contentType,
        contentData,
        contentIdToEdit,
        isPopUpOpen,
        isContentDrop,
        isTopicDragging,
        contentArrayLength,
        popupOverlay,
        setPopupOverlay,
        courseName,
        StudentQuizCLassAverage,
        topicRefId,
        content_Index,
        sourceIdTopic,
        setSourceIdTopic,
        isChangeColorShown,
        colorGroup,
        subGroupNum,
        studentPerSubGroup,
        schemes,
        groups,
        sourceIdContent,
        setSourceIdContent,
        DashboardAnnouncementsCurrentPage,
        setDashboardAnnouncementsCurrentPage,
        globalShowEditContent,
        ungrouped_students,
        showEditContentPositionLoader,
        setShowEditContentPositionLoader,
        student_ID,
        scheme_ID,
        AnalyticsNumberOfUsersTable,
        GlobalClassId,
        student__ID,
        SetGlobalClassId,
        setScheme_ID,
        setStudent_ID,
        setModuleID,
        isBigLineColorShown,
        isSmallLineColorShown,
        isRectangleColorShown,
        setBigLineColor,
        setSmallLineColor,
        setRectangleColor,
        bigLineColor,
        smallLineColor,
        rectangleColor,
        bigLineBorder,
        smallLineBorder,
        rectangleBorder,
        certificateDate,
        studentName,
        leftName,
        setLeftName,
        showEditTopicPositionLoader,
        setShowEditTopicPositionLoader,
        rightName,
        setRightName,
        leftPosition,
        setLeftPosition,
        rightPosition,
        setRightPosition,
        setStudentName,
        setCertificateDate,
        setBigLineBorder,
        setSmallLineBorder,
        setRectangleBorder,
        setIsBigLineColorShown,
        setIsSmallLineColorShown,
        setIsRectangleColorShown,
        setStudent__ID,
        setUngrouped_students,
        setGroups,
        setSchemes,
        setSchemeDescription,
        setSchemeTitle,
        setStudentQuizCLassAverage,
        setGlobalShowEditContent,
        setSubGroupNum,
        setStudentPerSubGroup,
        setColorGroup,
        setIsChangeColorShown,
        setIsShownChangeColor,
        setTopicRefId,
        setContent_Index,
        setContentArrayLength,
        setIsContentDrop,
        setIsTopicDragging,
        setIsPopUpOpen,
        setContentID,
        setContentTitle,
        setContentType,
        setContent_Id,
        setContent_Title,
        setContent_Type,
        setContentData,
        setContent_Data,
        getContent_Data,
        handle_SelectFile,
        getStoredFileNames,
        setContentIdToEdit,
        setContent_IdToEdit,
        getModules,
        setCourseName,
        SetAnalyticsNumberOfUsersTable,
        setContentGlobalState,
        contentGlobalState,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): ContextProps => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a ContextProvider");
  }
  return context;
};
