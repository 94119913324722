import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const MainContainer=styled.div`


`

export const Profileimg=styled.img`
border-radius:50%;
width:6.25rem;
height:6.25rem;
`

export const FlexedDiv=styled.div`
display:flex;
align-items:center;
justify-content:start;
gap:2rem;
`
export const LowerContainer=styled.div`
margin-top:2rem;
margin-bottom:1rem;


`

export const ParticipantContainer=styled.div<StyleThemeInterface>`
margin-top:0.75rem;
height:5rem;
width:40rem;
border-radius: 0.625rem;
background: rgba(255, 255, 255, 0.03);
box-shadow: ${(props) => props.boxshadow};
display:flex;
justify-content:start;
align-items:center;
padding:1rem;
`

export const ParticipantImg=styled.img`
width: 3.125rem;
height: 3.125rem;
border-radius:50%;


`

export const RightParticipantinfo=styled.div`
margin-left:1rem;
gap:1rem;


`