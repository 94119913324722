import { AnalyticsUserViewNewInterface } from "./AnalyticsUserViewCompointerface";


// https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/enrollments/byUser/all/${tenantName}/${subIdSplitted}

// const res = await axios.get(
//   `${BaseURI}/about/class/${classSourceId}/${tenantName}`
// );

export const AnalyticsUserViewNewData: AnalyticsUserViewNewInterface[] =[
  {id:"1",
  role:"teacher",
      CourseName: "Business and Finance",
      TermSemester: "Fall 2022/2023",
      StartDate: "9 Aug, 2023 | 8:00 AM",
      CloseDate: "26 Aug, 2023 | 8:00 AM",
      Status: "In progress",
      CourseAverageGrade: "-",
    
    },
    {
      id: "2",
      role:"teacher",
      CourseName: "Computer Science",
      TermSemester: "Fall 2022/2023",
      StartDate: "15 Aug, 2023 | 9:30 AM",
      CloseDate: "30 Aug, 2023 | 9:30 AM",
      Status: "In progress",
      CourseAverageGrade: "-",
    },
    {
      id: "3",
      role:"teacher",
      CourseName: "Marketing Strategies",
      TermSemester: "Fall 2022/2023",
      StartDate: "12 Aug, 2023 | 10:15 AM",
      CloseDate: "28 Aug, 2023 | 10:15 AM",
          Status: "Completed",
      CourseAverageGrade: "A",
    },
    

  {
    id: "6",
    role:"student",
    CourseName: "Business and Finance",
    TermSemester: "Fall 2022/2023",
    StartDate: "9 Aug, 2023 | 8:00 AM",
    CloseDate: "26 Aug, 2023 | 8:00 AM",
        Status: "In progress",
      CourseAverageGrade: "",
  },

]