// This component is responsible for rendering a default table for analytics with pagination, filtering, and sorting capabilities.

import React, { useState } from "react";
import {
  // Importing styled components for styling
  AQColumn,
  AQHeadTableTr,
  AQStudentCells,
  AQStudentCol,
  AQSvgMoreType,
  AQTableCol,
  AQTableTbody,
  AQTableThead,
  AQTableTr,
  AnalyticsQuizzesMainContainer,
  AnalyticsQuizzesScrollerContainer,
  AnalyticsQuizzesTable,
  AqFirstRow,
  AqMINIRow,
  AqMiniCol,
} from "../../components/AnalyticsQuizzes/AnalyticsQuizzes.style";
import { LightTypography, MediumTypography, Typography } from "../fonts/Fonts";
import { InstructorViewCompletionSearchContainer } from "../../components/StudentCompletionTrackingInstrcuterView/InstructorViewCompletion.style";
import SearchElement from "../searchElement/SearchElement";
import { SvgDownload, SvgEyeIcon, SvgGrade, SvgMore } from "../Icons";
import { LogoAndAction } from "../../components/GradesAnalyticsTable/GradesAnalyticsTable.style";
import ScrollLeftAndRight from "../ScrollLeftAndRight/ScrollLeftAndRight";
import { AQQTableTh } from "../../components/Tables/QuizQuestionsAnalytics/QuizQuestionsAnalytics.style";
import { AnalyticDefaultTableDataAction } from "./AnalyticDefaultTableDataAction";
import {
  ADTSupperMainContainer,
  ADTTableTd1,
  ADTTableThDetails,
} from "./AnalyticDefaultTable.style";
import Pagination from "../Pationation/Pagination";
import { Link } from "react-router-dom";
import TableViewSelectInput from "../../components/AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/TableViewSelectInput";
import {
  ViewsOptions,
  handleNumberDisplays,
} from "../../utils/NbOfViewesObjects";

// Interface defining the props for the component
interface AnalyticDefaultTableInterface {
  title: string;
  MappedHeadText: any;
  onClickExcelDownload?: () => void; // Callback function for Excel download
  DatabaseTableName: any[]; // Array of data to be displayed in the table
  HeadSubTitleArray?: any[]; // Array of subheadings for table headers
  StdName?: string; // Student name label
  LinkedText?: string; //the Text that well placed before the code in the Text
}

// Main component function
export const AnalyticDefaultTable = ({
  title,
  onClickExcelDownload,
  DatabaseTableName,
  LinkedText,
  MappedHeadText,
  StdName = "STUDENT NAME",
  HeadSubTitleArray = AnalyticDefaultTableDataAction, // Default subheadings
}: AnalyticDefaultTableInterface): JSX.Element => {
  // State variables for managing pagination, search, and view options
  const uniqueNames = new Set(); // Set for storing unique student names
  const [ViewText, setViewText] = useState("View All"); // Text for view options

  const [ShowPagination, setShowPagination] = useState(false); // Flag for showing pagination
  const [limit, setLimit] = useState(DatabaseTableName.length); // Limit for items per page
  const [searchText, setSearchText] = useState(""); // Search text input

  const handleNumberShows = (newValue: string) => {
    handleNumberDisplays(
      newValue,
      setViewText,
      setLimit,
      filteredData,
      setShowPagination
    );
  };

  // Variables for pagination
  const itemsPerPage = limit;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Filtering data based on search text and pagination
  const filteredData = DatabaseTableName.map((item) => {
    const filteredStudents = (item.students || [])
      .filter((student: { StdName: string }) =>
        student?.StdName?.toLowerCase().includes(searchText.toLowerCase())
      )
      .slice(startIndex, endIndex);

    // Including the main object only if there are matching students
    if (filteredStudents.length > 0) {
      return {
        ...item,
        students: filteredStudents,
      };
    }
    return null; // Excluding objects without matching students
  }).filter(Boolean);

  return (
    <ADTSupperMainContainer>
      <AqFirstRow>
        <AqMiniCol>
          <Typography text={title} fontSize="1.75rem" />
        </AqMiniCol>
        <AqMiniCol>
          <InstructorViewCompletionSearchContainer>
            <SearchElement onChange={(e) => setSearchText(e.target.value)} />
          </InstructorViewCompletionSearchContainer>
          <AqMINIRow>
            <TableViewSelectInput
              value={ViewText}
              options={ViewsOptions}
              onChange={handleNumberShows}
            />
            <LogoAndAction onClick={onClickExcelDownload}>
              <SvgDownload />
              <Typography text="Download" fontSize="0.75rem" />
            </LogoAndAction>
          </AqMINIRow>
        </AqMiniCol>
      </AqFirstRow>
      <AQColumn>
        <AnalyticsQuizzesMainContainer>
          <AQStudentCol NormalWidth="17.5%">
            <AQStudentCells />
            <AQStudentCells>
              <Typography text={StdName} fontSize="1rem" />
            </AQStudentCells>
            {/* Rendering filtered student names */}
            {filteredData.map(
              (val) =>
                val?.students &&
                val.students.map(
                  (
                    stdName: { StdCode: unknown; StdName: any },
                    Qindex: any
                  ) => {
                    if (!uniqueNames.has(stdName?.StdCode)) {
                      uniqueNames.add(stdName?.StdCode);
                      return (
                        <AQStudentCells key={Qindex}>
                          <Typography
                            text={stdName?.StdName || ""}
                            fontSize="0.875rem"
                          />
                        </AQStudentCells>
                      );
                    }
                    return null;
                  }
                )
            )}
          </AQStudentCol>
          <ScrollLeftAndRight
            Width="82.4%"
            SmallAdditionalWidth="79%"
            TabletAdditionalWidth="70%"
            paddingRight="10rem"
            LineWidth="60%"
            AlignSelf="flex-end"
            // MarginTop="1rem"
            content={
              <AnalyticsQuizzesScrollerContainer>
                <AnalyticsQuizzesTable>
                  <AQTableThead>
                    {/* Rendering table headers */}
                    {DatabaseTableName.map((Qval, Qindex) => (
                      <AQTableCol key={Qindex}>
                        {/* <Link to={`${LinkedText}${Qval.MainCode}`}> */}
                        <Link to={`${Qval.MainCode}`}>
                          <AQQTableTh
                            style={{
                              height: "3.3rem",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <MediumTypography
                                text={
                                  MappedHeadText && MappedHeadText[Qindex]
                                    ? MappedHeadText[Qindex]
                                    : ""
                                }
                                lineHeight="1.75rem"
                                fontSize="1.125rem"
                              />
                            </div>
                            <AQSvgMoreType>
                              <SvgMore />
                            </AQSvgMoreType>
                          </AQQTableTh>
                        </Link>
                        <AQHeadTableTr>
                          {/* Rendering subheadings */}
                          {HeadSubTitleArray.map((Pro) => (
                            <ADTTableThDetails key={Pro.Text}>
                              <Typography text={Pro.Text} fontSize="1rem" />
                            </ADTTableThDetails>
                          ))}
                        </AQHeadTableTr>
                      </AQTableCol>
                    ))}
                  </AQTableThead>

                  <div style={{ display: "flex" }}>
                    {/* Rendering table body */}
                    {filteredData.map((val) => (
                      <AQTableTbody key={val?.QuestionCode}>
                        {val?.students &&
                          val.students.map(
                            (
                              student: {
                                Status: string;
                                DueDate: string;
                                TimeSpent: any;
                                Answered: any;
                                Grade: any;
                              },
                              index: React.Key | null | undefined
                            ) => (
                              <AQTableTr key={index}>
                                <ADTTableTd1>
                                  <LightTypography
                                    text={
                                      (
                                        student?.DueDate as unknown as Date
                                      )?.toLocaleString("en-GB", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "2-digit",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      }) || ""
                                    }
                                    fontSize="0.75rem"
                                  />
                                </ADTTableTd1>
                                <ADTTableTd1>
                                  <LightTypography
                                    text={student?.Status || ""}
                                    fontSize="0.75rem"
                                  />
                                </ADTTableTd1>
                                <ADTTableTd1>
                                  <LightTypography
                                    text={student?.Grade || "0"}
                                    fontSize="0.75rem"
                                  />
                                </ADTTableTd1>
                              </AQTableTr>
                            )
                          )}
                      </AQTableTbody>
                    ))}
                  </div>
                </AnalyticsQuizzesTable>
              </AnalyticsQuizzesScrollerContainer>
            }
          />
        </AnalyticsQuizzesMainContainer>
      </AQColumn>
      {/* Pagination component */}
      {ShowPagination && (
        <Pagination
          currentPage={currentPage}
          total={DatabaseTableName.length}
          limit={itemsPerPage}
          onPageChange={(page: number) => setCurrentPage(page)}
          MarginTop="-1.7rem"
          justifyContent="left"
          Padding="0 0 0 5.5rem"
          BoxSize="1.85rem"
          ScrollTop={false}
          wholeWidth="40%"
        />
      )}
    </ADTSupperMainContainer>
  );
};
