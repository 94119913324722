import React from "react";
import { ActiveDot, IconHolderActive, VerticalLine } from "./ActiveIcon.style";
import { Typography } from "../fonts/Fonts";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

interface ActiveIconProps {
  iconActive: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  text: string;
}

export default function ActiveIcon({
  iconActive: IconActive,
  text,
}: ActiveIconProps) {
  const { themeTextColor } = useRecoilValue(ColorSelector);
  return (
    <div
      style={{
        // backgroundColor: "blue",
        display: "flex",
        width: "1.8rem",
        // height: "max-content",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "0.2rem",
      }}
    >
      <IconHolderActive>
        <IconActive />
      </IconHolderActive>
      <VerticalLine></VerticalLine>
      <ActiveDot></ActiveDot>
      <div
        style={{
          width: "8rem",
          // backgroundColor: "red",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography fontSize="0.875rem" text={text} color={themeTextColor} />
      </div>
    </div>
  );
}
