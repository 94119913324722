import { MouseEvent, useState } from "react";
import { StudentData, Activities } from "./NewGradeBookTableData";
import * as XLSX from "xlsx";
import { GradebookData } from "./NewVersion/NewGradebookData";
import axios from "axios";

interface TransformedData {
  students: {
    id: string;
    name: string;
    attendance: {
      score: number;
      weight: number;
    }[];
    activities: {
      id: string;
      name: string;
      grades: {
        name: string;
        id: string;
        score: number;
        weight: number;
      }[];
    }[];
  }[];
  MainActivity: {
    id: string;
    name: string;
    weight: any;
  }[];
}

export const calculateWeightedScore = (
  studentId: string,
  activityId: string,
  GradebookData: { MainActivity: any[]; students: any[] } | undefined,
  filters: {
    id: number;
    activityType: string;
    classSourceId: string;
    filter: string;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
  }[]
): number => {
  const activity = GradebookData?.MainActivity.find(
    (act: { id: string }) => act.id === activityId
  );
  if (!activity) return 0;

  // Find the appropriate filter for the activity type
  const activityFilter = filters.find(
    (filter) => filter.activityType === activity.name
  );
  const filterType = activityFilter?.filter || "manual"; // Default to 'manual' if no filter found

  const totalWeight = 100;

  // Find the student
  const student = GradebookData?.students.find(
    (student: { id: string }) => student.id === studentId
  );
  if (!student) return 0;

  // Get grades for the specified activity for the student
  const studentActivity = student.activities.find(
    (act: { id: string }) => act.id === activityId
  );
  if (!studentActivity) return 0;

  // Apply the filter to the grades
  let grades = studentActivity.grades;
  if (filterType === "maximum") {
    const maxGrade = Math.max(
      ...grades.map((grade: { score: any }) => grade.score)
    );
    grades = grades.filter(
      (grade: { maxGrade: number; score: number }) =>
        (grade.score * 100) / grade.maxGrade === maxGrade
    );
    return maxGrade;
  } else if (filterType === "lowest") {
    const minGrade = Math.min(
      ...grades.map((grade: { score: any }) => grade.score)
    );
    grades = grades.filter(
      (grade: { score: number }) => grade.score !== minGrade
    );

    return (
      grades.reduce(
        (
          sum: any,
          grade: {
            maxGrade: number;
            score: any;
          }
        ) => sum + (grade.score * 100) / grade.maxGrade,
        0
      ) / grades.length || 0
    );
  } else if (filterType === "average") {
    const averageScore =
      grades.reduce(
        (
          sum: any,
          grade: {
            maxGrade: number;
            score: any;
          }
        ) => sum + (grade.score * 100) / grade.maxGrade,
        0
      ) / grades.length;
    grades = [{ score: averageScore, weight: totalWeight }];

    return averageScore; // Replace grades with a single average grade
  } else {
    // If filterType is 'manual', keep grades as they are

    // Calculate the weighted sum of scores
    const weightedSum = grades.reduce(
      (
        sum: number,
        grade: {
          maxGrade: number;
          weight: number;
          score: number;
        }
      ) => {
        // Find the weight of the grade
        const gradeWeight = grade.weight ?? 0;

        // Add the weighted score to the sum
        return (
          sum + ((grade.score * 100) / grade.maxGrade) * (gradeWeight / 100)
        ); // Assuming weight is in percentage
      },
      0
    );

    // Calculate the weighted average
    const weightedAverage = (weightedSum / totalWeight) * 100; // Convert to percentage
    return weightedAverage;
  }
};

export const calculateManualWeightedScore = (
  studentId: string,
  activityId: string,
  GradebookData: { MainActivity: any[]; students: any[] } | undefined
): number => {
  const activity = GradebookData?.MainActivity.find(
    (act: { id: string }) => act.id === activityId
  );
  if (!activity) return 0;

  const totalWeight = 100;

  // Find the student
  const student = GradebookData?.students.find(
    (student: { id: string }) => student.id === studentId
  );
  if (!student) return 0;

  // Get grades for the specified activity for the student
  const studentActivity = student.activities.find(
    (act: { id: string }) => act.id === activityId
  );
  if (!studentActivity) return 0;

  const grades = studentActivity.grades;

  // Calculate the weighted sum of scores
  const weightedSum = grades.reduce(
    (sum: number, grade: { weight: number; score: number }) => {
      // Find the weight of the grade
      const gradeWeight = grade.weight ?? 0;

      // Add the weighted score to the sum
      return sum + grade.score * (gradeWeight / 100); // Assuming weight is in percentage
    },
    0
  );

  // Calculate the weighted average
  const weightedAverage = (weightedSum / totalWeight) * 100; // Convert to percentage
  return weightedAverage;
};

export const calculateTotalGrade = (
  studentId: string,
  GradebookData: { students: any[]; MainActivity: any[] },
  filters: any[]
): number => {
  // Ensure that GradebookData.students is defined and not empty
  if (!GradebookData?.students || GradebookData.students.length === 0) {
    return 0;
  }

  // Find the student
  const student = GradebookData.students.find(
    (student: any) => student?.id === studentId
  );
  if (!student) return 0;

  let totalGrade = 0;

  // Iterate through each activity
  GradebookData.MainActivity.forEach(
    (activity: { id: string; weight: any }) => {
      // Calculate weighted score for the activity using filters
      const weightedScore =
        (calculateWeightedScore(
          studentId,
          activity.id,
          GradebookData,
          filters
        ) *
          (activity.weight || 0)) /
        100;

      // Add weighted score to the total grade
      totalGrade += weightedScore;
    }
  );

  // Calculate weighted score for attendance
  const attendanceWeightedScore = ((student.attendance || 0) * 5) / 100;
  totalGrade += attendanceWeightedScore;

  return parseFloat(totalGrade.toFixed(2));
};

//Function to download the grades from the gradebook using xlsx
export const handleDownloadGrades = (
  GradebookData: { students: any[]; MainActivity: any[] },
  filters: any[],
  t: any
) => {
  const workbook = XLSX.utils.book_new();

  // Initialize the header row with student names
  const headerRow = [t("excel.Student")];

  // Map each activity name to its respective grades
  const activityToGrades: { [key: string]: string[] } = {};

  // Add unique activity names to the header row and track their indexes
  GradebookData?.students.forEach((student: { activities: any[] }) => {
    student.activities.forEach((activity: { id: string; grades: any[] }) => {
      if (!activityToGrades[activity.id]) {
        activityToGrades[activity.id] = [];
      }
      activity.grades.forEach((grade: { name: string }) => {
        if (!activityToGrades[activity.id].includes(grade.name)) {
          activityToGrades[activity.id].push(grade.name);
        }
      });
    });
  });

  // Add activity grades to header row and track total indexes
  const activityTotalIndexes: { [key: string]: number } = {};
  Object.keys(activityToGrades).forEach((activityId) => {
    activityToGrades[activityId].forEach((gradeName) => {
      headerRow.push(gradeName);
    });
    activityTotalIndexes[activityId] = headerRow.length;
    headerRow.push(`${t("excel.Total")} ${activityId}`);
  });

  // Add "Attendance Total" header
  headerRow.push(t("excel.AttendanceTotal"));

  // Prepare data array with the header row
  const data: any[][] = [headerRow];

  // Iterate over each student
  GradebookData?.students.forEach(
    (student: {
      id: string;
      name: any;
      activities: any[];
      attendance: number;
    }) => {
      const studentData: any[] = new Array(headerRow.length).fill("");

      // Add student name
      studentData[0] = student.name;

      // Iterate over each activity of the student
      student.activities.forEach((activity: { id: string; grades: any[] }) => {
        const gradeIndexes = activityToGrades[activity.id].map((name) =>
          headerRow.indexOf(name)
        );

        activity.grades.forEach((grade: { name: string; score: number }) => {
          const gradeIndex = headerRow.indexOf(grade.name);
          if (gradeIndex !== -1) {
            studentData[gradeIndex] = grade.score.toString();
          }
        });

        // Calculate and add total grade for the activity
        const totalGrade = calculateWeightedScore(
          student.id,
          activity.id,
          GradebookData,
          filters
        );
        const totalGradeIndex = activityTotalIndexes[activity.id];
        studentData[totalGradeIndex] = totalGrade.toString();
      });

      // Add Attendance Total
      studentData[headerRow.indexOf(t("excel.AttendanceTotal"))] =
        student.attendance.toString();

      // Push the student data to the main data array
      data.push(studentData);
    }
  );

  // Create a worksheet and append data to it
  const worksheet = XLSX.utils.aoa_to_sheet(data);

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, t("excel.Grades"));

  // Save the workbook as a file
  XLSX.writeFile(workbook, `${t("excel.Grades")}.xlsx`);
};

//End of function

//Function to import the grades from the gradebook using xlsx

export const parseExcelData = (
  excelData:
    | string
    | ArrayBuffer
    | MouseEvent<HTMLDivElement, MouseEvent>
    | null
    | undefined
) => {
  const workbook = XLSX.read(excelData, { type: "binary" });
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];
  const jsonData = XLSX.utils.sheet_to_json(worksheet);
};

//End of function

//Function to download the template of the gradebook using xlsx
// export const handleDownloadTemplate = () => {
//   const workbook = XLSX.utils.book_new();

//   // Initialize the header row with "Student" column
//   const headerRow = ["Student"];

//   // Map each activity name to its respective grades
//   const activityToGrades: { [key: string]: number[] } = {};

//   // Add unique activity names to the header row
//   const includedActivities: Set<string> = new Set();
//   GradebookData.students.forEach((student) => {
//     student.activities.forEach((activity) => {
//       if (!includedActivities.has(activity.name)) {
//         const activityTotalHeader = `${activity.name} - Total`;
//         headerRow.push(...activity.grades.map((grade) => grade.name), activityTotalHeader);
//         includedActivities.add(activity.name);
//       }
//     });
//   });

//   // Add "Attendance Total" and "Total Grades" headers
//   headerRow.push("Attendance Total", "Total Grades");

//   // Prepare data array with the header row
//   const data: any[][] = [headerRow];

//   // Add student names to the data array
//   GradebookData.students.forEach((student) => {
//     const studentData = [student.name];

//     // Add empty cells for each activity and total columns
//     includedActivities.forEach((activityName) => {
//       studentData.push("", ""); // Empty cell for activity and its total
//     });

//     // Add empty cells for "Attendance Total" and "Total Grades"
//     studentData.push("", "");

//     data.push(studentData);
//   });

//   // Create worksheet
//   const worksheet = XLSX.utils.aoa_to_sheet(data);

//   // Append worksheet to workbook
//   XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

//   // Download the workbook as a file
//   XLSX.writeFile(workbook, "template.xlsx");
// };

//End of function

//Function to import the gradebook using xlsx

export const HandleImportExcel = (file: Blob) => {
  // const reader = new FileReader();
  // reader.onload = (e) => {
  //   if (e.target && e.target.result) {
  //     const data = new Uint8Array(e.target.result);
  //     const workbook = XLSX.read(data, { type: 'array' });
  //     // Assuming the template sheet is named "Template"
  //     const templateSheet = workbook.Sheets['Template'];
  //     // Convert the template sheet to JSON
  //     const jsonData = XLSX.utils.sheet_to_json(templateSheet);
  //     // Log the JSON data
  //     console.log(jsonData);
  //   } else {
  //     console.error('Error: Unable to read file.');
  //   }
  // };
  // reader.readAsArrayBuffer(file);
};

//End of function

//Function to switch between numbers and letters for the grades inside the gradebook
export const getLetterGrade = (score: number) => {
  if (score < 40) return "D";
  else if (score >= 40 && score < 60) return "C";
  else if (score >= 60 && score < 80) return "B";
  else if (score >= 80 && score <= 100) return "A";
  else return ""; // You can customize this based on your requirements
};
//End of function

//Function to calculate the the average of grades for the student in the student view
export const calculateAverages = (arrays: any[]) => {
  const averages = [];
  for (let i = 0; i < arrays[0].length; i++) {
    const scores = arrays.map((array) => array[i]);
    const average =
      scores.reduce((total, score) => total + score, 0) / scores.length;
    averages.push(average);
  }
  return averages;
};
//End of function

//Function that calculates the finale grade for each student
export const calculateCourseFinalGrade = (student: {
  name?: string;
  id?: string;
  Assigments?: { id: number; name: string; score: number; weight: number }[];
  totalAssigment: any;
  Quizs?: { id: number; name: string; score: number; weight: number }[];
  totalQuiz: any;
  Forums?: { id: number; name: string; score: number; weight: number }[];
  totalForums: any;
}) => {
  return (
    student.totalAssigment * Activities[0].weight +
    student.totalQuiz * Activities[1].weight +
    student.totalForums * Activities[2].weight
  ).toFixed(2);
};
//End of function

//Function that calculates the average of the total grades
export const calculateAverageGrade = (students: any[]) => {
  const totalGrades = students.reduce(
    (sum: number, student: any) =>
      sum + parseFloat(calculateCourseFinalGrade(student)),
    0
  );
  return totalGrades / students.length;
};
//End of function

//Function that returns grades below average
export const viewGradesBelowAverage = () => {
  const averageGrade = calculateAverageGrade(StudentData);
  return StudentData.filter(
    (student) => parseFloat(calculateCourseFinalGrade(student)) < averageGrade
  );
};
//End of function

//Function that returns failing grades
export const viewFailingGrades = () => {
  const failingGradeThreshold = 60; // Adjust this threshold as needed
  return StudentData.filter(
    (student) =>
      parseFloat(calculateCourseFinalGrade(student)) < failingGradeThreshold
  );
};
//End of function

//Function that returns all grades
export const viewAllGrades = () => {
  return StudentData;
};
//End of function

//Function that sort the grades ascending or descending based on the score/grade(number)
export const sortArrayByIndexScore = <T>(
  array: T[],
  propertyName: string,
  index: number,
  sortOrder: "asc" | "desc",
  setSortOrder: React.Dispatch<React.SetStateAction<"asc" | "desc">>
): T[] => {
  const nextSortOrder = sortOrder === "asc" ? "desc" : "asc"; // Toggle the sort order
  setSortOrder(nextSortOrder); // Update the sort order state

  return [...array].sort((a, b) => {
    const aValue = (a as any)[propertyName]?.[index]?.score ?? 0;
    const bValue = (b as any)[propertyName]?.[index]?.score ?? 0;

    return nextSortOrder === "asc" ? aValue - bValue : bValue - aValue;
  });
};
//End of function

//Function that switches between 3 sets of data based on previous functions, failing, below average, and all grades
export const handleFilter = (
  filterType: string,
  setFilteredStudents: React.Dispatch<React.SetStateAction<any[]>>
) => {
  // Your filtering logic here
  if (filterType === "View All Grades") {
    setFilteredStudents(viewAllGrades());
  } else if (filterType === "View Grades Below Average") {
    setFilteredStudents(viewGradesBelowAverage());
  } else if (filterType === "View Failing Grades") {
    setFilteredStudents(viewFailingGrades());
  }
};
//End of function

//Function that shows attendance popup
export const handleAttendancePopup = (
  setAttendancePopUp: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setAttendancePopUp(true);
};
//End of function

//Function that sort the table by alphabetical order
export const sortTableByName = (
  setFilteredStudents: React.Dispatch<React.SetStateAction<any[]>>,
  nameSortOrder: "asc" | "desc",
  setNameSortOrder: React.Dispatch<React.SetStateAction<"asc" | "desc">>
) => {
  setFilteredStudents((prevStudents) => {
    const sortedStudents = [...prevStudents].sort((a, b) => {
      const aValue = a.name.toLowerCase();
      const bValue = b.name.toLowerCase();

      if (nameSortOrder === "asc") {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    });

    // Toggle sorting order for the next click
    setNameSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));

    return sortedStudents;
  });
};

//Functions that shows the average in the respective assignment,quiz and forum in alll of the student data
export const assignmentAverages = calculateAverages(
  StudentData.map((student) =>
    student.Assigments.map((assignment) => assignment.score)
  )
);

export const quizAverages = calculateAverages(
  StudentData.map((student) => student.Quizs.map((quiz) => quiz.score))
);

export const forumAverages = calculateAverages(
  StudentData.map((student) => student.Forums.map((forum) => forum.score))
);
//End of function

//Function that switches between modes wether it s alphabetical as grades or number as grades
export const handleSelectChangeEnrollment = (
  newValue: string,
  setNumberModel: (arg0: string) => void
) => {
  setNumberModel(newValue);
};
//End of function

//Function that finds the attendance score based on the id of the student
export const getAttendanceScore = (
  studentId: string,
  attendanceScores: any
) => {
  const studentAttendance = attendanceScores.find(
    (score: { id: string }) => score.id === studentId
  );
  return studentAttendance ? studentAttendance.score : 0;
};
//End of function

//Function  that shows the message popup
export const toggleMessageDiv = (
  studentId: any,
  setOpenMessageDivId: (arg0: (prevId: any) => any) => void
) => {
  setOpenMessageDivId((prevId) => (prevId === studentId ? null : studentId));
};
//End of function

//Function to handle searching in the gradebook table

// Assuming GradebookData.students is of type Student[]
export const handleSearch = (
  input: string,
  setSearchInput: (input: string) => void,
  studentData: any[], // Assuming Student is the correct type
  setFilteredStudents: any
) => {
  setSearchInput(input);

  // Filter students based on the search input
  const filtered = studentData.filter((student) =>
    student.name.toLowerCase().includes(input.toLowerCase())
  );

  setFilteredStudents(filtered);
};

//End of function

//Function to show the alert setting popup
export const handleAlertSettings = (
  setAlertSettingsPopUp: (arg0: boolean) => void,
  alertSettingsPopUp: any
) => {
  setAlertSettingsPopUp(!alertSettingsPopUp);
};
//End of function

//Function to handle the input change in the attendance section
export const handleAttendanceChange = (
  studentId: string,
  newScore: number,
  attendanceScores: any[],
  setAttendanceScores: (scores: any[]) => void
) => {
  const updatedScores = attendanceScores.map((score) =>
    score.id === studentId ? { ...score, score: newScore } : score
  );
  setAttendanceScores(updatedScores);
};

//End of function

export const transformData = (
  mainActivities: any[],
  gradeData: any[] | null
): TransformedData => {
  if (!mainActivities || !gradeData) {
    // Return an empty object or handle the case according to your requirements
    return { students: [], MainActivity: [] };
  }

  // Define an object to store student data
  const students: any[] = [];

  // Iterate over the grade data
  gradeData.forEach((grade: any) => {
    // Skip adding the activity if it is of type "Attendance"
    if (grade.activityType === "Attendance") {
      // Find the student in the students array
      let student = students.find((s) => s.id === grade.studentId);
      if (!student) {
        // If the student doesn't exist, create a new student object
        student = {
          id: grade.studentId,
          name: grade.student,
          attendance: grade.score, // Assign attendance score to the student object
          activities: [],
        };
        students.push(student);
      } else {
        // Update the attendance score if the student already exists
        student.attendance = grade.score;
      }
    } else {
      // Find the corresponding main activity
      const mainActivity = mainActivities.find(
        (activity) => Object.keys(activity)[0] === grade.activityType
      );

      if (!mainActivity) {
        // If there's no main activity corresponding to the grade's activity type, skip it
        return;
      }

      // Check if the student already exists in the students array
      let student = students.find((s) => s.id === grade.studentId);
      if (!student) {
        // If the student doesn't exist, create a new student object
        student = {
          id: grade.studentId,
          name: grade.student,
          attendance: 0, // Initialize attendance score to 0
          activities: [],
        };
        students.push(student);
      }

      // Add activity data
      let activity = student.activities.find(
        (a: { id: any }) => a.id === grade.activityType
      );
      if (!activity) {
        activity = {
          id: grade.activityType,
          name: grade.activityType, // Use the activity type as name for now
          grades: [],
        };
        student.activities.push(activity);
      }
      activity.grades.push({
        name: grade.activityName,
        id: `${grade.activityType}_${grade.id}`,
        score: grade.score,
        maxGrade: grade.maxGrade,
        weight: grade.weight,
        module: grade.moduleIndex,
        publish: grade.publish,
        gradedByInstructor: grade.gradedByInstructor,
      });
    }
  });

  // Define an array to store the main activities
  const mainActivityArray: { id: string; name: string; weight: any }[] = [];

  // Iterate over the main activities
  mainActivities.forEach((activityObj) => {
    const activityType = Object.keys(activityObj)[0];
    // Skip adding the main activity if its name is "Attendance" or if it's not present in the grade data
    if (
      activityType !== "Attendance" &&
      students.some((student) =>
        student.activities.some(
          (activity: { id: string }) => activity.id === activityType
        )
      )
    ) {
      const weight = activityObj[activityType].Weight;
      mainActivityArray.push({
        id: activityType,
        name: activityType,
        weight: weight,
      });
    }
  });

  // Return the transformed data
  return {
    students: students,
    MainActivity: mainActivityArray,
  };
};

export const StudenttransformData = (
  mainActivities: any[],
  gradeData: any[] | null
): TransformedData => {
  if (!mainActivities || !gradeData) {
    // Return an empty object or handle the case according to your requirements
    return { students: [], MainActivity: [] };
  }

  // Define an object to store student data
  const students: any[] = [];

  // Iterate over the grade data
  gradeData.forEach((grade: any) => {
    // Skip adding the activity if it is of type "Attendance"
    if (grade.activityType === "Attendance") {
      // Find the student in the students array
      let student = students.find((s) => s.id === grade.studentId);
      if (!student) {
        // If the student doesn't exist, create a new student object
        student = {
          id: grade.studentId,
          name: grade.student,
          attendance: grade.score, // Assign attendance score to the student object
          activities: [],
        };
        students.push(student);
      } else {
        // Update the attendance score if the student already exists
        student.attendance = grade.score;
      }
    } else {
      // Find the corresponding main activity
      const mainActivity = mainActivities.find(
        (activity) => Object.keys(activity)[0] === grade.activityType
      );

      if (!mainActivity) {
        // If there's no main activity corresponding to the grade's activity type, skip it
        return;
      }

      // Check if the student already exists in the students array
      let student = students.find((s) => s.id === grade.studentId);
      if (!student) {
        // If the student doesn't exist, create a new student object
        student = {
          id: grade.studentId,
          name: grade.student,
          attendance: 0, // Initialize attendance score to 0
          activities: [],
        };
        students.push(student);
      }

      // Add activity data
      let activity = student.activities.find(
        (a: { id: any }) => a.id === grade.activityType
      );
      if (!activity) {
        activity = {
          id: grade.activityType,
          name: grade.activityType, // Use the activity type as name for now
          grades: [],
        };
        student.activities.push(activity);
      }

      // Only push the grade if it is published
      if (grade.publish) {
        activity.grades.push({
          name: grade.activityName,
          id: `${grade.activityType}_${grade.id}`,
          score: grade.score,
          maxGrade: grade.maxGrade,
          weight: grade.weight,
          module: grade.moduleIndex,
          publish: grade.publish,
          gradedByInstructor: grade.gradedByInstructor,
        });
      }
    }
  });

  // Define an array to store the main activities
  const mainActivityArray: { id: string; name: string; weight: any }[] = [];

  // Iterate over the main activities
  mainActivities.forEach((activityObj) => {
    const activityType = Object.keys(activityObj)[0];
    // Skip adding the main activity if its name is "Attendance" or if it's not present in the grade data
    if (
      activityType !== "Attendance" &&
      students.some((student) =>
        student.activities.some(
          (activity: { id: string }) => activity.id === activityType
        )
      )
    ) {
      const weight = activityObj[activityType].Weight;
      mainActivityArray.push({
        id: activityType,
        name: activityType,
        weight: weight,
      });
    }
  });

  // Return the transformed data
  return {
    students: students,
    MainActivity: mainActivityArray,
  };
};

export const transformDataWithAttendance = (
  mainActivities: any[],
  gradeData: any[] | null
) => {
  // Check if gradeData is null, if so, assign an empty array
  if (!gradeData) {
    gradeData = [];
  }
  // Define an object to store student data
  const students: any[] = [];

  // Iterate over the grade data
  gradeData.forEach(
    (grade: {
      activityType: string;
      studentId: any;
      student: any;
      score: any;
      activityName: any;
      id: any;
      weight: any;
    }) => {
      // Find the corresponding main activity
      const mainActivity = mainActivities.find(
        (activity: { [x: string]: any }) => activity[grade.activityType]
      );

      // Check if the student already exists in the students array
      let student = students.find((s) => s.id === grade.studentId);
      if (!student) {
        // If the student doesn't exist, create a new student object
        student = {
          id: grade.studentId,
          name: grade.student,
          attendance: [],
          activities: [],
        };
        students.push(student);
      }

      // Add attendance data
      if (grade.activityType === "Attendance") {
        student.attendance.push({
          score: grade.score,
          weight: mainActivity[grade.activityType].Weight,
        });
      } else {
        // Add activity data
        let activity = student.activities.find(
          (a: { id: any }) => a.id === grade.activityType
        );
        if (!activity) {
          activity = {
            id: grade.activityType,
            name: mainActivity[grade.activityType].Name,
            grades: [],
          };
          student.activities.push(activity);
        }
        activity.grades.push({
          name: grade.activityName,
          id: `${grade.activityType}_${grade.id}`,
          score: grade.score,
          weight: grade.weight,
        });
      }
    }
  );

  // Define an array to store the main activities
  const mainActivityArray: { id: string; name: string; weight: any }[] = [];

  // Iterate over the main activities
  mainActivities.forEach((activityObj: { [x: string]: { Weight: any } }) => {
    const activityType = Object.keys(activityObj)[0];
    const weight = activityObj[activityType].Weight;
    mainActivityArray.push({
      id: activityType,
      name: activityType,
      weight: weight,
    });
  });

  // Return the transformed data
  return {
    students: students,
    MainActivity: mainActivityArray,
  };
};
