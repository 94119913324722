import styled from "styled-components";
import { theme } from "../../theme";
import CheckIcon from "../../assets/icons/Group2199.svg";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const AddAttendanceWrapper = styled.div<StyleThemeInterface>`
  /* width: 36.25rem; */
  width: 100% !important;

  display: flex;
  flex-direction: column;
  /* min-width: auto; */
  /* height:auto ; */
  max-height: 60vh;

  /* background:${(props) => props.bgcolor};
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0.375rem;
    background: #5dd3b3;
    border-radius: 1.25rem;
  } */
`;

export const DayTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PopUpContainer = styled.div`
  padding: 1.5rem 1.25rem 1.25rem 1.25rem;
`;
export const ExitRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
export const AddAttendIconText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  gap: 0.94rem;
  margin-bottom: 1.56rem;
`;
export const InputTitle = styled.input.attrs({ id: "title", type: "number" })`
  width: 99%;
  /* width: 33.75rem; */
  outline: none;
  color: white;
  height: 2.8125rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  background: transparent;
  padding-left: 1.25rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: ${(props) => props.theme.font.primaryLight};
  &::placeholder {
    color: #fff;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: ${(props) => props.theme.font.primaryLight};
  }
`;
export const TitleLabel = styled.label.attrs({ htmlFor: "title" })`
  margin-bottom: 0.63rem;
  display: flex;
`;
export const StartEndDateRow = styled.div`
  margin-top: 1.25rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  gap: 1.25rem;
`;
export const DateAndTimeDiv = styled.div`
  display: flex;
  gap: 5.62rem;
  flex-wrap: wrap;
`;
export const LabelDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.62rem;
  /* flex:1; */
  /* background:aqua; */
  /* .input-container {
    display: flex;
    flex-direction: column;
    gap: 1.12rem;
    width:100%;
  } */
`;
export const TitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.25rem;
`;
export const CheckBoxRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  /* gap: 0.6rem; */
  justify-content: flex-start;

  /* margin-top: 0.63rem; */
  /* background-color: red; */
`;
export const CheckBoxColumn = styled.div`
  display: flex;
  width: 20%;
  height: 2.2rem;
  /* background-color: blue; */
  flex-direction: column;
  justify-content: center;
  /* gap: 1.62rem; */
  /* justify-content: flex-start; */
  /* align-items: center; */
`;
export const TimeColumn = styled.div`
  /* justify-content: space-around; */
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  /* flex-wrap: wrap; */
  margin-bottom: 0.5rem;
  padding-left: 1.2rem;
`;
export const CheckBox = styled.input<StyleThemeInterface>`
  width: 1rem;
  height: 1rem;
  background: ${(props) => props.bgcolor};
  appearance: none;
  border: 1px solid ${(props) => props.bordercolor};
  border-radius: ${(props) => props.borderradius};
  transition: background-color 0.3s ease;

  &:checked {
    background-image: url(${CheckIcon}); /* Use the CheckIcon component as a URL */
    background-size: cover; /* Adjust this as needed */
    background-repeat: no-repeat;
    background-position: center;
    border: none;
  }
`;
export const DaysLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.62rem;
  width: fit-content;
  font-family: "Primary Font-Medium";
`;
export const ButtonCancel = styled.button`
  width: 8.8125rem;
  height: 2.125rem;
  flex-shrink: 0;
  border-radius: 1.25rem;
  border: 1px solid #d04b4b;
  background: none;
  cursor: pointer;
`;
export const ButtonAdd = styled.button`
  width: 8.8125rem;
  height: 2.125rem;
  flex-shrink: 0;
  border-radius: 1.25rem;
  outline: none;
  border: none;
  background: #5dd3b3;
  cursor: pointer;
`;
export const ButtonsHolder = styled.div`
  margin-top: 1.25rem;
  display: flex;
  gap: 0.94rem;
  width: 100%;

  justify-content: flex-end;
`;

export const LeftBorderColor = styled.div`
  position: absolute;
  width: 37.75rem;
  height: 1rem;
  transform: rotate(90deg);
  stroke-width: 4px;
  color: #5dd3b3;
`;

export const TimePickerHolder = styled.div`
  display: flex;
  align-items: center;

  gap: 1rem;
`;
export const TimePickerHolderContent = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;

  &:hover {
    color: #5dd3b3;
  }
  /* background:yellow; */
`;

export const TimePickerHolderContentDelete = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  cursor: pointer;
  &:hover {
    color: #d94b4b;
  }
`;
