import React, { FC, useEffect } from "react";
import {
  Overlay,
  BackgroundOverlay,
  PopupContainer,
  PopupContentContainer,
  IconsContainer,
  IconAndTitleContainer,
  PopupTitle,
  HorizantalLine,
  CloseIconContainer,
  ButtonContainer,
  ContentScrolled,
  StringInfo,
  IconCont,
} from "./PopUp.style";
import Button from "../QuizButton/Button";
import { ReactComponent as CloseIcon } from "../../assets/icons/Close1.svg";
import { LightTypography, Typography } from "../fonts/Fonts";
import CancelButton from "../StyledButton/CancelButton";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  PopupSelectorColor,
  courseCreatorColorSelector,
} from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
interface PopupProps {
  show: boolean;
  content: React.ReactElement;
  title?: string;
  showline: boolean;
  icon?: React.ReactElement;
  loading?: boolean;
  paddingTop?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingBottom?: string;
  CloseRightPadding?: string;
  marginTop?: string | undefined;
  showButton?: boolean | true;
  showButtonCancel?: boolean | true;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit?: () => void;
  buttonName?: string;
  BackGround?: string;
  IconContainer?: string;
  justifyContent?: string;
  closeContainer?: boolean;
  ContentContainerBgColor?: string;
  ContentMinWidth?: string;
  ContentWidth?: string;
  ContentHeight?: string;
  descriptionText?: string;
  descriptionTextFontSize?: string;
  descriptionAndTitleGapping?: string;
  FixedHeaderContent?: React.ReactElement;
  FixedFooterContent?: React.ReactElement;
  handleClosePopup?: () => void;
}

const PopUp: FC<PopupProps> = (
  {
    closeContainer = true,
    show,
    handleClosePopup,
    content,
    setShow,
    title,
    icon,
    loading,
    FixedFooterContent,
    FixedHeaderContent,
    showline,
    marginTop,
    justifyContent,
    paddingTop = "0",
    paddingLeft = "1.25rem",
    paddingRight = "1.25rem",
    paddingBottom = "1.5rem",
    CloseRightPadding = "0rem",
    showButton = true,
    handleSubmit,
    buttonName,
    showButtonCancel = false,
    BackGround,
    ContentContainerBgColor,
    ContentWidth = "fit-content",
    ContentMinWidth,
    ContentHeight,
    descriptionText,
    descriptionAndTitleGapping = "0.63rem",
    descriptionTextFontSize = "0.75rem",
  },
  IconContainer = true
) => {
  const { ContentContainer_BgColor, theme, PageBgColor, CloseColor } =
    useRecoilValue(PopupSelectorColor);
  const { themeTextColor, SecondaryWhiteTextColor } =
    useRecoilValue(ColorSelector);
  const { lineIconColor, tooltipTextColor } = useRecoilValue(
    courseCreatorColorSelector
  );
  const { t } = useTranslation();
  const ClosePopup = () => {
    setShow(false);
    handleClosePopup && handleClosePopup();
  };
  return (
    <Overlay isvisible={show.toString()}>
      {show && (
        <BackgroundOverlay background={BackGround || PageBgColor}>
          <PopupContainer>
            <PopupContentContainer
              style={{
                marginTop: marginTop ? marginTop : "20px",
                // paddingTop: `${paddingTop}`,
                // paddingLeft: `${paddingLeft}`,
                // paddingRight: `${paddingRight}`,
                // paddingBottom: `${paddingBottom}`,
              }}
              bgcolor={ContentContainerBgColor || ContentContainer_BgColor}
              width={ContentWidth}
              minwidth={ContentMinWidth}
            >
              {closeContainer ? (
                <IconsContainer
                  iconcontainer={IconContainer}
                  paddingright={CloseRightPadding}
                >
                  <IconAndTitleContainer style={{ color: themeTextColor }}>
                    <IconCont
                      style={{ marginTop: descriptionText ? "-1.3rem" : "" }}
                    >
                      {icon}
                    </IconCont>
                    <StringInfo gaps={descriptionAndTitleGapping}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.75rem",
                        }}
                      >
                        <Typography
                          fontSize="16px"
                          fontWeight="400"
                          lineHeight="19.78px"
                          text={title || ""}
                        />
                        {descriptionText && (
                          <div>
                            <LightTypography
                              text={descriptionText || ""}
                              fontSize={descriptionTextFontSize}
                              color={SecondaryWhiteTextColor}
                            />
                          </div>
                        )}
                      </div>
                    </StringInfo>
                  </IconAndTitleContainer>
                  <CloseIconContainer theme={theme}>
                    <CloseIcon
                      color={CloseColor}
                      onClick={() => ClosePopup()}
                    />
                  </CloseIconContainer>
                </IconsContainer>
              ) : (
                ""
              )}

              <HorizantalLine
                lineiconcolor={lineIconColor}
                showline={showline.toString()}
              />
              {FixedHeaderContent}

              <ContentScrolled
                theme={theme}
                height={ContentHeight}
                style={{
                  paddingTop: paddingTop,
                  paddingLeft: paddingLeft,
                  paddingRight: paddingRight,
                  paddingBottom: paddingBottom,
                }}
                // paddingBottom={paddingBottom}
              >
                {content}
              </ContentScrolled>
              {FixedFooterContent}
              {showButton && (
                <ButtonContainer justifycontent={justifyContent || "center"}>
                  {showButtonCancel ? (
                    <CancelButton
                      color="#D85D66"
                      borderColor="#D85D66"
                      name={t("forms.Cancel")}
                      onClickFunction={() => setShow(false)}
                    />
                  ) : (
                    ""
                  )}
                  {showButton && !loading ? (
                    <CancelButton
                      name={buttonName ? buttonName : t("general.Done")}
                      color="#fff"
                      borderColor="#5DD3B3"
                      backgroundColor="#5DD3B3"
                      onClickFunction={() => {
                        if (handleSubmit) {
                          handleSubmit();
                          setShow(false);
                        } else {
                          setShow(false);
                        }
                      }}
                    />
                  ) : loading ? (
                    <CancelButton
                      name={t("general.loading") + "..."}
                      color="#fff"
                      borderColor="#5DD3B3"
                      backgroundColor="#5DD3B3"
                      onClickFunction={() => {}}
                    />
                  ) : (
                    ""
                  )}
                </ButtonContainer>
              )}
            </PopupContentContainer>
          </PopupContainer>
        </BackgroundOverlay>
      )}
    </Overlay>
  );
};

export default PopUp;
