import React, { useState } from "react";
import { FiltersInterface } from "./LeaderboardFilterInterface";
import { BgDiv, BtnsDiv, CheckBox, DateDiv, DateDivTitle, FilterDiv, FilterTitle, FlexDiv, LabelDiv, SingleFilterDiv, TypographyDiv, } from "./LeaderboardFilter.style";
import { LightTypography, Typography } from "../../elements/fonts/Fonts";
import { ReactComponent as SvgSettingIcon } from "../../assets/icons/settings_1.svg";
import CheckBoxInput from "../../elements/checkbox/CheckBox";
import SelectDate from "../../elements/DatePicker/inputDate";
import CancelButton from "../../elements/StyledButton/CancelButton";

const LeaderboardFilter = ({ onApplyFilters, onClosePopup, }: FiltersInterface) => {
  const [showCustomDate, setShowCustomDate] = useState(false);

  return (
    <>
      <TypographyDiv>
        <SvgSettingIcon color="#fff"/>
        <Typography text="Filter your Leaderboard by" fontSize="1rem" />
      </TypographyDiv>

      <FilterDiv>
        <BgDiv />

        <SingleFilterDiv>
          <FilterTitle>
            <Typography text="Time Period" />
          </FilterTitle>
          <FlexDiv onClick={() => {}} style={{ cursor: "pointer" }}>
            <CheckBoxInput name="Select" value="" checked={false} onChange={() => {}} />
            <LightTypography text="Lifetime" fontSize="0.875rem" />
          </FlexDiv>
          <FlexDiv onClick={() => {}} style={{ cursor: "pointer" }}>
            <CheckBoxInput name="Select" value="" checked={false} onChange={() => {}} />
            <LightTypography text="This Month (01 Jan - Today)" fontSize="0.875rem" />
          </FlexDiv>
          <FlexDiv onClick={() => {}} style={{ cursor: "pointer" }}>
            <CheckBoxInput name="Select" value="" checked={false} onChange={() => {}} />
            <LightTypography text="This Quarter (01 Jan - Today)" fontSize="0.875rem" />
          </FlexDiv>
          <FlexDiv onClick={() => {}} style={{ cursor: "pointer" }}>
            <CheckBoxInput name="Select" value="" checked={false} onChange={() => {}} />
            <LightTypography text="This Half-Year (01 Jan - Today)" fontSize="0.875rem" />
          </FlexDiv>
          <FlexDiv onClick={() => {}} style={{ cursor: "pointer" }}>
            <CheckBoxInput name="Select" value="" checked={false} onChange={() => {}} />
            <LightTypography text="This Year (01 Jan - Today)" fontSize="0.875rem" />
          </FlexDiv>
          <FlexDiv onClick={() => setShowCustomDate(!showCustomDate)} style={{ cursor: "pointer" }}>
            <CheckBoxInput name="Select" value="" checked={showCustomDate} onChange={() => {}} />
            <LightTypography text="Custom" fontSize="0.875rem" />
          </FlexDiv>
        </SingleFilterDiv>

        <SingleFilterDiv>
          <FilterTitle zIndex="-1">
            <Typography text="Time Period" />
          </FilterTitle>
          <FlexDiv onClick={() => {}} style={{ cursor: "pointer" }}>
            <CheckBoxInput name="Select" value="" checked={false} onChange={() => {}} />
            <LightTypography text="Previous Month (Dec 23)" fontSize="0.875rem" />
          </FlexDiv>
          <FlexDiv onClick={() => {}} style={{ cursor: "pointer" }}>
            <CheckBoxInput name="Select" value="" checked={false} onChange={() => {}} />
            <LightTypography text="Previous Quarter (Oct 23 - Dec 23)" fontSize="0.875rem" />
          </FlexDiv>
          <FlexDiv onClick={() => {}} style={{ cursor: "pointer" }}>
            <CheckBoxInput name="Select" value="" checked={false} onChange={() => {}} />
            <LightTypography text="Previous Half-Year (Jul 23 - Dec 23)" fontSize="0.875rem" />
          </FlexDiv>
          <FlexDiv onClick={() => {}} style={{ cursor: "pointer" }}>
            <CheckBoxInput name="Select" value="" checked={false} onChange={() => {}} />
            <LightTypography text="Previous Year (Jan 23 - Dec 23)" fontSize="0.875rem" />
          </FlexDiv>
          <FlexDiv onClick={() => {}} style={{ cursor: "pointer" }}>
            <CheckBoxInput name="Select" value="" checked={false} onChange={() => {}} />
            <LightTypography text="Previous Half-Year (Jul 23 - Dec 23)" fontSize="0.875rem" />
          </FlexDiv>
        </SingleFilterDiv>
      </FilterDiv>

      {showCustomDate && (
      <>
        <DateDivTitle>
          <Typography text="Date" />
        </DateDivTitle>
        <DateDiv>
        <LabelDiv>
          <LightTypography text="From" fontSize="0.75rem" />
            <SelectDate
              width="17.1875rem"
              // value={globalQuizEndDate}
              // onChange={handleEndDate}
              showTime={false}
            />
        </LabelDiv>
        <LabelDiv>
          <LightTypography text="To" fontSize="0.75rem" />
            <SelectDate
              width="17.1875rem"
              // value={globalQuizEndDate}
              // onChange={handleEndDate}
              showTime={false}
            />
        </LabelDiv>
        </DateDiv>
      </>
      )}

      <BtnsDiv>
        <CancelButton name="Clear" color="#D85D66" borderColor="#D85D66" backgroundColor="transparent" onClickFunction={() => {}} />
        <CancelButton name="Apply" backgroundColor="#5DD3B3" color="#fff" onClickFunction={() => {}} />
      </BtnsDiv>
    </>
  );
};

export default LeaderboardFilter;
