import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import {
  IframeStyled,
  LinkContainer,
  LinkStyle,
} from "../linkTreeViewer/LinkTree.style";
import { SvgFile } from "../../elements/Icons";
import { Typography } from "../../elements/fonts/Fonts";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import { post } from "../../utils/AxiosRequests";
import { useRecoilValue } from "recoil";
import {
  CourseColorSelector,
  ColorSelector,
} from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import IframeComponent from "./IframeComponent";

interface UrlProps {
  url: string;
  linkId: string;
}
export default function UrlViewer({ url, linkId }: UrlProps) {
  const { progressChanged, setProgressChange } = useContext(ClassContext);

  const { t } = useTranslation();
  const tenantName = localStorage.getItem("tenant");
  const [s3Url, setS3Url] = useState();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const classId = params.classId;
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const { FileBgColor, theme } = useRecoilValue(CourseColorSelector);
  const { default_boxShadow } = useRecoilValue(ColorSelector);
  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const response = await axios.get(
          `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/resource/link/${linkId}/${tenantName}`
        );
        setData(response.data.data);
        if (response.data.data.iframeAble) {
          const trackingData = {
            classSourceId: classId,
            length: "",
            percentage: 100,
            refId: linkId,
            type: "Link",
            subId: subIdSplitted,
            provider: provider,
          };

          const trackinResponse = await post(
            `completionTracking/create/${tenantName}`,
            trackingData
          );
        }
        setProgressChange(!progressChanged);
        setProgressChange(!progressChanged);
      } catch (error) {
        console.error("Error fetching document:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchDocument();
  }, [linkId]);
  const handleLinkClick = async () => {
    const trackingData = {
      classSourceId: classId,
      length: "",
      percentage: 100,
      refId: linkId,
      type: "Link",
      subId: subIdSplitted,
    };

    try {
      const trackinResponse = await post(
        `completionTracking/create/${tenantName}`,
        trackingData
      );
      setProgressChange(!progressChanged);
      setProgressChange(!progressChanged);
    } catch (error) {
      console.error("Error tracking link click:", error);
    }
  };
  return (
    <>
      {loading ? (
        <div>{`${t("general.loading")}...`}</div>
      ) : (
        <div style={{ width: "100%", height: "100%" }}>
          {data.iframeAble ? (
            <IframeStyled
              src={data.url}
              width="100%"
              height="600"
              style={{
                border:
                  theme === "light"
                    ? "0.25rem solid #E7E8EA"
                    : "0.25rem solid #576371",
              }}
            ></IframeStyled>
          ) : (
            <LinkStyle
              href={data.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleLinkClick}
            >
              <LinkContainer
                bgcolor={
                  theme === "dark"
                    ? "linear-gradient(180deg, #415160 0%, #293440 100%)"
                    : "#fff"
                }
                boxshadow={default_boxShadow}
              >
                <SvgFile color={theme === "light" ? "#2D3741" : "#3985c5"} />
              </LinkContainer>
              <div style={{
                wordBreak: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal"
              }}>
                <Typography text={data.url} fontSize="0.875rem" />
              </div>
            </LinkStyle>
          )}
        </div>
      )}
    </>
  );
}
