import styled from "styled-components";
import { StyleThemeInterface } from "../../../../../interfaces/StyleThemeInterface";

export const CompetencyFrameworksObjectContainer = styled.div<StyleThemeInterface>`
background:${props =>props.bgcolor || "linear-gradient(180deg, #415160 0%, #293440 100%)" };
box-shadow:${props =>props.boxshadow};
border-radius: 0.625rem;
display: flex;
flex-direction: column;
width: 100%;
cursor: pointer;
`;

export const CompetencyFrameworksHeaderRow= styled.div`
display: flex;
justify-content:space-between;
padding:1.19rem 1.25rem;
`;

export const CompetencyFrameworksTableHeaderRow= styled.div<StyleThemeInterface>`
display: flex;

background:${props =>props.bgcolor};
padding: 1rem 1.25rem;
gap: 0.2rem;
`;

export const CompetencyFrameworksTableCell= styled.div`
display: flex;
flex-direction:column;
gap: 0.55rem;
flex:1;
text-align:center;
&:first-child{
  flex:1.5;
  /* background:aqua; */
  text-align:left;
}

/* &:nth-child(2){
  background:red;
}
&:nth-child(3){
  background:blue;
}
&:nth-child(4){
  background:orange;
} */
&:last-child{
  flex:0.7;
  justify-content:flex-end;
  text-align:right;
  /* background:green; */
}
`;

export const CompetencyFrameworksTableBodyRow= styled.div<StyleThemeInterface>`
display: flex;
padding: 1rem 1.25rem;
gap: 0.2rem;
&:nth-child(odd){
  background:${props =>props.nth_child_bgcolor};
}
`;

export const CompetencyFrameworksTableBodyCell = styled.div`
display: flex;
flex:0.63;
/* background:yellow; */
flex-direction:column;
gap:1.6rem;
`;

export const CompetencyFrameworksTableActivityCols = styled.div`
display: flex;
flex:1.25;
/* background:pink; */
flex-direction:column;

`;
export const CompetencyFrameworksTableActivityRow = styled.div`
display: flex;
flex:1;
/* background:purple; */
gap: 0.2rem;
`;

export const CompetencyFrameworksTableActivityCell = styled.div`
display: flex;
flex:1;
justify-content:center;
position:relative;
&:first-child{
  /* flex:1.5; */
  /* background:green; */
  text-align:left;
}

&:nth-child(2){
  /* background:purple; */
}
&:nth-child(3){
  /* background:greenyellow; */
}

&:last-child{
  /* flex:0.7;
  justify-content:flex-end; */
  /* background:gray; */
}
`;

export const CompetencyFrameworksSelectedContainer = styled.div`
position:absolute;
top:1.2rem;
right: 1.4rem;
`;

export const CompetencyFrameworksMoreOptionContainer = styled.div`
display: flex;
  flex:0.3;
  justify-content:flex-end;
  position:relative;
  /* background:blue; */

`;