import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import StyledDatePicker, { DatePickerWapper } from "./TwoDatePicker.style";
import { format } from "date-fns";
import { useRecoilValue } from "recoil";
import { ColorSelector, MainColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { enUS, tr, ar, fr } from 'date-fns/locale'; // Import locales as needed

interface TwoDatePickerProps {
  onDateChange?: (start: Date | null, end: Date | null) => void;
  prevStartDate?: Date | null;
  prevEndDate?: Date | null;
}


const TwoDatePicker: React.FC<TwoDatePickerProps> = ({
  onDateChange,
  prevStartDate,
  prevEndDate,
}) => {
  const { themeTextColor, inputBorderColor, theme } = useRecoilValue(ColorSelector);
  const { mainBackgroundColor } = useRecoilValue(MainColorSelector);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { t } = useTranslation()
  const [language, setLanguage] = useState<any>();
  const storedLanguage = localStorage.getItem("language")

  useEffect(()=>{
    storedLanguage === "tr" ? setLanguage(tr) :
    storedLanguage === "ar" ? setLanguage(ar) : 
    storedLanguage === "fr" ? setLanguage(fr) : 
    setLanguage(enUS)
  },[t])

  useEffect(() => {
    setDefaultLocale(language);
  }, [t]);

  const formatRange = (start: Date | null, end: Date | null) => {
    if (start && end) {
      const startFormatted = format(start, "d MMM", { locale: language });
      const endFormatted = format(end, "d MMM yyyy", { locale: language });
      const startDay = format(start, "d");
      const startMonth = format(start, "MMM", { locale: language });
      const endDay = format(end, "d");
      const endMonth = format(end, "MMM", { locale: language });
      const endYear = format(end, "yyyy");

      return storedLanguage === "en" ? `From ${startFormatted} to ${endFormatted}` : storedLanguage === "tr" ? `${startDay} ${startMonth}'dan ${endDay} ${endMonth} ${endYear}'e kadar` : "";
    } else if (prevStartDate && prevEndDate) {
      const prevStartFormatted = format(prevStartDate, "d MMM", { locale: language });
      const prevEndFormatted = format(prevEndDate, "d MMM yyyy", { locale: language });
      const prevStartDay = format(prevStartDate, "d");
      const prevStartMonth = format(prevStartDate, "MMM", { locale: language });
      const prevEndDay = format(prevEndDate, "d");
      const prevEndMonth = format(prevEndDate, "MMM", { locale: language });
      const prevEndYear = format(prevEndDate, "yyyy");
      return storedLanguage === "en" ? `From ${prevStartFormatted} to ${prevEndFormatted}` : storedLanguage === "tr" ? `${prevStartDay} ${prevStartMonth}'dan ${prevEndDay} ${prevEndMonth} ${prevEndYear}'e kadar` : "";
    }
    return `${t('online session.Visible from/to')}`;
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
    // Check if onDateChange is defined before invoking it
    if (onDateChange) {
      onDateChange(dates[0], dates[1]);
    }
  };

  return (
    <DatePickerWapper themetextcolor={themeTextColor} bgdatepicker={mainBackgroundColor} inputbordercolor={inputBorderColor}>
      <StyledDatePicker
        startDate={prevStartDate ? prevStartDate : startDate}
        endDate={prevEndDate ? prevEndDate : endDate}
        selectsRange
        minDate={new Date()}
        onChange={handleDateChange}
        placeholderText={t('online session.Visible from/to')}
        value={formatRange(startDate, endDate)}
        className={startDate || endDate ? "selected" : ""}
        theme={theme}
        locale={language}
      />
    </DatePickerWapper>
  );
};

export default TwoDatePicker;
