import { MessageChatListInterface, MessageRealChatInterface } from "./ContactMessagesInterface";
import { UserChatInterFace, UserInterFace,UserFriendsInterface, Messages } from "./UserMessageInterFace";



export const RealMessagesData: MessageRealChatInterface[] =[
  {id:"1",
  name:"Abed AlSalam Almahari",
  online:true,
  UserProfile: "assets/images/Farid.svg",
  major:"Software",
  Connecting:true,
ReadenData:[{
  Sender:"Abed AlSalama",
  message:"Hi Dear",
  Date:"27/11/2023",
  hour:"8:35 PM",
},
{
  Sender:"Abed AlSalama",
  message:"Hi Dear sho l2a5bar",

  Date:"26/11/2023",
  hour:"8:36 PM",
},
{
  Sender:"hadi",
  message:"Hi Dear sho l2a5bar",
  Date:"27/11/2023",
  hour:"8:37 PM",
},
]
},
{
  id: "2",
  name: "Hassan Fawwaz",
  online: false,
  major:"Accouting",
  UserProfile: "assets/images/teacherprof.png",
  Connecting: false,
  UnReadData: [
    {
      Sender: "Hassan Fawwaz",
      message: "Hey there!",
      Date: "27/11/2023",
      hour: "10:15 AM",
    },
    {
      Sender: "Hassan Fawwaz",
      message: "What's up?",
      Date: "27/11/2023",
      hour: "9:30 AM",
    },
    {
      Sender: "Hassan Fawwaz",
      message: "hahahahahahahahahahahah",
      Date: "27/11/2023",
      hour: "9:31 AM",
    },
  ],

},
{
  id: "3",
  name: "Sarah",
  online: true,
  major:"Engineering",
  UserProfile: "assets/images/Owen.svg",
  Connecting: false,
  UnReadData: [
    {
      Sender: "Sarah",
      message: "Hello kefak!",
      Date: "5/11/2023",
      hour: "2:20 PM",
    },
    {
      Sender: "Sarah",
      message: "How are you?",
      Date: "4/11/2023",
      hour: "2:22 PM",
    },
  ],
  ReadenData: [
    {
      Sender: "Sarah",
      message: "Hi, long time no see!",
      Date: "5/10/2023",
      hour: "6:45 PM",
    },
  ],
},
{
  id: "4",
  name: "John",
  online: true,
  UserProfile: "assets/images/Owen.svg",
  major:"IT",
  Connecting: false,
  UnReadData: [
    {
      Sender: "John",
      message: "Hey!",
      Date: "5/11/2023",
      hour: "12:30 PM",
    },
  ],
  ReadenData: [
    {
     Sender: "John",
      message: "What's new?",
      Date: "5/11/2023",
      hour: "11:45 AM",
    },
  ],
},

]
export const UserDetails:UserInterFace={
    id:"1",
    userName:'mohamad Fawaz',
    online:true,
    profile:"assets/images/Farid.svg",
blocked:["2","4"],
muted:["4"]
  }

  function getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i <6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

export const UserFriends:UserFriendsInterface[]=[
  {
    id:"3",
    userName:'Abed El salam',
    online:false,
    profile:"assets/images/Farid.svg",
    color: getRandomColor(),
  },
  {
    id:"2",
    userName:'hassan Fawaz',
    online:true,
    profile:"assets/images/Farid.svg",
    color: getRandomColor(),
  },
  {
    id:"4",
    userName:'hadi shouman',
    online:false,
    profile:"assets/images/Farid.svg",
    color: getRandomColor(),
  },
  {
    id:"5",
    userName:'younes younes',
    online:false,
    profile:"assets/images/Farid.svg",
    color: getRandomColor(),
  },
  {
    id:"6",
    userName:'lana younes',
    online:false,
    profile:"assets/images/Farid.svg",
    color: getRandomColor(),
  }
]





// Dummy data for UserChatInterFace interface
export const userChatData: UserChatInterFace[] = [
  {
    UserId: '1',
    FriendId: '3',
    Messages: [
      {
        date: new Date('2023-11-01T08:00:00Z'),
        content: 'Hello!',
        read: true,
        senderId: '3',
      },
      {
        date: new Date('2023-11-02T10:30:00Z'),
        content: 'How are you?',
        read: true,
        senderId: '1',
      },
      {
        date: new Date('2024-01-15T15:20:00Z'),
        content: 'camavinga -',
        read: false,
        senderId: '3',
      },
    ],
  },
  {
    UserId: '1',
    FriendId: '2',
    Messages: [
      {
        date: new Date('2023-11-01T08:00:00Z'),
        content: 'Hello number 3!',
        read: true,
        senderId: '1',
      },
      {
        date: new Date('2023-11-02T10:30:00Z'),
        content: 'How are you? number 3',
        read: true,
        senderId: '2',
      },
      {
        date: new Date('2024-11-03T15:20:00Z'),
        content: 'I am doing well heloz',
        read: false,
        senderId: '1',
      },
      {
        date: new Date('2023-11-03T15:20:00Z'),
        content: 'I am doing well, thank you! number 3',
        read: false,
        senderId: '3',
      },
      {
        date: new Date('2023-11-03T15:20:00Z'),
        content: 'I am doing well, thank you! number 3',
        read: true,
        senderId: '3',
      },
      {
        date: new Date('2023-11-03T15:20:00Z'),
        content: 'I am doing well, thank you! number 3',
        read: false,
        senderId: '3',
      },
      {
        date: new Date('2023-11-03T15:20:00Z'),
        content: 'I am doing well, thank you! number 3',
        read: false,
        senderId: '3',
      },
      {
        date: new Date('2023-11-03T15:20:00Z'),
        content: 'I am doing well, thank you! number 3',
        read: false,
        senderId: '3',
      },
      {
        date: new Date('2023-11-03T15:20:00Z'),
        content: 'I am doing well, thank you! number 3',
        read: false,
        senderId: '3',
      },
 
    ],
  },
 
];








export interface GroupChatInterface {
  id: string;
  name: string;
  online: boolean;
  UserProfile: string;
  Connecting: boolean;
  ChatData: Messages[];
  isGroup:boolean;
  members?: UserFriendsInterface[];
  GroupImage?:string;
}

// Add group chat data
export const GroupChatData: GroupChatInterface[] = [
  {
    GroupImage:require("../../assets/GroupDefaultProfile.png"),
    id: "101",
    name: "Group Chat 1",
    online: true,
    isGroup:true,
    members:[{
      id: "3",
      userName: "Abed El salam",
      online: false,
      profile: "assets/images/Farid.svg",
      color: getRandomColor(),
    },],
    UserProfile: "assets/images/group_icon_1.png",
    Connecting: false,
    ChatData: [
      {
        date: new Date('2023-11-01T08:00:00Z'),
        content: 'Hello everyone!',
        read: true,
        senderId: '1', 
      },
      {
        date: new Date('2023-11-02T10:30:00Z'),
        content: 'How is everyone doing?',
        read: false,
        senderId: '2', 
      },
      {
        date: new Date('2024-11-01T08:00:00Z'),
        content: 'Hello shu l wd3!',
        read: true,
        senderId: '1', 
      },
      {
        date: new Date('2025-11-03T15:20:00Z'),
        content: 'Let s discuss our project.',
        read: false,
        senderId: '3', 
      },
    ],
  },
  {
    id: "102",
    name: "Group Chat 2",
    online: true,
    isGroup:true,
    UserProfile: "assets/images/group_icon_1.png",
    Connecting: false,
    ChatData: [
      {
        date: new Date('2023-11-01T08:00:00Z'),
        content: 'Hello everyone!',
        read: true,
        senderId: '1', 
      },
      {
        date: new Date('2023-11-02T10:30:00Z'),
        content: 'How is everyone doing?',
        read: false,
        senderId: '2', 
      },
      {
        date: new Date('2023-11-01T15:20:00Z'),
        content: 'Let s discuss our project  lala.',
        read: false,
        senderId: '3', 
      },
      {
        date: new Date('2023-11-01T15:20:00Z'),
        content: 'Let s discuss our project  lala.',
        read: false,
        senderId: '3', 
      },

    ],
  },
];