import React from "react";
import { Frame, Icon, Menu } from "./DropDownItems.style";
import { TypographyRegularDescription } from "../../../elements/fonts/Fonts";
import { DropMenuFooterData } from "../DropDownMenuData";
import { SvgLogoutIcon } from "../../../elements/Icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
export default function DropDownMenuFooter() {
  const { logout,user } = useAuth0();
  const { t } = useTranslation();
  
  return (
    <Menu>
      {/* {DropMenuFooterData.map((item) => ( */}
      <Frame onClick={() =>
        {
       
        localStorage.clear();
        sessionStorage.clear();

        if(user?.sub?.split("|")[0] !== "auth0"){
          // window.location.href = "https://www.office.com/estslogout?ru=%2F" ;
          logout({ logoutParams: { returnTo: window.location.origin } })
          // window.open("https://www.office.com/estslogout?ru=%2F");
          const popup = window.open("https://www.office.com/estslogout?ru=%2F", "AzureADLogout", "width=400,height=400");
        }else{
          logout({ logoutParams: { returnTo: window.location.origin } })
        }

        
        

        }
        
      }>
        <SvgLogoutIcon />
        <TypographyRegularDescription fontSize="0.875rem" text={`${t('dashboard.logout')}`} />
      </Frame>
      {/* ))} */}
    </Menu>
  );
}
