import styled from "styled-components";

export const ContentContainer = styled.div`
  padding: 1.88rem 0rem;
  /* min-height: 47.6875rem; */
  overflow-y: hidden;
  overflow: hidden;
  /* max-width: 23rem; */
  &:dir(ltr) {
    /* margin-right: -0.5rem; */
    /* border: solid 1px red; */
    /* border-radius: 0 1rem 0 0; */
  }
  /* background-color:red; */
`;
export const CancelButton = styled.button`
  background: transparent;
  width: 8.75rem;
  height: 2.1875rem;
  border-radius: 1.25rem;
  color: #d85d66;
  border: 1px solid #d85d66;
  font-family: "Primary Font-Bold";
  cursor: pointer;
`;

export const SubmitButton = styled.button`
  border: none;
  background: #5dd3b3;
  width: 8.75rem;
  height: 2.1875rem;
  border-radius: 1.25rem;
  color: white;
  font-family: "Primary Font-Bold";
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  margin-top: 1.87rem;
  margin-right: 1.25rem;
  margin-left: 1.25rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.25rem;
`;
