import styled from "styled-components";

export const ForumTimeLineLayout =styled.div`
display: flex;
width: 100%;
flex-direction:column;
gap:1.25rem;
`;

export const ForumTimeLineRowActions =styled.div`
display: flex;
width: 100%;
justify-content:space-between;
align-items:center;
`;


export const ForumTimeLineActions =styled.div`
display: flex;
gap:1.25rem;
cursor: pointer;
`;

export const PostsContainer =styled.div<{bgColor:string}>`
border-radius: 10px;
background:${props =>props.bgColor};
padding: 1.5rem;
width: 100%;
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
`;

export const FileDownloaderContainer = styled.div `
display: flex;
column-gap: 0.62rem;
border: 2px solid #576371;
width: fit-content;
padding: 1rem;
padding-right: 5rem;
border-radius: 0.375rem;
margin-bottom: 1rem;
`