import { TooltipProps } from "./ToolTipInterfacet";
import { TooltipContainer, TooltipText } from "./ToolTip.style";
import { useState } from "react";

const Tooltip: React.FC<TooltipProps> = ({ text, children, margin }) => {
const [isVisible, setIsVisible] = useState(false);

    return (
      <TooltipContainer
      onMouseEnter={() =>setIsVisible(true)}
      onMouseLeave={() =>setIsVisible(false)}
      >
        {isVisible &&   <TooltipText margin={margin}>
          {text}  
        </TooltipText>}
       
  
        {children}
      </TooltipContainer>
    );
  }
  
  
  
  export default Tooltip;