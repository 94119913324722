import React, { FC, useState, useEffect } from "react";
import {
  Typography,
  LightTypography,
  MediumTypography,
} from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import {
  AssesmentContainer,
  Assesment,
  GradeInput,
  GradeInputContainer,
  Ruberic,
  GradeLateContainer,
  LateContainer,
} from "./AssesmentSection.style";
import { AssesmentSectionProps } from "./AssesmentSectionInterface";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { handleWheel } from "../../../utils/OnWheelFunction";
const AssesmentSection: FC<AssesmentSectionProps> = ({
  setShowRubric,
  showLate,
  onGradeChange,
  grade,
  student,
  studentIndex,
  allAssignment,
  submittedGrade,
  forum,
  quiz,
  totalPoints,
  usingRubric,
  rubricId,
  submitted,
}) => {
  const [penalty, setPenalty] = useState<boolean>(false);
  const [finaleGrade, setFinaleGrade] = useState<number>(0);
  const [quizFinalGrade, setQuizFinalGrade] = useState<number>(0);
  const { t } = useTranslation();
  useEffect(() => {
    if (
      !forum &&
      allAssignment &&
      student &&
      student.length > 0 &&
      allAssignment.dueDate &&
      // allAssignment.cutOffDate &&
      allAssignment.penalty
    ) {
      const submissionDate = new Date(student[studentIndex].createdAt);
      const dueDate = new Date(allAssignment.dueDate);
      // const cutOffDate = new Date(allAssignment.cutOffDate);

      const isPenalty = submissionDate >= dueDate;
      setPenalty(isPenalty);
    }
  }, [allAssignment, student, studentIndex]);
  useEffect(() => {
    if (
      forum &&
      allAssignment &&
      student &&
      student.length > 0 &&
      allAssignment.dueDateTime &&
      // allAssignment.cutOffDate &&
      allAssignment.penalty
    ) {
      const submissionDate = new Date(
        student[studentIndex].submissions[0].postTime
      );
      const dueDate = new Date(allAssignment.dueDateTime);
      // const cutOffDate = new Date(allAssignment.cutOffDate);

      const isPenalty = submissionDate >= dueDate;
      setPenalty(isPenalty);
    }
  }, [allAssignment, student, studentIndex]);
  const handleRubric = () => {
    setShowRubric(true);
  };

  const handleGradeInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newGrade = parseInt(event.target.value, 10);
      onGradeChange(newGrade);
 
  };

  const handleBlur = () => {
    if (allAssignment) {
      const maxGrade = allAssignment.maxGrade;
      if (grade !== undefined && maxGrade !== undefined && grade > maxGrade) {
        onGradeChange(maxGrade);
      }
    }
  };

  useEffect(() => {
    // Ensure that none of the values are 0 to avoid division by zero
    if (
      quiz &&
      totalPoints !== 0 &&
      allAssignment &&
      allAssignment.maxGrade !== 0 &&
      student &&
      student.length > 0 &&
      student[studentIndex] &&
      student[studentIndex].totalGrade &&
      totalPoints !== undefined
    ) {
      const finalGrade = (
        (student[studentIndex].totalGrade / totalPoints) *
        allAssignment.maxGrade
      ).toFixed(2);
      setQuizFinalGrade(Number(finalGrade));
    }
  }, [totalPoints, studentIndex, student, allAssignment.maxGrade]);
  const { themeTextColor, inputBorderColor, SecondaryWhiteTextColor } =
    useRecoilValue(ColorSelector);
  //  console.log("max Grade: " + submittedGrade)
  return (
    <AssesmentContainer>
      <Typography
        text={t("assignment.ASSESMENT")}
        fontSize="1rem"
        color={`${theme.dark.main_color}`}
      />

      <Assesment showlate={showLate.toString()}>
        <div
          style={{
            // height: " 2.5rem",

            display: "flex",
            alignItems: "center",
          }}
        >
          {allAssignment && !usingRubric && !penalty && (
            <LightTypography
              text={`${t("assignment.Grade out of")} ${allAssignment.maxGrade}`}
              fontSize="0.875rem"
            />
          )}
          {usingRubric && submitted && !penalty && (
            <LightTypography
              text={`${t("assignment.Grade out of")} 100`}
              fontSize="0.875rem"
            />
          )}

          {penalty && (
            <GradeLateContainer>
              <LateContainer>
                <MediumTypography
                  text={`${t("assignment.Late penalty")} :`}
                  fontSize="0.875rem"
                />
                <MediumTypography
                  text={`${allAssignment.penalty}%`}
                  fontSize="0.875rem"
                  color="#D85D66"
                />
              </LateContainer>
            </GradeLateContainer>
          )}
        </div>

        <div>
          {penalty && usingRubric && (
            <GradeLateContainer>
              <LateContainer>
                <MediumTypography
                  text={`${t("assignment.Final Grade")} :`}
                  fontSize="0.875rem"
                />
                <MediumTypography
                  text={`${Math.round(submittedGrade || 0)}`}
                  fontSize="0.875rem"
                  color="#5DD3B3"
                />
              </LateContainer>
            </GradeLateContainer>
          )}
          {/* If the using Rubric state is true hide the grade input */}
          {usingRubric && submitted && !penalty ? (
              <GradeInput
                type="number"
                value={Math.round(submittedGrade || 0)}
                disabled={true}
                color={SecondaryWhiteTextColor}
                bordercolor={inputBorderColor}
                onWheel={handleWheel}
              />
          ) : (
            !usingRubric && (
              <>
                {!penalty && !quiz ? (
                  <GradeInput
                    type="number"
                    onChange={handleGradeInputChange}
                    value={submitted ? Math.round(submittedGrade || 0) : Math.round(grade || 0)}
                    max={allAssignment.maxGrade || 100}
                    onBlur={handleBlur}
                    color={SecondaryWhiteTextColor}
                    // disabled={submitted ? true : false}
                    bordercolor={inputBorderColor}
                    onWheel={handleWheel}
                    // value="45"
                  />
                ) : // ) : submittedGrade && penalty && !quiz ? (
                //   <GradeInput
                //     color={SecondaryWhiteTextColor}
                //     type="number"
                //     //onChange={handleGradeInputChange}
                //     value={submittedGrade}
                //     disabled={true}
                //     //max={allAssignment.maxGrade || 100}
                //     //onBlur={handleBlur}
                //     // value="45"
                //   />
                // ) :
                //  !submittedGrade && penalty && !quiz ? (
                //   <GradeInput
                //     color={SecondaryWhiteTextColor}
                //     type="number"
                //     onChange={handleGradeInputChange}
                //     // value={grade}
                //     max={allAssignment.maxGrade || 100}
                //     onBlur={handleBlur}
                //     // value="45"
                //   />
                quiz ? (
                  <GradeInput
                    type="number"
                    // onChange={handleGradeInputChange}
                    value={Math.round(quizFinalGrade)}
                    disabled={true}
                    //  max={allAssignment.maxGrade || 100}
                    //  onBlur={handleBlur}
                    // value="45"
                    color={SecondaryWhiteTextColor}
                    bordercolor={inputBorderColor}
                    onWheel={handleWheel}
                  />
                ) : (
                  ""
                )}
              </>
            )
          )}
        </div>
      </Assesment>

      <Assesment showlate={showLate.toString()}>
        {allAssignment && !usingRubric && penalty && (
          <LightTypography
            text={`${t("assignment.Grade out of")} ${allAssignment.maxGrade}`}
            fontSize="0.875rem"
          />
        )}
        {!submitted && penalty && !quiz && !usingRubric ? (
          <GradeInput
            color={SecondaryWhiteTextColor}
            type="number"
            onChange={handleGradeInputChange}
            // value={grade}
            max={allAssignment.maxGrade || 100}
            onBlur={handleBlur}
            // value="45"
            bordercolor={inputBorderColor}
            onWheel={handleWheel}
          />
        ) : submitted && penalty && !quiz && !usingRubric ? (
          <GradeInput
            color={SecondaryWhiteTextColor}
            type="number"
            onChange={handleGradeInputChange}
            value={submitted ? Math.round(submittedGrade || 0) : Math.round(grade || 0)}
            // disabled={true}
            //max={allAssignment.maxGrade || 100}
            //onBlur={handleBlur}
            // value="45"
            bordercolor={inputBorderColor}
            onWheel={handleWheel}
          />
        ) : (
          ""
        )}
      </Assesment>
      {usingRubric  && (
        <Ruberic onClick={handleRubric}>{t("forum.Open Rubric")}</Ruberic>
      )}
    </AssesmentContainer>
  );
};

export default AssesmentSection;
