import {
    SegmentTitleDataInterface,
    TableHeadDataInterface,
    TableTrInterface,
  } from "./gradeinstructorDataInterface";
  
  export const SegmentTitleData: SegmentTitleDataInterface[] =[ 
  
 
  {
    segmentTitle: "Assignment 10%",
  },
  {
    segmentTitle: "Quiz 35%",
  },
  {
    segmentTitle: "Forum 20%",
  },
  {
    segmentTitle: "Attendance 5%",
  },
  {
    segmentTitle: "Course Final Grade",
  },
 
  

]
  
//   export const TableHeadData: TableHeadDataInterface[] = 
  
  
  
export const TableTrData: TableTrInterface[] = [
    {
        trContent: [
            {
                tdContent: "STUDENT NAME",
            },
         
            {
                tdContent: "ASSIGNMENT 1",
            },
            {
                tdContent: "ASSIGNMENT 2",
            },
            {
                tdContent: "ASSIGNMENT 3",
            },
            {   
                tdContent: "ASSIGNMENT TOTAL",
            },
            {
                tdContent: "Quiz 1",
            },
            {
                tdContent: "Quiz 2",
            },
            {
                tdContent: "Quiz 3",
            },
            {   
                tdContent: "Quiz TOTAL",
            },
            {
                tdContent: "Forum 1",
            },
            {
                tdContent: "Forum 2",
            },
            {
                tdContent: "Forum 3",
            },
            {   
                tdContent: "Forum TOTAL",
            },
       
            {
                tdContent: "ATTENDANCE TOTAL",
            },
       
         
        ],
    },
    {
        trContent: [
            {
                tdContent: "Lana",
                color: "#5DD3B3",
            },
            {
                tdContent: "1",
            },
            {
                tdContent: "2",
            },
            {
                tdContent: "3",
            },
            {
                tdContent: "total",
            },
            {
                tdContent: "4",
            },
            {
                tdContent: "5",
            },
            {
                tdContent: "6",
            },
            {
                tdContent: "quiztotal",
            },
            {
                tdContent: "7",
            },
            {
                tdContent: "8",
            },
            {
                tdContent: "9",
            },
            {
                tdContent: "forrum total",
            },
            {
                tdContent: "attendance total",
            },
            {
                tdContent: "FG",
            },
            
        ],
    },
    {
        trContent: [
            {
                tdContent: "Ghassan",
                color: "#5DD3B3",
            },
            {
                tdContent: "1",
            },
            {
                tdContent: "2",
            },
            {
                tdContent: "3",
            },
            {
                tdContent: "total",
            },
            {
                tdContent: "4",
            },
            {
                tdContent: "5",
            },
            {
                tdContent: "6",
            },
            {
                tdContent: "quiztotal",
            },
            {
                tdContent: "7",
            },
            {
                tdContent: "8",
            },
            {
                tdContent: "9",
            },
            {
                tdContent: "forrum total",
            },
            {
                tdContent: "attendance total",
            },
            {
                tdContent: "FG",
            },
        ],
    },
    {
        trContent: [
            {
                tdContent: "Tariq",
                color: "#5DD3B3",
            },
            {
                tdContent: "1",
            },
            {
                tdContent: "2",
            },
            {
                tdContent: "3",
            },
            {
                tdContent: "total",
            },
            {
                tdContent: "4",
            },
            {
                tdContent: "5",
            },
            {
                tdContent: "6",
            },
            {
                tdContent: "quiztotal",
            },
            {
                tdContent: "7",
            },
            {
                tdContent: "8",
            },
            {
                tdContent: "9",
            },
            {
                tdContent: "forrum total",
            },
            {
                tdContent: "attendance total",
            },
            {
                tdContent: "FG",
            },
           
          
        ],
    },
    // Add more student data here as needed
];
