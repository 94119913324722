import React, { useState, useEffect } from "react";
import {
  AnalyticsUserViewCompoTitle,
  AnalyticsUserViewCompoLayout,
  AnalyticsSearchSelect,
} from "./AnalyticsUserViewCompo.style";
import { Typography } from "../../elements/fonts/Fonts";
import { SvgSimpleArrow } from "../../elements/Icons";
import SelectInput from "../../elements/SelectInput/SelectInput";

// import { AnalyticsUserViewNewData } from "./AnalyticsUserViewCompoNewData";
import { AnalyticsUserViewNewInterface } from "./AnalyticsUserViewCompointerface";
import { Link } from "react-router-dom";
import { get } from "../../utils/AxiosRequests";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0ProviderSplitter, auth0SubIdSplitter } from "../../utils/StringManipulation";
import { bigFormatDate } from "../../utils/FormatedDATE";
import AnalyticsUserViewCompoTableDisplay from "./AnalyticsUserViewCompoTable";
import { useTranslation } from "react-i18next";

export const AnalyticsUserViewCompo = (): JSX.Element => {
  const { t } = useTranslation()
  const AnalyticsData = [
    { name: "All Classes" },
    { name: "Classes I Teach" },
    { name: "Classes I am Tacking" },
  ];
  const [TeacherArray, setTeacherArray] = useState<
    AnalyticsUserViewNewInterface[] | null
  >([]);
  const [StudentArray, setStudentArray] = useState<
    AnalyticsUserViewNewInterface[] | null
  >([]);

  const [ShowTeacher, setShowTeacher] = useState(true);
  const [ShowStudent, setShowStudent] = useState(true);
  const [ShowBodyTeacher, setShowBodyTeacher] = useState(true);
  const [ShowBodyStudent, setShowBodyStudent] = useState(true);
  const [loading, setLoading] = useState(true);

  const [SearchContent, setSearchContent] = useState("All Classes");
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");

  const [AnalyticsUserViewNewData, setAnalyticsUserViewNewData] = useState<
    any[]
  >([]);

  // Fetch the analytics Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get(
          `enrollments/byUser/all/${tenantName}/${subIdSplitted}`,provider
        );
        // Fetch and set data for each item individually
        const formattedData = await Promise.all(
          response.data
            .filter(
              (item: any) =>
                item.role === "student" ||
                (item.role === "teacher" && item.structured)
            )
            .map(async (item: any) => {
              // Fetch data for individual item
              const response2 = await get(
                `about/class/${item.id}/${tenantName}`
              );
              // Extract start and end dates from response2.data.classData
              const { startDate, endDate } = response2.data[0].classData;

              // This is the title of the course
              // const { title } = response2.data[0].course;

              // Return formatted data for the current item
              return {
                id: item.id,
                role: item.role,
                CourseName: item.title,
                TermSemester: "Fall 2022/2023", // You might need to get this dynamically
                StartDate: startDate ? bigFormatDate(startDate) : "-",
                CloseDate: endDate ? bigFormatDate(endDate) : "-",
                Status: "In progress", // You might need to determine this dynamically
                CourseAverageGrade: "-", // You might need to determine this dynamically
              };
            })
        );
        setLoading(false);
        setAnalyticsUserViewNewData(formattedData);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const handleSelectAnalytics = (value: any) => {
    // Update the search content immediately upon selection
    setSearchContent(value);
  };

  useEffect(() => {
    // Check the SearchContent to determine the display logic
    if (SearchContent === "All Classes") {
      setShowTeacher(true);
      setShowStudent(true);
    } else if (SearchContent === "Classes I Teach") {
      setShowTeacher(true);
      setShowStudent(false);
    } else if (SearchContent === "Classes I am Tacking") {
      setShowTeacher(false);
      setShowStudent(true);
    } else {
      // Add a default case if needed
      setShowTeacher(true);
      setShowStudent(true);
    }
  }, [SearchContent]);

  useEffect(() => {
    // Filter the data based on the role and set the state variables
    const teachers = AnalyticsUserViewNewData.filter(
      (item) => item.role === "teacher"
    );
    const students = AnalyticsUserViewNewData.filter(
      (item) => item.role === "student"
    );

    setTeacherArray(teachers);
    setStudentArray(students);
  }, [AnalyticsUserViewNewData]);

  const handelShowTeacherBody = () => {
    setShowBodyTeacher(!ShowBodyTeacher);
  };
  const handelShowStudentBody = () => {
    setShowBodyStudent(!ShowBodyStudent);
  };

  useEffect(() => {
    if (StudentArray && StudentArray.length > 0) {
      setShowBodyTeacher(false);
    }
  }, [StudentArray]);

  useEffect(() => {
    if (TeacherArray && TeacherArray.length > 0) {
      setShowBodyStudent(false);
    }
  }, [TeacherArray]);

  return (
    <>
      <AnalyticsUserViewCompoLayout>
        <AnalyticsUserViewCompoTitle>
          <Typography text="Classes" fontSize="1.75rem" />

          {StudentArray &&
            StudentArray?.length > 0 &&
            TeacherArray &&
            TeacherArray?.length > 0 && (
              <AnalyticsSearchSelect>
                <Typography text="Filter" fontSize="1.125rem" />
                <SelectInput
                  onChange={handleSelectAnalytics}
                  placeholder="Select Analytics"
                  width="13.5rem"
                  optionWidth="13.5rem"
                  options={AnalyticsData}
                  value={SearchContent}
                />
              </AnalyticsSearchSelect>
            )}
        </AnalyticsUserViewCompoTitle>
        {loading ? (
          <Typography text={`${t('general.loading')}...`} />
        ) : (
          <>
            {ShowTeacher && TeacherArray && TeacherArray.length > 0 && (
              <>
                {TeacherArray && (
                  <AnalyticsUserViewCompoTableDisplay
                    handleOnClick={handelShowTeacherBody}
                    Title="Classes I am Teaching"
                    Data={TeacherArray}
                    ShowBody={ShowBodyTeacher}
                  />
                )}
              </>
            )}
            {ShowStudent && StudentArray && StudentArray.length > 0 && (
              <>
                {StudentArray && (
                  <AnalyticsUserViewCompoTableDisplay
                    handleOnClick={handelShowStudentBody}
                    Title="Classes I am Taking"
                    Data={StudentArray}
                    ShowBody={ShowBodyStudent}
                  />
                )}
              </>
            )}
          </>
        )}
      </AnalyticsUserViewCompoLayout>
    </>
  );
};
