import styled from "styled-components";

export const StarsWrapper = styled.div`
  &:dir(ltr) {
    padding-left: 1.5rem;
  }
  &:dir(rtl) {
    padding-right: 1.5rem;
  }
`;

export const BranchName = styled.div`
  &:dir(ltr) {
    margin-left: 2rem;
  }
  &:dir(rtl) {
    margin-right: 2rem;
  }
  margin-top: 1rem;
  font-family: Primary Font-bold;
`;
