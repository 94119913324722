import React from "react";
import {
  LightTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import { Description, ModuleTopic, ProgressDiv, ScormBox, ScormFileName, ScormViewCont } from "./ScormClassView.style";
import CancelButton from "../../elements/StyledButton/CancelButton";
import ProgressCircle from "../../elements/progressCircle/ProgressCircle";

const ScormClassView = () => {
  return (
    <>
      <Typography text="Class View" fontSize="1.75rem" />
      <ModuleTopic>
        <TypographyRegularDescription
          text="Module 3 - Topic 1"
          fontSize="0.75rem"
          color="#5DD3B3"
        />
      </ModuleTopic>
      <Typography text="Scorm : Interactive lesson" fontSize="1rem" />

      <Description>
        <LightTypography
          text='Meeeeouw and sometimes switches in french and say "miaou" just because well why not. Sleep on dog bed, force dog to sleep on floor scratch at door to be let  outside, get let out then cratch at door immmediately after to be let back in kitty scratches couch bad kitty yet trip owner up in kitchen i want food your pillow is now  my pet bed, why must they do that.'
          fontSize="0.875rem"
        />
      </Description>

<ProgressDiv>
<ProgressCircle progress={50}/>
</ProgressDiv>


      <ScormViewCont>
        <ScormFileName>
        <TypographyRegularDescription
          text="ZipTitle.zip"
          fontSize="0.75rem"
        />
        </ScormFileName>
        <ScormBox>
        <Typography text="The process of “ learning” new attitudes and behaviors and formulating learning objectives" fontSize="1rem" />
        <CancelButton
            name="Enter"
            onClickFunction={() => {}}
            color="#fff"
            borderColor="#5DD3B3"
            backgroundColor="#5DD3B3"
            width= "8.8125rem"
          />
        </ScormBox>
      </ScormViewCont>
    </>
  );
};

export default ScormClassView;
