import React from "react";
import {
  SvgNumerical,
  SvgMatching,
  SvgTrueFalse,
  SvgShortAnswer,
  SvgMultipleIcon,
  SvgEssay,
} from "../../elements/Icons";
import { FlexDiv } from "../../globalStyles";
import { LightTypography } from "../../elements/fonts/Fonts";
import { useTranslation } from "react-i18next";

const RenderQuestionType = (value: any, t: any) => {
  switch (value) {
    case "ESSAY":
      return (
        <FlexDiv gap="0.75rem">
          <SvgEssay color="#B757E2" />
          <LightTypography
            text={t("quiz.ESSAY")}
            fontSize="0.875rem"
            whiteSpace="nowrap"
          />
        </FlexDiv>
      );
    case "SHORT_ANSWER":
      return (
        <FlexDiv gap="0.75rem">
          <SvgShortAnswer color="#5D69FD" />
          <LightTypography
            text={t("quiz.SHORT ANSWER")}
            fontSize="0.875rem"
            whiteSpace="nowrap"
          />
        </FlexDiv>
      );
    case "TF":
      return (
        <FlexDiv gap="0.75rem">
          <SvgTrueFalse color="#8CEDEA" />
          <LightTypography
            text={t("quiz.TRUE/ FALSE")}
            fontSize="0.875rem"
            whiteSpace="nowrap"
          />
        </FlexDiv>
      );
    case "NUMERIC":
      return (
        <FlexDiv gap="0.75rem">
          <SvgNumerical color="#8E5DFD" />
          <LightTypography
            text={t("quiz.NUMERICAL")}
            fontSize="0.875rem"
            whiteSpace="nowrap"
          />
        </FlexDiv>
      );
    case "MATCH":
      return (
        <FlexDiv gap="0.75rem">
          <SvgMatching color="#5DD3B3" />
          <LightTypography
            text={t("quiz.Matching")}
            fontSize="0.875rem"
            whiteSpace="nowrap"
          />
        </FlexDiv>
      );
    case "MCQ":
      return (
        <FlexDiv gap="0.75rem">
          <SvgMultipleIcon color="#0B8FFF" />
          <LightTypography
            text={t("quiz.MULTIPLE CHOICE")}
            fontSize="0.875rem"
            whiteSpace="nowrap"
          />
        </FlexDiv>
      );
    default:
      return null;
  }
};

export default RenderQuestionType;
