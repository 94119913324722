import axios from "axios";

// Define the interface for time intervals
interface TimeInterval {
  startDate: Date;
  endDate: Date;
  startIndex: number;
}

const convertToAMPM = (time24: string): string => {
  const [hours, minutes] = time24.split(":");
  const period = +hours >= 12 ? "PM" : "AM";
  const hours12 = +hours % 12 || 12;
  return `${hours12}:${minutes} ${period}`;
};
// Utility function to convert 12-hour format to 24-hour format
const convertTo24Hour = (time: string): string => {
  const [hoursMinutes, period] = time.split(" ");
  let [hours, minutes] = hoursMinutes.split(":").map(Number);
  if (period === "PM" && hours < 12) {
    hours += 12;
  } else if (period === "AM" && hours === 12) {
    hours = 0;
  }
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};

// Function to handle the generation of office hours
export const handleGenerate = async (
  daysOfWeek: string[], // Array of days of the week (e.g., ['Monday', 'Tuesday'])
  isCheckedArray: boolean[], // Array indicating whether each day is selected (e.g., [true, false, true])
  startTime: string[][], // 2D array of start times for each day (e.g., [['09:00 AM'], [], ['10:00 AM', '02:00 PM']])
  endTime: string[][], // 2D array of end times for each day (e.g., [['05:00 PM'], [], ['11:00 AM', '03:00 PM']])
  toast: any, // Toast component for showing notifications (adjust type as needed)
  subId: string, // Subject ID
  provider?:any,
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>, // Optional function to set loading state
  getOfficeHours?: any, // Optional function to refresh office hours after updating
  setErrorStartTime?: React.Dispatch<React.SetStateAction<string[][]>>,
  setErrorEndTime?: React.Dispatch<React.SetStateAction<string[][]>>,
) => {
  // Filter daysOfWeek to only include selected days
  const checkedDays = daysOfWeek.filter((_, index) => isCheckedArray[index]);

  // Initialize error arrays
  const errorStartTimes: string[][] = Array(7)
    .fill([])
    .map(() => []);
  const errorEndTimes: string[][] = Array(7)
    .fill([])
    .map(() => []);

  // Check if all selected days have corresponding start and end times
  let validTimes = true;
  let uniqueTimes = true;

  checkedDays.forEach((day, dayIndex) => {
    const index = daysOfWeek.indexOf(day);
    const timeIntervals: TimeInterval[] = [];

    startTime[index].forEach((start, idx) => {
      const start24Hour = convertTo24Hour(start);
      const end24Hour = convertTo24Hour(endTime[index][idx]);
      const startDate = new Date(`1970-01-01T${start24Hour}:00`);
      const endDate = new Date(`1970-01-01T${end24Hour}:00`);

      if (startDate >= endDate) {
        validTimes = false;
        errorStartTimes[index][idx] = "#D04B4B";
        errorEndTimes[index][idx] = "#D04B4B";
      }

      timeIntervals.push({ startDate, endDate, startIndex: idx });
    });

    for (let i = 0; i < timeIntervals.length; i++) {
      for (let j = i + 1; j < timeIntervals.length; j++) {
        if (
          (timeIntervals[i].startDate < timeIntervals[j].endDate &&
            timeIntervals[i].endDate > timeIntervals[j].startDate) ||
          (timeIntervals[j].startDate < timeIntervals[i].endDate &&
            timeIntervals[j].endDate > timeIntervals[i].startDate)
        ) {
          uniqueTimes = false;
          errorStartTimes[index][timeIntervals[i].startIndex] = "#D04B4B";
          errorEndTimes[index][timeIntervals[i].startIndex] = "#D04B4B";
          errorStartTimes[index][timeIntervals[j].startIndex] = "#D04B4B";
          errorEndTimes[index][timeIntervals[j].startIndex] = "#D04B4B";
        }
      }
    }
  });

  if (setErrorStartTime) setErrorStartTime(errorStartTimes);
  if (setErrorEndTime) setErrorEndTime(errorEndTimes);

  if (!validTimes) {
    return toast.error(
      "Please ensure all start times are less than end times."
    );
  }

  if (!uniqueTimes) {
    return toast.error(
      "Please ensure all time slots are unique within each day."
    );
  }

  if (
    checkedDays.every((day) => startTime[daysOfWeek.indexOf(day)].length > 0) &&
    checkedDays.every((day) => endTime[daysOfWeek.indexOf(day)].length > 0)
  ) {
    // Retrieve tenant name from local storage
    const tenantName = localStorage.getItem("tenant");

    // Map checked days to their corresponding time slots
    const sessionDays = checkedDays.map((day) => {
      const index = daysOfWeek.indexOf(day);
      return {
        day: day,
        timeSlots: startTime[index].map((start, idx) => ({
          start,
          end: endTime[index][idx],
        })),
      };
    });

    // Construct the data object to be sent in the request
    const data = {
      data: {
        officeHours: sessionDays,
        subId: subId,
        provider:provider,
      },
    };

    try {
      // Set loading state to true if setLoading function is provided
      setLoading && setLoading(true);

      // Check if sessionDays array is empty and show error if it is
      if (sessionDays.length === 0) {
        setLoading && setLoading(false);
        return toast.error("Please Select your office hours");
      }

      // Send a PATCH request to update the office hours
      await axios
        .patch(
          `https://9ws5f31duk.execute-api.eu-west-1.amazonaws.com/dev/teacher/academic/${tenantName}`,
          data
        )
        .then((response) => {
          toast.success("Office hours updated successfully");
        })
        .catch((error) => {
          console.error("Failed to update Office hours:", error);
          toast.error("Failed to update Office hours");
        });
      // console.log("time data", data)

      // Reset loading state and refresh office hours if functions are provided
      setLoading && setLoading(false);
      getOfficeHours && getOfficeHours();
    } catch (error) {
      // Show error toast if there was an exception during the request
      toast.error("Error in updating Office hours.");
    }
  } else {
    // Show error toast if not all selected days have corresponding start/end times
    toast.error("Please check all start/end times for the checked days.");
  }
};
