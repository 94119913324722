import styled from "styled-components";
import { breakpoints } from "../../theme";

interface GroupsMembersRectangleProps {
  membersnumber: number;
  boxShadow?: string;
}

export const GroupsMembersRectangle = styled.div<GroupsMembersRectangleProps>`
  /* width: 18.5625rem; */
  width: 23.65%;
  height: 100%;
  border-radius: 0.625rem;
  background: ${({ theme }) => theme.dark.bg_secondary_color};
  box-shadow: ${(props) => props.boxShadow};
  visibility: hidden;

  @media (max-width: ${breakpoints.tablets}) {
    width: 100%;
    height: 23.65%;
    min-height: 8rem;
    padding: ${(props) => (props.membersnumber > 0 ? "0" : "0rem 1.56rem")};
  }
`;

export const GroupsMembersWrapper = styled.div<GroupsMembersRectangleProps>`
  width: 100%;
  height: ${(props) => (props.membersnumber > 0 ? "fit-content" : "100%")};
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: ${(props) =>
    props.membersnumber > 0 ? "flex-start" : "center"};
  flex-wrap: wrap;
  gap: 1.56rem;
  padding: 1.88rem 1.56rem;
  @media (max-width: ${breakpoints.tablets}) {
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 0.45rem;
    }

    &::-webkit-scrollbar-thumb {
      background-image: linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%);
      border-radius: 0.625rem;
      box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.25);
    }

    &::-webkit-scrollbar-track {
      background-color: #2d3741;
      border-radius: 0.625rem;
    }
  }
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  &::-webkit-scrollbar-thumb {
    background-image: linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%);
    border-radius: 0.625rem;
    box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.25);
  }

  &::-webkit-scrollbar-track {
    background-color: #2d3741;
    border-radius: 0.625rem;
  }
`;

export const GroupsMembersEmpty = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
  @media (max-width: ${breakpoints.tablets}) {
    flex-direction: row;
  }
`;

export const GroupsMembersEmptyTextWrapper = styled.div`
  text-align: center;
`;
