import styled from 'styled-components'

export const TaskCardContainer=styled.div <{border:string, inputbordercolor?:string}>`
border-radius: 0.625rem;
border: ${props => `1px solid ${props.inputbordercolor}`};
padding:1.25rem;
min-height: 8.875rem;
/* border-bottom:${props=>props.border==='Low'?'2px solid #5DD3B3':props.border==='Medium'?'2px solid #D07E4B':props.border==='High'?'2px solid #D85D66':''}; */

position: relative; 
cursor: pointer;
&:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 1.5%; 
    width: 97%;
    height: 2px; 
    background-color: ${props =>
      props.border === ' low'
        ? '#5DD3B3'
        : props.border === ' medium'
        ? '#D07E4B'
        : props.border === 'high'
        ? '#D85D66'
        : 'transparent'};
  }
`;



export const TaskImage=styled.img`
width: 1.875rem;
height: 1.8rem;
border-radius:50%;
`;

export const TaskMainContent=styled.div`
display: flex;
gap:0.62rem;
align-items:flex-start;
`;

export const TaskMainWrapper=styled.div`
margin-top:0.94rem ;
display: flex;
flex-direction:column;
gap:0.62rem;
width: 100%;
`;
export const TaskFlexContainer=styled.div`

display: flex;
justify-content:space-between;
align-items:center;
`;
 export const TaskFlexDateContainer=styled.div<{section:any,submit:any}>`
 margin-top:1.38rem;
 /* margin-bottom:2rem ; */
 display: flex;
justify-content:${props=>props.section==='done' && props.submit==='Submitted'?'':'space-between'};
gap:${props=>props.section==='done' && props.submit==='Submitted'?'2.18rem':''};
align-items:start;
 `;

export const DateContainer=styled.div`
display: flex;
align-items:center;
gap:0.62rem;
padding-bottom:1.25rem;
`;

export const DateCircle=styled.div<{border:string}>`
width: 0.75rem;
height: 0.75rem;
border-radius:50%;
border: ${props=>props.border==='Low'?'2px solid #5DD3B3':props.border==='Medium'?'2px solid #D07E4B':props.border==='High'?'2px solid #D85D66':''};
`;

export const DateTextWrapper=styled.div`
width:9.6875rem;
text-align:end;
`;

export const TaskIconWraper=styled.div`
display: flex;
align-items:center;
gap:0.62rem;
cursor:pointer;
`;
