import React, { FC } from 'react';
import { AssignementContainer } from './StatisticsAssignement.style';
import { Typography, LightTypography } from '../../../../elements/fonts/Fonts';
import { useMyContext } from '../../../../contexts/surveyGraderContext';
import { SvgBackArrow } from '../../../../elements/Icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface StatisticsAssignement {
    studentIndex:number;
    student:any[];
    allAssignment:any;
} 

const StatisticsAssignement: FC<StatisticsAssignement> = ({
    studentIndex,
    student,
    allAssignment
}:StatisticsAssignement) => {
    const {submissionDate} = useMyContext()
    const { t } = useTranslation();
    const language = localStorage.getItem("language")

    return (
        <>
            <AssignementContainer>
                <Typography text={`${allAssignment && allAssignment.title}`} fontSize='1.125rem' fontWeight='400' lineHeight='normal' />
                <div>  
                <LightTypography
                    text={`${t('assignment.Submitted on').toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")} ${submissionDate}`}
                    fontSize='0.75rem'
                    fontWeight='400'
                    lineHeight='normal'
                />
                </div>
                {/* <Typography text={`Survey title goes here`} fontSize='1.125rem' fontWeight='400' lineHeight='normal' /> */}
                {/* <LightTypography
                    text={`SUBMITED ON TUESDAY 2!ST 2020, AT 13:30 PM`}
                    fontSize='0.75rem'
                    fontWeight='400'
                    lineHeight='normal'
                /> */}
            </AssignementContainer> 
        </>
    )
}
export default StatisticsAssignement;
