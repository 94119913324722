import Tooltip from "../toolTip/ToolTip";
import { ToggleContainer, ToggleInput, ToggleSlider } from "./ToggleButton.style";
import React, { ChangeEventHandler } from 'react';
interface ToggleButtonProps{
    checked: boolean;
  onChange?:  ChangeEventHandler<HTMLInputElement>;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ checked, onChange }) => {   
  return (
    
    <ToggleContainer>
        <ToggleInput checked={checked} onChange={onChange} />
        <ToggleSlider />
      </ToggleContainer>

    );
  };
  
  export default ToggleButton;