import { ActionsHeaderText } from "../../../../../interfaces/AnalyticsCommonInterfaces";

export const CompetencyHeaderData :ActionsHeaderText[] = [ 
{
  id:"SDFGHJK",
  text:"Outcome",
},
{
  id:"SDFGwertyuioHJK",
  text:"Activity",
},
{
  id:"ertyu7i87uytr",
  text:"Coverage",
},
{
  id:"frgtyhjuioiuy",
  text:"Proficiency Levels",
},

{
  id:"defrgthyju",
  text:"Action",
},

];