import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { TranslateTime } from '../../../utils/TimeCoverter';
import { CourseCardHover } from './CourseCardHover';
import { get } from '../../../utils/AxiosRequests';

interface hoveredCardParent {
    teacherId: string;
    profilePicture: string;
    classId: string;
    isHovered: boolean;
}
interface ParticipantsInterface {
    profilePicture: string;
    username: string;
    email: string;
    role: string;
    sourceId: string;
}

export default function MainOnHover({ teacherId, isHovered, classId }: hoveredCardParent) {
    const [loading, setLoading] = useState(true);
    const [teachersInfo, setTeachersInfo] = useState<any[]>([]);
    const [currentTeacherIndex, setCurrentTeacherIndex] = useState<number>(0);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchTeachers = async () => {
            setLoading(true);
            try {
                const tenantName = localStorage.getItem("tenant");
                const participantData = await get(`participant/class/${classId}/${tenantName}`);

                // Filter participants based on role
                if (participantData.data.length > 0) {
                    const instructors = participantData.data.filter(
                        (participant: ParticipantsInterface) => participant.role === 'Teacher'
                    );
                    // Fetch additional info for each instructor
                    const instructorsInfoPromises = instructors.map(async (instructor: any) => {
                        const response = await axios.get(
                            `https://9ws5f31duk.execute-api.eu-west-1.amazonaws.com/dev/teacher/academic/${tenantName}/${instructor.sourceId}`
                        );

                        // if (!response.data.data.officeHours) return null;

                        const officeHours: { [key: string]: string[] } = response.data.data.officeHours;
                        const hoursResponse = Object.values(officeHours).map((dayHours: string[]) => {
                            return dayHours
                                .map((timeRange) => {
                                    const [start, end] = timeRange.split("-");
                                    return `${TranslateTime(start, t)}-${TranslateTime(end, t)}`;
                                })
                                .sort(); // Sort the converted time ranges
                        });

                        const daysResponse = Object.keys(response.data.data.officeHours);

                        const hasOfficeHours = hoursResponse.some((hour: any) => hour.length > 0);

                        return {
                            ...instructor,
                            officeHours: hoursResponse,
                            days: daysResponse,
                            hours: hoursResponse,
                            hasOfficeHours: hasOfficeHours,
                            teacherData: response.data.data,
                        };
                    });

                    const instructorsInfo = await Promise.all(instructorsInfoPromises);
                    // const filteredInstructorsInfo = instructorsInfo.filter(info => info !== null);
                    const sortedInstructorsInfo = instructorsInfo.sort((a, b) => {
                        if (a.sourceId === teacherId) return -1;
                        if (b.sourceId === teacherId) return 1;
                        return 0;
                    });
                    // Set the initial teacher index to the teacherId
                    const initialTeacherIndex = sortedInstructorsInfo.findIndex(teacher => teacher.sourceId === teacherId);
                    setCurrentTeacherIndex(initialTeacherIndex >= 0 ? initialTeacherIndex : 0);
                    setTeachersInfo(sortedInstructorsInfo);
                }
                setLoading(false);

            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
        fetchTeachers();
    }, [classId]);

    const handlePrev = () => {
        setCurrentTeacherIndex((prevIndex) => (prevIndex - 1 + teachersInfo.length) <= 0 ? 0 : prevIndex - 1);
    };

    const handleNext = () => {
        setCurrentTeacherIndex((prevIndex) => (prevIndex + 1) >= teachersInfo.length ? prevIndex : prevIndex + 1);
    };

    return (
        <>
            {teachersInfo.length > 0 && (
                <div>
                    {/* <button onClick={handlePrev} disabled={currentTeacherIndex <= 0}>Prev</button> */}
                    <CourseCardHover
                        isHovered={isHovered}
                        handlePrev={handlePrev}
                        handleNext={handleNext}
                        teacherInfo={teachersInfo[currentTeacherIndex]}
                        classId={classId}
                        profilePicture={teachersInfo[currentTeacherIndex].profilePicture}
                        disabledLeft={currentTeacherIndex <= 0}
                        disabledRight={currentTeacherIndex >= teachersInfo.length - 1}
                        totTeachers={teachersInfo.length === 1}
                        teacherId=''
                    />
                    {/* <button onClick={handleNext} disabled={currentTeacherIndex >= teachersInfo.length - 1}>Next</button> */}
                </div>
            )}
        </>
    );
}