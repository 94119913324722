import React from 'react'
import { ButtonsContainer } from './AllQuestions.style'
import CancelButton from '../../elements/StyledButton/CancelButton'
import { ActionButtonsInterface } from './AllQuestionsInterface'
import { useTranslation } from 'react-i18next'

const ActionButtons = ({handleCancel, handleSave, isLoading} : ActionButtonsInterface) => {
  const { t } = useTranslation();
  return (
    <ButtonsContainer>
    <CancelButton name={t('quiz.Cancel')} color="#D85D66" borderColor="#D85D66" backgroundColor="transparent" onClickFunction={handleCancel}/>
    {isLoading ? (
    <CancelButton name="Loading..." color="#fff" borderColor="#5DD3B3" backgroundColor="#5DD3B3" onClickFunction={()=>{}} disabled={true}/>
    ) : (
    <CancelButton name={t('survey.Save')} color="#fff" borderColor="#5DD3B3" backgroundColor="#5DD3B3" onClickFunction={handleSave}/>
    )}
  </ButtonsContainer>
  )
}

export default ActionButtons