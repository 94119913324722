import React, { useEffect, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import { ReactComponent as SvgPlygonIcon } from "../../assets/icons/Arrow.svg";
import {
  Dropdown,
  Input,
  InputContainer,
  MainIconCont,
  OptionItem,
  PolygonDiv,
} from "./IconDropDown.style";
import { InputWithDropdownProps, Option } from "./IconDropDownInterface";
import { LightTypography, MediumTypography, Typography } from "../fonts/Fonts";
import { FlexDiv } from "../../globalStyles";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useRecoilValue } from "recoil";

// React component
const IconDropDown: React.FC<InputWithDropdownProps> = ({
  disabled,
  value,
  placeholder,
  onChange,
  options,
  width,
  height,
  borderRadius,
  borderColor,
  color,
  fontSize,
  paddingRL,
  icon,
  border,
  mainIcon,
  gap
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [optionName, setOptionName] = useState<string | null>(value);
  const [optionValue, setOptionValue] = useState<string | null>(value);
  const [selectedIcon, setSelectedIcon] = useState<React.ReactNode>(<></>);
  const containerRef = useClickOutside(isOpen, () => setIsOpen(false));

  useEffect(() => {
    setOptionValue(value);
    const selectedOptionObject = options?.find(
      (option) => option.value === value
    );
    setOptionName(selectedOptionObject?.name || null);
    setSelectedIcon(selectedOptionObject ? selectedOptionObject.icon : null);
  }, [value, options]);

  const handleItemClick = (option: Option) => {
    onChange(option.value);
    setOptionName(option.name);
    setOptionValue(option.value);
    setSelectedIcon(option.icon);
    setIsOpen(false);
  };

  const {
    themeTextColor,
    OpacitiesTextColor,
    bg_selectColor,
    inputBorderColor,
    theme,
  } = useRecoilValue(ColorSelector);

  return (
    <InputContainer ref={containerRef}>
      <Input
        onClick={() => setIsOpen(disabled ? false : !isOpen)}
        width={width}
        borderRadius={borderRadius}
        borderColor={borderColor}
        color={color}
        fontSize={fontSize}
        themetextcolor={themeTextColor}
        inputbordercolor={inputBorderColor}
        height={height}
        paddingRL={paddingRL}
        border={border}
        gap={gap}
      >
        <MainIconCont>{mainIcon}</MainIconCont>
        {optionValue ? (
          <FlexDiv gap="0.5rem" pointer="true">
            {icon && icon} {selectedIcon} {optionName}
          </FlexDiv>
        ) : (
          <MediumTypography
            color={color ? color : OpacitiesTextColor}
            text={placeholder || ""}
            fontSize={fontSize || "0.75rem"}
          />
        )}
      </Input>
      {isOpen && (
        <Dropdown
          width={width}
          fontSize={fontSize}
          bg_selectcolor={bg_selectColor}
          theme={theme}
        >
          {options.map((option) => (
            <OptionItem
              key={option.value}
              onClick={() => handleItemClick(option)}
            >
              <FlexDiv gap="0.5rem" pointer="true">
                {option.icon} {option.name}
              </FlexDiv>
            </OptionItem>
          ))}
        </Dropdown>
      )}
    </InputContainer>
  );
};

export default IconDropDown;
