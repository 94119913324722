import styled from "styled-components";
import { theme } from "../../../theme";
export const ShortAnswerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.62rem;
  padding: 0 1.5rem 0 1.5rem;
`;

export const ShortAnswerInputs = styled.input<{
  width: any;
  show: any;
  center: any;
  hasError?: boolean;
  color?: string;
}>`
  width: ${(props) => (props.width ? props.width : "")};
  height: 2.8125rem;
  border-radius: 0.625rem;
  border: 1px solid #57637180;
  border-color: ${(props) => (props.hasError ? "#D85D66" : "#57637180")};
  background: transparent;
  &:dir(ltr) {
    padding-left: ${(props) =>
      props.center === "yes" ? "1.88rem" : "1.25rem"};
  }
  &:dir(rtl) {
    padding-right: ${(props) =>
      props.center === "yes" ? "1.88rem" : "1.25rem"};
  }
  font-family: "Primary Font-Light";
  color: ${(props) => props.color || "white"};
  background: ${(props) => (props.show === "true" ? "#546270" : "")};
  opacity: 0.8;
  &&:focus {
    outline: none;
    color: ${(props) => props.color || "white"};
  }
  &::placeholder {
    opacity: 0.5;
  }
`;
