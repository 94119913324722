import styled from "styled-components";

export const AnalyticForumIdShadowHeaderContainer =styled.div`
display: flex;
width: 100%;
justify-content: space-between;
padding:1.68rem 18% 1.5rem 18%;
justify-content:space-between;
height: 5.5625rem;
border-radius: 0.625rem;
background: #2D3741;
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
`;

export const AnalyticForumIdShadowHeaderDataInfo =styled.div`
display: flex;
flex-direction:column;
height:100%;
justify-content:space-between;
`;

export const AnalyticForumIdShadowHeaderLine=styled.div`
height: 100%;
width:1.2px;
background-color:#576371;
`;

export const AnalyticPointerAction=styled.div`
/* cursor: pointer; */
display: flex;
flex-direction:column;
height:100%;
justify-content:space-between;
`;



////////////////////////////////////////////

export const AnalyticBackIcon=styled.div`
display: flex;
cursor: pointer;
gap:1rem;
align-items:center;
`;



/////////////////////////////






//////////////////////////////////////////////
export const AnalyticsShadowHeaderOneTextContainer =styled.div`
display: flex;
width: 100%;
padding:1.68rem ;
height: 5.5625rem;
border-radius: 0.625rem;
background: #2D3741;
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
gap: 0.5rem;
flex-direction: column;
`;





///////////////////////////////////////////////