import styled from "styled-components";
import { breakpoints } from "../../theme";
// import DateP   ker from 'react-datepicker';
// import TimePicker from 'react-time-picker';

export const TextInputSpace = styled.input<{ fontFamily: string }>`
  width: auto;
  display: flex;
  justify-content: flex-end;
  margin-top: 0.31rem;
  background-color: ${(props) => (props.disabled ? "red" : "transparent")};
  border: 0px solid #576371;
  color: #fff;
  font-size: 0.875rem;
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : "Primary Font-Medium"};
  outline: none;
`;
export const StyledInput = styled.div<{
  isopen: any;
  width?: any;
  border?: any;
  padding?: any;
  height?: any;
}>`
  width: ${(props) => (props.width ? props.width : "18rem")};
  height: ${(props) => (props.height ? props.height : "2.8125rem")};
  border-radius: ${(props) =>
    props.isopen === "true" ? "0.625rem 0.625rem 0rem 0rem" : "0.625rem"};
  border: ${(props) => (props.border ? props.border : "1px solid #576371")};

  /* padding: 0.87rem 0.87rem 0.87rem 0.87rem; */
  padding: ${(props) =>
    props.padding ? props.padding : "0.87rem 0.87rem 0.87rem 0.87rem"};

  ::placeholder {
    font-family: "Primary Font-Light";
    font-size: 0.75rem;
    color: gray;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  /* @media (max-width: ${breakpoints.large}) {
    width: 27.8125rem;
    height: 3.4375rem;
  }
  @media (max-width: ${breakpoints.small}) {
    width: 20.9925rem;
    height: 3.4375rem;
  } */
`;
export const SelectContainer = styled.div<{
  zIndex: number;
  isOpen?: boolean;
  color?: string;
}>`
  position: relative;
  cursor: pointer;
  z-index: ${(props) => (props.isOpen ? props.zIndex + 1 : props.zIndex)};
  color: ${(props) => props.color || "#000"};
  font-size: 0.875rem;
  font-family: "Primary Font-Medium";
`;
export const Select = styled.div<{ color: string }>`
  /* background-color: cyan; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  & ::placeholder {
    color: #546270 !important;
  }
  /* &:hover {
    color: ${(props) => (props.color ? props.color : "")};
  } */
`;
export const Arrow = styled.div<{ marginLeft: any; marginRight?: string }>`
  margin-right: ${(props) =>
    props.marginRight ? props.marginRight : "0.81rem"};
  opacity: 0.7;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
  margin-top: -0.1rem;
  &:hover {
    opacity: 1;
    transition: opacity 0.2s ease-in;
  }
`;
// export const DatePickerStyle = styled(DatePicker)`
// background-color:transparent;
// border: 0px solid #576371;
// color:#fff;
// font-size: 0.875rem;
// font-family: 'Primary Font-Medium';
// font-size: 0.875rem;
// height: 1.2rem;
// min-width: 18rem;
// width: 100%;
// &:focus {
//     border: 0px solid #576371;
//     outline: none;
//   }
// `;
// export const TimePickerStyle= styled(TimePicker)`
// background-color:transparent;
// border: 0px solid #576371;
// color:#fff;
// font-size: 0.875rem;
// font-family: 'Primary Font-Medium';
// font-size: 0.875rem;
// height: 1.2rem;
// min-width: 18rem;
// width: 100%;
// &:focus {
//     border: 0px solid #576371;
//     outline: none;
//   }
// `;
// export const TextAreaLongTextAreaInput = styled.textarea`
//   border-radius: 0.6rem;
//   border: 1px solid #576371;
//   box-sizing: border-box;
//   background-color: transparent;
//   color:#fff;
//    min-height:11rem;
//   width:100%;
//   padding:1rem;
//   outline: none;
//   resize: none;
//   font-family: 'Primary Font-Medium';
// font-size: 0.875rem;
// font-style: normal;
// font-weight: 400;
// line-height: 1.375rem;
//   &::placeholder{
// font-family: 'Primary Font-Medium';
// font-size: 0.875rem;
// color: #FFF ;
// /* width: 11.23094rem; */
// font-style: normal;
// font-weight: 400;
// line-height: 1.375rem;
// }
//   @media (max-width: ${breakpoints.tablets}) {
//     width:20rem;
//   }
// `;

export const OptionsContainer = styled.div<{ optionWidth?: any }>`
  position: relative;
  top: 0.8rem;
  bottom: 0;
  &:dir(ltr) {
    right: 0.95rem;
  }
  &:dir(rtl) {
    left: 0.95rem;
  }
  width: ${(props) => (props.optionWidth ? props.optionWidth : "18rem")};
  border-radius: 0 0 0.5rem 0.5rem;
`;

export const Options = styled.div<{
  index: any;
  lastitem: any;
  display: string;
  optionIcon?: React.ReactNode | null;
  fontFamily: string;
  padding?: string;
  fontSize?: string;
}>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "0.875rem")};
  cursor: pointer;
  padding: ${(props) => (props.padding ? props.padding : "1rem")};
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : "Primary Font-Bold"};
  display: ${(props) => (props.display ? props.display : "flex")};
  gap: 0.63rem;
  border-radius: ${(props) =>
    props.lastitem === props.index ? "0rem 0rem 0.625rem 0.625rem" : ""};
  &:hover {
    background: #5dd3b3;
  }
`;

export const MainOptionContainer = styled.div<{ fontSize?: any }>`
  display: flex;
  gap: 0.63rem;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "0.875rem")};
`;
