import { MenuWrapper, Frame } from "./DropDownFilter.style";
import DropDownFilterData from "../dropDownFilterData";
import { PrimaryFontBoldText } from "../../../elements/fonts/Fonts";

const DropDownFilter = (): JSX.Element => {
  return (
    <MenuWrapper>
      {DropDownFilterData.map((item, index) => (
        <Frame
          key={index}
          style={{
            borderRadius: `${
              index === 0
                ? "0.3125rem 0.3125rem 0rem 0rem"
                : index === DropDownFilterData.length - 1
                ? " 0rem 0rem 0.3125rem 0.3125rem"
                : ""
            }`,
          }}
        >
          <PrimaryFontBoldText text={item.title} fontSize=" 0.875rem" />
        </Frame>
      ))}
    </MenuWrapper>
  );
};

export default DropDownFilter;
