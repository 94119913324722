import React from "react";
import { Input, InputContainer } from "./StyledInput.style";
import { StyledInputInterface } from "./StyledInputInterface";
import { LightTypography } from "../fonts/Fonts";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { handleWheel } from "../../utils/OnWheelFunction";

const StyledInput = ({
  width,
  color,
  borderRadius,
  height,
  placeholder,
  value,
  type = "text",
  onChange,
  withPercentage = false,
  rightPlaceholder,
  id,
  borderColor,
  fontSize,
  padding,
  textAlign,
  readOnly= false
}: StyledInputInterface) => {
  const { inputBorderColor, themeTextColor, theme } = useRecoilValue(ColorSelector);


  return (
    <InputContainer className="InputContainer">
      <Input
        id={id ? id : ''}
        width={width}
        color={color}
        borderRadius={borderRadius}
        height={height}
        placeholder={placeholder}
        value={value}
        type={type}
        onChange={onChange}
        inputbordercolor={borderColor || inputBorderColor}
        themetextcolor={themeTextColor}
        onWheel={handleWheel}
        fontSize={fontSize}
        padding={padding}
        textAlign={textAlign}
        readOnly={readOnly}
      />
      {withPercentage && (
        <span
          style={{
            position: "absolute",
            top: "50%",
            right: "1.25rem",
            transform: "translateY(-50%)",
          }}
        >
          <LightTypography
            text={rightPlaceholder ? rightPlaceholder : "/ 100"}
            fontSize="0.855rem"
            color={inputBorderColor}
          />
        </span>
      )}
    </InputContainer>
  );
};

export default StyledInput;
