import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  SRLDivArrowsWrapper,
  ScrollLeftAndRightBody,
  ScrollLeftAndRightContainer,
  ScrollLeftAndRightLine,
} from "./ScrollLeftAndRight.style";
import { SvgNextArrowCourseBannerIcon, SvgPrevArrowIcon } from "../Icons";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
interface ScrollLeftAndRightInerface {
  justifyContent?: string;
  paddingRight?: string;
  paddingLeft?: string;
  content: any;
  Width?: string;
  MarginTop?: string;
  FixedBodyContainer?: any;
  LineWidth?: string;
  AlignSelf?: string;
  SmallAdditionalWidth?: string;
  children?: any;
  TabletAdditionalWidth?: string;
  position?: string;
}
const ScrollLeftAndRight = ({
  justifyContent = "right",
  paddingRight = "",
  paddingLeft = "",
  Width = "100%",
  content,
  MarginTop = "0",
  FixedBodyContainer,
  LineWidth,
  children,
  AlignSelf,
  SmallAdditionalWidth,
  position,
  TabletAdditionalWidth,
}: ScrollLeftAndRightInerface) => {
  const columnWidth = 378;
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollLeft = () => {
    if (tableContainerRef.current) {
      const newScrollPosition =
        tableContainerRef.current.scrollLeft - columnWidth;
      tableContainerRef.current.scrollBy({
        left: -columnWidth,
        behavior: "smooth",
      });
      setCurrentIndex(Math.max(0, newScrollPosition / columnWidth));
    }
  };

  const scrollRight = () => {
    if (tableContainerRef.current) {
      const newScrollPosition =
        tableContainerRef.current.scrollLeft + columnWidth;
      tableContainerRef.current.scrollBy({
        left: columnWidth,
        behavior: "smooth",
      });
      const maxIndex =
        Math.floor(tableContainerRef.current.scrollWidth / columnWidth) - 1;
      setCurrentIndex(Math.min(maxIndex, newScrollPosition / columnWidth));
    }
  };
  useEffect(() => {
    if (tableContainerRef.current) {
      const newScrollPosition = currentIndex * columnWidth;
      tableContainerRef.current.scrollLeft = newScrollPosition;
      setScrollPosition(newScrollPosition);

      // Check if you can scroll left
      if (newScrollPosition <= 0) {
        setCanScrollLeft(false);
      } else {
        setCanScrollLeft(true);
      }

      // Check if you can scroll right
      if (
        tableContainerRef.current.scrollWidth - newScrollPosition <=
        tableContainerRef.current.clientWidth
      ) {
        setCanScrollRight(false);
      } else {
        setCanScrollRight(true);
      }
    }
    // console.log("useEffect 3: ");
  }, [currentIndex]);
  const { Scroller_BgColor, boxShadow } = useRecoilValue(ColorSelector);
  return (
    <ScrollLeftAndRightContainer
      BigContainerWidth={Width}
      SmallAdditionalWidth={SmallAdditionalWidth}
      TabletAdditionalWidth={TabletAdditionalWidth}
    >
      <ScrollLeftAndRightBody
        ref={tableContainerRef}
        onScroll={(e) => setScrollPosition(e.currentTarget.scrollLeft)}

        // content={content}
      >
        {content}
      </ScrollLeftAndRightBody>
      <div>{FixedBodyContainer}</div>
      <ScrollLeftAndRightLine
        paddingRight={paddingRight}
        paddingLeft={paddingLeft}
        justifyContent={justifyContent}
        MarginTop={MarginTop}
        AlignSelf={AlignSelf}
        LineWidth={LineWidth}
        position={position}
      >
        <SRLDivArrowsWrapper bgcolor={Scroller_BgColor} boxshadow={boxShadow}>
          <SvgPrevArrowIcon
            onClick={scrollLeft}
            onMouseEnter={scrollLeft}
            // fill={scrollPosition === 0 ? '#898e94' : 'white'}
            style={{ height: "0.8rem" }}
          />

          <SvgNextArrowCourseBannerIcon
            style={{ height: "0.8rem" }}
            onClick={scrollRight}
            onMouseEnter={scrollRight}
            // fill={scrollPosition >= columnsToDisplay.length * columnWidth - numColumnsVisible ? '#898e94' : 'white'}
          />
        </SRLDivArrowsWrapper>
      </ScrollLeftAndRightLine>
    </ScrollLeftAndRightContainer>
  );
};

export default ScrollLeftAndRight;
