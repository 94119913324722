import { FC } from "react";
import { Oval } from "react-loader-spinner";
import { Container } from "./Loader.style";
import { Typography } from "../../elements/fonts/Fonts";

interface LoaderInterface {
  margin?: string;
  text?: string;
  width?: number;
  height?: number;
  color?: string;
  secondaryColor?: string;
}
const Loader: FC<LoaderInterface> = ({
  margin,
  text,
  width,
  height,
  color,
  secondaryColor,
}) => {
  return (
    <Container style={{ margin: margin }}>
      <Typography fontSize="1.25rem" text={text || ""} />
      <Oval
        height={height || 35}
        width={width || 35}
        color={color || "#5DD3B3"}
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor={secondaryColor || "gray"}
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </Container>
  );
};

export default Loader;
