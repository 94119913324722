import styled from "styled-components";

export const BadgeContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`
export const ModuleName = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 0.5rem;
margin-top: 0.94rem;
`
export const ButtonsContainer = styled.div`
display: flex;
align-items: center;
gap: 0.63rem;
margin-top: 1.25rem;
`
export const Button = styled.button`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 10.625rem;
height: 4.875rem;
border-radius: 1.25rem;
background: #3D4954;
gap: 0.62rem;
border: none;
color: #fff;
cursor: pointer;
`
export const PointsContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-evenly;
width: 35.25rem;
height: 10.375rem;
background: linear-gradient(90deg, #415160 0%, #293440 107.88%);
margin: 1.88rem 0 1.25rem 0;
`
export const StarContainer = styled.div`
display: flex;
align-items: center;
flex-direction: column;
gap: 0.5rem;
`