import React, { useState, useEffect, useRef, useContext } from "react";
import {
  StudentFirstRender,
  StudentQuizViewLayout,
  StudentSecondRender,
  StudentTextStyle,
  StudentQuizPagination,
  AttemptContainer,
  TimerContainer,
  InsideTimerContainer,
  GradeContainerAbsolute,
  GradeContainer,
  AttempContainerAbsolute,
  CancelButtonContainer,
} from "./StudentQuizView.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import StudentQuizCheckbox from "../../elements/StudentsQuizElements/StudentQuizCheckbox/StudentQuizCheckbox";
import StudentQuizSelect from "../../elements/StudentsQuizElements/StudentQuizSelect/StudentQuizSelect";
import StudentQuizEssay from "../../elements/StudentsQuizElements/StudentQuizEssay/StudentQuizEssay";
import StudentQuizShortAnswer from "../../elements/StudentsQuizElements/StudentQuizShortAnswer/StudentQuizShortAnswer";
import StudentQuizNumericalAnswer from "../../elements/StudentsQuizElements/StudentQuizNumerical/StudentQuizNumerical";
import StudentQuizTrueOrFalse from "../../elements/StudentsQuizElements/StudentQuizTruOrFalse/StudentQuizTrueOrFalse";
import BlueButton from "../../elements/blueButton/BlueButton";
import Pagination from "../../elements/Pagination/Pagination";
import { get, post } from "../../utils/AxiosRequests";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import DOMPurify from "dompurify";
import { ToastContainer, toast } from "react-toastify";
import {
  SvgChecked,
  SvgDone,
  SvgEyeIcon,
  SvgFalse,
  SvgEyeSlash,
} from "../../elements/Icons";
import { CommentContainer } from "../../elements/StudentsQuizElements/StudentQuizCheckbox/StudentQuizCheckbox.style";
import {
  findStudentGrade,
  formatTime,
  formatingAnswerData,
  returnInitialiseStates,
} from "./studentQuizViewUtils";
import StudentQuizViewBottom from "./StudentQuizViewBottom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { WebSocketConnector } from "../../Views/messages/webSocketConnection";
import { WebsocketContext } from "../../contexts/notificationContext";
import { getFormattedDate } from "../../utils/GetFormattedDate";
import { toArabicDigits, toArabicLetter } from "../../utils/formatDayArabic";
import { adminState } from "../../recoil/RecoilStates";

interface CheckboxItem {
  id: number;
  choice: string;
  isChecked?: boolean;
}
interface studentQuizViewProps {
  quizId?: string;
}
const StudentQuizView = ({ quizId }: studentQuizViewProps) => {
  const { progressChanged, setProgressChange } = useContext(ClassContext);

  // const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [PerPage, setPerPage] = useState<number>(2);
  const [fileResponse, setFileResponse] = useState<{
    [key: string]: string | null;
  }>({});
  const [essayDescriptions, setEssayDescriptions] = useState<{
    [key: string]: string;
  }>({});
  const [shortAnswer, setshortAnswer] = useState<{ [key: string]: string }>({});
  const [numerical, setNumerical] = useState<{ [key: string]: string }>({});
  const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: File[] }>(
    {}
  );
  const [selectedChoices, setSelectedChoices] = useState<{
    [key: string]: CheckboxItem[] | undefined;
  }>({});
  const [selectedMatchingChoices, setSelectedMatchingChoices] = useState<{
    [key: string]: any[];
  }>({});
  const [truOrFalseAnswer, setTruOrFalseAnswer] = useState<{
    [key: string]: boolean | undefined;
  }>({});
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [studentResponse, setStudentResponse] = useState<any>({});
  const [StudentQuizViwData, setStudentQuizViwData] = useState<any[]>([]);
  const [questionId, setQuestionId] = useState<{ [key: string]: string }>({});
  const [quizTemplateQuestionsId, setQuizTemplateQuestionsId] = useState<{
    [key: string]: number;
  }>({});
  const [questionType, setQuestionType] = useState<{ [key: string]: string }>(
    {}
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [submissionLoading, setSubmissionLoading] = useState<boolean>(false);
  const [showReSubmitButton, setShowReSubmitButton] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [started, setStarted] = useState<boolean>(false);
  const [timerStop, setTimerStop] = useState<boolean>(false);
  const [restNumberOfAttempts, setRestNumberOfAttempts] = useState<number>(0);
  const [totalNumberOfAttempts, setTotalNumberOfAttempts] = useState<number>(0);
  const [isCorrect, setIsCorrect] = useState<{
    [key: string]: boolean | null | string;
  }>({});
  const [showGrade, setShowGrade] = useState<boolean>(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [showTimer, setShowTimer] = useState<boolean>(true);
  const [quizMaxGrade, setQuizMaxGrade] = useState<number>(0);
  const [totalPoints, setTotalPoints] = useState<number>(0);
  const [countPoints, setCountPoints] = useState<number>(0);
  const [attemptSubmited, setAttemptSubmited] = useState<number>(0);
  const [attemptComment, setAttempComment] = useState<string>("");
  const [StartDateTime, setStartDateTime] = useState<Date>();
  const [cutOfDateTime, setCutOffDateTime] = useState<Date>();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showPublishGrade, setShowPublishGrade] = useState(false);
  const [quizTitle, setQuizTitle] = useState<string>("");
  // const [corrected, setCorrected] = useState<boolean>(false);
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const quizRef = useRef<HTMLDivElement | null>(null);
  const params = useParams();
  const classId = params.classId;
  const { Container_Gradient_BgColor, boxShadow } =
    useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  const language = localStorage.getItem("language");
  const { isReady, value, send, connect } = useContext(WebsocketContext);
  const [previewMode, setPreviewMode] = useState<boolean>(false);
  const [isAdmin] = useRecoilState(adminState);
  useEffect(() => {
    if (
      !isReady &&
      tenantName !== null &&
      tenantName !== undefined &&
      subIdSplitted !== null &&
      subIdSplitted !== undefined
    ) {
      connect();
    }
  }, [tenantName, subIdSplitted]);

  // Fetch Quiz Data from the dataBase
  const fecthQuizData = async () => {
    try {
      setDataLoading(true);
      const quizData = await get(`quiz/questions/${quizId}/${tenantName}`);
      // Format the Questions of the template to calculate the total points of the Template
      const processedQuestions = quizData.data.questions.map(
        (question: any) => {
          if (question.type === "COMPOSITE") {
            const compositeParts = question.questionParts.map((part: any) => {
              const modifiedTitle = `${question.title} ${part.title}`;
              return { ...part, title: modifiedTitle };
            });
            return [...compositeParts];
          } else {
            return [question];
          }
        }
      );
      const flattenedQuestions = processedQuestions.flat();
      // Claculating the Total points of the Template
      const totalPoint = flattenedQuestions.reduce(
        (accumulator: any, question: any) => {
          return accumulator + (question.points || 0);
        },
        0
      );
      // Set The Details of the Quiz
      setTotalPoints(totalPoint);
      setQuizTitle(quizData.data.title);
      setStudentQuizViwData(quizData.data.questions);
      setTotalNumberOfAttempts(quizData.data.numberOfAttempts);
      // setShowGrade(quizData.data.showGradeAfterSubmission)
      setQuizMaxGrade(quizData.data.maxGrade);
      // Fetch and set the timer from local storage if it exists
      const savedQuizTimer = JSON.parse(
        localStorage.getItem("quizTimer") || "[]"
      );
      const currentQuizTimer = savedQuizTimer.find(
        (item: any) =>
          item.classSourceId === classId &&
          item.subId === subIdSplitted &&
          item.refId === quizId
      );

      if (currentQuizTimer) {
        setCurrentTime(currentQuizTimer.time);
      } else {
        setCurrentTime(quizData.data.duration * 60);
      }
      const activityPublish = await axios.get(
        `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/activity/publish/${quizId}/Quiz/${tenantName}`
      );
      if (
        activityPublish &&
        activityPublish.data &&
        activityPublish.data.data &&
        activityPublish.data.data.length > 0
      ) {
        setShowPublishGrade(
          activityPublish.data.data[0].publish === 1 ? true : false
        );
      } else {
        setShowPublishGrade(false);
      }
      const studentAnswerData = await get(
        `quiz/get/answer/${subIdSplitted}/${quizId}/${tenantName}?provider=${provider}`
      );
      if (studentAnswerData && studentAnswerData.data) {
        // Check If There is Data
        setRestNumberOfAttempts(studentAnswerData.data.restOfAttempts);
        setCountPoints(studentAnswerData.data.totalGrade);
        setAttemptSubmited(studentAnswerData.data.numberOfAttemptsSubmit);
        setAttempComment(studentAnswerData.data.commentAttempt);
      } else {
        setRestNumberOfAttempts(quizData.data.numberOfAttempts);
      }
      if (
        studentAnswerData &&
        studentAnswerData.data &&
        studentAnswerData.data.lastAnswersOfStudent
      ) {
        // If Student Has An Answer
        setStudentResponse(studentAnswerData.data.lastAnswersOfStudent);
        setShowReSubmitButton(true);
        setShowPreview(true);
      } else {
        setStudentResponse([]);
        setShowReSubmitButton(false);
        setShowPreview(false);
      }
      if (
        quizData &&
        quizData.data &&
        quizData.data.startDateTime &&
        quizData.data.cutOffDateTime
      ) {
        // Check if The Quiz is Valid
        const currentDate = new Date();
        const startDateTime = new Date(quizData.data.startDateTime);
        const dueDateTime = new Date(quizData.data.cutOffDateTime);
        setStartDateTime(startDateTime);
        setCutOffDateTime(dueDateTime);
        const isValid =
          currentDate >= startDateTime && currentDate <= dueDateTime;
        setValid(isValid);
      } else if (quizData && quizData.data && quizData.data.startDateTime) {
        const currentDate = new Date();
        const startDateTime = new Date(quizData.data.startDateTime);
        const isValid = currentDate >= startDateTime;
        setValid(isValid);
      } else {
        setValid(true);
      }
      setDataLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  // Emptying the answer of the student when the component mount
  useEffect(() => {
    setStarted(false);
    fecthQuizData();
    setIsCorrect({});
    setEssayDescriptions({});
    setFileResponse({});
    setNumerical({});
    setSelectedChoices({});
    setSelectedMatchingChoices({});
    setTruOrFalseAnswer({});
    setshortAnswer({});
  }, [quizId]);

  // Sorting The Questions
  StudentQuizViwData.sort((a, b) => {
    if (a.questionIndex && b.questionIndex) {
      return a.questionIndex - b.questionIndex;
    }
    return 0;
  });

  // Call the returnInitialiseStates function to initialize the States object
  useEffect(() => {
    setSelectedChoices(
      returnInitialiseStates(StudentQuizViwData).initialSelectedChoices
    );
    setSelectedMatchingChoices(
      returnInitialiseStates(StudentQuizViwData).initialSelectedMatchingChoices
    );
    setEssayDescriptions(
      returnInitialiseStates(StudentQuizViwData).initialDescriptions
    );
    setshortAnswer(
      returnInitialiseStates(StudentQuizViwData).initialShortAnswers
    );
    setNumerical(returnInitialiseStates(StudentQuizViwData).initialNumerical);
    setSelectedFiles(
      returnInitialiseStates(StudentQuizViwData).initialFileDescriptions
    );
    setTruOrFalseAnswer(
      returnInitialiseStates(StudentQuizViwData).initialTrueOrFalseAnswers
    );
  }, []);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const indexOfLast = currentPage * PerPage;
  const indexOfFirst = indexOfLast - PerPage;

  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };

  // Handle Re-Submit Button
  const handleReSubmit = (outside?: boolean) => {
    // const timerData = {
    //   subId: subIdSplitted,
    //   classSourceId: classId,
    //   refId: quizId,
    //   // No need to include `time` since we are just identifying the object to remove
    // };
    // let quizAnswers = JSON.parse(localStorage.getItem('localStudentResponse') || '[]');

    //   // Find the index of the object that matches the criteria
    //   const Answersindex = quizAnswers.findIndex((item: any) =>
    //     item.classSourceId === timerData.classSourceId &&
    //     item.subId === timerData.subId &&
    //     item.refId === timerData.refId
    //   );

    //   // If the object exists, remove it from the array
    //   if (Answersindex !== -1) {
    //     quizAnswers.splice(Answersindex, 1);
    //   }

    //   // Save the updated array back to local storage
    //   localStorage.setItem('localStudentResponse', JSON.stringify(quizAnswers));
    setStudentResponse([]);
    setShowReSubmitButton(false);
    setStarted(true);
    setCurrentPage(1);
    setEssayDescriptions({});
    setSelectedFiles({});
    setFileResponse({});
    setNumerical({});
    setSelectedChoices({});
    setSelectedMatchingChoices({});
    setTruOrFalseAnswer({});
    setshortAnswer({});
    setCurrentPage(1);
    if (outside) {
      setShowPreview(false);
    }
  };

  // Handle Start Button
  const handleStartQuiz = () => {
    setStarted(true);
    toast.info(
      t(
        "course view.Your responses will be saved automatically as you enter them."
      )
    );
  };

  // Call the toestify when the student answer
  const handleSuccess = (text: any) => {
    const success = () => toast.success(`${text}`);
    success();
  };

  // call the error toestify when the student doesnt have more attempt
  const handleError = (text: any) => {
    const error = () => toast.error(`${text}`);
    error();
  };

  const attemptsMessage = (response: string) => {
    // Remove quotes and split the string based on the known format
    const parts = response.split(" ");

    // Assuming the format is "You have '5' attempts left"
    const firstPart = parts.slice(0, 2).join(" ");
    const numberPart = parts[2].replace(/'/g, "");
    const lastPart = parts.slice(3).join(" ");
    return language === "tr"
      ? `${numberPart} ${t(`quiz.${firstPart} ${lastPart}`)}`
      : `${t(`quiz.${firstPart}`)} ${toArabicDigits(numberPart)} ${t(
          `quiz.${lastPart}`
        )}`;
  };

  const totalDataCount = StudentQuizViwData.length;
  const remainingDataCount = totalDataCount - indexOfLast;

  // This Function is called when the student answer
  const handelSave = async (automaticaly?: boolean) => {
    if (!automaticaly && remainingDataCount > 0) {
      // Move to the next page if there is more data
      setCurrentPage(currentPage + 1);
    } else {
      setPreviewMode(false);
      const timerData = {
        subId: subIdSplitted,
        provider: provider,
        classSourceId: classId,
        refId: quizId,
        // No need to include `time` since we are just identifying the object to remove
      };

      let quizTimer = JSON.parse(localStorage.getItem("quizTimer") || "[]");

      // Find the index of the object that matches the criteria
      const index = quizTimer.findIndex(
        (item: any) =>
          item.classSourceId === timerData.classSourceId &&
          item.subId === timerData.subId &&
          item.refId === timerData.refId
      );

      // If the object exists, remove it from the array
      if (index !== -1) {
        quizTimer.splice(index, 1);
      }

      // Save the updated array back to local storage
      localStorage.setItem("quizTimer", JSON.stringify(quizTimer));

      let quizAnswers = JSON.parse(
        localStorage.getItem("localStudentResponse") || "[]"
      );

      // Find the index of the object that matches the criteria
      const Answersindex = quizAnswers.findIndex(
        (item: any) =>
          item.classSourceId === timerData.classSourceId &&
          item.subId === timerData.subId &&
          item.refId === timerData.refId
      );

      // If the object exists, remove it from the array
      if (Answersindex !== -1) {
        quizAnswers.splice(Answersindex, 1);
      }

      // Save the updated array back to local storage
      localStorage.setItem("localStudentResponse", JSON.stringify(quizAnswers));

      setLoading(true);
      // Call The formatingAnswerData Function to format the answer of the student
      const transformedArray = formatingAnswerData(
        selectedChoices,
        essayDescriptions,
        numerical,
        shortAnswer,
        selectedMatchingChoices,
        truOrFalseAnswer,
        questionId,
        quizTemplateQuestionsId,
        questionType,
        fileResponse
      );
      const data = {
        subId: subIdSplitted,
        provider: provider,
        quizActivityId: quizId,
        answers: transformedArray,
      };
      const trackingData = {
        classSourceId: classId,
        length: "",
        percentage: 100,
        refId: quizId,
        type: "Quiz",
        subId: subIdSplitted,
        provider: provider,
      };
      try {
        setSubmissionLoading(true);
        setTimerStop(true);
        const response = await post(`quiz/create/answer/${tenantName}`, data);
        const trackinResponse = await post(
          `completionTracking/create/${tenantName}`,
          trackingData
        );
        setProgressChange(!progressChanged);
        if (isReady && send) {
          send(
            JSON.stringify({
              action: "sendPublic",
              data: {
                tenantName: tenantName,
                classSourceId: classId,
                title: quizTitle,
                activityType: "Quiz",
                refId: quizId,
                refTable: "QuizActivities",
                subId: subIdSplitted,
                provider: provider,
                type: "createSubmit",
              },
            })
          );
        }
        setSubmissionLoading(false);
        setShowReSubmitButton(true);
        setStarted(false);
        setTimerStop(false);
        if (
          response &&
          totalNumberOfAttempts !== -1 &&
          response.data &&
          response.data.includes("attempts left")
        ) {
          handleSuccess(attemptsMessage(response.data));
        } else if (
          response &&
          response.data &&
          response.data.includes("You have exceeded")
        ) {
          handleError(response.data + "So your Answer has been not submitted");
        }
        setEssayDescriptions({});
        setFileResponse({});
        setNumerical({});
        setSelectedChoices({});
        setSelectedMatchingChoices({});
        setTruOrFalseAnswer({});
        setshortAnswer({});
        setLoading(false);
        fecthQuizData();
      } catch (err) {
        console.log(err);
      }
    }
  };

  // This Function is called when the timer of the quiz ends
  const handleTimerEnds = async () => {
    await handelSave(true);
  };

  useEffect(() => {
    let timerId: any;
    const updateTimer = async () => {
      setCurrentTime((prevTime) => {
        const newTime = prevTime - 1;

        // Retrieve the quizTimer array from local storage
        let quizTimer = JSON.parse(localStorage.getItem("quizTimer") || "[]");

        // Find the index of the existing timer object
        const index = quizTimer.findIndex(
          (item: any) =>
            item.classSourceId === classId &&
            item.subId === subIdSplitted &&
            item.refId === quizId
        );

        if (index !== -1) {
          // Update the time of the existing object
          quizTimer[index].time = newTime;
        } else {
          // Add a new object if it doesn't exist
          const data = {
            subId: subIdSplitted,
            provider: provider,
            classSourceId: classId,
            refId: quizId,
            time: newTime,
          };
          quizTimer.push(data);
        }

        // Save the updated array back to local storage
        localStorage.setItem("quizTimer", JSON.stringify(quizTimer));

        // Check if the timer has reached zero
        if (newTime === 0) {
          // Stop the timer
          clearInterval(timerId);
          try {
            handleTimerEnds();
          } catch (err) {
            console.log(err);
          }
        }
        return newTime;
      });
    };

    if (!timerStop && started && currentTime > 0) {
      // Start the timer if it's not already started
      timerId = setInterval(updateTimer, 1000);
    }

    return () => {
      // Clean up the timer on component unmount or when it's stopped
      clearInterval(timerId);
    };
  }, [started, currentTime, timerStop]);

  // Toggle the Timer View
  const handleShowTimer = () => {
    setShowTimer(!showTimer);
  };

  // Handle Preview Button
  const hanldeShowPreview = () => {
    setShowPreview(false);
    setCurrentPage(1);
    setPreviewMode(true);
    // const timerData = {
    //   subId: subIdSplitted,
    //   classSourceId: classId,
    //   refId: quizId,
    //   // No need to include `time` since we are just identifying the object to remove
    // };
    // let quizAnswers = JSON.parse(localStorage.getItem('localStudentResponse') || '[]');

    //   // Find the index of the object that matches the criteria
    //   const Answersindex = quizAnswers.findIndex((item: any) =>
    //     item.classSourceId === timerData.classSourceId &&
    //     item.subId === timerData.subId &&
    //     item.refId === timerData.refId
    //   );

    //   // If the object exists, remove it from the array
    //   if (Answersindex !== -1) {
    //     quizAnswers.splice(Answersindex, 1);
    //   }

    //   // Save the updated array back to local storage
    //   localStorage.setItem('localStudentResponse', JSON.stringify(quizAnswers));
  };
  //Handle close Button
  const handleClose = () => {
    setShowPreview(true);
    setPreviewMode(false);
  };

  const splitPart = (partTitle: string) => {
    const [part, letter] = partTitle
      .split(" ")
      .map((str: string) => str.trim());
    return `${t(`quiz.${part}`)} ${toArabicLetter(letter)}`;
  };

  if (showPreview) {
    return (
      <StudentQuizPagination>
        {isAdmin === false ? (
          <>
            {/* Show the re-submit button if the student has answered and still have available attempt */}
            {valid &&
            !submissionLoading &&
            !loading &&
            showReSubmitButton &&
            restNumberOfAttempts !== 0 ? (
              <BlueButton
                text={t("quiz.Re-Submit")}
                OnClick={() => handleReSubmit(true)}
                Width="8.8125rem"
                Height="2.1875rem"
                BackColor="transparent"
                Color="#5DD3B3"
                Border="1px solid #5DD3B3"
              />
            ) : null}
            {/* SHow the preview button if the student has an answer, The preview Button let the user to see his answer and re-submit */}
            <BlueButton
              text={t("assignment.preview")}
              OnClick={hanldeShowPreview}
              Width="8.8125rem"
              Height="2.1875rem"
              BackColor="#5DD3B3"
              Border="1px solid transparent"
            />
          </>
        ) : (
          <></>
        )}
      </StudentQuizPagination>
    );
  }

  if (dataLoading) {
    {
      /* Show Loading when the data doesnt fetched yet */
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography text={`${t("general.loading")}...`} />
      </div>
    );
  }

  if (valid && !started && !showReSubmitButton && restNumberOfAttempts !== 0) {
    {
      /* Show the start Button if the student has not answerd on the quiz */
    }
    return (
      <StudentQuizPagination>
        {isAdmin === false ? (
          <BlueButton
            text={t("quiz.Start")}
            OnClick={handleStartQuiz}
            Width="8.8125rem"
            Height="2.1875rem"
            BackColor="#5DD3B3"
          />
        ) : (
          ""
        )}
      </StudentQuizPagination>
    );
  }
  if (
    StartDateTime &&
    new Date() <= StartDateTime &&
    studentResponse &&
    studentResponse.length === 0 &&
    cutOfDateTime
  ) {
    {
      /* Handle if the quiz doesnt started yet */
    }
    return (
      <>
        <Typography
          color="#D85D66"
          text={`The Quiz will Start on ${
            StartDateTime && StartDateTime.toLocaleString()
          } and end on  ${cutOfDateTime && cutOfDateTime.toLocaleString()}`}
        />
      </>
    );
  }
  if (
    cutOfDateTime &&
    new Date() >= cutOfDateTime &&
    studentResponse &&
    studentResponse.length === 0
  ) {
    {
      /* Handle if the quiz has finished and the student doesnt answered */
    }
    return (
      <>
        <Typography
          color="#D85D66"
          text={
            localStorage.getItem("language") === "tr"
              ? `Sınav ${getFormattedDate(
                  cutOfDateTime && cutOfDateTime.toDateString()
                )} tarihinde sona erdi`
              : `${t("quiz.The Quiz has ended on")} ${getFormattedDate(
                  cutOfDateTime && cutOfDateTime.toDateString()
                )}`
          }
        />
      </>
    );
  }
  return (
    <>
      {!started &&
        attemptComment &&
        attemptComment !== "" &&
        studentResponse &&
        studentResponse.length > 0 &&
        showPublishGrade && (
          <>
            {/* Show The Comment of the instructor on the attempt if there is a comment */}
            <MediumTypography text={t("assignment.Instructor Comment")} />
            <CommentContainer>
              <LightTypography text={`${attemptComment}`} fontSize=".8rem" />
            </CommentContainer>
          </>
        )}
      {/* The Rendering of the Questions Start Here */}
      <StudentQuizViewLayout
        ref={quizRef}
        bgcolor={Container_Gradient_BgColor}
        boxshadow={boxShadow}
      >
        {started && (
          <>
            {/* The Timer Container */}
            <TimerContainer>
              <InsideTimerContainer>
                <MediumTypography text={t("quiz.Time Running")} />
                {showTimer ? (
                  <>
                    <SvgEyeIcon
                      style={{ color: "#5DD3B3", cursor: "pointer" }}
                      onClick={handleShowTimer}
                    />
                  </>
                ) : (
                  <SvgEyeSlash
                    style={{ color: "#5DD3B3", cursor: "pointer" }}
                    onClick={handleShowTimer}
                  />
                )}
              </InsideTimerContainer>
              {showTimer && <LightTypography text={formatTime(currentTime)} />}
            </TimerContainer>
          </>
        )}

        {!started &&
          countPoints !== null &&
          countPoints !== undefined &&
          studentResponse &&
          studentResponse.length > 0 &&
          showPublishGrade && (
            <>
              {/* The container of the Grade Of The Student on the quiz */}
              <GradeContainerAbsolute
                attemptComment={
                  attemptComment && attemptComment !== "" ? true : false
                }
              >
                <GradeContainer>
                  <span style={{ color: "#5dd3b3" }}>
                    {Math.round((countPoints / totalPoints) * quizMaxGrade)}
                  </span>{" "}
                  / {quizMaxGrade}
                </GradeContainer>
              </GradeContainerAbsolute>
            </>
          )}

        {!started &&
          totalNumberOfAttempts === -1 &&
          attemptSubmited !== 0 &&
          studentResponse &&
          studentResponse.length > 0 && (
            <>
              {/*The container of the Number of Attempt that the student try it (If The quiz has a limited number of attempt) */}
              <AttempContainerAbsolute
                attemptComment={
                  attemptComment && attemptComment !== "" ? true : false
                }
              >
                <Typography text={`${t("quiz.Attempt")} :${attemptSubmited}`} />
              </AttempContainerAbsolute>
            </>
          )}
        {/* Mapping the Questions of the Quiz Here */}
        {StudentQuizViwData &&
          StudentQuizViwData.length > 0 &&
          StudentQuizViwData.slice(indexOfFirst, indexOfLast).map(
            (question, index) => (
              <StudentFirstRender key={index}>
                {/* Render The Questions Here */}
                <StudentTextStyle>
                  <Typography
                    text={`${t("quiz.Question")} ${toArabicDigits(
                      (index + 1).toString()
                    )}`}
                    color="#5DD3B3"
                  />
                  {/* Show The Grade of the student on each Question (Exept the essay question) */}
                  {question &&
                  question.points &&
                  showPublishGrade &&
                  question.type !== "ESSAY" &&
                  findStudentGrade(
                    question.questionId,
                    question.type,
                    studentResponse
                  ) !== null &&
                  findStudentGrade(
                    question.questionId,
                    question.type,
                    studentResponse
                  ) !== "pending" ? (
                    <div>
                      <span>
                        {findStudentGrade(
                          question.questionId,
                          question.type,
                          studentResponse
                        )}
                      </span>
                      /<span>{question.points}</span>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* Show The Grade of the student on the essay Question */}
                  {question &&
                  question.points &&
                  showPublishGrade &&
                  question.type === "ESSAY" &&
                  findStudentGrade(
                    question.questionId,
                    question.type,
                    studentResponse
                  ) !== 0 &&
                  findStudentGrade(
                    question.questionId,
                    question.type,
                    studentResponse
                  ) !== null &&
                  findStudentGrade(
                    question.questionId,
                    question.type,
                    studentResponse
                  ) !== "pending" ? (
                    <div>
                      <span>
                        {findStudentGrade(
                          question.questionId,
                          question.type,
                          studentResponse
                        )}
                      </span>
                      /<span>{question.points}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </StudentTextStyle>
                {/* Render The Composed Questions (The Composed question has parts) */}
                {question.composed || question.type === "COMPOSITE" ? (
                  question.questionParts &&
                  question.questionParts.map((part: any, idx: number) => (
                    <StudentSecondRender key={idx}>
                      <StudentTextStyle>
                        <TypographyRegularDescription
                          text={splitPart(part.title) || ""}
                          color="#5DD3B3"
                          fontSize="0.875rem"
                        />
                        {/* Show The Grade of the student on each Question (Exept the essay question) */}
                        {part &&
                        part.points &&
                        showPublishGrade &&
                        part.type !== "ESSAY" &&
                        findStudentGrade(
                          part.id,
                          part.type,
                          studentResponse
                        ) !== null &&
                        findStudentGrade(
                          part.id,
                          part.type,
                          studentResponse
                        ) !== "pending" ? (
                          <div>
                            <span>
                              {findStudentGrade(
                                part.id,
                                part.type,
                                studentResponse
                              )}
                            </span>
                            /<span>{part.points}</span>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* Show The Grade of the student on the essay Question */}
                        {part &&
                        part.points &&
                        showPublishGrade &&
                        part.type === "ESSAY" &&
                        findStudentGrade(
                          part.id,
                          part.type,
                          studentResponse
                        ) !== 0 &&
                        findStudentGrade(
                          part.id,
                          part.type,
                          studentResponse
                        ) !== null &&
                        findStudentGrade(
                          part.id,
                          part.type,
                          studentResponse
                        ) !== "pending" ? (
                          <div>
                            <span>
                              {findStudentGrade(
                                part.id,
                                part.type,
                                studentResponse
                              )}
                            </span>
                            /<span>{part.points}</span>
                          </div>
                        ) : (
                          ""
                        )}
                      </StudentTextStyle>
                      {/* <Typography text={part.question || ""}  /> */}
                      <StudentTextStyle>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              decodeHtml(part.question)
                            ),
                          }}
                          style={{
                            fontSize: "0.875rem",
                            fontWeight: "400",
                            fontFamily: "Primary Font-Light",
                          }}
                        ></div>
                        {/* Show SvgDone icon when the question is correct, and SvgFalse icon when it's incorrect */}
                        {(part.type === "SHORT_ANSWER" ||
                          part.type === "NUMERIC" ||
                          part.type === "TF") && (
                          <>
                            {showPublishGrade &&
                            isCorrect[
                              `${question.questionId}_${part.id}_${part.type}_isCorrect`
                            ] !== null &&
                              isCorrect[
                                `${question.questionId}_${part.id}_${part.type}_isCorrect`
                              ] !== undefined &&
                              isCorrect[
                                `${question.questionId}_${part.id}_${part.type}_isCorrect`
                              ] !== "pending" &&
                              (isCorrect[
                                `${question.questionId}_${part.id}_${part.type}_isCorrect`
                              ] === true ? (
                                <SvgDone />
                              ) : (
                                <SvgFalse />
                              ))}
                          </>
                        )}
                      </StudentTextStyle>
                      {/* Rendering the Parts according to the type of the part*/}
                      {part.type === "MCQ" || part.type === "MRQ" ? (
                        part.choices && (
                          <StudentQuizCheckbox
                            showPublishGrade={showPublishGrade}
                            showGrade={showGrade}
                            started={started}
                            setQuestionType={setQuestionType}
                            setQuestionId={setQuestionId}
                            setQuizTemplateQuestionsId={
                              setQuizTemplateQuestionsId
                            }
                            studentResponse={studentResponse}
                            selectedChoices={selectedChoices}
                            partIndex={idx}
                            questionIndex={indexOfFirst + index}
                            allData={StudentQuizViwData}
                            setSelectedChoices={setSelectedChoices}
                            checkboxData={part.choices}
                            quizId={quizId}
                            previewMode={previewMode}
                          />
                        )
                      ) : part.type === "MATCH" ? (
                        <StudentQuizSelect
                          showGrade={showGrade}
                          showPublishGrade={showPublishGrade}
                          started={started}
                          setQuestionType={setQuestionType}
                          setQuestionId={setQuestionId}
                          setQuizTemplateQuestionsId={
                            setQuizTemplateQuestionsId
                          }
                          matchInitialeValue={selectedMatchingChoices}
                          match={part.match}
                          studentResponse={studentResponse}
                          selectedMatchingChoices={selectedMatchingChoices}
                          setSelectedMatchingChoices={
                            setSelectedMatchingChoices
                          }
                          partIndex={idx}
                          questionIndex={indexOfFirst + index}
                          allData={StudentQuizViwData}
                          selectData={part.choices}
                          quizId={quizId}
                          previewMode={previewMode}
                        />
                      ) : part.type === "ESSAY" ? (
                        <StudentQuizEssay
                          showGrade={showGrade}
                          showPublishGrade={showPublishGrade}
                          started={started}
                          setLoading={setLoading}
                          setFileResponse={setFileResponse}
                          setQuestionType={setQuestionType}
                          setQuestionId={setQuestionId}
                          setQuizTemplateQuestionsId={
                            setQuizTemplateQuestionsId
                          }
                          fileInitialValue={selectedFiles}
                          essayInitialValue={essayDescriptions}
                          studentResponse={studentResponse}
                          setSelectedFiles={setSelectedFiles}
                          questionIndex={indexOfFirst + index}
                          partIndex={idx}
                          allData={StudentQuizViwData}
                          setEssayDescriptions={setEssayDescriptions}
                          essayData={part.choices}
                          quizId={quizId}
                          previewMode={previewMode}
                        />
                      ) : part.type === "SHORT_ANSWER" ? (
                        <StudentQuizShortAnswer
                          showGrade={showGrade}
                          showPublishGrade={showPublishGrade}
                          setIsCorrect={setIsCorrect}
                          started={started}
                          setQuestionType={setQuestionType}
                          setQuestionId={setQuestionId}
                          setQuizTemplateQuestionsId={
                            setQuizTemplateQuestionsId
                          }
                          shortAnswerInitialState={shortAnswer}
                          studentResponse={studentResponse}
                          allData={StudentQuizViwData}
                          setshortAnswer={setshortAnswer}
                          partIndex={idx}
                          questionIndex={indexOfFirst + index}
                          shortAnswerData={part.choices}
                          quizId={quizId}
                          previewMode={previewMode}
                        />
                      ) : part.type === "NUMERIC" ? (
                        <StudentQuizNumericalAnswer
                          showGrade={showGrade}
                          showPublishGrade={showPublishGrade}
                          setIsCorrect={setIsCorrect}
                          started={started}
                          setQuestionType={setQuestionType}
                          setQuestionId={setQuestionId}
                          setQuizTemplateQuestionsId={
                            setQuizTemplateQuestionsId
                          }
                          numeriacalInitialValue={numerical}
                          studentResponse={studentResponse}
                          questionIndex={indexOfFirst + index}
                          setNumerical={setNumerical}
                          partIndex={idx}
                          allData={StudentQuizViwData}
                          NumericalData={part.choices}
                          quizId={quizId}
                          previewMode={previewMode}
                        />
                      ) : part.type === "TF" ? (
                        <StudentQuizTrueOrFalse
                          showGrade={showGrade}
                          showPublishGrade={showPublishGrade}
                          setIsCorrect={setIsCorrect}
                          started={started}
                          setQuestionType={setQuestionType}
                          setQuestionId={setQuestionId}
                          setQuizTemplateQuestionsId={
                            setQuizTemplateQuestionsId
                          }
                          truOrFalseAnswerInitialValue={truOrFalseAnswer}
                          studentResponse={studentResponse}
                          allData={StudentQuizViwData}
                          setTruOrFalseAnswer={setTruOrFalseAnswer}
                          questionIndex={indexOfFirst + index}
                          partIndex={idx}
                          partID={part.id}
                          trueorfalsedata={part.choices}
                          quizId={quizId}
                          previewMode={previewMode}
                        />
                      ) : null}
                    </StudentSecondRender>
                  ))
                ) : (
                  <StudentSecondRender>
                    {/* For The Normal Question (Without Parts) */}
                    <StudentTextStyle>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            decodeHtml(question.question)
                          ),
                        }}
                        style={{
                          fontSize: "0.875rem",
                          fontWeight: "400",
                          fontFamily: "Primary Font-Light",
                        }}
                      ></div>
                      {/* Show SvgDone icon when the question is correct, and SvgFalse icon when it's incorrect */}
                      {(question.type === "SHORT_ANSWER" ||
                        question.type === "NUMERIC" ||
                        question.type === "TF") && (
                        <>
                          {showPublishGrade &&
                          isCorrect[
                            `${question.questionId}_${question.type}_isCorrect`
                          ] !== null &&
                            isCorrect[
                              `${question.questionId}_${question.type}_isCorrect`
                            ] !== undefined &&
                            isCorrect[
                              `${question.questionId}_${question.type}_isCorrect`
                            ] !== "pending" &&
                            (isCorrect[
                              `${question.questionId}_${question.type}_isCorrect`
                            ] === true ? (
                              <SvgDone />
                            ) : (
                              <SvgFalse />
                            ))}
                        </>
                      )}
                      {/* Render The Normal Questions according to the type of each question*/}
                    </StudentTextStyle>
                    {question.type === "MCQ" || question.type === "MRQ" ? (
                      question.choices && (
                        <StudentQuizCheckbox
                          showGrade={showGrade}
                          showPublishGrade={showPublishGrade}
                          started={started}
                          setQuestionType={setQuestionType}
                          setQuestionId={setQuestionId}
                          setQuizTemplateQuestionsId={
                            setQuizTemplateQuestionsId
                          }
                          studentResponse={studentResponse}
                          selectedChoices={selectedChoices}
                          setSelectedChoices={setSelectedChoices}
                          questionIndex={indexOfFirst + index}
                          allData={StudentQuizViwData}
                          checkboxData={question.choices}
                          quizId={quizId}
                          previewMode={previewMode}
                        />
                      )
                    ) : question.type === "MATCH" ? (
                      <StudentQuizSelect
                        showGrade={showGrade}
                        showPublishGrade={showPublishGrade}
                        started={started}
                        setQuestionType={setQuestionType}
                        setQuestionId={setQuestionId}
                        setQuizTemplateQuestionsId={setQuizTemplateQuestionsId}
                        matchInitialeValue={selectedMatchingChoices}
                        match={question.match}
                        studentResponse={studentResponse}
                        selectedMatchingChoices={selectedMatchingChoices}
                        setSelectedMatchingChoices={setSelectedMatchingChoices}
                        questionIndex={indexOfFirst + index}
                        allData={StudentQuizViwData}
                        selectData={question.choices}
                        quizId={quizId}
                        previewMode={previewMode}
                      />
                    ) : question.type === "ESSAY" ? (
                      <StudentQuizEssay
                        showGrade={showGrade}
                        showPublishGrade={showPublishGrade}
                        started={started}
                        setLoading={setLoading}
                        setFileResponse={setFileResponse}
                        setQuestionType={setQuestionType}
                        setQuestionId={setQuestionId}
                        setQuizTemplateQuestionsId={setQuizTemplateQuestionsId}
                        fileInitialValue={selectedFiles}
                        essayInitialValue={essayDescriptions}
                        studentResponse={studentResponse}
                        setSelectedFiles={setSelectedFiles}
                        questionIndex={indexOfFirst + index}
                        allData={StudentQuizViwData}
                        setEssayDescriptions={setEssayDescriptions}
                        essayData={question.choices}
                        quizId={quizId}
                        previewMode={previewMode}
                      />
                    ) : question.type === "SHORT_ANSWER" ? (
                      <StudentQuizShortAnswer
                        showGrade={showGrade}
                        showPublishGrade={showPublishGrade}
                        setIsCorrect={setIsCorrect}
                        started={started}
                        setQuestionType={setQuestionType}
                        setQuestionId={setQuestionId}
                        setQuizTemplateQuestionsId={setQuizTemplateQuestionsId}
                        shortAnswerInitialState={shortAnswer}
                        studentResponse={studentResponse}
                        setshortAnswer={setshortAnswer}
                        allData={StudentQuizViwData}
                        questionIndex={indexOfFirst + index}
                        shortAnswerData={question.choices}
                        quizId={quizId}
                        previewMode={previewMode}
                      />
                    ) : question.type === "NUMERIC" ? (
                      <StudentQuizNumericalAnswer
                        showGrade={showGrade}
                        showPublishGrade={showPublishGrade}
                        setIsCorrect={setIsCorrect}
                        started={started}
                        setQuestionType={setQuestionType}
                        setQuestionId={setQuestionId}
                        setQuizTemplateQuestionsId={setQuizTemplateQuestionsId}
                        numeriacalInitialValue={numerical}
                        studentResponse={studentResponse}
                        setNumerical={setNumerical}
                        allData={StudentQuizViwData}
                        questionIndex={indexOfFirst + index}
                        NumericalData={question.choices}
                        quizId={quizId}
                        previewMode={previewMode}
                      />
                    ) : question.type === "TF" ? (
                      <StudentQuizTrueOrFalse
                        showGrade={showGrade}
                        showPublishGrade={showPublishGrade}
                        setIsCorrect={setIsCorrect}
                        started={started}
                        setQuestionType={setQuestionType}
                        setQuestionId={setQuestionId}
                        setQuizTemplateQuestionsId={setQuizTemplateQuestionsId}
                        truOrFalseAnswerInitialValue={truOrFalseAnswer}
                        studentResponse={studentResponse}
                        allData={StudentQuizViwData}
                        setTruOrFalseAnswer={setTruOrFalseAnswer}
                        questionIndex={indexOfFirst + index}
                        partID={question.id}
                        trueorfalsedata={question.choices}
                        quizId={quizId}
                        previewMode={previewMode}
                      />
                    ) : null}
                  </StudentSecondRender>
                )}
              </StudentFirstRender>
            )
          )}
      </StudentQuizViewLayout>
      <AttemptContainer>
        {/* This is the Bottom of the Quiz, it contains the buttons of the quiz */}
        <StudentQuizViewBottom
          totalNumberOfAttempts={totalNumberOfAttempts}
          showReSubmitButton={showReSubmitButton}
          submissionLoading={submissionLoading}
          handelSave={handelSave}
          handleClose={handleClose}
          handleReSubmit={handleReSubmit}
          valid={valid}
          remainingDataCount={remainingDataCount}
          restNumberOfAttempts={restNumberOfAttempts}
          loading={loading}
        />
      </AttemptContainer>
      {/* Show The Pagination */}
      {StudentQuizViwData.length > PerPage && (
        <Pagination
          postsPerPage={PerPage}
          currentPage={currentPage}
          totalPosts={StudentQuizViwData.length}
          setCurrentPage={handlePageChange}
          justifyContent="flex-end"
        />
      )}
    </>
  );
};

export default StudentQuizView;
