import React from "react";
import {
  AnalyticFilterLine,
  AnalyticRefreshIcon,
  ComplexSelectGreenBox,
} from "../../AnalyticsAllAssigment.style";
import { MediumTypography } from "../../../../elements/fonts/Fonts";
import { SvgRefreshIcon } from "../../../../elements/Icons";

interface SurveyTableFilteredOptionsProps {
  setStatusSearchText: React.Dispatch<React.SetStateAction<string>>;
}

const SurveyTableFilteredOptions: React.FC<SurveyTableFilteredOptionsProps> = ({
  setStatusSearchText,
}) => {
  const handleStatusSearchText = (text: string) => {
    if (text === "Responded") {
      setStatusSearchText("Responded");
    } else if (text === "Not Responded") {
      setStatusSearchText("Not Responded");
    } else {
      setStatusSearchText("");
    }
  };

  return (
    <AnalyticFilterLine>
      <ComplexSelectGreenBox
        onClick={() => handleStatusSearchText("Responded")}
      >
        <MediumTypography text="Responded" />
      </ComplexSelectGreenBox>
      <ComplexSelectGreenBox
        onClick={() => handleStatusSearchText("Not Responded")}
      >
        <MediumTypography text="Not Responded" />
      </ComplexSelectGreenBox>
      <AnalyticRefreshIcon onClick={() => handleStatusSearchText("")}>
        <SvgRefreshIcon />
      </AnalyticRefreshIcon>
    </AnalyticFilterLine>
  );
};

export default SurveyTableFilteredOptions;
