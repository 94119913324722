import styled from "styled-components";


export const  ACLayout = styled.div<{bgColor:string}>`
display:flex;
width:100%;
border-radius: 1.25rem 0rem 0rem 1.25rem;
background: #384450;
background:${props =>props.bgColor};
flex-direction:column;
/* background-color:aqua; */
/* padding:0.1rem; */
/* min-height:10rem; */
margin-bottom:2rem;
/* min-width:11rem !important; */
/* justify-content: center; */
align-items: center;
gap:1.25rem;
min-width:17rem;
height:35rem;
/* pa */

`;


export const  ACTextInput = styled.input<{color?:string}>`
display:flex;
width:90%;
border:none;
border-bottom: 1px solid #576371;
background-color:transparent;
padding:0.62rem;
/* color:#fff; */
color:${props =>props.color};
&:focus {
    outline: none; 
  }

`;