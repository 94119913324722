import React, { useContext, useEffect, useRef, useState } from "react";
import {
  DashboardNotificationsHolder,
  NotificationTab,
  NotificationTabCont,
} from "./DashboardNotificationUpdates.style";
import { Typography } from "../../../elements/fonts/Fonts";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  CourseColorSelector,
  PopupSelectorColor,
} from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import DashboardNotifications from "../DashboardNotifications/DashboardNotifications";
import DashboardUpdates from "../Dashboardupdates/DashboardUpdates";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../../utils/StringManipulation";
import { WebSocketConnector } from "../../../Views/messages/webSocketConnection";
import EmptyView from "../../../elements/EmptyView/EmptyView";
import { SvgEmpty } from "../../../elements/Icons";
import Empty from "../../courseCard/Empty";
import axios from "axios";

interface dashboardNotificationProps {
  courseId: string;
}
export default function DashboardNotificationUpdates({
  courseId,
}: dashboardNotificationProps) {
  const { ActiveContentTabBgColor, ActiveTabBgColor } =
    useRecoilValue(CourseColorSelector);
  const { Pagination_Item_Color } = useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(null);
  const [updatesTab, setUpdatesTab] = useState(true);
  const [notificationsTab, setNotificationsTab] = useState(false);
  const {
    setClassNotificationsData,
    classNotificationsData,
  } = useContext(ClassContext);
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");

  const fetchClassNotificationsData = async (classId: string) => {
    try {
      const notificationResponse = await axios.get(`https://nfo4qku4p7.execute-api.eu-west-1.amazonaws.com/dev/get/classNotifications/${subIdSplitted}/${classId}/${tenantName}?provider=${provider}`)
      setClassNotificationsData(notificationResponse.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchClassNotificationsData(courseId);
  }, [courseId]);
  const handleResourcesClick = (index: any) => {
    setActiveTab(index);
    if (index === 0) {
      setUpdatesTab(true);
      setNotificationsTab(false);
    } else {
      setUpdatesTab(false);
      setNotificationsTab(true);
    }
  };
  useEffect(() => {
    setActiveTab(null);
    setUpdatesTab(true);
    setNotificationsTab(false);
    handleResourcesClick(0);
  }, [courseId]);
  const { ContentContainer_BgColor, Content_BoxShadow } =
    useRecoilValue(PopupSelectorColor);
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "0" }}>
      <NotificationTabCont>
        <NotificationTab
          activeTab={0}
          onClick={() => handleResourcesClick(0)}
          style={{
            background: activeTab === 0 ? ActiveTabBgColor : "transparent",
            color: activeTab === 0 ? "#5dd3b3" : Pagination_Item_Color,
          }}
        >
          <Typography text={t("dashboard.Since Your Last Access")} fontSize="0.625rem" />
        </NotificationTab>
        <NotificationTab
          onClick={() => handleResourcesClick(1)}
          activeTab={1}
          style={{
            background: activeTab === 1 ? ActiveTabBgColor : "transparent",
            color: activeTab === 1 ? "#5dd3b3" : Pagination_Item_Color,
          }}
        >
          <Typography text={t("dashboard.Notifications")} fontSize="0.625rem" />
        </NotificationTab>
      </NotificationTabCont>
      <DashboardNotificationsHolder
        bgcolor={ContentContainer_BgColor}
        boxshadow={Content_BoxShadow}
      >
        {updatesTab &&
          (classNotificationsData && classNotificationsData.length > 0 ? (
            <DashboardUpdates classNotificationsData={classNotificationsData} />
          ) : (
            <Empty />
          ))}
        {/* activities content */}

        {notificationsTab &&
          (classNotificationsData && classNotificationsData.length > 0 ? (
            <DashboardNotifications
              classNotificationsData={classNotificationsData}
            />
          ) : (
            <Empty />
          ))}
      </DashboardNotificationsHolder>
    </div>
  );
}
