import React, { useContext, useState } from "react";
import {
  ActionsCardHolder,
  ActiveUnstructuredButton,
  SubjectCardHolder,
  UnstructuredButton,
  UnstructuredCardLayout,
} from "./UnstructureCourseCard.style";
import { MediumTypography, Typography } from "../../elements/fonts/Fonts";
import {
  SvgAnalysisIcon,
  SvgAnnouncement,
  SvgEdit,
  SvgGradesIcon,
  SvgNotificationIcon,
} from "../../elements/Icons";
import PopUp from "../../elements/Popup/PopUp";
import { CourseArctCompo } from "../CourseArct/CourseArctComp";
import { CourseCardData } from "../courseCard/CourseCardData";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { useRecoilValue } from "recoil";
import { ColorSelector, IconsColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
interface UnstructuredProps {
  unstId: any;
}

export const UnstructuredCourseCard = ({
  unstId,
}: UnstructuredProps): JSX.Element => {
  const { bData } = useContext(ClassContext);
  let course;
  if (bData) {
    course = bData.find((course: { id: any }) => course.id === unstId);
  }
  // course = CourseCardData.find((course) => course.id === unstId);
  const { show, setShow } = useContext(ClassContext);
  const { classId, setClassId } = useContext(ClassContext);
  // const [Show, setShow] = useState(false);
  const [Description, setDescription] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [imageName, setImageName] = useState<string | null>(null);
  const [selectedColor, setSelectedColor] = useState("#5DC5D3");
  const [instructorId, setInstructorId] = useState("");
  // const [colorError, setColorError] = useState('');
  // const [instructorIdError, setInstructorIdError] = useState('');
  const [descriptionError, setDescriptionError] = useState("");
  // const [fileNameError, setFileNameError] = useState('');
  // const [imageNameError, setImageNameError] = useState('');
  const { iconcolor, backgroundIconHoveredColor, iconHoveredcolor, backgroundIconColor } = useRecoilValue(IconsColorSelector);

  const handleShow = async () => {
    setShow(true);
    setClassId(unstId);
  };
  const { bgUnstructuredLinearColor } = useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  return (
    <>
      <UnstructuredCardLayout bgColor={bgUnstructuredLinearColor}>
        <Typography text={course?.title || ""} fontSize="1.125rem" />
        <SubjectCardHolder style={{ display: "flex", columnGap: "0.25rem" }}>
          <MediumTypography
            text={`${course?.courseCode}`}
            fontSize="0.75rem"
            wordWrap="initial"
          />
          <MediumTypography
            text={t('dashboard.unstructured')}
            fontSize="0.75rem"
            wordWrap="initial"
          />
        </SubjectCardHolder>

        <ActionsCardHolder>
          <UnstructuredButton color={iconcolor}>
            <SvgAnalysisIcon />
          </UnstructuredButton>
          <UnstructuredButton color={iconcolor}>
            <SvgAnnouncement />
          </UnstructuredButton>
          <UnstructuredButton color={iconcolor}>
            <SvgGradesIcon />
          </UnstructuredButton>
          <ActiveUnstructuredButton color={iconcolor} bgHoveredColor={backgroundIconHoveredColor} hoveredColor={iconHoveredcolor} bgColor={backgroundIconColor}>
            <SvgEdit onClick={handleShow} />
          </ActiveUnstructuredButton>
        </ActionsCardHolder>
      </UnstructuredCardLayout>
    </>
  );
};
