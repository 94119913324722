import { InstructorViewCompletionTrackingActionsInterface, InstructorViewCompletionTrackingInterface } from "./CompletionTrackingInstrcuterViewInterface";

export const InstructorViewCompletionTrackingActionsData :InstructorViewCompletionTrackingActionsInterface[] =[
  {id:"1",
  text:"Student",
},
{id:"2",
text:"Time Spent",
},
{id:"3",
text:"Last Access",
},
{id:"4",
text:"Grade",
},
{id:"5",
text:"GPA",
},
{id:"6",
text:"Actions",
},
];


export const InstructorViewCompletionData: InstructorViewCompletionTrackingInterface[] = [
  {
    id: "1",
    Img: require("../../assets/AliAhmad.png"),
    FirstName: "Ali",
    LastName: "Ahmad",
    TimeSpent: "12min",
    LastAccess: "9 Aug | 5:00 PM",
    Grade: "A",
    GPA: "3.6",
  },
  {
    id: "2",
    Img: require("../../assets/NourInstructor.jpg"),
    FirstName: "Nour",
    LastName: "Mohammad",
    TimeSpent: "20min",
    LastAccess: "10 Aug | 4:00 PM",
    Grade: "A",
    GPA: "3.7",
  },
  {
    id: "3",
    Img: require("../../assets/NourInstructor.jpg"),
    FirstName: "Nour",
    LastName: "Mohammad",
    TimeSpent: "20min",
    LastAccess: "",
    Grade: "F",
    GPA: "3.7",
  },
  {
    id: "4",
    Img: require("../../assets/KhaledInstructor.jpg"),
    FirstName: "John",
    LastName: "Doe",
    TimeSpent: "15min",
    LastAccess: "8 Aug | 3:30 PM",
    Grade: "B",
    GPA: "3.2",
  },
  {
    id: "5",
    Img: require("../../assets/NourInstructor.jpg"),
    FirstName: "Sara",
    LastName: "Smith",
    TimeSpent: "18min",
    LastAccess: "11 Aug | 6:15 PM",
    Grade: "A",
    GPA: "3.9",
  },
  {
    id: "6",
    Img: require("../../assets/AliAhmad.png"),
    FirstName: "Mark",
    LastName: "Johnson",
    TimeSpent: "25min",
    LastAccess: "12 Aug | 7:30 PM",
    Grade: "A",
    GPA: "3.8",
  },
  {
    id: "7",
    Img: require("../../assets/AyaInstructor.jpg"),
    FirstName: "Emily",
    LastName: "Davis",
    TimeSpent: "10min",
    LastAccess: "",
    Grade: "B",
    GPA: "3.0",
  },
  {
    id: "8",
    Img: require("../../assets/AliAhmad.png"),
    FirstName: "Robert",
    LastName: "Williams",
    TimeSpent: "22min",
    LastAccess: "13 Aug | 8:45 PM",
    Grade: "A",
    GPA: "3.5",
  },
  {
    id: "9",
    Img: require("../../assets/NourInstructor.jpg"),
    FirstName: "Lisa",
    LastName: "Miller",
    TimeSpent: "16min",
    LastAccess: "14 Aug | 9:30 PM",
    Grade: "B",
    GPA: "3.1",
  },
  {
    id: "10",
    Img: require("../../assets/SenderImg2.jpg"),
    FirstName: "Michael",
    LastName: "Johnson",
    TimeSpent: "30min",
    LastAccess: "15 Aug | 10:15 PM",
    Grade: "A",
    GPA: "3.9",
  },
  {
    id: "11",
    Img: require("../../assets/AliAhmad.png"),
    FirstName: "Mark",
    LastName: "Johnson",
    TimeSpent: "25min",
    LastAccess: "12 Aug | 7:30 PM",
    Grade: "A",
    GPA: "3.8",
  },
  {
    id: "12",
    Img: require("../../assets/AliAhmad.png"),
    FirstName: "Mark",
    LastName: "Johnson",
    TimeSpent: "25min",
    LastAccess: "12 Aug | 7:30 PM",
    Grade: "A",
    GPA: "3.8",
  },
];
