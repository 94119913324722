import React from "react";
import { Settings } from "react-slick";
import { SvgNextArrowIcon, SvgPrevNew } from "../../../../elements/Icons";

const detectLastVisibleItem = () => {
  let activeItem = document.querySelectorAll(".last-visible");
  if (activeItem[0] !== undefined) {
    activeItem[0].classList.remove("last-visible");
  }
  let itemArray = document.querySelectorAll(".slick-slide.slick-active");
  if (itemArray.length > 1) {
    let lastItem = itemArray[itemArray.length - 1];
    lastItem.classList.add("last-visible");
  }
};
export const ResourcesSliderSettings = (
  numCards: number,
  allResources?: boolean
): Settings => {
  return {
    centerMode: false,
    infinite: false,
    slidesToShow: allResources
      ? Math.min(4.2, numCards)
      : Math.min(3.5, numCards),
    slidesToScroll: 1,
    arrows: numCards > 1,
    dots: false,
    prevArrow: <SvgNextArrowIcon />,
    // prevArrow: <SvgPrevNew color="#5dd3b3" />,
    nextArrow: <SvgNextArrowIcon />,
    afterChange: detectLastVisibleItem,
    onInit: () =>
      setTimeout(() => {
        detectLastVisibleItem();
      }, 1000),
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          arrows: true,
          slidesToShow: allResources
            ? Math.min(4, numCards)
            : Math.min(3.5, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1444,
        settings: {
          arrows: true,
          slidesToShow: allResources
            ? Math.min(4, numCards)
            : Math.min(3.5, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          arrows: true,
          slidesToShow: allResources
            ? Math.min(3.75, numCards)
            : Math.min(3.5, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          arrows: true,
          slidesToShow: allResources
            ? Math.min(3, numCards)
            : Math.min(3, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1210,
        settings: {
          arrows: true,
          slidesToShow: allResources
            ? Math.min(3, numCards)
            : Math.min(2.75, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1158,
        settings: {
          arrows: true,
          slidesToShow: allResources
            ? Math.min(3, numCards)
            : Math.min(2.75, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1094,
        settings: {
          arrows: true,
          slidesToShow: allResources
            ? Math.min(2.6, numCards)
            : Math.min(2.5, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: allResources
            ? Math.min(2.6, numCards)
            : Math.min(2, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: allResources
            ? Math.min(2.3, numCards)
            : Math.min(2, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 885,
        settings: {
          slidesToShow: allResources
            ? Math.min(2.1, numCards)
            : Math.min(1.75, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 850,
        // arrows: false,
        settings: {
          slidesToShow: allResources
            ? Math.min(2, numCards)
            : Math.min(1.5, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 825,
        // arrows: false,
        settings: {
          slidesToShow: allResources
            ? Math.min(2, numCards)
            : Math.min(1.5, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 785,
        // arrows: false,
        settings: {
          slidesToShow: allResources
            ? Math.min(1.75, numCards)
            : Math.min(1.3, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 745,
        // arrows: false,
        settings: {
          slidesToShow: allResources
            ? Math.min(1.5, numCards)
            : Math.min(1.25, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 715,
        // arrows: false,
        settings: {
          slidesToShow: allResources
            ? Math.min(1.5, numCards)
            : Math.min(1.25, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 680,
        // arrows: false,
        settings: {
          slidesToShow: allResources
            ? Math.min(1.25, numCards)
            : Math.min(1.25, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 620,
        settings: {
          arrows: false,
          slidesToShow: allResources
            ? Math.min(1.25, numCards)
            : Math.min(1, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,

        settings: {
          arrows: false,
          slidesToShow: allResources
            ? Math.min(1, numCards)
            : Math.min(0.75, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 505,

        settings: {
          arrows: false,
          slidesToShow: Math.min(0.75, numCards),
          slidesToScroll: 1,
        },
      },
    ],
  };
};
