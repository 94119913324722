import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CheckBox,
  CheckLabel,
  ChecksRow,
  Input,
  InputWithText,
  PopUpContainer,
  SmallTextRow,
  MsgError,
} from "./AlertSettingsPopUp.style";
import {
  LightTypography,
  MediumTypography,
} from "../../../elements/fonts/Fonts";
import CancelButton from "../../../elements/StyledButton/CancelButton";
import { ErrorDiv } from "../../../elements/ErrorDiv/Errordiv.style";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../../utils/StringManipulation";
import { useTranslation } from "react-i18next";

interface AlertSettingsPopUpProps {
  onClose: () => void;
}

const AlertSettingsPopUp: React.FC<AlertSettingsPopUpProps> = ({ onClose }) => {
  const [isNumberOfSessionChecked, setIsNumberOfSessionChecked] =
    useState(false);
  const [isGradeChecked, setIsGradeChecked] = useState(false);
  const [numberOfSessions, setNumberOfSessions] = useState("");
  const [grade, setGrade] = useState("");
  const [error, setError] = useState(false);
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const params = useParams();
  const classId = params.classId;
  const { t } = useTranslation();

  // UseEffect to retrieve data from local storage on component mount
  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("alertSettings") || "{}");
    if (savedData) {
      setIsNumberOfSessionChecked(savedData.isNumberOfSessionChecked);
      setIsGradeChecked(savedData.isGradeChecked);
      setNumberOfSessions(savedData.numberOfSessions);
      setGrade(savedData.grade);
    }
  }, []);

  const handleCheckboxChange1 = () => {
    setIsNumberOfSessionChecked(!isNumberOfSessionChecked);
    setNumberOfSessions("");
    setError(false);
  };

  const handleCheckboxChange2 = () => {
    setIsGradeChecked(!isGradeChecked);
    setGrade("");
    setError(false);
  };

  const handleSaveClick = async () => {
    const alertSettings = {
      isNumberOfSessionChecked,
      isGradeChecked,
      numberOfSessions,
      grade,
    };

    if (
      (isNumberOfSessionChecked && !numberOfSessions) ||
      (isGradeChecked && !grade)
    ) {
      setError(true);
    } else {
      try {
        // Save data 
        const data = {
          subId: subIdSplitted,
          provider: provider,
          classSourceId: classId,
          grade: alertSettings.grade ? alertSettings.grade : null,
          attendance: alertSettings.numberOfSessions ? alertSettings.numberOfSessions : null
        }
        const createResponse = await axios.post(`https://nfo4qku4p7.execute-api.eu-west-1.amazonaws.com/dev/create/alert/settings/${tenantName}`, {
          data: data
        })
        setError(false);
        onClose();
      } catch (error) {
        console.log(error)
      }
    }
  };
  const {
    checkboxBgColor,
    checkboxBorderColor,
    checkboxBorderRadius,
    SecondaryTextColor,
    bghelp,
  } = useRecoilValue(ColorSelector);
  return (
    <PopUpContainer>
      <SmallTextRow>
        <LightTypography
          text={`${t("gradebook.Send alerts to students if")} : `}
          fontSize="0.75rem"
        />
      </SmallTextRow>
      <ChecksRow>
        <CheckLabel htmlFor="checkbox1" onClick={handleCheckboxChange1}>
          <CheckBox
            id={`checkbox1`}
            type="checkbox"
            checked={isNumberOfSessionChecked}
            name={`checkbox1`}
            value={"Students not attending"}
            bgcolor={checkboxBgColor}
            bordercolor={checkboxBorderColor}
            borderradius={checkboxBorderRadius}
          />
          <MediumTypography
            text={t("gradebook.Students not attending")}
            fontSize="0.875rem"
          />
        </CheckLabel>

        {isNumberOfSessionChecked && (
          <>
            <InputWithText>
              <LightTypography
                text={t("gradebook.Number Of Sessions")}
                fontSize="0.75rem"
                color={SecondaryTextColor}
              />
              <Input
                type="number"
                value={numberOfSessions}
                onChange={(e) => setNumberOfSessions(e.target.value)}
                bordercolor={bghelp}
                color={SecondaryTextColor}
              />
            </InputWithText>
            {!numberOfSessions && error && (
              <ErrorDiv>{t("quiz.This field is mandatory")}</ErrorDiv>
            )}
          </>
        )}

        <CheckLabel htmlFor="checkbox2" onClick={handleCheckboxChange2}>
          <CheckBox
             bgcolor={checkboxBgColor}
             bordercolor={checkboxBorderColor}
             borderradius={checkboxBorderRadius}
            id={`checkbox2`}
            type="checkbox"
            checked={isGradeChecked}
            name={`checkbox2`}
            value={`When a student's overall grade is below`}
          />
          <MediumTypography
            text={t("gradebook.When a student's overall grade is below")}
            fontSize="0.875rem"
          />
        </CheckLabel>

        {isGradeChecked && (
          <>
            <InputWithText>
              <LightTypography
                text={t("assignment.Grade")}
                fontSize="0.75rem"
              />
              <Input
                bordercolor={bghelp}
                color={SecondaryTextColor}
                type="number"
                value={grade}
                onChange={(e) => setGrade(e.target.value)}
              />
            </InputWithText>
            {!grade && error && (
              <ErrorDiv>{t("quiz.This field is mandatory")}</ErrorDiv>
            )}
          </>
        )}
      </ChecksRow>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <CancelButton
          name={t("forms.Cancel")}
          onClickFunction={() => {
            onClose();
          }}
          color="#D85D66"
          borderColor="#D85D66"
        />
        <CancelButton
          name={t("forms.Save")}
          onClickFunction={handleSaveClick}
          color="#fff"
          borderColor="#5DD3B3"
          backgroundColor="#5DD3B3"
        />
      </div>
    </PopUpContainer>
  );
};

export default AlertSettingsPopUp;
