import { ResourceFrom } from '../../Views/courseView/content/Content';
import { Typography, TypographyRegularDescription } from '../../elements/fonts/Fonts';
import { SvgNoResult, SvgDeny } from '../../elements/Icons';
import { htmlDecoder } from '../../utils/StringManipulation';
import SpecificContent from './SpecificContent';
import ScormViewer from '../ScormViewer/ScormViewer';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { ClassContext } from '../../Views/dashboard/UnstructuredContext';
import { isDateInRange } from '../../utils/FormatedDATE';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { resource, selectedContent } from '../../recoil/RecoilStates';
import { useLocation } from 'react-router-dom';
import { Module } from '../CourseTimeline/utils/CourseResourcesInterface';
interface ContentComponentProps {
  selectedResource: ResourceFrom | null;
  closeTimeline: () => void;
  openTimeline: () => void;
  isTimelineOpen: boolean;
  classId: string;
  data: Module[];
}

const ContentComponent = ({ selectedResource, data, closeTimeline, openTimeline, isTimelineOpen, classId }: ContentComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { role } = useContext(ClassContext);
  const [selectedResourceID, setSelectedResourceId] = useRecoilState(selectedContent(classId))
  const [selectedResourceRecoil] = useRecoilState(resource)
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric",
  };
  const resetResourceState = useResetRecoilState(resource);//reset the resource to null
  useEffect(() => {
    if (selectedResourceID !== null && selectedResourceID !== undefined) {
      const foundContent = (data).some(module =>
        (module.topicsArray).some(topic =>
          (topic.contents || []).some(content =>
            content.contentId === selectedResourceID.contentId
          )
        )
      );
      if (foundContent === false) {
        resetResourceState()
        setSelectedResourceId(null)
      }
    }

    else if (selectedResourceID === null || selectedResourceID === undefined) {
      resetResourceState()
      // setSelectedResourceId(null)
    }


  }, [classId])

  if (selectedResourceID === null || selectedResourceID === undefined || selectedResourceRecoil.contentId === null || (role === "student" && (selectedResourceRecoil.hasOwnProperty('isVisible') && (selectedResourceRecoil.isVisible === false || selectedResourceRecoil.isVisible === 0) || isDateInRange(selectedResourceRecoil?.visibleFrom, selectedResourceRecoil?.visibleTo, selectedResourceRecoil?.isVisible, "student") === false))) {
    return (
      <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", }} >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "20rem", textAlign: "center", }} >
          <SvgNoResult width={"10rem"} />
          <Typography text={t("course view.Please select a resource/activity from the left to get started")} />
        </div>
      </div>

    );
  }
  return (
    <>
      {/* <TypographyRegularDescription text={selectedResource.ModuleTopic} fontSize="0.75rem" color="#5DD3B3" /> */}
      <TypographyRegularDescription text={`${selectedResourceRecoil.moduleTitle}- ${selectedResourceRecoil.topicTitle}`} fontSize="0.75rem" color="#5DD3B3" />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography text={selectedResourceRecoil.contentTitle} />
        {selectedResourceRecoil.status && (
          <div style={{ display: "flex", color: "#D85D66", columnGap: "0.28rem", border: "1px solid #D85D66", padding: "0.4rem", }} >
            <SvgDeny />
            <Typography text={(t('course view.closed'))} fontSize="0.75rem" />
          </div>
        )}
        <TypographyRegularDescription
          text={
            selectedResourceRecoil.dateAdded
              ? new Intl.DateTimeFormat("en-US", options)
                .format(selectedResourceRecoil.dateAdded)
                ?.toString() || ""
              : ""
          }
          fontSize="0.75rem"
          color="rgba(255, 255, 255, 0.5)"
        />
      </div>
      <>
        {selectedResourceRecoil.description &&
          <div
            dangerouslySetInnerHTML={{
              __html:
                htmlDecoder(selectedResourceRecoil.description || "")
              ,
            }}
          />}
        {selectedResourceRecoil && <SpecificContent selectedResource={selectedResourceRecoil} closeTimeline={closeTimeline} isTimelineOpen={isTimelineOpen} openTimeline={openTimeline} classId={classId} />}
      </>
      {/* <Divider></Divider>
             <CommentSection closed={selectedResourceRecoil.status} /> */}
    </>
  );
  // }
};
export default ContentComponent;
