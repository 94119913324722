import React from "react";
import { FlexAppContainer } from "../../App.style";
import { InstructorViewBoxButton } from "./InstructorViewCompletion.style";
import { SvgEmail, SvgGrade } from "../../elements/Icons";
import { MediumTypography } from "../../elements/fonts/Fonts";
import { Link } from "react-router-dom";
interface IdTwoActionsProps {
  link?: any;
  messageClick?: () => void;
}
const IdTwoActions = ({ link, messageClick }: IdTwoActionsProps) => {
  return (
    <FlexAppContainer column={true}>
      <InstructorViewBoxButton
        // onClick={() => handleChangeShapeToggle(index)}
        onClick={messageClick}
      >
        <SvgEmail width={"1.3125rem"} height={"1rem"} />
        <MediumTypography text="Send Feedback" fontSize="0.75rem" />
      </InstructorViewBoxButton>
      {/* <Link to={`CourseActivityStudent/${item.id}`}> */}
      <Link to={link}>
        <InstructorViewBoxButton>
          <SvgGrade fill="#fff" />
          <MediumTypography text="Student Activity" fontSize="0.75rem" />
        </InstructorViewBoxButton>
      </Link>
    </FlexAppContainer>
  );
};

export default IdTwoActions;
