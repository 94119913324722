import React, { FC } from "react";
import { LineWave } from "react-loader-spinner";
const LoaderThreeDot = () => {
  return (
    <LineWave
      height="100"
      width="100"
      color="#5DD3B3"
      ariaLabel="line-wave"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      firstLineColor=""
      middleLineColor=""
      lastLineColor=""
    />
  );
};

export default LoaderThreeDot;
