import styled from "styled-components";

export const MainWrapper = styled.div`
  &:dir(ltr) {
    transform: rotate(0deg);
  }
  &:dir(rtl) {
    transform: rotateX(180deg) rotateY(180deg);
  }
`;
