import React from "react";
import {
  SenderInfo,
  ProfileHolder,
  MaskGroup,
  OnlineDiv,
} from "./MessageSenderInfo.style";
import {
  LightTypography,
  Typography,
  TypographyRegularDescription,
} from "../fonts/Fonts";
import { useRecoilValue } from "recoil";
import { ChatsColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

interface SenderData {
  profileImg?: string;
  senderName?: string;
  major?: string;
  role?: string;
  online?: boolean;
  OnlineInfo?: boolean;
  backGroundColor?: string;
}
export default function MessageSenderInfo({
  profileImg,
  senderName,
  major,
  role,
  online,
  OnlineInfo = false,
  backGroundColor,
}: SenderData) {
  const { FriendMessageBoxBG_Color } = useRecoilValue(ChatsColorSelector);
  const { t } = useTranslation();
  return (
    <SenderInfo>
      <ProfileHolder
        backGroundColor={backGroundColor || FriendMessageBoxBG_Color}
      >
        <MaskGroup alt="Profile Picture" src={profileImg} />
        <OnlineDiv online={online}></OnlineDiv>
      </ProfileHolder>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography text={senderName || ""} fontSize="0.875rem" />
        {OnlineInfo ? (
          <div style={{ marginTop: "0.2rem" }}>
            <LightTypography
              text={online ? "Online" : "Offline"}
              fontSize=" 0.75rem"
              color={online ? "#5DD3B3" : "#D85D66"}
            />
          </div>
        ) : (
          <div style={{ opacity: "0.5" }}>
            <TypographyRegularDescription
              // text={`${role}-${major}` || ""}
              text={
                (role === "Teacher" ? t("Chat.Teacher") : t("Chat.Student")) +
                ` - ${major}`
              }
              fontSize="0.75rem"
            />
          </div>
        )}
      </div>
    </SenderInfo>
  );
}
