// SachailMediaTextInput.tsx
import styled from "styled-components";
import { breakpoints } from "../../theme";

interface SachailMediaTextInputProps {
  bgImage?: string; // Define the prop here
  FocusColor?: string;
  color?: string;
}

export const SochialMediaTextInput = styled.input<SachailMediaTextInputProps>`
  /* width: 23rem; */
  width: 100%;
  height: 2.75rem;
  border-radius: 0.6rem;
  border: 1px solid #576371;

  box-sizing: border-box;
  background-color: transparent;
  color: ${(props) => props.color};
  margin-bottom: 1rem;
  &:dir(ltr) {
    padding-left: 1rem;
    background-position: 97%;
  }
  &:dir(rtl) {
    padding-right: 1rem;
    background-position: 3%;
  }
  background-image: url(./assets/icons${(props) => props.bgImage});
  background-repeat: no-repeat;
  font-family: "Primary font-light";
  /* background: green; */
  /* filter: brightness(10); */
  &:focus {
    outline: none;
    caret-color: ${(FocusColor) => FocusColor.color};
  }
  &::placeholder {
    opacity: 0.8;
  }
`;
export const TextInput = styled.input<{ color?: string; FocusColor?: string }>`
  width: 100%;
  min-width: 17rem;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #576371;
  padding-left: 1rem;
  /* opacity: 1 !important; */
  /* background-color:red; */
  box-sizing: border-box;
  background-color: transparent;
  color: ${(props) => props.color};
  height: 3rem;
  caret-color: #000;
  font-family: "Primary font-light";
  @media (max-width: ${breakpoints.tablets}) {
    width: 100%;
  }
  &:focus {
    outline: none;
    /* caret-color: white; */
    caret-color: ${(FocusColor) => FocusColor.color};
  }
`;
