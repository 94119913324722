import styled from "styled-components";

export const ForumParticipationDetailsTableCell =styled.div`
display: flex;
flex:1;
align-items:center;
padding:1.25rem;
justify-content:center;

&:first-child{
  flex:1.4;
  align-items:center;
  justify-content:left;
  word-break:break-word;
  /* background-color:red; */
}
&:nth-child(2){
  flex:0.7 !important;
  /* background-color:aqua; */
}
&:nth-child(3){
  flex:0.7;
  /* background-color:black; */
}
&:nth-child(4){
  flex:1;
  /* background-color:pink; */
}
&:nth-child(5){
  flex:0.7;
  /* background-color:purple; */
}
&:last-child{
  flex:0.9;
  justify-content:space-between;
  gap:0.3rem;
  position:relative;
}
`;

export const ForumParticipationActionBox =styled.div`
display: flex;
flex-direction:column;
width: 12.5rem;
position:absolute;
background-color:#1F2731;
z-index:1;
top:2.5rem;
right:2.5rem;
border-radius:0.3125rem;
`;

export const ForumParticipationActionCell =styled.div`
display: flex;
height: 2.8125rem;
width:100%;
transition: background-color 0.3s ease;
align-items:center;
gap:0.63rem;
padding:0 1.25rem;
cursor: pointer;
&:first-child{
  border-radius:0.3125rem 0.3125rem  0 0 ;
}
&:last-child{
  border-radius:0 0  0.3125rem 0.3125rem  ;
}
&:hover { 
  background-color:#5DD3B3;

}
`;

export const ForumAbsoluteDateContainer =styled.div`
display: flex;
z-index:1;
position:absolute;
right:0.7rem;

`;

export const ForumAbsoluteContainer =styled.div<{Top?:string , Right?:string}>`
display: flex;
position:absolute;
top:${({ Top }) => `${Top ?? '0rem'}`};
right:${({ Right }) => `${Right ?? '0rem'}`};


`;