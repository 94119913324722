import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const MessageHelpsLayout = styled.div<{ bgColor: string }>`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  /* height: 8.8125rem; */
  position: absolute;
  /* background: #1F2731; */
  background: ${(props) => props.bgColor};
  z-index: 4;
  /* top: -8rem; */
  top: -4rem;
  &:dir(ltr) {
    right: 0.2rem;
  }
  &:dir(rtl) {
    left: 0.2rem;
  }

  /* height:100%; */
  /* width:100%; */
  /* background-color:red; */
  /* border-radius: 0.3125rem; */
  gap: 0.1rem;
  width: 10.875rem;
  border-radius: 0.3125rem;
`;
export const MessageHelpsLayoutVB = styled.div<{ bgColor: string }>`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  height: 16.8125rem;
  position: absolute;
  /* background: #1F2731; */
  background: ${(props) => props.bgColor};
  z-index: 4;
  &:dir(ltr) {
    right: 0.2rem;
  }
  &:dir(rtl) {
    left: 0.2rem;
  }
  top: -16rem;
  /* height:100%; */
  /* width:100%; */
  /* background-color:red; */
  /* border-radius: 0.3125rem; */
  gap: 0.1rem;
  width: 15rem;
  border-radius: 0.3125rem;
  padding-bottom: 0.62rem;
`;
export const MiniMessageHelpsLayoutVB = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  /* height:3rem; */
`;

export const MessageHelpsCell = styled.div`
  display: flex;
  /* background-color:aqua; */
  cursor: pointer;
  &:dir(ltr) {
    padding: 0.62rem 0 0.62rem 1.25rem;
  }
  &:dir(rtl) {
    padding: 0.62rem 1.25rem 0.62rem 0;
  }

  &:hover {
    background-color: #5dd3b3;
    color: #fff;
  }
  &:first-child {
    border-radius: 0.3125rem 0.3125rem 0 0;
  }
  &:last-child {
    border-radius: 0 0 0.3125rem 0.3125rem;
  }
`;

export const ContactTitleMessage = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  padding-top: 0.7rem;
`;

export const HelpMessageContainer = styled.div<StyleThemeInterface>`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  height: 11.3rem;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0%.7rem;
  /* background-color:yellow; */
  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)"
        : "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)"};
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    /* background: ${(props) =>
      props.theme === "light" ? "#F7F7F8" : "#2d3741"}  ; */
    background: transparent;

    border-radius: 0.625rem;
  }
`;

export const HelpMessageCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.63rem;
  cursor: pointer;
`;
export const HelpMessageTitle = styled.div`
  min-height: 1rem;
  display: flex;
  align-items: center;
`;
export const HelpMessageBody = styled.div`
  opacity: 0.5;
`;
