import React from 'react'
import { AllUsers, FlexBetween, FlexDiv, OneChoices, ProfileContainer, Progress, ProgressBar } from '../PollClassView.style'
import CheckBoxInput from '../../../elements/checkbox/CheckBox'
import { MediumTypography } from '../../../elements/fonts/Fonts'
import { PercentageViewInterface } from './PercentageViewInterface'

const PercentageView = ({index, handleVote, poll, selectedOption, calculatedPercentage} : PercentageViewInterface) => {
  return (
    <OneChoices key={index}>
    <FlexBetween>
        <FlexDiv onClick={() => handleVote(poll.optionId)} style={{cursor: "pointer"}}>
            <CheckBoxInput onChange={() => handleVote(poll.optionId)} checked={selectedOption === poll.optionId} name="choice" value="" width="0.75rem" height="0.75rem" border="1px solid #576474" background="transparent"/>
            <MediumTypography text={poll.optionName}/>
        </FlexDiv>
        <FlexDiv>
            <AllUsers>
            {poll.voters.slice(-5).map((user: any, index: any) => (
                <ProfileContainer key={index} bgimage={user.profile}/>
                ))}
            </AllUsers>
        <MediumTypography text={`${calculatedPercentage(poll.voters.length)}%`} fontSize="0.75rem"/>
        </FlexDiv>
    </FlexBetween>
        <ProgressBar>
            <Progress width={`${calculatedPercentage(poll.voters.length)}%`} background={index % 2 === 0 ? '#5DD3B3' : '#0B8FFF'}/>
        </ProgressBar>
</OneChoices>
  )
}

export default PercentageView