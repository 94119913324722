import React, { useEffect, useState } from "react";
import PostsTimeLine from "../../components/PostsTimeLine/main/PostsTimeLine";
import {
  ForumTimeLineLayout,
  ForumTimeLineActions,
  ForumTimeLineRowActions,
  PostsContainer,
  FileDownloaderContainer,
} from "./ForumTimeLine.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import ButtonIconAndText from "../../elements/ButtonIconAndText/ButtonIconAndText";
import { AddSqIcon, SvgCoursesIcon } from "../../elements/Icons";
import CreatePost from "../../components/PostsTimeLine/CreatePost/CreatePost";
import { get } from "../../utils/AxiosRequests";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  htmlDecoder,
  uuidSplitter,
  auth0ProviderSplitter
} from "../../utils/StringManipulation";
import { CommentContainer } from "../../elements/StudentsQuizElements/StudentQuizCheckbox/StudentQuizCheckbox.style";
import CancelButton from "../../elements/CancelButton/CancelButton";
import DOMPurify from "dompurify";
import { GradeContainer } from "../../components/assignmentActivity/AssignmentDetails/AssignmentDetails.style";
import ReusableSubmitButton from "../../elements/reusableSubmitButton/ReusableSubmitButton";
import EmptyPost from "../../components/PostsTimeLine/EmptyPost/EmptyPost";
import { dateValidation } from "./ForumTimelineUtils";
import { useRecoilState, useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { HorDividLine } from "../../components/ActivitiesInstructorView/AssignmentInstructor.style";
import { getFormattedDate } from "../../utils/GetFormattedDate";
import { adminState } from "../../recoil/RecoilStates";
interface ForumTimeLine {
  forumId: any;
}

const ForumTimeLine = ({ forumId }: ForumTimeLine) => {
  const { backgroundColor, specialColor2 } = useRecoilValue(ColorSelector);
  const [PostsTimeLineData, setPostsTimeLineData] = useState<any>();
  const { user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [showAddPost, setshowAddPost] = useState(false);
  const [valid, setValid] = useState<boolean>(false);
  const [showGrade, setShowGrade] = useState(false);
  const [showPublishGrade, setShowPublishGrade] = useState(false);
  const [maxGrade, setMaxGrade] = useState<number>(0);
  const [grade, setGrade] = useState<number>(0);
  const [cutOfDate, setCutOfDate] = useState<Date | null>(null);
  const [startDate, setSTartDate] = useState<Date | null>(null);
  const [comment, setComment] = useState<string>("");
  const [studentSubmission, setStudentSubmission] = useState<any>({});
  const [PostText, setPostText] = useState("");
  const [fileResponse, setFileResponse] = useState<any>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [postId, setPostId] = useState<number>(0);
  const [isAdmin] = useRecoilState(adminState)
  const { t } = useTranslation();
  const [fileData, setFileData] = useState<File | null>(null); // Use File | null type
  const language = localStorage.getItem("language");
  // Show the Create Post Popup
  const handelCreatePost = () => {
    setshowAddPost(!showAddPost);
    setEditMode(false);
    setFileResponse(null);
    setFileData(null);
    setPostId(0);
    setPostText("");
  };

  // Function to Fetch data from dataBase
  const fetchData = async () => {
    try {
      const forumData = await get(
        `forum/post/${forumId}/${subId}/${tenantName}`, provider
      );
      setPostsTimeLineData(forumData && forumData.data && forumData.data);



      if (forumData.data.forumActivitiesData.isGraded) {
        // If the user's posts is graded
        const submissioData = await get(
          `forum/grade/${subId}/${forumId}/${tenantName}`, provider
        );

        setStudentSubmission(
          // Set the posts of the student
          submissioData && submissioData.data && submissioData.data.Posts && submissioData.data.Posts.length > 0 && submissioData.data.Posts[0]
        );
        setMaxGrade(submissioData && submissioData.data && submissioData.data.maxGrade && submissioData.data.maxGrade);
        // console.log("Student Submission: ", submissioData.data.Posts[0]);
        const activityPublish = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/activity/publish/${forumId}/Forum/${tenantName}`
        );
        if (
          activityPublish &&
          activityPublish.data &&
          activityPublish.data.data &&
          activityPublish.data.data.length > 0
        ) {
          setShowPublishGrade(
            activityPublish &&
              activityPublish.data &&
              activityPublish.data.data.length > 0 &&
              activityPublish.data.data[0].publish === 1 ? true : false
          );
        } else {
          setShowPublishGrade(false);
        }
        setComment(
          // set the comment of the instructor for the posts of the student
          submissioData && submissioData.data && submissioData.data.Posts && submissioData.data.Posts.length > 0 && submissioData.data.Posts[0] && submissioData.data.Posts[0].grade && submissioData.data.Posts[0].grade.length > 0
            ? submissioData.data.Posts[0].grade[0].feedback
            : ""
        );
        setGrade(
          // set the Grade of the instructor for the posts of the student
          submissioData && submissioData.data && submissioData.data.Posts && submissioData.data.Posts.length > 0 && submissioData.data.Posts[0] && submissioData.data.Posts[0].grade && submissioData.data.Posts[0].grade.length > 0
            ? submissioData.data.Posts[0].grade[0].grade
            : 0
        );
        if (submissioData && submissioData.data && submissioData.data.Posts && submissioData.data.Posts.length > 0 && submissioData.data.Posts[0] && submissioData.data.Posts[0].grade && submissioData.data.Posts[0].grade.length > 0) {
          setShowGrade(true);
        }
      }
      // Check if the Forum is Valid ( Call the dateValidation Function )

      if (forumData && forumData.data && forumData.data.forumActivitiesData && forumData.data.forumActivitiesData.cutDateTime) {
        setCutOfDate(forumData.data.forumActivitiesData.cutDateTime)
        setSTartDate(forumData.data.forumActivitiesData.startDateTime)
      }
      setDataLoading(false);
      setValid(dateValidation(forumData));
    } catch (err) {
      console.log(err);
    }
  };

  // Call the FetchData function to Fetch the data from the database
  useEffect(() => {
    setDataLoading(true);
    setShowGrade(false);
    fetchData();
  }, [forumId]);

  if (dataLoading) {
    return <EmptyPost text={`${t("general.loading")}...`} />;
  }

  const currentDate = new Date();

  const hasNotStarted = startDate && new Date(startDate) > currentDate;
  const hasEnded = cutOfDate && new Date(cutOfDate) < currentDate;

  return (
    <ForumTimeLineLayout>
      {/* This Component Mount if The Posts of the user is Graded by the instructor */}
      {showGrade && isAdmin === false ? (
        <>

          {/* Here We are mapping the posts of the user himself */}
          {studentSubmission.submissions &&
            studentSubmission.submissions.length > 0 &&
            studentSubmission.submissions.map(
              (submission: any, index: number) => (
                <PostsContainer
                  style={index === 0 ? { marginTop: "4rem" } : undefined}
                  bgColor={backgroundColor}
                >
                  <MediumTypography text={`Post ${index + 1}`} />
                  <div style={{ marginTop: "1rem" }}>
                    {/* Content of the post goes here */}
                    <p
                      style={{
                        wordBreak: "break-all",
                        marginBottom: 20,
                        fontFamily: "Primary font-light",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: htmlDecoder(submission.postText),
                      }}
                    ></p>
                    {/* The file of the post goes here (If there is a file)*/}
                    {submission.fileName.trim() !== "" ? (
                      <>
                        <FileDownloaderContainer>
                          <SvgCoursesIcon />
                          <a
                            href={submission.file}
                            target="_blank"
                            download={submission.file}
                          >
                            <TypographyRegularDescription
                              text={uuidSplitter(
                                submission.fileName || ""
                              ).replace(/^(forum\/submissions\/)/, "")}
                              fontSize="0.75rem"
                            />
                          </a>
                        </FileDownloaderContainer>
                      </>
                    ) : (
                      ""
                    )}
                    {/* Submission date of the post goes Here */}
                    <TypographyRegularDescription
                      text={`${t("assignment.Submitted on")} ${getFormattedDate(
                        submission.postTime
                      )}`}
                      color="rgba(255, 255, 255, 0.50)"
                      fontSize="0.75rem"
                    />
                  </div>
                </PostsContainer>
              )
            )}
          {showPublishGrade && (
            <>
              {/* The Comment Of the instructor of the user's posts Goes Here */}
              <LightTypography text={t("assignment.Instructor Comment")} />
              <CommentContainer>
                <LightTypography text={comment} fontSize=".8rem" />
              </CommentContainer>
              {/* The Grade Of the instructor of the user's posts Goes Here */}
              <GradeContainer>
                <span style={{ color: "#5dd3b3" }}>{grade}</span> / {maxGrade || 100}
              </GradeContainer>
              <div
                style={{ display: "flex", columnGap: "1rem", alignSelf: "end" }}
              ></div>
            </>
          )}
        </>
      ) : (
        // If The User's Posts isn't Graded this component mount
        <>
          {isAdmin === false && showAddPost &&
            valid && ( // The Create Post Component is Where the user Create a Post
              <CreatePost
                PostsTimeLineData={PostsTimeLineData}
                forumId={forumId}
                setshowAddPost={setshowAddPost}
                fetchData={fetchData}
                PostText={PostText}
                setPostText={setPostText}
                fileResponse={fileResponse}
                setFileResponse={setFileResponse}
                editMode={editMode}
                setEditMode={setEditMode}
                postId={postId}
                setPostId={setPostId}
                fileData={fileData}
                setFileData={setFileData}
              />
            )}
          {!valid && hasNotStarted ? (
            <LightTypography text={`${t("forum.This Forum will start on")} ${getFormattedDate(startDate.toString())}`} />
          ) : ""}
          {!valid && hasEnded ? (
            <LightTypography text={language === "tr" ? `Bu Forum ${getFormattedDate(cutOfDate.toString())}'te sona erdi` : `${t("forum.This Forum has ended on")} ${getFormattedDate(cutOfDate.toString())}`} color="#D85D66" />
          ) : ""}

          <HorDividLine></HorDividLine>

          <ForumTimeLineRowActions
            style={{
              width: "100%",
              justifyContent:
                PostsTimeLineData && PostsTimeLineData.Posts.length > 0
                  ? "space-between"
                  : "flex-end",
            }}
          >
            {PostsTimeLineData && PostsTimeLineData.Posts.length > 0 && (
              <Typography text={t("forum.Posts")} fontSize="1.75rem" />
            )}

            {!showAddPost && valid && (
              <>
                {isAdmin === false && <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ButtonIconAndText
                    fontSize="12px"
                    color={specialColor2}
                    // text={showAddPost ? t("forum.Posts") : t("forum.Posts")}
                    text={t("forum.Add Post")}
                    iconPath={AddSqIcon}
                    onClick={handelCreatePost}
                  />
                </div>}
              </>
            )}
          </ForumTimeLineRowActions>
          {/* The Posts Of The User's Goes Here */}
          <PostsTimeLine
            PostsTimeLineData={PostsTimeLineData}
            fetchData={fetchData}
            dataLoading={dataLoading}
            setshowAddPost={setshowAddPost}
            valid={valid}
            setEditMode={setEditMode}
            setPostText={setPostText}
            setFileResponse={setFileResponse}
            setPostId={setPostId}
            setFileData={setFileData}
            postId={postId}
          />
        </>
      )}
    </ForumTimeLineLayout>
  );
};

export default ForumTimeLine;
