import styled from "styled-components";
import { TimePickerProps } from "./TimePickerInterface";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const Input = styled.input<TimePickerProps>`
  width: ${(props) => props.width || "24.75rem"};
  height: 2.8125rem;
  padding: ${(props) => props.padding || "0.94rem 1.25rem"};
  border-radius: 0.625rem;
  border: 1px solid ${(props) => props.borderColor};
  background-color: transparent;
  color: ${(props) => props.color};
  opacity: 0;

  font-family: ${(props) => props.theme.font.primaryRegular};
  cursor: pointer;

  &:focus {
    outline: none;
    caret-color: white;
  }
`;
export const Dropdown = styled.div`
  position: absolute;
  z-index: 99999;
  width: 100%;
`;

export const TimePickerContainer = styled.div<TimePickerProps>`
  width: ${(props) => props.width || "24.75rem"};
  border-radius: 0.625rem;
  background: ${(props) => props.bgColor};
  padding: 0.94rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${(props) => props.boxShadow};
  gap: 1.88rem;
`;

export const InputCont = styled.div<{ width?: string }>`
  position: relative;

  width: ${(props) => props.width};
`;

export const InputContSvg = styled.div`
display: flex;
justify-content: center;
align-items: center;
`;
export const DisplayedContainer = styled.div<TimePickerProps>`
  position:absolute;
  top: 0;
  width: ${(props) => props.width || "24.75rem"};
  height: 2.8125rem;
  border-radius: 0.3125rem;
  background: ${(props) => props.bgColor};
  /* background:GREEN; */
  padding: 0 0.94rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${(props) => props.boxShadow};
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  z-index: 1;
  color: ${(props) => props.color};
  cursor: pointer;
  border: 1px solid ${(props) => props.borderColor} !important;
  border-radius: 0.625rem;
  font-family: "Primary Font-Light";
`;

export const NumInput = styled.input<StyleThemeInterface>`
  color: ${(props) => props.color};
  width: 2.25rem;
  height: 1.5625rem;
  border: none;
  border-radius: 0.3125rem;
  background-color: ${(props) => props.bgcolor};
  transition: 0.3s;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  font-size: 0.75rem;
  text-align: center;

  &:focus {
    outline: none;
  }
`;

export const DivGroup = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 0.31rem;
`;

export const TimeCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.88rem;
`;

export const PrevHourMinute = styled.div<StyleThemeInterface>`
  font-size: 0.625rem;
  color: ${(props) => (props.theme === "light" ? "#364350" : "#fff")};
  opacity: 0.3;
  cursor: pointer;
  user-select: none;
`;
