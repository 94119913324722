import styled from "styled-components";

export const LabelDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.62rem;
`;

export const FlexDiv = styled.div<{gap?: string}>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : "0.5rem")};
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DateAndTimeDiv = styled.div`
  display: flex;
  gap: 0.62rem;
  flex-wrap: wrap;
`;

export const ButtonsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 1rem;
`;

export const BtnsDiv = styled.div`
display: flex;
flex-direction: column;
gap: 0.88rem;
`

export const LabelBtnsDiv = styled.div`
display: flex;
align-items: center;
gap: 1.25rem;
`

export const LimitInput = styled.input`
width: 1.7rem;
height: 1.25rem;
  padding: 0.25rem 0.56rem;
  border-radius: 0.3125rem;
  border: 1px solid #576371;
  background-color: transparent;
  color: #fff;
  font-family: ${(props) => props.theme.font.primaryRegular};
  font-size: 0.625rem;

  &::placeholder {
    color: #fff;
    opacity: 0.5;
    font-size: 0.625rem;
    font-family: "Primary Font-Light";
  }
  &:focus {
    outline: none;
    caret-color: white;
  }
`;