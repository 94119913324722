import React from "react";
import { ButtonIconAndTextInterface } from "./ButtonIconAndTextInterface";
import { Typography } from "../fonts/Fonts";
import { ButtonIconAndTextStyle } from "./ButtonIconAndText.style";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

export default function ButtonIconAndText({
  text,
  iconPath: IconComponent,
  fontSize,
  onClick,
  justifyContent = "center",
  backGrourdColor = "none",
  borderRaduis = "0",
  Padding = "0",
  color,
}: ButtonIconAndTextInterface) {
  const { themeTextColor } = useRecoilValue(ColorSelector);
  return (
    <ButtonIconAndTextStyle
      color={color ? color : themeTextColor}
      onClick={onClick}
      style={{ justifyContent }}
      backgrourdcolor={backGrourdColor}
      borderraduis={borderRaduis}
      padding={Padding}
    >
      <IconComponent width={16} height={16}/>
      <Typography fontSize={fontSize} fontWeight="400" text={text} whiteSpace="nowrap"/>
    </ButtonIconAndTextStyle>
  );
}
