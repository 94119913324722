import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0ProviderSplitter, auth0SubIdSplitter } from "../utils/StringManipulation";


interface Enrollment {
  role: string;
}

interface RoleContextType {
  roleProvider: string;
  isLoading: boolean;
}

const RoleContext = createContext<RoleContextType | undefined>(undefined);

export const RoleProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth0();
  const [roleProvider, setRoleProvider] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  useEffect(() => {
    const CheckRole = async () => {
      try {
        const response = provider === "waad" ? await axios.get(
          `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/enrollments/byUser/all/${tenantName}/${subIdSplitted}?provider=${provider}`
        ) :await axios.get(
          `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/enrollments/byUser/all/${tenantName}/${subIdSplitted}?provider=${provider}`
        );
        const enrollments: Enrollment[] = response.data.data;
        const roles = enrollments.map((enrollment) => enrollment.role);

        // Determine if all roles are the same
        const allSameRole = roles.every((role) => role === roles[0]);

        // Determine if it's "teacher", "student", or both
        if (allSameRole) {
          setRoleProvider(roles[0]); // Set to "teacher" or "student"
        } else {
          setRoleProvider("both"); // Mixed roles
        }
      } catch (error) {
        console.error("Error fetching enrollments", error);
      } finally {
        setIsLoading(false);
      }
    };

    if(tenantName !== null && tenantName !== undefined && subIdSplitted !== null && subIdSplitted !== undefined){
      CheckRole();
    }
  }, [user, tenantName, subIdSplitted]);

  return (
    <RoleContext.Provider value={{ roleProvider, isLoading }}>
      {children}
    </RoleContext.Provider>
  );
};

export const useRoleProvider = (): RoleContextType => {
  const context = useContext(RoleContext);
  if (!context) {
    throw new Error("useRole must be used within a RoleProvider");
  }
  return context;
};
