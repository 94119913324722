import React from "react";
import {
  DropDownMenuInfoHolder,
  EmailUsernameHolder,
  StyledDropDownMenu,
  DividLine,
  DropDownFooter,
} from "./DropDownMenu.style";
import {
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import { DropDownMenuItems } from "../dropDownItems/DropDownMenuItems";
import DropDownMenuFooter from "../dropDownItems/DropDownMenuFooter";
import { useAuth0 } from "@auth0/auth0-react";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { ISUDropDownMenuItem } from "../dropDownItems/ISUDropDownMenuItem";

export default function DropDownMenu() {
  const { user } = useAuth0();
  const { backgroundColor } = useRecoilValue(ColorSelector);
  const tenantName = localStorage.getItem("tenant");
  return (
    <StyledDropDownMenu bgColor={backgroundColor}>
      <DropDownMenuInfoHolder>
        <EmailUsernameHolder>
          <Typography fontSize="1rem" text={user?.nickname || "Username"} />
          <TypographyRegularDescription
            fontSize="0.75rem"
            color="#AAB1B9"
            text={user?.email || "Email"}
          />
        </EmailUsernameHolder>
        <DividLine></DividLine>
        {tenantName === "ISU" ? <ISUDropDownMenuItem /> : <DropDownMenuItems />}

        <DividLine></DividLine>
        <DropDownFooter>
          <DropDownMenuFooter />
        </DropDownFooter>
      </DropDownMenuInfoHolder>
    </StyledDropDownMenu>
  );
}
