import React, { useContext, useEffect, useState } from "react";
import {
  RPProfilePic,
  ViewPostContainer,
  ViewPostDetails,
  ViewPostText,
  ViewPostLine,
  ViewPostAction,
  ViewPostContainerData,
} from "./ViewPost.style";
import { SvgDisLike, SvgLike, SvgMore } from "../../../elements/Icons";
import {
  LightTypography,
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import { formatDate } from "../../../utils/FormatDate";
import { t } from "i18next";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ThreeDotsContainer,
  ThreeDotsContainerAbsolute,
} from "../main/PostsTimeLine.style";
import MappedIndexClickOutSide from "../../../hooks/MapedIndexClickOutSide";
import { handleShowSelectedIndex } from "../../../utils/SaveSelectedIndex";
import SelectedOptions from "../../../elements/SelectedOptions/SelectedOptions";
import PopupDelete from "../../../elements/PopupDelete/PopupDelete";
import { del } from "../../../utils/AxiosRequests";
import ReplyInput from "./ReplyInput/ReplyInput";
import axios from "axios";

interface ReplyPostInteface {
  ViewPostData?: any[];
  valid?: boolean;
  fetchData?: any;
}

interface LikeState {
  [index: number]: {
    like: boolean;
    dislike: boolean;
  };
}

const ViewPost = ({ ViewPostData, valid, fetchData }: ReplyPostInteface) => {
  const [likeState, setLikeState] = useState<LikeState>({});
  const profileImage = require("../../../assets/userProfile.png");
  const { mySourceId } = useContext(ClassContext);
  const [showMenuOfSelectedIndex, setShowMenuOfSelectedIndex] = useState<
    number | null
  >(null);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [ShowEditMode, setShowEditMode] = useState<number | null>(null);
  const [replyId, setReplyId] = useState<any>();
  const { user } = useAuth0();
  const tenantName = localStorage.getItem("tenant");
  const [ReplyValue, setReplyValue] = useState("");
  const [LoadingReply, setLoadingReply] = useState(false);
  const myEmail = user?.email;
  const handleLike = (index: number) => {
    setLikeState((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        like: !prevState[index]?.like,
        dislike: false,
      },
    }));
  };

  const handleDisLike = (index: number) => {
    setLikeState((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        like: false,
        dislike: !prevState[index]?.dislike,
      },
    }));
  };
  const clickOutsideRef = MappedIndexClickOutSide(() =>
    setShowMenuOfSelectedIndex(null)
  );
  const handleDeletePost = async () => {
    try {
      await del(`forum/post/delete/${replyId}/${tenantName}`);
      // fetchData && fetchData();
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (ShowEditMode !== null && ViewPostData) {
      setReplyValue(ViewPostData[ShowEditMode]?.replyText || "");
    }
  }, [ShowEditMode, ViewPostData]);
  const handleUpdateReply = async (id: any) => {
    if (ReplyValue.trim() !== "") {
      setLoadingReply(true);
      const data = {
        replyId: id,
        tenantName: tenantName,
        reply: ReplyValue,
      };
      await axios.put(
        `https://5a9lacl2fi.execute-api.eu-west-1.amazonaws.com/dev/post/edit/reply`,
        { data: data }
      );

      await fetchData();
      setLoadingReply(false);
      setShowEditMode(null);
    } else {
      setShowEditMode(null);
    }
  };
  const handleDeleteReply = async (replyId: any) => {
    try {
      await axios.delete(
        `https://5a9lacl2fi.execute-api.eu-west-1.amazonaws.com/dev/post/delete/reply/${tenantName}/${replyId}`
      );
      fetchData && fetchData();
    } catch (err) {
      console.log(err);
    }
  };
  console.log("ViewPostData iss", ViewPostData);
  return (
    <>
      {ViewPostData && ViewPostData.length > 0 ? (
        ViewPostData.map((Reply, index) => (
          <ViewPostContainer key={index} style={{ width: "100%" }}>
            <ViewPostContainerData style={{ width: "100%" }}>
              <RPProfilePic src={Reply.replyProfilePic || profileImage} />
              <ViewPostDetails style={{ width: "100%" }}>
                <div>
                  <Typography text={Reply.replyName || ""} />
                  <LightTypography
                    text={formatDate(Reply.replyTime || "", t)}
                    fontSize="0.50rem"
                  />
                </div>
                {ShowEditMode === index ? (
                  <ReplyInput
                    value={ReplyValue}
                    onChange={(e) => {
                      setReplyValue(e.target.value);
                    }}
                    CancelClick={() => setShowEditMode(null)}
                    ConfirmClick={() => handleUpdateReply(Reply.replyId)}
                    loading={LoadingReply}
                  />
                ) : (
                  <ViewPostText>
                    <TypographyRegularDescription
                      text={Reply.replyText || ""}
                      fontSize="0.875rem"
                    />
                  </ViewPostText>
                )}

                {/* <ViewPostLine>
                <ViewPostAction
                  onClick={() => handleLike(index)}
                  style={{
                    color: likeState[index]?.like ? "#5DD3B3" : "#ffffff80",
                  }}
                >
                  <TypographyRegularDescription
                    text={
                      likeState[index]?.like
                        ? Reply.replyLike + 1
                        : Reply.replyLike
                    }
                    fontSize="0.75rem"
                  />
                  <SvgLike />
                </ViewPostAction>
                <ViewPostAction
                  onClick={() => handleDisLike(index)}
                  style={{
                    color: likeState[index]?.dislike ? "#5DD3B3" : "#ffffff80",
                  }}
                >
                  <TypographyRegularDescription
                    text={
                      likeState[index]?.dislike
                        ? Reply.replyDisLike + 1
                        : Reply.replyDisLike
                    }
                    fontSize="0.75rem"
                  />
                  <SvgDisLike />
                </ViewPostAction>
              </ViewPostLine> */}
              </ViewPostDetails>
            </ViewPostContainerData>
            {(valid && mySourceId === Reply.replyUserId) ||
            myEmail?.toLocaleLowerCase() ===
              Reply.replyEmail.toLocaleLowerCase() ? (
              <ThreeDotsContainer>
                <SvgMore
                  style={{
                    padding: "0.1rem",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleShowSelectedIndex(
                      index,
                      showMenuOfSelectedIndex,
                      setShowMenuOfSelectedIndex
                    )
                  }
                />
                {showMenuOfSelectedIndex === index && (
                  <ThreeDotsContainerAbsolute ref={clickOutsideRef}>
                    <SelectedOptions
                      FirstLabelClick={() => (
                        handleShowSelectedIndex(
                          index,
                          ShowEditMode,
                          setShowEditMode
                        ),
                        setShowMenuOfSelectedIndex(null)
                      )}
                      LastLabelClick={() => (
                        setShowDeletePopup(true),
                        setReplyId(Reply.replyId),
                        setShowMenuOfSelectedIndex(null)
                      )}
                    />
                  </ThreeDotsContainerAbsolute>
                )}
                {showDeletePopup && (
                  <PopupDelete
                    show={showDeletePopup}
                    setShow={setShowDeletePopup}
                    title={t("survey.Delete Your Post")}
                    handleSubmit={() => handleDeleteReply(Reply.replyId)}
                  />
                )}
              </ThreeDotsContainer>
            ) : (
              <></>
            )}
          </ViewPostContainer>
        ))
      ) : (
        <Typography text="No Replies" />
      )}
    </>
  );
};

export default ViewPost;
