import React, { useContext, useEffect, useState } from "react";
import { FileContainer, FileLink } from "./FileViewer.style";
import { SvgCourseIcon } from "../../elements/Icons";
import { LightTypography } from "../../elements/fonts/Fonts";
import { S3Get } from "../../services/S3Get";
import axios from "axios";
import { ContentR } from "../CourseTimeline/utils/CourseResourcesInterface";
import moment from "moment";
import {
  auth0SubIdSplitter,
  uuidSplitter,
  auth0ProviderSplitter
} from "../../utils/StringManipulation";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { post } from "../../utils/AxiosRequests";
import { useRecoilValue } from "recoil";
import { CourseColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
interface FileProps {
  fileId: string;
}

export default function FileViewer({ fileId }: FileProps) {
  const { progressChanged, setProgressChange } = useContext(ClassContext)

  const { t } = useTranslation()
  const tenantName = localStorage.getItem("tenant");
  const [fileUrl, setFileUrl] = useState<string>();
  const [s3Url, setS3Url] = useState();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState<string>("");
  const params = useParams();
  const classId = params.classId;
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const downloadFile = async (file: {
    path: RequestInfo | URL;
    title: string;
  }) => {
    if (s3Url) {
      try {
        const response = await fetch(s3Url);
        const blob = await response.blob();

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        const pathSegments = file.path.toString().split("/");
        const fileName = pathSegments[pathSegments.length - 1];

        // Remove UUIDv4 from the file name
        const title = fileName.replace(
          /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}/,
          ""
        );

        link.download = title || "download";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        const trackingData = {
          classSourceId: classId,
          length: "",
          percentage: 100,
          refId: fileId,
          type: "File",
          subId: subIdSplitted,
          provider: provider,
        };
        const trackinResponse = await post(
          `completionTracking/create/${tenantName}`,
          trackingData
        );
        setProgressChange(!progressChanged);
        // Revoke the blob URL to free up resources
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    } else {
      console.error("Invalid download link for file:", file);
    }
  };
  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const response = await axios.get(
          `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/resource/file/${fileId}/${tenantName}`
        );
        setData(response.data.data);
        if (response) {
          const s3Document = await S3Get(response.data.data.path);
          setS3Url(s3Document);
          const pathSegments = response.data.data.path.toString().split("/");
          const fileName = pathSegments[pathSegments.length - 1];

          // Remove UUIDv4 from the file name
          const titleName = uuidSplitter(fileName);
          setTitle(titleName);
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchDocument();
  }, [fileId]);

  const { FileBgColor } = useRecoilValue(CourseColorSelector);
  return (
    <>
      {loading ? (
        <div>{`${t('general.loading')}...`} </div>
      ) : (
        <>
          {data && (
            <>
              {/* <LightTypography text={moment(data.created_at).toString()} /> */}
              <FileContainer onClick={() => downloadFile(data)}>
                <FileLink bgcolor={FileBgColor}>
                  <SvgCourseIcon
                    color="#5dd3b3"
                    width={"1.5rem"}
                    height={"1.5rem"}
                  />
                </FileLink>
                <LightTypography
                  text={title || ""}
                  fontSize="0.875rem"
                  color="#5dd3b3"
                />
              </FileContainer>
            </>
          )}
        </>
      )}
    </>
  );
}
