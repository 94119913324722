import React, { useEffect, useRef, FC, useState } from "react";

// import WebViewer from "@pdftron/webviewer";
import {
  PdfViewrContainer,
  Pdfcontainer,
  SideToolsContainer,
  BottomMarging,
} from "./PdfViewer.style";
import PopUp from "../../../elements/Popup/PopUp";
import SideTools from "../SideTools/SideTools";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import GraderDiscussion from "../../GraderDiscussion/main/GraderDiscussion";
import { ReactComponent as CommentIcon } from "../../../assets/icons/Messaging.svg";
import { ReactComponent as AssignmentIcon } from "../../../assets/icons/Assignment.svg";
import {
  Discussion,
  Marks,
  AssignmentQuestions,
} from "../PdfViewerDataInterface";
import AssignmentRubric from "../../AssignmentRubric/main/AssignmentRubric";
import { PdfViewerProps } from "../PdfViewerInterface";
import AssignmentQuiz from "../../AssignmentQuiz/main/AssignmentQuiz";
import DocumentViewer from "../../documentViewer/DocumentViewer";
import PdfViewerV2 from "./PdfViewerV2";
import GraderAssignmentAnswer from "../../GraderStatistics/GraderAssigmentAnswer/GraderAssigmentAnswer";
import { SvgDownload, SvgSimpleArrowLeft } from "../../../elements/Icons";
import { LightTypography } from "../../../elements/fonts/Fonts";
import { uuidSplitter } from "../../../utils/StringManipulation";
import { get } from "../../../utils/AxiosRequests";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import RubricContent from "../../rubricContent/RubricContent";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import {
  ColorSelector,
  courseCreatorColorSelector,
} from "../../../recoil/ThemeSelectors";

import Loader from "../../loader/Loader";
import {
  CustomizedToolbar,
  FileName,
  IconsStyling,
  MainPdfViewerStyle,
} from "../../../Views/pdfViewer/ReactPdfViewer.style";
// import PdfViewerComponent from "../../PspdfKitViewer/PdfViewerComponent";
const PdfViewer: FC<PdfViewerProps> = ({
  student,
  studentIndex,
  showQuiz,
  showSubmitedTime,
  setShowSubmitedTime,
  showLate,
  showNavIcon,
  updateSubmittedByInstructor,
  allAssignment,
  quiz,
  questions,
  forum,
  fetchData,
  totalPoints,
  handleQuestionIndexChange,
  usingRubric,
  rubricId,
  resourceR,
  isAssignmentGraded,
}) => {
  const { t } = useTranslation();
  const viewer = useRef<HTMLDivElement | null>(null);
  const [showComment, setShowComment] = useState<boolean>(true);
  const [selectedRubricCell, setSelectedRubricCell] = useState<any[]>([]);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [showDiscussion, setShowDiscussion] = useState<boolean>(false);
  const [showRubric, setShowRubric] = useState<boolean>(false);
  const [showRating, setShowRating] = useState<boolean>(false);
  const [documentName, setDocumentName] = useState<string | null>(null);
  const [showPdfViewer, setShowPdfViewer] = useState<boolean>(false);
  const [showTurnitinViewer, setShowTurnitinViewer] = useState<boolean>(false);
  const [turnitinUrl, setTurnitinUrl] = useState<string>("");
  const [tunrnitinLoading, setTurnitinLoading] = useState<boolean>(true);
  const [profilePicture, setProfilePicture] = useState<string>("");
  const image = require("../../../assets/userProfile.png");
  const [loading, setLoading] = useState<boolean>(true);
  const imageGif = require("../../../assets/loadingGif.gif");
  const [selectedForumFile, setSelectedForumFile] = useState<any>("");
  const [selectedForumFileName, setSelectedForumFileName] = useState<any>("");
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [resolvedDocumentType, setResolvedDocumentType] = useState<
    string | null
  >(null);
  const ref = useRef();
  const [keyD, setKey] = useState(0);
  const [documentstudent, setDocument] = useState<string>("");
  const [discussionData, setDiscussionData] = useState<Discussion[]>([
    {
      image: "/assets/images/image Khaled Khaled.png",
      name: "Khaled Khaled",
      content:
        "Meeting Details dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      image: "/assets/images/image Khaled Khaled.png",
      name: "Khaled Khaled",
      content:
        "Meeting Details dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      image: "/assets/images/image Khaled Khaled.png",
      name: "Khaled Khaled",
      content:
        "Meeting Details dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  ]);

  const [marks, setMarks] = useState<Marks[]>([
    {
      type: "structure",
      mark: 10,
      markStatus: "Full Marks",
    },
    {
      type: "structure",
      mark: 10,
      markStatus: "Full Marks",
    },
    {
      type: "structure",
      mark: 10,
      markStatus: "Full Mark",
    },

    {
      type: "structure",
      mark: 10,
      markStatus: "Full Mark",
    },
    {
      type: "structure",
      mark: 10,
      markStatus: "Full Mark",
    },
    {
      type: "content",
      mark: 10,
      markStatus: "Full Mark",
    },
    {
      type: "content",
      mark: 10,
      markStatus: "Full Mark",
    },
    {
      type: "content",
      mark: 10,
      markStatus: "Full Mark",
    },
    {
      type: "content",
      mark: 10,
      markStatus: "Full Mark",
    },
    {
      type: "content",
      mark: 10,
      markStatus: "Full Mark",
    },
  ]);

  const handleOpenSearch = () => {
    if (showComment) {
      setShowComment(false);
      setShowSearch(true);
    } else {
      setShowSearch(!showSearch);
    }
  };
  const handleOpenComment = () => {
    setShowDiscussion(true);
  };

  // Show the Rubric Popup
  const handleRubric = () => {
    setShowRating(true);
    setShowSubmitedTime(false);
  };

  const pageNavigationPluginInstance = pageNavigationPlugin();

  const { GoToNextPageButton, GoToPreviousPageButton, CurrentPageLabel } =
    pageNavigationPluginInstance;

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const { tooltipTextColor } = useRecoilValue(courseCreatorColorSelector);

  // This function return the type of the file
  const getDocumentType = (url: string) => {
    if (url.includes(".pdf")) {
      return "pdf";
    } else if (url.includes(".xlsx") || url.includes(".xls")) {
      return "xlsx";
    } else if (url.includes(".docx") || url.includes(".doc")) {
      return "docx";
    } else if (url.includes(".pptx") || url.includes(".ppt")) {
      return "pptx";
    } else {
      return null;
    }
  };

  // Set the name of the file or the document uploaded by the student
  useEffect(() => {
    if (student && !forum && student.length > 0) {
      const newDoc = student[studentIndex].submissionFile;
      const documentName = student[studentIndex].fileName;
      setDocument(newDoc);
      setDocumentName(documentName);
      const documentstudent = newDoc;
      setKey((prevKey) => prevKey + 1);
    } else if (student && forum && student.length > 0) {
      setDocument(selectedForumFile);
      setDocumentName(selectedForumFileName);
      setKey((prevKey) => prevKey + 1);
    }
  }, [
    student,
    studentIndex,
    showQuiz,
    showComment,
    showDiscussion,
    showRubric,
    showSearch,
  ]);

  // Set the type of the file or document uploaded by the student
  useEffect(() => {
    if (
      student &&
      !forum &&
      student.length > 0 &&
      student[studentIndex].submissionFile
    ) {
      const type = getDocumentType(student[studentIndex].submissionFile);
      setResolvedDocumentType(type);
    } else if (student && forum && student.length > 0) {
      const type = getDocumentType(selectedForumFile);
      setResolvedDocumentType(type);
    }
    setShowPdfViewer(false);
    setShowTurnitinViewer(false);
    if (
      student &&
      student[studentIndex] &&
      student[studentIndex].rubricData &&
      student[studentIndex].rubricData.length > 0 &&
      !forum &&
      !quiz
    ) {
      setShowRating(true);
    } else if (
      student &&
      student.length > 0 &&
      student[studentIndex].grade &&
      student[studentIndex].grade.length > 0 &&
      student[studentIndex].grade[0].rubricData &&
      student[studentIndex].grade[0].rubricData.length > 0 &&
      !quiz
    ) {
      setShowRating(true);
    } else if (!quiz) {
      setShowRating(false);
    }
    setSelectedRubricCell([]);
  }, [documentstudent, studentIndex, questionIndex, student, questions]);

  // Toggle between the pdf viewer, and the default view for the Assignment
  const handleChangeView = () => {
    setShowPdfViewer(false);
    setShowTurnitinViewer(false);
  };

  const handleChangeViewForAssignment = () => {
    setShowPdfViewer(true);
  };

  // Toggle between the pdf viewer, and the default view for the Forum
  const handleChangeViewForForum = (file: any, fileName: any) => {
    setShowPdfViewer(!showPdfViewer);
    setSelectedForumFile(file);
    setSelectedForumFileName(fileName);
  };

  const handelDownloadClick = () => {};

  const handleDownloadDoc = async () => {
    const response = await fetch(
      forum
        ? selectedForumFile
        : showTurnitinViewer
        ? turnitinUrl
        : documentstudent
    );
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;

    link.download =
      uuidSplitter(showTurnitinViewer ? "report.pdf" : documentName || "") ||
      "download";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const { SecondaryTextColor, bghelp, theme } = useRecoilValue(ColorSelector);
  const language = localStorage.getItem("language");

  return (
    <>
      <PdfViewrContainer bordercolor={bghelp} style={{ height: "fit-content" }}>
        {quiz ? ( // This Component is for the Quiz
          <Pdfcontainer style={{ height: "fit-content" }}>
            <AssignmentQuiz
              questions={questions || []}
              questionIndex={questionIndex}
              setQuestionIndex={setQuestionIndex}
              submission={student}
              studentIndex={studentIndex}
              handleQuestionIndexChange={handleQuestionIndexChange}
              fetchData={fetchData}
              setShowRubric={setShowRubric}
              showRating={showRating}
              setShowRating={setShowRating}
              selectedRubricCell={selectedRubricCell}
            />
          </Pdfcontainer>
        ) : showPdfViewer ? ( // The Pdf Viewer Container (For Assignment and Forum)
          <Pdfcontainer>
            <BottomMarging onClick={handleChangeView}>
              <SvgSimpleArrowLeft
                style={{
                  transform: `${language === "ar" ? "rotate(180deg)" : ""}`,
                }}
              />
              <LightTypography text="Back" />
            </BottomMarging>
            {/* This is the PDF VIEWER */}
            {/* <DocViewer
              key={keyD}
              theme={{
                textPrimary: "white",
                secondary: "#2d3741",
              }}
              config={{
                header: {
                  disableHeader: false,
                  disableFileName: false,
                  retainURLParams: false,
                },
                pdfVerticalScrollByDefault: true,
              }}
              documents={[
                {
                  uri: forum ? selectedForumFile : showTurnitinViewer ? turnitinUrl : documentstudent,
                  fileType: resolvedDocumentType || "pdf",
                  fileName: forum
                    ? uuidSplitter(selectedForumFileName || "").replace(
                        /^(forum\/submissions\/)/,
                        ""
                      ) || ""
                    : uuidSplitter(documentName || "") || "",
                },
              ]}
              pluginRenderers={DocViewerRenderers}
              style={{
                height: "110vh",
                width: "100%",
                backgroundColor: "#384450",
              }}
            /> */}

            {/* PDF VIEWER */}
            <MainPdfViewerStyle>
              hadiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii
              <CustomizedToolbar
                color={tooltipTextColor}
                bgcolor={theme === "light" ? "#AAB1B9" : "#384450"}
              >
                <FileName style={{ marginTop: "1.2rem" }}>
                  <LightTypography
                    text={
                      forum
                        ? uuidSplitter(selectedForumFileName || "").replace(
                            /^(forum\/submissions\/)/,
                            ""
                          ) || ""
                        : uuidSplitter(documentName || "") || ""
                    }
                  />
                </FileName>
                <FileName>
                  <IconsStyling className="rpv-core__minimal-button rpv-core__minimal-button--disabled">
                    <SvgDownload
                      // className="rpv-core__icon"
                      style={{
                        height: "1rem",
                        width: "1rem",
                        color: "#F6F7F7",
                        marginTop: "1rem",
                      }}
                      onClick={() => {
                        handleDownloadDoc();
                      }}
                    />
                  </IconsStyling>
                </FileName>
              </CustomizedToolbar>
              <div style={{ flex: 1, overflowX: "hidden", height: "500px" }}>
                <Worker
                  workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
                >
                  <Viewer
                    fileUrl={
                      forum
                        ? selectedForumFile
                        : showTurnitinViewer
                        ? turnitinUrl
                        : documentstudent
                    }
                    // onPageChange={onPageChange}
                    // initialPage={Math.floor(pageNumber)}
                    defaultScale={
                      screenSize.width < 942 && screenSize.width > 860
                        ? 0.9
                        : screenSize.width < 860 && screenSize.width > 811
                        ? 0.85
                        : screenSize.width <= 811 && screenSize.width > 711
                        ? 0.7
                        : screenSize.width <= 678 && screenSize.width > 571
                        ? 0.85
                        : screenSize.width <= 571 && screenSize.width > 524
                        ? 0.75
                        : screenSize.width <= 524 && screenSize.width > 482
                        ? 0.7
                        : screenSize.width <= 482 && screenSize.width > 463
                        ? 0.65
                        : screenSize.width <= 463
                        ? 0.55
                        : 1
                    }
                    plugins={[pageNavigationPluginInstance]}
                    // onDocumentLoad={onDocumentLoad}
                  />
                </Worker>
              </div>
            </MainPdfViewerStyle>

            {/* PSPDFKIT to be later */}
            {/* <PdfViewerComponent document={
                   forum ? selectedForumFile : documentstudent
            }/> */}
          </Pdfcontainer>
        ) : (
          // For Assignment and Forum (Default View)
          <GraderAssignmentAnswer
            forum={forum}
            EyeClick={handleChangeViewForAssignment}
            EyeClick2={handleChangeViewForForum}
            DownloadClick={handelDownloadClick}
            Name={
              student && !forum && student.length > 0
                ? student[studentIndex].user.fName +
                    " " +
                    student[studentIndex].user.lName || " "
                : student &&
                  forum &&
                  student.length > 0 &&
                  student[studentIndex].posterName
            }
            Subject={
              (student &&
                !forum &&
                student.length > 0 &&
                student[studentIndex].textAnswer) ||
              `${t("assignment.No Answer")}`
            }
            fileName={
              (student &&
                !forum &&
                student.length > 0 &&
                student[studentIndex].fileName) ||
              ""
            }
            profilePic={
              student &&
              !forum &&
              student.length > 0 &&
              student[studentIndex].user.profilePicture
                ? student[studentIndex].user.profilePicture
                : student &&
                  !forum &&
                  student.length > 0 &&
                  !student[studentIndex].user.profilePicture
                ? image
                : student &&
                  student.length > 0 &&
                  student[studentIndex].posterProfilePic
            }
            filePath={
              (student &&
                !forum &&
                student.length > 0 &&
                student[studentIndex].submissionFile) ||
              ""
            }
            postsArray={
              (student &&
                forum &&
                student.length &&
                student[studentIndex].submissions) ||
              []
            }
          />
        )}

        {/* <SideToolsContainer> */}
        {/* This is the right Component */}
        <SideTools
          showComment={showComment}
          setShowComment={setShowComment}
          showSearch={showSearch}
          setShowSearch={setShowSearch}
          handleOpenSearch={handleOpenSearch}
          handleOpenComment={handleOpenComment}
          setShowRubric={setShowRubric}
          showRating={showRating}
          showSubmitedTime={showSubmitedTime}
          showNavIcon={showNavIcon}
          showLate={showLate}
          student={student}
          studentIndex={studentIndex}
          allAssignment={allAssignment}
          questions={questions || []}
          questionIndex={questionIndex}
          quiz={quiz}
          forum={forum}
          fetchData={fetchData}
          totalPoints={totalPoints}
          usingRubric={usingRubric}
          rubricId={rubricId}
          selectedRubricCell={selectedRubricCell}
          setTurnitinUrl={setTurnitinUrl}
          setShowTurnitinViewer={setShowTurnitinViewer}
          setTurnitinLoading={setTurnitinLoading}
          resourceR={resourceR}
          isAssignmentGraded={isAssignmentGraded}
          handleChangeViewForAssignment={handleChangeViewForAssignment}
          // updateSubmittedByInstructor={updateSubmittedByInstructor}
        />
        {/* </SideToolsContainer> */}
      </PdfViewrContainer>
      {showDiscussion ? (
        <PopUp
          content={<GraderDiscussion discussionData={discussionData} />}
          icon={<CommentIcon />}
          title="Discussion"
          showline={false}
          show={showDiscussion}
          setShow={setShowDiscussion}
          // marginTop="5rem"
          showButton={false}
        />
      ) : showRubric ? (
        <PopUp
          content={
            <RubricContent
              rubricId={
                !quiz
                  ? rubricId || 0
                  : (questions &&
                      questions.length > 0 &&
                      questions[questionIndex].rubricId) ||
                    0
              }
              setSelectedRubricCell={setSelectedRubricCell}
              setShow={setShowRubric}
              setShowRating={setShowRating}
            />
          }
          icon={<AssignmentIcon />}
          showline={false}
          show={showRubric}
          showButton={false}
          setShow={setShowRubric}
          title={t("Rubric.Assignment Rubric")}
          // handleSubmit={handleRubric}
          buttonName="Save"
          paddingLeft="0rem"
          paddingRight="0rem"
          paddingBottom="1.88rem"
        />
      ) : (
        ""
      )}
    </>
  );
};

export default PdfViewer;
