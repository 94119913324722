import styled from "styled-components";

export const LanginUniCol = styled.div`
display:flex;
/* width:100%; */
gap:1.20rem;
flex-direction:column;
width:24rem;
align-items:left;
justify-content:left;
text-align:left;
/* background-color: red; */
/* background-color:aqua; */

@media screen and (max-width: 440px) {
    width :15rem ;
    text-align: center;
  }

`;

export const LanginUniTanentsGroups = styled.div`
display:flex;
width:100%;
gap:1.20rem;
justify-content:space-evenly;
flex-wrap:wrap;
`;

export const LanginUniTanentsBox = styled.div`
display:flex;
width:fit-content;
min-width: 5rem !important;
height: 2rem;
flex-shrink: 0;
padding: 0.5rem 1.2rem;
border-radius: 0.625rem;
border: 1px solid #576474;
justify-content:center;
align-items:center;
cursor: pointer;
padding:1rem ;
color: #576474;
transition: background-color 0.3s ease, color 0.3s ease;
&:hover{
  background-color:#576474;
  color: #fff;
}
`;

export const LanginUniTanentsImg= styled.img`
display:flex;
width: 100%;
height:100%;
flex-shrink: 0;
padding: 0.5rem 2rem;
border-radius: 0.625rem;
border: 1px solid #576474;

position: cover;
/* background-color:red; */
`;

export const LanginUniTanentsButton= styled.div`
margin-top:0.5rem;
`;