import axios from "axios";
import { S3Upload } from "../services/S3Put";

// Convert Document or Power Point to pdf Function
export const convertToPdf = async (url: string) => {
  // Data To send To the pdf conversion Platform
  const data = {
    "url": url,
    "lang": "eng",
    "inline": true,
    "pages": "0-",
    "async": false,
    "name": "result.pdf"
  };
  try {
    // Post Request
    const response = await axios.post('https://api.pdf.co/v1/pdf/convert/from/doc', data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': 'h.shouman@zidyia.com_ZyQxloZpZvy920qc6rFoF9LAht0x4QclxhmI8G6hPTno6XSemDsW1D1isLioDqwY'
      }
    });
    // Return the converted url
    return response.data.url;
  } catch (error) {
    console.error('Error:', error);
    return '';
  }
}

// Convert Excel to pdf Function
export const convertExcelToPdf = async (url: string) => {
  // Data To send To the pdf conversion Platform
  const data = {
    "url": url,
    "lang": "eng",
    "inline": true,
    "pages": "0-",
    "async": false,
    "name": "result.pdf"
  };
  try {
    // Post Request
    const response = await axios.post('https://api.pdf.co/v1/pdf/convert/from/csv', data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': 'h.shouman@zidyia.com_ZyQxloZpZvy920qc6rFoF9LAht0x4QclxhmI8G6hPTno6XSemDsW1D1isLioDqwY'
      }
    });
    // Return the converted url
    return response.data.url;
  } catch (error) {
    console.error('Error:', error);
    return '';
  }
}

// Extract The File from the Converted s3 Url and upload it to s3
export const retrieveFileFromUrlAndUploadToS3 = async (url: string, type: string, filename: string, docPath: string, uuid: string) => {
  try {
    // File Type is doc or ppt
    if (type === 'doc') {
      // Get The Converted Url then extract the file from it, then upload it to s3
      const convertedUrl = await convertToPdf(url);
      const response = await axios.get(convertedUrl, {
        responseType: "blob"
      });
      const filenameWithoutExtension = filename.split('.').slice(0, -1).join('.') + '.pdf';
      const blob = new Blob([response.data], { type: response.data.type });
      const file = new File([blob], filenameWithoutExtension, { type: response.data.type });
      const fileNameUUid = `${uuid}${file.name}`;
      const response2 = await S3Upload(docPath, file.name, fileNameUUid, file);
      return `${docPath}/${fileNameUUid}`
    }
    // File Type is xls or csv
    else if (type === 'xls') {
      // Get The Converted Url then extract the file from it, then upload it to s3
      const convertedUrl = await convertExcelToPdf(url);
      const response = await axios.get(convertedUrl, {
        responseType: "blob"
      });
      const filenameWithoutExtension = filename.split('.').slice(0, -1).join('.') + '.pdf';
      const blob = new Blob([response.data], { type: response.data.type });
      const file = new File([blob], filenameWithoutExtension, { type: response.data.type });
      const fileNameUUid = `${uuid}${file.name}`;
      const response2 = await S3Upload(docPath, file.name, fileNameUUid, file);
      return `${docPath}/${fileNameUUid}`
    }

  } catch (error) {
    console.error('Error:', error);
    // Handle errors here
  }
}

// Get The Type Of The Document
export const getDocumentType = (url: string): string => {
  if (url.endsWith(".pdf")) {
    return "pdf";
  } else if (url.endsWith(".xlsx")) {
    return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  } else if (url.endsWith(".xls")) {
    return "application/vnd.ms-excel";
  } else if (url.endsWith(".csv")) {
    return "text/csv";
  } else if (url.endsWith(".docx")) {
    return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  } else if (url.endsWith(".doc")) {
    return "application/msword";
  } else if (url.endsWith(".pptx")) {
    return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
  } else if (url.endsWith("ppt")) {
    return "application/vnd.ms-powerpoint";
  } else {
    return "null";
  }
};