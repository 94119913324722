import styled from "styled-components";
interface ButtonMidiaChane {
  bgImage?: string; // Define the prop here
}
export const ButtonModification = styled.div<ButtonMidiaChane>`
  display: flex;
  background-color: #1F2731;
  /* align-items: space-around; */
display:flex;
  width: 100%;
  background-image: url(./assets/icons${(props) => props.bgImage});
  color: white;
  background-repeat: no-repeat;
  /* padding-left: 20%; */
  /* background-position: 3%; */
  font-family:"Primary Font-Medium";
  cursor:pointer;
  border:0px;
  /* align-items:center; */
  /* vertical-align:center; */
  height:2rem;
  padding-left: 0.5rem;
  align-items: center;
  gap: 1rem;
  margin-top:0.2rem;
  /* &:hover {
    box-shadow: 0 0 10px rgba(42, 243, 156, 0.3);
  } */
`;

// export const ButtonModification = styled.label<ButtonMidiaChane>`
//   display: flex;
//   background-color: #1F2731;
//   align-items: center;
//   width: 100%;
//   background-image: url(./assets/icons${(props) => props.bgImage});
//   color: white;
//   background-repeat: no-repeat;
//   padding-left: 20%;
//   background-position: 3%;
//   font-family: "Primary Font-Medium";
//   cursor: pointer;
//   border: 0;
//   height: 2.3rem;
// `;
