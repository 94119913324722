import styled from "styled-components";

export const Container = styled.div<{backgroundcolor?: string, themetextcolor?: string, scrollbar?: string}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: auto;
  background: ${props => props.backgroundcolor || "#384450"};
  color: ${props => props.themetextcolor};
  &:dir(ltr) {
  padding-right: 1.69rem;
  }
  &:dir(rtl) {
    padding-left: 1.69rem;
  }
  &::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.themetextcolor === "#fff" ? "linear-gradient(180deg, #5DD3B3 0%, #2D3741 100%)" : "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)"};
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.backgroundcolor || "#576371"};
    border-radius: 0.625rem;
  }
`;