import React from "react";
import {
  AnalyticForumIdShadowHeaderContainer,
  AnalyticForumIdShadowHeaderDataInfo,
  AnalyticForumIdShadowHeaderLine,
  AnalyticPointerAction,
} from "./AnalyticForumIdShadowHeader.style";
import { LightTypography, Typography } from "../../../elements/fonts/Fonts";
interface AnalyticForumIdShadowHeaderInterface {
  FirstText?: string;
  SecondText?: string;
  FirstNumberValue: number | string;
  SecondNumberValue: number | string;
  FirstPlaceClick?: () => void;
  SecondPlaceClick?: () => void;
  Anonymous?: boolean;
}
const AnalyticForumIdShadowHeader = ({
  FirstText,
  SecondText,
  FirstNumberValue,
  SecondNumberValue,
  FirstPlaceClick,
  SecondPlaceClick,
}: AnalyticForumIdShadowHeaderInterface) => {
  return (
    <AnalyticForumIdShadowHeaderContainer>
      <AnalyticForumIdShadowHeaderDataInfo>
        <AnalyticPointerAction onClick={FirstPlaceClick}>
          <Typography text={FirstText || ""} fontSize="1.125rem" />
          <LightTypography
            text={FirstNumberValue.toString()}
            fontSize="0.75rem"
          />
        </AnalyticPointerAction>
      </AnalyticForumIdShadowHeaderDataInfo>
      <AnalyticForumIdShadowHeaderLine />
      <AnalyticForumIdShadowHeaderDataInfo>
        <AnalyticPointerAction onClick={SecondPlaceClick}>
          <Typography text={SecondText || ""} fontSize="1.125rem" />
          <LightTypography
            text={SecondNumberValue.toString()}
            fontSize="0.75rem"
          />
        </AnalyticPointerAction>
      </AnalyticForumIdShadowHeaderDataInfo>
    </AnalyticForumIdShadowHeaderContainer>
  );
};

export default AnalyticForumIdShadowHeader;
