import React,{FC,useState} from 'react';
import { RatingContainer,Header,RatingContentContainer,CriteriaContainer,MarkContainer,MarkScaleContainer,ScaleContainer,ScaleContentNumber,ScaleStructureNumber } from './RatingSection.style';
import { TypographyRegularDescription,LightTypography } from '../../../../elements/fonts/Fonts';
import { theme } from '../../../../theme';

 const RatingSection:FC = () => {

    const [structureMark, setStructureMark] = useState<number>(10);
    const [contentMark, setContentMarkMark] = useState<number>(6);
    const scaleStructureWidth = `${(structureMark / 10) * 12.5}rem`;
    const scaleContentWidth = `${(contentMark / 10) * 12.5}rem`;
    
    return (
        <RatingContainer>
        <Header>
            <TypographyRegularDescription text='Criteria' color={theme.dark.primary_text_color} fontSize='1rem' />
            <TypographyRegularDescription text='Ratings' color={theme.dark.primary_text_color} fontSize='1rem' />
        </Header>
        <RatingContentContainer>
            <CriteriaContainer>
                <LightTypography text='Assignment' fontSize='0.75rem' color={theme.dark.primary_text_color} />
                <LightTypography text='Structure' fontSize='0.75rem' color={theme.dark.primary_text_color} />
            </CriteriaContainer>
            <MarkScaleContainer>
                <MarkContainer>
                    <LightTypography text='Full Marks' fontSize='0.75rem' color={theme.dark.primary_text_color} />
                    <LightTypography text={`${structureMark}/10 pts`} fontSize='0.75rem' color={theme.dark.main_color} />
                </MarkContainer>
                <ScaleContainer>
                    <ScaleStructureNumber
                        style={{
                            width: scaleStructureWidth,
                            height: '0.5rem',
                        }}
                    ></ScaleStructureNumber>
                </ScaleContainer>
            </MarkScaleContainer>
        </RatingContentContainer>
        <RatingContentContainer>
            <CriteriaContainer>
                <LightTypography text='Assignment' fontSize='0.75rem' color={theme.dark.primary_text_color} />
                <LightTypography text='Content' fontSize='0.75rem' color={theme.dark.primary_text_color} />
            </CriteriaContainer>
            <MarkScaleContainer>
                <MarkContainer>
                    <LightTypography text='Average' fontSize='0.75rem' color={theme.dark.primary_text_color} />
                    <LightTypography text={`${contentMark}/10 pts`} fontSize='0.75rem' color='#0B8FFF' />
                </MarkContainer>
                <ScaleContainer>
                    <ScaleContentNumber
                        style={{
                            width: scaleContentWidth,
                            height: '0.5rem',
                        }}
                    ></ScaleContentNumber>
                </ScaleContainer>
            </MarkScaleContainer>
        </RatingContentContainer>
    </RatingContainer>
    )
}

export default RatingSection;