import { useContext, useEffect, useState } from "react";
import {
  TimelineContentWrapper,
  ContentContainer,
  TimelineContainer,
  TimelineClosed,
  TimeLineExist,
  Divider,
} from "./Content.style";
import CourseTimeline from "../../../components/CourseTimeline/CourseTimeline";
import { SvgTimeline } from "../../../elements/Icons";
import { Typography } from "../../../elements/fonts/Fonts";
import { useParams } from "react-router";
import {
  ContentR,
  Module,
} from "../../../components/CourseTimeline/utils/CourseResourcesInterface";
import LoaderCircle from "../../../components/loader/loaderCircle/LoaderCircle";
import { FlexAppContainer } from "../../../App.style";
import { fetchData, getClassTitle } from "./ContentFunctions";
import ContentComponent from "../../../components/contentComponent/ContentComponent";
import { useRecoilState, useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../dashboard/UnstructuredContext";
import { get } from "../../../utils/AxiosRequests";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../../utils/StringManipulation";
import { adminRoleState, adminState, resource } from "../../../recoil/RecoilStates";
import CourseTimelineV2 from "../../../components/CourseTimeline/main/CourseTimelineV2";

export interface ResourceFrom extends ContentR {
  moduleTitle: string;
  topicTitle: string;
  // toIndex: number;
  // moduleIndex: number;
}
interface UserData {
  sourceId: string;
  username: string;
  email: string;
  profilePicture: string;
  role: string;
}
export default function Content() {
  const { t } = useTranslation();
  const classId = useParams();
  const { setMySourceId } = useContext(ClassContext);
  // const [selectedResource, setSelectedResource] = useState<ResourceFrom | null>(
  //   null
  // );
  const [selectedResource, setSelectedResource] = useRecoilState(resource);
  const [classTitle, setClassTitle] = useState<string>("");
  const [data, setData] = useState<Module[]>([]);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState<string>("");
  const [adminRole, setAdminRole] = useRecoilState(adminRoleState);
  const [isAdmin] = useRecoilState(adminState);
  const tenantName = localStorage.getItem("tenant");
  const { user } = useAuth0();
  const myEmail = user?.email;

  const { backgroundColor, boxShadowBanner, boxShadow } =
    useRecoilValue(ColorSelector);
  const checkEmailExists = (
    emailToCheck: string,
    userData: UserData[]
  ): boolean => {
    const lowerCaseEmailToCheck = emailToCheck.toLowerCase();

    return userData.some((user) => {
      const lowerCaseUserEmail = user.email.toLowerCase();
      return lowerCaseUserEmail === lowerCaseEmailToCheck;
    });
  };
  useEffect(() => {
    setLoading(true)
    const fetchDataAndSetTitle = async () => {
      const isParentClass = await axios.get(
        `https://bo39qdpjrc.execute-api.eu-west-1.amazonaws.com/dev/class/merge/type/${classId.classId}/${tenantName}`
      );
      const { dataFetched, isLoading } = await fetchData(
        isParentClass.data.data[0].isParent === true
          ? classId.classId
          : isParentClass.data.data[0].isChild === true
            ? isParentClass.data.data[0].parentSourceId
            : classId.classId
      );
      const subId = auth0SubIdSplitter(user?.sub || "");
      const provider = auth0ProviderSplitter(user?.sub || "");
      const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
      if (isAdmin === true) {
        setRole(adminRole);
      } else {
        if (isParentClass.data.data[0].isParent === true) {
          setRole("teacher");
          setAdminRole("teacher")
        }
        else {
          const auditors = await axios.get(`https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/classAuditor/auditors/${tenantName}/${classId.classId}`)
          const email = user?.email || "";
          let isAuditor = false;
          if (auditors && auditors.data.data.length > 0) {
            isAuditor = checkEmailExists(email, auditors.data.data);
            if (isAuditor === true) {
              setRole("auditor");
              setAdminRole("auditor")
            } else {
              const response = await axios.get(
                `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/enrollments/user/role/${tenantName}/${classId.classId}/${subIdSplitted}?provider=${provider}`
              );
              setRole(response.data.data.role);
              setAdminRole(response.data.data.role)
            }
          }
          else {
            const response = await axios.get(
              `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/enrollments/user/role/${tenantName}/${classId.classId}/${subIdSplitted}?provider=${provider}`
            );
            setRole(response.data.data.role);
            setAdminRole(response.data.data.role)
          }
        }
      }
      setLoading(isLoading);
      setData(dataFetched);

      const title = await getClassTitle(classId.classId);
      setClassTitle(title);
    };
    fetchDataAndSetTitle();
  }, [classId, isAdmin]);

  const [isTimelineOpen, setTimelineOpen] = useState(true);

  const openTimeline = () => {
    setTimelineOpen(true);
  };

  const closeTimeline = () => {
    setTimelineOpen(false);
  };

  const handleResourceSelect = (resource: any) => {
    setSelectedResource(resource);
  };

  if (loading) {
    return (
      <FlexAppContainer
        column
        gap="1rem"
        alignItems="center"
        justifyContent="center"
      >
        {" "}
        <LoaderCircle color="#5dd3b3" /> {`${t("general.loading")}...`}
      </FlexAppContainer>
    );
  }

  return (
    <>
      <TimelineContentWrapper>
        {!isTimelineOpen && (
          <TimelineClosed
            style={{ boxShadow: boxShadowBanner }}
            bgcolor={backgroundColor}
            onClick={openTimeline}
          >
            {" "}
            <SvgTimeline />{" "}
          </TimelineClosed>
        )}
        <TimeLineExist isopen={isTimelineOpen.toString()}>
          {isTimelineOpen && (
            <TimelineContainer>
              <CourseTimelineV2
                role={role}
                data={data}
                classTitle={classTitle}
                onSelectResource={handleResourceSelect}
                closeTimeline={closeTimeline} />
              {/* <CourseTimeline
                role={role}
                data={data}
                classTitle={classTitle}
                onSelectResource={handleResourceSelect}
                closeTimeline={closeTimeline}
              /> */}
            </TimelineContainer>
          )}
        </TimeLineExist>
        {!isTimelineOpen && <div></div>}
        <ContentContainer>
          <Typography text={t("course view.Class View")} fontSize="1.75rem" />
          <ContentComponent
            data={data}
            selectedResource={selectedResource}
            closeTimeline={closeTimeline}
            isTimelineOpen={isTimelineOpen}
            openTimeline={openTimeline}
            classId={classId.classId || ""}
          />
        </ContentContainer>
      </TimelineContentWrapper>
    </>
  );
}
