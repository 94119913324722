interface AccommodationsPopUpInterface {
  id:string;
  title:string;
  text:string;
}
export const AccommodationsPopUpData : AccommodationsPopUpInterface[] =[

  {
    id:"sadefrgtgrfeds",
    title: "Due date accommodation",
  text:"Students with a due date accommodation never have their work marked late. When students with accommodations are in a group, all students in that group inherit the accommodation."
  },
  {id:"Sdefrgty65tr4edcvthg",
    title:"Time limit accommodation",
    text:"Students with a time limit accommodation have more time to finish their work during timed assessments."
  }
]