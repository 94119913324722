import styled from "styled-components";
import DatePicker from "react-datepicker";
import { DatePickerProps } from "./inputInterface";

export const StyledInput = styled.div<DatePickerProps>`
  position: relative;
  width: ${(props) => props.width || "27.8125rem"};
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: ${(props) => props.themetextcolor};
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
  }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    margin-left: -4px;
    position: absolute;
    width: 0;
  }
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
  }

  .react-datepicker {
    font-family: "Primary Font", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: ${(props) => props.bg_datepicker};
    color: ${(props) => props.themetextcolor};
    border: 1px solid ${(props) => props.bg_datepicker};
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
  }
  .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: ${(props) => props.bg_datepicker};
    border-bottom-right-radius: 0.3rem;
  }

  ::-webkit-scrollbar {
    width: 0.45rem;
  }

  ::-webkit-scrollbar-thumb {
    background: var(
      --Green-Gradient,
      linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)
    );
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.bg_datepicker};
    border-radius: 0.625rem;
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 0;
  }
  .react-datepicker-popper[data-placement^="top"] {
    padding-bottom: 0px;
  }

  .react-datepicker__header {
    text-align: center;
    background-color: ${(props) => props.bg_datepicker};
    border-bottom: 1px solid ${(props) => props.bg_datepicker};
    border-top-left-radius: 0.3rem;
    padding: 1.44rem 1.44rem 0rem 1.44rem;
    position: relative;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-top: 0;
    margin-bottom: 1.3rem;
    color: ${(props) => props.themetextcolor};
    font-weight: bold;
    font-size: 0.944rem;
  }

  .react-datepicker__navigation--previous {
    top: 1.06rem;
    left: 1.44rem;
  }
  .react-datepicker__navigation--next {
    top: 1.06rem;
    /* right: 1.44rem; */
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    border-radius: 1.25rem;
    background-color: rgba(93, 211, 179, 0.5);
    color: ${(props) => props.themetextcolor};
    border: 1px solid #5dd3b3;
    filter: drop-shadow(0px 0px 10px rgba(93, 211, 179, 0.5));
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: rgba(93, 211, 179, 0.5);
    color: ${(props) => props.themetextcolor};
    font-weight: bold;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background-color: rgba(93, 211, 179, 0.5);
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled {
    border-radius: 1.25rem;
    background-color: rgba(93, 211, 179, 0.5);
    color: ${(props) => props.themetextcolor};
    border: 1px solid #5dd3b3;
    filter: drop-shadow(0px 0px 10px rgba(93, 211, 179, 0.5));
  }

  .react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }
  .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    border-radius: 1.25rem;
    background-color: rgba(93, 211, 179, 0.5);
    color: ${(props) => props.themetextcolor};
    border: 1px solid #5dd3b3;
    filter: drop-shadow(0px 0px 10px rgba(93, 211, 179, 0.5));
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: ${(props) => props.themetextcolor};
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 1.25rem;
    background-color: rgba(93, 211, 179, 0.5);
    color: ${(props) => props.themetextcolor};
    filter: drop-shadow(0px 0px 10px rgba(93, 211, 179, 0.5));
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 1.25rem;
    background-color: rgba(93, 211, 179, 0.5);
    color: ${(props) => props.themetextcolor};
    border: 1px solid #5dd3b3;
    filter: drop-shadow(0px 0px 10px rgba(93, 211, 179, 0.5));
  }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    color: #5dd3b3;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 1.25rem;
    background-color: rgba(93, 211, 179, 0.5);
    color: ${(props) => props.themetextcolor};
    border: 1px solid #5dd3b3;
    filter: drop-shadow(0px 0px 10px rgba(93, 211, 179, 0.5));
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    border-radius: 1.25rem;
    background-color: rgba(93, 211, 179, 0.5);
    color: ${(props) => props.themetextcolor};
    border: 1px solid #5dd3b3;
    filter: drop-shadow(0px 0px 10px rgba(93, 211, 179, 0.5));
  }
  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__month-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__quarter-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__year-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ) {
    color: #5dd3b3;
  }

  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    cursor: default;
    color: ${(props) => props.themetextcolor};
    opacity: 0.5;
  }
  .react-datepicker__day--disabled:hover,
  .react-datepicker__month-text--disabled:hover,
  .react-datepicker__quarter-text--disabled:hover,
  .react-datepicker__year-text--disabled:hover {
    background-color: transparent;
  }
`;

export const Arrow = styled.div`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:dir(ltr) {
    right: 1.25rem;
  }
  &:dir(rtl) {
    left: 1.25rem;
  }
`;
export const DatePickerStyle = styled(DatePicker)<DatePickerProps>`
  width: ${(props) => props.width || "27.8125rem"};
  /* min-width: 100% !important; */
  height: 2.8125rem;
  padding: 0.94rem 1.25rem;
  border-radius: 0.625rem;
  border: 1px solid ${(props) => props.inputbordercolor};
  background-color: transparent;
  color: ${(props) => props.themetextcolor};
  font-size: 0.875rem;
  /* opacity: 0.5; */
  font-family: ${(props) => props.theme.font.primaryRegular};
  cursor: pointer;

  &:focus {
    outline: none;
    caret-color: ${(props) => props.themetextcolor};
  }
  &::placeholder {
    color: ${(props) => props.themetextcolor};
    font-size: 0.875rem;
    opacity: 0.5;
  }
`;

// export const DateToDisplay = styled.input<DatePickerProps>`
//   position: absolute;
//   transform: translateY(-50%);
//   top: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 0.875rem;
//   background-color: transparent;
//   color: ${(props) => props.themetextcolor};
//   border: none;
//   &:focus {
//     outline: none;
//     caret-color: ${(props) => props.themetextcolor};
//   }
//   &:dir(ltr) {
//     left: 1.25rem;
//   }
//   &:dir(rtl) {
//     right: 1.25rem;
//   }
// `;
