import React, { useState, useEffect } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import {
  Arrow,
  Select,
  SelectContainer,
  StyledInput,
  OptionsContainer,
  Options,
  MainOptionContainer,
} from "./SelectInput.style";
import { ReactComponent as SvgPlygonIcon } from "../../assets/icons/Arrow.svg";

import { InputSelectInterface } from "./SelectInputInterface";
import { LightTypography } from "../fonts/Fonts";
import { SvgArrowShock, SvgArrowSimple, SvgEyeIcon } from "../Icons";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

const DropDownSelect = ({
  options,
  value = "",
  text = "",
  placeholder = "",
  onChange,
  width,
  optionWidth,
  fontSize,
  border,
  marginLeft,
  padding,
  height,
  icon: IconComponent,
  color,
  zIndex,
  display,
  fontFamily,
}: InputSelectInterface) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(value);
  const [selectedOptionName, setSelectedOptionName] = useState<string | null>(value);
  const [selectedIcon, setSelectedIcon] = useState<React.ReactNode>(<></>);
  const { themeTextColor, bg_selectColor } = useRecoilValue(ColorSelector);

  useEffect(() => {
    setSelectedOption(value);
    const selectedOptionObject = options?.find(
      (option) => option.value === value
    );
    setSelectedIcon(selectedOptionObject ? selectedOptionObject.icon : null);
  }, [value, options]);

  const handleOptionClick = (option: any) => {
    setSelectedOption(option.value);
    setSelectedOptionName(option.name);
    onChange(option.value);
    setSelectedIcon(option.icon);
    setIsOpen(false);
  };

  const containerRef = useClickOutside(isOpen, () => setIsOpen(false));

  return (
    <StyledInput
      ref={containerRef}
      width={width}
      isopen={isOpen.toString()}
      border={border}
      padding={padding}
      height={height}
      onClick={() => setIsOpen(!isOpen)}
    >
      <LightTypography color="#5DD3B3" text={text} fontSize="0.875rem" />

      <SelectContainer zIndex={zIndex || 9} isOpen={isOpen} >
        <Select color={color || "#5DD3B3"} >
          {selectedOption ? (
            <MainOptionContainer fontSize={fontSize}>
              {IconComponent && <SvgEyeIcon />}
              {selectedIcon} {selectedOptionName}
            </MainOptionContainer>
          ) : (
            <div>
              <LightTypography
                color="#5DD3B3"
                text={placeholder}
                fontSize="0.855rem"
              />
            </div>
          )}
          <Arrow marginLeft={marginLeft}>
            <SvgPlygonIcon color="#5DD3B3" />
          </Arrow>
        </Select>

        {isOpen && (
          <OptionsContainer optionWidth={optionWidth}>
            {options?.map((option, index) => (
              <div key={index} onClick={() => handleOptionClick(option)}>
                <Options
                  fontFamily={fontFamily || "Primary Font-Bold "}
                  display={display || ""}
                  index={index}
                  lastitem={options.length - 1}
                  color={themeTextColor}
                  backgroundColor={bg_selectColor}
                  // style={{backgroundColor: bg_selectColor}}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>{option.name}</div>
                  </div>
                </Options>
              </div>
            ))}
          </OptionsContainer>
        )}
      </SelectContainer>
    </StyledInput>
  );
};

export default DropDownSelect;
