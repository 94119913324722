import styled, { css } from "styled-components";
interface MessagePOPUP {
height?:string;
showFriendsList?:boolean;
bgColor?:string;
color?:string;
}


export const PageHeaderMessageContainer = styled.div<MessagePOPUP>`
width:11rem;
height:84%;
display: flex;
flex-direction: column;
position:fixed;
/* background-color:red; */
right:0;
z-index: 38888888888888;
bottom:0rem;
width: 19rem;
flex-shrink: 0;
border-radius: 1.25rem 0rem 0rem 0rem;
height: ${props => props.height || '84%'};
background:${props =>props.bgColor || "#384450"};
box-shadow: -5px -5px 20px 0px rgba(0, 0, 0, 0.15);
justify-content: flex-end !important;
  position: fixed;
  height: fit-content;
  max-height: 84%;
  padding-top: 1rem !important;
  bottom:${props =>
    props.showFriendsList ? "-100%" : "0"};
  /* Added transition for smooth animation */
  transition: transform 0.5s ease-in-out;
  color:${props =>props.color};
  /* Push up animation when ShowFriendsList is true */
  /* ${props =>
    props.showFriendsList ?
    css`
      transform: translateY(-117%);
    `:   css`
      transform: translateY(0);
    ` } */
`;


