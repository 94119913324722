import React, { FC } from "react";
import { theme } from "../../theme";
import { Typography } from "../fonts/Fonts";
import {
  Overlay,
  BackgroundOverlay,
  PopupContainer,
  CancelButton,
  DeleteButton,
  DeletePopupButtons,
  PopupContentContainer,
} from "./PopupDelete.style";
import { ColorSelector, MainColorSelector } from "../../recoil/ThemeSelectors";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../contexts/ContextProvider";

interface PopupDeleteProps {
  show: boolean;
  title?: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
}

const PopupDelete: FC<PopupDeleteProps> = ({
  show,
  title,
  setShow,
  handleSubmit,
}) => {
  const { backgroundColor } = useRecoilValue(ColorSelector);
  const { setIsPopUpOpen } = useStateContext();
  const { mainBackgroundColor, mainColor } = useRecoilValue(MainColorSelector);
  const { t } = useTranslation();
  const handleDelete = () => {
    handleSubmit();
    setShow(false);
  };
  return (
    <Overlay isvisible={show.toString()}>
      <BackgroundOverlay>
        <PopupContainer>
          <PopupContentContainer backgroundColor={backgroundColor}>
            <Typography
              text={`${title}`}
              fontSize="1.125rem"
              color={mainColor}
            />

            <DeletePopupButtons>
              <DeleteButton onClick={handleDelete}>
                {t("group.Delete")}
              </DeleteButton>
              <CancelButton
                name="Cancel"
                onClick={() => {
                  setShow(false);
                  setIsPopUpOpen(false);
                }}
              >
                {t("forms.Cancel")}
              </CancelButton>
            </DeletePopupButtons>
          </PopupContentContainer>
        </PopupContainer>
      </BackgroundOverlay>
    </Overlay>
  );
};

export default PopupDelete;
