import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  CommonCoursesStylee,
  DataExport,
  PPMainCols,
  PPMainRows,
  PicAndButtons,
} from "./ProfileView.style";
import { PublicViewProPic } from "../../../elements/PublicViewProfilePicture/PublicViewProPic";
import { ButtonSocialMediaTsx } from "../../../elements/ButtonSocialMediaAccount/ButtonSocialMedia";
import OngoingCommonCourses from "../../../components/ongoingCommonCourses/OngoingCommonCourses";
import { ProfilePageHeader } from "../ProfilePageHeader/ProfilePageHeader";
import { CourseCardData } from "../../../components/courseCard/CourseCardData";
import { PuplicProfileDataTsx } from "../../../components/PuplicProfileData/PuplicProfileData";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../../utils/StringManipulation";
import { get } from "../../../utils/AxiosRequests";
import TitleHeaderTextIconNavigator from "../../../elements/TitleHeaderTextIconNavigator/TitleHeaderTextIconNavigator";
import { useTranslation } from "react-i18next";
import MenuItemsV2 from "../../../components/ProfileMenueSetting/MenueItemsV2";

export const UserView = (): JSX.Element => {
  const [ProfilePic, setProfilePic] = useState("");
  const [FullName, setFullName] = useState("");

  const { user } = useAuth0();
  const tenantName = localStorage.getItem("tenant");
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");

  const img = require("../../../assets/userProfile.png");
  const [Data, setData] = useState<any>({});
  useEffect(() => {
    const getuserProfileById = async () => {
      try {
        const userProfileToDisplay = await get(
          `user/profile/${subIdSplitted}/${tenantName}?provider=${provider}`
        );
        console.log("userProfileToDisplay wsw", userProfileToDisplay);
        setFullName(
          userProfileToDisplay.data.fName +
            " " +
            userProfileToDisplay.data.lName
        );

        setData(userProfileToDisplay.data);

        console.log("userProfileToDisplay is ::", userProfileToDisplay);
      } catch (error) {
        console.error(error);
      }
    };
    getuserProfileById();
  }, []);
  const { t } = useTranslation();
  console.log("Data userProfileToDisplay is ::", Data);
  return (
    <>
      <PPMainCols>
        <TitleHeaderTextIconNavigator title={t("User Profile.User Profile")} />
        <PPMainRows>
          <PicAndButtons>
            <PublicViewProPic src={ProfilePic || img} />

            <ButtonSocialMediaTsx
              LinkedInLink={Data.linkedIn || ""}
              YouTubeLink={Data.youTube || ""}
              EmailVALUE={Data.email || ""}
              // Message={"81235624"}
              TitTokLink={Data.tiktok || ""}
              XLink={Data.xPlatform || ""}
              Website={Data.webLink || ""}
            />
          </PicAndButtons>
          <DataExport>
            <PuplicProfileDataTsx
              FullName={FullName || ""}
              data={Data} // Provide the data prop here
            />
          </DataExport>
        </PPMainRows>
        {/* <CommonCoursesStylee>
          <OngoingCommonCourses Title="On-Going Common Courses :" />
        </CommonCoursesStylee> */}
      </PPMainCols>
    </>
  );
};
