import React, { useEffect, useState } from "react";
import { TextEditor } from "../../elements/TextEditor/TextEditor";
import {
  ButtonsDiv,
  Container,
  FlexDiv,
  Header,
  WarningMsg,
} from "./ShareProgressPost.style";
import {
  LightTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import { SvgShareIcon, SvgAboutIcon, SvgClose } from "../../elements/Icons";
import SelectInput from "../../elements/SelectInput/SelectInput";
import CheckBoxInput from "../../elements/checkbox/CheckBox";
import CancelButton from "../../elements/StyledButton/CancelButton";
import { Courses, Groups } from "./ShareProgressPost.data";
import { DataInterface } from "./ShareProgressPostInterface";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";

const ShareProgressPost = ({
  onClosePopup,
  isBadge,
  classData,
  selectedId,
  isUpdateMode,
}: DataInterface) => {
  const [selectedRadio, setSelectedRadio] = useState("course");
  const [description, setDescription] = useState("");
  const [courseName, setCourseName] = useState("");
  const [groupName, setGroupName] = useState("");
  const [postData, setPostData] = useState<Array<any>>([]);

  // handle description change
  const handleDescriptionChange = (value: string) => {
    setDescription(value);
  };

  // handle radio change for course name or group name
  const handleRadioChange = (value: string) => {
    setSelectedRadio(value);
    setGroupName("");
    setCourseName("");
  };

  // dropdown select for course name
  const handleClassChange = (value: string) => {
    setCourseName(value);
  };

  // dropdown select for group name
  const handleGroupChange = (value: string) => {
    setGroupName(value);
  };

  // function to generate a random id for a post
  function generateRandomId() {
    const min = 5;
    const max = 9999;
    const randomId = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomId.toString();
  }

  const randomId = generateRandomId();

  // useEffect to fetch post data and update the states for update
  useEffect(() => {
    const storedData = localStorage.getItem("postData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setPostData(parsedData);

      const selectedPost = parsedData.find(
        (post: any) => post.id === selectedId
      );

      if (selectedPost) {
        setDescription(selectedPost.postDescription);
        setSelectedRadio(selectedPost.selectedRadio);
        setCourseName(selectedPost.courseName);
        setGroupName(selectedPost.groupName);
      }
    }
  }, [selectedId, isUpdateMode]);

  // handle post
  const handlePost = () => {
    const newPost = {
      id: randomId,
      postDescription: description,
      selectedRadio: selectedRadio,
      courseName: courseName,
      groupName: groupName,
      courseImage: require("../../assets/marketing-courses.png"),
      views: 0,
      responses: 0,
    };

    const existingPostIndex = postData.findIndex(
      (post) => post.id === selectedId
    );

    if (existingPostIndex !== -1) {
      // If the selectedId exists, update the existing entry
      const updatedPostData = [...postData];
      updatedPostData[existingPostIndex] = newPost;
      setPostData(updatedPostData);
      localStorage.setItem("postData", JSON.stringify(updatedPostData));
    } else {
      // If the selectedId doesn't exist, add the new entry
      const updatedPostData = [...postData, newPost];
      setPostData(updatedPostData);
      localStorage.setItem("postData", JSON.stringify(updatedPostData));
    }

    onClosePopup();
  };

  // const selectedModule = classData.reduce((foundModule:any, classItem:any) => {
  //   const module = classItem.modules.find((module:any) => module.id === selectedModuleId);
  //   return module ? { ...module, className: classItem.className } : foundModule;
  // }, null);
  return (
    <Container>
      <Header>
        <FlexDiv>
          <SvgShareIcon />
          <Typography text="Your Social Feed Post" fontSize="1.125rem" />
        </FlexDiv>
        {/* <SvgClose onClick={onClosePopup} style={{cursor: "pointer"}} color="#5DD3B3"/> */}
      </Header>
      {isBadge && (
        <FlexDiv>
          <LightTypography
            fontSize="0.875rem"
            fontWeight="400"
            text={`Congratulation for completing module name! Your certificate is now minted on the Blockchain and it can be viewed on Zidyia Passport using Transaction Hash Key 09876567890987656`}
          />
        </FlexDiv>
      )}

      <TinyEditor
        onChange={handleDescriptionChange}
        placeholder="Type something"
        initialValue={description}
        height="13.25rem"
      />

      <WarningMsg>
        <SvgAboutIcon color="#21D6F8" />
        <TypographyRegularDescription
          color="#21D6F8"
          fontSize="0.75rem"
          text="Please post messages that are relevant to you or your peers' learning experience, and refrain from spamming the forums or posting unwanted content."
        />
      </WarningMsg>

      <FlexDiv gap="0.62rem">
        <CheckBoxInput
          name="SelectUpload"
          value="course"
          checked={selectedRadio === "course"}
          onChange={() => handleRadioChange("course")}
        />
        <TypographyRegularDescription
          text="Share Only With Users Enrolled In Course"
          fontSize="1rem"
        />
        {selectedRadio === "course" && (
          <SelectInput
            onChange={handleClassChange}
            width="21.375rem"
            optionWidth="21.375rem"
            placeholder="Grism- Bundle - Arabic"
            options={Courses}
            value={courseName}
          />
        )}
      </FlexDiv>
      {/* <FlexDiv gap="0.62rem">
            <CheckBoxInput name="SelectUpload" value="everyoneUnder" // checked={selectedRadio === "Everyone Under"} // onChange={() => handleToggleChangeSelect(true)} />
          <TypographyRegularDescription text="Users Enrolled In The Course" fontSize="1rem"/>
          <SelectInput onChange={()=>{}} width="21.375rem" optionWidth="21.375rem" placeholder="Grism- Bundle - Arabic" options={EveryoneUnder}/>
        </FlexDiv> */}
      <FlexDiv gap="0.62rem">
        <CheckBoxInput
          name="SelectUpload"
          value="group"
          checked={selectedRadio === "group"}
          onChange={() => handleRadioChange("group")}
        />
        <TypographyRegularDescription
          text="Share Only With Users Of This Group"
          fontSize="1rem"
        />
        {selectedRadio === "group" && (
          <SelectInput
            onChange={handleGroupChange}
            width="21.375rem"
            optionWidth="21.375rem"
            placeholder="Select a Group"
            options={Groups}
            value={groupName}
          />
        )}
      </FlexDiv>

      {/* action buttons */}
      <ButtonsDiv>
        <FlexDiv style={{ float: "right" }}>
          <CancelButton
            onClickFunction={onClosePopup}
            name="Cancel"
            color="#D85D66"
            borderColor="#D85D66"
            backgroundColor="#384450"
          />
          <CancelButton
            onClickFunction={handlePost}
            name={isUpdateMode ? "Update" : "Post"}
            color="#fff"
            borderColor="#5DD3B3"
            backgroundColor="#5DD3B3"
          />
        </FlexDiv>
      </ButtonsDiv>
    </Container>
  );
};

export default ShareProgressPost;
