import React, { useState, FC, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  uuidSplitter,
  auth0ProviderSplitter
} from "../../utils/StringManipulation";
import axios from "axios";
import { Container } from "../../elements/StyledContainerForm/StyledContainerForm.style";
import {
  LightTypography,
  PrimaryFontBoldText,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  LabelDiv,
  BtnsDiv,
  LabelBtnsDiv,
  ButtonsDiv,
  FlexDiv,
  AddUrlDiv,
  FlexDivTitle,
  FlexBetween,
} from "./UrlLinkForm.style";
import StyledInput from "../../elements/StyledInput/StyledInput";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { SVGWebsite, SvgAdd, SvgClose } from "../../elements/Icons";
import Toggle from "../../elements/Toogle/toogle";
import TwoDatePicker from "../../elements/TwoDatePicker/TwoDatePicker";
import CancelButton from "../../elements/StyledButton/CancelButton";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import { useStateContext } from "../../contexts/ContextProvider";
import LoaderThreeDot from "../loader/loaderThreeDot/LoaderThreeDot";
import CheckBoxInput from "../../elements/checkbox/CheckBox";
import { ContentFormProps } from "../../interfaces/ContentFormProps";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { WebSocketConnector } from "../../Views/messages/webSocketConnection";
import LinkTreeForm from "../LinkTreeForm/LinkTreeForm";
import { ContentProps, TopicProps } from "../../Views/teacherView/Course-Creator/courseCreatorInterface";
import { WebsocketContext } from "../../contexts/notificationContext";

interface urlLinkProps {
  topicContents?: ContentProps[];
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit?: () => void;
  setShowEditContent?: React.Dispatch<React.SetStateAction<boolean>>;
  showEditContent?: boolean;
  setContentTitleInTopic?: (contentTitle: string) => void;
  editContent?: (contentId: number, newTitle: string) => void;
  newContentTitle?: string | undefined;
  contentId?: number | undefined;
  activeGetModulesWithTopics?: () => void;
  newTopicRefId?: number;
  setIsUrlLinkactive?: React.Dispatch<React.SetStateAction<boolean>>;
  isUrlLinkactive?: boolean;
  titleLinkTree?: string;
  descriptionLinkTree?: string;
  urlLinkTree?: string;
  linkIdToEdit?: string;
  topicRefIdUrl?: number;
  removeContent?: (
    topic: TopicProps,
    contentId: number,
    contentIndex: number,
  ) => void;
  topic?: TopicProps,
  contentIndex?: number
  topicRefIdLinkTree?: number
  contentIndexLinkTree?: number
}


const UrlLinkForm: FC<urlLinkProps> = ({
  setShow,
  handleSubmit,
  setShowEditContent,
  showEditContent,
  setContentTitleInTopic,
  editContent,
  newContentTitle,
  contentId,
  activeGetModulesWithTopics,
  newTopicRefId,
  topic,
  contentIndex,
  removeContent,
  setIsUrlLinkactive,
  isUrlLinkactive,
  titleLinkTree,
  descriptionLinkTree,
  urlLinkTree,
  topicRefIdLinkTree,
  contentIndexLinkTree
}) => {
  const classSourceId = useParams();
  const { t } = useTranslation();
  const setClassSourceId = classSourceId.id;
  const { isLoading, user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const baseURL = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";
  const path = "resource/create";
  const tenantName = localStorage.getItem("tenant");
  const {
    setContent_Title,
    contentType,
    setContent_Data,
    getContent_Data,
    contentIdToEdit,
    topicRefId,
    content_Index,
    setIsPopUpOpen,
  } = useStateContext();

  const [isLinkTreeActive, setIsLinkTreeActive] = useState<boolean>(false);
  const [linkIdToEdit, setLinkIdToEdit] = useState<string>("");
  const [prevData, setPrevData] = useState<any>();
  const [readyToEdit, setReadyToEdit] = useState(true);
  const [foundRefIdState, setFoundRefIdState] = useState<number>();
  const [visibleStudents, setVisibleStudents] = useState(true);
  const [showTimeVisibility, setShowTimeVisibility] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState(false);
  const [vimeoLink, setVimeoLink] = useState(false);
  const [urlName, setUrlName] = useState("");
  const [description, setDescription] = useState("");
  const [urlLink, setUrlLink] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [error, setError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  // const [isYoutube, setIsYoutube] = useState(false);
  const [urlType, setUrlType] = useState("URL");
  const { isReady, value, send } = useContext(WebsocketContext);

  useEffect(()=>{
    setUrlName(titleLinkTree ? titleLinkTree : "")
    setDescription(descriptionLinkTree ? descriptionLinkTree : "")
    setUrlLink(urlLinkTree ? urlLinkTree : "")
  },[isUrlLinkactive])

  const handleSwitchView = () => {
    setIsLinkTreeActive(true)
    setIsUrlLinkactive && setIsUrlLinkactive(false)
  }

  // Function to update form
  const updateLink = async (path: string, body: any) => {
    try {
      const response = await axios.put(
        `${baseURL}/${path}/${foundRefIdState}/${tenantName}`,
        { data: body }
      );
      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
      throw error; // Rethrow the error to be handled by the calling service
    }
  };

  // Function to get data
  const getURLToEdit = async (path: string, urlId: number) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(`${baseURL}/${path}/${urlId}/${tenantName}`);
      setPrevData(res.data.data);
      setReadyToEdit(false);
      const toReturn: any[] = res.data.data;
      return toReturn;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (showEditContent && urlName !== "") {
      setReadyToEdit(false);
    }
  }, [urlName]);

  // This useEffect hook is used to fetch data when showEditContent is true.
  useEffect(() => {
    const fetchData = async () => {
      const yourArray = await (activeGetModulesWithTopics && activeGetModulesWithTopics());
      const solutionArray: any[] = Array.isArray(yourArray) ? yourArray : [];
      function findRefIdInModules(
        modulesArray: any[],
        targetContentId: number
      ) {
        for (let i = 0; i < modulesArray.length; i++) {
          const topicsArray = modulesArray[i].topicsArray;

          for (let j = 0; j < topicsArray.length; j++) {
            const contentsArray = topicsArray[j].contents;

            for (let k = 0; k < contentsArray.length; k++) {
              if (contentsArray[k].contentId === targetContentId) {
                return contentsArray[k].refId;
              }
            }
          }
        }
        // Return a default value (e.g., null) if the contentId is not found
        return null;
      }
      function findIsYoutubeInModules(
        modulesArray: any[],
        targetContentId: number
      ) {
        for (let i = 0; i < modulesArray.length; i++) {
          const topicsArray = modulesArray[i].topicsArray;

          for (let j = 0; j < topicsArray.length; j++) {
            const contentsArray = topicsArray[j].contents;

            for (let k = 0; k < contentsArray.length; k++) {
              if (
                contentsArray[k].contentId === targetContentId &&
                contentsArray[k].type === "Link"
              ) {
                return contentsArray[k].isYoutube;
              }
            }
          }
        }
        // Return a default value (e.g., null) if the contentId is not found
        return null;
      }
      if (contentIdToEdit) {
        const contentIdToFind = contentIdToEdit; // Replace with the specific contentId you're looking for
        const foundRefId = findRefIdInModules(solutionArray, contentIdToFind);
        setFoundRefIdState(foundRefId);
        const isYoutube = findIsYoutubeInModules(
          solutionArray,
          contentIdToFind
        );
        if (isYoutube) {
          await getURLToEdit("resource/youtube", foundRefId);
        } else {
          await getURLToEdit("resource/link", foundRefId);
        }
      }
    };
    if (!isUrlLinkactive && showEditContent) {
      fetchData();
      // setReadyToEdit(false);
    }
  }, []);

  // This useEffect hook is used to update the states with edit data (prevData)
  useEffect(() => {
    if (prevData && showEditContent) {
      setLinkIdToEdit(prevData.id)
      setContent_Title(prevData.title);
      setUrlName(prevData.title);
      setDescription(prevData.description);
      setUrlLink(prevData.url);
      setYoutubeLink(prevData.youtubeLink);
      if (prevData.youtubeLink) {
        setUrlType("youtube");
      }
      setVisibleStudents(prevData.isVisible);
      setShowTimeVisibility(
        prevData.visibleFrom === null || prevData.visibleTo === null
          ? false
          : true
      );
      setFromDate(prevData.visibleFrom ? new Date(prevData.visibleFrom) : null);
      setToDate(prevData.visibleTo ? new Date(prevData.visibleTo) : null);
    }
  }, [prevData]);

  // Function to handle description
  const handleDescriptionChange = (value: any) => {
    setDescription(value);
  };

  // handle toggle change visiblity for students
  const handleToggleChangeVisible = (isChecked: boolean) => {
    setVisibleStudents(isChecked);
  };

  const handleToggleYoutube = (isChecked: boolean) => {
    setYoutubeLink(isChecked);
  };

  // function to set time visibility interval
  const handleTwoDatePickerChange = (start: Date | null, end: Date | null) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleToggleChangeTime = (isChecked: boolean) => {
    if (isChecked) {
      setShowTimeVisibility(true); // Show Time Visibility when the toggle is on
    } else {
      setShowTimeVisibility(false);
      setToDate(null);
      setFromDate(null);
    }
  };

  const isValidUrl = (url: string) => {
    // Regular expression for a simple URL validation
    // const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return url;
  };

  //THIS FUNCTION CHECK THE VALUE PARAMETER AND SET THE TYPE OF THE LINK
  const handleRadioChange = (value: string) => {
    setUrlType(value);
    if (value === "URL") {
      setYoutubeLink(false);
      setVimeoLink(false);
    } else if (value === "youtube") {
      setYoutubeLink(true);
      setVimeoLink(false);
    } else if (value === "vimeo") {
      setVimeoLink(true);
      setYoutubeLink(false);
    }
  };

  // handle save
  const handleContinueClick = async () => {
    if (
      urlName.trim() === "" ||
      description.trim() === "" ||
      urlLink.trim() === "" ||
      (showTimeVisibility && (!fromDate || !toDate))
    ) {
      setError(true);
      setShow && setShow(true);
    } else if (!isValidUrl(urlLink)) {
      setUrlError(true);
      setError(false);
      setShow && setShow(true);
    } else {
      setUrlError(false);
      setError(false);
      if (contentId !== undefined && newContentTitle !== undefined)
        editContent && editContent(contentId, newContentTitle);
      setShowEditContent && setShowEditContent(false);
      setShow && setShow(false);
      setIsPopUpOpen(false);
      if (!showEditContent) {
        handleSubmit && handleSubmit();
      }

      const UrlLinkData: any = {
        _id: contentIdToEdit,
        id: showEditContent ? foundRefIdState : null,
        classSourceId: setClassSourceId,
        userSourceId: subId,
        provider:provider,
        contentIndex: isUrlLinkactive ? contentIndexLinkTree : content_Index,
        topicRefId: isUrlLinkactive ? topicRefIdLinkTree : topicRefId,
        type: "Link",
        title: urlName,
        description: description,
        urlLink:  urlLink &&
        (urlLink.startsWith("https://") || urlLink.startsWith("http://"))
          ? urlLink
          : urlLink
          ? `https://${urlLink}`
          : "",
        youtubeLink: youtubeLink,
        vimeoLink: vimeoLink,
        showTimeVisibility: showTimeVisibility,
        visibleStudents: visibleStudents,
        fromDate: fromDate,
        toDate: toDate,
        urlType: urlType,
      };
      try {
        if (isUrlLinkactive || !showEditContent) {
          const response = await axios.post(
            `${baseURL}/${path}/${tenantName}`,
            { data: UrlLinkData }
          );
          if (isReady && send) {

            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "create",
                  title: urlName,
                  contentType: "Link",
                  refId: response.data.data.resourceId,
                  refTable: youtubeLink ? "YoutubeLinks" : "Iframes",
                  subId: subId,
                  provider:provider,
                  type: "content",
                  description: `Title: ${urlName}`,
                },
              })
            );
          }
          if(topic && contentIndex && contentId){
            removeContent && removeContent(topic, contentId, contentIndex)
            await (activeGetModulesWithTopics && activeGetModulesWithTopics());
          }
        } else if (!isUrlLinkactive || showEditContent) {
          await updateLink("resource/link", UrlLinkData);
          if (isReady && send) {
           send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "update",
                  title: urlName,
                  contentType: "Link",
                  subId: subId,
                  provider:provider,
                  refId: foundRefIdState,
                  refTable: youtubeLink ? "YoutubeLinks" : "Iframes",
                  type: "content",
                  description: `Title: ${urlName}`,
                },
              })
            );
          }
        }
        setShow && setShow(false);
        setIsPopUpOpen(false);
        setIsUrlLinkactive && setIsUrlLinkactive(false)
        // await (activeGetModulesWithTopics && activeGetModulesWithTopics());
      } catch (error) {
        console.error("Axios Error:", error);
      }finally{
        await (activeGetModulesWithTopics && activeGetModulesWithTopics());
      }
    }
  };
  const { backgroundColor, themeTextColor, inputBorderColor, iconCloseColor } =
    useRecoilValue(ColorSelector);

  return (
    <>
      {isLinkTreeActive ? (
        <LinkTreeForm 
        setIsLinkTreeActive={setIsLinkTreeActive}
        isLinkTreeActive={isLinkTreeActive}
        setShow={setShow}
        setShowEditContent={setShowEditContent}
        setContentTitleInTopic={setContentTitleInTopic}
        editContent={editContent}
        contentId={contentId}
        newContentTitle={newContentTitle}
        showEditContent={showEditContent}
        activeGetModulesWithTopics={activeGetModulesWithTopics}
        newTopicRefId={topicRefId}
        titleUrl={urlName}
        descriptionUrl={description}
        urlLinkUrl={urlLink}
        linkIdToEdit={linkIdToEdit}
        topicRefIdUrl={topicRefId}
        contentIndexUrl={contentIndex}
        topic={topic}
        contentIndex={contentIndex}
        removeContent={removeContent}
        />
      ) : (
      <Container
      themetextcolor={themeTextColor}
      backgroundcolor={backgroundColor}
      scrollbar={inputBorderColor}>
      {/* <FlexBetween>
        <FlexDivTitle>
          <SVGWebsite />
          <PrimaryFontBoldText text={t("link.URL Link")} fontSize="1.125rem" />
        </FlexDivTitle> */}
        {showEditContent && readyToEdit && !isUrlLinkactive && <LoaderThreeDot />}
        {/* <SvgClose
          color={iconCloseColor}
          onClick={() => {
            setShow(false);
            setIsPopUpOpen(false);
            setShowEditContent(false);
          }}
          style={{ cursor: "pointer" }}
        /> */}
      {/* </FlexBetween> */}

      {/* title */}
      <AddUrlDiv>
        <LabelDiv>
          <FlexDiv gap="0.25rem">
            <LightTypography text={t("forms.Title")} fontSize="0.875rem" />
            <span>*</span>
          </FlexDiv>
          <StyledInput
            placeholder={t("link.Enter URL Title")}
            type="text"
            onChange={(e: any) => {
              setUrlName(e.target.value);
              setContent_Title(e.target.value);
              setContentTitleInTopic && setContentTitleInTopic(e.target.value);
            }}
            value={urlName}
            width="100%"
          />
          {urlName.trim() === "" && error && (
            <ErrorDiv>{`${t("forms.Please")} ${t(
              "link.Enter URL Title"
            )}`}</ErrorDiv>
          )}
        </LabelDiv>

        {/* description */}
        <LabelDiv>
          <FlexDiv gap="0.25rem">
            <LightTypography
              text={t("forms.Description")}
              fontSize="0.875rem"
            />
            <span>*</span>
          </FlexDiv>
          <TinyEditor
            onChange={handleDescriptionChange}
            placeholder={t("forms.Description")}
            initialValue={`${
              description
            }`}
            height="13.25rem"
          />
          {description.trim() === "" && error && (
            <ErrorDiv>{`${t("forms.Please")} ${t(
              "forms.Enter Description"
            )}`}</ErrorDiv>
          )}
        </LabelDiv>

        {/* Url link */}
        <LabelDiv>
          <FlexDiv gap="0.25rem">
            <LightTypography text={t("link.URL Link")} fontSize="0.875rem" />
            <span>*</span>
          </FlexDiv>
          <StyledInput
            placeholder={t("link.Paste URL Link Here")}
            type="text"
            onChange={(e) => {
              setUrlLink(e.target.value);
              }}
            value={urlLink}
            width="100%"
          />
          {/* <LabelBtnsDiv>
              <Toggle onToggle={handleToggleYoutube} checked={youtubeLink} id="roleToggle3" />
              <TypographyRegularDescription text="YouTube Link" fontSize="1rem" />
            </LabelBtnsDiv> */}
          {urlLink.trim() === "" && error && !urlError && (
            <ErrorDiv>{`${t("forms.Please")} ${t(
              "link.Enter URL Link"
            )}`}</ErrorDiv>
          )}
          {urlError && <ErrorDiv>Please Enter a Valid URL</ErrorDiv>}
        </LabelDiv>

        {/* radio buttons link type */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <FlexDiv>
            {/* url */}
            <FlexDiv gap="0.62rem">
              <CheckBoxInput
                name="SelectUpload"
                value="URL"
                checked={urlType === "URL"}
                onChange={() => handleRadioChange("URL")}
              />
              <TypographyRegularDescription
                text={t("link.URL Link")}
                fontSize="1rem"
              />
            </FlexDiv>
            {/* youtube */}
            <FlexDiv gap="0.62rem">
              <CheckBoxInput
                name="SelectUpload"
                value="youtube"
                checked={urlType === "youtube"}
                onChange={() => handleRadioChange("youtube")}
              />
              <TypographyRegularDescription
                text={t("link.YouTube Link")}
                fontSize="1rem"
              />
            </FlexDiv>
            {/* Vimeo */}
            {/* <FlexDiv gap="0.62rem">
            <CheckBoxInput name="SelectUpload" value="vimeo" checked={urlType === "vimeo"} onChange={() => handleRadioChange("vimeo")} />
            <TypographyRegularDescription text="Vimeo Link" fontSize="1rem" />
          </FlexDiv> */}
          </FlexDiv>

          <FlexDiv gap="0.62rem" style={{cursor: "pointer"}} onClick={handleSwitchView}>
            <SvgAdd />
            <PrimaryFontBoldText text={t("link tree.Add URL Link")} fontSize="0.75rem" />
          </FlexDiv>
        </div>
      </AddUrlDiv>

      {/* toggles */}
      <BtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleChangeVisible}
            checked={visibleStudents}
            id="roleToggle1"
          />
          <TypographyRegularDescription
            text={t("forms.Make Visible for Students")}
            fontSize="1rem"
          />
        </LabelBtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleChangeTime}
            checked={showTimeVisibility}
            id="roleToggle2"
          />
          <TypographyRegularDescription
            text={t("forms.Set Time Visibility Interval")}
            fontSize="1rem"
          />
          {showTimeVisibility && (
            <>
              <TwoDatePicker
                onDateChange={handleTwoDatePickerChange}
                prevStartDate={fromDate}
                prevEndDate={toDate}
              />
              {(!fromDate || !toDate) && error && (
                <ErrorDiv>{`${t("forms.Please")} ${t(
                  "forms.Set Time Visibility Interval"
                )}`}</ErrorDiv>
              )}
            </>
          )}
        </LabelBtnsDiv>
      </BtnsDiv>

      {/* actions buttons */}
      <ButtonsDiv>
        <CancelButton
          name={t("forms.Cancel")}
          onClickFunction={() => {
            setShow && setShow(false);
            setIsPopUpOpen(false);
            setShowEditContent && setShowEditContent(false);
          }}
          color="#D85D66"
          borderColor="#D85D66"
          // backgroundColor="#384450"
        />
        <CancelButton
          name={t("forms.Save")}
          onClickFunction={() => {
            handleContinueClick();
            setContent_Title(urlName);
          }}
          color="#fff"
          borderColor="#5DD3B3"
          backgroundColor="#5DD3B3"
        />
      </ButtonsDiv>
      </Container>
      )}
    </>
  );
};

export default UrlLinkForm;
