import styled from "styled-components";


export const GradeBox = styled.div`
max-width: 18.125rem;
color: #FFF;
/* width: 18.125rem; */
height: 3.75rem;
border-radius: 0.3125rem;
border: 1px solid #FFF;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;

`