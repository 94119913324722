import React, { useEffect } from "react";
import AnalyticForumIdShadowHeader from "../../../components/AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticForumIdShadowHeader";
import { Typography } from "../../../elements/fonts/Fonts";
import { useNavigate, useParams } from "react-router-dom";
import { AnalyticsTopParticipantForumData } from "../../../components/AnalyticsTopParticipantForum/AnalyticsTopParticipantForumData";
import PostsTimeLine from "../../../components/PostsTimeLine/main/PostsTimeLine";
import { FlexAppContainer } from "../../../App.style";
import AnalyticsBackPage from "../../../components/AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticsBackPage";

const AnalyticsForumPosts = () => {
  const { PostId } = useParams<{ PostId: string }>();
  const postData = AnalyticsTopParticipantForumData.find(
    (item) => item.SenderPostId === PostId
  );

  const navigate = useNavigate();
  const NavigatePage = () => {
    navigate(-1);
  };
  return (
    <>
      {postData && postData.Posts && postData.Posts.length > 0 && (
        <FlexAppContainer gap="1.25rem" padding="0 0 2rem 0" column={true}>
          <AnalyticsBackPage onClick={NavigatePage} />
          <Typography
            text={postData.Posts[0].posterName || ""}
            fontSize="1.75rem"
          />
          <AnalyticForumIdShadowHeader
            FirstText="Posts"
            SecondText="Replies"
            FirstNumberValue={postData.Posts.length}
            SecondNumberValue={postData.Posts.reduce(
              (acc, post) => acc + (post.Reply?.length ?? 0),
              0
            )}
          />
          <PostsTimeLine PostsTimeLineData={postData} />
        </FlexAppContainer>
      )}
    </>
  );
};

export default AnalyticsForumPosts;
