import React from "react";

export const theme = {
  dark: {
    primary_text_color: "#fff",
    secondary_text_color: "#D9E4E8",
    main_color: "#5dd3b3",
    secondary_color: "#3CA36B",
    gradient_color_submenu: ["#5DD3B3", "#2F6A5A"],
    gradient_color_courseLabel: ["#5DD3B3", "#3CA36B"],
    submenu_color: "#D9E4E8",
    submenu_hover_color: "#fff",
    bg_main_color: "#2d3741",
    bg_secondary_color: "#384450",
  },
  font: {
    primaryRegular: "Primary Font",
    primaryLight: "Primary Font-Light",
    primaryBold: "Primary Font-Bold",
  },
};
export const breakpoints = {
  mobile: "480px",
  tablets: "768px",
  small: "990px",
  medium: "1024px",
  large: "1280px",
  extraLarge: "1900px",
  TvScreen: "2100px",
};
