import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams, Link } from "react-router-dom";
import {
  ButtonsContainer,
  IconsGroup,
  IdCols,
  IdRows,
  IdminiCols,
  TextLayout,
} from "./Notes.style";
import { TextEditor } from "../../elements/TextEditor/TextEditor";
import { SubmitButtomEditProfile } from "../../elements/SubmitSaveButton/SubmitButtonStyle";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import TitleHeaderTextIconNavigator from "../../elements/TitleHeaderTextIconNavigator/TitleHeaderTextIconNavigator";

interface Note {
  id: number;
  title: string;
  content: string;
}

export const NotesEditById = (): JSX.Element => {
  const { themeTextColor, inputBorderColor } = useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  const { id } = useParams();
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [noteToDisplay, setNoteToDisplay] = useState<Note>();
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  // Ensure that 'id' is a valid string before parsing it
  const parsedId = id ? parseInt(id, 10) : null;
  const tenantName = localStorage.getItem("tenant");

  // Get The note by It's ID
  useEffect(() => {
    const getNoteById = async () => {
      try {
        const noteToDisplay = await axios.get(
          `https://8hpec8qhhg.execute-api.eu-west-1.amazonaws.com/dev/note/${tenantName}/${parsedId}`
        );
        setNoteToDisplay(noteToDisplay.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    getNoteById();
  }, []);

  // Use useEffect to set the initial state only once
  useEffect(() => {
    if (noteToDisplay) {
      setTitle(noteToDisplay.title);
      setDescription(noteToDisplay.content);
    }
  }, [noteToDisplay]);
  if (!noteToDisplay) {
    // Handle the case where the 'id' doesn't match any data
    return <Typography text={t("general.loading")} />;
  }

  // Set The Title of the note
  const handleTitleChange = (newTitle: string) => {
    setTitle(newTitle);
  };

  // Set the Description of the note
  const handleDescriptionChange = (newDescription: string) => {
    setDescription(newDescription);
  };

  //Update the note By It's ID but the id is sended in the bode Not by parameter
  const handleSaveChanges = async () => {
    if (
      parsedId !== null &&
      title !== "" &&
      description !== "" &&
      description !== "<p><br></p>"
    ) {
      const updatedNote = {
        id: parsedId,
        title: title,
        content: description,
      };
      await axios.put(
        `https://8hpec8qhhg.execute-api.eu-west-1.amazonaws.com/dev/note/update/${tenantName}`,
        { data: updatedNote }
      );
      navigate("/Notes");
      setError("");
    } else {
      setError("Fields are mandatory");
    }
  };

  return (
    <>
      {/** Edit Form Goes Here */}
      <IdCols>
        <TitleHeaderTextIconNavigator title={t("notes.notes")} />
        <IdminiCols>
          <TypographyRegularDescription text={t("forms.Title")} />
          <IdRows>
            <TextLayout
              type="text"
              value={title}
              onChange={(e) => handleTitleChange(e.target.value)}
              color={themeTextColor}
              bordercolor={inputBorderColor}
            />
          </IdRows>
          <TypographyRegularDescription text={t("forms.Description")} />
          <TinyEditor
            onChange={handleDescriptionChange}
            initialValue={noteToDisplay.content}
            height="17.5625rem"
          />
          <ErrorDiv>{error}</ErrorDiv>
          <ButtonsContainer>
            <Link to="/Notes">
              {/* Cancel Button */}
              <SubmitButtomEditProfile
                type="button"
                value={t("forms.Cancel")}
                widths="8.8125rem"
                heights="2.125rem"
                color="#D85D66"
                border="#D85D66"
                background="none"
              />
            </Link>
            {/* Save Button */}
            <SubmitButtomEditProfile
              type="button"
              value={t("forms.Save")}
              widths="8.8125rem"
              heights="2.125rem"
              onClick={handleSaveChanges}
            />
          </ButtonsContainer>
        </IdminiCols>
      </IdCols>
    </>
  );
};
