import { ProfileImageHolder, BgProfile, MaskGroup } from "./Profile.style";
import { SvgBgProfiledIcon } from "../Icons";

import { useRecoilValue } from "recoil";
import { courseBannerColorSelector } from "../../recoil/ThemeSelectors";
interface ProfileInterface {
  iconPath: string;
  handleOnMouseEnter?: any;
  handleOnMouseLeave?: any;
}

const Profile = ({ iconPath, handleOnMouseEnter, handleOnMouseLeave }: ProfileInterface) => {
  const { backgroundProfileColor } = useRecoilValue(courseBannerColorSelector);

  return (
    <ProfileImageHolder onMouseEnter={handleOnMouseEnter}>
      <BgProfile>{/* <SvgBgProfiledIcon /> */}</BgProfile>
      <MaskGroup src={iconPath} />
    </ProfileImageHolder>
  );
};

export default Profile;