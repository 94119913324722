import React, { useState } from "react";
import SearchWithFilter from "../../elements/searchWithFilter/SearchWithFilter";
import DropDownSelect from "../../elements/dropDownSelect/SelectInput";
import { BtnsContainer, Container, CourseImageContainer, ExpandView, FlexBetween, FlexDiv, HeadPage, Post, PostContainer, ProfileContainer, ProfileInfo, } from "./ManageSocialFeed.style";
import { ReactComponent as SvgRefreshIcon } from "../../assets/icons/Group 2226.svg";
import { SvgAdd, SvgBackArrow, HourIcon, SvgEdit, SvgShareIcon, SvgScale, SvgEye, SvgSubtitlesLanguageIcon, } from "../../elements/Icons";
import { LightTypography, Typography, TypographyRegularDescription, } from "../../elements/fonts/Fonts";
import { PostData, sortingOptions } from "./ManageSocialFeed.data";
import { TextEditor } from "../../elements/TextEditor/TextEditor";
import CancelButton from "../../elements/StyledButton/CancelButton";
import { useNavigate, useParams } from "react-router-dom";
import ExpandFeedView from "../expandFeedView/ExpandFeedView";
import DOMPurify from "dompurify";
import PopUp from "../../elements/Popup/PopUp";
import ShareProgressPost from "../shareProgressPost/ShareProgressPost";
import { useTranslation } from "react-i18next";

const ManageSocialFeed = () => {
  const { t } = useTranslation()

  const [expandedPostId, setExpandedPostId] = useState(null);
  const [isExpandedView, setIsExpandedView] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSortingOption, setSelectedSortingOption] = useState("");
  const [showSharePopUp, setShowSharePopup] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);
  const { classId } = useParams();
  const navigate = useNavigate();

  // Load postData from local storage during component initialization
  const storedData = localStorage.getItem("postData");
  const localPostData = storedData ? JSON.parse(storedData) : [];

  // Merge localPostData with PostData.postData
  const allPostData = [...PostData.postData, ...localPostData];

  // Function to expand the post based on the post's ID
  const handleExpandClick = (postId: any) => {
    setExpandedPostId(postId === expandedPostId ? null : postId);
    setIsExpandedView(!isExpandedView);

    window.scrollTo({ top: 0, });
  };

  // Handle go back: If the view is expanded, go back to the social feed; if not, go back to completion tracking
  const handleBackArrowClick = () => {
    setExpandedPostId(null);
    setIsExpandedView(false);

    if (!isExpandedView) {
      navigate(`/completionTracking/${classId}`);
    }
  };

  // handle close popup
  const handleClosePopup = () => {
    setShowSharePopup(false);
  };

  // Find the post data for the expanded post
  const expandedPost = allPostData.find((post) => post.id === expandedPostId);

  // Function to sort data
  const sortingFunction = (a: any, b: any) => {
    switch (selectedSortingOption) {
      case "Recently Posted":
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      case "Recently Updated":
        return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
      case "Most Viewed":
        return b.views - a.views;
      case "Most Commented":
        return b.responses - a.responses;
      default:
        // Recently Posted or default sorting
        return 0;
    }
  };

  // search
  const filteredPosts = allPostData.filter(
    (post) =>
      post.postDescription.toLowerCase().includes(searchTerm.toLowerCase()) ||
      post.courseName.toLowerCase().includes(searchTerm.toLowerCase())
  ).sort(sortingFunction);

  // Function to remove the <p> tag from descriptio
  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };

  // Function to pass the post id for edit 
  const handlePostClick = (Id: number) => {
    setSelectedId(Id);
  };

  return (
    <Container>
      {/* header */}
      <FlexDiv gap="0.63rem">
        <SvgBackArrow onClick={handleBackArrowClick} style={{ cursor: "pointer" }} />
        <Typography text="Manage Social Feed" fontSize="1.75rem" />
      </FlexDiv>

      {/* switch between social feed and expanded post */}
      {expandedPost ? (
        <ExpandFeedView expandedPost={expandedPost} PostData={PostData} />
      ) : (
        <>
          {/* search, filter and add social feed */}
          <HeadPage>
            <SearchWithFilter placeholder="Search For a Keyword, title, feed" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} withFilter={false} />
            <DropDownSelect onChange={(option) => setSelectedSortingOption(option)} placeholder="Sorting" width="15rem" height="3.125rem" optionWidth="15rem" options={sortingOptions} value={selectedSortingOption} />
            {/* {selectedFilter && ( */}
            <FlexDiv style={{ cursor: "pointer", width: "10.5rem" }} onClick={() => { setShowSharePopup(true); setIsUpdateMode(false); setSelectedId(null) }}>
              <SvgAdd color="#5DD3B3" />
              <TypographyRegularDescription text={t('dashboard.Add Social Feed')} fontSize="0.75rem" color="#5DD3B3" />
            </FlexDiv>
            {selectedSortingOption &&
              <SvgRefreshIcon style={{ cursor: "pointer" }} onClick={() => { setSelectedSortingOption(""); }} />
            }
          </HeadPage>
          <FlexDiv gap="0.2rem">
            <TypographyRegularDescription text={`Showing`} fontSize="0.875rem" />
            <TypographyRegularDescription text={`${filteredPosts.length}`} fontSize="0.875rem" color="#5DD3B3" />
            <TypographyRegularDescription text={`results`} fontSize="0.875rem" />
          </FlexDiv>

          <PostContainer>
            {filteredPosts.map((post) => (
              <Post key={post.id}>
                <FlexBetween style={{ marginBottom: "1.25rem" }}>
                  <FlexDiv gap="1.25rem">
                    <ProfileContainer bgimage={PostData.learnerProfile} />
                    <ProfileInfo>
                      <Typography text={PostData.learnerName} fontSize="1.125rem" />
                      <FlexDiv>
                        <HourIcon />
                        <TypographyRegularDescription text="An hour ago" fontSize="0.75rem" />
                      </FlexDiv>
                    </ProfileInfo>
                  </FlexDiv>
                  <FlexDiv gap="1.25rem">
                    <SvgEdit style={{ cursor: "pointer" }} onClick={() => { setShowSharePopup(true); handlePostClick(post.id); setIsUpdateMode(true) }} />
                    <SvgShareIcon style={{ cursor: "pointer" }} />
                    <SvgScale onClick={() => handleExpandClick(post.id)} style={{ cursor: "pointer" }} />
                  </FlexDiv>
                </FlexBetween>
                <Typography text={post.courseName} fontSize="1.125rem" />
                <CourseImageContainer bgimage={post.courseImage} />

                <div dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(decodeHtml(post.postDescription)),
                }}
                  style={{ fontSize: '0.875rem', fontWeight: "400", fontFamily: "Primary Font-Light" }}
                >
                </div>

                <FlexDiv gap="0.63rem" style={{ marginTop: "0.69rem" }}>
                  <FlexDiv>
                    <SvgEye color="#5DD3B3" />
                    <TypographyRegularDescription text={`${post.views} Views`} fontSize="0.875rem" color="#5DD3B3" />
                  </FlexDiv>
                  <FlexDiv>
                    <SvgSubtitlesLanguageIcon width="0.875rem" />
                    <TypographyRegularDescription text={`${post.responses} Responses`} fontSize="0.875rem" color="#5DD3B3" />
                  </FlexDiv>
                </FlexDiv>
              </Post>
            ))}
          </PostContainer>
        </>
      )}

      {showSharePopUp && (
        <PopUp content={<ShareProgressPost onClosePopup={handleClosePopup} selectedId={selectedId} isUpdateMode={isUpdateMode} />} showline={false} show={showSharePopUp} setShow={setShowSharePopup} showButton={false} closeContainer={false} paddingRight="0rem" />
      )}
    </Container>
  );
};

export default ManageSocialFeed;
