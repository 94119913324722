import {
  GroupCardRectangle,
  GroupCardHeaderRow,
  PrtcsMoreIconColumn,
  TextAreaInsideCard,
  MembersAreaInsideCard,
  DropDownMenuHolder,
  LineInGrpCard,
  MemberBox,
  MemberProfile,
  FlexMembers,
  TextAreaInsideCard2,
  PrtcsText,
  PrtcsTextResp,
  GroupNamePrtcsResp,
} from "./GroupCard.style";
import { Typography, LightTypography } from "../../elements/fonts/Fonts";
import { SvgMore, SvgAddSq } from "../../elements/Icons";
import { GroupCardDataInterface, GroupCardProps } from "./groupCardInterface";
import useClickOutside from "../../hooks/useClickOutside";
import useToggle from "../../hooks/useToggle";
// import DropDownFilter from "../../components/dropDownFilter/main/DropDownFilter";
import DropDown from "./dropDown/DropDown";
import { useState, useEffect, FC, useMemo, useRef } from "react";
import {
  DropTarget,
  DragDropContainer,
} from "react-drag-drop-container-typescript";
import AddGRoupMembersBar from "../addGroupMembersBar/AddGRoupMembersBar";
import ModalWrapper from "./editModal/modalOverlay/ModalOverlay";

const GroupCard: FC<GroupCardDataInterface & GroupCardProps> = ({
  bordercolor,
  name,
  number,
  text,
  students,
  handleStudentDrop,
  toggleMemberBar,
}) => {
  const chosenColor = useMemo(() => bordercolor, []);
  // const { isOpen: isDropdownVisible, toggle: toggleDropdown } =
  //   useToggle(false);
  // const dropdownRef = useClickOutside(isDropdownVisible, toggleDropdown);
  const [groupMembers, setGroupMembers] = useState<
    Array<{ name: string; profilePicture: string }>
  >([]);

  const menuRef = useRef<HTMLDivElement | null>(null);

  const [modal, setModal] = useState<boolean>(false);
  const toggleModel = () => {
    setModal(!modal);
  };
  const [menu, setMenu] = useState<boolean>(false);
  const toggleMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    const handler = (e: any) => {
      if (menu && menuRef.current && !menuRef.current.contains(e.target)) {
        toggleMenu();
      }
    };

    if (menu) {
      document.addEventListener("mousedown", handler);
    } else {
      document.removeEventListener("mousedown", handler);
    }

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [isMembersBarVisible, setIsMembersBarVisible] = useState(false);

  const toggleMembersBar = () => {
    setIsMembersBarVisible(!isMembersBarVisible);
  };
  // const [activeTab, setActiveTab] = useState(false);

  return (
    <DropTarget targetKey="student" onHit={(e) => handleStudentDrop(e)}>
      <GroupCardRectangle borderColor={chosenColor}>
        <GroupCardHeaderRow>
          <GroupNamePrtcsResp>
            <Typography
              text={name}
              fontSize="1rem"
              fontWeight="400"
              lineHeight="normal"
              color="#FFF"
            />
            <PrtcsTextResp>
              <Typography
                text={number}
                fontSize="0.875rem"
                fontWeight="400"
                lineHeight="normal"
                color="#5DD3B3"
              />
            </PrtcsTextResp>
          </GroupNamePrtcsResp>
          <LineInGrpCard />
          <PrtcsMoreIconColumn>
            <PrtcsText>
              <Typography
                text={number}
                fontSize="0.875rem"
                fontWeight="400"
                lineHeight="normal"
                color="#5DD3B3"
              />
            </PrtcsText>

            <DropDownMenuHolder ref={menuRef}>
              {menu && (
                <div>
                  <DropDown />
                </div>
              )}
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  toggleMenu();
                  // setActiveTab((prev:any) => !prev);
                }}
              >
                <SvgMore color="#FFFFFF" />
              </div>
            </DropDownMenuHolder>
          </PrtcsMoreIconColumn>
        </GroupCardHeaderRow>
        <FlexMembers>
          {students.map((student, index) => (
            <MembersAreaInsideCard key={index}>
              <MemberBox>
                <MemberProfile src={student.profilePicture} />
                {/* <p>{student.name}</p> */}
                <Typography
                  fontSize="0.75rem"
                  fontWeight="400"
                  lineHeight="normal"
                  color="#D9E4E8"
                  text={`${student.firstName} ${student.lastName}`}
                />
              </MemberBox>
            </MembersAreaInsideCard>
          ))}
        </FlexMembers>
        {students.length === 0 && (
          <>
            <TextAreaInsideCard>
              <LightTypography
                text="Drag and drop students here"
                fontSize="0.875rem"
                fontWeight="400"
                lineHeight="normal"
                color="#fff"
              />
            </TextAreaInsideCard>
            <TextAreaInsideCard2 onClick={toggleMembersBar}>
              {isMembersBarVisible && (
                <ModalWrapper>
                  <AddGRoupMembersBar isVisible={true} isOpen={true} />
                </ModalWrapper>
              )}
              <SvgAddSq />

              <LightTypography
                text="Add Students "
                fontSize="0.875rem"
                fontWeight="400"
                lineHeight="normal"
                color="#fff"
              />
            </TextAreaInsideCard2>
          </>
        )}
      </GroupCardRectangle>
    </DropTarget>
  );
};

export default GroupCard;
