import React, { useEffect, useState } from "react";
import { ChoicesCont, PollContainer, QuestionCont } from "./PollClassView.style";
import { Typography } from "../../elements/fonts/Fonts";
import { PollData } from "./PollClassView.data";
import { useAuth0 } from "@auth0/auth0-react";
import { Poll } from "./PollClassViewInterface";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../utils/StringManipulation";
import { get } from "../../utils/AxiosRequests";
import LimitedChoices from "./limitedChoices/LimitedChoices";
import PercentageView from "./percentageView/PercentageView";

const PollClassView = () => {
    const [updatedPollData, setUpdatedPollData] = useState<Poll[]>(PollData);
    const [selectedOption, setSelectedOption] = useState<number | null>(null);
    const [FirstName, setFirstName] = useState<string>("");
    const [ProfilePic, setProfilePic] = useState<string>("");
    const [isLimitedChoiceMode, setIsLimitedChoiceMode] = useState<boolean>(true);
    const [showLimitedChoiceMessage, setShowLimitedChoiceMessage] = useState<boolean>(false)
    const [limitNumber, setLimitNumber] = useState<number>(5);
    const { user } = useAuth0();
    const tenantName = localStorage.getItem("tenant");
    const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
    const provider = auth0ProviderSplitter(user?.sub || "");

    // Function to get user data (name and profile)
    const getuserProfileById = async () => {
        if (tenantName) {
          if (tenantName !== null && tenantName !== "") {
            try {
              const userProfileToDisplay = await get(
                `user/profile/${subIdSplitted}auth0SubIdSplitter/${tenantName}`
              );
              if (userProfileToDisplay.data) {
                setProfilePic(userProfileToDisplay.data.profilePicture);
                setFirstName(userProfileToDisplay.data.fName);
              }
            } catch (error) {
              console.error(error);
            }
          }
        }
      };

    // UseEffect to call getuserProfileById function
    useEffect(()=>{
    getuserProfileById()
    },[])

    // Function to calculate the voting percentage
    const calculatedPercentage = (votersNumber: number) => {
        let studentsNumber = 8;
        const result = (votersNumber / studentsNumber) * 100;
        return result % 1 === 0 ? result.toFixed(0) : result.toFixed(1);
    }
    // Function to calculate the voting limit
    const calculatedLimit = (votersNumber: number) => {
        const result = (votersNumber / limitNumber) * 100;
        return result % 1 === 0 ? result.toFixed(0) : result.toFixed(1);
    }

    // Function to handle voting
    const handleVote = (optionId: number) => {
        // Find the selected option
        const selectedOption = updatedPollData.find(option => option.optionId === optionId);

        // Check if the limit for the selected option has been reached
        if (selectedOption && selectedOption.voters.length >= limitNumber && isLimitedChoiceMode) {
            setShowLimitedChoiceMessage(true);
            return
        }
        // Update the voted property of the selected option
        const updatedData: Poll[] = PollData.map((option) =>
            option.optionId === optionId
                ? {
                    ...option,
                    voters: [
                        ...option.voters,
                        { id: option.voters.length + 1, name: FirstName || "", profile: ProfilePic || "" },
                    ],
                }
                : option
        );
        setUpdatedPollData(updatedData);
        setSelectedOption(optionId);
    };

  return (
    <PollContainer>
      <QuestionCont>
        <Typography text="Question 01" fontSize="0.875rem" color="#5DD3B3" />
        <Typography
          text="Are you enjoying work from home?"
          fontSize="1.625rem"
        />
      </QuestionCont>

      <ChoicesCont>
        {updatedPollData.map((poll: any, index: number) => (
            isLimitedChoiceMode ? (
            <LimitedChoices key={index} index={index} poll={poll} calculatedLimit={calculatedLimit} handleVote={handleVote} selectedOption={selectedOption} showLimitedChoiceMessage={showLimitedChoiceMessage} setShowLimitedChoiceMessage={setShowLimitedChoiceMessage}/>
            ) : (
            <PercentageView key={index} index={index} poll={poll} calculatedPercentage={calculatedPercentage} handleVote={handleVote} selectedOption={selectedOption}/>
            )
        ))}
      </ChoicesCont>
    </PollContainer>
  );
};

export default PollClassView;
