

import React, { useState, useEffect } from "react";
import { Arrow, Select, SelectContainer, StyledInput, OptionsContainer, Options, MainOptionContainer } from "./SpecialSelectedInput.style";
import { ReactComponent as SvgPlygonIcon } from '../../../../assets/icons/Arrow.svg';

import { InputSelectInterface } from './SpecialSelectedInputInterface';
import { LightTypography } from '../../../../elements/fonts/Fonts';
import { SvgArrowShock, SvgArrowSimple, SvgEyeIcon } from "../../../../elements/Icons";
import MenuItems from "../../../MenuItems/MenuItems";
import { menuItems } from "../../../MenuItems/MenuItemsData";

const SpecialSelectedInput =
  ({ options, value = '', text = "", placeholder = "", onChange, width, optionWidth, fontSize, border, marginLeft, padding, height, icon: IconComponent, color, zIndex, display, onHover, onMouseLeave, handleSorting, columnIndex, depthLevel}: InputSelectInterface) => {

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | null>(value);
    const [selectedIcon, setSelectedIcon] = useState<React.ReactNode>(<></>);
    const [isSpecialInputHovered, setIsSpecialInputHovered] = useState(false);

    useEffect(() => {
      setSelectedOption(value);
      const selectedOptionObject = options?.find((option) => option.name === value);
      setSelectedIcon(
        selectedOptionObject ? selectedOptionObject.icon : null
      );
    }, [value, options]);

    const handleOptionClick = (option: any) => {
      setSelectedOption(option.name);
      onChange(option.name);
      setSelectedIcon(option.icon)
      setIsOpen(false);
    };

    return (
      <StyledInput width={width} isopen={isOpen.toString()} border={border} padding={padding} height={height} background="">
 
        <LightTypography color="rgba(255, 255, 255, 0.5)" text={text} fontSize="0.875rem" />



        <SelectContainer zIndex={zIndex || 99}>

          <Select color={color || '#fff'} onClick={() => setIsOpen(!isOpen)}>
            {selectedOption ? (
              <MainOptionContainer fontSize={fontSize}>
                {IconComponent && <SvgEyeIcon />}
                {selectedIcon} {selectedOption}
              </MainOptionContainer>
            )
              :
              (
                <div>
                  <LightTypography color="gray" text={placeholder} fontSize="0.855rem" />
                </div>
              )}
            <Arrow marginLeft={marginLeft}>
              <SvgPlygonIcon />
            </Arrow>
          </Select>

          {isOpen && (
            <OptionsContainer  optionWidth={optionWidth} >
               <div style={{ display:'' }}>
                  {menuItems.map((menu, index) => {
                    const depthLevel = 0;
                    return <MenuItems items={menu} key={index} depthLevel={depthLevel} handleSorting={handleSorting} columnIndex={columnIndex} onClick={() => handleOptionClick(options)}/>;
                  })}
                </div>
              {/* {options?.map((option, index) => (
                <div key={index} 
                onClick={() => handleOptionClick(option)}
                // onMouseEnter={() => option && onHover && onHover()}
                > */}

                  {/* <Options 
                  display={display || ""}
                   index={index} 
                   lastitem={options ? options.length - 1 : 0}
                   optionIcon={option.optionIcon}
                   >
               
                    <div>
                      {option?.icon}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div>
                        {option.name}
                      </div>
                      <SvgArrowShock />

                    </div>

                  </Options> */}
                {/* </div> */}
              {/* ))} */}
            </OptionsContainer>
          )}

        </SelectContainer>
      </StyledInput>
    )
  }

export default SpecialSelectedInput;