export const PollData = [
  {
    optionId: 1,
    optionName: "Yes",
    voters: [
      {
        id: 1,
        name: "Aya",
        profile: require("../../assets/AyaInstructor.jpg"),
      },
      {
        id: 2,
        name: "Khaled",
        profile: require("../../assets/KhaledInstructor.jpg"),
      },
      {
        id: 3,
        name: "Nour",
        profile: require("../../assets/NourInstructor.jpg"),
      },
      {
        id: 4,
        name: "Mohammad",
        profile: require("../../assets/Mohammad.png"),
      },
      {
        id: 5,
        name: "Sultan",
        profile: require("../../assets/updateProfileImg.jpg"),
      },
    ],
  },
  {
    optionId: 2,
    optionName: "No",
    voters: [
      {
        id: 1,
        name: "Mohammad",
        profile: require("../../assets/Mohammad.png"),
      },
      {
        id: 2,
        name: "Sultan",
        profile: require("../../assets/updateProfileImg.jpg"),
      },
    ],
  },
//   {
//     optionId: 3,
//     optionName: "Maybe",
//     votingPercentage: 70,
//     voters: [
//       {
//         id: 1,
//         name: "Mohammad",
//         profile: require("../../assets/Mohammad.png"),
//       },
//       {
//         id: 2,
//         name: "Khaled",
//         profile: require("../../assets/KhaledInstructor.jpg"),
//       },
//       {
//         id: 3,
//         name: "Nour",
//         profile: require("../../assets/NourInstructor.jpg"),
//       },
//       {
//         id: 4,
//         name: "Sultan",
//         profile: require("../../assets/updateProfileImg.jpg"),
//       },
//       {
//         id: 5,
//         name: "Aya",
//         profile: require("../../assets/AyaInstructor.jpg"),
//       },
//     ],
//   },
];
