import React, { useState, useEffect } from "react";
import { SurveyData } from "../AnalyticsSurveyPiesTwoValues/AnalyticsSurveyPiesTwoValues";
import { AnalyticsSurveyPiesTwoValuesContainer } from "../AnalyticsSurveyPiesTwoValues/AnalyticsSurveyPiesTwoValues.style";
import PieText from "../../Charts/PieText/PieText";
import PieWithText from "../../Charts/PieWithText/PieWithText";
import MultipleChoicePieChart from "../../Charts/PieWithText/MultipleChoicePieChart";

interface Student {
  StdCode: string;
  StdName: string;
  TimeSpent?: number;
  Answered?: (string | number)[] | null | undefined;
}

interface AnalyticsSurveyPiesRatingInterface {
  data: SurveyData;
}

export interface PieChartDataItem {
  text: string;
  val: number;
  bgColor: string;
}

const AnalyticsPieChartMultipleChoice = ({
  data,
}: AnalyticsSurveyPiesRatingInterface) => {
  const ColorFill = [
    "#5DD3B3",
    "#0B8FFF",
    "#5DC5D3",
    "#D85D66",
    "#576474",
    "#FFA500",
    "#FF6347",
    "#32CD32",
    "#FF1493",
    "#FFD700",
    "#4682B4",
  ];
  const [TotalNbValueCheck, setTotalNbValueCheck] = useState<number>();
  const [PieChartData, setPieChartData] = useState<PieChartDataItem[]>([]);
  const [CellValues, setCellValues] = useState<number[]>([]); // Array to store values for each rendered cell

  useEffect(() => {
    if (data && data.students) {
      let answeredOptions: { [key: string]: number } = {};

      // Count occurrences of each answered option
      data.students.forEach((student) => {
        if (student.Answered && Array.isArray(student.Answered)) {
          student.Answered.forEach((option) => {
            if (!answeredOptions[option]) {
              answeredOptions[option] = 1;
            } else {
              answeredOptions[option]++;
            }
          });
        }
      });

      // Update PieChartData with counts and corresponding background colors
      const updatedPieChartData = Object.keys(answeredOptions).map(
        (option, index) => ({
          text: option.toString(), // Convert option to string if it's a number
          val: answeredOptions[option],
          bgColor: ColorFill[index] || "#000000", // Use ColorFill or fallback color
        })
      );

      // Add No Answer count if applicable
      const noAnswerCount = data.students.filter(
        (student) =>
          !student.Answered ||
          !Array.isArray(student.Answered) ||
          student.Answered.length === 0
      ).length;

      if (noAnswerCount > 0) {
        updatedPieChartData.push({
          text: "No Answer",
          val: noAnswerCount,
          bgColor: "#8A2BE2",
        });
      }

      setPieChartData(updatedPieChartData);

      // Set CellValues array with values for each rendered cell
      const cellValues = updatedPieChartData.map((entry) => entry.val);
      setCellValues(cellValues);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.students) {
      let totalCount = 0;
      data.students.forEach((student) => {
        if (student.Answered && Array.isArray(student.Answered)) {
          totalCount += student.Answered.length;
        }
      });
      setTotalNbValueCheck(totalCount);
    }
  }, [data]);

  return (
    <AnalyticsSurveyPiesTwoValuesContainer>
      {/* PieWithText Component */}
      <MultipleChoicePieChart
        data={PieChartData}
        value={"val"}
        getColor={(entry) => entry.bgColor as string}
        Width="65%"
        SimpleChoiceTextEquation={false}
        MultipleTotalSum={TotalNbValueCheck}
        val={CellValues} // Pass the array of values
      />

      {/* PieText Component */}
      <PieText
        LoopName={PieChartData}
        ShowMiniInfo={false}
        title={data.QuestionText}
        MinWidth="6.4rem"
        SimpleChoiceTextEquation={false}
        MultipleTotalSum={TotalNbValueCheck}
      />
    </AnalyticsSurveyPiesTwoValuesContainer>
  );
};

export default AnalyticsPieChartMultipleChoice;
