import React, { useContext, useEffect, useState } from "react";
import {
  SvgAdd,
  SvgAddNew,
  SvgDecrease,
  SvgDownload,
} from "../../elements/Icons";
import {
  ImageViewerMainDiv,
  ImageViewerTop,
  ButtonsHolder,
  SvgStylingButton,
  ImageContainer,
  StyledImage,
} from "./ImageViewer.style";
import { S3Get } from "../../services/S3Get";
import axios from "axios";
import { downloadFileFile } from "../dashboardContents/ResourcesPerModule/resourceCard/downloadFile";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import { post } from "../../utils/AxiosRequests";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
interface ImageComponentProps {
  fileUrl: string;
  fileId?: string;
}

const ImageComponent: React.FC<ImageComponentProps> = ({ fileUrl, fileId }) => {
  const { progressChanged, setProgressChange } = useContext(ClassContext);

  const { t } = useTranslation();
  const [imageSize, setImageSize] = useState<number>(30);
  const MAX_IMAGE_SIZE: number = 60;
  const MIN_IMAGE_SIZE: number = 12.5;
  const IMAGE_SIZE_STEP: number = 3;
  const [imgPath, setImagePath] = useState();
  const params = useParams();
  const classId = params.classId;
  const { user } = useAuth0();
  const { boxShadow, theme } = useRecoilValue(ColorSelector);
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const handleIncreaseSize = () => {
    setImageSize((prevSize) => {
      const newSize: number = prevSize + IMAGE_SIZE_STEP;
      return newSize <= MAX_IMAGE_SIZE ? newSize : prevSize;
    });
  };

  const handleDecreaseSize = () => {
    setImageSize((prevSize) => {
      const newSize: number = prevSize - IMAGE_SIZE_STEP;
      return newSize >= MIN_IMAGE_SIZE ? newSize : prevSize;
    });
  };

  const handleDownloadImage = () => {
    if (s3Url) {
      const link: HTMLAnchorElement = document.createElement("a");
      link.href = s3Url;
      link.download = s3Url;
      link.click();
    } else {
      console.error("imageS3Url is undefined or null");
    }
  };
  const tenantName = localStorage.getItem("tenant");
  const [s3Url, setS3Url] = useState();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const handleContextMenu = (event: React.MouseEvent<HTMLImageElement>) => {
    event.preventDefault();
  };
  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const response = await axios.get(
          `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/resource/image/${fileId}/${tenantName}`
        );
        setData(response.data.data);
        const trackingData = {
          classSourceId: classId,
          length: "",
          percentage: 100,
          refId: fileId,
          type: "Image",
          subId: subIdSplitted,
          provider: provider,
        };
        const trackinResponse = await post(
          `completionTracking/create/${tenantName}`,
          trackingData
        );
        setProgressChange(!progressChanged);
        if (response.data.data) {
          const s3Document = await S3Get(response.data.data.path);
          setS3Url(s3Document);
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchDocument();
  }, [fileId]);

  return (
    <>
      {loading ? (
        <div> {`${t("general.loading")}...`} </div>
      ) : (
        <ImageViewerMainDiv boxshadow={boxShadow}>
          <ImageViewerTop bgcolor={theme === "light" ? "#AAB1B9" : "#384450"}>
            {/* <button >Download</button> */}
            <ButtonsHolder>
              <SvgStylingButton onClick={handleIncreaseSize}>
                <SvgAdd
                  style={{
                    height: "1rem",
                    width: "1rem",
                    color: theme === "light" ? "black" : "#5dd3b3",
                  }}
                />
              </SvgStylingButton>
              <SvgStylingButton onClick={handleDecreaseSize}>
                <SvgDecrease
                  style={{
                    height: "1rem",
                    width: "1rem",
                    color: theme === "light" ? "black" : "#5dd3b3",
                  }}
                />
              </SvgStylingButton>
            </ButtonsHolder>
            {data && data.downloadable === 1 && (
              <SvgDownload
                style={{
                  height: "1rem",
                  width: "1rem",
                  cursor: "pointer",
                }}
                color={theme === "light" ? "black" : "#5dd3b3"}
                onClick={() => {
                  handleDownloadImage();
                }}
              />
            )}
          </ImageViewerTop>
          <ImageContainer>
            <StyledImage
              src={s3Url}
              alt="resource not found"
              size={imageSize}
              onContextMenu={handleContextMenu}
            />
          </ImageContainer>
        </ImageViewerMainDiv>
      )}
    </>
  );
};

export default ImageComponent;
