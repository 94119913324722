import React, { useState, useEffect } from "react";
import {
  ButtonsContainer,
  NewAnnouncementsHolder,
} from "./EditAnnouncements.style";
import { MediumTypography, Typography } from "../../../../elements/fonts/Fonts";
import ReusableInput from "../../../../elements/reusableInput/ReusableInput";
import { TextEditor } from "../../../../elements/TextEditor/TextEditor";
import ReusableSubmitButton from "../../../../elements/reusableSubmitButton/ReusableSubmitButton";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { get, patch } from "../../../../utils/AxiosRequests";
import TinyEditor from "../../../../elements/tinyEditor/TinyEditor";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { HeaderHolder } from "../newAnnouncement/NewAnnouncements.style";
import SelectInput from "../../../../elements/SelectInput/SelectInput";
import Select from "react-select";
import { colorStyles } from "../newAnnouncement/colorStyle";
import { ColorSelector } from "../../../../recoil/ThemeSelectors";
import { useRecoilValue } from "recoil";
import { FlexedDiv } from "../../../../components/attendanceAllSessions/AttendanceAllSessions.style";
import CheckboxSquare from "../../../../elements/checkboxSquare/CheckboxSquare";

const EditAnnouncements = (): JSX.Element => {
  const { id } = useParams();
  const [inputData, setInputData] = useState<string>("");
  const [work, setWork] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const { state } = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const tenantName = localStorage.getItem("tenant");
  const { t } = useTranslation();
  const { user } = useAuth0();
  const myEmail = user?.email || "";
  const params = useParams();
  const classId = params.classId;
  const [CheckBoxChecked, SetCheckBoxChecked] = useState<boolean>(false);
  const LeftOptions = [
    // { id: 'tenant', label: 'Tenant',name:"Tenant",value:'tenant' },
    { id: "users", label: "Users", name: "Users", value: "users" },
    // { id: 'role', label: 'Roles',name:"Roles",value:'role' },
    { id: "class", label: "Class", name: "Class", value: "class" },
  ];
  const [RightOptions, setRightOptions] = useState([]);
  const [rightSelectedOption, setRightSelectedOption] = useState<any[]>([]);

  // This 2 arrays are used to be sended in the data (array of string not array of object)
  const [selectedMode, setSelectedMode] = useState<any>("");
  const [rightSelectedStrings, setRightSelectedStrings] = useState<any[]>([]);
  const [AllUsers, SetAllUsers] = useState([]);
  const [modeError, setModeError] = useState("");

  // GEt the Announecement By It's ID Data
  useEffect(() => {
    const getAnnounecmentById = async () => {
      try {
        const AnnounecementToDisplay = await get(
          `announcement/${id}/${tenantName}`
        );
        setWork(AnnounecementToDisplay.data.content);
        setInputData(AnnounecementToDisplay.data.title);
        setDate(AnnounecementToDisplay.data.updated_at);
        setSelectedMode(AnnounecementToDisplay.data.targetType);
        setDataLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    getAnnounecmentById();
  }, []);

  // Set the content of the announcement
  const handleContentChange = (newWork: string) => {
    setWork(newWork);
  };

  // Set the Title of the announcement
  const handleTitleChange = (data: string) => {
    setInputData(data);
  };

  // Handle go backward navigation
  const handleBack = () => {
    navigate(-1);
  };

  const handleCheckboxChange = () => {
    SetCheckBoxChecked(!CheckBoxChecked);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const [users, classes, roles] = await Promise.all([
        //   getAllUsers(),
        //   getAllClasses(),
        //   getAllRoles(),
        // ]);
        const ParticipantData = await get(
          `participant/class/${classId}/${tenantName}`
        );
        const participantWithoutMe = ParticipantData.data.filter(
          (user: any) => user.email.toUpperCase() !== myEmail.toUpperCase()
        );
        const transformedUsers = participantWithoutMe.map(
          (user: { sourceId: any; username: any; email: string }) => ({
            value: user.sourceId,
            label: `${user.username} - ${user.sourceId}`,
          })
        );
        SetAllUsers(transformedUsers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const rightOptions: any = [];
    setRightSelectedOption([]);
    setRightSelectedStrings([]);
    if (selectedMode === "users") {
      rightOptions.push(...AllUsers);
    } else if (selectedMode === "class") {
      setRightSelectedStrings([classId]);
    }

    setRightOptions(rightOptions);
  }, [selectedMode, AllUsers]);

  const saveDataToLocalStorage = async () => {
    const dataObject = {
      id: id,
      title: inputData,
      content: work,
      targetType: selectedMode,
      targetSourceId: rightSelectedStrings,
      isEmailed: CheckBoxChecked,
    };

    if (rightSelectedStrings.length === 0 && selectedMode !== "class") {
      console.error("Please select");
      return;
    }

    setLoading(true);
    await patch(`announcement/update/${tenantName}`, dataObject);
    setLoading(false);
    setWork("");
    setInputData("");
    navigate(`/courseview/announcements/${state.some}`);
  };

  const {
    checkboxBgColor,
    checkboxBorderColor,
    checkboxBorderRadius,
    SecondaryTextColor,
    inputBorderColor,
    theme,
  } = useRecoilValue(ColorSelector);

  return (
    <NewAnnouncementsHolder>
      <Typography
        text={
          id
            ? t("course view.Edit Announcement")
            : t("course view.Announce something to your class")
        }
        fontSize="1.75rem"
      />
      <ReusableInput
        onInputChange={handleTitleChange}
        value={inputData}
        placeHolder={`${dataLoading ? `${t("general.loading")}...` : ""}`}
        inputBorderRadius="0.625rem"
      />
      <HeaderHolder>
        <SelectInput
          border={modeError ? `1px solid #D04B4B` : ""}
          width="100%"
          placeholder="Select"
          height="4.4rem"
          text="Mode"
          value={selectedMode}
          options={LeftOptions}
          onChange={(option: string | number) => setSelectedMode(option)}
        />
        <Select
          options={RightOptions}
          onChange={(selectedOptions: any) => {
            const selectedRoles = selectedOptions.map(
              (option: { value: any; label: any }) => ({
                value: option.value,
                label: option.label,
              })
            );
            setRightSelectedOption(selectedRoles);
            const selectedRoleString = selectedOptions.map(
              (option: any) => option.value
            );
            setRightSelectedStrings(selectedRoleString);
          }}
          value={rightSelectedOption}
          isMulti
          styles={colorStyles(
            "#fff",
            theme === "light" ? "#fff" : "#1F2731",
            inputBorderColor,
            theme
          )}
          placeholder="Recepients"
          className="custom-select"
        />
      </HeaderHolder>
      <TinyEditor
        onChange={handleContentChange}
        initialValue={work}
        placeholder={`${dataLoading ? `${t("general.loading")}...` : ""}`}
      />
      <FlexedDiv style={{ gap: "0.3rem", marginTop: "1rem" }}>
        <CheckboxSquare
          bgcolor={checkboxBgColor}
          bordercolor={checkboxBorderColor}
          borderradius={checkboxBorderRadius}
          checked={CheckBoxChecked}
          name="isChecked"
          onChange={handleCheckboxChange}
        />
        <MediumTypography text={t("course view.Send By Email")} />
      </FlexedDiv>
      {/* Buttons Container Goes Here */}
      <ButtonsContainer>
        <ReusableSubmitButton
          text={t("forms.Cancel")}
          width="8.8125rem"
          padding="0.56rem 2.12rem 0.5rem 2.25rem"
          onClick={handleBack}
          border="1px solid #D85D66"
          color="#D85D66"
        />
        {loading ? (
          <ReusableSubmitButton
            text={`${t("general.loading")}...`}
            width="8.8125rem"
            padding="0.56rem 2.12rem 0.5rem 2.25rem"
          />
        ) : (
          <ReusableSubmitButton
            text={id ? t("forms.Save") : t("course view.Announce")}
            width="8.8125rem"
            padding="0.56rem 2.12rem 0.5rem 2.25rem"
            onClick={saveDataToLocalStorage}
          />
        )}
      </ButtonsContainer>
    </NewAnnouncementsHolder>
  );
};

export default EditAnnouncements;
