interface ColorPickerInterface {
  color: string;
  name: string;
}

export const ColorPickerData: ColorPickerInterface[] = [
  {
    color: "#5DC5D3",
    name: "Aqua_Blue",
  },
  {
    color: "#3DA4FC",
    name: "Sky_Blue",
  },
  {
    color: "#5D69FD",
    name: "Royal_Blue",
  },
  {
    color: "#8E5DFD",
    name: "Purple",
  },
  {
    color: "#B757E2",
    name: "Violet",
  },
  {
    color: "#DB56A3",
    name: "Magenta",
  },
  {
    color: "#D04B4B",
    name: "Red",
  },
  {
    color: "#D07E4B",
    name: "Orange",
  },
  {
    color: "#D0C64B",
    name: "Yellow",
  },
  {
    color: "#58BE5B",
    name: "Green",
  },
];
