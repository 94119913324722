import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import AssignmentDetails from "./AssignmentDetails/AssignmentDetails";
import ReusableSubmitButton from "../../elements/reusableSubmitButton/ReusableSubmitButton";
import AssignmentWork from "./assignmentSubmission/AssignmentWork";
import { SelectedFileDiv } from "../dropZone/main/DropZone.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import CourseResourcesData from "../CourseTimeline/utils/CourseResourcesData";
import DOMPurify from "dompurify";
import { get } from "../../utils/AxiosRequests";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
  uuidSplitter,
} from "../../utils/StringManipulation";
import { generateUUID } from "../../utils/StringManipulation";
import { S3Upload } from "../../services/S3Put";
import { post } from "../../utils/AxiosRequests";
import { SvgCoursesIcon } from "../../elements/Icons";
import { CommentContainer } from "../../elements/StudentsQuizElements/StudentQuizCheckbox/StudentQuizCheckbox.style";
import { GradeContainer } from "./AssignmentDetails/AssignmentDetails.style";
import { useRecoilState, useRecoilValue } from "recoil";
import { ChatsColorSelector, ColorSelector } from "../../recoil/ThemeSelectors";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { WebSocketConnector } from "../../Views/messages/webSocketConnection";
import { WebsocketContext } from "../../contexts/notificationContext";
import { toast } from "react-toastify";
import { adminState } from "../../recoil/RecoilStates";
import { TransformDirection } from "../../utils/Direction";

interface assignmentProps {
  assignmentId: string;
  role: string;
}

export default function Assignment({ assignmentId, role }: assignmentProps) {
  const { progressChanged, setProgressChange } = useContext(ClassContext);
  const { SecondaryTextColor, theme } = useRecoilValue(ColorSelector);

  const [showAssignmentDetails, setShowAssignmentDetails] = useState(true);
  const [showAssignmentWork, setShowAssignmentWork] = useState(false);
  const [showEditSubmission, setShowEditSubmission] = useState(false);
  const [currentAssignmentId, setCurrentAssignmentId] = useState(assignmentId);
  const [description, setDescription] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [activity, setActivity] = useState<any>({});
  const [attachment, setAttachment] = useState<any[]>([]);
  const filePath = `assignment/submissions/${assignmentId}`;
  const [fileResponse, setFileResponse] = useState<any>(null);
  const [textFileResponse, setTextFileResponse] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [submited, setSubmited] = useState<boolean>(false);
  const [submitedAnswer, setSubmitedAnswer] = useState<string>("");
  const [submitedFile, setSubmitedFile] = useState<string>("");
  const [submitedFileName, setSubmitedFileName] = useState<string>("");
  const [submissionDate, setSubmissionDate] = useState<string>("");
  const [valid, setValid] = useState<boolean>(false);
  const [maxGrade, setMaxGrade] = useState<number>(0);
  const [graded, setGraded] = useState<boolean>(false);
  const [grade, setGrade] = useState<any>("");
  const [showGrade, setShowGrade] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [teacherSourceId, setTeacherSourceId] = useState<string>("");
  const [isAdmin] = useRecoilState(adminState);
  const [errors, setErrors] = useState({
    description: "",
    files: "",
  });
  const tenantName = localStorage.getItem("tenant");
  const { user } = useAuth0();
  const params = useParams();
  const classId = params.classId;
  const { t } = useTranslation();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const { isReady, value, send, connect } = useContext(WebsocketContext);

  useEffect(() => {
    if (
      !isReady &&
      tenantName !== null &&
      tenantName !== undefined &&
      subIdSplitted !== null &&
      subIdSplitted !== undefined
    ) {
      connect();
    }
  }, [tenantName, subIdSplitted]);

  useEffect(() => {
    if (assignmentId !== currentAssignmentId) {
      setSubmited(false);
      setLoading(false);
      setShowAssignmentDetails(true);
      setShowAssignmentWork(false);
      setShowEditSubmission(false);
      setLoadingData(true);
      setCurrentAssignmentId(assignmentId);
      setSubmitedAnswer("");
      setSelectedFiles([]);
    }

    if (activity && activity.startDateTime && activity.cutOffDateTime) {
      // Check if the assignment is valid
      const currentDate = new Date();
      const startDateTime = new Date(activity.startDateTime);
      const dueDateTime = new Date(activity.cutOffDateTime);

      const isValid =
        currentDate >= startDateTime && currentDate <= dueDateTime;
      setValid(isValid);
      // console.log("Is valid for submission: " + isValid);
    } else if (activity && activity.startDateTime) {
      const currentDate = new Date();
      const startDateTime = new Date(activity.startDateTime);
      const isValid = currentDate >= startDateTime;
      setValid(isValid);
    } else {
      setValid(true);
    }
  }, [assignmentId, activity, currentAssignmentId]);

  // This function hande a get request to Get the Assignment By It's ID Data
  const getAssignmentById = async () => {
    try {
      const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
      const provider = auth0ProviderSplitter(user?.sub || "");
      const activityToDisplay = await get(
        `assignment/get/${tenantName}/${assignmentId}/${subIdSplitted}`,
        provider
      );
      const activityPublish = await axios.get(
        `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/activity/publish/${assignmentId}/Assignment/${tenantName}`
      );
      if (
        activityPublish &&
        activityPublish.data &&
        activityPublish.data.data &&
        activityPublish.data.data.length > 0
      ) {
        setShowGrade(activityPublish.data.data[0].publish === 1 ? true : false);
      } else {
        setShowGrade(false);
      }
      setActivity(activityToDisplay.data.activity);
      setAttachment(activityToDisplay.data.attachments);
      setTeacherSourceId(
        activityToDisplay &&
          activityToDisplay.data &&
          activityToDisplay.data.activity
          ? activityToDisplay.data.activity.userSourceId
          : ""
      );
      setLoadingData(false);

      if (
        activityToDisplay &&
        activityToDisplay.data &&
        activityToDisplay.data.message &&
        activityToDisplay.data.message.startsWith(
          "Assignment already submitted"
        )
      ) {
        // Set the states when the user is Already submitted
        setSubmited(true);
        setSubmitedFile(
          activityToDisplay.data.assignmentSubmissions.submissionFile
        );
        setSubmitedFileName(
          activityToDisplay.data.assignmentSubmissions.fileName
        );
        setSubmitedAnswer(
          activityToDisplay.data.assignmentSubmissions.textAnswer
        );
        setMaxGrade(activityToDisplay.data.assignmentSubmissions.maxGrade);
        setGrade(activityToDisplay.data.assignmentSubmissions.grade);
        setComment(activityToDisplay.data.assignmentSubmissions.comment);
        const formattedDate = new Date(
          activityToDisplay.data.assignmentSubmissions.createdAt
        ).toLocaleString();
        setSubmissionDate(formattedDate);
      } else {
        setSubmited(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // call the getAssignmentById function to get the data
  useEffect(() => {
    getAssignmentById();
  }, [assignmentId]);

  // Set the Text answer of the student
  const handleDescriptionChange = (newDescription: string) => {
    setDescription(newDescription);
    // Retrieve the quizAnswers array from local storage
    let assignmentAnswers = JSON.parse(
      localStorage.getItem("AssignmentResponse") || "[]"
    );

    // Find the index of the existing timer object
    const index = assignmentAnswers.findIndex(
      (item: any) =>
        item.classSourceId === classId &&
        item.subId === subIdSplitted &&
        item.refId === assignmentId
    );

    if (index !== -1) {
      // Retrieve the existing localStudentResponse
      let existingResponse = assignmentAnswers[index].description || "";

      // Merge the new key-value pair with the existing localStudentResponse
      existingResponse = newDescription;

      // Update the existing object with the merged localStudentResponse
      assignmentAnswers[index].description = existingResponse;
    } else {
      // Add a new object if it doesn't exist
      const data = {
        subId: subIdSplitted,
        provider: provider,
        classSourceId: classId,
        refId: assignmentId,
        description: newDescription,
      };
      assignmentAnswers.push(data);
    }

    // Save the updated array back to local storage
    localStorage.setItem(
      "AssignmentResponse",
      JSON.stringify(assignmentAnswers)
    );

    // Clear the description error when the user makes changes
    setErrors((prevErrors) => ({
      ...prevErrors,
      description: "",
    }));
  };

  // Set the File answer of the student
  const handleFilesChange = async (files: File[]) => {
    if (files) {
      setLoading(true);
      setSelectedFiles(files);
      const selectedFile: File | null = files[0];
      if (selectedFile) {
        const name: string =
          selectedFile.name.split("\\").pop() || selectedFile.name;
        const fileNameUUid = `${generateUUID()}${name}`;
        const fileuplKey = `${filePath}/${fileNameUUid}`;
        try {
          const response = await S3Upload(
            filePath,
            name,
            fileNameUUid,
            selectedFile
          );
          if (response?.status === 200) {
            // Retrieve the quizAnswers array from local storage
            let assignmentAnswers = JSON.parse(
              localStorage.getItem("AssignmentResponse") || "[]"
            );

            // Find the index of the existing timer object
            const index = assignmentAnswers.findIndex(
              (item: any) =>
                item.classSourceId === classId &&
                item.subId === subIdSplitted &&
                item.refId === assignmentId
            );
            if (index !== -1) {
              // Retrieve the existing localStudentResponse
              let existingResponse = assignmentAnswers[index].file;

              // Merge the new key-value pair with the existing localStudentResponse
              existingResponse = fileuplKey;

              // Update the existing object with the merged localStudentResponse
              assignmentAnswers[index].file = existingResponse;
            } else {
              // Add a new object if it doesn't exist
              const data = {
                subId: subIdSplitted,
                provider: provider,
                classSourceId: classId,
                refId: assignmentId,
                file: fileuplKey,
              };
              assignmentAnswers.push(data);
            }

            // Save the updated array back to local storage
            localStorage.setItem(
              "AssignmentResponse",
              JSON.stringify(assignmentAnswers)
            );

            console.log("response in Assignment Submission", response.status);
            setFileResponse(fileuplKey);
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }

    // Clear the files error when the user makes changes
    setErrors((prevErrors) => ({
      ...prevErrors,
      files: "",
    }));
  };

  // This use Effect is for getting the response of the student from the local storage
  useEffect(() => {
    // Fetch and set the timer from local storage if it exists
    const assignmentAnswers = JSON.parse(
      localStorage.getItem("AssignmentResponse") || "[]"
    );
    // Find the index of the existing timer object
    const currentAssignmentAnswer = assignmentAnswers.find(
      (item: any) =>
        item.classSourceId === classId &&
        item.subId === subIdSplitted &&
        item.refId === assignmentId
    );
    console.log("Current assignment answer: ", currentAssignmentAnswer);
    if (currentAssignmentAnswer && currentAssignmentAnswer.description) {
      setDescription(currentAssignmentAnswer.description);
    } else {
      setDescription("");
    }

    if (currentAssignmentAnswer && currentAssignmentAnswer.file) {
      const fileObject: File = new File(
        [currentAssignmentAnswer.file],
        uuidSplitter(currentAssignmentAnswer.file).replace(
          /^(assignment\/submissions\/\d+\/)/,
          ""
        ),
        { type: "text/plain" }
      );
      console.log("File object ---->", fileObject);
      setSelectedFiles([fileObject]);
      setFileResponse(currentAssignmentAnswer.file);
    } else {
      setSelectedFiles([]);
      setFileResponse("");
    }
  }, []);

  function exportUserInfo(userInfo: any) {
    // Helper function to decode HTML entities
    function decodeHtml(html: string): string {
      const txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    }

    // Decode the HTML entities
    const decodedHtml = decodeHtml(userInfo);

    // Use DOMParser to parse the HTML and extract the text content
    const parser = new DOMParser();
    const doc = parser.parseFromString(decodedHtml, "text/html");
    const purifiedText = doc.body.textContent || "";

    const file = new File([purifiedText], `${activity.title}.txt`, {
      type: "text/plain",
    });

    return file;
  }

  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };

  // Handle the submission of the answer
  const handleSubmission = async () => {
    const isDescriptionRequired = activity?.answerBox === 1;
    const isFilesRequired = activity?.fileSubmission === 1;

    // Check if the description is required, and if so, validate it
    if (isDescriptionRequired && description.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        description: t(
          "assignment.Description is required for this assignment."
        ),
      }));
      return;
    }

    // Check if files are required, and if so, validate them
    if (isFilesRequired && selectedFiles.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        files: t("assignment.Files are required for this assignment."),
      }));
      return;
    }

    // If both conditions are met, proceed with submission
    const newSubmissionData = {
      subId: subIdSplitted,
      provider: provider,
      assignmentId: assignmentId,
      textAnswer: description,
      submissionFile: fileResponse || null,
      title: activity.title,
      teacherSourceId: teacherSourceId,
      tenantName: tenantName,
    };
    const trackingData = {
      classSourceId: classId,
      length: "",
      percentage: 100,
      refId: assignmentId,
      type: "Assignment",
      subId: subIdSplitted,
      provider: provider,
    };
    try {
      // Retrieve the quizAnswers array from local storage
      let assignmentAnswers = JSON.parse(
        localStorage.getItem("AssignmentResponse") || "[]"
      );

      // Find the index of the existing timer object
      const index = assignmentAnswers.findIndex(
        (item: any) =>
          item.classSourceId === classId &&
          item.subId === subIdSplitted &&
          item.refId === assignmentId
      );

      // If the object exists, remove it from the array
      if (index !== -1) {
        assignmentAnswers.splice(index, 1);
      }

      // Save the updated array back to local storage
      localStorage.setItem(
        "AssignmentResponse",
        JSON.stringify(assignmentAnswers)
      );
      setLoading(true);
      const submissionId = await post(
        `assignment/submission/create/${tenantName}`,
        newSubmissionData
      );
      const turnitinFileData = {
        id: submissionId.data.assignmentSubmissions.id,
        subId: subIdSplitted,
        provider: provider,
        assignmentId: assignmentId,
        textAnswer: description,
        type: "file",
        submissionFile: fileResponse || null,
        title: activity.title,
        teacherSourceId: teacherSourceId,
        tenantName: tenantName,
      };
      if (
        fileResponse !== null &&
        activity &&
        activity.checkPlagiarism &&
        activity.checkPlagiarism === 1
      ) {
        await post(`assignment/submission/create/turnitin`, turnitinFileData);
      }
      if (
        description !== "" &&
        activity &&
        activity.checkPlagiarism &&
        activity.checkPlagiarism === 1
      ) {
        const name = activity.title;
        const fileNameUUid = `${generateUUID()}${name}`;
        const fileuplKey = `${filePath}/${fileNameUUid}`;
        try {
          const response = await S3Upload(
            filePath,
            name,
            fileNameUUid,
            exportUserInfo(description)
          );
        } catch (error) {
          console.log(error);
        }
        const turnitinTextFileData = {
          id: submissionId.data.assignmentSubmissions.id,
          subId: subIdSplitted,
          provider: provider,
          assignmentId: assignmentId,
          textAnswer: description,
          type: "text",
          submissionFile: fileuplKey,
          title: activity.title,
          teacherSourceId: teacherSourceId,
          tenantName: tenantName,
        };
        await post(
          `assignment/submission/create/turnitin`,
          turnitinTextFileData
        );
      }
      const trackinResponse = await post(
        `completionTracking/create/${tenantName}`,
        trackingData
      );
      if (send && isReady) {
        send(
          JSON.stringify({
            action: "sendPublic",
            data: {
              tenantName: tenantName,
              classSourceId: classId,
              title: activity.title,
              activityType: "Assignment",
              refId: assignmentId,
              refTable: "AssignmentActivities",
              subId: subIdSplitted,
              provider: provider,
              type: "createSubmit",
            },
          })
        );
      }
      //console.log('Tracking Response ---->', trackinResponse)
      setLoading(false);
      setDescription("");
      setFileResponse(null);
      getAssignmentById();
      setProgressChange(!progressChanged);
    } catch (error) {
      console.log(error);
    }

    // Show the "Edit Submission" section only if both description and files are present
    if (isDescriptionRequired || isFilesRequired) {
      setShowAssignmentWork(false);
      setShowEditSubmission(true);
    }
  };

  // This is an unused function, do not delete it maybe we will need it later
  const handleUnsubmit = () => {
    const existingSubmissions: any[] =
      JSON.parse(localStorage.getItem("AssignmentSubmissions") || "[]") || [];
    const updatedSubmissions = existingSubmissions.filter(
      (submission) => submission.classId !== classId
    );
    localStorage.setItem(
      "AssignmentSubmissions",
      JSON.stringify(updatedSubmissions)
    );
    setShowAssignmentDetails(true);
    setShowAssignmentWork(false);
    setShowEditSubmission(false);
    window.location.reload();
  };

  // Show Loading When the data doesnt fetched yet
  if (loadingData) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography text={`${t("general.loading")}...`} />
      </div>
    );
  }

  return (
    <>
      {showAssignmentDetails && (
        <>
          {/* This is the details of the assignment component */}
          {activity && (
            <AssignmentDetails attachment={attachment} assignment={activity} />
          )}

          {isAdmin === false &&
            role === "student" && ( // If the user is a Student
              <div
                style={{ display: "flex", columnGap: "1rem", alignSelf: "end" }}
              >
                {
                  /* Check if there is an existing submission for the same classId */
                  submited ? ( // Show Preview Button if there is an existing submission
                    <ReusableSubmitButton
                      width="8.8rem"
                      height="2.125rem"
                      text={t("assignment.preview")}
                      onClick={() => {
                        // Show the Edit Submission content
                        setShowAssignmentDetails(false);
                        setShowEditSubmission(true);
                      }}
                    />
                  ) : valid ? ( // If there isn't an existing submission and the assignment is valid, show the start button
                    <ReusableSubmitButton
                      width="8.8rem"
                      height="2.125rem"
                      text={t("course view.Start")}
                      onClick={() => {
                        setShowAssignmentDetails(false);
                        setShowAssignmentWork(true);
                        toast.info(
                          t(
                            "course view.Your responses will be saved automatically as you enter them."
                          )
                        );
                      }}
                    />
                  ) : (
                    ""
                  )
                }
              </div>
            )}
        </>
      )}
      {showAssignmentWork && ( // if showAssignmentWork is true, show the AssignmentWork component
        <>
          {activity && ( // this component containe a text editor for the text answer, and drop box for the file answer
            <AssignmentWork
              assignment={activity}
              onDescriptionChange={handleDescriptionChange}
              onFilesChange={handleFilesChange}
              initialDescription={submitedAnswer || description}
              initialFiles={selectedFiles}
              errors={errors}
              setLoading={setLoading}
            />
          )}
          <div style={{ display: "flex", columnGap: "1rem", alignSelf: "end" }}>
            {/* <ReusableSubmitButton
              bgColor="transparent"
              border="1px solid #5DD3B3"
              color="#5DD3B3"
              width="8.8rem"
              height="2.125rem"
              text="Save"
            /> */}
            {/* Cancel button if the user doesnt want to answer */}
            <ReusableSubmitButton
              bgColor="transparent"
              border="1px solid #D85D66"
              color="#D85D66"
              width="8.8rem"
              height="2.125rem"
              text={t("forms.Cancel")}
              onClick={() => {
                setShowAssignmentDetails(true);
                setShowAssignmentWork(false);
                setDescription("");
                setSelectedFiles([]);
                // Retrieve the quizAnswers array from local storage
                let assignmentAnswers = JSON.parse(
                  localStorage.getItem("AssignmentResponse") || "[]"
                );

                // Find the index of the existing timer object
                const index = assignmentAnswers.findIndex(
                  (item: any) =>
                    item.classSourceId === classId &&
                    item.subId === subIdSplitted &&
                    item.refId === assignmentId
                );

                // If the object exists, remove it from the array
                if (index !== -1) {
                  assignmentAnswers.splice(index, 1);
                }

                // Save the updated array back to local storage
                localStorage.setItem(
                  "AssignmentResponse",
                  JSON.stringify(assignmentAnswers)
                );
              }}
            />
            {loading ? (
              <ReusableSubmitButton
                width="8.8rem"
                height="2.125rem"
                text={`${t("general.loading")}...`}
                onClick={() => { }}
              />
            ) : (
              <ReusableSubmitButton
                width="8.8rem"
                height="2.125rem"
                text={t("forum.Submit")}
                onClick={() => {
                  handleSubmission();
                }}
              />
            )}
          </div>
        </>
      )}
      {showEditSubmission && (
        <>
          <TypographyRegularDescription
            text={`${t("assignment.Submitted on")} ${submissionDate}`}
            color={SecondaryTextColor}
            fontSize="0.75rem"
          />
          {/* <div style={{ display: "flex", columnGap: "1rem", alignSelf: "end" }}>
            <ReusableSubmitButton
              bgColor="transparent"
              border="1px solid #D85D66"
              color="#D85D66"
              width="8.8rem"
              height="2.125rem"
              text="Unsubmit"
              onClick={handleUnsubmit}
            />
            <ReusableSubmitButton
              width="8.8rem"
              height="2.125rem"
              text="Edit Submission"
              onClick={() => {
                setShowAssignmentWork(true);
                setShowEditSubmission(false);
              }}
            />
          </div> */}
          <div>
            {/* The Text answer of the student Goes Here */}
            <div style={{ marginBottom: "1rem" }}>
              <LightTypography
                text={`${t("assignment.Submitted Answer")}:`}
                fontSize="1rem"
              />
            </div>
            {submitedAnswer.trim() !== "" ? ( // If there is an answer
              <p
                style={{
                  wordBreak: "break-all",
                  marginBottom: 20,
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(decodeHtml(submitedAnswer)),
                }}
              ></p>
            ) : (
              // if there isn't an answer
              <p
                style={{
                  marginBottom: 20,
                }}
              >
                {t("assignment.No Answer")}
              </p>
            )}
            {/* The file answer of the student Goes Here */}
            <div style={{ marginBottom: "1rem" }}>
              <LightTypography
                text={`${t("assignment.Submitted File")}:`}
                fontSize="1rem"
              />
            </div>
            {/* If there is a file answer */}
            {submitedFileName.trim() !== "" ? (
              <div
                style={{
                  display: "flex",
                  gap: 5,
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    columnGap: "0.62rem",
                    border: `2px solid `,
                    borderColor: `${theme === "dark" ? "#576371" : "#AAB1B950"
                      }`,
                    width: "fit-content",
                    padding: "1rem",
                    paddingRight:
                      TransformDirection() === "ltr" ? "5rem" : "1rem",
                    paddingLeft:
                      TransformDirection() === "rtl" ? "5rem" : "1rem",
                    borderRadius: "0.375rem",
                  }}
                >
                  <SvgCoursesIcon />
                  <a
                    href={submitedFile}
                    target="_blank"
                    download={submitedFile}
                  >
                    <TypographyRegularDescription
                      text={t("assignment.Attachments")}
                      fontSize="0.75rem"
                    />
                  </a>
                </div>
              </div>
            ) : (
              // if there isn't a file answer
              <div
                style={{
                  marginBottom: 20,
                }}
              >
                <LightTypography text={t("grader.No Files")} />
              </div>
            )}
          </div>
          {/* The Comment of the instructor for the answer of the student goes Here */}
          {comment && showGrade ? (
            <>
              <MediumTypography text={t("assignment.Instructor Comment")} />
              <CommentContainer
                bgcolor={
                  theme === "light" ? "#AAB1B920" : "rgba(255, 255, 255, 0.03)"
                }
              >
                <LightTypography text={comment} fontSize=".8rem" />
              </CommentContainer>
            </>
          ) : (
            ""
          )}
          {/* The Grade That the instructor put it to the student answer */}
          {grade && showGrade ? (
            <>
              <GradeContainer>
                <span style={{ color: "#5dd3b3" }}>{grade}</span> /{" "}
                {maxGrade || 100}
              </GradeContainer>
            </>
          ) : (
            ""
          )}
          <div style={{ display: "flex", columnGap: "1rem", alignSelf: "end" }}>
            <ReusableSubmitButton
              bgColor="transparent"
              border="1px solid #5dd3b3"
              color="#5dd3b3"
              width="8.8rem"
              height="2.125rem"
              text={t("assignment.close")}
              onClick={() => {
                setShowAssignmentDetails(true);
                setShowAssignmentWork(false);
                setShowEditSubmission(false);
              }}
            />
          </div>
        </>
      )}
    </>
  );
}
