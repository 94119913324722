import { CourseNotificationInterface } from "../CourseNotificationIterface";

export const CourseNotificationStdData: CourseNotificationInterface[] =[
  
    {title: "User Profile.On Platform",
     titleValue: "On Platform",
    Data:[
      {id:"wrefedcef",text:"User Profile.Notify me when an activity is about to start", value:"Notify me when an activity is about to start"},
      {id:"rfghju6ytgf",text:"User Profile.Notify me about new announcements", value:"Notify me about new announcements"},
      {id:"sfgthyju6ygtf",text:"User Profile.Notify me when new content have been added to any course", value:"Notify me when new content have been added to any course"},
      {id:"fghyuytrgfcsd",text:"User Profile.Notify me when new grades and feedback have been added", value:"Notify me when new grades and feedback have been added"},
      {id:"ertu76ytfe",text:"User Profile.Notify me when new calendar events have been added", value:"Notify me when new calendar events have been added"},
      {id:"fgthyu7ytrgf",text:"User Profile.Notify me when my grade is low or at risk", value:"Notify me when my grade is low or at risk"},
      {id:"erft5yu6y5trfedqw",text:"User Profile.Notify me when new discussion post have been added", value:"Notify me when new discussion post have been added"},
      {id:"dafg65ytrf",text:"User Profile.Notify me about new and upcoming due dates", value:"Notify me about new and upcoming due dates"},
      {id:"wdewfrgtyu7iy",text:"User Profile.Notify me about past due items", value:"Notify me about past due items"},
      {id:"wrtjkuytg",text:"User Profile.Display recent submissions of activities", value:"Display recent submissions of activities"},

    ]
  },
  {title: "User Profile.By Email",
   titleValue: "By Email",
  Data:[
    {id:"wdefrgthyui87uyt",text:"User Profile.Notify me by email about new announcements", value:"Notify me by email about new announcements"},
    {id:"thryrfedqwrt54y",text:"User Profile.Notify me by email one day prior to the commencement of any activity", value:"Notify me by email one day prior to the commencement of any activity"},
  

  ]
}
  ]


