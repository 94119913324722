import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const UpdatesContainer = styled.div<StyleThemeInterface>`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const UpdatesTitleDiv = styled.div<StyleThemeInterface>`
  display: flex;
  width: 100%;
  background: ${(props) => props.bgcolor};
  padding: 0.69rem 1.25rem;
`;
export const UpdatesRowDiv = styled.div<StyleThemeInterface>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0.7rem 0;
  align-items: center;
  &:nth-child(even) {
    background: ${(props) => props.nth_child_bgcolor};
  }
`;

export const LightTypographyWrapper = styled.div`
  &:dir(ltr) {
    padding: 0 1.25rem 0 0;
  }
  &:dir(rtl) {
    padding: 0 0 0 1.25rem;
  }
`;

export const UpdatesInfoDiv = styled.div<StyleThemeInterface>`
  display: flex;
  gap: 1.25em;
  align-items: center;
  padding: 0 1.25rem;
`;
