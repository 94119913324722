import React, { FC, useState, useEffect } from "react";
import { CustomCalendarToolbarProps } from "./CustomCalendarToolBarInterface";
import {
  CalendarToolConatiner,
  MonthYearContainer,
  ArrowToolContainer,
  CalendarButtonTool,
} from "./CustomCalendarToolBar.style";
import {
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import { ReactComponent as NextIcon } from "../../../assets/icons/Path 3521.svg";
import { ReactComponent as PrviousIcon } from "../../../assets/icons/Path 3522.svg";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import "./CustomCalendarToolBar.css";
import { useTranslation } from "react-i18next";

const CustomCalendarToolBar: FC<CustomCalendarToolbarProps> = ({
  label,
  onNavigate,
  onView,
  view: propView,
}) => {
  const [view, setView] = useState<any>(propView);
  const [todayLabel, setTodayLabel] = useState<string>("Today");
  const { themeTextColor, theme } = useRecoilValue(ColorSelector);
  const { t } = useTranslation()
  const changeView = (newView: string) => {
    switch (newView) {
      case "month":
        onView("month");
        setView("month");
        break;
      case "week":
        onView("week");
        setView("week");
        break;
      case "day":
        onView("day");
        setView("day");
        break;
      default:
        break;
    }
  };

  const formatLabel = (label: string, view: string) => {
    if (view === "week" || view === "day") {
      const currentDate = new Date();
      const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
      const currentDay = String(currentDate.getDate()).padStart(2, "0");
      return `${currentMonth}-${currentDay}`;
    } else {
      return label;
    }
  };

  useEffect(() => {
    setTodayLabel("Today"); // Use 'view' instead of 'propView'
  }, [label, view]); // Update dependency array to include 'view' instead of 'propView'

  const language = localStorage.getItem("language")

  return (
    <CalendarToolConatiner>
      <MonthYearContainer>
        <Typography text={label} fontSize="1.5rem" />
      </MonthYearContainer>
      <ArrowToolContainer color={themeTextColor}>
        <PrviousIcon
          onClick={() => onNavigate("PREV")}
          style={{ cursor: "pointer", rotate: language === "ar" ? "180deg" : "" }}
        />
        <div onClick={() => onNavigate("TODAY")} style={{ cursor: "pointer" }}>
          {view === "month" ? (
            <TypographyRegularDescription text={t("calendar.Today")} fontSize="2.375rem" />
          ) : (
            <TypographyRegularDescription
              text={todayLabel}
              fontSize="2.375rem"
              // color={theme.dark.primary_text_color}
            />
          )}
        </div>
        <NextIcon
          onClick={() => onNavigate("NEXT")}
          style={{ cursor: "pointer", rotate: language === "ar" ? "180deg" : "" }}
        />
      </ArrowToolContainer>
      <ArrowToolContainer>
        <CalendarButtonTool
          onClick={() => changeView("month")}
          view={view}
          className={
            view === "month" && theme === "light"
              ? "whiteText"
              : view !== "month" && theme === "light"
              ? "BlackText"
              : "whiteText"
          }
          style={{
            background:
              view === "month"
                ? "linear-gradient(50deg, #5dd3b3 3.83%, #2f6a5a 95.8%)"
                : "transparent",
            cursor: "pointer"
          }}
        >
          <Typography
            text={t("calendar.Month")}
            fontSize="1.125rem"
            // color={theme.dark.primary_text_color}
          />
        </CalendarButtonTool>
        <CalendarButtonTool
          onClick={() => changeView("week")}
          view={view}
          className={
            view === "week" && theme === "light"
              ? "whiteText"
              : view !== "week" && theme === "light"
              ? "BlackText"
              : "whiteText"
          }
          style={{
            background:
              view === "week"
                ? "linear-gradient(50deg, #5dd3b3 3.83%, #2f6a5a 95.8%)"
                : "transparent",
                cursor: "pointer"
          }}
        >
          <Typography
            text={t("calendar.Week")}
            fontSize="1.125rem"
            // color={theme.dark.primary_text_color}
          />
        </CalendarButtonTool>
        <CalendarButtonTool
          onClick={() => changeView("day")}
          view={view}
          className={
            view === "day" && theme === "light"
              ? "whiteText"
              : view !== "day" && theme === "light"
              ? "BlackText"
              : "whiteText"
          }
          style={{
            background:
              view === "day"
                ? "linear-gradient(50deg, #5dd3b3 3.83%, #2f6a5a 95.8%)"
                : "transparent",
                cursor: "pointer"
          }}
        >
          <Typography
            text={t("calendar.Day")}
            fontSize="1.125rem"
            // color={theme.dark.primary_text_color}
          />
        </CalendarButtonTool>
      </ArrowToolContainer>
    </CalendarToolConatiner>
  );
};

export default CustomCalendarToolBar;
