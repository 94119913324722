import React, { useEffect, useState } from "react";
import {
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  ChoicesContainer,
  ChoicesHeader,
  ChoicesInputs,
  Input,
  InputCont,
  LabelDiv,
  QuestionSelected,
  QuestionSelectedContainer,
} from "./AllQuestions.style";
import { AllQuestionsInterface } from "./AllQuestionsInterface";
import { FlexDiv } from "../../globalStyles";
import { SvgAdd, SvgDelete, SvgRefreshIcon } from "../../elements/Icons";
import { useMyContext } from "../../contexts/QuestionBanksContext";
import CheckboxSquare from "../../elements/checkboxSquare/CheckboxSquare";
import { CheckBox } from "../../elements/checkbox/CheckBox.style";
import ActionButtons from "./ActionButtons";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import {
  auth0SubIdSplitter,
  generateUUID,
  auth0ProviderSplitter
} from "../../utils/StringManipulation";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  CourseColorSelector,
} from "../../recoil/ThemeSelectors";
import { StyledInput } from "../../elements/DatePicker/input.style";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const MultipleChoice = ({ handleCancel }: AllQuestionsInterface) => {
  const { id } = useParams();
  const { user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const { savedQuestions, setSavedQuestions, questionToUpdate, updateMode } =
    useMyContext();
  const { t } = useTranslation();
  const feedbackRight = "quiz.Your Answer is correct."
  const feedbackWrong = "quiz.Your Answer is incorrect."
  const [isLoading, setIsLoading] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [feedbackRightError, setFeedbackRightError] = useState(false);
  const [feedbackWrongError, setFeedbackWrongError] = useState(false);
  const [choicesErrors, setChoicesErrors] = useState<boolean[]>([]);
  const [percentagesErrors, setPercentagesErrors] = useState<boolean[]>([]);
  const [totalPercentageError, setTotalPercentageError] = useState(false);
  const [prevData, setPrevData] = useState<any>();
  const [multipleChoiceData, setMultiplechoiceData] = useState({
    questionText: "",
    choices: [
      { isTrue: true, choice: "", percentage: "" },
      { isTrue: false, choice: "", percentage: "" },
    ],
    isShuffled: false,
    feedbackRight: t(feedbackRight),
    feedbackWrong: t(feedbackWrong),
  });

  // Set initial state based on updateMode and questionToUpdate
  useEffect(() => {
    if (updateMode && questionToUpdate && questionToUpdate.id) {
      setMultiplechoiceData({
        questionText: questionToUpdate.question,
        choices: questionToUpdate.choices,
        isShuffled: questionToUpdate.isShuffled,
        feedbackRight: questionToUpdate.feedbackRight,
        feedbackWrong: questionToUpdate.feedbackWrong,
      });
    }
  }, [updateMode, questionToUpdate]);

  const handleQuestionChange = (value: string) => {
    setMultiplechoiceData((prevState) => ({
      ...prevState,
      questionText: value,
    }));
    setDescriptionError(value.trim() === ""); // Check if the question is empty
  };

  const handleFeedbackRightChange = (value: string) => {
    setMultiplechoiceData((prevState) => ({
      ...prevState,
      feedbackRight: value,
    }));
    setFeedbackRightError(value.trim() === "");
  };

  const handleFeedbackWrongChange = (value: string) => {
    setMultiplechoiceData((prevState) => ({
      ...prevState,
      feedbackWrong: value,
    }));
    setFeedbackWrongError(value.trim() === "");
  };

  const handleShuffleChange = () => {
    // Toggle the shuffle state when the shuffle icon is clicked
    setMultiplechoiceData((prevState) => ({
      ...prevState,
      isShuffled: !multipleChoiceData.isShuffled,
    }));
  };

  const addChoice = () => {
    // Add a new choice when the "Add Choice" button is clicked
    setMultiplechoiceData((prevState) => ({
      ...prevState,
      choices: [
        ...multipleChoiceData.choices,
        { isTrue: false, choice: "", percentage: "" },
      ],
    }));
    // Add a new entry to the choicesErrors array to track error state for the new choice
    setChoicesErrors([...choicesErrors, false]);
  };

  const removeChoice = (index: number) => {
    // Remove the choice at the specified index when the delete icon is clicked
    const newChoices = [...multipleChoiceData.choices];
    newChoices.splice(index, 1);
    // Update the choices array in the state
    setMultiplechoiceData((prevState) => ({
      ...prevState,
      choices: newChoices,
    }));
    // Remove the corresponding entry from the choicesErrors array to maintain consistency
    const newChoicesErrors = [...choicesErrors];
    newChoicesErrors.splice(index, 1);
    // Update the choicesErrors array in the state
    setChoicesErrors(newChoicesErrors);
  };

  const handleChoiceChange = (value: string, index: number) => {
    // Update the choice value when the user types in the input field for a choice
    const newChoices = [...multipleChoiceData.choices];
    newChoices[index].choice = value;
    // Update the choices array in the state
    setMultiplechoiceData((prevState) => ({
      ...prevState,
      choices: newChoices,
    }));
    // Update the error state for the corresponding choice based on whether it's empty
    const newChoicesErrors = [...choicesErrors];
    newChoicesErrors[index] = value.trim() === "";
    // Update the choicesErrors array in the state
    setChoicesErrors(newChoicesErrors);
  };

  const handleCheckboxChange = (isChecked: boolean, index: number) => {
    // Update the 'isTrue' property of the choice when the checkbox is checked or unchecked
    const newChoices = [...multipleChoiceData.choices];
    newChoices[index].isTrue = isChecked;
    // Update the choices array in the state
    setMultiplechoiceData((prevState) => ({
      ...prevState,
      choices: newChoices,
    }));
  };
  const handlePercentageChange = (value: string, index: number) => {
    // Update the 'isTrue' property of the choice when the checkbox is checked or unchecked
    const newChoices = [...multipleChoiceData.choices];
    newChoices[index].percentage = value;
    // Update the choices array in the state
    setMultiplechoiceData((prevState) => ({
      ...prevState,
      choices: newChoices,
    }));
    // Update the error state for the corresponding choice based on whether it's empty
    const newPercentagesErrors = [...percentagesErrors];
    newPercentagesErrors[index] = value.trim() === "";
    // Update the choicesErrors array in the state
    setPercentagesErrors(newPercentagesErrors);
  };

  const handleSave = async () => {
    const { questionText, choices, isShuffled, feedbackRight, feedbackWrong } =
      multipleChoiceData;

    // Check if the question is empty
    setDescriptionError(questionText.trim() === "");
    setFeedbackRightError(feedbackRight.trim() === "");
    setFeedbackWrongError(feedbackWrong.trim() === "");

    // Check if any choice is empty
    const newChoicesErrors = choices.map(
      (choice) => choice.choice.trim() === ""
    );
    setChoicesErrors(newChoicesErrors);

    // Check if any percentage is empty
    const newPercentagesErrors = choices.map(
      (choice) => choice.percentage.trim() === ""
    );
    setPercentagesErrors(newPercentagesErrors);

    // Check if total percentage equals 100
    const totalPercentage = choices.reduce((total, choice) => {
      const percentage = parseFloat(choice.percentage);
      return total + (isNaN(percentage) ? 0 : percentage);
    }, 0);
    const percentageError = totalPercentage !== 100;
    setTotalPercentageError(percentageError);

    // If either the question or any choice is empty, return early without saving
    if (
      questionText.trim() === "" ||
      feedbackRight.trim() === "" ||
      feedbackWrong.trim() === "" ||
      newChoicesErrors.some((error) => error) ||
      newPercentagesErrors.some((error) => error) ||
      percentageError
    ) {
      return;
    }

    setIsLoading(true);
    const newMultipleChoiceQuestion = {
      id: generateUUID(),
      classSourceId: id,
      subId: subId,
      provider:provider,
      contentType: "MCQ",
      question: questionText,
      choices: choices,
      isShuffled: isShuffled,
      feedbackRight: feedbackRight,
      feedbackWrong: feedbackWrong,
    };
    try {
      const response = await axios.post(
        `https://0ddea1gj55.execute-api.eu-west-1.amazonaws.com/dev/question/bank/create/${tenantName}`,
        {
          data: newMultipleChoiceQuestion,
        }
      );
      setIsLoading(false);
      handleCancel();
    } catch (error) {
      console.log(error);
    }
    // }
  };
  const { ClassGradientBox } = useRecoilValue(CourseColorSelector);
  const { themeTextColor, bghelp, RadioButtonGradientColor } =
    useRecoilValue(ColorSelector);

  return (
    <QuestionSelectedContainer>
      <QuestionSelected style={{ background: ClassGradientBox }}>
        <Typography text={t("quiz.MULTIPLE CHOICE")} fontSize="1.25rem" />

        {/* text editor part */}
        <LabelDiv>
          <Typography text={t("question bank.Question Text")} fontSize="1rem" />
          <TinyEditor
            hasError={descriptionError}
            initialValue={multipleChoiceData.questionText}
            onChange={handleQuestionChange}
            placeholder={t("question bank.Type your question")}
            height="17.5625rem"
          />
        </LabelDiv>

        {/* Choices Section */}
        <ChoicesContainer>
          <ChoicesHeader>
            <MediumTypography
              text={`${t("quiz.Choices")}*`}
              fontSize="0.875rem"
            />
            <FlexDiv gap="0.62rem" pointer="true">
              <SvgRefreshIcon
                onClick={handleShuffleChange}
                color={multipleChoiceData.isShuffled ? "#5DD3B3" : "#fff"}
              />
              <FlexDiv gap="0.62rem" onClick={addChoice} pointer="true">
                <SvgAdd color="#5DD3B3" />
                <Typography
                  text={t("quiz.Add Choices")}
                  fontSize="0.75rem"
                  color="#5DD3B3"
                />
              </FlexDiv>
            </FlexDiv>
          </ChoicesHeader>

          <ChoicesInputs>
            {multipleChoiceData.choices.map((choice, index) => (
              <InputCont key={index}>
                {multipleChoiceData.choices.length === 2 ? (
                  <CheckBox
                    type="radio"
                    name="isTrue"
                    value=""
                    onChange={(e) =>
                      handleCheckboxChange(e.target.checked, index)
                    }
                    checked={choice.isTrue}
                    bgcolor={RadioButtonGradientColor}
                  />
                ) : (
                  <CheckboxSquare
                    name="checkbox"
                    onChange={(e) =>
                      handleCheckboxChange(e.target.checked, index)
                    }
                    value=""
                    checked={choice.isTrue}
                  />
                )}
                <Input
                  borderColor={bghelp}
                  color={themeTextColor}
                  hasError={choicesErrors[index]}
                  width="100%"
                  placeholder={t("question bank.Enter Your Choice")}
                  value={choice.choice}
                  onChange={(e) => handleChoiceChange(e.target.value, index)}
                />
                <Input
                  borderColor={bghelp}
                  color={themeTextColor}
                  hasError={percentagesErrors[index] || totalPercentageError}
                  width="5rem"
                  type="number"
                  placeholder="%"
                  value={choice.percentage}
                  onChange={(e) =>
                    handlePercentageChange(e.target.value, index)
                  }
                />
                {multipleChoiceData.choices.length > 2 && (
                  <SvgDelete
                    onClick={() => removeChoice(index)}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </InputCont>
            ))}
          </ChoicesInputs>
        </ChoicesContainer>

        <InputCont>
          <LabelDiv style={{ width: "100%" }}>
            <TypographyRegularDescription
              text={t("quiz.Feedback if right")}
              fontSize="0.875rem"
            />
            <Input
              borderColor={bghelp}
              color={themeTextColor}
              hasError={feedbackRightError}
              value={multipleChoiceData.feedbackRight}
              onChange={(e) => handleFeedbackRightChange(e.target.value)}
              type="text"
              placeholder={t("quiz.Feedback if right")}
              width="100%"
            />
          </LabelDiv>
          <LabelDiv style={{ width: "100%" }}>
            <TypographyRegularDescription
              text={t("quiz.Feedback if wrong")}
              fontSize="0.875rem"
            />
            <Input
              borderColor={bghelp}
              color={themeTextColor}
              hasError={feedbackWrongError}
              value={multipleChoiceData.feedbackWrong}
              onChange={(e) => handleFeedbackWrongChange(e.target.value)}
              type="text"
              placeholder={t("quiz.Feedback if wrong")}
              width="100%"
            />
          </LabelDiv>
        </InputCont>

        {(choicesErrors.some((error) => error) ||
          descriptionError ||
          feedbackRightError ||
          feedbackWrongError ||
          percentagesErrors.some((error) => error)) && (
          <ErrorDiv>{t("quiz.This field is mandatory")}</ErrorDiv>
        )}
        {!choicesErrors.some((error) => error) &&
          !descriptionError &&
          !percentagesErrors.some((error) => error) &&
          totalPercentageError && (
            <ErrorDiv>
              {t("quiz.Total percentage of choices should be 100.")}
            </ErrorDiv>
          )}
      </QuestionSelected>

      {/* action buttons component part*/}
      <ActionButtons
        handleSave={handleSave}
        handleCancel={handleCancel}
        isLoading={isLoading}
      />
    </QuestionSelectedContainer>
  );
};

export default MultipleChoice;
