import React from "react";
import {
  AnalyticsGenerationText,
  AnalyticsGenerationTextContainer,
} from "./AnalyticsGenerationText.style";
import { Typography } from "../fonts/Fonts";
import { SvgGradesStar } from "../Icons";

interface AnalyticsGenerationTextProps {
  title?: string;
  text?: string;
  icon?: any;
}

const AnalyticsGenerationPage: React.FC<AnalyticsGenerationTextProps> = ({
  title,
  text,
  icon,
}) => {
  return (
    <AnalyticsGenerationTextContainer>
      <Typography text={title || "I Generate"} fontSize="1.5rem" />
      <AnalyticsGenerationText>
        <Typography text={text || "Course Content"} fontSize="1.5rem" />
        {icon ? icon : <SvgGradesStar />}
      </AnalyticsGenerationText>
    </AnalyticsGenerationTextContainer>
  );
};

export default AnalyticsGenerationPage;
