import styled from 'styled-components'

export const GradePointsContainer = styled.div`
display: flex;
gap:0.62rem;
align-items:center;
padding:0.38rem 0 0.56rem 2.88rem;
`;

export const PointsGradeLine = styled.div`
width: 100%;
min-height: 0.0625rem;
background: #576474;
`;