import styled from "styled-components";

export const ResourceTitle = styled.div<{ color?: string }>`
display: flex;
align-items: center;
column-gap: 0.56rem;
/* color: ${(props) => props.color}; */
&:hover {
    color: ${(props) => props.color};

    & > div {
      color: ${(props) => props.color};
    }
  }
.selectedResource {
  color: ${(props) => props.color};
}
`;