import styled from 'styled-components'

export const DetailsContainer=styled.div`
margin-top:2.5rem;
display: flex;
align-items:center;
gap:0.62rem;
`;

export const Enrollement=styled.div`
width: fit-content;
height: fit-content;
padding: .5rem;
border-radius: 1.25rem;
background: #576371;
display: flex;
justify-content:center;
align-items:center;
z-index:5;
font-family:'Primary Font-Bold';
font-size: 0.875rem;
cursor: pointer;
`;

export const DetailsLine=styled.div`
position:absolute;
width: 96.5%;
height: 0.0625rem;
background: #576371;

`;
export const Class=styled.div`
display: flex;
justify-content:center;
align-items:center;
z-index:5;
font-family:'Primary Font-Bold';
font-size: 0.875rem;
width: fit-content;
height: fit-content;
padding: .5rem;
border-radius: 1.25rem;
background: var(--Yellow-Gradient, linear-gradient(90deg, #5A6A2F 0%, #CED35D 100%));
cursor: pointer;
`;

export const Subject=styled.div`
display: flex;
justify-content:center;
align-items:center;
z-index:5;
font-family:'Primary Font-Bold';
font-size: 0.875rem;
width: fit-content;
height: fit-content;
padding: .5rem;
border-radius: 1.25rem;
background: linear-gradient(270deg, #5DD3B3 0.01%, #2F6A5A 99.99%);
cursor: pointer;
`;
export const OSA=styled.div`
display: flex;
justify-content:center;
align-items:center;
z-index:5;
font-family:'Primary Font-Bold';
font-size: 0.875rem;
width: fit-content;
height: fit-content;
padding: .5rem;
border-radius: 1.25rem;
background: linear-gradient(270deg, #5D86D3 0%, #2F366A 100%);
cursor: pointer;
`;
export const Cancel=styled.div`
display: flex;
justify-content:center;
align-items:center;
z-index:5;
font-family:'Primary Font-Bold';
font-size: 0.875rem;
width: 4.6875rem;
height: 1.875rem;
border-radius: 1.25rem;
background: linear-gradient(270deg, red 0%, #730202 100%);
cursor: pointer;
`;
