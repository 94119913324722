import React, { useState, useEffect } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import {
  Arrow,
  Select,
  SelectContainer,
  StyledInput,
  OptionsContainer,
  Options,
  MainOptionContainer,
} from "./SelectInput.style";
import { ReactComponent as SvgPlygonIcon } from "../../assets/icons/Arrow.svg";

import { InputSelectInterface } from "./SelectInputInterface";
import { LightTypography } from "../fonts/Fonts";
import { SvgArrowShock, SvgArrowSimple, SvgEyeIcon } from "../Icons";
import { FlexDiv } from "../../globalStyles";
import { useRecoilValue } from "recoil";
import { ChatsColorSelector, ColorSelector } from "../../recoil/ThemeSelectors";

const SelectInput = ({
  options,
  value = "",
  text = "",
  placeholder = "",
  onChange,
  width,
  optionWidth,
  fontSize,
  border,
  marginLeft,
  marginRight,
  padding,
  height,
  icon: IconComponent,
  color,
  zIndex,
  display,
  fontFamily,
  paddingOption,
  withPercentage = false,
}: InputSelectInterface) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(value);
  const [selectedOptionName, setSelectedOptionName] = useState<string | null>(
    value
  );
  const [selectedIcon, setSelectedIcon] = useState<React.ReactNode>(<></>);

  useEffect(() => {
    setSelectedOption(value);
    const selectedOptionObject = options?.find(
      (option) => option.value === value
    );
    setSelectedOptionName(selectedOptionObject?.name || null);
    setSelectedIcon(selectedOptionObject ? selectedOptionObject.icon : null);
  }, [value, options]);

  const handleOptionClick = (option: any) => {
    setSelectedOption(option.value);
    setSelectedOptionName(option.name);
    onChange(option.value);
    setSelectedIcon(option.icon);
    setIsOpen(false);
  };

  const containerRef = useClickOutside(isOpen, () => setIsOpen(false));
  const {
    themeTextColor,
    Third_TextColor,
    OpacitiesTextColor,
    bg_selectColor,
    theme,
    bghelp,
    SecondaryTextColor,
  } = useRecoilValue(ColorSelector);
  const { InfoSelectBorderColor } = useRecoilValue(ChatsColorSelector);
  return (
    <StyledInput
      ref={containerRef}
      width={width}
      isopen={isOpen.toString()}
      border={border ? border : `1px solid ${bghelp}`}
      padding={padding}
      height={height}
      style={{ borderBottom: isOpen ? "none" : `1px solid ${bghelp}` }}
    >
      <LightTypography
        color={SecondaryTextColor}
        text={text}
        fontSize="0.875rem"
      />

      <SelectContainer
        color={themeTextColor}
        zIndex={zIndex || 0}
        isOpen={isOpen}
      >
        <Select
          color={color || themeTextColor}
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedOption ? (
            <MainOptionContainer fontSize={fontSize}>
              {IconComponent && <SvgEyeIcon />}
              {selectedIcon} {selectedOptionName}
            </MainOptionContainer>
          ) : (
            <div>
              <LightTypography
                color={OpacitiesTextColor}
                text={placeholder}
                fontSize={fontSize || "0.855rem"}
              />
            </div>
          )}
          <FlexDiv gap="1.25rem">
            {withPercentage && (
              <LightTypography
                text="/ 100"
                color={OpacitiesTextColor}
                fontSize={fontSize || "0.855rem"}
              />
            )}
            <Arrow
              marginLeft={marginLeft}
              marginRight={marginRight}
              style={{
                transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease-in-out",
                color: themeTextColor,
              }}
            >
              <SvgPlygonIcon style={{ marginTop: isOpen ? "0" : "-1rem" }} />
            </Arrow>
          </FlexDiv>
        </Select>

        {isOpen && (
          <OptionsContainer
            style={{
              background: bg_selectColor,
              border: `1px solid ${bghelp}`,
              borderTop: "none",
            }}
            optionWidth={optionWidth}
          >
            {options?.map((option, index) => (
              <div key={index} onClick={() => handleOptionClick(option)}>
                <Options
                  padding={paddingOption}
                  fontFamily={fontFamily || "Primary Font-Bold "}
                  display={display || ""}
                  index={index}
                  lastitem={options.length - 1}
                  fontSize={fontSize}
                >
                  <div>{option?.icon}</div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>{option.name}</div>
                  </div>
                </Options>
              </div>
            ))}
          </OptionsContainer>
        )}
      </SelectContainer>
    </StyledInput>
  );
};

export default SelectInput;
