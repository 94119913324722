import React, { useState } from 'react';
import { LightTypography, Typography, TypographyRegularDescription } from '../../elements/fonts/Fonts';
import { scormData } from './ScormViewerData';
import { CommentTextAreaContainer, CommentTextArea, PostBtn, ProfileImg, ScormContainer, ScormFile, ScormIframe, CommentSection, MaskGroup, ProfileHolder, Absolutediv, CommentContainers, ActionDiv, Actions } from './ScormViewer.style';
import { FlexedDiv } from './ScormViewer.style';

const ScormViewer = () => {
  const [commentText, setCommentText] = useState('');
  const [claps, setClaps] = useState(new Array(scormData.comments.length).fill(0));

  const handleInputChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setCommentText(event.target.value);
  };

  const handlePostClick = () => {
    // Here you can implement logic to post the comment
  };

  const toggleClaps = (index: number) => {
    const newClaps = [...claps];
    newClaps[index] = newClaps[index] === 0 ? 1 : 0;
    setClaps(newClaps);
  };

  const handleReplyClick = (userId: string) => {
  };

  const handleReportClick = (userId: string) => {
  };

  const handleViewRepliesClick = (userId: string) => {
  };

  return (
    <>
      <TypographyRegularDescription text={scormData.moduleTitle} color='#5DD3B3' fontSize='0.825rem'/>
      <TypographyRegularDescription text={scormData.title} fontSize='0.625rem' />
      <LightTypography text={scormData.description} fontSize='0.625rem'/>
      <ScormContainer>
        <ScormFile>
          <Typography text="Scorm.zip" fontSize='0.625rem'/>
        </ScormFile>
        <ScormIframe src='src="https://scormbuckets.s3.amazonaws.com/New+folder+(3)/index.html' width='80%' height='400px' />
      </ScormContainer>
      <TypographyRegularDescription text="Comments" fontSize='0.825rem' />
      <FlexedDiv>
        <CommentTextAreaContainer>
          <ProfileHolder style={{ width: '30px', height: '30px' }}>
            <MaskGroup style={{ width: '30px', height: '30px' }} alt="Profile Picture" src={scormData.comments[0].profileImage} />
          </ProfileHolder>
          <CommentTextArea onChange={handleInputChange} placeholder="Comment" />
          <PostBtn onClick={handlePostClick}>
            <LightTypography text='Post' color='rgba(93, 211, 179, 1)' fontSize='0.625rem' />
          </PostBtn>
        </CommentTextAreaContainer>
      </FlexedDiv>
      <CommentSection>
        {scormData.comments.map((comment, index) => (
          <CommentContainers key={index}>
            <ProfileHolder>
              <MaskGroup alt="Profile Picture" src={comment.profileImage} />
            </ProfileHolder>
            <div>
              <Typography text={comment.userId} fontSize='0.625rem'/>
              <LightTypography text={comment.content} fontSize='0.625rem'/>
              <ActionDiv>
                <Actions onClick={() => toggleClaps(index)}> <LightTypography text={`${claps[index]} claps`} color='rgba(255, 255, 255, 0.5)' fontSize='0.525rem'  /></Actions>
                <Actions onClick={() => handleReplyClick(comment.userId)}><LightTypography text='Reply' color='rgba(255, 255, 255, 0.5)' fontSize='0.525rem'  /></Actions>  
                <Actions  onClick={() => handleViewRepliesClick(comment.userId)}><LightTypography text='View Replies' color='rgba(255, 255, 255, 0.5)' fontSize='0.525rem' /></Actions> 
                <Actions onClick={() => handleReportClick(comment.userId)}> <LightTypography text='Report' color='rgba(255, 255, 255, 0.5)' fontSize='0.525rem'  /></Actions>
              </ActionDiv>
            </div>
          </CommentContainers>
        ))}
      </CommentSection>
    </>
  );
};

export default ScormViewer;
