import React from "react";
import {
  ASIDSessionTable,
  ASIDTableBigHeaderRow,
  ASIDSessionTableObject,
  ASIDTableCol,
  ASIDTableHeaderRow,
  ASIDTableBigCol,
  ASIDbBodyRow,
  ASIDbBodyText,
} from "./AttendanceStudentID.style";
import {
  LightTypography,
  MediumTypography,
} from "../../../elements/fonts/Fonts";
import { GradeBookPopupData } from "./AttendanceStudentIData";

// Update the component to access sessions property on an element of the array
const ASIDSessionTableCompo = () => {
  return (
    <ASIDSessionTable>
      <ASIDTableBigHeaderRow>
        {GradeBookPopupData.length > 0 &&
          GradeBookPopupData[0].sessions &&
          GradeBookPopupData[0].sessions.map((session, index) => (
            <ASIDSessionTableObject key={session.sessionId}>
              <ASIDTableBigCol>
                <ASIDTableCol
                  style={{
                    borderLeft: index === 0 ? "none" : "1px solid #AAB1B9",
                    // background: index === 0 ? "red" : "aqua",
                  }}
                >
                  <MediumTypography
                    text={session.sessionName || ""}
                    fontSize="1.125rem"
                    lineHeight="1.75rem"
                  />

                  <ASIDTableHeaderRow>
                    <LightTypography
                      text={session.sessionDate || ""}
                      fontSize="0.75rem"
                    />
                    <LightTypography
                      text={
                        `${session.sessionStartTime} - ${session.sessionEndTime}` ||
                        ""
                      }
                      fontSize="0.75rem"
                    />
                  </ASIDTableHeaderRow>
                </ASIDTableCol>
              </ASIDTableBigCol>
            </ASIDSessionTableObject>
          ))}
      </ASIDTableBigHeaderRow>
      <ASIDbBodyRow>
        {GradeBookPopupData.length > 0 &&
          GradeBookPopupData[0].sessions &&
          GradeBookPopupData[0].sessions.map((session) => (
            <ASIDbBodyText key={session.sessionId}>
              <LightTypography
                text={session.sessionText || ""}
                fontSize="0.75rem"
              />
            </ASIDbBodyText>
          ))}
      </ASIDbBodyRow>
    </ASIDSessionTable>
  );
};

export default ASIDSessionTableCompo;
