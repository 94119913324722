import React from "react";
import {
  SvgDashboardIconActive,
  SvgContentIcon,
} from "../../../elements/Icons";
import ActiveIcon from "../../../elements/activeDashboardIcon/ActiveIcon";
import { ButtonStyle, IconHolder } from "./CourseCard.style";
import { useRecoilValue } from "recoil";
import { IconsColorSelector } from "../../../recoil/ThemeSelectors";
interface IconCardHolderProps {
  isOpen: boolean;
  toggleCardClick: () => void;
  SvgIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  IconText: string;
}

export default function IconCardHolder({
  isOpen,
  toggleCardClick,
  SvgIcon,
  IconText,
}: IconCardHolderProps) {
  const {
    iconcolor,
    backgroundIconColor,
    iconHoveredcolor,
    backgroundIconHoveredColor,
  } = useRecoilValue(IconsColorSelector);
  return (
    <ButtonStyle onClick={toggleCardClick}>
      {isOpen ? (
        <ActiveIcon iconActive={SvgDashboardIconActive} text={IconText} />
      ) : (
        <IconHolder
          iconcolor={iconcolor}
          backgroundcolor={backgroundIconColor}
          iconhoveredcolor={iconHoveredcolor}
          backgroundhoveredcolor={backgroundIconHoveredColor}
        >
          {" "}
          <SvgIcon width={"1.3rem"} />{" "}
        </IconHolder>
      )}
    </ButtonStyle>
  );
}
