import React, { useState } from "react";

import { NotifcationSting } from "../../../components/ProfileCheckBoxOptions/NotifcationSting";
import MenuItems from "../../../components/ProfileMenueSetting/MenuItems";
import { ProfilesMainRow } from "../ProfileShared.style";
import { ProfilePageHeader } from "../ProfilePageHeader/ProfilePageHeader";
import CompetencyPopUp from "../../../components/Competency/CompetencyPopUp/CompetencyPopUp";
import PopUp from "../../../elements/Popup/PopUp";
import { SvgVectorBrain } from "../../../elements/Icons";
import CompetencyStudentFrameworks from "../../../components/Competency/CompetencyStudentFrameworks/CompetencyStudentFrameworks";

export const NotificationSetting = (): JSX.Element => {
  const [CompetencyName, setCompetencyName] = useState("");
  const [ErrorCompetencyName, setErrorCompetencyName] = useState("");
  const [Shows, setShows] = useState(true);

  const [description, setDescription] = useState("");
  const [StudentVisibility, setStudentVisibility] = useState(true);
  const [activityForms, setActivityForms] = useState([
    {
      ActivityNames: "",
      RoleValue: "",
      PoorValue: "",
      IntermediateValue: "",
      AdvancedValue: "",
    },
  ]);

  const handleSaveData = () => {
    if (!CompetencyName || CompetencyName.trim() === "") {
      setErrorCompetencyName &&
        setErrorCompetencyName("Competency Name is required ");
      return;
    }

    const DataSaved: any = {
      CompetencyName: CompetencyName,
      Description: description,
      activityForms: activityForms,
      StudentVisibility: StudentVisibility,
    };
  };
  return (
    <>
      {/* <ProfilesMainRow>
        <MenuItems />
        <NotifcationSting />
      </ProfilesMainRow> */}

      {/* {Shows && (
        <PopUp
          content={
            <CompetencyPopUp
              CompetencyName={CompetencyName}
              setCompetencyName={setCompetencyName}
              description={description}
              setDescription={setDescription}
              ErrorCompetencyName={ErrorCompetencyName}
              setErrorCompetencyName={setErrorCompetencyName}
              activityForms={activityForms}
              setActivityForms={setActivityForms}
              StudentVisibility={StudentVisibility}
              setStudentVisibility={setStudentVisibility}
            />
          }
          icon={<SvgVectorBrain />}
          title="Add Competency"
          showline={false}
          show={Shows}
          setShow={setShows}
          justifyContent="flex-end"
          handleSubmit={handleSaveData}
        />
      )} */}

      <CompetencyStudentFrameworks />
    </>
  );
};
