import React from "react";
import { MediumTypography } from "../../elements/fonts/Fonts";
import { theme } from "../../theme";
import { SvgCheck } from "../../elements/Icons";

const ProgressBar = ({ progress }: any) => {
  // Calculate the circumference of the circle
  const radius = 17;
  const circumference = 2 * Math.PI * radius;
  const strokeWidth = 5;
  // Calculate the strokeDashoffset based on progress, considering stroke width
  let strokeDashoffset =
    (circumference * (100 - progress)) / 100 + strokeWidth / 2;
  // Adjust strokeDashoffset based on specific progress ranges
  if (progress < 3) strokeDashoffset -= 1;
  else if (progress > 70 && progress < 80) strokeDashoffset += 1;
  else if (progress > 80 && progress < 85) strokeDashoffset += 5;
  else if (progress > 85 && progress < 88) strokeDashoffset += 7;
  else if (progress > 88 && progress < 93) strokeDashoffset += 10;
  else if (progress > 93 && progress < 94) strokeDashoffset += 12;
  else if (progress > 94) strokeDashoffset += 15;

  return (
    <div>
      {progress !== 100 ? (
        <svg
          width={radius * 2}
          height={radius * 2}
          viewBox={`0 0 ${radius * 2} ${radius * 2}`}
          style={{ transform: "rotate(-90deg)" }} // Rotate to start from the top
        >
          <circle
            cx={radius}
            cy={radius}
            r={radius - strokeWidth / 2} // Adjust for stroke width
            fill="transparent"
            stroke="#eee"
            strokeWidth="4"
          />
          <circle
            cx={radius}
            cy={radius}
            r={radius - strokeWidth / 2} // Adjust for stroke width
            fill="transparent"
            stroke="#5DD3B3"
            strokeWidth="4"
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            strokeLinecap="round"
          />
          {/* Text element for displaying progress percentage */}
          <text
            x={radius}
            y={radius + 1} // Adjust position based on font size
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize="0.5rem"
            fill="white"
            fontFamily={theme.font.primaryRegular}
            transform={`rotate(90 ${radius} ${radius})`}
          >
            {`${Math.floor(progress)}%`}
          </text>
        </svg>
      ) : (
        <svg
          width="35"
          height="35"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="check_circle_outline-24px">
            <path
              id="Path 3420"
              d="M8.00039 1.3335C6.6819 1.3335 5.39302 1.72447 4.29673 2.45699C3.20045 3.1895 2.346 4.23065 1.84144 5.44878C1.33687 6.6669 1.20486 8.00729 1.46208 9.30045C1.7193 10.5936 2.35422 11.7814 3.28653 12.7138C4.21884 13.6461 5.40668 14.281 6.69984 14.5382C7.99299 14.7954 9.33338 14.6634 10.5515 14.1588C11.7696 13.6543 12.8108 12.7998 13.5433 11.7035C14.2758 10.6073 14.6668 9.31839 14.6668 7.9999C14.6662 6.23205 13.9636 4.5368 12.7135 3.28674C11.4635 2.03669 9.76823 1.33413 8.00039 1.3335ZM8.00039 13.3335C6.9455 13.3335 5.9143 13.0207 5.0372 12.4346C4.16009 11.8486 3.47647 11.0156 3.07278 10.041C2.6691 9.06639 2.56347 7.99398 2.76927 6.95936C2.97507 5.92475 3.48305 4.97439 4.22896 4.22847C4.97488 3.48255 5.92524 2.97458 6.95985 2.76878C7.99447 2.56298 9.06688 2.6686 10.0415 3.07229C11.0161 3.47598 11.849 4.1596 12.4351 5.03671C13.0212 5.91381 13.334 6.94501 13.334 7.9999C13.3321 9.41387 12.7695 10.7694 11.7697 11.7692C10.7699 12.769 9.41436 13.3316 8.00039 13.3335ZM10.5868 5.5271L6.66679 9.4471L5.41399 8.1935C5.28854 8.07272 5.12072 8.00599 4.9466 8.00764C4.77247 8.00929 4.60595 8.0792 4.48282 8.20233C4.35969 8.32546 4.28979 8.49198 4.28813 8.66611C4.28648 8.84023 4.35322 9.00805 4.47399 9.1335L6.20039 10.8599C6.26206 10.9217 6.33532 10.9707 6.41597 11.0042C6.49662 11.0376 6.58307 11.0549 6.67039 11.0549C6.7577 11.0549 6.84415 11.0376 6.9248 11.0042C7.00545 10.9707 7.07871 10.9217 7.14039 10.8599L11.534 6.4671C11.5958 6.40542 11.6448 6.33216 11.6783 6.25151C11.7117 6.17086 11.7289 6.08441 11.7289 5.9971C11.7289 5.90978 11.7117 5.82333 11.6783 5.74268C11.6448 5.66203 11.5958 5.58877 11.534 5.5271C11.4081 5.40228 11.238 5.33224 11.0608 5.33224C10.8835 5.33224 10.7134 5.40228 10.5876 5.5271H10.5868Z"
              fill="#5DD3B3"
            />
          </g>
        </svg>
      )}
    </div>
  );
};

export default ProgressBar;
