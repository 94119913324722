import React, { useEffect } from 'react'
import TinyEditor from '../../../elements/tinyEditor/TinyEditor';
import DOMPurify from 'dompurify';
import { findCommentForAnswer, findStudentAnswer } from './surveyStudentCompoUtils';
import { useAuth0 } from '@auth0/auth0-react';
import { auth0SubIdSplitter,auth0ProviderSplitter } from '../../../utils/StringManipulation';
import { useParams } from 'react-router-dom';
import { StudentSurveyInstructor, StudentSurveyInstructorReply } from './StudentSurvey.style';
import { TypographyRegularDescription } from '../../../elements/fonts/Fonts';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ColorSelector } from '../../../recoil/ThemeSelectors';

interface SurveyEssayProps {
  ShowSave: boolean;
  handleDescriptionChange:any;
  indexOfFirst:number;
  index:number
  question:any;
  studentAnswerArray:any
  id:number
  setDescriptions:any
  setSurveyQuestionId:any
}

const SurveyEssay = ({ 
    ShowSave,
    handleDescriptionChange,
    index,
    indexOfFirst,
    id,
    studentAnswerArray,
    question,
    setDescriptions,
    setSurveyQuestionId
 }: SurveyEssayProps) => {
    const { user } = useAuth0();
    const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
    const provider = auth0ProviderSplitter(user?.sub || "");
    const params = useParams();
    const classId = params.classId;
    const { t } = useTranslation();
    const { themeTextColor, theme } =
    useRecoilValue(ColorSelector);
    
    const decodeHtml = (value: any) => {
        const textarea = document.createElement("textarea");
        textarea.innerHTML = value;
        return textarea.value;
      };

      useEffect(()=>{
        const key = `${question.title}`;
    const idKey = `${key}_ID`;
    setSurveyQuestionId((prevId:any) => ({
      ...prevId,
      [idKey]: question.SurveyQuestionsId || 0,
    }));
        if(studentAnswerArray && studentAnswerArray.length === 0 ){
          // Fetch and set the timer from local storage if it exists
          const savedStudentAnswer = JSON.parse(localStorage.getItem('localStudentSurveyResponse') || '[]');
          const currentQuizAnswer = savedStudentAnswer.find((item: any) =>
            item.classSourceId === classId &&
            item.subId === subIdSplitted &&
            item.refId === id
          );
    
          if (currentQuizAnswer &&
            question) {
            
          if(currentQuizAnswer && currentQuizAnswer.localStudentResponse && currentQuizAnswer.localStudentResponse[key]){
            setDescriptions((prevLineValues:any) => ({
              ...prevLineValues,
              [key]: currentQuizAnswer.localStudentResponse[key],
            }));
            }else{
              setDescriptions((prevLineValues:any) => ({
                  ...prevLineValues,
                  [key]: '',
                }));
          }
          }
          }
      },[question])

  return (
    <>
                    <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              decodeHtml(question.question || "")
                            ),
                          }}
                          style={{
                            fontSize: "1rem"
                          }}
                    ></div>
                    {!ShowSave ? (
                      <>
                        <TinyEditor
                          onChange={(value: string) => { }}
                          placeholder={t('quiz.write your answer')}
                          initialValue={
                            String(
                              findStudentAnswer(
                                question.SurveyQuestionsId || 0,
                                studentAnswerArray,
                                question.title || '',
                                classId || '',
                                subIdSplitted,
                                id,
                                question.type
                              )[0].choice
                            ) !== "null"
                              ? String(
                                findStudentAnswer(
                                  question.SurveyQuestionsId || 0,
                                  studentAnswerArray,
                                  question.title || '',
                                  classId || '',
                                  subIdSplitted,
                                  id,
                                  question.type
                                )[0].choice
                              )
                              : ""
                          }
                          height="7.5rem"
                          readonly={true}
                        // PartTitle={question.question || ""}
                        />
                        {/* The Comment of the instructor for the answer goes Here */}
                        {findCommentForAnswer(
                          question.SurveyQuestionsId || 0,
                          studentAnswerArray
                        ) &&
                          findCommentForAnswer(
                            question.SurveyQuestionsId || 0,
                            studentAnswerArray
                          ).trim() !== "No Comment" && (
                            <StudentSurveyInstructor>
                              <TypographyRegularDescription
                                text={t("assignment.Instructor Comment")}
                                fontSize="0.875rem"
                              />
                              <StudentSurveyInstructorReply color={themeTextColor} theme={theme}>
                                <TypographyRegularDescription
                                  text={findCommentForAnswer(
                                    question.SurveyQuestionsId || 0,
                                    studentAnswerArray
                                  )}
                                  fontSize="0.75rem"
                                />
                              </StudentSurveyInstructorReply>
                            </StudentSurveyInstructor>
                          )}
                      </>
                    ) : (
                      <TinyEditor
                        onChange={(value: string) => {
                          if (question.id) {
                            handleDescriptionChange(value, indexOfFirst + index);
                          }
                        }}
                        placeholder={t('quiz.write your answer')}
                        initialValue={
                          String(
                            findStudentAnswer(
                              question.SurveyQuestionsId || 0,
                              studentAnswerArray,
                              question.title || '',
                              classId || '',
                              subIdSplitted,
                              id,
                              question.type
                            )[0].choice
                          ) !== "null"
                            ? String(
                              findStudentAnswer(
                                question.SurveyQuestionsId || 0,
                                studentAnswerArray,
                                question.title || '',
                                classId || '',
                                subIdSplitted,
                                id,
                                question.type
                              )[0].choice
                            )
                            : ""
                        }
                        height="7.5rem"
                      />
                    )}
                  </>
  )
}

export default SurveyEssay