import React, { useState } from "react";
import { AnalyticDefaultTableSurveyDataAction } from "../../../elements/AnalyticDefaultTable/AnalyticDefaultTableDataAction";
import { ADTSupperMainContainer } from "../../../elements/AnalyticDefaultTable/AnalyticDefaultTable.style";
import {
  AQColumn,
  AQStudentCells,
  AQStudentCol,
  AnalyticsQuizzesMainContainer,
  AqFirstRow,
  AqMINIRow,
  AqMiniCol,
} from "../../AnalyticsQuizzes/AnalyticsQuizzes.style";
import { Typography } from "../../../elements/fonts/Fonts";
import { InstructorViewCompletionSearchContainer } from "../../StudentCompletionTrackingInstrcuterView/InstructorViewCompletion.style";
import SearchElement from "../../../elements/searchElement/SearchElement";
import { SvgDownload } from "../../../elements/Icons";
import { LogoAndAction } from "../../gradeBookTable/GradeBookTable.style";
import ScrollLeftAndRight from "../../../elements/ScrollLeftAndRight/ScrollLeftAndRight";

import Pagination from "../../../elements/Pationation/Pagination";
import TableViewSelectInput from "../../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/TableViewSelectInput";
import {
  ViewsOptions,
  handleNumberDisplays,
} from "../../../utils/NbOfViewesObjects";
import SurveyTableContent from "./SurveyTableContent";
export interface SurveyQuestion {
  QuestionCode: string;
  QuestionTitle: string;
  QuestionType: string;
  points?: number;
  students: {
    StdCode: string;
    StdName: string;
    TimeSpent: number;
    Answered: boolean;
  }[];
}

interface AnalyticDefaultTableInterface {
  title: string;
  onClickExcelDowload?: () => void;
  DatabaseTableName: any[];
  HeadSubTitleArray?: any[];
  StdName?: string;
  // setChosenQuestion?: any;
  setChosenQuestion?: (question: SurveyQuestion) => void;
}
const SurveyQuestionAnalytics = ({
  title,
  onClickExcelDowload,
  setChosenQuestion,
  DatabaseTableName,
  StdName = "STUDENT NAME",
  HeadSubTitleArray = AnalyticDefaultTableSurveyDataAction,
}: AnalyticDefaultTableInterface) => {
  const uniqueNames = new Set();
  const [ViewText, setViewText] = useState("View All");

  const [limit, setLimit] = useState(DatabaseTableName.length);
  const itemsPerPage = limit;
  const [currentPage, setCurrentPage] = useState(1);
  const [ShowPagination, setShowPagination] = useState(false);
  const startIndex = (currentPage - 1) * itemsPerPage;

  const endIndex = startIndex + itemsPerPage;

  const [searchText, setSearchText] = useState("");

  const filteredData = DatabaseTableName.map((item) => {
    const filteredStudents = (item.students || [])
      .filter((student: { StdCode: string }) =>
        student?.StdCode?.toLowerCase().includes(searchText.toLowerCase())
      )
      .slice(startIndex, endIndex);

    // Include the main object only if there are matching students
    if (filteredStudents.length > 0) {
      return {
        ...item,
        students: filteredStudents,
      };
    }
    return null; // Exclude objects without matching students
  }).filter(Boolean);
  const handleNumberShows = (newValue: string) => {
    handleNumberDisplays(
      newValue,
      setViewText,
      setLimit,
      filteredData,
      setShowPagination
    );
  };

  return (
    <ADTSupperMainContainer>
      <AqFirstRow>
        <AqMiniCol>
          {/* title is the top top right title text */}
          <Typography text={title} fontSize="1.75rem" />
        </AqMiniCol>
        <AqMiniCol>
          <InstructorViewCompletionSearchContainer>
            <SearchElement onChange={(e) => setSearchText(e.target.value)} />
          </InstructorViewCompletionSearchContainer>
          <AqMINIRow>
            <TableViewSelectInput
              value={ViewText}
              options={ViewsOptions}
              onChange={handleNumberShows}
            />
            <LogoAndAction onClick={onClickExcelDowload}>
              <SvgDownload />
              <Typography text="Download" fontSize="0.75rem" />
            </LogoAndAction>
          </AqMINIRow>
        </AqMiniCol>
      </AqFirstRow>
      <AQColumn>
        <AnalyticsQuizzesMainContainer>
          <AQStudentCol>
            <AQStudentCells />
            <AQStudentCells
              style={{
                height: "2.95rem",
                marginTop: "0",
              }}
            >
              <Typography text={StdName} fontSize="1rem" />
            </AQStudentCells>
            {filteredData.map(
              (val) =>
                val?.students &&
                val.students.map(
                  (stdName: { StdCode: any; StdName: any }, Qindex: any) => {
                    if (!uniqueNames.has(stdName?.StdCode)) {
                      uniqueNames.add(stdName?.StdCode);
                      return (
                        <AQStudentCells style={{ width: "20rem" }}>
                          <Typography
                            text={stdName?.StdCode}
                            fontSize="0.875rem"
                            wordWrap="break-word"
                          />
                        </AQStudentCells>
                      );
                    }
                    return null;
                  }
                )
            )}
          </AQStudentCol>
          <ScrollLeftAndRight
            Width="76%"
            paddingRight="10rem"
            MarginTop="1rem"
            content={
              <SurveyTableContent
                DatabaseTableName={DatabaseTableName}
                setChosenQuestion={setChosenQuestion}
                HeadSubTitleArray={HeadSubTitleArray}
                filteredData={filteredData}
              />
            }
          />
        </AnalyticsQuizzesMainContainer>
      </AQColumn>
      {ShowPagination && (
        <Pagination
          currentPage={currentPage}
          total={DatabaseTableName.length}
          limit={itemsPerPage}
          onPageChange={(page: number) => setCurrentPage(page)}
          MarginTop="-2rem"
          justifyContent="left"
          Padding="0 0 0 5.5rem"
          BoxSize="1.85rem"
          ScrollTop={false}
        />
      )}
    </ADTSupperMainContainer>
  );
};

export default SurveyQuestionAnalytics;
