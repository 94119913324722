import React, { useEffect, useState } from "react";
import { SvgBackArrow, SvgEyeIcon, SvgShareIcon, SvgAssignmentIcon, SvgQuiz, SvgSurveyIcon, SvgDiscussion, SvgCheck, } from "../../elements/Icons";
import { LightTypography, Typography, TypographyRegularDescription, } from "../../elements/fonts/Fonts";
import { ReactComponent as SvgStarIcon } from "../../assets/icons/Path 4030.svg";
import { ReactComponent as SvgCredentialIcon } from "../../assets/icons/Credential.svg";
import { ReactComponent as SvgLeaderboardIcon } from "../../assets/icons/Leaderboard.svg";
import { FlexBetween, FlexDiv, Container, ModuleContainer, ModuleTitle, ProgressBarLine, Progress, ProgressBarContainer, ActivityContainer, ModulesAndLeaderCont, AllModulesCont, } from "./CompletionTracking.style";
import CancelButton from "../../elements/StyledButton/CancelButton";
import { ClassData } from "./CompletionTracking.data";
import PopUp from "../../elements/Popup/PopUp";
import ShareProgressPost from "../shareProgressPost/ShareProgressPost";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Badge from "../badge/Badge";
import axios from "axios";
import { getResourceIcon } from "../CourseTimeline/courseTimelineComponents/ResourceIcon";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../utils/StringManipulation";
import Loader from "../loader/Loader";
import Activity from "./activity/Activity";
import Leaderboard from "../leaderboard/Leaderboard";

const CompletionTracking = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { classId } = useParams();
  const BaseURL = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";
  const [data, setData] = useState<any>();
  const { isLoading, user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "")
  // Expand the leaderboard section if the user navigated back from ExpandedLeaderboard
  const initialActiveTab = location.state ? location.state.isLeaderboard : false;

  const [isLeaderboard, setIsLeaderboard] = useState<boolean>(initialActiveTab);
  const [showSharePopUp, setShowSharePopup] = useState<boolean>(false);
  const [showBadgePopUp, setShowBadgePopup] = useState<boolean>(false);
  const [selectedModuleId, setSelectedModuleId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [onlineSessionStatus, setOnlineSessionStatus] = useState<any>();
  const [assignmentSubmitted, setAssignmentSubmitted] = useState<boolean>(false);
  const [resourceData, setResourceData] = useState<any>();
  const [classTitle, setClassTitle] = useState<any>();
  const [classCredit, setClassCredit] = useState<any>();

  // close the share progress popup & badge popup
  const handleClosePopup = () => {
    setShowSharePopup(false);
    setShowBadgePopup(false);
  };

  // Assign the clicked module's ID to track which module was shared on social feed
  const handleModuleClick = (moduleId: string) => {
    setSelectedModuleId(moduleId);
  };

  // fetch the title and credit for a module
  const getAboutClassData = async (classSourceId: string) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(`${BaseURL}/about/class/${classSourceId}/${tenantName}`);
      setClassTitle(res.data.data[0].classData.title);
      setClassCredit(res.data.data[0].course.numberOfCredits);
    } catch (error) {
      console.log(error);
    }
  };

  // fetch data (activities and resources)
  useEffect(() => {
    const fetchData = async () => {
      const tenantName = localStorage.getItem("tenant");
      try {
        const response = await axios.get(`https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/class/module/all/${classId}/${tenantName}`);
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
    getAboutClassData(classId || "");
  }, [classId]);


  const getResourceUrl = (activityR: any, classId: any, subIdSplitted: string) => {
    const tenantName = localStorage.getItem("tenant");
    switch (activityR.type) {
      case "Assignment":
        return `${BaseURL}/assignment/get/${tenantName}/${activityR.refId}/${subIdSplitted}?provider=${provider}`;
      case "Quiz":
        return `${BaseURL}/quiz/questions/${activityR.refId}/${tenantName}`;
      case "Online_Session":
        return `${BaseURL}/activity/onlineSession/${activityR.refId}/${subIdSplitted}/${classId}/${tenantName}?provider=${provider}`;
      case "Survey":
        return `${BaseURL}/survey/get/submit/${activityR.refId}/${subIdSplitted}/${tenantName}?provider=${provider}`;
      default:
        return "";
    }
  };

  // handle show leaderboard right part
  const handleLeaderborad = () => {
    setIsLeaderboard(!isLeaderboard);
  };

  if (loading) return <Loader margin="30vh 0 0 0" text="Loading Modules..." />;

  return (
    <Container>
      {/* header */}
      <FlexBetween>
        {/* class title */}
        <FlexDiv gap="0.63rem">
          <SvgBackArrow onClick={() => navigate("/AllCourses")} style={{ cursor: "pointer" }} />
          <Typography text={classTitle} fontSize="1.75rem" />
        </FlexDiv>
        <FlexDiv gap="1.25rem">
          {/* leaderboard */}
          <FlexDiv onClick={handleLeaderborad} style={{ cursor: "pointer" }}>
            <SvgLeaderboardIcon color="#5DD3B3" />
            <TypographyRegularDescription text="Leaderboard" fontSize="0.875rem" color="#5DD3B3" />
          </FlexDiv>
          {/* social feed */}
          <FlexDiv onClick={() => { navigate(`/manageSocialFeed/${classId}`); }} style={{ cursor: "pointer" }}>
            <SvgEyeIcon color="#5DD3B3" />
            <TypographyRegularDescription text="View Social Feed" fontSize="0.875rem" color="#5DD3B3" />
          </FlexDiv>
          {/* credits */}
          <TypographyRegularDescription text={classCredit ? `${classCredit} Credits` : "No Credits"} fontSize="0.875rem" color="#5DD3B3" />
        </FlexDiv>
      </FlexBetween>

      <ModulesAndLeaderCont>
        <AllModulesCont>
          {data &&
            data.map((module: any, moduleIndex: any) => (
              <React.Fragment key={moduleIndex}>
                <ModuleContainer key={module.moduleTitle}>
                  <ModuleTitle>
                    <FlexDiv gap="1.25rem">
                      <Typography text={module.moduleTitle} fontSize="1.125rem" />
                      <FlexDiv onClick={() => { setShowSharePopup(true); handleModuleClick(module.moduleId); }} style={{ cursor: "pointer" }}>
                        <SvgShareIcon />
                        <TypographyRegularDescription text="Share Progress" fontSize="0.875rem" />
                      </FlexDiv>
                    </FlexDiv>

                    {/* {module.progress === 100 && (
                  <FlexDiv onClick={() => setShowBadgePopup(true)} style={{ cursor: "pointer" }}>
                    <SvgCredentialIcon />
                    <LightTypography text="Download Badge" color="#5DD3B3" fontSize="0.875rem" />
                  </FlexDiv>
                )} */}
                  </ModuleTitle>

                  {/* <ProgressBarContainer>
                <FlexBetween>
                  <LightTypography text="Course Status" fontSize="0.75rem" />
                  <TypographyRegularDescription text={`${module.progress} %`} fontSize="0.75rem" />
                </FlexBetween>
                <ProgressBarLine>
                  <Progress width={`${module.progress}%`} backgroundColor={ module.progress > 0 && module.progress < 50 ? "#D85D66" : module.progress === 50 ? "#CBD35D" : module.progress > 50 && module.progress < 100 ? "#5D86D3" : module.progress === 100 ? "#5DD3B3" : undefined // Handle other cases as needed } />
                </ProgressBarLine>
              </ProgressBarContainer> */}

                  {module.topicsArray.map((topic: any, index: any) => (
                    <React.Fragment key={index}>
                      {topic.contents.map((activity: any, index: any) => (
                        <ActivityContainer key={index} style={{ background: index % 2 === 0 ? "rgba(255, 255, 255, 0.03)" : "#384450", }} >
                          <Activity activityR={activity} courseId={classId} moduleIndex={moduleIndex} topicIndex={topic.topicIndex} />
                        </ActivityContainer>
                      ))}
                    </React.Fragment>
                  ))}
                </ModuleContainer>
              </React.Fragment>
            ))}
        </AllModulesCont>

        {/* Leaderboard part */}
        {isLeaderboard && <Leaderboard />}
      </ModulesAndLeaderCont>

      {/* popups */}
      {showSharePopUp && (
        <PopUp content={<ShareProgressPost onClosePopup={handleClosePopup} classData={ClassData} selectedId={selectedModuleId} />} showline={false} show={showSharePopUp} setShow={setShowSharePopup} showButton={false} closeContainer={false} />
      )}
      {showBadgePopUp && (
        <PopUp content={<Badge />} showline={false} show={showBadgePopUp} setShow={setShowBadgePopup} showButton={false} paddingTop="1.25rem" paddingLeft="0rem" paddingRight="0rem" />
      )}
    </Container>
  );
};

export default CompletionTracking;
