import React, { FC, useState, useEffect } from "react";
import {
  CustomCheckboxWrapper,
  CheckBox,
  TrueFalseContainer,
  BranchName,
} from "./TrueFalseQuestion.style";
import { TrueFalseQuestionProps } from "./TrueFalseQuestionInterface";
import {
  LightTypography,
  MediumTypography,
} from "../../../elements/fonts/Fonts";
import DropQuestion from "../../SurveyBranchCreatorDropQuestion/DropQuestion";
import QuestionCRUDTextEditor from "../QuestionCRUDTextEditor/QuestionCRUDTextEditorForBranching";
import MultipleQuestionForBranching from "../MultipleQuestion/MultipleQuestionForBranching";
import SurveyRating from "../../surveyRating/SurveyRating";
import { SurveyLikertScale } from "../../../elements/SurveyCreatorLikertScale/SurveyLikertScale";
import EssayQuestion from "../EssayQuestion/EssayQuestion";
import { Choice } from "../../generateSurvey/generateSurveyDataInterface";
import {
  QuestionIconsContainer,
  QuestionHeaderContainer,
} from "../../SurveyCreatorQuestionCRUD/QuestionCRUDTextEditor/QuestionCRUDTextEditor.style";
import { theme } from "../../../theme";
import { ErrorDiv } from "../../../elements/ErrorDiv/Errordiv.style";
import { ReactComponent as AddIcon } from "../../../assets/icons/Add (1).svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete 1 copy.svg";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { mapErrorType } from "../../../utils/QuizErrorTypes";

const TrueFalseQuestion: FC<TrueFalseQuestionProps> = ({
  choice,
  qindex,
  showQuest,
  handleTrueOrFalseChecked,
  quest,
  FillBranch,
  changeBranchName,
  questions,
  index,
  partIndex,
  questionError,
  addChoice,
  allChoices,
  handleChoiceMultipleQuestionForBranching,
  emptyBranch,
}) => {
  const [isChecked, setIsChecked] = useState<any>(null);
  const [isBranched, setIsBranched] = useState<any>(false);
  const [branchType, setBranchType] = useState("");
  const [branchChoiceTypes, setBranchChoiceTypes] = useState<
    Record<number, string>
  >({});
  const [branchChoices, setBranchChoices] = useState<Choice[]>([]);
  const { t } = useTranslation();

  const handleSingleBranchChoice = () => {
    const safeIndex = qindex ?? 0;
    // Ensure allChoices exists and has valid data before accessing its properties
    if (allChoices && allChoices[safeIndex]?.branch?.choices) {
      setBranchChoices(allChoices[safeIndex]?.branch?.choices || []);
    }
  };

  useEffect(() => {
    handleSingleBranchChoice();
  }, [questions, index, partIndex, branchChoiceTypes]);

  useEffect(() => {
    if (choice?.isChecked !== undefined) {
      setIsChecked(choice?.isChecked);
    }
  }, [choice]);

  useEffect(() => {
    setIsBranched(quest?.branching);
  }, [quest?.branching]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    handleTrueOrFalseChecked &&
      handleTrueOrFalseChecked(qindex || 0, !isChecked);
  };

  const handleBranchTypeChange = (type: any) => {
    const updatedBranchChoiceTypes = { ...branchChoiceTypes };
    updatedBranchChoiceTypes[qindex || 0] = type;
    setBranchChoiceTypes(updatedBranchChoiceTypes);
  };

  useEffect(() => {
    if (allChoices) {
      const currentBranchChoiceType =
        allChoices[qindex || 0]?.branch?.type || "";
      const updatedBranchChoiceTypes = { ...branchChoiceTypes };
      updatedBranchChoiceTypes[qindex || 0] = currentBranchChoiceType;
      setBranchChoiceTypes(updatedBranchChoiceTypes);
    }
  }, [allChoices]);
  const { SecondaryWhiteTextColor,theme } = useRecoilValue(ColorSelector);
  const errorType = mapErrorType(questionError || "", t);
  return (
    <>
      {questionError && qindex === 0 && (
        <ErrorDiv style={{ marginLeft: "1.5rem" }}>{errorType}</ErrorDiv>
      )}
      <TrueFalseContainer>
        {!isBranched ? (
          <>
            <MediumTypography
              text={`${t("quiz.Choices")}`}
              fontSize="0.875rem"
              fontWeight="400"
              color={SecondaryWhiteTextColor}
            />
            <LightTypography
              fontSize="0.875rem"
              text={`${t("survey.YES")}/${t("survey.NO")}`}
              fontWeight="400"
              color={theme === "light" ? "#AAB1B9" : "#D9E4E8"}
            />
          </>
        ) : (
          <>
            <MediumTypography
              text={`${t("quiz.Choices")}*`}
              fontSize="0.875rem"
              lineHeight="1.375rem"
            />
            <CustomCheckboxWrapper>
              {!showQuest ? (
                <>
                  <CheckBox
                    type="radio"
                    checked={isChecked === true}
                    name="isChecked"
                    id={`True${qindex}`}
                    onChange={handleCheckboxChange}
                  />
                  <LightTypography fontSize="0.875rem" text="Yes" />
                </>
              ) : (
                ""
              )}
            </CustomCheckboxWrapper>
            <CustomCheckboxWrapper>
              {!showQuest ? (
                <>
                  <CheckBox
                    type="radio"
                    checked={isChecked === false}
                    name="isChecked"
                    id={`False${qindex}`}
                    onChange={handleCheckboxChange}
                  />
                  <LightTypography fontSize="0.875rem" text="No" />
                </>
              ) : (
                ""
              )}
            </CustomCheckboxWrapper>
          </>
        )}
      </TrueFalseContainer>

      {isBranched &&
        qindex === 0 &&
        !showQuest &&
        allChoices &&
        allChoices.length < 2 && (
          <QuestionHeaderContainer>
            <QuestionIconsContainer>
              <MediumTypography
                text="Create the set of questions when student chooses this answer"
                // color={theme.dark.primary_text_color}
              />
            </QuestionIconsContainer>
            <AddIcon onClick={addChoice} />
          </QuestionHeaderContainer>
        )}

      {isBranched && !branchChoiceTypes[qindex || 0] && (
        <DropQuestion
          qindex={qindex}
          FillBranch={FillBranch}
          OnBranchTypeChange={handleBranchTypeChange}
        />
      )}
      {isBranched && branchChoiceTypes[qindex || 0] && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginRight: "2rem",
              alignItems: "center",
            }}
          >
            <BranchName style={{ marginLeft: "2rem", marginTop: "1rem" }}>
              Branch: {(qindex && qindex + 1) || 1}
            </BranchName>
            {!showQuest && (
              <DeleteIcon
                style={{ cursor: "pointer" }}
                onClick={() => emptyBranch && emptyBranch(qindex || 0)}
              >
                Delete
              </DeleteIcon>
            )}
          </div>
          <div style={{ padding: "2rem", marginTop: "-1.5rem" }}>
            <QuestionCRUDTextEditor
              questionError={questionError}
              qindex={qindex}
              allChoices={allChoices}
              showQuest={showQuest}
              quest={quest}
              changeBranchName={changeBranchName}
            />
          </div>
        </>
      )}
      {isBranched &&
        branchChoiceTypes[qindex || 0] &&
        branchChoiceTypes[qindex || 0] === "Multiple choice" &&
        branchChoices.map((branchchoice, branchChoiceIndex) => (
          <div style={{ padding: "2rem", marginTop: "-3rem" }}>
            <MultipleQuestionForBranching
              key={branchChoiceIndex}
              showQuest={showQuest}
              branchchoice={branchchoice}
              handleChoiceMultipleQuestionForBranching={
                handleChoiceMultipleQuestionForBranching
              }
              qindex={qindex}
              branchChoiceIndex={branchChoiceIndex}
            />
          </div>
        ))}
      {isBranched &&
        branchChoiceTypes[qindex || 0] &&
        branchChoiceTypes[qindex || 0] === "Yes-No" && (
          <div style={{ padding: "2rem", marginTop: "-3rem" }}>
            <TrueFalseQuestion />
          </div>
        )}
      {isBranched &&
        branchChoiceTypes[qindex || 0] &&
        branchChoiceTypes[qindex || 0] === "Rating" && (
          <div style={{ padding: "2rem", marginTop: "-3rem" }}>
            <SurveyRating />
          </div>
        )}
      {isBranched &&
        branchChoiceTypes[qindex || 0] &&
        branchChoiceTypes[qindex || 0] === "Scale" && (
          <div style={{ padding: "2rem", marginTop: "-3rem" }}>
            <SurveyLikertScale />
          </div>
        )}
      {isBranched &&
        branchChoiceTypes[qindex || 0] &&
        branchChoiceTypes[qindex || 0] === "Essay" && <EssayQuestion />}
      {questionError && qindex === 1 && (
        <ErrorDiv style={{ marginLeft: "3.37rem" }}>{questionError}</ErrorDiv>
      )}
    </>
  );
};

export default TrueFalseQuestion;
