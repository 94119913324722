import React, { FC, useEffect, useState } from "react";
import { ReactComponent as RightArrow } from "../../../assets/icons/arrow_simple_1 (2).svg";
import { ReactComponent as LeftArrow } from "../../../assets/icons/arrow_simple_1 (3).svg";
import { ReactComponent as DownArrow } from "../../../assets/icons/Arrow.svg";
import StudentPhoto from "../../../assets/updateProfileImg.jpg";
import { get } from "../../../utils/AxiosRequests";
import {
  StudentImage,
  StudentContainer,
  ContentContainer,
  AvailabilityContainer,
  NameContainer,
  SectionContainer,
} from "./Student.style";
import {
  LightTypography,
  TypographyRegularDescription,
  MediumTypography,
} from "../../../elements/fonts/Fonts";
import { StudentProps } from "./StudentInterface";
const Student: FC<StudentProps> = ({
  student,
  //setAvarage,
  // setGraded,
  // setSubmitted,
  setStudentIndex,
  students,
  studentIndex,
  forum,
  quiz,
  allAssignment,
}) => {
  const [Index, setIndex] = useState<number>(0);
  const [profilePicture, setProfilePicture] = useState<string>(""); // State to hold the profile picture URL
  const [loading, setLoading] = useState<boolean>(true);
  const imageGif = require("../../../assets/loadingGif.gif");
  const profileImage = require("../../../assets/userProfile.png");
  const [isPenalty, setIsPenalty] = useState<boolean>(false);

  useEffect(() => {
    if (
      !forum &&
      allAssignment &&
      student &&
      student.length > 0 &&
      allAssignment.dueDate &&
      // allAssignment.cutOffDate &&
      allAssignment.penalty
    ) {
      const submissionDate = new Date(student[studentIndex].createdAt);
      const dueDate = new Date(allAssignment.dueDate);
      // const cutOffDate = new Date(allAssignment.cutOffDate);

      const isPenalty = submissionDate >= dueDate;
      setIsPenalty(isPenalty);
    }
  }, [allAssignment, student, studentIndex]);

  useEffect(() => {
    if (
      forum &&
      allAssignment &&
      student &&
      student.length > 0 &&
      allAssignment.dueDateTime &&
      // allAssignment.cutOffDate &&
      allAssignment.penalty
    ) {
      const submissionDate = new Date(
        student[studentIndex].submissions[0].postTime
      );
      const dueDate = new Date(allAssignment.dueDateTime);
      // const cutOffDate = new Date(allAssignment.cutOffDate);

      const isPenalty = submissionDate >= dueDate;
      setIsPenalty(isPenalty);
    }
  }, [allAssignment, student, studentIndex]);

  const NextStudent = (index: number) => {
    if (student.length === 0) {
      return;
    }
    const newIndex = (studentIndex + 1) % student.length;
    setIndex(newIndex);
    setStudentIndex(newIndex);
    // setGraded(student[newIndex].graded);
    //setAvarage(student[newIndex].avarage);
    // setSubmitted(student[newIndex].submitted);
  };
  const BackStudent = (index: number) => {
    const newIndex = (studentIndex - 1 + student.length) % student.length;
    setIndex(newIndex);
    setStudentIndex(newIndex);
    //setAvarage(student[newIndex].avarage);
    // setSubmitted(student[newIndex].submitted);
  };

  // const getProfilePictureForUsers = async (userSourceId: any) => {
  //   try {
  //     setLoading(true);
  //     const tenantName = localStorage.getItem("tenant");
  //     const response = await get(`user/profilePicture/${tenantName}/${userSourceId}`);
  //     setLoading(false);
  //     return response.data;

  //   } catch (error) {
  //     console.error("Error fetching profile picture:", error);
  //     throw error; // Rethrow the error to handle it at a higher level
  //   }
  // };

  // useEffect(() => {
  //   // Fetch the profile picture URL when the component mounts or whenever 'students' change

  //   const fetchProfilePicture = async (sourceId: any) => {
  //     try {
  //       const picture = await getProfilePictureForUsers(sourceId);
  //       setProfilePicture(picture);

  //     } catch (error) {
  //       console.error("Error fetching profile picture:", error);
  //     }
  //   };

  //   if (students && students[Index] && !forum) {
  //     fetchProfilePicture(students[Index].sourceId);

  //   }
  // }, [students, Index]);
  const language = localStorage.getItem("language");

  return (
    <StudentContainer>
      {students
        ?.filter((data, index) => index === studentIndex)
        .map((data, index) => (
          <React.Fragment key={index}>
            <LeftArrow
              onClick={() => BackStudent(index)}
              style={{
                cursor: "pointer",
                transform: `${language === "ar" ? "rotate(180deg)" : ""}`,
              }}
            />
            {forum && (
              <StudentImage
                src={data.posterProfilePic || profileImage}
                alt="student-image"
              />
            )}
            {!forum && !quiz && (
              <StudentImage
                src={data.profilePicture || profileImage}
                alt="student-image"
              />
            )}
            {quiz && (
              <StudentImage
                src={data.profilePicture || profileImage}
                alt="student-image"
              />
            )}

            <ContentContainer>
              {isPenalty ? (
                <AvailabilityContainer>
                  <LightTypography
                    text="LATE"
                    fontSize="0.75rem"
                    fontWeight="400"
                    lineHeight="normal"
                    color="#D85D66"
                  />
                  {/* <TypographyRegularDescription
                  text={`${data.late}/10`}
                  fontSize="0.625rem"
                  fontWeight="400"
                  lineHeight="normal"
                /> */}
                </AvailabilityContainer>
              ) : (
                ""
              )}

              <SectionContainer>
                <NameContainer>
                  {forum && !quiz ? (
                    <MediumTypography
                      text={data.posterName}
                      fontSize="1.125rem"
                      fontWeight="400"
                      lineHeight="normal"
                    />
                  ) : !forum && quiz ? (
                    <MediumTypography
                      text={data.username}
                      fontSize="1.125rem"
                      fontWeight="400"
                      lineHeight="normal"
                    />
                  ) : (
                    <MediumTypography
                      text={data.fName + " " + data.lName}
                      fontSize="1.125rem"
                      fontWeight="400"
                      lineHeight="normal"
                    />
                  )}

                  <DownArrow style={{ cursor: "pointer" }} />
                </NameContainer>
                <div style={{ width: "10.25rem" }}>
                  <MediumTypography
                    text={`ID :${forum ? data.posterId : data.sourceId}`}
                    fontSize="0.75rem"
                    color="#AAB1B9"
                  />
                </div>
                {/* <MediumTypography
                  text={data.section}
                  fontSize="0.75rem"
                  fontWeight="400"
                  lineHeight="normal"
                /> */}
              </SectionContainer>
            </ContentContainer>

            <RightArrow
              onClick={() => NextStudent(index)}
              style={{
                cursor: "pointer",
                transform: `${language === "ar" ? "rotate(180deg)" : ""}`,
              }}
            />
          </React.Fragment>
        ))}
    </StudentContainer>
  );
};

export default Student;
