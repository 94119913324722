import styled from "styled-components";
import { breakpoints } from "../../theme";

interface FormDividLineInteface {
  Height?: string;
}
export const OptionsDisplays = styled.div`
  &:dir(ltr){
    padding-right: 1.3rem;
  }
  &:dir(rtl){
    padding-left: 1.3rem;
  }
  padding-top: 0;
  padding-left: 0;
  justify-content: center;
  /* margin-right: -0.3rem; */
  color: none;
  display: flex;
  /* background-color: aqua; */
  flex-direction: column;
  @media (max-width: ${breakpoints.tablets}) {
    padding: 0.7rem;

    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    /* background-color:aqua; */
    gap: 0.55rem;
  }
`;
export const FormDividLine = styled.div<FormDividLineInteface>`
  border-style: solid;
  border-width: 1px;
  border-color: rgba(87, 99, 113, 0.6);
  /* height: 42rem; */
  height: ${(props) => props.Height || "42rem"};
  @media (max-width: ${breakpoints.tablets}) {
    display: none;
  }
`;
export const OneROW = styled.div`
  display: flex;
  align-items: start;
`;
export const TextPlace = styled.div`
  @media (max-width: ${breakpoints.tablets}) {
    display: none;
  }
`;

export const CategoriesDisplays = styled.div<{ borderColor?: string }>`
  border: 0.05rem solid ${(props) => props.borderColor};
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  /* margin-left:-1rem; */
  padding: 0.7rem;
  padding-right: 1rem;
  display: flex;
  min-width: 15.5rem !important;
  /* width:14rem; */
  &:is(:hover, :focus) {
    color: #5dd3b3;
    transition: color 0.2s ease-in;
  }

  @media (max-width: ${breakpoints.tablets}) {
    padding: 0.82rem;
    min-width: 4rem !important;
    justify-content: center;
  }
`;

export const IconSpacing = styled.div`
  width: 1.5rem;
  height: auto;
  margin-left: 0.2rem;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${breakpoints.tablets}) {
    width: 1rem;
    height: 1rem;
    margin-right: 0.2rem;
    margin-left: 0;
    margin-right: 0;
    /* padding-left:0.2rem; */
  }
`;

export const MenuBody = styled.div`
  display: flex;
  gap: 1.88rem;
  width: 100%;
  @media (max-width: ${breakpoints.tablets}) {
    flex-direction: column;
  }
`;
