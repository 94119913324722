import React, { FC } from "react";
import { Container, SideMainContainer } from "./MainToolBar.style";
import ToolBar from "./main/ToolBar";
import { items } from "./ToolBarData";
import { useRecoilValue } from "recoil";
import { Quiz_Survey_Creator_ColorSelector } from "../../recoil/ThemeSelectors";
const MainToolBar: FC = () => {
  const { Quiz_Survey_MainContainer_BgColor } = useRecoilValue(
    Quiz_Survey_Creator_ColorSelector
  );
  return (
    <Container bgcolor={Quiz_Survey_MainContainer_BgColor}>
      <SideMainContainer>
        {items.map((item, index) => (
          <ToolBar key={index} item={item} />
        ))}
      </SideMainContainer>
    </Container>
  );
};

export default MainToolBar;
