import { ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { breakpoints } from "../../theme";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

interface ScrollLeftAndRightLineInterface {
  justifyContent?: string;
  paddingRight?: string;
  paddingLeft?: string;
  BigContainerWidth?: string;
  MarginTop?: string;
  LineWidth?: string;
  AlignSelf?: string;
  SmallAdditionalValue?: any;
  SmallAdditionalWidth?: string;
  TabletAdditionalValue?: any;
  TabletAdditionalWidth?: string;
  position?: string;
}

export const ScrollLeftAndRightContainer = styled.div<ScrollLeftAndRightLineInterface>`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  overflow-x: hidden;
  width: ${(props) => props.BigContainerWidth || "100%"};
  /* @media (max-width: ${breakpoints.tablets}) { } */
  @media (max-width: calc(${breakpoints.small} + ${({ SmallAdditionalValue }) =>
      SmallAdditionalValue || "105px"})) {
    width: ${(props) => props.SmallAdditionalWidth || props.BigContainerWidth};
  }

  @media (max-width: calc(${breakpoints.tablets} + ${({
      TabletAdditionalValue,
    }) => TabletAdditionalValue || "105px"})) {
    width: ${(props) =>
      props.TabletAdditionalWidth || props.SmallAdditionalWidth};
  }
`;
export const ScrollLeftAndRightBody = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  overflow-x: scroll;
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
  &::-ms-overflow-style {
    display: none;
  }
`;

export const ScrollLeftAndRightLine = styled.div<ScrollLeftAndRightLineInterface>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-end"};
  padding-right: ${(props) => props.paddingRight || "2rem"};
  padding-left: ${(props) => props.paddingLeft || "2rem"};
  margin-top: ${(props) => props.MarginTop || "0"};
  /* background-color:blue; */
  width: 100%;
  align-self: ${(props) => props.AlignSelf || "start"};
  position: ${(props) => props.position || "relative"};
  bottom: ${(props) => (props.position === "fixed" ? "2rem" : "")};
  right: ${(props) => (props.position === "fixed" ? "2rem" : "")};
  /* background: aqua; */
  /* position: fixed; */
  z-index: 2;
`;

export const SRLDivArrowsWrapper = styled.div<StyleThemeInterface>`
  width: 2.625rem;
  height: 1.25rem;
  border-radius: 1.25rem;
  background: ${(props) => props.bgcolor};
  box-shadow: ${(props) => props.boxshadow};
  /* position: absolute; */
  bottom: 1.25rem;

  left: 48%;
  display: flex;
  /* background: green; */
  &:dir(ltr) {
    flex-direction: row;
  }
  &:dir(rtl) {
    flex-direction: row-reverse;
  }
  justify-content: space-around;
  align-items: center;
  & > :first-child {
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.dark.main_color};
      transition: color 0.2s ease-in-out;
    }
  }
  & > :last-child {
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.dark.main_color};
      transition: color 0.2s ease-in-out;
    }
  }
`;
