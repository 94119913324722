// src/components/AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/TableViewSelectInput.tsx
import React from 'react';
import { SvgEyeIcon } from '../../../../elements/Icons';
import SelectDropDown from '../../../../elements/selectDropDown/SelectDropDown';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ColorSelector } from '../../../../recoil/ThemeSelectors';

interface Option {
  value?: string;
  name?: string;
 
}
interface TableViewSelectInputProps {
  value: string;
  // options: Option[];
  options:any[];
  onChange: (value: string) => void;
  width?:string;
}

const TableViewSelectInput: React.FC<TableViewSelectInputProps> = ({
  value,
  options,
  onChange,
  width,
}) => {
  const { t } = useTranslation();
  const language = localStorage.getItem("language")
  const { specialColor2,theme } = useRecoilValue(ColorSelector);
  return (
    <SelectDropDown
      icon={<SvgEyeIcon />}
      height="1.2rem"
      paddingRL="0.2rem"
      border="none"
      fontSize="0.625rem"
      width={language === "tr" ?"7.7rem" :"5.875rem"}
      value={value}
      placeholder={t(`assignment.View`)}
      options={options}
      onChange={onChange}
      color={specialColor2}
    />
  );
};

export default TableViewSelectInput;
