import styled, { css } from "styled-components";

export const MainCont = styled.div`
display: flex;
width: 100%;
gap: 1.25rem;
flex-direction: column;
`;

export const Container = styled.div`
display: flex;
width: 100%;
gap: 1.25rem;
flex-wrap: wrap;
`;

export const FlexDiv = styled.div<{ gap?: string }>`
display: flex;
align-items: center;
gap: ${({ gap }) => (gap ? gap : "0.5rem")};
`;
export const RuleBookBtn = styled.button`
width: 8.25rem;
height: 3.125rem;
border-radius: 6.25rem;
border: 1px solid #5DD3B3;
color: #5DD3B3;
font-family: "Primary Font-Medium";
background-color: transparent;
cursor: pointer;
box-shadow: 0 0 0 rgba(0, 0, 0, 0);
transition: box-shadow 0.3s ease;
&:hover {
    box-shadow: 0 0 1rem rgba(42, 243, 156, 0.3);
  }
`;

export const FirstThreeCont = styled.div`
display: flex;
align-items: flex-end;
width: 24.75rem;
height: 13.0625rem;
border-radius: 1.25rem;
background: #364350;
padding: 1.62rem 1.25rem;
gap: 0.62rem;
`;
export const FirstThree = styled.div<{index?:number}>`
display: flex;
flex-direction: column;
gap: 0.62rem;
width: 7rem;
height: 4.75rem;
border-radius: 1.25rem;
background: linear-gradient(90deg, #415160 0%, #293440 107.88%);
padding: 1.12rem 0.94rem;
position: relative;
text-align: center;
justify-content: center;

${({ index }) => index === 0 && css`
    height: 6.6875rem;
    background: linear-gradient(180deg, #5DD3B3 0%, #2D3741 100%);
  `}
`;

export const ProfileAndRank = styled.div`
position: relative;
display: flex;
justify-content: center;
align-items: center;
`;

export const ProfileContainer = styled.div<{ bgimage?: string, width?: string, height?:string }>`
  width: ${({ width }) => (width ? width : "5rem")};
  height: ${({ height }) => (height ? height : "5rem")};
  border-radius: 50%;
  background-image: url(${(props) => props.bgimage});
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #707070;
`;

export const Rank = styled.div`
position: absolute;
bottom: -0.69rem;
width: 1.4375rem;
height: 1.4375rem;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
background: linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%);
z-index: 5;
`;
export const RankCont = styled.div`
display: flex;
flex-direction: column;
`;
export const OtherRanksCont = styled.div`
display: flex;
flex-direction: column;
flex-grow: 1;
gap: 0.63rem;
`;
export const OtherRanks = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
height: 5rem;
padding: 0.94rem 1.25rem;
border-radius: 1.25rem;
background: #364350;
`;

export const RankNumber = styled.div`
width: 1.4375rem;
height: 1.4375rem;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
border: 1px solid #5DD3B3;
color: #5DD3B3;
`;
export const MainUser = styled.div`
height: fit-content;
border-radius: 50%;
display: flex;
flex-direction: column;
border-radius: 1.25rem;
background: #364350;
padding: 1.25rem 1.5rem;
`;
export const ViewAllCont = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 0.63rem;
`;
export const NoResult = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
margin-top: 7.5rem;
gap: 1.32rem
`;