import React, { useEffect, useState } from "react";
import { FlexAppContainer } from "../../App.style";
import { Typography } from "../../elements/fonts/Fonts";
import { AnalyticsAssignmentResponseTrackingInterface } from "../AnalyticsAssigmentResponseTraking/main/AnalyticsAssignmentResponseTracking";
import { AnalyticsPieChartContainer } from "../../Views/Analytics/AnalyticsPageStyles";
import PieWithText from "../Charts/PieWithText/PieWithText";
import PieText from "../Charts/PieText/PieText";
import ProgressBarChart from "../Charts/ProgressBarChart/ProgressBarChart";

const AnalyticsScormPieAndProggress = ({
  onClick,
  Data,
}: AnalyticsAssignmentResponseTrackingInterface) => {
  const [PieChartData, setPieChartData] = useState([
    {
      text: "Attempted",
      attendance: 0,
      bgColor: "#5DD3B3",
    },
    {
      text: "Unattempted",
      attendance: 0,
      bgColor: "#0B8FFF",
    },
  ]);
  useEffect(() => {
    // Calculate attempted count
    const attemptedCount = Data?.filter(
      (item: { ResponsesDate: any; status: any }) =>
        item.ResponsesDate &&
        item.status &&
        (item.status === true ||
          item.status === "Completed" ||
          item.status === "completed")
    ).length;

    const unattemptedCount = Data?.filter(
      (item: { ResponsesDate: any; status: any }) =>
        !item.ResponsesDate ||
        !item.status ||
        item.status === false ||
        item.status === "Incomplete"
    ).length;
    setPieChartData([
      {
        text: "Attempted",
        attendance: attemptedCount,
        bgColor: "#5DD3B3",
      },
      {
        text: "Unattempted",
        attendance: unattemptedCount,
        bgColor: "#0B8FFF",
      },
    ]);
  }, []);
  const MaxNumberOfData = Data?.length;
  return (
    <FlexAppContainer column={true} gap="1.25rem">
      <div style={{ cursor: "pointer" }} onClick={onClick}>
        <Typography text="Submission Tracking" fontSize="1.5rem" />
      </div>
      <FlexAppContainer gap="1.25rem" wrap={true}>
        <AnalyticsPieChartContainer style={{ height: "22rem" }}>
          {/* PieWithText Component */}
          <PieWithText
            data={PieChartData}
            value={"attendance"}
            getColor={(entry) => entry.bgColor as string}
            Width="60%"
            NormalTextDisplay={false}
          />

          {/* PieText Component */}
          <PieText LoopName={PieChartData} />
        </AnalyticsPieChartContainer>

        <AnalyticsPieChartContainer style={{ height: "22rem" }}>
          {/* ProgressBarChart Component */}
          <ProgressBarChart data={PieChartData} MaxNumber={MaxNumberOfData} />
        </AnalyticsPieChartContainer>
      </FlexAppContainer>
    </FlexAppContainer>
  );
};

export default AnalyticsScormPieAndProggress;
