import React, { useState, FC, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../utils/StringManipulation";
import axios from "axios";
import {
  LightTypography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  SurveyContainer,
  SurveyInput,
  LabelDiv,
  DateAndTimeDiv,
  BtnsDiv,
  LabelBtnsDiv,
  ButtonsDiv,
  FlexDiv,
  FlexBetween,
} from "./SurveyForm.style";
import { PrimaryFontBoldText } from "../../elements/fonts/Fonts";
import SelectDate from "../../elements/DatePicker/inputDate";
import Toggle from "../../elements/Toogle/toogle";
import { SvgEyeIcon, SvgSurveyIcon, SvgClose } from "../../elements/Icons";
import CancelButton from "../../elements/StyledButton/CancelButton";
import TwoDatePicker from "../../elements/TwoDatePicker/TwoDatePicker";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import { useStateContext } from "../../contexts/ContextProvider";
import SelectInput from "../../elements/nestedSelect/SelectInput";
import LoaderThreeDot from "../loader/loaderThreeDot/LoaderThreeDot";
import { useMyContext } from "../../contexts/CourseCreatorContext";
import { ContentFormProps } from "../../interfaces/ContentFormProps";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { Container } from "../../elements/StyledContainerForm/StyledContainerForm.style";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import StyledInput from "../../elements/StyledInput/StyledInput";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { WebSocketConnector } from "../../Views/messages/webSocketConnection";
import NestedSelect from "../../elements/nestedSelect/NestedSelect";
import { WebsocketContext } from "../../contexts/notificationContext";
const SurveyForm: FC<ContentFormProps> = ({
  setShow,
  handleSubmit,
  setShowEditContent,
  showEditContent,
  setContentTitleInTopic,
  editContent,
  newContentTitle,
  contentId,
  activeGetModulesWithTopics,
  newTopicRefId,
}) => {
  const { t } = useTranslation();
  const {
    setGlobalShowPopup,
    globalsurveyName,
    globaldescription,
    globalendDate,
    globalfromDate,
    globalisAnonymous,
    globalisTimeVisible,
    globalisVisible,
    globalselectedSurveyId,
    globalstartDate,
    globaltoDate,
    globalselectedSurvey,
    globalSurveyDuration,
    setGlobalSelectedSurvey,
    setGlobalDescription,
    setGlobalEndDate,
    setGlobalFromDate,
    setGlobalIsAnonymous,
    setGlobalIsTimeVisible,
    setGlobalIsVisible,
    setGlobalSelectedSurveyId,
    setGlobalStartDate,
    setGlobalSurveyName,
    setGlobalToDate,
    setEditSurvey,
    setGlobalSurveyDuration,
  } = useMyContext();
  const navigate = useNavigate();
  const classSourceId = useParams();
  const setClassSourceId = classSourceId.id;
  const { isLoading, user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const baseURL = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";
  const path2 = "survey/template";
  const path = "activity/create";
  const tenantName = localStorage.getItem("tenant");
  const {
    setContent_Title,
    contentID,
    topicRefId,
    contentType,
    setContent_Data,
    getContent_Data,
    contentData,
    contentIdToEdit,
    content_Index,
    setGlobalShowEditContent,
    globalShowEditContent,
    setIsPopUpOpen,
  } = useStateContext();

  const [prevData, setPrevData] = useState<any>();
  const [readyToEdit, setReadyToEdit] = useState(true);
  const [foundRefIdState, setFoundRefIdState] = useState<number>();
  const [surveyName, setSurveyName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [isVisible, setIsVisible] = useState(true);
  const [isTimeVisible, setIsTimeVisible] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [error, setError] = useState(false);
  const [surveyError, setSurveyError] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState("");
  const [selectedSurveyId, setSelectedSurveyId] = useState(0);
  const [duration, setDuration] = useState("");
  const [loading, setLoading] = useState(false);
  const [surveyOptions, setSurveyOptions] = useState<
    { title: string; submenu?: any }[]
  >([]);
  const { isReady, value, send } = useContext(WebsocketContext);

  const formatDate = (dueDate: any) => {
    const date = new Date(dueDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Function to update form
  const updateSurvey = async (path: string, body: any) => {
    try {
      const response = await axios.put(`${baseURL}/${path}/${tenantName}`, {
        data: body,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
      throw error; // Rethrow the error to be handled by the calling service
    }
  };

  // Function to get data
  const getSurveyToEdit = async (path: string, surveyId: number) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `${baseURL}/${path}/${surveyId}/${tenantName}`
      );
      setPrevData(res.data.data);
      setReadyToEdit(false);
      const toReturn: any[] = res.data.data;
      return toReturn;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      (showEditContent || globalShowEditContent) &&
      surveyName !== "" &&
      globalsurveyName !== ""
    ) {
      setReadyToEdit(false);
    }
  }, [surveyName, globalsurveyName]);

  // This useEffect hook is used to fetch data when showEditContent is true.
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const yourArray = await activeGetModulesWithTopics();
      const solutionArray: any[] = Array.isArray(yourArray) ? yourArray : [];
      function findRefIdInModules(
        modulesArray: any[],
        targetContentId: number
      ) {
        for (let i = 0; i < modulesArray.length; i++) {
          const topicsArray = modulesArray[i].topicsArray;

          for (let j = 0; j < topicsArray.length; j++) {
            const contentsArray = topicsArray[j].contents;

            for (let k = 0; k < contentsArray.length; k++) {
              if (contentsArray[k].contentId === targetContentId) {
                return contentsArray[k].refId;
              }
            }
          }
        }
        // Return a default value (e.g., null) if the contentId is not found
        return null;
      }
      if (contentIdToEdit) {
        const contentIdToFind = contentIdToEdit; // Replace with the specific contentId you're looking for
        const foundRefId = findRefIdInModules(solutionArray, contentIdToFind);
        setFoundRefIdState(foundRefId);

        await getSurveyToEdit("activity/survey", foundRefId);
        setLoading(false);
      }
    };
    if (showEditContent || globalShowEditContent) {
      fetchData();
      // setReadyToEdit(false);
    }
  }, []);

  // This useEffect hook is used to update the states with edit data (prevData)
  useEffect(() => {
    if (prevData && showEditContent) {
      setContent_Title(prevData.title);
      setSurveyName(prevData.title);
      setGlobalSurveyName(prevData.title);
      setDescription(prevData.description);
      setGlobalDescription(prevData.description);
      setStartDate(
        prevData.startDateTime ? new Date(prevData.startDateTime) : null
      );
      setGlobalStartDate(
        prevData.startDateTime ? new Date(prevData.startDateTime) : null
      );
      setEndDate(prevData.endDateTime ? new Date(prevData.endDateTime) : null);
      setGlobalEndDate(
        prevData.endDateTime ? new Date(prevData.endDateTime) : null
      );
      setSelectedSurveyId(prevData.surveyId);
      setGlobalSelectedSurveyId(prevData.surveyId);

      const foundSurvey = surveyOptions.find(
        (survey) =>
          survey.submenu &&
          survey.submenu.find((item: any) => item.id === prevData.surveyId)
      );
      if (foundSurvey) {
        const selectedSurvey = foundSurvey.submenu.find(
          (item: any) => item.id === prevData.surveyId
        );
        setSelectedSurvey(selectedSurvey.title);
        setGlobalSelectedSurvey(selectedSurvey.title);
      }

      setIsVisible(prevData.isVisible);
      setGlobalIsVisible(prevData.isVisible);
      setIsTimeVisible(
        prevData.visibleFrom === null || prevData.visibleTo === null
          ? false
          : true
      );
      setGlobalIsTimeVisible(
        prevData.visibleFrom === null || prevData.visibleTo === null
          ? false
          : true
      );
      setFromDate(prevData.visibleFrom ? new Date(prevData.visibleFrom) : null);
      setGlobalFromDate(
        prevData.visibleFrom ? new Date(prevData.visibleFrom) : null
      );
      setToDate(prevData.visibleTo ? new Date(prevData.visibleTo) : null);
      setGlobalToDate(prevData.visibleTo ? new Date(prevData.visibleTo) : null);
      setIsAnonymous(prevData.allowAnonymous);
      setGlobalIsAnonymous(prevData.allowAnonymous);
    }
  }, [prevData,surveyOptions]);

  // Function to handle description
  const handleDescriptionChange = (value: any) => {
    setDescription(value);
    setGlobalDescription(value);
  };

  const handleToggleChangeVisible = (isChecked: boolean) => {
    setIsVisible(isChecked);
    setGlobalIsVisible(isChecked);
  };

  const handleToggleChangeTime = (isChecked: boolean) => {
    setIsTimeVisible(isChecked);
    setGlobalIsTimeVisible(isChecked);
  };

  const handleToggleChangeAnonymous = (isChecked: boolean) => {
    setIsAnonymous(isChecked);
    setGlobalIsAnonymous(isChecked);
  };

  const handleTwoDatePickerChange = (start: Date | null, end: Date | null) => {
    setFromDate(start);
    setGlobalFromDate(start);
    setToDate(end);
    setGlobalToDate(end);
  };

  const handleStartDate = (startDateTime: Date | null) => {
    setStartDate(startDateTime);
    setGlobalStartDate(startDateTime);
    setFromDate(startDateTime);
    setGlobalFromDate(startDateTime);
  };
  const handleEndDate = (endDateTime: Date | null) => {
    setEndDate(endDateTime);
    setToDate(endDateTime);
    setGlobalToDate(endDateTime);
    setGlobalEndDate(endDateTime);
  };

  // Function to set the name of selected survey
  const handleSelectSurvey = (value: any) => {
    setSelectedSurvey(value);
    setGlobalSelectedSurvey(value);
  };

  // Function to set the id of selected survey
  const handleSelectSurveyId = (value: any) => {
    setSelectedSurveyId(value);
    setGlobalSelectedSurveyId(value);
  };

  const handleDurationChange = (e: any) => {
    const value = e.target.value;
    if (value === "" || (value >= 0 && /^\d+(\.\d{0,2})?$/.test(value))) {
      setDuration(value);
      setGlobalSurveyDuration(value);
    }
  };

  // Fetch survey templates from the server and update the options for SelectInput component.
  useEffect(() => {
    const getSurveyTemplate = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/${path2}/${setClassSourceId}/${tenantName}`
        );
        const quizDataArray = response.data.data || [];

        // Transform the quiz data array into the format expected by SelectInput
        const transformedOptions: {
          title: string;
          value: string;
          submenu?: any;
        }[] = [
          {
            title: `${t("survey.Use Existing Survey")}`,
            value: "Use Existing Survey",
            submenu: quizDataArray.map((quiz: any) => ({
              title: quiz.title,
              id: quiz.id,
            })),
          },
          {
            title: `${t("forum.create a new one")}`,
            value: "Create a new one",
          },
        ];

        setSurveyOptions(transformedOptions);
      } catch (error) {
        console.error("Axios Error:", error);
      }
    };

    getSurveyTemplate();
  }, [setClassSourceId, tenantName]);

  // Function to handle editing questions in a survey.
  // Checks if a survey is selected, if not, sets an error flag.
  // If selected, navigates to the createSurvey page with the selected source ID,
  // sets edit mode to true, and resets any survey error flags.
  const handleEditQuestions = () => {
    if (!globalselectedSurvey) {
      setSurveyError(true);
    } else {
      navigate(`/createSurvey/${setClassSourceId}`);
      setEditSurvey(true);
      setSurveyError(false);
    }
  };

  // Function for validation and post data
  const handleContinueClick = async () => {
    if (
      globalsurveyName.trim() === "" ||
      globaldescription.trim() === "" ||
      !globalselectedSurvey ||
      (globalisTimeVisible &&
        (!globalfromDate || !globaltoDate) &&
        (!globalstartDate || !globalendDate)) ||
      (globalstartDate && globalendDate && globalstartDate >= globalendDate) ||
      (!globalstartDate && globalendDate)
    ) {
      setError(true);
      setGlobalShowPopup(true);
    } else {
      setError(false);
      if (contentId !== undefined && newContentTitle !== undefined)
        editContent(contentId, newContentTitle);
      setShowEditContent(false);
      setGlobalShowEditContent(false);

      if (!showEditContent || !globalShowEditContent) {
        handleSubmit();
      }

      const surveyData: any = {
        _id: contentIdToEdit,
        id: globalShowEditContent ? foundRefIdState : null,
        classSourceId: setClassSourceId,
        userSourceId: subId,
        provider:provider,
        contentIndex: content_Index,
        topicRefId: topicRefId,
        type: contentType,
        title: globalsurveyName,
        description: globaldescription,
        selectedSurvey: globalselectedSurvey,
        selectedSurveyId: globalselectedSurveyId,
        startDate: globalstartDate,
        endDate: globalendDate,
        visibleStudents: globalisVisible,
        showTimeVisibility: globalisTimeVisible,
        fromDate:
          isTimeVisible || globalisTimeVisible
            ? fromDate || globalfromDate
            : null,
        toDate:
          isTimeVisible || globalisTimeVisible ? toDate || globaltoDate : null,
        anonymous: globalisAnonymous,
        surveyDuration: globalSurveyDuration,
      };

      try {
        if (!globalShowEditContent) {
          const response = await axios.post(
            `${baseURL}/${path}/${tenantName}`,
            { data: surveyData }
          );
          if (isReady && send) {

            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "create",
                  title: globalsurveyName,
                  contentType: "Survey",
                  refId: response.data.data,
                  refTable: "SurveyActivities",
                  subId: subId,
                  provider:provider,
                  type: "content",
                  description: globalendDate
                    ? `Due Date: ${formatDate(globalendDate)}`
                    : "",
                },
              })
            );
          }
        } else if (globalShowEditContent) {
          await updateSurvey("activity/survey/update", surveyData);
          if (isReady && send) {
            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "update",
                  title: globalsurveyName,
                  contentType: "Survey",
                  subId: subId,
                  provider:provider,
                  refId: foundRefIdState,
                  refTable: "SurveyActivities",
                  type: "content",
                  description: globalendDate
                    ? `Due Date: ${formatDate(globalendDate)}`
                    : "",
                },
              })
            );
          }
        }
        setIsPopUpOpen(false);
        setGlobalShowPopup(false);
        await activeGetModulesWithTopics();
      } catch (error) {
        console.error("Axios Error:", error);
      } finally {
        await (activeGetModulesWithTopics && activeGetModulesWithTopics());
      }
    }
  };
  const { backgroundColor, themeTextColor, inputBorderColor, iconCloseColor } =
    useRecoilValue(ColorSelector);
  return (
    <Container
      themetextcolor={themeTextColor}
      backgroundcolor={backgroundColor}
      scrollbar={inputBorderColor}
    >
      {/* Header */}
      {/* <FlexBetween>
        <FlexDiv>
          <SvgSurveyIcon />
          <PrimaryFontBoldText text={t("survey.Survey")} fontSize="1.125rem" />
        </FlexDiv> */}

        {/* <SvgClose
          color={iconCloseColor}
          onClick={() => {
            setShow(false);
            setIsPopUpOpen(false);
            setGlobalShowPopup(false);
            setShowEditContent(false);
            setGlobalShowEditContent(false);
          }}
          style={{ cursor: "pointer" }}
        /> */}
      {/* </FlexBetween> */}

      {/* Title */}
      {showEditContent && readyToEdit && <LoaderThreeDot />}
      <LabelDiv>
        <FlexDiv gap="0.25rem">
          <LightTypography text={t("forms.Title")} fontSize="0.875rem" />
          <span>*</span>
        </FlexDiv>
        <StyledInput
          type="text"
          placeholder={t("survey.Enter survey Title")}
          onChange={(e: any) => {
            setSurveyName(e.target.value);
            setGlobalSurveyName(e.target.value);
            setContent_Title(e.target.value);
            setContentTitleInTopic(e.target.value);
          }}
          value={surveyName || globalsurveyName}
          width="100%"
        />
        {!globalsurveyName && error && (
          <ErrorDiv>{`${t("forms.Please")} ${t(
            "survey.Enter survey Title"
          )}`}</ErrorDiv>
        )}
      </LabelDiv>

      {/* Text editor for the forum description */}
      <LabelDiv>
        <FlexDiv gap="0.25rem">
          <LightTypography text={t("forms.Description")} fontSize="0.875rem" />
          <span>*</span>
        </FlexDiv>
        <TinyEditor
          onChange={handleDescriptionChange}
          placeholder={t("forms.Description")}
          initialValue={`${description || globaldescription}`}
          height="13.25rem"
        />
      </LabelDiv>
      {!globaldescription && error && (
        <ErrorDiv>{`${t("forms.Please")} ${t(
          "forms.Enter Description"
        )}`}</ErrorDiv>
      )}

      {/* Select survey */}
      <FlexDiv gap="0.25rem">
        <LightTypography text={t("survey.Survey")} fontSize="0.875rem" />
        <span>*</span>
        <SvgEyeIcon
          onClick={handleEditQuestions}
          style={{ color: "#5DD3B3", cursor: "pointer" }}
        />
      </FlexDiv>
      <NestedSelect
        onChange={handleSelectSurvey}
        onIdChange={handleSelectSurveyId}
        placeholder={t("survey.Use Survey")}
        width="27.5rem"
        options={surveyOptions}
        value={globalselectedSurvey}
        redirectUrl={`/createSurvey/${setClassSourceId}`}
      />
      {!globalselectedSurvey && (error || surveyError) && (
        <ErrorDiv>{t("course view.Select Survey")}</ErrorDiv>
      )}

      {/* Date and time section */}
      <DateAndTimeDiv>
        <LabelDiv>
          <LightTypography text={t("forms.Start Date")} fontSize="0.875rem" />
          <SelectDate
            value={globalstartDate}
            onChange={handleStartDate}
            width="27.5rem"
          />
        </LabelDiv>

        <LabelDiv>
          <LightTypography text={t("forms.End Date")} fontSize="0.875rem" />
          <SelectDate
            value={globalendDate}
            onChange={handleEndDate}
            width="27.5rem"
          />
        </LabelDiv>
      </DateAndTimeDiv>
      {globalstartDate &&
        globalendDate &&
        globalstartDate >= globalendDate &&
        error && (
          <ErrorDiv>
            {t("course view.End date must be greater than the start date.")}
          </ErrorDiv>
        )}
      {!globalstartDate && globalendDate && (
        <ErrorDiv>{t("course view.Please Select Start Date.")}</ErrorDiv>
      )}

      {/* duration */}
      {/* <DateAndTimeDiv>
        <LabelDiv>
          <LightTypography
            text={t("quiz.Duration (in minutes)")}
            fontSize="0.875rem"
          />
          <StyledInput
            type="number"
            placeholder={t("quiz.Enter Duration")}
            width="27.5rem"
            onChange={handleDurationChange}
            value={duration || globalSurveyDuration}
          />
        </LabelDiv>
      </DateAndTimeDiv> */}

      {/* Toggles */}
      <BtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleChangeVisible}
            checked={globalisVisible}
            id="globalisVisible"
          />
          <TypographyRegularDescription
            text={t("forms.Make Visible for Students")}
            fontSize="1rem"
          />
        </LabelBtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleChangeTime}
            checked={globalisTimeVisible}
            id="globalisTimeVisible"
          />
          <TypographyRegularDescription
            text={t("forms.Set Time Visibility Interval")}
            fontSize="1rem"
          />
          {globalisTimeVisible && (
            <>
              <TwoDatePicker
                prevStartDate={globalfromDate}
                prevEndDate={globaltoDate}
                onDateChange={handleTwoDatePickerChange}
              />
              {(!globalfromDate || !globaltoDate) &&
                (!globalstartDate || !globalendDate) &&
                error && (
                  <ErrorDiv>{`${t("forms.Please")} ${t(
                    "forms.Set Time Visibility Interval"
                  )}`}</ErrorDiv>
                )}
            </>
          )}
        </LabelBtnsDiv>
        {/* <LabelBtnsDiv>
          <Toggle onToggle={handleToggleChangeAnonymous} checked={globalisAnonymous} id="roleToggle3" />
          <TypographyRegularDescription text="Anonymous" fontSize="1rem" />
        </LabelBtnsDiv> */}
      </BtnsDiv>

      {/* Action Buttons */}
      <ButtonsDiv>
        <CancelButton
          name={t("forms.Cancel")}
          onClickFunction={() => {
            setIsPopUpOpen(false);
            setGlobalShowPopup(false);
            setShowEditContent(false);
            setGlobalShowEditContent(false);
          }}
          color="#D85D66"
          borderColor="#D85D66"
          backgroundColor="transparent"
        />
        {loading ? (
          <CancelButton
            name={`${t("general.loading")}...`}
            onClickFunction={() => {}}
            disabled={true}
            color="#fff"
            borderColor="#5DD3B3"
            backgroundColor="#5DD3B3"
          />
        ) : (
          <CancelButton
            name={t("forms.Save")}
            onClickFunction={() => {
              handleContinueClick();
              setContent_Title(globalsurveyName);
            }}
            color="#fff"
            borderColor="#5DD3B3"
            backgroundColor="#5DD3B3"
          />
        )}
      </ButtonsDiv>
    </Container>
  );
};

export default SurveyForm;
