import styled from "styled-components";

export const FolderRecoursesLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.25rem;
`;

export const FolderRecoursesContainer = styled.div<{ theme?: string }>`
  display: flex !important;
  flex-direction:row !important;
  border-radius: 0.625rem;
  background: rgba(255, 255, 255, 0.03);
  width: 100%;
  justify-content: space-between;
  padding: 0.63rem 1.25rem;
  align-items: center;
  box-shadow: ${props => props.theme === "light" ? "0px 4px 14px 0px rgba(192, 220, 214, 0.25)" : ""};
  /* flex-wrap: wrap; */
`;

export const FRCLeft = styled.div`
  display: flex;
  width: 5rem;
  height: 4.5rem;
  flex-shrink: 0;
  align-items: center;
  gap: 0.62rem;
  /* background-color:red; */
  width: auto !important;

`;

// export const FRCLeft =styled.div`
// display:flex;
// width: 5rem;
// height: 4.5rem;
// flex-shrink: 0;
// align-items:center;
// gap:1.25rem;
// `;
