import React,{FC} from 'react';
import { GraderDiscussionProps } from '../GraderDiscussionInterface';
import { DiscussionContainer,DiscussionContentContainer,MessageContainer,ImageElment,MainContainer } from './GraderDiscussion.style';
import { Typography,LightTypography } from '../../../elements/fonts/Fonts';
import { theme } from '../../../theme';
 const GraderDiscussion:FC<GraderDiscussionProps> = ({discussionData}) => {
    
    return( 
        <MainContainer>
        <DiscussionContainer>
    {discussionData.map((discussion,index)=>(
     <DiscussionContentContainer key={index}>
      <ImageElment src={discussion.image} alt='student'/>
      <MessageContainer>
      <Typography text={discussion.name} fontSize='1rem' color={index % 2 === 0 ? theme.dark.primary_text_color : '#0B8FFF'}  />
      <LightTypography text={discussion.content} fontSize='0.875rem' color={index % 2 === 0 ? theme.dark.primary_text_color : '#0B8FFF'} />
      </MessageContainer>
     </DiscussionContentContainer>

    ))
    }
    </DiscussionContainer>
    </MainContainer>
    )
}

export default GraderDiscussion;