import React, { useState } from "react";
import { FlexAppContainer } from "../../../../App.style";
import AnalyticsBackPage from "../../../../components/AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticsBackPage";
import { Typography } from "../../../../elements/fonts/Fonts";
import { useNavigate, useParams } from "react-router-dom";
import ScrumDetailTableCompo from "../../../../components/Tables/ScrumDetailTable/main/ScrumDetailTable";
import ScormHeaderTitle from "../../../../components/AnalyticShadowHeaderFourDisplays/ScormHeaderTitle";
import { ScrumDetailTableData } from "../../../../components/Tables/ScrumDetailTable/ScrumDetailTableData";
import AnalyticOneTitleHeader from "../../../../components/AnalyticShadowHeaderFourDisplays/AnalyticOneTitleHeader";
import AnalyticsAssignmentBarChart from "../../../../components/AnalyticsAssigmentResponseTraking/AnalyticsAssignmentBarChart/AnalyticsAssignmentBarChart";
import AnalyticsAssignmentGraphChart from "../../../../components/AnalyticsAssigmentResponseTraking/AnalyticsAssigmentGraphChart/AnalyticsAssigmentGraphChart";
import AnalyticsScormPieAndProggress from "../../../../components/AnalyticsScormPieandProggress/AnalyticsScormPieandProggress";

const AnalyticsScormDetails = () => {
  const navigate = useNavigate();
  const NavigatePage = () => {
    navigate(-1);
  };
  const { MainCode } = useParams<{ MainCode: string }>();

  const ScormData = ScrumDetailTableData;
  const StudentData = ScrumDetailTableData.Students;
  const NumberOfStudents = StudentData.length;
  const [ScormDetails, setScormDetails] = useState(false);
  const [ScormGrade, setScormGrade] = useState(false);
  const [ScormTracking, setScormTracking] = useState(false);
  const handleShowScormDetails = () => {
    setScormDetails(!ScormDetails);
  };
  const handleShowScormGrade = () => {
    setScormGrade(!ScormGrade);
  };

  const handleShowScormTacking = () => {
    setScormTracking(!ScormTracking);
  };
  const CompletedAttempts =
    StudentData?.filter(
      (item) =>
        item.status &&
        item.status !== null &&
        item.status !== undefined &&
        ((typeof item.status === "boolean" && item.status === true) ||
          item.status === "Completed" ||
          item.status === "completed")
    )?.length || 0;
  const TotalNumberOfSCors = StudentData.reduce(
    (sum, student) => sum + (student.Grade || 0), // Ensure Posts is a number and handle undefined
    0
  );
  const TotalNumberOfTimesSpent = StudentData.reduce(
    (sum, student) => sum + (student.TimeSpent || 0), // Ensure Posts is a number and handle undefined
    0
  );
  const ResponsesStudents =
    StudentData?.filter(
      (item) =>
        item.status !== null &&
        item.status !== undefined &&
        ((typeof item.status === "boolean" && item.status === true) ||
          item.status === "Completed" ||
          item.status === "completed") &&
        item.ResponsesDate
    )?.length || 0;
  return (
    <FlexAppContainer column={true} padding="0 0 2rem 0" gap="1.25rem">
      <AnalyticsBackPage onClick={NavigatePage} />
      <Typography text="Scorm Analytics" fontSize="1.75rem" />
      <ScormHeaderTitle
        Graded={ScormData.Graded}
        CompletedAttempts={`${CompletedAttempts} / ${NumberOfStudents}`}
        AverageScore={(TotalNumberOfSCors / NumberOfStudents).toFixed(2)}
        Responses={`${ResponsesStudents} of ${NumberOfStudents}`}
        AverageTimesSpent={(TotalNumberOfTimesSpent / NumberOfStudents).toFixed(
          2
        )}
      />
      {ScormTracking ? (
        <AnalyticsScormPieAndProggress
          Data={StudentData}
          onClick={handleShowScormTacking}
        />
      ) : (
        <AnalyticOneTitleHeader
          Title="Responses Tracking"
          onClick={handleShowScormTacking}
        />
      )}

      {ScormDetails ? (
        <ScrumDetailTableCompo
          Data={ScormData}
          handleTextClick={handleShowScormDetails}
        />
      ) : (
        <AnalyticOneTitleHeader
          Title="Scorm Details"
          onClick={handleShowScormDetails}
        />
      )}

      {ScormData.Graded && (
        <>
          {ScormGrade ? (
            <FlexAppContainer column={true} gap="1.25rem" padding="0 0 2rem 0">
              <AnalyticsAssignmentBarChart
                Data={StudentData}
                title="Scorm Distribution"
                titleClick={handleShowScormGrade}
              />
              <AnalyticsAssignmentGraphChart
                title="Scorm Distribution"
                titleClick={handleShowScormGrade}
                Data={StudentData}
                MaxGrade={ScormData.MaxGrade || 20}
              />
            </FlexAppContainer>
          ) : (
            <AnalyticOneTitleHeader
              Title="Participation Tracking"
              onClick={handleShowScormGrade}
            />
          )}
        </>
      )}
    </FlexAppContainer>
  );
};

export default AnalyticsScormDetails;
