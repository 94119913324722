export const publishGrade = (count: number) => {
    if (count >= 97) return "A+";
    else if (count >= 93) return "A";
    else if (count >= 90) return "A-";
    else if (count >= 87) return "B+";
    else if (count >= 83) return "B";
    else if (count >= 80) return "B-";
    else if (count >= 77) return "C+";
    else if (count >= 73) return "C";
    else if (count >= 70) return "C-";
    else if (count >= 67) return "D+";
    else if (count >= 63) return "D";
    else if (count >= 60) return "D-";
    else return "F";
};

export const GradeColorPicker=(grade:number)=>{
    if(grade>=80 )return "#5DD3B3";
    else if (grade>=50) return "#CED35D";
    else if (grade<50) return "#D85D66";
    else return "white"
    }