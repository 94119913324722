import styled from "styled-components";
import { breakpoints } from "../../theme";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";
interface Theme {
  backGroundPosition?: string;
  PaddingLeft?: string;
  BgImage?: string;
  color?: string;
  borderColor?: string;
}


interface CloseInterface extends StyleThemeInterface {
  Close_RightPosition?: string;
  Close_TopPosition?: string;
  height ?:string;
}
export const SearchContainer = styled.div<CloseInterface>`
  position: relative;
  display: flex;
  width: 100%;
  height: ${(props) => props.height || "100%"};
`;

export const SearchInput = styled.input<Theme>`
  width: 100%;
  height: 100%;
  border-radius: 6.25rem;
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.borderColor || "#576371"};
  /* opacity: 0.5; */
  background-color: transparent;
  /* background-image: url(${(props) => props.BgImage}); */
  /* background-repeat: no-repeat; */
  /* background-position: ${(props) =>
    props.backGroundPosition || "1.69rem 1rem"}; */
  &:dir(ltr) {
    padding-left: ${(props) => props.PaddingLeft || "16%"};
  }
  &:dir(rtl) {
    padding-right: ${(props) => props.PaddingLeft || "16%"};
  }
  padding-top: 0.2rem;
  font-size: 0.875rem;
  font-family: ${(props) => props.theme.font.primaryRegular};
  font-style: normal;
  font-weight: 400;
  color: ${(props) => props.color};

  &:focus {
    outline: none;
    caret-color: ${(props) => props.color};
  }
  &::placeholder {
    opacity: 0.5;
  }
`;



export const RemoveTextContainer = styled.div<CloseInterface>`
  position: absolute;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  &:dir(ltr) {
    right: ${(props) => props.Close_RightPosition};
  }
  &:dir(rtl) {
    left: ${(props) => props.Close_RightPosition};
  }
  background: ${(props) =>
    props.theme === "light" ? "#F6F6F600" : "#2D374100"};
  top: ${(props) => props.Close_TopPosition};
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${(props) => (props.theme === "light" ? "#2d3741" : "#D9D9D9")};
  &:hover {
    background: ${(props) =>
      props.theme === "light" ? "#F6F6F660" : "#2D374160"};
    box-shadow: ${(props) =>
      props.theme === "light"
        ? " 0px 4px 14px 0px rgba(192, 220, 214, 0.32)"
        : "0px 4px 20px 0px rgba(0, 0, 0, 0.25)"};
  }
`;

interface MainHolderProps {
  inputwidth: string;
}
export const MainHolder = styled.div<MainHolderProps>`
  position: relative;
  width: ${({ inputwidth }) => inputwidth};
`;
interface SearchIconHolderProps {
  display?: string;
  left?: string;
  top?: string;
  paddingLeft?: string;
}

export const SearchIconHolder = styled.div<SearchIconHolderProps>`
  position: absolute;
  top: ${(props) => props.top}; /* Set top to 25% of the height of the input */
  /* transform: translateY(-50%); */
  color: #576371;
  width: min-content;
  display: ${({ display }) => display};
  &:dir(ltr) {
    left: ${(props) => props.left};
  }
  &:dir(rtl) {
    right: ${(props) => props.left};
  }
`;
