import styled from "styled-components";

export const SubmittedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  /* background: cadetblue; */
`;

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`;
export const SubmittedFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.62rem;
  margin-top: 1.25rem;
`;
export const HiddenButton = styled.button`
  color: #d85d66;
  border-radius: 1.25rem;
  border: 1px solid #d85d66;
  background-color: transparent;
  width: 5.625rem;
  height: 1.875rem;
`;

export const Assignment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
