import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { LoadingComp } from "../../components/LoadingComp/LoadingComp";

const Callback = () => {
  const basURL = 'https://6g25zsbnm2.execute-api.eu-west-1.amazonaws.com/dev/'
  const { isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
  const navigate = useNavigate();
  const [subId, setSubId] = useState("");
  const[Provider,SetProvider]= useState("");
  const [email,setEmail] = useState("");
  const [tenants, setTenants] = useState<string[]>([]);
  const [tenantsFetched, setTenantsFetched] = useState<boolean>(false);
  

  const fetchClaims = async () => {
    // alert("Fetch Claims Triggered");
    const idTokenClaims = await getIdTokenClaims();
    // alert(`Claims Fetched`)
    // Now you have access to your ID token claims
    if(idTokenClaims){
      const authSub = idTokenClaims.sub;
      const authSubSplit = authSub.split('|');
      SetProvider(authSubSplit[0]);
      setSubId(authSubSplit[1]);
      setEmail(idTokenClaims?.email || "");
    }
  };

  const getUserTenants = async (subId:string,isAzure?:boolean) => {
    if(isAzure){
      const response = await axios.get(`${basURL}user/tenants/${subId}?azureUser=true`);
      setTenants(response.data.data);
    }else{

      const response = await axios.get(`${basURL}user/tenants/${subId}`);
      setTenants(response.data.data);
    }
   
    
  };

  const navigateDecision = () => {
    if (!isLoading && tenantsFetched) {
      if (tenants.length > 1) {
        navigate("/selectTenant");
      } else if (tenants.length === 1) {
        // sessionStorage.setItem('tenant', tenants[0]);
        localStorage.setItem('tenant', tenants[0]);
        navigate("/dashboard");
      }
    }
  };

  const syncUserSub = async (subId:string,email:string) => {
    try {
      const toSend = {
        email : email,
        subId: subId
      }
      const res = await axios.put(`${basURL}user/syncSubId`,{
        data: toSend
      })
    } catch (error:any) {
      console.error(error);
      console.error(error.message);
      navigate("/");
    }
  }
  useEffect(() => {
    if (isLoading) return;

    if (isAuthenticated) {
      fetchClaims().then(() => {

        if(subId && !tenantsFetched){
          if(Provider==="waad"){
            syncUserSub(`waad|${subId}`, email)
            .then(()=>{
              getUserTenants(subId,true)
            })
            .then(() => {
              setTenantsFetched(true);
              navigateDecision(); // Call the navigate decision function after tenants are fetched
            });
          }else{
            getUserTenants(subId)
            .then(() => {
              setTenantsFetched(true);
              navigateDecision(); // Call the navigate decision function after tenants are fetched
            });
          }
          
        }
      });
    } else {
      navigate("/");
    }
  }, [isLoading, isAuthenticated, subId, tenantsFetched]);

  useEffect(() => {
    navigateDecision();
  }, [tenants]);

  return <LoadingComp/>;
};

export default Callback;
