import {
  SvgArrowShock,
  SvgArrowSimple,
  SvgDownload,
  SvgEyeIcon,
  SvgLine,
  SvgSimpleArrowLeft,
  SvgSimpleArrowRight,
} from "../../elements/Icons";
import { useState, useEffect, useRef, Key } from "react";

import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../elements/fonts/Fonts";
import ReusableInput from "../../elements/reusableInput/ReusableInput";
import {
  ActionsDiv,
  CourseDetailsWithSearch,
  CourseNameRow,
  CourseNameWithSemester,
  DivArrowsWrapper,
  FlexedDiv,
  GoBackRow,
  LogoAndAction,
  ProfileImageHolder,
  SearchAndLogosDiv,
  SessionNbWithDate,
  SessionsWrapper,
  TableContent,
  TableContentHolder,
  TableSection,
  TableTbody,
  TableTd,
  TableTd1,
  TableTd3,
  TableTh2,
  TableThead,
  TableTr,
  TableTr1,
  TableTr2,
  ViewAllWithSearchRow,
} from "./AttendanceAllSessions.style";
import {
  CourseData,
  TableTrData,
  TitleData,
} from "./attendanceAllSessionsData";
import SelectInput from "../../elements/SelectInput/SelectInput";
import * as XLSX from "xlsx";
import { useNavigate, useParams } from "react-router-dom";
import {
  countColor,
  downloadExcel,
  formatDateTime,
  getFormattedDateTime,
  handleSelectChangeEnrollment,
  scrollLeft,
  scrollRight,
} from "./AttendanceAllsessionsFunctions";
import axios from "axios";
import React from "react";
import { AttendanceItem, Session } from "./interfaces";
import {
  ArrowHolder,
  BackArrowNameHodler,
} from "../manageWeight/ManageWeight.style";
import { useRecoilValue } from "recoil";
import { ColorSelector, TableColorSelector } from "../../recoil/ThemeSelectors";
import ScrollLeftAndRight from "../../elements/ScrollLeftAndRight/ScrollLeftAndRight";
import { useTranslation } from "react-i18next";
import { formatOptionsForTranslation } from "../../utils/FormatOptionsForTranslation";
import SelectDropDown from "../../elements/selectDropDown/SelectDropDown";
import TitleHeaderTextIconNavigator from "../../elements/TitleHeaderTextIconNavigator/TitleHeaderTextIconNavigator";
import TableViewSelectInput from "../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/TableViewSelectInput";
import {
  ViewsOptionsTranslated,
  handleNumberDisplaysWithoutPagination,
} from "../../utils/NbOfViewesObjects";
import EmptyAllSession from "./EmptyAllSession";
import ProfileImgHolder from "../../elements/ProfileImgHolder/ProfileImgHolder";
import { SeasonTranslate } from "../../utils/TranslationFunc";

interface ClassData {
  classSourceId: string;
  title: string;
  numberOfHours: number;
  startDate: string | null;
  endDate: string | null;
  schedule: Record<string, any>;
  academicSessionTitle: string;
}

interface Course {
  sourceId: string;
  title: string;
  code: string;
  numberOfCredits: number | null;
}

interface ClassStructure {
  classStructureId: number;
  description: string;
  color: string;
  headline: string;
  picture: string;
  pictureName: string;
  syllabus: string | null;
  mainInstructorId: string;
  teamsId: string;
}

interface Teacher {
  sourceId: string;
  username: string;
  email: string;
  profilePicture: string;
  bio: string;
  department: string;
  major: string;
}

interface About {
  classData: ClassData;
  course: Course;
  classStructure: ClassStructure;
  courseObjectives: any[]; // Adjust the type if you have more details on course objectives
  teacher: Teacher[];
}

const AttendanceAllSessions = () => {
  const [formInputValue, setFormInputValue] = useState("");
  const [currentStudent, setCurrentStudent] = useState(TableTrData);
  const [limit, setLimit] = useState(TableTrData.length); // Initialize limit with 10
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [About, SetAbout] = useState<About>();

  const id = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickOnSpecificSession = (sessionId: number) => {
    navigate(
      `/courseview/GradeBook/${id.classId}/generateAttendance/${sessionId}`
    ); // Navigating to another route when the div is clicked
  };
  const columnWidth = 378;
  const [sessionsData, setSessionsData] = useState<Session[]>([]);

  const [participantData, setParticipantData] = useState([]);
  const [AttendanceData, setAttendanceData] = useState<AttendanceItem[]>([]);

  const tenantName = localStorage.getItem("tenant"); // Replace 'yourTenantName' with the actual tenant name
  const classSourceId = useParams();

  const handleFormInputChange = (value: string) => {
    setFormInputValue(value);
  };

  const { SecondaryTextColor, SecondaryWhiteTextColor, specialColor2, theme } =
    useRecoilValue(ColorSelector);
  const { Table_Head_TR, Table_nth_child } = useRecoilValue(TableColorSelector);

  const fetchSessionsData = async () => {
    try {
      const response = await axios.get(
        `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/sessions/${tenantName}/${classSourceId.classId}`
      );
      const sortedSessions = response.data.data.sessions.sort(
        (a: any, b: any) => {
          // Extract numbers from session titles
          const regex = /(\d+)/;
          const aNumber = parseInt(a.title.match(regex)[0]);
          const bNumber = parseInt(b.title.match(regex)[0]);

          return aNumber - bNumber;
        }
      );
      setSessionsData(sortedSessions);
      setAttendanceData(response.data.data.attendance);
    } catch (error) {
      console.error("Error fetching sessions data:", error);
    }
  };

  useEffect(() => {
    fetchParticipantsData();
    fetchSessionsData();
    getAboutClassData(classSourceId.classId);
  }, []);

  function generateAttendanceSheet(
    sessions: any[],
    attendances: any[],
    participants: any[]
  ) {
    const workbook = XLSX.utils.book_new();

    // Create a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([]);

    // Create headers
    const headers = ["Student"];
    const sessionIds: any[] = [];
    sessions.forEach((session: { title: string; id: any }) => {
      headers.push(session.title);
      sessionIds.push(session.id);
    });

    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });

    // Populate attendance data
    participants.forEach(
      (participant: { username: any; sourceId: any }, index: number) => {
        const row = [participant.username];
        sessionIds.forEach((sessionId) => {
          const attendance = attendances.find(
            (attendance: { sessionId: any; userSourceId: any }) =>
              attendance.sessionId === sessionId &&
              attendance.userSourceId === participant.sourceId
          );
          row.push(attendance ? attendance.status : "Absent");
        });
        XLSX.utils.sheet_add_aoa(worksheet, [row], { origin: `A${index + 2}` });
      }
    );

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Attendance");

    // Write workbook to file
    XLSX.writeFile(workbook, "attendance.xlsx");
  }

  const fetchParticipantsData = async () => {
    try {
      const response = await axios.get(
        `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/participant/class/${classSourceId.classId}/${tenantName}`
      );
      setParticipantData(response.data.data);
    } catch (error) {
      console.error("Error fetching sessions data:", error);
    }
  };

  useEffect(() => {
    if (tableContainerRef.current) {
      const newScrollPosition = currentIndex * columnWidth;
      tableContainerRef.current.scrollLeft = newScrollPosition;
      setScrollPosition(newScrollPosition);

      // Check if you can scroll left
      if (newScrollPosition <= 0) {
        setCanScrollLeft(false);
      } else {
        setCanScrollLeft(true);
      }

      // Check if you can scroll right
      if (
        tableContainerRef.current.scrollWidth - newScrollPosition <=
        tableContainerRef.current.clientWidth
      ) {
        setCanScrollRight(false);
      } else {
        setCanScrollRight(true);
      }
    }
  }, [currentIndex]);
  // const filteredStudents = formInputValue.length > 0 ? currentStudent : currentStudents;
  const filteredParticipantData = participantData.filter(
    (student: any) =>
      student.role === "Student" &&
      student.username.toLowerCase().includes(formInputValue.toLowerCase())
  );
  const [ViewText, setViewText] = useState("View All");
  const handleNumberShows = (newValue: string) => {
    handleNumberDisplaysWithoutPagination(
      newValue,
      setViewText,
      setLimit,
      filteredParticipantData
    );
  };
  const translatedEnrollmentOptions = ViewsOptionsTranslated();
  const BaseURI = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";

  const getAboutClassData = async (classSourceId: any) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `${BaseURI}/about/class/${classSourceId}/${tenantName}`
      );
      SetAbout(res.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const language = localStorage.getItem("language");
  return (
    <SessionsWrapper>
      <CourseDetailsWithSearch>
        <CourseNameWithSemester>
          <TitleHeaderTextIconNavigator
            title={
              About?.classData && About && About.classData.title
                ? About.classData.title
                : t("general.loading")
            }
            navigateTo={`/courseview/Gradebook/ManageWeightView/${id.classId}`}
          />
          <CourseNameRow>
            <LightTypography
              color={SecondaryWhiteTextColor}
              text={SeasonTranslate(
                About?.classData.academicSessionTitle || "",
                t
              )}
              fontSize="1.125rem"
            />
          </CourseNameRow>
          <LightTypography
            color={SecondaryWhiteTextColor}
            fontSize="0.75rem"
            text={t(
              "gradebook.Kindly select a specific session number from the first row to fill your session attendance table"
            )}
          />
        </CourseNameWithSemester>
        <SearchAndLogosDiv>
          <ReusableInput
            left="1.69rem"
            holderWidth=""
            placeHolder={t(`dashboard.Search`)}
            padingLeft="3.31rem"
            inputBorderRadius="6.25rem"
            inputWidth="23.625rem"
            inputHeight="3.125rem"
            display="block"
            onInputChange={(newValue: string) =>
              handleFormInputChange(newValue)
            }
            value={formInputValue}
          />
          {sessionsData.length > 0 && (
            <ActionsDiv style={{ color: specialColor2 }}>
              <LogoAndAction>
                <TableViewSelectInput
                  value={t(ViewText)}
                  options={translatedEnrollmentOptions}
                  onChange={handleNumberShows}
                />
              </LogoAndAction>
              <LogoAndAction>
                <SvgDownload
                  onClick={() =>
                    generateAttendanceSheet(
                      sessionsData,
                      AttendanceData,
                      participantData
                    )
                  }
                />
                <Typography text={t(`gradebook.Download`)} fontSize="0.75rem" />
              </LogoAndAction>
            </ActionsDiv>
          )}
        </SearchAndLogosDiv>
      </CourseDetailsWithSearch>
      {sessionsData.length === 0 ? (
        <EmptyAllSession />
      ) : (
        <ScrollLeftAndRight
          content={
            <TableSection
            // ref={tableContainerRef}
            // onScroll={(e) => setScrollPosition(e.currentTarget.scrollLeft)}
            >
              <TableContentHolder>
                <TableContent>
                  <TableThead
                    style={{
                      background:
                        language === "ar" && theme === "dark"
                          ? "linear-gradient(90deg, #293440 0%,  #415160  107.88%)"
                          : Table_Head_TR,
                      height: "5.1875rem",
                    }}
                  >
                    <TableTd1>{t(`gradebook.Student ID`)}</TableTd1>
                    <TableTd1>{t(`participants.Students`)}</TableTd1>
                    {sessionsData &&
                      sessionsData.map((item: Session, index: number) => (
                        <TableTd key={item.sessionId}>
                          <TableTh2
                            style={{
                              cursor: "pointer",
                              textAlign: `${
                                language === "ar" ? "right" : "left"
                              }`,
                              // background: "aqua",
                            }}
                            onClick={
                              item.id !== undefined
                                ? () => handleClickOnSpecificSession(item.id!)
                                : undefined
                            }
                          >
                            <MediumTypography
                              text={
                                `${t("gradebook.Session")} ${index + 1}` ??
                                "No session available"
                              }
                              fontSize={index === 0 ? "1rem" : "1.125rem"}
                              lineHeight="1.75rem"
                              whiteSpace="nowrap"
                            />
                            <SessionNbWithDate>
                              <LightTypography
                                text={getFormattedDateTime(
                                  item.startDateTime,
                                  item.endDateTime,
                                )}
                                fontSize="0.75rem"
                                lineHeight="1.75rem"
                                whiteSpace="nowrap"
                              />
                              <LightTypography
                                text={item.time ?? ""}
                                fontSize="0.75rem"
                                lineHeight="1.75rem"
                                whiteSpace="nowrap"
                              />
                            </SessionNbWithDate>
                          </TableTh2>
                          <SvgLine />
                        </TableTd>
                      ))}
                  </TableThead>

                  <TableTbody>
                    {/* Map through the participant data */}
                    {participantData &&
                      filteredParticipantData.map(
                        (student: any) =>
                          student.role === "Student" && (
                            <TableTr
                              nth_child_bgcolor={Table_nth_child}
                              key={student.sourceId}
                            >
                              <FlexedDiv>
                                <TableTd
                                  style={{
                                    alignItems: "center",
                                    paddingLeft: `${
                                      language === "ar" ? "" : "0.8rem"
                                    }`,
                                    paddingRight: `${
                                      language === "ar" ? "0.8rem" : ""
                                    }`,
                                    width: "10rem",
                                  }}
                                >
                                  {/* <ProfileImgHolder imgSrc={student.profilePicture}  
                                Size="2.125rem"/> */}
                                  <MediumTypography
                                    text={student.sourceId ?? ""}
                                    fontSize="0.875rem"
                                  />
                                </TableTd>
                                <TableTd
                                  style={{
                                    alignItems: "center",
                                    paddingLeft: `${
                                      language === "ar" ? "" : "0.8rem"
                                    }`,
                                    paddingRight: `${
                                      language === "ar" ? "0.8rem" : ""
                                    }`,
                                  }}
                                >
                                  {/* <ProfileImgHolder imgSrc={student.profilePicture}  
                                 Size="2.125rem"/> */}
                                  <MediumTypography
                                    text={student.username ?? ""}
                                    fontSize="0.875rem"
                                  />
                                </TableTd>
                                {/* Map through sessionsData to display attendance status */}

                                {sessionsData.map((session: Session) => {
                                  // Find attendance data for the current session and student
                                  const attendance = AttendanceData.find(
                                    (a: AttendanceItem) =>
                                      a.userSourceId === student.sourceId &&
                                      a.sessionId === session.id
                                  );

                                  // Check if attendance data exists
                                  if (attendance) {
                                    // Display the attendance status for each session
                                    return (
                                      <TableTd
                                        style={{
                                          justifyContent: `${
                                            language === "ar" ? "right" : "left"
                                          }`,

                                          // background: "red",
                                          paddingLeft: `${
                                            language === "ar" ? "" : "2.1rem"
                                          }`,
                                          paddingRight: `${
                                            language === "ar" ? "2.1rem" : ""
                                          }`,
                                        }}
                                        key={session.id}
                                      >
                                        <MediumTypography
                                          text={t(
                                            `gradebook.${attendance.status}`
                                          )}
                                          fontSize="0.875rem"
                                          color={countColor(
                                            attendance.statusId.toString(),
                                            theme
                                          )}
                                        />
                                      </TableTd>
                                    );
                                  } else {
                                    // Handle case where attendance data is not found
                                    return (
                                      <TableTd
                                        key={session.sessionId}
                                        style={{
                                          justifyContent: `${
                                            language === "ar" ? "right" : "left"
                                          }`,

                                          // background: "red",
                                          paddingLeft: `${
                                            language === "ar" ? "" : "2.1rem"
                                          }`,
                                          paddingRight: `${
                                            language === "ar" ? "2.1rem" : ""
                                          }`,
                                        }}
                                      >
                                        <MediumTypography
                                          text="-"
                                          fontSize="0.875rem"
                                        />
                                      </TableTd>
                                    );
                                  }
                                })}
                              </FlexedDiv>
                            </TableTr>
                          )
                      )}
                  </TableTbody>
                </TableContent>
              </TableContentHolder>
            </TableSection>
          }
        />
      )}
    </SessionsWrapper>
  );
};

export default AttendanceAllSessions;
