import React, { FC, useEffect, useState } from "react";
import {
  SubmittedContainer,
  SubmittedMain,
  SubmittedQuestion,
  QuestionContainer,
  RedDot,
  SubmittedNumber,
  Score,
} from "./SubmittedQuestion.style";
import { SubmittedQuizQuestions } from "./SubmittedQuestionsDataInterface";
import {
  LightTypography,
  TypographyRegularDescription,
  Typography,
} from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import { QuestionCommentProps } from "../QuestionComment/QuestionCommentInterface";
import { useTranslation } from "react-i18next";
import { getFormattedDate } from "../../../utils/GetFormattedDate";
const SubmittedQuestions: FC<QuestionCommentProps> = ({
  questions,
  questionIndex,
  submission,
  studentIndex,
}) => {
  const [questionId, setquestionId] = useState<any>("");
  const [questionType, setquestionType] = useState<any>("");
  const { t } = useTranslation();

  useEffect(() => {
    if (questions && questions.length > 0) {
      setquestionId(
        questions[questionIndex].questionId
          ? questions[questionIndex].questionId.toString()
          : questions[questionIndex].id.toString()
      );
      setquestionType(questions[questionIndex].type);
    }
  }, [questions, questionIndex]);

  // const getFormattedDate = (createdAt: string) => {
  //   const date = new Date(createdAt);
  //   const formattedDate = `${date.toDateString()} ${date.toLocaleTimeString()}`; // Change this according to your preferred date and time format
  //   return formattedDate;
  // };

  const findStudentPoint = (questionId: number, questionType: any) => {
    if (
      submission &&
      submission.length > 0 &&
      submission[studentIndex].lastAnswersOfStudent &&
      submission[studentIndex].lastAnswersOfStudent.length > 0 &&
      questions &&
      questions.length > 0
    ) {
      const studentAnswer = submission[studentIndex].lastAnswersOfStudent.find(
        (answer: any) =>
          answer.questionId.toString() === questionId.toString() &&
          answer.type === questionType
      );

      return studentAnswer ? studentAnswer.grade : 0;
    } else {
      return 0;
    }
  };
  const findStudentAttempDate = (questionId: number, questionType: any) => {
    if (
      submission &&
      submission.length > 0 &&
      submission[studentIndex].lastAnswersOfStudent &&
      submission[studentIndex].lastAnswersOfStudent.length > 0 &&
      questions &&
      questions.length > 0
    ) {
      const studentAnswer = submission[studentIndex].lastAnswersOfStudent.find(
        (answer: any) =>
          answer.questionId.toString() === questionId.toString() &&
          answer.type === questionType
      );

      return studentAnswer ? getFormattedDate(studentAnswer.createdAt) : "";
    } else {
      return "";
    }
  };

  const numberPart =
    questions &&
    questions.length > 0 &&
    questions[questionIndex].title.split(" ")[1];

  return (
    <SubmittedMain>
      <SubmittedContainer>
        <SubmittedQuestion>
          <QuestionContainer>
            <RedDot />
            <LightTypography
              text={`${t("quiz.Question")} ${numberPart}`}
              fontSize="0.875rem"
              lineHeight="1.375rem"
            />
          </QuestionContainer>
        </SubmittedQuestion>

        <SubmittedNumber>
          <Score>
            <TypographyRegularDescription
              text={`${t("quiz.score")}:`}
              fontSize="0.75rem"
              color={theme.dark.primary_text_color}
            />
            <Typography
              text={`${findStudentPoint(questionId, questionType)} ${t(
                "quiz.out of"
              )} ${
                questions &&
                questions.length > 0 &&
                questions[questionIndex].points
              }`}
              fontSize="0.75rem"
              color={theme.dark.primary_text_color}
            />
          </Score>

          <Score>
            <TypographyRegularDescription
              text={`${t("dashboard.Submitted")}:`}
              fontSize="0.75rem"
              color={theme.dark.primary_text_color}
            />
            <Typography
              text={`${findStudentAttempDate(questionId, questionType)}`}
              fontSize="0.75rem"
              color={theme.dark.primary_text_color}
            />
          </Score>

          <Score>
            {/* <TypographyRegularDescription text='Time:' fontSize='0.75rem' color={theme.dark.primary_text_color}/>
       <Typography text='2min' fontSize='0.75rem' color={theme.dark.primary_text_color}/> */}
          </Score>
        </SubmittedNumber>
      </SubmittedContainer>
    </SubmittedMain>
  );
};

export default SubmittedQuestions;
