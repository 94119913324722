import * as XLSX from "xlsx";
export const ExcelDownload = (data: any[], tableName: string) => {
  const flattenedData: any[] = [];

  data.forEach((student) => {
    student.Models.forEach((model: { ModelData: any[]; ModelName: any }) => {
      model.ModelData.forEach((activity) => {
        flattenedData.push({
          StudentID: student.stdId,
          StudentName: student.StdName,
          ModelName: model.ModelName,
          ActivitiesResources: activity.ActivitiesResources,
          Status: activity.Status,
          TimeSpent: activity.TimeSpent,
          Grade: activity.Grade,
        });
      });
    });
  });

  const workSheet = XLSX.utils.json_to_sheet(flattenedData);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, tableName);

  // Buffer
  let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  // Binary string
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  // Download
  XLSX.writeFile(workBook, `${tableName}.xlsx`);
};
