import styled from "styled-components";
import { breakpoints } from "../../../theme";
interface HoveredCardProps {
  hasofficehours?: boolean;
  bgcolor?: string;
}
export const CourseCardWrapper = styled.div < { isadmin?: string } > `
  position: relative;
  width: 17.5rem;
  /* height: 11.8rem; */
  height:${props => props.isadmin === "true" ? " 10rem" : "fit-content"};
  min-height: 8rem;
  /* background-color: #1f2731; */
  border-radius: 1.88rem;
  box-shadow: 0px 3px 6px #00000029;
  border: 0px none;
  padding: 1rem 1rem 0.6rem 1rem;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* &:hover {
    box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.15);
    transition: box-shadow 0.4s ease-in;
    z-index: 1000 !important;
  } */
  z-index: 1000 !important;
  @media (max-width: ${breakpoints.mobile}) {
    width: 19rem;
  }
`;
export const DividLine = styled.div`
  position: relative;
  width: 80%;
  height: 0rem;
  /* border: 1px solid #576371; */
`;
export const ProfileHolder = styled.div<HoveredCardProps>`
  /* border: 0px none; */
  height: 3.5rem;
  width: 3.5rem;
  background: ${(props) => props.bgcolor};
  border-radius: 50%;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MaskGroup = styled.img`
  width: 3.1rem;
  height: 3.1rem;
  border-radius: 50%;
  object-fit: cover;
`;
export const HoveredCardFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* position: absolute; */
  /* right:1.25rem; */
  /* left:1.25rem; */
  /* padding-left:2.2rem; */
  /* bottom: 0.8rem; */
`;

export const MessageInput = styled.input`
  /* opacity: 0.2;
  border-radius: 0.3rem;
  background-color: transparent;
  /* box-shadow: "0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.2)"; */
  width: 9.6875rem;
  height: 2rem;
  background-color: transparent;
  border-radius: 0.3rem;
  position: relative;
  color: white !important;
  color: #fff;
  font-family: Core Rhino 35 Light;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.67rem 0.63rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border: none;
  &::placeholder {
    color: #ffffff;
  }
  &:focus {
    outline: none;
  }
`;

export const TopHolder = styled.div`
  display: flex;
  width: 100% !important;
  align-items: center;

  /* column-gap: 2rem; */
  height: 1rem;
  /* & > :last-child {
    margin-left: 0.2rem;
  } */
`;

export const OfficeHoursDetails = styled.div`
  display: flex;
  align-items: start !important;
  column-gap: 0.6rem;
  margin-top: 0.2rem;
`;

export const DetailStyle = styled.div`
  display: flex;
  align-items: center !important;
  column-gap: 0.4rem;
  width: 77%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RightHolder = styled.div`
  display: flex;
  position: absolute;
  top: -0.5rem;
  right: 0.3rem;
  column-gap: 0.3rem;
`;
export const IconStyle = styled.div`
  background: linear-gradient(#5dd3b3, #2d3741);
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  display: flex;
`;
export const TeacherDetailsHolder = styled.div<HoveredCardProps>`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  & > :last-child {
    margin-top: ${(props) =>
    props.hasofficehours === true ? "-0.4rem !important" : "0rem"};
  }
`;
export const ButtonsDiv = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
`;
export const TimeHolder = styled.div<{ isadmin?: string }>`
  display: flex;
  flex-direction: column;
  row-gap: 0.125rem;
  /* background: yellow; */
  max-height:${props => props.isadmin === "true" ? "4.5rem" : "5rem"};
  max-width: 9rem;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0.05rem;
    /* opacity: 0; */
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(50deg, #5dd3b3 3.83%, #2f6a5a 95.8%);
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent;

    border-radius: 0.625rem;
  }
`;
