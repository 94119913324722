import styled from "styled-components";
import { breakpoints } from "../../../theme";

export const SegementHolder = styled.div`
max-width: 100%;
width: auto;
min-height: 15.9375rem;
max-height: auto;
border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));

padding-bottom: 2.69rem;
margin-bottom: 2.56rem;
padding-left: 1.25rem;
/* padding-right: 0rem; */
padding-top: 1.25rem;
`
export const  SegmentFlex = styled.div`
display: grid;
 grid-template-columns: repeat(auto-fit, 17.75rem);
 grid-template-rows:3.75rem 3.75rem ;
 column-gap: 2.31rem;
row-gap: 0.94rem;
@media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, 1fr);
 column-gap: 0.38rem;
 row-gap: 0.63rem;

  }

  @media (max-width: ${breakpoints.tablets}) {
    grid-template-columns: repeat(2, 1fr);
  } 
`

export const TitlePercentageIconsRow = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
gap: 0.75rem;
padding-bottom: 1.25rem;
padding-right: 1.25rem;
`