import React, { FC, useEffect, useState } from "react";
import {
  LightTypography,
  PrimaryFontBoldText,
} from "../../../elements/fonts/Fonts";
import { ToDoUpdateTaskProps } from "../ToDoUpdateTask";
import {
  Container,
  FlexContainer,
  LabelDiv,
  TaskTextArea,
  ButtonsDiv,
} from "./ToDoUpdateTask.style";
import { theme } from "../../../theme";
import SelectInput from "../../../elements/SelectInput/SelectInput";
import StyledInput from "../../../elements/StyledInput/StyledInput";
import SelectDate from "../../../elements/DatePicker/inputDate";
import { ReactComponent as MeduimIcon } from "../../../assets/icons/Arrow 02.svg";
import { ReactComponent as HighIcon } from "../../../assets/icons/Arrow 4.svg";
import { ReactComponent as LowIcon } from "../../../assets/icons/Arrow 02 (copy).svg";
import { ReactComponent as SvgToDoIcon } from "../../../assets/icons/TodoList.svg";
import { ReactComponent as SvgCloseIcon } from "../../../assets/icons/Close1.svg";
import CancelButton from "../../../elements/StyledButton/CancelButton";
import { ErrorDiv } from "../../../elements/ErrorDiv/Errordiv.style";
// import { enrollmentOptions } from "./ToDoUpdate.data";
import { useTranslation } from "react-i18next";
import { formatOptionsForTranslation } from "../../../utils/FormatOptionsForTranslation";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../../utils/StringManipulation";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import LoaderThreeDot from "../../loader/loaderThreeDot/LoaderThreeDot";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import SelectDropDown from "../../../elements/selectDropDown/SelectDropDown";

const ToDoUpdateTask: FC<ToDoUpdateTaskProps> = ({
  task,
  setEditTask,
  setShow,
  handleSubmit,
  allTasks,
  index,
}) => {
  const [error, setError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const { t } = useTranslation();
  const [EnrollmentData, setEnrollmentData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { user } = useAuth0();
  const tenantName = localStorage.getItem("tenant");
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");

  const priorityOptions = [
    // do not remove the space in medium and low
    { icon: <HighIcon />, value: "high", name: "to do list.High" },
    { icon: <MeduimIcon />, value: " medium", name: "to do list.Medium" },
    { icon: <LowIcon />, value: " low", name: "to do list.Low" },
  ];

  const getTaskById = async () => {
    try {
      let task = [...allTasks];
      const response = await axios.get(
        `https://0okzb6vum6.execute-api.eu-west-1.amazonaws.com/dev/getById/${task[index].id}/${tenantName}`
      );
      setEditTask(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTaskById();
  }, []);

  const handleNothing = () => {
  };

  const translatedPriorityOptions = formatOptionsForTranslation(
    priorityOptions,
    t
  );
  // const translatedEnrollmentData = formatOptionsForTranslation(enrollmentOptions, t);
  const { SecondaryTextColor, bghelp, theme, inputBorderColor } =
    useRecoilValue(ColorSelector);

  const handleSelectChangeEnrollment = (newValue: string) => {
    setEditTask({
      ...task,
      enrollment: newValue,
      classSourceId: EnrollmentData.find(
        (enrollment) => enrollment.value === newValue
      )?.id,
    });
  };
  const handleSelectChangePriority = (newValue: string) => {
    setEditTask({ ...task, proiority: newValue });
  };
  // const isValidUrl = (url: string) => {
  //   // Regular expression for a simple URL validation
  //   const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  //   return urlRegex.test(url);
  // };

  const getEnrollmentData = async () => {
    try {
      const response = await axios.get(
        `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/enrollments/byUser/all/${tenantName}/${subIdSplitted}?provider=${provider}`
      );
      // Extracting only id and title keys from each object in the array
      const formattedData = response.data.data.map((enrollment: any) => ({
        id: enrollment.id,
        value: enrollment.title, // Assuming 'name' is meant to be 'title'
        name: enrollment.title, // Assuming 'name' is meant to be 'title'
      }));

      setEnrollmentData(formattedData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getEnrollmentData();
  }, []);

  const handleFormSubmit = async () => {
    // if (task?.webLink && !isValidUrl(task?.webLink)) {
    //   setUrlError(true);
    // } else {
    //   setUrlError(false);
    // }

    if (!task.name || !task.description) {
      setError(true);
    } else {
      // Update the task data in local storage
      const currentTaskData = JSON.parse(
        localStorage.getItem("taskData") || "[]"
      );
      const updatedTaskData = currentTaskData.map((item: any) => {
        if (item.id === task.id) {
          return task;
        }
        return item;
      });

      // Store the updated task data in local storage
      localStorage.setItem("taskData", JSON.stringify(updatedTaskData));

      handleSubmit();
      setShow(false);
    }
  };

  const formatPriority = (priority: string) => {
    // Remove any leading or trailing spaces
    priority = priority.trim();

    // Remove any space just before slicing
    if (priority.charAt(0) === " ") {
      priority = priority.slice(1);
    }

    // Capitalize the priority
    priority = priority.charAt(0).toUpperCase() + priority.slice(1);

    return priority;
  };
  return (
    <Container theme={theme}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {loading && <LoaderThreeDot />}
      </div>
      <FlexContainer>
        <LabelDiv>
          <LightTypography
            text={`${t("to do list.Task summary")} *`}
            fontSize="1rem"
          />
          <StyledInput
            placeholder={t("to do list.Enter Task summary")}
            type="text"
            width="18rem"
            value={task.name}
            onChange={(e) => setEditTask({ ...task, name: e.target.value })}
          />
          {!task.name && error && (
            <ErrorDiv>{`${t("forms.Please")} ${t(
              "to do list.Enter Task summary"
            )}`}</ErrorDiv>
          )}
        </LabelDiv>
        <LabelDiv>
          <LightTypography text={t("to do list.Enrollment")} fontSize="1rem" />
          <SelectDropDown
            onChange={handleSelectChangeEnrollment}
            placeholder={t("to do list.Select Enrollment")}
            width="18rem"
            value={task.enrollment}
            options={EnrollmentData}
          />
        </LabelDiv>
      </FlexContainer>

      <FlexContainer>
        <LabelDiv>
          <LightTypography
            text={`${t("to do list.Priority")} *`}
            fontSize="1rem"
          />
          <SelectDropDown
            onChange={handleSelectChangePriority}
            placeholder={t("to do list.Select Priority")}
            width="18rem"
            value={task.proiority}
            options={translatedPriorityOptions}
          />
        </LabelDiv>
        <LabelDiv>
          <LightTypography text={t("forms.Due Date")} fontSize="1rem" />
          <SelectDate
            showTime={false}
            width="18rem"
            value={task.date ? new Date(task.date) : null}
            onChange={(date: Date | null) => {
              // Handle date conversion
              const dateString = date ? date.toISOString() : ""; // Convert Date to string
              setEditTask({ ...task, date: dateString });
            }}
          />
        </LabelDiv>
      </FlexContainer>

      <LabelDiv>
        <LightTypography text={t("to do list.Add web link")} fontSize="1rem" />
        <StyledInput
          type="text"
          width="100%"
          value={task?.webLink}
          onChange={(e) => setEditTask({ ...task, webLink: e.target.value })}
        />
        {/* {urlError && <ErrorDiv>Please Enter a Valid URL</ErrorDiv>} */}
      </LabelDiv>
      <LabelDiv>
        <LightTypography text={t("forms.Description")} fontSize="1rem" />
        <TaskTextArea
          bordercolor={inputBorderColor}
          color={SecondaryTextColor}
          value={task?.description}
          onChange={(e) =>
            setEditTask({ ...task, description: e.target.value })
          }
        />
        {!task.description && error && ( <ErrorDiv>{`${t("forms.Please")} ${t( "forms.Enter Description" )}`}</ErrorDiv> )}
      </LabelDiv>
      <ButtonsDiv>
        <CancelButton
          name={t("forms.Cancel")}
          onClickFunction={() => setShow(false)}
          color="#D85D66"
          borderColor="#D85D66"
        />
        <CancelButton
          name={t("to do list.Update")}
          onClickFunction={() => {
            handleFormSubmit();
          }}
          color="#fff"
          borderColor="#5DD3B3"
          backgroundColor="#5DD3B3"
        />
      </ButtonsDiv>
    </Container>
  );
};
export default ToDoUpdateTask;
