import GradesInstructorTable from "../../components/gradesInstructorTable/GradesInstructorTable"



const GradesInstructor = () => {
  return (
    <GradesInstructorTable/>
  )
}

export default GradesInstructor