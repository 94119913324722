/**
 * Function: TotalNumberOfView
 * Description: This function calculates the total number of views from the provided activities array.
 * @param {any[]} activities - The array of activities containing view data.
 * @returns {number} - The total number of views.
 */
export const TotalNumberOfView = (activities: any[]): number => {
  return activities.reduce((sum: number, obj: any) => {
    if (obj.Data && obj.Data.length > 0 && obj.Data[0].Viewed) { // Check if obj.Data is defined and not empty, and if obj.Data[0].Viewed is defined
      return (
        sum +
        obj.Data.reduce(
          (innerSum: number, innerObj: { Viewed: number }) =>
            innerSum + (innerObj.Viewed || 0), // Add the viewed count of each inner object to the innerSum
          0
        )
      );
    } else {
      return sum; // Return the current sum if obj.Data is undefined or obj.Data[0].Viewed is undefined
    }
  }, 0);
};
