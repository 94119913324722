import styled from "styled-components";
import { theme } from "../../../theme";
export const ShortAnswerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.62rem;
  padding: 0 1.5rem 0 1.5rem;
`;

export const ShortAnswerInputs = styled.input<{
  width: any;
  show: any;
  center: any;
  hasError?: boolean;
  color?: string;
  Validated_BgColor?: string;
  borderColor?: string;
}>`
  width: ${(props) => (props.width ? props.width : "")};
  height: 2.8125rem;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  border-color: ${(props) =>
    props.hasError
      ? "#D85D66"
      : props.show === "true"
      ? props.Validated_BgColor
      : props.borderColor};
  background: transparent;
  /* &:dir(ltr) {
    padding-left: ${(props) =>
      props.center === "yes" ? "1.88rem" : "1.25rem"};
  }
  &:dir(rtl) {
    padding-right: ${(props) =>
      props.center === "yes" ? "1.88rem" : "1.25rem"};
  } */
  padding: 0 ${(props) => props.center === "yes" ? "1.88rem" : "1.25rem"};
  font-family: "Primary Font-Light";
  color: ${(props) => props.color || "white"};
  background: ${(props) =>
    props.show === "true" ? props.Validated_BgColor : ""};
  &&:focus {
    color: ${(props) => props.color || "white"};
    outline: none;
  }
  &::placeholder {
    opacity: 0.5;
  }
`;
