import {
  FaqRectangleHolder,
  AnswerHolder,
  FaqRectangleWrapper,
  ToggleArrowButton,
} from "./FaqRectangle.style";
import { SvgArrowDown, SvgArrowUp } from "../Icons";
import { Typography, LightTypography } from "../fonts/Fonts";
import useToggle from "../../hooks/useToggle";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  MainColorSelector,
  TableColorSelector,
} from "../../recoil/ThemeSelectors";

interface FaqInterface {
  question: string;
  answer: string;
}
const FaqRectangle = ({ question, answer }: FaqInterface): JSX.Element => {
  const { mainBackgroundColor, mainColor } = useRecoilValue(MainColorSelector);
  const { bghelp, boxShadow } = useRecoilValue(ColorSelector);
  const { Table_BgColor } = useRecoilValue(TableColorSelector);

  const { isOpen, toggle } = useToggle(false);
  // const [showMore, setShowMore] = useState(false);
  // function handleMoreClick() {
  //   setShowMore(!showMore);
  // }
  return (
    <FaqRectangleWrapper
      style={{
        background: Table_BgColor,
        borderRadius: isOpen ? "0.625rem 0.625rem 0 0" : "0.625rem",
      }}
    >
      <FaqRectangleHolder
        style={{ borderRadius: isOpen ? "0.625rem 0.625rem 0 0" : "0.625rem" }}
        boxshadow={boxShadow}
        expanded={isOpen.toString()}
        onClick={toggle}
      >
        <LightTypography
          text={question}
          fontSize="1rem"
          lineHeight="1.5625rem"
          fontWeight="400"
        />
        <ToggleArrowButton expanded={isOpen.toString()}>
          {isOpen ? <SvgArrowUp /> : <SvgArrowDown />}
        </ToggleArrowButton>
      </FaqRectangleHolder>
      {isOpen && (
        <AnswerHolder
          expanded={isOpen.toString()}
          style={{ background: bghelp }}
        >
          <LightTypography
            text={answer}
            fontSize=" 0.875rem"
            lineHeight="1.375rem"
            fontWeight="400"
          />
        </AnswerHolder>
      )}
    </FaqRectangleWrapper>
  );
};

export default FaqRectangle;
