import React, { FC, useState } from "react";
import {
  QuestionMainContainer,
  LineBreakQuestion,
} from "./CardQuestions.style";
import { CardQuestionsProps } from "./CardQuestionInterface";
import QuestionHeader from "./QuestionHeader/QuestionHeader";
import QuestionContent from "./QuestionContent/QuestionContent";
import QuestionComposedStat from "./QuestionComposedStat/QuestionComposedStat";
import QuestionComposed from "./QuestionComposed/QuestionComposed";
import { useRecoilValue } from "recoil";
import { ChatsColorSelector, ColorSelector } from "../../recoil/ThemeSelectors";
const CardQuestions: FC<CardQuestionsProps> = ({
  question,
  index,
  questions,
  addMorePartQuestions,
  setDeleteQuestion,
  setIndex,
  setPartIndex,
  handleShowEditQuest,
  handleShowQuest,
  setDeletePart,
  disableClick,
  handleDynamicQuestionIndexFromChild,
  handleGrade,
  quest,
  setQuest,
  onSave,
  setShowQuest,
  notValidate,
  setNotValidate,
  setQuestionError,
  partIndex
}) => {
  const [showParts, setShowParts] = useState<boolean>(true);
  const { InfoSelectBorderColor } = useRecoilValue(ChatsColorSelector);
  const { bghelp } = useRecoilValue(ColorSelector);
  return (
    <QuestionMainContainer>
      <QuestionHeader
        setShowParts={setShowParts}
        question={question}
        setIndex={setIndex}
        addMorePartQuestions={addMorePartQuestions}
        setDeleteQuestion={setDeleteQuestion}
        index={index}
        showParts={showParts}
        handleShowQuest={handleShowQuest}
        disableClick={disableClick}
        handleShowEditQuest={handleShowEditQuest}
        notValidate={notValidate}
        setQuestionError={setQuestionError}
        handleDynamicQuestionIndexFromChild={
          handleDynamicQuestionIndexFromChild
        }
      />
      {question.type && question.questType === "normal" ? (
        <QuestionContent
          question={question}
          handleShowQuest={handleShowQuest}
          setIndex={setIndex}
          index={index}
          handleGrade={handleGrade}
          quest={quest}
          setQuest={setQuest}
          onSave={onSave}
          setNotValidate={setNotValidate}
          setShowQuest={setShowQuest}
          notValidate={notValidate}
        />
      ) : question.questType === "composed" ? (
        <>
          <QuestionComposedStat question={question} showParts={showParts} />
          {showParts ? (
            <QuestionComposed
              question={question}
              index={index}
              setIndex={setIndex}
              setPartIndex={setPartIndex}
              handleShowEditQuest={handleShowEditQuest}
              handleShowQuest={handleShowQuest}
              setDeletePart={setDeletePart}
              disableClick={disableClick}
              handleGrade={handleGrade}
              quest={quest}
              setQuest={setQuest}
              onSave={onSave}
              setNotValidate={setNotValidate}
              setShowQuest={setShowQuest}
              notValidate={notValidate}
              partIndex={partIndex}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      {questions.length - 1 !== index || !showParts ? (
        <LineBreakQuestion style={{ background: bghelp }} />
      ) : (
        ""
      )}
    </QuestionMainContainer>
  );
};

export default CardQuestions;
