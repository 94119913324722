import React, { useState, ChangeEvent, useContext, useEffect } from "react";
import {
  RubricPageeLayout,
  RubricTableLayout,
  RubricTableLayoutColumn,
  RubricTileLayout,
  RubricTableLayoutRow,
  RubricButtonsLayout,
  OneRowShapeDiv,
  MiniCellForm,
  MiniCellTextInput,
  MiniCellTextArea,
  MinErroreSum,
  MiniWeightCellForm,
  WeightDiv,
  WeightTextInput,
  WeightDivDeree,
  ShapeDiv,
} from "../RubricTableV2/RubricTable.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import BlueButton from "../../elements/blueButton/BlueButton";

import { RubricTableViewData } from "../RubricTableView/RubricTableViewData";
import {
  RubricTileRowEditLayout,
  OneRowViewShapeDiv,
} from "../RubricTableView/RubicTableVeiw.style";
import { SvgEditIcon } from "../../elements/Icons";
import { RubricTextInput } from "../RubricCreatorTab/RubricCreatorTab.style";
import { GlobalVariabels } from "../../UseContext";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ColorSelector, TableColorSelector } from "../../recoil/ThemeSelectors";
import axios from "axios";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { useQuizMyContext } from "../../contexts/CourseCreatorQuizContext";
interface RubricTableEditionInterface {
  RowsNum: number;
  ColNum: number;
  Description?: string;
  title?: string;
  rubricData?: any;
}
export const RubricUpdateTable = ({
  RowsNum,
  ColNum,
  title,
  Description,
  rubricData,
}: RubricTableEditionInterface): JSX.Element => {
  const L = RowsNum;
  const C = ColNum;

  const navigate = useNavigate();
  const tenantName = localStorage.getItem("tenant");
  const [rowsNum, setRowsNum] = useState<number>(0);
  const [colsNum, setColsNum] = useState<number>(0);
  const [sumOfWeight, setSumOfWeights] = useState<boolean>(false);
  const generalID = useParams();
  const id = generalID.id
  const classId = generalID.classId
  const { user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const { state } = location;

  const navigatedFrom = state?.navigatedFrom;

  const {
    setSelectedRubricId
  } = useQuizMyContext();

  const [levels, setLevels] = useState(() =>
    Array.from({ length: C }, (_, index) => ({
      level: index + 1,
      value: "",
      levelError: false,
    }))
  );
  const { Table_BgColor, Table_BoxShadow, Table_nth_child, Table_Head_TR } =
    useRecoilValue(TableColorSelector);
  const {
    SecondaryTextColor,
    borderInputColor,
    theme,
    SecondaryWhiteTextColor,
    themeTextColor
  } = useRecoilValue(ColorSelector);
  const [criteria, setCriteria] = useState(() =>
    Array.from({ length: L }, (_, index) => ({
      criteria: index + 1,
      criteriaName: "",
      criteriaDescription: "",
      criteriaWeight: 0,
      nameError: false,
      descError: false,
      weightError: false,
    }))
  );

  const [boxes, setBoxes] = useState(() =>
    Array.from({ length: L * C }, (_, index) => {
      const xIndex = Math.floor(index / C) + 1;
      const yIndex = (index % C) + 1;
      return {
        x_axis: xIndex,
        y_axis: yIndex,
        boxesWeight: 0,
        boxesDescription: "",
        weightError: false,
        descError: false,
      };
    })
  );

  useEffect(() => {
    const updateBoxes = () => {
      const newBoxes = Array.from({ length: L * C }, (_, index) => {
        const xIndex = Math.floor(index / C) + 1;
        const yIndex = (index % C) + 1;
        return (
          boxes.find((box) => box.x_axis === xIndex && box.y_axis === yIndex) || {
            x_axis: xIndex,
            y_axis: yIndex,
            boxesWeight: 0,
            boxesDescription: "",
            weightError: false,
            descError: false,
          }
        );
      });
      setBoxes(newBoxes);
    };

    updateBoxes();
  }, [L, C]);

  useEffect(() => {
    if (rubricData) {
      setLevels(rubricData.levels);
      setCriteria(rubricData.criteria);
      setBoxes(
        rubricData.boxes.map((box:any) => ({
          ...box,
          weightError: false,
          descError: false,
        }))
      );
      setColsNum(ColNum);
      setRowsNum(RowsNum);
    }
  }, [rubricData]);

  useEffect(() => {
    setColsNum(ColNum);
    setRowsNum(RowsNum);
  }, [ColNum, RowsNum]);



  const handleLevelInputChange = (index: number, value: string) => {
    const newLevels = [...levels];
    newLevels[index - 1] = {
      ...newLevels[index - 1],
      level: index,
      value,
      levelError: value.trim() === "",
    };
    setLevels(newLevels);
  };

  const handleCriteriaInputChange = (
    index: number,
    field: string,
    value: string
  ) => {
    const newCriteria = [...criteria];
    const currentIndex = index - 1;

    if (field === "name") {
      newCriteria[currentIndex] = {
        ...newCriteria[currentIndex],
        criteria: index,
        criteriaName: value,
        nameError: value.trim() === "",
      };
    } else if (field === "description") {
      newCriteria[currentIndex] = {
        ...newCriteria[currentIndex],
        criteria: index,
        criteriaDescription: value,
        descError: value.trim() === "",
      };
    } else if (field === "weight") {
      newCriteria[currentIndex] = {
        ...newCriteria[currentIndex],
        criteria: index,
        criteriaWeight: parseInt(value) || 0,
        weightError: value.trim() === "",
      };
    }

    setCriteria(newCriteria);
  };

  const handleBoxInputChange = (
    xIndex: number,
    yIndex: number,
    field: string,
    value: string
  ) => {
    setBoxes((prevBoxes) => {
      const updatedBoxes = [...prevBoxes];
      const currentIndex = (xIndex - 1) * colsNum + (yIndex - 1);

      if (field === "description") {
        updatedBoxes[currentIndex] = {
          ...updatedBoxes[currentIndex],
          boxesDescription: value,
          x_axis: xIndex,
          y_axis: yIndex,
          descError: value.trim() === "",
        };
      } else if (field === "weight") {
        updatedBoxes[currentIndex] = {
          ...updatedBoxes[currentIndex],
          boxesWeight: parseInt(value) || 0,
          x_axis: xIndex,
          y_axis: yIndex,
          weightError: value.trim() === "",
        };
      }

      return updatedBoxes;
    });
  };

  const handleUpdateClick = async () => {
    // Retrieve existing rubric data from localStorage
    const existingRubricsString = localStorage.getItem("rubrics");
    const existingRubrics = existingRubricsString
      ? JSON.parse(existingRubricsString)
      : [];

    // Find the index of the rubric to update based on its ID
    const rubricIndex = existingRubrics.findIndex(
      (rubric: any) => rubric.id === rubricData.id
    );

    // Check for empty criteria names, descriptions, and weights
    const criteriaErrors = criteria.map((criterion) => ({
      nameError: criterion.criteriaName.trim() === "",
      descError: criterion.criteriaDescription.trim() === "",
      weightError: criterion.criteriaWeight === 0,
    }));

    // Check for empty level values
    const levelErrors = levels.map((level) => level.value.trim() === "");

    // Check for empty box descriptions and weights
    const boxErrors = boxes.map((box) => ({
      descError: box.boxesDescription.trim() === "",
      weightError: box.boxesWeight === 0,
    }));

    // Set errors for criteria, levels, and boxes
    setCriteria((prevCriteria) =>
      prevCriteria.map((criterion, index) => ({
        ...criterion,
        ...criteriaErrors[index],
      }))
    );
    setLevels((prevLevels) =>
      prevLevels.map((level, index) => ({
        ...level,
        levelError: levelErrors[index],
      }))
    );
    setBoxes((prevBoxes) =>
      prevBoxes.map((box, index) => ({
        ...box,
        ...boxErrors[index],
      }))
    );

    // If any errors are found, prevent saving
    const hasErrors =
      criteriaErrors.some(
        (error) => error.nameError || error.descError || error.weightError
      ) ||
      levelErrors.some(Boolean) ||
      boxErrors.some((error) => error.descError || error.weightError);

    if (hasErrors) {
      // Handle error case (e.g., display an error message)
      return; // Exit the function early
    }

    // Calculate the sum of criteria weights
    const sumOfWeights = criteria.reduce(
      (total, criterion) => total + criterion.criteriaWeight,
      0
    );

    if (sumOfWeights !== 100) {
      // Display an error message if the sum of weights is less than 100
      setSumOfWeights(true);
      return;
    } else {
      setSumOfWeights(false);
    }

    // Filter out error indicators from criteria array
    const filteredCriteria = criteria.map(
      ({ nameError, descError, weightError, ...rest }) => rest
    );

    // Filter out error indicators from levels array
    const filteredLevels = levels.map(({ levelError, ...rest }) => rest);

    // Filter out error indicators from boxes array
    const filteredBoxes = boxes.map(
      ({ descError, weightError, ...rest }) => rest
    );


const RubricDataUpdate = {
        title: title,
        description: Description,
        criteria: filteredCriteria,
        levels: filteredLevels,
        boxes: filteredBoxes,
        classSourceId: rubricData.classSourceId,
        id: id,
        subId: subId,
        provider:provider,
      };
      setIsLoading(true);
      try {
        const response = await axios.put(
          `https://0j157bzxcf.execute-api.eu-west-1.amazonaws.com/dev/update/${tenantName}`,
          {
            data: RubricDataUpdate,
          }
        );

        setSelectedRubricId(response.data.data)
        setIsLoading(false);
        // console.log("Rubric updated successfully!");
      } catch (error) {
        console.log(error);
      }

      // // Store the updated array back into localStorage
      // localStorage.setItem("rubrics", JSON.stringify(existingRubrics));

      // // Optionally, you can perform any additional actions after the update
      // // For example, you can redirect the user to another page or display a success message

      // navigate(`/course-creator/${classId}`);
      navigate(navigatedFrom === "rubricsList" ? -1 : -2);
  };

  return (
    <RubricPageeLayout>
      <RubricTableLayout bgcolor={Table_BgColor} boxshadow={Table_BoxShadow}>
        <RubricTileLayout>
          <MediumTypography text={title || ""} />
          <LightTypography
            text={Description || ""}
            color={SecondaryWhiteTextColor}
          />
        </RubricTileLayout>
        {Array.from({ length: rowsNum + 1 }, (_, i) => (
          <RubricTableLayoutRow
            key={i}
            first_child_bgcolor={Table_Head_TR}
            nth_child_bgcolor={Table_nth_child}
          >
            {Array.from({ length: colsNum + 1 }, (_, d) => (
              <RubricTableLayoutColumn key={d}>
                {i === 0 && d === 0 ? (
                  <OneRowShapeDiv>
                    <Typography text={t("Rubric.Criteria / Level")} />
                  </OneRowShapeDiv>
                ) : i === 0 && d !== 0 ? (
                  <OneRowShapeDiv>
                    <RubricTextInput
                      color={SecondaryWhiteTextColor}
                      type="text"
                      placeholder={`${t("Rubric.Level")} ${d}`}
                      style={{
                        border: levels[d - 1]?.levelError
                          ? "1px solid #D85D66"
                          : `1px solid ${borderInputColor}`,
                      }}
                      onChange={(e) =>
                        handleLevelInputChange(d, e.target.value)
                      }
                      value={(levels[d - 1] && levels[d - 1].value) || ""}
                    />
                  </OneRowShapeDiv>
                ) : i !== 0 && d === 0 ? (
                  <MiniCellForm>
                    <MiniCellTextInput
                      color={SecondaryWhiteTextColor}
                      type="text"
                      placeholder={`${t("Rubric.Criteria")} ${i}`}
                      style={{
                        border: criteria[i - 1]?.nameError
                          ? "1px solid #D85D66"
                          : `1px solid ${borderInputColor}`,
                      }}
                      onChange={(e) =>
                        handleCriteriaInputChange(i, "name", e.target.value)
                      }
                      value={
                        (criteria[i - 1] && criteria[i - 1].criteriaName) || ""
                      }
                    />
                    <MiniCellTextArea
                      color={SecondaryWhiteTextColor}
                      themetextcolor={themeTextColor}
                      placeholder={t("forms.Description")}
                      style={{
                        border: criteria[i - 1]?.descError
                          ? "1px solid #D85D66"
                          : `1px solid ${borderInputColor}`,
                      }}
                      onChange={(e) =>
                        handleCriteriaInputChange(
                          i,
                          "description",
                          e.target.value
                        )
                      }
                      value={
                        (criteria[i - 1] &&
                          criteria[i - 1].criteriaDescription) ||
                        ""
                      }
                    />
                    <MinErroreSum>
                      <MiniWeightCellForm>
                        <TypographyRegularDescription
                          text={t("Rubric.Weight")}
                          fontSize="0.75rem"
                        />
                        <WeightDiv
                          style={{
                            border: criteria[i - 1]?.weightError
                              ? "1px solid #D85D66"
                              : `1px solid ${borderInputColor}`,
                          }}
                        >
                          <WeightTextInput
                            color={SecondaryWhiteTextColor}
                            type="number"
                            id={`Criteria-Weight-${i}`}
                            onChange={(e) =>
                              handleCriteriaInputChange(
                                i,
                                "weight",
                                e.target.value
                              )
                            }
                            value={
                              (criteria[i - 1] &&
                                criteria[i - 1].criteriaWeight) ||
                              ""
                            }
                          />
                          <WeightDivDeree>
                            <LightTypography text="%" fontSize="0.625rem" />
                          </WeightDivDeree>
                        </WeightDiv>
                      </MiniWeightCellForm>
                      {i === criteria.length && sumOfWeight && (
                        <LightTypography
                          color="#D85D66"
                          text={t(
                            "Rubric.The total of weights must be equal to 100"
                          )}
                          fontSize="0.75rem"
                        />
                      )}
                    </MinErroreSum>
                  </MiniCellForm>
                ) : (
                  <ShapeDiv>
                    <RubricTextInput
                      color={SecondaryWhiteTextColor}
                      type="number"
                      placeholder={`${t("Rubric.Weight")} ${d}`}
                      style={{
                        border: boxes.find(
                          (box) => box.x_axis === i && box.y_axis === d
                        )?.weightError
                          ? "1px solid #D85D66"
                          : `1px solid ${borderInputColor}`,
                      }}
                      onChange={(e) =>
                        handleBoxInputChange(i, d, "weight", e.target.value)
                      }
                      value={
                        (
                          boxes.find(
                            (box) => box && i === box.x_axis && d === box.y_axis
                          ) || {}
                        ).boxesWeight || ""
                      }
                    />
                    <MiniCellTextArea
                      color={SecondaryWhiteTextColor}
                      style={{
                        height: "6rem",
                        border: boxes.find(
                          (box) => box.x_axis === i && box.y_axis === d
                        )?.descError
                          ? "1px solid #D85D66"
                          : `1px solid ${borderInputColor}`,
                      }}
                      placeholder={`${t("Rubric.Text")} ${d}`}
                      onChange={(e) =>
                        handleBoxInputChange(
                          i,
                          d,
                          "description",
                          e.target.value
                        )
                      }
                      value={
                        (
                          boxes.find(
                            (box) => box && i === box.x_axis && d === box.y_axis
                          ) || {}
                        ).boxesDescription || ""
                      }
                    />
                  </ShapeDiv>
                )}
              </RubricTableLayoutColumn>
            ))}
          </RubricTableLayoutRow>
        ))}
      </RubricTableLayout>

      <RubricButtonsLayout>
      {isLoading ? (
          <BlueButton
            text={`${t("general.loading")}...`}
            Width="11rem"
            BackColor="#5DD3B3"
            OnClick={()=>{}}
            disabled
          />
        ) : (
        <BlueButton
          text={t("general.Update")}
          Width="11rem"
          BackColor="#5DD3B3"
          OnClick={handleUpdateClick}
        />
      )}
        <BlueButton
          text={t("forms.Cancel")}
          OnClick={() => navigate(-1)}
          Width="11rem"
          BackColor="none"
          Color="#D85D66"
          Border="1px solid #D85D66"
        />
      </RubricButtonsLayout>
    </RubricPageeLayout>
  );
};
