import {
  MainHeroSection,
  SectionWrapper,
  SectionHolderBg,
} from "./About_HeroSection.style";
import HeroSectionDetails from "../heroSectionDetails/HeroSectionDetails";

import { useRecoilValue } from "recoil";
import { courseBannerColorSelector } from "../../../recoil/ThemeSelectors";
interface AboutHeroSectionProps {
  bgimage: string;
  syllabus: string;
  course: any;
  classStructure: any[];
  isLoading: boolean;
}

const About_HeroSection = ({
  bgimage,
  course,
  classStructure,
  isLoading,
  syllabus,
}: AboutHeroSectionProps): JSX.Element => {
  const { courseBannerBgColor, boxShadow, theme } = useRecoilValue(
    courseBannerColorSelector
  );
  return (
    <>
      <MainHeroSection>
        {theme === "light" && (
          <div
            style={{
              background: "rgba(255, 255, 255, 0.87)",
              width: "100%",
              height: "100%",
              zIndex: 5,
              position: "absolute",
              top: 0,
              left: 0,
              borderRadius: "0.625rem",
            }}
          ></div>
        )}
        <SectionHolderBg bgcolor={courseBannerBgColor} boxshadow={boxShadow}>
          <SectionWrapper theme={theme} bgimage={bgimage}></SectionWrapper>
        </SectionHolderBg>
        <HeroSectionDetails
          course={course}
          classStructure={classStructure}
          isLoading={isLoading}
          syllabus={syllabus}
        />
      </MainHeroSection>
    </>
  );
};

export default About_HeroSection;
