import React, { ReactNode, createContext, useContext, useState } from "react";

interface QuizGraderContextProps {
  questionGrade: number;
  setQuestionGrade: React.Dispatch<React.SetStateAction<number>>;
  questionComment: string;
  setQuestionComment: React.Dispatch<React.SetStateAction<string>>;
  answerId: number;
  setAnswerId: React.Dispatch<React.SetStateAction<number>>;
  quizGraded: boolean;
  setQuizGraded: React.Dispatch<React.SetStateAction<boolean>>;
  api: string;
  setApi: React.Dispatch<React.SetStateAction<string>>;
}

// Create the context
const quizGraderContext = createContext<QuizGraderContextProps | undefined>(
  undefined
);

// Create a provider component
const MyContextProvider = ({ children }: { children: ReactNode }) => {
  const [questionGrade, setQuestionGrade] = useState<number>(0);
  const [questionComment, setQuestionComment] = useState<string>("");
  const [answerId, setAnswerId] = useState<number>(0);
  const [quizGraded, setQuizGraded] = useState<boolean>(false);
  const [api, setApi] = useState<string>("");

  return (
    <quizGraderContext.Provider
      value={{
        api,
        setApi,
        questionGrade,
        setQuestionGrade,
        questionComment,
        setQuestionComment,
        answerId,
        setAnswerId,
        quizGraded,
        setQuizGraded,
      }}
    >
      {children}
    </quizGraderContext.Provider>
  );
};

// Create a custom hook to access the context values
const useMyContext = () => {
  const context = useContext(quizGraderContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
};

export { MyContextProvider, useMyContext };
