import React from "react";
import { Settings } from "react-slick";
import { SvgNextArrowIcon, SvgPrevArrowIcon } from "../../../elements/Icons";

const detectLastVisibleItem = () => {
  let activeItem = document.querySelectorAll(".last-visible");
  if (activeItem[0] !== undefined) {
    activeItem[0].classList.remove("last-visible");
  }
  let itemArray = document.querySelectorAll(".slick-slide.slick-active");
  if (itemArray.length > 1) {
    let lastItem = itemArray[itemArray.length - 1];
    lastItem.classList.add("last-visible");
  }
};
export const slickSliderSettings = (numCards: number): Settings => {
  return {
    centerMode: false,
    infinite: false,
    slidesToShow: Math.min(4.2, numCards),
    slidesToScroll: 3,
    arrows: numCards > 1,
    dots: false,
    nextArrow: <SvgNextArrowIcon />,
    prevArrow: <SvgNextArrowIcon />,
    afterChange: detectLastVisibleItem,
    onInit: () =>
      setTimeout(() => {
        detectLastVisibleItem();
      }, 1000),
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: Math.min(4, numCards),
          slidesToScroll: 3,
        },
      },

      {
        breakpoint: 1540,
        settings: {
          slidesToShow: Math.min(3.75, numCards),
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1470,
        settings: {
          slidesToShow: Math.min(3.5, numCards),
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: Math.min(3.25, numCards),
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: Math.min(3, numCards),
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1095,
        settings: {
          slidesToShow: Math.min(2.5, numCards),
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(2, numCards),
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: Math.min(1.75, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: Math.min(1.5, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 683,
        settings: {
          slidesToShow: Math.min(1.3, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: Math.min(1.2, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 588,
        settings: {
          slidesToShow: Math.min(1, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 505,

        settings: {
          arrows: false,
          slidesToShow: Math.min(1.1, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          arrows: false,
          slidesToShow: Math.min(1.05, numCards),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 350,
        settings: {
          arrows: false,
          slidesToShow: Math.min(0.75, numCards),
          slidesToScroll: 1,
        },
      },
    ],
  };
};
