import React, { FC } from "react";
import { MenuContainer, ContentContainer } from "./OpenMenu.style";
import { ReactComponent as OpenIcon } from "../../../assets/icons/menu_1.svg";
import { OpenMenuProps } from "./OpenMenuInterface";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
const OpenMenu: FC<OpenMenuProps> = ({ setShowComment }) => {
  const { Third_BgColor, backgroundColor } = useRecoilValue(ColorSelector);
  return (
    <MenuContainer bgcolor={Third_BgColor}>
      <ContentContainer
        bgcolor={backgroundColor}
        onClick={() => setShowComment(true)}
      >
        <OpenIcon />
      </ContentContainer>
    </MenuContainer>
  );
};

export default OpenMenu;
