import React, { useState, useEffect } from "react";
import {
  CheckBox,
  CommentContainer,
  StudentCheckboxContainer,
  StudentCheckboxRow,
} from "./StudentQuizCheckbox.style";
import {
  CheckboxLabel,
  SeletectOptinToogle,
} from "../../../components/ProfileCheckBoxOptions/NotificationSetting.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../fonts/Fonts";
import { SvgDone, SvgFalse } from "../../Icons";
import { findAnswerFeedbackV2, findStudentComment } from "../quizElementsUtils";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../../utils/StringManipulation";
import { useParams } from "react-router-dom";

interface CheckboxItem {
  id: number;
  choice: string;
}

interface StudentQuizCheckboxProps {
  checkboxData?: any[];
  setSelectedChoices?: React.Dispatch<
    React.SetStateAction<{ [key: string]: CheckboxItem[] | undefined }>
  >;
  allData?: any[];
  questionIndex?: number;
  partIndex?: number;
  selectedChoices?: { [key: string]: CheckboxItem[] | undefined };
  studentResponse?: any;
  setQuestionId?: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  setQuizTemplateQuestionsId?:React.Dispatch<
  React.SetStateAction<{ [key: string]: number }>
>;
  setQuestionType?: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  started?: boolean;
  showGrade?: boolean;
  showPublishGrade?: boolean;
  quizId:any;
  previewMode:boolean;
}

const StudentQuizCheckbox = ({
  checkboxData,
  setSelectedChoices,
  selectedChoices,
  allData,
  questionIndex,
  partIndex,
  studentResponse,
  setQuestionId,
  setQuestionType,
  started,
  showGrade,
  showPublishGrade,
  setQuizTemplateQuestionsId,
  quizId,
  previewMode
}: StudentQuizCheckboxProps) => {
  const [checkBoxResponse, setCheckBoxResponse] = useState<any[]>([]);
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const params = useParams();
  const classId = params.classId;
  const [isCorrect, setIsCorrect] = useState<{
    [key: string]: boolean | null | string;
  }>({});

  // Find the answer of the student question
  const findStudentAnswer = (questionId: any, questionType: any) => {
    if (studentResponse && studentResponse.length > 0) {
      const studentAnswer = studentResponse.find(
        (answer: any) =>
          Number(answer.questionId) === Number(questionId) &&
          answer.type === questionType
      );
      if (
        studentAnswer &&
        studentAnswer.answers &&
        studentAnswer.answers.length > 0
      ) {
        // Extracting answer objects from the answers array
        const answerObjects = studentAnswer.answers.map(
          (answer: any) => answer.answer
        );
        setCheckBoxResponse(answerObjects);
        studentAnswer.answers.map((item: any, index: any) =>
          setIsCorrect((prevDescriptions) => ({
            ...prevDescriptions,
            [item.answer.id]: item.isCorrect,
          }))
        );
      } else {
        setCheckBoxResponse([]);
        setIsCorrect({});
      }
    } else {
      setCheckBoxResponse([]);
      setIsCorrect({});
    }
  };
  const { checkboxBgColor,checkboxBorderColor,checkboxBorderRadius , CorrectTick_Content_Color,RadioButtonGradientColor } =
    useRecoilValue(ColorSelector);
  // Call the findStudentAnswer to show the answer to the student
  useEffect(() => {
    if (questionIndex !== undefined) {
      if (
        partIndex !== undefined &&
        allData &&
        allData[questionIndex].questionParts &&
        allData[questionIndex].questionParts[partIndex]
      ) {
        findStudentAnswer(
          allData[questionIndex].questionParts[partIndex].id,
          allData[questionIndex].questionParts[partIndex].type
        );
      } else {
        findStudentAnswer(
          allData && allData[questionIndex].questionId,
          allData && allData[questionIndex].type
        );
      }

      if(studentResponse && studentResponse.length === 0 && !previewMode){
      // Fetch and set the timer from local storage if it exists
      const savedStudentAnswer = JSON.parse(localStorage.getItem('localStudentResponse') || '[]');
      const currentQuizAnswer = savedStudentAnswer.find((item: any) =>
        item.classSourceId === classId &&
        item.subId === subIdSplitted &&
        item.refId === quizId
      );

      if (currentQuizAnswer &&
        allData &&
        allData[questionIndex]) {
        let key = "";
      if (
        (allData[questionIndex].composed ||
          allData[questionIndex].type === "COMPOSITE") &&
        partIndex !== undefined &&
        allData[questionIndex].questionParts
      ) {
        key = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}`;
      } else {
        key = `${allData[questionIndex].title}`;
      }

      if(currentQuizAnswer && currentQuizAnswer.localStudentResponse && currentQuizAnswer.localStudentResponse[key]){
        setCheckBoxResponse((currentQuizAnswer.localStudentResponse[key]))
        setSelectedChoices && 
        setSelectedChoices((prevSelect) => ({
          ...prevSelect,
          [key]: currentQuizAnswer.localStudentResponse[key]
        }));
        }else{
          setCheckBoxResponse([])
        }
        
      } else {
        setCheckBoxResponse([])
      }
      }

    }
  }, [questionIndex, partIndex, allData, studentResponse]);

  // Update the type of the question and the id of the question depending on the dependencies
  useEffect(() => {
    if (questionIndex !== undefined) {
      if (
        partIndex !== undefined &&
        allData &&
        allData[questionIndex].questionParts &&
        allData[questionIndex].questionParts[partIndex]
      ) {
        // For composed questions
        const singleKey = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}_ID`;
        const typekey = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}Type`;
        const templateKey = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}_TEMPLATEKEY`;
        setQuestionType &&
          setQuestionType((prevDescriptions) => ({
            ...prevDescriptions,
            [typekey]: allData[questionIndex].questionParts[partIndex].type,
          }));
        setQuestionId &&
          setQuestionId((prevId) => ({
            ...prevId,
            [singleKey]: allData[questionIndex].questionParts[partIndex].id,
          }));
        setQuizTemplateQuestionsId &&
          setQuizTemplateQuestionsId((prevId) => ({
            ...prevId,
            [templateKey]: allData[questionIndex].quizTemplateQuestionId,
          }));
      } else {
        // For non-composed questions
        const singleKey = `${allData && allData[questionIndex].title}_ID`;
        const typekey = `${allData && allData[questionIndex].title}Type`;
        const templateKey = `${allData && allData[questionIndex].title}_TEMPLATEKEY`;
        setQuestionId &&
          setQuestionId((prevId) => ({
            ...prevId,
            [singleKey]: allData && allData[questionIndex].questionId,
          }));
        setQuestionType &&
          setQuestionType((prevDescriptions) => ({
            ...prevDescriptions,
            [typekey]: allData && allData[questionIndex].type,
          }));
        setQuizTemplateQuestionsId &&
          setQuizTemplateQuestionsId((prevId) => ({
            ...prevId,
            [templateKey]: allData && allData[questionIndex].quizTemplateQuestionId,
          }));
      }
    }
  }, [questionIndex, allData]);

  // Handle the chosen answer of the student
  const handleCheckboxChange = (item: CheckboxItem) => {
    let key:string;
    setSelectedChoices &&
      setSelectedChoices((prevSelectedChoices) => {
        let updatedChoices = prevSelectedChoices
          ? { ...prevSelectedChoices }
          : {};
        if (allData && questionIndex !== undefined) {
          const question = allData[questionIndex];
          if (
            (question.composed || question.type === "COMPOSITE") &&
            question.questionParts &&
            question.questionParts.length > 0
          ) {
            // Composed question (With parts)
            const part = question.questionParts[partIndex || 0];
            key = `${question.title}_${part.title}`;

            if (part.type === "MCQ") {
              // MCQ Type
              // For radio buttons
              updatedChoices[key] = [item];
            } else if (part.type === "MRQ") {
              // MRQ Type
              // For checkboxes
              const existingChoices = updatedChoices[key] || [];
              const existingItemIndex = existingChoices.findIndex(
                (choice: CheckboxItem) => choice.id === item.id
              );

              if (existingItemIndex === -1) {
                updatedChoices[key] = [...existingChoices, item];
              } else {
                updatedChoices[key] = existingChoices.filter(
                  (choice: CheckboxItem) => choice.id !== item.id
                );
              }
            }
          } else {
            // For uncomposed questions
            key = `${allData && allData[questionIndex].title}`;
            if (question.type === "MCQ") {
              // MCQ Type
              // If there are only two options (radio buttons)
              updatedChoices[key] = [item];
            } else if (question.type === "MRQ") {
              // MRQ Type
              // For checkboxes
              const existingChoices = updatedChoices[key] || [];
              const existingItemIndex = existingChoices.findIndex(
                (choice: CheckboxItem) => choice.id === item.id
              );
              if (existingItemIndex === -1) {
                updatedChoices[key] = [...existingChoices, item];
              } else {
                updatedChoices[key] = existingChoices.filter(
                  (choice: CheckboxItem) => choice.id !== item.id
                );
              }
            }
          }
        }
// Retrieve the quizAnswers array from local storage
let quizAnswers = JSON.parse(localStorage.getItem('localStudentResponse') || '[]');

// Find the index of the existing quiz object
const index = quizAnswers.findIndex((item: any) =>
  item.classSourceId === classId &&
  item.subId === subIdSplitted &&
  item.refId === quizId
);

if (index !== -1) {
  // Retrieve the existing localStudentResponse
  let existingResponse = quizAnswers[index].localStudentResponse || {};

  // Merge the new key-value pair with the existing localStudentResponse
  existingResponse[key] = updatedChoices[key];

  // Update the existing object with the merged localStudentResponse
  quizAnswers[index].localStudentResponse = existingResponse;
} else {
  // Add a new object if it doesn't exist
  const data = {
    subId: subIdSplitted,
    provider:provider,
    classSourceId: classId,
    refId: quizId,
    localStudentResponse: { [key]: updatedChoices[key] }
  };
  quizAnswers.push(data);
}

// Save the updated array back to local storage
localStorage.setItem('localStudentResponse', JSON.stringify(quizAnswers));


        return updatedChoices;
      });
  };

  const isEmptyObject = (obj:any) => obj && Object.keys(obj).length === 0;

  return (
    <StudentCheckboxContainer>
      {/* The Feedback on the student's answer goes here */}
      <MediumTypography
        text={showPublishGrade && findAnswerFeedbackV2(
          studentResponse,
          questionIndex !== undefined &&
            partIndex !== undefined &&
            allData &&
            allData[questionIndex].questionParts
            ? allData[questionIndex].questionParts[partIndex].id
            : questionIndex !== undefined && allData
            ? allData[questionIndex].questionId
            : undefined,
          questionIndex !== undefined &&
            partIndex !== undefined &&
            allData &&
            allData[questionIndex].questionParts
            ? allData[questionIndex].questionParts[partIndex].type
            : questionIndex !== undefined && allData
            ? allData[questionIndex].type
            : undefined
        )}
        color="#0B8FFF"
        fontSize=".8rem"
      />
      {checkboxData &&
        checkboxData.map((item, index) => (
          <StudentCheckboxRow key={index}>
            {(allData && allData[questionIndex || 0].type === "MCQ") ||
            (partIndex !== undefined &&
              allData &&
              allData[questionIndex || 0].questionParts[partIndex || 0].type ===
                "MCQ") ? (
              <>
                <StudentCheckboxRow>
                  <CheckBox
                    bgColor={RadioButtonGradientColor}
                    type="radio"
                    name={`${
                      (allData && allData[questionIndex || 0].composed) ||
                      (allData &&
                        allData[questionIndex || 0].type === "COMPOSITE")
                        ? allData[questionIndex || 0].questionParts[
                            partIndex || 0
                          ].title
                        : allData && allData[questionIndex || 0].title
                    }`}
                    onChange={() => handleCheckboxChange(item)}
                    checked = {
                      !isEmptyObject(selectedChoices) && selectedChoices && questionIndex !== undefined && allData
                      ? partIndex !== undefined && allData && allData[questionIndex].questionParts
                        ? selectedChoices[
                            `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}`
                          ]?.some((choice) => choice.id === item.id)
                        : selectedChoices[
                            `${allData[questionIndex].title}`
                          ]?.some((choice) => choice.id === item.id)
                      : checkBoxResponse !== undefined &&
                        checkBoxResponse &&
                        checkBoxResponse.length > 0
                        ? checkBoxResponse?.some((choice) => choice.id === item.id)
                      : false}
                    id={item.id.toString()}
                    value={item.choice}
                    disabled={!started}
                    correct={`${
                      showPublishGrade &&
                      isCorrect[item.id] !== null &&
                      isCorrect[item.id] !== undefined &&
                      isCorrect[item.id] !== "pending"
                        ? isCorrect[item.id] === true
                          ? "true"
                          : "false"
                        : "true"
                    }`}
                  />
                  <TypographyRegularDescription
                    text={`${item?.choice}`}
                    fontSize="16px"
                    lineHeight="22px"
                    fontWeight="400"
                  />
                </StudentCheckboxRow>
                {showPublishGrade &&
                isCorrect[item.id] !== null &&
                  isCorrect[item.id] !== undefined &&
                  isCorrect[item.id] !== "pending" &&
                  (isCorrect[item.id] === true ? <SvgDone /> : <SvgFalse />)}
              </>
            ) : (
              <>
                <StudentCheckboxRow>
                  <SeletectOptinToogle
                    bgColor={checkboxBgColor}
                    borderColor={checkboxBorderColor}
                    borderRadius={checkboxBorderRadius}
                    contentColor={CorrectTick_Content_Color}
                    type="checkbox"
                    id={item.id.toString()}
                    name={`${
                      (allData && allData[questionIndex || 0].composed) ||
                      (allData &&
                        allData[questionIndex || 0].type === "COMPOSITE")
                        ? allData[questionIndex || 0].questionParts[
                            partIndex || 0
                          ].title
                        : allData && allData[questionIndex || 0].title
                    }`}
                    value={item.choice}
                    onChange={() => handleCheckboxChange(item)}
                    disabled={!started}
                    correct={`${
                      showPublishGrade &&
                      isCorrect[item.id] !== null &&
                      isCorrect[item.id] !== undefined &&
                      isCorrect[item.id] !== "pending"
                        ? isCorrect[item.id] === true
                          ? "true"
                          : "false"
                        : "true"
                    }`}
                    checked = {
                      !isEmptyObject(selectedChoices) && selectedChoices && questionIndex !== undefined && allData
                      ? partIndex !== undefined && allData && allData[questionIndex].questionParts
                        ? selectedChoices[
                            `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}`
                          ]?.some((choice) => choice.id === item.id)
                        : selectedChoices[
                            `${allData[questionIndex].title}`
                          ]?.some((choice) => choice.id === item.id)
                      : checkBoxResponse !== undefined &&
                        checkBoxResponse &&
                        checkBoxResponse.length > 0
                        ? checkBoxResponse?.some((choice) => choice.id === item.id)
                      : false}
                  />
                  <CheckboxLabel
                     bgColor={checkboxBgColor}
                     borderColor={checkboxBorderColor}
                     borderRadius={checkboxBorderRadius}
                    htmlFor={item.id.toString()}
                  >
                    {item.choice}
                  </CheckboxLabel>
                </StudentCheckboxRow>
                {showPublishGrade &&
                isCorrect[item.id] !== null &&
                  isCorrect[item.id] !== undefined &&
                  isCorrect[item.id] !== "pending" &&
                  (isCorrect[item.id] === true ? <SvgDone /> : <SvgFalse />)}
              </>
            )}
          </StudentCheckboxRow>
        ))}
      {partIndex === undefined &&
      showPublishGrade &&
      allData &&
      allData[questionIndex || 0] &&
      findStudentComment(
        studentResponse,
        allData[questionIndex || 0].questionId,
        allData[questionIndex || 0].type
      ) !== "" ? (
        <>
          {/* The comment on the student's answer goes Here */}
          <MediumTypography text="Instructor Comment" />
          <CommentContainer>
            <LightTypography
              text={findStudentComment(
                studentResponse,
                questionIndex !== undefined &&
                  partIndex !== undefined &&
                  allData &&
                  allData[questionIndex].questionParts
                  ? allData[questionIndex].questionParts[partIndex].id
                  : questionIndex !== undefined && allData
                  ? allData[questionIndex].questionId
                  : undefined,
                questionIndex !== undefined &&
                  partIndex !== undefined &&
                  allData &&
                  allData[questionIndex].questionParts
                  ? allData[questionIndex].questionParts[partIndex].type
                  : questionIndex !== undefined && allData
                  ? allData[questionIndex].type
                  : undefined
              )}
              fontSize=".8rem"
            />
          </CommentContainer>
        </>
      ) : (
        ""
      )}
      {partIndex !== undefined &&
      showPublishGrade &&
      allData &&
      allData[questionIndex || 0] &&
      findStudentComment(
        studentResponse,
        allData[questionIndex || 0].questionParts[partIndex].id,
        allData[questionIndex || 0].questionParts[partIndex].type
      ) !== "" ? (
        <>
          <MediumTypography text="Instructor Comment" />
          <CommentContainer>
            <LightTypography
              text={findStudentComment(
                studentResponse,
                questionIndex !== undefined &&
                  partIndex !== undefined &&
                  allData &&
                  allData[questionIndex].questionParts
                  ? allData[questionIndex].questionParts[partIndex].id
                  : questionIndex !== undefined && allData
                  ? allData[questionIndex].questionId
                  : undefined,
                questionIndex !== undefined &&
                  partIndex !== undefined &&
                  allData &&
                  allData[questionIndex].questionParts
                  ? allData[questionIndex].questionParts[partIndex].type
                  : questionIndex !== undefined && allData
                  ? allData[questionIndex].type
                  : undefined
              )}
              fontSize=".8rem"
            />
          </CommentContainer>
        </>
      ) : (
        ""
      )}
    </StudentCheckboxContainer>
  );
};

export default StudentQuizCheckbox;
