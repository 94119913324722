import React, { FC, useState, useEffect } from "react";
import MainToolBar from "../../QuizToolBar/MainToolBar";
import {
  Container,
  BodyContainer,
  ToolBarContainer,
  QuizButtonContainer,
  FlexDiv,
  FlexDiv2,
} from "./GenerateQuiz.style";
import CreateQuestions from "../../QuestionCard/main/CreateQuestions";
import Button from "../../../elements/QuizButton/Button";
import { Question, Choice } from "../GenerateQuizDataInterface";
import { GenerateQuizProps } from "./GenerateQuizInterface";
import DropQuestion from "../../QuizCreatorDropQuestion/DropQuestion";
import QuestionCRUD from "../../QuizCreatorQuestionCRUD/main/QuestionCRUD";
import CancelButton from "../../../elements/CancelButton/CancelButton";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../../utils/StringManipulation";
import { get, post } from "../../../utils/AxiosRequests";
import { useNavigate } from "react-router-dom";
import { useQuizMyContext } from "../../../contexts/CourseCreatorQuizContext";
import {
  filteringQuestions,
  handleDropQuestions,
  setQuestionTypesCount,
} from "./generateQuizUtils";
import PopupRenderer from "./PopupRenderer";
import { handleValidation } from "./validationFunction";
import {
  SvgMultipleIcon,
  SvgShortAnswer,
  SvgNumerical,
  SvgEssay,
  SvgMatching,
  SvgTrueFalse,
} from "../../../elements/Icons";
import { useTranslation } from "react-i18next";
import { MyContextProvider } from "../../../contexts/QuestionBanksContext";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";

const GenerateQuiz: FC<GenerateQuizProps> = ({
  setStart,
  setQuestion,
  setMultipleChoice,
  setShortAnswer,
  setNumerical,
  setEssay,
  setGrade,
  setMatching,
  setTrueFalse,
  setTotalGrade,
  handleCancelQuiz
}) => {
  const { t } = useTranslation();
  // Destructuring States From the Context( This Context is For The Quiz Activity)
  const {
    setGlobalQuizShowPopup,
    editQuiz,
    globalSelectedQuizId,
    setEditQuiz,
    editQuizButton,
    globalQuestionsArray,
    setGlobalQuestionsArray,
    notValidate,
    setNotValidate,
    disableClick,
    setDisableClick,
    setGlobalSelectedQuiz,
    setGlobalSelectedQuizId,
    setSelectedRadio,
    setSelectedRubricId,
    setSelectedTitle
  } = useQuizMyContext();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showCategoryPopup, setShowCategoryPopup] = useState<boolean>(false);
  const [showLoadTemplate, setShowshowLoadTemplate] = useState<boolean>(false);
  const [showQuest, setShowQuest] = useState<boolean>(false);
  const [editQuest, setEditQuest] = useState<boolean>(false);
  const [choiceIndex, setChoiceIndex] = useState<number>(0);
  const [deleteQuestion, setDeleteQuestion] = useState<boolean>(false);
  const [deleteChoice, setDeleteChoice] = useState<boolean>(false);
  const [deletePart, setDeletePart] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(globalQuestionsArray.length - 1);
  const [quest, setQuest] = useState<any>();
  const [partIndex, setPartIndex] = useState<number>(globalQuestionsArray.length > 0 && !editQuiz && globalQuestionsArray[index] && globalQuestionsArray[index].questPart ? globalQuestionsArray[index].questPart.length - 1 : 0);
  const [choice, setChoice] = useState<Choice[]>([]);
  const [showPreviewPopup, setShowPreviewPopup] = useState<boolean>(false);
  const [selectedRandomQuestion, setSelectedRandomQuestion] =
    useState<boolean>(false);
  const [selectedRandomOption, setSelectedRandomOption] =
    useState<boolean>(false);
  const [qindexValue, setQindexValue] = useState<number>(0);
  const [templateName, setTemplateName] = useState<string>("");
  const [checkTemplateName, setCheckTemplateName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [questionDynamicIndex, setQuestionDynamicIndex] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [questionError, setQuestionError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [showQuestionBanksPopup, setShowQuestionBanksPopup] = useState(false);
  const [originalState, setOriginalState] = useState<Question[]>([]);
  // const [isShuffled, setIsShuffled] = useState<boolean>(false);
  const params = useParams();
  const classId = params.id;
  const { user } = useAuth0();
  const tenantName = localStorage.getItem("tenant");
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const navigate = useNavigate();
  const feedbackRight = "quiz.Your Answer is correct."
  const feedbackWrong = "quiz.Your Answer is incorrect."

  // This Function check the editQuiz state from the context, if true it load the template with questions.
  useEffect(() => {
    const addIconsToQuestions = (questions:any) => {
      return questions.map((question:any) => {
        const updatedQuestion = { ...question };
  
        switch (question.type) {
          case "ESSAY":
            updatedQuestion.icon = <SvgEssay />;
            break;
          case "MATCH":
            updatedQuestion.icon = <SvgMatching />;
            break;
          case "SHORT_ANSWER":
            updatedQuestion.icon = <SvgShortAnswer />;
            break;
          case "MCQ":
          case "MRQ":
            updatedQuestion.icon = <SvgMultipleIcon />;
            break;
          case "NUMERIC":
            updatedQuestion.icon = <SvgNumerical />;
            break;
          case "TF":
            updatedQuestion.icon = <SvgTrueFalse />;
            break;
          case "COMPOSITE":
            updatedQuestion.questPart = question.questPart.map((part:any) => {
              const updatedPart = { ...part };
  
              switch (part.type) {
                case "ESSAY":
                  updatedPart.icon = <SvgEssay />;
                  break;
                case "MATCH":
                  updatedPart.icon = <SvgMatching />;
                  break;
                case "SHORT_ANSWER":
                  updatedPart.icon = <SvgShortAnswer />;
                  break;
                case "MCQ":
                case "MRQ":
                  updatedPart.icon = <SvgMultipleIcon />;
                  break;
                case "NUMERIC":
                  updatedPart.icon = <SvgNumerical />;
                  break;
                case "TF":
                  updatedPart.icon = <SvgTrueFalse />;
                  break;
                default:
                  break;
              }
              return updatedPart;
            });
            break;
          default:
            break;
        }
        return updatedQuestion;
      });
    };
    const fetchData = async () => {
      setLoadingData(true);
      const response = await get(
        `quiz/questions/template/${globalSelectedQuizId}/${tenantName}`
      );
      const storedQuestions = response.data.questions;
      setTemplateName(response.data.title);
      setCheckTemplateName(response.data.title);
      // Store the name of the template array in local storage as quizTemplateName
      localStorage.setItem("quizTemplateName", JSON.stringify(response.data.title));
      // This function updates stored questions with additional properties based on their type (Edit Mode Only)
      // It optimizes the code by using a switch statement and reduces redundancy
      if (storedQuestions && storedQuestions.length > 0) {
        // Map through stored questions to update each one
        const updatedQuestions = storedQuestions.map((question: any) => {
          // Create a copy of the original question
          const updatedQuestion = { ...question };
          // Set common properties for all question types
          updatedQuestion.index = question.questionIndex;
          updatedQuestion.id = question.id;
          updatedQuestion.questType = "normal";
          updatedQuestion.questPart = [];

          // Switch based on the question type
          switch (question.type) {
            case "ESSAY":
              updatedQuestion.icon = <SvgEssay />;
              updatedQuestion.grade = question.points.toString();
              updatedQuestion.choices = [
                { name: "", isChecked: question.attachments === 1 },
              ];
              break;
            case "MATCH":
              updatedQuestion.icon = <SvgMatching />;
              updatedQuestion.grade = question.points.toString();
              updatedQuestion.choices = question.match.left.map(
                (left: any, i: any) => ({
                  index: i + 1,
                  left: left.toLowerCase(),
                  right: question.match.right[i].toLowerCase(),
                })
              );
              break;
            case "SHORT_ANSWER":
              updatedQuestion.icon = <SvgShortAnswer />;
              updatedQuestion.grade = question.points.toString();
              updatedQuestion.choices = (question.keywords || []).map(
                (keyword: any) => ({ name: keyword.keyword })
              );
              break;
            case "MCQ":
            case "MRQ":
              updatedQuestion.icon = <SvgMultipleIcon />;
              updatedQuestion.grade = question.points.toString();
              updatedQuestion.type = "MCQ";
              updatedQuestion.shuffle = question.shuffleOptions === 1;
              updatedQuestion.choices = (question.choices || []).map(
                (choice: any) => ({
                  index: choice.index,
                  name: choice.choice,
                  percentage:
                    question.type === "MRQ"
                      ? choice.percentage
                      : choice.correct
                      ? "100"
                      : "0",
                  isChecked:
                    question.type === "MRQ"
                      ? choice.percentage > 0
                      : choice.correct,
                })
              );
              break;
            case "NUMERIC":
              updatedQuestion.icon = <SvgNumerical />;
              updatedQuestion.grade = question.points.toString();
              updatedQuestion.choices = [
                {
                  name: `${question.answer}`,
                  errorMargin: `${question.errorMargin}`,
                },
              ];
              break;
            case "TF":
              updatedQuestion.icon = <SvgTrueFalse />;
              updatedQuestion.grade = question.points.toString();
              updatedQuestion.choices = [
                { name: "", isChecked: question.answer },
              ];
              break;
            case "COMPOSITE":
              updatedQuestion.questType = "composed";
              // For composite questions, update each part within the question
              updatedQuestion.questPart = question.questionParts.map(
                (part: any) => {
                  const updatedPart = { ...part };
                  updatedPart.id = part.id
                  // Switch based on the part type
                  switch (part.type) {
                    case "ESSAY":
                      updatedPart.icon = <SvgEssay />;
                      updatedPart.grade = part.points.toString();
                      updatedPart.choices = [
                        { name: "", isChecked: part.attachments === 1 },
                      ];
                      break;
                    case "MATCH":
                      updatedPart.icon = <SvgMatching />;
                      updatedPart.grade = part.points.toString();
                      updatedPart.choices = part.match.left.map(
                        (left: any, i: any) => ({
                          index: i + 1,
                          left: left.toLowerCase(),
                          right: part.match.right[i].toLowerCase(),
                        })
                      );
                      break;
                    case "SHORT_ANSWER":
                      updatedPart.icon = <SvgShortAnswer />;
                      updatedPart.grade = part.points.toString();
                      updatedPart.choices = (part.keywords || []).map(
                        (keyword: any) => ({ name: keyword.keyword })
                      );
                      break;
                    case "MCQ":
                    case "MRQ":
                      updatedPart.icon = <SvgMultipleIcon />;
                      updatedPart.grade = part.points.toString();
                      updatedPart.type = "MCQ";
                      updatedPart.shuffle = part.shuffleOptions === 1;
                      updatedPart.choices = (part.choices || []).map(
                        (choice: any) => ({
                          index: choice.index,
                          name: choice.choice,
                          percentage:
                            part.type === "MRQ"
                              ? choice.percentage
                              : choice.correct
                              ? "100"
                              : "0",
                          isChecked:
                            part.type === "MRQ"
                              ? choice.percentage > 0
                              : choice.correct,
                        })
                      );
                      break;
                    case "NUMERIC":
                      updatedPart.icon = <SvgNumerical />;
                      updatedPart.grade = part.points.toString();
                      updatedPart.choices = [
                        {
                          name: `${part.answer}`,
                          errorMargin: `${part.errorMargin}`,
                        },
                      ];
                      break;
                    case "TF":
                      updatedPart.icon = <SvgTrueFalse />;
                      updatedPart.grade = part.points.toString();
                      updatedPart.choices = [
                        { name: "", isChecked: part.answer },
                      ];
                      break;
                    default:
                      break;
                  }
                  return updatedPart;
                }
              );
              break;
            default:
              break;
          }
          return updatedQuestion;
        });
        // Remove the `icon` key from the updated questions and their parts
      const cleanedQuestions = updatedQuestions.map((question: any) => {
        const { icon, ...cleanedQuestion } = question;
        cleanedQuestion.questPart = cleanedQuestion.questPart.map((part: any) => {
          const { icon, ...cleanedPart } = part;
          return cleanedPart;
        });
        return cleanedQuestion;
      });

      // Update state and flags
      setGlobalQuestionsArray(updatedQuestions);
      // Store the cleaned questions array in local storage as quizTemplate
      localStorage.setItem("quizTemplate", JSON.stringify(cleanedQuestions));
        setLoadingData(false);
        setShowQuest(true);
        setDisableClick(false);
        setNotValidate(false);
        setEditQuiz(false);
      }
    };
    if (editQuiz && globalSelectedQuizId) {
      fetchData();
    }
    const localQuizTemplate = localStorage.getItem("quizTemplate");
    const localQuizTemplateName = localStorage.getItem("quizTemplateName");
    if(localQuizTemplateName){
      setCheckTemplateName(JSON.parse(localQuizTemplateName));
      setTemplateName(JSON.parse(localQuizTemplateName))
    }
    
    if (localQuizTemplate) {
      const parsedTemplate = JSON.parse(localQuizTemplate);
      if (parsedTemplate.length > 0) {
        setGlobalQuestionsArray(addIconsToQuestions(parsedTemplate));
        setShowQuest(true)
        setEditQuest(false);
        setNotValidate(false);
        setDisableClick(false);
      }
    }
  }, []);

  useEffect(() => {
    handleSingleQuestAndChoice();
    // Get The Count Of The Questions From The Function For Each Type Question
    setEssay(setQuestionTypesCount(globalQuestionsArray).essayCount);
    setMultipleChoice(
      setQuestionTypesCount(globalQuestionsArray).multipleChoiceCount
    );
    setMatching(setQuestionTypesCount(globalQuestionsArray).matchingCount);
    setTrueFalse(setQuestionTypesCount(globalQuestionsArray).trueFalseCount);
    setShortAnswer(
      setQuestionTypesCount(globalQuestionsArray).shortAnswerCount
    );
    setGrade(setQuestionTypesCount(globalQuestionsArray).grade);
    setTotalGrade(setQuestionTypesCount(globalQuestionsArray).totalGrade);
    setQuestion(setQuestionTypesCount(globalQuestionsArray).totalQuestions);
    setNumerical(setQuestionTypesCount(globalQuestionsArray).numericalCount);
  }, [globalQuestionsArray, index, partIndex]);

  // Save Question and Push it to the Questions Array.
  const onSave = () => {
    try{
    // Validation Before Saving The Question
    const error = handleValidation(
      globalQuestionsArray,
      quest,
      index,
      partIndex,
      choice,
      qindexValue
    );
    switch (error) {
      case "Please select a question type for the question.":
      case "Please select a question type for the question part.":
      case "This field is mandatory":
      case "Please add at least two choices for Matching questions.":
      case "Please add at least two choices for MCQ questions.":
      case "First two choices' names cannot be empty for Matching questions.":
      case "Choices cannot be empty.":
      case "Percentage field cannot be empty.":
      case "First two choices' names cannot be empty for MCQ questions.":
      case "Total percentage of choices should be 100.":
      case "Choices field cannot be empty.":
      case "First Keyword cannot be empty for Short answer questions.":
      case "Fields is mandatory.":
      case "Point cannot be 0":
        setDisableClick(true);
        setQuestionError(error);
        return;
      default:
        break;
    }
    const nonEmptyChoices = choice.filter((c) => {
      if (quest && quest.type === "MATCH") {
        return c.left?.trim() !== "" && c.right?.trim() !== "";
      } else if (
        (quest && quest.type === "MCQ") ||
        (quest && quest.type === "MRQ")
      ) {
        return (
          c.name?.trim() !== "" ||
          c.percentage?.trim() !== "" ||
          c.isChecked === true
        );
      } else if (quest && quest.type === "SHORT_ANSWER") {
        return c.name?.trim() !== "";
      }
      return choice;
    });

    // reset error messages
    setQuestionError("");
    setTypeError("");
    let newQuestions = [...globalQuestionsArray];
    if (newQuestions[index].questType === "normal") {
      if (quest) {
        newQuestions[index] = quest;
        newQuestions[index].choices = nonEmptyChoices;
      }
    } else if (newQuestions[index].questType === "composed") {
      if (quest) {
        newQuestions[index].questPart[partIndex] = quest;
        let newChoices = newQuestions[index].questPart[partIndex];

        newChoices.choices = nonEmptyChoices;
      }
    }

    const filteredQuestions = newQuestions.filter(
      (question) => question.type !== ""
    );
    
    setGlobalQuestionsArray(filteredQuestions);
    setShowQuest(true);
    setEditQuest(false);
    quest.bank = false;
    setDisableClick(false);
    setNotValidate(false);
    }catch(error){
      console.log(error)
    }
  };

  const handleSelectRubricId = (rubricId: number) => {
    quest.rubricId = rubricId
  }

  const handleQIndexFromChild = (qindexValue: number) => {
    setQindexValue(qindexValue);
  };

  const handleDynamicQuestionIndexFromChild = (qindexValue: number) => {
    setQuestionDynamicIndex(qindexValue);
  };

  // This Function Is Very Important For The Functionality of This Component
  const handleSingleQuestAndChoice = () => {
    if (globalQuestionsArray[index]?.questType === "normal") {
      // Normal Question (Without Parts)
      setQuest(globalQuestionsArray[index]);
      setChoice(globalQuestionsArray[index]?.choices || []);
    } else if (globalQuestionsArray[index]?.questType === "composed") {
      // Composed Question (With Parts)
      setQuest(globalQuestionsArray[index].questPart[partIndex]);
      setChoice(
        globalQuestionsArray[index]?.questPart[partIndex]?.choices || []
      );
    }
  };

  const handleRandomQuestion = () => {
    setSelectedRandomQuestion(!selectedRandomQuestion);
  };

  const handleRandomOptions = () => {
    setSelectedRandomOption(!selectedRandomOption);
  };

  // Add Question
  const addMoreQuestion = (type: string) => {
    if (quest?.type !== "" && notValidate) {
      setQuestionError("Save Before adding a question");
      return;
    }
    if (
      globalQuestionsArray[index]?.questType === "composed" &&
      globalQuestionsArray[index]?.questPart.length === 1 &&
      quest?.type !== ""
    ) {
      setQuestionError("Complex questions must have at least 2 parts.");
      setTypeError("");
      return;
    }
    let updateQuestions = globalQuestionsArray;
    if (type === "normal") {
      // If The Added Question is Normal (Without Parts)
      let newQuestionField = {
        type: "",
        question: "",
        questPart: [],
        questType: type,
        grade: "",
        icon: <></>,
        choices: [],
        feedRight: t(feedbackRight),
        feedWrong: t(feedbackWrong),
        subId: subIdSplitted,
        provider:provider,
        classSourceId: classId,
      };

      updateQuestions.push(newQuestionField);
      setIndex(globalQuestionsArray.length - 1);
      setShowQuest(false);
    } else if (type === "composed") {
      // If The Added Question is Composed (With Parts)
      const questPart = updateQuestions[index]?.questPart || [];
      const questId = questPart.length + 1;
      let newQuestionField = {
        question: "",
        questType: type,
        grade: "",
        icon: <></>,
        questPart: [],
        choices: [],
        feedRight: "",
        feedWrong: "",
        subId: subIdSplitted,
        provider:provider,
        classSourceId: classId,
      };
      let newQuestionFieldPart = {
        question: "",
        type: "",
        questType: "",
        questPart: [],
        grade: "",
        icon: <></>,
        choices: [],
        feedRight: t(feedbackRight),
        feedWrong: t(feedbackWrong),
        subId: subIdSplitted,
        provider:provider,
        classSourceId: classId,
      };
      updateQuestions.push(newQuestionField);
      updateQuestions[index + 1]?.questPart?.push(newQuestionFieldPart);
      setIndex(globalQuestionsArray.length - 1);
      setPartIndex(0);
      setShowQuest(false);
    }
    setGlobalQuestionsArray(updateQuestions);
    if (
      updateQuestions[index]?.type === "" ||
      updateQuestions[index]?.questPart[partIndex]?.type === ""
    ) {
      let deleteQuest = [...globalQuestionsArray];
      deleteQuest.splice(index, 1);
      setIndex(index);
      setGlobalQuestionsArray(deleteQuest);
      return;
    }
    setEditQuest(false);
  };

  // Add Parts To The Question
  const addMorePartQuestions = (index: number) => {
    if (quest?.type !== "" && notValidate) {
      setQuestionError("Save Before add a question");
      return;
    }
    let updateQuestions = [...globalQuestionsArray];
    const questPart = updateQuestions[index]?.questPart || [];
    const questId = questPart.length + 1;
    if (updateQuestions[index]?.questPart[partIndex]?.type === "") {
      return;
    }
    let newQuestionFieldPart = {
      question: "",
      questType: "",
      questPart: [],
      type: "",
      grade: "",
      icon: <></>,
      choices: [],
      feedRight: t(feedbackRight),
      feedWrong: t(feedbackWrong),
      subId: subIdSplitted,
      provider:provider,
      classSourceId: classId,
    };
    updateQuestions[index].questPart.push(newQuestionFieldPart);
    setGlobalQuestionsArray(updateQuestions);
    setIndex(index);
    setPartIndex(questId - 1);
    setShowQuest(false);
    setDisableClick(true);
  };

  // This Function Call the handleDropQuestions function that create the question when dropped
  const DropQuestionType = (data: any) => {
    setQuestionError("");
    setTypeError("");
    setDisableClick(true);
    setShowQuest(false);
    if (quest?.type !== "") {
      setNotValidate(false);
    } else {
      setNotValidate(true);
    }
    const updatedQuestions = handleDropQuestions(
      globalQuestionsArray,
      index,
      partIndex,
      data,
      t
    );
    setGlobalQuestionsArray(updatedQuestions);
      setSelectedRadio("Manually graded")
      handleSelectRubricId && handleSelectRubricId(0)
      setSelectedRubricId(0)
      setSelectedTitle('')
  };

  // Remove Question
  const removeQuestion = () => {
    let questionField = [...globalQuestionsArray];
    if (index === 0 && questionField.length === 1) {
      if (questionField[index].questType === "normal") {
        questionField[index].type = "";
        questionField[index].icon = <></>;
        questionField[index].question = "";
      } else if (questionField[index].questType === "composed") {
        questionField[index].questPart = [
          {
            question: "",
            type: "",
            questType: "",
            questPart: [],
            grade: "",
            icon: <></>,
            choices: [],
            feedRight: "",
            feedWrong: "",
          },
        ];
      }
      setIndex(0);
      setPartIndex(0);
    }
    if (index > 0) {
      questionField.splice(index, 1);
      setIndex(index - 1);
    }
    if (index === 0 && questionField.length > 1) {
      questionField.splice(index, 1);
      setIndex(0);
    }
    setShowQuest(true);
    setGlobalQuestionsArray(questionField);
  };

  // Remove The Part Question
  const removePartQuestion = () => {
    let questionField = [...globalQuestionsArray];
    if (partIndex > 0) {
      questionField[index].questPart.splice(partIndex, 1);
      setPartIndex(partIndex - 1);
    }
    if (partIndex === 0 && questionField[index].questPart.length === 1) {
      questionField[index].questPart.splice(partIndex, 1);
      questionField[index].questPart = [
        {
          question: "",
          type: "",
          questType: "",
          questPart: [],
          grade: "",
          icon: <></>,
          choices: [],
          feedRight: "",
          feedWrong: "",
        },
      ];
      setPartIndex(0);
    }
    if (partIndex === 0 && questionField[index].questPart.length > 1) {
      questionField[index].questPart.splice(partIndex, 1);
      setPartIndex(0);
    }
    setShowQuest(true);
    setGlobalQuestionsArray(questionField);
  };

  // Add Choices For The Questions (MRQ, MCQ, Matching and Short Answer)
  const addChoice = () => {
    setQuestionError("");
    const updatedQuest = { ...quest };
    quest.edited = true;
    let choiceField = [...choice];
    if (quest?.type === "MATCH" && choice.length < 15) {
      // Matching Question
      const questChoice = {
        index: choice.length + 1,
        right: "",
        left: "",
      };
      choiceField?.push(questChoice);
      setChoice(choiceField);
    } else if (
      (quest?.type === "MCQ" || quest?.type === "MRQ") &&
      choice.length < 15
    ) {
      // MCQ or MRQ Question
      const questChoice = {
        index: choice.length + 1,
        name: "",
        percentage: "",
        isChecked: false,
      };
      choiceField?.push(questChoice);
      setChoice(choiceField);
    } else if (choice.length < 15) {
      // Short Answer Question
      const questChoice = {
        right: "",
        left: "",
        name: "",
        percentage: "",
        errorMargin: "",
        isChecked: false,
      };
      choiceField?.push(questChoice);
      setChoice(choiceField);
    }
  };

  // Remove Choices For The Questions (MRQ, MCQ, Matching and Short Answer)
  const removeChoice = () => {
    let choiceField = [...choice];
    choiceField.splice(choiceIndex, 1);

    // When the count of choices returns to 2
    if (choiceField.length === 2) {
        const checkedChoicesCount = choiceField.filter(choice => choice.isChecked).length;
        const percentage = checkedChoicesCount > 0 ? (100 / checkedChoicesCount).toFixed(0) : "0";

        // Update the percentage for both choices
        choiceField.forEach((choice:any) => {
            choice.percentage = choice.isChecked ? percentage.toString() : "0";
        });
    } else {
        // Adjust the percentage for remaining choices
        const checkedChoicesCount = choiceField.filter(choice => choice.isChecked).length;
        const percentage = checkedChoicesCount > 0 ? (100 / checkedChoicesCount).toFixed(0) : "0";

        choiceField.forEach((choice:any) => {
            choice.percentage = choice.isChecked ? percentage.toString() : "0";
        });
    }

    setChoice(choiceField);
};


  // Fill The Choice Array For each Question
  const handleChoiceMatchingQuestion = (
    cIndex: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let newChoices = JSON.parse(JSON.stringify(choice));
    const updatedQuest = { ...quest };
    quest.edited = true;
    if (e.target.id === "left") {
      newChoices[cIndex].left = e.target.value;
    } else if (e.target.id === "right") {
      newChoices[cIndex].right = e.target.value;
    } else if (e.target.id === "percentage") {
      newChoices[cIndex].percentage = e.target.value;
    } else if (e.target.id === "name") {
      newChoices[cIndex].name = e.target.value;
    } else if (e.target.id === "error-margin") {
      newChoices[cIndex].errorMargin = e.target.value;
    }

    setChoice(newChoices);
  };

  // Toggle the isChecked Key in the choices array (More Than 2 choices)
  const handleChoiceChecked = (cIndex: number, checked: boolean) => {
    const updatedQuest = { ...quest };
    updatedQuest.edited = true;

    let newChoices = JSON.parse(JSON.stringify(choice));
    newChoices[cIndex].isChecked = checked;

    // Calculate the percentage for checked choices
    const checkedChoicesCount = newChoices.filter((choice:any) => choice.isChecked).length;
    const percentage = checkedChoicesCount > 0 ? (100 / checkedChoicesCount).toFixed(0) : "0";

    // Update the percentage for all choices
    newChoices.forEach((choice:any) => {
        choice.percentage = choice.isChecked ? percentage.toString() : "0";
    });

    setChoice(newChoices);
};

  // Toggle the isChecked Key in the choices array (2 choices)
  const handleRadioChecked = (cIndex: number, checked: any) => {
    const updatedQuest = { ...quest };
    quest.edited = true;
    let newChoices = JSON.parse(JSON.stringify(choice));
    newChoices[cIndex].isChecked = checked;
    newChoices[cIndex === 0 ? 1 : 0].isChecked = !checked;
    // Set the percentage based on the checked status
    newChoices[cIndex].percentage = checked ? "100" : "0";
    newChoices[cIndex === 0 ? 1 : 0].percentage = checked ? "0" : "100";
    setChoice(newChoices);
  };

  // Set The Name Of The Template
  const handleTemplateName = (value: string) => {
    setTemplateName(value);
  };

  // Set The Points of the questions
  const handleGrade = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!quest) {
      return;
    }
    const updatedQuest = { ...quest };
    if (e.target.id === "grade") {
      updatedQuest.grade = e.target.value;
    } 
    setQuest(updatedQuest);
  };

  // Set Feedback if the question is correct, and Feedback if the question is incorrect.
  const handleFeedBackAndGrade = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!quest) {
      return;
    }
    const updatedQuest = { ...quest };
     if (e.target.id === "feed-right") {
      updatedQuest.feedRight = e.target.value;
      quest.edited = true;
    } else if (e.target.id === "feed-wrong") {
      updatedQuest.feedWrong = e.target.value;
      quest.edited = true;
    }
    setQuest(updatedQuest);
  };

  // Set the question Filed in The Question (the question that will show to the student)
  const handleQuillChange = (value: string) => {
    if (!quest) {
      return;
    }
    const QuestionField = { ...quest };
    if (QuestionField) {
      QuestionField.question = value;
      quest.edited = true;
    }
    setQuest(QuestionField);
  };

  // Toogle The Shuffle of the Question
  const handleShuffle = (shuffled: boolean) => {
    // console.log("Shuffle is :" , isShuffled)
    if (
      quest &&
      (quest.type === "MCQ" || quest.type === "MRQ" || quest.type === "MATCH")
    ) {
      const updatedQuest = { ...quest };
      quest.edited = true;
      if (shuffled === true) {
        quest.shuffle = true;
        // setIsShuffled(true);
      } else if (shuffled === false) {
        quest.shuffle = false;
        // setIsShuffled(false);
      }
    }
  };

  // This Function disable the filed of the question, so the user can only review the Question
  const handleShowQuest = () => {
    setChoice([...choice]);
    let newQuestion = [...globalQuestionsArray];
    setGlobalQuestionsArray(newQuestion);
    setShowQuest(true);
    setEditQuest(false);
    setQuest(quest);
  };

  // Activate The Edit Mode, so the user can edit the Question.
  const handleShowEditQuest = () => {
    setShowQuest(false);
    setEditQuest(true);
    quest.bank = false;
  };

  // Cancel Button, it will cancel the Question
  const handleCancel = () => {
    if (editQuest && quest.bank) {
      // Restore the original state without the last added question
      setGlobalQuestionsArray((prevQuestions) => prevQuestions.slice(0, -1));
      // Reset indices and errors
      setIndex((prevIndex) => prevIndex - 1); // Adjust the index based on the removed question
      setPartIndex(0);
      setQuestionError("");
      setTypeError("");
      // Reset modified flag
      // Show the original state
      setShowQuest(true);
      setDisableClick(false);
      setNotValidate(false);
    } else if (editQuest && !quest.bank) {
      setPartIndex(0);
      setQuestionError("");
      setTypeError("");
      // setGlobalQuestionsArray(globalQuestionsArray)
      // Reset modified flag
      // Show the original state
      setShowQuest(true);
      setNotValidate(false);
      let newQuestion = [...globalQuestionsArray];
    setGlobalQuestionsArray(newQuestion);
    setQuest(quest);
    }
    else if ( !editQuest) {
      // Restore the original state without the last added question
      setGlobalQuestionsArray((prevQuestions) => prevQuestions.slice(0, -1));
      // Reset indices and errors
      setIndex((prevIndex) => prevIndex - 1); // Adjust the index based on the removed question
      setPartIndex(0);
      setQuestionError("");
      setTypeError("");
      // Reset modified flag
      // Show the original state
      setShowQuest(true);
      setDisableClick(false);
      setNotValidate(false);
    }
    else if (editQuest) {
      setPartIndex(0);
      setQuestionError("");
      setTypeError("");
      setGlobalQuestionsArray(globalQuestionsArray)
      // Show the original state
      setShowQuest(true);
      setNotValidate(false);
    }
  };

  // useEffect(() => {
  //   // Update the original state when the questions are modified
  //   if (!isModified) {
  //     setOriginalState([...globalQuestionsArray]);
  //     setIsModified(true);
  //   }
  // }, [globalQuestionsArray, isModified]);

  // Save the Template.
  const SaveQuiz = async () => {
    if (templateName.trim() === "") {
      setNameError(t("quiz.Template Name Is Mandatory"));
      return;
    }
    if (templateName.trim() === checkTemplateName) {
      setNameError(t("quiz.Duplicate Name"));
      return;
    }
    // Call the filteringQuestions function to filter the questions before saving
    const filteredQuestions = filteringQuestions(globalQuestionsArray);
    const Template = {
      name: templateName,
      description: "",
      questions: filteredQuestions,
      subId: subIdSplitted,
      provider:provider,
      classSourceId: classId,
    };
    try{
      setLoading(true);
      const response = await post(`quiz/create/question/${tenantName}`, Template);
      setGlobalSelectedQuiz(templateName);
      setGlobalSelectedQuizId(response.data)
      setLoading(false);
      navigate(`/course-creator/${classId}`);
      setNameError("");
      setEditQuiz(false);
      setGlobalQuizShowPopup(true);
      setGlobalQuestionsArray([
        {
          question: "",
          questType: "normal",
          type: "",
          grade: "",
          icon: <></>,
          questPart: [],
          choices: [] as {
            name: string;
            percentage?: string;
            isChecked?: boolean;
          }[],
          feedRight: t(feedbackRight),
          feedWrong: t(feedbackWrong),
          subId: subIdSplitted,
          provider:provider,
          classSourceId: classId,
        },
      ]);
       // Delete the quizTemplate array from local storage
       localStorage.removeItem("quizTemplate");
       // Delete the name of the template
       localStorage.removeItem("quizTemplateName");
    }catch(error){
      console.log(error)
    }
  };

  const { Third_BgColor, boxShadow } = useRecoilValue(ColorSelector);

  return (
    <Container bgcolor={Third_BgColor} boxshadow={boxShadow}>
      <BodyContainer>
        {/* This is the Left Component */}
        <ToolBarContainer>
          <MainToolBar />
        </ToolBarContainer>
        {/* End of  the Left Component */}
        {(globalQuestionsArray[index]?.type === "" &&
          !globalQuestionsArray[index]?.questPart) ||
        (!globalQuestionsArray[index]?.type &&
          !globalQuestionsArray[index]?.questPart[partIndex]?.type) ? (
          <DropQuestion
            addMoreQuestion={addMoreQuestion}
            loadingData={loadingData}
            questions={globalQuestionsArray}
            DropQuestionType={DropQuestionType}
          />
        ) : (
          <FlexDiv>
            {/* This Is The Middle Component In The Creator */}
            <QuestionCRUD
              index={index}
              partIndex={partIndex}
              addChoice={addChoice}
              setDeleteChoice={setDeleteChoice}
              setChoiceIndex={setChoiceIndex}
              handleChoiceMatchingQuestion={handleChoiceMatchingQuestion}
              handleQuestion={handleFeedBackAndGrade}
              handleGrade={handleGrade}
              handleQuillChange={handleQuillChange}
              choice={choice}
              quest={quest}
              handleShowEditQuest={handleShowEditQuest}
              showQuest={showQuest}
              setShowPreviewPopup={setShowPreviewPopup}
              handleChoiceChecked={handleChoiceChecked}
              questionError={questionError}
              typeError={typeError}
              handleShuffle={handleShuffle}
              handleQIndexFromChild={handleQIndexFromChild}
              setChoice={setChoice}
              handleRadioChecked={handleRadioChecked}
              handleSelectRubricId={handleSelectRubricId}
              // isShuffled={isShuffled}
            />
            {(globalQuestionsArray[index]?.type === "" &&
              !globalQuestionsArray[index]?.questPart) ||
            (!globalQuestionsArray[index]?.type &&
              !globalQuestionsArray[index].questPart[partIndex]?.type) ? (
              ""
            ) : showQuest ? (
              ""
            ) : (
              <QuizButtonContainer>
                <CancelButton
                  onClickFunction={() => {
                    handleCancel();
                    setQuestionError("");
                    setTypeError("");
                  }}
                  name={t("quiz.Cancel")}
                  color="#D85D66"
                  borderColor="#D85D66"
                  width="8.8125rem"
                />
                {!editQuizButton ? <CancelButton
                  onClickFunction={() => onSave()}
                  name={t("survey.Save")}
                  color="#fff"
                  borderColor="#5DD3B3"
                  backgroundColor="#5DD3B3"
                  width="8.8125rem"
                />: ""}
                
              </QuizButtonContainer>
            )}
          </FlexDiv>
        )}
        <FlexDiv>
          {/* This Is The Right Component In The Creator */}
          <CreateQuestions
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            setDeleteQuestion={setDeleteQuestion}
            setShowCategoryPopup={setShowCategoryPopup}
            addMoreQuestion={addMoreQuestion}
            setShowshowLoadTemplate={setShowshowLoadTemplate}
            setIndex={setIndex}
            addMorePartQuestions={addMorePartQuestions}
            setPartIndex={setPartIndex}
            handleShowQuest={handleShowQuest}
            handleShowEditQuest={handleShowEditQuest}
            setDeletePart={setDeletePart}
            handleRandomQuestion={handleRandomQuestion}
            selectedRandomQuestion={selectedRandomQuestion}
            selectedRandomOption={selectedRandomOption}
            handleRandomOptions={handleRandomOptions}
            disableClick={disableClick}
            showQuest={showQuest}
            handleTemplateName={handleTemplateName}
            nameError={nameError}
            handleDynamicQuestionIndexFromChild={
              handleDynamicQuestionIndexFromChild
            }
            quest={quest}
            notValidate={notValidate}
            setQuestionError={setQuestionError}
            templateName={templateName}
            setShowQuestionBanksPopup={setShowQuestionBanksPopup}
            handleGrade={handleGrade}
            setQuest={setQuest}
            onSave={onSave}
            setEditQuest={handleShowEditQuest}
            setNotValidate={setNotValidate}
            partIndex={partIndex}
          />
          <FlexDiv2>
            <CancelButton
              onClickFunction={() => {
                handleCancelQuiz();
              }}
              name={t("quiz.Cancel")}
              color="#D85D66"
              borderColor="#D85D66"
              width="8.8125rem"
            />
            {globalQuestionsArray.length > 0 &&
              quest?.type !== "" &&
              notValidate === false &&
              (loading ? (
                <CancelButton
                  onClickFunction={() => ""}
                  name={`${t("general.loading")}...`}
                  color="#fff"
                  borderColor="#5DD3B3"
                  backgroundColor="#5DD3B3"
                  width="8.8125rem"
                />
              ) : !editQuizButton ? (
                <CancelButton
                  onClickFunction={() => SaveQuiz()}
                  name={t("quiz.Save Quiz")}
                  color="#fff"
                  borderColor="#5DD3B3"
                  backgroundColor="#5DD3B3"
                  width="8.8125rem"
                />
              ): '')}
          </FlexDiv2>
        </FlexDiv>
      </BodyContainer>
      {/* This Component Render Different Popup According to The States */}
      <PopupRenderer
        showCategoryPopup={showCategoryPopup}
        showLoadTemplate={showLoadTemplate}
        showPreviewPopup={showPreviewPopup}
        showQuestionBanksPopup={showQuestionBanksPopup}
        deleteChoice={deleteChoice}
        deleteQuestion={deleteQuestion}
        deletePart={deletePart}
        quest={quest}
        index={index}
        partIndex={partIndex}
        setShowQuest={setShowQuest}
        setEditQuest={setEditQuest}
        setShowPreviewPopup={setShowPreviewPopup}
        setDeleteChoice={setDeleteChoice}
        setDeletePart={setDeletePart}
        setShowQuestionBanksPopup={setShowQuestionBanksPopup}
        setDeleteQuestion={setDeleteQuestion}
        removeChoice={removeChoice}
        removePartQuestion={removePartQuestion}
        removeQuestion={removeQuestion}
      />
    </Container>
  );
};

export default GenerateQuiz;
