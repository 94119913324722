import React, { FC } from "react";
import { Oval } from "react-loader-spinner";

interface LoaderCircleProps {
  width?: number;
  height?: number;
  color?: string;
  secondaryColor?: string;
}

const LoaderCircle: FC<LoaderCircleProps> = ({
  width,
  height,
  color,
  secondaryColor,
}) => {
  return (
    <Oval
      height={height || 20}
      width={width || 20}
      color={color || "#fff"}
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor={secondaryColor || "#fff"}
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  );
};

export default LoaderCircle;
