import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AssignmentDefaultTableData } from "../../../../components/Tables/AssigmentDefaultTable/AssigmentDefaultTableData";
import { AnalyticsPageStylesLayout } from "../../AnalyticsPageStyles";
import { Typography } from "../../../../elements/fonts/Fonts";
import AnalyticShadowHeaderFourDisplays from "../../../../components/AnalyticShadowHeaderFourDisplays/AnalyticShadowHeaderFourDisplays";
import AnalyticOneTitleHeader from "../../../../components/AnalyticShadowHeaderFourDisplays/AnalyticOneTitleHeader";
import AssignmentSubmissionTable from "../../../../components/Tables/AssignmentSubmissionDetails/AssignmentSubmissionTable";
import AnalyticsAssignmentResponseTracking from "../../../../components/AnalyticsAssigmentResponseTraking/main/AnalyticsAssignmentResponseTracking";
import { AnalyticsForumData } from "../../../../components/Tables/AnalyticsForum/AnalyticsForumData";
import AnalyticsAssignmentGraphChart from "../../../../components/AnalyticsAssigmentResponseTraking/AnalyticsAssigmentGraphChart/AnalyticsAssigmentGraphChart";
import { AssignmentSubmissionData } from "../../../../components/Tables/AssignmentSubmissionDetails/AssignmentSubmissionData";
import AnalyticsAssignmentBarChart from "../../../../components/AnalyticsAssigmentResponseTraking/AnalyticsAssignmentBarChart/AnalyticsAssignmentBarChart";
import AnalyticsBackPage from "../../../../components/AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticsBackPage";
import { FlexAppContainer } from "../../../../App.style";

const AssignmentDetails = () => {
  const navigate = useNavigate();
  const NavigatePage = () => {
    navigate(-1);
  };
  // Get the MainCode parameter from the URL using React Router's useParams hook
  const { MainCode } = useParams<{ MainCode: string }>();

  // Find the assignment data based on the MainCode parameter
  const AssignmentData = AssignmentDefaultTableData.find(
    (item) => item.MainCode === MainCode
  );

  // State variables to manage visibility of different components
  const [ShowDetailsTable, setShowDetailsTable] = useState(false);
  const [ShowSubmissionTrackingGraph, setShowSubmissionTrackingGraph] =
    useState(false);
  const [
    ShowAnalyticsAssignmentGraphChart,
    setShowAnalyticsAssignmentGraphChart,
  ] = useState(false);

  // Toggle function to show/hide details table
  const handleShowDetailsTable = () => {
    setShowDetailsTable(!ShowDetailsTable);
  };

  // Toggle function to show/hide submission tracking graph
  const handleShowSubmissionTracking = () => {
    setShowSubmissionTrackingGraph(!ShowSubmissionTrackingGraph);
  };
  const handleShowAnalyticsAssignmentGraphChart = () => {
    setShowAnalyticsAssignmentGraphChart(!ShowAnalyticsAssignmentGraphChart);
  };
  const AllStudents = AnalyticsForumData?.Students?.length;
  const SubmittedStudents = AnalyticsForumData.Students?.filter(
    (item) =>
      (item.Status &&
        typeof item.Status === "string" &&
        (item.Status as string) === "Yes") ||
      (typeof item.Status === "string" &&
        (item.Status as string) === "Submitted")
  ).length;
  const data = AssignmentSubmissionData.Students;
  const MaxGrade = AssignmentSubmissionData.MaxGrade;
  return (
    <AnalyticsPageStylesLayout>
      <AnalyticsBackPage onClick={NavigatePage} />
      {/* Title for the page */}
      <Typography text="Assignment Analytics" fontSize="1.75rem" />

      {/* Header component displaying four values */}
      <AnalyticShadowHeaderFourDisplays
        valA={`${SubmittedStudents} out of ${AllStudents}`}
        valB={AssignmentSubmissionData?.StartDate}
        valC={AssignmentSubmissionData?.DueDate}
        valD={AssignmentSubmissionData?.CutOffDate}
      />

      {/* Conditional rendering based on ShowSubmissionTrackingGraph state */}
      {ShowSubmissionTrackingGraph ? (
        <AnalyticsAssignmentResponseTracking
          onClick={handleShowSubmissionTracking} // Pass toggle function as prop
        />
      ) : (
        <AnalyticOneTitleHeader
          Title="Submission Tracking"
          onClick={handleShowSubmissionTracking} // Pass toggle function as prop
        />
      )}

      {/* Conditional rendering based on ShowDetailsTable state */}
      {ShowDetailsTable ? (
        <AssignmentSubmissionTable HeaderTextClick={handleShowDetailsTable} />
      ) : (
        <AnalyticOneTitleHeader
          Title="Submission Details"
          onClick={handleShowDetailsTable} // Pass toggle function as prop
        />
      )}

      {/* Conditional rendering based on ShowAnalyticsAssignmentGraphChart state */}
      {ShowAnalyticsAssignmentGraphChart ? (
        <FlexAppContainer column={true} gap="1.25rem" padding="0 0 2rem 0">
          <AnalyticsAssignmentBarChart
            Data={data}
            titleClick={handleShowAnalyticsAssignmentGraphChart}
            title="Grade Distribution"
          />
          <AnalyticsAssignmentGraphChart
            titleClick={handleShowAnalyticsAssignmentGraphChart}
            Data={data}
            MaxGrade={MaxGrade || 20}
            title="Grade Distribution"
          />
        </FlexAppContainer>
      ) : (
        <AnalyticOneTitleHeader
          Title="Grade Distribution"
          onClick={handleShowAnalyticsAssignmentGraphChart}
        />
      )}
    </AnalyticsPageStylesLayout>
  );
};
export default AssignmentDetails;
