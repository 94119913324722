import styled from 'styled-components'
import { StyleThemeInterface } from '../../../interfaces/StyleThemeInterface';

export const ToDoContainer = styled.div<StyleThemeInterface>`
  width: 25.9375rem;
  flex: 1; 
  display: flex;
  flex-direction: column; 
  border-radius: 0.625rem;
  background:${props =>props.bgcolor};
  box-shadow: ${props =>props.boxshadow}; 
  min-height:100vh;
`;

export const ParentContainer = styled.div`
  
  display: flex; 
  flex-direction: column; 
  padding:1.25rem;
`;

export const EmptyCardContainer=styled.div`
 display: flex;
 flex-direction:column;
 justify-content:center;
 align-items:center;
 height: 100vh;
 
`;
export const TaskContainer=styled.div`
padding: 1.25rem;
display: flex;
flex-direction:column;
gap:0.62rem;
`;

