import styled from "styled-components"

export const FormStyled = styled.form`
display: flex;
flex-direction: column;
gap: 0.63rem;
`
export const InstructionTextRow = styled.div`
display: flex;
flex-direction: column;
    padding: 0rem 3rem 1.25rem 3rem;
    gap: 0.63rem;
    color: #262626;
&>:last-child{
    color: #576474;
}
`