import React, { FC, useState } from "react";
import {
  ChoiceContainer,
  CheckBox,
  ChoiceMainContainer,
} from "./PreviewTrueFalse.style";
import { PreviewTrueFalseProps } from "./PreviewTrueFalseInterface";
import { TypographyRegularDescription } from "../../../elements/fonts/Fonts";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";

const PreviewTrueFalse: FC<PreviewTrueFalseProps> = ({ choice }) => {
  const [isTrueChecked, setIsTrueChecked] = useState(false);
  const [isFalseChecked, setIsFalseChecked] = useState(false);

  const handleTrueChange = () => {
    setIsTrueChecked(true);
    setIsFalseChecked(false);
  };

  const handleFalseChange = () => {
    setIsTrueChecked(false);
    setIsFalseChecked(true);
  };
  const {RadioButtonGradientColor } =
  useRecoilValue(ColorSelector);
  return (
    <ChoiceMainContainer>
      <ChoiceContainer>
        <CheckBox
          type="radio"
          name="trueFalse"
          id="isChecked1"
          checked={isTrueChecked}
          onChange={handleTrueChange}
          bgcolor={RadioButtonGradientColor}
        />
        <TypographyRegularDescription
          text="True"
          fontSize="16px"
          lineHeight="22px"
          fontWeight="400"
        />
        <CheckBox
          type="radio"
          name="trueFalse"
          id="isChecked2"
          checked={isFalseChecked}
          onChange={handleFalseChange}
          bgcolor={RadioButtonGradientColor}
        />
        <TypographyRegularDescription
          text="False"
          fontSize="16px"
          lineHeight="22px"
          fontWeight="400"
        />
      </ChoiceContainer>
    </ChoiceMainContainer>
  );
};

export default PreviewTrueFalse;
