import styled from "styled-components";
import { breakpoints } from '../../../theme';


export const EditModalWrapper = styled.div`
/* max-width: 45rem; */
width: 100%;
height: 100%;
border-radius: 0rem 0.625rem 0.625rem 0rem;
background: #384450;
border-left: 4px solid #5DD3B3;
padding: 2.69rem 2.5rem 0rem 2.5rem;

@media (max-width: ${breakpoints.tablets}) {
width: 100%; /* Full width on smaller screens */
    height: 100%;
    /* padding-bottom: 1.25rem; */
}
`
export const TitleExitRow = styled.div`
display: flex;
justify-content: space-between;
align-items: flex-start;
margin-bottom: 2.31rem;
`
export const TitleGroupLogo = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 0.62rem;
`
export const InputTitle = styled.input.attrs({id:"title", type:"text"})`
width: 99%;
outline: none;
color: white;
height: 2.8125rem;
flex-shrink: 0;
border-radius: 0.625rem;
border: 1px solid #576371;
background: transparent;
padding-left: 1.25rem;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
font-family: ${(props) => props.theme.font.primaryLight};
&::placeholder{
    color: #FFF;
    font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
font-family: ${(props) => props.theme.font.primaryLight};
}
`
export const TitleLabel = styled.label.attrs({htmlFor:"title"})`
margin-bottom: 0.63rem;
display: flex;
`

export const InputDescription = styled.textarea.attrs({id:"desc", type:"text"})`
outline: none;
resize: none;
color: #FFF;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
font-family: ${(props) => props.theme.font.primaryLight};
width: 99%;
height: 50%;
flex-shrink: 0;
border-radius: 0.625rem;
border: 1px solid #576371;
background: transparent;
padding-top: 1.25rem;
padding-left: 1.25rem;
line-height: 1;
&::placeholder{
    color: #FFF;
    font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
font-family: ${(props) => props.theme.font.primaryLight};
display: flex;
justify-content: flex-start;
height: 100%;
}
`
export const DescLabel = styled.label.attrs({htmlFor:"desc"})`
margin-bottom: 0.63rem;
margin-top: 1.25rem;
display: flex;
`
export const ButtonsRow = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
gap: 0.62rem;
margin-top: 1.12rem;
`
export const ButtonCancel = styled.button`
width: 7.625rem;
height: 2.125rem;
flex-shrink: 0;
border-radius: 1.25rem;
border: 1px solid #5DD3B3;
background:none;
cursor: pointer;
`
export const ButtonAdd = styled.button`
outline: none;
border: none;
width: 7.625rem;
height: 2.125rem;
flex-shrink: 0;
border-radius: 1.25rem;
background: #5DD3B3;
cursor: pointer;
`

export const LeftBorderColor = styled.div`
width: 37.75rem;
height: 1rem;
transform: rotate(90deg);
stroke-width: 4px;
color: #5DD3B3;
position: absolute;
`

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3; /* Ensure it's above other content */
  @media (max-width: 700px) {
   /* width: 50%; */
  }
`;

export const ModalContent = styled.div`
  background: #384450;
  /* padding: 20px; */
width: 50%;
height: 52.11%;
min-width: 20rem;
min-height: 35rem;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;