import styled from "styled-components";
import { breakpoints, theme } from "../../theme";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";
// import { breakpoints } from "../../theme";

interface TableTdProps {
  color?: string;
}

export const GradeBookWrapper = styled.div<StyleThemeInterface>`
  height: fit-content;
  /* background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%)); */
  background: ${(props) => props.bgcolor};
  box-shadow: ${(props) => props.boxshadow};
  padding-top: 1.25rem;
  margin: 0rem 0rem 1.25rem 0rem;
  width: 100%;
  border-radius: 1rem;
`;
export const AlertPublishSearchRow = styled.div`
  margin: 0rem 0 1rem 0;
  &:dir(ltr) {
    padding: 0 0 0 1.55rem;
  }
  &:dir(rtl) {
    padding: 0 1.55rem 0 0;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* background: aqua; */
  position: relative;
  /* padding-right: 2rem; */
`;
export const InputWrapper = styled.div`
  &:dir(ltr) {
    margin-right: -1.1rem;
  }
  &:dir(rtl) {
    margin-left: -1.1rem;
  }
`;
export const AlertPublishRow = styled.div`
  display: flex;
  /* width: ; */
  align-items: center;
  justify-content: flex-start;
  align-items: center;
  gap: 1.25rem;
  /* margin-left:0.4rem */
`;
export const AlertWithLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.62rem;
`;
export const PublishWithInput = styled.div`
  display: flex;
  align-items: center;
  gap: 0.62rem;
`;
export const FiltersActionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0rem 1.25rem;
  @media (max-width: ${breakpoints.small}) {
    flex-wrap: wrap;
    row-gap: 1.25rem;
    justify-content: center;
  }
`;
export const FilterBtnsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.62rem;
  width: auto;
  @media (max-width: ${breakpoints.small}) {
    flex-wrap: wrap;
  }
`;
export const ActionsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 1.25rem;
  &:dir(ltr) {
    margin-right: 0 !important;
  }
  &:dir(rtl) {
    margin-left: 0 !important;
  }
  @media (max-width: ${breakpoints.small}) {
    flex-wrap: wrap;
  }
`;
export const LogoAndAction = styled.div`
  display: flex;
  align-items: center;
  gap: 0.56rem;
  cursor: pointer;
`;
export const SortingFilter = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0rem;
`;
export const TableSmallSection = styled.div`
  width: 11.3rem;
  display: flex;
  min-height: fit-content;
  overflow-x: hidden;
  /* margin-top: 1.25rem; */
  position: relative;
`;
export const TableSection = styled.div`
  width: 100%;
  display: flex;
  min-height: fit-content;
  overflow-x: hidden;
  margin-top: 1.25rem;
  position: relative;
  scroll-behavior: smooth;
`;
export const TableFixedContentHolder = styled.table`
  width: auto;
  min-width: 900px;
  border-collapse: collapse;
  border: none;
  margin-top: 1.35rem;
`;

export const ArrowContainer = styled.div`
  margin-top: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap:1.25rem; */
  padding-bottom: 1rem;
`;

export const TableContent = styled.table`
  max-height: 100%;
  height: fit-content;
  /* width: 100%; */
  border-collapse: collapse;
  overflow-x: auto;
  white-space: nowrap;
  border: none;
  width: 100%;
`;

export const TableThead = styled.thead`
  border: none;
  white-space: nowrap;
  position: sticky;
`;

export const TableTh = styled.th`
  border: none;
  height: 3.5625rem;

  border-collapse: collapse;
  &:dir(ltr) {
    padding: 0rem 6rem 0rem 2rem;
  }
  &:dir(rtl) {
    padding: 0rem 2rem 0rem 6rem;
  }
  text-align: center;
  width: max-content;
`;

export const TableTbody = styled.tbody`
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
`;

export const TableTh1 = styled.th`
  /* border-collapse: collapse; */
  padding: 1rem 1rem 1.38rem 1rem;
  border: none;

  /* margin: 1rem; */
`;

export const TableTd1 = styled.td<TableTdProps>`
  border: none;
  height: 3.5625rem;
  border-collapse: collapse;
  /* padding: 0rem 1rem 0rem 2rem; */
  text-align: center;
  width: max-content;

  color: ${({ color }) => color};
`;

export const TableFixedTd = styled.div`
  border: none;
  height: 3.5625rem;

  border-collapse: collapse;
  padding: 1.25rem 0 1.25rem 0;

  /* text-align: center; */
  min-width: 10.1rem;
  color: ${({ color }) => color};
`;
export const DivArrowsWrapper = styled.div`
  width: 2.625rem;
  height: 1.25rem;
  border-radius: 1.25rem;
  background-color: #364350;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  /* position: absolute; */
  bottom: 1.25rem;
  /* left: 38.255rem; */
  &:dir(ltr) {
    left: 48%;
  }
  &:dir(rtl) {
    right: 48%;
  }
  display: flex;
  justify-content: space-around;
  align-items: center;
  & > :first-child {
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.dark.main_color};
      transition: color 0.2s ease-in-out;
    }
  }
  & > :last-child {
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.dark.main_color};
      transition: color 0.2s ease-in-out;
    }
  }
`;

export const Input = styled.input<StyleThemeInterface>`
  border: none;
  color: white;
  font-weight: 400;
  font-size: 0.75rem;
  width: 2.625rem;
  height: 1.8125rem;
  background: transparent;
  border-radius: 0.3125rem;
  outline: none;
  font-family: ${theme.font.primaryRegular};
  text-align: center;
  border: 1px solid ${(props) => props.bordercolor || "#5dd3b3"};

  &:dir(ltr) {
    margin-right: 0.5rem;
  }
  &:dir(rtl) {
    margin-left: 0.5rem;
  }
  &::placeholder {
    text-align: center;
    color: white;
  }
`;

export const FilterWithName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.63rem;
`;

export const FlexedDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.63rem;
`;

export const FlexedHeadDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.63rem;
`;

export const TopSection = styled.div`
  display: flex;
`;

export const SectionCell = styled.div`
  padding: 1rem;
`;

export const TableTdTop = styled.td<StyleThemeInterface>`
  align-items: center;
  border: none;
  min-width: 12rem;
  padding: 0 1.25rem;
  height: 3.98rem;
  &:dir(ltr) {
    border-right: ${(props) =>
      props.theme !== "light" ? "1px solid rgba(255, 255, 255, 0.2)" : ""};
  }
  &:dir(rtl) {
    border-left: ${(props) =>
      props.theme !== "light" ? "1px solid rgba(255, 255, 255, 0.2)" : ""};
  }
`;

export const TableTd = styled.td<StyleThemeInterface>`
  align-items: center;
  border: none;
  min-width: 12rem;
  padding: 0 1.25rem;
  height: 3.98rem;
`;

export const TableTr = styled.tr<StyleThemeInterface>`
  &:nth-child(odd) {
    background: ${(props) =>
      props.theme === "light" ? "#AAB1B920" : "rgba(255, 255, 255, 0.03)"};
  }
`;

export const TableContentHolder = styled.table`
  width: 100%;
  min-width: 800px;
  border: none;
  border-collapse: collapse;
`;

export const GradeBookTableTdHeader = styled.td<StyleThemeInterface>`
  align-items: center;
  justify-content: space-around;
  border: none;
  min-width: 15rem;
  padding: 1.25rem 1.25rem 1.37rem 1rem;

  &:dir(ltr) {
    border-right: ${(props) =>
      props.theme !== "light" ? "1px solid rgba(255, 255, 255, 0.2)" : ""};
  }
  &:dir(rtl) {
    border-left: ${(props) =>
      props.theme !== "light" ? "1px solid rgba(255, 255, 255, 0.2)" : ""};
  }
`;

export const GradebookHeadercontainer = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  gap: 1.25rem;
  &:dir(ltr) {
    padding-right: 1rem;
  }
  &:dir(rtl) {
    padding-left: 1rem;
  }

  height: 100%;
`;

export const GradeBookMainTableContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1.25rem;
  padding-bottom: 1.25rem;
`;

export const GradeBookColumns = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 17rem !important;
  width: 15% !important;
`;

export const GradeBookStudentCell = styled.div<{
  firstBgColor?: string;
  theme?: string;
  bgColor?: string;
  language?: string | null;
}>`
  display: flex;
  width: 100%;
  height: 3.98rem;
  padding: 0 1.25rem;
  align-items: center;
  background: ${(props) => props.bgColor};

  &:nth-child(odd) {
    background: ${(props) =>
      props.theme === "light" ? "#AAB1B920" : "rgba(255, 255, 255, 0.03)"};
  }

  &:first-child {
    background: ${(props) =>
      props.theme === "light"
        ? "#AAB1B940"
        : props.language === "ar" && props.theme === "dark"
        ? "linear-gradient(90deg, #415160 0%, #41516030  107.88%)"
        : "linear-gradient(90deg, #41516030 0%, #415160 107.88%)"};
    height: 3.98rem;
  }
  &:nth-child(2) {
  }
`;
export const GradeBookStudentCellTest = styled.div<{ theme?: string }>`
  display: flex;
  width: 100%;
  height: 3.98rem;
  padding: 0 1.25rem;
  align-items: center;
  background: ${(props) => (props.theme === "light" ? "#AAB1B940" : "#41515F")};
`;

export const RotatedArrow = styled.div`
  transform: rotate(180deg);
  margin: 0;
  padding: 0;
`;
export const ArrowHolder = styled.div`
  &:dir(ltr) {
    margin-left: 8rem;
  }
  &:dir(rtl) {
    margin-right: 8rem;
  }
`;
