import { Navigate, useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import {
  SvgAlert,
  SvgDataLines,
  SvgDownload,
  SvgDropDownArrow,
  SvgEye,
  SvgGraduateHat,
  SvgImportIcon,
  SvgManage,
  SvgRubric,
  SvgUpload,
  SvgloadingGroup,
} from "../../../../elements/Icons";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../../../elements/fonts/Fonts";
import ReusableInput from "../../../../elements/reusableInput/ReusableInput";
import {
  ActionsDiv,
  AlertPublishRow,
  AlertPublishSearchRow,
  AlertWithLogo,
  FilterBtnsDiv,
  FiltersActionsRow,
  FlexedDiv,
  GradeBookWrapper,
  LogoAndAction,
  PublishWithInput,
  InputWrapper,
} from "../../GradeBookTable.style";

import {
  ChangeEvent,
  MouseEvent,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  AssignmentData,
  StudentData,
  Activities,
} from "../NewGradeBookTableData";
import { ButtonFilledWrapper } from "../../../../elements/FiltersButtonFilled/FiltersButtonFilled.style";
import SelectInput from "../../../../elements/SelectInput/SelectInput";
import AlertSettingsPopUp from "../../AlertSettingsPopup/AlertSettingsPopUp";
import PopUp from "../../../../elements/Popup/PopUp";
import { MyContextProvider } from "../../../../contexts/GraderContext";
import { ClassContext } from "../../../../Views/dashboard/UnstructuredContext";
import {
  ButtonCancel,
  ButtonsRow,
} from "../../../GradesSchema/GradesSchema.style";
import { ButtonAdd } from "../../../manageWeight/ManageWeight.style";

import { publishGrade } from "../../../../utils/GradePointToLetter";

import {
  calculateTotalGrade,
  getLetterGrade,
  handleAlertSettings,
  handleDownloadGrades,
  // handleDownloadTemplate,
  handleFilter,
  handleSearch,
  handleSelectChangeEnrollment,
  parseExcelData,
  sortArrayByIndexScore,
  transformData,
} from "../GradebookFunctions";
import { StudentAttendance } from "../NewGradeBookTableInterface";
import NewTeacherView from "./DifViews/NewTeacherView";
import { GradebookData } from "./NewGradebookData";
import SimpleTable from "./DifViews/NewTeacherView";
import { transform } from "lodash";
import {
  ColumnDiv,
  DropdownContainer,
  DropdownItem,
  SchemaLink,
} from "../NewGradeBookTable.style";
import NewStudentView from "./DifViews/NewStudentView";
import axios from "axios";
import AttendancePopup from "../../../attendanceAllSessions/AttendancePopup";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../../../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import { get } from "../../../../utils/AxiosRequests";
import EmptyGradebook from "./emptyGradebook";
import LoadingGradebook from "./LoadingGradeBook";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  ColorSelector,
  CourseColorSelector,
} from "../../../../recoil/ThemeSelectors";
import { FlexAppContainer } from "../../../../App.style";
import { useTranslation } from "react-i18next";
import { formatOptionsForTranslation } from "../../../../utils/FormatOptionsForTranslation";
import { LoadingContainerSvg } from "../../../LoadingComp/LodingStyle";
import SelectDropDown from "../../../../elements/selectDropDown/SelectDropDown";
import AttendancePopupNew from "../../../attendanceAllSessions/AttendancePopupNew";
import AttendancePopupHeader from "../../../attendanceAllSessions/AttendancePopupHeader";
import { DashboardContentsHolder } from "../../../dashboardContents/main/DashboardContents.style";
import UploadPopup from "./UploadPopup/UploadPopup";
import { toast } from "react-toastify";
import { adminRoleState, adminState } from "../../../../recoil/RecoilStates";

interface Activity {
  id: string;
  name: string;
  grades: {
    name: string;
    id: string;
    score: number;
    weight: number;
  }[];
}

interface Student {
  id: string;
  name: string;
  activities: Activity[];
  attendance: {
    score: number;
    weight: number;
  }[];
}
interface TransformedData {
  students: any[];
  MainActivity: {
    id: string;
    name: string;
    weight: any;
  }[];
}
interface DashboardGradesProps {
  cardId?: string;
  cardRole?: string;
  dashboardGrades?: boolean;
}

interface ActivityData {
  id: number;
  activityName: string;
  activityType: string;
  activityId: number;
  moduleIndex: number;
  weight: number;
  publish: boolean;
  student: string;
  studentId: string;
  score: number;
  isGraded: boolean;
  maxGrade: number;
  isOffline: boolean;
}
const GradebookTableV2 = ({
  cardId,
  cardRole,
  dashboardGrades,
}: DashboardGradesProps) => {
  const [isAdmin] = useRecoilState(adminState);
  const [adminRole] = useRecoilState(adminRoleState);
  const [isLoading, setIsLoading] = useState(true);
  const [alertSettingsPopUp, setAlertSettingsPopUp] = useState(false);
  const [AttendancePopUp, setAttendancePopUp] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [Noinfo, setNoinfo] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [numberModel, setNumberModel] = useState("Point");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [nameSortOrder, setNameSortOrder] = useState<"asc" | "desc">("asc");
  const [attendanceScores, setAttendanceScores] = useState<StudentAttendance[]>(
    []
  );
  const [filteredStudents, setFilteredStudents] = useState<any>(GradebookData);
  const [openMessageDivId, setOpenMessageDivId] = useState(null);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const enrollmentOptions = [
    { name: "course view.Point", value: "Point" },
    { name: "gradebook.Letter", value: "Letter" },
  ];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [gradeData, setGradeData] = useState<Student[] | null>(null);
  const { role } = useContext(ClassContext);
  const [Mainactivities, setMainActivities] = useState<any>([]);
  const [selectedStudentId, setSelectedSTudentId] = useState<string>("");
  const [AttendanceLoading, SetAttendanceLoading] = useState<boolean>(true);
  const [filters, SetFilters] = useState([]);
  const [UploadPopupShow, SetUploadPopupShow] = useState(false);
  const [ExcelData, SetExcelData] = useState<ActivityData[]>([]);
  const [allData, setAllData] = useState<any>({
    students: [],
    MainActivity: [],
  });
  const [uploadingLoading, setUplaodLoading] = useState<boolean>(false);
  const { ClassGradientBox, theme } = useRecoilValue(CourseColorSelector);
  const { JustLightBoxShadow, bg_selectColor, specialColor2 } =
    useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  // const { courseTitleBgColor } = useRecoilValue(courseBannerColorSelector);
  const tenantName = localStorage.getItem("tenant");
  const language = localStorage.getItem("language");
  const handleToggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };
  const navigate = useNavigate();
  const id = useParams();
  const handleClick = () => {
    navigate(`/courseview/Gradebook/ManageWeightView/${id.classId}`);
  };
  const handleClickUpload = () => {
    SetUploadPopupShow(!UploadPopupShow);
  };
  const handleClickActivities = () => {
    navigate(`/courseview/Gradebook/GradeItemView/${id.classId}`);
  };
  const columnWidth = 378;
  const { user, getAccessTokenSilently } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const translatedEnrollmentOptions = formatOptionsForTranslation(
    enrollmentOptions,
    t
  );
  const getFilters = async () => {
    try {
      if (cardId !== null && cardId !== undefined) {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/grades/filter/get/${cardId}/${tenantName}`
        );
        SetFilters(response.data.data);
      } else {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/grades/filter/get/${id.classId}/${tenantName}`
        );
        SetFilters(response.data.data);
      }
      // Handle the response data as needed
    } catch (error) {
      console.error("There was an error making the GET request!", error);
    }
  };
  const handleAnalytic = () => {
    navigate(`/courseView/Analytics/${id.classId}`);
  };

  const handleSearch = (
    searchValue: string,
    setFilteredStudents: React.Dispatch<React.SetStateAction<any>>,
    allStudents: any[]
  ) => {
    // Filter students whose name matches the search value
    const filteredStudents = allStudents.filter((student) => {
      // Perform case-insensitive search
      return student.name.toLowerCase().includes(searchValue.toLowerCase());
    });

    // Create an object with the correct structure
    const filteredData = {
      students: filteredStudents,
      MainActivity: allData.MainActivity, // Add MainActivity if required
    };

    // Set filtered students to the students matching the search criteria
    setFilteredStudents(filteredData);
  };

  useEffect(() => {
    if (tableContainerRef.current) {
      const newScrollPosition = currentIndex * columnWidth;
      tableContainerRef.current.scrollLeft = newScrollPosition;
      setScrollPosition(newScrollPosition);

      if (newScrollPosition <= 0) {
        setCanScrollLeft(false);
      } else {
        setCanScrollLeft(true);
      }

      if (
        tableContainerRef.current.scrollWidth - newScrollPosition <=
        tableContainerRef.current.clientWidth
      ) {
        setCanScrollRight(false);
      } else {
        setCanScrollRight(true);
      }
    }
  }, [currentIndex]);

  useEffect(() => {
    const initialAttendanceScores = StudentData.map((student) => ({
      id: student.id,
      score: student.Attendance.score,
    }));
    getFilters();
    setAttendanceScores(initialAttendanceScores);
    fetchGradebookData();
    fetchMainActivityData();
  }, []);

  const calculateAverageGrade = () => {
    let totalGrades = 0;
    let totalStudents = 0;

    // Iterate over each student
    allData.students.forEach((student: { id: string }) => {
      const totalGrade = calculateTotalGrade(student.id, allData, filters); // Calculate the total grade for the student
      totalGrades += totalGrade; // Add the total grade to the sum
      totalStudents++; // Increment the total number of students
    });

    // Calculate the average total grade
    const averageTotalGrade =
      totalStudents > 0 ? totalGrades / totalStudents : 0;
    return averageTotalGrade;
  };

  const isFailing = (grade: number) => {
    return grade < 60; // Assuming failing grade is below 60%
  };

  const handleFilterAllGrades = () => {
    // Create an object with the correct structure
    const filteredData = {
      students: allData.students,
      MainActivity: allData.MainActivity, // Add MainActivity if required
    };
    setFilteredStudents(filteredData);
  };

  // Function to handle filtering grades below average
  const handleFilterBelowAverage = () => {
    // Calculate the average total grade
    const averageTotalGrade = calculateAverageGrade();

    // Filter students whose total grade is below the average total grade
    const belowAverageStudents = allData.students.filter(
      (student: { id: string }) => {
        const totalGrade = calculateTotalGrade(student.id, allData, filters);
        return totalGrade < averageTotalGrade;
      }
    );

    // Create an object with the correct structure
    const filteredData = {
      students: belowAverageStudents,
      MainActivity: GradebookData.MainActivity, // Add MainActivity if required
    };

    // Set filtered students to below average students
    setFilteredStudents(filteredData);
  };

  // Function to handle filtering failing grades
  const handleFilterFailingGrades = () => {
    const failingStudents = allData.students.filter(
      (student: { id: string }) => {
        return isFailing(calculateTotalGrade(student.id, allData, filters));
      }
    );

    // Create an object with the correct structure
    const filteredData = {
      students: failingStudents,
      MainActivity: allData.MainActivity, // Add MainActivity if required
    };

    setFilteredStudents(filteredData); // Set filtered students to failing students
  };

  const fetchGradebookData = async () => {
    setIsLoading(true);
    try {
      if (cardId !== null && cardId !== undefined) {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/gradebook/grades/${tenantName}/${cardId}`
        );
        setGradeData(response.data.data);
        console.log(response.data.data, "gradeData1")

      } else {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/gradebook/grades/${tenantName}/${id.classId}`
        );
        setGradeData(response.data.data);

      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching gradebook data:", error);
    }
  };

  const fetchMainActivityData = async () => {
    setIsLoading(true);
    try {
      if (cardId !== null && cardId !== undefined) {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/activities/weight/${cardId}/${tenantName}`
        );
        setMainActivities(response.data.data);
      } else {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/activities/weight/${id.classId}/${tenantName}`
        );
        setMainActivities(response.data.data);
      }
      setIsLoading(true);
    } catch (error) {
      console.error("Error fetching main activity data:", error);
    }
  };

  useEffect(() => { }, [Noinfo]);

  useEffect(() => {
    if (Mainactivities.length > 0 && gradeData ? gradeData.length > 0 : "") {
      const transformedData = transformData(Mainactivities, gradeData);
      setAllData(transformedData);
      if (transformedData) {
        setFilteredStudents(transformedData);
      }
    }
  }, [Mainactivities, gradeData]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Using optional chaining to access files safely
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = e.target?.result;
        if (
          typeof fileContent === "string" ||
          fileContent instanceof ArrayBuffer
        ) {
          parseExcelData(fileContent); // Call parseExcelData with the file content
        } else {
          console.error("Invalid file content.");
        }
      };
      reader.readAsBinaryString(file);
    } else {
      console.error("No file selected.");
    }
  };
  const isEmptyData =
    allData.students.length === 0 ||
    gradeData?.length === 0 ||
    Mainactivities.length === 0 ||
    transformData.length === 0 ||
    AssignmentData.length === 0;

  const parseGradebookFile = (file: any): Promise<ActivityData[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      try {
        reader.onload = (event) => {
          const data = new Uint8Array(event.target?.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: "array" });

          // Assuming the first sheet is the one with the grades
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json<any>(worksheet, {
            header: 1,
          });

          const [header, ...rows] = jsonData;

          // Extract activity details from header
          const activities = header
            .slice(2)
            .map((activityHeader: string) => {
              const match = activityHeader.match(
                /(.+?) \((.+?), (true|false), (\d+|attendance), Maximum Grade: (\d+)\)/
              );
              return match
                ? {
                  name: match[1],
                  type: match[2],
                  isOffline: match[3] === "true",
                  id: parseInt(match[4], 10),
                  maxGrade: parseInt(match[5], 10), // Extract maximum grade from regex match
                }
                : null;
            })
            .filter(Boolean) as {
              name: string;
              type: string;
              isOffline: boolean;
              id: number;
              maxGrade: number;
            }[];

          // Parse rows into activity data
          try {
            const activityData: ActivityData[] = rows.flatMap((row) => {
              const [studentName, studentId, ...scores] = row;

              return scores.map((score: string, index: number) => {
                // Replace "-" with 0
                const parsedScore =
                  score === "-" ? null : parseFloat(score) || 0;
                const maxGrade = activities[index].maxGrade;

                // Validate score against maxGrade
                if (parsedScore !== null && parsedScore > maxGrade) {
                  toast.error(
                    `Grade should be less than or equal to maximum grade in ${activities[index].name}`
                  );
                  reject(new Error("Invalid score: exceeds maximum grade")); // Reject promise on validation error
                  return null; // Return null to skip adding this data to activityData
                }

                return {
                  activityType: activities[index].type,
                  activityId: activities[index].id,
                  studentId: studentId.toString(),
                  score: parsedScore,
                  isOffline: activities[index].isOffline,
                  maxGrade: maxGrade,
                };
              });
            });

            if (activityData.some((data) => data === null)) {
              // If any score validation failed, reject the promise
              reject(new Error("Invalid score: exceeds maximum grade"));
              return;
            }

            SetExcelData(activityData);
            resolve(activityData);
          } catch (error) {
            toast.error("Error uploading the file");
          }
        };
        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsArrayBuffer(file);
      } catch (error) {
        toast.error(
          "error uploading the excel file, Please redonwload a template and try again"
        );
      }
    });
  };

  const [DropZoneFile, SetDropZoneFile] = useState(null);
  const handleDoneClick = async () => {
    const endpoint = `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/grade/update/${tenantName}`;

    try {
      setUplaodLoading(true);
      // Assuming parseGradebookFile returns the structured data you need
      const grades = await parseGradebookFile(DropZoneFile);

      // Map the parsed data into the structure required by your API

      const data = {
        classSourceId: id.classId, // Replace with your actual classSourceId
        subId: subIdSplitted, // Replace with your actual subId
        provider: provider,
        grades: grades,
      };

      const endpoint = `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/grade/update/${tenantName}`;
      // Send POST request with structured data
      const response = await axios.post(endpoint, { data: data });
      setUplaodLoading(false);
      fetchGradebookData();
      SetUploadPopupShow(false);
      toast.success("Grades Updated successfully");

      // Optionally, perform any actions after successful update
    } catch (error) {
      setUplaodLoading(false);
      console.error("Error updating data:", error);
      toast.error(
        "error updating data,Please re-download a template and try again"
      );
      // Handle errors as needed
    }
  };

  interface Activity {
    id: any;
    name: any;
  }

  interface Student {
    name: string;
    activities: { [key: string]: string }; // You can adjust the value type as needed
  }

  const ExportGrades = (data: any[] | null) => {
    try {
      const activities: {
        maxGrade: any;
        id: any;
        name: any;
        type: any;
        isOffline: any;
      }[] = [];
      const students: {
        [key: string]: { name: any; activities: { [key: string]: any } };
      } = {};

      data?.forEach(
        (item: {
          maxGrade: any;
          activityId: any;
          activityName: any;
          activityType: any;
          isOffline: any;
          studentId: string;
          student: string;
          score: number;
          gradedByInstructor: boolean;
        }) => {
          if (item.activityType === t("excel.Attendance")) {
            // Handle Attendance activity
            if (
              !activities.some(
                (activity) => activity.type === t("excel.Attendance")
              )
            ) {
              activities.push({
                id: t("excel.attendance"), // Set a unique identifier for attendance
                name: t("excel.attendance"),
                type: t("excel.Attendance"),
                isOffline: true,
                maxGrade: 100,
              });
            }
            if (!students[item.studentId]) {
              students[item.studentId] = { name: item.student, activities: {} };
            }
            students[item.studentId].activities[t("excel.attendance")] =
              item.score;
          } else {
            // Handle other activity types
            if (
              !activities.some((activity) => activity.id === item.activityId)
            ) {
              activities.push({
                id: item.activityId,
                name: item.activityName,
                type: item.activityType,
                isOffline: item.isOffline,
                maxGrade: item.maxGrade,
              });
            }
            if (!students[item.studentId]) {
              students[item.studentId] = { name: item.student, activities: {} };
            }
            students[item.studentId].activities[item.activityId] =
              item.gradedByInstructor ? item.score : "-";
          }
        }
      );

      // Step 1: Sort activities based on predefined order
      const activityOrder = [
        t("excel.Assignment"),
        t("excel.Quiz"),
        t("excel.Forum"),
        t("excel.Attendance"),
      ];
      activities.sort(
        (a, b) => activityOrder.indexOf(a.type) - activityOrder.indexOf(b.type)
      );

      // Step 2: Create the worksheet data
      const worksheetData: any[][] = [];
      const header = [
        t("excel.StudentName"),
        t("excel.StudentID"),
        ...activities.map(
          (activity) =>
            `${activity.name} (${activity.type}, ${activity.isOffline}, ${activity.id ?? t("excel.undefined")
            }, ${t("excel.MaximumGrade")}: ${activity.maxGrade})`
        ),
      ];
      worksheetData.push(header);

      Object.keys(students).forEach((studentId) => {
        const student = students[studentId];
        const row = [
          student.name,
          studentId,
          ...activities.map(
            (activity) =>
              student.activities[activity.id ?? t("excel.attendance")] ?? ""
          ),
        ];
        worksheetData.push(row);
      });

      // Step 3: Generate the worksheet and workbook
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, t("excel.Grades"));

      // Step 4: Trigger the download
      XLSX.writeFile(workbook, `${t("excel.GradesTemplate")}.xlsx`);
    } catch (error) {
      toast.error(
        "Failed to export the excel file. Please re-download a template and try again."
      );
    }
  };

  function calculateTotalGrade(
    studentId: string,
    GradebookData: { students: any[]; MainActivity: any[] },
    criteria: string[]
  ): number {
    // Implement your logic for calculating the total grade based on criteria
    return 0; // Placeholder value
  }

  const handleDownloadTemplate = () => {
    ExportGrades(gradeData);
  };

  return (
    <FlexAppContainer column={true} gap="1.25rem">
      {Noinfo ? (
        dashboardGrades ? (
          <DashboardContentsHolder
            language={language}
            style={{ paddingBottom: "2rem" }}
            bgColor={theme === "light" ? "#fff" : "#384450;"}
            boxShadow={
              theme === "light"
                ? "0px 4px 14px 0px rgba(192, 220, 214, 0.32)"
                : ""
            }
          >
            <EmptyGradebook />
          </DashboardContentsHolder>
        ) : (
          <EmptyGradebook />
        )
      ) : (
        <>
          {isLoading ? (
            dashboardGrades ? (
              <DashboardContentsHolder
                style={{ paddingBottom: "2rem" }}
                bgColor={theme === "light" ? "#fff" : "#384450;"}
                boxShadow={
                  theme === "light"
                    ? "0px 4px 14px 0px rgba(192, 220, 214, 0.32)"
                    : ""
                }
              >
                <LoadingGradebook />
              </DashboardContentsHolder>
            ) : (
              <LoadingGradebook />
            )
          ) : (
            <MyContextProvider>
              {/*alert setting popup */}
              {alertSettingsPopUp && (
                <PopUp
                  content={
                    <AlertSettingsPopUp
                      onClose={() => setAlertSettingsPopUp(false)}
                    />
                  }
                  showline={false}
                  show={alertSettingsPopUp}
                  setShow={setAlertSettingsPopUp}
                  title={t("gradebook.Alert Settings")}
                  showButtonCancel={false}
                  // marginTop="1rem"
                  // paddingTop="1.25rem"
                  showButton={false}
                />
              )}

              {!dashboardGrades && (
                <Typography
                  text={t("gradebook.Grades")}
                  fontSize="1.75rem"
                  lineHeight="normal"
                />
              )}

              <GradeBookWrapper
                bgcolor={ClassGradientBox}
                boxshadow={JustLightBoxShadow}
              >
                {isAdmin === false && !dashboardGrades && (
                  <>
                    {role === "teacher" && (
                      <AlertPublishSearchRow>
                        <AlertPublishRow>
                          {/* alert setting popup show*/}
                          <AlertWithLogo
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleAlertSettings(
                                setAlertSettingsPopUp,
                                alertSettingsPopUp
                              )
                            }
                          >
                            <SvgAlert />
                            <Typography
                              text={t("gradebook.Alert Settings")}
                              fontSize="0.75rem"
                            />
                          </AlertWithLogo>

                          <PublishWithInput>
                            <Typography
                              text={t("gradebook.Publish Grade")}
                              fontSize="0.75rem"
                            />

                            <SelectDropDown
                              width="7rem"
                              options={translatedEnrollmentOptions}
                              value={numberModel}
                              height="1.875rem"
                              onChange={(newValue: string) =>
                                handleSelectChangeEnrollment(
                                  newValue,
                                  setNumberModel
                                )
                              }
                            />
                          </PublishWithInput>
                          {/* <SchemaLink onClick={() => navigate(`/courseview/Gradebook/GradeSchema/${id.classId}`)}>
                          <LightTypography text={t("gradebook.Manage Grade Schema")} fontSize="0.75rem" />
                        </SchemaLink> */}
                        </AlertPublishRow>
                        <InputWrapper>
                          <ReusableInput
                            left="1.69rem"
                            holderWidth="90%"
                            placeHolder={t("dashboard.Search")}
                            padingLeft="3.31rem"
                            inputBorderRadius="6.25rem"
                            inputWidth="23.625rem"
                            inputHeight="3.125rem"
                            display="block"
                            value={searchInput}
                            onInputChange={(value: string) => {
                              setSearchInput(value); // Update search input state
                              handleSearch(
                                value,
                                setFilteredStudents,
                                allData.students
                              );
                            }}
                          />
                        </InputWrapper>
                      </AlertPublishSearchRow>
                    )}
                  </>
                )}
                {isAdmin === false && !dashboardGrades && (
                  <>
                    {" "}
                    {role === "teacher" && (
                      <FiltersActionsRow
                        style={{ flexWrap: "wrap", gap: "1.25rem" }}
                      >
                        <FilterBtnsDiv
                          style={{ flex: "1", justifyContent: "flex-start" }}
                        >
                          <ButtonFilledWrapper
                            onClick={() => handleFilterAllGrades()}
                          >
                            <LightTypography
                              text={t("gradebook.View All Grades")}
                              fontSize="1rem"
                            />
                          </ButtonFilledWrapper>
                          <ButtonFilledWrapper
                            onClick={() => handleFilterBelowAverage()}
                          >
                            <LightTypography
                              text={t("gradebook.View Grades Below Average")}
                              fontSize="1rem"
                            />
                          </ButtonFilledWrapper>
                          <ButtonFilledWrapper
                            onClick={() => handleFilterFailingGrades()}
                          >
                            <LightTypography
                              text={t("gradebook.View Failing Grades")}
                              fontSize="1rem"
                            />
                          </ButtonFilledWrapper>
                        </FilterBtnsDiv>
                        <div
                          style={{
                            display: "flex",
                            width: "fit-content",
                            justifyContent: "flex-end",
                            flex: "1",
                          }}
                        >
                          {role === "teacher" && (
                            <ActionsDiv
                              style={{
                                color: specialColor2,

                                minWidth: "45rem",
                              }}
                            >
                              <LogoAndAction onClick={handleClickActivities}>
                                <SvgGraduateHat />
                                <Typography
                                  text={t("gradebook.View Student Activities")}
                                  fontSize={
                                    language === "tr" ? "0.6rem" : "0.75rem"
                                  }
                                />
                              </LogoAndAction>
                              <LogoAndAction onClick={handleClick}>
                                <SvgManage />
                                <Typography
                                  text={t("gradebook.Manage Weight")}
                                  fontSize={
                                    language === "tr" ? "0.6rem" : "0.75rem"
                                  }
                                />
                              </LogoAndAction>
                              <LogoAndAction
                                onClick={() =>
                                  navigate(
                                    `/courseview/Gradebook/${id.classId}/RubricsList`
                                  )
                                }
                              >
                                <SvgRubric />
                                <Typography
                                  text={t("Rubric.View Rubric")}
                                  fontSize={
                                    language === "tr" ? "0.6rem" : "0.75rem"
                                  }
                                />
                              </LogoAndAction>
                              <LogoAndAction
                                onClick={() => handleClickUpload()}
                              >
                                <SvgUpload />
                                <Typography
                                  text={t("course view.Upload")}
                                  fontSize={
                                    language === "tr" ? "0.6rem" : "0.75rem"
                                  }
                                />
                              </LogoAndAction>

                              <ColumnDiv>
                                <LogoAndAction
                                  onClick={() => handleToggleDropdown()}
                                >
                                  <SvgDownload />
                                  <Typography
                                    text={t("gradebook.Download")}
                                    fontSize={
                                      language === "tr" ? "0.6rem" : "0.75rem"
                                    }
                                  />
                                  <SvgDropDownArrow
                                    style={{ transform: "rotate(180deg)" }}
                                  />
                                </LogoAndAction>
                                {isDropdownOpen && (
                                  <DropdownContainer bgcolor={bg_selectColor}>
                                    {/* Dropdown items */}
                                    <DropdownItem
                                      onClick={() =>
                                        handleDownloadGrades(
                                          allData,
                                          filters,
                                          t
                                        )
                                      }
                                    >
                                      <MediumTypography
                                        fontSize="0.7rem"
                                        text={t("gradebook.Download Table")}
                                      />
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => handleDownloadTemplate()}
                                    >
                                      <MediumTypography
                                        fontSize="0.7rem"
                                        text={t("gradebook.Download Template")}
                                      />
                                    </DropdownItem>
                                    {/* Add more dropdown items as needed */}
                                  </DropdownContainer>
                                )}
                              </ColumnDiv>

                              {/* <LogoAndAction>
          <input type="file" onChange={handleFileChange} style={{ display: 'none' }} />
          <SvgUpload onClick={() => (document.querySelector('input[type="file"]') as HTMLInputElement)?.click()} />
          <Typography text="Import Grades" fontSize="0.75rem" />
        </LogoAndAction> */}
                            </ActionsDiv>
                          )}
                        </div>
                      </FiltersActionsRow>
                    )}
                  </>
                )}
                {isAdmin === false && cardRole ? (
                  <>
                    {cardRole === "teacher" && (
                      <SimpleTable
                        cardId={cardId}
                        setNoinfo={setNoinfo}
                        Data={allData}
                        GradeType={numberModel}
                        onViewAllGrades={handleFilterAllGrades}
                        onViewBelowAverageGrades={handleFilterBelowAverage}
                        onViewFailingGrades={handleFilterFailingGrades}
                        filteredStudents={filteredStudents.students}
                        setSelectedStudentId={setSelectedSTudentId}
                        handleAttendancePopup={() => setAttendancePopUp(true)}
                      />
                    )}
                    {cardRole === "student" && (
                      <NewStudentView
                        allGradeData={gradeData}
                        allStudentData={allData}
                        cardId={cardId}
                        GradeType={numberModel}
                        handleAttendancePopup={() => setAttendancePopUp(true)}
                        setSelectedStudentId={setSelectedSTudentId}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {(role === "teacher" ||
                      (isAdmin === true && adminRole === "teacher")) && (
                        <SimpleTable
                          cardId={cardId}
                          setNoinfo={setNoinfo}
                          Data={allData}
                          GradeType={numberModel}
                          onViewAllGrades={handleFilterAllGrades}
                          onViewBelowAverageGrades={handleFilterBelowAverage}
                          onViewFailingGrades={handleFilterFailingGrades}
                          filteredStudents={filteredStudents.students}
                          setSelectedStudentId={setSelectedSTudentId}
                          handleAttendancePopup={() => setAttendancePopUp(true)}
                        />
                      )}
                    {(role === "student" ||
                      (isAdmin === true && adminRole === "student")) && (
                        <NewStudentView
                          allGradeData={gradeData}
                          allStudentData={allData}
                          cardId={cardId}
                          GradeType={numberModel}
                          handleAttendancePopup={() => setAttendancePopUp(true)}
                          setSelectedStudentId={setSelectedSTudentId}
                        />
                      )}
                  </>
                )}

                {isInputFocused && (
                  <ButtonsRow>
                    <ButtonAdd disabled={!isInputFocused}>
                      <Typography
                        text={t("forms.Save")}
                        color="#fff"
                        fontSize="0.875rem"
                        fontWeight="400"
                        lineHeight="normal"
                      />
                    </ButtonAdd>
                    <ButtonCancel>
                      <Typography
                        text={t("forms.Cancel")}
                        color="#D85D66"
                        fontSize="0.875rem"
                        fontWeight="400"
                        lineHeight="normal"
                      />
                    </ButtonCancel>
                  </ButtonsRow>
                )}
              </GradeBookWrapper>
            </MyContextProvider>
          )}
          {AttendancePopUp && (
            <PopUp
              content={
                <AttendancePopupNew
                  selectedStudentId={selectedStudentId}
                  onClose={() => setAttendancePopUp(false)}
                  setAttendanceLoading={SetAttendanceLoading}
                />
              }
              FixedHeaderContent={
                <AttendancePopupHeader
                  selectedStudentId={selectedStudentId}
                  onClose={() => setAttendancePopUp(false)}
                  setAttendanceLoading={SetAttendanceLoading}
                />
              }
              showline={false}
              show={AttendancePopUp}
              setShow={setAttendancePopUp}
              title={t("gradebook.View Attendances sheet")}
              icon={<SvgEye />}
              showButtonCancel={false}
              // marginTop="1rem"

              showButton={false}
              paddingLeft="0.2rem"
              paddingRight="0.2rem"
              ContentWidth="47%"
              ContentMinWidth="30rem"
            />
          )}

          {UploadPopupShow && (
            <PopUp
              show={UploadPopupShow}
              icon={<SvgUpload />}
              content={
                <UploadPopup
                  SetDropZoneFile={SetDropZoneFile}
                  DropZoneFile={DropZoneFile}
                />
              }
              showline={false}
              setShow={SetUploadPopupShow}
              title={t("gradebook.Upload Gradebook")}
              handleSubmit={() => handleDoneClick()}
              ContentWidth="53%"
              ContentMinWidth="20rem"
              paddingLeft="1.25rem"
              loading={uploadingLoading}
              paddingRight="1.25rem"
            />
          )}
        </>
      )}
    </FlexAppContainer>
  );
};

export default GradebookTableV2;
