import React from "react";
import {
  ProfileRowContainer,
  PRMiniRow,
  PRSVGAction,
} from "./ProfileRow.style";
import { ArrowStyle, MaskGroup, ProfileHolder } from "../../PageHeader.style";
import { Typography } from "../../../../elements/fonts/Fonts";
import { SvgClose, SvgPolygonDown, SvgScale } from "../../../../elements/Icons";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { IconsColorSelector } from "../../../../recoil/ThemeSelectors";
interface ProfileRowInterface {
  setShowMessagePOPUP: React.Dispatch<React.SetStateAction<boolean>>;
  ShowFriendsList: boolean;
  setShowFriendsList: React.Dispatch<React.SetStateAction<boolean>>;
}
const ProfileRow = ({
  setShowMessagePOPUP,
  ShowFriendsList,
  setShowFriendsList,
}: ProfileRowInterface) => {
  const handleCloseMessagePOPUP = () => {
    setShowMessagePOPUP(false);
  };

  const handleToggleFriendList = () => {
    setShowFriendsList(!ShowFriendsList);
  };
  const { backgroundImageColor } = useRecoilValue(IconsColorSelector);
  return (
    <ProfileRowContainer>
      <PRMiniRow>
        <ProfileHolder bgcolor={backgroundImageColor}>
          <MaskGroup
            alt="Profile Picture"
            // src={user?.picture || "/assets/images/profileImg.jpg"}
            src={"/assets/images/profileImg.jpg"}
          />
        </ProfileHolder>
        <Typography text="Chats" fontSize="1.125rem" />
      </PRMiniRow>
      <PRMiniRow>
        {/* <ArrowHolder> */}
        <PRSVGAction onClick={handleToggleFriendList}>
          <ArrowStyle
            style={{
              transform: ShowFriendsList ? "rotate(0deg)" : "rotate(180deg)",
              transition: "transform 0.3s ease-in-out",
            }}
          >
            <SvgPolygonDown />
          </ArrowStyle>
          {/* </ArrowHolder> */}
        </PRSVGAction>

        <PRSVGAction onClick={handleCloseMessagePOPUP}>
          <Link to="/messages">
            <SvgScale width={"0.7rem"} />
          </Link>
        </PRSVGAction>

        <PRSVGAction onClick={handleCloseMessagePOPUP}>
          <SvgClose width={"0.7rem"} />
        </PRSVGAction>
      </PRMiniRow>
    </ProfileRowContainer>
  );
};

export default ProfileRow;
