import React from "react";
import { PieDot, PieGrid, Pieflex, PieflexParent } from "./PieText.style";
import { MediumTypography, Typography } from "../../../elements/fonts/Fonts";
import { FlexAppContainer } from "../../../App.style";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";

interface PieTextInterface {
  LoopName: any[];
  title?: string;
  ShowMiniInfo?: boolean;
  SimpleChoiceTextEquation?: boolean;
  MinWidth?: string;
  MultipleTotalSum?: number;
  description?: string;
}

const PieText = ({
  LoopName,
  title,
  MinWidth,
  MultipleTotalSum = 0,
  SimpleChoiceTextEquation = true,
  ShowMiniInfo = true,
  description,
}: PieTextInterface) => {
  const totalSum = LoopName.reduce((acc, val) => acc + val.val, 0);
  const { SecondaryTextColor, bghelp, theme } = useRecoilValue(ColorSelector);
  return (
    <PieGrid style={{ gap: "1.25rem" }}>
      <Typography text={title || ""} fontSize="1rem" />
      <FlexAppContainer>
        <Typography
          text={description || ""}
          fontSize="0.75rem"
          color={SecondaryTextColor}
        />
      </FlexAppContainer>
      {ShowMiniInfo ? (
        <PieGrid>
          {LoopName &&
            LoopName.map((val, index) => (
              <Pieflex key={index}>
                <PieDot bgColor={val.bgColor} />
                <Typography fontSize="0.75rem" text={val.text} />
              </Pieflex>
            ))}
        </PieGrid>
      ) : (
        <PieGrid style={{ gap: "0.80rem" }}>
          {LoopName &&
            LoopName.map((val, index) =>
              val.val > 0 || val.attendance > 0 ? (
                <PieflexParent key={index}>
                  <Pieflex
                    color={SecondaryTextColor}
                    MinWidth={MinWidth || "6.5rem"}
                  >
                    <PieDot bgColor={val.bgColor} />
                    <Typography fontSize="0.75rem" text={val.text} />
                  </Pieflex>
                  {SimpleChoiceTextEquation ? (
                    <MediumTypography
                      text={`${
                        ((val.val / totalSum) * 100).toFixed(0) ||
                        ((val.ChosenAnswer / MultipleTotalSum) * 100).toFixed(
                          0
                        ) ||
                        ((val.attendance / totalSum) * 100).toFixed(0)
                      }%`}
                      fontSize="0.75rem"
                      color="#5DD3B3"
                    />
                  ) : (
                    <MediumTypography
                      text={`${
                        ((val.val / MultipleTotalSum) * 100).toFixed(0) ||
                        ((val.ChosenAnswer / MultipleTotalSum) * 100).toFixed(
                          0
                        ) ||
                        ((val.attendance / MultipleTotalSum) * 100).toFixed(0)
                      }%`}
                      fontSize="0.75rem"
                      color="#5DD3B3"
                    />
                  )}
                </PieflexParent>
              ) : null
            )}
        </PieGrid>
      )}
    </PieGrid>
  );
};

export default PieText;
