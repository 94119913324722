import React, { useEffect, useState } from "react";
import { FlexAppContainer } from "../../App.style";
import SelectDropDown from "../../elements/selectDropDown/SelectDropDown";
import axios from "axios";
import Select from "react-select";
import { colorStyles } from "./colorStyle";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { t } from "i18next";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  DropDownsCont,
  DropDownsContent,
  EmptyView,
  NameEmailColumn,
  ParticipantCardHolder,
  ProfileImageHolder,
  ProfileImg,
  ProfileNameEmailRow,
} from "./AddAuditor.style";
import { S3Get } from "../../services/S3Get";
import { useParams } from "react-router-dom";
import { FlexBetween } from "../../globalStyles";
import { SvgClose } from "../../elements/Icons";
import { useParentClass } from "../../utils/getParentClass";
import SelectMultiple from "../../elements/SelectMultiple/SelectMultiple";

interface AddAuditorsInterface {
  setAuditorsData: React.Dispatch<React.SetStateAction<any>>;
}

const AddAuditors = ({ setAuditorsData }: AddAuditorsInterface) => {
  const [users, setUsers] = useState<any[]>([]);
  const tenant = localStorage.getItem("tenant");
  const [auditors, setAuditors] = useState<any[]>([]);
  const [selectedSourceId, setSelectedSourceId] = useState<any[]>([]);
  const [childSourceId, setChildSourceId] = useState<string>("");
  const image = require("../../assets/userProfile.png");
  const params = useParams();
  const classId = params.classId;
  const [auditorProfile, setAuditorProfile] = useState<{
    [key: string]: string;
  }>({});
  const { SecondaryTextColor, inputBorderColor, theme, boxShadowBanner } =
    useRecoilValue(ColorSelector);
  const { isParent, children } = useParentClass(classId);

  const auditorsOptions = users.map((user) => ({
    label: `${user.SourceId} | ${user.fName} ${user.lName} | ${user.email}`,
    value: user.SourceId,
    name: `${user.SourceId} | ${user.fName} ${user.lName} | ${user.email}`,
    id: user.SourceId,
  }));

  const getUserProfile = async (src: any, sourceId: string) => {
    const profile = await S3Get(src);
    setAuditorProfile &&
      setAuditorProfile((prevAuditorProfile) => ({
        ...prevAuditorProfile,
        [sourceId]: profile,
      }));
  };

  const generateDataToPost = () => {
    return {
      data: {
        tenantName: tenant,
        classSourceId: classId,
        childSourceId: isParent ? childSourceId : "",
        users: selectedSourceId,
      },
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let allUsers: any[] = [];
        let currentPage = 1;
        const limit = 3000;

        while (true) {
          const response = await axios.get(
            `https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/classAuditor/users/${tenant}/${currentPage}/${limit}`
          );
          allUsers = allUsers.concat(response.data.data.data);
          setUsers(allUsers);
          if (currentPage >= response.data.data.totalPages) {
            break;
          }
          currentPage++;
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleSelectAuditors = async (id: any) => {
    const selectedOption = auditorsOptions.find(
      (option) => option.value === id
    );
    if (selectedOption) {
      const isAlreadySelected = auditors.some((item) => item.value === id);
  
      if (isAlreadySelected) {
        setAuditors(auditors.filter((item) => item.value !== id));
      } else {
        setAuditors([...auditors, selectedOption]);
      }
  
      let updatedSelectedSourceId = [...selectedSourceId];
  
      if (isAlreadySelected) {
        updatedSelectedSourceId = updatedSelectedSourceId.filter(
          (sourceId) => sourceId !== id
        );
      } else {
        updatedSelectedSourceId.push(id);
      }
  
      setSelectedSourceId(updatedSelectedSourceId);
  
      const foundedUser = users.find(
        (user) => user.SourceId === id
      );
      if (foundedUser && foundedUser.profilePicture) {
        await getUserProfile(foundedUser.profilePicture, foundedUser.SourceId);
      }
    }
  };

  const handleRemoveAuditor = (sourceId: any) => {
    setAuditors(auditors.filter((auditor) => auditor.value !== sourceId));
    setSelectedSourceId(selectedSourceId.filter((id) => id !== sourceId));
  };

  useEffect(() => {
    const dataToPost = generateDataToPost();
    // console.log("data to post", dataToPost);
    setAuditorsData(dataToPost);
  }, [auditors, selectedSourceId, classId, childSourceId, tenant, isParent, setAuditorsData]);

  function transformData(data: any) {
    return data.map((item: any) => ({
      name: item.className,
      value: item.sourceId,
    }));
  }

  const allClasses = transformData(children);

  const handleSelectChild = (option: any) => {
    setChildSourceId(option);
  };

  return (
    <FlexAppContainer width="33.75rem" column height="13.5rem" gap="1rem">
      <DropDownsCont>
        {isParent ? (
          <DropDownsContent>
            <SelectDropDown
              options={allClasses}
              placeholder={t("participants.Select Class")}
              value={childSourceId}
              onChange={(option) => {
                handleSelectChild(option);
              }}
              width="100%"
              fontSize="0.75rem"
            />
          </DropDownsContent>
        ) : (
          ""
        )}
        <DropDownsContent>
          <SelectMultiple
            placeholder={t("participants.Select Users")}
            options={auditorsOptions}
            onChange={handleSelectAuditors}
            value={auditors}
          />
        </DropDownsContent>
      </DropDownsCont>
      {selectedSourceId.length > 0 ? (
        <FlexAppContainer width="100%" column gap="0.62rem">
          {users
            .filter((user) => selectedSourceId.includes(user.SourceId))
            .map((auditor) => (
              <ParticipantCardHolder
                boxShadow={boxShadowBanner}
                bgColor={"rgba(255, 255, 255, 0.03)"}
                style={{ width: "100%" }}
              >
                <FlexBetween>
                  <ProfileNameEmailRow>
                    <ProfileImageHolder>
                      <ProfileImg
                        theme={theme}
                        src={auditorProfile[auditor.SourceId] || image}
                      ></ProfileImg>
                    </ProfileImageHolder>
                    <NameEmailColumn>
                      <Typography
                        text={`${auditor.fName} ${auditor.lName}`}
                        fontSize="1rem"
                        fontWeight="400"
                        lineHeight="normal"
                      />
                      {auditor.SourceId ? (
                        <LightTypography
                          text={`ID: ${auditor.SourceId || ""}`}
                          fontSize="0.75rem"
                          color="#AAB1B9"
                        />
                      ) : (
                        ""
                      )}
                      <MediumTypography
                        text={auditor.email}
                        fontSize="0.75rem"
                        fontWeight="400"
                        lineHeight="normal"
                        color={SecondaryTextColor}
                      />
                    </NameEmailColumn>
                  </ProfileNameEmailRow>
                  <SvgClose
                    color="#5DD3B3"
                    onClick={() => handleRemoveAuditor(auditor.SourceId)}
                    style={{ cursor: "pointer" }}
                  />
                </FlexBetween>
              </ParticipantCardHolder>
            ))}
        </FlexAppContainer>
      ) : (
        <EmptyView>
          <TypographyRegularDescription text={t("participants.Select from drop down to Invite Auditor")}/>
        </EmptyView>
      )}
    </FlexAppContainer>
  );
};

export default AddAuditors;
