import React, { FC, useEffect, useState } from "react";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit1.svg";
import { ReactComponent as ViewIcon } from "../../../assets/icons/Eye1.svg";
import { ReactComponent as AddIcon } from "../../../assets/icons/Add (2).svg";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/Refresh.svg";
import {
  Typography,
  MediumTypography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import { QuestionCRUDTextEditorProps } from "./QuestionCRUDTextEditorInterface";
import {
  QuellStyle,
  StyledQuillEditor,
  QuestionIconsContainer,
  Icons,
  QuestionHeaderContainer,
  PointsInput,
  FeedBackContainer,
  Points,
  Tooltip,
  IconWrapper,
  CheckBox,
  CheckboxHolder,
} from "./QuestionCRUDTextEditor.style";

import { ErrorDiv } from "../../../elements/ErrorDiv/Errordiv.style";
import "react-quill/dist/quill.snow.css";
import TinyEditor from "../../../elements/tinyEditor/TinyEditor";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { SvgEdit } from "../../../elements/Icons";
const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "clean"],
    [{ header: [] }],
    [{ list: "ordered" }, { list: "bullet" }],

    [{ align: [] }],
    ["link", "code", "image"],
  ],
};

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "clean",
  "header",
  "header",
  "list",

  "align",
  "link",
  "code",
  "image",
];
const QuestionCRUDTextEditor: FC<QuestionCRUDTextEditorProps> = ({
  questionError,
  typeError,
  // handleQuestion,
  handleQuillChange,
  addChoice,
  quest,
  showQuest,
  handleShowEditQuest,
  setShowPreviewPopup,
  handleChoiceChecked,
  // handleShuffle,
  // isShuffled
}) => {
  const quillValue = `<p>${quest?.question}</p>`;
  // const [isShuffled, setIsShuffled] = useState<any>(false);
  const [isChecked, setIsChecked] = useState<any>(false);
  // Ensure that isShuffled state is properly initialized and updated
  // useEffect(() => {
  //   setIsShuffled(quest?.shuffle);
  // }, [quest?.shuffle]);
  const { t } = useTranslation();

  useEffect(() => {
    setIsChecked(quest?.branching);
  }, [quest?.branching]);

  // useEffect(() => {
  //   setIsChecked(isChecked);
  // }, []);

  const handleCheckboxChange = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    handleChoiceChecked && handleChoiceChecked(newValue);
  };

  const { themeTextColor, bg_selectColor } = useRecoilValue(ColorSelector);

  return (
    <>
      <QuestionHeaderContainer>
        <QuestionIconsContainer>
          {quest?.type === "RATE" ? (
            <MediumTypography text={t("survey.Give us your rating")} />
          ) : quest?.type === "SCALE" ? (
            <MediumTypography text={t("survey.Give us your scale")} />
          ) : (
            <MediumTypography text={t("quiz.Question")} />
          )}
          {showQuest ? (
            <Icons>
              <IconWrapper>
                <SvgEdit onClick={handleShowEditQuest} color={themeTextColor} />
                <Tooltip bgcolor={bg_selectColor}>
                  {t("dashboard.Edit")}
                </Tooltip>
              </IconWrapper>
              {/* <IconWrapper>
              <ViewIcon onClick={() => setShowPreviewPopup && setShowPreviewPopup(true)} />
              <Tooltip>Preview</Tooltip>
              </IconWrapper> */}
            </Icons>
          ) : (
            ""
          )}
        </QuestionIconsContainer>
        {showQuest ? (
          // <Points />
          ""
        ) : quest?.type === "ESSAY" ? null : (
          <CheckboxHolder>
            {/* <CheckBox
        type='checkbox'
        checked={isChecked}
        name='isChecked'
        id="isChecked"
        onChange={handleCheckboxChange}
      />
      <Typography
        text='Branching question'
        fontSize='0.875rem'
        
      /> */}
          </CheckboxHolder>
        )}
      </QuestionHeaderContainer>

      <QuellStyle>
        {showQuest ? (
          <TinyEditor
            hasError={!!questionError && !quest?.name}
            initialValue={quest?.question}
            onChange={handleQuillChange}
            placeholder={t("quiz.type your question")}
            readonly
            height="13.25rem"
          />
        ) : (
          <TinyEditor
            hasError={!!questionError && !quest?.name}
            initialValue={quest?.question}
            onChange={handleQuillChange}
            placeholder={t("quiz.type your question")}
            height="13.25rem"
          />
        )}
      </QuellStyle>

      <FeedBackContainer>
        {quest?.type === "Short answer" ? (
          <MediumTypography
            text={t("survey.Key Answers")}
            fontSize="0.875rem"
            lineHeight="1.375rem"
          />
        ) : quest?.type === "SCALE" ||
          quest?.type === "ESSAY" ||
          quest?.type === "RATE" ||
          quest?.type === "BINARY" ? (
          ""
        ) : (
          <MediumTypography
            text={`${t("quiz.Choices")}*`}
            fontSize="0.875rem"
            lineHeight="1.375rem"
          />
        )}
        {quest?.type === "SCALE" ||
        quest?.type === "ESSAY" ||
        quest?.type === "RATE" ||
        quest?.type === "BINARY" ? (
          ""
        ) : (
          <Icons>
            {quest?.type === "Short answer" ? (
              <Icons
                onClick={() => {
                  if (!showQuest) {
                    addChoice && addChoice();
                  }
                }}
              >
                <AddIcon />
                <Typography
                  text={t("survey.Add Key Answers")}
                  fontSize="0.875rem"
                  color={theme.dark.main_color}
                  lineHeight="1.375rem"
                />
              </Icons>
            ) : (
              <>
                {/* <IconWrapper>
              <RefreshIcon color={isShuffled ? "#5DD3B3" : ""} onClick={() => {
                const updatedShuffle = !isShuffled; 
                setIsShuffled(updatedShuffle); 
                handleShuffle && handleShuffle(updatedShuffle); 
              }}/>
              <Tooltip>Shuffle</Tooltip>
              </IconWrapper> */}
                <Icons
                  onClick={() => {
                    if (!showQuest) {
                      addChoice && addChoice();
                    }
                  }}
                >
                  <AddIcon />
                  <Typography
                    text={t("quiz.Add Choices")}
                    fontSize="0.875rem"
                    color={theme.dark.main_color}
                    lineHeight="1.375rem"
                  />
                </Icons>
              </>
            )}
          </Icons>
        )}
      </FeedBackContainer>
    </>
  );
};

export default QuestionCRUDTextEditor;
