import styled from "styled-components";

export const CompetencyPopUpContainer =styled.div`
display: flex;
flex-direction: column;
/* width:100%; */
width:56rem;
/* margin:1.25rem 0; */
gap:1.25rem;
height: 50vh;
/* padding-right:1rem; */
/* margin:1.25rem 0; */
`;

export const CompetencyPopUpRow =styled.div`
display: flex;
gap:1.25rem;
justify-content: space-between;
flex-wrap: wrap;

`;
export const CompetencyPopUpColumnSelect =styled.div`
display: flex;
flex-direction: column;
flex:1;
gap:1.25rem;
justify-content: space-between;
position: relative;
min-width:21rem;
`;