import React, { useEffect, useState } from "react";
import {
  SidebarMobileStyle,
  SideMenuItem,
  SideMenu,
  ClosePosition,
} from "./SidebarMobile.style";
import { TypographyRegularDescription } from "../../../elements/fonts/Fonts";
import {
  SvgAnnouncement,
  SvgArrowDownIcon,
  SvgCalendarIcon,
  SvgCloseIcon,
  SvgDashboardIcon,
  SvgHelpIcon,
  SvgLxpIcon,
  SvgNotesIcon,
} from "../../../elements/Icons";
import { Link } from "react-router-dom";
import { getLogo } from "../../../services/logo";
import { Logo } from "../../../elements/logo/Logo";
import { t } from "i18next";
import { MenuItem } from "../SidebarMenu/sidebarInterface";

interface SidebarMobileProps {
  closeSidebar?: () => void;
  closeB?: boolean;
  bgColor: string;
}
export default function SidebarMobile({
  closeSidebar,
  closeB,
  bgColor,
}: SidebarMobileProps) {
  const [backgroundUrl, setBackgroundUrl] = useState();
  const [backgroundName, setBackgroundName] = useState("");
  const [bigLogoUtl, setBigLogoUtl] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const [bigLogoName, setBigLogoName] = useState("");
  const [logoName, setLogoName] = useState("");
  const [title, setTitle] = useState("");
  const [abv, setAbv] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLogo();
        if (response) {
          // const pathSegmentsBN = response.backgroundName.toString().split("/");
          // const fileNameBN = pathSegmentsBN[pathSegmentsBN.length - 1];
          // const titleNameBN = uuidSplitter(fileNameBN);
          // const pathSegmentsBL = response.bigLogoName.toString().split("/");
          // const fileNameBL = pathSegmentsBL[pathSegmentsBL.length - 1];
          // const titleNameBL = uuidSplitter(fileNameBL);
          // const pathSegmentsL = response.logoName.toString().split("/");
          // const fileNameL = pathSegmentsL[pathSegmentsL.length - 1];
          // const titleNameL = uuidSplitter(fileNameL);
          // setUnBackImg(response.backgroundName);
          // setUnBigLogo(response.bigLogoName);
          // setUnLogo(response.logoName);

          setBackgroundUrl(response.backgroundUrl);
          // setBackgroundName(titleNameBN);
          setBigLogoUtl(response.bigLogoUtl);
          setLogoUrl(response.logoUrl);
          // setBigLogoName(titleNameBL);
          // setLogoName(titleNameL);
          setTitle(response.title);
          setAbv(response.avg);
          // console.log("response.backgroundName", response.backgroundName);
          // console.log("response.bigLogoName", response.bigLogoName);
          // console.log("response.title", response.title);
          // console.log("response.av", response.abv);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  const menuData: MenuItem[] = [
    {
      icon: SvgDashboardIcon,
      title: t('sidebar.dashboard'),
      path: "/dashboard",
    },
    {
      icon: SvgAnnouncement,
      title: t('sidebar.announcement'),
      path: "/announcements",
    },
    {
      icon: SvgCalendarIcon,
      title: t('sidebar.calendar'),
      path: "/calendar",
    },
    // {
    //   icon: SvgTodoListIcon,
    //   title: "To do List",
    //   path: "/to-do",
    // },
    {
      icon: SvgNotesIcon,
      title: t('sidebar.notes'),
      path: "/Notes",
    },
    // {
    //   icon: SvgAnalysisIcon,
    //   title: "Analytics",
    //   path: "/Analytics",
    // },
    // {
    //   icon: SvgProgressIcon,
    //   title: "Progress",
    //   path: "/#",
    // },
    {
      icon: SvgHelpIcon,
      title: t('sidebar.help'),
      path: "/help",
    },
  ];
  return (
    <SidebarMobileStyle style={{ backgroundColor: bgColor }}>
      {closeB && (
        <ClosePosition onClick={closeSidebar}>
          <SvgCloseIcon />
        </ClosePosition>
      )}
      {/* {!loading && (
        <img
          src={
            logoUrl !== "" && logoUrl !== undefined && logoUrl !== null
              ? logoUrl
              : "/assets/images/images.png"
          }
          alt=""
          style={{ maxHeight: "7rem", width: "100%", objectFit: "cover" }}
        />
      )} */}
      <Logo />
      <SideMenu>
        {/* <SideMenuItem>
          <SvgLxpIcon />
          <TypographyRegularDescription text="Our Application" />
          <SvgArrowDownIcon />
        </SideMenuItem> */}
        {menuData.map((item, index) => (
          <Link to={item.path} key={index}>
            <SideMenuItem>
              <item.icon />

              <TypographyRegularDescription text={item.title} />
            </SideMenuItem>
          </Link>
        ))}
      </SideMenu>
    </SidebarMobileStyle>
  );
}
