import styled from "styled-components";
import { DatePickerProps } from "../../elements/TwoDatePicker/TwoDatePickerInterface";
import { SvgPolygonDown } from "../../elements/Icons";
import { breakpoints } from "../../theme";

export const DatePickerWapper = styled.div<DatePickerProps>`
  margin-top: -0.3rem;
  /* margin-left: -1.3rem; */
  width: 100% !important;
  .react-datepicker {
    font-family: "Primary Font-Light";
    font-size: 0.75rem;
    width: 100% !important;
    background-color: transparent;
    /* background: pink; */
    color: ${(props) => props.themetextcolor};
    border: none;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    /* height: 15.25rem; */
    margin: 0;
    padding: 0;
  }
  .react-datepicker__month-container {
    width: 100% !important;
  }
  .react-datepicker__header {
    width: 100% !important;
  }
  .custom-header {
    width: 100% !important;
    display: flex;

    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.6rem;
    /* background: cyan; */

    /* margin-bottom: -0.001rem;
    margin-top: 0.45rem; */

    /* @media (max-width: ${breakpoints.mobile}) {
      width: 5rem !important;
    } */
  }
  .custom-header-arrow {
    &:dir(ltr) {
      transform: rotate(0deg);
    }
    &:dir(rtl) {
      transform: rotate(180deg) !important;
    }
  }
  .has-tasks {
    border-radius: 50% !important;
    background-color: ${(props) =>
      props.theme === "dark" ? "transparent" : "#576474"} !important;
    color: #fff !important;
    border: 1px solid #5dd3b3 !important;
  }
  .is-today {
    border-radius: 50% !important;
    background-color: #5dd3b3 !important;
    color: #fff !important;
    border: 1px solid #5dd3b3 !important;
    filter: drop-shadow(0px 0px 10px rgba(93, 211, 179, 0.5)) !important;
  }
  .react-datepicker__month {
    margin: 0;
  }
  .react-datepicker__day-names {
    margin-bottom: -0.2rem;
    width: 100% !important;
    @media (max-width: ${breakpoints.tablets}) {
      display: flex;
      column-gap: var(--column-gap);

      justify-content: center !important;
    }
    /* margin-top: -0.5rem; */
  }
  .react-datepicker__week {
    @media (max-width: ${breakpoints.tablets}) {
      display: flex;
      column-gap: var(--column-gap);
      justify-content: center !important;
    }
  }
  .react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
    /* @media (max-width: ${breakpoints.tablets}) {
width:1rem !important;
  } */
  }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: ${(props) => props.themetextcolor};
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    /* top: 6px; */
    width: 9px;
  }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    margin-left: -4px;
    position: absolute;
    width: 0;
  }
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
  }

  .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: ${(props) => props.bgdatepicker};
    border-bottom-right-radius: 0.3rem;
  }

  ::-webkit-scrollbar {
    width: 0.45rem;
  }

  ::-webkit-scrollbar-thumb {
    background: var(
      --Green-Gradient,
      linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)
    );
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.bgdatepicker};
    border-radius: 0.625rem;
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 0;
  }
  .react-datepicker-popper[data-placement^="top"] {
    padding-bottom: 0px;
  }

  .react-datepicker__header {
    text-align: center;
    background-color: ${(props) => props.bgdatepicker};
    border-bottom: 1px solid ${(props) => props.bgdatepicker};
    border-top-left-radius: 0.3rem;
    padding: 0.38rem 1.44rem 0rem 1.44rem;
    position: relative;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-top: 0;
    /* margin-bottom: 1.3rem; */
    color: ${(props) => props.themetextcolor};
    font-family: "Primary Font-Bold";
    font-size: 1rem;
  }

  .react-datepicker__navigation--previous {
    top: 0;
    left: 1.44rem;
  }
  .react-datepicker__navigation--next {
    top: 0;
    right: 1.44rem;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    border-radius: 50%;
    background-color: rgba(93, 211, 179, 0.5);
    color: ${(props) => props.themetextcolor};
    border: 1px solid #5dd3b3;
    filter: drop-shadow(0px 0px 10px rgba(93, 211, 179, 0.5));
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: rgba(93, 211, 179, 0.5);
    color: ${(props) => props.themetextcolor};
    font-weight: bold;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background-color: rgba(93, 211, 179, 0.5);
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled {
    border-radius: 50%;
    background-color: rgba(93, 211, 179, 0.5);
    color: ${(props) => props.themetextcolor};
    border: 1px solid #5dd3b3;
    filter: drop-shadow(0px 0px 10px rgba(93, 211, 179, 0.5));
  }

  .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    border-radius: 50%;
    background-color: rgba(93, 211, 179, 0.5);
    color: ${(props) => props.themetextcolor};
    border: 1px solid #5dd3b3;
    filter: drop-shadow(0px 0px 10px rgba(93, 211, 179, 0.5));
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: ${(props) => props.themetextcolor};
    display: inline-block;
    width: 1.75rem;
    height: 1.75rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.09rem 0.3rem;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 50%;
    background-color: rgba(93, 211, 179, 0.5);
    color: #fff;
    filter: drop-shadow(0px 0px 10px rgba(93, 211, 179, 0.5));
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 50%;
    background-color: transparent;
    color: #5dd3b3;
    border: none;
    filter: drop-shadow(0px 0px 10px rgba(93, 211, 179, 0.5));
  }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    color: #5dd3b3;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 50%;
    background-color: transparent;
    color: #5dd3b3;
    border: none;
    /* filter: drop-shadow(0px 0px 10px rgba(93, 211, 179, 0.50)); */
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    border-radius: 50%;
    background-color: rgba(93, 211, 179, 0.5);
    color: #fff;
    border: 1px solid #5dd3b3;
    filter: drop-shadow(0px 0px 10px rgba(93, 211, 179, 0.5));
  }
  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__month-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__quarter-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__year-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ) {
    color: #5dd3b3;
  }

  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    cursor: default;
    color: ${(props) => props.themetextcolor};
    opacity: 0.5;
  }
  .react-datepicker__day--disabled:hover,
  .react-datepicker__month-text--disabled:hover,
  .react-datepicker__quarter-text--disabled:hover,
  .react-datepicker__year-text--disabled:hover {
    background-color: transparent;
  }
  .react-datepicker__day--selected {
    /* background: cyan !important; */
    padding: 0 !important;
    /* display: flex; */
    /* justify-content: center;
    align-items: center; */
  }
`;
