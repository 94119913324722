import React, { useEffect, useState } from "react";
import {
  GAAActions,
  GAAContainer,
  GAAContent,
  GAAGaps,
  GAALayout,
  GAAProfile,
  GAAdesc,
  PostsContainer,
  ProfileContainer,
} from "./GraderAssignmentAnswer.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import {
  SvgDownload,
  SvgEye,
  SvgGroup3339,
  SvgLightedDownload,
  SvgLike,
} from "../../../elements/Icons";
import DOMPurify from "dompurify";
import { uuidSplitter } from "../../../utils/StringManipulation";
import {
  PTLActionsCell,
  PTLLineActions,
} from "../../PostsTimeLine/main/PostsTimeLine.style";
import ViewPost from "../../PostsTimeLine/ViewPost/ViewPost";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { DisplayContentDisplay } from "../../PostsTimeLine/main/Function";
import { toArabicDigits } from "../../../utils/formatDayArabic";
const profileImage = require("../../../assets/userProfile.png");
interface GraderAssignmentAnswerInterface {
  profilePic?: string;
  Name?: string;
  Subject?: string;
  EyeClick?: () => void;
  EyeClick2?: (file: any, fileName: any) => void;
  DownloadClick?: () => void;
  fileName?: string;
  filePath?: string;
  forum?: boolean;
  postsArray?: any[];
}

interface LikeState {
  [index: number]: {
    like: boolean;
    dislike: boolean;
    view?: boolean;
    reply?: boolean;
    showMore: boolean;
  };
}

const GraderAssignmentAnswer = ({
  profilePic,
  Name,
  Subject,
  EyeClick,
  DownloadClick,
  fileName,
  filePath,
  forum,
  EyeClick2,
  postsArray,
}: GraderAssignmentAnswerInterface) => {
  const [likeState, setLikeState] = useState<LikeState>({});
  const { t } = useTranslation();

  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };

  const handleLike = (index: number) => {};
  const handleView = (index: number) => {
    setLikeState((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        view: !prevState[index]?.view,
      },
    }));
  };
  const {
    GrayBackGroup1,
    Lighted_BoxShadowDisplays,
    theme,
    bg_graderForumPost,
    inputBorderColor,
  } = useRecoilValue(ColorSelector);

  useEffect(() => {}, [theme]);
  return (
    <GAALayout forum={forum} postsArray={postsArray}>
      <ProfileContainer forum={forum} postsArray={postsArray}>
        <GAAProfile
          // src={require("../../../assets/AliAhmad.png")}
          src={profilePic || profileImage}
          alt={Name || ""}
        />
        {forum && postsArray && postsArray?.length > 1 && (
          <Typography text={Name || ""} fontSize="1rem" />
        )}
      </ProfileContainer>
      <GAAContainer>
        {postsArray && postsArray?.length <= 1 && (
          <Typography text={Name || ""} fontSize="1rem" />
        )}
        {!forum ? (
          <>
            <GAAdesc>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(decodeHtml(Subject)),
                }}
                style={{ fontSize: "0.875rem", lineHeight: "1.375rem" }}
              />
            </GAAdesc>

            {/* her you must make the map render  */}
            {fileName?.trim() !== "" ? (
              <GAAContent
                bgColor={
                  theme === "dark" ? "rgba(255, 255, 255, 0.03)" : "#AAB1B915"
                }
                boxShadow={Lighted_BoxShadowDisplays}
              >
                <GAAGaps>
                  {theme === "light" ? (
                    <SvgLightedDownload width={"4rem"} />
                  ) : (
                    <SvgGroup3339 width={"4rem"} />
                  )}
                  <MediumTypography text={uuidSplitter(fileName || "")} />
                </GAAGaps>
                <GAAGaps>
                  {fileName?.endsWith(".pdf") ? (
                    <GAAActions onClick={EyeClick}>
                      <SvgEye />
                      <LightTypography
                        text={t("assignment.View")}
                        fontSize="0.875rem"
                        color="#5DD3B3"
                      />
                    </GAAActions>
                  ) : (
                    ""
                  )}

                  <a href={filePath} download={filePath}>
                    <GAAActions>
                      <SvgDownload fill="#5DD3B3" />
                      <LightTypography
                        text={t("online session.Download")}
                        fontSize="0.875rem"
                        color="#5DD3B3"
                      />
                    </GAAActions>
                  </a>
                </GAAGaps>
              </GAAContent>
            ) : (
              // <MediumTypography text={t("grader.No Files")}/>
              ""
            )}
          </>
        ) : (
          postsArray &&
          postsArray.length > 0 &&
          postsArray.map((post: any, index: number) => (
            <>
              <PostsContainer
                forum={forum}
                postsArray={postsArray}
                style={{ backgroundColor: "" }}
                bgcolor={bg_graderForumPost}
              >
                {postsArray && postsArray.length > 1 && (
                  <>
                    <Typography
                      text={`${t("grader.Post")} ${toArabicDigits(
                        (index + 1).toString()
                      )}`}
                      fontSize="1rem"
                    />
                    <div style={{ marginBottom: "1rem" }}></div>
                  </>
                )}

                <GAAdesc>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(decodeHtml(post.postText)),
                    }}
                    style={{
                      fontSize: "0.875rem",
                      lineHeight: "1.375rem",
                      fontFamily: "Primary Font-Light",
                    }}
                  />
                </GAAdesc>
                <div style={{ marginBottom: "0.6rem" }}></div>
                {/* LIKE AND VIEW REPLIES FOR EACH POST*/}
                <PTLLineActions>
                  <PTLActionsCell
                    onClick={() => handleLike(index)}
                    style={{
                      color: "#AAB1B9",
                    }}
                  >
                    <TypographyRegularDescription
                      text={post.postLike}
                      fontSize="0.75rem"
                    />
                    <SvgLike />
                  </PTLActionsCell>

                  {/* <PTLActionsCell
                  onClick={() => handleDisLike(index)}
                  style={{
                    color: likeState[index]?.dislike ? "#5DD3B3" : "#ffffff80",
                  }}
                >
                  <TypographyRegularDescription
                    text={
                      likeState[index]?.dislike
                        ? val.postDisLike + 1
                        : val.postDisLike
                    }
                    fontSize="0.75rem"
                  />
                  <SvgDisLike />
                </PTLActionsCell> */}
                  {post.Reply && post.Reply.length > 0 && (
                    <PTLActionsCell
                      onClick={() => handleView(index)}
                      style={{
                        color: likeState[index]?.view ? "#5DD3B3" : "#ffffff80",
                      }}
                    >
                      <TypographyRegularDescription
                        text={t("forum.View Replies")}
                        fontSize="0.75rem"
                      />
                    </PTLActionsCell>
                  )}
                </PTLLineActions>
                <div style={{ marginBottom: "1rem" }}></div>
                {likeState[index]?.view && post.Reply && (
                  <ViewPost ViewPostData={post.Reply} />
                )}

                {/* her you must make the map render  */}
                {post.fileName?.trim() !== "" &&
                post.fileName?.endsWith(".pdf") ? (
                  <GAAContent>
                    <GAAGaps>
                      <SvgGroup3339 style={{ height: "2rem", width: "2rem" }} />
                      <MediumTypography
                        text={uuidSplitter(post.fileName || "").replace(
                          /^(forum\/submissions\/)/,
                          ""
                        )}
                      />
                    </GAAGaps>
                    <GAAGaps>
                      <GAAActions
                        onClick={() =>
                          EyeClick2 && EyeClick2(post.file, post.fileName)
                        }
                      >
                        <SvgEye />
                        <LightTypography
                          text={t("assignment.View")}
                          fontSize="0.875rem"
                          color="#5DD3B3"
                        />
                      </GAAActions>
                      <a href={post.file} download={post.file}>
                        <GAAActions>
                          <SvgDownload fill="#5DD3B3" />
                          <LightTypography
                            text={t("online session.Download")}
                            fontSize="0.875rem"
                            color="#5DD3B3"
                          />
                        </GAAActions>
                      </a>
                    </GAAGaps>
                  </GAAContent>
                ) : post.fileName?.trim() !== "" ? (
                  DisplayContentDisplay(post.file, post.fileName)
                ) : (
                  ""
                )}
              </PostsContainer>
            </>
          ))
        )}
      </GAAContainer>
    </GAALayout>
  );
};

export default GraderAssignmentAnswer;
