import styled from "styled-components";
import { breakpoints } from "../../../theme";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";
interface CourseCardWrapperProps {
  bgimage?: string;
  bgcolor?: string;
  role: string;
  theme?: string;
  language?: string | null;
}
interface CourseCardDetailHolderProps {
  rolelist: string;
  language?: string | null;
}
interface IconsHolder {
  iconcolor: string;
  backgroundcolor: string;
  iconhoveredcolor: string;
  backgroundhoveredcolor: string;
  // role: string;
}
export const CourseCardDetailHolder = styled.div<CourseCardDetailHolderProps>`
  /* margin-top: 1rem; */
  position: relative;
  height: 14.4rem;
  height: ${(props) => (props.rolelist === "teacher" ? "12.5rem" : "14.4rem")};
  /* height: 13rem; */
  /* background-color:red; */
  direction: ${(props) => (props.language === "ar" ? "rtl" : "ltr")};
`;
export const CourseCardWrapper = styled.div<CourseCardWrapperProps>`
  position: absolute;
  top: 0;
  right: ${(props) => (props.language === "ar" ? "0" : "")};
  z-index: 2;
  /* height: 12.0625rem; */
  height: ${(props) =>
    props.role === "teacher" ? "10.2625rem" : "12.0625rem"};
  /* background-color:red; */
  width: 20.25rem;
  border-radius: 1.25rem;
  box-shadow: 0px 3px 6px #00000029;
  border: 0px none;
  padding: 1.38rem 1.3rem 1.19rem 1.5rem;

  display: flex;
  flex-direction: column;
  /* gap: 0.7rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    /* box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.15); */
    transition: box-shadow 0.4s ease-in;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 19rem;
  }
`;
export const CourseCardOverlay = styled.div<CourseCardWrapperProps>`
  position: absolute;
  top: 0;
  right: ${(props) => (props.language === "ar" ? "0" : "")};
  opacity: ${(props) => (props.theme === "light" ? "0.35" : "0.25")};
  z-index: 1;
  /* background-color:blue; */
  /* height: 12.0625rem; */
  height: ${(props) =>
    props.role === "teacher" ? "10.2625rem" : "12.0625rem"};
  width: 20.25rem;
  background: ${(props) => props.bgcolor};
  background: ${(props) =>
    props.bgimage
      ? `url(${props.bgimage}), ${props.bgcolor}`
      : `${props.bgcolor}`};
  background-size: cover;
  /* opacity: 0.2; */
  /* background-position: center; */
  background-repeat: no-repeat;
  border-radius: 1.25rem;
  box-shadow: 0px 3px 6px #00000029;
  border: 0px none;
  padding: 1.1rem 1.44rem;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* filter: brightness(50%); */
  /* filter: grayscale(50%); */
  filter: brightness(110%);
  transition: filter 0.3s ease, box-shadow 0.3s ease; /* Combine transitions */

  &:hover {
    /* filter: brightness(100%);  */
    box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.15);
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 19rem;
  }
`;
export const CourseHoverHolder = styled.div<{
  ishovered: string;
  language?: string | null;
}>`
  /* cursor: pointer; */
  position: absolute;
  top: 0.5;
  right: ${(props) => (props.language === "ar" ? "" : "2rem")};
  left: ${(props) => (props.language === "ar" ? "2rem" : "")};
  /* &:dir(ltr) {
    right: 2rem;
  }
  &:dir(rtl) {
    left: 2rem !important;
  } */

  z-index: 2;
  display: ${(props) => (props.ishovered === "true" ? "block" : "none")};
  opacity: ${(props) => (props.ishovered === "true" ? "1" : "0")};
  transition: opacity 0.4s ease-in-out;
`;
export const ProfileHolder = styled.div`
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  background-color: #576371;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to right,
    rgb(65, 81, 96) 0%,
    rgb(41, 52, 64) 100%
  );
  /* background:red; */
`;
export const MaskProfileDiv = styled.div<StyleThemeInterface>`
  width: 3.6875rem;
  height: 3.6875rem;

  border-radius: 50%;

  background: ${(props) => props.bgcolor};
  position: relative;

  /* padding:0.4rem; */
`;
export const MaskGroup = styled.img<StyleThemeInterface>`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  border: 1.1px solid ${(props) => props.bordercolor};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const DashBoardProfileDot = styled.div<StyleThemeInterface>`
  width: 0.4375rem;
  height: 0.4375rem;
  flex-shrink: 0;
  border-radius: 50%;
  background: ${(props) => props.bgcolor || "#5DD3B3"};
  position: absolute;
  bottom: 13%;
  right: 13%;
  /* transform: translate(-50%, -50%); */
`;
export const TopHolder = styled.div`
  display: flex;
  width: 100%;

  /* flex-direction: row-reverse; */
  justify-content: space-between;
  &:dir(ltr) {
    flex-direction: row;
  }
  &:dir(rtl) {
    flex-direction: row-reverse !important;
  }
`;

export const CodeHolder = styled.div<{ gradientcolor: string[], language?: any }>`
  display: flex;
  /* flex-direction: row-reverse; */
  /* justify-content:center; */
  align-items: center;
  background: linear-gradient(
    to right,
    ${(props) => props.gradientcolor[0]} 0%,
    ${(props) => props.gradientcolor[1]} 100%
  );
  border-radius: 0.56rem;
  padding: 0.6rem 0.5rem 0.6rem 0.75rem;
  width: 35%;
  height: 1.875rem;
  text-align: right !important;
  /* &:dir(ltr) {
    margin-left: -2rem;
  }
  &:dir(rtl) {
    margin-right: -2rem;
  } */
  margin-right: ${(props) => (props.language === "ar" ? "-2rem" : "")};
  margin-left: ${(props) => (props.language === "ar" ? "" : "-2rem")};

  /* margin-top: -2rem; */
`;

export const StatusDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IconsHolder = styled.div`
  width: 100%;
  display: flex;
  /* background-color:red !important; */
  height: 1.875rem !important;
  justify-content: space-evenly;
  gap: 3.31rem;
`;

export const IconHolder = styled.div<IconsHolder>`
  cursor: pointer;
  border: 0px none;
  width: 1.875rem !important;
  color: white;
  height: 1.875rem !important;
  border-radius: 50%;
  /* background-color: #384450; */
  background-color: ${(props) => props.backgroundcolor};
  color: ${(props) => props.iconcolor};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.7s ease-in-out;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
  &:hover {
    color: ${(props) => props.iconhoveredcolor};
    background-color: ${(props) => props.backgroundhoveredcolor};
  }
`;

export const ActivityHolder = styled.div`
  position: absolute;
  left: 0;
  top: 15rem;
`;
export const CourseCardMain = styled.div<{
  isDashboardResourcesVisible: boolean;
}>``;
export const ButtonStyle = styled.button`
  /* box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); */
  color: white;
  border: none;
  background-color: transparent;
  outline: none;
  border-radius: 50%;
  padding: 0;
  margin: 0;
`;
