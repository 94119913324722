import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";


export const RubricPageeLayout = styled.div`
display:flex;
flex-direction:column;
gap:1.25rem;
height: 60vh;

`;
export const RubricTableLayout = styled.div`
display:flex;
flex:100%;
flex-direction:column;
align-content: flex-start;
background: transparent;
`;
export const RubricDescription = styled.div`
margin: -0.56rem 1.25rem 1.25rem 1.25rem
`;

export const RubricTableLayoutRow = styled.div<StyleThemeInterface>`
  flex: 100%;
  display: flex;

  &:first-child{
background: ${props => props.first_child_bgcolor};
padding: 0.625rem 0;
  }

  &:nth-child(6n + 3), &:nth-child(6n + 5) {
    background: ${props => props.nth_child_bgcolor};
  }

  &:last-child{
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
  }
`;

export const RubricTableLayoutColumn = styled.div`
flex:100%;
display:flex;
flex-direction:column;
`;

export const OneRowViewShapeDiv = styled.div`
display:flex;
height:2rem;
padding: 1.25rem;
align-items:center;
`;

export const OneRowHeaderDiv = styled.div`
display:flex;
height:2rem;
justify-content:space-between;
align-items:center;
width:90%;
padding: 1.25rem 0.5rem;
`;

export const RuricCratieriaText = styled.div`
display:flex;
flex-direction:column;
width:100%;
gap:0.63rem;
padding:1.25rem;
`;

export const RuricCratieriaMine = styled.div`
display:flex;
flex-direction:column;
width:100%;
`;

export const ShapeDivB = styled.div`
display:flex;
flex-direction:column;
gap:0.60rem;
flex:100%;
`;

export const ShapeDivBTextFormat = styled.div`
width:90%;
`;

export const ShapeDivBTextFormatColumn = styled.div`
display:flex;
flex-direction:column;
gap:0.60rem;
width:100%;
justify-content:space-between;
padding: 1.25rem 0.5rem;
margin: 0;
`;

export const TopContent = styled.div`
margin: 0 1.25rem;
`;
export const ButtonsCont = styled.div`
display: flex;
justify-content: flex-end;
margin-right: 1.88rem;
`;
export const LoaderDiv = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 1.25rem;
`;
