import React, { FC, useState } from "react";
import {
  SubmittedContainer,
  SubmittedMain,
  SubmittedQuestion,
  QuestionContainer,
  RedDot,
  SubmittedNumber,
  Score,
} from "./SubmittedQuestion.style";
import { SubmittedQuizQuestions } from "./SubmittedQuestionsDataInterface";
import {
  LightTypography,
  TypographyRegularDescription,
  Typography,
} from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import { useMyContext } from "../../../contexts/surveyGraderContext";
import { useTranslation } from "react-i18next";
const SubmittedQuestions: FC<SubmittedQuizQuestions> = ({
  questions,
  submission,
  studentIndex,
  questionIndex,
}) => {
  const { setSubmissionDate } = useMyContext();
  const { t } = useTranslation();

  const findAnswerSubmissionDate = (SurveyQuestionsId: number) => {
    if (
      submission &&
      submission.length > 0 &&
      questions &&
      questions.length > 0
    ) {
      const studentAnswer = submission[studentIndex].answers.find(
        (answer: any) => answer.SurveyQuestionsId === SurveyQuestionsId
      );
      const dateString = studentAnswer ? studentAnswer.created_at : "";

      if (dateString) {
        const properDate = new Date(dateString);
        // Format the date as per your requirements
        const formattedDate = properDate.toLocaleString();
        setSubmissionDate(formattedDate); // Example format, you can use other formats as needed
        return formattedDate;
      } else {
        setSubmissionDate("");
        return "";
      }
    } else {
      setSubmissionDate("");
      return "";
    }
  };

  const numberPart =
    questions &&
    questions.length > 0 &&
    questions[questionIndex].title.split(" ")[1];
  return (
    <SubmittedMain>
      <SubmittedContainer>
        <SubmittedQuestion>
          <QuestionContainer>
            <RedDot />
            <LightTypography
              text={`${t("quiz.Question")} ${numberPart}`}
              fontSize="0.875rem"
              lineHeight="1.375rem"
            />
          </QuestionContainer>
        </SubmittedQuestion>

        <SubmittedNumber>
          {/* <Score>
       <TypographyRegularDescription text='score:' fontSize='0.75rem' color={theme.dark.primary_text_color}/>
       <Typography text={`${questions.length} out of 40`} fontSize='0.75rem' color={theme.dark.primary_text_color}/>
       </Score> */}

          <Score>
            <TypographyRegularDescription
              text={`${t("grader.Submitted")}:`}
              fontSize="0.75rem"
            />
            <Typography
              text={findAnswerSubmissionDate(
                questions &&
                  questions.length > 0 &&
                  questions[questionIndex].SurveyQuestionsId
              )}
              fontSize="0.75rem"
            />
          </Score>

          {/* <Score>
       <TypographyRegularDescription text='Time:' fontSize='0.75rem' color={theme.dark.primary_text_color}/>
       <Typography text='2min' fontSize='0.75rem' color={theme.dark.primary_text_color}/>
       </Score> */}
        </SubmittedNumber>
      </SubmittedContainer>
    </SubmittedMain>
  );
};

export default SubmittedQuestions;
