import { Question } from "../GenerateQuizDataInterface";

// This function is looping through the question array, and formating it then return the updated format of question
export const loadTemplate = (storedQuestions:any[]) => {
    // Loop through parsedQuestions and add icon for each type
    const updatedQuestions = storedQuestions.map((question : any) => {
        let updatedQuestion = { ...question };

        // Normal Essay Question
        if (question.type === "ESSAY") {
          updatedQuestion.index = question.questionIndex
          updatedQuestion.questType = "normal";
          updatedQuestion.questPart = [];
          updatedQuestion.grade = (question.points).toString();
          updatedQuestion.choices = [{name:"",isChecked:question.attachments === 1 ? true : false}] ;

          // Normal Mathcing Question
        } else if (question.type === "MATCH") {
          updatedQuestion.index = question.questionIndex
          updatedQuestion.questType = "normal";
          updatedQuestion.questPart = [];
          updatedQuestion.grade = (question.points).toString();
          // Creating the choices array by merging left and right arrays
            const choices = [];
            const left = question.match.left;
            const right = question.match.right;
            // Assuming both left and right arrays have the same length
            for (let i = 0; i < left.length; i++) {
              choices.push({
                index: i + 1,
                left: left[i].toLowerCase(), // Convert to lowercase if needed
                right: right[i].toLowerCase(), // Convert to lowercase if needed
              });
            }
            updatedQuestion.choices = choices;

            // Normal Short Answer Question
        } else if (question.type === "SHORT_ANSWER") {
          updatedQuestion.index = question.questionIndex
          updatedQuestion.questType = "normal";
          updatedQuestion.questPart = [];
          updatedQuestion.grade = (question.points).toString();
          updatedQuestion.choices =question && question.keywords && question.keywords.map((keyword:any, index:number) => ({
            // index: keyword.index,
            name: keyword.keyword,
            // percentage: choice.percentage,
            // isChecked:Number(choice.percentage > 0) ? true : false
          }));

          // Normal MCQ or MRQ Question
        } else if (question.type === "MCQ" || question.type === "MRQ") {
          updatedQuestion.index = question.questionIndex
          updatedQuestion.questType = "normal";
          updatedQuestion.type = "MCQ";
          updatedQuestion.questPart = [];
          updatedQuestion.grade = (question.points).toString();
          updatedQuestion.shuffle = question.shuffleOptions === 1 ? true : false;
          if(question.type === "MRQ"){
            updatedQuestion.choices =question && question.choices && question.choices.map((choice:any, index:number) => ({
              index: choice.index,
              name: choice.choice,
              percentage: choice.percentage,
              isChecked:Number(choice.percentage > 0) ? true : false
            }));
          }else if (question.type === "MCQ"){
            updatedQuestion.choices =question && question.choices && question.choices.map((choice:any, index:number) => ({
              index: choice.index,
              name: choice.choice,
              percentage: choice.correct ? "100" : "0",
              isChecked:choice.correct
            }));
          }

          // Normal Numeric Question
        } else if (question.type === "NUMERIC") {
          updatedQuestion.index = question.questionIndex
          updatedQuestion.questType = "normal";
          updatedQuestion.questPart = [];
          updatedQuestion.grade = (question.points).toString();
          updatedQuestion.choices = [{name:`${question.answer}`,errorMargin:`${question.errorMargin}`}] ;

          // Normal True or False Question
        }else if(question.type === "TF"){
          updatedQuestion.index = question.questionIndex
          updatedQuestion.questType = "normal";
          updatedQuestion.questPart = [];
          updatedQuestion.grade = (question.points).toString();
          updatedQuestion.choices = [{name:"",isChecked:question.answer}] ;
        }
        // Check if the question type is 'composed' and add icons to questPart
        if (question.type === "COMPOSITE") {
          updatedQuestion.questType = "composed"
          updatedQuestion.index = question.questionIndex
          const questPartWithIcons = question.questionParts.map((part:any) => {
            let updatedPart = { ...part };
            // Add icons for each part based on their type

            // Essay Part Question
            if (part.type === "ESSAY") {
              updatedPart.grade = (part.points).toString();
              updatedPart.choices = [{name:"",isChecked:part.attachments === 1 ? true : false}] ;

              // Matching Part Question
            } else if (part.type === "MATCH") {
              updatedPart.grade = (part.points).toString();
              // Creating the choices array by merging left and right arrays
            const choices = [];
            const left = part.match.left;
            const right = part.match.right;
            // Assuming both left and right arrays have the same length
            for (let i = 0; i < left.length; i++) {
              choices.push({
                index: i + 1,
                left: left[i].toLowerCase(), // Convert to lowercase if needed
                right: right[i].toLowerCase(), // Convert to lowercase if needed
              });
            }
            updatedPart.choices = choices;

            // Short Answer Part Question
            } else if (part.type === "SHORT_ANSWER") {
              updatedPart.grade = (part.points).toString();
              updatedPart.choices =part && part.keywords && part.keywords.map((keyword:any, index:number) => ({
                // index: keyword.index,
                name: keyword.keyword,
                // percentage: choice.percentage,
                // isChecked:Number(choice.percentage > 0) ? true : false
              }));

              // MCQ or MRQ Part Question
            } else if (part.type === "MCQ" || part.type === "MRQ") {
              updatedPart.grade = (part.points).toString();
              updatedPart.type = "MCQ";
              updatedPart.shuffle = part.shuffleOptions === 1 ? true : false;
              if(part.type === "MRQ"){
                updatedPart.choices =part && part.choices && part.choices.map((choice:any, index:number) => ({
                  index: choice.index,
                  name: choice.choice,
                  percentage: choice.percentage,
                  isChecked:Number(choice.percentage > 0) ? true : false
                }));
              }else if (part.type === "MCQ"){
                updatedPart.choices =part && part.choices && part.choices.map((choice:any, index:number) => ({
                  index: choice.index,
                  name: choice.choice,
                  percentage: choice.correct ? "100" : "0",
                  isChecked:choice.correct
                }));
              }

              // Numeric Part Question
            } else if (part.type === "NUMERIC") {
              updatedPart.grade = (part.points).toString();
              updatedPart.choices = [{name:`${part.answer}`,errorMargin:`${part.errorMargin}`}] ;

              // True or False Part Question
            }else if(part.type === "TF"){
              updatedPart.grade = (part.points).toString();
              updatedPart.choices = [{name:"",isChecked:part.answer}] ;
            }
            return updatedPart;
          });
          updatedQuestion.questPart = questPartWithIcons;
        }
        return updatedQuestion;
      });

      return updatedQuestions;
}
// End of function


// This Function Calculate the count of each question,the Total Grade, and the Total of Questions
export const setQuestionTypesCount = (questions:Question[]) => {
    let newQuestions = [...questions];
    const totalQuestions = newQuestions.length;

    // Multiple Choice Question Count
    const multipleChoiceCount = newQuestions.filter((q) => {
      if (q.type === "MCQ" ||  q.type === "MRQ") {
        return true;
      } else if (q.questPart && q.questPart.length > 0) {
        return q.questPart.some((part) => part.type === "MCQ" || part.type === "MRQ");
      }
      return false;
    }).length;

    // Short Answer Question Count
    const shortAnswerCount = newQuestions.filter((q) => {
      if (q.type === "SHORT_ANSWER") {
        return true;
      } else if (q.questPart && q.questPart.length > 0) {
        return q.questPart.some((part) => part.type === "SHORT_ANSWER");
      }
      return false;
    }).length;

    // Numeric Question Count
    const numericalCount = newQuestions.filter((q) => {
      if (q.type === "NUMERIC") {
        return true;
      } else if (q.questPart && q.questPart.length > 0) {
        return q.questPart.some((part) => part.type === "NUMERIC");
      }
      return false;
    }).length;

    // Essay Question Count
    const essayCount = newQuestions.filter((q) => {
      if (q.type === "ESSAY") {
        return true;
      } else if (q.questPart && q.questPart.length > 0) {
        return q.questPart.some((part) => part.type === "ESSAY");
      }
      return false;
    }).length;

    // Matching Question Count
    const matchingCount = newQuestions.filter((q) => {
      if (q.type === "MATCH") {
        return true;
      } else if (q.questPart && q.questPart.length > 0) {
        return q.questPart.some((part) => part.type === "MATCH");
      }
      return false;
    }).length;

    // True Or False Qusetions Count
    const trueFalseCount = newQuestions.filter((q) => {
      if (q.type === "TF") {
        return true;
      } else if (q.questPart && q.questPart.length > 0) {
        return q.questPart.some((part) => part.type === "TF");
      }
      return false;
    }).length;

    // Total Grade Of Questions
    const normalQuestionsGrade = newQuestions.reduce((acc, q) => {
      const questionGrade = parseFloat(q.grade);
      return isNaN(questionGrade) ? acc : acc + questionGrade;
    }, 0);
  
    // Calculate total grade of composed questions (questions with parts)
    const composedQuestionsGrade = newQuestions
      .filter((q) => q.questPart && q.questPart.length > 0)
      .reduce((acc, q) => {
        const partsGrade = q.questPart.reduce((partAcc, part) => {
          const partGrade = parseFloat(part.grade);
          return isNaN(partGrade) ? partAcc : partAcc + partGrade;
        }, 0);
        return acc + partsGrade;
      }, 0);
  
    const totalGrade = normalQuestionsGrade + composedQuestionsGrade;
    const grade = 100

    return {
        totalQuestions,
        multipleChoiceCount,
        shortAnswerCount,
        numericalCount,
        essayCount,
        matchingCount,
        trueFalseCount,
        grade,
        totalGrade

    }

};
// End of function


// This Function is formation the question Array Before saving it to the backend
export const formatingBeforeSave = (question:any) => {
    let updatedQuestion = { ...question }; 
  
    const checkedChoices = updatedQuestion.choices ? updatedQuestion.choices.filter((c:any) => c.isChecked || c.correct) : [];
    const choicesArray = updatedQuestion.choices ? updatedQuestion.choices : [];
  
    if (updatedQuestion.questType === "normal") {
      if (updatedQuestion.type === "MCQ" && checkedChoices.length > 1) {
        updatedQuestion.type = "MRQ"; 
        // Format choices for MRQ
      updatedQuestion.choices = updatedQuestion.choices.map((choice:any, index:number) => ({
        index: index + 1,
        choice: choice.name,
        percentage: choice.percentage,
      }));
      } else if (updatedQuestion.type === "MRQ" && checkedChoices.length < 2) {
        updatedQuestion.type = "MCQ"; 
        // Format choices for MCQ
      updatedQuestion.choices = updatedQuestion.choices.map((choice:any, index:number) => ({
        index: index + 1,
        choice: choice.name,
        correct: choice.isChecked,
      }));
      }else if (updatedQuestion.type === "MCQ" && checkedChoices.length < 2){
         // Format choices for MCQ
      updatedQuestion.choices = updatedQuestion.choices.map((choice:any, index:number) => ({
        index: index + 1,
        choice: choice.name,
        correct: choice.isChecked,
      }));
      }else if (updatedQuestion.type === "TF" && checkedChoices.length === 1) {
        updatedQuestion.Answer = checkedChoices[0].isChecked || false;
      }
      else if (updatedQuestion.type === "NUMERIC") {
        updatedQuestion.Answer = Number(choicesArray[0].name)  || 0;
        updatedQuestion.errorMargin = Number(choicesArray[0].errorMargin)  || 0;
      }
      else if (updatedQuestion.type === "SHORT_ANSWER") {
        updatedQuestion.choices = updatedQuestion.choices.map((choice:any, index:number) => ({
          index: index + 1,
          keyword: choice.name,
        }));
      }else if (updatedQuestion.type === "ESSAY") {
        updatedQuestion.attachments = choicesArray[0].isChecked;
      }else if (updatedQuestion.type === "MATCH") {
        const terms: { index: number, term: string }[] = [];
        const match: { index: number, match: string }[] = [];
    
        updatedQuestion.choices.forEach((choice: any, index: number) => {
          terms.push({ index: index + 1, term: choice.left });
          match.push({ index: index + 1, match: choice.right });
        });
    
        updatedQuestion.terms = terms;
        updatedQuestion.matches = match;
      }
     
    } else if (updatedQuestion.questType === "composed") {
      // If it's a composed question
      updatedQuestion.questPart.forEach((part:any) => {
          const checkedPartChoices = part.choices ? part.choices.filter((c:any) => c.isChecked || c.correct) : [];
          const partChoicesArray = part.choices ? part.choices : [];
          if (part.type === "MCQ" && checkedPartChoices.length > 1) {
            part.type = "MRQ"; // Change the type to "MRQ" for the part if checked choices are 2 or more
             // Format choices for MRQ in composed questions
            part.choices = part.choices.map((choice:any, index:number) => ({
            index: index + 1,
            choice: choice.name,
            percentage: choice.percentage,
          }));
          } else if (part.type === "MRQ" && checkedPartChoices.length < 2) {
            part.type = "MCQ"; // Change the type to "MCQ" for the part if checked choices are less than 2
             // Format choices for MCQ in composed questions
          part.choices = part.choices.map((choice:any, index:number) => ({
            index: index + 1,
            choice: choice.name,
            correct: choice.isChecked,
          }));
          }else if (part.type === "MCQ" && checkedChoices.length < 2){
            // Format choices for MCQ in composed questions
          part.choices = part.choices.map((choice:any, index:number) => ({
            index: index + 1,
            choice: choice.name,
            correct: choice.isChecked,
          }));
          }else if (part.type === "TF" && checkedPartChoices.length === 1) {
            part.Answer = checkedPartChoices[0].isChecked || false;
          }else if (part.type === "NUMERIC") {
            part.Answer = Number(partChoicesArray[0].name)  || 0;
            part.errorMargin = Number(partChoicesArray[0].errorMargin)  || 0;
          }else if (part.type === "SHORT_ANSWER") {
            part.choices = part.choices.map((choice:any, index:number) => ({
              index: index + 1,
              keyword: choice.name,
            }));
          }else if (part.type === "ESSAY") {
            part.attachments = partChoicesArray[0].isChecked;
          }else if (part.type === "MATCH") {
            const terms: { index: number, term: string }[] = [];
            const match: { index: number, match: string }[] = [];
        
            part.choices.forEach((choice: any, index: number) => {
              terms.push({ index: index + 1, term: choice.left });
              match.push({ index: index + 1, match: choice.right });
            });
        
            part.terms = terms;
            part.matches = match;
          }
  
          
      });
    }
  
    return updatedQuestion;
};
// End of function


// This function is creating the question when it's droped according to the type of Question
export const handleDropQuestions = (questions:any[],index:number,partIndex:number,data:any, t:any) => {
    const updatedQuestions = [...questions];
    if (
      // Normal Questions (Without Types)
      updatedQuestions[index] &&
      updatedQuestions[index]?.questType === "normal"
    )  {
      updatedQuestions[index].type = data?.name;
      updatedQuestions[index].icon = data?.icon;
      updatedQuestions[index].index = index + 1;
      updatedQuestions[index].title = `Question ${index + 1}`;
      updatedQuestions[index].feedRight = t("quiz.Your Answer is correct.")
      updatedQuestions[index].feedWrong = t("quiz.Your Answer is incorrect.")
      
      if (data?.name === "NUMERIC") { // Numeric Question
        updatedQuestions[index].choices = [{ name: "", errorMargin: "" }];
      } else if (data?.name === "ESSAY") { // Essay Question
        updatedQuestions[index].choices = [{ name: "", isChecked: false }];
        updatedQuestions[index].rubricId = 0
      } else if (data?.name === "TF") { // Tru Or False Question
        updatedQuestions[index].choices = [{ name: "" , isChecked: false}];
      }else if (data?.name === "SHORT_ANSWER") { // Short Answer Question
        updatedQuestions[index].choices = [{ name: "" }];
      }  else if (data?.name === "MCQ") { // MCQ Question
        updatedQuestions[index].choices = [
          { index:1 , name: "", percentage: "100", isChecked: true },
          { index:2 , name: "", percentage: "0", isChecked: false },
        ];
      }else if (data?.name === "MATCH") { // Matching Question
        updatedQuestions[index].choices = [
          { index:1 , left: "", right: "" },
          { index:2 , left: "", right: "" },
        ];
      }
    } else if (
      // Composed Questions (With Types)
      updatedQuestions[index] &&
      updatedQuestions[index]?.questType === "composed"
    ) {
      updatedQuestions[index].questPart[partIndex].type = data.name;
      updatedQuestions[index].questPart[partIndex].icon = data.icon;
      updatedQuestions[index].index =index + 1;
      updatedQuestions[index].questPart[partIndex].index =partIndex + 1;
      updatedQuestions[index].title = `Question ${index + 1}`;
      updatedQuestions[index].questPart[partIndex].feedRight = t("quiz.Your Answer is correct.")
      updatedQuestions[index].questPart[partIndex].feedWrong = t("quiz.Your Answer is incorrect.")
      updatedQuestions[index].questPart[partIndex].title = `Part ${String.fromCharCode(65 + partIndex)}`
      if (data?.name === "NUMERIC") { // Numeric Part
        updatedQuestions[index].questPart[partIndex].choices = [
          { name: "", errorMargin: "" },
        ];
      } else if (data?.name === "ESSAY") { // Essay Part
        updatedQuestions[index].questPart[partIndex].choices = [
          { name: "", isChecked: false },
        ];
        updatedQuestions[index].questPart[partIndex].rubricId = 0
      }else if (data?.name === "TF") { // True Or False Part
        updatedQuestions[index].questPart[partIndex].choices = [
          { name: "" , isChecked: false},
        ];
      }else if (data?.name === "SHORT_ANSWER") { // Short Answer Part
        updatedQuestions[index].questPart[partIndex].choices = [
          { name: "" },
        ];
      }else if (data?.name === "MCQ") { // MCQ Part
        updatedQuestions[index].questPart[partIndex].choices = [
          { name: "", percentage: "100", isChecked: true },
          { name: "", percentage: "0", isChecked: false },
        ];
      }
      else if (data?.name === "MATCH") { // Matching Part
        updatedQuestions[index].questPart[partIndex].choices = [
          { left: "", right: "" },
          { left: "", right: "" },
        ];
      }
    }
    return updatedQuestions;
}
// End of function


// Adding Choices for Matching, MCQ, and Short Answer Questions
export const addChoices = (choice:any[],quest:any) => {
    let choiceField = [...choice];
    if(quest?.type === "MATCH" && choice.length < 15){
      // Matching Question
      const questChoice = {
        index: choice.length + 1,
        right: "",
        left: "",
      };
      choiceField?.push(questChoice);
      return choiceField;
    } else if ((quest?.type === "MCQ" || quest?.type === "MRQ") && choice.length < 15){
      // MCQ or MRQ Question
      const questChoice = {
        index: choice.length + 1,
        name: "",
        percentage: "",
        isChecked: false,
      };
      choiceField?.push(questChoice);
      return choiceField;
    }
    else if(choice.length < 15) {
      // Short Answer Question
      const questChoice = {
        right: "",
        left: "",
        name: "",
        percentage: "",
        errorMargin:"",
        isChecked: false,
      };
      choiceField?.push(questChoice);
      return choiceField;
    }
}
// End of function


// Filtering Before Saving
export const filteringQuestions = (questions:Question[]) => {
    let updatedQuestions = [...questions];
    updatedQuestions.forEach((question, index) => {
      question.index = index + 1;
      question.title = `Question ${index + 1}`;
      if (question.questType === "composed") {
        question.questPart.forEach((part, partIndex) => {
          part.index = partIndex + 1;
          part.title = `Part ${String.fromCharCode(65 + partIndex)}`;
        });
      }
      updatedQuestions[index] = formatingBeforeSave(question);
    });
    const filteredQuestions = updatedQuestions
      .map(({ icon, questPart, ...rest }) => {
        const updatedQuestPart = questPart.map(({ icon: questIcon, ...part }) => part);
        return {
          ...rest,
          questPart: updatedQuestPart,
          choices: rest.choices ? [...rest.choices] : [],
        };
      })
      .filter(question => question.type !== "" && question.type !== "composed");
      return filteredQuestions;
}
// End of function