import styled from 'styled-components'

export const EventContainer=styled.div`
width: 100%;
height:auto;
min-height:100%;
background: linear-gradient(0deg, #415160 0%, #293440 119.99%);
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
display: flex;
flex-direction:column ;
/* padding-top:0.6rem; */
/* padding-left:0.2rem; */
/* margin-top:1.4rem; */
/* justify-content:flex-start; */
/* align-items:flex-start; */
`

export const EventMainContainer = styled.div`
  display: flex;
  align-items: center;
gap:0.5px;
  height:20px;
  padding: 1px;
 
`;

export const EventsContent=styled.div`
/* margin-top:1rem; */
display: flex;
flex-direction:column;
gap:0.62rem;
align-items:center;
/* background-color:aqua !important; */

`;

export const DateTimeContainer=styled.div`
display: flex;
flex-direction:column;
gap:0.50rem;
align-items:center;
/* background-color:red !important; */
justify-content:center;
padding-left:0.3rem;
`;

export const MenuIconContainer=styled.div`
display: flex;
justify-content:flex-end;
margin-top:0.62rem ;
margin-right:0.62rem ;

`;