import styled from "styled-components";

export const FlexDiv = styled.div<{ gap?: string }>`
display: flex;
align-items: center;
gap: ${({ gap }) => (gap ? gap : "0.5rem")};
`;

export const FlexBetween = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`;
export const Container = styled.div`
display: flex;
flex-direction: column;
gap: 1.25rem;
margin-bottom: 1.25rem;
`;

export const ModuleContainer = styled.div`
width: 100%;
height: fit-content;
padding: 2.12rem 0 0 0;
border-radius: 1.25rem;
background: #384450;
`;
export const ModulesAndLeaderCont = styled.div`
display: flex;
width: 100%;
gap: 1.25rem;
`;
export const AllModulesCont = styled.div`
display: flex;
flex-direction: column;
flex: 1; /* Takes up the remaining space */
gap: 1.25rem;
`;


export const ModuleTitle = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
gap: 1.25rem;
margin: 0 1.25rem 2.5rem 1.25rem;
`;

export const ProgressBarContainer = styled.div`
display: flex;
flex-direction: column;
gap: 0.5rem;
margin: 1.25rem 1.25rem 2.5rem 1.25rem ;
`;

export const ProgressBarLine = styled.div`
  width: 100%;
  height: 0.5rem;
  background-color: #20272e;
  border-radius: 6.25rem;
`;
export const Progress = styled.div<{ width?: string; backgroundColor?: string }>`
  width: ${({ width }) => (width ? width : "0rem")};
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : "#fff")};
  height: 0.5rem;
  border-radius: 6.25rem;
`;

export const ActivityContainer = styled.div`
width: 100%;
padding: 1.12rem 1.25rem;
background: rgba(255, 255, 255, 0.03);
&:last-child {
    border-radius: 0 0 1.25rem 1.25rem;
  }
`;