import React, { useEffect, useRef, useState } from "react";
import {
  DownloadIconPlace,
  Video,
  VideoContainer,
  VideoPlayerContainer,
} from "./VideoPlayer.style";
import axios from "axios";
import { S3Get } from "../../services/S3Get";
import { fetchVideo, videoPlayerHandler } from "./VideoPlayerFunctions";
import { VideoPlayerProps } from "./VideoPlayerInterfaces";
import VideoControls from "./VideoControls";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import { LightTypography } from "../../elements/fonts/Fonts";
import { t } from "i18next";
import { SvgDownload } from "../../elements/Icons";
import { PointerContainer } from "../generateAttendance/GenerateAttendance.style";
export default function VideoPlayer({
  role,
  classId,
  videoUrl,
  videoId,
  isTimelineOpen,
  closeTimeline,
  openTimeline,
  transcription,
  downloadable,
  stretch,
}: VideoPlayerProps) {
  const [initialProgress, setInitialProgress] = useState<number>(0);
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const videoPlayer = useRef<HTMLVideoElement>(null);
  const [videocontrols, setvideocontrols] = useState(true);
  const [videoState, setVideostate] = useState(false);
  const [videoDuration, setVideoDuration] = useState({
    current: "0:0:0",
    total: "0:0:0",
  });
  const [playbackSpeedOption, setPlaybackSpeedOption] = useState(1.0);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [vidTranscript, setTranscript] = useState<string>("");
  const [vidThumbnail, setVideoThumbnail] = useState<string>("");
  const [vidPath, setPath] = useState<string>("");
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [currentTimeB, setCurrentTimeB] = useState(0);
  const [length, setLength] = useState<string>("");
  const [progressInitial, setProgressInitial] = useState<number>(0);

  // const [currentTimeLineWidth, setCurrentTimeLineWidth] = useState("0%");

  const [key, setKey] = useState(videoId); // Initialize key state with videoId

  // Update the key whenever videoId changes
  useEffect(() => {
    setKey(videoId);
  }, [videoId]);
  //mounting videourl
  useEffect(() => {
    setVideoLoaded(false);
    setVideostate(false);
    setVideoDuration({ current: "0:0:0", total: "0:0:0" });
    setPlaybackSpeedOption(1.0);
    setLoading(true);
    fetchVideo({
      setInitialProgress,
      subIdSplitted,
      videoId,
      setData,
      setPath,
      setVideoDuration,
      setTranscript,
      setVideoThumbnail,
      videoPlayer,
      setLoading,
      setCurrentTimeB,
      provider,
    });
  }, [videoId]);
  if (loading) {
    return (
      <div>
        {" "}
        <LightTypography text={`${t("general.loading")}...`} />
      </div>
    );
  } else {
    if (videoPlayer && vidPath) {
      return (
        <VideoPlayerContainer>
          <VideoContainer
            id="videoContainer"
            onMouseEnter={() => setvideocontrols(true)}
            onMouseLeave={() => setvideocontrols(false)}
            bgcolor={
              !videoLoaded ? "rgba(87, 99, 113, 0.6)" : "rgba(87, 99, 113, 0.6)"
            }
          >
            <VideoControls
              setPath={setPath}
              setData={setData}
              setInitialProgress={setInitialProgress}
              role={role}
              initialProgress={initialProgress}
              classId={classId}
              videoLoaded={videoLoaded}
              videoDuration={videoDuration}
              setVideoDuration={setVideoDuration}
              setPlaybackSpeedOption={setPlaybackSpeedOption}
              openTimeline={openTimeline}
              isTimelineOpen={isTimelineOpen}
              playbackSpeedOption={playbackSpeedOption}
              stretch={stretch}
              closeTimeline={closeTimeline}
              videoId={videoId}
              videoPlayer={videoPlayer}
              videoState={videoState}
              videocontrols={videocontrols}
              setVideostate={setVideostate}
              downloadable={data.downloadable}
              vidPath={vidPath}
            />
            <Video
              ref={videoPlayer}
              id="myVideo"
              controlsList={data.downloadable === 1 ? "default" : "nodownload"}
              onClick={() => {
                videoPlayerHandler({ videoPlayer, setVideostate });
              }}
              poster={vidThumbnail}
              onLoad={() => {
                if (videoPlayer.current) {
                }
              }}
              onLoadedMetadata={() => {
                if (videoPlayer.current) {
                  setLength(videoPlayer.current.duration.toString());
                  videoPlayer.current.currentTime = currentTimeB;
                  videoPlayer.current.volume = 0.5;
                  setVideoLoaded(true);
                }
              }}
              onEnded={() => setVideostate(false)}
            >
              <source src={vidPath} type="video/mp4" />
              <source src={vidPath} type="video/webm" />
              <source src={vidPath} type="video/ogg" />
              {/* <track label="English" kind="subtitles" src={transcription} srcLang="en" default /> */}
            </Video>
          </VideoContainer>
        </VideoPlayerContainer>
      );
    } else return null;
  }
}
