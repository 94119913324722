import React from "react";
import StudentProgressTable from "../../../../components/Tables/StudentProgressTable/StudentProgressTable";
import { useNavigate, useParams } from "react-router-dom";
import { FlexAppContainer } from "../../../../App.style";
import AnalyticsBackPage from "../../../../components/AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticsBackPage";

const StudentModals = () => {
  const { stdId } = useParams<{ stdId: string }>();
  const navigate = useNavigate();
  const NavigatePage = () => {
    navigate(-1);
  };
  return (
    <FlexAppContainer column={true} gap="1.25rem">
      <AnalyticsBackPage onClick={NavigatePage} />
      <StudentProgressTable StdId={stdId} />
    </FlexAppContainer>
  );
};

export default StudentModals;
