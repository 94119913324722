import React, { FC } from "react";

import { GraderStatisticsContainer } from "./GraderStatistics.style";
import StatisticsAssignement from "../StatisticsAssignement/StatisticsAssignement";
import StatisticsGrade from "../StatisticsGrade/StatisticsGrade";
import Student from "../Student/Student";
import { GraderStatisticsProps } from "../GraderStatisticsInterface";
const GraderStatistics: FC<GraderStatisticsProps> = ({
  totalSubmissions,
  submitted,
  avarage,
  student,
  //setAvarage,
  setSubmitted,
  // setGraded,
  setStudentIndex,
  students,
  graded,
  enrollements,
  studentIndex,
  allAssignment,
}) => {
  return (
    <GraderStatisticsContainer>
      <StatisticsAssignement
        allAssignment={allAssignment}
        student={student}
        studentIndex={studentIndex}
      />
      <StatisticsGrade
        enrollements={enrollements}
        students={students || []}
        student={student}
        totalSubmissions={totalSubmissions}
        graded={graded}
        submitted={submitted}
        avarage={avarage}
      />
      <Student
        student={student}
        students={students || []}
        // setSubmitted={setSubmitted}
        // setGraded={setGraded}
        //setAvarage={setAvarage}
        setStudentIndex={setStudentIndex}
        studentIndex={studentIndex}
      />
    </GraderStatisticsContainer>
  );
};

export default GraderStatistics;
