import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const Container = styled.div`
  /* padding:1rem; */
  min-width: 42.375rem;

  @media (max-width: 700px) {
    width: auto;
  }
`;

export const Question = styled.div`
  font-family: "Primary Font-Bold";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #5dd3b3;
  letter-spacing: 0rem;
  padding-left: 1.5rem;
`;

interface DivideLineProps {
  lineIconColor: string;
  theme: string;
}
export const HorizantalLine = styled.div<DivideLineProps>`
  margin-top: 1.25rem;
  border-style: solid;
  border-width: 2px;
  border-color: ${(props) => (props.theme === "light" ? "#576371" : "#fff")};
  width: 10%;
  &:dir(ltr) {
    margin-left: -25px;
  }
  &:dir(rtl) {
    margin-right: -25px;
  }
`;
export const SecondHorizantalLine = styled.div<StyleThemeInterface>`
  /* border: 1px solid ${(props) => props.bgcolor || "#576371"}; */
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.lineiconcolor};
  background: ${(props) => props.bgcolor || "#576371"};
  min-width: 29.25rem;
  height: 0;
`;

export const HeaderContainer = styled.div`
  margin-top: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.25rem;
  padding-right: 1.5rem;
`;

export const Text = styled.div`
  font-family: "Primary Font";
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  margin-top: 10px;
  padding-left: 1.2rem;
`;

export const BodyContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const UploadInputContainer = styled.label`
  display: inline-block;
  width: 88px;
  height: 88px;
  margin-top: 5px;
  cursor: pointer;
  text-align: center;
  input {
    display: none;
  }
`;

export const ImagesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
export const SelectedImage = styled.img`
  width: 88px;
  height: 88px;
`;
