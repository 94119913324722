import styled from "styled-components";

export const StudentNumericalContainerParent = styled.div`
display: flex;
flex-direction: column;
width: 100%;
gap: 1rem;
`;
export const CommentContainer = styled.div`
width: 100%;
gap: 1rem;
padding:1.5rem ;
border-radius: 10px;
background: rgba(255, 255, 255, 0.03);
`;
