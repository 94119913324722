import styled from "styled-components";
import { theme } from "../../../theme";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";
export const ToDoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.94rem;
`;

export const CustomCheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CustomCheckboxInput = styled.input`
  display: none;
`;

export const CustomCheckbox = styled.span<{ checked: any }>`
  width: 0.875rem;
  height: 0.875rem;
  border: 2px solid ${theme.dark.primary_text_color};
  background: ${(props) =>
    props.checked
      ? "linear-gradient(to bottom, #5DD3B3, #2F6A5A)"
      : "linear-gradient(to bottom, #576371, #2C3239)"};
`;
export const FilterContainerRowDisplays = styled.div`
  display: flex;
  align-items: center;
  gap: 0.62rem;
  cursor: pointer;
`;
export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.62rem;
  cursor: pointer;
`;

export const Line = styled.div<StyleThemeInterface>`
  background: ${(props) => props.bgcolor || "#576371"};
  width: 45rem;
  height: 0.0625rem;
`;
export const CreateButton = styled.button`
  border-radius: 1.25rem;
  background: #5dd3b3;
  width: 7.1875rem;
  height: 2.1875rem;
  cursor: pointer;
  color: #fff;
  font-family: "Primary font-Medium";
  border: none;
`;
export const CancelButton = styled.button`
  border-radius: 1.25rem;
  background: transparent;
  width: 7.1875rem;
  height: 2.1875rem;
  cursor: pointer;
  color: #d85d66;
  font-family: "Primary font-Medium";
  border: 1px solid #d85d66;
`;
export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.62rem;
`;
