import styled from "styled-components";
import { breakpoints } from "../../theme";

export const ButtonsContainerDisplayed = styled.div`
  width: 100%;
  /* background: yellow; */
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns with equal width */
  gap: 16px; /* Adjusts the spacing between columns */
  height: fit-content;
  @media (max-width: ${breakpoints.tablets}) {
    place-items: center;
  }
`;

export const ButtonIconAndTextStyle = styled.button`
  background-color: transparent;
  border: 1px solid #5dd3b3;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: center;
  cursor: pointer;
  gap: 0.63rem;
  width: 8rem;
  height: 2.3rem;
  color: #5dd3b3;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    background-color: #5dd3b3;
    color: #fff;
  }
`;
