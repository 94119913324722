import styled from "styled-components";
import { breakpoints } from "../../theme";

export const AnnouncementsCardHolder = styled.div<{
  bgcolor?: string;
  boxshadow?: string;
}>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.dark.bg_secondary_color};
  border-radius: 0.625rem;
  width: 100%;
  min-height: fit-content;
  padding: 0.88rem 1.44rem 0.88rem 1.44rem;
  box-shadow: ${(props) => props.boxshadow};
  cursor: default;
  /* position: relative; */
  background: ${(props) => props.bgcolor || "#384450"};
  /* &:hover {
    box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.15);
    transition: box-shadow 0.4s ease-in;
  } */
`;

export const Title = styled.div`
  margin-bottom: 0.62rem;
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
  align-items: center;
  /* background:aqua ; */
  /* z-index:13; */
`;

export const Description = styled.div`
  margin-bottom: 0.63rem;
  cursor: pointer;
  position: relative;
  /* background: red; */
`;

export const ProfileDateHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;
  row-gap: 1.25rem;
  cursor: pointer;
`;
export const DateHolder = styled.div`
  @media (max-width: ${breakpoints.mobile}) {
    opacity: 0.5;
  }
`;

export const SvgEdits = styled.div`
  margin-left: 90%;
`;

export const OptionRows = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 2.5rem;
`;

export const OptionCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 4.75rem;
  border-radius: 0.3125rem;
  background: #1f2731;
  position: absolute;
  right: -1rem;
  top: 0;
  justify-content: left;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  /* background:red; */
  /* z-index:11; */
`;

export const OptionTip = styled.div`
  display: flex;
  width: 100%;
  height: 2.34375rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 0.3125rem;
  padding-left: 1rem;

  &:is(:hover) {
    background: #5dd3b3;
    transition: color 0.2s ease-in;
  }
`;

export const NoteAddButtonClick = styled.div<{ color?: string }>`
  display: flex;
  /* padding: 0 0.6rem 0.6rem 0.6rem; */
  cursor: pointer;
  /* margin-left: auto; */
  /* z-index: 1; */
  color: ${(props) => props.color};
`;
