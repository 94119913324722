import { IMenuItem } from "./sliderTabsInterface";
import {
  SvgAboutIcon,
  SvgContentIcon,
  SvgAnnouncementsIcon,
  SvgParticipantsIcon,
  SvgGroupIcon,
  SvgGradesBannerIcon,
  SvgAnalysisIcon,
  SvgMessageIcon,
  SvgTeams,
} from "../../elements/Icons";

const menuCourseData: IMenuItem[] = [
  {
    icon: SvgAboutIcon,
    title: "course view.About",
    path: "/courseview/about",
  },
  {
    icon: SvgContentIcon,
    title: "dashboard.Content",
    path: "/courseView/content",
  },
  {
    icon: SvgAnnouncementsIcon,
    title: "sidebar.announcements",
    path: "/courseview/announcements",
  },
  {
    icon: SvgParticipantsIcon,
    title: "group.Participants",
    path: "/courseview/participants",
  },
  {
    icon: SvgGroupIcon,
    title: "group.Groups",
    path: "/courseView/groups",
  },
  {
    icon: SvgMessageIcon,
    title: "Chat.Chats",
    path: "/courseView/CHATS",
  },
  // {
  //   icon: SvgAnalysisIcon,
  //   title: "Analytics",
  //   path: "/courseView/Analytics",
  // },
  {
    icon: SvgGradesBannerIcon,
    title: "dashboard.Grades",
    path: "/courseview/Gradebook",
  },
  {
    icon: SvgTeams,
    title: "Teams",
    path: "/courseview/Teams",
  },
];

export default menuCourseData;
