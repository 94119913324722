import React, { FC, useState } from "react";
import {
  Overlay,
  BackgroundOverlay,
  PopupContainer,
  IconsContainer,
  IconAndTitleContainer,
  PopupTitle,
  HorizantalLine,
  CloseIconContainer,
} from "../Popup/PopUp.style";
import Button from "../QuizButton/Button";
import { ReactComponent as CloseIcon } from "../../assets/icons/X_simple_1.svg";
import { Typography } from "../fonts/Fonts";
import { ButtonFLex, LineFLex } from "../PopupLine/PopupLine.style";
import {
  DeletOverLay,
  PopupContentContainerr,
  TextPadding,
} from "./Delet.style";
import BlueButton from "../blueButton/BlueButton";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { PopupSelectorColor } from "../../recoil/ThemeSelectors";

interface Delete {
  show: boolean;
  // content: React.ReactElement;
  Subject: string;

  title: string;
  showline: boolean;
  icon: React.ReactElement;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete: () => void;
}

const PopUpDelete: FC<Delete> = ({
  show,
  Subject,
  setShow,
  title,
  icon,
  showline,
  onDelete,
}) => {
  const { t } = useTranslation();
  const { CloseColor, PageBgColor, ContentContainer_BgColor } =
    useRecoilValue(PopupSelectorColor);
  return (
    <DeletOverLay isvisible={show.toString()}>
      {show && (
        <BackgroundOverlay background={PageBgColor}>
          <PopupContainer>
            <PopupContentContainerr
              style={{ background: ContentContainer_BgColor }}
            >
              <IconsContainer>
                <LineFLex>
                  <React.Fragment>{icon}</React.Fragment>
                  <Typography
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="19.78px"
                    text={title}
                  />
                </LineFLex>
                <CloseIconContainer>
                  <CloseIcon onClick={() => setShow(false)} />
                </CloseIconContainer>
              </IconsContainer>

              <HorizantalLine showline={showline.toString()} />
              <TextPadding>{Subject}</TextPadding>
              <ButtonFLex>
                {/* <Button name="Cancel" onClickFunction={() => setShow(false)} /> */}

                <BlueButton
                  text={t("forms.Cancel")}
                  BackColor="#5DD3B3"
                  Width="7.600REM"
                  Height="2.125rem"
                  OnClick={() => setShow(false)}
                />
                <BlueButton
                  text={t("group.Delete")}
                  Color="#D85D66"
                  Border=" 1px solid #D85D66"
                  BackColor="transparent"
                  Width="7.600REM"
                  Height="2.125rem"
                  OnClick={() => {
                    onDelete();
                    // setShow(false);
                  }}
                />
                {/* <Button
                  name="DELETE"
                  onClickFunction={() => {
                    onDelete();
                    setShow(false);
                  }}
                /> */}
              </ButtonFLex>
            </PopupContentContainerr>
          </PopupContainer>
        </BackgroundOverlay>
      )}
    </DeletOverLay>
  );
};

export default PopUpDelete;
