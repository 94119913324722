import {
  SegmentTitleDataInterface,
  TableHeadDataInterface,
  TableTrInterface,
} from "./manageWeightInterface";

export const SegmentTitleData: SegmentTitleDataInterface[] = [

  {
    segmentTitle: "course creator.Activities",
  },
  {
    segmentTitle: " ",
  },
  {
    segmentTitle: "gradebook.Weight",
  },
  {
    segmentTitle: "gradebook.Publish",
  },
];

export const TableHeadData: TableHeadDataInterface[] = [
  {
    name: "",
    percentage: 5,
  },
  {
    name: "Assignments",
    percentage: 25,
  },
  {
    name: "Quizzes",
    percentage: 35,
  },
  {
    name: "Forums",
    percentage: 40,
  },
  {
    name: "Final Course Weight",
    percentage: 100,
  },
];



export const AttendanceData = [
  {
  Name: "Attendance",
 Weight: "",
  },
];
export const AssigmentData = [
  {
    Name: "Assignment 1",
  Weight: 50,
    module: 1
  },
  {
    Name: "Assignment 2",
   Weight: 20,
    module: 2
  },
  {
    Name: "Assignment 4",
  Weight: 0,
    module: 4
  },
  {
   Name: "Assignment 3",
    Weight: 100,
    module: 3
  },
];

export const QuizData = [
  {
   Name: "Quiz 1",
   Weight: 30,
    module: 1
  },
  {
   Name: "Quiz 2",
    Weight: 35,
    module: 2
  },
  {
    Name: "Quiz 3",
   Weight: 95,
    module: 3
  },
];

export const ForumData = [
  {
    Name: "Forum 1",
    Weight: 100,
    module: 1
  },
  {
    Name: "Forum 2",
    Weight: 30,
    module: 2
  },
  {
    Name: "Forum 3",
  Weight: 50,
    module: 3
  },
];



export const ActivitiesData = [
  {
    Name: "Assignment 1",
  Weight: 50,
    module: 1,
    type:"Assignment",
  },
  {
    Name: "Assignment 2",
   Weight: 20,
    module: 2,
    type:"Assignment",
  },
  {
    Name: "Assignment 4",
  Weight: 0,
    module: 4,
    type:"Assignment",
  },
  {
   Name: "Assignment 3",
    Weight: 100,
    module: 3,
    type:"Assignment",
  },
  {
    Name: "Quiz 1",
    Weight: 30,
     module: 1,
     type:"Quiz",
   },
   {
    Name: "Quiz 2",
     Weight: 35,
     module: 2,
     type:"Quiz",
   },
   {
     Name: "Quiz 3",
    Weight: 95,
     module: 3,
     type:"Quiz",
   },
  {
    Name: "Forum 1",
    Weight: 100,
    module: 1,
    type:"Forum",
  },
  {
    Name: "Forum 2",
    Weight: 30,
    module: 2,
    type:"Quiz",
  },
  {
    Name: "Forum 3",
  Weight: 50,
    module: 3,
    type:"Quiz",
  },
]

export const Activities = [
  {
    "Assignment": {
      "Weight": 20
    }
  },
  {
    "Attendance": {
      "Weight": 20
    }
  },
  {
    "quiz": {
      "Weight": 20
    }
  },
  {
    "scorm": {
      "Weight": 20
    }
  },
  {
    "forum": {
      "Weight": 20
    }
  }
];
