import React, { useEffect, useState } from "react";
import { EmptyView } from "./SelectedQuestion.style";
import { useMyContext } from "../../../contexts/QuestionBanksContext";
import Essay from "../../selectedQuestionBanks/Essay";
import Matching from "../../selectedQuestionBanks/Matching";
import MultipleChoice from "../../selectedQuestionBanks/MultipleChoice";
import TrueFalse from "../../selectedQuestionBanks/TrueFalse";
import ShortAnswer from "../../selectedQuestionBanks/ShortAnswer";
import Numerical from "../../selectedQuestionBanks/Numerical";
import { Typography } from "../../../elements/fonts/Fonts";
import { useRecoilValue } from "recoil";
import { CourseColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

const SelectedQuestion = () => {
  const { t } = useTranslation();
  const { currentView, setCurrentView, savedQuestions } = useMyContext();
  const [questionToShow, setQuestionToShow] = useState<any>("");

  // handle cancel to remove the question selected
  const handleCancel = () => {
    // remove the state from context
    setCurrentView("");
    // remove the showed question in the right side
    setQuestionToShow("");
  };

  // // useEffect to clg the data and handleCancel when savedQuestions from ContextAPI change
  // useEffect(() => {
  //   console.log("savedQuestions from parent***", savedQuestions);
  //   const existingQuestions = JSON.parse(
  //     localStorage.getItem("savedQuestions") || "[]"
  //   );

  //   // Filter out duplicate questions based on their IDs
  //   const newQuestions = savedQuestions.filter((newQuestion) => {
  //     // Check if the ID of the new question already exists in the existing questions
  //     return !existingQuestions.some(
  //       (existingQuestion: any) => existingQuestion.id === newQuestion.id
  //     );
  //   });

  //   // Merge existing questions with new questions
  //   const updatedQuestions = [...existingQuestions, ...newQuestions];
  //   localStorage.setItem("savedQuestions", JSON.stringify(updatedQuestions));
  //   handleCancel();
  // }, [savedQuestions]);

  // useEffect to switch between the components based on the currentView selected by contextAPI
  useEffect(() => {
    switch (currentView) {
      case "ESSAY":
        setQuestionToShow(<Essay handleCancel={handleCancel} />);
        break;
      case "MATCH":
        setQuestionToShow(<Matching handleCancel={handleCancel} />);
        break;
      case "MCQ":
        setQuestionToShow(<MultipleChoice handleCancel={handleCancel} />);
        break;
      case "TF":
        setQuestionToShow(<TrueFalse handleCancel={handleCancel} />);
        break;
      case "SHORT_ANSWER":
        setQuestionToShow(<ShortAnswer handleCancel={handleCancel} />);
        break;
      case "NUMERIC":
        setQuestionToShow(<Numerical handleCancel={handleCancel} />);
        break;
    }
  }, [currentView]);
  const { ClassGradientBox } = useRecoilValue(CourseColorSelector);
  return (
    <>
      {currentView === "" ? (
        <EmptyView style={{ background: ClassGradientBox }}>
          <Typography text={t("question bank.Pick a question type from the choices listed in the left-hand box")} />
        </EmptyView>
      ) : (
        <>{questionToShow}</>
      )}
    </>
  );
};

export default SelectedQuestion;
