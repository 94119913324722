import styled from "styled-components";
import { breakpoints, theme } from "../../theme";

import CheckIcon from "../../assets/icons/Group2199.svg";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

interface SeletectOptinToogleProps {
  borderRadius?: string; // Define the borderRadius prop
  contentDisplay?: string;
  correct?: boolean | string;
  bgColor?: string;
  contentColor?: string;
}
export const ButtonContent = styled.button`
  /* margin-top: 20px;
margin-bottom:50px; */
  width: 8.8125rem;
  height: 2.125rem;
  font-size: 14px;
  border: 1px solid #5dd3b3;
  background-color: #5dd3b3;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5rem;
  cursor: pointer;
  margin-bottom: 5px;
  font-family: "Primary font-medium";
`;
export const CustomCheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 1.25rem;
`;
export const CheckBox = styled.input<StyleThemeInterface>`
  width: 1.2rem;
  height: 1.2rem;
  background: ${(props) =>
    props.bgcolor ||
    "linear-gradient(180deg, #2d3741 0%, #576371 100%),linear-gradient(0deg, #ffffff, #ffffff)"};

  appearance: none;
  border: 1px solid ${theme.dark.primary_text_color};

  border-radius: 50%;
  transition: background-color 0.3s ease;
  cursor: pointer;
  &:checked {
    background-image: url(${CheckIcon});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background: linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%);
    border: 1px solid #5dd3b3;
  }
`;
export const Overlay = styled.div<{ isvisible: string }>`
  display: ${(props) => (props.isvisible ? "block" : "none")};
  opacity: ${(props) => (props.isvisible ? "1" : "0")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  transition: opacity 500ms;
  overflow: auto;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
`;
export const BackgroundOverlay = styled.div<StyleThemeInterface>`
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  min-height: 100%;
  background: ${(props) => props.bgcolor || "rgba(0, 0, 0, 0.4)"};
`;
export const ButtonContainer = styled.div`
  display: flex;
  &:dir(ltr) {
    justify-content: right;
  }
  &:dir(rtl) {
    justify-content: left;
  }
  align-items: center;
`;
export const PopupContainer = styled.div`
  margin: auto auto;
  width: max-content;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const PopupContentContainer = styled.div<StyleThemeInterface>`
  min-width: 17.4375rem;
  width: 30vw;
  min-height: fit-content;
  /* width: 25vw; */

  background: ${(props) => props.bgcolor || "#384450"};
  &:dir(ltr) {
    border-radius: 0rem 0.625rem 0.625rem 0rem;
    border-left: 4px solid #5dd3b3;
  }
  &:dir(rtl) {
    border-radius: 0.625rem 0rem 0rem 0.625rem;
    border-right: 4px solid #5dd3b3;
  }

  padding: 1.88rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  position: relative;
`;
export const CloseIconWrapper = styled.div`
  &:dir(ltr) {
    text-align: right;
  }
  &:dir(rtl) {
    text-align: left;
  }
  cursor: pointer;
  & > :hover {
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
`;
export const CheckboxLabel = styled.label<SeletectOptinToogleProps>`
  display: flex;
  /* align-items: center; */
  cursor: pointer;
  &::before {
    content: "";
    width: 1em;
    min-width: 1em;
    max-width: 1em;
    height: 1em;
    min-height: 1em;
    max-height: 1em;
    border: 0.05em solid #fff;
    border-radius: ${(props) => props.borderRadius || ".15rem"};
    margin-right: 0.5em;
    background: ${(props) =>
      props.bgColor || "linear-gradient(to bottom, #2F353D, #545F6D)"};
    @media (max-width: ${breakpoints.tablets}) {
      margin-top: 0.2rem;
      margin-right: 0.6rem;
    }
  }
  &:hover:before {
    background-color: #35e07d;
    border: 0.05em solid #5dd3b3;
    background: linear-gradient(
      to bottom,
      #5dd3b3,
      ${(props) => props.bgColor || "#2f3b40"}
    );
    /*   background-color:red; */
  }
`;
export const SeletectOptinToogle = styled.input<SeletectOptinToogleProps>`
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  border: 1px solid #fff;
  /* background-color: aqua; */
  /* color: black; */
  transition: background-color 0.3s ease;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  &:hover + ${CheckboxLabel}::before {
    background: linear-gradient(
      to bottom,
      #5dd3b3,
      ${(props) => props.bgColor || "#2f3b40"}
    );
  }
  &:checked + ${CheckboxLabel}::before {
    background: ${({ correct, bgColor }) =>
      correct === "true"
        ? bgColor ||
          "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%), linear-gradient(0deg, #5dd3b3, #5dd3b3)"
        : correct === "false"
        ? "#D85D66"
        : bgColor ||
          "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%), linear-gradient(0deg, #5dd3b3, #5dd3b3)"};
    border: 1px solid
      ${({ correct }) =>
        correct === "true"
          ? "#5dd3b3"
          : correct === "false"
          ? "#D85D66"
          : "#5dd3b3"};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ correct, contentColor }) =>
      correct === "true"
        ? contentColor || "#fff"
        : correct === "false"
        ? "#D85D66"
        : contentColor || "#fff"};
    content: "\\2713";
  }
  :focus + ${CheckboxLabel}::before {
    background: linear-gradient(
      to bottom,
      #5dd3b3,
      ${(props) => props.bgColor || "#2f3b40"}
    );
    border: 0.05em solid #5dd3b3;
  }
`;

export const RadiosDisplays = styled.label`
  display: grid;
  grid-template-columns: auto auto; /* Creates two columns per row */
  gap: 1rem; /* Optional: Adds space between the grid items */
`;
