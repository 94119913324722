import styled from "styled-components";

export const StudentLandingTableLayout =styled.div`
/* border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%)); */
width: 100%;
min-height:3rem;
display:flex;
flex-direction:column;
border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
gap:1rem;
padding:1rem;
`;

export const StudentLandingTableCols =styled.div`
/* border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%)); */
width: 100%;
min-height:3rem;
display:flex;
flex-direction:column;
gap:1rem;

`;

export const StudentLandingTableLayoutRow =styled.div`
/* border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%)); */
width: 100%;
justify-content:space-between;
display:flex;
/* width:100%; */
/* background-color:black; */
gap:0.62rem;

`;
export const StudentLandingTableColsTitle =styled.div`
display:flex;
flex:1;
color:#5DD3B3;
gap:0.62rem;
/* background:aqua; */
`;
export const StudentLandingCell =styled.div`
/* background:red; */
display:flex;
flex:1;
padding:0.2rem 0;
&:last-child{
  justify-content:flex-end;
  flex:0.8;

}

`;

export const  StudentLandingSeparatedLine =styled.div`
height: 0.0625rem;
background: #576371;
width: 90%;
margin:auto;
`;