import { toArabicDigits } from "../../utils/formatDayArabic";

export const translateTitleOrName = (item: any, t: any) => {
  let name = "", action = "", title = "";

  const translateAction = (action: string) => {
    switch (action) {
        case "Added":
            return t("notification.Added");
        case "Update":
            return t("notification.Update");
        case "Delete":
            return t("notification.Delete");
        default:
            return action;
        }
    };

  // createSubmit
  if (item.type === "createSubmit") {
    const [nameAndAction, titlePart] = item.TitleOrName.split(":").map( (str: string) => str.trim() );
    [name, action] = nameAndAction.split("_").map((str: string) => str.trim());
    title = titlePart;
    action = t(`notification.${action}`);
    return `${name} ${action}: ${t(`notification.${title}`)}`;

    // missingSubmit or createGrade
  } else if (item.type === "missingSubmit" || item.type === "createGrade") {
    [action, title] = item.TitleOrName.split(":").map((str: string) => str.trim() );
    action = t(`notification.${action}`);
    return `${action}: ${title}`;

    // missingAttendance
  } else if (item.type === "missingAttendance") {
    const [firstPart, secondPart] = item.TitleOrName.split(":").map( (str: string) => str.trim() );
    const [codePart, lastPart] = secondPart .split(",") .map((str: string) => str.trim());
    return `${t(`notification.${firstPart}`)} ${codePart}. ${t( `notification.${lastPart}` )}`;

    // announcement
  } else if (item.type === "announcement") {
    [action, title] = item.TitleOrName.split(": ").map((str: string) => str.trim() );
    action = translateAction(action);
    return `${action}: ${t(`notification.${title}`)}`;

    // Activities and resources
  } else {
    [action, title] = item.TitleOrName.split(":").map((str: string) => str.trim() );
    action = translateAction(action);
  }
  return `${action}: ${title}`;
};

export const translateTextDisplays = (item: any, t: any) => {
    let action = "", title = "";

    // missingSubmit
    if (item.type === "missingSubmit") {
    [action, title] = item.TextDisplays.split(" :").map((str: string) => str.trim());
    return `${t(`notification.${action}`)}: ${toArabicDigits(title)}`;

    // createGrade
    } else if (item.type === "createGrade") {
    return `${t(`notification.${item.TextDisplays}`)}`;

    // announcement
    } else if (item.type === "announcement" || item.type === "createSubmit") {
    return `${item.TextDisplays}`;

    // Activities and resources
    } else {
    if (item.TextDisplays === "") {
      return "";
    } else {
    [action, title] = item.TextDisplays.split(": ").map((str: string) => str.trim());
    return `${t(`notification.${action}`)}: ${toArabicDigits(title)}`;
    }
  }
}
