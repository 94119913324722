import React, { useEffect, useRef, useState } from "react";
import { Module } from "../../../CourseTimeline/utils/CourseResourcesInterface";
import {
  BackToModules,
  DashboardResourcesHolder,
  HeaderTitles,
  ModuleHolder,
  ModulesHolder,
  ResourcesCardsHolder,
  ResourcesDivHolder,
  ResourcesHeaderDiv,
  SearchDiv,
  ViewAllT,
  ResourceTypeTitleHolder,
  ResourcesHoler,
  ResourcesMainDiv,
} from "./ResourcesPerModule.style";
// import { ResourcesSliderSettings } from "../resourceCard/ResourcesSliderSettings";
import Empty from "../Empty";
import { Typography } from "../../../../elements/fonts/Fonts";
import { SvgBackArrow, SvgEmpty, SvgViewAll } from "../../../../elements/Icons";
import { ResourceSlider } from "../resourceCardsHolder/ResourceCardsHolder";
import { extractAllResources } from "../ExtractAllResources";
import { dashboardResourcesProps } from "../../dashboardContentInterfaces";
import SearchElement from "../../../../elements/searchElement/SearchElement";
import SearchWithFilter from "../../../../elements/searchWithFilter/SearchWithFilter";
import FilterPopUp from "../filterPopup/FilterPopUp";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  ChatsColorSelector,
  ColorSelector,
  courseBannerColorSelector,
} from "../../../../recoil/ThemeSelectors";
import { useStateContext } from "../../../../contexts/ContextProvider";
interface TranslatedTitles {
  [key: string]: string;
}
export default function ResourcesPerModule({
  courseId,
  Contentdata,
}: dashboardResourcesProps) {
  const { t } = useTranslation();
  const language = localStorage.getItem("language");
  const [data, setData] = useState<Module[]>([]);
  const [sorteddata, setSortedData] = useState<Module[]>([]);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState<string | null>(null);
  const [documentResourcesE, setDocumentResources] = useState<any[]>([]);
  const [fileResourcesE, setFileResources] = useState<any[]>([]);
  const [linkResourcesE, setLinkResources] = useState<any[]>([]);
  const [htmlResourcesE, sethtmlResources] = useState<any[]>([]);
  const [videoResourcesE, setVideoResources] = useState<any[]>([]);
  const [pictureResourcesE, setPictureResources] = useState<any[]>([]);
  const [linkTreeResourcesE, setLinkTreeResources] = useState<any[]>([]);
  const [folderResourcesE, setFolderResources] = useState<any[]>([]);
  const [allResources, setAllResources] = useState<boolean>(false);
  const [activeModule, setActiveModule] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showFilterPopUp, setShowFilterPopup] = useState<boolean>(false);
  const [filterTitles, setFilterTitles] = useState<any[]>([]);
  const [existingResourceTitles, setExistingResourceTitles] = useState<any[]>(
    []
  );
  useEffect(() => {
    setData(Contentdata);
    setAllResources(false);
    // setSortedData([]);
    // setLoading(true);
    // setError(null);
    // setExistingResourceTitles([]);
    setDocumentResources([]);
    setFileResources([]);
    setLinkResources([]);
    sethtmlResources([]);
    setVideoResources([]);
    setPictureResources([]);
    setLinkTreeResources([]);
    setFolderResources([]);
    setActiveModule(null);
    // setActiveModule(0);
    handleModuleClick(0);
  }, [courseId, language]);

  // Set the default value of the filtered Title Array as the existingResourceTitles
  useEffect(() => {
    setFilterTitles(existingResourceTitles);
  }, [existingResourceTitles, language]);
  const componentRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    if (componentRef.current) {
      componentRef.current.scrollTop = 0;
    }
  };
  const handleonClickFilter = (titles: any[]) => {
    setFilterTitles(titles);
    // console.log("titles", titles);
  };
  const handleModuleClick = (index: any) => {
    scrollToTop();
    setActiveModule(index);
    // setActiveTopic(index);
    const activeResourcesModule: Module[] = [];
    activeResourcesModule.push(Contentdata[index]);
    if (Contentdata[index]) {
      const {
        documentResources,
        linkResources,
        htmlResources,
        videoResources,
        pictureResources,
        linkTreeResources,
        folderResources,
        fileResources,
      } = extractAllResources(activeResourcesModule);
      setDocumentResources(documentResources);
      setFileResources(fileResources);
      setLinkResources(linkResources);
      sethtmlResources(htmlResources);
      setVideoResources(videoResources);
      setPictureResources(pictureResources);
      setLinkTreeResources(linkTreeResources);
      setFolderResources(folderResources);
      const existingResourceTitlesPerModule = [
        { resources: documentResources, title: t("document.Document") },
        { resources: fileResources, title: t("file.File") },
        { resources: linkResources, title: t("link.link") },
        { resources: htmlResources, title: t("html.Html") },
        { resources: linkTreeResources, title: t("link tree.Link Tree") },
        { resources: pictureResources, title: t("Image.Image") },
        { resources: videoResources, title: t("video.video") },
        { resources: folderResources, title: t("folder.Folder") },
      ]
        .filter((resourceGroup) => resourceGroup.resources.length > 0)
        .map((resourceGroup) => resourceGroup.title);
      setExistingResourceTitles(existingResourceTitlesPerModule);
    }
  };

  const handleViewAllClick = () => {
    scrollToTop();
    setAllResources(true);
    // setActiveModule(null);
    const {
      documentResources,
      linkResources,
      htmlResources,
      videoResources,
      pictureResources,
      linkTreeResources,
      folderResources,
      fileResources,
    } = extractAllResources(data);
    setDocumentResources(documentResources);
    setFileResources(fileResources);
    setLinkResources(linkResources);
    sethtmlResources(htmlResources);
    setVideoResources(videoResources);
    setPictureResources(pictureResources);
    setLinkTreeResources(linkTreeResources);
    setFolderResources(folderResources);
    const existingResourceTitlesPerModule = [
      { resources: documentResources, title: t("document.Document") },
      { resources: fileResources, title: t("file.File") },
      { resources: linkResources, title: t("link.link") },
      { resources: htmlResources, title: t("html.Html") },
      { resources: linkTreeResources, title: t("link tree.Link Tree") },
      { resources: pictureResources, title: t("Image.Image") },
      { resources: videoResources, title: t("video.video") },
      { resources: folderResources, title: t("folder.Folder") },
    ]
      .filter((resourceGroup) => resourceGroup.resources.length > 0)
      .map((resourceGroup) => resourceGroup.title);
    setExistingResourceTitles(existingResourceTitlesPerModule);
  };
  const handleBackToAll = () => {
    setAllResources(false);
    if (activeModule !== null) {
      handleModuleClick(activeModule);
    } else {
      setDocumentResources([]);
      setFileResources([]);
      setLinkResources([]);
      sethtmlResources([]);
      setVideoResources([]);
      setPictureResources([]);
      setLinkTreeResources([]);
      setFolderResources([]);
    }
  };

  // Close The filter popup
  const handleClosePopup = () => {
    setShowFilterPopup(false);
  };

  // List Of The Ressources
  const ResourcesT: any[] = [
    t("file.File"),
    t("link tree.Link Tree"),
    t("video.video"),
    t("folder.Folder"),
    t("link.link"),
    t("html.Html"),
    t("Image.Image"),
    t("document.Document"),
  ];

  const translatedTitles: TranslatedTitles = {
    File: t("file.File"),
    Link_Tree: t("link tree.Link Tree"),
    Video: t("video.video"),
    Folder: t("folder.Folder"),
    Link: t("link.link"),
    html: t("html.Html"),
    Image: t("Image.Image"),
    Document: t("document.Document"),
  };
  const { InfoSelectTextColor, FriendMessageBoxBG_Color, theme } =
    useRecoilValue(ChatsColorSelector);
  const { courseTitleBgColor } = useRecoilValue(courseBannerColorSelector);
  const { bghelp } = useRecoilValue(ColorSelector);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const handleScroll = (e: { deltaY: number; preventDefault: () => void }) => {
    const scrollContainer = containerRef.current;
    if (!scrollContainer) return; // Early return if scrollContainer is null

    const scrollAmount = 14.7001 * 16; // 18rem = 18 * 16px

    if (e.deltaY > 0) {
      // Scrolling down
      scrollContainer.scrollBy({
        top: scrollAmount,
        behavior: "smooth",
      });
    } else {
      // Scrolling up
      scrollContainer.scrollBy({
        top: -scrollAmount,
        behavior: "smooth",
      });
    }

    e.preventDefault(); // Prevent the default scrolling behavior
  };
  useEffect(() => {
    const scrollContainer = containerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("wheel", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("wheel", handleScroll);
      }
    };
  }, [containerRef]);

  return (
    <>
      <DashboardResourcesHolder allResources={allResources}>
        {/* {!loading ? ( */}
        <>
          {data.length > 0 ? (
            <ResourcesMainDiv allResources={allResources}>
              <ModulesHolder
                allResources={allResources}
                BorderColor={bghelp}
              // style={{ background: "red" }}
              >
                {data.map((module, index) => (
                  <ModuleHolder
                    activeModule={index === activeModule}
                    key={index}
                    onClick={() => handleModuleClick(index)}
                  >
                    <Typography
                      text={`${module.moduleTitle}`}
                      fontSize="0.875rem"
                      color={
                        index === activeModule ? "#fff" : InfoSelectTextColor
                      }
                    />
                  </ModuleHolder>
                ))}
              </ModulesHolder>
              <ResourcesDivHolder allResources={allResources}>
                <ResourcesHeaderDiv allResources={allResources}>
                  {allResources && (
                    <BackToModules
                      key={String(allResources)}
                      onClick={handleBackToAll}
                    >
                      <SvgBackArrow />
                      <Typography text={t("dashboard.All Resources")} />
                    </BackToModules>
                  )}
                  <SearchDiv>
                    <SearchWithFilter
                      placeholder={t("dashboard.Search")}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onClick={() => {
                        setShowFilterPopup(true);
                      }}
                    />
                  </SearchDiv>
                </ResourcesHeaderDiv>

                <HeaderTitles>
                  {!allResources && (
                    <>
                      {activeModule !== null && (
                        <div style={{ width: "100%" }}>
                          <Typography
                            text={`${data[activeModule].moduleTitle}`}
                            fontSize="1rem"
                          />
                        </div>
                      )}

                      {/* {!allResources && ( */}
                      <ViewAllT onClick={() => handleViewAllClick()}>
                        <Typography
                          fontSize="0.85rem"
                          text={t("dashboard.view all")}
                          color="#5dd3b3"
                        />
                      </ViewAllT>
                    </>
                  )}
                </HeaderTitles>
                <ResourcesHoler
                  ref={containerRef}
                  allResources={allResources}
                  onWheel={handleScroll}
                // style={{ height: "30rem" }}
                >
                  {[
                    {
                      resources: documentResourcesE,
                      title: t("document.Document"),
                    },
                    { resources: fileResourcesE, title: t("file.File") },
                    { resources: linkResourcesE, title: t("link.link") },
                    { resources: htmlResourcesE, title: t("html.Html") },
                    {
                      resources: linkTreeResourcesE,
                      title: t("link tree.Link Tree"),
                    },
                    { resources: pictureResourcesE, title: t("Image.Image") },
                    { resources: videoResourcesE, title: t("video.video") },
                    { resources: folderResourcesE, title: t("folder.Folder") },
                  ].some(
                    (resourceGroup) => resourceGroup.resources.length > 0
                  ) ? (
                    [
                      {
                        resources: documentResourcesE,
                        title: t("document.Document"),
                      },
                      { resources: fileResourcesE, title: t("file.File") },
                      { resources: linkResourcesE, title: t("link.link") },
                      { resources: htmlResourcesE, title: t("html.Html") },
                      {
                        resources: linkTreeResourcesE,
                        title: t("link tree.Link Tree"),
                      },
                      { resources: pictureResourcesE, title: t("Image.Image") },
                      { resources: videoResourcesE, title: t("video.video") },
                      {
                        resources: folderResourcesE,
                        title: t("folder.Folder"),
                      },
                    ]
                      .filter((resourceGroup) =>
                        filterTitles.includes(resourceGroup.title)
                      )
                      .map(
                        (resourceGroup) =>
                          resourceGroup.resources.length > 0 && (
                            <ResourcesCardsHolder
                              key={resourceGroup.title}
                            // style={{ background: FriendMessageBoxBG_Color }}
                            >
                              <ResourceTypeTitleHolder
                                allResources={allResources}
                                style={{
                                  background: allResources
                                    ? "none"
                                    : `${theme === "light"
                                      ? "rgba(0.667, 0.694, 0.725, 0.1)"
                                      : "linear-gradient(90deg, #415160 0%, #293440 107.88%)"
                                    }`,
                                }}
                              // bgColor={FriendMessageBoxBG_Color}
                              >
                                <Typography
                                  text={resourceGroup.title}
                                  fontSize="0.875rem"
                                />
                              </ResourceTypeTitleHolder>
                              <ResourceSlider
                                allResources={allResources}
                                resources={resourceGroup.resources}
                                title={resourceGroup.title}
                                background={allResources ? "true" : "false"}
                                courseId={courseId}
                                searchTerm={searchTerm}
                              />
                            </ResourcesCardsHolder>
                          )
                      )
                  ) : (
                    <>
                      {activeModule !== null && (
                        <Empty
                          text={`${t("dashboard.No Resources Available in")} ${data[activeModule].moduleTitle
                            }`}
                        />
                      )}
                    </>
                  )}
                </ResourcesHoler>
                {!allResources && activeModule === null && (
                  <Empty text="Please choose a module from the left to check available resources..." />
                )}
              </ResourcesDivHolder>
            </ResourcesMainDiv>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Empty text={t("dashboard.No Resources Yet")} />
            </div>
          )}
        </>
      </DashboardResourcesHolder>
      {showFilterPopUp && existingResourceTitles && (
        <FilterPopUp
          ResourcesT={ResourcesT}
          filterTitles={filterTitles}
          existingResourceTitles={existingResourceTitles}
          onClosePopup={handleClosePopup}
          onClickFilter={handleonClickFilter}
          type={t("view all.Filter Your Resources")}
        />
      )}
    </>
  );
}
