import styled from 'styled-components';
import { StyleThemeInterface } from '../../interfaces/StyleThemeInterface';

export const BodyContainer = styled.div<StyleThemeInterface>`
border-radius: 0.625rem;
background:${props =>props.bgcolor || "linear-gradient(180deg, #415160 0%, #293440 100%)"};

box-shadow:${props =>props.boxshadow || "0px 4px 20px 0px rgba(0, 0, 0, 0.25)"};
display: flex;
min-height: 37.75rem;
min-width:30rem;
flex:1.3 !important;

@media (max-width: 768px) {
    height: auto;
    width:auto;
  } 
`;


export const TextWrap = styled.div`

display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
width:100%;
 @media (max-width: 760px) {
    height: auto;
    width:auto;
   
  } 
`;

