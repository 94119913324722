import React, { FC, useState, useEffect, useContext } from "react";
import GraderStatistics from "../../components/GraderStatistics/main/GraderStatistics";
import { PageHeader } from "../../components/pageHeader/PageHeader";
import GarderNavMenu from "../../components/GraderNavMenu/main/GarderNavMenu";
import PdfViewer from "../../components/GraderTextEditor/main/PdfViewer";
import { Students } from "./ForumGraderInterface";
import { get } from "../../utils/AxiosRequests";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Student from "../../components/GraderStatistics/Student/Student";
import { AssignmentQuestions } from "../../components/GraderTextEditor/PdfViewerDataInterface";
import { MyContextProvider } from "../../contexts/quizGraderContext";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import { ClassContext } from "../dashboard/UnstructuredContext";
import { Typography } from "../../elements/fonts/Fonts";
import { SvgBackArrow } from "../../elements/Icons";
import { LoadingComp } from "../../components/LoadingComp/LoadingComp";
import { ResourceFrom } from "../courseView/content/Content";
import { useTranslation } from "react-i18next";
import { setFips } from "crypto";
const ForumGraderView: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const forumId = params.id;
  const classId = params.classId;
  const [showQuiz, setShowQuiz] = useState<boolean>(true);
  const [showNavIcon, setShowNavIcon] = useState<boolean>(true);
  const { userSelectedIndex, setUserSelectedIndex } = useContext(ClassContext);

  interface GradeData {
    AssignmentSubmissionId: number;
    grade: number;
    comment: string | null;
    submissionID: number;
    name: string;
  }
  const [questions, setQuestions] = useState<AssignmentQuestions[]>([
    {
      grade: null,
      question:
        "Lorem Ipsum is simply dummy, Lorem Ipsum is simply a dummy text",
      title: "Question 1",
    },
    {
      grade: null,
      question: "Hassan Fawaz",
      title: "Question 2",
    },
    {
      grade: null,
      question:
        "Lorem Ipsum is simply dummy, Lorem Ipsum is simply a dummy text",
      title: "Question 3",
    },
    {
      grade: null,
      question:
        "Lorem Ipsum is simply dummy, Lorem Ipsum is simply a dummy text",
      title: "Question 4",
    },
    {
      grade: null,
      question:
        "Lorem Ipsum is simply dummy, Lorem Ipsum is simply a dummy text",
      title: "Question 5",
    },
  ]);

  const [submission, setSubmission] = useState<any[]>([]);
  const [allAssignment, setAllAssignment] = useState<any>({});
  const [isGraded, setIsGraded] = useState<boolean>(false);
  const [allPosts, setAllPosts] = useState<any[]>([]);
  const [students, setStudents] = useState<any[]>([]);
  const [enrollements, setEnrollements] = useState<number>(0);
  const [gradedCount, setGradedCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [usingRubric, setUsingRubric] = useState<boolean>(false);
  const [rubricId, setRubricId] = useState<number>(0);
  const tenantName = localStorage.getItem("tenant");
  const [studentIndex, setStudentIndex] = useState<number>(
    userSelectedIndex || 0
  );
  const { user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const { t } = useTranslation();

  const fetchParticipantData = async () => {
    try {
      const ParticipantData = await get(
        `participant/class/${classId}/${tenantName}`
      );

      // Filter participants based on role
      if (ParticipantData.data.length > 0) {
        const students = ParticipantData.data.filter(
          (participant: any) => participant.role === "Student"
        );
        setEnrollements(students.length);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStudentsData = async () => {
    try {
      const forumData = await get(`forum/grades/${forumId}/${tenantName}`);
      const posts = forumData.data.Posts;

      // Set submissions and students data
      setSubmission(posts);
      setStudents(posts);
      const submissionsArray: any[] = [];

      // Loop through each post
      posts.forEach((post: any) => {
        // Extract submissions from each post
        const { submissions } = post;
        submissions.forEach((submission: any) => {
          submission["posterName"] = post.posterName;
        });

        // Push submissions into the submissionsArray
        submissionsArray.push(...submissions);
      });
      setAllPosts(submissionsArray);

      setTotalSubmissions(posts.length);

      // Set all assignment data
      setAllAssignment(forumData.data.forumActivitiesData);
      setIsGraded(forumData.data.forumActivitiesData.isGraded);
      if (
        forumData &&
        forumData.data &&
        forumData.data.forumActivitiesData &&
        forumData.data.forumActivitiesData.rubricId &&
        forumData.data.forumActivitiesData.rubricId !== null &&
        forumData.data.forumActivitiesData.rubricId !== ""
      ) {
        setUsingRubric(true);
        setRubricId(forumData.data.forumActivitiesData.rubricId);
      }

      // Count graded posts
      const countGraded = posts.filter(
        (post: any) => post.grade && post.grade.length > 0
      ).length;
      setGradedCount(countGraded);
      setLoading(false);

    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getStudentsData();
    fetchParticipantData();
  }, [tenantName]);

  useEffect(() => {
    const gradedSubmissions = students.filter(
      (submission: any) => submission.grade.length > 0
    );
    // Calculate the average grade
    const sumOfGrades = gradedSubmissions.reduce(
      (acc: number, submission: any) => acc + submission.grade[0].grade,
      0
    );
    const avgGrade =
      gradedSubmissions.length > 0 && !usingRubric
        ? (sumOfGrades / (gradedSubmissions.length * allAssignment.maxGrade)) *
        100
        : gradedSubmissions.length > 0 && usingRubric
          ? (sumOfGrades / (gradedSubmissions.length * 100)) * 100
          : 0;
    const avgGradeValue = !isNaN(avgGrade) ? Math.floor(avgGrade) : 0;
    setAvarage(avgGradeValue);
  }, [allAssignment, students]);
  // Assuming tenantName might change and triggers fetching students

  // console.log("Students array from student component in grader :" , students)

  // const [submitted, setSubmitted] = useState<number>(student[0].submitted);
  const [totalSubmissions, setTotalSubmissions] = useState<number>();
  const [avarage, setAvarage] = useState<string | number>(0);

  const [showSubmitedTime, setShowSubmitedTime] = useState<boolean>(true);
  const [showLate, setShowLate] = useState<boolean>(false);
  // const [submissions, setSubmissions] = useState<number>(student.length);
  const handleQuizView = () => {
    setShowQuiz(!showQuiz);
    setShowLate(!showLate);
    setShowNavIcon(!showNavIcon);
  };

  const navigateBack = () => {
    navigate(-1);
  };
  const [resourceR, setResourceR] = useState<ResourceFrom>();

  const language = localStorage.getItem("language");
  let { state } = useLocation();
  useEffect(() => {
    if (state) {
      const selectedResource: ResourceFrom = state.activity;
      setResourceR(selectedResource);
    }
  }, [state]);
  return (
    <>
      {loading && <LoadingComp />}
      <MyContextProvider>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "8.5%",
            justifyContent: "space-between",
            gap: "1.25rem",
          }}
        >
          <Link
            to={`/courseView/content/${classId}`}
          >
            {" "}
            <div
              // onClick={navigateBack}
              style={{
                marginTop: ".2rem",
                cursor: "pointer",
                transform: `${language === "ar" ? "rotate(180deg)" : ""}`,
              }}
            >
              <SvgBackArrow />
            </div>
          </Link>
          <Typography
            text={t("forum.Forum")}
            fontSize="1.75rem"
            fontWeight="400"
            lineHeight="normal"
          />
        </div>
        <GraderStatistics
          // setSubmitted={setSubmitted}
          // setGraded={setGraded}
          //setAvarage={setAvarage}
          totalSubmissions={totalSubmissions || 0}
          enrollements={enrollements}
          // submitted={submitted}
          avarage={avarage}
          student={submission}
          students={students}
          studentIndex={studentIndex}
          setStudentIndex={setStudentIndex}
          graded={gradedCount}
          allAssignment={allAssignment}
          forum={true}
        />
        <GarderNavMenu
          allAssignment={allAssignment}
          studentIndex={studentIndex}
          student={submission}
          handleQuizView={handleQuizView}
          forum={true}
          allPosts={allPosts}
        />
        <PdfViewer
          student={submission}
          studentIndex={studentIndex}
          showQuiz={showQuiz}
          showSubmitedTime={showSubmitedTime}
          setShowSubmitedTime={setShowSubmitedTime}
          showLate={showLate}
          showNavIcon={showNavIcon}
          allAssignment={allAssignment}
          forum={true}
          questions={questions}
          fetchData={getStudentsData}
          resourceR={resourceR}
          usingRubric={usingRubric}
          rubricId={rubricId}
          isAssignmentGraded={isGraded}
        // updateSubmittedByInstructor={updateSubmittedByInstructor}
        />
      </MyContextProvider>
    </>
  );
};
export default ForumGraderView;
