import React, { useState } from "react";
import {
  MainHolder,
  InputHolder,
  SearchIconHolder,
} from "./ReusableInput.style";
import { FC } from "react";
import { InputInterface } from "./reusableInputInterface";
import { SvgSearchInput } from "../Icons";
import { LightTypography } from "../fonts/Fonts";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

const ReusableInput: FC<InputInterface> = ({
  placeHolder,
  inputWidth,
  inputHeight,
  inputBorderRadius,
  inputBorder,
  padingLeft,
  display,
  onInputChange,
  value,
  right,
  left,
  holderWidth,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    onInputChange(newValue);
  };
  const { inputBorderColor, themeTextColor, theme } =
    useRecoilValue(ColorSelector);
  return (
    <MainHolder inputwidth={inputWidth || "100%"}>
      <InputHolder
        placeholder={placeHolder || t("course view.Announcement Title")}
        inputheight={inputHeight || "3.5rem"}
        inputborderradius={inputBorderRadius || "0.94rem"}
        inputborder={inputBorder || `1px solid ${inputBorderColor}`}
        padingleft={padingLeft || "1.19rem"}
        onChange={handleInputChange}
        value={value}
        holderWidth={holderWidth || "100%"}
        color={themeTextColor}
      />
      <SearchIconHolder
        display={display || "none"}
        right={right || "1.25rem"}
        left={left || "none"}
      >
        <div style={{ color: theme === "light" ? "#AAB1B9" : "#FFFFFF90" }}>
          <SvgSearchInput />
        </div>
      </SearchIconHolder>
    </MainHolder>
  );
};

export default ReusableInput;
