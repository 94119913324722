import styled from "styled-components";

export const MenuTeacherBox =styled.div<{bgColor?:string, color?: string}>`
display: flex;
width: fit-content;
border-radius: 0.3125rem;
/* background: #1F2731; */
background:${props =>props.bgColor || "#1F2731"};
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
position: absolute;
padding:0.65rem;
top: 2rem;
right: 2.5rem;
gap:0.62rem;
align-items:center;
cursor: pointer;
color:${props =>props.color};
&:hover{
  background-color: #5CD1B1;

}
`;

export const MenuStudentsBox =styled.div<{bgColor?:string, color?: string}>`
display: flex;
flex-direction:column;

border-radius: 0.3125rem;
/* background: #1F2731; */
background:${props =>props.bgColor || "#1F2731"};
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
position: absolute;
z-index:2;
top: 2rem;
right: 2.5rem;
/* gap:0.62rem; */
color:${props =>props.color};
`;

export const MenuStudentsRow =styled.div`
display: flex;

gap:0.62rem;
align-items:center;
cursor: pointer;
transition: background-color ease-in-out  0.3s;
padding:0.65rem;
/* align-items:center; */

&:first-child{
  border-radius: 0.3125rem 0.3125rem 0 0;
}

&:last-child{
  border-radius:  0 0 0.3125rem 0.3125rem;
}

&:hover{
  background-color: #5CD1B1

}


`;