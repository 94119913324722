import React, { FC } from "react";
import { QuizContainer } from "./SurveyQuiz.style";
import QuestionNumber from "../QuestionNumber/QuestionNumber";
import { AssignmentQuizProps } from "../SurveyQuizInterface";
import SubmittedQuestions from "../SubmittedQuestions/SubmittedQuestions";
import QuestionComment from "../QuestionComment/QuestionComment";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
const AssignmentQuiz: FC<AssignmentQuizProps> = ({
  questions,
  questionIndex,
  setQuestionIndex,
  submission,
  studentIndex,
  handleQuestionIndexChange,
}) => {
  const { bghelp, theme } = useRecoilValue(ColorSelector);

  return (
    <QuizContainer
      bordercolor={bghelp}
      boxshadow={
        theme === "light"
          ? "0px 4px 14px 0px rgba(192, 220, 214, 0.32)"
          : "-4px 0px 20px 0px rgba(0, 0, 0, 0.25)"
      }
    >
      <QuestionNumber
        questions={questions}
        setQuestionIndex={setQuestionIndex}
      />
      <SubmittedQuestions
        studentIndex={studentIndex}
        submission={submission}
        questions={questions}
        questionIndex={questionIndex}
      />
      <QuestionComment
        handleQuestionIndexChange={handleQuestionIndexChange}
        studentIndex={studentIndex}
        submission={submission}
        questions={questions}
        questionIndex={questionIndex}
      />
    </QuizContainer>
  );
};

export default AssignmentQuiz;
