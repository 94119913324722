import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Container,
  FiltersButtons,
  FiltersCont,
  LoaderContainer,
  NoData,
  PopupTitle,
  QuestionDiv,
  SearchContainer,
  SelectCont,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  TopContent,
} from "./QuestionBanksContent.style";
import { SvgAdd, SvgEyeIcon, SvgFAQ, SvgPlus } from "../../elements/Icons";
import { FlexBetween, FlexDiv } from "../../globalStyles";
import { LightTypography, Typography } from "../../elements/fonts/Fonts";
import SearchWithFilter from "../../elements/searchWithFilter/SearchWithFilter";
import SelectInput from "../../elements/SelectInput/SelectInput";
import { FilterBy, FilterData } from "./QuestionBanksContent.data";
import RenderQuestionType from "./QuestionsTypes";
import { useNavigate, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { ReactComponent as SvgNoResultIcon } from "../../assets/icons/Group 3182.svg";
import { ReactComponent as MultipleIcon } from "../../assets/icons/multiple.svg";
import { ReactComponent as ShortAnswer } from "../../assets/icons/share_chain_5.svg";
import { ReactComponent as Numerical } from "../../assets/icons/dice_6.svg";
import { ReactComponent as Essay } from "../../assets/icons/paper_1_text.svg";
import { ReactComponent as Matching } from "../../assets/icons/Group1.svg";
import { ReactComponent as TrueFalse } from "../../assets/icons/Group-1157.svg";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  courseBannerColorSelector,
} from "../../recoil/ThemeSelectors";
import SelectDropDown from "../../elements/selectDropDown/SelectDropDown";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import Loader from "../loader/Loader";

interface QuestionBanksContentProps {
  setShowQuestionBanksPopup?: React.Dispatch<React.SetStateAction<boolean>>;
  questions: any[];
  index: number;
  partIndex: number;
  setShowQuest?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditQuest?: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuestionBanksContent = ({
  setShowQuestionBanksPopup,
  questions,
  index,
  partIndex,
  setShowQuest,
  setEditQuest,
}: QuestionBanksContentProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const tenantName = localStorage.getItem("tenant");
  const { user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");

  const [questionBanksByClass, setQuestionBanksByClass] = useState<any[]>([]);
  const [questionBanksByUser, setQuestionBanksByUser] = useState<any[]>([]);
  const [allQuestionBanks, setAllQuestionBanks] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [selectedFilterBy, setSelectedFilterBy] =
    useState<string>("filteredByClass");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchQuestionBanksByClass = async () => {
    try {
      const response = await axios.get(
        `https://0ddea1gj55.execute-api.eu-west-1.amazonaws.com/dev/question/bank/get/${id}/${tenantName}`
      );
      setQuestionBanksByClass(response.data.data);
      setAllQuestionBanks(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchQuestionBanksByUser = async () => {
    try {
      const response = await axios.get(
        `https://0ddea1gj55.execute-api.eu-west-1.amazonaws.com/dev/question/bank/getBySubId/${subId}/${tenantName}?provider=${provider}`
      );
      setQuestionBanksByUser(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchQuestionBanksByClass();
    fetchQuestionBanksByUser();
  }, []);

  useEffect(() => {
    if (selectedFilterBy === "filteredByClass") {
      setAllQuestionBanks(questionBanksByClass);
    } else {
      setAllQuestionBanks(questionBanksByUser);
    }
  }, [selectedFilterBy]);

  // Filter question banks based on search query and selected filters
  const filteredQuestionBanks = allQuestionBanks.filter((question) => {
    return (
      question.question.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (selectedFilters.length === 0 ||
        selectedFilters.includes(question.contentType))
    );
  });

  const handleFilterBy = (value: string) => {
    setSelectedFilterBy(value);
  };

  // Update the searchQuery state with the new value from the input field
  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value); // Extract the value from the event and update the state
  };

  // Toggle the selected filter
  const toggleFilter = (filter: string) => {
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(selectedFilters.filter((f) => f !== filter)); // Remove the filter if already selected
    } else {
      setSelectedFilters([...selectedFilters, filter]); // Add the filter if not selected
    }
  };

  // Function to handle clicking on the eye icon
  const handleEyeIconClick = (questionToEdit: any) => {
    // Navigate to the QuestionBanks page while passing contentType as state data
    navigate(`/questionBanks/${id}`, {
      state: { questionToEdit, editMode: true },
    });
  };

  // Function to handle clicking on the plus icon
  const handlePlusIconClick = (question: any) => {
    const addedQuestion = [...questions];
    // Interface For the contentTypeActions Object of each question
    interface ContentTypeAction {
      icon: JSX.Element;
      shuffle?: boolean;
      pushChoices: any[];
    }
    // Create an object for each type of question
    const contentTypeActions: { [key: string]: ContentTypeAction } = {
      ESSAY: {
        icon: <Essay />,
        pushChoices: [{ name: "", isChecked: question.isAttachmentSelected }],
      },
      MATCH: {
        icon: <Matching />,
        pushChoices: [],
      },
      MCQ: {
        icon: <MultipleIcon />,
        shuffle: question.isShuffled,
        pushChoices: [],
      },
      TF: {
        icon: <TrueFalse />,
        pushChoices: [{ name: "", isChecked: question.answer }],
      },
      NUMERIC: {
        icon: <Numerical />,
        pushChoices: [
          {
            name: String(question.correctAnswer),
            errorMargin: String(question.errorMargin),
          },
        ],
      },
      SHORT_ANSWER: {
        icon: <ShortAnswer />,
        pushChoices: [],
      },
    };

    // Formatted choices array
    if (question.contentType === "MATCH" || question.contentType === "MCQ") {
      contentTypeActions[question.contentType].pushChoices =
        question.choices.map((choice: any, index: any) => ({
          index,
          ...(question.contentType === "MATCH"
            ? { left: choice.choice, right: choice.match }
            : {
                name: choice.choice,
                isChecked: choice.isTrue,
                percentage: String(choice.percentage) || "",
              }),
        }));
    } else if (question.contentType === "SHORT_ANSWER") {
      contentTypeActions[question.contentType].pushChoices =
        question.keywords.map((keyword: any) => ({ name: keyword }));
    }

    const addAction = (target: any, action: ContentTypeAction) => {
      target.id = question.id;
      target.question = question.question;
      target.feedRight = question.feedbackRight || "Your Answer is correct.";
      target.feedWrong = question.feedbackWrong || "Your Answer is Incorrect.";
      target.type = question.contentType;
      target.bank = true;
      target.icon = action.icon;
      if (action.shuffle !== undefined) target.shuffle = action.shuffle;
      target.choices.push(...action.pushChoices);
    };
    // Indicate if the target is a normal question or a composite question
    const target =
      addedQuestion[index]?.questType === "composed"
        ? addedQuestion[index].questPart[partIndex]
        : addedQuestion[index];

    if (addedQuestion.length > 0 && target && target.type === "") {
      const contentTypeAction = contentTypeActions[question.contentType];
      if (contentTypeAction) {
        addAction(target, contentTypeAction);
      }
    }
    // Close The Popup After Actions
    setShowQuestionBanksPopup && setShowQuestionBanksPopup(false);
    // setShowQuest && setShowQuest(true);
    setEditQuest && setEditQuest(true);
  };

  // function to remove <p> from questions after save
  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };
  const { courseTitleBgColor, theme } = useRecoilValue(
    courseBannerColorSelector
  );
  const { backgroundColor } = useRecoilValue(ColorSelector);

  return (
    <>
      {/* <PopupTitle>
        <FlexDiv gap="0.63rem">
          <SvgFAQ />
          <Typography text="Question Bank" fontSize="1.125rem" />
        </FlexDiv>
      </PopupTitle> */}
      <Container>
        <TopContent>
          <FlexBetween>
            <SearchContainer>
              <SearchWithFilter
                withFilter={false}
                placeholder={t("dashboard.Search")}
                onChange={handleSearchInputChange}
              />
            </SearchContainer>
            <FlexDiv
              gap="0.63rem"
              pointer="true"
              onClick={() =>
                navigate(`/questionBanks/${id}`, { state: { noData: false } })
              }
            >
              <SvgAdd color={theme === "dark" ? "#fff" : "#5DD3B3"} />
              <Typography
                text={t("question bank.Add Question")}
                fontSize="0.625rem"
                color={theme === "dark" ? "#fff" : "#5DD3B3"}
              />
            </FlexDiv>
          </FlexBetween>
          <FiltersCont>
            {FilterBy.map((type, index) => (
              <FiltersButtons
                key={index}
                onClick={() => handleFilterBy(type.value)}
                active={selectedFilterBy.includes(type.value)}
              >
                {t(type.type)}
              </FiltersButtons>
            ))}
          </FiltersCont>

          {/* <SelectCont> */}
          {/* <FlexBetween>
              <LightTypography
                // text={t("question bank.Choose The Category")}
                text={""}
                fontSize="0.875rem"
              />

            </FlexBetween> */}
          {/* <SelectDropDown
              onChange={handleFilterBy}
              placeholder="Filter By"
              width="100%"
              options={FilterBy}
              value={selectedFilterBy}
            /> */}
          {/* </SelectCont> */}

          <FiltersCont>
            {FilterData.map((type, index) => (
              <FiltersButtons
                key={index}
                onClick={() => toggleFilter(type.value)}
                active={selectedFilters.includes(type.value)}
              >
                {t(`quiz.${type.type}`)}
              </FiltersButtons>
            ))}
          </FiltersCont>
        </TopContent>

        <TableContainer>
          <TableHeader bgcolor={courseTitleBgColor} theme={theme}>
            <TableCell style={{ width: "25%" }}>
              <Typography text={t("quiz.Type")} />
            </TableCell>
            <TableCell style={{ width: "75%" }}>
              <Typography text={t("quiz.Question")} />
            </TableCell>
          </TableHeader>
          {isLoading ? (
            <LoaderContainer>
              <Loader text={t("general.loading")} />
            </LoaderContainer>
          ) : (
            <>
              {filteredQuestionBanks.length === 0 ? (
                <NoData>
                  <div style={{ marginLeft: "2rem" }}>
                    <SvgNoResultIcon width="7rem" height="7rem" />
                  </div>
                  <Typography
                    text={t("gradebook.no result found")}
                    fontSize="1.125rem"
                  />
                  <LightTypography
                    text={t("gradebook.Try again using another filter")}
                    fontSize="0.875rem"
                  />
                </NoData>
              ) : (
                <>
                  {filteredQuestionBanks.map((question, index) => (
                    <TableRow
                      key={index}
                      bgColor={
                        index % 2 === 0
                          ? "transparent"
                          : theme === "light"
                          ? "rgba(170, 177, 185, 0.1)"
                          : "rgba(45, 55, 65, 0.5)"
                      }
                    >
                      <TableCell style={{ width: "25%" }}>
                        {RenderQuestionType(question.contentType, t)}
                      </TableCell>
                      <TableCell style={{ width: "75%" }}>
                        <FlexBetween>
                          <QuestionDiv
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                decodeHtml(question.question)
                              ),
                            }}
                            style={{
                              fontSize: "0.875rem",
                              fontWeight: "400",
                              fontFamily: "Primary Font-Light",
                            }}
                          ></QuestionDiv>
                          <FlexDiv gap="1.25rem">
                            <SvgEyeIcon
                              color={theme === "light" ? "#5DD3B3" : ""}
                              onClick={() => handleEyeIconClick(question)}
                              style={{ cursor: "pointer" }}
                            />
                            <SvgPlus
                              color={theme === "light" ? "#5DD3B3" : ""}
                              onClick={() => handlePlusIconClick(question)}
                              style={{ cursor: "pointer" }}
                            />
                          </FlexDiv>
                        </FlexBetween>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </>
          )}
        </TableContainer>
      </Container>
    </>
  );
};

export default QuestionBanksContent;
