import React, { FC } from "react";
import { MediumTypography } from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import { MatchingContainer, MatchingInputs } from "./MatchingQuestion.style";
import { MatchinQuestionProps } from "./MatchingQuestionInterface";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete 1 copy.svg";
import { ErrorDiv } from "../../../elements/ErrorDiv/Errordiv.style";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

const MatchingQuestion: FC<MatchinQuestionProps> = ({
  questionError,
  choice,
  qindex,
  handleChoiceMatchingQuestion,
  showQuest,
  setDeleteChoice,
  setChoiceIndex,
  allChoices,
}) => {
  const leftError =
    (qindex === 0 || qindex === 1) && !!questionError && !choice.left;
  const rightError =
    (qindex === 0 || qindex === 1) && !!questionError && !choice.right;
  const { SecondaryTextColor, QuizCreator_ValidatesInput_bgColor, bghelp, readOnlyBgColor, inputBorderColor, themeTextColor,theme } =
    useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  return (
    <>
      <MatchingContainer>
        <MediumTypography
          text={`${qindex + 1}`}
          fontSize="0.875rem"
          color={themeTextColor}
          lineHeight="1.375rem"
        />
        <MatchingInputs
          hasError={leftError}
          id="left"
          value={choice.left}
          type="text"
          width="5rem"
          placeholder={t("quiz.Write your choice")}
          onChange={(e) => handleChoiceMatchingQuestion(qindex, e)}
          show={showQuest.toString()}
          readOnly={showQuest ? true : false}
          color={SecondaryTextColor}
          bgColor={readOnlyBgColor}
          borderColor={inputBorderColor}
        />
        <MatchingInputs
          hasError={rightError}
          id="right"
          value={choice.right}
          type="text"
          width="5rem"
          placeholder={t("quiz.Write the match")}
          onChange={(e) => handleChoiceMatchingQuestion(qindex, e)}
          show={showQuest.toString()}
          readOnly={showQuest ? true : false}
          color={SecondaryTextColor}
          bgColor={readOnlyBgColor}
          borderColor={inputBorderColor}
        />
        {allChoices.length > 2 ? (
          <DeleteIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setDeleteChoice(true);
              setChoiceIndex(qindex);
            }}
            color={theme === "light" ? "#D85D66" : "#fff"}
          />
        ) : (
          ""
        )}
      </MatchingContainer>
      {/* {(qindex === 1) && (leftError || rightError) && <ErrorDiv style={{ marginLeft: '2.75rem' }}>{questionError}</ErrorDiv>} */}
    </>
  );
};

export default MatchingQuestion;
