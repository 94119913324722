import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
  margin-bottom: 1.25rem;
`;

export const HeadPage = styled.div`
  width: 100%;
  display: flex;
  gap: 1.25rem;
  align-items: center;
`;

export const FlexDiv = styled.div<{ gap?: string }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : "0.5rem")};
`;

export const PostContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.19rem;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const Post = styled.div`
  width: 100%;
  min-height: fit-content;
  border-radius: 1.25rem;
  background: #384450;
  padding: 1.25rem;
  box-sizing: border-box;
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.31rem;
`;

export const ProfileContainer = styled.div<{ bgimage?: string }>`
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  background-image: url(${(props) => props.bgimage});
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const CourseImageContainer = styled.div<{
  bgimage?: string;
  height?: string;
  marginBottom?: string;
}>`
  width: 100%;
  height: ${(props) => props.height || "9.4375rem"};
  border-radius: 0.3rem;
  background-image: url(${(props) => props.bgimage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: lighten;
  margin-top: 0.63rem;
  margin-bottom: ${(props) => props.marginBottom || "0.63rem"};
`;
export const ExpandView = styled.div<{ bgimage?: string }>`
  width: 100%;
  height: fit-content;
  border-radius: 1.25rem;
  background: #384450;
  padding: 1.25rem;
`;

export const BtnsContainer = styled.div`
  display: flex;
  gap: 0.62rem;
  justify-content: flex-end;
  margin-top: 1.25rem;
`;
