import styled from "styled-components";

export const AnalyticScrollablePlaceLine= styled.div`
display: flex;
/* width: 100%; */
padding:0.75rem;
gap:1.60rem;
/* background-color:yellow !important; */
background: var(--Grey-Gradient, linear-gradient(90deg, #415160 0%, #293440 107.88%));
`;

export const AnalyticScrollablePlaceCell = styled.div<{ Opacity: string }>`
  display: flex;
  border-left: 1.2px solid #576371;
  gap: 3rem;
  padding: 0 1.6rem; /* Corrected padding value */
  opacity: ${(props) => props.Opacity || "1"}; /* Ensure Opacity is a string and provide a default value */
  width: fit-content;
  /* background-color: blue; */
  flex-wrap: nowrap;
  min-width:27rem;
  max-width:34rem;
  justify-content:space-between;
  align-items:center;
  &:first-child{
    border-left: none;
  }
`;

export const AnalyticsSurveyPiesContainer= styled.div`
display: flex;
width: 100%;
flex-direction: column;
border-radius: 1.25rem 0rem 0rem 1.25rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
padding:1.25rem 0;
gap:1.25rem;
`;


export const AnalyticsSurveyBackLeft= styled.div`
display: flex;
margin-left: 1.25rem
`;