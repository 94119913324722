import React from "react";
import { PageHeader } from "../../components/pageHeader/PageHeader";
import { CreatingListingPageCompo } from "../../components/CreatingListingPage/CreatingListingPageCompo";
import { ClassesInProgress } from "../../components/classesInProgress/ClassesInProgress";

const CreatingListingPage = ():JSX.Element => {
  return (
<>

<ClassesInProgress />
    </>
  )
}

export default CreatingListingPage