import React, { useContext, useEffect, useRef, useState } from "react";
import {
  SliderTabsWrapper,
  TabsBox,
  Tab,
  IconBannerWrapper,
  TypographyWrapper,
  DividLineHolder,
  DividLine,
  IconWrapper,
  RightDiv,
  LeftDiv,
  LeftIcon,
  RightIcon,
  CourseViewSidebar,
  CourseBannerAndSidebarContainer,
  SidebarPosition,
} from "./SliderTabs.syle";
import {
  SvgNextArrowCourseBannerIcon,
  SvgPrevArrowIcon,
  SvgSidebarIcon,
} from "../../elements/Icons";
import menuCourseData from "./courseBannerData";
import { Typography } from "../../elements/fonts/Fonts";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import useToggle from "../../hooks/useToggle";
import { Sidebar } from "../sidebar/main/Sidebar";
import { CourseCardData } from "../courseCard/CourseCardData";
import axios from "axios";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { get } from "../../utils/AxiosRequests";
import { useRecoilState, useRecoilValue } from "recoil";
import { courseBannerColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import useClickOutside from "../../hooks/useClickOutside";
import { WebsocketContext } from "../../contexts/chatContext";
import { RedCircle, RedCircle2 } from "../pageHeader/PageHeader.style";
import { adminRoleState, adminState } from "../../recoil/RecoilStates";
import { useTeamsClass } from "../../utils/ClassHasTeams";

interface ParticipantsInterface {
  profilePicture: string;
  username: string;
  email: string;
  role: string;
  sourceId: string;
}
interface UserData {
  sourceId: string;
  username: string;
  email: string;
  profilePicture: string;
  role: string;
}
const SliderTabs: React.FC = () => {
  const tabsBoxRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startTouchX, setStartTouchX] = useState(0);
  const [currentScrollLeft, setCurrentScrollLeft] = useState(0);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isAdmin, setIsAdmin] = useRecoilState(adminState);
  const [adminRole, setAdminRole] = useRecoilState(adminRoleState);

  const { courseBannerBgColor, courseBannerTextColor, boxShadow } =
    useRecoilValue(courseBannerColorSelector);

  const location = useLocation();
  // const [activeItem, setActiveItem] = useState<string | null>(null);
  const [activeItem, setActiveItem] = useState(menuCourseData[0].path);
  const { role, setRole, getUnvewedMessageCount, unviewedMessageCount, setMySourceId, getUnvewedAnnouncmentClassCount, unviewedAnnouncmentClassCount } = useContext(ClassContext);
  const { user, isLoading } = useAuth0();
  const urlClassId = useParams();
  const myEmail = user?.email;
  const tenantName = localStorage.getItem("tenant");
  const { value } = useContext(WebsocketContext);

  useEffect(() => {
    var data = JSON.parse(value);
    // console.log("Recieved Message---->",data)
    if (isAdmin === false)
      if (data && data.type && data.type === "announcement") {
        if (urlClassId.classId) {
          getUnvewedAnnouncmentClassCount(urlClassId.classId)
        }
      }
  }, [value]);

  useEffect(() => {
    if (isAdmin === false)
      if (urlClassId.classId) {
        getUnvewedMessageCount(urlClassId.classId)
        getUnvewedAnnouncmentClassCount(urlClassId.classId)
      }
  }, [urlClassId.classId])


  useEffect(() => {
    const parts = location.pathname.split("/");
    const newPath = parts.slice(0, 3).join("/");

    const matchingItem = menuCourseData.find((item) => item.path === newPath);

    if (matchingItem) {
      setActiveItem(matchingItem.path);
    }
  }, [location]);
  const handleItemClick = (url: string) => {
    setActiveItem(url);
    if (isAdmin === false)
      if (urlClassId.classId) {
        getUnvewedMessageCount(urlClassId.classId)
        getUnvewedAnnouncmentClassCount(urlClassId.classId)
      }
  };

  const handleIcons = (scrollVal: number) => {
    if (tabsBoxRef.current) {
      const maxScrollableWidth =
        tabsBoxRef.current.scrollWidth - tabsBoxRef.current.clientWidth;

      const leftIcon = tabsBoxRef.current.querySelector(".left-icon");
      const rightIcon = tabsBoxRef.current.querySelector(".right-icon");

      if (leftIcon instanceof HTMLElement) {
        leftIcon.style.display = scrollVal <= 0 ? "none" : "flex";
      }

      if (rightIcon instanceof HTMLElement) {
        rightIcon.style.display =
          maxScrollableWidth <= scrollVal ? "none" : "flex";
      }
    }
  };

  const handleIconClick = (direction: "left" | "right") => {
    if (tabsBoxRef.current) {
      const scrollWidth =
        tabsBoxRef.current.scrollLeft +
        (direction === "left" ? -10 * 16 : 10 * 16);
      tabsBoxRef.current.scrollLeft = scrollWidth;
      handleIcons(scrollWidth);
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setIsDragging(true);
    setStartTouchX(e.touches[0].clientX);
    setCurrentScrollLeft(tabsBoxRef.current?.scrollLeft || 0);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (isDragging && tabsBoxRef.current) {
      const movementX = e.touches[0].clientX - startTouchX;
      tabsBoxRef.current.scrollLeft = currentScrollLeft - movementX;
      handleIcons(tabsBoxRef.current.scrollLeft);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };
  const { isOpen: isSidebarVisible, toggle: toggleSidebar } = useToggle(false);

  // useEffect(() => {
  //   if (urlClassId && urlClassId !== classID) {
  //     setClassID(urlClassId);
  //     // console.log("The Class Id is: " + classID);
  //   }
  // }, [urlClassId, classID]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [participantData, setParticipantData] = useState<
    ParticipantsInterface[]
  >([]);
  const [notEnrolled, setNotEnrolled] = useState(false);
  const { t } = useTranslation();
  const classSourceId = useParams();
  const classId = classSourceId.classId;
  const { withTeams } = useTeamsClass(classId)

  const checkEmailExists = (
    emailToCheck: string,
    userData: UserData[]
  ): boolean => {
    const lowerCaseEmailToCheck = emailToCheck.toLowerCase();

    return userData.some((user) => {
      const lowerCaseUserEmail = user.email.toLowerCase();
      return lowerCaseUserEmail === lowerCaseEmailToCheck;
    });
  };
  const SideBarRef = useClickOutside(isSidebarVisible, toggleSidebar);
  useEffect(() => {
    if (location.pathname.includes("Gradebook")) {
      setActiveItem(menuCourseData[6].path);
    } else if (location.pathname.includes("about")) {
      setActiveItem(menuCourseData[0].path);
    }
  }, [location.pathname]);
  const navigate = useNavigate();
  const fetchUsersData = async () => {
    try {
      const response = await get(`participant/class/${urlClassId.classId}/${tenantName}`);
      const auditors = await axios.get(`https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/classAuditor/auditors/${tenantName}/${classId}`)
      const auditorFilteredData = auditors.data.data.filter(
        (user: any) => user.email.toLocaleLowerCase() === myEmail?.toLocaleLowerCase()
      );
      const filteredData = response.data.filter(
        (user: any) => user.email.toLocaleLowerCase() === myEmail?.toLocaleLowerCase()
      );
      if (filteredData.length > 0)
        setMySourceId(filteredData[0].sourceId);
      else if (auditorFilteredData.length > 0)
        setMySourceId(auditorFilteredData[0].sourceId);

    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {


    const fetchData = async (isAuditor?: any) => {
      const tenantName = localStorage.getItem("tenant");
      try {
        if (!isLoading) {
          const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
          const provider = auth0ProviderSplitter(user?.sub || "");
          if (isAdmin === true)
            setRole(adminRole)
          else {
            const isParentClass = await axios.get(`https://bo39qdpjrc.execute-api.eu-west-1.amazonaws.com/dev/class/merge/type/${urlClassId.classId}/${tenantName}`)
            if (isParentClass.data.data[0].isParent === true) {
              const ParticipantData = await get(
                `participant/class/${urlClassId.classId}/${tenantName}`
              );
              if (ParticipantData.data.length > 0) {
                const classRole = ParticipantData.data.find((item: { email: string | undefined; }) => item.email?.toLowerCase() === user?.email?.toLowerCase())
                if (classRole && classRole.role === "Teacher") {
                  setRole("teacher");
                  setAdminRole("teacher")
                }
                else {
                  navigate("/unAuth");
                }
              }

            }
            else {
              let response
              if (isAuditor && isAuditor === true) {
                setRole("auditor");
                setAdminRole("auditor")
              }
              else {
                response = await axios.get(
                  `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/enrollments/user/role/${tenantName}/${urlClassId.classId}/${subIdSplitted}?provider=${provider}`
                );
                setRole(response.data.data.role);
                setAdminRole(response.data.data.role)
              }
            }
          }

          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again.");
        setLoading(false);
      }
    };
    const fetchParticipantData = async () => {

      const tenantName = localStorage.getItem("tenant");
      try {
        const ParticipantData = await get(
          `participant/class/${urlClassId.classId}/${tenantName}`
        );
        const reauditors = await axios.get(`https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/classAuditor/auditors/${tenantName}/${classId}`)
        // Filter participants based on role
        if (ParticipantData.data.length > 0) {
          setParticipantData(ParticipantData.data);
          const email = user?.email || "";

          const enrolled = checkEmailExists(email, ParticipantData.data) || checkEmailExists(email, reauditors.data.data);
          setNotEnrolled(enrolled);
          if (!enrolled) navigate("/unAuth");
          else fetchData(checkEmailExists(email, reauditors.data.data));
        }
      } catch (error) {
        console.error(error);
      }
    };
    const getAdminState = async () => {
      try {
        const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
        const provider = auth0ProviderSplitter(user?.sub || "");
        const adminState = await axios.get(
          `https://9z6xeihi9l.execute-api.eu-west-1.amazonaws.com/dev/auth/isAdmin/${tenantName}/${subIdSplitted}?provider=${provider}`
        );
        localStorage.setItem('adminState', adminState.data.data);
        setIsAdmin(adminState.data.data);
        if (adminState.data.data === true) {
          fetchData()
        }
        else {
          fetchParticipantData();
        }
        if (adminState.data.data === false)
          fetchUsersData()
      } catch (error) {
        console.log(error);
      }
    }

    getAdminState()
  }, [isLoading, role]);

  // filter teams item
  const filteredMenuCourseData = withTeams
    ? menuCourseData
    : menuCourseData.filter((item) => item.title !== "Teams");

  // filter grades item (auditor)
  const finalFilteredMenuCourseData =
    role === "auditor"
      ? filteredMenuCourseData.filter(
        (item) => item.title !== "dashboard.Grades"
      )
      : isAdmin === true
        ? filteredMenuCourseData.filter(
          (item) =>
            item.path !== "/courseview/announcements" &&
            item.path !== "/courseView/CHATS"
        )
        : filteredMenuCourseData;

  return (
    <CourseBannerAndSidebarContainer>
      <CourseViewSidebar
        ref={SideBarRef}
        bgminiiconcolor={courseBannerBgColor}
        boxshadow={boxShadow}
        onClick={toggleSidebar}
      >
        <SvgSidebarIcon color={courseBannerTextColor} />
      </CourseViewSidebar>

      <SidebarPosition isopen={(isSidebarVisible).toString()}>
        {isSidebarVisible && <Sidebar sidebarMini={false} />}
      </SidebarPosition>

      <SliderTabsWrapper
        bgminiiconcolor={courseBannerBgColor}
        boxshadow={boxShadow}
      >
        <TabsBox
          ref={tabsBoxRef}
          className={isDragging ? "dragging" : ""}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <IconWrapper className="icon left-icon">
            <LeftIcon id="left-icon" onClick={() => handleIconClick("left")}>
              <SvgPrevArrowIcon />
            </LeftIcon>
          </IconWrapper>
          {finalFilteredMenuCourseData &&
            finalFilteredMenuCourseData.map((item, index) => (
              <React.Fragment key={index}>
                {index === 0 && <LeftDiv />}
                <Link to={`${item.path}/${urlClassId.classId}`}>
                  <Tab
                    onClick={() => {
                      setActiveTabIndex(index);
                      handleItemClick(item.path);
                    }}
                    isactive={(item.path === activeItem).toString()}
                    iconortextcolor={courseBannerTextColor}
                  >
                    <IconBannerWrapper>
                      <item.icon />
                    </IconBannerWrapper>
                    <TypographyWrapper
                      className={item.path === activeItem ? "line-active" : ""}
                    >
                      <Typography
                        text={t(item.title).toUpperCase()}
                        fontSize="0.875rem"
                      />
                    </TypographyWrapper>
                    {isAdmin !== true &&
                      unviewedMessageCount > 0 &&
                      t(item.title).toUpperCase() === "CHATS" && (
                        <RedCircle>
                          <Typography
                            color="#fff"
                            fontSize="0.5rem"
                            text={unviewedMessageCount.toString()}
                          />
                        </RedCircle>
                      )}
                    {isAdmin !== true &&
                      unviewedAnnouncmentClassCount > 0 &&
                      t(item.title).toUpperCase() === "ANNOUNCEMENTS" && (
                        <RedCircle2>
                          <Typography
                            color="#fff"
                            fontSize="0.5rem"
                            text={unviewedAnnouncmentClassCount.toString()}
                          />
                        </RedCircle2>
                      )}
                  </Tab>
                </Link>
                {index !== finalFilteredMenuCourseData.length - 1 && (
                  <DividLineHolder>
                    <DividLine />
                  </DividLineHolder>
                )}
                {index === finalFilteredMenuCourseData.length - 1 && (
                  <RightDiv />
                )}
              </React.Fragment>
            ))}
          <IconWrapper className="icon right-icon">
            <RightIcon id="right-icon" onClick={() => handleIconClick("right")}>
              <SvgNextArrowCourseBannerIcon />
            </RightIcon>
          </IconWrapper>
        </TabsBox>
      </SliderTabsWrapper>
    </CourseBannerAndSidebarContainer>
  );
};

export default SliderTabs;
