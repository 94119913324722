import React from "react";
import {
  AnalyticShadowHeaderFourDisplaysContainer,
  AnalyticShadowHeaderFourDisplaysLine,
  AnalyticShadowHeaderFourDisplaysTextColumn,
  AnalyticShadowHeaderFourDisplaysTextOpacity,
} from "./AnalyticShadowHeaderFourDisplays.styled";
import {
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
interface ScormHeaderTitleInterface {
  Responses?: any;
  CompletedAttempts?: any;
  AverageTimesSpent?: any;
  AverageScore?: any;
  Graded?: boolean;
}
const ScormHeaderTitle = ({
  Responses,
  CompletedAttempts,
  AverageTimesSpent,
  AverageScore,
  Graded,
}: ScormHeaderTitleInterface) => {
  return (
    <AnalyticShadowHeaderFourDisplaysContainer>
      <AnalyticShadowHeaderFourDisplaysTextColumn>
        <Typography text="Responses" fontSize="1.125rem" />
        <AnalyticShadowHeaderFourDisplaysTextOpacity>
          <TypographyRegularDescription
            text={Responses?.toString() || ""}
            fontSize="0.875rem"
          />
        </AnalyticShadowHeaderFourDisplaysTextOpacity>
      </AnalyticShadowHeaderFourDisplaysTextColumn>
      <AnalyticShadowHeaderFourDisplaysLine />

      <AnalyticShadowHeaderFourDisplaysTextColumn>
        <Typography text="Completed Attempts" fontSize="1.125rem" />
        <AnalyticShadowHeaderFourDisplaysTextOpacity>
          <TypographyRegularDescription
            text={CompletedAttempts?.toString() || ""}
            fontSize="0.875rem"
          />
        </AnalyticShadowHeaderFourDisplaysTextOpacity>
      </AnalyticShadowHeaderFourDisplaysTextColumn>
      <AnalyticShadowHeaderFourDisplaysLine />

      <AnalyticShadowHeaderFourDisplaysTextColumn>
        <Typography text="Average Times Spent" fontSize="1.125rem" />
        <AnalyticShadowHeaderFourDisplaysTextOpacity>
          <TypographyRegularDescription
            text={AverageTimesSpent?.toString() || ""}
            fontSize="0.875rem"
          />
        </AnalyticShadowHeaderFourDisplaysTextOpacity>
      </AnalyticShadowHeaderFourDisplaysTextColumn>
      <AnalyticShadowHeaderFourDisplaysLine />
      {Graded && (
        <AnalyticShadowHeaderFourDisplaysTextColumn>
          <Typography text="Average Score" fontSize="1.125rem" />
          <AnalyticShadowHeaderFourDisplaysTextOpacity>
            <TypographyRegularDescription
              text={AverageScore?.toString() || ""}
              fontSize="0.875rem"
            />
          </AnalyticShadowHeaderFourDisplaysTextOpacity>
        </AnalyticShadowHeaderFourDisplaysTextColumn>
      )}
      {Graded && <AnalyticShadowHeaderFourDisplaysLine />}

      <AnalyticShadowHeaderFourDisplaysTextColumn>
        <Typography text="Graded" fontSize="1.125rem" />
        <AnalyticShadowHeaderFourDisplaysTextOpacity>
          <TypographyRegularDescription
            text={Graded ? "Yes" : "No"}
            fontSize="0.875rem"
          />
        </AnalyticShadowHeaderFourDisplaysTextOpacity>
      </AnalyticShadowHeaderFourDisplaysTextColumn>
    </AnalyticShadowHeaderFourDisplaysContainer>
  );
};

export default ScormHeaderTitle;
