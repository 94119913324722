import React, { useEffect, useState } from "react";
import {
  SelectedStudentsContainer,
  InstructorMessageBoxInput,
  InstructorMessageBoxTitleRow,
  InstructorMessageButton,
  InstructorMessageTextArea,
} from "./InstructorViewCompletion.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import { SvgClose, SvgShare_Chain_29 } from "../../elements/Icons";
import BlueButton from "../../elements/blueButton/BlueButton";
import { FlexAppContainer } from "../../App.style";
import StyledSelect from "../../elements/StyledSelect/StyledSelect";
import { SelectedStudentsInterface } from "../InstructorAnalyticsUsersView/InstructorAnalyticsUsersViewTable/InstructorAnalyticsUsersViewTable";
interface StudentMessageProps {
  CloseClick?: () => void;
  ReserverName?: any;
  SendMessage?: () => void;
  SubjectValue?: string;
  setSubjectValue?: any;
  MessageValue?: string;
  setMessageValue?: any;
  ErrorMessageValue?: string;
  setErrorMessageValue?: any;
  FileStorage?: FileList | null;
  setFileStorage?: React.Dispatch<React.SetStateAction<FileList | null>>;
  OneSelected?: boolean;
  SelectedData?: SelectedStudentsInterface[];
}
const StudentMessage = ({
  CloseClick,
  ReserverName,
  SendMessage,
  SubjectValue,
  setSubjectValue,
  MessageValue,
  setMessageValue,
  ErrorMessageValue,
  setErrorMessageValue,
  FileStorage,
  setFileStorage,
  OneSelected = true,
  SelectedData,
}: StudentMessageProps) => {
  useEffect(() => {
    if (ErrorMessageValue?.trim() === "") {
      if (setErrorMessageValue) {
        // Check if setErrorMessageValue is defined
        setErrorMessageValue("");
      }
    }
  }, [MessageValue, ErrorMessageValue]);
  // Function to handle the selection of a file, upload it to S3, and set relevant state variables.

  const handleSelectFile = async (files: FileList | null) => {
    setFileStorage && setFileStorage(files);
  };

  return (
    <FlexAppContainer column={true} gap="1.25rem">
      <InstructorMessageBoxTitleRow>
        <Typography text="New Feedback" fontSize="1.125rem" />
        <SvgClose
          onClick={CloseClick}
          fill="#5DD3B3"
          style={{ cursor: "pointer", color: "#5DD3B3" }}
        />
      </InstructorMessageBoxTitleRow>
      <TypographyRegularDescription text="Recipient" fontSize="1rem" />
      {OneSelected ? (
        <InstructorMessageBoxInput
          type="text"
          value={ReserverName}
          disabled={true}
        />
      ) : (
        <SelectedStudentsContainer>
          {SelectedData &&
            SelectedData.map((info, index) => (
              <div
                style={{ display: "flex", alignItems: "center" }}
                key={info.id}
              >
                <TypographyRegularDescription
                  fontSize="0.75rem"
                  text={info.FullName}
                />
                {index < SelectedData.length - 1 &&
                index !== SelectedData.length - 2
                  ? ","
                  : ""}
                {index === SelectedData.length - 2 ? (
                  <div style={{ margin: "0px 8px" }}>
                    <TypographyRegularDescription
                      fontSize="0.75rem"
                      text={" and   "}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
        </SelectedStudentsContainer>
      )}
      {/* don't delete its must be used in future */}
      {/* <InstructorMessageBoxInput
        type="text"
        placeholder="Add a Subject"
        value={SubjectValue}
        onChange={(event) => setSubjectValue(event.target.value)}
      /> */}
      <MediumTypography text="Feedback" />
      <InstructorMessageTextArea
        RedColor={!!ErrorMessageValue && ErrorMessageValue.trim() !== ""}
        placeholder={
          ErrorMessageValue ? ErrorMessageValue : "Type Message here"
        }
        value={MessageValue}
        onChange={(event) => setMessageValue(event.target.value)}
      />

      {/* don't delete its must be used in future */}
      {/* <StyledSelect
        onChange={handleSelectFile}
        title={"Add Attachment"}
        icon={SvgShare_Chain_29}
        acceptedFileTypes=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .odt, .ods, .odp, .txt, .rtf, .csv"
        StopHoverAction={true}
        ShowBorder={false}
      /> */}
      <InstructorMessageButton>
        <BlueButton
          text="Send"
          Width="7.625rem"
          Height="2.125rem"
          BackColor="#5DD3B3"
          OnClick={SendMessage}
        />
      </InstructorMessageButton>
    </FlexAppContainer>
  );
};

export default StudentMessage;
