import React, { useEffect, useRef, useState } from "react";
import {
  Arrow,
  EmptySelectDivOptions,
  MainSelects,
  MultipleSelectContainer,
  Select,
  SelectDivOptions,
  StyledInput,
} from "./SelectMultiple.style";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { SvgPolygonDown } from "../Icons";
interface Option {
  id: string | number;
  label: string;
  value: string | number;
  name: string;
}

interface ObjectInputSelectInterface {
  options: Option[];
  value?: Option[];
  text?: string;
  placeholder?: string;
  disabled?: boolean;
  WidthSize?: string;
  borderColor?: string;
  onChange?: (id: string | number) => void;
  selected?: string;
}

const SelectMultiple = ({
  options = [],
  value = [],
  placeholder = "",
  onChange,
  selected,
}: ObjectInputSelectInterface) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const selectRef = useRef<HTMLDivElement | null>(null);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const { t } = useTranslation();
  const language = localStorage.getItem("language");

  useEffect(() => {
    // Filter options to exclude those that are already selected
    const filterSelectedOptions = () => {
      return options.filter(
        (option) =>
          !value.some((selectedOption) => selectedOption.id === option.id)
      );
    };
    setFilteredOptions(filterSelectedOptions());
  }, [options, value, language]);

  useEffect(() => {
    const foundedOption = options.find(
      (option) =>
        (option.id || option.value) ===
        (selected || (value && value[0] && value[0].id))
    );
    setInputValue(
      // foundedOption ? t(foundedOption.label || foundedOption.name) : ""
      ""
    );
  }, [selected, value, options, t]);

  const handleInputFocus = () => {
    setIsOpen(true);
  };

  const handleOptionClick = (option: Option) => {
    let updatedValues;

    updatedValues = value
      .map((v) => t(v.label || v.name))
      .includes(t(option.label || option.name))
      ? value.filter((v) => v.id !== option.id)
      : [...value, option];

    setInputValue(""); // Clear the search input after selection

    if (onChange) {
      onChange(option.id || option.value);
    }
  };

  const handleInputChange = (inputValue: string) => {
    setInputValue(inputValue);

    const filtered = options.filter((option) => {
      if (typeof (option.label || option.name) === "string") {
        return t(option.label).toLowerCase().includes(inputValue.toLowerCase());
      }
      return false;
    });

    setFilteredOptions(filtered);
    setSelectedOptionIndex(0);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowDown") {
      setSelectedOptionIndex((prevIndex) =>
        prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp") {
      setSelectedOptionIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : 0
      );
    } else if (e.key === "Enter") {
      const selectedOption = filteredOptions[selectedOptionIndex];
      if (selectedOption) {
        handleOptionClick(selectedOption);
      }
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const {
    inputBorderColor,
    SecondaryTextColor,
    textInputColorA,
    theme,
    bghelp,
    backgroundColor,
    bg_selectColor,
    boxShadow,
    OpacitiesTextColor,
    themeTextColor,
  } = useRecoilValue(ColorSelector);

  return (
    <MultipleSelectContainer
      ref={selectRef}
      bordercolor={bghelp}
      bgcolor={backgroundColor}
    >
      <Select onClick={() => setIsOpen(!isOpen)}>
        <StyledInput
          type="text"
          value={inputValue}
          placeholder={placeholder}
          onChange={(e) => handleInputChange(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          onFocus={handleInputFocus}
          onKeyDown={handleKeyDown}
          color={themeTextColor}
        />
        <Arrow>
          <SvgPolygonDown
            style={{
              transform: isOpen ? "rotate(180deg)" : "rotate(0)",
              transition: "transform 0.3s ease-in-out",
            }}
            color={SecondaryTextColor}
          />
        </Arrow>
      </Select>
      {isOpen && (
        <MainSelects
          theme={theme}
          boxshadow={boxShadow}
          bgcolor={bg_selectColor}
        >
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <SelectDivOptions
                key={option.id || index}
                onClick={() => handleOptionClick(option)}
                color={SecondaryTextColor}
                theme={theme}
              >
                {t(option.label)}
              </SelectDivOptions>
            ))
          ) : (
            <EmptySelectDivOptions color={SecondaryTextColor} theme={theme}>
              {t("dashboard.There is no data yet")}
            </EmptySelectDivOptions>
          )}
        </MainSelects>
      )}
    </MultipleSelectContainer>
  );
};

export default SelectMultiple;
