import React from 'react'
import { SvgNoResult } from '../../elements/Icons'
import { Typography } from '../../elements/fonts/Fonts'
import { t } from 'i18next'


const EmptyAllSessions = () => {
  return (
    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", }} >
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "20rem", textAlign: "center", }} >
        <SvgNoResult width={"10rem"} />
        <Typography text={t("course view.There is no data yet")} />
    </div>
</div>
  )
}

export default EmptyAllSessions