import React from "react";
import { AnalyticOneTitleHeaderContainer } from "./AnalyticShadowHeaderFourDisplays.styled";
import { Typography } from "../../elements/fonts/Fonts";
interface AnalyticOneTitleHeaderInterface {
  Title: string;
  onClick?: () => void;
}
const AnalyticOneTitleHeader = ({
  Title,
  onClick,
}: AnalyticOneTitleHeaderInterface) => {
  return (
    <AnalyticOneTitleHeaderContainer onClick={onClick}>
      <Typography text={Title} fontSize="1.125rem" />
    </AnalyticOneTitleHeaderContainer>
  );
};

export default AnalyticOneTitleHeader;
