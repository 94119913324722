// This function initializes the Objects States for the answer
export const returnInitialiseStates = (SurveyStudentViewData:any[]) => {
    const initialDescriptions: { [key: string]: string } = {};
    const initialStarRatings: { [key: string]: number } = {};
    const initialClassSelections: { [key: string]: string } = {};
    const initialLineValues: { [key: string]: string } = {};

    let allInstructorRepliesPresent = true; // Flag to check if instructor replies are present in all objects

    SurveyStudentViewData.forEach((survey) => {
      if (survey.questionParts && survey.questionParts.length > 0 && survey.questType === "COMPOSITE" ) {
        survey.questionParts.forEach((qVal:any) => {
          const key = `Question${survey.id}_Part${qVal.id}`;

          if (qVal.type === "star") {
            initialStarRatings[key] = 0;
          } else if (qVal.type === "text") {
            initialDescriptions[key] = "";
          } else if (qVal.type === "Radio") {
            initialClassSelections[key] = qVal.studentAnswer || "";
          } else if (qVal.type === "Likertscale") {
            initialLineValues[key] = "";
          }
        });
      }else{
        const key = `Question${survey.id}`;

        if (survey.type === "star") {
          initialStarRatings[key] = 0;
        } else if (survey.type === "text") {
          initialDescriptions[key] = "";
        } else if (survey.type === "Radio") {
          initialClassSelections[key] = survey.studentAnswer || "";
        } else if (survey.type === "Likertscale") {
          initialLineValues[key] = "";
        }
      }
    });
    return {
        initialStarRatings,
        initialDescriptions,
        initialClassSelections,
        initialLineValues
    }

    // Set ShowSave based on the flag
    // setShowSave(!allInstructorRepliesPresent);
}
// End of Function

// Find the Answer of student and return it
export const findStudentAnswer = (
  SurveyQuestionsId:number,
  studentAnswerArray:any[],
  key:string,
  classId:string,
  subIdSplitted:string,
  id:number,
  questionType:string,
  
) => {
    if(studentAnswerArray && studentAnswerArray.length > 0){
      const studentAnswer = studentAnswerArray.find(answer => answer.SurveyQuestionsId === SurveyQuestionsId);
      return studentAnswer ? studentAnswer.answer : [{choice : null}];
    }else  if(studentAnswerArray && studentAnswerArray.length === 0 ){
      // Fetch and set the timer from local storage if it exists
      const savedStudentAnswer = JSON.parse(localStorage.getItem('localStudentSurveyResponse') || '[]');
      const currentQuizAnswer = savedStudentAnswer.find((item: any) =>
        item.classSourceId === classId &&
        item.subId === subIdSplitted &&
        item.refId === id
      );

      if (currentQuizAnswer) {

      if(currentQuizAnswer && currentQuizAnswer.localStudentResponse && currentQuizAnswer.localStudentResponse[key] && currentQuizAnswer.localStudentResponse[key] !== null && currentQuizAnswer.localStudentResponse[key][0] !== null){
        if(questionType && questionType !== "MCQ" && questionType !== "MRQ"){
          return [
            {choice:currentQuizAnswer.localStudentResponse[key]}
          ]  
        }else {
          return(currentQuizAnswer.localStudentResponse[key])
        }
        
        
        
        }else{
          return [
            {choice : null}
          ];
        }
        
      } else {
        return [
          {choice : null}
        ];
      }
      }
    else{
      return [
        {choice : null}
      ];
    }
};
// End Of Function

// Fing the Comment that the instructor create it to the answer of the student
export const findCommentForAnswer = (SurveyQuestionsId:number,studentAnswerArray:any[]) => {
    if(studentAnswerArray && studentAnswerArray.length > 0){
      const studentAnswer = studentAnswerArray.find(answer => answer.SurveyQuestionsId === SurveyQuestionsId);
      // console.log("Comments : " , studentAnswer && studentAnswer.comment ? studentAnswer.comment.comment : "No Comment" ) 
      return studentAnswer && studentAnswer.comment ? studentAnswer.comment.comment : "No Comment"; 
    }else{
      return "No Comment";
    }
};
// End Of Student

// This Function is formating the answer data of the student before sending it to the backend
export const formatingAnswerData = (selectedChoices:any, descriptions:any, starRatings:any,classSelections:any,lineValues:any,surveyQuestionId:any,subIdSplitted:any,id:any,provider?:any,) => {
    // Accumulated responses object
    let accumulatedResponses: Record<string, any> = {};

    // Function to merge logs into accumulated responses
    const mergeLogs = (logs: any) => {
      for (const log of logs) {
        if (typeof log === "object" && !Array.isArray(log)) {
          accumulatedResponses = { ...accumulatedResponses, ...log };
        } else if (typeof log === "string") {
          const key = log;
          const value = logs[logs.indexOf(log) + 1];
          if (value !== undefined && typeof value !== "string") {
            accumulatedResponses[key] = value;
          }
        }
      }
    };

          mergeLogs([selectedChoices, descriptions, starRatings,classSelections,lineValues,surveyQuestionId]);

    // Sort keys in ascending order
    const sortedKeys = Object.keys(accumulatedResponses).sort((a, b) => {
      const keyA = a.toLowerCase();
      const keyB = b.toLowerCase();
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    // Create a new object with sorted keys
    const sortedResponses: Record<string, any> = {};
    sortedKeys.forEach((key) => {
      sortedResponses[key] = accumulatedResponses[key];
    });

            const transformObjectToArray = (responsesObject:any) => {
              const resultArray:any = [];
            
              Object.keys(responsesObject).forEach((key) => {
                if (key.startsWith('Question ') && key.endsWith('_ID')) {
                  const questionNumber = key.replace('Question ', '').replace('_ID', '');
                  const questionKey = `Question ${questionNumber}`;
                  const idKey = `${questionKey}_ID`;
                  const typeKey = `${questionKey}Type`;
            
                  // Check for the existence of the main keys for the composed question
                  if (idKey in responsesObject ) {
                    const question = responsesObject[questionKey];
                    const questionType = responsesObject[typeKey];
            
                    // Initialize file variable for the part if it exists and is an essay
                    let file = null;
                    const fileKey = `${questionKey}_File`;
                    if (fileKey in responsesObject) {
                      file = responsesObject[fileKey];
                    }
            
                    resultArray.push({
                      SurveyQuestionsId: responsesObject[idKey],
                      // type: questionType,
                      answer: question,
                      subId : subIdSplitted,
                      provider:provider,
                      surveyActivityId:id
                      // file: file, // Include file key if it's a composed question with an attachment for a part
                    });
            
                    // Handling additional parts if they exist for the composed question
                    const partKeys = Object.keys(responsesObject).filter(
                      (partKey) => partKey.startsWith(`${questionKey}_Part`)
                    );
            
                    partKeys.forEach((partKey) => {
                      const partTypeKey = `${partKey}Type`;
                      const partFileKey = `${partKey}_File`;
            
                      if (partTypeKey in responsesObject) {
                        const partType = responsesObject[partTypeKey];
                        let partFile = null;
            
                        if (partType === 'ESSAY' && partFileKey in responsesObject) {
                          partFile = responsesObject[partFileKey];
                        }
            
                        resultArray.push({
                          id: responsesObject[`${questionKey}_ID`],
                          type: partType,
                          question: responsesObject[partKey],
                          file: partFile, // Include file key for the part if it's an essay type
                        });
                      }
                    });
                  }
                }
              });
            
              return resultArray;
            };
            return transformObjectToArray(sortedResponses)
}
// End Of Function




// This is an unused Code for the composite Question, Do not delete it, maybe we will need it later.

// {question.questionParts &&
//     question.questionParts.length > 0 &&
//     question.type === "COMPOSITE" &&
//       question.questionParts.map((part, partIndex) => (
//         <div key={partIndex}>
//           {part.type === "RATE" ? (
//             <SurveyStarQuestion
//               PartTitle={part.title || ""}
//               text={part.question || ""}
//               onChange={(value: number) => {
//                 if (question.id && part.id) {
//                   handleStarSelection(value, index, partIndex);
//                 }
//               }}
//               showStarDisplay={true}
//               studentAnswer={part.studentAnswer} // Pass studentAnswer to SurveyStarQuestion
//               comment=""
//             />
//           ) : part.type === "BINARY" ? (
//             <SurveyRadoiButton
//               PartTitle={part.title || ""}
//               text={part.question || ""}
//               onChange={(value: string) => {
//                 if (question.id && part.id) {
//                   handleClassSelection(value, index, partIndex);
//                 }
//               }}
//               studentAnswer={part.studentAnswer}
//               comment=""
//             />
//           ) : part.type === "SCALE" ? (
//             <SurveyLikertScale
//               PartTitle={part.title || ""}
//               text={part.question || ""}
//               onChange={(value: string) => {
//                 if (question.id && part.id) {
//                   handleLineValueSelection(value, index, partIndex);
//                 }
//               }}
//               showText={true}
//               disabled={!!part.studentAnswer} // Disable if studentAnswer is present
//               studentAnswer={part.studentAnswer} // Pass the studentAnswer as a prop
//               comment=""
//             />
//           ) : part.type === "ESSAY" ? (
//             <>
//               <MediumTypography
//                 text={part.question || ""}
//                 fontSize="0.875rem"
//               />
//               {part.studentAnswer ? (
//                 <TextEditor
//                   onChange={(value: string) => {}}
//                   placeHolder="Write Your Answer"
//                   initialValue={part.studentAnswer}
//                   height={7}
//                   readonly={true}
//                   PartTitle={part.title || ""}
//                 />
//               ) : (
//                 <TextEditor
//                   onChange={(value: string) => {
//                     if (question.id && part.id) {
//                       handleDescriptionChange(
//                         value,
//                         index,
//                         partIndex
//                       );
//                     }
//                   }}
//                   placeHolder="Write Your Answer"
//                   PartTitle={part.title || ""}
//                   initialValue=""
//                   height={7}
//                 />
//               )}
//             </>
//           ) :
//           part.type === "MCQ" ? (
//             <>
//         <MediumTypography
//           text={part.title || ""}
//           fontSize="0.875rem"
//           color="#5DD3B3"
          
//         />
//         {
//           part.choices && part.choices.length > 0 && part.choices.map((Choice, choiceIndex) => (
//             <StudentCheckboxRowSurvey key={choiceIndex}>
//               <CheckBox
//                 type="radio"
//                 id={Choice.id && Choice.id.toString()}
//                 onChange={() => handleCheckboxChange(Choice, index, partIndex)}
//                 name={part.title}
//                 value={Choice.choice}
//                 checked={
//                   part.studentAnswer !== undefined &&
//                   part.studentAnswer.length > 0
//                     ? part.studentAnswer.some((choice: any) => choice.id === Choice.id)
//                     : selectedChoices &&
//                       index !== undefined &&
//                       partIndex !== undefined &&
//                       selectedChoices[
//                         `${question.title}_${part.title}`
//                       ]?.some((choice: any) => choice.id === Choice.id) ||
//                       selectedChoices[`${question.title}`]?.some((choice: any) => choice.id === Choice.id) ||
//                       false
//                 }
                
//               />
//               <TypographyRegularDescription text={`${Choice?.choice}`} fontSize='16px' lineHeight='22px' fontWeight='400' />
//             </StudentCheckboxRowSurvey>
//           ))
//         }
//       </>                 
//           )
//           :
//           part.type === "MRQ" ? (
//             <>
//         <MediumTypography
//           text={part.title || ""}
//           fontSize="0.875rem"
//           color="#5DD3B3"
          
//         />
//         {
//           part.choices && part.choices.length > 0 && part.choices.map((Choice, choiceIndex) => (
//             <div style={{marginTop:"1rem"}} key={choiceIndex}>
//               <SeletectOptinToogle
//                 type="checkbox"
//                 id={Choice.id && Choice.id.toString()}
//                 onChange={() => handleCheckboxChange(Choice, index, partIndex)}
//                 name={part.title}
//                 value={Choice.choice}
//                 checked={
//                   part.studentAnswer !== undefined &&
//                   part.studentAnswer.length > 0
//                     ? part.studentAnswer.some((choice: any) => choice.id === Choice.id)
//                     : selectedChoices &&
//                       index !== undefined &&
//                       partIndex !== undefined &&
//                       selectedChoices[
//                         `${question.title}_${part.title}`
//                       ]?.some((choice: any) => choice.id === Choice.id) ||
//                       selectedChoices[`${question.title}`]?.some((choice: any) => choice.id === Choice.id) ||
//                       false
//                 }
//               />
//               <CheckboxLabel htmlFor={Choice.id.toString()}>
//                 {Choice.choice}
//               </CheckboxLabel>
//             </div>
//           ))
//         }
//       </>
                       
//           )
//           :
//            (
//             <div
//               style={{
//                 width: "100%",
//                 height: "4rem",
//                 background: "aqua",
//               }}
//             ></div>
//           )}
//         </div>
//       ))
//       }