export const ClassData = [
    {
        id: "36908074-4315-45dd-bca5-676f1f11e658",
        className: "Marketing",
        credit: 15,
        modules: [
          {
            id:"1",
            name: "Module 1",
            progress: 90,
            activities: [
              {
                name: "Assignment 1",
                duration: 15,
                pages: 5,
                type: "assignment",
                isMandatory: true,
                status: "completed",
                completionDate: "12/20/2022",
              },
              {
                name: "Quiz 1",
                duration: 20,
                pages: 3,
                type: "quiz",
                isMandatory: true,
                status: "completed",
                completionDate: "12/20/2022",
              },
              {
                name: "Survey 1",
                duration: 60,
                pages: 6,
                type: "survey",
                isMandatory: false,
                status: "not started",
                completionDate: "",
              },
              {
                name: "Forum 1",
                duration: 60,
                pages: 2,
                type: "forum",
                isMandatory: true,
                status: "completed",
                completionDate: "12/20/2022",
              },

            ],
          },
          {
            id:"2",
            name: "Module 2",
            progress: 50,
            activities: [
              {
                name: "Assignment 2",
                duration: 15,
                pages: 5,
                type: "assignment",
                isMandatory: true,
                status: "completed",
                completionDate: "12/20/2022",
              },
              {
                name: "Quiz 2",
                duration: 20,
                pages: 3,
                type: "quiz",
                isMandatory: true,
                status: "not started",
                completionDate: "",
              },
              {
                name: "Survey 2",
                duration: 60,
                pages: 6,
                type: "survey",
                isMandatory: false,
                status: "not started",
                completionDate: "",
              },
              {
                name: "Forum 2",
                duration: 60,
                pages: 2,
                type: "forum",
                isMandatory: true,
                status: "completed",
                completionDate: "12/20/2022",
              },

            ],
          },
          {
            id:"3",
            name: "Module 3",
            progress: 25,
            activities: [
              {
                name: "Assignment 3",
                duration: 60,
                pages: 10,
                type: "assignment",
                isMandatory: true,
                status: "completed",
                completionDate: "12/20/2022",
              },
              {
                name: "Quiz 3",
                duration: 60,
                pages: 10,
                type: "quiz",
                isMandatory: true,
                status: "not started",
                completionDate: "",
              },
              {
                name: "Survey 3",
                duration: 60,
                pages: 6,
                type: "survey",
                isMandatory: false,
                status: "in progress",
                completionDate: "",
              },
              {
                name: "Forum 3",
                duration: 60,
                pages: 2,
                type: "forum",
                isMandatory: true,
                status: "not started",
                completionDate: "",
              },

            ],
          },
          {
            id:"4",
            name: "Module 4",
            progress: 100,
            activities: [
              {
                name: "Assignment 4",
                duration: 60,
                pages: 10,
                type: "assignment",
                isMandatory: true,
                status: "completed",
                completionDate: "12/20/2022",
              },
              {
                name: "Quiz 4",
                duration: 60,
                pages: 10,
                type: "quiz",
                isMandatory: true,
                status: "completed",
                completionDate: "12/20/2022",
              },
              {
                name: "Survey 4",
                duration: 60,
                pages: 6,
                type: "survey",
                isMandatory: false,
                status: "completed",
                completionDate: "12/20/2022",
              },
              {
                name: "Forum 4",
                duration: 60,
                pages: 2,
                type: "forum",
                isMandatory: true,
                status: "completed",
                completionDate: "12/20/2022",
              },

            ],
          },

        ],
      }
]