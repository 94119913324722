import styled from "styled-components";
import { theme } from "../../../theme";
export const MatchingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.63rem;
  padding: 0 1.5rem 0 1.5rem;
`;

export const MatchingInputs = styled.input<{
  min_width?: any;
  show?: any;
  hasError?: boolean;
  color?: string;
  bgColor?: string;
  borderColor?: string;
}>`
  /* width: ${(props) => (props.width ? props.width : "")}; */
  flex: 1;
  min-width: ${(props) => (props.min_width ? props.min_width : "")};
  height: 2.8125rem;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  border-color: ${(props) =>
    props.hasError
      ? "#D85D66"
      : props.show === "true"
      ? props.bgColor
      : props.borderColor};
  background: transparent;
  /* &:dir(ltr) {
    padding-left: 1.25rem;
  }
  &:dir(rtl) {
    padding-right: 1.25rem;
  } */
  padding: 0 1.25rem;
  font-family: "Primary Font-Light";
  color: ${(props) => (props.color ? props.color : "")};
  background: ${(props) =>
    props.show === "true" ? props.bgColor || "#546270" : ""};
  /* background:red !important; */
  &&:focus {
    outline: none;
  }

  &::placeholder {
    opacity: 0.5;
  }
`;
