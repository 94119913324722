import React from "react";
import { ReactComponent as TrueFalse } from "../../assets/icons/Group-1157.svg";
import { ReactComponent as MultipleIcon } from "../../assets/icons/multiple.svg";
import { ReactComponent as ShortAnswer } from "../../assets/icons/share_chain_5.svg";
import { ReactComponent as Numerical } from "../../assets/icons/dice_6.svg";
import { ReactComponent as Essay } from "../../assets/icons/paper_1_text.svg";
import { ReactComponent as Matching } from "../../assets/icons/Group1.svg";
import { SideBarIcons } from "./ToolBarInterface";

export const items: SideBarIcons[] = [
  {
    id: 1,
    name: "MCQ",
    icon: <MultipleIcon />,
    color: "#0B8FFF",
  },
  {
    id: 2,
    name: "TF",
    icon: <TrueFalse />,
    color: "#8CEDEA",
  },
  {
    id: 3,
    name: "SHORT_ANSWER",
    icon: <ShortAnswer />,
    color: "#5D69FD",
  },
  {
    id: 4,
    name: "NUMERIC",
    icon: <Numerical />,
    color: "#8E5DFD",
  },
  {
    id: 5,
    name: "ESSAY",
    icon: <Essay />,
    color: "#B757E2",
  },
  {
    id: 5,
    name: "MATCH",
    icon: <Matching />,
    color: "#5DD3B3",
  },
];
