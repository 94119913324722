// CreateNote.tsx
import React, { useState } from "react";
import { NoteContainer, NoteText, SubNoteContainer } from "./CreateNote.style";
import { TextEditor } from "../../../elements/TextEditor/TextEditor";
import { LightTypography } from "../../../elements/fonts/Fonts";
import { ErrorDiv } from "../../../elements/ErrorDiv/Errordiv.style";
import TinyEditor from "../../../elements/tinyEditor/TinyEditor";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

export const CreateNote = ({
  handleTitleChange,
  handleDescriptionChange,
  areInputsValid,
  error,
}: {
  handleTitleChange: (title: string) => void;
  handleDescriptionChange: (description: string) => void;
  areInputsValid: boolean;
  error?: string;
}): JSX.Element => {
  const { themeTextColor, inputBorderColor } = useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  return (
    <NoteContainer>
      <SubNoteContainer>
        <LightTypography text={t('forms.Title')} />
        <NoteText
          type="text"
          placeholder={t('notes.Enter the Title')}
          onChange={(e) => handleTitleChange(e.target.value)}
          style={{
            borderColor: areInputsValid ? inputBorderColor : "#D85D66", // Conditionally apply border color
          }}
          color={themeTextColor}
          bordercolor={inputBorderColor}
        />
      </SubNoteContainer>

      <SubNoteContainer>
        <LightTypography text={t('forms.Description')} />
        <TinyEditor
          onChange={handleDescriptionChange}
          placeholder={t('notes.Add a Note Description')}
          height="14rem"
        />
        <ErrorDiv>{error && t(error)}</ErrorDiv>
      </SubNoteContainer>
    </NoteContainer>
  );
};
