import React, { FC } from "react";
import {
  NumericalInputs,
  NumericalQuestionContainer,
  LableContainer,
} from "./NumericalQuestion.style";
import { NumericalQuestionProps } from "./NumericalQuestionInterface";
import { Typography } from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import { ErrorDiv } from "../../../elements/ErrorDiv/Errordiv.style";
import { useTranslation } from "react-i18next";
import { mapErrorType } from "../../../utils/QuizErrorTypes";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
const NumericalQuestion: FC<NumericalQuestionProps> = ({
  choice,
  showQuest,
  handleChoiceMatchingQuestion,
  qindex,
  questionError,
}) => {
  const { t } = useTranslation();
  const errorType = mapErrorType(questionError || "", t);
  const {
    themeTextColor,
    SecondaryTextColor,
    QuizCreator_ValidatesInput_bgColor,
    inputBorderColor,readOnlyBgColor
  } = useRecoilValue(ColorSelector);
  return (
    <>
      <NumericalQuestionContainer>
        <LableContainer>
          <Typography
            text={t("quiz.Correct Answer")}
            fontSize="0.875rem"
            lineHeight="1.375rem"
          />
          <NumericalInputs
            center="no"
            value={choice.name}
            show={showQuest.toString()}
            type="number"
            width="100%"
            placeholder={t("quiz.write answer")}
            readOnly={showQuest ? true : false}
            onChange={(e) => handleChoiceMatchingQuestion(qindex, e)}
            id="name"
            hasError={qindex === 0 && !!questionError && !choice.name}
            color={SecondaryTextColor}
            Validated_BgColor={readOnlyBgColor}
            borderColor={inputBorderColor}
          />
        </LableContainer>
        <LableContainer>
          <Typography
            text={t("quiz.Error Margin")}
            fontSize="0.875rem"
            color={theme.dark.primary_text_color}
            lineHeight="1.375rem"
          />
          <NumericalInputs
            center="no"
            value={choice?.errorMargin}
            show={showQuest.toString()}
            type="number"
            width="100%"
            placeholder={t("quiz.Error Margin")}
            readOnly={showQuest ? true : false}
            onChange={(e) => handleChoiceMatchingQuestion(qindex, e)}
            id="error-margin"
            hasError={qindex === 0 && !!questionError && !choice.errorMargin}
            color={SecondaryTextColor}
            Validated_BgColor={readOnlyBgColor}
            borderColor={inputBorderColor}
          />
        </LableContainer>
      </NumericalQuestionContainer>
      {/* {questionError && qindex === 0 && (
        <ErrorDiv style={{ marginLeft: "1.56rem" }}>{errorType}</ErrorDiv>
      )} */}
    </>
  );
};

export default NumericalQuestion;
