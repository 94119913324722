import React from "react";
import { FlexAppContainer } from "../../App.style";
import CourseActivityStudentID from "../../components/CourseActivityStudentID/main/CourseActivityStudentID";
import { useParams, useNavigate } from "react-router-dom";
import AnalyticsBackPage from "../../components/AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticsBackPage";

const AnalyticsStudentCourseActivityView = () => {
  const { stdId } = useParams<{ stdId: string }>();

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <FlexAppContainer column={true} padding="0 0 2rem 0" gap="1.25rem">
      <AnalyticsBackPage onClick={handleBack} />

      <CourseActivityStudentID id={stdId} />
    </FlexAppContainer>
  );
};

export default AnalyticsStudentCourseActivityView;
