import React, { FC, useEffect, useState } from "react";
import PopUp from "../../../elements/Popup/PopUp";
import PopupDelete from "../../../elements/PopupDelete/PopupDelete";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/menu_2 (1).svg";
import QuizCategory from "../../QuizCategory/main/QuizCategory";
import LoadTemplate from "../../QuizLoadTemplate/main/LoadTemplate";
import PreviewQuestion from "../../PreviewQuestions/main/PreviewQuestion";
import QuestionBanksContent from "../../questionBanksContent/QuestionBanksContent";
import {
  Question,
  Category,
  Template,
  Choice,
} from "../GenerateQuizDataInterface";

import { ReactComponent as EyeIcon } from "../../../assets/icons/Eye.svg";
import { useQuizMyContext } from "../../../contexts/CourseCreatorQuizContext";
import { useMyContext } from "../../../contexts/QuestionBanksContext";
import { SvgFAQ } from "../../../elements/Icons";
import { useTranslation } from "react-i18next";

interface PopupRendererProps {
  showCategoryPopup?: boolean;
  showLoadTemplate?: boolean;
  showPreviewPopup?: boolean;
  showQuestionBanksPopup?: boolean;
  deleteChoice?: boolean;
  deleteQuestion?: boolean;
  deletePart?: boolean;
  quest: any;
  index: number;
  partIndex?: number;
  setShowPreviewPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteChoice: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteQuestion: React.Dispatch<React.SetStateAction<boolean>>;
  setDeletePart: React.Dispatch<React.SetStateAction<boolean>>;
  setShowQuestionBanksPopup?: React.Dispatch<React.SetStateAction<boolean>>;
  removeChoice: () => void;
  removeQuestion: () => void;
  removePartQuestion: () => void;
  setShowQuest?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditQuest?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PopupRenderer: FC<PopupRendererProps> = ({
  showCategoryPopup,
  showLoadTemplate,
  showPreviewPopup,
  showQuestionBanksPopup,
  deleteChoice,
  deleteQuestion,
  deletePart,
  quest,
  index,
  partIndex,
  setShowPreviewPopup,
  setDeleteChoice,
  setDeleteQuestion,
  setDeletePart,
  setShowQuestionBanksPopup,
  removeChoice,
  removeQuestion,
  removePartQuestion,
  setShowQuest,
  setEditQuest,
}) => {
  const { t } = useTranslation();
  const { globalQuestionsArray } = useQuizMyContext();
  const { setGlobalShowPopup, globalShowPopup } = useMyContext();
  const [categories, setCategories] = useState<Category[]>([
    { id: 1, name: "Science 02" },
    { id: 2, name: "Science 02" },
    { id: 3, name: "Science 02" },
    { id: 4, name: "Math 003" },
    { id: 5, name: "Math 003" },
    { id: 6, name: "Math 003" },
  ]);
  const [templates, setTemplates] = useState<Template[]>([
    { id: 1, name: "Template 01", date: "20-10-2020", questionNumber: 10 },
    { id: 2, name: "Template 01", date: "20-10-2020", questionNumber: 10 },
    { id: 3, name: "Template 01", date: "20-10-2020", questionNumber: 10 },
    { id: 4, name: "Template 01", date: "20-10-2020", questionNumber: 10 },
  ]);

  return (
    <>
      {showCategoryPopup ? (
        // <PopUp
        //   show={showCategoryPopup}
        //   content={<QuizCategory categories={categories} />}
        //   setShow={setShowCategoryPopup}
        //   title={"Category"}
        //   icon={<CategoryIcon />}
        //   showline={false}
        // />
        ""
      ) : showLoadTemplate ? (
        // <PopUp
        //   show={showLoadTemplate}
        //   content={<LoadTemplate templates={templates} />}
        //   setShow={setShowshowLoadTemplate}
        //   title={"Load Template"}
        //   icon={<CategoryIcon />}
        //   showline={false}
        // />
        ""
      ) : showPreviewPopup ? (
        <PopUp
          show={showPreviewPopup}
          content={<PreviewQuestion questions={quest} index={index} />}
          setShow={setShowPreviewPopup}
          title={"Preview"}
          icon={<EyeIcon />}
          showline={true}
        />
      ) : deleteChoice ? (
        <PopupDelete
          show={deleteChoice}
          setShow={setDeleteChoice}
          title={t("quiz.Are you sure you want to delete this choice?")}
          handleSubmit={removeChoice}
        />
      ) : deleteQuestion ? (
        <PopupDelete
          show={deleteQuestion}
          setShow={setDeleteQuestion}
          title={t("quiz.Are you sure you want to delete this question?")}
          handleSubmit={removeQuestion}
        />
      ) : deletePart ? (
        <PopupDelete
          show={deletePart}
          setShow={setDeletePart}
          title={t("quiz.Are you sure you want to delete this part?")}
          handleSubmit={removePartQuestion}
        />
      ) : globalShowPopup && setGlobalShowPopup ? (
        <PopUp
          show={globalShowPopup}
          content={
            <QuestionBanksContent
              setShowQuestionBanksPopup={setGlobalShowPopup}
              setShowQuest={setShowQuest}
              setEditQuest={setEditQuest}
              questions={globalQuestionsArray || []}
              index={index}
              partIndex={partIndex || 0}
            />
          }
          setShow={setGlobalShowPopup}
          showline={false}
          showButton={false}
          title={t("question bank.Question Bank")}
          icon={<SvgFAQ />}
          paddingTop="0rem"
          paddingRight="0rem"
          paddingLeft="0rem"
          // paddingBottom="0rem"
        />
      ) : (
        ""
      )}
    </>
  );
};

export default PopupRenderer;
