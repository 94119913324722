import React, { useEffect, useMemo, useState } from "react";
import {
  AbsoluteDiv,
  AbsoluteDiv3,
  AbsoluteDiv4,
  CheckBox,
  FilterBtnsDiv,
  FlexDiv,
  Input,
  MainActivity,
  MainHolder,
  TableSection,
  TableTbody,
  TableTd,
  TableTd1,
  TableTdMainActivity,
  TableTr,
  TableTr1,
  TableTrMainActivity,
  TableTrMainModule,
} from "./ManageWeight.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../elements/fonts/Fonts";
import { Activity } from "./interfaces";
import { ButtonFilledWrapper } from "../../elements/FiltersButtonFilled/FiltersButtonFilled.style";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  MainColorSelector,
  TableColorSelector,
} from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { SvgDelete, SvgMore } from "../../elements/Icons";
import { PointerContainer } from "../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableTable.style";
import { handleShowSelectedIndex } from "../../utils/SaveSelectedIndex";
import SelectedOptions from "./SelectedOptions/SelectedOptions";
import {
  ManageWeightAttendanceCell,
  ManageWeightBodyCellContents,
  ManageWeightBodyCellFirst,
  ManageWeightBodyCellSize,
  ManageWeightBodyTdCells,
  ManageWeightBodyTds,
  ManageWeightFlexTr,
  ManageWeightTable,
} from "./SelectedOptions/ManagewieghtUpdatesStyle";
import MappedIndexClickOutSide from "../../hooks/MapedIndexClickOutSide";
import { handleWheel } from "../../utils/OnWheelFunction";
import { languageState } from "../../recoil/RecoilStates";
import { toast } from "react-toastify";
interface Attendance {
  Attendance: {
    Weight: number;
  };
}

interface ActivityTableProps {
  activities: Activity[];
  isCheckedArray: boolean[];
  mainActivities: any[];
  fetchManageWeightData: () => void;
  handleMainWeightChange: (
    activityType: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleWeightChange: (
    contentId: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleCheckboxChange: (contentId: number) => void; // Define the prop for handleCheckboxChange
  sendInputStateToParent: (percentage: number) => void;
  SetShowSave: React.Dispatch<React.SetStateAction<boolean>>;
  shouldGroupPerModule: boolean;
  AttendanceWeight: number; // Define the prop for AttendanceWeight
  SetAttendanceWeight: React.Dispatch<React.SetStateAction<number>>;
  onAttendanceChange: (value: number) => void;
}

const ActivityTable: React.FC<ActivityTableProps> = ({
  activities,
  fetchManageWeightData,
  SetShowSave,
  mainActivities,
  isCheckedArray,
  SetAttendanceWeight,
  AttendanceWeight,
  handleWeightChange,
  handleCheckboxChange,
  handleMainWeightChange,
  sendInputStateToParent,
  shouldGroupPerModule,
  onAttendanceChange,
}) => {
  const [percentage, setPercentage] = useState<number | null>(0);
  const [Filters, SetFilters] = useState<any[]>([]);
  const [FiltersClicked, SetFiltersClicked] = useState<boolean>(false);
  const [renderedMainActivities, setRenderedMainActivities] = useState<
    string[]
  >([]);
  const [attendance, setAttendance] = useState<Attendance[]>([]);
  const tenantName = localStorage.getItem("tenant");
  const id = useParams();
  const { t } = useTranslation();
  const [showMenuOfSelectedIndex, setShowMenuOfSelectedIndex] = useState<
    number | null
  >(null);
  const {
    SecondaryTextColor,
    bghelp,
    theme,
    MainColoredColor,
    checkboxBgColor,
    checkboxBorderColor,
    checkboxBorderRadius,
  } = useRecoilValue(ColorSelector);
  const { Table_nth_child } = useRecoilValue(TableColorSelector);
  const { mainBackgroundColor } = useRecoilValue(MainColorSelector);
  const clickOutsideRef = MappedIndexClickOutSide(() =>
    setShowMenuOfSelectedIndex(null)
  );
  useEffect(() => {
    // Extract the main activity types and update renderedMainActivities
    const mainActivityTypes = mainActivities.map(
      (activity) => Object.keys(activity)[0]
    );
    setRenderedMainActivities(mainActivityTypes);
  }, [mainActivities]);
  const getFilters = async () => {
    try {
      const response = await axios.get(
        `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/grades/filter/get/${id.classId}/${tenantName}`
      );
      SetFilters(response.data.data);

      // Handle the response data as needed
    } catch (error) {
      console.error("There was an error making the GET request!", error);
    }
  };
  useEffect(() => {
    getFilters();
  }, []);

  const handlePercentageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = Number(event.target.value);
    setPercentage(value);
    sendInputStateToParent(value);
  };

  const handleWeightInputChange = (
    contentId: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    SetShowSave(true);
    handleWeightChange(contentId, event);
  };

  const MainWeightChange = (
    activityType: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleMainWeightChange(activityType, event);
    SetShowSave(true);
  };

  const handleCheckboxToggle = (contentId: number) => {
    handleCheckboxChange(contentId);
    SetShowSave(true);
  };

  if (activities.length === 0) {
    return null;
  }

  const findFilterForActivity = (activityType: string) => {
    const filter = Filters.find(
      (filter: any) => filter.activityType === activityType
    );
    if (!filter) {
      return `${t("gradebook.No filter found")}`;
    }
    switch (filter.filter) {
      case "maximum":
        return `(${t(
          "gradebook.The current grading system has been configured to only consider the highest grade."
        )})`;
      case "lowest":
        return `(${t(
          "gradebook.The current grading system has been adjusted to exclude the lowest grade."
        )})`;
      case "average":
        return `(${t(
          "gradebook.The current grading system has been adjusted to calculate the average grade."
        )})`;
      case "manual":
        return `(${t(
          "gradebook.The current grading system has been configured to use the manually entered weights."
        )})`;
      default:
        return `${t("gradebook.No filter found")}`;
    }
  };

  function AttendanceChange(value: number): void {
    onAttendanceChange(value);
    SetShowSave(true);
  }
  const activityTypeCount: { [key: string]: number } = {};
  activities.forEach((activity) => {
    if (activityTypeCount[activity.type]) {
      activityTypeCount[activity.type]++;
    } else {
      activityTypeCount[activity.type] = 1;
    }
  });
  const language = localStorage.getItem("language");

  const isInputDisabled = (activityType: string) => {
    const filter = Filters.find(
      (filter: any) => filter.activityType === activityType
    );
    return filter && filter.filter !== "manual";
  };

  const deleteActivity = async (id: any) => {
    try {
      const response = await axios.delete(
        `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/offline/activity/KEY/${id}`
      );
      console.log("Activity deleted successfully:", response.data);
      toast.success("offline activity deleted sucessfully");
      fetchManageWeightData();
    } catch (error) {
      console.error("Error deleting activity:", error);
      toast.error("failed to deleted offline activity");
    }
  };

  return (
    <ManageWeightTable>
      <ManageWeightFlexTr>
        <ManageWeightAttendanceCell>
          <Input
            type="number"
            value={AttendanceWeight}
            onChange={(event) => AttendanceChange(Number(event.target.value))}
            color={SecondaryTextColor}
            bordercolor={MainColoredColor}
            onWheel={handleWheel}
            min={0}
          />
          <div
            style={{
              margin: `${
                language === "ar" ? "0 0.62rem 0 0" : "0 0 0 0.62rem"
              }`,
            }}
          ></div>
          <MediumTypography text="%" />
          <div />
        </ManageWeightAttendanceCell>
        <ManageWeightAttendanceCell>
          <MediumTypography
            text={t("gradebook.Attendance")}
            fontSize="0.875rem"
          />
        </ManageWeightAttendanceCell>
        <ManageWeightAttendanceCell></ManageWeightAttendanceCell>
        <ManageWeightAttendanceCell></ManageWeightAttendanceCell>
      </ManageWeightFlexTr>

      {/* Render activities */}
      {activities.map((activity: Activity, index: number) => {
        const activityType = activity.type;
        console.log(activities);
        const isMainModuleActivity =
          shouldGroupPerModule && activity.moduleIndex !== undefined;

        // Check if the current activity is the first one of its type
        const isFirstActivityOfType =
          index === 0 || activities[index - 1].type !== activityType;

        if (isFirstActivityOfType) {
          // Check if this activity type has a corresponding main activity
          const correspondingMainActivity = isMainModuleActivity
            ? `Module ${activity.moduleIndex}` // Use module index as main activity if shouldGroupPerModule is true
            : mainActivities.find(
                (mainActivity) => Object.keys(mainActivity)[0] === activityType
              );

          if (correspondingMainActivity) {
            const mainActivityType = isMainModuleActivity
              ? `Module ${activity.moduleIndex}` // Use module index as main activity if shouldGroupPerModule is true
              : Object.keys(correspondingMainActivity)[0];
            const mainActivityWeight =
              correspondingMainActivity[mainActivityType]?.Weight || "";
            const filter = findFilterForActivity(activityType);
            const hasSingleOrNoContentTitle =
              activityTypeCount[activityType] <= 1;
            return (
              <ManageWeightBodyCellFirst key={activity.content.contentId}>
                {/* Render main activity */}
                <ManageWeightBodyCellSize>
                  <MainActivity>
                    <MediumTypography
                      text={t(`gradebook.${mainActivityType}`)}
                      fontSize="0.875rem"
                    />
                    {!shouldGroupPerModule && (
                      <MainHolder style={{ marginBottom: "0" }}>
                        <Input
                          type="number"
                          value={mainActivityWeight || 0}
                          onChange={(event) =>
                            MainWeightChange(mainActivityType, event)
                          }
                          color={SecondaryTextColor}
                          bordercolor={MainColoredColor}
                          onWheel={handleWheel}
                          min={0}
                        />

                        <MediumTypography text="%" />
                      </MainHolder>
                    )}
                    {hasSingleOrNoContentTitle ? (
                      <AbsoluteDiv4>
                        <LightTypography text={filter} fontSize="0.5rem" />
                      </AbsoluteDiv4>
                    ) : (
                      <AbsoluteDiv3>
                        <LightTypography text={filter} fontSize="0.7rem" />
                      </AbsoluteDiv3>
                    )}
                  </MainActivity>
                </ManageWeightBodyCellSize>
                {/* Render sub-activity */}
                <ManageWeightBodyCellSize>
                  <ManageWeightBodyTds>
                    <LightTypography text={activity.content.contentTitle} />
                  </ManageWeightBodyTds>
                  <ManageWeightBodyTds>
                    <Input
                      color={SecondaryTextColor}
                      bordercolor={
                        isInputDisabled(activityType) ? "#5DD3B3" : ""
                      }
                      type="number"
                      value={activity.weight || 0}
                      onChange={(event) =>
                        handleWeightInputChange(
                          activity.content.contentId,
                          event
                        )
                      }
                      onWheel={handleWheel}
                      min={0}
                      disabled={isInputDisabled(activityType)}
                    />
                    %
                  </ManageWeightBodyTds>
                  <ManageWeightBodyTds style={{ position: "relative" }}>
                    {activity.isOffline && (
                      <SvgDelete onClick={() => deleteActivity(activity.id)} />
                    )}
                    {!activity.isOffline && (
                      <CheckBox
                        bgcolor={checkboxBgColor}
                        bordercolor={checkboxBorderColor}
                        borderradius={checkboxBorderRadius}
                        type="checkbox"
                        checked={activity.publish}
                        name={`status_${activity.content.contentId}`}
                        onChange={() =>
                          handleCheckboxToggle(activity.content.contentId)
                        }
                      />
                    )}
                    {activityTypeCount[activityType] > 1 && (
                      <PointerContainer
                        onClick={() =>
                          handleShowSelectedIndex(
                            index,
                            showMenuOfSelectedIndex,
                            setShowMenuOfSelectedIndex
                          )
                        }
                      >
                        <SvgMore
                          style={{
                            transform: "rotate(90deg)",
                          }}
                        />
                      </PointerContainer>
                    )}
                    {showMenuOfSelectedIndex === index && (
                      <AbsoluteDiv ref={clickOutsideRef}>
                        <SelectedOptions
                          Type={activity.content.type}
                          getFilters={getFilters}
                          setShowMenuOfSelectedIndex={
                            setShowMenuOfSelectedIndex
                          }
                        />{" "}
                      </AbsoluteDiv>
                    )}
                  </ManageWeightBodyTds>
                </ManageWeightBodyCellSize>
              </ManageWeightBodyCellFirst>
            );
          }
        }

        // Render sub-activity
        return (
          <ManageWeightBodyCellContents
            nth_child_bgcolor={Table_nth_child}
            key={activity.content.contentId}
          >
            <ManageWeightBodyTdCells
              style={{ background: mainBackgroundColor }}
            ></ManageWeightBodyTdCells>
            <ManageWeightBodyTdCells>
              <LightTypography text={activity.content.contentTitle} />
            </ManageWeightBodyTdCells>
            <ManageWeightBodyTdCells>
              <Input
                color={SecondaryTextColor}
                bordercolor={MainColoredColor}
                type="number"
                value={activity.weight || 0}
                onChange={(event) =>
                  handleWeightInputChange(activity.content.contentId, event)
                }
                onWheel={handleWheel}
                min={0}
                disabled={isInputDisabled(activityType)}
              />
              %
            </ManageWeightBodyTdCells>
            <ManageWeightBodyTdCells>
              <CheckBox
                bgcolor={checkboxBgColor}
                bordercolor={checkboxBorderColor}
                borderradius={checkboxBorderRadius}
                type="checkbox"
                checked={activity.publish}
                name={`status_${activity.content.contentId}`}
                onChange={() =>
                  handleCheckboxToggle(activity.content.contentId)
                }
                disabled={activity.isOffline} // Add the disabled attribute here
              />
            </ManageWeightBodyTdCells>
          </ManageWeightBodyCellContents>
        );
      })}
    </ManageWeightTable>
  );
};

export default ActivityTable;
