import { SvgEyeIcon, SvgGear, SvgPrevArrowIcon, SvgNextArrowCourseBannerIcon } from "../../elements/Icons"
// import SelectInput from "../../elements/SelectInput/SelectInput"
import { MediumTypography, Typography } from "../../elements/fonts/Fonts"
// import IconWithText from "../../elements/iconWithText/main/IconWithText"
import ReusableInput from "../../elements/reusableInput/ReusableInput"
// import Pagination from "../../elements/Pagination/Pagination"
import { AboveTableRow, ArrowContainer, DivArrowsWrapper, GradesTableShowHolder, ReducedPaddingRow, ReducedPaddingcell, ReducedPaddingcellFixed, TableContent, TableContentHolder, TableFixedContentHolder, TableFixedTd, TableSection, TableSmallSection, TableTbody, TableTd, TableTh, TableTh1, TableThead, TableTr, TableTr1, TitleWithArrow, TitleWithArrow2, TitlesWithSearch, TitlesWrapper } from "./GradesInstructorTable.style"
import { SegmentTitleData, TableTrData } from "./gradesInstructorData"
import { useState, useRef, useEffect, } from 'react';
import { useNavigate } from "react-router-dom"
import ToggleButton from "./toggleButton/ToggleButton"
import SpecialSelectedInput from "./SpecialSelectedInput/main/SpecialSelectedInput"
// import SubOption from "./SpecialSelectedInput/SubOption/SubOption"
// import MenuItems from "../MenuItems/MenuItems"
// import { menuItems } from "../MenuItems/MenuItemsData"
import Tooltip from "./toolTip/ToolTip"
import GradesModal from "./GradesModal/GradesModal"
// import useClickOutside from "../../hooks/useClickOutside"


const GradesInstructorTable = () => {

  const navigate = useNavigate()
  const [isSpecialInputHovered, setIsSpecialInputHovered] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const numCells = TableTrData[0].trContent.length;
  const [tooltipTexts, setTooltipTexts] = useState(
    Array(numCells).fill('Manually')
  );
  const [isChecked, setIsChecked] = useState(
    Array(numCells).fill(false)
  );
  const [isCheckedArray, setIsCheckedArray] = useState(Array(numCells).fill(true));
  const [modal, setModal] = useState<boolean>(false);
  const [order, setOrder] = useState('');
  const [columnIndex, setColumnIndex] = useState<number>(0); // Initialize it with a default value
  const [isAscendingOrder, setIsAscendingOrder] = useState(true);

  const sortTableData = (columnIndex: number) => {
    const sortedData = [...TableTrData].slice(1); // Skip the header row
    sortedData.sort((a, b) => {
      if (order === 'ASC') {
        return a.trContent[columnIndex].tdContent.localeCompare(b.trContent[columnIndex].tdContent);
      } else {
        return b.trContent[columnIndex].tdContent.localeCompare(a.trContent[columnIndex].tdContent);
      }
    });

    return sortedData;
  };

  const sortedData = sortTableData(currentIndex);
  const handleSorting = (sortingCriteria: string) => {
    // const sortOrder = 'ascending';
    if (sortingCriteria === 'Ascending') {
      setOrder('ASC');
    } else if (sortingCriteria === 'Descending') {
      setOrder('DESC');
    }
    // setCurrentIndex(columnIndex); // Set the current index to trigger a re-render
  };


  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let handler = (e: any) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setModal(false);
      }
    };

    document.addEventListener("mousedown", handler);


    return () => {
      document.removeEventListener("mousedown", handler);
    }

  });




  const handleHover = () => {
    setIsSpecialInputHovered(true);
  };

  const handleMouseLeave = () => {
    setIsSpecialInputHovered(false);
  };





  const handleSelectChangeEnrollment = (newValue: string) => {
    // setEditTask({...task, enrollment: newValue});
  };
  const columnWidth = 200;

  const ebrollmentOptions = [{ name: 'Sort by' }, { name: 'Filters' }]
  // const columnsPerPage = 8;
  // const [PerPage, setPerPage] = useState<number>(7);
  // const totalColumns = SegmentTitleData.length;
  // const totalPages = Math.ceil(totalColumns / columnsPerPage);

  // const handlePageChange = (page: number) => {
  //   setCurrentPage(page);
  // };
  // const columnsPerPage = PerPage;
  // const indexOfLast = currentPage * columnsPerPage;
  // const indexOfFirst = indexOfLast - (columnsPerPage);

  // const columnsToDisplay = SegmentTitleData.slice(indexOfFirst, indexOfLast);
  // const lastColumnIndex = currentPage * columnsPerPage;
  // const columnsToDisplay = SegmentTitleData.slice(
  //     firstColumnIndex,
  //     lastColumnIndex
  //   );
  const handleToggle = (index: number) => {

    setIsChecked(prev => {
      const newArray = [...prev];
      newArray[index] = !newArray[index];
      return newArray;
    });
    // setIsChecked(prev => !prev);

    setTooltipTexts(prev => {
      const texts = [...prev];
      if (isChecked[index]) {
        texts[index] = 'Manually';
      } else {
        texts[index] = 'Automatically';
      }
      return texts;
    });
  }

  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollLeft = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollBy({
        left: -200, // Adjust this value based on your column width
        behavior: 'smooth', // Use 'auto' for instant scrolling
      });
    }
  };

  // Function to scroll to the right
  const scrollRight = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollBy({
        left: 200, // Adjust this value based on your column width
        behavior: 'smooth', // Use 'auto' for instant scrolling
      });
    }
  };

  // Add an effect to update the scroll position when currentIndex changes
  useEffect(() => {
    if (tableContainerRef.current) {
      const newScrollPosition = currentIndex * columnWidth;
      tableContainerRef.current.scrollLeft = newScrollPosition;
      setScrollPosition(newScrollPosition);
    }
  }, [currentIndex]);


  // const numColumnsVisible = 5;
  // const toggleModel = () =>{
  //   setModal(!modal)
  //   console.log(modal)
  // }
  const handleOpenModal = () => {
    // open modal
    setModal(!modal)
  }


  const numCellsInSegment = SegmentTitleData.length + 1;

  const calculateSegmentTotal = (segmentIndex: number) => {
    let total = 0;

    sortedData.forEach((student, studentIndex) => {
      total += parseFloat(student.trContent[segmentIndex].tdContent) || 0;
    });

    const average = total / numCellsInSegment;
    return average.toFixed(2);
  };

  const totalSegments = SegmentTitleData.length;
  const gradesPerSegment = TableTrData[0].trContent.length / totalSegments;
  return (
    <GradesTableShowHolder>
      <AboveTableRow>
        <TitlesWithSearch>
          <TitlesWrapper>
            <TitleWithArrow>
              {/* <SvgEyeIcon /> */}

              <SpecialSelectedInput
                // optionIcon={<SvgArrowShock />}
                color="#5dd3b3"
                icon={<SvgEyeIcon />}
                height="2rem"
                padding="0.69rem 0.75rem 0.56rem 0.63rem"
                marginLeft="0.63rem"
                border="none"
                fontSize="0.625rem"
                width="5.875rem"
                optionWidth='12.5rem'
                display="block"
                value="VIEW" options={ebrollmentOptions}
                onChange={handleSelectChangeEnrollment}
                onHover={handleHover}
                onMouseLeave={handleMouseLeave}
                handleSorting={handleSorting}
                columnIndex={columnIndex}
              // options={menuItems}
              />

            </TitleWithArrow>
            <TitleWithArrow2 onClick={() => {
              navigate('/gradeBook');
            }}>
              <SvgGear />

              <Typography text="SETTINGS" fontSize="0.625rem" />
            </TitleWithArrow2>
          </TitlesWrapper>

          <ReusableInput
            placeHolder="Search"
            padingLeft="1.25rem"
            inputBorderRadius="0.63rem"
            inputWidth="25rem"
            inputHeight="2.75rem"
            display="block"
          />
        </TitlesWithSearch>
      </AboveTableRow>
      <div style={{ display: 'flex' }}>

        <TableSmallSection style={{ display: 'flex', marginTop: '1.35rem' }}>

          <TableFixedContentHolder>
            <TableThead >
              <TableTr1 >
                {SegmentTitleData.map((item, index) => (
                  <TableTh1 colSpan={index === 0 ? 4 : 4} key={index} style={{ textAlign: 'end' }}>
                    <MediumTypography

                      text={item.segmentTitle}
                      fontSize="1.125rem"
                      lineHeight="1.75rem"
                      whiteSpace="nowrap"
                    />
                  </TableTh1>
                ))}
              </TableTr1>
            </TableThead>
            <TableContent>
              <TableTbody>
                {TableTrData[0] && (
                  <ReducedPaddingRow key={0}>
                    {TableTrData[0].trContent.map((td, tdIndex) => (
                      <ReducedPaddingcellFixed
                        key={tdIndex}
                        style={{
                          textAlign: "start",
                          paddingLeft: "1.25rem",
                        }}
                      >
                        <Typography
                          text={td.tdContent}
                          fontSize={tdIndex === 0 ? "1rem" : "0.875rem"}
                          color={td.color}
                        />
                      </ReducedPaddingcellFixed>
                    ))}
                  </ReducedPaddingRow>
                )}

                {sortedData.map((tr, index) => (

                  <TableTr key={index}>
                    {/* Render the first column */}+563
                    <TableFixedTd
                      style={{
                        textAlign: "start",
                        paddingLeft: "1.25rem",
                      }}
                    >
                      {tr.trContent.slice(0, 1).map((td, tdIndex) => (
                        <div
                          onClick={handleOpenModal}
                          style={{ cursor: 'pointer' }}
                        >

                          <Typography
                            key={tdIndex}
                            text={td.tdContent}
                            fontSize="0.875rem"
                            color={td.color}
                          />
                        </div>
                      ))}
                    </TableFixedTd>
                  </TableTr>
                ))}
              </TableTbody>
            </TableContent>
          </TableFixedContentHolder>
        </TableSmallSection>
        <TableSection
          ref={tableContainerRef}
          onScroll={(e) => setScrollPosition(e.currentTarget.scrollLeft)}

        >
          <TableContentHolder >

            <TableContent style={{ minWidth: "auto" }}>
              <TableThead>
                <TableTr1 >
                  {SegmentTitleData.map((item, index) => (
                    <TableTh1 colSpan={TableTrData[0].trContent.findIndex(td => td.tdContent.toLowerCase().includes('total'))} key={index + 1}>

                      <MediumTypography
                        text={item.segmentTitle}
                        fontSize="1.125rem"
                        lineHeight="1.75rem   "
                        whiteSpace="nowrap"
                      />
                    </TableTh1>
                  ))}
                </TableTr1>
              </TableThead>

              <TableTbody>

                {TableTrData[0] && (
                  <ReducedPaddingRow key={0}>
                    {TableTrData[0].trContent.slice(1).map((td, tdIndex) => (
                      <ReducedPaddingcell key={tdIndex}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.3rem', width: 'max-content', paddingLeft: '1.25rem', paddingRight: '1.25rem' }}>
                          {!td.tdContent.includes('TOTAL') ? (
                            <>
                              <Typography
                                text={td.tdContent}
                                fontSize="1rem"
                                color={td.color}
                              />
                              {isCheckedArray[tdIndex] && (
                                <Tooltip margin={"15px"} text={tooltipTexts[tdIndex]}>
                                  <ToggleButton
                                    checked={isChecked[tdIndex]}
                                    onChange={() => handleToggle(tdIndex)}
                                  />
                                </Tooltip>
                              )}
                            </>
                          ) : (
                            <Typography
                              text={td.tdContent}
                              fontSize="1rem"
                              color={td.color}
                            />
                          )}
                        </div>
                      </ReducedPaddingcell>
                    ))}
                  </ReducedPaddingRow>
                )}


                {sortedData.map((tr, index) => (
                  <TableTr key={index}>
                    {tr.trContent.slice(1).map((td, tdIndex) => (
                      <TableTd key={tdIndex}>
                        {/* here i display segment total */}
                        <Typography
                          text={td.tdContent}
                          fontSize="0.875rem"
                          color={td.color}
                        />
                      </TableTd>
                    ))}
                  </TableTr>
                ))}
              </TableTbody>
            </TableContent>
          </TableContentHolder>
        </TableSection>
      </div>
      <ArrowContainer >
        <DivArrowsWrapper>
          <SvgPrevArrowIcon onClick={scrollLeft}
            // fill={scrollPosition === 0 ? '#898e94' : 'white'}
            style={{ height: '0.8rem' }}
          />

          <SvgNextArrowCourseBannerIcon style={{ height: '0.8rem' }} onClick={scrollRight}
          // fill={scrollPosition >= columnsToDisplay.length * columnWidth - numColumnsVisible ? '#898e94' : 'white'}
          />


        </DivArrowsWrapper>
      </ArrowContainer>
      {modal && (
        <>
          <div ref={menuRef}>

            <GradesModal />
          </div>


        </>
      )}
    </GradesTableShowHolder>
  )
}

export default GradesInstructorTable