import React from "react";
import { SpinnerContainer, Zloader } from "./ZSpinner.style";

export interface ZSpinnerName {
  containerHeight?: string;
  loaderSize?: string;
  loaderPadding?: string;
}

const ZSpinner: React.FC<ZSpinnerName> = ({ containerHeight }) => {
  return (
    <SpinnerContainer style={{ height: containerHeight || "100%" }}>
      <Zloader />
    </SpinnerContainer>
  );
};

export default ZSpinner;
