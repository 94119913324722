import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 63rem;
  flex-direction: column;
  gap: 1.25rem;
  height: 75vh;
  /* overflow-x: hidden;
  overflow-y: auto; */
  padding-right: 1.25rem;
`;
export const FlexDiv = styled.div<{ gap?: string }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : "0.5rem")};
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WarningMsg = styled.div<{ gap?: string }>`
  width: 100%;
  padding: 1.12rem 1.25rem;
  display: flex;
  gap: 0.62rem;
  border-radius: 0.625rem;
  background: rgba(255, 255, 255, 0.03);
  align-items: center;
`;

export const ButtonsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
`;
