import React, { FC, useState, useEffect } from "react";
import {
  CustomCheckboxWrapper,
  CustomCheckboxInput,
  CustomCheckbox,
  MultileQuestionContainer,
  CheckBox,
  MultipleInputs,
} from "./MultipleQuestion.style";
import { MultipleQuestionProps } from "./MultipleQuestionInterface";
import { ReactComponent as CheckIcon } from "../../../assets/icons/Group2199.svg";

import { ErrorDiv } from "../../../elements/ErrorDiv/Errordiv.style";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { SvgDelete } from "../../../elements/Icons";
import { useTranslation } from "react-i18next";

const MultipleQuestion: FC<MultipleQuestionProps> = ({
  questionError,
  showQuest,
  qindex,
  choice,
  handleQIndexFromChild,
  handleChoiceMatchingQuestion,
  handleChoiceChecked,
  allChoices,
  setChoiceIndex,
  setDeleteChoice,
  handleRadioChecked,
}) => {
  const [isChecked, setIsChecked] = useState(choice?.isChecked);
  const [countChecked, setCountChecked] = useState(1);
  const [errorShown, setErrorShown] = useState(false);
  const { t } = useTranslation();

  const handleQIndexChange = (qindex: number) => {
    // Call the function received from props and pass the qindex value
    handleQIndexFromChild && handleQIndexFromChild(qindex);
  };

  const handleCheckboxChange = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    handleChoiceChecked(qindex, newValue);
  };
  const handleRadioChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    handleRadioChecked && handleRadioChecked(qindex, newCheckedState);
  };

  useEffect(() => {
    setCountChecked(
      allChoices.filter((choice) => choice.isChecked === true).length
    );
    setErrorShown(false); // Reset errorShown when allChoices changes
    handleQIndexChange(qindex);
    setIsChecked(choice?.isChecked);
  }, [allChoices, choice, qindex]);

  useEffect(() => {
    handleQIndexChange(qindex);
  }, [qindex]);
  const {
    themeTextColor,
    checkboxBgColor,checkboxBorderColor,checkboxBorderRadius,RadioButtonGradientColor,
    QuizCreator_ValidatesInput_bgColor,
    bghelp,
    inputBorderColor,readOnlyBgColor, theme
  } = useRecoilValue(ColorSelector);

  return (
    <>
      <MultileQuestionContainer>
        {!showQuest ? (
          allChoices.length === 2 ? (
            <>
              {choice?.isChecked ? (
                <CheckBox
                  name="radio"
                  id={`${choice.isChecked}`}
                  type="radio"
                  checked={true}
                  onChange={handleRadioChange}
                  bgcolor={RadioButtonGradientColor}
                />
              ) : (
                <CheckBox
                  name="radio"
                  id={`${choice.isChecked}`}
                  type="radio"
                  checked={false}
                  onChange={handleRadioChange}
                  bgcolor={RadioButtonGradientColor}
                />
              )}
            </>
          ) : allChoices.length > 2 ? (
            <CustomCheckboxWrapper>
              <CustomCheckboxInput type="checkbox"  />
              {choice?.isChecked ? (
                <CheckIcon id="isChecked" onClick={handleCheckboxChange} />
              ) : (
                <CustomCheckbox
                bgcolor={checkboxBgColor}
                bordercolor={checkboxBorderColor}
                borderradius={checkboxBorderRadius}
                  id="isChecked"
                  onClick={handleCheckboxChange}
                />
              )}
            </CustomCheckboxWrapper>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        <MultipleInputs
          center="no"
          value={choice.name}
          show={showQuest.toString()}
          type="text"
          width="90%"
          placeholder={t("quiz.write answer")}
          readOnly={showQuest ? true : false}
          onChange={(e) => handleChoiceMatchingQuestion(qindex, e)}
          id="name"
          hasError={!!questionError && !choice.name}
          color={themeTextColor}
          Validated_BgColor={readOnlyBgColor}
          borderColor={inputBorderColor}
        />
        <MultipleInputs
          center="yes"
          value={choice.percentage}
          show={showQuest.toString()}
          type="number"
          width="10%"
          placeholder="%"
          readOnly={showQuest ? true : false}
          onChange={(e) => handleChoiceMatchingQuestion(qindex, e)}
          id="percentage"
          hasError={!!questionError && !choice.percentage}
          color={themeTextColor}
          Validated_BgColor={readOnlyBgColor}
          borderColor={inputBorderColor}
        />
        {allChoices.length > 2 ? (
          <SvgDelete
            style={{ cursor: "pointer" }}
            onClick={() => {
              setDeleteChoice(true);
              setChoiceIndex(qindex);
            }}
            color={theme === "light" ? "#D85D66" : "#fff"}
          />
        ) : (
          ""
        )}
      </MultileQuestionContainer>
      {allChoices.length > 2}
      {/* {(questionError && !errorShown && qindex === 1) && <ErrorDiv style={{marginLeft:"3.37rem"}}>{questionError}</ErrorDiv>} */}
    </>
  );
};

export default MultipleQuestion;
