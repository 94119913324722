import React, { useEffect, useState } from "react";
import {
  BtnsDiv,
  ButtonContent,
  CheckboxLabel,
  FilerPopUpOverlay,
  PopupContainer,
  PopupContentContainer,
  SeletectOptinToggle,
  PopupHeader,
  CheckboxsContainer,
} from "./FilterPopUp.style";
import CancelButton from "../../../../elements/StyledButton/CancelButton";
import { SvgClose } from "../../../../elements/Icons";
import {
  LightTypography,
  TypographyRegularDescription,
} from "../../../../elements/fonts/Fonts";
import { ReactComponent as SvgSettingIcon } from "../../../../assets/icons/settings_1.svg";
import { FilterPopUpInterface } from "../../dashboardContentInterfaces";
import { useTranslation } from "react-i18next";

import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../../recoil/ThemeSelectors";

export default function FilterPopUp({
  existingResourceTitles,
  onClosePopup,
  onClickFilter,
  filterTitles,
  ResourcesT,
  type,
}: FilterPopUpInterface) {
  const [ResourceTitles, setResourceTitles] = useState<any[]>(
    filterTitles || []
  );
  // set the Titles to map as default equal to the list of resources or activities
  const [dataToMap, setDataToMap] = useState<any[]>(ResourcesT);
  const { t } = useTranslation();
  const {
    backgroundColor,
    iconCloseColor,
    themeTextColor,
    checkboxBgColor,
    checkboxBorderColor,
    checkboxBorderRadius,
    theme,
  } = useRecoilValue(ColorSelector);

  const language = localStorage.getItem("language");
  return (
    <FilerPopUpOverlay isvisible={true.toString()}>
      <PopupContainer>
        <PopupContentContainer bgColor={backgroundColor}>
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <SvgSettingIcon color={themeTextColor} />
            <PopupHeader>
              <LightTypography text={type || ""} color={themeTextColor} />
              <SvgClose
                color={iconCloseColor}
                width={"2rem"}
                cursor={"pointer"}
                onClick={onClosePopup}
              />
            </PopupHeader>
          </div>
          {dataToMap && (
            <div style={{ paddingTop: "1rem" }}>
              <CheckboxsContainer
                style={{
                  paddingTop: "1rem",
                  display: "grid",
                  gridTemplateColumns:"1fr 1fr 1fr",
                  gap: "1.5rem",
                  height: "70%",
                  paddingBottom: "2rem",
                }}
              >
                {/* The Data To Map is the initial resources or activities that the class has. The Checked Checkboxes is the existing resources or activities in the module and the applied filter. */}
                {dataToMap.map((title: any, index: any) => (
                  <div
                    style={{
                      width: "6rem",
                      position: "relative",
                      // background: "red",
                    }}
                    onClick={() => {
                      const updatedResourceTitles = ResourceTitles.includes(
                        title
                      )
                        ? ResourceTitles.filter((item) => item !== title)
                        : [...ResourceTitles, title];

                      setResourceTitles(updatedResourceTitles);
                    }}
                  >
                    <SeletectOptinToggle
                      bgColor={checkboxBgColor}
                      borderColor={checkboxBorderColor}
                      borderRadius={checkboxBorderRadius}
                      theme={theme}
                      type="checkbox"
                      id={title}
                      value={title}
                      checked={ResourceTitles.includes(title)}
                      onChange={() => {
                        const updatedResourceTitles = ResourceTitles.includes(
                          title
                        )
                          ? ResourceTitles.filter((item) => item !== title)
                          : [...ResourceTitles, title];

                        setResourceTitles(updatedResourceTitles);
                      }}
                    />

                    <CheckboxLabel
                      language={language}
                      bgColor={checkboxBgColor}
                      borderColor={checkboxBorderColor}
                      borderRadius={checkboxBorderRadius}
                      color={themeTextColor}
                    >
                      <TypographyRegularDescription
                        text={title}
                        fontSize="0.875rem"
                      />
                    </CheckboxLabel>
                  </div>
                ))}
              </CheckboxsContainer>
              <BtnsDiv>
                <CancelButton
                  name={t("view all.Clear all")}
                  color="#D85D66"
                  borderColor="#D85D66"
                  backgroundColor="transparent"
                  onClickFunction={() => {
                    // Remove The Filter Functionality and close the Popup
                    onClickFilter(existingResourceTitles);
                    onClosePopup();
                  }}
                />

                <ButtonContent
                  onClick={() => {
                    // Apply The Filter Functionality and close the Popup
                    onClickFilter(ResourceTitles);
                    onClosePopup();
                  }}
                  style={{
                    backgroundColor:
                      ResourceTitles.length === 0 ? "#7f858c" : "#5DD3B3",
                    color: ResourceTitles.length === 0 ? "#5e6267" : "#fff",
                    border:
                      ResourceTitles.length === 0
                        ? "none"
                        : "1px solid #5DD3B3",
                  }}
                >
                  {t("view all.Apply")}
                </ButtonContent>
              </BtnsDiv>
            </div>
          )}
        </PopupContentContainer>
      </PopupContainer>
    </FilerPopUpOverlay>
  );
}
