import styled from "styled-components";
import { breakpoints } from "../../theme";

interface OverlapGroupProps {
  notificationactive?: boolean;
}

export const PageHeaderPosition = styled.div`
  width: 100%;
`;
export const PageHeaderHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  /* & > :first-child {
    margin-top: 0.25rem;
  } */
  @media (max-width: calc(${breakpoints.tablets} + 25px)) {
    align-items: start;
  }
`;

export const SearchInput = styled.div`
  width: 23.625rem;
  height: 3.125rem;
  /* border-radius: 6.25rem;
  display: flex;
  align-items: center;
  border: 1px solid #576371;
  opacity: 0.4;
  background-color: transparent;
  background-image: url("assets/icons/Search.svg");
  background-position: 1.69rem 1rem;
  background-repeat: no-repeat;
  padding-left: 3.5rem;
  padding-top: 0.2rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  color: white;
  &:focus {
    outline: none;
    
  } */
  @media (max-width: calc(${breakpoints.tablets} + 47px)) {
    display: none;
  }
`;

export const TextHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.62rem;
  row-gap: 0.62rem;
  flex-wrap: wrap;
  @media (max-width: calc(${breakpoints.mobile} + 25px)) {
    width: 50%;
  }
`;
export const Logomobile = styled.img`
  @media (min-width: calc(${breakpoints.mobile} + 25px)) {
    display: none;
  }
`;
export const TextHeader = styled.div`
  display: flex;
  gap: 0.6rem;
  /* @media (max-width: ${breakpoints.small}) {
    display: none;
  } */
`;
export const SearchIconHolder = styled.div`
  margin-right: 0.5rem;
  @media (min-width: calc(${breakpoints.tablets} + 47px)) {
    display: none;
  }
`;
export const BigTitleStyle = styled.div`
  height: 2.125rem;
  display: flex;
  align-items: center;
`;
export const UserNameStyle = styled.div`
  height: 2.125rem;
  display: flex;
  align-items: center;
  @media (max-width: 534px) {
    display: none;
  }
`;
export const DropdownMenuPosition = styled.div`
  position: absolute;
  z-index: 1000;
  &:dir(ltr) {
    top: 4rem;
  right: 1.5rem;
  }
  &:dir(rtl) {
    top: 4rem;
  left: 1.5rem;
  }
`;
export const LineStyle = styled.div`
  width: 100%;
  display: flex;
`;

export const HeaderRight = styled.div`
  height: 2.125rem;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const RightIconsHolder = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`;
export const IconsHolder = styled.div`
  position: relative;
  display: flex;
  background-color: transparent;
  @media (max-width: calc(${breakpoints.mobile} + 25px)) {
    display: none;
  }
`;
export const MenuScreen = styled.div`
  cursor: pointer;
`;

export const ArrowHolder = styled.div`
  cursor: pointer;
  @media (max-width: calc(${breakpoints.mobile} + 25px)) {
    display: none;
  }
`;
export const ArrowStyle = styled.div`
  /* margin-bottom: 0.18rem; */
`;
export const LxpButton = styled.div`
  cursor: pointer;
  margin-top: 0.2rem;
  @media (max-width: calc(${breakpoints.mobile} + 25px)) {
    display: none;
  }
`;
export const ClosePosition = styled.div`
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
`;

export const SidebarMobilePosition = styled.div`
  position: relative;
  padding: 4.56rem 0 4.5rem 0 !important;
  position: absolute;
  @media (min-width: calc(${breakpoints.mobile} + 25px)) {
    display: none;
  }
`;

export const NotificationPosition = styled.div`
  position: absolute;
  
  z-index: 1000;
  &:dir(ltr) {
    top: 3.6rem;
    right: 4.2rem;
  }
  &:dir(rtl) {
    top: 3.6rem;
    left: 4.2rem;
  }
`;

export const SidebarIcon = styled.div`
  cursor: pointer;
  border-radius: 50%;
  background-color: #384450;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 0.7s ease-in-out, color 0.7s ease-in-out;
  &:hover {
    background-color: white !important;
    color: #384450;
  }
  @media (min-width: calc(${breakpoints.mobile} + 25px)) {
    display: none;
  }
`;
export const OverlapGroup = styled.div<{
  color: string;
  bgcolor: string;
  hovercolor: string;
  bghovercolor: string;
}>`
  color: ${(props) => props.color};
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
  background-color: ${(props) => props.bgcolor};
  cursor: pointer;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: background-color 0.7s ease-in-out, color 0.7s ease-in-out;
  &:hover {
    background-color: ${(props) => props.bghovercolor}!important;
    color: ${(props) => props.hovercolor}!important;
  }
`;
export const ImgStyle = styled.img`
  width: 1rem;
  height: 1rem;
`;

export const RedCircle = styled.div`
background: #D85D66;
position: absolute;
width: 0.75rem;
height: 0.75rem;
flex-shrink: 0;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
top:0.44rem;
right: 0.55rem;
`

export const RedCircle2 = styled.div`
background: #D85D66;
position: absolute;
width: 0.75rem;
height: 0.75rem;
flex-shrink: 0;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
top:0.44rem;
right: 2.75rem;
`

export const ProfileHolder = styled.div<{ bgcolor: string }>`
  cursor: pointer;
  border-radius: 50%;
  background: ${(props) => props.bgcolor}!important;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 0.8rem; */
`;

export const MaskGroup = styled.img`
  border-radius: 2.125rem;
  border: 1px solid #707070;
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 50%;
  object-fit: cover;
`;
