import styled from "styled-components";

interface CourseGeneralTracingAttProps {
  isActive?: boolean;
}

export const CourseGeneralTracingAtt = styled.div<CourseGeneralTracingAttProps>`
  display: flex;
  flex-direction: column;
  gap: 0.31rem;
  height: 100%;
  height: 4.8125rem;
  border-left: 1px solid #576371;
  padding-left: 1.88rem;
  cursor: pointer;
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  &:first-child {
    border-left: none;
  }
`;

export const CourseGeneralTracingText = styled.div`
  margin-top: 0.31rem;
`;
