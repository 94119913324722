import styled from "styled-components";
import { breakpoints } from "../../theme";

export const GlobalWrapper = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.62rem;
  overflow-y: hidden;
  overflow-x: hidden;
`;

export const PageTitle = styled.div`
  margin-bottom: 1.25rem;
  display: flex;
  column-gap: 0.62rem;
  align-items: center;
`;
export const BackArrow = styled.div`
  &:dir(ltr) {
    transform: rotate(0deg);
  }
  &:dir(rtl) {
    transform: rotate(180deg);
  }
`;

export const AddGroupPageWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
  width: 100%;
  height: 100%;
  padding-bottom: 1.88rem;
  position: relative;
  @media (max-width: ${breakpoints.tablets}) {
    flex-direction: column;
  }
`;
