import { InstructorViewCompletionTrackingInterface, Module } from "./TeacherLandingPageInterface";

export const modules: Module[] = [
  {
    id: 1,
    title: "Module 1: Module Name",
    activities: [
      { type: "assignment", name: "Assignment 1", submitted: 20 },
      { type: "survey", name: "Survey 1", submitted: 15 },
      { type: "quiz", name: "Quiz 1", submitted: 25 },
    ],
    resources: [
      { type: "file", name: "File 1", viewed: 5 },
      { type: "video", name: "Video 1", viewed: 30 },
      { type: "image", name: "Image 1", viewed: 10 },
    ],
  },
  {
    id: 2,
    title: "Module 2: Module Name",
    activities: [
      { type: "assignment", name: "Assignment 2", submitted: 18 },
      { type: "survey", name: "Survey 2", submitted: 12 },
      { type: "quiz", name: "Quiz 2", submitted: 22 },
    ],
    resources: [
      { type: "file", name: "File 2", viewed: 10 },
      { type: "video", name: "Video 2", viewed: 15 },
      { type: "image", name: "Image 2", viewed: 20 },
    ],
  },
  {
    id: 3,
    title: "Module 3: Module Name",
    activities: [
      { type: "assignment", name: "Assignment 3", submitted: 15 },
      { type: "survey", name: "Survey 3", submitted: 10 },
      { type: "quiz", name: "Quiz 3", submitted: 20 },
    ],
    resources: [
      { type: "file", name: "File 3", viewed: 30 },
      { type: "video", name: "Video 3", viewed: 40 },
      { type: "image", name: "Image 3", viewed: 5 },
    ],
  },
];





export const InstructorViewCompletionData: InstructorViewCompletionTrackingInterface[] = [
  {id:"1",
  Img: require("../../../assets/AliAhmad.png"),
  FirstName: "Ali ",
  LastName: "Ahmad",
  TimeSpent: "",
  LastAccess: "",
  Grade: "",
  GPA: "",
}
];
