import { Module } from "../../CourseTimeline/utils/CourseResourcesInterface";
import { isDateInRange } from "../isDateInRange";

export function pushToResourceArray(
  resourceArray: any[],
  content: any,
  topicIndex: number,
  moduleIndex: number
) {
  resourceArray.push({
    ...content,
    topicIndex: topicIndex - 1,
    moduleIndex: moduleIndex - 1,
  });
}

// This Function is entering in each module then each Topic then to the content
// and push the content in an array for each content type
export function extractAllActivities(resources: Module[]) {
  const quizActivity: any[] = [];
  const surveyActivity: any[] = [];
  const assignmentActivity: any[] = [];
  const forumActivity: any[] = [];
  const sessionActivity: any[] = [];
  const pollActivity: any[] = [];
  const teamsSessionActivity: any[] = [];


  resources.forEach((module, moduleIndex) => {
    module.topicsArray.forEach((topic, topicIndex) => {
      topic.contents?.forEach((content) => {
        if (
          isDateInRange(content.startDate, content.endDate, content.isVisible)
        ) {
          switch (content.type) {
            case "Assignment":
              pushToResourceArray(
                assignmentActivity,
                content,
                topic.topicIndex,
                module.moduleIndex
              );
              break;
            case "Quiz":
              pushToResourceArray(
                quizActivity,
                content,
                topic.topicIndex,
                module.moduleIndex
              );
              break;
            case "Online_Session":
              pushToResourceArray(
                sessionActivity,
                content,
                topic.topicIndex,
                module.moduleIndex
              );
              break;
            case "Teams":
              pushToResourceArray(
                teamsSessionActivity,
                content,
                topic.topicIndex,
                module.moduleIndex
              );
              break;
            case "Forum":
              pushToResourceArray(
                forumActivity,
                content,
                topic.topicIndex,
                module.moduleIndex
              );
              break;
            case "Survey":
              pushToResourceArray(
                surveyActivity,
                content,
                topic.topicIndex,
                module.moduleIndex
              );
              break;
            case "Poll":
              pushToResourceArray(
                pollActivity,
                content,
                topic.topicIndex,
                module.moduleIndex
              );
              break;
            default:
              break;
          }
        }
      });
    });
  });

  return {
    assignmentActivity,
    quizActivity,
    teamsSessionActivity,
    forumActivity,
    sessionActivity,
    pollActivity,
    surveyActivity
  };
}
