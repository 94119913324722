import React from "react";
import { OneRowHeaderDiv, OneRowViewShapeDiv, RubricDescription, RubricTableLayout, RubricTableLayoutColumn, RubricTableLayoutRow, RuricCratieriaMine, RuricCratieriaText, ShapeDivB, ShapeDivBTextFormat, ShapeDivBTextFormatColumn, } from "./RubricsListTable.style";
import { useRecoilValue } from "recoil";
import { TableColorSelector } from "../../recoil/ThemeSelectors";
import { LightTypography, PrimaryLightNumber, Typography, } from "../../elements/fonts/Fonts";
import { useTranslation } from "react-i18next";

const RubricsListTable = ({ rubricData }: any) => {
  const { Table_nth_child, Table_Head_TR } = useRecoilValue(TableColorSelector);
  const language = localStorage.getItem("language");
  const { t } = useTranslation();
  
  return (
    <>
    <RubricDescription>
        <LightTypography text={rubricData.description} fontSize="0.875rem"/>
    </RubricDescription>
    <RubricTableLayout>
    {Array.from({ length: rubricData.criteria.length + 1 }, (_, i) => (
      <RubricTableLayoutRow
        key={i}
        first_child_bgcolor={Table_Head_TR}
        nth_child_bgcolor={Table_nth_child}
      >
        {Array.from({ length: rubricData.levels.length + 1 }, (_, d) => (
          <RubricTableLayoutColumn key={d}>
            {i === 0 && d === 0 ? (
              <OneRowViewShapeDiv>
                <Typography text={t("Rubric.Criteria / Level")} />
              </OneRowViewShapeDiv>
            ) : i === 0 && d !== 0 ? (
              <OneRowHeaderDiv>
                <Typography text={rubricData.levels[d - 1]?.value} />
              </OneRowHeaderDiv>
            ) : i !== 0 && d === 0 ? (
              <RuricCratieriaText>
                <Typography
                  text={rubricData.criteria[i - 1]?.criteriaName}
                />
                <RuricCratieriaMine>
                  {language === "en" ? (
                    <>
                      <PrimaryLightNumber
                        number={`${
                          rubricData.criteria[i - 1]?.criteriaWeight
                        }%`}
                        fontSize="0.75rem"
                      />
                      <LightTypography
                        text="of total grade"
                        fontSize="0.75rem"
                        lineHeight="1.25rem"
                      />
                    </>
                  ) : (
                    <>
                      <LightTypography
                        text={t("Rubric.of total grade")}
                        fontSize="0.75rem"
                        lineHeight="1.25rem"
                      />
                      <PrimaryLightNumber
                        number={`${
                          rubricData.criteria[i - 1]?.criteriaWeight
                        }%`}
                        fontSize="0.75rem"
                      />
                        <LightTypography
                        text="kadarı"
                        fontSize="0.75rem"
                        lineHeight="1.25rem"
                      />
                    </>
                  )}
                </RuricCratieriaMine>
              </RuricCratieriaText>
            ) : (
              <ShapeDivB>
                {/* <>{console.log("i is  before", i, "d is", d)}</> */}
                <ShapeDivBTextFormat>
                  {/* <>{console.log("i is ", i, "d is", d)}</> */}
                  <ShapeDivBTextFormatColumn>
                    <PrimaryLightNumber
                      fontSize="0.75rem"
                      number={
                        rubricData.boxes.find(
                          (box: any) =>
                            i === box.x_axis && d === box.y_axis
                        )?.boxesWeight
                      }
                    />
                    <LightTypography
                      fontSize="0.75rem"
                      lineHeight="1.25rem"
                      text={
                        rubricData.boxes.find(
                          (box: any) =>
                            i === box.x_axis && d === box.y_axis
                        )?.boxesDescription || ""
                      }
                    />
                  </ShapeDivBTextFormatColumn>
                  {/* <OneRowHeaderDeleteDiv>
                    <SvgDeleteIcon />
                  </OneRowHeaderDeleteDiv> */}
                </ShapeDivBTextFormat>
              </ShapeDivB>
            )}
          </RubricTableLayoutColumn>
        ))}
      </RubricTableLayoutRow>
    ))}
  </RubricTableLayout>
  </>
  );
};

export default RubricsListTable;
