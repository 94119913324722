import { EditModalWrapper, TitleExitRow, InputTitle, TitleLabel, InputDescription, DescLabel, ButtonsRow, ButtonCancel, ButtonAdd, TitleGroupLogo, LeftBorderColor, ModalOverlay, ModalContent } from './EditModal.style'
import { Typography, LightTypography } from '../../../elements/fonts/Fonts';
import { SvgGroupDots, SvgClose } from "../../../elements/Icons";
import React, { useState } from "react";

// interface EditModalProps {
//     onClose?: () => void;
//     // Other props for your modal component
//   }  

const EditModal: React.FC = (): JSX.Element => {
    const[modal, setModal] = useState<boolean>(true);
const toggleModel = () =>{
    setModal((prevModal) => !prevModal);
}
    return (<>
        {modal && (
            <ModalOverlay>
            <ModalContent>
        <EditModalWrapper >

            <TitleExitRow>
                <TitleGroupLogo>
                    <SvgGroupDots />
                    <Typography
                        text='Edit Group'
                        fontSize='1rem'
                        fontWeight='400'
                        lineHeight='normal'
                    />
                </TitleGroupLogo>
                <SvgClose onClick={toggleModel} style={{ cursor: 'pointer' }}/>
            </TitleExitRow>
            <TitleLabel>
            <LightTypography
                        text='Title'
                        fontSize='1rem'
                        fontWeight='400'
                        lineHeight='normal'
                    />
            </TitleLabel>
            <InputTitle placeholder='Group Title' />

            <DescLabel>
            <LightTypography
                        text='Description'
                        fontSize='1rem'
                        fontWeight='400'
                        lineHeight='normal'
                    />
            </DescLabel>
            <InputDescription placeholder='Group Description' />
            <ButtonsRow>
                <ButtonCancel>
                <Typography
                        text='Cancel'
                        color='#5DD3B3'
                        fontSize='0.875rem'
                        fontWeight='400'
                        lineHeight='normal'
                    />
                </ButtonCancel>
                <ButtonAdd>
                <Typography
                        text='Add'
                        color='#fff'
                        fontSize='0.875rem'
                        fontWeight='400'
                        lineHeight='normal'
                    />
                </ButtonAdd>
            </ButtonsRow>


            <LeftBorderColor/>
            
        </EditModalWrapper>
        </ModalContent>
        </ModalOverlay>
        )}
        </>
    )
}

export default EditModal