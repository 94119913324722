import React, { useState } from "react";
import { FlexAppContainer } from "../../../../App.style";
import TitleHeaderTextIconNavigator from "../../../../elements/TitleHeaderTextIconNavigator/TitleHeaderTextIconNavigator";
import CompetencyFrameworksHeader from "./header/CompetencyFrameworksHeader";
import { CompetencyFrameworksInterface } from "../ComopetencyFrameworksData/CompetencyFrameworksInterface";
import { CompetencyFrameworksData } from "../ComopetencyFrameworksData/ComopetencyFrameworksData";
import CompetencyFrameworksTable from "./CompetencyFrameworksTable/CompetencyFrameworksTable";

const CompetencyFrameworksCompo = () => {
  const [FilteredValue, setFilteredValue] = useState<string>("");
  const [TextSearch, setTextSearch] = useState<string>("");
  const [MainData, setMain] = useState<CompetencyFrameworksInterface[]>(
    CompetencyFrameworksData
  );

  return (
    <FlexAppContainer column={true} gap="3.5rem">
      <CompetencyFrameworksHeader
        setFilteredValue={setFilteredValue}
        setTextSearch={setTextSearch}
      />
      <CompetencyFrameworksTable
        Data={MainData}
        FilteredText={FilteredValue}
        TextSearch={TextSearch}
      />
    </FlexAppContainer>
  );
};

export default CompetencyFrameworksCompo;
