import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";
export const PdfViewerStyle = styled.div<StyleThemeInterface>`
  border-radius: 0.625rem;

  height: 110vh;
  overflow-x: hidden;
  width: 100%;
  .rpv-core__icon {
    color:${props =>props.color};
    width: 0.8rem;
    top: 0.5em;
  }
  .rpv-core__textbox {
    width: 2.5rem;
    font-size: 0.8rem;
  }
  .rpv-core__tooltip-body {
    display: none;
    background-color: #384450;
    font-size: 0.8rem;
    /* left: 5rem; */
  }
  .rpv-core__tooltip-body-arrow {
    background-color: #384450;
  }
  .rpv-core__icon {
    top: 0.5em;
  }
 .rpv-core__inner-pages .rpv-core__inner-pages--vertical{
  border-radius: 0 0 0.625rem 0.625rem;
 }
 
 
`;

export const MainPdfViewerStyle = styled.div`
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  display: flex;
  flex-direction: column;
  /* border-radius: 0.625rem 0.625rem 0rem 0rem; */
  height: 100%;
  overflow-x: hidden;
`;
export const FileName = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  justify-content: center;
`;

export const IconsStyling = styled.div`
  /* align-items: center; */
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
`;
export const CustomizedToolbar = styled.div<StyleThemeInterface>`

  align-items: center;
  padding-left: 1rem;
  padding-right: 0.5rem;
  background:${props =>props.bgcolor || "#384450"};
padding:0.7rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  /* padding: 0.25rem; */
  color:${props =>props.color || "white"};
  justify-content: space-between;
  overflow-x: hidden;
  border-radius: 1.25rem 1.25rem 0rem 0rem;
`;
export const ProgressBarStyle = styled.div`
  .circular-progress-bar {
    position: relative;
    width: 120px;
    height: 120px;
  }

  .progress {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .progress-circle {
    position: relative;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
  }

  .progress-mask {
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: transparent;
    clip: rect(0px, 50px, 100px, 0px);
  }

  .progress-fill {
    position: absolute;
    width: 100%;
    height: 100%;
    clip: rect(0px, 50px, 100px, 0px);
    background-color: #4caf50; /* Progress color */
  }

  .progress-mask.full {
    clip: rect(auto, auto, auto, auto);
  }

  .progress-mask.half {
    clip: rect(0px, 50px, 100px, 0px);
  }

  .progress-fill-second {
    transform: rotate(180deg);
  }

  .progress-text {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .percentage {
    font-size: 20px;
  }
`;
