import { getFormattedDate } from "../../utils/GetFormattedDate";

// Function To Format Date
export const formatDate = (createdDate: string, t:any) => {
    const currentDate = new Date();
    const announcementDate = new Date(createdDate);
    const timeDifference = currentDate.getTime() - announcementDate.getTime();
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));
  
    if (minutesDifference < 1) {
      return t("course view.Now");
    } else if (minutesDifference < 60) {
      return `${minutesDifference} ${t("course view.minute")}${minutesDifference > 1 && localStorage.getItem("language") === 'en' ? 's' : ''} ${t("course view.ago")}`;
    } else if (minutesDifference < 1440) { // 1440 minutes in a day (24 hours)
      const hoursDifference = Math.floor(minutesDifference / 60);
      return `${hoursDifference} ${t("course view.hour")}${hoursDifference > 1 && localStorage.getItem("language") === 'en' ? 's' : ''} ${t("course view.ago")}`;
    } else if (minutesDifference < 2880) { // 2880 minutes in two days
      return `${t("course view.Yesterday at")} ${announcementDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      })}`;
    } else {
      return getFormattedDate(announcementDate.toLocaleString());
    }
  };