import React from "react";
import { AnalyticDefaultTable } from "../../../../elements/AnalyticDefaultTable/AnalyticDefaultTable";
import { AnalyticsQuizzesDataB } from "../../../../components/AnalyticsQuizzes/AnalyticsQuizzesDataB";
import { FlexAppContainer } from "../../../../App.style";
import AnalyticsBackPage from "../../../../components/AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticsBackPage";
import { Typography } from "../../../../elements/fonts/Fonts";
import { useNavigate } from "react-router-dom";

const QuizAnalyticsDefaultTable = () => {
  const navigate = useNavigate();
  const NavigatePage = () => {
    navigate(-1);
  };
  return (
    <FlexAppContainer column={true} padding="0 0 2rem 0" gap="1.25rem">
      <AnalyticsBackPage onClick={NavigatePage} />
      <Typography text="Quiz Analytics" fontSize="1.75rem" />
      <AnalyticDefaultTable
        title="Quizzes Details"
        DatabaseTableName={AnalyticsQuizzesDataB}
        // is the table header changes
        MappedHeadText={AnalyticsQuizzesDataB.map((val) => val.Title)}
        LinkedText="QuizDetails"
      />
    </FlexAppContainer>
  );
};

export default QuizAnalyticsDefaultTable;
