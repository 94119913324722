import { FC, useState, useEffect } from "react";
import { breakpoints } from "../../theme";
import {
  CourseMoreDetailsWrapper,
  TitleWithArrowWrapper,
  ArrowWrapper,
  DividLine,
  MainContent,
  DataWrapper,
  DisplayCoursTags,
  ClassContainer,
} from "./CourseMoreDetails.style";
import courseMoreDetailsData from "./courseMoreDetailsData";
import { Typography, LightTypography } from "../../elements/fonts/Fonts";
import IconWithText from "../../elements/iconWithText/main/IconWithText";
import { SvgArrowDownIcon, SvgHoursDurationIcon } from "../../elements/Icons";
import CoursTags from "../coursTags/main/CoursTags";

import { useRecoilValue } from "recoil";
import { courseBannerColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import {
  getArabicHourLabel,
  toArabicDigits,
} from "../../utils/formatDayArabic";

interface CourseMoreDetailsProps {
  classData: any[];
}

const CourseMoreDetails: FC<CourseMoreDetailsProps> = ({ classData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation();

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const { courseBannerBgColor, boxShadow } = useRecoilValue(
    courseBannerColorSelector
  );

  const toggleMainContent = () => {
    setIsOpen((prev) => !prev);
  };

  const getArrowTransform = () => {
    return isOpen ? "rotate(180deg)" : "rotate(0deg)";
  };

  const language = localStorage.getItem("language");

  return (
    <CourseMoreDetailsWrapper
      bgcolor={courseBannerBgColor}
      boxshadow={boxShadow}
      isopen={isOpen.toString()}
    >
      <TitleWithArrowWrapper onClick={toggleMainContent}>
        <Typography
          text={`${t("course view.class_details")} :`}
          fontSize="1.25rem"
        />
        <ArrowWrapper>
          <SvgArrowDownIcon
            style={{
              transform: getArrowTransform(),
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </ArrowWrapper>
      </TitleWithArrowWrapper>

      {windowWidth <= parseInt(breakpoints.tablets) && (
        <MainContent className={isOpen ? "open" : "closed"}>
          <DividLine style={{ marginBottom: "1.25rem" }} />
          <DataWrapper>
            {classData &&
              classData.length > 0 &&
              classData.map((classD) => (
                <ClassContainer>
                  {classData.length > 1 && (
                    <Typography text={classD.title} fontSize="1.125rem" />
                  )}
                  <IconWithText
                    icon={SvgHoursDurationIcon}
                    textElement={
                      <LightTypography
                        text={`${
                          classD.numberOfHours
                            ? language === "ar"
                              ? `${t("course view.Duration")} ${toArabicDigits(
                                  classD.numberOfHours.toString()
                                )} ${getArabicHourLabel(classD.numberOfHours)}`
                              : `${classD.numberOfHours} ${t(
                                  "view all.Hours Duration"
                                )}`
                            : t("view all.No Duration")
                        }`}
                        fontSize="1rem"
                        lineHeight="1.375rem"
                      />
                    }
                    margin="0 0 0 0.45rem"
                  />
                </ClassContainer>
              ))}
          </DataWrapper>
        </MainContent>
      )}

      {windowWidth > parseInt(breakpoints.tablets) && (
        <>
          <DividLine />
          <DataWrapper>
            {classData &&
              classData.length > 0 &&
              classData.map((classD) => (
                <ClassContainer>
                  {classData.length > 1 && (
                    <Typography text={classD.title} fontSize="1.125rem" />
                  )}
                  <IconWithText
                    icon={SvgHoursDurationIcon}
                    textElement={
                      <LightTypography
                        text={`${
                          classD.numberOfHours
                            ? language === "ar"
                              ? `${t("course view.Duration")} ${toArabicDigits(
                                  classD.numberOfHours.toString()
                                )} ${getArabicHourLabel(classD.numberOfHours)}`
                              : `${classD.numberOfHours} ${t(
                                  "view all.Hours Duration"
                                )}`
                            : t("view all.No Duration")
                        }`}
                        fontSize="1rem"
                        lineHeight="1.375rem"
                      />
                    }
                    margin="0 0 0 0.45rem"
                  />
                </ClassContainer>
              ))}
          </DataWrapper>
        </>
      )}
    </CourseMoreDetailsWrapper>
  );
};

export default CourseMoreDetails;
