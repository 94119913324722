import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const PostReplyContainer = styled.div`
  width: 100%;
  /* padding-left:1.25rem; */
  gap: 1.4rem;
  margin-top: 0.6rem;
  display: flex;
`;

export const PRProfilePic = styled.img`
  background: var(
    --background-card,
    linear-gradient(180deg, #415160 0%, #293440 100%)
  );
  padding: 0.22rem;
  width: 3.29375rem;
  height: 3.29375rem;
  border-radius: 50%;
`;

export const PRDivInput = styled.div<StyleThemeInterface>`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  padding: 0.2rem;
  border-radius: 0.625rem;
  border: 1px solid ${(props) => props.bordercolor};
  height: fit-content;
`;

export const PRInput = styled.input<{ color?: string }>`
  width: 100%;

  border: none;
  padding: 1.25rem;
  background-color: transparent;
  /* color: #fff; */
  color: ${(props) => props.color};
  &:focus {
    outline: none; // Remove default focus outline
    border: none; // Remove border on focus
  }
  &::placeholder {
    color: ${(props) => props.color};
    opacity: 0.5;
  }
  &:dir(ltr) {
    padding-right: 5rem !important;
  }
  &:dir(rtl) {
    padding-left: 5rem !important;
  }
`;

export const PRPost = styled.div<StyleThemeInterface>`
  color: #5dd3b3;
  font-size: 0.75rem;
  position: absolute;

  height: 100%;

  top: -0.03rem;
  display: flex;
  padding: 1.25rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  &:dir(ltr) {
    right: 0;
    border-left: 1px solid ${(props) => props.bordercolor};
  }
  &:dir(rtl) {
    left: 0;
    border-right: 1px solid ${(props) => props.bordercolor};
  }
  /* padding-top:1.3rem; */
  /* background-color:yellow; */
`;

export const PRIRepliesCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const PRFilePlace = styled.div`
  color: #5dd3b3;
  font-size: 0.75rem;
  position: absolute;
  /* border-left: 1px solid #576371; */
  height: 100%;
  right: 4.3rem;
  top: -0.02rem;
  display: flex;
  /* padding: 1.25rem; */
  cursor: pointer;
  /* padding-top:1.3rem; */
  /* background-color:red; */
  width: 3.5rem;
  justify-content: center;
  align-items: center;
  color: #5dd3b3;
  /* background-color:yellow; */
`;
export const RepliedImgReader = styled.div`
  max-width: 6.25rem;
  max-height: 5.5rem;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  position: relative;
  background-color: yellow;
`;

export const RepliedImgPlace = styled.img`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  object-fit: cover;
`;

export const RepliedImgDeleted = styled.div`
  display: flex;
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  /* width:1.5rem ;
height:1.5rem ; */
  color: #d85d66;
  /* background-color:black; */
  cursor: pointer;
  padding: 0;
  justify-content: flex-end;
  align-items: right;
`;

export const RepliedImgDeletedPlace = styled.div`
  display: flex;
  width: 100%;
  height: 100;
`;
