import styled from "styled-components";
import { breakpoints } from "../../../theme";
interface CourseLabelsWrapperProps {
  justifycontent?: string;
  isprevdisabled?: boolean
}

export const CourseLabelStyle = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.94rem;color: "#5dd3b3"; */

`;
export const FlexContainer = styled.div`
 display: flex; 
 align-items: center; 
 column-gap: 0.53rem;
 width:100%;
`
export const CourseLabelsHolder = styled.div`

  display: flex;
  align-items: center;
  column-gap: 1.31rem;
  height: 3.5rem;
  white-space: nowrap;
  flex-wrap: wrap;
  width: 70% !important;

  &::-webkit-scrollbar {
    height: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5dd3b3;
    border-radius: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #2d3741;
    border-radius: 0.25rem;
  }
  @media (max-width: calc(${breakpoints.tablets} + 25px )) {
    width: 100% !important;
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  column-gap: 0.5rem;
  margin-top: 0.3rem;
  @media (min-width: 648.2px) {
    display: none;
  }
`;
export const CourseLabelsWrapper = styled.div<CourseLabelsWrapperProps>`
  display: flex;
  align-items: start;
  justify-content: start;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  /* flex-wrap: wrap; */
  @media (min-width: calc(${breakpoints.tablets} + 25px )) {
    justify-content: ${({ justifycontent }) => justifycontent};
    width: 100% !important;
    align-items: center;
  }
`;
export const ButtonStyled = styled.button`
 
            background-color: transparent;
            border: none;
            
            cursor: pointer;
        
         
`