import React, { useEffect, useRef, useState } from "react";
import {
  ComplexSelectBodyTable,
  ComplexSelectTable,
  ComplexSelectTableBodyRow,
  ComplexSelectTableHeaderRow,
  SurveyComplexProfileTableContainer,
} from "../../AnalyticsAllAssigment.style";
import {
  ForumAbsoluteContainer,
  ForumAbsoluteDateContainer,
  ForumParticipationDetailsTableCell,
} from "./ForumParticipationDetailsTable.style";
import {
  CheckboxLabel,
  SeletectOptinToogle,
} from "../../../ProfileCheckBoxOptions/NotificationSetting.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../../../elements/fonts/Fonts";
import { ForumParticipationDetailsTableHeaderData } from "./ForumParticipationDetailsTableHeaderData";
import {
  ComplexSelectTableMessagePopup,
  PointerContainer,
} from "../../SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableTable.style";
import { SvgEmail, SvgMore } from "../../../../elements/Icons";
import { StudentCompletionTracingIMG } from "../../../StudentCompletionTracing/StudentCompletionTracing.style";
import DateBoxPopUp from "../../../../elements/DateBoxPopUp/DateBoxPopUp";
import { formatDate } from "../../../../utils/FormatedDATE";
import AanlyticsMenueThreeOptions from "../../AssignmentSubmissionCompo/AanlyticsMenueThreeOptions";
import DateIndexClickOutSide from "../../../../hooks/DateIndexClickOutSide";
import {
  HandelPenalityYesNo,
  handleDateChangeState,
} from "../../../../utils/AnalyticsProperties";
import {
  handleItemToggle,
  handleSelectAllToggle,
} from "../../../../hooks/TableSelectedOptions";
import useClickOutside from "../../../../hooks/useClickOutside";
import {
  handleChangeShapeToggle,
  updateMessageText,
} from "../../../StudentCompletionTrackingInstrcuterView/functions";
import StudentMessage from "../../../StudentCompletionTrackingInstrcuterView/StudentMessage";
import { InstructorMessageBox } from "../../../StudentCompletionTrackingInstrcuterView/InstructorViewCompletion.style";
import { SelectedStudentsInterface } from "../../../InstructorAnalyticsUsersView/InstructorAnalyticsUsersViewTable/InstructorAnalyticsUsersViewTable";

/**
 * ForumParticipationDetailsTable Component
 *
 * This component renders a table displaying forum participation details.
 * It provides options for filtering, selecting items, and managing due/cutoff dates.
 *
 * @param {Array} filteredData - The array containing filtered participation details data.
 * @param {boolean} Graded - Optional. Indicates whether participation is graded.
 * @param {boolean} Anonymous - Optional. Indicates whether participation is anonymous.
 *
 * @returns {JSX.Element} The JSX representation of the ForumParticipationDetailsTable component.
 */
interface ForumParticipationDetailsTableProps {
  filteredData: any[];
  Graded?: boolean;
  Anonymous?: boolean;
  ForumDueDate?: Date;
  ForumCutOffEDate?: Date;
}

const ForumParticipationDetailsTable: React.FC<
  ForumParticipationDetailsTableProps
> = ({
  filteredData,
  Graded = false,
  Anonymous = true,
  ForumDueDate,
  ForumCutOffEDate,
}: ForumParticipationDetailsTableProps) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(
    null
  );
  const menuRef = useRef<HTMLDivElement>(null);
  const dateBoxRef = useRef<HTMLDivElement>(null);
  const Today = new Date();
  const [shapeDueDateToggles, setShapeDueDateToggles] = useState<
    { isOpen: boolean; selectedDate: Date | null }[]
  >(filteredData.map(() => ({ isOpen: false, selectedDate: null })));
  const [ShapeCutOffDateToggles, setShapeCutOffDateToggles] = useState<
    { isOpen: boolean; selectedDate: Date | null }[]
  >(filteredData.map(() => ({ isOpen: false, selectedDate: null })));

  // Function for handling button click to display options menu
  const handleOptionButtonClick = (index: number) => {
    setSelectedOptionIndex((prevIndex) => (prevIndex === index ? null : index));
    setShowFirstPOP(false);
    setShowFirstCutOffDATEPOP(false);
    setShowFirstDueDatePOP(false);
  };
  // Function for handling opening/closing due date options
  const handleOpenDueDate = (index: number) => {
    handleDateChangeState(
      index,
      setShapeDueDateToggles,
      selectedOptionIndex,
      setSelectedOptionIndex,
      filteredData
    );
  };

  // Function for handling opening/closing cutoff date options
  const handleOpenCutOffDate = (index: number) => {
    handleDateChangeState(
      index,
      setShapeCutOffDateToggles,
      selectedOptionIndex,
      setSelectedOptionIndex,
      filteredData
    );
  };

  const clickOutsideRef = DateIndexClickOutSide(
    () => setSelectedOptionIndex(null),
    [menuRef, dateBoxRef]
  );

  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  // Initialize an array to track the checked state of each item
  const [checkedItems, setCheckedItems] = useState<boolean[]>(
    new Array(filteredData.length).fill(false)
  );
  // this useState stored the the selected students in ana array
  const [SelectedStudents, setSelectedStudents] = useState<any[]>([]);

  const [ShowFirstPOP, setShowFirstPOP] = useState<boolean>(false);
  const [ShowMessagesInFirst, setShowMessagesInFirst] =
    useState<boolean>(false);

  const handleShowShowFirstPOP = () => {
    setShowFirstPOP(!ShowFirstPOP);
  };

  useEffect(() => {
    if (SelectedStudents.length === 0) {
      setShowMessagesInFirst(false);
    } else {
      setShowMessagesInFirst(true);
    }
  }, [SelectedStudents]);
  useState<boolean>(false);

  const [ShowFirstCutOffDATEPOP, setShowFirstCutOffDATEPOP] =
    useState<boolean>(false);
  const [MainCutOffDATE, setMainCutOffDATE] = useState<Date | null>(null);

  const handleShowFirstCutOffPOP = () => {
    setShowFirstPOP(false);
    setShowFirstCutOffDATEPOP(!ShowFirstCutOffDATEPOP);
  };

  const [ShowFirstDueDatePOP, setShowFirstDueDatePOP] =
    useState<boolean>(false);
  const [MainDueDate, setMainDueDate] = useState<Date | null>(null);

  const handleShowShowFirstDueDatePOP = () => {
    setShowFirstPOP(false);
    setShowFirstDueDatePOP(!ShowFirstDueDatePOP);
  };
  const MainContainerRef = useClickOutside(ShowFirstPOP, () =>
    setShowFirstPOP(false)
  );

  const [shapeToggles, setShapeToggles] = useState<Array<boolean>>(
    Array(filteredData.length).fill(true)
  );

  const [messageTexts, setMessageTexts] = useState<{ [id: string]: string }>(
    {}
  );
  const [ErrormessageTexts, setErrorMessageTexts] = useState<{
    [id: string]: string;
  }>({});

  const handleMessageSend = (index: number, id: string) => {
    if (messageTexts[id] === undefined || messageTexts[id].trim() === "") {
      // If messageTexts[id] is undefined or empty, set the errorMessageTexts for the specific id
      setErrorMessageTexts((prevState) => ({
        ...prevState,
        [id]: "This field is required.",
      }));
      return;
    }

    const Data = {
      id: id,
      // MessageValue: messageSubjects,
      // File: FileStorageId,
      MessageText: messageTexts,
    };
  };
  const [ShowDisplayMessagesForm, setShowDisplayMessagesForm] =
    useState<boolean>(false);
  // useState<string>("");
  const [GroupSelectedMessageTexts, setGroupSelectedMessageTexts] =
    useState<string>("");
  const [GroupSelectedErrorMessageTexts, setGroupSelectedErrorMessageTexts] =
    useState<string>("");
  const handleSelectedGroupMessageSend = () => {
    if (GroupSelectedMessageTexts.trim() === "") {
      setGroupSelectedErrorMessageTexts("This field is required.");
      return false;
    }

    const Data = {
      // MessageValue: GroupSelectedMessageSubjects,
      MessageText: GroupSelectedMessageTexts,
      // File: GroupSelectedFile,
    };
    setGroupSelectedMessageTexts("");
  };

  const handleShowMessagePOP = () => {
    setShowDisplayMessagesForm(!ShowDisplayMessagesForm);
  };
  const SelectedStudentsData: SelectedStudentsInterface[] =
    SelectedStudents &&
    SelectedStudents.map((item) => ({
      id: item.id,
      FullName: `${item.Name}`, // Corrected syntax
      email: item.Email, // Changed 'items' to 'item' and corrected capitalization
    }));

  return (
    <ComplexSelectTable>
      <ComplexSelectTableHeaderRow>
        <ForumParticipationDetailsTableCell>
          <SeletectOptinToogle
            type="checkbox"
            id="selectAll" // Add optional chaining to handle potential undefined
            value="selectAll" // Add optional chaining to handle potential undefined
            checked={selectAllChecked} // Control the checked state of the "Select All" checkbox
            onChange={() =>
              handleSelectAllToggle(
                setSelectAllChecked,
                setCheckedItems,
                filteredData,
                setSelectedStudents
              )
            }
          />
          <CheckboxLabel htmlFor="selectAll">
            <MediumTypography
              text={Anonymous ? "Submission ID" : "Student"}
              fontSize="1rem"
              lineHeight="1.25rem"
            />
          </CheckboxLabel>
        </ForumParticipationDetailsTableCell>
        {ForumParticipationDetailsTableHeaderData.map((header) =>
          Graded === false && header.text === "Apply Penalty"
            ? null
            : (
                <ForumParticipationDetailsTableCell key={header.text}>
                  <MediumTypography
                    text={header.text}
                    fontSize="1rem"
                    lineHeight="1.25rem"
                  />
                </ForumParticipationDetailsTableCell>
              ) ||
              (Graded && (
                <ForumParticipationDetailsTableCell key={header.text}>
                  <MediumTypography
                    text={header.text}
                    fontSize="1rem"
                    lineHeight="1.25rem"
                  />
                </ForumParticipationDetailsTableCell>
              ))
        )}

        <ForumParticipationDetailsTableCell
          style={{
            flex: Graded ? 0.9 : 0.4,
            justifyContent: Graded ? "space-between" : "flex-end",
          }}
          ref={MainContainerRef}
        >
          {Graded && (
            <MediumTypography
              text="Grade"
              fontSize="1rem"
              lineHeight="1.25rem"
            />
          )}
          <PointerContainer onClick={handleShowShowFirstPOP}>
            <SvgMore style={{ transform: "rotate(90deg)" }} />
          </PointerContainer>
          {ShowFirstPOP && Graded && (
            <ForumAbsoluteContainer>
              <AanlyticsMenueThreeOptions
                handleSaveCutOffDate={handleShowFirstCutOffPOP}
                handleSaveDueDate={handleShowShowFirstDueDatePOP}
                ShowMessageLabel={ShowMessagesInFirst}
                handleSendMessage={handleShowMessagePOP}
              />
            </ForumAbsoluteContainer>
          )}
          {ShowFirstPOP && !Graded && ShowMessagesInFirst && (
            <ComplexSelectTableMessagePopup
              style={{
                top: "2.2rem",
              }}
              ref={menuRef}
              onClick={handleShowMessagePOP}
            >
              <SvgEmail width={"1.3125rem"} height={"1rem"} />
              <MediumTypography text="Send FeedBack" fontSize="0.75rem" />
            </ComplexSelectTableMessagePopup>
          )}
          {ShowDisplayMessagesForm && (
            <InstructorMessageBox
              ref={clickOutsideRef}
              style={{ right: "1.5rem", top: "-2rem", opacity: "1" }}
            >
              <StudentMessage
                CloseClick={handleShowMessagePOP}
                OneSelected={false}
                SelectedData={SelectedStudentsData}
                SendMessage={handleSelectedGroupMessageSend}
                MessageValue={GroupSelectedMessageTexts}
                setMessageValue={(value: React.SetStateAction<string>) =>
                  setGroupSelectedMessageTexts(value)
                }
                ErrorMessageValue={GroupSelectedErrorMessageTexts}
                setErrorMessageValue={setGroupSelectedErrorMessageTexts}
              />
            </InstructorMessageBox>
          )}
          {ShowFirstDueDatePOP && (
            <ForumAbsoluteDateContainer>
              <DateBoxPopUp
                TextForAxios={"ForumExtendDueDate"}
                TitleHeader="Extend Due Date"
                DataBaseStartDate={ForumDueDate || Today}
                selectedDate={MainDueDate || null}
                setSelectedDate={setMainDueDate}
                setShowDateBox={setShowFirstDueDatePOP}
                ShowDateBox={ShowFirstDueDatePOP}
              />
            </ForumAbsoluteDateContainer>
          )}
          {ShowFirstCutOffDATEPOP && (
            <ForumAbsoluteDateContainer>
              <DateBoxPopUp
                TextForAxios={"ForumExtendOffDate"}
                TitleHeader="Extend Cut Off Date"
                DataBaseStartDate={ForumCutOffEDate || Today}
                selectedDate={MainCutOffDATE || null}
                setSelectedDate={setMainCutOffDATE}
                setShowDateBox={setShowFirstCutOffDATEPOP}
                ShowDateBox={ShowFirstCutOffDATEPOP}
              />
            </ForumAbsoluteDateContainer>
          )}
        </ForumParticipationDetailsTableCell>
      </ComplexSelectTableHeaderRow>
      <ComplexSelectBodyTable>
        {filteredData.map((item, index) => (
          <ComplexSelectTableBodyRow key={index}>
            <ForumParticipationDetailsTableCell>
              <SeletectOptinToogle
                type="checkbox"
                id={item.StdCode?.toString()}
                value={item.StdCode?.toString()}
                checked={checkedItems[index]}
                onChange={() =>
                  handleItemToggle(
                    index,
                    checkedItems,
                    setCheckedItems,
                    setSelectedStudents,
                    filteredData
                  )
                }
              />
              <CheckboxLabel
                htmlFor={item.StdCode.toString()}
                style={{ marginTop: "0.8rem" }}
              >
                {Anonymous ? (
                  <Typography
                    text={item.StdCode.toString() || "e3rtfds"}
                    fontSize="0.875rem"
                  />
                ) : (
                  <SurveyComplexProfileTableContainer>
                    <StudentCompletionTracingIMG
                      src={
                        item.Pic
                          ? item.Pic
                          : require("../../../../assets/userProfile.png")
                      }
                      alt="no img"
                    />
                    <Typography text={item.Name || ""} fontSize="0.875rem" />
                  </SurveyComplexProfileTableContainer>
                )}
              </CheckboxLabel>
            </ForumParticipationDetailsTableCell>
            <ForumParticipationDetailsTableCell>
              <LightTypography
                text={item.Posts?.toString() || 0}
                fontSize="0.75rem"
              />
            </ForumParticipationDetailsTableCell>
            <ForumParticipationDetailsTableCell>
              <LightTypography
                text={item.Replies?.toString() || 0}
                fontSize="0.75rem"
              />
            </ForumParticipationDetailsTableCell>
            <ForumParticipationDetailsTableCell>
              <LightTypography
                // text={formatDate(item?.DueDate as Date)}
                text={formatDate(item?.Last_Participation as Date)}
                fontSize="0.75rem"
              />
            </ForumParticipationDetailsTableCell>
            {Graded && (
              <ForumParticipationDetailsTableCell>
                <LightTypography
                  text={HandelPenalityYesNo(item.Apply_penalty)}
                  fontSize="0.75rem"
                />
              </ForumParticipationDetailsTableCell>
            )}

            <ForumParticipationDetailsTableCell
              style={{
                flex: Graded ? 0.9 : 0.4,
                justifyContent: Graded ? "space-between" : "flex-end",
              }}
              ref={clickOutsideRef}
            >
              {Graded && (
                <div style={{ marginLeft: "0.5rem" }}>
                  <LightTypography
                    text={`${item.Grade}/10` || "-/10"}
                    fontSize="0.75rem"
                  />
                </div>
              )}
              <PointerContainer onClick={() => handleOptionButtonClick(index)}>
                <SvgMore style={{ transform: "rotate(90deg)" }} />
              </PointerContainer>
              {selectedOptionIndex === index &&
                Graded &&
                shapeToggles[index] && (
                  <ForumAbsoluteContainer ref={clickOutsideRef}>
                    <AanlyticsMenueThreeOptions
                      handleSaveCutOffDate={() => handleOpenCutOffDate(index)}
                      handleSaveDueDate={() => handleOpenDueDate(index)}
                      handleSendMessage={() =>
                        handleChangeShapeToggle(
                          index,
                          shapeToggles,
                          setShapeToggles
                        )
                      }
                    />
                  </ForumAbsoluteContainer>
                )}
              {selectedOptionIndex === index &&
                !Graded &&
                shapeToggles[index] && (
                  <ComplexSelectTableMessagePopup
                    style={{
                      top: "2.2rem",
                    }}
                    ref={clickOutsideRef}
                    onClick={() =>
                      handleChangeShapeToggle(
                        index,
                        shapeToggles,
                        setShapeToggles
                      )
                    }
                  >
                    <SvgEmail width={"1.3125rem"} height={"1rem"} />
                    <MediumTypography text="Send FeedBack" fontSize="0.75rem" />
                  </ComplexSelectTableMessagePopup>
                )}
              {selectedOptionIndex === index &&
                shapeToggles[index] === false && (
                  <InstructorMessageBox
                    ref={clickOutsideRef}
                    style={{ right: "1.5rem", top: "-2rem" }}
                  >
                    <StudentMessage
                      CloseClick={() =>
                        handleChangeShapeToggle(
                          index,
                          shapeToggles,
                          setShapeToggles
                        )
                      }
                      ReserverName={item.Name}
                      SendMessage={() => handleMessageSend(index, item.StdCode)}
                      MessageValue={messageTexts[item.StdCode] || ""}
                      setMessageValue={(value: string) =>
                        updateMessageText(item.StdCode, value, setMessageTexts)
                      } // Update the value for the specific id
                      ErrorMessageValue={ErrormessageTexts[item.StdCode] || ""}
                      setErrorMessageValue={(value: string) =>
                        updateMessageText(
                          item.StdCode,
                          value,
                          setErrorMessageTexts
                        )
                      }
                    />
                  </InstructorMessageBox>
                )}

              {selectedOptionIndex === index &&
              shapeDueDateToggles[index].isOpen ? (
                <ForumAbsoluteContainer ref={menuRef}>
                  <ForumAbsoluteDateContainer>
                    <DateBoxPopUp
                      IdForAxios={item.StdCode}
                      TextForAxios={"ForumExtendDueDate"}
                      TitleHeader="Extend Due Date"
                      DataBaseStartDate={ForumDueDate || Today}
                      ShowDateBox={shapeDueDateToggles[index].isOpen}
                      selectedDate={shapeDueDateToggles[index].selectedDate}
                      setShowDateBox={(value) => {
                        setShapeDueDateToggles((prevToggles) => {
                          const updatedToggles = [...prevToggles];
                          updatedToggles[index] = {
                            isOpen:
                              typeof value === "function"
                                ? value(prevToggles[index].isOpen)
                                : value,
                            selectedDate: prevToggles[index].selectedDate,
                          };
                          return updatedToggles;
                        });
                      }}
                      setSelectedDate={(date: any) => {
                        setShapeDueDateToggles((prevToggles) => {
                          const updatedToggles = [...prevToggles];
                          updatedToggles[index] = {
                            isOpen: prevToggles[index].isOpen,
                            selectedDate: date,
                          };
                          return updatedToggles;
                        });
                      }}
                    />
                  </ForumAbsoluteDateContainer>
                </ForumAbsoluteContainer>
              ) : null}

              {selectedOptionIndex === index &&
                ShapeCutOffDateToggles[index].isOpen && (
                  <div ref={menuRef}>
                    <ForumAbsoluteDateContainer>
                      <DateBoxPopUp
                        IdForAxios={item.StdCode}
                        DataBaseStartDate={ForumCutOffEDate || Today}
                        TextForAxios={"ForumCutOffDate"}
                        TitleHeader="Cut Off Date"
                        ShowDateBox={ShapeCutOffDateToggles[index].isOpen}
                        setShowDateBox={(value) => {
                          setShapeCutOffDateToggles((prevToggles) => {
                            const updatedToggles = [...prevToggles];
                            updatedToggles[index] = {
                              isOpen:
                                typeof value === "function"
                                  ? value(prevToggles[index].isOpen)
                                  : value,
                              selectedDate: prevToggles[index].selectedDate,
                            };
                            return updatedToggles;
                          });
                        }}
                        selectedDate={
                          ShapeCutOffDateToggles[index].selectedDate
                        }
                        setSelectedDate={(date: any) => {
                          setShapeCutOffDateToggles((prevToggles) => {
                            const updatedToggles = [...prevToggles];
                            updatedToggles[index] = {
                              isOpen: prevToggles[index].isOpen,
                              selectedDate: date,
                            };
                            return updatedToggles;
                          });
                        }}
                      />
                    </ForumAbsoluteDateContainer>
                  </div>
                )}
            </ForumParticipationDetailsTableCell>
          </ComplexSelectTableBodyRow>
        ))}
      </ComplexSelectBodyTable>
    </ComplexSelectTable>
  );
};

export default ForumParticipationDetailsTable;
