import styled from 'styled-components'
import { StyleThemeInterface } from '../../../interfaces/StyleThemeInterface';

export const CalendarToolConatiner=styled.div`
display:flex;
justify-content:space-between;
align-items:center;

`;

export const MonthYearContainer=styled.div`
display: flex;
align-items:center;
gap:3.75rem;
max-width:22rem;
min-width:22rem;
`;

export const ArrowToolContainer=styled.div<StyleThemeInterface>`
display:flex;
justify-content:space-between;
align-items:center;
gap:1.25rem;
color:${props =>props.color};
`;

export const CalendarButtonTool=styled.button<{view:any}>`
  width: 6.1875rem;
  height: 2.625rem;
  background: transparent;
  border-radius: 1.25rem;
  border:none;
 
  
  /* &&:focus {
    background: ${(props) =>
      props.view === 'month' ||
      props.view === 'week' ||
      props.view === 'day' ||
      props.view === 'agenda'
        ? 'linear-gradient(50deg, #5dd3b3 3.83%, #2f6a5a 95.8%)'
        : 'transparent'};
  } */
`;