import styled from "styled-components";

interface ProfileImageHolderProps {
  widthimgholder: string;
  heightimgholder: string;
  profilebackcolor?: string;
}

interface MaskGroupProps {
  top: string;
  left: string;
  width: string;
  height: string;
  border: string;
  ImgBorderColor?: string;
}

export const ProfileImageHolder = styled.div<ProfileImageHolderProps>`
  width: ${({ widthimgholder }) => widthimgholder};
  height: ${({ heightimgholder }) => heightimgholder};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  background: ${(props) =>
    props.profilebackcolor || "url(#paint0_linear_915_79747)"} !important;
`;

export const BgProfile = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const MaskGroup = styled.img<MaskGroupProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: ${({ border }) => border};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  border-radius: 50%;
  z-index: 2;
  object-fit: cover;
  /* border:1px solid ${(props) => props.ImgBorderColor || "#707070"} */
`;
