import React, { FC, useState } from 'react';
import { TypographyRegularDescription } from '../../../elements/fonts/Fonts';
import { QuestionFilterContainer, QuestionFilterFlex, CheckBox,FilterLine } from './QuestionsFilter.style'
import { QuestionFilterProps } from './QuestionFilterInterface';
const QuestionsFilter: FC<QuestionFilterProps> = ({selectedRandomOption,selectedRandomQuestion,handleRandomQuestion,handleRandomOptions}) => {


    return (
        <>
        {/* <QuestionFilterContainer>
            <QuestionFilterFlex>
                <CheckBox type='checkbox' checked={selectedRandomQuestion===true} onChange={() => handleRandomQuestion()} />
                <TypographyRegularDescription text='Randomize questions' fontSize='0.75rem' lineHeight='1.125rem' />
            </QuestionFilterFlex>
            <QuestionFilterFlex>
                <CheckBox type='checkbox' checked={selectedRandomOption} onChange={() => handleRandomOptions()} />
                <TypographyRegularDescription text='Randomize options within questions' fontSize='0.75rem' lineHeight='1.125rem' />
            </QuestionFilterFlex>
        </QuestionFilterContainer> 
          <FilterLine/>*/}
        </>
    )
}

export default QuestionsFilter;