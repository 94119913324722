import React from "react";
import {
  AnalyticFilterLine,
  AnalyticRefreshIcon,
  ComplexSelectGreenBox,
} from "../../AnalyticsAllAssigment.style";
import { MediumTypography } from "../../../../elements/fonts/Fonts";
import { SvgRefreshIcon, SvgReset } from "../../../../elements/Icons";

interface ForumParHeaderFilterProps {
  setStatusSearchText: React.Dispatch<React.SetStateAction<string>>;
}

const ForumParHeaderFilter: React.FC<ForumParHeaderFilterProps> = ({
  setStatusSearchText,
}) => {
  const handleStatusSearchText = (text: string) => {
    if (text === "Participants") {
      setStatusSearchText("Participants");
    } else if (text === "Not Participants") {
      setStatusSearchText("Not Participants");
    } else if (text === "Participants after Date") {
      setStatusSearchText("Participants after Date");
    } else {
      setStatusSearchText("");
    }
  };

  return (
    <AnalyticFilterLine>
      <ComplexSelectGreenBox
        onClick={() => handleStatusSearchText("Participants")}
      >
        <MediumTypography text="Participants" />
      </ComplexSelectGreenBox>
      <ComplexSelectGreenBox
        onClick={() => handleStatusSearchText("Not Participants")}
      >
        <MediumTypography text="Not Participants" />
      </ComplexSelectGreenBox>
      <ComplexSelectGreenBox
        onClick={() => handleStatusSearchText("Participants after Date")}
      >
        <MediumTypography text="Participants after Date" />
      </ComplexSelectGreenBox>
      <AnalyticRefreshIcon onClick={() => handleStatusSearchText("")}>
        <SvgRefreshIcon />
      </AnalyticRefreshIcon>
    </AnalyticFilterLine>
  );
};

export default ForumParHeaderFilter;
