import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const RubricPageeLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  height: 60vh;
`;
export const RubricTableLayout = styled.div`
  display: flex;
  flex: 100%;
  flex-direction: column;
  align-content: flex-start;
  /* background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%)); */
  background: transparent;
`;

export const RubricTableLayoutRow = styled.div<StyleThemeInterface>`
  flex: 100%;
  display: flex;
  /* gap: 3rem; */

  &:first-child{
background: ${props => props.first_child_bgcolor};
padding: 0.625rem 0;
max-height: 3.75rem;
  }

  &:nth-child(6n + 3), &:nth-child(6n + 5) {
    background: ${props => props.nth_child_bgcolor};
  }
`;

export const RubricTableLayoutColumn = styled.div`
  flex: 100%;
  display: flex;
  flex-direction: column;
  /* gap:0.5rem;
padding-right:0.5rem ; */
`;

export const OneRowViewShapeDiv = styled.div`
  display: flex;
  height: 2rem;
  padding: 1.25rem;
  align-items: center;
`;

export const OneRowHeaderDiv = styled.div`
  display: flex;
  height: 2rem;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding: 1.25rem 0.5rem;
`;

export const RuricCratieriaText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.63rem;
  padding: 1.25rem;
`;

export const RuricCratieriaMine = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ShapeDivB = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  flex: 100%;
  cursor: pointer;
`;

export const ShapeDivBTextFormat = styled.div`
  /* display:flex;
gap:0.60rem; */
  width: 90%;
  /* gap:0.70rem;
justify-content:space-between; */
`;

export const ShapeDivBTextFormatColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  width: 100%;
  justify-content: space-between;
  padding: 1.25rem 0.5rem;
  margin: 0;
`;

export const TopContent = styled.div`
  margin: 0 1.25rem;
`;
export const ButtonsCont = styled.div`
  display: flex;
  justify-content: flex-end;
  &:dir(ltr) {
    margin-right: 1.88rem;
  }
  &:dir(rtl) {
    margin-left: 1.88rem;
  }
`;
export const LoaderDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem;
`;
