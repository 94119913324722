import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import ReusableInput from "../../elements/reusableInput/ReusableInput";
import {
  useState,
  useEffect,
  useRef,
  Key,
  ReactElement,
  ReactNode,
  ReactPortal,
  JSXElementConstructor,
} from "react";
import {
  ActionsDiv,
  FilterBtnsDiv,
  FiltersWithTitle,
  LogoAndAction,
  PaginationContainer,
  ProfileContainer,
  ProfileImageHolder,
  SearchAndLogosDiv,
  TableContent,
  TableContentHolder,
  TableSection,
  TableTbody,
  TableTd,
  TableTd1,
  TableTh,
  TableTh1,
  TableThead,
  TableTr,
  TableTr1,
  TitleSearchRow,
} from "./GradesItem.style";
import { SvgDownload, SvgEyeIcon } from "../../elements/Icons";
import { SegmentTitleData, TableTrData, classData } from "./gradesItemData";
import SelectInput from "../../elements/SelectInput/SelectInput";
import Pagination from "../../elements/Pationation/Pagination";
import * as XLSX from "xlsx";
import { ButtonFilledWrapper } from "../../elements/FiltersButtonFilled/FiltersButtonFilled.style";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../utils/StringManipulation";
import { get } from "../../utils/AxiosRequests";
import React from "react";
import { FlexDiv } from "../../globalStyles";
import userProfile from "../../assets/userProfile.png";
import { useRecoilValue } from "recoil";
import { ColorSelector, TableColorSelector } from "../../recoil/ThemeSelectors";
import { theme } from "../../theme";
import SelectDropDown from "../../elements/selectDropDown/SelectDropDown";
import { formatOptionsForTranslation } from "../../utils/FormatOptionsForTranslation";
import { useTranslation } from "react-i18next";
import TableViewSelectInput from "../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/TableViewSelectInput";
import { ViewsOptionsTranslated, handleNumberDisplays, handleNumberDisplaysWithoutPagination } from "../../utils/NbOfViewesObjects";
import TitleHeaderTextIconNavigator from "../../elements/TitleHeaderTextIconNavigator/TitleHeaderTextIconNavigator";
import { toArabicDigits } from "../../utils/formatDayArabic";
const GradesItem = () => {
  const [formInputValue, setFormInputValue] = useState("");
  const [gradeData, setGradeData] = useState<any[]>([]);
  const { t } = useTranslation();
  const [AllUsers, setAllUsers] = useState<any[]>([]);
  const [role, setRole] = useState("");
  const tenantName = localStorage.getItem("tenant");
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const id = useParams();
  const classId = id.classId;
  useEffect(() => {
    const FetchRole = async () => {
      try {
        const response = await axios.get(
          `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/enrollments/user/role/${tenantName}/${id.classId}/${subIdSplitted}?provider=${provider}`
        );
        setRole(response.data.data);
      } catch (error) {
        console.error("Error fetching gradebook data:", error);
      }
    };
    FetchRole();
  }, []);

  const fetchUsersData = async () => {
    try {
      const response = await get(`participant/class/${classId}/${tenantName}`);

      setAllUsers(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  // Filtered students array containing only one student with id "student1"

  useEffect(() => {
    const fetchGradebookData = async () => {
      try {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/gradebook/grades/${tenantName}/${id.classId}`
        );
        const filteredData = response.data.data.filter(
          (item: { activityType: string }) => item.activityType !== "Attendance"
        );
        setGradeData(filteredData);
      } catch (error) {
        console.error("Error fetching gradebook data:", error);
      }
    };

    const fetchMainActivityData = async () => {
      try {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/activities/weight/${id.classId}/${tenantName}`
        );
      } catch (error) {
        console.error("Error fetching main activity data:", error);
      }
    };

    fetchGradebookData();
    fetchMainActivityData();
    fetchUsersData();
  }, [tenantName, id]);

  const countColor = (count: number) => {
    if (count >= 70) return "#5DD3B3";
    else if (count < 70 && count >= 50) return "#CED35D";
    else if (count < 50) return "#D85D66";
  };
  const [limit, setLimit] = useState(classData.length); // Initialize limit with 10
  const itemsPerPage = classData.slice(0, limit).length;
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentStudents = classData.slice(startIndex, endIndex);

  const [currentStudent, setCurrentStudent] = useState(currentStudents);

  // console.log(filteredStudents)
  // console.log(formInputValue)
  function s2ab(s: string): ArrayBuffer {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }
  const downloadExcel = () => {
    const newData = classData.map((row, index) => {
      const studentData: { [key: string]: string } = {}; // Object to store student data

      // Loop through each activity and get the student's grades
      Object.keys(TableTrData[0]).forEach((key) => {
        if (key !== "STUDENT") {
          // Extracting student grades for each activity
          const grade = (TableTrData[0] as any)[key][index].studentgrade; // Using 'any' to bypass type check due to dynamic key
          studentData[key] = grade;
        }
      });

      return {
        Name: row.studentName,
        ...studentData, // Add other session statuses dynamically
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(newData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Students");

    // Add Course Data and Title Data to the worksheet
    const titleDataSheet = XLSX.utils.json_to_sheet(TableTrData);
    XLSX.utils.book_append_sheet(workbook, titleDataSheet, "TitleData");

    const courseDataSheet = XLSX.utils.json_to_sheet([TableTrData]);
    XLSX.utils.book_append_sheet(workbook, courseDataSheet, "CourseData");

    // Convert workbook to a data URL
    const excelData = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "binary",
    });
    const dataBlob = new Blob([s2ab(excelData)], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a temporary URL and create a link element to trigger the download
    const downloadLink = window.URL.createObjectURL(dataBlob);
    const link = document.createElement("a");
    link.href = downloadLink;
    link.download = "Grades Item.xlsx";
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadLink);
  };
  const [moduleKey, setModuleKey] = useState([""]);

  useEffect(() => {
    const keys = Object.keys(classData[0]); // Get keys of the first object in classData
    if (keys.length > 0) {
      setModuleKey(keys); // Set the first key as moduleKey
    }
  }, [classData]);
  const [showByModule, setShowByModule] = useState(false); // State for 'Group by Module' button
  const [showByActivity, setShowByActivity] = useState(true); // State for 'Group by Activity' button
  const [ViewText, setViewText] = useState("View All");
  // Function to toggle display by module
  const toggleDisplayByModule = () => {
    setShowByModule(true);
    setShowByActivity(false);
  };

  // Function to toggle display by activity
  const toggleDisplayByActivity = () => {
    setShowByActivity(true);
    setShowByModule(false);
  };

  const rowStyle = (index: number) => ({
    background: index % 2 === 1 ? "rgba(255, 255, 255, 0.03)" : "transparent",
    border: "none",
  });

  const transformToHundredBasedGrade = (score: number, maxGrade: number) => {
    if (maxGrade === 0) return 0;
    return (score / maxGrade) * 100;
  };
  const { Table_Head_TR, Table_CellBorder, Table_nth_child, theme } =
    useRecoilValue(TableColorSelector);
    const [ShowPagination, setShowPagination] = useState(false);
    const handleNumberShows = (newValue: string) => {
      handleNumberDisplays(
        newValue,
        setViewText,
        setLimit,
        classData,
        setShowPagination
      );
    };
    const translatedEnrollmentOptions = ViewsOptionsTranslated(); 


    const handleInputChange = (newValue :any) => {
      setFormInputValue(newValue);
    };


    const filteredUsers = AllUsers.filter((user) =>
      user?.username?.toLowerCase().includes(formInputValue.toLowerCase())
    || user.sourceId.toLocaleLowerCase().includes(formInputValue.toLocaleLowerCase())
    );
  return (
    <>
      <TitleSearchRow>
        <FiltersWithTitle>
          {/* <p>{moduleKey}</p> */}
          <TitleHeaderTextIconNavigator title={t("gradebook.Grades Item")} />
 
          {/* <FilterBtnsDiv>
            <ButtonFilledWrapper
              onClick={toggleDisplayByActivity}
              style={{
                background: showByActivity ? "#5DD3B3" : "", // Change color based on the active state
                color: showByActivity ? "#fff" : "#5DD3B3",
              }}
            >
              <LightTypography text="Group Per Activity" fontSize="1rem" />
            </ButtonFilledWrapper>
            <ButtonFilledWrapper
              onClick={toggleDisplayByModule}
              style={{
                background: showByModule ? "#5DD3B3" : "", // Change color based on the active state
                color: showByModule ? "#fff" : "#5DD3B3",
              }}
            >
              <LightTypography text="Group Per Module" fontSize="1rem" />
            </ButtonFilledWrapper>
          </FilterBtnsDiv> */}
        </FiltersWithTitle>

        <SearchAndLogosDiv>
          <ReusableInput
            left="1.69rem"
            holderWidth=""
            placeHolder={t("dashboard.Search")}
            padingLeft="3.31rem"
            inputBorderRadius="6.25rem"
            inputWidth="23.625rem"
            inputHeight="3.125rem"
            display="block"
            value={formInputValue}
            onInputChange={handleInputChange}
          />
          <ActionsDiv>
            <LogoAndAction>
              <TableViewSelectInput value={t(ViewText)}   options={translatedEnrollmentOptions} onChange={handleNumberShows} />
              {/* </div> */}
            </LogoAndAction>
            {/* <LogoAndAction onClick={downloadExcel}>
              <SvgDownload />
              <Typography text="Download" fontSize="0.75rem" />
            </LogoAndAction> */}
          </ActionsDiv>
        </SearchAndLogosDiv>
      </TitleSearchRow>

      <TableSection>
        <TableContentHolder>
          <TableContent>
            <TableThead
              style={{
                background: Table_Head_TR,
                height: "3.625rem",
              }}
            >
              <TableTr1>
                <TableTh1>
                  <Typography text={t("gradebook.Student")} fontSize="1rem" />
                </TableTh1>
                <th>
                  <Typography text={t("gradebook.Activity name")} fontSize="1rem" />
                </th>
                <th>
                  <Typography text={t("gradebook.Grade")} fontSize="1rem" />
                </th>
              </TableTr1>
            </TableThead>
            <tbody>
              {filteredUsers.map((user) => {
                // Filter the grade data for the current student
                const studentGrades = gradeData.filter(
                  (grade) => grade.studentId === user.sourceId
                );

                return (
                  <React.Fragment key={user.sourceId}>
                    {/* Create a row for each activity of the current student */}
                    {studentGrades.map((grade, index) => (
                      <TableTr1
                        key={index}
                        nth_child_bgcolor={Table_nth_child}
                        style={
                          index === 0
                            ? { borderTop: `1px solid ${Table_CellBorder}` }
                            : undefined
                        }
                      >
                        {/* Render the student name only in the first row */}
                        {index === 0 && (
                          <TableTd
                            style={{
                              background:
                                theme === "light"
                                  ? "#f6f6f6"
                                  : "rgba(45, 55, 65, 1)",
                            }}
                          >
                            {" "}
                            <FlexDiv gap="0.62rem" style={{alignItems: "flex-start"}}>
                              {" "}
                              <div style={{width: "2.5rem"}}>
                              <ProfileContainer
                                bgimage={
                                  user.profilePicture
                                    ? user.profilePicture
                                    : userProfile
                                }
                              /></div>
                              <div style={{display: "flex", flexDirection: "column", gap: "0.31rem"}}>
                              <Typography
                                text={user.username}
                                fontSize="0.875rem"
                              />
                              <LightTypography
                                text={`ID: ${user.sourceId}`}
                                fontSize="0.75rem"
                                color="#AAB1B9"
                              />
                              </div>
                            </FlexDiv>
                          </TableTd>
                        )}
                        {index !== 0 && (
                          <TableTd
                            style={{
                              background:
                                theme === "light"
                                  ? "#f6f6f6"
                                  : "rgba(45, 55, 65, 1)",
                            }}
                          ></TableTd>
                        )}
                        <TableTd1>
                          <TypographyRegularDescription
                            text={grade.activityName}
                            fontSize="0.875rem"
                          />
                        </TableTd1>
                        <TableTd1>
                          <MediumTypography
                            text={`
                          ${toArabicDigits(transformToHundredBasedGrade(
                            grade.score,
                            grade.maxGrade
                          ).toFixed(2).toString())}
                          /${toArabicDigits("100")}`}
                            fontSize=" 0.75rem"
                            color={countColor(grade.score)}
                          />
                        </TableTd1>
                      </TableTr1>
                    ))}
                  </React.Fragment>
                );
              })}
            </tbody>
          </TableContent>
        </TableContentHolder>
      </TableSection>
      {ShowPagination &&
      <PaginationContainer>
        <Pagination
          currentPage={currentPage}
          total={classData.length}
          limit={itemsPerPage}
          onPageChange={(page: number) => setCurrentPage(page)}
          justifyContent="flex-end"
        />
      </PaginationContainer> }
    </>
  );
};
export default GradesItem;
