// used to convert the Date in to this Structure  23 Aug, 2023 | 8:00 AM

import moment from "moment";

export const formatDate = (date: Date | undefined) => {
  return date
    ? date.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
    : "";
};


// used to convert the Date in to this Structure  23 Aug, 2023 | 8:00 AM but the parameter is string
export const bigFormatDate = (dateString: any) => {
  const date = new Date(dateString);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight
  const time = `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
  return `${day} ${month}, ${year} | ${time}`;
}

export const isDateInRange = (
  startDate: Date | null | undefined,
  endDate: Date | null | undefined,
  isVisible: boolean | null | undefined | number,
  role?: string,
) => {
  if (role === "teacher") {
    return true;
  } else {
    if (isVisible === 0) return false;
    else {
      if (!startDate && !endDate) {
        // No date range specified, always visible
        return true;
      }
      const currentDate = new Date();
      const startDateTime = startDate ? new Date(startDate) : null;
      const endDateTime = endDate ? new Date(endDate) : null;

      return (
        (!startDateTime || currentDate >= startDateTime) &&
        (!endDateTime || currentDate <= endDateTime)
      );
    }
  }
};

export const formatDateJustDate = (date: Date | undefined) => {
  return date
    ? date.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).split('/').reverse().join(' - ')
    : "";
};
export function formatEventData(data: any) {
  const events: any[] = [];
  if (data && data.length > 0) {
    data.forEach((item: any) => {
      if (item.assignment) {
        item.assignment.forEach((assignment: any) => {
          if (assignment.startDateTime && assignment.title && assignment.instructions) {
            const event = {
              date: moment(assignment.startDateTime).format("YYYY-MM-DD"),
              title: assignment.title,
              task: `${events.length + 1}-${assignment.instructions.replace(/<\/?p>/g, '')}`,
              type: "Assignment",
              startDate: new Date(assignment.startDateTime),
              endDate: new Date(assignment.cutOffDateTime),
            };
            events.push(event);
          }
        });
      }

      if (item.quiz) {
        item.quiz.forEach((quiz: any) => {
          if (quiz.startDateTime && quiz.title && quiz.description) {
            const event = {
              date: moment(quiz.startDateTime).format("YYYY-MM-DD"),
              title: quiz.title,
              task: `${events.length + 1}-${quiz.description.replace(/<\/?p>/g, '')}`,
              type: "Quiz",
              startDate: new Date(quiz.startDateTime),
              endDate: quiz.cutOffDateTime ? new Date(quiz.cutOffDateTime) : null
            };
            events.push(event);
          }
        });
      }

      if (item.session) {
        item.session.forEach((session: any) => {
          if (session.startDateTime && session.title) {
            const event = {
              date: moment(session.startDateTime).format("YYYY-MM-DD"),
              title: session.title,
              task: `${events.length + 1}-${session.description.replace(/<\/?p>/g, '')}`,
              type: "OnlineSession",
              link: session.path,
              startDate: new Date(session.startDateTime),
              endDate: new Date(session.endDateTime)
            };
            events.push(event);
          }
        });
      }

      if (item.survey) {
        item.survey.forEach((survey: any) => {
          if (survey.startDateTime && survey.title && survey.description) {
            const event = {
              date: moment(survey.startDateTime).format("YYYY-MM-DD"),
              title: survey.title,
              task: `${events.length + 1}-${survey.description.replace(/<\/?p>/g, '')}`,
              type: "Survey",
              startDate: new Date(survey.startDateTime),
              endDate: survey.endDateTime ? new Date(survey.endDateTime) : null
            };
            events.push(event);
          }
        });
      }

      if (item.forum) {
        item.forum.forEach((forum: any) => {
          if (forum.startDateTime && forum.title && forum.description) {
            const event = {
              date: moment(forum.startDateTime).format("YYYY-MM-DD"),
              title: forum.title,
              task: `${forum.description.replace(/<\/?p>/g, "")}`,
              type: "Forum",
              startDate: new Date(forum.startDateTime),
              endDate: item.classes.teachers,
            };
            events.push(event);
          }
        });
      }
    });

    return events;
  }

}