import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { get } from "../../utils/AxiosRequests";
import {
  Container,
  FileContainer,
  InsideContainer,
  MainContainer,
  RightSide,
  FolderContainer,
  FlexedDiv,
  FlexedDiv2,
  FlexedDiv3,
  FileIconDiv,
  FolderDiv,
  ChannelHolder,
  FilesParent,
  FileFolderContainer,
  ParentFolderFiles,
  FlexedDivFiles,
  FolderFileIconDiv,
} from "./teams.style";
import {
  SvgArrowDown,
  SvgFileIcon,
  SvgFolderD,
  SvgPolygonDown,
  Svgdownload2,
} from "../../elements/Icons";

import { LightTypography, MediumTypography, Typography } from "../../elements/fonts/Fonts";
import { useRecoilValue } from "recoil";
import { ColorSelector, MainColorSelector } from "../../recoil/ThemeSelectors";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { FlexDiv } from "../../globalStyles";
import { t } from "i18next";
import EmptyAllSessions from "../attendanceAllSessions/EmptyAllSession";
import { useAuth0 } from "@auth0/auth0-react";
import { useParentClass } from "../../utils/getParentClass";
import { LoadingContainer } from "../LoadingComp/LodingStyle";
import LoaderCircle from "../loader/loaderCircle/LoaderCircle";

interface Channel {
  id: string;
  displayName: string;
}

interface File {
  id: string;
  name: string;
  downloadUrl: string;
  webUrl: string;
}

interface Folder {
  id: string;
  name: string;
  driveId: string;
}

const OAuth2Flow = (teams: any) => {
  const { theme } = useRecoilValue(MainColorSelector);
  const { themeTextColor } = useRecoilValue(ColorSelector);
  const [Teams, setTeams] = useState([]);
  const [Channels, setChannels] = useState<Channel[]>([]);
  const [Files, setFiles] = useState<{ [key: string]: File[] }>({});
  const [Folders, setFolders] = useState<{ [key: string]: Folder[] }>({});
  const token = localStorage.getItem("MSToken");
  const { classId } = useParams<{ classId: string }>();
  const tenantName = localStorage.getItem("tenant");
  const teamsId = teams.teamId.teamsId;
  const [openChannels, setOpenChannels] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [openFolders, setOpenFolders] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [FileUrl, SetFileUrl] = useState("");
  const [Loading, SetLoading] = useState<{ [key: string]: boolean }>({});
  const [isFolderOpen, setIsFolderOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true); // Overall loading state
  const [about, SetAbout] = useState()
  const { user } = useAuth0();
  const myEmail = user?.email;
  const [UserMSid, SetUserMSid] = useState("")
  const [AccessToken, SetAccessToken] = useState("")
  const navigate = useNavigate()

  const getUserId = async () => {
    try {

      const response = await axios.get(
        `https://k9jjuskf3i.execute-api.eu-west-1.amazonaws.com/dev/ms/user/byEmail/${myEmail}/{tenantName}`,

      );
      SetUserMSid(response.data.data)
    } catch (err) {
      console.log(err);
    }
  };




  const fetchCalendarId = async () => {
    try {
      if (UserMSid) {

        const response = await axios.get(`https://graph.microsoft.com/v1.0/users/${UserMSid}/calendars`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Assuming you want the primary calendar
        const primaryCalendar = response.data.value.find((calendar: { name: string; }) => calendar.name === 'Calendar');

        return primaryCalendar ? primaryCalendar.id : null;
      }
      else {
        return;
      }
    } catch (error) {
      console.error('Error fetching calendar ID:', error);
      throw error;
    }
  };








  useEffect(() => {
    fetchClassData()
    getAccessToken();
  }, [teamsId])

  useEffect(() => {

    getUserId()
  }, [AccessToken]);

  useEffect(() => {
    fetchCalendarId()
  }, [UserMSid]);




  useEffect(() => {
    if (teamsId) {
      fetchChannels();
    }
  }, [teamsId, AccessToken]);

  const fetchChannels = async () => {
    try {
      const response = await axios.get(
        `https://graph.microsoft.com/v1.0/teams/${teamsId}/channels`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      setChannels(response.data.value);
    } catch (error) {
      console.log("Error fetching channels:", error);
    }
  };



  const fetchClassData = async () => {
    try {
      const classData = await get(`about/class/${classId}/${tenantName}`);
      SetAbout(classData.data)
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchFilesForChannel = async (channelId: string) => {
    try {
      SetLoading((prevState) => ({
        ...prevState,
        [channelId]: true,
      }));

      const response = await axios.get(
        `https://graph.microsoft.com/v1.0/teams/${teamsId}/channels/${channelId}/filesFolder`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const folderId = response.data.id;
      const driveId = response.data.parentReference.driveId;

      const filesResponse = await axios.get(
        `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${folderId}/children`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const folders = filesResponse.data.value.filter(
        (item: any) => item.folder
      );
      const files = filesResponse.data.value.filter(
        (item: any) => !item.folder
      );

      setFolders((prevState) => ({
        ...prevState,
        [channelId]: folders.map((folder: any) => ({
          id: folder.id,
          name: folder.name,
          driveId: driveId,
        })),
      }));

      setFiles((prevState) => ({
        ...prevState,
        [channelId]: files.map((file: any) => ({
          id: file.id,
          name: file.name,
          downloadUrl: file["@microsoft.graph.downloadUrl"],
          webUrl: file["webUrl"],
        })),
      }));
      SetLoading((prevState) => ({
        ...prevState,
        [channelId]: false,
      }));
    } catch (error) {
      console.log("Error fetching files: ", error);
      SetLoading((prevState) => ({
        ...prevState,
        [channelId]: false,
      }));
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleArrowClick = async (channelId: string) => {
    setOpenChannels((prevState) => ({
      ...prevState,
      [channelId]: !prevState[channelId],
    }));

    if (!openChannels[channelId]) {
      fetchFilesForChannel(channelId);
      fetchRecordingsForChannel(channelId);  // Fetch recordings when the channel is expanded
    }
  };

  const handleFolderClick = async (
    driveId: string,
    folderId: string,
    channelId: string
  ) => {
    setOpenFolders((prevState) => ({
      ...prevState,
      [folderId]: !prevState[folderId],
    }));

    if (!openFolders[folderId]) {
      const folderContents = await fetchFolderContents(driveId, folderId);
      setFiles((prevState) => ({
        ...prevState,
        [folderId]: folderContents,
      }));
    }
  };

  const fetchRecordingsForChannel = async (channelId: string) => {
    try {
      const response = await axios.get(
        `https://graph.microsoft.com/v1.0/teams/${teamsId}/channels/${channelId}/messages`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const recordings = response.data.value
        // Filtering messages that have call recordings
        .map((message: any) => {
          const recordingUrl = message.webUrl;
          return {
            id: message.id,
            name: message.subject || 'Meeting Recording',
            downloadUrl: recordingUrl,
            webUrl: recordingUrl,
          };
        });
      setFiles((prevState) => ({
        ...prevState,
        [channelId]: (prevState[channelId] || []).concat(recordings),
      }));
    } catch (error) {
      console.log("Error fetching recordings: ", error);
    }
  };

  const fetchFolderContents = async (driveId: string, folderId: string) => {
    try {
      const response = await axios.get(
        `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${folderId}/children`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data.value.map((file: any) => ({
        id: file.id,
        name: file.name,
        downloadUrl: file["@microsoft.graph.downloadUrl"],
        webUrl: file["webUrl"],
      }));
    } catch (error) {
      console.log("Error fetching folder contents: ", error);
      return [];
    }
  };

  const getAccessToken = async () => {
    try {
      const response = await axios.get(
        `https://k9jjuskf3i.execute-api.eu-west-1.amazonaws.com/dev/ms/token`,
        // {
        //   headers: {
        //     'tenantName': tenantName,
        //     // Replace YOUR_AUTH_TOKEN with the actual token if required
        //     // Add other headers if needed
        //   }
        // }
      );
      localStorage.setItem("MSToken", response.data.data);
      SetAccessToken(response.data.data)
    } catch (err) {
      console.log(err);
    }
  };

  const fetchFileMetadata = async (fileId: string) => {
    try {
      const response = await axios.get(
        `https://graph.microsoft.com/v1.0/drive/items/${fileId}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      // console.error("Error fetching file metadata:", error);
      return null;
    }
  };

  const handleViewFile = async (fileId: string) => {
    try {
      const fileMetadata = await fetchFileMetadata(fileId);
      if (fileMetadata && fileMetadata["@microsoft.graph.downloadUrl"]) {
        const fileUrl = fileMetadata["@microsoft.graph.downloadUrl"];
        SetFileUrl(fileUrl);

      } else {

      }
    } catch (error) {
      console.error("Error handling file view:", error);
    }
  };

  const handleFileClick = async (fileId: string) => {
    try {
      await handleViewFile(fileId);

    } catch (error) {
      console.error("Error handling file click:", error);
    }
  };

  if (isLoading) {
    return (

      <LoaderCircle />

    );
  }

  if (!teamsId) {
    return <EmptyAllSessions />
  }



  return (
    <>


      {
        !isLoading ?

          <>
            <ChannelHolder id="1">
              {Channels.map((channel) => (
                <MainContainer
                  id="2"
                  key={channel.id}
                  style={{
                    background:
                      theme === "light"
                        ? "#fff"
                        : "linear-gradient(180deg, #415160 0%, #293440 100%)",
                  }}
                >
                  <Container
                    id="3"
                    onClick={() => handleArrowClick(channel.id)}
                    // style={{
                    //   background:
                    //     theme === "light"
                    //       ? "#fff"
                    //       : "var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%))",
                    // }}
                    active={Object.keys(openChannels).length > 0}
                    theme={theme}
                  >
                    <MediumTypography text={channel.displayName} />
                  </Container>
                  {openChannels[channel.id] && (
                    <InsideContainer id="4">
                      {Loading[channel.id] ? (
                        <div style={{ marginLeft: "1.25rem" }}>
                          <MediumTypography text={`${t("general.loading")}...`} />{" "}
                        </div>
                      ) : (
                        <>
                          {Folders[channel.id] &&
                            Folders[channel.id].map((folder) => (
                              <FolderContainer
                                id="5"
                                key={folder.id}
                                onClick={() => {
                                  handleFolderClick(
                                    folder.driveId,
                                    folder.id,
                                    channel.id
                                  );
                                  setIsFolderOpen(!isFolderOpen)
                                }
                                }
                                theme={theme}
                              >
                                <FlexedDiv2
                                  id="6"
                                  onClick={() => setIsFolderOpen(!isFolderOpen)}
                                  active={isFolderOpen}
                                  theme={theme}
                                >
                                  <FlexedDiv3 id="7">
                                    {!isFolderOpen ? (
                                      <FolderDiv id="8" theme={theme}>
                                        <SvgFolderD width="20" height="22" />
                                      </FolderDiv>
                                    ) : (
                                      ""
                                    )}
                                    <MediumTypography text={folder.name} />
                                  </FlexedDiv3>
                                  <SvgPolygonDown style={{ transform: (isFolderOpen ? "rotate(180deg)" : "rotate(0deg)"), transition: "transform 0.3s ease-in-out" }} />
                                </FlexedDiv2>
                                {openFolders[folder.id] && (
                                  <ParentFolderFiles id="55">
                                    {Files[folder.id] &&
                                      Files[folder.id].map((file) => (
                                        <FileFolderContainer key={file.id} id="9">
                                          <FlexedDivFiles
                                            id="10"
                                            onClick={() => { handleFileClick(file.id); setIsFolderOpen(!isFolderOpen) }}
                                            theme={theme}
                                          >
                                            <a href={file.webUrl} target="_blank" rel="noopener noreferrer"> <FlexedDiv3 id="11">
                                              <FolderFileIconDiv id="12" theme={theme}>
                                                <SvgFileIcon width="14" height="16" color={themeTextColor} />
                                              </FolderFileIconDiv>

                                              <MediumTypography text={file.name} />
                                            </FlexedDiv3>
                                            </a>
                                            <a
                                              href={file.downloadUrl}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <FlexDiv pointer="true">
                                                <Svgdownload2 color="#5DD3B3" />
                                                <LightTypography
                                                  text={t("gradebook.Download")}
                                                  fontSize="0.875rem"
                                                  color="#5DD3B3"
                                                />
                                              </FlexDiv>
                                            </a>
                                          </FlexedDivFiles>
                                        </FileFolderContainer>
                                      ))}
                                  </ParentFolderFiles>
                                )}
                              </FolderContainer>
                            ))}
                          <FilesParent id="13">
                            {Files[channel.id] &&
                              Files[channel.id].map((file) => (
                                <FileContainer key={file.id} id="14">
                                  <FlexedDiv
                                    onClick={() => { handleFileClick(file.id) }}
                                    id="15"
                                    theme={theme}
                                  >
                                    <FlexedDiv3 id="16" onClick={() => navigate(file.webUrl)}>
                                      <FileIconDiv id="17" theme={theme}>
                                        <SvgFileIcon width="14" height="16" color={themeTextColor} />
                                      </FileIconDiv>
                                      <MediumTypography text={file.name} />
                                    </FlexedDiv3>
                                    <RightSide id="18">
                                      <a
                                        href={file.downloadUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <FlexDiv pointer="true">
                                          <Svgdownload2 color="#5DD3B3" />
                                          <LightTypography
                                            text={t("gradebook.Download")}
                                            fontSize="0.875rem"
                                            color="#5DD3B3"
                                          />
                                        </FlexDiv>
                                      </a>
                                    </RightSide>
                                  </FlexedDiv>
                                </FileContainer>
                              ))}
                          </FilesParent>
                          {(!Folders[channel.id] ||
                            Folders[channel.id].length === 0) &&
                            (!Files[channel.id] ||
                              Files[channel.id].length === 0) && (
                              <div style={{ marginLeft: "1.25rem" }}>
                                {" "}
                                <MediumTypography text={t("course view.No files found")} />
                              </div>
                            )}
                        </>
                      )}
                    </InsideContainer>
                  )}
                </MainContainer>
              ))}
            </ChannelHolder>
          </>
          :
          <>
            <LoaderCircle />

          </>
      }
    </>
  );
};

export default OAuth2Flow;
