import styled from "styled-components";
import { breakpoints } from "../../../theme";

export const SidebarMobileStyle = styled.div`
  position: relative;
  padding: 4.56rem 0 4.5rem 0 !important;
  position: absolute;
  border-top-right-radius: 0.8rem;
  box-shadow: 0px 5px 35px #0000005c;
  display: flex;
  flex-direction: column;
  /* row-gap: 3.19rem; */
  top: 0;
  left: -1rem;
  z-index: 1000;
  width: 22.5rem;
  & > :last-child {
    margin-top: 3.69rem;
  }
`;
export const Logo = styled.div`
  background-size: 83% auto !important;
  width: 100%;
  background-image: url("/assets/images/images.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 5.5rem;
  /* background-size: contain; */
`;
export const SideMenuItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.31rem;
`;
export const SideMenu = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2.75rem;
  padding-left: 2.8rem;
`;
export const ClosePosition = styled.div`
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
`;
