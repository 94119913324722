import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  CloseHolder,
  FlexedDiv,
  LowerContainer,
  MainContainer,
  MainContainerLoader,
  ParticipantContainer,
  ParticipantImg,
  Profileimg,
  RightParticipantinfo,
  SpinnerHolder,
  SubmitButton,
  TitleHolder
} from './EditGroup.style';
import { MediumTypography } from '../../elements/fonts/Fonts';
import StyledInput from '../../elements/StyledInput/StyledInput';
import SelectMultiple from '../../elements/SelectMultiple/SelectMultiple';
import { get } from '../../utils/AxiosRequests';
import { useParams } from 'react-router-dom';
import { SvgClose } from '../../elements/Icons';
import { generateUUID } from '../../utils/StringManipulation';
import { S3Upload } from '../../services/S3Put';
import { S3Get } from '../../services/S3Get';
import { SpinnerContainer } from '../../elements/Spinner/ZSpinner/ZSpinner.style';
import { Spinner } from '@react-pdf-viewer/core';
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import { useAuth0 } from '@auth0/auth0-react';


interface EditGroupProps {
  selectedGroupId: any;
  SetSavedEdit: React.Dispatch<React.SetStateAction<boolean>>;
  SavedEdit: boolean;
}



const EditGroup: React.FC<EditGroupProps> = ({ selectedGroupId, SetSavedEdit, SavedEdit }) => {


  const tenantName = localStorage.getItem('tenant');
  const [Participant, SetParticipant] = useState<any>(null);
  const [Title, SetTitle] = useState<string>('');
  const [OptionsData, SetOptionsData] = useState<any[]>([]);
  const [selectedAuditors, setSelectedAuditors] = useState<any[]>([]);
  const [imagePath, setImagePath] = useState<any>(null);
  const [isLoading, SetisLoading] = useState(false);
  const [fileResponse, setFileResponse] = useState<any>(null);
  const [groupData, setGroupData] = useState<any>({
    data: {
      tenantName: '',
      subId: '',
      provider: null,
      conversationId: '',
      groupName: '',
      groupProfile: '',
      participants: [],
    },
  });
  const [AllLoading,SetAllLoading]=useState(false)
  const filePath = `chat/group/profilePicture/`;
  const image = require('../../assets/userProfile.png');
  const id = useParams();
  const classId = id.classId;
  const { user } = useAuth0();
const subId = auth0SubIdSplitter(user?.sub || "");  
const provider = auth0ProviderSplitter(user?.sub || "");
  const HandleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    SetTitle(event.target.value);
    console.log(event.target.value);
  };

  const getParticipant = async (conversationId: any) => {
    console.log("worked")
    SetAllLoading(true)
    try {
      console.log("worked1")
      const response = await axios.get(
        `https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/get/conversation/${conversationId}/${tenantName}`
      );
      console.log(response.data.data, 'participants');
      SetParticipant(response.data.data);
      SetTitle(response.data.data.name);
      setImagePath(response.data.data.profile);
      setFileResponse(response.data.data.profileName)
      filterOptions(response.data.data.ParticipantsInfo);
      // Update groupData state
      setGroupData((prevData: { data: any; }) => ({
        ...prevData,
        data: {
          ...prevData.data,
          tenantName: tenantName || '',
          conversationId: conversationId,
          groupName: response.data.data.name,
          groupProfile: response.data.data.profile || '',
          participants: response.data.data.ParticipantsInfo.map((p: any) => p.sourceId),
        },
      }));
      SetAllLoading(false)
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAllUsers = async () => {
    try {
      const response = await get(`participant/class/${classId}/${tenantName}`);
      const allUsers = response.data;
      console.log(response, "response");
      console.log(allUsers, "alluserz");
      createOptionsData(allUsers);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log("selectedGroupId.selectedGroupId",selectedGroupId.selectedGroupId)
    console.log("selectedGroupId.selectedGroupId",selectedGroupId)
    if (selectedGroupId) {
      console.log("useffectworked")
      getParticipant(selectedGroupId);
    }else{
      console.log("useffect didn t work")
    }
  }, [selectedGroupId.selectedGroupId]);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const createOptionsData = (users: any[]) => {
    const options = users.map((user) => ({
      label: `${user.sourceId} | ${user.username} | ${user.email}`,
      value: user.sourceId,
    }));
    SetOptionsData(options);
    console.log(options, 'OptionsData');
  };

  const filterOptions = (participants: any[]) => {
    const participantsIds = participants.map((p: any) => p.sourceId);
    const filteredOptions = OptionsData.filter(
      (option) => !participantsIds.includes(option.value)
    );
    SetOptionsData(filteredOptions);
  };

  const handleSelectAuditors = (selected: any) => {
    // Ensure selected is an array
    const selectedArray = Array.isArray(selected) ? selected : [selected];
    
    // Find and map selected users from OptionsData
    const selectedUsers = selectedArray
      .map((selId: any) => {
        const matchedUser = OptionsData.find((user) => user.value === selId);
        if (matchedUser) {
          return {
            sourceId: matchedUser.value,
            username: matchedUser.label.split(" | ")[1],
            email: matchedUser.label.split(" | ")[2],
            profilePicture: '', // or use any default profile picture if available
          };
        }
        return null;
      })
      .filter(user => user !== null); // Filter out any null values
  
    // Add selected users to ParticipantsInfo
    SetParticipant((prevState: any) => ({
      ...prevState,
      ParticipantsInfo: [...prevState.ParticipantsInfo, ...selectedUsers],
    }));
  
    // Remove selected users from OptionsData
    const updatedOptions = OptionsData.filter(
      (option) => !selectedUsers.some((user) => user?.sourceId === option.value)
    );
    SetOptionsData(updatedOptions);
  
    // Clear selected auditors
    setSelectedAuditors([]);
  };
  
  const handleRemoveParticipant = (sourceId: string) => {
    // Remove from participants list
    const updatedParticipants = Participant.ParticipantsInfo.filter(
      (info: any) => info.sourceId !== sourceId
    );
    SetParticipant((prevState: any) => ({
      ...prevState,
      ParticipantsInfo: updatedParticipants,
    }));

    // Add removed user back to SelectMultiple options
    const removedUser = Participant.ParticipantsInfo.find(
      (info: any) => info.sourceId === sourceId
    );
    if (removedUser) {
      SetOptionsData((prevOptions) => [
        ...prevOptions,
        {
          label: `${removedUser.sourceId} | ${removedUser.username} | ${removedUser.email}`,
          value: removedUser.sourceId,
        },
      ]);
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      SetisLoading(true);
      const name: string = file.name.split("\\").pop() || file.name;
      const fileNameUUid = `${generateUUID()}${name}`;
      const fileuplKey = `${filePath}/${fileNameUUid}`;
      try {
        const response = await S3Upload(filePath, name, fileNameUUid, file);
        if (response?.status === 200) {
          setFileResponse(fileuplKey);
          const pic = await S3Get(fileuplKey);
          if (pic) {
            setImagePath(pic);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        SetisLoading(false);
      }
    }
  };

  const triggerFileInput = () => {
    document.getElementById('fileInput')?.click();
  };

  const updateData = async (body:any) => {
    const url = 'https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/update/conversations';
    
    try {
      const response = await axios.put(url, body
      
      );

      return response.data;
    } catch (error) {
      console.error('Error updating conversation:', error);
    }finally{
      SetSavedEdit(!SavedEdit)
    }
  };
  const getSourceIds=(data: any[])=> {
    if(data){

      return data.map((item: { sourceId: any; }) => item.sourceId);
    }
}
  const body = {
    data: {
      tenantName: tenantName,
      subId: subId,
      provider: provider,
      conversationId: selectedGroupId,
      groupName: Title,
      groupProfile: fileResponse ,
      participants: getSourceIds(Participant?.ParticipantsInfo),
    },
  };
  
  if (AllLoading) {
    return (
      <MainContainerLoader>

      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
      </MainContainerLoader>
    );
  }
  
  return (
    <MainContainer>
      <FlexedDiv>
      {
        isLoading ? 
        <SpinnerHolder>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </SpinnerHolder>
        :
        <>
          <Profileimg
            src={imagePath || image}
            onClick={triggerFileInput}
            style={{ cursor: "pointer" }}
          />
          <input
            type="file"
            id="fileInput"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </>
      }
      </FlexedDiv>
      <LowerContainer>
        <TitleHolder>
          <MediumTypography text="Title" fontSize="1rem" />
        </TitleHolder>
        <StyledInput
          placeholder={Participant?.name}
          width="40rem"
          onChange={HandleTitleChange}
          value={Title}
        />
      </LowerContainer>
      {Participant && (
        <>
          <MediumTypography
            text={`Participants ( ${Participant.ParticipantsInfo.length} )`}
            fontSize="1rem"
          />
          <SelectMultiple
            placeholder={'Select Users'}
            options={OptionsData}
            onChange={handleSelectAuditors}
            value={selectedAuditors}
          />
          {Participant.ParticipantsInfo.map(
            (
              info: {
                sourceId: any;
                profilePicture: string | undefined;
                username: string;
                email: string;
              },
              index: any
            ) => (
              <ParticipantContainer key={info.sourceId || index}>
                <FlexedDiv>
                  <ParticipantImg
                    src={info.profilePicture ? info.profilePicture : image}
                  />
                  <RightParticipantinfo>
                    <MediumTypography text={info.username} fontSize="1rem" />
                    <MediumTypography
                      text={`ID: ${info.sourceId}`}
                      fontSize="0.75rem"
                      color="#AAB1B9"
                    />
                    <MediumTypography
                      text={info.email}
                      fontSize="0.75rem"
                      color="#D9E4E8"
                    />
                  </RightParticipantinfo>
                </FlexedDiv>
                <CloseHolder>
                  <SvgClose onClick={() => handleRemoveParticipant(info.sourceId)} color='#5DD3B3'/>
                </CloseHolder>
              </ParticipantContainer>
            )
          )}
        </>
      )}
      <SubmitButton onClick={()=>updateData(body)}><MediumTypography text={'Save'} color='#FFFF'/></SubmitButton>
    </MainContainer>
  );
};

export default EditGroup;
