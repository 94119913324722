import React, { useState, useEffect, useContext } from "react";
import EmptyPost from "../EmptyPost/EmptyPost";
import {
  FlexShow,
  PostsTimeLineContainer,
  PTLActionsCell,
  PTLLineActions,
  PTLPosterCols,
  PTLPosterImg,
  PTLPosterRows,
  PTLPosterText,
  ThreeDotsContainer,
  ThreeDotsContainerAbsolute,
} from "./PostsTimeLine.style";
import {
  LightTypography,
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import {
  SvgDisLike,
  SvgLike,
  SvgloadingGroup,
  SvgMore,
  SvgSmallPDF,
} from "../../../elements/Icons";
import ViewPost from "../ViewPost/ViewPost";
import PostReply from "../PostReply/PostReply";
import "./PostsTimeLine.css";
import { DisplayContentDisplay } from "./Function";
import DOMPurify from "dompurify";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  htmlDecoder,
  uuidSplitter,
  auth0ProviderSplitter,
} from "../../../utils/StringManipulation";
import { get, post, del } from "../../../utils/AxiosRequests";
import { LoadingContainerSvg } from "../../LoadingComp/LodingStyle";
import { formatDate } from "../../../utils/FormatDate";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import { handleShowSelectedIndex } from "../../../utils/SaveSelectedIndex";
import SelectedOptions from "../../../elements/SelectedOptions/SelectedOptions";
import MappedIndexClickOutSide from "../../../hooks/MapedIndexClickOutSide";
import PopupDelete from "../../../elements/PopupDelete/PopupDelete";
import { toArabicDigits } from "../../../utils/formatDayArabic";

interface PostsTimeLineProps {
  dataLoading?: boolean;
  PostsTimeLineData: any | any[];
  fetchData?: () => void;
  setshowAddPost?: any;
  valid?: boolean;
  setEditMode?: any;
  setPostText?: any;
  setFileResponse?: any;
  setPostId?: any;
  setFileData?: any;
  postId?: number;
}

interface LikeState {
  [index: number]: {
    like: boolean;
    dislike: boolean;
    view?: boolean;
    reply?: boolean;
    showMore: boolean;
  };
}

const PostsTimeLine = ({
  PostsTimeLineData,
  dataLoading,
  fetchData,
  setshowAddPost,
  valid,
  setEditMode,
  setFileData,
  setFileResponse,
  setPostId,
  setPostText,
  postId,
}: PostsTimeLineProps) => {
  const { SolidGrey_Forum, themeTextColor } = useRecoilValue(ColorSelector);
  const [likeState, setLikeState] = useState<LikeState>({});
  const [replyStates, setReplyStates] = useState<{ [index: number]: string }>(
    {}
  );
  const { user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const [likeLoading, setLikeLoading] = useState<{ [index: number]: boolean }>(
    {}
  );
  const [replyLoading, setReplyLoading] = useState<{
    [index: number]: boolean;
  }>({});
  const profileImage = require("../../../assets/userProfile.png");
  const [replyData, setReplyData] = useState({
    index: -1,
    PosterId: "",
    posterText: "",
  });
  const { mySourceId } = useContext(ClassContext);
  const myEmail = user?.email;
  const [showMenuOfSelectedIndex, setShowMenuOfSelectedIndex] = useState<
    number | null
  >(null);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const { t } = useTranslation();
  const [NumberShows, setNumberShows] = useState<number>(0);
  const language = localStorage.getItem("language");
  // Function to handle clicking the like icon (ex: user like a post)
  const handleLike = async (index: number) => {
    if (valid) {
      setLikeLoading({ ...likeLoading, [index]: true });
      const likeData = {
        postId: PostsTimeLineData.Posts[index].PosterId,
        subId: subId,
        provider: provider,
      };
      try {
        await post(`forum/post/create/claps/${tenantName}`, likeData);
        if (fetchData) {
          await fetchData();
        }
      } catch (error) {
        console.error("Error liking post:", error);
      } finally {
        setLikeLoading({ ...likeLoading, [index]: false });
      }
    }
  };

  // Handle clicking on the view reply button (ex: user viewed the replies on a post)
  const handleView = (index: number) => {
    setLikeState((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        view: !prevState[index]?.view,
      },
    }));
  };

  // Handle clicking on the reply button (ex: user want to reply on a post)
  const handleReply = (index: number) => {
    if (valid) {
      const postData =
        PostsTimeLineData.Posts && PostsTimeLineData.Posts[index];
      if (postData) {
        setReplyData({
          index,
          PosterId: postData.PosterId || "",
          posterText: postData.posterText || "",
        });

        setLikeState((prevState) => ({
          ...prevState,
          [index]: {
            ...prevState[index],
            reply: !prevState[index]?.reply,
          },
        }));
      }
    }
  };

  // Set the value of the reply field of the user on the post
  const handleReplyChange = (index: number, value: string) => {
    setReplyStates((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  // Handle the reply submission on the post (ex: user has replied to a post)
  const postReply = async (index: number) => {
    if (!replyStates[index]) {
      return;
    }
    setReplyLoading({ ...replyLoading, [index]: true });
    const data = {
      postId: replyData.PosterId,
      subId: subId,
      provider: provider,
      posterText: replyData.posterText,
      index: replyData.index,
      reply: replyStates[index],
    };
    await post(`forum/post/create/reply/${tenantName}`, data);
    setReplyLoading({ ...replyLoading, [index]: false });
    if (fetchData) {
      fetchData();
    }

    setReplyStates((prevState) => ({
      ...prevState,
      [index]: "", // Reset the reply state for the post after posting
    }));
  };

  // Handle clicking on the show more text
  const toggleShowMore = (index: number) => {
    setLikeState((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        showMore: !prevState[index]?.showMore,
      },
    }));
  };

  const clickOutsideRef = MappedIndexClickOutSide(() =>
    setShowMenuOfSelectedIndex(null)
  );

  const handleEditPost = (post: any) => {
    setEditMode && setEditMode(true);
    setshowAddPost(true);
    setPostId && setPostId(post.PosterId);
    setPostText && setPostText(post.posterText);
    setFileResponse && setFileResponse(post.fileName);
    setFileData &&
      setFileData({
        name: uuidSplitter(post.fileName || "").replace(
          /^(forum\/submissions\/)/,
          ""
        ),
      });
    setShowMenuOfSelectedIndex(null);
  };

  const handleDeletePost = async () => {
    try {
      await del(`forum/post/delete/${postId}/${tenantName}`);
      fetchData && fetchData();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // if (window.innerWidth < 555) {
    //   setNumberShows(100);
    // } else if (window.innerWidth < 750) setNumberShows(180);
    // else if (window.innerWidth < 1200) setNumberShows(240);
    // else setNumberShows(240);
    setNumberShows(window.innerWidth / 5.7);
  }, [window.innerWidth]);

  const stripHtmlTags = (htmlString: string) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || "";
  };
  return (
    <>
      {/* Here we are mapping the posts of the users */}
      {PostsTimeLineData &&
      PostsTimeLineData.Posts &&
      PostsTimeLineData.Posts.length > 0 ? (
        <>
          {PostsTimeLineData.Posts.map((val: any, index: number) => (
            <PostsTimeLineContainer
              key={val.PosterId}
              bgColor={SolidGrey_Forum}
              color={themeTextColor}
            >
              <PTLPosterRows>
                {/* The user Profile goes here */}
                <div style={{ display: "flex", gap: ".7rem" }}>
                  <PTLPosterImg src={val.posterProfilePic || profileImage} />
                  <PTLPosterCols>
                    <Typography fontSize="1rem" text={val.posterName || ""} />
                    <LightTypography
                      text={val.posterEmail || ""}
                      fontSize="0.75rem"
                    />
                    {/* The time of submission of the post goes here */}
                    <LightTypography
                      text={formatDate(val.posterTime || "", t)}
                      fontSize="0.65rem"
                    />
                  </PTLPosterCols>
                </div>
                {valid &&
                (mySourceId === val.userSourceId ||
                  myEmail?.toLocaleLowerCase() ===
                    val.posterEmail.toLocaleLowerCase()) ? (
                  <ThreeDotsContainer>
                    <SvgMore
                      onClick={() =>
                        handleShowSelectedIndex(
                          index,
                          showMenuOfSelectedIndex,
                          setShowMenuOfSelectedIndex
                        )
                      }
                      style={{
                        cursor: "pointer",
                      }}
                    />
                    {showMenuOfSelectedIndex === index && (
                      <ThreeDotsContainerAbsolute ref={clickOutsideRef}>
                        <SelectedOptions
                          FirstLabelClick={() => handleEditPost(val)}
                          LastLabelClick={() => (
                            setShowDeletePopup(true),
                            setPostId(val.PosterId),
                            setShowMenuOfSelectedIndex(null)
                          )}
                        />
                      </ThreeDotsContainerAbsolute>
                    )}
                  </ThreeDotsContainer>
                ) : (
                  ""
                )}
              </PTLPosterRows>
              <PTLPosterText>
                {/* The Content of the post goes here */}

                {val.posterText && val.posterText.length < NumberShows ? (
                  <div
                    className="TextDisplayForum"
                    dangerouslySetInnerHTML={{
                      __html: htmlDecoder(val.posterText || ""),
                    }}
                  ></div>
                ) : likeState[index]?.showMore ? (
                  <FlexShow>
                    <p className="TextDisplayForum">
                      {stripHtmlTags(val.posterText || "")}
                      <button
                        className="ShowsLength"
                        onClick={() => toggleShowMore(index)}
                      >
                        {" "}
                        {t("survey.Show Less")}{" "}
                      </button>
                    </p>
                  </FlexShow>
                ) : (
                  <FlexShow>
                    <p className="TextDisplayForum">
                      {stripHtmlTags(
                        val.posterText.slice(0, NumberShows) || ""
                      )}
                      <button
                        className="ShowsLength"
                        onClick={() => toggleShowMore(index)}
                      >
                        {" "}
                        ...{t("survey.Show More")}{" "}
                      </button>
                    </p>
                  </FlexShow>
                )}

                {/* The Option of show more text goes here*/}
              </PTLPosterText>

              {/* The File of the post goes here (if there is)*/}
              {val.file && <>{DisplayContentDisplay(val.file, val.fileName)}</>}
              <PTLLineActions>
                {likeLoading[index] ? (
                  <LoadingContainerSvg>
                    <SvgloadingGroup />
                  </LoadingContainerSvg>
                ) : (
                  // The like icon goes here
                  <PTLActionsCell
                    onClick={() => handleLike(index)}
                    style={{
                      color: val.ifAlreadyClaps ? "#5DD3B3" : `#AAB1B9`,
                    }}
                  >
                    {/* The Count of likes on the post goes here */}
                    <TypographyRegularDescription
                      text={toArabicDigits(val.postLike.toString())}
                      fontSize="0.75rem"
                    />
                    <SvgLike />
                  </PTLActionsCell>
                )}
                {/* The Reply icon goes here */}
                <PTLActionsCell
                  onClick={() => handleReply(index)}
                  style={{
                    color: likeState[index]?.reply ? "#5DD3B3" : `#AAB1B9`,
                  }}
                >
                  <TypographyRegularDescription
                    text={t("forum.Reply")}
                    fontSize="0.75rem"
                  />
                </PTLActionsCell>
                {/* The View Replies icon goes here */}
                {val.Reply && val.Reply.length > 0 && (
                  <PTLActionsCell
                    onClick={() => handleView(index)}
                    style={{
                      color: likeState[index]?.view ? "#5DD3B3" : `#AAB1B9`,
                    }}
                  >
                    <TypographyRegularDescription
                      text={t("forum.View Replies")}
                      fontSize="0.75rem"
                    />
                  </PTLActionsCell>
                )}
              </PTLLineActions>
              {likeState[index]?.view &&
                val.Reply && ( // The replies of the post Goes Here
                  <ViewPost
                    ViewPostData={val.Reply}
                    valid={valid}
                    fetchData={fetchData}
                  />
                )}

              {likeState[index]?.reply && (
                <PostReply
                  onReplyChange={(value) => handleReplyChange(index, value)}
                  onPostReply={() => postReply(index)}
                  placeholderText={
                    language === "tr"
                      ? `${val.posterName || ""} ${t(
                          "course view.Replying to"
                        )} `
                      : `${t("course view.Replying to")} ${
                          val.posterName || ""
                        }`
                  }
                  replyValue={replyStates[index] || ""}
                  replyLoading={replyLoading[index]}
                />
              )}
              {showDeletePopup && (
                <PopupDelete
                  show={showDeletePopup}
                  setShow={setShowDeletePopup}
                  title={t("survey.Delete Your Post")}
                  handleSubmit={handleDeletePost}
                />
              )}
            </PostsTimeLineContainer>
          ))}
        </>
      ) : dataLoading ? (
        <EmptyPost text={`${t("general.loading")}...`} />
      ) : (
        <EmptyPost text={t("forum.no Posts Yet")} />
      )}
    </>
  );
};

export default PostsTimeLine;
