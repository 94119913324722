import styled from "styled-components";
import { breakpoints } from "../../theme";

export interface FrameProps {
  isactive: string;
  iconortextcolor: string;
}

interface CourseViewSidebarProps {
  bgminiiconcolor: string;
  boxshadow: string;
}

//**********CourseBannerAndSidebarContainer**********
export const CourseBannerAndSidebarContainer = styled.div`
  display: flex;
  /* width: 100vw; */
  column-gap: 0.63rem;
  @media (max-width: calc(${breakpoints.mobile} + 25px)) {
    display: block;
  }
`;
//**********CourseViewSidebar**********

export const CourseViewSidebar = styled.div<CourseViewSidebarProps>`
  width: 5.125rem;
  flex-shrink: 0;
  min-height: 5.0625rem;
  border-radius: 0.625rem;
  background: ${(props) => props.bgminiiconcolor};
  box-shadow: ${(props) => props.boxshadow};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  @media (max-width: calc(${breakpoints.mobile} + 25px)) {
    display: none;
  }
`;
export const SidebarPosition = styled.div<{ isopen: string }>`
  position: absolute;
  z-index: 10;
  &:dir(ltr) {
    left: 0;
    transform: translateX(
      ${(props: { isopen: string }) => (props.isopen === "true" ? "0" : "-100%")}
    );
  }
  &:dir(rtl) {
    right: 0;
    transform: translateX(
      ${(props: { isopen: string }) => (props.isopen === "true" ? "0" : "100%")}
    );
  }
  width: 21.5rem;
  top: 5.1rem;
  opacity: ${(props: { isopen: string }) => (props.isopen === "true"  ? 1 : 0)};

  transition: opacity 1s ease,
    transform ${(props: { isopen: string }) => (props.isopen === "true"  ? "1s" : "2s")}
      ease;

  @media (max-width: calc(${breakpoints.mobile} + 25px)) {
    display: none;
  }
`;
// **********CourseBannerWrapper**********
export const SliderTabsWrapper = styled.div<CourseViewSidebarProps>`
  width: 100%;
  min-height: 5.0625rem;
  border-radius: 10px;
  grid-column: 1/5;
  display: flex;
  justify-content: center;
  background-color: ${({ bgminiiconcolor }) => bgminiiconcolor};
  box-shadow: ${(props) => props.boxshadow};
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  & .icon {
    position: absolute;
    top: 0;
    height: 100%;
  }
  & .left-icon {
    left: 0;
  }
  & .right-icon {
    right: 0;
  }
  @media (max-width: ${breakpoints.mobile}) {
    margin-bottom: 2.5rem;
    margin-top: 0.6rem;
    width: 180vw;
    margin-left: -50vw;
    border-radius: 0;
    padding: 0 40vw 0 44vw;
  }
`;

// *********CourseMenu*********
export const TabsBox = styled.div`
  width: 95%;
  min-height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  @media (max-width: ${breakpoints.small}) {
    justify-content: space-between;
  }

  &.dragging {
    -webkit-overflow-scrolling: touch;
    cursor: grab;
    .tab {
      user-select: none;
      pointer-events: none;
    }
  }
`;

// *********Frame*********
export const Tab = styled.div<FrameProps>`
  cursor: pointer;
  display: flex;
  row-gap: 0.62rem;
  flex-direction: column;
  position: relative;
  align-items: center;
  scroll-snap-align: center;
  /* color: ${({ theme }) => theme.dark.primary_text_color}; */
  &:hover {
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in-out;
  }
  color: ${(props) =>
    props.isactive === "true"  ? props.theme.dark.main_color : props.iconortextcolor};
  /* &.active {
    color: ${({ theme }) => theme.dark.main_color};
  } */
`;

export const IconBannerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15.023px;
`;

export const TypographyWrapper = styled.div`
  display: inline-block;
  position: relative;
  &.line-active::after {
    content: "";
    position: absolute;
    bottom: -1.2rem;
    left: calc(-25%);
    width: 150%;
    height: 0.09375rem;
    background: ${({ theme }) => theme.dark.main_color};
    border-radius: 1px;
    transform: scaleX(1);
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -1.2rem;
    left: calc(-25%);
    width: 150%;
    height: 0.09375rem;
    background: ${({ theme }) => theme.dark.main_color};
    border-radius: 1px;
    transform: scaleX(0);
    transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    transform-origin: right center;
  }
  &:hover::after {
    transform: scaleX(1);
    transform-origin: left center;
    transition-duration: 0.4s;
  }
`;

export const DividLineHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.375rem;
  min-width: 4rem;
  margin: 0.47rem 0 0.59rem 0;
`;

export const DividLine = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: rgba(87, 99, 113, 0.6);
  height: 100%;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
`;

export const LeftIcon = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 0.8rem;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: linear-gradient(
    50deg,
    rgba(93, 211, 179, 0.4) 0%,
    rgba(47, 106, 90, 0.4) 40%,
    rgba(47, 106, 90, 0.4) 60%,
    rgba(93, 211, 179, 0.4) 100%
  );
  & > :first-child {
    color: #89949c;
  }
  &:hover {
    background: linear-gradient(
      50deg,
      rgba(93, 211, 179, 0.6) 0%,
      rgba(47, 106, 90, 0.6) 40%,
      rgba(47, 106, 90, 0.6) 60%,
      rgba(93, 211, 179, 0.6) 100%
    );
    transition: all 0.4s ease-in-out;
    & > :first-child {
      color: white;
    }
  }
  @media (min-width: ${breakpoints.medium}) {
    display: none;
  }
  @media (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`;
export const LeftDiv = styled.div`
  margin-right: 1rem;
  @media (min-width: ${breakpoints.medium}) {
    display: none;
  }
`;
export const RightDiv = styled.div`
  margin-left: 1rem;
  @media (min-width: ${breakpoints.medium}) {
    display: none;
  }
`;

export const RightIcon = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 0.8rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  justify-content: flex-end;
  background: linear-gradient(
    50deg,
    rgba(93, 211, 179, 0.4) 0%,
    rgba(47, 106, 90, 0.4) 40%,
    rgba(47, 106, 90, 0.4) 60%,
    rgba(93, 211, 179, 0.4) 100%
  );
  & > :first-child {
    color: #89949c;
  }

  &:hover {
    background: linear-gradient(
      50deg,
      rgba(93, 211, 179, 0.6) 0%,
      rgba(47, 106, 90, 0.6) 40%,
      rgba(47, 106, 90, 0.6) 60%,
      rgba(93, 211, 179, 0.6) 100%
    );
    transition: all 0.4s ease-in-out;
    & > :first-child {
      color: white;
    }
  }
  @media (min-width: ${breakpoints.medium}) {
    display: none;
  }
  @media (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`;
