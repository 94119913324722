import React from "react";
// import { DropMenuData } from "../DropDownMenuData";
import {
  SvgProfileIcon,
  SvgSettionIcon,
  SvgHelpIcon,
  SvgLogoutIcon,
} from "../../../elements/Icons";
import { Menu, MenuWrapper, Frame, Icon } from "./DropDownItems.style";
import { TypographyRegularDescription } from "../../../elements/fonts/Fonts";
import { Link } from "react-router-dom";
import { DropDownMenuItem } from "../DropDownDataInterface";
import { useTranslation } from "react-i18next";
import ToggleSwitch from "../../../elements/themeSwitch/ThemeSwitch";

export const DropDownMenuItems = (): JSX.Element => {
  const { t } = useTranslation();
  const DropMenuData: DropDownMenuItem[] = [
    {
      icon: SvgProfileIcon,
      title: t("dashboard.My Profile"),
      path: "/editProfile",
    },
    // {
    //   icon: SvgRefreshIcon,
    //   title: "Switch Roles",
    //   path: "#",
    // },
    {
      icon: SvgSettionIcon,
      title: t("User Profile.Account Settings"),
      path: "/changePassword",
    },
    // {
    //   icon: SvgPaymentIcon,
    //   title: "Payment Methods",
    //   path: "/Payment",
    // },
    // {
    //   icon: SvgSecurityIcon,
    //   title: "Privacy Settings",
    //   path: "/PrivacySetting",
    // },
    // {
    //   icon: SvgNotification2Icon,
    //   title: "Notifications Settings",
    //   path: "/NotificationSetting",
    // },
    {
      icon: SvgHelpIcon,
      title: t("help.Help"),
      path: "/help",
    },
  ];
  const tenantName = localStorage.getItem("tenant");
  return (
    <Menu>
      {DropMenuData.map((item, index) => (
        <Link to={item.path}>
          <Frame key={index}>
            <item.icon />
            <TypographyRegularDescription
              fontSize="0.875rem"
              text={item.title}
            />
          </Frame>
        </Link>
      ))}
      <ToggleSwitch />
    </Menu>
  );
};
