import { FC, useContext } from "react";
import {
  CertificateMainHolder,
  RectangleHolder,
  QR_Wrapper,
  CertificateInfoWrapper,
  FooterHolder,
  SignatureNamePositionHolder,
  ImageWrapper,
  UniLogoWrapper,
  StampWrapper,
  BigBorderFirstPieceDivTop,
  BigBorderSecondPieceDivTop,
  BigBorderFirstPieceDivBottom,
  BigBorderSecondPieceDivBottom,
  SmallBorderFirstPieceDivTop,
  SmallBorderSecondPieceDivTop,
  SmallBorderFirstPieceDivBottom,
  SmallBorderSecondPieceDivBottom,
} from "./Certificate.style";
import { CertificateInterface } from "./certificateInterface";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";

import {
  CertificateTypography,
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import { SvgBadge } from "../../elements/Icons";

/**
 * Certificate component displays a certificate of completion.
 * It renders the certificate template based on the provided props.
 * @param templateType The type of certificate template ("one", "two", "three").
 * @param qrCode The URL of the QR code for the certificate.
 * @param studentName The name of the student receiving the certificate.
 * @param courseName The name of the course for which the certificate is issued.
 * @param certificateDate The date when the certificate is issued.
 * @param leftSignature The URL of the left signature image.
 * @param rightSignature The URL of the right signature image.
 * @param badge The URL of the badge image (for template type "three").
 * @param stamp The URL of the stamp image (for template type "three").
 * @param uniLogo The URL of the university logo image (for template type "two").
 * @param leftName The name of the signer on the left side of the certificate.
 * @param rightName The name of the signer on the right side of the certificate (for template type "one").
 * @param leftPosition The position/title of the signer on the left side of the certificate.
 * @param rightPosition The position/title of the signer on the right side of the certificate (for template type "one").
 * @param rectangleColor The color of the rectangle background.
 * @param bigLineColor The color of the big border lines.
 * @param smallLineColor The color of the small border lines.
 * @param rectangleBorder The border thickness of the rectangle.
 * @param bigLineBorder The border thickness of the big border lines.
 * @param smallLineBorder The border thickness of the small border lines.
 */
const Certificate: FC<CertificateInterface> = ({
  templateType,
  qrCode,
  studentName,
  courseName,
  certificateDate,
  leftSignature,
  rightSignature,
  badge,
  stamp,
  uniLogo,
  leftName,
  rightName,
  leftPosition,
  rightPosition,
  rectangleColor,
  bigLineColor,
  smallLineColor,
  rectangleBorder,
  bigLineBorder,
  smallLineBorder,
}) => {
  // Accessing class data from context
  const { bData, classId } = useContext(ClassContext);
  const course = bData.find((course: { id: any }) => course.id === classId);

  return (
    <CertificateMainHolder>
      <RectangleHolder
        rectangleColor={rectangleColor || "#BA8738"}
        rectangleBorder={rectangleBorder || "1px"}
      >
        {/* Borders */}
        <BigBorderFirstPieceDivTop
          bigLineBorder={bigLineBorder || "16px"}
          bigLineColor={bigLineColor || "#1D3644"}
        ></BigBorderFirstPieceDivTop>
        <BigBorderSecondPieceDivTop
          bigLineBorder={bigLineBorder || "16px"}
          bigLineColor={bigLineColor || "#1D3644"}
        ></BigBorderSecondPieceDivTop>
        <BigBorderFirstPieceDivBottom
          bigLineBorder={bigLineBorder || "16px"}
          bigLineColor={bigLineColor || "#1D3644"}
        ></BigBorderFirstPieceDivBottom>
        <BigBorderSecondPieceDivBottom
          bigLineBorder={bigLineBorder || "16px"}
          bigLineColor={bigLineColor || "#1D3644"}
        ></BigBorderSecondPieceDivBottom>
        <SmallBorderFirstPieceDivTop
          smallLineBorder={smallLineBorder || "10px"}
          smallLineColor={smallLineColor || "#BE842E"}
        ></SmallBorderFirstPieceDivTop>
        <SmallBorderSecondPieceDivTop
          smallLineBorder={smallLineBorder || "10px"}
          smallLineColor={smallLineColor || "#BE842E"}
        ></SmallBorderSecondPieceDivTop>
        <SmallBorderFirstPieceDivBottom
          smallLineBorder={smallLineBorder || "10px"}
          smallLineColor={smallLineColor || "#BE842E"}
        ></SmallBorderFirstPieceDivBottom>
        <SmallBorderSecondPieceDivBottom
          smallLineBorder={smallLineBorder || "10px"}
          smallLineColor={smallLineColor || "#BE842E"}
        ></SmallBorderSecondPieceDivBottom>
        
        {/* QR Code */}
        <QR_Wrapper src={qrCode} alt="qr-code" />

        {/* Certificate Information */}
        <CertificateInfoWrapper>
          <CertificateTypography
            text="Certificate of Completion"
            fontSize="3rem"
            fontWeight="700"
            lineHeight="3.6rem"
            color="#2D3741"
          />
          <LightTypography text="This is to certify that" color="#2D3741" />
          <CertificateTypography
            text={studentName || "Student Name"}
            fontSize="3.75rem"
            fontWeight="700"
            lineHeight="4.5rem"
            color="#2D3741"
          />
          <LightTypography
            text="Has successfully completed the course"
            color="#2D3741"
            lineHeight="1.236rem"
          />
          <MediumTypography
            text={`${courseName} on ${certificateDate}`}
            lineHeight="1.236rem"
            color="#5DD3B3"
          />
          
          {/* Footer */}
          <FooterHolder>
            <SignatureNamePositionHolder>
              <ImageWrapper src={leftSignature} alt="signature" />
              <Typography
                text={leftName}
                fontSize="0.75rem"
                color="#2D3741"
                whiteSpace="nowrap"
              />
              <TypographyRegularDescription
                text={leftPosition.toUpperCase()}
                fontSize="0.75rem"
                color="#2D3741"
              />
            </SignatureNamePositionHolder>

            {/* Rendering based on template type */}
            {templateType === "one" && (
              <SignatureNamePositionHolder>
                <ImageWrapper src={rightSignature} alt="signature" />
                <Typography
                  text={rightName ? rightName : ""}
                  fontSize="0.75rem"
                  color="#2D3741"
                  whiteSpace="nowrap"
                />
                <TypographyRegularDescription
                  text={rightPosition ? rightPosition.toUpperCase() : ""}
                  fontSize="0.75rem"
                  color="#2D3741"
                />
              </SignatureNamePositionHolder>
            )}
            {templateType === "two" && (
              <UniLogoWrapper src={uniLogo} alt="University Logo" />
            )}
            {templateType === "three" && (
              <>
                <SvgBadge />
                <StampWrapper src={stamp} alt="stamp" />
              </>
            )}
          </FooterHolder>
        </CertificateInfoWrapper>
      </RectangleHolder>
    </CertificateMainHolder>
  );
};

export default Certificate;
