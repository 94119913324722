import React, { useEffect, useState } from "react";
import {
  AnalyticsAssignmentResponseTrackingMainContainer,
  AnalyticsAssignmentResponseTrackingMainContainerRow,
} from "../AnalyticsAssignmentResponseTracking.style";
import { Typography } from "../../../elements/fonts/Fonts";
import { AssignmentSubmissionData } from "../../Tables/AssignmentSubmissionDetails/AssignmentSubmissionData";
import {
  AnalyticsContainerRow,
  AnalyticsPieChartContainer,
} from "../../../Views/Analytics/AnalyticsPageStyles";
import PieWithText from "../../Charts/PieWithText/PieWithText";
import PieText from "../../Charts/PieText/PieText";
import ProgressBarChart from "../../Charts/ProgressBarChart/ProgressBarChart";
export interface AnalyticsAssignmentResponseTrackingInterface {
  onClick?: () => void;
  Data?: any;
}
const AnalyticsAssignmentResponseTracking = ({
  onClick,
}: AnalyticsAssignmentResponseTrackingInterface) => {
  const [PieChartData, setPieChartData] = useState([
    {
      text: "Attempted",
      attendance: 0,
      bgColor: "#5DD3B3",
    },
    {
      text: "Unattempted",
      attendance: 0,
      bgColor: "#0B8FFF",
    },
  ]);
  // useEffect to update PieChartData on component mount
  useEffect(() => {
    // Calculate attempted count
    const attemptedCount = AssignmentSubmissionData.Students?.filter(
      (item) =>
        (typeof item.Status === "string" &&
          (item.Status as string) === "Yes") ||
        (typeof item.Status === "string" &&
          (item.Status as string) === "Submitted") ||
        (typeof item.Status === "boolean" && (item.Status as boolean) === true)
    ).length;

    // Calculate unattempted count
    const unattemptedCount = AssignmentSubmissionData.Students?.filter(
      (item) =>
        (typeof item.Status === "string" && (item.Status as string) === "No") ||
        (typeof item.Status === "string" &&
          (item.Status as string) === "Unsubmitted") ||
        item.Status === undefined ||
        item.Status === null ||
        !item.Status ||
        (typeof item.Status === "boolean" && (item.Status as boolean) === false)
    ).length;

    // Update PieChartData state with new data
    setPieChartData([
      {
        text: "Attempted",
        attendance: attemptedCount,
        bgColor: "#5DD3B3",
      },
      {
        text: "Unattempted",
        attendance: unattemptedCount,
        bgColor: "#0B8FFF",
      },
    ]);
  }, []);
  // Maximum Number of Data
  const MaxNumberOfData = AssignmentSubmissionData.Students?.length;

  const [ProgressBarCharData, setProgressBarCharData] = useState([
    {
      text: "Attempted",
      value: 0,
    },
    {
      text: "Unattempted",
      value: 0,
    },
    {
      text: "Submission after Due date",
      value: 0,
    },
  ]);
  useEffect(() => {
    // Calculate attempted count
    const attemptedCount = AssignmentSubmissionData.Students?.filter(
      (item) =>
        (item.Status &&
          typeof item.Status === "string" &&
          (item.Status as string) === "Yes") ||
        (typeof item.Status === "string" &&
          (item.Status as string) === "Submitted") ||
        (typeof item.Status === "boolean" && (item.Status as boolean) === true)
    ).filter(
      (item) =>
        item.DueDate &&
        item.SubmissionDate &&
        item.DueDate >= item.SubmissionDate
    ).length;

    // Calculate Submission after Due date count
    const SubmissionAfterDueDate = AssignmentSubmissionData.Students?.filter(
      (item) =>
        (item.Status &&
          typeof item.Status === "string" &&
          (item.Status as string) === "Yes") ||
        (typeof item.Status === "string" &&
          (item.Status as string) === "Submitted") ||
        (typeof item.Status === "boolean" && (item.Status as boolean) === true)
    ).filter(
      (item) =>
        item.DueDate &&
        item.SubmissionDate &&
        item.DueDate < item.SubmissionDate
    ).length;

    // Calculate unattempted count
    const unattemptedCount = AssignmentSubmissionData.Students?.filter(
      (item) =>
        (typeof item.Status === "string" && (item.Status as string) === "No") ||
        (typeof item.Status === "string" &&
          (item.Status as string) === "Unsubmitted") ||
        item.Status === undefined ||
        item.Status === null ||
        !item.Status ||
        (typeof item.Status === "boolean" && (item.Status as boolean) === false)
    ).length;

    // Update PieChartData state with new data
    setProgressBarCharData([
      {
        text: "Attempted",
        value: attemptedCount,
      },
      {
        text: "Unattempted",
        value: unattemptedCount,
      },
      {
        text: "Submission after Due date",
        value: SubmissionAfterDueDate,
      },
    ]);
  }, []);

  return (
    <AnalyticsAssignmentResponseTrackingMainContainer>
      <div style={{ cursor: "pointer" }} onClick={onClick}>
        <Typography text="Submission Tracking" fontSize="1.5rem" />
      </div>
      <AnalyticsAssignmentResponseTrackingMainContainerRow>
        <AnalyticsContainerRow>
          {/* AnalyticsPieChartContainer for Pie Chart */}
          <AnalyticsPieChartContainer>
            {/* PieWithText Component */}
            <PieWithText
              data={PieChartData}
              value={"attendance"}
              getColor={(entry) => entry.bgColor as string}
              Width="60%"
              NormalTextDisplay={false}
            />

            {/* PieText Component */}
            <PieText LoopName={PieChartData} />
          </AnalyticsPieChartContainer>

          {/* AnalyticsPieChartContainer for Progress Bar Chart */}
          <AnalyticsPieChartContainer>
            {/* ProgressBarChart Component */}
            <ProgressBarChart
              data={ProgressBarCharData}
              MaxNumber={MaxNumberOfData}
            />
          </AnalyticsPieChartContainer>
        </AnalyticsContainerRow>
      </AnalyticsAssignmentResponseTrackingMainContainerRow>
    </AnalyticsAssignmentResponseTrackingMainContainer>
  );
};

export default AnalyticsAssignmentResponseTracking;
