import styled, { css } from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

export const ButtonContent = styled.div<{ backgroundColor?: string,bordercolor?:string, width?:string }>`
  margin-top: 20px;
  margin-bottom: 50px;
  width: ${props => props.width || '7.625rem'};
  height: 2.125rem;
  font-size: 0.875rem;
  border-radius: 1.25rem;
  border: ${props=>props.bordercolor?`1px solid ${props.bordercolor}`:'1px solid #5DD3B3'};

  color: #5DD3B3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5rem;
  cursor: pointer;
  margin-bottom: 5px;
  font-family: 'Primary Font-Bold';

  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
`;
