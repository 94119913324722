import React, { useEffect, useState } from "react";
import { AddSqIcon, SvgAdd, SvgClose, SvgDelete } from "../../elements/Icons";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../elements/fonts/Fonts";
import ModalWrapper from "../groupCard/editModal/modalOverlay/ModalOverlay";
import {
  AddAttendIconText,
  AddAttendanceWrapper,
  ButtonAdd,
  ButtonCancel,
  ButtonsHolder,
  CheckBox,
  CheckBoxColumn,
  CheckBoxRow,
  DateAndTimeDiv,
  DaysLabel,
  ExitRow,
  InputTitle,
  LabelDiv,
  LeftBorderColor,
  PopUpContainer,
  StartEndDateRow,
  TimeColumn,
  TimePickerHolder,
  TimePickerHolderContent,
  TimePickerHolderContentDelete,
  TitleLabel,
  TitleRow,
} from "./AddAttendancePopUp.style";
import SelectDate from "../../elements/DatePicker/inputDate";
import TimePickerComponent from "../../elements/TimePicker/TimePicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { FlexDiv } from "../../globalStyles";
import {
  handleAddTimePicker,
  handleCheckboxChange,
  handleDeleteTimePicker,
  handleGenerate,
  handleUpdateTime,
} from "./AddAttendanceFunctions";
import { useRecoilValue } from "recoil";
import { ColorSelector, PopupSelectorColor } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

interface EditModalProps {
  toggleModel: () => void;
}

const AddAttendancePopUp = ({ toggleModel }: EditModalProps): JSX.Element => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<string[][]>(Array(7).fill([]));
  const [endTime, setEndTime] = useState<string[][]>(Array(7).fill([]));
  const daysOfWeek = [
    { name: "calendar.Monday", value: "Monday" },
    { name: "calendar.Tuesday", value: "Tuesday" },
    { name: "calendar.Wednesday", value: "Wednesday" },
    { name: "calendar.Thursday", value: "Thursday" },
    { name: "calendar.Friday", value: "Friday" },
    { name: "calendar.Saturday", value: "Saturday" },
    { name: "calendar.Sunday", value: "Sunday" }
  ];

  const [isCheckedArray, setIsCheckedArray] = useState(
    new Array(daysOfWeek.length).fill(false)
  );
  const [isError, setIsError] = useState("");
  const [isTimePickerVisible, setIsTimePickerVisible] = useState(
    Array(7).fill(false)
  );
  const [timeColumnCounts, setTimeColumnCounts] = useState<number[]>(
    Array(7).fill(1)
  );
  const [Loading, setIsLoading] = useState(false);
  const SessionId = useParams();
  const handleNavigate = () => {
    navigate("/AttendanceAllSessions");
  };
  const { ContentContainer_BgColor } = useRecoilValue(PopupSelectorColor);
  const { checkboxBgColor,checkboxBorderColor,checkboxBorderRadius } =
    useRecoilValue(ColorSelector);
  const { t } = useTranslation();

  const daysOfWeekStrings = daysOfWeek.map(day => day.value);

  return (
    <div style={{display:"flex",width:"100%"}}>
      <ModalWrapper>
        <AddAttendanceWrapper bgcolor={"transparent"}>
         
          
      
            <StartEndDateRow>
              <LabelDiv>
                <LightTypography text={t("forms.Start Date")} fontSize="0.875rem" />
            
                  <SelectDate
                    width="16.25rem"
                    value={startDate}
                    showTime={false}
                    onChange={(date: Date | null) => setStartDate(date)}
                  />
           
              </LabelDiv>
              <LabelDiv>
                <LightTypography text={t("forms.End Date")} fontSize="0.875rem" />
                
                  <SelectDate
                    width="16.25rem"
                    value={endDate}
                    showTime={false}
                    onChange={(date: Date | null) => setEndDate(date)}
                  />
             
              </LabelDiv>
            </StartEndDateRow>
            <TitleRow>
              <MediumTypography text={t("gradebook.Meeting Day")} fontSize="0.875rem" />
            </TitleRow>
            {daysOfWeek.map((day, index) => (
              <CheckBoxRow key={index}>
                <CheckBoxColumn>
                  <DaysLabel
                    htmlFor={`checkbox_${index}`}
                    key={index}
                    onClick={() =>
                      handleCheckboxChange(
                        index,
                        isCheckedArray,
                        setIsCheckedArray,
                        setIsTimePickerVisible,
                        setStartTime,
                        setEndTime
                      )
                    }
                  >
                    <CheckBox
                      id={`checkbox_${index}`}
                      type="checkbox"
                      checked={isCheckedArray[index]}
                      name={`status_${index}`}
                      value={day.value}
                      bgcolor={checkboxBgColor}
                      bordercolor={checkboxBorderColor}
                      borderradius={checkboxBorderRadius}
                    />
                    {t(day.name)}
                  </DaysLabel>
                </CheckBoxColumn>
                {isTimePickerVisible[index] && (
                 
                    <TimeColumn>
                      {Array.from({ length: timeColumnCounts[index] }).map(
                        (_, timeIndex) => (
                          <TimePickerHolder key={`time_${index}_${timeIndex}`}>
                            <TimePickerComponent
                              placeholder="Start Time"
                              width="14.5rem"
                              absoleteBgColor={ContentContainer_BgColor}
                              value={startTime[index][timeIndex] || ""}
                              onChange={(newValue: string) =>
                                handleUpdateTime(
                                  index,
                                  timeIndex,
                                  newValue,
                                  "start",
                                  setStartTime,
                                  setEndTime
                                )
                              }
                            />
                            <TimePickerComponent
                              placeholder="End Time"
                              width="14.5rem"
                              absoleteBgColor={ContentContainer_BgColor}
                              value={endTime[index][timeIndex] || ""}
                              onChange={(newValue: string) =>
                                handleUpdateTime(
                                  index,
                                  timeIndex,
                                  newValue,
                                  "end",
                                  setStartTime,
                                  setEndTime
                                )
                              }
                            />
                            {timeIndex === 0 && (
                              <TimePickerHolderContent>
                      <AddSqIcon
                        onClick={() =>
                          handleAddTimePicker(
                            index,
                            setTimeColumnCounts,
                            setStartTime,
                            setEndTime
                          )
                        }
                      /> 
                    </TimePickerHolderContent>)}
                            {timeIndex > 0 && (
                              <TimePickerHolderContentDelete>
                              <SvgDelete
                                onClick={() =>
                                  handleDeleteTimePicker(
                                    index,
                                    setTimeColumnCounts,
                                    setStartTime,
                                    setEndTime
                                  )
                                }
                              />
                              </TimePickerHolderContentDelete>
                            )}
                          </TimePickerHolder>
                        )
                      )}
                    </TimeColumn>
                  
                
                )}
              </CheckBoxRow>
            ))}
            {isError && <LightTypography text={isError} color="red" />}
            <ButtonsHolder>
              <ButtonCancel onClick={toggleModel}>
                <MediumTypography text={t("forms.Cancel")} color="#D04B4B" />
              </ButtonCancel>
              <ButtonAdd
                onClick={() =>
                  handleGenerate(
                    daysOfWeekStrings,
                    isCheckedArray,
                    startDate,
                    endDate,
                    startTime,
                    endTime,
                    toast,
                    SessionId,
                    Loading,
                    setIsLoading,
                    navigate,
                  )
                }
              >
                <MediumTypography
                  text={Loading ? `${t('general.loading')}...` : t("gradebook.Generate")}
                  color="#fff"
                />
              </ButtonAdd>
            </ButtonsHolder>
        
        </AddAttendanceWrapper>
      </ModalWrapper>
    </div>
  );
};

export default AddAttendancePopUp;
