// This component provides a reusable header for survey analytics with search, filtering, and Excel download capabilities.

import React, { ReactElement, useEffect, useState } from "react";
import {
  AnalyticFilterLine,
  AnalyticHeader,
  ComplexSelectGreenBox,
  ComplexSelectRow,
} from "../../AnalyticsAllAssigment.style";
import { MediumTypography, Typography } from "../../../../elements/fonts/Fonts";
import { InstructorViewCompletionSearchContainer } from "../../../StudentCompletionTrackingInstrcuterView/InstructorViewCompletion.style";
import SearchElement from "../../../../elements/searchElement/SearchElement";
import SelectInput from "../../../../elements/SelectInput/SelectInput";
import { SvgDownload, SvgEyeIcon } from "../../../../elements/Icons";
import { LogoAndAction } from "../../../GradesAnalyticsTable/GradesAnalyticsTable.style";
import { SurveyComplexIDTableData } from "../SurveyComplexIDTable/SurveyComplexIDTableData";
import {
  ViewsOptions,
  handleNumberDisplays,
} from "../../../../utils/NbOfViewesObjects";
import TableViewSelectInput from "./TableViewSelectInput";

interface SurveyReusableHeaderInterface {
  title: string;
  onClickExcelDowload?: () => void;
  data: any[];
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setLimit: any;
  setShowPagination?: React.Dispatch<React.SetStateAction<boolean>>;
  FilteredOptions?: ReactElement;
  HeaderTextClick?: () => void;
}

const SurveyReusableHeader = ({
  title,
  onClickExcelDowload,
  data,
  setSearchText,
  setShowPagination,
  setLimit,
  FilteredOptions,
  HeaderTextClick,
}: SurveyReusableHeaderInterface) => {
  const [ViewText, setViewText] = useState("View All");

  const handleNumberShows = (newValue: string) => {
    handleNumberDisplays(
      newValue,
      setViewText,
      setLimit,
      data,
      setShowPagination
    );
  };

  return (
    <AnalyticHeader>
      <ComplexSelectRow>
        <div style={{ cursor: "pointer" }} onClick={HeaderTextClick}>
          <Typography text={title} fontSize="1.5rem" />
        </div>
        <InstructorViewCompletionSearchContainer>
          <SearchElement onChange={(e) => setSearchText(e.target.value)} />
        </InstructorViewCompletionSearchContainer>
      </ComplexSelectRow>
      <ComplexSelectRow>
        {FilteredOptions}

        <AnalyticFilterLine
          style={{ justifyContent: FilteredOptions ? "initial" : "flexEnd" }}
        >
          <TableViewSelectInput
            value={ViewText}
            options={ViewsOptions}
            onChange={handleNumberShows}
          />
          <LogoAndAction onClick={onClickExcelDowload}>
            <SvgDownload />
            <Typography text="Download" fontSize="0.75rem" />
          </LogoAndAction>
        </AnalyticFilterLine>
      </ComplexSelectRow>
    </AnalyticHeader>
  );
};

export default SurveyReusableHeader;
