import React, { useState } from "react";
import { DropZoneForm } from "../../dropZone/DropZoneForm";
import { TextEditor } from "../../../elements/TextEditor/TextEditor";
import {
  LightTypography,
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import {
  DateContainer,
  DateDiv,
  ScoreDiv,
  TimeContainer,
} from "./AssignmentDetails.style";
import {
  SvgAlarmIcon,
  SvgCalendarIcon,
  SvgCoursesIcon,
  SvgGradesBannerIcon,
  SvgHoursDurationIcon,
  SvgStar,
} from "../../../elements/Icons";
import { ContentR } from "../../CourseTimeline/utils/CourseResourcesInterface";
import {
  calculateDuration,
  formatDateTime,
  formatTime,
  getFormattedTime,
} from "./assignmentDetailsUtils";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { toArabicDigits } from "../../../utils/formatDayArabic";
import { TransformDirection } from "../../../utils/Direction";
// import { Divider } from "../../../Views/courseView/content/Content.style";
interface assignmentProps {
  assignment: any;
  attachment: any[];
}
export default function AssignmentDetails({
  assignment,
  attachment,
}: assignmentProps) {
  // Call the formatDateTime and formatTime function from utils to change the format of the date and time
  const { t } = useTranslation();
  const startdate = formatDateTime(assignment.startDateTime || "", t);
  const endDate = formatDateTime(assignment.dueDateTime || "", t);
  const startTime = formatTime(assignment.startDateTime || "", t);
  const endTime = formatTime(assignment.dueDateTime || "", t);
  const CutDate = formatDateTime(assignment.cutOffDateTime || "", t);
  const CutTime = formatTime(assignment.cutOffDateTime || "", t);
  const startDateTime = assignment.startDateTime || "";
  const cutDateTime = assignment.cutOffDateTime || "";
  const { backgroundColor } = useRecoilValue(ColorSelector);
  return (
    <>
      <TimeContainer bgColor={backgroundColor}>
        <div style={{ display: "flex", columnGap: "1rem" }}>
          <SvgCalendarIcon />
          {/* Dates Container */}
          <DateContainer>
            <DateDiv>
              <LightTypography
                text={t("forms.Start Date")}
                fontSize="0.75rem"
              />
              <Typography text={toArabicDigits(startdate)} fontSize="0.75rem" />
            </DateDiv>
            <DateDiv>
              <LightTypography text={t("forms.Due Date")} fontSize="0.75rem" />
              <Typography text={toArabicDigits(endDate)} fontSize="0.75rem" />
            </DateDiv>{" "}
            <DateDiv>
              <LightTypography
                text={t("forms.Cut Off Date")}
                fontSize="0.75rem"
              />
              <Typography text={toArabicDigits(CutDate)} fontSize="0.75rem" />
            </DateDiv>{" "}
          </DateContainer>
        </div>
        <div style={{ display: "flex", columnGap: "1rem" }}>
          <SvgAlarmIcon />
          {/* Time Container */}
          <DateContainer>
            <DateDiv>
              <LightTypography
                text={t("course view.Start time")}
                fontSize="0.75rem"
              />
              <Typography
                text={getFormattedTime(startTime, t)}
                fontSize="0.75rem"
              />
            </DateDiv>
            <DateDiv>
              <LightTypography
                text={t("assignment.due time")}
                fontSize="0.75rem"
              />
              <Typography text={endTime} fontSize="0.75rem" />
            </DateDiv>{" "}
            <DateDiv>
              <LightTypography
                text={t("course view.Cut Off Time")}
                fontSize="0.75rem"
              />
              <Typography
                text={getFormattedTime(CutTime, t)}
                fontSize="0.75rem"
              />
            </DateDiv>{" "}
          </DateContainer>
        </div>
        <div style={{ display: "flex", columnGap: "1rem" }}>
          <SvgHoursDurationIcon />

          {/* Duration container */}
          <DateDiv>
            <div style={{ width: "4rem" }}>
              <LightTypography
                text={t("course view.CountDown")}
                fontSize="0.75rem"
              />
            </div>
            {/* Call the calculateDuration function to calculate the duration */}
            <Typography
              text={calculateDuration(startDateTime, cutDateTime) || "-"}
              fontSize="0.75rem"
            />
          </DateDiv>
        </div>
        <div style={{ display: "flex", columnGap: "1rem" }}>
          {/* <SvgStar /> */}
          <SvgGradesBannerIcon />
          {/* Max Grade of the assignment */}
          <ScoreDiv>
            <LightTypography text={`${t("quiz.score")}:`} fontSize="0.75rem" />
            <LightTypography
              text={`__/${assignment.rubricId !== 0 &&
                  assignment.rubricId !== "" &&
                  assignment.rubricId !== null
                  ? toArabicDigits("100")
                  : toArabicDigits(assignment.maxGrade.toString())
                } ${t("course view.Points")}`}
              fontSize="0.75rem"
            />
          </ScoreDiv>
        </div>
      </TimeContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.7rem",
        }}
      >
        <TypographyRegularDescription
          text={t("assignment.Attachments")}
          whiteSpace="nowrap"
        />
        <div
          style={{ height: "0", border: "1px solid #576371", width: "100%" }}
        ></div>
      </div>

      {/* The Attachment of the assignment. (Created By The Instructor) */}
      {attachment &&
        attachment.length > 0 &&
        attachment.map(
          (item, index) =>
            item.attachmentUrl !== null &&
            item.attachmentUrl !== undefined && (
              <div
                style={{
                  display: "flex",
                  columnGap: "0.62rem",
                  border: "2px solid #576371",
                  width: "fit-content",
                  padding: "1rem",

                  paddingRight:
                    TransformDirection() === "ltr" ? "5rem" : "1rem",
                  paddingLeft: TransformDirection() === "rtl" ? "5rem" : "1rem",
                  borderRadius: "0.375rem",
                }}
                key={index} // Adding a unique key for each mapped element
              >
                <SvgCoursesIcon />
                <a
                  href={item.attachmentUrl}
                  target="_blank"
                  download="desiredFileName.docx"
                >
                  <TypographyRegularDescription
                    text="attachment" // Assuming there's a property like attachmentName
                    fontSize="0.75rem"
                  />
                </a>
              </div>
            )
        )}
    </>
  );
}
