import React from "react";
import {
  SvgCoursesIcon,
  SvgMedia,
  SvgMic,
  SvgUrl,
  SvgNews,
  SvgQuiz,
  SvgDiscussion,
  SvgVideoIcon,
  SvgLinkTree,
  SvgLink,
  SvgDocument,
  SvgGroup3339,
  SvgMonitorIcon,
  SvgFolderIcon,
  SvgVideoNew,
  SvgSurveyIcon,
} from "../elements/Icons";

export function getResourceIcon(resourceType: string) {
  switch (resourceType) {
    case "Document":
      return { icon: <SvgDocument />, color: "#21D6F8" };
    case "Picture":
      return { icon: <SvgMedia />, color: "#D07E4B" };
    case "Link":
      return { icon: <SvgUrl />, color: "#8E5DFD" };
    case "Video":
      return { icon: <SvgVideoNew />, color: "#B757E2" };
    case "Assignment":
      return { icon: <SvgNews />, color: "#5DD3B3" };
    case "Forum":
      return { icon: <SvgDiscussion />, color: "#8cedea" };
    case "Quiz":
      return { icon: <SvgQuiz />, color: "#96b4e3" };
    case "File":
      return { icon: <SvgCoursesIcon />, color: "#3DA4FC" };
    case "Link_Tree":
      return { icon: <SvgLinkTree />, color: "#CED35D" };
    case "Folder":
      return {
        icon: <SvgFolderIcon />,
        color: "#5D69FD",
      };
    case "Html":
      return { icon: <SvgMonitorIcon />, color: "#D04B4B" };
    case "Online_Session":
      return { icon: <SvgMic />, color: "#0B8FFF" };
    case "Survey":
      return { icon: <SvgSurveyIcon />, color: "	#D9E4E8" };

    default:
      return { icon: <SvgMonitorIcon />, color: "red" };
  }
  // return { icon: iconComponent, color: color };
}

export const detectIconColor = (contentType: string) => {
  switch (contentType) {
    case "Quiz":
      return "#96B4E3";
    case "Assignment":
      return "#5DD3B3";
    case "Online_Session":
      return "#0B8FFF";
    case "Survey":
      return "#D9E4E8";
    case "Forum":
      return "#8CEDEA";
    case "File":
      return "#3DA4FC";
    case "Document":
      return "#21D6F8";
    case "Folder":
      return "#5D69FD";
    case "Link":
      return "#8E5DFD";
    case "Link_Tree":
      return "#CED35D";
    case "Video":
      return "#B757E2";
    case "Html":
      return "#D04B4B";
    case "Picture":
      return "#D07E4B";
    case "Scorm":
      return "#000000";
    case "Teams":
      return "#4E5FBF";
    case "createGrade":
      return " #00FFFF";
    default:
      return ""; // You might want to add a default case as well.
  }
};

export const ThemeDetectIconColor = (contentType: string, theme: string) => {
  switch (contentType) {
    case "Quiz":
      return "#96B4E3";
    case "Assignment":
      return "#5DD3B3";
    case "Online_Session":
      return "#0B8FFF";
    case "Survey":
      return theme === "dark" ? "#D9E4E8" : "#2D3741";
    case "Forum":
      return "#8CEDEA";
    case "File":
      return "#3DA4FC";
    case "Document":
      return "#21D6F8";
    case "Folder":
      return "#5D69FD";
    case "Link":
      return "#8E5DFD";
    case "Link_Tree":
      return "#CED35D";
    case "Video":
      return "#B757E2";
    case "Html":
      return "#D04B4B";
    case "Picture":
      return "#D07E4B";
    case "Scorm":
      return "#000000";
    case "Teams":
      return "#4E5FBF";
    case "createGrade":
      return " #00FFFF";
    default:
      return ""; // You might want to add a default case as well.
  }
};
