import styled from 'styled-components'

export const ShowTaskContainer=styled.div`
min-width:45rem;
padding: 1.5rem 0rem;
`;

export const ShowTaskFlexContainer=styled.div`

display: grid;
grid-template-columns: 0.3fr 0.9fr;
/* gap:3.38rem; */


`;
export const ShowMainContainer=styled.div`
display: flex;
flex-direction:column;
gap:1.19rem;
`;

export const Line=styled.div<{color?: string}>`
min-width: 1.25rem;
border:3px solid ${({ color }) => (color ? color : "#fff")};
border-radius:20% ;
`;

export const DeadLineStatus=styled.div`
display: flex;
align-items:end;
gap:1.25rem;
`;