import styled from "styled-components"
import { breakpoints } from "../../theme";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const FourOPageLayout =styled.div`
display:flex;
align-items:center;
padding: 4rem;
justify-content:space-between;
gap:3rem;
flex-wrap:wrap;
/* margin-bottom:3rem; */
@media (max-width: ${breakpoints.tablets}) {
  flex-direction:column
  }
`;

export const DivAction =styled.div`
display:flex;
align-items:center;
height: 2.125rem;
border-radius: 1.25rem;
background: #5DD3B3;
width: 14rem;
justify-content:center;
margin-top:1rem;
cursor: pointer;

`;
export const FourText =styled.div`
padding:0.5rem;
display:flex;
flex-direction:column;
/* gap:0.56rem; */
`;

export const FourLogoPlace =styled.div`
display:flex;
flex-direction:column;
`;

export const FourOA =styled.div<StyleThemeInterface>`
  display: flex;
  position: relative;
  height: 39rem;
  width: 41%;
  padding: 0 2.2rem;
  padding-top: 3.5rem;
  flex-direction: column;
  gap: 3.35rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${props => props.theme === "light" ? '../../assets/images/404papersLight.svg' : '../../assets/images/404Left.svg'});
    background-repeat: no-repeat;
    background-size: cover;
    [dir="ltr"] & {
      transform: none;
    }
    [dir="rtl"] & {
    transform: scaleX(-1); /* Flip the background image horizontally */
    }
    z-index: -1; /* Ensure the background stays behind the content */
  }
@media (max-width: ${breakpoints.tablets}) {
width:100%

  } 
  @media (min-width: ${breakpoints.extraLarge}) {

    height:50rem;
  } 

`;
export const FourOB =styled.div<StyleThemeInterface>`
display:cover;
align-items:center;
background-image: url(${props => props.theme === "light" ?'../../assets/images/404PageRight.svg':'../../assets/images/404PageRightLight' });
background-repeat: no-repeat;
height:25rem;
width:54%;
/* background-color:aqua; */
background-size: cover; 
align-self:flex-end;

@media (max-width: ${breakpoints.tablets}) {
width:100%
  } 
  @media (min-width: ${breakpoints.extraLarge}) {

height:35rem;
} 

`;

export const FourOc =styled.div`
/* display:cover; */
/* align-items:center; */
/* background-image: url('../../assets/images/404PageRight.svg'); */
/* background-repeat: no-repeat; */
/* height:25rem; */
/* width:54%; */
/* background-color:aqua; */
background-size: cover; 
align-self:flex-end;
[dir="ltr"] & {
      transform: none;
    }
    [dir="rtl"] & {
    transform: scaleX(-1); /* Flip the background image horizontally */
    }

@media (max-width: ${breakpoints.tablets}) {
width:100%
  } 
  @media (min-width: ${breakpoints.extraLarge}) {

height:35rem;
} 

`;