import React, { FC } from "react";

import { BodyContainer, TextWrap } from "./DropQuestion.style";
import { DropQuestionProps } from "./DropQuestionInterface";
import { MediumTypography } from "../../elements/fonts/Fonts";
import { theme } from "../../theme";
import { useDrop } from "react-dnd";
import { ReactComponent as AddIcon } from "../../assets/icons/Add (1).svg";
import { useQuizMyContext } from "../../contexts/CourseCreatorQuizContext";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
const DropQuestion: FC<DropQuestionProps> = ({
  DropQuestionType,
  questions,
  loadingData,
  addMoreQuestion,
}) => {
  const { editQuiz } = useQuizMyContext();
  const { t } = useTranslation();

  const [, drop] = useDrop({
    accept: "ITEMS",
    drop: (droppedItem: any) => {
      const { data } = droppedItem;
      DropQuestionType(data);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const { Container_Gradient_BgColor, boxShadow } =
    useRecoilValue(ColorSelector);
  return (
    <BodyContainer
      bgcolor={Container_Gradient_BgColor}
      boxshadow={boxShadow}
      ref={drop}
    >
      <TextWrap>
        {editQuiz && loadingData ? (
          <MediumTypography text={`${t('general.loading')}...`} fontSize="1rem" />
        ) : questions.length > 0 ? (
          <MediumTypography
            text={t("quiz.Drag and Drop a question type to start editing")}
            fontSize="1rem"
          />
        ) : (
          <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
            <MediumTypography
              text={t("quiz.Create a Question First")}
              fontSize="1rem"
            />
            <AddIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                addMoreQuestion && addMoreQuestion("normal");
              }}
            />
          </div>
        )}
      </TextWrap>
    </BodyContainer>
  );
};

export default DropQuestion;
