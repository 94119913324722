import styled from "styled-components";

export const StyledDropDownMenu = styled.div<{ bgColor: string }>`
  width: 17rem;
  max-height: 25.625rem;
  background-color: ${(props) => props.bgColor};
  border-radius: 0.625rem;
  box-shadow: 0px 4px 20px #00000040;
  padding: 1.5rem 2rem;
`;
export const DropDownMenuInfoHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
`;
export const EmailUsernameHolder = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.4rem;
  padding-left: 0.5rem;
`;

export const DropDownFooter = styled.div``;
export const DividLine = styled.div`
  border: 1px solid #aab1b9;
  padding-left: 0.5rem;
`;
