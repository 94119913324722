import { FC } from "react";
import { IconWrapper, IconContainer, Tooltip } from "./CourseToolbar.style";
import { MediumTypography } from "../../elements/fonts/Fonts";
import { useDrag } from "react-dnd";
import { SideBarCourseIcons } from "./courseToolbarInterface";

import { useRecoilValue } from "recoil";
import { courseCreatorColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
interface ToolBarProps {
  item: SideBarCourseIcons;
  index: number;
}

const CourseToolbar: FC<ToolBarProps> = ({ item, index }) => {
  const { t } = useTranslation();
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "ITEM",
    item: { type: "ITEM", data: item },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  // {isDragging && console.log(object)}s
  // console.log("isDragging: ", isDragging);

  // Recoil state and selectors
  const { surveyIconColor, tooltipBgColor, tooltipTextColor, boxShadow } =
    useRecoilValue(courseCreatorColorSelector);
  return (
    <IconWrapper>
      <IconContainer
        ref={drag}
        style={{
          // border: isDragging ? `1px solid ${item.color}` : "0px",
          opacity: isDragging ? "0.3" : "1",
        }}
      >
        <item.icon
          className="iconItem"
          style={{
            marginLeft: `${
              item.type === "Online_Session"
                ? "0.12rem"
                : item.type === "Video"
                ? "-0.12rem"
                : "0rem"
            }`,
          }}
          color={item.type === "Survey" ? surveyIconColor : item.color}
        />
      </IconContainer>
      <Tooltip
        bgcolor={tooltipBgColor}
        textcolor={tooltipTextColor}
        boxshadow={boxShadow}
      >
        <MediumTypography
          text={t(item.name)}
          fontSize="0.625rem"
          whiteSpace="nowrap"
        />
      </Tooltip>
    </IconWrapper>
  );
};

export default CourseToolbar;
