import { QuestionsCartiristicsInterface } from "./QuizQuestionsAnalyticsinterface";

export const QuestionsCartiristicsData : QuestionsCartiristicsInterface[] =[
  {
    QId:"1",
    Text:"Time Spent",
  },
  {
    QId:"2",
    Text:"Answered",
  },
  {
    QId:"3",
    Text:"Grade",
  },
]
