import React, { FC, useState } from "react";
import {
  StatisticsContainer,
  GradeContainer,
  SeparateLine,
  Grade,
  GradeNumber,
  OverGrade,
} from "./StatisticsGrade.style";
import { Typography } from "../../../../elements/fonts/Fonts";
import { StatisticsGradeProps } from "./StatisticsGradeInterface";
import { Grades } from "./StatisticsGradeIDatanterface";
import { useTranslation } from "react-i18next";

const initialGrades: Grades[] = [
  {
    grade: "10",
    name: "assignment.SUBMITTED",
    over: "/20",
    line: true,
  },
  {
    grade: "6",
    name: "dashboard.Not Submitted",
    over: "/20",
    line: true,
  },
  // {
  //   grade: '15',
  //   name: 'AVERAGE',
  //   over: '/100',
  //   line: false,
  // },
];

const StatisticsGrade: FC<StatisticsGradeProps> = ({
  submitted,
  totalSubmissions,
  avarage,
  graded,
  students,
  student,
  enrollements,
}) => {
  // const [totalSubmissionsState, setTotalSubmissions] = useState(totalSubmissions);
  // const [gradedCount, setGradedCount] = useState(graded);
  const grades = [...initialGrades];
  const { t } = useTranslation();
  const language = localStorage.getItem("language")

  if (
    totalSubmissions !== undefined
    // avarage !== undefined
  ) {
    grades[0].grade = totalSubmissions.toString();
    grades[1].grade = `${enrollements - totalSubmissions}`;
    // grades[2].grade = avarage.toString();
  }

  // console.log("Submitted is :" , totalSubmissions)

  grades[1].over = `/${enrollements}`;

  grades[0].over = `/${enrollements}`;

  return (
    <StatisticsContainer>
      {grades.map((grade, index) => (
        <React.Fragment key={index}>
          <GradeContainer>
            <Grade>
              <GradeNumber>{grade.grade}</GradeNumber>
              <OverGrade>
                <Typography
                  text={grade.over}
                  fontSize="1.5rem"
                  fontWeight="400"
                  lineHeight="normal"
                />
              </OverGrade>
            </Grade>

            <Typography
              text={t(grade.name).toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}
              fontSize="0.75rem"
              fontWeight="400"
              lineHeight="normal"
              // color="#D9E4E8"
            />
          </GradeContainer>

          {index === 0 && <SeparateLine show={grade.line.toString()} />}
        </React.Fragment>
      ))}
    </StatisticsContainer>
  );
};

export default StatisticsGrade;
