import React from "react";
import {
  CoursesNumberHolder,
  OverlapGroup,
  Element,
} from "./CoursesNumber.style";
import { CourseLabelInterface } from "../courseLabelInterface";
import { Typography } from "../../fonts/Fonts";

export const CoursesNumber = ({
  courseNumber,
  color,
  gradientColor,
}: CourseLabelInterface): JSX.Element => {
  const courseNumberstring = courseNumber.toString();
  return (
    <CoursesNumberHolder color={color}>
      <OverlapGroup gradientcolor={gradientColor}>
        <Element>
          <Typography
            fontSize="1.125rem"
            fontWeight="600"
            text={courseNumberstring}
          />
        </Element>
      </OverlapGroup>
    </CoursesNumberHolder>
  );
};
