import React, { FC } from "react";
import { theme } from "../../../theme";
import { TypographyRegularDescription } from "../../../elements/fonts/Fonts";
import {
  GradePointsContainer,
  PointsGradeLine,
} from "./QuestionComposedStat.style";
import { QuestionComposedStatProps } from "./QuestionComposedStatInterface";
const QuestionComposedStat: FC<QuestionComposedStatProps> = ({
  question,
  showParts,
}) => {
  return (
    <>
      <GradePointsContainer>
        <TypographyRegularDescription
          text={`${question.questPart.length} Parts`}
          fontSize="0.75rem"
          lineHeight="1.125rem"
          color={theme.dark.main_color}
        />
        {/* <TypographyRegularDescription
                    text={`${question.questPart.reduce((sum: number, part) => {
                        const gradeAsNumber = parseFloat(part.grade);
                        return sum + (isNaN(gradeAsNumber) ? 0 : gradeAsNumber);
                    }, 0)} pts`}
                    fontSize='0.75rem'
                    lineHeight='1.125rem'
                    color={theme.dark.main_color}
                /> */}
      </GradePointsContainer>
      {showParts ? <PointsGradeLine /> : ""}
    </>
  );
};
export default QuestionComposedStat;
