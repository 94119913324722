import React, { useState } from "react";
import DragAndDropZone from "../dropZone/main/dropzone";
import SelectInput from "../../elements/nestedSelect/SelectInput";
import { InputContainer, InputLabel, PopupContainer, UploadGradeContainer, UploadGradeWrapper } from "./ManageWeight.style";
import { LightTypography, Typography } from "../../elements/fonts/Fonts";
import { DropZoneForm } from "../dropZone/ManageWeightDropzone";

const UploadGradePopup = () => {
  const SelectData: any = [
    {
      title: "Assigment",
      submenu: [
        {
          title: "Assigment 01",
        },
        {
          title: "Assigment 02",
        },
        {
          title: "Assigment 03",
        },
      ],
    },
    {
      title: "Quiz",
      submenu: [
        {
          title: "Quiz 01",
        },
        {
          title: "Quiz 02",
        },
        {
          title: "Quiz 03",
        },
      ],
    },
    {
      title: "Forum",
      submenu: [
        {
          title: "Forum 01",
        },
        {
          title: "Forum 02",
        },
        {
          title: "Forum 03",
        },
      ],
    },
  ];

  const [SelectedInput, setSelectedInput] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedType, setSelectedType] = useState<any>();

  const handleOptionChange = (value: string) => {
    setSelectedInput(value);
  };

  const handleDrop = (acceptedFiles: File[]) => {
    setSelectedFile(acceptedFiles[0]);
  };

  const accF: { [key: string]: string[] } = {
    "application/pdf": [".pdf"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    "application/vnd.ms-excel": [".xls"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ]
  };
  return (
      <UploadGradeWrapper>
        <UploadGradeContainer>


          <InputLabel>
            <LightTypography text="Activity Name" fontSize="14px" fontWeight="400" />
          </InputLabel>


          <SelectInput
            onChange={handleOptionChange}
            options={SelectData}
            value={SelectedInput}
            width="445px"
            optionWidth="445px"
          />
        </UploadGradeContainer>

        <DropZoneForm header={""} />
      </UploadGradeWrapper>
  );
};

export default UploadGradePopup;
