import React, { FC, useState, useEffect } from "react";
import ToDoFilters from "../../components/ToDoFilters/main/ToDoFilters";
import { Typography } from "../../elements/fonts/Fonts";
import { theme } from "../../theme";
import ToDoDetailsHeader from "../../components/ToDoDetailsHeader/main/ToDoDetailsHeader";
import ToDoSections from "../../components/ToDoSections/main/ToDoSections";
import { Task } from "./ToDoDataIntrface";
import { initialTasks } from "./ToDo.data";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingComp } from "../../components/LoadingComp/LoadingComp";

const ToDoView: FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [selectedPriority, setSelectedPriority] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const { t } = useTranslation();
  const [EnrollmentData, setEnrollmentData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { user } = useAuth0();
  const tenantName = localStorage.getItem("tenant");
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");

  // Create a function to retrieve data from local storage
  const getTasksFromDataBase = async () => {
    try {
      const response = await axios.get(
        `https://0okzb6vum6.execute-api.eu-west-1.amazonaws.com/dev/all/${subIdSplitted}/${tenantName}?provider=${provider}`
      );
      setTasks(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error retrieving tasks", error);
    }
  };

  const getEnrollmentData = async () => {
    try {
      const response = await axios.get(
        `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/enrollments/byUser/all/${tenantName}/${subIdSplitted}?provider=${provider}`
      );
      // Extracting only id and title keys from each object in the array
      const formattedData = response.data.data.map((enrollment: any) => ({
        id: enrollment.id,
        name: enrollment.title, // Assuming 'name' is meant to be 'title'
      }));

      setEnrollmentData(formattedData);
    } catch (err) {
      console.log(err);
    }
  };

  // Call the function to retrieve data when the component mounts
  useEffect(() => {
    getTasksFromDataBase();
    getEnrollmentData();
  }, []);

  // Function to filter tasks based on the selected category
  const filterTasksByCategory = (categories: string[]) => {
    setSelectedCategory(categories);
  };

  // Function to filter tasks based on the selected priority
  const filterTasksByPriority = (priority: string | null) => {
    setSelectedPriority(priority);
  };

  // Function to filter tasks based on the selected status
  const filterTasksByStatus = (status: string | null) => {
    setSelectedStatus(status);
  };

  const filteredTasks = tasks.filter((task) => {
    // Filter by category
    if (
      selectedCategory.length > 0 &&
      !selectedCategory.includes(task.enrollment || "")
    ) {
      return false;
    }

    // Filter by priority
    if (selectedPriority && task.proiority !== selectedPriority) {
      return false;
    }

    // Filter by status
    if (selectedStatus) {
      if (selectedStatus === "Near Deadline" && task.date) {
        // Define your own time frame for "near deadline"
        const deadline = new Date(task.date);
        const currentTime = new Date();
        const timeDifference = +deadline - +currentTime;
        if (timeDifference >= 0 && timeDifference <= 24 * 3600000) {
          // Task is "Near Deadline"
          return true;
        }
      } else if (selectedStatus === "Over Due" && task.date) {
        // Check if the task is overdue based on its date
        if (new Date(task.date) < new Date()) {
          // Task is overdue
          return true;
        }
      } else if (selectedStatus === "No Deadline" && !task.date) {
        // Task has "No Deadline"
        return true;
      }
      return false;
    } else {
      // No status selected, so include the task
      return true;
    }
  });

  // This function is when hovering a task
  const handleDropTask = (
    dragIndex: number,
    section: string,
    hoverIndex: number
  ) => {
    // Create a copy of the tasks array
    const updatedTasks = [...tasks];

    // Get the dragged task
    const draggedTask = updatedTasks[dragIndex];

    // Remove the dragged task from its original position
    updatedTasks.splice(dragIndex, 1);

    // Insert the dragged task into the new position
    updatedTasks.splice(hoverIndex, 0, draggedTask);

    // Update the 'section' property of the dragged task based on the target section
    if (section === "To Do") {
      draggedTask.section = "ToDo";
    } else if (section === "IN PROGRESS") {
      draggedTask.section = "inProgress";
    } else if (section === "Done") {
      draggedTask.section = "Done";
    }

    // Update the state with the new task order
    setTasks(updatedTasks);
  };

  // This function is when we dropped the task
  const handleAfterDropTask = async () => {
    try {
      const updateResponse = await axios.put(
        `https://0okzb6vum6.execute-api.eu-west-1.amazonaws.com/dev/updateAllTasks/${tenantName}`,
        {
          data: tasks,
        }
      );
      getTasksFromDataBase();
    } catch (err) {
      console.log(err);
    }
  };

  // edit task
  const handleEditTask = async (index: number, task: any) => {
    try {
      // Task to update object
      const taskToUpdate = {
        id: task.id,
        title: task.name,
        enrollment: task.enrollment,
        classSourceId: EnrollmentData.find(
          (enrollment) => enrollment.name === task.enrollment
        )?.id,
        priority: task.proiority,
        dueDate: task.date,
        webLink: task.webLink,
        subId: subIdSplitted,
        provider: provider,
        description: task.description,
      };
      const updateResponse = await axios.put(
        `https://0okzb6vum6.execute-api.eu-west-1.amazonaws.com/dev/update/${tenantName}`,
        {
          data: taskToUpdate,
        }
      );
      getTasksFromDataBase();
    } catch (err) {
      console.log(err);
    }

    // Set the state with the updated tasks array
    // setTasks(editTask);
  };

  // delete task
  const removeTask = async (index: number) => {
    // Create a copy of the tasks array
    let task = [...tasks];
    try {
      const deleteResponse = await axios.delete(
        `https://0okzb6vum6.execute-api.eu-west-1.amazonaws.com/dev/${task[index].id}/${tenantName}`
      );
      getTasksFromDataBase();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    // Implement the logic to clear the selected filters or perform any other action
    setSelectedCategory([]); // Example: Clear selected categories
    setSelectedPriority(null); // Example: Clear selected priority
    setSelectedStatus(null); // Example: Clear selected status
  };

  // Check if any data is selected based on selectedCategory, selectedPriority, and selectedStatus
  const hasDataSelected: boolean =
    selectedCategory.length > 0 ||
    selectedPriority !== null ||
    selectedStatus !== null;

  return (
    <>
      {loading ? (
        <LoadingComp />
      ) : (
        <>
          {/* filters and create */}
          <ToDoFilters
            filterTasksByPriority={filterTasksByPriority}
            selectedPriority={selectedPriority}
            filterTasksByStatus={filterTasksByStatus}
            selectedStatus={selectedStatus}
            handleCancel={handleCancel}
            hasDataSelected={hasDataSelected}
            setTasks={setTasks}
          />

          {/* filters */}
          <ToDoDetailsHeader
            filterTasksByCategory={filterTasksByCategory}
            selectedCategory={selectedCategory}
          />

          {/* todo sections (todo/inProgress/done) */}
          <ToDoSections
            tasks={filteredTasks}
            handleDropTask={handleDropTask}
            handleAfterDropTask={handleAfterDropTask}
            handleEditTask={handleEditTask}
            removeTask={removeTask}
          />
        </>
      )}
    </>
  );
};
export default ToDoView;
