import React, { useState, useEffect } from "react";
import {
  AnalyticsTopParticipantBoxContainer,
  AnalyticsTopParticipantStudentCols,
  AnalyticsTopParticipantStudentInfos,
  AnalyticsTopParticipantStudentMainRow,
  AnalyticsTopParticipantStudentProfilePic,
  AnalyticsTopParticipantRenderedContainer,
  AnalyticsTopParticipantStudentNbPostReplyRow,
  AnalyticsTopParticipantStudentNbPostMenuContainer,
  AnalyticsTopParticipantStudentNbPostMenuCell,
} from "./AnalyticsTopParticipantForum.style";
import {
  Typography,
  LightTypography,
  MediumTypography,
} from "../../elements/fonts/Fonts";
import { AnalyticsTopParticipantForumData } from "./AnalyticsTopParticipantForumData";
import MappedIndexClickOutSide from "../../hooks/MapedIndexClickOutSide";
import { Link } from "react-router-dom";
import { PostsTimeLineInterface } from "../PostsTimeLine/PostsTimeLineInterface";
import { PointerContainer } from "../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableTable.style";
import { SvgEye, SvgMessageIcon, SvgMore } from "../../elements/Icons";
// import { PostData } from "../../../interfaces/PostData"; // Import the correct interface for PostData

const AnalyticsTopParticipantBox: React.FC = () => {
  const [SelectedIndex, setSelectedIndex] = useState<number | null>(null);

  const clickOutsideRef = MappedIndexClickOutSide(() => setSelectedIndex(null)); // Pass the callback to close the component

  const [TopParticipantData, setTopParticipantData] = useState<
    PostsTimeLineInterface[]
  >([]);

  useEffect(() => {
    const sortedData = [...AnalyticsTopParticipantForumData].sort((a, b) => {
      const aPosts = a.Posts ? a.Posts.length : 0;
      const bPosts = b.Posts ? b.Posts.length : 0;
      if (aPosts !== bPosts) {
        return bPosts - aPosts;
      } else {
        const aReplies = a.Posts
          ? a.Posts.reduce((acc, post) => acc + (post.Reply?.length ?? 0), 0)
          : 0;
        const bReplies = b.Posts
          ? b.Posts.reduce((acc, post) => acc + (post.Reply?.length ?? 0), 0)
          : 0;
        return bReplies - aReplies;
      }
    });
    const top3Data = sortedData.slice(0, 3);
    setTopParticipantData(top3Data);
  }, []);

  const handelDisplayEditPop = (index: number) => {
    if (SelectedIndex === index) {
      setSelectedIndex(null);
    } else {
      setSelectedIndex(index);
    }
  };

  return (
    <AnalyticsTopParticipantBoxContainer>
      <Typography text="Top Participants" fontSize="1.125rem" />
      {TopParticipantData.map((item, index) => {
        const Selected = SelectedIndex === index;
        return (
          <AnalyticsTopParticipantRenderedContainer key={item.SenderPostId}>
            {item.Posts && item.Posts.length > 0 && (
              <AnalyticsTopParticipantStudentMainRow>
                <AnalyticsTopParticipantStudentInfos>
                  <AnalyticsTopParticipantStudentProfilePic
                    src={
                      item.Posts[0].posterProfilePic ??
                      require("../../assets/userProfile.png")
                    }
                  />
                  <AnalyticsTopParticipantStudentCols>
                    <Typography text={item.Posts[0].posterName || ""} />
                    <AnalyticsTopParticipantStudentNbPostReplyRow>
                      <LightTypography
                        text={
                          item.Posts.reduce(
                            (acc, post) => acc + (post.Reply?.length ?? 0),
                            0
                          ) > 0
                            ? `${item.Posts.reduce(
                                (acc, post) => acc + (post.Reply?.length ?? 0),
                                0
                              )} Replies`
                            : "0"
                        }
                        fontSize="0.75rem"
                      />
                      <LightTypography
                        text={`${item.Posts.length.toString()} Posts`}
                        fontSize="0.75rem"
                      />
                    </AnalyticsTopParticipantStudentNbPostReplyRow>
                  </AnalyticsTopParticipantStudentCols>
                </AnalyticsTopParticipantStudentInfos>
                <div style={{ position: "relative" }}>
                  <PointerContainer onClick={() => handelDisplayEditPop(index)}>
                    <SvgMore style={{ transform: "rotate(90deg)" }} />
                  </PointerContainer>
                  {Selected && (
                    <div ref={clickOutsideRef}>
                      <AnalyticsTopParticipantStudentNbPostMenuContainer>
                        {/* <AnalyticsTopParticipantStudentNbPostMenuCell>
                          <SvgMessageIcon />
                          <MediumTypography
                            text="Message Student"
                            fontSize="0.75rem"
                            lineHeight="1.375rem"
                          />
                        </AnalyticsTopParticipantStudentNbPostMenuCell> */}
                        <Link to={`Analytics/Posts/${item.SenderPostId}`}>
                          <AnalyticsTopParticipantStudentNbPostMenuCell>
                            <SvgEye />
                            <MediumTypography
                              text="View Posts/Replies"
                              fontSize="0.75rem"
                              lineHeight="1.375rem"
                            />
                          </AnalyticsTopParticipantStudentNbPostMenuCell>
                        </Link>
                      </AnalyticsTopParticipantStudentNbPostMenuContainer>
                    </div>
                  )}
                </div>
              </AnalyticsTopParticipantStudentMainRow>
            )}
          </AnalyticsTopParticipantRenderedContainer>
        );
      })}
    </AnalyticsTopParticipantBoxContainer>
  );
};

export default AnalyticsTopParticipantBox;
