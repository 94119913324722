import styled from "styled-components";
import { theme } from "../../../theme";
export const FeedBackContainer = styled.div`
  padding: 0 1.5rem 2.25rem 1.5rem;
  margin-top: 1.25rem;
  display: flex;
  gap: 0.62rem;
  align-items: center;
  line-height: 1.375rem;
`;

export const FeedBackInput = styled.input<{
  show: any;
  color?: string;
  Validated_BgColor?: string;
  borderColor?: string;
}>`
  margin-top: 0.5rem;
  min-width: 10rem;
  flex: 1;
  height: 2.8125rem !important;
  border-radius: 0.625rem;
  border: 1px solid
    ${(props) =>
      props.show === "true" ? props.Validated_BgColor : props.borderColor};
  background: transparent;
  /* &:dir(ltr) {
    padding-left: 1.25rem;
  }
  &:dir(rtl) {
    padding-right: 1.25rem;
  } */
  padding: 0 1.25rem;
  font-family: "Primary Font-Light";
  color: ${(props) => props.color};
  background: ${(props) =>
    props.show === "true" ? props.Validated_BgColor : ""};
  &&:focus {
    outline: none;
    color: ${(props) => props.color};
  }
  &::placeholder {
    opacity: 0.5;
  }
`;
