import React, { useState } from "react";
import {
  MainHolder,
  InputHolder,
  SearchIconHolder,
} from "./ReusableInput.style";
import { FC } from "react";
import { InputInterface } from "./reusableInputInterface";
import { SvgSearchInput } from "../Icons";
import { LightTypography } from "../fonts/Fonts";

import { useRecoilValue } from "recoil";
import { courseCreatorColorSelector } from "../../recoil/ThemeSelectors";

const ReusableInput: FC<InputInterface> = ({
  placeHolder,
  inputWidth,
  inputHeight,
  inputBorderRadius,
  inputBorder,
  padingLeft,
  display,
  value,
  right,
  left,
  holderWidth,
  inputTupe,
  handleSubGroupInputChange,
  handleStudentInputChange,
}) => {
  // Recoil state and selectors
  const { addModuleIconColor } = useRecoilValue(courseCreatorColorSelector);
  return (
    <MainHolder inputwidth={inputWidth || "100%"}>
      <InputHolder
        color={addModuleIconColor}
        placeholder={placeHolder || "Announcement Title"}
        inputheight={inputHeight || "3.5rem"}
        inputborderradius={inputBorderRadius || "0.94rem"}
        inputborder={inputBorder || "1px solid #576371"}
        padingleft={padingLeft || "1.19rem"}
        onChange={
          inputTupe === "Student"
            ? handleStudentInputChange
            : handleSubGroupInputChange
        }
        value={value}
        type="number"
        holderWidth={holderWidth || "100%"}
      />
      <SearchIconHolder
        display={display || "none"}
        right={right || "1.25rem"}
        left={left || "none"}
      >
        <SvgSearchInput />
      </SearchIconHolder>
    </MainHolder>
  );
};

export default ReusableInput;
