import styled from "styled-components";
import { breakpoints } from "../../../theme";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";
interface ManageWeightStyles extends StyleThemeInterface {
  firstChildBorder?: string;
  borderBottom?: string;
  firstChildBorderRight?: string;
  firstChildBgColor?: string;
}
export const ManageWeightTable = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  /* background:green; */
  /* gap:1.2rem; */
`;
export const ManageWeightHeaderTr = styled.div<StyleThemeInterface>`
  display: flex;
  width: 100%;
  background: ${(props) => props.bgcolor};
`;

export const ManageWeightHeaderCell = styled.div`
  display: flex;

  align-items: center;
  padding: 1.25rem 1.55rem;
  &:first-child {
    flex: 0.7;
    /* background:yellow; */
    min-width: 14rem;
  }
  &:nth-child(2) {
    flex: 1.2;
    /* background:blue; */
    padding-left: 5rem;
  }
  &:nth-child(3) {
    flex: 1;
    /* background:black; */
    justify-content: center;
  }
  &:last-child {
    flex: 1;
    /* background:purple;| */
    justify-content: center;
  }
`;

export const ManageWeightAttendanceCell = styled.div<ManageWeightStyles>`
  display: flex;

  align-items: center;
  &:dir(ltr) {
    padding: 1.31rem 1.55rem 1.2rem 1.55rem;
  }
  &:dir(rtl) {
    padding: 1.55rem 1.55rem 1.2rem 1.31rem;
  }
  &:first-child {
    /* background: yellow; */
    min-width: 14rem;
    /* border-right: ${(props) => props.firstChildBorderRight}; */
  }
  &:nth-child(2) {
    flex: 1.15;
    /* background: blue; */
    &:dir(ltr) {
      padding-left: 9%;
    }
    &:dir(rtl) {
      padding-right: 9%;
    }

    @media (min-width: ${breakpoints.extraLarge}) {
      &:dir(ltr) {
        padding-left: 11%;
      }
      &:dir(rtl) {
        padding-right: 11%;
      }
    }
  }
  &:nth-child(3) {
    flex: 1;
    /* background: black; */
    justify-content: center;
  }
  &:last-child {
    flex: 1;
    /* background: purple; */
    justify-content: center;
  }
`;

export const ManageWeightFlexTr = styled.div<ManageWeightStyles>`
  display: flex;
  width: 100%;

  /* background: brown; */
`;

export const ManageWeightBodyCellFirst = styled.div<ManageWeightStyles>`
  display: flex;

  /* background: green; */
  /* min-width:14rem; */

  /* width: 100vw; */
  /* justify-content:space-between; */
  /* grid-template-columns: 31.5% auto 19%; */
  /* justify-content: space-between; */
  /* gap: 12%; */
  white-space: nowrap;
  /* gap:0.63rem; */
  width: 100%;
  border-top: 0.0625rem solid #57637180;
`;

export const ManageWeightBodyCellSize = styled.div`
  display: flex;
  flex: 0.7;
  /* background: green; */
  /* min-width:14rem; */

  /* width: 100vw; */
  justify-content: space-between;
  /* grid-template-columns: 31.5% auto 19%; */
  /* justify-content: space-between; */
  /* gap: 12%; */
  white-space: nowrap;

  &:first-child {
    flex: 0.534;
    &:dir(ltr) {
      border-right: 0.0625rem solid #57637180;
    }
    &:dir(rtl) {
      border-left: 0.0625rem solid #57637180;
    }

    /* background: aqua; */
    min-width: 14rem;
    gap: 0.6rem;

    @media (min-width: ${parseInt(breakpoints.extraLarge) + 500}px) {
      flex: 0.3;
    }

    @media (max-width: "1300px") {
      flex: 0.534;
    }
  }

  &:last-child {
    flex: 2.4;
    /* background: purple; */
    justify-content: center;
  }
`;

export const ManageWeightBodyTds = styled.div`
  display: flex;
  flex: 0.7;
  padding: 1.31rem 1.55rem 1rem 1.55rem;
  &:nth-child(1) {
    min-width: 14rem;
    flex: 0.8402;
    /* flex:0.534; */
    /* background: pink; */
    /* min-width:14rem; */
    padding-left: 5rem;

    @media (min-width: ${parseInt(breakpoints.extraLarge) + 500}px) {
      flex: 0.85 !important;
      padding-left: 12%;
    }
    /* @media (max-width: "1300px") {
      flex: 0.534;
    } */
  }
  &:nth-child(2) {
    /* flex:2.4; */
    /* background: black; */
    justify-content: center;
    gap: 0.6rem;
  }
  &:last-child {
    /* flex:2.4; */
    /* background: purple; */
    justify-content: center;
  }

  @media (min-width: ${parseInt(breakpoints.extraLarge) + 500}px) {
    &:nth-child(1) {
      flex: 0.938;
      /* flex:0.534; */
      /* background: pink; */
      /* min-width:14rem; */
    }
  }
`;

export const ManageWeightBodyCellTitleTD = styled.div`
  display: flex;
  width: 100%;
`;

export const ManageWeightBodyCellContents = styled.div<ManageWeightStyles>`
  /* display:flex;
flex-direction:column;


    min-width:14rem; */

  display: flex;

  width: 100%;
  justify-content: space-between;
  /* grid-template-columns: 31.5% auto 19%; */
  /* justify-content: space-between; */
  /* gap: 12%; */
  white-space: nowrap;
  &:nth-child(odd) {
    background: ${(props) => props.nth_child_bgcolor};
  }
  /* background: red; */
`;

export const ManageWeightBodyTdCells = styled.div<ManageWeightStyles>`
  display: flex;

  align-items: center;
  &:dir(ltr) {
    padding: 1.31rem 1.55rem 1.2rem 1.55rem;
  }
  &:dir(rtl) {
    padding: 1.55rem 1.55rem 1.2rem 1.31rem;
  }

  /* border-right:0.0625rem solid #57637180; */
  &:first-child {
    flex: 0.7;
    background: ${(props) => props.firstChildBgColor};
    /* background: yellow; */
    min-width: 14rem;
    &:dir(ltr) {
      border-right: 0.0625rem solid #57637180;
    }
    &:dir(rtl) {
      border-left: 0.0625rem solid #57637180;
    }
  }
  &:nth-child(2) {
    flex: 1.2;
    /* background: blue; */
    &:dir(ltr) {
      padding-left: 5rem !important;
    }
    &:dir(rtl) {
      padding-right: 5rem !important;
    }
  }
  &:nth-child(3) {
    flex: 1;
    /* background: black; */
    justify-content: center;
    gap: 0.6rem;
  }
  &:last-child {
    flex: 1;
    /* background: purple; */
    justify-content: center;
  }
`;
