import styled from "styled-components";
import { theme } from "../../theme";
import CheckIcon from "../../assets/icons/checkbox.svg";
import { CheckBoxInterface } from "./CheckboxSquareInterface";

export const CheckBox = styled.input<CheckBoxInterface>`
  min-width: 1rem;
  min-height: 1rem;
  
  /* background: ${(props) => (props.theme === "dark" ? props.bgcolor : "transparent")}; */
  appearance: none;
  border: ${(props) => (props.theme === "dark" ? "1px solid #fff" : `1px solid ${props.inputbordercolor}`)};
  border-radius: 0.188rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  transition: background-color 0.3s ease;
  background:${props =>props.bgcolor};
 border: 1px solid ${props =>props.bordercolor};
  border-radius:${props =>props.borderradius};
  &:hover{
  
  background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
}

:focus  {
    background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
    border: .05em solid #5DD3B3;
  }

  &:checked {
    background-image: url(${CheckIcon}); /* Use the CheckIcon component as a URL */
    background-size: cover; /* Adjust this as needed */
    background-repeat: no-repeat;
    background-position: center;
    border: none;
  }
  cursor: pointer;
`;