import styled from "styled-components";

export const AnalyticShadowHeaderFourDisplaysContainer = styled.div`
width:100%;
display: flex;
padding:1.25rem 2rem;
justify-content:space-between;
border-radius: 0.625rem;
background: #2D3741;
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
/* height: 5.5625rem; */
flex-wrap:wrap;
gap:1.2rem;
`;

export const AnalyticShadowHeaderFourDisplaysTextColumn = styled.div`
display: flex;
flex-direction:column;
gap:0.50rem;
justify-content:space-between;
`;
export const AnalyticShadowHeaderFourDisplaysTextOpacity = styled.div`
opacity:0.5;
display: flex;
`;

export const AnalyticShadowHeaderFourDisplaysLine = styled.div`
display: flex;
width:1.2px;
height:2.375rem;
background-color:#576371;
`;









//////////////////////////AnalyticOneTitleHeader////////////
export const AnalyticOneTitleHeaderContainer = styled.div`
display: flex;
width:100%;
border-radius: 0.625rem;
padding: 1.25rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
cursor: pointer;
`;