import React from "react";
import { AnalyticDefaultTable } from "../../../elements/AnalyticDefaultTable/AnalyticDefaultTable";
import { AssignmentDefaultTableData } from "./AssigmentDefaultTableData";

const AssignmentDefaultTable = () => {
  return (
    <>
      <AnalyticDefaultTable
        title="Assignment Details"
        DatabaseTableName={AssignmentDefaultTableData}
        // Is Table Header Changes Data
        MappedHeadText={AssignmentDefaultTableData.map(
          (val) => val.AssignmentDetailsTitle
        )}
        LinkedText="AssignmentDetails"
        ////////
      />
    </>
  );
};

export default AssignmentDefaultTable;
