import React, { useState } from "react";
import ForumParticipationDetailsTable from "../ForumParticipationDetailsTable/ForumParticipationDetailsTable";
import { AnalyticMainBoxContainer } from "../../AnalyticsAllAssigment.style";
import SurveyReusableHeader from "../../SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableHeader";
import ForumParHeaderFilter from "./ForumParHeaderFilter";
// import { FPDetailsTableData } from "../ForumParticipationDetailsTable/ForumParticipationDetailsTableHeaderData";
import { ExcelDownload } from "../../../../utils/ExcelGlobalFunction";
import Pagination from "../../../../elements/Pationation/Pagination";
import { filteredDataGet } from "./Function";
// * @param {Array} ArrayData - The array containing participation details data.
// * @param {Function} HeaderTextClick - Optional. A function to handle click events on the header text.
// * @param {boolean} Graded - Optional. A boolean indicating whether participation is graded.
// * @param {boolean} Anonymous - Optional. A boolean indicating whether participation is anonymous.
// *
// * @returns {JSX.Element} The JSX representation of the ForumParticipationDetailsMain component.
// */
interface ForumParticipationDetailsMainInterface {
  ArrayData: any[];
  HeaderTextClick?: () => void;
  Graded?: boolean;
  Anonymous?: boolean;
  ForumDueDate?: Date;
  ForumCutOffEDate?: Date;
}
const ForumParticipationDetailsMain = ({
  ArrayData,
  HeaderTextClick,
  Graded,
  Anonymous,
  ForumDueDate,
  ForumCutOffEDate,
}: ForumParticipationDetailsMainInterface) => {
  // State variables for managing search, pagination, and limit
  const [searchText, setSearchText] = useState("");
  const [StatusSearchText, setStatusSearchText] = useState("");
  const [limit, setLimit] = useState(ArrayData.length);
  const [pageNumber, setPageNumber] = useState(1);

  const itemsPerPage = limit;
  const [currentPage, setCurrentPage] = useState(1);
  const [ShowPagination, setShowPagination] = useState(false);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  // Filtering data based on search text and status search text
  // Usage:
  const filteredData = filteredDataGet(
    ArrayData,
    searchText,
    StatusSearchText,
    startIndex,
    endIndex
  );

  return (
    <AnalyticMainBoxContainer>
      <SurveyReusableHeader
        data={ArrayData}
        title="Participation Details"
        onClickExcelDowload={() =>
          ExcelDownload(ArrayData, "Participation Details")
        }
        setSearchText={setSearchText}
        setLimit={setLimit}
        setShowPagination={setShowPagination}
        FilteredOptions={
          <ForumParHeaderFilter setStatusSearchText={setStatusSearchText} />
        }
        HeaderTextClick={HeaderTextClick}
      />
      <ForumParticipationDetailsTable
        filteredData={filteredData}
        Graded={Graded}
        Anonymous={Anonymous}
        ForumCutOffEDate={ForumCutOffEDate}
        ForumDueDate={ForumDueDate}
      />

      {ShowPagination && (
        <Pagination
          currentPage={currentPage}
          total={ArrayData.length}
          limit={itemsPerPage}
          onPageChange={(page: number) => setCurrentPage(page)}
          MarginTop="1rem"
          justifyContent="left"
          Padding="0 0 0 5.5rem"
          BoxSize="1.85rem"
          ScrollTop={false}
        />
      )}
    </AnalyticMainBoxContainer>
  );
};

export default ForumParticipationDetailsMain;
