import styled from "styled-components";
import { breakpoints } from '../../theme';

interface GroupCardRectangleProps {
    borderColor: string;
  }
export const GroupCardRectangle = styled.div<GroupCardRectangleProps>`
  max-width: 27.5rem;
  width: 100%;
  min-height: 14.375rem; 
  border-radius: 0.625rem;
  border: 2px solid;
  border-color: ${({ borderColor }) => borderColor};
  background: #384450;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow-y: auto; */
@media (max-width: ${breakpoints.tablets}) {
    /* margin-right: 1.12rem;
    margin-left: -0.7rem; */
    max-width: 19.625rem;
    /* width: auto; */
max-height: auto;
padding-bottom: 1.69rem;
/* overflow-x: hidden; */

}
`
export const GroupCardHeaderRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1.37rem 1.25rem 0rem 1.25rem;
    gap: 0.3rem;
    &>:first-child{
display: flex;
flex-wrap: wrap;
    }
    @media (max-width: ${breakpoints.tablets}) {
padding: 1.25rem 0.33rem 1.25rem 0.89rem ;
}
    
`
export const LineInGrpCard = styled.div`
    width: 15.4375rem;
height: 0.0625rem;
background: #576371;
`
export const PrtcsMoreIconColumn = styled.div`
    display: flex;
    gap: 0.31rem;
`
export const PrtcsText = styled.div`

@media (max-width: ${breakpoints.tablets}) {
display: none;

}
`
export const PrtcsTextResp = styled.div`
display: none;
@media (max-width: ${breakpoints.tablets}) {
display: block;
}
`

export const TextAreaInsideCard = styled.div`
    transform: translateY(4rem);
    @media (max-width: ${breakpoints.tablets}) {
display: none;
    }
`
export const GroupNamePrtcsResp = styled.div`

`
export const TextAreaInsideCard2 = styled.div`
    /* transform: translateY(2rem); */
    display: none;
    @media (max-width: ${breakpoints.tablets}) {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 0.62rem;
    }
`
export const FlexMembers = styled.div`
    display: flex;
    /* flex-direction: row; */
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 0.62rem;
    padding-left:1.23rem;
    padding-top: 1rem;
    padding-right:1.25rem;
    /* padding-bottom: 1.25rem; */
    @media (max-width: ${breakpoints.tablets}) {
flex-direction: column;

    }
`
export const MembersAreaInsideCard = styled.div`
   
` 
export const MemberBox = styled.div`
    width: 6.9375rem;
height: 4.375rem;
border-radius: 0.625rem;
border: 2px solid #576371;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 0.31rem;
break-inside: avoid;
@media (max-width: ${breakpoints.tablets}) {
flex-direction: row;
width: 17.9375rem;
height: 4.375rem;
border-radius: 0.625rem;
border: 2px solid #576371;
justify-content: start;
align-items: center;
gap: 1.25rem;
padding-left: 1.19rem;

    }

`
export const MemberProfile = styled.img`
    width: 1.875rem;
height: 1.875rem;
border-radius:50%;

@media (max-width: ${breakpoints.tablets}) {
    display: flex;
    justify-content: center;
    width: 2.75rem;
height: 2.75rem;
}
`
export const DropDownMenuHolder = styled.div`
display: flex;
  flex-direction: column;
  position: relative;
`

