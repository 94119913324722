import React from "react";
import { Link } from "react-router-dom";
import {
  DivAction,
  FourOA,
  FourOPageLayout,
  FourLogoPlace,
  FourText,
  FourOc,
} from "./ForOFourPage.style";
import {
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  SvgComingSoon,
  SvgComingSoonLight,
  SvgLogoFour,
  SvgZName,
} from "../../elements/Icons";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

export const ComingSoon = (): JSX.Element => {
  const { theme } = useRecoilValue(ColorSelector);
  return (
    <FourOPageLayout>
      <FourOA theme={theme}>
        <FourLogoPlace>
          <SvgLogoFour />
          <SvgZName />
        </FourLogoPlace>

        <FourText>
          <Typography
            fontSize="5rem"
            lineHeight="5.500rem"
            text="Coming Soon"
          />

          <Link to="/dashboard">
            <DivAction>
              <Typography
                fontSize="0.875rem"
                text="Go back to Main page"
                color="#fff"
              />
            </DivAction>{" "}
          </Link>
        </FourText>
      </FourOA>

      <FourOc>
        {theme !== "light" ? <SvgComingSoon /> : <SvgComingSoonLight />}
      </FourOc>
    </FourOPageLayout>
  );
};
