import { FC } from "react";
import {
  CreditNumbersHolder,
  CreditNumbersMainHolder,
  CreditNumbersWrapper,
  NumbersWrapper,
  CreditsWrapper,
} from "./CreditNumbers.style";
import { CreditNumbersInterface } from "../creditNumbersInterface";

import { useRecoilValue } from "recoil";
import { courseBannerColorSelector } from "../../../recoil/ThemeSelectors";

const CreditNumbersDetails: FC<CreditNumbersInterface> = ({
  icon: IconComponent,
  textElement,
  credit,
}) => {
  const { backgroundImageColor } = useRecoilValue(courseBannerColorSelector);
  return (
    <CreditNumbersMainHolder bgbordercolor={backgroundImageColor}>
      {/* <IconComponent  /> */}
      <CreditNumbersHolder>
        <CreditNumbersWrapper>
          <NumbersWrapper>{credit}</NumbersWrapper>
          <CreditsWrapper>{textElement}</CreditsWrapper>
        </CreditNumbersWrapper>
      </CreditNumbersHolder>
    </CreditNumbersMainHolder>
  );
};

export default CreditNumbersDetails;
