import styled from "styled-components";
interface CourseGeneralTracingAttProps {
  isActive: boolean;
}
// export  const IAUV  = styled.div`

// `;

export  const IAUVLayout  = styled.div`
width: 100%;
display:flex;
flex-direction:column;
gap:1.25rem;
border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));

`;

export  const IAUVLine  = styled.div`
width: 100%;
display:flex;
justify-content:space-between;
min-height:1.5rem;
/* background-color:red; */
padding: 1.25rem 1.25rem 0 1.25rem;
&:nth-child(2){
align-items:center;
/* background-color:aqua; */
padding: 0 1.25rem 0 1.25rem;
}
`;

export  const IAUVMiniLine  = styled.div`
display:flex;
gap:0.62rem;
`;

export  const IAUVActions  = styled.div<CourseGeneralTracingAttProps>`
width: 7.1875rem;
height: 2.125rem;
flex-shrink: 0;
display:flex;
justify-content:center;
border:1px solid #5DD3B3;
border-radius:0.7rem;
background-color:${(props) => (props.isActive ? "#5DD3B3" : "transparent")};
transition: background-color 0.3s ease;
color:${(props) => (props.isActive ? "#fff" : "#5DD3B3")};
align-items:center;
cursor: pointer;
&:hover{
  color:#fff;
background-color:#5DD3B3 !important;

}
`;