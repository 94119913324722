import React, { FC } from "react";
import ToDo from "../ToDo/ToDo";
import { ToDoSectionsContainer } from "./ToDoSections.style";
import { ToDoSectionsProps } from "../ToDoSectionsInterface";
import { useTranslation } from "react-i18next";

const ToDoSections: FC<ToDoSectionsProps> = ({ tasks, handleDropTask, handleEditTask, removeTask,handleAfterDropTask }) => {
  const { t } = useTranslation();
  return (
    <ToDoSectionsContainer>
      {/* todo part */}
      <ToDo title={t("to do list.To Do")} text="To Do" tasks={tasks.filter((task) => task.section === "ToDo")} handleDropTask={handleDropTask} handleAfterDropTask={handleAfterDropTask} allTasks={tasks} handleEditTask={handleEditTask} removeTask={removeTask} />

      {/* inProgress part */}
      <ToDo title={t("to do list.IN PROGRESS")} text="IN PROGRESS" tasks={tasks.filter((task) => task.section === "inProgress")} handleDropTask={handleDropTask} handleAfterDropTask={handleAfterDropTask} allTasks={tasks} handleEditTask={handleEditTask} removeTask={removeTask} />

      {/* Done part */}
      <ToDo title={t("to do list.Done")} text="Done" tasks={tasks.filter((task) => task.section === "Done")} handleDropTask={handleDropTask} handleAfterDropTask={handleAfterDropTask} allTasks={tasks} handleEditTask={handleEditTask} removeTask={removeTask} />
    </ToDoSectionsContainer>
  );
};

export default ToDoSections;
