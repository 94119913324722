import React, { useEffect, useState } from "react";
import {
  AnalyticsContainerRow,
  AnalyticsPageStylesLayout,
  AnalyticsPieChartContainer,
} from "../../AnalyticsPageStyles";
import AnalyticForumIdShadowHeader from "../../../../components/AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticForumIdShadowHeader";
import { Typography } from "../../../../elements/fonts/Fonts";
import { useNavigate, useParams } from "react-router-dom";
import { SurveyDefaultTableData } from "../../../../components/Tables/SurveyDefaultTable/SurveyDefaultTableData";
import SurveyComplexIDTable from "../../../../components/AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyComplexIDTable/SurveyComplexIDTable";
import SurveyComplexProfileTable from "../../../../components/AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyComplexProfileTable/SurveyComplexProfileTable";
import SurveyQuestionAnalytics, {
  SurveyQuestion,
} from "../../../../components/Tables/SurveyQuestionAnalytics/SurveyQuestionAnalytics";
import { SurveyQuestionAnalyticsData } from "../../../../components/Tables/SurveyQuestionAnalytics/SurveyQuestionAnalyticsData";
import { SurveyComplexIDTableData } from "../../../../components/AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyComplexIDTable/SurveyComplexIDTableData";
import { CircleContainer } from "../../AnalyticsPage/AnalyticsPage.style";
import PieWithText from "../../../../components/Charts/PieWithText/PieWithText";
import PieText from "../../../../components/Charts/PieText/PieText";
import ProgressBarChart from "../../../../components/Charts/ProgressBarChart/ProgressBarChart";
import AnalyticsSurveyPies from "../../../../components/AnalyticsSurveyPies/main/AnalyticsSurveyPies";
import { SurveyComplexProfileTableData } from "../../../../components/AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyComplexProfileTable/SurveyComplexProfileTable.Data";
import { FlexAppContainer } from "../../../../App.style";
import AnalyticOneTitleHeader from "../../../../components/AnalyticShadowHeaderFourDisplays/AnalyticOneTitleHeader";
import AnalyticsBackPage from "../../../../components/AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticsBackPage";

// SurveyDetails Component
const SurveyDetails = () => {
  const navigate = useNavigate();
  const NavigatePage = () => {
    navigate(-1);
  };
  // Get MainCode from URL Params
  const { MainCode } = useParams<{ MainCode: string }>();

  // Find Survey Data based on MainCode
  const SurveyData = SurveyDefaultTableData.find(
    (item) => item.MainCode === MainCode
  );

  const StudentsData = SurveyComplexProfileTableData.Students;
  const Anonymous = SurveyComplexProfileTableData.Anonymous;

  const [ShowDetails, setShowDetails] = useState(false);
  const [ShowQuestions, setShowQuestions] = useState(false);
  const [ShowTracking, setShowTracking] = useState(false);
  const handleShowDetails = () => {
    setShowDetails(!ShowDetails);
  };
  const handleShowQuestions = () => {
    setShowQuestions(!ShowQuestions);
  };

  const handleShowTacking = () => {
    setShowTracking(!ShowTracking);
  };
  // State for Pie Chart Data
  const [PieChartData, setPieChartData] = useState([
    {
      text: "Attempted",
      attendance: 0,
      bgColor: "#5DD3B3",
    },
    {
      text: "Unattempted",
      attendance: 0,
      bgColor: "#0B8FFF",
    },
  ]);

  // Calculate attempted count
  const attemptedCount = StudentsData.filter(
    (item) => typeof item.Date !== "undefined" && item.Date !== null
  ).length;

  // useEffect to update PieChartData on component mount
  useEffect(() => {
    // Calculate attempted count
    const attemptedCount = StudentsData.filter(
      (item) => typeof item.Date !== "undefined" && item.Date !== null
    ).length;

    // Calculate unattempted count
    const unattemptedCount = StudentsData.filter(
      (item) => typeof item.Date === "undefined" || item.Date === null
    ).length;

    // Update PieChartData state with new data
    setPieChartData([
      {
        text: "Attempted",
        attendance: attemptedCount,
        bgColor: "#5DD3B3",
      },
      {
        text: "Unattempted",
        attendance: unattemptedCount,
        bgColor: "#0B8FFF",
      },
    ]);
  }, []);

  // State for Anonymous checkbox
  // const [Anonymous, setAnonymous] = useState(true);

  // Maximum Number of Data
  const MaxNumberOfData = StudentsData.length;

  // State for Chosen Question
  const [ChosenQuestion, setChosenQuestion] = useState<SurveyQuestion | null>(
    null
  );

  return (
    <AnalyticsPageStylesLayout>
      <AnalyticsBackPage onClick={NavigatePage} />
      {/* Survey Analysis Title */}
      <Typography text="Survey Analysis" fontSize="1.75rem" />

      {/* AnalyticForumIdShadowHeader Component */}
      <AnalyticForumIdShadowHeader
        FirstText="Responses"
        FirstNumberValue={`${attemptedCount} out of ${MaxNumberOfData}`}
        SecondText="Anonymous"
        SecondNumberValue={Anonymous ? "Yes" : "No"}
      />
      {ShowTracking ? (
        <FlexAppContainer column={true} gap="1.25rem">
          {/* Responses Tracking Title */}
          <div style={{ cursor: "pointer" }} onClick={handleShowTacking}>
            <Typography text="Responses Tracking" fontSize="1.75rem" />
          </div>
          {/* AnalyticsContainerRow */}
          <AnalyticsContainerRow>
            {/* AnalyticsPieChartContainer for Pie Chart */}
            <AnalyticsPieChartContainer>
              {/* PieWithText Component */}
              <PieWithText
                data={PieChartData}
                value={"attendance"}
                getColor={(entry) => entry.bgColor as string}
                Width="65%"
                NormalTextDisplay={false}
              />

              {/* PieText Component */}
              <PieText LoopName={PieChartData} />
            </AnalyticsPieChartContainer>

            {/* AnalyticsPieChartContainer for Progress Bar Chart */}
            <AnalyticsPieChartContainer>
              {/* ProgressBarChart Component */}
              <ProgressBarChart
                data={PieChartData}
                MaxNumber={MaxNumberOfData}
              />
            </AnalyticsPieChartContainer>
          </AnalyticsContainerRow>
        </FlexAppContainer>
      ) : (
        <AnalyticOneTitleHeader
          Title="Grade Distribution"
          onClick={handleShowTacking}
        />
      )}

      {ShowDetails ? (
        <SurveyComplexProfileTable
          Data={StudentsData || []}
          Anonymous={SurveyComplexProfileTableData.Anonymous}
          TitleClick={handleShowDetails}
        />
      ) : (
        <AnalyticOneTitleHeader
          Title="Responses Details"
          onClick={handleShowDetails}
        />
      )}

      {ShowQuestions ? (
        <>
          {/* Conditional Rendering based on ChosenQuestion state */}
          {ChosenQuestion ? (
            <AnalyticsSurveyPies
              ChosenQuestion={ChosenQuestion}
              setChosenQuestion={setChosenQuestion}
            />
          ) : (
            <SurveyQuestionAnalytics
              title="Questions Analytics"
              DatabaseTableName={SurveyQuestionAnalyticsData}
              setChosenQuestion={setChosenQuestion}
              StdName="Submission ID"
            />
          )}
        </>
      ) : (
        <AnalyticOneTitleHeader
          Title="Questions Analytics"
          onClick={handleShowQuestions}
        />
      )}
    </AnalyticsPageStylesLayout>
  );
};

export default SurveyDetails;
