import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const ScaleContainer=styled.div<StyleThemeInterface>`
width: 11rem;
height: 0.5rem;
border-radius: 0.3125rem;
background: ${props => props.bgcolor};
`;
export const ScaleStructureNumber = styled.div`
  border-radius: 0.3125rem;
  /* background: #5DD3B3; */
  /* box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25); */
`;

export const ScaleContentNumber = styled.div`
  border-radius: 0.3125rem;
  background: #0b8fff;
  /* box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25); */
`;

export const Header = styled.div<StyleThemeInterface>`
  width: auto;
  height: 2.8125rem;
  display: flex;
  justify-content: space-between;
  &:dir(ltr) {
    padding-left: 1.25rem;
  }
  &:dir(rtl) {
    padding-right: 1.25rem;
  }
  align-items: center;
  /* display: grid;
grid-template-columns: auto 1fr;
padding-left:1.5rem;
align-items:center;
gap:3.3rem; */
background: ${props => props.first_child_bgcolor};

`;
export const TableCell = styled.div``;

export const RatingContainer = styled.div`
  margin-top: 1.25rem;
`;
export const RatingContentContainer = styled.div`
  margin-top: 1.25rem;
  display: flex;
  justify-content: space-between;
  &:dir(ltr) {
    margin-right: 1.25rem;
  }
  &:dir(rtl) {
    margin-left: 1.25rem;
  }
`;
export const MarkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const MarkScaleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.62rem;
`;

export const CriteriaContainer = styled.div`
  display: flex;
  flex-direction: column;
  &:dir(ltr) {
    padding-left: 1.25rem;
  }
  &:dir(rtl) {
    padding-right: 1.25rem;
  }

  gap: 0.31rem;
`;
