import styled from "styled-components";

export const DropdownMenu = styled.div<{ isopen: string }>`
  position: absolute;
  min-width: 10.125rem;
  top: 30px;
  &:dir(ltr) {
    right: 0rem;
  }
  &:dir(rtl) {
    left: 0rem;
  }
  background-color: #1f2731;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 0.625rem;
  z-index: 10;
  display: ${(props) => (props.isopen === "true" ? "block" : "none")};
`;
export const MenuContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;
export const MenuItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-family: "Primary Font-Bold";
  font-size: 0.875rem;
`;

export const MenuElementContainer = styled.div<{ index: number }>`
  display: flex;
  align-items: center;
  &:dir(ltr) {
    padding: 0.62rem 0 0.62rem 1.25rem;
  }
  &:dir(rtl) {
    padding: 0.62rem 1.25rem 0.62rem 0;
  }

  &:hover {
    background-color: #5dd3b3;
    border-radius: ${(props) =>
      props.index === 1
        ? "5px 5px 0px 0px"
        : props.index === 2
        ? "0px"
        : "0px 0px 5px 5px"};
    color: white;
  }
`;
