import React from 'react'
import { TextHeaderstyle } from './TextHeder.style';
import { LightTypography, Typography } from '../fonts/Fonts';

interface TextInputs {
  titleA?: string;
  titleB?: string;
}

const TextHeader = ({ titleA, titleB }: TextInputs): JSX.Element => {
  return (
    <TextHeaderstyle>
      <LightTypography text={titleA || "" } fontSize='1.125rem' />
      <Typography text={titleB || ""} fontSize='1.125rem' />
    </TextHeaderstyle>
  );
};

export default TextHeader;
