import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const NotificationLineMainContainer = styled.div<StyleThemeInterface>`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
  max-height: 98%;
  border-radius: 1.25rem;
  background: ${(props) => props.bgcolor};
  position: relative;
  padding: 1.25rem;
  margin-bottom: 1.25rem;
  color: ${(props) => props.color};
  box-shadow: ${(props) =>
    props.theme === "light"
      ? " 0px 4px 14px 0px rgba(192, 220, 214, 0.32)"
      : ""};
`;

export const NotificationLineScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
  min-height: 10rem;
  height: 98% !important;
  position: relative;
  height: fit-content;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  overflow-y: hidden;
  /* gap:3.2rem; */

  /* overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0.50rem;
  
  }

  &::-webkit-scrollbar-thumb {
    background: var(
      --Green-Gradient,
      linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)
    );
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0.625rem;
  } */
`;

export const NotificationRenderContainer = styled.div<{ color?: string }>`
  display: flex;
  width: fit-content;
  gap: 0.65rem;
  align-items: center;
  z-index: 3;
  color: ${({ color }) => color || ""};
  /* justify-content:center; */
  /* background-color:yellow; */
  padding: 0 1rem;

  &:dir(ltr) {
    &:nth-child(even) {
      flex-direction: row-reverse;
      /* margin-left:-20%; */
      margin-left: -16rem;
    }
    &:nth-child(odd) {
      flex-direction: row;
      margin-right: -16.65rem;
    }
  }
  &:dir(rtl) {
    &:nth-child(even) {
      flex-direction: row-reverse;
      /* margin-left:-20%; */
      margin-left: 16.65rem;
    }
    &:nth-child(odd) {
      flex-direction: row;
      margin-right: 16rem;
    }
  }
`;
export const NotificationDataContainer = styled.div<StyleThemeInterface>`
  display: flex;
  width: 15.625rem;
  min-height: 4.3125rem;
  flex-shrink: 0;
  background: ${(props) => props.bgcolor};
  border-radius: 1.2rem;
  padding: 0.63rem;
  flex-direction: column;
  gap: 0.31rem;
  cursor: ${(props) => props.cursor};
`;

export const VerticalDashedLine = styled.div<StyleThemeInterface>`
  border-left: 6px dashed
    ${(props) => (props.theme === "light" ? "#AAB1B950" : "#ffffff80")};
  height: 100% !important;
  left: 50%;
  margin-left: -2.7px;
  top: 0;
  /* background:; */
  position: absolute;
  left: 50%;
  right: 50%;
  margin: auto;

  z-index: 0;
`;

export const VerticalRadiusDot = styled.div<StyleThemeInterface>`
  background: ${(props) =>
    props.theme === "light" ? "#AAB1B950" : "#ffffff80"};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  height: 1rem;
  width: 4px;
  border-radius: 1rem;
  top: ${(props) => props.top || "0"};
`;

export const ShowMoreContainer = styled.div<StyleThemeInterface>`
  display: flex;
  width: 100%;
  &:dir(ltr) {
    justify-content: right;
  }
  &:dir(rtl) {
    justify-content: left;
  }
`;
