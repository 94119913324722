import styled from "styled-components";
import { breakpoints } from "../../theme";
import { StyleProps } from "../../App.style";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const ClumnDilpay = styled.div`
  display: flex;
  align-items: space-between;
  flex-direction: column;
  flex: 1;
  min-width: 22rem;

  @media (max-width: ${breakpoints.tablets}) {
    width: 100%;
  }
`;
export const ColumnDisplayExtention = styled.div`
  display: flex;
  flex-direction: column;
  width: 81%;
  /* background-color:white; */
  justify-content: space-between;
  @media (max-width: ${breakpoints.tablets}) {
    width: 100%;
  }
`;

export const FormEditDisplay = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color:red; */
  width: 70%;
  margin-left: 1rem;
  @media (max-width: ${breakpoints.tablets}) {
    width: 100%;
    margin-left: 0rem;
  }
`;

export const FormRow = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;

  &:nth-child(2) {
    width: 100%;

    justify-content: space-between;
    /* margin-left:-2rem; */
    /* margin-right:1rem; */
  }
  &:nth-child(7) {
    width: 98.8%;
    display: flex;
    /* min-width:30rem; */
    justify-content: space-between;
    flex-wrap: wrap;
    height: auto;
    /* background-color:green; */
    /* padding-left:-2rem; */
  }
  &:nth-child(1) {
    /* margin-left:2rem; */
    justify-content: space-between;
    flex-wrap: wrap;
    width: 98%;
    /* background-color:yellow; */
  }

  @media (max-width: ${breakpoints.tablets}) {
    width: 100%;
    flex-direction: column;
  }
`;
export const ProfileUpdateImage = styled.img<StyleThemeInterface>`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  object-fit: cover;
  /* border: 0.1rem #576371 solid; */
  margin-top: 1rem;
  margin-right: 1rem;
  border: 2px solid ${(props) => props.bordercolor || "#576371"};
  /* background-color:red; */
`;
export const ColumnFormDIsplay = styled.div`
  display: flex;
  flex-direction: column;
  /* height:4.7rem; */
  width: 47%;
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  /* color:${(props) => props.color}; */
  /* background-color:aqua; */

  @media (max-width: ${breakpoints.tablets}) {
    margin-top: 1rem;
    width: 100%;
  }

  &:nth-child(4) {
    height: 12.6rem;
    width: 98.8%;
    justify-content: space-between;
    /* background-color:black; */
    margin-top: 0.3rem;
  }
`;
export const ColumnMoreTopeFormDIsplay = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  height: 6.2rem;
  justify-content: space-between;
  width: 98%;
  /* margin-left:1rem; */
  margin-top: 1rem;
  /* background-color:purple; */
`;

export const LongTextInput = styled.input<{
  color?: string;
  FocusColor?: string;
}>`
  width: 100%;
  min-width: 14rem;

  height: 3rem;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #576371;
  opacity: 0.8;
  box-sizing: border-box;
  background-color: transparent;
  color: ${(props) => props.color};
  padding-left: 1rem;
  font-family: "Primary font-light";
  @media (max-width: ${breakpoints.tablets}) {
    width: 100%;
  }
  &:focus {
    outline: none;
    caret-color: ${(FocusColor) => FocusColor.color};
  }
`;

export const TextAreaLongTextAreaInput = styled.textarea<{
  color?: string;
  FocusColor?: string;
}>`
  border-radius: 0.6rem;
  border: 1px solid #576371;
  opacity: 0.8;
  box-sizing: border-box;
  background-color: transparent;
  /* color:#fff; */
  min-height: 11rem;
  max-height: 11rem;
  min-width: 20rem;
  width: 100%;
  max-width: 100%;
  padding: 1rem;
  color: ${(props) => props.color};
  resize: none;
  font-family: "Primary font-light";
  @media (max-width: ${breakpoints.tablets}) {
    width: 100%;
    min-width: 20rem;
  }
  &:focus {
    outline: none;
    caret-color: ${(FocusColor) => FocusColor.color};
  }
`;

export const GoFlexRight = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  /* background-color:blue; */
  /* margin-left:-1rem; */
  &:nth-child(5) {
    width: 99%;
    margin-left: 0;
    margin-top: 0.3rem;
  }
  &:nth-child(8) {
    width: 98%;
    /* background-color:yellowgreen; */
    margin: 0.2rem 0 3rem 0;
  }
`;
export const LineForText = styled.div`
  display: flex;
  width: 100;
  /* padding-left:1rem; */
  margin: 0.7rem 0 0.5rem 0;
`;

export const TitleText = styled.div`
  width: 100%;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
`;
export const ImageCotainer = styled.div`
  cursor: pointer;

  @media (max-width: ${breakpoints.tablets}) {
    display: flex;
    justify-content: center;
  }
`;

export const OptingAboutImageCotainer = styled.div<StyleProps>`
  width: 13rem;
  /* height: 7.5rem; */
  display: none;
  flex-direction: column;
  background-color: #1f2731;
  border-radius: 10px;
  position: absolute;
  margin-top: 8rem;
  margin-left: -6rem;
  z-index: 1;
  font-size: 0.875rem;
  padding: 0.4rem;
  /* background:${({ backgroundColor }) => backgroundColor || ""};
color:${({ color }) => color}; */

  ${ImageCotainer}:hover & {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    gap: 0.05rem;
    background-color: #1f2731;
  }
`;
