// InstructorViewCompletion.tsx
import React, { useEffect, useState } from "react";
import {
  InstructorCompletionTextName,
  InstructorMessageAttachment,
  InstructorMessageBox,
  InstructorMessageBoxInput,
  InstructorMessageBoxTitleRow,
  InstructorMessageButton,
  InstructorMessageTextArea,
  InstructorViewBox,
  InstructorViewBoxButton,
  InstructorViewCompletionContainer,
  InstructorViewCompletionOptionButton,
  InstructorViewCompletionProfile,
  InstructorViewCompletionSearchContainer,
  InstructorViewCompletionTable,
  InstructorViewCompletionTableBodyCell,
  InstructorViewCompletionTableBodyRow,
  InstructorViewCompletionTableHeaderCell,
  InstructorViewCompletionTableHeaderRow,
  InstructorViewCompletionTitles,
} from "./InstructorViewCompletion.style";
import {
  MediumTypography,
  Typography,
  LightTypography,
} from "../../elements/fonts/Fonts";
import SearchElement from "../../elements/searchElement/SearchElement";
import {
  InstructorViewCompletionData,
  InstructorViewCompletionTrackingActionsData,
} from "./InstructorViewCompletionData";
import { SvgMore, SvgDownload } from "../../elements/Icons";
import { LogoAndAction } from "../gradesItem/GradesItem.style";
import { ExcelDownload } from "../../utils/ExcelGlobalFunction";
import MappedIndexClickOutSide from "../../hooks/MapedIndexClickOutSide";
import StudentMessage from "./StudentMessage";
import {
  ViewsOptions,
  handleNumberDisplays,
} from "../../utils/NbOfViewesObjects";
import TableViewSelectInput from "../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/TableViewSelectInput";
import IdTwoActions from "./IdTwoActions";
import { getColorBaseOnGrade } from "../../utils/AnalyticsProperties";
import {
  handleChangeShapeToggle,
  handleOptionButtonClick,
  updateMessageText,
} from "./functions";
import Pagination from "../../elements/Pationation/Pagination";
import { useStateContext } from "../../contexts/ContextProvider";
interface InstructorViewCompletionInterface {
  setNumberOfUsers: React.Dispatch<React.SetStateAction<number>>;
}
const InstructorViewCompletion = ({
  setNumberOfUsers,
}: InstructorViewCompletionInterface) => {
  const { AnalyticsNumberOfUsersTable, SetAnalyticsNumberOfUsersTable } =
    useStateContext();
  useEffect(() => {
    setNumberOfUsers(InstructorViewCompletionData.length);
    SetAnalyticsNumberOfUsersTable(InstructorViewCompletionData.length);
  }, [InstructorViewCompletionData]);

  const [limit, setLimit] = useState(InstructorViewCompletionData.length);
  const itemsPerPage = limit;
  const [currentPage, setCurrentPage] = useState(1);
  const [ShowPagination, setShowPagination] = useState(false);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const [ViewText, setViewText] = useState("View All");

  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(
    null
  );

  const [searchText, setSearchText] = useState("");

  const filteredDataLimited = InstructorViewCompletionData.slice(
    startIndex,
    endIndex
  );

  const filteredData = filteredDataLimited.filter((item) => {
    const searchTextMatches = Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes((searchText ?? "").toLowerCase())
    );

    return searchTextMatches;
  });

  const handleNumberShows = (newValue: string) => {
    handleNumberDisplays(
      newValue,
      setViewText,
      setLimit,
      filteredData,
      setShowPagination
    );
  };
  const [shapeToggles, setShapeToggles] = useState<Array<boolean>>(
    Array(filteredData.length).fill(true)
  );

  const clickOutsideRef = MappedIndexClickOutSide(() =>
    setSelectedOptionIndex(null)
  );

  // don't delete its must be used in future

  // const [messageSubjects, setMessageSubjects] = useState<{
  //   [id: string]: string;
  // }>({});
  // const [FileStorageId, setFileStorageId] = useState<{
  //   [id: string]: FileList | null;
  // }>({});

  const [messageTexts, setMessageTexts] = useState<{ [id: string]: string }>(
    {}
  );
  const [ErrormessageTexts, setErrorMessageTexts] = useState<{
    [id: string]: string;
  }>({});

  // Update the state variables with the MessageSubject and MessageText for a specific id

  const handleMessageSend = (index: number, id: string) => {
    if (messageTexts[id] === undefined || messageTexts[id].trim() === "") {
      // If messageTexts[id] is undefined or empty, set the errorMessageTexts for the specific id
      setErrorMessageTexts((prevState) => ({
        ...prevState,
        [id]: "This field is required.",
      }));
      return;
    }

    const Data = {
      index: index,
      id: id,
      // MessageValue: messageSubjects, // Assuming MessageSubject is the subject of the message
      MessageText: messageTexts,
      // File: FileStorageId,
    };

    // Reset the messageSubjects and messageTexts states for the specific id
    // setMessageSubjects((prevState) => ({
    //   ...prevState,
    //   [id]: "",
    // }));

    setMessageTexts((prevState) => ({
      ...prevState,
      [id]: "",
    }));
    setErrorMessageTexts((prevState) => ({
      ...prevState,
      [id]: "",
    }));
    // setFileStorageId((prevState) => ({
    //   ...prevState,
    //   [id]: null,
    // }));
    // Perform any other actions with Data as needed
  };

  return (
    <InstructorViewCompletionContainer>
      <InstructorViewCompletionTitles>
        <MediumTypography
          text="Student Completion Tracking"
          fontSize="1.125rem"
          lineHeight="1.75rem"
        />
        <InstructorViewCompletionSearchContainer>
          <SearchElement onChange={(e) => setSearchText(e.target.value)} />
        </InstructorViewCompletionSearchContainer>
      </InstructorViewCompletionTitles>
      <InstructorViewCompletionTitles>
        <TableViewSelectInput
          value={ViewText}
          options={ViewsOptions}
          onChange={handleNumberShows}
        />
        <LogoAndAction
          onClick={() =>
            ExcelDownload(
              InstructorViewCompletionData,
              "StudentCompletionTracking"
            )
          }
        >
          <SvgDownload />
          <Typography text="Download" fontSize="0.75rem" />
        </LogoAndAction>
      </InstructorViewCompletionTitles>
      <InstructorViewCompletionTable>
        <InstructorViewCompletionTableHeaderRow>
          {InstructorViewCompletionTrackingActionsData.map((val) => (
            <InstructorViewCompletionTableHeaderCell key={val.text}>
              <MediumTypography
                text={val.text || ""}
                fontSize="1.125rem"
                lineHeight="1.75rem"
              />
            </InstructorViewCompletionTableHeaderCell>
          ))}
        </InstructorViewCompletionTableHeaderRow>
        {filteredData.map((item, index) => (
          <InstructorViewCompletionTableBodyRow key={item.id}>
            <InstructorViewCompletionTableBodyCell>
              <InstructorViewCompletionProfile src={item.Img} />
              <InstructorCompletionTextName>
                <Typography text={item.FirstName || ""} fontSize="0.875rem" />
                <Typography text={item.LastName || ""} fontSize="0.875rem" />
              </InstructorCompletionTextName>
            </InstructorViewCompletionTableBodyCell>
            <InstructorViewCompletionTableBodyCell>
              <LightTypography
                text={item.TimeSpent || "Never"}
                fontSize="0.75rem"
              />
            </InstructorViewCompletionTableBodyCell>
            <InstructorViewCompletionTableBodyCell>
              <LightTypography
                text={item.LastAccess || "-"}
                fontSize="0.75rem"
              />
            </InstructorViewCompletionTableBodyCell>
            <InstructorViewCompletionTableBodyCell>
              <LightTypography
                text={item.Grade || "-"}
                color={getColorBaseOnGrade(item.Grade)}
                fontSize="0.75rem"
              />
            </InstructorViewCompletionTableBodyCell>
            <InstructorViewCompletionTableBodyCell>
              <LightTypography text={item.GPA || ""} fontSize="0.75rem" />
            </InstructorViewCompletionTableBodyCell>
            <InstructorViewCompletionTableBodyCell>
              <InstructorViewCompletionOptionButton
                onClick={() =>
                  handleOptionButtonClick(
                    index,
                    selectedOptionIndex,
                    setSelectedOptionIndex
                  )
                }
              >
                <SvgMore
                  style={{
                    transform: `rotate(${
                      selectedOptionIndex === index ? "0deg" : "90deg"
                    })`,
                  }}
                />
              </InstructorViewCompletionOptionButton>
              {selectedOptionIndex === index && shapeToggles[index] ? (
                <InstructorViewBox ref={clickOutsideRef}>
                  <IdTwoActions
                    link={`CourseActivityStudent/${item.id}`}
                    messageClick={() =>
                      handleChangeShapeToggle(
                        index,
                        shapeToggles,
                        setShapeToggles
                      )
                    }
                  />
                </InstructorViewBox>
              ) : selectedOptionIndex === index &&
                shapeToggles[index] === false ? (
                <InstructorMessageBox ref={clickOutsideRef}>
                  <StudentMessage
                    CloseClick={() =>
                      handleChangeShapeToggle(
                        index,
                        shapeToggles,
                        setShapeToggles
                      )
                    }
                    ReserverName={item.FirstName + " " + item.LastName}
                    SendMessage={() => handleMessageSend(index, item.id)}
                    // don't delete its must be used in future
                    // SubjectValue={messageSubjects[item.id] || ""} // Get the value for the specific id or default to an empty string
                    // setSubjectValue={(value: string) =>
                    //   updateMessageText(item.id, value, setMessageSubjects)
                    // } // Update the value for the specific id
                    MessageValue={messageTexts[item.id] || ""} // Get the value for the specific id or default to an empty string
                    setMessageValue={(value: string) =>
                      updateMessageText(item.id, value, setMessageTexts)
                    } // Update the value for the specific id
                    ErrorMessageValue={ErrormessageTexts[item.id] || ""}
                    setErrorMessageValue={(value: string) =>
                      updateMessageText(item.id, value, setErrorMessageTexts)
                    }
                    // FileStorage={FileStorageId[item.id]}
                    // setFileStorage={(files) =>
                    //   setFileStorageId((prevState) => ({
                    //     ...prevState,
                    //     [item.id || null]: files,
                    //   }))
                    // }
                  />
                </InstructorMessageBox>
              ) : null}
            </InstructorViewCompletionTableBodyCell>
          </InstructorViewCompletionTableBodyRow>
        ))}
      </InstructorViewCompletionTable>
      {ShowPagination && (
        <Pagination
          currentPage={currentPage}
          total={InstructorViewCompletionData.length}
          limit={itemsPerPage}
          onPageChange={(page: number) => setCurrentPage(page)}
          justifyContent="left"
          Padding="0 0 0 5.5rem"
          BoxSize="1.85rem"
          ScrollTop={false}
          MarginTop="1rem"
        />
      )}
    </InstructorViewCompletionContainer>
  );
};

export default InstructorViewCompletion;
