import styled from "styled-components";
import { theme } from "../../../theme";

export const QuestionNumberContainer = styled.div`
  padding-top: 1.88rem;
  &:dir(ltr) {
    padding-left: 2.5rem;
  }
  &:dir(rtl) {
    padding-right: 2.5rem;
  }
`;

export const BreakLine = styled.div`
  width: 1.875rem;
  height: 0.0625rem;
  background: ${theme.dark.primary_text_color};
`;

export const BreakLineNumber = styled.div<{ bgColor?: string }>`
  width: 1.875rem;
  height: 0.0625rem;
  background: ${(props) => props.bgColor};
  &:last-child {
    display: inline-block;
  }
`;

export const PaginationContainer = styled.div`
  margin-top: 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.62rem;
`;

export const PaginationContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.62rem;
`;

export const Number = styled.div<{ color: any; selected: any }>`
  width: 1.5625rem;
  height: 1.5625rem;
  background: ${(props) => (props.selected ? "#5DD3B3" : "")};
  border: ${(props) => (props.selected ? "" : `1px solid ${props.color}`)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "Primary Font-Medium";
`;
