import styled from 'styled-components'

export const StudentImage=styled.img`
width:3.75rem;
height:3.75rem;
border-radius:50%;
`;

export const StudentContainer=styled.div`
display:flex;
align-items:center;
gap:1.88rem;
`;

export const ContentContainer=styled.div`
display: flex;
flex-direction:column ;
gap:0.31rem;
`;
export const AvailabilityContainer=styled.div`
display: flex;
gap:7.5rem;
`;

export const NameContainer=styled.div`
display:flex;
align-items:center;
gap:1.56rem;
`;
export const SectionContainer=styled.div`
display: flex;
flex-direction:column;
gap:0.31rem;
`;