/**
 * Translates season names in the given text using the provided translate function.
 *
 * @param {string} text - The input text that may contain season names.
 * @param {function} translate - The translation function that maps season names to their translations.
 * @returns {string} - The translated text with season names replaced by their translations.
 */
export const SeasonTranslate = (text: string, translate: any): string => {
  // Define an array of season names to be translated
  const seasons = ["summer", "fall", "spring", "winter"];

  // Iterate over each season name
  seasons.forEach((season) => {
    // Create a regular expression to match the season name, case-insensitively
    const regex = new RegExp(season, "gi");
    // Replace all occurrences of the season name in the text with the translated value
    text = text.replace(regex, translate(`course view.${season}`));
  });

  // Return the translated text
  return text;
};
