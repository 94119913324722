import styled from "styled-components";

export const LeaderBoardCont = styled.div`
display: flex;
flex-direction: column;
flex: 0 0 21.8125rem;
height: fit-content;
border-radius: 1.25rem 0rem 0rem 1.25rem;
background: #384450;
padding-bottom: 1.87rem;
`;

export const FlexBetween = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`;
export const ProfileInfoHolder = styled.div`
display: flex;
flex-direction: column;
background: rgba(255, 255, 255, 0.03);
width: 100%;
padding: 1.44rem 0 1.25rem 0;
justify-content: center;
align-items: center;
margin-bottom: 1.25rem;
`;
export const ProfileAndRank = styled.div`
position: relative;
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 1.31rem;
`;

export const ProfileContainer = styled.div<{ bgimage?: string, width?: string, height?:string }>`
  width: ${({ width }) => (width ? width : "5rem")};
  height: ${({ height }) => (height ? height : "5rem")};
  border-radius: 50%;
  background-image: url(${(props) => props.bgimage});
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #707070;
`;

export const Rank = styled.div`
position: absolute;
bottom: -0.69rem;
width: 1.4375rem;
height: 1.4375rem;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
background: var(--Green-gradient, linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%));
`;
export const Points = styled.div`
display: flex;
align-items: center;
gap: 0.63rem;
margin: 0.62rem 0 0.81rem 0;
`;
export const LeaderboardUser = styled.div`
display: flex;
justify-content: space-between;
padding: 1.25rem;
`;

export const FlexDiv = styled.div<{ gap?: string }>`
display: flex;
align-items: center;
gap: ${({ gap }) => (gap ? gap : "0.5rem")};
`;
export const RankNumber = styled.div`
width: 1.4375rem;
height: 1.4375rem;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
border: 1px solid #5DD3B3;
color: #5DD3B3;
`;
export const UsernameDiv = styled.div`
width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Primary Font-Bold";
  font-size: 0.875rem;
font-style: normal;
font-weight: 400;
`;
