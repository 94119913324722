import React from "react";
import {
  ReplyInputActions,
  ReplyInputButtons,
  ReplyInputContainer,
  ReplyInputText,
} from "./ReplyInput.style";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../../recoil/ThemeSelectors";
import {
  SvgCancel,
  SvgCheckedIcon,
  SvgClose,
  SvgCorrectTick,
  SvgDeleteAll,
  SvgDeleteIcon,
  SvgloadingGroup,
  SvgTick,
} from "../../../../elements/Icons";
import LoaderThreeDot from "../../../loader/loaderThreeDot/LoaderThreeDot";
import { LoadingContainerSvg } from "../../../LoadingComp/LodingStyle";

interface ReplyInputInterface {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  CancelClick?: () => void;
  ConfirmClick?: () => void;
  loading?: boolean;
}

const ReplyInput = ({
  value,
  onChange,
  CancelClick,
  ConfirmClick,
  loading,
}: ReplyInputInterface) => {
  const { bghelp, SecondaryTextColor } = useRecoilValue(ColorSelector);

  return (
    <ReplyInputContainer bordercolor={bghelp}>
      <ReplyInputText
        type="text"
        value={value}
        onChange={onChange}
        placeholder=""
        name="text"
        color={SecondaryTextColor}
      />
      <ReplyInputActions>
        {loading ? (
          <ReplyInputButtons>
            <LoadingContainerSvg>
              <SvgloadingGroup />
            </LoadingContainerSvg>
          </ReplyInputButtons>
        ) : (
          <ReplyInputButtons onClick={ConfirmClick} bordercolor={bghelp}>
            <SvgTick width={12} />
          </ReplyInputButtons>
        )}
        <ReplyInputButtons onClick={CancelClick} bordercolor={bghelp}>
          <SvgClose width={12} />
        </ReplyInputButtons>
      </ReplyInputActions>
    </ReplyInputContainer>
  );
};

export default ReplyInput;
