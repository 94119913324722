import React, { useContext, useEffect, useRef } from "react";
import { FlexAppContainer } from "../../../App.style";
import { LightTypography, Typography } from "../../../elements/fonts/Fonts";
import { DashboardUpdatesData } from "./DashboardUpdatesData";
import {
  UpdatesContainer,
  UpdatesInfoDiv,
  UpdatesRowDiv,
  UpdatesTitleDiv,
  LightTypographyWrapper,
} from "./DashboardUpdates.style";

import {
  ColorSelector,
  TableColorSelector,
} from "../../../recoil/ThemeSelectors";
import { useRecoilValue } from "recoil";
import { getIconBasedOnType } from "../../../elements/StudentLandingTable/StudentLandingTableFunction";
import { t } from "i18next";
import {
  translateTextDisplays,
  translateTitleOrName,
} from "../../Natifications/TranslationFunc";

interface DashboardUpdatesProps {
  classNotificationsData: any[];
}

const DashboardUpdates = ({
  classNotificationsData,
}: DashboardUpdatesProps) => {
  const { Table_Head_TR, Table_nth_child } = useRecoilValue(TableColorSelector);
  const { SecondaryTextColor } = useRecoilValue(ColorSelector);

  // Filter activities and resources
  const activities =
    classNotificationsData &&
    classNotificationsData.length > 0 &&
    classNotificationsData.filter((item) =>
      [
        "AssignmentActivities",
        "QuizActivities",
        "SurveyActivities",
        "ForumActivities",
      ].includes(item.refTable)
    );
  const resources =
    classNotificationsData &&
    classNotificationsData.length > 0 &&
    classNotificationsData.filter(
      (item) =>
        ![
          "AssignmentActivities",
          "QuizActivities",
          "SurveyActivities",
          "ForumActivities",
          "",
        ].includes(item.refTable)
    );

  const formatedData = [
    { id: "sfdsvfsdfds", title: "dashboard.Resources", Data: resources },
    { id: "asdasdfgrtfwwdw", title: "dashboard.Activities", Data: activities },
  ];

  useEffect(() => {
    console.log("formatedData", formatedData);
  }, []);

  return (
    <FlexAppContainer column={true} padding="0 0  1.25rem 0" gap="1.25rem">
      <div style={{ padding: "1.25rem 1.25rem 0 1.25rem" }}>
        <Typography
          text={t("dashboard.Since Your Last Access")}
          fontSize="1rem"
        />
      </div>
      <FlexAppContainer column={true} gap="1.2rem">
        {formatedData &&
          formatedData.length > 0 &&
          formatedData.map(
            (item, indx) =>
              item.Data &&
              item.Data.length > 0 && (
                <UpdatesContainer key={item.id}>
                  <UpdatesTitleDiv bgcolor={Table_Head_TR}>
                    <Typography
                      text={t(item.title || "")}
                      fontSize="0.875rem"
                      color="#5dd3b3"
                    />
                  </UpdatesTitleDiv>
                  {item.Data &&
                    item.Data.map((val) => (
                      <UpdatesRowDiv nth_child_bgcolor={Table_nth_child}>
                        <UpdatesInfoDiv>
                          {getIconBasedOnType(
                            val.type,
                            val.refTable ? val.refTable : ""
                          )}
                          <Typography
                            color={`${
                              val.type === "missingSubmit"
                                ? "#D85D66"
                                : SecondaryTextColor
                            }`}
                            fontSize="0.75rem"
                            text={translateTitleOrName(val, t)}
                          />
                        </UpdatesInfoDiv>
                        <LightTypographyWrapper>
                          <LightTypography
                            fontSize="0.75rem"
                            text={translateTextDisplays(val, t)}
                          />
                        </LightTypographyWrapper>
                      </UpdatesRowDiv>
                    ))}
                </UpdatesContainer>
              )
          )}
      </FlexAppContainer>
    </FlexAppContainer>
  );
};

export default DashboardUpdates;
