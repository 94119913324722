import React from 'react'

function isObjectEmpty(obj: any) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && !obj[key]) {
        return true;
      }
    }
    return false;
  }

export default isObjectEmpty