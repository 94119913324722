import { useState, useEffect, SetStateAction, useContext, useRef } from "react";
import { SvgArrowShock, SvgArrowSimple, SvgMore } from "../../elements/Icons";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../elements/fonts/Fonts";
import ReusableInput from "../../elements/reusableInput/ReusableInput";
import {
  AbsoluteDiv,
  ButtonAdd,
  ButtonCancel,
  ButtonsRow,
  CourseNameRow,
  CourseNameWithSemester,
  GenerateAttendanceWrapper,
  GoBackRow,
  PointerContainer,
  RadioButton,
  TableContent,
  TableContentHolder,
  TableSection,
  TableTbody,
  TableTh,
  TableThead,
  TableTr1,
  ViewAllWithSearchRow,
  ViewAllWithSearchRowWithSession,
} from "./GenerateAttendance.style";
import { CourseData, TableTrData } from "./NewGenerateAttendanceData";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { formatDateTime } from "../attendanceAllSessions/AttendanceAllsessionsFunctions";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { WebSocketConnector } from "../../Views/messages/webSocketConnection";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../utils/StringManipulation";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector, TableColorSelector } from "../../recoil/ThemeSelectors";
import TitleHeaderTextIconNavigator from "../../elements/TitleHeaderTextIconNavigator/TitleHeaderTextIconNavigator";
import ProfileImgHolder from "../../elements/ProfileImgHolder/ProfileImgHolder";
import { WebsocketContext } from "../../contexts/notificationContext";
import { CheckBox } from "../manageWeight/ManageWeight.style";
import MappedIndexClickOutSide from "../../hooks/MapedIndexClickOutSide";
import SelectedOptions from "./newGenerateAttendanceSelect/SelectedOptions";
// Define interfaces
interface Participant {
  sourceId: string;
  username: string;
  profilePicture: string;
}

interface Session {
  sessionTitle: string;
  userSourceId: string;
  statusId: number;
}

const NewGenerateAttendance = () => {
  const navigate = useNavigate();
  const [formInputValue, setFormInputValue] = useState("");
  const [sessionTitle, setSessionTitle] = useState("");
  const [changesMade, setChangesMade] = useState(false);
  const [participantData, setParticipantData] = useState<Participant[]>([]);
  const [sessionData, setSessionData] = useState<Session[]>([]);
  const [MainSessionData,setMainSessionData]=useState<any>([])
  const tenantName = localStorage.getItem("tenant");
  const { classId } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const {
    SecondaryTextColor,
    bghelp,
    theme,
    MainColoredColor,
    checkboxBgColor,
    checkboxBorderColor,
    checkboxBorderRadius,
  } = useRecoilValue(ColorSelector);
  const {Table_Head_TR,Table_nth_child} = useRecoilValue(TableColorSelector)
  const { RadioButtonGradientColor } =
    useRecoilValue(ColorSelector);

const currentUrl = location.pathname;
const sessionId = currentUrl.split("/").pop();
  const handleBackToManageWeight = () => {
    navigate(`/courseview/Gradebook/AttendanceAllSessions/${classId}`);
  };
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const { isReady, value, send } = useContext(WebsocketContext);

  
    const fetchParticipantsData = async () => {
      try {
        const response = await axios.get(
          `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/participant/class/${classId}/${tenantName}`
        );
        const filteredParticipants: Participant[] = response.data.data.filter(
          (participant: { role: string }) => participant.role !== "Teacher"
        );
        setParticipantData(filteredParticipants);
      } catch (error) {
        console.error("Error fetching participants data:", error);
      }
    };

  useEffect(() => {
    fetchSessionData();
    fetchParticipantsData();
  }, []);

    const fetchSessionData = async () => {
      try {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/attendance/session/${tenantName}/${sessionId}`
        );
        setMainSessionData(response.data.data)
        setSessionTitle(response.data.data.sessionTitle)
        setSessionData(response.data.data.sessionAttendanceArray);
      } catch (error) {
        console.error("Error fetching sessions data:", error);
      }
    };

    

  const getAttendanceStatus = (sourceId: string) => {
    const attendance = sessionData.find(
      (item) => item.userSourceId === sourceId
    );
    return attendance ? attendance.statusId : null;
  };

  const handleRadioChange = (sourceId: string, statusId: number) => {
    const existingStudentIndex = sessionData.findIndex((item) => item.userSourceId === sourceId);
    const updatedSessionData = [...sessionData];
  
    if (existingStudentIndex !== -1) {
      // If the student already exists in sessionData, update the status
      updatedSessionData[existingStudentIndex].statusId = statusId;
    } else {
      // If the student doesn't exist in sessionData, add a new entry
      updatedSessionData.push({
        sessionTitle:MainSessionData?.sessionTitle , // Set sessionTitle as desired
        userSourceId: sourceId,
        statusId: statusId,
      });
    }
  
    // Update the session data and set changesMade to true
    setSessionData(updatedSessionData);
    setChangesMade(true);
  };
  
  const handleMultipleRadioChange = (statusId: number) => {
    const updatedSessionData = [...sessionData];
  
    selectedStudents.forEach((sourceId) => {
      const existingStudentIndex = updatedSessionData.findIndex((item) => item.userSourceId === sourceId);
  
      if (existingStudentIndex !== -1) {
        // If the student already exists in sessionData, update the status
        updatedSessionData[existingStudentIndex].statusId = statusId;
      } else {
        // If the student doesn't exist in sessionData, add a new entry
        updatedSessionData.push({
          sessionTitle: MainSessionData?.sessionTitle, // Set sessionTitle as desired
          userSourceId: sourceId,
          statusId: statusId,
        });
      }
    });
  
    // Update the session data and set changesMade to true
    setSessionData(updatedSessionData);
    setChangesMade(true);
  };

  const handleSaveClick = async () => {
    try {
      const dataToSend = participantData
        .map((student) => {
          const statusId = getAttendanceStatus(student.sourceId);
          if (statusId !== 0 && statusId !== null) {
            return {
              sessionId: sessionId,
              statusId,
              userSourceId: student.sourceId,
            };
          }
          return null;
        })
        .filter(Boolean);
      const response = await axios.post(
        `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/attendance/session/create/${tenantName}`,
        { data: dataToSend }
      );
      if (isReady && send) {
        
        send(JSON.stringify({
          action: 'sendPublic',
          data: {
            tenantName: tenantName,
            classSourceId: classId,
            subId:subIdSplitted,
            provider:provider,
            type: 'missingAttendance', 
            users:dataToSend
          }
        }));
      }

      toast.success("Attendance data saved successfully");
      navigate(`/courseview/Gradebook/AttendanceAllSessions/${classId}`)
    } catch (error) {
      console.error("Error saving attendance data:", error);
      toast.error("Error saving attendance data");
    }
  };

  const handleFormInputChange = (value: SetStateAction<string>) => {
    setFormInputValue(value);
  };

  const filteredParticipantData = participantData.filter((student) =>
    student.username.toLowerCase().includes(formInputValue.toLowerCase())
  );
  const formatDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false // Use 24-hour format
    });
    return formattedDate;
  };
  const Headers=["gradebook.Student ID", "participants.Student","gradebook.Present","gradebook.Late","gradebook.Absent","gradebook.Excused"]
  const [selectedStudents, setSelectedStudents] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const applyStatusToSelectedStudents = (statusId: number) => {
    selectedStudents.forEach((sourceId) => {
      console.log(sourceId,"changing for source id")
      handleMultipleRadioChange( statusId);
    });
  };
  const handleSelectAllChange = () => {
  
    setSelectAll(!selectAll);
    setSelectedStudents((prevSelected) => {
      if (selectAll) {
        return [];
      } else {
        console.log(participantData.map(student => student.sourceId),"clicked")
        return participantData.map(student => student.sourceId);
      }
    });
  };

  const handleCheckboxChange = (sourceId: any) => {
    setSelectedStudents(prevSelected => {
      if (prevSelected.includes(sourceId)) {
        return prevSelected.filter(id => id !== sourceId);
      } else {
        console.log(selectedStudents,"clicked")
        return [...prevSelected, sourceId];
      }
    });
  };
  const parts = sessionTitle.split(' ');
const number = parts[1]; // 

const [showMenuOfSelectedIndex, setShowMenuOfSelectedIndex] = useState<boolean>(false);
const clickOutsideRef = MappedIndexClickOutSide(() =>
  setShowMenuOfSelectedIndex(false)
);
const handleShowSvgMore=()=>{
  setShowMenuOfSelectedIndex(!showMenuOfSelectedIndex)
}
  return (
    <GenerateAttendanceWrapper>
     <div style={{display:"flex",width:"100%",justifyContent:"space-between"}}>
     <div style={{display:"flex",flexDirection:"column",gap:"1em"}}>
      <TitleHeaderTextIconNavigator title={`${t("gradebook.Session")}  ${number}`} />
      
      <CourseNameWithSemester>
      
        <CourseNameRow>
          <LightTypography
            text={formatDate(MainSessionData?.sessionStartDate )}
            fontSize="1.125rem"
          />
        </CourseNameRow>
      </CourseNameWithSemester>
      </div>
     
      <ViewAllWithSearchRowWithSession>
        <ViewAllWithSearchRow>
        <div style={{display:"flex",flexDirection:"column",gap:"1rem",alignItems:"flex-end"}}>
          <div onClick={handleBackToManageWeight}>
            <Typography
              text={t("gradebook.View All Sessions")}
              fontSize="0.75rem"
              color="#5DD3B3"
            />
          </div>
          <ReusableInput
            left="1.69rem"
            holderWidth=""
            placeHolder={t("dashboard.Search")}
            padingLeft="3.31rem"
            inputBorderRadius="6.25rem"
            inputWidth="23.625rem"
            inputHeight="3.125rem"
            display="block"
            onInputChange={(value: SetStateAction<string>) => handleFormInputChange(value)}
            value={formInputValue}
          /></div>
        </ViewAllWithSearchRow>
      </ViewAllWithSearchRowWithSession>
      </div>
      <TableSection>
      <TableContentHolder>
      
        <TableThead style={{ background: Table_Head_TR, height: '3.625rem' }}>
   
          
         
          {Headers.map((item, index) => (
            <TableTh
              colSpan={index === 2 ? 4 : 2}
              key={index + 1}
            >
              {index === 0 && (
                <CheckBox
                  bgcolor={checkboxBgColor}
                  bordercolor={checkboxBorderColor}
                  borderradius={checkboxBorderRadius}
                  type="checkbox"
                  checked={selectAll}
                  name="selectAll"
                  onClick={()=>handleSelectAllChange()}
                  marginright="2rem"
                  margintop="0.5rem"
                />
              )}
              <div style={{ display: 'flex', alignItems: 'center', gap: '3rem' }}>
                <MediumTypography
                  text={t(item)}
                  fontSize="1rem"
                  lineHeight="1.75rem"
                  whiteSpace="nowrap"
                />
                 {index === 0 && (
                  <PointerContainer
                      onClick={() =>
                        handleShowSvgMore()
                      }
                    >
                      <SvgMore  />
                      {showMenuOfSelectedIndex && (
                                           <AbsoluteDiv ref={clickOutsideRef} >
                                          <SelectedOptions 
                                      marginLeft="1rem"
                                      applyStatusToSelectedStudents={applyStatusToSelectedStudents}
                                          Type={""}
                                           setShowMenuOfSelectedIndex={setShowMenuOfSelectedIndex}/>
                                           </AbsoluteDiv>
                                         )}
                    </PointerContainer>
                    
                     )}
              </div>
            </TableTh>
          ))}
        </TableThead>
        <TableContent>
          <TableTbody>
            {filteredParticipantData.map((student, index) => (
              <TableTr1 key={student.sourceId} nth_child_bgcolor={Table_nth_child}>
                
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.62rem' }}>
                <CheckBox
                 bgcolor={checkboxBgColor}
                 bordercolor={checkboxBorderColor}
                 borderradius={checkboxBorderRadius}
                 type="checkbox"
                 name="student.sourceId"
                    checked={selectedStudents.includes(student.sourceId)}
                    onChange={() => handleCheckboxChange(student.sourceId)}
                  />
                  <LightTypography text={student.sourceId || ""} fontSize="0.875rem" />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.62rem', paddingTop: '1rem' }}>
                  {/* <ProfileImgHolder
                    imgSrc={student.profilePicture || ''}
                    Size="3.125rem"
                  /> */}
                   
                  <MediumTypography
                    text={student.username || ''}
                    fontSize="0.875rem"
                  />
                </div>
                <RadioButton
                  type="radio"
                  name={`status_${index}`}
                  value="Present"
                  checked={getAttendanceStatus(student.sourceId) === 1}
                  onChange={() => handleRadioChange(student.sourceId, 1)}
                  bgcolor={RadioButtonGradientColor}
                />
                <RadioButton
                  type="radio"
                  name={`status_${index}`}
                  value="Absent"
                  checked={getAttendanceStatus(student.sourceId) === 3}
                  onChange={() => handleRadioChange(student.sourceId, 3)}
                  bgcolor={RadioButtonGradientColor}
                />
                <RadioButton
                  type="radio"
                  name={`status_${index}`}
                  value="Late"
                  checked={getAttendanceStatus(student.sourceId) === 2}
                  onChange={() => handleRadioChange(student.sourceId, 2)}
                  bgcolor={RadioButtonGradientColor}
                />
                <RadioButton
                  type="radio"
                  name={`status_${index}`}
                  value="Excused"
                  checked={getAttendanceStatus(student.sourceId) === 4}
                  onChange={() => handleRadioChange(student.sourceId, 4)}
                  bgcolor={RadioButtonGradientColor}
                />
              </TableTr1>
            ))}
          </TableTbody>
        </TableContent>
      </TableContentHolder>
    </TableSection>
      {changesMade && (
        <ButtonsRow>
          <ButtonAdd onClick={handleSaveClick}>
            <Typography
              text={t("forms.Save")}
              color="#fff"
              fontSize="0.875rem"
              fontWeight="400"
              lineHeight="normal"
            />
          </ButtonAdd>
          <ButtonCancel onClick={handleBackToManageWeight}>
            <Typography
              text={t("forms.Cancel")}
              color="#D85D66"
              fontSize="0.875rem"
              fontWeight="400"
              lineHeight="normal"
            />
          </ButtonCancel>
        </ButtonsRow>
      )}
    </GenerateAttendanceWrapper>
  );
};

export default NewGenerateAttendance;
