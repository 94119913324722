import styled from 'styled-components'

export const Flexed=styled.div`

display:flex;
justify-content:space-between;
align-items:left;
flex-direction:column;
gap:0.63rem;

/* padding-right:5px; */
width:22.30rem;

`;



export const Titletext=styled.div`
display:flex;
flex-direction:column;
width:100%;
margin-bottom:0.62rem;
gap: 0.4rem;


`


export const LowerContainer=styled.div`
display:flex;
position:relative;
gap:1.25rem;
/* padding-left:1px;
padding-right:1px; */

`;

export const Image = styled.img`
  border: 2px solid white;
  border-radius: 50%;
  width: 2.5rem; /* Adjust the width of the image */
  height:2.5rem; /* Adjust the height of the image */
  margin-right:5px;
  position:relative;
`;
export const GreenRight=styled.div`
position: absolute;
width:0.34rem;
height:0.34rem;

bottom:1rem;
right:0.5rem;
`


export const Wholeprofile=styled.div`
position:relative;
width:fit-content;
`



export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  /* align-items: center; */
`;