import React, { useEffect, useState } from "react";
import { Container, FirstThree, FirstThreeCont, FlexDiv, MainCont, MainUser, NoResult, OtherRanks, OtherRanksCont, ProfileAndRank, ProfileContainer, Rank, RankCont, RankNumber, RuleBookBtn, ViewAllCont, } from "./ExpandedLeaderboard.style";
import { SvgBackArrow } from "../../elements/Icons";
import { MediumTypography, Typography } from "../../elements/fonts/Fonts";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SearchWithFilter from "../../elements/searchWithFilter/SearchWithFilter";
import Profile from "../../assets/updateProfileImg.jpg";
import { ReactComponent as SvgStarIcon } from "../../assets/icons/MiniStar.svg";
import { ReactComponent as SvgNoResultIcon } from "../../assets/icons/NoProgress.svg";
import CancelButton from "../../elements/StyledButton/CancelButton";
import ViewAll from "./viewAll/ViewAll";
import PopUp from "../../elements/FilterPopUp/PopUp";
import LeaderboardFilter from "../leaderboardFilter/LeaderboardFilter";

const ExpandedLeaderboard = () => {
  const navigate = useNavigate();
  const { classId } = useParams();
  const location = useLocation();
  const sortedLeaderboardData = location.state.sortedLeaderboardData;

  const [isViewAll, setIsViewAll] = useState(false);
  const [showFilterPopUp, setShowFilterPopup] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewAllMode, setViewAllMode] = useState(false);

  // get first three ranks
  const firstTwo = sortedLeaderboardData.slice(0, 2);
  const thirdOne = sortedLeaderboardData.slice(0, 3).pop();

  // handle view all 
  const handleChangeView = () => {
    setIsViewAll(!isViewAll);
    setViewAllMode(true)
  };

  // close popup
  const handleClosePopup = () => {
    setShowFilterPopup(false);
  };

  const handleApplyFilters = (filters: any) => {
  };

  // Function to handle the click event of the back arrow button
  const handleBackArrowClick = () => {
    setIsViewAll(false);
    setViewAllMode(false);

    // If not in view all mode, navigate to completion tracking with isLeaderboard state
    if (!isViewAll) {
      navigate(`/completionTracking/${classId}`, { state: { isLeaderboard: true }, });
    }
  };

  const navigateToRulebook = () => {
    navigate(`/rulebook/${classId}`, { state: { sortedLeaderboardData, activeTab: "myPoints" }, });
  };

  // search
  const filteredPosts = sortedLeaderboardData.filter((leaderboardItem: any) =>
    leaderboardItem.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // use effect to sqitch to view all mode on search
  useEffect(()=>{
    if(searchTerm === "" && !viewAllMode){
      setIsViewAll(false)
    }
  },[searchTerm])

  return (
    <MainCont>
      {/* Header */}
      <FlexDiv gap="0.63rem">
        <SvgBackArrow onClick={handleBackArrowClick} style={{ cursor: "pointer" }} />
        <Typography text="Leaderboard" fontSize="1.75rem" />
      </FlexDiv>
      <FlexDiv gap="0.63rem">
        <SearchWithFilter placeholder="Search" onClick={() => setShowFilterPopup(true)} value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value); setIsViewAll(true); }} />
        <RuleBookBtn onClick={() => { navigate(`/ruleBook/${classId}`, { state: { sortedLeaderboardData }, }); }} > RuleBook </RuleBookBtn>
      </FlexDiv>

      {!isViewAll ? (
        <>
        {/* view all button */}
          <div style={{ alignSelf: "flex-end", cursor: "pointer" }} onClick={handleChangeView} >
            <Typography text="View All" fontSize="0.625rem" color="#5DD3B3" />
          </div>
          <Container>
            {/* first three container */}
            <FirstThreeCont>
              {sortedLeaderboardData.length >= 3 && (
                <div>
                  <RankCont>
                    <ProfileAndRank>
                      <ProfileContainer bgimage={thirdOne.profile} width="3.125rem" height="3.125rem" />
                      <Rank> <Typography text="3" fontSize="0.75rem" /> </Rank>
                    </ProfileAndRank>

                    <FirstThree>
                      <MediumTypography text={thirdOne.name} fontSize="0.875rem" />
                      <FlexDiv gap="0.63rem" style={{ justifyContent: "center" }} >
                        <MediumTypography text={thirdOne.points} fontSize="0.875rem" />
                        <SvgStarIcon width="0.875rem" height="0.875rem" />
                      </FlexDiv>
                    </FirstThree>
                  </RankCont>
                </div>
              )}
              <div style={{ display: "flex", gap: "0.62rem", alignItems: "flex-end", }} >
                {firstTwo.map((leaderboardItem: any, index: number) => (
                  <RankCont key={index}>
                    <ProfileAndRank>
                      <ProfileContainer bgimage={leaderboardItem.profile} width="3.125rem" height="3.125rem" />
                      <Rank> <Typography text={(index + 1).toString()} fontSize="0.75rem" /> </Rank>
                    </ProfileAndRank>

                    <FirstThree index={index}>
                      <MediumTypography text={leaderboardItem.name} fontSize="0.875rem" />
                      <FlexDiv gap="0.63rem" style={{ justifyContent: "center" }} >
                        <MediumTypography text={leaderboardItem.points} fontSize="0.875rem" />
                        <SvgStarIcon width="0.875rem" height="0.875rem" />
                      </FlexDiv>
                    </FirstThree>
                  </RankCont>
                ))}
              </div>
            </FirstThreeCont>

            {/* other ranks part */}
            <OtherRanksCont>
              {sortedLeaderboardData
                .slice(3)
                .map((leaderboardItem: any, index: number) => (
                  <OtherRanks key={index}>
                    <FlexDiv gap="1.25rem">
                      <RankNumber>
                        <Typography text={(index + 4).toString()} fontSize="0.75rem" />
                      </RankNumber>
                      <ProfileContainer bgimage={leaderboardItem.profile} width="3.125rem" height="3.125rem" />
                      <Typography text={leaderboardItem.name} fontSize="1rem" />
                    </FlexDiv>
                    <FlexDiv gap="0.63rem">
                      <MediumTypography text={leaderboardItem.points} fontSize="0.875rem" />
                      <SvgStarIcon width="0.875rem" height="0.875rem" />
                    </FlexDiv>
                  </OtherRanks>
                ))}
            </OtherRanksCont>

            {/* user part */}
            <MainUser>
              <ProfileAndRank style={{ marginBottom: "1.31rem" }}>
                <ProfileContainer bgimage={Profile} />
                <Rank> <Typography text="4" fontSize="0.75rem" /> </Rank>
              </ProfileAndRank>
              <Typography text="Sultan Al Rajhi" fontSize="1rem" />
              <FlexDiv gap="0.63rem" style={{ justifyContent: "center", marginTop: "0.63rem", marginBottom: "0.81rem", }} >
                <MediumTypography text="10" fontSize="0.875rem" />
                <SvgStarIcon width="0.875rem" height="0.875rem" />
              </FlexDiv>
              <CancelButton name="View Logs" onClickFunction={navigateToRulebook} borderColor="#5DD3B3" backgroundColor="#5DD3B3" color="#fff" width="6.9375rem" />
            </MainUser>
          </Container>
        </>
      ) : (
        <>
          {filteredPosts.length === 0 ? (
            <NoResult>
              <SvgNoResultIcon />
              <Typography text="No Result Found" fontSize="1.375rem" />
            </NoResult>
          ) : (
            <ViewAllCont>
              {filteredPosts.map((leaderboardItem: any, index: number) => (
                <ViewAll sortedLeaderboardData={leaderboardItem} index={index} />
              ))}
            </ViewAllCont>
          )}
        </>
      )}

      {showFilterPopUp && (
        <PopUp content={ <LeaderboardFilter onClosePopup={handleClosePopup} onApplyFilters={handleApplyFilters} /> } showline={false} show={showFilterPopUp} setShow={setShowFilterPopup} showButton={false} />
      )}
    </MainCont>
  );
};

export default ExpandedLeaderboard;
