// ExcelGlobalFunction.ts
import * as XLSX from "xlsx";


// download as excel for simple table having one array  and lot of objects
export const ExcelDownload = (rows: any[], tableName: string) => {
  const newData = rows.map((row) => row);
  const workSheet = XLSX.utils.json_to_sheet(newData);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, tableName);
  // Buffer
  let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  // Binary string
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  // Download
  XLSX.writeFile(workBook, `${tableName}.xlsx`);
};

export const GraphExcelDownload = (rows: any[], tableName: string) => {
  const truncatedTableName = tableName.substring(0, 31); // Truncate to 31 characters
  const newData = rows.map((row) => row);
  const workSheet = XLSX.utils.json_to_sheet(newData);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, truncatedTableName); // Use truncated name
  // Buffer
  let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  // Binary string
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  // Download
  XLSX.writeFile(workBook, `${truncatedTableName}.xlsx`);
};




export const IdHaveArrayOfData = (data: any[], tableName: string) => {
  const workBook = XLSX.utils.book_new();

  data.forEach((item) => {
    if (item.Data && Array.isArray(item.Data)) {
      const nestedData = item.Data.map((nestedItem: any) => ({
        ...nestedItem,
        ParentID: item.student_id, // Assuming student_id is a unique identifier
      }));

      const workSheet = XLSX.utils.json_to_sheet(nestedData);
      XLSX.utils.book_append_sheet(workBook, workSheet, `${item.student_id}_${tableName}`);
    }
  });

  // Buffer
  let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  // Binary string
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  // Download
  XLSX.writeFile(workBook, `${tableName}.xlsx`);
};

// download as excel for complex table having one array any sub arrays  and lot of objects
export const BigExcelDownload = (data: any[], tableName: string) => {
  const flattenedData: any[] = [];

  data.forEach((item) => {
    item.Quizes.forEach((quizItem: any) => {
      flattenedData.push({
        ...quizItem,
        StudentID: item.id,
        StudentName: item.StudentName,
      });
    });
  });

  const workSheet = XLSX.utils.json_to_sheet(flattenedData);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, tableName);

  // Buffer
  let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  // Binary string
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  // Download
  XLSX.writeFile(workBook, `${tableName}.xlsx`);
};

