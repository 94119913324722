import React, { ReactNode, createContext, useContext, useState } from "react";

interface QuizGraderContextProps {
  globalShowPopup: boolean;
  setGlobalShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  globalsurveyName: string;
  setGlobalSurveyName: React.Dispatch<React.SetStateAction<string>>;
  globaldescription: string; // Assuming this state is of type string
  setGlobalDescription: React.Dispatch<React.SetStateAction<string>>; // Assuming this setter is for string state
  globalstartDate: Date | null;
  setGlobalStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  globalendDate: Date | null;
  setGlobalEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  globalfromDate: Date | null;
  setGlobalFromDate: React.Dispatch<React.SetStateAction<Date | null>>;
  globaltoDate: Date | null;
  setGlobalToDate: React.Dispatch<React.SetStateAction<Date | null>>;
  globalisVisible: boolean;
  setGlobalIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  globalisTimeVisible: boolean;
  setGlobalIsTimeVisible: React.Dispatch<React.SetStateAction<boolean>>;
  globalisAnonymous: boolean;
  setGlobalIsAnonymous: React.Dispatch<React.SetStateAction<boolean>>;
  globalselectedSurveyId: number;
  setGlobalSelectedSurveyId: React.Dispatch<React.SetStateAction<number>>;
  globalselectedSurvey: string;
  setGlobalSelectedSurvey: React.Dispatch<React.SetStateAction<string>>;
  editSurvey: boolean;
  setEditSurvey: React.Dispatch<React.SetStateAction<boolean>>;
  globalSurveyDuration: string;
  setGlobalSurveyDuration: React.Dispatch<React.SetStateAction<string>>;
}

// Create the context
const quizGraderContext = createContext<QuizGraderContextProps | undefined>(
  undefined
);

// Create a provider component
const CourseCreatoProvider = ({ children }: { children: ReactNode }) => {
  const [globalShowPopup, setGlobalShowPopup] = useState<boolean>(false);
  const [globalsurveyName, setGlobalSurveyName] = useState("");
  const [globaldescription, setGlobalDescription] = useState("");
  const [globalstartDate, setGlobalStartDate] = useState<Date | null>(null);
  const [globalendDate, setGlobalEndDate] = useState<Date | null>(null);
  const [globalfromDate, setGlobalFromDate] = useState<Date | null>(null);
  const [globaltoDate, setGlobalToDate] = useState<Date | null>(null);
  const [globalisVisible, setGlobalIsVisible] = useState(true);
  const [globalisTimeVisible, setGlobalIsTimeVisible] = useState(false);
  const [globalisAnonymous, setGlobalIsAnonymous] = useState(false);
  const [globalselectedSurveyId, setGlobalSelectedSurveyId] = useState(0);
  const [globalselectedSurvey, setGlobalSelectedSurvey] = useState("");
  const [editSurvey, setEditSurvey] = useState<boolean>(false);
  const [globalSurveyDuration, setGlobalSurveyDuration] = useState<string>("");

  return (
    <quizGraderContext.Provider
      value={{
        globalShowPopup,
        setGlobalShowPopup,
        globalsurveyName,
        setGlobalSurveyName,
        globaldescription,
        setGlobalDescription,
        globalstartDate,
        setGlobalStartDate,
        globalendDate,
        setGlobalEndDate,
        globalfromDate,
        setGlobalFromDate,
        globaltoDate,
        setGlobalToDate,
        globalisVisible,
        setGlobalIsVisible,
        globalisTimeVisible,
        setGlobalIsTimeVisible,
        globalisAnonymous,
        setGlobalIsAnonymous,
        globalselectedSurveyId,
        setGlobalSelectedSurveyId,
        globalselectedSurvey,
        setGlobalSelectedSurvey,
        editSurvey,
        setEditSurvey,
        globalSurveyDuration,
        setGlobalSurveyDuration,
      }}
    >
      {children}
    </quizGraderContext.Provider>
  );
};

// Create a custom hook to access the context values
const useMyContext = () => {
  const context = useContext(quizGraderContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
};

export { CourseCreatoProvider, useMyContext };
