import React from "react";
import { NotesCardCompo } from "../../components/NotesCard/main/NotesCardCompo";
import { PageHeader } from "../../components/pageHeader/PageHeader";

import { NoteMainCol } from "./Notes.style";

export const NotesCardMain = (): JSX.Element => {
  return (
    <>
      <NoteMainCol>
        {/* <CourseBanner /> */}

        <NotesCardCompo />
      </NoteMainCol>
    </>
  );
};
