import React, { useState, useEffect } from "react";
import {
  BackgroundOverlay,
  ButtonContainer,
  ButtonContent,
  CheckBox,
  CustomCheckboxWrapper,
  Overlay,
  PopupContainer,
  PopupContentContainer,
  CloseIconWrapper,
  RadiosDisplays,
} from "./LanguageComponent.style";
import { useStateContext } from "../../contexts/ContextProvider";
import { SvgClose, SvgLangaugeIcon } from "../../elements/Icons";
import { LightTypography, Typography } from "../../elements/fonts/Fonts";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  ColorSelector,
  PopupSelectorColor,
  IconsColorSelector,
} from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { languageState } from "../../recoil/RecoilStates";

interface LanguageTogglePopup {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const languages = [
  { code: "en", name: "English" },
  { code: "tr", name: "Turkish" },
  { code: "ar", name: "Arabic" },
  { code: "fr", name: "French" },
];

export default function LanguageComponent({
  show,
  setShow,
}: LanguageTogglePopup) {
  const { RadioButtonGradientColor } = useRecoilValue(ColorSelector);
  const { i18n } = useTranslation();
  const [, setLanguage] = useRecoilState(languageState);
  const { t } = useTranslation();
  const { PageBgColor, ContentContainer_BgColor } =
    useRecoilValue(PopupSelectorColor);
  const { iconCloseColor } = useRecoilValue(IconsColorSelector);
  const { setIsPopUpOpen } = useStateContext();

  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("language") || "en"
  );

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
    setLanguage(selectedLanguage);
  }, [selectedLanguage, i18n, setLanguage]);

  const handleLanguageChange = (languageCode: any) => {
    setSelectedLanguage(languageCode);
    setLanguage(languageCode);
    localStorage.setItem("language", languageCode);
  };

  return (
    <Overlay isvisible={show.toString()}>
      <BackgroundOverlay bgcolor={PageBgColor}>
        <PopupContainer>
          <PopupContentContainer bgcolor={ContentContainer_BgColor}>
            <CloseIconWrapper onClick={() => setShow(false)}>
              <SvgClose color={iconCloseColor} />
            </CloseIconWrapper>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "0.63rem",
                marginTop: "-1.5rem",
              }}
            >
              <SvgLangaugeIcon />
              <Typography text={t("language.language")} fontSize="1.125rem" />
              <LightTypography
                text={t(
                  "language.Select the language you want to use throughout the system"
                )}
                fontSize="0.75rem"
              />
            </div>
            <RadiosDisplays>
              {languages.map((lang) => (
                <CustomCheckboxWrapper key={lang.code}>
                  <CheckBox
                    bgcolor={RadioButtonGradientColor}
                    type="radio"
                    name={lang.name}
                    checked={selectedLanguage === lang.code}
                    onChange={() => handleLanguageChange(lang.code)}
                  />
                  <LightTypography
                    fontSize="0.875rem"
                    text={t(`language.${lang.name.toLowerCase()}`)}
                  />
                </CustomCheckboxWrapper>
              ))}
            </RadiosDisplays>
            <ButtonContainer></ButtonContainer>
          </PopupContentContainer>
        </PopupContainer>
      </BackgroundOverlay>
    </Overlay>
  );
}
