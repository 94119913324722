import styled from "styled-components";

export const SurverRadoiCOLS = styled.div`
display:flex;
flex-direction:column;
width:100%;
gap:0.63rem;
margin-bottom:0.4rem;
`;
export const SurverRadoiRow = styled.div`
display:flex;
/* width:100%; */
gap:2rem;
margin-top:0.35rem;
/* background-color:aqua; */
`;

export const SurverRadoiItems = styled.div`
display:flex;
/* width:100%; */
gap:1rem;
align-items:center;
`;