import styled from "styled-components";

export const ButtonStyle = styled.button<{ disabledButton?: string }>`
  background-color: transparent;
  border-radius: 6.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: ${(props) =>
    props.disabledButton === "onGoing" ? "pointer" : "not-allowed"};
  gap: 0.63rem;
  min-width: 8.25rem;
  max-width: 10rem;
  height: 1.75rem;
  border: ${(props) =>
    props.disabledButton === "notStarted"
      ? "1px solid #576371"
      : props.disabledButton === "onGoing"
      ? "solid #2af39c 1px"
      : props.disabledButton === "ended"
      ? "solid #D85D66 1px"
      : "solid #2af39c 0.06rem"};
  color: ${(props) =>
    props.disabledButton === "onGoing"
      ? props.theme.dark.main_color
      : props.disabledButton === "ended"
      ? "#D85D66"
      : props.disabledButton === "notStarted"
      ? "#576371"
      : ""};
  box-shadow: 0 0 0 transparent;
  transition: box-shadow 0.3s ease-in-out;
  padding: 1rem;
  &:hover {
    box-shadow: ${(props) =>
      props.disabledButton === "onGoing"
        ? "0 0 10px rgba(42, 243, 156, 0.3)"
        : "none"};
  }
`;
