import React, { useEffect, useState } from "react";
import {
  AttendanceContainer,
  AttendanceHeaderRow,
  AttendanceProfileRow,
} from "./AttendancePopup.style";
import ProfileImgHolder from "../../elements/ProfileImgHolder/ProfileImgHolder";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AttendanceItem, Session } from "./interfaces";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { TableColorSelector } from "../../recoil/ThemeSelectors";
import { MediumTypography, Typography } from "../../elements/fonts/Fonts";
interface AddAttendancePopUp {
  onClose: any;
  selectedStudentId?: any;
  setAttendanceLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
const AttendancePopupHeader = ({
  onClose,
  selectedStudentId,
  setAttendanceLoading,
}: AddAttendancePopUp) => {
  const [loading, SetLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const id = useParams();
  const navigate = useNavigate();

  const handleClickOnSpecificSession = (sessionId: number) => {
    navigate(
      `/courseview/GradeBook/${id.classId}/generateAttendance/${sessionId}`
    ); // Navigating to another route when the div is clicked
  };

  const [sessionsData, setSessionsData] = useState<Session[]>([]);

  const [participantData, setParticipantData] = useState([]);
  const [AttendanceData, setAttendanceData] = useState<AttendanceItem[]>([]);
  const tenantName = localStorage.getItem("tenant"); // Replace 'yourTenantName' with the actual tenant name
  const classSourceId = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchSessionsData = async () => {
      try {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/sessions/${tenantName}/${classSourceId.classId}`
        );
        const sortedSessions = response.data.data.sessions.sort(
          (a: any, b: any) => {
            // Extract numbers from session titles
            const regex = /(\d+)/;
            const aNumber = parseInt(a.title.match(regex)[0]);
            const bNumber = parseInt(b.title.match(regex)[0]);

            return aNumber - bNumber;
          }
        );
        setSessionsData(sortedSessions);
        setAttendanceData(response.data.data.attendance);

        SetLoading(false);
      } catch (error) {
        console.error("Error fetching sessions data:", error);
      }
    };

    fetchSessionsData();

    // Cleanup function
    return () => {
      // You can perform cleanup actions here if needed
    };
  }, [tenantName, classSourceId]);

  useEffect(() => {
    const fetchParticipantData = async () => {
      try {
        const response = await axios.get(
          `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/participant/class/${id.classId}/${tenantName}`
        );
        // Filter the participant data to get only the data for the specified student ID
        const studentData = response.data.data.filter(
          (student: { sourceId: string }) =>
            student.sourceId === selectedStudentId
        );
        setParticipantData(studentData);
      } catch (error) {
        console.error("Error fetching participant data:", error);
      }
    };

    fetchParticipantData();

    // Cleanup function
    return () => {
      // You can perform cleanup actions here if needed
    };
  }, [id, selectedStudentId]);

  const { Table_Head_TR, theme } = useRecoilValue(TableColorSelector);
  return (
    <div>
      {participantData &&
        participantData.map(
          (student: any) =>
            student.role === "Student" && (
              <AttendanceProfileRow>
                <ProfileImgHolder
                  Size={" 3.125rem"}
                  imgSrc={student.profilePicture || ""}
                />
                <div style={{display: "flex", flexDirection: "column",gap: "0.31rem"}}>
                <Typography text={student.username ?? ""} fontSize="1rem" />
                <Typography text={`ID: ${student.sourceId ?? ""}`} fontSize="0.75rem" color="#AAB1B9"/>
                </div>
              </AttendanceProfileRow>
            )
        )}
    </div>
  );
};

export default AttendancePopupHeader;
