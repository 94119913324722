import React, { FC, useEffect, useRef, useState } from "react";
import { theme } from "../../../theme";
import {
  TypographyRegularDescription,
  Typography,
  LightTypography,
} from "../../../elements/fonts/Fonts";

import { ReactComponent as EditPartIcon } from "../../../assets/icons/pen_1.svg";
import {
  IconContainer,
  IconPointsContainer,
  QuestionFlexContainer,
  PartContainer,
} from "./QuestionComposed.style";
import { QuestionComposedProps } from "./QuestionComposedInterface";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { SvgDelete } from "../../../elements/Icons";
import DOMPurify from "dompurify";
import StyledInput from "../../../elements/StyledInput/StyledInput";
import { useTranslation } from "react-i18next";
import { useQuizMyContext } from "../../../contexts/CourseCreatorQuizContext";
import { toArabicDigits, toArabicLetter } from "../../../utils/formatDayArabic";
const QuestionComposed: FC<QuestionComposedProps> = ({
  question,
  setPartIndex,
  setIndex,
  index,
  handleShowEditQuest,
  handleShowQuest,
  setDeletePart,
  disableClick,
  notValidate,
  handleGrade,
  setNotValidate,
  setQuest,
  quest,
  setShowQuest,
  partIndex
}) => {
  const [questionGrade, setQuestionGrade] = useState<{ [key: string]: string }>(
    {}
  );
  const [showInput, setShowInput] = useState<{ [key: string]: boolean }>({})
  const inputRefs = useRef<{ [key: string]: HTMLElement | null }>({});
  const {
    globalQuestionsArray,
    setGlobalQuestionsArray,
  } = useQuizMyContext();
  const { t } = useTranslation();
  const { OpacitiesTextColor, SecondaryTextColor, theme } =
    useRecoilValue(ColorSelector);
    const decodeHtml = (value: any) => {
      const textarea = document.createElement("textarea");
      textarea.innerHTML = value;
      return textarea.value;
    };

    const handleGradeChange = (e: React.ChangeEvent<HTMLInputElement>,pIndex:number) => {
      handleGrade && handleGrade(e);
      const updatedQuest = { ...questionGrade };
    if (e.target.id === "grade") {
      updatedQuest.grade = e.target.value;
    }
      setQuestionGrade &&
        setQuestionGrade((prevId) => ({
          ...prevId,
          [`Question ${index}_Part ${partIndex}`]: updatedQuest.grade,
        }));
      let newQuestions = [...globalQuestionsArray];
      if (newQuestions[index].questType === "composed") {    
          newQuestions[index].questPart[partIndex || 0].grade = e.target.value;
      }
    const filteredQuestions = newQuestions.filter(
      (question) => question.type !== ""
    );
    
    setGlobalQuestionsArray(filteredQuestions);
    };
  
    useEffect(() => {
      if(question && question.questPart && question.questPart.length > 0 && question.questPart[partIndex].grade ){
        setQuestionGrade &&
        setQuestionGrade((prevId) => ({
          ...prevId,
          [`Question ${index}_Part ${partIndex || 0}`]: question && question.questPart && question.questPart.length > 0 ? question.questPart[partIndex || 0].grade : "0",
        }));
      }
      
    }, [question ,notValidate,partIndex,index]);

    const handleDoubleClick = (pIndex:number)=>{
      setShowInput &&
        setShowInput((prevId) => ({
          ...prevId,
          [`Question ${index}_Part ${pIndex || 0}`]: true,
        }));
    }
  
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        Object.keys(inputRefs.current).forEach((key) => {
          if (inputRefs.current[key] && !inputRefs.current[key]?.contains(event.target as Node)) {
            setShowInput((prevId) => ({
              ...prevId,
              [key]: false,
            }));
          }
        });
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [inputRefs]);
  return (
    <>
      {question?.questPart?.map((quest, pindex) => (
        <IconPointsContainer key={pindex}>
          {quest.icon}
          <QuestionFlexContainer>
            {quest.type ? (
              <IconContainer>
                {/* <PartContainer onClick={() => { setPartIndex(pindex); setIndex(index);handleShowQuest() }}> */}
                <PartContainer
                  onClick={() => {
                    setIndex(index);
                    setPartIndex(pindex);
                  }}
                >
                  <TypographyRegularDescription
                    text={`${t("quiz.Part")} ${toArabicLetter(String.fromCharCode(65 + pindex))}`}
                    fontSize="0.75rem"
                    lineHeight="1.125rem"
                    color={
                      theme === "light"
                        ? SecondaryTextColor
                        : SecondaryTextColor + "80"
                    }
                  />
                  <TypographyRegularDescription
                    text={t(`quiz.${quest.type}`)}
                    fontSize="0.75rem"
                    lineHeight="1.125rem"
                    color={OpacitiesTextColor}
                  />
                </PartContainer>
                {!disableClick && (
                  <>
                  <PartContainer>
                    <EditPartIcon
                      onClick={() => {
                        handleShowEditQuest();
                        setIndex(index);
                        setPartIndex(pindex);
                      }}
                    />
                    <SvgDelete
                      onClick={() => {
                        setDeletePart(true);
                        setIndex(index);
                        setPartIndex(pindex);
                      }}
                    />
                    
                  </PartContainer>
                  <div
                  ref={(el) => inputRefs.current[`Question ${index}_Part ${pindex}`] = el}
                  onDoubleClick={()=>handleDoubleClick(pindex)}
                  onClick={() => {
                    // handleShowEditQuest();
                    setIndex(index);
                    setPartIndex(pindex);
                    // setNotValidate && setNotValidate(true)
                  }}
                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                  {showInput[`Question ${index}_Part ${partIndex}`] === true ?
                  <StyledInput
                    // borderColor="transparent"
                    type="number"
                    width="2.5rem"
                    height="2.5rem"
                    textAlign="center"
                    color="#5DD3B3"
                    value={`${quest?.grade}`}
                    id="grade"
                    onChange={(e)=>handleGradeChange(e,pindex)}
                    fontSize="0.75rem"
                    padding={"0.94rem .25rem"}
                  />
                  :
                  <>
                  <LightTypography  fontSize="0.75rem" text={`${toArabicDigits(quest?.grade)}`} color="#5DD3B3"/>
                  <div style={{marginRight:"0.4rem"}}></div>
                  </>
                  }
                  <Typography
                    text={`${t("course view.pts")}`}
                    fontSize="0.75rem"
                    color="#5DD3B3"
                  />
                </div>
                </>
                )}
              </IconContainer>
            ) : (
              ""
            )}
            {/* <TypographyRegularDescription
              text={quest?.question}
              fontSize="0.75rem"
              lineHeight="1.125rem"
              color={
                theme === "light"
                  ? SecondaryTextColor
                  : SecondaryTextColor + "80"
              }
            /> */}
            <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(decodeHtml(quest?.question)),
            }}
            style={{
              fontSize:"0.75rem",
          lineHeight:"1.125rem",
          color:
            theme === "light" ? SecondaryTextColor : SecondaryTextColor + "80"
          
          }}
          ></p>
          </QuestionFlexContainer>
        </IconPointsContainer>
      ))}
    </>
  );
};
export default QuestionComposed;
