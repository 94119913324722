import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 38rem;
  height: fit-content;
  max-height: 14.625rem;
  align-items: center;
`;

export const FilterMaps = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
`;

export const FilterMapsLine = styled.div`
  flex: 0 0 50%; /* Each item should take up 50% of the container's width */
  min-width: 7rem;
  margin-bottom: 0.7rem;
  display: flex;
  align-items: center;
`;

export const RadioLabel = styled.label<StyleThemeInterface>`
  display: flex;
  align-items: center;
  cursor: pointer;
  &::before {
    content: "";
    width: 1em;
    min-width: 1em;
    max-width: 1em;
    height: 1em;
    min-height: 1em;
    max-height: 1em;
    border: 0.05em solid #fff;
    border-radius: 50%;
    [dir="ltr"] & {
      margin-right: 0.5rem;
  }
    [dir="rtl"] & {
      margin-left: 0.5rem;
  }
    background: ${(props) =>
      props.bgcolor || "linear-gradient(to bottom, #2F353D, #545F6D)"};
  }
  &:hover:before {
    background: linear-gradient(to bottom, #5dd3b340, #2f3b4060);
  }
`;

export const SeletectOptinToogle = styled.input`
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  border: 1px solid #fff;
  transition: background-color 0.3s ease;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  &:hover + ${RadioLabel} ::before {
    background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
  }

  &:checked + ${RadioLabel}::before {
    background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.05em solid #5dd3b3;
  }

  :focus + ${RadioLabel}::before {
    background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
    border: 0.05em solid #5dd3b3;
  }
`;

export const SearchTab = styled.div`
  width: 100%;
`;
