import React, { FC, useEffect, useState } from "react";
import { ReactComponent as RightArrow } from "../../../../assets/icons/arrow_simple_1 (2).svg";
import { ReactComponent as LeftArrow } from "../../../../assets/icons/arrow_simple_1 (3).svg";
import { ReactComponent as DownArrow } from "../../../../assets/icons/Arrow.svg";
import StudentPhoto from "../../../assets/updateProfileImg.jpg";
import {
  StudentImage,
  StudentContainer,
  ContentContainer,
  AvailabilityContainer,
  NameContainer,
  SectionContainer,
} from "./Student.style";
import {
  LightTypography,
  TypographyRegularDescription,
  MediumTypography,
} from "../../../../elements/fonts/Fonts";
import { StudentProps } from "./StudentInterface";
import { get } from "../../../../utils/AxiosRequests";
const Student: FC<StudentProps> = ({
  student,
  //setAvarage,
  // setGraded,
  // setSubmitted,
  setStudentIndex,
  students,
  studentIndex,
}) => {
  const [Index, setIndex] = useState<number>(0);
  const [profilePicture, setProfilePicture] = useState<string>(""); // State to hold the profile picture URL
  const [loading, setLoading] = useState<boolean>(true);
  const imageGif = require("../../../../assets/loadingGif.gif");
  const image = require("../../../../assets/userProfile.png");
  const language = localStorage.getItem("language");
  const NextStudent = (index: number) => {
    if (student.length === 0) {
      return;
    }
    const newIndex = (studentIndex + 1) % student.length;
    setIndex(newIndex);
    setStudentIndex(newIndex);
    // setGraded(student[newIndex].graded);
    //setAvarage(student[newIndex].avarage);
    // setSubmitted(student[newIndex].submitted);
  };
  const BackStudent = (index: number) => {
    const newIndex = (studentIndex - 1 + student.length) % student.length;
    setIndex(newIndex);
    setStudentIndex(newIndex);
    //setAvarage(student[newIndex].avarage);
    // setSubmitted(student[newIndex].submitted);
  };

  // const getProfilePictureForUsers = async (userSourceId: any) => {
  //   try {
  //     setLoading(true);
  //     const tenantName = localStorage.getItem("tenant");
  //     const response = await get(`user/profilePicture/${tenantName}/${userSourceId}`);
  //     setLoading(false);
  //     return response.data;

  //   } catch (error) {
  //     console.error("Error fetching profile picture:", error);
  //     throw error; // Rethrow the error to handle it at a higher level
  //   }
  // };

  // useEffect(() => {
  //   // Fetch the profile picture URL when the component mounts or whenever 'students' change

  //   const fetchProfilePicture = async (sourceId: any) => {
  //     try {
  //       const picture = await getProfilePictureForUsers(sourceId);
  //       setProfilePicture(picture);

  //     } catch (error) {
  //       console.error("Error fetching profile picture:", error);
  //     }
  //   };

  //   if (student && student[Index]) {
  //     fetchProfilePicture(student[Index].sourceId);

  //   }
  // }, [student, Index]);

  return (
    <StudentContainer>
      {student &&
        student.length > 0 &&
        student
          ?.filter((data, index) => index === studentIndex)
          .map((data, index) => (
            <React.Fragment key={index}>
              <LeftArrow
                onClick={() => BackStudent(index)}
                style={{
                  cursor: "pointer",
                  transform: `${language === "ar" ? "rotate(180deg)" : ""}`,
                }}
              />

              <StudentImage
                src={data.profilePicture ? data.profilePicture : image}
                alt="student-image"
              />

              <ContentContainer>
                <AvailabilityContainer>
                  {/* <LightTypography
                  text="LATE"
                  fontSize="0.75rem"
                  fontWeight="400"
                  lineHeight="normal"
                  color="#D85D66"
                />
                <TypographyRegularDescription
                  text={`${data.late}/10`}
                  fontSize="0.625rem"
                  fontWeight="400"
                  lineHeight="normal"
                /> */}
                </AvailabilityContainer>
                <SectionContainer>
                  <NameContainer>
                    <MediumTypography
                      text={data.fullName}
                      fontSize="1.125rem"
                      fontWeight="400"
                      lineHeight="normal"
                    />

                    {/* <DownArrow  style={{ cursor: "pointer" }}/> */}
                  </NameContainer>
                  {/* <MediumTypography
                  text={data.section}
                  fontSize="0.75rem"
                  fontWeight="400"
                  lineHeight="normal"
                /> */}
                </SectionContainer>
              </ContentContainer>

              <RightArrow
                onClick={() => NextStudent(index)}
                style={{
                  cursor: "pointer",
                  transform: `${language === "ar" ? "rotate(180deg)" : ""}`,
                }}
              />
            </React.Fragment>
          ))}
    </StudentContainer>
  );
};

export default Student;
