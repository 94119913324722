import React, { useState } from "react";
import {
  BadgeContainer,
  Button,
  ButtonsContainer,
  ModuleName,
  PointsContainer,
  StarContainer,
} from "./Badge.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import { ReactComponent as SvgImportNew } from "../../assets/icons/Group 2325.svg";
import { ReactComponent as SvgExportIcon } from "../../assets/icons/Export.svg";
import { ReactComponent as SvgStarIcon } from "../../assets/icons/Group 3691.svg";
import { ReactComponent as SvgBadgeIcon } from "../../assets/icons/Group 3697.svg";
import CancelButton from "../../elements/StyledButton/CancelButton";
import PopUp from "../../elements/Popup/PopUp";
import ShareProgressPost from "../shareProgressPost/ShareProgressPost";

const Badge = () => {
  const [showSharePopUp, setShowSharePopup] = useState<boolean>(false);
  const handleClosePopup = () => { setShowSharePopup(false); };
  return (
    <BadgeContainer>
      <div style={{ marginBottom: "1.44rem" }}>
        <SvgBadgeIcon />
      </div>
      <Typography text="Congratulations!" fontSize="1.5rem" fontWeight="400" fontStyle="normal" />
      <ModuleName>
        <LightTypography text="You have successfully completed" fontSize="0.875rem" fontWeight="400" fontStyle="normal" />
        <Typography text="Module Name" fontSize="0.875rem" fontWeight="400" fontStyle="normal" />
      </ModuleName>

      <ButtonsContainer>
        <Button>
          <SvgImportNew />
          <MediumTypography text="Certificate" fontSize="0.875rem" />
        </Button>
        <Button>
          <SvgExportIcon />
          <MediumTypography text="Export QR" fontSize="0.875rem" />
        </Button>
      </ButtonsContainer>
      <PointsContainer>
        <StarContainer>
          <SvgStarIcon />
          <Typography text="20+" fontSize="1.75rem" color="#F3D53F" />
          <TypographyRegularDescription text="Module Points" fontSize="0.875rem" />
        </StarContainer>
        <StarContainer>
          <SvgStarIcon />
          <Typography text="6" fontSize="1.75rem" color="#F3D53F" />
          <TypographyRegularDescription text="Early Completion Points" fontSize="0.875rem" />
        </StarContainer>
      </PointsContainer>
      <CancelButton onClickFunction={() => { setShowSharePopup(true); }} name="Share with others" color="#5DD3B3" borderColor="#5DD3B3" backgroundColor="transparent" width="11rem" />
      {showSharePopUp && (
        <PopUp content={ <ShareProgressPost onClosePopup={handleClosePopup} isBadge={true} /> } showline={false} show={showSharePopUp} setShow={setShowSharePopup} showButton={false} closeContainer={false} />
      )}
    </BadgeContainer>
  );
};

export default Badge;
