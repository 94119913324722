import { FC, useState } from "react";
import {
  InstructorsMainWrapper,
  InstructorsWrapper,
  InstructorInfoWrapper,
  ProfileWithSubtitlesContainer,
  ProfileWithSubtitlesWrapper,
  SubtitlesWrapper,
  BioWrapper,
  CourseHoverHolder,
} from "./Instructors.style";
import { Typography, LightTypography } from "../../../elements/fonts/Fonts";
import Profile from "../../../elements/profile/Profile";
import InstructorsData from "./instructorsData";
import { useTranslation } from "react-i18next";
import { CourseCardHover } from "../../courseCard/courseCardHoverDetails/CourseCardHover";
interface InstructorsProps {
  teacher: any;
  classId: string;
}

const Instructors: FC<InstructorsProps> = ({ teacher, classId }) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const image = require("../../../assets/userProfile.png");
  const { t } = useTranslation();
  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  return (
    <InstructorsMainWrapper>
      <Typography
        text={`${t("course view.Instructors")} :`}
        fontSize="1.375rem"
      />
      <InstructorsWrapper>
        {teacher &&
          teacher.length > 0 &&
          teacher.map((item: any, index: number) => (
            <InstructorInfoWrapper key={index}>
              <ProfileWithSubtitlesContainer>
                <ProfileWithSubtitlesWrapper>
                  <Profile
                    iconPath={item.profilePicture ? item.profilePicture : image}
                    handleOnMouseEnter={() => handleMouseEnter(index)}
                    handleOnMouseLeave={handleMouseLeave}
                  />
                  <SubtitlesWrapper>
                    <Typography text={item.username} fontSize="1.25rem" />
                  </SubtitlesWrapper>
                </ProfileWithSubtitlesWrapper>
                <CourseHoverHolder
                  ishovered={hoveredIndex === index}
                  onMouseLeave={handleMouseLeave}
                >
                  <CourseCardHover
                    handleMouseLeave={handleMouseLeave}
                    teacherInfo={null}
                    profilePicture={
                      item.profilePicture ? item.profilePicture : image
                    }
                    classId={classId}
                    isHovered={hoveredIndex === index}
                    totTeachers={true}
                    teacherId={teacher[index].sourceId}
                  />
                  {/* <Typography text={item.qualifications} fontSize="1rem" /> */}
                </CourseHoverHolder>
              </ProfileWithSubtitlesContainer>
              <BioWrapper>
                <LightTypography
                  text={item.bio}
                  fontSize="0.875rem"
                  lineHeight="1.375rem"
                />
              </BioWrapper>
            </InstructorInfoWrapper>
          ))}
      </InstructorsWrapper>
    </InstructorsMainWrapper>
  );
};

export default Instructors;
