export const  getColorForLevel=(level: string) => {
  switch (level.toLowerCase()) {
    case "advanced":
      return "#5DD3B3";
    case "intermediate":
      return "#CED35D";
    case "poor":
      return "#D85D66";
    default:
      return "";
  }
}

// Example usage:
// console.log(getColorForLevel("Advanced")); // Output: #5DD3B3
// console.log(getColorForLevel("Intermediate")); // Output: #CED35D
// console.log(getColorForLevel("Poor")); // Output: #D85D66
// console.log(getColorForLevel("Unknown")); // Output: ""
