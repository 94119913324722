import styled from "styled-components";

export const ASIDContainer = styled.div`
display: flex;
flex-direction: column;
gap:1.25rem;
width:80%;
border-radius: 0rem 0.625rem 0.625rem 0rem;
background: #384450;
padding: 0 0 1.25rem 0;
`;

export const ASIDFistInfo = styled.div`
display: flex;
flex-direction: column;
gap:0.60rem;
padding:1.25rem 0 0 1.25rem ;
`;

export const ASIDProfileInfo = styled.div`
display: flex;
gap:1.25rem;
align-items:center;
padding: 0 1.25rem;
`;

export const ASIDProfileImg = styled.img`
display: flex;
width: 3.125rem;
height: 3.125rem;
flex-shrink: 0;
border-radius:50%;
object-fit:cover;

`;



export const ASIDSessionTable= styled.div`
width: 100%;
display: flex;
flex-direction:column;

`;

export const ASIDSessionTableObject= styled.div`
display: flex;
flex-direction:column;
width:fit-content;

`;
export const ASIDTableBigCol= styled.div`
display: flex;
/* flex-direction:column; */
padding:  1.25rem 0;
/* background: var(--Grey-Gradient, linear-gradient(90deg, #415160 0%, #293440 107.88%)); */
min-width:15rem;


`;
export const ASIDTableCol= styled.div`
display: flex;
flex-direction:column;
gap:0.19rem;
padding:  0 1.25rem;
/* border-left: 1px solid #AAB1B9 !important ; */



`;
export const ASIDTableBigHeaderRow= styled.div`
display: flex;
width:fit-content;
background: var(--Grey-Gradient, linear-gradient(90deg, #415160 0%, #293440 107.88%)); 

`;
export const ASIDTableHeaderRow= styled.div`
display: flex;
/* flex-direction:column; */
/* padding: 0 1.25rem; */
opacity: 0.7;
gap: 0.5rem;

`;


export const ASIDbBodyRow= styled.div`
display: flex;


`;

export const ASIDbBodyText= styled.div`
display: flex;
padding:1.25rem;
background: #384450;
min-width:15rem;

`;

export const ASIDArrowsPosition= styled.div`
display: flex;
justify-content:flex-end;
padding-right:10rem

`;