// import React, { useState } from "react";
// import {
//   RadioLabel,
//   SeletectOptinToogle,
// } from "../../components/FacultiDepartment/FacultiDepartment.style";
// import { MediumTypography, TypographyRegularDescription } from "../fonts/Fonts";
// import {
//   SurverRadoiCOLS,
//   SurverRadoiItems,
//   SurverRadoiRow,
// } from "./SurveyRadoiButton.style";

// interface RadoiComponent {
//   PartTitle?: string;
//   text?: string;
//   onChange: (value: string) => void;
//   studentAnswer?: string;
// }

// export const SurveyRadoiButton = ({
//   PartTitle,
//   text,
//   onChange,
//   studentAnswer,
// }: RadoiComponent): JSX.Element => {
//   const [selectedValue, setSelectedValue] = useState<string | undefined>(
//     studentAnswer || undefined
//   );

//   const handleRadioChange = (value: string) => {
//     setSelectedValue(value);
//     onChange(value);
//   };

//   return (
//     <>
//       <SurverRadoiCOLS>
//         <TypographyRegularDescription
//           text={PartTitle || ""}
//           color="#5DD3B3"
//           fontSize="0.875rem"
//         />

//         <MediumTypography text={text || ""} fontSize="0.875rem" />
//         <SurverRadoiRow>
//           <SurverRadoiItems>
//             <SeletectOptinToogle
//               type="radio"
//               id={`${text ? text.trim() : ""}-yes`}
//               value="Yes"
//               name={text ? text.trim() : ""}
//               checked={selectedValue === "Yes"}
//               onChange={() => handleRadioChange("Yes")}
//             />
//             <RadioLabel htmlFor={`${text ? text.trim() : ""}-yes`}>
//               Yes
//             </RadioLabel>
//           </SurverRadoiItems>

//           <SurverRadoiItems>
//             <SeletectOptinToogle
//               type="radio"
//               id={`${text ? text.trim() : ""}-no`}
//               value="No"
//               name={text ? text.trim() : ""}
//               checked={selectedValue === "No"}
//               onChange={() => handleRadioChange("No")}
//             />
//             <RadioLabel htmlFor={`${text ? text.trim() : ""}-no`}>
//               No
//             </RadioLabel>
//           </SurverRadoiItems>
//         </SurverRadoiRow>
//       </SurverRadoiCOLS>
//     </>
//   );
// };

import { useEffect, useState } from "react";
import {
  RadioLabel,
  SeletectOptinToogle,
} from "../../components/FacultiDepartment/FacultiDepartment.style";
import { MediumTypography, TypographyRegularDescription } from "../fonts/Fonts";
import {
  SurverRadoiCOLS,
  SurverRadoiItems,
  SurverRadoiRow,
} from "./SurveyRadoiButton.style";
import {
  StudentSurveyInstructor,
  StudentSurveyInstructorReply,
} from "../../components/SurveyStudentView/main/StudentSurvey.style";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import DOMPurify from "dompurify";
import { t } from "i18next";
import { findStudentAnswer } from "../../components/SurveyStudentView/main/surveyStudentCompoUtils";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../utils/StringManipulation";
import { useParams } from "react-router-dom";

interface RadoiComponent {
  PartTitle?: string;
  text?: string;
  onChange: (value: string) => void;
  studentAnswer?: any; // New prop for studentAnswer
  comment: string;
  disabled?: boolean;
  setClassSelections?:any;
  question?:any;
  studentAnswerArray?:any
  id?:number
  setSurveyQuestionId?:any
}
export const SurveyRadoiButton = ({
  PartTitle,
  text,
  onChange,
  studentAnswer,
  comment,
  disabled,
  question,
  studentAnswerArray,
  setClassSelections,
  id,
  setSurveyQuestionId
}: RadoiComponent): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    studentAnswer || undefined
  );
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const params = useParams();
  const classId = params.classId;

  const handleRadioChange = (value: string) => {
    if (!disabled) {
      // Allow changing the value only if studentAnswer is not present
      setSelectedValue(value);
      onChange(value);
    }
  };

  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };

  useEffect(() => {
    if (studentAnswer || studentAnswer !== null) {
      setSelectedValue(studentAnswer);
    } else {
      setSelectedValue(undefined);
    }
  }, [studentAnswer]);
  useEffect(()=>{
    const key = `${question.title}`;
    const idKey = `${key}_ID`;
    setSurveyQuestionId((prevId:any) => ({
      ...prevId,
      [idKey]: question.SurveyQuestionsId || 0,
    }));
    if(studentAnswerArray && studentAnswerArray.length === 0 ){
      // Fetch and set the timer from local storage if it exists
      const savedStudentAnswer = JSON.parse(localStorage.getItem('localStudentSurveyResponse') || '[]');
      const currentQuizAnswer = savedStudentAnswer.find((item: any) =>
        item.classSourceId === classId &&
        item.subId === subIdSplitted &&
        item.refId === id
      );

      if (currentQuizAnswer &&
        question) {
        
      if(currentQuizAnswer && currentQuizAnswer.localStudentResponse && currentQuizAnswer.localStudentResponse[key]){
        setClassSelections((prevLineValues:any) => ({
          ...prevLineValues,
          [key]: currentQuizAnswer.localStudentResponse[key],
        }));
        } else{
          setClassSelections((prevLineValues:any) => ({
            ...prevLineValues,
            [key]: '',
          }));
        }
      }
      }
  },[question])
  const { RadioButtonGradientColor, themeTextColor, theme } = useRecoilValue(ColorSelector);
  return (
    <>
      <SurverRadoiCOLS>
        <TypographyRegularDescription
          text={PartTitle || ""}
          color="#5DD3B3"
          fontSize="0.875rem"
        />

        {/* <MediumTypography text={text || ""} fontSize="0.875rem" /> */}
        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              decodeHtml(text || '')
                            ),
                          }}
                          style={{
                            fontSize: "1rem",
                            fontFamily: "Primary Font-Medium"
                          }}
                        ></div>
        <SurverRadoiRow>
          <SurverRadoiItems>
            <SeletectOptinToogle
              type="radio"
              id={`${text ? text.trim() : ""}-yes`}
              value="Yes"
              name={text ? text.trim() : ""}
              checked={selectedValue === "Yes"}
              onChange={() => handleRadioChange("Yes")}
              disabled={disabled} // Disable if studentAnswer is present
            />
            <RadioLabel
              bgColor={RadioButtonGradientColor}
              htmlFor={`${text ? text.trim() : ""}-yes`}
            >
              {t("survey.YES")}
            </RadioLabel>
          </SurverRadoiItems>

          <SurverRadoiItems>
            <SeletectOptinToogle
              type="radio"
              id={`${text ? text.trim() : ""}-no`}
              value="No"
              name={text ? text.trim() : ""}
              checked={selectedValue === "No"}
              onChange={() => handleRadioChange("No")}
              disabled={disabled} // Disable if studentAnswer is present
            />
            <RadioLabel
            bgColor={RadioButtonGradientColor}
             htmlFor={`${text ? text.trim() : ""}-no`}>
              {t("survey.NO")}
            </RadioLabel>
          </SurverRadoiItems>
        </SurverRadoiRow>
      </SurverRadoiCOLS>
      {comment && comment.trim() !== "No Comment" && (
        <StudentSurveyInstructor>
          <TypographyRegularDescription
            text={t("assignment.Instructor Comment")}
            fontSize="0.875rem"
          />
          <StudentSurveyInstructorReply color={themeTextColor} theme={theme}>
            <TypographyRegularDescription
              text={comment}
              fontSize="0.75rem"
            />
          </StudentSurveyInstructorReply>
        </StudentSurveyInstructor>
      )}
    </>
  );
};
