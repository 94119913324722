import React from "react";
import { TextEditorAnswerReaderRow } from "./TextEditorAnswerReader.style";
import { TextEditor } from "../../../../elements/TextEditor/TextEditor";
import TinyEditor from "../../../../elements/tinyEditor/TinyEditor";
interface TextEditorAnswerReaderInterface {
  answer: any;
}

const TextEditorAnswerReader = ({
  answer,
}: TextEditorAnswerReaderInterface) => {
  return (
    <TextEditorAnswerReaderRow>
      <TinyEditor
        initialValue={(answer.answer && answer.answer.toString()) || ""}
        readonly
        height="8rem"
      />
    </TextEditorAnswerReaderRow>
  );
};

export default TextEditorAnswerReader;
