import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const FilterTextContainer = styled.div<StyleThemeInterface>`
  width: fit-content;
  padding: 0.52rem 0.62rem;
  gap: 0.62rem;
  border-radius: 0.5rem;
  background: ${(props) =>
    props.theme === "dark" ? "rgba(87, 99, 113, 0.20)" : "#AAB1B915"};
  display: flex;
  align-items: center;
`;

export const TagRemoveButtonAction = styled.div<StyleThemeInterface>`
  cursor: pointer;
  color: #5dd3b3;
  display: flex;
  justify-content: center;
  align-items: center;
`;