import React, { useEffect, useState } from "react";
import AllMembersGroups from "../../components/allMembersGroups/AllMembersGroups";
import DroppableGroupsArea from "../../components/droppableGroupsArea/DroppableGroupsArea";
import {
  GlobalWrapper,
  PageTitle,
  AddGroupPageWrapper,
  BackArrow,
} from "./GroupCreator.style";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { Typography } from "../../elements/fonts/Fonts";
import { useTranslation } from "react-i18next";
import { SvgBackArrow } from "../../elements/Icons";
import { useStateContext } from "../../contexts/ContextProvider";

const GroupCreator = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const params = useParams();
  // const classId = params.classId;

  const location = useLocation();
  const classId = location.state.classId;
  console.log("from group creator", classId);
  return (
    <GlobalWrapper>
      <PageTitle>
        <Link to={`/courseView/groups/${classId}`}>
          <BackArrow>
            <SvgBackArrow />
          </BackArrow>
        </Link>
        <Typography text={t("group.Group Creator")} fontSize="1.75rem" />
      </PageTitle>

      <AddGroupPageWrapper>
        <AllMembersGroups />
        <DroppableGroupsArea />
      </AddGroupPageWrapper>
    </GlobalWrapper>
  );
};

export default GroupCreator;
