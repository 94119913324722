import React, { ReactElement, useEffect, useState } from "react";
import {
  ComplexSelectBodyTable,
  ComplexSelectContainer,
  ComplexSelectRow,
  ComplexSelectTable,
  ComplexSelectTableBodyRow,
  ComplexSelectTableHeaderRow,
} from "../../AnalyticsAllAssigment.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../../../elements/fonts/Fonts";
import { SurveyReusableTableData } from "./SurveyReusableTableIntereface";
import {
  ComplexSelectTableMessagePopup,
  PointerContainer,
  SurveyReusableTableCell,
} from "./SurveyReusableTable.style";
import {
  CheckboxLabel,
  SeletectOptinToogle,
} from "../../../ProfileCheckBoxOptions/NotificationSetting.style";
import { SvgEmail, SvgMore } from "../../../../elements/Icons";
import useClickOutside from "../../../../hooks/useClickOutside";
import {
  InstructorMessageBox,
  InstructorViewCompletionSearchContainer,
} from "../../../StudentCompletionTrackingInstrcuterView/InstructorViewCompletion.style";
import SearchElement from "../../../../elements/searchElement/SearchElement";
import SurveyReusableHeader from "./SurveyReusableHeader";
import {
  handleSelectAllToggle,
  handleItemToggle,
} from "../../../../hooks/TableSelectedOptions";
import MappedIndexClickOutSide from "../../../../hooks/MapedIndexClickOutSide";
import { formatDate } from "../../../../utils/FormatedDATE";
import StudentMessage from "../../../StudentCompletionTrackingInstrcuterView/StudentMessage";
import {
  handleChangeShapeToggle,
  updateMessageText,
} from "../../../StudentCompletionTrackingInstrcuterView/functions";
import { SelectedStudentsInterface } from "../../../InstructorAnalyticsUsersView/InstructorAnalyticsUsersViewTable/InstructorAnalyticsUsersViewTable";

/**
 * Interface for the props of SurveyComplexResponsesTable component.
 */
interface SurveyComplexResponsesTableInterface {
  data: any[]; // Array of data to display in the table.
  SelectedNameTitleLabel: string; // Label for the selected name/title.
  FistElementChanges?: ReactElement[]; // Array of React elements for first element changes (optional).
  marginTop?: string; // Top margin for the table (optional).
}

/**
 * SurveyComplexResponsesTable Component
 * @param {SurveyComplexResponsesTableInterface} props - Props for SurveyComplexResponsesTable component.
 * @returns {ReactElement} - SurveyComplexResponsesTable component.
 */
const SurveyComplexResponsesTable = ({
  data,
  SelectedNameTitleLabel,
  FistElementChanges = [],
  marginTop = "0",
}: SurveyComplexResponsesTableInterface): ReactElement => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(
    null
  );

  /**
   * Function to handle the click event of option button.
   * @param {number} index - Index of the clicked option.
   */
  const handleOptionButtonClick = (index: number) => {
    setSelectedOptionIndex(selectedOptionIndex === index ? null : index);
  };

  /**
   * Function to handle showing/hiding the "Send All Message" popup.
   */

  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  // Initialize an array to track the checked state of each item
  const [checkedItems, setCheckedItems] = useState<boolean[]>(
    new Array(data.length).fill(false)
  );

  const clickOutsideRef = MappedIndexClickOutSide(() =>
    setSelectedOptionIndex(null)
  );

  // this useState stored the the selected students in ana array
  const [SelectedStudents, setSelectedStudents] = useState<any[]>([]);
  const [shapeToggles, setShapeToggles] = useState<Array<boolean>>(
    Array(data.length).fill(true)
  );

  const [messageTexts, setMessageTexts] = useState<{ [id: string]: string }>(
    {}
  );
  const [ErrormessageTexts, setErrorMessageTexts] = useState<{
    [id: string]: string;
  }>({});

  const handleMessageSend = (index: number, id: string) => {
    if (messageTexts[id] === undefined || messageTexts[id].trim() === "") {
      // If messageTexts[id] is undefined or empty, set the errorMessageTexts for the specific id
      setErrorMessageTexts((prevState) => ({
        ...prevState,
        [id]: "This field is required.",
      }));
      return;
    }

    const Data = {
      id: id,
      // MessageValue: messageSubjects,
      // File: FileStorageId,
      MessageText: messageTexts,
    };
  };

  // const [ShowAllMessagePopUp, setShowAllMessagePopUp] =
  // useState<boolean>(false);

  const [ShowFirstPOP, setShowFirstPOP] = useState<boolean>(false);
  const [ShowMessagesInFirst, setShowMessagesInFirst] =
    useState<boolean>(false);
  useEffect(() => {
    if (SelectedStudents.length === 0) {
      setShowFirstPOP(false);
      setShowMessagesInFirst(false);
    }
  }, [SelectedStudents]);
  // const handleShowSendAllMessage = () => {
  //   if (ShowFirstPOP === false) {
  //     setShowFirstPOP(!ShowFirstPOP);
  //   } else {
  //     setShowFirstPOP(!ShowFirstPOP);
  //     setShowMessagesInFirst(!ShowMessagesInFirst);
  //   }
  // };
  const handleShowFirstPOP = () => {
    setShowFirstPOP(!ShowFirstPOP);
  };
  const handleShowMessagePOP = () => {
    setShowMessagesInFirst(!ShowMessagesInFirst);
  };

  const SelectedStudentsData: SelectedStudentsInterface[] =
    SelectedStudents &&
    SelectedStudents.map((item) => ({
      id: item.id,
      FullName: `${item.StudentName}`, // Corrected syntax
      email: item.Email, // Changed 'items' to 'item' and corrected capitalization
    }));
  const [GroupSelectedMessageTexts, setGroupSelectedMessageTexts] =
    useState<string>("");
  const [GroupSelectedErrorMessageTexts, setGroupSelectedErrorMessageTexts] =
    useState<string>("");
  const handleSelectedGroupMessageSend = () => {
    if (GroupSelectedMessageTexts.trim() === "") {
      setGroupSelectedErrorMessageTexts("This field is required.");
      return false;
    }

    const Data = {
      // MessageValue: GroupSelectedMessageSubjects,
      MessageText: GroupSelectedMessageTexts,
      // File: GroupSelectedFile,
    };

    setGroupSelectedMessageTexts("");
  };
  const MainContainerRef = useClickOutside(ShowFirstPOP, () =>
    setShowFirstPOP(false)
  );

  return (
    <ComplexSelectTable>
      <ComplexSelectTableHeaderRow>
        <SurveyReusableTableCell>
          <SeletectOptinToogle
            type="checkbox"
            id="selectAll"
            value="selectAll"
            checked={selectAllChecked}
            onChange={() =>
              handleSelectAllToggle(
                setSelectAllChecked,
                setCheckedItems,
                data,
                setSelectedStudents
              )
            }
          />

          <CheckboxLabel htmlFor="selectAll">
            <MediumTypography text={SelectedNameTitleLabel} />
          </CheckboxLabel>
        </SurveyReusableTableCell>
        {SurveyReusableTableData.map((val, index) => (
          <SurveyReusableTableCell key={val.text}>
            <MediumTypography text={val.text} />
          </SurveyReusableTableCell>
        ))}
        <SurveyReusableTableCell ref={MainContainerRef}>
          <MediumTypography text="Responses Date" />
          <PointerContainer onClick={handleShowFirstPOP}>
            <SvgMore
              style={{
                transform: "rotate(90deg)",
              }}
            />
          </PointerContainer>
          {ShowFirstPOP && (
            <ComplexSelectTableMessagePopup
              style={{
                top: "2.2rem",
              }}
              onClick={handleShowMessagePOP}
            >
              <SvgEmail width={"1.3125rem"} height={"1rem"} />
              <MediumTypography text="Send Feedback" fontSize="0.75rem" />
            </ComplexSelectTableMessagePopup>
          )}
          {ShowMessagesInFirst && (
            <InstructorMessageBox
              ref={clickOutsideRef}
              style={{ right: "1.5rem", top: "-2rem", opacity: "1" }}
            >
              <StudentMessage
                CloseClick={handleShowMessagePOP}
                OneSelected={false}
                SelectedData={SelectedStudentsData}
                SendMessage={handleSelectedGroupMessageSend}
                MessageValue={GroupSelectedMessageTexts}
                setMessageValue={(value: React.SetStateAction<string>) =>
                  setGroupSelectedMessageTexts(value)
                }
                ErrorMessageValue={GroupSelectedErrorMessageTexts}
                setErrorMessageValue={setGroupSelectedErrorMessageTexts}
              />
            </InstructorMessageBox>
          )}
        </SurveyReusableTableCell>
      </ComplexSelectTableHeaderRow>
      <ComplexSelectBodyTable>
        {data.map((item, index) => (
          <ComplexSelectTableBodyRow key={index}>
            {/* Use FistElementChanges[index] to get the specific element */}
            <SurveyReusableTableCell>
              <SeletectOptinToogle
                type="checkbox"
                id={item.studentId?.toString()}
                value={item.studentId?.toString()}
                checked={checkedItems[index]}
                onChange={() =>
                  handleItemToggle(
                    index,
                    checkedItems,
                    setCheckedItems,
                    setSelectedStudents,
                    data
                  )
                }
              />
              <CheckboxLabel
                htmlFor={item.studentId.toString()}
                style={{ marginTop: marginTop }}
              >
                {FistElementChanges[index]}
              </CheckboxLabel>
            </SurveyReusableTableCell>
            <SurveyReusableTableCell>
              <LightTypography text={item.Status || ""} fontSize="0.75rem" />
            </SurveyReusableTableCell>
            <SurveyReusableTableCell>
              <LightTypography text={item.TimeSpent || ""} fontSize="0.75rem" />
            </SurveyReusableTableCell>
            <SurveyReusableTableCell>
              <div>
                <LightTypography
                  text={formatDate(item?.Date)}
                  fontSize="0.75rem"
                />
              </div>
              <PointerContainer onClick={() => handleOptionButtonClick(index)}>
                <SvgMore
                  style={{
                    transform: "rotate(90deg)",
                  }}
                />
              </PointerContainer>
              {selectedOptionIndex === index && shapeToggles[index] && (
                <ComplexSelectTableMessagePopup
                  ref={clickOutsideRef}
                  onClick={() =>
                    handleChangeShapeToggle(
                      index,
                      shapeToggles,
                      setShapeToggles
                    )
                  }
                >
                  <SvgEmail width={"1.3125rem"} height={"1rem"} />
                  <MediumTypography text="Message Student" fontSize="0.75rem" />
                </ComplexSelectTableMessagePopup>
              )}
              {selectedOptionIndex === index &&
                shapeToggles[index] === false && (
                  <InstructorMessageBox
                    ref={clickOutsideRef}
                    style={{ right: "1.5rem", top: "-2rem" }}
                  >
                    <StudentMessage
                      CloseClick={() =>
                        handleChangeShapeToggle(
                          index,
                          shapeToggles,
                          setShapeToggles
                        )
                      }
                      ReserverName={item.Name}
                      SendMessage={() => handleMessageSend(index, item.StdCode)}
                      MessageValue={messageTexts[item.StdCode] || ""}
                      setMessageValue={(value: string) =>
                        updateMessageText(item.StdCode, value, setMessageTexts)
                      } // Update the value for the specific id
                      ErrorMessageValue={ErrormessageTexts[item.StdCode] || ""}
                      setErrorMessageValue={(value: string) =>
                        updateMessageText(
                          item.StdCode,
                          value,
                          setErrorMessageTexts
                        )
                      }
                    />
                  </InstructorMessageBox>
                )}
            </SurveyReusableTableCell>
          </ComplexSelectTableBodyRow>
        ))}
      </ComplexSelectBodyTable>
    </ComplexSelectTable>
  );
};

export default SurveyComplexResponsesTable;
