import styled from "styled-components";

export const EmptyView = styled.div`
flex: 1;
height: 36rem;
border-radius: 0.625rem;
background: linear-gradient(180deg, #415160 0%, #293440 100%);
display: flex;
justify-content: center;
align-items: center;
`