import React, { useContext, useEffect } from 'react'
import { ContentR } from '../utils/CourseResourcesInterface'
import { Typography } from '../../../elements/fonts/Fonts';
import { useTranslation } from 'react-i18next';
import { ResourceTitle } from './ContentItem.style';
import { useRecoilState, useRecoilValue } from 'recoil';
import { adminState, selectedContent } from '../../../recoil/RecoilStates';
import { useParams } from 'react-router-dom';
import { getResourceIcon } from '../courseTimelineComponents/ResourceIcon';
import { ColorSelector } from '../../../recoil/ThemeSelectors';
import { ClassContext } from '../../../Views/dashboard/UnstructuredContext';
import ProgressBar from '../courseTimelineComponents/ProgressBar';
import VisibilityComponent from '../courseTimelineComponents/VisibilityComponent';
import { isDateInRange } from '../../../utils/FormatedDATE';

export default function ContentItem({ moduleTitle, topicTitle, content, moduleIndex, topicIndex, onSelectResource }: { moduleTitle: string, topicTitle: string, content: ContentR, moduleIndex: number, topicIndex: number, onSelectResource: Function }) {
    const { role, setContentVisibility, contentVisibility, progressChanged } = useContext(ClassContext)
    const { t } = useTranslation();
    const classID = useParams()
    const [isAdmin] = useRecoilState(adminState);
    const [selectedResourceID, setSelectedResourceId] = useRecoilState(selectedContent(classID.classId))
    // const [selectedContent, setSelectedContent] = useRecoilState(resource)
    const { backgroundColor, bghelp, themeTextColor, boxShadow } =
        useRecoilValue(ColorSelector);
    // const savedContent = JSON.parse(localStorage.getItem(`selectedResource-${classID.classId}`) || `{}`)
    // setSelectedResourceId({ contentId: savedContent.contentId, moduleIndex: savedContent.moduleIndex, topicIndex: savedContent.topicIndex, isVisible: savedContent.isVisible })

    useEffect(() => {
        // console.log("triggered")
        if (selectedResourceID !== null && selectedResourceID !== undefined && selectedResourceID.contentId === content.contentId) {
            // console.log(content)
            onSelectResource({ ...content, moduleTitle, topicTitle })
        }

    }, [selectedResourceID, classID, contentVisibility, progressChanged])
    const shortenTitle = (title: any) => {
        const maxLength = 65;
        if (title.length > maxLength) {
            return `${title.slice(0, maxLength - 8)}...`;
        }
        return title;
    };
    return (
        <>
            {(((role === "student" || role === "auditor") && isDateInRange(content.visibleFrom, content.visibleTo, content.isVisible)) || role === "teacher") ? (
                <ResourceTitle key={content.contentIndex}
                    style={{ color: content && content.contentId && selectedResourceID ? selectedResourceID.contentId === content.contentId ? getResourceIcon(content.type || "").color : themeTextColor : "", }}
                    color={getResourceIcon(content.type || "").color}
                >
                    <div style={{ width: "1.2rem" }} >{getResourceIcon(content.type || "").icon}</div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", }}>
                        <div
                            style={{ display: "flex", columnGap: "0.56rem", alignItems: "center", cursor: "pointer", height: "100%", }}
                            onClick={() => {
                                localStorage.setItem(`selectedResource-${classID.classId}`, JSON.stringify({ contentId: content.contentId, moduleIndex, topicIndex, isVisible: content.isVisible }));
                                setSelectedResourceId({ contentId: content.contentId, moduleIndex, topicIndex, isVisible: content.isVisible })
                                onSelectResource({ content, moduleTitle, topicTitle })
                                window.scrollTo({ top: 0, behavior: "smooth" });
                                // setSelectedContent(content)
                            }}
                        >
                            <Typography text={`${t(`notification.${content.type}`)} : ${shortenTitle(content.contentTitle)}`} fontSize="0.625rem" />

                        </div>
                        {isAdmin === false && role === "student" && (
                            <ProgressBar progress={Math.floor(content.percentage)} />
                        )}
                        {role === "teacher" && (
                            <VisibilityComponent isVisible={content.visibility && content.visibility} contentId={content.contentId} setVisible={setContentVisibility} />
                        )}
                    </div>
                </ResourceTitle>
            ) :
                <></>
            }
        </>
    );
};
