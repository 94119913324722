import axios from "axios";
import { useEffect, useState } from "react";

export const useParentClass = (classId: string | undefined) => {
  const [isParent, setIsParent] = useState<boolean>(false);
  const [children, setChildren] = useState<any[]>([]);
  const tenantName = localStorage.getItem("tenant")

  useEffect(() => {
    const checkIfParent = async () => {
      try {
        const response = await axios.get(
          `https://bo39qdpjrc.execute-api.eu-west-1.amazonaws.com/dev/class/merge/type/${classId}/${tenantName}`
        );
        // console.log("checkIfParent data", response.data.data[0]);
        setIsParent(response.data.data[0].isParent);
        setChildren(response.data.data[0].children);
      } catch (err) {
        console.log(err);
      }
    };
    checkIfParent();
  }, [classId, tenantName]);

  return { isParent, children, };
};
