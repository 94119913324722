import styled from "styled-components";

export const CreatePostContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap:1.25rem;

`;

export const CreatePostLine= styled.div`
width: 100%;
display: flex;
justify-content:flex-end;
gap:0.85rem;
margin-bottom:-30px;
margin-top: -20px;
`;