import React from 'react'

const SortCourses = (selectedFilter: any, filteredCourses: any, filteredAndFilteredCourses: any) => {

    let sortedFilteredCourses = [...filteredAndFilteredCourses];

    if (selectedFilter === "Latest Due Date") {
        // Sort by DueDate in ascending order (earliest to latest)
        sortedFilteredCourses = filteredCourses.sort((a: any, b: any) => {
          if (a.DueDate && b.DueDate) {
            return new Date(b.DueDate).getTime() - new Date(a.DueDate).getTime();
          }
          return 0;
        });
      } else if (selectedFilter === "Oldest Due Date") {
        // Sort by DueDate in ascending order (oldest to latest)
        sortedFilteredCourses = filteredCourses.sort((a: any, b: any) => {
          if (a.DueDate && b.DueDate) {
            return new Date(a.DueDate).getTime() - new Date(b.DueDate).getTime();
          }
          return 0;
        });
      } else if (selectedFilter === "Recently Accessed") {
        // Sort by Accessed in descending order (from biggest to smallest)
        sortedFilteredCourses = filteredCourses.sort((a: any, b: any) => {
          return b.accessed - a.accessed;
        });
      } else if (selectedFilter === "Latest Enrollment") {
        // Sort by DueDate in ascending order (oldest to latest)
        sortedFilteredCourses = filteredCourses.sort((a: any, b: any) => {
          if (a.EnrollmentDate && b.EnrollmentDate) {
            return (
              new Date(b.EnrollmentDate).getTime() -
              new Date(a.EnrollmentDate).getTime()
            );
          }
          return 0;
        });
      } else if (selectedFilter === "Oldest Enrollment") {
        // Sort by DueDate in ascending order (oldest to latest)
        sortedFilteredCourses = filteredCourses.sort((a: any, b: any) => {
          if (a.EnrollmentDate && b.EnrollmentDate) {
            return (
              new Date(a.EnrollmentDate).getTime() -
              new Date(b.EnrollmentDate).getTime()
            );
          }
          return 0;
        });
      } else if (selectedFilter === "Latest Creation Date") {
        // Sort by DueDate in ascending order (oldest to latest)
        sortedFilteredCourses = filteredCourses.sort((a: any, b: any) => {
          if (a.createdAt && b.createdAt) {
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          }
          return 0;
        });
      } else if (selectedFilter === "Oldest Creation Date") {
        // Sort by DueDate in ascending order (oldest to latest)
        sortedFilteredCourses = filteredCourses.sort((a: any, b: any) => {
          if (a.createdAt && b.createdAt) {
            return (
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            );
          }
          return 0;
        });
      }

      return sortedFilteredCourses
}

export default SortCourses