import React, { useEffect, useState } from "react";
import {
  RubricPageeLayout,
  RubricTableLayout,
  RubricTableLayoutColumn,
  RubricTileLayout,
  RubricTableLayoutRow,
  RubricButtonsLayout,
} from "../RubricTableV2/RubricTable.style";
import {
  LightTypography,
  MediumTypography,
  PrimaryLightNumber,
  Typography,
} from "../../elements/fonts/Fonts";
import BlueButton from "../../elements/blueButton/BlueButton";

import { RubricTableViewData } from "./RubricTableViewData";
import {
  RuricCratieriaMine,
  RuricCratieriaText,
  ShapeDivB,
  OneRowHeaderDiv,
  OneRowHeaderDeleteDiv,
  ShapeDivBTextFormat,
  RubricTileRowEditLayout,
  OneRowViewShapeDiv,
  ShapeDivBTextFormatColumn,
} from "./RubicTableVeiw.style";
import { SvgDeleteIcon, SvgEditIcon } from "../../elements/Icons";
import { Link, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ColorSelector, TableColorSelector } from "../../recoil/ThemeSelectors";
import axios from "axios";
import { useTranslation } from "react-i18next";
import TitleHeaderTextIconNavigator from "../../elements/TitleHeaderTextIconNavigator/TitleHeaderTextIconNavigator";

export const RubricTableView = (): JSX.Element => {
  const { t } = useTranslation()

  const generalID = useParams();
  const id = generalID.id
  const classId = generalID.classId
  const tenantName = localStorage.getItem("tenant");
  const [rubricData, setRubricData] = useState<any>(null);
  const { Table_BgColor, Table_BoxShadow, Table_nth_child, Table_Head_TR } =
    useRecoilValue(TableColorSelector);
  const { SecondaryWhiteTextColor, bghelp, theme } =
    useRecoilValue(ColorSelector);
  // Effect to fetch rubricData from localStorage based on id

  const GetRubricById = async () => {
    try {
      const response = await axios.get(`https://0j157bzxcf.execute-api.eu-west-1.amazonaws.com/dev/get/${id}/${tenantName}`)
      setRubricData(response.data.data)
    } catch (error) {
      console.log("Error", error)
    }
  }
  useEffect(() => {
    // const rubricsFromLocalStorage = JSON.parse(
    //   localStorage.getItem("rubrics") || "[]"
    // );
    // const rubric = rubricsFromLocalStorage.find((item: any) => item.id === id);
    // console.log("data to get is:", rubric);
    GetRubricById()
    // setRubricData(rubric);
  }, [id]);

  // Check if rubricData is null before accessing its properties
  if (!rubricData) {
    return <div>{`${t('general.loading')}...`}</div>;
  }

  const criteriaLength = rubricData.criteria.length;
  const levelLength = rubricData.levels.length;
  // // Get the lengths of the 'Criteria' and 'level' arrays
  // const criteriaLength = (RubricTableViewData[0]?.Criteria || []).length;
  // const levelLength = (RubricTableViewData[0]?.level || []).length;
  // Construct the absolute path
  const absolutePath = `/courseView/Gradebook/${classId}/RubricUpdate/${id}`;

  return (
    <>
      <RubricPageeLayout>
        <TitleHeaderTextIconNavigator title={rubricData.title} />
        <RubricTableLayout bgcolor={Table_BgColor} boxshadow={Table_BoxShadow}>
          <RubricTileRowEditLayout>
            <RubricTileLayout>
              {/* <MediumTypography text={rubricData.title} /> */}
              <LightTypography
                text={rubricData.description}
                color={SecondaryWhiteTextColor}
              />
            </RubricTileLayout>
            <OneRowHeaderDeleteDiv style={{ marginTop: "1.3rem" }}>
              <Link to={absolutePath}>
                <SvgEditIcon />
              </Link>
            </OneRowHeaderDeleteDiv>
          </RubricTileRowEditLayout>

          {Array.from({ length: rubricData.criteria.length + 1 }, (_, i) => (
            <RubricTableLayoutRow
              first_child_bgcolor={Table_Head_TR}
              nth_child_bgcolor={Table_nth_child}
              key={i}
            >
              {Array.from({ length: rubricData.levels.length + 1 }, (_, d) => (
                <RubricTableLayoutColumn key={d}>
                  {i === 0 && d === 0 ? (
                    <OneRowViewShapeDiv>
                      <Typography text="Criteria / Level" />
                    </OneRowViewShapeDiv>
                  ) : i === 0 && d !== 0 ? (
                    <OneRowHeaderDiv>
                      <Typography text={rubricData.levels[d - 1]?.value} />
                      {/* <OneRowHeaderDeleteDiv>
                        <SvgDeleteIcon />
                      </OneRowHeaderDeleteDiv> */}
                    </OneRowHeaderDiv>
                  ) : i !== 0 && d === 0 ? (
                    <RuricCratieriaText>
                      <Typography
                        text={rubricData.criteria[i - 1]?.criteriaName}
                      />
                      <RuricCratieriaMine>
                        <PrimaryLightNumber
                          number={rubricData.criteria[i - 1]?.criteriaWeight}
                          fontSize="0.75rem"
                          color={SecondaryWhiteTextColor}
                        />
                        <LightTypography
                          text={rubricData.criteria[i - 1]?.criteriaDescription}
                          fontSize="0.75rem"
                          lineHeight="1.25rem"
                          color={SecondaryWhiteTextColor}
                        />
                      </RuricCratieriaMine>
                    </RuricCratieriaText>
                  ) : (
                    <ShapeDivB
                      style={{
                        justifyContent: "center",
                        // backgroundColor: "aqua",
                      }}
                    >
                      {/* <>{console.log("i is  before", i, "d is", d)}</> */}
                      <ShapeDivBTextFormat>
                        {/* <>{console.log("i is ", i, "d is", d)}</> */}
                        <ShapeDivBTextFormatColumn>
                          <Typography
                            fontSize="0.75rem"
                            text={
                              rubricData.boxes.find(
                                (box: any) =>
                                  i === box.x_axis && d === box.y_axis
                              )?.boxesWeight
                            }
                          />
                          <LightTypography
                            fontSize="0.75rem"
                            lineHeight="1.25rem"
                            text={
                              rubricData.boxes.find(
                                (box: any) =>
                                  i === box.x_axis && d === box.y_axis
                              )?.boxesDescription || ""
                            }
                          />
                        </ShapeDivBTextFormatColumn>
                        {/* <OneRowHeaderDeleteDiv>
                            <SvgDeleteIcon />
                          </OneRowHeaderDeleteDiv> */}
                      </ShapeDivBTextFormat>
                    </ShapeDivB>
                  )}
                </RubricTableLayoutColumn>
              ))}
            </RubricTableLayoutRow>
          ))}
        </RubricTableLayout>

        {/* <RubricButtonsLayout>
          <BlueButton text="Save" Width="11rem" BackColor="#5DD3B3" />
          <BlueButton
            text="Cancel"
            Width="11rem"
            BackColor="none"
            Color="#D85D66"
            Border="1px solid #D85D66"
          />
        </RubricButtonsLayout> */}
      </RubricPageeLayout>
    </>
  );
};
