import React, { FC, useState } from "react";
import {
  ChoiceContainer,
  CheckBox,
  ChoiceMainContainer,
  CustomCheckboxWrapper,
  CustomCheckboxInput,
  CustomCheckbox,
} from "./PreviewMultipleChoices.style";
import { TypographyRegularDescription } from "../../../elements/fonts/Fonts";
import { PreviewMultipleChoicesProps } from "./PreviewMultipleChoicesInterface";
import { ReactComponent as CheckIcon } from "../../../assets/icons/Group2199.svg";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
const PreviewMultipleChoices: FC<PreviewMultipleChoicesProps> = ({
  choice,
  countChecked,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const { RadioButtonGradientColor,checkboxBgColor,checkboxBorderColor,checkboxBorderRadius } = useRecoilValue(ColorSelector);
  return (
    <ChoiceMainContainer>
      <ChoiceContainer>
        {countChecked <= 1 ? (
          <CheckBox
            bgcolor={RadioButtonGradientColor}
            type="radio"
            name={`isChecked`}
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
        ) : countChecked > 1 ? (
          <CustomCheckboxWrapper>
            <CustomCheckboxInput type="checkbox" />
            {isChecked ? (
              <CheckIcon id="isChecked" onClick={handleCheckboxChange} />
            ) : (
              <CustomCheckbox
              bgcolor={checkboxBgColor}
              bordercolor={checkboxBorderColor}
              borderradius={checkboxBorderRadius}
                id="isChecked"
                onClick={handleCheckboxChange}
              />
            )}
          </CustomCheckboxWrapper>
        ) : (
          ""
        )}

        <TypographyRegularDescription
          text={`${choice?.name}`}
          fontSize="16px"
          lineHeight="22px"
          fontWeight="400"
        />
      </ChoiceContainer>
    </ChoiceMainContainer>
  );
};
export default PreviewMultipleChoices;
