import styled, { css } from "styled-components";

interface PaginationWrapperProps {
  justifycontent?: string;
  borderraduis?: string;
  margintop?: string;
  padding?: string;
  boxsize?: string;
  wholewidth?: string;
  color?: string;
  bordercolor?: string;
}

export const PaginatioWrapper = styled.ul<PaginationWrapperProps>`
  display: flex;
  background-color: transparent;
  column-gap: 0.88rem;
  justify-content: ${({ justifycontent }) => justifycontent};
  align-items: center;
  margin-top: ${({ margintop }) => margintop || "0"};
  padding: ${({ padding }) => padding || "0"};
  /* background-color:red; */
  width: ${({ wholewidth }) => wholewidth || "100%"};
`;

export const ArrowStyle = styled.div`
  &:dir(ltr) {
    transform: rotate(90deg);
  }
  &:dir(rtl) {
    transform: rotate(270deg);
  }
  width: 0.6rem;
  height: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PageItem = styled.li<
  { isactive?: string } & PaginationWrapperProps
>`
  display: inline;
  border-radius: ${({ borderraduis }) => borderraduis || "0.3125rem"};
  color: ${(props) => props.color};
  /* bor */
  border: 1px solid ${(props) => props.bordercolor};
  &:not(:first-child, :last-child):hover {
    display: inline;
    background-color: #5dd3b3;
    transition: background-color 0.2s ease-in;
    border-radius: ${({ borderraduis }) => borderraduis || "0.3125rem"};
    outline-style: solid;
    outline-color: #576371;
    outline-width: 1px;
    outline-offset: -1px;
    color: #fff;
  }

  /* Conditionally apply styles for active and focus states */
  ${({ isactive }) =>
    isactive === "true" &&
    css`
      cursor: default;
      background-color: #5dd3b3;
      transition: all 0.2s ease-in;
      color: #fff;
      /* border: 2px solid #fff !important; */
    `}
`;

export const PageLink = styled.span<PaginationWrapperProps>`
  /* padding: 0.69rem 0.87rem 0.62rem 0.88rem; */
  border-radius: ${({ borderraduis }) => borderraduis || "0.3125rem"};

  background-color: transparent;
  white-space: nowrap;
  max-height: min-content;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: #fff; */
  text-decoration: none;
  width: ${({ boxsize }) => boxsize || " 2.375rem !important"};
  height: ${({ boxsize }) => boxsize || " 2.375rem !important"};
  /* background-color:transparent; */
`;

export const ScrollTopAnimation = styled.div`
  transition: scrollBehavior 0.5s ease-in-out;
`;
