import React, { FC, useState } from "react";
import useClickOutside from "../../../hooks/useClickOutside";
import {
  QuesionContainer,
  QuestionMenuContainer,
  QuestionComposedQuestion,
  DropdownMenu,
  MenuContainer,
  MenuElementContainer,
  MenuItem,
  PartContainer,
} from "./QuestionHeader.style";
import { theme } from "../../../theme";
import { ReactComponent as QuestionIcon } from "../../../assets/icons/Drag and Drop.svg";
// import { ReactComponent as DotsIcon } from "../../../assets/icons/menu_3 (1).svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/Arrow.svg";
import { ReactComponent as UpIcon } from "../../../assets/icons/Arrow-up-1.svg";
// import { ReactComponent as AddPartIcon } from "../../../assets/icons/Add (1) copy.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit 1.svg";
import { ReactComponent as ViewIcon } from "../../../assets/icons/Eye1.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete 1.svg";
import { MediumTypography } from "../../../elements/fonts/Fonts";
import { QuestionHeaderProps } from "./QuestionHeaderInterface";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { SVGMenue, SvgAddModuleIcon } from "../../../elements/Icons";
import { toArabicDigits } from "../../../utils/formatDayArabic";
const QuestionHeader: FC<QuestionHeaderProps> = ({
  setIndex,
  setShowParts,
  question,
  setDeleteQuestion,
  addMorePartQuestions,
  index,
  showParts,
  handleShowQuest,
  disableClick,
  handleShowEditQuest,
  handleDynamicQuestionIndexFromChild,
  notValidate,
  setQuestionError
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const menuRef = useClickOutside(menuOpen, () => setMenuOpen(false)); // Use the custom hook to handle clicks outside the menu
  const { t } = useTranslation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const { bg_selectColor, SecondaryTextColor } = useRecoilValue(ColorSelector);
  return (
    <QuestionComposedQuestion>
      <QuestionMenuContainer style={{ color: SecondaryTextColor }}>
        <QuesionContainer
          onClick={() => {
            if(!notValidate){
              setIndex(index);
              handleShowQuest();
            }else{
              setQuestionError && setQuestionError("Save The Question")
            }
          }}
        >
          <QuestionIcon />
          <MediumTypography
            text={`${t("quiz.Question")} ${toArabicDigits((index + 1).toString())}`}
            fontSize="0.875rem"
            lineHeight="1.375rem"
            color={SecondaryTextColor}
          />
        </QuesionContainer>

        <PartContainer>
          <div
            style={{ position: "relative" }}
            ref={menuRef}
            onClick={toggleMenu}
          >
            {!disableClick && (
              <SVGMenue
                style={{
                  cursor: "pointer",
                  transform: "rotate(90deg)",
                }}
              />
            )}

            <MenuContainer>
              <DropdownMenu
                bgColor={bg_selectColor}
                isopen={menuOpen.toString()}
              >
                <MenuElementContainer
                  onClick={() => {
                    setIndex(index);
                    handleShowEditQuest();
                  }}
                >
                  <EditIcon />
                  <MenuItem>{t("dashboard.Edit")}</MenuItem>
                </MenuElementContainer>
                <MenuElementContainer
                  onClick={() => {
                    setIndex(index);
                    setDeleteQuestion(true);
                  }}
                >
                  <DeleteIcon />
                  <MenuItem>{t("User Profile.Remove")}</MenuItem>
                </MenuElementContainer>
              </DropdownMenu>
            </MenuContainer>
          </div>
        </PartContainer>
          {question.questType === "composed" ? (
            <SvgAddModuleIcon
              width={14}
              height={14}
              onClick={() => {
                addMorePartQuestions();
                setShowParts(true);
              }}
              style={{ cursor: "pointer" }}
            />
          ) : (
            ""
          )}
      </QuestionMenuContainer>
      {question.questType === "composed" && showParts ? (
        <UpIcon
          style={{ cursor: "pointer" }}
          onClick={() => setShowParts(false)}
        />
      ) : question.questType === "composed" && !showParts ? (
        <ArrowIcon
          style={{ cursor: "pointer" }}
          onClick={() => setShowParts(true)}
        />
      ) : (
        ""
      )}
    </QuestionComposedQuestion>
  );
};

export default QuestionHeader;
