import React, { useState, FC, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
  generateUUID,
} from "../../utils/StringManipulation";
import axios from "axios";
import {
  LightTypography,
  PrimaryFontBoldText,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  FlexDiv,
  TextEditorDiv,
  TextEditorNumber,
  RadioDiv,
  RadioDivLabel,
  GradeInput,
  DateAndTimeDiv,
  LabelDiv,
  FlexCenter,
  CheckBox,
  CheckBoxDiv,
  RestrictionsDiv,
  BtnsDiv,
  LabelBtnsDiv,
  DeletePopupMainWrapper,
  CancelButtonStyle,
  DeleteButton,
  DeletePopupButtons,
  FlexBetween,
} from "./AssignmentForm.style";
import { Container } from "../../elements/StyledContainerForm/StyledContainerForm.style";
import StyledInput from "../../elements/StyledInput/StyledInput";
import { useStateContext } from "../../contexts/ContextProvider";
import { uuidSplitter } from "../../utils/StringManipulation";
import ButtonElement from "../../elements/button/ButtonElement";
import CheckBoxInput from "../../elements/checkbox/CheckBox";
import SelectInput from "../../elements/nestedSelect/SelectInput";
import SelectInputClassic from "../../elements/SelectInput/SelectInput";
import {
  Options,
  RulesOptions,
  SelectGroupingOptions,
} from "./AssignmentForm.data";
import LoaderThreeDot from "../loader/loaderThreeDot/LoaderThreeDot";
import SelectDate from "../../elements/DatePicker/inputDate";
import Toggle from "../../elements/Toogle/toogle";
import TwoDatePicker from "../../elements/TwoDatePicker/TwoDatePicker";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import StyledSelect from "../../elements/StyledSelect/StyledSelect";
import { S3Upload } from "../../services/S3Put";
import {
  SvgClose,
  SvgAssignmentIcon,
  SvgAddIcon,
  SvgImportIcon,
  SvgEyeIcon,
} from "../../elements/Icons";
import { ContentFormProps } from "../../interfaces/ContentFormProps";
import isPositiveNumber from "../../utils/IsPositiveNumber";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import CheckboxSquare from "../../elements/checkboxSquare/CheckboxSquare";
import { useTranslation } from "react-i18next";
import { WebSocketConnector } from "../../Views/messages/webSocketConnection";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import NestedSelect from "../../elements/nestedSelect/NestedSelect";
import { AssignmentContext } from "../../contexts/CourseCreatorAssignmentContext";
import { WebsocketContext } from "../../contexts/notificationContext";
import { useQuizMyContext } from "../../contexts/CourseCreatorQuizContext";

const AssignmentForm: FC<ContentFormProps> = ({
  setShow,
  handleSubmit,
  setShowEditContent,
  setContentTitleInTopic,
  editContent,
  newContentTitle,
  contentId,
  activeGetModulesWithTopics,
  newTopicRefId,
  showEditContent,
}) => {
  const { backgroundColor, themeTextColor, inputBorderColor, iconCloseColor } =
    useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  const classSourceId = useParams();
  const setClassSourceId = classSourceId.id;
  const { isLoading, user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const filePath = `class/${setClassSourceId}/activities/assignments`;
  const baseURL = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";
  const path = "activity/create";
  const tenantName = localStorage.getItem("tenant");
  const {
    setContent_Title,
    contentType,
    setContent_Data,
    getContent_Data,
    contentIdToEdit,
    topicRefId,
    content_Index,
    setIsPopUpOpen,
    globalShowEditContent,
    setGlobalShowEditContent,
  } = useStateContext();
  const dateString = "2023-11-01T22:00:00.000Z"; // Your date string
  const dateObject = new Date(dateString);
  const formattedDate = dateObject.toString();
  const navigate = useNavigate();

  const [prevData, setPrevData] = useState<any>();
  const [readyToEdit, setReadyToEdit] = useState(true);
  const [foundRefIdState, setFoundRefIdState] = useState<number>();

  const [rubricOptions, setRubricOptions] = useState<
    { title: string; submenu?: any }[]
  >([]);
  const [error, setError] = useState(false);
  const [firstError, setFirstError] = useState(true);
  const [fileUUId, setFileUUId] = useState("");
  const [readyToPost, setReadyToPost] = useState(true);
  const [isSetLoading, setIsSetLoading] = useState(false);
  const [rubricError, setRubricError] = useState(false);
  const [refIdLoading, setRefIdLoading] = useState(false);
  const { isReady, value, send } = useContext(WebsocketContext);
  const {
    setSelectedRadio,
    selectedGrouping,
    selectedRadio,
    selectedRule,
    selectedTitle,
    answerBoxChecked,
    assignmentName,
    description,
    dueDate,
    cutOffDate,
    showPlagiarismPrevention,
    showSelectGrouping,
    showTimeVisibility,
    startDate,
    fileSubmissionChecked,
    fromDate,
    file,
    toDate,
    maxGrade,
    penalty,
    urlAttachment,
    visibleStudents,
    prevDataAttachments,
    isGraded,
    setPrevDataAttachments,
    setSelectedTitle,
    setGlobalAssignmentShowPopup,
    setSelectedRule,
    setShowTimeVisibility,
    setShowSelectGrouping,
    setShowPlagiarismPrevention,
    setSelectedGrouping,
    setAssignmentName,
    setDescription,
    setMaxGrade,
    setStartDate,
    setDueDate,
    setCutOffDate,
    setPenalty,
    setVisibleStudents,
    setFromDate,
    setToDate,
    setAnswerBoxChecked,
    setFileSubmissionChecked,
    setFile,
    setUrlAttachment,
    setIsGraded,
  } = AssignmentContext();

  const {
    selectedRubricId,
    setSelectedRubricId,
  } = useQuizMyContext();

  // Function to update form
  const updateAssignment = async (path: string, body: any) => {
    try {
      const response = await axios.put(`${baseURL}/${path}/${tenantName}`, {
        data: body,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
      throw error; // Rethrow the error to be handled by the calling service
    }
  };

  // Function to get data
  const getAssignmentToEdit = async (path: string, assignmentId: number) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `${baseURL}/${path}/${tenantName}/${assignmentId}/${subId}?provider=${provider}`
      );
      setPrevData(res.data.data.activity);
      setUrlAttachment(res.data.data.attachments[0].attachmentName);
      const fileNameResolved = uuidSplitter(
        res.data.data.attachments[0].attachmentName?.split("assignments/")[1]
      );
      setPrevDataAttachments(fileNameResolved);
      setReadyToEdit(false);
      const toReturn: any[] = res.data.data;
      return toReturn;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (globalShowEditContent && assignmentName !== "") {
      setReadyToEdit(false);
    }
  }, [assignmentName]);

  // This useEffect hook is used to fetch data when showEditContent is true.
  useEffect(() => {
    const fetchData = async () => {
      setRefIdLoading(true);
      const yourArray = await activeGetModulesWithTopics();
      const solutionArray: any[] = Array.isArray(yourArray) ? yourArray : [];
      function findRefIdInModules(
        modulesArray: any[],
        targetContentId: number
      ) {
        for (let i = 0; i < modulesArray.length; i++) {
          const topicsArray = modulesArray[i].topicsArray;

          for (let j = 0; j < topicsArray.length; j++) {
            const contentsArray = topicsArray[j].contents;

            for (let k = 0; k < contentsArray.length; k++) {
              if (contentsArray[k].contentId === targetContentId) {
                return contentsArray[k].refId;
              }
            }
          }
        }
        // Return a default value (e.g., null) if the contentId is not found
        return null;
      }
      if (contentIdToEdit) {
        const contentIdToFind = contentIdToEdit; // Replace with the specific contentId you're looking for
        const foundRefId = findRefIdInModules(solutionArray, contentIdToFind);
        setFoundRefIdState(foundRefId);
        await getAssignmentToEdit("assignment/get", foundRefId);
        setRefIdLoading(false);
      }
    };
    if (globalShowEditContent) {
      fetchData();
      // setReadyToEdit(false);
    }
  }, []);

  // This useEffect hook is used to update the states with edit data (prevData)
  useEffect(() => {
    if (prevData && showEditContent) {
      setContent_Title(prevData.title);
      setAssignmentName(prevData.title);
      setDescription(prevData.instructions);
      setMaxGrade(prevData.maxGrade);
      setPenalty(prevData.penalty);
      setVisibleStudents(prevData.isVisible);
      setAnswerBoxChecked(prevData.answerBox);
      setFileSubmissionChecked(prevData.fileSubmission);
      setShowTimeVisibility(
        prevData.visibleFrom === null || prevData.visibleTo === null
          ? false
          : true
      );
      setStartDate(
        prevData.startDateTime ? new Date(prevData.startDateTime) : null
      );
      setDueDate(prevData.dueDateTime ? new Date(prevData.dueDateTime) : null);
      setCutOffDate(
        prevData.cutOffDateTime ? new Date(prevData.cutOffDateTime) : null
      );
      setFromDate(prevData.visibleFrom ? new Date(prevData.visibleFrom) : null);
      setToDate(prevData.visibleTo ? new Date(prevData.visibleTo) : null);
      setSelectedRubricId(prevData.rubricId);
      const foundRubric = rubricOptions.find(
        (rubric) =>
          rubric.submenu &&
          rubric.submenu.find((item: any) => item.id === prevData.rubricId)
      );

      if (foundRubric) {
        const selectedRubric = foundRubric.submenu.find(
          (item: any) => item.id === prevData.rubricId
        );
        setSelectedTitle(selectedRubric.title);
      }
      setSelectedRadio(
        prevData.rubricId && prevData.rubricId !== null ? "rubric" : "grade"
      );
      setIsGraded(
        (prevData.rubricId && prevData.rubricId !== null) || prevData.maxGrade
          ? true
          : false
      );
      // if (file && file != undefined) {
      //   setFile(file);
      // }
    }
    if (prevData && globalShowEditContent && prevDataAttachments) {
      setFile(prevDataAttachments.attachmentUrl);
      // setReadyToEdit(false);
    }
  }, [prevData, prevDataAttachments]);

  useEffect(()=>{
    if(selectedRubricId){
      const foundRubric = rubricOptions.find(
        (rubric) =>
          rubric.submenu &&
          rubric.submenu.find((item: any) => item.id === selectedRubricId)
      );

      if (foundRubric) {
        const selectedRubric = foundRubric.submenu.find(
          (item: any) => item.id === selectedRubricId
        );
        setSelectedTitle(selectedRubric.title);
      }
    }
  },[rubricOptions])

  const handleToggleChangeTime = (isChecked: boolean) => {
    if (isChecked) {
      setShowTimeVisibility(true); // Show Time Visibility when the toggle is on
    } else {
      setShowTimeVisibility(false);
    }
  };

  const handleSubmitByGroups = (isChecked: boolean) => {
    if (isChecked) {
      setShowSelectGrouping(true);
    } else {
      setShowSelectGrouping(false);
    }
  };

  const handleVisibleStudents = (isChecked: boolean) => {
    setVisibleStudents(isChecked);
  };

  const handleAnonymous = (isChecked: boolean) => {
    // Handle the description change here.
  };

  const handlePlagiarism = () => {
    setShowPlagiarismPrevention(!showPlagiarismPrevention);
  };

  const handleNotifications = (isChecked: boolean) => {
    // Handle the description change here.
  };

  const handleToggleChangeRubric = (value: string) => {
    setSelectedRadio(value); // Update the selected radio
    setMaxGrade("");
    setSelectedRubricId(0);
    setSelectedTitle("");
  };

  const handleSelectChange = (value: any) => {
    setSelectedTitle(value); // Update the selectedTitle state
  };
  const handleSelectrubricId = (value: any) => {
    setSelectedRubricId(value); // Update the selectedTitle state
  };

  // Fetch quiz templates from the server and update the options for SelectInput component.
  useEffect(() => {
    const getQuizTemplate = async () => {
      try {
        const response = await axios.get(
          `https://0j157bzxcf.execute-api.eu-west-1.amazonaws.com/dev/get/all/${setClassSourceId}/${tenantName}`
        );
        const quizDataArray = response.data.data.rubricsData || [];

        // Transform the quiz data array into the format expected by SelectInput
        const transformedOptions: {
          title: any;
          value: string;
          submenu?: any;
        }[] = [
          {
            title: `${t("forum.Use Existing Rubric")}`,
            value: "Use Existing Rubric",
            submenu: quizDataArray.map((quiz: any) => ({
              title: quiz.title,
              id: quiz.id,
            })),
          },
          {
            title: `${t("forum.create a new one")}`,
            value: "Create a new one",
          },
        ];
        setRubricOptions(transformedOptions);
      } catch (error) {
        console.error("Axios Error:", error);
      }
    };

    getQuizTemplate();
  }, [setClassSourceId, tenantName]);

  const handleToggleChangeGraded = () => {
    setIsGraded(!isGraded);
    setDueDate(null);
    setPenalty("");
    setMaxGrade("");
    setSelectedTitle("");
    setSelectedRubricId(0);
  };

  const handleRulesChange = (value: any) => {
    setSelectedRule(value); // Update the selectedTitle state
  };

  const handleChangeSubmitGrouping = (value: any) => {
    setSelectedGrouping(value); // Update the selectedTitle state
  };

  // Function to handle description
  const handleDescriptionChange = (value: any) => {
    setDescription(value);
  };

  // Function to handle TwoDatePicker Change
  const handleTwoDatePickerChange = (start: Date | null, end: Date | null) => {
    setFromDate(start);
    setToDate(end);
  };

  // Function to handle startDate and fromDate for TwoDatePicker
  const handleStartDate = (startDateTime: Date | null) => {
    setStartDate(startDateTime);
    setFromDate(startDateTime);
  };

  // Function to handle dueDate
  const handleDueDate = (dueDateTime: Date | null) => {
    setDueDate(dueDateTime);
  };

  // Function to handle CutOffDate and toDate for TwoDatePicker
  const handleCutOffDate = (cutOffDateTime: Date | null) => {
    setCutOffDate(cutOffDateTime);
    setToDate(cutOffDateTime);
  };

  // Function to handle changes in the "Answer Box" checkbox
  const handleAnswerBoxChange = () => {
    setAnswerBoxChecked(!answerBoxChecked);
  };

  // Function to handle changes in the "File Submission" checkbox
  const handleFileSubmissionChange = () => {
    setFileSubmissionChecked(!fileSubmissionChecked);
  };

  // Function to handle the selection of a file, upload it to S3, and set relevant state variables.
  const handleSelectFile = async (files: FileList | null) => {
    if (files) {
      setReadyToPost(false);
      setFile(files);
      setPrevDataAttachments(files[0].name);
      const fileNameUUid = `${generateUUID()}${files[0].name}`;
      const response = await S3Upload(
        filePath,
        files[0].name,
        fileNameUUid,
        files[0]
      );
      setFileUUId(fileNameUUid);
    }
  };

  // Function to handle input change, and validating input value.
  const handleInputChange = (
    value: string,
    setStateFunction: (value: string) => void
  ) => {
    if (isPositiveNumber(value) || value === "") {
      setStateFunction(value);
      setError(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (fileUUId !== "") {
      setReadyToPost(true);
    }
  }, [fileUUId]);

  useEffect(() => {
    if (
      assignmentName ||
      description ||
      maxGrade ||
      selectedTitle ||
      (showTimeVisibility && (!fromDate || !toDate)) ||
      startDate ||
      cutOffDate ||
      answerBoxChecked ||
      fileSubmissionChecked
    ) {
      setFirstError(false);
    }
  }, [
    assignmentName,
    description,
    maxGrade,
    fromDate,
    toDate,
    startDate,
    cutOffDate,
    answerBoxChecked,
    fileSubmissionChecked,
    dueDate,
  ]);
  const handleFormValidation = async () => {
    if (
      !assignmentName ||
      !description ||
      (isGraded && selectedRadio === "grade" && !maxGrade) ||
      (isGraded && selectedRadio === "rubric" && !selectedTitle) ||
      (showTimeVisibility &&
        (!fromDate || !toDate) &&
        !startDate &&
        !cutOffDate) ||
      (showSelectGrouping && !selectedGrouping) ||
      (!answerBoxChecked && !fileSubmissionChecked) ||
      (startDate && dueDate && startDate >= dueDate) ||
      (startDate && cutOffDate && startDate >= cutOffDate) ||
      (dueDate && cutOffDate && dueDate > cutOffDate) ||
      (!startDate && (dueDate || cutOffDate)) ||
      rubricError
    ) {
      if (!firstError) {
        setError(true);
      }
      // setGlobalAssignmentShowPopup(true);
      return false;
    } else {
      setError(false);
      if (contentId !== undefined && newContentTitle !== undefined)
        editContent(contentId, newContentTitle);
      // setShow(false);
      // if (!showEditContent) {
      //   handleSubmit();
      // }
      return true;
    }
  };

  useEffect(() => {
    // if (showEditContent && !readyToEdit)
    handleFormValidation();
  }, [
    assignmentName,
    description,
    maxGrade,
    fromDate,
    toDate,
    startDate,
    cutOffDate,
    answerBoxChecked,
    fileSubmissionChecked,
    dueDate,
  ]);

  const handleContinueClick = async () => {
    const isValid = await handleFormValidation();
    if (isValid) {
      if (!globalShowEditContent) {
        handleSubmit();
      }

      // Determine the file name to update based on whether a file is selected or using previous data attachments.
      let fileToUpdate =
        file && file[0].name ? file[0].name : prevDataAttachments;

      try {
        const assignmentData: any = {
          _id: contentIdToEdit,
          id: globalShowEditContent ? foundRefIdState : null,
          classSourceId: setClassSourceId,
          userSourceId: subId,
          provider: provider,
          contentIndex: content_Index,
          topicRefId: topicRefId,
          type: contentType,
          title: assignmentName,
          description: description,
          file: fileToUpdate,
          path:
            file && file[0].name ? `${filePath}/${fileUUId}` : urlAttachment,
          // file: file && typeof file[Symbol.iterator] === "function" ? Array.from(file).map((file) => file.name) : null,
          // path: file !== null ? `${filePath}/${fileUUId}` : "",
          // file: file !== null ? Array.from(file).map((file) => file.name) : null,
          // path: file !== null ? `${filePath}/${fileUUId}` : "",
          maxGrade: maxGrade,
          startDate: startDate,
          dueDate: dueDate,
          cutOffDate: cutOffDate,
          penalty: penalty,
          visibleStudents: visibleStudents,
          // selectedGrouping: selectedGrouping,
          showTimeVisibility: showTimeVisibility,
          fromDate: fromDate,
          toDate: toDate,
          checkPlagiarism: showPlagiarismPrevention,
          isGraded:isGraded,
          answerBoxChecked: answerBoxChecked,
          fileSubmissionChecked: fileSubmissionChecked,
          gradingType: selectedRadio,
          selectedRubric: selectedTitle,
          rubricId: selectedRubricId,
        };
        const formatDate = (dueDate: any) => {
          const date = new Date(dueDate);
          const day = date.getDate().toString().padStart(2, "0");
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        };
        if (!globalShowEditContent) {
          const response = await axios.post(
            `${baseURL}/${path}/${tenantName}`,
            { data: assignmentData }
          );
          if (send && isReady) {

            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "create",
                  title: assignmentName,
                  contentType: "Assignment",
                  refId: response.data.data,
                  refTable: "AssignmentActivities",
                  subId: subId,
                  provider: provider,
                  type: "content",
                  description: dueDate
                    ? `Due Date: ${formatDate(dueDate)}`
                    : "",
                },
              })
            );
          }
          setIsSetLoading(false);
        } else if (globalShowEditContent) {
          await updateAssignment("activity/assignment/update", assignmentData);
          if (send && isReady) {
            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "update",
                  title: assignmentName,
                  contentType: "Assignment",
                  subId: subId,
                  provider: provider,
                  refId: foundRefIdState,
                  refTable: "AssignmentActivities",
                  type: "content",
                  description: dueDate
                    ? `Due Date: ${formatDate(dueDate)}`
                    : "",
                },
              })
            );
          }
          // await getAssignmentToEdit("assignment/get", foundRefId);
        }
        setGlobalAssignmentShowPopup(false);
        setIsPopUpOpen(false);
        // if (!showEditContent) {
        await activeGetModulesWithTopics();
        // }
      } catch (error) {
        console.error("Axios Error:", error);
      } finally {
        await (activeGetModulesWithTopics && activeGetModulesWithTopics());
      }
    } else {
      setGlobalAssignmentShowPopup(true);
    }
  };

  const handleEditRubric = () => {
    if (!selectedTitle) {
      setRubricError(true);
    } else {
      setRubricError(false);
      navigate(
        `/courseView/Gradebook/${setClassSourceId}/RubricTableView/${selectedRubricId}`
      );
    }
  };

  return (
    <Container
      themetextcolor={themeTextColor}
      backgroundcolor={backgroundColor}
      scrollbar={inputBorderColor}
    >
      {/* Header */}
      {/* <FlexBetween> */}
        {/* <FlexCenter>
          <SvgAssignmentIcon />
          <PrimaryFontBoldText
            text={t("assignment.Assignment")}
            fontSize="1.125rem"
          />
        </FlexCenter> */}

        {/* <SvgClose
          color={iconCloseColor}
          onClick={() => {
            setShow(false);
            setShowEditContent(false);
            setIsPopUpOpen(false);
          }}
          style={{ cursor: "pointer" }}
        /> */}
      {/* </FlexBetween> */}
      {showEditContent && readyToEdit && <LoaderThreeDot />}

      {/* Title */}
      <LabelDiv>
        <FlexDiv gap="0.25rem">
          <LightTypography text={t("forms.Title")} fontSize="1rem" />
          <span>*</span>
        </FlexDiv>
        <StyledInput
          placeholder={t("assignment.Enter Assignment Title")}
          width="100%"
          onChange={(e: any) => {
            setAssignmentName(e.target.value);
            setContent_Title(e.target.value);
            setContentTitleInTopic(e.target.value);
          }}
          value={assignmentName}
        />
        {!assignmentName && error && (
          <ErrorDiv>{`${t("forms.Please")} ${t(
            "assignment.Enter Assignment Title"
          )}`}</ErrorDiv>
        )}
      </LabelDiv>

      {/* Description */}
      <LabelDiv>
        <FlexDiv gap="0.25rem">
          <LightTypography text={t("forms.Description")} fontSize="1rem" />
          <span>*</span>
        </FlexDiv>
        <TextEditorDiv>
          <TinyEditor
            onChange={handleDescriptionChange}
            placeholder={t("forms.Description")}
            initialValue={`${
              prevData && prevData.instructions
                ? prevData.instructions
                : description
                ? description
                : ""
            }`}
            height="13.25rem"
            limit={300}
          />
          <TextEditorNumber>
            <TypographyRegularDescription text={t("assignment.300 Words")} fontSize="0.75rem" />
          </TextEditorNumber>
          {!description && error && (
            <ErrorDiv style={{ marginTop: "0.62rem" }}>
              {`${t("forms.Please")} ${t("forms.Enter Description")}`}
            </ErrorDiv>
          )}
        </TextEditorDiv>
      </LabelDiv>

      {/* Attachments */}
      <FlexDiv>
        <LightTypography text={t("assignment.Attachments")} fontSize="1rem" />
        <StyledSelect
          onChange={handleSelectFile}
          title={`${
            prevDataAttachments
              ? prevDataAttachments
              : `${t("assignment.Upload file")}`
          }`}
          icon={SvgImportIcon}
          acceptedFileTypes=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .odt, .ods, .odp, .txt, .rtf, .csv"
        />
      </FlexDiv>

      <LabelBtnsDiv>
        <Toggle
          checked={isGraded}
          onToggle={handleToggleChangeGraded}
          id="roleToggle2"
        />
        <TypographyRegularDescription
          text={t("forms.Graded")}
          fontSize="1rem"
        />
      </LabelBtnsDiv>

      {isGraded && (
        <>
          {/* Grading type */}
          <PrimaryFontBoldText
            text={t("assignment.Grading type")}
            fontSize="1rem"
          />
          <FlexDiv>
            <RadioDivLabel onClick={() => handleToggleChangeRubric("grade")}>
              <CheckBoxInput
                name="gradeType"
                value="grade"
                checked={selectedRadio === "grade"}
                // onChange={() => handleToggleChangeRubric("grade")}
              />
              <LightTypography
                text={t("assignment.Grade")}
                fontSize="0.875rem"
              />
            </RadioDivLabel>
            <RadioDivLabel onClick={() => handleToggleChangeRubric("rubric")}>
              <CheckBoxInput
                name="gradeType"
                value="rubric"
                checked={selectedRadio === "rubric"}
                // onChange={() => handleToggleChangeRubric("rubric")}
              />
              <LightTypography
                text={t("assignment.Rubric")}
                fontSize="0.875rem"
              />
            </RadioDivLabel>
          </FlexDiv>

          <RadioDiv>
            {selectedRadio === "grade" && (
              <LabelDiv>
                <FlexDiv gap="0.25rem">
                  <LightTypography
                    text={t("assignment.Maximum grade")}
                    fontSize="1rem"
                  />
                  <span>*</span>
                </FlexDiv>
                <StyledInput
                  // themeTextColor={themeTextColor}
                  // inputBorderColor={inputBorderColor}
                  width="27.5rem"
                  type="number"
                  value={maxGrade}
                  onChange={(e) =>
                    handleInputChange(e.target.value, setMaxGrade)
                  }
                  placeholder={t("assignment.Maximum grade")}
                />
                {selectedRadio === "grade" && !maxGrade && error && (
                  <ErrorDiv>
                    {t("course view.Please Enter a valid Maximum Grade")}
                  </ErrorDiv>
                )}
              </LabelDiv>
            )}

            {selectedRadio === "rubric" && (
              <LabelDiv>
                <FlexDiv gap="0.25rem">
                  <LightTypography
                    text={t("Rubric.Rubric")}
                    fontSize="0.875rem"
                  />
                  <span>*</span>
                  <SvgEyeIcon
                    onClick={handleEditRubric}
                    style={{ color: "#5DD3B3", cursor: "pointer" }}
                  />
                </FlexDiv>
                <NestedSelect
                  onChange={handleSelectChange}
                  onIdChange={handleSelectrubricId}
                  placeholder="Use Rubric"
                  fontSize="0.875rem"
                  width="27.5rem"
                  paddingRL="1.25rem"
                  options={rubricOptions}
                  value={selectedTitle}
                  redirectUrl={`/courseView/Gradebook/${setClassSourceId}/Rubric`}
                  setGlobalAssignmentShowPopup={setGlobalAssignmentShowPopup}
                />
                {selectedRadio === "rubric" &&
                  !selectedTitle &&
                  (error || rubricError) && (
                    <ErrorDiv>{t("forms.Please select a rubric")}</ErrorDiv>
                  )}
              </LabelDiv>
            )}
          </RadioDiv>
        </>
      )}

      {/* Date and time part */}
      <DateAndTimeDiv>
        <LabelDiv>
          <LightTypography text={t("forms.Start Date")} fontSize="1rem" />
          <SelectDate
            width="27.5rem"
            value={startDate}
            onChange={handleStartDate}
          />
        </LabelDiv>
        <LabelDiv>
          <LightTypography text={t("forms.Cut-off")} fontSize="1rem" />
          <SelectDate
            width="27.5rem"
            value={cutOffDate}
            onChange={handleCutOffDate}
          />
        </LabelDiv>
      </DateAndTimeDiv>

      {isGraded && (
        <DateAndTimeDiv>
          <LabelDiv>
            <LightTypography text={t("forms.Due Date")} fontSize="1rem" />
            <SelectDate
              width="27.5rem"
              value={dueDate}
              onChange={handleDueDate}
            />
          </LabelDiv>
          {dueDate && (
            <LabelDiv>
              <LightTypography
                text={t("assignment.Penalty Percentage")}
                fontSize="1rem"
              />
              <StyledInput
                type="number"
                width="27.5rem"
                value={penalty}
                withPercentage
                rightPlaceholder="%"
                onChange={(e) => handleInputChange(e.target.value, setPenalty)}
              />
            </LabelDiv>
          )}
          {/* {dueDate && !penalty && error && (
              <ErrorDiv>
                Please Enter a valid Penalty percentage
              </ErrorDiv>
            )} */}
        </DateAndTimeDiv>
      )}

      {startDate && dueDate && startDate >= dueDate && error && (
        <ErrorDiv>
          {t("course view.Due date must be greater than the start date")}
        </ErrorDiv>
      )}
      {cutOffDate && dueDate && dueDate > cutOffDate && error && (
        <ErrorDiv>
          {t("course view.CutOff Date must be greater than the due date.")}
        </ErrorDiv>
      )}
      {startDate && cutOffDate && startDate >= cutOffDate && error && (
        <ErrorDiv>
          {t("course view.Cut Off Date must be greater than the start date.")}
        </ErrorDiv>
      )}
      {!startDate && (dueDate || cutOffDate) && error && (
        <ErrorDiv>{t("course view.Please Select Start Date.")}</ErrorDiv>
      )}

      {/* Toggles */}
      <BtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleVisibleStudents}
            checked={visibleStudents}
            id="roleToggle1"
          />
          <TypographyRegularDescription
            text={t("forms.Make Visible for Students")}
            fontSize="1rem"
          />
        </LabelBtnsDiv>

        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleChangeTime}
            checked={showTimeVisibility}
            id="roleToggle3"
          />
          <TypographyRegularDescription
            text={t("forms.Set Time Visibility Interval")}
            fontSize="1rem"
          />
          {showTimeVisibility && (
            <>
              <TwoDatePicker
                onDateChange={handleTwoDatePickerChange}
                prevStartDate={fromDate}
                prevEndDate={toDate}
              />
              {(!fromDate || !toDate) && !startDate && !cutOffDate && error && (
                <ErrorDiv>{`${t("forms.Please")} ${t(
                  "forms.Set Time Visibility Interval"
                )}`}</ErrorDiv>
              )}
            </>
          )}
        </LabelBtnsDiv>
        {/* <LabelBtnsDiv>
          <Toggle onToggle={handleSubmitByGroups} id="roleToggle2" />
          <TypographyRegularDescription text="Submit by groups" fontSize="1rem" />
          {showSelectGrouping && (
            <>
              <SelectInputClassic onChange={handleChangeSubmitGrouping} placeholder="Select Grouping Scheme" width="14.8125rem" optionWidth="14.8125rem" options={SelectGroupingOptions} value={selectedGrouping} />
              {!selectedGrouping && error && <ErrorDiv>Please Select Grouping Scheme</ErrorDiv>}
            </>
          )}
        </LabelBtnsDiv> */}
      </BtnsDiv>

      {/* Submission Type */}
      <FlexDiv gap="0.25rem">
        <PrimaryFontBoldText
          text={t("assignment.Submission Type")}
          fontSize="1.125rem"
        />
        <span>*</span>
      </FlexDiv>
      <CheckBoxDiv>
        <RadioDivLabel onClick={handleAnswerBoxChange}>
          <CheckboxSquare
            checked={answerBoxChecked}
            onChange={handleAnswerBoxChange}
          />
          <LightTypography text={t("assignment.Answer Box")} fontSize="1rem" />
        </RadioDivLabel>
        <RadioDivLabel onClick={handleFileSubmissionChange}>
          <CheckboxSquare
            checked={fileSubmissionChecked}
            onChange={handleFileSubmissionChange}
          />
          <LightTypography
            text={t("assignment.File Submission")}
            fontSize="1rem"
          />
        </RadioDivLabel>
        {!answerBoxChecked && !fileSubmissionChecked && error && (
          <ErrorDiv>{t("course view.Please Select Submission Type")}</ErrorDiv>
        )}
      </CheckBoxDiv>

      {/* <RestrictionsDiv>
        <FlexDiv>
        <CheckBox />
        <PrimaryFontBoldText text="Additional restrictions" fontSize="1.25rem" />
        <SelectInputClassic onChange={handleRulesChange} placeholder="Matching Rule" width="11rem" optionWidth="10rem" options={RulesOptions} value={selectedRule} />
        </FlexDiv>
        <FlexDiv>
            <SvgAddIcon/>
        <LightTypography text="Add Restriction" fontSize="0.875" color="#5DD3B3"/>
        </FlexDiv>
      </RestrictionsDiv> */}

      <BtnsDiv>
        {/* <LabelBtnsDiv>
            <Toggle onToggle={handleAnonymous} id="roleToggle4" />
            <TypographyRegularDescription text={t('assignment.Anonymous submissions')} fontSize="1rem" />
          </LabelBtnsDiv> */}
        {/* <LabelBtnsDiv>
          <Toggle checked={showPlagiarismPrevention} onToggle={handlePlagiarism} id="roleToggle5" />
          <TypographyRegularDescription text="Enable/disable plagiarism prevention" fontSize="1rem" />
        </LabelBtnsDiv> */}
        {/* <LabelBtnsDiv>
            <Toggle onToggle={handleNotifications} id="roleToggle6" />
            <TypographyRegularDescription text="Allow users to turn off notifications" fontSize="1rem" />
          </LabelBtnsDiv> */}
      </BtnsDiv>

      {/* Buttons */}
      <DeletePopupMainWrapper>
        <DeletePopupButtons>
          <CancelButtonStyle
            name="Cancel"
            onClick={() => {
              setGlobalAssignmentShowPopup(false);
              setIsPopUpOpen(false);
              setShowEditContent(false);
              setGlobalShowEditContent(false);
            }}
          >
            {t("forms.Cancel")}
          </CancelButtonStyle>
          {readyToPost && !refIdLoading ? (
            <DeleteButton
              onClick={async () => {
                // handleFormValidation();
                // setFirstError(false);

                if (!error) {
                  await setIsSetLoading(true);
                  setShowEditContent(false);
                  setGlobalShowEditContent(false);
                  setGlobalAssignmentShowPopup(false);
                  await handleContinueClick();
                  setContent_Title(assignmentName);
                }
              }}
              disabled={isSetLoading}
            >
              {t("forms.Save")}
            </DeleteButton>
          ) : (
            <DeleteButton
              onClick={() => {
                handleFormValidation();
                setContent_Title(assignmentName);
              }}
            >
              {" "}
              {`${t("general.loading")}...`}
            </DeleteButton>
          )}
        </DeletePopupButtons>
      </DeletePopupMainWrapper>
    </Container>
  );
};

export default AssignmentForm;
