export const Courses =  [
    {
      name: 'Marketing',
    },
    {
      name: 'Intro To Html',
    },
]
export const Groups =  [
    {
      name: 'Group 1',
    },
    {
      name: 'Group 2',
    },
    {
      name: 'Group 3',
    },
]