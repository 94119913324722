import styled from 'styled-components'

export const NavContainer=styled.div`
margin-top:0.69rem;
display:flex;
align-items:center;
gap:1.25rem;
`;

export const ItemsContainer=styled.div`
display:flex;
align-items:center;
gap:0.62rem;
cursor: pointer;
`;