import styled from 'styled-components'
import { StyleThemeInterface } from '../../../../interfaces/StyleThemeInterface';

export const ScaleContainer=styled.div<StyleThemeInterface>`
width: 11rem;
height: 0.5rem;
border-radius: 0.3125rem;
background: ${props => props.bgcolor};
`;
export const ScaleStructureNumber=styled.div`
border-radius: 0.3125rem;
/* background: #5DD3B3; */
/* box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25); */
`;

export const ScaleContentNumber=styled.div`
border-radius: 0.3125rem;
background: #0B8FFF;
/* box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25); */
`;

export const Header = styled.div<StyleThemeInterface>`
width: auto;
height: 2.8125rem;
display: grid;
grid-template-columns: auto 1fr;
padding-left:1.25rem;
align-items:center;
gap: 9.06rem;
background: ${props => props.theme === "dark" ? "#3d4854" : "rgba(170, 177, 185, 0.3)"};

`;
export const TableCell = styled.div`
width: 3.349rem;
`;

export const RatingContainer=styled.div<StyleThemeInterface>`
margin:1.25rem 0;
background: ${props => props.bgcolor};
padding-bottom: 1.19rem;
`;
export const RatingContentContainer=styled.div`
margin-top:1.25rem;
display: grid;
grid-template-columns: auto 1fr;
align-items:center;
gap: 9.06rem;
`;
export const MarkContainer=styled.div`
display: flex;
justify-content:space-between;
align-items:center;
width: 11rem;
`;
export const MarkScaleContainer=styled.div`
display: flex;
flex-direction:column;
gap:0.62rem;
`;

export const CriteriaContainer=styled.div`
display: flex;
flex-direction:column;
margin-left: 1.25rem;
gap:0.31rem;
width: 3.349rem;
`;