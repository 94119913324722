import { useState, useEffect, useContext } from "react";
import {
  SchemeGroupsWrapper,
  TitleWithAddSchemeWrapper,
  SchemesWrapper,
  EmptyViewWrapper,
  LoaderContainer,
  ParentContainer,
} from "./GroupSchemaView.style";
import IconWithText from "../../elements/iconWithText/main/IconWithText";
import { Typography, MediumTypography } from "../../elements/fonts/Fonts";
import { SvgAdd, SvgAddSchemeGroup, SvgEmpty } from "../../elements/Icons";
import SchemePopUp from "../../elements/newReusablePopUp/ReusablePopUp";
import SchemeGroup from "../schemeGroup/SchemeGroup";
import { SchemeGroupInterface } from "../schemeGroup/schemeGroupInterface";
import { useStateContext } from "../../contexts/ContextProvider";
import { AllGroupMembersData } from "../allMembersGroups/allGroupMemberBarData";
import { useNavigate, useParams } from "react-router-dom";
import EmptyView from "../../elements/EmptyView/EmptyView";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { set } from "lodash";
import { GroupStudent } from "../groupCardNew/groupCardNewInterface";
import Loader from "../loader/Loader";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { adminRoleState, adminState } from "../../recoil/RecoilStates";
import { useParentClass } from "../../utils/getParentClass";

const GroupSchemaView = () => {
  const {
    schemeTitle,
    schemeDescription,
    setSchemeTitle,
    setSchemeDescription,
    schemes,
    setSchemes,
    setIsPopUpOpen,
  } = useStateContext();
  const [isAdmin] = useRecoilState(adminState);
  const [adminRole] = useRecoilState(adminRoleState);
  const { role } = useContext(ClassContext);
  const { isLoading, user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const { t } = useTranslation();

  const params = useParams();
  const classId = params.classId;

  const [showSchemePopup, setShowSchemePopup] = useState<boolean>(false);
  const [isLoadingScheme, setIsLoadingScheme] = useState<boolean>(false);
  const [selectedClassId, setSelectedClassId] = useState<string>("");
  const [classSchemes, setClassSchemes] = useState<{
    [key: string]: SchemeGroupInterface[];
  }>({});

  const BaseURL = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";

  const { isParent, children } = useParentClass(classId);

  const getUngroupedStudents = async (
    schemeId: string | number,
    classId: string
  ) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `${BaseURL}/groups/get/all/users/${classId}/${schemeId}/${tenantName}`
      );

      return res.data.data || [];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const generateId = () => Math.floor(Math.random() * 10000) + 1;

  const navigate = useNavigate();

  const [isSchemeId, setIsSchemeId] = useState<number | string>();

  const setIsSchemeIdInGroupSchemeView = (schemeId: number | string) => {
    setIsSchemeId(schemeId);
  };

  const [showEditScheme, setShowEditScheme] = useState(false);

  const handleShowEditSchemeInGroupSchemeView = (showEditScheme: boolean) => {
    setShowEditScheme(showEditScheme);
  };

  const getCreatedScheme = async (currentClassId: any) => {
    try {
      setIsLoadingScheme(true);
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.get(
        `${BaseURL}/groups/get/all/schema/${currentClassId}/${tenantName}`
      );

      const updatedSchemes = await Promise.all(
        response.data.data.map(async (scheme: SchemeGroupInterface) => {
          const ungroupedStudents = await getUngroupedStudents(
            scheme.groupSchemaId,
            currentClassId
          );
          return {
            ...scheme,
            ungroupedStudents: ungroupedStudents,
          };
        })
      );

      setClassSchemes((prev) => ({
        ...prev,
        [currentClassId]: updatedSchemes, // Store schemes for the specific class
      }));

      setIsLoadingScheme(false);
    } catch (error) {
      console.error("Error in GET request:", error);
    }
  };

  const createScheme = async (path: string, body: any) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.post(`${BaseURL}/${path}/${tenantName}`, {
        tenantName: tenantName,
        data: { ...body, classSourceId: isParent ? selectedClassId : classId },
      });
      await navigate(
        `/group-creator/${response.data.data}/${isParent ? selectedClassId : classId
        }`,
        { state: { classId } }
      );
      return response.data;
    } catch (error) {
      console.error("Error in POST request:", error);
    }
  };

  const addNewScheme = async (title: string, description?: string) => {
    const schemeId = generateId();
    const data = {
      title: title,
      description: description,
      subId: subId,
      provider: provider,
    };

    await createScheme("groups/create/schema", data);

    setClassSchemes((prevSchemes) => {
      const newSchemeField = {
        groupSchemaId: schemeId,
        titleScheme: title,
        description: description,
        groupsForScheme: [],
        ungroupedStudents: [],
      };
      return {
        ...prevSchemes,
        [selectedClassId]: [
          ...(prevSchemes[selectedClassId] || []),
          newSchemeField,
        ],
      };
    });
  };

  const deleteScheme = async (id: number | string) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.delete(
        `${BaseURL}/groups/delete/schema/${id}/${tenantName}`
      );

      setClassSchemes((prev) => {
        const classSchemes = prev[selectedClassId] || [];
        return {
          ...prev,
          [selectedClassId]: classSchemes.filter(
            (scheme) => scheme.groupSchemaId !== id
          ),
        };
      });

      return response.data;
    } catch (error) {
      console.error("Error in DELETE request:", error);
    }
  };

  const removeScheme = async (schemeId: number | string, classId?: string) => {
    if (schemeId !== undefined) {
      if (classId && classSchemes[classId]) {
        // Remove scheme from the specified class
        const updatedSchemes = classSchemes[classId].filter(
          (scheme: SchemeGroupInterface) => scheme.groupSchemaId !== schemeId
        );
        setClassSchemes({
          ...classSchemes,
          [classId]: updatedSchemes,
        });
      } else {
        // Remove scheme from all classes where it exists
        const updatedSchemes = Object.fromEntries(
          Object.entries(classSchemes).map(([key, schemes]) => [
            key,
            schemes.filter(
              (scheme: SchemeGroupInterface) =>
                scheme.groupSchemaId !== schemeId
            ),
          ])
        );
        setClassSchemes(updatedSchemes);
      }
      await deleteScheme(schemeId);
    }
  };

  useEffect(() => {
    if (isParent) {
      const fetchSchemesForAllClasses = async () => {
        for (const child of children) {
          await getCreatedScheme(child.sourceId);
        }
      };
      fetchSchemesForAllClasses();
    } else {
      getCreatedScheme(classId);
    }
  }, [isParent, children, classId]);

  return (
    <SchemeGroupsWrapper>
      {isParent ? (
        <>
          <Typography text={t("group.Group Creator")} fontSize="1.75rem" />
          {children.map((child: any, index: number) => (
            <ParentContainer key={index}>
              <TitleWithAddSchemeWrapper>
                <Typography text={child.className} fontSize="1.75rem" />
                {(role === "teacher" ||
                  (isAdmin === true && adminRole === "teacher")) && (
                    <div
                      onClick={() => {
                        setShowSchemePopup(true);
                        setSchemeTitle("");
                        setSchemeDescription("");
                        setSelectedClassId(child.sourceId);
                      }}
                    >
                      <IconWithText
                        icon={SvgAdd}
                        textElement={
                          <MediumTypography
                            text={t("group.Add Scheme")}
                            fontSize="0.75rem"
                            lineHeight="1.375rem"
                          />
                        }
                        margin="-0.1rem 0 0 0"
                      />
                    </div>
                  )}
              </TitleWithAddSchemeWrapper>
              {(showSchemePopup || showEditScheme) && (
                <SchemePopUp
                  show={showSchemePopup}
                  setShow={setShowSchemePopup}
                  handleSubmit={() => {
                    addNewScheme(schemeTitle, schemeDescription);
                  }}
                  showComponent={true}
                  title={t("group.Add Scheme")}
                  icon={SvgAddSchemeGroup}
                  setShowEditScheme={setShowEditScheme}
                  showEditScheme={showEditScheme}
                  setClassSchemes={setClassSchemes}
                // getCreatedScheme={getCreatedScheme}
                />
              )}
              {isLoadingScheme ? (
                <LoaderContainer>
                  <Loader text={`${t("grader.Loading your schemes")}...`} />
                </LoaderContainer>
              ) : (
                <SchemesWrapper
                  schemenumber={classSchemes[child.sourceId]?.length || 0}
                >
                  {(classSchemes[child.sourceId]?.length || 0) === 0 ? (
                    <EmptyViewWrapper>
                      <EmptyView icon={SvgEmpty} />
                    </EmptyViewWrapper>
                  ) : (
                    classSchemes[child.sourceId]?.map((scheme, index) => (
                      <SchemeGroup
                        key={index}
                        groupSchemaId={scheme.groupSchemaId}
                        titleScheme={scheme.titleScheme}
                        description={scheme.description}
                        groupsForScheme={scheme.groupsForScheme}
                        ungroupedStudents={scheme.ungroupedStudents}
                        removeScheme={removeScheme}
                        setIsSchemeIdInGroupSchemeView={
                          setIsSchemeIdInGroupSchemeView
                        }
                        setShowEditScheme={
                          handleShowEditSchemeInGroupSchemeView
                        }
                        showEditScheme={showEditScheme}
                        scheme={scheme}
                        selectedClassId={child.sourceId}
                      />
                    ))
                  )}
                </SchemesWrapper>
              )}
            </ParentContainer>
          ))}
        </>
      ) : (
        <>
          <TitleWithAddSchemeWrapper>
            <Typography text={t("group.Group Creator")} fontSize="1.75rem" />
            {(role === "teacher" ||
              (isAdmin === true && adminRole === "teacher")) && (
                <div
                  onClick={() => {
                    setShowSchemePopup(true);
                    setSchemeTitle("");
                    setSchemeDescription("");
                  }}
                >
                  <IconWithText
                    icon={SvgAdd}
                    textElement={
                      <MediumTypography
                        text={t("group.Add Scheme")}
                        fontSize="0.75rem"
                        lineHeight="1.375rem"
                      />
                    }
                    margin="-0.1rem 0 0 0"
                  />
                </div>
              )}
          </TitleWithAddSchemeWrapper>
          {(showSchemePopup || showEditScheme) && (
            <SchemePopUp
              show={showSchemePopup}
              setShow={setShowSchemePopup}
              handleSubmit={() => {
                addNewScheme(schemeTitle, schemeDescription);
              }}
              showComponent={true}
              title={t("group.Add Scheme")}
              icon={SvgAddSchemeGroup}
              setShowEditScheme={setShowEditScheme}
              showEditScheme={showEditScheme}
              setClassSchemes={setClassSchemes}
            // getCreatedScheme={getCreatedScheme}
            />
          )}
          {classId && isLoadingScheme ? (
            <LoaderContainer>
              <Loader text={`${t("grader.Loading your schemes")}...`} />
            </LoaderContainer>
          ) : (
            classId && (
              <SchemesWrapper schemenumber={classSchemes[classId]?.length || 0}>
                {(classSchemes[classId]?.length || 0) === 0 ? (
                  <EmptyViewWrapper>
                    <EmptyView icon={SvgEmpty} />
                  </EmptyViewWrapper>
                ) : (
                  classSchemes[classId]?.map((scheme, schemeIndex) => (
                    <SchemeGroup
                      key={schemeIndex}
                      groupSchemaId={scheme.groupSchemaId}
                      titleScheme={scheme.titleScheme}
                      description={scheme.description}
                      groupsForScheme={scheme.groupsForScheme}
                      ungroupedStudents={scheme.ungroupedStudents}
                      removeScheme={removeScheme}
                      setIsSchemeIdInGroupSchemeView={
                        setIsSchemeIdInGroupSchemeView
                      }
                      setShowEditScheme={handleShowEditSchemeInGroupSchemeView}
                      showEditScheme={showEditScheme}
                      scheme={scheme}
                      selectedClassId={classId}
                    />
                  ))
                )}
              </SchemesWrapper>
            )
          )}
        </>
      )}
    </SchemeGroupsWrapper>
  );
};

export default GroupSchemaView;
