import React from "react";
import {
  SvgCoursesIcon,
  SvgMedia,
  SvgMic,
  SvgUrl,
  SvgNews,
  SvgQuiz,
  SvgDiscussion,
  SvgVideoIcon,
  SvgLinkTree,
  SvgLink,
  SvgDocument,
  SvgGroup3339,
  SvgMonitorIcon,
  SvgFolderIcon,
  SvgVideoNew,
  SvgSurveyIcon,
  SvgTeams,
} from "../../../elements/Icons"; // Import your resource icons here

export function getResourceIcon(resourceType: string) {
  switch (resourceType) {
    case "Document":
      return { icon: <SvgDocument />, color: "#21D6F8" };
    case "Picture":
      return { icon: <SvgMedia />, color: "#85db8f" };
    case "Link":
      return { icon: <SvgUrl />, color: "#8E5DFD" };
    case "Video":
      return { icon: <SvgVideoNew />, color: "#B757E2" };
    case "Assignment":
      return { icon: <SvgNews />, color: "#5DD3B3" };
    case "Forum":
      return { icon: <SvgDiscussion />, color: "#8cedea" };
    case "Quiz":
      return { icon: <SvgQuiz />, color: "#96b4e3" };
    case "File":
      return { icon: <SvgCoursesIcon width={"1rem"} height={"1rem"} />, color: "#3DA4FC" };
    case "Link_Tree":
      return { icon: <SvgLinkTree />, color: "#CED35D" };
    case "Folder":
      return {
        icon: <SvgFolderIcon width={"1rem"} height={"1rem"} />,
        color: "#5D69FD",
      };
    case "Html":
      return { icon: <SvgMonitorIcon />, color: "#96b4e3" };
    case "Online_Session":
      return { icon: <SvgMic />, color: "red" };
    case "Survey":
      return { icon: <SvgSurveyIcon />, color: "	#FFB6C1" };
    case "Teams":
      return {
        icon: <SvgTeams />, color: "#4E5FBF"
      };
    default:
      return { icon: <SvgMonitorIcon />, color: "red" };
  }
  // return { icon: iconComponent, color: color };
}
