import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { PageHeader } from "../../components/pageHeader/PageHeader";
import {
  MessageView,
  TopHolder,
  IconWrapper,
  MessagesHolder,
  MessagesHolderTop,
  RightIconsHolder,
  OverlapGroup,
  MessageInput,
  MessagesWrapper,
  MessageInputField,
  RecentMessagesDiv,
  ReceivedMessage,
  MaskGroup,
  ProfileHolder,
  ContactMessagesLayoutplace,
  MessageViewLay,
  MessageViewEXTRA,
  SenderMessageRow,
  SenderMessage,
  ChatContainer,
  OnlyMessagesWrapper,
  OnlySenderMessagesWrapper,
  MessageAndEmoji,
  InfoSvg,
  MessageOpenOption,
  MessageOpenLabel,
  BlockContainer,
  MessagesFlow,
  MessagesLayout,
  MessagesLayoutProfile,
  ReceivedMessageRow,
  GroupSenderName,
  MessageEmptyBox,
} from "./Messages.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import { SvgEyeIcon, SvgGroup3456 } from "../../elements/Icons";
import { sortBy } from "lodash";
import RecentMessages from "../../components/recentMessages/RecentMessages";
import MessageSenderInfo from "../../elements/messageSenderInfo/MessageSenderInfo";
import { ContactMessagesCompo } from "../../components/ContactMessages/ContactMessages";
import { MessageSearch } from "./MessageSearch/MessageSearch";
import {
  GroupChatData,
  GroupChatInterface,
  RealMessagesData,
  UserDetails,
  userChatData,
} from "../../components/ContactMessages/ContactMessagesData";
import { MessageRealChatInterface } from "../../components/ContactMessages/ContactMessagesInterface";
import {
  SelectedFriendContext,
  SelectedFriendProvider,
} from "./MessageSearch/SelectedFriendcontext/SelectedFriendContext";
import useClickOutside from "../../hooks/useClickOutside";
import "./messages.css";
import { useLocation, useParams } from "react-router-dom";
import EmptyView from "../../elements/EmptyView/EmptyView";
import MessamessageSenderInfoOnlyImgeSenderInfo from "../../elements/messageSenderInfo/messageSenderInfoOnlyImg";
import {
  getSelectedFriendInfo,
  handleArchiveConversation,
  handleBlockConversation,
  handleDeleteConversation,
  handleExitGroup,
  handleFriendSelect,
  handleGroupSelect,
  handleMuteConversation,
  handleSendIconClick,
  handleShowChatOption,
  loadSelectedFriendMessages,
  scrollToMessage,
} from "./MessagesFunctions";
import Friendsview from "./MessagesFriendsview";
import GroupView from "./MessagesGroupView";
import { useRecoilValue } from "recoil";
import { ChatsColorSelector } from "../../recoil/ThemeSelectors";
import { get } from "../../utils/AxiosRequests";
import { useAuth0 } from "@auth0/auth0-react";
import { WebSocketConnector } from "./webSocketConnection";
import ToggleSwitch from "../../elements/themeSwitch/ThemeSwitch";
import { useTranslation } from "react-i18next";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import {
  WebsocketContext,
  WebsocketProvider,
} from "../../contexts/chatContext";
import { ClassContext } from "../dashboard/UnstructuredContext";
import PopUp from "../../elements/Popup/PopUp";
import ViewParticipant from "../../components/viewParticipant/ViewParticipant";
import axios from "axios";
interface Friend {
  role: string | undefined;
  id: string;
  profilePicture: any;
  username: any;
}
interface GroupConv {
  profile: any;
  _id: string;
  name: string;
  Type: string;
}
export default function Messages() {
  const { header_BG_Color, MainChatBox_bgColor } =
    useRecoilValue(ChatsColorSelector);
  const { t } = useTranslation();
  const [isMessageVisible, setMessageVisible] = useState(false);
  const [ShowChatOption, setShowChatOption] = useState<boolean>(false);
  const [ShowSearchPlace, setShowSearchPlace] = useState<boolean>(false);
  const [selectedFriendId, setSelectedFriendId] = useState<any>(null);
  const [inputMessage, setInputMessage] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState<any>(null);
  const [selectedGroupChatData, setSelectedGroupChatData] = useState<any>([]);
  const [selectedGroupData, setSelectedGroupData] =
    useState<GroupChatInterface | null>(null);
  const [realMessagesData, setRealMessagesData] =
    useState<MessageRealChatInterface[]>(RealMessagesData);
  const { SelectedFriend, setSelectedFriend } = useContext(
    SelectedFriendContext
  );
  const location = useLocation();
  const { state } = location;
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [mySourceId, setMySourceId] = useState<string>("");
  const [connectedToWebsocket, setConnectedToWebsocket] =
    useState<boolean>(false);
  const UrlId = useParams();
  const classId = UrlId.classId;
  const tenantName = localStorage.getItem("tenant");
  const { user, getAccessTokenSilently } = useAuth0();
  const myEmail = user?.email?.toLowerCase();
  // const connector = new WebSocketConnector();
  // const webSocket = useRef(connector);
  const [UserFriends, SetUserFriends] = useState<Friend[]>([]);
  const [friendConversationId, setFriendConversationId] = useState<any>();
  const [GroupConversation, SetGroupConversation] = useState([]);
  const [convo, SetConvo] = useState<GroupConv[]>([]);
  const [GroupConv, SetGroupConv] = useState<GroupConv[]>([]);
  const [UserMessages, SetUserMessages] = useState<any>([]);
  const [GroupMessages, SetGroupMessages] = useState<any>([]);
  const { isReady, value, send, connect } = useContext(WebsocketContext);
  const [ConvDeleted, SetConvDeleted] = useState(false);
  const [viewParticipant, SetviewParticipant] = useState<boolean>(false)
  const { setOnlineClients, getUnvewedConversationsMessageCount } =
    useContext(ClassContext);
  let ws: any;

  // Defines the ID to navigate to, either from the state or directly from the state
  const NavigatedId = state?.fromNavigateId || state?.id;

  // Finds messages between the logged-in user and the selected friend
  const selectedFriendMessages =
    userChatData.find(
      (chat) =>
        (chat.UserId === mySourceId && chat.FriendId === selectedFriendId) ||
        (chat.UserId === selectedFriendId && chat.FriendId === mySourceId)
    )?.Messages || [];

  // Checks if the selected friend is blocked by the user
  const isBlocked = UserDetails?.blocked?.includes(selectedFriendId);

  // Creates a ref for the chat container to handle clicks outside the chat options
  const containerRef = useClickOutside(ShowChatOption, () =>
    setShowChatOption(false)
  );

  // Retrieves profile image and sender name of the selected friend
  const { profileImg, senderName } = getSelectedFriendInfo(
    selectedFriendId,
    UserFriends
  );

  useEffect(() => {
    if (NavigatedId) {
      if (UserFriends.some((friend) => friend.id === NavigatedId)) {
        setSelectedFriend(NavigatedId); // Update the context value here
        setSelectedGroupId(null);
        handleFriendSelect(
          NavigatedId,
          setSelectedFriendId,
          setSelectedGroupId
        );
        loadSelectedFriendMessages(NavigatedId);
      } else {
        setSelectedGroupId(NavigatedId);
        setSelectedFriendId(null);
        handleGroupSelect(
          NavigatedId,
          setSelectedGroupId,
          setSelectedFriendId,
          GroupChatData,
          setSelectedGroupData,
          setSelectedGroupChatData
        );
        loadSelectedFriendMessages(selectedFriendId);
        // Fetch group chat data or perform necessary actions
        // handleGroupSelect(NavigatedId);
      }
    }
  }, [NavigatedId]);
  //using the chat container ref to scroll to the bottom of the messages div on open
  const chatContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Scroll to the bottom of the chat when component is mounted or selectedFriendId changes
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [selectedFriendId, selectedGroupId]);

  const fetchUsersData = async () => {
    try {
      const response = await get(`participant/class/${classId}/${tenantName}`);
      const auditors = await axios.get(`https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/classAuditor/auditors/${tenantName}/${classId}`)
      setDataLoading(false);
      const filteredData = response.data.filter(
        (user: any) => user.email.toLowerCase() === myEmail
      );
      const auditorFilteredData = auditors.data.data.filter(
        (user: any) => user.email.toLocaleLowerCase() === myEmail?.toLocaleLowerCase()
      );
      if (filteredData.length > 0)
        setMySourceId(filteredData[0].sourceId);
      else if (auditorFilteredData.length > 0)
        setMySourceId(auditorFilteredData[0].sourceId);
      const filteredData2 = response.data.filter(
        (user: any) => user.email.toLowerCase() !== myEmail?.toLowerCase()
      );
      const modifiedData = filteredData2.map((user: any) => ({
        ...user,
        id: user.sourceId,
      }));
      SetUserFriends(modifiedData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");

  var url = `wss://p1orbzycf9.execute-api.eu-west-1.amazonaws.com/dev/?subId=${subIdSplitted}&provider=${provider}&classSourceId=${classId}&tenantName=${tenantName} `;

  // webSocket.current.getConnection(url).onopen = function (event: any) {
  //   console.log("Connection is established");
  //   setConnectedToWebsocket(true);
  // };

  useEffect(() => {
    var data = JSON.parse(value);
    // console.log("Recieved Message---->",data)
    if (data && data.type && data.type === "message") {
      const receive = data.data;
      getUnvewedConversationsMessageCount(classId || '')
    } else if (data && data.type && data.type === "clients") {
      setOnlineClients(data.value)
    } else if (data && data.type && data.type === "messages") {
    } else if (data && data.type && data.type === "public") {
      const receive = data.data;
      getUnvewedConversationsMessageCount(classId || '')
    } else if (data && data.type && data.type === "conversations") {
      SetGroupConversation(data.value);
    }
  }, [value]);

  const [LeaveGroup, SetLeaveGroup] = useState(false);

  return (
    <>
      <SelectedFriendProvider fromNavigateId={NavigatedId}>
        <WebsocketProvider url={url}>
          <MessageViewLay>
            <MessageViewEXTRA>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  flex: "9",
                }}
              >
                {/* handle where there s no group or friend selected*/}
                {!selectedFriendId && !selectedGroupId ? (
                  <MessageView
                    style={{ flexDirection: "column" }}
                    bgColor={header_BG_Color}
                  >
                    <TopHolder>
                      <Typography
                        fontSize="1.125rem"
                        text={t("Chat.Conversations")}
                      />
                    </TopHolder>
                    <MessageEmptyBox bgColor={MainChatBox_bgColor}>
                      <EmptyView
                        icon={SvgGroup3456}
                        text={t("Chat.No message yet Start the Conversations")}
                        lineHeight="1.375rem"
                        TypographyType={false}
                        fontSize="0.875rem"
                        width="15rem"
                      />
                    </MessageEmptyBox>
                  </MessageView>
                ) : (
                  <MessageView bgColor={header_BG_Color}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gridColumn: "1/4",
                        flex: "9",
                      }}
                    >
                      <TopHolder>
                        <Typography
                          fontSize="1.125rem"
                          text={t("Chat.Conversations")}
                        />
                      </TopHolder>
                      {selectedFriendId ? (
                        <Friendsview
                          // Pass props to the Friendsview component as needed
                          ConvDeleted={ConvDeleted}
                          SetConvDeleted={SetConvDeleted}
                          SetUserMessages={SetUserMessages}
                          UserMessages={UserMessages}
                          SetSelectedFriendId={setSelectedFriendId}
                          SetGroupMessages={SetGroupMessages}
                          handleMuteConversation={handleMuteConversation}
                          handleArchiveConversation={handleArchiveConversation}
                          handleBlockConversation={handleBlockConversation}
                          handleDeleteConversation={handleDeleteConversation}
                          handleShowChatOption={handleShowChatOption}
                          handleSendIconClick={handleSendIconClick}
                          ShowSearchPlace={ShowSearchPlace}
                          setShowSearchPlace={setShowSearchPlace}
                          ShowChatOption={ShowChatOption}
                          setShowChatOption={setShowChatOption}
                          containerRef={containerRef}
                          inputMessage={inputMessage}
                          setInputMessage={setInputMessage}
                          selectedFriendId={selectedFriendId}
                          selectedGroupId={selectedGroupId}
                          UserFriends={UserFriends}
                          isBlocked={isBlocked}
                          senderName={senderName}
                          chatContainerRef={chatContainerRef}
                          friendConversationId={friendConversationId}
                          SetGroupConv={SetGroupConv}
                          SetConvo={SetConvo}
                        />
                      ) : (
                        <GroupView
                          viewParticipant={viewParticipant}
                          SetviewParticipant={SetviewParticipant}
                          SetGroupMessages={SetGroupMessages}
                          GroupMessages={GroupMessages}
                          LeaveGroup={LeaveGroup}
                          SetLeaveGroup={SetLeaveGroup}
                          SetUserMessages={SetUserMessages}
                          handleMuteConversation={handleMuteConversation}
                          handleArchiveConversation={handleArchiveConversation}
                          handleDeleteConversation={handleDeleteConversation}
                          handleExitGroup={handleExitGroup}
                          handleShowChatOption={handleShowChatOption}
                          handleSendIconClick={handleSendIconClick}
                          selectedFriendId={selectedFriendId}
                          ShowSearchPlace={ShowSearchPlace}
                          setShowSearchPlace={setShowSearchPlace}
                          ShowChatOption={ShowChatOption}
                          setShowChatOption={setShowChatOption}
                          containerRef={containerRef}
                          inputMessage={inputMessage}
                          setInputMessage={setInputMessage}
                          selectedGroupId={selectedGroupId}
                          selectedGroupData={selectedGroupData}
                          selectedGroupChatData={selectedGroupChatData}
                          UserFriends={UserFriends} // Make sure to pass UserFriends data
                          chatContainerRef={chatContainerRef}
                          isBlocked={isBlocked}
                          senderName={senderName}
                          handleBlockConversation={handleBlockConversation}
                          convo={convo}
                          SetConvo={SetConvo}
                        />
                      )}
                    </div>
                    {/* handle the message search */}
                    {ShowSearchPlace && (
                      <MessageSearch
                        UserMessages={UserMessages}
                        GroupMessages={GroupMessages}
                        ShowSearchPlace={ShowSearchPlace}
                        setShowSearchPlace={setShowSearchPlace}
                        selectedGroupId={selectedGroupId}
                        selectedFriendId={selectedFriendId}
                        scrollToMessage={(index) =>
                          scrollToMessage(index, chatContainerRef)
                        }
                      />
                    )}
                  </MessageView>
                )}
              </div>
            </MessageViewEXTRA>
            {/* handle thecase where we are sending a message to a group or a friend */}
            <ContactMessagesLayoutplace>
              <ContactMessagesCompo
                ConvDeleted={ConvDeleted}
                SetConvDeleted={SetConvDeleted}
                SetGroupConv={SetGroupConv}
                GroupConv={GroupConv}
                convo={convo}
                SetConvo={SetConvo}
                LeaveGroup={LeaveGroup}
                SetLeaveGroup={SetLeaveGroup}
                setFriendConversationId={setFriendConversationId}
                onFriendSelect={(friendId: any) =>
                  handleFriendSelect(
                    friendId,
                    setSelectedFriendId,
                    setSelectedGroupId
                  )
                }
                onGroupSelect={(groupId: string) =>
                  handleGroupSelect(
                    groupId,
                    setSelectedGroupId,
                    setSelectedFriendId,
                    GroupChatData,
                    setSelectedGroupData,
                    setSelectedGroupChatData
                  )
                }
                GroupConversation={GroupConversation}
              ></ContactMessagesCompo>
            </ContactMessagesLayoutplace>
            {viewParticipant && (
              <PopUp
                show={viewParticipant}
                justifyContent="flex-end"
                content={
                  <ViewParticipant selectedGroupId={selectedGroupId} />

                }
                setShow={SetviewParticipant}
                buttonName={t("Chat.Chat")}
                title={t("View Group")}
                icon={<SvgEyeIcon />}
                showline={false}
                ContentWidth="43.75rem"
                ContentHeight="25rem"
                showButton={false}
                showButtonCancel={false}


              />
            )}
          </MessageViewLay>
        </WebsocketProvider>
      </SelectedFriendProvider>
    </>
  );
}
