import styled from "styled-components";


export const AnalyticsMainCol = styled.div`
  display: flex;

  flex-direction:column;
  
  width:100%;
  min-height:80rem;
  /* background-color:#fff; */
  gap:1rem;
  margin-bottom:3rem;
`;
export const AnalyticsMainRow = styled.div`
  display: flex;
  /* align-items:start; */
  justify-content:space-around;
  width:100%;
  flex-direction:row;
  /* background-color:yellow; */
  flex-wrap:wrap;
  height:25rem;

`;
export const AnalyticsMainCols = styled.div`
  display: flex;
  /* align-items:start; */
  justify-content:space-around;
  width:80%;
  flex-direction:column;
  /* background-color:yellow; */

  border-radius: 1.25rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  padding:0.3rem;
`;



export const CircleContainer =styled.div`
width:47%;
min-width:20rem;

display: flex;
justify-content:center;
align-items:center;
/* background-color:black; */
padding:0;
`;

export const FullMainRow = styled.div`
  display: flex;
  /* align-items:start; */
  justify-content:center;
  flex:1;
  /* background-color:aqua; */
  min-height:3rem;
  height:24rem;
  flex-direction:column;
  padding:0.5rem;
  border-radius: 1.25rem;
background:linear-gradient(180deg, #415160 0%, #293440 100%);
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
position:relative;
`;

export const FullMainRowVetical = styled.div`
  display: flex;
  /* align-items:start; */
  justify-content:center;
  width:100%;
  /* background-color:aqua; */
  min-height:3rem;
  height:30rem;
  flex-direction:column;
  padding:0.5rem;
  border-radius: 1.25rem;
background:linear-gradient(180deg, #415160 0%, #293440 100%);
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
`;

export const ProgressBarChartMainContainer = styled.div`
  display: flex;
  border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  justify-content:center;
  align-items:center;
  width:50%;
  padding :1.25rem;
height: 16.4375rem;
`;

export const GraphTextAbsolute = styled.div`
  display: flex;
  position:absolute;
  padding:0.5rem;
  right:1rem;
bottom:0.8rem;
/* background-color:red; */
`;