import styled from "styled-components";
import { theme } from "../../theme";
import CheckIcon from "../../assets/icons/Group2199.svg";
import { RadioInterface } from "./CheckBoxInterface";

export const CheckBox = styled.input<RadioInterface>`
    width: ${({ width }) => (width ? width : "1.25rem")};
    height: ${({ height }) => (height ? height : "1.25rem")};
    background: ${props => props.bgcolor};

  appearance: none;
  border: ${({ border }) => (border ? border : `1px solid ${theme.dark.primary_text_color}`)};

  border-radius: 50%;
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:checked {
    background-image: url(${CheckIcon}); /* Use the CheckIcon component as a URL */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background: linear-gradient(180deg, #2F6A5A 0%, #5dd3b3 100%);
    border: 1px solid #5DD3B3;
  }
`;