import { Question } from "../GenerateQuizDataInterface";

// Validation for the creation of the Question
export const handleValidation = (questions:Question[],quest:any,index:number,partIndex:number,choice:any[],qindexValue:number) => {
     // Calculate the total percentage of all choices
     const totalPercentage = choice.reduce(
        (total, currentChoice) => total + parseFloat(currentChoice.percentage || '0'),
        0
      );

      if (questions[index]?.type === "" && !questions[index]?.questPart) {
        return ("Please select a question type for the question.");
      } else if (
        !questions[index]?.type &&
        !questions[index]?.questPart[partIndex]?.type
      ) {
        return ("Please select a question type for the question part.");
      }
  
      if (!quest || quest.question.trim() === "" || quest.question.trim() === "<br>") {
        return ("This field is mandatory");
      }
      if (!quest || quest.grade.trim() === "") {
        return ("This field is mandatory");
      }

      if (quest.grade.trim() === "0") {
        return ("Point cannot be 0");
      }
  
      if (quest.type === "MATCH" && choice.length < 2) {
        return ("Please add at least two choices for Matching questions.");
      }
      if (quest.type === "MCQ" && choice.length < 2) {
        return ( "Please add at least two choices for MCQ questions.");
      }
  
      if (quest.type === "MATCH") {
        // Ensure the first two choices' names are not empty strings
        if (!choice[0]?.left?.trim() || !choice[1]?.left?.trim() || !choice[0]?.right?.trim() || !choice[1]?.right?.trim()) {
          return ("First two choices' names cannot be empty for Matching questions.");
        }
      }
  
      if (quest.type === "MCQ" || quest.type === "MRQ") {
        // Ensure the first two choices' names are not empty strings
        if (choice[qindexValue].isChecked===true && (!choice[qindexValue]?.name?.trim())) {
          return ("Choices cannot be empty.");
        }
        // Ensure the Percentage field cannot be empty.
        if (choice[qindexValue].isChecked===true && ( !choice[qindexValue]?.percentage?.trim())) {
          return ("Percentage field cannot be empty.");
        }
        if (!choice[0]?.name?.trim() || !choice[1]?.name?.trim() || !choice[0]?.percentage?.trim() || !choice[1]?.percentage?.trim()) {
          return ("First two choices' names cannot be empty for MCQ questions.");
        }
        // Check if the total percentage is not equal to 100
        if (totalPercentage !== 100) {
          return ("Total percentage of choices should be 100.");
        } 
      }
  
      if ((quest.type === "MCQ" || quest.type === "MRQ") && totalPercentage === 100 && (choice[qindexValue]?.percentage?.trim() && !choice[qindexValue]?.name?.trim())){
        return ("Choices field cannot be empty.");
      }
  
      if (totalPercentage === 100 && (!choice[qindexValue]?.percentage?.trim() && choice[qindexValue]?.name?.trim())){
        return ("Percentage field cannot be empty.");
      }
  
      if (quest.type === "SHORT_ANSWER") {
        // Ensure the first two choices' names are not empty strings
        if (!choice[0]?.name?.trim()) {
          return ("First Keyword cannot be empty for Short answer questions.");
        }
      }
  
      if (quest.type === "NUMERIC") {
        // Ensure the first two choices' names are not empty strings
        if (!choice[0]?.name?.trim() || !choice[0]?.errorMargin?.trim()) {
          return ("Fields is mandatory.");
        }
      }
  
      if((quest.type === "MCQ" || quest.type === "MATCH" || quest.type === "MRQ") && !quest.shuffle){
        quest.shuffle= false;
      }
}
// Ends Of Function