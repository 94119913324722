import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

interface ChatsProps {
  height?: string;
}
// export const  ContactMessages = styled.div`

// `;

export const ContactMessagesLayout = styled.div<StyleThemeInterface>`
  display: flex;
  width: 100%;
  &:dir(ltr) {
    border-radius: 1.25rem 0rem 0rem 1.25rem;
  }
  &:dir(rtl) {
    border-radius: 0rem 1.25rem 1.25rem 0rem;
  }
  /* background: #384450; */
  background: ${(props) => props.bgcolor};
  flex-direction: column;
  /* background-color:aqua; */
  /* padding:0.1rem; */
  /* min-height:10rem; */
  /* margin-bottom:2rem; */
`;

export const MessagesTitleRow = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;

  &:nth-child(1) {
    margin: 1.25rem 0.94rem 1.2rem 1.2rem;
    /* margin:1.25rem 0.94rem  0 1.20rem; */
    /* background-color:aqua; */
  }
`;
export const MessagesSearchRow = styled.div`
  display: flex;
  width: 91%;
  justify-content: space-between;
  margin: 0 0.94rem 1rem 1.2rem;
  height: 100%;
  /* height:3rem; */
  align-items: center;
  /* background-color:red; */
`;
export const MessagesSearchItem = styled.div`
  height: 2.5rem;
  width: 100%;
`;
export const MessagesAdd = styled.div<{ color?: string }>`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background-color: #5dd3b3;
  border-radius: 50%;
  cursor: pointer;
  /* old margin */
  /* margin-bottom:2rem;  */
  position: relative;
  color: ${(props) => props.color};
`;

export const MessagesAddType = styled.div<{ bgColor: string; color?: string }>`
  display: flex;
  flex-direction: column;
  width: 10.875rem;
  /* height: 4.0625rem; */
  border-radius: 0.3125rem;
  /* background: #1F2731; */
  background: ${(props) => props.bgColor};
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 1rem;
  z-index: 1;
  &:dir(ltr) {
    right: 1rem;
  }
  &:dir(rtl) {
    left: 1rem;
  }
  color: ${(props) => props.color};
`;
export const MessagesAddCell = styled.div`
  display: flex;
  padding: 0.62rem 1.25rem 0.55rem 1.25rem;
  &:first-child {
    border-radius: 0.3125rem 0.3125rem 0 0;
  }
  &:last-child {
    border-radius: 0 0 0.3125rem 0.3125rem;
  }
  &:hover {
    background-color: #5cd1b1;
    color: #fff;
  }
`;

export const MessagesCheckedUserDiv = styled.div`
  display: flex;
  width: 100%;
  gap: 0.25rem;
  cursor: pointer;
  /* background-color:red; */
  padding: 0.3rem 0.7rem 0.3rem 0.9rem;
  min-width: 19rem;
  height: 4.25rem;
  background: rgba(255, 255, 255, 0.03);
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const RightTriangleDiv = styled.div`
  width: 0;
  height: 0;
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid transparent;
  border-right: 0.5rem solid green;
  margin: 1rem 1rem 0 -1.2rem;
`;

export const UserMessagesParentRender = styled.div<ChatsProps>`
  display: flex;
  width: 103.2% !important;
  &:dir(ltr) {
    margin-left: -0.7rem;
  }
  &:dir(rtl) {
    margin-right: -0.7rem;
  }
  /* background-color: aqua; */
  gap: 0.31rem;
  flex-direction: column;
  align-items: flex-end;

  height: ${(props) => props.height || "50vh"};

  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0.2rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(
      --Green-Gradient,
      linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)
    );
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0.625rem;
  }
`;

export const UserMessagesChildRender = styled.div`
  display: flex;
  width: 102%;
  gap: 0.31rem;
  flex-direction: column;
  justify-content: flex-end;
  align-self: flex-end;
  &:dir(ltr) {
    margin-right: -1rem;
  }
  &:dir(rtl) {
    margin-left: -1rem;
  }
`;

export const ShowFormAtrr = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const UserHackedMessagesContainer = styled.div`
  width: 3rem;
  height: 4.25rem;
  background: rgba(255, 255, 255, 0.03);
`;

export const UserMessagesContainer = styled.div<{ bgColor?: string }>`
  display: flex;
  width: 100%;
  /* gap: 0.25rem; */
  cursor: pointer;
  padding: 0.3rem 0.7rem 0.3rem 0.9rem;
  min-width: 19rem;
  height: 4.25rem;
  /* background: rgba(255, 255, 255, 0.03); */
  position: relative;
  align-items: center;

  background: ${(props) => props.bgColor || "rgba(255, 255, 255, 0.03)"};
`;

export const ContainerCheckChat = styled.div`
  display: flex;
  /* background-color:aqua; */
  margin-top: -1rem;
`;

export const RightTriangle = styled.div`
  /* width: 0;  
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #000; */
  position: absolute;
  top: 35%;
  left: -0.3rem;
  z-index: 1;
  &:dir(rtl) {
    /* background-color: red !important; */
    left: 100%;
    transform: rotate(180deg) !important;
  }
`;

export const UserProfileContainer = styled.div`
  display: flex;
  /* width: 3.3875rem;
height: 3.6875rem; */
  /* border-radius:50%;
background-color:black; */
  padding-top: 0.3rem;
`;

export const UserProfileTextColumn = styled.div`
  display: flex;
  /* justify-content:space-between; */

  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  /* background-color:aqua; */
  padding-left: 0.2rem;
  padding-bottom: 0.2rem;
  width: 100%;
  /* gap: 0.4rem; */
`;
export const UserProfileTextPlace = styled.div`
  display: flex;
  gap: 1rem;
  /* justify-content:space-between; */
  width: 100%;
  min-width: 11rem;
  /* background-color:aqua; */

  align-items: center;
  padding-left: 0.25rem;

  /* background-color:aqua; */
  &:nth-child(2) {
    /* background-color:red; */
    min-width: 12rem;
    /* background-color:aqua; */
  }
`;

export const UserProfileTextPlaceMessage = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
  min-width: 11rem;
  min-width: 12rem;
  /* background-color:aqua; */

  /* align-items:flex-end; */
  padding-left: 0.25rem;
`;

export const UserProfileTextWidth = styled.div<StyleThemeInterface>`
  display: flex;
  max-width: 8.5rem;
  /* background-color:black; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  opacity: ${(props) => props.opacity};
  /* background: green; */
  /* color: ${(props) => props.color}; */
`;

export const NumOfMessages = styled.div`
  display: flex;
  width: 1rem;
  height: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #5dd3b3;
  /* padding:0.50rem; */
  /* border-radius: 0.625rem; */
`;

export const NumOfMessagesPlus = styled.div`
  display: flex;
  min-width: 1.2rem;
  height: 1.2rem;
  justify-content: center;
  align-items: center;

  background-color: #5dd3b3;
  padding: 0.5rem;
  border-radius: 0.625rem;
`;
export const ArchivePlace = styled.div`
  height: 2.625rem;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.03);
  margin-bottom: 0.5rem;
  width: 100%;
`;

export const DeleteButton = styled.div`
  cursor: pointer;
`;

export const QuestionPart = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-end;
  height: 2rem;
  margin: 1rem 0;
  position: relative;
`;

export const OptionQuestion = styled.div`
  /* width: 10.875rem; */

  flex-shrink: 0;
  border-radius: 0.3125rem;
  background: #1f2731;

  position: relative;
  display: flex;
  flex-direction: column;
  /* gap:0.30rem; */
`;

export const OptionQuestionText = styled.div`
  padding: 0.63rem;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #5dd3b3;
  }
`;

export const ChatEditBoxTool = styled.div<{
  bgColor: string;
  color?: string;
  borderColor?: string;
}>`
  width: 100%;
  height: 3.5625rem;
  flex-shrink: 0;
  display: flex;
  border-top: 1px solid ${(props) => props.borderColor};
  /* background: #384450; */
  justify-content: space-between;
  padding: 0.94rem;
  color: ${(props) => props.color};
  background: ${(props) => props.bgColor};
`;

export const SearchInputPlaceDiv = styled.div`
  margin-top: -0.5rem;
  height: 2rem !important;
  padding: 0 1rem;
`;

export const FriendCardDiv = styled.div`
  display: flex;
`;

export const MessagesNoFriendsBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 22rem;
  /* background-color:yellow; */
`;

export const NoConnection = styled.div`
  display: flex;
  /* align-items: flex-start;
justify-content: center; */
  background-color: #aab1b980;
  padding: 0.81rem 1.25rem;
  width: 21.8125rem;
`;
