import styled from "styled-components";
import { theme } from "../../theme";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";
interface TableTdProps {
    color?: string;
  }

  export const TitleRowBeforeTable = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding-left: 1rem;
  align-items: center;
  gap: 0.63rem;
  `
export const GradeSchemaWrapper = styled.div`
width: 100%;
height: fit-content;
/* background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%)); */
/* padding-top: 1.25rem ; */
/* margin: 0rem 0rem 1.25rem 0rem; */
/* width: 100%;
border-radius: 1rem; */
`
export const TableSection = styled.div`
  width: 100%;
  display: flex;
  min-height: fit-content;
  overflow-x: hidden;
  margin-top: 1.25rem;
  position: relative;
  scroll-behavior: smooth;
  /* background-color: red; */
`;

export const TableContentHolder = styled.table`
  width: 100%;
  /* min-width: 800px; */
  border: none;
  /* background-color: yellow; */
`;

export const TableContent = styled.table`
  max-height: 100%;
  height: fit-content;
  /* width: 100%; */
  border-collapse: collapse;
  overflow-x: auto;
  white-space: nowrap;
  border: none;
  width: 100%;
  /* background-color: white; */
`;

export const TableThead = styled.thead`
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  align-items: center;
  /* gap: 6.5rem; */
  border: none;
  white-space: nowrap;
`;

export const TableTh = styled.th`
  display: flex;
  flex-direction: row;
  width: 58%;
  padding: 0 2.5rem 0rem 1rem;
  /* gap: 25.5rem; */
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
`;

export const TableTr = styled.tr`
  /* width: auto; */
  &:nth-child(even) {
    /* background: rgba(255, 255, 255, 0.03); */
    border: none;
  }
`;
export const TableTr1 = styled.tr<StyleThemeInterface>`
  display: flex;
justify-content: space-between;
  white-space: nowrap;
  /* padding-left: 1.5rem; */
  /* padding-right: 50%; */
  align-items: center;
  /* min-width: 100vw; */
  height: 3.5625rem;
  &:nth-child(even) {
    /* background: rgba(255, 255, 255, 0.03); */
    background:${props =>props.nth_child_bgcolor || "rgba(255, 255, 255, 0.03)"};
    border: none;
  }
`;

export const TableTbody = styled.tbody`
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
`;

export const TableTd = styled.td<TableTdProps>`
  border: none;
  width: 48%;
  display: flex;
  /* justify-content: flex-start; */
  height: 3.5625rem;
  border-collapse: collapse;
  padding: 1rem 2rem 0rem 0rem;
  text-align: center;
  /* max-width: 23.1rem; */
  color: ${({ color }) => color};
&:first-child{
  padding: 1rem 2rem 0rem 1rem;

}
`;
export const TableTd1 = styled.td<TableTdProps>`
  border: none;
  height: 3.5625rem;
  border-collapse: collapse;
  padding: 0rem 2rem 0rem 0rem;
  text-align: center;
  /* min-width: 10.1rem; */
  color: ${({ color }) => color};
`;
export const Input = styled.input<StyleThemeInterface>`
  border:none;
  color:${props =>props.color};
  font-weight:400;
  width: 4.1875rem;
height: 2.1875rem;
  background: transparent;
  border-radius: 0.625rem;
  outline: none;
  font-family: ${theme.font.primaryRegular};
  font-size: 0.875rem;
  text-align: center;
  &:focus {
    border: 1px solid ${props =>props.bordercolor};
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  }
  &::placeholder {
    text-align: center;
    color:white;

  }
`;
export const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.62rem;
  margin: 2.12rem 0rem;
`;
export const ButtonCancel = styled.button`
  width: 13.4375rem;
  height: 3.125rem;
  border-radius: 6.25rem;
  border: 1px solid #d85d66;
  background: none;
  cursor: pointer;
`;
export const ButtonAdd = styled.button`
  outline: none;
  border: none;
  width: 13.4375rem;
  height: 3.125rem;
  border-radius: 6.25rem;
  background: #5dd3b3;
  cursor: pointer;
`;