import styled from "styled-components";

export const Overlay = styled.div<{ isvisible: string }>`
  display: ${(props) => (props.isvisible ? "block" : "none")};
  opacity: ${(props) => (props.isvisible ? "1" : "0")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 300vh;
  transition: opacity 500ms;
  overflow: auto;
  z-index: 10;
`;

export const BackgroundOverlay = styled.div`
  background: rgba(0, 0, 0, 0.4);
`;

export const PopupContainer = styled.div`
  margin: auto auto;
  padding: 1rem;
  border-radius: 5px;
  width: 43rem;
  color: white;
  height: 100vh;

  h2 {
    margin-top: 0;
    color: white;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: white;

    &:hover {
      cursor: pointer;
      color: #000;
    }
  }

  .content {
    /* margin-top: 3.5%; */
    overflow: auto;
    margin-bottom: 100px;
  }

  @media screen and (max-width: 700px) {
    width: 70%;
  }
`;

export const PopupContentContainer = styled.div`
  border-left: 4px solid #5dd3b3;
  background-color: #384450;
  border-radius: 0px 10px 10px 0px;
  width: 43rem;
  /* padding: 10px; */
`;
export const BackgroundImageContainer = styled.div<{ bckimg: string }>`
  background: ${(props) =>
    props.bckimg
      ? `url(${props.bckimg})`
      : "linear-gradient(180deg, #576371 8.55%, #313c47 83.75%)"};
  background-size: contain;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  /* height: 10rem; */
  height: 20rem;
`;
export const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2.5rem 2.5rem 0 2.5rem;
`;
export const IconsDescContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  overflow-y: auto;
  padding: 1.25rem;
  max-height: 20rem;
  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5dd3b3;
    border-radius: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #2d3741;
    border-radius: 0.25rem;
  }
`;
export const CloseIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
export const HorizantalLine = styled.div<{ showline: string }>`
  display: ${(props) => (props.showline === "false" ? "none" : "")};
  border: 1px solid white;
  opacity: 0.2;
  /* margin-top: 1.5rem; */
`;
export const IconWrapper = styled.div`
  border-radius: 50%;
  cursor: pointer;
  width: 1.5625rem;
  height: 1.5625rem;
  border: 1px solid white;
  background-color: transparent;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
`;
export const RightIconsWrapper = styled.div`
  display: flex;

  column-gap: 0.63rem;
`;
export const PopupTitle = styled.div`
  color: white;
  font-family: "Core Rhino 65 Bold", sans-serif;
  font-size: 16px;
`;

export const IconAndTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 45px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.62rem;
`;
