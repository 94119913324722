import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Arrow,
  Select,
  SelectContainer,
  StyledInput,
  OptionsContainer,
  Options,
  MainOptionContainer,
  SubMenu,
  SubMenuItem
} from "./SelectInput.style";
import { ReactComponent as SvgPlygonIcon } from "../../assets/icons/Arrow.svg";
import { ReactComponent as SvgArrowIcon } from "../../assets/icons/arrow_simple_shock.svg";

import { InputSelectInterface } from "./SelectInputInterface";
import { LightTypography } from "../fonts/Fonts";
import { SvgEyeIcon } from "../Icons";
import { useQuizMyContext } from "../../contexts/CourseCreatorQuizContext";
import { useMyContext } from "../../contexts/CourseCreatorContext";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

const SelectInput = ({
  options,
  value = "",
  text = "",
  placeholder = "",
  onChange,
  onIdChange,
  width,
  optionWidth,
  fontSize,
  border,
  marginLeft,
  padding,
  height,
  icon: IconComponent,
  color,
  zIndex,
  display,
  redirectUrl,
}: InputSelectInterface) => {
  const { inputBorderColor, themeTextColor, bg_selectColor } = useRecoilValue(ColorSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(value);
  const [selectedOptionName, setSelectedOptionName] = useState<string | null>(value);
  const [selectedSubmenu, setSelectedSubmenu] = useState<{ title: string }[] | null>(null);
  const selectContainerRef = useRef<HTMLDivElement | null>(null); 
  const navigate = useNavigate();
  const {setEditQuiz} = useQuizMyContext();
  const {setEditSurvey} = useMyContext()

  useEffect(() => {
    setSelectedOption(value);
    const selectedOptionObject = options?.find((option) => option.title === value);
    setSelectedSubmenu(selectedOptionObject && selectedOptionObject.submenu ? selectedOptionObject.submenu : null);
  }, [value, options]);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => { // Specify the type of 'e' as MouseEvent
      if (selectContainerRef.current && !selectContainerRef.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("click", handleOutsideClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOptionClick = (option: any) => {
    if (option.value === "Create a new one") {
      navigate(redirectUrl || "/");
      setEditQuiz(false);
      setEditSurvey(false);
    } else if (option.submenu) {
      // If the clicked option has a submenu, close the submenu if it's already open
      if (selectedSubmenu && selectedSubmenu === option.submenu) {
        setSelectedSubmenu(null);
      } else {
        // If not, open the submenu
        setSelectedSubmenu(option.submenu);
      }
    } else {
      // If not, select the option
      setSelectedOption(option.title);
      if(onChange){
        onChange(option.title);
      }
      if(onIdChange){
        onIdChange(option.id);
      }
      setSelectedSubmenu(null);
      setIsOpen(false);
    }
  };

  const renderSubmenu = () => {
    if (selectedSubmenu) {
      return (
        <div>
          {selectedSubmenu.map((subOption, subIndex) => (
            <SubMenuItem key={subIndex} onClick={() => handleOptionClick(subOption)}>
              {subOption.title}
            </SubMenuItem>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <StyledInput
      width={width}
      isopen={isOpen.toString()}
      border={border}
      padding={padding}
      height={height}
      inputBorderColor={inputBorderColor}
      themeTextColor={themeTextColor}
    >
      <LightTypography
        color="rgba(255, 255, 255, 0.5)"
        text={text}
        fontSize="0.875rem"
      />

      <SelectContainer ref={selectContainerRef} zIndex={zIndex || 99} themeTextColor={themeTextColor}>
        <Select color={color || "#fff"} onClick={() => setIsOpen(!isOpen)}>
          {selectedOption ? (
            <MainOptionContainer fontSize={fontSize}>
              {IconComponent && <SvgEyeIcon />}
              {selectedOption}
            </MainOptionContainer>
          ) : (
            <div style={{color: themeTextColor, opacity: "0.5"}}>
              <LightTypography
                text={placeholder}
                fontSize="0.855rem"
              />
            </div>
          )}
          <Arrow marginLeft={marginLeft}>
            <SvgPlygonIcon />
          </Arrow>
        </Select>

        {isOpen && (
          <OptionsContainer optionWidth={optionWidth}>
            {options?.map((option, index) => (
              <div key={index} onClick={() => handleOptionClick(option)}>
                <Options
                  display={display || ""}
                  index={index}
                  lastitem={options.length - 1}
                  bg_selectColor={bg_selectColor}
                >
                  {option.submenu ? (
                    <>
                      <div>{option.title}</div>
                      <SvgArrowIcon fill={themeTextColor}/>
                    </>
                  ) : (
                    <div>{option.title}</div>
                  )}
                </Options>
              </div>
            ))}
            {selectedSubmenu && <SubMenu bg_selectColor={bg_selectColor}>{renderSubmenu()}</SubMenu>}
          </OptionsContainer>
        )}
      </SelectContainer>
    </StyledInput>
  );
};

export default SelectInput;
