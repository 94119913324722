import React, { useContext, useEffect, useRef, useState } from "react";
import {
  SvgVideoPaused,
  SvgVideoPlay,
  SvgsecondsPrev,
  Svgvideoplay2,
  SvgvidPause,
  SvgVolume,
  SvgvidMute,
  SvgChecked,
  SvgvidStretch,
  SvgvidExpand,
  SvgDownload,
} from "../../elements/Icons";
import { TypographyRegularDescription } from "../../elements/fonts/Fonts";
import {
  VideoPlayIcon,
  VideoControlsContainer,
  TimelineContainer,
  CurrentTimeLine,
  Controls,
  LeftRightControls,
  Btn,
  DurationCount,
  VolumeDiv,
  VolumeProgressBar,
  Volumebar,
  ButtonBox,
  PlayBackMenu,
  PlaybackOptionItem,
  PlyBtn,
} from "./VideoPlayer.style";
import {
  pauseBtnHandler,
  playBtnHandler,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  reversePlaybackHandler,
  forwardPlaybackHanlder,
  volumeChangeHandler,
  volumeHandler,
  playBackSpeedOptions,
  handleMouseUpDocument,
  handleTimeUpdate,
  fetchVideoAfterTrack,
} from "./VideoPlayerFunctions";
import { VideoPlayerControlProps } from "./VideoPlayerInterfaces";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { PointerContainer } from "../generateAttendance/GenerateAttendance.style";
export default function VideoControls({
  videoId,
  setInitialProgress,
  role,
  initialProgress,
  classId,
  videoDuration,
  videocontrols,
  setVideostate,
  playbackSpeedOption,
  videoPlayer,
  videoState,
  isTimelineOpen,
  closeTimeline,
  openTimeline,
  setPlaybackSpeedOption,
  stretch,
  setVideoDuration,
  videoLoaded,
  downloadable,
  vidPath,
}: VideoPlayerControlProps) {
  const { progressChanged, setProgressChange } = useContext(ClassContext);
  const { t } = useTranslation();
  const [playbackOptionsBox, setPlaybackOptionsBox] = useState(false);
  const [volumeState, setVolumeState] = useState(true);
  const [currVolume, setCurrVolume] = useState(0.5);
  const [fullScreen, setFullScreen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTimeLineWidth, setCurrentTimeLineWidth] = useState("0%");
  const [loading, setLoading] = useState(true);

  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  //stretching videocontainer
  const handleTimelineClick = () => {
    if (isTimelineOpen) {
      closeTimeline?.();
    } else {
      openTimeline?.();
    }
  };
  const progressRef = useRef(progress);
  progressRef.current = progress;
  useEffect(() => {
    setProgress(0);
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      const createTrack = async () => {
        try {
          const tenantName = localStorage.getItem("tenant");
          // setCurrentTime(playerRef.current.getCurrentTime());
          // setProgressChange((prev) => !prev);
          if (
            initialProgress !== 100 ||
            initialProgress === null ||
            initialProgress === null
          ) {
            // setProgressChange((prev) => !prev);
            let int = progressRef.current;
            const updateProgressRef = async () => {
              // setProgressChange((prev) => !prev);
              int = await fetchVideoAfterTrack({
                initialProgress,
                setInitialProgress,
                videoId,
                setLoading,
                subIdSplitted,
                provider,
              });
              if (int === undefined || int === null) {
                int = 0;
              }
              // else {
              if (progressRef.current > int || int === 0) {
                setInitialProgress(progressRef.current);
                const trackingData = {
                  classSourceId: classId,
                  length: videoPlayer.current?.duration,
                  percentage: progressRef.current, // Access the current value of progress using the ref
                  refId: videoId,
                  type: "Video",
                  subId: subIdSplitted,
                  provider: provider,
                };
                const trackingResponse = await axios.post(
                  `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/completionTracking/create/${tenantName}`,
                  { data: trackingData }
                );
                setProgressChange((prev) => !prev);
                // console.log("progressChanged", progressChanged)
              }
              // }
            };
            updateProgressRef();
          }
        } catch (error) {
          console.error("Error fetching document:", error);
        }
      };
      if (role === "student") createTrack();
    }, 10000);

    return () => clearInterval(interval);
  }, [videoId]);
  useEffect(() => {
    if (progressChanged) {
      // Perform actions that depend on the latest state here
    }
  }, [progressChanged]);
  // useEffect(() => {
  //     let isFirstPost = true; // Flag to track the first post

  //     const interval = setInterval(async () => {
  //         const createTrack = async () => {
  //             try {
  //                 const tenantName = localStorage.getItem("tenant");
  //                 const trackingData = {
  //                     classSourceId: classId,
  //                     length: videoPlayer.current?.duration,
  //                     percentage: progressRef.current, // Access the current value of progress using the ref
  //                     refId: videoId,
  //                     type: "Video",
  //                     subId: subIdSplitted
  //                 };
  //                 console.log("trackingData", trackingData);
  //                 const response = await axios.post(
  //                     `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/completionTracking/create/${tenantName}`,
  //                     { data: trackingData }
  //                 );
  //                 console.log("response from createtrack", response);

  //                 // Check if it's the first post, and if so, modify the interval
  //                 if (isFirstPost) {
  //                     clearInterval(interval); // Clear the current interval
  //                     isFirstPost = false; // Update the flag
  //                     // Set a longer interval for subsequent posts
  //                     setInterval(createTrack, 15000); // Modify the interval as needed
  //                 }
  //             } catch (error) {
  //                 console.error("Error fetching document:", error);
  //             }
  //         };
  //         createTrack();
  //     }, 10000);

  //     return () => clearInterval(interval);
  // }, [videoId]);

  //duration handler with dragging event
  useEffect(() => {
    if (videoPlayer.current) {
      //in case of previous progress data it should be sent to the backend the time and the progress
      const timeUpdateHandler = () =>
        handleTimeUpdate({
          videoPlayer,
          progress,
          setProgress,
          setVideoDuration,
          setCurrentTimeLineWidth,
        });
      videoPlayer.current.addEventListener("timeupdate", timeUpdateHandler);
      return () => {
        if (videoPlayer.current) {
          videoPlayer.current.removeEventListener(
            "timeupdate",
            timeUpdateHandler
          );
        }
      };
    }
  }, [videoPlayer.current, videoId, videoPlayer, videoLoaded]);

  // Add event listeners for mouseup and remove them on component unmount
  useEffect(() => {
    document.addEventListener("mouseup", () => {
      handleMouseUpDocument({ setIsDragging });
    });
    return () => {
      document.removeEventListener("mouseup", () => {
        handleMouseUpDocument({ setIsDragging });
      });
    };
  }, [videoId]);
  //full screen
  useEffect(() => {
    if (videoPlayer.current) {
      if (fullScreen) {
        videoPlayer.current.requestFullscreen().catch((err) => {
          console.log("Error full screen");
        });
      }
    }
  }, [fullScreen]);
  //volume
  useEffect(() => {
    if (videoPlayer.current) {
      videoPlayer.current.volume = currVolume;
    }
  }, [currVolume]);
  //speedOptions
  useEffect(() => {
    if (videoPlayer.current) {
      videoPlayer.current.playbackRate = playbackSpeedOption || 1.0;
    }
  }, [playbackSpeedOption]);
  return (
    <>
      <VideoPlayIcon>
        {!videoLoaded ? (
          // <img width={"100%"} src={loadingGif} alt="" />
          <TypographyRegularDescription
            text={`${t("general.loading")}...`}
            fontSize="0.75rem"
          />
        ) : (
          <>
            {videocontrols && (
              <>
                {videoState ? (
                  <SvgVideoPaused
                    onClick={() =>
                      pauseBtnHandler({ videoPlayer, setVideostate })
                    }
                  />
                ) : (
                  <SvgVideoPlay
                    onClick={() =>
                      playBtnHandler({ videoPlayer, setVideostate })
                    }
                  />
                )}
              </>
            )}
          </>
        )}
      </VideoPlayIcon>

      <>
        {videoLoaded && (
          <VideoControlsContainer id="videoControlsContainer">
            {/* <TimelineContainer
                            onMouseDown={(e) => { handleMouseDown(e, { setIsDragging }); }}
                            onMouseMove={(e) => { handleMouseMove(e, { videoPlayer, isDragging }); }}
                            onMouseUp={(e) => { handleMouseUp({ setIsDragging }); }}
                        >

                            <CurrentTimeLine id="currentTimeLine" style={{ width: currentTimeLineWidth }}></CurrentTimeLine>
                        </TimelineContainer> */}

            <Controls>
              <LeftRightControls>
                {/* back 10 seconds */}
                <Btn>
                  {" "}
                  <SvgsecondsPrev
                    onClick={() => {
                      reversePlaybackHandler({ videoPlayer, videoState });
                    }}
                  />{" "}
                </Btn>
                {/* playpause button */}
                <Btn>
                  {" "}
                  {!videoState ? (
                    <Svgvideoplay2
                      onClick={() =>
                        playBtnHandler({ videoPlayer, setVideostate })
                      }
                    />
                  ) : (
                    <SvgvidPause
                      onClick={() =>
                        pauseBtnHandler({ videoPlayer, setVideostate })
                      }
                    />
                  )}{" "}
                </Btn>
                {/*  forward 10 seconds*/}
                <Btn>
                  {" "}
                  <SvgsecondsPrev
                    transform="rotate(180)"
                    onClick={() => {
                      forwardPlaybackHanlder({ videoPlayer, videoState });
                    }}
                  />{" "}
                </Btn>
                {/* duration */}
                <DurationCount>
                  {" "}
                  <TypographyRegularDescription
                    text={`${videoDuration.current}/${videoDuration.total}`}
                    fontSize="0.75rem"
                  />{" "}
                </DurationCount>
              </LeftRightControls>
              <LeftRightControls>
                {/* captions options  */}
                {/* <div id="captionsBox" className="captionsBox">
                                <Btn> <SvgTranscript /> </Btn>
                            </div> */}
                {/* volume option */}
                {downloadable !== 0 && (
                  <a href={vidPath} download={true}>
                    <SvgDownload />
                  </a>
                )}
                <VolumeDiv>
                  <VolumeProgressBar>
                    <Volumebar
                      type="range"
                      min={0}
                      max={1}
                      value={currVolume}
                      step={0.1}
                      onChange={(e) => {
                        volumeChangeHandler(e, {
                          videoPlayer,
                          setCurrVolume,
                          setVolumeState,
                        });
                      }}
                    />
                  </VolumeProgressBar>
                  <Btn>
                    {volumeState ? (
                      <SvgVolume
                        onClick={() => {
                          volumeHandler({
                            videoPlayer,
                            setCurrVolume,
                            setVolumeState,
                          });
                        }}
                      />
                    ) : (
                      <SvgvidMute
                        onClick={() => {
                          volumeHandler({
                            videoPlayer,
                            setCurrVolume,
                            setVolumeState,
                          });
                        }}
                      />
                    )}
                  </Btn>
                </VolumeDiv>
                {/* speed options */}

                <ButtonBox
                  id="buttonBox"
                  onClick={() =>
                    setPlaybackOptionsBox((prevValue) => !prevValue)
                  }
                >
                  {playbackOptionsBox ? (
                    <PlayBackMenu>
                      {playBackSpeedOptions?.map((item, id) => {
                        if (item) {
                          return (
                            <PlaybackOptionItem
                              isactive={item.value === playbackSpeedOption}
                              key={`playbackoption-${id}`}
                              id={`playbackoption-${id}`}
                              onClick={() => setPlaybackSpeedOption(item.value)}
                            >
                              {item.key}
                              {item.value === playbackSpeedOption && (
                                <SvgChecked />
                              )}
                            </PlaybackOptionItem>
                          );
                        }
                      })}
                    </PlayBackMenu>
                  ) : null}
                  <PlyBtn data-div="playback"> {playbackSpeedOption}x </PlyBtn>
                </ButtonBox>
                {/* stretching the player */}
                {stretch && (
                  <Btn>
                    {" "}
                    <SvgvidStretch onClick={handleTimelineClick} />{" "}
                  </Btn>
                )}
                {/* full screen */}
                <Btn onClick={() => setFullScreen((p) => !p)}>
                  {" "}
                  <SvgvidExpand />{" "}
                </Btn>
              </LeftRightControls>
            </Controls>
          </VideoControlsContainer>
        )}
      </>
    </>
  );
}
