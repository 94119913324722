import React from "react";
import { MenuTeacherBox } from "./MenueOption.style";
import { SvgEmail } from "../../../../elements/Icons";
import { Typography } from "../../../../elements/fonts/Fonts";
import { MenuOptionStudentsInterface } from "./MenueOptionStudents";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../../recoil/ThemeSelectors";

const MenuOptionTeacher = ({ MessageClick }: MenuOptionStudentsInterface) => {
  const { bg_selectColor } = useRecoilValue(ColorSelector);
  return (
    <MenuTeacherBox onClick={MessageClick} bgColor={bg_selectColor}>
      <SvgEmail width="1rem" height="0.75rem" />
      <Typography text="Message" fontSize="0.875rem" />
    </MenuTeacherBox>
  );
};

export default MenuOptionTeacher;
