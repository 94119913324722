import styled from "styled-components";

export const MainWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: start;
  & > :last-child {
    margin-top: 0.62rem;
  }
`;

export const LineDecoration = styled.div`
  width: 1.4375rem;
  height: 0.1875rem;
  background-color: ${({ theme }) => theme.dark.main_color};
`;

export const NumberHolder = styled.div`
  height: 2.1875rem;
  width: fit-content;
  display: flex;
  padding: 0 0.125rem;
  margin-top: 0.31rem;
  align-items: center;
`;
