import React from "react";
import { CheckBox } from "./CheckboxSquare.style";
import { CheckBoxInterface } from "./CheckboxSquareInterface";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

const CheckboxSquare = ({ name, value, checked, onChange, disabled }: CheckBoxInterface) => {
  const { checkboxBgColor,checkboxBorderColor,checkboxBorderRadius , theme, inputBorderColor } = useRecoilValue(ColorSelector);
  return (
    <CheckBox
      type="checkbox"
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      
   bgcolor={checkboxBgColor}
   bordercolor={checkboxBorderColor}
   borderradius={checkboxBorderRadius}
      theme={theme}
      inputbordercolor={inputBorderColor}
      disabled={disabled}
    />
  );
};

export default CheckboxSquare;
