import React, { ReactElement } from "react";
import MainSurveyReusableTable from "../SurveyReusableTable/MainSurveyReusableTable";
import { SurveyComplexStudent } from "./SurveyComplexProfileTable.Data";
import { SurveyComplexProfileTableContainer } from "../../AnalyticsAllAssigment.style";
import { StudentCompletionTracingIMG } from "../../../StudentCompletionTracing/StudentCompletionTracing.style";
import { Typography } from "../../../../elements/fonts/Fonts";
import { ExcelDownload } from "../../../../utils/ExcelGlobalFunction";

/**
 * Interface for the props of SurveyComplexProfileTable component.
 */
interface SurveyComplexProfileTableInterface {
  Data: SurveyComplexStudent[]; // Array of survey complex student data.
  Anonymous?: boolean; // Flag indicating if data is anonymous (optional).
  TitleClick?: () => void; // Function triggered on title click (optional).
}

/**
 * SurveyComplexProfileTable Component
 * @param {SurveyComplexProfileTableInterface} props - Props for SurveyComplexProfileTable component.
 * @returns {ReactElement} - SurveyComplexProfileTable component.
 */
const SurveyComplexProfileTable = ({
  Data,
  Anonymous,
  TitleClick,
}: SurveyComplexProfileTableInterface): ReactElement => {
  return (
    <MainSurveyReusableTable
      marginTop="0.8rem"
      onClickExcelDowload={() => ExcelDownload(Data, "Data")}
      data={Data}
      title="Responses Details"
      SelectedNameTitleLabel={Anonymous ? "Submission ID" : "Students"}
      titleClick={TitleClick}
      FistElementChanges={Data.map((item, index) =>
        Anonymous ? (
          <Typography key={index} text={item.studentId} fontSize="0.875rem" />
        ) : (
          <SurveyComplexProfileTableContainer key={index}>
            <StudentCompletionTracingIMG
              src={item.StudentProfile}
              alt="no img"
            />
            <Typography text={item.StudentName || ""} fontSize="0.875rem" />
          </SurveyComplexProfileTableContainer>
        )
      )}
    />
  );
};

export default SurveyComplexProfileTable;
