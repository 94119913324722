import styled from "styled-components";
import { breakpoints } from "../../../theme";
import { SvgArrowDown } from "../../../elements/Icons";

export const GradeBookWrapper = styled.div`
  /* padding:1rem; */
  width: 100%;
  height: 100%;
  border-radius: 0rem 0.625rem 0.625rem 0rem;
  background: #384450;
  border-left: 6px solid #5dd3b3;
  @media (max-width: 700px) {
    width: auto;
  }
`;

export const GradeBookBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.63rem;
  padding: 1.88rem 1.69rem 1.38rem 2.38rem;
`;

export const ExitRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  /* margin-bottom: 2.31rem; */
  color: #fff;
`;
export const TitleRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* margin-bottom: 2.31rem; */
`;

export const TitleGroupLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 0.94rem;
  margin-bottom: 1.56rem;
`;
export const InputSegmentName = styled.input.attrs({
  id: "title",
  type: "text",
})`
  width: 99%;
  outline: none;
  color: white;
  height: 2.8125rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  background: transparent;
  padding-left: 1.25rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: ${(props) => props.theme.font.primaryLight};
  &::placeholder {
    color: #fff;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: ${(props) => props.theme.font.primaryLight};
  }
`;
export const SegmentNameLabel = styled.label.attrs({ htmlFor: "title" })`
  /* margin-bottom: 0.63rem; */
  display: flex;
`;

export const InputPercentage = styled.input.attrs({
  id: "perc",
  type: "number",
})`
  position: relative;
  outline: none;
  color: #fff;
  appearance: none;
  -webkit-appearance: none;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  /* line-height: normal; */
  font-family: ${(props) => props.theme.font.primaryLight};
  width: 99%;
  height: 50%;
  border-radius: 0.625rem;
  border: none;
  background: transparent;
  padding-top: 0.88rem;
  padding-bottom: 0.88rem;
  padding-left: 1.25rem;
  line-height: 1;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: #fff;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    font-family: ${(props) => props.theme.font.primaryLight};
    display: flex;
    justify-content: flex-start;
    height: 100%;
  }
`;

export const PercentageLabel = styled.span`
  /* position: absolute;
top: 44.6%;
left: 66.5%; */
  /* position: fixed; */
  /* display: flex;
flex-direction: row; */
  margin-top: 3.2rem;
  margin-left: 33rem;
`;

export const DIV = styled.div`
  display: -ms-flexbox; /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
`;

export const InputMaxHolder = styled.div`
  width: 99%;
  background: transparent;
  height: 50%;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1.25rem;
`;

export const InputMaxGrade = styled.input.attrs({
  id: "MaxGrade",
  type: "number",
})`
  outline: none;
  color: #fff;
  appearance: none;
  -webkit-appearance: none;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  /* line-height: normal; */
  background: transparent;

  font-family: ${(props) => props.theme.font.primaryLight};
  background-image: url("/assets/icons/percent.svg");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 100px;
  /* width: 99%; */
  width: 50%;
  height: 50%;
  border-radius: 0.625rem;
  border: none;
  padding-top: 0.88rem;
  padding-bottom: 0.88rem;
  padding-left: 1.25rem;
  line-height: 1;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: #fff;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    font-family: ${(props) => props.theme.font.primaryLight};
    display: flex;
    justify-content: flex-start;
    height: 100%;
  }
`;
export const PercLabel = styled.label.attrs({ htmlFor: "perc" })`
  /* margin-bottom: 0.63rem; */
  /* margin-top: 1.25rem; */
  display: flex;
`;
export const MaxGradeLabel = styled.label.attrs({ htmlFor: "MaxGrade" })`
  margin-bottom: 0.63rem;
  /* margin-top: 1.25rem; */
  display: flex;
`;
export const SelectNbOfGradesLabel = styled.label.attrs({
  htmlFor: "MaxGrade",
})`
  /* margin-bottom: 0.63rem; */
  /* margin-top: 1.25rem; */
  display: flex;
`;
export const SelectNbOfGrades = styled.select`
  width: 99%;
  outline: none;
  color: white;
  height: 2.8125rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  /* opacity: 0.4; */
  background-color: transparent;
  background-image: url("/assets/icons/arrow.svg");

  padding-left: 1.25rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: ${(props) => props.theme.font.primaryLight};
  appearance: none;
  padding-right: 40px; /* Adjust the padding as needed */

  /* Style the custom arrow icon */
  background-position: 96.7%;
  background-repeat: no-repeat;
  background-size: 10px;

  &::after {
  }
`;
export const OptionsNbOfGrades = styled.option`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: none;
  background-color: #384450;
  padding: 1rem;
  line-height: normal;
  &:hover {
    color: #384450;
    background-color: #384450;
  }
`;
export const TitleRadio = styled.div``;
export const RadioButtonsRow = styled.div`
  display: flex;
  gap: 1.87rem;
`;

export const RadioButton1 = styled.input`
  width: 0.875rem;
  height: 0.875rem;
  border: 0.05em solid #fff;
  border-radius: 50%;
  margin-right: 0.5em;
  background: linear-gradient(to bottom, #2f353d, #545f6d);
  border-radius: 50%;
  appearance: none;
  /* opacity: 0; */
  /* position: absolute;
  transform: translate(-50%, -50%); */
  &:checked::before {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
    display: flex;
    justify-content: center;
    margin-left: -10%;
    margin-top: -10%;
    align-items: center;
    color: #fff;
    border: 0.05em solid #5dd3b3;
    content: "";
  }

  /* &:hover ::before {
  
    background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
  }

  &:checked::before {
    background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
    background-color:red;
    display:flex;
    justify-content:center;
    align-items:center;
    color: #fff;;
    border: .05em solid #5DD3B3;
    content: "\\2713";
  
  }

  :focus::before {
    background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
    border: .05em solid #5DD3B3;
  } */
`;

export const CheckboxLabel = styled.label`
  display: flex;
  /* align-items: center; */
  cursor: pointer;
  /* &::before{
    content:"";
    width:1em;
    min-width:1em;
    max-width:1em;  
    height:1em;
    min-height:1em;
    max-height:1em;
    border: .05em solid #fff;
    border-radius: 50%; 
    margin-right:.5em;
    background: linear-gradient(to bottom, #2F353D, #545F6D); */
  @media (max-width: ${breakpoints.tablets}) {
    margin-top: 0.2rem;
    &:dir(ltr) {
      margin-right: 0.6rem;
    }
    &:dir(rtl) {
      margin-left: 0.6rem;
    }
  }
  /* } */
  /* &:hover:before{
  background-color:#35e07d;
  border: .05em solid #5DD3B3;
  background: linear-gradient(to bottom, #5dd3b3, #2f3b40); */
  /*   background-color:red; */
  /* } */
  /* ${RadioButton1}:checked + &::before {
    background-color:#35e07d;
  border: .05em solid #5DD3B3;
  background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
  } */
`;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.62rem;
  margin-top: 1.12rem;
`;
export const ButtonCancel = styled.button`
  width: 8.8125rem;
  height: 2.125rem;

  flex-shrink: 0;
  border-radius: 1.25rem;
  border: 1px solid #d85d66;
  background: none;
  cursor: pointer;
`;
export const ButtonAdd = styled.button`
  outline: none;
  border: none;
  width: 8.8125rem;
  height: 2.125rem;

  flex-shrink: 0;
  border-radius: 1.25rem;
  background: #5dd3b3;
  cursor: pointer;
`;

export const LeftBorderColor = styled.div`
  width: 37.75rem;
  height: 1rem;
  transform: rotate(90deg);
  stroke-width: 4px;
  color: #5dd3b3;
  position: absolute;
  top: 10%;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3; /* Ensure it's above other content */
  @media (max-width: 700px) {
    /* width: 50%; */
  }
`;

export const ModalContent = styled.div`
  background: #384450;
  /* padding: 20px; */
  width: 42%;
  height: auto;
  min-width: 20rem;
  min-height: 35rem;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;
