import React, { FC,useState } from 'react';
import { AssignmentRubricContainer, Header, TableContainer, TableCellStructure, TableHeaderStructure, TableHeaderContent, AssignmentCells, TableCellContent,BreakLine } from './AssignmentRubric.style';
import { TypographyRegularDescription, Typography } from '../../../elements/fonts/Fonts';
import { theme } from '../../../theme';
import { AssignmentRubricProps } from '../AssignmentRubricInterface';

const AssignmentRubric: FC<AssignmentRubricProps> = ({ marks }) => {
    const structureMarks = marks.filter((mark) => mark.type === 'structure');
    const contentMarks = marks.filter((mark) => mark.type === 'content');
    const [selectedItemStructure, setSelectedItemStructure] = useState<number>(-1);
    const [selectedItemContent, setSelectedItemContent] = useState<number>(-1);

    const handleItemStructureClick = (index:number) => {
        setSelectedItemStructure(index);
    };
    const handleItemContentClick = (index:number) => {
        setSelectedItemContent(index);
    };
    return (
        <AssignmentRubricContainer>
            <Header>
                <TypographyRegularDescription text='Criteria' color={theme.dark.primary_text_color} fontSize='1rem' />
                <TypographyRegularDescription text='Ratings' color={theme.dark.primary_text_color} fontSize='1rem' />
            </Header>
            <TableContainer>

                <TableHeaderStructure><TypographyRegularDescription text='Assignment Structure' color={theme.dark.primary_text_color} fontSize='0.875rem' lineHeight='2rem' /></TableHeaderStructure>
                <AssignmentCells>
                    {structureMarks.map((mark, index) => (
                        <TableCellStructure 
                        key={index}  
                        lastitemstructure={index === structureMarks.length - 1}
                        lastitemcontent={index === contentMarks.length - 1}
                        isselected={index === selectedItemStructure}
                        onClick={() => handleItemStructureClick(index)}
                        >
                            <Typography text={`${mark.mark} pts`} color={theme.dark.primary_text_color} fontSize='0.75rem' />
                            <TypographyRegularDescription text={mark.markStatus} fontSize='0.75rem' />

                        </TableCellStructure>
                    ))}
                </AssignmentCells>
                </TableContainer>
               <BreakLine/>
               <TableContainer>
                <TableHeaderContent><TypographyRegularDescription text='Assignment Content' color={theme.dark.primary_text_color} fontSize='0.875rem' lineHeight='2rem' /></TableHeaderContent>
                <AssignmentCells>
                    {contentMarks?.map((mark,index) => (
                    <TableCellContent 
                    key={index}
                    lastitemcontent={index === contentMarks.length - 1}
                    lastitemstructure={index === structureMarks.length - 1}
                    isselected={index === selectedItemContent}
                    onClick={() => handleItemContentClick(index)}
                    >
                          <Typography text={`${mark.mark} pts`} color={theme.dark.primary_text_color} fontSize='0.75rem' />
                            <TypographyRegularDescription text={mark.markStatus} fontSize='0.75rem' />
                        </TableCellContent>
                    ))}
                </AssignmentCells>
            </TableContainer>
        </AssignmentRubricContainer>
    )
}

export default AssignmentRubric;