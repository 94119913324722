import React, { useState } from "react";
import { PageHeaderMessageContainer } from "./PageHeaderMessage.style";
import ProfileRow from "./ProfileRow/ProfileRow";
import PageHeaderChats from "../PageHeaderChats/PageHeaderChats";
import { ContactMessagesPopUp } from "../../ContactMessages/ContactMessagesPopUp";
import useClickOutside from "../../../hooks/useClickOutside";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";

interface PageHeaderMessageIterface {
  setShowMessagePOPUP: React.Dispatch<React.SetStateAction<boolean>>;
  ShowMessagePOPUP: boolean;
}

function PageHeaderMessage({
  setShowMessagePOPUP,
  ShowMessagePOPUP,
}: PageHeaderMessageIterface) {
  const [ShowFriendsList, setShowFriendsList] = useState<boolean>(false);
  const containerRef = useClickOutside(ShowFriendsList, () =>
    setShowFriendsList(false)
  );

  const [selectedFriends, setSelectedFriends] = useState<string[]>([]);

  const handleFriendSelect = (friendId: string) => {
    setSelectedFriends((prevSelectedFriends) => {
      if (prevSelectedFriends.includes(friendId)) {
        return prevSelectedFriends.filter((id) => id !== friendId);
      } else {
        return [...prevSelectedFriends, friendId];
      }
    });
  };
  const { backgroundColor, themeTextColor } = useRecoilValue(ColorSelector);
  return (
    <>
      {ShowMessagePOPUP && (
        <PageHeaderMessageContainer
          bgColor={backgroundColor}
          color={themeTextColor}
        >
          <ProfileRow
            setShowMessagePOPUP={setShowMessagePOPUP}
            ShowFriendsList={ShowFriendsList}
            setShowFriendsList={setShowFriendsList}
          />
          {ShowFriendsList && (
            <ContactMessagesPopUp PageHeaderclosed={setShowMessagePOPUP} />
          )}
        </PageHeaderMessageContainer>
      )}
      {/* <PageHeaderChats onFriendSelect={handleFriendSelect} /> */}
      {/* {selectedFriends.map((friendId) => (
        <PageHeaderChats key={friendId} friendId={friendId} />
      ))} */}
    </>
  );
}

export default PageHeaderMessage;
