import React, { FC, useState } from "react";
import {
  EventMainContainer,
  EventContainer,
  EventsContent,
  DateTimeContainer,
  MenuIconContainer,
} from "./EventCard.style";
import { Typography, LightTypography } from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import PopUp from "../../../elements/Popup/PopUp";
import { EventCardProps } from "./EventCardInterface";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/noun_event_20805481.svg";
import { ReactComponent as MenuIcon } from "../../../assets/icons/menu_3.svg";
import { SvgAssignmentIcon, SvgDiscussion, SvgEventLogo, SvgOnlineSessionIcon, SvgQuiz, SvgSurveyIcon, SvgTeams } from "../../../elements/Icons";

const EventCard: FC<EventCardProps> = ({ events, index}) => {
  const [isClicked, setisClicked] = useState<boolean>(false);
  const colors = ["#D04B4B", "#5D69FD", "#DB56A3", "#5DD3B3", "#3DA4FC"];
  

  const getEventIcon = (eventType: string) => {
    switch (eventType) {
      case "Survey":
        return <SvgSurveyIcon width={25} height={14}  fill="white" />;
      case "OnlineSession":
  
        return <SvgOnlineSessionIcon width={25} height={14}  fill="white" />;
      case "Quiz":
        // Replace this with your SVG component for quizzes
        return < SvgQuiz width={25} height={14}  fill="white"/>;
      case "Assignment":
        return <SvgAssignmentIcon width={25} height={14}  fill="white"/>;
      case "Forum":
        return <SvgDiscussion width={25} height={14}  fill="white"/>;
      case "Teams":
        return <SvgTeams width={25} height={14}/>;
      default:
        return <SvgEventLogo width={25} height={14} fill="white" />;
    }
  };

 




  return (
    <>
      <EventMainContainer>
      
      <div>
        {getEventIcon(events.type)}
        </div>
        <Typography text={`${events.title}`} fontSize="1rem"   whiteSpace="nowrap"
 
  />
      </EventMainContainer>
    </>
  );
};

export default EventCard;
