import axios from "axios";

const BaseURL = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/";

export const get = async (path: string, provider?: string) => {
  const response = provider ? await axios.get(`${BaseURL}${path}?provider=${provider}`) : await axios.get(`${BaseURL}${path}`);
  return response.data;
};

export const post = async (path: string, body: any) => {
  try {
    const tenantName = localStorage.getItem("tenant");
    const response = await axios.post(`${BaseURL}${path}`, {
      tenantName: tenantName,
      data: body,
    });
    return response.data;
  } catch (error) {
    console.error("Error in PUT request:", error);
    // throw error;
  }
};

export const put = async (path: string, body: any) => {
  try {
    const response = await axios.put(`${BaseURL}${path}`, {
      data: body,
    });
    return response.data;
  } catch (error) {
    console.error("Error in PUT request:", error);
    throw error; // Rethrow the error to be handled by the calling service
  }
};

export const patch = async (path: string, body: any) => {
  try {
    const response = await axios.patch(`${BaseURL}${path}`, {
      data: body,
    });
    return response.data;
  } catch (error) {
    console.error("Error in PUT request:", error);
    throw error; // Rethrow the error to be handled by the calling service
  }
};

export const del = async (path: string) => {
  const response = await axios.delete(`${BaseURL}${path}`);
  return response.data;
};
