import styled from "styled-components";
import { breakpoints } from "../../../theme";

interface SectionWrapperProps {
  bgimage: string;
  theme?: string;
}

export const MainHeroSection = styled.div`
  position: relative;
  width: 100%;
  min-height: 16.6875rem;
  height: auto;
  object-fit: contain;
  margin-bottom: 0.76rem;
  grid-column: 1/5;
  @media (max-width: ${breakpoints.medium}) {
    order: 3;
  }
  @media (max-width: ${breakpoints.tablets}) {
    min-height: 33.125rem;
  }
  @media (max-width: ${breakpoints.mobile}) {
    min-height: 37rem;
  }
  @media (max-width: calc(${breakpoints.mobile} - 150px)) {
    min-height: 48rem;
  }
`;
interface SectionHolderBgProps {
  bgcolor: string;
  boxshadow: string;
}

export const SectionHolderBg = styled.section<SectionHolderBgProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  box-shadow: ${(props) => props.boxshadow};
  background: ${(props) => props.bgcolor};

  width: 100%;
  height: 100%;
  min-height: 16.6875rem;
  border-radius: 10px;
`;

export const SectionWrapper = styled.div<SectionWrapperProps>`
  width: 100%;
  height: 100%;
  min-height: 16.6875rem;
  border-radius: 10px;
  background-image: url(${(props) => props.bgimage});
  filter: contrast(110%);
  object-fit: cover;
  background-size: 100%;
  background-position: center;
  mix-blend-mode: ${(props) =>
    props.theme === "light" ? "normal" : "multiply"};
  opacity: ${(props) => (props.theme === "light" ? "0.87" : "1")};
  background-repeat: no-repeat;

  @media (max-width: ${breakpoints.tablets}) {
    background-size: 210%;
  }
  @media (max-width: 550px) {
    background-size: 270%;
  }
  @media (max-width: ${breakpoints.mobile}) {
    background-size: 380%;
  }
  /* @media (max-width: ${breakpoints.extraLarge}) {
    background-size: 130%;
  }
  @media (max-width: ${breakpoints.large}) {
    background-size: 170%;
  }
  @media (max-width: ${breakpoints.medium}) {
    background-size: 180%;
  }
  @media (max-width: ${breakpoints.small}) {
    background-size: 240%;
  }
  @media (max-width: ${breakpoints.tablets}) {
    background-size: 700%;
  }
  @media (max-width: ${breakpoints.mobile}) {
    background-size: 1050%;
  }
  @media (max-width: calc(${breakpoints.mobile} - 105px)) {
    background-size: 1100%;
  }
  @media (max-width: calc(${breakpoints.mobile} - 120px)) {
    background-size: 1300%;
  }
  @media (max-width: calc(${breakpoints.mobile} - 150px)) {
    background-size: 2050%;
  } */
`;
