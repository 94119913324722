export const getResourceType = (fileName: string) => {
  const fileExtension = fileName.split(".").pop()?.toLowerCase();

  switch (fileExtension) {
    case "doc":
    case "docx":
    case "xlsx":
    case "xls":
    case "pptx":
    case "ppt":
    case "pdf":
    case "rar":
    case "exe":
      return "Doc";
    case "mp4":
    case "mov":
      return "Vid";
    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
      return "Pic";
  }
};
