import React, { useState } from "react";
import { Container } from "../../elements/StyledContainerForm/StyledContainerForm.style";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { FlexBetween, FlexDiv } from "../../globalStyles";
import { SvgImagesIcon, SvgVideo2 } from "../../elements/Icons";
import {
  LightTypography,
  PrimaryFontBoldText,
} from "../../elements/fonts/Fonts";
import { ButtonsDiv, LabelDiv } from "../VideoForm/VideoForm.style";
import { t } from "i18next";
import StyledInput from "../../elements/StyledInput/StyledInput";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import { DropZoneForm } from "../../elements/dropZone/DropZoneForm";
import isObjectEmpty from "../../utils/IsObjectEmpty";
import CancelButton from "../../elements/StyledButton/CancelButton";
import {
  ContentFormProps,
  FileInterface,
} from "../../interfaces/ContentFormProps";
import ImageForm from "../ImageForm/ImageForm";
import VideoForm from "../VideoForm/VideoForm";

const MediaForm = ({
  topicContents,
  setShow,
  handleSubmit,
  setShowEditContent,
  showEditContent,
  setContentTitleInTopic,
  editContent,
  newContentTitle,
  contentId,
  activeGetModulesWithTopics,
  newTopicRefId,
}: ContentFormProps) => {
  const [mediaName, setMediaName] = useState("");
  const [mediaDescription, setMediaDescription] = useState("");
  const [error, setError] = useState(false);
  const [mediaFile, setMediaFile] = useState<FileInterface>({ path: "" });
  const [currentView, setCurrentView] = useState("media");
  const { backgroundColor, themeTextColor, inputBorderColor } =
    useRecoilValue(ColorSelector);

  // Function to handle description
  const handleDescriptionChange = (value: any) => {
    setMediaDescription(value);
  };

  // function to handle video and s3 upload
  const handleFile = async (value: any) => {
    setMediaFile(value);
    const fileExtension = value.path.split(".").pop();

    switch (fileExtension) {
      case "jpg":
      case "jpeg":
      case "png":
        setCurrentView("image");
        break;
      case "mp4":
      case "mov":
        setCurrentView("video");
    }
  };

  const handleContinueClick = async () => {
    if (!mediaName || !mediaDescription || isObjectEmpty(mediaFile) === true) {
      setError(true);
    } else {
      setError(false);
    }
  };
  const accF: { [key: string]: string[] } = {
    "video/mp4": [".mp4"],
    "video/quicktime": [".mov"],
    "image/jpeg": [".jpg", ".jpeg"],
    "image/png": [".png"],
    "image/webp": [".webp"],
  };

  return (
    <>
      {currentView === "media" ? (
        <Container
          themetextcolor={themeTextColor}
          backgroundcolor={backgroundColor}
          scrollbar={inputBorderColor}
        >
          {/* header */}
          {/* <FlexBetween>
            <FlexDiv>
              <SvgImagesIcon />
              <PrimaryFontBoldText text={t("forms.Media")} fontSize="1.125rem" />
            </FlexDiv>
          </FlexBetween> */}

          {/* title */}
          <LabelDiv>
            <FlexDiv gap="0.25rem">
              <LightTypography text={t("forms.Title")} fontSize="0.875rem" />
              <span>*</span>
            </FlexDiv>
            <StyledInput
              placeholder={t("forms.Enter Media Title")}
              type="text"
              onChange={(e: any) => {
                setMediaName(e.target.value);
              }}
              value={mediaName}
              width="100%"
            />
            {!mediaName && error && (
              <ErrorDiv>{`${t("forms.Please")} ${t(
                "Enter Media Title"
              )}`}</ErrorDiv>
            )}
          </LabelDiv>

          {/* description */}
          <LabelDiv>
            <FlexDiv gap="0.25rem">
              <LightTypography
                text={t("forms.Description")}
                fontSize="0.875rem"
              />
              <span>*</span>
            </FlexDiv>
            <TinyEditor
              onChange={handleDescriptionChange}
              placeholder={t("forms.Description")}
              height="13.25rem"
            />
            {!mediaDescription && error && (
              <ErrorDiv>{`${t("forms.Please")} ${t(
                "forms.Enter Description"
              )}`}</ErrorDiv>
            )}
          </LabelDiv>

          {/* dropzone */}
          <DropZoneForm
            text={`${t("forms.Click or Drag and Drop, to upload Media")} *`}
            testDrop={handleFile}
            acceptedTypes={accF}
          />
          {isObjectEmpty(mediaFile) === true && error && (
            <ErrorDiv>{t("Please Select a Media")}</ErrorDiv>
          )}

          {/* actions buttons */}
          <ButtonsDiv>
            <CancelButton
              name={t("forms.Cancel")}
              onClickFunction={() => {
                setShow(false);
              }}
              color="#D85D66"
              borderColor="#D85D66"
            />
            <CancelButton
              name={t("forms.Save")}
              onClickFunction={handleContinueClick}
              color="#fff"
              borderColor="#5DD3B3"
              backgroundColor="#5DD3B3"
            />
          </ButtonsDiv>
        </Container>
      ) : currentView === "image" ? (
        <ImageForm
          topicContents={topicContents}
          setShow={setShow}
          handleSubmit={handleSubmit}
          setShowEditContent={setShowEditContent}
          showEditContent={showEditContent}
          setContentTitleInTopic={setContentTitleInTopic}
          editContent={editContent}
          newContentTitle={newContentTitle}
          contentId={contentId}
          activeGetModulesWithTopics={activeGetModulesWithTopics}
          newTopicRefId={newTopicRefId}
          mediaName={mediaName}
          mediaDescription={mediaDescription}
          mediaFile={mediaFile}
        />
      ) : (
        <VideoForm
          topicContents={topicContents}
          setShow={setShow}
          handleSubmit={handleSubmit}
          setShowEditContent={setShowEditContent}
          showEditContent={showEditContent}
          setContentTitleInTopic={setContentTitleInTopic}
          editContent={editContent}
          newContentTitle={newContentTitle}
          contentId={contentId}
          activeGetModulesWithTopics={activeGetModulesWithTopics}
          newTopicRefId={newTopicRefId}
          mediaName={mediaName}
          mediaDescription={mediaDescription}
          mediaFile={mediaFile}
        />
      )}
    </>
  );
};

export default MediaForm;
