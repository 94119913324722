import { FC } from "react";
import {
  MenuWrapper,
  Frame,
  TextWithIconWrapper,
} from "./DropDownEditDelete.style";
import DropDownData from "../dropDownData";
import { PrimaryFontMediumText } from "../../../elements/fonts/Fonts";
import { ModuleProps } from "../../../Views/teacherView/Course-Creator/courseCreatorInterface";

import { useRecoilValue } from "recoil";
import {
  courseCreatorColorSelector,
  ColorSelector,
} from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

interface DropDownEditDeleteProps {
  removeTopic: (module: ModuleProps, index: number) => void;
  topicId: number;
  setShowDeleteTopic: (showDeleteTopic: boolean) => void;
  module: ModuleProps;
  setShowEditTopic: (showEditTopic: boolean) => void;
  setShowDropDown: (showDropDown: boolean) => void;
}

const DropDownEditDeleteTopic = ({
  removeTopic,
  topicId,
  setShowDeleteTopic,
  module,
  setShowEditTopic,
  setShowDropDown,
}: DropDownEditDeleteProps): JSX.Element => {
  // Recoil state and selectors
  const { t } = useTranslation();
  const { tooltipBgColor, tooltipTextColor, boxShadow } = useRecoilValue(
    courseCreatorColorSelector
  );
  const { theme } = useRecoilValue(ColorSelector);
  return (
    <MenuWrapper bgColor={tooltipBgColor} boxShadow={boxShadow}>
      {DropDownData.map((item, index) => (
        <Frame
          key={index}
          textColor={tooltipTextColor}
          style={{
            borderRadius: `${
              index === 0
                ? "0.3125rem 0.3125rem 0rem 0rem"
                : index === DropDownData.length - 1
                ? " 0rem 0rem 0.3125rem 0.3125rem"
                : ""
            }`,
          }}
        >
          <TextWithIconWrapper
            onClick={() => {
              if (index === 1) {
                setShowDeleteTopic(true);
              } else if (index === 0) {
                setShowEditTopic(true);
                setShowDropDown(false);
              }
            }}
          >
            <item.icon />
            <PrimaryFontMediumText text={t(item.title)} fontSize=" 0.875rem" />
          </TextWithIconWrapper>
        </Frame>
      ))}
    </MenuWrapper>
  );
};

export default DropDownEditDeleteTopic;
