import React, { useEffect, useState } from "react";
import { LogoImage } from "./Logo.style";
import { getLogo } from "../../services/logo";

interface SidebarProps {
  sidebarMini?: boolean;
}

export const Logo = ({ sidebarMini = false }: SidebarProps): JSX.Element => {
  const [backgroundUrl, setBackgroundUrl] = useState("");

  const [bigLogoUtl, setBigLogoUtl] = useState("");
  const [logoUrl, setLogoUrl] = useState();
  const [bigLogoName, setBigLogoName] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLogo();
        if (response) {
          setBackgroundUrl(response.backgroundUrl);
          setBigLogoUtl(response.bigLogoUtl);
          setLogoUrl(response.logoUrl);
          // setTitle(response.title);
          // setAbv(response.abv);

          // Save encoded logoUrl to localStorage
          // localStorage.setItem("logoUrl", encodeURIComponent(response.logoUrl));
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (!logoUrl) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [logoUrl]);

  if (!loading) {
    return (
      <LogoImage
        sidebarmini={sidebarMini.toString()}
        logourl={
          logoUrl !== "" && logoUrl !== undefined && logoUrl !== null
            ? logoUrl
            : "/assets/images/zidyia-logo.png"
        }
      ></LogoImage>
    );
  }
  return (
    <LogoImage
      sidebarmini={sidebarMini.toString()}
      logourl={"/assets/images/zidyia-logo.png"}
    >
    </LogoImage>
  );
};
