import React, { FC } from "react";
import {
  ButtonContainer,
  ButtonContent,
  LoaderIconHolder,
} from "./CancelButton.style";
import LoaderCircle from "../../components/loader/loaderCircle/LoaderCircle";

interface CancelButtonProps {
  name: string;
  onClickFunction: () => void;
  backgroundColor?: string;
  color?: string;
  borderColor?: string;
  disabled?: boolean;
  display?: string;
  width?: string;
}

const CancelButton: FC<CancelButtonProps> = ({
  name,
  onClickFunction,
  backgroundColor = "transparent",
  color,
  borderColor,
  disabled,
  display,
  width,
}) => {
  return (
    <ButtonContainer>
      <ButtonContent
        onClick={onClickFunction}
        style={{ backgroundColor, color, width }}
        bordercolor={borderColor}
        disabled={disabled || false}
      >
        {name}
      </ButtonContent>
      <LoaderIconHolder display={display || "none"}>
        <LoaderCircle />
      </LoaderIconHolder>
    </ButtonContainer>
  );
};

export default CancelButton;
