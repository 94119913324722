import React, { FC } from "react";
import {
  QuestionCRUDContainer,
  ChoiceFlexContainer,
} from "./QuestionCRUD.style";
import { QuestionCRUDProps } from "../QuestionCRUDInterface";
import MatchingQuestion from "../MatchingQuestion/MatchingQuestion";
import QuestionCRUDTextEditor from "../QuestionCRUDTextEditor/QuestionCRUDTextEditor";
import QuestionCRUDFeedback from "../QuestionCRUDFeedBack/QuestionCRUDFeedback";
import MultipleQuestion from "../MultipleQuestion/MultipleQuestion";
import TrueFalseQuestion from "../TrueFalseQuestion/TrueFalseQuestion";
import ShortAnswerQuestion from "../ShortAnswerQuestion/ShortAnswerQuestion";
import NumericalQuestion from "../NumericalQuestion/NumericalQuestion";
import EssayQuestion from "../EssayQuestion/EssayQuestion";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  CourseColorSelector,
} from "../../../recoil/ThemeSelectors";
const QuestionCRUD: FC<QuestionCRUDProps> = ({
  questionError,
  typeError,
  index,
  partIndex,
  addChoice,
  handleChoiceMatchingQuestion,
  handleQuestion,
  handleQuillChange,
  choice,
  quest,
  showQuest,
  handleShowEditQuest,
  setShowPreviewPopup,
  handleChoiceChecked,
  setDeleteChoice,
  setChoiceIndex,
  handleShuffle,
  isShuffled,
  handleQIndexFromChild,
  setChoice,
  handleRadioChecked,
  handleGrade,
  handleSelectRubricId
}) => {
  const { ClassGradientBox } = useRecoilValue(CourseColorSelector);
  const { boxShadow } = useRecoilValue(ColorSelector);
  return (
    <QuestionCRUDContainer boxshadow={boxShadow} bgcolor={ClassGradientBox}>
      <QuestionCRUDTextEditor
        questionError={questionError}
        typeError={typeError}
        handleQuillChange={handleQuillChange}
        addChoice={addChoice}
        handleQuestion={handleQuestion}
        handleGrade={handleGrade}
        quest={quest}
        showQuest={showQuest}
        handleShowEditQuest={handleShowEditQuest}
        setShowPreviewPopup={setShowPreviewPopup}
        handleShuffle={handleShuffle}
        isShuffled={isShuffled}
        index={index}
      />
      <ChoiceFlexContainer
        style={{
          display: quest?.type === "True-False" ? "flex" : "",
          width: "100%",
          justifyContent: "space-between",
          gap: "0.62rem",
          // background: "blue",
        }}
      >
        {choice?.map((choices, qindex) =>
          quest?.type === "MATCH" ? (
            <MatchingQuestion
              key={qindex}
              choice={choices}
              qindex={qindex}
              allChoices={choice}
              setDeleteChoice={setDeleteChoice}
              handleChoiceMatchingQuestion={handleChoiceMatchingQuestion}
              showQuest={showQuest}
              setChoiceIndex={setChoiceIndex}
              questionError={questionError ?? ""}
            />
          ) : quest?.type === "MCQ" || quest?.type === "MRQ" ? (
            <MultipleQuestion
              key={qindex}
              choice={choices}
              qindex={qindex}
              setDeleteChoice={setDeleteChoice}
              handleChoiceMatchingQuestion={handleChoiceMatchingQuestion}
              showQuest={showQuest}
              handleChoiceChecked={handleChoiceChecked}
              allChoices={choice}
              setChoiceIndex={setChoiceIndex}
              questionError={questionError ?? ""}
              handleQIndexFromChild={handleQIndexFromChild}
              setChoice={setChoice}
              handleRadioChecked={handleRadioChecked}
            />
          ) : quest?.type === "TF" ? (
            <TrueFalseQuestion
              key={qindex}
              choice={choices}
              qindex={qindex}
              handleChoiceMatchingQuestion={handleChoiceMatchingQuestion}
              showQuest={showQuest}
              handleChoiceChecked={handleChoiceChecked}
              allChoices={choice}
              questionError={questionError ?? ""}
            />
          ) : quest?.type === "SHORT_ANSWER" ? (
            <ShortAnswerQuestion
              key={qindex}
              choice={choices}
              allChoices={choice}
              qindex={qindex}
              setDeleteChoice={setDeleteChoice}
              handleChoiceMatchingQuestion={handleChoiceMatchingQuestion}
              showQuest={showQuest}
              setChoiceIndex={setChoiceIndex}
              questionError={questionError ?? ""}
            />
          ) : quest?.type === "NUMERIC" ? (
            <NumericalQuestion
              key={qindex}
              choice={choices}
              qindex={qindex}
              handleChoiceMatchingQuestion={handleChoiceMatchingQuestion}
              showQuest={showQuest}
              questionError={questionError ?? ""}
            />
          ) : (
            <EssayQuestion
              key={qindex}
              choice={choices}
              qindex={qindex}
              handleChoiceMatchingQuestion={handleChoiceMatchingQuestion}
              showQuest={showQuest}
              handleChoiceChecked={handleChoiceChecked}
              questionError={questionError ?? ""}
              handleSelectRubricId={handleSelectRubricId}
              quest={quest}
              index={index}
            />
          )
        )}
      </ChoiceFlexContainer>
      <QuestionCRUDFeedback
        handleQuestion={handleQuestion}
        handleQuillChange={handleQuillChange}
        quest={quest}
        showQuest={showQuest}
      />
    </QuestionCRUDContainer>
  );
};

export default QuestionCRUD;
