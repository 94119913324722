import styled from "styled-components";

export const CompetencyFrameworksSearch = styled.div`

width:23.625rem;
height: 3.125rem;
flex-shrink: 0;
`;

export const CompetencyFrameworksContainer = styled.div`
display: flex;
gap:0.62rem;
flex:1;

&:last-child{
  justify-content:flex-end;
}
`;