import { styled } from "styled-components";
import { breakpoints } from "../../../theme";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const AllOnecol = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 2.5rem;
`;
export const OptionRows = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 2.5rem;
`;

export const NoteMainRow = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.75rem;
  @media screen and (max-width: ${breakpoints.tablets}) {
    width: 95%;
    /* background-color:aqua !important; */
  }
`;

export const NoteCardDiv = styled.div<{
  bgcolor?: string;
  FocusColor?: string;
  boxshadow?: string;
}>`
  border-radius: 0.625rem;
  background: ${(props) => props.bgcolor};
  box-shadow: ${(props) => props.boxshadow};
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  width: 32.2%;
  /* width:24rem; */
  min-width: 20rem;
  /* height: 5.28rem; */
  padding: 0.99rem;
  position: relative;
  @media screen and (max-width: ${breakpoints.tablets}) {
    width: 100%;
    min-width: 10rem;
  }
`;

export const TitleAndMenueRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const NoteLine = styled.div`
  width: 80%;
  margin: auto;
  height: 0.069rem;
  background-color: #576371;
`;

export const NoteMiniRows = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const TextUnderlined = styled.div`
  text-decoration: underline;
  color: #5dd3b3;
`;

export const OptionCard = styled.div<{ bgColor?: string }>`
  display: flex;
  flex-direction: column;
  /* width: 6.75rem;
  height: 5rem; */
  position: absolute;
  border-radius: 0.3125rem;
  background: ${(props) => props.bgColor};
  justify-content: space-between;
  &:dir(ltr) {
    right: 1rem;
  }
  &:dir(rtl) {
    left: 1rem;
  }
  top: 1rem;

  z-index: 100;
  width: 6.75rem;
`;

export const NoteAddButtonClick = styled.div`
  display: flex;
  padding: 0 0.6rem 0.6rem 0.6rem;
  cursor: pointer;
`;
