import styled, { css } from "styled-components";
import { breakpoints } from "../../theme";

interface SidebarProps {
  sidebarmini: string;
  logourl: string;
}

const mediaQueries = (mini: boolean, logourl: string) => {
  if (mini && logourl) {
    return css`
      @media (max-width: ${breakpoints.extraLarge}) {
        background-image: url(${logourl});
        background-size: contain;
        transition: background-image 0.7s ease-in-out;
      }
    `;
  }
  // If mini is false or undefined, return an empty CSS block
  return css``;
};
export const LogoImage = styled.div<SidebarProps>`
  width: 100%;
  background-image: url(${(props) => props.logourl});
  background-repeat: no-repeat;
  background-position: center;
  height: 5.5rem;
  background-size: contain;
  ${(props) => mediaQueries( props.sidebarmini === "true" ? true : false, props.logourl )}/* @media (max-width: ${breakpoints.extraLarge}) {
    background-image: url("/assets/images/responsivelogo.png");
    background-size: cover;
    transition: background-image 0.7s ease-in-out;
  } */
`;
