import styled from "styled-components";
import { theme } from "../../../theme";

export const QuestionMain = styled.div`
  padding: 1.25rem 1.31rem 0 2.5rem;
`;

export const QuestionCommentContainer = styled.div`
  border: 1px solid #576371;
  padding: 1.62rem 1.25rem 1.31rem 1.25rem;
`;
export const QuestionName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.31rem;
`;

export const OverContainer = styled.div`
  border: 1px dashed #0b8fff;
  width: 6.25rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderQuestionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GradeInput = styled.input`
  border: none;
  background: transparent;
  width: 1.25rem;
  color: ${theme.dark.primary_text_color};
  font-family: "Primary Font-Light";
  font-size: 0.75rem;
  line-height: 1.375rem;
`;

export const QuestionAnswer = styled.div`
  margin-top: 0.62rem;
`;

export const CommentSection = styled.textarea<{ color?: string }>`
  /* margin-top:1.25rem; */
  border: 1px dashed #5dd3b3;
  height: 6.25rem;
  width: 100%;
  background: transparent;
  font-size: 0.875rem;
  font-family: "Primary Font-Light";
  color: ${(props) => props.color};
  resize: none;
  padding: 1rem;
  &&::placeholder {
    color: ${(props) => props.color} !important;
    opacity: 0.5;
  }
  &:focus {
    outline: none;
  }
`;
