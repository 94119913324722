import React, { useEffect, useState } from "react";
import {
  SelectedOptionsBox,
  SelectedOptionsLabel,
} from "./SelectedOptions.style";
import { Typography } from "../fonts/Fonts";
import { SelectedOptionDataInterface } from "./SelectedOptionDataInterface";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { SvgDelete, SvgEdit, SvgEditIcon } from "../Icons";
import { t } from "i18next";
interface SelectedOptionsInterfaceTsx {
  Data?: SelectedOptionDataInterface[];
  FirstText?: string;
  FirstIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  FirstLabelClick?: () => void;
  SecondText?: string;
  SecondIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  SecondLabelClick?: () => void;
  LastText?: string;
  LastIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  LastLabelClick?: () => void;
  width?: string;
}
const SelectedOptions = ({
  Data,
  FirstText = "general.Edit",
  FirstIcon = SvgEdit,
  FirstLabelClick,
  LastText = "general.Delete",
  LastIcon = SvgDelete,
  LastLabelClick,
  SecondText = "",
  SecondIcon,
  SecondLabelClick,
  width,
}: SelectedOptionsInterfaceTsx) => {
  const { bg_selectColor, boxShadow, default_boxShadow } =
    useRecoilValue(ColorSelector);
  // const [ShowSvgEdit, setShowSvgEdit] = useState<boolean>(FirstText.includes("Edit"));
  const [ShowRed, setShowRed] = useState<boolean>(LastText.includes("Delete"));

  return (
    <>
      {/* You must call it within an absolutely positioned container on the calling page.
     You Can check how it is used in CompetencyFrameworksTable */}
      {Data ? (
        <SelectedOptionsBox bgcolor={bg_selectColor} width={width}>
          {Data.map((item, index) => (
            <SelectedOptionsLabel
              hoverbgcolor={ShowRed ? "#D85D66" : undefined}
              key={index}
              // onClick={() => {
              //   if (onClickIndex) onClickIndex(index);
              // }}
              onClick={() => {
                item.onClick && item.onClick();
              }}
            >
              <>{item.icon ? <item.icon /> : null}</>
              {/* if Your text Does not Placed on the same Line  you must  replace the space with
            \u00A0 like "Edit Competency" =>  "Edit\u00A0Competency" */}
              <Typography text={item.Label} fontSize="0.875rem" />
            </SelectedOptionsLabel>
          ))}
        </SelectedOptionsBox>
      ) : (
        <SelectedOptionsBox
          boxshadow={default_boxShadow}
          bgcolor={bg_selectColor}
          width={width}
        >
          {FirstLabelClick ? (
          <SelectedOptionsLabel
            bgcolor={bg_selectColor}
            onClick={FirstLabelClick}
            FirstLabelClick={FirstLabelClick}
          >
            <FirstIcon /> <Typography text={t(FirstText)} fontSize="0.875rem" />
          </SelectedOptionsLabel>
          ) : ""}
          {SecondText || SecondIcon ? (
            <SelectedOptionsLabel
              bgcolor={bg_selectColor}
              onClick={SecondLabelClick}
            >
              {SecondIcon && <SecondIcon />}{" "}
              <Typography text={SecondText} fontSize="0.875rem" />
            </SelectedOptionsLabel>
          ) : null}

          <SelectedOptionsLabel
            bgcolor={bg_selectColor}
            onClick={LastLabelClick}
            FirstLabelClick={FirstLabelClick}
            // hoverBgColor={ShowRed ? "#D85D66" : undefined}
          >
            <LastIcon /> <Typography text={t(LastText)} fontSize="0.875rem" />
          </SelectedOptionsLabel>
        </SelectedOptionsBox>
      )}
    </>
  );
};

export default SelectedOptions;
