export const CourseData = [
  {
    id: "36908074-4315-45dd-bca5-676f1f11e658",
    DueDate: "12/15/2023",
    EnrollmentDate: "1/10/2022",
    Progress: 15,
    Category: ["Marketing"],
    duration: 70,
    isMandatory: false,
    accessed: 10,
    academicSessions: "Fall Semester",
  },
  {
    id: "bc8f8949-8c12-4c1e-a2f7-8392739b696a",
    DueDate: "1/15/2024",
    EnrollmentDate: "1/4/2024",
    Progress: 70,
    Category: ["Coding", "Artificial Intelligence", "Machine learning"],
    duration: 120,
    isMandatory: false,
    accessed: 5,
    academicSessions: "Spring Semester"
  },
  {
    id: "2bf1b2b3-fe67-4d96-9e99-f5a53bea4aa6",
    DueDate: "7/20/2024",
    EnrollmentDate: "4/3/2023",
    Progress: 100,
    Category: ["Coding", "Artificial Intelligence", "Machine learning"],
    duration: 90,
    isMandatory: true,
    accessed: 3,
    academicSessions: "Summer Semester"
  },
  {
    id: "61c3ac68-e230-4da9-99bd-980b18f0239b",
    DueDate: "4/26/2024",
    EnrollmentDate: "1/2/2024",
    Progress: 50,
    Category: ["Coding", "Artificial Intelligence"],
    duration: 60,
    isMandatory: true,
    accessed: 1,
    academicSessions: "Summer Semester"
  },
  {
    id: "18161ccb-f6de-40c3-bcf3-c553f477b35d",
    DueDate: "12/15/2024",
    EnrollmentDate: "12/2/2023",
    Progress: 100,
    Category: ["Coding", "Artificial Intelligence", "Machine learning"],
    duration: 50,
    isMandatory: false,
    accessed: 7,
    academicSessions: "Summer Semester"
  },
  {
    id: "de3c4c3d-bc2e-49ba-8166-fbf65728de95",
    DueDate: "12/15/2024",
    EnrollmentDate: "12/2/2023",
    Progress: 100,
    Category: ["Coding", "Artificial Intelligence", "Machine learning"],
    duration: 50,
    isMandatory: false,
    accessed: 7,
    academicSessions: "Summer Semester"
  },
]


export const OptionsFilter = [
  // {
  //   name: 'view all.Recently Accessed',
  //   value: 'Recently Accessed'
  // },
  // {
  //   name: 'view all.Latest Due Date',
  //   value: 'Latest Due Date'
  // },
  // {
  //   name: 'view all.Oldest Due Date',
  //   value: 'Oldest Due Date'
  // },
  {
    name: 'view all.Latest Enrollment',
    value: 'Latest Enrollment'
  },
  {
    name: 'view all.Oldest Enrollment',
    value: 'Oldest Enrollment'
  },
  {
    name: 'view all.Latest Creation Date',
    value: 'Latest Creation Date'
  },
  {
    name: 'view all.Oldest Creation Date',
    value: 'Oldest Creation Date'
  },
];

export const OptionsFilterArchived = [
  {
    name: 'dashboard.classes in progress',
    value: 'Classes in Progress'
  },
  {
    name: 'dashboard.Archived Classes',
    value: 'Archived Classes'
  },
  {
    name: 'dashboard.completed classes',
    value: 'completed classes'
  },
];