import styled from "styled-components";

export const ProgressBarChartLayout = styled.div`
display: flex;
flex-direction:column;
background-color:transparent;
gap:1.25rem;
width: 100%;
/* background-color:red; */
`;

export const ProgressBarChartRendered = styled.div`
display: flex;
flex-direction:column;
gap:0.62rem;
width: 100%;
`;
export const ProgressBarChartRenderedRow = styled.div`
display: flex;
width: 100%;
justify-content:space-between;

`;