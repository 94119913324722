import styled from "styled-components";

export const AQQTableTh = styled.th`
  display: flex;
  flex-direction: row;
  width: 58%;
align-self: center;
  padding: 0 2.5rem 0rem 1rem;
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
  border-left: 1px solid #576371;
    min-width:16rem;
  height: 3.3rem;
    width: 100%;
  align-items: center;
/* background-color:blue; */
justify-content:space-between;
`;

export const AQQTableThData = styled.div`
display: flex;
gap:0.30rem;
align-items:center;
`;

export const AQQTableThDetails = styled.th`
  height: 2.7rem;
  border-collapse: collapse;
  /* padding: 0rem 1rem 0rem 2rem; */
  padding: 0 2.5rem 0rem 1rem;
  display: flex;
  justify-content:center;
  align-items:center;
  width:10rem;

`;

export const AQQTableTd1 = styled.td`
  border: none;
height: 3.5625rem;
  border-collapse: collapse;
  /* padding: 0rem 1rem 0rem 2rem; */
  padding: 0 2.5rem 0rem 1rem;
  width:10rem;
  text-align:center;
  `;