import React, { useContext, useEffect, useRef, useState } from "react";
import { Banner } from "../../components/banner/main/banner";
import {
  DashboardLayout,
  MainTitles,
  CalendarAnnouncement,
  MainDiv,
  AnnouncementDivTitles,
  MainOnlineTitles,
  ViewAllHolder,
} from "./Dashboard.style";
import CourseCardsHolder from "../../components/courseCard/main/CourseCardsHolder";
import { DashboardTitle } from "../../elements/dashboardTitle/DashboardTitle";
import CalendarHolder from "../../components/CalendarHolder/CalendarHolder";
import AnnouncementCard from "../../components/announcementCard/AnnouncementCard";
import OnlineCoursesCardHolder from "../../components/OnlineCoursesCard/main/OnlineCoursesCardHolder";
import { Typography } from "../../elements/fonts/Fonts";
import { Link } from "react-router-dom";
import { ClassContext } from "./UnstructuredContext";
// import { CourseArctCompo } from "../../components/CourseArct/CourseArctComp";
import { useTranslation } from "react-i18next";
import {
  auth0SubIdSplitter,
  capitalizeFirstLetter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useRecoilValue } from "recoil";
import { WebSocketConnector } from "../messages/webSocketConnection";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { WebsocketContext } from "../../contexts/notificationContext";

export const Dashboard = (): JSX.Element => {
  const { t } = useTranslation();
  const { show } = useContext(ClassContext);
  const [enrolled, setEnrolled] = useState<boolean>(true);
  const [enrolledClasses, setEnrollmentClasses] = useState<any[]>([]);
  const { specialColor2 } = useRecoilValue(ColorSelector);
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const { isReady, value, send, connect } = useContext(WebsocketContext);

  const sendPublic = async () => {
    try {
      if (send && isReady) {
        send(
          JSON.stringify({
            action: "sendPublic",
            data: {
              tenantName: tenantName,
              subId: subIdSplitted,
              provider: provider,
              type: "missingSubmit",
            },
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    sendPublic();
  }, [isReady]);

  useEffect(() => {
    if (
      !isReady &&
      tenantName !== null &&
      tenantName !== undefined &&
      subIdSplitted !== null &&
      subIdSplitted !== undefined
    ) {
      connect();
    }
  }, [tenantName, subIdSplitted]);

  return (
    <>
      <DashboardLayout style={{ position: "relative" }}>
        <Banner enrollments={enrolledClasses} />
        <MainDiv>
          <AnnouncementDivTitles>
            <MainOnlineTitles>
              <DashboardTitle text={`${t("dashboard.classes in progress")}`} />
            </MainOnlineTitles>
            {enrolled && (
              <ViewAllHolder color={specialColor2}>
                <Link to="/Allcourses">
                  <Typography
                    text={`${t("dashboard.view all")}`}
                    fontSize="0.625rem"
                    // color={specialColor2}
                  />

                  {/* <Typography fontSize="0.875" text={capitalizeFirstLetter(`${t('dashboard.view all')}`)} /> */}
                </Link>
              </ViewAllHolder>
            )}
          </AnnouncementDivTitles>

          <CourseCardsHolder
            setEnrollmentClasses={setEnrollmentClasses}
            setEnrolled={setEnrolled}
          />
        </MainDiv>

        <CalendarAnnouncement>
          <MainDiv>
            <MainTitles>
              <DashboardTitle text={`${t("dashboard.my calendar")}`} />
            </MainTitles>

            <CalendarHolder />
          </MainDiv>
          <MainDiv>
            <AnnouncementDivTitles>
              <MainTitles>
                <DashboardTitle
                  text={`${t("dashboard.latest announcement")}`}
                />
              </MainTitles>

              <Link to="/announcements">
                <ViewAllHolder color={specialColor2}>
                  <Typography
                    text={`${t("dashboard.view all")}`}
                    fontSize="0.625rem"
                    // color={specialColor2}
                  />
                </ViewAllHolder>
              </Link>
            </AnnouncementDivTitles>

            <AnnouncementCard />
          </MainDiv>
        </CalendarAnnouncement>
        {/* <MainDiv>
          <AnnouncementDivTitles>
            <MainOnlineTitles>
              <DashboardTitle text="Recommended" text2="Online Classes" />
            </MainOnlineTitles>
            <div style={{ cursor: "pointer" }}>
              <Typography fontSize="0.875" text="View all" />
            </div>
          </AnnouncementDivTitles>
          <div>
            <OnlineCoursesCardHolder />
          </div>
        </MainDiv> */}
      </DashboardLayout>
      {/* {show && <CourseArctCompo />} */}
    </>
  );
};
