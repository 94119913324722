import moment from "moment";
import * as XLSX from "xlsx";
import { TranslateTime } from "../../utils/TimeCoverter";

/**
 * Determines the color based on the count status.
 * @param count - The count status.
 * @returns The color corresponding to the count status.
 */
export const countColor = (count: string, theme?: string) => {
  if (count === "1" || count === "2") {
    if (theme === "light") return "#364350";
    else return "#fff";
  } else if (count === "3") return "#0B8FFF";
  else if (count === "4") return "#D85D66";
}; // End of countColor function

/**
 * Handles the change in enrollment view limit.
 * @param newValue - The new value selected for view limit.
 * @param setLimit - Function to set the view limit.
 * @param TableTrData - The data array.
 */

export const handleSelectChangeEnrollment = (
  newValue: string,
  setLimit: React.Dispatch<React.SetStateAction<number>>,
  TableTrData: any[] // Assuming TableTrData is an array
) => {
  if (newValue === "View 10") {
    setLimit(10); // Set limit to 10
  } else if (newValue === "View All") {
    setLimit(TableTrData.length); // Set limit to total number of students
  } else if (newValue === "View 100") {
    setLimit(100); // Set limit to 100
  }
};
// End of handleSelectChangeEnrollment function
/**
 * Converts a string to an ArrayBuffer.
 * @param s - The input string.
 * @returns The ArrayBuffer representation of the input string.
 */

function s2ab(s: string): ArrayBuffer {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
} // End of s2ab function

/**
 * Downloads an Excel file with attendance data.
 * @param TableTrData - The data array.
 * @param TitleData - The title data array.
 * @param CourseData - The course data array.
 */
export const downloadExcel = (
  TableTrData: any[],
  TitleData: { [key: string]: any }[],
  CourseData: any[]
) => {
  const newData = TableTrData.map((row: any, index: number) => {
    const sessions: { [key: string]: string } = {}; // Explicitly define sessions as a string-keyed object
    Object.keys(TableTrData[0]).forEach((key: string) => {
      if (key !== "STUDENT") {
        sessions[key] = (TableTrData[0] as any)[key][index].status; // Use 'any' to bypass type check due to dynamic key
      }
    });

    return {
      Name: row.Name,
      ...sessions, // Add other session statuses dynamically
    };
  });

  const worksheet = XLSX.utils.json_to_sheet(newData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Students");

  // Add Course Data and Title Data to the worksheet
  const titleDataSheet = XLSX.utils.json_to_sheet(TitleData);
  XLSX.utils.book_append_sheet(workbook, titleDataSheet, "TitleData");

  const courseDataSheet = XLSX.utils.json_to_sheet([CourseData]);
  XLSX.utils.book_append_sheet(workbook, courseDataSheet, "CourseData");

  // Convert workbook to a data URL
  const excelData = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  const dataBlob = new Blob([s2ab(excelData)], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a temporary URL and create a link element to trigger the download
  const downloadLink = window.URL.createObjectURL(dataBlob);
  const link = document.createElement("a");
  link.href = downloadLink;
  link.download = "Attendance.xlsx";
  document.body.appendChild(link);
  link.click();

  // Cleanup
  document.body.removeChild(link);
  window.URL.revokeObjectURL(downloadLink);
}; // End of downloadExcel function

/**
 * Scrolls the table container to the left.
 * @param tableContainerRef - Reference to the table container element.
 * @param columnWidth - The width of each column.
 * @param setCurrentIndex - Function to set the current index.
 */
export const scrollLeft = (
  tableContainerRef: React.RefObject<HTMLDivElement>,
  columnWidth: number,
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
) => {
  if (tableContainerRef.current) {
    const newScrollPosition =
      tableContainerRef.current.scrollLeft - columnWidth;
    tableContainerRef.current.scrollBy({
      left: -columnWidth,
      behavior: "smooth",
    });
    setCurrentIndex(Math.max(0, newScrollPosition / columnWidth));
  }
}; // End of scrollLeft function

/**
 * Scrolls the table container to the right.
 * @param tableContainerRef - Reference to the table container element.
 * @param columnWidth - The width of each column.
 * @param setCurrentIndex - Function to set the current index.
 */
export const scrollRight = (
  tableContainerRef: React.RefObject<HTMLDivElement>,
  columnWidth: number,
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
) => {
  if (tableContainerRef.current) {
    const newScrollPosition =
      tableContainerRef.current.scrollLeft + columnWidth;
    tableContainerRef.current.scrollBy({
      left: columnWidth,
      behavior: "smooth",
    });
    const maxIndex =
      Math.floor(tableContainerRef.current.scrollWidth / columnWidth) - 1;
    setCurrentIndex(Math.min(maxIndex, newScrollPosition / columnWidth));
  }
}; // End of scrollRight function

export const formatDateTime = (
  startDateTime: string,
  endDateTime: string,
  t: any
): string => {
  const startDate = new Date(startDateTime);
  const endDate = new Date(endDateTime);

  // Convert start and end dates to moment objects
  const startTime = moment(startDate);
  const NewStart = moment.utc(startDate).format("ddd");
  const endTime = moment(endDate);

  // Format only the time part to GMT
  const startTimeGMT = startTime.format(`hh:mm A`);
  const endTimeGMT = endTime.format("hh:mm A");

  const TranslatestartTimeGMT = TranslateTime(startTimeGMT, t);
  const TranslateEndTimeGMT = TranslateTime(endTimeGMT, t);
  const TranslateDay = t(`calendar.${NewStart}`);

  // Construct the formatted string
  return `${TranslateDay} ${TranslatestartTimeGMT} - ${TranslateEndTimeGMT}`;
};

// new func
export const getFormattedDateTime = (startDateTime: string | undefined, endDateTime: string | undefined) => {
  const language = localStorage.getItem("language");

  if (!startDateTime || !endDateTime) {
    return language === "tr"
      ? "Yükleniyor..."
      : language === "ar"
      ? "...جارٍ التحميل"
      : "Chargement...";
  }

  const startDate = new Date(startDateTime);
  const endDate = new Date(endDateTime);

  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return "Invalid date";
  }

  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formatter = new Intl.DateTimeFormat(
    language === "tr" ? "tr-TR" : language === "ar" ? "ar-AE" : language === "fr" ? "fr-FR" : "en-US",
    options
  );

  const startParts = formatter.formatToParts(startDate);
  const endParts = formatter.formatToParts(endDate);

  const getPart = (parts: Intl.DateTimeFormatPart[], type: string) => {
    return parts.find(part => part.type === type)?.value || "";
  };

  let day = getPart(startParts, "day");
  let dayName = getPart(startParts, "weekday");
  let startHour = getPart(startParts, "hour");
  let startMinute = getPart(startParts, "minute");
  let startDayPeriod = getPart(startParts, "dayPeriod");

  let endHour = getPart(endParts, "hour");
  let endMinute = getPart(endParts, "minute");
  let endDayPeriod = getPart(endParts, "dayPeriod");

  // Function to convert Arabic numerals to Eastern Arabic numerals
  const convertToArabicNumerals = (numStr: string) => {
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return numStr.replace(/\d/g, (digit: any) => arabicNumerals[digit]);
  };

  if (language === "ar") {
    day = convertToArabicNumerals(day);
    startHour = convertToArabicNumerals(startHour);
    startMinute = convertToArabicNumerals(startMinute);
    endHour = convertToArabicNumerals(endHour);
    endMinute = convertToArabicNumerals(endMinute);
  }

  const formattedDate = `${dayName} ${startHour}:${startMinute} ${startDayPeriod} - ${endHour}:${endMinute} ${endDayPeriod}`;
  return formattedDate;
};

// export const generateAttendanceData=(participants: any[], sessions: any[], attendance: any[]) {
//     const attendanceData: { username: any; email: any; profilePicture: any; role: any; sessions: { sessionTitle: any; status: any; }[]; }[] = [];

//     participants.forEach((participant: { username: any; email: any; profilePicture: any; role: any; sourceId: any; }) => {
//         const participantData = {
//             username: participant.username,
//             email: participant.email,
//             profilePicture: participant.profilePicture,
//             role: participant.role,
//             sessions: []
//         };

//         attendance.forEach((attendanceSession: { id: any; }) => {
//             const session: any = sessions.find((session: { id: any; }) => session.id === attendanceSession.id);
//             if (!session) return;

//             const sessionAttendance = session.attendance.find((a: { userSourceId: any; }) => a.userSourceId === participant.sourceId);
//             if (!sessionAttendance) return;

//             participantData.sessions.push({
//                 sessionTitle: session.title,
//                 status: sessionAttendance.status
//             });
//         });

//         attendanceData.push(participantData);
//     });

//     return attendanceData;
//     clg
// }
