import React, { useState, useEffect } from 'react';
import { StudentEssayContainerParent, CommentContainer } from './StudentQuizEssay.style';
import { TextEditor } from '../../TextEditor/TextEditor';
import { DropZoneForm } from '../../../components/dropZone/DropZoneForm';
import { QuellStyle, StyledQuillEditor } from '../../../components/QuizCreatorQuestionCRUD/QuestionCRUDTextEditor/QuestionCRUDTextEditor.style';
import { LightTypography, MediumTypography, TypographyRegularDescription } from '../../fonts/Fonts';
import { auth0SubIdSplitter, generateUUID, uuidSplitter,auth0ProviderSplitter } from '../../../utils/StringManipulation';
import { S3Upload } from '../../../services/S3Put';
import { findStudentComment } from '../quizElementsUtils';
import TinyEditor from '../../tinyEditor/TinyEditor';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';

type EssayDescriptions = { [key: string]: string };

interface StudentQuizEssayProps {
  essayData?: any[];
  allData?: any[];
  questionIndex?: number;
  onFilesChange?: (newFiles: File[]) => void;
  initialFiles?: File[];
  partIndex?: number;
  partsData?: any[];
  setEssayDescriptions: React.Dispatch<React.SetStateAction<EssayDescriptions>>;
  setSelectedFiles: React.Dispatch<React.SetStateAction<{ [key: string]: File[] }>>;
  studentResponse?: any;
  essayInitialValue?: { [key: string]: string }
  fileInitialValue?: { [key: string]: File[] }
  setQuestionId?: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  setQuestionType?: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  setFileResponse?: React.Dispatch<React.SetStateAction<{ [key: string]: string | null }>>;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  started?: boolean;
  showGrade?: boolean;
  showPublishGrade?: boolean;
  setQuizTemplateQuestionsId?: React.Dispatch<
    React.SetStateAction<{ [key: string]: number }>
  >;
  quizId:any;
  previewMode:boolean;
}

const accF: { [key: string]: string[] } = {
  "application/pdf": [".pdf"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  // Add other MIME types and extensions as needed
};

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "clean"],
    [{ header: [] }],
    [{ list: "ordered" }, { list: "bullet" }],

    [{ align: [] }],
    ["link", "code", "image"],
  ],
};

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "clean",
  "header",
  "header",
  "list",

  "align",
  "link",
  "code",
  "image",
];

function StudentQuizEssay({ essayData,
  // initialFiles,
  allData,
  partIndex,
  partsData,
  questionIndex,
  setEssayDescriptions,
  setSelectedFiles,
  studentResponse,
  essayInitialValue,
  fileInitialValue,
  setQuestionId,
  setQuestionType,
  setFileResponse,
  setLoading,
  started,
  showGrade,
  showPublishGrade,
  setQuizTemplateQuestionsId,
  quizId,
  previewMode
}: StudentQuizEssayProps) {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const params = useParams();
  const classId = params.classId;
  // const [selectedFiles, setSelectedFiles] = useState(initialFiles);
  const [essayResponse, setEssayResponse] = useState<string>('');
  const [initialFiles, setInitialFiles] = useState<File[]>([]);
  const filePath = `quiz/submissions`;

  // Find the answer of the student and return it.
  const findStudentAnswer = (questionId: any, questionType: string) => {
    if (studentResponse && studentResponse.length > 0) {
      const studentAnswer = studentResponse.find((answer: any) => Number(answer.questionId) === Number(questionId) && answer.type === questionType);

      if (studentAnswer && studentAnswer.answers && studentAnswer.answers.length > 0 && studentAnswer.answers[0].answer) {
        setEssayResponse((studentAnswer.answers[0].answer.answer).toString());

        // Create a File object with the necessary properties
        if (studentAnswer.answers[0].answer.file) {
          const fileObject: File = new File([studentAnswer.answers[0].answer.file], `${uuidSplitter(studentAnswer.answers[0].answer.file).replace(/^(quiz\/submissions\/)/, '')}`, { type: 'text/plain' });

          setInitialFiles([fileObject]);
        }
      } else {
        setEssayResponse('');
        setInitialFiles([]); // Set to an empty array if there's no file
      }
    } else {
      setEssayResponse('');
      setInitialFiles([]); // Set to an empty array if there's no file
    }
  };

  // Call the findStudentAnswer to show the answer to the student
  useEffect(() => {
    if (questionIndex !== undefined) {
      if (partIndex !== undefined && allData && allData[questionIndex].questionParts && allData[questionIndex].questionParts[partIndex]) {
        findStudentAnswer(allData[questionIndex].questionParts[partIndex].id, allData[questionIndex].questionParts[partIndex].type)
      } else {
        findStudentAnswer(allData && allData[questionIndex].questionId, allData && allData[questionIndex].type)
      }

      if(studentResponse && studentResponse.length === 0 && !previewMode){
      // Fetch and set the timer from local storage if it exists
      const savedStudentAnswer = JSON.parse(localStorage.getItem('localStudentResponse') || '[]');
      const currentQuizAnswer = savedStudentAnswer.find((item: any) =>
        item.classSourceId === classId &&
        item.subId === subIdSplitted &&
        item.refId === quizId
      );

      if (currentQuizAnswer &&
        allData &&
        allData[questionIndex]) {
        let key = "";
        let fileKey = "";
      if (
        (allData[questionIndex].composed ||
          allData[questionIndex].type === "COMPOSITE") &&
        partIndex !== undefined &&
        allData[questionIndex].questionParts
      ) {
        key = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}`;
        fileKey = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}_File`;
      } else {
        key = `${allData[questionIndex].title}`;
        fileKey = `${allData && allData[questionIndex].title}_File`;
      }

      if(currentQuizAnswer && currentQuizAnswer.localStudentResponse && currentQuizAnswer.localStudentResponse[key]){
        setEssayResponse((currentQuizAnswer.localStudentResponse[key]).toString())
        setEssayDescriptions((prevDescriptions) => ({
          ...prevDescriptions,
          [key]: (currentQuizAnswer.localStudentResponse[key]).toString(),
        }));
        }else{
          setEssayResponse('')
        }

      if(currentQuizAnswer && currentQuizAnswer.localStudentResponse && currentQuizAnswer.localStudentResponse[fileKey]){
        const fileObject: File = new File([currentQuizAnswer.localStudentResponse[fileKey]], uuidSplitter(currentQuizAnswer.localStudentResponse[fileKey]).replace(/^(quiz\/submissions\/)/, ''), { type: 'text/plain' });

          setInitialFiles([fileObject]);
          setSelectedFiles((prevSelectedFiles) => ({
            ...prevSelectedFiles,
            [fileKey]: [fileObject],
          }));
          setFileResponse && setFileResponse((prevSelectedFiles) => ({
            ...prevSelectedFiles,
            [fileKey]: currentQuizAnswer.localStudentResponse[fileKey],
          }));
        }else{
          setInitialFiles([]);
        }
        
      } else {
        setEssayResponse('')
        setInitialFiles([]);
      }
    }
    }
  }, [questionIndex, partIndex, allData, studentResponse]);

  // Update the type of the question and the id of the question depending on the dependencies
  useEffect(() => {
    if (questionIndex !== undefined) {
      if (partIndex !== undefined && allData && allData[questionIndex].questionParts && allData[questionIndex].questionParts[partIndex]) {
        // For composed questions
        const singleKey = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}_ID`;
        const typekey = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}Type`;
        const templateKey = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}_TEMPLATEKEY`;
        setQuestionType && setQuestionType((prevDescriptions) => ({
          ...prevDescriptions,
          [typekey]: allData[questionIndex].questionParts[partIndex].type,
        }));
        setQuestionId && setQuestionId((prevId) => ({
          ...prevId,
          [singleKey]: allData[questionIndex].questionParts[partIndex].id,
        }));
        setQuizTemplateQuestionsId &&
          setQuizTemplateQuestionsId((prevId) => ({
            ...prevId,
            [templateKey]: allData && allData[questionIndex].quizTemplateQuestionId,
          }));
      } else {
        // For non-composed questions
        const singleKey = `${allData && allData[questionIndex].title}_ID`;
        const typekey = `${allData && allData[questionIndex].title}Type`;
        const templateKey = `${allData && allData[questionIndex].title}_TEMPLATEKEY`;
        setQuestionId && setQuestionId((prevId) => ({
          ...prevId,
          [singleKey]: allData && allData[questionIndex].questionId,
        }));
        setQuestionType && setQuestionType((prevDescriptions) => ({
          ...prevDescriptions,
          [typekey]: allData && allData[questionIndex].type,
        }));
        setQuizTemplateQuestionsId &&
          setQuizTemplateQuestionsId((prevId) => ({
            ...prevId,
            [templateKey]: allData && allData[questionIndex].quizTemplateQuestionId,
          }));
      }


    }
  }, [questionIndex, allData])

  // Hnalde the answer of the student
  const handleResponseChange = (value: string) => {
    if (questionIndex !== undefined) {
      if (partIndex !== undefined && allData && allData[questionIndex].questionParts && allData[questionIndex].questionParts[partIndex]) {
        // For composed questions
        const key = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}`;
// Retrieve the quizAnswers array from local storage
let quizAnswers = JSON.parse(localStorage.getItem('localStudentResponse') || '[]');

// Find the index of the existing timer object
const index = quizAnswers.findIndex((item: any) =>
  item.classSourceId === classId &&
  item.subId === subIdSplitted &&
  item.refId === quizId
);

if (index !== -1) {
  // Retrieve the existing localStudentResponse
  let existingResponse = quizAnswers[index].localStudentResponse || {};

  // Merge the new key-value pair with the existing localStudentResponse
  existingResponse[key] = value;

  // Update the existing object with the merged localStudentResponse
  quizAnswers[index].localStudentResponse = existingResponse;
} else {
  // Add a new object if it doesn't exist
  const data = {
    subId: subIdSplitted,
    provider:provider,
    classSourceId: classId,
    refId: quizId,
    localStudentResponse: { [key]: value }
  };
  quizAnswers.push(data);
}

// Save the updated array back to local storage
localStorage.setItem('localStudentResponse', JSON.stringify(quizAnswers));


        setEssayDescriptions((prevDescriptions) => ({
          ...prevDescriptions,
          [key]: value,
        }));
      } else {
        // For non-composed questions
        const singleKey = `${allData && allData[questionIndex].title}`;
// Retrieve the quizAnswers array from local storage
let quizAnswers = JSON.parse(localStorage.getItem('localStudentResponse') || '[]');

// Find the index of the existing timer object
const index = quizAnswers.findIndex((item: any) =>
  item.classSourceId === classId &&
  item.subId === subIdSplitted &&
  item.refId === quizId
);

if (index !== -1) {
  // Retrieve the existing localStudentResponse
  let existingResponse = quizAnswers[index].localStudentResponse || {};

  // Merge the new key-value pair with the existing localStudentResponse
  existingResponse[singleKey] = value;

  // Update the existing object with the merged localStudentResponse
  quizAnswers[index].localStudentResponse = existingResponse;
} else {
  // Add a new object if it doesn't exist
  const data = {
    subId: subIdSplitted,
    provider:provider,
    classSourceId: classId,
    refId: quizId,
    localStudentResponse: { [singleKey]: value }
  };
  quizAnswers.push(data);
}

// Save the updated array back to local storage
localStorage.setItem('localStudentResponse', JSON.stringify(quizAnswers));


        setEssayDescriptions((prevDescriptions) => ({
          ...prevDescriptions,
          [singleKey]: value,
        }));
      }
    }
  };

  // Handle the file answer of the student
  const handleFilesChange = async (newFiles: File[]) => {
    if (questionIndex !== undefined) {
      if (partIndex !== undefined && allData && allData[questionIndex].questionParts && allData[questionIndex].questionParts[partIndex]) {
        // For composed questions
        const key = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}_File`;

        // Retrieve the quizAnswers array from local storage
        let quizAnswers = JSON.parse(localStorage.getItem('localStudentResponse') || '[]');
        
        // Find the index of the existing timer object
        const index = quizAnswers.findIndex((item: any) =>
          item.classSourceId === classId &&
          item.subId === subIdSplitted &&
          item.refId === quizId
        );
        setSelectedFiles((prevSelectedFiles) => ({
          ...prevSelectedFiles,
          [key]: newFiles,
        }));
        if (newFiles) {
          setLoading && setLoading(true);
          const selectedFile: File | null = newFiles[0];
          if (selectedFile) {
            const name: string =
              selectedFile.name.split("\\").pop() || selectedFile.name;
            const fileNameUUid = `${generateUUID()}${name}`;
            const fileuplKey = `${filePath}/${fileNameUUid}`;
            try {
              const response = await S3Upload(
                filePath,
                name,
                fileNameUUid,
                selectedFile
              );
              if (response?.status === 200) {
                if (index !== -1) {
                  // Retrieve the existing localStudentResponse
                  let existingResponse = quizAnswers[index].localStudentResponse || {};
                
                  // Merge the new key-value pair with the existing localStudentResponse
                  existingResponse[key] = fileuplKey;
                
                  // Update the existing object with the merged localStudentResponse
                  quizAnswers[index].localStudentResponse = existingResponse;
                } else {
                  // Add a new object if it doesn't exist
                  const data = {
                    subId: subIdSplitted,
                    provider:provider,
                    classSourceId: classId,
                    refId: quizId,
                    localStudentResponse: {[key]:fileuplKey }
                  };
                  quizAnswers.push(data);
                }
                
                // Save the updated array back to local storage
                localStorage.setItem('localStudentResponse', JSON.stringify(quizAnswers));
                console.log("response in Assignment Submission", response.status);
                setFileResponse && setFileResponse((prevSelectedFiles) => ({
                  ...prevSelectedFiles,
                  [key]: fileuplKey,
                }));
                setLoading && setLoading(false);
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
      } else {
        // For non-composed questions
        const singleKey = `${allData && allData[questionIndex].title}_File`;

        // Retrieve the quizAnswers array from local storage
        let quizAnswers = JSON.parse(localStorage.getItem('localStudentResponse') || '[]');
        
        // Find the index of the existing timer object
        const index = quizAnswers.findIndex((item: any) =>
          item.classSourceId === classId &&
          item.subId === subIdSplitted &&
          item.refId === quizId
        );
        setSelectedFiles((prevSelectedFiles) => ({
          ...prevSelectedFiles,
          [singleKey]: newFiles,
        }));
        if (newFiles) {
          setLoading && setLoading(true);
          const selectedFile: File | null = newFiles[0];
          if (selectedFile) {
            const name: string =
              selectedFile.name.split("\\").pop() || selectedFile.name;
            const fileNameUUid = `${generateUUID()}${name}`;
            const fileuplKey = `${filePath}/${fileNameUUid}`;
            try {
              const response = await S3Upload(
                filePath,
                name,
                fileNameUUid,
                selectedFile
              );
              if (response?.status === 200) {
                if (index !== -1) {
                  // Retrieve the existing localStudentResponse
                  let existingResponse = quizAnswers[index].localStudentResponse || {};
                
                  // Merge the new key-value pair with the existing localStudentResponse
                  existingResponse[singleKey] = fileuplKey;
                
                  // Update the existing object with the merged localStudentResponse
                  quizAnswers[index].localStudentResponse = existingResponse;
                } else {
                  // Add a new object if it doesn't exist
                  const data = {
                    subId: subIdSplitted,
                    provider:provider,
                    classSourceId: classId,
                    refId: quizId,
                    localStudentResponse: {[singleKey]:fileuplKey }
                  };
                  quizAnswers.push(data);
                }
                
                // Save the updated array back to local storage
                localStorage.setItem('localStudentResponse', JSON.stringify(quizAnswers));
                console.log("response in Assignment Submission", response.status);
                setFileResponse && setFileResponse((prevSelectedFiles) => ({
                  ...prevSelectedFiles,
                  [singleKey]: fileuplKey,
                }));
                setLoading && setLoading(false);
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
      }
    }

  };

  return (
    <StudentEssayContainerParent>
      {/* Text Editor goes Here */}
      <TinyEditor
        onChange={(value: string) => {
          handleResponseChange(value);
        }}
        placeholder={(t('course view.Type your answer here'))}
        height="21.063rem"
        initialValue={
          essayResponse ||
          (essayInitialValue && questionIndex !== undefined ?
            (partIndex !== undefined && allData && allData[questionIndex].questionParts ?
              essayInitialValue[`${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}`] :
              essayInitialValue[`${allData && allData[questionIndex].title}`]
            ) :
            undefined
          )
        }
        readonly={!started ? true : false}
      />
      {/* Drop Zone Goes Here */}
      {(allData && allData[questionIndex || 0].attachments === 1) &&
        <DropZoneForm
          readonly={!started ? true : false}
          header=""
          onFilesChange={handleFilesChange}
          selectedFiles={
            initialFiles.length > 0 ? initialFiles :
              (fileInitialValue && Object.keys(fileInitialValue).length > 0 &&
                (questionIndex !== undefined ?
                  fileInitialValue[
                  partIndex !== undefined && allData && allData[questionIndex || 0]?.questionParts ?
                    `${allData[questionIndex || 0].title}_${allData[questionIndex || 0].questionParts[partIndex].title}_File` :
                    `${allData && allData[questionIndex || 0].title}_File`
                  ] :
                  []
                )
              ) || undefined // Ensure it defaults to undefined if no valid files are available
          }
          acceptedTypes={accF}
        />
      }
      {(partIndex !== undefined && allData && allData[questionIndex || 0].questionParts[partIndex || 0].attachments === 1) &&
        <DropZoneForm
          readonly={!started ? true : false}
          header=""
          onFilesChange={handleFilesChange}
          selectedFiles={
            initialFiles.length > 0 ? initialFiles :
              (fileInitialValue && Object.keys(fileInitialValue).length > 0 &&
                (questionIndex !== undefined ?
                  fileInitialValue[
                  partIndex !== undefined && allData && allData[questionIndex || 0]?.questionParts ?
                    `${allData[questionIndex || 0].title}_${allData[questionIndex || 0].questionParts[partIndex].title}_File` :
                    `${allData && allData[questionIndex || 0].title}_File`
                  ] :
                  []
                )
              ) || undefined // Ensure it defaults to undefined if no valid files are available
          }
          acceptedTypes={accF}
        />
      }
      {partIndex === undefined && showPublishGrade && allData && allData[questionIndex || 0] && findStudentComment(studentResponse, allData[questionIndex || 0].questionId, allData[questionIndex || 0].type) !== "" ?
        <>
          {/* Comment of the instructor on the answer for the normal question goes Here */}
          <MediumTypography
            text='Instructor Comment'
          />
          <CommentContainer>
            <LightTypography
              text={findStudentComment(studentResponse,
                (questionIndex !== undefined && partIndex !== undefined && allData && allData[questionIndex].questionParts)
                  ? allData[questionIndex].questionParts[partIndex].id
                  : (questionIndex !== undefined && allData ? allData[questionIndex].questionId : undefined),
                (questionIndex !== undefined && partIndex !== undefined && allData && allData[questionIndex].questionParts)
                  ? allData[questionIndex].questionParts[partIndex].type
                  : (questionIndex !== undefined && allData ? allData[questionIndex].type : undefined)
              )}
              fontSize='.8rem'
            />
          </CommentContainer>
        </>
        : ""}
      {partIndex !== undefined && showPublishGrade && allData && allData[questionIndex || 0] && findStudentComment(studentResponse, allData[questionIndex || 0].questionParts[partIndex].id, allData[questionIndex || 0].questionParts[partIndex].type) !== "" ?
        <>
          {/* Comment of the instructor on the answer for the composed question goes Here */}
          <MediumTypography
            text='Instructor Comment'
          />
          <CommentContainer>
            <LightTypography
              text={findStudentComment(studentResponse,
                (questionIndex !== undefined && partIndex !== undefined && allData && allData[questionIndex].questionParts)
                  ? allData[questionIndex].questionParts[partIndex].id
                  : (questionIndex !== undefined && allData ? allData[questionIndex].questionId : undefined),
                (questionIndex !== undefined && partIndex !== undefined && allData && allData[questionIndex].questionParts)
                  ? allData[questionIndex].questionParts[partIndex].type
                  : (questionIndex !== undefined && allData ? allData[questionIndex].type : undefined)
              )}
              fontSize='.8rem'
            />
          </CommentContainer>

        </>
        : ""}
    </StudentEssayContainerParent>
  );
}

export default StudentQuizEssay;
