import styled from 'styled-components'
import { StyleThemeInterface } from '../../../interfaces/StyleThemeInterface';

export const MenuContainer=styled.div<StyleThemeInterface>`
display: flex;
justify-content:flex-end;
max-height: 120vh;
height:100%;
background:${props =>props.bgcolor ||  "#2D3741"};
`;

export const ContentContainer=styled.div<StyleThemeInterface>`
width: 1.875rem;
height: 100%;
background:${props =>props.bgcolor || "#384450"};
display: flex;
justify-content:center;
align-items:center;
`;