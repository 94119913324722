import React, { useEffect, useState } from "react";
import {
  IAUVFirstChild,
  IAUVImg,
  IAUVTableBody,
  IAUVTableBodyCell,
  IAUVTableHeader,
  IAUVTableHeaderCell,
  IAUVTableView,
} from "./InstructorAnalyticsUsersViewTable.style";
import {
  InstructorAnalyticsUsersViewTableActionData,
  InstructorAnalyticsUsersViewTableData,
} from "./InstructorAnalyticsUsersViewTableDATA";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../../elements/fonts/Fonts";
import {
  CheckboxLabel,
  SeletectOptinToogle,
} from "../../ProfileCheckBoxOptions/NotificationSetting.style";
import { SvgEmail, SvgMore } from "../../../elements/Icons";
import {
  handleItemToggle,
  handleSelectAllToggle,
} from "../../../hooks/TableSelectedOptions";
import {
  handleChangeShapeToggle,
  handleOptionButtonClick,
  updateMessageText,
} from "../../StudentCompletionTrackingInstrcuterView/functions";
import {
  InstructorMessageBox,
  InstructorViewBox,
  InstructorViewCompletionOptionButton,
} from "../../StudentCompletionTrackingInstrcuterView/InstructorViewCompletion.style";
import IdTwoActions from "../../StudentCompletionTrackingInstrcuterView/IdTwoActions";
import MappedIndexClickOutSide from "../../../hooks/MapedIndexClickOutSide";
import StudentMessage from "../../StudentCompletionTrackingInstrcuterView/StudentMessage";
import useClickOutside from "../../../hooks/useClickOutside";
import {
  ComplexSelectTableMessagePopup,
  PointerContainer,
} from "../../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableTable.style";

interface InstructorAnalyticsUsersViewTableInterface {
  filteredData: any[];
}
export interface SelectedStudentsInterface {
  id: number; // Change the type to number
  FullName: string;
  email: any;
}
const InstructorAnalyticsUsersViewTable = ({
  filteredData,
}: InstructorAnalyticsUsersViewTableInterface) => {
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  // Initialize an array to track the checked state of each item
  const [checkedItems, setCheckedItems] = useState<boolean[]>(
    new Array(filteredData.length).fill(false)
  );
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(
    null
  );
  const clickOutsideRef = MappedIndexClickOutSide(() =>
    setSelectedOptionIndex(null)
  );
  const [shapeToggles, setShapeToggles] = useState<Array<boolean>>(
    Array(filteredData.length).fill(true)
  );

  // const [messageSubjects, setMessageSubjects] = useState<{
  //   [id: string]: string;
  // }>({});
  // const [FileStorageId, setFileStorageId] = useState<{
  //   [id: string]: FileList | null;
  // }>({});
  const [messageTexts, setMessageTexts] = useState<{ [id: string]: string }>(
    {}
  );
  const [ErrormessageTexts, setErrorMessageTexts] = useState<{
    [id: string]: string;
  }>({});

  const handleMessageSend = (index: number, id: string) => {
    if (messageTexts[id] === undefined || messageTexts[id].trim() === "") {
      // If messageTexts[id] is undefined or empty, set the errorMessageTexts for the specific id
      setErrorMessageTexts((prevState) => ({
        ...prevState,
        [id]: "This field is required.",
      }));
      return;
    }

    const Data = {
      id: id,
      // MessageValue: messageSubjects,
      // File: FileStorageId,
      MessageText: messageTexts,
    };

    // Reset the messageSubjects and messageTexts states for the specific id
    // setMessageSubjects((prevState) => ({
    //   ...prevState,
    //   [id]: "",
    // }));

    setMessageTexts((prevState) => ({
      ...prevState,
      [id]: "",
    }));
    setErrorMessageTexts((prevState) => ({
      ...prevState,
      [id]: "",
    }));

    // setFileStorageId((prevState) => ({
    //   ...prevState,
    //   [id]: null, // Assuming you want to reset the FileList to null
    // }));

    // Perform any other actions with Data as needed
  };

  const [SelectedStudents, setSelectedStudents] = useState<any[]>([]);
  const [ShowAllFirstAction, setShowAllFirstAction] = useState<boolean>(false);
  const [ShowFirstPOP, setShowFirstPOP] = useState<boolean>(false);
  const [ShowFirstMessagePOP, setShowFirstMessagePOP] =
    useState<boolean>(false);
  useEffect(() => {
    if (SelectedStudents.length === 0) {
      setShowAllFirstAction(false);
    } else {
      setShowAllFirstAction(true);
    }
  }, [SelectedStudents]);

  const handleShowFirstPOP = () => {
    if (SelectedStudents.length >= 1) {
      setShowAllFirstAction(true);
      setShowFirstPOP(!ShowFirstPOP);
    }
  };

  const handleShowMessagePOP = () => {
    setShowFirstPOP(!ShowFirstPOP);
    setShowAllFirstAction(true);
    setShowFirstMessagePOP(!ShowFirstMessagePOP);
  };

  const MainContainerRef = useClickOutside(ShowFirstPOP, () =>
    setShowFirstPOP(false)
  );

  const SelectedStudentsData: SelectedStudentsInterface[] =
    SelectedStudents &&
    SelectedStudents.map((item) => ({
      id: item.id,
      FullName: `${item.FirstName} ${item.LastName}`, // Corrected syntax
      email: item.Email, // Changed 'items' to 'item' and corrected capitalization
    }));

  // const [GroupSelectedMessageSubjects, setGroupSelectedMessageSubjects] =
  useState<string>("");
  const [GroupSelectedMessageTexts, setGroupSelectedMessageTexts] =
    useState<string>("");
  const [GroupSelectedErrorMessageTexts, setGroupSelectedErrorMessageTexts] =
    useState<string>("");
  // const [GroupSelectedFile, setGroupSelectedFile] = useState<FileList | null>(
  //   null
  // );

  const handleSelectedGroupMessageSend = () => {
    if (GroupSelectedMessageTexts.trim() === "") {
      setGroupSelectedErrorMessageTexts("This field is required.");
      return false;
    }

    const Data = {
      // MessageValue: GroupSelectedMessageSubjects,
      MessageText: GroupSelectedMessageTexts,
      // File: GroupSelectedFile,
    };
    // setGroupSelectedMessageSubjects("");
    setGroupSelectedMessageTexts("");
    setShowAllFirstAction(false);
    setShowFirstMessagePOP(false);
    // setGroupSelectedFile(null);
  };

  return (
    <IAUVTableView>
      <IAUVTableHeader>
        <IAUVTableHeaderCell>
          <SeletectOptinToogle
            type="checkbox"
            id="selectAll" // Add optional chaining to handle potential undefined
            value="selectAll" // Add optional chaining to handle potential undefined
            checked={selectAllChecked} // Control the checked state of the "Select All" checkbox
            onChange={() =>
              handleSelectAllToggle(
                setSelectAllChecked,
                setCheckedItems,
                filteredData,
                setSelectedStudents
              )
            }
          />
          <CheckboxLabel htmlFor={"selectAll"}>{"Name"}</CheckboxLabel>
        </IAUVTableHeaderCell>
        {InstructorAnalyticsUsersViewTableActionData.map((Action) => (
          <IAUVTableHeaderCell key={Action.id}>
            <MediumTypography text={Action.text || ""} />
          </IAUVTableHeaderCell>
        ))}
        <IAUVTableHeaderCell
          ref={MainContainerRef}
          style={{ position: "relative" }}
        >
          <PointerContainer onClick={handleShowFirstPOP}>
            <SvgMore style={{ transform: "rotate(90deg)" }} />{" "}
          </PointerContainer>
          {ShowFirstPOP && ShowAllFirstAction && (
            <ComplexSelectTableMessagePopup
              style={{
                top: "2.2rem",
              }}
              onClick={handleShowMessagePOP}
            >
              <SvgEmail width={"1.3125rem"} height={"1rem"} />
              <MediumTypography text="Send Feedback" fontSize="0.75rem" />
            </ComplexSelectTableMessagePopup>
          )}
          {ShowAllFirstAction && ShowFirstMessagePOP && (
            <InstructorMessageBox
              ref={clickOutsideRef}
              style={{ right: "1.5rem", top: "-2rem", opacity: "1" }}
            >
              <StudentMessage
                CloseClick={handleShowMessagePOP}
                OneSelected={false}
                SelectedData={SelectedStudentsData}
                SendMessage={handleSelectedGroupMessageSend}
                // SubjectValue={GroupSelectedMessageSubjects || ""}
                // setSubjectValue={(value: React.SetStateAction<string>) =>
                //   setGroupSelectedMessageSubjects(value)
                // }
                MessageValue={GroupSelectedMessageTexts}
                setMessageValue={(value: React.SetStateAction<string>) =>
                  setGroupSelectedMessageTexts(value)
                }
                ErrorMessageValue={GroupSelectedErrorMessageTexts}
                setErrorMessageValue={setGroupSelectedErrorMessageTexts}
                // FileStorage={GroupSelectedFile}
                // setFileStorage={setGroupSelectedFile}
              />
            </InstructorMessageBox>
          )}
        </IAUVTableHeaderCell>
      </IAUVTableHeader>
      {filteredData.map((val, index) => (
        <IAUVTableBody key={val.id}>
          <IAUVTableBodyCell>
            <SeletectOptinToogle
              type="checkbox"
              id={val?.id?.toString() || ""}
              value={val?.id?.toString() || ""}
              checked={checkedItems[index]}
              onChange={() =>
                handleItemToggle(
                  index,
                  checkedItems,
                  setCheckedItems,
                  setSelectedStudents,
                  filteredData
                )
              }
              style={{ alignItems: "center" }}
            />
            <CheckboxLabel
              htmlFor={val?.id?.toString() || ""}
              style={{ alignItems: "center" }}
            >
              <IAUVFirstChild>
                <IAUVImg src={val?.Img} />
                <Typography text={val?.FirstName || "-"} fontSize="0.875rem " />
                <Typography text={val?.LastName || "-"} fontSize="0.875rem " />
              </IAUVFirstChild>
            </CheckboxLabel>
          </IAUVTableBodyCell>
          <IAUVTableBodyCell>
            <LightTypography text={val.id || "-"} fontSize="0.75rem" />
          </IAUVTableBodyCell>
          <IAUVTableBodyCell>
            <LightTypography text={val.Role || "-"} fontSize="0.75rem" />
          </IAUVTableBodyCell>
          <IAUVTableBodyCell>
            <LightTypography text={val.Email || "-"} fontSize="0.75rem" />
          </IAUVTableBodyCell>
          <IAUVTableBodyCell>
            <LightTypography text={val.LastAccess || "-"} fontSize="0.75rem" />
          </IAUVTableBodyCell>
          <IAUVTableBodyCell>
            <InstructorViewCompletionOptionButton
              onClick={() =>
                handleOptionButtonClick(
                  index,
                  selectedOptionIndex,
                  setSelectedOptionIndex
                )
              }
            >
              <SvgMore
                //  style={{ transform: "rotate(90deg)" }}
                style={{
                  transform: `rotate(${
                    selectedOptionIndex === index ? "0deg" : "90deg"
                  })`,
                }}
              />
            </InstructorViewCompletionOptionButton>
            {selectedOptionIndex === index && shapeToggles[index] ? (
              <InstructorViewBox
                ref={clickOutsideRef}
                style={{ right: "4.5rem", top: "3rem" }}
              >
                <IdTwoActions
                  link={`CourseActivityStudent/${val.id}`}
                  messageClick={() =>
                    handleChangeShapeToggle(
                      index,
                      shapeToggles,
                      setShapeToggles
                    )
                  }
                />
              </InstructorViewBox>
            ) : selectedOptionIndex === index &&
              shapeToggles[index] === false ? (
              <InstructorMessageBox
                ref={clickOutsideRef}
                style={{ right: "1.5rem", top: "-2rem" }}
              >
                <StudentMessage
                  CloseClick={() =>
                    handleChangeShapeToggle(
                      index,
                      shapeToggles,
                      setShapeToggles
                    )
                  }
                  ReserverName={val.FirstName + " " + val.LastName}
                  SendMessage={() => handleMessageSend(index, val.id)}
                  // SubjectValue={messageSubjects[val.id] || ""} // Get the value for the specific id or default to an empty string
                  // setSubjectValue={(value: string) =>
                  //   updateMessageText(val.id, value, setMessageSubjects)
                  // } // Update the value for the specific id
                  MessageValue={messageTexts[val.id] || ""} // Get the value for the specific id or default to an empty string
                  setMessageValue={(value: string) =>
                    updateMessageText(val.id, value, setMessageTexts)
                  } // Update the value for the specific id
                  ErrorMessageValue={ErrormessageTexts[val.id] || ""}
                  setErrorMessageValue={(value: string) =>
                    updateMessageText(val.id, value, setErrorMessageTexts)
                  }
                  // FileStorage={FileStorageId[val.id]}
                  // setFileStorage={(files) =>
                  //   setFileStorageId((prevState) => ({
                  //     ...prevState,
                  //     [val.id]: files,
                  //   }))
                  // }
                />
              </InstructorMessageBox>
            ) : null}
          </IAUVTableBodyCell>
        </IAUVTableBody>
      ))}
    </IAUVTableView>
  );
};

export default InstructorAnalyticsUsersViewTable;
