import styled from "styled-components";
import { theme } from "../../../theme";
import { StyledInputInterface } from "../../../elements/StyledInput/StyledInputInterface";

export const ShortAnswerContainer = styled.div`
  padding-left: 1.4rem;
  padding-right: 1.4rem;
`;

export const ShortAnswerInputs = styled.input<StyledInputInterface>`
  width: 100%;
  height: 2.8125rem;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  background: transparent;
  &:dir(ltr) {
    padding-left: 1.25rem;
  }
  &:dir(rtl) {
    padding-right: 1.25rem;
  }
  font-family: "Primary Font-Light";
  color: ${(props) => props.themetextcolor};
  &:focus {
    outline: none;
    caret-color: ${(props) => props.themetextcolor};
  }
`;
