import React, { useContext, useEffect, useRef, useState } from "react";
import {
  NotificationDataContainer,
  NotificationLineMainContainer,
  NotificationLineScrollableContainer,
  NotificationRenderContainer,
  ShowMoreContainer,
  VerticalDashedLine,
} from "./NotificationVerticalLine.styled";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../../elements/fonts/Fonts";
import {
  NotificationMenuIconSpaceContainer,
  NotificationMenuV2BtnChape,
} from "../../notificationDrop/NotificationMenuV2/NotificationMenuV2.style";
import { getIconBasedOnType } from "../../../elements/StudentLandingTable/StudentLandingTableFunction";
import { FlexAppContainer, NormalProfileImgAPP } from "../../../App.style";
import {
  ManyOptionsDate,
  OptimizeColor,
  ShowDismissButton,
  ShowViewMyGradeButton,
  TextBeforeDateDisplays,
} from "../../notificationDrop/NotificationMenuV2/Functions";
import { formatDateJustDate } from "../../../utils/FormatedDATE";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../../utils/StringManipulation";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import { WebSocketConnector } from "../../../Views/messages/webSocketConnection";
import { LoadingComp } from "../../LoadingComp/LoadingComp";
import axios from "axios";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import {
  translateTextDisplays,
  translateTitleOrName,
} from "../TranslationFunc";
import { ThemeDetectIconColor } from "../../../utils/ResourceIcon";
import VerticalRadius from "./VerticalRadius";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

const NotificationVerticalLine = () => {
  const navigate = useNavigate();

  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const [loading, setLoading] = useState<boolean>(true);
  const tenantName = localStorage.getItem("tenant");
  const { notificationData, setNotificationData, setUnviewedNotificationData } =
    useContext(ClassContext);
  const [NBOfNotificationShows, setNBOfNotificationShows] =
    useState<number>(20);
  const { t } = useTranslation();
  const generateLinkData = (item: any) => {
    let navigationLink = "";
    let providedState = {};
    if (item.type === "announcement") {
      navigationLink = `/announcements/${item.refId}`;
    } else if (item.type === "missingAttendance") {
      toast.error(`${item.TitleOrName}`);
    } else {
      if (item.contentid === "") {
        navigationLink = "#";
        providedState = {};
        toast.error(t("This content was deleted"));
      } else {
        navigationLink = `/courseView/content/${item.classSourceId}`;

        // providedState = {
        //   moduleIndex: item.moduleindex,
        //   topicIndex: item.topicindex,
        //   contentId: item.contentid,
        //   isVisible: item?.isvisible,
        // };
      }

      //  else {
      //   navigationLink = ``;
      //   providedState = {};
      // }
    }

    return { navigationLink, providedState };
  };

  const fetchUnviewedNotification = async () => {
    try {
      const unviewedNotificationResponse = await axios.get(
        `https://nfo4qku4p7.execute-api.eu-west-1.amazonaws.com/dev/get/unviewed/${subIdSplitted}/${tenantName}?provider=${provider}`
      );
      setUnviewedNotificationData(unviewedNotificationResponse.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // fetchUnviewedNotification();
    // fetchAllNotifications()
    setLoading(false);
    fetchUnviewedNotification();
  }, []);

  const updateNotificationViews = async () => {
    if (notificationData && notificationData.length > 0) {
      const data = {
        subId: subIdSplitted,
        provider: provider,
        notifications: notificationData,
      };
      const createResponse = await axios.post(
        `https://nfo4qku4p7.execute-api.eu-west-1.amazonaws.com/dev/create/views/${tenantName}`,
        {
          data: data,
        }
      );
    }
  };

  useEffect(() => {
    updateNotificationViews();
  }, [notificationData]);
  const sortedNotificationDropData =
    notificationData &&
    notificationData.length > 0 &&
    notificationData
      .slice() // Create a copy of the array before sorting
      .sort((a, b) => {
        const dateA = new Date(a.ArrivalDate);
        const dateB = new Date(b.ArrivalDate);
        return dateB.getTime() - dateA.getTime();
      });

  const {
    NotifyBox_BgColor_IconHolder,
    theme,
    themeTextColor,
    backgroundColor,
    NotifyBox_BgColor,
  } = useRecoilValue(ColorSelector);
  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };

  const handleNotificationClick = (item: any) => {
    console.log("item", item)
    localStorage.setItem(`selectedResource-${item.classSourceId}`, JSON.stringify({ contentId: item.contentid, moduleIndex: item.moduleindex, topicIndex: item.topicindex, isVisible: item?.isvisible }));
    const { navigationLink, providedState } = generateLinkData(item);
    navigate(navigationLink, { state: providedState });
  };
  const CursorType = (item: any) => {
    if (item.type === "missingAttendance") {
      return "not-allowed";
    } else {
      if (item.contentid === "") {
        return "not-allowed";
      }
    }
    return "pointer";
  };
  return (
    <>
      <ToastContainer />
      {loading ? (
        <LoadingComp />
      ) : (
        <NotificationLineMainContainer
          theme={theme}
          color={themeTextColor}
          bgcolor={backgroundColor}
        >
          {/* <Typography text="Notifications" fontSize="1.125rem" /> */}

          <NotificationLineScrollableContainer>
            {sortedNotificationDropData &&
              sortedNotificationDropData.length > 0 &&
              sortedNotificationDropData.map((x: any, index: number) => (
                <div
                  style={{
                    position: "absolute",
                    marginTop: `${index * 10}rem`,
                    marginLeft: "0.3rem",
                  }}
                >
                  <VerticalRadius />
                </div>
              ))}

            {sortedNotificationDropData &&
              sortedNotificationDropData.length > 0 &&
              sortedNotificationDropData
                .slice(0, NBOfNotificationShows)
                .map((item: any) => {
                  return (
                    <NotificationRenderContainer
                      color={OptimizeColor(item.DueDate)}
                      key={item.id} // Ensure you have a unique key
                    >
                      {item.type === "Posts" || item.type === "createSubmit" ? (
                        <NormalProfileImgAPP
                          src={
                            item.ProfilePic ||
                            require("../../../assets/userProfile.png")
                          }
                          ImgSize="2.25rem"
                        />
                      ) : (
                        <NotificationMenuIconSpaceContainer
                          color={ThemeDetectIconColor(item.type, theme)}
                          bgcolor={NotifyBox_BgColor_IconHolder}
                        >
                          {getIconBasedOnType(
                            item.type,
                            item.refTable ? item.refTable : ""
                          )}
                        </NotificationMenuIconSpaceContainer>
                      )}
                      {/* <Link to={navigationLink} state={providedState}
                    > */}
                      <NotificationDataContainer
                        bgcolor={NotifyBox_BgColor}
                        onClick={() => {
                          handleNotificationClick(item);
                        }}
                        cursor={CursorType(item)}
                      >
                        <FlexAppContainer
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <MediumTypography
                            text={item.CourseCode || ""}
                            fontSize="0.75rem"
                            color={themeTextColor}
                          />
                          <Typography
                            text={ManyOptionsDate(item.ArrivalDate, t)}
                            fontSize="0.75rem"
                            color="#5DD3B3"
                          />
                        </FlexAppContainer>
                        <LightTypography
                          text={translateTitleOrName(item, t)}
                          fontSize="0.625rem"
                          color={
                            item.type === "missingSubmit"
                              ? "#D85D66"
                              : themeTextColor
                          }
                        />
                        {item.TextDisplays ? (
                          <p
                            style={{
                              fontFamily: "Primary Font-Light",
                              fontSize: "0.625rem",
                              color:
                                item.type === "missingSubmit"
                                  ? "#D85D66"
                                  : themeTextColor,
                            }}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                decodeHtml(translateTextDisplays(item, t))
                              ),
                            }}
                          ></p>
                        ) : (
                          <LightTypography
                            text={`${TextBeforeDateDisplays(
                              item.DueDate
                            )} ${formatDateJustDate(item.DueDate)}`}
                            fontSize="0.625rem"
                          />
                        )}
                        {ShowDismissButton(item.DueDate, item.Viewed) && (
                          <NotificationMenuV2BtnChape>
                            <Typography text="Dismiss" fontSize="0.625rem" />
                          </NotificationMenuV2BtnChape>
                        )}
                        {ShowViewMyGradeButton(
                          item.type,
                          item.TextDisplays
                        ) && (
                            <NotificationMenuV2BtnChape color="#5DD3B3">
                              <Typography
                                text="View my grade"
                                fontSize="0.625rem"
                              />
                            </NotificationMenuV2BtnChape>
                          )}
                      </NotificationDataContainer>
                      {/* </Link> */}
                    </NotificationRenderContainer>
                  );
                })}
          </NotificationLineScrollableContainer>
          {sortedNotificationDropData &&
            sortedNotificationDropData.length > NBOfNotificationShows && (
              <ShowMoreContainer>
                <div
                  style={{
                    display: "flex",
                    alignSelf: "right",
                    width: "fit-content",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setNBOfNotificationShows(NBOfNotificationShows + 20)
                  }
                >
                  <Typography
                    text={t("survey.Show More")}
                    fontSize="0.875rem"
                  />
                </div>
              </ShowMoreContainer>
            )}
        </NotificationLineMainContainer>
      )}
    </>
  );
};

export default NotificationVerticalLine;
