import React, { useEffect, useState } from "react";
import ScrumDetailTable from "../../../AnalyticComplexSelectedTables/ScrumDetail/ScrumDetailTable";
import { AnalyticMainBoxContainer } from "../../../AnalyticComplexSelectedTables/AnalyticsAllAssigment.style";
import SurveyReusableHeader from "../../../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableHeader";
import { ExcelDownload } from "../../../../utils/ExcelGlobalFunction";
import ScromFitlerCompo from "../ScromFitlerCompo";
import Pagination from "../../../../elements/Pationation/Pagination";
import { ScrumStudentDetailTableInterface } from "../ScrumDetailTableData";
import { DataArrayProps } from "../../../../interfaces/AnalyticsCommonInterfaces";

const ScrumDetailTableCompo = ({ Data, handleTextClick }: DataArrayProps) => {
  const StudentsData: ScrumStudentDetailTableInterface[] = Data?.Students;
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState(StudentsData?.length);
  const [SubmittedSearchText, setSubmittedSearchText] = useState("");
  const [ShowPagination, setShowPagination] = useState(false);
  // const filteredDataLimited = Data.slice(0, limit);
  const itemsPerPage = limit;
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const filteredData = StudentsData?.filter((val) => {
    const matchesSearchText = Object.values(val)
      .join(" ")
      .toLowerCase()
      .includes(searchText.toLowerCase());

    let matchesSubmittedSearchText = true;

    if (SubmittedSearchText === "Submitted") {
      matchesSubmittedSearchText = val.ResponsesDate !== undefined;
    } else if (SubmittedSearchText === "Unattempted") {
      matchesSubmittedSearchText =
        val.ResponsesDate === undefined ||
        (Array.isArray(val.ResponsesDate) && val.ResponsesDate.length === 0);
    } else if (SubmittedSearchText === "Submission after Due date") {
      matchesSubmittedSearchText =
        val.ResponsesDate !== undefined &&
        val.DueDate !== undefined &&
        val.ResponsesDate > val.DueDate;
    }

    return matchesSubmittedSearchText && matchesSearchText;
  }).slice(startIndex, endIndex);

  return (
    <AnalyticMainBoxContainer>
      <SurveyReusableHeader
        setShowPagination={setShowPagination}
        title="Scrom Detail"
        data={StudentsData}
        onClickExcelDowload={() => ExcelDownload(StudentsData, "Scorm Detail")}
        setSearchText={setSearchText}
        setLimit={setLimit}
        FilteredOptions={<ScromFitlerCompo setText={setSubmittedSearchText} />}
        HeaderTextClick={handleTextClick}
      />
      <ScrumDetailTable
        Grade={Data.Graded || false}
        filteredData={filteredData}
        MaxGrade={Data.MaxGrade}
      />
      {ShowPagination && (
        <Pagination
          currentPage={currentPage}
          total={StudentsData.length}
          limit={itemsPerPage}
          onPageChange={(page: number) => setCurrentPage(page)}
          MarginTop="1rem"
          justifyContent="left"
          Padding="0 0 0 5.5rem"
          BoxSize="1.85rem"
          ScrollTop={false}
        />
      )}
    </AnalyticMainBoxContainer>
  );
};

export default ScrumDetailTableCompo;
