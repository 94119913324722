import React, { useState } from "react";
import {
  MainHolder,
  InputHolder,
  SearchIconHolder,
} from "./ReusableInput.style";
import { FC } from "react";
import { InputInterface } from "./reusableInputInterface";
import { SvgSearchInput } from "../Icons";
import { LightTypography } from "../fonts/Fonts";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

const ReusableTitleInput: FC<InputInterface> = ({
  placeHolder,
  inputWidth,
  inputHeight,
  inputBorderRadius,
  inputBorder,
  padingLeft,
  display,
  value,
  right,
  left,
  holderWidth,
  handleSchemeTitleChange,
}) => {
  const { inputBorderColor, themeTextColor } = useRecoilValue(ColorSelector);
  return (
    <MainHolder inputwidth={inputWidth || "100%"}>
      <InputHolder
        placeholder={placeHolder || "Announcement Title"}
        inputheight={inputHeight || "3.5rem"}
        inputborderradius={inputBorderRadius || "0.94rem"}
        inputborder={inputBorder || `1px solid ${inputBorderColor}`}
        padingleft={padingLeft || "1.19rem"}
        onChange={handleSchemeTitleChange}
        value={value}
        type="text"
        holderWidth={holderWidth || "100%"}
        color={themeTextColor}
      />

      <SearchIconHolder
        display={display || "none"}
        right={right || "1.25rem"}
        left={left || "none"}
      >
        <SvgSearchInput />
      </SearchIconHolder>
    </MainHolder>
  );
};

export default ReusableTitleInput;
