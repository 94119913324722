import React, { useEffect, useState } from "react";
import { QuestionType, TypesContainer } from "./QuestionBanksTypes.style";
import {
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import CheckBoxInput from "../../../elements/checkbox/CheckBox";
import { FlexDiv } from "../../../globalStyles";
import { QuestionTypesData } from "./QuestionBanksTypes.data";
import { useMyContext } from "../../../contexts/QuestionBanksContext";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  CourseColorSelector,
} from "../../../recoil/ThemeSelectors";

const QuestionBanksTypes = ({ questionToEdit, editMode }: any) => {
  const { t } = useTranslation();
  const {
    currentView,
    setCurrentView,
    SetQuestionToUpdate,
    setUpdateMode,
    updateMode,
  } = useMyContext();

  // function to handle the question type selected
  const handleTypeChange = (value: string) => {
    setCurrentView(value);
    // set the question content to empty if the user switch to another question type in updateMode
    SetQuestionToUpdate({});
  };

  // Using ternary operation to set update mode based on editMode
  setUpdateMode(editMode ? true : false);
  // console.log(editMode ? "Update Mode" : "Create Mode");

  // Switches the current view based on the contentType of the question to edit.
  // Sets the questionToEdit content that comes from props.
  useEffect(() => {
    if (updateMode === true) {
      setCurrentView(questionToEdit.contentType);
      SetQuestionToUpdate(questionToEdit);
    }
  }, [updateMode, questionToEdit]);
  const { ClassGradientBox } = useRecoilValue(CourseColorSelector);
  // const { Text_Color } = useRecoilValue(ColorSelector);
  return (
    <QuestionType bgcolor={ClassGradientBox}>
      <Typography text={t("question bank.Question Types")} />
      <TypesContainer>
        {QuestionTypesData.map((type, index) => (
          // render question types to select and switch
          <FlexDiv
            gap="0.62rem"
            key={index}
            onClick={() => {
              handleTypeChange(`${type.value}`);
            }}
            pointer="true"
          >
            <CheckBoxInput
              name="type"
              value={type.value}
              checked={currentView === type.value}
            />
            <TypographyRegularDescription
              text={t(`quiz.${type.typeName}`)}
              fontSize="0.875rem"
            />
          </FlexDiv>
        ))}
      </TypesContainer>
    </QuestionType>
  );
};

export default QuestionBanksTypes;
