import styled from "styled-components";

export const FiltersSection = styled.div`
display: flex;
flex-direction: column;
gap: 0.625rem;
`
export const FilterButtonsCont = styled.div`
display: flex;
align-items: center;
gap: 0.625rem;
`
export const FilterButton = styled.div`
padding: 0.5rem 1.25rem;
border-radius: 0.3125rem;
display: flex;
justify-content: center;
align-items: center;
font-family: "Primary Font-Medium";
font-size: 0.875rem;
background-color: "transparent";
color: #5DD3B3;
border: 1px solid #5DD3B3;
cursor: pointer;
box-shadow: 0 0 0 rgba(0, 0, 0, 0);
transition: box-shadow 0.3s ease;
&:hover {
    box-shadow: 0 0 1rem rgba(42, 243, 156, 0.3);
  }
`;