import React, { useEffect, useState } from "react";
// import { DropMenuData } from "../DropDownMenuData";
import {
  SvgProfileIcon,
  SvgSettionIcon,
  SvgHelpIcon,
  SvgLogoutIcon,
  SvgInstructorInformation,
} from "../../../elements/Icons";
import { Menu, MenuWrapper, Frame, Icon } from "./DropDownItems.style";
import { TypographyRegularDescription } from "../../../elements/fonts/Fonts";
import { Link } from "react-router-dom";
import { DropDownMenuItem } from "../DropDownDataInterface";
import { useTranslation } from "react-i18next";
import ToggleSwitch from "../../../elements/themeSwitch/ThemeSwitch";
import { useRoleProvider } from "../../../contexts/RoleContext";

export const ISUDropDownMenuItem = (): JSX.Element => {
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>();
  const { roleProvider, isLoading } = useRoleProvider();
  const DropMenuData: DropDownMenuItem[] = [
    {
      icon: SvgInstructorInformation,
      title: t("User Profile.Instructor Information"),
      path: "/InstructorInformation",
    },

    {
      icon: SvgHelpIcon,
      title: t("help.Help"),
      path: "/help",
    },
  ];

  useEffect(() => {
    if (roleProvider === "student") {
      const filteredData = DropMenuData.filter(
        (item: any) => item.path !== "/InstructorInformation"
      );
      setData(filteredData);
    } else {
      // Include all items
      setData(DropMenuData);
    }
  }, []);
  return (
    <Menu>
      {data &&
        data.map((item, index) => (
          <Link to={item.path}>
            <Frame key={index}>
              <item.icon />
              <TypographyRegularDescription
                fontSize="0.875rem"
                text={item.title}
              />
            </Frame>
          </Link>
        ))}
      <ToggleSwitch />
    </Menu>
  );
};
