import React, { FC, useState, useEffect } from "react";
import {
  CustomCheckboxWrapper,
  CheckBox,
  TrueFalseContainer,
} from "./TrueFalseQuestion.style";
import { TrueFalseQuestionProps } from "./TrueFalseQuestionInterface";
import { LightTypography } from "../../../elements/fonts/Fonts";
import { ErrorDiv } from "../../../elements/ErrorDiv/Errordiv.style";
import { useTranslation } from "react-i18next";
import { mapErrorType } from "../../../utils/QuizErrorTypes";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";

const TrueFalseQuestion: FC<TrueFalseQuestionProps> = ({
  choice,
  qindex,
  showQuest,
  handleChoiceChecked,
  questionError,
}) => {
  const [isChecked, setIsChecked] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (choice?.isChecked !== undefined) {
      setIsChecked(choice?.isChecked);
    }
  }, [choice]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    handleChoiceChecked(qindex, !isChecked);
  };

  const errorType = mapErrorType(questionError || "", t);
     
  const { RadioButtonGradientColor} = useRecoilValue(ColorSelector);
  return (
    <TrueFalseContainer>
      <CustomCheckboxWrapper>
        {!showQuest ? (
          <>
            <CheckBox
              type="radio"
              checked={isChecked === true}
              name="isChecked"
              id="isChecked1"
              onChange={handleCheckboxChange}
   
              bgcolor={RadioButtonGradientColor}
           

            />
            <div style={{ textTransform: "capitalize" }}>
              <LightTypography fontSize="0.875rem" text={t("quiz.TRUE")} />
            </div>
          </>
        ) : (
          ""
        )}
      </CustomCheckboxWrapper>
      <CustomCheckboxWrapper>
        {!showQuest ? (
          <>
            <CheckBox
              type="radio"
              checked={isChecked === false}
              name="isChecked"
              id="isChecked2"
              onChange={handleCheckboxChange}
              bgcolor={RadioButtonGradientColor}
            />
            <LightTypography fontSize="0.875rem" text={t("quiz.FALSE")} />
          </>
        ) : (
          ""
        )}
      </CustomCheckboxWrapper>
      {/* {questionError && qindex === 0 && (
        <ErrorDiv style={{ marginLeft: "1.56rem" }}>{errorType}</ErrorDiv>
      )} */}
    </TrueFalseContainer>
  );
};

export default TrueFalseQuestion;

// import React, { FC, useState, useEffect } from "react";
// import {
//   CustomCheckboxWrapper,
//   CheckBox,
//   TrueFalseContainer,
//   TrueFalseInputs,
// } from "./TrueFalseQuestion.style";
// import { TrueFalseQuestionProps } from "./TrueFalseQuestionInterface";
// import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete 1 copy.svg";
// import { LightTypography } from "../../../elements/fonts/Fonts";

// const TrueFalseQuestion: FC<TrueFalseQuestionProps> = ({
//   choice,
//   qindex,
//   showQuest,
//   handleChoiceMatchingQuestion,
//   handleChoiceChecked,
//   allChoices,
// }) => {
//   const [isChecked, setIsChecked] = useState<boolean | undefined>(false);
//   // const [trueChoiceIndex, setTrueChoiceIndex] = useState(0);

//   const handleCheckboxChange = () => {
//     const trueChoiceIndex = allChoices.findIndex(
//       (choice) => choice?.isChecked === true
//     );
//     if (qindex !== trueChoiceIndex) {
//       if (trueChoiceIndex !== -1) {
//         handleChoiceChecked(trueChoiceIndex, false);
//       }

//       setIsChecked(!isChecked);
//       handleChoiceChecked(qindex, true);
//     } else {
//       setIsChecked(!isChecked);
//       handleChoiceChecked(qindex, !isChecked);
//     }
//   };
//   useEffect(() => {
//     setIsChecked(choice?.isChecked);
//   }, [choice]);

//   return (
//     <TrueFalseContainer>
//       <CustomCheckboxWrapper>
//         <CustomCheckboxWrapper>
//           {!showQuest ? (
//             <>
//               <CheckBox
//                 type="radio"
//                 checked={isChecked === true}
//                 name="isChecked"
//                 id="isChecked1"
//                 onChange={handleCheckboxChange}
//               />
//               <LightTypography fontSize="0.875rem" text="True" />
//             </>
//           ) : (
//             ""
//           )}
//         </CustomCheckboxWrapper>
//       </CustomCheckboxWrapper>
//       {/* <TrueFalseInputs
//         center="no"
//         show={showQuest.toString()}
//         width="100%"
//         id="name"
//       >
//         True
//       </TrueFalseInputs> */}
//       {/* <input type="radio" /> */}
//       <CustomCheckboxWrapper>
//         <CustomCheckboxWrapper>
//           {!showQuest ? (
//             <>
//               <CheckBox
//                 type="radio"
//                 checked={isChecked === false}
//                 name="isChecked"
//                 id="isChecked2"
//                 onChange={handleCheckboxChange}
//               />
//               False
//             </>
//           ) : (
//             ""
//           )}
//         </CustomCheckboxWrapper>
//       </CustomCheckboxWrapper>
//       {/* <TrueFalseInputs
//         center="no"
//         show={showQuest.toString()}
//         width="100%"
//         id="name"
//       >
//         False
//       </TrueFalseInputs> */}

//       {/* {!showQuest ? (
//         <DeleteIcon onClick={() => removeChoice(qindex)} />
//       ) : (
//         <DeleteIcon />
//       )} */}
//     </TrueFalseContainer>
//   );
// };

// export default TrueFalseQuestion;
