import styled from 'styled-components';


export const QuestionMainContainer = styled.div`
padding:0.88rem 1.25rem 0.88rem ;
`;


export const LineBreakQuestion = styled.div`
width: 100%;
min-height: 0.0625rem;
background: #576474;
margin-top:0.94rem;
`;





