import styled from "styled-components";


export const ForumDetailsColumnContainer = styled.div`
width:100%;
display: flex;
flex-direction:column;
gap:1.25rem;
`;
export const ForumDetailsRowContainer = styled.div`
width:100%;
display: flex;
gap:1.25rem;
flex-wrap: wrap;
`;


export const ForumDetailsContentContainer = styled.div<{ ColumnDisplay?: boolean }>`
  min-width: 19rem;
flex:1;
  height: 20rem;
  display: flex;
  flex-direction: ${({ ColumnDisplay }) => (ColumnDisplay ? 'column' : 'row')};
  border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
padding: 1.25rem;

&:first-child{
  flex:1.25;
  min-width: 26rem;
}
`;
