export const formatDate = (createdDate: string, t: any) => {
  const currentDate = new Date();
  const announcementDate = new Date(createdDate);
  const timeDifference = currentDate.getTime() - announcementDate.getTime();
  const minutesDifference = Math.floor(timeDifference / (1000 * 60));
  const language = localStorage.getItem("language");

  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  const formatter = new Intl.DateTimeFormat(
    `${
      language === "tr"
        ? "tr-TR"
        : language === "ar"
        ? "ar-AE"
        : language === "fr"
        ? "fr-FR"
        : "en-US"
    }`,
    options
  );
  const parts = formatter.formatToParts(announcementDate);

  let day = parts.find((part) => part.type === "day")?.value;
  let monthName = parts.find((part) => part.type === "month")?.value;
  let year = parts.find((part) => part.type === "year")?.value;
  let hour = parts.find((part) => part.type === "hour")?.value;
  let minute = parts.find((part) => part.type === "minute")?.value;
  let dayPeriod = parts.find((part) => part.type === "dayPeriod")?.value;

  // Function to convert Arabic numerals to Eastern Arabic numerals
  const convertToArabicNumerals = (numStr: any) => {
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return numStr.replace(/\d/g, (digit: any) => arabicNumerals[digit]);
  };

  if (language === "ar") {
    day = convertToArabicNumerals(day);
    year = convertToArabicNumerals(year);
    hour = convertToArabicNumerals(hour);
    minute = convertToArabicNumerals(minute);
  }

  if (minutesDifference < 1) {
    return t("course view.Now");
  } else if (minutesDifference < 60) {
    // ar
    return language === "ar"
      ? `${t("course view.ago")} ${convertToArabicNumerals(
          minutesDifference.toString()
        )} ${
          minutesDifference > 1 && minutesDifference < 11
            ? t("course view.minutes")
            : t("course view.minute")
        } `
      : // fr
      language === "fr"
      ? `${t("course view.ago")} ${minutesDifference} ${t(
          "course view.minute"
        )}${minutesDifference > 1 ? "s" : ""}`
      : // en and tr
        `${minutesDifference} ${t("course view.minute")}${
          minutesDifference > 1 && language === "en" ? "s" : ""
        } ${t("course view.ago")}`;
  } else if (minutesDifference < 1440) {
    // 1440 minutes in a day (24 hours)
    const hoursDifference = Math.floor(minutesDifference / 60);

    // ar
    return language === "ar"
      ? `${t("course view.ago")} ${convertToArabicNumerals(
          hoursDifference.toString()
        )} ${
          hoursDifference > 1 && hoursDifference < 11
            ? t("course view.hours")
            : t("course view.hour")
        } `
      
      // fr
      : language === "fr"
      ? `${t("course view.ago")} ${hoursDifference} ${t("course view.hour")}${
          hoursDifference > 1 ? "s" : ""
        }`

      // en and tr
      : `${hoursDifference} ${t("course view.hour")}${
          hoursDifference > 1 && language === "en" ? "s" : ""
        } ${t("course view.ago")}`;
      
  } else if (minutesDifference < 2880) {
    // 2880 minutes in two days
    return `${t("course view.Yesterday at")} ${hour}:${minute} ${dayPeriod}`;
  } else {
    return `${monthName} ${day}, ${year} | ${hour}:${minute} ${dayPeriod}`;
  }
};
