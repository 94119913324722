import { CourseActivityStudentInterface } from "./CourseActivityStudentIDInterface";

export const CourseActivityStudentData: CourseActivityStudentInterface[] = [
  {
    student_id: "4",
    ActivityCode:"DSfdsfdsgfdcsxc",
    ClassCode:"czxcweqrewrwerwdsfe",
    profilePic: require("../../assets/NourInstructor.jpg"),
    firstName: "Nour",
    LastName: "Mohammad",
    OverallGrade: "B",
    Data: [
      {
        id: "1",
        ActivityResourceName: "Assignment",
        ActivityResourceType: "Assignment",
        StartDate: new Date("2023-08-01T08:00:00Z"),
        DueDate: new Date("2023-08-09T08:00:00Z"),
        CloseDate: new Date("2023-09-20T10:00:00Z"),
        Status: "Not Started",
        Grade: "60",
      },
      {
        id: "2",
        ActivityResourceName: "Quiz",
        ActivityResourceType: "Quiz",
        StartDate: new Date("2023-09-05T10:00:00Z"),
        // DueDate: new Date("2023-09-12T10:00:00Z"),
        CloseDate: new Date("2024-09-20T10:00:00Z"),
        Status: "Not Started",
        // Grade: "60",
      },
      {
        id: "3",
        ActivityResourceName: "Discussion Forum",
        ActivityResourceType: "Forum",
        StartDate: new Date("2023-09-15T15:00:00Z"),
        DueDate: new Date("2023-09-22T15:00:00Z"),
        CloseDate: new Date("2023-09-30T15:00:00Z"),
        Status: "Active",
        Grade: "90",
      },
      {
        id: "4",
        ActivityResourceName: "Reading Assignment",
        ActivityResourceType: "Assignment",
        StartDate: new Date("2023-10-08T09:30:00Z"),
        DueDate: new Date("2023-10-15T09:30:00Z"),
        CloseDate: new Date("2023-10-25T09:30:00Z"),
        Status: "Not Started",
        Grade: "",
      },
      {
        id: "5",
        ActivityResourceName: "Project About Quiz",
        ActivityResourceType: "Quiz",
        StartDate: new Date("2023-10-18T13:00:00Z"),
        DueDate: new Date("2023-10-25T13:00:00Z"),
        CloseDate: new Date("2023-11-05T13:00:00Z"),
        Status: "Not Submitted",
        Grade: "70",
      },
      {
        id: "6",
        ActivityResourceName: "Survey",
        ActivityResourceType: "Survey",
        StartDate: new Date("2023-11-02T11:00:00Z"),
        DueDate: new Date("2023-11-09T11:00:00Z"),
        CloseDate: new Date("2023-11-20T11:00:00Z"),
        Status: "Not Taken",
        Grade: "",
      },
    ],
  },
];
