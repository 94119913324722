import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Props } from "./DropzoneInterface";
import {
  Container,
  Content,
  SelectedFileDiv,
  FileNameDiv,
} from "./DropZone.style";
import { SvgCloseIcon, SvgDropZoneIcon } from "../../../elements/Icons";
import {
  MediumTypography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";

const DragAndDropZone = ({
  onDrop,
  text,
  acceptedTypes,
  initialFile,
  onRemoveFile,
  isDisabled
}: Props): JSX.Element => {
  const [selectedFiles, setSelectedFiles] = useState<string[]>(
    initialFile ? [initialFile] : []
  );

  const onDropHandler = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0 && onDrop) {
        const newFiles = acceptedFiles.map((file) => file.name);

        // Check if any of the dropped files is a directory
        const isDirectoryDropped = acceptedFiles.some(
          (file) => file.webkitRelativePath !== ""
        );

        if (isDirectoryDropped) {
          // Process files inside the directory
          const filesInsideDirectory = acceptedFiles
            .filter((file) => file.webkitRelativePath !== "")
            .map((file) => file.name);

          setSelectedFiles((prevFiles) => [
            ...prevFiles,
            ...filesInsideDirectory,
          ]);
          onDrop(acceptedFiles);
        } else {
          setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
          onDrop(acceptedFiles);
        }
      }
    },
    [onDrop]
  );

  const removeFile = (fileName: string) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileName)
    );

    if (onRemoveFile) {
      onRemoveFile();
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropHandler,
    accept: acceptedTypes,
    disabled: isDisabled
  });
  const { inputBorderColor } = useRecoilValue(ColorSelector);

  return (
    <>
      <Container bordercolor={inputBorderColor} isDisabled={isDisabled}>
        <div
          style={{
            minHeight: "3rem",
            display: "flex",
            columnGap: "2.19rem",
            rowGap: "0.25rem",
            flexWrap: "wrap",
          }}
        >
          {selectedFiles.map((fileName, index) => (
            <SelectedFileDiv
              key={index}
              onClick={() => removeFile(fileName)}
              style={{ backgroundColor: "#5DD3B3", color: "white" }}
            >
              <FileNameDiv>
                <TypographyRegularDescription
                  text={fileName}
                  fontSize="0.75rem"
                />
              </FileNameDiv>
              <SvgCloseIcon onClick={() => removeFile(fileName)} />
            </SelectedFileDiv>
          ))}
        </div>
        <div {...getRootProps()} style={{ minHeight: "4rem" }}>
          {selectedFiles.length === 0 && (
            <>
              <input {...getInputProps()} />
              <Content>
                <SvgDropZoneIcon />
                <div style={{ paddingTop: "1.25rem", textAlign: "center" }}>
                  <MediumTypography
                    text={text}
                    fontSize="1.125rem"
                    color="#5DD3B3"
                  />
                </div>
              </Content>
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default DragAndDropZone;
