import React,{FC} from 'react';
import { EssayPreviewContainer,EssayTextArea } from './PreviewEssay.style';
import { DropZoneForm } from '../../dropZone/DropZoneForm';
import { PreviewEssayProps } from './PreviewEssayInterface';
import TinyEditor from '../../../elements/tinyEditor/TinyEditor';
import { t } from 'i18next';
 const PreviewEssay:FC<PreviewEssayProps> = ({choice}) => {
    
    return (
    <EssayPreviewContainer>
       {/* <EssayTextArea placeholder='write your answer'/> */}
       <TinyEditor placeholder={t('quiz.write your answer')} readonly height="13.0625rem"/>
       {choice.isChecked?
        <DropZoneForm header=''/>:''
       }
        </EssayPreviewContainer>
    )
}

export default PreviewEssay;