import { styled } from "styled-components";
interface Theme {
  backGroundPosition?: string;
  PaddingLeft?: string;
}
interface CourseCardImageProps {
  bgimage: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
  margin-bottom: 1.25rem;
`;

export const HeadPage = styled.div`
  width: 100%;
  display: flex;
  gap: 0.63rem;
  align-items: center;
`;

export const Sorting = styled.button`
  width: 12.5625rem;
  height: 3.125rem;
  border-radius: 6.25rem;
  border: 1px solid #5dd3b3;
  background-color: transparent;
  color: #5dd3b3;
  font-family: "Primary Font-Medium";
  font-size: 0.875rem;
  cursor: pointer;
`;
export const CoursesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr)); /* Repeat until 100% width */
  gap: 1.25rem 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const CourseCard = styled.div<{boxShadow?: string, theme: any}>`
  max-width: 18.4375rem;
  /* min-height: 25.875rem; */
  height: 14.774rem;
  border-radius: 1.25rem;
  background: ${props => props.theme === "light" ? "#fff" : "linear-gradient(180deg, #415160 0%, #293440 100%)"};
  cursor: pointer;
  position: relative;

  /* Initial box-shadow */
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);

  /* Transition for smooth effect */
  transition: box-shadow 0.3s ease;

  /* Hover effect */
  &:hover {
    box-shadow: ${props => props.boxShadow};
  }
`;
export const CourseCardImage = styled.div<CourseCardImageProps>`
  width: 100%;
  height: 9.375rem;
  object-fit: fill;
  border-radius: 1.25rem 1.25rem 0 0;
  background-image: url(${(props) => props.bgimage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

export const CourseCardDesc = styled.div`
  width: 100%;
  height: fit-content;
  padding: 0.62rem 0.62rem 1.81rem 0.62rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const CourseCode = styled.div<{ gradientColor: string }>`
  position: absolute;
  background: linear-gradient(
    to right,
    ${(props) => props.gradientColor} 0%,
    ${(props) => props.gradientColor} 100%
  );
  border-radius: 0.56rem;
  padding: 0.6rem 0.5rem 0.6rem 0;
  width: 5.0625rem;
  text-align: right;
  margin: 1.87rem -0.63rem;
  font-family: "Primary Font-Medium";
  font-size: 0.75rem;
  color: #fff;
`;

export const FlexDivRow = styled.div<{ gap?: string }>`
  display: flex;
  gap: ${({ gap }) => (gap ? gap : "0.5rem")};
  align-items: center;
`;
export const FlexDivCol = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : "0.5rem")};
`;

export const CourseCategoryCont = styled.div<{ gap?: string }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ gap }) => (gap ? gap : "0.5rem")};
`;

export const CourseCategory = styled.div`
  padding: 0.31rem 0.62rem;
  color: #5dd3b3;
  text-align: center;
  font-family: "Primary Font-medium";
  font-size: 0.875rem;
  border-radius: 0.3125rem;
  border: 1px solid #5dd3b3;
  background-color: transparent;
  width: fit-content;
`;
export const ProgressBarCont = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.63rem;
`;

export const ProgressBarLine = styled.div`
  width: 17.2rem;
  height: 3px;
  background-color: #20272e;
  border-radius: 1.25rem;
`;
export const Progress = styled.div<{ width?: string; backgroundColor?: string }>`
  background-color: #5dd3b3;
  width: ${({ width }) => (width ? width : "0rem")};
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : "#fff")};
  height: 3px;
  border-radius: 1.25rem;
`;
export const FlexBetween = styled.div<{ color?: string }>`
  display: flex;
  justify-content: space-between;
  color: ${({ color }) => (color ? color : "#fff")};
`;

export const NoData = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.63rem;
  padding-top: 7.81rem;
  text-transform: capitalize;
`;

export const FiltersSection = styled.div`
display: flex;
flex-direction: column;
gap: 0.625rem;
`
export const FilterButtonsCont = styled.div`
display: flex;
align-items: center;
gap: 0.625rem;
`
export const FilterButton = styled.div`
padding: 0.5rem 1.25rem;
border-radius: 0.3125rem;
display: flex;
justify-content: center;
align-items: center;
font-family: "Primary Font-Medium";
font-size: 0.875rem;
background-color: "transparent";
color: #5DD3B3;
border: 1px solid #5DD3B3;
cursor: pointer;
box-shadow: 0 0 0 rgba(0, 0, 0, 0);
transition: box-shadow 0.3s ease;
&:hover {
    box-shadow: 0 0 1rem rgba(42, 243, 156, 0.3);
  }
`;
