import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { ContextProvider } from "./contexts/ContextProvider";
import { Auth0Provider } from "@auth0/auth0-react";
import { RecoilRoot, useRecoilValue } from "recoil";
import { CourseCreatorAssignmentProvider } from "./contexts/CourseCreatorAssignmentContext";
// import { RoleProvider } from "./contexts/RoleContext";

// import { UseContext } from "./UseContext";

// const theme = localStorage.get("theme");
const isTouchDevice = () =>
  // "ontouchstart" in window || navigator.maxTouchPoints > 0;
  "ontouchstart" in window;
const backend = isTouchDevice() ? TouchBackend : HTML5Backend;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Auth0Provider
    domain="dev-zidyia.eu.auth0.com"
    clientId="6mKTlpja8CrE1MuM61hP56yIA72kx0WZ"
    authorizationParams={{
      redirect_uri: `${window.location.origin}/callback`,
    }}
  >
    <DndProvider backend={backend}>
      {/* <UseContext> */}
      <ContextProvider>
        <CourseCreatorAssignmentProvider>
          {/* <RoleProvider> */}
          <RecoilRoot>
            <App />
          </RecoilRoot>
          {/* </RoleProvider> */}
        </CourseCreatorAssignmentProvider>
      </ContextProvider>
      {/* </UseContext> */}
    </DndProvider>
  </Auth0Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
