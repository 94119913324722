import styled from "styled-components"
import { breakpoints } from '../../theme';
export const FaqWrapper = styled.div`
  display: flex;
  flex-direction:column;

;`
export const FaqHolder = styled.div`
   /* display: flex;
    justify-content: center;
    column-gap: 4.38rem; */
   margin-top: 2.19rem; 
   columns: 2;
  column-gap: 1.1rem;
   @media (max-width: ${breakpoints.medium}) {
    columns: 1;
    column-gap: 1rem;
    margin-top: 1rem;
  }
  

`
// export const Column = styled.div`
//   /* display: flex;
//   flex-direction: column; */
//   /* row-gap: 1.25rem; */
//   columns: 2;
//   column-gap: 2.38rem;
// `;
export const FaqTitle =styled.h1`

`

