// Define a Comment interface to represent each comment
interface Comment {
    profileImage: string; // URL of the profile image
    userId: string; // User ID of the commenter
    content: string; // Content of the comment
  }
  
  // Define the structure of SCORM data including comments
  export interface ScormData {
    title: string;
    description: string;
    moduleTitle: string;
    comments: Comment[]; // Array of Comment objects
  }
  
  // Example SCORM data with comments
  export const scormData: ScormData = {
    title: "SCORM Resource",
    description: "This SCORM resource is designed to provide interactive learning content for students enrolled in the course. It contains a variety of multimedia elements such as videos, quizzes, and interactive simulations to engage learners and enhance their understanding of the course material. The content is organized into modules and topics, allowing students to navigate through the material at their own pace. Additionally, the SCORM resource tracks student progress and performance, providing instructors with valuable insights into student learning outcomes.",
    moduleTitle: "SCORM Module",
    comments: [
      {
        profileImage: "/assets/images/teacherprof.png",
        userId: "user1",
        content: "Comment 1"
      },
      {
        profileImage: "/assets/images/teacherprof.png",
        userId: "user2",
        content: "Comment 2"
      },
      {
        profileImage: "/assets/images/teacherprof.png",
        userId: "user3",
        content: "Comment 3"
      }
    ]
  };
  