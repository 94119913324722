import styled from "styled-components";

export const SelectedOptionsBox = styled.div<{bgColor? : string,width?:string,marginLeft?:string}>`
display: flex;
flex-direction: column;
min-width: 6.8rem;
width:${props =>props.width || "fit-content"};
height: fit-content;
border-radius: 0.3125rem;
background:${props =>props.bgColor || "#1F2731"};
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
z-index:15;
cursor: pointer;
margin-left:${props=>props.marginLeft};
`;

export const SelectedOptionsLabel = styled.div<{bgColor? : string,hoverBgColor? : string,padding?:string,hoveredColor?:string}>`
display: inline-flex; /* Updated display property */
padding:${(props) => props.padding || "0.63rem"};
width:100% ;
gap:0.62rem;

z-index:15;
&:first-child{
  border-radius: 0.3125rem 0.3125rem 0 0;
}
&:last-child{
  border-radius: 0 0 0.3125rem 0.3125rem;
}
&:hover {
    background: ${(props) => props.hoverBgColor || "#5DD3B3"};
    transition: background 0.3s ease; /* Add transition for background color */
    color:#fff;
  }
`;

