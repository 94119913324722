import React, { ReactNode, createContext, useContext, useState } from "react";

interface QuestionBanksContexProps {
  currentView: string;
  setCurrentView: React.Dispatch<React.SetStateAction<string>>;
  savedQuestions: any[];
  setSavedQuestions: React.Dispatch<React.SetStateAction<any[]>>;
  questionToUpdate: any;
  SetQuestionToUpdate: React.Dispatch<React.SetStateAction<any>>;
  updateMode: boolean;
  setUpdateMode: React.Dispatch<React.SetStateAction<boolean>>;
  globalShowPopup: boolean;
  setGlobalShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  courseTitles: any[];
  setCourseTitles: React.Dispatch<React.SetStateAction<any[]>>;
  academicSessionTitles: any[];
  setClassTitles: React.Dispatch<React.SetStateAction<any[]>>;
  classTitles: any[];
  setAcademicSessionTitles: React.Dispatch<React.SetStateAction<any[]>>;
  selectedAcademicSession: string;
  setselectedAcademicSession: React.Dispatch<React.SetStateAction<string>>;
  selectedCourse: string;
  setselectedCourse: React.Dispatch<React.SetStateAction<string>>;
  selectedClass: string;
  setselectedClass: React.Dispatch<React.SetStateAction<string>>;
}

// Create the context
const QuestionBanksContext = createContext<
  QuestionBanksContexProps | undefined
>(undefined);

// Create a provider component
const MyContextProvider = ({ children }: { children: ReactNode }) => {
  const [currentView, setCurrentView] = useState<string>("");
  const [savedQuestions, setSavedQuestions] = useState<any[]>([]);
  const [questionToUpdate, SetQuestionToUpdate] = useState<any>();
  const [updateMode, setUpdateMode] = useState<boolean>(false);
  const [globalShowPopup, setGlobalShowPopup] = useState<boolean>(false);
  const [academicSessionTitles, setAcademicSessionTitles] = useState<string[]>([]);
  const [courseTitles, setCourseTitles] = useState<string[]>([]);
  const [classTitles, setClassTitles] = useState<string[]>([]);
  const [selectedAcademicSession, setselectedAcademicSession] = useState<string>("")
  const [selectedCourse, setselectedCourse] = useState<string>("")
  const [selectedClass, setselectedClass] = useState<string>("")

  return (
    <QuestionBanksContext.Provider
      value={{
        globalShowPopup,
        setGlobalShowPopup,
        updateMode,
        setUpdateMode,
        currentView,
        setCurrentView,
        savedQuestions,
        setSavedQuestions,
        SetQuestionToUpdate,
        questionToUpdate,
        courseTitles,
        setCourseTitles,
        academicSessionTitles,
        setAcademicSessionTitles,
        selectedAcademicSession,
        setselectedAcademicSession,
        selectedCourse,
        setselectedCourse,
        classTitles,
        setClassTitles,
        selectedClass,
        setselectedClass
      }}
    >
      {children}
    </QuestionBanksContext.Provider>
  );
};

// Create a custom hook to access the context values
const useMyContext = () => {
  const context = useContext(QuestionBanksContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
};

export { MyContextProvider, useMyContext };
