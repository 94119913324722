import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GradeItemDetail,
  GradingDeatils,
  HorDividLine,
  QuizCard,
  Quizes,
  RecordAttemptsWrapper,
  StudentDetailsWrapper,
  StudentQuizDetailsRight,
  StudentsDetailsLeft,
  StudentsHeaderContainer,
  StudentsQuizzes,
  TextIconStyling,
} from "../AssignmentInstructor.style";
import {
  SvgAttempt,
  SvgCalendarIcon,
  SvgEdit,
  SvgEmpty,
  SvgGrade,
  SvgStar,
} from "../../../elements/Icons";
import { LightTypography, Typography } from "../../../elements/fonts/Fonts";
import ReusableProfile from "../../../elements/reusableProfile/ReusableProfile";
import ProgressBar from "../../../elements/progressBar/ProgressBar";
import { get } from "../../../utils/AxiosRequests";
import { calculateForumGrades } from "../InstructorViewUtils";
import { getFormattedDate } from "../../../utils/GetFormattedDate";
import ForumGradesDetails from "./ForumGradesDetails";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import { RPProfilePic } from "../../PostsTimeLine/ViewPost/ViewPost.style";
import { useRecoilValue } from "recoil";
import { CourseColorSelector } from "../../../recoil/ThemeSelectors";
import { ResourceFrom } from "../../../Views/courseView/content/Content";
import { useTranslation } from "react-i18next";
interface ActivityProps {
  id: any;
  resourceR: ResourceFrom;
}
const ForumInstructor = ({ id, resourceR }: ActivityProps) => {
  const [AssignmentsData, setAssignmentsData] = useState<any[]>([]);
  const [minimumGrade, setMinimumGrade] = useState<number>(0);
  const [maximumGrade, setMaximumGrade] = useState<number>(0);
  const [average, setAverage] = useState<number>(0);
  const [gradedCount, setGradedCount] = useState<number>(0);
  const [maximumGradeOfAssignment, setMaximumGradeOfAssignment] = useState<
    number | string
  >(0);
  const [forum, setForum] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const tenantName = localStorage.getItem("tenant");
  const profileImage = require("../../../assets/userProfile.png");
  const params = useParams();
  const classId = params.classId;
  const { setUserSelectedIndex } = useContext(ClassContext);
  const { ClassGradientBox } = useRecoilValue(CourseColorSelector);
  const { t } = useTranslation();
  // GEt the Forum Submissions By It's ID Data
  useEffect(() => {
    const getAssignmentSubmissionsById = async () => {
      try {
        const forumSubmissions = await get(`forum/grades/${id}/${tenantName}`);
        setAssignmentsData(forumSubmissions.data.Posts);
        setForum(forumSubmissions.data.forumActivitiesData);
        setLoading(false);
        setMaximumGradeOfAssignment(
          forumSubmissions.data.forumActivitiesData.maxGrade
        );
      } catch (error) {
        console.error(error);
      }
    };
    getAssignmentSubmissionsById();
  }, [id]);

  useEffect(() => {
    if (AssignmentsData && AssignmentsData.length > 0) {
      // Update the state values By Calling calculateForumGrades Function from utils File
      setMinimumGrade(
        calculateForumGrades(AssignmentsData, forum).minGradeValue
      );
      setMaximumGrade(
        calculateForumGrades(AssignmentsData, forum).maxGradeValue
      );
      setAverage(calculateForumGrades(AssignmentsData, forum).avgGradeValue);
      setGradedCount(calculateForumGrades(AssignmentsData, forum).countGraded);
    }
  }, [AssignmentsData]);

  // If the Data is empty
  if (!loading && AssignmentsData && AssignmentsData.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <SvgEmpty />
        <Typography text={t("assignment.No submissions yet")} />
      </div>
    );
  } else if (loading) {
    // if the data isn't fetched yet
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography text={`${t("general.loading")}...`} />
      </div>
    );
  }

  const handleGraderNavigate = (userIndex: number) => {
    setUserSelectedIndex(userIndex);
    navigate(`/graderForum/${id}/${classId}`, {
      state: {
        activity: resourceR,
        moduleIndex: resourceR.moduleIndex,
        topicIndex: resourceR.topicIndex,
        contentIndex: resourceR.contentIndex,
      },
    });
  };

  return (
    <>
      <>
        {(() => {
          return (
            <>
              {forum.isGraded && ( // If the Forum is Graded ( Has the Graded Option  ) The Grades Details Component Will mount
                <GradingDeatils>
                  <ForumGradesDetails
                    maximumGrade={maximumGrade}
                    minimumGrade={minimumGrade}
                    average={average}
                    gradedCount={gradedCount}
                    AssignmentsData={AssignmentsData}
                    loading={loading}
                  />
                </GradingDeatils>
              )}
              <HorDividLine></HorDividLine>
              <StudentsQuizzes bgColor={ClassGradientBox}>
                <StudentsHeaderContainer>
                  <Typography text={t("online session.Students")} />
                </StudentsHeaderContainer>
                {/* Here We are mapping the submitted students */}
                {AssignmentsData &&
                  AssignmentsData.length > 0 &&
                  AssignmentsData.map((submission, index) => (
                    <div key={index}>
                      <>
                        <StudentDetailsWrapper>
                          {/* The profile picture of the student */}
                          <div
                            style={{ display: "flex", columnGap: "0.63rem" }}
                          >
                            <RPProfilePic
                              src={submission.posterProfilePic || profileImage}
                            />
                            <StudentsDetailsLeft>
                              <div
                                style={{
                                  display: "flex",
                                  columnGap: "0.62rem",
                                  alignItems: "center",
                                }}
                              >
                                {/* The Name of the student */}
                                <Typography
                                  text={submission.posterName}
                                  fontSize="0.875rem"
                                />
                              </div>
                              <LightTypography
                                text={`ID: ${submission.posterId}`}
                                fontSize="0.75rem"
                                color="#AAB1B9"
                              />
                              <div
                                style={{
                                  display: "flex",
                                  columnGap: "0.62rem",
                                }}
                              >
                                {/* The Submission Time of the student */}
                                {submission.submissions[0].postTime && (
                                  <LightTypography
                                    text={`${getFormattedDate(
                                      submission.submissions[0].postTime
                                    )}`}
                                    fontSize="0.75rem"
                                    color="#AAB1B9"
                                  />
                                )}
                              </div>
                              {/* The Comment of the student */}
                              {submission.grade &&
                                forum.isGraded &&
                                submission.grade.length > 0 &&
                                submission.grade[0].feedback && ( // if the forum has the graded option
                                  <LightTypography
                                    text={submission.grade[0].feedback || ""}
                                    fontSize="0.75rem"
                                    color="#D85D66"
                                    fontStyle="italic"
                                  />
                                )}
                            </StudentsDetailsLeft>
                          </div>
                          <StudentQuizDetailsRight>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  columnGap: "0.62rem",
                                  alignItems: "center",
                                }}
                              >
                                <LightTypography
                                  text={
                                    forum.isGraded
                                      ? t("assignment.Student's grade")
                                      : t("assignment.Student's grade")
                                  }
                                  fontSize="0.75rem"
                                />
                                <SvgEdit
                                  style={{
                                    cursor: "pointer",
                                    color: "#5dd3b3",
                                  }}
                                  onClick={() => handleGraderNavigate(index)}
                                  width="0.75rem"
                                />
                              </div>

                              {submission.grade &&
                              forum.isGraded &&
                              submission.grade.length > 0 &&
                              submission.grade[0].grade ? ( // if the forum has the graded option show the Grade of the student
                                <Typography
                                  text={
                                    submission.grade &&
                                    submission.grade.length > 0 &&
                                    Math.round(submission.grade[0].grade).toString()
                                  }
                                  fontSize="0.75rem"
                                  color="#6C9AF0"
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                            {submission.grade &&
                            forum.isGraded &&
                            submission.grade.length > 0 &&
                            submission.grade[0].grade ? ( // if the forum has the graded option show the Grade on the proccess bar
                              <ProgressBar
                                progress={submission.grade[0].grade || 0}
                                color="#6C9AF0"
                                // progressColor="#20272E"
                                maxprogress={
                                  maximumGradeOfAssignment !== 0 &&
                                  maximumGradeOfAssignment !== ""
                                    ? Number(maximumGradeOfAssignment)
                                    : 100
                                }
                              />
                            ) : forum.isGraded ? (
                              <ProgressBar
                                progress={0}
                                color="#6C9AF0"
                                progressColor="#20272E"
                                maxprogress={
                                  maximumGradeOfAssignment !== 0 &&
                                  maximumGradeOfAssignment !== ""
                                    ? Number(maximumGradeOfAssignment)
                                    : 100
                                }
                              />
                            ) : (
                              ""
                            )}

                            <RecordAttemptsWrapper></RecordAttemptsWrapper>
                          </StudentQuizDetailsRight>
                        </StudentDetailsWrapper>
                      </>
                    </div>
                  ))}
              </StudentsQuizzes>
            </>
          );
        })()}
      </>
    </>
  );
};

export default ForumInstructor;
