import React, { ReactElement, ReactNode } from "react";
import { ModuleEmpty, TextBobSizePlace } from "./EmptyView.style";
import { SvgEmpty } from "../Icons";
import { MediumTypography, Typography } from "../fonts/Fonts";
import { useTranslation } from "react-i18next";

interface EmptyViewInterface {
  text?: string;
  width?: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>; // Change the type to ReactNode
  gapSpace?: string;
  fontSize?: string;
  Styles?: any;
  lineHeight?: string;
  TypographyType?: boolean;
  Component?: ReactElement;
  ShowComponent?: boolean;
  ParentHeight?: string;
  ParentJustifyContent?: string;
}

const EmptyView = ({
  text = "view all.Nothing here yet",
  width,
  fontSize = "1.375rem",
  gapSpace = "1.25rem",
  lineHeight = "normal",
  ShowComponent = false,
  Component,
  ParentHeight,
  ParentJustifyContent,
  icon: IconComponent,
  TypographyType = true, // Provide a default value here if needed
}: EmptyViewInterface) => {
  const { t } = useTranslation()
  return (
    <ModuleEmpty
      style={{ gap: gapSpace }}
      height={ParentHeight}
      justifyContent={ParentJustifyContent}
    >
      {IconComponent && <IconComponent />}

      {/* ShowComponent is use to modify to write only text or create a nw component */}
      {ShowComponent ? (
        Component
      ) : (
        <TextBobSizePlace width={width}>
          {/* TypographyType is use to modify if you want to write in Typography or MediumTypography */}
          {TypographyType ? (
            <Typography
              text={t(text)}
              fontSize={fontSize}
              lineHeight={lineHeight}
            />
          ) : (
            <MediumTypography
              text={t(text)}
              fontSize={fontSize}
              lineHeight={lineHeight}
            />
          )}
        </TextBobSizePlace>
      )}
    </ModuleEmpty>
  );
};

export default EmptyView;
