


export const GradebookData={
    students: [
    
    
      // Add more students as needed
    ],
    MainActivity: [
   
    ]
  }
  


  export const data={
    "students": [
        {
            "name": "",
            "attendance": [],
            "activities": [
                {
                    "id": "Assignment",
                    "grades": [
                        {
                            "name": "Assignment 1",
                            "id": "Assignment_1154",
                            "score": "",
                            "weight": 60
                        }
                    ]
                },
                {
                    "id": "Forum",
                    "grades": [
                        {
                            "name": "Test forum",
                            "id": "Forum_1147",
                            "score": "",
                            "weight": 20
                        }
                    ]
                }
            ]
        },
        {
            "id": "72591147-6b40-4e71-b4fe-0efe5b2edf3e",
            "name": "Ali Mallahh",
            "attendance": [],
            "activities": [
                {
                    "id": "Assignment",
                    "grades": [
                        {
                            "name": "Test ass module 2 topic 1",
                            "id": "Assignment_1160",
                            "score": 0,
                            "weight": 20
                        }
                    ]
                },
                {
                    "id": "Forum",
                    "grades": [
                        {
                            "name": "Test without topic",
                            "id": "Forum_1155",
                            "score": 0,
                            "weight": 60
                        }
                    ]
                }
            ]
        },
        {
            "id": "f5b2d7d2-ba7d-46ad-9958-63d19f480af3",
            "name": "Abdelsalam Mahari",
            "attendance": [],
            "activities": [
                {
                    "id": "Assignment",
                    "grades": [
                        {
                            "name": "Test ass module 2 topic 1",
                            "id": "Assignment_1160",
                            "score": 0,
                            "weight": 20
                        },
                        {
                            "name": "Test ass module 2 topic 2",
                            "id": "Assignment_1161",
                            "score": 40,
                            "weight": 20
                        }
                    ]
                },
                {
                    "id": "Forum",
                    "grades": [
                        {
                            "name": "Test without topic 2",
                            "id": "Forum_1156",
                            "score": 45,
                            "weight": 20
                        }
                    ]
                },
                {
                    "id": "Quiz",
                    "grades": [
                        {
                            "name": "Test quiz",
                            "id": "Quiz_1164",
                            "score": 50,
                            "weight": 100
                        }
                    ]
                }
            ]
        },
        {
            "id": "131d8c58-dd8c-4713-b00d-f38eb98aed5c",
            "name": "test test",
            "attendance": [
                {
                    "score": 60,
                    "weight": 5
                }
            ],
            "activities": []
        }
    ],
    "MainActivity": [
        {
            "id": "Assignment",
            "name": "Assignment",
            "weight": 40
        },
        {
            "id": "Quiz",
            "name": "Quiz",
            "weight": 40
        },
        {
            "id": "Forum",
            "name": "Forum",
            "weight": 15
        },
        {
            "id": "Attendance",
            "name": "Attendance",
            "weight": 5
        }
    ]
}