import styled from "styled-components";

export const CardDetailsViewHeader = styled.div`
  width: 100%;
  & > :last-child {
    margin-top: 2.83rem;
    margin-bottom: 2rem;
  }
`;

