import React, { useState, useEffect } from "react";
import {
  PHCBodyChat,
  PHCCloseAction,
  PHCContainer,
  PHCExpanded,
  PHCFirstRow,
  PHCGroupRow,
  PHCIconWidth,
  PHCProfile,
  PHCIcons,
  PHCInput,
  PHCMessageChatTime,
  PHCMessagesRow,
  PHCMyMessageChat,
  PHCMyMessageRow,
  PHCReceivedMessageChat,
  PHCShrink,
  PHCContainerParent,
  SVGPointer,
} from "./PageHeaderChats.style";
import {
  LightTypography,
  MediumTypography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import {
  SvgClose,
  SvgSendMessage,
  SvgAttachment,
  SvgEmoji,
  SvgPolygonDown,
  SvgScale,
} from "../../../elements/Icons";
import CircleUploadFiles from "../../../elements/CircleUploadFile/CircleUploadFiles";
import {
  userChatData,
  UserFriends,
  GroupChatData,
} from "../../ContactMessages/ContactMessagesData";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  ChatsColorSelector,
  ColorSelector,
} from "../../../recoil/ThemeSelectors";
// import { ChatsColorSelector } from "../../../recoil/ThemeSelectors";

interface PageHeaderChatsProps {
  friendId?: { id: string };
  groupId?: { id: string };
  onClose?: () => void;
  onScale?: () => void;
  SenderName?: boolean;
}

const PageHeaderChats: React.FC<PageHeaderChatsProps> = ({
  friendId,
  groupId,
  onClose,
  onScale,
  SenderName = false,
}) => {
  const [OpenChat, setOpenChat] = useState<boolean>(true);
  const [selectedFriend, setSelectedFriend] = useState<any | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<any | null>(null);
  const [inputMessage, setInputMessage] = useState<string>("");
  const [messages, setMessages] = useState<any[]>([]);

  const navigate = useNavigate();

  const handleSvgScaleClick = () => {
    if (onScale) {
      onScale();
    }
    let id = "";

    if (friendId) {
      id = friendId.id;
    } else if (groupId) {
      id = groupId.id;
    }

    navigate("/messages", { state: { id } });
  };

  const handleCloseOpenChat = () => {
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (friendId) {
      const friendData = userChatData.find(
        (data) => data.FriendId === friendId?.id
      );
      setSelectedFriend(friendData);
    }
  }, [friendId]);

  useEffect(() => {
    if (groupId) {
      const groupData = GroupChatData.find((data) => data.id === groupId?.id);
      setSelectedGroup(groupData);
    }
  }, [groupId]);

  const handleIndexChangeOpenChat = () => {
    setOpenChat(!OpenChat);
  };

  const getFriendOrGroupName = (): string => {
    if (friendId) {
      const friend = UserFriends.find((friend) => friend.id === friendId?.id);
      return friend?.userName || "Friend Name";
    } else if (groupId) {
      const group = GroupChatData.find((group) => group.id === groupId?.id);
      return group?.name || "Group Name";
    }
    return "Chat";
  };
  const getFriendOrGroupImage = (): string => {
    if (friendId) {
      const friend = UserFriends.find((friend) => friend.id === friendId?.id);
      return friend?.profile || require("../../../assets/userProfile.png");
    } else if (groupId) {
      const group = GroupChatData.find((group) => group.id === groupId?.id);
      return (
        group?.GroupImage || require("../../../assets/GroupDefaultProfile.png")
      );
    }
    return "Image";
  };

  const sendMessage = (senderId: string, message: string) => {
    const newMessage = {
      date: new Date(),
      content: message,
      read: false,
      senderId: senderId,
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInputMessage(""); // Clear the input after sending the message
  };
  const { backgroundColor, themeTextColor } = useRecoilValue(ColorSelector);
  const { PopUpChatMessageColor } = useRecoilValue(ChatsColorSelector);
  return (
    <PHCContainer>
      {OpenChat ? (
        <PHCExpanded bgColor={backgroundColor}>
          {friendId || groupId ? (
            <PHCFirstRow>
              <PHCGroupRow>
                <PHCProfile src={getFriendOrGroupImage()} />
                <MediumTypography
                  text={getFriendOrGroupName()}
                  fontSize="1rem"
                />
              </PHCGroupRow>
              <PHCCloseAction>
                <PHCIconWidth
                  onClick={handleIndexChangeOpenChat}
                  style={{ marginTop: "-0.3rem" }}
                >
                  <SvgPolygonDown width={" 0.625rem"} />
                </PHCIconWidth>
                <SvgScale onClick={handleSvgScaleClick} width={" 0.625rem"} />

                <SvgClose onClick={handleCloseOpenChat} width={" 0.625rem"} />
              </PHCCloseAction>
            </PHCFirstRow>
          ) : null}
          {selectedFriend || selectedGroup ? (
            <PHCBodyChat>
              {(selectedFriend
                ? selectedFriend.Messages
                : selectedGroup
                ? selectedGroup.ChatData
                : []
              ).map((message: any, index: number) => (
                <div key={index}>
                  {message.senderId === "1" ? (
                    <PHCMyMessageRow>
                      <PHCMyMessageChat bgColor={PopUpChatMessageColor}>
                        <TypographyRegularDescription
                          text={message.content}
                          fontSize="0.625rem"
                          lineHeight="1.125rem"
                          wordWrap="break-word"
                          color="#fff"
                        />
                        <PHCMessageChatTime justifyContent="flex-end">
                          <LightTypography
                            text="11:45 am"
                            fontSize="0.5rem"
                            color="#ffffff"
                          />
                        </PHCMessageChatTime>
                      </PHCMyMessageChat>
                    </PHCMyMessageRow>
                  ) : (
                    <PHCReceivedMessageChat bgColor={PopUpChatMessageColor}>
                      {SenderName && (
                        <TypographyRegularDescription
                          text="senderName"
                          fontSize="0.625rem"
                          color="#fff"
                        />
                      )}
                      <TypographyRegularDescription
                        text={message.content}
                        fontSize="0.625rem"
                        lineHeight="1.125rem"
                        wordWrap="break-word"
                        color="#fff"
                      />
                      <PHCMessageChatTime>
                        <LightTypography
                          text="11:45 am"
                          fontSize="0.5rem"
                          color="#ffffff"
                        />
                      </PHCMessageChatTime>
                    </PHCReceivedMessageChat>
                  )}
                </div>
              ))}
            </PHCBodyChat>
          ) : (
            <></>
            // <PHCMessagesRow>
            //   <PHCIcons>
            //     <SvgEmoji width={"0.75rem"} />
            //   </PHCIcons>

            //   <PHCInput
            //     type="text"
            //     placeholder="Send a message"
            //     value={inputMessage}
            //     onChange={(e) => setInputMessage(e.target.value)}
            //   />
            //   <SvgSendMessage onClick={() => sendMessage("1", inputMessage)} />
            //   <SvgAttachment />
            // </PHCMessagesRow>
          )}
          <PHCMessagesRow>
            <PHCIcons>
              <SvgEmoji width={"0.75rem"} />
            </PHCIcons>

            <PHCInput
              type="text"
              placeholder="Send a message"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              color={themeTextColor}
            />

            <SVGPointer>
              <SvgAttachment />
            </SVGPointer>
            <SVGPointer>
              <SvgSendMessage onClick={() => sendMessage("1", inputMessage)} />
            </SVGPointer>
          </PHCMessagesRow>
        </PHCExpanded>
      ) : (
        <PHCShrink>
          {/* Display the contact in small shape */}
          <PHCFirstRow style={{ borderBottom: "none" }}>
            <PHCGroupRow>
              <PHCProfile src={getFriendOrGroupImage()} />
              <MediumTypography text={getFriendOrGroupName()} fontSize="1rem" />
            </PHCGroupRow>
            <PHCCloseAction>
              <PHCIconWidth
                onClick={handleIndexChangeOpenChat}
                style={{ marginTop: "-0.3rem" }}
              >
                <SvgPolygonDown
                  width={" 0.625rem"}
                  style={{
                    transform: "rotate(180deg)",
                    transition: "transform 0.5s ease-in-out",
                  }}
                />
              </PHCIconWidth>
              <SvgScale onClick={handleSvgScaleClick} width={" 0.625rem"} />
              <SvgClose onClick={handleCloseOpenChat} width={" 0.625rem"} />
            </PHCCloseAction>
          </PHCFirstRow>
        </PHCShrink>
      )}
    </PHCContainer>
  );
};

export default PageHeaderChats;
