import styled from "styled-components";
import { breakpoints } from "../../../theme";

export const CourseTagsHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  row-gap: 0.94rem;
  column-gap: 1.25rem;
  padding: 0.5rem 0;
  @media (max-width: ${breakpoints.medium}) {
    grid-column: 1/6;
  }
  @media (max-width: ${breakpoints.tablets}) {
    gap: 0.31rem;
  }
`;
