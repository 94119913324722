import React, { useEffect, useState } from "react";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  ChoicesContainer,
  ChoicesHeader,
  ChoicesInputs,
  Input,
  InputCont,
  LabelDiv,
  QuestionSelected,
  QuestionSelectedContainer,
} from "./AllQuestions.style";
import { AllQuestionsInterface } from "./AllQuestionsInterface";
import { useMyContext } from "../../contexts/QuestionBanksContext";
import { FlexDiv } from "../../globalStyles";
import { SvgAdd, SvgDelete, SvgRefreshIcon } from "../../elements/Icons";
import ActionButtons from "./ActionButtons";
import {
  auth0SubIdSplitter,
  generateUUID,
  auth0ProviderSplitter
} from "../../utils/StringManipulation";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { useTranslation } from "react-i18next";
import {
  ColorSelector,
  CourseColorSelector,
} from "../../recoil/ThemeSelectors";
import { useRecoilValue } from "recoil";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const Matching = ({ handleCancel }: AllQuestionsInterface) => {
  const { id } = useParams();
  const { user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const { savedQuestions, setSavedQuestions, questionToUpdate, updateMode } =
    useMyContext();
  const { t } = useTranslation();
  const feedbackRight = "quiz.Your Answer is correct."
  const feedbackWrong = "quiz.Your Answer is incorrect."
  const [isLoading, setIsLoading] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [feedbackRightError, setFeedbackRightError] = useState(false);
  const [feedbackWrongError, setFeedbackWrongError] = useState(false);
  const [choicesErrors, setChoicesErrors] = useState<boolean[]>([]);
  const [matchesErrors, setMatchesErrors] = useState<boolean[]>([]);
  const [prevData, setPrevData] = useState<any>();
  const [matchingData, setMatchingData] = useState({
    questionText: "",
    choices: [
      { choice: "", match: "" },
      { choice: "", match: "" },
    ],
    isShuffled: false,
    feedbackRight: t(feedbackRight),
    feedbackWrong: t(feedbackWrong),
  });

  // Set initial state based on updateMode and questionToUpdate
  useEffect(() => {
    if (updateMode && questionToUpdate && questionToUpdate.id) {
      setMatchingData({
        questionText: questionToUpdate.question,
        choices: questionToUpdate.choices,
        isShuffled: questionToUpdate.isShuffled,
        feedbackRight: questionToUpdate.feedbackRight,
        feedbackWrong: questionToUpdate.feedbackWrong,
      });
    }
  }, [updateMode, questionToUpdate]);

  // function to handle the question from text editor
  const handleQuestionChange = (value: string) => {
    setMatchingData((prevState) => ({ ...prevState, questionText: value }));
    setDescriptionError(value.trim() === ""); // Check if the question is empty
  };

  const handleFeedbackRightChange = (value: string) => {
    setMatchingData((prevState) => ({ ...prevState, feedbackRight: value }));
    setFeedbackRightError(value.trim() === "");
  };

  const handleFeedbackWrongChange = (value: string) => {
    setMatchingData((prevState) => ({ ...prevState, feedbackWrong: value }));
    setFeedbackWrongError(value.trim() === "");
  };

  // function to handle shuffle state
  const handleShuffleChange = () => {
    setMatchingData((prevState) => ({
      ...prevState,
      isShuffled: !matchingData.isShuffled,
    }));
  };

  const handleChoiceChange = (value: string, index: number, field: string) => {
    // Clone the existing choices array to avoid mutating state directly
    const newChoices = [...matchingData.choices];
    // Update the specific choice's field with the new value
    newChoices[index][field as keyof (typeof newChoices)[number]] = value;
    // Update the state with the modified choices array
    setMatchingData((prevState) => ({ ...prevState, choices: newChoices }));
    const newChoicesErrors = [...choicesErrors];
    newChoicesErrors[index] = value.trim() === "";
    setChoicesErrors(newChoicesErrors);
  };

  const handleMatchChange = (value: string, index: number) => {
    // Clone the existing choices array to avoid mutating state directly
    const newChoices = [...matchingData.choices];
    // Update the specific choice's field with the new value
    newChoices[index].match = value;
    // Update the state with the modified choices array
    setMatchingData((prevState) => ({ ...prevState, choices: newChoices }));
    const newMatchesErrors = [...matchesErrors];
    newMatchesErrors[index] = value.trim() === "";
    setMatchesErrors(newMatchesErrors);
  };

  // function to add choice
  const addChoice = () => {
    setMatchingData((prevState) => ({
      ...prevState,
      choices: [...matchingData.choices, { choice: "", match: "" }],
    }));
    setChoicesErrors([...choicesErrors, false]);
    setMatchesErrors([...matchesErrors, false]);
  };

  const removeChoice = (index: number) => {
    // Clone the existing choices array to avoid mutating state directly
    const newChoices = [...matchingData.choices];
    // Remove the choice at the specified index
    newChoices.splice(index, 1);
    // Update the state with the modified choices array
    setMatchingData((prevState) => ({ ...prevState, choices: newChoices }));
    // Clone the existing choicesErrors array to avoid mutating state directly
    const newChoicesErrors = [...choicesErrors];
    // Remove the error associated with the removed choice
    newChoicesErrors.splice(index, 1);
    // Update the state with the modified choicesErrors array
    setChoicesErrors(newChoicesErrors);
    // Clone the existing matchesErrors array to avoid mutating state directly
    const newMatchesErrors = [...matchesErrors];
    // Remove the error associated with the removed choice
    newMatchesErrors.splice(index, 1);
    // Update the state with the modified matchesErrors array
    setMatchesErrors(newMatchesErrors);
  };

  const handleSave = async () => {
    const { questionText, choices, isShuffled, feedbackRight, feedbackWrong } =
      matchingData;

    // Check if the question is empty
    setDescriptionError(questionText.trim() === "");
    setFeedbackRightError(feedbackRight.trim() === "");
    setFeedbackWrongError(feedbackWrong.trim() === "");

    // Check if any choice or match is empty
    const newChoicesErrors = choices.map(
      (choice) => choice.choice.trim() === ""
    );
    setChoicesErrors(newChoicesErrors);
    const newMatchesErrors = choices.map(
      (choice) => choice.match.trim() === ""
    );
    setMatchesErrors(newMatchesErrors);

    // If either the question, choice, or match is empty, return early without saving
    if (
      questionText.trim() === "" ||
      feedbackRight.trim() === "" ||
      feedbackWrong.trim() === "" ||
      newChoicesErrors.some((error) => error) ||
      newMatchesErrors.some((error) => error)
    ) {
      return;
    }

    setIsLoading(true);
    const newMatchingQuestion = {
      id: generateUUID(),
      classSourceId: id,
      subId: subId,
      provider:provider,
      contentType: "MATCH",
      question: questionText,
      choices: choices.filter((choice) => choice.choice.trim() !== ""), // Remove empty choices
      isShuffled: isShuffled,
      feedbackRight: feedbackRight,
      feedbackWrong: feedbackWrong,
    };
    try {
      const response = await axios.post(
        `https://0ddea1gj55.execute-api.eu-west-1.amazonaws.com/dev/question/bank/create/${tenantName}`,
        {
          data: newMatchingQuestion,
        }
      );
      setIsLoading(false);
      handleCancel();
    } catch (error) {
      console.log(error);
    }
    // }
  };
  const { ClassGradientBox } = useRecoilValue(CourseColorSelector);
  const { themeTextColor, bghelp } = useRecoilValue(ColorSelector);
  return (
    <QuestionSelectedContainer style={{ color: themeTextColor }}>
      <QuestionSelected style={{ background: ClassGradientBox }}>
        <Typography text={t("quiz.Matching")} fontSize="1.25rem" />

        {/* text editor part */}
        <LabelDiv>
          <Typography text={t("question bank.Question Text")} fontSize="1rem" />
          <TinyEditor
            hasError={descriptionError}
            initialValue={matchingData.questionText}
            onChange={handleQuestionChange}
            placeholder={t("question bank.Type your question")}
            height="17.5625rem"
          />
        </LabelDiv>

        {/* Choices Section */}
        <ChoicesContainer>
          <ChoicesHeader>
            <MediumTypography
              text={`${t("quiz.Choices")}*`}
              fontSize="0.875rem"
            />
            <FlexDiv gap="0.62rem" pointer="true">
              {/* <SvgRefreshIcon onClick={handleShuffleChange} color={matchingData.isShuffled ? "#5DD3B3" : "#fff"} /> */}
              <FlexDiv gap="0.62rem" onClick={addChoice} pointer="true">
                <SvgAdd color="#5DD3B3" />
                <Typography
                  text={t("quiz.Add Choices")}
                  fontSize="0.75rem"
                  color="#5DD3B3"
                />
              </FlexDiv>
            </FlexDiv>
          </ChoicesHeader>

          <ChoicesInputs>
            {matchingData.choices.map((choice, index) => (
              <InputCont key={index}>
                <Typography text={(index + 1).toString()} />
                <Input
                  borderColor={bghelp}
                  color={themeTextColor}
                  hasError={choicesErrors[index]}
                  width="100%"
                  placeholder={t("question bank.Write Your Choice")}
                  value={choice.choice}
                  onChange={(e) =>
                    handleChoiceChange(e.target.value, index, "choice")
                  }
                />
                <Input
                  borderColor={bghelp}
                  color={themeTextColor}
                  hasError={matchesErrors[index]}
                  width="100%"
                  placeholder={t("question bank.Write The Match")}
                  value={choice.match}
                  onChange={(e) => handleMatchChange(e.target.value, index)}
                />
                {matchingData.choices.length > 2 && (
                  <SvgDelete
                    onClick={() => removeChoice(index)}
                    style={{ cursor: "pointer", width: "5rem" }}
                  />
                )}
              </InputCont>
            ))}
          </ChoicesInputs>
        </ChoicesContainer>

        <InputCont>
          <LabelDiv style={{ width: "100%" }}>
            <TypographyRegularDescription
              text={t("quiz.Feedback if right")}
              fontSize="0.875rem"
            />
            <Input
              borderColor={bghelp}
              color={themeTextColor}
              hasError={feedbackRightError}
              value={matchingData.feedbackRight}
              onChange={(e) => handleFeedbackRightChange(e.target.value)}
              type="text"
              placeholder={t("quiz.Feedback if right")}
              width="100%"
            />
          </LabelDiv>
          <LabelDiv style={{ width: "100%" }}>
            <TypographyRegularDescription
              text={t("quiz.Feedback if wrong")}
              fontSize="0.875rem"
            />
            <Input
              borderColor={bghelp}
              color={themeTextColor}
              hasError={feedbackWrongError}
              value={matchingData.feedbackWrong}
              onChange={(e) => handleFeedbackWrongChange(e.target.value)}
              type="text"
              placeholder={t("quiz.Feedback if wrong")}
              width="100%"
            />
          </LabelDiv>
        </InputCont>

        {(descriptionError ||
          feedbackRightError ||
          feedbackWrongError ||
          choicesErrors.some((error) => error) ||
          matchesErrors.some((error) => error)) && (
          <ErrorDiv>{t("quiz.This field is mandatory")}</ErrorDiv>
        )}
      </QuestionSelected>

      {/* action buttons component part*/}
      <ActionButtons
        handleSave={handleSave}
        handleCancel={handleCancel}
        isLoading={isLoading}
      />
    </QuestionSelectedContainer>
  );
};

export default Matching;
