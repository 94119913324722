import styled from "styled-components";

export const MainContainer=styled.div`


`

export const Profileimg=styled.img`
border-radius:50%;
width:6.25rem;
height:6.25rem;
`

export const FlexedDiv=styled.div`
display:flex;
align-items:center;
justify-content:start;
gap:2rem;
`

export const LowerContainer=styled.div`
margin-top:2rem;
width:100%;
margin-bottom:1rem;
`

export const TitleHolder=styled.div`
margin-bottom:1rem;

`


export const ParticipantContainer=styled.div`

margin-top:0.75rem;
height:5rem;
width:40rem;
border-radius: 0.625rem;
background-color: rgba(255, 255, 255, 0.03);
display:flex;
justify-content:space-between;
align-items:center;
padding:1rem;
`

export const ParticipantImg=styled.img`
width: 3.125rem;
height: 3.125rem;
border-radius:50%;


`
export const Participantleft=styled.img`



`

export const RightParticipantinfo=styled.div`
margin-left:1rem;
gap:1rem;


`

export const CloseHolder=styled.div`
cursor: pointer;


`

export const SpinnerHolder=styled.div`
height:4rem;
width:4rem;
margin-left:1rem;
margin-bottom:1rem;

`

export const SubmitButton=styled.button`
background-color:#5DD3B3;
display: flex;
width: 6.25rem;
height: 2.125rem;
justify-content: center;
align-items: center;
border:none;
border-radius:1.5625rem;
margin-top:1rem;
margin-left:85%;
cursor:pointer;
`

export const MainContainerLoader=styled.div`
  display: flex;
  height: 8rem;
  width: 100%;
  justify-content: center;
  align-items: center;
margin-top:7rem;


`