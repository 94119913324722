import React, { useState } from "react";
import {
  SelectedOptionsBox,
  SelectedOptionsLabel,
} from "./SelectedOptions.style";

import { Typography } from "../../../elements/fonts/Fonts";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { SelectedOptionDataInterface } from "../../manageWeight/SelectedOptions/SelectedOptionDataInterface";
interface SelectedOptionsInterfaceTsx {
  Data?: SelectedOptionDataInterface[];
  FirstText?: string;
  FirstIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  FirstLabelClick?: () => void;
  SecondText?: string;
  SecondIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  SecondLabelClick?: () => void;
  LastText?: string;
  LastIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  LastLabelClick?: () => void;
  width?: string;
  Type: string;
  ref?: any;
  marginLeft?: string;
  getFilters?: () => void;
  setShowMenuOfSelectedIndex: React.Dispatch<React.SetStateAction<any>>;
  applyStatusToSelectedStudents: (statusId: number) => void; // New prop
}

const SelectedOptions = ({
  Type,
  width,
  ref,
  marginLeft,
  setShowMenuOfSelectedIndex,
  applyStatusToSelectedStudents, // New prop
}: SelectedOptionsInterfaceTsx) => {
  const handleOptionClick = (statusId: number) => {
    applyStatusToSelectedStudents(statusId);
    setShowMenuOfSelectedIndex(false); // Close the menu after applying the status
  };

  const { SecondaryTextColor, bg_selectColor } = useRecoilValue(ColorSelector);
  const { t } = useTranslation();

  return (
    <>
      <SelectedOptionsBox bgColor={bg_selectColor} ref={ref} width={width} marginLeft={marginLeft}>
        <SelectedOptionsLabel onClick={() => handleOptionClick(1)}>
          <Typography text={t("gradebook.Present")} fontSize="0.875rem" />
        </SelectedOptionsLabel>
        <SelectedOptionsLabel onClick={() => handleOptionClick(3)}>
          <Typography text={t("gradebook.Late")} fontSize="0.875rem" />
        </SelectedOptionsLabel>
        <SelectedOptionsLabel onClick={() => handleOptionClick(2)}>
          <Typography text={t("gradebook.Absent")} fontSize="0.875rem" />
        </SelectedOptionsLabel>
        <SelectedOptionsLabel onClick={() => handleOptionClick(4)}>
          <Typography text={t("gradebook.Excused")} fontSize="0.875rem" />
        </SelectedOptionsLabel>
      </SelectedOptionsBox>
    </>
  );
};

export default SelectedOptions;
