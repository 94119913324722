import styled, { keyframes } from 'styled-components';

export  const LoadinPageLayout = styled.div<{bgcolor?:string}>`
display: flex;
position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:${props =>props.bgcolor || "#2D3741"};
  z-index: 1000;
justify-content:center;
align-items:center;


`; 
export  const LoadingContainer = styled.div<{bgcolor?:string}>`
    display:flex;
    justify-content: space-between;
    &:dir(ltr){
    border-radius: 0rem 0.625rem 0.625rem 0rem;
    }
    &:dir(rtl){
    border-radius: 0.625rem 0rem 0rem 0.625rem ;
    }

width: 18.625rem;
height: 8.5625rem;
flex-shrink: 0;
background:${props =>props.bgcolor || "#384450"};
    
    `;

    export  const LoadingContainerLine = styled.div`
width:2%;
height: 100%;
stroke-width: 4px;
stroke: #5DD3B3;
background-color: #5DD3B3;
    
    `;

export  const LoadingContainerContent = styled.div`
width:98% ;
height: 100% ;
stroke-width: 4px ;
stroke: #5DD3B3;
gap:0.63rem;
display: flex;
padding: 1.25rem;
flex-direction:column ;
align-items:center;

    `;
    
    export const LoadingContainerSpace= styled.div`
justify-content:center;
display:flex !important;
  /* margin-bottom: 0.62rem; */
  width: 100%; 
  /* height: 60px; */
  /* border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db; */
  background-color:transparent;
  justify-content:center;


`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(180deg); }
`;



export const LoadingContainerSvg = styled.div`
justify-content:center;
  /* margin-bottom: 0.62rem; */
  width: 35px; 
  height: 35px;
  /* border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db; */
  border-radius: 50%;
  animation: ${spinAnimation} 1s linear infinite; /* Apply the animation */
  /* padding:5px; */

/* 
  background-color:red; */

`;