import React, { useState, useEffect } from "react";
import {
  SelectedOptionsBox,
  SelectedOptionsLabel,
} from "./SelectedOptions.style";

import { SelectedOptionDataInterface } from "./SelectedOptionDataInterface";
import { Typography } from "../../../elements/fonts/Fonts";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

interface SelectedOptionsInterfaceTsx {
  Data?: SelectedOptionDataInterface[];
  FirstText?: string;
  FirstIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  FirstLabelClick?: () => void;
  SecondText?: string;
  SecondIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  SecondLabelClick?: () => void;
  LastText?: string;
  LastIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  LastLabelClick?: () => void;
  width?: string;
  Type: string;
  ref?: any;
  marginLeft?: string;
  getFilters?: () => void;
  setShowMenuOfSelectedIndex: React.Dispatch<React.SetStateAction<any>>
}

const SelectedOptions = ({
  Type,
  width,
  ref,
  getFilters,
  setShowMenuOfSelectedIndex,
  marginLeft,
}: SelectedOptionsInterfaceTsx) => {
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const tenantName = localStorage.getItem("tenant");
  const Url = useParams();
  const classId = Url.classId;

  useEffect(() => {
    // Fetch the current filter when the component mounts
    const fetchCurrentFilter = async () => {
      try {
        const response = await axios.get(`https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/grades/filter/get/${classId}/${tenantName}`);
        const currentFilter = response.data.data.find((filter: any) => filter.activityType === Type);
        if (currentFilter) {
          setSelectedFilter(currentFilter.filter);
        }
      } catch (error) {
        console.error("There was an error fetching the current filter!", error);
      }
    };

    fetchCurrentFilter();
  }, [classId, tenantName, Type]);

  const sendPostRequest = async (filterType: any) => {
    try {
      const response = await axios.post(`https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/grades/filter/create/${tenantName}`, {
        data: {
          classSourceId: classId,
          activityType: Type,
          filter: filterType,
        }
      });
      console.log("data :", [
        classId,
        Type,
        filterType,
      ])
      console.log(response.data);
      if (getFilters) {
        getFilters();
      }
      setSelectedFilter(filterType); // Set the selected filter after a successful request
    } catch (error) {
      console.error("There was an error making the POST request!", error);
    }
  };

  const handleOptionClick = (filterType: string) => {
    setFilterTypeAndSendRequest(filterType);
    setShowMenuOfSelectedIndex(null);
  };

  const setFilterTypeAndSendRequest = (filterType: any) => {
    sendPostRequest(filterType);
  };

  const { bg_selectColor } = useRecoilValue(ColorSelector);
  const { t } = useTranslation();

  return (
    <>
      <SelectedOptionsBox bgColor={bg_selectColor} ref={ref} width={width} marginLeft={marginLeft}>
        <SelectedOptionsLabel
          onClick={() => handleOptionClick("lowest")}
          isSelected={selectedFilter === "lowest"}
        >
          <Typography text={t("gradebook.Drop the lowest")} fontSize="0.875rem" />
        </SelectedOptionsLabel>

        <SelectedOptionsLabel
          onClick={() => handleOptionClick("manual")}
          isSelected={selectedFilter === "manual"}
        >
          <Typography text={t("gradebook.Manual Weight")} fontSize="0.875rem" />
        </SelectedOptionsLabel>

        <SelectedOptionsLabel
          onClick={() => handleOptionClick("maximum")}
          isSelected={selectedFilter === "maximum"}
        >
          <Typography text={t("assignment.Maximum grade")} fontSize="0.875rem" />
        </SelectedOptionsLabel>

        <SelectedOptionsLabel
          onClick={() => handleOptionClick("average")}
          isSelected={selectedFilter === "average"}
        >
          <Typography text={t("gradebook.Average Grade")} fontSize="0.875rem" />
        </SelectedOptionsLabel>
      </SelectedOptionsBox>
    </>
  );
};

export default SelectedOptions;
