import styled from "styled-components";

export const SubmissionStyle = styled.div<{ onlineSessionStatus?: string }>`
  display: flex;

  align-items: center;

  /* column-gap: ${(props) =>
    props.onlineSessionStatus === "Join Now"
      ? "2.5rem"
      : props.onlineSessionStatus === "Ended"
        ? "3.7rem"
        : props.onlineSessionStatus === "Not Started"
          ? "1.7rem"
          : ""}; */

  /* color: ${(props) =>
    props.onlineSessionStatus === "Join Now"
      ? "#5dd3b3"
      : props.onlineSessionStatus === "Ended"
        ? "#D85D66"
        : props.onlineSessionStatus === "Not Started"
          ? "White"
          : ""}; */
`;
export const ActivityIconTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.63rem;
`;
