import React, { useState } from "react";
import {
  CourseGeneralTracingAtt,
  CourseGeneralTracingText,
} from "./AnalyticsHeaderAtt.style";
import { SvgRectangle899 } from "../Icons";
import { Typography } from "../fonts/Fonts";

interface AnalyticsHeaderAttInterface {
  number: any;
  text: string;
  isActive?: boolean; // Receive isActive as a prop
  onClick?: () => void;
  // Pass a function to set active state
}

export const AnalyticsHeaderAtt = ({
  number,
  text,
  isActive,
  onClick,
}: AnalyticsHeaderAttInterface): JSX.Element => {
  return (
    <>
      <CourseGeneralTracingAtt
        onClick={onClick}
        isActive={isActive} // Pass the isActive state to the styled component
      >
        <SvgRectangle899 />
        <Typography fontSize="1.75rem" text={number.toString()} />
        <CourseGeneralTracingText>
          <Typography fontSize="0.75rem" text={text} />
        </CourseGeneralTracingText>
      </CourseGeneralTracingAtt>
    </>
  );
};
