import React, { useState } from "react";
import {
  ProgressBarChartLayout,
  ProgressBarChartRendered,
  ProgressBarChartRenderedRow,
} from "./ProggresBarChart.style";
import ProgressBar from "../../../elements/progressBar/ProgressBar";
import {
  LightTypography,
  MediumTypography,
} from "../../../elements/fonts/Fonts";
interface ProgressBarChartInterface {
  data?: any[];
  MaxNumber: number;
}
const ProgressBarChart = ({ data, MaxNumber }: ProgressBarChartInterface) => {
  // when we want to connect with the backend MaxNumber need to be in the parent page and called to here as props
  // const [MaxNumber, setMaxNumber] = useState(30);
  return (
    <ProgressBarChartLayout>
      {data?.map((item, index: number) => (
        <ProgressBarChartRendered key={index}>
          <ProgressBarChartRenderedRow>
            <LightTypography text={item.text || ""} fontSize="1rem" />
            <MediumTypography
              text={item.value || item.attendance || ""}
              fontSize="1rem"
            />
          </ProgressBarChartRenderedRow>
          <ProgressBar
            maxprogress={MaxNumber}
            progressColor="rgba(255, 255, 255, 0.50)"
            Height="0.9375rem"
            color="#5DD3B3"
            progress={item.value || item.attendance || ""}
          />
        </ProgressBarChartRendered>
      ))}
    </ProgressBarChartLayout>
  );
};

export default ProgressBarChart;
