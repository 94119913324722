import React, { useState } from "react";
import {
  ButtonsCont,
  ChoicesContainer,
  ChoicesHeader,
  ChoicesInputs,
  FlexDiv,
  Header,
  Input,
  InputCont,
  PollQuestionsContainer,
  TextEditorContainer,
  TextEditorDiv,
} from "./Pollquestion.style";
import { MediumTypography, Typography } from "../../elements/fonts/Fonts";
import {
  SvgEditIcon,
  SvgEyeIcon,
  SvgAdd,
  SvgDelete,
} from "../../elements/Icons";
import { TextEditor } from "../../elements/TextEditor/TextEditor";
import CancelButton from "../../elements/StyledButton/CancelButton";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";

const PollQuestion = () => {
  const [question, setQuestion] = useState("");
  const [choices, setChoices] = useState(["", ""]);

  const addChoice = () => {
    setChoices([...choices, ""]);
  };

  const removeChoice = (index: number) => {
    const newChoices = [...choices];
    newChoices.splice(index, 1);
    setChoices(newChoices);
  };

  const handleChoiceChange = (value: string, index: number) => {
    const newChoices = [...choices];
    newChoices[index] = value;
    setChoices(newChoices);
  };

  const handleStart = () => {
    const pollData = {
      question: question,
      choices: choices,
    };
  };

  return (
    <>
      <PollQuestionsContainer>
        {/* Text Editor Section */}
        <TextEditorContainer>
          <Header>
            <MediumTypography text="Question" fontSize="0.875rem" />
            <FlexDiv gap="0.62rem">
              <SvgEditIcon />
              <SvgEyeIcon />
            </FlexDiv>
          </Header>

          <TextEditorDiv>
            <TinyEditor
              onChange={(value) => setQuestion(value)}
              height="13.25rem"
              placeholder="Type here your work"
            />
          </TextEditorDiv>
        </TextEditorContainer>

        {/* Choices Section */}
        <ChoicesContainer>
          <ChoicesHeader>
            <MediumTypography text="Choices*" fontSize="0.875rem" />
            <FlexDiv
              gap="0.62rem"
              onClick={addChoice}
              style={{ cursor: "pointer" }}
            >
              <SvgAdd color="#5DD3B3" />
              <Typography
                text="Add Choices"
                fontSize="0.75rem"
                color="#5DD3B3"
              />
            </FlexDiv>
          </ChoicesHeader>

          <ChoicesInputs>
            {choices.map((choice, index) => (
              <InputCont key={index}>
                <Input
                  placeholder="Write answer"
                  value={choice}
                  onChange={(e) => handleChoiceChange(e.target.value, index)}
                />
                {choices.length > 2 && (
                  <SvgDelete
                    onClick={() => removeChoice(index)}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </InputCont>
            ))}
          </ChoicesInputs>
        </ChoicesContainer>
      </PollQuestionsContainer>

      <ButtonsCont>
        <CancelButton
          name="Cancel"
          onClickFunction={() => {}}
          color="#D85D66"
          borderColor="#D85D66"
          backgroundColor="transparent"
        />
        <CancelButton
          name="Start"
          onClickFunction={handleStart}
          color="#fff"
          borderColor="#5DD3B3"
          backgroundColor="#5DD3B3"
        />
      </ButtonsCont>
    </>
  );
};

export default PollQuestion;
