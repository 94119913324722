import React, { useState } from "react";
import {
  ButtonAdd,
  ButtonCancel,
  ButtonsRow,
  GradeSchemaWrapper,
  Input,
  TableContent,
  TableContentHolder,
  TableSection,
  TableTbody,
  TableTd,
  TableTh,
  TableThead,
  TableTr,
  TableTr1,
  TitleRowBeforeTable,
} from "./GradesSchema.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../elements/fonts/Fonts";
import { TableTrData, TitleData } from "./gradesSchemaData";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import {
  SvgArrowRight,
  SvgArrowShock,
  SvgArrowSimple,
  SvgBackArrow,
  SvgSimpleArrowLeft,
} from "../../elements/Icons";
import { TitleHolder } from "../about_HeroSection/heroSectionDetails/HeroSectionDetails.style";
import { useTranslation } from "react-i18next";
import { FlexAppContainer } from "../../App.style";
import TitleHeaderTextIconNavigator from "../../elements/TitleHeaderTextIconNavigator/TitleHeaderTextIconNavigator";
import { useRecoilValue } from "recoil";
import { ColorSelector, TableColorSelector } from "../../recoil/ThemeSelectors";
const GradesSchema = () => {
  const [changesMade, setChangesMade] = useState(false);

  const handleSave = () => {
    const isValid = validateData(gradeData);

    if (isValid) {
      setChangesMade(false);
    } else {
      toast.error("Validation failed. Please correct the errors.");
    }
  };

  const validateData = (data: any) => {
    let isValid = true;

    for (let i = 0; i < data.length; i++) {
      const grade = data[i];
      for (let j = 0; j < grade.GradeSchema.length; j++) {
        const schema = grade.GradeSchema[j];

        if (schema.From !== null && (schema.From >= 100 || schema.From < 0)) {
          toast.error(`From value must be between 0 and 99 in line ${j + 1}`);
          isValid = false; // Validation failed
        }

        if (schema.To !== null && (schema.To >= 101 || schema.To <= 0)) {
          toast.error(`To value must be between 1 and 100 in line ${j + 1}`);
          isValid = false; // Validation failed
        }

        if (
          schema.From !== null &&
          schema.To !== null &&
          schema.From >= schema.To
        ) {
          toast.error(
            `To value must be greater than From value in line ${j + 1}`
          );
          isValid = false; // Validation failed
        }

        // Add more validations here as needed
      }
    }

    return isValid;
  };

  const { t } = useTranslation();
  const { SecondaryTextColor, borderInputColor } =
    useRecoilValue(ColorSelector);
  const [gradeData, setGradeData] = useState(TableTrData);
  const { Table_Head_TR, Table_nth_child } = useRecoilValue(TableColorSelector);
  const handleGradeChange = (
    outerIndex: number,
    innerIndex: number,
    event: { target: { value: string } },
    propToUpdate: string // 'From' or 'To'
  ) => {
    const inputValue = parseInt(event.target.value) || 0;
    const updatedGrades = gradeData.map((grade, i) => {
      if (i === outerIndex) {
        const gradeSchema = grade.GradeSchema.map((schema, j) => {
          if (
            j === innerIndex &&
            (propToUpdate === "From" || propToUpdate === "To")
          ) {
            return {
              ...schema,
              [propToUpdate]: inputValue, // Update the 'From' or 'To' value directly
            };
          }
          return schema;
        });
        return { ...grade, GradeSchema: gradeSchema };
      }
      return grade;
    });
    setGradeData(updatedGrades);
    setChangesMade(true);
  };

  //   const initialStudentData = TableTrData; // Store initial student data

  const resetForm = () => {
    // setGradeData([]); // Clear the data to send
    setChangesMade(false); // Reset changes made flag
  };

  const handleCancelClick = () => {
    resetForm(); // Call the reset function when Cancel is clicked
  };

  // const handleToChange = (outerIndex: number, innerIndex: number, event: { target: { value: string } }) => {
  //     const inputValue = parseInt(event.target.value) || 0;
  //     const updatedQuizs = quizs.map((item, i) => {
  //         if (i === outerIndex) {
  //             const gradeSchema = item.GradeSchema.map((grade, j) => {
  //                 if (j === innerIndex) {
  //                     if (inputValue > grade.From) {
  //                         return { ...grade, To: inputValue };
  //                     }
  //                 }
  //                 return grade;
  //             });
  //             return { ...item, GradeSchema: gradeSchema };
  //         }
  //         return item;
  //     });
  //     setQuizs(updatedQuizs);
  // };

  return (
    <FlexAppContainer column={true} gap="2.5rem">
      <TitleHeaderTextIconNavigator title={t("gradebook.Grade Schemas")} />

      <GradeSchemaWrapper>
        <TableSection
          style={{ marginLeft: "-1.25rem", width: "calc(100% + 2.5rem)" }}
        >
          <TableContentHolder>
            <TableContent>
              <TableThead
                style={{ background: Table_Head_TR, height: "3.625rem" }}
              >
                {TitleData.map((item, index) => (
                  <TableTh
                    //   colSpan={index === 2 ? 4 : 2}
                    key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2.25rem",
                        paddingLeft: "1.25rem",
                      }}
                    >
                      <MediumTypography
                        text={t(item.Title)}
                        fontSize="1rem"
                        lineHeight="1.75rem"
                        whiteSpace="nowrap"
                      />
                    </div>
                  </TableTh>
                ))}
              </TableThead>
              <TableTbody>
                {TableTrData.map((Grade, outerIndex) => (
                  <TableTr key={outerIndex}>
                    {Grade.GradeSchema.map((schema, innerIndex) => (
                      <TableTr1
                        nth_child_bgcolor={Table_nth_child}
                        key={innerIndex}
                      >
                        <TableTd style={{ paddingLeft: "2.5rem" }}>
                          {schema.GradeName}
                        </TableTd>
                        <TableTd style={{ paddingLeft: "0.7rem" }}>
                          <Input
                            max={99}
                            type="number"
                            value={
                              gradeData[outerIndex].GradeSchema[innerIndex]
                                .From ?? ""
                            }
                            onChange={(event) =>
                              handleGradeChange(
                                outerIndex,
                                innerIndex,
                                event,
                                "From"
                              )
                            }
                            color={SecondaryTextColor}
                            bordercolor={borderInputColor}
                          />
                        </TableTd>
                        <TableTd style={{ paddingLeft: "0.7rem" }}>
                          <Input
                            max={100}
                            type="number"
                            value={
                              gradeData[outerIndex].GradeSchema[innerIndex]
                                .To ?? ""
                            }
                            onChange={(event) =>
                              handleGradeChange(
                                outerIndex,
                                innerIndex,
                                event,
                                "To"
                              )
                            }
                            color={SecondaryTextColor}
                            bordercolor={borderInputColor}
                          />
                        </TableTd>
                      </TableTr1>
                    ))}
                  </TableTr>
                ))}
              </TableTbody>
            </TableContent>
          </TableContentHolder>
        </TableSection>
      </GradeSchemaWrapper>
      {changesMade && (
        <ButtonsRow>
          <ButtonAdd onClick={handleSave}>
            <Typography
              text={t("forms.Save")}
              color="#fff"
              fontSize="0.875rem"
              fontWeight="400"
              lineHeight="normal"
            />
          </ButtonAdd>
          <ButtonCancel onClick={handleCancelClick}>
            <Typography
              text={t("forms.Cancel")}
              color="#D85D66"
              fontSize="0.875rem"
              fontWeight="400"
              lineHeight="normal"
            />
          </ButtonCancel>
        </ButtonsRow>
      )}
    </FlexAppContainer>
  );
};

export default GradesSchema;
