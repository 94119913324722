import styled from "styled-components";
import { breakpoints, theme } from "../../theme";
import CheckIcon from "../../assets/icons/Group2199.svg";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

interface InstructorCellProps {
  active: boolean;
  borderColor?: string;
  colorTextProfile?: string;
}

interface RadioButtonProps {
  checked: boolean;
}

interface CertificateCheckedWrapperProps {
  ischecked: boolean;
}

export const Overlay = styled.div<{ isvisible: string }>`
  display: ${(props) => (props.isvisible ? "block" : "none")};
  opacity: ${(props) => (props.isvisible ? "1" : "0")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 300vh;
  transition: opacity 500ms;
  overflow: auto;
  z-index: 10;
  /* background: rgba(0, 0, 0, 0.4); */
`;

interface BackgroundOverlayProps {
  bgOverlayColor: string;
}
export const BackgroundOverlay = styled.div<BackgroundOverlayProps>`
  background: ${(props) => props.bgOverlayColor};
  width: 100%;
  min-height: 100%;
`;
export const PopupContainer = styled.div`
  margin: auto auto;
  width: max-content;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface PopupContentContainerProps {
  bgColor: string;
}
export const PopupContentContainer = styled.div<PopupContentContainerProps>`
  width: 100%;
  max-width: 65vw;
  position: relative;
  &:dir(ltr) {
    border-left: 4px solid #5dd3b3;
    border-radius: 0rem 0.625rem 0.625rem 0rem;
    padding: 1.88rem 0.69rem 1.25rem 1.87rem;
  }
  &:dir(rtl) {
    border-right: 4px solid #5dd3b3;
    border-radius: 0.625rem 0 0 0.625rem;
    padding: 1.88rem 1.87rem 1.25rem 0.69rem;
  }
  background-color: ${(props) => props.bgColor};
  min-width: fit-content;

  display: flex;
  flex-direction: column;
  row-gap: 0.62rem;
  & > :first-child {
    margin-top: 1rem;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 35vw;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 1.25rem;
`;

export const GridItem1 = styled.div`
  grid-column: 1/2;
  @media screen and (max-width: 785px) {
    grid-column: 1/3;
  }
`;
export const GridItem2 = styled.div`
  grid-column: 2/3;

  @media screen and (max-width: 785px) {
    grid-column: 1/3;
  }
`;
interface IconsWrapperProps {
  iconCloseColor: string;
}
export const IconsWrapper = styled.div<IconsWrapperProps>`
  position: absolute;
  top: 1.25rem;
  &:dir(ltr) {
    right: 1.88rem;
  }
  &:dir(rtl) {
    left: 1.88rem;
  }
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-height: 1rem;
  color: ${({ iconCloseColor }) => iconCloseColor};
  & > :first-child:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
`;

export const PopupArchLayout = styled.div`
  /* margin-top: 1rem; */
  overflow: auto;
  /* background-color: red; */
  /* margin-bottom: 100px; */
`;
export const ButtonContainer = styled.div`
  margin-top: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.62rem;
`;

export const CourseArchLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 1.69rem;
`;
interface CourseLargeScrollableProps {
  theme?: string;
}
export const CourseLargeScrollable = styled.div<CourseLargeScrollableProps>`
  width: 100%;
  padding-right: 0.69rem;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 80vh;
  /* background-color: gray; */
  &::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)"
        : "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)"};

    border-radius: 0.625rem;
    box-shadow: ${(props) =>
      props.theme === "light"
        ? "none"
        : "-10px 0px 10px 0px rgba(0, 0, 0, 0.25)"};
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => (props.theme === "light" ? "#fff" : "#2d3741")};
    border-radius: 0.625rem;
  }
`;
export const CourseSmallColorPicker = styled.div`
  /* position: relative; */
  /* background-color: gray; */
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.81rem;
  @media screen and (max-width: 549px) {
    padding-top: 1.5rem;
  }
`;
export const ClassColorWrapper = styled.div<{ color: string }>`
  width: 15px;
  height: 15px;
  &:dir(ltr) {
    margin-left: 0.5rem;
  }
  &:dir(rtl) {
    margin-right: 0.5rem;
  }
  border-radius: 50%;
  background-color: ${(props) => props.color || "#5DD3B3"};
`;

export const CourseSmall = styled.div`
  /* position: relative;
  background-color: gray; */
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.81rem;
`;
export const InstructorRender = styled.div`
  margin-top: 0.44rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.62rem;
  /* background-color: red; */
`;

export const InstructorCell = styled.div<InstructorCellProps>`
  display: flex;
  flex-direction: column;
  gap: 0.31rem;
  justify-content: center;
  align-items: center;
  padding: 0.62rem 0.25rem;
  width: 6.9375rem;
  height: 4.375rem;
  border-radius: 0.625rem;
  border: 2px solid;
  border-color: ${({ active, borderColor, colorTextProfile }) =>
    active ? borderColor || "#5DD3B3" : colorTextProfile};
  cursor: pointer;
  /* flex-direction: column; */
  position: relative;
  overflow: hidden;
`;

export const TypographyWrapper = styled.div`
  max-width: 100%;
  text-align: center;
  & > :first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* Set the number of lines to display before truncating */
  }
`;
export const InstructorIMG = styled.div`
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  display: cover;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`;
export const InstructorButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1.25rem;
  /* background-color:red; */
  /* justify-content: space-between; */
  width: 100%;
`;

export const NewFuturesWrapper = styled.div`
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  width: 100%;
`;
export const InstructorColorPicker = styled.div`
  display: flex;
  /* margin-left:10rem; */
  margin-top: -2rem;
  /* background-color:red; */
  /* position:absolute; */
`;

export const ButtonContent = styled.button`
  /* margin-top: 20px; */
  /* margin-bottom: 50px; */
  margin-top: -0.6rem;
  width: 8.8125rem;
  height: 2.125rem;
  font-size: 14px;
  border: 1px solid #5dd3b3;
  background-color: #5dd3b3;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5rem;
  cursor: pointer;
  margin-bottom: 5px;
  font-family: "Primary font-medium";
  &:disabled {
    background-color: #5dd3b3;
    color: #666;
    opacity: 0.7;
    cursor: not-allowed;
  }
`;
export const SketchPickerHolder = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 4.25rem;
  top: 4.5rem;
  @media screen and (max-width: 549px) {
    left: 2.125rem;
    top: 6rem;
  }
  /* @media (max-width: ${breakpoints.mobile}) {
    top: 8.2rem;
  } */
  z-index: 25;
`;

export const SketchPickerClose = styled.div`
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.dark.bg_secondary_color};
  padding: 0.3rem;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
`;

export const SketchPickerSave = styled.div`
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.dark.bg_secondary_color};
  padding: 0.3rem;
  position: absolute;
  bottom: 0.45rem;
  right: 0.5rem;
`;

export const SearchKeywordsWrapper = styled.div`
  position: relative;
  & > :last-child {
    position: absolute;
    top: 0.7rem;
    right: 4.25rem;
    cursor: pointer;
  }
`;

export const RadioDivLabel = styled.label`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 0.69rem;
  cursor: pointer;
  flex-wrap: wrap;
`;

interface CheckBoxProps {
  bgColor?: string;
}
export const CheckBox = styled.input<StyleThemeInterface>`
  width: 1rem;
  height: 1rem;
  background: ${(props) => props.bgcolor};

  appearance: none;
  border: 1px solid ${theme.dark.primary_text_color};
  border-radius: 3px;
  transition: background 0.3s ease;
  background: ${(props) => props.bgcolor};
  border: 1px solid ${(props) => props.bordercolor};
  border-radius: 3px;
  &:checked {
    background-image: url(${CheckIcon}); /* Use the CheckIcon component as a URL */
    background-size: cover; /* Adjust this as needed */
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    /* border: none; */
  }
  cursor: pointer;
`;

export const IconToolTipMainHolder = styled.div`
  display: flex;
  width: 100%;
  column-gap: 0.62rem;
  align-items: center;
  & > :nth-child(2):hover {
    cursor: pointer;
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
`;

export const Tooltip = styled.div`
  & > ::before {
    content: "";
    position: absolute;
    top: 35%;
    left: -1rem;
    background-color: transparent;
    border: 0.5rem solid transparent;
    border-right-color: #1f2731;
    width: 0.12rem !important;
  }
  visibility: hidden;
  border-radius: 0.625rem;
  background: #1f2731;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  width: fit-content;
  padding: 0.625rem;
  text-align: center;
  position: absolute;
  top: 0.5rem;
  left: 1.5rem;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.3s;
  z-index: 5;
`;

export const TooltipSecondaryTag = styled.div`
  & > ::before {
    content: "";
    position: absolute;
    top: 38%;
    left: -1rem;
    background-color: transparent;
    border: 0.5rem solid transparent;
    border-right-color: #1f2731;
    width: 0.12rem !important;
  }
  visibility: hidden;
  border-radius: 0.625rem;
  background: #1f2731;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  width: fit-content;
  padding: 0.625rem;
  text-align: center;
  position: absolute;
  top: 0.5rem;
  left: 1.5rem;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.3s;
  z-index: 5;
`;

export const IconToolTipWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: fit-content;
  &:hover {
    ${Tooltip} {
      visibility: visible;
      opacity: 1;
      color: #fff;
    }
  }
`;

export const IconToolTipSecondaryTagWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: fit-content;
  &:hover {
    ${TooltipSecondaryTag} {
      visibility: visible;
      opacity: 1;
      color: #fff;
    }
  }
`;

export const CertificateHolder = styled.img`
  width: 7.5rem;
  height: 6.25rem;
  object-fit: fill;
  &:hover {
    cursor: pointer;
    /* box-shadow: 0px 8px 20px 0px rgba(93, 211, 179, 0.25); */
    box-shadow: 0.2rem 0.2rem 1.5rem 0.2rem rgba(93, 211, 179, 0.5);
    transition: box-shadow 0.2s ease-in-out;
  }
`;

export const CertificateCheckedWrapper = styled.div<CertificateCheckedWrapperProps>`
  position: absolute;
  width: 0.875rem;
  height: 0.875rem;
  display: ${(props) => (props.ischecked ? "flex" : "none")};
  top: 0.32rem;
  right: 0.32rem;
  z-index: 30;
`;

export const InputHolder = styled.button`
  width: 7.8125rem;
  height: 1.875rem;
  border-radius: 1.25rem;
  border: 1px solid #5dd3b3;

  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  padding: 0.5rem 1.25rem 0.44rem 1.25rem;
  background: transparent;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  max-height: min-content;
  cursor: pointer;
  overflow: hidden;

  color: #5dd3b3;
  &:hover {
    box-shadow: 0 0 10px rgba(42, 243, 156, 0.3);
    transition: box-shadow 0.2s ease-in;
  }
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.69rem;
`;

export const RadioButton = styled.input<RadioButtonProps>`
  cursor: pointer;
  width: 0.875rem;
  height: 0.875rem;
  border: 0.05em solid #fff;
  border-radius: 50%;
  background: linear-gradient(to bottom, #2f353d, #545f6d);
  border-radius: 50%;
  appearance: none;
  &:checked::before {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
    display: ${(props) => (props.checked ? "flex" : "none")};
    justify-content: center;
    margin-left: -10%;
    margin-top: -10%;
    align-items: center;
    color: #fff;
    border: 0.05em solid #5dd3b3;
    content: "";
  }
`;

interface inputNumberProps {
  width?: string;
  height?: string;
  textAlign?: string;
  paddingLeft?: string;
}
export const DateInput = styled.input<inputNumberProps>`
  width: ${(props) => (props.width ? props.width : "3.125rem")};

  height: ${(props) => (props.height ? props.height : "1.938rem")};

  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  border-radius: 0.625rem;
  border: 1px solid #576371;
  background-color: transparent;
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : "")};
  color: #fff;
  font-family: ${(props) => props.theme.font.primaryLight};
  font-size: 0.875rem;

  &::placeholder {
    color: #fff;
    opacity: 0.5;
    font-size: 0.625rem;
    font-family: "Primary Font-Light";
  }
  &:focus {
    outline: none;
    caret-color: #5dd3b3 !important;
  }
`;

export const LabelToggleDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;
