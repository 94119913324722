import React from "react";
import {
  AnalyticScrollablePlaceCell,
  AnalyticScrollablePlaceLine,
} from "./AnalyticScrollabelPlace.style";
import {
  SurveyQuestionAnalyticsData,
  SurveyQuestionAnalyticsInterface,
} from "../../../Tables/SurveyQuestionAnalytics/SurveyQuestionAnalyticsData";
import { Typography } from "../../../../elements/fonts/Fonts";
import { AQSvgMoreType } from "../../../AnalyticsQuizzes/AnalyticsQuizzes.style";
import { SvgGrade } from "../../../../elements/Icons";
import { SurveyQuestion } from "../../../Tables/SurveyQuestionAnalytics/SurveyQuestionAnalytics";
import { AnalyticsPiesInterface } from "../../../../interfaces/AnalyticsCommonInterfaces";

const AnalyticScrollablePlace = ({
  ChosenQuestion,
  setChosenQuestion,
}: AnalyticsPiesInterface) => {
  const handleQuestionObject = (question: SurveyQuestion) => {
    setChosenQuestion && setChosenQuestion(question);
  };

  return (
    <AnalyticScrollablePlaceLine>
      {SurveyQuestionAnalyticsData.map(
        (Qval: SurveyQuestionAnalyticsInterface, index: number) => (
          <AnalyticScrollablePlaceCell
            Opacity={ChosenQuestion === Qval ? "1" : "0.5"}
            key={index}
          >
            <Typography
              text={`${Qval.QuestionTitle} ( ${Qval.QuestionType} )`}
              fontSize="1.125rem"
              lineHeight="1.75rem"
            />
            <AQSvgMoreType
              onClick={() => handleQuestionObject(Qval as SurveyQuestion)}
            >
              <SvgGrade />
            </AQSvgMoreType>
          </AnalyticScrollablePlaceCell>
        )
      )}
    </AnalyticScrollablePlaceLine>
  );
};

export default AnalyticScrollablePlace;
