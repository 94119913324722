import  styled  from "styled-components";
import { breakpoints } from '../../../../theme';


export const GraderStatisticsContainer=styled.div`
display:flex;
justify-content:space-between;


@media (max-width: ${breakpoints.mobile && breakpoints.tablets && breakpoints.small}) {
   display: flex;
   flex-direction:column;
   gap:4rem;

  }
   
`;

