import React, { FC, useEffect, useState } from "react";
import {
  RatingContainer,
  Header,
  RatingContentContainer,
  CriteriaContainer,
  MarkContainer,
  MarkScaleContainer,
  ScaleContainer,
  ScaleContentNumber,
  ScaleStructureNumber,
  TableCell,
} from "./RatingSection.style";
import {
  TypographyRegularDescription,
  LightTypography,
  Typography,
} from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  TableColorSelector,
} from "../../../recoil/ThemeSelectors";
interface RatingSectionInterface {
  selectedRubricCell: any[];
  choosedCell?: any[];
}

const RatingSection = ({
  selectedRubricCell,
  choosedCell,
}: RatingSectionInterface) => {
  const { t } = useTranslation();
  const { Table_Head_TR, theme } = useRecoilValue(TableColorSelector);
  const { RatingRubricTrackColor } = useRecoilValue(ColorSelector);
  const [cells, setCells] = useState<any[]>([]);

  useEffect(() => {
    setCells(choosedCell || []);
  }, [choosedCell]);


  const language = localStorage.getItem("language");
  return (
    <RatingContainer>
      <Header
        first_child_bgcolor={
          language === "ar" && theme === "dark"
            ? "linear-gradient(90deg, #293440  0%,  #415160 107.88%)"
            : language === "ar"
              ? "#AAB1B94"
              : Table_Head_TR
        }
      >
        <Typography text={t("Rubric.Criteria")} fontSize="0.875rem" />
        <TableCell
          style={{
            width: "11rem",
            marginRight: `${language === "ar" ? " " : "1.25rem"}`,
            marginLeft: `${language === "ar" ? "1.25rem " : ""}`,
          }}
        >
          <Typography text={t("Rubric.Level")} fontSize="0.875rem" />
        </TableCell>
      </Header>
      {cells && cells.length > 0
        ? cells.map((item: any, index: number) => (
          <RatingContentContainer key={index}>
            <CriteriaContainer>
              <LightTypography text={item.criteria} fontSize="0.75rem" />
            </CriteriaContainer>

            <MarkScaleContainer>
              <MarkContainer>
                <LightTypography text={item.level} fontSize="0.75rem" />
                <LightTypography
                  text={`${item.weight} %`}
                  fontSize="0.75rem"
                  color={
                    item.weight === 100
                      ? "#5DD3B3"
                      : item.weight >= 50
                        ? "#0B8FFF"
                        : "#D85D66"
                  }
                />
              </MarkContainer>
              <ScaleContainer bgcolor={RatingRubricTrackColor}>
                <ScaleStructureNumber
                  style={{
                    width: `${item.weight}%`,
                    height: "0.5rem",
                    backgroundColor:
                      item.weight === 100
                        ? "#5DD3B3"
                        : item.weight >= 50
                          ? "#0B8FFF"
                          : "#D85D66",
                  }}
                ></ScaleStructureNumber>
              </ScaleContainer>
            </MarkScaleContainer>
          </RatingContentContainer>
        ))
        :selectedRubricCell &&  selectedRubricCell.map((item: any, index: number) => (
          <RatingContentContainer key={index}>
            <CriteriaContainer>
              <LightTypography text={item.criteria} fontSize="0.75rem" />
            </CriteriaContainer>

            <MarkScaleContainer>
              <MarkContainer>
                <LightTypography text={item.level} fontSize="0.75rem" />
                <LightTypography
                  text={`${item.weight} %`}
                  fontSize="0.75rem"
                  color={
                    item.weight === 100
                      ? "#5DD3B3"
                      : item.weight >= 50
                        ? "#0B8FFF"
                        : "#D85D66"
                  }
                />
              </MarkContainer>
              <ScaleContainer bgcolor={RatingRubricTrackColor}>
                <ScaleStructureNumber
                  style={{
                    width: `${item.weight}%`,
                    height: "0.5rem",
                    backgroundColor:
                      item.weight === 100
                        ? "#5DD3B3"
                        : item.weight >= 50
                          ? "#0B8FFF"
                          : "#D85D66",
                  }}
                ></ScaleStructureNumber>
              </ScaleContainer>
            </MarkScaleContainer>
          </RatingContentContainer>
        ))}
    </RatingContainer>
  );
};

export default RatingSection;
