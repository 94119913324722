import React, { useState } from "react";
import {
  RubicViewView,
  RubicFlexView,
  RubricClacificationDiv,
  RubricTablenDiv,
} from "./RibricView.style";
import { RubricCreatorTab } from "../../components/RubricCreatorTab/RubricCreatorTab";
import { RubricTableEdition } from "../../components/RubricTableV2/RubricTable";
import { RubricEmptyCompo } from "../../components/RubricEmptyCompo/RubricEmptyCompo";
import { Typography } from "../../elements/fonts/Fonts";
import { SvgBackArrow } from "../../elements/Icons";

import { useNavigate } from "react-router-dom";
import TitleHeaderTextIconNavigator from "../../elements/TitleHeaderTextIconNavigator/TitleHeaderTextIconNavigator";
import { useRecoilValue } from "recoil";
import { ColorSelector, TableColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

export const RubricView = (): JSX.Element => {
  const [ColNum, setColNum] = useState<number>(0);
  const [RowsNum, setRowsNum] = useState<number>(0);
  const [ShowTableRubric, setShowTableRubric] = useState<boolean>(false);
  const [title, settitle] = useState("");
  const [Description, setDescription] = useState<string>("");
  const {
    boxShadow,
    Container_Gradient_BgColor,
    SecondaryTextColor,
    bghelp,
    theme,
    borderInputColor,
  } = useRecoilValue(ColorSelector);
  const { Table_BgColor, Table_BoxShadow, Table_nth_child, Table_Head_TR } =
    useRecoilValue(TableColorSelector);
  const { t } = useTranslation()
  return (
    <RubicViewView>
      <TitleHeaderTextIconNavigator title={t("Rubric.Generate Rubric")} />
      <RubicFlexView>
        <RubricClacificationDiv>
          <RubricCreatorTab
            ColNum={ColNum}
            setColNum={setColNum}
            RowsNum={RowsNum}
            setRowsNum={setRowsNum}
            setShowTableRubric={setShowTableRubric}
            title={title}
            Description={Description}
            settitle={settitle}
            setDescription={setDescription}
          />
        </RubricClacificationDiv>
        <RubricTablenDiv>
          {ShowTableRubric ? (
            <RubricTableEdition
              ColNum={ColNum}
              RowsNum={RowsNum}
              setColNum={setColNum}
              setRowsNum={setRowsNum}
              title={title}
              Description={Description}
              setShowTableRubric={setShowTableRubric}
              settitle={settitle}
              setDescription={setDescription}
            />
          ) : (
            <RubricEmptyCompo />
          )}
        </RubricTablenDiv>
      </RubicFlexView>
    </RubicViewView>
  );
};
