import React, { FC, useState, useEffect } from "react";
import { theme } from "../../theme";
import { Typography, LightTypography } from "../fonts/Fonts";
import ReusableSubmitButton from "../reusableSubmitButton/ReusableSubmitButton";
import ReusableInput from "../groupShuffleInput/ReusableInput";
import { toast } from "react-toastify";
import { useStateContext } from "../../contexts/ContextProvider";
import { GroupCardDataInterface } from "../../components/groupCardNew/groupCardNewInterface";
import { useRecoilValue } from "recoil";
import {
  unstructuredCoursePopup,
  IconsColorSelector,
} from "../../recoil/ThemeSelectors";

import {
  Overlay,
  BackgroundOverlay,
  PopupContainer,
  SubtitleWrapper,
  ButtonsHolder,
  PopupContentContainer,
  IconsWrapper,
  InputsHolder,
  InputWrapper,
} from "./ReusablePopUp.style";

import { SvgClose, SvgCloseIcon } from "../Icons";
import { useTranslation } from "react-i18next";

interface ReusablePopUpProps {
  show: boolean;
  showInputs?: boolean;
  title?: string;
  subtitle?: string;
  lineHeight?: string;
  fontSize?: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
  setShowRandomizePopup: React.Dispatch<React.SetStateAction<boolean>>;
  popUpType: "Warning" | "Randomize";
  allStudentsNumber: number;
  randomizeGroups: (subGroupNum: number, studentPerSubGroup?: number) => void;
  randomizeGroupsWithoutSubGroupsNum: () => void;
  removeAllExistingGroup: () => void;
  groups: GroupCardDataInterface[];
}

const ReusablePopUp: FC<ReusablePopUpProps> = ({
  show,
  showInputs,
  title,
  subtitle,
  lineHeight,
  fontSize,
  icon: IconComponent,
  setShow,
  handleSubmit,
  setShowRandomizePopup,
  popUpType,
  allStudentsNumber,
  randomizeGroups,
  removeAllExistingGroup,
  groups,
  randomizeGroupsWithoutSubGroupsNum,
}) => {
  const {
    subGroupNum,
    setSubGroupNum,
    studentPerSubGroup,
    setStudentPerSubGroup,
  } = useStateContext();
  const { t } = useTranslation();
  const { setIsPopUpOpen } = useStateContext();
  // Recoil state and selectors
  const { popUpBgColor, overlayBgColor } = useRecoilValue(
    unstructuredCoursePopup
  );
  const { iconCloseColor } = useRecoilValue(IconsColorSelector);

  useEffect(() => {
    if (subGroupNum && subGroupNum > allStudentsNumber) {
      toast.error(
        t(
          "grader.desired number of subgroups cannot exceed number of students in your class"
        ),
        {
          theme: "dark",
          // icon: <SvgInfo />,
        }
      );
    }
    if (studentPerSubGroup && studentPerSubGroup > allStudentsNumber) {
      toast.error(
        t(
          "grader.the number of students per subgroup entered exceeds the actual number of students that are in your class"
        ),
        {
          theme: "dark",
          // icon: <SvgInfo />,
        }
      );
    }
  }, [subGroupNum, studentPerSubGroup]);

  const handleSubGroupInputChange = (e: any) => {
    const inputValue = e.target.value;
    if (/^[1-9]\d*$/.test(inputValue) || inputValue === "") {
      setSubGroupNum(inputValue);
    }
  };

  const handleStudentInputChange = (e: any) => {
    const inputValue = e.target.value;
    if (/^[1-9]\d*$/.test(inputValue) || inputValue === "") {
      setStudentPerSubGroup(inputValue);
    }
  };

  return (
    <Overlay isvisible={show.toString()}>
      <BackgroundOverlay bgOverlayColor={overlayBgColor}>
        <PopupContainer>
          <PopupContentContainer bgColor={popUpBgColor}>
            <IconsWrapper iconCloseColor={iconCloseColor}>
              <IconComponent />
              <SvgClose
                onClick={() => {
                  setShow(false);
                  setIsPopUpOpen(false);
                }}
              />
            </IconsWrapper>

            <Typography text={`${title}`} fontSize="1rem" />
            <SubtitleWrapper>
              <LightTypography
                text={`${subtitle}`}
                fontSize={fontSize}
                lineHeight={lineHeight}
              />
            </SubtitleWrapper>
            <InputsHolder display={showInputs || false}>
              <InputWrapper>
                <LightTypography
                  text={t("group.Number of sub groups")}
                  fontSize="0.875rem"
                />
                <ReusableInput
                  inputHeight="2.8125rem"
                  placeHolder={t("group.Enter Number")}
                  inputBorderRadius="0.625rem"
                  value={subGroupNum}
                  inputTupe="SubGroup"
                  handleSubGroupInputChange={handleSubGroupInputChange}
                />
              </InputWrapper>
              <InputWrapper>
                <LightTypography
                  text={t("group.Number of students per sub group")}
                  fontSize="0.875rem"
                />
                <ReusableInput
                  inputHeight="2.8125rem"
                  placeHolder={t("group.Enter Number")}
                  inputBorderRadius="0.625rem"
                  value={studentPerSubGroup}
                  inputTupe="Student"
                  handleStudentInputChange={handleStudentInputChange}
                />
              </InputWrapper>
            </InputsHolder>
            <ButtonsHolder>
              <ReusableSubmitButton
                text={t("forms.Cancel")}
                color="#D85D66"
                bgColor="transparent"
                border="1px solid #D85D66"
                // padding="0.56rem 2.31rem 0.5rem 2.31rem"
                onClick={() => {
                  setShow(false);
                  setIsPopUpOpen(false);
                }}
              />
              <ReusableSubmitButton
                text={t("group.Proceed")}
                width="7.625rem"
                height="2.125rem"
                // padding="0.56rem 2rem 0.5rem 2rem"
                onClick={async () => {
                  if (subGroupNum && subGroupNum > allStudentsNumber) {
                    toast.error(
                      t(
                        "grader.desired number of subgroups cannot exceed number of students in your class"
                      ),
                      {
                        theme: "dark",
                        // icon: <SvgInfo />,
                      }
                    );
                  }
                  if (
                    studentPerSubGroup &&
                    studentPerSubGroup > allStudentsNumber
                  ) {
                    toast.error(
                      t(
                        "grader.the number of students per subgroup entered exceeds the actual number of students that are in your class"
                      ),
                      {
                        theme: "dark",
                        // icon: <SvgInfo />,
                      }
                    );
                  }
                  if (popUpType === "Warning") {
                    setShow(false);
                    setIsPopUpOpen(false);
                    setShowRandomizePopup(true);
                  } else if (
                    (popUpType === "Randomize" &&
                      subGroupNum &&
                      subGroupNum <= allStudentsNumber &&
                      studentPerSubGroup &&
                      studentPerSubGroup <= allStudentsNumber) ||
                    !subGroupNum ||
                    !studentPerSubGroup ||
                    (!subGroupNum && !studentPerSubGroup)
                  ) {
                    setShow(false);
                    setIsPopUpOpen(false);
                    if (
                      subGroupNum &&
                      studentPerSubGroup &&
                      groups.length > 1
                    ) {
                      // Only call removeAllExistingGroup if the condition is true
                      await removeAllExistingGroup();
                    }

                    // Now that removeAllExistingGroup (if called) has completed, call randomizeGroups
                    if (
                      subGroupNum &&
                      studentPerSubGroup &&
                      groups.length === 1
                    ) {
                      await randomizeGroups(subGroupNum, studentPerSubGroup);
                    } else if (
                      subGroupNum &&
                      studentPerSubGroup &&
                      groups.length > 1
                    ) {
                      await randomizeGroups(subGroupNum, studentPerSubGroup);
                    } else if (subGroupNum) {
                      await randomizeGroups(subGroupNum);
                    } else if (
                      !subGroupNum ||
                      (!subGroupNum && !studentPerSubGroup) ||
                      (subGroupNum === 0 && studentPerSubGroup === 0) ||
                      (subGroupNum === 0 && studentPerSubGroup === undefined) ||
                      (subGroupNum === undefined && studentPerSubGroup === 0)
                    ) {
                      randomizeGroupsWithoutSubGroupsNum();
                    }
                    // Handle the final steps after both functions have completed
                    handleSubmit();
                  }
                }}
              />
            </ButtonsHolder>
          </PopupContentContainer>
        </PopupContainer>
      </BackgroundOverlay>
    </Overlay>
  );
};

export default ReusablePopUp;
