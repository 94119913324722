import styled from "styled-components";

// export  const IAUVTable  = styled.div`

// `;

export  const IAUVTableView  = styled.div`
display:flex;
flex-direction:column;
width:100%;
/* background: #2D3741; */
/* border-radius: 0.625rem; */
/* background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%)); */
`;

export  const IAUVTableHeader  = styled.div`
display:flex;
flex-direction:row;
width:100%;
gap: 0.3rem;
justify-content:space-between;
/* background-color:yellow; */
height: 3.625rem;
flex-shrink: 0;
/* opacity: 0.7; */
background: var(--Grey-Gradient, linear-gradient(90deg, #415160 0%, #293440 107.88%));
`;

export  const IAUVTableHeaderCell  = styled.div`
display:flex;
flex:1;
/* background-color:black; */
align-items:center;
padding:1.25rem;
opacity: 0.7;
&:first-child{
  flex:1.5;

}
&:last-child{
  flex:0.5;
  justify-content: center;
  opacity: 1;
};
`;

export  const IAUVTableBody  = styled.div`
display:flex;
flex-direction:row;
width:100%;
gap: 0.3rem;
justify-content:space-between;
/* background-color:palegreen; */
/* height: 3.625rem; */
flex-shrink: 0;
align-items:center !important;

/* background: var(--Grey-Gradient, linear-gradient(90deg, #415160 0%, #293440 107.88%)); */
&:nth-child(odd){
  background: rgba(255, 255, 255, 0.03);
}
`;

export  const IAUVTableBodyCell  = styled.div`
display:flex;
flex:1;
/* background-color:aqua; */
align-items:center !important;
padding:1.25rem;



&:first-child{
  flex:1.5;
  align-items:center;
  align-self:center;
  

}
&:last-child{
  flex:0.5;
  justify-content: center;
  cursor: pointer;
  position: relative;
};
`;

export  const IAUVFirstChild  = styled.div`
display:flex;
gap:0.3rem;
align-items:center;
width:100%;
cursor: pointer;
align-self:center;
padding-left:0.3rem;

`;

export  const IAUVImg  = styled.img`
width: 3.125rem;
height: 3.125rem;
flex-shrink: 0;
border-radius:50%;
margin-right:0.2rem;

`;