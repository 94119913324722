import React, { useState } from 'react'
import { SimilarityReportBody, SimilarityReportBodyCells, SimilarityReportContainer, SimilarityReportHeader } from './SimilarityReportStyled'
import { LightTypography, Typography } from '../../elements/fonts/Fonts';
import { SvgEyeIcon, SvgSimpleArrow } from '../../elements/Icons';
import { ReactComponent as Download } from '../../assets/icons/Download.svg'
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { auth0SubIdSplitter, auth0ProviderSplitter } from '../../utils/StringManipulation';
import LoaderCircle from '../loader/loaderCircle/LoaderCircle';
interface SimilarityReportInteface {
  title: string;
  OverallMatchValue?: any;
  InternetMatchValue?: any;
  submissionId?: string;
  setTurnitinUrl?: any;
  setShowTurnitinViewer?: any;
  student?: any[];
  studentIndex?: number;
  handleChangeViewForAssignment?: any;
}

const SimilarityReport = ({ title, OverallMatchValue, InternetMatchValue, submissionId, setTurnitinUrl, setShowTurnitinViewer, student, studentIndex, handleChangeViewForAssignment }: SimilarityReportInteface) => {
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem('tenant');
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const language = localStorage.getItem("language");
  const getSimilarityReportUrl = async (pdfReport: boolean) => {
    try {
      if (pdfReport) {

        handleChangeViewForAssignment && handleChangeViewForAssignment();
        const response = await axios.get(`https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/assignment/submission/turnitin/url/${submissionId}/${subIdSplitted}/${tenantName}/${student && student[studentIndex || 0].id}?provider=${provider}`)

        // console.log("Url to fetch --->", `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/assignment/submission/turnitin/url/${submissionId}/${subIdSplitted}/${tenantName}/${student && student[studentIndex || 0].id}?provider=${provider}`)

        const foundetObject = response.data.data.find((object: any) => object.submissionOfTurnitin === submissionId)
        if (foundetObject) {
          const turnitinUrl = foundetObject.pdf;
          setTurnitinUrl(turnitinUrl);
          setShowTurnitinViewer(true);
        }

      } else {
        setLoading(true);
        const response = await axios.get(`https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/assignment/submission/turnitin/url/${submissionId}/${subIdSplitted}/${tenantName}/${student && student[studentIndex || 0].id}?provider=${provider}`)

        const foundetObject = response.data.data.find((object: any) => object.submissionOfTurnitin === submissionId)
        if (foundetObject) {
          const turnitinUrl = foundetObject.url.viewer_url;
          setLoading(false)
          // Open the URL in a new tab
          window.open(turnitinUrl, '_blank');
        }
      }


    } catch (error) {
      console.log(error)
    }
  }

  const downloadReportUrl = async () => {
    try {
      setDownloadLoading(true);
      console.log("Download clicked");

      const response = await axios.get(`https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/assignment/submission/turnitin/url/${submissionId}/${subIdSplitted}/${tenantName}/${student && student[studentIndex || 0].id}?provider=${provider}`);
      console.log("Response.data.data of turnitin from Eye icon ---->", response.data.data);

      const foundObject = response.data.data.find((object: any) => object.submissionOfTurnitin === submissionId);

      if (foundObject && student) {
        console.log("Found object --->", foundObject);
        const turnitinUrl = foundObject.pdf;

        // Trigger file download
        const link = document.createElement('a');
        link.href = turnitinUrl;
        link.download = `${student[studentIndex || 0].user.fName} ${student[studentIndex || 0].user.lName}-turnitin-report.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      setDownloadLoading(false);
    } catch (error) {
      console.log(error);
      setDownloadLoading(false);
    }
  };


  return (
    <SimilarityReportContainer>
      <SimilarityReportHeader>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography text={title} fontSize="0.875rem" />
          <SvgEyeIcon
            style={{
              marginLeft: `${language === "ar" ? "" : "0.7rem"}`,
              marginRight: `${language === "ar" ? "0.7rem" : ""}`,
              cursor: "pointer",
            }}
            onClick={() => getSimilarityReportUrl(true)}
          />
          {downloadLoading ?
            <>
              <LoaderCircle />
              <div></div>
            </>
            :
            <Download style={{ marginLeft: ".7rem", cursor: "pointer" }} onClick={downloadReportUrl} />
          }
        </div>

        {loading ? (
          <LoaderCircle />
        ) : (
          <SvgSimpleArrow
            style={{ cursor: "pointer" }}
            onClick={() => getSimilarityReportUrl(false)}
          />
        )}
      </SimilarityReportHeader>
      <SimilarityReportBody>
        <SimilarityReportBodyCells>
          <LightTypography text="Overall Match" fontSize="0.875rem" />
          <Typography
            text={`${OverallMatchValue || ""}%`}
            fontSize=" 0.875rem"
          />
        </SimilarityReportBodyCells>

        <SimilarityReportBodyCells>
          <LightTypography text="Internet Match" fontSize="0.875rem" />
          <Typography
            text={`${InternetMatchValue || ""}%`}
            fontSize=" 0.875rem"
          />
        </SimilarityReportBodyCells>
      </SimilarityReportBody>
    </SimilarityReportContainer>
  );
};

export default SimilarityReport;
