import React, { useContext, useEffect, useState } from "react";
import {
  FRCLeft,
  FolderRecoursesContainer,
  FolderRecoursesLayout,
} from "./FolderResourses.style";
import { FolderResourcesData } from "./FolderResoursesData";
import {
  SvgCoursesIcon,
  SvgDownload,
} from "../../elements/Icons";
import { LightTypography, MediumTypography } from "../../elements/fonts/Fonts";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { S3Get } from "../../services/S3Get";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../utils/StringManipulation";
import { post } from "../../utils/AxiosRequests";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { downloadFile } from "../dashboardContents/ResourcesPerModule/resourceCard/downloadFile";
interface FileProps {
  fileId: string;
}

const FolderRecourses = ({ fileId }: FileProps) => {
  const { progressChanged, setProgressChange } = useContext(ClassContext)

  const tenantName = localStorage.getItem("tenant");
  const [s3Url, setS3Url] = useState();
  const [data, setData] = useState<any>();
  const [folderData, setFolderData] = useState<any>();

  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState<Array<any>>([]);
  const params = useParams();
  const classId = params.classId;
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadAll = async () => {

    setIsDownloading(true);
    const zip = new JSZip();

    await Promise.all(files.map(async (file) => {
      if (file.s3Document) {
        try {
          const response = await fetch(file.s3Document);
          const blob = await response.blob();
          zip.file(file.fileName, blob);
        } catch (error) {
          console.error("Error fetching file:", error);
        }
      }
    }));

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${folderData.title}.zip` || "files.zip");
      setIsDownloading(false);
    });
    const trackingData = {
      classSourceId: classId,
      length: "",
      percentage: 100,
      refId: fileId,
      type: "Folder",
      subId: subIdSplitted,
      provider: provider,
    };
    await post(`completionTracking/create/${tenantName}`, trackingData);
    setProgressChange(!progressChanged);
    setProgressChange(!progressChanged);

    // files.forEach(async (file) => {
    //   const trackingData = {
    //     classSourceId: classId,
    //     length: "",
    //     percentage: 100,
    //     refId: fileId,
    //     type: "Folder",
    //     subId: subIdSplitted,
    //     provider:provider,
    //   };
    //   await post(`completionTracking/create/${tenantName}`, trackingData);
    //   setProgressChange(!progressChanged);
    // });
  };


  useEffect(() => {
    setIsDownloading(false)
    const fetchDocument = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/resource/folder/${fileId}/${tenantName}`
        );

        setData(response.data.data.files);
        setFolderData(response.data.data.folder);

        const files = response.data.data.files;
        const filePromises = files.map(async (file: any) => {
          try {
            const s3Document = await S3Get(file.filePath);
            return { ...file, s3Document };
          } catch (error) {
            console.error(error);
          }
        });

        const resolvedFiles = await Promise.all(filePromises);
        if (resolvedFiles) {
          setFiles(resolvedFiles);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchDocument();
  }, [fileId]);

  const { theme } = useRecoilValue(ColorSelector);

  return (
    <>
      {files && !loading ? (
        <FolderRecoursesLayout>
          <div
            style={{ cursor: "pointer", display: "flex", flexDirection: "row-reverse" }}
            onClick={() => {
              if (!isDownloading) handleDownloadAll();
            }}
          >

            <div style={{ cursor: "pointer", display: "flex", alignItems: "center", columnGap: "0.62rem" }}>
              <SvgDownload color="#5dd3b3" />
              <LightTypography
                text={isDownloading ? `${t('general.loading')}...` : t("assignment.Download all")}
                color="#5DD3B3"
                fontSize="0.875rem"
              />
            </div>
          </div>
          {files.map((val) => (
            <FolderRecoursesContainer theme={theme} key={val.fileName}>
              <FRCLeft>
                <SvgCoursesIcon width={"1rem"} height={"1rem"} />
                <MediumTypography
                  text={
                    (val.fileName || "").length > 30
                      ? (val.fileName || "").substring(0, 30) + "..."
                      : val.fileName || ""
                  }
                />
              </FRCLeft>
              {/* <Link to={val.s3Document || ""}> */}
              <FRCLeft style={{ cursor: "pointer" }} onClick={() => downloadFile({ s3Document: val.s3Document, fileName: val.fileName })}>
                <SvgDownload color="#5dd3b3" />
                <LightTypography
                  text={t("online session.Download")}
                  color="#5DD3B3"
                  fontSize="0.875rem"
                />
              </FRCLeft>
              {/* </Link> */}
            </FolderRecoursesContainer>
          ))}
        </FolderRecoursesLayout>
      ) :
        <div>Loading...</div>}
    </>
  );
};

export default FolderRecourses;
