import React from "react";
import { AnalyticsForumContainer } from "../AnalyticsForum.style";
import {
  ProgressBarChartRendered,
  ProgressBarChartRenderedRow,
} from "../../Charts/ProgressBarChart/ProggresBarChart.style";
import {
  LightTypography,
  MediumTypography,
} from "../../../elements/fonts/Fonts";
import ProgressBar from "../../../elements/progressBar/ProgressBar";
interface AnalyticsForumProgressBar {
  MaxNumber: number;
  PostsNumber: number;
  ReliesNumber: number;
  NotParticipateNumber: number;
}
const AnalyticsForumProgressBar = ({
  MaxNumber,
  PostsNumber,
  ReliesNumber,
  NotParticipateNumber,
}: AnalyticsForumProgressBar) => {
  return (
    <AnalyticsForumContainer>
      <ProgressBarChartRendered>
        <ProgressBarChartRenderedRow>
          <LightTypography text={"Posts"} fontSize="1rem" />
          <MediumTypography text={PostsNumber.toString()} fontSize="1rem" />
        </ProgressBarChartRenderedRow>
        <ProgressBar
          maxprogress={MaxNumber}
          progressColor="rgba(255, 255, 255, 0.50)"
          Height="0.9375rem"
          color="#5DD3B3"
          progress={PostsNumber}
        />
      </ProgressBarChartRendered>
      <ProgressBarChartRendered>
        <ProgressBarChartRenderedRow>
          <LightTypography text={"Relies"} fontSize="1rem" />
          <MediumTypography text={ReliesNumber.toString()} fontSize="1rem" />
        </ProgressBarChartRenderedRow>
        <ProgressBar
          maxprogress={MaxNumber}
          progressColor="rgba(255, 255, 255, 0.50)"
          Height="0.9375rem"
          color="#5DD3B3"
          progress={ReliesNumber}
        />
      </ProgressBarChartRendered>
      <ProgressBarChartRendered>
        <ProgressBarChartRenderedRow>
          <LightTypography text={"Did Not Participate"} fontSize="1rem" />
          <MediumTypography
            text={NotParticipateNumber.toString()}
            fontSize="1rem"
          />
        </ProgressBarChartRenderedRow>
        <ProgressBar
          maxprogress={MaxNumber}
          progressColor="rgba(255, 255, 255, 0.50)"
          Height="0.9375rem"
          color="#5DD3B3"
          progress={NotParticipateNumber}
        />
      </ProgressBarChartRendered>
    </AnalyticsForumContainer>
  );
};

export default AnalyticsForumProgressBar;
