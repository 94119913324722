import React, { FC } from "react";
import {
  Container,
  StatHeader,
  QuestionsContainer,
  QuestionsNumber,
  OptionContainer,
  OptionsItemsOne,
  OptionsItemsTwo,
  VerticalLine,
} from "./SurveyStatistics.style";
import { StatisticsProps } from "./surveyStatisticsInterface";
import { theme } from "../../theme";
import { Typography } from "../../elements/fonts/Fonts";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

const Statistics: FC<StatisticsProps> = ({
  questions,
  multipleChoice,
  essay,
  yesNo,
  rating,
  scale,
}) => {
  const { t } = useTranslation();
  const { SecondaryTextColor } = useRecoilValue(ColorSelector);
  const language = localStorage.getItem("language")
  return (
    <Container>
      <StatHeader>
        <Typography text={t("quiz.SUMMARY")} fontSize="1.75rem" />
      </StatHeader>
      <QuestionsContainer>
        <QuestionsNumber>{questions}</QuestionsNumber>
        <Typography
          text={t("quiz.Questions")}
          fontSize="0.75rem"
          color={SecondaryTextColor}
        />
      </QuestionsContainer>

      <OptionsItemsOne>
        <OptionContainer>
          <Typography
            text={t("quiz.MULTIPLE CHOICE").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
          <Typography text={`${multipleChoice}`} fontSize="0.625rem" />
        </OptionContainer>
        <OptionContainer>
          <Typography
            text={`${t("survey.YES").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")} - ${t("survey.NO").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}`}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
          <Typography
            text={`${yesNo}`}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
        </OptionContainer>
        <OptionContainer>
          <Typography
            text={t("survey.RATING").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
          <Typography text={`${rating}`} fontSize="0.625rem" />
        </OptionContainer>
      </OptionsItemsOne>

      <OptionsItemsTwo>
        <OptionContainer>
          <Typography
            text={t("survey.SCALE").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
          <Typography
            text={`${scale}`}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
        </OptionContainer>
        <OptionContainer>
          <Typography
            text={t("quiz.ESSAY").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
          <Typography
            text={`${essay}`}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
        </OptionContainer>
      </OptionsItemsTwo>
    </Container>
  );
};

export default Statistics;
