import styled from "styled-components";

// export const GAA = styled.div``;

export const GAALayout = styled.div`
display:flex;
flex-direction:row;
min-width: 20rem;
height: 100%;
margin-left: -0.5rem;
flex: 2.5;
gap:1.25rem;
/* background-color:black; */
padding: 1.87rem 1.25rem ;

`;

export const GAAProfile = styled.img`
width: 3.125rem;
height: 3.125rem;
flex-shrink: 0;
border-radius:50%;
`;

export const GAAContainer = styled.div`
display: flex;
flex-direction:column;
gap:1.25rem;
width:100%;

`;

export const GAAdesc = styled.div`
display: flex;
width:100%;
margin-top:-0.62rem;
align-items:center;
`;

export const GAAContent = styled.div`
display:flex;
width:100%;
gap:0.62rem 1.25rem;
border-radius: 0.625rem;
background: rgba(255, 255, 255, 0.03);
justify-content:space-between;
padding:0.62rem 1.25rem;
padding-right:2.30rem;
`;

export const GAAGaps = styled.div`
display:flex;
gap:1.25rem;
align-items:center;
`;

export const GAAActions = styled.div`
display:flex;
gap:0.62rem;
align-items:center;
cursor: pointer;
color:#5DD3B3;
`;