import styled from "styled-components";
import { breakpoints } from "../../../theme";

export const DashboardContentsHolder = styled.div<{
  bgColor?: string;
  boxShadow?: string;
  language?: string | null;
}>`
  padding: 0.7rem 0 0 0;
  /* max-height: 24rem !important; */
  height: 100%;
  background: ${(props) =>
    props.bgColor || "linear-gradient(180deg, #415160 0%, #293440 100%)"};
  border-radius: 1rem;
  margin-bottom: 2rem;
  box-shadow: ${(props) => props.boxShadow};
`;
export const ResourcesTitleSliderStyle = styled.div`
  display: flex;
  &:dir(ltr) {
    padding-left: 1rem;
  }
  &:dir(rtl) {
    padding-right: 1rem;
  }
  flex-direction: column;
  row-gap: 0.63rem;
  position: relative;
`;
export const ContentTab = styled.div<{ activeTab: number }>`
  padding: 0.6rem 0.45rem;
  border-radius: 0.625rem 0.625rem 0rem 0rem;
  cursor: pointer;
`;
export const ContentTabCont = styled.div`
 display: flex;
  &:dir(ltr) {
    padding-left:2.37rem;
  }
  &:dir(rtl) {
    padding-right:2.37rem;
  }
`;
