import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ColorSelector } from '../../../recoil/ThemeSelectors';
import TopicItem from '../topicItem/TopicItem';
import { Module } from '../utils/CourseResourcesInterface';
import { TimeLineModule, TimeLineModuleTop, TimeLineModuleTopTitle, TopicsHolder } from './ModuleItem.style';
import { selectedContent } from '../../../recoil/RecoilStates';
import { useParams } from 'react-router-dom';
import { SvgArrowDownIcon, SvgArrowUpIcon, SvgNoResult } from '../../../elements/Icons';
import { Typography, TypographyRegularDescription } from '../../../elements/fonts/Fonts';
import { toArabicDigits } from '../../../utils/formatDayArabic';
import { useTranslation } from 'react-i18next';
import { NoTopicAvailable } from '../topicItem/TopicItem.style';

export default function ModuleItem({ module, onSelectResource }: { module: Module, onSelectResource: Function }) {
    const [isModuleOpen, setIsModuleOpen] = useState<{ [key: number]: boolean }>({});
    const { classId } = useParams();
    const [selectedResourceID] = useRecoilState(selectedContent(classId));

    const toggleModule = (index: number) => {
        setIsModuleOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    useEffect(() => {
        if (selectedResourceID && selectedResourceID.moduleIndex === module.moduleIndex) {
            setIsModuleOpen((prevState) => ({
                ...prevState,
                [module.moduleIndex]: true,
            }));
        } else {
            setIsModuleOpen((prevState) => ({
                ...prevState,
                [module.moduleIndex]: false,
            }));
        }
    }, [module.moduleIndex]);//add selectedresourceId as a dependency if needed to close the other modules same for topic

    const { backgroundColor, bghelp, themeTextColor, boxShadow } = useRecoilValue(ColorSelector);
    const { t } = useTranslation()
    return (
        <TimeLineModule bgColor={bghelp}>
            <TimeLineModuleTop onClick={() => toggleModule(module.moduleIndex)}>
                <TimeLineModuleTopTitle>
                    <div style={{ display: "flex", columnGap: "0.2rem" }}>
                        <Typography
                            text={`${t("course view.Module")} ${toArabicDigits(
                                (module.moduleIndex).toString()
                            )} : `}
                            fontSize="0.75rem"
                            wordWrap="break-word"
                            whiteSpace="nowrap"
                        />
                        <Typography text={module.moduleTitle} fontSize='0.75rem' />
                    </div>
                    {isModuleOpen[module.moduleIndex] ? (
                        <SvgArrowUpIcon />
                    ) : (
                        <SvgArrowDownIcon />
                    )}
                </TimeLineModuleTopTitle>
                <TypographyRegularDescription
                    text={`${toArabicDigits(
                        module.topicsArray.length.toString()
                    )} ${t("course view.Topics")}`}
                    color="#5DD3B3"
                    fontSize="0.625rem"
                />
            </TimeLineModuleTop>
            {isModuleOpen[module.moduleIndex] && (
                <TopicsHolder>
                    {module.topicsArray && module.topicsArray.length > 0 ?
                        (module.topicsArray
                            .sort((a, b) => a.topicIndex - b.topicIndex)
                            .map((topic) => (
                                <TopicItem
                                    key={`${module.moduleIndex}-${topic.topicIndex}`}
                                    topic={topic}
                                    moduleIndex={module.moduleIndex}
                                    onSelectResource={onSelectResource}
                                    moduleTitle={module.moduleTitle}
                                />
                            ))) :
                        <NoTopicAvailable
                            bgColor={backgroundColor}
                            borderColor={bghelp}
                        // style={{ boxShadow: boxShadow }}
                        >
                            <SvgNoResult width={"10rem"} />
                            <Typography text={t("course view.Empty Module")} />
                        </NoTopicAvailable>
                    }
                </TopicsHolder>
            )}
        </TimeLineModule>
    );
}
