import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FlexedDiv, LowerContainer, Profileimg, MainContainer, ParticipantContainer, ParticipantImg, RightParticipantinfo } from './ViewParticipant.style'
import { LightTypography, MediumTypography } from '../../elements/fonts/Fonts'
import { MainContainerLoader } from '../editGroup/EditGroup.style'
import { SpinnerContainer } from '../../elements/Spinner/ZSpinner/ZSpinner.style'
import { Spinner } from '@react-pdf-viewer/core'
import { useRecoilValue } from 'recoil'
import { ColorSelector } from '../../recoil/ThemeSelectors'
import { groupProfile } from '../../elements/Images/Images'



const ViewParticipant = (selectedGroupId: any) => {
  console.log(selectedGroupId, "participants");
  const tenantName = localStorage.getItem("tenant");
  const [Participant, SetParticipant] = useState<any>();
  const image = require("../../assets/userProfile.png");
    const [AllLoading,SetAllLoading]=useState(true)  
    const { boxShadowBanner } = useRecoilValue(ColorSelector);

  const getParticipant = async (conversationId: any) => {
    try {
      const response = await axios.get(
        `https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/get/conversation/${conversationId}/${tenantName} `
      );
      console.log(response.data.data, "participants");
      SetParticipant(response.data.data);
        SetAllLoading(false)
    } catch (err) {
      console.log(err);
    }
}
  useEffect(()=>{
    getParticipant(selectedGroupId.selectedGroupId)
  },[])  

  if (AllLoading) {
    return (
      <MainContainerLoader>

      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
      </MainContainerLoader>
    );
  }

  return (
    <>
      {Participant && Participant.ParticipantsInfo.length > 0 && (
        <MainContainer>
          <FlexedDiv>
            <Profileimg
              src={Participant?.profile ? Participant?.profile : groupProfile}
            />
            <MediumTypography text={Participant?.name} fontSize="1rem" />
          </FlexedDiv>
          <LowerContainer>
            <MediumTypography
              text={`Participants ( ${Participant.ParticipantsInfo.length} )`}
              fontSize="1rem"
            />
          </LowerContainer>
          {Participant.ParticipantsInfo.map(
            (
              info: {
                sourceId: any;
                profilePicture: string | undefined;
                username: string;
                email: string;
              },
              index: any
            ) => (
              <ParticipantContainer key={info.sourceId || index} boxshadow={boxShadowBanner}>
                <ParticipantImg
                  src={info.profilePicture ? info.profilePicture : image}
                />
                <RightParticipantinfo>
                  <MediumTypography text={info.username} fontSize="1rem" />
                  <MediumTypography
                    text={`ID: ${info.sourceId}`}
                    fontSize="0.75rem"
                    color="#AAB1B9"
                  />
                  <MediumTypography
                    text={info.email}
                    fontSize="0.75rem"
                    color="#D9E4E8"
                  />
                </RightParticipantinfo>
              </ParticipantContainer>
            )
          )}
        </MainContainer>
      )}
    </>
  );
};

export default ViewParticipant;
