import styled from "styled-components";
import { breakpoints } from "../../theme";

export const MainInstructorInformationRow = styled.div`
  display: flex;
  width: 100%;
  gap: 3.06rem;
  align-items: flex-start;
`;
export const MainInstructorInformationSelectedRow = styled.div`
  display: flex;
  align-items: center;
`;

export const InstructorCheckBoxColumn = styled.div`
  display: flex;
  width: 10rem;
  height: 2.2rem;
  /* background-color: blue; */
  flex-direction: column;
  justify-content: center;
  /* background: purple; */
  /* gap: 1.62rem; */
  /* justify-content: flex-start; */
  /* align-items: center; */
`;

export const InstructorCheckBoxRow = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  /* gap: 0.6rem; */
  @media (max-width: ${breakpoints.tablets}) {
    flex-wrap: wrap;
  }
`;

export const InstructorTimeColumn = styled.div`
  /* justify-content: space-around; */
  display: flex;
  flex-direction: column;
  gap: 0.63rem;
  /* flex-wrap: wrap; */
  margin-bottom: 0.5rem;

  padding-left: 1.2rem;
  width: 100%;
`;
