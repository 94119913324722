import React from "react";
import { ProgressBarStyle, ProgressBarContainer } from "./ProgressBar.style";

import { ProgressBarInterface } from "./ProgressBarInterface";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

const ProgressBar = ({
  color,
  progress,
  progressColor,
  maxprogress,
  Height = "0.375rem",
}: ProgressBarInterface) => {
  const { themeProgressColor } = useRecoilValue(ColorSelector);
  return (
    // don't use progressColor  and keep as  taking value from themeProgressColor
    <ProgressBarContainer
      maxprogress={maxprogress || 0}
      progresscolor={progressColor || themeProgressColor}
      height={Height}
    >
      <ProgressBarStyle
        maxprogress={maxprogress || 0}
        color={color}
        progress={progress}
      ></ProgressBarStyle>
    </ProgressBarContainer>
  );
};

export default ProgressBar;
