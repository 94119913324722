import styled, { css } from "styled-components";
import { breakpoints } from "../../../theme";

interface SidebarProps {
  sidebarmini: string;
}

const mediaQueries = (mini: boolean, componentType: string) => {
  if (mini) {
    switch (componentType) {
      case "Rectangle":
        return css`
          @media (max-width: ${breakpoints.extraLarge}) {
            /* Styles specific to Rectangle component */
            display: none;
          }

          @media (max-width: calc(${breakpoints.mobile} + 25px)) {
            /* Additional styles specific to Rectangle component */
            display: none !important;
          }
        `;
      case "Foot":
        return css`
          @media (max-width: ${breakpoints.extraLarge}) {
            padding: 0;
            align-items: center !important;
          }
          @media (max-width: calc(${breakpoints.mobile}+ 25px)) {
            display: none !important;
          }
        `;
      case "FooterStyle":
        return css`
          @media (max-width: ${breakpoints.extraLarge}) {
            display: none;
          }
        `;
      case "FooterDots":
        return css`
          @media (max-width: ${breakpoints.extraLarge}) {
            display: block;
            color: #89949c;
            cursor: pointer;
            text-align: center;
          }
        `;
      default:
        return css``;
    }
  } else {
    // If SidebarMini is false, don't apply any media queries
    return css``;
  }
};
interface RectangleProps {
  boxshadow?: string;
  backgroundcolor: string;
  contenttype: string;
  overlaybgcolor: string;
  theme: string;
  includescoursecreator?: string;
}
export const Rectangle = styled.div<RectangleProps>`
  background-color: ${({
    contenttype,
    overlaybgcolor,
    backgroundcolor,
    includescoursecreator,
  }) => backgroundcolor};

  padding: 4.56rem 0 1.38rem 0 !important;
  position: relative;
  height: 100%;
  /* z-index: 155 !important; */
  /* background-color: #384450; */
  &:dir(rtl) {
    border-radius: 25px 0px 0px 25px;
  }
  &:dir(ltr) {
    border-radius: 0px 25px 25px 0px;
  }
  box-shadow: ${(props) => props.boxshadow};
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
`;
export const SideItem = styled.div``;

export const Foot = styled.div<SidebarProps>`
  padding: 0 1.38rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  /* @media (max-width: ${breakpoints.extraLarge}) {
    padding: 0;
    align-items: center !important;
  }
  @media (max-width: calc(${breakpoints.mobile}+ 25px)) {
    display: none !important;
  } */

  ${(props) => mediaQueries(props.sidebarmini === "true", "Foot")}
`;
export const FooterStyle = styled.div<SidebarProps>`
  /* @media (max-width: ${breakpoints.extraLarge}) {
    display: none;
  } */
  ${(props) => mediaQueries(props.sidebarmini === "true", "FooterStyle")}
`;
export const Footerdots = styled.div<SidebarProps>`
  width: 100%;
  display: none;
  /* @media (max-width: ${breakpoints.extraLarge}) {
    display: block;
    color: #89949c;
    cursor: pointer;
    text-align: center;
  } */
  ${(props) => mediaQueries(props.sidebarmini === "true", "FooterDots")}
`;
export const ZidyiaLogoStyle = styled.div`
  height: fit-content;
`;

export const DividLineHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
`;

export const DividLine = styled.div`
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(87, 99, 113, 0.6);
`;
