import React from "react";

import { TypographyRegularDescription } from "../fonts/Fonts";
import {
  ButtonIconAndTextStyle,
  ButtonsContainerDisplayed,
} from "./ButtonSocialMedia.style";

import { ReactComponent as SVGSend } from "../../assets/icons/Send.svg";
import { ReactComponent as SVGEMAIL } from "../../assets/icons/Email.svg";
import { ReactComponent as SVGLinkedIn } from "../../assets/icons/linkedin-icon-1.svg";
import { ReactComponent as SVGTwitter } from "../../assets/icons/Twitter.svg";
import { ReactComponent as SVGYouTube } from "../../assets/icons/iconmonstr-youtube.svg";
import { ReactComponent as SVGTiktok } from "../../assets/icons/tik-tok.svg";

import { SVGWebsite } from "../../elements/Icons";
import { useTranslation } from "react-i18next";

interface PROFILELinks {
  ProfilLink?: string;
  LinkedInLink?: string;
  YouTubeLink?: string;
  TitTokLink?: string;
  EmailVALUE?: string;
  XLink?: string;
  Website?: string;
  Message?: string;
}

export const ButtonSocialMediaTsx = ({
  ProfilLink,
  LinkedInLink,
  YouTubeLink,
  TitTokLink,
  EmailVALUE,
  XLink,
  Website,
  Message,
}: PROFILELinks): JSX.Element => {
  const { t } = useTranslation();
  const handleLinkClick = (link: string | undefined) => {
    if (link) {
      if (!/^https?:\/\//i.test(link)) {
        link = `https://${link}`;
      }
      window.open(link, "_blank");
    }
  };

  function sendEmail(Email: string | undefined) {
    if (Email) {
      const emailt = Email;
      window.location.href = `mailto:${emailt}`;
    }
  }

  const SendMessage = (phoneNumber: string | undefined) => {
    if (phoneNumber) {
      window.open(`sms:${phoneNumber}`);
    }
  };

  return (
    <ButtonsContainerDisplayed>
      {/* <ButtonIconAndTextStyle onClick={() => SendMessage(Message)}>
        <SVGSend />
        <TypographyRegularDescription text="Message" />
      </ButtonIconAndTextStyle> */}
      <ButtonIconAndTextStyle onClick={() => sendEmail(EmailVALUE)}>
        <SVGEMAIL />
        <TypographyRegularDescription text={t("User Profile.Email")} />
      </ButtonIconAndTextStyle>
      {LinkedInLink && (
        <ButtonIconAndTextStyle onClick={() => handleLinkClick(LinkedInLink)}>
          <SVGLinkedIn />
          <TypographyRegularDescription text="LinkedIn" />
        </ButtonIconAndTextStyle>
      )}
      <ButtonIconAndTextStyle onClick={() => handleLinkClick(XLink)}>
        <SVGTwitter />
        <TypographyRegularDescription text="X" />
      </ButtonIconAndTextStyle>
      <ButtonIconAndTextStyle onClick={() => handleLinkClick(YouTubeLink)}>
        <SVGYouTube />
        <TypographyRegularDescription text="Youtube" />
      </ButtonIconAndTextStyle>
      <ButtonIconAndTextStyle onClick={() => handleLinkClick(TitTokLink)}>
        <SVGTiktok />
        <TypographyRegularDescription text="TikTok" />
      </ButtonIconAndTextStyle>
      <ButtonIconAndTextStyle onClick={() => handleLinkClick(Website)}>
        <SVGWebsite />
        <TypographyRegularDescription text={t("User Profile.Website")} />
      </ButtonIconAndTextStyle>
    </ButtonsContainerDisplayed>
  );
};

export default ButtonSocialMediaTsx;
