import styled from "styled-components";

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

export const TooltipText = styled.div<{ margin?: string }>`
  /* visibility: hidden; */
  width: 7.5rem;
height: 1.5rem;
  color: #fff;
  text-align: center;
  border-radius: 1.25rem;
background: #1F2731;
  padding: 0.37rem;
  position: absolute;
  z-index: 999;
  left: 5%;
  top: 120%;
  transform: translateX(-50%); 
  /* opacity: 0; */
  transition: opacity 0.3s;
  font-family: Core Rhino 45 Regular;
font-size: 0.625rem;
font-style: normal;
font-weight: 400;
line-height: normal;
  ${props => props.margin && `margin-left: ${props.margin};`}
 
`;

export const StyledTooltip = styled(TooltipContainer)`
&:hover ${TooltipText} {
  visibility: visible;
  opacity: 1;
}
`