import { useState } from "react";
import { SvgAdd, SvgBook, SvgDelete, SvgEdit, SvgEyeIcon, SvgGear } from "../../elements/Icons";
import { Typography, TypographyRegularDescription } from "../../elements/fonts/Fonts";
import TextElement from "../../elements/textElement/TextElement";
import { GradeBookWrapper, SegementHolder, GradeBox, SaveCancelButtonsRow, ButtonCancel, ButtonAdd, TitleIconRow, AddNewSegRow, TitlePercentageIconsRow, SegmentFlex, AddNewSeg, TitleWithArrow, TitlesWrapper, TitlePageAndAddSeg, SettingsDiv } from "./GradeBook.style";
import GradeBoxData from "./gradeBoxData";
import GradeBookModal from "./gradeBookModal/GradeBookModal";
import DraggableGradeBox from "./draggableGradeBox/DraggableGradeBox";
import DroppableTargetSegment from "./droppableTargetSegment/DroppableTargetSegment";
import SelectInput from "../../elements/SelectInput/SelectInput";


// import { SvgArrowDown } from "../../elements/Icons";


const GradeBook = () => {
    interface Segment {
        name: string;
        percentage: string;
        gradeBoxes: GradeBoxInterface[];
       
    }
    interface  GradeBoxInterface{
        title:string,
        segmentIndex:number
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    // const [isSegmentSaved, setIsSegmentSaved] = useState(false);
    // const [isPercentage, setIsPercentage] = useState(100);
    // const [isSegmentName, setIsSegmentName] = useState(100);
  const [segments, setSegments] = useState<Segment[]>([{name:'Grade Segment', percentage:'100',gradeBoxes:[{
    title: "Assignment 01",
    segmentIndex:1
  },
  {
    title: "Assignment 02",
    segmentIndex:1
  },
  {
    title: "Assignment 03",
  
    segmentIndex:1
  },
  {
    title: "Assignment 04",
    segmentIndex:1
  },
  {
    title: "Quiz 01",
    segmentIndex:1
  },
  {
    title: "Quiz 01",
    segmentIndex:1
  },
  {
    title: "Quiz 01",
    segmentIndex:1
  },
]}]);
  const [droppedBoxes, setDroppedBoxes] = useState<string[]>([]);
   
  const handleDrop = (data:any,index:number) => {
   
    const updatedSegments = [...segments];
  
   
    
    const gradeBoxes = updatedSegments[data.index]?.gradeBoxes as GradeBoxInterface[] || [];

 
  if (data.gIndex >= 0 && data.gIndex < gradeBoxes.length) {
    
    gradeBoxes.splice(data.gIndex, 1);
  }
     updatedSegments[index]?.gradeBoxes.push({title:data.name,segmentIndex:data.index})
       
        setSegments(updatedSegments);
  
   
    
      
    }
 
  
  
  
  
  
  


    const addSegment= (segmentName:string, segmentPercentage:string) => {
        const updateSegments = [...segments]
        // const segmentName = `Grade Segment`;
        const newSegment = {
            name: segmentName,
            percentage: segmentPercentage,
            gradeBoxes: [],
          
        };
        const percent=parseInt(updateSegments[0].percentage)-parseInt(segmentPercentage)
        updateSegments[0].percentage=percent.toString()
        updateSegments.push(newSegment)
        setSegments(updateSegments);
    };


    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };
    const saveSegment = (segmentName:string, segmentPercentage:string) => {
        // setIsSegmentSaved(true); 
        setIsModalVisible(false);
        addSegment(segmentName, segmentPercentage);
    }
 
    const calculateTotalPercentage = () => {
        // Assuming segments is an array of objects with a 'percentage' property
        const totalPercentage = segments.reduce((total, segment) => {
            // Convert percentage strings to numbers and add them up
            return total + parseFloat(segment.percentage);
        }, 0);
    
        // Ensure the total percentage is not greater than 100
        return Math.min(100, totalPercentage);
    };

    const ebrollmentOptions = [{ name: 'Sort by' }, { name: 'Filters' }]
 const handleSelectChangeEnrollment = (newValue: string) => {
    // setEditTask({...task, enrollment: newValue});
  };
    

    return (
        <>
        <TitlePageAndAddSeg>
           
                    <div style={{ paddingLeft:'1.25rem', marginBottom:'0.5rem' }}>

                <Typography
                text="Grades"
                fontSize="1.75rem"
                />
                    </div>
                
                <AddNewSeg onClick={toggleModal}>
                <SvgAdd />
                    <Typography
                        text="Add new segment"
                        fontSize="0.625rem"
                        fontWeight="400"
                        lineHeight="normal"
                    />
                </AddNewSeg>
            </TitlePageAndAddSeg>
        <GradeBookWrapper>
        <TitleIconRow>
            <TitlesWrapper>
        <TitleWithArrow>
              {/* <SvgEyeIcon /> */}

              <SelectInput
              zIndex= {1}
                color="#5DD3B3"
                icon={<SvgEyeIcon />}
                height="0.875rem"
                padding="0rem 0rem 0rem 0rem"
                marginLeft="0.63rem"
                border="none"
                fontSize="0.625rem"
                width="80%"
                optionWidth='150.3%'
                value="VIEW" 
                // options={ebrollmentOptions}
                onChange={handleSelectChangeEnrollment}
              />
            </TitleWithArrow>
            <TitleWithArrow>
                <SettingsDiv>
              <SvgGear />
              <Typography text="SETTINGS" fontSize="0.625rem" />
              </SettingsDiv>
            </TitleWithArrow>
            </TitlesWrapper>
            </TitleIconRow>
            
            {segments.map((segment, index) => (
         <DroppableTargetSegment  key={index} handleDrop={handleDrop} index={index} segment={segment} calculateTotalPercentage={calculateTotalPercentage}/>

              ))}
            {segments.length >1 && (
            <SaveCancelButtonsRow>
                <ButtonAdd>
                    <Typography
                        text='Save'
                        color='#fff'
                        fontSize='0.875rem'
                        fontWeight='400'
                        lineHeight='normal'
                    /></ButtonAdd>
                <ButtonCancel> <Typography
                    text='Cancel'
                    color='#D85D66'
                    fontSize='0.875rem'
                    fontWeight='400'
                    lineHeight='normal'
                /></ButtonCancel>
            </SaveCancelButtonsRow>
             )}
            {isModalVisible && <GradeBookModal onClose={closeModal} onSave={saveSegment} />}
        </GradeBookWrapper>
        </>
    )
}

export default GradeBook