import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  IconsGroup,
  IdCols,
  IdRows,
  IdminiCols,
  TextLayout,
} from "./Notes.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";

import DOMPurify from "dompurify";
import { SvgDeleteIcon, SvgEditIcon } from "../../elements/Icons";
import Empty from "../courseCard/Empty";
import axios from "axios";
import { useTranslation } from "react-i18next";
import PopupDelete from "../../elements/PopupDelete/PopupDelete";
import TitleHeaderTextIconNavigator from "../../elements/TitleHeaderTextIconNavigator/TitleHeaderTextIconNavigator";

interface Note {
  id: number;
  title: string;
  content: string;
}

export const NoteById = (): JSX.Element => {
  const { t } = useTranslation();

  const { id } = useParams();
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [deleteNoteId, setDeleteNoteId] = useState<number | null>(null);
  const [noteToDisplay, setNoteToDisplay] = useState<Note>();
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  // Ensure that 'id' is a valid string before parsing it
  const parsedId = id ? parseInt(id, 10) : null;
  const tenantName = localStorage.getItem("tenant");

  // GEt the Note By It's ID Data
  useEffect(() => {
    const getNoteById = async () => {
      try {
        const noteToDisplay = await axios.get(
          `https://8hpec8qhhg.execute-api.eu-west-1.amazonaws.com/dev/note/${tenantName}/${parsedId}`
        );
        setDataLoading(false);
        setNoteToDisplay(noteToDisplay.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    getNoteById();
  }, []);

  if (dataLoading) {
    // Handle the data loading
    return (
      <Empty
        backgroundColor={"transparent"}
        text={`${t("general.loading")}...`}
      />
    );
  }
  if (!noteToDisplay) {
    // Handle the case where the 'id' doesn't match any data
    return <div>Note not found</div>;
  }
  // Handle Edit Note
  const handleEditNote = () => {
    navigate(`/Notes/Edit/${parsedId}`);
  };

  // Open the delete Popup of the note
  const handleDeletePop = (parsedId: number) => {
    setDeleteNoteId(parsedId);
    setShowDeletePopup(true);
  };

  // Delete Note
  const handleDeleteForm = async (id: number) => {
    await axios.delete(
      `https://8hpec8qhhg.execute-api.eu-west-1.amazonaws.com/dev/note/${tenantName}/${id}`
    );
    navigate("/Notes");
    setShowDeletePopup(false);
  };

  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };

  return (
    <>
      <IdCols>
        <TitleHeaderTextIconNavigator title={t("notes.notes")} />
        <IdminiCols>
          <IdRows>
            {/* Title of the note Goes Here */}
            <Typography text={noteToDisplay.title} fontSize="1.75rem" />
            <IconsGroup>
              {" "}
              <SvgEditIcon onClick={handleEditNote} />
              <SvgDeleteIcon
                onClick={() => {
                  if (parsedId !== null) {
                    handleDeletePop(parsedId);
                  }
                }}
              />{" "}
            </IconsGroup>
          </IdRows>
          {/* Description of the note Goes Here */}
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(decodeHtml(noteToDisplay.content)),
            }}
          ></div>
        </IdminiCols>
      </IdCols>

      {/* Delete Popup */}
      {showDeletePopup && ( // Show Delete Popup for the note
        // <PopUpDelete
        //   show={showDeletePopup}
        //   Subject={t('notes.Are you sure you want to delete Note Title from your notes?')}
        //   setShow={setShowDeletePopup}
        //   title={t('notes.Delete Note')}
        //   icon={<SvgDeleteIcon />}
        //   showline={false}
        //   onDelete={() => {
        //     if (deleteNoteId !== null) {
        //       handleDeleteForm(deleteNoteId);
        //     }
        //   }}
        // />
        <PopupDelete
          show={showDeletePopup}
          setShow={setShowDeletePopup}
          title={t(
            "notes.Are you sure you want to delete Note Title from your notes?"
          )}
          handleSubmit={() => {
            if (deleteNoteId !== null) {
              handleDeleteForm(deleteNoteId);
            }
          }}
        />
      )}
    </>
  );
};
