import React, { useState, useEffect } from "react";
import {
  SurveyColScale,
  SurveyLineStyle,
  SurveyScaleAction,
  SurveyScaleActionPlace,
  SurveyScaleDote,
  SurveyScaleActiveDote,
  SurveyScaleLableAction,
  BranchName,
} from "./SurveyLikertScale.style";
import { MediumTypography, TypographyRegularDescription } from "../fonts/Fonts";
import { PointsProData } from "./SurveyLikertScaleData";
import { SvgChecked } from "../Icons";
import {
  Choice,
  Question,
} from "../../components/generateSurvey/generateSurveyDataInterface";
import DropQuestion from "../../components/SurveyBranchCreatorDropQuestion/DropQuestion";
import QuestionCRUDTextEditor from "../../components/SurveyCreatorQuestionCRUD/QuestionCRUDTextEditor/QuestionCRUDTextEditorForBranching";
import MultipleQuestionForBranching from "../../components/SurveyCreatorQuestionCRUD/MultipleQuestion/MultipleQuestionForBranching";
import SurveyRating from "../../components/surveyRating/SurveyRating";
import EssayQuestion from "../../components/SurveyCreatorQuestionCRUD/EssayQuestion/EssayQuestion";
import TrueFalseQuestion from "../../components/SurveyCreatorQuestionCRUD/TrueFalseQuestion/TrueFalseQuestion";
import {
  QuestionIconsContainer,
  QuestionHeaderContainer,
} from "../../components/SurveyCreatorQuestionCRUD/QuestionCRUDTextEditor/QuestionCRUDTextEditor.style";
import { theme } from "../../theme";
import { ErrorDiv } from "../ErrorDiv/Errordiv.style";
import { ReactComponent as AddIcon } from "../../assets/icons/Add (1).svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/Delete 1 copy.svg";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { mapErrorType } from "../../utils/QuizErrorTypes";

interface LikertComponent {
  PartTitle?: string;
  text?: string;
  onChange?: (value: string) => void;
  showText?: boolean;
  quest?: Question;
  allChoices?: Choice[];
  qindex?: number;
  setAllChoices?: (choices: Choice[]) => void;
  FillBranch?: (data: any, cIndex: number) => void;
  showQuest?: boolean;
  changeBranchName?: (cIndex: number, newName: string) => void;
  questions?: Question[];
  index?: number;
  partIndex?: number;
  questionError?: string;
  addChoice?: () => void;
  handleChoiceMultipleQuestionForBranching?: (
    cIndex: number,
    branchChoiceIndex: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  emptyBranch?: (qIndex: number) => void;
}

export const SurveyLikertScale = ({
  PartTitle,
  text,
  showText,
  onChange,
  quest,
  allChoices,
  qindex,
  setAllChoices,
  FillBranch,
  changeBranchName,
  showQuest,
  questions,
  index,
  partIndex,
  questionError,
  addChoice,
  handleChoiceMultipleQuestionForBranching,
  emptyBranch,
}: LikertComponent): JSX.Element => {
  const [activeTexts, setActiveTexts] = useState<Record<number, string | null>>(
    {}
  );
  const [isBranched, setIsBranched] = useState<any>(false);
  const [branchType, setBranchType] = useState("");
  const [branchChoiceTypes, setBranchChoiceTypes] = useState<
    Record<number, string>
  >({});
  const [branchChoices, setBranchChoices] = useState<Choice[]>([]);
  const { bghelp } = useRecoilValue(ColorSelector);
  const { t } = useTranslation();

  const handleSingleBranchChoice = () => {
    const safeIndex = qindex ?? 0;
    // Ensure allChoices exists and has valid data before accessing its properties
    if (allChoices && allChoices[safeIndex]?.branch?.choices) {
      setBranchChoices(allChoices[safeIndex]?.branch?.choices || []);
    }
  };

  useEffect(() => {
    handleSingleBranchChoice();
  }, [questions, index, partIndex, branchChoiceTypes]);

  useEffect(() => {
    // Initialize activeText for each question in the object when allChoices or qindex changes
    if (allChoices && qindex !== undefined) {
      const updatedActiveTexts = { ...activeTexts };
      updatedActiveTexts[qindex] = allChoices[qindex]?.name || null;
      setActiveTexts(updatedActiveTexts);
    }
  }, [allChoices, qindex]);

  useEffect(() => {
    setIsBranched(quest?.branching);
  }, [quest?.branching]);

  const handleScaleItemClick = (text: string) => {
    setActiveTexts((prevActiveTexts) => {
      const updatedActiveTexts = { ...prevActiveTexts };
      updatedActiveTexts[qindex || 0] = text;
      return updatedActiveTexts;
    });

    onChange && onChange(text);

    const updatedChoices = [...(allChoices || [])];
    if (updatedChoices[qindex || 0]?.name !== undefined) {
      updatedChoices[qindex || 0].name = text;
      setAllChoices && setAllChoices(updatedChoices);
    }
  };

  const handleBranchTypeChange = (type: any) => {
    const updatedBranchChoiceTypes = { ...branchChoiceTypes };
    updatedBranchChoiceTypes[qindex || 0] = type;
    setBranchChoiceTypes(updatedBranchChoiceTypes);
  };

  useEffect(() => {
    if (allChoices) {
      const currentBranchChoiceType =
        allChoices[qindex || 0]?.branch?.type || "";
      const updatedBranchChoiceTypes = { ...branchChoiceTypes };
      updatedBranchChoiceTypes[qindex || 0] = currentBranchChoiceType;
      setBranchChoiceTypes(updatedBranchChoiceTypes);
    }
  }, [allChoices]);

  const errorType = mapErrorType(questionError || "", t);

  return (
    <>
      {questionError && qindex === 0 && (
        <ErrorDiv
          style={{
            // marginLeft: "2.37rem",
            marginTop: "-1.5rem",
            marginBottom: "1rem",
          }}
        >
          {errorType}
        </ErrorDiv>
      )}
      <SurveyColScale>
        {showText && (
          <TypographyRegularDescription
            text={PartTitle || ""}
            color="#5DD3B3"
            fontSize="0.875rem"
          />
        )}
        {showText && <MediumTypography text={text || ""} fontSize="0.875rem" />}
        <SurveyScaleAction>
          <SurveyLineStyle bgColor={bghelp}></SurveyLineStyle>
          {isBranched === true ? (
            <SurveyScaleLableAction>
              {PointsProData.map((item) => (
                <SurveyScaleActionPlace
                  key={item.id}
                  onClick={() => handleScaleItemClick(item.name)} // Pass the text value to the callback
                >
                  {activeTexts[qindex || 0] === item.name ? (
                    <SurveyScaleActiveDote>
                      <SvgChecked />
                    </SurveyScaleActiveDote>
                  ) : (
                    <SurveyScaleDote bgcolor={bghelp}></SurveyScaleDote>
                  )}
                  <TypographyRegularDescription
                    text={item.name}
                    fontSize="0.75rem"
                    lineHeight="1rem"
                  />
                </SurveyScaleActionPlace>
              ))}
            </SurveyScaleLableAction>
          ) : (
            <SurveyScaleLableAction>
              {PointsProData.map((item) => (
                <SurveyScaleActionPlace
                  key={item.id}
                  // onClick={() => handleScaleItemClick(item.name)} // Pass the text value to the callback
                >
                  {activeTexts[qindex || 0] === item.name ? (
                    <SurveyScaleActiveDote>
                      <SvgChecked />
                    </SurveyScaleActiveDote>
                  ) : (
                    <SurveyScaleDote bgcolor={bghelp} />
                  )}
                  <TypographyRegularDescription
                    text={t(item.title)}
                    fontSize="0.75rem"
                    lineHeight="1rem"
                  />
                </SurveyScaleActionPlace>
              ))}
            </SurveyScaleLableAction>
          )}
        </SurveyScaleAction>
      </SurveyColScale>

      {isBranched && qindex === 0 && (
        <QuestionHeaderContainer>
          <QuestionIconsContainer>
            <MediumTypography
              text="Create the set of questions when student chooses this answer"
              color={theme.dark.primary_text_color}
            />
          </QuestionIconsContainer>
          <AddIcon onClick={addChoice} />
        </QuestionHeaderContainer>
      )}

      {isBranched && !branchChoiceTypes[qindex || 0] && (
        <DropQuestion
          qindex={qindex}
          FillBranch={FillBranch}
          OnBranchTypeChange={handleBranchTypeChange}
        />
      )}
      {isBranched && branchChoiceTypes[qindex || 0] && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginRight: "2rem",
              alignItems: "center",
            }}
          >
            <BranchName style={{ marginLeft: "2rem", marginTop: "1rem" }}>
              Branch: {(qindex && qindex + 1) || 1}
            </BranchName>
            {!showQuest && (
              <DeleteIcon
                style={{ cursor: "pointer" }}
                onClick={() => emptyBranch && emptyBranch(qindex || 0)}
              >
                Delete
              </DeleteIcon>
            )}
          </div>
          <div style={{ padding: "2rem", marginTop: "-1.5rem" }}>
            <QuestionCRUDTextEditor
              questionError={questionError}
              qindex={qindex}
              allChoices={allChoices}
              showQuest={showQuest}
              quest={quest}
              changeBranchName={changeBranchName}
            />
          </div>
        </>
      )}
      {isBranched &&
        branchChoiceTypes[qindex || 0] &&
        branchChoiceTypes[qindex || 0] === "Multiple choice" &&
        branchChoices.map((branchchoice, branchChoiceIndex) => (
          <div style={{ padding: "2rem", marginTop: "-3rem" }}>
            <MultipleQuestionForBranching
              key={branchChoiceIndex}
              showQuest={showQuest}
              branchchoice={branchchoice}
              handleChoiceMultipleQuestionForBranching={
                handleChoiceMultipleQuestionForBranching
              }
              qindex={qindex}
              branchChoiceIndex={branchChoiceIndex}
            />
          </div>
        ))}
      {isBranched &&
        branchChoiceTypes[qindex || 0] &&
        branchChoiceTypes[qindex || 0] === "Yes-No" && (
          <div style={{ padding: "2rem", marginTop: "-3rem" }}>
            <TrueFalseQuestion />
          </div>
        )}
      {isBranched &&
        branchChoiceTypes[qindex || 0] &&
        branchChoiceTypes[qindex || 0] === "Rating" && (
          <div style={{ padding: "2rem", marginTop: "-3rem" }}>
            <SurveyRating />
          </div>
        )}
      {isBranched &&
        branchChoiceTypes[qindex || 0] &&
        branchChoiceTypes[qindex || 0] === "Scale" && (
          <div style={{ padding: "2rem", marginTop: "-3rem" }}>
            <SurveyLikertScale />
          </div>
        )}
      {isBranched &&
        branchChoiceTypes[qindex || 0] &&
        branchChoiceTypes[qindex || 0] === "Essay" && <EssayQuestion />}
      {questionError && qindex === 4 && (
        <ErrorDiv style={{ marginLeft: "3.37rem" }}>{errorType}</ErrorDiv>
      )}
    </>
  );
};
