import styled from 'styled-components';

export const FileInputButtonIcon = styled.i`

`;

export const FileInputButton = styled.label<{bgColor?:string}>`
display: flex;
flex-direction:column;
width:7.5rem;
height:7.5rem;
/* background-color:#576371; */
background:${props =>props.bgColor || "#576371"};
gap:0.62rem;

border-radius:50%;
justify-content:center;
align-items:center;
cursor: pointer;
text-align:center;
`;



export const HiddenInput = styled.input`
  display: none;
`;

export const  GroupImage=styled.image`
width:7.5rem;
height:7.5rem;
border-radius:50%;
justify-content:center;
align-items:center;
z-index:999;
`