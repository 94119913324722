import styled from "styled-components";

export const NewAnnouncementsHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0rem;
  margin-bottom: 2rem;
  & > :first-child {
    margin-bottom: 0.94rem;
  }
  & > :nth-child(2) {
    margin-bottom: 1.88rem;
  }
  & > :last-child {
    margin-top: 1.19rem;
    align-self: end;
  }
`;

export const HeaderHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  width: 100%;
`;

export const HeaderChildStyle = styled.div`
  display: flex;
  flex: 1 0 40rem;
  gap: 0.63rem;
  display: flex;
  flex-direction: column;
`;
