export const QuestionTypesData = [
    {
        id: 1,
        typeName: "ESSAY",
        value: "ESSAY"
    },
    {
        id: 2,
        typeName: "Matching",
        value: "MATCH"
    },
    {
        id: 3,
        typeName: "MULTIPLE CHOICE",
        value: "MCQ"
    },
    {
        id: 4,
        typeName: "TRUE/ FALSE",
        value: "TF"
    },
    {
        id: 5,
        typeName: "SHORT ANSWER",
        value: "SHORT_ANSWER"
    },
    {
        id: 5,
        typeName: "NUMERICAL",
        value: "NUMERIC"
    },
]