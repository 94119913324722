import React, { useState, useEffect } from "react";
import {
  GroupsMembersRectangle,
  GroupsMembersWrapper,
  GroupsMembersEmpty,
  GroupsMembersEmptyTextWrapper,
} from "./AllMembersGroups.style";
import { SvgGroupsMembersEmpty } from "../../elements/Icons";
import { LightTypography } from "../../elements/fonts/Fonts";
import MemberInfoBox from "../../elements/memberInfoBox/MemberInfoBox";
import { AllGroupMembersData } from "./allGroupMemberBarData";
import { breakpoints } from "../../theme";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  courseCreatorColorSelector,
} from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

const AllMembersGroups = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation();

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const { boxShadow } = useRecoilValue(ColorSelector);
  const { tooltipTextColor } = useRecoilValue(courseCreatorColorSelector);

  return (
    <GroupsMembersRectangle
      boxShadow={boxShadow}
      membersnumber={AllGroupMembersData.length}
    >
      <GroupsMembersWrapper membersnumber={AllGroupMembersData.length}>
        {AllGroupMembersData && AllGroupMembersData.length === 0 ? (
          <GroupsMembersEmpty>
            <SvgGroupsMembersEmpty color={tooltipTextColor} />
            <GroupsMembersEmptyTextWrapper>
              <LightTypography
                text={t("group.Drag and drop students here to ungroup them")}
                lineHeight="1.375rem"
                fontSize="0.875rem"
              />
            </GroupsMembersEmptyTextWrapper>
          </GroupsMembersEmpty>
        ) : (
          AllGroupMembersData &&
          AllGroupMembersData.length > 0 &&
          AllGroupMembersData.map((student, index) => (
            <></>
            // <div>
            //   <MemberInfoBox
            //     username={student.username}
            //     profilePicture={student.profilePicture}
            //     index={index}
            //     studentId={student.studentId}
            //     groupId={student.groupId}
            //   />
            // </div>
          ))
        )}
      </GroupsMembersWrapper>
    </GroupsMembersRectangle>
  );
};

export default AllMembersGroups;
