import axios from "axios";
import { VideoPlayerHandlers, VolumeHandlers, draggingProps, TimeUpdateHandlers, VideoPlayerDataHandler, VideoPlayerDataHandlerAfter } from "./VideoPlayerInterfaces";
import { S3Get } from "../../services/S3Get";

//playBackSpeedOptions
export const playBackSpeedOptions = [
  { key: "2.0x", value: 2.0, },
  { key: "1.5x", value: 1.5, },
  { key: "1.25x", value: 1.25, },
  { key: "1.0x", value: 1.0, },
  { key: "0.75x", value: 0.75, },
  { key: "0.5x", value: 0.5, },
  { key: "0.25x", value: 0.25, },
];
//fetch Video data
export const fetchVideo = async ({ setInitialProgress, videoId, setPath, setVideoDuration, setCurrentTimeB, setTranscript, setVideoThumbnail, setData, videoPlayer, setLoading, subIdSplitted,provider }: VideoPlayerDataHandler) => {
  const tenantName = localStorage.getItem("tenant");
  try {
    const response = await axios.get(`https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/resource/video/${videoId}/${tenantName}`);
    setData(response.data.data);
    const videoData = response.data.data;
    const response2 = await axios.get(`https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/completionTracking/get/${Number(videoId)}/Video/${subIdSplitted}/${tenantName}?provider=${provider}`)

    let currentT = 0;
    if (response2.data.data[0] && response2.data.data[0].length) {
      currentT = (Number(response2.data.data[0].length) * response2.data.data[0].percentage) / 100;
      setInitialProgress(response2.data.data[0].percentage)
    }
    if (videoData) {

      const [url, thumbnail, transcript] = await Promise.all([S3Get(videoData.path), S3Get(videoData.thumbnail), S3Get(videoData.transcript)]);
      setPath(url);

      setVideoThumbnail(thumbnail);
      setTranscript(transcript);
      // setProgressInitial(response2.data.data[0].percentage)
      setCurrentTimeB(currentT);
      if (videoPlayer.current) {
        videoPlayer.current.src = url;
        videoPlayer.current.load();
      }
    }
  } catch (error) {
    console.error("Error fetching document:", error);
  } finally {
    setLoading(false);
  }
};
export const fetchVideoAfterTrack = async ({ initialProgress, setInitialProgress, videoId, setLoading, subIdSplitted,provider }: VideoPlayerDataHandlerAfter) => {
  const tenantName = localStorage.getItem("tenant");
  try {

    const response2 = await axios.get(`https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/completionTracking/get/${Number(videoId)}/Video/${subIdSplitted}/${tenantName}?provider=${provider}`)
    let currentT = 0;
    if (response2.data.data[0] && response2.data.data[0].length) {
      currentT = (Number(response2.data.data[0].length) * response2.data.data[0].percentage) / 100;
      setInitialProgress(response2.data.data[0].percentage)
      return response2.data.data[0].percentage
    }

  } catch (error) {
    console.error("Error fetching document:", error);
  } finally {
    setLoading(false);
  }
};
// play pause video
export const handleVideoPlayback = ({
  videoPlayer,
  setVideostate,
  play,
}: { videoPlayer: any; setVideostate?: any; play?: boolean }): void => {
  if (videoPlayer.current) {
    if (setVideostate) setVideostate(play);
    if (play) {
      videoPlayer.current.play();
    } else {
      videoPlayer.current.pause();
    }
  }
};

// play video
export const playBtnHandler = ({
  videoPlayer,
  setVideostate,
}: VideoPlayerHandlers): void => {
  if (videoPlayer.current) {
    if (setVideostate) setVideostate(true);
    videoPlayer.current.play();
  }
};
// pause video
export const pauseBtnHandler = ({
  videoPlayer,
  setVideostate,
}: VideoPlayerHandlers): void => {
  if (videoPlayer.current) {
    if (setVideostate) setVideostate(false);
    videoPlayer.current.pause();
  }
};
//play pause video by pressing inside the video container
export const videoPlayerHandler = ({
  videoPlayer,
  setVideostate,
}: VideoPlayerHandlers): void => {
  if (setVideostate) setVideostate((p) => !p);
  if (videoPlayer.current) {
    videoPlayer?.current.paused
      ? videoPlayer?.current.play()
      : videoPlayer?.current.pause();
  }
};

//handle back 10 seconds
export const reversePlaybackHandler = ({
  videoPlayer,
  videoState,
}: VideoPlayerHandlers) => {
  if (videoPlayer.current) {
    videoPlayer.current.currentTime -= 10;
    if (videoState) videoPlayer.current.play();
  }
};
//handle forward 10 seconds
export const forwardPlaybackHanlder = ({
  videoPlayer,
  videoState,
}: VideoPlayerHandlers) => {
  if (videoPlayer.current) {
    videoPlayer.current.currentTime += 10;
    if (videoState) videoPlayer.current.play();
  }
};

//volume handlers
//handle mute !mute
export const volumeHandler = ({
  videoPlayer,
  setCurrVolume,
  setVolumeState,
}: VolumeHandlers) => {
  if (videoPlayer.current) {
    setVolumeState((p) => {
      if (!p) {
        setCurrVolume(0);
      }
      return !p;
    });

    videoPlayer.current.muted
      ? (videoPlayer.current.muted = false)
      : (videoPlayer.current.muted = true);
  }
};
//handle volume change
export const volumeChangeHandler = (
  e: React.ChangeEvent<HTMLInputElement>,
  { videoPlayer, setCurrVolume, setVolumeState }: VolumeHandlers
) => {
  const volume: number = parseFloat(e.target.value) || 0;
  setCurrVolume(volume);
  if (volume <= 0) {
    setVolumeState(false);
  } else {
    setVolumeState(true);
  }
};
//video progress
export const handleMouseUpDocument = ({ setIsDragging }: draggingProps) => {
  if (setIsDragging) setIsDragging(false);
};
export const handleMouseDown = (
  e: React.MouseEvent<HTMLDivElement>,
  { setIsDragging }: draggingProps
) => {
  if (setIsDragging) {
    setIsDragging(true);
  }
};

export const handleMouseMove = (
  e: React.MouseEvent<HTMLDivElement>,
  { videoPlayer, isDragging }: draggingProps
) => {
  if (videoPlayer) {
    if (isDragging) {
      const currentTimeLine = document.querySelector("#currentTimeLine") as HTMLElement;

      if (currentTimeLine) {
        const timelineRect =
          currentTimeLine.parentElement?.getBoundingClientRect();

        if (timelineRect) {
          const newMouseX = e.clientX;
          const newWidth =
            ((newMouseX - timelineRect.left) / timelineRect.width) * 100;
          currentTimeLine.style.width = newWidth + "%";
          if (videoPlayer.current) {
            const newTime = (newWidth / 100) * videoPlayer.current.duration;
            videoPlayer.current.currentTime = newTime;
          }
        }
      }
    }
  }
};

export const handleMouseUp = ({ setIsDragging }: draggingProps) => {
  if (setIsDragging) setIsDragging(false);
};


//video duration 
export const handleTimeUpdate = ({ videoPlayer, progress, setProgress, setVideoDuration, setCurrentTimeLineWidth }: TimeUpdateHandlers) => {
  if (videoPlayer) {
    if (videoPlayer.current && videoPlayer.current.duration) {
      const currentTimeLine = document.querySelector("#currentTimeLine") as HTMLElement;
      let widthPercentage;

      if (currentTimeLine) {
        widthPercentage =
          ((videoPlayer.current.currentTime || 0) / videoPlayer.current.duration) * 100 + "%";
        // currentTimeLine.style.width = widthPercentage;
        setCurrentTimeLineWidth(widthPercentage);
      }
      const formattedCurrentTime = formatTime(videoPlayer.current.currentTime);
      const totalDuration = formatTime(videoPlayer.current.duration);
      const currentProgress = (videoPlayer.current.currentTime / videoPlayer.current.duration) * 100;
      if (currentProgress > progress) {
        setProgress(currentProgress);
      }
      setVideoDuration((prevState) => ({ ...prevState, current: formattedCurrentTime, total: totalDuration, }));
    }
  }
};

export const formatTime = (time: any) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);
  return `${hours}:${minutes}:${seconds}`;
};