interface  CompetencyInterface{
  CompetencyId: string
  CompetencyName: string;
  decs?: string;
  PieData:{
    text: string;
    val: number;
    bgColor?: string;
  }[]
}

export const CompetencyData :CompetencyInterface = 
  {
    CompetencyId:"1",
    CompetencyName:'Communication',
    decs:`This skill is essential for any`,
    PieData:[
      {
        text:"Advanced",
        val: 45.0,
        
      },
      {
        text:"Intermediate",
        val: 35.0,
      },
      {
        text:"Poor",
        val: 20.0,
      },
    ]

  }


