import React, { useState, useEffect, useRef } from "react";
import Pagination from "../../../elements/Pationation/Pagination";
import {
  NoteCardDiv,
  NoteLine,
  NoteMainRow,
  NoteMiniRows,
  TextUnderlined,
  TitleAndMenueRow,
  AllOnecol,
  OptionRows,
  OptionCard,
  NoteAddButtonClick,
} from "./NotesCard.style";
// import { NotesCardData } from "../NotesCardData";
import { Link, useNavigate } from "react-router-dom";
import {
  LightTypography,
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import ButtonIconAndText from "../../../elements/ButtonIconAndText/ButtonIconAndText";
import { CreateNote } from "../CreateNote/CreateNote";
import { ReactComponent as SVGADD } from "../../../assets/icons/noun_note_1859321.svg";
import PopUpLine from "../../../elements/PopupLine/PopupLine";

import Empty from "../../courseCard/Empty";
import DOMPurify from "dompurify";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../../utils/StringManipulation";
import {
  AddSqIcon,
  SVGMenue,
  SvgDeleteIcon,
  SvgEditIcon,
} from "../../../elements/Icons";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import axios from "axios";
import SelectedOptions from "../../../elements/SelectedOptions/SelectedOptions";
import PopupDelete from "../../../elements/PopupDelete/PopupDelete";
import PopUp from "../../../elements/Popup/PopUp";

interface Note {
  id: number;
  title: string;
  content: string;
}

export const NotesCardCompo = (): JSX.Element => {
  const { backgroundColor, themeTextColor, bg_selectColor, boxShadow } =
    useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  const [openedIndex, setOpenedIndex] = useState<number | null>(null);
  const [showNotePopup, setShowNotePopup] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [deleteNoteId, setDeleteNoteId] = useState<number | null>(null);
  const [notes, setNotes] = useState<Note[]>([]);
  const [newNoteTitle, setNewNoteTitle] = useState<string>("");
  const [newNoteDescription, setNewNoteDescription] = useState<string>("");
  const [areInputsValid, setAreInputsValid] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const [AfterSubmit, setAfterSubmit] = useState<boolean>(true);
  const [allFieldsFilled, setAllFieldsFilled] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { user } = useAuth0();
  const tenantName = localStorage.getItem("tenant");
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const optionCardRef = useRef<HTMLDivElement | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  // Fetch Notes Data from the data base
  const fecthNotesData = async () => {
    try {
      const notesData = await axios.get(
        `https://8hpec8qhhg.execute-api.eu-west-1.amazonaws.com/dev/note/all/${tenantName}/${subIdSplitted}?provider=${provider}`
      );
      setDataLoading(false);

      // Sort notes data by created_at from newest to oldest
      const sortedNotes = notesData.data.data.sort(
        (
          a: { created_at: string | number | Date },
          b: { created_at: string | number | Date }
        ) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        }
      );

      setNotes(sortedNotes);
    } catch (error) {
      console.error(error);
    }
  };

  //Call the fecthNotesData function when the component mount
  useEffect(() => {
    fecthNotesData();
  }, []);
  // console.log("Notes Data", notes);
  useEffect(() => {
    // Function to handle clicks outside the option card
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the option card reference exists and if the clicked element is not within the option card
      if (
        optionCardRef.current &&
        !optionCardRef.current.contains(event.target as Node)
      ) {
        // Close the option card by setting the opened index to null
        setOpenedIndex(null);
      }
    };
    // Add event listener for mousedown event to detect clicks outside the option card
    document.addEventListener("mousedown", handleClickOutside);
    // Clean up function to remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Empty dependency array ensures that this effect runs only once after initial render

  // handle open three dots menu for the selected note
  const toggleOptionCard = (index: number) => {
    if (openedIndex === index) {
      setOpenedIndex(null);
    } else {
      setOpenedIndex(index);
    }
  };

  // Open the Add new Note Popup
  const handleAddNewNote = () => {
    setShowNotePopup(true);
  };

  // Open the Delete Note Popup
  const handleDeletePop = (id: number) => {
    setDeleteNoteId(id);
    setShowDeletePopup(true);
    setOpenedIndex(null);
  };

  // Delete Note Function
  const handleDeleteForm = async (id: number) => {
    await axios.delete(
      `https://8hpec8qhhg.execute-api.eu-west-1.amazonaws.com/dev/note/${tenantName}/${id}`
    );
    setShowDeletePopup(false);
    fecthNotesData();
  };

  //Create Note
  const handleSubmit = async () => {
    if (
      !newNoteTitle ||
      !newNoteDescription ||
      newNoteDescription === "<p><br></p>"
    ) {
      setAreInputsValid(false);
      setShowNotePopup(true);
      setAfterSubmit(true);
      setError("quiz.These fields are mandatory");
      return;
    }
    setAfterSubmit(false);
    const newNote = {
      title: newNoteTitle,
      content: newNoteDescription,
      subId: subIdSplitted,
      provider: provider,
    };
    try {
      setLoading(true);
      await axios.post(
        `https://8hpec8qhhg.execute-api.eu-west-1.amazonaws.com/dev/note/create/${tenantName}`,
        { data: newNote }
      );
      setLoading(false);
      setShowNotePopup(false);
      fecthNotesData();
      setNewNoteTitle("");
      setNewNoteDescription("");
      setError("");
      setAreInputsValid(true);
    } catch (e) {
      console.log(e);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentNotes = notes.slice(startIndex, endIndex);

  // Check if the 'notes' array is empty
  const isNotesEmpty = currentNotes.length === 0;
  const isAllNotesEmpty = notes.length === 0;

  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };
  if (dataLoading) {
    return (
      <Empty
        backgroundColor={"transparent"}
        text={`${t("general.loading")}...`}
      />
    );
  }

  return (
    <AllOnecol>
      <OptionRows>
        <Typography text={t("notes.notes")} fontSize="1.75rem" />
        {/* Add New Note Button Goes Here */}
        <ButtonIconAndText
          fontSize="12px"
          text={t("notes.Add New")}
          iconPath={AddSqIcon}
          onClick={handleAddNewNote}
          color={themeTextColor}
        />
      </OptionRows>

      {isNotesEmpty ? ( // If the notes are empty
        <Empty
          backgroundColor={"transparent"}
          text={t(
            "notes.You haven't created any notes yet. Start by adding a new note!"
          )}
        />
      ) : (
        <NoteMainRow>
          {/* Mapping the Notes Here */}
          {currentNotes.map((val: Note, index: number) => (
            <NoteCardDiv
              key={val.id}
              bgcolor={backgroundColor}
              boxshadow={boxShadow}
            >
              <TitleAndMenueRow>
                <Typography text={val.title} fontSize="1rem" />
                {/* Open the menu for the selected note */}
                <NoteAddButtonClick onClick={() => toggleOptionCard(index)}>
                  <SVGMenue />
                </NoteAddButtonClick>
              </TitleAndMenueRow>
              {/* Navigate to a selected Note */}
              <Link to={`/Notes/${val.id}`}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      decodeHtml(
                        val.content.length > 370
                          ? val.content.slice(0, 370) + "..."
                          : val.content
                      )
                    ),
                  }}
                  style={{
                    display: "flex",
                    fontSize: "1rem",
                    minHeight: "7rem",
                  }}
                />
              </Link>
              {openedIndex === index && ( // Navigate to edit the selected Note
                <OptionCard
                  ref={optionCardRef}
                // color={bg_selectColor}
                // bgColor={backgroundColor}
                >
                  <SelectedOptions
                    FirstLabelClick={() => navigate(`/Notes/Edit/${val.id}`)}
                    LastLabelClick={() => handleDeletePop(val.id)}
                  />
                </OptionCard>
              )}
            </NoteCardDiv>
          ))}
        </NoteMainRow>
      )}

      {/* Create Note Popup */}
      {showNotePopup && (
        <PopUp
          show={showNotePopup}
          content={
            // The content of the popup is the form for the new note creation
            <CreateNote
              handleTitleChange={(title) => setNewNoteTitle(title)}
              handleDescriptionChange={(description) =>
                setNewNoteDescription(description)
              }
              areInputsValid={areInputsValid}
              error={error}
            // handleSubmit={handleSubmit}
            />
          }
          setShow={setShowNotePopup}
          title={t("notes.Add Note")}
          icon={<SVGADD />}
          showline={false}
          handleSubmit={handleSubmit}
          buttonName={t("notes.Send")}
          showButtonCancel
        // loading={loading}
        // Btn1Name="Save"
        // Btn2Name="Save"
        />
      )}

      {/* Delete Note Popup */}
      {showDeletePopup && (
        // <PopUpDelete
        //   show={showDeletePopup}
        //   Subject={t('notes.Are you sure you want to delete Note Title from your notes?')}
        //   setShow={setShowDeletePopup}
        //   title={t('notes.Delete Note')}
        //   icon={<SvgDeleteIcon />}
        //   showline={false}
        //   onDelete={() => {
        //     if (deleteNoteId !== null) {
        //       handleDeleteForm(deleteNoteId);
        //     }
        //   }}
        // />
        <PopupDelete
          show={showDeletePopup}
          handleSubmit={() => {
            if (deleteNoteId !== null) {
              handleDeleteForm(deleteNoteId);
            }
          }}
          setShow={setShowDeletePopup}
          title={t("notes.Delete Note")}
        />
      )}

      {notes.length > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          total={notes.length}
          limit={itemsPerPage}
          onPageChange={(page: number) => setCurrentPage(page)}
          justifyContent="center"
          Padding="0 2rem 0 0"
        />
      )}
    </AllOnecol>
  );
};
