import { Module } from "../../CourseTimeline/utils/CourseResourcesInterface";

export const filterContentsByCategory = (data: Module[], category: string) => {
    let filteredData: Module[] = [];

    data.forEach((module) => {
        const filteredModule = { ...module };
        filteredModule.topicsArray = [];

        module.topicsArray.forEach((topic) => {
            const filteredTopic = { ...topic };
            filteredTopic.contents = topic.contents?.filter(
                (content) => content.category === category
            );

            if (filteredTopic.contents && filteredTopic.contents.length > 0) {
                filteredModule.topicsArray.push(filteredTopic);
            }
        });

        if (filteredModule.topicsArray.length > 0) {
            filteredData.push(filteredModule);
        }
    });
    return filteredData;
};