import styled from "styled-components";

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SwitchInput = styled.input`
  display: none;
`;

export const SwitchLabel = styled.label<{togglegradientcolor?: string}>`
  position: relative;
  display: inline-block;
  width: 3.563rem;
  height: 1.25rem;
  background: ${(props) => props.togglegradientcolor};
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
`;

export const Slider = styled.span<{ ischecked: boolean, theme?: string}>`
  position: absolute;
  top: 0;
  &:dir(ltr) {
  left: ${({ ischecked }) => (ischecked ? "2px" : "0px")};
  transform: ${({ ischecked }) => (ischecked ? "translateX(37px)" : "translateX(0)")};
  }
  &:dir(rtl) {
  right: ${({ ischecked }) => (ischecked ? "2px" : "0px")};
  transform: ${({ ischecked }) => (ischecked ? "translateX(-37px)" : "translateX(0)")};
  }
  width: 1.25rem;
  height: 1.25rem;
  background: ${({ ischecked, theme }) => (ischecked ? 'var(--green-gradient, linear-gradient(50deg, #5DD3B3 0%, #2F6A5A))' : theme === "dark" ? 'rgba(255, 255, 255, 0.5)' : "#AAB1B9" )};
  border-radius: 50%;
  transition: transform 0.3s;
`;