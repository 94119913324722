import React, { useState, FC, useRef, useEffect } from "react";
import axios from "axios";
import {
  MainHolder,
  Title,
  DragNDropContainer,
  DivArrowsWrapper,
  DivideLine,
  IconWrapper,
  Tooltip,
  AddModuleWrapper,
  ModulesContainer,
  ModulesContainerDroppable,
  AddNewModuleHolder,
  LoaderContainer,
  BackArrow,
  DragNDropContainerOverlay,
} from "./CourseCreator.style";
import { Link } from "react-router-dom";
import Loader from "../../../../components/loader/Loader";
import LoaderText from "../../../../components/loader/loaderText/LoaderText";
import { useParams } from "react-router-dom";
import { Typography, MediumTypography } from "../../../../elements/fonts/Fonts";
import CourseStatistics from "../../../../components/courseStatistics/CourseStatistics";
import { SvgAddModuleIcon, SvgCopilot, SvgUpload } from "../../../../elements/Icons";
import IconWithText from "../../../../elements/iconWithText/main/IconWithText";
import Module from "../../../../components/module/main/Module";
import { ModuleProps } from "../courseCreatorInterface";
import ItemDraggable from "../../../../components/courseToolbar/ItemDraggable";
import {
  SvgSimpleArrowRight,
  SvgSimpleArrowLeft,
} from "../../../../elements/Icons";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useStateContext } from "../../../../contexts/ContextProvider";
import { SvgBackArrow, SvgViewEye } from "../../../../elements/Icons";

import { useMyContext } from "../../../../contexts/CourseCreatorContext";
import { useQuizMyContext } from "../../../../contexts/CourseCreatorQuizContext";
import { useRecoilValue } from "recoil";
import {
  courseCreatorColorSelector,
  ColorSelector,
} from "../../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { FlexDiv } from "../../../../globalStyles";
import { FlexedDiv } from "./CourseCreator.style";
import PopUp from "../../../../elements/Popup/PopUp";
import ImportClassPopup from "../../../../components/courseCreatorPopups/ImportClassPopup";
import ImportClassSecondPhase from "../../../../components/courseCreatorPopups/ImportClassSecondPhase";
import { toast } from "react-toastify";
import { auth0ProviderSplitter, auth0SubIdSplitter } from "../../../../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import { toArabicDigits } from "../../../../utils/formatDayArabic";

/**
 * CourseCreator component represents the main interface for creating and managing courses.
 * It allows users to add, edit, and delete modules, as well as view course statistics.
 *
 */

const CourseCreator: FC = () => {
  // Recoil state and selectors
  const {
    tooltipBgColor,
    tooltipTextColor,
    boxShadow,
    addModuleIconColor,
    scrollingHbgColor,
    moduleDragNDropBgColor,
    borderModulesContainerColor,
    lineIconColor,
    theme,
  } = useRecoilValue(courseCreatorColorSelector);
  const { backgroundColor, iconCloseColor, overlayBgColor } =
    useRecoilValue(ColorSelector);

  // State and context hooks
  const {
    courseName,
    setIsContentDrop,
    setShowEditContentPositionLoader,
    setShowEditTopicPositionLoader,
    setSourceIdContent,
    setSourceIdTopic,
    setModuleID,
    contentType,
  } = useStateContext();
  const { t } = useTranslation();

  // const { setGlobalQuizShowPopup, setIsPopupAllDroped } = useQuizMyContext();

  // const { setGlobalShowPopup } = useMyContext();

  // get the classId of the course from the URL
  const classSourceId = useParams();
  const setClassSourceId = classSourceId.id;

  // Base URL for API requests
  const BaseURL =
    "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/class/module";

  const [modules, setModules] = useState<ModuleProps[]>([]);
  const [classTitle, setClassTitle] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSetLoading, setIsSetLoading] = useState<boolean>(false);
  const [isImportPopup,SetisImportPopup]=useState<boolean>(false)
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const BaseURLClassName =
    "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";

  // Fetch class title by class source ID
  const getClassTitle = async (classSourceId: string) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `${BaseURLClassName}/class/title/${tenantName}/${classSourceId}`
      );
      // console.log("res ClassName: ", res.data.data[0][0].title);
      setClassTitle(res.data.data[0][0].title);
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch modules with topics by class source ID
  const getModulesWithTopics = async (classSourceId: string) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `${BaseURL}/all/${classSourceId}/${tenantName}`
      );
      setIsLoading(false);
      setIsSetLoading(false);
      setIsContentDrop(true);
      setModules(res.data.data);
      const toReturn: any[] = res.data.data;
      return toReturn;
    } catch (error) {
      console.log(error);
    }
  };

  // Function to get the modules with their topics in the children component of the courseCreator  (Module, Topic)
  const activeGetModulesWithTopics = async () => {
    if (setClassSourceId) {
      const cc = await getModulesWithTopics(setClassSourceId);
      return cc;
    }
  };

  // Fetch modules with topics on component mount
  useEffect(() => {
    if (setClassSourceId) {
      getModulesWithTopics(setClassSourceId);
    }
  }, []);

  // useEffect(() => {
  //   // setGlobalQuizShowPopup(false);
  //   setIsPopupAllDroped(false);
  //   // setGlobalShowPopup(false);
  // }, []);

  // Fetch class title of the course
  useEffect(() => {
    if (setClassSourceId) {
      getClassTitle(setClassSourceId);
    }
  }, []);

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  // State for module and topic IDs and indices
  const [isModuleId, setIsModuleId] = useState<number>();
  const [isModuleIndex, setIsModuleIndex] = useState<number>();

  // State for handling the delete module button
  const [isModuleDeleteClicked, setIsModuleDeleteClicked] =
    useState<boolean>(false);

  // State for new module title
  const [newModuleTitle, setNewModuleTitle] = useState<string>(
    t("course view.Module")
  );

  // State for handling the button add New Module
  const [isAddNewModuleClicked, setIsAddNewModuleClicked] =
    useState<boolean>(false);

  // Function to generate a random module ID
  const generateId = () => {
    // generate a random Id number between 1 and 10000
    return Math.floor(Math.random() * 10000) + 1;
  };

  // Function to create a new module via API request
  const createModule = async (path: string, body: any) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.post(`${BaseURL}/${path}/${tenantName}`, {
        tenantName: tenantName,
        data: { ...body, classSourceId: setClassSourceId },
      });
      return response.data;
    } catch (error) {
      console.error("Error in POST request:", error);
    }
  };

  // Function to add a new module to the courseCreator
  const addNewModule = async () => {
    setIsSetLoading(true);
    const moduleId = generateId();
    const newIndex = modules[modules.length - 1]?.moduleIndex
      ? modules[modules.length - 1].moduleIndex + 1
      : 1;
    const data = {
      moduleIndex: newIndex,
      moduleId: moduleId,
      moduleTitle: `${t("course view.Module")} ${toArabicDigits((newIndex).toString())}`,
      topicsArray: [],
    };
    await createModule("create", data);
    if (setClassSourceId) {
      await getModulesWithTopics(setClassSourceId);
    }
  };

  // Function to edit the title of a module via API request
  const editModuleTitle = async (path: string, body: any) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.patch(`${BaseURL}/${path}/${tenantName}`, {
        data: body,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PATCH request:", error);
    }
  };

  // Function to edit a module title
  const editModule = async (moduleId: number, newTitle: string) => {
    if (moduleId !== undefined) {
      let newModules = [...modules];

      const data = {
        moduleTitle: newTitle,
        moduleId: moduleId,
      };

      await editModuleTitle("title", data);

      const editedModule =
        newModules &&
        newModules.map((module) =>
          module.moduleId === moduleId
            ? (module.moduleTitle = newTitle)
            : (module.moduleTitle = module.moduleTitle)
        );

      setModules(newModules);
    }
  };

  // Function to delete a module via API request
  const deleteModule = async (path: string, body: any) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.put(`${BaseURL}/${path}/${tenantName}`, {
        data: body,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
    }
  };

  // Function to remove a module from the courseCreator
  const removeModule = async (moduleId: number, moduleIndex: number) => {
    if (modules[moduleIndex - 1].moduleIndex !== modules.length) {
      let newModules = [...modules];
      for (
        let i = modules[moduleIndex - 1].moduleIndex;
        i < modules.length;
        i++
      ) {
        newModules[i].moduleIndex -= 1;
      }
    }

    if (moduleId !== undefined) {
      const updatedModules = modules.filter(
        (module) => module.moduleId !== moduleId
      );

      const data = {
        idToDelete: moduleId,
        payload: updatedModules,
      };

      await deleteModule("delete", data);
      setModules(updatedModules);
    }
    setIsModuleDeleteClicked(true);
  };

  // Function to set module ID in the courseCreator
  const setIsModuleIdInCourseCreator = (moduleId: number) => {
    setIsModuleId(moduleId);
  };

  // Function to set module index in the courseCreator
  const setIsModuleIndexInCourseCreator = (moduleIndex: number) => {
    setIsModuleIndex(moduleIndex);
  };

  // Function to set module title in the courseCreator
  const setModuleTitleInCourseCreator = (moduleTitle: string) => {
    if (moduleTitle.length === 0) {
      setNewModuleTitle(t("course view.Module"));
    } else {
      setNewModuleTitle(moduleTitle);
    }
  };

  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to scroll left in the modules container
  const scrollLeft = () => {
    if (tableContainerRef.current) {
      const newScrollPosition =
        tableContainerRef.current.scrollLeft - columnWidth;
      tableContainerRef.current.scrollBy({
        left: -columnWidth,
        behavior: "smooth",
      });
      setCurrentIndex(Math.max(0, newScrollPosition / columnWidth));
    }
  };

  // Function to scroll right in the modules container
  const scrollRight = () => {
    if (tableContainerRef.current) {
      const newScrollPosition =
        tableContainerRef.current.scrollLeft + columnWidth;
      tableContainerRef.current.scrollBy({
        left: columnWidth,
        behavior: "smooth",
      });
      const maxIndex =
        Math.floor(tableContainerRef.current.scrollWidth / columnWidth) - 1;
      setCurrentIndex(Math.min(maxIndex, newScrollPosition / columnWidth));
    }
  };

  // Width of the Module Container in pixel
  const columnWidth = 378;

  useEffect(() => {
    if (tableContainerRef.current) {
      const newScrollPosition = currentIndex * columnWidth;
      tableContainerRef.current.scrollLeft = newScrollPosition;
      setScrollPosition(newScrollPosition);

      // Check if you can scroll left
      if (newScrollPosition <= 0) {
        setCanScrollLeft(false);
      } else {
        setCanScrollLeft(true);
      }

      // Check if you can scroll right
      if (
        tableContainerRef.current.scrollWidth - newScrollPosition <=
        tableContainerRef.current.clientWidth
      ) {
        setCanScrollRight(false);
      } else {
        setCanScrollRight(true);
      }
    }
  }, [currentIndex]);

  // Function to reactivate the state of modules with all her new item updates.
  const onAddOrDelete = () => {
    setModules((modules) => {
      return [...modules];
    });
  };

  // State variables to track activity counts
  const [quiz, setQuiz] = useState<number>(0);
  const [assignment, setAssignment] = useState<number>(0);
  const [onlineSession, setOnlineSession] = useState<number>(0);
  const [survey, setSurvey] = useState<number>(0);
  const [forum, setForum] = useState<number>(0);

  // Effect to update activity counts when modules change
  useEffect(() => {
    if (modules.length !== 0) {
      // Reset counts
      let quizTemp = 0;
      let assignmentTemp = 0;
      let onlineSessionTemp = 0;
      let surveyTemp = 0;
      let forumTemp = 0;

      // Calculate counts based on module contents
      for (let i = 0; i < modules.length; i++) {
        for (let j = 0; j < modules[i]?.topicsArray?.length; j++) {
          for (
            let k = 0;
            k < modules[i].topicsArray[j]?.contents?.length;
            k++
          ) {
            const content = modules[i].topicsArray[j].contents[k]?.type;

            // Use a switch statement to handle different activity types
            // Increment counts based on content type
            switch (content) {
              case "Quiz":
                quizTemp += 1;
                break;
              case "Assignment":
                assignmentTemp += 1;
                break;
              case "Online_Session":
                onlineSessionTemp += 1;
                break;
              case "Survey":
                surveyTemp += 1;
                break;
              case "Forum":
                forumTemp += 1;
                break;
              default:
                break;
            }
          }
        }
      }

      setQuiz(quizTemp);
      setAssignment(assignmentTemp);
      setOnlineSession(onlineSessionTemp);
      setSurvey(surveyTemp);
      setForum(forumTemp);
    } else {
      setQuiz(0);
      setAssignment(0);
      setOnlineSession(0);
      setSurvey(0);
      setForum(0);
    }
  }, [modules]);

  // Function to edit the position of a module via API request
  const editModulePosition = async (path: string, body: any) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.patch(`${BaseURL}/${path}/${tenantName}`, {
        data: body,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PATCH request:", error);
    } finally {
      await (activeGetModulesWithTopics && activeGetModulesWithTopics());
    }
  };

  // Function to edit the position of a topic via API request
  const editTopicPosition = async (path: string, body: any) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.patch(`${BaseURL}/${path}/${tenantName}`, {
        data: body,
      });
      setShowEditTopicPositionLoader(true);
      return response.data;
    } catch (error) {
      console.error("Error in PATCH request:", error);
    } finally {
      await (activeGetModulesWithTopics && activeGetModulesWithTopics());
      setShowEditTopicPositionLoader(false);
    }
  };

  // Function to edit the position of a content via API request
  const editContentPosition = async (path: string, body: any) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const response = await axios.patch(`${BaseURL}/${path}/${tenantName}`, {
        data: body,
      });
      setShowEditContentPositionLoader(true);
      return response.data;
    } catch (error) {
      console.error("Error in PATCH request:", error);
    } finally {
      await (activeGetModulesWithTopics && activeGetModulesWithTopics());
      await setShowEditContentPositionLoader(false);
    }
  };

  /**
   * Handles the drag end event when an item is dropped.
   *
   * @param results The results object containing information about the drag event.
   * - source: The source of the drag event.
   * - destination: The destination where the item is dropped.
   * - type: The type of container where the drag event occurred (modulesContainer, topicsContainer, contentsContainer).
   */
  const handleDragEnd = async (results: any) => {

    const { source, destination, type } = results;

    // Reorder modules
    if (type === "modulesContainer") {
      if (!destination) return;
      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      )
        return;

      const reorderedModules = [...modules];
      const sourceIndex = source.index;
      const destinationIndex = destination.index;
      const [removedModule] = reorderedModules.splice(sourceIndex, 1);
      reorderedModules.splice(destinationIndex, 0, removedModule);

      reorderedModules.forEach((moduleMapping, indexMapping) => {
        moduleMapping.moduleIndex = indexMapping + 1;
      });

      await editModulePosition("position", reorderedModules);
      return setModules(reorderedModules);
    }

    // Reorder topics
    else if (type === "topicsContainer") {
      if (!destination) return;
      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      )
        return;

      const sourceModuleIndex = source.droppableId.split("-")[1];
      const destinationModuleIndex = destination.droppableId.split("-")[1];

      const newSourceTopics = [...modules[sourceModuleIndex].topicsArray];
      const newDestinationTopics =
        source.droppableId !== destination.droppableId
          ? [...modules[destinationModuleIndex].topicsArray]
          : newSourceTopics;

      const [deletedTopic] = newSourceTopics.splice(source.index, 1);
      setSourceIdTopic(deletedTopic.topicId);
      newDestinationTopics.splice(destination.index, 0, deletedTopic);

      const newModules = [...modules];

      newModules[sourceModuleIndex] = {
        ...modules[sourceModuleIndex],
        topicsArray: newSourceTopics,
      };

      newModules[destinationModuleIndex] = {
        ...modules[destinationModuleIndex],
        topicsArray: newDestinationTopics,
      };

      newModules.forEach((moduleMapping) => {
        moduleMapping.topicsArray.forEach((topicMapping, indexTopicMapping) => {
          topicMapping.topicIndex = indexTopicMapping + 1;
        });
      });

      await editTopicPosition("topic/position", newModules);
      await setModules(newModules);
      if (setClassSourceId) {
        await getModulesWithTopics(setClassSourceId);
      }
    }
    // Reorder contents
    else if (type === "contentsContainer") {
      if (!destination) return;
      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      )
        return;

      const sourceModuleIndex = source.droppableId.split("-")[1];
      const destinationModuleIndex = destination.droppableId.split("-")[1];

      const sourceTopicIndex = source.droppableId.split("-")[2];
      const destinationTopicIndex = destination.droppableId.split("-")[2];

      const newSourceContents = [
        ...modules[sourceModuleIndex].topicsArray[sourceTopicIndex].contents,
      ];

      const newDestinationContents =
        source.droppableId !== destination.droppableId
          ? [
              ...modules[destinationModuleIndex].topicsArray[
                destinationTopicIndex
              ].contents,
            ]
          : newSourceContents;

      const [deletedContent] = newSourceContents.splice(source.index, 1);
      setSourceIdContent(deletedContent.contentId);
      setModuleID(Number(sourceModuleIndex) + 1);
      newDestinationContents.splice(destination.index, 0, deletedContent);

      const newModules = [...modules];

      /* Move content to other module or same module and same topic*/
      if (
        sourceModuleIndex !== destinationModuleIndex ||
        (sourceModuleIndex === destinationModuleIndex &&
          sourceTopicIndex === destinationTopicIndex)
      ) {
        newModules[sourceModuleIndex] = {
          ...modules[sourceModuleIndex],
          topicsArray: [
            ...modules[sourceModuleIndex].topicsArray.slice(
              0,
              sourceTopicIndex
            ),
            {
              ...newModules[sourceModuleIndex].topicsArray[sourceTopicIndex],
              contents: newSourceContents,
            },
            ...newModules[sourceModuleIndex].topicsArray.slice(
              sourceTopicIndex + 1
            ),
          ],
        };

        newModules[destinationModuleIndex] = {
          ...modules[destinationModuleIndex],
          topicsArray: [
            ...modules[destinationModuleIndex].topicsArray.slice(
              0,
              destinationTopicIndex
            ),
            {
              ...newModules[destinationModuleIndex].topicsArray[
                destinationTopicIndex
              ],
              contents: newDestinationContents,
            },
            ...newModules[destinationModuleIndex].topicsArray.slice(
              destinationTopicIndex + 1
            ),
          ],
        };
      } else {
        /* Move content in the same module but other topic */

        const newModules = [...modules];
        const sourceContents =
          newModules[sourceModuleIndex].topicsArray[sourceTopicIndex].contents;
        const [movedContent] = sourceContents.splice(source.index, 1);

        // setSourceIdContent()
        // Remove content from source topic
        newModules[sourceModuleIndex].topicsArray[sourceTopicIndex].contents =
          sourceContents;

        // Add content to destination topic
        newModules[destinationModuleIndex].topicsArray[
          destinationTopicIndex
        ].contents.splice(destination.index, 0, movedContent);
      }

      newModules.forEach((moduleMapping) => {
        moduleMapping.topicsArray.forEach((topicMapping) => {
          topicMapping.contents.forEach(
            (contentMapping, indexContentMapping) => {
              contentMapping.contentIndex = indexContentMapping + 1;
            }
          );
        });
      });
      await editContentPosition("topic/content/position", newModules);
      setModules(newModules);
    }
  };

  // Function to reorder the index of content based on the react-beautiful-dnd library
  const reorderedContentIndex = () => {
    modules.forEach((moduleMapping) => {
      moduleMapping.topicsArray.forEach((topicMapping) => {
        topicMapping.contents.forEach((contentMapping, indexContentMapping) => {
          contentMapping.contentIndex = indexContentMapping + 1;
        });
      });
    });
  };
  const direction = document.dir;

  const [selectedClassId, setSelectedClassId] = useState<any>(null);

  const handleNextPhase = (classId: string) => {
    setSelectedClassId(classId);
  };
  const [selectedContentIds, setSelectedContentIds] = useState<number[]>([]);

useEffect(()=>{
  setSelectedContentIds([])
},[isImportPopup])

const [Loading,SetLoading]=useState(false)
  const handlePopUpSubmit = async () => {
    const tenantName = localStorage.getItem("tenant");
    const url = `https://nyaqbcug2f.execute-api.eu-west-1.amazonaws.com/dev/class/inheritance/content/${tenantName}`;

    const data = {
      data: {
        classSourceId: setClassSourceId,
        contents: selectedContentIds,
        subId: subIdSplitted,
        provider:provider
      }
    };
console.log(data,"datatosend")
    try {
      SetLoading(true)
      const response = await axios.post(url, data);
      console.log('Response:', response.data);
      toast.success("Classes merged sucessfully")
      setSelectedContentIds([])
    } catch (error) {
      console.error('Error submitting data:', error);
      setSelectedContentIds([])
      toast.error("Failed to merge classes")
    }
    finally{
      await activeGetModulesWithTopics()
      SetisImportPopup(false)
      setSelectedClassId("")
      SetLoading(false)
    }
  };
  return (

    <>
    
    <MainHolder contenttype={contentType} overlaybgcolor={overlayBgColor}>
      {/* Course Header Section */}
      <Title>
        <Link to={"/dashboard"}>
          <BackArrow>
            <SvgBackArrow />
          </BackArrow>
        </Link>
        <Typography
          text={t("course creator.Class Creator")}
          fontSize="1.75rem"
          whiteSpace="nowrap"
        />
        <DivideLine lineiconcolor={lineIconColor}></DivideLine>
        <MediumTypography
          text={courseName !== "" ? courseName : classTitle}
          color="#5DD3B3"
        />
        {/* View Timeline Icon */}
        <IconWrapper>
          <Link to={`/courseView/content/${setClassSourceId}`}>
            <SvgViewEye />
          </Link>
          <Tooltip
            bgcolor={tooltipBgColor}
            textcolor={tooltipTextColor}
            boxshadow={boxShadow}
          >
            <MediumTypography
              text={t("course creator.View Timeline")}
              fontSize="0.625rem"
              whiteSpace="nowrap"
              color={tooltipTextColor}
            />
          </Tooltip>
        </IconWrapper>
      </Title>
      {/* Course Statistics Section */}
      <CourseStatistics
        mainTitle={t("course creator.TOTAL")}
        mainTitleSecondWord={t("course creator.ACTIVITIES")}
        quiz={quiz}
        assignment={assignment}
        onlineSession={onlineSession}
        survey={survey}
        forum={forum}
      />

      {/* Drag and Drop Container */}
      <DragNDropContainer
        boxshadow={boxShadow}
        moduledragndropbgcolor={moduleDragNDropBgColor}
        bordermodulescontainercolor={borderModulesContainerColor}
        contenttype={contentType}
        overlaybgcolor={overlayBgColor}
      >
        {/* Loader while loading modules */}
        {isLoading ? (
          <LoaderContainer>
            <Loader text={`${t("course view.loading your modules")}...`} />
          </LoaderContainer>
        ) : (
          <>
            {/* Add New Module Button */}

            <AddModuleWrapper>
            
             <AddNewModuleHolder
             isloading={isSetLoading.toString()}
             isclicked={isAddNewModuleClicked.toString()}
             disabled={isSetLoading}
             color={addModuleIconColor}
             
             >
              <FlexDiv onClick={()=>SetisImportPopup(true)} pointer="true">

              <SvgUpload/>
              <Typography text={t("course creator.Import Class")}  fontSize="0.625rem"/>
              </FlexDiv>
             </AddNewModuleHolder>
              {/* <SvgCopilot /> */}
              {/* <Link to={"/CompetencyPopup"}>
                <IconWithText
                  icon={SvgAddModuleIcon}
                  textElement={
                    <Typography text={t("course view.Add Competency")} fontSize="0.625rem" />
                  }
                  margin="0.2rem 0 0 0"
                  color={addModuleIconColor}
                />
              </Link> */}
              <AddNewModuleHolder
                isloading={isSetLoading.toString()}
                isclicked={isAddNewModuleClicked.toString()}
                disabled={isSetLoading}
                color={addModuleIconColor}
                onClick={() => {
                  setIsAddNewModuleClicked(true);
                  addNewModule();
                }}
              >
                <IconWithText
                  icon={SvgAddModuleIcon}
                  textElement={
                    <Typography
                      text={t("course creator.Add Module")}
                      fontSize="0.625rem"
                    />
                  }
                  margin="0.2rem 0 0 0"
                  color={addModuleIconColor}
                />
              </AddNewModuleHolder>


            </AddModuleWrapper>
       
          </>
        )}
        {/* Drag and Drop Content for topics (sideBar) */}
        <ItemDraggable />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable
            droppableId="modulesContainerId"
            type="modulesContainer"
            direction="horizontal"
          >
            {(provided, snapshot) => (
              <ModulesContainer
                ref={tableContainerRef}
                onScroll={(e) => setScrollPosition(e.currentTarget.scrollLeft)}
              >
                <ModulesContainerDroppable
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {/* Render Modules */}
                  {modules &&
                    modules.length > 0 &&
                    modules
                      .sort((a, b) => a.moduleIndex - b.moduleIndex)
                      .map((module, index) => (
                        <React.Fragment key={module.moduleId}>
                          <Module
                            key={index}
                            moduleIndex={index}
                            moduleId={module?.moduleId}
                            moduleTitle={module.moduleTitle}
                            topicsArray={module.topicsArray}
                            module={module}
                            reorderedContentIndex={reorderedContentIndex}
                            removeModule={() => {
                              if (
                                isModuleId !== undefined &&
                                isModuleIndex !== undefined
                              ) {
                                removeModule(isModuleId, isModuleIndex);
                              }
                            }}
                            editModule={() => {
                              if (isModuleId !== undefined) {
                                editModule(isModuleId, newModuleTitle);
                              }
                            }}
                            isModuleDeleteClicked={isModuleDeleteClicked}
                            setIsModuleIdInCourseCreator={
                              setIsModuleIdInCourseCreator
                            }
                            setIsModuleIndexInCourseCreator={
                              setIsModuleIndexInCourseCreator
                            }
                            onAddOrDelete={onAddOrDelete}
                            setModuleTitleInCourseCreator={
                              setModuleTitleInCourseCreator
                            }
                            newModuleTitle={newModuleTitle}
                            activeGetModulesWithTopics={
                              activeGetModulesWithTopics
                            }
                          />
                        </React.Fragment>
                      ))}
                  {isSetLoading && <LoaderText />}
                  {provided.placeholder}
                </ModulesContainerDroppable>
              </ModulesContainer>
            )}
          </Droppable>
        </DragDropContext>
        {/* Arrows for Horizontal Scrolling */}
        <DivArrowsWrapper bgcolor={scrollingHbgColor}>
          <SvgSimpleArrowLeft
            onMouseEnter={() => {
              scrollLeft();
            }}
            style={{ fillOpacity: `${canScrollLeft ? 1 : 0.5}` }}
          />
          <SvgSimpleArrowRight
            onMouseEnter={() => {
              scrollRight();
            }}
            style={{ fillOpacity: `${canScrollRight ? 1 : 0.5}` }}
          />
        </DivArrowsWrapper>
        {/* <DragNDropContainerOverlay
          boxShadow={boxShadow}
          moduleDragNDropBgColor={moduleDragNDropBgColor}
          borderModulesContainerColor={borderModulesContainerColor}
          contentType={contentType}
          overlayBgColor={overlayBgColor}
          theme={theme}
        ></DragNDropContainerOverlay> */}
      </DragNDropContainer>
    
    </MainHolder>
{
  isImportPopup &&
    <PopUp
    ContentWidth="35rem"
    ContentHeight="25rem"
           icon={<SvgUpload />}
           content={ !selectedClassId ? <ImportClassPopup setSelectedClassId={setSelectedClassId} /> : <ImportClassSecondPhase selectedClassId={selectedClassId} setSelectedClassId={setSelectedClassId} setSelectedContentIds={setSelectedContentIds} selectedContentIds={selectedContentIds} Loading={Loading}/> }
           showline={false}
           show={isImportPopup}
           setShow={SetisImportPopup}
           title={t("course creator.Import from Class")}
           showButtonCancel={false}
           handleSubmit={handlePopUpSubmit}
           marginTop="1rem"
           paddingTop="0"
           showButton={Loading?false:(selectedClassId?true:false)}
           buttonName={t("group.Import")}
           handleClosePopup={()=>setSelectedClassId(null)}
           justifyContent="flex-end"
      />
}
    </>
  );
};

export default CourseCreator;
