import {
  GradesProps,
    SegmentTitleDataInterface,
    StudentData2,
    // TableHeadDataInterface,
    TableTrInterface,
  } from "./gradesItemInterface";
  
  export const SegmentTitleData: SegmentTitleDataInterface[] =[ 
  
 
  {
    segmentTitle: "Students",
  },
  {
    segmentTitle: "Activities",
  },
  {
    segmentTitle: "Grades",
  },

  

]
  
//   export const TableHeadData: TableHeadDataInterface[] = 
  
  
  
export const TableTrData: TableTrInterface[] = [

    {
      STUDENT: [
        {
          Name: "Lana Mohammad",
          color: "#5DD3B3",
          img: require("../../assets/NourInstructor.jpg"),
        },
        {
          Name: "Tariq Al Rajihi",
          color: "#5DD3B3",
          img: require("../../assets/AliAhmad.png"), 
        },
      
        {
          Name: "Ghassan ",
          color: "#5DD3B3",
          img: require("../../assets/KhaledInstructor.jpg"), 
        },
        {
          Name: "Jana",
          color: "#5DD3B3",
          img: require("../../assets/AyaInstructor.jpg"), 
        },
        {
          Name: "Tamer",
          color: "#5DD3B3",
          img: require("../../assets/KhaledInstructor.jpg"), 
        },
      
        {
          Name: "Gina",
          color: "#5DD3B3",
          img: require("../../assets/NourInstructor.jpg"), 
        },
        {
          Name: "Lamis",
          color: "#5DD3B3",
          img: require("../../assets/NourInstructor.jpg"), 
        },
        {
          Name: "Tala",
          color: "#5DD3B3",
          img: require("../../assets/AyaInstructor.jpg"), 
        },
      
        {
          Name: "Gaby",
          color: "#5DD3B3",
          img: require("../../assets/AliAhmad.png"), 
        },
        {
          Name: "Loris",
          color: "#5DD3B3",
          img: require("../../assets/KhaledInstructor.jpg"), 
        },
        {
          Name: "Ahmad",
          color: "#5DD3B3",
          img: require("../../assets/AliAhmad.png"), 
        },
      
        {
          Name: "Kholoud",
          color: "#5DD3B3",
          img: require("../../assets/NourInstructor.jpg"), 
        },
        {
          Name: "Loaay",
          color: "#5DD3B3",
          img: require("../../assets/KhaledInstructor.jpg"), 
        },
        {
          Name: "Saiid",
          color: "#5DD3B3",
          img: require("../../assets/AliAhmad.png"), 
        },
      
        {
          Name: "Ali",
          color: "#5DD3B3",
          img: require("../../assets/KhaledInstructor.jpg"), 
        },
      
      ],
        ASSIGNMENT1: [
          {activity:"Assignment 1"},
          { studentname: "Lana", studentgrade: "70" },
          { studentname: "Tariq", studentgrade: "80" },
          { studentname: "Ghassan", studentgrade: "30" },
          { studentname: "Jana", studentgrade: "70" },
          { studentname: "Tamer", studentgrade: "80" },
          { studentname: "Gina", studentgrade: "30" },
          { studentname: "Lamis", studentgrade: "70" },
          { studentname: "Tala", studentgrade: "80" },
          { studentname: "Gaby", studentgrade: "30" },
          { studentname: "Loris", studentgrade: "70" },
          { studentname: "Ahmad", studentgrade: "80" },
          { studentname: "Kholoud", studentgrade: "30" },
          { studentname: "Loaay", studentgrade: "70" },
          { studentname: "Saiid", studentgrade: "80" },
          { studentname: "Ali", studentgrade: "30" },
        ],
        ASSIGNMENT2: [
          {activity:"Assignment 2"},

          { studentname: "Lana", studentgrade: "75" },
          { studentname: "Tariq", studentgrade: "65" },
          { studentname: "Ghassan", studentgrade: "95" },
          { studentname: "Jana", studentgrade: "70" },
          { studentname: "Tamer", studentgrade: "80" },
          { studentname: "Gina", studentgrade: "30" },
          { studentname: "Lamis", studentgrade: "70" },
          { studentname: "Tala", studentgrade: "80" },
          { studentname: "Gaby", studentgrade: "30" },
          { studentname: "Loris", studentgrade: "70" },
          { studentname: "Ahmad", studentgrade: "80" },
          { studentname: "Kholoud", studentgrade: "30" },
          { studentname: "Loaay", studentgrade: "70" },
          { studentname: "Saiid", studentgrade: "80" },
          { studentname: "Ali", studentgrade: "30" },
        ],
        ASSIGNMENT3: [
          {activity:"Assignment 3"},

          { studentname: "Lana", studentgrade: "52" },
          { studentname: "Tariq", studentgrade: "62" },
          { studentname: "Ghassan", studentgrade: "72" },
          { studentname: "Jana", studentgrade: "70" },
          { studentname: "Tamer", studentgrade: "80" },
          { studentname: "Gina", studentgrade: "30" },
          { studentname: "Lamis", studentgrade: "70" },
          { studentname: "Tala", studentgrade: "80" },
          { studentname: "Gaby", studentgrade: "30" },
          { studentname: "Loris", studentgrade: "70" },
          { studentname: "Ahmad", studentgrade: "80" },
          { studentname: "Kholoud", studentgrade: "30" },
          { studentname: "Loaay", studentgrade: "70" },
          { studentname: "Saiid", studentgrade: "80" },
          { studentname: "Ali", studentgrade: "30" },
        ],
        QUIZ1: [
          {activity:"Quiz 1"},

          { studentname: "Lana", studentgrade: "50" },
          { studentname: "Tariq", studentgrade: "15" },
          { studentname: "Ghassan", studentgrade: "25" },
          { studentname: "Jana", studentgrade: "70" },
          { studentname: "Tamer", studentgrade: "80" },
          { studentname: "Gina", studentgrade: "30" },
          { studentname: "Lamis", studentgrade: "70" },
          { studentname: "Tala", studentgrade: "80" },
          { studentname: "Gaby", studentgrade: "30" },
          { studentname: "Loris", studentgrade: "70" },
          { studentname: "Ahmad", studentgrade: "80" },
          { studentname: "Kholoud", studentgrade: "30" },
          { studentname: "Loaay", studentgrade: "70" },
          { studentname: "Saiid", studentgrade: "80" },
          { studentname: "Ali", studentgrade: "30" },
        ],
        QUIZ2: [
          {activity:"Quiz 2"},

          { studentname: "Lana", studentgrade: "8" },
          { studentname: "Tariq", studentgrade: "78" },
          { studentname: "Ghassan", studentgrade: "58" },
          { studentname: "Jana", studentgrade: "70" },
          { studentname: "Tamer", studentgrade: "80" },
          { studentname: "Gina", studentgrade: "30" },
          { studentname: "Lamis", studentgrade: "70" },
          { studentname: "Tala", studentgrade: "80" },
          { studentname: "Gaby", studentgrade: "30" },
          { studentname: "Loris", studentgrade: "70" },
          { studentname: "Ahmad", studentgrade: "80" },
          { studentname: "Kholoud", studentgrade: "30" },
          { studentname: "Loaay", studentgrade: "70" },
          { studentname: "Saiid", studentgrade: "80" },
          { studentname: "Ali", studentgrade: "30" },
        ],
        QUIZ3: [
          {activity:"Quiz 3"},

          { studentname: "Lana", studentgrade: "7" },
          { studentname: "Tariq", studentgrade: "17" },
          { studentname: "Ghassan", studentgrade: "90" },
          { studentname: "Jana", studentgrade: "70" },
          { studentname: "Tamer", studentgrade: "80" },
          { studentname: "Gina", studentgrade: "30" },
          { studentname: "Lamis", studentgrade: "70" },
          { studentname: "Tala", studentgrade: "80" },
          { studentname: "Gaby", studentgrade: "30" },
          { studentname: "Loris", studentgrade: "70" },
          { studentname: "Ahmad", studentgrade: "80" },
          { studentname: "Kholoud", studentgrade: "30" },
          { studentname: "Loaay", studentgrade: "70" },
          { studentname: "Saiid", studentgrade: "80" },
          { studentname: "Ali", studentgrade: "30" },
        ],
        FORUM1: [
          {activity:"Forum 1"}
,
          { studentname: "Lana", studentgrade: "43" },
          { studentname: "Tariq", studentgrade: "76" },
          { studentname: "Ghassan", studentgrade: "59" },
          { studentname: "Jana", studentgrade: "70" },
          { studentname: "Tamer", studentgrade: "80" },
          { studentname: "Gina", studentgrade: "30" },
          { studentname: "Lamis", studentgrade: "70" },
          { studentname: "Tala", studentgrade: "80" },
          { studentname: "Gaby", studentgrade: "30" },
          { studentname: "Loris", studentgrade: "70" },
          { studentname: "Ahmad", studentgrade: "80" },
          { studentname: "Kholoud", studentgrade: "30" },
          { studentname: "Loaay", studentgrade: "70" },
          { studentname: "Saiid", studentgrade: "80" },
          { studentname: "Ali", studentgrade: "30" },
        ],
        FORUM2: [
          {activity:"Forum 2"}
,
          { studentname: "Lana", studentgrade: "74" },
          { studentname: "Tariq", studentgrade: "67" },
          { studentname: "Ghassan", studentgrade: "90" },
          { studentname: "Jana", studentgrade: "70" },
          { studentname: "Tamer", studentgrade: "80" },
          { studentname: "Gina", studentgrade: "30" },
          { studentname: "Lamis", studentgrade: "70" },
          { studentname: "Tala", studentgrade: "80" },
          { studentname: "Gaby", studentgrade: "30" },
          { studentname: "Loris", studentgrade: "70" },
          { studentname: "Ahmad", studentgrade: "80" },
          { studentname: "Kholoud", studentgrade: "30" },
          { studentname: "Loaay", studentgrade: "70" },
          { studentname: "Saiid", studentgrade: "80" },
          { studentname: "Ali", studentgrade: "30" },
        ],
        FORUM3: [
          {activity:"Forum 3"}
,
          { studentname: "Lana", studentgrade: "45" },
          { studentname: "Tariq", studentgrade: "48" },
          { studentname: "Ghassan", studentgrade: "51" },
          { studentname: "Jana", studentgrade: "70" },
          { studentname: "Tamer", studentgrade: "80" },
          { studentname: "Gina", studentgrade: "30" },
          { studentname: "Lamis", studentgrade: "70" },
          { studentname: "Tala", studentgrade: "80" },
          { studentname: "Gaby", studentgrade: "30" },
          { studentname: "Loris", studentgrade: "70" },
          { studentname: "Ahmad", studentgrade: "80" },
          { studentname: "Kholoud", studentgrade: "30" },
          { studentname: "Loaay", studentgrade: "70" },
          { studentname: "Saiid", studentgrade: "80" },
          { studentname: "Ali", studentgrade: "30" },
        ],
      }
]
   
export const classData:StudentData2[] = [
  {
    studentName: "Nour Mohamad",
    id: '1',
    img: require("../../assets/NourInstructor.jpg"),
    Modules: [
      {
        ModuleName: "Module 1",
        activities: [
          { type: "Assignment", title: "Assignment 1", grade: 55 },
          { type: "Quiz", title: "Quiz 1", grade: 77 },
          { type: "Forum", title: "Forum 1", grade: 56 },
        ],
      },
      {
        ModuleName: "Module 2",
        activities: [
          { type: "Assignment", title: "Assignment 2", grade: 58 },
          { type: "Quiz", title: "Quiz 2", grade: 59 },
          { type: "Forum", title: "Forum 2", grade: 80 },
        ],
      },
      {
        ModuleName: "Module 3",
        activities: [
          { type: "Assignment", title: "Assignment 3", grade: 55 },
          { type: "Quiz", title: "Quiz 3", grade: 55 },
          { type: "Forum", title: "Forum 3", grade: 95 },
        ],
      },
    ],
  },
  {
    studentName: "Mohamad Fawaz",
    id: '2',
    img: require("../../assets/AliAhmad.png"),
    Modules: [
      {
        ModuleName: "Module 1",
        activities: [
          { type: "Assignment", title: "Assignment 1", grade: 55 },
          { type: "Quiz", title: "Quiz 1", grade: 57 },
          { type: "Forum", title: "Forum 1", grade: 56 },
        ],
      },
      {
        ModuleName: "Module 2",
        activities: [
          { type: "Assignment", title: "Assignment 2", grade: 58 },
          { type: "Quiz", title: "Quiz 2", grade: 59 },
          { type: "Forum", title: "Forum 2", grade: 60 },
        ],
      },
      {
        ModuleName: "Module 3",
        activities: [
          { type: "Assignment", title: "Assignment 3", grade: 55 },
          { type: "Quiz", title: "Quiz 3", grade: 55 },
          { type: "Forum", title: "Forum 3", grade: 55 },
        ],
      },
    ],
  },
  {
    studentName: "Khaled khaled",
    id: '3',
    img: require("../../assets/KhaledInstructor.jpg"),
    Modules: [
      {
        ModuleName: "Module 1",
        activities: [
          { type: "Assignment", title: "Assignment 1", grade: 55 },
          { type: "Quiz", title: "Quiz 1", grade: 57 },
          { type: "Forum", title: "Forum 1", grade: 56 },
        ],
      },
      {
        ModuleName: "Module 2",
        activities: [
          { type: "Assignment", title: "Assignment 2", grade: 58 },
          { type: "Quiz", title: "Quiz 2", grade: 59 },
          { type: "Forum", title: "Forum 2", grade: 60 },
        ],
      },
      {
        ModuleName: "Module 3",
        activities: [
          { type: "Assignment", title: "Assignment 3", grade: 55 },
          { type: "Quiz", title: "Quiz 3", grade: 55 },
          { type: "Forum", title: "Forum 3", grade: 55 },
        ],
      },
    ],
  },
 
  // Add more classes if needed
];