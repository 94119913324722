import React, { useState, FC } from "react";
import StartQuiz from "../../components/StartQuiz/main/StartQuiz";
import GenerateSurvey from "../../components/generateSurvey/GenerateSurvey";
import Statistics from "../../components/surveyStatistics/SurveyStatistics";
import { PageHeader } from "../../components/pageHeader/PageHeader";
import { useMyContext } from "../../contexts/CourseCreatorContext";
import { useNavigate } from "react-router-dom";
import { SvgBackArrow } from "../../elements/Icons";
import { FlexDiv } from "../../globalStyles";
import { Typography } from "../../elements/fonts/Fonts";
import { BackArrow } from "../../globalStyles";
import { t } from "i18next";
const CreateSurvey: FC = () => {
  const [start, setStart] = useState<boolean>(false);
  const [questions, setQuestion] = useState<number>(0);
  const [multpleChoice, setMultipleChoice] = useState<number>(0);
  // const [shortAnswer, setShortAnswer] = useState<number>(0);
  // const [numerical, setNumerical] = useState<number>(0);
  const [essay, setEssay] = useState<number>(0);
  // const [grade, setGrade] = useState<number>(0);
  // const [matching,setMatching]=useState<number>(0)
  const [yesNo, setYesNo] = useState<number>(0);
  const [rating, setRating] = useState<number>(0);
  const [scale, setScale] = useState<number>(0);
  const {
    globalShowPopup,
    setGlobalShowPopup,
    editSurvey,
    globalselectedSurveyId,
    setEditSurvey,
    setGlobalSelectedSurvey,
    setGlobalSelectedSurveyId,
  } = useMyContext();
  const navigate = useNavigate();

  // cancel all the creation of the template
  const handleCancelQuiz = () => {
    navigate(-1);
    setEditSurvey(false);
    setGlobalShowPopup(true);
    // Delete the quizTemplate array from local storage
    localStorage.removeItem("surveyTemplate");
    // Delete the name of the template
    localStorage.removeItem("surveyTemplateName");
  };

  return (
    <>
      <div>
        <FlexDiv gap="0.62rem">
          <div
            // onClick={navigateBack}
            style={{ marginTop: ".2rem", cursor: "pointer" }}
          >
            <BackArrow>
              <SvgBackArrow onClick={handleCancelQuiz} />
            </BackArrow>
          </div>
          <Typography
            text={t("survey.Survey Creator")}
            fontSize="1.75rem"
            fontWeight="400"
            lineHeight="normal"
          />
        </FlexDiv>
        <Statistics
          questions={questions}
          multipleChoice={multpleChoice}
          // shortAnswer={shortAnswer}
          // numerical={numerical}
          essay={essay}
          // grade={grade}
          // matching={matching}
          yesNo={yesNo}
          rating={rating}
          scale={scale}
        />
        {/* {start ? ( */}
        <GenerateSurvey
          setStart={setStart}
          setQuestion={setQuestion}
          setMultipleChoice={setMultipleChoice}
          setEssay={setEssay}
          setYesNo={setYesNo}
          setRating={setRating}
          setScale={setScale}
          handleCancelQuiz={handleCancelQuiz}
        />
        {/* ) : ( */}
        {/* <StartQuiz
            setStart={setStart}
            setQuestion={setQuestion}
            setMultipleChoice={setMultipleChoice}
            setShortAnswer={setShortAnswer}
            setNumerical={setNumerical}
            setEssay={setEssay}
            setGrade={setGrade}
          /> */}
        {/* )} */}
      </div>
    </>
  );
};

export default CreateSurvey;
