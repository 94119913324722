import React, { FC, useState, useEffect, useRef, useContext } from "react";
import { GroupCardDataGlobalInterface } from "./groupCardNewInterface";
import {
  GroupCardRectangle,
  GroupCardHeaderRow,
  DivideLine,
  VDotsWrapper,
  EmptyGroupMembersTextWrapper,
  GroupMembersWrapper,
  GroupsMembersEmpty,
  GroupsMembersEmptyTextWrapper,
} from "./GroupCardNew.style";
import { SvgMore, SvgGroupsMembersEmpty } from "../../elements/Icons";
import { Typography, LightTypography } from "../../elements/fonts/Fonts";
import MemberInfoBox from "../../elements/memberInfoBox/MemberInfoBox";
import DropDownEditDeleteGroup from "../dropDownEditDeleteGroup/main/DropDownEditDelete";
import useToggle from "../../hooks/useToggle";
import useClickOutside from "../../hooks/useClickOutside";
import PopupDelete from "../../elements/PopupDelete/PopupDelete";
import { useStateContext } from "../../contexts/ContextProvider";
import ReusableInputGroup from "../../elements/groupInputEdit/ReusableInput";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { AllGroupMembersData } from "../allMembersGroups/allGroupMemberBarData";
import LoaderCircle from "../loader/loaderCircle/LoaderCircle";
import { breakpoints } from "../../theme";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  courseCreatorColorSelector,
} from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { toArabicDigits } from "../../utils/formatDayArabic";

const GroupCardNew: FC<GroupCardDataGlobalInterface> = ({
  bordercolor,
  title,
  updateUngroupStudents,
  students,
  groupId,
  indexGroup,
  removeGroup,
  updateGroupTitle,
  setGroupTitleInGroupCreator,
  newGroupTitle,
  setIsGroupIdInGroupCreator,
  provided,
  firstSnapshot,
  snapshot,
  isOver,
  group,
  isLoadingUngroupedStudents,
}) => {
  const studentCount = students?.length ?? 0;

  const { setIsPopUpOpen } = useStateContext();
  const { role } = useContext(ClassContext);
  const { t } = useTranslation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const { isOpen: isDropdownVisible, toggle: toggleDropdown } =
    useToggle(false);
  const dropdownRef = useClickOutside(isDropdownVisible, toggleDropdown);

  const [showDeleteGroup, setShowDeleteGroup] = useState(false);
  const handleDeleteGroup = (showDeleteGroup: boolean) => {
    setShowDeleteGroup(showDeleteGroup);
    setIsPopUpOpen(true);
  };

  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const handleShowDropDown = (showDropDown: boolean) => {
    setShowDropDown(showDropDown);
  };
  const [showEditGroup, setShowEditGroup] = useState(false);

  const handleShowEditInGroup = (showEditGroup: boolean) => {
    setShowEditGroup(showEditGroup);
  };

  const handleEditGroup = (showEditGroup: boolean) => {
    setShowEditGroup(showEditGroup);
  };

  const [titleGroup, setTitleGroup] = useState<string>(`${title}`);
  const handleInputChange = (e: any) => {
    setTitleGroup(e.target.value);
    setGroupTitleInGroupCreator(e.target.value);
  };
  // console.log("isOver: ", isOver);

  useEffect(() => {
    if (showEditGroup) {
      setIsPopUpOpen(true);
    } else {
      setIsPopUpOpen(false);
    }
  }, [showEditGroup]);

  const isGroupIdRef = useRef<number | string | undefined>(undefined);
  useEffect(() => {
    isGroupIdRef.current = groupId;
  }, [groupId]);

  const { backgroundColor, boxShadow, profileCardBorderColor } =
    useRecoilValue(ColorSelector);

  const { lineIconColor, tooltipTextColor, theme } = useRecoilValue(
    courseCreatorColorSelector
  );
  return (
    <GroupCardRectangle
      index={indexGroup}
      boxShadowlightDark={boxShadow}
      bordercolor={bordercolor || "white"}
      membersnumber={AllGroupMembersData.length}
      boxshadow={firstSnapshot}
      boxshadowGroup={snapshot}
      isOver={isOver}
      bgColor={backgroundColor}
      // style={{ background: "red" }}
      // onMouseEnter={() => console.log("*****Ali This your Index: ", index)}
    >
      <GroupCardHeaderRow index={indexGroup}>
        {showEditGroup ? (
          <ReusableInputGroup
            placeHolder={t("group.Enter Title")}
            value={titleGroup}
            handleInputChange={handleInputChange}
            setShowEditInGroup={handleShowEditInGroup}
            updateGroupTitle={() => {
              if (groupId !== undefined) {
                updateGroupTitle(groupId, newGroupTitle);
              }
            }}
            groupId={groupId ? groupId : ""}
            newGroupTitle={newGroupTitle}
            setShowDropDown={handleShowDropDown}
            toggleDropdown={toggleDropdown}
          />
        ) : (
          <>
            <Typography text={title} whiteSpace="nowrap" />
            <DivideLine lineIconColor={lineIconColor}></DivideLine>
            <Typography
              text={`${toArabicDigits(studentCount.toString())} ${t(
                "group.prtcs"
              )}`}
              fontSize="0.875rem"
              color="#5DD3B3"
              whiteSpace="nowrap"
            />

            <VDotsWrapper
              ref={dropdownRef}
              onClick={() => {
                toggleDropdown();
                setShowDropDown(true);
                if (isGroupIdRef.current !== undefined) {
                  setIsGroupIdInGroupCreator(isGroupIdRef.current);
                }
              }}
            >
              <SvgMore />
            </VDotsWrapper>
          </>
        )}
      </GroupCardHeaderRow>
      {isDropdownVisible && showDropDown && (
        <DropDownEditDeleteGroup
          groupIndex={indexGroup}
          setShowDeleteGroup={handleDeleteGroup}
          setShowDropDown={handleShowDropDown}
          setShowEditGroup={handleEditGroup}
        />
      )}
      {showDeleteGroup && (
        <PopupDelete
          show={showDeleteGroup}
          setShow={setShowDeleteGroup}
          title={t("grader.Are you sure you want to delete this Group?")}
          handleSubmit={async () => {
            if (groupId !== undefined) {
              await removeGroup(groupId);
              await updateUngroupStudents();
              setIsPopUpOpen(false);
            }
          }}
        />
      )}

      {
        <GroupMembersWrapper
          theme={theme}
          ref={provided.innerRef}
          {...provided.droppableProps}
          index={indexGroup}
          bordercolor={bordercolor || "white"}
          membersnumber={students?.length}
          style={{
            boxShadow: `${
              isOver && indexGroup === 0
                ? "0px 4px 20px 0px rgba(93, 211, 179, 0.25)"
                : "none"
            }`,
            borderRadius: `${isOver && indexGroup === 0 ? "0.625rem" : "0"}`,
          }}
        >
          {students &&
          students.length === 0 &&
          indexGroup === 0 &&
          isLoadingUngroupedStudents ? (
            <div style={{ margin: "auto" }}>
              <LoaderCircle
                width={windowWidth > parseInt(breakpoints.tablets) ? 50 : 40}
                height={windowWidth > parseInt(breakpoints.tablets) ? 50 : 40}
                color="#5DD3B3"
                secondaryColor="#5DD3B3"
              />
            </div>
          ) : students && students.length === 0 && indexGroup === 0 ? (
            <GroupsMembersEmpty>
              <SvgGroupsMembersEmpty color={tooltipTextColor} />
              <GroupsMembersEmptyTextWrapper>
                <LightTypography
                  text={t("group.Drag and drop students here to ungroup them")}
                  lineHeight="1.375rem"
                  fontSize="0.875rem"
                />
              </GroupsMembersEmptyTextWrapper>
            </GroupsMembersEmpty>
          ) : (
            <></>
          )}
          {students && students.length === 0 && indexGroup > 0 && (
            <EmptyGroupMembersTextWrapper>
              <LightTypography
                text={t("group.Drag and drop students here")}
                fontSize="0.875rem"
              />
            </EmptyGroupMembersTextWrapper>
          )}
          {students &&
            students.length > 0 &&
            students?.map((student, index) => (
              <Draggable
                draggableId={`Student-${index}-${groupId} || ''`}
                index={index}
                key={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <MemberInfoBox
                      username={student.username}
                      profilePicture={student.profilePicture}
                      index={index}
                      studentId={student.studentId}
                      boxShadow={
                        snapshot.isDragging
                          ? "0px 4px 20px 0px rgba(93, 211, 179, 0.25)"
                          : "none"
                      }
                      outlineColor={
                        snapshot.isDragging
                          ? "#5DD3B3"
                          : `${profileCardBorderColor}`
                      }
                      groupId={student.groupId}
                      id={student.id}
                    />
                  </div>
                )}
              </Draggable>
            ))}
          {provided.placeholder}
        </GroupMembersWrapper>
      }
    </GroupCardRectangle>
  );
};

export default GroupCardNew;
