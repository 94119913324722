import React, { useState, FC } from "react";
import StartQuiz from "../../components/StartQuiz/main/StartQuiz";
import GenrateQuiz from "../../components/GenerateQuiz/main/GenerateQuiz";
import Statistics from "../../components/QuizStatistics/main/Statistics";
import { PageHeader } from "../../components/pageHeader/PageHeader";
import { Typography } from "../../elements/fonts/Fonts";
import { useTranslation } from "react-i18next";
import { SvgBackArrow } from "../../elements/Icons";
import { FlexDiv } from "../../globalStyles";
import { useNavigate, useParams } from "react-router-dom";
import { useQuizMyContext } from "../../contexts/CourseCreatorQuizContext";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../utils/StringManipulation";
import { BackArrow } from "../../globalStyles";
const CreateQuiz: FC = () => {
  const [start, setStart] = useState<boolean>(false);
  const [questions, setQuestion] = useState<number>(0);
  const [multpleChoice, setMultipleChoice] = useState<number>(0);
  const [shortAnswer, setShortAnswer] = useState<number>(0);
  const [numerical, setNumerical] = useState<number>(0);
  const [essay, setEssay] = useState<number>(0);
  const [grade, setGrade] = useState<number>(0);
  const [matching, setMatching] = useState<number>(0);
  const [trueFalse, setTrueFalse] = useState<number>(0);
  const [totalGrade, setTotalGrade] = useState<number>(0);
  const { t } = useTranslation();
  const params = useParams();
  const classId = params.id;
  const navigate = useNavigate();
  const {
    setGlobalQuizShowPopup,
    setEditQuiz,
    setEditQuizButton,
    setGlobalQuestionsArray
  } = useQuizMyContext();
  const feedbackRight = "quiz.Your Answer is correct."
  const feedbackWrong = "quiz.Your Answer is incorrect."
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");

  // Exit From The Quiz Creator.
  const handleCancelQuiz = () => {
    navigate(`/course-creator/${classId}`);
    setEditQuiz(false);
    setEditQuizButton(false);
    setGlobalQuizShowPopup(true);
    setGlobalQuestionsArray([
      {
        question: "",
        questType: "normal",
        type: "",
        grade: "",
        icon: <></>,
        questPart: [],
        choices: [] as {
          name: string;
          percentage?: string;
          isChecked?: boolean;
        }[],
        feedRight: t(feedbackRight),
        feedWrong: t(feedbackWrong),
        subId: subIdSplitted,
        provider:provider,
        classSourceId: classId,
      },
    ]);
    // Delete the quizTemplate array from local storage
    localStorage.removeItem("quizTemplate");
    // Delete the name of the template
    localStorage.removeItem("quizTemplateName");
  };
  return (
    <>
      <div>
        <FlexDiv gap="0.62rem">
          <div
            // onClick={navigateBack}
            style={{ marginTop: ".2rem", cursor: "pointer" }}
          >
            <BackArrow>
              <SvgBackArrow onClick={handleCancelQuiz} />
            </BackArrow>
          </div>
          <Typography
            text={t("quiz.Quiz Creator")}
            fontSize="1.75rem"
            fontWeight="400"
            lineHeight="normal"
          />
        </FlexDiv>
        <Statistics
          questions={questions}
          multipleChoice={multpleChoice}
          shortAnswer={shortAnswer}
          numerical={numerical}
          essay={essay}
          grade={grade}
          matching={matching}
          trueFalse={trueFalse}
          totalGrade={totalGrade}
        />
        {/* {start ? ( */}
        <GenrateQuiz
          setStart={setStart}
          setQuestion={setQuestion}
          setMultipleChoice={setMultipleChoice}
          setShortAnswer={setShortAnswer}
          setNumerical={setNumerical}
          setEssay={setEssay}
          setGrade={setGrade}
          setMatching={setMatching}
          setTrueFalse={setTrueFalse}
          setTotalGrade={setTotalGrade}
          handleCancelQuiz={handleCancelQuiz}
        />
        {/* ) : ( */}
        {/* <StartQuiz
            setStart={setStart}
            setQuestion={setQuestion}
            setMultipleChoice={setMultipleChoice}
            setShortAnswer={setShortAnswer}
            setNumerical={setNumerical}
            setEssay={setEssay}
            setGrade={setGrade}
          /> */}
        {/* )} */}
      </div>
    </>
  );
};

export default CreateQuiz;
