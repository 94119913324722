import React, { useState, FC, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import axios from "axios";
import { Container } from "../../elements/StyledContainerForm/StyledContainerForm.style";
import {
  LightTypography,
  PrimaryFontBoldText,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  LabelDiv,
  BtnsDiv,
  LabelBtnsDiv,
  ButtonsDiv,
  FlexDiv,
  AddUrlDiv,
  AddUrlLink,
  FlexDivTitle,
  FlexBetween,
  IconDev,
} from "./LinkTreeForm.style";
import StyledInput from "../../elements/StyledInput/StyledInput";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import {
  SvgLinkTree,
  SvgClose,
  SvgDeleteIcon,
  SvgAdd,
} from "../../elements/Icons";
import Toggle from "../../elements/Toogle/toogle";
import TwoDatePicker from "../../elements/TwoDatePicker/TwoDatePicker";
import CancelButton from "../../elements/StyledButton/CancelButton";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import { useStateContext } from "../../contexts/ContextProvider";
import LoaderThreeDot from "../loader/loaderThreeDot/LoaderThreeDot";
import { ContentFormProps } from "../../interfaces/ContentFormProps";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { WebSocketConnector } from "../../Views/messages/webSocketConnection";
import {
  ContentProps,
  TopicProps,
} from "../../Views/teacherView/Course-Creator/courseCreatorInterface";
import UrlLinkForm from "../UrlLinkForm/UrlLinkFrom";
import { WebsocketContext } from "../../contexts/notificationContext";

interface linkTreeProps {
  topicContents?: ContentProps[];
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit?: () => void;
  setShowEditContent?: React.Dispatch<React.SetStateAction<boolean>>;
  showEditContent?: boolean;
  setContentTitleInTopic?: (contentTitle: string) => void;
  editContent?: (contentId: number, newTitle: string) => void;
  newContentTitle?: string | undefined;
  contentId?: number | undefined;
  activeGetModulesWithTopics?: () => void;
  newTopicRefId?: number;
  setIsLinkTreeActive?: React.Dispatch<React.SetStateAction<boolean>>;
  isLinkTreeActive?: boolean;
  titleUrl?: string;
  descriptionUrl?: string;
  urlLinkUrl?: string;
  linkIdToEdit?: string;
  topicRefIdUrl?: number;
  contentIndexUrl?: number;
  removeContent?: (
    topic: TopicProps,
    contentId: number,
    contentIndex: number
  ) => void;
  topic?: TopicProps;
  contentIndex?: number;
}

const LinkTreeForm: FC<linkTreeProps> = ({
  setShow,
  handleSubmit,
  setShowEditContent,
  showEditContent,
  setContentTitleInTopic,
  editContent,
  newContentTitle,
  contentId,
  activeGetModulesWithTopics,
  newTopicRefId,
  setIsLinkTreeActive,
  titleUrl,
  descriptionUrl,
  urlLinkUrl,
  isLinkTreeActive,
  linkIdToEdit,
  topicRefIdUrl,
  removeContent,
  topic,
  contentIndex,
  contentIndexUrl,
}) => {
  const classSourceId = useParams();
  const { t } = useTranslation();
  const setClassSourceId = classSourceId.id;
  const { isLoading, user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const baseURL = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";
  const path = "resource/create";
  const tenantName = localStorage.getItem("tenant");
  const {
    setContent_Title,
    contentType,
    setContent_Data,
    getContent_Data,
    contentIdToEdit,
    topicRefId,
    content_Index,
    setIsPopUpOpen,
    contentGlobalState,
  } = useStateContext();

  const [isUrlLinkactive, setIsUrlLinkactive] = useState<boolean>(false);
  const [prevData, setPrevData] = useState<any>();
  const [readyToEdit, setReadyToEdit] = useState(true);
  const [foundRefIdState, setFoundRefIdState] = useState<number>();
  const [visibleStudents, setVisibleStudents] = useState(true);
  const [showTimeVisibility, setShowTimeVisibility] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [error, setError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [urlData, setUrlData] = useState([
    { title: "", description: "", url: "", isUrlInvalid: false },
    { title: "", description: "", url: "", isUrlInvalid: false },
  ]);
  const { isReady, value, send } = useContext(WebsocketContext);

  useEffect(() => {
    setTitle(titleUrl ? titleUrl : "");
    const updatedUrlData = [...urlData];
    updatedUrlData[0].description = descriptionUrl ? descriptionUrl : "";
    setUrlData(updatedUrlData);
    updatedUrlData[0].url = urlLinkUrl ? urlLinkUrl : "";
    setUrlData(updatedUrlData);
  }, [isLinkTreeActive]);

  useEffect(() => {
    if (urlData.length < 2) {
      setIsUrlLinkactive(true);
      setIsLinkTreeActive && setIsLinkTreeActive(false);
    }
  }, [urlData]);

  // Function to update form
  const updateLinkTree = async (path: string, body: any) => {
    try {
      const response = await axios.put(
        `${baseURL}/${path}/${foundRefIdState}/${tenantName}`,
        { data: body }
      );
      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
      throw error; // Rethrow the error to be handled by the calling service
    }
  };

  // Function to get data
  const getLinkTreeToEdit = async (path: string, linkTreeId: number) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `${baseURL}/${path}/${linkTreeId}/${tenantName}`
      );
      setPrevData(res.data.data);
      setReadyToEdit(false);
      const toReturn: any[] = res.data.data;
      return toReturn;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (showEditContent && title !== "") {
      setReadyToEdit(false);
    }
  }, [title]);

  // This useEffect hook is used to fetch data when showEditContent is true.
  useEffect(() => {
    const fetchData = async () => {
      const yourArray = await (activeGetModulesWithTopics &&
        activeGetModulesWithTopics());
      const solutionArray: any[] = Array.isArray(yourArray) ? yourArray : [];
      function findRefIdInModules(
        modulesArray: any[],
        targetContentId: number
      ) {
        for (let i = 0; i < modulesArray.length; i++) {
          const topicsArray = modulesArray[i].topicsArray;

          for (let j = 0; j < topicsArray.length; j++) {
            const contentsArray = topicsArray[j].contents;

            for (let k = 0; k < contentsArray.length; k++) {
              if (contentsArray[k].contentId === targetContentId) {
                return contentsArray[k].refId;
              }
            }
          }
        }
        // Return a default value (e.g., null) if the contentId is not found
        return null;
      }
      if (contentIdToEdit) {
        const contentIdToFind = contentIdToEdit; // Replace with the specific contentId you're looking for
        const foundRefId = findRefIdInModules(solutionArray, contentIdToFind);
        setFoundRefIdState(foundRefId);
        await getLinkTreeToEdit("resource/linkTree", foundRefId);
      }
    };
    if (!isLinkTreeActive || isUrlLinkactive) {
      fetchData();
      // setReadyToEdit(false);
    }
  }, [isUrlLinkactive]);

  // This useEffect hook is used to update the states with edit data (prevData)
  useEffect(() => {
    if (prevData && showEditContent) {
      setContent_Title(prevData.linkTree.title);
      setTitle(prevData.linkTree.title);
      setDescription(prevData.linkTree.description);
      setUrlData(prevData.result);
      setVisibleStudents(prevData.linkTree.isVisible);
      setShowTimeVisibility(
        prevData.linkTree.visibleFrom === null ||
          prevData.linkTree.visibleTo === null
          ? false
          : true
      );
      setFromDate(
        prevData.linkTree.visibleFrom
          ? new Date(prevData.linkTree.visibleFrom)
          : null
      );
      setToDate(
        prevData.linkTree.visibleTo
          ? new Date(prevData.linkTree.visibleTo)
          : null
      );
    }
  }, [prevData]);

  // Function to handle url description
  const handleUrlDescriptionChange = (value: any) => {
    setDescription(value);
  };

  // Function to handle description
  const handleDescriptionChange = (value: any, index: number) => {
    const updatedUrlData = [...urlData];
    updatedUrlData[index].description = value;
    setUrlData(updatedUrlData);
  };

  // Function to handle url name
  const handleNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedUrlData = [...urlData];
    updatedUrlData[index].title = e.target.value;
    setUrlData(updatedUrlData);
  };

  // Function to handle url link
  const handleLinkChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedUrlData = [...urlData];
    updatedUrlData[index].url = e.target.value;
    updatedUrlData[index].isUrlInvalid = false; // Reset the validity when the link changes
    setUrlData(updatedUrlData);
  };

  // handle toggle change visiblity for students
  const handleToggleChangeVisible = (isChecked: boolean) => {
    setVisibleStudents(isChecked);
  };

  // function to set time visibility interval
  const handleTwoDatePickerChange = (start: Date | null, end: Date | null) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleToggleChangeTime = (isChecked: boolean) => {
    if (isChecked) {
      setShowTimeVisibility(true); // Show Time Visibility when the toggle is on
    } else {
      setShowTimeVisibility(false);
    }
  };

  // function to add url block
  const addUrlDiv = () => {
    setUrlData([
      ...urlData,
      { title: "", description: "", url: "", isUrlInvalid: false },
    ]);
  };

  // function to remove url block
  const handleRemoveUrl = (index: number) => {
    const updatedUrlData = [...urlData];
    updatedUrlData.splice(index, 1);
    setUrlData(updatedUrlData);
  };

  const isValidUrl = (url: string) => {
    // Regular expression for a simple URL validation
    // const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return url;
  };

  // handle save
  const handleContinueClick = async () => {
    let hasValidationError = false;

    const updatedUrlData = urlData.map((urlItem) => {
      const isUrlInvalid = !isValidUrl(urlItem.url);
      if (isUrlInvalid) {
        hasValidationError = true;
      }

      let formattedUrl = urlItem.url;
      if (formattedUrl) {
        formattedUrl =
          formattedUrl.startsWith("https://") ||
          formattedUrl.startsWith("http://")
            ? formattedUrl
            : `https://${formattedUrl}`;
      } else {
        formattedUrl = "";
      }

      return {
        ...urlItem,
        url: formattedUrl, // Update the URL if it needs to be prefixed with https://
        isUrlInvalid, // Add a property to track URL validation for each link
      };
    });

    setUrlData(updatedUrlData);

    if (
      updatedUrlData.some(
        (urlItem) =>
          urlItem.title.trim() === "" ||
          urlItem.description.trim() === "" ||
          urlItem.isUrlInvalid
      ) ||
      (showTimeVisibility && (!fromDate || !toDate)) ||
      title.trim() === "" ||
      description.trim() === ""
    ) {
      setError(true);
      setShow && setShow(true);
    } else {
      setError(false);
      if (contentId !== undefined && newContentTitle !== undefined) {
        editContent && editContent(contentId, newContentTitle);
      }
      setShowEditContent && setShowEditContent(false);
      setShow && setShow(false);
      setIsPopUpOpen(false);
      if (!showEditContent) {
        handleSubmit && handleSubmit();
      }

      const UrlLinkTreeData: any = {
        _id: contentIdToEdit,
        id: showEditContent ? foundRefIdState : null,
        classSourceId: setClassSourceId,
        userSourceId: subId,
        provider: provider,
        contentIndex: isLinkTreeActive ? contentIndexUrl : content_Index,
        topicRefId: isLinkTreeActive ? topicRefIdUrl : topicRefId,
        type: "Link_Tree",
        title: title,
        description: description,
        urlLinkTreeData: updatedUrlData,
        showTimeVisibility: showTimeVisibility,
        visibleStudents: visibleStudents,
        fromDate: fromDate,
        toDate: toDate,
      };

      try {
        if (isLinkTreeActive) {
          const response = await axios.post(
            `${baseURL}/${path}/${tenantName}`,
            { data: UrlLinkTreeData }
          );
          if (isReady && send) {
            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "create",
                  title: title,
                  contentType: "Link_Tree",
                  refId: response.data.data.resourceId,
                  refTable: "LinkTree",
                  subId: subId,
                  provider: provider,
                  type: "content",
                  description: `Title: ${title}`,
                },
              })
            );
          }
          if (topic && contentIndex && contentId) {
            removeContent && removeContent(topic, contentId, contentIndex);
            await (activeGetModulesWithTopics && activeGetModulesWithTopics());
          }
        } else if (!isLinkTreeActive) {
          await updateLinkTree("resource/linkTree", UrlLinkTreeData);
          if (isReady && send) {
            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "update",
                  title: title,
                  contentType: "Link_Tree",
                  subId: subId,
                  provider: provider,
                  refId: foundRefIdState,
                  refTable: "LinkTree",
                  type: "content",
                  description: `Title: ${title}`,
                },
              })
            );
          }
        }
        setShow && setShow(false);
        setIsPopUpOpen(false);
        setIsLinkTreeActive && setIsLinkTreeActive(false);
        await (activeGetModulesWithTopics && activeGetModulesWithTopics());
      } catch (error) {
        console.error("Axios Error:", error);
      } finally {
        await (activeGetModulesWithTopics && activeGetModulesWithTopics());
      }
      contentIdToEdit && setContent_Data(UrlLinkTreeData, contentIdToEdit);
    }
  };
  const { backgroundColor, themeTextColor, inputBorderColor, iconCloseColor } =
    useRecoilValue(ColorSelector);
  return (
    <>
      {isUrlLinkactive ? (
        <UrlLinkForm
          setShow={setShow}
          setShowEditContent={setShowEditContent}
          setContentTitleInTopic={setContentTitleInTopic}
          editContent={editContent}
          contentId={contentId}
          newContentTitle={newContentTitle}
          showEditContent={showEditContent}
          activeGetModulesWithTopics={activeGetModulesWithTopics}
          newTopicRefId={topicRefId}
          setIsUrlLinkactive={setIsUrlLinkactive}
          isUrlLinkactive={isUrlLinkactive}
          titleLinkTree={title}
          descriptionLinkTree={urlData[0].description}
          urlLinkTree={urlData[0].url}
          topicRefIdLinkTree={topicRefId}
          contentIndexLinkTree={contentIndex}
          topic={topic}
          contentIndex={contentIndex}
          removeContent={removeContent}
        />
      ) : (
        <Container
          themetextcolor={themeTextColor}
          backgroundcolor={backgroundColor}
          scrollbar={inputBorderColor}
        >
          {/* header */}
          {/* <FlexBetween>
            <FlexDivTitle>
              <SvgLinkTree />
              <PrimaryFontBoldText
                text={t("link tree.Link Tree")}
                fontSize="1.125rem"
              />
            </FlexDivTitle> */}
            {showEditContent && readyToEdit && <LoaderThreeDot />}
            {/* <SvgClose
          color={iconCloseColor}
          onClick={() => {
            setShow(false);
            setIsPopUpOpen(false);
            setShowEditContent(false);
          }}
          style={{ cursor: "pointer" }}
        /> */}
          {/* </FlexBetween> */}

          {/* title */}
          <LabelDiv>
            <FlexDiv gap="0.25rem">
              <LightTypography text={t("forms.Title")} fontSize="0.875rem" />
              <span>*</span>
            </FlexDiv>
            <StyledInput
              placeholder={t("link tree.Enter Link Title")}
              type="text"
              onChange={(e: any) => {
                setTitle(e.target.value);
                setContent_Title(e.target.value);
                setContentTitleInTopic &&
                  setContentTitleInTopic(e.target.value);
              }}
              value={title}
              width="100%"
            />
            {title.trim() === "" && error && (
              <ErrorDiv>{`${t("forms.Please")} ${t(
                "link tree.Enter Link Title"
              )}`}</ErrorDiv>
            )}
          </LabelDiv>

          {/* description */}
          <LabelDiv>
            <FlexDiv gap="0.25rem">
              <LightTypography
                text={t("forms.Description")}
                fontSize="0.875rem"
              />
              <span>*</span>
            </FlexDiv>
            <TinyEditor
              onChange={handleUrlDescriptionChange}
              placeholder={t("forms.Description")}
              initialValue={`${
                prevData && prevData.linkTree.description
                  ? prevData.linkTree.description
                  : ""
              }`}
              height="13.25rem"
            />
            {description.trim() === "" && error && (
              <ErrorDiv>{`${t("forms.Please")} ${t(
                "forms.Enter Description"
              )}`}</ErrorDiv>
            )}
          </LabelDiv>

          {/* url block part */}
          {urlData.map((url, index) => (
            <AddUrlDiv key={index}>
              <FlexDiv>
                {/* url name */}
                <LabelDiv>
                  <FlexDiv gap="0.25rem">
                    <LightTypography
                      text={t("link.URL Name")}
                      fontSize="0.875rem"
                    />
                    <span>*</span>
                  </FlexDiv>
                  <StyledInput
                    placeholder={t("link.Enter URL Name")}
                    type="text"
                    value={url.title}
                    onChange={(e) => handleNameChange(e, index)}
                    width={index > 0 ? "26rem" : "27.5rem"}
                  />
                  {url.title.trim() === "" && error && (
                    <ErrorDiv>{`${t("forms.Please")} ${t(
                      "link.Enter URL Name"
                    )}`}</ErrorDiv>
                  )}
                </LabelDiv>

                {/* url link */}
                <LabelDiv>
                  <FlexDiv gap="0.25rem">
                    <LightTypography
                      text={t("link.URL Link")}
                      fontSize="0.875rem"
                    />
                    <span>*</span>
                  </FlexDiv>
                  <FlexDiv>
                    <StyledInput
                      placeholder={t("link tree.Paste URL Link Here")}
                      type="text"
                      value={url.url}
                      onChange={(e) => handleLinkChange(e, index)}
                      width={index > 0 ? "26rem" : "27.5rem"}
                    />
                  </FlexDiv>
                  {url.url.trim() === "" && error && (
                    <ErrorDiv>{`${t("forms.Please")} ${t(
                      "link.Enter URL Link"
                    )}`}</ErrorDiv>
                  )}
                  {/* {url.isUrlInvalid && (
                <ErrorDiv>Please Enter a Valid URL</ErrorDiv>
              )} */}
                </LabelDiv>

                {/* delete option when block length is > 0 */}
                {index > 0 && (
                  <IconDev onClick={() => handleRemoveUrl(index)}>
                    <SvgDeleteIcon color="#D85D66" />
                  </IconDev>
                )}
              </FlexDiv>

              {/* url link description */}
              <LabelDiv>
                <FlexDiv gap="0.25rem">
                  <LightTypography
                    text={t("link.URL Link Description")}
                    fontSize="0.875rem"
                  />
                  <span>*</span>
                </FlexDiv>
                <TinyEditor
                  onChange={(value) => handleDescriptionChange(value, index)}
                  placeholder={t("general.Description")}
                  initialValue={url.description}
                  height="13.25rem"
                />
                {!url.description && error && (
                  <ErrorDiv>{`${t("forms.Please")} ${t(
                    "link.URL Link Description"
                  )}`}</ErrorDiv>
                )}
              </LabelDiv>
            </AddUrlDiv>
          ))}

          {/* add url block */}
          <AddUrlLink onClick={addUrlDiv}>
            <SvgAdd />
            <PrimaryFontBoldText
              text={t("link tree.Add URL Link")}
              fontSize="0.75rem"
            />
          </AddUrlLink>

          {/* toggles */}
          <BtnsDiv>
            <LabelBtnsDiv>
              <Toggle
                onToggle={handleToggleChangeVisible}
                checked={visibleStudents}
                id="roleToggle1"
              />
              <TypographyRegularDescription
                text={t("forms.Make Visible for Students")}
                fontSize="1rem"
              />
            </LabelBtnsDiv>
            <LabelBtnsDiv>
              <Toggle
                onToggle={handleToggleChangeTime}
                checked={showTimeVisibility}
                id="roleToggle2"
              />
              <TypographyRegularDescription
                text={t("forms.Set Time Visibility Interval")}
                fontSize="1rem"
              />
              {showTimeVisibility && (
                <>
                  <TwoDatePicker
                    onDateChange={handleTwoDatePickerChange}
                    prevStartDate={fromDate}
                    prevEndDate={toDate}
                  />
                  {(!fromDate || !toDate) && error && (
                    <ErrorDiv>{`${t("forms.Please")} ${t(
                      "forms.Set Time Visibility Interval"
                    )}`}</ErrorDiv>
                  )}
                </>
              )}
            </LabelBtnsDiv>
          </BtnsDiv>

          {/* actions buttons */}
          <ButtonsDiv>
            <CancelButton
              name={t("forms.Cancel")}
              onClickFunction={() => {
                setShow && setShow(false);
                setIsPopUpOpen(false);
                setShowEditContent && setShowEditContent(false);
              }}
              color="#D85D66"
              borderColor="#D85D66"
              backgroundColor="transparent"
            />
            <CancelButton
              name={t("forms.Save")}
              onClickFunction={() => {
                handleContinueClick();
                setContent_Title(title);
              }}
              color="#fff"
              borderColor="#5DD3B3"
              backgroundColor="#5DD3B3"
            />
          </ButtonsDiv>
        </Container>
      )}
    </>
  );
};

export default LinkTreeForm;
