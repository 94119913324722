import styled from "styled-components";

export const StudentLandingPageLayout =styled.div`
display: flex;
flex-direction:column;
/* gap:1.25rem;
border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));*/
width:100%;
padding:1.25rem 1.40rem; 
gap:1.25rem;
`;

export const StudentLandingPageMainTitle =styled.div`
display: flex;
width:100%;
justify-content:space-between;
border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
/* width: 86.9375rem; */
min-height: 3.875rem;
flex-shrink: 0;
padding:1.25rem;
/* margin-bottom:0.30rem;
cursor:pointer; */
`;

export const StudentLandingPageMain =styled.div`
display: flex;
flex-direction:column;
width:100%;
gap: 1.25rem;
`;
export const StudentLandingPageMainObject =styled.div`
display: flex;
flex-direction:column;
width:100%;
gap:0.65rem;
border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
padding:1.25rem 1.40rem; 
background-color:red;

`;
export const StudentLandingPageMainObjectTitle =styled.div`
display: flex;
width:100%;
justify-content:space-between;
margin-bottom:0.30rem;
cursor:pointer;
`;

