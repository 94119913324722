import styled from "styled-components";

export const DateBoxPopUpContainer = styled.div`
border-radius: 0.625rem;
background: var(--Main-dark-grey, #364350);
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.20);
width: 18.5rem;
/* height: 27.5rem; */
flex-shrink: 0;
display: flex;
flex-direction:column;
gap:1.25rem;
padding:1.25rem;
position:relative;
margin-bottom:2rem;
justify-content:space-between;
`;

export const DateBoxPopUpSeparatedLine = styled.div`
height: 0.0625rem;
width: 100%;
background: #576371;
`;

export const DateBoxPopUpActionLine = styled.div`
display: flex;
width: 100%;
justify-content:space-between;
margin-top:-1rem;
`;

export const DateBoxPopUpSmall = styled.div`
display: flex;
flex-direction:column;
gap:1.2rem;

`;
export const DatePickerContainerStyled =styled.div`
  position: relative;
  display: inline-block;
  margin-left: -0.6rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: 17.8rem;
  margin-bottom: -0.3rem;
  background-color: transparent;
  border: none;
  top: -1.2rem;
  .react-datepicker {
  position: absolute;
  background-color: transparent;
  border: none;
  left: 0;
}

.react-datepicker-wrapper {
  display: inline-block;
  opacity: 0;
  height: 0.1rem;
  background-color: transparent;
}

.react-datepicker__header {
  background-color: transparent;
  border: none;
}
`;