// ThemeState.js

import { atom, atomFamily } from 'recoil';
import { ContentR } from '../components/CourseTimeline/utils/CourseResourcesInterface';
import { ResourceFrom } from '../Views/courseView/content/Content';

export const themeState = atom({
    key: 'themeState',
    default: localStorage.getItem('theme') || 'dark',

});

export const languageState = atom({
    key: 'languageState',
    default: localStorage.getItem('language') || 'en', // Default language is English
});

export const adminState = atom({
    key: 'adminState',
    default: localStorage.getItem('adminState') || false // isAdmin default
    // default: false // isAdmin default
});

export const adminRoleState = atom({
    key: 'adminRoleState',
    default: localStorage.getItem('adminRoleState') || "student",
});

export const selectedContent = atomFamily({
    key: 'selectedContent', // unique ID (with respect to other atoms/selectors)
    default: (classId) => {
        const id = String(classId);//wrapped in String to prevent fail on runtime
        // Retrieve the value from localStorage based on the resourceId
        const savedValue = localStorage.getItem(`selectedResource-${id}`);
        return savedValue ? JSON.parse(savedValue) : null;
    },
});
export const resource = atom<ResourceFrom>({
    key: 'resource', // Unique key for this atom
    default: {
        percentage: null,
        documentType: '',
        isYoutube: false,
        contentId: null,
        contentTitle: '',
        online_session: null,
        refId: '',
        type: '',
        path: '',
        description: '',
        comments: [],
        dateAdded: undefined,
        visibleFrom: null,
        visibleTo: null,
        contentIndex: 0,
        isVisible: false,
        images: [],
        status: false,
        duration: '',
        contentOpen: undefined,
        maxAttempts: 0,
        startDate: undefined,
        endDate: undefined,
        startTime: '',
        endTime: '',
        upload: false,
        write: false,
        recordings: [],
        thumbnail: '',
        downloadable: false,
        transcription: '',
        links: [],
        category: '',
        moduleIndex: 0,
        topicIndex: 0,
        title: '',
        visibility: null,
        topicTitle: "",
        moduleTitle: "",
    },
});
export const visibilityChange = atom({
    key: 'visibility',
    default: false
})
