import React, { FC } from "react";
import { ReactComponent as PinnedIcon } from "../../../../assets/icons/pinned_2.svg";
import { ReactComponent as VideoIcon } from "../../../../assets/icons/video_2.svg";
import { ReactComponent as VoiceIcon } from "../../../../assets/icons/sound_3.svg";
import { Typography, LightTypography } from "../../../../elements/fonts/Fonts";
import { theme } from "../../../../theme";
import {
  CommentContainer,
  Comment,
  CommentTextAreaContainer,
  CommetnTextArea,
  IconsContainer,
} from "./AssignmentCommentSection.style";
import { AssignmentCommentSectionProps } from "./AssignmentCommentSectionInterface";
import { useTranslation } from "react-i18next";
const AssignmentCommentSection: FC<AssignmentCommentSectionProps> = ({
  showRating,
  onCommentChange,
  comment
}) => {
  const { t } = useTranslation();
  const handleCommentInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newComment = event.target.value;
    onCommentChange(newComment); 
  };

  return (
    <CommentContainer showrating={showRating.toString()}>
      {/* <div style={{textTransform: "uppercase"}}>  
      <Typography
        text={t('survey.Survey Comment')}
        fontSize="1rem"
        color={`${theme.dark.main_color}`}
      />
      </div> */}
      {/* <Comment>
        <div>
          <LightTypography text={t('assignment.Add a comment')} fontSize="0.875rem" />
          <IconsContainer>
            <PinnedIcon />
            <VideoIcon color="#5DD3B3"/>
            <VoiceIcon />
          </IconsContainer>
        </div>
        <CommentTextAreaContainer>
          <CommetnTextArea placeholder={t('online session.Comment')} onChange={handleCommentInputChange} value={comment}/>
        </CommentTextAreaContainer>
      </Comment> */}
    </CommentContainer>
  );
};

export default AssignmentCommentSection;
