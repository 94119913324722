import styled from "styled-components";

// export const  AnalyticsForum = styled.div`
// display: flex;
// width:100%
// `;
export const  AnalyticsForumContainer = styled.div`
display: flex;
flex-direction: column;
gap:1.25rem;
padding:1.25rem 0;
border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
`;
export const  AnalyticsForumSpace = styled.div`
display: flex;
justify-content:space-between;
padding: 0 1.25rem;
`;

export const  AnalyticsForumTable = styled.div`
display: flex;
width:100%;
flex-direction: column;
`;
export const  AnalyticsForumTableHeaderRow = styled.div`
display: flex;
width:100%;
/* gap: 0.1rem; */
height: 3.625rem;
flex-shrink: 0;

`;

export const  AnalyticsForumTableHeaderCell = styled.div`
display: flex;
flex:1;
height: 3.625rem;
align-items:center;
padding-left:1.25rem;
justify-content:center;
/* background-color:yellow; */
&:first-child{
  flex:1.3;
  justify-content:left;
  /* background-color:blue; */
}
&:last-child{
  flex:0.5;
  /* background-color:aqua; */
  justify-content:center;
}
&:nth-child(2){
  flex:0.7;
  /* background-color:red; */
}
&:nth-child(3){
  
  /* background-color:brown; */
}
&:nth-child(4){

  /* background-color:yellowgreen; */
}
&:nth-child(5){
  flex:0.75;
  /* background-color:black; */
}
&:nth-child(6){
  flex:0.75;
  /* background-color:red; */
}
`;

export const  AnalyticsForumTableBody = styled.div`
display: flex;
width:100%;
flex-direction:column;
/* gap: 0.2rem; */
`;

export const  AnalyticsForumTableBodyRow = styled.div`
display: flex;
width:100%;
/* gap: 0.1rem; */
/* height: 4rem; */
align-items:center;
padding:0.4rem 0 ;
flex-shrink: 0;
&:nth-child(odd){
  background: rgba(255, 255, 255, 0.03);
}
`;



export const  AnalyticsForumImg = styled.img`
display: flex;
width: 3.125rem;
height: 3.125rem;
flex-shrink: 0;
border-radius:50%;
object-fit: cover;
margin-right:0.62rem;
`;

export const  AnalyticsForumSvgs = styled.div`
display: flex;

margin-right:0.62rem;
`;

export const  AnalyticsForumInput = styled.input`
display: flex !important;
border-radius: 0.3125rem;
border: 1px solid #576371;
width: 3rem;
/* max-width: 3.5rem; */
height: 2.3125rem;
flex-shrink: 0;
background-color:transparent;
color:#fff;

text-align:center !important;
padding:0.7rem;
&:focus{
  outline: none;
}
`;