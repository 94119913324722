import React, { useState } from "react";
import {
  StudentLandingCell,
  StudentLandingSeparatedLine,
  StudentLandingTableCols,
  StudentLandingTableColsTitle,
  StudentLandingTableLayout,
  StudentLandingTableLayoutRow,
} from "./StudentLandingTable.style";
import { LightTypography, MediumTypography, Typography } from "../fonts/Fonts";
import { SvgSimpleArrow } from "../Icons";
import { getIconBasedOnType } from "./StudentLandingTableFunction";
import { FlexAppContainer } from "../../App.style";
import { PointerContainer } from "../../components/AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableTable.style";
import { Link } from "react-router-dom";

interface StudentLandingTableProps {
  LandingData?: any[];
  role?: any;
  LinkActions?: boolean;
  TitleLink?: any;
  TotalView?: any;
}

export const StudentLandingTable = ({
  LandingData,
  role,
  LinkActions,
  TitleLink,
  TotalView,
}: StudentLandingTableProps): JSX.Element => {
  const ViewedProperty = true;

  // const [openRows, setOpenRows] = useState<number[]>([]);
  // Initialize openRows state with all indices
  const [openRows, setOpenRows] = useState<number[]>(
    LandingData ? LandingData.map((_, index) => index) : []
  );

  const toggleRow = (index: number) => {
    if (openRows.includes(index)) {
      setOpenRows(openRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setOpenRows([...openRows, index]);
    }
  };

  return (
    <>
      {LandingData &&
        LandingData.map((Value: any, index: number) => (
          <StudentLandingTableLayout key={index}>
            <StudentLandingTableCols>
              <StudentLandingTableLayoutRow
                onClick={() => toggleRow(index)}
                style={{ cursor: "pointer" }}
              >
                {Value.type && (
                  <StudentLandingTableColsTitle>
                    {getIconBasedOnType(Value.type,"")}
                    <Typography fontSize="1rem" text={Value.type} />
                  </StudentLandingTableColsTitle>
                )}
                <StudentLandingCell>
                  <LightTypography
                    text={Value.titleA || ""}
                    fontSize="0.75rem"
                  />
                </StudentLandingCell>
                <StudentLandingCell>
                  <LightTypography
                    text={Value.titleB || ""}
                    fontSize="0.75rem"
                  />
                </StudentLandingCell>
                <StudentLandingCell>
                  {role === "teacher" && (
                    <FlexAppContainer justifyContent="flex-end">
                      <MediumTypography
                        text={
                          Value.totalView
                            ? `${Value.totalView} Views`
                            : Value.totalSubmissions
                            ? `${Value.totalSubmissions} Submissions`
                            : `${TotalView[index]}`
                        }
                        color="#5DD3B3"
                        fontSize="0.75rem"
                      />
                      {LinkActions && (
                        <Link to={`${TitleLink[index]}`}>
                          {/* MappedHeadText && MappedHeadText[Qindex]
                                    ? MappedHeadText[Qindex]
                                    : "" */}
                          <PointerContainer>
                            <SvgSimpleArrow />
                          </PointerContainer>
                        </Link>
                      )}
                    </FlexAppContainer>
                  )}
                </StudentLandingCell>
              </StudentLandingTableLayoutRow>

              <StudentLandingSeparatedLine />

              {Value.Data &&
                openRows.includes(index) &&
                Value.Data.map((item: any, dataIndex: number) => (
                  <StudentLandingTableLayoutRow key={item.id}>
                    <StudentLandingCell>
                      <MediumTypography
                        text={item.title || ""}
                        fontSize="0.75rem"
                      />
                    </StudentLandingCell>
                    <StudentLandingCell>
                      <LightTypography
                        text={item.textA || ""}
                        fontSize="0.75rem"
                        color="#FAFAFA"
                      />
                    </StudentLandingCell>
                    <StudentLandingCell>
                      <LightTypography
                        text={item.textB || ""}
                        fontSize="0.75rem"
                        color="#FAFAFA"
                      />
                    </StudentLandingCell>
                    {role === "teacher" ? (
                      <StudentLandingCell>
                        <MediumTypography
                          text={
                            item.Viewed
                              ? `${item.Viewed} Resources`
                              : `${item.Submissions} Submitted`
                          }
                          fontSize="0.75rem"
                        />
                        {LinkActions && (
                          <Link to={`${TitleLink[index]}/${item.id}`}>
                            <PointerContainer>
                              <SvgSimpleArrow />
                            </PointerContainer>
                          </Link>
                        )}
                      </StudentLandingCell>
                    ) : (
                      <StudentLandingCell
                        style={{
                          justifyContent: "flex-end",
                        }}
                      >
                        {item.Viewed === ViewedProperty ? (
                          <MediumTypography
                            text="Viewed"
                            color="#5DD3B3"
                            fontSize="0.75rem"
                          />
                        ) : (
                          <MediumTypography
                            text="Not Viewed"
                            color="#D85D66"
                            fontSize="0.75rem"
                          />
                        )}
                      </StudentLandingCell>
                    )}
                  </StudentLandingTableLayoutRow>
                ))}
            </StudentLandingTableCols>
          </StudentLandingTableLayout>
        ))}
    </>
  );
};
