import styled from "styled-components";


//////// AnalyticsForumProgreesBar////////

export const AnalyticsForumContainer =styled.div`
width: 100%;
height:100%;
justify-content:center;
align-items:center;
display:flex;
flex-direction:column;
gap:1.25rem;
`;