import React, { useContext, useEffect, useState } from "react";
import { CreatePostContainer, CreatePostLine } from "./CreatePost.style";
// import { PostsTimeLineData } from "../PostsTimeLineData";
import { TextEditor } from "../../../elements/TextEditor/TextEditor";
import { DropZoneForm } from "../../../elements/dropZone/DropZoneForm";
import CancelButton from "../../../elements/CancelButton/CancelButton";
import { LightTypography } from "../../../elements/fonts/Fonts";
import {
  auth0SubIdSplitter,
  generateUUID,
  auth0ProviderSplitter,
  uuidSplitter,
} from "../../../utils/StringManipulation";
import { S3Upload } from "../../../services/S3Put";
import { useAuth0 } from "@auth0/auth0-react";
import { get, post, put } from "../../../utils/AxiosRequests";
import TinyEditor from "../../../elements/tinyEditor/TinyEditor";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";

interface CreatePostInterface {
  setshowAddPost?: React.Dispatch<React.SetStateAction<boolean>>;
  forumId: number;
  PostsTimeLineData: any;
  fetchData?: () => void;
  PostText: string;
  setPostText: any;
  fileResponse: any;
  setFileResponse: any;
  editMode: boolean;
  setEditMode: any;
  postId: number;
  setPostId: any;
  setFileData: any;
  fileData: any;
}

const CreatePost = ({
  setshowAddPost,
  forumId,
  PostsTimeLineData,
  fetchData,
  PostText,
  setPostText,
  fileResponse,
  setFileResponse,
  fileData,
  setFileData,
  setEditMode,
  setPostId,
  postId,
  editMode,
}: CreatePostInterface) => {
  const { t } = useTranslation();
  const { progressChanged, setProgressChange } = useContext(ClassContext);

  const tenantName = localStorage.getItem("tenant");
  const { user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const showDropZoneForm =
    PostsTimeLineData.forumActivitiesData.AllowAttachment;
  // const [PostText, setPostText] = useState("");
  const [ErrorPostText, setErrorPostText] = useState("");
  // const [fileData, setFileData] = useState<File | null>(null); // Use File | null type
  // const [fileResponse, setFileResponse] = useState<any>(null);
  const filePath = `forum/submissions`;
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams();
  const classId = params.classId;
  const accF: { [key: string]: string[] } = {
    "image/jpeg": [".jpg", ".jpeg"],
    "application/pdf": [".pdf"],
    "image/png": [".png"],
    "image/webp": [".webp"],
  };

  // Set the Content of the post
  const handlePostTexChange = (value: any) => {
    setPostText(value);
    if (!editMode) {
      // Retrieve the quizAnswers array from local storage
      let forumAnswers = JSON.parse(
        localStorage.getItem("ForumResponse") || "[]"
      );

      // Find the index of the existing timer object
      const index = forumAnswers.findIndex(
        (item: any) =>
          item.classSourceId === classId &&
          item.subId === subId &&
          item.refId === forumId
      );

      if (index !== -1) {
        // Retrieve the existing localStudentResponse
        let existingResponse = forumAnswers[index].description || "";

        // Merge the new key-value pair with the existing localStudentResponse
        existingResponse = value;

        // Update the existing object with the merged localStudentResponse
        forumAnswers[index].description = existingResponse;
      } else {
        // Add a new object if it doesn't exist
        const data = {
          subId: subId,
          provider: provider,
          classSourceId: classId,
          refId: forumId,
          description: value,
        };
        forumAnswers.push(data);
      }

      // Save the updated array back to local storage
      localStorage.setItem("ForumResponse", JSON.stringify(forumAnswers));
    }
  };

  // Set The File of Post
  const handleFile = async (acceptedFiles: File[] | File) => {
    if (acceptedFiles) {
      setLoading(true);

      let selectedFile: File | null = null;
      if (Array.isArray(acceptedFiles)) {
        // If acceptedFiles is an array, use the first file
        setFileData(acceptedFiles[0]);
        selectedFile = acceptedFiles[0];
      } else {
        // If it's a single file, directly set it
        setFileData(acceptedFiles);
        selectedFile = acceptedFiles;
      }

      if (selectedFile) {
        // Upload the file to s3
        const name: string =
          selectedFile.name.split("\\").pop() || selectedFile.name;
        const fileNameUUid = `${generateUUID()}${name}`;
        const fileuplKey = `${filePath}/${fileNameUUid}`;
        try {
          const response = await S3Upload(
            filePath,
            name,
            fileNameUUid,
            selectedFile
          );
          if (response?.status === 200) {
            if (!editMode) {
              // Retrieve the quizAnswers array from local storage
              let forumAnswers = JSON.parse(
                localStorage.getItem("ForumResponse") || "[]"
              );

              // Find the index of the existing timer object
              const index = forumAnswers.findIndex(
                (item: any) =>
                  item.classSourceId === classId &&
                  item.subId === subId &&
                  item.refId === forumId
              );
              if (index !== -1) {
                // Retrieve the existing localStudentResponse
                let existingResponse = forumAnswers[index].file;

                // Merge the new key-value pair with the existing localStudentResponse
                existingResponse = fileuplKey;

                // Update the existing object with the merged localStudentResponse
                forumAnswers[index].file = existingResponse;
              } else {
                // Add a new object if it doesn't exist
                const data = {
                  subId: subId,
                  provider: provider,
                  classSourceId: classId,
                  refId: forumId,
                  file: fileuplKey,
                };
                forumAnswers.push(data);
              }

              // Save the updated array back to local storage
              localStorage.setItem(
                "ForumResponse",
                JSON.stringify(forumAnswers)
              );
            }
            setFileResponse(fileuplKey);
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  // This use Effect is for getting the response of the student from the local storage
  useEffect(() => {
    if (!editMode) {
      // Fetch and set the timer from local storage if it exists
      const forumAnswers = JSON.parse(
        localStorage.getItem("ForumResponse") || "[]"
      );
      // Find the index of the existing timer object
      const currentForumAnswer = forumAnswers.find(
        (item: any) =>
          item.classSourceId === classId &&
          item.subId === subId &&
          item.refId === forumId
      );
      console.log("Current assignment answer: ", currentForumAnswer);
      if (currentForumAnswer && currentForumAnswer.description) {
        setPostText(currentForumAnswer.description);
      } else {
        setPostText("");
      }

      if (currentForumAnswer && currentForumAnswer.file) {
        const fileObject: File = new File(
          [currentForumAnswer.file],
          uuidSplitter(currentForumAnswer.file).replace(
            /^(forum\/submissions\/)/,
            ""
          ),
          { type: "text/plain" }
        );
        console.log("File object ---->", fileObject);
        setFileData(fileObject);
        setFileResponse(currentForumAnswer.file);
      } else {
        setFileData(null);
        setFileResponse("");
      }
    }
  }, []);

  // Handle Cancel Button
  const handleCancel = () => {
    setshowAddPost && setshowAddPost(false);
    setEditMode(false);
    setFileResponse(null);
    setFileData(null);
    setPostId(0);
    setPostText("");
    // Retrieve the quizAnswers array from local storage
    let forumAnswers = JSON.parse(
      localStorage.getItem("ForumResponse") || "[]"
    );

    // Find the index of the existing timer object
    const index = forumAnswers.findIndex(
      (item: any) =>
        item.classSourceId === classId &&
        item.subId === subId &&
        item.refId === forumId
    );

    // If the object exists, remove it from the array
    if (index !== -1) {
      forumAnswers.splice(index, 1);
    }

    // Save the updated array back to local storage
    localStorage.setItem("ForumResponse", JSON.stringify(forumAnswers));
  };
  const handleNothing = () => {};

  // Handle Submit Button ( Create The Post)
  const handleSubmit = async () => {
    if (PostText.trim() === "") {
      setErrorPostText("You must Enter the text");
      return;
    }
    setLoading(true);

    // Initialize the data of the Post to be created
    const Data: any = {
      PostText: PostText,
      file: fileResponse || null,
      subId: subId,
      provider: provider,
      forumId: forumId,
    };
    const editData = {
      postId: postId,
      post: PostText,
      attachment: fileResponse || null,
    };
    const trackingData = {
      classSourceId: classId,
      length: "",
      percentage: 100,
      refId: forumId,
      type: "Forum",
      subId: subId,
      provider: provider,
    };
    try {
      // Retrieve the quizAnswers array from local storage
      let forumAnswers = JSON.parse(
        localStorage.getItem("ForumResponse") || "[]"
      );

      // Find the index of the existing timer object
      const index = forumAnswers.findIndex(
        (item: any) =>
          item.classSourceId === classId &&
          item.subId === subId &&
          item.refId === forumId
      );

      // If the object exists, remove it from the array
      if (index !== -1) {
        forumAnswers.splice(index, 1);
      }

      // Save the updated array back to local storage
      localStorage.setItem("ForumResponse", JSON.stringify(forumAnswers));
      if (editMode) {
        await put(`forum/post/update/${tenantName}`, editData);
      } else {
        await post(`forum/post/create/${tenantName}`, Data);
      }
    } catch (error) {
      console.log(error);
    }
    const trackinResponse = await post(
      `completionTracking/create/${tenantName}`,
      trackingData
    );
    setProgressChange(!progressChanged);
    fetchData && fetchData();
    setshowAddPost && setshowAddPost(false);
    setLoading(false);
    // Optionally, reset the form or close the modal
    // setshowAddPost && setshowAddPost(false);
  };

  return (
    <CreatePostContainer>
      {/* Text Editor Goes Here */}
      <TinyEditor
        onChange={handlePostTexChange}
        placeholder={t("general.Description")}
        initialValue={PostText}
        height="10rem"
      />
      <div style={{ marginTop: "-0.75rem" }}>
        {ErrorPostText && (
          <LightTypography
            text={ErrorPostText}
            color="#D85D66"
            fontSize="0.75rem"
          />
        )}
      </div>
      {showDropZoneForm && ( // Drop Zone Goes Here ( if the Forum allows attachments )
        <DropZoneForm
          text={t("course view.Click or Drag and Drop, to upload Image")}
          testDrop={handleFile}
          acceptedTypes={accF}
          initialFile={fileData ? fileData.name.toString() : undefined}
          setFileResponse={setFileResponse}
        />
      )}
      <CreatePostLine>
        <CancelButton
          onClickFunction={handleCancel}
          name={t("forms.Cancel")}
          borderColor="#D85D66"
          color="#D85D66"
        />
        {loading ? (
          <CancelButton
            onClickFunction={handleNothing}
            name={`${t("general.loading")}...`}
            backgroundColor="#5DD3B3"
            color="#fff"
          />
        ) : (
          <CancelButton
            onClickFunction={handleSubmit}
            name={t("general.Submit")}
            backgroundColor="#5DD3B3"
            color="#fff"
          />
        )}

        {/* <CancelButton
          onClickFunction={handleSubmit}
          name="Submit"
          backgroundColor="#5DD3B3"
          color="#fff"
        /> */}
      </CreatePostLine>
    </CreatePostContainer>
  );
};

export default CreatePost;
