import styled from "styled-components";

export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
width: 33rem;
margin: auto;
`
export const SvgCont = styled.div`
margin-top: 5rem;
margin-bottom: 1.32rem;
`
export const TextCont = styled.div`
display: flex;
flex-direction: column;
gap: 0.62rem;
margin-bottom: 1.25rem;
`
