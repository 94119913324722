import styled from "styled-components";

export const LandingPaymentWavesContainer =styled.div`
position: absolute;
transform: rotate(-1.5deg);
left: -4rem;
top: -3.5rem;
display: flex;
width: 115% !important;
/* overflow-x: hidden !important;  */
/* background-color:aqua; */
z-index:0;

`;