import {
  CourseDataInterface,
  TableTrInterface,
  TitleDataInterface,
} from "./attendanceAllSessionsInterface";

export const TitleData: TitleDataInterface[] = [

  {
    sessionId: 1,
    session: "Session 1",
    date: "Mon, 16 Feb",
    time: "12 PM - 1 PM",
  },
  {
    sessionId: 2,

    session: "Session 2",
    date: "Mon, 17 Feb",
    time: "12 PM - 1 PM",
  },
  {
    sessionId: 3,
    session: "Session 3",
    date: "Mon, 18 Feb",
    time: "12 PM - 1 PM",
  },
  {
    sessionId: 4,
    session: "Session 4",
    date: "Mon, 19 Feb",
    time: "12 PM - 1 PM",
  },
  {
    sessionId: 5,
    session: "Session 5",
    date: "Mon, 16 Feb",
    time: "12 PM - 1 PM",
  },
  {
    sessionId: 6,
    session: "Session 6",
    date: "Mon, 16 Feb",
    time: "12 PM - 1 PM",
  },
  {
    sessionId: 7,
    session: "Session 7",
    date: "Mon, 16 Feb",
    time: "12 PM - 1 PM",
  },
  {
    sessionId: 8,
    session: "Session 8",
    date: "Mon, 16 Feb",
    time: "12 PM - 1 PM",
  },
  {
    sessionId: 9,
    session: "Session 9",
    date: "Mon, 16 Feb",
    time: "12 PM - 1 PM",
  },
];
export const CourseData: CourseDataInterface = {
  courseName: "Computer Science",
  semester: "Spring 2023 /2024",
};

export const TableTrData: TableTrInterface[] = [
  {
    Name: "Lana Mohammad",
    color: "#5DD3B3",
    img: require("../../assets/NourInstructor.jpg"),
    sessions: [
      { sessionName: "session 1 ", status: "Present" },
      { sessionName: "session 2", status: "Present" },
      { sessionName: "session 3", status: "Present" },
      { sessionName: "session 4", status: "Present" },
      { sessionName: "session 5", status: "Present" },
      { sessionName: "session 6", status: "Present" },
      { sessionName: "session 7", status: "Present" },
      { sessionName: "session 8", status: "Present" },
      { sessionName: "session 9", status: "Present" },
     
    ],
  },
  {
    Name: "Tariq Al Rajihi",
    color: "#5DD3B3",
    img: require("../../assets/AliAhmad.png"),
    sessions: [
      { sessionName: "session 1 ", status: "Present" },
      { sessionName: "session 2", status: "Present" },
      { sessionName: "session 3", status: "Present" },
      { sessionName: "session 4", status: "Present" },
      { sessionName: "session 5", status: "Present" },
      { sessionName: "session 6", status: "Present" },
      { sessionName: "session 7", status: "Present" },
      { sessionName: "session 8", status: "Present" },
      { sessionName: "session 9", status: "Present" },
      
    ],
  },

  {
    Name: "Ghassan ",
    color: "#5DD3B3",
    img: require("../../assets/KhaledInstructor.jpg"),
    sessions: [
      { sessionName: "session 1 ", status: "Present" },
      { sessionName: "session 2", status: "Present" },
      { sessionName: "session 3", status: "Present" },
      { sessionName: "session 4", status: "Present" },
      { sessionName: "session 5", status: "Present" },
      { sessionName: "session 6", status: "Present" },
      { sessionName: "session 7", status: "Present" },
      { sessionName: "session 8", status: "Present" },
      { sessionName: "session 9", status: "Present" },
  
    ],
  },
  {
    Name: "Jana",
    color: "#5DD3B3",
    img: require("../../assets/AyaInstructor.jpg"),
    sessions: [
      { sessionName: "session 1 ", status: "Present" },
      { sessionName: "session 2", status: "Present" },
      { sessionName: "session 3", status: "Present" },
      { sessionName: "session 4", status: "Present" },
      { sessionName: "session 5", status: "Present" },
      { sessionName: "session 6", status: "Present" },
      { sessionName: "session 7", status: "Present" },
      { sessionName: "session 8", status: "Present" },
      { sessionName: "session 9", status: "Present" },
    
    ],
  },
  {
    Name: "Tamer",
    color: "#5DD3B3",
    img: require("../../assets/KhaledInstructor.jpg"),
    sessions: [
      { sessionName: "session 1 ", status: "Present" },
      { sessionName: "session 2", status: "Present" },
      { sessionName: "session 3", status: "Present" },
      { sessionName: "session 4", status: "Present" },
      { sessionName: "session 5", status: "Present" },
      { sessionName: "session 6", status: "Present" },
      { sessionName: "session 7", status: "Present" },
      { sessionName: "session 8", status: "Present" },
      { sessionName: "session 9", status: "Present" },
     
    ],
  },

  {
    Name: "Gina",
    color: "#5DD3B3",
    img: require("../../assets/NourInstructor.jpg"),
    sessions: [
      { sessionName: "session 1 ", status: "Present" },
      { sessionName: "session 2", status: "Present" },
      { sessionName: "session 3", status: "Present" },
      { sessionName: "session 4", status: "Present" },
      { sessionName: "session 5", status: "Present" },
      { sessionName: "session 6", status: "Present" },
      { sessionName: "session 7", status: "Present" },
      { sessionName: "session 8", status: "Present" },
      { sessionName: "session 9", status: "Present" },
      
    ],
  },
  {
    Name: "Lamis",
    color: "#5DD3B3",
    img: require("../../assets/NourInstructor.jpg"),
    sessions: [
      { sessionName: "session 1 ", status: "Present" },
      { sessionName: "session 2", status: "Present" },
      { sessionName: "session 3", status: "Present" },
      { sessionName: "session 4", status: "Present" },
      { sessionName: "session 5", status: "Present" },
      { sessionName: "session 6", status: "Present" },
      { sessionName: "session 7", status: "Present" },
      { sessionName: "session 8", status: "Present" },
      { sessionName: "session 9", status: "Present" },
      
    ],
  },
  {
    Name: "Tala",
    color: "#5DD3B3",
    img: require("../../assets/AyaInstructor.jpg"),
    sessions: [
      { sessionName: "session 1 ", status: "Present" },
      { sessionName: "session 2", status: "Present" },
      { sessionName: "session 3", status: "Present" },
      { sessionName: "session 4", status: "Present" },
      { sessionName: "session 5", status: "Present" },
      { sessionName: "session 6", status: "Present" },
      { sessionName: "session 7", status: "Present" },
      { sessionName: "session 8", status: "Present" },
      { sessionName: "session 9", status: "Present" },
    
    ],
  },

  {
    Name: "Gaby",
    color: "#5DD3B3",
    img: require("../../assets/AliAhmad.png"),
    sessions: [
      { sessionName: "session 1 ", status: "Present" },
      { sessionName: "session 2", status: "Present" },
      { sessionName: "session 3", status: "Present" },
      { sessionName: "session 4", status: "Present" },
      { sessionName: "session 5", status: "Present" },
      { sessionName: "session 6", status: "Present" },
      { sessionName: "session 7", status: "Present" },
      { sessionName: "session 8", status: "Present" },
      { sessionName: "session 9", status: "Present" },
      
    ],
  },
  {
    Name: "Loris",
    color: "#5DD3B3",
    img: require("../../assets/KhaledInstructor.jpg"),
    sessions: [
      { sessionName: "session 1 ", status: "Present" },
      { sessionName: "session 2", status: "Present" },
      { sessionName: "session 3", status: "Present" },
      { sessionName: "session 4", status: "Present" },
      { sessionName: "session 5", status: "Present" },
      { sessionName: "session 6", status: "Present" },
      { sessionName: "session 7", status: "Present" },
      { sessionName: "session 8", status: "Present" },
      { sessionName: "session 9", status: "Present" },
   
    ],
  },
  {
    Name: "Ahmad",
    color: "#5DD3B3",
    img: require("../../assets/AliAhmad.png"),
    sessions: [
      { sessionName: "session 1 ", status: "Present" },
      { sessionName: "session 2", status: "Late" },
      { sessionName: "session 3", status: "Present" },
      { sessionName: "session 4", status: "Present" },
      { sessionName: "session 5", status: "Late" },
      { sessionName: "session 6", status: "Present" },
      { sessionName: "session 7", status: "Present" },
      { sessionName: "session 8", status: "Late" },
      { sessionName: "session 9", status: "Present" },
      
    ],
  },

  {
    Name: "Kholoud",
    color: "#5DD3B3",
    img: require("../../assets/NourInstructor.jpg"),
    sessions: [
      { sessionName: "session 1 ", status: "Present" },
      { sessionName: "session 2", status: "Late" },
      { sessionName: "session 3", status: "Present" },
      { sessionName: "session 4", status: "Present" },
      { sessionName: "session 5", status: "Late" },
      { sessionName: "session 6", status: "Present" },
      { sessionName: "session 7", status: "Present" },
      { sessionName: "session 8", status: "Present" },
      { sessionName: "session 9", status: "Late" },
     
    ],
  },
  {
    Name: "Loaay",
    color: "#5DD3B3",
    img: require("../../assets/KhaledInstructor.jpg"),
    sessions: [
      { sessionName: "session 1 ", status: "Present" },
      { sessionName: "session 2", status: "Absent" },
      { sessionName: "session 3", status: "Present" },
      { sessionName: "session 4", status: "Absent" },
      { sessionName: "session 5", status: "Present" },
      { sessionName: "session 6", status: "Absent" },
      { sessionName: "session 7", status: "Absent" },
      { sessionName: "session 8", status: "Present" },
      { sessionName: "session 9", status: "Present" },
    
    ],
  },
  {
    Name: "Saiid",
    color: "#5DD3B3",
    img: require("../../assets/AliAhmad.png"),
    sessions: [
      { sessionName: "session 1 ", status: "Present" },
      { sessionName: "session 2", status: "Present" },
      { sessionName: "session 3", status: "Present" },
      { sessionName: "session 4", status: "Present" },
      { sessionName: "session 5", status: "Present" },
      { sessionName: "session 6", status: "Present" },
      { sessionName: "session 7", status: "Present" },
      { sessionName: "session 8", status: "Present" },
      { sessionName: "session 9", status: "Present" },
    
    ],
  },

  {
    Name: "Ali",
    color: "#5DD3B3",
    img: require("../../assets/KhaledInstructor.jpg"),
    sessions: [
      { sessionName: "session 1 ", status: "Present" },
      { sessionName: "session 2", status: "Present" },
      { sessionName: "session 3", status: "Present" },
      { sessionName: "session 4", status: "Present" },
      { sessionName: "session 5", status: "Present" },
      { sessionName: "session 6", status: "Present" },
      { sessionName: "session 7", status: "Present" },
      { sessionName: "session 8", status: "Present" },
      { sessionName: "session 9", status: "Present" },
    
    ],
  },
];
