import styled from 'styled-components'
import { StyleThemeInterface } from '../../../interfaces/StyleThemeInterface';

//new
export const Container = styled.div<{theme?:string}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  height: 60vh;

  
`;
export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const LabelDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const TaskTextArea = styled.textarea<StyleThemeInterface>`
  min-width: 100%;
  min-height: 10.75rem;
  background: transparent;
  border-radius: 0.625rem;
  border: 1px solid ${props =>props.bordercolor || "#576371"};
  padding: 0.94rem 1.25rem;
  font-family: "Primary Font-Bold";
  /* font-size: 0.875rem; */
  color:${props =>props.color};
resize:none;
  &:focus {
    outline: none;
    caret-color: ${props =>props.color};;
  }
`;
export const ButtonsDiv = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 0.62rem;
margin-bottom: 0.2rem;
`;
