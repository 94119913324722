import React from "react";
import {
  ForumParticipationActionBox,
  ForumParticipationActionCell,
} from "../ForumParticipationDetails/ForumParticipationDetailsTable/ForumParticipationDetailsTable.style";
import { SvgCalendarIcon, SvgEmail } from "../../../elements/Icons";
import { MediumTypography } from "../../../elements/fonts/Fonts";
interface AanlyticsMenueThreeOptionsInterface {
  handleSaveDueDate?: () => void;
  handleSaveCutOffDate?: () => void;
  handleSendMessage?: () => void;
  ShowMessageLabel?: boolean;
}
const AanlyticsMenueThreeOptions = ({
  handleSaveDueDate,
  handleSaveCutOffDate,
  handleSendMessage,
  ShowMessageLabel = true,
}: AanlyticsMenueThreeOptionsInterface) => {
  return (
    <ForumParticipationActionBox>
      {ShowMessageLabel && (
        <ForumParticipationActionCell onClick={handleSendMessage}>
          <SvgEmail width={"1.3125rem"} height={"1rem"} />
          <MediumTypography text="Send FeedBack" fontSize="0.75rem" />
        </ForumParticipationActionCell>
      )}
      <ForumParticipationActionCell onClick={handleSaveDueDate}>
        <SvgCalendarIcon />
        <MediumTypography text="Extend Due Date" fontSize="0.75rem" />
      </ForumParticipationActionCell>
      <ForumParticipationActionCell onClick={handleSaveCutOffDate}>
        <SvgCalendarIcon />
        <MediumTypography text="Extend Cut Off Date" fontSize="0.75rem" />
      </ForumParticipationActionCell>
    </ForumParticipationActionBox>
  );
};

export default AanlyticsMenueThreeOptions;
