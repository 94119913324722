import React, { FC, useEffect, useState } from "react";
import {
  RatingContainer,
  Header,
  RatingContentContainer,
  CriteriaContainer,
  MarkContainer,
  MarkScaleContainer,
  ScaleContainer,
  ScaleContentNumber,
  ScaleStructureNumber,
  TableCell,
} from "./RatingSection.style";
import {
  TypographyRegularDescription,
  LightTypography,
  Typography,
} from "../../../../elements/fonts/Fonts";
import { theme } from "../../../../theme";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  TableColorSelector,
} from "../../../../recoil/ThemeSelectors";
interface RatingSectionInterface {
  selectedRubricCell: any[];
  choosedCell?:any[]
}

const RatingSection = ({ selectedRubricCell,choosedCell }: RatingSectionInterface) => {
  const { t } = useTranslation();
  const { Table_Head_TR } = useRecoilValue(TableColorSelector);
  const { RatingRubricTrackColor, theme, backgroundColor } =
    useRecoilValue(ColorSelector);

    const [cells, setCells] = useState<any[]>([]);

    useEffect(()=>{
      setCells(choosedCell || [])
    },[choosedCell])

  return (
    <RatingContainer bgcolor={backgroundColor}>
      <Header theme={theme}>
        <TableCell>
          <Typography text={t("Rubric.Criteria")} fontSize="0.875rem" />
        </TableCell>
        <TableCell>
          <Typography text={t("Rubric.Level")} fontSize="0.875rem" />
        </TableCell>
      </Header>
      {cells && cells.length > 0 ? cells.map((item: any, index: number)=>(
        <RatingContentContainer key={index}>
        <CriteriaContainer>
          <TypographyRegularDescription
            text={item.criteria}
            fontSize="0.75rem"
          />
        </CriteriaContainer>

        <MarkScaleContainer>
          <MarkContainer>
            <TypographyRegularDescription
              text={item.level}
              fontSize="0.75rem"
            />
            <TypographyRegularDescription
              text={`${item.weight} %`}
              fontSize="0.75rem"
              color={
                item.weight === 100
                  ? "#5DD3B3"
                  : item.weight >= 50
                  ? "#0B8FFF"
                  : "#D85D66"
              }
            />
          </MarkContainer>
          <ScaleContainer bgcolor={RatingRubricTrackColor}>
            <ScaleStructureNumber
              style={{
                width: `${item.weight}%`,
                height: "0.5rem",
                backgroundColor:
                  item.weight === 100
                    ? "#5DD3B3"
                    : item.weight >= 50
                    ? "#0B8FFF"
                    : "#D85D66",
              }}
            ></ScaleStructureNumber>
          </ScaleContainer>
        </MarkScaleContainer>
      </RatingContentContainer>
      ))
      :selectedRubricCell &&
      selectedRubricCell.map((item: any, index: number) => (
        <RatingContentContainer key={index}>
          <CriteriaContainer>
            <TypographyRegularDescription
              text={item.criteria}
              fontSize="0.75rem"
            />
          </CriteriaContainer>

          <MarkScaleContainer>
            <MarkContainer>
              <TypographyRegularDescription
                text={item.level}
                fontSize="0.75rem"
              />
              <TypographyRegularDescription
                text={`${item.weight} %`}
                fontSize="0.75rem"
                color={
                  item.weight === 100
                    ? "#5DD3B3"
                    : item.weight >= 50
                    ? "#0B8FFF"
                    : "#D85D66"
                }
              />
            </MarkContainer>
            <ScaleContainer bgcolor={RatingRubricTrackColor}>
              <ScaleStructureNumber
                style={{
                  width: `${item.weight}%`,
                  height: "0.5rem",
                  backgroundColor:
                    item.weight === 100
                      ? "#5DD3B3"
                      : item.weight >= 50
                      ? "#0B8FFF"
                      : "#D85D66",
                }}
              ></ScaleStructureNumber>
            </ScaleContainer>
          </MarkScaleContainer>
        </RatingContentContainer>
      ))}
    </RatingContainer>
  );
};

export default RatingSection;
