import React from 'react';
import { SvgUserProfile } from '../Icons';
import { ImageContainer } from './ProfileImg.style';
import { ImgProfileImage } from '../Images/Images';

interface ProfileImgInterface {
  imgSrc?: string;
  Size?: string;
  gender?: string;
  border?: string;
}


const ProfileImgHolder = ({ imgSrc, Size = "100%", gender, border }: ProfileImgInterface) => {



  return (
<ImageContainer border={border} width={Size} height={Size} src={imgSrc || ImgProfileImage} alt="img" />

  );
};

export default ProfileImgHolder;
