import { createGlobalStyle, styled } from "styled-components";
import { StyleThemeInterface } from "./interfaces/StyleThemeInterface";

const GlobalStyle = createGlobalStyle<StyleThemeInterface>`
  *{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  }
  body{
    background:${(props) => props.bgcolor};
  }
`;
export default GlobalStyle;

export const BackArrow = styled.div`
  &:dir(ltr) {
    transform: rotate(0deg);
  }
  &:dir(rtl) {
    transform: rotate(180deg);
  }
`;

export const FlexDiv = styled.div<{ gap?: string; pointer?: string }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : "0.5rem")};
  cursor: ${({ pointer }) => (pointer === "true" ? "pointer" : "default")};
`;
export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
