import React, { useState } from "react";
import {
  SwitchContainer,
  SwitchLabel,
  SwitchInput,
  Slider,
} from "./toogle.style";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

type ToggleProps = {
  onToggle: (isChecked: boolean) => void;
  checked?: boolean;
  id: string; // Ensure that 'id' is properly defined in the type
};

const Toggle: React.FC<ToggleProps> = ({ onToggle, checked, id }) => {
  // const [isChecked, setIsChecked] = useState(false);
  const { toggleGradientColor, theme } = useRecoilValue(ColorSelector);

  const handleToggle = () => {
    // setIsChecked(!isChecked);
    onToggle(!checked);
  };

  return (
    <SwitchContainer>
      <SwitchInput
        type="checkbox"
        id={id}
        checked={checked ? checked : false}
        onChange={handleToggle}
      />
      <SwitchLabel htmlFor={id} togglegradientcolor={toggleGradientColor} >
        <Slider
          ischecked={checked ? checked : false}
          theme={theme}
          // style={{
          //   transform:
          //      checked ? "translateX(37px)" : "translateX(0)",
          // }}
        />
      </SwitchLabel>
    </SwitchContainer>
  );
};

export default Toggle;
