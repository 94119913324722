import { CourseNotificationInterface } from "../CourseNotificationIterface";

export const CourseNotificationTeacherData: CourseNotificationInterface[] = [ 
  {title:"User Profile.On Platform",
  titleValue:"On Platform",
Data:[
  {id:"SDefrgt",text:"User Profile.Notify me about gradable items", value:"Notify me about gradable items"},
  {id:"wrfgrew3",text:"User Profile.Notify me about new announcements", value:"Notify me about new announcements"},
  {id:"werfg4d6y5t",text:"User Profile.Notify me when new calendar events have been added", value:"Notify me when new calendar events have been added"},
  {id:"efrty6tr4y6u7",text:"User Profile.Notify me about low course overall grade", value:"Notify me about low course overall grade"},
  {id:"tyu6i7u6yt",text:"User Profile.Notify me when students missed due dates", value:"Notify me when students missed due dates"},
  {id:"SDefwdertrrfdwqrgt",text:"User Profile.Notify me when new discussion post have been added", value:"Notify me when new discussion post have been added"},
  {id:"SDedafrgthyuiu7yfrgt",text:"User Profile.Notify me when a student is at risk", value:"Notify me when a student is at risk"},
  {id:"dfgtyrf7iuy5rt",text:"User Profile.Display recent updates, additions, and modifications to resources and activities", value:"Display recent updates, additions, and modifications to resources and activities"},

],
},
{
title: "User Profile.By Email",
titleValue: "By Email",
Data: [
  {id:"SDefefrt5rdgt",text:"User Profile.Notify me by email about new announcements", value:"Notify me by email about new announcements"},
  {id:"fghytgfsd",text:"User Profile.Notify me by email when students missed due dates", value:"Notify me by email when students missed due dates"},
  {id:"dfgtrhyyg",text:"User Profile.Notify me by email when a student is at risk", value:"Notify me by email when a student is at risk"},
],
},
]