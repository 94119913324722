// import styled from "styled-components"
import styled, { keyframes } from "styled-components";
import { breakpoints } from "../../theme";

const landingPageBg = require("../../assets/landingPageBg.jpg");

export const Container = styled.div`
  background-color: rgba(0, 0, 0);
  width: 100%;
  height: 56.8125rem;
  min-height: 100%;
  min-width: 56.8125rem;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
`;
export const ImageHolder = styled.div`
  width: 100%;
  height: 100%;
`;
export const ImageStyled = styled.div`
  background: url(${landingPageBg}) lightgray 50% / cover no-repeat;
  opacity: 0.3;
  background-size: cover;
  background-position: 90% 60%;
  min-height: 100%;
  min-width: 56.8125rem;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
`;

const waveAnimation = keyframes`
 0% {
    transform: translateX(0) translateY(0);
  }
  25% {
    transform: translateX(-5rem) translateY(0);
  }
  50% {
    transform: translateX(0) translateY(-5rem);
  }
  75% {
    transform: translateX(0) translateY(5rem);
  }
  100% {
    transform: translateX(0) translateY(0);
  }
`;
export const WavesWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: -0.5rem;
  left: -3rem;
  animation: ${waveAnimation} 8s cubic-bezier(0.42, 0, 0.58, 1) infinite;
`;
export const SingleWaveWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 16rem;
  left: 13rem;
`;
export const WhiteWrapperWelcome = styled.div`
  display: flex;
  align-items: end;
`;
export const WhiteWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 12.513%;
  display: flex;
  justify-content: center;
  z-index: 2;
  background: #fff;
  box-shadow: 0px 4px 14px 0px rgba(182, 182, 182, 0.25);
  min-height: 100%;
  min-width: 26.8125rem;
  width: 24.178%;
  height: 56.25rem;
  overflow: hidden;

  & > :last-child {
    position: absolute;
    z-index: -1;
    top: -10rem;
    left: -59rem;
    transform: rotate(90deg);
    opacity: 0.3;
  }

  @media screen and (max-width: 800px) {
    /* Adjust styles for mobile screens */
    right: 30%; /* Example: Reducing the right spacing */
    width: 15%; /* Example: Making the width 90% of the screen */
    
  }
  @media screen and (max-width: 640px) {
    /* Adjust styles for mobile screens */
    right: 40%; /* Example: Reducing the right spacing */
    
    
  }
  @media screen and (max-width: 550px) {
    /* Adjust styles for mobile screens */
    right: 45%; /* Example: Reducing the right spacing */
    width: 10%;
    
  }
  @media screen and (max-width: 510px) {
    /* Adjust styles for mobile screens */
    right: 50%; /* Example: Reducing the right spacing */
    min-width:25rem !important;    
  }
  @media screen and (max-width: 460px) {
    /* Adjust styles for mobile screens */
    right: 53%; /* Example: Reducing the right spacing */
    min-width:25rem !important;    
  }
  @media screen and (max-width: 440px) {
    /* Adjust styles for mobile screens */
    right: 60%; /* Example: Reducing the right spacing */
    min-width:20rem !important;    
  }
  @media screen and (max-width: 375px) {
    /* Adjust styles for mobile screens */
    right: 62%; /* Example: Reducing the right spacing */
  }
  @media screen and (max-width: 345px) {
    /* Adjust styles for mobile screens */
    right: 64%; /* Example: Reducing the right spacing */
    min-width: 22rem;
  }
  @media screen and (max-width: 335px) {
    /* Adjust styles for mobile screens */
    right: 66%; /* Example: Reducing the right spacing */
    min-width: 18rem !important;
  }
  @media screen and (max-width: 310px) {
    /* Adjust styles for mobile screens */
    right: 70%; /* Example: Reducing the right spacing */
    min-width: 15rem !important;
  }
  @media screen and (max-width: 275px) {
    /* Adjust styles for mobile screens */
    right: 72%; /* Example: Reducing the right spacing */
    min-width: 15rem !important;
  }
`;
export const BottomWelcomeWrapper = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 14.5%;
  left: 7%;
  color: #ffffff;
  height: 30%;
  width: 34%;
  @media screen and (max-width: 800px) {
    display: none;
    
  }
`;
export const BottomLeftLine = styled.div`
  background: linear-gradient(180deg, #28edb2 0%, #23dde5 100%);
  width: 10.5rem;
  height: 0.1875rem;
  position: absolute;
  bottom: 10%;
`;
export const FormWrapper = styled.div`
  position: absolute;
  top: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  & > :first-child {
    width: 6.8995rem;
    height: 7.02269rem;
    margin-bottom: 3.1rem;
  }
`;
export const BottomLogo = styled.div`
  position: absolute;
  bottom: 11.44rem;
  right: 2.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.94rem;
  color: #576474;

  & > :first-child {
    margin-bottom: -2.5rem;
  }
`;
