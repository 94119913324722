import { FC } from "react";
import {
  MenuWrapper,
  Frame,
  TextWithIconWrapper,
} from "./DropDownEditDelete.style";
import { DropDownDataInterface } from "../dropDownDataNew";
import { PrimaryFontMediumText } from "../../../elements/fonts/Fonts";
import { useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "../../../contexts/ContextProvider";
import { SchemeGroupInterface } from "../../schemeGroup/schemeGroupInterface";
import {
  GroupCardDataInterface,
  GroupStudent,
} from "../../groupCardNew/groupCardNewInterface";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

interface DropDownEditDeleteProps {
  setShowDeleteScheme: (showDeleteScheme: boolean) => void;
  setShowEditScheme: (showEditScheme: boolean) => void;
  setShowDropDown: (showDropDown: boolean) => void;
  schemeId: number | string;
  scheme: SchemeGroupInterface;
  selectedClassId?: any;
}

const DropDownEditDeleteScheme: FC<DropDownEditDeleteProps> = ({
  setShowDeleteScheme,
  setShowEditScheme,
  setShowDropDown,
  schemeId,
  scheme,
  selectedClassId,
}) => {
  const {
    groups,
    setGroups,
    schemes,
    setSchemes,
    setSchemeDescription,
    setSchemeTitle,
    setIsPopUpOpen,
  } = useStateContext();
  const params = useParams();
  const navigate = useNavigate();
  const classId = params.classId;
  const { bg_selectColor, boxShadow } = useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  return (
    <MenuWrapper boxShadow={boxShadow} bgColor={bg_selectColor}>
      {DropDownDataInterface.map((item: any, index: number) => (
        <Frame
          key={index}
          style={{
            borderRadius: `${
              index === 0
                ? "0.3125rem 0.3125rem 0rem 0rem"
                : index === DropDownDataInterface.length - 1
                ? " 0rem 0rem 0.3125rem 0.3125rem"
                : ""
            }`,
          }}
        >
          <TextWithIconWrapper
            onClick={() => {
              if (index === 2) {
                setShowDeleteScheme(true);
              } else if (index === 1) {
                setShowEditScheme(true);
                setIsPopUpOpen(true);
                setSchemeTitle("");
                setSchemeDescription("");
                setShowDropDown(false);
              } else {
                navigate(`/group-creator/${schemeId}/${selectedClassId}`, {
                  state: { classId },
                });
                setShowDropDown(false);
              }
            }}
          >
            <item.icon />
            <PrimaryFontMediumText text={t(item.name)} fontSize=" 0.875rem" />
          </TextWithIconWrapper>
        </Frame>
      ))}
    </MenuWrapper>
  );
};

export default DropDownEditDeleteScheme;
