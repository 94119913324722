import { BlueButtonStyle } from './BlueButton.style';
import { Typography } from '../fonts/Fonts';

interface BlueButtonInterface {
  text: string;
  fontSize?: string;
  Width?: string;
  Height?: string;
  Padding?: string;
  Border?: string;
  BackColor?: string;
  Color?: string;
  disabled?: boolean
  OnClick?: () => void; // Updated the type for OnClick
}

const BlueButton = ({
  text,
  fontSize = '0.9375rem',
  Width,
  Height,
  Padding,
  Border = 'none',
  BackColor,
  Color="#fff",
  OnClick, // Add OnClick as a prop
  disabled = false
}: BlueButtonInterface): JSX.Element => {
  return (
    <BlueButtonStyle
      width={Width}
      height={Height}
      padding={Padding}
      BorderColor={Border}
      BKGColor={BackColor}
      onClick={OnClick} 
      Color={Color}
      disabled={disabled || false}
    >
      <Typography
        text={text}
        fontSize={fontSize || '0.9375rem'}
        lineHeight="normal"
        fontWeight="400"
      />
    </BlueButtonStyle>
  );
};

export default BlueButton;
