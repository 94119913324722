import React from "react";
import { SvgEmpty, SvgNoResult } from "../../elements/Icons";
import { Typography } from "../../elements/fonts/Fonts";
import { MainEmptyDiv } from "./Empty.style";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { CourseColorSelector } from "../../recoil/ThemeSelectors";

const Empty = ({
  backgroundColor,
  text,
  marginRight = "1.3rem",
}: {
  backgroundColor?: string;
  text?: string;
  marginRight?: string;
}) => {
  const { t } = useTranslation();
  const { ClassGradientBox } = useRecoilValue(CourseColorSelector);
  return (
    <MainEmptyDiv
      style={{
        background: backgroundColor || ClassGradientBox,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <SvgNoResult width={"10rem"} />
        <div style={{ marginRight: marginRight }}>
          <Typography text={text || t("view all.Nothing here yet")} />
        </div>
      </div>
    </MainEmptyDiv>
  );
};

export default Empty;
