import styled from "styled-components";

interface MainHolderProps {
  inputwidth: string;
}
interface InputProps {
  inputheight: string;
  inputborderradius: string;
  inputborder: string;
  padingleft: string;
  holderWidth?: string;
  color?: string;
  padding?: string;
}
interface SearchIconHolderProps {
  display: string;
  right: string;
  left?: string;
}

export const MainHolder = styled.div<MainHolderProps>`
  position: relative;
  width: ${({ inputwidth }) => inputwidth};
`;
export const InputHolder = styled.input<InputProps>`
  height: ${({ inputheight }) => inputheight};
  border-radius: ${({ inputborderradius }) => inputborderradius};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${({ holderWidth }) => holderWidth};
  border: ${({ inputborder }) => inputborder};
  /* opacity: 0.5; */
  background-color: transparent;
  color: ${(props) => props.color};
  /* &:dir(ltr) {
    padding-left: ${({ padingleft }) => padingleft};
  }
  &:dir(rtl) {
    padding-right: ${({ padingleft }) => padingleft};
  } */
  padding: 0 ${({ padingleft }) => padingleft};
  &:focus {
    outline: none;
    caret-color: ${(props) => props.color};
  }
  &::placeholder {
    color: ${(props) => props.color};
    opacity: 0.5;
  }
`;

export const SearchIconHolder = styled.div<SearchIconHolderProps>`
  position: absolute;
  top: 0.88rem;
  right: ${({ right }) => right || "1.25rem"};
  left: ${({ left }) => left || "0rem"};
  color: #576371;
  width: min-content;
  display: ${({ display }) => display};
`;
