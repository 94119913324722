import { FC, useState, useRef, useEffect, useContext } from "react";
import axios from "axios";
import {
  TopicsHolder,
  TopicContainer,
  TopicHeader,
  VDotsWrapper,
  ActivitiesWrapper,
  ContentsContainer,
} from "./Topic.style";
import { useStateContext } from "../../../contexts/ContextProvider";
import { PrimaryFontMediumText } from "../../../elements/fonts/Fonts";
import { SvgMoreVerticalIcon } from "../../../elements/Icons";
import DropDownEditDeleteTopic from "../../dropDownEditDeleteTopic/main/DropDownEditDelete";
import useToggle from "../../../hooks/useToggle";
import useClickOutside from "../../../hooks/useClickOutside";
import Content from "../../activity/main/Content";
import { TopicInterface } from "../../../Views/teacherView/Course-Creator/courseCreatorInterface";
import DropActivity from "../../courseCreatorDropActivity/DropActivity";
import { useDrop } from "react-dnd";
import PopupDelete from "../../../elements/PopupDelete/PopupDelete";
import {
  ContentProps,
  TopicProps,
} from "../../../Views/teacherView/Course-Creator/courseCreatorInterface";
import ReusableInput from "../../../elements/topicInputEdit/ReusableInput";
import { Draggable, Droppable } from "react-beautiful-dnd";

import CourseCreatorPopups from "../../courseCreatorPopups/CourseCreatorPopups";
import { useMyContext } from "../../../contexts/CourseCreatorContext";
import { useQuizMyContext } from "../../../contexts/CourseCreatorQuizContext";
import { useRecoilValue } from "recoil";
import { courseCreatorColorSelector } from "../../../recoil/ThemeSelectors";
import { WebSocketConnector } from "../../../Views/messages/webSocketConnection";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../../utils/StringManipulation";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import { useParams } from "react-router-dom";
import { AssignmentContext } from "../../../contexts/CourseCreatorAssignmentContext";
import Loader from "../../loader/Loader";
import { detectIconColor } from "../../../utils/ResourceIcon";
import { WebsocketContext } from "../../../contexts/notificationContext";
import { t } from "i18next";
import { ForumContext } from "../../../contexts/ForumContext";
import { toast } from "react-toastify";

/**
 * Topic component represents a single topic within a course module.
 * It contains a list of content items and supports adding, editing, and deleting content.
 * Users can also reorder content within a topic by dragging and dropping.
 *
 * @param topicIndex The index of the topic within its parent module.
 * @param topicId The unique identifier for the topic.
 * @param topicTitle The title of the topic.
 * @param topic The data and details of the topic.
 * @param contents The list of content items within the topic.
 * @param topics The list of all topics within the module.
 * @param removeTopic Function to remove the topic.
 * @param setTopics Function to update the list of topics.
 * @param setIsTopicIdInModule Function to set the topic ID within its parent module.
 * @param setIsTopicIndexInModule Function to set the topic index within its parent module.
 * @param module The parent module containing the topic.
 * @param isModuleDeleteClicked Boolean indicating whether the module deletion is clicked.
 * @param onAddOrDelete Function to reactivate the state of modules with all her new item updates.
 * @param activeGetModulesWithTopics Function to get the modules with their topics.
 * @param editTopic Function to edit the topic.
 * @param setTopicTitleInModule Function to set the topic title within its parent module.
 * @param newTopicTitle The new title for the topic.
 * @param moduleIndex The index of the parent module.
 * @param reorderedContentIndex Function to reorder content within the topic.
 */

const Topic: FC<TopicInterface> = ({
  topicIndex,
  topicId,
  topicTitle,
  topic,
  contents,
  topics,
  removeTopic,
  setTopics,
  setIsTopicIdInModule,
  setIsTopicIndexInModule,
  module,
  isModuleDeleteClicked,
  onAddOrDelete,
  activeGetModulesWithTopics,
  editTopic,
  setTopicTitleInModule,
  newTopicTitle,
  moduleIndex,
  reorderedContentIndex,
}) => {
  // Recoil state and selectors
  const { topicHeaderBgColor, lineIconColor } = useRecoilValue(
    courseCreatorColorSelector
  );

  // Context
  const {
    contentTitle,
    setContent_Id,
    setContent_IdToEdit,
    contentID,
    setContent_Type,
    setTopicRefId,
    contentType,
    getModules,
    isPopUpOpen,
    setIsPopUpOpen,
    setContent_Index,
    isContentDrop,
    setGlobalShowEditContent,
    contentGlobalState,
    showEditTopicPositionLoader,
    sourceIdTopic,
    eventContentId,
  } = useStateContext();

  // Toggle visibility of dropdown menu
  const { isOpen: isDropdownVisible, toggle: toggleDropdown } =
    useToggle(false);
  const dropdownRef = useClickOutside(isDropdownVisible, toggleDropdown);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  // Refs for continue clicked states in each form popup
  const [isAssignmentContinueClicked, setIsAssignmentContinueClicked] =
    useState<boolean>(false);
  const [isQuizContinueClicked, setIsQuizContinueClicked] =
    useState<boolean>(false);
  const [isOnlineSessionContinueClicked, setIsOnlineSessionContinueClicked] =
    useState<boolean>(false);
  const [isSurveyContinueClicked, setIsSurveyContinueClicked] =
    useState<boolean>(false);
  const [isForumContinueClicked, setIsForumContinueClicked] =
    useState<boolean>(false);
  const [isFileContinueClicked, setIsFileContinueClicked] =
    useState<boolean>(false);
  const [isDocumentContinueClicked, setIsDocumentContinueClicked] =
    useState<boolean>(false);
  const [isFolderContinueClicked, setIsFolderContinueClicked] =
    useState<boolean>(false);
  const [isLinkContinueClicked, setIsLinkContinueClicked] =
    useState<boolean>(false);
  const [isLinkTreeContinueClicked, setIsLinkTreeContinueClicked] =
    useState<boolean>(false);
  const [isVideoContinueClicked, setIsVideoContinueClicked] =
    useState<boolean>(false);
  const [isHtmlContinueClicked, setIsHtmlContinueClicked] =
    useState<boolean>(false);
  const [isPictureContinueClicked, setIsPictureContinueClicked] =
    useState<boolean>(false);
  const [isScormContinueClicked, setIsScormContinueClicked] =
    useState<boolean>(false);
  const [isTeamsContinueClicked, setIsTeamsContinueClicked] =
    useState<boolean>(false);


  // State for edit content modal
  const [showEditContent, setShowEditContent] = useState(false);

  /**
   * Handles showing or hiding the edit content modal within the topic.
   *
   * @param showEditContent Boolean indicating whether to show or hide the edit content modal.
   */
  const handleShowEditContentInTopic = (showEditContent: boolean) => {
    setShowEditContent(showEditContent);
    setGlobalShowEditContent(showEditContent);
  };

  /**
   * Handles showing or hiding the dropdown menu within the topic.
   *
   * @param showDropDown Boolean indicating whether to show or hide the dropdown menu.
   */
  const handleShowDropDown = (showDropDown: boolean) => {
    setShowDropDown(showDropDown);
  };

  // State for the array of content and their properties
  const [contentsState, setContentsState] = useState<ContentProps[]>(
    topic.contents
  );

  // Base URL for API requests
  const BaseURL =
    "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/class/module";

  // State for content and topic references
  const [contentState, setContentState] = useState<ContentProps | null>(null);
  const [contentIndex, setContentIndex] = useState<number>(0);
  const [topicRefIdSolution, setTopicRefIdSolution] = useState<number>();

  // State for various types of content drops
  const [isDrop, setIsDrop] = useState<boolean>(
    getModules().length != 0 ? true : false
  );

  const [isPopupAllDroped, setIsPopupAllDroped] = useState<boolean>(false);
  const [isAssignmentDrop, setIsAssignmentDrop] = useState<boolean>(false);
  const [isQuizDrop, setIsQuizDrop] = useState<boolean>(false);
  const [isOnlineSessionDrop, setIsOnlineSessionDrop] =
    useState<boolean>(false);
  const [isSurveyDrop, setIsSurveyDrop] = useState<boolean>(false);
  const [isForumDrop, setIsForumDrop] = useState<boolean>(false);
  const [isFileDrop, setIsFileDrop] = useState<boolean>(false);
  const [isDocumentDrop, setIsDocumentDrop] = useState<boolean>(false);
  const [isFolderDrop, setIsFolderDrop] = useState<boolean>(false);
  const [isLinkDrop, setIsLinkDrop] = useState<boolean>(false);
  const [isLinkTreeDrop, setIsLinkTreeDrop] = useState<boolean>(false);
  const [isVideoDrop, setIsVideoDrop] = useState<boolean>(false);
  const [isHtmlDrop, setIsHtmlDrop] = useState<boolean>(false);
  const [isPictureDrop, setIsPictureDrop] = useState<boolean>(false);
  const [isScormDrop, setIsScormDrop] = useState<boolean>(false);
  const [isTeamsDrop, setIsTeamsDrop] = useState<boolean>(false);
  // States to store content ID, index, and type
  const [isContentId, setIsContentId] = useState<number>();
  const [isContentIndex, setIsContentIndex] = useState<number>();
  const [isContentType, setIsContentType] = useState<string>();

  // State for handling delete and edit topic modals
  const [showDeleteTopic, setShowDeleteTopic] = useState(false);
  const [showEditTopic, setShowEditTopic] = useState(false);

  // Context for quizzes and surveys and Assignments
  const {
    globalShowPopup,
    setGlobalShowPopup,
    setGlobalDescription,
    setGlobalEndDate,
    setGlobalFromDate,
    setGlobalIsAnonymous,
    setGlobalIsTimeVisible,
    setGlobalIsVisible,
    setGlobalSelectedSurvey,
    setGlobalSelectedSurveyId,
    setGlobalStartDate,
    setGlobalSurveyName,
    setGlobalToDate,
    setEditSurvey,
    setGlobalSurveyDuration,
  } = useMyContext();

  const {
    globalQuizShowPopup,
    setEditQuiz,
    setEditQuizButton,
    setGlobalAttempt,
    setGlobalDuration,
    setGlobalMaxGrade,
    setGlobalPassingGrade,
    setGlobalQuizDescription,
    setGlobalQuizEndDate,
    setGlobalQuizFromDate,
    setGlobalQuizIsTimeVisible,
    setGlobalQuizIsVisible,
    setGlobalQuizName,
    setGlobalQuizShowPopup,
    setGlobalQuizStartDate,
    setGlobalQuizToDate,
    setGlobalSelectedQuiz,
    setGlobalSelectedQuizId,
    setGlobalShowGrade,
    setGlobalUnlimitedAttempts,
    setSelectedRubricId,
    // isPopupAllDroped,
    // setIsPopupAllDroped,
  } = useQuizMyContext();

  const {
    setSelectedRadio,
    setSelectedTitle,
    globalAssignmentShowPopup,
    setGlobalAssignmentShowPopup,
    setSelectedRule,
    setShowTimeVisibility,
    setShowSelectGrouping,
    setShowPlagiarismPrevention,
    setSelectedGrouping,
    setAssignmentName,
    setDescription,
    setMaxGrade,
    setStartDate,
    setDueDate,
    setCutOffDate,
    setPenalty,
    setVisibleStudents,
    setFromDate,
    setToDate,
    setAnswerBoxChecked,
    setFileSubmissionChecked,
    setFile,
    setUrlAttachment,
  } = AssignmentContext();

  const {
    globalForumShowPopup,
    setGlobalForumShowPopup,
    setForumAttach,
    setForumCutOffDate,
    setForumDescription,
    setForumDueDate,
    setForumEndTime,
    setForumName,
    setForumFromDate,
    setForumMaxGrade,
    setForumStartDate,
    setForumPenalty,
    setForumSelectedRadio,
    setForumShowGradingType,
    setForumShowTimeVisibility,
    setForumStartTime,
    setForumToDate,
    setForumVisibleStudents,

  } = ForumContext()

  // WEB Socket

  const { user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const params = useParams();
  const classId = params.id;
  const { isReady, value, send, connect } = useContext(WebsocketContext);
  const myEmail = user?.email;
  useEffect(() => {
    if (!isReady && tenantName !== null && tenantName !== undefined && subId !== null && subId !== undefined) {
      connect();
    }
  }, [subId, tenantName])

  /**
   * Handles showing or hiding the edit content modal within the topic.
   *
   * @param showEditContent Boolean indicating whether to show or hide the edit content modal.
   */
  const handleShowEditInTopic = (showEditTopic: boolean) => {
    setShowEditTopic(showEditTopic);
  };

  // Function to handle showing/hiding the delete topic popup
  const handleDeleteTopic = (showDeleteTopic: boolean) => {
    setShowDeleteTopic(showDeleteTopic);
    setIsPopUpOpen(true);
  };

  /**
   * Sets the content ID within the topic.
   *
   * @param contentId The ID of the content item.
   */
  const setIsContentIdInTopic = (contentId: number) => {
    setIsContentId(contentId);
  };

  /**
   * Sets the content index within the topic.
   *
   * @param contentIndex The index of the content item.
   */
  const setIsContentIndexInTopic = (contentIndex: number) => {
    setIsContentIndex(contentIndex);
  };

  /**
   * Sets the content type within the topic.
   *
   * @param contentType The type of the content item.
   */
  const setIsContentTypeInTopic = (contentType: string) => {
    setIsContentType(contentType);
  };

  // State for the new content title
  const [newContentTitle, setNewContentTitle] = useState<string | undefined>(
    isContentType
  );

  // References for continue clicked states
  const isAssignmentContinueClickedRef = useRef<boolean>();
  const isQuizContinueClickedRef = useRef<boolean>();
  const isOnlineSessionContinueClickedRef = useRef<boolean>();
  const isSurveyContinueClickedRef = useRef<boolean>();
  const isForumContinueClickedRef = useRef<boolean>();
  const isFileContinueClickedRef = useRef<boolean>();
  const isDocumentContinueClickedRef = useRef<boolean>();
  const isFolderContinueClickedRef = useRef<boolean>();
  const isLinkContinueClickedRef = useRef<boolean>();
  const isLinkTreeContinueClickedRef = useRef<boolean>();
  const isVideoContinueClickedRef = useRef<boolean>();
  const isHtmlContinueClickedRef = useRef<boolean>();
  const isPictureContinueClickedRef = useRef<boolean>();
  const isScormContinueClickedRef = useRef<boolean>();
  const isTeamsContinueClickedRef = useRef<boolean>();

  // Effect to update the references for continue clicked states
  useEffect(() => {
    onAddOrDelete();
    isAssignmentContinueClickedRef.current = isAssignmentContinueClicked;
    isQuizContinueClickedRef.current = isQuizContinueClicked;
    isOnlineSessionContinueClickedRef.current = isOnlineSessionContinueClicked;
    isSurveyContinueClickedRef.current = isSurveyContinueClicked;
    isForumContinueClickedRef.current = isForumContinueClicked;
    isFileContinueClickedRef.current = isFileContinueClicked;
    isDocumentContinueClickedRef.current = isDocumentContinueClicked;
    isFolderContinueClickedRef.current = isFolderContinueClicked;
    isLinkContinueClickedRef.current = isLinkContinueClicked;
    isLinkTreeContinueClickedRef.current = isLinkTreeContinueClicked;
    isVideoContinueClickedRef.current = isVideoContinueClicked;
    isHtmlContinueClickedRef.current = isHtmlContinueClicked;
    isPictureContinueClickedRef.current = isPictureContinueClicked;
    isScormContinueClickedRef.current = isScormContinueClicked;
    isTeamsContinueClickedRef.current = isTeamsContinueClicked;
  }, [
    isAssignmentContinueClicked,
    isQuizContinueClicked,
    isOnlineSessionContinueClicked,
    isSurveyContinueClicked,
    isForumContinueClickedRef,
    isFileContinueClicked,
    isDocumentContinueClicked,
    isFolderContinueClicked,
    isLinkContinueClicked,
    isLinkTreeContinueClicked,
    isVideoContinueClicked,
    isHtmlContinueClicked,
    isPictureContinueClicked,
    isTeamsContinueClicked,
  ]);

  /**************** Drop Function ****************/
  /**
   * Handles dropping different types of content items into the topic.
   *
   * @param data The data representing the dropped content item.
   * @param topic The topic in which the content is dropped.
   */
  const DropContentType = async (data: any, topic: TopicProps) => {
    const content_Title = localStorage.getItem("contentTitle");

    const generateId = () => {
      // generate a random Id number between 1 and 10000
      return Math.floor(Math.random() * 10000) + 1;
    };

    const contentId = generateId();

    const solution = await activeGetModulesWithTopics();
    const solutionArray: any[] = Array.isArray(solution) ? solution : [];

    const myTopic: TopicProps = solutionArray[0]?.topicsArray.filter(
      (topic: any) => topic.topicId === topicRefIdSolution
    );

    const newIndex = contents.length + 1;
    setContentIndex(newIndex);
    reorderedContentIndex();
    const newContent: ContentProps = {
      topicRefId: topic.topicId,
      contentIndex: contentIndex,
      contentId: contentId,
      contentTitle: `${content_Title ? content_Title : data.name}`,
      type: data.type,
      color: data.color,
    };
    setContent_Id(contentId);
    setContent_IdToEdit(contentId);
    setContent_Type(newContent.type);
    setTopicRefId(topic.topicId);
    setContent_Index(contentIndex);
    setIsContentId(undefined);
    setContentState(newContent);
    setIsPopupAllDroped(
      newContent.type === "Assignment" ||
        newContent.type === "Quiz" ||
        newContent.type === "Survey"
        ? false
        : true
    );

    switch (newContent.type) {
      // Handle different content types
      case "Assignment":
        // setIsAssignmentDrop(true);
        // setIsQuizDrop(false);
        // setIsOnlineSessionDrop(false);
        // setIsSurveyDrop(false);
        setIsPopupAllDroped(false);
        setGlobalShowPopup(false);
        setGlobalQuizShowPopup(false);
        setGlobalForumShowPopup(false);
        setGlobalAssignmentShowPopup(true);
        setSelectedRadio("grade");
        setSelectedTitle("");
        setSelectedRubricId(0);
        setSelectedRule("");
        setShowTimeVisibility(false);
        setShowSelectGrouping(false);
        setShowPlagiarismPrevention(false);
        setSelectedGrouping("");
        setAssignmentName("");
        setDescription("");
        setMaxGrade("");
        setStartDate(null);
        setDueDate(null);
        setCutOffDate(null);
        setPenalty("");
        setVisibleStudents(true);
        setFromDate(null);
        setToDate(null);
        setAnswerBoxChecked(false);
        setFileSubmissionChecked(false);
        setFile(null);
        setUrlAttachment("");
        // setIsForumDrop(false);
        // setIsFileDrop(false);
        // setIsDocumentDrop(false);
        // setIsFolderDrop(false);
        // setIsLinkDrop(false);
        // setIsLinkTreeDrop(false);
        // setIsVideoDrop(false);
        // setIsHtmlDrop(false);
        // setIsPictureDrop(false);
        // setIsTeamsDrop(false);
        break;
      // Handle Quiz content type
      case "Quiz":
        // setIsAssignmentDrop(false);
        // setIsQuizDrop(true);
        // Set default values for quiz properties
        setGlobalQuizShowPopup(true);
        setGlobalForumShowPopup(false)
        setGlobalAttempt("1");
        setGlobalUnlimitedAttempts(false);
        setGlobalQuizDescription("");
        setGlobalDuration("");
        setGlobalQuizEndDate(null);
        setGlobalQuizStartDate(null);
        setGlobalQuizIsTimeVisible(false);
        setGlobalQuizIsVisible(true);
        setGlobalQuizName("");
        setGlobalQuizToDate(null);
        setGlobalQuizFromDate(null);
        setGlobalMaxGrade("");
        setGlobalPassingGrade("");
        setGlobalSelectedQuiz("");
        setGlobalSelectedQuizId(0);
        setGlobalShowGrade(false);
        setEditQuiz(false);
        setEditQuizButton(false);
        // setIsOnlineSessionDrop(false);
        // setIsSurveyDrop(false);
        setGlobalShowPopup(false);
        setGlobalAssignmentShowPopup(false);
        setIsPopupAllDroped(false);
        // setIsForumDrop(false);
        // setIsFileDrop(false);
        // setIsDocumentDrop(false);
        // setIsFolderDrop(false);
        // setIsLinkDrop(false);
        // setIsLinkTreeDrop(false);
        // setIsVideoDrop(false);
        // setIsHtmlDrop(false);
        // setIsPictureDrop(false);
        // setIsTeamsDrop(false);
        break;
      // Handle Online Session content type
      case "Online_Session":
        // setIsAssignmentDrop(false);
        // setIsQuizDrop(false);
        // setIsOnlineSessionDrop(true);
        // setIsSurveyDrop(false);
        setGlobalShowPopup(false);
        setGlobalQuizShowPopup(false);
        setGlobalForumShowPopup(false)
        setGlobalAssignmentShowPopup(false);
        // setIsForumDrop(false);
        // setIsFileDrop(false);
        // setIsDocumentDrop(false);
        // setIsFolderDrop(false);
        // setIsLinkDrop(false);
        // setIsLinkTreeDrop(false);
        // setIsVideoDrop(false);
        // setIsHtmlDrop(false);
        // setIsPictureDrop(false);
        // setIsTeamsDrop(false);
        break;
      // Handle Survey content type
      case "Survey":
        // setIsAssignmentDrop(false);
        // setIsQuizDrop(false);
        // setIsOnlineSessionDrop(false);
        // setIsSurveyDrop(true);
        // Set default values for survey properties
        setGlobalShowPopup(true);
        setGlobalAssignmentShowPopup(false);
        setGlobalForumShowPopup(false)
        setGlobalQuizShowPopup(false);
        setGlobalDescription("");
        setGlobalSurveyName("");
        setGlobalEndDate(null);
        setGlobalStartDate(null);
        setGlobalFromDate(null);
        setGlobalToDate(null);
        setGlobalIsAnonymous(false);
        setGlobalIsTimeVisible(false);
        setGlobalIsVisible(true);
        setGlobalSelectedSurveyId(0);
        setGlobalSelectedSurvey("");
        setIsPopupAllDroped(false);
        // setIsForumDrop(false);
        // setIsFileDrop(false);
        // setIsDocumentDrop(false);
        // setIsFolderDrop(false);
        // setIsLinkDrop(false);
        // setIsLinkTreeDrop(false);
        // setIsVideoDrop(false);
        // setIsHtmlDrop(false);
        // setIsPictureDrop(false);
        setEditSurvey(false);
        setGlobalSurveyDuration("");
        // setIsTeamsDrop(false);
        break;
      // Handle Discussion Forum content type
      case "Forum":
        // setIsAssignmentDrop(false);
        // setIsQuizDrop(false);
        // setIsOnlineSessionDrop(false);
        // setIsSurveyDrop(false);
        setGlobalShowPopup(false);
        setIsPopupAllDroped(false);
        setGlobalAssignmentShowPopup(false);
        setGlobalQuizShowPopup(false);
        setGlobalForumShowPopup(true)
        setForumAttach(false);
        setForumCutOffDate(null)
        setForumDescription('')
        setForumDueDate(null)
        setForumEndTime('')
        setForumName('')
        setForumFromDate(null)
        setForumMaxGrade('')
        setForumStartDate(null)
        setForumPenalty('')
        setForumSelectedRadio('grade')
        setForumShowGradingType(false)
        setForumShowTimeVisibility(false)
        setForumStartTime('')
        setForumToDate(null)
        setForumVisibleStudents(true)
        // setIsForumDrop(true);
        // setIsFileDrop(false);
        // setIsDocumentDrop(false);
        // setIsFolderDrop(false);
        // setIsLinkDrop(false);
        // setIsLinkTreeDrop(false);
        // setIsVideoDrop(false);
        // setIsHtmlDrop(false);
        // setIsPictureDrop(false);
        // setIsTeamsDrop(false);
        break;
      // Handle File content type
      case "File":
        // setIsAssignmentDrop(false);
        // setIsQuizDrop(false);
        // setIsOnlineSessionDrop(false);
        // setIsSurveyDrop(false);
        setGlobalShowPopup(false);
        setGlobalAssignmentShowPopup(false);
        setGlobalQuizShowPopup(false);
        setGlobalForumShowPopup(false)
        // setIsForumDrop(false);
        // setIsFileDrop(true);
        // setIsDocumentDrop(false);
        // setIsFolderDrop(false);
        // setIsLinkDrop(false);
        // setIsLinkTreeDrop(false);
        // setIsVideoDrop(false);
        // setIsHtmlDrop(false);
        // setIsPictureDrop(false);
        // setIsTeamsDrop(false);
        break;
      // Handle Document content type
      case "Document":
        // setIsAssignmentDrop(false);
        // setIsQuizDrop(false);
        // setIsOnlineSessionDrop(false);
        // setIsSurveyDrop(false);
        setGlobalShowPopup(false);
        setGlobalForumShowPopup(false)
        setGlobalAssignmentShowPopup(false);
        setGlobalQuizShowPopup(false);
        // setIsForumDrop(false);
        // setIsFileDrop(false);
        // setIsDocumentDrop(true);
        // setIsFolderDrop(false);
        // setIsLinkDrop(false);
        // setIsLinkTreeDrop(false);
        // setIsVideoDrop(false);
        // setIsHtmlDrop(false);
        // setIsPictureDrop(false);
        // setIsTeamsDrop(false);
        break;
      // Handle Folder content type
      case "Folder":
        // setIsAssignmentDrop(false);
        // setIsQuizDrop(false);
        // setIsOnlineSessionDrop(false);
        // setIsSurveyDrop(false);
        setGlobalShowPopup(false);
        setGlobalAssignmentShowPopup(false);
        setGlobalForumShowPopup(false)
        setGlobalQuizShowPopup(false);
        // setIsForumDrop(false);
        // setIsFileDrop(false);
        // setIsDocumentDrop(false);
        // setIsFolderDrop(true);
        // setIsLinkDrop(false);
        // setIsLinkTreeDrop(false);
        // setIsVideoDrop(false);
        // setIsHtmlDrop(false);
        // setIsPictureDrop(false);
        // setIsTeamsDrop(false);
        break;
      // Handle URL content type
      case "Link":
        // setIsAssignmentDrop(false);
        // setIsQuizDrop(false);
        // setIsOnlineSessionDrop(false);
        // setIsSurveyDrop(false);
        setGlobalAssignmentShowPopup(false);
        setGlobalForumShowPopup(false)
        setGlobalShowPopup(false);
        setGlobalQuizShowPopup(false);
        // setIsForumDrop(false);
        // setIsFileDrop(false);
        // setIsDocumentDrop(false);
        // setIsFolderDrop(false);
        // setIsLinkDrop(true);
        // setIsLinkTreeDrop(false);
        // setIsVideoDrop(false);
        // setIsHtmlDrop(false);
        // setIsPictureDrop(false);
        // setIsTeamsDrop(false);
        break;
      // Handle URL link tree content type
      case "Link_Tree":
        // setIsAssignmentDrop(false);
        // setIsQuizDrop(false);
        // setIsOnlineSessionDrop(false);
        // setIsSurveyDrop(false);
        setGlobalAssignmentShowPopup(false);
        setGlobalShowPopup(false);
        setGlobalForumShowPopup(false)
        setGlobalQuizShowPopup(false);
        // setIsForumDrop(false);
        // setIsFileDrop(false);
        // setIsDocumentDrop(false);
        // setIsFolderDrop(false);
        // setIsLinkDrop(false);
        // setIsLinkTreeDrop(true);
        // setIsVideoDrop(false);
        // setIsHtmlDrop(false);
        // setIsPictureDrop(false);
        // setIsTeamsDrop(false);
        break;
      // Handle Video content type
      case "Video":
        // setIsAssignmentDrop(false);
        // setIsQuizDrop(false);
        // setIsOnlineSessionDrop(false);
        // setIsSurveyDrop(false);
        setGlobalAssignmentShowPopup(false);
        setGlobalShowPopup(false);
        setGlobalForumShowPopup(false)
        setGlobalQuizShowPopup(false);
        // setIsForumDrop(false);
        // setIsFileDrop(false);
        // setIsDocumentDrop(false);
        // setIsFolderDrop(false);
        // setIsLinkDrop(false);
        // setIsLinkTreeDrop(false);
        // setIsVideoDrop(true);
        // setIsHtmlDrop(false);
        // setIsPictureDrop(false);
        // setIsTeamsDrop(false);
        break;
      // Handle HTML content type
      case "Html":
        // setIsAssignmentDrop(false);
        // setIsQuizDrop(false);
        // setIsOnlineSessionDrop(false);
        // setIsSurveyDrop(false);
        setGlobalAssignmentShowPopup(false);
        setGlobalShowPopup(false);
        setGlobalQuizShowPopup(false);
        setGlobalForumShowPopup(false)
        // setIsForumDrop(false);
        // setIsFileDrop(false);
        // setIsDocumentDrop(false);
        // setIsFolderDrop(false);
        // setIsLinkDrop(false);
        // setIsLinkTreeDrop(false);
        // setIsVideoDrop(false);
        // setIsHtmlDrop(true);
        // setIsPictureDrop(false);
        // setIsTeamsDrop(false);
        break;
      // Handle Image content type
      case "Picture":
        // setIsAssignmentDrop(false);
        // setIsQuizDrop(false);
        // setIsOnlineSessionDrop(false);
        // setIsSurveyDrop(false);
        setGlobalAssignmentShowPopup(false);
        setGlobalShowPopup(false);
        setGlobalForumShowPopup(false)
        setGlobalQuizShowPopup(false);
        // setIsForumDrop(false);
        // setIsFileDrop(false);
        // setIsDocumentDrop(false);
        // setIsFolderDrop(false);
        // setIsLinkDrop(false);
        // setIsLinkTreeDrop(false);
        // setIsVideoDrop(false);
        // setIsHtmlDrop(false);
        // setIsPictureDrop(true);
        // setIsTeamsDrop(false);
        break;
      case "Scorm":
        // setIsAssignmentDrop(false);
        // setIsQuizDrop(false);
        // setIsOnlineSessionDrop(false);
        // setIsSurveyDrop(false);
        setGlobalAssignmentShowPopup(false);
        setGlobalShowPopup(false);
        setGlobalQuizShowPopup(false);
        setGlobalForumShowPopup(false)
        // setIsForumDrop(false);
        // setIsFileDrop(false);
        // setIsDocumentDrop(false);
        // setIsFolderDrop(false);
        // setIsLinkDrop(false);
        // setIsLinkTreeDrop(false);
        // setIsVideoDrop(false);
        // setIsHtmlDrop(false);
        // setIsPictureDrop(false);
        // setIsScormDrop(true);
        // setIsTeamsDrop(false);
        break;
      case "Teams":
        // setIsAssignmentDrop(false);
        // setIsQuizDrop(false);
        // setIsOnlineSessionDrop(false);
        // setIsSurveyDrop(false);
        setGlobalAssignmentShowPopup(false);
        setGlobalShowPopup(false);
        setGlobalForumShowPopup(false)
        setGlobalQuizShowPopup(false);
        // setIsForumDrop(false);
        // setIsFileDrop(false);
        // setIsDocumentDrop(false);
        // setIsFolderDrop(false);
        // setIsLinkDrop(false);
        // setIsLinkTreeDrop(false);
        // setIsVideoDrop(false);
        // setIsHtmlDrop(false);
        // setIsPictureDrop(false);
        // setIsScormDrop(false);
        // setIsTeamsDrop(true);
        break;
    }
    reorderedContentIndex();
  };

  /**
   * Handles the second stage of dropping content into the topic when we click continue in the popup form.
   *
   * @param newContent The new content item to be added to the topic.
   */
  const handleSecondStage = (newContent: ContentProps) => {
    if (contentState) {
      if (
        (isAssignmentContinueClickedRef.current &&
          newContent.type === "Assignment") ||
        (isQuizContinueClickedRef.current && newContent.type === "Quiz") ||
        (isOnlineSessionContinueClickedRef.current &&
          newContent.type === "Online_Session") ||
        (isSurveyContinueClickedRef.current && newContent.type === "Survey") ||
        (isForumContinueClickedRef.current && newContent.type === "Forum") ||
        (isFileContinueClickedRef.current && newContent.type === "File") ||
        (isDocumentContinueClickedRef.current &&
          newContent.type === "Document") ||
        (isFolderContinueClickedRef.current && newContent.type === "Folder") ||
        (isLinkContinueClickedRef.current && newContent.type === "Link") ||
        (isLinkTreeContinueClickedRef.current &&
          newContent.type === "Link_Tree") ||
        (isVideoContinueClickedRef.current && newContent.type === "Video") ||
        (isHtmlContinueClickedRef.current && newContent.type === "Html") ||
        (isPictureContinueClickedRef.current &&
          newContent.type === "Picture") ||
        (isPictureContinueClickedRef.current && newContent.type === "Teams")
      ) {
        topic.contents.push(newContent);
        setContentsState([...contentsState, newContent]);
        setIsAssignmentContinueClicked(false);
        setIsQuizContinueClicked(false);
        setIsOnlineSessionContinueClicked(false);
        setIsSurveyContinueClicked(false);
        setIsForumContinueClicked(false);
        setIsFileContinueClicked(false);
        setIsDocumentContinueClicked(false);
        setIsFolderContinueClicked(false);
        setIsLinkContinueClicked(false);
        setIsLinkTreeContinueClicked(false);
        setIsVideoContinueClicked(false);
        setIsHtmlContinueClicked(false);
        setIsPictureContinueClicked(false);
        setIsScormContinueClicked(false);
        setIsTeamsContinueClicked(false);
      }
      setIsDrop(true);
    }
    reorderedContentIndex();
  };

  // Use a useEffect to listen for changes in relevant state variables
  useEffect(() => {
    if (contentState && !showEditContent) {
      handleSecondStage(contentState);
      if (
        contentID !== undefined &&
        contentID === contentState.contentId &&
        contentTitle !== undefined
      ) {
        editContent(contentID, contentTitle);
      }
      onAddOrDelete();
    }
  }, [
    isAssignmentContinueClickedRef.current,
    isQuizContinueClickedRef.current,
    isOnlineSessionContinueClickedRef.current,
    isSurveyContinueClickedRef.current,
    isForumContinueClickedRef.current,
    isFileContinueClickedRef.current,
    isDocumentContinueClickedRef.current,
    isFolderContinueClickedRef.current,
    isLinkContinueClickedRef.current,
    isLinkTreeContinueClickedRef.current,
    isVideoContinueClickedRef.current,
    isHtmlContinueClickedRef.current,
    isPictureContinueClickedRef.current,
    isScormContinueClickedRef.current,
    isTeamsContinueClickedRef.current,
    contentState,
  ]);

  /**
   * Sets the content title within the topic.
   *
   * @param contentTitle The title of the content item.
   */
  const setContentTitleInTopic = (contentTitle: string) => {
    if (contentTitle.length === 0) {
      setNewContentTitle(isContentType);
    } else {
      setNewContentTitle(contentTitle);
    }
  };

  /**
   * Edits the title of a content item.
   *
   * @param contentId The ID of the content item to be edited.
   * @param newTitle The new title for the content item.
   */
  const editContent = (contentId: number, newTitle: string) => {
    if (contentId !== undefined) {
      let newContents = [...topic.contents];
      const editedContent = newContents?.map((content) =>
        content?.contentId === contentId
          ? (content.contentTitle = newTitle)
          : (content.contentTitle = content.contentTitle)
      );
      topic.contents = newContents;
    }
    onAddOrDelete();
  };

  /**
   * Deletes content from the topic.
   *
   * @param path The API path for deleting content.
   * @param body The data payload for the delete request.
   * @returns The response from the delete request.
   */
  const deleteContent = async (path: string, body: any, content: any) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const contentId = body.idToDelete;
      const deleteActivityWeight = await axios.delete(
        `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/activity/weight/Delete/${contentId}/${tenantName}`
      );
      const response = await axios.put(`${BaseURL}/${path}/${tenantName}`, {
        data: body,
      });
      if (isReady && send) {
        send(
          JSON.stringify({
            action: "sendPublic",
            data: {
              tenantName: tenantName,
              classSourceId: classId,
              method: "delete",
              title: content.contentTitle,
              contentType: content.type,
              subId: subId,
              provider: provider,
              type: "content",
            },
          })
        );
      }
      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
    }
  };

  /**
   * Removes content from the topic.
   *
   * @param topic The topic from which content is to be removed.
   * @param contentId The ID of the content item to be removed.
   * @param contentIndex The index of the content item to be removed.
   */

  const GetEventId = async (onlineSessionId: number) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `https://k9jjuskf3i.execute-api.eu-west-1.amazonaws.com/dev/teamsOnlineSession/${onlineSessionId}/${subId}/${classId}/${tenantName}?provider=${provider}`
      );
      //https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/activity/onlineSession/${ReceivedActivityId}/${subIdSplitted}/${classId}/${tenantName}
      return res.data.data.online_session.eventId;
    } catch (error) {
      console.log(error);
    }
  };

  // const [AccessToken, SetAccessToken] = useState("")

  const getAccessToken = async () => {
    try {
      const response = await axios.get(
        `https://k9jjuskf3i.execute-api.eu-west-1.amazonaws.com/dev/ms/token`,
        // {
        //   headers: {
        //     'tenantName': tenantName,
        //     // Replace YOUR_AUTH_TOKEN with the actual token if required
        //     // Add other headers if needed
        //   }
        // }
      );

      console.log(response, "responseToken");
      localStorage.setItem("MSToken", response.data.data);
      // SetAccessToken(response.data.data)
      return response.data.data;
    } catch (err) {
      console.log(err);
    }
  };

  const [UserMSid, SetUserMSid] = useState("")
  const getUserId = async () => {
    try {

      const response = await axios.get(
        `https://k9jjuskf3i.execute-api.eu-west-1.amazonaws.com/dev/ms/user/byEmail/${myEmail}/{tenantName}`,

      );
      SetUserMSid(response.data.data)

      console.log(response.data.data, "responseUserId");
      return response.data.data
    } catch (err) {
      console.log(err);
    }
  };


  const fetchCalendarId = async () => {
    try {
      const AccessToken = await getAccessToken()
      await getUserId()
      if (UserMSid) {

        const response = await axios.get(`https://graph.microsoft.com/v1.0/users/${UserMSid}/calendars`, {
          headers: {
            Authorization: `${AccessToken}`,
          },
        });

        // Assuming you want the primary calendar
        const primaryCalendar = response.data.value.find((calendar: { name: string; }) => calendar.name === 'Calendar');

        console.log(primaryCalendar.id, "iddddddddd")
        return primaryCalendar ? primaryCalendar.id : null;
      }
      else {
        return;
      }
    } catch (error) {
      console.error('Error fetching calendar ID:', error);
      throw error;
    }
  };

  const deleteEvent = async (
    bearerToken: any,
    calendarId: any,
    eventId: any
  ) => {
    try {
      const UserId = await getUserId()
      const response = await axios.delete(
        `https://graph.microsoft.com/v1.0/users/${UserId}/calendars/${calendarId}/events/${eventId}`,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      console.log(`Event with ID ${eventId} deleted successfully`);
      toast.success(`Team meeting  deleted successfully`)
      return true; // Return true to indicate successful deletion
    } catch (err) {
      console.log(err); // Log any errors
      return false; // Return false to indicate deletion failure
    }
  };
  const removeContent = async (
    topic: TopicProps,
    contentId: number | undefined,
    contentIndex: number,
    content: any
  ) => {
    if (
      topic.contents[contentIndex - 1].contentIndex !== topic.contents.length
    ) {
      for (
        let i = topic.contents[contentIndex - 1].contentIndex;
        i < topic.contents.length;
        i++
      ) {
        topic.contents[i].contentIndex -= 1;
      }
    }
    if (contentId !== undefined) {
      const updatedContents: ContentProps[] = topic.contents.filter(
        (content) => content.contentId !== contentId
      );
      const data = {
        idToDelete: contentId,
        payload: updatedContents,
      };

      topic.contents = updatedContents;
      await deleteContent("topic/content/delete", data, content);
      if (content.type === "Teams") {
        const eventId = await GetEventId(content.refId);
        const bearerToken = await getAccessToken();
        const calendarId = await fetchCalendarId();
        await deleteEvent(bearerToken, calendarId, eventId);
      }
      setContentsState(updatedContents);
    }
    onAddOrDelete();
    reorderedContentIndex();
  };

  // State for the title of the topic
  const [title, setTitle] = useState<string>(`${topic.topicTitle}`);
  const handleInputChange = (e: any) => {
    setTitle(e.target.value);
    setTopicTitleInModule(e.target.value);
  };

  // Drop zone for accepting dropped items
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: "ITEM",
    drop: (droppedItem: any) => {
      const { data } = droppedItem;
      DropContentType(data, topic);
      setIsPopUpOpen(true);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  // Refs for topic ID and index
  const isTopicIdRef = useRef<number | undefined>(undefined);
  const isTopicIndexRef = useRef<number | undefined>(undefined);
  useEffect(() => {
    isTopicIdRef.current = topicId;
    isTopicIndexRef.current = topicIndex;
  }, [topicId, topicIndex]);

  // Effect to handle pop-up visibility based on content drop and edit state
  useEffect(() => {
    if (isPopupAllDroped || showEditContent) {
      setIsPopUpOpen(true);
    } else if (!isPopupAllDroped && !showEditContent) {
      setIsPopUpOpen(false);
    }
  }, [isPopupAllDroped, showEditContent]);

  // Effect to handle pop-up visibility based on showEditTopic state
  useEffect(() => {
    if (showEditTopic) {
      setIsPopUpOpen(true);
    } else {
      setIsPopUpOpen(false);
    }
  }, [showEditTopic]);

  /**
   * Detects the color of the icon based on the content type.
   *
   * @param contentType The type of content.
   * @returns The color code for the icon.
   */

  return (
    <>
      {/* Draggable component for handling topic drag and drop */}
      <Draggable
        draggableId={`Module-${module.moduleIndex - 1}-${topicIndex}`}
        index={topicIndex}
        isDragDisabled={isPopUpOpen} // Disables dragging when a popup is open
      >
        {(provided, snapshot) => (
          <TopicsHolder
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              // Apply different styles when the item is being dragged
              boxShadow: snapshot.isDragging
                ? "0px 4px 20px 0px rgba(93, 211, 179, 0.25)"
                : "none",
              borderRadius: snapshot.isDragging ? "0.625rem" : "0.625rem",
              ...provided.draggableProps.style,
            }}
          >
            <div>
              {/* Topic container */}
              <TopicContainer
                ref={drop}
                lineiconcolor={lineIconColor}
                style={{
                  borderWidth: isOver ? "2px" : "1px",
                  opacity: `${showEditTopicPositionLoader && sourceIdTopic === topicId
                    ? "0.25"
                    : "1"
                    }`,
                  borderColor: isOver
                    ? "rgba(93, 211, 179, 0.25)"
                    : lineIconColor,
                  boxShadow: isOver
                    ? "0px 4px 20px 0px rgba(93, 211, 179, 0.25)"
                    : "none",
                }}
                onMouseEnter={() => setTopicRefIdSolution(topic.topicId)}
              >
                {/* Topic header */}
                <TopicHeader bgheadercolor={topicHeaderBgColor}>
                  {showEditTopic ? (
                    // Editable input field for topic title
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem",
                      }}
                    >
                      <ReusableInput
                        inputWidth="100%"
                        placeHolder="Enter Title"
                        value={title}
                        handleInputChange={handleInputChange}
                        setShowEditInTopic={handleShowEditInTopic}
                        editTopic={() => editTopic(topicId, newTopicTitle)}
                        topicId={topicId}
                        newTopicTitle={newTopicTitle}
                        setShowDropDown={handleShowDropDown}
                        toggleDropdown={toggleDropdown}
                      />
                    </div>
                  ) : (
                    // Display topic title with dropdown icon
                    <>
                      <PrimaryFontMediumText
                        text={topicTitle}
                        fontSize="0.75rem"
                      />
                      <VDotsWrapper
                        ref={dropdownRef}
                        onClick={() => {
                          toggleDropdown();
                          setShowDropDown(true);
                          if (isTopicIdRef.current !== undefined) {
                            setIsTopicIdInModule(isTopicIdRef.current);
                          }
                          if (isTopicIndexRef.current !== undefined) {
                            setIsTopicIndexInModule(
                              isTopicIndexRef.current + 1
                            );
                          }
                        }}
                      >
                        <SvgMoreVerticalIcon />
                      </VDotsWrapper>
                    </>
                  )}
                </TopicHeader>

                {/* Dropdown menu for editing or deleting topic */}
                {isDropdownVisible && showDropDown && (
                  <DropDownEditDeleteTopic
                    topicId={topicId}
                    module={module}
                    removeTopic={() => {
                      removeTopic(module, topicId, topicIndex);
                    }}
                    setShowDeleteTopic={handleDeleteTopic}
                    setShowEditTopic={setShowEditTopic}
                    setShowDropDown={handleShowDropDown}
                  />
                )}

                {/* Confirmation popup for deleting topic */}
                {showDeleteTopic && removeTopic && (
                  <PopupDelete
                    show={showDeleteTopic}
                    setShow={setShowDeleteTopic}
                    title={t(
                      "course view.Are you sure you want to delete this Topic"
                    )}
                    handleSubmit={() => {
                      removeTopic(module, topicId, topicIndex);
                      setIsPopUpOpen(false);
                    }}
                  />
                )}

                {/* Wrapper for topic contents */}
                <ActivitiesWrapper>
                  <Droppable
                    droppableId={`content-${moduleIndex}-${topicIndex}`}
                    type="contentsContainer"
                    direction="vertical"
                  >
                    {(provided, snapshot) => (
                      <ContentsContainer
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          borderRadius: snapshot.isDraggingOver
                            ? "0.3125rem"
                            : "none",
                          boxShadow: snapshot.isDraggingOver
                            ? "0px 4px 20px 0px rgba(93, 211, 179, 0.25)"
                            : "none",
                        }}
                      >
                        {/* Render contents within the topic */}
                        {(isDrop === false &&
                          isModuleDeleteClicked === false &&
                          isContentDrop === false) ||
                          topic.contents.length === 0 ? (
                          <DropActivity />
                        ) : (
                          <>
                            {topic?.contents
                              ?.sort((a, b) => a.contentIndex - b.contentIndex)
                              .map((content, index) => (
                                // Render each content item within the topic
                                <Content
                                  key={index}
                                  contentIndex={index}
                                  contentId={content.contentId}
                                  type={content.type}
                                  color={detectIconColor(content.type)}
                                  contentTitle={content.contentTitle}
                                  content={content}
                                  topic={topic}
                                  module={module}
                                  moduleIndex={moduleIndex}
                                  topicIndex={topicIndex}
                                  topicRefId={topic.topicId}
                                  reorderedContentIndex={reorderedContentIndex}
                                  removeContent={() => {
                                    if (
                                      isContentId !== undefined &&
                                      isContentIndex !== undefined
                                    ) {
                                      removeContent(
                                        topic,
                                        isContentId,
                                        isContentIndex,
                                        content
                                      );
                                    }
                                  }}
                                  setIsContentIdInTopic={setIsContentIdInTopic}
                                  setIsContentIndexInTopic={
                                    setIsContentIndexInTopic
                                  }
                                  setIsContentTypeInTopic={
                                    setIsContentTypeInTopic
                                  }
                                  borderRadius={`${content.contentIndex ===
                                    topic.contents.length &&
                                    content.contentIndex > 2
                                    ? "0rem 0rem 0.625rem 0.625rem"
                                    : "0"
                                    } `}
                                  setShowEditContent={
                                    handleShowEditContentInTopic
                                  }
                                  showEditContent={showEditContent}
                                />
                              ))}
                          </>
                        )}
                        {provided.placeholder}
                      </ContentsContainer>
                    )}
                  </Droppable>
                  {showEditTopicPositionLoader && sourceIdTopic === topicId && (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: `${topic.contents.length > 0
                          ? topic.contents.length * (2.0625 + 0.31) - 1.25
                          : 2.1
                          }rem`,
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <Loader width={30} height={30} />
                    </div>
                  )}
                </ActivitiesWrapper>
              </TopicContainer>
            </div>
          </TopicsHolder>
        )}
      </Draggable>

      {/* Conditional rendering of popups for different content types */}
      {/* Assignment popup */}
      {(isPopupAllDroped ||
        globalQuizShowPopup ||
        globalShowPopup ||
        globalForumShowPopup ||
        globalAssignmentShowPopup ||
        showEditContent) && (
          <CourseCreatorPopups
            topicRefId={topic.topicId}
            show={isPopupAllDroped}
            setShow={setIsPopupAllDroped}
            activeGetModulesWithTopics={activeGetModulesWithTopics}
            handleSubmit={() => {
              setIsAssignmentContinueClicked(true);
              setIsQuizContinueClicked(true);
              setIsOnlineSessionContinueClicked(true);
              setIsSurveyContinueClicked(true);
              setIsForumContinueClicked(true);
              setIsFileContinueClicked(true);
              setIsDocumentContinueClicked(true);
              setIsFolderContinueClicked(true);
              setIsLinkContinueClicked(true);
              setIsLinkTreeContinueClicked(true);
              setIsVideoContinueClicked(true);
              setIsHtmlContinueClicked(true);
              setIsPictureContinueClicked(true);
              setIsScormContinueClicked(true);
              setIsTeamsContinueClicked(true);
            }}
            setShowEditContent={setShowEditContent}
            showEditContent={showEditContent}
            editContent={() => {
              if (isContentId !== undefined && contentTitle !== undefined)
                editContent(isContentId, contentTitle);
            }}
            newContentTitle={contentTitle}
            contentId={isContentId}
            setContentTitleInTopic={setContentTitleInTopic}
            removeContent={() => {
              if (isContentId !== undefined && isContentIndex !== undefined) {
                removeContent(
                  topic,
                  isContentId,
                  isContentIndex,
                  contentGlobalState
                );
              }
            }}
            topic={topic}
            contentIndex={isContentIndex}
          />
        )}

      {/* Quiz popup */}
      {/* {(globalQuizShowPopup ||
        (showEditContent && isContentType === "Quiz")) && (
        <PopupQuiz
          topicRefId={topic.topicId}
          show={isQuizDrop}
          setShow={setIsQuizDrop}
          activeGetModulesWithTopics={activeGetModulesWithTopics}
          handleSubmit={() => {
            setIsAssignmentContinueClicked(false);
            setIsQuizContinueClicked(true);
            setIsOnlineSessionContinueClicked(false);
            setIsSurveyContinueClicked(false);
            setIsForumContinueClicked(false);
            setIsFileContinueClicked(false);
            setIsDocumentContinueClicked(false);
            setIsFolderContinueClicked(false);
            setIsLinkContinueClicked(false);
            setIsLinkTreeContinueClicked(false);
            setIsVideoContinueClicked(false);
            setIsHtmlContinueClicked(false);
            setIsPictureContinueClicked(false);
            setIsScormContinueClicked(false);
            setIsTeamsContinueClicked(false);
          }}
          setShowEditContent={setShowEditContent}
          showEditContent={showEditContent}
          editContent={() => {
            if (isContentId !== undefined && contentTitle !== undefined)
              editContent(isContentId, contentTitle);
          }}
          newContentTitle={contentTitle}
          contentId={isContentId}
          setContentTitleInTopic={setContentTitleInTopic}
        />
      )} */}

      {/* Online session popup */}
      {/* {(isOnlineSessionDrop ||
        (showEditContent && isContentType === "Online_Session")) && (
        <PopupOnlineSession
          show={isOnlineSessionDrop}
          setShow={setIsOnlineSessionDrop}
          topicRefId={topic.topicId}
          activeGetModulesWithTopics={activeGetModulesWithTopics}
          handleSubmit={() => {
            setIsAssignmentContinueClicked(false);
            setIsQuizContinueClicked(false);
            setIsOnlineSessionContinueClicked(true);
            setIsSurveyContinueClicked(false);
            setIsForumContinueClicked(false);
            setIsFileContinueClicked(false);
            setIsDocumentContinueClicked(false);
            setIsFolderContinueClicked(false);
            setIsLinkContinueClicked(false);
            setIsLinkTreeContinueClicked(false);
            setIsVideoContinueClicked(false);
            setIsHtmlContinueClicked(false);
            setIsPictureContinueClicked(false);
            setIsScormContinueClicked(false);
            setIsTeamsContinueClicked(false);
          }}
          setShowEditContent={setShowEditContent}
          showEditContent={showEditContent}
          editContent={() => {
            if (isContentId !== undefined && contentTitle !== undefined)
              editContent(isContentId, contentTitle);
          }}
          newContentTitle={contentTitle}
          contentId={isContentId}
          setContentTitleInTopic={setContentTitleInTopic}
        />
      )} */}

      {/* Survey popup */}
      {/* {(globalShowPopup || (showEditContent && isContentType === "Survey")) && (
        <PopupSurvey
          show={isSurveyDrop}
          setShow={setIsSurveyDrop}
          topicRefId={topic.topicId}
          activeGetModulesWithTopics={activeGetModulesWithTopics}
          handleSubmit={() => {
            setIsAssignmentContinueClicked(false);
            setIsQuizContinueClicked(false);
            setIsOnlineSessionContinueClicked(false);
            setIsSurveyContinueClicked(true);
            setIsForumContinueClicked(false);
            setIsFileContinueClicked(false);
            setIsDocumentContinueClicked(false);
            setIsFolderContinueClicked(false);
            setIsLinkContinueClicked(false);
            setIsLinkTreeContinueClicked(false);
            setIsVideoContinueClicked(false);
            setIsHtmlContinueClicked(false);
            setIsPictureContinueClicked(false);
            setIsScormContinueClicked(false);
            setIsTeamsContinueClicked(false);
          }}
          setShowEditContent={setShowEditContent}
          showEditContent={showEditContent}
          editContent={() => {
            if (isContentId !== undefined && contentTitle !== undefined)
              editContent(isContentId, contentTitle);
          }}
          newContentTitle={contentTitle}
          contentId={isContentId}
          setContentTitleInTopic={setContentTitleInTopic}
        />
      )} */}

      {/* Discussion forum popup */}
      {/* {(isForumDrop || (showEditContent && isContentType === "Forum")) && (
        <PopupForum
          show={isForumDrop}
          setShow={setIsForumDrop}
          topicRefId={topic.topicId}
          activeGetModulesWithTopics={activeGetModulesWithTopics}
          handleSubmit={() => {
            setIsAssignmentContinueClicked(false);
            setIsQuizContinueClicked(false);
            setIsOnlineSessionContinueClicked(false);
            setIsSurveyContinueClicked(false);
            setIsForumContinueClicked(true);
            setIsFileContinueClicked(false);
            setIsDocumentContinueClicked(false);
            setIsFolderContinueClicked(false);
            setIsLinkContinueClicked(false);
            setIsLinkTreeContinueClicked(false);
            setIsVideoContinueClicked(false);
            setIsHtmlContinueClicked(false);
            setIsPictureContinueClicked(false);
            setIsScormContinueClicked(false);
            setIsTeamsContinueClicked(false);
          }}
          setShowEditContent={setShowEditContent}
          showEditContent={showEditContent}
          editContent={() => {
            if (isContentId !== undefined && contentTitle !== undefined)
              editContent(isContentId, contentTitle);
          }}
          newContentTitle={contentTitle}
          contentId={isContentId}
          setContentTitleInTopic={setContentTitleInTopic}
        />
      )} */}

      {/* Teams popup */}
      {/* {(isTeamsDrop || (showEditContent && isContentType === "Teams")) && (
        <PopupTeams
          show={isTeamsDrop}
          setShow={setIsTeamsDrop}
          topicRefId={topic.topicId}
          activeGetModulesWithTopics={activeGetModulesWithTopics}
          handleSubmit={() => {
            setIsAssignmentContinueClicked(false);
            setIsQuizContinueClicked(false);
            setIsOnlineSessionContinueClicked(false);
            setIsSurveyContinueClicked(false);
            setIsForumContinueClicked(false);
            setIsFileContinueClicked(false);
            setIsDocumentContinueClicked(false);
            setIsFolderContinueClicked(false);
            setIsLinkContinueClicked(false);
            setIsLinkTreeContinueClicked(false);
            setIsVideoContinueClicked(false);
            setIsHtmlContinueClicked(false);
            setIsPictureContinueClicked(false);
            setIsScormContinueClicked(false);
            setIsTeamsContinueClicked(true);
          }}
          setShowEditContent={setShowEditContent}
          showEditContent={showEditContent}
          editContent={() => {
            if (isContentId !== undefined && contentTitle !== undefined)
              editContent(isContentId, contentTitle);
          }}
          newContentTitle={contentTitle}
          contentId={isContentId}
          setContentTitleInTopic={setContentTitleInTopic}
        />
      )} */}

      {/* File popup */}
      {/* {(isFileDrop || (showEditContent && isContentType === "File")) && (
        <PopupFile
          show={isFileDrop}
          setShow={setIsFileDrop}
          activeGetModulesWithTopics={activeGetModulesWithTopics}
          topicRefId={topic.topicId}
          handleSubmit={() => {
            setIsAssignmentContinueClicked(false);
            setIsQuizContinueClicked(false);
            setIsOnlineSessionContinueClicked(false);
            setIsSurveyContinueClicked(false);
            setIsForumContinueClicked(false);
            setIsFileContinueClicked(true);
            setIsDocumentContinueClicked(false);
            setIsFolderContinueClicked(false);
            setIsLinkContinueClicked(false);
            setIsLinkTreeContinueClicked(false);
            setIsVideoContinueClicked(false);
            setIsHtmlContinueClicked(false);
            setIsPictureContinueClicked(false);
            setIsScormContinueClicked(false);
            setIsTeamsContinueClicked(false);
          }}
          setShowEditContent={setShowEditContent}
          showEditContent={showEditContent}
          editContent={() => {
            if (isContentId !== undefined && contentTitle !== undefined)
              editContent(isContentId, contentTitle);
          }}
          newContentTitle={contentTitle}
          contentId={isContentId}
          setContentTitleInTopic={setContentTitleInTopic}
        />
      )} */}

      {/* Document popup */}
      {/* {(isDocumentDrop ||
        (showEditContent && isContentType === "Document")) && (
        <PopupDocument
          show={isDocumentDrop}
          activeGetModulesWithTopics={activeGetModulesWithTopics}
          topicRefId={topic.topicId}
          setShow={setIsDocumentDrop}
          handleSubmit={() => {
            setIsAssignmentContinueClicked(false);
            setIsQuizContinueClicked(false);
            setIsOnlineSessionContinueClicked(false);
            setIsSurveyContinueClicked(false);
            setIsForumContinueClicked(false);
            setIsFileContinueClicked(false);
            setIsDocumentContinueClicked(true);
            setIsFolderContinueClicked(false);
            setIsLinkContinueClicked(false);
            setIsLinkTreeContinueClicked(false);
            setIsVideoContinueClicked(false);
            setIsHtmlContinueClicked(false);
            setIsPictureContinueClicked(false);
            setIsScormContinueClicked(false);
            setIsTeamsContinueClicked(false);
          }}
          setShowEditContent={setShowEditContent}
          showEditContent={showEditContent}
          editContent={() => {
            if (isContentId !== undefined && contentTitle !== undefined)
              editContent(isContentId, contentTitle);
          }}
          newContentTitle={contentTitle}
          contentId={isContentId}
          setContentTitleInTopic={setContentTitleInTopic}
        />
      )} */}

      {/* Folder popup */}
      {/* {(isFolderDrop || (showEditContent && isContentType === "Folder")) && (
        <PopupFolder
          show={isFolderDrop}
          setShow={setIsFolderDrop}
          activeGetModulesWithTopics={activeGetModulesWithTopics}
          topicRefId={topic.topicId}
          handleSubmit={() => {
            setIsAssignmentContinueClicked(false);
            setIsQuizContinueClicked(false);
            setIsOnlineSessionContinueClicked(false);
            setIsSurveyContinueClicked(false);
            setIsForumContinueClicked(false);
            setIsFileContinueClicked(false);
            setIsDocumentContinueClicked(false);
            setIsFolderContinueClicked(true);
            setIsLinkContinueClicked(false);
            setIsLinkTreeContinueClicked(false);
            setIsVideoContinueClicked(false);
            setIsHtmlContinueClicked(false);
            setIsPictureContinueClicked(false);
            setIsScormContinueClicked(false);
            setIsTeamsContinueClicked(false);
          }}
          setShowEditContent={setShowEditContent}
          showEditContent={showEditContent}
          editContent={() => {
            if (isContentId !== undefined && contentTitle !== undefined)
              editContent(isContentId, contentTitle);
          }}
          newContentTitle={contentTitle}
          contentId={isContentId}
          setContentTitleInTopic={setContentTitleInTopic}
        />
      )} */}

      {/* Link popup */}
      {/* {(isLinkDrop || (showEditContent && isContentType === "Link")) && (
        <PopupUrl
          show={isLinkDrop}
          setShow={setIsLinkDrop}
          activeGetModulesWithTopics={activeGetModulesWithTopics}
          topicRefId={topic.topicId}
          handleSubmit={() => {
            setIsAssignmentContinueClicked(false);
            setIsQuizContinueClicked(false);
            setIsOnlineSessionContinueClicked(false);
            setIsSurveyContinueClicked(false);
            setIsForumContinueClicked(false);
            setIsFileContinueClicked(false);
            setIsDocumentContinueClicked(false);
            setIsFolderContinueClicked(false);
            setIsLinkContinueClicked(true);
            setIsLinkTreeContinueClicked(false);
            setIsVideoContinueClicked(false);
            setIsHtmlContinueClicked(false);
            setIsPictureContinueClicked(false);
            setIsScormContinueClicked(false);
            setIsTeamsContinueClicked(false);
          }}
          setShowEditContent={setShowEditContent}
          showEditContent={showEditContent}
          editContent={() => {
            if (isContentId !== undefined && contentTitle !== undefined)
              editContent(isContentId, contentTitle);
          }}
          newContentTitle={contentTitle}
          contentId={isContentId}
          setContentTitleInTopic={setContentTitleInTopic}
        />
      )} */}

      {/* Link tree popup */}
      {/* {(isLinkTreeDrop ||
        (showEditContent && isContentType === "Link_Tree")) && (
        <PopupLinkTree
          show={isLinkTreeDrop}
          activeGetModulesWithTopics={activeGetModulesWithTopics}
          topicRefId={topic.topicId}
          setShow={setIsLinkTreeDrop}
          handleSubmit={() => {
            setIsAssignmentContinueClicked(false);
            setIsQuizContinueClicked(false);
            setIsOnlineSessionContinueClicked(false);
            setIsSurveyContinueClicked(false);
            setIsForumContinueClicked(false);
            setIsFileContinueClicked(false);
            setIsDocumentContinueClicked(false);
            setIsFolderContinueClicked(false);
            setIsLinkContinueClicked(false);
            setIsLinkTreeContinueClicked(true);
            setIsVideoContinueClicked(false);
            setIsHtmlContinueClicked(false);
            setIsPictureContinueClicked(false);
            setIsScormContinueClicked(false);
            setIsTeamsContinueClicked(false);
          }}
          setShowEditContent={setShowEditContent}
          showEditContent={showEditContent}
          editContent={() => {
            if (isContentId !== undefined && contentTitle !== undefined)
              editContent(isContentId, contentTitle);
          }}
          newContentTitle={contentTitle}
          contentId={isContentId}
          setContentTitleInTopic={setContentTitleInTopic}
        />
      )} */}

      {/* Video popup */}
      {/* {(isVideoDrop || (showEditContent && isContentType === "Video")) && (
        <PopupVideo
          show={isVideoDrop}
          setShow={setIsVideoDrop}
          activeGetModulesWithTopics={activeGetModulesWithTopics}
          topicRefId={topic.topicId}
          handleSubmit={() => {
            setIsAssignmentContinueClicked(false);
            setIsQuizContinueClicked(false);
            setIsOnlineSessionContinueClicked(false);
            setIsSurveyContinueClicked(false);
            setIsForumContinueClicked(false);
            setIsFileContinueClicked(false);
            setIsDocumentContinueClicked(false);
            setIsFolderContinueClicked(false);
            setIsLinkContinueClicked(false);
            setIsLinkTreeContinueClicked(false);
            setIsVideoContinueClicked(true);
            setIsHtmlContinueClicked(false);
            setIsPictureContinueClicked(false);
            setIsScormContinueClicked(false);
            setIsTeamsContinueClicked(false);
          }}
          setShowEditContent={setShowEditContent}
          showEditContent={showEditContent}
          editContent={() => {
            if (isContentId !== undefined && contentTitle !== undefined)
              editContent(isContentId, contentTitle);
          }}
          newContentTitle={contentTitle}
          contentId={isContentId}
          setContentTitleInTopic={setContentTitleInTopic}
        />
      )} */}

      {/* Html popup */}
      {/* {(isHtmlDrop || (showEditContent && isContentType === "Html")) && (
        <PopupHtml
          show={isHtmlDrop}
          setShow={setIsHtmlDrop}
          activeGetModulesWithTopics={activeGetModulesWithTopics}
          topicRefId={topic.topicId}
          handleSubmit={() => {
            setIsAssignmentContinueClicked(false);
            setIsQuizContinueClicked(false);
            setIsOnlineSessionContinueClicked(false);
            setIsSurveyContinueClicked(false);
            setIsForumContinueClicked(false);
            setIsFileContinueClicked(false);
            setIsDocumentContinueClicked(false);
            setIsFolderContinueClicked(false);
            setIsLinkContinueClicked(false);
            setIsLinkTreeContinueClicked(false);
            setIsVideoContinueClicked(false);
            setIsHtmlContinueClicked(true);
            setIsPictureContinueClicked(false);
            setIsScormContinueClicked(false);
            setIsTeamsContinueClicked(false);
          }}
          setShowEditContent={setShowEditContent}
          showEditContent={showEditContent}
          editContent={() => {
            if (isContentId !== undefined && contentTitle !== undefined)
              editContent(isContentId, contentTitle);
          }}
          newContentTitle={contentTitle}
          contentId={isContentId}
          setContentTitleInTopic={setContentTitleInTopic}
        />
      )} */}

      {/* Picture popup */}
      {/* {(isPictureDrop || (showEditContent && isContentType === "Picture")) && (
        <PopupImage
          topicContents={topic.contents}
          show={isPictureDrop}
          setShow={setIsPictureDrop}
          activeGetModulesWithTopics={activeGetModulesWithTopics}
          topicRefId={topic.topicId}
          handleSubmit={() => {
            setIsAssignmentContinueClicked(false);
            setIsQuizContinueClicked(false);
            setIsOnlineSessionContinueClicked(false);
            setIsSurveyContinueClicked(false);
            setIsForumContinueClicked(false);
            setIsFileContinueClicked(false);
            setIsDocumentContinueClicked(false);
            setIsFolderContinueClicked(false);
            setIsLinkContinueClicked(false);
            setIsLinkTreeContinueClicked(false);
            setIsVideoContinueClicked(false);
            setIsHtmlContinueClicked(false);
            setIsPictureContinueClicked(true);
            setIsScormContinueClicked(false);
            setIsTeamsContinueClicked(false);
          }}
          setShowEditContent={setShowEditContent}
          showEditContent={showEditContent}
          editContent={() => {
            if (isContentId !== undefined && contentTitle !== undefined)
              editContent(isContentId, contentTitle);
          }}
          newContentTitle={contentTitle}
          contentId={isContentId}
          setContentTitleInTopic={setContentTitleInTopic}
        />
      )} */}
      {/* {(isScormDrop || (showEditContent && isContentType === "Scorm")) && (
        <PopupScorm
          topicContents={topic.contents}
          show={isScormDrop}
          setShow={setIsScormDrop}
          activeGetModulesWithTopics={activeGetModulesWithTopics}
          topicRefId={topic.topicId}
          handleSubmit={() => {
            setIsAssignmentContinueClicked(false);
            setIsQuizContinueClicked(false);
            setIsOnlineSessionContinueClicked(false);
            setIsSurveyContinueClicked(false);
            setIsForumContinueClicked(false);
            setIsFileContinueClicked(false);
            setIsDocumentContinueClicked(false);
            setIsFolderContinueClicked(false);
            setIsLinkContinueClicked(false);
            setIsLinkTreeContinueClicked(false);
            setIsVideoContinueClicked(false);
            setIsHtmlContinueClicked(false);
            setIsPictureContinueClicked(false);
            setIsScormContinueClicked(true);
            setIsTeamsContinueClicked(false);
          }}
          setShowEditContent={setShowEditContent}
          showEditContent={showEditContent}
          editContent={() => {
            if (isContentId !== undefined && contentTitle !== undefined)
              editContent(isContentId, contentTitle);
          }}
          newContentTitle={contentTitle}
          contentId={isContentId}
          setContentTitleInTopic={setContentTitleInTopic}
        />
      )} */}
    </>
  );
};

export default Topic;
