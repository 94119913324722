import styled from "styled-components";
import { breakpoints } from "../../theme";
interface GroupsHolderProps {
  groupslength: number;
  boxShadow?: string;
  theme?: string;
}

export const Overlay = styled.div<{ isvisible: string }>`
  display: ${(props) => (props.isvisible ? "block" : "none")};
  opacity: ${(props) => (props.isvisible ? "1" : "0")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 300vh;
  transition: opacity 500ms;
  overflow: auto;
  z-index: 10;
`;

export const MainHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 76.35%;
  height: 100%;
  row-gap: 2.19rem;
  margin-bottom: 2.63rem;

  /* @media (min-width: ${breakpoints.large}) {
    height: 100vh;
  }
  @media (max-width: ${breakpoints.large}) {
    height: 100vh;
  } */
  @media (max-width: 860px) {
    width: 75%;
  }
  @media (max-width: ${breakpoints.tablets}) {
    width: 100%;
    height: 76% !important;
    margin-top: 1rem !important;
  }
`;
interface DragDroppableGroupsWrapperProps {
  boxShadow: string;
  moduleDragNDropBgColor: string;
  borderModulesContainerColor: number;
}
export const DragDroppableGroupsWrapper = styled.div<DragDroppableGroupsWrapperProps>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.moduleDragNDropBgColor};
  padding: 1.88rem;
  border-radius: 0.625rem;
  border: ${(props) => props.borderModulesContainerColor}px solid #576371;
  display: flex;
  flex-direction: column;
  box-shadow: ${(props) => props.boxShadow};
`;
export const PublishCancelWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 2.62rem; */
  column-gap: 0.94rem;
`;
interface TooltipProps {
  bgColor: string;
  textColor: string;
  boxShadow: string;
}
export const Tooltip = styled.div<TooltipProps>`
  visibility: hidden;
  border-radius: 1.25rem;
  background: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  box-shadow: ${(props) => props.boxShadow};
  width: fit-content;
  padding: 0.5rem 1.38rem 0.63rem 1.25rem;
  text-align: center;
  position: absolute;
  top: 3.35rem;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 5;
`;

export const AddAndRandomizeWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: end;
  align-items: center;
  column-gap: 0.62rem;
  margin-bottom: 0.62rem;
  & > :not(:last-child):hover {
    cursor: pointer;
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
  & > :nth-child(4):hover {
    cursor: pointer;
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;
  height: fit-content;
  &:hover {
    ${Tooltip} {
      visibility: visible;
      opacity: 0.9;
      color: #fff;
    }
  }
`;

export const SketchPickerHolder = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  position: absolute;
  &:dir(ltr) {
    right: 1.88rem;
  }
  &:dir(rtl) {
    left: 1.88rem;
  }
  top: 7rem;
  @media (max-width: ${breakpoints.mobile}) {
    top: 8.2rem;
  }
  z-index: 25;
`;

export const SketchPickerClose = styled.div`
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.dark.bg_secondary_color};
  padding: 0.3rem;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
`;

export const SketchPickerSave = styled.div`
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.dark.bg_secondary_color};
  padding: 0.3rem;
  position: absolute;
  bottom: 0.45rem;
  right: 0.5rem;
`;

export const GroupsHolder = styled.div<GroupsHolderProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.5;
  padding: 0.87rem;
  column-gap: 2.56rem;
  row-gap: 2.5rem;
  justify-content: start;
  align-items: ${(props) => (props.groupslength > 1 ? "start" : "center")};
  height: ${(props) => (props.groupslength > 1 ? "fit-content" : "100%")};
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;

  & > :first-child {
    position: absolute;
    box-shadow: ${(props) => props.boxShadow};
    &:dir(ltr) {
      left: 0;
    }
    &:dir(rtl) {
      right: 0;
    }
    top: 0;
    width: 23.35% !important;
    height: 95.5% !important;
    /* padding: 0 1.2rem 1.2rem 1.2rem; */

    @media (min-width: ${breakpoints.large}) {
      height: 96% !important;
    }

    @media (max-width: ${breakpoints.tablets}) {
      width: 100% !important;
      height: 23.65vh !important;
      min-height: 8rem !important;
      max-height: 10rem !important;
      align-items: ${(props) => (props.groupslength === 1 ? "end" : "start")};
    }
    /* @media (min-width: 1367px) {
      height: 89vh !important;
    }
    @media (min-width: ${breakpoints.extraLarge}) {
      height: 91vh !important;
    } */
  }

  &::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)"
        : "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)"};

    border-radius: 0.625rem;
    box-shadow: ${(props) =>
      props.theme === "light"
        ? "none"
        : "-10px 0px 10px 0px rgba(0, 0, 0, 0.25)"};
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => (props.theme === "light" ? "#fff" : "#2d3741")};
    border-radius: 0.625rem;
  }
`;

export const LogoLabelEmptyGroups = styled.div<GroupsHolderProps>`
  margin-top: ${(props) => (props.groupslength > 1 ? "0" : "-4rem")};
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.62rem;
  & > :first-child {
    margin-bottom: 0.63rem;
    text-align: center;
  }
  & > :nth-child(2) {
    text-align: center;
  }
  & > :last-child {
    text-align: center;
  }
  @media (max-width: ${breakpoints.tablets}) {
    margin-top: ${(props) => (props.groupslength > 1 ? "0" : "0.5rem")};
  }
  @media (max-width: ${breakpoints.mobile}) {
    margin-top: ${(props) => (props.groupslength > 1 ? "0" : "0.5rem")};
  }
`;
