import React, { useState, FC, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  generateUUID,
  uuidSplitter,
  auth0ProviderSplitter
} from "../../utils/StringManipulation";
import axios from "axios";
import { Container } from "../../elements/StyledContainerForm/StyledContainerForm.style";
import {
  LightTypography,
  PrimaryFontBoldText,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  LabelDiv,
  RadioDiv,
  RadioDivLabel,
  GridDiv,
  BtnsDiv,
  LabelBtnsDiv,
  ButtonsDiv,
  FlexDiv,
  FlexBetween,
} from "./VideoForm.style";
import StyledInput from "../../elements/StyledInput/StyledInput";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { DropZoneForm } from "../../elements/dropZone/DropZoneForm";
import CheckBoxInput from "../../elements/checkbox/CheckBox";
import {
  SvgClose,
  SvgVideo2,
  SvgImagesIcon,
  SvgImportIcon,
} from "../../elements/Icons";
import StyledSelect from "../../elements/StyledSelect/StyledSelect";
import Toggle from "../../elements/Toogle/toogle";
import TwoDatePicker from "../../elements/TwoDatePicker/TwoDatePicker";
import CancelButton from "../../elements/StyledButton/CancelButton";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import { useStateContext } from "../../contexts/ContextProvider";
import { S3Upload } from "../../services/S3Put";
import LoaderThreeDot from "../loader/loaderThreeDot/LoaderThreeDot";
import { ContentFormProps } from "../../interfaces/ContentFormProps";
import isObjectEmpty from "../../utils/IsObjectEmpty";
import { FileInterface } from "../../interfaces/ContentFormProps";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { WebSocketConnector } from "../../Views/messages/webSocketConnection";
import { WebsocketContext } from "../../contexts/notificationContext";

const VideoForm: FC<ContentFormProps> = ({
  setShow,
  handleSubmit,
  setShowEditContent,
  showEditContent,
  setContentTitleInTopic,
  editContent,
  newContentTitle,
  contentId,
  activeGetModulesWithTopics,
  newTopicRefId,
  mediaName,
  mediaDescription,
  mediaFile
}) => {
  const { t } = useTranslation();
  const { backgroundColor, themeTextColor, inputBorderColor, iconCloseColor } =
    useRecoilValue(ColorSelector);
  const classSourceId = useParams();
  const setClassSourceId = classSourceId.id;
  const { isLoading, user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const vidPath = `class/${setClassSourceId}/resources/videos`;
  const vidThumbPath = `class/${setClassSourceId}/resources/videos/thumbs`;
  const watermarkPath = `class/${setClassSourceId}/resources/videos/watermarks`;
  const transcPath = `class/${setClassSourceId}/resources/videos/Transcripts`;
  const baseURL = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";
  const path = "resource/create";
  const tenantName = localStorage.getItem("tenant");
  const {
    setContent_Title,
    contentType,
    setContent_Data,
    getContent_Data,
    contentIdToEdit,
    topicRefId,
    content_Index,
    setIsPopUpOpen,
  } = useStateContext();

  const [prevData, setPrevData] = useState<any>();
  const [prevDataAttachments, setPrevDataAttachments] = useState<any>();
  const [prevDataThumbnail, setPrevDataThumbnail] = useState<any>();
  const [readyToEdit, setReadyToEdit] = useState(true);
  const [foundRefIdState, setFoundRefIdState] = useState<number>();
  const [selectedRadio, setSelectedRadio] = useState("thumbnail");
  const [visibleStudents, setVisibleStudents] = useState(true);
  const [showTimeVisibility, setShowTimeVisibility] = useState(false);
  const [isDownloadable, setIsDownloadable] = useState(false);
  const [videoName, setVideoName] = useState("");
  const [description, setDescription] = useState("");
  const [videoSec, setVideoSec] = useState("");
  const [thumbnail, setthumbnail] = useState<FileList | null>(null);
  const [waterMark, setWaterMark] = useState<FileList | null>(null);
  const [transcription, setTranscription] = useState<FileList | null>(null);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [error, setError] = useState(false);
  const [firstError, setFirstError] = useState(true);
  const [newFile, setNewFile] = useState<FileInterface>({ path: "" });
  const [vidUUId, setVidUUId] = useState("");
  const [thumbUUId, setThumbUUId] = useState("");
  const [waterMarkUUId, setWaterMarkUUId] = useState("");
  const [transcUUId, setTranscUUId] = useState("");
  const [readyToPost, setReadyToPost] = useState(true);
  const [isSetLoading, setIsSetLoading] = useState(false);

  const prev_Data = getContent_Data(contentIdToEdit, "Video");
  const { isReady, value, send } = useContext(WebsocketContext);

  useEffect(()=>{
    setVideoName(mediaName ? mediaName : "")
    setDescription(mediaDescription ? mediaDescription : "")
  }
  ,[mediaName, mediaDescription])

  // Function to update form
  const updateVideo = async (
    path: string,
    body: any,
    videoId: number | undefined
  ) => {
    try {
      const response = await axios.put(
        `${baseURL}/${path}/${videoId}/${tenantName}`,
        { data: body }
      );
      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
      throw error; // Rethrow the error to be handled by the calling service
    }
  };

  // Function to get data
  const getVideoToEdit = async (path: string, videoId: number) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `${baseURL}/${path}/${videoId}/${tenantName}`
      );
      setPrevData(res.data.data);
      const fileNameResolved = uuidSplitter(
        res.data.data.path?.split("videos/")[1]
      );
      setPrevDataAttachments(fileNameResolved);
      const thumbnailResolved = uuidSplitter(
        res.data.data.thumbnail?.split("thumbs/")[1]
      );
      setPrevDataThumbnail(thumbnailResolved);
      setReadyToEdit(false);
      const toReturn: any[] = res.data.data;
      return toReturn;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (showEditContent && videoName !== "") {
      setReadyToEdit(false);
    }
  }, [videoName]);

  // This useEffect hook is used to fetch data when showEditContent is true.
  useEffect(() => {
    const fetchData = async () => {
      const yourArray = await activeGetModulesWithTopics();
      const solutionArray: any[] = Array.isArray(yourArray) ? yourArray : [];
      function findRefIdInModules(
        modulesArray: any[],
        targetContentId: number
      ) {
        for (let i = 0; i < modulesArray.length; i++) {
          const topicsArray = modulesArray[i].topicsArray;

          for (let j = 0; j < topicsArray.length; j++) {
            const contentsArray = topicsArray[j].contents;

            for (let k = 0; k < contentsArray.length; k++) {
              if (contentsArray[k].contentId === targetContentId) {
                return contentsArray[k].refId;
              }
            }
          }
        }
        // Return a default value (e.g., null) if the contentId is not found
        return null;
      }
      if (contentIdToEdit) {
        const contentIdToFind = contentIdToEdit; // Replace with the specific contentId you're looking for
        const foundRefId = findRefIdInModules(solutionArray, contentIdToFind);
        setFoundRefIdState(foundRefId);

        await getVideoToEdit("resource/video", foundRefId);
      }
    };
    if (showEditContent) {
      fetchData();
      // setReadyToEdit(false);
    }
  }, []);

  // This useEffect hook is used to update the states with edit data (prevData)
  useEffect(() => {
    if (prevData && showEditContent) {
      setContent_Title(prevData.title);
      setVideoName(prevData.title);
      setDescription(prevData.description);
      setVideoSec(prevData.videoSec);
      setSelectedRadio(prevData.selectedRadio);
      setVisibleStudents(prevData.isVisible);
      setShowTimeVisibility(
        prevData.visibleFrom === null || prevData.visibleTo === null
          ? false
          : true
      );
      setFromDate(prevData.visibleFrom ? new Date(prevData.visibleFrom) : null);
      setToDate(prevData.visibleTo ? new Date(prevData.visibleTo) : null);
      // setNewFile({ path: prevData.file });
      if (prevData && showEditContent && prevDataAttachments) {
        setNewFile(prevDataAttachments);
        // setReadyToEdit(false);
      }
      setIsDownloadable(prevData.downloadable);
      // if (thumbnail && thumbnail != undefined) {
      //   setthumbnail(thumbnail);
      // }
      // if (waterMark && waterMark != undefined) {
      //   setWaterMark(waterMark);
      // }
      // if (transcription && transcription != undefined) {
      //   setTranscription(transcription);
      // }
    }
  }, [prevData, prevDataAttachments]);

  // Function to handle description
  const handleDescriptionChange = (value: any) => {
    setDescription(value);
  };

  useEffect(()=>{
    const handleFile = async () => {
      if (!mediaFile) return; // Exit if mediaFile does not exist
      
      setReadyToPost(false);
      setNewFile(mediaFile);
      const fileNameUUid = `${generateUUID()}${mediaFile.path}`;
      const response = await S3Upload(vidPath, mediaFile.path, fileNameUUid, mediaFile);
      setVidUUId(fileNameUUid);
    };
    handleFile()
  },[mediaFile])

  // function to handle video and s3 upload
  const handleFile = async (value: any) => {
    setReadyToPost(false);
    setNewFile(value);
    const fileNameUUid = `${generateUUID()}${value.path}`;
    const response = await S3Upload(vidPath, value.path, fileNameUUid, value);
    setVidUUId(fileNameUUid);
  };

  // handle toggle change visiblity for students
  const handleToggleChangeVisible = (isChecked: boolean) => {
    setVisibleStudents(isChecked);
  };

  // handle toggle change for downloadable option
  const handleToggleDownloadable = (isChecked: boolean) => {
    setIsDownloadable(isChecked);
  };

  const handleToggleChangeSelect = (isChecked: boolean) => {
    setSelectedRadio(isChecked ? "thumbnail" : "video"); // Update the selected radio
  };

  // function to set time visibility interval
  const handleTwoDatePickerChange = (start: Date | null, end: Date | null) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleToggleChangeTime = (isChecked: boolean) => {
    if (isChecked) {
      setShowTimeVisibility(true); // Show Time Visibility when the toggle is on
    } else {
      setShowTimeVisibility(false);
    }
  };

  // function to handle thumbnail
  const handlethumbnail = async (files: FileList | null) => {
    if (files) {
      setReadyToPost(false);
      setthumbnail(files);
      const fileNameUUid = `${generateUUID()}${files[0].name}`;
      const response = await S3Upload(
        vidThumbPath,
        files[0].name,
        fileNameUUid,
        files[0]
      );
      setThumbUUId(fileNameUUid);
    }
  };

  // function to handle watermark
  const handleWaterMark = async (files: FileList | null) => {
    if (files) {
      setReadyToPost(false);
      setWaterMark(files);
      const fileNameUUid = `${generateUUID()}${files[0].name}`;
      const response = await S3Upload(
        watermarkPath,
        files[0].name,
        fileNameUUid,
        files[0]
      );
      setWaterMarkUUId(fileNameUUid);
    }
  };

  // function to handle transcription
  const handleTranscription = async (files: FileList | null) => {
    if (files) {
      setReadyToPost(false);
      setTranscription(files);
      const fileNameUUid = `${generateUUID()}${files[0].name}`;
      const response = await S3Upload(
        transcPath,
        files[0].name,
        fileNameUUid,
        files[0]
      );
      setTranscUUId(fileNameUUid);
    }
  };

  // function to check if the number is positive
  const isPositiveNumber = (value: string) => {
    return /^\d+(\.\d+)?$/.test(value) && parseFloat(value) >= 0;
  };

  const handleInputChange = (
    value: string,
    setStateFunction: (value: string) => void
  ) => {
    if (isPositiveNumber(value) || value === "") {
      setStateFunction(value);
      setError(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (
      vidUUId !== "" ||
      thumbUUId !== "" ||
      waterMarkUUId !== "" ||
      transcUUId !== ""
    ) {
      setReadyToPost(true);
    }
  }, [vidUUId, thumbUUId, waterMarkUUId, transcUUId]);

  useEffect(() => {
    if (
      videoName ||
      description ||
      isObjectEmpty(newFile) === false ||
      (showTimeVisibility && (!fromDate || !toDate))
    ) {
      setFirstError(false);
    }
  }, [videoName, description, newFile, showTimeVisibility, fromDate, toDate]);

  // function to handle validation
  const handleFormValidation = async () => {
    if (
      !videoName ||
      !description ||
      isObjectEmpty(newFile) === true ||
      // (selectedRadio === "video" && !videoSec) ||
      (showTimeVisibility && (!fromDate || !toDate))
    ) {
      if (!firstError) {
        setError(true);
      }
      setShow(true);
      return false;
    } else {
      setError(false);
      if (contentId !== undefined && newContentTitle !== undefined)
        editContent(contentId, newContentTitle);
      return true;
    }
  };

  useEffect(() => {
    // if (showEditContent && !readyToEdit)
    handleFormValidation();
  }, [videoName, description, newFile, showTimeVisibility, fromDate, toDate]);

  // handle save
  const handleContinueClick = async () => {
    const isValid = await handleFormValidation();
    if (isValid) {
      if (!showEditContent) {
        handleSubmit();
      }
      try {
        let fileToUpdate =
          newFile && newFile.path ? newFile.path : prevDataAttachments;
        let ThumbToUpdate =
          thumbnail && thumbnail[0].name
            ? thumbnail[0].name
            : prevDataThumbnail;
        // if (readyToPost && (vidUUId || thumbUUId || waterMarkUUId || transcUUId)) {
        const videoData: any = {
          _id: contentIdToEdit,
          classSourceId: setClassSourceId,
          userSourceId: subId,
          provider:provider,
          contentIndex: content_Index,
          topicRefId: newTopicRefId,
          type: "Video",
          title: videoName,
          description: description,
          file: fileToUpdate,
          videoPath:
            newFile && newFile.path ? `${vidPath}/${vidUUId}` : prevData.path,
          // thumbPath:
          //   thumbnail && thumbnail[0].name
          //     ? `${vidThumbPath}/${thumbUUId}`
          //     : prevData.thumbnail,
          waterMarkPath:
            waterMark !== null ? `${watermarkPath}/${waterMarkUUId}` : "",
          transcriptPath:
            transcription !== null ? `${transcPath}/${transcUUId}` : "",
          selectedRadio: selectedRadio,
          videoSec: videoSec,
          // thumbnail: ThumbToUpdate,
          waterMark:
            waterMark !== null
              ? Array.from(waterMark).map((waterMark) => waterMark.name)
              : "",
          transcription:
            transcription !== null
              ? Array.from(transcription).map(
                  (transcription) => transcription.name
                )
              : "",
          showTimeVisibility: showTimeVisibility,
          visibleStudents: visibleStudents,
          fromDate: fromDate,
          toDate: toDate,
          downloadable: isDownloadable,
        };
        if (!showEditContent) {
          const response = await axios.post(
            `${baseURL}/${path}/${tenantName}`,
            { data: videoData }
          );
          if (isReady && send) {

            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "create",
                  title: videoName,
                  contentType: "video",
                  refId: response.data.data.resourceId,
                  refTable: "Videos",
                  subId: subId,
                  provider:provider,
                  type: "content",
                  description: `Title: ${videoName}`,
                },
              })
            );
          }
          setIsSetLoading(false);
        } else if (showEditContent) {
          await updateVideo("resource/video", videoData, foundRefIdState);
          if (isReady && send) {
            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "update",
                  title: videoName,
                  contentType: "video",
                  subId: subId,
                  provider:provider,
                  refId: foundRefIdState,
                  refTable: "Videos",
                  type: "content",
                  description: `Title: ${videoName}`,
                },
              })
            );
          }
        }
        setShow(false);
        setIsPopUpOpen(false);
        await activeGetModulesWithTopics();
        // }
      } catch (error) {
        console.error("Axios Error:", error);
      } finally {
        await (activeGetModulesWithTopics && activeGetModulesWithTopics());
      }
    }
  };

  // function to set accepted type for file
  const accF: { [key: string]: string[] } = {
    "video/mp4": [".mp4"],
    "video/quicktime": [".mov"],
  };

  return (
    <Container
      themetextcolor={themeTextColor}
      backgroundcolor={backgroundColor}
      scrollbar={inputBorderColor}
    >
      {/* header */}
      {/* <FlexBetween>
        <FlexDiv>
          <SvgVideo2 />
          <PrimaryFontBoldText text={t("video.video")} fontSize="1.125rem" />
        </FlexDiv> */}
        {showEditContent && readyToEdit && <LoaderThreeDot />}
        {/* <SvgClose
          color={iconCloseColor}
          onClick={() => {
            setShow(false);
            setIsPopUpOpen(false);
            setShowEditContent(false);
          }}
          style={{ cursor: "pointer" }}
        /> */}
      {/* </FlexBetween> */}

      {/* title */}
      <LabelDiv>
        <FlexDiv gap="0.25rem">
          <LightTypography text={t("forms.Title")} fontSize="0.875rem" />
          <span>*</span>
        </FlexDiv>
        <StyledInput
          placeholder={t("video.Enter Video Title")}
          type="text"
          onChange={(e: any) => {
            setVideoName(e.target.value);
            setContent_Title(e.target.value);
            setContentTitleInTopic(e.target.value);
          }}
          value={videoName}
          width="100%"
        />
        {!videoName && error && (
          <ErrorDiv>{`${t("forms.Please")} ${t(
            "video.Enter Video Title"
          )}`}</ErrorDiv>
        )}
      </LabelDiv>

      {/* description */}
      <LabelDiv>
        <FlexDiv gap="0.25rem">
          <LightTypography text={t("forms.Description")} fontSize="0.875rem" />
          <span>*</span>
        </FlexDiv>
        <TinyEditor
          onChange={handleDescriptionChange}
          placeholder={t("forms.Description")}
          initialValue={`${
            prevData && prevData.description ? prevData.description : description
          }`}
          height="13.25rem"
        />
        {!description && error && (
          <ErrorDiv>{`${t("forms.Please")} ${t(
            "forms.Enter Description"
          )}`}</ErrorDiv>
        )}
      </LabelDiv>

      {/* dropzone */}
      <DropZoneForm
        text={`${t("video.Click or Drag and Drop, to upload Video")} *`}
        testDrop={handleFile}
        acceptedTypes={accF}
        initialFile={mediaFile ? mediaFile.path : prevDataAttachments}
      />
      {isObjectEmpty(newFile) === true && error && (
        <ErrorDiv>{t("course view.Please Select a Video")}</ErrorDiv>
      )}

      {/* upload thumbnail */}
      {/* <RadioDiv> */}
      {/* <GridDiv> */}
      {/* <CheckBoxInput name="SelectUpload" value="thumbnail" checked={selectedRadio === "thumbnail"} onChange={() => handleToggleChangeSelect(true)} /> */}
      {/* <TypographyRegularDescription
            text={t('video.Upload Thumbnail')}
            fontSize="1rem"
          /> */}
      {/* {selectedRadio === "thumbnail" && ( */}
      {/* <div>
            <StyledSelect
              onChange={handlethumbnail}
              title={`${
                prevDataThumbnail ? prevDataThumbnail : `${t('video.Upload Thumbnail')}`
              }`}
              icon={SvgImagesIcon}
              acceptedFileTypes=".jpg, .jpeg, .png"
              disabled={!readyToPost ? true : false}
            />
          </div> */}
      {/* )} */}
      {/* </GridDiv> */}
      {/* <RadioDivLabel>
          <CheckBoxInput name="SelectUpload" value="video" checked={selectedRadio === "video"} onChange={() => handleToggleChangeSelect(false)} />
          <TypographyRegularDescription text="Choose From Video" fontSize="1rem" />
          {selectedRadio === "video" && (
            <StyledInput placeholder="At What Second" type="number" width="10.9375rem" onChange={(e) => handleInputChange(e.target.value, setVideoSec)} value={videoSec} />
          )}
          {selectedRadio === "video" && !videoSec && error && ( <ErrorDiv>Please Select At What Second</ErrorDiv> )}
        </RadioDivLabel> */}
      {/* </RadioDiv> */}

      {/* <GridDiv>
        <TypographyRegularDescription text="Watermark Image" fontSize="1rem" />
        <StyledSelect onChange={handleWaterMark} title={`${ prev_Data.waterMark != "undefined" && prev_Data.waterMark != undefined ? prev_Data.waterMark : "Upload Image" }`} icon={SvgImageIcon} acceptedFileTypes=".jpg, .jpeg, .png" />
      </GridDiv> */}

      {/* Transcription */}
      <GridDiv>
        <TypographyRegularDescription
          text={t("video.Transcription")}
          fontSize="1rem"
        />
        <StyledSelect
          onChange={handleTranscription}
          title={`${
            prev_Data.transcription != "undefined" &&
            prev_Data.transcription != undefined
              ? prev_Data.transcription
              : `${t("video.Upload File")}`
          }`}
          icon={SvgImportIcon}
          acceptedFileTypes=".vtt"
        />
      </GridDiv>

      {/* toggles */}
      <BtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleDownloadable}
            checked={isDownloadable}
            id="isDownloadable"
          />
          <TypographyRegularDescription
            text={t("document.Downloadable")}
            fontSize="1rem"
          />
        </LabelBtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleChangeVisible}
            checked={visibleStudents}
            id="roleToggle1"
          />
          <TypographyRegularDescription
            text={t("forms.Make Visible for Students")}
            fontSize="1rem"
          />
        </LabelBtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleChangeTime}
            checked={showTimeVisibility}
            id="roleToggle2"
          />
          <TypographyRegularDescription
            text={t("forms.Set Time Visibility Interval")}
            fontSize="1rem"
          />
          {showTimeVisibility && (
            <>
              <TwoDatePicker
                onDateChange={handleTwoDatePickerChange}
                prevStartDate={fromDate}
                prevEndDate={toDate}
              />
              {(!fromDate || !toDate) && error && (
                <ErrorDiv>{`${t("forms.Please")} ${t(
                  "forms.Set Time Visibility Interval"
                )}`}</ErrorDiv>
              )}
            </>
          )}
        </LabelBtnsDiv>
      </BtnsDiv>

      {/* actions buttons */}
      <ButtonsDiv>
        <CancelButton
          name={t("forms.Cancel")}
          onClickFunction={() => {
            setShow(false);
            setIsPopUpOpen(false);
            setShowEditContent(false);
          }}
          color="#D85D66"
          borderColor="#D85D66"
          backgroundColor="transparent"
        />
        {readyToPost ? (
          <CancelButton
            name={t("forms.Save")}
            onClickFunction={async () => {
              if (!error) {
                await setIsSetLoading(true);
                setShowEditContent(false);
                await handleContinueClick();
                setContent_Title(videoName);
              }
            }}
            disabled={isSetLoading}
            color="#fff"
            borderColor="#5DD3B3"
            backgroundColor="#5DD3B3"
          />
        ) : (
          <CancelButton
            name="Loading"
            display="block"
            onClickFunction={() => {
              handleFormValidation();
              setContent_Title(videoName);
            }}
            color="#fff"
            borderColor="#5DD3B3"
            backgroundColor="#5DD3B3"
          />
        )}
      </ButtonsDiv>
    </Container>
  );
};

export default VideoForm;
