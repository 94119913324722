import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const VideoPlayerContainer = styled.div`
  .captionsMenu {
    height: auto;
    width: 140px;
    position: absolute;
    bottom: 3rem;
    right: -1rem;
    color: white !important;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .captionLangItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .captions {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white !important;
    padding: 5px 10px;
    font-size: 16px;
    white-space: pre-line;
  }
`;
export const Video = styled.video`
  width: 100%;
  height: 40rem;
  border-radius: 0.625rem;
  overflow: hidden;
  video.loading {
    background: black url("../../assets/loadingGif.gif") center center no-repeat;
  }
`;
export const VideoControlsMainContainer = styled.video``;

export const VideoContainer = styled.div<StyleThemeInterface>`
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  margin-inline: auto;
  background-color: "#2D3741" !important;
  position: relative;
  border-radius: 0.625rem;
  background: rgba(87, 99, 113, 0.6);
  /* padding-top: 0.5rem; */
  background: ${(props) => props.bgcolor || "rgba(87, 99, 113, 0.6)"};
`;
export const VideoControlsContainer = styled.div`
  padding: 0.5rem;
  position: absolute;
  bottom: 0;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
`;
export const TimelineContainer = styled.div`
  width: 100%;
  height: 0.56rem;
  background-color: rgba(255, 255, 255, 0.5);
  position: relative;
`;
export const CurrentTimeLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  /* width: 0%; */
  height: 100%;
  background-color: #5dd3b3;
`;
export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const LeftRightControls = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.25rem;
`;
export const Btn = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
`;
export const DurationCount = styled.div`
  margin: 0.5rem;
  font-size: 1.1rem;
  font-weight: 700;
  color: white;
`;
export const ButtonBox = styled.div`
  position: relative;
`;
export const VideoPlayIcon = styled.div`
  position: absolute;
  top: 40%;
  cursor: pointer;
  z-index: 1;
`;
export const PlyBtn = styled.button`
  padding: 2px 5px;
  width: max-content;
  color: white;
  background-color: transparent;
  font-weight: 700;
  cursor: pointer;
`;
export const PlayBackMenu = styled.div`
  height: auto;
  width: 5rem;
  position: absolute;
  bottom: 3rem;
  left: -2rem;
  color: white !important;
  background-color: #1f2731;
`;
export const VolumeProgressBar = styled.div`
  display: none;
  position: absolute;
  left: -4rem;
  top: -5rem;
  padding-left: 1rem;
  transform: rotate(270deg);
  width: max-content;
  height: max-content;
  cursor: pointer;
`;
export const VolumeDiv = styled.div`
  position: relative;
  &:hover {
    ${VolumeProgressBar} {
      display: flex;
    }
  }
  input[type="range"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 100%;
    height: 1rem;
    cursor: pointer;
    outline: none;
    /*  slider progress trick  */
    overflow: hidden;
    border-radius: 0.25rem;
  }

  /* Track: webkit browsers */
  input[type="range"]::-webkit-slider-runnable-track {
    height: 0.9rem;
    background: white;
    border-radius: 0.25rem;
  }

  /* Track: Mozilla Firefox */
  input[type="range"]::-moz-range-track {
    height: 0.9rem;
    background: white;
    border-radius: 0.25rem;
  }

  /* Thumb: webkit */
  input[type="range"]::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    height: 0.95rem;
    width: 0.95rem;
    background-color: #5dd3b3;
    border-radius: 0.25rem;
    /* border: 2px solid #f50; */
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px #5dd3b3;
  }

  /* Thumb: Firefox */
  input[type="range"]::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: #5dd3b3;
    border-radius: 0.25rem;
    border: 1px solid #f50;
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px #f50;
  }
`;
export const Volumebar = styled.input`
  background: white;
  /* accent-color: #5dd3b3; */
`;
export const PlaybackOptionItem = styled.div<{ isactive: boolean }>`
  min-width: 5rem;
  /* font-size: 1rem; */
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &::after {
    font-size: 1rem;
    color: white;
    visibility: ${(props) => (props.isactive ? "visible" : "hidden")};
  }
`;

export const DownloadIconPlace = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  margin-top: -0.5rem;
  z-index: 5;
`;
