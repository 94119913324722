import React, { FC, useState, useRef, useEffect } from "react";
import {
  ColorPickerMainHolder,
  ColorPickerHolder,
  ColorPickerButton,
  ColorPickerDiv,
} from "./ColorPickerMenu.style";
import { ColorPickerData } from "./colorPickerData";
import { useStateContext } from "../../contexts/ContextProvider";

interface ReusableColorPickerInterface {
  onColorChange?: (color: string) => void;
  position?: string;
  top?: string;
  left?: string;
  type: string;
  width?: string;
  height?: string;
  flexWrap?: string;
}

const ReusableColorPicker: FC<ReusableColorPickerInterface> = ({
  onColorChange,
  position,
  top,
  left,
  type,
  width,
  height,
  flexWrap,
}) => {
  const {
    setIsBigLineColorShown,
    setIsSmallLineColorShown,
    setIsRectangleColorShown,
    isBigLineColorShown,
    isSmallLineColorShown,
    isRectangleColorShown,
    setBigLineColor,
    setSmallLineColor,
    setRectangleColor,
  } = useStateContext();

  const [isClicked, setIsClicked] = useState<boolean>(false);

  const handleButtonClick = (color: string) => {
    onColorChange && onColorChange(color);
    setIsClicked(true);

    if (type === "bigLine") {
      setBigLineColor(color);
    } else if (type === "smallLine") {
      setSmallLineColor(color);
    } else if (type === "rectangleLine") {
      setRectangleColor(color);
    }
    // Pass the selected color to the parent component via the onColorChange prop
  };
  return (
    <ColorPickerHolder
      position={position || "absolute"}
      left={left || "0rem"}
      top={top || "0.81rem"}
      width={width || "18.4375rem"}
      height={height || "2.1875rem"}
      flexWrap={flexWrap || "nowrap"}
    >
      {ColorPickerData.map((color) => (
        <ColorPickerButton
          key={color.name}
          backgroundcolor={color.color}
          onClick={() => handleButtonClick(color.color)}
          className={isClicked ? "clicked" : ""}
        ></ColorPickerButton>
      ))}
      <ColorPickerDiv
        className={
          isBigLineColorShown || isSmallLineColorShown || isRectangleColorShown
            ? "rotated"
            : ""
        }
        onClick={() => {
          if (type === "bigLine") {
            setIsBigLineColorShown(true);
          } else if (type === "smallLine") {
            setIsSmallLineColorShown(true);
          } else if (type === "rectangleLine") {
            setIsRectangleColorShown(true);
          }
        }}
      ></ColorPickerDiv>
    </ColorPickerHolder>
  );
};

export default ReusableColorPicker;
