import {
  GroupRectangle,
  TitleRow,
  IconRow,
  DoneButtonResp,
} from "./AddGRoupMembersBar.style";
import MemberInfoBox from "../../elements/memberInfoBox/MemberInfoBox";
import { GroupMemberData } from "./addGroupMemberBarData";
import {
  DragDropContainer,
  DropTarget,
} from "react-drag-drop-container-typescript";
import { useState } from "react";
// import {AddGRoupMembersBarProps} from "./AddGRoupMembersBar.style"
import { SvgArrowSimple } from "../../elements/Icons";
import {
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";

const AddGRoupMembersBar = ({
  isOpen,
  isVisible,
}: {
  isOpen: boolean;
  isVisible: boolean;
}): JSX.Element => {
  const [students, setStudents] = useState(GroupMemberData);

  const handleStudentDrop = (index: number, e: CustomEvent) => {
    const updatedStudents = [...students];
    const student = updatedStudents[index];

    // Check if the student has not been dragged before
    // if (!student.dragged) {
    //   // Set the flag to true indicating it has been dragged
    //   student.dragged = true;
    //   setStudents(updatedStudents);
    // }
  };
  const [isMembersBarVisible, setIsMembersBarVisible] = useState(false);

  const toggleMembersBar = () => {
    setIsMembersBarVisible(!isMembersBarVisible);
  };

  return (
    <>
      {isOpen && (
        <GroupRectangle isOpen={isOpen} isVisible={isVisible}>
          {/* <IconAndTitleRow> */}
          <IconRow>
            <SvgArrowSimple onClick={toggleMembersBar} />
          </IconRow>

          <TitleRow>
            <TypographyRegularDescription
              text="Select "
              fontSize="1.75rem"
              fontWeight="400"
              lineHeight="normal"
            />

            <Typography
              text="Students"
              fontSize="1.75rem"
              fontWeight="400"
              lineHeight="normal"
            />
          </TitleRow>
          {/* </IconAndTitleRow> */}
          {GroupMemberData.map((members, index) => (
            <>
              <DragDropContainer
                targetKey="student"
                dragData={{
                  index,
                  profilePicture: members.profilePicture,
                  firstName: members.firstName,
                  lastName: members.lastName,
                }}
                onDrop={(e) => handleStudentDrop(index, e)}
              >
                {/* <MemberInfoBox
                  key={index}
                  index={index}
                  profilePicture={members.profilePicture}
                  username ={members.username}
                  studentId={index}
                  groupId={-1}
                /> */}
              </DragDropContainer>
            </>
          ))}
          <DoneButtonResp>
            <Typography
              color="#fff"
              text="Done"
              fontSize="0.875rem"
              fontWeight="400"
              lineHeight="normal"
            />
          </DoneButtonResp>
        </GroupRectangle>
      )}
    </>
  );
};

export default AddGRoupMembersBar;
