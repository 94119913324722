import React, { useEffect, useState } from "react";
import { DocumentViewerDiv } from "./DocumentViewer.style";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { S3Get } from "../../services/S3Get";
import { FileContainer, FileLink } from "../fileViewer/FileViewer.style";
import { LightTypography } from "../../elements/fonts/Fonts";
import { SvgCourseIcon } from "../../elements/Icons";
import axios from "axios";
import ReactPdfViewer from "../../Views/pdfViewer/ReactPdfViewer";
import { downloadFile, getDocumentType } from "./DocumentViewerUtils";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

interface DocumentProps {
  documentUrl: string;
  downloadable?: boolean;
  documentType?: string;
  grader: boolean;
  classId?: string;
  documentId?: string;
  role: string;
}

const DocumentViewer = ({ documentUrl, grader, documentId, classId, role }: DocumentProps) => {
  const [resolvedDocumentType, setResolvedDocumentType] = useState<string>();
  const [s3Url, setS3Url] = useState("");
  const [downloadableUrl, setDownloadableUrl] = useState("");
  const [downloadable, setDownloadable] = useState(false);
  const tenantName = localStorage.getItem("tenant");
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [length, setLength] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [fileName, setFileName] = useState();
  const [docViewerKey, setDocViewerKey] = useState(0);
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const { SecondaryTextColor, bghelp, theme } = useRecoilValue(ColorSelector);

  useEffect(() => {
    setPageNumber(0);
    setPercentage(0)
    setDownloadable(false)
    const fetchDocument = async () => {
      try {
        const response = await axios.get(
          `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/resource/document/${documentId}/${tenantName}`
        );
        const response2 = await axios.get(`https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/completionTracking/get/${Number(documentId)}/Document/${subIdSplitted}/${tenantName}`)

        setData(response.data.data);
        if (response2) {
          if (response2.data.data) {
            if (response2.data.data[0]) {
              if (response2.data.data[0].length) {
                setLength(response2.data.data[0].length)
                if (response2.data.data[0].percentage) {
                  if (response2.data.data[0].percentage === 0)
                    setPageNumber(0);
                  else { setPageNumber(response2.data.data[0].percentage * (response2.data.data[0].length - 1) / 100); }
                  setPercentage(response2.data.data[0].percentage)
                }
              }
            }
          }

        }
        if (response) {
          let s3DocumentPdf;
          if (role === "student" || role === "auditor") {
            if (response.data.data.downloadable === 0) setDownloadable(false)
            else setDownloadable(true)
          }
          const s3Document = await S3Get(response.data.data.path);
          if (response.data.data.type === "PDF") {
            setS3Url(s3Document);
            setDownloadableUrl(s3Document);
            s3DocumentPdf = s3Document;
          }
          else {
            s3DocumentPdf = await S3Get(response.data.data.convertedLink);
            setS3Url(s3DocumentPdf);
            setDownloadableUrl(s3Document);

          }

          const pathSegments = response.data.data.path.toString().split("/");
          const fileName = pathSegments[pathSegments.length - 1];

          // Remove UUIDv4 from the file name
          const title = fileName.replace(
            /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}/,
            ""
          );
          setFileName(title);
          setDocViewerKey((prevKey) => prevKey + 1);
          setResolvedDocumentType(response.data.data.type);
          return { ...data, s3DocumentPdf };
        }

      } catch (error) {
        console.error("Error fetching document:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchDocument();
  }, [documentId]);

  return (
    <>
      {loading ? (
        <div>loading ...</div>
      ) : (
        // <DocumentViewerDiv isgrader={grader} downloadable={data.downloadable}>
        //   {s3Url && resolvedDocumentType === "pdf" ? (
        //     // <DocViewer
        //     //   theme={{
        //     //     textPrimary: "#ffffff",
        //     //     secondary: "#2d3741",
        //     //   }}
        //     //   documents={[
        //     //     {
        //     //       uri: s3Url,
        //     //       fileType: resolvedDocumentType,
        //     //       fileName: fileName,
        //     //     },
        //     //   ]}
        //     //   prefetchMethod="GET"
        //     //   pluginRenderers={DocViewerRenderers}
        //     //   style={{ height: "100vh", backgroundColor: "#384450" }}
        //     //   key={docViewerKey}
        //     // />
        //     <ReactPdfViewer
        //       documentUrl={s3Url}
        //       key={docViewerKey} classId={classId || ""} documentId={Number(documentId)} percentage={percentage} subId={subIdSplitted} length={length} pageNumber={pageNumber} />
        //   ) : (
        //     <FileContainer onClick={() => downloadFile(data, s3Url || "")}>
        //       <FileLink>
        //         <SvgCourseIcon
        //           color="#5dd3b3"
        //           width={"1.5rem"}
        //           height={"1.5rem"}
        //         />
        //       </FileLink>
        //       {data && (
        //         <LightTypography
        //           text={fileName || ""}
        //           fontSize="0.875rem"
        //           color="#5dd3b3"
        //         />
        //       )}
        //     </FileContainer>
        //   )}
        // </DocumentViewerDiv>
        <>
          {s3Url ?
            (<DocumentViewerDiv bgColor={theme === "light" ? "#AAB1B9" : "#384450"} boxShadow={theme === "light" ? "0px 2px 10px 0px rgba(0, 0, 0, 0.25)" : "0px 2px 10px 0px rgba(0, 0, 0, 0.25)"} isgrader={grader} downloadable={data.downloadable}>
              <ReactPdfViewer
                role={role}
                downloadable={downloadable}
                documentUrl={s3Url}
                key={docViewerKey} classId={classId || ""} documentId={Number(documentId)} percentage={percentage} subId={`${subIdSplitted}?provider=${provider}`} length={length} pageNumber={pageNumber} downloadUrl={downloadableUrl} />
            </DocumentViewerDiv>
            ) : (
              <div> Document Not Available</div>
            )}
        </>
      )}
    </>
  );
};

export default DocumentViewer;
