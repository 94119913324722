import { FC, useState, useRef, useEffect } from "react";
import {
  ActivityContainer,
  IconTextWrapper,
  VDotsWrapper,
  DraggableArea,
  TypographyWrapper,
  IconWrapper,
  VThreeDotsWrapper,
} from "./Content.style";

import { Draggable } from "react-beautiful-dnd";

import { PrimaryFontMediumText } from "../../../elements/fonts/Fonts";
import { ContentInterface } from "../../../Views/teacherView/Course-Creator/courseCreatorInterface";
import useToggle from "../../../hooks/useToggle";
import useClickOutside from "../../../hooks/useClickOutside";
import PopupDelete from "../../../elements/PopupDelete/PopupDelete";
import DropDownEditDeleteActivity from "../../dropDownEditDeleteActivity/main/DropDownEditDelete";
import { useStateContext } from "../../../contexts/ContextProvider";
import Loader from "../../loader/Loader";
import {
  SvgAssignmentIcon,
  SvgQuiz,
  SvgOnlineSessionIcon,
  SvgCourseIcon,
  SvgDocument,
  SvgWebsiteIcon,
  SvgLinkTree,
  SvgVideoNew,
  SvgImagesIcon,
  SvgMessagingIcon,
  SvgFolderIcon,
  SvgMonitorIcon,
  SvgSurveyIcon,
  SvgMoreVerticalIcon,
  SvgTeams,
} from "../../../elements/Icons";

import { useRecoilValue } from "recoil";
import { courseCreatorColorSelector } from "../../../recoil/ThemeSelectors";
import axios from "axios";
import { useParams } from "react-router-dom";
import { auth0SubIdSplitter } from "../../../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import { t } from "i18next";

/**
 * Content component displays individual activities or resources within a course module.
 * It supports drag-and-drop functionality using react-beautiful-dnd.
 * Users can edit or delete content items using a dropdown menu.
 * @param contentIndex The index of the content item within its parent topic.
 * @param contentId The unique identifier for the content item.
 * @param type The type of content (e.g., Quiz, Assignment).
 * @param color The color associated with the content type.
 * @param contentTitle The title of the content item.
 * @param content The content data or details.
 * @param topicRefId The reference ID of the parent topic.
 * @param removeContent Function to remove the content item.
 * @param reorderedContentIndex The index after the content item is reordered.
 * @param borderRadius The border radius of the content container.
 * @param setIsContentIdInTopic Function to set the content ID within its parent topic.
 * @param setIsContentIndexInTopic Function to set the content index within its parent topic.
 * @param topic The parent topic containing the content.
 * @param setShowEditContent Function to toggle the edit content modal.
 * @param moduleIndex The index of the parent module.
 * @param topicIndex The index of the parent topic.
 * @param showEditContent Boolean indicating whether the edit content modal is visible.
 * @param setIsContentTypeInTopic Function to set the content type within its parent topic.
 */
const Content: FC<ContentInterface> = ({
  contentIndex,
  contentId,
  type,
  color,
  contentTitle,
  content,
  topicRefId,
  removeContent,
  reorderedContentIndex,
  borderRadius,
  setIsContentIdInTopic,
  setIsContentIndexInTopic,
  topic,
  setShowEditContent,
  moduleIndex,
  topicIndex,
  showEditContent,
  setIsContentTypeInTopic,
  module,
}) => {
  const {
    setContent_IdToEdit,
    setContentType,
    contentType,
    isPopUpOpen,
    setIsPopUpOpen,
    setContentGlobalState,
    showEditContentPositionLoader,
    setShowEditContentPositionLoader,
    sourceIdContent,
    moduleID,
    SetEventContentId,
  } = useStateContext();

  useEffect(() => {
    if (type === "Link" || type === "Link_Tree")
      setContentGlobalState(contentType);
  }, [content]);

  // Recoil state and selectors
  const { surveyIconColor } = useRecoilValue(courseCreatorColorSelector);
  const language = localStorage.getItem("language");

  // Toggle visibility of dropdown menu
  const { isOpen: isDropdownVisible, toggle: toggleDropdown } =
    useToggle(false);
  const dropdownRef = useClickOutside(isDropdownVisible, toggleDropdown);

  // State for showing delete content confirmation popup
  const [showDeleteContent, setShowDeleteContent] = useState(false);

  // Refs to store content ID, index, and type
  const isContentIdRef = useRef<number | undefined>(undefined);
  const isContentIndexRef = useRef<number | undefined>(undefined);
  const isContentTypeRef = useRef<string | undefined>(undefined);
  useEffect(() => {
    isContentIdRef.current = contentId;
    isContentIndexRef.current = contentIndex;
    isContentTypeRef.current = type;
  }, [contentId, contentIndex, type]);

  // Function to handle showing/hiding the delete content popup
  const handleDeleteActivity = (showDeleteContent: boolean) => {
    setShowDeleteContent(showDeleteContent);
    setIsPopUpOpen(true);
  };

  const [TeamId, SetTeamId] = useState("");
  const classId = useParams();
  const setClassSourceId = classId.classId;

  // Detect and render appropriate icon based on content type
  const detectIconType = (contentType: string) => {
    switch (contentType) {
      case "Quiz":
        return <SvgQuiz color="#96B4E3" />;
      case "Assignment":
        return <SvgAssignmentIcon color="#5DD3B3" />;
      case "Online_Session":
        return <SvgOnlineSessionIcon color="#0B8FFF" />;
      case "Survey":
        return <SvgSurveyIcon color={`${surveyIconColor}`} />;
      case "Forum":
        return <SvgMessagingIcon color="#8CEDEA" />;
      case "Teams":
        return <SvgTeams color="#4E5FBF" />;
      case "File":
        return <SvgCourseIcon color="#3DA4FC" />;
      case "Document":
        return <SvgDocument color="#21D6F8" />;
      case "Folder":
        return <SvgFolderIcon color="#5D69FD" />;
      case "Link":
        return <SvgWebsiteIcon color="#8E5DFD" />;
      case "Link_Tree":
        return <SvgLinkTree color="#CED35D" />;
      case "Video":
        return <SvgVideoNew color="#B757E2" />;
      case "Html":
        return <SvgMonitorIcon color="#D04B4B" />;
      case "Picture":
        return <SvgImagesIcon color="#D07E4B" />;
      default:
        return null; // You might want to add a default case as well.
    }
  };

  const detectedIcon = detectIconType(content.type);

  return (
    <>
      <Draggable
        draggableId={`content-${moduleIndex}-${topicIndex}-${contentIndex}`}
        index={contentIndex}
        //When a popup of a content is open, disable dragging
        isDragDisabled={isPopUpOpen}
      >
        {/* Content container */}
        {(provided, snapshot) => (
          <>
            <ActivityContainer
              bordercolor={
                type === "Survey" ? surveyIconColor : color || "#576371"
              }
              borderradius={borderRadius || "0"}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                opacity: `${
                  showEditContentPositionLoader && sourceIdContent === contentId
                    ? "0.25"
                    : "1"
                }`,
                // Apply different styles when the item is being dragged
                boxShadow: snapshot.isDragging
                  ? "0px 4px 20px 0px rgba(93, 211, 179, 0.25)"
                  : "none",

                ...provided.draggableProps.style,
              }}
            >
              {/* <DraggableArea></DraggableArea> */}
              <IconTextWrapper style={{ maxWidth: "100%" }}>
                <IconWrapper>{detectedIcon}</IconWrapper>
                <TypographyWrapper>
                  <PrimaryFontMediumText
                    text={contentTitle}
                    fontSize="0.75rem"
                    color={type === "Survey" ? surveyIconColor : color}
                  />
                </TypographyWrapper>
              </IconTextWrapper>
              <VDotsWrapper
                ref={dropdownRef}
                onClick={() => {
                  toggleDropdown();
                  setContent_IdToEdit(contentId);
                  setContentType(content.type);
                  // await getOnlineSessionToEdit("activity/onlineSession", foundRefId)
                  if (content.type === "Teams") {
                    SetEventContentId("123");
                  }
                  if (isContentIdRef.current !== undefined) {
                    setIsContentIdInTopic(isContentIdRef.current);
                  }
                  if (isContentIndexRef.current !== undefined) {
                    setIsContentIndexInTopic(isContentIndexRef.current + 1);
                  }
                  if (isContentTypeRef.current !== undefined) {
                    setIsContentTypeInTopic(isContentTypeRef.current);
                  }
                }}
              >
                <SvgMoreVerticalIcon />
              </VDotsWrapper>
              {isDropdownVisible && removeContent && (
                <DropDownEditDeleteActivity
                  contentId={contentId}
                  contentType={content.type}
                  topic={topic}
                  removeContent={() => {
                    removeContent(topic, contentId, contentIndex);
                  }}
                  setShowDeleteContent={handleDeleteActivity}
                  setShowEditContent={setShowEditContent}
                />
              )}
              {showEditContentPositionLoader &&
                sourceIdContent === contentId && (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: "35%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <Loader width={20} height={20} />
                  </div>
                )}
            </ActivityContainer>
          </>
        )}
      </Draggable>
      {showDeleteContent && removeContent && (
        <PopupDelete
          show={showDeleteContent}
          setShow={setShowDeleteContent}
          title={
            language === "tr"
              ? `Bu ${t(
                  `notification.${contentType}`
                )} silmek istediğinizden emin misiniz?`
              : language === "ar" ? `${t("course view.Are you sure you want to delete this")}${t(`notification.${contentType}`)}؟`
              : `${t("course view.Are you sure you want to delete this")} ${t(`notification.${contentType}`)}?`
          }
          handleSubmit={() => {
            removeContent(topic, contentId, contentIndex);
            setIsPopUpOpen(false);
          }}
        />
      )}
    </>
  );
};

export default Content;
