import styled from "styled-components";

interface ActivityContainerProps {
  bordercolor?: string;
  borderradius?: string;
}

export const ActivityContainer = styled.div<ActivityContainerProps>`
  position: relative;
  width: 100%;
  height: 2.0625rem;

  &:dir(ltr) {
    padding: 0.62rem 1.78rem 0.5rem 2.45rem;
  }
  &:dir(rtl) {
    padding: 0.62rem 2.45rem 0.5rem 1.78rem;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ bordercolor }) => bordercolor};
  border-radius: ${({ borderradius }) => borderradius};
`;

export const ActivityContainerDragging = styled.div`
  position: relative;
  width: 100%;
  height: 2.0625rem;
  border: 1px solid #5dd3b3;
  opacity: 0.15;
  background: #000;
`;

export const DraggableArea = styled.div`
  width: 88%;
  height: 100%;
  position: absolute;
  top: 0rem;
  right: 2.5rem;
  cursor: move;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 0.48rem;

  &:dir(ltr) {
    left: 0.87rem;
  }
  &:dir(rtl) {
    right: 0.87rem;
  }
`;
export const VThreeDotsWrapper = styled.div`
  position: absolute;
  top: 0.53rem;
  right: 0.87rem;
`;

export const IconTextWrapper = styled.div`
  max-width: 100%;
  display: flex;
  column-gap: 0.56rem;
  /* background: gray; */
`;

export const VDotsWrapper = styled.div`
  width: 0.625rem;
  height: 0.625rem;
  display: flex;
  position: absolute;
  top: 0.7rem;
  &:dir(ltr) {
    right: 1.8rem;
  }
  &:dir(rtl) {
    left: 1.8rem;
  }

  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
`;

export const TypographyWrapper = styled.div`
  max-width: 100%;
  text-align: start;
  & > :first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* Set the number of lines to display before truncating */
  }
`;
