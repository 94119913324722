import React from "react";
import { ChoseModel } from "./resourcesPerModuleMain/ResourcesPerModule.style";
import { SvgEmpty, SvgNoResult } from "../../../elements/Icons";
import { Typography } from "../../../elements/fonts/Fonts";
import { EmptyProps } from "../dashboardContentInterfaces";

export default function Empty({ text }: EmptyProps) {
  return (
    <ChoseModel>
      <SvgNoResult width={"10rem"} />
      <Typography text={text} />
    </ChoseModel>
  );
}
