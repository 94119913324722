import React, { useContext, useEffect, useRef, useState } from "react";
import { CourseCard } from "../courseCardDetails/CourseCard";
import { CourseCardHolderStyle } from "./CourseCardsHolder.style";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import DashboardAnnouncements from "../../dashboardAnnouncements/DashboardAnnouncements";
import Empty from "../Empty";
import { slickSliderSettings } from "./CourseCardsSliderSettings";
import GradeBookTableView from "../../../Views/gradeBook/GradeBookTableView";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import LoaderText from "../../loader/loaderText/LoaderText";
import { CourseCardItem } from "../CourseCardInterface";
import {
  auth0ProviderSplitter,
  auth0SubIdSplitter,
} from "../../../utils/StringManipulation";
import DashboardContents from "../../dashboardContents/main/DashboardContents";
import { customSort } from "./CourseCardsHolderFunctions";
import { useTranslation } from "react-i18next";
import DashboardNotificationUpdates from "../../dashboardNotificationUpdates/main/DashboardNotificationUpdates";
import DashboardGrades from "../../dashboardGrades/DashboardGrades";
import { S3Get } from "../../../services/S3Get";
import { Typography } from "../../../elements/fonts/Fonts";
import { useRecoilState } from "recoil";
import { adminState } from "../../../recoil/RecoilStates";
import AdminCourseCard from "../adminCourseCard/AdminCourseCard";
// import { adminState } from "../../../recoil/RecoilStates";
interface coursecardsholderProps {
  setEnrollmentClasses: React.Dispatch<React.SetStateAction<any>>;
  setEnrolled: React.Dispatch<React.SetStateAction<boolean>>;
}

const CourseCardsHolder = ({
  setEnrolled,
  setEnrollmentClasses,
}: coursecardsholderProps): JSX.Element => {
  const { t } = useTranslation();
  const language = localStorage.getItem("language");
  // const isRTL = language === "ar" ? true : false
  const [isRTL, setIsRTL] = useState(false);

  useEffect(() => {
    setIsRTL(language === "ar");
  }, [language, t]);

  const { user } = useAuth0();
  const { setBData } = useContext(ClassContext);

  //handle show of unstructured popup
  const [Show, setShow] = useState(false);
  const handleShow = async () => {
    setShow(true);
  };
  /* handel click of icons in cards*/
  const [openCardIds, setOpenCardIds] = useState<{
    [key: string]: number | null;
  }>({
    resource: null,
    activity: null,
    announcement: null,
    content: null,
    analytics: null,
    grades: null,
    notifications: null,
  });
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [data, setData] = useState<CourseCardItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [roleList, setRoleList] = useState("");
  const tenantName = localStorage.getItem("tenant");
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        let response;
        const adminState = await axios.get(
          `https://9z6xeihi9l.execute-api.eu-west-1.amazonaws.com/dev/auth/isAdmin/${tenantName}/${subIdSplitted}?provider=${provider}`
        );
        localStorage.setItem('adminState', adminState.data.data);
        setIsAdmin(adminState.data.data)
        // Fetch data based on admin status
        if (adminState.data.data !== null && adminState.data.data === true) {
          response = await axios.get(
            `https://9z6xeihi9l.execute-api.eu-west-1.amazonaws.com/dev/admin/classes/${tenantName}?provider=${provider}`
          );
        } else {
          response = await axios.get(
            `https://dz0k14jayb.execute-api.eu-west-1.amazonaws.com/dev/classes/onGoing/${subIdSplitted}/${tenantName}?provider=${provider}`
          );
        }
        const enrollments = response.data.data;
        setEnrolled(enrollments.length > 0);

        // Extract roles and determine if all are the same
        const roles = enrollments.map(
          (enrollment: { role: any }) => enrollment.role
        );
        const allSameRole = roles.every((role: any) => role === roles[0]);
        setRoleList(allSameRole ? roles[0] : "both");

        let combinedData = enrollments;

        // Additional processing if not admin
        if (adminState.data.data === false) {
          try {
            const response2 = await axios.get(
              `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/completionTracking/class/track/${subIdSplitted}/${tenantName}?provider=${provider}`
            );

            if (response2.data.data && response2.data.data.length > 0) {
              combinedData = enrollments.map((item1: any) => {
                const matchingItem = response2.data.data.find(
                  (item2: any) => item2.classSourceId === item1.id
                );
                const matchingItemForProfile = enrollments.find(
                  (item2: any) => item2.id === item1.id
                );
                if (matchingItem) {
                  const { classTrack } = matchingItem;
                  const { teacherProfile } = matchingItemForProfile;
                  return { ...item1, classTrack, teacherProfile }; // Merge properties from both objects
                }
                return item1; // Return the original item if no match found
              });
            }
          } catch (error) {
            console.error("Error fetching completion tracking data:", error);
          }
        }

        // Set state with the final data
        setData(combinedData);
        setBData(combinedData);
        setEnrollmentClasses(combinedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again.");
        setLoading(false);
      }
    };

    if (
      tenantName !== null &&
      tenantName !== undefined &&
      subIdSplitted !== null &&
      subIdSplitted !== undefined &&
      isAdmin !== null
    ) {
      // console.log("isAdmin before fetching data", isAdmin)
      // setLoading(true)
      fetchData();
    }
  }, [tenantName, subIdSplitted]);

  // const { data, loading, error } = useFetchData<any[]>(getAllUserEnrollments);
  let numCards;
  if (data) {
    numCards = data.length;
    setBData(data);
  }

  useEffect(() => {
    // Reset the slider when the language changes
    if (sliderRef.current) {
      if (language === "ar") {
        sliderRef.current.slickGoTo(data.length - 1); // Go to the last slide for RTL
      } else {
        sliderRef.current.slickGoTo(0); // Go to the first slide for LTR
      }
    }
  }, [data, language]);

  /*Slider Settings*/
  const sliderRef = useRef<Slider | null>(null);
  const set = slickSliderSettings(numCards || 0);

  // Function to toggle card state based on card type
  const toggleCard = (cardType: string, cardId: number) => {
    const updatedCardIds = { ...openCardIds };
    // Reset other card types and toggle the specified one
    for (const type in updatedCardIds) {
      updatedCardIds[type] =
        type === cardType
          ? updatedCardIds[type] === cardId
            ? null
            : cardId
          : null;
    }
    setOpenCardIds(updatedCardIds);
  };
  /* end handel click of icons in cards*/

  if (loading) {
    return (
      <div style={{ marginLeft: "40%" }}>
        {" "}
        <LoaderText />{" "}
      </div>
    );
  } else {
    if (numCards && numCards > 0) {
      let sortedData;
      if (data) {
        sortedData = customSort(data);
      }
      return (
        <>
          {numCards > 0 && (
            <CourseCardHolderStyle isAdmin={isAdmin?.toString()}>
              <Slider ref={sliderRef} {...set} rtl={isRTL}>
                {sortedData &&
                  sortedData.map((course, index) =>
                    !isAdmin ? (
                      <CourseCard
                        roleList={roleList}
                        key={index}
                        id={course.id}
                        isOpenResource={openCardIds.resource === index}
                        toggleCardResource={() => toggleCard("resource", index)}
                        isOpenContent={openCardIds.content === index}
                        toggleCardContent={() => toggleCard("content", index)}
                        isOpenActivity={openCardIds.activity === index}
                        toggleCardActivity={() => toggleCard("activity", index)}
                        isOpenAnnouncement={openCardIds.announcement === index}
                        toggleCardAnnouncement={() =>
                          toggleCard("announcement", index)
                        }
                        isOpenAnalytics={openCardIds.analytics === index}
                        toggleCardAnalytics={() =>
                          toggleCard("analytics", index)
                        }
                        isOpenGrades={openCardIds.grades === index}
                        toggleCardGrades={() => toggleCard("grades", index)}
                        isOpenNotifications={
                          openCardIds.notifications === index
                        }
                        toggleCardNotifications={() =>
                          toggleCard("notifications", index)
                        }
                      />
                    ) : (
                      <AdminCourseCard key={index} id={course.id} />
                    )
                  )}
              </Slider>
            </CourseCardHolderStyle>
          )}
          {/* Clicked Icons */}
          {sortedData && openCardIds.content !== null && (
            <DashboardContents courseId={sortedData[openCardIds.content]?.id} />
          )}
          {sortedData && openCardIds.activity !== null && (
            <div
              style={{
                backgroundColor: "transparent",
                marginBottom: "1.25rem",
              }}
            >
              <Empty text={t("view all.Nothing here yet")} />
            </div>
          )}
          {sortedData && openCardIds.analytics !== null && (
            <div
              style={{
                backgroundColor: "transparent",
                marginBottom: "1.25rem",
              }}
            >
              <Empty text={t("view all.Nothing here yet")} />
            </div>
          )}
          {sortedData && openCardIds.notifications !== null && (
            <div style={{ backgroundColor: "transparent" }}>
              <DashboardNotificationUpdates
                courseId={sortedData[openCardIds.notifications]?.id}
              />
            </div>
          )}
          {/* {openCardIds.notifications !== null && (<Empty text={t('view all.Nothing here yet')} />)} */}
          {/* {sortedData && openCardIds.announcement !== null && (<div style={{ backgroundColor: "transparent", marginBottom: "1.25rem" }}><Empty text={t('view all.Nothing here yet')} /></div>)} */}
          {sortedData && openCardIds.announcement !== null && (
            <div
              style={{
                backgroundColor: "transparent",
                marginBottom: "1.25rem",
              }}
            >
              <DashboardAnnouncements
                id={sortedData[openCardIds.announcement]?.id}
              />
            </div>
          )}
          {sortedData && openCardIds.grades !== null && (
            <div
              style={{
                backgroundColor: "transparent",
                marginBottom: "1.25rem",
              }}
            >
              <DashboardGrades
                classId={sortedData[openCardIds.grades]?.id}
                role={sortedData[openCardIds.grades]?.role}
              />
            </div>
          )}
          {/* {openCardIds.grades !== null && (<div style={{ backgroundColor: "transparent", marginBottom: "1.25rem" }}><Empty text={t('view all.Nothing here yet')} /></div>)} */}
        </>
      );
    } else {
      return (
        <div style={{ position: "relative", paddingBottom: "1rem" }}>
          {" "}
          <Typography
            text={t("dashboard.You have no registered courses yet")}
          />{" "}
        </div>
      );
    }
  }
};

export default CourseCardsHolder;
