import { FC } from "react";
import { CourseInfoWrapper } from "./CourseInfo.style";
import CourseObjectives from "./courseObjectives/CourseObjectives";
import Description from "./description/Description";
import AccessConditions from "./accessConditions/AccessConditions";
import EducationalGoals from "./educationalGoals/EducationalGoals";
import Instructors from "./instructors/Instructors";

interface CourseInfoProps {
  classStructure: any | any[];
  courseObjectives: any | any[];
  teacher: any | any[];
  classSourceId: any | any[];
  ParentClassData: any;
}
const CourseInfo: FC<CourseInfoProps> = ({
  classSourceId,
  classStructure,
  courseObjectives,
  teacher,
  ParentClassData,
}) => {
  return (
    <CourseInfoWrapper>
      {/* {courseObjectives &&( courseObjectives.length)} */}
      <CourseObjectives courseObjectives={courseObjectives} />
      <Description ParentClassData={ParentClassData} />
      {/* <AccessConditions />
      <EducationalGoals /> */}
      <Instructors teacher={teacher} classId={classSourceId} />
    </CourseInfoWrapper>
  );
};

export default CourseInfo;
