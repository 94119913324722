import React, { useState, useEffect } from "react";
import {
  DateBoxPopUpActionLine,
  DateBoxPopUpContainer,
  DateBoxPopUpSeparatedLine,
  DateBoxPopUpSmall,
} from "./DateBoxPopUp.style";
import { Typography } from "../fonts/Fonts";
import { SubmitButtomEditProfile } from "../SubmitSaveButton/SubmitButtonStyle";
import CustomDatePicker from "./DatePicker";

interface DateBoxPopUpInterface {
  TitleHeader: string;
  ShowDateBox?: boolean;
  setShowDateBox?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDate: Date | null;
  setSelectedDate?: React.Dispatch<React.SetStateAction<Date | null>>;
  IdForAxios?: any;
  TextForAxios?: any;
  DataBaseStartDate?: Date;
}

const DateBoxPopUp: React.FC<DateBoxPopUpInterface> = ({
  TitleHeader,
  ShowDateBox,
  setShowDateBox,
  selectedDate,
  setSelectedDate,
  IdForAxios,
  TextForAxios,
  DataBaseStartDate,
}) => {
  const [formattedDate, setFormattedDate] = useState<string | null>(null);
  const [DefaultDate, setDefaultDate] = useState<Date | null>();

  useEffect(() => {
    const formatter = new Intl.DateTimeFormat("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
    });

    const formatted = selectedDate
      ? formatter.format(selectedDate)
      : formatter.format(new Date());

    setFormattedDate(formatted);
  }, [selectedDate]);

  const handleCloseContainer = () => {
    setShowDateBox && setShowDateBox(!ShowDateBox);
  };

  const handleSave = () => {
    setSelectedDate && setSelectedDate(DefaultDate || null);
    setShowDateBox && setShowDateBox(!ShowDateBox);
    // console.log("Selected Date:", DefaultDate);
  };
  // useEffect(() => {
  //   console.log("Selected Date out Side the function:", selectedDate);
  // }, [selectedDate, DefaultDate]);

  return (
    <>
      {ShowDateBox && (
        <DateBoxPopUpContainer>
          <DateBoxPopUpSmall>
            <Typography text={TitleHeader} fontSize="1.125rem" />
            <DateBoxPopUpSeparatedLine />
            <Typography text={formattedDate || ""} fontSize="0.875rem" />
            <CustomDatePicker
              DataBaseStartDate={DataBaseStartDate}
              selectedDate={selectedDate}
              setSelectedDate={(date) =>
                setSelectedDate && setSelectedDate(date)
              }
              DefaultDate={DefaultDate || null}
              setDefaultDate={(date) =>
                setDefaultDate && setDefaultDate(date as Date)
              }
            />
          </DateBoxPopUpSmall>

          <DateBoxPopUpActionLine>
            <SubmitButtomEditProfile
              type="button"
              value="Save"
              widths="6.25rem"
              // onClick={handleSaveDate}
              onClick={handleSave}
            />
            <SubmitButtomEditProfile
              type="button"
              value="Cancel"
              widths="6.25rem"
              background="none"
              color="#D85D66"
              border="#D85D66"
              onClick={handleCloseContainer}
            />
          </DateBoxPopUpActionLine>
        </DateBoxPopUpContainer>
      )}
    </>
  );
};

export default DateBoxPopUp;
