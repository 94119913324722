import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ContentHolder,
  FlexedDiv,
  LoaderHolder,
  MainContainer,
  ModuleHolder,
  PolygonDiv,
  SecondLoaderHolder,
  SecondPhaseHolder,
  StyledCB,
  TopicHolder,
  TopicHolderContainer,
} from "./ImportClassPopup.style";
import { SvgBackArrow, SvgPolygonDown } from "../../elements/Icons";
import LoaderCircle from "../loader/loaderCircle/LoaderCircle";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../elements/fonts/Fonts";
import CheckboxSquare from "../../elements/checkboxSquare/CheckboxSquare";
import { toast } from "react-toastify";
import { FlexDiv } from "../../globalStyles";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useRecoilValue } from "recoil";

interface ImportClassPopupProps {
  selectedClassId: string;
  setSelectedClassId: (classId: string) => void;
  setSelectedContentIds: (updateFn: (prevState: number[]) => number[]) => void;
  selectedContentIds: number[];
  Loading?: boolean;
}

const ImportClassSecondPhase: React.FC<ImportClassPopupProps> = ({
  selectedClassId,
  setSelectedClassId,
  setSelectedContentIds,
  selectedContentIds,
  Loading,
}) => {
  const { theme } = useRecoilValue(ColorSelector)
  const language = localStorage.getItem("language")
  const tenantName = localStorage.getItem("tenant");
  const [Content, setContent] = useState<any[]>([]);
  const [Class, SetClass] = useState<any>();

  const [expandedModules, setExpandedModules] = useState<{
    [key: number]: boolean;
  }>({});
  const [expandedTopics, setExpandedTopics] = useState<{
    [key: number]: boolean;
  }>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const BaseURLClassName =
    "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";

  const getAboutClassData = async (classSourceId: string) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/about/class/${classSourceId}/${tenantName}`
      );
      if (!Array.isArray(res.data.data)) {
        toast.error(res.data.data);
      }
      SetClass(res.data.data[0]);
      console.log(res.data.data, "about");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchContent = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${BaseURLClassName}/class/module/all/${selectedClassId}/${tenantName}`
        );
        setContent(response.data.data);
        console.log(response.data.data, "Content fetched");
      } catch (error) {
        console.error("Error fetching content:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedClassId) {
      fetchContent();
      getAboutClassData(selectedClassId);
    }
  }, [selectedClassId, tenantName]);

  const toggleModule = (moduleId: number) => {
    setExpandedModules((prevState) => ({
      ...prevState,
      [moduleId]: !prevState[moduleId],
    }));
  };

  const toggleTopic = (topicId: number) => {
    setExpandedTopics((prevState) => ({
      ...prevState,
      [topicId]: !prevState[topicId],
    }));
  };

  const handleModuleCheckboxChange = (module: any, isChecked: boolean) => {
    const contentIds = module.topicsArray.flatMap((topic: any) =>
      topic.contents.map((content: any) => content.contentId)
    );

    setSelectedContentIds((prevState: number[]) => {
      const newSelectedIds = isChecked
        ? [...prevState, ...contentIds]
        : prevState.filter((id: any) => !contentIds.includes(id));
      console.log("Selected Content IDs:", newSelectedIds);
      return newSelectedIds;
    });
  };

  const handleTopicCheckboxChange = (topic: any, isChecked: boolean) => {
    const contentIds = topic.contents.map((content: any) => content.contentId);

    setSelectedContentIds((prevState: number[]) => {
      const newSelectedIds = isChecked
        ? [...prevState, ...contentIds]
        : prevState.filter((id: any) => !contentIds.includes(id));
      console.log("Selected Content IDs:", newSelectedIds);
      return newSelectedIds;
    });
  };

  const handleContentCheckboxChange = (
    contentId: number,
    isChecked: boolean
  ) => {
    setSelectedContentIds((prevState: number[]) => {
      const newSelectedIds = isChecked
        ? [...prevState, contentId]
        : prevState.filter((id: number) => id !== contentId);
      console.log("Selected Content IDs:", newSelectedIds);
      return newSelectedIds;
    });
  };

  const isContentSelected = (contentId: number) => {
    return selectedContentIds.includes(contentId);
  };

  const BackToClasses = () => {
    setSelectedClassId("");
  };

  return (
    <MainContainer>
      <FlexDiv pointer="true" onClick={() => BackToClasses()}>
        <SvgBackArrow  style={{transform: language === "ar" ? "rotate(180deg)" : ""}}/>
        <Typography
          text={
            Class && Class.classData && Class.classData.title
          }
        />
      </FlexDiv>
      {isLoading || Loading ? (
        <SecondLoaderHolder>
          <LoaderCircle height={25} width={25} />
        </SecondLoaderHolder>
      ) : Content.length > 0 ? (
        <SecondPhaseHolder>
          {Content.filter((module) =>
            module.topicsArray.some((topic: any) => topic.contents.length > 0)
          ).map((module) => (
            <ModuleHolder key={module.moduleId}>
              <FlexedDiv>
                <CheckboxSquare
                  checked={module.topicsArray.every((topic: any) =>
                    topic.contents.every((content: any) =>
                      isContentSelected(content.contentId)
                    )
                  )}
                  onChange={(e) =>
                    handleModuleCheckboxChange(module, e.target.checked)
                  }
                />
                <div
                  onClick={() => toggleModule(module.moduleId)}
                  style={{
                    cursor: "pointer",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography text={module.moduleTitle} fontSize="0.875rem" />
                </div>

                <PolygonDiv
                  isopen={expandedModules[module.moduleId]}
                  onClick={() => toggleModule(module.moduleId)}
                >
                  <SvgPolygonDown />
                </PolygonDiv>
              </FlexedDiv>
              {expandedModules[module.moduleId] && (
                <TopicHolderContainer>
                  {module.topicsArray
                    .filter((topic: any) => topic.contents.length > 0)
                    .map((topic: any) => (
                      <TopicHolder key={topic.topicId} theme={theme}>
                        <FlexedDiv>
                          <CheckboxSquare
                            checked={topic.contents.every((content: any) =>
                              isContentSelected(content.contentId)
                            )}
                            onChange={(e) =>
                              handleTopicCheckboxChange(topic, e.target.checked)
                            }
                          />
                          <div
                            onClick={() => toggleTopic(topic.topicId)}
                            style={{
                              cursor: "pointer",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <MediumTypography
                              text={topic.topicTitle}
                              fontSize="0.875rem"
                            />
                          </div>
                          <PolygonDiv
                            isopen={expandedTopics[topic.topicId]}
                            onClick={() => toggleTopic(topic.topicId)}
                          >
                            <SvgPolygonDown />
                          </PolygonDiv>
                        </FlexedDiv>
                        {expandedTopics[topic.topicId] &&
                        topic.contents.length > 0 ? (
                          <ContentHolder>
                            {topic.contents.map((content: any) => (
                              <FlexedDiv key={content.contentId}>
                                {/* <StyledCB
                                  type="checkbox"
                                  checked={isContentSelected(content.contentId)}
                                  onChange={(e) => handleContentCheckboxChange(content.contentId, e.target.checked)}
                                /> */}
                                <CheckboxSquare
                                  checked={isContentSelected(content.contentId)}
                                  onChange={(e) =>
                                    handleContentCheckboxChange(
                                      content.contentId,
                                      e.target.checked
                                    )
                                  }
                                />
                                <Typography
                                  text={content.contentTitle}
                                  fontSize="0.8rem"
                                />
                              </FlexedDiv>
                            ))}
                          </ContentHolder>
                        ) : null}
                      </TopicHolder>
                    ))}
                </TopicHolderContainer>
              )}
            </ModuleHolder>
          ))}
        </SecondPhaseHolder>
      ) : (
        <p>No content available</p>
      )}
    </MainContainer>
  );
};

export default ImportClassSecondPhase;
