import React, { ReactNode, createContext, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0ProviderSplitter,
  auth0SubIdSplitter,
} from "../../utils/StringManipulation";
import { useParams } from "react-router-dom";
import { get } from "../../utils/AxiosRequests";
import { LoadingComp } from "../../components/LoadingComp/LoadingComp";
interface ClassContextProps {
  classId: any;
  setClassId: (newState: any) => void;
  show: boolean;
  setShow: (newState: any) => void;
  bData: any;
  setBData: (newState: any) => void;
  role: any;
  setRole: (newState: any) => void;
  userSelectedIndex: number;
  setUserSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  chatConnectedToWebsocket: boolean;
  setChatConnectedToWebsocket: React.Dispatch<React.SetStateAction<boolean>>;
  onlineClients: any[];
  setOnlineClients: React.Dispatch<React.SetStateAction<any[]>>;
  notificationData: any[];
  setNotificationData: any;
  unviewedNotificationData: any[];
  classNotificationsData: any[];
  globalConversationId: string;
  setProgressChange: React.Dispatch<React.SetStateAction<boolean>>;
  progressChanged: boolean;
  setContentVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  contentVisibility: boolean;
  setClassNotificationsData: React.Dispatch<React.SetStateAction<any[]>>;
  setGlobalConversationId: React.Dispatch<React.SetStateAction<string>>;
  mySourceId: string;
  setMySourceId: React.Dispatch<React.SetStateAction<string>>;
  setUnviewedNotificationData: any;
  unviewedMessageCount: any;
  getUnvewedMessageCount: (ClassSourceId: string) => void;
  unviewedAnnouncmentCount: any;
  getUnvewedAnnouncmentCount: () => void;
  getUnvewedAnnouncmentClassCount: (classSourceId: string) => void;
  unviewedAnnouncmentClassCount: any;
  getUnvewedConversationsMessageCount: (classSourceId: string) => void;
  conversationsMessagesCount: any[];
  AnnouncementsCurrentPage: number;

  setAnnouncementsCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  DashBoardAnnouncementsCurrentPage: number;

  setDashBoardAnnouncementsCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;
}
interface ProviderProps {
  children: ReactNode;
}

const ClassContext = createContext({} as ClassContextProps);

const ClassContextProvider = ({ children }: ProviderProps) => {
  const [classId, setClassId] = useState(null);
  const [progressChanged, setProgressChange] = useState<boolean>(false);
  const [contentVisibility, setContentVisibility] = useState<boolean>(false);
  const [AnnouncementsCurrentPage, setAnnouncementsCurrentPage] =
    useState<number>(1);
  const [
    DashBoardAnnouncementsCurrentPage,
    setDashBoardAnnouncementsCurrentPage,
  ] = useState<number>(1);
  const [show, setShow] = useState(false);
  const [bData, setBData] = useState();
  const [role, setRole] = useState();
  const [chatConnectedToWebsocket, setChatConnectedToWebsocket] =
    useState(false);
  const [onlineClients, setOnlineClients] = useState<any[]>([]);
  const [notificationData, setNotificationData] = useState<any[]>([]);
  const [unviewedNotificationData, setUnviewedNotificationData] = useState<
    any[]
  >([]);
  const [classNotificationsData, setClassNotificationsData] = useState<any[]>(
    []
  );
  const [globalConversationId, setGlobalConversationId] = useState<string>("");
  // The userSelectedIndex is used in the Grader for all the activities
  const [userSelectedIndex, setUserSelectedIndex] = useState<number>(0);
  const [mySourceId, setMySourceId] = useState<string>("");
  const { user, isLoading, isAuthenticated } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const [unviewedMessageCount, setUnviewedMessageCount] = useState<number>(0);
  const [unviewedAnnouncmentCount, setUnviewedAnnouncmentCount] =
    useState<number>(0);
  const [unviewedAnnouncmentClassCount, setUnviewedAnnouncmentClassCount] =
    useState<number>(0);
  const [conversationsMessagesCount, setConversationsMessagesCount] = useState<
    any[]
  >([]);

  const getUnvewedMessageCount = async (ClassSourceId: any) => {
    try {
      const response = await axios.get(
        `https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/get/unviewed/messages/${ClassSourceId}/${tenantName}/${subIdSplitted}?provider=${provider}`
      );
      setUnviewedMessageCount(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getUnvewedAnnouncmentCount = async () => {
    try {
      if (
        tenantName !== null &&
        tenantName !== undefined &&
        subIdSplitted !== null &&
        subIdSplitted !== undefined
      ) {
        const announcmentCount = await axios.get(
          `https://yhntwosoqe.execute-api.eu-west-1.amazonaws.com/dev/announcement/count/${subIdSplitted}/${tenantName}?provider=${provider}`
        );
        setUnviewedAnnouncmentCount(announcmentCount.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUnvewedAnnouncmentClassCount = async (classSourceId: string) => {
    try {
      const announcmentCount = await axios.get(
        `https://yhntwosoqe.execute-api.eu-west-1.amazonaws.com/dev/announcement/count/class/${classSourceId}/${subIdSplitted}/${tenantName}?provider=${provider}`
      );
      setUnviewedAnnouncmentClassCount(announcmentCount.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getUnvewedConversationsMessageCount = async (classSourceId: any) => {
    try {
      const unviewedCinversationMessageCount = await axios.get(
        `https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/get/unviewed/conversations/messages/${classSourceId}/${tenantName}/${subIdSplitted}?provider=${provider}`
      );
      setConversationsMessagesCount(unviewedCinversationMessageCount.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const ClassContextValue: ClassContextProps = {
    classId,
    setClassId,
    progressChanged,
    setProgressChange,
    contentVisibility,
    setContentVisibility,
    show,
    setShow,
    bData,
    setBData,
    role,
    setRole,
    AnnouncementsCurrentPage,
    setAnnouncementsCurrentPage,
    DashBoardAnnouncementsCurrentPage,
    setDashBoardAnnouncementsCurrentPage,
    userSelectedIndex,
    setUserSelectedIndex,
    onlineClients,
    setOnlineClients,
    notificationData,
    setNotificationData,
    setUnviewedNotificationData,
    classNotificationsData,
    unviewedNotificationData,
    setClassNotificationsData,
    chatConnectedToWebsocket,
    setChatConnectedToWebsocket,
    globalConversationId,
    setGlobalConversationId,
    mySourceId,
    setMySourceId,
    unviewedMessageCount,
    getUnvewedMessageCount,
    unviewedAnnouncmentCount,
    getUnvewedAnnouncmentCount,
    unviewedAnnouncmentClassCount,
    getUnvewedAnnouncmentClassCount,
    getUnvewedConversationsMessageCount,
    conversationsMessagesCount,
  };

  return (
    <ClassContext.Provider value={ClassContextValue}>
      {children}
    </ClassContext.Provider>
  );
};

export { ClassContext, ClassContextProvider };
