import styled from "styled-components";

export const Container = styled.div`
display: flex;
flex-direction: column;
gap: 1.25rem;
margin-bottom: 1.25rem;
`
export const QuestionBankContainer = styled.div`
display: flex;
gap: 1.25rem;
`
