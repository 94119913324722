import styled from "styled-components";
import { breakpoints } from "../../../theme";

export const MenuWrapper = styled.div<{ bgColor?: string; boxShadow: string }>`
  position: absolute;
  &:dir(ltr) {
    right: 1.5rem;
  }
  &:dir(rtl) {
    left: 1.5rem;
  }
  top: 2.35rem;
  border-radius: 0.3125rem;
  /* background: #1f2731; */
  background: ${(props) => props.bgColor || "#1f2731"};
  box-shadow: ${(props) => props.boxShadow};
  min-height: fit-content;
  min-width: fit-content;
  width: 8.1875rem;
  /* height: 6.9375rem; */
  display: flex;
  flex-direction: column;
  z-index: 20;
`;

export const Frame = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: start;
  &:hover {
    background: ${({ theme }) => theme.dark.main_color};
    transition: all 0.2s ease-in;
    color: #fff;
  }
`;

export const TextWithIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.62rem;
  padding-left: 0.75rem;
  display: flex;
  column-gap: 0.44rem;
  align-items: center;
`;
