import React, { ChangeEvent, FC, useState } from "react";
import {
  NavBarItems,
  ContainerItems,
  Items,
  Tooltip,
  IconWrapper,
} from "./NavBar.style";
import { items } from "../NavBarData";
import { NavBarProps } from "../NavBarInterface";
import { ShortAnswerInputs } from "../../../SurveyCreatorQuestionCRUD/ShortAnswerQuestion/ShortAnswerQuestion.style";
import { MediumTypography } from "../../../../elements/fonts/Fonts";
import { theme } from "../../../../theme";
import { ErrorDiv } from "../../../../elements/ErrorDiv/Errordiv.style";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  courseBannerColorSelector,
  ColorSelector,
} from "../../../../recoil/ThemeSelectors";

const NavBar: FC<NavBarProps> = ({
  addMoreQuestion,
  setShowshowLoadTemplate,
  questions,
  handleTemplateName,
  nameError,
  showQuest,
  templateName,
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    handleTemplateName(value); // Pass the value to handleTemplateName
  };
  const { t } = useTranslation();
  const { courseTitleBgColor, theme } = useRecoilValue(
    courseBannerColorSelector
  );
  const { bg_selectColor, SecondaryTextColor } = useRecoilValue(ColorSelector);
  return (
    <ContainerItems theme={theme} bgcolor={courseTitleBgColor}>
      <Items>
        <MediumTypography
          text={t("quiz.Create Your Template")}
          fontSize="0.875rem"
          lineHeight="1.375rem"
          // color={theme.dark.primary_text_color}
        />
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          {items.map((item, index) => (
            <NavBarItems key={index}>
              {item.id === 4 ? (
                <IconWrapper
                  onClick={() => {
                    addMoreQuestion("normal");
                  }}
                >
                  {" "}
                  {item.icon}
                  <Tooltip bgcolor={bg_selectColor}>
                    {t("quiz.Simple Question")}
                  </Tooltip>
                </IconWrapper>
              ) : item.id === 5 ? (
                <IconWrapper
                  onClick={() => {
                    addMoreQuestion("composed");
                  }}
                >
                  {" "}
                  {item.icon}
                  <Tooltip>Complex Question</Tooltip>
                </IconWrapper>
              ) : item.id === 2 ? (
                <span onClick={() => setShowshowLoadTemplate(true)}>
                  {item.icon}
                </span>
              ) : (
                <span>{item.icon}</span>
              )}
            </NavBarItems>
          ))}
        </div>
      </Items>
      {/* {templateName && templateName.trim() === "" ? 
      <ShortAnswerInputs
      center="no"
      show={false.toString()}
      type="text"
      width="100%"
      placeholder="write Template name here"
      // readOnly={showQuest ? true : false}
      onChange={handleChange}
      id="name"
      hasError={!!nameError}
      />
       :  */}
      {nameError && nameError.trim() !== "" && <ErrorDiv>{nameError}</ErrorDiv>}
      <ShortAnswerInputs
        color={SecondaryTextColor}
        center="no"
        show={false.toString()}
        value={templateName || ""}
        type="text"
        width="100%"
        placeholder={t("quiz.Write template name here")}
        // readOnly={showQuest ? true : false}
        onChange={handleChange}
        id="name"
        hasError={!!nameError}
      />
      {/* } */}
    </ContainerItems>
  );
};

export default NavBar;
