import React, { useContext, useEffect, useState } from 'react';
import { Topic } from '../utils/CourseResourcesInterface';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Typography, TypographyRegularDescription } from '../../../elements/fonts/Fonts';
import { ColorSelector } from '../../../recoil/ThemeSelectors';
import ContentItem from '../contentItem/ContentItem';
import { TimeLineTopic, TimeLineTopicResources, TimeLineTopicTop, TimeLineTopicTopTile, NoTopicAvailable } from './TopicItem.style';
import { useParams } from 'react-router-dom';
import { selectedContent } from '../../../recoil/RecoilStates';
import { SvgArrowDownIcon, SvgArrowUpIcon, SvgNoResult } from '../../../elements/Icons';
import { toArabicDigits } from '../../../utils/formatDayArabic';
import { useTranslation } from 'react-i18next';
import { isDateInRange } from '../../../utils/FormatedDATE';
import { ClassContext } from '../../../Views/dashboard/UnstructuredContext';
import { getActivitiesLenght, getContentDescription, getResourcesLenght } from '../utils/CourseTimelineUtils';

export default function TopicItem({ moduleTitle, topic, moduleIndex, onSelectResource }: { moduleTitle: string, topic: Topic, moduleIndex: number, onSelectResource: Function }) {
    const { t } = useTranslation()
    const [isTopicOpen, setIsTopicOpen] = useState<boolean>(false);
    const { classId } = useParams();
    const { role } = useContext(ClassContext)
    const [selectedResourceID] = useRecoilState(selectedContent(classId));
    const { backgroundColor, bghelp, themeTextColor, boxShadow } = useRecoilValue(ColorSelector);

    const toggleTopic = () => {
        setIsTopicOpen(prevState => !prevState);
    };

    useEffect(() => {
        if (selectedResourceID && selectedResourceID.moduleIndex === moduleIndex && selectedResourceID.topicIndex === topic.topicIndex) {
            setIsTopicOpen(true);
        } else {
            setIsTopicOpen(false);
        }
    }, [moduleIndex, topic.topicIndex]);//add selectedresourceId as a dependency if needed to close the other topics same for module

    return (
        <TimeLineTopic
            bgColor={"green"}
            borderColor={bghelp}
            style={{
                background: backgroundColor,
                boxShadow: boxShadow,
            }}
        >
            <TimeLineTopicTop onClick={toggleTopic}>
                <TimeLineTopicTopTile >
                    <div style={{ display: "flex", columnGap: "0.2rem" }}>
                        <TypographyRegularDescription
                            text={`${t("course view.Topic")} ${toArabicDigits((topic.topicIndex).toString())} : `}
                            fontSize="0.75rem"
                            wordWrap="break-word"
                            whiteSpace="nowrap"
                        />
                        <TypographyRegularDescription text={topic.topicTitle} fontSize="0.75rem" />
                    </div>
                    {isTopicOpen ? (<SvgArrowUpIcon />) : (<SvgArrowDownIcon />)}
                </TimeLineTopicTopTile>
                <TypographyRegularDescription
                    text={getContentDescription(topic.contents, role, t)}
                    fontSize="0.75rem"
                    color="#AAB1B9"
                />
            </TimeLineTopicTop>
            {isTopicOpen && (
                <TimeLineTopicResources bgColor={backgroundColor} borderColor={bghelp} >
                    {topic.contents && topic.contents?.length > 0 ?
                        (topic.contents?.sort((a, b) => a.contentIndex - b.contentIndex).map((content) => (
                            <ContentItem
                                moduleIndex={moduleIndex}
                                topicIndex={topic.topicIndex}
                                key={content.contentId}
                                content={content}
                                onSelectResource={onSelectResource}
                                moduleTitle={moduleTitle}
                                topicTitle={topic.topicTitle}
                            />
                        ))) :
                        <NoTopicAvailable
                            bgColor={backgroundColor} borderColor={bghelp}
                        // style={{ boxShadow: boxShadow }}
                        >
                            <SvgNoResult width={"10rem"} />
                            <Typography text={t("course view.Empty Topic")} />
                        </NoTopicAvailable>
                    }
                </TimeLineTopicResources>
            )}
        </TimeLineTopic>
    );
}
