import React, { useEffect, useState } from 'react'
import GradebookTableV2 from '../gradeBookTable/main/NewVersion/GradebookTableV2'
interface DashboardGradesProps {
    classId: string;
    role: string;
}
export default function DashboardGrades2({ classId, role }: DashboardGradesProps) {
    const [renderTrigger, setRenderTrigger] = useState(0);

    useEffect(() => {
        setRenderTrigger(prev => prev + 1);
    }, [classId, role]);

    return (
        <GradebookTableV2 cardId={classId} cardRole={role} dashboardGrades key={renderTrigger} />
    )
}
