import React from "react";
import {
  AQHeadTableTr,
  AQSvgMoreType,
  AQTableCol,
  AQTableTbody,
  AQTableThead,
  AQTableTr,
  AnalyticsQuizzesScrollerContainer,
  AnalyticsQuizzesTable,
} from "../../AnalyticsQuizzes/AnalyticsQuizzes.style";
import { AQQTableTh } from "../QuizQuestionsAnalytics/QuizQuestionsAnalytics.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../../elements/fonts/Fonts";
import { SvgGrade } from "../../../elements/Icons";
import {
  ADTTableTd1,
  ADTTableThDetailsSmall,
} from "../../../elements/AnalyticDefaultTable/AnalyticDefaultTable.style";
import { SurveyQuestion } from "./SurveyQuestionAnalytics";
import { SurveyhandleCorrectQuestionSvg } from "../../../utils/Function";
interface SurveyTableContentInterface {
  DatabaseTableName: any[];
  setChosenQuestion?: (question: SurveyQuestion) => void;
  HeadSubTitleArray?: any[];
  filteredData: any[];
}

const SurveyTableContent = ({
  DatabaseTableName,
  setChosenQuestion,
  HeadSubTitleArray,
  filteredData,
}: SurveyTableContentInterface) => {
  const handleQuestionObject = (question: SurveyQuestion) => {
    setChosenQuestion && setChosenQuestion(question);
  };
  return (
    <AnalyticsQuizzesScrollerContainer>
      <AnalyticsQuizzesTable>
        <AQTableThead>
          {DatabaseTableName.map((Qval, Qindex) => (
            <AQTableCol key={Qindex}>
              <AQQTableTh
                style={{
                  height: "3.3rem",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <MediumTypography
                  text={`${Qval.QuestionTitle} ${Qval.QuestionType}`}
                  lineHeight="1.75rem"
                  fontSize="1.125rem"
                />

                <AQSvgMoreType onClick={() => handleQuestionObject(Qval)}>
                  <SvgGrade />
                </AQSvgMoreType>
              </AQQTableTh>
              <AQHeadTableTr>
                {HeadSubTitleArray &&
                  HeadSubTitleArray.map((Pro) => (
                    <ADTTableThDetailsSmall key={Pro.Text}>
                      <Typography text={Pro.Text} fontSize="1rem" />
                    </ADTTableThDetailsSmall>
                  ))}
              </AQHeadTableTr>
            </AQTableCol>
          ))}
        </AQTableThead>

        <div style={{ display: "flex" }}>
          {filteredData.map((val) => (
            <AQTableTbody key={val?.QuestionCode}>
              {val?.students &&
                val.students.map(
                  (
                    student: {
                      Status: string;
                      DueDate: string;
                      TimeSpent: any;
                      Answered: any;
                      Grade: any;
                    },
                    index: React.Key | null | undefined
                  ) => (
                    <AQTableTr key={index}>
                      <ADTTableTd1>
                        <LightTypography
                          text={
                            student?.TimeSpent > 0
                              ? `${student?.TimeSpent} min`
                              : student?.TimeSpent || "0"
                          }
                          fontSize="0.75rem"
                        />
                      </ADTTableTd1>
                      <ADTTableTd1>
                        {SurveyhandleCorrectQuestionSvg(student?.Answered)}
                      </ADTTableTd1>
                    </AQTableTr>
                  )
                )}
            </AQTableTbody>
          ))}
        </div>
      </AnalyticsQuizzesTable>
    </AnalyticsQuizzesScrollerContainer>
  );
};

export default SurveyTableContent;
