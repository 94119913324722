import { AnalyticDefaultTableInterface } from "./AnalyticDefaultTableInterface";

export const AnalyticDefaultTableDataAction : AnalyticDefaultTableInterface[] =[
  {
    QId:"1",
    Text:"Due Date",
  },
  {
    QId:"2",
    Text:"Status",
  },
  {
    QId:"3",
    Text:"Grade",
  },
]

export const AnalyticDefaultTableSurveyDataAction : AnalyticDefaultTableInterface[] =[
  {
    QId:"1",
    Text:"Time Spent",
  },
  {
    QId:"2",
    Text:"Answered",
  },
  
]