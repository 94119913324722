import styled from "styled-components";
import { breakpoints } from "../../theme";
interface ActivityProps {
  selected: boolean;
}
export const GradingDeatils = styled.div`
  /* display: grid;
  justify-content: space-between;
  margin-bottom: 1.31rem;
  grid-template-columns: 1fr 1fr 1fr 1fr; */
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
`;

export const GradingDetalInstructor = styled.div<{ nbShows?: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.nbShows ?? 4}, 1fr);
  gap: 16px;

  @media (max-width: 1200px) {
    /* Adjust the number of columns for medium screens */
    grid-template-columns: repeat(${(props) => (props.nbShows ?? 4) - 1}, 1fr);
  }

  @media (max-width: 900px) {
    /* Adjust the number of columns for smaller screens */
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    /* Switch to flex layout for very small screens */
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    grid-template-columns: none; /* Remove grid layout */
  }
`;

export const GradeItemDetail = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  column-gap: 0.5rem;
  align-items: center;
  color: #5dd3b3;
  min-width: 15rem;
  /* width: 14rem; */
  &:last-child {
    flex: 0.6;
  }
`;
export const HorDividLine = styled.div`
  height: 0.25px;
  background-color: #5dd3b3;
  opacity: 0.4;
`;
export const StudentsDetailsLeft = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 20rem;

  row-gap: 0.31rem;
  & > :last-child {
    /* margin-top: 0.5rem; */
    margin-bottom: 0.5rem;
  }
`;
export const StudentsQuizDetailsLeft = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 20rem;

  row-gap: 0.33rem;
`;
export const StudentsQuizzes = styled.div<{ bgColor?: string }>`
  overflow-y: auto;
  padding: 1.25rem;
  border-radius: 1.25rem;
  background: ${(props) =>
    props.bgColor || "linear-gradient(180deg, #415160 0%, #293440 100%)"};
  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5dd3b3;
    border-radius: 0.25rem;
    width: 0.25rem;
    /* width: 1rem; */
  }

  &::-webkit-scrollbar-track {
    background-color: #576371;
    border-radius: 0.25rem;
    width: 0.125rem;
    /* width: 0.125rem; */
  }
  &:dir(ltr) {
    margin-right: -0.5rem;
    padding-right: 0.5rem;
  }
  &:dir(rtl) {
    margin-left: -0.5rem;
    padding-left: 0.5rem;
  }
`;
export const StudentDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  &:dir(ltr) {
    padding-left: 0.5rem;
  }
  &:dir(rtl) {
    padding-right: 0.5rem;
  }

  /* background-color: red; */
  padding-top: 0.5rem;
`;
export const StudentQuizDetailsRight = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.62rem;
  width: 13rem;
  /* & > :last-child {
    margin-top: 1rem;
  } */
`;
export const RecordAttemptsWrapper = styled.div`
  display: flex;
  column-gap: 0.8rem;
`;
export const TextIconStyling = styled.div`
  margin-top: 1rem;
  display: flex;
  column-gap: 0.62rem;
  align-items: center;
`;

export const Quizes = styled.div`
  flex: 4;

  display: flex;
  flex-direction: column;

  overflow-y: auto;
  row-gap: 0.31rem;

  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5dd3b3;
    border-radius: 0.25rem;
    width: 0.25rem;
    /* width: 1rem; */
  }

  &::-webkit-scrollbar-track {
    background-color: #576371;
    border-radius: 0.25rem;
    width: 0.125rem;
    /* width: 0.125rem; */
  }
  /* @media (max-width: calc(${breakpoints.tablets})) {
    flex: 6;
    /* height: 10rem !important; 
  } */
  @media (max-width: calc(${breakpoints.tablets})) {
    min-height: 15rem;
  }
`;

export const QuizCard = styled.div<ActivityProps>`
  cursor: pointer;

  &:dir(ltr) {
    padding: 0.38rem 2rem 0.38rem 1.12rem;
  }
  &:dir(rtl) {
    padding: 0.38rem 1.12 0.38rem 2rem;
  }
  display: flex;
  flex-direction: column;

  row-gap: 0.35rem;
  min-height: 8.5rem;
  border-radius: 1rem 0 0 1rem;
  background: ${(props) =>
    props.selected
      ? "linear-gradient(90deg, #3c6765 2.37%, #2d3741 94.37%)"
      : "linear-gradient(90deg, #576371 0%, #293440 100%)"};
  & > :last-child {
    margin-top: 0.4rem;
  }
`;

export const StudentsHeaderContainer = styled.div`
  margin-bottom: 1rem;
`;
