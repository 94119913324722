import React, { FC } from "react";
import { ButtonContainer, ButtonContent } from "./CancelButton.style";

interface CancelButtonProps {
  name: string;
  onClickFunction: () => void;
  backgroundColor?: string;
  color?: string; 
  borderColor?:string;
  width?:string;
}

const CancelButton: FC<CancelButtonProps> = ({
  name,
  onClickFunction,
  backgroundColor,
  color,
  borderColor,
  width
}) => {
  return (
    <ButtonContainer>
      <ButtonContent
        onClick={onClickFunction}
        style={{ backgroundColor, color }}
        bordercolor={borderColor}
        width={width}
      >
        {name}
      </ButtonContent>
    </ButtonContainer>
  );
};

export default CancelButton;
