import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Props } from "./DropzoneInterface";
import { Container, Content, SelectedFileDiv } from "./DropZone.style";
import {
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import { SvgCloseIcon, SvgDropZoneIcon } from "../../../elements/Icons";
import * as XLSX from "xlsx";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
type FileObject = {
  path: string;
};

const DragAndDropZone = ({
  onDrop,
  readonly,
  initialSelectedFiles,
  acceptedTypes,
  setLoading,
}: Props): JSX.Element => {
  const [selectedFile, setSelectedFile] = useState<File | FileObject | null>(
    null
  );

  useEffect(() => {
    if (initialSelectedFiles && initialSelectedFiles?.length > 0) {
      setSelectedFile(initialSelectedFiles[0]);
    }
  }, [initialSelectedFiles]);

  const onDropHandler = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        setSelectedFile(acceptedFiles[0]);
        onDrop(acceptedFiles);
      }
    },
    [onDrop]
  );

  const removeFile = () => {
    setSelectedFile(null);
    onDrop([]);
    setLoading && setLoading(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropHandler,
    multiple: false,
    accept: acceptedTypes,
  });

  const handleDownload = () => {
    // Create a sample Excel workbook
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([
      {
        sourceId: 78547,
        grade: 50,
      },
    ]);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate a Blob from the workbook
    XLSX.writeFile(wb, "GradeTemplate.xlsx");
  };
  const { inputBorderColor } = useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  return (
    <Container borderColor={inputBorderColor}>
      <div
        style={{
          minHeight: "3rem",
          display: "flex",
          columnGap: "2.19rem",
          rowGap: "0.25rem",
          flexWrap: "wrap",
        }}
      >
        {selectedFile && (
          <SelectedFileDiv
            style={{
              backgroundColor: "#5DD3B3",
              color: "white",
            }}
          >
            {isFileObject(selectedFile) ? (
              <TypographyRegularDescription
                text={selectedFile.path}
                fontSize="0.75rem"
              />
            ) : (
              <TypographyRegularDescription
                text={(selectedFile as File).name || "Unnamed File"}
                fontSize="0.75rem"
              />
            )}
            {!readonly && <SvgCloseIcon onClick={removeFile} />}
          </SelectedFileDiv>
        )}
      </div>
      {!readonly && (
        <div {...getRootProps()} style={{ minHeight: "4rem" }}>
          {selectedFile == null ? (
            <>
              <input {...getInputProps()} />
              <Content>
                <SvgDropZoneIcon />
                <div style={{ paddingTop: "1.25rem", textAlign: "center" }}>
                  <MediumTypography
                    text={t("forum.Click or Drag and Drop, to upload file")}
                    fontSize="1.125rem"
                    color="#5DD3B3"
                  />
                </div>
              </Content>
            </>
          ) : null}
        </div>
      )}
    </Container>
  );
};

function isFileObject(file: File | FileObject | null): file is FileObject {
  return file !== null && (file as FileObject).path !== undefined;
}

export default DragAndDropZone;
