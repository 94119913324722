import { FilteredDataInterface } from "../../../../../interfaces/FilteredData";


export const CompetencyFrameworksFilteredData : FilteredDataInterface[] =[

  {
    text:"Advanced",
  value:"Advanced",
  },
  {
    text:"Intermediate",
  value:"Intermediate",
  },
  {
    text:"Poor",
  value:"Poor",
  },

  {
    text:"",
  value:"",
  },
]