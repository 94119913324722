import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  100% {
    transform: rotate(1turn);
  }
`;

export const Zloader = styled.div<{
  circleSize?: string;
  width?: string;
  height?: string;
}>`
  --d: ${(props) => props.circleSize || " 18px"};
  width: ${(props) => props.circleSize || " 2px"};
  height: ${(props) => props.circleSize || " 2px"};
  border-radius: 150% !important;
  color: #25b09b;
  box-shadow: calc(1 * var(--d)) calc(0 * var(--d)) 0 0,
    calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 1px,
    calc(0 * var(--d)) calc(1 * var(--d)) 0 2px,
    calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 3px,
    calc(-1 * var(--d)) calc(0 * var(--d)) 0 4px;

  animation: ${rotate} 1s infinite steps(8);
`;

export const SpinnerContainer = styled.div`
  display: flex;
  height: 8rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* background: yellow; */
`;
