import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";



export const MainContainer=styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 1.25rem;
user-select: none;

`
export const ClassHolderContainer=styled.div`
display: flex;
flex-direction: column;
gap: 0.62rem;

`
export const ClassHolder=styled.div`
border-radius:1.25rem;
background-color: rgba(170, 177, 185, 0.1);
padding: 1.25rem;
display:flex;
align-items:center;
gap:0.63rem;
cursor: pointer;
`

export const Image = styled.div<{src?: string}>`
width: 1.25rem;
height: 1.25rem;
background-image: url(${(props) => props.src});
background-size: cover;
background-position: center;
object-fit: cover;
`

export const ModuleHolder=styled.div`
border-radius:1.25rem;
background-color:rgba(170, 177, 185, 0.1);
padding:1.25rem;
/* margin-top:0.3rem; */
width:100%;
cursor: pointer;
`

export const TopicHolderContainer=styled.div`
display: flex;
flex-direction: column;
gap: 0.62rem;
margin-top:1.25rem;
`
export const TopicHolder=styled.div<StyleThemeInterface>`
background-color: ${props => props.theme === "light" ? "#fff" : "#48535F"};
padding:1.25rem;
border-radius:1.25rem;

`

export const ContentHolder=styled.div`
background-color:rgba(170, 177, 185, 0.1);
padding: 1.25rem;
border-radius:1.25rem;
margin-top:1.25rem;
display: flex;
flex-direction:column;
gap:1rem;
`

export const FlexedDiv=styled.div`
display:flex;
align-items:center;
gap:0.5rem;
`

export const StyledCB = styled.input`
  height: 1rem;
  width: 1rem;
  margin:0.2rem;
  cursor: pointer;
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;

  &:checked {
    background-color: #5DD3B3;
    border-color: #5DD3B3;
  }

  &:checked::after {
    content: '✔';
    display: block;
    color: white;
    font-size: 0.75rem;
    text-align: center;
    line-height: 1rem;
  }
`;

export const LoaderHolder=styled.div`
/* margin-left:45%;
margin-top:8rem; */
display:flex;
justify-content:center;
align-items:center;
height:100%;
`
export const SecondLoaderHolder=styled.div`
display:flex;
justify-content:center;
align-items:center;
height:100%;
`

export const SecondPhaseHolder=styled.div`
width:100%;
display: flex;
flex-direction: column;
gap: 0.62rem;
`

export const PolygonDiv = styled.div<{isopen:boolean}>`
  transform: ${(props) => (props.isopen === true ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
