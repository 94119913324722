// CourseTraking.tsx
import React, { useEffect, useState } from "react";
import {
  StyledHeader,
  FlexBetween,
  FlexDiv,
  ModuleExpandedDiv,
} from "./CourseTraking.style";
import {
  LightTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import { Module, Resource, Activity } from "./CourseTrakingInterface";
import { ReactComponent as SvgArrowIcon } from "../../assets/icons/arrow_simple_1.svg";

interface CourseTrakingProps {
  module: Module[];
  SearchText?: string;
  isParentExpanded?: boolean;
  setisParentExpanded?: any;
  ToggleAll?: boolean;
  ActivitiesMode?: boolean;
  RearousesMode?: boolean;
  setActivitiesMode?: boolean;
  setRearousesMode?: boolean;
}

export const CourseTraking = ({
  module,
  isParentExpanded,
  setisParentExpanded,
  SearchText = "",
  ToggleAll,
  ActivitiesMode,
  RearousesMode,
}: CourseTrakingProps): JSX.Element => {
  const [ShowResources, setShowResources] = useState(false);
  const [ShowActivities, setShowActivities] = useState(false);
  const [isChildExpanded, setIsChildExpanded] = useState<{
    [moduleId: number]: boolean;
  }>(
    Object.fromEntries(module.map((mod) => [mod.id, ToggleAll])) as {
      [moduleId: number]: boolean;
    }
  );

  const [isActivityExpanded, setIsActivityExpanded] = useState<{
    [moduleId: number]: boolean;
  }>(
    Object.fromEntries(
      module.map((mod) => [mod.id, ActivitiesMode ? ActivitiesMode : false])
    )
  );

  const [isResourceExpanded, setIsResourceExpanded] = useState<{
    [moduleId: number]: boolean;
  }>(
    Object.fromEntries(
      module.map((mod) => [mod.id, RearousesMode ? RearousesMode : true])
    )
  );
  const prevActivitiesModeRef = React.useRef(ActivitiesMode);
  const prevResorsesModeRef = React.useRef(RearousesMode);
  useEffect(() => {
    if (ActivitiesMode !== prevActivitiesModeRef.current) {
      setIsResourceExpanded((prev) => {
        const newState = { ...prev };
        Object.keys(newState).forEach((moduleId: string) => {
          newState[parseInt(moduleId)] = false; // Parse moduleId to number if needed
        });
        return newState;
      });
    }
    if (ActivitiesMode === false && prevActivitiesModeRef.current === true) {
      setIsActivityExpanded((prev: any) => {
        const newState = { ...prev };
        Object.keys(newState).forEach((moduleId: string) => {
          newState[parseInt(moduleId)] = false; // Parse moduleId to number if needed
        });
        return newState;
      });
    }
    if (RearousesMode !== prevResorsesModeRef.current) {
      setIsActivityExpanded((prev) => {
        const newState = { ...prev };
        Object.keys(newState).forEach((moduleId: string) => {
          newState[parseInt(moduleId)] = false; // Parse moduleId to number if needed
        });
        return newState;
      });
    }
    setIsActivityExpanded((prev) =>
      Object.fromEntries(
        module.map((mod) => [
          mod.id,
          ActivitiesMode ? ActivitiesMode : prev[mod.id],
        ])
      )
    );
    setIsResourceExpanded((prev) =>
      Object.fromEntries(
        module.map((mod) => [
          mod.id,
          RearousesMode ? RearousesMode : prev[mod.id],
        ])
      )
    );

    // console.log(
    //   "ActivitiesMode changed from",
    //   prevActivitiesModeRef.current,
    //   "to",
    //   ActivitiesMode
    // );
  }, [ActivitiesMode, RearousesMode, module]);

  const toggleSubChild = (moduleId: number, subChildType: string) => {
    if (subChildType === "activity") {
      setIsActivityExpanded((prev) => ({
        ...prev,
        [moduleId]: !prev[moduleId],
      }));
    } else if (subChildType === "resource") {
      setIsResourceExpanded((prev) => ({
        ...prev,
        [moduleId]: !prev[moduleId],
      }));
    }
  };

  const handleChildClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  const toggleParent = () => {
    setisParentExpanded(!isParentExpanded);
  };

  const toggleChild = (moduleId: number) => {
    setIsChildExpanded((prev) => ({
      ...prev,
      [moduleId]: !prev[moduleId],
    }));
  };

  const filteredModules = module.filter((mod) => {
    const isMatch =
      mod.title.toLowerCase().includes(SearchText.toLowerCase()) ||
      (SearchText.toLowerCase() === "activities" &&
        mod.activities.length > 0) ||
      (SearchText.toLowerCase() === "resources" && mod.resources.length > 0);

    return isMatch;
  });

  return (
    <>
      <StyledHeader onClick={toggleParent} isExpanded={isParentExpanded}>
        <FlexBetween>
          <Typography text="Course completion tracking" fontSize="1.125rem" />
          <TypographyRegularDescription
            text={`${filteredModules.length} Modules`}
            fontSize="0.875rem"
          />
        </FlexBetween>
        {isParentExpanded && (
          <>
            {filteredModules.map((mod) => (
              <StyledHeader
                key={mod.id}
                onClick={(e) => {
                  toggleChild(mod.id);
                  handleChildClick(e);
                }}
                isChild={true}
                isExpanded={isChildExpanded[mod.id]}
              >
                <FlexBetween>
                  <Typography
                    text={mod.title}
                    fontSize="1rem"
                    color="#5DD3B3"
                  />
                  <FlexDiv>
                    <TypographyRegularDescription
                      text={`${mod.activities.length} Activities`}
                      fontSize="0.875rem"
                    />
                    <TypographyRegularDescription
                      text={`${mod.resources.length} Resources`}
                      fontSize="0.875rem"
                    />
                  </FlexDiv>
                </FlexBetween>
                {isChildExpanded[mod.id] && (
                  <>
                    <StyledHeader
                      onClick={(e) => {
                        toggleSubChild(mod.id, "resource");
                        handleChildClick(e);
                      }}
                      isChild={true}
                      isExpanded={isResourceExpanded[mod.id]}
                    >
                      <FlexBetween>
                        <Typography text="Resources" fontSize="0.875rem" />
                        <TypographyRegularDescription
                          text={`${mod.resources.reduce(
                            (acc: number, resource: Resource) =>
                              acc + resource.viewed,
                            0
                          )} Viewed`}
                          fontSize="0.75rem"
                        />
                      </FlexBetween>
                      {isResourceExpanded[mod.id] && (
                        <ModuleExpandedDiv
                          isExpanded={isResourceExpanded[mod.id]}
                        >
                          {mod.resources.map(
                            (resource: Resource, index: number) => (
                              <FlexBetween key={index}>
                                <TypographyRegularDescription
                                  text={resource.name}
                                  fontSize="0.75rem"
                                />
                                <FlexDiv gap="0.62rem">
                                  <LightTypography
                                    text={`${resource.viewed.toString()} Views`}
                                    fontSize="0.75rem"
                                  />
                                  <SvgArrowIcon />
                                </FlexDiv>
                              </FlexBetween>
                            )
                          )}
                        </ModuleExpandedDiv>
                      )}
                    </StyledHeader>

                    <StyledHeader
                      onClick={(e) => {
                        toggleSubChild(mod.id, "activity");
                        handleChildClick(e);
                      }}
                      isChild={true}
                      isExpanded={isActivityExpanded[mod.id]}
                    >
                      <FlexBetween>
                        <Typography text="Activity" fontSize="0.875rem" />
                        <TypographyRegularDescription
                          text={`${mod.activities.reduce(
                            (acc: number, activity: Activity) =>
                              acc + activity.submitted,
                            0
                          )} Submitted`}
                          fontSize="0.75rem"
                        />
                      </FlexBetween>

                      {isActivityExpanded[mod.id] && (
                        <ModuleExpandedDiv
                          isExpanded={isActivityExpanded[mod.id]}
                        >
                          {mod.activities.map(
                            (activity: Activity, index: number) => (
                              <FlexBetween key={index}>
                                <TypographyRegularDescription
                                  text={activity.name}
                                  fontSize="0.75rem"
                                />
                                <FlexDiv gap="0.62rem">
                                  <LightTypography
                                    text={`${activity.submitted.toString()} Submitted`}
                                    fontSize="0.75rem"
                                  />
                                  <SvgArrowIcon />
                                </FlexDiv>
                              </FlexBetween>
                            )
                          )}
                        </ModuleExpandedDiv>
                      )}
                    </StyledHeader>
                  </>
                )}
              </StyledHeader>
            ))}
          </>
        )}
      </StyledHeader>
    </>
  );
};
