import { TagHolder } from "./TagButton.style";
import { TypographyRegularDescription } from "../fonts/Fonts";
import { tagButtonInterface } from "./tagButtonInterface";

const TagButton = ({ text }: tagButtonInterface) => {
  return (
    <TagHolder>
      <TypographyRegularDescription text={text} fontSize="0.875rem" />
    </TagHolder>
  );
};

export default TagButton;
