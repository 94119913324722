import styled from "styled-components";
import { breakpoints } from "../../../../../theme";

export const Overlay = styled.div<{ isvisible: string }>`
  display: ${(props) => (props.isvisible ? "block" : "none")};
  opacity: ${(props) => (props.isvisible ? "1" : "0")};
  position: fixed;
  top: 0;
  left: 0;
  /* width: 100%; */
  min-height: 300vh;
  transition: opacity 500ms;
  overflow: auto;
  z-index: 10;
`;

export const BackgroundOverlay = styled.div`
  background: rgba(0, 0, 0, 0.4);
`;

export const PopupContainer = styled.div`
  max-width: 60rem;
  margin: auto auto;
  padding: 1rem;
  border-radius: 5px;
  /* width: max-content; */
  color: white;
  height: 100vh;

  h2 {
    margin-top: 0;
    color: white;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: white;

    &:hover {
      cursor: pointer;
      color: #000;
    }
  }

  .content {
    margin-top: 3.5%;
    overflow: auto;
    margin-bottom: 100px;
  }

  @media screen and (max-width: 700px) {
    width: 70%;
  }
`;

export const PopupContentContainer = styled.div`
  border-left: 4px solid #5dd3b3;
  background-color: #384450;
  border-radius: 0px 10px 10px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  padding: 1rem;
`;

export const IconsContainer = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  /* padding: 2.5rem 2.5rem 0 2.5rem; */
`;
export const CloseIconContainer = styled.div`
  margin-top: 10px;
  cursor: pointer;
`;
export const HorizantalLine = styled.div<{ showline: string }>`
  display: ${(props) => (props.showline === "false" ? "none" : "")};
  border: 1px solid white;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  opacity: 0.2;
  margin-top: 1.5rem;
`;

export const PopupTitle = styled.div`
  color: white;
  font-family: "Core Rhino 65 Bold", sans-serif;
  font-size: 16px;
`;

export const IconAndTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 45px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.62rem;
`;
interface justifyP {
  justifyContent?: string;
}
export const LineFLex = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.7rem;
  margin-bottom: 2.3rem;
`;

export const ButtonFLex = styled.div<justifyP>`
  display: flex;
  gap: 1rem;
  margin: 1rem 0 !important;
  padding-top: 1rem;
  height: 4rem;

  /* justify-content:flex-end; */
  justify-content: ${(props) => props.justifyContent || "flex-end"};
  padding-right: 2.5rem;
  @media screen and (max-width: ${breakpoints.tablets}) {
    flex-direction: column;
    gap: 0.5rem !important;
  }
`;

export const PopUplineStyle = styled.div<{ isvisible: string }>`
  display: ${(props) => (props.isvisible ? "block" : "none")};
  opacity: ${(props) => (props.isvisible ? "1" : "0")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: opacity 500ms;
  overflow: auto;
  z-index: 10;
`;

export const ButtonFlex = styled.div`
  margin-bottom: 1rem;
  display: flex;
`;
