import React from "react";
import { AnalyticDefaultTable } from "../../../elements/AnalyticDefaultTable/AnalyticDefaultTable";
import { ScrumDefaultTableData } from "./ScrumDefaultTableData";

const ScrumDefaultTable = () => {
  return (
    <div>
      <AnalyticDefaultTable
        title="Scrum Details"
        DatabaseTableName={ScrumDefaultTableData}
        // Is Table Header Changes Data
        MappedHeadText={ScrumDefaultTableData.map((val) => val.Title)}
        LinkedText="ScormDetails"
        /////////////////////
      />
    </div>
  );
};

export default ScrumDefaultTable;
