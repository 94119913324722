import React from "react";
import {
  EditCompInput,
  EditCompTextArea,
  EditInputContainer,
} from "./EditProfileInput.style";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../../recoil/ThemeSelectors";
import { LightTypography } from "../../../../elements/fonts/Fonts";
interface EditProfileInputInterface {
  title: string;
  borderColor?: string;
  value: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onKeyDown?: (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  type?: string;
  placeholder?: string;
  textArea?: boolean;
}
const EditProfileInput = ({
  title,
  type = "text",
  value,
  borderColor,
  placeholder,
  onKeyDown,
  onChange,
  textArea = false,
}: EditProfileInputInterface) => {
  const {
    themeTextColor,
    textInputColorA,
    bg_selectColor,
    FocusTextColor,
    headerTextInput_ProfileAndBorder,
    theme,
    profileCardBorderColor,
  } = useRecoilValue(ColorSelector);
  return (
    <EditInputContainer>
      <LightTypography
        text={title}
        fontSize="0.875rem"
        fontWeight="400"
        color={headerTextInput_ProfileAndBorder}
      />
      {textArea ? (
        <EditCompTextArea
          value={value}
          onChange={onChange}
          bordercolor={borderColor || profileCardBorderColor}
          color={theme === "dark" ? "#fff" : "#364350"}
          focuscolor={FocusTextColor}
        />
      ) : (
        <EditCompInput
          type={type}
          value={value}
          onChange={onChange}
          bordercolor={borderColor || profileCardBorderColor}
          color={theme === "dark" ? "#fff" : "#364350"}
          focuscolor={FocusTextColor}
        />
      )}
    </EditInputContainer>
  );
};

export default EditProfileInput;
