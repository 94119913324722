import React from "react";
import { Link } from "react-router-dom";
import {
  DivAction,
  FourOA,
  FourOPageLayout,
  FourLogoPlace,
  FourText,
  FourOc,
} from "./ForOFourPage.style";
import {
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  SvgFourOOne,
  SvgFourOOneLight,
  SvgLogoFour,
  SvgZName,
} from "../../elements/Icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { t } from "i18next";

export const FourOOneCompo = (): JSX.Element => {
  const { isAuthenticated } = useAuth0();
  const { theme } = useRecoilValue(ColorSelector);
  return (
    <FourOPageLayout>
      <FourOA theme={theme}>
        <FourLogoPlace>
          <SvgLogoFour />
          <SvgZName />
        </FourLogoPlace>

        <FourText>
          <Typography fontSize="5rem" lineHeight="5.500rem" text="401" />
          <TypographyRegularDescription
            fontSize="1.375"
            text={t("not found.Unauthorized")}
            lineHeight="2rem"
          />
          <Link to={isAuthenticated ? "/dashboard" : "/"}>
            <DivAction>
              <Typography
                fontSize="0.875rem"
                text={t("not found.Go back to Main page")}
                color="#fff"
              />
            </DivAction>
          </Link>
        </FourText>
      </FourOA>

      <FourOc>
        {theme !== "light" ? <SvgFourOOne /> : <SvgFourOOneLight />}
      </FourOc>
    </FourOPageLayout>
  );
};
