import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import ProgressBar from "./ProgressBar";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import {
  CustomizedToolbar,
  FileName,
  IconsStyling,
  MainPdfViewerStyle,
  PdfViewerStyle,
} from "./ReactPdfViewer.style";
import { TypographyRegularDescription } from "../../elements/fonts/Fonts";
import { auth0SubIdSplitter, uuidSplitter, auth0ProviderSplitter } from "../../utils/StringManipulation";
import { SvgDownload } from "../../elements/Icons";
import { getFileName } from "../../components/dashboardContents/ResourcesPerModule/resourceCard/getFileName";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { ColorSelector, courseCreatorColorSelector } from "../../recoil/ThemeSelectors";
import { ClassContext } from "../dashboard/UnstructuredContext";
import { useAuth0 } from "@auth0/auth0-react";
interface ReactPdfViewerProps {
  documentUrl: string;
  percentage: number;
  classId?: string;
  documentId?: number;
  length: string;
  subId: string;
  pageNumber: number;
  downloadUrl: string;
  downloadable: boolean;
  role: string;
}
const PdfViewer = ({
  downloadable,
  documentUrl,
  downloadUrl,
  percentage,
  classId,
  documentId,
  length,
  subId,
  pageNumber,
  role
}: ReactPdfViewerProps) => {
  const { progressChanged, setProgressChange } = useContext(ClassContext)


  // const originalProgress = 100;
  // const intialPageCalc = (progressFromBack * pageNumber) / 100;
  // console.log("intialPageCalc", intialPageCalc);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(length);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(percentage);
  // const [initialPage, setInitialPage] = useState<number>(pageNumber);
  // console.log("pageNumber from documentviewer", pageNumber);
  // console.log("progress", progress);
  // console.log(
  //   "(progressFromBack *  (pageNumber - 1)) / 100 ",
  //   (progressFromBack * (pageNumber - 1)) / 100
  // );

  const progressRef = useRef(progress); // Initialize a ref with the current progress value
  progressRef.current = progress; // Update the ref whenever progress changes

  const totalNumberOfPagesRef = useRef(totalNumberOfPages); // Initialize a ref with the current progress value
  totalNumberOfPagesRef.current = totalNumberOfPages;
  useEffect(() => {
    // Reset everything to default when the component mounts
    setTotalNumberOfPages(length);
    setLoading(true);
    setProgress(percentage);
  }, []);
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  useEffect(() => {
    const interval = setInterval(() => {
      const createTrack = async () => {
        try {
          const tenantName = localStorage.getItem("tenant");
          let int = progressRef.current;
          setProgressChange((prev) => !prev);

          const updateProgressRef = async () => {
            const response = await axios.get(`https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/completionTracking/get/${Number(documentId)}/Document/${subIdSplitted}/${tenantName}?provider=${provider}`)
            // console.log("response before tracking percentage", response.data.data[0].percentage)

            if (response && response.data.data && response.data.data[0]) {
              if (response.data.data[0].percentage === undefined || response.data.data[0].percentage === null) {
                int = 0;
              }
              else {
                int = response.data.data[0].percentage
              }
            }
            else {
              int = 0;
            }
            setProgressChange((prev) => !prev);
            if (progressRef.current > int || int === 0) {
              setProgressChange((prev) => !prev);
              const trackingData = {
                classSourceId: classId,
                length: totalNumberOfPagesRef.current,
                percentage: progressRef.current, // Access the current value of progress using the ref
                refId: documentId,
                type: "Document",
                subId: subIdSplitted,
                provider: provider,
              };

              const response = await axios.post(
                `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/completionTracking/create/${tenantName}`,
                { data: trackingData }
              );

            }
          }
          updateProgressRef();
        } catch (error) {
          console.error("Error fetching document:", error);
        }
      };
      if (role === "student" && percentage !== 100)
        createTrack();
    }, 6000);

    return () => clearInterval(interval);
  }, [documentId]);

  const onPageChange = (page: any) => {
    const newProgress = (page.currentPage / (page.doc.numPages - 1)) * 100;
    if (newProgress > progress) {
      setProgress(newProgress)
    };
  };

  let fileName = "";
  if (documentUrl !== null && documentUrl !== "" && documentUrl !== undefined) {
    const lastSlashIndex = documentUrl.lastIndexOf("/");
    const questionMarkIndex = documentUrl.indexOf("?");

    if (lastSlashIndex !== -1 && questionMarkIndex !== -1 && questionMarkIndex > lastSlashIndex) {
      fileName = documentUrl.substring(lastSlashIndex + 1, questionMarkIndex);
    } else if (lastSlashIndex !== -1 && questionMarkIndex === -1) {
      fileName = documentUrl.substring(lastSlashIndex + 1);
    } else {
      fileName = documentUrl; // or whatever default value makes sense for your application
    }
  }
  else {
    fileName = "untitled"
  }
  //extract file name from url
  // const fileName = documentUrl.substring(
  //   documentUrl.lastIndexOf("/") + 1,
  //   documentUrl.indexOf("?")
  // );
  const title = getFileName(fileName, 25);

  //download plugin with filename generator
  // const getFilePluginInstance = getFilePlugin({
  //   fileNameGenerator: () => {
  //     return `${title}`;
  //   },
  // });
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const handleDownloadDoc = async () => {
    if (downloadUrl) {
      const response = await fetch(downloadUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      link.download = title || "download";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Document Url is undefined or null");
    }
  };
  const pageNavigationPluginInstance = pageNavigationPlugin();

  const { GoToNextPageButton, GoToPreviousPageButton, CurrentPageLabel } =
    pageNavigationPluginInstance;
  const onDocumentLoad = ({ doc }: any) => {
    if (doc.numPages === 1) {
      const newProgress = 100;
      // console.log("numPages", doc.numPages);

      if (newProgress > progress) {
        setProgress(newProgress)
      };
    }
    setTotalNumberOfPages(doc.numPages);
  };
  const { theme, SecondaryWhiteTextColor, SecondaryTextColor } = useRecoilValue(ColorSelector);
  const { tooltipTextColor } = useRecoilValue(courseCreatorColorSelector);
  return (
    <>
      <PdfViewerStyle color={SecondaryWhiteTextColor}>
        <MainPdfViewerStyle>
          <CustomizedToolbar color={tooltipTextColor} bgcolor={theme === "light" ? "#AAB1B9" : "#384450"}>
            <FileName>
              <TypographyRegularDescription text={title} fontSize="0.75rem" />
              <IconsStyling className="rpv-core__minimal-button rpv-core__minimal-button--disabled">
                {downloadable && <SvgDownload
                  // className="rpv-core__icon"
                  style={{
                    height: "1rem",
                    width: "1rem",
                    color: "#F6F7F7",
                  }}
                  onClick={() => {
                    handleDownloadDoc();
                  }}
                />}
              </IconsStyling>
            </FileName>
            <IconsStyling>
              <GoToPreviousPageButton />
              <CurrentPageLabel />/{totalNumberOfPages}
              <GoToNextPageButton />
            </IconsStyling>

            <ProgressBar progress={progress} />
          </CustomizedToolbar>
          <div style={{ flex: 1, overflowX: "hidden", height: "500px" }}>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={documentUrl}
                onPageChange={onPageChange}
                initialPage={Math.floor(pageNumber)}
                defaultScale={
                  screenSize.width < 942 && screenSize.width > 860
                    ? 0.9
                    : screenSize.width < 860 && screenSize.width > 811
                      ? 0.85
                      : screenSize.width <= 811 && screenSize.width > 711
                        ? 0.7
                        : screenSize.width <= 678 && screenSize.width > 571
                          ? 0.85
                          : screenSize.width <= 571 && screenSize.width > 524
                            ? 0.75
                            : screenSize.width <= 524 && screenSize.width > 482
                              ? 0.7
                              : screenSize.width <= 482 && screenSize.width > 463
                                ? 0.65
                                : screenSize.width <= 463
                                  ? 0.55
                                  : 1
                }
                plugins={[pageNavigationPluginInstance]}
                onDocumentLoad={onDocumentLoad}
              />
            </Worker>
          </div>
        </MainPdfViewerStyle>
      </PdfViewerStyle>
    </>
  );
};

export default PdfViewer;
