import React, {
  FC,
  useState,
  useEffect,
  useContext,
  useRef,
  RefObject,
} from "react";

import { SketchPicker } from "react-color";
import ReusableColorPicker from "../../elements/reusableColorPicker/ReusableColorPicker";
import { breakpoints, theme } from "../../theme";
import {
  Typography,
  LightTypography,
  MediumTypography,
} from "../../elements/fonts/Fonts";
import ReusableSubmitButton from "../../elements/reusableSubmitButton/ReusableSubmitButton";
import { useStateContext } from "../../contexts/ContextProvider";
import Certificate from "../certificate/Certificate";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { CertificateData } from "../certificate/certificateData";

import SelectDate from "../../elements/DatePicker/inputDate";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import LoaderCircle from "../loader/loaderCircle/LoaderCircle";
import {
  BigBorderInputOptions,
  SmallBorderInputOptions,
  RectBorderInputOptions,
} from "../CourseArct/CourceArchData";

import { DropZoneForm } from "../../elements/dropZone/DropZoneForm";
import {
  Overlay,
  BackgroundOverlay,
  PopupContainer,
  EditShareDownloadWrapper,
  ButtonsHolder,
  PopupContentContainer,
  IconsWrapper,
  IconWithTextHolder,
  ScrollableCertificateWrapper,
  EditSectionHolder,
  TextFieldHolder,
  TextFieldElementWrapper,
  LabelWithInputWrapper,
  Input,
  LoaderWrapper,
  UploadColorHolder,
  UploadsWrapper,
  GridItem1,
  GridItem2,
  ColorSectionMainHolder,
  ColorSection,
  TextWithCircleColor,
  ColorCircle,
  TextWithBorder,
  BorderInput,
  SketchPickerHolder,
  SketchPickerClose,
  SketchPickerSave,
  UploadsWrapperWithBadge,
  GridItem1WithBadge1,
  GridItem1WithBadge2,
  GridItem1WithBadge3,
} from "./CertificatePopUp.style";

import {
  SvgClose,
  SvgEdit,
  SvgShareAnnouncements,
  SvgCertificateDownload,
  SvgCheckModuleTopic,
} from "../../elements/Icons";

import SelectInput from "../../elements/SelectInput/SelectInput";
import { useTranslation } from "react-i18next";

interface CertificatePopUpProps {
  show: boolean;
  title?: string;
  templateType: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
  courseName: string;
}

/**
 * CertificatePopUp component renders a popup window for editing and downloading certificates.
 * It allows users to customize the appearance and content of the certificate before downloading it.
 * @param show Indicates whether the popup window is visible or not.
 * @param title The title of the popup window.
 * @param icon The icon displayed in the popup window.
 * @param setShow Function to control the visibility of the popup window.
 * @param handleSubmit Function to handle form submission or download action.
 * @param templateType The type of certificate template.
 * @param courseName The name of the course for which the certificate is issued.
 */

const CertificatePopUp: FC<CertificatePopUpProps> = ({
  show,
  title,
  icon: IconComponent,
  setShow,
  handleSubmit,
  templateType,
  courseName,
}) => {
  // State variables for managing certificate customization
  const {
    isBigLineColorShown,
    isSmallLineColorShown,
    isRectangleColorShown,
    setIsBigLineColorShown,
    setIsSmallLineColorShown,
    setIsRectangleColorShown,
    setBigLineColor,
    setSmallLineColor,
    setRectangleColor,
    bigLineColor,
    smallLineColor,
    rectangleColor,
    bigLineBorder,
    smallLineBorder,
    rectangleBorder,
    setBigLineBorder,
    setSmallLineBorder,
    setRectangleBorder,
    certificateDate,
    setCertificateDate,
    studentName,
    setStudentName,
    leftName,
    rightName,
    leftPosition,
    rightPosition,
    setLeftName,
    setRightName,
    setLeftPosition,
    setRightPosition,
  } = useStateContext();

  // Context variables
  const { bData } = useContext(ClassContext);
  const { classId } = useContext(ClassContext);
  const course = bData.find((course: { id: any }) => course.id === classId);

  // State variables for border sizes and window width
  const [bigBorderSize, setBigBorderSize] = useState<string | undefined>("M");
  const [smallBorderSize, setSmallBorderSize] = useState<string | undefined>("M");
  const [rectBorderSize, setRectBorderSize] = useState<string | undefined>("S");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation();

  // Function to handle window resize
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Effect to handle window resize event
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // Ref for PDF generation
  const pdfRef = useRef<HTMLDivElement>(null);

  // Function to format date
  function formatDate(dateString: Date) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
    const year = date.getFullYear();

    return `${day} ${month},${year}`;
  }

  // Function to get current date formatted
  function getCurrentDateFormatted(): string {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ];
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();

    return `${day} ${month},${year}`;
  }

  // State variables for loading and edit mode
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
  const [showEditMode, setShowEditMode] = useState<boolean>(false);

  // State variables for color picker visibility
  const [showEditColorBigLine, setShowEditColorBigLine] = useState<boolean>(false);
  const [showEditColorSmallLine, setShowEditColorSmallLine] = useState<boolean>(false);
  const [showEditColorRectangle, setShowEditColorRectangle] = useState<boolean>(false);

  // Function to handle certificate date change
  const handleCertificateDate = (certificateDate: Date | null) => {
    setCertificateDate(certificateDate);
  };

  // Function to handle border input change
  const handleBorderInput = (
    value: string,
    setStateFunction: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setStateFunction(value);
  };

  // Function to download certificate as PDF
  const downloadCertificate = async () => {
    setLoadingDownload(true);
    const input = pdfRef.current;
    if (input) {
      try {
        const canvas = await html2canvas(input);
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4", true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = (pdfHeight - imgHeight * ratio) / 2;
        pdf.addImage(
          imgData,
          "PNG",
          imgX,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio
        );
        pdf.save("certificate.pdf");
      } catch (error) {
        console.error("Error generating PDF:", error);
      } finally {
        setLoadingDownload(false);
      }
    }
  };

  // Accepted file types and corresponding file extensions
  const accF: { [key: string]: string[] } = {
    "image/jpeg": [".jpg", ".jpeg"],
    "image/png": [".png"],
    "image/webp": [".webp"],
  };

  return (
    <Overlay isvisible={show.toString()}>
      <BackgroundOverlay>
        <PopupContainer>
          <PopupContentContainer>
            <IconsWrapper>
              <IconComponent />
              <SvgClose onClick={() => setShow(false)} />
            </IconsWrapper>
            <Typography
              text={`${title}`}
              fontSize="1rem"
              color={theme.dark.primary_text_color}
            />
            {loadingDownload && (
              <LoaderWrapper>
                <LoaderCircle width={20} height={20} color="#5dd3b3" />
                <LightTypography
                  text="Downloading..."
                  fontSize="0.875rem"
                  color={theme.dark.primary_text_color}
                />
              </LoaderWrapper>
            )}
            {!showEditMode && (
              <EditShareDownloadWrapper>
                <IconWithTextHolder onClick={() => setShowEditMode(true)}>
                  <SvgEdit />
                  <Typography text={t("dashboard.Edit")} fontSize="0.875rem" />
                </IconWithTextHolder>
                {/* <IconWithTextHolder>
                <SvgShareAnnouncements />
                <Typography
                  text="Share On LinkedIn"
                  fontSize="0.875rem"
                  whiteSpace="nowrap"
                />
              </IconWithTextHolder> */}
                {/* <IconWithTextHolder
                  onClick={() => {
                    downloadCertificate();
                  }}
                >
                  <SvgCertificateDownload />
                  <Typography text="Download" fontSize="0.875rem" />
                </IconWithTextHolder> */}
              </EditShareDownloadWrapper>
            )}
            <ScrollableCertificateWrapper>
              <div ref={pdfRef}>
                <Certificate
                  templateType={templateType}
                  courseName={course?.title ? course?.title : courseName}
                  qrCode={CertificateData.qrCode}
                  leftSignature={CertificateData.leftSignature}
                  leftName={leftName || CertificateData.leftName}
                  leftPosition={leftPosition || CertificateData.leftPosition}
                  certificateDate={
                    certificateDate
                      ? formatDate(certificateDate)
                      : getCurrentDateFormatted()
                  }
                  rightSignature={CertificateData.rightSignature}
                  rightName={rightName || CertificateData.rightName}
                  rightPosition={rightPosition || CertificateData.rightPosition}
                  uniLogo={CertificateData.uniLogo}
                  stamp={CertificateData.stamp}
                  badge={CertificateData.badge}
                  bigLineColor={bigLineColor}
                  smallLineColor={smallLineColor}
                  rectangleColor={rectangleColor}
                  bigLineBorder={bigLineBorder}
                  smallLineBorder={smallLineBorder}
                  rectangleBorder={rectangleBorder}
                  studentName={studentName}
                />
              </div>
              {showEditMode && (
                <EditSectionHolder>
                  <TextFieldHolder>
                    <MediumTypography text="Text Filed" />
                    <TextFieldElementWrapper>
                      {/* <GridItem1>
                        <LabelWithInputWrapper>
                          <LightTypography text="Student Name" />
                          <Input
                            type="text"
                            placeholder="Enter Student Name"
                            onChange={(e: any) => {
                              setStudentName(e.target.value);
                            }}
                            value={studentName}
                          />
                        </LabelWithInputWrapper>
                      </GridItem1> */}
                      {/* <GridItem2> */}
                      <LabelWithInputWrapper>
                        <LightTypography text={t("course view.Date")} />
                        <SelectDate
                          onChange={handleCertificateDate}
                          value={certificateDate}
                          width="17.1rem"
                          showTime={false}
                        />
                      </LabelWithInputWrapper>
                      {/* </GridItem2> */}
                    </TextFieldElementWrapper>
                    <TextFieldElementWrapper>
                      <GridItem1>
                        <LabelWithInputWrapper>
                          <LightTypography text="Left Name" />
                          <Input
                            type="text"
                            placeholder="Enter Left Name"
                            onChange={(e: any) => {
                              setLeftName(e.target.value);
                            }}
                            value={leftName}
                          />
                        </LabelWithInputWrapper>
                      </GridItem1>
                      <GridItem2>
                        {templateType === "one" ? (
                          <LabelWithInputWrapper>
                            <LightTypography text="Right Name" />
                            <Input
                              type="text"
                              placeholder="Enter Right Name"
                              onChange={(e: any) => {
                                setRightName(e.target.value);
                              }}
                              value={rightName}
                            />
                          </LabelWithInputWrapper>
                        ) : (
                          <LabelWithInputWrapper>
                            <LightTypography text="Left Position/ Title" />
                            <Input
                              type="text"
                              placeholder="Enter Left Position"
                              onChange={(e: any) => {
                                setLeftPosition(e.target.value);
                              }}
                              value={leftPosition}
                            />
                          </LabelWithInputWrapper>
                        )}
                      </GridItem2>
                    </TextFieldElementWrapper>

                    {templateType === "one" && (
                      <TextFieldElementWrapper>
                        <GridItem1>
                          <LabelWithInputWrapper>
                            <LightTypography text="Left Position/ Title" />
                            <Input
                              type="text"
                              placeholder="Enter Left Position"
                              onChange={(e: any) => {
                                setLeftPosition(e.target.value);
                              }}
                              value={leftPosition}
                            />
                          </LabelWithInputWrapper>
                        </GridItem1>
                        <GridItem2>
                          <LabelWithInputWrapper>
                            <LightTypography text="Right Position/ Title" />
                            <Input
                              type="text"
                              placeholder="Enter Right Position"
                              onChange={(e: any) => {
                                setRightPosition(e.target.value);
                              }}
                              value={rightPosition}
                            />
                          </LabelWithInputWrapper>
                        </GridItem2>
                      </TextFieldElementWrapper>
                    )}
                  </TextFieldHolder>
                  <UploadColorHolder>
                    <MediumTypography text={t("course view.Upload")} />
                    {(templateType === "one" || templateType === "two") && (
                      <UploadsWrapper>
                        <GridItem1>
                          <DropZoneForm
                            text="Upload Left Signature"
                            // testDrop={handleSelectImage}
                            acceptedTypes={accF}
                            minHeight="6.25rem"
                            fontSize="0.75rem"
                            marginTop="-1.25rem"
                            widthIcon={16}
                            heightIcon={16}
                          />
                        </GridItem1>
                        <GridItem2>
                          <DropZoneForm
                            text={`${
                              templateType === "one"
                                ? " Upload Right Signature"
                                : t("course view.Upload Logo")
                            }`}
                            // testDrop={handleSelectImage}
                            acceptedTypes={accF}
                            minHeight="6.25rem"
                            fontSize="0.75rem"
                            widthIcon={16}
                            heightIcon={16}
                            marginTop="-1.25rem"
                          />
                        </GridItem2>
                      </UploadsWrapper>
                    )}

                    {templateType === "three" && (
                      <UploadsWrapperWithBadge>
                        <GridItem1WithBadge1>
                          <DropZoneForm
                            text="Upload Left Signature"
                            // testDrop={handleSelectImage}
                            acceptedTypes={accF}
                            minHeight="6.25rem"
                            fontSize="0.75rem"
                            marginTop="-1.25rem"
                            widthIcon={16}
                            heightIcon={16}
                          />
                        </GridItem1WithBadge1>
                        <GridItem1WithBadge2>
                          <DropZoneForm
                            text="Upload Badge"
                            // testDrop={handleSelectImage}
                            acceptedTypes={accF}
                            minHeight="6.25rem"
                            fontSize="0.75rem"
                            marginTop="-1.25rem"
                            widthIcon={16}
                            heightIcon={16}
                          />
                        </GridItem1WithBadge2>
                        <GridItem1WithBadge3>
                          <DropZoneForm
                            text="Upload Stamp"
                            // testDrop={handleSelectImage}
                            acceptedTypes={accF}
                            minHeight="6.25rem"
                            fontSize="0.75rem"
                            widthIcon={16}
                            heightIcon={16}
                            marginTop="-1.25rem"
                          />
                        </GridItem1WithBadge3>
                      </UploadsWrapperWithBadge>
                    )}

                    <ColorSectionMainHolder>
                      <MediumTypography text={t("course view.Color")} />
                      <ColorSection>
                        {isBigLineColorShown && (
                          <SketchPickerHolder>
                            <SketchPickerClose>
                              <SvgClose
                                style={{
                                  width: "12",
                                  height: "12",
                                  color: "5DD3B3",
                                }}
                                onClick={() => setIsBigLineColorShown(false)}
                              />
                            </SketchPickerClose>
                            <SketchPicker
                              color={bigLineColor}
                              disableAlpha
                              onChange={(color) => setBigLineColor(color.hex)}
                            />
                            <SketchPickerSave>
                              <SvgCheckModuleTopic
                                onClick={() => {
                                  setIsBigLineColorShown(false);
                                  setBigLineColor(bigLineColor);
                                }}
                              />
                            </SketchPickerSave>
                          </SketchPickerHolder>
                        )}
                        {isSmallLineColorShown && (
                          <SketchPickerHolder>
                            <SketchPickerClose>
                              <SvgClose
                                style={{
                                  width: "12",
                                  height: "12",
                                  color: "5DD3B3",
                                }}
                                onClick={() => setIsSmallLineColorShown(false)}
                              />
                            </SketchPickerClose>
                            <SketchPicker
                              color={smallLineColor}
                              disableAlpha
                              onChange={(color) => setSmallLineColor(color.hex)}
                            />
                            <SketchPickerSave>
                              <SvgCheckModuleTopic
                                onClick={() => {
                                  setIsSmallLineColorShown(false);
                                  setSmallLineColor(smallLineColor);
                                }}
                              />
                            </SketchPickerSave>
                          </SketchPickerHolder>
                        )}

                        {isRectangleColorShown && (
                          <SketchPickerHolder>
                            <SketchPickerClose>
                              <SvgClose
                                style={{
                                  width: "12",
                                  height: "12",
                                  color: "5DD3B3",
                                }}
                                onClick={() => setIsRectangleColorShown(false)}
                              />
                            </SketchPickerClose>
                            <SketchPicker
                              color={rectangleColor}
                              disableAlpha
                              onChange={(color) => setRectangleColor(color.hex)}
                            />
                            <SketchPickerSave>
                              <SvgCheckModuleTopic
                                onClick={() => {
                                  setIsRectangleColorShown(false);
                                  setRectangleColor(rectangleColor);
                                }}
                              />
                            </SketchPickerSave>
                          </SketchPickerHolder>
                        )}
                        <TextWithCircleColor>
                          <LightTypography
                            text="Big Line Color"
                            fontSize="0.875rem"
                          />
                          <ColorCircle
                            colorCircle={bigLineColor}
                            onClick={() => {
                              setShowEditColorBigLine(!showEditColorBigLine);
                              setShowEditColorSmallLine(false);
                              setShowEditColorRectangle(false);
                            }}
                          >
                            {(showEditColorBigLine || isBigLineColorShown) && (
                              <ReusableColorPicker
                                left="1.875rem"
                                top="-0.5rem"
                                type="bigLine"
                              />
                            )}
                          </ColorCircle>
                          <TextWithBorder>
                            <LightTypography
                              text="Border Size"
                              fontSize="0.875rem"
                              whiteSpace="nowrap"
                            />
                            {/* <BorderInput
                              type="number"
                              value={bigLineBorder}
                              onChange={(e: any) =>
                                handleBorderInput(
                                  e.target.value,
                                  setBigLineBorder
                                )
                              }
                            /> */}
                            <SelectInput
                              zIndex={30}
                              paddingOption="0.625rem 0 0.625rem 1.75rem"
                              color="#5DD3B3"
                              height="2.813rem"
                              width="5.438rem"
                              optionWidth="5.438rem"
                              fontSize="0.75rem"
                              marginRight="0"
                              fontFamily="Primary Font-Light"
                              options={BigBorderInputOptions}
                              onChange={(
                                selectedOption: string | undefined
                              ) => {
                                if (selectedOption === "S")
                                  setBigLineBorder(`15`);
                                else if (selectedOption === "M")
                                  setBigLineBorder(`20`);
                                else if (selectedOption === "L")
                                  setBigLineBorder(`25`);
                                {
                                  setBigBorderSize(selectedOption);
                                }
                              }}
                              value={bigBorderSize}
                              placeholder={t("course view.Select")}
                            />
                          </TextWithBorder>
                        </TextWithCircleColor>
                        <TextWithCircleColor>
                          <LightTypography
                            text="Small Line Color"
                            fontSize="0.875rem"
                          />
                          <ColorCircle
                            colorCircle={smallLineColor}
                            onClick={() => {
                              setShowEditColorSmallLine(
                                !showEditColorSmallLine
                              );
                              setShowEditColorBigLine(false);
                              setShowEditColorRectangle(false);
                            }}
                          >
                            {(showEditColorSmallLine ||
                              isSmallLineColorShown) && (
                              <ReusableColorPicker
                                left="1.875rem"
                                top="-0.5rem"
                                type="smallLine"
                                width={`${
                                  windowWidth <= parseInt("600px")
                                    ? "11rem"
                                    : "18.4375rem"
                                }`}
                                height={`${
                                  windowWidth <= parseInt("600px")
                                    ? "fit-content"
                                    : "2.1875rem"
                                }`}
                                flexWrap={`${
                                  windowWidth <= parseInt("600px")
                                    ? "wrap"
                                    : "nowrap"
                                }`}
                              />
                            )}
                          </ColorCircle>
                          <TextWithBorder>
                            <LightTypography
                              text="Border Size"
                              fontSize="0.875rem"
                              whiteSpace="nowrap"
                            />
                            {/* <BorderInput
                              type="number"
                              value={smallLineBorder}
                              onChange={(e: any) =>
                                handleBorderInput(
                                  e.target.value,
                                  setSmallLineBorder
                                )
                              }
                            /> */}
                            <SelectInput
                              zIndex={30}
                              paddingOption="0.625rem 0 0.625rem 1.75rem"
                              color="#5DD3B3"
                              height="2.813rem"
                              width="5.438rem"
                              optionWidth="5.438rem"
                              fontSize="0.75rem"
                              marginRight="0"
                              fontFamily="Primary Font-Light"
                              options={SmallBorderInputOptions}
                              onChange={(
                                selectedOption: string | undefined
                              ) => {
                                if (selectedOption === "S")
                                  setSmallLineBorder(`7.5`);
                                else if (selectedOption === "M")
                                  setSmallLineBorder(`10`);
                                else if (selectedOption === "L")
                                  setSmallLineBorder(`12.5`);
                                {
                                  setSmallBorderSize(selectedOption);
                                }
                              }}
                              value={smallBorderSize}
                              placeholder={t("course view.Select")}
                            />
                          </TextWithBorder>
                        </TextWithCircleColor>
                        <TextWithCircleColor>
                          <LightTypography
                            text="Rectangle Color"
                            fontSize="0.875rem"
                          />
                          <ColorCircle
                            colorCircle={rectangleColor}
                            onClick={() => {
                              setShowEditColorRectangle(
                                !showEditColorRectangle
                              );
                              setShowEditColorBigLine(false);
                              setShowEditColorSmallLine(false);
                            }}
                          >
                            {(showEditColorRectangle ||
                              isRectangleColorShown) && (
                              <ReusableColorPicker
                                left={`${
                                  windowWidth <= parseInt("1200px")
                                    ? "-3.35rem"
                                    : "1.875rem"
                                }`}
                                top={`${
                                  windowWidth <= parseInt("1200px")
                                    ? "-5.75rem"
                                    : "-0.5rem"
                                }`}
                                type="rectangleLine"
                                width={`${
                                  windowWidth <= parseInt("1200px")
                                    ? "8rem"
                                    : "18.4375rem"
                                }`}
                                height={`${
                                  windowWidth <= parseInt("1200px")
                                    ? "fit-content"
                                    : "2.1875rem"
                                }`}
                                flexWrap={`${
                                  windowWidth <= parseInt("1200px")
                                    ? "wrap"
                                    : "nowrap"
                                }`}
                              />
                            )}
                          </ColorCircle>
                          <TextWithBorder>
                            <LightTypography
                              text="Border Size"
                              fontSize="0.875rem"
                              whiteSpace="nowrap"
                            />
                            {/* <BorderInput
                              type="number"
                              value={rectangleBorder}
                              onChange={(e: any) =>
                                handleBorderInput(
                                  e.target.value,
                                  setRectangleBorder
                                )
                              }
                            /> */}
                            <SelectInput
                              zIndex={30}
                              paddingOption="0.625rem 0 0.625rem 1.75rem"
                              color="#5DD3B3"
                              height="2.813rem"
                              width="5.438rem"
                              optionWidth="5.438rem"
                              fontSize="0.75rem"
                              marginRight="0"
                              fontFamily="Primary Font-Light"
                              options={RectBorderInputOptions}
                              onChange={(
                                selectedOption: string | undefined
                              ) => {
                                console.log("selectedOption: ", selectedOption);
                                if (selectedOption === "S")
                                  setRectangleBorder(`1`);
                                else if (selectedOption === "M")
                                  setRectangleBorder(`2`);
                                else if (selectedOption === "L")
                                  setRectangleBorder(`4`);
                                {
                                  setRectBorderSize(selectedOption);
                                }
                              }}
                              value={rectBorderSize}
                              placeholder={t("course view.Select")}
                            />
                          </TextWithBorder>
                        </TextWithCircleColor>
                      </ColorSection>
                    </ColorSectionMainHolder>
                  </UploadColorHolder>
                </EditSectionHolder>
              )}
            </ScrollableCertificateWrapper>

            {showEditMode && (
              <ButtonsHolder>
                <ReusableSubmitButton
                  text={t("forms.Save")}
                  width="8.813rem"
                  height="2.125rem"
                  padding="0.56rem 2rem 0.5rem 2rem"
                  onClick={() => {
                    handleSubmit();
                    setShowEditMode(false);
                  }}
                />
              </ButtonsHolder>
            )}
          </PopupContentContainer>
        </PopupContainer>
      </BackgroundOverlay>
    </Overlay>
  );
};

export default CertificatePopUp;
