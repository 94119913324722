import React, { FC } from 'react';
import { SearchContainer, SearchInput, SearchAndFilterContainer, Line, FilterButton, PositionContainer,FilterContainer,SortContainer } from './SearchAndFilter.style';
import { ReactComponent as SearchIcon } from '../../../assets/icons/Search 1.svg'
import { Typography, TypographyRegularDescription } from '../../../elements/fonts/Fonts';
import {ReactComponent as DownArrowIcon} from '../../../assets/icons/Arrow.svg'
const SearchAndFilter: FC = () => {

    return (
        <SearchAndFilterContainer>
            <SearchContainer>
                <SearchInput type='text' placeholder='Search' />
                <SearchIcon />
            </SearchContainer>
            <Line />
            <FilterContainer>
            <FilterButton>Filter</FilterButton>
            <SortContainer>
            <Typography text='Sort By' fontSize='0.75rem' />
            <PositionContainer>
                <TypographyRegularDescription text='Position' fontSize='0.625rem' />
                <DownArrowIcon/>
            </PositionContainer>
            </SortContainer>
            </FilterContainer>

        </SearchAndFilterContainer>
    )
}
export default SearchAndFilter;