import Styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const NotificationWrapperParent = Styled.div<StyleThemeInterface>`
   width: 18rem;
  max-height: 20rem;
  box-shadow: 0px 4px 20px #00000040;
  padding: 0.25rem 0 0 0;
  /* overflow-y:auto; */
  display:flex;
  flex-direction:column;
  gap:0.75rem;
  border-radius: 0.625rem;
  background:${(props) => props.bgcolor};
box-shadow:${(props) =>
  props.theme === "light"
    ? "4px 4px 14px 0px rgba(192, 220, 214, 0.32)"
    : "4px 4px 4px 0px rgba(0, 0, 0, 0.25)"};

overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
  
    background: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)"
        : "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)"}  ;
    border-radius: 0.625rem;

  }
 
  &::-webkit-scrollbar-track {
    /* background: ${(props) =>
      props.theme === "light" ? "#F7F7F8" : "#2d3741"}  ; */
    background:transparent;
    
    border-radius: 0.625rem;
  }
`;

export const NotificationWrapper = Styled.div`
   max-width: 100%;
  height: 100%;

  /* box-shadow: 0px 4px 20px #00000040; */
  /* overflow-x:hidden; */
  overflow-y:auto;
  display:flex;
  flex-direction:column;
  gap:0.75rem;


/*  
  &::-webkit-scrollbar {
    width: 0.25rem; 
  }

  &::-webkit-scrollbar-thumb {
    background-color:#5dd3b3; 
    border-radius:  0.25rem; 
    height:98%;
    margin-left:-0.7rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #2d3741;
    border-radius: 0.25rem; 
    height:98%;
    margin-left:-0.7rem;
  } */
`;
