import React, { useContext, useEffect, useState } from "react";
import {
  Menu,
  FrameWrapper,
  Frame,
  Icon,
  MenuText,
  HoveredDiv,
  RedCircle,
} from "./SubMenu.style";
import {
  LightTypography,
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import {
  SvgDashboardIcon,
  SvgCalendarIcon,
  SvgNotesIcon,
  SvgAnnouncement,
  SvgHelpIcon,
  SvgTodoListIcon,
} from "../../../elements/Icons";
import { Link, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  DashBoardColorSelector,
} from "../../../recoil/ThemeSelectors";
import { MenuItem } from "./sidebarInterface";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../../utils/StringManipulation";
import { menuData } from "./sidebarData";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
interface SuMenuProps {
  sidebarMini: boolean;
}
export const SubMenu = ({ sidebarMini }: SuMenuProps): JSX.Element => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const { unviewedAnnouncmentCount } = useContext(ClassContext);

  useEffect(() => {
    const currentPath = location.pathname;
    const matchingItem = menuData.find(
      (item) =>
        currentPath.includes(item.path) ||
        (item.path === "/dashboard" && currentPath === "/") ||
        currentPath === "/Allcourses"
    );
    if (matchingItem) {
      if (
        currentPath.includes("courseview") ||
        currentPath.includes("CourseViewLayout")
      )
        setActiveItem(null);
      else setActiveItem(matchingItem.path);
    } else {
      setActiveItem(null);
    }
  }, [location]);

  const handleItemClick = (url: string) => {
    setActiveItem(url);
  };

  const { t } = useTranslation();
  const direction = document.dir;

  const { backgroundColor, themeTextColor } = useRecoilValue(ColorSelector);
  const { SideBarActiveBoxBgColor, sideBarIconTextColor } = useRecoilValue(
    DashBoardColorSelector
  );
  return (
    <Menu sidebarmini={sidebarMini.toString()}>
      {menuData.map((item, index) => (
        <FrameWrapper
          key={index}
          isactive={(item.path === activeItem).toString()}
          onClick={() => handleItemClick(item.path)}
          sidebarmini={sidebarMini.toString()}
          activehoverbgcolors={SideBarActiveBoxBgColor}
          icontextcolors={sideBarIconTextColor}
        >
          <Link to={item.path}>
            <Frame sidebarmini={sidebarMini.toString()}>
              <Icon
                style={{
                  margin: `${
                    item.path === "/announcements" && direction === "rtl"
                      ? "0 -0.25rem 0 0.25rem "
                      : "0"
                  }`,
                }}
              >
                {" "}
                <item.icon />
              </Icon>
              <MenuText
                sidebarmini={sidebarMini.toString()}
                // style={{ color: themeTextColor }}
              >
                <TypographyRegularDescription
                  text={capitalizeFirstLetter(t(item.title))}
                />
              </MenuText>
            </Frame>
          </Link>
          <HoveredDiv
            bgcolor={backgroundColor}
            color={themeTextColor}
            sidebarmini={sidebarMini.toString()}
          >
            <LightTypography
              text={capitalizeFirstLetter(t(item.title))}
              fontSize="0.75rem"
            />
          </HoveredDiv>
          {unviewedAnnouncmentCount > 0 &&
          t(item.title).toUpperCase() === "ANNOUNCEMENT" ? (
            <RedCircle left={sidebarMini ? "3.4rem" : "4.92rem"}>
              <Typography
                color="#fff"
                fontSize="0.5rem"
                text={unviewedAnnouncmentCount.toString()}
              />
            </RedCircle>
          ) : (
            ""
          )}
        </FrameWrapper>
      ))}
    </Menu>
  );
};
