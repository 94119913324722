import React from 'react'
import { Container, FlexDiv, InfoCont, ProfileAndRank, ProfileContainer, Rank } from './ViewAll.style'
import { MediumTypography, Typography } from '../../../elements/fonts/Fonts'
import { ReactComponent as SvgStarIcon } from "../../../assets/icons/MiniStar.svg";

const ViewAll = ({sortedLeaderboardData, index}: any) => {
  return (
    <InfoCont>
        <ProfileAndRank>
            <ProfileContainer bgimage={sortedLeaderboardData.profile} />
            <Rank>
              <Typography text={index + 1} fontSize="0.75rem" />
            </Rank>
          </ProfileAndRank>
          <Typography text={sortedLeaderboardData.name} fontSize="1rem" />
          <FlexDiv gap="0.63rem" style={{ justifyContent: "center" , marginTop: "0.63rem"}}>
            <MediumTypography text={sortedLeaderboardData.points} fontSize="0.875rem" />
            <SvgStarIcon width="0.875rem" height="0.875rem" />
          </FlexDiv>
    </InfoCont>
  )
}

export default ViewAll