import { FC } from "react";
import {
  CourseObjectivesMainWrapper,
  CourseObjectivesWrapper,
  CheckedWithTextWrapper,
} from "./CourseObjectives.style";
import { Typography, LightTypography } from "../../../elements/fonts/Fonts";
import IconWithText from "../../../elements/iconWithText/main/IconWithText";
import { ReactComponent as SvgCheckedIcon } from "../../../assets/icons/Group_1853.svg";
import Objectives from "./courseObjectivesData";
import { useTranslation } from "react-i18next";
import CoursesDisplay from "../CoursesDisplay/CoursesDisplay";
import {
  CoursesContainerContainer,
  CoursesContainerDataContainer,
  CoursesContainerTitleContainer,
} from "../CoursesDisplay/CoursesDisplay.style";
import { useParentClass } from "../../../utils/getParentClass";
import { useParams } from "react-router-dom";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";

interface CourseObjectivesProps {
  courseObjectives: string[]; // Define the type of courseObjectives as an array of strings
}

const CourseObjectives: FC<CourseObjectivesProps> = ({ courseObjectives }) => {
  const { t } = useTranslation();
  const params = useParams();
  const classId = params.classId;
  const { isParent } = useParentClass(classId);

  return (
    <>
      {courseObjectives && courseObjectives.length > 0 && (
        <CourseObjectivesMainWrapper>
          <Typography
            text={`${t("course view.Course Objectives")} :`}
            fontSize="1.375rem"
          />
          <CourseObjectivesWrapper
            style={{
              width: "100%",

              display: "flex",
              flexDirection: "column",
            }}
          >
            {courseObjectives && courseObjectives.length > 0 ? (
              <CoursesContainerContainer>
                {courseObjectives.map((item: any, index: number) => (
                  <CoursesContainerTitleContainer>
                    {isParent ? (
                      <Typography
                        fontSize="1.375rem"
                        text={item.courseTitle || ""}
                      />
                    ) : (
                      ""
                    )}
                    <CoursesContainerDataContainer>
                      {item.objective && item.objective.length > 0 ? (
                        <>
                          {item.objective &&
                            item.objective.map((obj: any) => (
                              <div style={{ display: "flex", gap: "0.5rem" }}>
                                <div style={{ width: "1rem" }}>
                                  <SvgCheckedIcon />
                                </div>
                                <LightTypography
                                  text={obj || ""}
                                  fontSize="0.875rem"
                                  lineHeight="1.375rem"
                                />
                              </div>
                            ))}
                        </>
                      ) : (
                        <LightTypography
                          text={t("course view.No Objectives Yet!")}
                          fontSize="0.875rem"
                          lineHeight="1.375rem"
                        />
                      )}
                    </CoursesContainerDataContainer>
                  </CoursesContainerTitleContainer>
                ))}
              </CoursesContainerContainer>
            ) : (
              <LightTypography
                text={t("course view.No Objectives Yet!")}
                fontSize="0.875rem"
                lineHeight="1.375rem"
              />
            )}
          </CourseObjectivesWrapper>
        </CourseObjectivesMainWrapper>
      )}
    </>
  );
};

export default CourseObjectives;
