import React, { FC, useState, useEffect, useRef } from "react";
import { ToDoCardProps } from "./ToDoCardInterface";
import {
  TaskCardContainer,
  TaskImage,
  TaskMainContent,
  TaskMainWrapper,
  TaskFlexContainer,
  DateContainer,
  DateCircle,
  TaskFlexDateContainer,
  DateTextWrapper,
  TaskIconWraper,
} from "./ToDoCard.style";
import {
  LightTypography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/DeleteTask.svg";
import { ReactComponent as AlertIcon } from "../../../assets/icons/Group 2190.svg";
import { ReactComponent as HourIcon } from "../../../assets/icons/time_1.svg";
import { ReactComponent as NewsIcon } from "../../../assets/icons/news.svg";
import { ReactComponent as ToDoIcon } from "../../../assets/icons/To do list.svg";
import { ReactComponent as SvgToDoIcon } from "../../../assets/icons/TodoList.svg";
import PopUp from "../../../elements/Popup/PopUp";
import PopUpUpdate from "../../../elements/Popup/PopUpUpdate";
import ToDoShow from "../../ToDoShowTask/main/ToDoShow";
import { Task } from "../../../Views/ToDoView/ToDoDataIntrface";
import ToDoUpdateTask from "../../ToDoUpdateTask/main/ToDoUpdateTask";
import { useDrag } from "react-dnd";
import PopupDelete from "../../../elements/PopupDelete/PopupDelete";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import ProfileImgHolder from "../../../elements/ProfileImgHolder/ProfileImgHolder";

const ToDoCard: FC<ToDoCardProps> = ({
  task,
  index,
  handleEditTask,
  removeTask,
  allTasks,
}) => {
  const [timeDifferenceString, setTimeDifferenceString] = useState<string>("");
  const [isTaskOverdue, setIsTaskOverdue] = useState<boolean>(false);
  const [isTaskNearDeadline, setIsTaskNearDeadline] = useState<boolean>(false);
  const [showTask, setShowTask] = useState<boolean>(false);
  const [showEditTask, setShowEditTask] = useState<boolean>(false);
  const [editTask, setEditTask] = useState<any>({
    name: "",
    date: "",
    idx: 0,
    description: "",
    enrollment: "",
    id: 0,
    image: "",
    proiority: "",
    section: "",
    stageId: 0,
    webLink: "",
    userSourceId: "",
    classSourceId: "",
  });
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState<Task | null>(null);

  useEffect(() => {
    if (!showEditTask) {
      setEditTask({
        name: "",
        date: "",
        idx: 0,
        description: "",
        enrollment: "",
        id: 0,
        image: "",
        proiority: "",
        section: "",
        stageId: 0,
        webLink: "",
        userSourceId: "",
        classSourceId: "",
      });
    }
  }, [showEditTask]);
  const { t } = useTranslation();

  useEffect(() => {
    const taskDate = task.date ? new Date(task.date) : null;
    const currentDate = new Date();

    if (taskDate) {
      const timeDifferenceMs = +taskDate - +currentDate;
      const hoursDifference = Math.floor(timeDifferenceMs / 3600000);
      const minutesDifference = Math.floor(
        (timeDifferenceMs % 3600000) / 60000
      );

      if (timeDifferenceMs < 0) {
        setTimeDifferenceString(t("to do list.Over Due"));
      } else {
        if (hoursDifference >= 24) {
          const daysDifference = Math.floor(hoursDifference / 24);
          setTimeDifferenceString(
            `${daysDifference} ${t("calendar.Day")}${
              daysDifference > 1 && localStorage.getItem("language") === "en"
                ? "s"
                : ""
            }`
          );
        } else {
          setTimeDifferenceString(
            `${hoursDifference} ${t(
              "to do list.hours"
            )} ${minutesDifference} ${t("to do list.minutes")}`
          );
        }
      }

      setIsTaskOverdue(taskDate < currentDate);
      setIsTaskNearDeadline(
        taskDate > currentDate && timeDifferenceMs <= 24 * 3600000
      );
    }
  }, [task.date, t]);

  const ref: any = useRef(null);

  const [, drag] = useDrag({
    type: "TASK",
    item: { type: "TASK", index: index, id: task.id, section: task.section },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(ref);

  const removeTaskFromLocalStorage = (taskId: number) => {
    const currentTaskData = JSON.parse(
      localStorage.getItem("taskData") || "[]"
    );
    const updatedTaskData = currentTaskData.filter(
      (task: any) => task.id !== taskId
    );
    localStorage.setItem("taskData", JSON.stringify(updatedTaskData));
  };

  const handleDeleteTask = () => {
    if (taskToDelete) {
      removeTask(index); // Assuming this removes the task from your UI
      removeTaskFromLocalStorage(taskToDelete.id); // Remove the task from localStorage
      setShowDeletePopup(false);
    }
  };
  const { inputBorderColor } = useRecoilValue(ColorSelector);
  return (
    <>
      <TaskCardContainer
        inputbordercolor={inputBorderColor}
        id="task-card"
        border={task.proiority ? task.proiority : inputBorderColor}
        onClick={() => setShowEditTask(true)}
        ref={ref}
      >
        <TaskMainContent>
          <ProfileImgHolder imgSrc={task.image} Size="1.875rem"/>
          <TaskMainWrapper>
            <TaskFlexContainer>
              <TypographyRegularDescription
                text={
                  task.enrollment === "Personal"
                    ? t(`to do list.${task.enrollment}`)
                    : task.enrollment || ""
                }
                fontSize="0.75rem"
              />
              <TaskIconWraper>
                <DeleteIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setTaskToDelete(task);
                    setShowDeletePopup(true);
                  }}
                />
              </TaskIconWraper>
            </TaskFlexContainer>
            <TaskFlexContainer>
              <TypographyRegularDescription
                text={`${task.name}`}
                fontSize="0.875rem"
              />
              {task.forumType ? (
                <LightTypography
                  text={`${task.forumType}`}
                  fontSize="0.625rem"
                  color="#AAB1B9"
                />
              ) : (
                ""
              )}
            </TaskFlexContainer>

            <TaskFlexDateContainer
              section={task?.section}
              submit={task.isSubmitted}
            >
              <DateContainer>
                {!task.date ? (
                  <>
                    <DateCircle border={task.proiority ? task.proiority : ""} />
                    <TypographyRegularDescription
                      text={t("to do list.No due date")}
                      fontSize="0.75rem"
                    />
                  </>
                ) : (
                  <>
                    {task?.section === "inprogress" ? (
                      <AlertIcon />
                    ) : (
                      <HourIcon color="#5DD3B3" />
                    )}
                    <TypographyRegularDescription
                      text={timeDifferenceString}
                      fontSize="0.75rem"
                    />
                  </>
                )}
              </DateContainer>
              {task?.section !== "done" && task?.date ? (
                <>
                  {isTaskOverdue ? (
                    <DateTextWrapper>
                      <LightTypography
                        text={t(
                          "to do list.You have missed the deadline. This task is overdue"
                        )}
                        fontSize="0.625rem"
                        color="#AAB1B9"
                        lineHeight="0.9375rem"
                      />
                    </DateTextWrapper>
                  ) : isTaskNearDeadline ? (
                    <DateTextWrapper>
                      <LightTypography
                        text={t("to do list.This task is near the deadline")}
                        fontSize="0.625rem"
                        color="#AAB1B9"
                        lineHeight="0.9375rem"
                      />
                    </DateTextWrapper>
                  ) : (
                    ""
                  )}
                </>
              ) : task?.section === "done" &&
                task.isSubmitted === "Submitted" ? (
                <LightTypography
                  text={t("to do list.Submitted")}
                  fontSize="0.625rem"
                  color="#AAB1B9"
                  lineHeight="0.9375rem"
                />
              ) : (
                ""
              )}
            </TaskFlexDateContainer>
          </TaskMainWrapper>
        </TaskMainContent>
      </TaskCardContainer>

      {showTask ? (
        <PopUp
          title={`${task.name}`}
          showline={true}
          icon={<NewsIcon />}
          content={<ToDoShow task={task} />}
          show={showTask}
          buttonName={t("forum.Submit")}
          setShow={setShowTask}
          marginTop="9rem"
          showButtonCancel={true}
        />
      ) : showEditTask ? (


        <PopUp
          showline={false}
          showButton={false}
title={t("to do list.Update Task")}
icon={<SvgToDoIcon />}
          // CloseRightPadding="1.7rem"
 
          content={
            <ToDoUpdateTask
              allTasks={allTasks}
              index={index}
              task={editTask}
              setEditTask={setEditTask}
              setShow={setShowEditTask}
              handleSubmit={() => {
                handleEditTask(index, editTask);
                // setShowTask(true);
              }}
            />
          }
          show={showEditTask}
          setShow={setShowEditTask}
          handleSubmit={() => {
            handleEditTask(index, editTask);
            // setShowTask(true);
          }}
        />
      ) : (
        ""
      )}
      {showDeletePopup && (
        <PopupDelete
          show={showDeletePopup}
          title={t("to do list.Are you sure you want to delete this task?")}
          setShow={setShowDeletePopup}
          handleSubmit={handleDeleteTask}
        />
      )}
    </>
  );
};
export default ToDoCard;
