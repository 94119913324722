import { toast } from "react-toastify";
import { Activity } from "./interfaces";

//Daqta item interface
export   interface DataItem {
    AssignmentData: any[]; // Change 'any[]' to the actual type of your data
    QuizData: any[]; // Change 'any[]' to the actual type of your data
    ForumData: any[]; // Change 'any[]' to the actual type of your data
  }

  
// Toggle modal state
export const toggleModel = (prevModal: boolean, setModal: React.Dispatch<React.SetStateAction<boolean>>) => {
    setModal((prevModal) => !prevModal); // Toggle the modal state
};
// End of Function


// Toggle offline activity modal state
export const toggleOfflineActivityModel = (prevModal: boolean, setOfflineActivityModal: React.Dispatch<React.SetStateAction<boolean>>) => {
    setOfflineActivityModal((prevModal) => !prevModal); // Toggle the modal state
}; 
// End of Function


// Handle save function for saving data and checking weights
export const handleSave = (
    assignments: any[],
    isAssignmentCheckedArray: boolean[],
    quizs: any[],
    isquizCheckedArray: boolean[],
    forums: any[],
    isForumCheckedArray: boolean[],
    ForumWeight: number | null,
    AttendanceWeight: number | null,
    QuizWeight: number | null,
    AssignmentWeight: number | null
) => {
    const forumWeightStr = ForumWeight !== undefined ? String(ForumWeight) : "0";
    const attendanceWeightStr = AttendanceWeight !== undefined ? String(AttendanceWeight) : "0";
    const quizWeightStr = QuizWeight !== undefined ? String(QuizWeight) : "0";
    const assignmentWeightStr = AssignmentWeight !== undefined ? String(AssignmentWeight) : "0";
    const totalWeight = parseInt(forumWeightStr) + parseInt(attendanceWeightStr) + parseInt(quizWeightStr) + parseInt(assignmentWeightStr);

    if (totalWeight !== 100) {
        toast.error("Total weight must be 100%!");
        return; // Exit function if the total weight is not 100
    }

    // Calculate the total sum for assignments, including both checked and unchecked
    const assignmentsSum = assignments.reduce((total, assignment, index) => {
        return total + (isAssignmentCheckedArray[index] ? assignment.Weight : 0);
    }, 0);

    if (assignmentsSum !== 100) {
        toast.error("Assignment sum must be 100%!");
        return; // Exit function if sum is not 100
    }

    // Calculate the total sum for quizs, including both checked and unchecked
    const quizsSum = quizs.reduce((total, quiz, index) => {
        return total + (isquizCheckedArray[index] ? quiz.Weight : 0);
    }, 0);

    if (quizsSum !== 100) {
        toast.error("Quiz sum must be 100%!");
        return; // Exit function if sum is not 100
    }

    // Calculate the total sum for forums, including both checked and unchecked
    const forumsSum = forums.reduce((total, forum, index) => {
        return total + (isForumCheckedArray[index] ? forum.Weight : 0);
    }, 0);

    if (forumsSum !== 100) {
        toast.error("Forum sum must be 100%!");
        return; // Exit function if sum is not 100
    }

    // If all sums are valid, proceed with data sending
    const dataToSend = {
        assignments: assignments.filter((_, index) => isAssignmentCheckedArray[index]),
        quizs: quizs.filter((_, index) => isquizCheckedArray[index]),
        forums: forums.filter((_, index) => isForumCheckedArray[index]),
        forumWeight: ForumWeight,
        AttendanceWeight: AttendanceWeight,
        QuizWeight: QuizWeight,
        AssignmentWeight: AssignmentWeight,
    };

    toast.success("Weights sent successfully!");
};

// End of Function


// Handle activity view
export const handleActivityView = (
    setShouldGroupPerActivity: React.Dispatch<React.SetStateAction<boolean>>,
    setShouldGroupPerModule: React.Dispatch<React.SetStateAction<boolean>>
) => {
    setShouldGroupPerActivity(true);
    setShouldGroupPerModule(false);
}; 
// End of Function


// Form input handler for search 
export const formInputHandler = (
    formInputValue: string,
    setFormInputValue: (newValue: string) => void,
    currentActivities: any[], // Assuming currentActivities is an array of objects with a 'name' property
    setCurrentActivities: (newActivities: any[]) => void
  ) => {
    const handleFormInputChange = (newValue: string) => {
      setFormInputValue(newValue);
      const filteredActivities = currentActivities.filter((activity) =>
        activity.name.toLowerCase().includes(newValue.toLowerCase())
      );
      setCurrentActivities(filteredActivities);
    };
  
    return {
      handleFormInputChange,
      formInputValue,
      setFormInputValue,
      currentActivities,
      setCurrentActivities
    };
  };
  

// End of Function

// Handle input state change of the weights
export const handleInputStateChange = (percentage: number, setWeight: (percentage: number) => void) => {
    setWeight(percentage);
};
// End of Function


//Hanlde main activity weight chnage 
export const handleMainActivityChange = (
    activityType: string,
    event: React.ChangeEvent<HTMLInputElement>,
    mainActivities: any[],
    setMainActivities: React.Dispatch<any[]>
) => {
    const value = Number(event.target.value);
    // Find the index of the main activity in the mainActivities array
    const index = mainActivities.findIndex(activity => Object.keys(activity)[0] === activityType);
    if (index !== -1) {
        // Clone the mainActivities array to avoid mutating state directly
        const updatedMainActivities = [...mainActivities];
        // Update the weight of the main activity
        updatedMainActivities[index][activityType].Weight = value;
        // Set the updated mainActivities array to state
        setMainActivities(updatedMainActivities);
    }
};
//Hanlde main attendance weight chnage 
export const handleAttendanceChange = (
    value: number,
    setAttendance: React.Dispatch<number | null>
) => {
    setAttendance(value);
}



  

 
//end of function

// Handle weight change
export const handleWeightChange = (
    contentId: number,
    event: React.ChangeEvent<HTMLInputElement>,
    setState: React.Dispatch<React.SetStateAction<any[]>>,
    state: any[]
) => {
    // Get the value from the input field and parse it to an integer
    const newValue = parseInt(event.target.value);

    // Check if the newValue is a non-negative number
    if (isNaN(newValue) || newValue < 0) {
        // If the input is not a valid number or it's negative, return without updating the state
        return;
    }

    const updatedState = state.map(item => {
        // Check if the item's contentId matches the contentId passed as an argument
        if (item.content?.contentId === contentId) {
            return {
                ...item,
                weight: newValue
            };
        }
        return item;
    });

    setState(updatedState); // Update the state with the modified item
};


// End of Function


// Handle checkbox change to check what to send on save
export const handleCheckboxChange = (
    id: number,
    setIsCheckedArray: React.Dispatch<React.SetStateAction<boolean[]>>,
    isCheckedArray: boolean[],
    activities: Activity[],
    setActivities: React.Dispatch<React.SetStateAction<Activity[]>>
  ) => {
    setIsCheckedArray((prevState: boolean[]) => {
      const newState = [...prevState];
      newState[id] = !newState[id]; // Toggle the checkbox state
      return newState;
    });
  
    // Find the activity with the corresponding contentId
    const activityToUpdate = activities.find(activity => activity.content.contentId === id);
    if (activityToUpdate) {
      // Update the publish property of the found activity
      setActivities(prevState => {
        const updatedActivities = prevState.map(activity => {
          if (activity.content.contentId === id) {
            // Toggle the publish property
            return {
              ...activity,
              publish: !activity.publish
            };
          }
          return activity;
        });
        return updatedActivities;
      });
    }
  };
  
  
  
// End of Function


// Module view switching
export const moduleView = (
    AssignmentData: any[],
    QuizData: any[],
    ForumData: any[],
    setFilteredDataByModule: React.Dispatch<React.SetStateAction<{ [key: string]: DataItem }>>,
    setShouldGroupPerModule: React.Dispatch<React.SetStateAction<boolean>>,
    setShouldGroupPerActivity: React.Dispatch<React.SetStateAction<boolean>>
) => {
    // Create an object to store filtered data by modules
    const filteredDataByModule: { [key: string]: DataItem } = {};

    // Iterate through each module
    for (let module = 1; module <= 3; module++) {
        // Filter AssignmentData, QuizData, and ForumData based on the current module
        const filteredAssignmentData = AssignmentData.filter(
            (data) => data.module === module
        );
        const filteredQuizData = QuizData.filter(
            (data) => data.module === module
        );
        const filteredForumData = ForumData.filter(
            (data) => data.module === module
        );

        // Store filtered data in the object with the module as the key
        filteredDataByModule[`module ${module}`] = {
            AssignmentData: filteredAssignmentData,
            QuizData: filteredQuizData,
            ForumData: filteredForumData,
        };
    }

    // Log the object containing filtered data by modules
    setFilteredDataByModule(filteredDataByModule);
    setShouldGroupPerModule(true);
    setShouldGroupPerActivity(false);
}; 
// End of Function
