import React from "react";
import {
  MatchesAfterSubmissionCols,
  MatchesAfterSubmissionContainer,
  MatchesAfterSubmissionLabel,
  MatchesAfterSubmissionLayout,
} from "./MatchesAfterSubmission.style";
import { LightTypography } from "../fonts/Fonts";

interface MatchesAfterSubmissionInterface {
  index: number;
  answer?: any;
}

const MatchesAfterSubmission = ({
  index,
  answer,
}: MatchesAfterSubmissionInterface) => {
  return (
    <MatchesAfterSubmissionLayout>
      {answer && (
        <MatchesAfterSubmissionContainer>
          <MatchesAfterSubmissionCols>
            <LightTypography
              text={`Answer ${index < 10 ? `0${index}` : `${index}`} `}
            />
            <MatchesAfterSubmissionLabel opacity="0.5">
              <LightTypography text={answer.left} fontSize="0.875rem" />
            </MatchesAfterSubmissionLabel>
          </MatchesAfterSubmissionCols>
          <MatchesAfterSubmissionCols>
            <LightTypography
              text={`Choice ${index < 10 ? `0${index}` : `${index}`} `}
            />
            <MatchesAfterSubmissionLabel>
              <LightTypography text={answer.right} fontSize="0.875rem" />
            </MatchesAfterSubmissionLabel>
          </MatchesAfterSubmissionCols>
        </MatchesAfterSubmissionContainer>
      )}
    </MatchesAfterSubmissionLayout>
  );
};

export default MatchesAfterSubmission;
