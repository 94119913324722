import React from "react";
import { BtnsContainer, CourseImageContainer, ExpandView, FlexBetween, FlexDiv, ProfileContainer, ProfileInfo, } from "./ExpandFeedView.style";
import { HourIcon, SvgEdit, SvgShareIcon, SvgEye, SvgSubtitlesLanguageIcon, } from "../../elements/Icons";
import { Typography, TypographyRegularDescription, LightTypography, } from "../../elements/fonts/Fonts";
import { TextEditor } from "../../elements/TextEditor/TextEditor";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import CancelButton from "../../elements/StyledButton/CancelButton";

const ExpandFeedView = ({ expandedPost, PostData, handleBackArrowClick, }: any) => {
  return (
    <ExpandView>
      <FlexBetween style={{ marginBottom: "1.25rem" }}>
        <FlexDiv gap="1.25rem">
          <ProfileContainer bgimage={PostData.learnerProfile} />
          <ProfileInfo>
            <Typography text={PostData.learnerName} fontSize="1.125rem" />
            <FlexDiv>
              <HourIcon />
              <TypographyRegularDescription text="An hour ago" fontSize="0.75rem" />
            </FlexDiv>
          </ProfileInfo>
        </FlexDiv>
        <FlexDiv gap="1.25rem">
          <SvgEdit />
          <SvgShareIcon />
        </FlexDiv>
      </FlexBetween>
      <Typography text={expandedPost.courseName} fontSize="1.125rem" />
      <CourseImageContainer bgimage={expandedPost.courseImage} height="13.75rem" marginBottom="1.31rem" />

      <LightTypography text={expandedPost.postDescription} fontSize="0.875rem" />

      <FlexDiv gap="0.63rem" style={{ marginTop: "1.44rem" }}>
        <FlexDiv>
          <SvgEye color="#5DD3B3" />
          <TypographyRegularDescription text={`${expandedPost.views} Views`} fontSize="0.875rem" color="#5DD3B3" />
        </FlexDiv>
        <FlexDiv>
          <SvgSubtitlesLanguageIcon width="0.875rem" />
          <TypographyRegularDescription text={`${expandedPost.responses} Responses`} fontSize="0.875rem" color="#5DD3B3" />
        </FlexDiv>
      </FlexDiv>
      {/* 
          <FlexDiv gap="1.25rem" style={{ marginTop: "2.06rem" }}>
            <ProfileContainer bgimage={PostData.learnerProfile} />
            <ProfileInfo> <Typography text={PostData.learnerName} fontSize="1.125rem" /> </ProfileInfo>
          </FlexDiv>

          <div>
            <TinyEditor onChange={() => {}} placeholder="Type something" // initialValue={} height="13.25rem" />
          </div>

          <BtnsContainer>
            <CancelButton onClickFunction={() => {}} name="Add Attachment" color="#5DD3B3" borderColor="#5DD3B3" backgroundColor="transparent" />
            <CancelButton onClickFunction={() => {}} name="Say It" color="#5DD3B3" borderColor="#5DD3B3" backgroundColor="transparent" />
            <CancelButton onClickFunction={() => {}} name="Capture It" color="#5DD3B3" borderColor="#5DD3B3" backgroundColor="transparent" />
            <CancelButton onClickFunction={() => {}} name="Post" color="#fff" borderColor="#5DD3B3" backgroundColor="#5DD3B3" />
          </BtnsContainer> */}
    </ExpandView>
  );
};

export default ExpandFeedView;
