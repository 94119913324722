import React, { useState } from "react";
import {
  SPContainer,
  SPContainerMessagePlace,
  SPContainerProfile,
  SPContainerRow,
  SPTable,
  SPTableBigRow,
  SPTableBody,
  SPTableBodyCells,
  SPTableCols,
  SPTableHeader,
  SPTableHeaderCell,
  SPTableRow,
} from "./StudentProgressTablestyle";
import {
  StudentProgressTableData,
  StudentProgressTableHeaderData,
} from "./StudentProgressTableData";
import { LightTypography, Typography } from "../../../elements/fonts/Fonts";
import MessageSenderInfo from "../../../elements/messageSenderInfo/MessageSenderInfo";
import { SvgEmail, SvgChat, SvgDownload } from "../../../elements/Icons";
import { InstructorViewCompletionSearchContainer } from "../../StudentCompletionTrackingInstrcuterView/InstructorViewCompletion.style";
import SearchElement from "../../../elements/searchElement/SearchElement";
import {
  AqMINIRow,
  AqMiniCol,
} from "../../AnalyticsQuizzes/AnalyticsQuizzes.style";
import { LogoAndAction } from "../../GradesAnalyticsTable/GradesAnalyticsTable.style";
import { ExcelDownload } from "./Function";
interface StudentProgressTableProps {
  StdId: any;
}
const StudentProgressTable = ({ StdId }: StudentProgressTableProps) => {
  const [SearchText, setSearchText] = useState("");
  const filteredData = StudentProgressTableData.map((student) => {
    const filteredModels = (student.Models || []).map((model) => {
      const filteredModelData = (model.ModelData || []).filter((data) =>
        data.ActivitiesResources?.toLowerCase().includes(
          SearchText.toLowerCase()
        )
      );

      return {
        ...model,
        ModelData: filteredModelData,
      };
    });

    return {
      ...student,
      Models: filteredModels,
    };
  });

  return (
    <SPContainer>
      <SPContainerRow>
        <SPContainerProfile>
          <MessageSenderInfo
            profileImg={StudentProgressTableData[0].StdProfile}
            senderName={StudentProgressTableData[0].StdName}
            OnlineInfo={true}
            online={StudentProgressTableData[0].StdOnline}
          />
          <SPContainerMessagePlace>
            <SvgChat />
            <SvgEmail height={12} />
          </SPContainerMessagePlace>
        </SPContainerProfile>
        <AqMiniCol>
          <InstructorViewCompletionSearchContainer>
            <SearchElement onChange={(e) => setSearchText(e.target.value)} />
          </InstructorViewCompletionSearchContainer>
          <AqMINIRow>
            <LogoAndAction
              onClick={() =>
                ExcelDownload(StudentProgressTableData, "StudentProgressTable")
              }
            >
              <SvgDownload />
              <Typography text="Download" fontSize="0.75rem" />
            </LogoAndAction>
          </AqMINIRow>
          {/* </InstructorViewCompletionTitles> */}
        </AqMiniCol>
      </SPContainerRow>
      <SPContainerRow></SPContainerRow>
      <SPTable>
        <SPTableHeader>
          {StudentProgressTableHeaderData.map((Header) => (
            <SPTableHeaderCell key={Header.HeaderId}>
              <Typography text={Header.HeaderName} fontSize="1rem" />
            </SPTableHeaderCell>
          ))}
        </SPTableHeader>
        <SPTableBody>
          {filteredData[0].Models?.map((Model, index) => (
            <SPTableBigRow>
              <SPTableCols>
                {/* <div style={{ width: "100%", textAlign: "center" }}> */}
                <Typography text={Model.ModelName || " "} fontSize="1rem" />
                {/* </div> */}
              </SPTableCols>
              <SPTableCols>
                {Model.ModelData &&
                  Model.ModelData.map((data, index) => (
                    <SPTableRow>
                      <SPTableBodyCells>
                        <Typography
                          text={data.ActivitiesResources || " "}
                          fontSize="0.875rem"
                        />
                      </SPTableBodyCells>
                      <SPTableBodyCells>
                        <LightTypography
                          text={data.Status || " "}
                          fontSize="0.75rem"
                        />
                      </SPTableBodyCells>
                      <SPTableBodyCells>
                        <LightTypography
                          text={data.TimeSpent || " "}
                          fontSize="0.75rem"
                        />
                      </SPTableBodyCells>
                      <SPTableBodyCells>
                        <LightTypography
                          text={data.Grade || " "}
                          fontSize="0.75rem"
                          color="#5DD3B3"
                        />
                      </SPTableBodyCells>
                    </SPTableRow>
                  ))}
              </SPTableCols>
            </SPTableBigRow>
          ))}
        </SPTableBody>
      </SPTable>
    </SPContainer>
  );
};

export default StudentProgressTable;
