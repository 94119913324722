import { GradeBookPopupInterface } from "./AttendanceStudentIDInterface";

export const GradeBookPopupData: GradeBookPopupInterface[] = [
  {
    stdid: "234",
    major: "Computer Science",
    year: "Spring 2023 /2024",
    fullNAME: "Nour Mohammad",
    profile: require("../../../assets/NourInstructor.jpg"),
    sessions: [
      {
        sessionId: "324",
        sessionName: "Session 1",
        sessionDate: "Mon, 16 Feb",
        sessionStartTime: "12 PM",
        sessionEndTime: "1 PM",
        sessionText: "Present",
      },
      {
        sessionId: "325",
        sessionName: "Session 2",
        sessionDate: "Tue, 17 Feb",
        sessionStartTime: "1 PM",
        sessionEndTime: "2 PM",
        sessionText: "Absent",
      },
      {
        sessionId: "326",
        sessionName: "Session 3",
        sessionDate: "Wed, 18 Feb",
        sessionStartTime: "2 PM",
        sessionEndTime: "3 PM",
        sessionText: "Present",
      },
      {
        sessionId: "327",
        sessionName: "Session 4",
        sessionDate: "Thu, 19 Feb",
        sessionStartTime: "3 PM",
        sessionEndTime: "4 PM",
        sessionText: "Absent",
      },
      {
        sessionId: "328",
        sessionName: "Session 5",
        sessionDate: "Fri, 20 Feb",
        sessionStartTime: "4 PM",
        sessionEndTime: "5 PM",
        sessionText: "Present",
      },
      {
        sessionId: "329",
        sessionName: "Session 6",
        sessionDate: "Sat, 21 Feb",
        sessionStartTime: "5 PM",
        sessionEndTime: "6 PM",
        sessionText: "Absent",
      },
      {
        sessionId: "330",
        sessionName: "Session 7",
        sessionDate: "Sun, 22 Feb",
        sessionStartTime: "6 PM",
        sessionEndTime: "7 PM",
        sessionText: "Present",
      },
      {
        sessionId: "331",
        sessionName: "Session 8",
        sessionDate: "Mon, 23 Feb",
        sessionStartTime: "7 PM",
        sessionEndTime: "8 PM",
        sessionText: "Absent",
      },
      {
        sessionId: "332",
        sessionName: "Session 9",
        sessionDate: "Tue, 24 Feb",
        sessionStartTime: "8 PM",
        sessionEndTime: "9 PM",
        sessionText: "Present",
      },
    ],
  },

];
