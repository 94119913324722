import styled from "styled-components";
import { StyleThemeInterface } from "../../../../interfaces/StyleThemeInterface";

export const ReplyInputContainer = styled.div<StyleThemeInterface>`
  display: flex;
  width: calc(100% + 2rem);
  flex-wrap: nowrap;
  border: 1px solid ${(props) => props.bordercolor};
  border-radius: 0.625rem;
  justify-content: space-between;
  margin-top: 0.3rem;
`;

export const ReplyInputText = styled.input<StyleThemeInterface>`
  /* display: flex; */
  width: 100%;
  flex-wrap: nowrap;
  padding: 1.22rem 0.5rem;
  background: transparent !important;
  border: none !important;

  color: ${(props) => props.color};
  &:focus {
    outline: none;
  }
`;

export const ReplyInputActions = styled.div`
  display: flex;
  width: fit-content;
  flex-wrap: nowrap;
`;

export const ReplyInputButtons = styled.div<StyleThemeInterface>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem;
  width: 3rem;
  border: 1px solid transparent;
  border-radius: 0.625rem;
  cursor: pointer;
  &:first-child {
    color: #5dd3b3 !important;
  }
  &:last-child {
    color: #d85d66;
  }
  &:hover {
    border: 1px solid ${(props) => props.bordercolor};
  }
`;
