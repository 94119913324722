import React, { FC } from "react";
import { NumberElementProps } from "./numberElementInterface";

const NumberElement: FC<NumberElementProps> = ({
  tag,
  number,
  fontSize,
  fontFamily,
  fontWeight,
  color,
  wordBreak,
}) => {
  const style: React.CSSProperties = {
    fontSize: fontSize || "1.125rem",
    fontFamily: fontFamily || "Primary Font",
    fontWeight: fontWeight || "400",
    color: color || "inherit",
    wordBreak: (wordBreak as React.CSSProperties["wordBreak"]) || "normal",
  };

  return React.createElement(tag, { style }, number);
};

export default NumberElement;
