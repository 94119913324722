import React, {
  useState,
  ChangeEvent,
  useEffect,
  useContext,
  useTransition,
} from "react";
import { useParams } from "react-router-dom";
import { SketchPicker } from "react-color";
import ColorPickerMenuNew from "../../elements/colorPickerMenu/ColorPickerMenu";
import { useStateContext } from "../../contexts/ContextProvider";
import { DropZoneForm } from "../../elements/dropZone/DropZoneForm";
import Select from "react-select";
import LoaderThreeDot from "../loader/loaderThreeDot/LoaderThreeDot";
import { colorStyles, colorStylesSearch } from "./ColorStyle";
import SelectDate from "../../elements/DatePicker/inputDate";

import {
  PreRequisitesDataOptions,
  PreRequisitesOptionProps,
  TargetCompetencyLevelDataOptions,
  TagDataOptions,
  ChannelData,
} from "./CourceArchData";
import Toggle from "../../elements/Toogle/toogle";
import SelectInput from "../../elements/SelectInput/SelectInput";
import {
  SearchKeywordsWrapper,
  CertificateCheckedWrapper,
  IconToolTipSecondaryTagWrapper,
  TooltipSecondaryTag,
  IconToolTipMainHolder,
  Tooltip,
  IconToolTipWrapper,
  TypographyWrapper,
  LabelToggleDiv,
  DateInput,
  CheckboxLabel,
  RadioButton,
  GridContainer,
  GridItem1,
  GridItem2,
  CertificateHolder,
  ButtonContainer,
  CourseSmallColorPicker,
  CourseArchLayout,
  CourseSmall,
  InstructorButtons,
  InstructorCell,
  ButtonContent,
  InstructorColorPicker,
  InstructorIMG,
  InstructorRender,
  Overlay,
  PopupArchLayout,
  PopupContainer,
  PopupContentContainer,
  BackgroundOverlay,
  IconsWrapper,
  CourseLargeScrollable,
  SketchPickerHolder,
  SketchPickerClose,
  SketchPickerSave,
  NewFuturesWrapper,
  RadioDivLabel,
  CheckBox,
  InputHolder,
} from "./CourseArct.style";
import {
  LightTypography,
  Typography,
  MediumTypography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import { CourseArctData } from "./CourceArchData";
import { TextEditor } from "../../elements/TextEditor/TextEditor";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import StyledSelect from "../../elements/StyledSelect/StyledSelect";
import {
  SvgAddTaskIcon,
  SvgCheckModuleTopic,
  SvgClose,
  SvgUnstructuredCourse,
  SvgMoreInformation,
  SvgMoreCheckedCertificate,
  SvgCertificateBadge,
} from "../../elements/Icons";

import { useRecoilValue } from "recoil";
import {
  unstructuredCoursePopup,
  IconsColorSelector,
  ColorSelector,
} from "../../recoil/ThemeSelectors";

import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { CourseCardData } from "../courseCard/CourseCardData";
import useFetchData from "../../hooks/useFetchData";
import { getClassTeachers } from "../../services/UserEnrollments";
import axios from "axios";
import { generateUUID, uuidSplitter } from "../../utils/StringManipulation";
import { S3Put, S3Upload } from "../../services/S3Put";
import { contentTypeGenerater } from "../../utils/ContentTypeGenerator";
import { get, put } from "../../utils/AxiosRequests";
import { classStructure, getAllTeachers } from "../../services/classService";
import InstructorsData from "../courseInfo/instructors/instructorsData";
import ReusableSubmitButton from "../../elements/reusableSubmitButton/ReusableSubmitButton";
import { CertificateDataInterface } from "./CourceArchData";
import { set } from "lodash";
import CertificatePopUp from "../certificatePopUp/CertificatePopUp";
import SelectDropDown from "../../elements/selectDropDown/SelectDropDown";
import { useTranslation } from "react-i18next";
import CancelButton from "../../elements/StyledButton/CancelButton";
import { FlexDiv } from "../../globalStyles";

interface CourseArctCompoProps {
  Description?: string;
  setDescription?: (description: string) => void;
  setSelectedColor?: (color: string) => void;
  setInstructorId?: (id: string) => void;
  setImageName?: (name: string | "") => void;
  setFileName?: (name: string | "") => void;
  setFile?: React.Dispatch<React.SetStateAction<File | "">>;
  setImage?: React.Dispatch<React.SetStateAction<File | "">>;
  imageName?: string | null;
  // colorError?:string;
  // instructorIdError?:string;
  descriptionError?: string;
  selectedColor?: string;
  // fileNameError?:string;
  // imageNameError?:string;
}

interface OptionType {
  value: string;
  label: string;
}
interface Team {
  id: string;
  displayName: string;
  // Add other properties if needed, depending on the structure of the team object
}
type ValueType<T> = T extends Array<infer V> ? Array<V> : T;

export const CourseArctCompoEdit = ({ }: CourseArctCompoProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    isChangeColorShown,
    setIsChangeColorShown,
    colorGroup,
    setColorGroup,
  } = useStateContext();
  const param = useParams();
  const classId = param.classId;
  const tenantName = localStorage.getItem("tenant");

  // Recoil state and selectors
  const { popUpBgColor, overlayBgColor, theme } = useRecoilValue(
    unstructuredCoursePopup
  );
  const { iconCloseColor } = useRecoilValue(IconsColorSelector);
  const {
    profileCardTextColor,
    checkboxBgColor,
    checkboxBorderColor,
    checkboxBorderRadius,
    themeTextColor,
  } = useRecoilValue(ColorSelector);

  const { show, setShow } = useContext(ClassContext);
  //const { classId, setClassId } = useContext(ClassContext);
  //   const { bData } = useContext(ClassContext);
  type FileInterface = {
    path: string;
    name?: string;
  };
  const [newFile, setNewFile] = useState<FileInterface>({ path: "" });
  const [fileUUId, setFileUUId] = useState("");
  const [readyToPost, setReadyToPost] = useState(true);
  const [fetchInstructorDone, setFetchInstructorDone] =
    useState<boolean>(false);

  const [selectGradeChecked, setSelectGradeChecked] = useState(false);
  const [selectAttendanceChecked, setSelectAttendanceChecked] = useState(false);
  const [passingGradeNum, setPassingGradeNum] = useState("");
  const [attendanceAllowedNum, setAttendanceAllowedNum] = useState("");

  const [formInputValue, setFormInputValue] = useState<string>();
  const [prevDataAttachments, setPrevDataAttachments] = useState<any>();
  //   const course = bData.find((course: { id: any }) => course.id === classId);
  const [Description, setDescription] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [imageName, setImageName] = useState<string | null>(null);
  const [selectedColor, setSelectedColor] = useState("#5DC5D3");
  const [instructorId, setInstructorId] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [InstructorError, setInstructorError] = useState("");

  const [loading, setLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [picKey, setPicKey] = useState<string>();
  const [fileUrl, setFileUrl] = useState<string>("");
  const [fileKey, setFileKey] = useState<string>();
  const filePath = "class/structure/syllabus";
  const imagePath = "class/structure/image";
  const [fileResponse, setFileResponse] = useState<any>(null);
  const [ImageResponse, setImageResponse] = useState<any>(null);
  const [classData, setClassData] = useState<any[]>([]);
  const [Instructorsdata, setInstructorsData] = useState<any[]>([]);
  const [courseTitle, setCourseTitle] = useState<string>("");
  const [courseCode, setCourseCode] = useState<string>("");
  const [additionalData, setAdditionalData] = useState<any>(null);
  const [prevFileName, setPrevFileName] = useState<string>("");
  const [prevPictureName, setPrevPictureName] = useState<string>("");
  const imageGif = require("../../assets/loadingGif.gif");
  const [teams, SetTeams] = useState([]);
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [dateNum, setDateNum] = useState("");
  const [radioValue, setRadioValue] = useState<string | null>(null);
  const [teamsId, SetTeamsId] = useState("");
  const [accessToken, setAccessToken] = useState(null);
  const [preRequisites, setPreRequisites] = useState<
    PreRequisitesOptionProps[]
  >([]);
  const [connectToMSTeamsChecked, setConnectToMSTeamsChecked] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState("");

  const [certificateChecked, setCertificateChecked] = useState(false);
  const [primaryTags, setPrimaryTags] = useState<PreRequisitesOptionProps[]>(
    []
  );
  const [secondaryTags, setSecondaryTags] = useState<
    PreRequisitesOptionProps[]
  >([]);
  const [searchedKeyword, setSearchedKeyword] = useState<
    PreRequisitesOptionProps[]
  >([]);

  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [certificateId, setCertificateId] = useState<string | number>();

  const [certificateTemplate, setCertificateTemplate] = useState<string>("");
  const [isPrerequisiteClicked, setIsPrerequisiteClicked] =
    useState<boolean>(false);
  const [previewErrorMessage, setPreviewErrorMessage] =
    useState<boolean>(false);

  const customFilter = (option: OptionType, rawInput: string): boolean => {
    const inputValue = rawInput.trim().toLowerCase();
    const optionValue = option.value.toLowerCase();
    return optionValue.includes(inputValue);
  };
  const MsToken = localStorage.getItem("MSToken");
  const handleInputSearchChange = (newValue: string) => {
    setInputValue(newValue);
  };

  // Function to handle changes in the "Select Grade " checkbox
  const handleSelectGradeCheckChange = () => {
    setSelectGradeChecked(!selectGradeChecked);
  };

  // Function to handle changes in the "Select Attendance " checkbox
  const handleSelectAttendanceCheckChange = () => {
    setSelectAttendanceChecked(!selectAttendanceChecked);
  };

  // Function to handle changes in the "File Submission" checkbox
  const handleCertificateCheckChange = () => {
    setCertificateChecked(!certificateChecked);
  };

  // Function to handle changes in the "connectToMSTeams " checkbox
  const handleConnectToMSTeams = () => {
    setConnectToMSTeamsChecked(!connectToMSTeamsChecked);
  };

  const handleSelectChannel = (value: string) => {
    setSelectedChannel(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement> | any) => {
    const enterKeyPressed = event.key === "Enter";
    const nextOrGoKeyPressed = event.key === "Next" || event.key === "Go";

    if (
      (enterKeyPressed && inputValue.trim() !== "") ||
      (nextOrGoKeyPressed && inputValue.trim() !== "")
    ) {
      // Create a new option based on the input value
      const newOption: OptionType = { value: inputValue, label: inputValue };
      setSelectedOptions([...selectedOptions, newOption]);
      setInputValue(""); // Clear input after adding
    }
  };

  const [competencyLevel, setCompetencyLevel] = useState<string | undefined>();
  const [errorDateNum, setErrorDateNum] = useState(false);
  const isPositiveNumber = (value: string) => {
    return /^\d+(\.\d+)?$/.test(value) && parseFloat(value) >= 0;
  };

  const handleInputChange = (
    value: string,
    setStateFunction: (value: string) => void
  ) => {
    if (isPositiveNumber(value) || value === "") {
      setStateFunction(value);
      setErrorDateNum(false);
    } else {
      setErrorDateNum(true);
    }
  };

  const [certificatePopup, setCertificatePopup] = useState(false);

  const [visibleForStudents, setVisibleForStudents] = useState(true);
  const [progressTracking, setProgressTracking] = useState(true);
  const [forcedModules, setForcedModules] = useState(true);
  const [releaseConditions, setReleaseConditions] = useState(true);

  const handleVisibleForStudents = (isChecked: boolean) => {
    setVisibleForStudents(isChecked);
  };
  const handleProgressTracking = (isChecked: boolean) => {
    setProgressTracking(isChecked);
  };
  const handleForcedModules = (isChecked: boolean) => {
    setForcedModules(isChecked);
  };
  const handleReleaseConditions = (isChecked: boolean) => {
    setReleaseConditions(isChecked);
  };

  const fetchClassData = async () => {
    try {
      const classData = await axios.get(`https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/class/about/${classId}/${tenantName}`);
      setPrevPictureName(
        uuidSplitter(
          classData.data.data[0].parentClass.picture?.split("image/")[1]
        )
      );

      // Fetch instructors only after receiving class data
      if (classData.data.data.length > 0) {
        setClassData(classData.data.data);
        // const InstructorsData = await getAllTeachers(classData.data[0].classStructure.classStructureId);
        setInstructorsData(classData.data.data[0].teacher);
        setCourseTitle(classData.data.data[0].parentClass.courseTitle);
        setCourseCode(classData.data.data[0].parentClass.courseCode);
        setSelectedColor(classData.data.data[0].parentClass.color);
        setColorGroup(classData.data.data[0].parentClass.color);
        setDescription(classData.data.data[0].parentClass.description);
        setInstructorId(classData.data.data[0].parentClass.mainInstructorId);
        SetTeamsId(classData.data.data[0].parentClass.teamsId);
        setActiveInstructorId(
          classData.data.data[0].parentClass.mainInstructorId
        );
        setBorderColor(classData.data.data[0].parentClass.color);
        setImageResponse(classData.data.data[0].parentClass.picture);
        setFileResponse(classData.data.data[0].parentClass.syllabus);
        const fileNameResolved = uuidSplitter(
          classData.data.data[0].parentClass.syllabus?.split("syllabus/")[1]
        );
        const pictureNameResolved = uuidSplitter(
          classData.data.data[0].parentClass.picture?.split("image/")[1]
        );
        // setPrevPictureName(pictureNameResolved);
        setPrevFileName(fileNameResolved);
        if (classData.data.data[0].parentClass.teamsId !== "") {
          setConnectToMSTeamsChecked(true);
        }

        // setImage(classData.data[0].classStructure.picture)
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchClassData();
  }, []);

  useEffect(() => {
    const getInstroctursProfile = async () => {
      try {
        setProfileLoading(true);
        if (Instructorsdata.length > 0) {
          const instructorIds = Instructorsdata.map(
            (instructor: { sourceId: any }) => instructor.sourceId
          );

          const additionalDataArray = [];

          for (const instructorId of instructorIds) {
            // Introduce a delay before making each API call
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust the delay time as needed

            const response = await get(
              `user/profilePicture/${tenantName}/${instructorId}`
            );
            setFetchInstructorDone(true);
            additionalDataArray.push(response.data);
          }

          // Store the additional data in state
          setAdditionalData(additionalDataArray);
          setProfileLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getInstroctursProfile();
  }, [Instructorsdata, tenantName]);

  const handleSubmit = async () => {
    let hasError = false;
    if (instructorId.trim() === "") {
      setInstructorError("Instructor is required");
      hasError = true;
      return;
    }
    if (!Description) {
      setDescriptionError("Description is required");
      hasError = true;
    } else {
      setDescriptionError("");
      setInstructorError("");
    }

    if (!hasError) {
      const formDataObject = {
        classSourceId: classId,
        description: Description,
        color: colorGroup,
        mainInstructorId: instructorId,
        picture: ImageResponse || null,
        syllabus: fileResponse || null,
        headline: "",
        teamId: selectedChannel || null,
      };
      await put(`class/structure/update/${tenantName}`, formDataObject);
      window.location.reload();
      setShow(false);
    } else {
      return;
    }
  };

  //   console.log("Class id from edit popup :" , bData)

  const [activeInstructorId, setActiveInstructorId] = useState("");
  const [borderColor, setBorderColor] = useState("");

  const handleDescriptionChange = (value: any) => {
    setDescription && setDescription(value);
  };
  const handleInstructorCellClick = (instructorId: string) => {
    setInstructorId && setInstructorId(instructorId);
    setActiveInstructorId(instructorId);
    // console.log("Selected Instructor ID:", instructorId);
  };

  const handleSelectFile = async (files: FileList | null) => {
    if (files) {
      const selectedFile: File | null = files[0];
      if (selectedFile) {
        const name: string =
          selectedFile.name.split("\\").pop() || selectedFile.name;

        setFile(selectedFile);
        setFileName(name);

        const fileNameUUid = `${generateUUID()}${name}`;
        const fileuplKey = `${filePath}/${fileNameUUid}`;
        setFileKey(fileNameUUid);
        // setFileKey(fileuplKey);
        try {
          // const response = await post(`s3/generateUploadUrl`, {
          //   tenantName: "TrialFix",
          //   contentType: contentTypeGenerater(fileName || ""),
          //   fileName: fileuplKey,
          //   filePath: filePath,
          // });
          //setFileUrl(response.data);
          setLoading(true);
          const response = await S3Upload(
            filePath,
            name,
            fileNameUUid,
            selectedFile
          );
          if (response) {
            setLoading(false);
          }
          if (response?.status === 200) {
            setFileResponse(fileuplKey);
          }
        } catch (error) {
          console.log(error);
        }
        // console.log("Selected File is:", selectedFile);
      }
    }
  };

  const handleSelectImage = async (files: any) => {
    if (files) {
      const selectedImage = files;

      if (selectedImage !== null) {
        const name = selectedImage.path;
        setImage(selectedImage);
        setImageName(name);

        const picUuid = `${generateUUID()}${name}`;
        const pictureKey = `${imagePath}/${picUuid}`;
        setPicKey(picUuid);
        try {
          // const response = await post(`s3/generateUploadUrl`, {
          //   tenantName: "TrialFix",
          //   contentType: contentTypeGenerater(name || ""),
          //   fileName: pictureKey,
          //   filePath: filePath,
          // });
          // setImageUrl(response.data);
          // console.log("in hadnleselectImage", response.data);
          // console.log("fileKey", picKey);
          // console.log("Selected Image is:", selectedImage);
          setLoading(true);
          const response = await S3Upload(
            imagePath,
            name,
            picUuid,
            selectedImage
          );
          if (response) {
            setLoading(false);
          }
          if (response?.status === 200)
          setImageResponse(pictureKey);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const handleColorChange = (color: string) => {
    setSelectedColor && setSelectedColor(color);
    setBorderColor(color);
    setColorGroup(color);
    // console.log("borderColor will be:", color);
  };

  // Function to handle dueDate
  const handleDueDate = (dueDateTime: Date | null) => {
    setDueDate(dueDateTime);
  };

  const accF: { [key: string]: string[] } = {
    "image/jpeg": [".jpg", ".jpeg"],
    "image/png": [".png"],
    "image/webp": [".webp"],
  };

  const getAccessToken = async () => {
    const tenantNames = tenantName; // Assuming tenantName is defined somewhere in your code
  
    try {
      const response = await axios.get(
        `https://k9jjuskf3i.execute-api.eu-west-1.amazonaws.com/dev/ms/token`
        // {
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'tenantName': tenantNames,
        //   }
        // }
      );
  
      const accessToken = response.data.data;
      setAccessToken(accessToken);
      localStorage.setItem("MSToken", accessToken); // Store the token directly if it's a string
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAccessToken();
  }, []);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get(
          "https://graph.microsoft.com/v1.0/teams",
          {
            headers: {
              Authorization: `${MsToken}`,
            },
          }
        );
        // Assuming response.data.value contains the array of team objects
        const transformedTeams = response.data.value.map((team: Team) => ({
          name: team.displayName,
          value: team.id,
        }));
        SetTeams(transformedTeams);
      } catch (err) {
        console.log(err);
      }
    };

    fetchTeams();
  }, [MsToken]);

  return (
    <>
      <Overlay isvisible={true.toString()}>
        <BackgroundOverlay bgOverlayColor={overlayBgColor}>
          <PopupContainer>
            <PopupContentContainer bgColor={popUpBgColor}>
              <FlexDiv>
              <SvgUnstructuredCourse color={themeTextColor} />
                
              <IconsWrapper iconCloseColor={iconCloseColor}>
                <SvgClose
                  onClick={() => {
                    setShow(false);
                  }}
                />
              </IconsWrapper>

              {courseTitle && courseCode ? (
                <Typography
                  text={`${courseTitle} - ${courseCode}`}
                  fontSize="1.125rem"
                />
              ) : (
                <Typography
                  text={`${t("general.loading")}...`}
                  fontSize="1rem"
                />
              )}
              </FlexDiv>
              <CourseLargeScrollable theme={theme}>
                <CourseSmall style={{ position: "relative" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <LightTypography text={`${t("course view.Class Color")}*`} fontSize="0.875rem" />
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        marginLeft: ".5rem",
                        borderRadius: "50%",
                        backgroundColor: `${colorGroup}`,
                      }}
                    ></div>
                  </div>

                  <ColorPickerMenuNew
                    top="1.8725rem"
                    onColorChange={handleColorChange}
                    addNewGroup={() => { }}
                  />
                  {isChangeColorShown && (
                    <SketchPickerHolder>
                      <SketchPickerClose>
                        <SvgClose
                          style={{ width: "12", height: "12", color: "5DD3B3" }}
                          onClick={() => setIsChangeColorShown(false)}
                        />
                      </SketchPickerClose>
                      <SketchPicker
                        color={selectedColor}
                        disableAlpha
                        onChange={(color) => setSelectedColor(color.hex)}
                      />
                      <SketchPickerSave>
                        <SvgCheckModuleTopic
                          onClick={() => {
                            setIsChangeColorShown(false);
                            setColorGroup(selectedColor);
                          }}
                        />
                      </SketchPickerSave>
                    </SketchPickerHolder>
                  )}
                </CourseSmall>
                {Instructorsdata && Instructorsdata.length > 0 && (
                  <CourseSmallColorPicker>
                    <div style={{ marginTop: "4.25rem" }}>
                      <LightTypography
                        text={`${t("course view.Main Instructor")}*`}
                        fontSize="1rem"
                      />
                    </div>

                    <InstructorRender>
                      {!fetchInstructorDone ? (
                        <LoaderThreeDot />
                      ) : (
                        Instructorsdata.map((val, index) => (
                          <InstructorCell
                            colorTextProfile={profileCardTextColor}
                            key={val.sourceId}
                            onClick={() =>
                              handleInstructorCellClick(val.sourceId)
                            }
                            active={activeInstructorId === val.sourceId}
                            borderColor={colorGroup}
                          >
                            {additionalData ? (
                              <>
                                {/* {console.log(
                                  "additionalData[index]",
                                  additionalData[index]
                                )} */}
                                <InstructorIMG>
                                  <img
                                    src={
                                      !profileLoading
                                        ? additionalData[index]
                                        : imageGif
                                    }
                                    width={"1.875rem"}
                                    height={"1.875rem"}
                                    alt="profile"
                                    onError={(e) => {
                                      const target =
                                        e.target as HTMLImageElement;
                                      target.src =
                                        "./assets/images/userProfile.svg";
                                      target.onerror = null;
                                    }}
                                  />
                                </InstructorIMG>
                              </>
                            ) : (
                              <InstructorIMG>
                                <img src={imageGif} alt="" />
                              </InstructorIMG>
                            )}
                            <TypographyWrapper>
                              <TypographyRegularDescription
                                fontSize="0.75rem"
                                text={`${val.username} `}
                                wordWrap="wrap"
                                color={profileCardTextColor}
                              />
                            </TypographyWrapper>
                          </InstructorCell>
                        ))
                      )}
                      {InstructorError && (
                        <h5 style={{ color: "red" }}>{InstructorError}</h5>
                      )}
                    </InstructorRender>
                  </CourseSmallColorPicker>
                )}

                <CourseSmall style={{ marginTop: "1.31rem" }}>
                  <div>
                    <LightTypography
                      text={`${t("forms.Description")}*`}
                      fontSize="0.875rem"
                    />
                  </div>

                  <div style={{ marginTop: "-0.19rem" }}>
                    <TinyEditor
                      onChange={handleDescriptionChange}
                      placeholder={`${t("forms.Description")}*`}
                      initialValue={Description}
                      height={"13.25rem"}
                    />
                    {descriptionError && (
                      <h5 style={{ color: "red" }}>{descriptionError}</h5>
                    )}
                  </div>
                  <div
                    style={{ marginTop: "-0.18rem", marginBottom: "1.25rem" }}
                  >
                    <DropZoneForm
                      text={`${t(
                        "course view.Click or Drag and Drop, to upload Image"
                      )}*`}
                      testDrop={handleSelectImage}
                      acceptedTypes={accF}
                      initialFile={prevPictureName}
                    />
                  </div>
                </CourseSmall>

                <InstructorButtons>
                  <LightTypography text={`${t("course view.Class Syllabus")}*`} fontSize="1rem" />
                  <StyledSelect
                    onChange={handleSelectFile}
                    title={`${prevFileName ? prevFileName : `${t("course view.Upload Document")}*`}`}
                    icon={SvgAddTaskIcon}
                    acceptedFileTypes=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .odt, .ods, .odp, .txt, .rtf, .csv"
                  />
                </InstructorButtons>
                {/* <CourseSmall style={{ marginTop: "1.25rem" }}>
                  <RadioDivLabel htmlFor="connectToMSTeams">
                    <CheckBox
                      bgColor={checkboxBgColor}
                      borderColor={checkboxBorderColor}
                      borderRadius={checkboxBorderRadius}
                      type="checkbox"
                      id="connectToMSTeams"
                      checked={connectToMSTeamsChecked}
                      onChange={handleConnectToMSTeams}
                    />
                    <LightTypography
                      text="Connect To MS Teams"
                      fontSize="0.875rem"
                    />
                  </RadioDivLabel>
                  {connectToMSTeamsChecked && (
                    <div
                      style={{
                        marginBottom: connectToMSTeamsChecked ? "5rem" : "1rem",
                      }}
                    >
                      <SelectDropDown
                        onChange={handleSelectChannel}
                        placeholder="Select The Team"
                        options={teams}
                        value={teamsId}
                      />
                    </div>
                  )}
                </CourseSmall> */}
                {/* <NewFuturesWrapper>
                  <RadioDivLabel htmlFor="selectGrade">
                    <CheckBox
                      type="checkbox"
                      id="selectGrade"
                      checked={selectGradeChecked}
                      onChange={handleSelectGradeCheckChange}
                    />
                    <LightTypography text="Select Grade " fontSize="0.875rem" />
                  </RadioDivLabel>
                  <LightTypography text="Passing Grade" fontSize="0.875rem" />
                  <DateInput
                    width="100%"
                    height="2.813rem"
                    textAlign="start"
                    paddingLeft="1.25rem"
                    type="number"
                    value={passingGradeNum}
                    onChange={(e: any) =>
                      handleInputChange(e.target.value, setPassingGradeNum)
                    }
                  />
                  <RadioDivLabel htmlFor="selectAttendance">
                    <CheckBox
                      type="checkbox"
                      id="selectAttendance"
                      checked={selectAttendanceChecked}
                      onChange={handleSelectAttendanceCheckChange}
                    />
                    <LightTypography
                      text="Select Attendance "
                      fontSize="0.875rem"
                    />
                  </RadioDivLabel>
                  <LightTypography
                    text="Max Attendance Allowed"
                    fontSize="0.875rem"
                  />
                  <DateInput
                    width="100%"
                    height="2.813rem"
                    textAlign="start"
                    paddingLeft="1.25rem"
                    type="number"
                    value={attendanceAllowedNum}
                    onChange={(e: any) =>
                      handleInputChange(e.target.value, setAttendanceAllowedNum)
                    }
                  />

                  <LightTypography
                    text="Under these conditions Launch  the following"
                    fontSize="1rem"
                  />
                  <RadioDivLabel htmlFor="checkCertificate">
                    
                    <LightTypography text="Certificate" fontSize="1rem" />
                    <InputHolder
                      onClick={() => {
                        if (certificateTemplate) setCertificatePopup(true);
                        else {
                          setPreviewErrorMessage(true);
                          setTimeout(() => {
                            setPreviewErrorMessage(false);
                          }, 3000);
                        }
                      }}
                    >
                      <TypographyRegularDescription
                        text="Quick Preview"
                        fontSize="0.75rem"
                        color="#5DD3B3"
                        whiteSpace="nowrap"
                      />
                    </InputHolder>
                    {previewErrorMessage && (
                      <LightTypography
                        text="Please Select Your Template Before Preview"
                        fontSize="0.875rem"
                        color="#D85D66"
                      />
                    )}
                  </RadioDivLabel>
                  {CertificateDataInterface &&
                    CertificateDataInterface.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "1.25rem",
                          width: "100%",
                          minHeight: "fit-content",
                        }}
                      >
                        {CertificateDataInterface.map((certificate, index) => {
                          const isActive =
                            certificate.certificateId == certificateId;
                          return (
                            <div
                              onClick={() => {
                                setCertificateId(certificate.certificateId);
                                setCertificateTemplate(
                                  certificate.certificateTemplate
                                );
                              }}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                rowGap: "0.62rem",
                                position: "relative",
                              }}
                            >
                              <CertificateHolder
                                src={certificate.certificateImage}
                              ></CertificateHolder>
                              <CertificateCheckedWrapper ischecked={isActive}>
                                <SvgMoreCheckedCertificate />
                              </CertificateCheckedWrapper>
                              <LightTypography
                                text={certificate.title}
                                fontSize="0.625rem"
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "0.62rem",
                    }}
                  >
                    <MediumTypography text="Advanced Settings" />
                    <LightTypography
                      text="Pre-requisites to complete this Course"
                      fontSize="0.875rem"
                    />

                    <Select
                      onKeyDown={() => setIsPrerequisiteClicked(true)}
                      onFocus={() => setIsPrerequisiteClicked(true)}
                      onBlur={() => setIsPrerequisiteClicked(false)}
                      options={PreRequisitesDataOptions}
                      onChange={(selectedOptions) => {
                        const selectedPreRequisites: PreRequisitesOptionProps[] =
                          selectedOptions.map((option: any) => ({
                            value: option.value,
                            label: option.label,
                          }));
                        setPreRequisites(selectedPreRequisites);
                      }}
                      isMulti
                      styles={colorStyles}
                      placeholder="Select"
                    />
                  </div>
                  <MediumTypography text="Tags and search keywords" />

                  <GridContainer>
                    <GridItem1
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "0.62rem",
                      }}
                    >
                      <LightTypography
                        text="Target competency level"
                        fontSize="0.875rem"
                      />

                      <SelectInput
                        zIndex={isPrerequisiteClicked ? -5 : 1}
                        paddingOption="0.625rem 0 0.625rem 1.25rem"
                        color="#5DD3B3"
                        height="2.813rem"
                        width="100%"
                        optionWidth="109%"
                        fontSize="0.75rem"
                        fontFamily="Primary Font-Light"
                        options={TargetCompetencyLevelDataOptions}
                        onChange={(selectedOption) => {
                          console.log("selectedOption: ", selectedOption);
                          {
                            setCompetencyLevel(selectedOption);
                            setFormInputValue(selectedOption);
                          }
                        }}
                        value={formInputValue}
                        placeholder="Select"
                      />
                    </GridItem1>
                    <GridItem2
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "0.62rem",
                      }}
                    >
                      <IconToolTipMainHolder>
                        <LightTypography
                          text="Primary Tag"
                          fontSize="0.875rem"
                          whiteSpace="nowrap"
                        />
                        <IconToolTipWrapper>
                          <SvgMoreInformation />
                          <Tooltip>
                            <MediumTypography
                              text="These tags help in filtering Modules on search"
                              fontSize="0.625rem"
                              whiteSpace="pre-line"
                            />
                          </Tooltip>
                        </IconToolTipWrapper>
                      </IconToolTipMainHolder>
                      <Select
                        options={TagDataOptions}
                        onChange={(selectedOptions) => {
                          const selectedPrimaryTags: PreRequisitesOptionProps[] =
                            selectedOptions.map((option: any) => ({
                              value: option.value,
                              label: option.label,
                            }));
                          setPrimaryTags(selectedPrimaryTags);
                        }}
                        isMulti
                        styles={colorStyles}
                        placeholder="Select"
                      />
                    </GridItem2>
                  </GridContainer>
                  <GridContainer>
                    <GridItem1
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "0.62rem",
                      }}
                    >
                      <IconToolTipMainHolder>
                        <LightTypography
                          text="Secondary Tag"
                          fontSize="0.875rem"
                          whiteSpace="nowrap"
                        />
                        <IconToolTipSecondaryTagWrapper>
                          <SvgMoreInformation />
                          <TooltipSecondaryTag>
                            <MediumTypography
                              text="These tags can help to create a collection of Modules or help group Modules together"
                              fontSize="0.625rem"
                              whiteSpace="pre-line"
                            />
                          </TooltipSecondaryTag>
                        </IconToolTipSecondaryTagWrapper>
                      </IconToolTipMainHolder>

                      <Select
                        options={TagDataOptions}
                        onChange={(selectedOptions) => {
                          const selectedPrimaryTags: PreRequisitesOptionProps[] =
                            selectedOptions.map((option: any) => ({
                              value: option.value,
                              label: option.label,
                            }));
                          setSecondaryTags(selectedPrimaryTags);
                        }}
                        isMulti
                        styles={colorStyles}
                        placeholder="Select"
                      />
                    </GridItem1>
                    <GridItem2
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "0.62rem",
                      }}
                    >
                      <IconToolTipMainHolder>
                        <LightTypography
                          text="Search Keywords"
                          fontSize="0.875rem"
                          whiteSpace="nowrap"
                        />
                        <IconToolTipWrapper>
                          <SvgMoreInformation />
                          <Tooltip>
                            <MediumTypography
                              text="keywords help in boosting the search for a Module"
                              fontSize="0.625rem"
                              whiteSpace="pre-line"
                            />
                          </Tooltip>
                        </IconToolTipWrapper>
                      </IconToolTipMainHolder>

                      <Select
                        options={selectedOptions}
                        value={selectedOptions}
                        onChange={(selectedOptions) =>
                          setSelectedOptions(selectedOptions as OptionType[])
                        }
                        isMulti
                        filterOption={customFilter}
                        onInputChange={handleInputSearchChange}
                        onKeyDown={handleKeyDown}
                        inputValue={inputValue}
                        placeholder="Enter Search Keywords"
                        styles={colorStylesSearch}
                      />
                    </GridItem2>
                  </GridContainer>
                  <MediumTypography text="Due date settings" />
                  <div
                    style={{
                      display: "flex",
                      gap: "0.69rem",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <CheckboxLabel htmlFor="due_date">
                      <RadioButton
                        type="radio"
                        name="due_date_group"
                        id="due_date"
                        value={"radio-1"}
                        checked={radioValue === "radio-1"}
                        onChange={(e) => setRadioValue(e.target.value)}
                      />
                      <LightTypography
                        text="Fixed due date"
                        fontSize="0.875rem"
                      />
                    </CheckboxLabel>
                    <SelectDate
                      width="22.54rem"
                      value={dueDate}
                      onChange={handleDueDate}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.69rem",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <CheckboxLabel htmlFor="timeToComplete">
                      <RadioButton
                        type="radio"
                        name="timeToComplete_group"
                        id="timeToComplete"
                        value={"radio-2"}
                        checked={radioValue === "radio-2"}
                        onChange={(e) => setRadioValue(e.target.value)}
                      />
                      <LightTypography
                        text="Learner need to complete the course within"
                        fontSize="0.875rem"
                      />
                    </CheckboxLabel>
                    <DateInput
                      type="number"
                      value={dateNum}
                      onChange={(e: any) =>
                        handleInputChange(e.target.value, setDateNum)
                      }
                    />
                    <LightTypography
                      text="days post enrollment"
                      fontSize="0.875rem"
                    />
                  </div>
                  <LabelToggleDiv>
                    <Toggle
                      onToggle={handleVisibleForStudents}
                      checked={visibleForStudents}
                      id="roleToggle1"
                    />
                    <TypographyRegularDescription text="Make visible for students" />
                  </LabelToggleDiv>
                  <LabelToggleDiv>
                    <Toggle
                      onToggle={handleProgressTracking}
                      checked={progressTracking}
                      id="roleToggle2"
                    />
                    <TypographyRegularDescription text="Progress Tracking" />
                  </LabelToggleDiv>
                  <LabelToggleDiv>
                    <Toggle
                      onToggle={handleForcedModules}
                      checked={forcedModules}
                      id="roleToggle3"
                    />
                    <TypographyRegularDescription text="Forced Modules Sequence" />
                  </LabelToggleDiv>
                </NewFuturesWrapper> */}

                <ButtonContainer>
                  {loading ? (
                    <CancelButton
                      name={`${t("general.loading")}...`}
                      width="7.625rem"
                      disabled={true}
                      onClickFunction={()=>{}}
                      backgroundColor="#5DD3B3"
                      color="#fff"
                    />
                  ) : (
                    <ReusableSubmitButton
                      text={t("forms.Save")}
                      width="7.625rem"
                      height="2.125rem"
                      padding="0.56rem 2rem 0.5rem 2rem"
                      onClick={handleSubmit}
                      disabled={loading}
                    />
                  )}
                </ButtonContainer>
              </CourseLargeScrollable>
            </PopupContentContainer>
          </PopupContainer>
        </BackgroundOverlay>
      </Overlay>
      {certificatePopup && (
        <CertificatePopUp
          show={certificatePopup}
          templateType={certificateTemplate}
          setShow={setCertificatePopup}
          title="Certificate"
          icon={SvgCertificateBadge}
          handleSubmit={() => { }}
          courseName={courseTitle}
        />
      )}
    </>
  );
};
