import React, { useState, useEffect } from 'react'
import { CommentContainer, StudentShortAnswerContainerParent } from './StudentQuizShortAnswer.style'
import { TextEditor } from '../../TextEditor/TextEditor';
import { LightTypography, MediumTypography } from '../../fonts/Fonts';
import { findAnswerFeedbackV1, findStudentComment } from '../quizElementsUtils';
import TinyEditor from '../../tinyEditor/TinyEditor';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { auth0SubIdSplitter,auth0ProviderSplitter } from '../../../utils/StringManipulation';
import { useParams } from 'react-router-dom';

type shortAnswer = { [key: string]: string };

interface StudentQuizSHortAnswerProps {
  shortAnswerData?: any[];
  handleShortAnswerResponseChange?: (newDescription: string) => void;
  questionIndex?: number;
  partIndex?: number;
  setshortAnswer?: React.Dispatch<React.SetStateAction<shortAnswer>>;
  allData?: any[];
  studentResponse?: any;
  shortAnswerInitialState?: { [key: string]: string; }
  setQuestionId?: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  setQuestionType?: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  started?: boolean;
  setIsCorrect?: React.Dispatch<React.SetStateAction<{ [key: string]: boolean | null | string }>>;
  showGrade?: boolean;
  showPublishGrade?: boolean;
  setQuizTemplateQuestionsId?: React.Dispatch<
    React.SetStateAction<{ [key: string]: number }>
  >;
  quizId:any;
  previewMode:boolean;
}

function StudentQuizShortAnswer({
  studentResponse,
  shortAnswerData,
  allData,
  questionIndex,
  partIndex,
  setshortAnswer,
  shortAnswerInitialState,
  setQuestionId,
  setQuestionType,
  started,
  setIsCorrect,
  showGrade,
  showPublishGrade,
  setQuizTemplateQuestionsId,
  quizId,
  previewMode
}: StudentQuizSHortAnswerProps) {
  const { t } = useTranslation();
  const [shortAnswerResponse, setShortAnswerResponse] = useState('');
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const params = useParams();
  const classId = params.classId;

  // Find Student's answer and return it
  const findStudentAnswer = (questionId: any, questionType: string) => {
    if (studentResponse && studentResponse.length > 0) {
      const studentAnswer = studentResponse.find((answer: any) => Number(answer.questionId) === Number(questionId) && answer.type === questionType);
      if (studentAnswer && studentAnswer.answers && studentAnswer.answers.length > 0 && studentAnswer.answers[0].answer) {
        setShortAnswerResponse((studentAnswer.answers[0].answer.answer).toString())
      } else {
        setShortAnswerResponse('');
      }
      if (questionIndex !== undefined && studentAnswer && studentAnswer.answers && studentAnswer.answers.length && allData) {
        const correctKeyNormal = `${allData[questionIndex].questionId}_${allData[questionIndex].type}_isCorrect`
        if (partIndex !== undefined && allData && allData[questionIndex].questionParts && allData[questionIndex].questionParts[partIndex]) {
          const correctKeyConposed = `${allData[questionIndex].questionId}_${allData[questionIndex].questionParts[partIndex].id}_${allData[questionIndex].questionParts[partIndex].type}_isCorrect`
          setIsCorrect && setIsCorrect((prevDescriptions) => ({
            ...prevDescriptions,
            [correctKeyConposed]: studentAnswer.answers[0].isCorrect,
          }));
        } else {
          setIsCorrect && setIsCorrect((prevDescriptions) => ({
            ...prevDescriptions,
            [correctKeyNormal]: studentAnswer.answers[0].isCorrect,
          }));
        }
      } else {
        setIsCorrect && setIsCorrect({})
      }

    } else {
      setShortAnswerResponse('');
      setIsCorrect && setIsCorrect({})
    }
  };

  // Call the findStudentAnswer to show the answer to the student
  useEffect(() => {
    if (questionIndex !== undefined) {
      if (partIndex !== undefined && allData && allData[questionIndex].questionParts && allData[questionIndex].questionParts[partIndex]) {
        findStudentAnswer(allData[questionIndex].questionParts[partIndex].id, allData[questionIndex].questionParts[partIndex].type)
      } else {
        findStudentAnswer(allData && allData[questionIndex].questionId, allData && allData[questionIndex].type)
      }

      if(studentResponse && studentResponse.length === 0 && !previewMode){
      // Fetch and set the timer from local storage if it exists
      const savedStudentAnswer = JSON.parse(localStorage.getItem('localStudentResponse') || '[]');
      const currentQuizAnswer = savedStudentAnswer.find((item: any) =>
        item.classSourceId === classId &&
        item.subId === subIdSplitted &&
        item.refId === quizId
      );

      if (currentQuizAnswer &&
        allData &&
        allData[questionIndex]) {
        let key = "";
      if (
        (allData[questionIndex].composed ||
          allData[questionIndex].type === "COMPOSITE") &&
        partIndex !== undefined &&
        allData[questionIndex].questionParts
      ) {
        key = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}`;
      } else {
        key = `${allData[questionIndex].title}`;
      }

      if(currentQuizAnswer && currentQuizAnswer.localStudentResponse && currentQuizAnswer.localStudentResponse[key]){
        setShortAnswerResponse((currentQuizAnswer.localStudentResponse[key]).toString())
        setshortAnswer &&
        setshortAnswer((prevDescriptions) => ({
          ...prevDescriptions,
          [key]: (currentQuizAnswer.localStudentResponse[key]).toString(),
        }));
        }else{
          setShortAnswerResponse('')
        }
        
      } else {
        setShortAnswerResponse('')
      }
    }

    }

  }, [questionIndex, partIndex, allData, studentResponse]);

  // Update the type of the question and the id of the question depending on the dependencies
  useEffect(() => {
    if (questionIndex !== undefined) {
      if (partIndex !== undefined && allData && allData[questionIndex].questionParts && allData[questionIndex].questionParts[partIndex]) {
        // For composed questions
        const singleKey = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}_ID`;
        const typekey = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}Type`;
        const templateKey = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}_TEMPLATEKEY`;
        setQuestionType && setQuestionType((prevDescriptions) => ({
          ...prevDescriptions,
          [typekey]: allData[questionIndex].questionParts[partIndex].type,
        }));
        setQuestionId && setQuestionId((prevId) => ({
          ...prevId,
          [singleKey]: allData[questionIndex].questionParts[partIndex].id,
        }));
        setQuizTemplateQuestionsId &&
          setQuizTemplateQuestionsId((prevId) => ({
            ...prevId,
            [templateKey]: allData && allData[questionIndex].quizTemplateQuestionId,
          }));
      } else {
        // For non-composed questions
        const singleKey = `${allData && allData[questionIndex].title}_ID`;
        const typekey = `${allData && allData[questionIndex].title}Type`;
        const templateKey = `${allData && allData[questionIndex].title}_TEMPLATEKEY`;
        setQuestionId && setQuestionId((prevId) => ({
          ...prevId,
          [singleKey]: allData && allData[questionIndex].questionId,
        }));
        setQuestionType && setQuestionType((prevDescriptions) => ({
          ...prevDescriptions,
          [typekey]: allData && allData[questionIndex].type,
        }));
        setQuizTemplateQuestionsId &&
          setQuizTemplateQuestionsId((prevId) => ({
            ...prevId,
            [templateKey]: allData && allData[questionIndex].quizTemplateQuestionId,
          }));
      }
    }
  }, [questionIndex, allData])

  // Set the answer of the student answers
  const handleResponseChange = (value: any) => {
    if (
      questionIndex !== undefined &&
      allData &&
      allData[questionIndex] &&
      allData[questionIndex].questionParts &&
      allData[questionIndex].questionParts[partIndex || 0]
    ) {
      const key = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex || 0].title}`;

      // Retrieve the quizAnswers array from local storage
let quizAnswers = JSON.parse(localStorage.getItem('localStudentResponse') || '[]');

// Find the index of the existing timer object
const index = quizAnswers.findIndex((item: any) =>
  item.classSourceId === classId &&
  item.subId === subIdSplitted &&
  item.refId === quizId
);

if (index !== -1) {
  // Retrieve the existing localStudentResponse
  let existingResponse = quizAnswers[index].localStudentResponse || {};
  
  // Merge the new key-value pair with the existing localStudentResponse
  existingResponse[key] = value;
  
  // Update the existing object with the merged localStudentResponse
  quizAnswers[index].localStudentResponse = existingResponse;
} else {
  // Add a new object if it doesn't exist
  const data = {
    subId: subIdSplitted,
    provider:provider,
    classSourceId: classId,
    refId: quizId,
    localStudentResponse: { [key]: value }
  };
  quizAnswers.push(data);
}

// Save the updated array back to local storage
localStorage.setItem('localStudentResponse', JSON.stringify(quizAnswers));

      setshortAnswer &&
        setshortAnswer((prevDescriptions) => ({
          ...prevDescriptions,
          [key]: value,
        }));
    } else if (questionIndex !== undefined && allData && allData[questionIndex]) {
      const singleKey = `${allData[questionIndex].title}`;

      // Retrieve the quizAnswers array from local storage
let quizAnswers = JSON.parse(localStorage.getItem('localStudentResponse') || '[]');

// Find the index of the existing timer object
const index = quizAnswers.findIndex((item: any) =>
  item.classSourceId === classId &&
  item.subId === subIdSplitted &&
  item.refId === quizId
);

if (index !== -1) {
  // Retrieve the existing localStudentResponse
  let existingResponse = quizAnswers[index].localStudentResponse || {};
  
  // Merge the new key-value pair with the existing localStudentResponse
  existingResponse[singleKey] = value;
  
  // Update the existing object with the merged localStudentResponse
  quizAnswers[index].localStudentResponse = existingResponse;
} else {
  // Add a new object if it doesn't exist
  const data = {
    subId: subIdSplitted,
    provider:provider,
    classSourceId: classId,
    refId: quizId,
    localStudentResponse: { [singleKey]: value }
  };
  quizAnswers.push(data);
}

// Save the updated array back to local storage
localStorage.setItem('localStudentResponse', JSON.stringify(quizAnswers));


      setshortAnswer &&
        setshortAnswer((prevDescriptions) => ({
          ...prevDescriptions,
          [singleKey]: value,
        }));
    }
  };

  return (
    <StudentShortAnswerContainerParent>
      {/* The Feedback of the answer goes here */}
      <MediumTypography
        text={showPublishGrade && findAnswerFeedbackV1(studentResponse,
          (questionIndex !== undefined && partIndex !== undefined && allData && allData[questionIndex].questionParts)
            ? allData[questionIndex].questionParts[partIndex].id
            : (questionIndex !== undefined && allData ? allData[questionIndex].questionId : undefined),
          (questionIndex !== undefined && partIndex !== undefined && allData && allData[questionIndex].questionParts)
            ? allData[questionIndex].questionParts[partIndex].type
            : (questionIndex !== undefined && allData ? allData[questionIndex].type : undefined)
        )}
        color='#0B8FFF'
        fontSize='.8rem'
      />
      {/* The text editor goes here */}
      <TinyEditor
        onChange={handleResponseChange}
        placeholder={(t('course view.Type your answer here'))}
        height="6.5rem"
        limit={250}
        initialValue={
          shortAnswerResponse ||
          (shortAnswerInitialState && questionIndex !== undefined ?
            (partIndex !== undefined && allData && allData[questionIndex].questionParts ?
              shortAnswerInitialState[`${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}`] :
              shortAnswerInitialState[`${allData && allData[questionIndex].title}`]
            ) :
            undefined
          )
        }
        readonly={!started ? true : false}
      />
      {partIndex === undefined && showPublishGrade && allData && allData[questionIndex || 0] && findStudentComment(studentResponse, allData[questionIndex || 0].questionId, allData[questionIndex || 0].type) !== "" ?
        <>
          {/* Comment of the instructor on the answer for the normal question goes Here */}
          <MediumTypography
            text='Instructor Comment'
          />
          <CommentContainer>
            <LightTypography
              text={findStudentComment(studentResponse,
                (questionIndex !== undefined && partIndex !== undefined && allData && allData[questionIndex].questionParts)
                  ? allData[questionIndex].questionParts[partIndex].id
                  : (questionIndex !== undefined && allData ? allData[questionIndex].questionId : undefined),
                (questionIndex !== undefined && partIndex !== undefined && allData && allData[questionIndex].questionParts)
                  ? allData[questionIndex].questionParts[partIndex].type
                  : (questionIndex !== undefined && allData ? allData[questionIndex].type : undefined)
              )}
              fontSize='.8rem'
            />
          </CommentContainer>
        </>
        : ""}
      {partIndex !== undefined && showPublishGrade && allData && allData[questionIndex || 0] && findStudentComment(studentResponse, allData[questionIndex || 0].questionParts[partIndex].id, allData[questionIndex || 0].questionParts[partIndex].type) !== "" ?
        <>
          {/* Comment of the instructor on the answer for the composed question goes Here */}
          <MediumTypography
            text='Instructor Comment'
          />
          <CommentContainer>
            <LightTypography
              text={findStudentComment(studentResponse,
                (questionIndex !== undefined && partIndex !== undefined && allData && allData[questionIndex].questionParts)
                  ? allData[questionIndex].questionParts[partIndex].id
                  : (questionIndex !== undefined && allData ? allData[questionIndex].questionId : undefined),
                (questionIndex !== undefined && partIndex !== undefined && allData && allData[questionIndex].questionParts)
                  ? allData[questionIndex].questionParts[partIndex].type
                  : (questionIndex !== undefined && allData ? allData[questionIndex].type : undefined)
              )}
              fontSize='.8rem'
            />
          </CommentContainer>

        </>
        : ""}
    </StudentShortAnswerContainerParent>
  )
}

export default StudentQuizShortAnswer