import React from "react";
import {
  CompetencyBodyCells,
  CompetencyCellsText,
  CompetencyHeaderCells,
  CompetencyStudentBodyCols,
  CompetencyStudentBodyMainRow,
  CompetencyStudentBodyRow,
  CompetencyStudentContainer,
  CompetencyStudentTitleCols,
} from "./CompetencyStudent.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";

import {
  CompetencyStudentData,
  CompetencyStudentHeaderData,
} from "./CompetencyStudentFrameworksInterfaceStudentFrameworksData";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  TableColorSelector,
  courseBannerColorSelector,
} from "../../../recoil/ThemeSelectors";

const CompetencyStudentFrameworks = () => {
  const { SecondaryTextColor, bghelp, theme, Container_Gradient_BgColor } =
    useRecoilValue(ColorSelector);
  const { courseTitleBgColor } = useRecoilValue(courseBannerColorSelector);
  const { Table_BoxShadow, Table_CellBorder, Table_nth_child } =
    useRecoilValue(TableColorSelector);
  return (
    <CompetencyStudentContainer
      style={{
        background: Container_Gradient_BgColor,
        boxShadow: Table_BoxShadow,
      }}
    >
      <CompetencyStudentTitleCols>
        <MediumTypography text="List Of Competency Frameworks" />
        <LightTypography
          text="To achieve the highest level in this course, you need to fulfill the activities outlined in this table"
          fontSize="0.875rem"
          lineHeight="1.375rem"
          color={SecondaryTextColor}
        />
      </CompetencyStudentTitleCols>
      <CompetencyStudentBodyRow style={{ background: courseTitleBgColor }}>
        {CompetencyStudentHeaderData.map((heads) => (
          <CompetencyHeaderCells key={heads.id}>
            <Typography text={heads.text} fontSize="1rem" />
          </CompetencyHeaderCells>
        ))}
      </CompetencyStudentBodyRow>
      {/* <CompetencyStudentBodyCols> */}
      {CompetencyStudentData.map((val) => (
        <CompetencyStudentBodyMainRow>
          <CompetencyCellsText bordercolor={bghelp}>
            <Typography text={val.CompetencyName || ""} fontSize="0.875rem" />
          </CompetencyCellsText>
          <CompetencyStudentBodyCols>
            {val.Competencies &&
              val.Competencies.map((item) => (
                <CompetencyStudentBodyRow
                  style={{
                    width: "100%",
                    // padding: "3rem",
                  }}
                  nth_child_bgcolor={Table_nth_child}
                  bordercolor={bghelp}
                  color={SecondaryTextColor}
                >
                  <CompetencyBodyCells>
                    <TypographyRegularDescription
                      text={item.Module || ""}
                      fontSize="0.75rem"
                    />
                  </CompetencyBodyCells>
                  <CompetencyBodyCells>
                    <TypographyRegularDescription
                      text={item.Activity || ""}
                      fontSize="0.75rem"
                    />
                  </CompetencyBodyCells>
                  <CompetencyBodyCells>
                    <TypographyRegularDescription
                      text={item.Poor || "Not Contributed"}
                      fontSize="0.75rem"
                      color="#D85D66"
                    />
                  </CompetencyBodyCells>
                  <CompetencyBodyCells>
                    <TypographyRegularDescription
                      text={item.Intermediate || "-"}
                      fontSize="0.75rem"
                      color="#CED35D"
                    />
                  </CompetencyBodyCells>
                  <CompetencyBodyCells>
                    <TypographyRegularDescription
                      text={item.Advanced || "Contributed"}
                      fontSize="0.75rem"
                      color="#5DD3B3"
                    />
                  </CompetencyBodyCells>
                  <CompetencyBodyCells>
                    <TypographyRegularDescription
                      text={item.Level || ""}
                      fontSize="0.75rem"
                    />
                  </CompetencyBodyCells>
                </CompetencyStudentBodyRow>
              ))}
          </CompetencyStudentBodyCols>
        </CompetencyStudentBodyMainRow>
      ))}
      {/* </CompetencyStudentBodyCols> */}
    </CompetencyStudentContainer>
  );
};

export default CompetencyStudentFrameworks;
