export const LeaderboardData = [
    {
        id: 1,
        name: "Sultan Al Rajhi",
        profile: require("../../assets/updateProfileImg.jpg"),
        points: 10
    },
    {
        id: 2,
        name: "Sultana Mohammad",
        profile: require("../../assets/NourInstructor.jpg"),
        points: 20
    },
    {
        id: 3,
        name: "Aya Ismail",
        profile: require("../../assets/ayaInstructor2.jpg"),
        points: 15
    },
    {
        id: 4,
        name: "Ahmad Ali",
        profile: require("../../assets/AliAhmad.png"),
        points: 18
    },
    {
        id: 5,
        name: "Khaled",
        profile: require("../../assets/KhaledInstructor.jpg"),
        points: 7
    },
    {
        id: 6,
        name: "Mohammad",
        profile: require("../../assets/Mohammad.png"),
        points: 4
    }
]