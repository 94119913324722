import React, { useState, useEffect, useRef } from "react";
import {
  MessageSearchCell,
  MessageSearchCellText,
  MessageSearchLayout,
  MessageSearchRender,
  MessageSearchRow,
  SearchCloseView,
  SearchViewElement,
} from "./MessageSearch.style";
import { TypographyRegularDescription } from "../../../elements/fonts/Fonts";
import { SvgCloseIcon } from "../../../elements/Icons";
import {
  userChatData,
  GroupChatData,
} from "../../../components/ContactMessages/ContactMessagesData";
import SearchElement from "../../../elements/searchElement/SearchElement";
import { useRecoilValue } from "recoil";
import { ChatsColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useParams } from "react-router-dom";
import { get } from "../../../utils/AxiosRequests";
import { useAuth0 } from "@auth0/auth0-react";

interface MessageSearchProps {
  ShowSearchPlace?: boolean;
  setShowSearchPlace?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedGroupId?: string | null;
  selectedFriendId?: string | null;
  scrollToMessage?: (index: number) => void;
  UserMessages: any[];
  GroupMessages: any[];
}

export const MessageSearch = ({
  UserMessages,
  GroupMessages,
  ShowSearchPlace,
  setShowSearchPlace,
  selectedGroupId,
  selectedFriendId,
  scrollToMessage,
}: MessageSearchProps): JSX.Element => {
  const [searchText, setSearchText] = useState<string>("");
  const messageSearchRef = useRef<HTMLDivElement>(null);
  const [mySourceId, setMySourceId] = useState<string>("");
  const Url = useParams();
  const classId = Url.classId;
  const { user, getAccessTokenSilently } = useAuth0();
  const myEmail = user?.email?.toLowerCase();
  const { t } = useTranslation();
  const tenantName = localStorage.getItem("tenant");

  const fetchUsersData = async () => {
    try {
      const response = await get(`participant/class/${classId}/${tenantName}`)
      const auditors = await axios.get(`https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/classAuditor/auditors/${tenantName}/${classId}`)

      const filteredData = response.data.filter(
        (user: any) => user.email.toLowerCase() === myEmail
      );
      const auditorFilteredData = auditors.data.data.filter(
        (user: any) => user.email.toLocaleLowerCase() === myEmail?.toLocaleLowerCase()
      );
      if (filteredData.length > 0)
        setMySourceId(filteredData[0].sourceId);
      else if (auditorFilteredData.length > 0)
        setMySourceId(auditorFilteredData[0].sourceId);
    } catch (err) {
      console.log(err);
    }
  };

  const GetBg = (message: { senderId: string }) => {
    if (message.senderId === mySourceId) {
      return theme === "light" ? "#ffffff50" : "rgba(255, 255, 255, 0.03)";
    } else {
      return theme === "light" ? "#ffffff" : "rgba(255, 255, 255, 0.10)";
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  const handleMessageDiv = () => {
    if (setShowSearchPlace) {
      setShowSearchPlace(!ShowSearchPlace);
    }
  };

  const handleItemClick = (index: number) => {
    if (scrollToMessage) {
      scrollToMessage(index);
    }
  };

  const filteredMessages = [...UserMessages, ...GroupMessages]
    .map((message, index) => ({ ...message, originalIndex: index }))
    .filter((message) =>
      message.message.toLowerCase().includes(searchText.toLowerCase())
    );

  const { SearchCell_BgColor, theme } = useRecoilValue(ChatsColorSelector);

  const highlightMatch = (text: string) => {
    const regex = new RegExp(searchText, "gi");
    return text.replace(
      regex,
      (match) => `<span style="color: #5DD3B3;">${match}</span>`
    );
  };

  return (
    <MessageSearchLayout ref={messageSearchRef}>
      <MessageSearchRow>
        <TypographyRegularDescription
          text={t("dashboard.Search")}
          fontSize="0.875rem"
        />
        <SearchCloseView onClick={handleMessageDiv}>
          <SvgCloseIcon />
        </SearchCloseView>
      </MessageSearchRow>
      <SearchViewElement>
        <SearchElement
          backGroundPosition="1.2rem 0.75rem"
          PaddingLeft="2.8rem"
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          showRemoveText={true}
          leftPositionIcon="1.15rem"
          paddingLeftIcon="0.8rem"
          topPositionIcon="0.75rem"
          handleRemoveText={() => setSearchText("")}
        />
      </SearchViewElement>
      <MessageSearchRender>
        {filteredMessages.map((message, index) => (
          <MessageSearchCell
            key={message.originalIndex}
            onClick={() => handleItemClick(message.originalIndex)}
            bgColor={GetBg(message)}
          >
            <MessageSearchCellText
              dangerouslySetInnerHTML={{
                __html: highlightMatch(message.message || ""),
              }}
            />
          </MessageSearchCell>
        ))}
      </MessageSearchRender>
    </MessageSearchLayout>
  );
};
