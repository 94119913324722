import { PostsTimeLineInterface } from "../PostsTimeLine/PostsTimeLineInterface";

export const  AnalyticsTopParticipantForumData :  PostsTimeLineInterface[] =[
  {SenderPostId:"safdss45tgvc",
  
  title:"Discussion Topic",
  text:"Hate dog claws in your leg mark territory flop over, or chase the pig around the house, or bite plants or enslave the hooman. Love fish. Eat owner's food chew the plant swat turds around the house i can haz and run in circles gnaw the corn cob or blow up sofa in 3 seconds. Headbutt owner's knee chirp at birds milk the cow.Carefully drink from water glass and then spill it everywhere and proceed to lick the puddle use lap as chair your pillow is now my pet bed so lick the curtain just to be annoying.",
  Posts: [
    {
      Posterid:"232",
      posterProfilePic: require("../../assets/SenderImg2.jpg"),
      posterName: "Sultan Al Rajhi",
      posterMajor: "Digital Marketing",
      posterTime: "1 h",
      file:require("../../assets/Rectangle 1170.png"),
      fileName:require("../../assets/Rectangle 1170.png"),
      posterText: "Hate dog claws in your leg mark territory flop over, or chase the pig around the house, or bite plants or enslave the hooman.Love fish. Eat owner's food chew the plant swat turds around the house i can haz and run in circles gnaw the corn cob or blow up sofa in 3 seconds. Headbutt owner's knee chirp at birds milk the cow.",
      postLike: 0,
      postDisLike: 0,
      Reply: [
        {
          replyId: "133",
          replyName: "Sultan Al Rajhi",
          replyText: "What is your favorite color of the year because I don't understand why it isn't green?",
          replyProfilePic: require("../../assets/AliAhmad.png"),
          replyLike: 9,
          replyDisLike: 9,
        },
        {
          replyId: "143",
          replyName: "Nour Mohammad",
          replyText: "my favorite  color is yellow",
          replyProfilePic: require("../../assets/NourInstructor.jpg"),
          replyLike: 7,
          replyDisLike: 7,
        },
        {
          replyId: "143",
          replyName: "Sultan Mohammad",
          replyText: "sory but is your favorite color of the year because I don't understand why it isn't green?",
          replyProfilePic: require("../../assets/NourInstructor.jpg"),
          replyLike: 7,
          replyDisLike: 7,
        },
      ],
    },
  

  ],
},
{SenderPostId:"safdw2ertyhgfcfdsfghtg",
title:"Discussion Topic",
text:"Hate dog claws in your leg mark territory flop over, or chase the pig around the house, or bite plants or enslave the hooman. Love fish. Eat owner's food chew the plant swat turds around the house i can haz and run in circles gnaw the corn cob or blow up sofa in 3 seconds. Headbutt owner's knee chirp at birds milk the cow.Carefully drink from water glass and then spill it everywhere and proceed to lick the puddle use lap as chair your pillow is now my pet bed so lick the curtain just to be annoying.",
Posts: [
  {
    Posterid:"4t5gewtrg",
    posterProfilePic: require("../../assets/KhaledInstructor.jpg"),
    posterName: "Sultan Al Rajhi",
    posterMajor: "Digital Marketing",
    posterTime: "1 h",
    file:require("../../assets/Rectangle 1170.png"),
    fileName:require("../../assets/Rectangle 1170.png"),
    posterText: "Hate dog claws in your leg mark territory flop over, or chase the pig around the house, or bite plants or enslave the hooman.Love fish. Eat owner's food chew the plant swat turds around the house i can haz and run in circles gnaw the corn cob or blow up sofa in 3 seconds. Headbutt owner's knee chirp at birds milk the cow.",
    postLike: 0,
    postDisLike: 0,
    Reply: [
      {
        replyId: "133",
        replyName: "Sultan Al Rajhi",
        replyText: "What is your favorite color of the year because I don't understand why it isn't green?",
        replyProfilePic: require("../../assets/AliAhmad.png"),
        replyLike: 9,
        replyDisLike: 9,
      },
      {
        replyId: "133",
        replyName: "Sultan Al Rajhi",
        replyText: "What is your favorite color of the year because I don't understand why it isn't green?",
        replyProfilePic: require("../../assets/AliAhmad.png"),
        replyLike: 9,
        replyDisLike: 9,
      },
      {
        replyId: "143",
        replyName: "Nour Mohammad",
        replyText: "my favorite  color is yellow",
        replyProfilePic: require("../../assets/NourInstructor.jpg"),
        replyLike: 7,
        replyDisLike: 7,
      },
      {
        replyId: "143",
        replyName: "Sultan Mohammad",
        replyText: "sory but is your favorite color of the year because I don't understand why it isn't green?",
        replyProfilePic: require("../../assets/NourInstructor.jpg"),
        replyLike: 7,
        replyDisLike: 7,
      },
    ],
  },


],
},
  {SenderPostId:"safdss45tgvc",
  
  title:"Discussion Topic",
  text:"Hate dog claws in your leg mark territory flop over, or chase the pig around the house, or bite plants or enslave the hooman. Love fish. Eat owner's food chew the plant swat turds around the house i can haz and run in circles gnaw the corn cob or blow up sofa in 3 seconds. Headbutt owner's knee chirp at birds milk the cow.Carefully drink from water glass and then spill it everywhere and proceed to lick the puddle use lap as chair your pillow is now my pet bed so lick the curtain just to be annoying.",
  Posts: [
    {
      Posterid:"232",
      posterProfilePic: require("../../assets/SenderImg2.jpg"),
      posterName: "Sultan Al Rajhi",
      posterMajor: "Digital Marketing",
      posterTime: "1 h",
      file:require("../../assets/Rectangle 1170.png"),
      fileName:require("../../assets/Rectangle 1170.png"),
      posterText: "Hate dog claws in your leg mark territory flop over, or chase the pig around the house, or bite plants or enslave the hooman.Love fish. Eat owner's food chew the plant swat turds around the house i can haz and run in circles gnaw the corn cob or blow up sofa in 3 seconds. Headbutt owner's knee chirp at birds milk the cow.",
      postLike: 0,
      postDisLike: 0,
      Reply: [
        {
          replyId: "133",
          replyName: "Sultan Al Rajhi",
          replyText: "What is your favorite color of the year because I don't understand why it isn't green?",
          replyProfilePic: require("../../assets/AliAhmad.png"),
          replyLike: 9,
          replyDisLike: 9,
        },
        {
          replyId: "143",
          replyName: "Nour Mohammad",
          replyText: "my favorite  color is yellow",
          replyProfilePic: require("../../assets/NourInstructor.jpg"),
          replyLike: 7,
          replyDisLike: 7,
        },
        {
          replyId: "143",
          replyName: "Sultan Mohammad",
          replyText: "sory but is your favorite color of the year because I don't understand why it isn't green?",
          replyProfilePic: require("../../assets/NourInstructor.jpg"),
          replyLike: 7,
          replyDisLike: 7,
        },
      ],
    },
    {
      Posterid:"232",
      posterProfilePic:  require("../../assets/SenderImg2.jpg"),
      posterName:"Sultan Al Rajhi",
      posterMajor: "Computer Science",
      file:"https://www.africau.edu/images/default/sample.pdf",
      fileName:require("../../assets/Rectangle 1170.png"),
      posterTime: "2 h",
      posterText: "Just finished a coding marathon. Anyone up for a discussion on the latest programming languages?",
      postLike: 15,
      postDisLike: 5,
      Reply: [
        {
          replyId: "153",
          replyName: "CodingFanatic",
          replyText: "Absolutely! I'm always excited to talk about coding. What specific languages are you interested in?",
          replyProfilePic: require("../../assets/SenderImg2.jpg"),
          replyLike: 12,
          replyDisLike: 3,
        },
        {
          replyId: "163",
          replyName: "TechExplorer",
          replyText: "Count me in! Let's discuss the trends and innovations in the tech world.",
          replyProfilePic: require("../../assets/AliAhmad.png"),
          replyLike: 10,
          replyDisLike: 2,
        },
      ],
    },
    {
      Posterid:"132",
      posterProfilePic:  require("../../assets/SenderImg2.jpg"),
      posterName: "Sultan Al Rajhi",
      posterMajor: "Environmental Science",
      posterTime: "3 h",
      posterText: "Just returned from a field expedition. Witnessed some fascinating ecological interactions. Anyone else passionate about environmental science?",
      postLike: 20,
      postDisLike: 3,
      file:"https://www.youtube.com/watch?v=wjFFipY3tC0",
      Reply: [
        {
          replyId: "173",
          replyName: "NatureEnthusiast",
          replyText: "That sounds amazing! I'm also passionate about environmental science. What did you observe during your expedition?",
          replyProfilePic: require("../../assets/AyaInstructor.jpg"),
          replyLike: 18,
          replyDisLike: 2,
        },
        {
          replyId: "183",
          replyName: "EcoWarrior",
          replyText: "Welcome back! Let's discuss the conservation efforts and challenges we face in today's world.",
          replyProfilePic: require("../../assets/NourInstructor.jpg"),
          replyLike: 15,
          replyDisLike: 1,
        },
      ],
    },

  ],
},
{SenderPostId:"safdw2ertyhgfcfdsfghtg",
title:"Discussion Topic",
text:"Hate dog claws in your leg mark territory flop over, or chase the pig around the house, or bite plants or enslave the hooman. Love fish. Eat owner's food chew the plant swat turds around the house i can haz and run in circles gnaw the corn cob or blow up sofa in 3 seconds. Headbutt owner's knee chirp at birds milk the cow.Carefully drink from water glass and then spill it everywhere and proceed to lick the puddle use lap as chair your pillow is now my pet bed so lick the curtain just to be annoying.",
Posts: [
  {
    Posterid:"4t5gewtrg",
    posterProfilePic: require("../../assets/KhaledInstructor.jpg"),
    posterName: "John Doe",
    posterMajor: "Digital Marketing",
    posterTime: "1 h",
    file:require("../../assets/Rectangle 1170.png"),
    fileName:require("../../assets/Rectangle 1170.png"),
    posterText: "Hate dog claws in your leg mark territory flop over, or chase the pig around the house, or bite plants or enslave the hooman.Love fish. Eat owner's food chew the plant swat turds around the house i can haz and run in circles gnaw the corn cob or blow up sofa in 3 seconds. Headbutt owner's knee chirp at birds milk the cow.",
    postLike: 0,
    postDisLike: 0,
    Reply: [
      {
        replyId: "133",
        replyName: "Sultan Al Rajhi",
        replyText: "What is your favorite color of the year because I don't understand why it isn't green?",
        replyProfilePic: require("../../assets/AliAhmad.png"),
        replyLike: 9,
        replyDisLike: 9,
      },
      {
        replyId: "133",
        replyName: "Sultan Al Rajhi",
        replyText: "What is your favorite color of the year because I don't understand why it isn't green?",
        replyProfilePic: require("../../assets/AliAhmad.png"),
        replyLike: 9,
        replyDisLike: 9,
      },
      {
        replyId: "143",
        replyName: "Nour Mohammad",
        replyText: "my favorite  color is yellow",
        replyProfilePic: require("../../assets/NourInstructor.jpg"),
        replyLike: 7,
        replyDisLike: 7,
      },
      {
        replyId: "143",
        replyName: "Sultan Mohammad",
        replyText: "sory but is your favorite color of the year because I don't understand why it isn't green?",
        replyProfilePic: require("../../assets/NourInstructor.jpg"),
        replyLike: 7,
        replyDisLike: 7,
      },
    ],
  },
  {
    Posterid:"4t5gewtrg",
    posterProfilePic:  require("../../assets/SenderImg2.jpg"),
    posterName: "John Doe",
    posterMajor: "Computer Science",
    file:"https://www.africau.edu/images/default/sample.pdf",
    posterTime: "2 h",
    posterText: "Just finished a coding marathon. Anyone up for a discussion on the latest programming languages?",
    postLike: 15,
    postDisLike: 5,
    Reply: [
      {
        replyId: "153",
        replyName: "CodingFanatic",
        replyText: "Absolutely! I'm always excited to talk about coding. What specific languages are you interested in?",
        replyProfilePic: require("../../assets/SenderImg2.jpg"),
        replyLike: 12,
        replyDisLike: 3,
      },
      {
        replyId: "163",
        replyName: "TechExplorer",
        replyText: "Count me in! Let's discuss the trends and innovations in the tech world.",
        replyProfilePic: require("../../assets/AliAhmad.png"),
        replyLike: 10,
        replyDisLike: 2,
      },
    ],
  },
  {
    Posterid:"4t5gewtrg",
    posterProfilePic:  require("../../assets/SenderImg2.jpg"),
    posterName: "John Doe",
    posterMajor: "Environmental Science",
    posterTime: "3 h",
    posterText: "Just returned from a field expedition. Witnessed some fascinating ecological interactions. Anyone else passionate about environmental science?",
    postLike: 20,
    postDisLike: 3,
    file:"https://www.youtube.com/watch?v=wjFFipY3tC0",
    Reply: [
      {
        replyId: "173",
        replyName: "NatureEnthusiast",
        replyText: "That sounds amazing! I'm also passionate about environmental science. What did you observe during your expedition?",
        replyProfilePic: require("../../assets/AyaInstructor.jpg"),
        replyLike: 18,
        replyDisLike: 2,
      },
      {
        replyId: "183",
        replyName: "EcoWarrior",
        replyText: "Welcome back! Let's discuss the conservation efforts and challenges we face in today's world.",
        replyProfilePic: require("../../assets/NourInstructor.jpg"),
        replyLike: 15,
        replyDisLike: 1,
      },
    ],
  },

],
},
{SenderPostId:"fgherwertyhjukjtyrgfdfgfbv",
title:"Discussion Topic",
text:"Hate dog claws in your leg mark territory flop over, or chase the pig around the house, or bite plants or enslave the hooman. Love fish. Eat owner's food chew the plant swat turds around the house i can haz and run in circles gnaw the corn cob or blow up sofa in 3 seconds. Headbutt owner's knee chirp at birds milk the cow.Carefully drink from water glass and then spill it everywhere and proceed to lick the puddle use lap as chair your pillow is now my pet bed so lick the curtain just to be annoying.",
Posts: [
  {
    Posterid:"342432534",
    posterProfilePic: require("../../assets/AliAhmad.png"),
    posterName: "Alice Johnson",
    posterMajor: "Digital Marketing",
    posterTime: "1 h",
    file:require("../../assets/Rectangle 1170.png"),
    fileName:require("../../assets/Rectangle 1170.png"),
    posterText: "Hate dog claws in your leg mark territory flop over, or chase the pig around the house, or bite plants or enslave the hooman.Love fish. Eat owner's food chew the plant swat turds around the house i can haz and run in circles gnaw the corn cob or blow up sofa in 3 seconds. Headbutt owner's knee chirp at birds milk the cow.",
    postLike: 0,
    postDisLike: 0,
    Reply: [
      {
        replyId: "133",
        replyName: "Sultan Al Rajhi",
        replyText: "What is your favorite color of the year because I don't understand why it isn't green?",
        replyProfilePic: require("../../assets/AliAhmad.png"),
        replyLike: 9,
        replyDisLike: 9,
      },
      {
        replyId: "143",
        replyName: "Nour Mohammad",
        replyText: "my favorite  color is yellow",
        replyProfilePic: require("../../assets/NourInstructor.jpg"),
        replyLike: 7,
        replyDisLike: 7,
      },
      {
        replyId: "143",
        replyName: "Sultan Mohammad",
        replyText: "sory but is your favorite color of the year because I don't understand why it isn't green?",
        replyProfilePic: require("../../assets/NourInstructor.jpg"),
        replyLike: 7,
        replyDisLike: 7,
      },
    ],
  },

  {
    Posterid:"342432534",
    posterProfilePic:  require("../../assets/SenderImg2.jpg"),
    posterName: "Alice Johnson",
    posterMajor: "Environmental Science",
    posterTime: "3 h",
    posterText: "Just returned from a field expedition. Witnessed some fascinating ecological interactions. Anyone else passionate about environmental science?",
    postLike: 20,
    postDisLike: 3,
    file:"https://www.youtube.com/watch?v=wjFFipY3tC0",
    Reply: [
      {
        replyId: "173",
        replyName: "NatureEnthusiast",
        replyText: "That sounds amazing! I'm also passionate about environmental science. What did you observe during your expedition?",
        replyProfilePic: require("../../assets/AyaInstructor.jpg"),
        replyLike: 18,
        replyDisLike: 2,
      },
  
    ],
  },

],
}
]