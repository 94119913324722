import styled from "styled-components";
import { breakpoints } from "../../theme";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

interface TableTdProps {
  color?: string;
}
export const FilterBtnsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.62rem;
  width: auto;
  @media (max-width: ${breakpoints.small}) {
    flex-wrap: wrap;
  }
`;
export const TitleSearchRow = styled.div`
  margin: 0rem 1.25rem 0rem 1.25rem;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  &:dir(ltr) {
    padding-right: 2rem;
  }
  &:dir(rtl) {
    padding-left: 2rem;
  }
`;
export const FiltersWithTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
`;
export const LogoAndAction = styled.div`
  display: flex;
  align-items: center;
  gap: 0.56rem;
  cursor: pointer;
`;

export const SearchAndLogosDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.81rem;
  justify-content: flex-end;
  align-items: flex-end;
  & > :first-child {
    align-content: flex-end;
  }
`;
export const ActionsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.25rem;
  align-self: end;
  @media (max-width: ${breakpoints.small}) {
    flex-wrap: wrap;
  }
`;

export const TableSection = styled.div`
  display: flex;
  min-height: fit-content;
  overflow-x: hidden;
  margin-top: 1.25rem;
  position: relative;
  scroll-behavior: smooth;
  /* background-color: red; */
`;

export const TableContentHolder = styled.table`
  width: 100vw;

  /* min-width: 800px; */
  border: none;
  /* background-color: yellow; */
`;

export const TableContent = styled.table`
  font-family: "Primary Font-Medium";
  /* width: 100%; */
  border-collapse: collapse;
  border: none;
  /* background-color: white; */
  width: 100%;
`;

export const TableThead = styled.thead``;

export const TableTh = styled.th`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 48%;
  &:dir(ltr) {
    padding: 0 2.5rem 0rem 1rem;
  }
  &:dir(rtl) {
    padding: 0 1rem 0rem 2.5rem;
  }
  /* gap: 25.5rem; */
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
`;

export const TableTr = styled.tr`
  /* width: auto; */
  white-space: nowrap;
  /* width: max-content; */
  &:nth-child(even) {
    /* background: rgba(255, 255, 255, 0.03); */
    border: none;
  }
`;
export const TableTr1 = styled.tr<StyleThemeInterface>`
  height: 3.56rem;
  &:nth-child(even) {
    background: ${props =>props.nth_child_bgcolor};
  border: none;
  }
`;

export const TableTbody = styled.tbody`
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
`;

export const TableTd = styled.td<TableTdProps>`
  border: none;
  height: 3.5625rem;
  border-collapse: collapse;
  width: 5rem;
  padding: 0.5rem;
  text-align: start;
  &:dir(ltr) {
    border-right: 1px solid rgba(87, 99, 113, 0.5);
  }
  &:dir(rtl) {
    border-left: 1px solid rgba(87, 99, 113, 0.5);
  }
  background-color: rgba(45, 55, 65, 1);
  max-width: 2rem;
`;
export const TableTh1 = styled.td<TableTdProps>`
  border: none;
  height: 3.5625rem;
  border-collapse: collapse;
  width: 5rem;
  padding: 0.5rem;
  text-align: start;
  max-width: 2rem;
`;
export const TableTd1 = styled.td<TableTdProps>`
  border: none;
  height: 3.5625rem;
  border-collapse: collapse;
  width: 5rem;
  text-align: center;
  /* min-width: 10.1rem; */
  color: ${({ color }) => color};
`;

export const ProfileImageHolder = styled.img`
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  object-fit: cover;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* position: relative; */
`;
export const PaginationContainer = styled.div`
  width: 100%;
  &:dir(ltr) {
    margin-left: -2rem;
  }
  &:dir(rtl) {
    margin-right: -2rem;
  }
  display: flex;
  justify-content: flex-end;
`;

export const ProfileContainer = styled.div<{ bgimage?: string }>`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-image: url(${(props) => props.bgimage});
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
