 import {styled} from "styled-components"

 
export const DeletOverLay = styled.div<{ isvisible: string }>`
display: ${(props) => (props.isvisible ? 'block' : 'none')};
opacity: ${(props) => (props.isvisible ? '1' : '0')};
position: fixed;
top: 0;
left: 0;
width: 100%;
height:100vh;
transition: opacity 500ms;
overflow: auto;
z-index: 10;

`;

export const PopupContentContainerr = styled.div`
   border-left: 4px solid #5DD3B3;
   border-radius: 0rem 0.625rem 0.625rem 0rem;
background: #384450;
   margin-top:4rem;
   padding: 10px;
   width:34rem;
   padding-bottom:0;
`;

export const TextPadding = styled.div`
width:100%;
padding-left:2rem;
`;