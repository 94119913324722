import React, { useTransition } from 'react'
import { GradeItemDetail } from '../AssignmentInstructor.style'
import { SvgGrade, SvgSimpleArrow, SvgStar } from '../../../elements/Icons'
import { LightTypography, Typography } from '../../../elements/fonts/Fonts'
import { useTranslation } from 'react-i18next';
import { toArabicDigits } from '../../../utils/formatDayArabic';

interface ForumDetailsProps {
  minimumGrade: any;
  average: any;
  maximumGrade: any;
  gradedCount: any;
  AssignmentsData: any[]
  loading: boolean
}

const ForumGradesDetails = ({
  minimumGrade,
  maximumGrade,
  gradedCount,
  average,
  AssignmentsData,
  loading
}: ForumDetailsProps) => {
  const { t } = useTranslation();
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography text={`${t('general.loading')}...`} />
      </div>
    )
  }
  return (
    <>
      <GradeItemDetail>
        <SvgSimpleArrow style={{ transform: 'rotate(90deg)' }} />
        {/* Minimum Grade between all the students */}
        <LightTypography
          text={t("assignment.Minimum Grade")}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
        <Typography
          text={toArabicDigits(minimumGrade.toString())}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
      </GradeItemDetail>
      <GradeItemDetail>
        <SvgGrade />
        {/* Average of the class */}
        <LightTypography
          text={t("assignment.Class Average")}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
        <Typography
          text={toArabicDigits(average.toString())}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
      </GradeItemDetail>{" "}
      <GradeItemDetail>
        <SvgSimpleArrow style={{ transform: 'rotate(-90deg)' }} />
        {/* MAximum Grade between all the students */}
        <LightTypography
          text={t("assignment.Maximum grade")}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
        <Typography
          text={toArabicDigits(maximumGrade.toString())}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
      </GradeItemDetail>{" "}
      <GradeItemDetail>
        <SvgStar color="#5dd3b3" />
        {/* Count of the student graded */}
        <LightTypography
          text={t("assignment.Graded")}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
        <Typography
          text={`${toArabicDigits(gradedCount.toString())} / ${AssignmentsData &&
            AssignmentsData.length > 0 &&
            toArabicDigits(AssignmentsData.length.toString())
            }`}
          fontSize="0.75rem"
          color="#5dd3b3"
        />
      </GradeItemDetail>
    </>
  )
}

export default ForumGradesDetails