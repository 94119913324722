import React from "react";
import { FlexAppContainer } from "../../../App.style";
import { LightTypography, Typography } from "../../../elements/fonts/Fonts";
interface LandingPagePaymentTitleInterface {
  title: string;
  text?: string;
}
const LandingPagePaymentTitle = ({
  title,
  text,
}: LandingPagePaymentTitleInterface) => {
  return (
    <FlexAppContainer
      justifyContent="center"
      alignItems="center"
      gap="0.62rem"
      column={true}
    >
      <Typography text={title} fontSize="1.5625rem" color="#2D3741" />
      <LightTypography
        text={text || ""}
        fontSize="0.875rem"
        lineHeight="1.375rem"
      />
    </FlexAppContainer>
  );
};

export default LandingPagePaymentTitle;
