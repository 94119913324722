import React, { FC } from "react";
import { Container, QuestionsContainer } from "./CreateQuestion.style";
import { CreateQuestionsProps } from "./CreateQuestionInterface";
import NavBar from "../QuizNavBar/main/NavBar";
import QuestionsFilter from "../QuestionsFilter/QuestionsFilter";
import CardQuestions from "../CardQuestions";
import { useQuizMyContext } from "../../../contexts/CourseCreatorQuizContext";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
const CreateQuestions: FC<CreateQuestionsProps> = ({
  handleDynamicQuestionIndexFromChild,
  nameError,
  handleTemplateName,
  showQuest,
  setShowCategoryPopup,
  addMoreQuestion,
  setShowshowLoadTemplate,
  setIndex,
  addMorePartQuestions,
  setPartIndex,
  handleShowEditQuest,
  handleShowQuest,
  setDeleteQuestion,
  setDeletePart,
  setShowQuestionBanksPopup,
  selectedRandomOption,
  selectedRandomQuestion,
  handleRandomQuestion,
  handleRandomOptions,
  templateName,
  disableClick,
  quest,
  notValidate,
  setQuestionError,
  handleGrade,
  setQuest,
  onSave,
  setEditQuest,
  setNotValidate,
  partIndex
}) => {
  const { globalQuestionsArray } = useQuizMyContext();
  const { Container_Gradient_BgColor, boxShadow } =
    useRecoilValue(ColorSelector);
  return (
    <Container bgcolor={Container_Gradient_BgColor} boxshadow={boxShadow}>
      <NavBar
        setShowQuestionBanksPopup={setShowQuestionBanksPopup}
        addMoreQuestion={addMoreQuestion}
        nameError={nameError}
        templateName={templateName}
        handleTemplateName={handleTemplateName}
        showQuest={showQuest}
        setShowshowLoadTemplate={setShowshowLoadTemplate}
        questions={globalQuestionsArray}
        quest={quest}
        notValidate={notValidate}
        setQuestionError={setQuestionError}
      />
      <QuestionsFilter
        selectedRandomOption={selectedRandomOption}
        selectedRandomQuestion={selectedRandomQuestion}
        handleRandomQuestion={handleRandomQuestion}
        handleRandomOptions={handleRandomOptions}
      />
      <QuestionsContainer>
        {globalQuestionsArray?.map((question, index) => (
          <div key={index}>
            <CardQuestions
              question={question}
              index={index}
              questions={globalQuestionsArray}
              setDeleteQuestion={setDeleteQuestion}
              addMorePartQuestions={() => addMorePartQuestions(index)}
              setIndex={setIndex}
              setPartIndex={setPartIndex}
              handleShowEditQuest={handleShowEditQuest}
              handleShowQuest={handleShowQuest}
              setDeletePart={setDeletePart}
              disableClick={disableClick}
              handleDynamicQuestionIndexFromChild={
                handleDynamicQuestionIndexFromChild
              }
              handleGrade={handleGrade}
              quest={quest}
              setQuest={setQuest}
              onSave={onSave}
              setShowQuest={setEditQuest}
              notValidate={notValidate}
              setNotValidate={setNotValidate}
              setQuestionError={setQuestionError}
              partIndex={partIndex}
            />
          </div>
        ))}
      </QuestionsContainer>
    </Container>
  );
};

export default CreateQuestions;
