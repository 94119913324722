// import React from 'react';
// import { Route, Navigate } from 'react-router-dom';
// import { useAuth0 } from '@auth0/auth0-react';

// interface ProtectedRouteProps {
//     component: JSX.Element;

// }

// const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component }) => {
//     const { isAuthenticated, isLoading } = useAuth0();
//     console.log('m3araf:' , isAuthenticated);
//     return isAuthenticated ? (
//         component
//     ) : (
//         <Navigate to="/unAuth" replace />
//     );
// };

// export default ProtectedRoute;

import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { LoadingComp } from "../LoadingComp/LoadingComp";

interface ProtectedRouteProps {
  component: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <LoadingComp />; // You can replace this with your own loading indicator.
  }

  return isAuthenticated ? component : <Navigate to="/unAuth" replace />;
};

export default ProtectedRoute;
