import React, { useState } from "react";
import SurveyDefaultTable from "../../../../components/Tables/SurveyDefaultTable/SurveyDefaultTable";
import { AnalyticsPageStylesLayout } from "../../AnalyticsPageStyles";
import AnalyticsBackPage from "../../../../components/AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticsBackPage";
import { Typography } from "../../../../elements/fonts/Fonts";
import { AnalyticDefaultTable } from "../../../../elements/AnalyticDefaultTable/AnalyticDefaultTable";
import { SurveyDefaultTableData } from "../../../../components/Tables/SurveyDefaultTable/SurveyDefaultTableData";
import { useNavigate } from "react-router-dom";

const AnalyticsSurveyDefaultTable = () => {
  const navigate = useNavigate();
  const NavigatePage = () => {
    navigate(-1);
  };
  return (
    <AnalyticsPageStylesLayout>
      <AnalyticsBackPage onClick={NavigatePage} />
      <Typography text="Survey Analysis" fontSize="1.75rem" />

      {/* <SurveyDefaultTable /> */}
      {/* //or */}
      <AnalyticDefaultTable
        title="Survey Details"
        DatabaseTableName={SurveyDefaultTableData}
        // Is Table Header Changes Data
        MappedHeadText={SurveyDefaultTableData.map((val) => val.Title)}
        ////////////////
        // LinkedText="SurveyDetails"
      />
    </AnalyticsPageStylesLayout>
  );
};

export default AnalyticsSurveyDefaultTable;
