import {
  ParticipantCardHolder,
  ProfileNameEmailRow,
  NameEmailColumn,
  InputSendRow,
  InputMessageField,
  SendButton,
  ProfileImageHolder,
  ProfileImg,
  DotsContainer,
  OptionCard,
} from "./ParticipantCard.style";
import {
  Typography,
  MediumTypography,
  LightTypography,
} from "../../elements/fonts/Fonts";
import { SVGMenue, SvgSendButton } from "../../elements/Icons";
import CourseViewAboutProfile from "../../elements/courseViewAboutProfile/CourseViewAboutProfile";
import { ParticipantsInterface } from "./participantCardInterface";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { FlexBetween } from "../../globalStyles";
import { useContext, useEffect, useRef, useState } from "react";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import SelectedOptions from "../../elements/SelectedOptions/SelectedOptions";
import PopupDelete from "../../elements/PopupDelete/PopupDelete";
import { t } from "i18next";
import useClickOutside from "../../hooks/useClickOutside";
import axios from "axios";
const ParticipantCard = ({
  imgPath,
  name,
  email,
  sourceId,
  isAuditor,
  auditorId,
  handleDeleteAuditor
}: ParticipantsInterface): JSX.Element => {
  const { backgroundColor, SecondaryTextColor, boxShadowBanner, theme } =
    useRecoilValue(ColorSelector);
  const { role } = useContext(ClassContext);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [deleteNoteId, setDeleteNoteId] = useState<any>(null);
  const [openedIndex, setOpenedIndex] = useState<any>(null);
  const optionCardRef = useRef<HTMLDivElement | null>(null);
  const tenantName = localStorage.getItem("tenant")

  useEffect(() => {
    // Function to handle clicks outside the option card
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the option card reference exists and if the clicked element is not within the option card
      if (
        optionCardRef.current &&
        !optionCardRef.current.contains(event.target as Node)
      ) {
        // Close the option card by setting the opened index to null
        setOpenedIndex(null);
      }
    };
    // Add event listener for mousedown event to detect clicks outside the option card
    document.addEventListener("mousedown", handleClickOutside);
    // Clean up function to remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Empty dependency array ensures that this effect runs only once after initial render

  const toggleOptionCard = (index: any) => {
    if (openedIndex === index) {
      setOpenedIndex(null);
    } else {
      setOpenedIndex(index);
    }
  };

  const handleDeletePop = (id: any) => {
    setDeleteNoteId(id);
    setShowDeletePopup(true);
  };


  return (
    <ParticipantCardHolder
      boxShadow={boxShadowBanner}
      bgColor={backgroundColor}
    >
      <ProfileNameEmailRow>
        <ProfileImageHolder>
          <ProfileImg theme={theme} src={imgPath} alt="profile" />
        </ProfileImageHolder>
        <NameEmailColumn>
          <Typography
            text={name}
            fontSize="1rem"
            fontWeight="400"
            lineHeight="normal"
          />
          {role === "teacher" && isAuditor ? (
            <>
              <DotsContainer onClick={() => toggleOptionCard(sourceId)}>
                <SVGMenue />
              </DotsContainer>
              {openedIndex === sourceId && (
              <OptionCard
              ref={optionCardRef}
              >
                <SelectedOptions
                  LastLabelClick={() => handleDeletePop(auditorId)}
                />
              </OptionCard>
              )}
            </>
          ) : (
            ""
          )}
          {sourceId ? (
            <LightTypography
              text={`ID: ${sourceId || ""}`}
              fontSize="0.75rem"
              color="#AAB1B9"
            />
          ) : (
            ""
          )}
          <MediumTypography
            text={email}
            fontSize="0.75rem"
            fontWeight="400"
            lineHeight="normal"
            color={SecondaryTextColor}
          />
        </NameEmailColumn>
      </ProfileNameEmailRow>
      {/* <InputSendRow>
        <InputMessageField placeholder="Message John Scott" />

<SendButton >
        <SvgSendButton/>
</SendButton>

      </InputSendRow> */}

      {showDeletePopup && (
        <PopupDelete
          show={showDeletePopup}
          handleSubmit={() => {
            if (deleteNoteId !== null) {
              handleDeleteAuditor && handleDeleteAuditor(deleteNoteId);
              setShowDeletePopup(false);
            }
          }}
          setShow={setShowDeletePopup}
          title={t("participants.Delete Auditor")}
        />
      )}
    </ParticipantCardHolder>
  );
};

export default ParticipantCard;
