import React, { FC, useState, useEffect, useContext } from "react";
import GraderStatistics from "../../components/GraderStatistics/main/GraderStatistics";
import { PageHeader } from "../../components/pageHeader/PageHeader";
import GarderNavMenu from "../../components/GraderNavMenu/main/GarderNavMenu";
import PdfViewer from "../../components/GraderTextEditor/main/PdfViewer";
import { Students } from "./QuizGraderViewInterface";
import { get } from "../../utils/AxiosRequests";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Student from "../../components/GraderStatistics/Student/Student";
import { AssignmentQuestions } from "../../components/GraderTextEditor/PdfViewerDataInterface";
import {
  MyContextProvider,
  useMyContext,
} from "../../contexts/quizGraderContext";
import { ClassContext } from "../dashboard/UnstructuredContext";
import { Typography } from "../../elements/fonts/Fonts";
import { SvgBackArrow } from "../../elements/Icons";
import { LoadingComp } from "../../components/LoadingComp/LoadingComp";
import { useTranslation } from "react-i18next";
import { FlexDiv } from "../../globalStyles";
import { ResourceFrom } from "../courseView/content/Content";
const QuizGraderView: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const activityId = params.id;
  const classId = params.classId;
  // const activityId = 12;
  const [showQuiz, setShowQuiz] = useState<boolean>(true);
  const [showNavIcon, setShowNavIcon] = useState<boolean>(true);
  const { userSelectedIndex, setUserSelectedIndex } = useContext(ClassContext);

  interface GradeData {
    AssignmentSubmissionId: number;
    grade: number;
    comment: string | null;
    submissionID: number;
    name: string;
  }
  const { t } = useTranslation();
  const [questions, setQuestions] = useState<any[]>([]);

  const [submission, setSubmission] = useState<any[]>([]);
  const [allAssignment, setAllAssignment] = useState<any>({});
  const [students, setStudents] = useState<any[]>([]);
  const [enrollements, setEnrollements] = useState<number>(0);
  const [gradedCount, setGradedCount] = useState<number>(0);
  const tenantName = localStorage.getItem("tenant");
  const [totalPoints, setTotalPoints] = useState<number>(0);
  const [studentIndex, setStudentIndex] = useState<number>(
    userSelectedIndex || 0
  );
  const [totalSubmissions, setTotalSubmissions] = useState<number>(
    submission.length
  );
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const handleQuestionIndexChange = (index: number) => {
    setQuestionIndex(index);
  };

  const fetchParticipantData = async () => {
    try {
      const ParticipantData = await get(
        `participant/class/${classId}/${tenantName}`
      );

      // Filter participants based on role
      if (ParticipantData.data.length > 0) {
        const students = ParticipantData.data.filter(
          (participant: any) => participant.role === "Student"
        );
        setEnrollements(students.length);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStudentsData = async () => {
    try {
      const studentsData = await get(
        `quiz/get/all/answers/${activityId}/${tenantName}`
      );
      const questionsData = await get(
        `quiz/questions/${activityId}/${tenantName}`
      );
      const processedQuestions = questionsData.data.questions.map(
        (question: any) => {
          if (question.type === "COMPOSITE") {
            const compositeParts = question.questionParts.map((part: any) => {
              const modifiedTitle = `${question.title} ${part.title}`;
              return { ...part, title: modifiedTitle };
            });
            return [...compositeParts];
          } else {
            return [question];
          }
        }
      );
      const flattenedQuestions = processedQuestions.flat();
      const totalPoint = flattenedQuestions.reduce(
        (accumulator: any, question: any) => {
          return accumulator + (question.points || 0);
        },
        0
      );
      setTotalPoints(totalPoint);
      setQuestions(flattenedQuestions);
      setSubmission(studentsData.data);
      setTotalSubmissions(studentsData.data.length);
      setAllAssignment(questionsData.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getStudentsData();
    fetchParticipantData();
  }, [tenantName, studentIndex, questionIndex]); // Assuming tenantName might change and triggers fetching students

  // Use another useEffect to handle the state update for students
  useEffect(() => {
    if (submission && submission.length > 0) {
      const usersArray = submission.map(
        (submission: any) => submission.userData
      );
      setStudents(usersArray);
    }
  }, [submission]);

  const calculateClassAverage = () => {
    if (!submission || submission.length === 0) {
      return 0;
    }

    // Initialize variables to store the total grade and the count of submissions
    let totalFinalGrade = 0;
    let submissionCount = 0;

    // Iterate through each student's submission
    submission.forEach((studentSubmission) => {
      let totalGrade = 0; // Initialize totalGrade for each student

      // Iterate through each answer in the submission
      studentSubmission.lastAnswersOfStudent.forEach((answer: any) => {
        // Add the grade for each question to the total grade
        totalGrade += answer.grade;
      });

      // Calculate the final grade for the student
      const finalGrade = (totalGrade / totalPoints) * allAssignment.maxGrade;

      // Add the final grade to the totalFinalGrade
      totalFinalGrade += finalGrade;

      // Increment the submission count
      submissionCount++;
    });

    // Calculate the average grade
    const classAverage =
      (totalFinalGrade / (submissionCount * allAssignment.maxGrade)) * 100;

    setAvarage(classAverage.toFixed(0));
  };

  useEffect(() => {
    if (submission && submission.length > 0) {
      calculateClassAverage();
    }
  }, [submission, studentIndex, allAssignment]);

  const [avarage, setAvarage] = useState<string | number>(0);

  const [showSubmitedTime, setShowSubmitedTime] = useState<boolean>(true);
  const [showLate, setShowLate] = useState<boolean>(false);
  // const [submissions, setSubmissions] = useState<number>(student.length);
  const handleQuizView = () => {
    setShowQuiz(!showQuiz);
    setShowLate(!showLate);
    setShowNavIcon(!showNavIcon);
  };

  const navigateBack = () => {
    navigate(-1);
  };
  const [resourceR, setResourceR] = useState<ResourceFrom>();
  const language = localStorage.getItem("language");
  let { state } = useLocation();
  useEffect(() => {
    if (state) {
      const selectedResource: ResourceFrom = state.activity;
      setResourceR(selectedResource);
    }
  }, [state]);
  return (
    <>
      {loading && <LoadingComp />}
      <MyContextProvider>
        <Link
          to={`/courseView/content/${classId}`}
        >
          <FlexDiv gap="1.25rem">
            <div
              // onClick={navigateBack}
              style={{
                marginTop: ".2rem",
                cursor: "pointer",
                transform: `${language === "ar" ? "rotate(180deg)" : ""}`,
              }}
            >
              <SvgBackArrow />
            </div>
            <Typography
              text={t("quiz.Quiz")}
              fontSize="1.75rem"
              fontWeight="400"
              lineHeight="normal"
            />
          </FlexDiv>
        </Link>
        <GraderStatistics
          // setSubmitted={setSubmitted}
          // setGraded={setGraded}
          //setAvarage={setAvarage}
          totalSubmissions={totalSubmissions}
          enrollements={enrollements}
          // submitted={submitted}
          avarage={avarage}
          student={submission}
          students={students}
          studentIndex={studentIndex}
          setStudentIndex={setStudentIndex}
          graded={gradedCount}
          allAssignment={allAssignment}
          quiz={true}
        />
        <GarderNavMenu
          allAssignment={allAssignment}
          studentIndex={studentIndex}
          student={submission}
          handleQuizView={handleQuizView}
          quiz={true}
        />
        <PdfViewer
          student={submission}
          studentIndex={studentIndex}
          showQuiz={showQuiz}
          showSubmitedTime={showSubmitedTime}
          setShowSubmitedTime={setShowSubmitedTime}
          showLate={showLate}
          showNavIcon={showNavIcon}
          allAssignment={allAssignment}
          quiz={true}
          questions={questions}
          fetchData={getStudentsData}
          totalPoints={totalPoints}
          handleQuestionIndexChange={handleQuestionIndexChange}
          resourceR={resourceR}
        // updateSubmittedByInstructor={updateSubmittedByInstructor}
        />
      </MyContextProvider>
    </>
  );
};
export default QuizGraderView;
