import styled from "styled-components";
import { StyleThemeInterface } from "../../../../interfaces/StyleThemeInterface";

export const NavBarItems = styled.div`
  font-size: 10px;
  cursor: pointer;
`;

export const ContainerItems = styled.div<StyleThemeInterface>`
  /* margin-top:13px;
padding:10px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  padding: 0.94rem 1.24rem;
  border-radius: 0.625rem 0.625rem 0rem 0rem;
  background: ${(props) =>
    props.theme === "light"
      ? props.bgcolor + "15"
      : props.bgcolor || "linear-gradient(90deg, #415160 0%, #293440 107.88%)"};
  gap: 1rem;
  width: 100%;
`;

export const Items = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.94rem;
  align-items: center;
  flex-wrap: wrap;
`;

export const Tooltip = styled.span<StyleThemeInterface>`
  visibility: hidden;
  width: fit-content;
  white-space: nowrap;
  height: 1.75rem;
  background-color: ${(props) => props.bgcolor || "#1F2731"};
  padding: 0.5rem 1.38rem 0.63rem 1.25rem;
  /* color: #fff; */
  text-align: center;
  border-radius: 5px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0.4rem;
  /* right: -2rem; */
  top: 1.5rem;
  border-radius: 15px;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-family: "Primary Font-Medium";
  font-size: 0.625rem;
  z-index: 1;
`;

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    /* background-color: #5DD3B3; */
    ${Tooltip} {
      visibility: visible;
      opacity: 1;
    }
  }
`;
