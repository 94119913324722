import React, { useEffect, useState } from "react";
import {
  SERVERStarColumn,
  SERVERStarROw,
  SurveyStarTextSize,
  QADisplayPartsRowStart,
  QADisplaySVGHolder,
} from "./ServeyStarQuestion.style";
import { MediumTypography, TypographyRegularDescription } from "../fonts/Fonts";
import { SvgStayFav } from "../Icons";
import {
  StudentSurveyInstructor,
  StudentSurveyInstructorReply,
} from "../../components/SurveyStudentView/main/StudentSurvey.style";
import DOMPurify from "dompurify";
import { t } from "i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { findStudentAnswer } from "../../components/SurveyStudentView/main/surveyStudentCompoUtils";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../utils/StringManipulation";
import { useParams } from "react-router-dom";

interface StarComponent {
  PartTitle?: string;
  text?: string;
  onChange: (value: number) => void;
  showStarDisplay?: boolean;
  studentAnswer?: any; // New prop to receive studentAnswer
  comment: string;
  disabled?: boolean;
  setStarRatings?:any
  question?:any;
  studentAnswerArray?:any
  id?:number
  setSurveyQuestionId?:any
}

export const SurveyStarQuestion = ({
  PartTitle,
  text,
  onChange,
  showStarDisplay,
  studentAnswer, // New prop to receive studentAnswer
  comment,
  disabled,
  setStarRatings,
  question,
  studentAnswerArray,
  id,
  setSurveyQuestionId
}: StarComponent): JSX.Element => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [clickedIndex, setClickedIndex] = useState<number | null>(
    studentAnswer !== undefined ? studentAnswer - 1 : null // Set initial clickedIndex based on studentAnswer
  );
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const params = useParams();
  const classId = params.classId;

  const handleHover = (index: number | null) => {
    setHoveredIndex(index);
  };

  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };

  const handleClick = (index: number) => {
    // Check if studentAnswer already has a value
    if (!disabled) {
      setClickedIndex(index);
      onChange(index + 1);
    }
  };

  useEffect(() => {
    if (studentAnswer || studentAnswer !== null) {
      setClickedIndex(studentAnswer - 1);
    } else {
      setClickedIndex(null);
    }
  }, [studentAnswer]);

  useEffect(()=>{
    const key = `${question.title}`;
    const idKey = `${key}_ID`;
    setSurveyQuestionId((prevId:any) => ({
      ...prevId,
      [idKey]: question.SurveyQuestionsId || 0,
    }));
    if(studentAnswerArray && studentAnswerArray.length === 0 ){
      // Fetch and set the timer from local storage if it exists
      const savedStudentAnswer = JSON.parse(localStorage.getItem('localStudentSurveyResponse') || '[]');
      const currentQuizAnswer = savedStudentAnswer.find((item: any) =>
        item.classSourceId === classId &&
        item.subId === subIdSplitted &&
        item.refId === id
      );

      if (currentQuizAnswer &&
        question) {
        
      if(currentQuizAnswer && currentQuizAnswer.localStudentResponse && currentQuizAnswer.localStudentResponse[key]){
        setStarRatings((prevLineValues:any) => ({
          ...prevLineValues,
          [key]: currentQuizAnswer.localStudentResponse[key],
        }));
        }else{
          setStarRatings((prevLineValues:any) => ({
              ...prevLineValues,
              [key]: 0,
            }));
      }
      }
      }
  },[question])

  const ZidyiaInterface = () => {
    const svgArray = [];
    for (let i = 0; i < 5; i++) {
      const isHovered = hoveredIndex === i;
      const isActive = clickedIndex !== null && i <= clickedIndex;

      svgArray.push(
        <QADisplaySVGHolder
          key={i}
          color={isActive ? "#5DD3B3" : isHovered ? "#5DD3B3" : undefined}
          onMouseEnter={() => handleHover(i)}
          onMouseLeave={() => handleHover(null)}
          onClick={() => handleClick(i)}
        >
          <SvgStayFav />
        </QADisplaySVGHolder>
      );
    }
    return svgArray;
  };

  const { themeTextColor, theme } = useRecoilValue(ColorSelector);

  return (
    <>
      <SERVERStarColumn>
        {showStarDisplay && (
          <TypographyRegularDescription
            text={PartTitle || ""}
            color="#5DD3B3"
            fontSize="0.875rem"
          />
        )}

        <SERVERStarROw>
          <>
            {showStarDisplay && (
              <SurveyStarTextSize>
                {/* <MediumTypography text={text || ""} /> */}
                <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              decodeHtml(text || '')
                            ),
                          }}
                          style={{
                            fontSize: "1rem"
                          }}
                        ></div>
              </SurveyStarTextSize>
            )}
            <QADisplayPartsRowStart>{ZidyiaInterface()}</QADisplayPartsRowStart>
          </>
        </SERVERStarROw>
      </SERVERStarColumn>
      {comment && comment.trim() !== "No Comment" && (
        <StudentSurveyInstructor>
          <TypographyRegularDescription
            text={t("assignment.Instructor Comment")}
            fontSize="0.875rem"
          />
          <StudentSurveyInstructorReply color={themeTextColor} theme={theme}>
            <TypographyRegularDescription
              text={comment}
              fontSize="0.75rem"
            />
          </StudentSurveyInstructorReply>
        </StudentSurveyInstructor>
      )}
    </>
  );
};
