import React from "react";
import { PageHeader } from "../../components/pageHeader/PageHeader";
import { NoteById } from "../../components/NotesById/NoteById";

export const NoteByIdView = (): JSX.Element => {
  return (
    <>
      <NoteById />
    </>
  );
};
