
/**
 * Handles the "Select All" toggle functionality.
 * Toggles the checked state of all items and updates the selected students accordingly.
 * @param setSelectAllChecked - React state setter for the "Select All" checkbox checked state.
 * @param setCheckedItems - React state setter for the checked state of individual items.
 * @param data - The array of all items.
 * @param setSelectedStudents - Optional React state setter for the selected students array.
 */
export const handleSelectAllToggle = (
  setSelectAllChecked: React.Dispatch<React.SetStateAction<boolean>>,
  setCheckedItems: React.Dispatch<React.SetStateAction<boolean[]>>,
  data: any[],
  setSelectedStudents?: React.Dispatch<React.SetStateAction<any[]>>,
) => {
  setSelectAllChecked((prevSelectAllChecked) => {
    // Toggle the "Select All" checked state
    const newSelectAllChecked = !prevSelectAllChecked;

    // Set the checked state of all items based on the "Select All" state
    setCheckedItems(new Array(data.length).fill(newSelectAllChecked));

    // Update the selected students array if it's provided
    setSelectedStudents && setSelectedStudents(newSelectAllChecked ? [...data] : []);

    return newSelectAllChecked; // Return the new "Select All" checked state
  });
};


/**
 * Handles the toggle functionality for individual items.
 * Toggles the checked state of an individual item and updates the selected students accordingly.
 * @param index - The index of the item being toggled.
 * @param checkedItems - Array representing the checked state of individual items.
 * @param setCheckedItems - React state setter for the checked state of individual items.
 * @param setSelectedStudents - Optional React state setter for the selected students array.
 * @param filteredData - Optional array of filtered data to determine selected students.
 */
export const handleItemToggle = (
  index: number,
  checkedItems: boolean[],
  setCheckedItems: React.Dispatch<React.SetStateAction<boolean[]>>,
  setSelectedStudents?: React.Dispatch<React.SetStateAction<any[]>>,
  filteredData?: any[] // Add filteredData as a parameter
) => {
  const newCheckedItems = [...checkedItems];
  newCheckedItems[index] = !newCheckedItems[index];
  setCheckedItems(newCheckedItems);

  if (setSelectedStudents) {
    setSelectedStudents((prevSelectedStudents) => {
      if (newCheckedItems[index]) {
        // Add the selected item to the state
        return [...prevSelectedStudents, filteredData && filteredData[index]];
      } else {
        // Remove the selected item from the state
        return prevSelectedStudents.filter(
          (_, idx) => idx !== index
        );
      }
    });
  }
};
