import styled from "styled-components";

export const TextEditorAnswerReaderRow =styled.div`
/* height: 2rem; */
width:100%;
flex-direction:row;
display: flex;
align-items:center;
justify-content:space-between;
/* background-color:red; */
margin-left:-1.15rem;
/* padding: 0.25rem 0 1.25rem 1.25rem; */
`;