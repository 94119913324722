import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const Container = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 2.219rem;
  width: 85%;
  display: grid;
  grid-template-columns: 0.7fr 0.3fr 0.5fr 0.6fr 0.2fr 0.3fr;

  @media (max-width: 870px) {
    grid-template-columns: 2fr 2fr;
  }
`;

export const StatHeader = styled.h1`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const QuestionsNumber = styled.div`
  font-family: "Primary Font-Bold";
  font-size: 46px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 20%;
    height: 2px;
    background-color: #5dd3b3;
    top: 0;
  }
  @media (max-width: 970px) {
    margin-top: 10px;
    &::before {
      content: "";
      position: absolute;
      width: 30px;
      height: 2px;
      background-color: #5dd3b3;
      top: 0;
    }
  }
`;

export const VerticalLine = styled.div<StyleThemeInterface>`
  margin-top: 7px;
  &:dir(ltr) {
    border-left: 1px solid ${(props) => props.bordercolor};
  }
  &:dir(rtl) {
    border-right: 1px solid ${(props) => props.bordercolor};
  }
  height: 77px;
  /* opacity:0.5; */
  @media (max-width: 970px) {
    display: none;
  }
`;

export const OptionsItemsOne = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.5rem;
`;

export const OptionsItemsTwo = styled.div`
  margin-top: 0.62rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.5rem;
`;

export const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70%;
`;
