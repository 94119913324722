import React from "react";
import { PublicProPIC } from "./PublicViewProfilePicture.style";
import ProfiledePictureData from "./PublicViewProfilePictureInterface";

interface PRFOFILEiMAGE {
  src: string;
}
export const PublicViewProPic = ({ src }: PRFOFILEiMAGE): JSX.Element => {
  return (
    <>
      <PublicProPIC src={src} />
    </>
  );
};
