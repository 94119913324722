import { StyledDropdown } from "./Menu.style";
import MenuItems from "./MenuItems";

interface DropdownProps {
    submenus: any[]; // You should replace 'any' with the appropriate type for your submenu items
    dropdown: boolean;
    hasBorder: boolean;
    depthLevel: number;
    handleSorting?:any
    columnIndex?:any
    onClick?:any
  }
  const Dropdown: React.FC<DropdownProps> = ({ submenus, dropdown, depthLevel, hasBorder, handleSorting, columnIndex, onClick }) => {
    depthLevel = depthLevel + 1;
    const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";
    return (
      <StyledDropdown hasBorder={ hasBorder } dropdown={dropdown} depthLevel={depthLevel} className={dropdownClass}>
        {submenus.map((submenu, index) => (
          <MenuItems items={submenu} key={index} depthLevel={depthLevel} handleSorting={handleSorting} columnIndex={columnIndex} onClick={onClick} />
        ))}
      </StyledDropdown>
    );
  };

export default Dropdown;