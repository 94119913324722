import styled from "styled-components";

interface Styled{
  opacity?:string
}
export const MatchesAfterSubmissionLayout=styled.div`
display:flex;
flex-direction:column;
width:100%;
/* gap:1.25rem;
padding: 1.25rem; */
`;
export const MatchesAfterSubmissionContainer =styled.div`
display:flex;
justify-content:space-between;
width:103%;
gap:1.25rem;
/* background-color:red; */
`;

export const MatchesAfterSubmissionCols =styled.div`
display:flex;
width:47%;
gap:0.62rem;
flex-direction:column;

`;

export const MatchesAfterSubmissionLabel = styled.div<Styled>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 2.8125rem;
flex-shrink: 0;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  opacity: ${props => props.opacity || "1"};
  padding:0.87rem 1.25rem;
`;

export const MatchesAfterSubmissionSmallLine =styled.div`
display:flex;
align-self:flex-end;
height:100%;
width: 1px;
background-color: rgba(87, 99, 113, 0.60);
`;