import styled, { css } from "styled-components";
import { breakpoints, theme } from "../../../../theme";
import CheckIcon from "../../../../assets/icons/Group2199.svg";
import { BorderRadius } from "html2canvas/dist/types/css/property-descriptors/border-radius";
export interface SeletectOptinToogleProps {
  borderRadius?: string; // Define the borderRadius prop
  contentDisplay?: string;
  color?: string;
  bgColor?: string;
  theme?: string;
  borderColor?: string;
  language?: string | null;
}
export const FilerPopUpOverlay = styled.div<{ isvisible: string }>`
  display: ${(props) => (props.isvisible ? "block" : "none")};
  opacity: ${(props) => (props.isvisible ? "1" : "0")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 300vh;
  transition: opacity 500ms;
  overflow: auto;
  z-index: 10;
  background: rgba(0, 0, 0, 0.4);
`;
export const PopupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  color: white;
  height: 100vh;

  @media screen and (max-width: 700px) {
    width: 70%;
  }
`;
export const PopupContentContainer = styled.div<{ bgColor?: string }>`
  background: ${(props) => props.bgColor || "#384450"};
  max-width: 27rem;
  &:dir(ltr) {
    padding: 1.5rem 1.75rem 1.25rem 2.5rem;
    border-radius: 0rem 0.625rem 0.625rem 0rem;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 0.2rem;
      background: #5dd3b3;
      border-radius: 1.25rem;
    }
  }
  &:dir(rtl) {
    padding: 1.5rem 2.5rem 1.25rem 1.75rem;
    border-radius: 0.625rem 0rem 0rem 0.625rem;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 0.2rem;
      background: #5dd3b3;
      border-radius: 1.25rem;
    }
  }
  position: relative;
  display: flex;
  flex-direction: column;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0.2rem;
    background: #5dd3b3;
    border-radius: 1.25rem;
  }
`;
export const BtnsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const ButtonContent = styled.button<{
  backgroundColor?: string;
  bordercolor?: string;
}>`
  width: 8.8125rem;
  height: 2.125rem;
  font-size: 0.875rem;
  border-radius: 1.25rem;

  color: #5dd3b3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5rem;
  cursor: pointer;
  font-family: "Primary Font-Bold";

  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
`;

export const CheckboxsContainer = styled.div`
  &:dir(ltr) {
    margin-left: 1.45rem;
  }
  &:dir(rtl) {
    margin-right: 1.45rem;
  }
`;
export const CheckboxLabel = styled.label<SeletectOptinToogleProps>`
  display: flex;
  /* align-items: center; */
  color: ${(props) => props.color};

  cursor: pointer;
  &::before {
    content: "";
    width: 1em;
    min-width: 1em;
    max-width: 1em;
    height: 1em;
    min-height: 1em;
    max-height: 1em;
    position: absolute;
    [dir = "ltr"] & {
      left: -1.5rem;
      right: 0;
    }
    [dir = "rtl"] & {
      left: 0;
      right: -1.5rem;
    }

    /* border: 0.05em solid #fff;
    border-radius: 3px; */
    &:dir(ltr) {
      margin-right: 0.5em;
    }
    &:dir(rtl) {
      margin-left: 0.5em;
    }
    background: ${(props) => props.bgColor};

    border: 1px solid ${(props) => props.borderColor};
    border-radius: 3px;
    @media (max-width: ${breakpoints.tablets}) {
      margin-top: 0.2rem;
      &:dir(ltr) {
        margin-right: 0.6em;
      }
      &:dir(rtl) {
        margin-left: 0.6em;
      }
    }
  }
  &:hover:before {
    /* background-color: #35e07d; */
    /* border: 0.05em solid #5dd3b3; */
    /* background-image: linear-gradient(to bottom, #5dd3b3, #2f3b40);
    transition: background-image 0.3s ease-in; */
    /*   background-color:red; */
  }
`;
export const SeletectOptinToggle = styled.input<SeletectOptinToogleProps>`
  width: 2rem;
  height: 2rem;

  &:dir(ltr) {
    margin-right: 1rem;
  }
  &:dir(rtl) {
    margin-left: 1rem;
  }
  /* border: 1px solid
    ${(props) => (props.theme === "light" ? "#AAB1B9" : "#5dd3b3")}; */
  /* background-color: aqua; */
  /* color: black; */

  cursor: pointer;
  opacity: 0;
  position: absolute;
  transform: translate(-50%, -50%);

  background: ${(props) => props.bgColor};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 3px;
  &:hover + ${CheckboxLabel} ::before {
    background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
    transition: background 0.3s ease;
  }

  &:checked + ${CheckboxLabel}::before {
    background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
    /* background-color: red; */
    display: flex;

    justify-content: center;
    align-items: center;
    color: #fff;
    border: none;
    border-radius: 3px;
    background-image: url(${CheckIcon});
  }

  :focus + ${CheckboxLabel}::before {
    background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
    /* border: 0.05em solid #5dd3b3; */
  }
`;

export const PopupHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  & > :last-child:hover {
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
`;
