import React from "react";
import {
  CheckBoxAfterCorrectionLine,
  CheckBoxAfterCorrectionSquare,
} from "./CheckBoxAfterCorrection.style";
import { TypographyRegularDescription } from "../../../../elements/fonts/Fonts";
interface CheckBoxAfterCorrectionInterface {
  answer: any;
  IsCorrect: any;
}
const CheckBoxAfterCorrection = ({
  answer,
  IsCorrect,
}: CheckBoxAfterCorrectionInterface) => {
  const Answer = answer.choice || answer.answer;

  const TrueAnswer = true;
  return (
    <CheckBoxAfterCorrectionLine>
      <CheckBoxAfterCorrectionSquare
        Content={true}
        BKCOLOR={
          IsCorrect === TrueAnswer
            ? "linear-gradient(to right, #5dd3b3, #2f3b40)"
            : "linear-gradient(to right, #9B3D44, #D85D66)"
        }
        Border={IsCorrect === TrueAnswer ? " #5dd3b3" : "#D85D66"}
      />
      <TypographyRegularDescription
        text={answer}
        fontSize="1rem"
        lineHeight="1.375rem"
      />
    </CheckBoxAfterCorrectionLine>
  );
};

export default CheckBoxAfterCorrection;
