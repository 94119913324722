import styled from "styled-components";
import { breakpoints } from "./theme";
export interface StyleProps {
  padding?: string;
  margin?: string;
  gap?: string;
  wrap?: boolean;
  width?: string;
  height?: string;
  pointer?: boolean;
  column?: boolean;
  alignItems?: string;
  justifyContent?: string;
  backgroundColor?: string;
  border?: string;
  boxShadow?: string;
  opacity?: string;
  ImgSize?: string;
  position?: string;
  alignSelf?: string;
  borderRadius?: string;
  borderColor?: string;
  color?: string;
}

interface ApplayoutProps {
  contenttype: string;
  overlaybgcolor: string;
  backgroundcolor: string;
  color: string;
  includescoursecreator: string;
  pathnamestate: string;
}
export const AppLayout = styled.div<ApplayoutProps>`
  /* background: ${({
    contenttype,
    overlaybgcolor,
    backgroundcolor,
    includescoursecreator,
  }) => backgroundcolor}; */
  color: ${(props) => props.color};
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  overflow-x: hidden;
  overflow-y: hidden;

  @media (max-width: calc(${breakpoints.mobile} + 25px)) {
    overflow-x: hidden;
    overflow-y: hidden;
  }
`;
export const PageheaderLayout = styled.div`
  min-height: 5rem;
  padding: 1.25rem 1.5rem 0 1.5rem;
`;
export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  @media (max-width: ${breakpoints.extraLarge}) {
    grid-template-columns: 1fr 12fr;
  }
  @media (max-width: (${breakpoints.mobile} + 25px)) {
    display: block;
  }
`;

export const SidebarContainer = styled.div`
  grid-column: 1/2;
  width: 5.625rem;

  /* max-width: 22rem; */
  height: fit-content;
  /* height: 49.5rem; */
  @media (max-width: calc(${breakpoints.mobile} + 25px)) {
    display: none;
  }
  @media (min-width: calc(${breakpoints.extraLarge} )) {
    width: 22rem !important;
  }
`;
export const ContentContainer = styled.div`
  grid-column: 2/4;
  padding: 0 1.5rem;
  position: relative;
  padding: 0 1.5rem;
  @media (max-width: calc(${breakpoints.mobile} + 25px)) {
    grid-column: 1/5;
    /* overflow-x: hidden; */
  }
`;
export const CourseViewContentContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  /* padding: 0 1.5rem; */
  row-gap: 1.25rem;
`;
export const MainViewContentContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  /* padding: 0 1.5rem; */
  row-gap: 1.25rem;
`;
export const MessagesContainer = styled.div`
  /*height: 85vh;*/
  grid-column: 4/5;

  width: 20rem;
`;

export const LandingMainContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  /* padding: 0 1.5rem; */
  row-gap: 1.25rem;
  background-color: #fff;
  color: #2d3741;
  position: relative;
  padding: 1.9rem 2.5rem;
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 607px;
`;

///////////////////////////////////////
export const FlexAppContainer = styled.div<StyleProps>`
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "fit-content"};
  padding: ${({ padding }) => padding || "0"};
  margin: ${({ margin }) => margin || "0"};
  gap: ${({ gap }) => gap || "0"};
  position: ${({ position }) => position || "relative"};
  /* position: relative; */
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};
  cursor: ${({ pointer }) => (pointer ? "pointer" : "auto")};
  align-items: ${({ alignItems }) => alignItems || "stretch"};
  justify-content: ${({ justifyContent }) => justifyContent || "normal"};
  background: ${({ backgroundColor, theme }) =>
    backgroundColor
      ? backgroundColor
      : //  theme.dark.bg_main_color
        "transparent"};

  border: ${({ border }) => border || "none"};
  box-shadow: ${({ boxShadow }) => boxShadow || `none`};
  opacity: ${({ opacity }) => opacity || "1"};
  align-self: ${({ alignSelf }) => alignSelf || "start"};
  border-radius: ${({ borderRadius }) => borderRadius || "0"};
`;

export const NormalProfileImgAPP = styled.img<StyleProps>`
  border-radius: 50%;
  object-fit: cover;
  width: ${({ ImgSize }) => ImgSize || "3.125rem"};
  height: ${({ ImgSize }) => ImgSize || "3.125rem"};
`;

export const NormalTextInput = styled.input<StyleProps>`
  border-radius: ${({ borderRadius }) => borderRadius || "2.8125rem"};
  /* border: 1px solid #576371; */
  height: ${({ height }) => height || "2.8125rem"};
  width: ${({ width }) => width || "100%"};
  border: ${({ border }) => border || "1px solid #576371"};
  border-color: ${({ borderColor }) => borderColor || ""};
  padding: ${({ padding }) => padding || "0.94rem 1.25rem"};
  background-color: transparent;
  color: ${({ color }) => color || "#fff"};
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &:focus {
    outline: none;
    color: ${({ color }) => color || "#fff"};
  }
  &::placeholder {
    opacity: 0.5;
  }
`;
