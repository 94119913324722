import React, { FC } from "react";
import OpenMenu from "../OpenMenu/OpenMenu";
import SearchAndFilter from "../../GraderSearchAndFilter/main/SearchAndFilter";
import Comments from "../../GraderComments/main/Comments";
import NavBarIcons from "../NavBarIcons/NavBarIcons";
import { BlackLine, SideToolsContainer } from "./SideTools.style";
import { SideToolsProps } from "./SideToolsInterface";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../../recoil/ThemeSelectors";
const SideTools: FC<SideToolsProps> = ({
  showComment,
  showSearch,
  setShowSearch,
  setShowComment,
  handleOpenComment,
  handleOpenSearch,
  setShowRubric,
  showRating,
  showSubmitedTime,
  showNavIcon,
  showLate,
  student,
  studentIndex,
  updateSubmittedByInstructor,
  allAssignment,
  resourceR,
}) => {
  const { backgroundColor } = useRecoilValue(ColorSelector);
  return (
    <SideToolsContainer bgcolor={backgroundColor}>
      {showNavIcon ? (
        <>
          {/* <NavBarIcons
            handleOpenComment={handleOpenComment}
            handleOpenSearch={handleOpenSearch}
          /> */}
          {/* <BlackLine /> */}
        </>
      ) : (
        ""
      )}
      {showComment ? (
        <Comments
          setShowComment={setShowComment}
          setShowRubric={setShowRubric}
          showRating={showRating}
          showSubmitedTime={showSubmitedTime}
          showLate={showLate}
          student={student}
          studentIndex={studentIndex}
          allAssignment={allAssignment}
          resourceR={resourceR}
          // updateSubmittedByInstructor={updateSubmittedByInstructor}
        />
      ) : showSearch ? (
        <SearchAndFilter />
      ) : (
        <OpenMenu setShowComment={setShowComment} />
      )}
    </SideToolsContainer>
  );
};

export default SideTools;
