import styled from "styled-components";

export const CourseGeneralTracingContainer =styled.div`
display: flex;
width:100%;
justify-content:space-between;
align-items:center;
/* min-height:6rem; */
/* background-color:brown; */
margin-bottom:0.4rem;
gap:1rem;
`;

export const CourseGeneralTracingTextContainer=styled.div`
display: flex;
justify-content: space-between;
gap:0.5rem;
/* background-color:aqua; */
width:76%;
padding:0 0.7rem;
`;

