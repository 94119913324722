import styled from "styled-components";

export const ButtonFilledWrapper = styled.button`
  width: max-content;
  height: auto;
  border-radius: 0.3125rem;
  border: 1px solid #5dd3b3;
  border-radius: 0.3125rem;
  background: transparent;
  color: #5dd3b3;
  padding: 0.44rem 1.19rem 0.44rem 1.25rem;
  cursor: pointer;
  &:focus {
    background: #5dd3b3;
    color: #fff;
  }
`;
