import styled from "styled-components";
import { breakpoints } from "../../theme";

export const DataProfileMainCols = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.88rem;
`;

export const HeightSizeA = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  /* background-color: red; */
`;

export const HeightSize = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 1rem; */
  &:nth-child(1) {
    /* background-color: black; */
    width: 20rem;
  }
  &:nth-child(2) {
    /* background-color: purple; */
    color: #5dd3b3;

    width: 100%;
  }
  &:nth-child(3) {
    /* background-color:purple; */
    /* margin-top: 1rem; */
  }
`;

export const GroupA = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  /* background: pink; */
  /* margin-bottom: 1rem; */
  /* @media (max-width: ${breakpoints.tablets}) {
    flex-direction: column;
    margin-top: -10rem;
    margin-bottom: 4rem;
    align-items: center;
  } */
`;

export const DataProfileMiniCols = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 1.25rem;
  /* margin-top: 1rem !important ; */
`;

export const DataProfileRenderCols = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.62rem;
`;
export const DataProfileMiniRow = styled.div`
  display: flex;
  width: 13rem;
  /* margin-bottom: 0.5rem; */
  /* background-color:red */

  /* @media (max-width: ${breakpoints.tablets}) {
    margin-bottom: 0.8rem;
    margin-top: 1rem;
    margin-bottom: -0rem;
  } */
`;
