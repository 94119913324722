import axios from "axios";
import { S3Get } from "../../../services/S3Get";

export const fetchData = async (teacherId: string, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    setLoading(true);
    const tenantName = localStorage.getItem("tenant");

    try {
        const response = await axios.get(
            `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/user/profilePicture/${tenantName}/${teacherId}`
        );
        const fetchedData = response.data.data;
        setLoading(false);
        return { fetchedData, errorMessage: "" };
    } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
        return { data: null, errorMessage: "Error fetching data. Please try again." };
    } finally {
        setLoading(false);
    }
};
export const fetchImage = async (bgImage: string, setS3ImageUrl: React.Dispatch<React.SetStateAction<string>>, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    setLoading(true);
    if (bgImage) {
        try {
            const s3IMage = await S3Get(bgImage);
            if (s3IMage !== "") {
                setS3ImageUrl(s3IMage);
                return s3IMage
            }
            return ""
        } catch {
            console.log("error in loading card image");
        } finally {
            setLoading(false);
        }

    }
};