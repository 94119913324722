import React from "react";
import { SvgNoData } from "../../elements/Icons";
import { LightTypography, Typography } from "../../elements/fonts/Fonts";
import CancelButton from "../../elements/StyledButton/CancelButton";
import { Container, SvgCont, TextCont } from "./NoQuestionBanksData.style";
import { useTranslation } from "react-i18next";

const NoQuestionBanksData = ({ onClickFunction }: any) => {
  const { t } = useTranslation()
  return (
    <Container>
      <SvgCont>
        <SvgNoData />
      </SvgCont>
      <TextCont>
        <Typography text={t("question bank.There are currently no question in this bank")} fontSize="1.375rem" />
        <LightTypography text={t("question bank.You can import question banks from other courses and resources Select the button below to begin")} />
      </TextCont>
      <CancelButton name={t("question bank.Create Question")} onClickFunction={onClickFunction} width="13.4375rem" backgroundColor="#5DD3B3" color="#fff" />
    </Container>
  );
};

export default NoQuestionBanksData;
