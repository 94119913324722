import * as XLSX from "xlsx";

// Function to handle Excel download
export const ExcelDownload = (data: any[], tableName: string) => {
  const flattenedData: any[] = [];

  data.forEach((item) => {
    if (item.students && Array.isArray(item.students)) {
      item.students.forEach(
        (student: {
          StdCode: any;
          StdName: any;
          TimeSpent: any;
          Answered: any;
          Grade: any;
        }) => {
          flattenedData.push({
            QuestionCode: item.QuestionCode,
            QuestionTitle: item.QuestionTitle,
            QuestionType: item.QuestionType,
            points: item.points,
            StudentID: student.StdCode,
            StudentName: student.StdName,
            TimeSpent: student.TimeSpent,
            Answered: student.Answered,
            Grade: student.Grade,
          });
        }
      );
    }
  });

  const workSheet = XLSX.utils.json_to_sheet(flattenedData);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, tableName);

  // Downloading the Excel file
  XLSX.writeFile(workBook, `${tableName}.xlsx`);
};

/**
 * Filters an array of objects based on a search text within a specific range of indices.
 * @param {any[]} data - The array of objects to be filtered.
 * @param {string} searchText - The text to be searched within the object's properties.
 * @param {number} startIndex - The start index for slicing the filtered data.
 * @param {number} endIndex - The end index for slicing the filtered data.
 * @returns {any[]} - The filtered array of objects.
 */
export const filterDataBySearchText = (
  data: any[],
  searchText: string,
  startIndex: number,
  endIndex: number
): any[] => {
  return data
    .map((item) => {
      const filteredStudents = (item.students || [])
        .filter((student: { StdName: string }) =>
          student?.StdName?.toLowerCase().includes(searchText.toLowerCase())
        )
        .slice(startIndex, endIndex);

      // Include the main object only if there are matching students
      if (filteredStudents.length > 0) {
        return {
          ...item,
          students: filteredStudents,
        };
      }
      return null; // Exclude objects without matching students
    })
    .filter(Boolean);
};
