import React from 'react';
import { useDrag } from 'react-dnd';
import {GradeBox} from './DraggableGradeBox.style'; // Import your styled component

interface DraggableGradeBoxProps{
    text:string
    children?: React.ReactNode; 
    gIndex:number;
    index:number
}

const DraggableGradeBox = ({ text,gIndex,index }:DraggableGradeBoxProps) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'GRADE_BOX', // Specify the type of draggable item
    item: { type: 'GRADE_BOX',data:{name:text,gIndex:gIndex,index:index} },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <GradeBox
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
    >
      {text}
    </GradeBox>
  );
};

export default DraggableGradeBox;
