import React, { FC, useState, useEffect } from "react";
import MainToolBar from "../surveyToolBar/MainToolBar";
import {
  Container,
  BodyContainer,
  ToolBarContainer,
  QuizButtonContainer,
  FlexDiv,
  FlexDiv2,
  ScrollabaleContainer,
} from "./GenerateSurvey.style";
import CreateQuestions from "../SurveyQuestionCard/main/CreateQuestions";
import Button from "../../elements/QuizButton/Button";
import {
  Question,
  Category,
  Template,
  Choice,
} from "./generateSurveyDataInterface";
import { GenerateQuizProps } from "./generateSurveyInterface";
import { ReactComponent as CategoryIcon } from "../../assets/icons/menu_2 (1).svg";
import DropQuestion from "../SurveyCreatorDropQuestion/DropQuestion";
import QuestionCRUD from "../SurveyCreatorQuestionCRUD/main/QuestionCRUD";
import CancelButton from "../../elements/CancelButton/CancelButton";
import { ReactComponent as Essay } from "../../assets/icons/paper_1_text.svg";
import { ReactComponent as TrueFalse } from "../../assets/icons/Group-1157.svg";
import { ReactComponent as MultipleIcon } from "../../assets/icons/multiple.svg";
import { SvgProgress, SvgRating } from "../../elements/Icons";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../utils/StringManipulation";
import { post, get } from "../../utils/AxiosRequests";
import { useMyContext } from "../../contexts/CourseCreatorContext";
import {
  filteringQuestion,
  handleDropQuestions,
  setQuestionTypesCount,
} from "./generateSurveyUtils";
import { handleValidation } from "./validationFunction";
import PopupRenderer from "../GenerateQuiz/main/PopupRenderer";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

const GenerateSurvey: FC<GenerateQuizProps> = ({
  setStart,
  setQuestion,
  setMultipleChoice,
  setEssay,
  setYesNo,
  setScale,
  setRating,
  handleCancelQuiz
}) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showCategoryPopup, setShowCategoryPopup] = useState<boolean>(false);
  const [showLoadTemplate, setShowshowLoadTemplate] = useState<boolean>(false);
  const [showQuest, setShowQuest] = useState<boolean>(false);
  const [editQuest, setEditQuest] = useState<boolean>(false);
  const [choiceIndex, setChoiceIndex] = useState<number>(0);
  const [deleteQuestion, setDeleteQuestion] = useState<boolean>(false);
  const [deleteChoice, setDeleteChoice] = useState<boolean>(false);
  const [deletePart, setDeletePart] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [partIndex, setPartIndex] = useState<number>(0);
  const [quest, setQuest] = useState<Question>();
  const [choice, setChoice] = useState<Choice[]>([]);
  const [showPreviewPopup, setShowPreviewPopup] = useState<boolean>(false);
  const [selectedRandomQuestion, setSelectedRandomQuestion] =
    useState<boolean>(false);
  const [selectedRandomOption, setSelectedRandomOption] =
    useState<boolean>(false);
  const [disableClick, setDisableClick] = useState(true);
  const [qindexValue, setQindexValue] = useState<number>(0);
  const [notValidate, setNotValidate] = useState<boolean>(true);
  const [templateName, setTemplateName] = useState<string>("");
  const [checkTemplateName, setCheckTemplateName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  // const [isShuffled, setIsShuffled] = useState<boolean>(false);
  const params = useParams();
  const classId = params.classId;
  const { user } = useAuth0();
  const tenantName = localStorage.getItem("tenant");
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [isModified, setIsModified] = useState(false);
  const [originalState, setOriginalState] = useState<Question[]>([]);
  const [questionError, setQuestionError] = useState("");
  const [typeError, setTypeError] = useState("");
  const { t } = useTranslation();
  // use the context of the survey.
  const {
    globalShowPopup,
    setGlobalShowPopup,
    editSurvey,
    globalselectedSurveyId,
    setEditSurvey,
    setGlobalSelectedSurvey,
    setGlobalSelectedSurveyId
  } = useMyContext();
  const [questions, setQuestions] = useState<Question[]>([
    {
      // id: 1,
      name: "",
      question: "",
      questType: "normal",
      type: "",
      icon: <></>,
      questPart: [],
      branching: false,
      choices: [] as {
        name: string;
      }[],
      subId: subIdSplitted,
      classSourceId: classId,
    },
  ]);

  // Fetch The Data Only In The Edit Mode
  useEffect(() => {
    const addIconsToQuestions = (questions:any) => {
      return questions.map((question:any) => {
        const updatedQuestion = { ...question };
  
        switch (question.type) {
          case "ESSAY":
            updatedQuestion.icon = <Essay />;
            break;
          case "RATE":
            updatedQuestion.icon = <SvgRating />;
            break;
          case "SCALE":
            updatedQuestion.icon = <SvgProgress />;
            break;
          case "MCQ":
          case "MRQ":
            updatedQuestion.icon = <MultipleIcon />;
            break;
          case "BINARY":
            updatedQuestion.icon = <TrueFalse />;
            break;
          default:
            break;
        }
        return updatedQuestion;
      });
    };
    const fetchData = async () => {
      setLoadingData(true);
      const response = await get(
        `survey/questions/template/${globalselectedSurveyId}/${tenantName}`
      );
      const storedQuestions = response.data.questions;
      setTemplateName(response.data.title);
      setCheckTemplateName(response.data.title);
      // Store the Name of the template array in local storage as surveyTemplateName
      localStorage.setItem("surveyTemplateName", JSON.stringify(response.data.title));
      if (storedQuestions && storedQuestions.length > 0) {
        // Loop through parsedQuestions and add icon for each type
        const updatedQuestions = storedQuestions.map((question: any) => {
          let updatedQuestion = { ...question };
          if (question.type === "ESSAY") {
            updatedQuestion.icon = <Essay />;
            updatedQuestion.index = question.questionIndex;
            updatedQuestion.questType = "normal";
            updatedQuestion.questPart = [];
            updatedQuestion.choices = [{ name: "" }];
          } else if (question.type === "RATE") {
            updatedQuestion.icon = <SvgRating />;
            updatedQuestion.index = question.questionIndex;
            updatedQuestion.questType = "normal";
            updatedQuestion.questPart = [];
            updatedQuestion.choices = [{ value: 0 }];
          } else if (question.type === "SCALE") {
            updatedQuestion.icon = <SvgProgress />;
            updatedQuestion.index = question.questionIndex;
            updatedQuestion.questType = "normal";
            updatedQuestion.choices = [{ name: "" }];
            updatedQuestion.questPart = [];
          } else if (question.type === "MCQ" || question.type === "MRQ") {
            updatedQuestion.icon = <MultipleIcon />;
            updatedQuestion.index = question.questionIndex;
            updatedQuestion.questType = "normal";
            updatedQuestion.type = "MCQ";
            updatedQuestion.questPart = [];
            updatedQuestion.choices =
              question &&
              question.choices &&
              question.choices.map((choice: any, index: number) => ({
                // index: index + 1,
                name: choice.choice,
                // percentage: choice.percentage,
              }));
          } else if (question.type === "BINARY") {
            updatedQuestion.icon = <TrueFalse />;
            updatedQuestion.index = question.questionIndex;
            updatedQuestion.questType = "normal";
            updatedQuestion.questPart = [];
            updatedQuestion.choices = [{ name: "" }];
          }
          // Check if the question type is 'composed' and add icons to questPart
          if (question.questType === "composed") {
            const questPartWithIcons = question.questPart.map((part: any) => {
              let updatedPart = { ...part };
              // Add icons for each part based on their type
              if (part.type === "ESSAY") {
                updatedPart.icon = <Essay />;
              } else if (part.type === "RATE") {
                updatedPart.icon = <SvgRating />;
              } else if (part.type === "SCALE") {
                updatedPart.icon = <SvgProgress />;
              } else if (part.type === "MCQ") {
                updatedPart.icon = <MultipleIcon />;
              } else if (part.type === "BINARY") {
                updatedPart.icon = <TrueFalse />;
              }
              return updatedPart;
            });
            updatedQuestion.questPart = questPartWithIcons;
          }
          return updatedQuestion;
        });
        // Remove the `icon` key from the updated questions and their parts
      const cleanedQuestions = updatedQuestions.map((question: any) => {
        const { icon, ...cleanedQuestion } = question;
        return cleanedQuestion;
      });
        setLoadingData(false);
        setQuestions(updatedQuestions);
        // Store the cleaned questions array in local storage as surveyTemplate
        localStorage.setItem("surveyTemplate", JSON.stringify(cleanedQuestions));
        setShowQuest(true);
        setDisableClick(false);
        setNotValidate(false);
      }
    };
    if (editSurvey && globalselectedSurveyId) {
      fetchData();
    }
    // Get the Template the survey from local storage
    const localSurveyTemplate = localStorage.getItem("surveyTemplate");
    // Get the name of the template from local storage
    const localSurveyTemplateName = localStorage.getItem("surveyTemplateName");
    if(localSurveyTemplateName){
      setCheckTemplateName(JSON.parse(localSurveyTemplateName));
      setTemplateName(JSON.parse(localSurveyTemplateName))
    }
    
    if (localSurveyTemplate) {
      const parsedTemplate = JSON.parse(localSurveyTemplate);
      if (parsedTemplate.length > 0) { // add the icon of each question
        setQuestions(addIconsToQuestions(parsedTemplate));
        setShowQuest(true)
        setEditQuest(false);
        setNotValidate(false);
        setDisableClick(false);
      }
    }
  }, []);

  useEffect(() => {
    handleSingleQuestAndChoice();
    // Handle The Statistics Of The Survey Creator
    setQuestion(setQuestionTypesCount(questions).totalQuestions);
    setEssay(setQuestionTypesCount(questions).essayCount);
    setMultipleChoice(setQuestionTypesCount(questions).multipleChoiceCount);
    setYesNo(setQuestionTypesCount(questions).yesNoCount);
    setScale(setQuestionTypesCount(questions).scaleCount);
    setRating(setQuestionTypesCount(questions).ratingCount);
    setGlobalShowPopup(true);
  }, [questions, index, partIndex]);

  // Handle Save The Question
  const onSave = () => {
    // Validation Before Saving The Question
    const error = handleValidation(
      questions,
      quest,
      index,
      partIndex,
      choice,
      qindexValue
    );
    switch (error) {
      case "Please select a question type for the question.":
      case "Please select a question type for the question part.":
      case "This field is mandatory":
      case "Duplicate ratings are not allowed.":
      case "Duplicate scale names are not allowed.":
      case "First two choices' names cannot be empty for MCQ questions.":
      case "Duplicate values for Yes/No are not allowed.":
      case "Select a Type for the branch":
      case "The Choices of Multiple Questions is mandatory":
      case "The name of branches is mandatory":
      case "The rating value must be between 1 and 5":
      case "The scale name cannot be empty.":
        setDisableClick(true);
        setQuestionError(error);
        return;
      default:
        break;
    }
    const nonEmptyChoices = choice.filter((c) => {
      if (quest && quest.type === "MCQ") {
        return c.name?.trim() !== "";
      }
      return choice;
    });
    setQuestionError("");
    setTypeError("");
    let newQuestions = [...questions];
    if (newQuestions[index].questType === "normal") {
      if (quest) {
        newQuestions[index] = quest;
        newQuestions[index].choices = nonEmptyChoices;
      }
    } else if (newQuestions[index].questType === "composed") {
      if (quest) {
        newQuestions[index].questPart[partIndex] = quest;
        let newChoices = newQuestions[index].questPart[partIndex];
        newChoices.choices = nonEmptyChoices;
      }
    }
    const filteredQuestions = newQuestions.filter(
      (question) => question.type !== ""
    );
    // console.log("All Questions :" , newQuestions);
    setQuestions(filteredQuestions);
    setShowQuest(true);
    setEditQuest(false);
    setDisableClick(false);
    setNotValidate(false);
  };

  // Emptying The Branch
  const emptyBranch = (qIndex: number) => {
    const updatedChoices = [...choice];
    if (updatedChoices[qIndex] && updatedChoices[qIndex].branch) {
      updatedChoices[qIndex].branch = {}; // Emptying the branch object
    }
    setChoice(updatedChoices); // Assuming `setAllChoices` is the function used to update state
  };

  const handleQIndexFromChild = (qindexValue: number) => {
    setQindexValue(qindexValue);
  };

  // This Function Is Very Important For The Functionality of The Creator
  const handleSingleQuestAndChoice = () => {
    if (questions[index]?.questType === "normal") {
      setQuest(questions[index]);
      setChoice(questions[index]?.choices || []);
    } else if (questions[index]?.questType === "composed") {
      setQuest(questions[index].questPart[partIndex]);
      setChoice(questions[index]?.questPart[partIndex]?.choices || []);
    }
  };

  const handleRandomQuestion = () => {
    setSelectedRandomQuestion(!selectedRandomQuestion);
  };
  const handleTemplateName = (value: string) => {
    setTemplateName(value);
  };
  const handleRandomOptions = () => {
    setSelectedRandomOption(!selectedRandomOption);
  };

  // Add More Questions
  const addMoreQuestion = (type: string) => {
    if (quest?.type !== "" && notValidate) {
      setQuestionError("Save Before add a question");
      return;
    }
    if (
      questions[index]?.questType === "composed" &&
      questions[index]?.questPart.length === 1 &&
      quest?.type !== ""
    ) {
      setQuestionError("Complex questions must have at least 2 parts.");
      setTypeError("");
      return;
    }
    let updateQuestions = questions;
    if (type === "normal") {
      let newQuestionField = {
        question: "",
        type: "",
        name: "",
        questPart: [],
        questType: type,
        icon: <></>,
        choices: [],
        subId: subIdSplitted,
        provider:provider,
        classSourceId: classId,
      };
      updateQuestions.push(newQuestionField);
      setIndex(questions.length - 1);
      setShowQuest(false);
    } else if (type === "composed") {
      const questPart = updateQuestions[index]?.questPart || [];
      let newQuestionField = {
        name: "",
        question: "",
        questType: type,
        grade: "",
        icon: <></>,
        questPart: [],
        choices: [],
        feedRight: "",
        feedWrong: "",
        subId: subIdSplitted,
        provider:provider,
        classSourceId: classId,
      };
      let newQuestionFieldPart = {
        question: "",
        name: "",
        type: "",
        questType: "",
        questPart: [],
        grade: "",
        icon: <></>,
        choices: [],
        feedRight: "",
        feedWrong: "",
        subId: subIdSplitted,
        provider:provider,
        classSourceId: classId,
      };
      updateQuestions.push(newQuestionField);
      updateQuestions[index + 1]?.questPart?.push(newQuestionFieldPart);
      setIndex(questions.length - 1);
      setPartIndex(0);
      setShowQuest(false);
    }
    setQuestions(updateQuestions);
    if (
      updateQuestions[index]?.type === "" ||
      updateQuestions[index]?.questPart[partIndex]?.type === ""
    ) {
      let deleteQuest = [...questions];
      deleteQuest.splice(index, 1);
      setIndex(index);
      setQuestions(deleteQuest);
      return;
    }
    setEditQuest(false);
  };

  // Add More Parts To Questions
  const addMorePartQuestions = (index: number) => {
    if (quest?.type !== "" && notValidate) {
      setQuestionError("Save Before add a question");
      return;
    }
    let updateQuestions = [...questions];
    const questPart = updateQuestions[index]?.questPart || [];
    const questId = questPart.length + 1;
    if (updateQuestions[index]?.questPart[partIndex]?.type === "") {
      return;
    }
    let newQuestionFieldPart = {
      question: "",
      name: "",
      questType: "",
      questPart: [],
      type: "",
      grade: "",
      icon: <></>,
      choices: [],
    };
    updateQuestions[index].questPart.push(newQuestionFieldPart);
    setQuestions(updateQuestions);
    setIndex(index);
    setPartIndex(questId - 1);
    setShowQuest(false);
    setDisableClick(true);
  };

  // This is the Drop Function.
  const DropQuestionType = (data: any) => {
    setQuestionError("");
    setTypeError("");
    setDisableClick(true);
    setShowQuest(false);
    if (quest?.type !== "") {
      setNotValidate(false);
    } else {
      setNotValidate(true);
    }
    const updatedQuestions = handleDropQuestions(
      questions,
      index,
      partIndex,
      data
    );
    setQuestions(updatedQuestions);
  };

  // Remove Questions
  const removeQuestion = () => {
    let questionField = [...questions];
    if (index === 0 && questionField.length === 1) {
      if (questionField[index].questType === "normal") {
        questionField[index].type = "";
        questionField[index].icon = <></>;
        questionField[index].name = "";
      } else if (questionField[index].questType === "composed") {
        questionField[index].questPart = [
          {
            question: "",
            name: "",
            type: "",
            questType: "",
            questPart: [],
            icon: <></>,
            choices: [],
          },
        ];
      }
      setIndex(0);
      setPartIndex(0);
    }
    if (index > 0) {
      questionField.splice(index, 1);
      setIndex(index - 1);
    }
    if (index === 0 && questionField.length > 1) {
      questionField.splice(index, 1);
      setIndex(0);
    }
    setShowQuest(true);
    setQuestions(questionField);
  };

  // Remove Parts From Questions
  const removePartQuestion = () => {
    let questionField = [...questions];
    if (partIndex > 0) {
      questionField[index].questPart.splice(partIndex, 1);
      setPartIndex(partIndex - 1);
    }
    if (partIndex === 0 && questionField[index].questPart.length === 1) {
      questionField[index].questPart.splice(partIndex, 1);
      questionField[index].questPart = [
        {
          question: "",
          name: "",
          type: "",
          questType: "",
          questPart: [],
          icon: <></>,
          choices: [],
        },
      ];
      setPartIndex(0);
    }
    if (partIndex === 0 && questionField[index].questPart.length > 1) {
      questionField[index].questPart.splice(partIndex, 1);
      setPartIndex(0);
    }
    setShowQuest(true);
    setQuestions(questionField);
  };

  // Add Choice Field To Different Types of Questions
  const addChoice = () => {
    if (quest?.type === "BINARY" && choice.length < 2) {
      const newChoice = {
        isChecked: false,
        branch: {},
      };
      setChoice([...choice, newChoice]);
    } else if (quest?.type !== "BINARY" && choice.length < 5) {
      let newChoice = {};
      if (quest?.type === "MCQ") {
        newChoice = {
          name: "",
          branch: {},
        };
      } else if (quest?.type === "RATE") {
        newChoice = {
          value: 0,
          branch: {},
        };
      } else if (quest?.type === "SCALE") {
        newChoice = {
          name: "",
          branch: {},
        };
      } else {
        newChoice = {
          right: "",
          left: "",
          name: "",
          percentage: "",
          errorMargin: "",
        };
      }
      setChoice([...choice, newChoice]);
    }
  };

  // Remove The CHoice Field
  const removeChoice = () => {
    let choiceField = [...choice];
    choiceField.splice(choiceIndex, 1);
    setChoice(choiceField);
  };

  // Set The Choice Field
  const handleChoiceMatchingQuestion = (
    cIndex: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let newChoices = [...(choice || [])];
    if (e.target.id === "left") {
      newChoices[cIndex].left = e.target.value;
    } else if (e.target.id === "right") {
      newChoices[cIndex].right = e.target.value;
    } else if (e.target.id === "percentage") {
      newChoices[cIndex].percentage = e.target.value;
    } else if (e.target.id === "name") {
      newChoices[cIndex].name = e.target.value;
    } else if (e.target.id === "error-margin") {
      newChoices[cIndex].errorMargin = e.target.value;
    }
    setChoice(newChoices);
  };

  // Set The Multiple Choice For The Branch
  const handleChoiceMultipleQuestionForBranching = (
    cIndex: number,
    branchChoiceIndex: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let newChoices = [...(choice ?? [])];
    if (newChoices[cIndex] && newChoices[cIndex].branch) {
      const selectedChoice =
        newChoices[cIndex]?.branch?.choices?.[branchChoiceIndex];
      if (selectedChoice) {
        selectedChoice.name = e.target.value;
      }
    }
    setChoice(newChoices);
  };

  // Keep The First Choice Field For The Rating, Scale, and Binary question.
  const handleChoiceChecked = (checked: any) => {
    if (quest) {
      setQuest({ ...quest, branching: checked });
      // If the quest type is "Rating" or "Scale" or "Yes-No" and branching is set to false
      if (
        (quest.type === "RATE" ||
          quest.type === "SCALE" ||
          quest.type === "BINARY") &&
        !checked
      ) {
        // Keep the first choice and remove the rest
        const updatedChoices = choice.slice(0, 1); // Keep the first choice
        setChoice(updatedChoices);
      }
    }
  };

  // Change The isChecked filed for the binary Question
  const handleTrueOrFalseChecked = (cIndex: number, checked: any) => {
    const updatedChoice = [...(choice || [])];
    updatedChoice[cIndex].isChecked = checked;
    setChoice(updatedChoice);
  };

  // set The question property. (The question that shows to the user to answer)
  const handleQuillChange = (value: string) => {
    if (!quest) {
      return;
    }
    const QuestionField = { ...quest };
    if (QuestionField) {
      QuestionField.question = value;
    }
    setQuest(QuestionField);
  };

  // set the name of the branch
  const changeBranchName = (cIndex: number, newName: string) => {
    if (choice && choice[cIndex] && choice[cIndex].branch) {
      // Update the branch name
      choice[cIndex].branch!.name = newName;
    }
  };

  // put the user in a view mode, he cannot change or edit the question.
  const handleShowQuest = () => {
    setChoice([...choice]);
    let newQuestion = [...questions];
    setQuestions(newQuestion);
    setShowQuest(true);
    setEditQuest(false);
    setQuest(quest);
  };

  // put the user in a edit mode.
  const handleShowEditQuest = () => {
    setShowQuest(false);
    setEditQuest(true);
  };

  // handle cancel button
  const handleCancel = () => {
    if (isModified && !editQuest) {
      // Restore the original state without the last added question
      setQuestions((prevQuestions) => prevQuestions.slice(0, -1));
      // Reset indices and errors
      setIndex((prevIndex) => prevIndex - 1); // Adjust the index based on the removed question
      setPartIndex(0);
      setQuestionError("");
      setTypeError("");
      // Reset modified flag
      setIsModified(false);
      // Show the original state
      setShowQuest(true);
      setDisableClick(false);
      setNotValidate(false);
    } else if (isModified && editQuest) {
      setPartIndex(0);
      setQuestionError("");
      setTypeError("");
      // Reset modified flag
      setIsModified(false);
      // Show the original state
      setShowQuest(true);
      setNotValidate(false);
    }
  };

  useEffect(() => {
    // Update the original state when the questions are modified
    if (!isModified) {
      setOriginalState([...questions]);
      setIsModified(true);
    }
  }, [questions, isModified]);

  // Save the Template with the template name, and the questions.
  const SaveSurvey = async () => {
    if (templateName.trim() === "") {
      setNameError(t("quiz.Template Name Is Mandatory"));
      return;
    }
    if (templateName.trim() === checkTemplateName) {
      setNameError(t("quiz.Duplicate Name"));
      return;
    }
    try{
      // Call the filtering questions function
      const filteredQuestions = filteringQuestion(questions);
      const Template = {
        name: templateName,
        description: "",
        questions: filteredQuestions,
        subId: subIdSplitted,
        provider:provider,
        classSourceId: classId,
      };
      setLoading(true);
      const response = await post(`survey/create/question/${tenantName}`, Template);
      setGlobalSelectedSurvey(templateName);
      setGlobalSelectedSurveyId(response.data)
      setLoading(false);
      setGlobalShowPopup(true);
      // Delete the quizTemplate array from local storage
      localStorage.removeItem("surveyTemplate");
      // Delete the name of the template
      localStorage.removeItem("surveyTemplateName");
      navigate(`/course-creator/${classId}`);
      setNameError("");
      setEditSurvey(false);
    }catch(error){
      console.log(error)
    }
    
  };


  const { Third_BgColor, boxShadow, theme,Container_Gradient_BgColor } = useRecoilValue(ColorSelector);
  return (
    <Container bgcolor={Third_BgColor} boxshadow={boxShadow} theme={theme}>
      <BodyContainer>
        <ToolBarContainer>
          <MainToolBar />
        </ToolBarContainer>
        {(questions[index]?.type === "" && !questions[index]?.questPart) ||
        (!questions[index]?.type &&
          !questions[index]?.questPart[partIndex]?.type) ? (
          <DropQuestion
            addMoreQuestion={addMoreQuestion}
            loadingData={loadingData}
            questions={questions}
            DropQuestionType={DropQuestionType}
          />
        ) : (
          <FlexDiv>
            <ScrollabaleContainer isBranched={quest?.branching} style={{boxShadow:boxShadow,background:Container_Gradient_BgColor}}>
              <QuestionCRUD
                questions={questions}
                index={index}
                partIndex={partIndex}
                addChoice={addChoice}
                setDeleteChoice={setDeleteChoice}
                setChoiceIndex={setChoiceIndex}
                handleChoiceMatchingQuestion={handleChoiceMatchingQuestion}
                handleChoiceMultipleQuestionForBranching={
                  handleChoiceMultipleQuestionForBranching
                }
                // handleQuestion={handleQuestion}
                handleQuillChange={handleQuillChange}
                choice={choice}
                quest={quest}
                handleShowEditQuest={handleShowEditQuest}
                showQuest={showQuest}
                setShowPreviewPopup={setShowPreviewPopup}
                handleChoiceChecked={handleChoiceChecked}
                questionError={questionError}
                typeError={typeError}
                // FillBranch={FillBranch}
                changeBranchName={changeBranchName}
                handleTrueOrFalseChecked={handleTrueOrFalseChecked}
                handleQIndexFromChild={handleQIndexFromChild}
                emptyBranch={emptyBranch}
                // handleShuffle={handleShuffle}
                // isShuffled={isShuffled}
              />
            </ScrollabaleContainer>
            {(questions[index]?.type === "" && !questions[index]?.questPart) ||
            (!questions[index]?.type &&
              !questions[index].questPart[partIndex]?.type) ? (
              ""
            ) : showQuest ? (
              ""
            ) : (
              <QuizButtonContainer>
                <CancelButton
                  onClickFunction={() => {
                    handleCancel();
                    setQuestionError("");
                    setTypeError("");
                  }}
                  name={t("forms.Cancel")}
                  color="#D85D66"
                  borderColor="#D85D66"
                  width="8.8125rem"
                />
                <CancelButton
                  onClickFunction={() => onSave()}
                  name={t("forms.Save")}
                  color="#fff"
                  backgroundColor="#5DD3B3"
                  borderColor="#5DD3B3"
                  width="8.8125rem"
                />
              </QuizButtonContainer>
            )}
          </FlexDiv>
        )}
        <FlexDiv>
          <CreateQuestions
            questions={questions}
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            setDeleteQuestion={setDeleteQuestion}
            setShowCategoryPopup={setShowCategoryPopup}
            addMoreQuestion={addMoreQuestion}
            setShowshowLoadTemplate={setShowshowLoadTemplate}
            setIndex={setIndex}
            addMorePartQuestions={addMorePartQuestions}
            setPartIndex={setPartIndex}
            handleShowQuest={handleShowQuest}
            handleShowEditQuest={handleShowEditQuest}
            setDeletePart={setDeletePart}
            handleRandomQuestion={handleRandomQuestion}
            selectedRandomQuestion={selectedRandomQuestion}
            selectedRandomOption={selectedRandomOption}
            handleRandomOptions={handleRandomOptions}
            disableClick={disableClick}
            nameError={nameError}
            handleTemplateName={handleTemplateName}
            templateName={templateName}
            showQuest={showQuest}
          />
          <FlexDiv2>
            <CancelButton
              onClickFunction={() => {
                handleCancelQuiz();
              }}
              name={t("forms.Cancel")}
              color="#D85D66"
              borderColor="#D85D66"
              width="8.8125rem"
            />
            {questions.length > 0 &&
              quest?.type !== "" &&
              notValidate === false &&
              (loading ? (
                <CancelButton
                  onClickFunction={() => ""}
                  name="Loading ..."
                  width="8.8125rem"
                  color="#fff"
                  backgroundColor="#5DD3B3"
                  borderColor="#5DD3B3"
                />
              ) : (
                <CancelButton
                  onClickFunction={() => SaveSurvey()}
                  name={t("forms.Save")}
                  color="#fff"
                  backgroundColor="#5DD3B3"
                  borderColor="#5DD3B3"
                  width="8.8125rem"
                />
              ))}
          </FlexDiv2>
        </FlexDiv>
      </BodyContainer>
      <PopupRenderer
        showCategoryPopup={showCategoryPopup}
        showLoadTemplate={showLoadTemplate}
        showPreviewPopup={showPreviewPopup}
        deleteChoice={deleteChoice}
        deleteQuestion={deleteQuestion}
        deletePart={deletePart}
        quest={quest}
        index={index}
        setShowPreviewPopup={setShowPreviewPopup}
        setDeleteChoice={setDeleteChoice}
        setDeletePart={setDeletePart}
        setDeleteQuestion={setDeleteQuestion}
        removeChoice={removeChoice}
        removePartQuestion={removePartQuestion}
        removeQuestion={removeQuestion}
      />
    </Container>
  );
};

export default GenerateSurvey;
