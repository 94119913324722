import React from "react";
import { AnalyticsPageStylesLayout } from "../../AnalyticsPageStyles";
import AnalyticsBackPage from "../../../../components/AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticsBackPage";
import ForumDetails from "../../../../components/Tables/ForumDetails/ForumDetails";
import { useNavigate } from "react-router-dom";

const AnalyticsForumDefaultTable = () => {
  const navigate = useNavigate();
  const NavigatePage = () => {
    navigate(-1);
  };
  return (
    <AnalyticsPageStylesLayout>
      <AnalyticsBackPage onClick={NavigatePage} />
      {/* <AssignmentDefaultTable /> */}
      <ForumDetails />
    </AnalyticsPageStylesLayout>
  );
};

export default AnalyticsForumDefaultTable;
