import React from "react";
import {
  ZidyiaLogoBox,
  FrameLogo,
  FrameLogoWrapper,
  LogoZidyia,
} from "./ZidyiaLogo.style";

export const ZidyiaLogo = (): JSX.Element => {
  return (
    <ZidyiaLogoBox>
      <FrameLogoWrapper>
        <FrameLogo>
          <LogoZidyia src="/assets/images/zidyia-logo.png" alt="Logo zidyia" />
        </FrameLogo>
      </FrameLogoWrapper>
    </ZidyiaLogoBox>
  );
};
