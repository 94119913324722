// AssignmentSubmissionTableCompo.tsx

import React, { useEffect, useRef, useState } from "react";
import {
  ComplexSelectBodyTable,
  ComplexSelectTable,
  ComplexSelectTableBodyRow,
  ComplexSelectTableHeaderRow,
  SurveyComplexProfileTableContainer,
} from "../AnalyticsAllAssigment.style";
import { AssignmentSubmissionCell } from "./AssignmentSubmissionDetails.style";
import {
  CheckboxLabel,
  SeletectOptinToogle,
} from "../../ProfileCheckBoxOptions/NotificationSetting.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../../elements/fonts/Fonts";
import {
  AssignmentSubmissionTableHeaderData,
  TableHeader,
} from "./AssignmentSubmissionHeaderData";
import { PointerContainer } from "../SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableTable.style";
import { SvgMore } from "../../../elements/Icons";

import { StudentCompletionTracingIMG } from "../../StudentCompletionTracing/StudentCompletionTracing.style";
import { formatDate } from "../../../utils/FormatedDATE";
import { AnalyticsForumSvgs } from "../../Tables/AnalyticsForum/AnalyticsForum.style";
import { contentSvgGenerator } from "../../../utils/ContentTypeGenerator";
import { SubmissionDateColor } from "../../../utils/Function";
import Each from "../../../utils/Each";

import {
  ForumAbsoluteContainer,
  ForumAbsoluteDateContainer,
} from "../ForumParticipationDetails/ForumParticipationDetailsTable/ForumParticipationDetailsTable.style";
import DateBoxPopUp from "../../../elements/DateBoxPopUp/DateBoxPopUp";
import useClickOutside from "../../../hooks/useClickOutside";
import AanlyticsMenueThreeOptions from "./AanlyticsMenueThreeOptions";
import DateIndexClickOutSide from "../../../hooks/DateIndexClickOutSide";
import { AnalyticsStudentsInterface } from "../../../interfaces/AnalyticsCommonInterfaces";
import {
  handleItemToggle,
  handleSelectAllToggle,
} from "../../../hooks/TableSelectedOptions";
import { handleDateChangeState } from "../../../utils/AnalyticsProperties";
import { FlexAppContainer } from "../../../App.style";
import {
  handleChangeShapeToggle,
  updateMessageText,
} from "../../StudentCompletionTrackingInstrcuterView/functions";
import { InstructorMessageBox } from "../../StudentCompletionTrackingInstrcuterView/InstructorViewCompletion.style";
import StudentMessage from "../../StudentCompletionTrackingInstrcuterView/StudentMessage";
import { SelectedStudentsInterface } from "../../InstructorAnalyticsUsersView/InstructorAnalyticsUsersViewTable/InstructorAnalyticsUsersViewTable";
/**
 * AssignmentSubmissionTableCompo Component
 *
 * This component renders a table displaying forum participation details.
 * It provides options for filtering, selecting items, and managing due/cutoff dates.
 *
 * @param {Array} filteredData - The array containing filtered participation details data.
 * @returns {JSX.Element} The JSX representation of the AssignmentSubmissionTableCompo component.
 */
interface AssignmentSubmissionTableCompoProps {
  filteredData: any[];
  AssignmentDueDate?: Date;
  AssignmentCutOffEDate?: Date;
}
const AssignmentSubmissionTableCompo = ({
  filteredData,
  AssignmentDueDate,
  AssignmentCutOffEDate,
}: AssignmentSubmissionTableCompoProps) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(
    null
  );

  const menuRef = useRef<HTMLDivElement>(null);
  const dateBoxRef = useRef<HTMLDivElement>(null);
  const Today = new Date();
  const [shapeDueDateToggles, setShapeDueDateToggles] = useState<
    { isOpen: boolean; selectedDate: Date | null }[]
  >(
    filteredData.map(() => ({
      isOpen: false,
      selectedDate: null,
    }))
  ); //AssignmentSubmissionTableHeaderData
  const [ShapeCutOffDateToggles, setShapeCutOffDateToggles] = useState<
    { isOpen: boolean; selectedDate: Date | null }[]
  >(
    filteredData.map(() => ({
      isOpen: false,
      selectedDate: null,
    }))
  ); //filteredData

  const handleOpenDueDate = (index: number) => {
    handleDateChangeState(
      index,
      setShapeDueDateToggles,
      selectedOptionIndex,
      setSelectedOptionIndex,
      filteredData
    );
  };

  const handleOpenCutOffDate = (index: number) => {
    handleDateChangeState(
      index,
      setShapeCutOffDateToggles,
      selectedOptionIndex,
      setSelectedOptionIndex,
      filteredData
    );
  };
  const clickOutsideRef = DateIndexClickOutSide(
    () => setSelectedOptionIndex(null),
    [menuRef, dateBoxRef]
  );

  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  // Initialize an array to track the checked state of each item
  const [checkedItems, setCheckedItems] = useState<boolean[]>(
    new Array(filteredData.length).fill(false)
  );
  // this useState stored the the selected students in ana array
  const [SelectedStudents, setSelectedStudents] = useState<any[]>([]);

  const [ShowFirstPOP, setShowFirstPOP] = useState<boolean>(false);
  const [ShowMessagesInFirst, setShowMessagesInFirst] =
    useState<boolean>(false);
  const [ShowFirstDueDatePOP, setShowFirstDueDatePOP] =
    useState<boolean>(false);
  const [MainDueDate, setMainDueDate] = useState<Date | null>(null);

  const handleShowShowFirstPOP = () => {
    setShowFirstPOP(!ShowFirstPOP);
  };
  const handleShowShowFirstDueDatePOP = () => {
    setShowFirstPOP(false);
    setShowFirstDueDatePOP(!ShowFirstDueDatePOP);
  };

  const [ShowFirstCutOffDATEPOP, setShowFirstCutOffDATEPOP] =
    useState<boolean>(false);
  const [MainCutOffDATE, setMainCutOffDATE] = useState<Date | null>(null);

  const handleShowFirstCutOffPOP = () => {
    setShowFirstPOP(false);
    setShowFirstCutOffDATEPOP(!ShowFirstCutOffDATEPOP);
  };

  useEffect(() => {
    if (SelectedStudents.length === 0) {
      setShowMessagesInFirst(false);
    } else {
      setShowMessagesInFirst(true);
    }
  }, [SelectedStudents]);

  const MainContainerRef = useClickOutside(ShowFirstPOP, () =>
    setShowFirstPOP(false)
  );

  const handleOptionButtonClick = (index: number) => {
    setShowFirstPOP(false);
    setShowFirstCutOffDATEPOP(false);
    setShowFirstDueDatePOP(false);
    setSelectedOptionIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const [shapeToggles, setShapeToggles] = useState<Array<boolean>>(
    Array(filteredData.length).fill(true)
  );

  const [messageTexts, setMessageTexts] = useState<{ [id: string]: string }>(
    {}
  );
  const [ErrormessageTexts, setErrorMessageTexts] = useState<{
    [id: string]: string;
  }>({});

  const handleMessageSend = (index: number, id: string) => {
    if (messageTexts[id] === undefined || messageTexts[id].trim() === "") {
      // If messageTexts[id] is undefined or empty, set the errorMessageTexts for the specific id
      setErrorMessageTexts((prevState) => ({
        ...prevState,
        [id]: "This field is required.",
      }));
      return;
    }

    const Data = {
      id: id,
      // MessageValue: messageSubjects,
      // File: FileStorageId,
      MessageText: messageTexts,
    };
  };

  const [ShowDisplayMessagesForm, setShowDisplayMessagesForm] =
    useState<boolean>(false);
  useState<string>("");
  const [GroupSelectedMessageTexts, setGroupSelectedMessageTexts] =
    useState<string>("");
  const [GroupSelectedErrorMessageTexts, setGroupSelectedErrorMessageTexts] =
    useState<string>("");
  const handleSelectedGroupMessageSend = () => {
    if (GroupSelectedMessageTexts.trim() === "") {
      setGroupSelectedErrorMessageTexts("This field is required.");
      return false;
    }

    const Data = {
      // MessageValue: GroupSelectedMessageSubjects,
      MessageText: GroupSelectedMessageTexts,
      // File: GroupSelectedFile,
    };

    setGroupSelectedMessageTexts("");
  };

  const handleShowMessagePOP = () => {
    setShowDisplayMessagesForm(!ShowDisplayMessagesForm);
  };
  const SelectedStudentsData: SelectedStudentsInterface[] =
    SelectedStudents &&
    SelectedStudents.map((item) => ({
      id: item.id,
      FullName: `${item.Name}`, // Corrected syntax
      email: item.Email, // Changed 'items' to 'item' and corrected capitalization
    }));
  return (
    <ComplexSelectTable>
      <ComplexSelectTableHeaderRow>
        <AssignmentSubmissionCell>
          <SeletectOptinToogle
            type="checkbox"
            id="selectAll" // Add optional chaining to handle potential undefined
            value="selectAll" // Add optional chaining to handle potential undefined
            checked={selectAllChecked} // Control the checked state of the "Select All" checkbox
            onChange={() =>
              handleSelectAllToggle(
                setSelectAllChecked,
                setCheckedItems,
                filteredData,
                setSelectedStudents
              )
            }
          />
          <CheckboxLabel htmlFor="selectAll">
            <MediumTypography
              text="Student"
              fontSize="1rem"
              lineHeight="1.25rem"
            />
          </CheckboxLabel>
        </AssignmentSubmissionCell>

        <Each
          of={AssignmentSubmissionTableHeaderData}
          render={(item: TableHeader, index: number) => (
            <AssignmentSubmissionCell key={index}>
              <MediumTypography
                text={item.name}
                fontSize="1rem"
                lineHeight="1.25rem"
              />
            </AssignmentSubmissionCell>
          )}
        />
        <AssignmentSubmissionCell ref={MainContainerRef}>
          <div style={{ marginRight: "-0.5rem" }}>
            <MediumTypography
              text="Grade"
              fontSize="1rem"
              lineHeight="1.25rem"
            />
          </div>

          <PointerContainer onClick={handleShowShowFirstPOP}>
            <SvgMore style={{ transform: "rotate(90deg)" }} />
          </PointerContainer>
          {ShowFirstPOP && (
            <ForumAbsoluteContainer>
              <AanlyticsMenueThreeOptions
                handleSaveCutOffDate={handleShowFirstCutOffPOP}
                handleSaveDueDate={handleShowShowFirstDueDatePOP}
                handleSendMessage={handleShowMessagePOP}
                ShowMessageLabel={ShowMessagesInFirst}
              />
            </ForumAbsoluteContainer>
          )}
          {ShowFirstDueDatePOP && (
            <ForumAbsoluteDateContainer>
              <DateBoxPopUp
                TextForAxios={"ForumExtendDueDate"}
                TitleHeader="Extend Due Date"
                DataBaseStartDate={AssignmentDueDate || Today}
                selectedDate={MainDueDate || null}
                setSelectedDate={setMainDueDate}
                setShowDateBox={setShowFirstDueDatePOP}
                ShowDateBox={ShowFirstDueDatePOP}
              />
            </ForumAbsoluteDateContainer>
          )}
          {ShowDisplayMessagesForm && (
            <InstructorMessageBox
              ref={clickOutsideRef}
              style={{ right: "1.5rem", top: "-2rem", opacity: "1" }}
            >
              <StudentMessage
                CloseClick={handleShowMessagePOP}
                OneSelected={false}
                SelectedData={SelectedStudentsData}
                SendMessage={handleSelectedGroupMessageSend}
                MessageValue={GroupSelectedMessageTexts}
                setMessageValue={(value: React.SetStateAction<string>) =>
                  setGroupSelectedMessageTexts(value)
                }
                ErrorMessageValue={GroupSelectedErrorMessageTexts}
                setErrorMessageValue={setGroupSelectedErrorMessageTexts}
              />
            </InstructorMessageBox>
          )}
          {ShowFirstCutOffDATEPOP && (
            <ForumAbsoluteDateContainer>
              <DateBoxPopUp
                TextForAxios={"ForumExtendOffDate"}
                TitleHeader="Extend Cut Off Date"
                DataBaseStartDate={AssignmentCutOffEDate || Today}
                selectedDate={MainCutOffDATE || null}
                setSelectedDate={setMainCutOffDATE}
                setShowDateBox={setShowFirstCutOffDATEPOP}
                ShowDateBox={ShowFirstCutOffDATEPOP}
              />
            </ForumAbsoluteDateContainer>
          )}
        </AssignmentSubmissionCell>
      </ComplexSelectTableHeaderRow>
      <ComplexSelectBodyTable>
        <Each
          of={filteredData}
          render={(item: AnalyticsStudentsInterface, index: number) => (
            <ComplexSelectTableBodyRow key={index}>
              <AssignmentSubmissionCell>
                <SeletectOptinToogle
                  type="checkbox"
                  id={item.StdCode?.toString()} // Add optional chaining to handle potential undefined
                  value={item.StdCode?.toString()} // Add optional chaining to handle potential undefined
                  checked={checkedItems[index]}
                  onChange={() =>
                    handleItemToggle(
                      index,
                      checkedItems,
                      setCheckedItems,
                      setSelectedStudents,
                      filteredData
                    )
                  }
                />
                <CheckboxLabel
                  htmlFor={item.StdCode}
                  style={{ marginTop: "1rem" }}
                >
                  <SurveyComplexProfileTableContainer>
                    <StudentCompletionTracingIMG
                      src={
                        item.ProfilePic
                          ? item.ProfilePic
                          : require("../../../assets/userProfile.png")
                      }
                      alt="no img"
                    />
                    <Typography text={item.Name || ""} fontSize="0.875rem" />
                  </SurveyComplexProfileTableContainer>
                </CheckboxLabel>
              </AssignmentSubmissionCell>
              <AssignmentSubmissionCell>
                <LightTypography
                  text={item.Status || "UnSubmitted"}
                  fontSize="0.75rem"
                />
              </AssignmentSubmissionCell>
              <AssignmentSubmissionCell>
                <LightTypography
                  text={formatDate(item.SubmissionDate as Date)}
                  fontSize="0.75rem"
                  color={SubmissionDateColor(item.SubmissionDate, item.DueDate)}
                />
              </AssignmentSubmissionCell>
              <AssignmentSubmissionCell>
                <LightTypography
                  text={formatDate(item.LastModified as Date)}
                  fontSize="0.75rem"
                />
              </AssignmentSubmissionCell>
              <AssignmentSubmissionCell>
                {item.SubmissionFiles &&
                  item.SubmissionFiles.map((Files, indexes) => (
                    <AnalyticsForumSvgs key={indexes}>
                      {contentSvgGenerator(
                        Files.File,
                        <LightTypography text="None" fontSize="0.75rem" />
                      )}
                    </AnalyticsForumSvgs>
                  ))}
              </AssignmentSubmissionCell>
              <AssignmentSubmissionCell>
                <LightTypography
                  text={item.ApplyPenalty || "No"}
                  fontSize="0.75rem"
                />
              </AssignmentSubmissionCell>
              <AssignmentSubmissionCell ref={clickOutsideRef}>
                <LightTypography
                  text={item.Grade !== undefined ? `${item.Grade}/10` : "--/10"}
                  fontSize="0.75rem"
                />
                <PointerContainer
                  onClick={() => handleOptionButtonClick(index)}
                >
                  <SvgMore style={{ transform: "rotate(90deg)" }} />
                </PointerContainer>
                {selectedOptionIndex === index && (
                  <ForumAbsoluteContainer ref={menuRef}>
                    {shapeToggles[index] ? (
                      <div ref={clickOutsideRef}>
                        <AanlyticsMenueThreeOptions
                          handleSaveCutOffDate={() =>
                            handleOpenCutOffDate(index)
                          }
                          handleSaveDueDate={() => handleOpenDueDate(index)}
                          handleSendMessage={() =>
                            handleChangeShapeToggle(
                              index,
                              shapeToggles,
                              setShapeToggles
                            )
                          }
                        />
                      </div>
                    ) : shapeToggles[index] === false ? (
                      <InstructorMessageBox
                        ref={clickOutsideRef}
                        style={{ right: "1.5rem", top: "-2rem" }}
                      >
                        <StudentMessage
                          CloseClick={() =>
                            handleChangeShapeToggle(
                              index,
                              shapeToggles,
                              setShapeToggles
                            )
                          }
                          ReserverName={item.Name}
                          SendMessage={() =>
                            handleMessageSend(index, item.StdCode)
                          }
                          MessageValue={messageTexts[item.StdCode] || ""}
                          setMessageValue={(value: string) =>
                            updateMessageText(
                              item.StdCode,
                              value,
                              setMessageTexts
                            )
                          } // Update the value for the specific id
                          ErrorMessageValue={
                            ErrormessageTexts[item.StdCode] || ""
                          }
                          setErrorMessageValue={(value: string) =>
                            updateMessageText(
                              item.StdCode,
                              value,
                              setErrorMessageTexts
                            )
                          }
                        />
                      </InstructorMessageBox>
                    ) : null}
                  </ForumAbsoluteContainer>
                )}
                {selectedOptionIndex === index &&
                shapeDueDateToggles[index].isOpen ? (
                  <ForumAbsoluteContainer ref={menuRef}>
                    <ForumAbsoluteDateContainer>
                      <DateBoxPopUp
                        IdForAxios={item.StdCode}
                        TextForAxios={"ForumExtendDueDate"}
                        TitleHeader="Extend Due Date"
                        DataBaseStartDate={AssignmentDueDate || Today}
                        ShowDateBox={shapeDueDateToggles[index].isOpen}
                        selectedDate={shapeDueDateToggles[index].selectedDate}
                        setShowDateBox={(value) => {
                          setShapeDueDateToggles((prevToggles) => {
                            const updatedToggles = [...prevToggles];
                            updatedToggles[index] = {
                              isOpen:
                                typeof value === "function"
                                  ? value(prevToggles[index].isOpen)
                                  : value,
                              selectedDate: prevToggles[index].selectedDate,
                            };
                            return updatedToggles;
                          });
                        }}
                        setSelectedDate={(date: any) => {
                          setShapeDueDateToggles((prevToggles) => {
                            const updatedToggles = [...prevToggles];
                            updatedToggles[index] = {
                              isOpen: prevToggles[index].isOpen,
                              selectedDate: date,
                            };
                            return updatedToggles;
                          });
                        }}
                      />
                    </ForumAbsoluteDateContainer>
                  </ForumAbsoluteContainer>
                ) : null}
                {selectedOptionIndex === index &&
                ShapeCutOffDateToggles[index].isOpen ? (
                  <div ref={menuRef}>
                    <ForumAbsoluteDateContainer>
                      <DateBoxPopUp
                        IdForAxios={item.StdCode}
                        DataBaseStartDate={AssignmentCutOffEDate || Today}
                        TextForAxios={"ForumCutOffDate"}
                        TitleHeader="Cut Off Date"
                        ShowDateBox={ShapeCutOffDateToggles[index].isOpen}
                        setShowDateBox={(value) => {
                          setShapeCutOffDateToggles((prevToggles) => {
                            const updatedToggles = [...prevToggles];
                            updatedToggles[index] = {
                              isOpen:
                                typeof value === "function"
                                  ? value(prevToggles[index].isOpen)
                                  : value,
                              selectedDate: prevToggles[index].selectedDate,
                            };
                            return updatedToggles;
                          });
                        }}
                        selectedDate={
                          ShapeCutOffDateToggles[index].selectedDate
                        }
                        setSelectedDate={(date: any) => {
                          setShapeCutOffDateToggles((prevToggles) => {
                            const updatedToggles = [...prevToggles];
                            updatedToggles[index] = {
                              isOpen: prevToggles[index].isOpen,
                              selectedDate: date,
                            };
                            return updatedToggles;
                          });
                        }}
                      />
                    </ForumAbsoluteDateContainer>
                  </div>
                ) : null}
              </AssignmentSubmissionCell>
            </ComplexSelectTableBodyRow>
          )}
        />
      </ComplexSelectBodyTable>
    </ComplexSelectTable>
  );
};

export default AssignmentSubmissionTableCompo;
