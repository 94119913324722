import React, { ReactNode, Children } from "react";

interface EachProps {
  of: any[];
  render: (item: any, index: number) => ReactNode;
}

const Each = ({ of, render }: EachProps) => (
  <>{Children.toArray(of.map((item, index) => render(item, index)))}</>
);

export default Each;
