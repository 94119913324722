import React, { FC } from "react";
import { FeedBackContainer, FeedBackInput } from "./QuestionCRUDFeedback.style";
import { MediumTypography } from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import { QuestionCRUDFeedbackProps } from "./QuestionCRUDFeedbackInterface";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
const QuestionCRUDFeedback: FC<QuestionCRUDFeedbackProps> = ({
  handleQuestion,
  quest,
  showQuest,
}) => {
  const { t } = useTranslation();
  const {
    themeTextColor,
    SecondaryTextColor,
    QuizCreator_ValidatesInput_bgColor,
    bghelp,
    readOnlyBgColor,
    inputBorderColor
  } = useRecoilValue(ColorSelector);
  return (
    <>
      {quest?.type === "ESSAY" ? (
        ""
      ) : (
        <FeedBackContainer>
          <div
            style={{
              display: "flex",
              flex: "1",
              flexDirection: "column",
              height: "5rem",
            }}
          >
            <MediumTypography
              text={t("quiz.Feedback if right")}
              fontSize="0.875rem"
              lineHeight="1.375rem"
              color={themeTextColor}
            />
            <FeedBackInput
              color={SecondaryTextColor}
              show={showQuest.toString()}
              id="feed-right"
              value={quest?.feedRight}
              type="text"
              placeholder={t("quiz.Write your feedback if right")}
              onChange={handleQuestion}
              readOnly={showQuest ? true : false}
              Validated_BgColor={readOnlyBgColor}
              borderColor={inputBorderColor}
            />
          </div>
          <div
            style={{
              display: "flex",
              flex: "1",
              flexDirection: "column",
              height: "5rem",
            }}
          >
            <MediumTypography
              text={t("quiz.Feedback if wrong")}
              fontSize="0.875rem"
              lineHeight="1.375rem"
              color={themeTextColor}
            />
            <FeedBackInput
              color={SecondaryTextColor}
              show={showQuest.toString()}
              id="feed-wrong"
              value={quest?.feedWrong}
              type="text"
              placeholder={t("quiz.Write your feedback if wrong")}
              onChange={handleQuestion}
              readOnly={showQuest ? true : false}
              Validated_BgColor={readOnlyBgColor}
              borderColor={inputBorderColor}
            />
          </div>
        </FeedBackContainer>
      )}
    </>
  );
};

export default QuestionCRUDFeedback;
