import {
  ProfileNameEmailHolder,
  NameEmailHolder,
} from "./ProfileNameEmail.style";
import { ProfileNameEmailInterface } from "./profileNameEmailInterface";
import { FC } from "react";
import ReusableProfile from "../reusableProfile/ReusableProfile";
import {
  PrimaryFontMediumText,
  TypographyRegularDescription,
} from "../fonts/Fonts";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

const ProfileNameEmail: FC<ProfileNameEmailInterface> = ({
  iconPath,
  iconBg,
  top,
  left,
  border,
  width,
  height,
  widthImgHolder,
  heightImgHolder,
  fullName,
  email,
  ImageBox_BgColor,
  userId,
}) => {
  const { SecondaryTextColor, EmailTextColor } = useRecoilValue(ColorSelector);

  return (
    <ProfileNameEmailHolder>
      <ReusableProfile
        iconPath={iconPath}
        iconBg={iconBg}
        top={top}
        left={left}
        border={border}
        width={width}
        height={height}
        widthImgHolder={widthImgHolder}
        heightImgHolder={heightImgHolder}
        ImageBox_BgColor={ImageBox_BgColor}
      />
      <NameEmailHolder>
        <PrimaryFontMediumText text={fullName} fontSize="0.875rem" />
        <TypographyRegularDescription
          text={`ID: ${userId?.toString() || ""}`}
          fontSize="0.75rem"
          color="#AAB1B9"
        />
        <TypographyRegularDescription
          text={email}
          fontSize="0.75rem"
          color={EmailTextColor}
        />
      </NameEmailHolder>
    </ProfileNameEmailHolder>
  );
};

export default ProfileNameEmail;
