import styled from "styled-components";
import { breakpoints } from '../../theme';

export const DragDropWrapper = styled.div`
/* max-width: 61.3125rem; */
width: 100%;
height: 54.0625rem;
min-height: auto;
/* flex-shrink: 0; */
border-radius: 0.625rem;
border: 1px solid #576371;
display: flex;
flex-direction: column;
position: relative;
@media (max-width: ${breakpoints.mobile}) {
  padding-right: 1.12rem;
  padding-left: 1.12rem;
  align-items: center;

  /* max-width: 24.375rem; */
  width: 100%;
height: 52.75rem;
align-items: center;
}
@media (max-width: ${breakpoints.tablets}) {
align-items: center;
}
`
export const LogoAndLabel = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 1.25rem;
`
export const LogoLabelTextColumn = styled.div`
display: flex;
justify-self: center;
flex-direction: column;
justify-content: center;
transform: translateY(15rem);
align-items: center;
gap: 0.63rem;
`
export const TextRow = styled.div`
width: 100%;
  max-width: 21.5rem;
  justify-content: center; 
  text-align: center; 
display: flex;
`
export const TopIconsRow = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
width: auto;
gap: 0.62rem;
padding-right: 1.87rem;
padding-top: 1.88rem;
@media (max-width: ${breakpoints.tablets}) {
justify-content: center;
padding-right: 0rem;
gap: 1.25rem;
}
`
export const GroupsHolder = styled.div`
display: flex;
flex-wrap: wrap;
padding-top: 3.37rem;
padding-left: 1.87rem;
/* justify-content: center; */
column-gap: 2.56rem;
row-gap: 2.5rem;
@media (max-width: ${breakpoints.tablets}) {
  padding-left: 0rem;
justify-content: center;

}

`;
export const ColorPickerLayout = styled.div`
position: absolute;
top: 4.1rem;
right: 3.8rem;
`;