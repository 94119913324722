export const isDateInRange = (
  startDate: Date | null | undefined,
  endDate: Date | null | undefined,
  isVisible: boolean | null | undefined | number
) => {
  if (isVisible === 0) return false;
  else {
    if (!startDate && !endDate) {
      return true;
    }
    const currentDate = new Date();
    const startDateTime = startDate ? new Date(startDate) : null;
    const endDateTime = endDate ? new Date(endDate) : null;

    return (
      (!startDateTime || currentDate >= startDateTime) &&
      (!endDateTime || currentDate <= endDateTime)
    );
  }
};
