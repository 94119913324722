import styled from "styled-components"

export const TextFieldStyled = styled.input`
color: #576474;
width: 20.6875rem;
height: 2.8125rem;
border-radius: 62.5rem;
border: 1px solid rgba(87, 100, 116, 0.20);
background: #FFF;
padding: 0.81rem 1.56rem;
outline: none;


&::placeholder{
    color: #576474;
    opacity: 0.5;
}
`