import styled from "styled-components";
interface ChartsHeadInterface{
  Padding?:string;
  bgColor?: string; 
}
export const ChartsHeadTitleContainer = styled.div<ChartsHeadInterface>`
display: flex;
justify-content:space-between;
width:100%;
padding:${props => props.Padding || "0"};

`;

export const ChartsHeadContainerColumn = styled.div`
display: flex;
flex-direction:column;
justify-content:flex-end;
align-items:flex-end;
gap:1.25rem;

`;

export const ChartsHeadContainerRow = styled.div`
display: flex;
gap:1rem;
align-items:center;
`;

export const ChartsHeadContainerCell = styled.div`
display: flex;
gap:0.50rem;
align-items:center;
`;

export const ChartsHeadContainerCellAction = styled.div`
display: flex;
gap:0.50rem;
cursor:pointer;
transition: color 0.3s ease;
align-items:center;
  
  &:hover{
  
    color: linear-gradient(to bottom, #5dd3b3, #2f3b40);
  }

`;

export const CircleDot = styled.div<ChartsHeadInterface>`
width:0.50rem;
height:0.50rem;
border-radius: 50%;
background-color: ${props => props.bgColor }
`;