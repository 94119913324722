import React, { FC, useState } from "react";
import { PreviewMatchingProps } from "./PreviewMatchingInterface";
import SelectInput from "../../../elements/SelectInput/SelectInput";
import { MediumTypography, Typography } from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import {
  PreviewMatchingContainer,
  LeftChoiceContainer,
} from "./PreviewMatching.style";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";

const PreviewMatching: FC<PreviewMatchingProps> = ({
  choice,
  choiceIndex,
  choices,
  selectedValues,
  onSelectChoice,
}) => {
  const options = (choices || []).map((ch) => ({ name: ch.right }));

  // Find the selected value for the specific choice
  const selectedValueForChoice = selectedValues?.[choiceIndex] || "";

  // Separate state for each dropdown
  const [value, setValue] = useState("");
  // Filter out selected values from options
  const filteredOptions = options.filter(
    (option) => !selectedValues?.includes(option.name || "")
  );
  const handleSelectChoice = (selectedValue: any) => {
    onSelectChoice && onSelectChoice(choiceIndex, selectedValue); // Notify the parent component about the selected value
  };
  const { bghelp } = useRecoilValue(ColorSelector);
  return (
    <PreviewMatchingContainer>
      <div style={{ opacity: "0.8" }}>
        <MediumTypography
          text={`${choiceIndex + 1}`}
          fontSize="0.875rem"
          lineHeight="1.375rem"
        />
      </div>
      <LeftChoiceContainer bordercolor={bghelp}>
        <Typography text={`${choice.left}`} fontSize="0.875rem" />
      </LeftChoiceContainer>
      <SelectInput
        placeholder="Pick your choice"
        options={filteredOptions}
        onChange={handleSelectChoice}
        value={selectedValueForChoice}
      />
    </PreviewMatchingContainer>
  );
};

export default PreviewMatching;
