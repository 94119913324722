import styled from "styled-components";

export const ContentContainer = styled.div`
  padding: 1.88rem 1.5rem;
  /* min-height: 47.6875rem; */
  height: 100%;
  overflow-y: auto;
  justify-content: space-between;
  /* background: red; */
  &:dir(rtl) {
    /* margin-left: -1.5rem; */
  }
  /* max-width: 23rem; */
`;
export const ContentContainerPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.25rem;

  max-width: 23rem;
  /* min-height:26rem; */
`;
export const CancelButton = styled.button`
  background: transparent;
  width: 8.75rem;
  height: 2.1875rem;
  border-radius: 1.25rem;
  color: #d85d66;
  border: 1px solid #d85d66;
  font-family: "Primary Font-Bold";
  cursor: pointer;
`;

export const SubmitButton = styled.button`
  border: none;
  background: #5dd3b3;
  width: 8.75rem;
  height: 2.1875rem;
  border-radius: 1.25rem;
  color: white;
  font-family: "Primary Font-Bold";
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  margin-top: 1.87rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.25rem;
`;
