import { DropDownFilterItem } from "./dropDownFilterInterface";

const DropDownFilterData: DropDownFilterItem[] = [
  {
    title: "Latest",
  },
  {
    title: "Earliest",
  },
  {
    title: "Teachers' only",
  },
  {
    title: "Students' only",
  },
];

export default DropDownFilterData;
