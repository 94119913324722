import React from "react";
import { DateHolderDiv } from "./DateHolder.style";
import TextElement from "../textElement/TextElement";
import { SvgClock } from "../Icons";

interface DateHolderProps {
  date: string;
}

export default function DateHolder({ date }: DateHolderProps) {
  return (
    <DateHolderDiv>
      <SvgClock />
      <TextElement
        tag="p"
        text={date}
        fontSize="0.75rem"
        fontFamily="Primary Font"
        fontWeight="400"
      />
    </DateHolderDiv>
  );
}
