import styled from "styled-components";

export const AnnouncementCardHolder = styled.div`
  position: relative;
`;
export const ButtonsDiv = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  /* margin-top: -0.25rem; */
  /* background: gray; */
  /* margin-top: 0.2rem; */
  /* padding: 0.2rem; */
  /* &:dir(ltr){

  }
  &:dir(rtl){

} */
`;

export const ButtonDiv = styled.div`
  &:dir(ltr) {
    transform: rotate(0deg);
  }
  &:dir(rtl) {
    transform: rotate(180deg);
  }
`;

export const InnerAnnouncementCard = styled.div<{ bgcolor: string }>`
  padding: 1.3rem 1.5rem 1.3rem 1.5rem;
  height: 17.956rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  color: #fff;
  border-radius: 1.25rem;
  /* box-shadow: 0px 3px 6px #00000029; */
  background: ${(props) => props.bgcolor};
`;
export const AnnouncementText = styled.div`
  /* color: ${(props) => props.theme.dark.main_color}; */
`;
export const AnnouncementDesc = styled.div`
  max-height: 40%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5dd3b3;
    border-radius: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #2d3741;
    border-radius: 0.25rem;
  }
`;
export const ImgStyle = styled.img`
  width: 50%;
  height: auto;
`;
export const AnnouncementDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Announcer = styled.div`
  display: flex;
  gap: 0.63rem;
`;
export const AnnouncementFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const NavigationHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;
export const AnnouncementFoot = styled.div`
  width: 90%;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  bottom: 1.6rem;
`;
