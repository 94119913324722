import styled from 'styled-components';

export const BodyContainer = styled.div`
border-radius: 0.625rem;
background: linear-gradient(180deg, #415160 0%, #293440 100%);
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
display: flex;
/* min-height: 37.75rem; */
/* min-width:49.75rem; */
width: 97%;
height: 30rem;
margin: 1rem auto;

@media (max-width: 768px) {
    height: auto;
    width:auto;
  } 
`;


export const TextWrap = styled.div`

display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
width:100%;
 @media (max-width: 760px) {
    height: auto;
    width:auto;
   
  } 
`;

