import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const RubricTabLayout =styled.div<StyleThemeInterface>`
width:100%;
display:flex;
flex-direction:column;
padding:1.25rem;
gap:1.25rem;
min-height:10rem;
border-radius: 1.25rem;
background:${props =>props.bgcolor};
box-shadow: ${props =>props.boxshadow};
`;

export const RubricTitletModal =styled.div`
width:100%;
display:flex;
align-items: center;
gap:0.63rem;
`;


export const RubricTextModal =styled.div`
width:100%;
display:flex;
flex-direction:column;
gap:0.63rem;
`;

export const RubricTextInput =styled.input<StyleThemeInterface>`
width:100%;
padding:0.50rem;
border-radius: 0.313rem;
border: 1px solid ${props =>props.bordercolor};
height: 2rem;
background-color: transparent;
color:${props =>props.color};
font-family: "Primary Font-Light";
&:focus {
    outline: none;
    caret-color: ${props =>props.color};
  }
  &::placeholder{
    color: ${props =>props.color};
    opacity: 0.5;
  }
`;
export const RubricTextAreaInput =styled.textarea<StyleThemeInterface>`
max-width:100%;
min-width:99%;
padding:0.50rem;
border-radius: 0.313rem;
border: 1px solid ${props =>props.bordercolor};
height: 2.8125rem;
background-color: transparent;
color:${props =>props.color};
min-height:6.25rem;
max-height:6.25rem;
resize:none;
font-family: "Primary Font-Light";
&:focus {
    outline: none;
    color:${props =>props.color};
  }
  &::placeholder{
    color: ${props =>props.color};
    opacity: 0.5;
  }
`;

export const RubricRowTextModal =styled.div`
width:100%;
display:flex;
gap:1rem;
align-items:center;
/* background-color:red; */
justify-content:space-between;
`;

export const RubricSmallTextInput =styled.input`
width:6.75rem;
height: 1.813rem;
flex-shrink: 0;
padding:0.50rem;
border-radius: 0.313rem;
border: 1px solid #576371;
/* padding-left:0.9rem; */

background-color: transparent;
font-family: "Primary Font-Light";
text-align:center;
color:#fff;
&:focus {
    outline: none;
    caret-color: ${(props) => props.theme.dark.main_color};
  }
  &::placeholder{
    color: #ffffff80;
  }
`;
export const RubricRowAdditionAndSUB =styled.div`

display:flex;
gap:0.40rem;
align-items:center;
/* background-color:red; */
justify-content:space-between;
`;

export const RubricRow =styled.div`
width:100%;
display:flex;
gap:1rem;
align-items:center;
/* background-color:red; */

`;
export const RubricButtonSpace =styled.div`
padding:0.2rem;
cursor: pointer;
/* color:#FFFFFF; */
/* background-color:red; */

`;

