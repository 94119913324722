import styled from "styled-components"
import { breakpoints } from "../../theme";

export const FaciltyPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.40rem;
  width: 100%;
  min-height: 4rem;
  padding: 1rem;
  margin-top:-4.8rem;
  

  @media screen and (max-width: ${breakpoints.tablets}) {
    width: 17rem !important;
    /* background-color:red !important; */
  }
  @media screen and (max-width: ${breakpoints.mobile}) {
    width: 100% !important;
    /* background-color:aqua !important; */
  }
`;


export const SearchTab = styled.div`
width:100% !important;
margin-bottom:0.40rem;
display:flex;
height:3rem;
margin-top:1rem !important;

`;

export const FaciltyMaps =styled.div`
display:flex;
flex-direction:column;
gap:0.70rem;

`;


export const FaciltyOneLine =styled.div`
width:100%;
display: flex;
  flex-wrap: wrap;

padding-left:1rem;


`;
export const FaciltyMapsLine =styled.div`
flex: 0 0 50%; /* Each item should take up 50% of the container's width */

margin-bottom:0.7rem;



`;

export const RadioLabel = styled.label<{bgColor?:string}>`
  display: flex;
  font-family: "Primary Font-Light";
  /* align-items: center; */
  cursor:pointer;
  &::before{
    content:"";
    width:1em;
    min-width:1em;
    max-width:1em;
    height:1em;
    min-height:1em;
    max-height:1em;
    border: .05em solid #fff;
    border-radius: 50%; 
    [dir = "ltr"] & {
      margin-right:.5em;
    }
    [dir = "rtl"] & {
      margin-left:.5em;
    }
    /* background: linear-gradient(to bottom, #2F353D, #545F6D); */
    background:${props =>props.bgColor};
  
  }
  &:hover:before{
  background-color:#35e07d;
  border: .05em solid #5DD3B3;
  background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
  /*   background-color:red; */
  }

`;


export const SeletectOptinToogle = styled.input`
  width: 1rem;
  height: 1rem;
  /* margin-right: 1rem; */
  border: 1px solid #fff;
  /* background-color: aqua; */
  /* color: black; */
  transition: background-color 0.3s ease;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  &:hover + ${RadioLabel} ::before {
  
    background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
  }

  &:checked + ${RadioLabel}::before {
    background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
    background-color:red;
    display:flex;
    justify-content:center;
    align-items:center;
    color: #fff;;
    border: .05em solid #5DD3B3;

  }

  :focus + ${RadioLabel}::before {
    background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
    border: .05em solid #5DD3B3;
  }
`;

