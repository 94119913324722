import { t } from "i18next";
import { toArabicDigits } from "../../../utils/formatDayArabic";

const isValidDate = (date: any) =>
  date instanceof Date && !isNaN(date.getTime());

export const formatDateTime = (dateTime: any, t: any) => {
  const date = new Date(dateTime);
  return isValidDate(date)
    ? date.toLocaleDateString()
    : t("assignment.No Date");
};

export const formatTime = (dateTime: any, t: any) => {
  const date = new Date(dateTime);
  return isValidDate(date)
    ? date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
    : t("assignment.No Date");
};

// Thsi function format the dates and return the formatted duration of the assignment
export const calculateDuration = (startDateTime: any, cutDateTime: any) => {
  let duration = "";
  const moment = require("moment");
  const currentTime = moment(); // Current time

  if (startDateTime && cutDateTime) {
    const startTime = moment(startDateTime);
    const cutTime = moment(cutDateTime);

    // Check if current time is between startDateTime and cutDateTime
    if (currentTime.isBetween(startTime, cutTime)) {
      const durationM = moment.duration(cutTime.diff(currentTime));

      if (durationM.asMilliseconds() < 0) {
        // Handle invalid case where cutDateTime is in the past
        duration = "";
      } else if (durationM.asMinutes() < 1) {
        // Less than a minute
        duration = t("assignment.Less than a minute remaining");
      } else if (durationM.asHours() < 1) {
        // Less than an hour
        duration = `${durationM.minutes()} ${t(
          "assignment.minutes remaining"
        )}`;
      } else if (durationM.asDays() < 1) {
        // Less than a day
        duration = `${toArabicDigits(durationM.hours().toString())} ${t(
          "to do list.hours"
        )} ${toArabicDigits(durationM.minutes().toString())} ${t(
          "assignment.minutes remaining"
        )}`;
      } else {
        // More than a day
        duration = `${toArabicDigits(durationM.days().toString())} ${t(
          "assignment.days"
        )} ${toArabicDigits(durationM.hours().toString())} ${t(
          "to do list.hours"
        )} ${t("assignment.remaining")}`;
      }
    } else {
      duration = "";
    }
  } else {
    // Handle case where startDateTime or cutDateTime is undefined
    duration = "";
  }
  return duration;
};
// End of Function

// used for time like "12:00 AM"
export const getFormattedTime = (time: string, t?: any): string => {
  // Split time to extract the period (AM/PM)
  const [timePart, period] = time.split(" ");

  // Return the time with the translated period
  return time === ""
    ? time
    : `${toArabicDigits(timePart)} ${t(`notification.${period}`)}`;
};
