import { useRecoilValue } from "recoil";
import { ModalOverlay, ModalContent } from "./ModalOverlay.style";
import React, { ReactNode, MouseEvent } from "react";
import { PopupSelectorColor } from "../../../../recoil/ThemeSelectors";
interface ModalWrapperProps {
  // onClose: (e: MouseEvent) => void;
  children: ReactNode;
}
const ModalWrapper: React.FC<ModalWrapperProps> = ({
  children,
}): JSX.Element => {
  const { PageBgColor, ContentContainer_BgColor } =
    useRecoilValue(PopupSelectorColor);
  return (
    <ModalOverlay bgcolor={"transparent"}>
      {/* <ModalContent style={{ background: ContentContainer_BgColor,boxShadow:"none" }}> */}
        {children}
      {/* </ModalContent> */}
    </ModalOverlay>
  );
};

export default ModalWrapper;
