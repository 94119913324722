import styled from "styled-components";
import { breakpoints } from "../../theme";
import { SeletectOptinToogleProps } from "./SeletOptionTisplaysInterface";
import SvgCheckedIcon from "../../assets/icons/Group2199.svg";

export const CheckboxLabel = styled.label<SeletectOptinToogleProps>`
  display: flex;
  /* align-items: center; */
  cursor: pointer;
  &::before {
    content: "";
    width: 1em;
    min-width: 1em;
    max-width: 1em;
    height: 1em;
    min-height: 1em;
    max-height: 1em;
    border: 0.05em solid #fff;
    border-radius: ${(props) => props.borderRadius || ".15rem"}!important;

    [dir="ltr"] & {
      margin-right: 0.5em;
    }
    [dir="rtl"] & {
      margin-left: 0.5em;
    }

    background: ${(props) =>
      props.bgColor || "linear-gradient(to bottom, #2F353D, #545F6D)"};
    background: ${(props) => props.bgColor};
    border: 1px solid ${(props) => props.borderColor};

    @media (max-width: ${breakpoints.tablets}) {
      margin-top: 0.2rem;
      margin-right: 0.6rem;
    }
  }
  &:hover:before {
    background-color: #35e07d;
    border: 0.05em solid #5dd3b3;
    background: linear-gradient(
      to bottom,
      #5dd3b3,
      ${(props) => props.bgColor || "#2f3b40"}
    );
    /*   background-color:red; */
  }
  font-family: "Primary Font-Medium";
`;

export const OnlyForNoti = styled.div`
  gap: 1.25rem;
  display: flex;
  flex-direction: column;
`;
export const SeletectOptinToogle = styled.input<SeletectOptinToogleProps>`
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  border: 1px solid #fff;
  /* background-color: aqua; */
  /* color: black; */
  transition: background-color 0.3s ease;

  cursor: pointer;
  opacity: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  &:hover + ${CheckboxLabel}::before {
    background: linear-gradient(
      to bottom,
      #5dd3b3,
      ${(props) => props.bgColor || "#2f3b40"}
    );
  }
  &:checked + ${CheckboxLabel}::before {
    /* background: ${({ correct, bgColor }) =>
      correct === "true"
        ? bgColor ||
          "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%), linear-gradient(0deg, #5dd3b3, #5dd3b3)"
        : correct === "false"
        ? "#D85D66"
        : bgColor ||
          "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%), linear-gradient(0deg, #5dd3b3, #5dd3b3)"}; */

    background: ${({ correct, bgColor }) =>
      correct === "true"
        ? "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%), linear-gradient(0deg, #5dd3b3, #5dd3b3)"
        : correct === "false"
        ? "#D85D66"
        : "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%), linear-gradient(0deg, #5dd3b3, #5dd3b3)"};
    border: 1px solid
      ${({ correct }) =>
        correct === "true"
          ? "#5dd3b3"
          : correct === "false"
          ? "#D85D66"
          : "#5dd3b3"};
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* border-radius: ${(props) => props.borderRadius || ".15rem"}; */
    /* color: ${({ correct, contentColor }) =>
      correct === "true"
        ? contentColor || "#fff"
        : correct === "false"
        ? "#D85D66"
        : contentColor || "#fff"}   ;              */
    /* content: "\\2713"; */
    background-image: url(${SvgCheckedIcon});
    background-size: cover; // Or 'cover', 'auto', etc. based on your needs
    background-repeat: no-repeat;
    background-position: center;

    color: #f6f6f6;
    width: 100%;
    height: 100%;
    border-radius: ${(props) => props.borderRadius || ".15rem"}!important;
  }
  :focus + ${CheckboxLabel}::before {
    background: linear-gradient(
      to bottom,
      #5dd3b3,
      ${(props) => props.bgColor || "#2f3b40"}
    );
    border: 0.05em solid #5dd3b3;
  }
`;

export const HeightRowDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const MainCols = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  /* background-color:black; */
  @media (max-width: ${breakpoints.tablets}) {
    width: 100%;
  }
`;

export const MiniColsDisplay = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 1.87rem;
  margin-left: 1rem;
  /* background-color:red; */
  &:nth-child(2) {
    /* background-color:aqua; */
  }
  /* &:nth-child(6) {
  background-color:red;
} */
`;

export const MainTextDisplay = styled.div`
  width: 100%;
  display: flex;
  margin-left: 1rem;
`;

export const RightDisplay = styled.div`
  width: 85%;
  display: flex;
  justify-content: flex-end;
`;
export const CommentContainer = styled.div`
  width: 100%;
  gap: 1rem;
  padding: 1.5rem;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.03);
`;
