import styled from 'styled-components'

export const ScaleContainer=styled.div`
width: 12.5rem;
height: 0.5rem;
border-radius: 0.3125rem;
background: #2D3741;
`;
export const ScaleStructureNumber=styled.div`
border-radius: 0.3125rem;
background: #5DD3B3;
box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
`;

export const ScaleContentNumber=styled.div`
border-radius: 0.3125rem;
background: #0B8FFF;
box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
`;

export const Header = styled.div`
width: auto;
height: 2.8125rem;
display: grid;
grid-template-columns: auto 1fr;
padding-left:1.5rem;
align-items:center;
gap:5.94rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));

`;

export const RatingContainer=styled.div`
margin-top:1.88rem ;
`;
export const RatingContentContainer=styled.div`
margin-top:1.25rem;
display: flex;
justify-content:space-between;
`;
export const MarkContainer=styled.div`
display: flex;
justify-content:space-between;
align-items:center;
`;
export const MarkScaleContainer=styled.div`
display: flex;
flex-direction:column;
gap:0.62rem;
`;

export const CriteriaContainer=styled.div`
display: flex;
flex-direction:column;
padding-left: 1.5rem;
gap:0.31rem;
`;