import styled from "styled-components";
import { theme } from "../../theme";

export const LabelDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.62rem;

  .input-container {
    display: flex;
    flex-direction: column;
    gap: 1.12rem;
  }
`;

export const BtnsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.88rem;
`;

export const LabelBtnsDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;
export const ButtonsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 1rem;
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* & > :last-child:hover {
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  } */
`;

export const FlexDiv = styled.div<{ gap?: string }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : "0.5rem")};
`;
export const RadioButton = styled.input<{ checked: boolean }>`
  width: 1rem;
  height: 1rem;
  border: 0.05em solid #fff;
  border-radius: 50%;
  margin-right: 0.5em;
  background: ${({ checked }) =>
    checked
      ? "var(--green-gradient, linear-gradient(50deg, #5DD3B3 0%, #2F6A5A))"
      : "linear-gradient(to right, rgba(56, 68, 80, 0.8), rgba(0, 0, 0, 0))"};
  border-radius: 50%;
  appearance: none;
  cursor: pointer;
  /* opacity: 0; */
  /* position: absolute;
  transform: translate(-50%, -50%); */
  &:checked::before {
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 50%;
    background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
    display: flex;
    justify-content: center;
    margin-left: -7%;
    margin-top: -6%;
    align-items: center;
    color: #fff;
    border: 0.05em solid #5dd3b3;
    content: "";
  }
`;

export const Input = styled.input`
  border: none;
  color: white;
  font-weight: 400;
  font-size: 0.75rem;
  width: 2.625rem;
  height: 1.8125rem;
  background: transparent;
  border-radius: 0.3125rem;
  outline: none;
  font-family: ${theme.font.primaryRegular};
  text-align: center;
  border: 1px solid #576371;
  margin-right: 0.5rem;
  &::placeholder {
    text-align: center;
    color: white;
  }
`;
