import styled from "styled-components";
import CheckIcon from "../../assets/icons/Group2199.svg";
import { theme } from "../../theme";

export const TypographyDiv = styled.div`
margin-left: 2.06rem;
display: flex;
gap: 0.5rem;
`;

export const FilterDiv = styled.div`
display: flex;
width: 100%;
gap: 1.25rem;
position: relative;
padding: 0 2.06rem;
`;

export const BgDiv = styled.div`
width: 100%;
position: absolute;
background: var(--Grey-Gradient, linear-gradient(90deg, #415160 0%, #293440 107.88%));
top: 1.25rem;
left: 0;
height: 2.45rem;
z-index: 0;
`;
export const SingleFilterDiv = styled.div`
display: flex;
flex-direction: column;
gap: 1.81rem;
margin: 1.25rem 0 1.87rem 0;
width: 17rem;
`;

export const FilterTitle = styled.div<{zIndex?: string}>`
padding: 0.62rem 0;
z-index: ${({ zIndex }) => (zIndex ? zIndex : "1")};
`;

export const CheckBox = styled.input`
  min-width: 1rem;
  height: 1rem;
  background: linear-gradient(180deg, #2d3741 0%, #576371 100%),
    linear-gradient(0deg, #ffffff, #ffffff);

  appearance: none;
  border: 1px solid ${theme.dark.primary_text_color};

  transition: background-color 0.3s ease;

  &:checked {
    background-image: url(${CheckIcon}); /* Use the CheckIcon component as a URL */
    background-size: cover; /* Adjust this as needed */
    background-repeat: no-repeat;
    background-position: center;
    border: none;
  }
  cursor: pointer;
`;

export const FlexDiv = styled.div`
display: flex;
align-items: center;
gap: 0.5rem;
`;

export const DateDivTitle = styled.div`
width: 100%;
height: 2.45rem;
background: linear-gradient(90deg, #415160 0%, #293440 107.88%);
padding: 0.62rem 2.06rem;
`;
export const DateDiv = styled.div`
display: flex;
justify-content: space-between;
gap: 1.25rem;
padding: 0.62rem 2.06rem 0 2.06rem;
`;

export const LabelDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.62rem;
`;

export const BtnsDiv = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: 1rem;
margin-top: 1.25rem;
`;