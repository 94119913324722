import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AnalyticsUserViewNewData } from "../AnalyticsUserViewCompo/AnalyticsUserViewCompoNewData";
import { StudentLandingPage } from "./StudentLandingPage/StudentLandingPage";
import { TeacherLandingPage } from "./TeacherLandingPage/TeacherLandingPage";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { FlexAppContainer } from "../../App.style";
import AnalyticsBackPage from "../AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticsBackPage";
import { useStateContext } from "../../contexts/ContextProvider";

export const AnalyticsLandingPage = (): JSX.Element => {
  const { classId } = useParams();
  const { SetGlobalClassId, GlobalClassId } = useStateContext();
  useEffect(() => {
    if (classId) {
      // const classIdNumber = classId;
      // Set the global class ID
      SetGlobalClassId(classId);
    }
  }, [classId]);

  const [selectedObject, setSelectedObject] = useState<any>(null);

  useEffect(() => {
    // Find the object with the matching ID
    const foundObject = AnalyticsUserViewNewData.find(
      (item) => item.id === classId
    );
    // Set the selected object in the state
    setSelectedObject(foundObject);
  }, [classId]);
  const { role } = useContext(ClassContext);
  const navigate = useNavigate();
  const NavigatePage = () => {
    navigate(-1);
  };
  return (
    <FlexAppContainer column={true} gap="1.25rem">
      <AnalyticsBackPage onClick={NavigatePage} />
      {role === "student" ? (
        <StudentLandingPage student={selectedObject} />
      ) : (
        <TeacherLandingPage Teacher={selectedObject} />
      )}
    </FlexAppContainer>
  );
};
