import React, { useCallback } from "react";
import DragAndDropZone from "./main/dropzone";
import { Typography } from "../../elements/fonts/Fonts";

interface Prop {
  text: string;
  minHeight?: string;
  testDrop?: (acceptedFiles: File[] | File) => void;
  acceptedTypes?: { [key: string]: string[] };
  initialFile?: string | undefined;
  setFileResponse?: React.Dispatch<React.SetStateAction<any>>;
  fontSize?: string;
  marginTop?: string;
  widthIcon?: number;
  heightIcon?: number;
}
export const DropZoneForm = ({
  text,
  testDrop,
  acceptedTypes,
  initialFile,
  setFileResponse,
  minHeight,
  fontSize,
  marginTop,
  widthIcon,
  heightIcon,
}: Prop): JSX.Element => {
  const handleDrop = useCallback((acceptedFiles: File) => {
    if (testDrop) {
      testDrop(acceptedFiles);
    }
  }, []);
  // if (initialFile) {
  //   console.log("Initial Value: ", initialFile);
  // }

  return (
    <div>
      <DragAndDropZone
        onDrop={handleDrop}
        text={text}
        acceptedTypes={acceptedTypes}
        initialFile={initialFile}
        setFileResponse={setFileResponse}
        minHeight={minHeight}
        fontSize={fontSize}
        marginTop={marginTop}
        widthIcon={widthIcon}
        heightIcon={heightIcon}
      />
    </div>
  );
};
