import {
  MainHolder,
  InputHolder,
  SearchIconHolder,
} from "../topicInputEdit/ReusableInput.style";
import { FC, useState } from "react";
import { InputInterface } from "./reusableInputInterface";
import { SvgClose, SvgCheckModuleTopic } from "../Icons";

import { useRecoilValue } from "recoil";
import { courseCreatorColorSelector } from "../../recoil/ThemeSelectors";

const ReusableInputGroup: FC<InputInterface> = ({
  placeHolder,
  inputWidth,
  inputHeight,
  inputBorderRadius,
  inputBorder,
  padding,
  display,
  value,
  handleInputChange,
  setShowEditInGroup,
  updateGroupTitle,
  newGroupTitle,
  groupId,
  setShowDropDown,
  toggleDropdown,
}) => {
  const [isCheckedEnter, setIsCheckedEnter] = useState(false);

  // Recoil state and selectors
  const { addModuleIconColor } = useRecoilValue(courseCreatorColorSelector);
  return (
    <MainHolder inputwidth={inputWidth || "100%"}>
      <InputHolder
        color={addModuleIconColor}
        placeholder={placeHolder || "Title"}
        inputheight={inputHeight || "auto"}
        inputborderradius={inputBorderRadius || "0.3125rem"}
        inputborder={inputBorder || "1px solid #576371"}
        padding={padding || "0.75rem"}
        value={value}
        autoFocus
        onChange={handleInputChange}
        onBlur={() => {
          if (!isCheckedEnter) {
            setShowEditInGroup(false);
            setShowDropDown(true);
            toggleDropdown();
          }
        }}
        onKeyDown={(e: any) => {
          if (e.key === "Enter") {
            setShowEditInGroup(false);
            updateGroupTitle(groupId, newGroupTitle);
            setShowDropDown(true);
            toggleDropdown();
          }
          if (e.key === "Escape") {
            setShowEditInGroup(false);
            setShowDropDown(true);
            toggleDropdown();
          }
        }}
      />
      <SearchIconHolder display={display || "flex"}>
        <SvgCheckModuleTopic
          onMouseEnter={() => setIsCheckedEnter(true)}
          onMouseLeave={() => setIsCheckedEnter(false)}
          onClick={() => {
            setShowEditInGroup(false);
            updateGroupTitle(groupId, newGroupTitle);
            setShowDropDown(true);
            toggleDropdown();
          }}
        />
        <SvgClose
          style={{ width: "12", height: "12", color: "D04B4B" }}
          onClick={() => {
            setShowEditInGroup(false);
            setShowDropDown(true);
            toggleDropdown();
          }}
        />
      </SearchIconHolder>
    </MainHolder>
  );
};

export default ReusableInputGroup;
