import React, { FC } from "react";
import {
  Overlay,
  BackgroundOverlay,
  PopupContainer,
  PopupContentContainer,
  IconsContainer,
  IconAndTitleContainer,
  PopupTitle,
  HorizantalLine,
  CloseIconContainer,
  ButtonContainer,
} from "./PopUp.style";
import Button from "../QuizButton/Button";
import { ReactComponent as CloseIcon } from "../../assets/icons/Close1.svg";
import { Typography } from "../fonts/Fonts";
import CancelButton from "../StyledButton/CancelButton";
interface PopupProps {
  show: boolean;
  content: React.ReactElement;
  title?: string;
  showline: boolean;
  icon?: React.ReactElement;
  paddingTop?: string;
  marginTop?: string | undefined;
  showButton?: boolean | true;
  showButtonCancel?: boolean | true;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit?: () => void;
  buttonName?: string;
  BackGround?: string;
  IconContainer?: string;
  justifyContent?: string;
}

const PopUp: FC<PopupProps> = (
  {
    show,
    content,
    setShow,
    title,
    icon,
    showline,
    marginTop,
    justifyContent,
    paddingTop = "2.06rem",
    showButton = true,
    handleSubmit,
    buttonName,
    showButtonCancel = false,
    BackGround = "rgba(0, 0, 0, 0.4)",
  },
  IconContainer = true
) => {
  return (
    <Overlay isvisible={show.toString()}>
      {show && (
        <BackgroundOverlay BackGround={BackGround}>
          <PopupContainer>
            <PopupContentContainer
              style={{
                marginTop: marginTop ? marginTop : "20px",
                paddingTop: `${paddingTop}`,
              }}
            >
              <IconsContainer IconContainer={IconContainer}>
                <IconAndTitleContainer>
                  <React.Fragment>{icon}</React.Fragment>
                  <Typography
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="19.78px"
                    text={title || ""}
                  />
                </IconAndTitleContainer>
                <CloseIconContainer>
                  <CloseIcon color="#5DD3B3" onClick={() => setShow(false)} />
                </CloseIconContainer>
              </IconsContainer>

              <HorizantalLine showline={showline.toString()} />
              {content}
              <ButtonContainer justifyContent={justifyContent || "center"}>
                {showButtonCancel ? (
                  <CancelButton
                    color="#D85D66"
                    borderColor="#D85D66"
                    backgroundColor="transparent"
                    name="Clear"
                    onClickFunction={() => setShow(false)}
                  />
                ) : (
                  ""
                )}
                {showButton ? (
                  <CancelButton
                  backgroundColor="#5DD3B3"
                  color="#fff"
                    name={buttonName ? buttonName : "Done"}
                    onClickFunction={() => {
                      if (handleSubmit) {
                        handleSubmit();
                        // setShow(false);
                      } else {
                        setShow(false);
                      }
                    }}
                  />
                ) : (
                  ""
                )}
              </ButtonContainer>
            </PopupContentContainer>
          </PopupContainer>
        </BackgroundOverlay>
      )}
    </Overlay>
  );
};

export default PopUp;
