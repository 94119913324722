import styled from "styled-components";
interface MenuWrapperProps {
  bgColor: string;
  boxShadow: string;
}
export const MenuWrapper = styled.div<MenuWrapperProps>`
  position: absolute;
  top: 1.5rem;

  &:dir(ltr) {
    left: 18.55rem;
  }
  &:dir(rtl) {
    right: 18.55rem;
  }
  border-radius: 0.3125rem;
  background: ${(props) => props.bgColor};
  box-shadow: ${(props) => props.boxShadow};

  min-height: fit-content;
  min-width: fit-content;
  width: 6.75rem;
  height: 4.6875rem;
  display: flex;
  flex-direction: column;
  z-index: 20;
`;

interface FrameProps {
  textColor: string;
}
export const Frame = styled.div<FrameProps>`
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: ${(props) => props.textColor};
  display: flex;
  align-items: center;
  text-align: start;
  &:hover {
    background: ${({ theme }) => theme.dark.main_color};
    transition: all 0.2s ease-in;
    color: #fff;
  }
`;

export const TextWithIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  &:dir(ltr) {
    padding: 0.62rem 0 0.62rem 1.25rem;
  }
  &:dir(rtl) {
    padding: 0.62rem 1.25rem 0.62rem 0;
  }
  display: flex;
  column-gap: 0.44rem;
  align-items: center;
  /* background-color: gray; */
`;
