import styled, { css } from "styled-components";
import { FrameProps } from "./sidebarInterface";
import { breakpoints } from "../../../theme";

interface SubMenuProps {
  sidebarmini: string;
  bgcolor?: string;
  color?: string;
}

const mediaQueries = (mini: boolean, componentType: string) => {
  if (mini) {
    switch (componentType) {
      case "Menu":
        return css`
          @media (min-width: ${breakpoints.large}) {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;
            align-items: start;
          }
        `;
      case "Hovered":
        return css`
          @media (min-width: calc(${breakpoints.large} + 100px)) {
            &:dir(ltr) {
              left: 6.25rem !important;
            }
            &:dir(rtl) {
              right: 6.25rem !important;
            }
          }
          @media (min-width: ${breakpoints.extraLarge}) {
            display: none;
          }
        `;
      case "Frame":
        return css`
          @media (max-width: ${breakpoints.extraLarge}) {
            display: flex;
            padding-left: 0;
            /* gap: 1.5rem; */
            align-items: center;
            justify-content: center;
          }
        `;
      case "MenuText":
        return css`
          @media (max-width: ${breakpoints.extraLarge}) {
            display: none;
          }
        `;
      default:
        return css``;
    }
  } else {
    // If sidebarmini is false, don't apply any media queries
    return css``;
  }
};

export const Menu = styled.div<SubMenuProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${(props) => mediaQueries(props.sidebarmini === "true" , "Menu")}
`;
export const HoveredDiv = styled.div<SubMenuProps>`
  background: ${(props) => props.bgcolor || "#384450"};
  color: ${(props) => props.color};
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  position: absolute;
  bottom: 1.2rem;
  left: 6.5rem !important;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: start;
  width: max-content;
  /* min-width: 5rem; */
  padding: 0.3rem 0.5rem;
  display: none;
  ${(props) => mediaQueries(props.sidebarmini === "true", "Hovered")}
  /* display: ${(props) => (props.sidebarmini === "false" ? "none" : "initial")}; */
`;

export const RedCircle = styled.div<{ bottom?: string; left?: string }>`
  background: #d85d66;
  position: absolute;
  width: 0.625rem;
  height: 0.625rem;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  bottom: ${(props) => props.bottom || " 1.84em"};
  left: ${(props) => props.left || "3.35rem"};
`;

export const FrameWrapper = styled.div<FrameProps & SubMenuProps>`
  position: relative;
  border: 0px none;
  width: 100%;

  /* color: ${(props) =>
    props.isactive === "true" ? "white" : props.theme.dark.submenu_color}; */

  background: ${(props) =>
    props.isactive === "true" ? props.activehoverbgcolors : "initial"};

  color: ${(props) => (props.isactive === "true" ? "#fff" : props.icontextcolors)};
  &:hover {
    background: ${(props) => props.activehoverbgcolors};

    &:hover ${HoveredDiv} {
      display: ${(props) => (props.sidebarmini === "false" ? "none" : "initial")};
      /* display: block; */
    }
  }
  @media (min-width: ${breakpoints.extraLarge}) {
    &:hover {
      background: ${(props) =>
        `linear-gradient(to right, ${props.theme.dark.gradient_color_submenu[0]}, ${props.theme.dark.gradient_color_submenu[1]})`};
      &:hover ${HoveredDiv} {
        display: none;
      }
    }
  }
`;
export const Frame = styled.div<SubMenuProps>`
  height: 4rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1.5rem;
  &:dir(rtl) {
    padding-right: ${(props) =>
      props.sidebarmini === "false" ? "3.75rem" : "0"};
  }
  &:dir(ltr) {
    padding-left: ${(props) => (props.sidebarmini === "false" ? "3.75rem" : "0")};
  }
  @media (min-width: ${breakpoints.extraLarge}) {
    /* &:dir(ltr){
  padding-left: 3.75rem;
} */
    &:dir(rtl) {
      padding-right: 3.75rem;
    }
    &:dir(ltr) {
      padding-left: 3.75rem;
    }
  }

  ${(props) => mediaQueries(props.sidebarmini === "true", "Frame")}

  &:hover {
    color: #fff;
  }
`;
export const MenuText = styled.div<SubMenuProps>`
  ${(props) => mediaQueries(props.sidebarmini === "true", "MenuText")}
`;
export const Icon = styled.div`
  width: 1.125rem;
  height: 1.125rem;
`;
