import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { CourseCardData } from "../CourseCardData";
import {
  OfficeHoursDetails,
  CourseCardWrapper,
  ProfileHolder,
  MaskGroup,
  TopHolder,
  DetailStyle,
  TeacherDetailsHolder,
  RightHolder,
  DividLine,
  MessageInput,
  HoveredCardFooter,
  IconStyle,
  TimeHolder,
  ButtonsDiv,
} from "./CourseCardHover.style";
import {
  SvgLinkedIn,
  SvgSendIcon,
  SvgGroup434,
  SvgTwitter,
  SvgAlarmIcon,
  SvgPhone,
  SvgEmail,
  HourIcon,
  SvgNextIcon,
} from "../../../elements/Icons";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../../elements/fonts/Fonts";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import LoaderCircle from "../../loader/loaderCircle/LoaderCircle";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import ZSpinner from "../../../elements/Spinner/ZSpinner/ZSpinner";
import { TranslateTime } from "../../../utils/TimeCoverter";
import { adminState, languageState } from "../../../recoil/RecoilStates";
import { TransformDirection } from "../../../utils/Direction";
import { toArabicDaysLetter, toArabicDigits } from "../../../utils/formatDayArabic";

interface hoveredCard {
  teacherInfo: any;
  profilePicture: string;
  classId: string;
  handlePrev?: any;
  handleNext?: any;
  isHovered: boolean;
  disabledLeft?: boolean;
  disabledRight?: boolean;
  totTeachers: boolean;
  teacherId?: string;
  handleMouseLeave?: any;
}

export const CourseCardHover = ({
  totTeachers,
  teacherInfo,
  handlePrev,
  handleNext,
  profilePicture,
  disabledLeft,
  disabledRight,
  teacherId,
  classId,
  isHovered,
  handleMouseLeave,
}: hoveredCard): JSX.Element => {
  const { ProfileGradient_BgColor_Both, theme } = useRecoilValue(ColorSelector);
  const groupedOfficeHours: { [time: string]: string[] } = {};
  const [teacher, setTeacher] = useState<any>({});
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const tenantName = localStorage.getItem("tenant");
  const [message, setMessage] = useState<string>("");
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const { setGlobalConversationId } = useContext(ClassContext);
  const navigate = useNavigate();
  const [days, setDays] = useState<any>();
  const [hours, setHours] = useState<any>();
  const [hasOfficeHours, setHasOfficeHours] = useState<any>();
  const [isAdmin] = useRecoilState(adminState)
  const getTeacherInfo = async () => {
    try {
      setDataLoading(true);
      const response = await axios.get(
        `https://9ws5f31duk.execute-api.eu-west-1.amazonaws.com/dev/teacher/academic/${tenantName}/${teacherId}`
      );

      if (!response.data.data.officeHours) return null;
      const officeHours: { [key: string]: string[] } =
        response.data.data.officeHours;
      const hoursResponse = Object.values(officeHours).map(
        (dayHours: string[]) => {
          return dayHours
            .map((timeRange) => {
              const [start, end] = timeRange.split("-");
              return `${TranslateTime(start, t)}-${TranslateTime(end, t)}`;
            })
            .sort(); // Sort the converted time ranges
        }
      );

      // Sort days if needed
      const daysResponse = Object.keys(response.data.data.officeHours);
      setDays(daysResponse);

      setHours(hoursResponse);

      const hasOfficeHours = hoursResponse.some((hour: any) => hour.length > 0);
      setHasOfficeHours(hasOfficeHours);

      setTeacher(response.data.data);
      setDataLoading(false);
    } catch (error) {
      console.log(error);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    if (isHovered) {
      {
        teacherId !== null &&
          teacherId !== "" &&
          teacherId !== undefined &&
          getTeacherInfo();
      }
    }
  }, [teacherId, isHovered]);

  if (teacher?.officeHours) {
    const officeHours = teacher.officeHours as {
      [time: string]: string[];
    };

    Object.entries(officeHours).forEach(([day, hours]) => {
      if (hours.length > 0) {
        const formattedHours = `${hours[0]} - ${hours[1]}`;
        if (!groupedOfficeHours[formattedHours]) {
          groupedOfficeHours[formattedHours] = [];
        }
        groupedOfficeHours[formattedHours].push(day);
      }
    });
  }

  const handleSendMessage = async () => {
    try {
      const data = {
        tenantName: tenantName,
        classSourceId: classId,
        participants: teacherInfo
          ? [teacherInfo.sourceId]
          : [teacher?.teacherId],
        type: "individual",
        subId: subIdSplitted,
        provider: provider,
      };
      setLoading(true);
      const response = await axios.post(
        `https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/create/conversations`,
        {
          data: data,
        }
      );
      setGlobalConversationId(response.data.data.id);
      navigate(`/courseView/CHATS/${classId}`);
    } catch (error) {
      console.log(error);
    }
  };
  const [language] = useRecoilState(languageState);

  const { t } = useTranslation();

  return (
    <CourseCardWrapper isadmin={isAdmin.toString()}
      onMouseLeave={handleMouseLeave}
      style={{
        background: theme === "light" ? " #FFF" : "#1f2731",
        filter:
          theme === "light"
            ? " drop-shadow(0px 4px 14px rgba(133, 133, 133, 0.25))"
            : "none",
      }}
    >
      {/* {dataLoading ? (
        <ZSpinner containerHeight="8rem" />
      ) : ( */}
      <>
        <div style={{ display: "flex", columnGap: "0.3rem" }}>
          <Typography
            text={
              teacherInfo
                ? teacherInfo?.teacherData.teacherName
                : teacher.teacherName
            }
          />
          {!totTeachers && (
            <ButtonsDiv>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  transform: language === "ar" ? "none" : "rotate(180deg)",
                  cursor: "pointer",
                  color:
                    theme === "light"
                      ? disabledLeft
                        ? "gray"
                        : "black"
                      : disabledLeft
                        ? "gray"
                        : "white",
                  marginTop: language === "ar" ? "0.3rem" : "-0.3rem",
                }}
                onClick={handlePrev}
                disabled={disabledLeft}
              >
                <SvgNextIcon width={"0.75rem"} />
              </button>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  transform:
                    TransformDirection() === "rtl" ? "rotate(180deg)" : "none",
                  cursor: "pointer",
                  marginTop:
                    TransformDirection() === "rtl" ? "-0.2rem" : "0.2rem",
                  color:
                    theme === "light"
                      ? disabledRight
                        ? "gray"
                        : "black"
                      : disabledRight
                        ? "gray"
                        : "white",
                }}
                onClick={handleNext}
                disabled={disabledRight}
              >
                <SvgNextIcon width={"0.75rem"} />
              </button>
            </ButtonsDiv>
          )}
        </div>
        <TopHolder>
          <DividLine
            style={{
              border:
                theme === "light" ? "1px solid #EAECED" : "1px solid #576371",
              marginBottom: "0.24rem ",
            }}
          >
            <RightHolder>
              {teacherInfo
                ? teacherInfo.teacherData.linkedIn !== ""
                : teacher.linkedIn !== "" && (
                  <IconStyle
                    onClick={() =>
                      window.open(teacherInfo.teacherData.linkedIn, "_blank")
                    }
                  >
                    <SvgLinkedIn />
                  </IconStyle>
                )}
              {teacherInfo
                ? teacherInfo.teacherData.xPlatform !== ""
                : teacher.xPlatform !== "" && (
                  <IconStyle
                    onClick={() =>
                      window.open(
                        teacherInfo
                          ? teacherInfo.teacherData.xPlatform
                          : teacher.xPlatform,
                        "_blank"
                      )
                    }
                  >
                    <SvgTwitter />
                  </IconStyle>
                )}
            </RightHolder>
          </DividLine>
          <ProfileHolder bgcolor={ProfileGradient_BgColor_Both}>
            {teacherInfo ? (
              <MaskGroup
                alt="Profile"
                src={
                  teacherInfo.profilePicture !== undefined &&
                    teacherInfo.profilePicture !== null &&
                    teacherInfo.profilePicture !== ""
                    ? teacherInfo.profilePicture
                    : "./assets/images/userProfile.svg"
                }
              />
            ) : (
              <MaskGroup alt="Profile" src={profilePicture} />
            )}
          </ProfileHolder>
        </TopHolder>
        <TeacherDetailsHolder
          hasofficehours={
            teacherInfo ? teacherInfo?.hasOfficeHours : hasOfficeHours
          }
        >
          {/* <DetailStyle>
          <SvgPhone />
          <MediumTypography
            fontSize="0.625rem"
            text={teacher?.TeacherPhone || ""}
          />
        </DetailStyle> */}
          <DetailStyle>
            <SvgEmail style={{ transform: "translateY(0.1rem)" }} />
            <MediumTypography
              fontSize="0.625rem"
              text={
                teacherInfo
                  ? teacherInfo?.teacherData.teacherEmail
                  : teacher.teacherEmail
              }
            />
          </DetailStyle>
          {teacherInfo ? (
            <OfficeHoursDetails>
              {teacherInfo?.hasOfficeHours && (
                <div
                  style={{
                    display: "flex",
                    columnGap: "0.4rem",
                    alignItems: "center",
                    height: "0.8rem",
                    width: "7.7rem",
                  }}
                >
                  <HourIcon color={theme === "light" ? "#364350" : "white"} />
                  <MediumTypography
                    text={t("User Profile.Office Hours:")}
                    fontSize="0.6rem"
                  />
                </div>
              )}
              <TimeHolder isadmin={isAdmin.toString()}>
                {(teacherInfo ? teacherInfo?.days : days).map((day: any, index: any) => (
                  <div key={index} style={{ display: "flex" }}>
                    <div style={{ width: "2rem" }}>
                      {teacherInfo ? (
                        teacherInfo.hours[index].length > 0 && (
                          <LightTypography text={toArabicDaysLetter(day)} fontSize="0.625rem" />
                        )
                      ) : (
                        hours[index].length > 0 && <LightTypography text={day} fontSize="0.625rem" />
                      )}
                    </div>
                    <div style={{ width: "100%" }}>
                      {teacherInfo ? (
                        <Typography
                          text={toArabicDigits(teacherInfo.hours[index].join("  "))}
                          fontSize="0.6rem"
                          color="#5DD3B3"
                        />
                      ) : (
                        <Typography
                          text={hours[index]}
                          fontSize="0.6rem"
                          color="#5DD3B3"
                        />
                      )}
                    </div>
                  </div>
                ))}
              </TimeHolder>

            </OfficeHoursDetails>
          ) : (
            hasOfficeHours && (
              <OfficeHoursDetails>
                <div
                  style={{
                    display: "flex",
                    columnGap: "0.4rem",
                    alignItems: "center",
                    height: "0.8rem",
                    width: "7.7rem",
                  }}
                >
                  <HourIcon color={theme === "light" ? "#364350" : "white"} />
                  <MediumTypography
                    text={t("User Profile.Office Hours:")}
                    fontSize="0.6rem"
                  />
                </div>
                <TimeHolder isadmin={isAdmin.toString()}>
                  {days.map((day: any, index: any) => (
                    <div key={index} style={{ display: "flex" }}>
                      <div style={{ width: "2rem" }}>
                        {hours[index].length > 0 && (
                          <LightTypography text={day} fontSize="0.625rem" />
                        )}
                      </div>
                      <div style={{ width: "100%" }}>
                        {hours[index].length > 0 && (
                          <Typography
                            text={hours[index]}
                            fontSize="0.6rem"
                            color="#5DD3B3"
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </TimeHolder>
              </OfficeHoursDetails>
            )
          )}
          {isAdmin === false ? <HoveredCardFooter>
            <div
              style={{
                borderRadius: " 2.25rem",
                border: "1px solid #5DD3B3",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: "0.5rem",
                cursor: "pointer",
                marginTop: teacherInfo
                  ? teacherInfo?.hasOfficeHours
                  : hasOfficeHours
                    ? "0"
                    : "1rem",
              }}
              onClick={handleSendMessage}
            >
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    columnGap: "0.63rem",
                    alignItems: "center",
                  }}
                >
                  <LoaderCircle />
                  <Typography
                    text={t("general.loading")}
                    fontSize="0.625rem"
                    color="#5DD3B3"
                  />
                </div>
              ) : (
                <Typography
                  text={t("Chat.send a message")}
                  fontSize="0.625rem"
                  color="#5DD3B3"
                />
              )}
            </div>
          </HoveredCardFooter> : <div style={{
            marginTop: teacherInfo
              ? teacherInfo?.hasOfficeHours
              : hasOfficeHours
                ? "0"
                : "1rem",
          }}></div>}
        </TeacherDetailsHolder>
      </>
      {/* )} */}
    </CourseCardWrapper>
  );
};
