import React, { useContext, useEffect } from "react";
import { FlexAppContainer, NormalProfileImgAPP } from "../../../App.style";
import {
  NotificationMenuV2RenderedContainer,
  NotificationMenuV2MiniContainer,
  NotificationMenuIconSpaceContainer,
  NotificationMenuV2MiniCols,
  NotificationMenuV2MiniColsEnd,
  NotificationMenuV2BtnChape,
  TextColorDiv,
  TitleTextColorDiv,
  TitleTextColorDivOpacity,
  TitleTextColorDivRed,
} from "./NotificationMenuV2.style";
import { PTLPosterImg } from "../../PostsTimeLine/main/PostsTimeLine.style";
import { SvgAnnouncement, SvgNotificationIcon } from "../../../elements/Icons";
import {
  LightTypography,
  MediumTypography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import { formatDateJustDate } from "../../../utils/FormatedDATE";
import { formatDateDisplay } from "../../ContactMessages/ContactMessagesFunctions";
import { getIconBasedOnType } from "../../../elements/StudentLandingTable/StudentLandingTableFunction";
import {
  ManyOptionsDate,
  OptimizeColor,
  OptimizeOpacity,
  ShowDismissButton,
  ShowViewMyGradeButton,
  TextBeforeDateDisplays,
} from "./Functions";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../../utils/StringManipulation";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import DOMPurify from "dompurify";
import {
  translateTextDisplays,
  translateTitleOrName,
} from "../../Natifications/TranslationFunc";
import { useTranslation } from "react-i18next";
import { detectIconColor } from "../../../utils/ResourceIcon";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
interface NotificationMenuV2Props {
  unviewedNotificationData: any[];
}

const NotificationMenuV2: React.FC<NotificationMenuV2Props> = ({
  unviewedNotificationData,
}) => {
  const tenantName = localStorage.getItem("tenant");
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const { t } = useTranslation();
  // Sort NotificationDropDataV2 by ArrivalDate from newest to oldest
  // const sortedNotificationDropData = NotificationDropDataV2.slice().sort(
  //   (a, b) => b.ArrivalDate.getTime() - a.ArrivalDate.getTime()
  // );
  const generateLinkData = (item: any) => {
    let navigationLink = "";
    let providedState = {};
    if (item.type === "announcement") {
      navigationLink = `/announcements/${item.refId}`;
    } else if (item.type === "missingAttendance") {
      toast.error(`${item.TitleOrName}`);
    } else {
      if (item.contentid === "") {
        navigationLink = "#";
        providedState = {};
        toast.error(t("This content was deleted"));
      } else {
        navigationLink = `/courseView/content/${item.classSourceId}`;
        // providedState = {
        //   activity: item,
        //   moduleIndex: item.moduleindex - 1,
        //   topicIndex: item.topicindex - 1,
        //   contentIndex: item.contentindex,
        //   contentId: item.contentid,
        //   isVisible: item?.isvisible,
        // };
      }

      //  else {
      //   navigationLink = ``;
      //   providedState = {};
      // }
    }

    return { navigationLink, providedState };
  };
  const navigate = useNavigate();
  const handleNotificationClick = (item: any) => {
    localStorage.setItem(`selectedResource-${item.classSourceId}`, JSON.stringify({ contentId: item.contentid, moduleIndex: item.moduleindex, topicIndex: item.topicindex, isVisible: item?.isvisible }));
    const { navigationLink, providedState } = generateLinkData(item);
    navigate(navigationLink, { state: providedState });
  };

  const sortedNotificationDropData =
    unviewedNotificationData &&
    unviewedNotificationData
      .slice() // Create a copy of the array before sorting
      .sort((a, b) => {
        const dateA = new Date(a.ArrivalDate);
        const dateB = new Date(b.ArrivalDate);
        return dateB.getTime() - dateA.getTime();
      });

  const updateNotificationViews = async () => {
    if (unviewedNotificationData && unviewedNotificationData.length > 0) {
      const data = {
        subId: subIdSplitted,
        provider: provider,
        notifications: unviewedNotificationData,
      };
      const createResponse = await axios.post(
        `https://nfo4qku4p7.execute-api.eu-west-1.amazonaws.com/dev/create/views/${tenantName}`,
        {
          data: data,
        }
      );
    }
  };

  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };

  useEffect(() => {
    updateNotificationViews();
  }, [unviewedNotificationData]);
  const {
    bg_selectColor,
    theme,
    NotifyBox_BgColor,
    NotifyBox_BgColor_IconHolder,
    Light_TextColor,
    SecondaryWhiteTextColor,
    themeTextColor,
  } = useRecoilValue(ColorSelector);
  const currentDate = new Date();
  return (
    <>
      <ToastContainer />
      {sortedNotificationDropData && sortedNotificationDropData.length > 0 ? (
        <FlexAppContainer width="100%" column={true}>
          {sortedNotificationDropData.slice(0, 4).map((item, index) => (
            <NotificationMenuV2RenderedContainer
              onClick={() => handleNotificationClick(item)}
              key={index}
              color={OptimizeColor(item.DueDate)}
              opacity={OptimizeOpacity(item.Viewed)}
            >
              <NotificationMenuV2MiniContainer>
                {item.type === "Posts" || item.type === "createSubmit" ? (
                  <NormalProfileImgAPP
                    src={
                      item.ProfilePic ||
                      require("../../../assets/userProfile.png")
                    }
                    ImgSize="2.25rem"
                  />
                ) : (
                  <NotificationMenuIconSpaceContainer
                    color={detectIconColor(item.type)}
                    bgcolor={NotifyBox_BgColor}
                  >
                    {getIconBasedOnType(
                      item.type,
                      item.refTable ? item.refTable : ""
                    )}
                  </NotificationMenuIconSpaceContainer>
                )}
                <NotificationMenuV2MiniCols>
                  <TitleTextColorDiv color={themeTextColor} type={item.type}>
                    <MediumTypography
                      text={translateTitleOrName(item, t)}
                      fontSize="0.625rem"
                    />
                  </TitleTextColorDiv>
                  {item.TextDisplays ? (
                    <TitleTextColorDivOpacity theme={theme}>
                      {/* <LightTypography text={item.TextDisplays} fontSize="0.5rem" /> */}
                      <p
                        style={{
                          fontFamily: "Primary Font-Light",
                          fontSize: "0.5rem",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            decodeHtml(translateTextDisplays(item, t))
                          ),
                        }}
                      ></p>
                    </TitleTextColorDivOpacity>
                  ) : item.DueDate && item.DueDate < currentDate ? (
                    <TitleTextColorDivRed theme={theme}>
                      <LightTypography
                        text={`${TextBeforeDateDisplays(
                          item.DueDate
                        )} ${formatDateJustDate(item.DueDate)}`}
                        fontSize="0.5rem"
                      />
                    </TitleTextColorDivRed>
                  ) : (
                    <TitleTextColorDivOpacity theme={theme}>
                      <LightTypography
                        text={`${TextBeforeDateDisplays(
                          item.DueDate
                        )} ${formatDateJustDate(item.DueDate)}`}
                        fontSize="0.5rem"
                      // color="yellow"
                      />
                    </TitleTextColorDivOpacity>
                  )}

                  <TextColorDiv>
                    <TypographyRegularDescription
                      text={ManyOptionsDate(item.ArrivalDate, t)}
                      fontSize="0.5rem"
                    />
                  </TextColorDiv>
                </NotificationMenuV2MiniCols>
              </NotificationMenuV2MiniContainer>
              <NotificationMenuV2MiniColsEnd>
                <TitleTextColorDiv>
                  <MediumTypography
                    text={item.CourseCode || ""}
                    fontSize="0.625rem"
                  />
                </TitleTextColorDiv>
                {ShowDismissButton(item.DueDate, item.Viewed) && (
                  <NotificationMenuV2BtnChape>
                    <TypographyRegularDescription
                      text="Dismiss"
                      fontSize="0.625rem"
                    />{" "}
                  </NotificationMenuV2BtnChape>
                )}
                {ShowViewMyGradeButton(item.type, item.TextDisplays) && (
                  <NotificationMenuV2BtnChape color="#5DD3B3">
                    <TypographyRegularDescription
                      text="View my grade"
                      fontSize="0.625rem"
                    />{" "}
                  </NotificationMenuV2BtnChape>
                )}
              </NotificationMenuV2MiniColsEnd>
            </NotificationMenuV2RenderedContainer>
          ))}
        </FlexAppContainer>
      ) : (
        <FlexAppContainer
          column={true}
          gap="0.63rem"
          alignItems="center"
          justifyContent="center"
          padding="1.80rem 0 2.80rem 0 "
        >
          <SvgNotificationIcon />
          <TypographyRegularDescription
            text={t("notification.No Data")}
            fontSize="0.75rem"
            color={SecondaryWhiteTextColor}
          />
        </FlexAppContainer>
      )}
    </>
  );
};

export default NotificationMenuV2;
