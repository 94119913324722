import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
`;
export const InputTitle = styled.input<StyleThemeInterface>`
  /* width: 99%; */
  width: 100%;
  outline: none;
  /* color: white; */
  color: ${(props) => props.color};
  height: 2.8125rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  border: 1px solid ${props =>props.bordercolor};
  background: transparent;
  &:dir(ltr) {
    padding-left: 1.25rem;
  }
  &:dir(rtl) {
    padding-right: 1.25rem;
  }
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: ${(props) => props.theme.font.primaryLight};
  &::placeholder {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: ${(props) => props.theme.font.primaryLight};
  }
  &&:focus {
    color: ${(props) => props.color};
  }
`;
export const InputDesc = styled.input`
  width: 35rem;
  outline: none;
  color: white;
  height: 6.3125rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  background: transparent;
  &:dir(ltr) {
    padding-left: 1.25rem;
  }
  &:dir(rtl) {
    padding-right: 1.25rem;
  }
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: ${(props) => props.theme.font.primaryLight};
  &::placeholder {
    color: #fff;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: ${(props) => props.theme.font.primaryLight};
  }
`;
export const TitleLabel = styled.label`
  margin-bottom: 0.63rem;
  display: flex;
  flex-direction: column;
  gap: 0.62rem;
`;
export const ButtonCancel = styled.button`
  width: 8.8125rem;
  height: 2.125rem;
  flex-shrink: 0;
  border-radius: 1.25rem;
  border: 1px solid #d04b4b;
  background: none;
  cursor: pointer;
`;
export const ButtonAdd = styled.button`
  width: 8.8125rem;
  height: 2.125rem;
  flex-shrink: 0;
  border-radius: 1.25rem;
  outline: none;
  border: none;
  background: #5dd3b3;
  cursor: pointer;
`;
export const ButtonsHolder = styled.div`
  margin-top: 1.25rem;
  display: flex;
  gap: 0.94rem;
  width: 100%;
  justify-content: flex-end;
`;
