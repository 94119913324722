import axios, { AxiosError } from "axios";
import { get } from "../utils/AxiosRequests";
const basePath = "s3/signedUrl?tenantName=";

export const S3Get = async (path?: string) => {
  const tenantName = localStorage.getItem("tenant");
  try {
    if (path) {
      const encodedPath = encodeURIComponent(path);
      const response = await get(
        `s3/signedUrl?tenantName=${tenantName}&fileName=${encodedPath}`
      );
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const serverError = error as AxiosError;
      if (serverError && serverError.response) {
        console.error("Error:", serverError.response.data);
        if (serverError.response.status === 404) {
          // Specific handling for 404 error
          return null;
        }
      }
    }
    console.log(error);
  }
};

// import axios, { AxiosError } from "axios";
// import { get } from "../utils/AxiosRequests";
// const basePath = "s3/signedUrl?tenantName=";
// const tenantName = localStorage.getItem("tenant");
// export const S3Get = async (path?: string) => {
//   if(!path){
//     console.log("Path is required")
//     return null
//   }
//   try {
//     const encodedPath = encodeURIComponent(path);
//     const url = `${basePath}${tenantName}&fileName=${encodedPath}`;
//     const response = await axios.get(url)
//     if(response.data && response.data.signedUrl){
//       const response2 = await axios.get(response.data.signedUrl)
//       return response2.data
//     }else{
//       console.log("failed to get signedUrl")
//       return null
//     }
//   } catch (error) {
//     if (axios.isAxiosError(error)) {
//       const serverError = error as AxiosError;
//       if (serverError && serverError.response) {
//         // console.error("Error:", serverError.response.data);
//         if (serverError.response.status === 404) {
//           // Specific handling for 404 error
//           return null;
//         }
//       }
//     }
//     console.log(error);
//   }
// };
