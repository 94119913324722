import styled from "styled-components";
import { StyledInput } from "./ForumFormInterface";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const ForumContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  height: 75vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 1.69rem;
  &::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(
      --Green-Gradient,
      linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)
    );
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #576371;
    border-radius: 0.625rem;
  }
`;

export const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const LabelDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.62rem;

  .input-container {
    display: flex;
    flex-direction: column;
    gap: 1.12rem;
  }
`;

export const ForumInput = styled.input<StyledInput>`
  width: ${(props) => props.width || "100%"}; // Default width or use the prop
  height: 2.8125rem;
  padding: 0.94rem 1.25rem;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  background-color: transparent;
  color: #fff;
  font-family: ${(props) => props.theme.font.primaryRegular};

  &::placeholder {
    color: #fff;
    opacity: 0.5;
    font-size: 0.75rem;
    font-family: "Primary Font-Light";
  }
  &:focus {
    outline: none;
    caret-color: white;
  }
`;
export const DateAndTimeDiv = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  /* gap: 0.62rem; */
  flex-wrap: wrap;
`;

export const BtnsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.88rem;
`;

export const LabelBtnsDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

export const RadioDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.31rem;
`;
export const RadioDivLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.69rem;
  cursor: pointer;
  user-select: none;
`;
export const ButtonsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 1rem;
`;

// export const FlexDiv = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 1.25rem;
// `;

export const GradeInput = styled.input<StyleThemeInterface>`
  width: 2rem;
  height: 1.25rem;
  padding: 0.25rem 0.3rem;
  border-radius: 0.3125rem;
  border: 1px solid ${props => props.bordercolor};
  background-color: transparent;
  color: ${props => props.color};
  font-family: ${(props) => props.theme.font.primaryRegular};
  font-size: 0.625rem;

  &::placeholder {
    color: #fff;
    opacity: 0.5;
    font-size: 0.625rem;
    font-family: "Primary Font-Light";
  }
  &:focus {
    outline: none;
    caret-color: ${props => props.color};
  }
`;
export const FlexDiv = styled.div<{ gap?: string }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : "0.5rem")};
  width: 100%;
`;
export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* & > :last-child:hover {
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  } */
`;
