import { useEffect, useRef } from "react";

const DateIndexClickOutSide = (callback: () => void, ignoreRefs: React.RefObject<HTMLElement>[]) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !ignoreRefs.some((ignoreRef) => ignoreRef.current && ignoreRef.current.contains(event.target as Node))
      ) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback, ignoreRefs]);

  return ref;
};

export default DateIndexClickOutSide;
