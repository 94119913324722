import styled from 'styled-components'

export const AssignmentRubricContainer = styled.div`
width: auto;
/* padding: 1.25rem 2.5rem 2.5rem 2.5rem; */
/* overflow-x: auto;  */
`;

export const Header = styled.div`
width: auto;
height: 2.8125rem;
display: grid;
grid-template-columns: auto 1fr;
padding-left:0.62rem;
align-items:center;
gap:3.94rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));

`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 0.5px solid #576371;
  border-top:none;
 
  
  
`;

export const TableHeaderStructure = styled.div`
  background: var(--Grey-Gradient, linear-gradient(90deg, #415160 0%, #293440 107.88%));
  width: 6.9375rem;
  height: 5.4375rem;
  padding: 0.62rem;
`;
export const TableHeaderContent = styled.div`
  background: var(--Grey-Gradient, linear-gradient(90deg, #415160 0%, #293440 107.88%));
  width: 6.9375rem;
  height: 5.4375rem;
  padding: 0.62rem;
 
`;


export const TableCellStructure = styled.div<{ lastitemstructure: any, lastitemcontent: any, isselected: any }>`
  padding: 1.25rem;
  width: 6.9375rem;
  border-right: ${(props) =>
    (props.lastitemstructure <= props.lastitemcontent && props.lastitemstructure)
      ? 'none'
      : '1px solid #576371'};
  display: flex;
  flex-direction:column;
  justify-content:center;
  gap:0.75rem;
  cursor: pointer;
  ${(props) =>
    props.isselected &&
    `
    border: 1px dashed #5DD3B3;
  `}
`;

export const TableCellContent = styled.div<{  lastitemstructure: any, lastitemcontent: any, isselected: any }>`
padding: 1.25rem;
  width: 6.9375rem;
  border-right: ${(props) =>
    (props.lastitemcontent <= props.lastitemstructure && props.lastitemcontent)
      ? 'none'
      : '1px solid #576371'
      };
  display: flex;
  flex-direction:column;
  justify-content:center;
  gap:0.75rem;
  cursor: pointer;
  ${(props) =>
    props.isselected &&
    `
    border: 1px dashed #0B8FFF;
  `}
`;

export const AssignmentCells = styled.div`
display: flex;
`;

export const BreakLine = styled.div`
   background: #576371;
  
`;