import React, { useState } from "react";
import ScrollLeftAndRight from "../../../elements/ScrollLeftAndRight/ScrollLeftAndRight";
import {
  AQColumn,
  AQStudentCells,
  AQStudentCol,
  AQSupperMainContainer,
  AnalyticsQuizzesMainContainer,
  AqFirstRow,
  AqMINIRow,
  AqMiniCol,
} from "../../AnalyticsQuizzes/AnalyticsQuizzes.style";
import { Typography } from "../../../elements/fonts/Fonts";
import { SvgDownload } from "../../../elements/Icons";
import { QuizQuestionsAnalyticsDataV2 } from "./QuizQuestionsAnalyticsDataV2";
import { InstructorViewCompletionSearchContainer } from "../../StudentCompletionTrackingInstrcuterView/InstructorViewCompletion.style";
import SearchElement from "../../../elements/searchElement/SearchElement";
import { LogoAndAction } from "../../gradeBookTable/GradeBookTable.style";
import { QuestionsCartiristicsData } from "./QuizQuestionsAnalyticsData";
import Pagination from "../../../elements/Pationation/Pagination";
import { FlexAppContainer } from "../../../App.style";
import { Link } from "react-router-dom";
import {
  ViewsOptions,
  handleNumberDisplays,
} from "../../../utils/NbOfViewesObjects";
import TableViewSelectInput from "../../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/TableViewSelectInput";
import { ExcelDownload, filterDataBySearchText } from "./Functions";
import AnalyticsQuizzesScrolled from "./AnalyticsQuizzesScrolledContainer";

interface QuizQuestionsAnalyticsInterface {
  TitleClick?: () => void;
  setChosenQuestion?: any;
}
const QuizQuestionsAnalytics = ({
  TitleClick,
  setChosenQuestion,
}: QuizQuestionsAnalyticsInterface) => {
  // State variables for managing pagination, search, and view options
  const uniqueQuizNames = new Set();
  const [limit, setLimit] = useState(QuizQuestionsAnalyticsDataV2.length);
  const itemsPerPage = limit;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [ViewText, setViewText] = useState("View All");
  const [ShowPagination, setShowPagination] = useState(false);

  // State variable for search text input
  const [searchText, setSearchText] = useState("");

  // Filtering data based on search text and pagination
  const filteredData = filterDataBySearchText(
    QuizQuestionsAnalyticsDataV2,
    searchText,
    startIndex,
    endIndex
  );

  /**
   * Function to handle changing the number of displayed items.
   * @param {string} newValue - The new value selected for display.
   */
  const handleNumberShows = (newValue: string) => {
    handleNumberDisplays(
      newValue,
      setViewText,
      setLimit,
      filteredData,
      setShowPagination
    );
  };

  return (
    <AQSupperMainContainer>
      <AqFirstRow>
        <AqMiniCol>
          <FlexAppContainer
            width="fit-content"
            pointer={true}
            onClick={TitleClick}
          >
            <Typography text="Quizzes Details" fontSize="1.75rem" />
          </FlexAppContainer>
        </AqMiniCol>

        <AqMiniCol>
          <InstructorViewCompletionSearchContainer>
            <SearchElement onChange={(e) => setSearchText(e.target.value)} />
          </InstructorViewCompletionSearchContainer>
          <AqMINIRow>
            <TableViewSelectInput
              value={ViewText}
              options={ViewsOptions}
              onChange={handleNumberShows}
            />
            <LogoAndAction
              onClick={() =>
                ExcelDownload(
                  QuizQuestionsAnalyticsDataV2,
                  "AnalyticsQuizzesData"
                )
              }
            >
              <SvgDownload />
              <Typography text="Download" fontSize="0.75rem" />
            </LogoAndAction>
          </AqMINIRow>
        </AqMiniCol>
      </AqFirstRow>
      <AQColumn>
        <AnalyticsQuizzesMainContainer>
          <AQStudentCol NormalWidth="17.5%">
            <AQStudentCells />
            <AQStudentCells style={{ height: "3rem" }}>
              <Typography text="STUDENT NAME" fontSize="1rem" />
            </AQStudentCells>

            {/* Rendering filtered student names */}
            {filteredData.map(
              (val) =>
                val?.students &&
                val.students.map(
                  (
                    stdName: { StdCode: unknown; StdName: any },
                    Qindex: React.Key | null | undefined
                  ) => {
                    if (!uniqueQuizNames.has(stdName?.StdCode)) {
                      uniqueQuizNames.add(stdName?.StdCode);
                      return (
                        <AQStudentCells key={Qindex}>
                          <FlexAppContainer
                            height="100%"
                            width="100%"
                            pointer={true}
                            alignItems="center"
                          >
                            <Link to={`Student/${stdName.StdCode}`}>
                              <Typography
                                text={stdName?.StdName || ""}
                                fontSize="0.875rem"
                                color="#5DD3B3"
                              />
                            </Link>
                          </FlexAppContainer>
                        </AQStudentCells>
                      );
                    }
                    return null;
                  }
                )
            )}
          </AQStudentCol>
          <ScrollLeftAndRight
            Width="82.4%"
            SmallAdditionalWidth="79%"
            TabletAdditionalWidth="70%"
            paddingRight="10rem"
            MarginTop="1rem"
            content={
              <AnalyticsQuizzesScrolled
                NormalData={QuizQuestionsAnalyticsDataV2}
                filteredData={filteredData}
                QuestionsCartiristicsData={QuestionsCartiristicsData}
                setChosenQuestion={setChosenQuestion}
              />
            }
          />
        </AnalyticsQuizzesMainContainer>
      </AQColumn>
      {/* Pagination component */}
      {ShowPagination && (
        <Pagination
          currentPage={currentPage}
          total={QuizQuestionsAnalyticsDataV2.length}
          limit={itemsPerPage}
          onPageChange={(page: number) => setCurrentPage(page)}
          justifyContent="left"
          Padding="0 0 0 5.5rem"
          BoxSize="1.85rem"
        />
      )}
    </AQSupperMainContainer>
  );
};

export default QuizQuestionsAnalytics;
