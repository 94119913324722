import styled from "styled-components";

import { breakpoints, theme } from "../../theme";
// import { breakpoints } from "../../theme";
import CheckIcon from "../../assets/icons/Group2199.svg";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

interface TableTdProps {
  color?: string;
}
export const ArrowHolder = styled.div`
  transform: rotate(180deg);
  cursor: pointer;
`;

export const BackArrowNameHodler = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const ManageWeightWrapper = styled.div``;

export const AlertPublishSearchRow = styled.div`
  margin: 0rem 1.25rem 1.81rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 2rem;
`;
export const AlertPublishRow = styled.div`
  display: flex;
  /* width: ; */
  align-items: center;
  justify-content: flex-start;
  align-items: center;
  gap: 1.25rem;
`;
export const AlertWithLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.62rem;
`;
export const PublishWithInput = styled.div`
  display: flex;
  align-items: center;
  gap: 0.62rem;
`;
export const FiltersActionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0rem 1.25rem;
  @media (max-width: ${breakpoints.small}) {
    flex-wrap: wrap;
    row-gap: 1.25rem;
    justify-content: center;
  }
`;
export const FilterBtnsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.62rem;
  width: auto;
  @media (max-width: ${breakpoints.small}) {
    flex-wrap: wrap;
  }
`;
export const ActionsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.25rem;
  @media (max-width: ${breakpoints.small}) {
    flex-wrap: wrap;
  }
`;
export const LogoAndAction = styled.div`
  display: flex;
  align-items: center;
  gap: 0.56rem;
  cursor: pointer;
`;
export const SortingFilter = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0rem;
`;

export const TableSection = styled.div`
  width: 100vw;
  display: flex;
  min-height: fit-content;
  overflow-x: hidden;

  /* margin-bottom: 3.75rem; */
  position: relative;
  scroll-behavior: smooth;
  /* background-color: red; */
`;

export const TableContent = styled.table`
  max-height: 100vw;
  height: fit-content;
  width: 100%;
  border-collapse: collapse;
  overflow-x: hidden;
  white-space: nowrap;
  border: none;
  /* width: 100vw; */
  /* background-color: white; */
`;

export const TableContentHolder = styled.table`
  width: 100vw;
  /* min-width: 800px; */
  border: none;
  /* background-color: yellow; */
`;
export const TableThead = styled.thead<StyleThemeInterface>`
  display: flex;
  /* justify-content: space-between; */
  /* grid-template-columns: 45% 40% auto; */
  align-items: center;

  /* gap: 6.5rem; */
  border: none;
  white-space: nowrap;
  background: ${(props) => props.bgcolor};
  height: 3.625rem;
  width: 100vw;
`;
export const TableTh = styled.th`
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: space-between; */
  width: 100%;
  padding: 0 1rem 0rem 0rem;
  /* gap: 25.5rem; */
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
  flex: 2.5;
  &:nth-child(1) {
    margin-right: 20rem;
  }
  &:nth-child(2) {
    padding: 12rem;
  }
`;

export const TableTrMainActivity = styled.tr<StyleThemeInterface>`
  display: flex;
  border-top: 1px solid rgba(87, 99, 113, 0.5);
  /* background:yellow; */
  justify-content: space-between;
  /* grid-template-columns: 31.5% auto 19%; */
  /* justify-content: space-between; */
  /* gap: 12%; */
  white-space: nowrap;

  /* padding-right: 8.5%; */

  width: 100vw;
  height: 3.56rem;
  &:nth-child(even) {
    /* background: rgba(255, 255, 255, 0.03); */
    background: ${(props) => props.nth_child_bgcolor};
    /* background:green; */
  }
`;
export const TableTrMainModule = styled.tr<StyleThemeInterface>`
  display: flex;

  justify-content: space-between;
  /* grid-template-columns: 31.5% auto 19%; */
  /* justify-content: space-between; */
  /* gap: 12%; */
  white-space: nowrap;

  /* padding-right: 8.5%; */

  width: 100vw;
  height: 3.56rem;
  &:nth-child(even) {
    /* background: rgba(255, 255, 255, 0.03); */
    background: ${(props) => props.nth_child_bgcolor};
  }
  &:nth-child(1) {
    border-top: 1px solid rgba(87, 99, 113, 0.5);
  }
`;

export const TableTr1 = styled.tr`
  display: flex;
  justify-content: space-between;
  /* grid-template-columns: 31.5% auto 19%; */
  /* justify-content: space-between; */
  /* gap: 12%; */
  white-space: nowrap;

  /* padding-right: 8.5%; */

  width: 100vw;
  height: 3.56rem;
  &:nth-child(even) {
    background: rgba(255, 255, 255, 0.03);
    border: none;
  }
`;
export const TableTd = styled.td<TableTdProps>`
  border: none;
  height: 3.5625rem;
  width: 4rem;
  border-collapse: collapse;
  padding: 1rem 2rem 0rem 2rem;
  text-align: center;
  /* max-width: 23.1rem; */
  color: ${({ color }) => color};
`;
export const TableTd1 = styled.td<StyleThemeInterface>`
  display: flex;
  border: none;
  width: 14rem;
  /* width: 15vw; */
  height: 3.5625rem;
  border-collapse: collapse;
  padding: 1rem 0rem 0rem 0rem;
  text-align: center;
  align-items: center;

  /* min-width: 10.1rem; */
  color: ${({ color }) => color};
  /* background-color: blue; */
  &:nth-child(1) {
    border-right: 1px solid rgba(87, 99, 113, 0.5);
    /* background:${(props) => props.nth_child_bgcolor}; */
  }
`;
export const TableTdMainActivity = styled.td<TableTdProps>`
  border-right: 1px solid rgba(87, 99, 113, 1);
  display: flex;
  border: none;
  width: 14rem;
  /* width: 15vw; */
  height: 3.5625rem;
  border-collapse: collapse;
  padding: 1rem 0rem 0rem 0rem;
  text-align: center;
  align-items: center;

  /* min-width: 10.1rem; */
  color: ${({ color }) => color};
  /* background-color: blue; */
`;
export const TableTbody = styled.tbody`
  border-collapse: collapse;
  width: 100%;
  border: none;
  white-space: nowrap;
`;
export const TableTr = styled.tr`
  /* width: auto; */
  white-space: nowrap;
  width: 100%;
  /* width: max-content; */
  /* &:nth-child(even) {
    background: rgba(255, 255, 255, 0.03);
    border: none;
  } */
`;
export const CheckBox = styled.input<StyleThemeInterface>`
  min-width: 1rem;
  min-height: 1rem;
  max-width: 1rem;
  max-height: 1rem;

  background: ${(props) => props.bgcolor};
  margin-right: ${(props) => props.marginright};
  margin-top: ${(props) => props.margintop};

  appearance: none;
  border: 1px solid ${theme.dark.primary_text_color};

  transition: background-color 0.3s ease;
  background: ${(props) => props.bgcolor};
  border: 1px solid ${(props) => props.bordercolor};
  border-radius: ${(props) => props.borderradius};
  cursor: pointer;
  &:checked {
    background-image: url(${CheckIcon}); /* Use the CheckIcon component as a URL */
    background-size: cover; /* Adjust this as needed */
    background-repeat: no-repeat;
    background-position: center;
    border: none;
  }
`;
export const Input = styled.input<StyleThemeInterface>`
  border: none;
  color: ${(props) => props.color};
  font-weight: 400;
  width: 2.625rem;
  height: 1.8125rem;
  background: transparent;
  border-radius: 0.3125rem;
  outline: none;
  font-family: ${theme.font.primaryRegular};
  font-size: 0.875rem;
  text-align: center;
  border: 1px solid ${(props) => props.bordercolor};
  &::placeholder {
    text-align: center;
    color: ${(props) => props.color};
    opacity: 0.5;
  }
  &:disabled {
    border: 1px solid #1023;
  }
`;

export const PopupContainer = styled.div`
  width: 750px;
  /* padding: 5px; */
  height: 300px;
`;

export const TableRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.62rem;
  padding-top: 1rem;
  padding-right: 0;
  width: max-content;
`;

export const InputLabel = styled.div`
  margin: 4px;
`;

export const InputContainer = styled.div`
  margin-bottom: 15px;
  margin-top: 5px;
`;
export const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.62rem;
  margin: 2.12rem 0rem;
`;
export const ButtonCancel = styled.button`
  width: 13.4375rem;
  height: 3.125rem;
  border-radius: 6.25rem;
  border: 1px solid #d85d66;
  background: none;
  cursor: pointer;
`;
export const ButtonAdd = styled.button`
  outline: none;
  border: none;
  width: 13.4375rem;
  height: 3.125rem;
  border-radius: 6.25rem;
  background: #5dd3b3;
  cursor: pointer;
`;
export const UploadGradeWrapper = styled.div`
  width: 40rem;
  /* min-width: auto; */
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-top: 0.4rem;
`;
export const UploadGradeContainer = styled.div`
  width: 100%;
  /* min-width: auto; */
  display: flex;
  flex-direction: column;
  gap: 0.85rem;
`;

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;
export const MainHolder = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  gap: 0.6rem;
`;

export const MainActivity = styled.div`
  padding: 1rem 1.55rem 0.4rem 1.55rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  position: relative;
`;
export const MainModule = styled.div`
  border-top: 1px solid rgba(87, 99, 113, 1);
  border-right: 1px solid rgba(87, 99, 113, 1);
`;

export const TableTd3 = styled.td<TableTdProps>`
  display: flex;
  border: none;
  /* width: 15vw; */
  height: 3.5625rem;
  border-collapse: collapse;
  padding: 1rem 0rem 0rem 0rem;
  text-align: flex-start;
  align-items: center;
  width: fit-content;
  /* min-width: 10.1rem; */
  color: ${({ color }) => color};
  /* background-color: blue; */
`;

export const TableHeaders = styled.div`
  display: flex;
  align-items: center;
  gap: 2.25rem;
`;
export const AbsoluteDiv = styled.div`
  position: absolute;
  top: 2.7rem;
  &:dir(ltr) {
    right: 3rem;
  }
  &:dir(rtl) {
    left: 3rem;
  }
`;
export const RelativeDiv = styled.div`
  position: relative;
  width: 100%;
  &:dir(ltr) {
    margin-right: 1.5rem;
  }
  &:dir(rtl) {
    margin-left: 1.5rem;
  }
`;
export const AbsoluteDiv2 = styled.div<StyleThemeInterface>`
  position: absolute;
  bottom: 4rem;
  &:dir(ltr) {
    left: 0rem;
  }
  &:dir(rtl) {
    right: 0rem;
  }
  width: 100%;

  /* emoji picker */
  .epr_-vj00gx {
    background-color: ${(props) =>
      props.theme === "dark" ? "#3C4752" : "#F6F7F8"};
    border: none;
    width: 100% !important;
    height: 12rem !important;
    border-radius: 1.25rem 1.25rem 0rem 0rem;
  }

  .epr_-vj00gx {
    --epr-emoji-variation-picker-bg-color: ;
    --epr-hover-bg-color-reduced-opacity: ;
    --epr-highlight-color: ;
    --epr-text-color: ;
    --epr-hover-bg-color: #5dd3b3 !important;
    --epr-focus-bg-color: #5dd3b3 !important;
    --epr-search-input-bg-color: #5dd3b3 !important;
    --epr-category-label-bg-color: #5dd3b3 !important;
    --epr-picker-border-color: #5dd3b3 !important;
    --epr-bg-color: #5dd3b3 !important;
    --epr-reactions-bg-color: #5dd3b3 !important;
    --epr-search-input-bg-color-active: #5dd3b3 !important;
    --epr-emoji-variation-indicator-color: #5dd3b3 !important;
    --epr-category-icon-active-color: #5dd3b3 !important;
    --epr-skin-tone-picker-menu-color: #5dd3b3 !important;
  }

  /* search */
  .epr_-8623pq {
    background-color: transparent;
    border: 1px solid ${(props) => props.bordercolor};
    border-radius: 6.25rem;
    font-family: "Primary Font-Light";

    &:focus {
      background-color: transparent;
      border: 1px solid ${(props) => props.bordercolor};
    }
  }

  /* colors */
  .epr_-l1995p {
    display: none;
  }

  /* colors parent div */
  .epr-skin-tones.epr_-v86i3b.epr_q53mwh.epr_vl50xg.epr_vwruzc.epr_zeg9on.epr_qyh4cg {
    flex-basis: 0 !important;
  }

  /* desc above */
  .epr-emoji-category-label.epr_vl50xg.epr_-ufflr1.epr_-99lr9q.epr_r48c1o.epr_q53mwh.epr_-rk4ix5.epr_-ucs3ns.epr_3z09g4.epr_40wpk4.epr_gd8l4g.epr_-6dchwm.epr_-blo395.epr_1ttcuj.epr_-quhs3c.epr_-xuzz9z {
    display: none !important;
  }
  /* desc bellow */
  .epr_q53mwh.epr_vl50xg.epr_-3yva2a.epr_c90x4z.epr_-sisw4f.epr_mw4zr1.epr_-kg0voo.epr_iogimd.epr_wtwvf4.epr_-lzq3oa {
    display: none !important;
  }
  /* categories */
  .epr-category-nav.epr_q53mwh.epr_-lzq3oa.epr_rco1ad.epr_-8ygbw8.epr_1q9rpn {
    display: none !important;
  }
  /* recent */
  .epr-emoji-category.epr_-lphakh.epr-hidden-on-search.epr_iu1na8 {
    display: none !important;
  }
  /* .epr_q53mwh.epr_-8ygbw8.epr_346qu6.epr_-lzq3oa{
  padding-bottom: 0 !important;
} */
  .epr-body.epr_-l19tlc.epr_b8hfyo.epr_-mpla0i.epr_mw4zr1.epr_iogimd.epr_wtwvf4::-webkit-scrollbar {
    width: 0.1875rem !important; /* width of the entire scrollbar */
  }

  .epr-body.epr_-l19tlc.epr_b8hfyo.epr_-mpla0i.epr_mw4zr1.epr_iogimd.epr_wtwvf4::-webkit-scrollbar-track {
    background-color: transparent !important;
    border-radius: 0.625rem !important;
  }

  .epr-body.epr_-l19tlc.epr_b8hfyo.epr_-mpla0i.epr_mw4zr1.epr_iogimd.epr_wtwvf4::-webkit-scrollbar-thumb {
    background-image: linear-gradient(
      180deg,
      #5dd3b3 0%,
      #2d3741 100%
    ) !important;
    border-radius: 0.625rem !important;
    box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.25) !important;
  }

  .epr-body.epr_-l19tlc.epr_b8hfyo.epr_-mpla0i.epr_mw4zr1.epr_iogimd.epr_wtwvf4::-webkit-scrollbar-thumb:hover {
    background: #555 !important; /* color of the scrollbar thumb when hovered */
  }
`;
export const AbsoluteDiv3 = styled.div`
  position: absolute;
  min-width: 9rem;
  top: 4.8rem;
`;
export const AbsoluteDiv4 = styled.div`
  position: absolute;
  &:dir(ltr) {
    left: 7rem;
  }
  &:dir(rtl) {
    right: 7rem;
  }
  min-width: 5rem;
`;
