import React, { ReactNode } from "react";

import classNames from "classnames";
import { PrimaryNumber } from "../fonts/Fonts";
import {
  PageItem,
  PageLink,
  PaginatioWrapper,
  ArrowStyle,
} from "./Pagination.style";
import { SvgArrowDownIcon } from "../Icons";
import { useRecoilValue } from "recoil";
import { ColorSelector, MainColorSelector } from "../../recoil/ThemeSelectors";
import { toArabicDigits } from "../../utils/formatDayArabic";

const range = (start: number, end: number): number[] => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

interface PagesCut {
  start: number;
  end: number;
}

const getPagesCut = ({
  pagesCount,
  pagesCutCount,
  currentPage,
}: {
  pagesCount: number;
  pagesCutCount: number;
  currentPage: number;
}): PagesCut => {
  const ceiling = Math.ceil(pagesCutCount / 2);
  const floor = Math.floor(pagesCutCount / 2);

  if (pagesCount < pagesCutCount) {
    return { start: 1, end: pagesCount + 1 };
  } else if (currentPage >= 1 && currentPage <= ceiling) {
    return { start: 1, end: pagesCutCount + 1 };
  } else if (currentPage + floor >= pagesCount) {
    return { start: pagesCount - pagesCutCount + 1, end: pagesCount + 1 };
  } else {
    return { start: currentPage - ceiling + 1, end: currentPage + floor + 1 };
  }
};

interface PaginationItemProps {
  page: ReactNode;
  currentPage: number;
  onPageChange: (page: number) => void;
  isDisabled?: boolean;
  isFunctional?: boolean;
  borderRaduis?: string; // Corrected typo here
  BoxSize?: string;
  ScrollTop?: boolean;
  color?: string;
  borderColor?: string;
}

const PaginationItem: React.FC<PaginationItemProps> = ({
  page,
  currentPage,
  ScrollTop,
  onPageChange,
  isDisabled = false,
  isFunctional = true,
  BoxSize = "2.375rem",
  borderRaduis, // Corrected typo here
  color,
  borderColor,
}: PaginationItemProps) => {
  const liClasses = classNames({
    active: page === currentPage,
    disabled: isDisabled,
  });

  const onClickHandler = () => {
    if (ScrollTop === true && isFunctional && !isDisabled && onPageChange) {
      onPageChange(Number(page));
      // Add the smooth scrolling animation class to the body
      document.body.classList.add("scroll-top-animation");
      // Scroll to the top of the page
      window.scrollTo(0, 0);
      // Remove the smooth scrolling animation class after the animation is complete
      setTimeout(() => {
        document.body.classList.remove("scroll-top-animation");
      }, 500); // Adjust the timeout duration to match the transition duration
    } else if (
      ScrollTop !== true &&
      isFunctional &&
      !isDisabled &&
      onPageChange
    ) {
      onPageChange(Number(page));
      // Remove the smooth scrolling animation class after the animation is complete
      setTimeout(() => {
        document.body.classList.remove("scroll-top-animation");
      }, 500);
    }
  };
  const { Pagination_Item_Color, theme } = useRecoilValue(ColorSelector);
  const language = localStorage.getItem("language")
  return (
    <PageItem
      className={liClasses}
      onClick={onClickHandler}
      isactive={(page === currentPage).toString()}
      color={color}
      bordercolor={borderColor}
      // style={{ color: "red" }}
      // color={themeTextColor}
    >
      <PageLink boxsize={BoxSize}>
        {typeof page === "number" ? (
          <PrimaryNumber number={toArabicDigits(page.toString())} fontSize="0.875rem" />
        ) : (
          page
        )}
      </PageLink>
    </PageItem>
  );
};

interface PaginationProps {
  currentPage: number;
  total: number;
  limit: number;
  onPageChange: (page: number) => void;
  justifyContent?: string;
  borderRaduis?: string;
  MarginTop?: string;
  Padding?: string;
  BoxSize?: string;
  ScrollTop?: boolean;
  wholeWidth?: string;
}

const Pagination: React.FC<PaginationProps> = ({
  MarginTop = "0",
  Padding = "0",
  currentPage,
  total,
  limit,
  BoxSize = "2.375rem",
  onPageChange,
  justifyContent,
  ScrollTop = true,
  borderRaduis,
  wholeWidth,
}: PaginationProps) => {
  // const { themeTextColor } = useRecoilValue(ColorSelector);

  const pagesCount = Math.ceil(total / limit);
  const pagesCut = getPagesCut({ pagesCount, pagesCutCount: 5, currentPage });
  const pages = range(pagesCut.start, pagesCut.end);
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === pagesCount;
  const shouldRenderArrow = !isLastPage;
  const shouldRenderFirstArrow = !isFirstPage;
  const { Pagination_Item_Color, theme } = useRecoilValue(ColorSelector);
  const { mainColor } = useRecoilValue(MainColorSelector);
  return (
    <PaginatioWrapper
      margintop={MarginTop}
      justifycontent={justifyContent || "center"}
      padding={Padding}
      wholewidth={wholeWidth}
      // style={{ color: themeTextColor }}
    >
      {shouldRenderFirstArrow && (
        <PaginationItem
          borderColor="none"
          page={
            <ArrowStyle
              style={{ color: theme === "light" ? "#2D3741" : "#fff" }}
            >
              <SvgArrowDownIcon />
            </ArrowStyle>
          }
          currentPage={currentPage}
          onPageChange={() => onPageChange(currentPage - 1)}
          isDisabled={isFirstPage}
          isFunctional={!isFirstPage}
          ScrollTop={ScrollTop}
          BoxSize={BoxSize}
          borderRaduis={borderRaduis || "0.3125rem"} // Corrected typo here
        />
      )}
      {pages.map((page) => (
        <PaginationItem
          borderColor={Pagination_Item_Color}
          borderRaduis={borderRaduis || "0.3125rem"} // Corrected typo here
          page={page}
          key={page}
          currentPage={currentPage}
          onPageChange={onPageChange}
          BoxSize={BoxSize}
          ScrollTop={ScrollTop}
          color={Pagination_Item_Color}
        />
      ))}
      {shouldRenderArrow && (
        <PaginationItem
          borderColor="none"
          BoxSize={BoxSize}
          page={
            <ArrowStyle
              style={{
                rotate: "180deg",
                color: theme === "light" ? "#2D3741" : "#fff",
              }}
            >
              <SvgArrowDownIcon />
            </ArrowStyle>
          }
          currentPage={currentPage}
          onPageChange={() => onPageChange(currentPage + 1)}
          isDisabled={isLastPage}
          isFunctional={!isLastPage}
          ScrollTop={ScrollTop}
          borderRaduis={borderRaduis || "0.3125rem"} // Corrected typo here
        />
      )}
    </PaginatioWrapper>
  );
};

export default Pagination;
