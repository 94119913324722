import React from "react";
import { FlexBetween, FlexDiv, LeaderBoardCont, LeaderboardUser, Points, ProfileAndRank, ProfileContainer, ProfileInfoHolder, Rank, RankNumber, UsernameDiv, } from "./Leaderboard.style";
import { MediumTypography, Typography, TypographyRegularDescription, } from "../../elements/fonts/Fonts";
import { SvgScale } from "../../elements/Icons";
import Profile from "../../assets/updateProfileImg.jpg";
import { ReactComponent as SvgStarIcon } from "../../assets/icons/MiniStar.svg";
import CancelButton from "../../elements/StyledButton/CancelButton";
import { LeaderboardData } from "./Leaderboard.data";
import { useNavigate, useParams } from "react-router-dom";

const Leaderboard = () => {
  const navigate = useNavigate();
  const { classId } = useParams();

  // function to get rank
  const getRank = (userPoints: number, leaderboardData: any) => {
    const sortedLeaderboard = [...leaderboardData].sort( (a, b) => b.points - a.points );
    const userRank = sortedLeaderboard.findIndex( (user) => user.points === userPoints );
    return userRank + 1; 
  };

  // Sort LeaderboardData based on rank
  const sortedLeaderboardData = [...LeaderboardData].sort(
    (a, b) => getRank(a.points, LeaderboardData) - getRank(b.points, LeaderboardData)
  );

  // Navigate to ExpandedLeaderboard with sorted leaderboard data
  const navigateToLeaderboard = () => {
    navigate(`/leaderboard/${classId}`, { state: { sortedLeaderboardData }, });
  };

  // Navigate to Rulebook with sorted leaderboard data and active tab set to "mypoints"
  const navigateToRulebook = () => {
    navigate(`/rulebook/${classId}`, { state: { sortedLeaderboardData, activeTab: "myPoints" }, });
  };

  return (
    <>
      <LeaderBoardCont>
        {/* header */}
        <FlexBetween style={{ padding: "1.87rem 1.25rem 1.25rem 1.25rem" }}>
          <Typography text="Leaderboard" fontSize="1.25rem" />
          <SvgScale style={{ cursor: "pointer" }} onClick={navigateToLeaderboard} />
        </FlexBetween>

        {/* user profile */}
        <ProfileInfoHolder>
          <ProfileAndRank>
            <ProfileContainer bgimage={Profile} />
            <Rank> <Typography text="4" fontSize="0.75rem" /> </Rank>
          </ProfileAndRank>

          <Typography text="Sultan Al Rajhi" fontSize="1rem" />

          <Points>
            <TypographyRegularDescription text="10" fontSize="0.875rem" />
            <SvgStarIcon width="0.875rem" height="0.875rem" />
          </Points>
          <CancelButton name="View Logs" onClickFunction={navigateToRulebook} borderColor="#5DD3B3" backgroundColor="#5DD3B3" color="#fff" width="6.9375rem" />
        </ProfileInfoHolder>

        {/* all leaderboard data */}
        {sortedLeaderboardData.map((user, index) => (
          <LeaderboardUser key={index} style={{ background: index % 2 === 0 ? "rgba(255, 255, 255, 0.03)" : "#384450", }} >
            <FlexDiv gap="1.25rem">
              <RankNumber>
                <Typography text={getRank(user.points, LeaderboardData).toString()} fontSize="0.75rem" />
              </RankNumber>
              <FlexDiv gap="0.63rem">
                <ProfileContainer bgimage={user.profile} width="3.125rem" height="3.125rem" />
                <UsernameDiv>{user.name}</UsernameDiv>
              </FlexDiv>
            </FlexDiv>
            <Points>
              <TypographyRegularDescription text={user.points.toString()} fontSize="0.875rem" />
              <SvgStarIcon width="0.875rem" height="0.875rem" />
            </Points>
          </LeaderboardUser>
        ))}
      </LeaderBoardCont>
    </>
  );
};

export default Leaderboard;
