import styled from "styled-components";

export const ZidyiaLogoBox = styled.div`
  width: 100%;
`;

export const FrameLogoWrapper = styled.div`
  border: 0px none;
  overflow-x: hidden;
`;

export const FrameLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 4.375rem;
  width: 4.375rem;
`;

export const LogoZidyia = styled.img``;
