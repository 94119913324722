import React, { useState, useEffect } from 'react';
import { CommentContainer, StudentInput, StudentSelectColumn, StudentSelectContainer, StudentSelectContainerParent } from './StudentQuizSelect.style';
import { LightTypography, MediumTypography, Typography } from '../../fonts/Fonts';
import SelectInput from '../../SelectInput/SelectInput';
import { SvgDone, SvgFalse } from '../../Icons';
import { findAnswerFeedbackV2, findStudentComment } from '../quizElementsUtils';
import { useTranslation } from 'react-i18next';
import SelectDropDown from '../../selectDropDown/SelectDropDown';
import { useRecoilValue } from 'recoil';
import { ColorSelector } from '../../../recoil/ThemeSelectors';
import { useAuth0 } from '@auth0/auth0-react';
import { auth0SubIdSplitter,auth0ProviderSplitter } from '../../../utils/StringManipulation';
import { useParams } from 'react-router-dom';

interface StudentOptions {
  label: string;
  value: string;
}

interface StudentQuizSelectProps {
  selectData?: any[];
  allData?: any[];
  questionIndex?: number;
  partIndex?: number;
  setSelectedMatchingChoices?: React.Dispatch<React.SetStateAction<{ [key: string]: any[] }>>;
  selectedMatchingChoices?: { [key: string]: any[] };
  studentResponse?: any;
  match?: any;
  matchInitialeValue?: { [key: string]: any[] }
  setQuestionId?: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  setQuestionType?: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  setQuizTemplateQuestionsId?: React.Dispatch<
    React.SetStateAction<{ [key: string]: number }>
  >;
  started?: boolean;
  showGrade?: boolean;
  showPublishGrade?: boolean;
  quizId:any;
  previewMode:boolean;
}

function StudentQuizSelect({
  studentResponse,
  selectData,
  allData,
  questionIndex,
  partIndex,
  selectedMatchingChoices,
  setSelectedMatchingChoices,
  match,
  matchInitialeValue,
  setQuestionId,
  setQuestionType,
  started,
  showGrade,
  showPublishGrade,
  setQuizTemplateQuestionsId,
  quizId,
  previewMode
}: StudentQuizSelectProps) {

  const [selectedChoices, setSelectedChoices] = useState<Array<string>>([]);
  const [matchingResponse, setMatchingResponse] = useState<any[]>([]);
  const [isCorrect, setIsCorrect] = useState<{ [key: string]: boolean | null | string }>({});
  const [isCorrectKey, setIsCorrectKey] = useState<any>('');
  const { t } = useTranslation();
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const params = useParams();
  const classId = params.classId;

  // Find the answer of the student and return it
  const findStudentAnswer = (questionId: any, questionType: string) => {
    if (studentResponse && studentResponse.length > 0) {
      const studentAnswer = studentResponse.find((answer: any) => Number(answer.questionId) === Number(questionId) && answer.type === questionType);
      if (studentAnswer && studentAnswer.answers && studentAnswer.answers.length > 0) {
        const answerObjects = studentAnswer.answers.map((answer: any) => answer.answer);
        setMatchingResponse(answerObjects)
        studentAnswer.answers.map((item: any, index: any) => (
          setIsCorrect((prevDescriptions) => ({
            ...prevDescriptions,
            [`${studentAnswer.questionId}_${studentAnswer.type}_${item.answer.left}`]: item.isCorrect,
          }))
        ))
      } else {
        setMatchingResponse([]);
        setIsCorrect({})
      }
    } else {
      setMatchingResponse([]);
      setIsCorrect({})
    }
  };

  // Call the findStudentAnswer to show the answer to the student
  useEffect(() => {
    if (questionIndex !== undefined) {
      if (partIndex !== undefined && allData && allData[questionIndex].questionParts && allData[questionIndex].questionParts[partIndex]) {
        findStudentAnswer(allData[questionIndex].questionParts[partIndex].id, allData[questionIndex].questionParts[partIndex].type)
      } else {
        findStudentAnswer(allData && allData[questionIndex].questionId, allData && allData[questionIndex].type)
      }

      if(studentResponse && studentResponse.length === 0 && !previewMode){
      // Fetch and set the timer from local storage if it exists
      const savedStudentAnswer = JSON.parse(localStorage.getItem('localStudentResponse') || '[]');
      const currentQuizAnswer = savedStudentAnswer.find((item: any) =>
        item.classSourceId === classId &&
        item.subId === subIdSplitted &&
        item.refId === quizId
      );

      if (currentQuizAnswer &&
        allData &&
        allData[questionIndex]) {
        let key = "";
      if (
        (allData[questionIndex].composed ||
          allData[questionIndex].type === "COMPOSITE") &&
        partIndex !== undefined &&
        allData[questionIndex].questionParts
      ) {
        key = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}`;
      } else {
        key = `${allData[questionIndex].title}`;
      }

      if(currentQuizAnswer && currentQuizAnswer.localStudentResponse && currentQuizAnswer.localStudentResponse[key]){
        setMatchingResponse(currentQuizAnswer.localStudentResponse[key])
        setSelectedMatchingChoices && 
        setSelectedMatchingChoices((prevSelect) => ({
          ...prevSelect,
          [key]: currentQuizAnswer.localStudentResponse[key]
        }));
        }else{
          setMatchingResponse([])
        }
        
      } else {
        setMatchingResponse([])
      }
    }
    }

  }, [questionIndex, partIndex, allData, studentResponse]);

  // Update the type of the question and the id of the question depending on the dependencies
  useEffect(() => {
    if (questionIndex !== undefined) {
      if (partIndex !== undefined && allData && allData[questionIndex].questionParts && allData[questionIndex].questionParts[partIndex]) {
        // For composed questions
        const singleKey = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}_ID`;
        const typekey = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}Type`;
        const correctKey = `${allData[questionIndex].questionParts[partIndex].id}_${allData[questionIndex].questionParts[partIndex].type}`
        const templateKey = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}_TEMPLATEKEY`;
        setIsCorrectKey(correctKey);
        setQuestionType && setQuestionType((prevDescriptions) => ({
          ...prevDescriptions,
          [typekey]: allData[questionIndex].questionParts[partIndex].type,
        }));
        setQuestionId && setQuestionId((prevId) => ({
          ...prevId,
          [singleKey]: allData[questionIndex].questionParts[partIndex].id,
        }));
        setQuizTemplateQuestionsId &&
          setQuizTemplateQuestionsId((prevId) => ({
            ...prevId,
            [templateKey]: allData && allData[questionIndex].quizTemplateQuestionId,
          }));
      } else {
        // For non-composed questions
        const singleKey = `${allData && allData[questionIndex].title}_ID`;
        const typekey = `${allData && allData[questionIndex].title}Type`;
        const correctKey = `${allData && allData[questionIndex].questionId}_${allData && allData[questionIndex].type}`
        const templateKey = `${allData && allData[questionIndex].title}_TEMPLATEKEY`;
        setIsCorrectKey(correctKey);
        setQuestionId && setQuestionId((prevId) => ({
          ...prevId,
          [singleKey]: allData && allData[questionIndex].questionId,
        }));
        setQuestionType && setQuestionType((prevDescriptions) => ({
          ...prevDescriptions,
          [typekey]: allData && allData[questionIndex].type,
        }));
        setQuizTemplateQuestionsId &&
          setQuizTemplateQuestionsId((prevId) => ({
            ...prevId,
            [templateKey]: allData && allData[questionIndex].quizTemplateQuestionId,
          }));
      }
    }
  }, [questionIndex, allData])


  useEffect(() => {
    if (
      allData &&
      questionIndex &&
      selectedMatchingChoices &&
      allData[questionIndex]) {
      let key = "";
    if (
      (allData[questionIndex].composed ||
        allData[questionIndex].type === "COMPOSITE") &&
      partIndex !== undefined &&
      allData[questionIndex].questionParts
    ) {
      key = `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}`;
    } else {
      key = `${allData[questionIndex].title}`;
    }
    const initialSelectedChoices = selectedMatchingChoices[key]?.map((choice) => choice.right); // Initialize with empty strings for each choice
    setSelectedChoices(initialSelectedChoices || []);
    }
    
  }, [selectedMatchingChoices]);

  // Handle the selection of the answer
  const handleSelectChoice = (index: number, value: any) => {
    const updatedChoices = [...selectedChoices];
    updatedChoices[index] = value;

    if (setSelectedMatchingChoices && allData && questionIndex !== undefined) {
      let updatedMatchingChoices = selectedMatchingChoices ? { ...selectedMatchingChoices } : {};

      const key =
        partIndex !== undefined &&
          (allData[questionIndex]?.composed || allData[questionIndex]?.type === "COMPOSITE")
          ? `${allData[questionIndex].title}_${allData[questionIndex].questionParts[partIndex].title}`
          : allData[questionIndex].title;

      // Convert the selected values to the desired format
      const formattedChoices =
        match.left &&
        match.left.length > 0 &&
        match.left.map((left: string, leftIndex: number) => ({
          left: left,
          right: updatedChoices[leftIndex], // Use the corresponding selected value
        }));
      updatedMatchingChoices[key] = formattedChoices; // Store the array of selected choices for the respective key
      setSelectedMatchingChoices(updatedMatchingChoices);
// Retrieve the quizAnswers array from local storage
let quizAnswers = JSON.parse(localStorage.getItem('localStudentResponse') || '[]');

// Find the index of the existing timer object
const index = quizAnswers.findIndex((item: any) =>
  item.classSourceId === classId &&
  item.subId === subIdSplitted &&
  item.refId === quizId
);

if (index !== -1) {
  // Retrieve the existing localStudentResponse
  let existingResponse = quizAnswers[index].localStudentResponse || {};

  // Merge the new key-value pair with the existing localStudentResponse
  existingResponse[key] = formattedChoices;

  // Update the existing object with the merged localStudentResponse
  quizAnswers[index].localStudentResponse = existingResponse;
} else {
  // Add a new object if it doesn't exist
  const data = {
    subId: subIdSplitted,
    provider:provider,
    classSourceId: classId,
    refId: quizId,
    localStudentResponse: { [key]: formattedChoices }
  };
  quizAnswers.push(data);
}

// Save the updated array back to local storage
localStorage.setItem('localStudentResponse', JSON.stringify(quizAnswers));


    }
    setSelectedChoices(updatedChoices);
  };

  // Formating the answers Options
  const allAnswersSet = new Set<string>(); // Initialize as Set<string>
  match.right && match.right.forEach((item: any) => {
    if (item) {
      allAnswersSet.add(item);
    }
  });
  const allAnswers: StudentOptions[] = Array.from(allAnswersSet).map((right: string) => ({
    label: right,
    value: right,
  }));
  const allAnswersMapped = allAnswers.map((option) => ({
    name: option.label,
    value: option.label,
    icon: null,
  }));

  const { themeTextColor, inputBorderColor } = useRecoilValue(ColorSelector);

  return (
    <StudentSelectContainerParent>
      {/* The Feedback Goes Here */}
      <MediumTypography
        text={showPublishGrade && findAnswerFeedbackV2(studentResponse,
          (questionIndex !== undefined && partIndex !== undefined && allData && allData[questionIndex].questionParts)
            ? allData[questionIndex].questionParts[partIndex].id
            : (questionIndex !== undefined && allData ? allData[questionIndex].questionId : undefined),
          (questionIndex !== undefined && partIndex !== undefined && allData && allData[questionIndex].questionParts)
            ? allData[questionIndex].questionParts[partIndex].type
            : (questionIndex !== undefined && allData ? allData[questionIndex].type : undefined)
        )}
        color='#0B8FFF'
        fontSize='.8rem'
      />
      {matchingResponse && matchingResponse.length > 0 && matchingResponse.map((item: any, index: any) => (
        <StudentSelectContainer key={index}>
          <StudentSelectColumn>
            <LightTypography text={`Answer ${index + 1}`} />
            <StudentInput
              style={{
                border: `${isCorrect[`${isCorrectKey}_${item.left}`] !== null && isCorrect[`${isCorrectKey}_${item.left}`] !== undefined && isCorrect[`${isCorrectKey}_${item.left}`] !== "pending" && showPublishGrade ? (
                  isCorrect[`${isCorrectKey}_${item.left}`] === true ? `1px solid ${inputBorderColor}` : '1px solid #D85D66'
                ) : `1px solid ${inputBorderColor}`
                  }`,
              }}
              type='text'
              value={item.left}
              disabled={true}
              color={themeTextColor}
            />

          </StudentSelectColumn>
          <StudentSelectColumn>
            {/* If the student Has an answer the select input will be disabled */}
            <LightTypography text={`Choice ${index + 1}`} />
            { !started ?
              <>
                <SelectDropDown
                  onChange={(value) => handleSelectChoice(index, value)}
                  fontSize='0.875rem'
                  placeholder={(t('course view.Select Choice'))}
                  width="100%"
                  // options={[]}
                  borderColor={`${isCorrect[`${isCorrectKey}_${item.left}`] !== null && isCorrect[`${isCorrectKey}_${item.left}`] !== undefined && isCorrect[`${isCorrectKey}_${item.left}`] !== "pending" && showPublishGrade? (
                    isCorrect[`${isCorrectKey}_${item.left}`] === true ? `${inputBorderColor}` : '#D85D66'
                  ) : `${inputBorderColor}`
                    }`}
                  options={allAnswersMapped}
                  disabled={true}
                  value = {
                    matchInitialeValue &&
                    matchInitialeValue[
                      partIndex !== undefined &&
                      allData?.[questionIndex || 0]?.questionParts?.length > 0
                        ? `${allData?.[questionIndex || 0]?.title}_${allData?.[questionIndex || 0]?.questionParts?.[partIndex]?.title}`
                        : `${allData?.[questionIndex || 0]?.title}`
                    ]?.[index]?.right !== undefined
                      ? matchInitialeValue[
                          partIndex !== undefined &&
                          allData?.[questionIndex || 0]?.questionParts?.length > 0
                            ? `${allData?.[questionIndex || 0]?.title}_${allData?.[questionIndex || 0]?.questionParts?.[partIndex]?.title}`
                            : `${allData?.[questionIndex || 0]?.title}`
                        ]?.[index]?.right
                      : matchingResponse?.[index]?.right
                  }
                />

              </>
              :
              <SelectDropDown
                onChange={(value) => handleSelectChoice(index, value)}
                placeholder={(t('course view.Select Choice'))}
                width="100%"
                fontSize='0.875rem'
                options={allAnswersMapped}
                value = {
                  matchInitialeValue &&
                  matchInitialeValue[
                    partIndex !== undefined &&
                    allData?.[questionIndex || 0]?.questionParts?.length > 0
                      ? `${allData?.[questionIndex || 0]?.title}_${allData?.[questionIndex || 0]?.questionParts?.[partIndex]?.title}`
                      : `${allData?.[questionIndex || 0]?.title}`
                  ]?.[index]?.right !== undefined
                    ? matchInitialeValue[
                        partIndex !== undefined &&
                        allData?.[questionIndex || 0]?.questionParts?.length > 0
                          ? `${allData?.[questionIndex || 0]?.title}_${allData?.[questionIndex || 0]?.questionParts?.[partIndex]?.title}`
                          : `${allData?.[questionIndex || 0]?.title}`
                      ]?.[index]?.right
                    : matchingResponse?.[index]?.right
                }
              />
            }

          </StudentSelectColumn>
          {isCorrect[`${isCorrectKey}_${item.left}`] !== null && isCorrect[`${isCorrectKey}_${item.left}`] !== undefined && isCorrect[`${isCorrectKey}_${item.left}`] !== "pending" && showPublishGrade && (
            isCorrect[`${isCorrectKey}_${item.left}`] === true ? <SvgDone /> : <SvgFalse />
          )
          }
        </StudentSelectContainer>
      ))}
      {match.left && match.left.length > 0 && matchingResponse.length === 0 && match.left.map((item: any, index: any) => (
        <StudentSelectContainer key={index}>
          <StudentSelectColumn>
            <LightTypography text={`${t("quiz.Answer")} ${index + 1}`} />
            <StudentInput type='text' value={item} disabled={true} bordercolor={inputBorderColor} color={themeTextColor}/>
          </StudentSelectColumn>
          <StudentSelectColumn>
            <LightTypography text={`${t("quiz.Choice")} ${index + 1}`} />
            { !started ?
              <>
                <SelectDropDown
                  onChange={(value) => handleSelectChoice(index, value)}
                  placeholder={(t('course view.Select Choice'))}
                  width="100%"
                  fontSize='0.875rem'
                  // options={[]}
                  options={allAnswersMapped}
                  disabled={true}
                  value = {
                    matchInitialeValue &&
                    matchInitialeValue[
                      partIndex !== undefined &&
                      allData?.[questionIndex || 0]?.questionParts?.length > 0
                        ? `${allData?.[questionIndex || 0]?.title}_${allData?.[questionIndex || 0]?.questionParts?.[partIndex]?.title}`
                        : `${allData?.[questionIndex || 0]?.title}`
                    ]?.[index]?.right !== undefined
                      ? matchInitialeValue[
                          partIndex !== undefined &&
                          allData?.[questionIndex || 0]?.questionParts?.length > 0
                            ? `${allData?.[questionIndex || 0]?.title}_${allData?.[questionIndex || 0]?.questionParts?.[partIndex]?.title}`
                            : `${allData?.[questionIndex || 0]?.title}`
                        ]?.[index]?.right
                      : matchingResponse?.[index]?.right
                  }
                />

              </>
              :
              <SelectDropDown
                onChange={(value) => handleSelectChoice(index, value)}
                placeholder={(t('course view.Select Choice'))}
                width="100%"
                fontSize='0.875rem'
                options={allAnswersMapped}
                value = {
                  matchInitialeValue &&
                  matchInitialeValue[
                    partIndex !== undefined &&
                    allData?.[questionIndex || 0]?.questionParts?.length > 0
                      ? `${allData?.[questionIndex || 0]?.title}_${allData?.[questionIndex || 0]?.questionParts?.[partIndex]?.title}`
                      : `${allData?.[questionIndex || 0]?.title}`
                  ]?.[index]?.right !== undefined
                    ? matchInitialeValue[
                        partIndex !== undefined &&
                        allData?.[questionIndex || 0]?.questionParts?.length > 0
                          ? `${allData?.[questionIndex || 0]?.title}_${allData?.[questionIndex || 0]?.questionParts?.[partIndex]?.title}`
                          : `${allData?.[questionIndex || 0]?.title}`
                      ]?.[index]?.right
                    : matchingResponse?.[index]?.right
                }
              />
            }

          </StudentSelectColumn>
          {isCorrect[`${isCorrectKey}_${item}`] !== null && isCorrect[`${isCorrectKey}_${item}`] !== undefined && isCorrect[`${isCorrectKey}_${item}`] !== "pending" && showPublishGrade &&(
            isCorrect[`${isCorrectKey}_${item}`] === true ? <SvgDone /> : <SvgFalse />
          )
          }
        </StudentSelectContainer>
      ))}
      {partIndex === undefined && showPublishGrade && allData && allData[questionIndex || 0] && findStudentComment(studentResponse, allData[questionIndex || 0].questionId, allData[questionIndex || 0].type) !== "" ?
        <>
          {/* The comment of the instructor if the question is normal goes here */}
          <MediumTypography
            text='Instructor Comment'
          />
          <CommentContainer>
            <LightTypography
              text={findStudentComment(studentResponse,
                (questionIndex !== undefined && partIndex !== undefined && allData && allData[questionIndex].questionParts)
                  ? allData[questionIndex].questionParts[partIndex].id
                  : (questionIndex !== undefined && allData ? allData[questionIndex].questionId : undefined),
                (questionIndex !== undefined && partIndex !== undefined && allData && allData[questionIndex].questionParts)
                  ? allData[questionIndex].questionParts[partIndex].type
                  : (questionIndex !== undefined && allData ? allData[questionIndex].type : undefined)
              )}
              fontSize='.8rem'
            />
          </CommentContainer>
        </>
        : ""}
      {partIndex !== undefined && showPublishGrade && allData && allData[questionIndex || 0] && findStudentComment(studentResponse, allData[questionIndex || 0].questionParts[partIndex].id, allData[questionIndex || 0].questionParts[partIndex].type) !== "" ?
        <>
          {/* The comment of the instructor if the question is composed goes here */}
          <MediumTypography
            text='Instructor Comment'
          />
          <CommentContainer>
            <LightTypography
              text={findStudentComment(studentResponse,
                (questionIndex !== undefined && partIndex !== undefined && allData && allData[questionIndex].questionParts)
                  ? allData[questionIndex].questionParts[partIndex].id
                  : (questionIndex !== undefined && allData ? allData[questionIndex].questionId : undefined),
                (questionIndex !== undefined && partIndex !== undefined && allData && allData[questionIndex].questionParts)
                  ? allData[questionIndex].questionParts[partIndex].type
                  : (questionIndex !== undefined && allData ? allData[questionIndex].type : undefined)
              )}
              fontSize='.8rem'
            />
          </CommentContainer>

        </>
        : ""}
    </StudentSelectContainerParent>
  );
}

export default StudentQuizSelect;
