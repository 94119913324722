import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

interface CheckboxProps {
  borderRadius?: string; // Define the borderRadius prop
  contentDisplay?: string;
  correct?: boolean | string;
  bgColor?: string;
}

export const CheckBox = styled.input<CheckboxProps>`
  width: 20px;
  height: 20px;
  /* border-radius: 50%; */
  background: ${(props) =>
    props.bgColor || "linear-gradient(180deg, #2d3741 0%, #576371 100%)"};

  appearance: none;
  border: 1px solid #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  transition: background-color 0.3s ease;

  &:checked {
    background: ${({ correct, bgColor }) =>
      correct === "true"
        ? "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%), linear-gradient(0deg, #5dd3b3, #5dd3b3)"
        : correct === "false"
        ? "#D85D66"
        : "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%), linear-gradient(0deg, #5dd3b3, #5dd3b3)"};
    border: 1px solid #fff;
  }
`;

export const StudentCheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 4rem;
  gap: 1rem;
`;

export const StudentCheckboxRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
`;
export const StudentCheckboxRowSurvey = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
`;
export const CommentContainer = styled.div<StyleThemeInterface>`
  width: 100%;
  gap: 1rem;
  padding: 1.5rem;
  border-radius: 10px;
  background: ${(props) => props.bgcolor || "rgba(255, 255, 255, 0.03)"};
`;
