/**
 * Function: updateMessageSubject
 * Description: This function updates the message subject based on the provided id and value.
 * @param {string} id - The unique identifier of the message subject.
 * @param {string} value - The new value to be assigned to the message subject.
 */
export const updateMessageText = (id: string, value: string,text:any) => {
  text((prevState: any) => ({
    ...prevState,
    [id]: value, // Update the message subject with the provided value.
  }));
};



/**
 * Function: handleChangeShapeToggle
 * Description: This function toggles the shape at the specified index in the shapeToggles array.
 * @param {number} index - The index of the shape toggle to be toggled.
 * @param {Array<boolean>} ShapeToggle - The array of shape toggles to be updated.
 * @param {React.Dispatch<React.SetStateAction<Array<boolean>>>} setShapeToggle - The function to update the shapeToggles array.
 */
export const handleChangeShapeToggle = (
  index: number,
  ShapeToggle: Array<boolean>,
  setShapeToggle: React.Dispatch<React.SetStateAction<Array<boolean>>>
) => {
  const updatedToggles = [...ShapeToggle]; // Create a copy of shapeToggles array.
  updatedToggles[index] = !updatedToggles[index]; // Toggle the value at the specified index.
  setShapeToggle(updatedToggles); // Update the state with the updated toggles.
};



/**
 * Function: handleOptionButtonClick
 * Description: This function handles the button click for selecting options.
 * @param {number} index - The index of the clicked option.
 * @param {number} selectedOptionIndex - The currently selected option index.
 * @param {React.Dispatch<React.SetStateAction<number>>} setSelectedOptionIndex - The function to update the selected option index state.
 */
export const handleOptionButtonClick = (
  index: number,
  selectedOptionIndex: number | null,
  setSelectedOptionIndex: React.Dispatch<React.SetStateAction<number | null>>
) => {
  setSelectedOptionIndex(selectedOptionIndex === index ? null : index);
};

