import styled from "styled-components";
import { breakpoints } from "../../theme";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";
import { StyleProps } from "../../App.style";

interface TableTdProps {
  color?: string;
}

export const GenerateAttendanceWrapper = styled.div`

`
export const GoBackRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.62rem;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 1.25rem;
`;
export const CourseNameRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.63rem;
`;
export const CourseNameWithSemester = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.63rem;
  margin-bottom: 1.25rem;
  margin-top:0.8rem;
`;
export const SessionNbWithDate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.63rem;
  /* margin-bottom: 1.25rem; */
`;
export const ViewAllWithSearchRowWithSession = styled.div`
  display: flex;
  /* flex-direction: column; */

  justify-content: space-between;
  /* align-items: flex-end; */
  /* gap: 1.25rem; */
  & > :first-child {
    /* cursor: pointer; */
  }
`;
export const ViewAllWithSearchRow = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: flex-end;
  gap: 1.25rem;
  & > :first-child {
    cursor: pointer;
  }
`;
export const TableSection = styled.div`
  width: 100%;
  display: flex;
  min-height: fit-content;
  overflow-x: hidden;
  margin-top: 1.25rem;
  position: relative;
  scroll-behavior: smooth;
  /* background-color: red; */
`;

export const TableContentHolder = styled.table`
  width: 100%;
  /* min-width: 800px; */
  border: none;
  /* background-color: yellow; */
`;

export const TableContent = styled.table`
  max-height: 100%;
  height: fit-content;
  /* width: 100%; */
  border-collapse: collapse;
  overflow-x: auto;
  white-space: nowrap;
  border: none;
  width: 100%;
  /* background-color: white; */
`;

export const TableThead = styled.thead`
  display: grid;
  grid-template-columns: 15rem 15rem 12rem 12rem 12rem 12rem;
  align-items: center;
  /* gap: 6.5rem; */
  border: none;
  white-space: nowrap;
`;

export const TableTh = styled.th`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 48%;
  padding: 0 2.5rem 0rem 1rem;
  /* gap: 25.5rem; */
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
`;

export const TableTr = styled.tr`
  /* width: auto; */
  &:nth-child(even) {
    /* background: rgba(255, 255, 255, 0.03); */
    border: none;
  }
`;
export const TableTr1 = styled.tr<StyleThemeInterface>`
  display: grid;
  grid-template-columns: 15rem 15rem 12rem 12rem 12rem 12rem;

  white-space: nowrap;
  padding-left: 1rem;
  /* padding-right: 50%; */
  align-items: center;
  min-width: 100vw;
  height: 5.625rem;
  &:nth-child(even) {
    background:${props =>props.nth_child_bgcolor};
    border: none;
  }
`;

export const TableTbody = styled.tbody`
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
`;

export const TableTd = styled.td<TableTdProps>`
  border: none;
  height: 3.5625rem;
  width: 1.8rem;
  border-collapse: collapse;
  padding: 1rem 1rem 0rem 0.3rem;
  text-align: center;
  /* max-width: 23.1rem; */
  color: ${({ color }) => color};
`;
export const TableTd1 = styled.td<TableTdProps>`
  border: none;
  height: 3.5625rem;
  border-collapse: collapse;
  padding: 0rem 2rem 0rem 2rem;
  text-align: center;
  /* min-width: 10.1rem; */
  color: ${({ color }) => color};
`;

export const RadioButtonsRow = styled.div`
  display: flex;
`;
export const CheckboxLabel = styled.label`
  display: flex;
  /* align-items: center; */
  cursor: pointer;
  @media (max-width: ${breakpoints.tablets}) {
    margin-top: 0.2rem;
    margin-right: 0.6rem;
  }
`;

export const GroupRadio = styled.fieldset``;
export const RadioButton = styled.input<StyleThemeInterface>`
  width: 1.25rem;
  height: 1.25rem;
  border: 0.05em solid #fff;
  border-radius: 50%;

  background:${props =>props.bgcolor};
  border-radius: 50%;
  appearance: none;
  cursor: pointer;
  /* opacity: 0; */
  /* position: absolute;
  transform: translate(-50%, -50%); */
 &:checked {
    background: linear-gradient(to bottom, #5dd3b3, #2f3b40);
  }
`;
export const ProfileImageHolder = styled.img`
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* position: relative; */
`;
export const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.62rem;
  margin: 2.12rem 0rem;
`;
export const ButtonCancel = styled.button`
  width: 13.4375rem;
  height: 3.125rem;
  border-radius: 6.25rem;
  border: 1px solid #d85d66;
  background: none;
  cursor: pointer;
`;
export const ButtonAdd = styled.button`
  outline: none;
  border: none;
  width: 13.4375rem;
  height: 3.125rem;
  border-radius: 6.25rem;
  background: #5dd3b3;
  cursor: pointer;
`;


export const PointerContainer =styled.div<StyleProps>`
display: flex;
position:relative;
cursor:pointer;
padding: ${({ padding }) => padding || "0.3rem 0.7rem"};
color:${({ color }) => color };

`;

export const AbsoluteDiv=styled.div`
position:absolute;


`