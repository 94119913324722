import React, { FC, useRef, useEffect } from "react";
import { Typography } from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import ToDoCard from "../ToDoCard/ToDoCard";
import { useDrop } from "react-dnd";
import type { Identifier } from "dnd-core";
import {
  ParentContainer,
  ToDoContainer,
  EmptyCardContainer,
  TaskContainer,
} from "./ToDo.style";
import { DragItem, ToDoProps } from "./ToDoInterface";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";

const ToDo: FC<ToDoProps> = ({
  title,
  text,
  tasks,
  handleDropTask,
  allTasks,
  handleEditTask,
  removeTask,
  handleAfterDropTask,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const minHeightOfSingleTaskItem = 8.875 * 16; // Convert rem to pixels (assuming 1rem = 16px)
  const gapBetweenTasks = 0.62 * 16;
  const { t } = useTranslation();
  const { To_Do_bgColor, boxShadowV2, bghelp } = useRecoilValue(ColorSelector);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "TASK",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      const dragIndex = allTasks.findIndex((task) => task.id === item.id);
      let hoverIndex = 0;
      if (!ref.current) {
        return;
      }

      if (tasks.length === 0) {
        handleDropTask(dragIndex, text, 0);
      } else {
        const hoverBoundingRect = ref.current.getBoundingClientRect();
        const clientOffset = monitor.getClientOffset();

        if (!clientOffset) {
          return;
        }
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        const hover = Math.min(
          Math.max(
            0,
            Math.floor(
              hoverClientY / (minHeightOfSingleTaskItem + gapBetweenTasks)
            )
          ),
          tasks.length
        );
        if (hover >= 0 && hover < tasks.length) {
          const hovered = tasks[hover]?.id;
          hoverIndex = allTasks.findIndex((task) => task.id === hovered);

          if (hoverIndex >= 0) {
            handleDropTask(dragIndex, text, hoverIndex);
          }
        }
      }
    },
    drop() {
      handleAfterDropTask();
    },
  });
  drop(ref);

  return (
    <ToDoContainer
      bgcolor={To_Do_bgColor}
      boxshadow={boxShadowV2}
      ref={ref}
      data-handler-id={handlerId}
    >
      <>
        <ParentContainer>
          <Typography text={`${title}`} fontSize="1rem" />
        </ParentContainer>
        {tasks.length > 0 ? (
          <TaskContainer style={{ borderColor: bghelp }}>
            {tasks
              .sort((a: any, b: any) => a.idx - b.idx)
              .map((task, index) => {
                const realIndex = allTasks.findIndex((t) => t.id === task.id);
                return (
                  <ToDoCard
                    allTasks={allTasks}
                    task={task}
                    key={index}
                    index={realIndex}
                    handleEditTask={handleEditTask}
                    removeTask={removeTask}
                  />
                );
              })}
          </TaskContainer>
        ) : tasks.length < 1 ? (
          <EmptyCardContainer>
            {localStorage.getItem("language") === "tr" ? (
              <>
                <Typography
                  text={`${
                    text === "To Do"
                      ? t("to do list.Under To Do")
                      : text === "IN PROGRESS"
                      ? t("to do list.Under Progress")
                      : text === "Done"
                      ? t("to do list.Under Done")
                      : ""
                  }`}
                />
                <Typography text={t("to do list.There is nothing")} />
              </>
            ) : (
              <>
                <Typography text={t("to do list.There is nothing")} />
                <Typography
                  text={`${
                    text === "To Do"
                      ? t("to do list.Under To Do")
                      : text === "IN PROGRESS"
                      ? t("to do list.Under Progress")
                      : text === "Done"
                      ? t("to do list.Under Done")
                      : ""
                  }`}
                />
              </>
            )}
          </EmptyCardContainer>
        ) : (
          ""
        )}
      </>
    </ToDoContainer>
  );
};

export default ToDo;
