import styled from "styled-components";

export const Container = styled.div`
display: flex;
gap: 0.63rem;
flex-wrap: wrap;
`
export const InfoCont = styled.div`
height: fit-content;
min-width: 15.625rem;
border-radius: 50%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 1.25rem;
background: #364350;
padding: 1.25rem 0;
`

export const ProfileAndRank = styled.div`
position: relative;
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 1.31rem;
`;

export const ProfileContainer = styled.div<{ bgimage?: string, width?: string, height?:string }>`
  width: ${({ width }) => (width ? width : "5rem")};
  height: ${({ height }) => (height ? height : "5rem")};
  border-radius: 50%;
  background-image: url(${(props) => props.bgimage});
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #707070;
`;

export const Rank = styled.div`
position: absolute;
bottom: -0.69rem;
width: 1.4375rem;
height: 1.4375rem;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
background: linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%);
z-index: 5;
`;

export const FlexDiv = styled.div<{ gap?: string }>`
display: flex;
align-items: center;
gap: ${({ gap }) => (gap ? gap : "0.5rem")};
`;