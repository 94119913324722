import { CourseActivityStudentIDActions } from "./CourseActivityStudentIDInterface";

export const CourseActivityStudentHeaderData :  CourseActivityStudentIDActions[] = [

  {text: "Activity/Resource Name"},
  {text: "Start Date"},
  {text: "Due Date"},
  {text: "Close Date"},
  {text: "Status"},
  {text: "Grade"},
  {text: ""},
  
]