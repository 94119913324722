import React, { useState } from "react";
import {
  ContactTitleMessage,
  HelpMessageBody,
  HelpMessageCell,
  HelpMessageContainer,
  HelpMessageTitle,
  MessageHelpsCell,
  MessageHelpsLayout,
  MessageHelpsLayoutVB,
  MiniMessageHelpsLayoutVB,
} from "./MessageHelps.style";
import {
  LightTypography,
  MediumTypography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import { Link, useNavigate } from "react-router-dom";
import { SvgSVGRepMinus } from "../../elements/Icons";
import { SearchInputPlaceDiv } from "../ContactMessages/ContactMessages.style";
import SearchElement from "../../elements/searchElement/SearchElement";
import { FaqData } from "../Faq/faqData";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

export const MessageHelps = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>("");
  const [ContactIsOpen, setContactIsOpen] = useState<boolean>(true);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(
    null
  );

  const handleChangeContact = () => {
    setContactIsOpen(!ContactIsOpen);
  };

  const handleToggleItem = (index: number) => {
    setSelectedItemIndex(selectedItemIndex === index ? null : index);
  };
  const filteredData = FaqData.filter(
    (val) =>
      val.question?.toLowerCase().includes(searchText.toLowerCase()) ||
      val.answer?.toLowerCase().includes(searchText.toLowerCase())
  );
  const { bg_selectColor, themeTextColor, theme } =
    useRecoilValue(ColorSelector);
  const navigate = useNavigate();
  return (
    <>
      {ContactIsOpen ? (
        <MessageHelpsLayout bgColor={bg_selectColor}>
          <MessageHelpsCell onClick={() => navigate("/help")}>
            <MediumTypography text={t("Chat.Help Center")} fontSize="0.75rem" />
          </MessageHelpsCell>

          <MessageHelpsCell onClick={handleChangeContact}>
            <MediumTypography
              text={t("Chat.Contact support")}
              fontSize="0.75rem"
            />
          </MessageHelpsCell>
          {/* <MessageHelpsCell>
            <MediumTypography
              text={t("Chat.Submit feedback")}
              fontSize="0.75rem"
            />
          </MessageHelpsCell>
          <MessageHelpsCell>
            <MediumTypography
              text={t("Chat.Ask the Community")}
              fontSize="0.75rem"
            />
          </MessageHelpsCell> */}
        </MessageHelpsLayout>
      ) : (
        <MessageHelpsLayoutVB bgColor={bg_selectColor}>
          <MiniMessageHelpsLayoutVB>
            <ContactTitleMessage>
              <MediumTypography
                text={t("Chat.Contact support")}
                fontSize="0.75rem"
              />
              <SvgSVGRepMinus
                style={{ cursor: "pointer", color: themeTextColor }}
                onClick={handleChangeContact}
              />
            </ContactTitleMessage>
            <SearchInputPlaceDiv>
              <SearchElement
                backGroundPosition="0.5rem"
                Placeholder={t("Chat.How can we Help?")}
                PaddingLeft="2.2rem"
                onChange={(e) => setSearchText(e.target.value)}
                leftPositionIcon="0.8rem"
                paddingLeftIcon="1.25rem"
                topPositionIcon="0.5rem"
              />
            </SearchInputPlaceDiv>
          </MiniMessageHelpsLayoutVB>
          <HelpMessageContainer theme={theme}>
            {filteredData.map((val, index) => (
              <HelpMessageCell key={index}>
                <HelpMessageTitle onClick={() => handleToggleItem(index)}>
                  <TypographyRegularDescription
                    text={t(val.question)}
                    fontSize="0.75rem"
                  />
                </HelpMessageTitle>
                {selectedItemIndex === index && (
                  <HelpMessageBody onClick={() => handleToggleItem(index)}>
                    <LightTypography
                      text={t(val.answer)}
                      fontSize="0.75rem"
                      lineHeight="1.125rem"
                    />
                  </HelpMessageBody>
                )}
              </HelpMessageCell>
            ))}
          </HelpMessageContainer>
        </MessageHelpsLayoutVB>
      )}
    </>
  );
};
