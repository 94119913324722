import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.62rem 1rem;
  &:hover {
    & .iconItem {
      color: #fff;
      transition: all 0.2s ease-in;
    }
  }
`;
interface IconProps {
  color: string;
}
export const Icon = styled.div<IconProps>`
  color: ${(props) => props.color};
  transition: background-color 0.3s ease-in-out;
`;

export const Tooltip = styled.span<StyleThemeInterface>`
  visibility: hidden;
  white-space: nowrap;
  /* height: 20px; */
  background: ${(props) => props.bgcolor};
  box-shadow: ${(props) => props.boxshadow};
  padding: 0.5rem 1.38rem 0.63rem 1.25rem;

  text-align: center;
  border-radius: 1.25rem;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  &:dir(ltr) {
    width: 7.7rem;
    left: 7.3rem;
  }
  &:dir(rtl) {
    width: 5.5rem;
    right: 0.7rem;
  }

  /* right: -2rem; */
  /* top: 1.5rem; */
  /* border-radius: 15px; */
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-family: "Primary Font-Medium";
  font-size: 0.625rem;
  z-index: 1;
`;

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-image: linear-gradient(50deg, #5dd3b3 3.83%, #2f6a5a 95.8%);
    ${Tooltip} {
      visibility: visible;
      opacity: 1;
    }
  }
`;
