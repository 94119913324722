import React, { useEffect } from "react";
import ScrollLeftAndRight from "../../../elements/ScrollLeftAndRight/ScrollLeftAndRight";
import AnalyticScrollablePlace from "./AnalyticScrollabelPlace/AnalyticScrollabelPlace";
import {
  AnalyticsSurveyBackLeft,
  AnalyticsSurveyPiesContainer,
} from "./AnalyticScrollabelPlace/AnalyticScrollabelPlace.style";
import AnalyticsBackPage from "../../AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticsBackPage";
import AnalyticsSurveyPiesTwoValues from "../AnalyticsSurveyPiesTwoValues/AnalyticsSurveyPiesTwoValues";
import AnalyticsSurveyLikertScale from "../AnalyticsSurveyLikertScale/AnalyticsSurveyLikertScale";
import AnalyticsSurveyPiesRating from "../AnalyticsSurveyPiesRating/AnalyticsSurveyPiesRating";
import AnalyticsPieChartMultipleChoice from "../AnalyticsPieChartMultipleChoice/AnalyticsPieChartMultipleChoice";
import AnalyticsSurveyYesNoTrueFalse from "../AnalyticsSurveyPiesTwoValues/AnalyticsSurveyYesNoTrueFalse";
import { AnalyticsPiesInterface } from "../../../interfaces/AnalyticsCommonInterfaces";

// AnalyticsSurveyPies Component
const AnalyticsSurveyPies = ({
  ChosenQuestion,
  setChosenQuestion,
}: AnalyticsPiesInterface) => {
  // Retrieve QuestionType from ChosenQuestion
  const QuestionType = ChosenQuestion.QuestionType;

  // Render component based on QuestionType
  return (
    <AnalyticsSurveyPiesContainer>
      {/* AnalyticsBackPage Component */}
      <AnalyticsSurveyBackLeft>
        <AnalyticsBackPage
          text="Main table"
          onClick={() => setChosenQuestion(null)}
        />
      </AnalyticsSurveyBackLeft>

      {/* ScrollLeftAndRight Component */}
      <ScrollLeftAndRight
        content={
          <AnalyticScrollablePlace
            ChosenQuestion={ChosenQuestion}
            setChosenQuestion={setChosenQuestion}
          />
        }
        FixedBodyContainer={
          <div>
            {/* Render different components based on QuestionType */}
            {QuestionType === "Yes/No" || QuestionType === "True/False" ? (
              <AnalyticsSurveyYesNoTrueFalse data={ChosenQuestion} />
            ) : QuestionType === " Short answer" ||
              QuestionType === "Essay" ||
              QuestionType === "Numerical" ? (
              <AnalyticsSurveyPiesTwoValues data={ChosenQuestion} />
            ) : QuestionType === "Likert Scale" ? (
              <AnalyticsSurveyLikertScale data={ChosenQuestion} />
            ) : QuestionType === "Rating" ? (
              <AnalyticsSurveyPiesRating data={ChosenQuestion} />
            ) : QuestionType === "Multiple Choice" ? (
              <AnalyticsPieChartMultipleChoice data={ChosenQuestion} />
            ) : null}
          </div>
        }
      />
    </AnalyticsSurveyPiesContainer>
  );
};

export default AnalyticsSurveyPies;
