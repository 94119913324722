import React, { useState, useEffect } from "react";
import { TextEditor } from "../../../elements/TextEditor/TextEditor";
import { DropZoneForm } from "../../dropZone/DropZoneForm";
import { ContentR } from "../../CourseTimeline/utils/CourseResourcesInterface";
import TinyEditor from "../../../elements/tinyEditor/TinyEditor";
import { useTranslation } from "react-i18next";
import { ErrorDiv } from "../../../elements/ErrorDiv/Errordiv.style";

interface AssignmentWorkProps {
  assignment: any;
  onDescriptionChange: (newDescription: string) => void;
  onFilesChange: (newFiles: File[]) => void;
  initialDescription: string;
  initialFiles: File[];
  errors: any;
  setLoading: (loading: boolean) => void;
}

type Submission = {
  classId: number;
  description: string;
  files: File[];
};

export default function AssignmentWork({
  assignment,
  onDescriptionChange,
  onFilesChange,
  initialDescription,
  initialFiles,
  errors,
  setLoading,
}: AssignmentWorkProps) {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState(initialFiles);

  // Remove the useState for description and selectedFiles here
  // Instead, rely on the parent component to manage and pass these values

  // Set The Text answer value
  const handleDescriptionChange = (newDescription: string) => {
    onDescriptionChange(newDescription); // Pass the new description to the parent component
  };

  // set the file answer
  const handleFilesChange = (newFiles: File[]) => {
    setSelectedFiles(newFiles); // Update the local state
    //initialFiles && setSelectedFiles(initialFiles);
    onFilesChange(newFiles);
  };

  // This is a collections of documents types that the drop box accept it.
  const accF: { [key: string]: string[] } = {
    "application/pdf": [".pdf"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    "application/vnd.ms-powerpoint": [".ppt"],
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      [".pptx"],
    "application/vnd.ms-excel": [".xls"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
    "image/jpeg": [".jpeg", ".jpg"],
    "image/png": [".png"],
    "image/gif": [".gif"],
    // Add more image types if needed
  };


  return (
    <>
      <div></div>
      <div
        style={{ display: "flex", flexDirection: "column", rowGap: "1.25rem" }}
      >
        {assignment.answerBox === 1 && ( // This is the text editor where the student will type the answer
          <TinyEditor
            placeholder={(t('course view.Type here your work'))}
            onChange={handleDescriptionChange}
            height="21.063rem"
            initialValue={initialDescription}
          />
        )}
        {/* This is where the student can select a document or a file to submit it */}
        {assignment.fileSubmission === 1 && (
          <DropZoneForm
            setLoading={setLoading}
            header=""
            onFilesChange={handleFilesChange}
            selectedFiles={initialFiles}
            acceptedTypes={accF}
          />
        )}
        <ErrorDiv>{errors.description}</ErrorDiv>
        <ErrorDiv>{errors.files}</ErrorDiv>
      </div>
    </>
  );
}
