import styled from "styled-components";
import { breakpoints } from "../../theme";

interface TableTdProps {
    color?: string;
  }

export const GradesTableShowHolder = styled.div`
width: 100%;
min-height: fit-content;
/* max-height: 25.125rem;; */
border-radius: 1.25rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
padding: 1.25rem 1rem 1.06rem 1.25rem;
`;

export const AboveTableRow = styled.div`
  width: 100%;
  padding-right: 1REM;
  /* margin-bottom: 2.25rem; */
`
export const TitlesWithSearch = styled.div`
 width: 100%;
  display: flex;
  row-gap: 0.62rem;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
`

export const TitlesWrapper = styled.div`
  display: flex;
  column-gap: 0.63rem;
  align-items: center;
  color: #fff;
  
`
export const TitleWithArrow = styled.div`
  display: flex;
  column-gap: 0.62rem;
  align-items: center;
  
`;

export const TitleWithArrow2 = styled.div`
  display: flex;
  column-gap: 0.62rem;
  align-items: center;
  &:hover{
    color: #5dd3b3;
    cursor: pointer;
  }
`

export const SearchIconHolder = styled.div`
  position: absolute;
  top: 0.88rem;
  right: 1.25rem;
  color: #576371;
`;

export const TableSmallSection = styled.div`
 width: 11.3rem;
  display: flex;
  min-height: fit-content;
   overflow-x: hidden;
   margin-top: 1.25rem;
   position: relative;
`
export const TableSection = styled.div`
  width: 100%;
  display: flex;
  min-height: fit-content;
   overflow-x: hidden;
   margin-top: 1.25rem;
   position: relative;
   scroll-behavior: smooth;
   /*
  &::-webkit-scrollbar {
    height: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5dd3b3;
    border-radius: 0.625rem;
    box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.25);
  }

  &::-webkit-scrollbar-track {
    background-color: #2d3741;
    border-radius: 0.625rem;
  } */
  /* @media (max-width: ${breakpoints.small}) {
    width: 100%;
    flex-wrap: wrap;
    row-gap: 1.25rem;
  } */
`;
export const TableFixedContentHolder =styled.table`
width: auto;
  min-width: 800px;
  border-collapse: collapse;
  border: 0.5px;
  
`
export const TableContentHolder = styled.table`
  width: auto;
  min-width: 800px;
  
  /* overflow-x: auto; */

  /* @media (max-width: ${breakpoints.small}) {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #5dd3b3;
      border-radius: 0.625rem;
      box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.25);
    }

    &::-webkit-scrollbar-track {
      background-color: #2d3741;
      border-radius: 0.625rem;
    }
  } */
`;

export const ArrowContainer = styled.div`
margin-top:1.25rem;
display: flex;
justify-content:center;
align-items:center;
/* gap:1.25rem; */
/* padding-right:1.51rem; */
`;

export const TableContent = styled.table`
  max-height: 100%;
  height: fit-content;
  /* width: 100%; */
  border-collapse: collapse;
  overflow-x: auto;
  white-space: nowrap;
`;

export const TableThead = styled.thead`
  border: 0.2px solid #576371;
  white-space: nowrap;
 
`;

export const TableTh = styled.th`
  /* padding: 0 0 1.38rem 0; */
  border-collapse: collapse;
  white-space: nowrap;
`;

export const TableTbody = styled.tbody`
  border-collapse: collapse;
  white-space: nowrap;
`;

export const TableTr1 =styled.tr`
/* width: 100%; */
`
export const TableTh1 = styled.th`
  /* border-collapse: collapse; */
  padding: 1rem 1rem 1.38rem 1rem;
  border: 0.2px solid #576371;
  /* margin: 1rem; */

`

export const TableTr = styled.tr`

  &:nth-child(even) {
    background: rgba(255, 255, 255, 0.03);
  }
`;

export const TableTd = styled.td<TableTdProps>`
  border: 0.2px solid #576371;
  border-collapse: collapse;
  padding: 1.25rem 0 1.25rem 0;
  text-align: center;
  /* min-width: 10.1rem; */
  color: ${({ color }) => color};
`;

export const TableFixedTd = styled.div`
 border: 0.5px solid #576371;
  border-collapse: collapse;
  padding: 1.25rem 0 1.22rem 0;

  /* text-align: center; */
  min-width: 10.1rem;
  color: ${({ color }) => color};
`

export const ReducedPaddingRow = styled.tr`
  padding-top: 0rem; /* Adjust the top padding as needed */
  padding-bottom: 0rem; /* Adjust the bottom padding as needed */
  border: 0.2px solid #576371;
  border-collapse: collapse;
  /* padding: 1.69rem 0 1.62rem 0; */
  text-align: center;
  /* min-width: 10.1rem; */
  color: ${({ color }) => color};
`;
export const ReducedPaddingcell = styled.td`
  border: 0.2px solid #576371;
  border-collapse: collapse;
  height: 1rem;
  padding-top: 0.63rem;
  padding-bottom: 0.63rem;
  /* padding-right: 1.25rem; */
  /* padding-left: 1.25rem; */
  text-align: center;
  /* min-width: 10.1rem; */
  color: ${({ color }) => color};
  /* &:nth-child(1) {
    padding-left: 1.25rem;
  } */
`;

export const ReducedPaddingcellFixed = styled.td`
  border: 0.2px solid #576371;
  border-collapse: collapse;
  height: 1rem;
  padding-top: 0.63rem;
  padding-bottom: 0.63rem;
  /* padding-right: 1.25rem; */
  /* padding-left: 1.25rem; */
  text-align: center;
  min-width: 10.1rem;
  color: ${({ color }) => color};
  /* &:nth-child(1) {
    padding-left: 1.25rem;
  } */
`

// export const PaginationContainer = styled.div`
//   width: 100%;
//   margin-left: -2rem;
//   display: flex;
//   justify-content: center;
//   background-color: red;
// `;

export const DivArrowsWrapper = styled.div`
  width: 2.625rem;
  height: 1.25rem;
  border-radius: 1.25rem;
  background-color: #364350;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  /* position: absolute; */
  bottom: 1.25rem;
  /* left: 38.255rem; */
  left: 48%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  & > :first-child {
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.dark.main_color};
      transition: color 0.2s ease-in-out;
    }
  }
  & > :last-child {
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.dark.main_color};
      transition: color 0.2s ease-in-out;
    }
  }
`;
