import React from 'react'
import { CircularProgress, ProgressValue } from './ProgressCircle.style'
import { MediumTypography } from '../fonts/Fonts';

// Define the ProgressCircle component
const ProgressCircle = ({ progress }: any) => {
    return (
      <>
        <CircularProgress progress={progress}>
          <ProgressValue>
            <MediumTypography text={`${progress}%`} fontSize='0.5rem'/>
          </ProgressValue>
        </CircularProgress>
      </>
    );
  };

export default ProgressCircle