import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

interface ScrollabaleContainerProps {
  isBranched?: boolean;
}

export const Container = styled.div<StyleThemeInterface>`
  border-radius: 1.5625rem;
  border: ${(props) => props.theme !== "light" && "1px solid #576371"};
  background: ${(props) => props.bgcolor || "#2D3741"};
  box-shadow: ${(props) =>
    props.boxshadow || "0px 4px 20px 0px rgba(0, 0, 0, 0.25)"};
  min-height: 45.0625rem;
  max-width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  /* overflow:hidden; */
  @media (max-width: 760px) {
    width: auto;
  }
`;

export const BodyContainer = styled.div`
  width: 100%;
  max-width: 100%;
  margin-top: 1.88rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between !important;
  &:dir(ltr) {
    padding-right: 1rem;
  }
  &:dir(rtl) {
    padding-left: 1rem;
  }
  gap: 1.2rem;
  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ToolBarContainer = styled.div`
  width: 3rem;
  display: flex;
  align-items: flex-start;
  margin-top: 4rem;
`;

export const QuizButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
`;
export const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color:red; */
  flex: 1.3;

  &:last-child {
    /* background:greenyellow; */
    flex: 0.5;
    /* max-width:20rem; */
  }
`;

export const FlexDiv2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 1.2rem;
`;

export const ScrollabaleContainer = styled.div<ScrollabaleContainerProps>`
  height: ${(props) => (props.isBranched ? "45.0625rem" : "auto")};
  overflow-y: auto;
  border-radius: 0.625rem !important;
  width: 100%;
  &::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(
      --Green-Gradient,
      linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)
    );
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0.625rem;
  }
`;
