import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const AnswerCorrectionLayout = styled.div`
width:100%;
display:flex;
flex-direction:column;
gap: 0.25rem;

`;

export const AnswerCorrectionContainer = styled.div<StyleThemeInterface>`
width:100%;
display:flex;
flex-direction:column;
border-radius: 0.625rem;
padding: 1rem;
background: ${props =>props.bgcolor || "#364350"};


`;

export const AnswerCorrectionMappedContainer = styled.div`
width:100%;
display:flex;
gap: 1.25rem;

`;

export const AnswerCorrectionObject = styled.div`

display:flex;
flex:11;
padding:1.25rem;
gap:1.25rem;
`;

export const AnswerCorrectionAction = styled.div`
display: flex;
flex:1;
justify-content:center;
align-items:center;
gap:0.80rem;
/* background:yellow; */
padding-right:0.5rem;
`;

export const AnswerCorrectionLine = styled.div`
width: 1.3px;
background-color: rgba(87, 99, 113, 0.60);
height: 1.0625rem;
`;