import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const AddChatLayout = styled.div`
display: flex;
flex-direction:column;
gap:1.75rem;
width:45rem;
/* margin-bottom:1rem; */
position:relative;
padding:1.6rem 0.3rem 1.1rem 0.3rem;

/* margin-top:-.8rem; */
/* background-color:green; */
/* z-index:4000000; */
`;

export const AddChatSearch = styled.div`
display: flex;
width:98%;
height:3rem;
position:absolute;
top:0.3rem;
z-index:40000;
/* background-color:#384450; */
`;

export const AddChatRender = styled.div<StyleThemeInterface>`
display: flex;
gap:2rem;
width:100%;
flex-wrap:wrap;
justify-content:flex-start;
z-index:1;
margin-top:3.5rem;
min-height:9rem;
max-height:15rem;
/* background-color:red; */
overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
  
    background: ${props =>props.theme === "light" ? "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)" :"linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)"}  ;
    border-radius: 0.625rem;

  }
 
  &::-webkit-scrollbar-track {
    /* background: ${props =>props.theme === "light" ? "#F7F7F8" :"#2d3741"}  ; */
    background:transparent;
    
    border-radius: 0.625rem;
  }
`;

export const AddChatCell = styled.div`
display: flex;
flex-direction:column;
gap:1.25rem;
align-items:center;
cursor:pointer;
`;
export const AddChatCellImg = styled.img`
display: flex;
width: 3.125rem;
height: 3.125rem;
flex-shrink: 0;
fill: url(<path-to-image>), lightgray 50% / cover no-repeat;
border-radius:50%;
stroke-width: 5px;
stroke: #707070;

`;