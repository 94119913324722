import styled, { css } from "styled-components";

interface LoaderIconHolderProps {
  display?: string;
}

export const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
`;

export const ButtonContent = styled.button<{
  backgroundColor?: string;
  bordercolor?: string;
}>`
  width: 8.8125rem;
  height: 2.125rem;
  font-size: 0.875rem;
  border-radius: 1.25rem;
  border: ${(props) =>
    props.bordercolor ? `1px solid ${props.bordercolor}` : "1px solid #5DD3B3"};

  color: #5dd3b3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5rem;
  cursor: pointer;
  font-family: "Primary Font-Bold";

  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
`;

export const LoaderIconHolder = styled.div<LoaderIconHolderProps>`
  position: absolute;
  top: 0.45rem;
  right: 0.8rem;
  color: #576371;
  width: min-content;
  display: ${({ display }) => display};
`;
