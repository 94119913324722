import React from "react";
import {
  AnalyticsUserTableActions,
  AnalyticsUserViewCompoTable,
  AnalyticsUserViewCompoTableBody,
  AnalyticsUserViewCompoTableBodyRow,
  AnalyticsUserViewCompoTableHeader,
  TableActionsProperties,
  TableBodyCell,
  TableHeaderCell,
} from "./AnalyticsUserViewCompo.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../elements/fonts/Fonts";
import { SvgSimpleArrow } from "../../elements/Icons";
import { AnalyticsUserViewCompoHeaderData } from "./AnalyticsUserViewCompoData";
import { Link } from "react-router-dom";
interface AnalyticsUserViewCompoTableDisplayProps {
  handleOnClick?: () => void;
  Title: string;
  Data: any[];
  ShowBody: boolean;
}
const AnalyticsUserViewCompoTableDisplay = ({
  handleOnClick,
  Title,
  Data,
  ShowBody,
}: AnalyticsUserViewCompoTableDisplayProps) => {
  return (
    <AnalyticsUserViewCompoTable>
      <AnalyticsUserTableActions>
        <Typography text={Title} />
        <TableActionsProperties onClick={handleOnClick}>
          <SvgSimpleArrow />
          <MediumTypography
            text={`${Data.length} Classes`}
            fontSize="0.875rem"
            lineHeight="1.375rem"
          />
        </TableActionsProperties>
      </AnalyticsUserTableActions>
      {ShowBody && (
        <>
          <AnalyticsUserViewCompoTableHeader>
            {AnalyticsUserViewCompoHeaderData.map((val) => (
              <TableHeaderCell key={val.id}>
                <Typography text={val.label} />
              </TableHeaderCell>
            ))}
            <TableHeaderCell>
              <Typography text="Class Average Grade" />
            </TableHeaderCell>
          </AnalyticsUserViewCompoTableHeader>
          <AnalyticsUserViewCompoTableBody>
            {Data.map((DataItem, index) => (
              <Link
                to={`/courseView/Analytics/${DataItem.id}`}
                style={{ width: "100%" }}
              >
                {" "}
                <AnalyticsUserViewCompoTableBodyRow key={DataItem.id}>
                  {/*   <AnalyticsUserViewCompoTableBodyRowChild> */}
                  <TableBodyCell>
                    <Typography
                      text={DataItem.CourseName || ""}
                      fontSize="0.875rem"
                    />
                  </TableBodyCell>
                  <TableBodyCell>
                    <LightTypography
                      text={DataItem.TermSemester || ""}
                      fontSize="0.75rem"
                    />
                  </TableBodyCell>
                  <TableBodyCell>
                    <LightTypography
                      text={DataItem.StartDate || ""}
                      fontSize="0.75rem"
                    />
                  </TableBodyCell>
                  <TableBodyCell>
                    <LightTypography
                      text={DataItem.CloseDate || ""}
                      fontSize="0.75rem"
                    />
                  </TableBodyCell>
                  <TableBodyCell>
                    <LightTypography
                      text={DataItem.Status || ""}
                      fontSize="0.75rem"
                    />
                  </TableBodyCell>
                  <TableBodyCell>
                    <LightTypography
                      text={DataItem.CourseAverageGrade || ""}
                      fontSize="0.75rem"
                      color="#5DD3B3"
                    />
                  </TableBodyCell>
                  {/* </AnalyticsUserViewCompoTableBodyRowChild> */}
                </AnalyticsUserViewCompoTableBodyRow>
              </Link>
            ))}
          </AnalyticsUserViewCompoTableBody>
        </>
      )}
    </AnalyticsUserViewCompoTable>
  );
};

export default AnalyticsUserViewCompoTableDisplay;
