import axios from 'axios';
import React, { useContext, useEffect } from 'react'
import { post } from '../../utils/AxiosRequests';
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../utils/StringManipulation";
import { ClassContext } from '../../Views/dashboard/UnstructuredContext';
interface HtmlProps {
    HtmlId: string;
    classId: string;
}

export default function HtmlViewer({ HtmlId, classId }: HtmlProps) {
    const { progressChanged, setProgressChange } = useContext(ClassContext)

    const { user } = useAuth0();
    const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
    const provider = auth0ProviderSplitter(user?.sub || "");
    useEffect(() => {
        const fetchDocument = async () => {
            const tenantName = localStorage.getItem("tenant");
            try {
                const trackingData = {
                    classSourceId: classId,
                    length: "",
                    percentage: 100,
                    refId: HtmlId,
                    type: "Html",
                    subId: subIdSplitted,
                    provider:provider,
                }
                const trackinResponse = await post(`completionTracking/create/${tenantName}`, trackingData)
                setProgressChange(!progressChanged);
                setProgressChange(!progressChanged);
            } catch (error) {
                console.error("Error fetching document:", error);
            }
        };
        fetchDocument();
    }, [HtmlId]);

    // JSX content should be placed here
    return (
        <>
        </>
    );
}

