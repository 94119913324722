import styled from "styled-components";
import { StyleThemeInterface } from "../../../../interfaces/StyleThemeInterface";

export const BlackLine = styled.div`
  max-height: 2.75rem;
  min-height: 2.75rem;
  background: #18181f;
`;

export const SideToolsContainer = styled.div<StyleThemeInterface>`
  /* width: 100%; */

  /* max-height: 41.875rem; */
  /* height: fit-content; */
  height: 34rem;
  /* display: flex;
flex-direction: column; */
  background: ${(props) => props.bgcolor || " #384450"};
  box-shadow: ${(props) => props.boxshadow};
  width: 23rem;
  &:dir(ltr) {
    margin-right: -1.25rem;
  }
  &:dir(rtl) {
    margin-left: -1.25rem;
  }
`;
