import React from "react";
import { LightTypography, Typography } from "../../elements/fonts/Fonts";
import { Container, Title } from "./LimitedChoicesMessage.style";
import { SvgInfo } from "../../elements/Icons";

const LimitedChoicesMessage = () => {
  return (
    <Container>
      <Title>
        <SvgInfo color="#fff"/>
        <Typography text="Limited Choice" fontSize="1.125rem" />
      </Title>
      <LightTypography
        text="Choose another option your instructor limited the choices"
        fontSize="0.875rem"
      />
    </Container>
  );
};

export default LimitedChoicesMessage;
