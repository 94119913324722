import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  CourseLabelStyle,
  CourseLabelsWrapper,
  CourseLabelsHolder,
  ButtonsDiv,
  FlexContainer,
  ButtonStyled,
} from "./CourseLabel.style";
import { CoursesNumber } from "../CoursesNumber/CoursesNumber";
import { courseLabelData, courseLabelTeacherData } from "../CourseLabelData";
import { Typography } from "../../fonts/Fonts";
import { SvgNextIcon } from "../../Icons";
import { theme } from "../../../theme";
import { useTranslation } from "react-i18next";
import { toArabicDigits } from "../../../utils/formatDayArabic";
import {
  auth0ProviderSplitter,
  auth0SubIdSplitter,
} from "../../../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
interface label {
  justifyContent?: string;
  enrollments: any[];
}
export const CourseLabel = ({
  justifyContent,
  enrollments,
}: label): JSX.Element => {
  const { pathname } = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [CompletedClassesData, setCompletedClassesData] = useState<
    any | any[]
  >();
  const { t } = useTranslation();
  const { user } = useAuth0();
  const tenantName = localStorage.getItem("tenant");
  const courseLabelDataState = [
    {
      courseNumber: enrollments.length,
      color: "#21D6F8",
      gradientColor: ["#21D6F8", "#4FC7DD"],
      title: t("dashboard.ongoing classes"),
    },
    {
      courseNumber: 0,
      color: "#5dd3b3",
      gradientColor: [
        theme.dark.gradient_color_courseLabel[0],
        theme.dark.gradient_color_courseLabel[1],
      ],
      title: t("dashboard.completed classes"),
    },
  ];
  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === courseLabelDataState.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? courseLabelDataState.length - 1 : prevIndex - 1
    );
  };

  const isPrevDisabled = activeIndex === 0;
  const isNextDisabled = activeIndex === courseLabelDataState.length - 1;

  const showSlidingEffect = windowWidth < 649;

  const fetchCompletedClassesData = async () => {
    const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
    const provider = auth0ProviderSplitter(user?.sub || "");
    const response = await axios.get(
      `https://dz0k14jayb.execute-api.eu-west-1.amazonaws.com/dev/classes/completed/count/${subIdSplitted}/${tenantName}?provider=${provider}`
    );
    const fetchData = response.data.data;
    setCompletedClassesData(fetchData);
  };

  useEffect(() => {
    fetchCompletedClassesData();
  }, []);

  return (
    <CourseLabelsHolder>
      <CourseLabelsWrapper justifycontent={justifyContent || "space-between"}>
        {showSlidingEffect ? (
          courseLabelDataState.map((course, index) => (
            <CourseLabelStyle
              key={index}
              style={{
                color: course.color,
                display: index === activeIndex ? "block" : "none",
              }}
            // index={index}
            >
              <FlexContainer>
                <CoursesNumber
                  courseNumber={course.courseNumber.toString()}
                  color={course.color}
                  gradientColor={course.gradientColor}
                />
                <Typography fontSize="0.875" text={`${course.title}`} />
              </FlexContainer>
            </CourseLabelStyle>
          ))
        ) : (
          //  courseLabelDataState.map((course, index) => (
          <>
            <FlexContainer>
              <CoursesNumber
                courseNumber={toArabicDigits(enrollments.length.toString())}
                color="#21D6F8"
                gradientColor={["#21D6F8", "#4FC7DD"]}
              />
              <Typography
                fontSize="0.875"
                text={`${t("dashboard.ongoing classes")}`}
                color="#21D6F8"
              />
            </FlexContainer>

            <FlexContainer>
              <CoursesNumber
                courseNumber={
                  CompletedClassesData
                    ? toArabicDigits(CompletedClassesData.toString())
                    : toArabicDigits((0).toString())
                }
                color="#5dd3b3"
                gradientColor={[
                  theme.dark.gradient_color_courseLabel[0],
                  theme.dark.gradient_color_courseLabel[1],
                ]}
              />
              <Typography
                fontSize="0.875"
                text={`${t("dashboard.completed classes")}`}
                color="#5dd3b3"
              />
            </FlexContainer>
          </>
        )}
      </CourseLabelsWrapper>
      <ButtonsDiv>
        <ButtonStyled
          style={{
            color: isPrevDisabled ? "gray" : "white",
            marginTop: "-0.4rem",
            transform: " rotate(180deg)",
          }}
          onClick={handlePrev}
          disabled={isPrevDisabled}
        >
          <SvgNextIcon />
        </ButtonStyled>
        <ButtonStyled
          style={{ color: isNextDisabled ? "gray" : "white" }}
          onClick={handleNext}
          disabled={isNextDisabled}
        >
          <SvgNextIcon />
        </ButtonStyled>
      </ButtonsDiv>
    </CourseLabelsHolder>
  );
};
