import { FC } from "react";
import {
  DescriptionMainWrapper,
  DescriptionWrapper,
  HtmlContentWrapper,
} from "./Description.style";
import descriptionData from "./descriptionData";
import { Typography, LightTypography } from "../../../elements/fonts/Fonts";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

interface DescriptionProps {
  ParentClassData: any;
}

const Description: FC<DescriptionProps> = ({ ParentClassData }) => {
  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };
  const { t } = useTranslation();
  return (
    <>
      {ParentClassData && ParentClassData.description !== null && (
        <DescriptionMainWrapper>
          <Typography
            text={`${t("forms.Description")} :`}
            fontSize="1.375rem"
          />
          <DescriptionWrapper>
            <HtmlContentWrapper
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  decodeHtml(ParentClassData.description)
                ),
              }}
            ></HtmlContentWrapper>
            {/* <LightTypography
          text={descriptionData.text}
          fontSize="0.875rem"
          lineHeight="1.375rem"
        /> */}
          </DescriptionWrapper>
        </DescriptionMainWrapper>
      )}{" "}
    </>
  );
};

export default Description;
