import React from "react";
import { SvgExcel, SvgExtract, SvgMedia, SvgPdf, SvgPptx, SvgSmalMp4media, SvgSmallBrowsing, SvgSmallExcel, SvgSmallPDF, SvgSmallPowerPoint, SvgSmallWord, SvgWord } from "../elements/Icons";

export const contentTypeGenerater = (fileName: String) => {
  const lastDotIndex = fileName.lastIndexOf(".");
  const extension = fileName.slice(lastDotIndex + 1).toLowerCase();
  switch (extension) {
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "webp":
      return "image/webp";
    case "doc":
      return "application/msword";
    case "docx":
      return "application/vnd.openxmlformats";
    case "pdf":
      return "application/pdf";
    case "ppt":
      return "application/vnd.ms-powerpoint";
    case "pptx":
      return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    case "xls":
      return "application/vnd.ms-excel";
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "csv":
      return "text/csv";
    case "mp4":
      return "video/mp4";
    case "mpeg":
      return "video/mpeg";
    case "mov":
      return "video/quicktime";
    case "m4v":
      return "video/x-m4v";
    default:
      break;
  }
};


export const contentSvgGenerator = (fileName: string ,text: any ) => {
  const lastDotIndex = fileName.lastIndexOf(".");
  const extension = fileName.slice(lastDotIndex + 1).toLowerCase();
  switch (extension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
      return React.createElement(SvgMedia);
    case 'webp':
    case 'mp4':
    case 'mpeg':
    case 'mov':
    case 'm4v':
      case 'mp3':
      return React.createElement(SvgSmalMp4media);
    case 'doc':
    case 'docx':
      return React.createElement(SvgSmallWord);
    case 'pdf':
      return React.createElement(SvgSmallPDF);
    case 'ppt':
    case 'pptx':
      return React.createElement(SvgSmallPowerPoint);
      case 'com':
        case 'org':
          case 'edu':
          return React.createElement(SvgSmallBrowsing);
    case 'xls':
    case 'xlsx':
    case 'csv':
      return React.createElement(SvgSmallExcel);
      case 'txt':
    
          return React.createElement(SvgSmallPowerPoint);
          case 'zip':
            case 'rar':
              return React.createElement(SvgExtract);
    default:
      return text;
  }
  
};
