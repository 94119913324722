import styled from "styled-components";

export const FlexDiv = styled.div<{ gap?: string }>`
display: flex;
align-items: center;
gap: ${({ gap }) => (gap ? gap : "0.5rem")};
`;

export const SwitchButton = styled.div<{ bgColor?: string, color?: string, borderColor?: string }>`
width: 8.25rem;
height: 2.1875rem;
border-radius: 6.25rem;
display: flex;
justify-content: center;
align-items: center;
font-family: "Primary Font-Medium";
font-size: 0.875rem;
background-color: ${({ bgColor }) => (bgColor ? bgColor : "transparent")};
color: ${({ color }) => (color ? color : "#5DD3B3")};
border: 1px solid #5DD3B3;
cursor: pointer;
box-shadow: 0 0 0 rgba(0, 0, 0, 0);
transition: box-shadow 0.3s ease;
&:hover {
    box-shadow: 0 0 1rem rgba(42, 243, 156, 0.3);
  }
`;

export const RuleBookCont = styled.div`
display: flex;
flex-direction: column;
width: 100%;
gap: 0.63rem;
margin-bottom: 1.25rem;
`;

export const RuleBook = styled.div`
display: flex;
padding: 1.25rem;
justify-content: space-between;
border-radius: 0.625rem;
background: #364350;
height: fit-content;
width: 100%;
`;

export const RuleBookDate = styled.div`
display: flex;
flex-direction: column;
gap: 0.63rem
`;