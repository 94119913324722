import React, { useState } from "react";
import AssignmentSubmissionTableCompo from "../../AnalyticComplexSelectedTables/AssignmentSubmissionCompo/AssignmentSubmissionTable";
import { AssignmentSubmissionData } from "./AssignmentSubmissionData";
import { AnalyticMainBoxContainer } from "../../AnalyticComplexSelectedTables/AnalyticsAllAssigment.style";
import { ExcelDownload } from "../../../utils/ExcelGlobalFunction";
import SurveyReusableHeader from "../../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableHeader";
import AssignmentSubmissionDetailsHeaderFilter from "./AssignmentSubmissionDetailsHeaderFilter";
import Pagination from "../../../elements/Pationation/Pagination";
import { filterSubmissionDataGet } from "./Function";
interface AssignmentSubmissionTableInterface {
  HeaderTextClick?: () => void;
}
const AssignmentSubmissionTable = ({
  HeaderTextClick,
}: AssignmentSubmissionTableInterface) => {
  const [searchText, setSearchText] = useState("");
  const [SubmittedSearchText, setSubmittedSearchText] = useState("");
  const [limit, setLimit] = useState(AssignmentSubmissionData.Students?.length);
  const itemsPerPage = limit;
  const [currentPage, setCurrentPage] = useState(1);
  const [ShowPagination, setShowPagination] = useState(false);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const filteredData = filterSubmissionDataGet(
    AssignmentSubmissionData.Students, // Provide the array of student submissions
    searchText, // Provide the search text
    SubmittedSearchText, // Provide the submission status search text
    startIndex, // Provide the start index for slicing the filtered data
    endIndex // Provide the end index for slicing the filtered data
  );
  const AssignmentDueDate = AssignmentSubmissionData?.DueDate;
  const AssignmentCutOffEDate = AssignmentSubmissionData?.CutOffDate;

  return (
    <AnalyticMainBoxContainer>
      <SurveyReusableHeader
        setShowPagination={setShowPagination}
        data={AssignmentSubmissionData?.Students}
        title="Participation Details"
        onClickExcelDowload={() =>
          ExcelDownload(
            AssignmentSubmissionData?.Students,
            "Participation Details"
          )
        }
        setSearchText={setSearchText}
        setLimit={setLimit}
        FilteredOptions={
          <AssignmentSubmissionDetailsHeaderFilter
            setText={setSubmittedSearchText}
          />
        }
        HeaderTextClick={HeaderTextClick}
      />
      <AssignmentSubmissionTableCompo
        filteredData={filteredData}
        AssignmentCutOffEDate={
          AssignmentCutOffEDate ? new Date(AssignmentCutOffEDate) : new Date()
        }
        AssignmentDueDate={
          AssignmentDueDate ? new Date(AssignmentDueDate) : new Date()
        }
      />
      {ShowPagination && (
        <Pagination
          currentPage={currentPage}
          total={AssignmentSubmissionData.Students?.length}
          limit={itemsPerPage}
          onPageChange={(page: number) => setCurrentPage(page)}
          justifyContent="left"
          Padding="0 0 0 5.5rem"
          BoxSize="1.85rem"
          ScrollTop={false}
          MarginTop="2rem"
        />
      )}
    </AnalyticMainBoxContainer>
  );
};

export default AssignmentSubmissionTable;
