import { SideBarCourseIcons } from "./courseToolbarInterface";

import {
  SvgAssignmentIcon,
  SvgOnlineSessionIcon,
  SvgMessagingIcon,
  SvgTextIcon,
  SvgCourseIcon,
  SvgFolderIcon,
  SvgWebsiteIcon,
  SvgSaveCourseIcon,
  SvgCloudIcon,
  SvgMonitorIcon,
  SvgImagesIcon,
  SvgSurveyIcon,
  SvgVideoNew,
  SvgDocument,
  SvgLinkTree,
  SvgTeams,
} from "../../elements/Icons";

export const aboveLine: SideBarCourseIcons[] = [
  {
    id: 1,
    name: "assignment.Assignment",
    type: "Assignment",
    icon: SvgAssignmentIcon,
    color: "#5DD3B3",
  },
  {
    id: 2,
    name: "quiz.Quiz",
    type: "Quiz",
    icon: SvgTextIcon,
    color: "#96B4E3",
  },
  {
    id: 3,
    name: "online session.Online Session",
    type: "Online_Session",
    icon: SvgOnlineSessionIcon,
    color: "#0B8FFF",
  },
  {
    id: 4,
    name: "survey.Survey",
    type: "Survey",
    icon: SvgSurveyIcon,
    color: "#D9E4E8",
  },
  {
    id: 5,
    name: "forum.Discussion Forum",
    type: "Forum",
    icon: SvgMessagingIcon,
    color: "#8CEDEA",
  },
  {
    id: 6,
    name: "forms.MS Teams Call",
    type: "Teams",
    icon: SvgTeams,
    color: "#4E5FBF",
  },
];

export const underLine: SideBarCourseIcons[] = [
  {
    id: 7,
    name: "file.File",
    type: "File",
    icon: SvgCourseIcon,
    color: "#3DA4FC",
  },
  {
    id: 8,
    name: "document.Document",
    type: "Document",
    icon: SvgDocument,
    color: "#21D6F8",
  },
  {
    id: 9,
    name: "folder.Folder",
    type: "Folder",
    icon: SvgFolderIcon,
    color: "#5D69FD",
  },
  {
    id: 10,
    name: "link.link",
    type: "Link",
    icon: SvgWebsiteIcon,
    color: "#8E5DFD",
  },
  // {
  //   id: 10,
  //   name: "link tree.Link Tree",
  //   type: "Link_Tree",
  //   icon: SvgLinkTree,
  //   color: "#CED35D",
  // },
  // {
  //   id: 11,
  //   name: "video.video",
  //   type: "Video",
  //   icon: SvgVideoNew,
  //   color: "#B757E2",
  // },
  // {
  //   id: 12,
  //   name: "scorm.Scorm",
  //   type: "Scorm",
  //   icon: SvgCloudIcon,
  //   color: "#DB56A3",
  // },
  {
    id: 13,
    name: "html.Html",
    type: "Html",
    icon: SvgMonitorIcon,
    color: "#D04B4B",
  },
  // {
  //   id: 14,
  //   name: "Image.Image",
  //   type: "Picture",
  //   icon: SvgImagesIcon,
  //   color: "#D07E4B",
  // },
  {
    id: 15,
    name: "forms.Media",
    type: "Media",
    icon: SvgImagesIcon,
    color: "#D07E4B",
  },
];
