 export interface ScrumStudentDetailTableInterface {

  StdCode:string;
  StdName:string;
  status?:string;
  ResponsesDate?:Date;
  DueDate?:Date;
  LastModification?:Date;
  TimeSpent?:number | string;
  Grade?:number;
  ProfilePic?:string[];
}


interface ScrumDetailTableInterface {
  Graded?: boolean;
  MaxGrade?:number;
  Students:{
  StdCode:string;
  StdName:string;
  status?:string;
  ResponsesDate?:Date;
  DueDate?:Date;
  LastModification?:Date;
  TimeSpent?:number;
  Grade?:number;
  ProfilePic?:string}[];
}

export const ScrumDetailTableData: ScrumDetailTableInterface ={
  Graded: true,
  MaxGrade:10,
  Students:[
  {StdCode:"asfr43rfdxwqrfgredfdsv",
  StdName:"Hadi Shouman",
  status:"Completed",
  ResponsesDate:new Date("2023-10-02T10:30:00Z"),
  DueDate: new Date("2023-10-15T16:30:00Z"),
  LastModification: new Date("2023-10-02T10:30:00Z"),
  TimeSpent: 10,
  Grade: 7,
  ProfilePic: require("../../../assets/AliAhmad.png"),
},
{StdCode:"safwetf23254rtfwefcsd-vsf-dvcsfcb",
StdName:"Sultana Shouman",
status:"Completed",
ResponsesDate:new Date("2023-10-05T10:30:00Z"),
DueDate: new Date("2023-10-15T16:30:00Z"),
LastModification: new Date("2023-10-02T10:30:00Z"),
TimeSpent: 12,
Grade: 4,
ProfilePic: require("../../../assets/NourInstructor.jpg"),
},
{StdCode:"adasdsad-vsadassf-dvcsfcb",
StdName:"Sultana Nasser",
status:"Completed",
ResponsesDate:new Date("2023-10-05T10:30:00Z"),
DueDate: new Date("2023-10-15T16:30:00Z"),
LastModification: new Date("2023-10-02T10:30:00Z"),
TimeSpent: 7,
// Grade: 9,
ProfilePic: require("../../../assets/AyaInstructor.jpg"),
},
{StdCode:"sdfsdfsadsad-vsf-dvcsfcb",
StdName:"Isaac Shouman",
status:"Completed",
ResponsesDate:new Date("2023-10-05T10:30:00Z"),
DueDate: new Date("2023-10-15T16:30:00Z"),
LastModification: new Date("2023-10-02T10:30:00Z"),
TimeSpent: 12,
Grade: 10,
ProfilePic: require("../../../assets/KhaledInstructor.jpg"),
},
{StdCode:"sfdasdfgtg56tgf-vsf-dvcsfcb",
StdName:"Isaac Bahsoun",
status:"Completed",
ResponsesDate:new Date("2023-10-05T10:30:00Z"),
DueDate: new Date("2023-10-15T16:30:00Z"),
LastModification: new Date("2023-10-02T10:30:00Z"),
TimeSpent: 11,
Grade: 6,
ProfilePic: require("../../../assets/KhaledInstructor.jpg"),
},
{StdCode:"zxzscdsxzcxz-vsf-dvcsfcb",
StdName:"Ali Bahsoun",
status:"Incomplete",
ResponsesDate:new Date("2023-10-05T10:30:00Z"),
DueDate: new Date("2023-10-15T16:30:00Z"),
LastModification: new Date("2023-10-02T10:30:00Z"),
TimeSpent: 15,
Grade: 6,
},
{StdCode:"xcxsczyh678s-vsf-dvcsfcb",
StdName:"Ali Mallah",
status:"Incomplete",
ResponsesDate:new Date("2023-10-05T10:30:00Z"),
DueDate: new Date("2023-10-15T16:30:00Z"),
LastModification: new Date("2023-10-02T10:30:00Z"),
TimeSpent: 12,
Grade: 9,
ProfilePic: require("../../../assets/AliAhmad.png"),
},

],
};

// export const ScrumDetailUnGradedTableData: ScrumDetailTableInterface[] =[
//   {StdCode:"asfr43rfdxwqrfgredfdsv",
//   StdName:"Hadi Shouman",
//   status:"Completed",
//   ResponsesDate:new Date("2023-10-02T10:30:00Z"),
//   DueDate: new Date("2023-10-15T16:30:00Z"),
//   LastModification: new Date("2023-10-02T10:30:00Z"),
//   TimeSpent: "8h 30m",

//   ProfilePic: require("../../../assets/AliAhmad.png"),
// },
// {StdCode:"safwetf23254rtfwefcsd-vsf-dvcsfcb",
// StdName:"Sultana Shouman",
// status:"Completed",
// ResponsesDate:new Date("2023-10-05T10:30:00Z"),
// DueDate: new Date("2023-10-15T16:30:00Z"),
// LastModification: new Date("2023-10-02T10:30:00Z"),
// TimeSpent: "8h 30m",
// ProfilePic: require("../../../assets/NourInstructor.jpg"),
// },
// {StdCode:"adasdsad-vsadassf-dvcsfcb",
// StdName:"Sultana Nasser",
// status:"Completed",
// ResponsesDate:new Date("2023-10-05T10:30:00Z"),
// DueDate: new Date("2023-10-15T16:30:00Z"),
// LastModification: new Date("2023-10-02T10:30:00Z"),
// TimeSpent: "8h 30m",
// ProfilePic: require("../../../assets/AyaInstructor.jpg"),
// },
// {StdCode:"sdfsdfsadsad-vsf-dvcsfcb",
// StdName:"Isaac Shouman",
// status:"Completed",
// ResponsesDate:new Date("2023-10-05T10:30:00Z"),
// DueDate: new Date("2023-10-15T16:30:00Z"),
// LastModification: new Date("2023-10-02T10:30:00Z"),
// TimeSpent: "8h 30m",
// ProfilePic: require("../../../assets/AyaInstructor.jpg"),
// },
// {StdCode:"sfdasdfgtg56tgf-vsf-dvcsfcb",
// StdName:"Isaac Bahsoun",
// status:"Completed",
// ResponsesDate:new Date("2023-10-05T10:30:00Z"),
// DueDate: new Date("2023-10-15T16:30:00Z"),
// LastModification: new Date("2023-10-02T10:30:00Z"),
// TimeSpent: "8h 30m",
// ProfilePic: require("../../../assets/KhaledInstructor.jpg"),
// },
// {StdCode:"zxzscdsxzcxz-vsf-dvcsfcb",
// StdName:"Ali Bahsoun",
// status:"Completed",
// ResponsesDate:new Date("2023-10-05T10:30:00Z"),
// DueDate: new Date("2023-10-15T16:30:00Z"),
// LastModification: new Date("2023-10-02T10:30:00Z"),
// TimeSpent: "8h 30m",
// ProfilePic: require("../../../assets/KhaledInstructor.jpg"),
// },
// {StdCode:"xcxsczyh678s-vsf-dvcsfcb",
// StdName:"Ali Mallah",
// status:"Completed",
// ResponsesDate:new Date("2023-10-05T10:30:00Z"),
// DueDate: new Date("2023-10-15T16:30:00Z"),
// LastModification: new Date("2023-10-02T10:30:00Z"),
// TimeSpent: "8h 30m",
// ProfilePic: require("../../../assets/AliAhmad.png"),
// },

// ]