import styled from "styled-components";

export const ProfileRowContainer= styled.div`
width: 100%;
display: flex;
align-items:center;
justify-content:space-between;
/* background-color:aqua; */
padding:0 1.10rem 1rem 1.10rem;
/* border-radius: 1.25rem 0rem 0rem 0rem; */
`;

export const PRMiniRow= styled.div`
display: flex;
align-items:center;
gap:0.30rem;
/* &:nth-child(2){
  background-color:green;
  gap:0.50rem;
} */

`;

export const PRSVGAction= styled.div`
padding:0.3rem;
cursor: pointer;
`;