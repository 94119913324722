import styled from "styled-components";

export const NewAnnouncementsHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2.86rem;
  & > :first-child {
    margin-bottom: 0.94rem;
  }
  & > :nth-child(2) {
    margin-bottom: 1.88rem;
  }
  & > :last-child {
    margin-top: 1.19rem;
    align-self: end;
  }
`;

export const ButtonsContainer = styled.div`
display: flex;
align-items: center;
gap: 0.62rem;
`
