import styled from "styled-components";
import { breakpoints } from "../../../../theme";

export const DashboardResourcesHolder = styled.div<{ allResources: boolean }>`
  &:dir(ltr) {
    padding: ${(props) =>
      props.allResources ? "1.25rem 2rem 3rem 0;" : "1.25rem 0 3rem 0"};
  }
  &:dir(rtl) {
    padding: ${(props) =>
      props.allResources ? "1.25rem 0 3rem 2rem ;" : "1.25rem 0 3rem 0"};
  }
  border-radius: 1.25rem;
  background: transparent;
  height: 100% !important;
  max-height: ${(props) => (props.allResources ? "100%" : "22rem")};

  margin-bottom: 1rem;
`;
export const ResourcesMainDiv = styled.div<{ allResources: boolean }>`
  display: flex;
  width: 100%;

  max-height: ${(props) => (props.allResources ? "100%" : "22rem")};
`;
export const ResourcesCardsHolder = styled.div`
  display: flex;

  flex-direction: column;
  max-height: 100% !important;
  &:last-child {
    /* background: yellow !important; */
    margin-bottom: 2rem;
  }
  /* row-gap: 0.63rem; */
`;
export const ResourcesTitleSliderStyle = styled.div`
  display: flex;
  flex-direction: column;
  /* row-gap: 0.63rem; */
  position: relative;
`;
export const DivArrowsWrapper = styled.div`
  padding: 0.25rem 0.62rem 0 0.63rem;
  width: 2.625rem;
  height: 1.25rem;
  border-radius: 1.25rem;
  background-color: #364350;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 1.25rem;
  /* left: 48%; */
  &:dir(ltr) {
    right: 3rem;
  }
  &:dir(rtl) {
    left: 3rem;
  }
  bottom: 0;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  @media (max-width: ${breakpoints.tablets}) {
    left: 46%;
  }
  @media (max-width: ${breakpoints.mobile}) {
    left: 45%;
  }

  & > :first-child {
    cursor: pointer;
  }
  & > :last-child {
    cursor: pointer;
    &:hover {
      /* color: ${({ theme }) => theme.dark.main_color}; */
      transition: color 0.2s ease-in-out;
    }
  }
`;

export const ResourcesSliderStyleN = styled.div`
  border-radius: 0.7rem;
  background: linear-gradient(90deg, #3b6663 2.37%, #2c3640 94.37%);
`;
export const ResourceDiv = styled.div`
  margin: 0;
  padding: 0;
`;
export const ModulesHolder = styled.div<{
  allResources: boolean;
  BorderColor?: string;
  boxShadow?: string;
}>`
  max-height: 23rem !important;

  /* max-height: 23.75rem !important; */
  overflow-y: auto;
  width: 25%;
  &:dir(ltr) {
    border-right: 1px solid ${(props) => props.BorderColor || "#576371"};
  }
  &:dir(rtl) {
    border-left: 1px solid ${(props) => props.BorderColor || "#576371"};
  }
  flex-direction: column;
  display: ${(props) => (props.allResources ? "none" : "flex")};
`;

export const ModuleHolder = styled.div<{
  activeModule: boolean;
}>`
  background: ${(props) =>
    props.activeModule
      ? "linear-gradient(90deg, #5DD3B3 0%, #2D3741 100%)"
      : "transparent"};
  &:dir(ltr) {
    padding: 0.56rem 0 0.56rem 2.56rem;
  }
  &:dir(rtl) {
    padding: 0.56rem 2.56rem 0.56rem 0;
  }
  cursor: pointer;
`;
export const ResourcesDivHolder = styled.div<{
  allResources: boolean;
}>`
  width: ${(props) => (props.allResources ? "100%" : "75%")};
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
`;
export const ResourcesHeaderDiv = styled.div<{
  allResources: boolean;
}>`
  /* margin-bottom: 1rem; */
  /* margin-top: -1rem; */
  display: flex;
  /* &:dir(ltr) {
    padding-right: 2rem;
  }
  &:dir(rtl) {
    padding-left: 2rem;
  } */
  justify-content: ${(props) =>
    props.allResources ? "space-between" : "flex-end"};
  align-items: center;
  width: 100%;
`;
export const BackToModules = styled.div`
  display: flex;

  &:dir(ltr) {
    padding-left: 1.37rem;
  }
  &:dir(rtl) {
    padding-right: 1.37rem;
  }
  column-gap: 1rem;
  cursor: pointer;
`;
export const SearchDiv = styled.div`
  height: 2.75rem;
  width: 34%;
`;
export const HeaderTitles = styled.div`
  &:dir(ltr) {
    padding-right: 2.2rem;
    padding-left: 1.31rem;
  }
  &:dir(rtl) {
    padding-left: 2.2rem;
    padding-right: 1.31rem;
  }
  align-self: end;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -0.25rem;
`;
export const ViewAllT = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;
  min-width: max-content;

  /* padding-left: 2rem; */
`;
export const ModuleEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`;
export const ChoseModel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20rem;
  height: 22rem;
  /* margin-top: -3rem; */
  align-self: center;
  text-align: center;
  margin-top: -2rem;
`;
export const LoadingNoResources = styled.div`
  /* margin-bottom: 4rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ResourceTypeTitleHolder = styled.div<{
  allResources: boolean;
}>`
  /* height: 2.3rem !important; */
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  &:dir(ltr) {
    padding-left: 1.31rem;
  }
  &:dir(rtl) {
    padding-right: 1.31rem;
  }
  background: ${(props) =>
    props.allResources
      ? "none"
      : "linear-gradient(90deg, #415160 0%, #293440 107.88%)"};
`;
export const ResourcesHoler = styled.div<{
  allResources: boolean;
  Scroll?: string;
}>`
  /* max-height: 24rem; */
  margin-top: ${(props) => (props.allResources ? "-1rem" : "0")};
  /* border-top: ${(props) =>
    props.allResources ? "none" : "1px solid #576371"}; */

  /* border-top: ; */
  display: flex;
  flex-direction: column;
  row-gap: 2rem !important;
  /* overflow-y: ${(props) => props.Scroll || "auto"}; */
  overflow-y: auto;
  max-height: ${(props) => (props.allResources ? "100%" : "80%")};
  scroll-behavior: smooth;
  &&::-webkit-scrollbar {
    width: 0 !important;
  }
`;
