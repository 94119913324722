import React, { FC, useState } from "react";
import { Container } from "../../elements/StyledContainerForm/StyledContainerForm.style";
import {
  LightTypography,
  MediumTypography,
  PrimaryFontBoldText,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import { ContentProps } from "../../Views/teacherView/Course-Creator/courseCreatorInterface";
import {
  LabelDiv,
  BtnsDiv,
  LabelBtnsDiv,
  ButtonsDiv,
  FlexBetween,
  FlexDiv,
  RadioButton,
  Input,
} from "./ScormForm.style";
import StyledInput from "../../elements/StyledInput/StyledInput";
import { TextEditor } from "../../elements/TextEditor/TextEditor";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { DropZoneForm } from "../../elements/dropZone/DropZoneForm";
import { ReactComponent as SvgCloudIcon } from "../../assets/icons/Cloud 1.svg";
import Toggle from "../../elements/Toogle/toogle";
import TwoDatePicker from "../../elements/TwoDatePicker/TwoDatePicker";
import CancelButton from "../../elements/StyledButton/CancelButton";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import { SvgClose } from "../../elements/Icons";
import { useStateContext } from "../../contexts/ContextProvider";
import { DateAndTimeDiv } from "../OnlineSessionForm/OnlineSessionForm.style";
import SelectDate from "../../elements/DatePicker/inputDate";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import { useTranslation } from "react-i18next";

interface ContentFormProps {
  topicContents?: ContentProps[];
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
  setShowEditContent: React.Dispatch<React.SetStateAction<boolean>>;
  showEditContent: boolean;
  setContentTitleInTopic: (contentTitle: string) => void;
  editContent: (contentId: number, newTitle: string) => void;
  newContentTitle: string | undefined;
  contentId: number | undefined;
  activeGetModulesWithTopics: () => void;
  newTopicRefId: number;
}

const ScormForm: FC<ContentFormProps> = ({
  topicContents,
  setShow,
  handleSubmit,
  setShowEditContent,
  showEditContent,
  setContentTitleInTopic,
  editContent,
  newContentTitle,
  contentId,
  activeGetModulesWithTopics,
  newTopicRefId,
}) => {
  const {
    setContent_Title,
    contentType,
    setContent_Data,
    getContent_Data,
    contentIdToEdit,
    topicRefId,
    content_Index,
    setIsContentDrop,
  } = useStateContext();

  type FileInterface = {
    path: string;
    name?: string;
  };

  const [visibleStudents, setVisibleStudents] = useState(false);
  const [showTimeVisibility, setShowTimeVisibility] = useState(false);
  const [scormName, setScormName] = useState("");
  const [description, setDescription] = useState("");
  const [attempts, setAttempts] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [error, setError] = useState(false);
  const [newFile, setNewFile] = useState<FileInterface>({ path: "" });
  const [isGraded, setIsGraded] = useState(false);
  const [GradeType, setGradeType] = useState("");
  const [maxGrade, setMaxGrade] = useState(0);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const classSourceId = useParams();
  const setClassSourceId = classSourceId.id;
  const { isLoading, user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");

  const handleStartDate = (startDateTime: Date | null) => {
    setStartDate(startDateTime);
    setFromDate(startDateTime);
  };

  const handleEndDate = (endDateTime: Date | null) => {
    setEndDate(endDateTime);
    setToDate(endDateTime);
  };
  const handleChangeMaxGrade = (event: any) => {
    setMaxGrade(event.target.value);
  };

  const handleDescriptionChange = (value: any) => {
    setDescription(value);
  };

  const handleFile = async (value: any) => {
    setNewFile(value);
  };

  const handleToggleChangeVisible = (isChecked: boolean) => {
    setVisibleStudents(isChecked);
  };
  const handleToggleChangeGraded = (isChecked: boolean) => {
    setIsGraded(!isGraded);
  };

  const handleTwoDatePickerChange = (start: Date | null, end: Date | null) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleToggleChangeTime = (isChecked: boolean) => {
    if (isChecked) {
      setShowTimeVisibility(true); // Show Time Visibility when the toggle is on
    } else {
      setShowTimeVisibility(false);
    }
  };

  const handleContinueClick = () => {
    if (
      !scormName ||
      !description ||
      !attempts ||
      (showTimeVisibility && (!fromDate || !toDate))
    ) {
      setError(true);
    } else {
      setError(false);
      const scormData: any = {
        _id: contentIdToEdit,
        classSourceId: setClassSourceId,
        userSourceId: subId,
        provider: provider,
        contentIndex: content_Index,
        topicRefId: topicRefId,
        type: contentType,
        title: scormName,
        description: description,
        attempts: attempts,
        visibleStudents: visibleStudents,
        fromDate: fromDate,
        toDate: toDate,
      };
      if (isGraded) {
        if (startDate && endDate) {
          if (startDate > endDate) {
            toast.error("Start date must be smaller than end date");
            return;
          }
        } else {
          console.error("One or both dates are null");
        }
      }
    }
  };
  const { backgroundColor, themeTextColor, inputBorderColor, iconCloseColor } =
    useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  return (
    <Container
      themetextcolor={themeTextColor}
      backgroundcolor={backgroundColor}
      scrollbar={inputBorderColor}
    >
      <FlexBetween>
        <FlexDiv>
          <SvgCloudIcon />
          <PrimaryFontBoldText text="Scorm" fontSize="1.125rem" />
        </FlexDiv>
        {/* <SvgClose
          color={iconCloseColor}
          onClick={() => {
            setShow(false);
            setShowEditContent(false);
          }}
          style={{ cursor: "pointer" }}
        /> */}
      </FlexBetween>
      <LabelDiv>
        <LightTypography text="Scorm Name" fontSize="0.875rem" />
        <StyledInput
          placeholder={t("scorm.Enter Scorm Name")}
          type="text"
          onChange={(e) => setScormName(e.target.value)}
          width="100%"
        />
        {!scormName && error && <ErrorDiv>Please Enter Scrom Name</ErrorDiv>}
      </LabelDiv>

      <LabelDiv>
        <LightTypography
          text={t("scorm.File Description")}
          fontSize="0.875rem"
        />
        <div>
          <TinyEditor
            onChange={handleDescriptionChange}
            placeholder={t("general.Description")}
            initialValue=""
            height="13.25rem"
          />
        </div>
        {!description && error && (
          <ErrorDiv>{t("scorm.Please Enter Description")}</ErrorDiv>
        )}
      </LabelDiv>

      <DropZoneForm
        testDrop={handleFile}
        text={t("scorm.Click or Drag and Drop, to upload Scorm ( Max 100MB )")}
      />
      {/* <LabelBtnsDiv>
        <Toggle onToggle={handleToggleChangeGraded} id="roleToggle3" />
        <TypographyRegularDescription text="Graded" fontSize="1rem" />
      </LabelBtnsDiv> */}

      {/* {isGraded && (
        <>
          <FlexDiv>
            <RadioButton
              type="radio"
              name="GradeType"
              checked={GradeType === "Grade"}
              onChange={() => setGradeType("Grade")}
            />{" "}
            Grade
            <RadioButton
              type="radio"
              name="GradeType"
              checked={GradeType === "Rubric"}
              onChange={() => setGradeType("Rubric")}
            />{" "}
            Rubric Type
          </FlexDiv>
          <FlexDiv>
            <MediumTypography text="Maximum Grade :" fontSize="0.7rem" />
            <Input value={maxGrade} onChange={handleChangeMaxGrade} />
          </FlexDiv>
          <DateAndTimeDiv>
            <LabelDiv>
              <LightTypography text="Start Date" fontSize="0.875rem" />
              <SelectDate
                value={startDate}
                onChange={handleStartDate}
                width="30rem"
              />
            </LabelDiv>

            <LabelDiv>
              <LightTypography text="End Date" fontSize="0.875rem" />
              <SelectDate
                value={endDate}
                onChange={handleEndDate}
                width="30rem"
              />
            </LabelDiv>
          </DateAndTimeDiv>
        </>
      )} */}
      <LabelDiv>
        <LightTypography
          text={t("quiz.Number Of Attempts")}
          fontSize="0.875rem"
        />
        <StyledInput
          placeholder={t("quiz.Enter Number of Attempts")}
          type="number"
          onChange={(e) => setAttempts(e.target.value)}
          width="100%"
        />
        {!attempts && error && (
          <ErrorDiv>{t("quiz.Enter Number of Attempts")}</ErrorDiv>
        )}
      </LabelDiv>

      <BtnsDiv>
        <LabelBtnsDiv>
          <Toggle onToggle={handleToggleChangeVisible} id="roleToggle1" />
          <TypographyRegularDescription
            text={t("quiz.Make Visible for Students")}
            fontSize="1rem"
          />
        </LabelBtnsDiv>
        <LabelBtnsDiv>
          <Toggle onToggle={handleToggleChangeTime} id="roleToggle2" />
          <TypographyRegularDescription
            text={t("forms.Set Time Visibility Interval")}
            fontSize="1rem"
          />
          {showTimeVisibility && (
            <>
              <TwoDatePicker onDateChange={handleTwoDatePickerChange} />
              {(!fromDate || !toDate) && error && (
                <ErrorDiv>
                  {t("forms.Please Set Time Visibility Interval")}
                </ErrorDiv>
              )}
            </>
          )}
        </LabelBtnsDiv>
      </BtnsDiv>

      <ButtonsDiv>
        <CancelButton
          name={t("forms.Cancel")}
          onClickFunction={() => {
            setShow(false);
            setShowEditContent(false);
          }}
          color="#D85D66"
          borderColor="#D85D66"
          backgroundColor="transparent"
        />
        <CancelButton
          name={t("forms.Save")}
          onClickFunction={handleContinueClick}
          color="#fff"
          borderColor="#5DD3B3"
          backgroundColor="#5DD3B3"
        />
      </ButtonsDiv>
    </Container>
  );
};

export default ScormForm;
