import React, { FC, useState } from "react";
import {
  StatisticsContainer,
  GradeContainer,
  SeparateLine,
  Grade,
  GradeNumber,
  OverGrade,
} from "./StatisticsGrade.style";
import { Typography } from "../../../elements/fonts/Fonts";
import { StatisticsGradeProps } from "./StatisticsGradeInterface";
import { Grades } from "./StatisticsGradeIDatanterface";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import {
  formatDayContents,
  toArabicDigits,
} from "../../../utils/formatDayArabic";

const initialGrades: Grades[] = [
  {
    grade: "10",
    name: "assignment.SUBMITTED",
    over: "/20",
    line: true,
  },
  {
    grade: "6",
    name: "assignment.GRADED",
    over: "/20",
    line: true,
  },
  {
    grade: "15",
    name: "assignment.AVERAGE",
    over: "/100",
    line: false,
  },
];
const initialGrades2: Grades[] = [
  {
    grade: "10",
    name: "assignment.SUBMITTED",
    over: "/20",
    line: true,
  },
  // {
  //   grade: '6',
  //   name: 'GRADED',
  //   over: '/20',
  //   line: true,
  // },
  {
    grade: "15",
    name: "assignment.AVERAGE",
    over: "/100",
    line: false,
  },
];

const StatisticsGrade: FC<StatisticsGradeProps> = ({
  submitted,
  totalSubmissions,
  avarage,
  graded,
  students,
  student,
  enrollements,
  forum,
  quiz,
}) => {
  // const [totalSubmissionsState, setTotalSubmissions] = useState(totalSubmissions);
  // const [gradedCount, setGradedCount] = useState(graded);
  const { SecondaryTextColor, theme } = useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  const grades = [...initialGrades];
  const grades2 = [...initialGrades2];
  if (!quiz) {
    if (totalSubmissions !== undefined && avarage !== undefined) {
      grades[0].grade = totalSubmissions.toString();
      grades[2].grade = avarage.toString();
    }

    if (graded !== undefined) {
      grades[1].grade = graded.toString();
    }

    grades[1].over = `/${student.length}`;
    grades[0].over = `/${enrollements}`;
  } else if (quiz) {
    if (
      totalSubmissions !== undefined &&
      avarage !== undefined &&
      grades2 &&
      grades2.length > 0
    ) {
      grades2[0].grade = totalSubmissions.toString();
      grades2[1].grade = avarage.toString();
      grades2[0].over = `/${enrollements}`;
    }
  }

  return (
    <StatisticsContainer>
      {!quiz &&
        grades.map((grade, index) => (
          <React.Fragment key={index}>
            <GradeContainer>
              <Grade>
                <GradeNumber>{toArabicDigits(grade.grade)}</GradeNumber>
                <OverGrade>
                  <Typography
                    text={toArabicDigits(grade.over)}
                    fontSize="1.5rem"
                    fontWeight="400"
                    lineHeight="normal"
                    color={theme === "light" ? "#AAB1B9" : "#D9E4E8"}
                  />
                </OverGrade>
              </Grade>

              <Typography
                text={t(grade.name)}
                fontSize="0.75rem"
                fontWeight="400"
                lineHeight="normal"
                color={theme === "light" ? "#AAB1B9" : "#D9E4E8"}
              />
            </GradeContainer>

            <SeparateLine show={grade.line.toString()} />
          </React.Fragment>
        ))}
      {quiz &&
        grades2.map((grade, index) => (
          <React.Fragment key={index}>
            <GradeContainer>
              <Grade>
                <GradeNumber>{grade.grade}</GradeNumber>
                <OverGrade>
                  <Typography
                    text={toArabicDigits(grade.over)}
                    fontSize="1.5rem"
                    fontWeight="400"
                    lineHeight="normal"
                  />
                </OverGrade>
              </Grade>

              <Typography
                text={t(grade.name)}
                fontSize="0.75rem"
                fontWeight="400"
                lineHeight="normal"
                color={SecondaryTextColor}
              />
            </GradeContainer>

            <SeparateLine show={grade.line.toString()} />
          </React.Fragment>
        ))}
    </StatisticsContainer>
  );
};

export default StatisticsGrade;
