import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const ImageViewerMainDiv = styled.div<StyleThemeInterface>`
  box-shadow:${props => props.boxshadow || "0px 4px 20px 0px rgba(0, 0, 0, 0.25)"};
  height: 100%;
  border-radius: 0.625rem;
  /* border-top-left-radius: 0.625rem; */
`;
export const ImageViewerTop = styled.div<StyleThemeInterface>`
  width: 100%;
  height: 3rem;
  background:${props => props.bgcolor || "#384450"};
  border-top-right-radius: 0.625rem;
  border-top-left-radius: 0.625rem;
  /* border-radius: 0.625rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
`;
export const ButtonsHolder = styled.div``;
export const SvgStylingButton = styled.button`
  background-color: transparent;
  height: 100%;
  width: 2rem;
  color: #5dd3b3;
  border: none;
  cursor: pointer;
`;
export const ImageContainer = styled.button`
  width: 100%;
  height: 40rem;
  overflow: auto;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  justify-content: center;
`;
interface StyledImageProps {
  size?: number;
}
export const StyledImage = styled.img<StyledImageProps>`
  max-width: 100%;
  max-height: 100%;
  ${({ size }) => size && `width: ${size}rem;`}
`;
