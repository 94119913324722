import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const PopUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  overflow-x: hidden;
  width: 40rem;
  height: 20rem;
`;

export const SessionsWrapper = styled.div`
  margin-bottom: 1.25rem;
  width: 50rem;
`;

export const TableSection = styled.div`
  width: 50vw;
  display: flex;
  min-height: fit-content;
  overflow-x: hidden;
  margin-top: 1.25rem;
  position: relative;
  scroll-behavior: smooth;
  /* background-color: red; */
`;

export const AttendanceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  height: 20rem;
`;

export const AttendanceProfileRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.25rem;
  padding: 0 1rem;
  margin-bottom: 1.25rem;
`;

export const AttendanceHeaderRow = styled.div<StyleThemeInterface>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.25rem;

  &:nth-child(odd) {
    background: ${(props) => props.nth_child_bgcolor};
  }
`;

export const AttendanceHeaderCell = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  /* gap: 1.25rem; */

  &:first-child {
    flex: 1;
    padding: 0.63rem 1rem;
    /* background: greenyellow; */
  }
  &:nth-child(2) {
    flex: 1.8;
    /* background: black; */
    padding: 0 1rem;
  }
  &:last-child {
    flex: 1;
    justify-content: center;
    padding: 0.63rem 1rem;
    /* background: purple; */
  }
`;
