import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Arrow, DatePickerStyle, StyledInput } from "./input.style";
import { ReactComponent as SvgCalenderIcon } from "../../assets/icons/Calendar.svg";
import { DatePickerProps } from "./inputInterface";
import { useRecoilValue } from "recoil";
import { ColorSelector, MainColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { enUS, tr, ar, fr } from 'date-fns/locale';
import { formatDayContents, toArabicDigits } from "../../utils/formatDayArabic";
import { DatePickerFormater } from "./DateFormater";

const SelectDate = ({ width, value, onChange, showTime = true }: DatePickerProps) => {
  const { themeTextColor, inputBorderColor } = useRecoilValue(ColorSelector);
  const { mainBackgroundColor } = useRecoilValue(MainColorSelector);
  const { t } = useTranslation();
  const [language, setLanguage] = useState<any>();
  const localStorageLanguage = localStorage.getItem("language");

  useEffect(() => {
    switch (localStorageLanguage) {
      case "tr":
        setLanguage(tr);
        break;
      case "ar":
        setLanguage(ar);
        break;
      case "fr":
        setLanguage(fr);
        break;
      default:
        setLanguage(enUS);
    }
  }, [t]);

  useEffect(() => {
    setDefaultLocale(language);
  }, [language]);

  useEffect(() => {
    if (localStorageLanguage === "ar") {
      const intervalId = setInterval(() => {
        // Convert time elements
        const timeElements = document.querySelectorAll('.react-datepicker__time-list-item');
        timeElements.forEach(element => {
          element.textContent = toArabicDigits(element.textContent || '');
        });

        // Convert year elements
        const yearElements = document.querySelectorAll('.react-datepicker__current-month');
        yearElements.forEach(element => {
          element.textContent = toArabicDigits(element.textContent || '');
        });
      }, 100);
      return () => clearInterval(intervalId);
    }
  }, [localStorageLanguage]);

  const formatDate = (date: Date | null) => {
    if (!date) return "";
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-indexed
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return localStorageLanguage === "ar" ? toArabicDigits(formattedDate) : formattedDate;
  };

  return (
    <StyledInput
      width={width}
      themetextcolor={themeTextColor}
      bg_datepicker={mainBackgroundColor}
      inputbordercolor={inputBorderColor}
    >
      <DatePickerStyle
        width={width}
        selected={value}
        onChange={onChange}
        minDate={new Date()}
        placeholderText={showTime ? `${t('online session.Select Date & Time')}` : `${t('online session.Select Date')}`}
        dateFormat={showTime ? "d/MM/yyyy | h:mm aa" : "d/MM/yyyy"}
        showTimeSelect={showTime}
        inputbordercolor={inputBorderColor}
        themetextcolor={themeTextColor}
        locale={language}
        timeCaption={showTime ? `${t('calendar.Time')}` : undefined}
        timeFormat={showTime ? "h:mm aa" : ""}
        renderDayContents={localStorageLanguage === "ar" ? (day) => formatDayContents(day) : (day) => day}
      />
      <Arrow>
        <SvgCalenderIcon />
      </Arrow>
      {/* <DateToDisplay value={DatePickerFormater(value)} themetextcolor={themeTextColor}/> */}
    </StyledInput>
  );
};

export default SelectDate;
