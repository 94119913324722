import styled from "styled-components";

export const FileInputButtonIcon = styled.i``;

export const FileInputButton = styled.label`
  display: inline-block;
  cursor: pointer;
  /* padding: 0.4rem 1.25rem; */
  /* border: 1px solid #5DD3B3; */
  width: 100%;
  height: 1.875rem;
  /* border-radius: 1.25rem; */
  background-color: transparent;
  /* color: #5DD3B3; */
  font-size: 0.75rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  align-items: center;
  font-family: "Primary Font-Medium";
  padding-left: 0.5rem;
  /* &:hover {
    box-shadow: 0 0 10px rgba(42, 243, 156, 0.3);
  } */

  /* color: white;
  background-repeat: no-repeat;
  padding-left: 20%;
  background-position: 3%;
  font-family:"Primary Font-Medium";
  cursor:pointer;
  border:0px;
  align-items:center;
  vertical-align:center;
  height:2rem; */
`;

export const HiddenInput = styled.input`
  display: none;
`;
