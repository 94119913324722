import { styled } from "styled-components";
import { breakpoints } from "../../../theme";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const NoteContainer =styled.div`
width:36rem;
min-height:5rem;
display:flex;
flex-direction:column;
gap:1.35rem;
/* padding:1rem 1.25rem 1rem 1.25rem; */
/* background-color:red; */
@media (max-width: ${breakpoints.tablets}) { 
    width:100%;
  
  }
`;

export const SubNoteContainer =styled.div`
width:100%;
gap:0.5rem;
display:flex;
flex-direction:column;

/* background-color:red; */
`;

export const NoteText =styled.input<StyleThemeInterface>`
width:100%;
height: 2.8125rem;
padding:0.94rem 0.91rem;
margin:auto;
border-radius: 0.625rem;
border: 1px solid ${props => props.bordercolor};
background-color:transparent;
font-family:"Primary Font";
/* padding:1rem; */
color:#fff;
color:${props =>props.color};
&::placeholder{
  color: ${props =>props.color};
  opacity: 0.5;
}
&:focus {
    outline: none;
    caret-color: ${props =>props.color};
  }
/* background-color:red; */
`;