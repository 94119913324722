import { Module } from "../../CourseTimeline/utils/CourseResourcesInterface";
import { isDateInRange } from "../isDateInRange";

export function pushToResourceArray(
  resourceArray: any[],
  content: any,
  topicIndex: number,
  moduleIndex: number
) {
  resourceArray.push({
    ...content,
    topicIndex: topicIndex - 1,
    moduleIndex: moduleIndex - 1,
  });
}
export function extractAllResources(resources: Module[]) {
  const documentResources: any[] = [];
  const fileResources: any[] = [];
  const linkResources: any[] = [];
  const htmlResources: any[] = [];
  const pictureResources: any[] = [];
  const videoResources: any[] = [];
  const linkTreeResources: any[] = [];
  const folderResources: any[] = [];
  resources.forEach((module, moduleIndex) => {
    module.topicsArray.forEach((topic, topicIndex) => {
      topic.contents?.forEach((content) => {
        if (
          isDateInRange(content.startDate, content.endDate, content.isVisible)
        ) {
          switch (content.type) {
            case "Document":
              pushToResourceArray(
                documentResources,
                content,
                topic.topicIndex,
                module.moduleIndex
              );
              break;
            case "File":
              pushToResourceArray(
                fileResources,
                content,
                topic.topicIndex,
                module.moduleIndex
              );
              break;
            case "Video":
              pushToResourceArray(
                videoResources,
                content,
                topic.topicIndex,
                module.moduleIndex
              );
              break;
            case "Picture":
              pushToResourceArray(
                pictureResources,
                content,
                topic.topicIndex,
                module.moduleIndex
              );
              break;
            case "Link":
              pushToResourceArray(
                linkResources,
                content,
                topic.topicIndex,
                module.moduleIndex
              );
              break;
            case "Link_Tree":
              pushToResourceArray(
                linkTreeResources,
                content,
                topic.topicIndex,
                module.moduleIndex
              );
              break;
            case "Html":
              pushToResourceArray(
                htmlResources,
                content,
                topic.topicIndex,
                module.moduleIndex
              );
              break;
            case "Folder":
              pushToResourceArray(
                folderResources,
                content,
                topic.topicIndex,
                module.moduleIndex
              );
              break;
            default:
              break;
          }
        }
      });
    });
  });

  return {
    documentResources,
    linkResources,
    htmlResources,
    videoResources,
    pictureResources,
    linkTreeResources,
    folderResources,
    fileResources,
  };
}
