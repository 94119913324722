import { Link } from "react-router-dom";
import LandingPageStatic from "../../components/landingPageStatic/LandingPageStatic";
import SignInForm from "../../components/SignInForm/SignInForm";
import BlueButton from "../../elements/blueButton/BlueButton";
import { TypographyRegularDescription, Typography } from "../../elements/fonts/Fonts"
import {InstructionTextRow} from "./SuccessfulReset.style"

const SuccessfulReset = () => {
  return (
    <LandingPageStatic>
    <InstructionTextRow>
        <Typography
            text="Successful password reset!"
            fontSize="1.25rem"
            lineHeight="normal"
            fontWeight="400"

        />
        <TypographyRegularDescription
            text="You can now use your new password to login to your account,"
            fontSize="0.875rem"
            lineHeight="1.375rem"
            fontWeight="400"
        />

    </InstructionTextRow>
    <SignInForm>
<Link to={"/login"}>
        <BlueButton text="Login" />
        </Link>
    </SignInForm>
    </LandingPageStatic>
  )
}

export default SuccessfulReset