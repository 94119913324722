import {
    SvgCoursesIcon,
    SvgDiscussion,
    SvgDocument,
    SvgFolderIcon,
    SvgLinkTree,
    SvgMedia,
    SvgMic,
    SvgMonitorIcon,
    SvgNews,
    SvgNotificationIcon,
    SvgQuiz,
    SvgSurveyIcon,
    SvgTeams,
    SvgUrl,
    SvgVideoNew,
  } from "../elements/Icons";

export const getIconType = (type: string) => {
    switch (type) {
      case "Document":
        return <SvgDocument />;
      case "Media":
        return <SvgMedia />;
      case "Link":
        return <SvgUrl />;
      case "video":
        return <SvgVideoNew />;
      case "Assignment":
        return <SvgNews />;
      case "Forum":
        return <SvgDiscussion />;
      case "Quiz":
        return <SvgQuiz />;
      case "File":
        return <SvgCoursesIcon />;
      case "Link_Tree":
        return <SvgLinkTree />;
      case "Folder":
        return <SvgFolderIcon />;
      case "Html":
        return <SvgMonitorIcon />;
      case "Online_Session":
        return <SvgMic />;
      case "Teams":
        return <SvgTeams/>;
      case "Survey":
        return <SvgSurveyIcon />;
      default:
        return <SvgNews />;
    }
  };