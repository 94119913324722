import styled from "styled-components";
import { breakpoints } from "../../theme";
import { InputSelectInterface } from "./SelectInputInterface";
// import DateP   ker from 'react-datepicker';
// import TimePicker from 'react-time-picker';

export const TextInputSpace = styled.input`
  width: auto;
  display: flex;
  justify-content: flex-end;
  margin-top: 0.31rem;
  background-color: ${(props) => (props.disabled ? "red" : "transparent")};
  border: 0px solid #576371;
  color: #fff;
  font-size: 0.875rem;
  font-family: "Primary Font-Medium";
  outline: none;
`;
export const StyledInput = styled.div<InputSelectInterface>`
  width: ${(props) => (props.width ? props.width : "18rem")};
  height: ${(props) => (props.height ? props.height : "2.8125rem")};
  border-radius: ${(props) =>
    props.isopen === "true" ? "0.625rem 0.625rem 0rem 0rem" : "0.625rem"};
  border: 1px solid ${(props) => props.inputBorderColor};

  /* padding: 0.87rem 0.87rem 0.87rem 0.87rem; */
  padding: ${(props) =>
    props.padding ? props.padding : "0.87rem 0.87rem 0.87rem 0.87rem"};

  ::placeholder {
    font-family: "Primary Font-Light";
    font-size: 0.875rem;
    color: ${(props) => props.themeTextColor};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  /* @media (max-width: ${breakpoints.large}) {
    width: 27.8125rem;
    height: 3.4375rem;
  }
  @media (max-width: ${breakpoints.small}) {
    width: 20.9925rem;
    height: 3.4375rem;
  } */
`;
export const SelectContainer = styled.div<InputSelectInterface>`
  position: relative;
  cursor: pointer;
  z-index: ${(props) => (props.zIndex ? props.zIndex : "99")};
  color: ${(props) => props.themeTextColor};
  font-size: 0.875rem;
  font-family: "Primary Font-Medium";
`;
export const Select = styled.div<{ color: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    color: ${(props) => (props.color ? props.color : "")};
  }
`;
export const Arrow = styled.div<{ marginLeft: any }>`
  margin-right: 0.81rem;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
  margin-top: -0.4rem;
`;

export const OptionsContainer = styled.div<{ optionWidth?: any }>`
  position: relative;
  top: 0.8rem;
  bottom: 0;
  right: 0.9rem;
  width: ${(props) => (props.optionWidth ? props.optionWidth : "18rem")};
`;
export const SubMenu = styled.div<InputSelectInterface>`
  position: absolute;
  top: 0;
  left: 100%;
  border-radius: 0.3125rem;
  background: ${(props) => props.bg_selectColor};
  line-height: 2rem;
  width: 12.5rem;
`;
export const SubMenuItem = styled.div`
padding: 0.31rem 1.25rem;
border-radius: 0.3125rem;
&:hover {
    background: #5dd3b3;
    color: #fff;
  }
`;

export const Options = styled.div<{
  index: any;
  lastitem: any;
  display: string;
  optionIcon?: React.ReactNode | null;
  bg_selectColor?: string
}>`
  background: ${(props) => props.bg_selectColor};
  font-size: 0.875rem;
  cursor: pointer;
  padding: 1rem;
  font-family: "Primary Font-Medium";
  display: ${(props) => (props.display ? props.display : "flex")};
  gap: 0.63rem;
  border-radius: ${(props) =>
    props.lastitem === props.index ? "0rem 0rem 0.3125rem 0.3125rem" : ""};
  &:hover {
    background: #5dd3b3;
    color: #fff;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MainOptionContainer = styled.div<{ fontSize?: any }>`
  display: flex;
  gap: 0.63rem;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "0.875rem")};
`;
