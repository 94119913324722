import React, { useEffect, useRef, useState } from "react";
import { Module } from "../../CourseTimeline/utils/CourseResourcesInterface";
import {
  ChoseModel,
  DashboardActivitiesHolder,
  HeaderTitles,
  ModuleEmpty,
  ModuleHolder,
  ModulesHolder,
  LoadingNoResources,
  ActivitiesDivHolder,
  SearchDiv,
  ActivitiesHoler,
  ActivityCardHolder,
  ViewAllT,
} from "./ActivitiesPerModule.style";
import { Typography } from "../../../elements/fonts/Fonts";
import { SvgEmpty, SvgNoResult, SvgViewAll } from "../../../elements/Icons";
import SearchElement from "../../../elements/searchElement/SearchElement";
import ActivityCard from "./ActivityCard/ActivityCard";
import { isDateInRange } from "../isDateInRange";
import { dashboardResourcesProps } from "../dashboardContentInterfaces";
import { Link } from "react-router-dom";
import { ResourcesHeaderDiv } from "../ResourcesPerModule/resourcesPerModuleMain/ResourcesPerModule.style";
import SearchWithFilter from "../../../elements/searchWithFilter/SearchWithFilter";
import FilterPopUp from "../ResourcesPerModule/filterPopup/FilterPopUp";
import { extractAllActivities } from "./ExtractAllActivities";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  ChatsColorSelector,
  ColorSelector,
  courseBannerColorSelector,
} from "../../../recoil/ThemeSelectors";
interface TranslatedTitles {
  [key: string]: string;
}
export default function ActivitiesPerModule({
  courseId,
  Contentdata,
}: dashboardResourcesProps) {
  const { t } = useTranslation();
  const language = localStorage.getItem("language");
  const [data, setData] = useState<Module[]>([]);
  const [activeModule, setActiveModule] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showFilterPopUp, setShowFilterPopup] = useState<boolean>(false);
  const [filterTitles, setFilterTitles] = useState<any[]>([]);
  const [existingActivitiesTitles, setExistingActivitiesTitles] = useState<
    any[]
  >([]);
  useEffect(() => {
    //set filtered activities data
    setData(Contentdata);
    // set active module to first module
    setActiveModule(0);
    handleModuleClick(0);
  }, [courseId, language]);

  // Put The existing Activities Titles as a default filtered Titles
  useEffect(() => {
    setFilterTitles(existingActivitiesTitles);
  }, [existingActivitiesTitles]);

  const handleModuleClick = (index: any) => {
    setActiveModule(index);
    const activeResourcesModule: Module[] = [];
    activeResourcesModule.push(Contentdata[index]);
    // Extract The Existing Activities and put them in a state
    if (Contentdata[index]) {
      const {
        quizActivity,
        surveyActivity,
        sessionActivity,
        teamsSessionActivity,
        forumActivity,
        pollActivity,
        assignmentActivity,
      } = extractAllActivities(activeResourcesModule);
      const existingResourceTitlesPerModule = [
        { resources: quizActivity, title: t("quiz.Quiz") },
        { resources: surveyActivity, title: t("survey.Survey") },
        {
          resources: sessionActivity,
          title: t("online session.Online Session"),
        },
        {
          resources: teamsSessionActivity,
          title: t("forms.MS Teams Call"),
        },
        { resources: forumActivity, title: t("forum.Forum") },
        // { resources: pollActivity, title: "Poll" },
        { resources: assignmentActivity, title: t("assignment.Assignment") },
      ]
        .filter((resourceGroup) => resourceGroup.resources.length > 0)
        .map((resourceGroup) => resourceGroup.title);
      setExistingActivitiesTitles(existingResourceTitlesPerModule);
    }
  };

  // set the selected titles in the Filter Titles Array
  const handleonClickFilter = (titles: any[]) => {
    setFilterTitles(titles);
  };

  //Close the Filter Popup
  const handleClosePopup = () => {
    setShowFilterPopup(false);
  };

  // List of The Activities
  const ActivitiesT: any[] = [
    t("survey.Survey"),
    t("online session.Online Session"),
    t("assignment.Assignment"),
    t("forum.Forum"),
    t("forms.MS Teams Call"),
    t("quiz.Quiz"),
    // "Poll"
  ];

  const translatedTitles: TranslatedTitles = {
    Quiz: t("quiz.Quiz"),
    Survey: t("survey.Survey"),
    Online_Session: t("online session.Online Session"),
    Forum: t("forum.Forum"),
    Teams: t("forms.MS Teams Call"),
    Assignment: t("assignment.Assignment"),
  };
  const { InfoSelectTextColor } = useRecoilValue(ChatsColorSelector);
  const { bghelp, theme } = useRecoilValue(ColorSelector);
  const { courseTitleBgColor } = useRecoilValue(courseBannerColorSelector);
  return (
    <>
      <DashboardActivitiesHolder>
        {/* {!loading ? ( */}
        <>
          {data.length > 0 ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
              }}
            >
              <ModulesHolder BorderColor={bghelp}>
                {data.map((module, index) => (
                  <ModuleHolder
                    activeModule={index === activeModule}
                    key={index}
                    onClick={() => handleModuleClick(index)}
                  >
                    <Typography
                      text={`${module.moduleTitle}`}
                      fontSize="0.875rem"
                      color={
                        index === activeModule ? "#fff" : InfoSelectTextColor
                      }
                    />
                  </ModuleHolder>
                ))}
              </ModulesHolder>
              <ActivitiesDivHolder>
                <div></div>
                <ResourcesHeaderDiv allResources={false}>
                  <SearchDiv style={{ width: "35%" }}>
                    {/* <SearchElement /> */}
                    <SearchWithFilter
                      placeholder={t("dashboard.Search")}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onClick={() => {
                        setShowFilterPopup(true);
                      }}
                    />
                  </SearchDiv>
                </ResourcesHeaderDiv>
                <HeaderTitles>
                  {activeModule !== null && (
                    <div style={{ width: "100%" }}>
                      <Typography
                        text={`${data[activeModule].moduleTitle}`}
                        fontSize="1rem"
                      />
                    </div>
                  )}

                  <ViewAllT>
                    <Link to={`/courseView/content/${courseId}`}>
                      {/* <SvgViewAll /> */}
                      <Typography
                        text={t("dashboard.view all")}
                        fontSize="0.85rem"
                        color="#5dd3b3"
                      />
                    </Link>
                  </ViewAllT>
                </HeaderTitles>

                <ActivitiesHoler>
                  {activeModule !== null &&
                    data[activeModule].topicsArray.length > 0 ? (
                    <div>
                      {data[activeModule].topicsArray
                        .flatMap(
                          (topic, topicIndex) =>
                            topic.contents?.map((content) => ({
                              ...content,
                              topicTitle: topic.topicTitle,
                              topicIndex: topicIndex,
                            })) || []
                        )
                        .filter(
                          (activity) =>
                            activity.type &&
                            filterTitles.includes(translatedTitles[activity.type])
                        )
                        .filter((activity) =>
                          activity.contentTitle
                            .toLowerCase()
                            .includes((searchTerm || "").toLowerCase())
                        )
                        .filter((content) =>
                          isDateInRange(content.visibleFrom, content.visibleTo, content.isVisible)
                        )
                        .map((content, index) => {
                          const topicIndex = content.topicIndex;
                          return (
                            <ActivityCardHolder
                              key={index}
                              style={{
                                background: index % 2 === 0
                                  ? `${theme === "light"
                                    ? "#F7F7F8"
                                    : "linear-gradient(90deg, #3C4A58 0%, #323D49 107.88%)"
                                  }`
                                  : "transparent",
                              }}
                            >
                              <ActivityCard
                                activityR={content}
                                courseId={courseId}
                                moduleIndex={activeModule}
                                topicIndex={topicIndex}
                              />
                            </ActivityCardHolder>
                          );
                        })}
                    </div>
                  ) : (
                    <>
                      {activeModule !== null && (
                        <ModuleEmpty>
                          <SvgNoResult width={"10rem"} />
                          <Typography
                            text={`No Activities Available in ${data[activeModule].moduleTitle}`}
                          />
                        </ModuleEmpty>
                      )}
                    </>
                  )}
                </ActivitiesHoler>
                {activeModule === null && (
                  <ChoseModel>
                    <SvgNoResult width={"10rem"} />
                    <Typography text="Please choose a module from the left to check available activities..." />
                  </ChoseModel>
                )}
              </ActivitiesDivHolder>
            </div>
          ) : (
            <LoadingNoResources>
              <SvgNoResult width={"10rem"} />
              <Typography text={t("dashboard.No Activities Yet")} />
            </LoadingNoResources>
          )}
        </>
      </DashboardActivitiesHolder>
      {showFilterPopUp && (
        <FilterPopUp
          ResourcesT={ActivitiesT}
          filterTitles={filterTitles}
          onClosePopup={handleClosePopup}
          onClickFilter={handleonClickFilter}
          existingResourceTitles={existingActivitiesTitles}
          type={t("view all.ActivitiesFilter")}
        />
      )}
    </>
  );
}
