import React, { useState, useEffect } from "react";

import { SurveyStarQuestion } from "../../elements/SurveyCreatorStarQuestion/SurveyStarQuestion";
import {
    
    QuestionIconsContainer,
    QuestionHeaderContainer, 
  } from "../SurveyCreatorQuestionCRUD/QuestionCRUDTextEditor/QuestionCRUDTextEditor.style";
  import {theme} from '../../theme'
import CreateQuestions from "../QuestionCard/main/CreateQuestions";
import { StarsWrapper,BranchName } from "./SurveyRating.style";
import {SurveyMainInterface} from './surveyRatingInterface'
import DropQuestion from "../SurveyBranchCreatorDropQuestion/DropQuestion";
import QuestionCRUDTextEditor from "../SurveyCreatorQuestionCRUD/QuestionCRUDTextEditor/QuestionCRUDTextEditorForBranching";
import MultipleQuestionForBranching from "../SurveyCreatorQuestionCRUD/MultipleQuestion/MultipleQuestionForBranching";
import { SurveyLikertScale } from "../../elements/SurveyCreatorLikertScale/SurveyLikertScale";
import EssayQuestion from "../SurveyCreatorQuestionCRUD/EssayQuestion/EssayQuestion";
import TrueFalseQuestion from "../SurveyCreatorQuestionCRUD/TrueFalseQuestion/TrueFalseQuestion";
import { Choice } from "../generateSurvey/generateSurveyDataInterface";
import { MediumTypography } from "../../elements/fonts/Fonts";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import {ReactComponent as AddIcon} from '../../assets/icons/Add (1).svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/Delete 1 copy.svg'
import { mapErrorType } from "../../utils/QuizErrorTypes";
import { t } from "i18next";


const SurveyRating = ({quest,
    qindex,
    allChoices,
    setAllChoices,
    FillBranch,
    changeBranchName,
    showQuest,
    questions,
    index,
    partIndex,
    questionError,
    addChoice,
    handleChoiceMultipleQuestionForBranching,
    emptyBranch
    }:SurveyMainInterface) => {
    const [isBranched, setIsBranched] = useState<any>(false);
    const [branchType, setBranchType] = useState('');
    const [branchChoiceTypes, setBranchChoiceTypes] = useState<Record<number, string>>({});
    const [branchChoices, setBranchChoices] = useState<Choice[]>([]);

    const handleSingleBranchChoice = () => {
        const safeIndex = qindex || 0;
        // Ensure allChoices exists and has valid data before accessing its properties
        if (allChoices && allChoices[safeIndex]?.branch?.choices) {
            setBranchChoices(allChoices[safeIndex]?.branch?.choices || []);
        }
    };




      useEffect(() => {
        handleSingleBranchChoice();
      }, [questions, index, partIndex,branchChoiceTypes]);

    useEffect(()=>{
        setIsBranched(quest?.branching)
        
      },[quest?.branching]) 

    const handleStarRatingChange = (value: any) => {
        // Handle the value change here (e.g., update state or perform other actions)
        // Update the value of choice for type 'Rating' and the corresponding cIndex
        const updatedChoice = [...allChoices || []]; // Clone the array to avoid mutating state directly
        // Assuming qindex represents the index of the choice to update for 'Rating' type
        if (updatedChoice[qindex || 0]?.value !== undefined) {
            updatedChoice[qindex || 0].value = value; // Update the value field for the Rating type
            setAllChoices && setAllChoices(updatedChoice); // Set the updated choices array in state using the provided setter function
        }
    }

    const handleBranchTypeChange = (type: any) => {
        const updatedBranchChoiceTypes = { ...branchChoiceTypes };
        updatedBranchChoiceTypes[qindex || 0] = type;
        setBranchChoiceTypes(updatedBranchChoiceTypes);
    };

    useEffect(() => {
        if (allChoices) {
            const currentBranchChoiceType = allChoices[qindex || 0]?.branch?.type || '';
            const updatedBranchChoiceTypes = { ...branchChoiceTypes };
            updatedBranchChoiceTypes[qindex || 0] = currentBranchChoiceType;
            setBranchChoiceTypes(updatedBranchChoiceTypes);
        }
    }, [allChoices]);

    const errorType = mapErrorType(questionError || "", t);


    return (
        <>
        {(questionError  && qindex === 0) && <ErrorDiv style={{marginLeft:"1.5rem"}}>{errorType}</ErrorDiv>}
        
        <StarsWrapper>
     
            <SurveyStarQuestion
                showStarDisplay={false}
                onChange={handleStarRatingChange}
                quest={quest}
                allChoices={allChoices}
                qindex={qindex}
            />
              
        </StarsWrapper>
        {(isBranched && qindex ===0) && (
            <QuestionHeaderContainer>
            <QuestionIconsContainer>
            <MediumTypography
                text="Create the set of questions when student chooses this answer"
                color={theme.dark.primary_text_color}
              />
              
            </QuestionIconsContainer>
            <AddIcon onClick={addChoice}/>
          </QuestionHeaderContainer>
        ) }

{isBranched && 
    (!branchChoiceTypes[qindex || 0]) &&
    <DropQuestion qindex={qindex} FillBranch={FillBranch} OnBranchTypeChange={handleBranchTypeChange}/>}
    {isBranched && branchChoiceTypes[qindex || 0] && 
    <>
    <div style={{display:"flex",justifyContent:"space-between",marginRight:"2rem",alignItems:"center"}}>
    <BranchName style={{marginLeft:"2rem",marginTop:"1rem"}}>Branch: {qindex && qindex + 1 || 1}</BranchName>
    {!showQuest && <DeleteIcon style={{cursor:"pointer"}} onClick={()=>emptyBranch && emptyBranch(qindex || 0)}>Delete</DeleteIcon>}
    </div>
    <div style={{padding:"2rem",marginTop:"-1.5rem"}}>
    <QuestionCRUDTextEditor questionError={questionError} qindex={qindex} allChoices={allChoices} showQuest={showQuest}  quest={quest} changeBranchName={changeBranchName}/>
    </div>    
    </>
    }
   {isBranched && branchChoiceTypes[qindex || 0] && branchChoiceTypes[qindex || 0] === 'Multiple choice' && (
    branchChoices.map((branchchoice, branchChoiceIndex) => (
        <div style={{padding:"2rem",marginTop:"-3rem"}}>
        <MultipleQuestionForBranching key={branchChoiceIndex} showQuest={showQuest} branchchoice={branchchoice} handleChoiceMultipleQuestionForBranching={handleChoiceMultipleQuestionForBranching} qindex={qindex} branchChoiceIndex={branchChoiceIndex}/>
        </div>
    ))
    )}
     {isBranched && branchChoiceTypes[qindex || 0] && branchChoiceTypes[qindex || 0] === 'Yes-No' && (
        <div style={{padding:"2rem",marginTop:"-3rem"}}>
        <TrueFalseQuestion/>
        </div>
    )}
     {isBranched && branchChoiceTypes[qindex || 0] && branchChoiceTypes[qindex || 0] === 'Rating' && (
        <div style={{padding:"2rem",marginTop:"-3rem"}}>
        <SurveyRating/>
        </div>
    )}
     {isBranched && branchChoiceTypes[qindex || 0] && branchChoiceTypes[qindex || 0] === 'Scale' && (
        <div style={{padding:"2rem",marginTop:"-3rem"}}>
        <SurveyLikertScale/>
        </div>
    )}
     {isBranched && branchChoiceTypes[qindex || 0] && branchChoiceTypes[qindex || 0] === 'Essay' && (
        <EssayQuestion/>
    )}
    {(questionError  && qindex === 4) && <ErrorDiv style={{marginLeft:"3.37rem"}}>{questionError}</ErrorDiv>}
    </>
    );
}

export default SurveyRating


/* 
{(questions[index]?.type === "" && !questions[index]?.questPart) ||
        (!questions[index]?.type &&
          !questions[index]?.questPart[partIndex]?.type) ? (
          <DropQuestion questions={questions} DropQuestionType={DropQuestionType} />
        ) 
*/