import React, { useState } from "react";
import { FlexAppContainer } from "../../../App.style";
import TitleHeaderTextIconNavigator from "../../../elements/TitleHeaderTextIconNavigator/TitleHeaderTextIconNavigator";
import CompetencyFrameworksCompo from "../../../components/Competency/ComopetencyFrameworks/main/Competencyrameworks";

const CompetencyFrameworks = () => {
  return (
    <FlexAppContainer column={true} gap="3.5rem">
      <TitleHeaderTextIconNavigator title="competency frameworks" />
      <CompetencyFrameworksCompo />
    </FlexAppContainer>
  );
};

export default CompetencyFrameworks;
