import React, { useContext, useEffect, useRef } from "react";
import {
  NotificationWrapper,
  NotificationWrapperParent,
} from "./Notification.style";
import { MediumTypography } from "../../../elements/fonts/Fonts";
import NotificationItem from "../notificationMenu/NotificationItem";
import { FlexAppContainer } from "../../../App.style";
import { PointerContainer } from "../../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableTable.style";
import { SvgScale } from "../../../elements/Icons";
import NotificationMenuV2 from "../NotificationMenuV2/NotificationMenuV2";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
interface NotificationMenuV2Props {
  unviewedNotificationData: any[];
  setShowNotification?: React.Dispatch<React.SetStateAction<boolean>>;
}
const Notification: React.FC<NotificationMenuV2Props> = ({
  unviewedNotificationData,
  setShowNotification,
}) => {
  const { bg_selectColor, theme } = useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigateNotification = () => {
    setShowNotification && setShowNotification(false);
    navigate("/Notification");
  };
  return (
    <NotificationWrapperParent theme={theme} bgcolor={bg_selectColor}>
      <NotificationWrapper>
        <FlexAppContainer
          padding="0.7rem 1rem 0 1rem"
          justifyContent="space-between"
          alignItems="center"
          // backgroundColor="red"
          width="100%"
        >
          <MediumTypography
            text={t("notification.Notification")}
            fontWeight="500"
            fontSize="0.875rem"
          />
          {/* {unviewedNotificationData.length > 0 && ( */}

          <PointerContainer
            style={{ padding: "0.2rem " }}
            onClick={handleNavigateNotification}
          >
            <SvgScale width={"0.625rem"} />
          </PointerContainer>

          {/* )} */}
        </FlexAppContainer>
        {/* <NotificationItem /> */}
        <NotificationMenuV2
          unviewedNotificationData={unviewedNotificationData}
        />
      </NotificationWrapper>
    </NotificationWrapperParent>
  );
};

export default Notification;
