import styled from "styled-components";

export const TimeContainer = styled.div<{ bgColor?: string }>`
  color: #5dd3b3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 4.625rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  /* background: var(--Main-dark-grey, #364350); */
  /* background:red; */
  row-gap: 1rem;
  padding: 1rem 1.62rem;
  align-items: start;
  background: ${(props) => props.bgColor || "#364350"};
`;
export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 0.64rem;
`;

export const DateDiv = styled.div`
  display: flex;
  /* column-gap: 1.5rem; */

  & > :first-child {
    min-width: 5.16rem;
    /* background: purple; */
  }
`;
export const ScoreDiv = styled.div`
  display: flex;
  gap: 0.75rem;
  /* column-gap: 1.5rem; */

  /* & > :first-child {
    min-width: 3rem;
    background: purple;
  } */
`;

export const GradeContainer = styled.div`
  position: absolute;
  border: 0.15rem dashed #5dd3b3;
  width: fit-content;
  padding: 1rem;
  &:dir(ltr) {
    right: 2rem;
  }
  &:dir(rtl) {
    left: 2rem;
  }
`;
