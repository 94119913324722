import { toArabicDigits } from "../../../utils/formatDayArabic";
import { getFormattedDate } from "../../../utils/GetFormattedDate";

export const TextBeforeDateDisplays = (date: Date | undefined): string => {
  const currentDate = new Date();
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // One day in milliseconds

  // Check if the date is defined
  if (date !== undefined) {
    // Check if the date is in the past
    if (date < currentDate) {
      return "Past Due:";
    }
    // Check if the date is in the future and exactly one day ahead
    else if (date.getTime() - currentDate.getTime() === oneDayInMilliseconds) {
      return "Soon Due:";
    }
    // Check if the date is in the future
    else if (date > currentDate) {
      return "Due Due:";
    }
  }
  // Return an empty string if the date is undefined
  return "";
};




/**
 * Determines the color or opacity value based on the given date and viewed status.
 * @param date - The date to check.
 * @param Viewed - Optional parameter indicating whether the item has been viewed.
 * @returns A string representing the color value or opacity value.
 */
export const OptimizeColor = (
  date: Date | undefined,

): string => {
  // Check if the date is defined and in the past
  if (date !== undefined && date < new Date()) {
    return "#D85D66"; // Return red color for past due date
  }
 else {
    return "none"; // Return 'none' if neither condition is met
  }
};

/**
 * Determines the color or opacity value based on the given date and viewed status.
 * @param Viewed - Optional parameter indicating whether the item has been viewed.
 * @returns A string representing the color value or opacity value.
 */
export const OptimizeOpacity = (
  Viewed?: boolean | undefined
): string => {

   if (Viewed !== undefined && Viewed === true) {
    return "0.6"; // Return opacity value for viewed item
  } else {
    return "none"; // Return 'none' if neither condition is met
  }
};


export const ManyOptionsDate = (date: Date | string | undefined, t: any): string => {
  if (!date) {
    return "";
  }

  if (typeof date === 'string') {
    // Parse the string to a Date object
    date = new Date(date);
  }

  const currentDate = new Date();
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  const optionsToday: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const optionsOther: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formatTimeWithTranslation = (date: Date, options: Intl.DateTimeFormatOptions): string => {
    const parts = new Intl.DateTimeFormat(undefined, options).formatToParts(date);
    return parts.map(part => {
      if (part.type === 'dayPeriod') {
        return t(`notification.${part.value}`);
      }
      return part.value;
    }).join('');
  };

  if (
    date.getDate() === currentDate.getDate() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getFullYear() === currentDate.getFullYear()
  ) {
    // Display time if it's the current date
    return `${t("notification.Today at")} ${toArabicDigits(formatTimeWithTranslation(date, optionsToday))}`;
  } else if (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  ) {
    // Display "Yesterday" if it's the previous day
    return `${t("notification.Yesterday at")} ${toArabicDigits(formatTimeWithTranslation(date, optionsToday))}`;
  } else {
    // Display full date if it's more than yesterday
    return getFormattedDate(date.toDateString());
  }
};



export const ShowDismissButton = (
  date: Date | undefined,
  Viewed:boolean | undefined,
  
): boolean => {
  // Check if the date is defined and in the past
  if (date !== undefined && date < new Date() && Viewed === false) {
    return true; // Return red color for past due date
  }
  // Check if the item has been viewed
 else {
    return false; // Return 'none' if neither condition is met
  }
};

export const ShowViewMyGradeButton = (
  type: string | undefined,
  TextDisplays?: string | undefined,
): boolean => {
  // Check if the date is defined and in the past
  if (type !== undefined && type === "Quiz" && TextDisplays !== undefined && TextDisplays === "Grade Posted") {
    return true; // Return red color for past due date
  }
  // Check if the item has been viewed
 else {
    return false; // Return 'none' if neither condition is met
  }
};