import styled from "styled-components";

export const LabelDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.62rem;

  .input-container {
    display: flex;
    flex-direction: column;
    gap: 1.12rem;
  }
`;

export const BtnsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.88rem;
`;

export const LabelBtnsDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;
export const ButtonsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 1rem;
`;

export const FlexDiv = styled.div<{ gap?: string }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : "0.5rem")};
  /* width: 100%; */
`;
export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* & > :last-child:hover {
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  } */
`;
