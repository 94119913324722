import styled from "styled-components";

export const SimilarityReportContainer = styled.div`
  border-radius: 0.625rem;
  background: rgba(255, 255, 255, 0.03);
  display: flex;
  flex-direction: column;
  width: 92%;
  margin: 1.25rem 1rem;
`;

export const SimilarityReportHeader = styled.div`
  border-radius: 0.625rem 0.625rem 0rem 0rem;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0.625rem 1.25rem;
  align-items: center;
`;

export const SimilarityReportBody = styled.div`
  display: flex;
  width: 100%;
  padding: 1.25rem 0;
`;

export const SimilarityReportBodyCells = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1.25rem;
  padding: 0 1.25rem;
  &:first-child {
    &:dir(ltr) {
      border-right: 1.1px solid #576371;
    }
    &:dir(rtl) {
      border-left: 1.1px solid #576371;
    }
  }
`;
