import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { auth0ProviderSplitter, auth0SubIdSplitter } from '../../utils/StringManipulation';
import { ClassHolder, ClassHolderContainer, Image, LoaderHolder, MainContainer } from './ImportClassPopup.style';
import SearchElement from '../../elements/searchElement/SearchElement';
import LoaderCircle from '../loader/loaderCircle/LoaderCircle';
import { useParams } from 'react-router-dom';
import SearchWithFilter from '../../elements/searchWithFilter/SearchWithFilter';
import { t } from 'i18next';
import { DarkCourseCard } from '../../elements/PngCalls/Pngcalls';
import { MediumTypography } from '../../elements/fonts/Fonts';

interface ImportClassPopupProps {
  setSelectedClassId: (classId: string) => void;
}

const ImportClassPopup: React.FC<ImportClassPopupProps> = ({ setSelectedClassId }) => {
  const tenantName = localStorage.getItem('tenant');
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const [classes, setClasses] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const ClassId = useParams();
  const id = ClassId.id;

  const handleClick = (id: any) => {
    setSelectedClassId(id);
  };

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await axios.get(`https://nyaqbcug2f.execute-api.eu-west-1.amazonaws.com/dev/class/teacher/${subIdSplitted}/${tenantName}?provider=${provider}`);
        setClasses(response.data.data);
        // console.log(response.data.data, "response");
      } catch (error) {
        console.error('Error fetching classes:', error);
      } finally {
        setIsLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchClasses();
  }, [subIdSplitted, tenantName]);

  const filteredClasses = classes
    .filter(classItem => classItem.classSourceId !== id) // Exclude the class with the matching ID
    .filter(classItem => classItem.classTitle.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <MainContainer>
      <SearchWithFilter 
      withFilter={false}
      placeholder={t('dashboard.Search')}
      height='2.75rem'
      onChange={(e) => setSearchTerm(e.target.value)} 
      />
      {isLoading ? (
        <LoaderHolder>
          <LoaderCircle height={25} width={25} />
        </LoaderHolder>
      ) : (
        filteredClasses.length > 0 ? (
          <ClassHolderContainer>
            {filteredClasses.map((classItem, index) => (
              <ClassHolder key={index} onClick={() => handleClick(classItem.classSourceId)}>
                <Image src={classItem.picture ? classItem.picture : DarkCourseCard}  />  
                <MediumTypography text={classItem.classTitle}/>
              </ClassHolder>
            ))}
          </ClassHolderContainer>
        ) : (
          <MediumTypography text={t("gradebook.no result found")}/>
        )
      )}
    </MainContainer>
  );
};

export default ImportClassPopup;
