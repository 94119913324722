import styled from "styled-components";

interface Component {
  color?: string;
}

export const SERVERStarColumn  =styled.div`
display: flex;
flex-direction: column;
gap:0.70rem;
`;

export const SERVERStarROw  =styled.div`
display: flex;
/* flex-direction: column; */
gap:1rem;
align-items:center;
`;

export const SurveyStarTextSize = styled.div`
  width: 15rem;
  /* background-color: aqua; */
`;

export const QADisplayPartsRowStart = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.69rem;
  flex-wrap: nowrap;
`;

export const QADisplaySVGHolder = styled.div<Component>`
  color: ${(props) => props.color};
  cursor: pointer;
`;