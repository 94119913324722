import styled from "styled-components";

export const Container = styled.div`
  background-color: #242c34;
  &:dir(ltr) {
    border-radius: 0 15px 15px 0;
  }
  &:dir(rtl) {
    border-radius: 15px 0 0 15px;
  }
  min-height: 12.6875rem;
  min-width: auto;
`;

export const SideMainContainer = styled.div`
  margin-top: 1.81rem;
  margin-bottom: 1.75rem;
`;
