import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { customStyles, EditorLoading, EditorWrapper } from "./TinyEditor.style";
import { TextEditProps } from "./TinyEditorInterface";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { SvgAttachment } from "../Icons";
import { generateUUID } from "../../utils/StringManipulation";
import { S3Upload } from "../../services/S3Put";

const TinyEditor = ({
  onChange,
  initialValue,
  placeholder,
  height,
  readonly,
  hasError = false,
  limit
}: TextEditProps): JSX.Element => {
  const [editorContent, setEditorContent] = useState<string>(
    initialValue ?? ""
  );
  const editorRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true); // For loading
  const { theme } = useRecoilValue(ColorSelector);
  const language = localStorage.getItem("language")
  const languageCode = language === "fr" ? "fr_FR" : language
  const { t } = useTranslation()

  useEffect(() => {
    setEditorContent(initialValue ?? "");
  }, [initialValue]);

  useEffect(() => {
    // Check if TinyMCE script is cached
    const cachedScript = localStorage.getItem("tinymceScript");

    if (cachedScript) {
      setIsLoading(false);
    } else {
      // Load TinyMCE script if not cached
      const script = document.createElement("script");
      script.src = "/tinymce/tinymce.min.js";
      script.async = true;
      script.onload = () => {
        setIsLoading(false);
        // Cache the script
        localStorage.setItem("tinymceScript", "cached");
      };
      document.body.appendChild(script);

      return () => {
        // Clean up script on component unmount
        document.body.removeChild(script);
      };
    }
  }, []);

  const handleEditorChange = (content: string) => {
    setEditorContent(content);
    onChange && onChange(content);
  };

  const openFilePicker = (editor: any) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', '.pdf, .doc, .docx, .xls, .xlsx, .jpg, .png, .jpeg, .mp4, .mov');
    input.onchange = function(event) {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const file = target.files[0];
        const reader = new FileReader();
        reader.onload = async function() {
          const fileContent = reader.result as string;
          const fileType = file.type.split('/')[1];
          const extention = file.name.split('.')[1]
          const fileUrl = URL.createObjectURL(file);
          const fileNameUUid = `${generateUUID()}${file.name}`;
          const filePath = new Date();
          const tenant = localStorage.getItem("tenant")
          const response = await S3Upload(
            filePath.toISOString(),
            file.name,
            fileNameUUid,
            file,
            true,
            true
          );
  
          // Insert file content based on type
          if (extention === 'pdf') {
            editor.insertContent(`<iframe src="https://tiny-mce-public.s3.eu-west-1.amazonaws.com/${tenant}/${fileNameUUid}" width="100%" height="500px"></iframe>`);
          } 
          if (['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].includes(extention)) {
            // editor.insertContent(`<p><a href="${fileUrl}" download>${file.name}</a></p>`);
            editor.insertContent(`<iframe src="https://view.officeapps.live.com/op/embed.aspx?src=https://tiny-mce-public.s3.eu-west-1.amazonaws.com/${tenant}/${fileNameUUid}" width="100%" height="500px"></iframe>`);
          }if(['jpg', 'png', 'jpeg'].includes(extention)){
            editor.insertContent(`<img src="https://tiny-mce-public.s3.eu-west-1.amazonaws.com/${tenant}/${fileNameUUid}" width="100%" height="500px"/>`)
          }
          if(['mp4', 'mov'].includes(extention)){
            editor.insertContent(`
              <video width="320" controls>
              <source src="https://tiny-mce-public.s3.eu-west-1.amazonaws.com/${tenant}/${fileNameUUid}" type="video/mp4">
              </video>`)
          }
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  }

  // customButton
  const directionality: 'rtl' | 'ltr' | undefined = language === 'ar' ? 'rtl' : 'ltr';

  const editorConfig = {
    menubar: false,
    plugins: [ "advlist", "autolink", "lists", "link", "image", "charmap", "codesample", "anchor", "searchreplace", "visualblocks", "code", "fullscreen", "insertdatetime", "media", "table", "preview", "help", "wordcount", "emoticons", "directionality"],
    toolbar: "undo redo | bold italic underline | superscript subscript forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | ltr rtl | fontsize table customButton image media unlink link emoticons charmap | copy paste cut | preview code codesample removeformat",
    content_style: customStyles(theme, language),
    content_css: theme === "dark" ? require("./TinyEditor.css") : require("./TinyEditorLight.css"),
    // content_css: require("./TinyEditor.css"),
    placeholder: placeholder ? placeholder : t("general.Enter your text here..."),
    height: height ? height : "20rem",
    statusbar: false,
    directionality,
    // language: languageCode ? languageCode : "",
    // language_url: language === 'tr' ? '/tinymce/langs/tr.js?v=1.0' : language === 'ar' ? '/tinymce/langs/ar.js?v=1.0' : language === 'fr' ? '/tinymce/langs/fr.js?v=1.0' : '',

    max_chars: limit,
    setup: function(editor:any) {
      if(limit){
        editor.on('init', function() {
          // Limit character count logic
          editor.on('keydown', function(event:any) {
            const content = editor.getContent({ format: 'text' });
            if (content.length >= limit && ![8, 37, 38, 39, 40, 46].includes(event.keyCode)) {
              event.preventDefault();
            }
          });
    
          editor.on('input', function() {
            const content = editor.getContent({ format: 'text' });
            if (content.length > limit) {
              const limitedContent = content.substring(0, limit);
              editor.setContent(limitedContent);
              editor.selection.setCursorLocation(null, limitedContent.length); // Move cursor to end
            }
          });
    
          editor.on('paste', function(event:any) {
            const content = editor.getContent({ format: 'text' });
            if (content.length >= limit) {
              event.preventDefault();
            }
          });
        });
    }
      editor.ui.registry.addIcon('attachIcon', '<svg class="custom-attach-icon" width="17" height="18" viewBox="0 0 17 18" fill="currentColor" color="currentColor" xmlns="http://www.w3.org/2000/svg"><g id="Vector"><path id="Vector_2" d="M14.2701 9.60644L7.63033 15.9186C6.90195 16.611 5.91405 17 4.88396 17C3.85387 17 2.86597 16.611 2.13759 15.9186C1.4092 15.2261 1 14.287 1 13.3077C1 12.3285 1.4092 11.3893 2.13759 10.6969L11.5799 1.72061C11.8204 1.49207 12.1059 1.31081 12.4201 1.18716C12.7343 1.06352 13.071 0.999924 13.4111 1C13.7512 1.00008 14.0879 1.06383 14.402 1.18761C14.7162 1.31139 15.0016 1.49278 15.242 1.72143C15.4824 1.95007 15.6731 2.22149 15.8031 2.52019C15.9332 2.81889 16.0001 3.13902 16 3.46229C15.9999 3.78557 15.9329 4.10566 15.8027 4.4043C15.6724 4.70294 15.4816 4.97428 15.2411 5.20281L5.79194 14.1857C5.5468 14.4091 5.21915 14.5311 4.8796 14.5271C4.54004 14.523 4.21577 14.3922 3.97667 14.163C3.73757 13.9337 3.60279 13.6244 3.60137 13.3016C3.59994 12.9787 3.732 12.6683 3.96908 12.4372L10.7099 6.02906M5.7997 14.1775L5.79107 14.1857" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g></svg>');
      editor.ui.registry.addButton("customButton", {
        icon: "attachIcon",
        tooltip: "Attach File",
        onAction: function() {
          openFilePicker(editor)
        }
      });
    },
  };

  return (
    <EditorWrapper haserror={hasError.toString()} readonly={readonly} theme={theme}>
      {isLoading ? (
        <EditorLoading height={height} theme={theme}></EditorLoading>
      ) : (
        <Editor
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          onInit={(evt, editor) => (editorRef.current = editor)}
          init={editorConfig}
          onEditorChange={handleEditorChange}
          disabled={readonly}
          value={editorContent}
        />
      )}
    </EditorWrapper>
  );
};

export default TinyEditor;
