import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { sortBy } from "lodash"; // Assuming you are using lodash for sorting
import {
  BlockContainer,
  ChatContainer,
  IconWrapper,
  InfoSvg,
  MaskGroup,
  MessageAndEmoji,
  MessageInput,
  MessageInputField,
  MessageOpenLabel,
  MessageOpenOption,
  MessagesFlow,
  MessagesHolder,
  MessagesHolderTop,
  MessagesLayout,
  OverlapGroup,
  ReceivedMessage,
  ReceivedMessageRow,
  RightIconsHolder,
  SenderMessage,
  SenderMessageRow,
  GroupSenderName, // Assuming you have GroupSenderName component defined
} from "./Messages.style";

import {
  AddSqIcon,
  SvgCircledDots,
  SvgEmoji,
  SvgEyeIcon,
  SvgMessageIcon,
  SvgSearchIcon,
  SvgSend2Icon,
} from "../../elements/Icons";
import {
  MediumTypography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import { getUserDisplayName, handleMessageDiv } from "./MessagesFunctions";
import MessamessageSenderInfoOnlyImgeSenderInfo from "../../elements/messageSenderInfo/messageSenderInfoOnlyImg";
import { useRecoilValue } from "recoil";
import { ChatsColorSelector, ColorSelector } from "../../recoil/ThemeSelectors";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { WebSocketConnector } from "./webSocketConnection";
import { get } from "../../utils/AxiosRequests";
import { ParticipantData } from "../../components/ParticipantProporties/ParticipantData/ParticipantData";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import { ClassContext } from "../dashboard/UnstructuredContext";
import { WebsocketContext } from "../../contexts/chatContext";
import {
  AbsoluteDiv2,
  RelativeDiv,
} from "../../components/manageWeight/ManageWeight.style";
import EmojiPicker, { EmojiStyle, Theme } from "emoji-picker-react";
import { toast } from "react-toastify";
import useClickOutside from "../../hooks/useClickOutside";
import { SpinnerContainer } from "../../elements/Spinner/ZSpinner/ZSpinner.style";
import LoaderCircle from "../../components/loader/loaderCircle/LoaderCircle";
import PopUp from "../../elements/Popup/PopUp";
import EditGroupPopup from "../../components/editGroup/EditGroupPopup";

interface Message {
  message: string;
  senderId: string;
  content: string;
  date: Date;
}
interface Conversation {
  _id: string;
  Participants: string[];
  name: string;
  Type: string;
}
interface Props {
  GroupMessages: any[];
  handleMuteConversation: (friendId: string, groupId: string) => void;
  handleArchiveConversation: (friendId: string, groupId: string) => void;
  handleBlockConversation: (friendId: string, groupId: string) => void;
  handleDeleteConversation: (friendId: string, groupId: string) => void;
  handleExitGroup: (groupId: string) => void;
  handleShowChatOption: (
    setShowChatOption: Dispatch<SetStateAction<boolean>>,
    ShowChatOption: boolean
  ) => void;
  handleSendIconClick: (
    inputMessage: string,
    friendId: string,
    groupId: string,
    setInputMessage: Dispatch<SetStateAction<string>>
  ) => void;
  SetviewParticipant: Dispatch<SetStateAction<boolean>>;
  viewParticipant: boolean;
  ShowSearchPlace: boolean;
  setShowSearchPlace: Dispatch<SetStateAction<boolean>>;
  ShowChatOption: boolean;
  setShowChatOption: Dispatch<SetStateAction<boolean>>;
  containerRef: React.RefObject<any>;
  inputMessage: string;
  setInputMessage: Dispatch<SetStateAction<string>>;
  selectedFriendId: string;
  selectedGroupId: any;
  selectedGroupData: any; // Update the type accordingly
  selectedGroupChatData: Message[]; // Update the type accordingly
  UserFriends: any; // Update the type accordingly
  isBlocked: boolean | undefined;
  senderName: string;
  chatContainerRef: React.RefObject<any>;
  convo: any;
  SetConvo: any;
  SetGroupMessages: Dispatch<SetStateAction<string[]>>;
  SetUserMessages: Dispatch<SetStateAction<string[]>>;
  SetLeaveGroup: Dispatch<SetStateAction<boolean>>;
  LeaveGroup: boolean;
}
interface GroupConv {
  _id: string;
  name: string;
  Type: string;
  Participants: string[];
  profile: string;
}

const GroupView: React.FC<Props> = ({
  SetviewParticipant,
  viewParticipant,
  selectedFriendId,
  LeaveGroup,
  SetLeaveGroup,
  GroupMessages,
  SetUserMessages,
  SetGroupMessages,
  handleMuteConversation,
  handleArchiveConversation,
  handleDeleteConversation,
  handleExitGroup,
  handleShowChatOption,
  handleSendIconClick,
  ShowSearchPlace,
  setShowSearchPlace,
  ShowChatOption,
  setShowChatOption,
  containerRef,
  inputMessage,
  setInputMessage,
  selectedGroupId,
  selectedGroupData,
  selectedGroupChatData,
  UserFriends,
  chatContainerRef,
  convo,
  SetConvo,
}) => {
  const {
    MainChatBox_bgColor,
    UserMessageBoxBG_Color,
    FriendMessageBoxBG_Color,
    AddBox_BG_Color,
  } = useRecoilValue(ChatsColorSelector);
  const { t } = useTranslation();
  const {
    backgroundColor,
    themeTextColor,
    bg_selectColor,
    bghelp,
    theme,
    inputBorderColor,
  } = useRecoilValue(ColorSelector);
  // const connector = new WebSocketConnector();
  // const webSocket = useRef(connector);
  const [mySourceId, setMySourceId] = useState<string>("");
  const [connectedToWebsocket, setConnectedToWebsocket] =
    useState<boolean>(false);
  const MainId = useParams();
  const classId = MainId.classId;
  const tenantName = localStorage.getItem("tenant");
  const { user, getAccessTokenSilently } = useAuth0();
  const myEmail = user?.email?.toLowerCase();
  const [messages, setMessages] = useState<any[]>([]);
  const [conversations, SetConversations] = useState<Conversation[]>([]);
  const [conversationId, SetConversationId] = useState("");
  const [GroupConv, SetGroupConv] = useState<GroupConv[]>([]);
  const [SingleGroupConversation, setSingleGroupConversation] = useState<
    GroupConv[]
  >([]);
  const [GroupParticipants, SetGroupParticipants] = useState([]);
  const [SentMessage, setSentMessage] = useState(false);
  const [ChosenGroup, SetChosenGroup] = useState<any>({});
  const [AllUsers, SetAllUsers] = useState<any[]>([]);
  const [EditGroup, SetEditGroup] = useState<boolean>(false)
  const coloursArray = ["#5DD3B3", "#3DA4FC", "#D85D66", "#CED35D", "#5DC5D3", "#8E5DFD", "#5D88D3", "#FDAD5D", "#0AB5AF", "#96B4E3", "#DB56A3", "#D07E4B", "#576371", "#AAB1B9"]
  const userColorMap = new Map();

  const getRandomColor = () => {
    const assignedColors = Array.from(userColorMap.values());
    const unassignedColors = coloursArray.filter(color => !assignedColors.includes(color));
    return unassignedColors.length > 0
      ? unassignedColors[Math.floor(Math.random() * unassignedColors.length)]
      : coloursArray[Math.floor(Math.random() * coloursArray.length)];
  };

  const getDeterministicColor = (senderId: any) => {
    let hash = 0;
    for (let i = 0; i < senderId.length; i++) {
      hash = senderId.charCodeAt(i) + ((hash << 5) - hash); // simple hash function
    }
    // Map the hash to a valid color index
    const colorIndex = Math.abs(hash) % coloursArray.length;
    return coloursArray[colorIndex];
  };

  const getUserColor = (senderId: any) => {
    // If the sender already has a color, return it
    if (userColorMap.has(senderId)) {
      return userColorMap.get(senderId);
    }

    // Otherwise, determine a new color based on the senderId
    const newColor = getDeterministicColor(senderId);
    userColorMap.set(senderId, newColor);
    return newColor;
  };





  const [groupData, setGroupData] = useState<any>({
    data: {
      tenantName: '',
      subId: '',
      provider: null,
      conversationId: '',
      groupName: '',
      groupProfile: '',
      participants: [],
    },
  });
  const {
    setOnlineClients,
    getUnvewedMessageCount,
    getUnvewedConversationsMessageCount,
  } = useContext(ClassContext);
  const { isReady, value, send } = useContext(WebsocketContext);
  const [EmojiOpen, SetEmojiOpen] = useState<boolean>(false);
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };
  const fetchAllUsers = async () => {
    try {
      const response = await get(`participant/class/${classId}/${tenantName}`);
      SetAllUsers(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const Noimage = require("../../assets/userProfile.png");
  const getSenderProfilePicture = (senderId: any) => {
    const senderUser = AllUsers.find((user) => user.sourceId === senderId);
    // Access the profile picture of the senderUser
    return senderUser ? senderUser.profilePicture : Noimage;
  };

  const fetchSingleConv = async () => {
    try {
      const response = await axios.get(
        `https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/get/conversations/${classId}/${subIdSplitted}/${tenantName}`
      );
      const selectedConversation = response.data.data.find(
        (conversation: { _id: any }) => conversation._id === selectedGroupId
      );
      SetChosenGroup(selectedConversation);
      console.log(selectedConversation, "Selectedes")
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchSingleConv();
    fetchAllUsers();
  }, [selectedGroupId, mySourceId]);

  const fetchUsersData = async () => {
    try {
      const response = await get(`participant/class/${classId}/${tenantName}`)
      const auditors = await axios.get(`https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/classAuditor/auditors/${tenantName}/${classId}`)

      const filteredData = response.data.filter(
        (user: any) => user.email.toLowerCase() === myEmail
      );
      const auditorFilteredData = auditors.data.data.filter(
        (user: any) => user.email.toLocaleLowerCase() === myEmail?.toLocaleLowerCase()
      );
      if (filteredData.length > 0)
        setMySourceId(filteredData[0].sourceId);
      else if (auditorFilteredData.length > 0)
        setMySourceId(auditorFilteredData[0].sourceId);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, [connectedToWebsocket]);

  const fetchAllUsersData = async () => {
    try {
      const response = await get(`participant/class/${classId}/${tenantName}`);
      const filteredData = response.data.filter(
        (user: any) => user.email.toLowerCase() !== myEmail
      );
      const studentIdsArray = filteredData.map(
        (student: { sourceId: any }) => student.sourceId
      );

      // Logging the student IDs array

      SetGroupParticipants(studentIdsArray);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllUsersData();
  }, [selectedGroupId, connectedToWebsocket]);

  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");

  var url = `wss://p1orbzycf9.execute-api.eu-west-1.amazonaws.com/dev/?subId=${subIdSplitted}&classSourceId=${classId}&tenantName=${tenantName} `;

  // webSocket.current.getConnection(url).onopen = function (event: any) {
  //   console.log('Connection is established');
  //   setConnectedToWebsocket(true);
  // };

  useEffect(() => {
    var data = JSON.parse(value);
    // console.log("Recieved Message---->",data)
    if (data && data.type && data.type === "message") {
      const receive = data.data;
      fetchEveryMessage();
      fetchEveryConv();
    } else if (data && data.type && data.type === "clients") {
      setOnlineClients(data.value);
      fetchEveryConv();
    } else if (data && data.type && data.type === "messages") {
      setMessages(data.value);
      SetGroupMessages(data.value);
      SetUserMessages([]);
    } else if (data && data.type && data.type === "public") {
      const receive = data.data;
      fetchEveryMessage();
      fetchEveryConv();
    } else if (data && data.type && data.type === "conversations") {
      SetConversations(data.value);
      SetConvo(data.value);
      fetchEveryMessage();
    } else if (data && data.type && data.type === "leaveGroup") {
    }
    // if (data && data.message === "Internal server error") {
    //   toast.error('An error occurred while sending the message. Please try again.')
    // }
  }, [value]);

  const HandleEmoji = () => {
    SetEmojiOpen(!EmojiOpen);
  };
  // webSocket.current.getConnection(url).onerror = function (event: any) {
  //   console.log('WebSocket error: ', event);
  // };

  // webSocket.current.getConnection(url).onclose = function () {
  //   console.log('Connection is closed');
  //   setConnectedToWebsocket(false);
  // };

  const fetchEveryConv = async () => {
    try {
      const response = await axios.get(
        `https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/get/conversations/${classId}/${subIdSplitted}/${tenantName}?provider=${provider}`
      );
      SetConvo(response.data.data);
      const filteredGroups = response.data.data.filter(
        (conversation: { Type: any }) => conversation.Type === "group"
      );
      SetGroupConv(filteredGroups);
      scrollToBottom();
    } catch (err) {
      console.log(err);
    }
  };

  const fetchEveryMessage = async () => {
    try {
      const response = await axios.get(
        `https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/get/messages/${selectedGroupId}/${tenantName}/${subIdSplitted}?provider=${provider}`
      );
      setMessages(response.data.data);
      SetGroupMessages(response.data.data);
      SetUserMessages([]);
      scrollToBottom();
      const updateViewedMessadeData = {
        tenantName: tenantName,
        subId: subIdSplitted,
        provider: provider,
        conversationId: selectedGroupId,
      };
      const updateResponse = await axios.put(
        `https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/update/viewed`,
        {
          data: updateViewedMessadeData,
        }
      );
      getUnvewedMessageCount(classId || "");
      getUnvewedConversationsMessageCount(classId || "");
      scrollToBottom();
    } catch (err) {
      console.log(err);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      sendPublicMessage();
      fetchEveryMessage();
      SetEmojiOpen(false);
    }
  };

  // Send Public Message
  const sendPublicMessage = () => {
    const data = {
      tenantName: tenantName,
      message: inputMessage, // The message i want to sent
      senderId: mySourceId, // My sourceId
      recipientId: null, // The sourceId of the users in the group
      conversationId: selectedGroupId,
    };
    if (send && isReady) {
      send(
        JSON.stringify({
          action: "sendPublic",
          data: {
            tenantName: tenantName,
            message: inputMessage, // The message i want to sent
            senderId: subIdSplitted, // My sourceId
            provider: provider,
            recipientId: ChosenGroup.Participants, // The sourceId of the users in the group
            conversationId: selectedGroupId, // The id of the conversation
          },
        })
      );
    }
    setInputMessage("");
    scrollToBottom();
  };

  // Leave Group
  const leaveGroup = () => {
    if (send && isReady) {
      send(
        JSON.stringify({
          action: "leaveGroup",
          data: {
            tenantName: tenantName,
            userSourceId: mySourceId,
            subId: subIdSplitted,
            provider: provider,
            conversationId: selectedGroupId, // The conversation id
          },
        })
      );
    }
    SetLeaveGroup(!LeaveGroup);
    SetGroupConv([]);
    fetchEveryConv();
    window.location.reload();
  };

  useEffect(() => {
    setInputMessage("");
    fetchEveryMessage();
  }, [selectedGroupId, connectedToWebsocket]);

  const filterGroupConversations = () => {
    const filteredGroups = convo.filter(
      (conversation: any) => conversation.Type === "group"
    );
    SetGroupConv(filteredGroups);
  };
  useEffect(() => {
    filterGroupConversations();
  }, [selectedFriendId, connectedToWebsocket]);

  useEffect(() => {
    filterGroupConversations();
  }, [selectedGroupId, connectedToWebsocket]);

  useEffect(() => {
    if (mySourceId) {
      const foundConversations = GroupConv.filter(
        (conversation) => conversation._id === selectedGroupId
      );

      // Check if any conversation is found
      if (foundConversations.length > 0) {
        // Set the conversation to state
        setSingleGroupConversation(foundConversations);
      }
    }
  }, [mySourceId, GroupConv, connectedToWebsocket]);

  const getUserDisplayName = (id: string) => {
    const user = UserFriends.find((user: { id: any }) => user.id === id);
    return user ? user.username : null;
  };

  const handleEmojiClick = (emojiData: any) => {
    setInputMessage((prevMessage) => prevMessage + emojiData.emoji); // Use the appropriate property to get the emoji character
  };

  const deleteConversations = async () => {
    try {
      const response = await axios.put(
        "https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/delete/conversations",
        {
          data: {
            tenantName: tenantName,
            subId: subIdSplitted,
            provider: provider,
            conversationId: conversationId,
          },
        }
      );
    } catch (error) {
      console.error("Error deleting conversations:", error);
    }
  };
  const EmojiContainerRef = useClickOutside(EmojiOpen, () =>
    SetEmojiOpen(false)
  );






  const ShowEditGroup = () => {

    SetEditGroup(true)
    setShowChatOption(false)
  }

  const CloseEditPopup = async () => {
    await fetchEveryConv();
    SetEditGroup(false)
  }


  const [SavedEdit, SetSavedEdit] = useState(false)

  useEffect(() => {
    try {

      fetchEveryConv()
      fetchSingleConv()
      SetEditGroup(false)
    } catch {
      console.log("error getting conversations")
    } finally {
      SetLeaveGroup(!LeaveGroup)
      console.log("fetching conversations done")
    }

  }, [SavedEdit])
  return (
    <>
      {selectedGroupId && (
        <MessagesHolder bgColor={MainChatBox_bgColor}>
          <MessagesHolderTop bordercolor={bghelp}>
            <MessamessageSenderInfoOnlyImgeSenderInfo
              onClick={() => SetviewParticipant(true)}
              profileImg={
                ChosenGroup?.profile
                  ? ChosenGroup?.profile
                  : require("../../assets/GroupDefaultProfile.png")
              }
              online={true}
              ShowOnline={false}
              Name={ChosenGroup?.name}
              participantNames={true}
              participantInfo={ChosenGroup?.ParticipantsInfo}

            />

            <RightIconsHolder ref={containerRef}>
              {!ShowSearchPlace && (
                <InfoSvg
                  style={{
                    paddingTop: "-0.2rem",
                    paddingRight: "0.3rem",
                    background: backgroundColor,
                    boxShadow:
                      theme === "light"
                        ? "0px 3px 6px rgba(0, 0, 0, 0.16)"
                        : "",
                  }}
                  onClick={() =>
                    handleMessageDiv(setShowSearchPlace, ShowSearchPlace)
                  }
                >
                  <SvgSearchIcon />
                </InfoSvg>
              )}

              <InfoSvg
                style={{
                  // transform: "rotate(90deg)",
                  paddingTop: "-0.2rem",
                  paddingRight: "0.3rem",
                  background: backgroundColor,
                  boxShadow:
                    theme === "light" ? "0px 3px 6px rgba(0, 0, 0, 0.16)" : "",
                }}
                onClick={() =>
                  handleShowChatOption(setShowChatOption, ShowChatOption)
                }
                ref={containerRef}
              >
                <SvgCircledDots width={18} height={22} />
              </InfoSvg>
              {ShowChatOption && (
                <MessageOpenOption bgColor={bg_selectColor}>
                  {/* <MessageOpenLabel
                    onClick={() =>
                      handleMuteConversation(
                        selectedFriendId,
                        selectedGroupId ?? ""
                      )
                    }
                  >
                    <MediumTypography text={t("Chat.Mute")} fontSize="0.75rem;" />
                  </MessageOpenLabel>
                  <MessageOpenLabel
                    onClick={() =>
                      handleArchiveConversation(
                        selectedFriendId,
                        selectedGroupId ?? ""
                      )
                    }
                  >
                    <MediumTypography text={t("Chat.Archive")} fontSize="0.75rem;" />
                  </MessageOpenLabel>
                  <MessageOpenLabel
                    onClick={() =>
                      handleDeleteConversation(
                        selectedFriendId,
                        selectedGroupId ?? ""
                      )
                    }
                  >
                    <MediumTypography
                      text={t("Chat.Delete conversation")}
                      fontSize="0.75rem;"
                    />
                  </MessageOpenLabel> */}
                  <MessageOpenLabel onClick={() => leaveGroup()}>
                    <MediumTypography
                      text={t("Chat.Exit Group")}
                      fontSize="0.75rem;"
                    />
                  </MessageOpenLabel>
                  {
                    ChosenGroup.isAdmin &&
                    <MessageOpenLabel onClick={() => ShowEditGroup()}>
                      <MediumTypography
                        text={"Edit Group"}
                        fontSize="0.75rem;"
                      />
                    </MessageOpenLabel>
                  }

                </MessageOpenOption>
              )}
            </RightIconsHolder>
          </MessagesHolderTop>

          {mySourceId ? (
            <ChatContainer ref={chatContainerRef}>
              {/* Render group chat messages */}
              {sortBy(GroupMessages, "date").map((message, index, arr) => {
                const isCurrentUser = message.senderId === mySourceId;
                const isSameSenderAsPrevious =
                  index > 0 && arr[index - 1].senderId === message.senderId; // Check if the sender is the same as the previous message

                return (
                  <MessagesFlow key={index}>
                    {isCurrentUser ? (
                      <MessagesLayout>
                        <SenderMessageRow>
                          <SenderMessage
                            bgColor={UserMessageBoxBG_Color}
                            style={{ marginTop: isSameSenderAsPrevious ? "" : "27px" }}
                          >
                            <p className="messagesText">{message.message}</p>
                          </SenderMessage>
                          <MaskGroup
                            src={
                              getSenderProfilePicture(message.senderId)
                                ? getSenderProfilePicture(message.senderId)
                                : Noimage
                            }
                            style={{ visibility: isSameSenderAsPrevious ? "hidden" : "visible" }}
                          />
                        </SenderMessageRow>
                      </MessagesLayout>
                    ) : (
                      <ReceivedMessageRow>
                        <MaskGroup
                          src={
                            getSenderProfilePicture(message.senderId)
                              ? getSenderProfilePicture(message.senderId)
                              : Noimage
                          }
                          style={{ visibility: isSameSenderAsPrevious ? "hidden" : "visible" }} // Make it invisible for consecutive messages
                        />

                        <ReceivedMessage
                          bgColor={FriendMessageBoxBG_Color}
                          style={{ marginTop: "27px" }}
                        >
                          <GroupSenderName>
                            <TypographyRegularDescription
                              text={getUserDisplayName(message.senderId)}
                              fontSize="0.75rem"
                              color={
                                UserFriends.find(
                                  (friend: { id: string }) => friend.id === message.senderId
                                )?.color || getUserColor(message.senderId)
                              }
                            />
                          </GroupSenderName>

                          <p className="messagesText">{message.message}</p>
                        </ReceivedMessage>
                      </ReceivedMessageRow>
                    )}
                  </MessagesFlow>
                );
              })}
            </ChatContainer>


          ) : (
            <SpinnerContainer>
              <LoaderCircle />
            </SpinnerContainer> // Replace with your loading component
          )}
          <MessageInput ref={EmojiContainerRef}>
            {/* <OverlapGroup bgColor={AddBox_BG_Color}>
              <AddSqIcon />
            </OverlapGroup> */}
            <RelativeDiv>
              <AbsoluteDiv2 bordercolor={inputBorderColor} theme={theme}>
                <EmojiPicker
                  open={EmojiOpen}
                  onEmojiClick={handleEmojiClick}
                  height={"20rem"}
                  theme={Theme.DARK}
                  emojiStyle={EmojiStyle.TWITTER}
                  searchPlaceHolder={t("Chat.Search emoji")}
                />
              </AbsoluteDiv2>
              <MessageAndEmoji>
                <MessageInputField
                  type="text"
                  placeholder={t("Chat.Send message")}
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  color={themeTextColor}
                  onKeyDown={handleKeyDown}
                  themeColor={theme}
                  EmojiOpen={EmojiOpen}
                />
                <OverlapGroup bgColor={AddBox_BG_Color}>
                  <SvgEmoji
                    color={themeTextColor}
                    onClick={() => HandleEmoji()}
                  />
                </OverlapGroup>
              </MessageAndEmoji>
            </RelativeDiv>

            <div style={{ width: "2.5rem" }}>
              <IconWrapper onClick={() => sendPublicMessage()}>
                <SvgSend2Icon />
              </IconWrapper>
            </div>
          </MessageInput>
        </MessagesHolder>
      )}
      {
        EditGroup &&
        <PopUp
          content={<EditGroupPopup selectedGroupId={selectedGroupId} SetSavedEdit={SetSavedEdit} SavedEdit={SavedEdit} />}
          setShow={SetEditGroup}
          show={EditGroup}
          showButton={false}
          showButtonCancel={false}
          title={t("Edit Group")}
          icon={<SvgEyeIcon />}
          showline={false}
          ContentWidth="43.75rem"
          ContentHeight="25rem"
        />
      }
    </>
  );
};

export default GroupView;
