export const menuItems = [
   
  
       
        {
          title: "Sort by",
          submenu: [
            {
              title: "Alphabetical Order",
              submenu: [
                {
                  title: "Ascending",
                },
                {
                  title: "Descending",
                },
              ],
            },
            {
              title: "Highest Grade",
             
            },
            {
              title: "Lowest Grade",
             
            },
          ],
        },
        {
          title: "Filters",
          submenu: [
            {
              title: "Assignment",
            },
            {
              title: "Attendance",
            },
            {
              title: "Quiz",
            },
          ],
        },
     
    
  ];