import styled from "styled-components";

export const PublicProPIC = styled.img`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  /* margin:auto; */
  margin: 0 auto;
  border: 4px solid #576371;
`;
