import styled from "styled-components";
import { breakpoints } from "../../theme";

export const Cols = styled.style`
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: space-evenly;
  @media (max-width: ${breakpoints.tablets}) {
    flex-direction: column;
    width: 100%;
  }
  &:nth-child(7) {
    /* width:105%; */
    width: 90%;
    /* background-color:yellow; */
    margin-top: 2.5rem;
    margin-left: 1rem;
  }
`;

export const ColsWithPaddingText = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  height: 5.5rem;
  justify-content: space-between;
  margin-left: 1rem;
  @media (max-width: ${breakpoints.tablets}) {
    width: 100%;
  }
`;
export const ColsWithoutPaddingText = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 95%;
  margin-left: 1rem;
  @media (max-width: ${breakpoints.tablets}) {
    width: 100%;
  }
`;

export const TextTile = styled.div`
  height: 3rem;
`;
export const SubTile = styled.div`
  margin-bottom: 0.7rem;
  margin-top: 1rem;
  display: flex;
`;

export const LongTextInputEmail = styled.input<{
  color?: string;
  FocusColor?: string;
}>`
  border-radius: 0.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #576371;
  /* opacity: 0.8; */
  box-sizing: border-box;
  background-color: transparent;
  color: ${(props) => props.color};
  padding: 0.88rem 1.25rem 0.81rem 1.25rem;
  &:dir(ltr) {
    padding-left: 1rem;
  }
  &:dir(rtl) {
    padding-right: 1rem;
  }

  width: 95%;
  font-family: "Primary font-light";
  @media (min-width: ${breakpoints.extraLarge}) {
    /* display:none; */
    height: 4.5rem;
  }
  &:focus {
    outline: none;
    /* caret-color: white; */
    caret-color: ${(FocusColor) => FocusColor.color};
  }
`;

export const GoFlexRight = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 95%;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const GoFlexLeft = styled.div`
  display: flex;
  width: 100%;

  /* background-color:red; */
  &:nth-child(1) {
    /* width:105%; */
    width: 10.3rem;
    justify-content: space-between;
  }
  &:nth-child(3) {
    justify-content: space-between;
  }

  &:nth-child(5) {
    justify-content: space-between;
  }
`;

export const TextAsSizeDive = styled.div`
  width: 83%;
  display: flex;
  text-align: left;
  /* background-color:purple; */
`;

export const ButtomFieldStyle = styled.div`
  display: flex;
  text-align: center;
  justify-content: right;
  align-items: center;
`;

export const ErroTextDisplay = styled.div`
  display: flex;
  width: 100%;
`;
export const ErroTextSpaceDisplay = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-between;
`;
export const GroupRow = styled.div`
  display: flex;
  /* width:40%; */
`;

export const ErrorColsDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0.5rem 0;
  &:nth-child(1) {
    margin: -0.5rem 0 1rem 0;
    /* background-color:red; */
  }
`;

export const SmallIconStatus = styled.img`
  border-radius: 50%;
  margin-left: 0.5rem;
  display: cover;
`;

export const ColumnWebLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.63rem;
  margin-top: 0.3rem;
`;
