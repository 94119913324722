import styled from "styled-components";
import { breakpoints } from "../../theme";
// import DateP   ker from 'react-datepicker';
// import TimePicker from 'react-time-picker';

export const TextInputSpace = styled.input<{fontFamily:string}>`
width:auto;  
display:flex;
justify-content:flex-end;
margin-top:0.31rem;
background-color: ${props => (props.disabled ? "red" : "transparent")};
border-radius: 6.25rem;
border: 1px solid #5DD3B3;
color:#fff;
font-size: 0.875rem; font-family: ${(props) => (props.fontFamily ? props.fontFamily : 'Primary Font-Medium')};
outline: none;
`;
export const StyledInput = styled.div<{isopen:any, width?:any, border?:any, padding?:any, height?:any}>`
 width: ${(props) => (props.width ? props.width : '18rem')};
height: ${(props) => (props.height ? props.height : '2.8125rem')}; 
border: ${(props) => (props.border ? props.border : '1px solid #576371')};
border-radius: 6.25rem;
border: 1px solid #5DD3B3;
color: #5DD3B3;
cursor: pointer;

/* padding: 0.87rem 0.87rem 0.87rem 0.87rem; */
padding:${(props) => (props.padding ? props.padding : '1rem 1.25rem')};

::placeholder{
font-family: 'Primary Font-Light';
font-size: 0.875rem;
color: #5DD3B3;
font-style: normal;
font-weight: 400;
line-height: normal;
}
/* @media (max-width: ${breakpoints.large}) {
    width: 27.8125rem;
    height: 3.4375rem;
  }
  @media (max-width: ${breakpoints.small}) {
    width: 20.9925rem;
    height: 3.4375rem;
  } */
 
`;
export const SelectContainer = styled.div<{ zIndex: number; isOpen?: boolean }>`
   position: relative; 
  cursor:pointer; 
  z-index: ${(props) => (props.isOpen ? props.zIndex + 1 : props.zIndex)};
  color: ${props => props.color};
font-size: 0.875rem;
font-family: 'Primary Font-Medium';

`;
export const Select = styled.div<{color:string}>`
display: flex;
justify-content: space-between;
align-items:center;
 &:hover{
  color:${(props) => (props.color ? props.color : '')} ;
} 
`;
export const Arrow = styled.div<{marginLeft:any}>`
display: flex;
justify-content: center;
align-items: center;
`;

export const OptionsContainer = styled.div<{optionWidth?:any}>`
position: relative;
top:1rem;
bottom:0;
right: 1.2rem;
width: ${(props) => (props.optionWidth ? props.optionWidth : '0')};


`;

export const Options = styled.div<{ index: any, lastitem: any, display: string, optionIcon?: React.ReactNode | null, fontFamily: string, color?: string, backgroundColor?:string }>`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0.62rem 1.25rem;
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : 'Primary Font-Bold')};
  display: ${(props) => (props.display ? props.display : 'flex')};
  gap: 0.63rem;
  border-radius: ${(props) => {
    if (props.index === 0 && props.lastitem === 0) {
      // First and last item
      return '0.625rem 0.625rem 0 0';
    } else if (props.index === 0) {
      // First item
      return '0.625rem 0.625rem 0 0';
    } else if (props.lastitem === props.index) {
      // Last item
      return '0 0 0.625rem 0.625rem';
    } else {
      return ''; // Default value
    }
  }};
  &:hover {
    background: #5DD3B3;
    color: #fff;
  }
`;


export const MainOptionContainer=styled.div<{fontSize?:any}>`
display: flex;
gap:0.63rem;
font-size:${(props) => (props.fontSize ? props.fontSize : '0.875rem')};
`;