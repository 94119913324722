import styled from "styled-components";
import { breakpoints } from '../../../theme';



export const ColorPickerHolder = styled.div`


width: 18.4375rem;
height: 2.1875rem;
flex-shrink: 0;
border-radius: 0.625rem;
background: linear-gradient(90deg, #576371 0%, #415160 0%, #293440 100%);
display: flex;
justify-content: center;
align-items: center;
gap: 0.62rem;

@media (max-width: ${breakpoints.tablets}) {
  display: flex;
  width: 100%;
height: 2.1875rem;
  justify-content: center;
align-items: center;
margin-top:-2rem ;
position: absolute;
top: 10%;
right: 0%;
border-radius: 0rem;

}
;
`
export const ColorPickerButton = styled.label`
  display: inline-block;
  position: relative;
 width: 0.9375rem;
height: 0.9375rem;
  /* margin: 10px; */
  cursor: pointer;

  input {
    display: none;
    background-color: transparent;
    appearance: none;
  }

  span {
    display: block;
    position: absolute;
    width: 0.9375rem;
height: 0.9375rem;
    padding: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    background: #eeeeee;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    transition: ease 0.3s;
  }

  &:hover span {
    padding: 10px;
  }

  &.color1 span {
    background: #5DC5D3;
  }

  &.color2 span {
    background: #3DA4FC;
  }
  &.none span {
    background: none;
  }

  &.color3 span {
    background: #5D69FD;
  }

  &.color4 span {
    background: #8E5DFD;
  }

  &.color5 span {
    background: #B757E2;
  }

  &.color6 span {
    background: #DB56A3;
  }
  &.color7 span {
    background: #D04B4B;
  }
  &.color8 span {
    background: #D07E4B;
  }
  &.color9 span {
    background: #D0C64B;
  }
  &.color10 span {
    background: #58BE5B;
  }

  input:checked + .layer {
    background: ${(props) => props.color};
  }
`;
export const ColorPickerLayer = styled.div`
display: block;
position: absolute;
width: 100%;
height: 100%;
top: 0;
left: 0;
background: transparent;
z-index: -1;
`;
// export const RadioStyled = styled.input.attrs({type:"radio"})`
// accent-color:   ${(props) => props.color || '#5DC5D3'};;
// width: 0.9375rem;
// height: 0.9375rem;
// appearance: none;
// background: transparent;
//   margin: 0;
//   border: 1px solid  ${(props) => props.color || '#5DC5D3'};;
//   border-radius: 50%;
//   &::before{
//     content: "";
//     display: flex;
//     align-items: center;
//     justify-content: center;
//       width: 100%;
//       height: 100%;
//       background-color: ${(props) => props.color || '#5DC5D3'};;
//       border-radius: 50%;
//   }
// `
export const ColorPicker = styled.input.attrs({ type: 'color' })`
  width: 0.9375rem;
height: 0.9375rem;
  border-radius: 50%; /* Make it a circle */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  border: none; /* Remove border */
  appearance: none; /* Remove default styles */
  cursor: pointer; /* Change cursor to pointer for user interaction */
  background-color: transparent; /* Remove background color */
  position: relative;
  /* Style the color indicator inside the circle */
  &::before{
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: none;
  }
  &::-webkit-color-swatch-wrapper {
    padding: 0;
    border: none;
    border-radius: 50%;
    display: none;
  }

  &::-webkit-color-swatch {
    border: none;
    display: none;
    border-radius: 50%;
  }
  &:hover {
    cursor: url('.'), auto; /* Replace '/path/to/custom-cursor.png' with the actual path to your cursor image */
  }
`;
export const ColorPickerSvgHolder = styled.div`
position: absolute;
/* transform: translateX(7.8rem); */
margin-top: -0.1rem;
width: 0.9375rem;
height: 0.9375rem;
`

