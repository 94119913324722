import React, { PureComponent } from "react";
import {
  BarChart,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  ResponsiveContainer,
} from "recharts";
import { BarChartsInterface } from "./BarChartsInterface";

interface VerticalChartProps {
  dataKeyA: string;
  dataKeyB?: string;
  dataKeyText: string;
  data: BarChartsInterface[];
  avg?: string;
  DisplayDataB?: boolean;
  TextDispalys?: boolean;
  height?: string;
  // Line?:string;
}

export default class BarChartsCompo extends PureComponent<VerticalChartProps> {
  static demoUrl = "https://codesandbox.io/s/vertical-composed-chart-w6fni";

  render() {
    const {
      dataKeyA,
      dataKeyB,
      data,
      dataKeyText,
      avg,
      height = "100%",
      DisplayDataB = false,
      TextDispalys = true,
    } = this.props;
    return (
      <ResponsiveContainer width="100%" height={height}>
        <ComposedChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid
            vertical={false}
            horizontal={true}
            strokeWidth={0.1}
            strokeOpacity={1}
          />
          <XAxis
            tickLine={false}
            axisLine={false}
            dataKey={dataKeyText}
            style={{ marginTop: "2rem" }}
            interval={0}
            tick={(props) => {
              const { x, y, payload } = props;
              const words = payload.value.split(" "); // Split label into words
              return (
                <>
                  {TextDispalys && (
                    <g transform={`translate(${x},${y + 5})`}>
                      <text
                        x={0}
                        y={-9}
                        dy={0}
                        fontSize="0.75rem"
                        fontWeight="bold"
                        textAnchor="middle"
                        fill="#fff"
                      >
                        {words.map((word: string, index: number) => (
                          <tspan
                            key={index}
                            // x={-14}
                            x={0}
                            dy={index === 0 ? "1em" : "1.5em"}
                          >
                            {word}
                          </tspan>
                        ))}
                      </text>
                    </g>
                  )}
                </>
              );
            }}
          />

          <YAxis tickLine={false} axisLine={false} />
          {/* <Tooltip /> */}
          {/* <Legend /> */}

          {DisplayDataB && <Bar dataKey={dataKeyB || ""} fill="#5DC5D3" />}
          <Bar dataKey={dataKeyA} fill="#5DD3B3" width={1} barSize={20} />

          <Line
            style={{ width: "140%", marginLeft: "-10rem" }}
            type="monotone"
            // dataKey={avg }
            dataKey={avg}
            width={100}
            stroke="#0B8FFF"
            strokeDasharray="5 5"
            dot={{
              stroke: "#3DA4FC",
              strokeWidth: 2,
              fill: "#fff",
              r: 0,
              width: 0,
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}
