import React, { useEffect, useState } from "react";
import { DotsStyle, IconsPosition, Overlay, ResourceBottom, ResourceBottomHolder, ResourceHolderStyle, ResourceUpperHolder, } from "./ResourceCard.style";
import { SvgDotsIcon, SvgDownload, SvgPlay } from "../../../../elements/Icons";
import { Link } from "react-router-dom";
import { Typography } from "../../../../elements/fonts/Fonts";
import { getFileName } from "./getFileName";
import { S3Get } from "../../../../services/S3Get";
import { ContentR } from "../../../CourseTimeline/utils/CourseResourcesInterface";
import { getResourceType } from "./getResourceType";
import { downloadFileFile } from "./downloadFile";
import { ResourceProps } from "../../dashboardContentInterfaces";
import { useRecoilValue } from "recoil";
import { ColorSelector, CourseColorSelector } from "../../../../recoil/ThemeSelectors";

const getImageUrl = async (resourceR: ContentR) => {

  const resourceRType = getResourceType(getFileName(resourceR?.path || ""));
  if (resourceRType === "Pic") {
    try {
      const imageUrl = await S3Get(resourceR.path);
      return imageUrl;
    } catch (error) {
      console.error("Error fetching image URL:", error);
      return "";
    }
  } else if (resourceRType === "Vid") {
    try {
      const imageUrl = await S3Get(resourceR.thumbnail);
      return imageUrl;
    } catch (error) {
      console.error("Error fetching image URL:", error);
      return "";
    }
  }
  return resourceR?.path;
};

export default function ResourcePicVidComponent(
  { resourceData, hovered, allResources, courseId, resourceR }: ResourceProps,
  fileType: string
) {
  const { ContentDocBgColor, theme } = useRecoilValue(CourseColorSelector);
  const { themeTextColor } = useRecoilValue(ColorSelector);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<Boolean>(true);
  //   const [imageS3Url, setImageS3Url] = useState();
  const [vidTranscript, setTranscript] = useState();
  const [vidPath, setVidPath] = useState();
  const handleDownload = () => {
    if (loading) {
      return;
    }

    if (resourceR.type === "Picture") {
      if (imageUrl) {
        downloadFile(imageUrl);
      } else {
        console.error("imageUrl is undefined or null");
      }
    } else if (resourceR.type === "File") {
      downloadFile(imageUrl);
    } else if (resourceR.type === "Video") {
      if (vidPath) downloadFileFile(vidPath, resourceData);
    }
  };

  const downloadFile = (fileUrl: any) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = getFileName(fileUrl);
    link.click();
  };
  useEffect(() => {
    const fetchImage = async () => {
      setImageUrl(await getImageUrl(resourceData));
      setLoading(false);
    };
    const fetchVideo = async () => {
      setLoading(true);
      setVidPath(await S3Get(resourceData.path));

      setLoading(false);
    };
    fetchImage();
    if (resourceR.type === "Video") {
      fetchVideo();
    }
  }, [resourceData]);

  return (
    <ResourceHolderStyle
      hovered={hovered}
      allResources={allResources}
    // bgColor={}
    >
      <ResourceUpperHolder bgImg={imageUrl} allResources={allResources}
        theme={theme}
        bgColor={ContentDocBgColor}
      // bg={allResources ? theme === "light" ? "#F7F7F8" : "90deg, #415160 0%, #293440 107.88%" : ContentDocBgColor}
      >
        <Overlay></Overlay>
        {fileType === "Vid" && <SvgPlay />}
      </ResourceUpperHolder>
      <ResourceBottomHolder
        hovered={hovered}
        style={{ background: "transparent" }}
      >
        <ResourceBottom hovered={hovered}>
          <Typography
            text={getFileName(resourceData?.path || "") || resourceData.title}
            fontSize="0.75rem"
            color={themeTextColor}
          />
          <IconsPosition>
            {loading ? (
              <></>
            ) : (
              <SvgDownload
                color="#5dd3b3"
                onClick={handleDownload}
                cursor={"pointer"}
              />
            )}

            <Link
              to={`/courseView/content/${courseId}`}
              state={{
                activity: resourceR,
                moduleIndex: resourceR?.moduleIndex,
                topicIndex: resourceR?.topicIndex,
                contentIndex: resourceR?.contentIndex,
                contentId: resourceR?.contentId,
                isVisible: resourceR?.isVisible
              }}
            >
              <DotsStyle>
                <SvgDotsIcon color="#5dd3b3" width={"1rem"} />
              </DotsStyle>
            </Link>
          </IconsPosition>
        </ResourceBottom>
      </ResourceBottomHolder>
    </ResourceHolderStyle>
  );
}
