import styled, { css } from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

// interface Interface{
//     placeholderFontFamily:string
// }
export const ParticipantCardHolder = styled.div<{
  bgColor?: string;
  boxShadow?: string;
}>`
  min-width: 25.4375rem;
  max-width: 29rem;
  /* height: 7.625rem; */
  flex-shrink: 0;
  border-radius: 0.625rem;
  /* background: #384450; */
  box-shadow: ${(props) => props.boxShadow};
  background: ${(props) => props.bgColor || "#384450"};
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  /* padding-bottom: 1.12rem; */
  position: relative;
`;
export const ProfileImageHolder = styled.div``;
export const ProfileImg = styled.img<StyleThemeInterface>`
  border: 1px solid ${({ bordercolor }) => bordercolor || "#384450"};
  width: 3.125rem;
  height: 3.125rem;
  filter: ${({ bordercolor }) =>
    bordercolor === "light"
      ? " drop-shadow(1px 1px 10px rgba(0, 0, 0, 0.25))"
      : "drop-shadow(1px 1px 10px rgba(0, 0, 0, 0.25))"};
  border-radius: 50%;
  object-fit: cover;
`;
export const ProfileNameEmailRow = styled.div`
  display: flex;
  gap: 0.62rem;
  /* margin-left: 0.5rem; */
  align-items: center;
`;
export const NameEmailColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.31rem;
`;
export const InputSendRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.44rem;
`;
export const InputMessageField = styled.input`
  width: 14.61025rem;
  height: 1.625rem;
  border-radius: 0.375rem;
  background: #576371;
  outline: none;
  border: none;
  padding-left: 0.71rem;
  color: #fff;
  &::placeholder {
    color: #fff;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: ${(props) => props.theme.font.primaryLight};
  }
`;
export const ProfileImgHolder = styled.div`
  /* margin-left: 0.5rem; */
`;
export const SendButton = styled.button`
  align-self: center;
  appearance: none;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  width: 1.07275rem;
  height: 1rem;
`;
export const DotsContainer = styled.div`
  position: absolute;
  top: 1rem;
  &:dir(ltr){
    right: 1rem;
  }
  &:dir(rtl){
    left: 1rem;
  }
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
`


export const OptionCard = styled.div<{ bgColor?: string }>`
  display: flex;
  flex-direction: column;
  /* width: 6.75rem;
  height: 5rem; */
  position: absolute;
  border-radius: 0.3125rem;
  background: ${(props) => props.bgColor};
  justify-content: space-between;
  &:dir(ltr){
    right: 1.25rem;
  }
  &:dir(rtl){
    left: 1.25rem;
  }
  top: 2.5rem;

  z-index: 1;
  width: 6.75rem;
`;