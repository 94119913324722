import styled from "styled-components";

export const CASIDContainer =styled.div`
width: 100%;
flex-shrink: 0;
border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
min-height:7rem;
display:flex;
flex-direction:column;
`;



export const CASIDContainerHeader =styled.div`
width: 100%;
display:flex;
flex-direction:row;
justify-content:space-between;
padding: 1.25rem 1.25rem 2.40rem  1.25rem;
`;


export const CASIDContainerHeaderProfileInfo =styled.div`

display:flex;
gap:0.2rem;
align-items:center;
font-size:1.5rem;
`;

export const CASIDProfilePic=styled.img`
width: 3.125rem;
height: 3.125rem;
flex-shrink: 0;
border-radius:50%;
margin-right:0.35rem;
`;

export const CASIDContainerHeaderOptions=styled.div`
display:flex;
flex-direction:column;
gap:1.50rem;
justify-content:flex-end;
align-items:flex-end;
`;


export const CASIDTable=styled.div`
width: 100%;
display:flex;
flex-direction:column;

`;

export const CASIDTableHeader=styled.div`
width: 100%;
display:flex;
gap:0.35rem;
/* background:aqua ; */
padding: 0.94rem 1.25rem ;
opacity: 0.5;
background: var(--Grey-Gradient, linear-gradient(90deg, #415160 0%, #293440 107.88%));

`;






export const CASIDTableBody=styled.div`
width: 100%;
display:flex;
gap:0.35rem;
/* background:black ; */
padding: 0.94rem 1.25rem ;
&:nth-child(odd) {
  background: rgba(255, 255, 255, 0.03);
  }
`;

export const CASIDTableHeaderCell = styled.div`
  flex: 1;
  /* background-color: red; */
  display:flex;
  align-items:center;
justify-content:center;
  &:first-child {
    justify-content:left;
    flex: 1.35;
  }

  &:nth-child(5){
    flex: 0.8;
    /* background-color:blue; */

  }
  &:nth-child(6){
    flex: 0.6;

  }
  &:last-child {
    flex: 0.4;
    justify-content:flex-end;

  }
`;

export const CASIDTableBodyCell = styled.div`
  flex: 1;
  /* background-color: yellow ; */
font-size:0.75rem !important;
display: flex !important;
justify-content:center;
align-items:center;

  &:first-child {
    justify-content:left;
    flex: 1.35;
    font-size:0.875rem;
    /* background-color:purple; */
  }

  &:nth-child(5){
    flex: 0.8;
  }
  
  &:nth-child(6){
    flex: 0.6;

  }
  &:last-child {
    flex: 0.4;
    position:relative !important;
    justify-content:flex-end;
  }
`;

export const CASIDTableViewLink = styled.div`
width: 6.625rem;
height: 2.25rem;
flex-shrink: 0;
border-radius: 0.3125rem;
background: #1F2731;
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
/* justify-content:center; */
align-items:center;
display: flex;
position:absolute;
left:-2.6rem;
top:1rem;
gap:0.5rem;
padding-left:1.2rem;
&:hover{
  &:hover{
  background: var(--Main-Green-color, #5DD3B3);
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);  
}}
`;