import styled from 'styled-components'


export const IconPointsContainer = styled.div`
display: flex;
align-items:start;
padding-left:1.60rem;
margin-top:1.06rem;
gap:0.62rem;
/* background:red; */
`;

export const IconContainer = styled.div`
display: flex;
justify-content:space-between;
align-items:center;
/* background:blue; */
`;

export const QuestionFlexContainer = styled.div`
width:100%;
display: flex;
flex-direction:column;
gap:0.31rem;

`;
export const PartContainer = styled.div`
display: flex;
align-items:end;
gap:0.62rem;
&:first-child{
flex-wrap:wrap;
  /* background:yellow !important; */
}
&:last-child{
  cursor: pointer;

  flex-wrap:nowrap;
  justify-content:flex-end;
  align-self: start;
  min-width:5rem;
}
`;