import styled from "styled-components";
import { breakpoints } from "../../../theme";

export const DashboardActivitiesHolder = styled.div`
  padding: 0.5rem 0 3rem 0;
  border-radius: 1.25rem;
  background: transparent;
  max-height: 24rem !important;
  /* max-height: 24rem; */

  margin-bottom: 2rem;
`;

export const ModulesHolder = styled.div<{ BorderColor?: string }>`
  /* max-height: 21rem !important; */

  max-height: 23rem !important;

  overflow-y: auto;
  width: 33.5%;
  &:dir(ltr) {
    border-right: 1px solid ${(props) => props.BorderColor || "#576371"};
  }
  &:dir(rtl) {
    border-left: 1px solid ${(props) => props.BorderColor || "#576371"};
  }
  flex-direction: column;
  display: flex;
`;

export const ModuleHolder = styled.div<{
  activeModule: boolean;
}>`
  background: ${(props) =>
    props.activeModule
      ? "linear-gradient(90deg, #5DD3B3 0%, #2D3741 100%)"
      : "transparent"};
  &:dir(ltr) {
    padding: 0.56rem 0 0.56rem 2.56rem;
  }
  &:dir(rtl) {
    padding: 0.56rem 2.56rem 0.56rem 0;
  }
  cursor: pointer;
`;
export const ActivitiesDivHolder = styled.div`
  width: 100%;
  max-height: 24rem !important;
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  overflow-y: auto;
`;
export const ActivityCardHolder = styled.div`
  &:dir(ltr) {
    padding-left: 1.32rem;
  }
  &:dir(rtl) {
    padding-left: 1.32rem;
  }

  display: flex;
  align-items: center;
  height: 2.5rem;
`;

export const ResourcesHeaderDiv = styled.div`
  display: flex;
  &:dir(ltr) {
    padding-right: 2.2rem;
  }
  &:dir(rtl) {
    padding-left: 2.2rem;
  }

  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const SearchDiv = styled.div`
  height: 2.75rem;
  width: 30%;
`;
export const HeaderTitles = styled.div`
  &:dir(ltr) {
    padding-right: 2.2rem;
    padding-left: 1.31rem;
  }
  &:dir(rtl) {
    padding-left: 2.2rem;
    padding-right: 1.31rem;
  }
  align-self: end;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ViewAllT = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;
`;
export const ModuleEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`;
export const ChoseModel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20rem;
  align-self: center;
  text-align: center;
  margin-top: -2rem;
`;
export const LoadingNoResources = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ActivitiesHoler = styled.div`
  /* border-top: ; */
  display: flex;
  width: 100%;
  /* margin-top: 1rem; */
  flex-direction: column;
  row-gap: 1.25rem;
  overflow-y: auto;
  /* border-top: 1px solid #576371; */
  /* max-height: 60%; */
  scroll-behavior: smooth;
  &&::-webkit-scrollbar {
    width: 0 !important;
  }
`;
