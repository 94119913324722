import React, { useState } from "react";
import { FlexAppContainer } from "../../../../../App.style";
import { Typography } from "../../../../../elements/fonts/Fonts";
import SearchElement from "../../../../../elements/searchElement/SearchElement";
import {
  CompetencyFrameworksContainer,
  CompetencyFrameworksSearch,
} from "./CompetencyFrameworksHeader.style";
import { CompetencyFrameworksFilteredData } from "./CompetencyFrameworksFilteredData";
import FilteredButtonShape from "../../../../../elements/FilteredButtonShape/FilteredButtonShape";
import ButtonIconAndText from "../../../../../elements/ButtonIconAndText/ButtonIconAndText";
import { SvgDownload, SvgPieCharts } from "../../../../../elements/Icons";
import { ExcelDownload } from "../../../../../utils/ExcelGlobalFunction";
import { CompetencyFrameworksData } from "../../ComopetencyFrameworksData/ComopetencyFrameworksData";
import { CompetencyFrameworksInterface } from "../../ComopetencyFrameworksData/CompetencyFrameworksInterface";
import * as XLSX from "xlsx";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../../../recoil/ThemeSelectors";
// Define the props interface for CompetencyFrameworksHeader
interface CompetencyFrameworksHeaderInterface {
  setFilteredValue: React.Dispatch<React.SetStateAction<string>>;
  setTextSearch: React.Dispatch<React.SetStateAction<string>>;
}

// Define the CompetencyFrameworksHeader component
const CompetencyFrameworksHeader = ({
  setFilteredValue,
  setTextSearch,
}: CompetencyFrameworksHeaderInterface) => {
  // Function to set the filtered value and log information
  const ShowValue = (text: string, value: string) => {
    setFilteredValue(value);
  };
  const [activeValue, setActiveValue] = useState<string | null>(null);
  const ExcelDownload = (
    data: CompetencyFrameworksInterface[] | undefined,
    tableName: string
  ) => {
    if (!data) {
      console.error("No competency data found.");
      return;
    }

    const headers = [
      "Student Code",
      "Student Name",
      "Competency ID",
      "Competency Name",
      "Competency Description",
      "Activity Name",
      "Coverage",
      "Proficiency Level",
      "Role",
      "Poor Range",
      "Intermediate Range",
      "Advanced Range",
      "Student Visibility",
    ];

    const excelRows = [];

    data.forEach((student) => {
      const competencies = student.competencies || [];
      competencies.forEach((competency) => {
        if (!competency) return; // Skip if competency is undefined

        const activities = competency.Activities || [];
        activities.forEach((activity: any) => {
          const row = [
            student.stdCode || "", // Accessing stdCode from the outer structure
            student.studentName || "", // Accessing studentName from the outer structure
            competency.CompetencyId || "",
            competency.CompetencyName || "",
            competency.CompetencyDescription || "",
            activity.activityName || "",
            activity.coverage || "",
            activity.ProficiencyLevel || "",
            activity.Role || "",
            activity.PoorRange || "",
            activity.IntermediateRange || "",
            activity.AdvanceRange || "",
            competency.StudentsVisibility || "",
          ];
          excelRows.push(row);
        });
      });
    });

    excelRows.unshift(headers);

    const workSheet = XLSX.utils.aoa_to_sheet(excelRows);
    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, tableName);

    const buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    XLSX.writeFile(workBook, `${tableName}.xlsx`);
  };
  const { SecondaryTextColor, bghelp, theme } = useRecoilValue(ColorSelector);
  return (
    // Main container for CompetencyFrameworksHeader
    <FlexAppContainer column={true} gap="1.5rem">
      {/* Container for the title and search element */}
      <FlexAppContainer
        justifyContent="space-between"
        wrap={true}
        gap="1.25rem"
      >
        {/* Title */}
        <Typography
          text="List Of Competency Frameworks"
          fontSize="1.375rem"
          lineHeight="2rem"
          color={SecondaryTextColor}
        />

        {/* Search Element */}
        <CompetencyFrameworksSearch>
          <SearchElement onChange={(e) => setTextSearch(e.target.value)} />
        </CompetencyFrameworksSearch>
      </FlexAppContainer>

      {/* Container for competency frameworks and actions */}
      <FlexAppContainer wrap={true} gap="1.25rem">
        {/* Container for competency frameworks */}
        <CompetencyFrameworksContainer>
          {/* Mapping through CompetencyFrameworksFilteredData and creating FilteredButtonShape for each */}
          {CompetencyFrameworksFilteredData.map((val) => (
            <FilteredButtonShape
              key={val.value}
              value={val.value}
              text={val.text}
              setActiveValue={setActiveValue}
              activeValue={activeValue}
              onClick={() => ShowValue(val.text, val.value)}
            />
          ))}
        </CompetencyFrameworksContainer>

        {/* Container for actions */}
        <CompetencyFrameworksContainer>
          {/* ButtonIconAndText for Coverage */}
          {/* <ButtonIconAndText iconPath={SvgPieCharts} text="Coverage" /> */}
          {/* ButtonIconAndText for Download */}
          <ButtonIconAndText
            iconPath={SvgDownload}
            text="Download"
            onClick={() =>
              ExcelDownload(CompetencyFrameworksData, "Competency Frameworks")
            }
          />
        </CompetencyFrameworksContainer>
      </FlexAppContainer>
    </FlexAppContainer>
  );
};

export default CompetencyFrameworksHeader;
