import React, { FC } from "react";
import { TextElementProps } from "./TextElementInterface";
import { theme } from "../../theme";
const TextElement: FC<TextElementProps> = ({
  tag,
  text,
  fontSize,
  fontFamily,
  fontWeight,
  lineHeight,
  wordWrap,
  whiteSpace,
  color,
  overflowWrap,
  fontStyle,
  // opacity,
}) => {
  const style: React.CSSProperties = {
    fontSize: fontSize || "1rem",
    fontFamily: fontFamily || "Primary Font",
    fontWeight: fontWeight || "400",
    lineHeight: lineHeight || "normal",
    wordWrap: (wordWrap as React.CSSProperties["wordWrap"]) || "normal",
    whiteSpace: (whiteSpace as React.CSSProperties["whiteSpace"]) || "normal",
    color: color,
    // opacity,opacity,

    overflowWrap:
      (overflowWrap as React.CSSProperties["overflowWrap"]) || "normal",
    fontStyle: fontStyle || "normal",
  };

  return React.createElement(tag, { style }, text);
};

export default TextElement;
