import React, { useEffect, useState } from "react";
import {
  LightTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  Input,
  InputCont,
  LabelDiv,
  QuestionSelected,
  QuestionSelectedContainer,
} from "./AllQuestions.style";
import { AllQuestionsInterface } from "./AllQuestionsInterface";
import { FlexDiv } from "../../globalStyles";
import CheckBoxInput from "../../elements/checkbox/CheckBox";
import { useMyContext } from "../../contexts/QuestionBanksContext";
import ActionButtons from "./ActionButtons";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import {
  auth0SubIdSplitter,
  generateUUID,
  auth0ProviderSplitter
} from "../../utils/StringManipulation";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  CourseColorSelector,
} from "../../recoil/ThemeSelectors";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const TrueFalse = ({ handleCancel }: AllQuestionsInterface) => {
  const { id } = useParams();
  const { user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const { savedQuestions, setSavedQuestions, questionToUpdate, updateMode } =
    useMyContext();
  const { t } = useTranslation();
  const feedbackRight = "quiz.Your Answer is correct."
  const feedbackWrong = "quiz.Your Answer is incorrect."
  const [isLoading, setIsLoading] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [feedbackRightError, setFeedbackRightError] = useState(false);
  const [feedbackWrongError, setFeedbackWrongError] = useState(false);
  const [prevData, setPrevData] = useState<any>();
  const [trueFalseData, setTrueFalseData] = useState({
    questionText: "",
    answer: true,
    feedbackRight: t(feedbackRight),
    feedbackWrong: t(feedbackWrong),
  });

  // Set initial state based on updateMode and questionToUpdate
  useEffect(() => {
    if (updateMode && questionToUpdate && questionToUpdate.id) {
      setTrueFalseData({
        questionText: questionToUpdate.question,
        answer: questionToUpdate.answer,
        feedbackRight: questionToUpdate.feedbackRight,
        feedbackWrong: questionToUpdate.feedbackWrong,
      });
    }
  }, [updateMode, questionToUpdate]);

  const handleQuestionChange = (value: string) => {
    setTrueFalseData((prevState) => ({ ...prevState, questionText: value }));
    setDescriptionError(value.trim() === ""); // Check if the question is empty
  };

  const handleFeedbackRightChange = (value: string) => {
    setTrueFalseData((prevState) => ({
      ...prevState,
      feedbackRight: value,
    }));
    setFeedbackRightError(value.trim() === "");
  };

  const handleFeedbackWrongChange = (value: string) => {
    setTrueFalseData((prevState) => ({
      ...prevState,
      feedbackWrong: value,
    }));
    setFeedbackWrongError(value.trim() === "");
  };

  const handleAnswerChange = (value: boolean) => {
    setTrueFalseData((prevState) => ({ ...prevState, answer: value }));
  };

  //function to save the data in the context state
  const handleSave = async () => {
    const { questionText, answer, feedbackRight, feedbackWrong } =
      trueFalseData;

    // Check if the question is empty
    setDescriptionError(questionText.trim() === "");
    setFeedbackRightError(feedbackRight.trim() === "");
    setFeedbackWrongError(feedbackWrong.trim() === "");

    if (
      questionText.trim() === "" ||
      feedbackRight.trim() === "" ||
      feedbackWrong.trim() === ""
    ) {
      return;
    }

    setIsLoading(true);
    const newTrueFalseQuestion = {
      id: generateUUID(),
      classSourceId: id,
      subId: subId,
      provider:provider,
      contentType: "TF",
      question: questionText,
      answer: answer,
      feedbackRight: feedbackRight,
      feedbackWrong: feedbackWrong,
    };
    try {
      const response = await axios.post(
        `https://0ddea1gj55.execute-api.eu-west-1.amazonaws.com/dev/question/bank/create/${tenantName}`,
        {
          data: newTrueFalseQuestion,
        }
      );
      setIsLoading(false);
      handleCancel();
    } catch (error) {
      console.log(error);
    }
    // }
  };
  const { ClassGradientBox } = useRecoilValue(CourseColorSelector);
  const { themeTextColor, bghelp } = useRecoilValue(ColorSelector);
  return (
    <QuestionSelectedContainer>
      <QuestionSelected style={{ background: ClassGradientBox }}>
        <Typography text={t("quiz.TRUE/ FALSE")} fontSize="1.25rem" />

        {/* text editor part */}
        <LabelDiv>
          <Typography text={t("question bank.Question Text")} fontSize="1rem" />
          <TinyEditor
            hasError={descriptionError}
            initialValue={trueFalseData.questionText}
            onChange={handleQuestionChange}
            placeholder={t("question bank.Type your question")}
            height="17.5625rem"
          />
        </LabelDiv>

        <TypographyRegularDescription text={`${t("quiz.Choices")}`} />
        <FlexDiv gap="2rem">
          <FlexDiv gap="0.5rem">
            <CheckBoxInput
              name="check"
              checked={trueFalseData.answer === true}
              onChange={() => handleAnswerChange(true)}
              value=""
            />
            <LightTypography text={t("quiz.TRUE")} fontSize="0.75rem" />
          </FlexDiv>
          <FlexDiv gap="0.5rem">
            <CheckBoxInput
              name="check"
              checked={trueFalseData.answer === false}
              onChange={() => handleAnswerChange(false)}
              value=""
            />
            <LightTypography text={t("quiz.FALSE")} fontSize="0.75rem" />
          </FlexDiv>
        </FlexDiv>

        <InputCont>
          <LabelDiv style={{ width: "100%" }}>
            <TypographyRegularDescription
              text={t("quiz.Feedback if right")}
              fontSize="0.875rem"
            />
            <Input
              borderColor={bghelp}
              color={themeTextColor}
              hasError={feedbackRightError}
              value={trueFalseData.feedbackRight}
              onChange={(e) => handleFeedbackRightChange(e.target.value)}
              type="text"
              placeholder={t("quiz.Feedback if right")}
              width="100%"
            />
          </LabelDiv>
          <LabelDiv style={{ width: "100%" }}>
            <TypographyRegularDescription
              text={t("quiz.Feedback if wrong")}
              fontSize="0.875rem"
            />
            <Input
              borderColor={bghelp}
              color={themeTextColor}
              hasError={feedbackWrongError}
              value={trueFalseData.feedbackWrong}
              onChange={(e) => handleFeedbackWrongChange(e.target.value)}
              type="text"
              placeholder={t("quiz.Feedback if wrong")}
              width="100%"
            />
          </LabelDiv>
        </InputCont>

        {(descriptionError || feedbackRightError || feedbackWrongError) && (
          <ErrorDiv>{t("quiz.This field is mandatory")}</ErrorDiv>
        )}
      </QuestionSelected>

      {/* action buttons component part*/}
      <ActionButtons
        handleSave={handleSave}
        handleCancel={handleCancel}
        isLoading={isLoading}
      />
    </QuestionSelectedContainer>
  );
};

export default TrueFalse;
