import React, { FC } from "react";
import {
  ShortAnswerContainer,
  ShortAnswerInputs,
} from "./ShortAnswerQuestion.style";
import { ShortAnswerQuestionProps } from "./ShortAnswerQuestionInterface";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete 1 copy.svg";
import { ErrorDiv } from "../../../elements/ErrorDiv/Errordiv.style";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
const ShortAnswerQuestion: FC<ShortAnswerQuestionProps> = ({
  choice,
  qindex,
  showQuest,
  handleChoiceMatchingQuestion,
  setChoiceIndex,
  setDeleteChoice,
  questionError,
  allChoices,
}) => {
  const { SecondaryTextColor, QuizCreator_ValidatesInput_bgColor, bghelp,inputBorderColor,readOnlyBgColor } =
    useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  return (
    <>
      <ShortAnswerContainer>
        <ShortAnswerInputs
          center="no"
          value={choice.name}
          show={showQuest.toString()}
          type="text"
          width="100%"
          placeholder={t("quiz.write answer")}
          readOnly={showQuest ? true : false}
          onChange={(e) => handleChoiceMatchingQuestion(qindex, e)}
          id="name"
          hasError={qindex === 0 && !!questionError && !choice.name}
          color={SecondaryTextColor}
          Validated_BgColor={readOnlyBgColor}
          borderColor={inputBorderColor}
        />
        {allChoices.length > 1 ? (
          <DeleteIcon
            onClick={() => {
              setDeleteChoice(true);
              setChoiceIndex(qindex);
            }}
          />
        ) : (
          ""
        )}
      </ShortAnswerContainer>
      {/* {(questionError)  && (qindex === 0 ) && <ErrorDiv style={{marginLeft:"1.56rem"}}>{questionError}</ErrorDiv>} */}
    </>
  );
};
export default ShortAnswerQuestion;
