import React, { useEffect, useState } from "react";
import { FlexAppContainer } from "../../../App.style";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import {
  ColorSelector,
  MainColorSelector,
} from "../../../recoil/ThemeSelectors";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../../elements/fonts/Fonts";
import {
  EditFormMainColumnContainer,
  EditFormMainRowContainer,
  EditFormNormalRowContainer,
} from "./EditForm.style";
import {
  ClumnDilpay,
  FormRow,
  GoFlexRight,
  ImageCotainer,
  OptingAboutImageCotainer,
  ProfileUpdateImage,
} from "../FormEditProfile.style";
import ProfileFileSelected from "../StyledSelect/ProfileFileSelected";
import { ButtonModification } from "../../../elements/ProfileModifiedOption/ProfileModiedButton.style";
import { SvgClose, SvgRefreshIcon } from "../../../elements/Icons";
import { get, put } from "../../../utils/AxiosRequests";
import { S3Get } from "../../../services/S3Get";
import {
  auth0SubIdSplitter,
  generateUUID,
  auth0ProviderSplitter,
} from "../../../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import { S3Upload } from "../../../services/S3Put";
import EditProfileInput from "./Input/EditProfileInput";
import { SochialMediaTextInput } from "../../../elements/SocialMediaTextInput/SochailMediaTextInput ";
import { SubmitButtomEditProfile } from "../../../elements/SubmitSaveButton/SubmitButtonStyle";
import PopupDelete from "../../../elements/PopupDelete/PopupDelete";
import { ImgProfileImage } from "../../../elements/Images/Images";
import { ColumnWebLinks } from "../../ChngingPrimaryEmailForm/UpdateEmailForm.style";

const EditFormV2 = () => {
  const {
    themeTextColor,
    textInputColorA,
    bg_selectColor,
    FocusTextColor,
    headerTextInput_ProfileAndBorder,
    profileCardBorderColor,
    theme,
  } = useRecoilValue(ColorSelector);
  const { t } = useTranslation();
  const { user } = useAuth0();
  const [isHovered, setIsHovered] = useState(false);
  const [ProfileImage, setProfileImage] = useState<string>();
  const [ProfilePic, setProfilePic] = useState<string>("");

  const [FirstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [Heading, setHeading] = useState("");
  const [Biography, setBiography] = useState("");
  const [WebSite, setWebSite] = useState("");
  const [Email, setEmail] = useState("");
  const [X, setX] = useState("");
  const [YouTube, setYouTube] = useState("");
  const [TikTok, setTikTok] = useState("");
  const [LikedIn, setLikedIn] = useState("");
  const [Password, setPassword] = useState("12345678");
  const [id, setID] = useState(1234);
  const [fileUUId, setFileUUId] = useState("");

  const [errorFirstName, seterrorFirstName] = useState("");
  const [errorlastName, seterrorlastName] = useState("");
  // const [errorHeading, seterrorHeading] = useState("");
  // const [errorBiography, seterrorBiography] = useState("");
  // const [ErrorEmail, setErrorEmail] = useState("");
  const [darkTheme, setdarkTheme] = useState<boolean>(true);

  const tenantName = localStorage.getItem("tenant");
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const imageGif = require("../../../assets/loadingGif.gif");
  const filePath = "user/profilePictures";
  const isValidEmail = (email: string) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };
  const [ShowDeletePopUp, setShowDeletePopUp] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const [profPicAfterGet, setprofPicAfterGet] = useState("");
  const handleRemovePic = () => {
    setProfileImage("");
    setProfilePic("");
  };

  const [loading, setIsLoading] = useState(true);
  const handleAddprofile = async (files: FileList | null) => {
    if (files) {
      setIsLoading(true);

      const fileNameUUid = `${generateUUID()}${files[0].name}`;
      const response = await S3Upload(
        filePath,
        files[0].name,
        fileNameUUid,
        files[0]
      );

      setFileUUId(fileNameUUid);
      const pictureKey = `${filePath}/${fileNameUUid}`;

      const pic = await S3Get(`${filePath}/${fileNameUUid}`);
      if (pic) {
        setProfileImage(pictureKey);
        setProfilePic(pic);
      }

      setIsLoading(false);
    }
  };

  const handleOpenDelet = () => {
    setShowDeletePopUp(true);
  };

  const handelEditUser = async () => {
    seterrorFirstName("");
    seterrorlastName("");
    // seterrorHeading("");
    // seterrorBiography("");
    // setErrorEmail("");

    if (FirstName.trim() === "") {
      seterrorFirstName("error");
      return;
    }
    if (lastName.trim() === "") {
      seterrorlastName("error");
      return;
    }

    const userProfile = {
      // id,
      profilePicture: ProfileImage ? ProfileImage : "",
      // profilePictureName:(ProfileImage ? ProfileImage : ""),
      fName: FirstName ? FirstName : "",
      lName: lastName ? lastName : "",
      headline: Heading ? Heading : "",
      bio: Biography ? Biography : "",
      // webLink: WebSite ? WebSite : "",
      webLink:
        WebSite &&
        (WebSite.startsWith("https://") || WebSite.startsWith("http://"))
          ? WebSite
          : WebSite
          ? `https://${WebSite}`
          : "",
      email: Email ? Email : "",
      // Password,
      xPlatform:
        X && (X.startsWith("https://") || X.startsWith("http://"))
          ? X
          : X
          ? `https://${X}`
          : "",
      youtube:
        YouTube &&
        (YouTube.startsWith("https://") || YouTube.startsWith("http://"))
          ? YouTube
          : YouTube
          ? `https://${YouTube}`
          : "",
      tiktok:
        TikTok &&
        (TikTok.startsWith("https://") || TikTok.startsWith("http://"))
          ? TikTok
          : TikTok
          ? `https://${TikTok}`
          : "",
      linkedIn:
        LikedIn &&
        (LikedIn.startsWith("https://") || LikedIn.startsWith("http://"))
          ? LikedIn
          : LikedIn
          ? `https://${LikedIn}`
          : "",
      subId: subIdSplitted ? subIdSplitted : "",
      isActive: 0,
    };

    //https://f7shhykgob.execute-api.eu-west-1.amazonaws.com/dev/user/profile/update/{tenantName}
    await put(`user/profile/update/${tenantName}`, userProfile);
    window.location.reload();
  };
  //https://f7shhykgob.execute-api.eu-west-1.amazonaws.com/dev/user/profile/{subId}/{tenantName}

  //GEt the Announecement By It's ID Data
  useEffect(() => {
    const getuserProfileById = async () => {
      try {
        const userProfileToDisplay = await get(
          `user/profile/${subIdSplitted}/${tenantName}/?provider=${provider}`
        );
        setFirstName(userProfileToDisplay.data.fName);
        setlastName(userProfileToDisplay.data.lName);
        setHeading(userProfileToDisplay.data.headline);
        setBiography(userProfileToDisplay.data.bio);
        setWebSite(userProfileToDisplay.data.webLink);
        setEmail(userProfileToDisplay.data.email);
        setX(userProfileToDisplay.data.xPlatform);
        setYouTube(userProfileToDisplay.data.youtube);
        setLikedIn(userProfileToDisplay.data.linkedIn);

        setTikTok(userProfileToDisplay.data.tiktok);
        setprofPicAfterGet(userProfileToDisplay.data.profilePicture);
        const profPic = await S3Get(userProfileToDisplay.data.profilePicture);
        setProfilePic(userProfileToDisplay.data.profilePicture);
        setProfileImage(userProfileToDisplay.data.profilePictureName);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    getuserProfileById();
  }, []);
  useEffect(() => {
    if (theme === "default" || theme === "dark") setdarkTheme(true);
    else setdarkTheme(false);
  }, [theme]);

  return (
    <FlexAppContainer column={true} gap="1.25rem">
      <Typography
        fontSize="1.75rem"
        fontWeight="400"
        text={`${t("User Profile.Profile Information")} : `}
      />
      <EditFormMainRowContainer>
        <ImageCotainer>
          <OptingAboutImageCotainer
            style={{ background: bg_selectColor, color: themeTextColor }}
          >
            {/* <ButtonModification
              type="button"
              value="View Profile Picture"
              bgImage="/Images-1.svg"
            /> */}

            <ProfileFileSelected
              onChange={handleAddprofile}
              title={t("video.Upload Image")}
              icon={SvgRefreshIcon}
              acceptedFileTypes=".jpg, .jpeg, .png"
            />
            <ButtonModification
              onClick={handleOpenDelet}
              style={{ background: bg_selectColor, color: themeTextColor }}
            >
              <SvgClose />
              <MediumTypography
                text={t("User Profile.Delete Profile Picture")}
                fontSize="0.75rem"
              />
            </ButtonModification>
          </OptingAboutImageCotainer>

          {loading ? (
            <ProfileUpdateImage
              src={imageGif}
              alt=""
              bordercolor={theme === "dark" ? "#576371" : "#AAB1B9"}
            />
          ) : (
            <ProfileUpdateImage
              // src={ProfileImage}
              src={
                // profPicAfterGet !== "" ||
                ProfilePic !== "" || ProfileImage !== ""
                  ? ProfilePic
                  : ImgProfileImage
              }
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          )}
        </ImageCotainer>
        <EditFormMainColumnContainer>
          <EditFormNormalRowContainer>
            <EditProfileInput
              title={t("User Profile.First Name")}
              placeholder={t("User Profile.First Name")}
              borderColor={errorFirstName && "red"}
              value={FirstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                seterrorFirstName("");
              }}
            />
            <EditProfileInput
              title={t("User Profile.Last Name")}
              placeholder={t("User Profile.Last Name")}
              borderColor={errorlastName && "red"}
              value={lastName}
              onChange={(e) => {
                setlastName(e.target.value);
                seterrorlastName("");
              }}
            />
          </EditFormNormalRowContainer>
          <EditProfileInput
            title={t("User Profile.Headline")}
            placeholder="Ex : Engineer at BMW"
            value={Heading}
            onChange={(e) => {
              setHeading(e.target.value);
            }}
          />
        </EditFormMainColumnContainer>
      </EditFormMainRowContainer>
      <EditProfileInput
        textArea={true}
        title={t("User Profile.Biography")}
        placeholder={t("User Profile.First Name")}
        value={Biography}
        onChange={(e) => {
          setBiography(e.target.value);
        }}
      />
      {/* <GoFlexRight>
        {" "}
        <MediumTypography
          text={t("User Profile.300 CHARACTER")}
          fontSize="0.625"
          color={headerTextInput_ProfileAndBorder}
        />
      </GoFlexRight> */}
      <ColumnWebLinks>
        <LightTypography fontSize="0.875rem" text="Web Links" />
        <FormRow style={{ gap: "1.25rem", flexWrap: "wrap" }}>
          <ClumnDilpay>
            <SochialMediaTextInput
              type="text"
              bgImage={theme === "dark" ? "/Website.svg" : "/darkWebsite.svg"}
              placeholder={t("User Profile.Website")}
              value={WebSite}
              // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              //   const value = e.target.value;
              //   setWebSite(
              //     value.startsWith("https://") ? value : `https://${value}`
              //   );
              // }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWebSite(e.target.value)
              }
              color={theme === "dark" ? "#fff" : "#364350"}
              FocusColor={FocusTextColor}
              style={{ borderColor: profileCardBorderColor }}
            />
            <SochialMediaTextInput
              type="text"
              bgImage={theme === "dark" ? "/Twitter.svg" : "/darkTwitter.svg"}
              placeholder="X"
              value={X}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setX(e.target.value)
              }
              color={theme === "dark" ? "#fff" : "#364350"}
              FocusColor={FocusTextColor}
              style={{ borderColor: profileCardBorderColor }}
            />
            <SochialMediaTextInput
              type="text"
              bgImage={theme === "dark" ? "/tik-tok.svg" : "/darktTk-tok.svg"}
              placeholder="TikTok"
              value={TikTok}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTikTok(e.target.value)
              }
              color={theme === "dark" ? "#fff" : "#364350"}
              FocusColor={FocusTextColor}
              style={{ borderColor: profileCardBorderColor }}
            />
          </ClumnDilpay>

          <ClumnDilpay>
            <SochialMediaTextInput
              type="text"
              bgImage={theme === "dark" ? "/Email.svg" : "/darkEmail.svg"}
              placeholder={t("User Profile.Type Your Email")}
              value={Email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              color={theme === "dark" ? "#fff" : "#364350"}
              FocusColor={FocusTextColor}
              style={{ borderColor: profileCardBorderColor }}
              // style={{ borderColor: ErrorEmail ? "red" : "" }}
            />
            <SochialMediaTextInput
              type="text"
              bgImage={theme === "dark" ? "/Youtube.svg" : "/darkYoutube.svg"}
              placeholder="Youtube"
              value={YouTube}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setYouTube(e.target.value)
              }
              color={theme === "dark" ? "#fff" : "#364350"}
              FocusColor={FocusTextColor}
              style={{ borderColor: profileCardBorderColor }}
            />
            <SochialMediaTextInput
              type="text"
              bgImage={
                theme === "dark"
                  ? "/linkedin-icon.svg"
                  : "/darkLinkedin-icon.svg"
              }
              placeholder="LinkedIn"
              value={LikedIn}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLikedIn(e.target.value)
              }
              color={theme === "dark" ? "#fff" : "#364350"}
              FocusColor={FocusTextColor}
              style={{ borderColor: profileCardBorderColor }}
            />
          </ClumnDilpay>
        </FormRow>
      </ColumnWebLinks>
      <GoFlexRight>
        {loading ? (
          <MediumTypography
            text={t("User Profile.Please Wait, we're uploading your picture")}
          />
        ) : (
          <SubmitButtomEditProfile
            type="button"
            value={t("forms.Save")}
            widths="6.5rem"
            onClick={handelEditUser}
          />
        )}
      </GoFlexRight>
      {ShowDeletePopUp && (
        <PopupDelete
          show={ShowDeletePopUp}
          setShow={setShowDeletePopUp}
          title={`${t("User Profile.Delete Profile Picture")}?`}
          handleSubmit={handleRemovePic}
        />
      )}
    </FlexAppContainer>
  );
};

export default EditFormV2;
