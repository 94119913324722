interface ForumParticipationDetailsTableInterface {
  text:string;
} 

export  const ForumParticipationDetailsTableHeaderData : ForumParticipationDetailsTableInterface[] =[
  {text:"Post"},
  {text:"Replies"},
  {text:"Last Participation"},
  {text:"Apply Penalty"},
]

interface FPDetailsTableInterface {
  Graded?:boolean;
  Anonymous?:boolean;
  MaxGrade?:number;
  StartDate?: Date | null,
  CutOffDate?: Date | null,
  DueDate?: Date | null,
  Students:{
  StdCode:string;
  Name?:string;
  Pic?:string;
  Posts?: number;
  Replies?: number;
  Last_Participation?: Date | null,
  DueDate?: Date | null,
  CutOffDate?: Date | null,
  Apply_penalty?: boolean,
  Grade:number;}[]
};

export const FPDetailsTableData : FPDetailsTableInterface ={
  Graded:false,
  Anonymous:false,
  MaxGrade:15,
  CutOffDate:new Date("2023-10-30T10:30:00Z"),
  DueDate:new Date("2023-10-01T15:30:00Z"),
 Students:[
  {StdCode:"1szdsa1",
  Name:"Hadi Alhaj Ali",
  Pic:require("../../../../assets/AliAhmad.png"),
  // Posts:3,
  // Replies:3,
  Last_Participation:new Date("2023-10-02T10:30:00Z"),
  CutOffDate:new Date("2023-10-30T10:30:00Z"),
  DueDate:new Date("2023-10-10T10:30:00Z"),
  Apply_penalty:false,
  Grade:4,
},
{StdCode:"retgfvre",
Name:"Hadi Shouman",
Pic:require("../../../../assets/AliAhmad.png"),
Posts:3,
Replies:6,
Last_Participation:new Date("2023-11-08T10:30:00Z"),
DueDate:new Date("2023-09-05T10:30:00Z"),
CutOffDate:new Date("2023-10-30T10:30:00Z"),
Apply_penalty:true,
Grade:4
},
{StdCode:"sadsad",
Name:"Sultana Nasser",
Pic:require("../../../../assets/AyaInstructor.jpg"),
Posts:3,
// Replies:1,
Last_Participation:new Date("2023-10-15T10:30:00Z"),
DueDate:new Date("2023-10-05T10:30:00Z"),
Apply_penalty:false,
Grade:3,
},
{StdCode:"regvrfv",
Name:"Ali Mansour",
Pic:require("../../../../assets/AliAhmad.png"),
Posts:1,
// Replies:7,
Last_Participation:new Date("2023-10-05T10:30:00Z"),
DueDate:new Date("2023-08-05T10:30:00Z"),
Apply_penalty:true,
Grade:10
},
{StdCode:"sadasxdwef",
Name:"3xxx",
Pic:require("../../../../assets/AliAhmad.png"),
// Posts:6,
// Replies:10,
// Last_Participation:new Date("2023-10-02T10:30:00Z"),
DueDate:new Date("2023-10-10T10:30:00Z"),
Apply_penalty:false,
Grade:4
},
{StdCode:"wqdxsqwdqert54y6fr",
Name:"Hadi Shouman",
Pic:require("../../../../assets/AliAhmad.png"),
Posts:2,
Replies:7,
Last_Participation:new Date("2023-11-08T10:30:00Z"),
DueDate:new Date("2023-09-05T10:30:00Z"),

Apply_penalty:true,
Grade:4
},
{StdCode:"dwqdfghy5t43redw",
Name:"Sultana Nasser",
Pic:require("../../../../assets/AyaInstructor.jpg"),
// Posts:3,
// Replies:20,
Last_Participation:new Date("2023-10-15T10:30:00Z"),
DueDate:new Date("2023-10-05T10:30:00Z"),
Apply_penalty:true,
Grade:3
},
{StdCode:"4rwqrt5ytgedfsdf",
Name:"Ali Mansour",
Pic:require("../../../../assets/AliAhmad.png"),
Posts:3,
Replies:3,
Last_Participation:new Date(""),
DueDate:new Date("2023-08-05T10:30:00Z"),
Apply_penalty:true,
Grade:10
},

{StdCode:"qwrefvcADCFGHJHGF",
Name:"Hadi Alhaj Ali",
Pic:require("../../../../assets/AliAhmad.png"),
Posts:13,
Replies:20,
Last_Participation:new Date("2023-10-02T10:30:00Z"),
DueDate:new Date("2023-10-10T10:30:00Z"),
Apply_penalty:true,
Grade:4
},
{StdCode:"rtgercftuedwasds",
Name:"Hadi Shouman",
Pic:require("../../../../assets/AliAhmad.png"),
Posts:3,
Replies:5,
Last_Participation:new Date("2023-11-08T10:30:00Z"),
DueDate:new Date("2023-09-05T10:30:00Z"),
Apply_penalty:true,
Grade:4
},
{StdCode:"wrdcfgty654rdc",
Name:"Sultana Nasser",
Pic:require("../../../../assets/AyaInstructor.jpg"),
Posts:3,
Replies:5,
Last_Participation:new Date("2023-10-15T10:30:00Z"),
DueDate:new Date("2023-10-05T10:30:00Z"),
Apply_penalty:true,
Grade:3
},
{StdCode:"asdfgh34tfsa",
Name:"Ali Mansour",
Pic:require("../../../../assets/AliAhmad.png"),
Posts:13,
Replies:20,
Last_Participation:new Date("2023-10-05T10:30:00Z"),
DueDate:new Date("2023-08-05T10:30:00Z"),
Apply_penalty:true,
Grade:10
},
 ],
};