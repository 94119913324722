import React, { useState } from "react";
import { FlexDiv, RuleBook, RuleBookCont, RuleBookDate, SwitchButton } from "./Rulebook.style";
import { SvgBackArrow } from "../../elements/Icons";
import { LightTypography, MediumTypography, Typography } from "../../elements/fonts/Fonts";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as SvgStarIcon } from "../../assets/icons/MiniStar.svg";
import { RulebookData, StudentPointsData } from "./Rulebook.data";

const Rulebook = () => {
  const { classId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const sortedLeaderboardData = location.state.sortedLeaderboardData
  const initialActiveTab = location.state.activeTab || "rulebook"

  const [activeTab, setActiveTab] = useState(initialActiveTab);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <>
      <FlexDiv gap="0.63rem">
        <SvgBackArrow
          onClick={() => {
            navigate(`/leaderboard/${classId}`,{
                state: { sortedLeaderboardData },
              });
          }}
          style={{ cursor: "pointer" }}
        />
        <Typography text="Rulebook" fontSize="1.75rem" />
      </FlexDiv>
      <FlexDiv gap="0.62rem" style={{marginTop: "1.25rem", marginBottom: "2.19rem"}}>
      <SwitchButton
          onClick={() => handleTabClick("rulebook")}
          style={{ backgroundColor: activeTab === "rulebook" ? "#5DD3B3" : "", color: activeTab === "rulebook" ? "#fff" : "" }}
        >
          Rulebook
        </SwitchButton>
        <SwitchButton
          onClick={() => handleTabClick("myPoints")}
          style={{ backgroundColor: activeTab === "myPoints" ? "#5DD3B3" : "", color: activeTab === "myPoints" ? "#fff" : "" }}
        >
          My Points
        </SwitchButton>
      </FlexDiv>

        <RuleBookCont>
        {activeTab === "rulebook" ? (
            <>
            {RulebookData.map((rulebook: any, index: number) => (
                <RuleBook key={index}>
                <Typography text={rulebook.rule} fontSize="1rem" />
                <FlexDiv gap="0.63rem">
                    <MediumTypography text={rulebook.points.toString()} fontSize="0.875rem" />
                    <SvgStarIcon width="0.875rem" height="0.875rem" />
                </FlexDiv>
                </RuleBook>
            ))}
            </>
        ) : (
            <>
            {StudentPointsData.map((rulebook: any, index: number) => (
                <RuleBook key={index}>
                    <RuleBookDate>
                       <Typography text={rulebook.rule} fontSize="1rem" /> 
                       <LightTypography text={rulebook.date} fontSize="0.875rem" color="#FFFFFF80"/>
                    </RuleBookDate>
                
                <FlexDiv gap="0.63rem">
                    <MediumTypography text={rulebook.points.toString()} fontSize="0.875rem" />
                    <SvgStarIcon width="0.875rem" height="0.875rem" />
                </FlexDiv>
                </RuleBook>
            ))}
            </>
        )}
        </RuleBookCont>

    </>
  );
};

export default Rulebook;
