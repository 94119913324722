import React, { useEffect } from "react";
import { AnalyticsPiesInterface } from "../../../interfaces/AnalyticsCommonInterfaces";
import {
  AnalyticsSurveyBackLeft,
  AnalyticsSurveyPiesContainer,
} from "../../AnalyticsSurveyPies/main/AnalyticScrollabelPlace/AnalyticScrollabelPlace.style";
import AnalyticsBackPage from "../../AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticsBackPage";
import ScrollLeftAndRight from "../../../elements/ScrollLeftAndRight/ScrollLeftAndRight";
import { FlexAppContainer } from "../../../App.style";
import AnalyticQuizPiesScrollablePlace from "./AnalyticQuizPiesScrollablePlace/AnalyticQuizPiesScrollablePlace";
import AnalyticsQuizzesNumericalTwoChoice from "../AnalyticsQuizzesNumericalTwoChoice/AnalyticsQuizzesNumericalTwoChoice";
import AnalyticsQuizzesMultipleChoices from "../AnalyticsQuizzesMultipleChoices/AnalyticsQuizzesMultipleChoices";
import AnalyticQuizEssayPie from "../AnalyticQuizEssayPie/AnalyticQuizEssayPie";
import AnalyticsPieChartMultipleChoice from "../../AnalyticsSurveyPies/AnalyticsPieChartMultipleChoice/AnalyticsPieChartMultipleChoice";

const AnalyticQuizPies = ({
  ChosenQuestion,
  setChosenQuestion,
}: AnalyticsPiesInterface) => {
  // Retrieve QuestionType from ChosenQuestion
  const QuestionType = ChosenQuestion.QuestionType;
  return (
    <AnalyticsSurveyPiesContainer>
      <AnalyticsSurveyBackLeft>
        <AnalyticsBackPage
          text="Main table"
          onClick={() => setChosenQuestion(null)}
        />
      </AnalyticsSurveyBackLeft>
      <ScrollLeftAndRight
        content={
          <AnalyticQuizPiesScrollablePlace
            ChosenQuestion={ChosenQuestion}
            setChosenQuestion={setChosenQuestion}
          />
        }
        FixedBodyContainer={
          <div>
            {QuestionType === "Numerical" || QuestionType === "Short answer" ? (
              <AnalyticsQuizzesNumericalTwoChoice
                data={ChosenQuestion}
                FirstTextLabel="Correct"
                SecondTextLabel="Incorrect"
              />
            ) : QuestionType === "Multiple Choice" ||
              QuestionType === "Matching" ? (
              // <AnalyticsQuizzesMultipleChoices data={ChosenQuestion} />
              <AnalyticsPieChartMultipleChoice data={ChosenQuestion} />
            ) : QuestionType === "True or False" ? (
              <AnalyticsQuizzesNumericalTwoChoice
                data={ChosenQuestion}
                FirstTextLabel="True"
                SecondTextLabel="False"
              />
            ) : QuestionType === "Essay" ? (
              <AnalyticQuizEssayPie data={ChosenQuestion} />
            ) : null}
          </div>
        }
      />
    </AnalyticsSurveyPiesContainer>
  );
};

export default AnalyticQuizPies;
