import React, { useState, useEffect } from "react";
import {
  Typography,
  LightTypography,
  MediumTypography,
} from "../../elements/fonts/Fonts";
import {
  ColsWithPaddingText,
  Cols,
  TextTile,
  LongTextInputEmail,
  ColsWithoutPaddingText,
  GoFlexRight,
  GoFlexLeft,
  SubTile,
  ButtomFieldStyle,
  TextAsSizeDive,
  ErroTextDisplay,
  ErrorColsDiv,
  ErroTextSpaceDisplay,
  GroupRow,
  SmallIconStatus,
  // ,ErroTextSpaceDisplay,ErrorColsDiv,GroupRow,SmallIconStatus
} from "./UpdateEmailForm.style";
import { SubmitButtomEditProfile } from "../../elements/SubmitSaveButton/SubmitButtonStyle";
import { ButtonModificationWithoutBachougroud } from "../../elements/ButtonOnlyText/NobackGround.style";
import PopupDelete from "../../elements/PopupDelete/PopupDelete";
import { log } from "console";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { get } from "../../utils/AxiosRequests";
import { useRecoilValue } from "recoil";
import { ColorSelector, MainColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

export const UpdateEmailForm = (): JSX.Element => {
  const { t } = useTranslation();
  const { mainBackgroundColor, mainColor } = useRecoilValue(MainColorSelector);
  const {
    FocusTextColor,
    textInputColorA,
    profileCardBorderColor,
    themeTextColor,
  } = useRecoilValue(ColorSelector);
  const [Email, setEmail] = useState("");
  const [ZidyiaEmail, setZidyiaEmail] = useState("");
  const [ZidyiaEmailVerfied, setZidyiaEmailVerfied] = useState("");
  const [Code, setCode] = useState("");

  const [oldPassword, setoldPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [ConfirmeNew, setConfirmeNew] = useState("");

  const [FirstShow, setFirstShow] = useState(true);
  const [ShowtwoEmail, setShowtwoEmail] = useState(false);
  const [Wromgemail, setWromgemail] = useState(false);
  const [VerifyCode, setVerifyCode] = useState(false);
  const [ShowDeletUserPopUp, setShowDeletUserPopUp] = useState(false);

  const [ErrorEmail, setErrorEmail] = useState("");
  const [ErrorZidyiaEmail, setErrorZidyiaEmail] = useState("");
  const [erooroldPassword, seterooroldPassword] = useState("");
  const [eroorNewPassword, seteroorNewPassword] = useState("");
  const [eroorConfirmeNew, seteroorConfirmeNew] = useState("");
  const [accessToken, setAccessToken] = useState<any>("");
  const { user, getAccessTokenSilently } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const email = user?.email;
  const tenantName = localStorage.getItem("tenant");
  const AUTH0_DOMAIN = "https://dev-zidyia.eu.auth0.com";
  // const getNewToken = async () => {
  //   const newAccessToken = await getAccessTokenSilently();
  //   setAccessToken(newAccessToken)
  // }
  // getNewToken()

  // const useremail = "hadi@gmail.com";

  const [useremail, setuseremail] = useState("");
  const [password, setpassword] = useState("");

  const code = "1234";

  const ZidiyiaEmail = "hadi@zidyia.com";

  // useEffect(() => {
  //   // Read the user's profile data from localStorage
  //   const userProfileJSON = localStorage.getItem("userProfile");

  //   if (userProfileJSON) {
  //     const userProfile = JSON.parse(userProfileJSON);

  //     // Now you can access the user's profile data like this:
  //     const storedFirstName = userProfile.FirstName;
  //     const storedPassword = userProfile.Password;
  //     const LocalEmail = userProfile.Email;

  //     // ... and so on for other fields

  //     // Do something with the retrieved data, if needed
  //     setuseremail(LocalEmail);
  //     setpassword(storedPassword);
  //     console.log(useremail);
  //   }
  // }, []);

  const isValidEmail = (email: string) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };

  const handelDisplayTwoEmails = () => {
    if (Email === useremail) {
      setFirstShow(false);
      setShowtwoEmail(true);
      return;
    } else {
      setErrorEmail(t("User Profile.please enter your Email"));
    }
  };

  const handleChangeEmail = () => {
    // if (!isValidEmail(ZidyiaEmail) || !isValidEmail(Email)) {
    //   setWromgemail(true);
    // }
    if (Email === useremail && isValidEmail(ZidyiaEmail)) {
      setShowtwoEmail(false);
      setWromgemail(true);
      return;
    } else {
      setErrorZidyiaEmail(t("User Profile.Enter a valid email"));
    }
  };

  const verifiedEmailA = () => {
    if (ZidyiaEmailVerfied === ZidyiaEmail) {
      setWromgemail(false);
      setVerifyCode(true);
    }
  };

  const handeShowDlete = () => {
    setShowDeletUserPopUp(true);
  };

  const handleDeleteUser = () => {
    setShowDeletUserPopUp(false);
  };

  const verifiedCode = () => {
    if (ZidyiaEmailVerfied === ZidyiaEmail && Code === code) {
      setVerifyCode(false);
    }
  };

  const success = () =>
    toast.success(t("User Profile.Password has been changed"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const changePassword = async () => {
    // alert(password);
    // console.log(password);
    seterooroldPassword("");
    seteroorNewPassword("");
    seteroorConfirmeNew("");
    if (
      // oldPassword.trim() === "" ||
      NewPassword.trim() === "" &&
      ConfirmeNew.trim() === ""
    ) {
      seterooroldPassword("error");
      seteroorNewPassword("error");
      seteroorConfirmeNew(t("survey.All Fields are mandatory"));
      return;
    }
    if (
      // oldPassword.trim() === "" &&
      NewPassword.trim() === "" &&
      ConfirmeNew.trim() === ""
    ) {
      // seterooroldPassword("error");
      seteroorNewPassword("error");
      seteroorConfirmeNew(t("survey.All Fields are mandatory"));
      return;
    }

    // if (oldPassword !== password) {
    //   seterooroldPassword("error");

    //   return;
    // }
    if (NewPassword.length < 8) {
      seteroorNewPassword("error");
      seteroorConfirmeNew(
        t("User Profile.Password must be more than 8 characters")
      );
      return;
    }

    if (
      NewPassword !== ConfirmeNew ||
      NewPassword.trim() === "" ||
      ConfirmeNew.trim() === ""
    ) {
      seteroorNewPassword("error");
      seteroorConfirmeNew(t("User Profile.Both Passwords must be the same"));
      return;
    }

    // Get the access token from AUTH0
    const response = await get(
      `user/profile/update/password/${subId}/${tenantName}?provider=${provider}`
    );

    // Send the token in the headers and change the password
    const response2 = await axios.patch(
      `${AUTH0_DOMAIN}/api/v2/users/auth0|${subId}?provider=${provider}`,
      {
        password: ConfirmeNew,
        connection: "Username-Password-Authentication",
      },
      {
        headers: {
          Authorization: `Bearer ${response.data}`,
        },
      }
    );

    // If the status is 200 Show a toastify
    if (response2.status === 200) {
      success();
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Typography
        fontSize="1.75rem"
        fontWeight="400"
        text={t("User Profile.Account Settings")}
      />

      {ShowDeletUserPopUp && (
        <PopupDelete
          show={ShowDeletUserPopUp}
          setShow={setShowDeletUserPopUp}
          title={`${t("User Profile.Delete Profile Picture")}?`}
          handleSubmit={handleDeleteUser}
        />
      )}
      <ColsWithoutPaddingText>
        {/* 
        <ErroTextDisplay id='EmailVerifyWrongIcon'>
    <LightTypography text="Account Email" fontSize="0.875rem"  fontWeight="400"/>
    <SmallIconStatus src='./assets/icons/Frame.svg' />
     </ErroTextDisplay> */}

        {/* <ErroTextDisplay id='EmailVerifyWrongIcon'>
    <LightTypography text="Account Email" fontSize="0.875rem"  fontWeight="400"/>
    <SmallIconStatus src='./assets/icons/noun_verify_1738093.svg' />
     </ErroTextDisplay> */}

        {/* 111111111111111111111111111 */}
        {FirstShow && (
          <div
            style={{
              marginBottom: "2rem",
              gap: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ErroTextSpaceDisplay id="EmailVerifyWrongIcon">
              <GroupRow>
                <LightTypography
                  text={t("User Profile.Account Email")}
                  fontSize="0.875rem"
                  fontWeight="400"
                  color={themeTextColor}
                />
                {/* <SmallIconStatus src="./assets/icons/noun_verify_1738093.svg" /> */}
              </GroupRow>
              {/* <GroupRow>
                <ButtonModificationWithoutBachougroud
                  type="button"
                  value="change"
                  onClick={handelDisplayTwoEmails}
                />{" "}
              </GroupRow> */}
            </ErroTextSpaceDisplay>

            <div
              style={{
                gap: "0.5rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LongTextInputEmail
                type="text"
                placeholder="S.alrjhi@gmail.com"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
                disabled={true}
                style={{
                  borderColor: ErrorEmail ? "#D85D66" : profileCardBorderColor,
                }}
                color={textInputColorA}
                FocusColor={FocusTextColor}
              />
              {ErrorEmail ? (
                <LightTypography
                  color="#D85D66"
                  fontSize="0.70rem"
                  fontWeight="200"
                  text={ErrorEmail}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        )}
        {/* 111111111111111111111111111 */}

        {/* 222222222222222222222222222222222 */}
        {ShowtwoEmail && (
          <div
            style={{
              gap: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ErroTextDisplay id="normalText">
              <LightTypography
                text={t("User Profile.Account Email")}
                fontSize="0.875rem"
                fontWeight="400"
              />
            </ErroTextDisplay>

            <LongTextInputEmail
              type="text"
              placeholder="S.alrjhi@gmail.com"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              disabled={true}
              color={textInputColorA}
              FocusColor={FocusTextColor}
            />
            <div
              style={{
                gap: "0.5rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LongTextInputEmail
                id="secondEmail"
                type="text"
                placeholder="S.alrjhi@gmail.com"
                value={ZidyiaEmail}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setZidyiaEmail(e.target.value)
                }
                style={{
                  borderColor: ErrorZidyiaEmail ? "#D85D66" : textInputColorA,
                }}
                color={textInputColorA}
                FocusColor={FocusTextColor}
              />
              {ErrorZidyiaEmail && (
                <LightTypography
                  color="#D85D66"
                  fontSize="0.70rem"
                  fontWeight="200"
                  text={ErrorZidyiaEmail}
                />
              )}
            </div>
            <GoFlexRight>
              <SubmitButtomEditProfile
                type="button"
                widths="5.5rem"
                value={t("forms.Save")}
                onClick={handleChangeEmail}
              />
            </GoFlexRight>
          </div>
        )}
        {/* 222222222222222222222222222222222 */}

        {/* 333333333333333333333333333333333 */}
        {Wromgemail && (
          <div
            style={{
              marginBottom: "2rem",
              gap: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ErroTextSpaceDisplay id="EmailVerifyWrongIcon">
              <ErroTextDisplay id="EmailVerifyWrongIcon">
                <LightTypography
                  text={t("User Profile.Account Email")}
                  fontSize="0.875rem"
                  fontWeight="400"
                />
                <SmallIconStatus src="./assets/icons/Frame.svg" />
              </ErroTextDisplay>
              {/* <GroupRow>
    <LightTypography text="Account Email" fontSize="0.875rem"  fontWeight="400"/>
    <SmallIconStatus src='./assets/icons/Frame.svg' />
    </GroupRow> */}
              {/* <GroupRow><ButtonModificationWithoutBachougroud type="button" value="change" />   </GroupRow> */}
            </ErroTextSpaceDisplay>
            <LongTextInputEmail
              type="text"
              placeholder="S.alrjhi@gmail.com"
              value={ZidyiaEmailVerfied}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setZidyiaEmailVerfied(e.target.value)
              }
              color={textInputColorA}
              FocusColor={FocusTextColor}
            />
            <GoFlexRight>
              <SubmitButtomEditProfile
                type="button"
                widths="5.5rem"
                value={t("User Profile.Verify")}
                onClick={verifiedEmailA}
              />
            </GoFlexRight>
          </div>
        )}
        {/* 333333333333333333333333333333333 */}
      </ColsWithoutPaddingText>

      {/* 4444444444444444444444444444444444444444444 */}

      <ColsWithoutPaddingText>
        {/* <ErrorColsDiv>
<LightTypography fontSize='0.625rem' color='#FFFFFF80' text='This is the verified email that Zidyia uses as your account reference, changing it might result in side effects like credit card information loss.' />
</ErrorColsDiv>

        <ErrorColsDiv id='CompoRedDisplay'>
  <ErroTextDisplay><LightTypography fontSize='0.625rem' color='#D85D66' text='An Incorrect code has been entered. '  />
  <div style={{marginRight:"0.2rem"}} />
  <LightTypography fontSize='0.625rem' text='The code has been sent to your new email , enter the code in the field above to verify your email.' />
  </ErroTextDisplay>
<GoFlexRight>
<SubmitButtomEditProfile id='ResendButton' type="button" widths="5.5rem" value="Resend" backGround="none"   color="#5DD3B3"/>
<SubmitButtomEditProfile  type="button" widths="5.5rem" value="Verify"   style={{marginLeft:"1rem"}}   />
</GoFlexRight>
</ErrorColsDiv> */}

        {VerifyCode && (
          <>
            <LongTextInputEmail
              style={{ marginBottom: "0.5rem" }}
              type="text"
              placeholder="S.alrjhi@gmail.com"
              value={ZidyiaEmailVerfied}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setZidyiaEmailVerfied(e.target.value)
              }
              color={textInputColorA}
              FocusColor={FocusTextColor}
            />
            <LongTextInputEmail
              type="text"
              placeholder={t("User Profile.Enter Code Here")}
              value={Code}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCode(e.target.value)
              }
              color={textInputColorA}
              FocusColor={FocusTextColor}
            />
            <ErrorColsDiv id="CompoWhiteDisplay">
              <ErroTextDisplay>
                <LightTypography
                  fontSize="0.625rem"
                  color="#858582d9"
                  text="A code has been sent to your email , enter the code in the field above to verify your email."
                />
              </ErroTextDisplay>
              <GoFlexRight>
                <SubmitButtomEditProfile
                  id="ResendButton"
                  type="button"
                  widths="5.5rem"
                  value="Resend"
                  background="none"
                  color="#5DD3B3"
                />
                <SubmitButtomEditProfile
                  type="button"
                  widths="5.5rem"
                  value="Verify"
                  style={{ marginLeft: "1rem" }}
                />
              </GoFlexRight>
            </ErrorColsDiv>
          </>
        )}
      </ColsWithoutPaddingText>
      <ColsWithoutPaddingText>
        <LightTypography
          text={t("User Profile.Password")}
          fontSize="0.875rem"
          fontWeight="400"
          color={themeTextColor}
        />
      </ColsWithoutPaddingText>

      {/* <ColsWithPaddingText>
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
        >
          <LongTextInputEmail
            style={{ borderColor: erooroldPassword ? "red" : "" }}
            name="passss"
            type="password"
            placeholder="Enter Current Password"
            value={oldPassword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setoldPassword(e.target.value)
            }
          />
          {erooroldPassword && (
            <LightTypography
              text="Enter Your Password"
              color="red"
              fontSize="0.70rem"
              fontWeight="200"
            />
          )}
        </div>
      </ColsWithPaddingText> */}
      <ColsWithoutPaddingText>
        <LongTextInputEmail
          type="password"
          placeholder={t("User Profile.Enter New Password")}
          style={{
            borderColor: eroorNewPassword ? "#D85D66" : profileCardBorderColor,
          }}
          value={NewPassword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNewPassword(e.target.value)
          }
          color={textInputColorA}
          FocusColor={FocusTextColor}
        />{" "}
      </ColsWithoutPaddingText>
      <ColsWithoutPaddingText>
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
        >
          <LongTextInputEmail
            style={{
              borderColor: eroorConfirmeNew
                ? "#D85D66"
                : profileCardBorderColor,
            }}
            type="password"
            placeholder={t("User Profile.Re-enter New Password")}
            value={ConfirmeNew}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setConfirmeNew(e.target.value)
            }
            color={textInputColorA}
            FocusColor={FocusTextColor}
          />
          {eroorConfirmeNew && (
            <LightTypography
              text={eroorConfirmeNew}
              color="#D85D66"
              fontSize="0.70rem"
              fontWeight="200"
            />
          )}
        </div>
        <GoFlexRight>
          <SubmitButtomEditProfile
            type="button"
            widths="5.5rem"
            value={t("forms.Save")}
            color="#5DD3B3"
            border="#5DD3B3"
            background="none"
            onClick={changePassword}
          />
        </GoFlexRight>{" "}
      </ColsWithoutPaddingText>
      {/* <Cols>
        <GoFlexLeft>
          <LightTypography
            text="Account Status : "
            fontSize="0.875rem"
            fontWeight="400"
          />
          <MediumTypography
            text=" Active"
            color="#5DD3B3"
            fontSize="0.875rem"
            fontWeight="400"
          />
        </GoFlexLeft>

        <SubTile>
          <Typography fontSize="1rem" text="Disable Account :" />
        </SubTile>
        <GoFlexLeft>
          <TextAsSizeDive>
            <LightTypography
              text="Disabling your account is temporarily stopping it until further notice. A disabled account is an account that cannot follow any program , perform no payments and watch no courses. You will not lose any data. You can re-enable it later on.  "
              fontSize="0.875rem"
              fontWeight="400"
              lineHeight="1.375rem"
            />
          </TextAsSizeDive>
          <ButtomFieldStyle>
            <SubmitButtomEditProfile
              type="button"
              value="Disable"
              backGround="none"
              color="white"
              border="white"
            />
          </ButtomFieldStyle>
        </GoFlexLeft>

        <SubTile>
          <Typography fontSize="1rem" text="Delete Account :" />
        </SubTile>
        <GoFlexLeft>
          <TextAsSizeDive>
            <LightTypography
              text="Deleting your account will result in the loss of all your enrollements, programs, resources, courses and all your personal data. Your achieved programs and completed courses will remain archieved in our Database. "
              fontSize="0.875rem"
              fontWeight="400"
              lineHeight="1.375rem"
            />
          </TextAsSizeDive>
          <ButtomFieldStyle>
            <SubmitButtomEditProfile
              type="button"
              value="Delete"
              backGround="none"
              color="#D85D66"
              border="#D85D66"
              onClick={handeShowDlete}
            />
          </ButtomFieldStyle>
        </GoFlexLeft>
      </Cols> */}
    </div>
  );
};
