import React from "react";
import { EmptyPostContainer } from "./EmptyPost.style";
import { Typography } from "../../../elements/fonts/Fonts";
import { SvgEmptyPaper } from "../../../elements/Icons";

interface EmptyPostProps {
  text?: string;
}

const EmptyPost = ({ text }: EmptyPostProps) => {
  return (
    <EmptyPostContainer>
      <SvgEmptyPaper />
      <Typography text={text || ""} fontSize="1.375rem" />
    </EmptyPostContainer>
  );
};

export default EmptyPost;
