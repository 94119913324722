import styled from "styled-components";
import { StyleProps } from "../../../../App.style";

export const SurveyReusableTableCell = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 1.25rem;
  justify-content: center;
  &:first-child {
    justify-content: left;
    flex: 1.2;
    align-items: center;
    /* background-color:yellowgreen; */
  }

  &:last-child {
    flex: 1;
    justify-content: space-between;
    padding: 0 2rem;
    gap: 0.1rem;
    position: relative;
  }
`;

export const SurveyReusableTableSubCell = styled.div`
  display: flex;
  flex: 1;
  &:first-child {
  }
  flex: 1.4;
  &:last-child {
    flex: 0.7;
  }
`;

export const PointerContainer = styled.div<StyleProps>`
  display: flex;
  cursor: pointer;
  padding: ${({ padding }) => padding || "0.3rem 0.7rem"};
  color: ${({ color }) => color};
  position: absolute;
  &:dir(ltr) {
    right: 1.25rem;
  }
  &:dir(rtl) {
    left: 1.25rem;
  }
`;

export const ComplexSelectTableMessagePopup = styled.div`
  position: absolute;
  display: flex;
  padding: 1.05rem;
  border-radius: 0.3125rem;
  background: #1f2731;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  gap: 0.5rem;
  width: 12.5rem;
  height: 2.625rem;
  transition: background 0.3s ease;
  top: 1.2rem;
  right: 3rem;
  z-index: 1;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: var(--Main-Green-color, #5dd3b3);
  }
`;
