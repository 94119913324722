import React, { useState, useEffect } from "react";
import { GraphChartDataItem } from "../AnalyticsAssigmentGraphChart/AnalyticsAssigmentGraphChart";
import {
  FullMainRow,
  GraphTextAbsolute,
} from "../../../Views/Analytics/AnalyticsPage/AnalyticsPage.style";
import ChartsHeadTitle from "../../Charts/ChartsHeadTitle/ChartsHeadTitle";
import { GraphExcelDownload } from "../../../utils/ExcelGlobalFunction";
import BarChartsCompo from "../../Charts/BarCharts/BarCharts";
import { Typography } from "../../../elements/fonts/Fonts";

export interface BarChartData {
  id: number; // Change the type to number
  Name: string;
  val: number;
  avg: number;
}

interface AnalyticsAssignmentBarChartProps {
  Data?: any[];
  titleClick?: () => void;
  title?: string;
  teacherView?: boolean;
}

const AnalyticsAssignmentBarChart = ({
  Data,
  titleClick,
  title,
  teacherView = true,
}: AnalyticsAssignmentBarChartProps) => {
  const [GraphChartData, setGraphChartData] = useState<GraphChartDataItem[]>(
    []
  );

  useEffect(() => {
    if (Data && Data.length > 0) {
      const gradeMap = new Map<number, number>();
      let totalVal = 0; // Initialize total value counter

      Data.forEach((student) => {
        if (student.Grade !== undefined) {
          const existingCount = gradeMap.get(student.Grade) || 0;
          gradeMap.set(student.Grade, existingCount + 1);
          totalVal += student.Grade; // Accumulate values for calculating total
        }
      });

      const avg = totalVal / Data.length; // Calculate average

      const updatedGraphChartData = Array.from(gradeMap.entries()).map(
        ([val, count]) => ({
          val,
          name: count.toString(),
          avg: parseFloat(avg.toFixed(2)), // Parse the result to a number
        })
      );

      setGraphChartData(updatedGraphChartData);
    }
  }, [Data]);

  const mappedChartData: BarChartData[] = GraphChartData.map((item, index) => ({
    id: index + 1, // Convert index to number (add 1 to avoid id being 0)
    Name: item.name,
    val: item.val,
    avg: item.avg,
  }));

  return (
    <FullMainRow>
      <ChartsHeadTitle
        Title={title || "Graph"}
        handleDownload={() =>
          GraphExcelDownload(
            mappedChartData,
            `${title ? `${title} Bar Chart Download` : "Bar Chart Download"}`
          )
        }
        titleClick={titleClick}
      />
      <div style={{ height: "20rem" }}>
        {/* {teacherView ?  */}
        <BarChartsCompo
          dataKeyA="val"
          data={teacherView ? mappedChartData : Data || []}
          dataKeyText="Name"
          avg="avg"
        />
        {/* : */}
        {/* <BarChartsCompo
          dataKeyA="val"
          data={Data || []}
          dataKeyText="Name"
          avg="avg"
        />
} */}
      </div>
      {teacherView && (
        <GraphTextAbsolute>
          <Typography text={"Occurency"} fontSize="0.75rem" />
        </GraphTextAbsolute>
      )}
    </FullMainRow>
  );
};

export default AnalyticsAssignmentBarChart;
