import styled from "styled-components";

export const PollQuestionsContainer = styled.div`
  width: 100%;
  height: fit-content;
  border-radius: 0.625rem;
  background: linear-gradient(180deg, #415160 0%, #293440 100%);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  padding: 1.12rem 1.5rem 2.37rem 1.5rem;
`;
export const TextEditorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.31rem;
  gap: 0.94rem;
`;
export const Header = styled.div`
  display: flex;
  gap: 1.25rem;
`;
export const TextEditorDiv = styled.div`
  margin-top: -1rem;
`;
export const ChoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.63rem;
`;
export const ChoicesHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ChoicesInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.62rem;
`;
export const InputCont = styled.div`
  display: flex;
  gap: 0.62rem;
  align-items: center;
`;
export const ButtonsCont = styled.div`
  display: flex;
  gap: 0.62rem;
  align-items: center;
  justify-content: center;
  margin-top: 1.38rem;
`;
export const Input = styled.input`
  width: 100%;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  background-color: transparent;
  padding: 0.87rem 1.25rem;
  color: #fff;
  font-family: "Primary font-light";
  &&:focus {
    outline: none;
  }

  &&::placeholder {
    color: #fff;
    opacity: 0.5;
  }
`;

export const FlexDiv = styled.div<{ gap?: string }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : "1.25rem")};
  user-select: none;
`;
