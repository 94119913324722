import LandingPageStatic from "../../components/landingPageStatic/LandingPageStatic";
import SignInForm from "../../components/SignInForm/SignInForm";
import BlueButton from "../../elements/blueButton/BlueButton";
import TextField from "../../elements/textField/TextField";
import { BackToLoginLink, InstructionTextRow } from "./ForgotPassword.style";
import { TypographyRegularDescription, Typography } from "../../elements/fonts/Fonts"
import { Link } from "react-router-dom";


const ForgotPassword = () => {
    return (
        <LandingPageStatic>
            <InstructionTextRow>
                <Typography
                    text="Forgot Password"
                    fontSize="1.25rem"
                    lineHeight="normal"
                    fontWeight="400"

                />
                <TypographyRegularDescription
                    text="Enter your email address and we will send a link reset your password."
                    fontSize="0.875rem"
                    lineHeight="1.375rem"
                    fontWeight="400"
                />

            </InstructionTextRow>
            <SignInForm>

                <TextField text="Email" />
                <BlueButton text="Send reset link" />
                <BackToLoginLink>
                    <Link to={"/login"}>
                    <TypographyRegularDescription
                        text="Back to login"
                        fontSize="0.75rem"
                        lineHeight="normal"
                        fontWeight="400"
                    />
                    </Link>
                   

                </BackToLoginLink>
            </SignInForm>

        </LandingPageStatic>

    )
}

export default ForgotPassword