import { Question } from "./generateSurveyDataInterface";

// This function is creating the question when it's droped according to the type of Question
export const handleDropQuestions = (questions:any[],index:number,partIndex:number,data:any) => {
    const updatedQuestions = [...questions];
    if (
      updatedQuestions[index] &&
      updatedQuestions[index]?.questType === "normal"
    ) {
      updatedQuestions[index].type = data?.name;
      updatedQuestions[index].icon = data?.icon;
      updatedQuestions[index].index = index + 1;
      updatedQuestions[index].title = `Question ${index + 1}`;
      if (data?.name === "SCALE") {
        updatedQuestions[index].choices = [{ name: "",branch:{}},];
      } else if (data?.name === "ESSAY") {
        updatedQuestions[index].choices = [{ name: "", }];
      } else if (data?.name === "BINARY") {
        updatedQuestions[index].choices = [{ isChecked:false ,branch:{} }];
      }else if (data?.name === "RATE") {
        updatedQuestions[index].choices = [{ value: 0 ,branch:{}}];
      }  else if (data?.name === "MCQ") {
        updatedQuestions[index].choices = [
          { name: "", branch:{} },
          { name: "", branch:{} },
        ];
      }
    } else if (
      updatedQuestions[index] &&
      updatedQuestions[index]?.questType === "composed"
    ) {
      updatedQuestions[index].questPart[partIndex].type = data.name;
      updatedQuestions[index].questPart[partIndex].icon = data.icon;
      updatedQuestions[index].index =index + 1;
      updatedQuestions[index].questPart[partIndex].index =partIndex + 1;
      updatedQuestions[index].title = `Question ${index + 1}`;
      updatedQuestions[index].questPart[partIndex].title = `Part ${String.fromCharCode(65 + partIndex)}`
      if (data?.name === "SCALE") {
        updatedQuestions[index].questPart[partIndex].choices = [
          { name: "",branch:{}}
        ];
      } else if (data?.name === "ESSAY") {
        updatedQuestions[index].questPart[partIndex].choices = [
          { name: "", },
        ];
      }else if (data?.name === "BINARY") {
        updatedQuestions[index].questPart[partIndex].choices = [
          { isChecked:false ,branch:{} }
        ];
      }else if (data?.name === "RATE") {
        updatedQuestions[index].questPart[partIndex].choices = [
          { value: 0 ,branch:{}}
        ];
      }else if (data?.name === "MCQ") {
        updatedQuestions[index].questPart[partIndex].choices = [
          { name: "",branch:{}},
          { name: "",branch:{}}
        ];
      }
    }
    return updatedQuestions;
}
// End of function

// Return The Count Of Each Type Of Question
export const setQuestionTypesCount =(questions:Question[]) => {
        let newQuestions = [...questions];
        const totalQuestions = newQuestions.length;
    
        const multipleChoiceCount = newQuestions.filter((q) => {
          if (q.type === "MCQ") {
            return true;
          } else if (q.questPart && q.questPart.length > 0) {
            return q.questPart.some((part) => part.type === "MCQ");
          }
          return false;
        }).length;
       
        const ratingCount = newQuestions.filter((q) => {
          if (q.type === "RATE") {
            return true;
          } else if (q.questPart && q.questPart.length > 0) {
            return q.questPart.some((part) => part.type === "RATE");
          }
          return false;
        }).length;
        const essayCount = newQuestions.filter((q) => {
          if (q.type === "ESSAY") {
            return true;
          } else if (q.questPart && q.questPart.length > 0) {
            return q.questPart.some((part) => part.type === "ESSAY");
          }
          return false;
        }).length;
        const scaleCount = newQuestions.filter((q) => {
          if (q.type === "SCALE") {
            return true;
          } else if (q.questPart && q.questPart.length > 0) {
            return q.questPart.some((part) => part.type === "SCALE");
          }
          return false;
        }).length;
        const yesNoCount = newQuestions.filter((q) => {
          if (q.type === "BINARY") {
            return true;
          } else if (q.questPart && q.questPart.length > 0) {
            return q.questPart.some((part) => part.type === "BINARY");
          }
          return false;
        }).length;
        return {
            totalQuestions,
            multipleChoiceCount,
            essayCount,
            yesNoCount,
            ratingCount,
            scaleCount
        }
}
// End Of Function

// This Function is formating The data before Sanding it to the Backend
export const formatingBeforeSaving = (question:any) => {
        let updatedQuestion = { ...question }; 
      
        // const checkedChoices = updatedQuestion.choices ? updatedQuestion.choices.filter((c:any) => c.isChecked || c.correct) : [];
        const choicesArray = updatedQuestion.choices ? updatedQuestion.choices : [];
      
        if (updatedQuestion.questType === "normal") {
          if (updatedQuestion.type === "MCQ" && choicesArray.length > 2) {
            updatedQuestion.type = "MRQ"; 
            // Format choices for MRQ
          updatedQuestion.choices = updatedQuestion.choices.map((choice:any, index:number) => ({
            index: index + 1,
            choice: choice.name,
            // percentage: choice.percentage,
          }));
          }else if (updatedQuestion.type === "MRQ" && choicesArray.length <= 2){
            updatedQuestion.type = "MCQ"; 
            // Format choices for MRQ
          updatedQuestion.choices = updatedQuestion.choices.map((choice:any, index:number) => ({
            index: index + 1,
            choice: choice.name,
            // percentage: choice.percentage,
          }));
          }else if(updatedQuestion.type === "MCQ" && choicesArray.length <= 2){
            updatedQuestion.choices = updatedQuestion.choices.map((choice:any, index:number) => ({
              index: index + 1,
              choice: choice.name,
              // percentage: choice.percentage,
            }));
          }else if(updatedQuestion.type === "MRQ" && choicesArray.length > 2){
            updatedQuestion.choices = updatedQuestion.choices.map((choice:any, index:number) => ({
              index: index + 1,
              choice: choice.name,
              // percentage: choice.percentage,
            }));
          }else if(updatedQuestion.type === "RATE" ){
            updatedQuestion.allowHalfStar = false;
          }else if(updatedQuestion.type === "SCALE" ){
            updatedQuestion.choices = [
              {index:1,choice:"Strongly Disagree"},
              {index:2,choice:"Disagree"},
              {index:3,choice:"Neutral"},
              {index:4,choice:"Agree"},
              {index:5,choice:"Strongly Agree"},
            ];
          }else if(updatedQuestion.type === "BINARY" ){
            updatedQuestion.choice1 = "Yes";
            updatedQuestion.choice2 = "No";
          }
          
          
        } else if (updatedQuestion.questType === "composed") {
          // If it's a composed question
          updatedQuestion.questPart.forEach((part:any) => {
              const partChoicesArray = part.choices ? part.choices : [];
              if (part.type === "MCQ" && partChoicesArray.length > 2) {
                part.type = "MRQ"; 
                // Format choices for MRQ
                part.choices = part.choices.map((choice:any, index:number) => ({
                index: index + 1,
                choice: choice.name,
                // percentage: choice.percentage,
              }));
              }else if (part.type === "MRQ" && partChoicesArray.length <= 2){
                part.type = "MCQ"; 
                // Format choices for MRQ
                part.choices = part.choices.map((choice:any, index:number) => ({
                index: index + 1,
                choice: choice.name,
                // percentage: choice.percentage,
              }));
              }else if(part.type === "MCQ" && partChoicesArray.length <= 2){
                part.choices = part.choices.map((choice:any, index:number) => ({
                  index: index + 1,
                  choice: choice.name,
                  // percentage: choice.percentage,
                }));
              }else if(part.type === "MRQ" && partChoicesArray.length > 2){
                part.choices = part.choices.map((choice:any, index:number) => ({
                  index: index + 1,
                  choice: choice.name,
                  // percentage: choice.percentage,
                }));
              }
              
          });
        }
      
        return updatedQuestion;
      
}
// End of Function

// This Function is Filtering The Data Before Sanding it To the Backend
export const filteringQuestion = (questions:Question[]) => {
    let updatedQuestions = [...questions];
  
    updatedQuestions.forEach((question, index) => {
      question.index = index + 1;
      question.title = `Question ${index + 1}`;
  
      if (question.questType === "composed") {
        question.questPart.forEach((part, partIndex) => {
          part.index = partIndex + 1;
          part.title = `Part ${String.fromCharCode(65 + partIndex)}`;
        });
      }

      updatedQuestions[index] = formatingBeforeSaving(question);

    });
    const filteredQuestions = updatedQuestions.map(({ icon, questPart, ...rest }) => {
      const updatedQuestPart = questPart.map(({ icon: questIcon, ...part }) => part);
      return {
        ...rest,
        questPart: updatedQuestPart,
        choices: rest.choices ? [...rest.choices] : [],
      };
    }).filter(question => question.type !== "" && question.type !== "composed");

    return filteredQuestions

}


// const FillBranch = (data: any, cIndex: number) => {
//   if(quest?.type === "RATE" || quest?.type === "SCALE" || quest?.type === "BINARY"){
//     addChoice()
//   }
//   if (choice) {
//     const selectedChoice = choice[cIndex]?.branch;
//     console.log("Selected Choice : ", selectedChoice)
    
//     if (selectedChoice) {
//       selectedChoice.name = ''; // Initially empty as per your requirement
//       selectedChoice.type = data?.name || ''; // Assigning the 'type' based on dropped data's name

//       // Fill choices array based on data's name
//       if (data?.name === 'MCQ') {
//         selectedChoice.choices = [{ name: '' }, { name: '' }];
//       }
//     }
//   }
// };
