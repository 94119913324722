import styled from "styled-components";
interface Styled{
  Border?:string;
  BKCOLOR?:string;
}

export const RadioAfterSubmissionLayout  =styled.div`
display:flex;
flex-direction:column;
gap:1.25rem;
`;

export const RadioAfterSubmissionContainer  =styled.div`
display:flex;
flex-direction:column;
/* gap:1.25rem;
padding: 1.25rem; */
height:fit-content;
`;
export const RadioAfterSubmissionBigRow  =styled.div`
display:flex;
justify-content:space-between;
height:fit-content;
`;
export const RadioAfterSubmissionRow  =styled.div`
display:flex;
width:100%;
/* flex-direction:column; */
gap:1.60rem;
`;

export const RadioAfterSubmissionCell  =styled.div`
display:flex;
gap:0.60rem;
align-items:center;
`;

export const RadioAfterSubmissionCircle  =styled.div<Styled>`
display:flex;
gap:0.60rem;
width: 1.25rem;
height: 1.25rem;
border:1.5px solid ${props => props.Border || "#fff"};
border-radius:50%;
background:${props => props.BKCOLOR || "#343E49"};
`;

export const RadioAfterSubmissionAction  =styled.div`
display:flex;
gap:0.70rem;
align-items:center;
background-color:yellow
`;