import React, { ReactNode } from 'react';
import { FormStyled } from "../SignInForm/SignInForm.style";

interface SignInFormInterface {
  children: ReactNode;
}
const SignInForm = ({ children }: SignInFormInterface): JSX.Element => {
  return (
  
  
    <FormStyled>
      {children}
      
    </FormStyled>
  

  )
}

export default SignInForm