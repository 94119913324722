import React, { useState, useEffect, useRef } from "react";
import Dropdown from "./DropDown";
import { Arrow, MenuItemWrapper  } from "./Menu.style";
import { SvgArrowShock, SvgArrowRight } from "../../elements/Icons"
interface MenuItemProps {
  items: {
    title: string;
    submenu?: any[]; // You should replace 'any' with the appropriate type for your submenu items
  };
  onClick:any
  depthLevel: number;
  columnIndex: any;
  handleSorting: (columnIndex: any) => void;
}

const MenuItems: React.FC<MenuItemProps> = ({ items, depthLevel, handleSorting, columnIndex, onClick  }) => {
  const [dropdown, setDropdown] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState('ascending'); // Default to ascending

  let ref = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    const mouseDownHandler = (event: MouseEvent) => {
      if (dropdown && ref.current && !ref.current.contains(event.target as Node)) {
        setDropdown(false);
      }
    };

    const touchStartHandler = (event: TouchEvent) => {
      if (dropdown && ref.current && !ref.current.contains(event.target as Node)) {
        setDropdown(false);
      }
    };

    document.addEventListener("mousedown", mouseDownHandler);
    document.addEventListener("touchstart", touchStartHandler);

    return () => {
      // Cleanup the event listeners
      document.removeEventListener("mousedown", mouseDownHandler);
      document.removeEventListener("touchstart", touchStartHandler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    if (window.innerWidth > 960) {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth > 960) {
      setDropdown(false);
    }
  };

  const handleButtonClick = () => {
    if (!items.submenu) {
      // If there is no submenu, close the menu and trigger sorting
      setDropdown(false);
  
      // Toggle the sorting order between ascending and descending
      // const newSortOrder = sortOrder === 'ascending' ? 'descending' : 'ascending';
      // setSortOrder(newSortOrder);
  
      // Trigger your function here using newSortOrder as the sorting criteria
      // handleSorting(columnIndex);
    } else {
      // If there is a submenu, toggle the submenu visibility
      setDropdown((prev) => !prev);
    }
  };
  

  

  return (
    <MenuItemWrapper 
    depthLevel={depthLevel}
    ref={ref}
    // onMouseEnter={onMouseEnter}
    // onMouseLeave={onMouseLeave}
  >
    {items.submenu ? (
      <>
        <button
        style={{ display:'flex', justifyContent:'space-between', alignItems:'center' }}
          type="button"
          aria-haspopup="menu"
          aria-expanded={dropdown ? "true" : "false"}
        
          onClick={() => {
            handleButtonClick()
            // if (depthLevel === 3) {
              handleSorting(items.title);
            // }
          }}
        >
          {items.title}{" "}
          {depthLevel >= 0 ? <Arrow> <SvgArrowShock/> </Arrow>: <span  />}
        </button>
        <Dropdown
          depthLevel={depthLevel}
          submenus={items.submenu}
          dropdown={dropdown}
    hasBorder={depthLevel >= 0}
    handleSorting={handleSorting}
        />
      </>
    ) : (
      <button  onClick={() => handleSorting(items.title)}>
             {/* {depthLevel === 3 && dropdown ? <SvgArrowRight /> : null} */}
        
        {items.title}</button>
    )}
  </MenuItemWrapper >
  );
};

export default MenuItems;
