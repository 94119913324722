import React, { FC } from "react";
import {
  ShortAnswerInputs,
  ShortAnswerContainer,
} from "./PreviewShortAnswer.style";
import { PreviewShortAnswerProps } from "./PreviewShortAnswerInterface";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { t } from "i18next";

const PreviewShortAnswer: FC<PreviewShortAnswerProps> = ({ choice }) => {
  const { inputBorderColor, themeTextColor, theme } =
    useRecoilValue(ColorSelector);
  return (
    <ShortAnswerContainer>
      <ShortAnswerInputs
        type="text"
        themetextcolor={themeTextColor}
        placeholder={t('quiz.write your answer')}
        id="name"
      />
    </ShortAnswerContainer>
  );
};
export default PreviewShortAnswer;
