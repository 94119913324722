import { ReusableProfileInterface } from "./reusableProfileInterface";
import { FC } from "react";

import {
  ProfileImageHolder,
  BgProfile,
  MaskGroup,
} from "./ReusableProfile.style";
import { SvgBgProfiledIcon2 } from "../Icons";
import { useRecoilValue } from "recoil";
import { ChatsColorSelector } from "../../recoil/ThemeSelectors";

const ReusableProfile: FC<ReusableProfileInterface> = ({
  iconPath,
  iconBg: IconComponent = SvgBgProfiledIcon2,
  top,
  left,
  border,
  width,
  height,
  widthImgHolder,
  heightImgHolder,
  ImageBox_BgColor,
  ImgBorderColor,
}) => {
  const { FriendMessageBoxBG_Color } = useRecoilValue(ChatsColorSelector);
  return (
    <ProfileImageHolder
      widthimgholder={widthImgHolder || "2.875rem"}
      heightimgholder={heightImgHolder || "2.875rem"}
      profilebackcolor={ImageBox_BgColor ? ImageBox_BgColor : "#707070"}
    >
      {/* hadi edit here please don't uncomment  */}
      {/* <BgProfile
        ProfileBorderColor={
          ImageBox_BgColor ? ImageBox_BgColor : FriendMessageBoxBG_Color
        }
      >
        <IconComponent />
      </BgProfile> */}
      <MaskGroup
        src={iconPath}
        width={width || "2.625rem"}
        height={height || "2.625rem"}
        top={top || "0.12rem"}
        left={left || "0.13rem"}
        border={border || "none"}
        ImgBorderColor={ImgBorderColor}
      />
    </ProfileImageHolder>
  );
};

export default ReusableProfile;
