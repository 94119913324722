import React, { FC, useEffect, useState } from "react";

import { ReactComponent as AddIcon } from "../../../assets/icons/Add (2).svg";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/Refresh.svg";
import {
  Typography,
  MediumTypography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import { QuestionCRUDTextEditorProps } from "./QuestionCRUDTextEditorInterface";
import {
  QuellStyle,
  StyledQuillEditor,
  QuestionIconsContainer,
  Icons,
  QuestionHeaderContainer,
  PointsInput,
  FeedBackContainer,
  Points,
  Tooltip,
  IconWrapper,
} from "./QuestionCRUDTextEditor.style";
import { ErrorDiv } from "../../../elements/ErrorDiv/Errordiv.style";
import "react-quill/dist/quill.snow.css";
import TinyEditor from "../../../elements/tinyEditor/TinyEditor";
import { useTranslation } from "react-i18next";
import { mapErrorType } from "../../../utils/QuizErrorTypes";
import { SvgEdit, SvgEye } from "../../../elements/Icons";

import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useRecoilValue } from "recoil";
import { toArabicDigits } from "../../../utils/formatDayArabic";
const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "clean"],
    [{ header: [] }],
    [{ list: "ordered" }, { list: "bullet" }],

    [{ align: [] }],
    ["link", "code", "image"],
  ],
};

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "clean",
  "header",
  "header",
  "list",

  "align",
  "link",
  "code",
  "image",
];
const QuestionCRUDTextEditor: FC<QuestionCRUDTextEditorProps> = ({
  questionError,
  typeError,
  handleQuestion,
  handleQuillChange,
  addChoice,
  quest,
  showQuest,
  handleShowEditQuest,
  setShowPreviewPopup,
  handleShuffle,
  handleGrade,
  index
  // isShuffled
}) => {
  const quillValue = `<p>${quest?.question}</p>`;
  const [isShuffled, setIsShuffled] = useState<any>(false);
  const { t } = useTranslation();


  // Ensure that isShuffled state is properly initialized and updated
  useEffect(() => {
    setIsShuffled(quest?.shuffle);
  }, [quest?.shuffle]);

  const errorType = mapErrorType(questionError || "", t);
  const { bg_selectColor, default_boxShadow, SecondaryTextColor, bghelp, inputBorderColor } =
    useRecoilValue(ColorSelector);
  return (
    <>
      <QuestionHeaderContainer>
        <QuestionIconsContainer>
          <MediumTypography
            text={t("quiz.Question") + ' ' + (toArabicDigits((index + 1).toString()))}
            // color={theme.dark.primary_text_color}
          />
          {showQuest ? (
            <Icons>
              <IconWrapper>
                <SvgEdit onClick={handleShowEditQuest} />
                <Tooltip bgcolor={bg_selectColor}>
                  {t("dashboard.Edit")}
                </Tooltip>
              </IconWrapper>
              <IconWrapper>
                <SvgEye onClick={() => setShowPreviewPopup(true)} />
                <Tooltip bgcolor={bg_selectColor}>
                  {t("assignment.preview")}
                </Tooltip>
              </IconWrapper>
            </Icons>
          ) : (
            ""
          )}
        </QuestionIconsContainer>
        {showQuest ? (
          <Points>
            <TypographyRegularDescription
              text={`${toArabicDigits(quest?.grade)} ${t("course view.pts")}`}
              fontSize="0.875rem"
              color={theme.dark.primary_text_color}
            />
          </Points>
        ) : (
          <PointsInput
            value={quest?.grade}
            id="grade"
            type="number"
            placeholder={t("course view.Points")}
            onChange={(e) => handleGrade && handleGrade(e)}
            hasError={!!questionError && !quest?.grade}
            color={SecondaryTextColor}
            borderColor={inputBorderColor}
          />
        )}
      </QuestionHeaderContainer>

      <QuellStyle>
        {showQuest ? (
          <TinyEditor
            hasError={!!questionError && !quest?.question}
            initialValue={quest?.question}
            onChange={handleQuillChange}
            placeholder={t("quiz.type your question")}
            readonly
            height="13.25rem"
          />
        ) : (
          <TinyEditor
            hasError={!!questionError && !quest?.question}
            initialValue={quest?.question}
            onChange={handleQuillChange}
            placeholder={t("quiz.type your question")}
            height="13.25rem"
          />
        )}
                    {questionError && (
              <ErrorDiv style={{ marginTop: "0.62rem" }}>{errorType}</ErrorDiv>
            )}
      </QuellStyle>

      <FeedBackContainer>
        {quest?.type === "SHORT_ANSWER" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <MediumTypography
              text={t("quiz.Keyword")}
              fontSize="0.875rem"
              color={theme.dark.primary_text_color}
              lineHeight="1.375rem"
            />
            {/* {questionError && (
              <ErrorDiv style={{ marginLeft: "3.37rem" }}>{errorType}</ErrorDiv>
            )} */}
          </div>
        ) : quest?.type === "NUMERIC" ||
          quest?.type === "ESSAY" ||
          quest?.type === "TF" ? (
          ""
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <MediumTypography
              text={`${t("quiz.Choices")}*`}
              fontSize="0.875rem"
              // color={theme.dark.primary_text_color}
              lineHeight="1.375rem"
            />

          </div>
        )}
        {quest?.type === "NUMERIC" ||
        quest?.type === "ESSAY" ||
        // quest?.type === "MATCH" ||
        quest?.type === "TF" ? (
          ""
        ) : (
          <Icons>
            {quest?.type === "SHORT_ANSWER" ? (
              <Icons
                onClick={() => {
                  if (!showQuest) {
                    addChoice();
                  }
                }}
              >
                <AddIcon />
                <Typography
                  text={t("quiz.Add Keywords")}
                  fontSize="0.875rem"
                  color={theme.dark.main_color}
                  lineHeight="1.375rem"
                />
              </Icons>
            ) : quest?.type === "MATCH" ?
            <Icons
                onClick={() => {
                  if (!showQuest) {
                    addChoice();
                  }
                }}
              >
                <AddIcon />
                <Typography
                  text={t("quiz.Add Choices")}
                  fontSize="0.875rem"
                  color={theme.dark.main_color}
                  lineHeight="1.375rem"
                />
              </Icons>
             : (
              <>
                <IconWrapper>
                  <RefreshIcon
                    color={isShuffled ? "#5DD3B3" : ""}
                    onClick={() => {
                      const updatedShuffle = !isShuffled;
                      setIsShuffled(updatedShuffle);
                      handleShuffle && handleShuffle(updatedShuffle);
                    }}
                  />
                  <Tooltip
                    bgcolor={bg_selectColor}
                    boxshadow={default_boxShadow}
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("quiz.shuffle")}
                  </Tooltip>
                </IconWrapper>
                <Icons
                  onClick={() => {
                    if (!showQuest) {
                      addChoice();
                    }
                  }}
                >
                  <AddIcon />
                  <Typography
                    text={t("quiz.Add Choices")}
                    fontSize="0.875rem"
                    color={theme.dark.main_color}
                    lineHeight="1.375rem"
                  />
                </Icons>
              </>
            )}
          </Icons>
        )}
      </FeedBackContainer>
    </>
  );
};

export default QuestionCRUDTextEditor;
