import { styled } from "styled-components";
import { Search } from "./SearchWithFilterInterface";

export const SearchBarContainer = styled.div<Search>`
  position: relative;
  display: flex;
  align-items: center;
  width: ${(props) => props.width || "100%"};
`;

export const SearchInput = styled.input<Search>`
  flex: 1;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "3.125rem"};
  border-radius: 6.25rem;
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.borderColor};
  background-color: transparent;
  padding: 0 2.82rem;
  font-size: 0.875rem;
  font-family: ${(props) => props.theme.font.primaryRegular};
  font-style: normal;
  font-weight: 400;
  color: ${(props) => props.color};

  &:focus {
    outline: none;
    caret-color: ${(props) => props.color};
  }

  &::placeholder {
    color: ${(props) => props.color};
    opacity: 0.4;
  }
`;

export const Icon = styled.div`
  position: absolute;
  font-size: 20px;
  cursor: pointer;
`;

export const LeftIcon = styled(Icon)`
  &:dir(ltr) {
    left: 1.25rem;
  }
  &:dir(rtl) {
    right: 1.25rem;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RightIcon = styled(Icon)`
  &:dir(ltr) {
    right: 1.25rem;
  }
  &:dir(rtl) {
    left: 1.25rem;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;
