import React from "react";
import { FlexAppContainer } from "../../App.style";
import { SvgLandingZidyiaLogo } from "../../elements/Icons";

const LandingPageHeader = () => {
  return (
    <FlexAppContainer justifyContent="space-between">
      <SvgLandingZidyiaLogo />
    </FlexAppContainer>
  );
};

export default LandingPageHeader;
