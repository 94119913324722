import styled from "styled-components";
import { breakpoints } from "../../theme";

interface GroupCardRectangleProps {
  bgcolor: string;
  index: number;
  membersnumber: number;
  color?: string;
}

export const GroupCardRectangle = styled.div<GroupCardRectangleProps>`
  position: relative;
  width: 100%;
  max-width: 22.875rem;
  height: 3.125rem;
  background-color: ${({ bgcolor }) => bgcolor};
  border-radius: 0.25rem;
  padding: 0.56rem 0.63rem 0.56rem 0.62rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.color};
`;

export const GroupTitleParticipantsHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  /* background: cyan; */
  height: 100%;
`;

export const ProfilesStudentsHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
`;
