import styled from "styled-components";
import { breakpoints } from "../../../theme";

export const BannerWrapper = styled.div`
  display: flex;
  /* max-width: "100%"; */
`;
interface InformationProps {
  boxshadow?: string;
}
export const Information = styled.div<InformationProps>`
  /* background-color: #384450; */
  box-shadow: ${(props) => props.boxshadow};
  border-radius: 1.56rem;
  width: 100%;
  height: 5.5rem;
  opacity: 1;
  padding: 0 3rem 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: calc(${breakpoints.large} )) {
    padding: 0 1rem 0 1rem;
  }
  @media (max-width: calc(${breakpoints.tablets} + 25px )) {
    padding: 0 1rem 0 1rem;
    padding-top: 1rem;
    height: 10rem;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    row-gap: 1rem;
    /* flex-wrap: wrap !important; */
  }
  @media (max-width: calc(${breakpoints.mobile} )) {
    max-width: 100%;
    /* flex-wrap: wrap !important; */
  }
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 30%;
  @media (max-width: calc(609px)) {
    width: 100%;
  }
`;

export const DoingGreat = styled.div`
  /* color: #ffffff; */
`;

export const Div = styled.div`
  display: flex;
  width: 100%;
  column-gap: 0.31rem;
  font-family: "Primary font-Medium";
  font-size: 1.125rem;
`;

export const GpaPosition = styled.div`
  /* @media (max-width: calc(${breakpoints.tablets} + 82px)) {
    display: none;
  } */
  @media (max-width: 632px) {
    display: block;
  }
`;
