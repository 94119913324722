export const PostData = {
  id: "1",
  learnerName: "Learner Name",
  learnerProfile: require("../../assets/AliAhmad.png"),
  postData: [
    {
      id: 1,
      createdAt: "5/20/2023",
      updatedAt: "1/12/2024",
      courseName: "Marketing",
      courseImage: require("../../assets/toy_car_engineering_hires2.png"),
      postDescription:
        "Happy to share that I have successfully completed Marketing. Please check out this!",
      views: 20,
      responses: 5,
    },
    {
      id: 2,
      createdAt: "1/12/2024",
      updatedAt: "1/10/2024",
      courseName: "Intro To React",
      courseImage: require("../../assets/marketing-courses.png"),
      postDescription:
        "Happy to share that I have successfully completed Intro To React. Please check out this!",
      views: 50,
      responses: 10,
    },
    {
      id: 3,
      createdAt: "12/15/2023",
      updatedAt: "1/15/2024",
      courseName: "Gestion d'inforamtion",
      courseImage: require("../../assets/marketing-courses.png"),
      postDescription:
        "Happy to share that I have successfully completed Gestion d'inforamtion. Please check out this!",
      views: 35,
      responses: 2,
    },
    {
      id: 4,
      createdAt: "1/2/2024",
      updatedAt: "1/5/2024",
      courseName: "JavaScript",
      courseImage: require("../../assets/toy_car_engineering_hires2.png"),
      postDescription:
        "Happy to share that I have successfully completed JavaScript. Please check out this!",
      views: 14,
      responses: 7,
    },
  ],
};

export const sortingOptions = [
  //   {
  //     name: "All",
  //   },
  //   {
  //     name: "Relevance",
  //   },
  {
    name: "Recently Posted",
    value: "Recently Posted"
  },
  {
    name: "Recently Updated",
    value: "Recently Updated"
  },
  {
    name: "Most Viewed",
    value: "Most Viewed"
  },
  {
    name: "Most Commented",
    value: "Most Commented"
  },
];
