import styled from "styled-components";

export const AnalyticsPageStylesLayout = styled.div`
display: flex;
flex-direction:column;
width:100%;
height:100%;
padding-bottom:3rem;
gap:1.25rem;
`;

export const AnalyticsContainerRow   = styled.div`
display: flex;
gap:1.25rem;
width:100%;
height:22rem;

`;
export const AnalyticsPieChartContainer = styled.div`
display: flex;
flex:1;
justify-content:center;
align-items:center;
height: 16.4375rem;
flex-shrink: 0;
border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
height:100%;
padding:1.25rem;
`;