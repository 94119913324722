// RecordingContext.js
import React, { createContext, useContext, useState, ReactNode } from "react";
import { RecordingInterface } from "../CourseTimeline/utils/CourseResourcesInterface";

export const RecordingContext = createContext({
  recordings: [] as RecordingInterface[],
  setRecordings: (recordings: RecordingInterface[]) => { },
});

export const RecordingProvider = ({ children }: { children: ReactNode }) => {
  const [recordings, setRecordings] = useState([] as RecordingInterface[]);

  return (
    <RecordingContext.Provider value={{ recordings, setRecordings }}>
      {children}
    </RecordingContext.Provider>
  );
};

export const useRecordings = () => {
  return useContext(RecordingContext);
};
