export const RulebookData = [
    {
        rule: "Learner's First Login of the Day",
        points: 8
    },
    {
        rule: "Only on a user's first login",
        points: 10
    },
    {
        rule: "Every Social Feed post creation",
        points: 12
    },
    {
        rule: "Completion of a YouTube video (within a Module) by a Learner",
        points: 6
    },
    {
        rule: "Completion of a Weblink activity (within a Module) by a Learner",
        points: 9
    },
    {
        rule: "Completion of a Micro-Learning Module by a Learner",
        points: 15
    },
    {
        rule: "Completion of a Document activity (within a Module) by a Learner",
        points: 20
    },
    {
        rule: "Bonus points for a Learner when he/she completes a Micro-Learning Module before its due date",
        points: 14
    }
]

export const StudentPointsData = [
    {
        rule: "Logged in",
        points: 10,
        date: "February 14, 2019"
    },
    {
        rule: "Completed 'PPT test' before time",
        points: 15,
        date: "February 14, 2019"
    },
    {
        rule: "Completed Document activity",
        points: 8,
        date: "February 14, 2019"
    },
]