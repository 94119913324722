import { StudentLandingPageInterface } from "./StudentLandingPageInterface";

export const StudentLandingPageData: StudentLandingPageInterface[] = [
  {
    id: "1",
   Course: [
      {
        id: "1",
        name: "Nour Mohammad",
        ProfilePic:require("../../../assets//NourInstructor.jpg"),
        TimeSpent: "12 h",
        LastAccess: "9 Aug | 5:00 PM",
        Grade: 18,
        GPA: "3.9",
      },
    ],
    Recourses: [
      {
        id: "1",
        type: "Files",
        titleB: "Average Time Spent 2 hours",
      Data: [
          {
            id: "1",
            title: "File 1",
            textA: "",
            textB: "Average Time Spent 2 hours",
            Viewed: true,
          },
          {
            id: "2",
            title: "File 2",
            textA: "",
            textB: "Average Time Spent 2 hours",
            Viewed: false,
          },
          {
            id: "3",
            title: "File 3",
            textA: "",
            textB: "Average Time Spent 2 hours",
            Viewed: true,
          },
        ],
      },
      {
        id: "2",
        type: "HTML Links",
        titleB: "Average Time Spent 2 hours",
        Data: [
          {
            id: "1",
            title: "HTML Link 1",
            textA: "",
            textB: "Average Time Spent 2 hours",
            Viewed: false,
          },
          {
            id: "2",
            title: "HTML Link 2",
            textA: "",
            textB: "Average Time Spent 2 hours",
            Viewed: false,
          },
          {
            id: "3",
            title: "HTML Link 3",
            textA: "",
            textB: "Average Time Spent 2 hours",
            Viewed: true,
          },
        ],
      },
      {
        id: "3",
        type: "Videos",
        titleB: "Average Time Spent 2 hours",
      Data: [
          {
            id: "1",
            title: "Video 1 1",
            textA: "",
            textB: "Average Time Spent 2 hours",
            Viewed: true,
          },
          {
            id: "2",
            title: "Video 1 2",
            textA: "",
            textB: "Average Time Spent 2 hours",
            Viewed: true,
          },
          {
            id: "3",
            title: "Video 1 3",
            textA: "",
            textB: "Average Time Spent 2 hours",
            Viewed: true,
          },
        ],
      },
    ],

    Activities: [
      {
        id: "1",
        type: "Quiz",
        titleA: "Average Time Spent 1 hour 10 min",
        titleB: "My Average Grade 71",
      Data: [
          {
            id: "1",
            title: "Quiz 1",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: true,
          },
          {
            id: "2",
            title: "Quiz 2",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: false,
          },
          {
            id: "3",
            title: "Quiz 3",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: true,
          },
        ],
      },
      {
        id: "2",
        type: "Assignments",
        titleB: "Average Time Spent 2 hours",
        Data: [
          {
            id: "1",
            title: "Assignment 1",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: true,
          },
          {
            id: "2",
            title: "Assignment 2",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: false,
          },
          {
            id: "3",
            title: "Assignment 3",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: true,
          },
        ],
      },
      {
        id: "3",
        type: "Forums",
        titleB: "Average Time Spent 2 hours",
        Data: [
          {
            id: "1",
            title: "Forum 1",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: true,
          },
          {
            id: "2",
            title: "Forum 2",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: false,
          },
          {
            id: "3",
            title: "Forum 3",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: true,
          },
        ],
      },
      {
        id: "4",
        type: "Survey",
        titleB: "Average Time Spent 2 hours",
      Data: [
          {
            id: "1",
            title: "Survey 1",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: true,
          },
          {
            id: "2",
            title: "Survey 2",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: false,
          },
          {
            id: "3",
            title: "Survey 3",
            textA: "Average Time Spent 2 hours",
            textB: "Average Time Spent 2 hours",
            Submissions: true,
          },
        ],
      },
    ],
    
  },
];
