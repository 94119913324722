import styled from 'styled-components'
import { theme } from '../../../theme';
export const EssayPreviewContainer=styled.div`
padding-left:1.4rem ;
padding-right:1.4rem;
gap:0.62rem;
width: 100%;
  display: flex;
  flex-direction: column;
`;


export const EssayTextArea=styled.textarea`
width: 100%;
min-height:10rem;
height: 2.8125rem;
border-radius: 0.625rem;
border: 1px solid #576371;
background: transparent;
padding-left:1.25rem;
font-family:'Primary Font-Light';
color:${theme.dark.primary_text_color};
padding-top:0.62rem;
&&:focus {
    outline: none;
  }
`;