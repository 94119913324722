import React from "react";
import {
  RadioAfterSubmissionCell,
  RadioAfterSubmissionCircle,
  RadioAfterSubmissionContainer,
  RadioAfterSubmissionLayout,
  RadioAfterSubmissionRow,
} from "./RadioAfterSubmission.style";
import { TypographyRegularDescription } from "../../../../elements/fonts/Fonts";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../../recoil/ThemeSelectors";
interface MCQAfterSubmissionInterface {
  answer?: any;
  IsCorrect: any;
}
const MCQAfterSubmission = ({
  answer,
  IsCorrect,
}: MCQAfterSubmissionInterface) => {
  const Answer = answer;
  const { RadioButtonGradientColor } = useRecoilValue(ColorSelector);
  return (
    <RadioAfterSubmissionLayout>
      <RadioAfterSubmissionContainer>
        <RadioAfterSubmissionRow>
          <RadioAfterSubmissionCell>
            <RadioAfterSubmissionCircle
              BKCOLOR={
                Answer && IsCorrect === true
                  ? "linear-gradient(to right, #5dd3b3, #2f3b40)"
                  : Answer && IsCorrect !== true
                  ? "linear-gradient(to right, #9B3D44, #D85D66)"
                  : RadioButtonGradientColor
              }
              Border={
                Answer && IsCorrect === true
                  ? " #5dd3b3"
                  : Answer && IsCorrect !== true
                  ? "#D85D66"
                  : "#fff"
              }
            />
            <TypographyRegularDescription
              text={Answer}
              fontSize="0.875rem"
              lineHeight="1.375rem"
            />
          </RadioAfterSubmissionCell>
        </RadioAfterSubmissionRow>
      </RadioAfterSubmissionContainer>
    </RadioAfterSubmissionLayout>
  );
};

export default MCQAfterSubmission;
