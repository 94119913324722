import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const MultipleSelectContainer = styled.div<StyleThemeInterface>`
  display: flex;
  position: relative;
  cursor: pointer;
  /* z-index: 19 */

  font-size: 0.875rem;
  font-family: "Primary Font-Medium";
  height: 2.8125rem;
  width: 100%;
  border: 1px solid ${(props) => props.bordercolor};
  background-color: ${(props) => props.bgcolor};
  position: relative;
  border-radius: 0.625rem;
  padding: 0 0.87rem;
`;

export const Select = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 0.1rem; */
  height: 100%;
  width: 100%;
`;

export const StyledInput = styled.input<StyleThemeInterface>`
  background: transparent;
  border: none;
  width: 100%;
  font-size: 0.75rem;
  font-family: "Primary Font-Medium";
  color: ${(props) => props.color} !important;
  &:focus {
    outline: none;
    color: ${(props) => props.color} !important;
  }
  &::placeholder {
    color: ${(props) => props.color} !important;
    opacity: 0.5;
  }
  cursor: pointer;
`;

export const Arrow = styled.div`
display: flex;
justify-content: center;
align-items: center;
  /* &:dir(ltr) {
    margin-right: 0.81rem;
  }
  &:dir(rtl) {
    margin-left: 0.81rem;
  } */
  /* z-index:-"" */
`;

export const MainSelects = styled.div<StyleThemeInterface>`
  display: flex;
  flex-direction: column;
  z-index: 10; /* Ensure this is a reasonable value */
  position: absolute;
  height: fit-content !important;
  width: 100%;
  max-height: 10rem;
  left: 0;
  top: 2.8125rem;

  box-shadow: ${(props) => props.boxshadow};

  min-height: ${(props) => props.minheight};
  overflow-y: auto;
  overflow-x: hidden;
  /* background:green; */
  border-radius: 0.3125rem;
  /* border-radius: ${(props) =>
    props.theme === "dark" ? "0.3125rem" : "1.25rem "};
  padding: 0.1rem 0; */
  background: ${(props) => (props.theme === "light" ? "#fff" : "#1F2731")};

  &::-webkit-scrollbar {
    width: 0.3rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)"
        : "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)"};
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0.625rem;
  }
`;

export const SelectDivOptions = styled.div<StyleThemeInterface>`
  background: ${(props) => (props.theme === "light" ? "#fff" : "#1F2731")};
  font-size: 0.75rem;
  cursor: pointer;
  padding: 0.5rem 0.87rem;
  /* z-index:25 !important ; */
  font-family: "Primary Font-Medium";
  transition: background-color 0.3s ease;
  justify-content: flex-end;
  color: ${(props) => props.color};
  /* border-radius:6rem !important; */
  /* margin-left:10rem; */

  &:hover {
    background-color: #5dd3b3;
    color: #fff;
  }
  &:first-child {
    border-radius: 0.3125rem 0.3125rem 0rem 0rem
  }
  &:last-child {
    border-radius:0rem 0rem 0.3125rem 0.3125rem
  }
`;

export const EmptySelectDivOptions = styled.div<StyleThemeInterface>`
  height: 6.15rem;
  background: ${(props) => (props.theme === "light" ? "#fff" : "#1F2731")};
  font-size: 0.875rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color};
`;
