import { useState, useEffect, useRef } from 'react'
import { ModalContent, ModalOverlay } from '../../gradeBook/gradeBookModal/GradeBookModal.style'
import useClickOutside from '../../../hooks/useClickOutside';
import { BtnHidden, DividingLine, FirstArrows, GradesModalWrapper, GradesTitleWithInput, ImgBtnRow, Input, NameFirstArrowsRow, SecondArrows, SpeedGraderLogoText, TitleSecondArrowsRow } from './GradesModal.style';
import { SvgArrowRight, SvgArrowShock, SvgArrowSimple, SvgSpeedGraderLabel, SvgSultan } from '../../../elements/Icons';
import { LightTypography, MediumTypography, Typography, TypographyRegularDescription } from '../../../elements/fonts/Fonts';
import { Label } from 'recharts';

const GradesModal = () => {

    const[modal, setModal] = useState<boolean>(true);
    const toggleModel = () =>{
        setModal((prevModal:any) => !prevModal);
    }
    const menuRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      let handler = (e:any)=>{
          if (menuRef.current && !menuRef.current.contains(e.target)) {
          setModal(false);
        }   
      };
    
      document.addEventListener("mousedown", handler);
      
    
      return() =>{
        document.removeEventListener("mousedown", handler);
      }
    
    });

  return (<>
    {modal && (
    <ModalOverlay >

    <GradesModalWrapper 
    ref={menuRef}
    
    >
    <ImgBtnRow>
       <SvgSultan/>
       <BtnHidden>
        <TypographyRegularDescription
        text='HIDDEN'
        fontSize='0.875rem'
        color='#D85D66'
        />
       </BtnHidden>
    </ImgBtnRow>
    <NameFirstArrowsRow>
        <Typography
        text='Sultan'
        fontSize='1rem'
        />
        <FirstArrows>
            <SvgArrowSimple style={{ cursor:'pointer' }}/>
            <SvgArrowSimple style={{
cursor:'pointer',
    transform: 'rotate(180deg)'
  }}/>
        </FirstArrows>
    </NameFirstArrowsRow>

    <TitleSecondArrowsRow>
        <MediumTypography
        text='Chapter 3 - Title chapter'
        />
        <SecondArrows>
            <SvgArrowSimple style={{ cursor:'pointer' }}/>
            <SvgArrowSimple style={{
                cursor:'pointer',
    transform: 'rotate(180deg)'
  }}/>
        </SecondArrows>
    </TitleSecondArrowsRow>
    <DividingLine/>
    <SpeedGraderLogoText>
        <SvgSpeedGraderLabel/>
        <Typography
        text='SPEED GRADER'
        fontSize='0.625rem'
        />
    </SpeedGraderLogoText>
    <GradesTitleWithInput>
    <LightTypography
    text='Grade out of 100'
    fontSize='1rem'
    />
<Input type='number' placeholder='50 %'/>
    </GradesTitleWithInput>
    <GradesTitleWithInput style={{
       gap:'4.62rem' 
    }}>
    <LightTypography
    text='Comments'
    fontSize='1rem'
    />
<Input placeholder='Leave comment'/>
    </GradesTitleWithInput>
    </GradesModalWrapper>

    </ModalOverlay>
    )}
    </>
  )
}

export default GradesModal