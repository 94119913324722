import axios from "axios";
import { contentTypeGenerater } from "../utils/ContentTypeGenerator";
import { generateUUID } from "../utils/StringManipulation";
import { post } from "../utils/AxiosRequests";
const tenantName = localStorage.getItem("tenant");

export const S3Upload = async function (
  filePath: string,
  fileName: string,
  fileNameUuid: string,
  payload: any,
  isPublic?: boolean,
  isInline?: boolean
) {
  const tenantName = localStorage.getItem("tenant");

  // const fileuplKey = `${generateUUID()}${fileName}`;
  // const { fileName, contentType, tenantName, filePath } = body;

  try {
    const tenantName = localStorage.getItem("tenant");
    const response = await post(`s3/generateUploadUrl`, {
      tenantName: tenantName,
      contentType: contentTypeGenerater(fileName || ""),
      fileName: fileNameUuid,
      filePath: filePath,
      isPublic: isPublic || false,
    });

    const fileUploaded = isInline ? await S3Put(response.data, payload, isInline) : await S3Put(response.data, payload);
    return fileUploaded;
  } catch (error) {
    console.log(error);
  }
};

export const S3Put = async function (url: string, payload: any, isInline?: boolean) {
  const res = isInline ? await axios.put(url, payload, {
    headers: {
      'Content-Disposition': 'inline',
      'Content-Type': contentTypeGenerater(payload.name)
    }
  }) : await axios.put(url, payload,
    {

      headers: {
        'Content-Disposition': 'attachment',
        'Content-Type': contentTypeGenerater(payload.name)
      }
    }
  );
  return res;
};
