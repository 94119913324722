import { MainDefaultTable } from "../../../interfaces/AnalyticsCommonInterfaces";
import { ScrumDefaultTableInterface } from "./ScrumDefaultTableInterface";


export  const ScrumDefaultTableData: MainDefaultTable[] =[
  {
    MainCode: "esdfc3453",
    Title: "Scrum 1",


    students:[
      {
        StdCode:"34f32",  StdName:"Hadi Souman", DueDate:new Date("2023-10-02T10:30:00Z"), Status:"Completed on 10 Oct 2023", Grade:"C",
      },
      {
        StdCode:"54fg45",  StdName:"Jihad Semaan", DueDate:new Date("2023-11-02T10:30:00Z"), Status:"Completed on 10 Oct 2023", Grade:"A",
      },
      {
        StdCode:"Salim32234",  StdName:"Salim Bahsoun", DueDate:new Date("2023-10-02T10:30:00Z"), Status:"Not enough data", Grade:"C",
      },
      {
        StdCode:"3rfeg",  StdName:"Yassmina Dbouk", DueDate:new Date("2023-12-05T14:15:00Z"), Status:"Completed on 10 Oct 2023", Grade:"C",
      },
      {
        StdCode:"324fdfdgwq",  StdName:"Rawad Shouman", DueDate:new Date("2023-10-02T10:30:00Z"), Status:"Not enough data", Grade:"C",
      },
      {
        StdCode:"Walsdr435id",  StdName:"Abdallah Zbib", DueDate:new Date("2023-12-10T16:45:00Z"), Status:"Not enough data", Grade:"A",
      },
    ]
  },
  
  {
    MainCode: "4rtgtrfgew",
    Title: "Scrum 2",


    students:[
      {
        StdCode:"34f32",  StdName:"Hadi Souman", DueDate:new Date("2023-10-02T10:30:00Z"), Status:"Not enough data", Grade:"B",
      },
      {
        StdCode:"54fg45",  StdName:"Jihad Semaan", DueDate:new Date("2023-11-02T10:30:00Z"), Status:"Completed on 10 Oct 2023", Grade:"A",
      },
      {
        StdCode:"Salim32234",  StdName:"Salim Bahsoun", DueDate:new Date("2023-10-02T10:30:00Z"), Status:"Completed on 10 Oct 2023", Grade:"A",
      },
      {
        StdCode:"3rfeg",  StdName:"Yassmina Dbouk", DueDate:new Date("2023-12-05T14:15:00Z"), Status:"Completed on 10 Oct 2023", Grade:"A",
      },
      {
        StdCode:"324fdfdgwq",  StdName:"Rawad Shouman", DueDate:new Date("2023-10-02T10:30:00Z"), Status:"Not enough data", Grade:"A",
      },
      {
        StdCode:"Walsdr435id",  StdName:"Abdallah Zbib", DueDate:new Date("2023-12-10T16:45:00Z"), Status:"Not enough data", Grade:"A",
      },
    ]
  },
  {
    MainCode: "sdf435fsde",
    Title: "Scrum 3",


    students:[
      {
        StdCode:"34f32",  StdName:"Hadi Souman", DueDate:new Date("2023-10-02T10:30:00Z"), Status:"Not enough data", Grade:"B",
      },
      {
        StdCode:"54fg45",  StdName:"Jihad Semaan", DueDate:new Date("2023-11-02T10:30:00Z"), Status:"Completed on 10 Oct 2023", Grade:"D",
      },
      {
        StdCode:"Salim32234",  StdName:"Salim Bahsoun", DueDate:new Date("2023-10-02T10:30:00Z"), Status:"Completed on 10 Oct 2023", Grade:"D",
      },
      {
        StdCode:"3rfeg",  StdName:"Yassmina Dbouk", DueDate:new Date("2023-12-05T14:15:00Z"), Status:"Completed on 10 Oct 2023", Grade:"D",
      },
      {
        StdCode:"324fdfdgwq",  StdName:"Rawad Shouman", DueDate:new Date("2023-10-02T10:30:00Z"), Status:"Not enough data", Grade:"C",
      },
      {
        StdCode:"Walsdr435id",  StdName:"Abdallah Zbib", DueDate:new Date("2023-12-10T16:45:00Z"), Status:"Not enough data", Grade:"C",
      },
    ]
  },
  {
    MainCode: "456gfdsd",
    Title: "Scrum 6",


    students:[
      {
        StdCode:"34f32",  StdName:"Hadi Souman", DueDate:new Date("2023-10-02T10:30:00Z"), Status:"Completed on 10 Oct 2023", Grade:"A",
      },
      {
        StdCode:"54fg45",  StdName:"Jihad Semaan", DueDate:new Date("2023-11-02T10:30:00Z"), Status:"Not enough data", Grade:"A",
      },
      {
        StdCode:"Salim32234",  StdName:"Salim Bahsoun", DueDate:new Date("2023-10-02T10:30:00Z"), Status:"Not enough data", Grade:"A",
      },
      {
        StdCode:"3rfeg",  StdName:"Yassmina Dbouk", DueDate:new Date("2023-12-05T14:15:00Z"), Status:"Completed on 10 Oct 2023", Grade:"A",
      },
      {
        StdCode:"324fdfdgwq",  StdName:"Rawad Shouman", DueDate:new Date("2023-10-02T10:30:00Z"), Status:"Completed on 10 Oct 2023", Grade:"A",
      },
      {
        StdCode:"Walsdr435id",  StdName:"Abdallah Zbib", DueDate:new Date("2023-12-10T16:45:00Z"), Status:"Completed on 10 Oct 2023", Grade:"A",
      },
    ]
  },
  
  {
    MainCode: "65uhbffds",
    Title: "Scrum 7",


    students:[
      {
        StdCode:"34f32",  StdName:"Hadi Souman", DueDate:new Date("2023-10-02T10:30:00Z"), Status:"Not enough data", Grade:"B",
      },
      {
        StdCode:"54fg45",  StdName:"Jihad Semaan", DueDate:new Date("2023-11-02T10:30:00Z"), Status:"Completed on 10 Oct 2023", Grade:"B",
      },
      {
        StdCode:"Salim32234",  StdName:"Salim Bahsoun", DueDate:new Date("2023-10-02T10:30:00Z"), Status:"Not enough data", Grade:"B",
      },
      {
        StdCode:"3rfeg",  StdName:"Yassmina Dbouk", DueDate:new Date("2023-12-05T14:15:00Z"), Status:"Not enough data", Grade:"B",
      },
      {
        StdCode:"324fdfdgwq",  StdName:"Rawad Shouman", DueDate:new Date("2023-10-02T10:30:00Z"), Status:"Completed on 10 Oct 2023", Grade:"B",
      },
      {
        StdCode:"Walsdr435id",  StdName:"Abdallah Zbib", DueDate:new Date("2023-12-10T16:45:00Z"), Status:"Not enough data", Grade:"B",
      },
    ]
  },
  {
    MainCode: "sdfte43fghgfh",
    Title: "Scrum 8",


    students:[
      {
        StdCode:"34f32",  StdName:"Hadi Souman", DueDate:new Date("2023-10-02T10:30:00Z"), Status:"Not enough data", Grade:"A",
      },
      {
        StdCode:"54fg45",  StdName:"Jihad Semaan", DueDate:new Date("2023-11-02T10:30:00Z"), Status:"Completed on 10 Oct 2023", Grade:"B",
      },
      {
        StdCode:"Salim32234",  StdName:"Salim Bahsoun", DueDate:new Date("2023-10-02T10:30:00Z"), Status:"Completed on 10 Oct 2023", Grade:"A",
      },
      {
        StdCode:"3rfeg",  StdName:"Yassmina Dbouk", DueDate:new Date("2023-12-05T14:15:00Z"), Status:"Completed on 10 Oct 2023", Grade:"B",
      },
      {
        StdCode:"324fdfdgwq",  StdName:"Rawad Shouman", DueDate:new Date("2023-10-02T10:30:00Z"), Status:"Not enough data", Grade:"A",
      },
      {
        StdCode:"Walsdr435id",  StdName:"Abdallah Zbib", DueDate:new Date("2023-12-10T16:45:00Z"), Status:"Not enough data", Grade:"B",
      },
    ]
  },
]