import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";
import { breakpoints } from "../../theme";

export const Container = styled.div<StyleThemeInterface>`
display: flex;
flex-direction: column;
max-width: 48.838rem;
height: 60vh;
gap: 1.25rem;
`
export const PopupTitle = styled.div`
margin-left: 1.87rem;
margin-bottom: 1.25rem;
`
export const TopContent = styled.div`
display: flex;
flex-direction: column;
width: 100%;
gap: 1.25rem;
padding-left: 1.25rem;
padding-right: 1.63rem;
`
export const SearchContainer = styled.div`
/* margin-left: auto; Align to the right */
width: 23.625rem;
`
export const SelectCont = styled.div`
display: flex;
flex-direction: column;
gap: 0.62rem;
`
export const FiltersCont = styled.div`
display: flex;
flex-wrap: wrap;
gap: 0.62rem;
`
export const FiltersButtons = styled.div<{ active?: boolean, bgColor?: string, color?: string, borderColor?: string }>`
  border-radius: 0.4rem;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Primary Font-Medium";
  font-size: 0.875rem;
  background-color: ${({ active }) => (active ? "#5DD3B3" : "transparent")};
  color: ${({ active }) => (active ? "#fff" : "#5DD3B3")};
  border: 1px solid #5DD3B3;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s ease;
  user-select: none;
  &:hover {
    box-shadow: 0 0 1rem rgba(42, 243, 156, 0.3);
  }
`;


export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TableRow = styled.div<{bgColor?: string}>`
  display: flex;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "transparent")};

  /* > *:first-child {
    width: 15rem;
  } */
`;
export const TableHeader = styled.div<StyleThemeInterface>`
  display: flex;
  background: ${props =>props.theme === "dark" ? "linear-gradient(90deg, #415160 0%, #293440 107.88%)" : "rgba(170, 177, 185, 0.3)"};
  /* background:${props =>props.bgcolor}; */

  /* > *:first-child {
    width: 12.3rem;
  } */
`;

export const TableCell = styled.div`
  padding: 0.69rem 1.87rem;
`;
export const QuestionDiv = styled.div`
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any overflow */
  text-overflow: ellipsis; /* Add ellipsis (...) when text overflows */
  width: 75%;
`;

export const NoData = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.63rem;
  padding-top: 2.5rem;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  padding-top: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;