import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Props } from "./DropzoneInterface";
import {
  Container,
  Content,
  SelectedFileDiv,
  FileNameDiv,
} from "./DropZone.style";
import { SvgCloseIcon, SvgDropZoneIcon } from "../../../elements/Icons";
import {
  MediumTypography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";

const DragAndDropZone = ({
  onDrop,
  text,
  acceptedTypes,
  initialFile,
  setFileResponse,
  minHeight,
  fontSize,
  marginTop,
  widthIcon,
  heightIcon,
}: Props): JSX.Element => {
  const { inputBorderColor } = useRecoilValue(ColorSelector);
  const [selectedFile, setSelectedFile] = useState<string | null | undefined>(
    initialFile ? initialFile : null
  );
  const [selectedFileToDelete, setSelectedFileToDelete] = useState<
    string | null
  >(null);

  const onDropHandler = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const firstAcceptedFile = acceptedFiles[0];
        setSelectedFile(firstAcceptedFile.name);
        setSelectedFileToDelete(firstAcceptedFile.name);
        onDrop(firstAcceptedFile);
      }
    },
    [onDrop]
  );

  const removeFile = () => {
    setSelectedFile(null);
    setSelectedFileToDelete(null);
    setFileResponse && setFileResponse(null);
  };

  const toggleFileSelection = (fileName: string) => {
    if (selectedFileToDelete === fileName) {
      setSelectedFileToDelete(null);
    } else {
      setSelectedFileToDelete(fileName);
    }
  };

  useEffect(() => {
    setSelectedFile(initialFile);
  }, [initialFile]);

  const isFileSelectedForDeletion = (fileName: string) =>
    selectedFileToDelete === fileName;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropHandler,
    accept: acceptedTypes,
  });

  return (
    <>
      <Container minheight={minHeight || "13.0625rem"} inputbordercolor={inputBorderColor}>
        <div
          style={{
            minHeight: "3rem",
            display: "flex",
            columnGap: "2.19rem",
            rowGap: "0.25rem",
            flexWrap: "wrap",
          }}
        >
          {selectedFile && (
            <SelectedFileDiv
              onClick={() => toggleFileSelection(selectedFile)}
              style={{
                backgroundColor: isFileSelectedForDeletion(selectedFile)
                  ? "#5DD3B3"
                  : "",
                color: isFileSelectedForDeletion(selectedFile) ? "white" : "",
              }}
              bordercolor={inputBorderColor}
            >
              <FileNameDiv>
                <TypographyRegularDescription
                  text={selectedFile}
                  fontSize="0.75rem"
                />
              </FileNameDiv>
              {isFileSelectedForDeletion(selectedFile) && (
                <SvgCloseIcon onClick={removeFile} />
              )}
            </SelectedFileDiv>
          )}
        </div>
        <div
          {...getRootProps()}
          style={{
            minHeight: "4rem",
            // backgroundColor: "gray",
            marginTop: `${marginTop ? marginTop : "0"}`,
          }}
        >
          {!selectedFile && (
            <div>
              <input {...getInputProps()} />
              <Content>
                <SvgDropZoneIcon
                  width={widthIcon || 20}
                  height={heightIcon || 20}
                />
                <div
                  style={{
                    paddingTop: "1.25rem",
                    textAlign: "center",
                  }}
                >
                  <MediumTypography
                    text={text}
                    fontSize={fontSize || "1.125rem"}
                    color="#5DD3B3"
                  />
                </div>
              </Content>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default DragAndDropZone;
