import styled from "styled-components";
import { StyleThemeInterface } from "../../../../interfaces/StyleThemeInterface";

// export const ModalOverlay = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 3; /* Ensure it's above other content */
// `;

// export const ModalContent = styled.div`
//   background: #384450;
//   /* padding: 20px; */

//   border-radius: 8px;
//   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
// `;
export const ModalOverlay = styled.div<StyleThemeInterface>`
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; */
  background:${props =>props.bgcolor};
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 3;  */
  @media (max-width: 700px) {
   /* width: 50%; */
  }
`;

export const ModalContent = styled.div`
  /* background: #384450; */
  /* padding: 20px; */
width: auto;
height: auto;
/* min-width: 20rem; */
/* min-height: 35rem; */
/* max-height: 50rem; */
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  /* background:red; */
  
`;