import React, { useState } from "react";
import { LightTypography } from "../../../../elements/fonts/Fonts";
import SelectInput from "../../../../elements/SelectInput/SelectInput";
import TextInputElement from "../../../../elements/TextInput/TextInput";
import { FlexAppContainer } from "../../../../App.style";
import ButtonIconAndText from "../../../../elements/ButtonIconAndText/ButtonIconAndText";
import { SvgAdd, SvgDelete } from "../../../../elements/Icons";
import {
  ActivityNamesOptions,
  ConditionAdvancedValues,
  ConditionIntermediateValues,
  ConditionPoorValues,
  RoleGradedOptions,
} from "../SelectsValuesAndChanges/SelectsValuesAndChanges";
import {
  CompetencyPopUpColumnSelect,
  CompetencyPopUpRow,
} from "../CompetencyPopUp.style";
import { PointerContainer } from "../../../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableTable.style";
import SelectDropDown from "../../../../elements/selectDropDown/SelectDropDown";

interface CompetencyActivityFormInterface {
  activityForms: {
    ActivityNames: string;
    RoleValue: string;
    PoorValue: string;
    IntermediateValue: string;
    AdvancedValue: string;
  }[];
  setActivityForms: React.Dispatch<
    React.SetStateAction<
      {
        ActivityNames: string;
        RoleValue: string;
        PoorValue: string;
        IntermediateValue: string;
        AdvancedValue: string;
      }[]
    >
  >;
}

const CompetencyActivityForm = ({
  activityForms,
  setActivityForms,
}: CompetencyActivityFormInterface) => {
  const handleAddActivity = () => {
    setActivityForms([
      ...activityForms,
      {
        ActivityNames: "",
        RoleValue: "",
        PoorValue: "",
        IntermediateValue: "",
        AdvancedValue: "",
      },
    ]);
  };

  const handleRemoveActivity = (indexToRemove: number) => {
    setActivityForms(
      activityForms.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleActivityNameChange = (newValue: string, index: number) => {
    const updatedForms = [...activityForms];
    updatedForms[index].ActivityNames = newValue;
    const selectedOption = ActivityNamesOptions.find(
      (option) => option.name === newValue
    );
    if (selectedOption && selectedOption.graded) {
      updatedForms[index].RoleValue = "";
    } else {
      updatedForms[index].RoleValue = "Contributed";
    }
    setActivityForms(updatedForms);
  };

  return (
    <FlexAppContainer column={true} gap="1.25rem">
      {activityForms.map((form, index) => (
        <FlexAppContainer key={index} column={true} gap="1.25rem">
          <CompetencyPopUpRow>
            <CompetencyPopUpColumnSelect>
              <LightTypography text="Activity Name" fontSize="0.875rem" />
              <SelectDropDown
                onChange={(newValue: string) =>
                  handleActivityNameChange(newValue, index)
                }
                placeholder="Choose the activity"
                options={ActivityNamesOptions}
                value={form.ActivityNames}
              />
            </CompetencyPopUpColumnSelect>
            {form.ActivityNames && (
              <>
                {ActivityNamesOptions.find(
                  (option) =>
                    option.name === form.ActivityNames && option.graded
                ) ? (
                  <CompetencyPopUpColumnSelect>
                    <LightTypography text="Role" fontSize="0.875rem" />
                  
                     <SelectDropDown
                      height="2.8rem"
                      onChange={(newValue: string) => {
                        const updatedForms = [...activityForms];
                        updatedForms[index].RoleValue = newValue;
                        setActivityForms(updatedForms);
                      }}
                placeholder="Select The Role"
                options={RoleGradedOptions}
                value={form.RoleValue}
              />
                  </CompetencyPopUpColumnSelect>
                ) : (
                  <CompetencyPopUpColumnSelect>
                    <LightTypography text="Role" fontSize="0.875rem" />
                    <TextInputElement
                      readOnly={true}
                      value={form.RoleValue}
                      borderRadius="0.625rem"
                    />
                  </CompetencyPopUpColumnSelect>
                )}
              </>
            )}
            {index > 0 && (
              <FlexAppContainer width="fit-content" alignSelf="flex-end">
                <PointerContainer
                  color="#D85D66"
                  onClick={() => handleRemoveActivity(index)}
                >
                  <SvgDelete />
                </PointerContainer>
              </FlexAppContainer>
            )}
          </CompetencyPopUpRow>
          {form.RoleValue === "Grade Scale" && (
            <CompetencyPopUpRow>
              <CompetencyPopUpColumnSelect>
                <LightTypography text="Poor" fontSize="0.875rem" />
             
                    <SelectDropDown
                      height="2.8rem"
                      onChange={(newValue: string) => {
                        const updatedForms = [...activityForms];
                        updatedForms[index].PoorValue = newValue;
                        setActivityForms(updatedForms);
                      }}
                placeholder="Select grade scale"
                options={ConditionPoorValues}
                value={form.PoorValue}
              />
              </CompetencyPopUpColumnSelect>
              <CompetencyPopUpColumnSelect>
                <LightTypography text="Intermediate" fontSize="0.875rem" />
               
                      <SelectDropDown
                      height="2.8rem"
                      onChange={(newValue: string) => {
                        const updatedForms = [...activityForms];
                        updatedForms[index].IntermediateValue = newValue;
                        setActivityForms(updatedForms);
                      }}
                placeholder="Select grade scale"
                options={ConditionIntermediateValues}
                value={form.IntermediateValue}
              />
              </CompetencyPopUpColumnSelect>
              <CompetencyPopUpColumnSelect>
                <LightTypography text="Advanced" fontSize="0.875rem" />
              
                    <SelectDropDown
                      height="2.8rem"
                      onChange={(newValue: string) => {
                        const updatedForms = [...activityForms];
                        updatedForms[index].AdvancedValue = newValue;
                        setActivityForms(updatedForms);
                      }}
                placeholder="Select grade scale"
                options={ConditionAdvancedValues}
                value={form.AdvancedValue}
              />
              </CompetencyPopUpColumnSelect>
              <CompetencyPopUpColumnSelect />
            </CompetencyPopUpRow>
          )}
        </FlexAppContainer>
      ))}

      <FlexAppContainer justifyContent="flex-end">
        <ButtonIconAndText
          text="Add Activity"
          iconPath={SvgAdd}
          onClick={handleAddActivity}
        />
      </FlexAppContainer>
    </FlexAppContainer>
  );
};

export default CompetencyActivityForm;
