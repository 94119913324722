import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useEffect, useRef, useState, useCallback } from "react";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../utils/StringManipulation";
import axios from "axios";
import { useParams } from "react-router-dom";

type WebsocketContextType = {
  isReady: boolean;
  value: any;
  send: ((data: string) => void) | null;
  connect: () => void;
};

const defaultValue: WebsocketContextType = {
  isReady: false,
  value: null,
  send: null,
  connect: () => {},
};

type WebsocketProviderProps = {
  children: React.ReactNode;
};

export const WebsocketContext = createContext<WebsocketContextType>(defaultValue);

export const WebsocketProvider: React.FC<WebsocketProviderProps> = ({ children}) => {
  const [isReady, setIsReady] = useState(false);
  const [val, setVal] = useState<any>(null);
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const ws = useRef<WebSocket | null>(null);
  const params = useParams();
  const classId = params.classId
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;

  var url = `wss://u9stdu002i.execute-api.eu-west-1.amazonaws.com/dev/?subId=${subIdSplitted}&provider=${provider}&tenantName=${tenantName} `;

  const shouldRenderWebsocketProvider = tenantName && subIdSplitted;

  const connect = useCallback(() => {
    if (ws.current) {
      ws.current.close();
    }

    const socket = new WebSocket(url);

    socket.onopen = () => {
      setIsReady(true);
      // setRetryCount(0); // Reset retry count on successful connection
    };

    socket.onclose = async () => {
      try {
        setIsReady(false);
        const deleteResponse = await axios.delete(`https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/delete/client/notification/${subIdSplitted}/${tenantName}?provider=${provider}`);
      } catch (error) {
        console.log(error);
      }

      // if (retryCount < maxRetries) {
      //   setTimeout(() => {
      //     console.log(`Reconnecting... Attempt ${retryCount + 1}`);
      //     setRetryCount(retryCount + 1);
      //     connect(); // Attempt to reconnect
      //   }, 3000); // Retry connection after 3 seconds
      // } else {
      //   console.log("Max reconnection attempts reached.");
      // }
    };

    socket.onerror = () => {
      setIsReady(false);
    }

    socket.onmessage = (event) => setVal(event.data);

    ws.current = socket;
  }, [url]);

  useEffect(() => {
    if(tenantName !== null && tenantName !== undefined && subIdSplitted !== null && subIdSplitted !== undefined){
      connect();
    }

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [connect,subIdSplitted,tenantName]);

  const send = ws.current ? ws.current.send.bind(ws.current) : null;

  const contextValue: WebsocketContextType = {
    isReady,
    value: val,
    send,
    connect,
  };

  return shouldRenderWebsocketProvider ? (
    <WebsocketContext.Provider value={contextValue}>
    {children}
    </WebsocketContext.Provider>
  ) : (
    <>{children}</>
  );
};
