import styled from 'styled-components';

// export const GlobalStyles = styled.div`
//   * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//   }

//   body {
//     font-family: sans-serif;
//   }
// `;

export const StyledDropdown = styled.div<{ dropdown: boolean; depthLevel: number; hasBorder: boolean }>`
  position: absolute;
  color: #fff;
  top: 0;
  right: -12.5rem;
  /* left: auto; */
  width: 12.5rem;
height: fit-content; 
/* border-radius: 0rem 0rem 0.625rem 0.625rem ; */
border: none;
${(props) =>
    props.hasBorder
      ? "border-left: 2px solid #5DD3B3;" // Apply border and padding
      : ""} // No border and padding when depthLevel is 0
       ${(props) =>
    props.depthLevel === 0
      ? "border-radius: 0rem 0.3125rem 0.3125rem 0.3125rem;"
      : props.depthLevel === 1
      ? "border-radius: 0rem 0rem 0.3125rem 0rem;"
      : props.depthLevel === 2
      ? "border-radius: 0rem 0.3125rem 0.3125rem 0rem;"
      : ""} // Apply different border-radius based on depthLevel
background: #1F2731;
/* padding: 0.87rem 0.87rem 0.87rem 0.87rem; */
/* padding:0.87rem 0.87rem 0.87rem 0.87rem; */

/* ::placeholder{
font-family: 'Primary Font-Light';
font-size: 0.875rem;
color: gray;
font-style: normal;
font-weight: 400;
line-height: normal;
} */
  z-index: 9999;
  min-width: 10rem;
  /* padding: 0.5rem 0; */
  list-style: none;
  /* background-color: #fff; */
  /* border-radius: 0.5rem; */
  display: ${(props) => (props.dropdown ? "block" : "none")};
  &.dropdown-submenu {
    position: absolute;
    left: 100%;
    top: 0px;
    
  }

  > div.dropdown-submenu > div:nth-of-type(2) {
    position: relative; /* Make it relative to the parent */
    top: -45px; /* Adjust this value to your desired positioning */
  }
`;


export const Header = styled.header`
  height: 58px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
`;

export const NavArea = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  height: 58px;
`;

export const Logo = styled.a`
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
`;

export const Menus = styled.ul`
  display: flex;
  list-style: none;
/* height: 2.8125rem;  */

`;

export const MenuItemWrapper  = styled.li<{ depthLevel: number; }>`
  position: relative;
  font-size: 14px;
  background: #1F2731;
/* height: 2.8125rem;  */
list-style: none;
&:last-child{

border-radius: 0rem 0rem 0.3125rem 0.3125rem;


}

  a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }

  button {
    color: #fff;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    height: 2.8125rem; 

  }

  a,
  button {
    text-align: left;
    padding: 0.7rem 1rem;
  }

  a:hover,
  button:hover {
    background-color: #5DD3B3;
  }
`;

export const Arrow = styled.span`
  /* &::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  } */
  width: 0.5rem;
/* height: 0.3125rem; */
`;

export const Dropdown = styled.ul`
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem; 
  display: none;

  &.show {
    display: block;
  }
`;

export const DropdownSubmenu = styled.div`
  position: absolute;
  left: 100%;
  top: -7px;
`;
