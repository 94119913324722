import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MenuItem } from "../sidebar/SidebarMenu/sidebarInterface";
import { ReactComponent as SvgProfileIcon } from "../../assets/icons/Profile _02.svg";
import { ReactComponent as SvgSettionIcon } from "../../assets/icons/Group_1889.svg";
import { ReactComponent as SvgPaymentIcon } from "../../assets/icons/Payment_Method.svg";
import { ReactComponent as SvgSecurityIcon } from "../../assets/icons/Security.svg";
import { ReactComponent as SvgNotificationIcon } from "../../assets/icons/Notification2.svg";
import { ReactComponent as SvgEyeIcon } from "../../assets/icons/NewEye.svg";
import { CourseCardData } from "../courseCard/CourseCardData";
import { SvgInstructorInformation } from "../../elements/Icons";

const ProfileOptionsDataFields = () => {

  const [id, setid] = useState(0)
  // const { id } = useParams();
  // const parsedId = id ? parseInt(id) : null;
  // const Profiledata =
  //   parsedId !== null
  //     ? CourseCardData.find((item) => item.id === parsedId)
  //     : null;

  useEffect(() => {
    // Read the user's profile data from localStorage
    const userProfileJSON = localStorage.getItem("userProfile");

    if (userProfileJSON) {
      const userProfile = JSON.parse(userProfileJSON);

      // Now you can access the user's profile data like this:
      const storedFirstName = userProfile.FirstName;
      const storedPassword = userProfile.Password;
      const LocalEmail = userProfile.Email;

      const storedId = userProfile.id;

      // ... and so on for other fields
      setid(storedId)

      // Do something with the retrieved data, if needed


    }
  }, []);

  // Define a placeholder value for 'id'
  const ProfileOptionData: MenuItem[] = [
    {
      icon: SvgProfileIcon,
      title: "User Profile.Profile Information",
      path: "/editProfile",
    },
    {
      icon: SvgInstructorInformation,
      title: "User Profile.Instructor Information",
      path: "/InstructorInformation",
    },
    {
      icon: SvgSettionIcon,
      title: "User Profile.Account Settings",
      path: "/changePassword",
    },
    // {
    //   icon: SvgPaymentIcon,
    //   title: "Payment Methods",
    //   path: "/Payment",
    // },
    // {
    //   icon: SvgSecurityIcon,
    //   title: "Privacy Settings",
    //   path: "/PrivacySetting",
    // },
    // {
    //   icon: SvgNotificationIcon,
    //   title: "Notification Settings",
    //   path: "/NotificationSetting",
    // },
    {
      icon: SvgEyeIcon,
      title: "User Profile.Public Profile View",
      path: `/user/${id}`,
    }


    // in app.tsx
    //  <Route
    //     path="/user/:id"
    //     element={<ProtectedRoute component={<UserView />} />}
    //      /> 

  ];

  return ProfileOptionData;
};



export default ProfileOptionsDataFields;
