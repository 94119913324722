import React, { ReactNode, createContext, useContext, useState } from 'react';

interface QuizGraderContextProps {
    assignmentGrade: any[];
    quizGrade: any[];
    forumGrade: any[];
    setAssignmentGrade: React.Dispatch<React.SetStateAction<any[]>>;
    setQuizGrade: React.Dispatch<React.SetStateAction<any[]>>;
    setForumGrade: React.Dispatch<React.SetStateAction<any[]>>;
    currentView: 'assignment' | 'quiz'  | 'forum';
    setCurrentView: React.Dispatch<React.SetStateAction<'assignment' | 'quiz'  | 'forum'>>;
  }

  // Modify the initial state accordingly
const initialState: QuizGraderContextProps = {
    assignmentGrade: [],
    quizGrade: [],
    forumGrade: [],
    setAssignmentGrade: () => {},
    setQuizGrade: () => {},
    setForumGrade: () => {},
    currentView: 'assignment',
    setCurrentView: () => {},
  };

// Create the context
const GraderContext = createContext<QuizGraderContextProps>(initialState);

// Create a provider component
const MyContextProvider = ({ children }: { children: ReactNode }) => {
    const [assignmentGrade, setAssignmentGrade] = useState<any[]>([]);
    const [quizGrade, setQuizGrade] = useState<any[]>([]);
    const [forumGrade, setForumGrade] = useState<any[]>([]);
    const [currentView, setCurrentView] = useState<'assignment' | 'quiz' | 'forum'>('assignment');
  
    return (
      <GraderContext.Provider value={{ assignmentGrade, quizGrade, forumGrade, setAssignmentGrade, setQuizGrade, setForumGrade, currentView, setCurrentView }}>
        {children}
      </GraderContext.Provider>
    );
  };
  
  // Create a custom hook to access the context values
const useMyContext = () => {
    const context = useContext(GraderContext);
    if (!context) {
      throw new Error('useMyContext must be used within a MyContextProvider');
    }
    return context;
  };
  
  export { MyContextProvider, useMyContext };