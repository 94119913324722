import { Question } from "./generateSurveyDataInterface";

// This Function is a Validation For saving the question
export const handleValidation = (questions:Question[],quest:any,index:number,partIndex:number,choice:any[],qindexValue:number) => {
    if (questions[index]?.type === "" && !questions[index]?.questPart) {
        return("Please select a question type for the question.");
      } else if (
        !questions[index]?.type &&
        !questions[index]?.questPart[partIndex]?.type
      ) {
        return("Please select a question type for the question part.");
      }
      if (!quest || quest.question.trim() === "" || quest.question.trim() === "<br>") {
        return("This field is mandatory");
      }
  ////////////////////////////////////////////////////////////////////////////////////////////////
      if (
        (quest.type === "RATE" || quest.type === "SCALE") &&
        quest.branching === true
      ) {
        for (let i = 0; i < 5; i++) { // Assuming there are 5 choices
          if (!choice[i]?.branch || Object.keys(choice[i]?.branch || {}).length === 0) {
            return('Select a Type for the branch');
          }
        }
      }
      ////////////////////////////////////////////////////////////////////////////////////////////////
      if (
        (quest.type === "MCQ" ) &&
        quest.branching === true
      ) {
        for (let i = 0; i < qindexValue; i++) { // Assuming there are 5 choices
          if (!choice[i]?.branch || Object.keys(choice[i]?.branch || {}).length === 0) {
            return('Select a Type for the branch');
          }
        }
      }
      ////////////////////////////////////////////////////////////////////////////////////////////////
      if (
        (quest.type === "BINARY") &&
        quest.branching === true
      ) {
        for (let i = 0; i < 2; i++) { // Assuming there are 2 choices
          if (!choice[i]?.branch || Object.keys(choice[i]?.branch || {}).length === 0) {
            return('Select a Type for the branch');
          }
        }
      }
      ////////////////////////////////////////////////////////////////////////////////////////////////
      if (quest.type === "MCQ") {
        // Ensure the first two choices' names are not empty strings
        if (!choice[0]?.name?.trim() || !choice[1]?.name?.trim()) {
          return("First two choices' names cannot be empty for MCQ questions.");
        }
      }
      ////////////////////////////////////////////////////////////////////////////////////////////////
      if( (quest.type === "RATE" || quest.type === "SCALE") && quest.branching === true){
        if(
          (!choice[0]?.branch?.name?.trim() || choice[0]?.branch?.name?.trim() ==="<br>") || 
          (!choice[1]?.branch?.name?.trim() || choice[1]?.branch?.name?.trim() ==="<br>") || 
          (!choice[2]?.branch?.name?.trim() || choice[2]?.branch?.name?.trim() ==="<br>") || 
          (!choice[3]?.branch?.name?.trim() || choice[3]?.branch?.name?.trim() ==="<br>") ||
          (!choice[4]?.branch?.name?.trim() || choice[4]?.branch?.name?.trim() ==="<br>") 
        )
        {
          return('The name of branches is mandatory')
        }
      }
      ////////////////////////////////////////////////////////////////////////////////////////////////
      if (
        (quest.type === "RATE" || quest.type === "SCALE" || quest.type === "MCQ" || quest.type === "BINARY") &&
        quest.branching === true
      ) {
        for (let i = 0; i < choice.length; i++) {
          const currentChoice = choice[i];
          if (currentChoice?.branch?.type === "MCQ" && currentChoice?.branch?.choices) {
            const choices = currentChoice.branch.choices;
            for (let j = 0; j < (choices?.length ?? 0); j++) {
              const currentBranchChoice = choices?.[j];
              if (!currentBranchChoice?.name?.trim()) {
                return('The Choices of Multiple Questions is mandatory');
              }
            }
          }
        }
      }
       ////////////////////////////////////////////////////////////////////////////////////////////////
       if( (quest.type === "MCQ") && quest.branching === true){
        if(
          (!choice[qindexValue]?.branch?.name?.trim() || choice[qindexValue]?.branch?.name?.trim()==="<br>")
          
        )
        {
          return('The name of branches is mandatory')
        }
      }
      ////////////////////////////////////////////////////////////////////////////////////////////////
      if( quest.type === "BINARY"  && quest.branching === true){
        if(
          (!choice[0]?.branch?.name?.trim() || choice[0]?.branch?.name?.trim() === "<br>") || 
          (!choice[1]?.branch?.name?.trim() || choice[1]?.branch?.name?.trim() === "<br>") 
        )
        {
          return('The name of branches is mandatory')
        }
      }
      ////////////////////////////////////////////////////////////////////////////////////////////////
      if (quest.type === "RATE" && quest.branching === true) {
        let ratings: number[] = [];
        let duplicateRatings = new Set<number>();
        let hasZeroRating = false;
      
        // Extract ratings, check for zero ratings, and identify duplicates
        for (const c of choice) {
          if (c.value !== undefined && !isNaN(c.value)) {
            const rating = c.value;
            if (rating === 0) {
              hasZeroRating = true;
              break;
            }
            ratings.push(rating);
            duplicateRatings.add(rating);
          }
        }
      
        if (hasZeroRating) {
          return('The rating value must be between 1 and 5');
        }
      
        if (duplicateRatings.size !== ratings.length) {
          return('Duplicate ratings are not allowed.');
        }
      }
      ////////////////////////////////////////////////////////////////////////////////////////////////
      if (quest.type === "SCALE" && quest.branching === true) {
        let scaleNames: string[] = [];
        let hasEmptyScaleName = false;
        let duplicateScaleNames = new Set<string>();
      
        // Extract scale names, check for empty names, and identify duplicates
        for (const c of choice) {
          if (c.name && typeof c.name === 'string' && c.name.trim() !== '') {
            const scaleName = c.name.trim();
            scaleNames.push(scaleName);
      
            if (duplicateScaleNames.has(scaleName)) {
              return('Duplicate scale names are not allowed.');
            }
            duplicateScaleNames.add(scaleName);
          } else {
            hasEmptyScaleName = true;
            break;
          }
        }
      
        if (hasEmptyScaleName) {
          return('The scale name cannot be empty.');
        }
      }
      ////////////////////////////////////////////////////////////////////////////////////////////////
      if (quest.type === "BINARY" && quest.branching === true) {
        let isCheckedValues = new Set();
      
        for (const c of choice) {
          if (c.isChecked !== undefined) {
            isCheckedValues.add(c.isChecked);
          }
        }
      
        if (isCheckedValues.size !== choice.length) {
          return('Duplicate values for Yes/No are not allowed.');
        }
      }
}
// End Of Function