import styled from "styled-components";
import { breakpoints } from "../../../theme";

export const TimelineWrapper = styled.div<{ bgColor?: string }>`
  border-radius: 0.625rem !important;
  /* min-width: 20rem; */
  width: 100%;
  background: ${(props) => props.bgColor || "#384450"};
  /* background: red; */
  border-radius: 0rem 0rem 0.625rem 0.625rem;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  /* height: 20rem; */
  /* overflow-y: scroll; */
  transition: opacity 0.3s ease, transform 0.3s ease-in;
  /* padding-bottom:.5rem; */
  @media (max-width: ${breakpoints.mobile}) {
    background-color: transparent;
    box-shadow: none;
    margin-top: -4rem;
  }
`;
export const TimelineTop = styled.div<{ bgColor?: string }>`
  padding: 2rem 1.25rem;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.bgColor || "#384450"};
  border-radius: 0.625rem 0.625rem 0 0 !important;
  /* background: violet; */

  /* cursor: pointer; */
  @media (max-width: ${breakpoints.mobile}) {
    /* display: none; */
    padding-left: 0;
    background: transparent;
    /* font-size:1.25rem!important; */
    & > :last-child {
      display: none !important;
    }
  }
`;
export const TimeLineModules = styled.div<{ bgColor?: string }>`
  background: ${(props) => props.bgColor || " #576371"};
  /* background-color: green; */
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;

  & > :first-child {
    border-top: none;
  }
  & > :last-child {
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
  }
  @media (max-width: ${breakpoints.mobile}) {
    /* box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25); */
    background-color: transparent;
    row-gap: 0.62rem;
  }
`;

export const TimeLineTopicTopTile = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
word-wrap: nowrap;
  /* margin-bottom: -0.5rem; */
  /* border-bottom: 1px solid white; */
`;

export const SvgEditDiv = styled.div`
  & > :hover {
    color: #5dd3b3;
  }
`;

export const NoTopicAvailable = styled.div<{
  bgColor?: string;
  borderColor?: string;
}>`
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 10rem;
  gap: 1rem;
  align-items: center;
  /* border-radius: 0.625rem !important; */
  /* background-color: #384450; */
  background: ${(props) => props.bgColor || "#384450"};
  /* border-left: 2px solid ${(props) => props.borderColor || "#576371"};
  border-right: 2px solid ${(props) => props.borderColor || "#576371"}; */
  @media (max-width: ${breakpoints.mobile}) {
    border: none;
    margin-top: -0.9rem;
  }
`;