import React from "react";
import { AnalyticsUserViewCompo } from "../../components/AnalyticsUserViewCompo/AnalyticsUserViewCompo";
import EmptyView from "../../elements/EmptyView/EmptyView";
import { SvgEmptyAnalytics } from "../../elements/Icons";

export const AnalyticsUserView = (): JSX.Element => {
  return (
    <>
      <AnalyticsUserViewCompo />

      {/* <EmptyView
        icon={SvgEmptyAnalytics}
        text="no data analysis yet"
        ParentHeight="90%"
      /> */}
    </>
  );
};
