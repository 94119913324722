export const FilterData = [
  { type: "ESSAY", value: "ESSAY" },
  { type: "Matching", value: "MATCH" },
  { type: "TRUE/ FALSE", value: "TF" },
  { type: "MULTIPLE CHOICE", value: "MCQ" },
  { type: "NUMERICAL", value: "NUMERIC" },
  { type: "SHORT ANSWER", value: "SHORT_ANSWER" },
];
export const FilterBy = [
  { type: "question bank.Filter By User", value: "filteredByUser" },
  { type: "question bank.Filter By Class", value: "filteredByClass" }
];
