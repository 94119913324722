import React, { useEffect, useState } from "react";
import ScrollLeftAndRight from "../../../../../elements/ScrollLeftAndRight/ScrollLeftAndRight";
import {
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../../../../elements/fonts/Fonts";
import {
  GradeBookColumns,
  GradeBookMainTableContainer,
  GradeBookStudentCell,
  GradeBookTableTdHeader,
  GradebookHeadercontainer,
  Input,
  TableContentHolder,
  TableTd,
  TableTdTop,
  TableTr,
  RotatedArrow,
  ArrowHolder,
  GradeBookStudentCellTest,
} from "../../../GradeBookTable.style";
import { GradebookData } from "../NewGradebookData";
import {
  SvgDropDownArrow,
  SvgEyeIcon,
  SvgInsideout,
  SvgScale,
  SvgDoubleArrow,
} from "../../../../../elements/Icons";
import {
  GradeColorPicker,
  publishGrade,
} from "../../../../../utils/GradePointToLetter";
import {
  calculateTotalGrade,
  calculateWeightedScore,
  handleAttendancePopup,
  transformData,
  transformDataWithAttendance,
} from "../../GradebookFunctions";
import { FlexDiv } from "../../../../../globalStyles";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  ButtonAdd,
  ButtonCancel,
  ButtonsRow,
} from "../../../../GradesSchema/GradesSchema.style";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  ColorSelector,
  TableColorSelector,
} from "../../../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import BarChartsCompo from "../../../../Charts/BarCharts/BarCharts";
import AnalyticsAssignmentGraphChart from "../../../../AnalyticsAssigmentResponseTraking/AnalyticsAssigmentGraphChart/AnalyticsAssigmentGraphChart";
import { toArabicDigits } from "../../../../../utils/formatDayArabic";
import { adminState } from "../../../../../recoil/RecoilStates";
import EmptyAllSessions from "../../../../attendanceAllSessions/EmptyAllSession";

interface TransformedData {
  students: any[];
  MainActivity: {
    id: string;
    name: string;
    weight: any;
  }[];
}

interface SimpleTableProps {
  Data: TransformedData[];
  GradeType: string;
  filteredStudents: any; // Define the type of filteredStudents according to your data structure
  onViewAllGrades: () => void;
  onViewBelowAverageGrades: () => void;
  onViewFailingGrades: () => void;
  handleAttendancePopup: () => void;
  setSelectedStudentId: any;
  setNoinfo: React.Dispatch<React.SetStateAction<boolean>>;
  cardId?: String;
}

const SimpleTable: React.FC<SimpleTableProps> = ({
  cardId,
  Data,
  setNoinfo,
  GradeType,
  onViewAllGrades,
  onViewBelowAverageGrades,
  onViewFailingGrades,
  filteredStudents,
  handleAttendancePopup,
  setSelectedStudentId,
}) => {
  interface Student {
    id: string;
    name: string;
    activities: Activity[];
    attendance: {
      score: number;
      weight: number;
    }[];
  }
  interface Activity {
    id: string;
    name: string;
    grades: {
      name: string;
      id: string;
      score: number;
      weight: number;
    }[];
  }
  const [activityViewModes, setActivityViewModes] = useState<{
    [key: string]: boolean;
  }>({});
  const [gradeData, setGradeData] = useState<Student[] | null>(null);
  const [attendanceValues, setAttendanceValues] = useState<{
    [key: string]: number;
  }>({});
  const [Mainactivities, setMainActivities] = useState<any>([]);
  const [isAdmin] = useRecoilState(adminState);
  const [ShowSave, setShowSave] = useState<any>(false);
  const [allData, setAllData] = useState<any>({
    students: [],
    MainActivity: [],
  });
  const [allAttendanceData, setallAttendanceData] = useState<any>({
    students: [],
    MainActivity: [],
  });
  const tenantName = localStorage.getItem("tenant");
  const { SecondaryTextColor, bghelp, theme, MainColoredColor } =
    useRecoilValue(ColorSelector);
  const { ComplexTable_FirstHeadCell } = useRecoilValue(TableColorSelector);

  const id = useParams();
  const { t } = useTranslation();

  const [isScaleView, setIsScaleView] = useState<boolean>(true);

  const toggleView = (activityId: string) => {
    setActivityViewModes((prevState) => ({
      ...prevState,
      [activityId]: !prevState[activityId],
    }));
    setActivityVisibility((prevState) => ({
      ...prevState,
      [activityId]: !prevState[activityId],
    }));
  };
  // Define the type for activityVisibility
  type ActivityVisibility = {
    [key: string]: boolean;
  };
  const [AttendanceWeight, SetAttendanceWeight] = useState(0);
  // Initialize activityVisibility with an empty object
  const [activityVisibility, setActivityVisibility] =
    useState<ActivityVisibility>({});

  const getAttendanceScore = (studentId: string) => {
    const student = filteredStudents.find(
      (student: any) => student.id === studentId
    );
    return student?.attendance || 0; // Use 0 as default if attendance score is not available
  };
  const fetchGradebookData = async () => {
    try {
      if (cardId !== null && cardId !== undefined) {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/gradebook/grades/${tenantName}/${cardId}`
        );
        if (response.data.data.length === 0) {
          setNoinfo(true);
        }
        setGradeData(response.data.data);
      } else {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/gradebook/grades/${tenantName}/${id.classId}`
        );
        if (response.data.data.length === 0) {
          setNoinfo(true);
        }
        setGradeData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching gradebook data:", error);
    }
  };

  const fetchMainActivityData = async () => {
    try {
      if (cardId !== null && cardId !== undefined) {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/activities/weight/${cardId}/${tenantName}`
        );
        setMainActivities(response.data.data);
        SetAttendanceWeight(response.data.data[3].Attendance.Weight);
        if (response.data.data.length === 0) {
          setNoinfo(true);
        }
      } else {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/activities/weight/${id.classId}/${tenantName}`
        );
        setMainActivities(response.data.data);
        SetAttendanceWeight(response.data.data[3].Attendance.Weight);
        if (response.data.data.length === 0) {
          setNoinfo(true);
        }
      }
    } catch (error) {
      console.error("Error fetching main activity data:", error);
    }
  };

  const transformedData = transformData(Mainactivities, gradeData);

  const [filters, SetFilters] = useState<any[]>([]);

  const getFilters = async () => {
    try {
      if (cardId !== null && cardId !== undefined) {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/grades/filter/get/${cardId}/${tenantName}`
        );
        SetFilters(response.data.data);
      } else {
        const response = await axios.get(
          `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/grades/filter/get/${id.classId}/${tenantName}`
        );
        SetFilters(response.data.data);
      }
      // Handle the response data as needed
    } catch (error) {
      console.error("There was an error making the GET request!", error);
    }
  };

  useEffect(() => {
    fetchGradebookData();
    fetchMainActivityData();
    getFilters();
  }, []);

  useEffect(() => {
    setAllData(transformedData);
  }, [Mainactivities, gradeData]);

  useEffect(() => {
    const initializeAttendanceValues = () => {
      const initialAttendanceValues: { [key: string]: number } = {};
      // Initialize attendance score for each student from filteredStudents
      allData.students.forEach((student: any) => {
        initialAttendanceValues[student.id] = student?.attendance
          ? student.attendance
          : 0;
      });
      // Update the state with initial attendance values
      setAttendanceValues(initialAttendanceValues);
    };

    if (allData.students.length > 0) {
      initializeAttendanceValues();
    }
    handleSaveGrades();
  }, [allData]);

  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortedStudents, setSortedStudents] = useState(filteredStudents);
  const [isSorted, setIsSorted] = useState<boolean>(false);
  const [GraphData, SetGraphData] = useState<any[]>([]);
  // Function to toggle sorting order

  // Function to toggle sorting order
  const toggleSortOrder = () => {
    setSortOrder((prevSortOrder) => (prevSortOrder === "asc" ? "desc" : "asc"));
  };

  useEffect(() => {
    setSortedStudents(filteredStudents);
  }, [filteredStudents]);

  //function to sort student by alphabetical order

  const toggleSort = () => {
    setIsSorted((prevIsSorted) => !prevIsSorted);
  };

  const sortAlphaStudents = () => {
    if (isSorted) {
      // If already sorted, revert to filteredStudents
      setSortedStudents(filteredStudents);
    } else {
      // Sort alphabetically
      const sorted = [...filteredStudents].sort((a, b) => {
        const nameA = a.name.toUpperCase(); // Convert names to uppercase for case-insensitive comparison
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1; // If nameA comes before nameB alphabetically
        }
        if (nameA > nameB) {
          return 1; // If nameA comes after nameB alphabetically
        }
        return 0; // If names are equal
      });
      setSortedStudents(sorted);
    }
  };

  useEffect(() => {
    sortAlphaStudents();
  }, [isSorted]);

  // Function to sort the students based on grade score
  const sortStudents = (activityId: string) => {
    const sorted = [...filteredStudents].sort((a, b) => {
      const getTotalScore = (
        student: { activities: any[] },
        activityId: string
      ) => {
        const activity = student.activities.find(
          (activity: { id: any }) => activity.id === activityId
        );
        if (activity && activity.grades) {
          return activity.grades.reduce(
            (sum: any, grade: { score: any }) => sum + grade.score,
            0
          );
        }
        return 0;
      };

      const scoreA = getTotalScore(a, activityId);
      const scoreB = getTotalScore(b, activityId);

      return sortOrder === "asc" ? scoreA - scoreB : scoreB - scoreA;
    });

    setSortedStudents(sorted);
  };

  // Function to handle change in the attendance input value
  const handleAttendanceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    studentId: string
  ) => {
    setShowSave(true);
    const value = Number(event.target.value); // Convert the input value to a number

    // Update the attendance score for the corresponding student in allData.students
    setAllData((prevData: TransformedData) => ({
      ...prevData,
      students: prevData.students.map((student: Student) => {
        if (student.id === studentId) {
          // Update attendance for the specific student
          return { ...student, attendance: value };
        }
        // Keep the attendance score unchanged for other students
        return student;
      }),
    }));

    // Update the attendance score for the corresponding student in attendanceValues
    setAttendanceValues((prevAttendanceValues) => ({
      ...prevAttendanceValues,
      [studentId]: value,
    }));
  };
  const handleCancel = () => {
    fetchGradebookData();
    fetchMainActivityData();
    setAllData(transformData);
    setShowSave(false);
  };
  const saveAttendanceData = async () => {
    try {
      const isValidAttendance = Object.values(attendanceValues).every(
        (value) => value >= 0 && value <= 100
      );

      if (!isValidAttendance) {
        toast.error("Attendance value must be between 0 and 100.");
        return;
      }
      // Iterate through each student's attendance data and prepare the payload
      const attendancePayload = {
        data: {
          Attendance: allData.students.map((student: any) => ({
            studentId: student.id,
            classid: cardId ? cardId : id.classId, // Assuming `id.classId` contains the class ID
            score: attendanceValues[student.id] || 0, // Use attendance value from state or default to 0
          })),
        },
      };

      // Make an HTTP POST request to the API endpoint with the attendance data
      const response = await axios.post(
        `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/attendance/grade/create/${tenantName}`,
        attendancePayload
      );

      // Update attendance values for all students with the latest values from the state
      const updatedAttendanceValues: { [key: string]: number } = {};
      allData.students.forEach((student: any) => {
        updatedAttendanceValues[student.id] = attendanceValues[student.id] || 0;
      });
      setAttendanceValues(updatedAttendanceValues);

      toast.success("Attendance added successfully");
      setShowSave(false);
    } catch (error) {
      console.error("Error saving attendance data:", error);
      toast.error("Error saving attendance data:");
    }
  };

  const postFinalGrades = async (gradesData: any[]) => {
    const url = `https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/course/finalGrade/create/${tenantName}`;

    try {
      const response = await axios.post(
        url,
        { data: gradesData },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Handle the response as needed
    } catch (error) {
      console.error("Error making POST request:", error);
      // Handle the error as needed
    }
  };

  const collectGradesData = (
    students: any[],
    classSourceId: string | undefined,
    allData: any,
    filters: any
  ) => {
    return students.map((student) => ({
      classSourceId,
      userSourceId: student.id,
      finalGrade: calculateTotalGrade(student.id, allData, filters),
    }));
  };

  const handleSaveGrades = async () => {
    const gradesData = collectGradesData(
      filteredStudents,
      id.classId,
      allData,
      filters
    );
    await postFinalGrades(gradesData);
  };

  const transformGradeData = (data: any): any[] => {
    return data.map((item: { userSourceId: any; finalGrade: any }) => ({
      StdCode: item.userSourceId,
      StdName: "John Doe",
      Grade: item.finalGrade,
    }));
  };

  useEffect(() => {
    const gradesData = collectGradesData(
      filteredStudents,
      id.classId,
      allData,
      filters
    );

    const transformed = transformGradeData(gradesData);
    SetGraphData(transformed);
  }, []);

  const language = localStorage.getItem("language");

  function getFilterType(activityId: any) {
    const filter = filters.find((f) => f.activityType === activityId);
    return filter && filter.filter ? filter.filter : "manual";
  }

  const calculatedGrade = (grade: number, maxGrade: number): number => {
    return grade * (100 / maxGrade);
  };

  return (
    <>
      {allData?.students[0]?.activities.length > 0 ? (
        <>
          <GradeBookMainTableContainer>
            <GradeBookColumns>
              <GradeBookStudentCell theme={theme} language={language} />
              <GradeBookStudentCell theme={theme}>
                <Typography text={t("gradebook.Student ID")} />
              </GradeBookStudentCell>
              {sortedStudents.map((student: any) => (
                <GradeBookStudentCell key={student.id} theme={theme}>
                  <TypographyRegularDescription
                    text={student.id}
                    fontSize="1rem"
                  />
                </GradeBookStudentCell>
              ))}
            </GradeBookColumns>

            <GradeBookColumns>
              <GradeBookStudentCellTest theme={theme} />
              <GradeBookStudentCell
                theme={theme}
                style={{ justifyContent: "space-between" }}
              >
                <Typography text={t("gradebook.Student Name")} />

                {/* <ArrowHolder> */}
                <div style={{ cursor: "pointer" }}>
                  <SvgDoubleArrow
                    onClick={() => {
                      toggleSort();
                    }}
                    width={"8px"}
                    height={"10px"}
                  />
                </div>
                {/* </ArrowHolder> */}
              </GradeBookStudentCell>
              {sortedStudents.map((student: any) => (
                <GradeBookStudentCell key={student.id} theme={theme}>
                  <TypographyRegularDescription
                    text={student.name}
                    fontSize="1rem"
                  />
                </GradeBookStudentCell>
              ))}
            </GradeBookColumns>
            <ScrollLeftAndRight
              justifyContent={language === "ar" ? "left" : "right"}
              Width="100%"
              LineWidth="100%"
              position="fixed"
              content={
                <TableContentHolder>
                  <TableTr
                    style={{
                      background:
                        theme === "light"
                          ? "#AAB1B940"
                          : language === "ar" && theme === "dark"
                          ? "linear-gradient(90deg, #293440 0%, #415160  107.88%"
                          : "linear-gradient(90deg, #415160 0%, #293440 107.88%",
                    }}
                  >
                    {/* Generating headers for each activity */}
                    {allData?.MainActivity.map(
                      (activity: {
                        id: string;
                        name: string;
                        weight: number;
                      }) => {
                        // Add the weight validation

                        // Calculate the colspan based on the number of grades
                        const matchingActivity =
                          filteredStudents[0]?.activities.find(
                            (studentActivity: { id: string }) =>
                              studentActivity.id === activity.id
                          );
                        const filterType = getFilterType(activity.id);
                        const colspan = matchingActivity
                          ? matchingActivity.grades.filter(
                              (grade: { weight: number }) =>
                                (grade.weight > 0 && filterType === "manual") ||
                                filterType !== "manual"
                            ).length + 1
                          : 1;

                        return (
                          <GradeBookTableTdHeader
                            key={activity.id}
                            colSpan={
                              activityVisibility[activity.id] ? colspan : 1
                            }
                            theme={theme}
                          >
                            <GradebookHeadercontainer>
                              <MediumTypography
                                text={t(`gradebook.${activity?.name}`)}
                                fontSize="1rem"
                              />
                              {activityViewModes[activity.id] ? (
                                <SvgInsideout
                                  onClick={() => toggleView(activity.id)}
                                />
                              ) : (
                                <SvgScale
                                  onClick={() => toggleView(activity.id)}
                                />
                              )}
                            </GradebookHeadercontainer>
                          </GradeBookTableTdHeader>
                        );
                      }
                    )}

                    {AttendanceWeight > 0 && (
                      <GradeBookTableTdHeader theme={theme}>
                        <MediumTypography text={t("gradebook.Attendance")} />
                      </GradeBookTableTdHeader>
                    )}
                    <TableTd
                      style={{
                        minWidth: "13rem",
                        width: "fit-content",
                      }}
                    >
                      <MediumTypography
                        text={t("gradebook.Course Final Grade")}
                      />
                    </TableTd>
                  </TableTr>
                  {/* Rest of your table content */}
                  <TableTr>
                    {/* Mapping through activities */}
                    {allData?.MainActivity.map(
                      (activity: { id: string; name: any; weight: any }) => {
                        // Get the filter type for the current activity
                        const filterType = getFilterType(activity.id);
                        // Check if the filter type is "manual" and apply the weight condition accordingly

                        return (
                          <React.Fragment key={activity.id}>
                            {/* Mapping through grades of each activity */}
                            {filteredStudents[0]?.activities
                              .find(
                                (act: { id: string }) => act.id === activity.id
                              )
                              ?.grades.map(
                                (grade: {
                                  id: React.Key | null | undefined;
                                  name: string;
                                  weight: number;
                                }) =>
                                  grade.weight > 0 &&
                                  filterType === "manual" ? (
                                    <React.Fragment key={grade.id}>
                                      {activityVisibility[activity.id] && (
                                        <TableTd>
                                          <FlexDiv>
                                            <MediumTypography
                                              text={grade.name}
                                            />
                                            <SvgDoubleArrow
                                              onClick={() => {
                                                toggleSortOrder();
                                                sortStudents(activity.id);
                                              }}
                                              width={"8px"}
                                              height={"10px"}
                                            />
                                          </FlexDiv>
                                        </TableTd>
                                      )}
                                    </React.Fragment>
                                  ) : null || filterType !== "manual" ? (
                                    <React.Fragment key={grade.id}>
                                      {activityVisibility[activity.id] && (
                                        <TableTd>
                                          <FlexDiv>
                                            <MediumTypography
                                              text={grade.name}
                                            />
                                            <SvgDoubleArrow
                                              onClick={() => {
                                                toggleSortOrder();
                                                sortStudents(activity.id);
                                              }}
                                              width={"8px"}
                                              height={"10px"}
                                            />
                                          </FlexDiv>
                                        </TableTd>
                                      )}
                                    </React.Fragment>
                                  ) : null
                              )}

                            {/* Total for the activity */}
                            <TableTdTop key={`total_${activity.id}`}>
                              <MediumTypography
                                text={`${t("gradebook.Total")} ${t(
                                  `gradebook.${activity?.name}`
                                )}`}
                              />
                            </TableTdTop>
                          </React.Fragment>
                        );
                      }
                    )}

                    {AttendanceWeight > 0 && (
                      <TableTdTop>
                        <MediumTypography
                          text={t("gradebook.Attendance Total")}
                          fontSize="1rem"
                        />
                      </TableTdTop>
                    )}
                  </TableTr>

                  {sortedStudents?.map((student: any) => (
                    <TableTr theme={theme} key={student.id}>
                      {/* Mapping through each student's activities */}
                      {allData.MainActivity.map(
                        (activity: { weight: any; id: string }) => {
                          const studentActivity = student.activities.find(
                            (act: { id: string }) => act.id === activity.id
                          );
                          // Get the filter type for the current activity
                          const filterType = getFilterType(activity.id);
                          // Determine whether to apply the weight condition based on the filter type
                          return (
                            <React.Fragment
                              key={`${student.id}_${activity.id}`}
                            >
                              {/* Mapping through grades of each activity */}
                              {studentActivity &&
                                activityVisibility[activity.id] &&
                                studentActivity.grades.map(
                                  (grade: {
                                    maxGrade: number;
                                    id: any;
                                    score: number;
                                    gradedByInstructor: boolean;
                                    weight: any;
                                  }) =>
                                    (grade.weight > 0 &&
                                      filterType === "manual" && (
                                        <TableTd
                                          key={`${student.id}_${grade.id}`}
                                        >
                                          <MediumTypography
                                            text={`${
                                              grade.gradedByInstructor
                                                ? GradeType === "Point"
                                                  ? toArabicDigits(
                                                      calculatedGrade(
                                                        grade.score,
                                                        grade.maxGrade
                                                      )
                                                        .toFixed(2)
                                                        .toString()
                                                    )
                                                  : publishGrade(
                                                      grade.score *
                                                        (100 / grade.maxGrade)
                                                    )
                                                : "-"
                                            }`}
                                            color={
                                              grade.gradedByInstructor
                                                ? GradeColorPicker(
                                                    grade.score *
                                                      (100 / grade.maxGrade)
                                                  )
                                                : "gray"
                                            }
                                            fontSize="0.75rem"
                                          />
                                        </TableTd>
                                      )) ||
                                    (filterType !== "manual" && (
                                      <TableTd
                                        key={`${student.id}_${grade.id}`}
                                      >
                                        <MediumTypography
                                          text={`${
                                            grade.gradedByInstructor
                                              ? GradeType === "Point"
                                                ? toArabicDigits(
                                                    calculatedGrade(
                                                      grade.score,
                                                      grade.maxGrade
                                                    )
                                                      .toFixed(2)
                                                      .toString()
                                                  )
                                                : publishGrade(
                                                    grade.score *
                                                      (100 / grade.maxGrade)
                                                  )
                                              : "-"
                                          }`}
                                          color={
                                            grade.gradedByInstructor
                                              ? GradeColorPicker(
                                                  grade.score *
                                                    (100 / grade.maxGrade)
                                                )
                                              : "gray"
                                          }
                                          fontSize="0.75rem"
                                        />
                                      </TableTd>
                                    ))
                                )}

                              {/* Calculate and display total for each activity for the student */}
                              {studentActivity && (
                                <TableTdTop
                                  key={`${student.id}_total_${activity.id}`}
                                >
                                  <TypographyRegularDescription
                                    text={`${
                                      GradeType === "Point"
                                        ? toArabicDigits(
                                            calculateWeightedScore(
                                              student.id,
                                              activity.id,
                                              allData,
                                              filters
                                            )
                                              .toFixed(2)
                                              .toString()
                                          )
                                        : publishGrade(
                                            calculateWeightedScore(
                                              student.id,
                                              activity.id,
                                              allData,
                                              filters
                                            )
                                          )
                                    }`}
                                    color={GradeColorPicker(
                                      studentActivity.grades.reduce(
                                        (total: any, grade: { score: any }) =>
                                          total + grade.score,
                                        0
                                      )
                                    )}
                                    fontSize="0.75rem"
                                  />
                                </TableTdTop>
                              )}
                            </React.Fragment>
                          );
                        }
                      )}

                      {AttendanceWeight > 0 && (
                        <TableTdTop>
                          {isAdmin === false ? (
                            <Input
                              bordercolor={MainColoredColor}
                              key={student.id}
                              value={attendanceValues[student.id]} // Bind input value to the attendance score of the student from the state
                              onChange={(event) =>
                                handleAttendanceChange(event, student.id)
                              }
                              style={{
                                color: GradeColorPicker(
                                  attendanceValues[student.id]
                                ),
                              }}
                            />
                          ) : (
                            <Input
                              bordercolor={MainColoredColor}
                              key={student.id}
                              value={attendanceValues[student.id]} // Bind input value to the attendance score of the student from the state
                              // onChange={(event) => handleAttendanceChange(event, student.id)}
                              disabled={true}
                              style={{
                                color: GradeColorPicker(
                                  attendanceValues[student.id]
                                ),
                              }}
                            />
                          )}
                          {!cardId && (
                            <SvgEyeIcon
                              onClick={() => {
                                handleAttendancePopup();
                                setSelectedStudentId(student.id);
                              }}
                            />
                          )}
                        </TableTdTop>
                      )}

                      <TableTd>
                        <TypographyRegularDescription
                          text={`${
                            GradeType === "Point"
                              ? toArabicDigits(
                                  calculateTotalGrade(
                                    student.id,
                                    allData,
                                    filters
                                  ).toString()
                                )
                              : publishGrade(
                                  calculateTotalGrade(
                                    student.id,
                                    allData,
                                    filters
                                  )
                                )
                          }`}
                          fontSize="0.75rem"
                          color={GradeColorPicker(
                            calculateTotalGrade(student.id, allData, filters)
                          )}
                        />
                      </TableTd>
                    </TableTr>
                  ))}
                </TableContentHolder>
              }
            />
          </GradeBookMainTableContainer>
          {ShowSave && (
            <ButtonsRow>
              <ButtonAdd onClick={() => saveAttendanceData()}>
                <Typography
                  text={t("forms.Save")}
                  color="#fff"
                  fontSize="0.875rem"
                  fontWeight="400"
                  lineHeight="normal"
                />
              </ButtonAdd>
              <ButtonCancel onClick={() => handleCancel()}>
                <Typography
                  text={t("forms.Cancel")}
                  color="#D85D66"
                  fontSize="0.875rem"
                  fontWeight="400"
                  lineHeight="normal"
                />
              </ButtonCancel>
            </ButtonsRow>
          )}

          {/* <BarChartsCompo
          dataKeyA="val"
          data={ mappedChartData }
          dataKeyText="Name"
          avg="avg"
        /> */}

          {/* <AnalyticsAssignmentGraphChart
            title="Student Graph"
            Data={GraphData}
            MaxGrade={ 100}
          /> */}
        </>
      ) : (
        <EmptyAllSessions />
      )}
    </>
  );
};

export default SimpleTable;
