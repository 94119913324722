import { useState, useEffect } from "react";
import About_HeroSection from "../../../components/about_HeroSection/main/About_HeroSection";
import CourseSchedule from "../../../components/courseSchedule/CourseSchedule";
import CourseMoreDetails from "../../../components/courseMoreDetails/CourseMoreDetails";
import CoursTags from "../../../components/coursTags/main/CoursTags";
import CourseInfo from "../../../components/courseInfo/CourseInfo";
import Loader from "../../../components/loader/Loader";
import LoaderText from "../../../components/loader/loaderText/LoaderText";
import { PageHeader } from "../../../components/pageHeader/PageHeader";
import { breakpoints } from "../../../theme";
import SliderTabs from "../../../components/courseBanner/SliderTabs";
import axios from "axios";
import { useParams } from "react-router-dom";
import { S3Get } from "../../../services/S3Get";

import { useRecoilValue } from "recoil";
import { courseBannerColorSelector } from "../../../recoil/ThemeSelectors";

import {
  CourseViewLayout,
  CourseInfoHolder,
  CourseDetailsHolder,
  CourseViewLayoutLoader,
} from "./AboutCourseView.style";
import { MediumTypography, Typography } from "../../../elements/fonts/Fonts";
import { useLocation } from "react-router";
import { useRole } from "../../../RoleProvider";
import { LoadingComp } from "../../../components/LoadingComp/LoadingComp";
import { SvgNoResult, SvgNotificationIcon } from "../../../elements/Icons";
import CourseNotificationCard from "../../../components/CorseNotification/CourseNotificationCard/CourseNotificationCard";
import PopUp from "../../../elements/Popup/PopUp";
import CorseNotificationPopUp from "../../../components/CorseNotification/CorseNotificationPopUp/CorseNotificationPopUp";
import { CourseNotificationInterface } from "../../../components/CorseNotification/CourseNotificationIterface";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const CourseView = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const role = queryParams.get("role");
  const { userRole, setRole } = useRole();
  if (role !== null) {
    setRole(role);
  } else {
    setRole("student");
  }

  const { t } = useTranslation();

  const classSourceId = useParams();
  const [classData, setClassData] = useState<any>();
  const [ParentClassData, setParentClassData] = useState<any>();
  const setClassSourceId = classSourceId.classId;

  const [classAboutData, setClassAboutData] = useState<any>();
  const [selectedOption, setSelectedOption] = useState<
    CourseNotificationInterface[]
  >([]);

  const BaseURI = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";

  const { courseBannerBgColor, boxShadow } = useRecoilValue(
    courseBannerColorSelector
  );

  const getAboutClassData = async (classSourceId: string) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/class/about/${classSourceId}/${tenantName}`
        // `${BaseURI}/about/class/${classSourceId}/${tenantName}`
      );
      if (!Array.isArray(res.data.data)) {
        toast.error(res.data.data);
      }

      setClassAboutData(res.data.data[0]);
      setParentClassData(res.data.data[0].parentClass);
      setClassData(res.data.data[0].classData);
      setClassStructure(res.data.data[0].classStructure);
      setPicture(res.data.data[0].parentClass.picture);
      setSyllabus(res.data.data[0].parentClass.syllabus);
      setCourse(res.data.data[0].course);
      setCourseObjectives(res.data.data[0].courseObjectives);
      setTeacher(res.data.data[0].teacher);

      setDataLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [classStructure, setClassStructure] = useState<any[]>([]);
  const [course, setCourse] = useState({});
  const [picture, setPicture] = useState("");
  const [syllabus, setSyllabus] = useState("");
  const [courseObjectives, setCourseObjectives] = useState<any[]>([]);
  const [teacher, setTeacher] = useState<any[]>([]);
  const [ShowSharePopUp, setShowSharePopUp] = useState(false);

  const handleShowNotificationPopUp = () => {
    setShowSharePopUp(!ShowSharePopUp);
  };
  useEffect(() => {
    if (setClassSourceId) {
      getAboutClassData(setClassSourceId);
      // console.log("classAboutData: ", classAboutData);
      // setClassData(classAboutData.classData);
      // setClassStructure(classAboutData.classStructure);
      // setCourse(classAboutData.course);
      // setCourseObjectives(classAboutData.courseObjectives);
      // setTeacher(classAboutData.teacher);
      setIsLoading(false);
    }
  }, []);

  const [bgImage, setBgImage] = useState("");
  const [syllabusFile, setSyllabusFile] = useState("");

  const fetchFiles = async (path: any, syllabus: any) => {
    const result = await S3Get(path);
    const result2 = await S3Get(syllabus);
    setBgImage(result);
    setSyllabusFile(result2);
  };

  useEffect(() => {
    if (!isLoading) {
      fetchFiles(picture, syllabus);
      // console.log("s3 image", bgImage);
    }
  }, [picture, bgImage, syllabus]);

  if (dataLoading) {
    return <LoadingComp />;
  }
  // console.log("class  title is:", classData);

  return (
    <>
      {/* {windowWidth <= parseInt(breakpoints.mobile) && <SliderTabs />} */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1.25rem",
        }}
      >
        <Typography
          text={t("course view.Class View")}
          fontSize="1.75rem"
          lineHeight="0"
        />
        {ParentClassData && ParentClassData.classTitle && (
          <MediumTypography
            text={ParentClassData.classTitle || ""}
            color="#5DD3B3"
            fontSize="1rem"
          />
        )}
      </div>
      <CourseViewLayout>
        {/* {windowWidth > parseInt(breakpoints.mobile) && <SliderTabs />} */}
        {isLoading ? (
          // <div style={{ marginLeft: "80%" }}>
          <CourseViewLayoutLoader>
            <Loader />
          </CourseViewLayoutLoader>
        ) : (
          // </div>
          // <p>Loading...</p>
          <>
            {/* <About_HeroSection
              syllabus={syllabusFile}
              bgimage={picture}
              course={course}
              classStructure={classStructure}
              isLoading={isLoading}
            /> */}
            <About_HeroSection
              syllabus={syllabusFile}
              bgimage={bgImage}
              course={ParentClassData}
              classStructure={classStructure}
              isLoading={isLoading}
            />

            <CourseInfoHolder
              bgcolor={courseBannerBgColor}
              boxshadow={boxShadow}
            >
              <CourseInfo
                classSourceId={setClassSourceId}
                classStructure={classStructure}
                courseObjectives={courseObjectives}
                teacher={teacher}
                ParentClassData={ParentClassData}
              />
            </CourseInfoHolder>
            <CourseDetailsHolder>
              {/* <CourseNotificationCard onClick={handleShowNotificationPopUp} /> */}
              <CourseSchedule classData={classData} />
              <CourseMoreDetails classData={classData} />
              <CoursTags />
            </CourseDetailsHolder>
          </>
        )}
        {ShowSharePopUp && course && (
          <PopUp
            content={
              <CorseNotificationPopUp
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            }
            showline={false}
            show={ShowSharePopUp}
            setShow={setShowSharePopUp}
            // paddingRight="1.25rem"
            // paddingTop="1.25rem"
            // paddingBottom="0"
            justifyContent="flex-end"
            title={t("User Profile.Notifications Settings")}
            icon={<SvgNotificationIcon />}
          />
        )}
      </CourseViewLayout>
    </>
  );
};

export default CourseView;
