import styled from "styled-components";
import { breakpoints } from '../../theme';

export interface AddGRoupMembersBarProps {
    isVisible: boolean; // Define the prop here
    isOpen: boolean
  }
export const GroupRectangle = styled.div<AddGRoupMembersBarProps>`
    width: 18.5625rem;
height: 54.0625rem;
border-radius: 0.625rem;
background: #384450;
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
display: grid;
grid-template-columns: 6.9375rem 6.9375rem;
grid-auto-rows: minmax(4rem, auto);
align-content: start; /* or adjust to your desired alignment */
justify-content: center;
column-gap: 1.56rem;
row-gap: 2.1rem;
padding-top: 1.88rem;
padding: 1.88rem 1.5rem 1.88rem 1.5rem;
@media (max-width: ${breakpoints.tablets}) {
    display: ${(props) => (props.isVisible ? 'flex' : 'none')};
    flex-direction:column;
    /* position: absolute;
    top: -10rem;
    left: -7.5rem; */
    width: 100vw;
height: 100vh;
align-items: center;
gap: 0.63rem;
overflow-y: scroll;
padding-top: 38rem;
}
`


export const TitleRow = styled.div`
display: none;

@media (max-width: ${breakpoints.tablets}) {
display: flex;
align-items: flex-start;
width: 100%;
margin-left: 1.31rem;
margin-bottom: 1.15rem;
gap: 0.4rem;
}
`
export const IconRow = styled.div`

display: none;
@media (max-width: ${breakpoints.tablets}) {
display: flex;
align-items: flex-start;
width: 100%;
margin-left: 1.31rem;
/* margin-bottom: 2.19rem; */

}
`
export const DoneButtonResp = styled.button`
display: none;

@media (max-width: ${breakpoints.tablets}) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21.875rem;
height: 3.125rem;
flex-shrink: 0;
border-radius: 6.25rem;
background: #5DD3B3;
margin-top: 5rem;
margin-bottom: 1.25rem;
outline: none;
border: none;
}
`