import React from "react";
import { FourOFourPageCompo } from "../../components/FourOFour/FourOFourPageCompo";


export const FouroFourPageView = (): JSX.Element => {
  return (
    <>
    <FourOFourPageCompo />
      
    </>
  );
};
