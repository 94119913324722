import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const CardHolders = styled.div`
display:flex;
/* background-color:rgba(56, 68, 80, 1); */
height:8rem;
width:10rem;
margin:0.5rem;
border-radius:0.625rem;
justify-content:center;
align-items:center;
`


export const Container = styled.div<{active?: boolean, theme?: string}>`
width:100%;
background: ${props => props.active ? "transparent" : props.theme === "dark" ? "linear-gradient(180deg, #415160 0%, #293440 100%)" : "#fff"};
height:auto;
border-radius: 0.625rem;
padding:1.25rem;
display:flex;
justify-content:space-between;
align-items:center;
cursor: pointer;
`

export const InsideContainer = styled.div`
height:auto;
padding-bottom: 1.25rem;
`


export const MainContainer = styled.div`
/* background-color:#415160; */
border-radius: 0.625rem;
margin-bottom:0.63rem;
`
export const FilesParent = styled.div`
display: flex;
flex-direction: column;
gap: 0.63rem;
/* padding-bottom: 1.25rem; */
`

export const FileContainer = styled.div`
padding:0rem 1.25rem ;
/* margin:0.3rem; */
display:flex;
justify-content:space-between;

`
export const FileFolderContainer = styled.div`
display:flex;
justify-content:space-between;

`

export const RightSide = styled.div`
gap:1rem;

`

export const FolderContainer=styled.div<StyleThemeInterface>`
background: ${props => props.theme === "dark" ? "rgba(255, 255, 255, 0.03)" : "rgba(170, 177, 185, 0.1)"};
/* margin-top:0.2rem; */
margin: 0 1.25rem 0.62rem 1.25rem;
border-radius:0.625rem;
`

export const FlexedDiv=styled.div<StyleThemeInterface>`
align-items:center;
display:flex;
gap:0.69rem;
padding:0.44rem 1.25rem;
background: ${props => props.theme === "dark" ? "rgba(255, 255, 255, 0.03)" : "rgba(170, 177, 185, 0.1)"};
width:100%;
border-radius: 0.625rem;
justify-content:space-between;
`
export const FlexedDivFiles=styled.div<StyleThemeInterface>`
align-items:center;
display:flex;
gap:0.69rem;
padding:0.44rem 1.25rem;
background: ${props => props.theme === "dark" ? "rgba(255, 255, 255, 0.03)" : "#fff"};
width:100%;
border-radius: 0.625rem;
justify-content:space-between;
`
export const ParentFolderFiles=styled.div<StyleThemeInterface>`
display: flex;
flex-direction: column;
padding: 1.25rem;
gap: 0.62;
`
export const FlexedDiv2=styled.div<{active?: boolean, theme?: string}>`
align-items:center;
display:flex;
justify-content:space-between;
gap:0.69rem;
padding: ${props => props.active ? "1.25rem 1.25rem 0 1.25rem" : "0.44rem 1.25rem"};
background: ${props => props.active ? "transparent" : props.theme === "dark" ?"linear-gradient(180deg, #415160 0%, #293440 100%)" : ""};
width:100%;
border-radius: 0.625rem;
cursor: pointer;
`

export const FlexedDiv3=styled.div`
display:flex;
padding:0;
gap:0.69rem;
align-items:center;
cursor: pointer;
`


export const FileIconDiv=styled.div<StyleThemeInterface>`

background: ${props => props.theme === "dark" ? "linear-gradient(180deg, #415160 0%, #293440 100%)" : "#fff"};
width: 2.4375rem;
height: 2.5625rem;
display: flex;
justify-content: center;
align-items: center;
border-radius:0.625rem;
`
export const FolderFileIconDiv=styled.div<StyleThemeInterface>`

background: ${props => props.theme === "dark" ? "linear-gradient(180deg, #415160 0%, #293440 100%)" : "rgba(170, 177, 185, 0.1)"};
width: 2.4375rem;
height: 2.5625rem;
display: flex;
justify-content: center;
align-items: center;
border-radius:0.625rem;
`

export const FolderDiv=styled.div<StyleThemeInterface>`
width: 2.4375rem;
height: 2.5625rem;
display: flex;
justify-content: center;
align-items: center;
background-color: ${props => props.theme === "dark" ? "rgba(217, 217, 217, 0.2)" : "#fff"};
border-radius:0.625rem;

`


export const ChannelHolder=styled.div`


`