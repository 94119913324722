import React, { useContext, useEffect, useState } from "react";
// import { ParticipantData } from "../ParticipantData/ParticipantData";
import { FlexAppContainer } from "../../../App.style";
import { Typography } from "../../../elements/fonts/Fonts";
import ParticipantInstructorsCard from "../ParticipanInstructorsCard/ParticipantInstructorsCard";
import { ParticipantInstructorsTextPlace } from "./ParticipantInstructors.style";
import MappedIndexClickOutSide from "../../../hooks/MapedIndexClickOutSide";
import MenuOptionTeacher from "../ParticipanInstructorsCard/MenueOption/MenueOptionTeacher";
import MenuOptionStudents from "../ParticipanInstructorsCard/MenueOption/MenueOptionStudents";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter } from "../../../utils/StringManipulation";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import { handleSelectedIndex } from "../../../utils/Function";
import PopUp from "../../../elements/Popup/PopUp";
import { SvgAccommodations, SvgNoResult } from "../../../elements/Icons";
import AccommodationsPopUp from "../AccommodationsPopUp/AccommodationsPopUp";
import { ParticipantDataInterface } from "../ParticipantData/ParticipantDataInterface";
import { useParams } from "react-router-dom";
import { get } from "../../../utils/AxiosRequests";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

const ParticipantInstructors = () => {
  // const { user } = useAuth0();
  // const subId = auth0SubIdSplitter(user?.sub || "");
  const { user } = useAuth0();
  const { t } = useTranslation();
  const HasanEmail = user?.email;

  const [participantData, setParticipantData] = useState<any[]>([]);
  const { role } = useContext(ClassContext);

  const params = useParams();
  const classId = params.classId;
  const tenantName = localStorage.getItem("tenant");
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const fetchParticipantData = async () => {
    try {
      const ParticipantData = await get(
        `participant/class/${classId}/${tenantName}`
      );
      setDataLoading(false);

      // Filter participants based on role
      if (ParticipantData.data.length > 0) {
        setParticipantData(ParticipantData.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const studentsData = participantData.filter(
    (participant) => participant.role === "Student"
  );
  const teachersData = participantData.filter(
    (participant) => participant.role === "Teacher"
  );

  const [SelectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [SelectedIndexStudent, setSelectedIndexStudent] = useState<
    number | null
  >(null);

  const clickOutsideRef = MappedIndexClickOutSide(() => setSelectedIndex(null));
  const clickOutsideRefStd = MappedIndexClickOutSide(() =>
    setSelectedIndexStudent(null)
  );

  const ShowMoreOptionFunc = (subId: any, id: any): boolean => {
    return subId !== id;
  };

  const [ShowAccommodations, setShowAccommodations] = useState(false);
  const toggleShowAccommodations = () => {
    setShowAccommodations((prevState) => !prevState);
  };
  const [SelectedItems, setSelectedItems] = useState<any[]>([]);

  useEffect(() => {
    fetchParticipantData();
  }, []);
  if (dataLoading) {
    // if data is Loading
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SvgNoResult width={"10rem"} />
          <Typography text={`${t("general.loading")}...`} />
        </div>
      </>
    );
  }

  return (
    <FlexAppContainer column={true} gap="5rem" padding="0 0 1.3rem 0">
      <FlexAppContainer column={true} gap="1.25rem">
        <Typography text={t("participants.Instructors")} fontSize="1.75rem" />
        <FlexAppContainer wrap={true} gap="2.5rem">
          {teachersData.map((teacher, index) => (
            <ParticipantInstructorsCard
              ContainerRef={clickOutsideRef}
              key={index}
              Picture={
                teacher.profilePicture ||
                require("../../../assets/userProfile.png")
              }
              Name={teacher.username}
              Email={teacher.email}
              OnClickShowMore={() =>
                handleSelectedIndex(index, SelectedIndex, setSelectedIndex)
              }
              MenuBow={SelectedIndex === index ? <MenuOptionTeacher /> : null}
              ShowMoreOption={ShowMoreOptionFunc(HasanEmail, teacher.email)}
            />
          ))}
        </FlexAppContainer>
      </FlexAppContainer>

      <FlexAppContainer column={true} gap="1.25rem">
        <ParticipantInstructorsTextPlace>
          <Typography text={t("participants.Students")} fontSize="1.75rem" />
          <Typography
            text={`( ${studentsData.length} ${t(
              "participants.Students"
            ).toLowerCase()} )`}
            fontSize="0.875rem"
            color="#5DD3B3"
          />
        </ParticipantInstructorsTextPlace>
        <FlexAppContainer
          wrap={true}
          gap="2.5rem"
          // justifyContent="space-evenly"
        >
          {studentsData.map((std, index) => (
            <ParticipantInstructorsCard
              ContainerRef={clickOutsideRefStd}
              key={std.id}
              Picture={
                std.profilePicture || require("../../../assets/userProfile.png")
              }
              Name={std.username}
              Email={std.email}
              OnClickShowMore={() =>
                handleSelectedIndex(
                  index,
                  SelectedIndexStudent,
                  setSelectedIndexStudent
                )
              }
              MenuBow={
                SelectedIndexStudent !== index ? null : role === "teacher" ? (
                  <MenuOptionStudents
                    AccommodationsClick={toggleShowAccommodations}
                  />
                ) : (
                  <MenuOptionTeacher />
                )
              }
              ShowMoreOption={ShowMoreOptionFunc(HasanEmail, std.email)}
            />
          ))}
        </FlexAppContainer>
      </FlexAppContainer>
      {ShowAccommodations && (
        <PopUp
          content={
            <AccommodationsPopUp
              SelectedItems={SelectedItems}
              setSelectedItems={setSelectedItems}
            />
          }
          justifyContent="flex-end"
          buttonName="Save"
          showline={false}
          show={ShowAccommodations}
          setShow={setShowAccommodations}
          title="Accommodations"
          showButtonCancel={false}
          // marginTop="1rem"
          // paddingTop="1.25rem"
          showButton={true}
          icon={<SvgAccommodations />}
        />
      )}
    </FlexAppContainer>
  );
};

export default ParticipantInstructors;
