import styled from "styled-components";

export const ProfileNameEmailHolder = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 0.47rem;
  flex-wrap: wrap;
`;

export const NameEmailHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 0.31rem;
`;
