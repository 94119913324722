import React, { FC, useState, useEffect } from "react";
import {
  ContentContainer,
  CancelButton,
  SubmitButton,
  ButtonsContainer,
} from "./Comments.style";
import { CommentsProps } from "../CommentsInterface.style";
import SubmittionSection from "../SubmmittionSection/SubmittionSection";
import AssesmentSection from "../AssesmentSection/AssesmentSection";
import RatingSection from "../RatingSection/RatingSection";
import AssignmentCommentSection from "../AssignmentCommentSection/AssignmentCommentSection";
import { number } from "yargs";
import { useAuth0 } from "@auth0/auth0-react";
import { post, get, put } from '../../../utils/AxiosRequests';
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../../utils/StringManipulation";
import { useMyContext } from '../../../contexts/quizGraderContext';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import SimilarityReport from "../../SimilarityReport/SimilarityReport";
import { Link, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { adminState } from "../../../recoil/RecoilStates";
const Comments: FC<CommentsProps> = ({
  setShowComment,
  setShowRubric,
  showRating,
  showSubmitedTime,
  showLate,
  student,
  studentIndex,
  updateSubmittedByInstructor,
  allAssignment,
  questionIndex,
  questions,
  quiz,
  forum,
  fetchData,
  totalPoints,
  usingRubric,
  rubricId,
  selectedRubricCell,
  setShowTurnitinViewer,
  setTurnitinUrl,
  resourceR,
  isAssignmentGraded,
  handleChangeViewForAssignment
}) => {
  const [grade, setGrade] = useState(0);
  const [submittedGrade, setSubmittedGrade] = useState<number>(0);
  const [comment, setComment] = useState("");
  const [gradeError, setGradeError] = useState(false);
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams();
  const classId = params.classId;

  const { t } = useTranslation();
  const [isAdmin] = useRecoilState(adminState)


  // Set the grade of the answer
  const handleGradeChange = (newGrade: number) => {
    setGrade(newGrade);
    setSubmitted(false);
    setGradeError(false);
  };

  // Set the comment of the answer. ( if the activity is a quiz, it will set the comment of the attempt of the student)
  const handleCommentChange = (newComment: string) => {
    setComment(newComment);
  }

  // Handle the submit button
  const handleSubmit = async () => {
    if (quiz && totalPoints) // If the activity is a quiz
    {
      //COMMENT FOR THE ATTEMPT OF THE STUDENTS
      const hasEssayQuestion = questions && questions.length > 0 && questions.some(question => question.type === 'ESSAY');
      const hasZeroGradeEssayAnswer = student &&
        student.length > 0 &&
        student[studentIndex] &&
        student[studentIndex].lastAnswersOfStudent &&
        student[studentIndex].lastAnswersOfStudent.some(
          (answer: any) => answer.type === 'ESSAY' && answer.grade === 0
        );
      if (hasEssayQuestion && hasZeroGradeEssayAnswer) { // Check if there is an answer to the essay question that doesnt graded yet
        toast.error('Please Grade the Essay Question')
        return;
      }
      setLoading(true);
      const data = {
        commentAttempt: comment,
        finalGrade: ((student[studentIndex].totalGrade / totalPoints) * allAssignment.maxGrade),
        subId: subIdSplitted,
        provider: provider,
        quizAttemptId: student[studentIndex].quizAttemptId
      }
      try {
        const response = await post(`quiz/create/Quiz/Attempt/Feedback/${tenantName}`, data)
        if (response && response.data && response.data !== 1 && response.data.includes('You have already submitted')) {
          toast.error('You have already submitted For This Attempt')
        }
        fetchData && fetchData();
        setLoading(false);
      } catch (err) {
        console.log(err)
      }
    } else if (forum) { // if the activity is a forum
      if (grade === 0 && !submitted && !usingRubric && isAssignmentGraded) {
        setGradeError(true);
        console.error("Grade is required");
      } else if (usingRubric && !submitted && selectedRubricCell && selectedRubricCell.length === 0) {
        return
      } else {
        setLoading(true);
        const data = {
          feedback: comment,
          grade: grade || submittedGrade,
          subId: subIdSplitted,
          provider: provider,
          postId: student[studentIndex].submissions[0].postId,
          rubricGrade: selectedRubricCell && selectedRubricCell?.length > 0 ? selectedRubricCell : student && student[studentIndex] && student[studentIndex].grade[0] && student[studentIndex].grade[0].length > 0 && student[studentIndex].grade[0].rubricData && student[studentIndex].grade[0].rubricData.length > 0 ? student[studentIndex].grade[0].rubricData : ''
        }
        await post(`forum/post/create/grade/${tenantName}`, data)
        fetchData && fetchData();
        setSubmitted(true);
        setLoading(false);
      }
    }
    else { // if the activity is an assignment
      if (grade === 0 && !submitted && !usingRubric && isAssignmentGraded) {
        setGradeError(true);
        console.error("Grade is required");
      }
      else if (usingRubric && !submitted && selectedRubricCell && selectedRubricCell.length === 0) {
        return
      }
      else {

        setLoading(true);
        const newGradeData = {
          grade: grade || submittedGrade,
          comment: comment === "" || comment.toLowerCase() === "no comment" ? null : comment,
          subId: subIdSplitted,
          provider: provider,
          assignmentSubmissionId: student[studentIndex].id,
          rubricGrade: selectedRubricCell && selectedRubricCell?.length > 0 ? selectedRubricCell : student && student[studentIndex] && student[studentIndex].rubricData && student[studentIndex].rubricData.length > 0 ? student[studentIndex].rubricData : ''
        };
        await post(`assignment/submission/create/grade/${tenantName}`, newGradeData)
        fetchData && fetchData();
        setLoading(false);
      }
    }

  };

  // GEt the Submission of students By It's ID 
  useEffect(() => {
    setSubmittedGrade(0)
    setComment('')
    if (student && !quiz && !forum && student.length > 0) { // if the activity is an assignment
      if (student[studentIndex].grade) {
        setComment(student[studentIndex].grade.comment)
        setSubmittedGrade(student[studentIndex].grade.grade)
        setSubmitted(true);
      } else {
        setComment("")
        setGrade(0)
        setSubmitted(false);
      }
    } else if (student && forum && !quiz && student.length > 0 && student[studentIndex].grade && student[studentIndex].grade.length > 0) {
      // if the activity is forum and the student answer is graded
      setComment(student[studentIndex].grade[0].feedback)
      setSubmittedGrade(student[studentIndex].grade[0].grade)
      setSubmitted(true);
    } else if (student && forum && !quiz && student.length > 0 && student[studentIndex].grade && student[studentIndex].grade.length === 0) {
      // if the activity is forum and the student answer is not graded
      setComment("")
      setSubmittedGrade(0)
      setGrade(0)
      setSubmitted(false);
    } else if (student && student.length > 0 && quiz && student[studentIndex].commentAttempt) {
      // if the activity is a quiz and the student answer is graded
      setComment(student[studentIndex].commentAttempt);
    } else {
      // if the activity is a quiz and the student answer is not graded
      setComment('');
    }
  }, [student, studentIndex])

  return (
    <ContentContainer>
      {isAdmin === false ?
        <>
          {/* This component is for Turnitin similarity report just for assignment */}
          {!quiz && !forum && student && student.length > 0 && student[studentIndex] && student[studentIndex].report && student[studentIndex].report.length > 0 && (
            student[studentIndex].report.map((item: any) =>
              <SimilarityReport
                title={item.report.type === "text" ? 'Question Report' : 'Attachment Report'}
                submissionId={item.report.submission_id}
                OverallMatchValue={item.report.overall_match_percentage}
                InternetMatchValue={item.report.internet_match_percentage}
                setTurnitinUrl={setTurnitinUrl}
                setShowTurnitinViewer={setShowTurnitinViewer}
                student={student}
                studentIndex={studentIndex}
                handleChangeViewForAssignment={handleChangeViewForAssignment}
              />
            )
          )}
          <SubmittionSection quiz={quiz} forum={forum} student={student} studentIndex={studentIndex} showRating={showRating} showLate={showLate} />
          {isAssignmentGraded && !quiz ?
            <AssesmentSection usingRubric={usingRubric} rubricId={rubricId} quiz={quiz} totalPoints={totalPoints} forum={forum} allAssignment={allAssignment} student={student} studentIndex={studentIndex} setShowRubric={setShowRubric} showLate={showLate} onGradeChange={handleGradeChange} submittedGrade={submittedGrade} grade={grade} submitted={submitted} />
            : quiz ?
              <AssesmentSection usingRubric={usingRubric} rubricId={rubricId} quiz={quiz} totalPoints={totalPoints} forum={forum} allAssignment={allAssignment} student={student} studentIndex={studentIndex} setShowRubric={setShowRubric} showLate={showLate} onGradeChange={handleGradeChange} submittedGrade={submittedGrade} grade={grade} submitted={submitted} />
              : ""}
          {showRating && !forum && !quiz ? <RatingSection selectedRubricCell={student[studentIndex].rubricData || selectedRubricCell || []} choosedCell={selectedRubricCell || []} /> :
            showRating && forum && !quiz ? <RatingSection selectedRubricCell={student && student.length > 0 && student[studentIndex].grade && student[studentIndex].grade.length > 0 ? student[studentIndex].grade[0].rubricData : selectedRubricCell || []} choosedCell={selectedRubricCell || []} /> : ""}
          <AssignmentCommentSection forum={forum} quiz={quiz} showRating={showRating} onCommentChange={handleCommentChange} comment={comment} />


          {isAdmin === false ? <ButtonsContainer>

            {/* Cancel Button */}
            <Link
              to={`/courseView/content/${classId}`}
              state={{
                activity: resourceR,
                moduleIndex: resourceR?.moduleIndex,
                topicIndex: resourceR?.toIndex,
                contentIndex: resourceR?.contentIndex,
              }}
            >
              <CancelButton>{t('quiz.Cancel')}</CancelButton>
            </Link>


            {/* { !forum && !quiz && submitted && !loading ? ( // if the activity is an assignment and student answer is graded
          <SubmitButton style={{ opacity: 0.7, cursor: "default" }} disabled>{t('dashboard.Submitted')}</SubmitButton>
        ) : */}

            {!forum && !quiz && !loading ? // if the activity is an assignment and student answer is not graded
              (
                <SubmitButton onClick={() => handleSubmit()}>{t('forum.Submit')}</SubmitButton>

              ) : !forum && !quiz && loading ? // if loading is true
                (
                  <SubmitButton style={{ opacity: 0.7, cursor: "default" }} disabled>{`${t('general.loading')}...`} </SubmitButton>

                ) : ""}

            {/* { forum && !quiz && submitted && !loading ? ( // if the activity is a forum and student answer is graded
          <SubmitButton style={{ opacity: 0.7, cursor: "default" }} disabled>{t('dashboard.Submitted')}</SubmitButton>
        ) : */}

            {forum && !quiz && !loading ? // if the activity is a forum and student answer is not graded
              (
                <SubmitButton onClick={() => handleSubmit()}>{t('forum.Submit')}</SubmitButton>

              ) : forum && !quiz && loading ?  // if loading is true
                (
                  <SubmitButton style={{ opacity: 0.7, cursor: "default" }} disabled>{`${t('general.loading')}...`} </SubmitButton>
                ) : ""}

            {!forum && quiz && !loading ? // if the activity is a Quiz
              (
                <SubmitButton onClick={() => handleSubmit()}>{t('forum.Submit')}</SubmitButton>

              ) : !forum && quiz && loading ? // if loading is true
                (
                  <SubmitButton style={{ opacity: 0.7, cursor: "default" }} disabled>{`${t('general.loading')}...`} </SubmitButton>
                ) : ""}
          </ButtonsContainer> :
            <></>}
        </>
        :
        <></>}
    </ContentContainer>
  );
};

export default Comments;
