import styled from "styled-components";
import { breakpoints } from "../../../theme";

interface MenuWrapperInterface {
  groupindex: number;
  bgColor: string;
  boxShadow: string;
}

export const MenuWrapper = styled.div<MenuWrapperInterface>`
  position: absolute;
  top: 2.81rem;
  &:dir(ltr) {
    right: -4.5625rem;
  }
  &:dir(rtl) {
    left: -4.5625rem;
  }
  border-radius: 0.3125rem;
  background: ${(props) => props.bgColor};
  box-shadow: ${(props) => props.boxShadow};
  min-height: fit-content;
  min-width: fit-content;
  width: 6.5625rem;
  height: 5.0625rem;
  display: flex;
  flex-direction: column;
  z-index: 20;
  @media (max-width: ${breakpoints.small}) {
    &:dir(ltr) {
      right: 1.5rem;
    }
    &:dir(rtl) {
      left: 1.5rem;
    }
  }
  @media (min-width: 1488px) and (max-width: 1710px) {
    &:dir(ltr) {
      right: ${({ groupindex }) =>
        groupindex % 2 === 0 ? "1.5rem" : "-4.5625rem"};
    }
    &:dir(rtl) {
      left: ${({ groupindex }) =>
        groupindex % 2 === 0 ? "1.5rem" : "-4.5625rem"};
    }
  }
`;

interface FrameProps {
  textColor: string;
}
export const Frame = styled.div<FrameProps>`
  color: ${(props) => props.textColor};
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: start;
  &:hover {
    background: ${({ theme }) => theme.dark.main_color};
    transition: all 0.2s ease-in;
    color: #fff;
  }
`;

export const TextWithIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  &:dir(ltr) {
    padding: 0.62rem 0 0.62rem 1.25rem;
  }
  &:dir(rtl) {
    padding: 0.62rem 1.25rem 0.62rem 0;
  }
  display: flex;
  column-gap: 0.44rem;
  align-items: center;
`;
