import styled from "styled-components";

export const IconHolderActive = styled.div`
  cursor: pointer;
  border: 0px none;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #5dd3b3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VerticalLine = styled.div`
  height: 1.2rem;
  width: 0rem;
  border: 1px solid #5dd3b3;
`;

export const ActiveDot = styled.div`
  border: 0px none;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #5dd3b3;
`;
