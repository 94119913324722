import { FC } from "react";
import {
  EmptyActivitiesTextWrapper,
  ActivitiesWrapper,
} from "./DropActivity.style";
import { DropActivityProps } from "./dropActivityInterface";

import { LightTypography } from "../../elements/fonts/Fonts";
import { useTranslation } from "react-i18next";

const DropActivity: FC = () => {
  const { t } = useTranslation()
  return (
    <ActivitiesWrapper>
      <EmptyActivitiesTextWrapper>
        <LightTypography
          text={t("course creator.Drag and Drop activities and resources")}
          fontSize="0.75rem"
        />
      </EmptyActivitiesTextWrapper>
    </ActivitiesWrapper>
  );
};

export default DropActivity;
