import styled from "styled-components";
import { breakpoints } from "../../theme";

export const MainEmptyDiv = styled.div`
  background: linear-gradient(180deg, #415160 0%, #293440 100%);
  border-radius: 1rem;
  /* height: 30rem; */
  display: flex;
  justify-content: center;
  gap: 1.88rem;
  padding: 1.75rem 2rem;
  max-height: 22rem;
  @media (max-width: calc(${breakpoints.tablets})) {
    display: flex;
    flex-direction: column;
  }
`;
