
interface StudentFinalGradeInterface {
  MaxGrade:number;
  Students:{
  StdCode:string;
  StdName:string;
    Grade:number;
  Questions?:{
    QuestionCode: string;
    StudentAnswer?: any;
  }[];
  }[];
    

}

export const StudentFinalGradeData : StudentFinalGradeInterface =
{MaxGrade:20,
  Students:[
  {StdCode:"34f32",  StdName:"Hadi Shouman", Grade:19,
  Questions:[
      {QuestionCode:'Q1',StudentAnswer:'C'},
      {QuestionCode:'Q2',StudentAnswer:'B'}
   ]},
  {StdCode:"54fg45",  StdName:"Jihad Semaan", Grade:17,
  Questions:[
      {QuestionCode:'Q1',StudentAnswer:'A'},
      {QuestionCode:'Q2',StudentAnswer:'B+'},]
},
{StdCode:"Salim32234",  StdName:"Salim Bahsoun", Grade:18,
Questions:[
  {QuestionCode:'Q1',StudentAnswer:'A'},
  {QuestionCode:'Q2',StudentAnswer:null},]
},
{StdCode:"3rfeg",  StdName:"Yassmina Dbouk", Grade:16,
Questions:[
  {QuestionCode:'Q1',StudentAnswer:''},
  {QuestionCode:'Q2',StudentAnswer:'B+'},]
},
{StdCode:"324fdfdgwq",  StdName:"Rawad Shouman", Grade:15,
Questions:[
  {QuestionCode:'Q1',StudentAnswer:true},
  {QuestionCode:'Q2',StudentAnswer:true},]
},
{StdCode:"Walsdr435id",  StdName:"Abdallah Zbib", Grade:15,
Questions:[
  {QuestionCode:'Q1',StudentAnswer:true},
  {QuestionCode:'Q2',StudentAnswer:true},]
},

]
}