import React from "react";
import {
  AnalyticShadowHeaderFourDisplaysContainer,
  AnalyticShadowHeaderFourDisplaysLine,
  AnalyticShadowHeaderFourDisplaysTextColumn,
  AnalyticShadowHeaderFourDisplaysTextOpacity,
} from "../../AnalyticShadowHeaderFourDisplays/AnalyticShadowHeaderFourDisplays.styled";
import {
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
interface AnalyticHeaderManyDisplaysInterface {
  Participation?: any;
  Posts?: number;
  Replies?: number;
  AveragePostsPerStudent?: number;
  Graded?: boolean;
  Anonymous?: boolean;
}
const AnalyticHeaderManyDisplays = ({
  Participation,
  Posts,
  Replies,
  AveragePostsPerStudent,
  Anonymous,
  Graded,
}: AnalyticHeaderManyDisplaysInterface) => {
  return (
    <AnalyticShadowHeaderFourDisplaysContainer>
      <AnalyticShadowHeaderFourDisplaysTextColumn>
        <Typography text="Participation" fontSize="1.125rem" />
        <AnalyticShadowHeaderFourDisplaysTextOpacity>
          <TypographyRegularDescription
            text={Participation?.toString() || ""}
            fontSize="0.875rem"
          />
        </AnalyticShadowHeaderFourDisplaysTextOpacity>
      </AnalyticShadowHeaderFourDisplaysTextColumn>
      <AnalyticShadowHeaderFourDisplaysLine />
      <AnalyticShadowHeaderFourDisplaysTextColumn>
        <Typography text="Posts" fontSize="1.125rem" />
        <AnalyticShadowHeaderFourDisplaysTextOpacity>
          <TypographyRegularDescription
            text={Posts?.toString() || ""}
            fontSize="0.875rem"
          />
        </AnalyticShadowHeaderFourDisplaysTextOpacity>
      </AnalyticShadowHeaderFourDisplaysTextColumn>
      <AnalyticShadowHeaderFourDisplaysLine />
      <AnalyticShadowHeaderFourDisplaysTextColumn>
        <Typography text="Replies" fontSize="1.125rem" />
        <AnalyticShadowHeaderFourDisplaysTextOpacity>
          <TypographyRegularDescription
            text={Replies?.toString() || ""}
            fontSize="0.875rem"
          />
        </AnalyticShadowHeaderFourDisplaysTextOpacity>
      </AnalyticShadowHeaderFourDisplaysTextColumn>
      <AnalyticShadowHeaderFourDisplaysLine />
      <AnalyticShadowHeaderFourDisplaysTextColumn>
        <Typography text="Average Participation" fontSize="1.125rem" />
        <AnalyticShadowHeaderFourDisplaysTextOpacity
          style={{ gap: "1.50rem", justifyContent: "space-between" }}
        >
          <TypographyRegularDescription
            text={AveragePostsPerStudent?.toString() || ""}
            fontSize="0.875rem"
          />
        </AnalyticShadowHeaderFourDisplaysTextOpacity>
      </AnalyticShadowHeaderFourDisplaysTextColumn>
      <AnalyticShadowHeaderFourDisplaysLine />
      <AnalyticShadowHeaderFourDisplaysTextColumn>
        <Typography text="Graded" fontSize="1.125rem" />
        <AnalyticShadowHeaderFourDisplaysTextOpacity>
          <TypographyRegularDescription
            text={Graded ? "Yes" : "No"}
            fontSize="0.875rem"
          />
        </AnalyticShadowHeaderFourDisplaysTextOpacity>
      </AnalyticShadowHeaderFourDisplaysTextColumn>
      <AnalyticShadowHeaderFourDisplaysLine />
      <AnalyticShadowHeaderFourDisplaysTextColumn>
        <Typography text="Anonymous" fontSize="1.125rem" />
        <AnalyticShadowHeaderFourDisplaysTextOpacity>
          <TypographyRegularDescription
            text={Anonymous ? "Yes" : "No"}
            fontSize="0.875rem"
          />
        </AnalyticShadowHeaderFourDisplaysTextOpacity>
      </AnalyticShadowHeaderFourDisplaysTextColumn>
    </AnalyticShadowHeaderFourDisplaysContainer>
  );
};

export default AnalyticHeaderManyDisplays;
