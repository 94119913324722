import { CourceArchInterface } from "./CourceArchInterface";

interface CertificateTemplateProp {
  title: string;
  certificateImage: string;
  certificateId: string | number;
  certificateTemplate: string;
}

export const CertificateDataInterface: CertificateTemplateProp[] = [
  {
    title: "Template 1",
    certificateImage: require("../../assets/certificate-template1.jpeg"),
    certificateId: 1,
    certificateTemplate: "one",
  },
  {
    title: "Template 2",
    certificateImage: require("../../assets/template 2.jpeg"),
    certificateId: 2,
    certificateTemplate: "two",
  },
  {
    title: "Template 3",
    certificateImage: require("../../assets/template 3.jpeg"),
    certificateId: 3,
    certificateTemplate: "three",
  },
];

export type PreRequisitesOptionProps = {
  value: string;
  label: string;
};

export const PreRequisitesDataOptions: PreRequisitesOptionProps[] = [
  {
    value: "Live training course 1",
    label: "Live Training Course 1",
  },
  {
    value: "Live training course 2",
    label: "Live Training Course 2",
  },
  {
    value: "Live training course 3",
    label: "Live Training Course 3",
  },
  {
    value: "Live training course 4",
    label: "Live Training Course 4",
  },
  {
    value: "Live training course 5",
    label: "Live Training Course 5",
  },
];

export const TargetCompetencyLevelDataOptions = [
  {
    name: "All",
  },
  {
    name: "Basic",
  },
  {
    name: "Intermediate",
  },
  {
    name: "Expert",
  },
  {
    name: "Master",
  },
];

export const BigBorderInputOptions = [
  {
    name: "S",
  },
  {
    name: "M",
  },
  {
    name: "L",
  },
];

export const SmallBorderInputOptions = [
  {
    name: "S",
  },
  {
    name: "M",
  },
  {
    name: "L",
  },
];

export const RectBorderInputOptions = [
  {
    name: "S",
  },
  {
    name: "M",
  },
  {
    name: "L",
  },
];

export const FinalGradeDataConditions = [
  {
    name: ">",
  },
  {
    name: "> =",
  },
  {
    name: "<",
  },
];

export const FinalGradeDataOptions = [
  {
    name: "20",
  },
  {
    name: "40",
  },
  {
    name: "50",
  },
  {
    name: "60",
  },
  {
    name: "70",
  },
  {
    name: "80",
  },
  {
    name: "90",
  },
  {
    name: "100",
  },
];

export const TagDataOptions: PreRequisitesOptionProps[] = [
  {
    value: "Business",
    label: "Business",
  },
  {
    value: "Machine learning",
    label: "Machine Learning",
  },
];

export const CourseArctData: CourceArchInterface[] = [
  { id: "1", name: "Ali Ahmad", profilePic: "./assets/images/teacherprof.png" },
  {
    id: "2",
    name: "Ismail Ahmad",
    profilePic: "./assets/images/profileImg.jpg",
  },
  {
    id: "3",
    name: "Fadi Alkhatib",
    profilePic: "./assets/images/SenderImg2.jpg",
  },
  { id: "4", name: "Amir Suid", profilePic: "./assets/images/teacherprof.png" },
  {
    id: "5",
    name: "Hadi Ahmad",
    profilePic: "./assets/images/teacherprof.png",
  },
  // {id:"6",
  // name: "Rokayia Nassar",
  // profilePic:"./assets/images/SenderImg.jpg"
  // },
];

export const ChannelData = [
  {name: "Channel 1", value: "Channel 1 value"},
  {name: "Channel 2", value: "Channel 2 value"},
  {name: "Channel 3", value: "Channel 3 value"}
]