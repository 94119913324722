import React, { useRef } from "react";
import {
  FileInputButtonIcon,
  FileInputButton,
  HiddenInput,
  GroupImage,
} from "./ProfilePICUpload.style";
import { LightTypography } from "../fonts/Fonts";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

interface FileInputProps {
  onChange: (files: FileList | null) => void;
  title: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  acceptedFileTypes?: string;
  bgColor?: string;
  fileResponse:string
}
const ProfilePICUpload = ({
  onChange,
  title,
  fileResponse,
  acceptedFileTypes,
  icon: IconComponent,
  bgColor,
}: FileInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    onChange(files);
  };
  const { bghelp } = useRecoilValue(ColorSelector);
  return (
    <FileInputButton bgColor={bgColor || bghelp}>
      {
!fileResponse &&
<>

<FileInputButtonIcon>
  <IconComponent />
</FileInputButtonIcon>
<LightTypography text={title} fontSize="0.75rem" />
</>
      }

      <HiddenInput
        type="file"
        ref={inputRef}
        onChange={handleFileChange}
        accept={acceptedFileTypes}
      />
      {fileResponse &&
    <img 
    src={fileResponse} 
    alt="" 
    style={{
      width: '100%',
      objectFit:"cover",
      height: '100%',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999,
    }} 
  />
  
}
    </FileInputButton>
  );
};

export default ProfilePICUpload;
