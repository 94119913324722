import React, { useEffect, useState } from "react";
import { AnalyticsSurveyPiesTwoValuesContainer } from "../../AnalyticsSurveyPies/AnalyticsSurveyPiesTwoValues/AnalyticsSurveyPiesTwoValues.style";
import PieWithText from "../../Charts/PieWithText/PieWithText";
import PieText from "../../Charts/PieText/PieText";
import { QuizQuestionsAnalyticsInterfaceV2 } from "../../Tables/QuizQuestionsAnalytics/QuizQuestionsAnalyticsinterfaceV2";

// Define interface for AnalyticsSurveyPiesTwoValues
interface AnalyticsSurveyPiesTwoValuesInterfaceData {
  data: QuizQuestionsAnalyticsInterfaceV2;
  FirstTextLabel: string;
  SecondTextLabel: string;
  // ThirdTextLabel?: string;
}

const AnalyticsQuizzesNumericalTwoChoice = ({
  data,
  FirstTextLabel,
  SecondTextLabel,
}: AnalyticsSurveyPiesTwoValuesInterfaceData) => {
  // useEffect(() => {
  //   console.log("ChosenQuestion is", data);
  // }, [data]);
  const [PieChartData, setPieChartData] = useState([
    {
      text: FirstTextLabel,
      val: 0,
      bgColor: "#5DD3B3",
    },
    {
      text: SecondTextLabel,
      val: 0,
      bgColor: "#0B8FFF",
    },
    {
      text: "Not Answered",
      val: 0,
      bgColor: "#8A2BE2",
    },
  ]);

  useEffect(() => {
    const CorrectCount = data.students.filter(
      (item) => item.Answered !== undefined && item.Answered
    ).length;
    const UnCorrectCount = data.students.filter(
      (item) => item.Answered !== undefined && !item.Answered
    ).length;
    const NotAnsweredCount = data.students.filter(
      (item) => item.Answered === undefined || item.Answered === null
    ).length;

    setPieChartData([
      {
        text: FirstTextLabel,
        val: CorrectCount,
        bgColor: "#5DD3B3",
      },
      {
        text: SecondTextLabel,
        val: UnCorrectCount,
        bgColor: "#0B8FFF",
      },
      {
        text: "Not Answered",
        val: NotAnsweredCount,
        bgColor: "#8A2BE2",
      },
    ]);
  }, [data]);

  return (
    <AnalyticsSurveyPiesTwoValuesContainer>
      {/* PieWithText Component */}
      <PieWithText
        data={PieChartData}
        value={"val"}
        getColor={(entry) => entry.bgColor as string}
        Width="65%"
      />

      {/* PieText Component */}
      <PieText
        LoopName={PieChartData}
        ShowMiniInfo={false}
        title={data.QuestionText}
        description={data.QuestionDescription}
      />
    </AnalyticsSurveyPiesTwoValuesContainer>
  );
};

export default AnalyticsQuizzesNumericalTwoChoice;
