import React, { useEffect, useState } from "react";
import {
  IAUVLayout,
  IAUVLine,
  IAUVMiniLine,
  IAUVActions,
} from "./InstructorAnalyticsUsersView.style";
import { InstructorViewCompletionSearchContainer } from "../StudentCompletionTrackingInstrcuterView/InstructorViewCompletion.style";
import SearchElement from "../../elements/searchElement/SearchElement";
import { LightTypography, Typography } from "../../elements/fonts/Fonts";
import { SvgDownload, SvgEyeIcon } from "../../elements/Icons";
import { LogoAndAction } from "../gradesItem/GradesItem.style";
import InstructorAnalyticsUsersViewTable from "./InstructorAnalyticsUsersViewTable/InstructorAnalyticsUsersViewTable";
import { InstructorAnalyticsUsersViewTableData } from "./InstructorAnalyticsUsersViewTable/InstructorAnalyticsUsersViewTableDATA";
import {
  ViewsOptions,
  handleNumberDisplays,
} from "../../utils/NbOfViewesObjects";
import TableViewSelectInput from "../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/TableViewSelectInput";
import Pagination from "../../elements/Pationation/Pagination";
import { ExcelDownload } from "../../utils/ExcelGlobalFunction";

const InstructorAnalyticsUsersView = () => {
  const [limit, setLimit] = useState(
    InstructorAnalyticsUsersViewTableData.length
  );
  const itemsPerPage = limit;
  const [currentPage, setCurrentPage] = useState(1);
  const [ShowPagination, setShowPagination] = useState(false);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const [searchText, setSearchText] = useState("");
  const [searchButtonText, setSearchButtonText] = useState("");

  const AllUsers = () => {
    setSearchButtonText("");
  };

  const InstructorUsers = () => {
    setSearchButtonText("Instructor");
  };

  const StudentUsers = () => {
    setSearchButtonText("Student");
  };
  const filteredDataLimited = InstructorAnalyticsUsersViewTableData.slice(
    startIndex,
    endIndex
  );
  const filteredData = filteredDataLimited.filter((item) => {
    const searchTextMatches = Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes((searchText ?? "").toLowerCase())
    );

    const roleMatches =
      searchButtonText === "" ||
      (item.Role ?? "").toLowerCase() ===
        (searchButtonText ?? "").toLowerCase();

    return searchTextMatches && roleMatches;
  });
  const handleNumberShows = (newValue: string) => {
    handleNumberDisplays(
      newValue,
      setViewText,
      setLimit,
      filteredData,
      setShowPagination
    );
  };
  const [ViewText, setViewText] = useState("View All");

  return (
    <IAUVLayout>
      <IAUVLine>
        <Typography text="Users" fontSize="1.5rem" />
        <InstructorViewCompletionSearchContainer>
          <SearchElement onChange={(e) => setSearchText(e.target.value)} />
        </InstructorViewCompletionSearchContainer>
      </IAUVLine>
      <IAUVLine>
        <IAUVMiniLine>
          <IAUVActions onClick={AllUsers} isActive={searchButtonText === ""}>
            <LightTypography text="All Users" fontSize="0.75rem" />
          </IAUVActions>
          <IAUVActions
            onClick={InstructorUsers}
            isActive={searchButtonText === "Instructor"}
          >
            <LightTypography text="Instructor/s" fontSize="0.75rem" />
          </IAUVActions>
          <IAUVActions
            onClick={StudentUsers}
            isActive={searchButtonText === "Student"}
          >
            <LightTypography text="Students" fontSize="0.75rem" />
          </IAUVActions>
        </IAUVMiniLine>
        <IAUVMiniLine>
          <TableViewSelectInput
            value={ViewText}
            options={ViewsOptions}
            onChange={handleNumberShows}
          />
          <LogoAndAction
            onClick={() =>
              ExcelDownload(
                InstructorAnalyticsUsersViewTableData,
                "InstructorAnalyticsData"
              )
            }
          >
            <SvgDownload />
            <Typography text="Download" fontSize="0.75rem" />
          </LogoAndAction>
        </IAUVMiniLine>
      </IAUVLine>
      <InstructorAnalyticsUsersViewTable filteredData={filteredData} />
      {ShowPagination && (
        <Pagination
          currentPage={currentPage}
          total={InstructorAnalyticsUsersViewTableData?.length}
          limit={itemsPerPage}
          onPageChange={(page: number) => setCurrentPage(page)}
          justifyContent="left"
          Padding="0 0 0 5.5rem"
          BoxSize="1.85rem"
          ScrollTop={false}
          MarginTop="1rem"
        />
      )}
    </IAUVLayout>
  );
};

export default InstructorAnalyticsUsersView;
