import styled from "styled-components";
import { breakpoints } from "../../theme";

interface SchemeGroupCardRectangle {
  bgColor: string;
  boxShadow: string;
}
export const SchemeGroupCardRectangle = styled.div<SchemeGroupCardRectangle>`
  position: relative;
  width: 25.25rem;
  height: fit-content;
  border-radius: 0.625rem;
  /* background: ${({ theme }) => theme.dark.bg_secondary_color}; */
  background: ${(props) => props.bgColor || "#384450"};
  box-shadow: ${(props) => props.boxShadow};
  padding: 0.94rem 1.19rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.63rem;
  break-inside: avoid;
  margin-bottom: 1.25rem;

  @media (max-width: ${breakpoints.mobile}) {
    width: 21.8125rem;
    margin-bottom: 0.62rem;
  }
`;

export const SchemeGroupCardHeaderRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  & > :last-child:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
`;

export const SchemeGroupDescriptionWrapper = styled.div`
  max-width: 100% !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.31rem;
  flex-wrap: wrap;
  /* background-color: gray; */
`;

export const VDotsWrapper = styled.div`
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PartitioningMainHolder = styled.div<{ color?: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.63rem;
  /* color:${(props) => props.color} */
`;

export const PartitioningHolderRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 0.62rem;
`;

export const UngroupedHolderRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 1.06rem;
`;

export const PartitioningNumWithLine = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  column-gap: 0.31rem;
  align-items: center;
`;

interface DivideLineProps {
  lineColor: string;
}
export const DivideLine = styled.div<DivideLineProps>`
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.lineColor};
  width: 100%;
`;

export const GroupsWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  row-gap: 0.62rem;
`;

export const UngroupedStudentsWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.31rem;
  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const UngroupedStudentsColumn = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.31rem;
`;
export const UngroupedStudentRow = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  column-gap: 0.62rem;
  justify-content: start;
  align-items: center;
`;

export const TypographyWrapper = styled.div`
  max-width: 100%;
  text-align: start;
  & > :first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* Set the number of lines to display before truncating */
  }
`;
