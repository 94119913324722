import React, { PureComponent } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { SvgCertificate } from "../../../elements/Icons";

// Constant for converting degrees to radians
const RADIAN = Math.PI / 180;

// Customized label component for displaying percentage and attendance
const BIGenderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  attendance,
}: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  attendance: number;
}) => {
  // Calculating coordinates for positioning elements
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const svgX = cx + radius * Math.cos(-midAngle * RADIAN) - 15;
  const svgY = cy + radius * Math.sin(-midAngle * RADIAN) - 27;
  const SmallTextX = cx + radius * Math.cos(-midAngle * RADIAN) - 5;
  const SmallTextY = cy + radius * Math.sin(-midAngle * RADIAN) + 18;

  // Don't display anything if percent is zero
  if (percent === 0) {
    return null;
  }

  return (
    <g textAnchor="middle">
      {/* Display percentage */}
      <text
        x={x}
        y={y}
        fill="#FFF"
        dominantBaseline="central"
        style={{
          pointerEvents: "none",
          fontFamily: "Primary Font-Bold",
          fontSize: "0.875rem",
        }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>

      {/* Display certificate icon */}
      <g
        transform={`translate(${svgX},${svgY})`}
        style={{ pointerEvents: "none" }}
      >
        <SvgCertificate />
      </g>

      {/* Display attendance */}
      <text
        x={SmallTextX}
        y={SmallTextY}
        fill="#FFF"
        dominantBaseline="central"
        style={{
          pointerEvents: "none",
          fontFamily: "Primary Font",
          fontSize: "0.75rem",
        }}
      >
        {attendance}
      </text>
    </g>
  );
};

// Customized label component for displaying text data
const OnlyTextCustomizedLabel = ({
  percent,
  cx,
  cy,
  midAngle,
  SimpleChoiceTextEquation = true,
  val,
  MultipleTotalSum = 0,
  index,
}: {
  percent: number;
  cx: number;
  cy: number;
  midAngle: number;
  SimpleChoiceTextEquation?: boolean;
  val: number;
  MultipleTotalSum: number;
  index: number;
}) => {
  // Calculating coordinates for positioning text
  const radius = 100; // You can adjust this value as needed
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  // Render nothing if percent is zero
  if (percent === 0) {
    return null;
  }

  return (
    <text
      x={x}
      y={y}
      fill="#FFF"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      style={{
        pointerEvents: "none",
        fontFamily: "Primary Font-Bold",
        fontSize: "0.875rem",
      }}
    >
      {SimpleChoiceTextEquation
        ? `${(percent * 100).toFixed(0)}%`
        : `${(((val || 0) / MultipleTotalSum) * 100).toFixed(0)}%`}
    </text>
  );
};

// Props interface for PieWithText component
interface PieWithTextProps {
  data: any[];
  value?: string;
  getColor: (entry: any) => string;
  Width?: string;
  NormalTextDisplay?: boolean;
  OuterRadius?: number;
  InnerRadius?: number;
  MultipleTotalSum?: number;
  SimpleChoiceTextEquation?: boolean;
  val?: number | number[];
}

// PieWithText component
export default class PieWithText extends PureComponent<PieWithTextProps> {
  // Render method
  render() {
    // Destructuring props
    const {
      data,
      value = "val",
      getColor,
      Width = "100%",
      NormalTextDisplay = true,
      OuterRadius = 150,
      InnerRadius = 50,
      SimpleChoiceTextEquation = true,
      MultipleTotalSum = 0,
      val = 0, // Adding val with a default value of 0
    } = this.props;

    return (
      <ResponsiveContainer width={Width} height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={
              NormalTextDisplay
                ? ({ cx, cy, midAngle, percent, index }) => (
                    <OnlyTextCustomizedLabel
                      percent={percent}
                      cx={cx}
                      cy={cy}
                      midAngle={midAngle}
                      SimpleChoiceTextEquation={SimpleChoiceTextEquation}
                      val={Array.isArray(val) ? val[index] : val} // Pass the first value if it's an array
                      MultipleTotalSum={MultipleTotalSum}
                      index={index} // Pass the index to display inside the cell
                    />
                  )
                : BIGenderCustomizedLabel
            }
            outerRadius={OuterRadius}
            innerRadius={InnerRadius}
            fill="#8A2BE2"
            dataKey={value}
            stroke="none"
            strokeWidth={0}
          >
            {/* Render cells based on data */}
            {data.map((entry, index) =>
              entry[value] > 0 ? (
                <Cell key={`cell-${index}`} fill={getColor(entry)} />
              ) : null
            )}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
