import styled from "styled-components";
import { theme } from "../../../theme";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const CommentContainer = styled.div<{ showrating: string }>`
  display: flex;
  flex-direction: column;
  gap: 0.62rem;
  margin-top: ${(props) =>
    props.showrating === "true" ? "1.87rem" : "1.87rem"};
    margin-right: 1.25rem;
    margin-left: 1.25rem;
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  margin-top: 1.25rem;
`;
export const CommentTextAreaContainer = styled.div`
  width: 50%;
  height: 8.75rem;
  /* border: 1px solid #576371; */
  /* border-radius: 0.625rem; */
  display: flex;
  justify-content: center;
  margin-top:-0.5rem;
  max-width:10.7rem;
`;

export const CommetnTextArea = styled.textarea<StyleThemeInterface>`

  background-color: transparent;
  border: 1px solid ${props =>props.bordercolor};
  border-radius: 0.625rem;
  font-size: 0.875rem;
  width: 100%;
  height: 100%;

  min-width: 70%;
  padding: 0.63rem 1rem;
  font-family: "Primary Font-Light";
   color:${props =>props.color};
   resize: none;
   max-width:10.7rem;
  &:focus {
    outline: none;
    caret-color:${props =>props.color};
    /* border: #576371; */
  }
  &::placeholder {
     color:${props =>props.color};
    font-size: 0.875rem;
    opacity: 0.5;
  }
`;

export const Comment = styled.div`
  display: flex;
  justify-content: space-between;
`;
