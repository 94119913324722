import styled from "styled-components";

export const BlackLine = styled.div`
  max-height: 2.75rem;
  min-height: 2.75rem;
  background: #18181f;
`;

export const SideToolsContainer = styled.div<{
  bgColor?: string;
  isGraded?: boolean;
  isadmin?: string;
}>`
  /* width: 100%; */
  /* height: 110vh; */
  height:${props => props.isadmin === "true" ? "100vh !important" : "100% !important"} ;
  overflow: hidden;
  /* max-width: 23rem; */
  /* background: red; */
  min-width: 22rem;
  background: ${(props) => props.bgColor || "#384450"};
  &:dir(ltr) {
    margin-left: -0.5rem;
  }
  &:dir(rtl) {
    /* background: cadetblue; */
    margin-right: -0.5rem;
  }
  ${(props) => !props.isGraded && "min-height: 100vh;"};
`;
