import styled from "styled-components";
export const ResourceDivHovered = styled.div<{
  allResources: boolean;
}>`
  margin-top: ${({ allResources }) => (allResources ? "10rem" : "14.5rem")};

  /* padding: ${({ allResources }) => (allResources ? "1rem" : "2rem")}; */
  /* height: 16rem; */
  height: ${({ allResources }) => (allResources ? "16rem" : "20rem")};
  width: ${({ allResources }) => (allResources ? "23rem" : "23rem")};
  /* width: 23rem; */
  margin-left: ${({ allResources }) => (allResources ? "-2rem" : "0")};
  /* margin-left: -2rem; */
  display: none;
  transition: margin-left 1s, margin-top 1s;
  white-space: nowrap; /* Prevents the text from wrapping to the next line */
  overflow: hidden; /* Ensures content that overflows is hidden */
  text-overflow: ellipsis;
  /* background: green; */
  &:hover {
    opacity: 1;
  }
`;
export const ResourcesSliderStyle = styled.div<{
  allResources: boolean;
  ResourceType: string;
  background?: string;
  backgroundColor?: string;
}>`
  border-radius: 0.7rem;
  background: ${(props) =>
    props.background === "true"
      ? props.backgroundColor ||
        "linear-gradient(90deg, #415160 0%, #293440 107.88%)"
      : "transparent"};

  /* padding: ${(props) =>
    props.background === "true"
      ? "1rem 0 1rem 1rem"
      : "0.25rem 0 0.25rem 0.5rem"}; */
  padding: 0.5rem 0 0 0rem;
  display: flex;
  gap: 0.9rem;
  align-items: center;

  .slick-slide {
    width: 23rem !important;
    /* height: 12rem !important; */
    height: ${(props) =>
      props.allResources ? "12rem !important" : "10rem !important"};
    display: flex;
    align-items: center;
    &:hover {
      ${ResourceDivHovered} {
        display: flex;
        align-items: center;
        justify-content: center;

        /* margin-left: -1.9rem; */
      }
    }
    &:first-child {
      &:hover {
        ${ResourceDivHovered} {
          display: flex;
          align-items: center;
          justify-content: center;
          /* margin-left: -1.5rem; */
        }
      }
    }
  }
  .slick-slider {
    width: 100%;
  }
  .slick-list {
    overflow: hidden;
    padding-right: 4rem;
  }
  .slick-track {
    overflow: hidden;
    display: flex;
  }
  .slick-slider .slick-slide {
    /* position: relative; */
    overflow: hidden;
    width: ${({ allResources }) =>
      allResources ? "20rem !important" : "17rem !important"};

    visibility: visible;
    transition: all 0.3s ease;
    &:hover {
      /* width: 23rem !important; */
      width: ${({ allResources }) =>
        allResources ? "23rem !important" : "20rem !important"};
      height: ${({ allResources }) =>
        allResources ? "13rem !important" : "10rem !important"};
    }
  }

  /* align-content: center; */
  .slick-arrow.slick-prev {
    transition: opacity 0.5s ease-in-out;
    background: none;
    transform: rotate(180deg);
    position: absolute;
    /* top: -50%; */
    left: -0.5rem;
    top: 44%;
    z-index: 3;
  }
  .slick-arrow.slick-next {
    transition: opacity 0.5s ease-in-out;
    background: none;
    position: absolute;
    z-index: 3;
    right: 0.5rem;
    /* top: -20%; */
  }

  .slick-arrow.next-cus.slick-disabled,
  .slick-arrow.prev-cus.slick-disabled {
    opacity: 50%;
    cursor: auto;
    pointer-events: none;
    background: none;
  }
  .last-visible {
    right: 0 !important;
    left: 0 !important;
  }
  .slick-arrow.slick-prev.slick-disabled,
  .slick-arrow.slick-next.slick-disabled {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
    background: none;
  }
  /* right bottom*/
`;
