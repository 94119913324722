import React from 'react'

const documentTypeGenerator = (fileName: any) => {
    const ext = fileName?.split(".").pop().toLowerCase();
    if (ext === "docx" || ext === "doc") {
        return "WORD";
    } else if (ext === "csv" || ext === "xls" || ext === "xlsx") {
        return "XLS";
    } else if (ext === "ppt" || ext === "pptx") {
        return "PPT";
    } else if (ext === "pdf") {
        return "PDF";
    } else {
        return "";
    }
};

export default documentTypeGenerator;