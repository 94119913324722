import styled from "styled-components";

interface TimePlace{
justifyContent?:string;
}

export const PHCContainerParent = styled.div`
position:fixed;
bottom:0rem;
max-width:68%;
height:4rem;
/* background-color:blue; */
right:20rem;
z-index: 10;
display: flex;
flex-direction:row-reverse;
gap:0.60rem;
/* background-color:red; */
width:fit-content;
`;
 export const PHCContainer = styled.div`
 position:relative;
 bottom:0rem;
 width:100%;
 height:4rem;
 /* background-color:blue; */
 /* right:39rem; */
 /* z-index: 10; */
 display: flex;
 flex-direction:row-reverse;
 gap:0.60rem;
 /* background-color:yellow; */
 `;

export const PHCShrink = styled.div`
width:17rem;
background-color:#37444f;
display:flex;
justify-content:space-between;
align-items:center;
border-radius:  0.3rem  0.3rem 0 0;
/* padding: 0 0.5rem; */
/* border:1px solid #fff; */
/* border-bottom:none !important; */
border-radius:  1.25rem 1.25rem 0rem 0rem;
background: #384450;
height: 4rem;
border:1px solid #5dd3b3;
box-shadow: -5px -5px 20px 0px rgba(0, 0, 0, 0.10);
`;



export const PHCExpanded= styled.div<{bgColor?:string}>`
width:17rem;
max-height:22rem;
/* background-color:blueviolet; */
background:${props =>props.bgColor || "#384450"};
margin-top:-18rem;
border-radius:  0.3rem  0.3rem 0 0;
/* padding: 0 0.5rem; */
display: flex;
flex-direction:column;
/* border:1px solid #fff;
border-bottom:none !important; */
border-radius:  1.25rem 1.25rem 0rem 0rem;
/* background: #384450; */
gap:0.3rem;
box-shadow: -5px -5px 20px 0px rgba(0, 0, 0, 0.10);
/* border:1px solid #5dd3b3; */

`;

export const PHCCloseAction= styled.div`
cursor: pointer;
display: flex;
gap:0.5rem;
align-items:center;
`;

export const PHCFirstRow= styled.div`
display: flex;
justify-content:space-between;
padding:0.6rem;
border-bottom:1px solid #576371;
align-items:center;
width:100%;
`;


export const PHCGroupRow= styled.div`
display: flex;
gap:0.5rem;
align-items:center;
`;

export const PHCProfile= styled.img`
border: 1px solid linear-gradient(180deg, #2D3741 0%, #576371 100%);
padding: 0.12em;
border-radius: 50%;
width: 2.5rem;
height: 2.5rem;
flex-shrink: 0;
`;



export const PHCMessagesRow= styled.div`
border-top:1px solid #576371;
display: flex;
justify-content:center;
width:100%;


align-items:center;
padding:0.62rem;
gap:0.60rem;
/* background-color:yellowgreen; */
height:fit-content;
`;

export const PHCIcons= styled.div`
/* padding:0.25rem; */
border:1px solid #5DD3B3;
width: 1.25rem;
height: 1.25rem;
border-radius:50%;
display: flex;
justify-content:center;
align-items:center;
color:#5DD3B3;
cursor: pointer;
`;
export const PHCInput= styled.input<{color?:string,FocusColor?:string}>`
border-radius: 0.625rem;
opacity: 0.5;
background: rgba(0, 0, 0, 0.20);
height: 1.875rem;
width: 10rem;
flex-shrink: 0;
border:none !important;
padding: 0.50rem ;
color:#fff;
color:${props =>props.color};
bottom:0;
&:focus{
  outline: none;
}
`;


export const PHCBodyChat= styled.div`
display: flex;
width:100%;
height: 14.35rem;
min-height: 14.5rem;
/* background-color:green; */
flex-direction:column;
padding:0.70rem;
height:fit-content;
overflow-y: auto;
gap:0.6rem;

&::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-image: linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%);
    border-radius: 0.625rem;
    /* box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.25); */
  }

  &::-webkit-scrollbar-track {
    /* background-color: #2d3741; */
    border-radius: 0.625rem;
  }
`;

export const PHCIconWidth= styled.div`
padding:0.3rem;

`;

export const PHCMyMessageRow= styled.div`
display: flex;
width:100%;
justify-content:flex-end;

`;

export const PHCMyMessageChat= styled.div<{bgColor:string}>`
display: flex;
width:80%;
flex-direction:column;
gap:0.3rem;
border-radius: 0.625rem  0rem 0.625rem 0.625rem;
/* background: rgba(255, 255, 255, 0.03); */
background:${props =>props.bgColor};
padding:0.50rem;

`;

export const PHCMessageChatTime= styled.div<TimePlace>`
display: flex;
width:95%;
justify-content: ${props => props.justifyContent || 'flexStart'};
opacity:0.5;
margin:auto;
`;

export const PHCReceivedMessageChat= styled.div<{bgColor:string}>`
display: flex;
width:80%;
flex-direction:column;
gap:0.3rem;
border-radius:0rem 0.625rem   0.625rem 0.625rem;
/* background: rgba(255, 255, 255, 0.03); */
background:${props =>props.bgColor};
padding:0.50rem;
`;

export const SVGPointer= styled.div`
display: flex;
cursor: pointer;
`;