import styled from "styled-components";


export const RubicViewView =styled.div`
display:flex;
width:100%;
gap:1.25rem;
flex-direction:column;
`;

export const RubicFlexView =styled.div`
display:flex;
width:100%;
gap:1rem;
align-items:start;
`;

export const RubricClacificationDiv =styled.div`
display:flex;
flex:2;
/* width:25%; */
margin-bottom:5rem;
`;
export const RubricTablenDiv =styled.div`
display:flex;
flex:10;
width:75%;

`;

export const RubricButtonFlexView =styled.div`
display:flex;
width:100%;
justify-content:center;
`;


export const TitleRubricView =styled.div`


width:100%;

`;