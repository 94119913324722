import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FlexAppContainer } from "../../../../App.style";
import AnalyticsAssignmentBarChart from "../../../../components/AnalyticsAssigmentResponseTraking/AnalyticsAssignmentBarChart/AnalyticsAssignmentBarChart";
import AnalyticsAssignmentGraphChart from "../../../../components/AnalyticsAssigmentResponseTraking/AnalyticsAssigmentGraphChart/AnalyticsAssigmentGraphChart";
import { StudentFinalGradeData } from "../../../../components/Tables/QuizQuestionsAnalytics/QuizFinalGradeData";
import AnalyticOneTitleHeader from "../../../../components/AnalyticShadowHeaderFourDisplays/AnalyticOneTitleHeader";
import QuizQuestionsAnalytics from "../../../../components/Tables/QuizQuestionsAnalytics/QuizQuestionsAnalytics";
import { SurveyQuestion } from "../../../../components/Tables/SurveyQuestionAnalytics/SurveyQuestionAnalytics";
import AnalyticQuizPies from "../../../../components/AnalyticQuizPies/main/AnalyticQuizPies";
import AnalyticQuizHeaderPieandProgressBar from "../../../../components/AnalyticQuizPies/AnalyticQuizHeaderPieandProgressBar/AnalyticQuizHeaderPieandProgressBar";
import AnalyticsBackPage from "../../../../components/AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticsBackPage";
import { Typography } from "../../../../elements/fonts/Fonts";
import AnalyticQuizShadowHeader from "../../../../components/AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticQuizShadowHeader";
import { QuizQuestionsAnalyticsDataV2 } from "../../../../components/Tables/QuizQuestionsAnalytics/QuizQuestionsAnalyticsDataV2";

const QuizAnalyticsDetails = () => {
  const { MainCode } = useParams<{ MainCode: string }>();
  const [ShowDetails, setShowDetails] = useState(false);
  const [ShowGrade, setShowGrade] = useState(false);
  const [ShowTracking, setShowTracking] = useState(false);
  const handleShowDetails = () => {
    setShowDetails(!ShowDetails);
  };
  const handleShowGrade = () => {
    setShowGrade(!ShowGrade);
  };

  const handleShowTacking = () => {
    setShowTracking(!ShowTracking);
  };
  const [ChosenQuestion, setChosenQuestion] = useState<SurveyQuestion | null>(
    null
  );
  const navigate = useNavigate();
  const NavigatePage = () => {
    navigate(-1);
  };

  return (
    <FlexAppContainer column={true} padding="0 0 2rem 0" gap="1.25rem">
      <AnalyticsBackPage onClick={NavigatePage} />
      <Typography text="Quiz Analysis" fontSize="1.75rem" />
      {ShowTracking ? (
        <AnalyticQuizHeaderPieandProgressBar onClick={handleShowTacking} />
      ) : (
        <AnalyticOneTitleHeader
          Title="Grade Distribution"
          onClick={handleShowTacking}
        />
      )}
      {ShowDetails ? (
        <>
          {ChosenQuestion ? (
            <AnalyticQuizPies
              ChosenQuestion={ChosenQuestion}
              setChosenQuestion={setChosenQuestion}
            />
          ) : (
            <QuizQuestionsAnalytics
              TitleClick={handleShowDetails}
              setChosenQuestion={setChosenQuestion}
            />
          )}
        </>
      ) : (
        <AnalyticOneTitleHeader
          Title="Participation Tracking"
          onClick={handleShowDetails}
        />
      )}

      {ShowGrade ? (
        <FlexAppContainer column={true} gap="1.25rem" padding="0 0 2rem 0">
          <AnalyticsAssignmentBarChart
            Data={StudentFinalGradeData.Students}
            title="Scorm Distribution"
            titleClick={handleShowGrade}
          />
          <AnalyticsAssignmentGraphChart
            title="Scorm Distribution"
            titleClick={handleShowGrade}
            Data={StudentFinalGradeData.Students}
            MaxGrade={StudentFinalGradeData.MaxGrade || 20}
          />
        </FlexAppContainer>
      ) : (
        <AnalyticOneTitleHeader
          Title="Participation Tracking"
          onClick={handleShowGrade}
        />
      )}
    </FlexAppContainer>
  );
};

export default QuizAnalyticsDetails;
