import React, { FC } from "react";
import {
  IconContainer,
  IconPointsContainer,
  QuestionFlexContainer,
} from "./QuestionContent.style";
import {
  TypographyRegularDescription,
  Typography,
} from "../../../elements/fonts/Fonts";
import { QuestionContentProps } from "./QuestionContentInterface";
import { theme } from "../../../theme";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  courseCreatorColorSelector,
} from "../../../recoil/ThemeSelectors";
const QuestionContent: FC<QuestionContentProps> = ({
  question,
  handleShowQuest,
  setIndex,
  index,
}) => {
  const { t } = useTranslation();
  const language = localStorage.getItem("language")

  const mapQuestionType = (questionType: string): string => {
    switch (questionType) {
      case "MCQ":
        return `${t("quiz.MULTIPLE CHOICE").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}`;
      case "BINARY":
        return `${t("survey.YES").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")} - ${t("survey.NO").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}`;
      case "ESSAY":
        return `${t("quiz.ESSAY").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}`;
      case "RATE":
        return `${t("survey.RATING").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}`;
      case "SCALE":
        return `${t("survey.SCALE").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}`;
      default:
        return questionType;
    }
  };
    const SurveyModifyColor = (name: string)=> {
    switch (name) {
      case "MCQ":
        return "#0B8FFF";
      case "BINARY":
        return "#8CEDEA";
      case "ESSAY":
        return "#B757E2";
      case "RATE":
        return "#CED35D";
      case "SCALE":
        return "#8CED99";
      default:
        return "";
    }
  }

  const questionType = mapQuestionType(question.type || "");
  const { statisticsTextColor, theme } = useRecoilValue(
    courseCreatorColorSelector
  );
  const { SecondaryTextColor } = useRecoilValue(ColorSelector);
  
  return (
    // <IconPointsContainer onClick={()=>{setIndex(index);handleShowQuest()}}>
    <IconPointsContainer>
      <div style={{marginTop:"-0.2rem",color:SurveyModifyColor(question.type || "")}}>
      {question.icon}</div>
      <QuestionFlexContainer>
        <IconContainer>
          <div style={{ marginTop: "-0.2rem" }}>
            <TypographyRegularDescription
              text={questionType}
              fontSize="0.75rem"
              lineHeight="1.125rem"
              color={
                theme === "light"
                  ? statisticsTextColor 
                  : statisticsTextColor
              }
              // color='rgba(255, 255, 255, 0.50)'
            />
          </div>
          {/* <Typography
            text={`${question?.grade} pts`}
            fontSize="0.75rem"
            color={theme.dark.main_color}
          /> */}
        </IconContainer>
        <TypographyRegularDescription
          text={question?.name}
          fontSize="0.75rem"
          lineHeight="1.125rem"
          color={
            theme === "light" ? statisticsTextColor : statisticsTextColor + "20"
          }
        />
      </QuestionFlexContainer>
    </IconPointsContainer>
  );
};

export default QuestionContent;
