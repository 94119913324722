import React from "react";
import { FlexAppContainer } from "../../App.style";
import { PaymentsFinallyRenderData } from "./PaymentsFinallyRenderData";
import {
  PaymentsFinallyRenderContainer,
  PaymentsFinallyRenderFeaturesContainer,
  PaymentsFinallyRenderFeaturesLine,
  PaymentsFinallyRenderFeaturesTitlesContainer,
  PaymentsFinallyRenderFeaturesUl,
  PaymentsFinallyRenderHeader,
  PaymentsFinallyRenderLine,
  PaymentsFinallyRenderPricingPerUser,
  PaymentsFinallyTicChooseBtn,
  PaymentsFinallyTicCorrectPlace,
} from "./PaymentsFinallyRender.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import { SvgCheckModuleTopic } from "../../elements/Icons";

const PaymentsFinallyRender = () => {
  return (
    <FlexAppContainer wrap={true} gap="1.25rem" justifyContent="center">
      {PaymentsFinallyRenderData.map((data) => (
        <PaymentsFinallyRenderContainer key={data.PackageId}>
          <PaymentsFinallyRenderHeader>
            <MediumTypography
              text={data.PackageName}
              color="#576474"
              fontSize="1.25rem"
              lineHeight="1.625rem"
            />
            <PaymentsFinallyRenderPricingPerUser>
              <Typography
                text={data.PackagePrice}
                color="#5DD3B3"
                fontSize="2.5rem"
              />
              <LightTypography
                text=" / User"
                fontSize="1.25rem"
                color="#576474"
              />
            </PaymentsFinallyRenderPricingPerUser>
          </PaymentsFinallyRenderHeader>
          <PaymentsFinallyRenderLine />
          {data.Features && (
            <PaymentsFinallyRenderFeaturesContainer>
              <Typography text="Features" fontSize="1.125rem" color="#576474" />
              <PaymentsFinallyRenderFeaturesTitlesContainer>
                {data.Features.map((feature, index) => (
                  <div key={index}>
                    <PaymentsFinallyRenderFeaturesLine
                      key={feature.FeaturesText}
                    >
                      <PaymentsFinallyTicCorrectPlace>
                        <SvgCheckModuleTopic />
                      </PaymentsFinallyTicCorrectPlace>
                      <TypographyRegularDescription
                        text={feature.FeaturesText}
                        color="#2D3741"
                        fontSize="0.875rem"
                        lineHeight="1.625rem"
                      />
                    </PaymentsFinallyRenderFeaturesLine>
                    <PaymentsFinallyRenderFeaturesUl>
                      {feature.FeaturesData && (
                        <ul>
                          {feature.FeaturesData.map((info, idx) => (
                            <li key={idx}>
                              <LightTypography
                                text={info}
                                fontSize="0.75rem"
                                lineHeight="1.375rem"
                              />
                            </li>
                          ))}
                        </ul>
                      )}
                    </PaymentsFinallyRenderFeaturesUl>
                  </div>
                ))}
              </PaymentsFinallyRenderFeaturesTitlesContainer>
            </PaymentsFinallyRenderFeaturesContainer>
          )}
          {/* <FlexAppContainer justifyContent="center"> */}
          <PaymentsFinallyTicChooseBtn>
            <Typography text="Choose" fontSize="0.9375rem" />
          </PaymentsFinallyTicChooseBtn>
          {/* </FlexAppContainer> */}
        </PaymentsFinallyRenderContainer>
      ))}
    </FlexAppContainer>
  );
};

export default PaymentsFinallyRender;
