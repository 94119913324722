import { useAuth0 } from '@auth0/auth0-react';
import DOMPurify from 'dompurify';
import React, { useEffect } from 'react'
import { auth0SubIdSplitter,auth0ProviderSplitter } from '../../../utils/StringManipulation';
import { useParams } from 'react-router-dom';
import { findCommentForAnswer, findStudentAnswer } from './surveyStudentCompoUtils';
import { CheckBox,StudentCheckboxRowSurvey } from '../../../elements/StudentsQuizElements/StudentQuizCheckbox/StudentQuizCheckbox.style';
import { useRecoilValue } from 'recoil';
import { ColorSelector } from '../../../recoil/ThemeSelectors';
import { TypographyRegularDescription } from '../../../elements/fonts/Fonts';
import { StudentSurveyInstructor, StudentSurveyInstructorReply } from './StudentSurvey.style';
import { useTranslation } from 'react-i18next';

interface SurveyMCQProps {
    ShowSave: boolean;
    handleCheckboxChange:any;
    indexOfFirst:number;
    index:number
    question:any;
    studentAnswerArray:any
    id:number
    selectedChoices:any
    setSelectedChoices:any
    setSurveyQuestionId:any
  }

const SurveyMCQ = ({ 
    ShowSave,
    handleCheckboxChange,
    index,
    indexOfFirst,
    id,
    studentAnswerArray,
    question,
    selectedChoices,
    setSelectedChoices,
    setSurveyQuestionId
 }: SurveyMCQProps) => {
    const { user } = useAuth0();
    const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
    const provider = auth0ProviderSplitter(user?.sub || "");
    const params = useParams();
    const classId = params.classId;
    const { RadioButtonGradientColor, themeTextColor, theme } =
    useRecoilValue(ColorSelector);
    const { t } = useTranslation();

    const decodeHtml = (value: any) => {
        const textarea = document.createElement("textarea");
        textarea.innerHTML = value;
        return textarea.value;
      };

      useEffect(()=>{
        const key = `${question.title}`;
    const idKey = `${key}_ID`;
    setSurveyQuestionId((prevId:any) => ({
      ...prevId,
      [idKey]: question.SurveyQuestionsId || 0,
    }));
        if(studentAnswerArray && studentAnswerArray.length === 0 ){
          // Fetch and set the timer from local storage if it exists
          const savedStudentAnswer = JSON.parse(localStorage.getItem('localStudentSurveyResponse') || '[]');
          const currentQuizAnswer = savedStudentAnswer.find((item: any) =>
            item.classSourceId === classId &&
            item.subId === subIdSplitted &&
            item.refId === id
          );
    
          if (currentQuizAnswer &&
            question) {
            
          if(currentQuizAnswer && currentQuizAnswer.localStudentResponse && currentQuizAnswer.localStudentResponse[key]){
            setSelectedChoices((prevLineValues:any) => ({
              ...prevLineValues,
              [key]: currentQuizAnswer.localStudentResponse[key],
            }));
            }else{
                setSelectedChoices((prevLineValues:any) => ({
                    ...prevLineValues,
                    [key]: [],
                  }));
            }
          }
          }
      },[question])

  return (
    <>
                    
                    <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              decodeHtml(question.question || "")
                            ),
                          }}
                          style={{
                            fontSize: "1rem"
                          }}
                        ></div>
                    {question.choices &&
                      question.choices.length > 0 &&
                      question.choices.map((Choice:any, choiceIndex:number) => (
                        <>
                          <StudentCheckboxRowSurvey key={choiceIndex}>
                            <CheckBox
                              bgColor={RadioButtonGradientColor}
                              type="radio"
                              id={Choice.id && Choice.id.toString()}
                              onChange={() =>
                                handleCheckboxChange(Choice, indexOfFirst + index)
                              }
                              name={question.title}
                              value={Choice.choice}
                              disabled={!ShowSave}
                              checked={
                                (
                                  (findStudentAnswer(
                                    question.SurveyQuestionsId || 0,
                                    studentAnswerArray,
                                    question.title || '',
                                    classId || '',
                                    subIdSplitted,
                                    id,
                                    question.type || ''
                                  ) ?? []) as {
                                    id: number;
                                    index: number;
                                    choice: string;
                                  }[]
                                ).some((choice: any) => {
                                  if (typeof choice === "string" && choice !== null) {
                                    return choice === Choice.choice; // Compare string choice
                                  } else if( choice !== null) {
                                    return choice.choice === Choice.choice; // Compare object choice
                                  }else {
                                    return false
                                  }
                                }) ||
                                (selectedChoices &&
                                    index !== undefined &&
                                    selectedChoices[`${question.title}`]?.some(
                                      (choice: any) => {
                                        return choice && choice !== null && choice.id && choice.id === Choice.id;
                                      }
                                    )) ||
                                  false
                              }
                            />
                            <TypographyRegularDescription
                              text={`${Choice?.choice}`}
                              fontSize="16px"
                              lineHeight="22px"
                              fontWeight="400"
                            />
                          </StudentCheckboxRowSurvey>
                        </>
                      ))}
                    {/* The Comment of the instructor for the answer goes Here */}
                    {findCommentForAnswer(
                      question.SurveyQuestionsId || 0,
                      studentAnswerArray
                    ) &&
                      findCommentForAnswer(
                        question.SurveyQuestionsId || 0,
                        studentAnswerArray
                      ).trim() !== "No Comment" && (
                        <StudentSurveyInstructor>
                          <TypographyRegularDescription
                            text={t("assignment.Instructor Comment")}
                            fontSize="0.875rem"
                          />
                          <StudentSurveyInstructorReply color={themeTextColor} theme={theme}>
                            <TypographyRegularDescription
                              text={findCommentForAnswer(
                                question.SurveyQuestionsId || 0,
                                studentAnswerArray
                              )}
                              fontSize="0.75rem"
                            />
                          </StudentSurveyInstructorReply>
                        </StudentSurveyInstructor>
                      )}
                  </>
  )
}

export default SurveyMCQ