import AssignmentInstructor from "../../components/ActivitiesInstructorView/Assignment/AssignmentInstructor"
import AttendanceAllSessions from "../../components/attendanceAllSessions/AttendanceAllSessions"
import GradesItem from "../../components/gradesItem/GradesItem"

const GradeItemView = () => {
  return (
    
    <GradesItem/>

  )
}

export default GradeItemView