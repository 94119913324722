import styled from 'styled-components';
import { StyleThemeInterface } from '../../../interfaces/StyleThemeInterface';

export const Container=styled.div<StyleThemeInterface>`
display: flex;
align-items:flex-start;
flex-direction:column;
align-self:flex-end;
 min-width: 15.625rem;
width:100%;
 min-height: 33.3125rem;
 /* overflow-y: auto; */
border-radius: 0.625rem;
background:${props => props.bgcolor ||  "linear-gradient(180deg, #415160 0%, #293440 100%)"};
box-shadow:${props => props.boxshadow ||  "0px 4px 20px 0px rgba(0, 0, 0, 0.25)"};





&::-webkit-scrollbar {
  width: 0.2rem;
}


&::-webkit-scrollbar-thumb {
  background-color: #5DD3B3;
  border-radius: 0.25rem; 
}


&::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

 @media (max-width: 760px) {
  
    width:auto;
   
  } 
`;

export const QuestionsContainer=styled.div`
height: 23.3125rem;
overflow-y: auto;
width:100%;
&::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(
      --Green-Gradient,
      linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)
    );
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0.625rem;
  }
`

