import styled from "styled-components";
import { breakpoints } from "../../../theme";

export const CourseObjectivesMainWrapper = styled.div`
  width: 100%;
  & > :first-child {
    margin-bottom: 0.63rem;
  }
`;
export const CourseObjectivesWrapper = styled.div`
  column-count: 2;
  column-gap: 2.5rem;
  max-height: 25rem;
  min-width: fit-content;
  @media (max-width: ${breakpoints.tablets}) {
    column-count: 1;
    max-height: max-content;
  }
`;

export const CheckedWithTextWrapper = styled.div``;
