import React, { FC, useState } from "react";
import { TypographyRegularDescription } from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import {
  QuestionNumberContainer,
  BreakLine,
  PaginationContainer,
  PaginationContent,
  Number,
  BreakLineNumber,
} from "./QuestionNumber.style";
import { QuestionsNumberProps } from "./QuestionsNumberInterface";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";

const QuestionNumber: FC<QuestionsNumberProps> = ({
  questions,
  setQuestionIndex,
}) => {
  const { t } = useTranslation();
  const [numberIndex, setNumberIndex] = useState<number>(0);
  const handleNumber = (index: number) => {
    setQuestionIndex(index);
    setNumberIndex(index);
  };
  const { themeTextColor } = useRecoilValue(ColorSelector);
  const language = localStorage.getItem("language");
  return (
    <>
      <QuestionNumberContainer>
        <TypographyRegularDescription
          text={t("quiz.Question Number")}
          fontSize="0.75rem"
        />
      </QuestionNumberContainer>
      <PaginationContainer
        style={{
          paddingLeft: `${language === "ar" ? "" : "0.6rem"}`,
          paddingRight: `${language === "ar" ? "0.6rem" : ""}`,
        }}
      >
        {/* <BreakLine /> */}
        {questions.map((question, index) => (
          <PaginationContent key={index}>
            <BreakLineNumber bgColor={themeTextColor} />
            <Number
              color={index % 2 === 0 ? "#0B8FFF" : "#5DD3B3"}
              onClick={() => handleNumber(index)}
              selected={index === numberIndex}
            >
              {index + 1}
            </Number>
          </PaginationContent>
        ))}
      </PaginationContainer>
    </>
  );
};

export default QuestionNumber;
