import { Container, HDevideLine } from "./CourseToolbar.style";
import CourseToolbar from "./CourseToolbar";
import { aboveLine, underLine } from "./courseToolbarData";
import React, { useEffect, useState } from "react";

import { useRecoilValue } from "recoil";
import { courseCreatorColorSelector } from "../../recoil/ThemeSelectors";
import { useTeamsClass } from "../../utils/ClassHasTeams";
import { useParams } from "react-router-dom";

const ItemDraggable = () => {
  const [scrollPosition, setScrollPosition] = useState(11); // Set the initial scroll position
  const classSourceId = useParams();
  const classId = classSourceId.id;
  const { withTeams } = useTeamsClass(classId)

  // Recoil state and selectors
  const {
    miniSideBarBgColor,
    lineIconColor,
    miniSideBarOpacity,
    tooltipBgColor,
    tooltipTextColor,
    boxShadow,
  } = useRecoilValue(courseCreatorColorSelector);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY - window.innerHeight / 2;
      setScrollPosition(currentScrollPosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Run effect only once on component mount

  // filter teams item
  const filteredAboveLine = withTeams
  ? aboveLine
  : aboveLine.filter((item) => item.type !== "Teams");

  return (
    <Container
      scrollposition={scrollPosition}
      bgcolor={miniSideBarBgColor}
      // opacity={miniSideBarOpacity}
    >
      {filteredAboveLine.map((itemAbove, indexAbove) => (
        <CourseToolbar key={indexAbove} item={itemAbove} index={indexAbove} />
      ))}
      <HDevideLine lineiconcolor={lineIconColor}></HDevideLine>
      {underLine.map((itemUnder, indexUnder) => (
        <CourseToolbar key={indexUnder} item={itemUnder} index={indexUnder} />
      ))}
    </Container>
  );
};

export default ItemDraggable;
