import React, { ChangeEvent, useEffect, useState } from "react";
import { AddNew, Container, Title, TitleAndSearch, Titles, TitlesCont, TopContent, } from "./RubricsList.style";
import TitleHeaderTextIconNavigator from "../../elements/TitleHeaderTextIconNavigator/TitleHeaderTextIconNavigator";
import { LightTypography, Typography, } from "../../elements/fonts/Fonts";
import SearchWithFilter from "../../elements/searchWithFilter/SearchWithFilter";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { SvgAdd, SvgEditIcon } from "../../elements/Icons";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import RubricsListTable from "../../components/rubricsListTable/RubricsListTable";
import { useTranslation } from "react-i18next";
import LoaderCircle from "../../components/loader/loaderCircle/LoaderCircle";
import { FlexDiv } from "../../globalStyles";

const RubricsList = () => {
  const navigate = useNavigate();
  const tenantName = localStorage.getItem("tenant");
  const { classId } = useParams();
  const { theme } = useRecoilValue(ColorSelector);
  const { t } = useTranslation();

  const [rubricTitles, setRubricTitles] = useState<any[]>([]);
  const [rubricData, setRubricData] = useState<any>([]);
  const [selectedRubricId, setSelectedRubricId] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getRubricTitles = async () => {
      try {
        const response = await axios.get(
          `https://0j157bzxcf.execute-api.eu-west-1.amazonaws.com/dev/get/all/${classId}/${tenantName}`
        );
        setRubricTitles(response.data.data.rubricsData);
      } catch (error) {
        console.log(error);
      }
    };
    getRubricTitles();
  }, [classId]);

  useEffect(() => {
    const getRubricById = async () => {
      setIsLoading(true)
      if (selectedRubricId) {
        try {
          const response = await axios.get(
            `https://0j157bzxcf.execute-api.eu-west-1.amazonaws.com/dev/get/${selectedRubricId}/${tenantName}`
          );
          setRubricData(response.data.data);
          setIsLoading(false)
        } catch (error) {
          console.log("Error", error);
        }
      }
    };
    getRubricById();
  }, [selectedRubricId, tenantName]);

  const handleSelectRubric = (rubricId: string) => {
    setSelectedRubricId(rubricId === selectedRubricId ? "" : rubricId);
  };

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value); // Extract the value from the event and update the state
  };

  const filteredRubricsList = rubricTitles.filter((rubric) => {
    return rubric.title.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const state = { navigatedFrom: 'rubricsList' };

  return (
    <Container>
      <TopContent>
        <TitleHeaderTextIconNavigator title={t("Rubric.Rubric")} navigateTo={-1} />
        <TitleAndSearch>
          <Typography text={t("Rubric.List Of Rubrics")} fontSize="1.375rem" />
          <SearchWithFilter
            placeholder={t("dashboard.Search")}
            withFilter={false}
            onChange={handleSearchInputChange}
            width="23.625rem"
          />
        </TitleAndSearch>
      </TopContent>

      <AddNew onClick={() => navigate(`/courseView/Gradebook/${classId}/Rubric`)} theme={theme}>
        <SvgAdd color={theme === "light" ? "#5DD3B3" : "#fff"}/>
        <Typography text={t("Rubric.Add New Rubric")} fontSize="0.75rem" />
      </AddNew>

      <TitlesCont>
        {filteredRubricsList.map((rubricTitle: any, index: number) => (
          <Titles key={index} theme={theme} style={{ cursor: "pointer" }} onClick={() => handleSelectRubric(rubricTitle.id)}>
            <Title>
              <Typography text={rubricTitle.title} />
              {selectedRubricId === rubricTitle.id &&
              rubricData &&
              rubricData.criteria &&
              rubricData.levels ? (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(
                      `/courseView/Gradebook/${classId}/RubricUpdate/${rubricTitle.id}`, {state}
                    )
                  }
                >
                  <SvgEditIcon />
                </div>
              ) : (
                ""
              )}
            </Title>
            {selectedRubricId === rubricTitle.id &&
            rubricData &&
            rubricData.criteria &&
            rubricData.levels ? (
              <>
              {isLoading ? (
                <FlexDiv style={{margin: "0 0 1.25rem 1.25rem"}}><LoaderCircle color="#5dd3b3" /><LightTypography text={t("general.loading")} fontSize="0.875rem"/></FlexDiv>
              ) : (
              <RubricsListTable rubricData={rubricData} />
            )}
            </>) : (
              ""
            )}
          </Titles>
        ))}
      </TitlesCont>
    </Container>
  );
};

export default RubricsList;
