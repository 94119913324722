import React from "react";
import {
  ASIDContainer,
  ASIDFistInfo,
  ASIDProfileInfo,
  ASIDProfileImg,
} from "./AttendanceStudentID.style";
import { GradeBookPopupData } from "./AttendanceStudentIData";
import { LightTypography, Typography } from "../../../elements/fonts/Fonts";
import ScrollLeftAndRight from "../../../elements/ScrollLeftAndRight/ScrollLeftAndRight";
import ASIDSessionTableCompo from "./ASIDSessionTableCompo";

const AttendanceStudentID = () => {
  return (
    <>
      {GradeBookPopupData.map((data, index) => (
        <ASIDContainer key={index}>
          <ASIDFistInfo>
            <Typography text={data.major || ""} fontSize="1rem" />
            <LightTypography text={data.year || ""} />
          </ASIDFistInfo>
          <ASIDProfileInfo>
            <ASIDProfileImg src={data.profile} />

            <Typography text={data.fullNAME || ""} fontSize="1rem" />
          </ASIDProfileInfo>
          <ScrollLeftAndRight content={<ASIDSessionTableCompo />} />
        </ASIDContainer>
      ))}
    </>
  );
};

export default AttendanceStudentID;
