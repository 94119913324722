import styled from "styled-components";
import { breakpoints } from "../../../theme";

export const InstructorsMainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
`;

export const InstructorsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0.63rem;
`;
export const InstructorInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
`;
export const CourseHoverHolder = styled.div<{ ishovered: boolean }>`
  /* cursor: pointer; */
  position: absolute;
  top: 0.5;

  z-index: 2;
  display: ${(props) => (props.ishovered ? "block" : "none")};
  opacity: ${(props) => (props.ishovered ? "1" : "0")};
  transition: opacity 0.4s ease-in-out;
  &:dir(ltr) {
    left: 7rem;
  }
  &:dir(rtl) {
    right: 7rem;
  }
`;
export const ProfileWithSubtitlesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${breakpoints.tablets}) {
    flex-direction: column;

    /* flex-wrap: wrap; */
  }
`;
export const ProfileWithSubtitlesWrapper = styled.div`
  display: flex;
  column-gap: 1.62rem;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${breakpoints.tablets}) {
    flex-direction: column;
    align-items: start;
    row-gap: 0.31rem;
  }
`;
export const SubtitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  & > :first-child {
    color: ${({ theme }) => theme.dark.main_color};
  }
  @media (max-width: ${breakpoints.tablets}) {
    margin-bottom: 0.94rem;
  }
`;

export const BioWrapper = styled.div`
  @media (min-width: ${breakpoints.tablets}) {
    margin-left: 7.95rem;
  }
`;
