import React, { useState } from 'react'
import { FlexBetween, FlexDiv, OneChoices, Progress, ProgressBar } from '../PollClassView.style'
import CheckBoxInput from '../../../elements/checkbox/CheckBox'
import { MediumTypography } from '../../../elements/fonts/Fonts'
import { LimitedChoicesInterface } from './LimitedChoicesInterface'
import PopUp from '../../../elements/Popup/PopUp'
import LimitedChoicesMessage from '../../limitedChoicesMessage/LimitedChoicesMessage'

const LimitedChoices = ({index, handleVote, poll, selectedOption, calculatedLimit, showLimitedChoiceMessage, setShowLimitedChoiceMessage} : LimitedChoicesInterface) => {
  return (
    <OneChoices key={index}>
    <FlexBetween>
        <FlexDiv onClick={() => handleVote(poll.optionId)} style={{cursor: "pointer"}}>
            <CheckBoxInput onChange={() => handleVote(poll.optionId)} checked={selectedOption === poll.optionId} name="choice" value="" width="0.75rem" height="0.75rem" border="1px solid #576474" background="transparent"/>
            <MediumTypography text={poll.optionName}/>
        </FlexDiv>
        <FlexDiv>
        <MediumTypography text={`${poll.voters.length}`} fontSize="0.75rem"/>
        </FlexDiv>
    </FlexBetween>
        <ProgressBar>
            <Progress width={`${calculatedLimit(poll.voters.length)}%`} background={index % 2 === 0 ? '#5DD3B3' : '#0B8FFF'}/>
        </ProgressBar>

    {showLimitedChoiceMessage && (
        <PopUp
        content={<LimitedChoicesMessage/>}
        showline={false}
        show={showLimitedChoiceMessage}
        setShow={setShowLimitedChoiceMessage}
        showButton={false}
        paddingRight="1.25rem"
        paddingTop='1.25rem'
        />
    )}
    </OneChoices>
  )
}

export default LimitedChoices