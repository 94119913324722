import Faq from "../../components/Faq/Faq";
// import { useState } from "react";

const FaqView = () => {

  return (<>
      <Faq  />
   
      </>
  )
}

export default FaqView;
