import styled from "styled-components";

export const SelectedOptionsBox = styled.div<{
  bgcolor?: string;
  width?: string;
  boxshadow?: string;
}>`
  display: flex;
  flex-direction: column;
  min-width: 6.8rem;
  width: ${(props) => props.width || "fit-content"};
  height: fit-content;
  border-radius: 0.3125rem;
  background: ${(props) => props.bgcolor || "#1F2731"};
  box-shadow: ${(props) => props.boxshadow};
  z-index: 15;
  cursor: pointer;
`;

export const SelectedOptionsLabel = styled.div<{
  bgcolor?: string;
  hoverbgcolor?: string;
  padding?: string;
  hoveredcolor?: string;
  FirstLabelClick?: any
}>`
  display: inline-flex; /* Updated display property */
  padding: ${(props) => props.padding || "0.63rem"};
  width: 100%;
  gap: 0.62rem;
  align-items: center;
  background: ${(props) => props.bgcolor || "#1F2731"};
  z-index: 15;
  &:first-child {
    border-radius: 0.3125rem 0.3125rem 0 0;
  }
  &:last-child {
    border-radius: ${props => props.FirstLabelClick ? "0 0 0.3125rem 0.3125rem" : "0.3125rem"};
  }
  &:hover {
    background: ${(props) => props.hoverbgcolor || "#5DD3B3"};
    transition: background 0.3s ease; /* Add transition for background color */
    color: #fff;
  }
`;
