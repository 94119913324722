import React, { useEffect } from "react";
import { AnalyticsPiesInterface } from "../../../../interfaces/AnalyticsCommonInterfaces";
import {
  AnalyticScrollablePlaceCell,
  AnalyticScrollablePlaceLine,
} from "../../../AnalyticsSurveyPies/main/AnalyticScrollabelPlace/AnalyticScrollabelPlace.style";
import { QuizQuestionsAnalyticsDataV2 } from "../../../Tables/QuizQuestionsAnalytics/QuizQuestionsAnalyticsDataV2";
import { Typography } from "../../../../elements/fonts/Fonts";
import { AQSvgMoreType } from "../../../AnalyticsQuizzes/AnalyticsQuizzes.style";
import { SvgGrade } from "../../../../elements/Icons";
import { QuizQuestionsAnalyticsInterfaceV2 } from "../../../Tables/QuizQuestionsAnalytics/QuizQuestionsAnalyticsinterfaceV2";

const AnalyticQuizPiesScrollablePlace = ({
  ChosenQuestion,
  setChosenQuestion,
}: AnalyticsPiesInterface) => {
  const handleQuestionObject = (
    question: QuizQuestionsAnalyticsInterfaceV2
  ) => {
    setChosenQuestion && setChosenQuestion(question);
  };

  return (
    <AnalyticScrollablePlaceLine>
      {QuizQuestionsAnalyticsDataV2.map((Qval, index) => (
        <AnalyticScrollablePlaceCell
          Opacity={ChosenQuestion === Qval ? "1" : "0.5"}
          key={index}
        >
          <Typography
            text={`${Qval.QuestionTitle} ( ${Qval.QuestionType} )`}
            fontSize="1.125rem"
            lineHeight="1.75rem"
          />
          <AQSvgMoreType
            onClick={() =>
              handleQuestionObject(Qval as QuizQuestionsAnalyticsInterfaceV2)
            }
          >
            <SvgGrade />
          </AQSvgMoreType>
        </AnalyticScrollablePlaceCell>
      ))}
    </AnalyticScrollablePlaceLine>
  );
};

export default AnalyticQuizPiesScrollablePlace;
