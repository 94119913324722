import React, { FC } from "react";

import { Item, IconWrapper, Icon, Tooltip } from "./ToolBar.style";
import { useDrag } from "react-dnd";
import { ToolBarProps } from "./ToolBarInterface";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";

const ToolBar: FC<ToolBarProps> = ({ item }) => {
  const { t } = useTranslation();
  const language = localStorage.getItem("language")

  const mapQuestionType = (questionType: string): string => {
    switch (questionType) {
      case "MCQ":
        return `${t("quiz.MULTIPLE CHOICE").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}`;
      case "TF":
        return `${t("quiz.TRUE").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")} - ${t("quiz.FALSE").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}`;
      case "SHORT_ANSWER":
        return `${t("quiz.SHORT ANSWER").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}`;
      case "NUMERIC":
        return `${t("quiz.NUMERICAL").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}`;
      case "ESSAY":
        return `${t("quiz.ESSAY").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}`;
      case "MATCH":
        return `${t("quiz.Matching").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}`;
      default:
        return questionType;
    }
  };

  const [, drag] = useDrag({
    type: "ITEMS",
    item: { type: "ITEMS", data: item },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const questionType = mapQuestionType(item.name);
  const { bg_selectColor, default_boxShadow } = useRecoilValue(ColorSelector);
  return (
    <IconWrapper>
      <Item ref={drag}>
        <Icon color={item.color} className="iconItem">
          {item.icon}
        </Icon>
      </Item>
      <Tooltip
        bgcolor={bg_selectColor}
        boxshadow={default_boxShadow}
      >
        {questionType}
      </Tooltip>
    </IconWrapper>
  );
};

export default ToolBar;
