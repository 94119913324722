export const colorStyles = (
  color?: string,
  background?: string,
  borderColor?: string,
  theme?: string,
  minHeight?: string
) => ({
  control: (styles: any) => ({
    ...styles,
    color: color,
    backgroundColor: "transparent",
    minWidth: "400px",
    minHeight: minHeight ? minHeight : "70px",
    overflowY: "auto",
    border: `1px solid ${borderColor}`,
    borderRadius: "9px",
    boxShadow: "0",
    ":hover": {
      border: `1px solid ${borderColor}`,
    },
    width: "100%",
  }),
  input: (styles: any) => ({
    ...styles,
    color: color,
  }),
  option: (styles: any) => ({
    ...styles,

    color: color,
    backgroundColor: background,
    ":hover": {
      backgroundColor: "#5DD3B3",
      color: "#fff",
    },
  }),
  menu: (styles: any) => ({
    ...styles,
    marginTop: "0",
    width: "calc(100% + 11px)",
    backgroundColor: background,
    "&::-webkit-scrollbar": {
      width: "0.5rem",
    },
    "&::-webkit-scrollbar-thumb": {
      background:
        theme === "light"
          ? "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)"
          : "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)",
      borderRadius: "0.625rem",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
      borderRadius: "0.625rem",
    },
  }),
  menuList: (styles: any) => ({
    ...styles,

    "&::-webkit-scrollbar": {
      width: "0.5rem",
    },
    "&::-webkit-scrollbar-thumb": {
      background:
        theme === "light"
          ? "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)"
          : "linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)",
      borderRadius: "0.625rem",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
      borderRadius: "0.625rem",
    },
  }),
  multiValue: (styles: any) => ({
    ...styles,
    // backgroundColor: "#5DD3B3",
    background: theme === "light" ? "#F7F7F8" : "rgba(255, 255, 255, 0.03)",
    color: "#fff",
    borderRadius: "0.625rem",
    padding: "0.2rem",
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: color,
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: "#5DD3B3",
    cursor: "pointer",
    ":hover": {
      color: color,
    },
  }),
});
