import React, { useEffect, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import { ReactComponent as SvgPlygonIcon } from "../../assets/icons/Arrow.svg";
import {
  Dropdown,
  Input,
  InputContainer,
  OptionItem,
  PolygonDiv,
} from "./SelectDropDown.style";
import { InputWithDropdownProps, Option } from "./SelectDropDownInterface";
import { LightTypography, MediumTypography, Typography } from "../fonts/Fonts";
import { FlexDiv } from "../../globalStyles";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useRecoilValue } from "recoil";

// React component
const SelectDropDown: React.FC<InputWithDropdownProps> = ({
  disabled,
  value,
  placeholder,
  onChange,
  options,
  width,
  height,
  borderRadius,
  borderColor,
  color,
  fontSize,
  titleFontSize,
  titleWeight = "medium",
  withPercentage = false,
  paddingRL,
  icon,
  border,
  gap
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [optionName, setOptionName] = useState<string | null>(value);
  const [optionValue, setOptionValue] = useState<string | null>(value);
  const [selectedIcon, setSelectedIcon] = useState<React.ReactNode>(<></>);
  const containerRef = useClickOutside(isOpen, () => setIsOpen(false));

  useEffect(() => {
    setOptionValue(value);
    const selectedOptionObject = options?.find(
      (option) => option.value === value
    );
    setOptionName(selectedOptionObject?.name || null);
    setSelectedIcon(selectedOptionObject ? selectedOptionObject.icon : null);
  }, [value, options]);

  const handleItemClick = (option: Option) => {
    onChange(option.value);
    setOptionName(option.name);
    setOptionValue(option.value);
    setSelectedIcon(option.icon);
    setIsOpen(false);
  };

  const {
    themeTextColor,
    OpacitiesTextColor,
    bg_selectColor,
    inputBorderColor,
    theme,
  } = useRecoilValue(ColorSelector);

  return (
    <InputContainer ref={containerRef}>
      <Input
        onClick={() => setIsOpen(disabled ? false : !isOpen)}
        width={width}
        borderRadius={borderRadius}
        borderColor={borderColor}
        color={color}
        titleFontSize={titleFontSize}
        fontSize={fontSize}
        themetextcolor={themeTextColor}
        inputbordercolor={inputBorderColor}
        height={height}
        paddingRL={paddingRL}
        border={border}
        gap={gap}
      >
        {optionValue ? (
          <FlexDiv gap="0.5rem" pointer="true">
            {icon && icon} {selectedIcon} {optionName}
          </FlexDiv>
        ) : (
          <>
          {titleWeight === "medium" ? (
          <MediumTypography
            color={color ? color : OpacitiesTextColor}
            text={placeholder || ""}
            fontSize={titleFontSize ? titleFontSize : fontSize || "0.75rem"}
          />
          ) : (
          <Typography
            color={color ? color : OpacitiesTextColor}
            text={placeholder || ""}
            fontSize={titleFontSize ? titleFontSize : fontSize || "0.75rem"}
          />
          )}
          </>
        )}
        <FlexDiv gap="1.25rem">
          {withPercentage && (
            <LightTypography
              text="/ 100"
              color={color ? color : OpacitiesTextColor}
              fontSize={fontSize ? fontSize : "0.75rem"}
            />
          )}
          <PolygonDiv isopen={isOpen.toString()}>
            <SvgPlygonIcon
              color={
                disabled ? inputBorderColor : color ? color : themeTextColor
              }
            />
          </PolygonDiv>
        </FlexDiv>
      </Input>
      {isOpen && (
        <Dropdown
          width={width}
          fontSize={fontSize}
          bg_selectcolor={bg_selectColor}
          theme={theme}
          gap={gap}
        >
          {options.map((option) => (
            <OptionItem
              key={option.value}
              onClick={() => handleItemClick(option)}
            >
              <FlexDiv gap="0.5rem" pointer="true">
                {option.icon} {option.name}
              </FlexDiv>
            </OptionItem>
          ))}
        </Dropdown>
      )}
    </InputContainer>
  );
};

export default SelectDropDown;
