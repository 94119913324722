import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const CompetencyStudentContainer =styled.div`
display: flex;
flex-direction:column;
border-radius: 1.25rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
width:100%;
/* gap:1.25rem; */
`;

export const CompetencyStudentTitleCols =styled.div`
display: flex;
flex-direction:column;
gap:0.6rem;
padding: 1.25rem;
`;
export const CompetencyStudentRow =styled.div`
display: flex;
padding:0  0.5rem;
width:100%;
`;

export const CompetencyHeaderCells =styled.div`
display: flex;
flex:1;
padding: 1.25rem;
/* background-color:red; */
&:nth-child(1){
  /* background-color:yellow; */
  /* width:25rem !important; */
  min-width:14rem;
  max-width:14rem;
}
/* &:nth-child(2){
  background-color:green;
}
&:nth-child(3){
  background-color:red;
}
&:nth-child(4){
  background-color:black;
}
&:nth-child(5){
  background-color:purple;
}
&:nth-child(6){
  background-color:green;
} */
`;

export const CompetencyStudentBodyCols =styled.div`
display: flex;
flex-direction:column;
/* gap:0.6rem; */
/* padding: 1.25rem; */
width:100%;
`;
export const CompetencyCellsText =styled.div<StyleThemeInterface>`
  min-width:14rem;
  max-width:14rem;
  /* background-color:red; */
  border-bottom:1px solid ${props =>props.bordercolor || "#576371"};
  border-right:1px solid ${props =>props.bordercolor || "#576371"};
  padding:1.2rem;
  `;
  export const CompetencyStudentBodyMainRow =styled.div`
  display: flex;
  width:100%;

  /* align-items:center;
  justify-content: center; */
  /* background-color:brown; */

  `;

export const CompetencyStudentBodyRow =styled.div<StyleThemeInterface>`
display: flex;
width:100% !important;
color:${props => props.color};
/* background-color:aqua; */
&:nth-child(even){
  /* background: rgba(255, 255, 255, 0.03); */
  background:${props =>props.nth_child_bgcolor};
}
&:last-child{
  border-bottom:1px solid ${props =>props.bordercolor || "#576371"};
}
`;
export const CompetencyBodyCells =styled.div`
display: flex;
flex:1 !important;
padding: 1.25rem;
/* background-color:red;
&:nth-child(1){
  background-color:yellow;
}
&:nth-child(2){
  background-color:green;
}
&:nth-child(3){
  background-color:red;
}
&:nth-child(4){
  background-color:black;
}
&:nth-child(5){
  background-color:purple;
}
&:nth-child(6){
  background-color:green;
} */
`;