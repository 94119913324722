import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const Container = styled.div<StyleThemeInterface>`
  background: ${(props) => props.bgcolor};
  &:dir(ltr) {
    border-radius: 0 15px 15px 0;
  }
  &:dir(rtl) {
    border-radius: 15px 0 0 15px;
  }
  min-height: 12.6875rem;
  min-width: auto;
  /* position: fixed;
  bottom: 8rem; */
`;

export const SideMainContainer = styled.div`
  margin-top: 1.81rem;
  margin-bottom: 1.75rem;
`;
