import styled from "styled-components";

export const ViewPostContainer = styled.div`
  width: 100%;
  /* padding-left:1.25rem; */
  gap: 1.3rem;
  margin-top: 0.6rem;
  display: flex;
  justify-content: space-between;
`;
export const ViewPostContainerData = styled.div`
  /* width:100%; */
  /* padding-left:1.25rem; */
  gap: 1.3rem;
  /* margin-top:0.6rem; */
  display: flex;
  /* background: yellow; */
`;

export const RPProfilePic = styled.img<{ bgColor?: string }>`
  background: ${(props) =>
    props.bgColor || "linear-gradient(180deg, #415160 0%, #293440 100%)"};
  padding: 0.22rem;
  width: 3.29375rem;
  height: 3.29375rem;
  border-radius: 50%;
`;

export const ViewPostDetails = styled.div`
  width: 100%;
  gap: 0.3rem;
  margin-top: 0.6rem;
  display: flex;
  flex-direction: column;
`;

export const ViewPostText = styled.div`
  width: 100%;
  display: flex;
  margin-top: 0.3rem;
`;

export const ViewPostLine = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  /* margin-top:1.25rem; */
`;

export const ViewPostAction = styled.div`
  display: flex;
  margin-top: 0.3rem;
  color: rgba(255, 255, 255, 0.5);
  gap: 0.3rem;
  cursor: pointer;
`;
