import React, { useEffect, useRef, useState } from "react";
import ProfileFileSelected from "../../../FormUpdateProfile/StyledSelect/ProfileFileSelected";
import { SvgCamera, SvgPrevNext } from "../../../../elements/Icons";
import { ACLayout, ACTextInput } from "./AceptCreation.style";
import {
  GCBackAction,
  GCCricleAction,
  GCLineAction,
  GCTitleRow,
} from "../GroupGreator.style";
import { Typography } from "../../../../elements/fonts/Fonts";
import ProfilePICUpload from "../../../../elements/ProfilePICUpload/ProfilePICUpload";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../../recoil/ThemeSelectors";
import { WebSocketConnector } from "../../../../Views/messages/webSocketConnection";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { get } from "../../../../utils/AxiosRequests";
import { toast } from "react-toastify";
import { auth0SubIdSplitter, generateUUID, auth0ProviderSplitter } from "../../../../utils/StringManipulation";
import { S3Upload } from "../../../../services/S3Put";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { S3Get } from "../../../../services/S3Get";
import { SpinnerContainer } from "../../../../elements/Spinner/ZSpinner/ZSpinner.style";
import LoaderCircle from "../../../loader/loaderCircle/LoaderCircle";
// ... (other imports)

interface AceptCreationInterface {
  setShowGoupContainer: React.Dispatch<React.SetStateAction<boolean>>;
  chosenContacts: any;
  setShowCreateGroup: React.Dispatch<React.SetStateAction<boolean>>;
  fetchAllConv?: any;
  SetCreatedGroup: React.Dispatch<React.SetStateAction<boolean>>
}

const AcceptCreation = ({
  setShowGoupContainer,
  chosenContacts,
  fetchAllConv,
  setShowCreateGroup,
  SetCreatedGroup,
}: AceptCreationInterface) => {
  const [groupName, setGroupName] = React.useState(""); // Add this line for state
  const [File, setFile] = useState(""); // Add this line for state
  const [isLoading, SetisLoading] = useState(false)
  const [fileResponse, setFileResponse] = useState<any>(null);
  const [imagePath, setImagePath] = useState<any>(null);
  const filePath = `chat/group/profilePicture/`
  const { t } = useTranslation();

  const handleChangePic = async (file: any) => {
    if (file) {
      SetisLoading(true);
      const selectedFile: any = file[0];
      if (selectedFile) {
        const name: string =
          selectedFile.name.split("\\").pop() || selectedFile.name;
        const fileNameUUid = ` ${generateUUID()}${name}`;
        const fileuplKey = `${filePath}/${fileNameUUid}`;
        try {
          const response = await S3Upload(
            filePath,
            name,
            fileNameUUid,
            selectedFile
          );
          if (response?.status === 200) {
            setFileResponse(fileuplKey);
            SetisLoading(false);
          }
        } catch (error) {
          console.log(error);
        }


        const pictureKey = `${filePath}/${fileNameUUid}`;

        const pic = await S3Get(`${filePath}/${fileNameUUid}`);
        if (pic) {

          setImagePath(pic);
        }




      }
    }
  };


  const handleGetBack = async () => {
    setShowGoupContainer(true);
  };

  const handleCreateGroup = () => {
    // Add logic to create the group with chosenContacts and groupName
  };
  const { backgroundColor, themeTextColor } = useRecoilValue(ColorSelector);
  const [mySourceId, setMySourceId] = useState<string>('');
  const tenantName = localStorage.getItem('tenant');
  const UrlId = useParams();
  const classId = UrlId.classId;
  const { user, getAccessTokenSilently } = useAuth0();
  const myEmail = user?.email?.toLowerCase();
  const [dataLoading, setDataLoading] = useState<boolean>(true);



  const fetchUsersData = async () => {
    try {
      const response = await get(`participant/class/${classId}/${tenantName}`)
      const auditors = await axios.get(`https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/classAuditor/auditors/${tenantName}/${classId}`)

      setDataLoading(false);
      const filteredData = response.data.filter(
        (user: any) => user.email.toLowerCase() === myEmail
      );
      const auditorFilteredData = auditors.data.data.filter(
        (user: any) => user.email.toLocaleLowerCase() === myEmail?.toLocaleLowerCase()
      );
      if (filteredData.length > 0)
        setMySourceId(filteredData[0].sourceId);
      else if (auditorFilteredData.length > 0)
        setMySourceId(auditorFilteredData[0].sourceId);
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchUsersData()

  }, [])
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");

  const createConversation = async () => {
    SetisLoading(true)
    // Prepend mySourceId to the participantsSourceIds array
    const participantsSourceIds = [...chosenContacts.map((contact: { sourceId: any; }) => contact.sourceId)];
    const data = {
      tenantName: tenantName,
      classSourceId: classId,
      participants: participantsSourceIds,
      type: 'group',
      subId: subIdSplitted,
      groupName: groupName,
      profileGroup: fileResponse,
      provider: provider,
    }

    try {
      const response = await axios.post(`https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/create/conversations`, {
        data: data
      })
    } catch (error) {
      console.log(error)
    }
    toast.success(t("course view.Group created successfully"));
    setShowCreateGroup(false)
    SetCreatedGroup(true)
    fetchAllConv && fetchAllConv()
  }







  return (
    <ACLayout bgColor={backgroundColor}>
      <GCTitleRow>
        <GCBackAction onClick={handleGetBack}>
          <SvgPrevNext />
        </GCBackAction>
        <Typography text={t("Chat.New Chat")} fontSize="1.125rem" />
      </GCTitleRow>
      <ProfilePICUpload
        onChange={handleChangePic}
        title={t("Chat.Add Group Icon")}
        icon={SvgCamera}
        acceptedFileTypes=".jpg, .jpeg, .png"
        fileResponse={imagePath}
      />

      <ACTextInput
        type="text"
        placeholder={t("Chat.Group Name (Optional)")}
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)} // Handle input change
        color={themeTextColor}
      />

      <GCLineAction>
        {isLoading ? <SpinnerContainer>
          <LoaderCircle />
        </SpinnerContainer>
          :
          <GCCricleAction>
            <SvgPrevNext onClick={() => createConversation()} color="#fff" />
          </GCCricleAction>
        }
      </GCLineAction>
    </ACLayout>
  );
};

export default AcceptCreation;
