import styled from "styled-components";
interface DocumentViewerProps {
  isgrader: boolean;
  downloadable?: number;
  boxShadow?:string;
  bgColor?:string;
}
export const DocumentViewerDiv = styled.div<DocumentViewerProps>`
  height: 100%;

background: ${props => props.bgColor};
border-radius: 1.25rem;
border:none !important;
box-shadow:${props =>props.boxShadow || "0px 2px 10px 0px rgba(0, 0, 0, 0.25)"};

`;
