import styled from "styled-components";

export const SearchAndFilterContainer = styled.div`
  padding: 1.88rem 1.5rem;
  min-height: 47.6875rem;
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  padding: 15px;
  margin-top: 10px;
  /* width: 23.125rem; */
  height: 2.8125rem;
`;

export const SearchInput = styled.input`
  border: none;
  outline: none;
  flex: 1;
  padding: 0;
  margin: 0;
  background: #384450;
  color: white;
  &::placeholder {
    color: #fff;
    font-family: "Primary Font";
  }
`;

export const Line = styled.div`
  margin-top: 1.25rem;
  background-color: #576371;
  /* width: 23.125rem; */
  height: 0.0625rem;
`;

export const FilterButton = styled.div`
  width: 5.3125rem;
  height: 2.1875rem;
  background: transparent;
  border: 1px solid #5dd3b3;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5dd3b3;
`;
export const PositionContainer = styled.div`
  width: 8.125rem;
  height: 1.875rem;
  background: #2d3741;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 2.44rem;
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.25rem;
`;

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;
