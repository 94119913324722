import styled from "styled-components";

export const AssignmentSubmissionCell =styled.div`
display: flex;
flex:1;
align-items:center;
padding:1.25rem;
align-self: center;
justify-content:center;
&:first-child{
  justify-content:left;
  flex:1.5;
  /* background-color:black; */
  /* padding-top:1.30rem; */
}
&:nth-child(2){
  flex:0.6;
  /* background-color:yellow; */
}
&:nth-child(3){
  flex:1.1;
  /* background-color:green; */
}
&:nth-child(4){
  flex:1.1;
  /* background-color:gray; */
}
&:nth-child(5){
  flex:0.7;
  /* background-color:pink; */
}
&:nth-child(6){
  flex:0.9;
  /* background-color:black; */
}
&:last-child{
  flex:0.8;
  /* background-color:blue; */
  justify-content:flex-end;
  gap:2rem;
  position:relative;
}
`;



