import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import {
  BlockContainer,
  ChatContainer,
  IconWrapper,
  InfoSvg,
  MaskGroup,
  MessageAndEmoji,
  MessageInput,
  MessageInputField,
  MessageOpenLabel,
  MessageOpenOption,
  MessagesFlow,
  MessagesHolder,
  MessagesHolderTop,
  MessagesLayout,
  OverlapGroup,
  ReceivedMessage,
  ReceivedMessageRow,
  RightIconsHolder,
  SenderMessage,
  SenderMessageRow,
} from "./Messages.style";
import MessageSenderInfo from "../../elements/messageSenderInfo/MessageSenderInfo";
import {
  AddSqIcon,
  SvgDotsIcon,
  SvgEmoji,
  SvgSearchIcon,
  SvgSend2Icon,
  SvgCircledDots,
} from "../../elements/Icons";
import {
  MediumTypography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import { SelectedFriendContext } from "./MessageSearch/SelectedFriendcontext/SelectedFriendContext";
import { handleMessageDiv } from "./MessagesFunctions";
import { useRecoilValue } from "recoil";
import { ChatsColorSelector, ColorSelector } from "../../recoil/ThemeSelectors";
import { WebSocketConnector } from "./webSocketConnection";
import { useLocation, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { get } from "../../utils/AxiosRequests";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import { ClassContext } from "../dashboard/UnstructuredContext";
import { WebsocketContext } from "../../contexts/chatContext";
import EmojiPicker, { EmojiStyle, Theme } from "emoji-picker-react";
import {
  AbsoluteDiv,
  AbsoluteDiv2,
  RelativeDiv,
} from "../../components/manageWeight/ManageWeight.style";
import { toast } from "react-toastify";
import useClickOutside from "../../hooks/useClickOutside";

interface Friend {
  role: string | undefined;
  id: React.Key | null | undefined;
  profilePicture: any;
  username: any;
  headline?: string;
}

interface Message {
  senderId: string;
  message: string;
}
interface Conversation {
  _id: string;
  Participants: string[];
  name: string;
}
interface Props {
  handleMuteConversation: (friendId: string, groupId: string) => void;
  handleArchiveConversation: (friendId: string, groupId: string) => void;
  handleBlockConversation: (friendId: string, groupId: string) => void;
  handleDeleteConversation: (friendId: string, groupId: string) => void;
  handleShowChatOption: (
    setShowChatOption: Dispatch<SetStateAction<boolean>>,
    ShowChatOption: boolean
  ) => void;

  handleSendIconClick: (
    inputMessage: string,
    friendId: string,
    groupId: string,
    setInputMessage: Dispatch<SetStateAction<string>>
  ) => void;
  ShowSearchPlace: boolean;
  setShowSearchPlace: Dispatch<SetStateAction<boolean>>;
  SetSelectedFriendId: Dispatch<SetStateAction<string>>;
  ShowChatOption: boolean;
  setShowChatOption: Dispatch<SetStateAction<boolean>>; // Changed type here
  containerRef: React.RefObject<any>;
  inputMessage: string;
  setInputMessage: Dispatch<SetStateAction<string>>;
  selectedFriendId: string;
  selectedGroupId: string | null;
  UserFriends: Friend[];
  isBlocked: boolean | undefined;
  senderName: string;
  chatContainerRef: React.RefObject<any>;
  friendConversationId: any;
  SetGroupConv: any;
  SetUserMessages: Dispatch<SetStateAction<string[]>>;
  SetGroupMessages: Dispatch<SetStateAction<string[]>>;
  SetConvo: any;
  UserMessages: any[];
  ConvDeleted: boolean;
  SetConvDeleted: Dispatch<SetStateAction<boolean>>;
}

const Friendsview: React.FC<Props> = ({
  handleMuteConversation,
  ConvDeleted,
  SetConvDeleted,
  SetConvo,
  SetGroupMessages,
  UserMessages,
  handleArchiveConversation,
  handleBlockConversation,
  handleDeleteConversation,
  handleShowChatOption,
  handleSendIconClick,
  ShowSearchPlace,
  SetSelectedFriendId,
  setShowSearchPlace,
  ShowChatOption,
  setShowChatOption,
  containerRef,
  inputMessage,
  setInputMessage,
  selectedFriendId,
  selectedGroupId,
  UserFriends,
  SetUserMessages,
  isBlocked,
  senderName,
  chatContainerRef,
  friendConversationId,
  SetGroupConv,
}) => {
  const {
    MainChatBox_bgColor,
    UserMessageBoxBG_Color,
    FriendMessageBoxBG_Color,
    AddBox_BG_Color,
  } = useRecoilValue(ChatsColorSelector);
  const { t } = useTranslation();
  const {
    backgroundColor,
    headerTextInput_ProfileAndBorder,
    themeTextColor,
    bg_selectColor,
    theme,
    bghelp,
    inputBorderColor,
  } = useRecoilValue(ColorSelector);

  // const connector = new WebSocketConnector();
  // const webSocket = useRef(connector);
  const location = useLocation();
  const { state } = location;
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [mySourceId, setMySourceId] = useState<string>("");
  const [connectedToWebsocket, setConnectedToWebsocket] =
    useState<boolean>(false);
  const MainId = useParams();
  const classId = MainId.classId;
  const tenantName = localStorage.getItem("tenant");
  const { user, getAccessTokenSilently } = useAuth0();
  const myEmail = user?.email?.toLowerCase();
  const [messages, setMessages] = useState<Message[]>([]);
  const [conversations, SetConversations] = useState<Conversation[]>([]);
  const [conversationId, SetConversationId] = useState(null);
  const [getConvMessage, SetgetConvMessage] = useState(false);
  const [AllUsers, SetAllUsers] = useState<any[]>([]);
  const [EmojiOpen, SetEmojiOpen] = useState<boolean>(false);
  const image = require("../../assets/userProfile.png");
  const { isReady, value, send } = useContext(WebsocketContext);
  const {
    onlineClients,
    setOnlineClients,
    globalConversationId,
    getUnvewedMessageCount,
    getUnvewedConversationsMessageCount,
  } = useContext(ClassContext);
  // useEffect(() => {
  //   getConversation()
  //   getMessages()
  // }, [getConvMessage])

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const HandleEmoji = () => {
    SetEmojiOpen(!EmojiOpen);
  };

  const fetchUsersData = async () => {
    try {
      const response = await get(`participant/class/${classId}/${tenantName}`)
      const auditors = await axios.get(`https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/classAuditor/auditors/${tenantName}/${classId}`)

      setDataLoading(false);
      const filteredData = response.data.filter(
        (user: any) => user.email.toLowerCase() === myEmail
      );
      const auditorFilteredData = auditors.data.data.filter(
        (user: any) => user.email.toLocaleLowerCase() === myEmail?.toLocaleLowerCase()
      );
      if (filteredData.length > 0)
        setMySourceId(filteredData[0].sourceId);
      else if (auditorFilteredData.length > 0)
        setMySourceId(auditorFilteredData[0].sourceId);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAllUsersData = async () => {
    try {
      const response = await get(`participant/class/${classId}/${tenantName}`);
      SetAllUsers(response.data);
      setDataLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUsersData();
    fetchEveryConv();
    fetchAllUsersData();
  }, [connectedToWebsocket, mySourceId, selectedFriendId]);

  useEffect(() => {
    fetchEveryMessage();
  }, [selectedFriendId, conversationId]);

  const fetchAllConv = async () => {
    try {
      const response = await axios.get(
        `https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/get/conversations/${classId}/${subIdSplitted}/${tenantName}?provider=${provider}`
      );
      SetConversations(response.data.data);
      const filteredGroups = response.data.data.filter(
        (conversation: { Type: any }) => conversation.Type === "group"
      );
      SetGroupConv(filteredGroups);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchEveryConv = async () => {
    try {
      const response = await axios.get(
        `https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/get/conversations/${classId}/${subIdSplitted}/${tenantName}?provider=${provider}`
      );
      SetConversations(response.data.data);
      SetConvo(response.data.data);
      const conversation = response.data.data.find(
        (conversation: { Participants: any }) =>
          conversation.Participants.includes(selectedFriendId)
      );
      SetConversationId(conversation._id);
      scrollToBottom();
    } catch (err) {
      console.log(err);
    }
  };

  const fetchEveryMessage = async () => {
    try {
      const response = await axios.get(
        `https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/get/messages/${friendConversationId || conversationId
        }/${tenantName}/${subIdSplitted}?provider=${provider}`
      );
      setMessages(response.data.data);
      SetUserMessages(response.data.data);
      SetGroupMessages([]);
      scrollToBottom();
      const updateViewedMessadeData = {
        tenantName: tenantName,
        subId: subIdSplitted,
        provider: provider,
        conversationId: friendConversationId || conversationId,
      };
      const updateResponse = await axios.put(
        `https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/update/viewed`,
        {
          data: updateViewedMessadeData,
        }
      );
      getUnvewedMessageCount(classId || "");
      getUnvewedConversationsMessageCount(classId || "");
      scrollToBottom();
    } catch (err) {
      console.log(err);
    }
  };

  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");

  var url = `wss://p1orbzycf9.execute-api.eu-west-1.amazonaws.com/dev/?subId=${subIdSplitted}?provider=${provider}&classSourceId=${classId}&tenantName=${tenantName} `;

  // webSocket.current.getConnection(url).onopen = function (event: any) {

  //   console.log('Connection is established');
  //   setConnectedToWebsocket(true);
  // };

  useEffect(() => {
    var data = JSON.parse(value);
    if (data && data.type && data.type === "message") {
      const receive = data.data;
      fetchEveryMessage();
      fetchAllConv();
    } else if (data && data.type && data.type === "clients") {
      setOnlineClients(data.value);
      fetchAllConv();
    } else if (data && data.type && data.type === "messages") {
      setMessages(data.value);
      SetUserMessages(data.value);
      SetGroupMessages([]);
    } else if (data && data.type && data.type === "public") {
      const receive = data.data;
      fetchEveryMessage();
      fetchAllConv();
    } else if (data && data.type && data.type === "conversations") {
      fetchEveryMessage();
    }
    // if (data && data.message === "Internal server error") {
    //   toast.error('An error occurred while sending the message. Please try again.')
    // }
  }, [value]);

  // webSocket.current.getConnection(url).onerror = function (event: any) {
  //   console.log('WebSocket error: ', event);
  // };

  // webSocket.current.getConnection(url).onclose = function () {
  //   console.log('Connection is closed');
  //   setConnectedToWebsocket(false);
  // };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      sendPrivateMessage();
      fetchEveryMessage();
    }
  };

  // Send Private Message
  const sendPrivateMessage = () => {
    const datatoSend = {
      tenantName: tenantName,
      message: inputMessage, // The message to be sent
      senderId: mySourceId, // My sourceId
      recipientId: [null], // The recipient SourceId
      conversationId: friendConversationId || conversationId, // The id of the conversation
    };

    try {
      // Check if conversationId is available
      if (friendConversationId && send && isReady) {
        send(
          JSON.stringify({
            action: "createMessage",
            data: {
              tenantName: tenantName,
              message: inputMessage, // The message i want to sent
              senderId: subIdSplitted, // My sourceId
              provider: provider,
              recipientId: [selectedFriendId], // The recipient SourceId
              conversationId: friendConversationId || globalConversationId, // The id of the conversation
            },
          })
        );
        scrollToBottom();
        setInputMessage("");
        SetEmojiOpen(false);
      } else {
        // If conversationId is not available, log an error
        console.error("ConversationId is not available yet.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setInputMessage("");
  }, [selectedFriendId, connectedToWebsocket]);

  const getSenderProfilePicture = (senderId: any) => {
    const senderUser = AllUsers.find((user) => user.sourceId === senderId);
    // Access the profile picture of the senderUser
    return senderUser ? senderUser.profilePicture : image;
  };

  const handleEmojiClick = (emojiData: any) => {
    setInputMessage((prevMessage) => prevMessage + emojiData.emoji); // Use the appropriate property to get the emoji character
  };

  const deleteConversations = async () => {
    try {
      const response = await axios.put(
        "https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/delete/conversations",
        {
          data: {
            tenantName: tenantName,
            subId: subIdSplitted,
            provider: provider,
            conversationId: conversationId,
          },
        }
      );
    } catch (error) {
      console.error("Error deleting conversations:", error);
    }
  };

  const DeleteClick = async () => {
    await deleteConversations();
    SetConvDeleted(!ConvDeleted);
    SetSelectedFriendId("");
    fetchEveryConv();
  };
  const language = localStorage.getItem("language");
  const EmojiContainerRef = useClickOutside(EmojiOpen, () =>
    SetEmojiOpen(false)
  );
  return (
    <>
      {UserFriends.map(
        (friend) =>
          selectedFriendId === friend.id && (
            <MessagesHolder key={friend.id} bgColor={MainChatBox_bgColor}>
              {friend.id === selectedFriendId && (
                <MessagesHolderTop bordercolor={bghelp}>
                  <MessageSenderInfo
                    profileImg={
                      friend.profilePicture ? friend.profilePicture : image
                    }
                    senderName={friend.username}
                    role={friend.role}
                    major={friend.headline || ""}
                    online={onlineClients.some(
                      (client: any) => client.userSourceId === selectedFriendId
                    )}
                  />
                  <RightIconsHolder ref={containerRef}>
                    {!ShowSearchPlace && (
                      <InfoSvg
                        style={{
                          paddingTop: "-0.2rem",

                          background: backgroundColor,
                          boxShadow:
                            theme === "light"
                              ? "0px 3px 6px rgba(0, 0, 0, 0.16)"
                              : "",
                        }}
                        onClick={() =>
                          handleMessageDiv(setShowSearchPlace, ShowSearchPlace)
                        }
                      >
                        <SvgSearchIcon />
                      </InfoSvg>
                    )}
                    <InfoSvg
                      style={{
                        paddingTop: "-0.2rem",
                        // paddingRight: "0.3rem",
                        background: backgroundColor,
                        boxShadow:
                          theme === "light"
                            ? "0px 3px 6px rgba(0, 0, 0, 0.16)"
                            : "",
                      }}
                      onClick={() =>
                        handleShowChatOption(setShowChatOption, ShowChatOption)
                      }
                      ref={containerRef}
                    >
                      <SvgCircledDots width={18} height={22} />
                    </InfoSvg>
                    {ShowChatOption && (
                      <MessageOpenOption bgColor={bg_selectColor}>
                        <MessageOpenLabel onClick={() => DeleteClick()}>
                          <MediumTypography
                            text={t("Chat.Delete conversation")}
                            fontSize="0.75rem;"
                          />
                        </MessageOpenLabel>
                      </MessageOpenOption>
                    )}
                  </RightIconsHolder>
                </MessagesHolderTop>
              )}
              {/* handle the friend selection view */}
              {selectedFriendId && (
                <SelectedFriendContext.Consumer>
                  {(value) => (
                    // Inside the return statement of Friendsview component

                    <ChatContainer ref={chatContainerRef}>
                      {messages &&
                        messages.map((message, index) => (
                          <MessagesFlow key={index}>
                            {/* Check if mySourceId exists and if message.senderId matches mySourceId */}
                            {(!mySourceId ||
                              (mySourceId &&
                                message.senderId === mySourceId)) && (
                                <MessagesLayout>
                                  <SenderMessageRow>
                                    <SenderMessage
                                      theme={theme}
                                      bgColor={UserMessageBoxBG_Color}
                                      language={language}
                                    >
                                      <p className="messagesText">
                                        {message.message}
                                      </p>
                                    </SenderMessage>
                                    {/* <MaskGroup
              alt="Sender's profile picture"
              src={getSenderProfilePicture(message.senderId)}
            /> */}
                                  </SenderMessageRow>
                                </MessagesLayout>
                              )}
                            {/* Check if mySourceId doesn't exist or if message.senderId doesn't match mySourceId */}
                            {mySourceId && message.senderId !== mySourceId && (
                              <ReceivedMessageRow>
                                {/* <MaskGroup
            alt="Sender's profile picture"
            src={getSenderProfilePicture(message.senderId)}
          /> */}
                                <ReceivedMessage
                                  bgColor={FriendMessageBoxBG_Color}
                                  theme={theme}
                                  language={language}
                                >
                                  <p className="messagesText">
                                    {message.message}
                                  </p>
                                </ReceivedMessage>
                              </ReceivedMessageRow>
                            )}
                          </MessagesFlow>
                        ))}
                    </ChatContainer>
                  )}
                </SelectedFriendContext.Consumer>
              )}

              {/* handle the blocked friend selection view */}
              {isBlocked && (
                <BlockContainer>
                  <TypographyRegularDescription
                    text={`Unblock contact to send a message for ${senderName}`}
                    fontSize="0.75rem"
                    lineHeight="1.125rem"
                  />
                </BlockContainer>
              )}

              {/* handle the normal friend selection view */}
              {!isBlocked && (
                <MessageInput ref={EmojiContainerRef}>
                  {/* <OverlapGroup bgColor={AddBox_BG_Color}>
                    <AddSqIcon />
                    
                  </OverlapGroup> */}
                  <RelativeDiv>
                    <AbsoluteDiv2 bordercolor={inputBorderColor} theme={theme}>
                      <EmojiPicker
                        open={EmojiOpen}
                        onEmojiClick={handleEmojiClick}
                        height={"20rem"}
                        theme={Theme.DARK}
                        emojiStyle={EmojiStyle.TWITTER}
                        searchPlaceHolder={t("Chat.Search emoji")}
                      />
                    </AbsoluteDiv2>
                    <MessageAndEmoji>
                      <MessageInputField
                        type="text"
                        placeholder={t("Chat.Send message")}
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        color={themeTextColor}
                        onKeyDown={handleKeyDown}
                        themeColor={theme}
                        EmojiOpen={EmojiOpen}
                      />
                      <OverlapGroup bgColor={AddBox_BG_Color}>
                        <SvgEmoji
                          color={themeTextColor}
                          onClick={() => HandleEmoji()}
                        />
                      </OverlapGroup>
                    </MessageAndEmoji>
                  </RelativeDiv>

                  <div style={{ width: "2.5rem" }}>
                    <IconWrapper
                      onClick={() => {
                        sendPrivateMessage();
                        fetchEveryMessage();
                      }}
                    >
                      <SvgSend2Icon />
                    </IconWrapper>
                  </div>
                </MessageInput>
              )}
            </MessagesHolder>
          )
      )}
    </>
  );
};

export default Friendsview;
