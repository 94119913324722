import React, { FC } from "react";
import { Typography, MediumTypography } from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import { ReactComponent as DowloadIcon } from "../../../assets/icons/Download.svg";
import {
  SubmittedContainer,
  TimeContainer,
  HiddenButton,
  Assignment,
  SubmittedFilesContainer,
} from "./SubmittionSection.style";
import { SubmittionSectionProps } from "./SubmittionSectionInterface";
import { useTranslation } from "react-i18next";
import { getFormattedDate } from "../../../utils/GetFormattedDate";
const SubmittionSection: FC<SubmittionSectionProps> = ({
  showRating,
  showLate,
  student,
  studentIndex,
  forum,
  quiz,
}) => {
  const { t } = useTranslation();

  // const getFormattedDate = (createdAt: string) => {
  //   const date = new Date(createdAt);
  //   const formattedDate = `${date.toDateString()} ${date.toLocaleTimeString()}`; // Change this according to your preferred date and time format
  //   return formattedDate;
  // };

  return (
    <div style={{ margin: "0 1.25rem" }}>
      {!forum && !quiz ? (
        <SubmittedContainer>
          <Typography
            text={t("assignment.SUBMITTED")}
            fontSize="1rem"
            color={`${theme.dark.main_color}`}
          />
          <TimeContainer>
            <MediumTypography
              text={`${
                student &&
                student.length > 0 &&
                getFormattedDate(student[studentIndex].createdAt)
              }`}
              fontSize="0.875rem"
            />
            {/* <HiddenButton>HIDDEN</HiddenButton> */}
          </TimeContainer>
        </SubmittedContainer>
      ) : forum ? (
        <SubmittedContainer>
          <Typography
            text={t("assignment.SUBMITTED")}
            fontSize="1rem"
            color={`${theme.dark.main_color}`}
          />
          <TimeContainer>
            <MediumTypography
              text={`${
                student &&
                student.length > 0 &&
                getFormattedDate(student[studentIndex].submissions[0].postTime)
              }`}
              fontSize="0.875rem"
            />
            {/* <HiddenButton>HIDDEN</HiddenButton> */}
          </TimeContainer>
        </SubmittedContainer>
      ) : (
        ""
      )}
      {!showLate &&
      !forum &&
      !quiz &&
      student &&
      student.length > 0 &&
      student[studentIndex].fileName.trim() !== "" ? (
        <SubmittedFilesContainer>
          <Typography
            text={t("assignment.SUBMITTED FILES")}
            fontSize="1rem"
            color={`${theme.dark.main_color}`}
          />
          <Assignment>
            <MediumTypography
              text={t("assignment.SUBMITTED")}
              fontSize="0.875rem"
            />
            {student && student.length > 0 && (
              <a
                href={student[studentIndex].submissionFile}
                download={student[studentIndex].submissionFile}
                target="_blank"
              >
                <DowloadIcon />
              </a>
            )}
          </Assignment>
          {/* hoodddoo */}
        </SubmittedFilesContainer>
      ) : (
        ""
      )}
    </div>
  );
};
export default SubmittionSection;
