import styled from "styled-components";
import { theme } from "../../theme";

export const QuestionSelected = styled.div`
  width: 100%;
  border-radius: 0.625rem;
  background: linear-gradient(180deg, #415160 0%, #293440 100%);
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;
export const QuestionSelectedContainer = styled.div`
  flex: 1;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;
export const LabelDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.62rem;
`;

export const Input = styled.input<{
  width?: any;
  show?: any;
  hasError?: boolean;
  color?: string;
  borderColor?: string;
}>`
  width: ${(props) => (props.width ? props.width : "23.0625rem")};
  height: 2.8125rem;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  border-color: ${(props) =>
    props.hasError ? "#D85D66" : props.borderColor || "#576371"};
  background: transparent;
  &:dir(ltr) {
    padding-left: 1.25rem;
  }
  &:dir(rtl) {
    padding-right: 1.25rem;
  }
  font-size: 0.875rem;
  font-family: "Primary Font-Light";
  /* color: ${theme.dark.primary_text_color}; */
  color: ${(props) => props.color};
  background: ${(props) => (props.show === "true" ? "#546270" : "")};
  &&:focus {
    outline: none;
    caret-color: ${(props) => props.color};
  }

  &&::placeholder {
    color: ${(props) => props.color};
    opacity: 0.5;
    font-family: "Primary Font-Light";
  }
`;

export const ChoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.63rem;
`;
export const ChoicesHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ChoicesInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.62rem;
`;
export const InputCont = styled.div`
  display: flex;
  gap: 0.62rem;
  align-items: center;
`;
