import React from "react";
import {
  AnalyticShadowHeaderFourDisplaysContainer,
  AnalyticShadowHeaderFourDisplaysLine,
  AnalyticShadowHeaderFourDisplaysTextColumn,
  AnalyticShadowHeaderFourDisplaysTextOpacity,
} from "./AnalyticShadowHeaderFourDisplays.styled";
import {
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import { formatDate } from "../../utils/FormatedDATE";
interface AnalyticShadowHeaderFourDisplaysInterface {
  textA?: string;
  textB?: string;
  textC?: string;
  textD?: string;
  valA?: string | Date;
  valB?: string | Date;
  valC?: string | Date;
  valD?: string | Date;
}
const AnalyticShadowHeaderFourDisplays = ({
  textA = "Submissions",
  textB = "Start Date",
  textC = "Due Date",
  textD = "Cut Off Date",
  valA,
  valB,
  valC,
  valD,
}: AnalyticShadowHeaderFourDisplaysInterface) => {
  return (
    <AnalyticShadowHeaderFourDisplaysContainer>
      <AnalyticShadowHeaderFourDisplaysTextColumn>
        <Typography text={textA} fontSize="1.125rem" />
        <AnalyticShadowHeaderFourDisplaysTextOpacity>
          <TypographyRegularDescription
            text={valA?.toString() || ""}
            fontSize="0.875rem"
          />
        </AnalyticShadowHeaderFourDisplaysTextOpacity>
      </AnalyticShadowHeaderFourDisplaysTextColumn>
      <AnalyticShadowHeaderFourDisplaysLine />
      <AnalyticShadowHeaderFourDisplaysTextColumn>
        <Typography text={textB} fontSize="1.125rem" />
        <AnalyticShadowHeaderFourDisplaysTextOpacity>
          <TypographyRegularDescription
            text={formatDate(valB as unknown as Date)}
            fontSize="0.875rem"
          />
        </AnalyticShadowHeaderFourDisplaysTextOpacity>
      </AnalyticShadowHeaderFourDisplaysTextColumn>
      <AnalyticShadowHeaderFourDisplaysLine />
      <AnalyticShadowHeaderFourDisplaysTextColumn>
        <Typography text={textC} fontSize="1.125rem" />
        <AnalyticShadowHeaderFourDisplaysTextOpacity>
          <TypographyRegularDescription
            text={formatDate(valC as unknown as Date)}
            fontSize="0.875rem"
          />
        </AnalyticShadowHeaderFourDisplaysTextOpacity>
      </AnalyticShadowHeaderFourDisplaysTextColumn>
      <AnalyticShadowHeaderFourDisplaysLine />
      <AnalyticShadowHeaderFourDisplaysTextColumn>
        <Typography text={textD} fontSize="1.125rem" />
        <AnalyticShadowHeaderFourDisplaysTextOpacity>
          <TypographyRegularDescription
            text={formatDate(valD as unknown as Date)}
            fontSize="0.875rem"
          />
        </AnalyticShadowHeaderFourDisplaysTextOpacity>
      </AnalyticShadowHeaderFourDisplaysTextColumn>
    </AnalyticShadowHeaderFourDisplaysContainer>
  );
};

export default AnalyticShadowHeaderFourDisplays;
