import React, { useState, useRef, useEffect } from "react";
import {
  Input,
  Dropdown,
  TimePickerContainer,
  InputCont,
  InputContSvg,
  NumInput,
  DivGroup,
  TimeCont,
  PrevHourMinute,
  DisplayedContainer,
} from "./TimePicker.style";
import "./TimePicker.css";
import { ReactComponent as SvgClockIcon } from "../../assets/icons/Clock.svg";
import { TimePickerProps } from "./TimePickerInterface";
import { useRecoilValue } from "recoil";
import { ColorSelector, MainColorSelector } from "../../recoil/ThemeSelectors";
import {
  LightTypography,
  Typography,
  TypographyRegularDescription,
} from "../fonts/Fonts";
import { useTranslation } from "react-i18next";
import { toArabicDigits } from "../../utils/formatDayArabic";

function TimePickerComponent({
  width,
  placeholder,
  value,
  borderColor,
  padding,
  absoleteBgColor,
  onChange = () => {},
}: TimePickerProps) {
  const [time, setTime] = useState("12:00 AM");
  const [tempTime, setTempTime] = useState("12:00 AM");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        event.target instanceof Element &&
        !inputRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef]);

  const toggleDropdown = () => {
    setTempTime(time);
    setDropdownOpen(true);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const updateInputValue = () => {
    setTime(tempTime);
    closeDropdown();
    onChange(tempTime);
  };

  const convertTo12Hour = (hours: number) => {
    let period = "AM";
    if (hours >= 12) {
      period = "PM";
      if (hours > 12) hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }
    return { hours, period };
  };

  const convertTo24Hour = (hours: number, period: string) => {
    if (period === "PM" && hours < 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }
    return hours;
  };

  const getPrevHour = (hours: number) => {
    return hours === 1 ? 12 : hours - 1;
  };

  const getNextHour = (hours: number) => {
    return hours === 12 ? 1 : hours + 1;
  };

  const getPrevMinute = (minutes: number) => {
    return minutes === 0 ? 59 : minutes - 1;
  };

  const getNextMinute = (minutes: number) => {
    return minutes === 59 ? 0 : minutes + 1;
  };

  const increaseHours = () => {
    let [hours, minutesPeriod] = tempTime.split(":");
    let [minutes, period] = minutesPeriod.split(" ");
    let hour = parseInt(hours);
    hour = (hour % 12) + 1;
    if (hour === 12) period = period === "AM" ? "PM" : "AM";
    setTempTime(`${String(hour).padStart(2, "0")}:${minutes} ${period}`);
  };

  const decreaseHours = () => {
    let [hours, minutesPeriod] = tempTime.split(":");
    let [minutes, period] = minutesPeriod.split(" ");
    let hour = parseInt(hours);
    hour = hour === 1 ? 12 : hour - 1;
    if (hour === 11) period = period === "AM" ? "PM" : "AM";
    setTempTime(`${String(hour).padStart(2, "0")}:${minutes} ${period}`);
  };

  const increaseMinutes = () => {
    let [hours, minutesPeriod] = tempTime.split(":");
    let [minutes, period] = minutesPeriod.split(" ");
    let minute = parseInt(minutes);
    minute = (minute + 1) % 60;
    if (minute === 0) increaseHours();
    setTempTime(`${hours}:${String(minute).padStart(2, "0")} ${period}`);
  };

  const decreaseMinutes = () => {
    let [hours, minutesPeriod] = tempTime.split(":");
    let [minutes, period] = minutesPeriod.split(" ");
    let minute = parseInt(minutes);
    minute = (minute - 1 + 60) % 60;
    if (minute === 59) decreaseHours();
    setTempTime(`${hours}:${String(minute).padStart(2, "0")} ${period}`);
  };

  const handleHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newHours = parseInt(e.target.value, 10);
    if (!isNaN(newHours) && newHours >= 1 && newHours <= 12) {
      let [hours, minutesPeriod] = tempTime.split(":");
      let [minutes, period] = minutesPeriod.split(" ");
      setTempTime(`${String(newHours).padStart(2, "0")}:${minutes} ${period}`);
    }
  };

  const handleMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMinutes = parseInt(e.target.value, 10);
    if (!isNaN(newMinutes) && newMinutes >= 0 && newMinutes <= 59) {
      let [hours, minutesPeriod] = tempTime.split(":");
      let [minutes, period] = minutesPeriod.split(" ");
      setTempTime(`${hours}:${String(newMinutes).padStart(2, "0")} ${period}`);
    }
  };

  const togglePeriod = () => {
    let [hours, minutesPeriod] = tempTime.split(":");
    let [minutes, period] = minutesPeriod.split(" ");
    period = period === "AM" ? "PM" : "AM";
    setTempTime(`${hours}:${minutes} ${period}`);
  };

  const {
    SecondaryTextColor,
    bghelp,
    theme,
    bg_selectColor,
    JustLightBoxShadow,
    boxShadow,
    timePickerFieldBgColor,
  } = useRecoilValue(ColorSelector);

  const [hours, minutesPeriod] = tempTime.split(":");
  const [minutes, period] = minutesPeriod.split(" ");
  const hour = parseInt(hours);
  const minute = parseInt(minutes);
  const { mainBackgroundColor } = useRecoilValue(MainColorSelector);
  useEffect(() => {
    if (!isDropdownOpen) {
      updateInputValue();
    }
  }, [isDropdownOpen]);

  const handleScrollHours = (e: React.WheelEvent<HTMLDivElement>) => {
    if (e.deltaY < 0) {
      decreaseHours();
    } else {
      increaseHours();
    }
  };
  const handleScrollMinutes = (e: React.WheelEvent<HTMLDivElement>) => {
    if (e.deltaY < 0) {
      decreaseMinutes();
    } else {
      increaseMinutes();
    }
  };

  useEffect(() => {
    const preventScroll = (e: Event) => {
      e.preventDefault();
    };

    if (isDropdownOpen) {
      // document.body.style.overflow = 'hidden';
      document.addEventListener("wheel", preventScroll, { passive: false });
      document.addEventListener("touchmove", preventScroll, { passive: false });
    } else {
      // document.body.style.overflow = '';
      document.removeEventListener("wheel", preventScroll);
      document.removeEventListener("touchmove", preventScroll);
    }

    return () => {
      // document.body.style.overflow = '';
      document.removeEventListener("wheel", preventScroll);
      document.removeEventListener("touchmove", preventScroll);
    };
  }, [isDropdownOpen]);

  return (
    <InputCont ref={inputRef} width={width} id="1">
      <DisplayedContainer
        bgColor={absoleteBgColor || mainBackgroundColor}
        color={SecondaryTextColor}
        onClick={toggleDropdown}
        borderColor={borderColor || bghelp}
        width={width}
        id="2"
      >
        <LightTypography
          fontSize="0.875rem"
          text={
            value
              ? `${toArabicDigits(value).slice(0, -3)} ${
                  value.includes("PM")
                    ? t("notification.PM")
                    : t("notification.AM")
                }`
              : toArabicDigits(time.toString())
          }
        />

        <InputContSvg>
          <SvgClockIcon />
        </InputContSvg>
      </DisplayedContainer>
      <Input
        bgColor={bg_selectColor}
        color={SecondaryTextColor}
        type="text"
        value={value ? value : time}
        readOnly
        onClick={toggleDropdown}
        placeholder={placeholder}
        width={width}
        padding={padding}
        id="3"
      />
      {isDropdownOpen && (
        <Dropdown>
          <TimePickerContainer
            boxShadow={JustLightBoxShadow}
            bgColor={bg_selectColor}
            width={width}
          >
            <TimeCont>
              <DivGroup onWheel={handleScrollHours}>
                <PrevHourMinute theme={theme} onClick={decreaseHours}>
                  {toArabicDigits(getPrevHour(hour).toString().padStart(2, "0"))}
                </PrevHourMinute>
                <NumInput
                  color={SecondaryTextColor}
                  bgcolor={timePickerFieldBgColor}
                  type="text"
                  className="num-input"
                  min="1"
                  max="12"
                  value={toArabicDigits(hour.toString().padStart(2, "0"))}
                  onChange={handleHoursChange}
                />
                <PrevHourMinute theme={theme} onClick={increaseHours}>
                  {toArabicDigits(getNextHour(hour).toString().padStart(2, "0"))}
                </PrevHourMinute>
              </DivGroup>
              <TypographyRegularDescription text=":" fontSize="0.75rem" />
              <DivGroup onWheel={handleScrollMinutes}>
                <PrevHourMinute theme={theme} onClick={decreaseMinutes}>
                  {toArabicDigits(getPrevMinute(minute).toString().padStart(2, "0"))}
                </PrevHourMinute>
                <NumInput
                  color={SecondaryTextColor}
                  bgcolor={timePickerFieldBgColor}
                  type="text"
                  className="num-input"
                  min="0"
                  max="59"
                  value={toArabicDigits(minute.toString().padStart(2, "0"))}
                  onChange={handleMinutesChange}
                />
                <PrevHourMinute theme={theme} onClick={increaseMinutes}>
                  {toArabicDigits(getNextMinute(minute).toString().padStart(2, "0"))}
                </PrevHourMinute>
              </DivGroup>
            </TimeCont>
            <DivGroup style={{ gap: "0.62rem" }}>
              <div
                onClick={() => setTempTime(`${hours}:${minutes} AM`)}
                style={{
                  cursor: "pointer",
                  color: period === "AM" ? "#5DD3B3" : "#AAB1B9",
                }}
              >
                <Typography text={t("notification.AM")} />
              </div>
              <div
                onClick={() => setTempTime(`${hours}:${minutes} PM`)}
                style={{
                  cursor: "pointer",
                  color: period === "PM" ? "#5DD3B3" : "#AAB1B9",
                }}
              >
                <Typography text={t("notification.PM")} />
              </div>
            </DivGroup>
          </TimePickerContainer>
        </Dropdown>
      )}
    </InputCont>
  );
}

export default TimePickerComponent;
