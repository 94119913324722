import styled from "styled-components";

export const LabelDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.62rem;

  .input-container {
    display: flex;
    flex-direction: column;
    gap: 1.12rem;
  }
`;

export const BtnsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.88rem;
`;

export const LabelBtnsDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;
export const ButtonsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 1rem;
`;

export const FlexDiv = styled.div<{ gap?: string }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : "0.5rem")};
`;

export const FlexDivInput = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;
export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* & > :last-child:hover {
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  } */
`;

export const ExistingFolder = styled.div`
  display: flex;
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 10px;
  width: 100%;
  padding: 15px 20px;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
`;
export const DeleteIcon = styled.div`
  color: red;
  cursor: pointer;
`;
export const FirstSection = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
