import React, { useContext, useEffect, useState } from "react";
import VideoPlayer from "../videoPlayer/VideoPlayer";
import { useRecordings } from "./RecordingContext";
import { ResourceFrom } from "../../Views/courseView/content/Content";
import { SessionOver, SessionOverWithRecording, RecordingsContainer, ActionHolder, ListItem, RightActionsHolder } from "./OnlineSession.style";
import {
  LightTypography,
  MediumTypography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import RecordingsComponent from "./RecordingsComponent/RecordingsComponent";
import JoinSession from "./joinSession/JoinSession";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useParams } from "react-router";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../utils/StringManipulation";
import DOMPurify from "dompurify";
import { RecordingInterface } from "../CourseTimeline/utils/CourseResourcesInterface";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { SvgEyeIcon, SvgDownload } from "../../elements/Icons";
import RecordingBox from "./RecordingsComponent/recordingBox/RecordingBox";
import { LoadingComp } from "../LoadingComp/LoadingComp";
import ZSpinner from "../../elements/Spinner/ZSpinner/ZSpinner";
import { useRecoilState } from "recoil";
import { adminState } from "../../recoil/RecoilStates";
interface RecordingProps {
  ReceivedActivityId: any;
  type: string;
}
export default function OnlineSession({ ReceivedActivityId, type }: RecordingProps) {
  const [isAdmin] = useRecoilState(adminState)
  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };
  const { classId } = useParams();
  const { t } = useTranslation()
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth0();
  const [joinUrl, SetJoinUrl] = useState('')
  const myEmail = user?.email;
  const [UserMSid, SetUserMSid] = useState("")
  const [AccessToken, SetAccessToken] = useState("")
  // const [onlineMeetingId, SetOnlineMeetingId] = useState("")
  const [RecordingUrl, SetRecordingUrl] = useState([])
  const [loadingRecords, setLoadingRecords] = useState(true);
  const navigate = useNavigate();
  const [vidRec, setVidRec] = useState<any>([])


  const getAccessToken = async () => {
    try {
      const response = await axios.get(
        `https://k9jjuskf3i.execute-api.eu-west-1.amazonaws.com/dev/ms/token`,
        // {
        //   headers: {
        //     'tenantName': tenantName,
        //     // Replace YOUR_AUTH_TOKEN with the actual token if required
        //     // Add other headers if needed
        //   }
        // }
      );

      localStorage.setItem("MSToken", response.data.data);
      SetAccessToken(response.data.data)
      return response.data.data;
    } catch (err) {
      console.log(err);
    }
  };


  const getUserId = async () => {
    try {

      const response = await axios.get(
        `https://k9jjuskf3i.execute-api.eu-west-1.amazonaws.com/dev/ms/user/byEmail/${myEmail}/{tenantName}`,

      );
      SetUserMSid(response.data.data)

      return response.data.data
    } catch (err) {
      console.log(err);
    }
  };



  const FetchOnlineMeetings = async (url: string) => {
    const UserMSidOn = await getUserId()
    try {
      const token = await getAccessToken()
      if (UserMSidOn) {

        const encodedJoinUrl = encodeURIComponent(url);
        const response = await axios.get(
          `https://graph.microsoft.com/v1.0/users/${UserMSidOn}/onlineMeetings?$filter=joinWebUrl eq '${encodedJoinUrl}'`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        getRecordings(response.data.value[0].id)
        // SetOnlineMeetingId(response.data.value[0].id)
      }
    } catch (error) {
      console.log(error);
    }
  };



  const getRecordings = async (onlineMeetingId: any) => {
    const UserMSidOn = await getUserId()
    const token = await getAccessToken()
    if (onlineMeetingId && UserMSidOn) {

      try {
        const response = await axios.get(`https://graph.microsoft.com/v1.0/users/${UserMSidOn}/onlineMeetings/${onlineMeetingId}/recordings`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        SetRecordingUrl(response.data.value)
        try {
          const videoPromises = await response.data.value.map(async (url: any) => {
            const response = await axios.get(url.recordingContentUrl, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              responseType: 'blob',
            });
            if (response.status === 200) {
              // Create a URL for the blob
              const videoBlob = new Blob([response.data], { type: 'video/mp4' });
              const videoUrl = URL.createObjectURL(videoBlob);
              return videoUrl;
            } else {
              console.error('Failed to fetch video from', url);
              return null;
            }
          });
          setLoadingRecords(true)
          const videoUrls = await Promise.all(videoPromises);
          const validVideoUrls = videoUrls.filter((url) => url !== null);
          setVidRec(validVideoUrls);
          setLoadingRecords(false)
        } catch (error) {
          console.error('Error:', error);
        }
      } catch (error) {
        console.log(error)
      }
    }
  }





  const handleApiCallAndNavigate = async (url: any) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${AccessToken}`,
          'Content-Type': 'application/json',
        },
        responseType: 'blob', // This ensures the response is treated as binary data
      });


      if (response.status === 200) {
        // Create a URL for the blob
        const videoBlob = new Blob([response.data], { type: 'video/mp4' });
        const videoUrl = URL.createObjectURL(videoBlob);
        setVidRec(videoUrl)
        // Open the video URL in a new window
        // window.open(videoUrl, '_blank');
      } else {
        // Handle error response
        console.error('Failed to fetch video');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  // useEffect(() => {
  //   if (type !== 'Online_Session') {
  //     getUserId()
  //   }
  // }, [])

  // useEffect(() => {
  //   if (type !== 'Online_Session') {
  //     // setVidRec([])
  //     // SetRecordingUrl([])
  //   }
  // }, [ReceivedActivityId])


  // useEffect(() => {
  //   if (type !== 'Online_Session') {
  //     getRecordings()
  //   }
  // }, [onlineMeetingId, ReceivedActivityId])

  // useEffect(() => {
  //   if (type !== 'Online_Session') {
  //     FetchOnlineMeetings()
  //   }
  // }, [UserMSid, ReceivedActivityId])


  useEffect(() => {
    // setVidRec([]);
    // SetRecordingUrl([])
    // setData({});
    const fetchData = async () => {
      SetJoinUrl("")
      setVidRec([]);
      SetRecordingUrl([])
      setData({});
      setLoading(true);
      const tenantName = localStorage.getItem("tenant");
      const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
      const provider = auth0ProviderSplitter(user?.sub || "");
      try {
        if (type === "Online_Session") {
          const response = await axios.get(
            `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/activity/onlineSession/${ReceivedActivityId}/${subIdSplitted}/${classId}/${tenantName}?provider=${provider}`
          );
          if (response.data.data) {
            setData(response.data.data);
            setLoading(false);
          }
        }
        else {
          const response = await axios.get(
            `https://k9jjuskf3i.execute-api.eu-west-1.amazonaws.com/dev/teamsOnlineSession/${ReceivedActivityId}/${subIdSplitted}/${classId}/${tenantName}?provider=${provider}`
          );
          if (response.data.data) {
            setData(response.data.data);
            SetJoinUrl(response.data.data.path)

            await FetchOnlineMeetings(response.data.data.path)
            // await getRecordings()
            setLoading(false);
          }

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again.");
        setLoading(false);
      }
    };

    fetchData();
  }, [ReceivedActivityId]);
  let endDateTimeUTC: Date | undefined;
  let endDateTimeString;
  const { setRecordings } = useRecordings();
  let today = new Date();
  if (data && data.session) {
    endDateTimeString = data.session.endDateTime;

    // Create a Date object from the string (assumes the string is in UTC)
    const endDateTime = new Date(endDateTimeString);

    // Check if endDateTime is a valid Date object
    if (!isNaN(endDateTime.getTime())) {
      // Convert endDateTime to UTC to match the time zone of endDateTimeString
      endDateTimeUTC = new Date(endDateTime.toISOString());

      // Extract hours and minutes from endDateTimeUTC
      const hours = endDateTimeUTC.getUTCHours();
      const minutes = endDateTimeUTC.getUTCMinutes();

      // Use hours and minutes in your component logic
      endDateTimeUTC.setUTCHours(hours, minutes, 0, 0);

      // Ensure endDateTimeUTC is valid
      if (!isNaN(endDateTimeUTC.getTime())) {
      } else {
        console.error("Invalid endDateTimeUTC:", endDateTimeUTC);
      }
    } else {
      console.error("Invalid endDateTime:", endDateTimeString);
    }
  }
  // const records: RecordingInterface[] = [
  //   { title: "recod1", path: "https://youtube.com" },
  //   { title: "recod1", path: "https://youtube.com" },
  // ];
  //useeffect for recordings
  // useEffect(() => {
  //   if (data && data.online_session) {
  //     if (data.online_session.isRecordable) {
  //       // if (
  //       //   data.online_session.recordings &&
  //       //   data.online_session.recordings.length > 0
  //       // ) {
  //       setRecordings(records);
  //       // }
  //     }
  //   }
  // }, [ReceivedActivityId]);
  return (
    <>
      {data && data.online_session && (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              decodeHtml(data.online_session.description || "")
            ),
          }}
          style={{ marginLeft: 5 }}
        />
      )}
      {/* {endDateTimeUTC &&
        console.log(
          "endDateTimeString >= today",
          endDateTimeUTC >= today,
          endDateTimeUTC,
          today
        )} */}
      {isAdmin === false && data &&
        data.session &&
        data.online_session &&
        endDateTimeUTC &&
        endDateTimeUTC >= today && (
          <>
            <JoinSession ReceivedActivityId={ReceivedActivityId} ReceivedActivity={data} type={type} />
            {/* {
              RecordingUrl.length > 0 &&
              RecordingUrl.map((recording: any) => {
                return <div key={recording.id} onClick={() => handleApiCallAndNavigate(recording.recordingContentUrl)}> <MediumTypography text={recording.recordingContentUrl} /></div>
              })
            } */}
          </>
        )}
      {endDateTimeUTC && endDateTimeUTC < today && (
        <>
          <SessionOver>
            <Link to="https://teams.microsoft.com/v2/">
              <MediumTypography
                text={`${t("online session.This Session Is Over")}. ${t("online session.click here to access teams")}`}
                fontSize="0.875rem"
                color="#D85D66"
              />
            </Link>
            {/* {!data.online_session.recordings ? (
              <TypographyRegularDescription
                text="No Recording Available"
                color="rgba(255, 255, 255, 0.50)"
                fontSize="0.75rem"
              />
            ) : (
              <TypographyRegularDescription
                text="Recordings Available"
                color="rgba(255, 255, 255, 0.50)"
                fontSize="0.75rem"
              />
            )} */}
          </SessionOver>
          {/* {data.online_session.recordings && ( */}

          {/* )} */}
        </>
      )}
      {type === "Teams" && RecordingUrl && RecordingUrl.length > 0 &&
        <SessionOverWithRecording>
          {/* {
            RecordingUrl.length > 0 &&
            RecordingUrl.map((recording: any, index) => {
              return <RecordingsContainer key={recording.id} >
                <ListItem>
                  <div style={{ display: "flex", columnGap: "0.6rem" }}>
                    <LightTypography text="Recording" fontSize="0.875rem" />
                    <LightTypography text={`${index + 1}`} fontSize="0.875rem" />
                    <video width="320" height="240">
                      <source src={vidRec} type="video/mp4" />
                    </video>
                  </div>
                  <RightActionsHolder>
                    <ActionHolder onClick={() => handleApiCallAndNavigate(recording.recordingContentUrl)}>
                      <SvgEyeIcon />
                      <LightTypography text="View Recording" fontSize="0.875rem" />
                    </ActionHolder>
                  </RightActionsHolder>
                </ListItem>
              </RecordingsContainer>
            })
          } */}
          {loadingRecords && <div style={{ height: "3rem" }}> <ZSpinner /></div>}
          {!loadingRecords && vidRec && vidRec.length > 0 && vidRec.map((recording: any, index: any) => {
            return <RecordingsContainer key={index} >
              {/* <ListItem>
                <div style={{ display: "flex", columnGap: "0.6rem" }}>
                  <video width="320" height="240" controls>
                    <source src={recording} type="video/mp4" />
                  </video>
                </div>
                <RightActionsHolder>
                  <ActionHolder >
                    <SvgEyeIcon />
                    <LightTypography text="View Recording" fontSize="0.875rem" />
                  </ActionHolder>
                </RightActionsHolder>
              </ListItem> */}
              <RecordingBox recording={recording} index={index + 1} />
            </RecordingsContainer>
          })
          }
        </SessionOverWithRecording>
      }
    </>
  );
}
