import React from "react";
import {
  FilterTextContainer,
  TagRemoveButtonAction,
} from "./FilterSelected.style";
import { SvgClose } from "../Icons";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useRecoilValue } from "recoil";
import { TypographyRegularDescription } from "../fonts/Fonts";
import { FilterSelectedInterface } from "./FilterSelectedInterface";

const FilterSelected = ({
  text,
  labelContext,
  labelContextClickFunction,
}: FilterSelectedInterface) => {
  const { theme } = useRecoilValue(ColorSelector);
  return (
    <>
      <FilterTextContainer theme={theme}>
        <TypographyRegularDescription
          fontSize="0.875rem"
          text={`${text}: ${labelContext}`}
        />
        <TagRemoveButtonAction
          onClick={labelContextClickFunction}
          theme={theme}
        >
          <SvgClose width={"0.5rem"} />
        </TagRemoveButtonAction>
      </FilterTextContainer>
    </>
  );
};

export default FilterSelected;
