import styled from "styled-components";
import { theme } from "../../../theme";
import CheckIcon from "../../../assets/icons/Group2199.svg";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const PopUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  min-width: 27rem;
  margin-bottom: 1rem;
`;
export const SmallTextRow = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1.13rem;
`;
export const ChecksRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.31rem;
  width: 100%;
`;
export const CheckBox = styled.input<StyleThemeInterface>`
  width: 1rem;
  height: 1rem;  
  background:${props =>props.bgcolor};

  appearance: none;
  border: 1px solid ${theme.dark.primary_text_color};

  transition: background-color 0.3s ease;
  background:${props =>props.bgcolor};
 border: 1px solid ${props =>props.bordercolor};
  border-radius:${props =>props.borderradius};
  &:checked {
    background-image: url(${CheckIcon}); /* Use the CheckIcon component as a URL */
    background-size: cover; /* Adjust this as needed */
    background-repeat: no-repeat;
    background-position: center;
    border: none;
  }
`;
export const CheckLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.62rem;
  width: fit-content;
`;
export const Input = styled.input<StyleThemeInterface>`
  color: ${(props) => props.color};
  font-weight: 400;
  width: 3rem;
  height: 2.1875rem;
  background: transparent;
  border-radius: 0.625rem;
border: 1px solid ${props =>props.bordercolor};;
  outline: none;
  font-family: ${theme.font.primaryRegular};
  font-size: 0.875rem;
  text-align: center;
  &:focus {
    border: 1px solid #5dd3b3;
    caret-color: ${(props) => props.color};
  }
  &::placeholder {
    text-align: center;
    color: white;
  }
`;
export const InputWithText = styled.div`
  display: flex;
  gap: 0.62rem;
  align-items: center;
  &:dir(ltr) {
    padding-left: 1.75rem;
  }
  &:dir(rtl) {
    padding-right: 1.75rem;
  }
`;
export const MsgError = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
