import { InputHolder } from "./ReusableSubmitButton.style";
import { ButtonInterface } from "./reusableSubmitButtonInterface";
import { FC } from "react";
import { Typography } from "../fonts/Fonts";

const ReusableSubmitButton: FC<ButtonInterface> = ({
  text,
  width,
  height,
  borderRadius,
  padding,
  bgColor,
  border,
  color,
  onClick,
  disabled,
}) => {
  return (
    <InputHolder
      disabled={disabled || false}
      width={width || "7.625rem"}
      height={height || "2.125rem"}
      borderradius={borderRadius || "1.25rem"}
      padding={padding || "0.56rem 0rem 0.5rem 0rem"}
      bgcolor={bgColor || "#5DD3B3"}
      border={border || "none"}
      color={color || "white"}
      onClick={onClick}
    >
      <Typography text={text || "Submit"} fontSize="0.875rem" />
    </InputHolder>
  );
};

export default ReusableSubmitButton;
