import React, { useEffect, useState } from "react";
import {
  RubricPageeLayout,
  RubricTableLayout,
  RubricTableLayoutColumn,
  RubricTableLayoutRow,
  OneRowHeaderDiv,
  OneRowViewShapeDiv,
  RuricCratieriaMine,
  RuricCratieriaText,
  ShapeDivB,
  ShapeDivBTextFormat,
  ShapeDivBTextFormatColumn,
  TopContent,
  ButtonsCont,
  LoaderDiv,
} from "./RubricContent.style";
import {
  LightTypography,
  PrimaryLightNumber,
  Typography,
} from "../../elements/fonts/Fonts";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { RubricContentInterface } from "./RubricContentInterface";
import CancelButton from "../../elements/StyledButton/CancelButton";
import { TableColorSelector } from "../../recoil/ThemeSelectors";
import { useRecoilValue } from "recoil";
import Loader from "../loader/Loader";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { TaskContainer } from "../ToDoSections/ToDo/ToDo.style";

const RubricContent = ({
  rubricId,
  setSelectedRubricCell,
  setShow,
  setShowRating,
}: RubricContentInterface) => {
  const { t } = useTranslation();
  const id = rubricId;
  const { Table_Head_TR, Table_nth_child, theme } =
    useRecoilValue(TableColorSelector);
  const [rubricData, setRubricData] = useState<any>(null);
  const tenantName = localStorage.getItem("tenant");
  const language = localStorage.getItem("language");
  const [formValid, setFormValid] = useState(false);
  const [selectedCells, setSelectedCells] = useState<
    {
      xAxis: number;
      yAxis: number;
      weight: number;
      criteria: any;
      criteriaWeight: number;
      level: any;
    }[]
  >([]);

  // // Effect to fetch rubricData from localStorage based on id
  // useEffect(() => {
  //   const rubricsFromLocalStorage = JSON.parse(
  //     localStorage.getItem("rubrics") || "[]"
  //   );
  //   const rubric = rubricsFromLocalStorage.find((item: any) => item.id === id);
  //   console.log("data to get is:", rubric);
  //   setRubricData(rubric);
  // }, [id]);

  const GetRubricById = async () => {
    try {
      const response = await axios.get(
        `https://0j157bzxcf.execute-api.eu-west-1.amazonaws.com/dev/get/${id}/${tenantName}`
      );
      setRubricData(response.data.data);
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    // const rubricsFromLocalStorage = JSON.parse(
    //   localStorage.getItem("rubrics") || "[]"
    // );
    // const rubric = rubricsFromLocalStorage.find((item: any) => item.id === id);
    // console.log("data to get is:", rubric);
    GetRubricById();
    // setRubricData(rubric);
  }, [id]);

  // Function to handle cell click
  const handleCellClick = (
    xAxis: number,
    yAxis: number,
    weight: number,
    description: string
  ) => {
    // Find the criteria index based on the x-axis
    const criteriaIndex = xAxis - 1; // Since x-axis starts from 1, and criteria index starts from 0

    // Find the level index based on the y-axis
    const levelIndex = yAxis - 1; // Since y-axis starts from 1, and level index starts from 0

    // Check if a cell with the same x-axis already exists
    const existingCell = selectedCells.find((cell) => cell.xAxis === xAxis);

    // If a cell with the same x-axis exists, replace it with the new cell
    if (existingCell) {
      const updatedCells = selectedCells.map((cell) =>
        cell.xAxis === xAxis
          ? {
              ...cell,
              yAxis,
              weight,
              // description,
              criteria: rubricData.criteria[criteriaIndex]?.criteriaName,
              criteriaWeight:
                rubricData.criteria[criteriaIndex]?.criteriaWeight,
              level: rubricData.levels[levelIndex]?.value,
            }
          : cell
      );
      setSelectedCells(updatedCells);
    } else {
      // If no cell with the same x-axis exists, add the new cell
      setSelectedCells([
        ...selectedCells,
        {
          xAxis,
          yAxis,
          weight,
          // description,
          criteria: rubricData.criteria[criteriaIndex]?.criteriaName,
          criteriaWeight: rubricData.criteria[criteriaIndex]?.criteriaWeight,
          level: rubricData.levels[levelIndex]?.value,
        },
      ]);
    }
  };

  // Validation function to check if all criteria have been selected
  const validateForm = () => {
    if (rubricData?.criteria?.length === selectedCells.length) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  // Function to handle form submission
  const handleSubmit = () => {
    if (formValid) {
      setSelectedRubricCell(selectedCells);
      setShowRating(true);
      setShow(false);
    } else {
      toast.error(t("Rubric.Please select a level for each criterion"));
      return;
    }
  };

  useEffect(() => {
    validateForm();
  }, [handleSubmit]);

  // Check if rubricData is null before accessing its properties
  if (!rubricData) {
    return (
      <LoaderDiv>
        <Loader text={`${t("general.loading")}...`} />
      </LoaderDiv>
    );
  }

  return (
    <>
      <ToastContainer />
      <RubricPageeLayout>
        <TopContent>
          <LightTypography
            text={t(
              "Rubric.For each criterion listed in the rubric, assess the student's performance by selecting the appropriate level that best reflects their achievement. This involves selecting the level descriptor within the rubric for each criterion."
            )}
            fontSize="0.75rem"
          />
        </TopContent>
        <RubricTableLayout>
          {Array.from({ length: rubricData.criteria.length + 1 }, (_, i) => (
            <RubricTableLayoutRow
              key={i}
              first_child_bgcolor={
                language === "ar" && theme === "dark"
                  ? "linear-gradient(90deg, #293440 0%, #415160 107.88%)"
                  : Table_Head_TR
              }
              nth_child_bgcolor={Table_nth_child}
            >
              {Array.from({ length: rubricData.levels.length + 1 }, (_, d) => (
                <RubricTableLayoutColumn key={d}>
                  {i === 0 && d === 0 ? (
                    <OneRowViewShapeDiv>
                      <Typography text={t("Rubric.Criteria / Level")} />
                    </OneRowViewShapeDiv>
                  ) : i === 0 && d !== 0 ? (
                    <OneRowHeaderDiv>
                      <Typography text={rubricData.levels[d - 1]?.value} />
                    </OneRowHeaderDiv>
                  ) : i !== 0 && d === 0 ? (
                    <RuricCratieriaText>
                      <Typography
                        text={rubricData.criteria[i - 1]?.criteriaName}
                      />
                      <RuricCratieriaMine>
                        {language === "en" ? (
                          <>
                            <PrimaryLightNumber
                              number={`${
                                rubricData.criteria[i - 1]?.criteriaWeight
                              }%`}
                              fontSize="0.75rem"
                            />
                            <LightTypography
                              text="of total grade"
                              fontSize="0.75rem"
                              lineHeight="1.25rem"
                            />
                          </>
                        ) : (
                          <>
                            <LightTypography
                              text={t("Rubric.of total grade")}
                              fontSize="0.75rem"
                              lineHeight="1.25rem"
                            />
                            <PrimaryLightNumber
                              number={`${
                                rubricData.criteria[i - 1]?.criteriaWeight
                              }%`}
                              fontSize="0.75rem"
                            />
                            <LightTypography
                              text="kadarı"
                              fontSize="0.75rem"
                              lineHeight="1.25rem"
                            />
                          </>
                        )}
                      </RuricCratieriaMine>
                    </RuricCratieriaText>
                  ) : (
                    <ShapeDivB
                      style={{
                        // justifyContent: "center",
                        border: selectedCells.some(
                          (cell) => cell.xAxis === i && cell.yAxis === d
                        )
                          ? `1px dashed ${
                              selectedCells.some(
                                (cell) =>
                                  cell.xAxis === i &&
                                  cell.yAxis === d &&
                                  cell.weight === 100
                              )
                                ? "#5DD3B3"
                                : selectedCells.some(
                                    (cell) =>
                                      cell.xAxis === i &&
                                      cell.yAxis === d &&
                                      cell.weight >= 50 &&
                                      cell.weight < 100
                                  )
                                ? "#0B8FFF"
                                : selectedCells.some(
                                    (cell) =>
                                      cell.xAxis === i &&
                                      cell.yAxis === d &&
                                      cell.weight < 50
                                  )
                                ? "#D85D66"
                                : ""
                            }`
                          : "",
                      }}
                      onClick={() =>
                        handleCellClick(
                          i,
                          d,
                          rubricData.boxes.find(
                            (box: any) => i === box.x_axis && d === box.y_axis
                          )?.boxesWeight,
                          rubricData.boxes.find(
                            (box: any) => i === box.x_axis && d === box.y_axis
                          )?.boxesDescription || ""
                        )
                      }
                    >
                      {/* <>{console.log("i is  before", i, "d is", d)}</> */}
                      <ShapeDivBTextFormat>
                        {/* <>{console.log("i is ", i, "d is", d)}</> */}
                        <ShapeDivBTextFormatColumn>
                          <PrimaryLightNumber
                            fontSize="0.75rem"
                            number={
                              rubricData.boxes.find(
                                (box: any) =>
                                  i === box.x_axis && d === box.y_axis
                              )?.boxesWeight
                            }
                          />
                          <LightTypography
                            fontSize="0.75rem"
                            lineHeight="1.25rem"
                            text={
                              rubricData.boxes.find(
                                (box: any) =>
                                  i === box.x_axis && d === box.y_axis
                              )?.boxesDescription || ""
                            }
                          />
                        </ShapeDivBTextFormatColumn>
                        {/* <OneRowHeaderDeleteDiv>
                          <SvgDeleteIcon />
                        </OneRowHeaderDeleteDiv> */}
                      </ShapeDivBTextFormat>
                    </ShapeDivB>
                  )}
                </RubricTableLayoutColumn>
              ))}
            </RubricTableLayoutRow>
          ))}
        </RubricTableLayout>
        <ButtonsCont>
          <CancelButton
            name={t("general.Submit")}
            onClickFunction={handleSubmit}
            color="#fff"
            backgroundColor="#5DD3B3"
            borderColor="#5DD3B3"
          />
        </ButtonsCont>
      </RubricPageeLayout>
    </>
  );
};

export default RubricContent;
