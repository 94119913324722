import styled from "styled-components";
import { breakpoints } from "../../../theme";

export const CourseCardHolderStyle = styled.div<{ isAdmin: string }>`
  .slick-slider {
    max-width: 78vw;
    @media (max-width: ${breakpoints.extraLarge}) {
      max-width: 87vw;
    }
    @media (max-width: ${breakpoints.large}) {
      max-width: 86vw;
    }
    @media (max-width: 1174px) {
      max-width: 84vw;
    }
    @media (max-width: 1070px) {
      max-width: 82vw;
    }
    @media (max-width: ${breakpoints.medium}) {
      max-width: 83vw;
    }
    @media (max-width: calc(${breakpoints.small} + 15px)) {
      max-width: 78vw;
    }
    @media (max-width: ${breakpoints.tablets}) {
      max-width: 76vw;
    }
    @media (max-width: calc(${breakpoints.tablets} - 82px)) {
      max-width: 74vw;
    }
    @media (max-width: calc(${breakpoints.mobile} + 152px)) {
      max-width: 73vw;
    }
    @media (max-width: calc(${breakpoints.mobile} + 129px)) {
      max-width: 72vw;
    }
    @media (max-width: calc(${breakpoints.mobile} + 107px)) {
      max-width: 70vw;
    }
    @media (max-width: calc(${breakpoints.mobile} + 68px)) {
      max-width: 67vw;
    }
    @media (max-width: calc(${breakpoints.mobile} + 25px)) {
      max-width: 90vw;
    }
  }
  .slick-list {
    overflow: hidden;
  }
  .last-visible {
    right: 0 !important;
    left: 0 !important;
  }
  .slick-arrow.slick-prev {
    transition: opacity 0.5s ease-in-out;
    background: none;
    transform: rotate(180deg);
    position: absolute;
    top:${props => props.isAdmin === "true" ? "4rem !important" : "5rem !important"} ;
    /* background-color:red !important; */
    z-index: 3;
    color: #5dd3b3;
    .slide-button-next,
    .slide-button-prev {
      cursor: pointer;
    }
  }
  .slick-arrow.slick-next {
    transition: opacity 0.5s ease-in-out;
    background: none;
    position: absolute;
    top:${props => props.isAdmin === "true" ? "4.5rem !important" : "5.5rem !important"} ;
    /* top: 5.5rem; */
    z-index: 3;
    color: #5dd3b3;
    .slide-button-next,
    .slide-button-prev {
      cursor: pointer;
    }
  }
  .slick-arrow.slick-prev.slick-disabled,
  .slick-arrow.slick-next.slick-disabled {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
    background: none;
  }
  .slick-arrow.slick-next {
    [dir="rtl"] & {
      right: -25px !important;
      left: auto !important;
    }
  }
  .slick-arrow.slick-prev {
    [dir="rtl"] & {
    left: -25px !important;
    right: auto !important;
    }
  }
  .slick-arrow.next-cus.slick-disabled,
  .slick-arrow.prev-cus.slick-disabled {
    opacity: 50%;
    cursor: auto;
    pointer-events: none;
    background: none;
  }
  .slick-arrow.next-cus,
  .slick-arrow.prev-cus {
    transition: opacity 0.5s ease-in-out;
    background: none;
    position: absolute;
    top: 116%;
    z-index: 2;
    .slide-button-next,
    .slide-button-prev {
      svg {
        stroke: transparent;
        fill: white;
      }
    }
  }
  .slick-arrow.next-cus {
    right: 0px;
  }
  .slick-arrow.prev-cus {
    right: 50px;
    left: auto;
  }

  /* &.online-courses-swiper {
        margin-top: 10px;
    } */
  .slick-track {
    display: flex;
    /* gap: 1.4rem; */
  }

  .slick-slide {
    width: 24rem !important;
    /* margin-right: 30px; */
    > div {
      /* width: 20rem; */
      /* height: 100%; */
    }

    &:first-child {
      [dir="ltr"] & {
        margin-left: 0.7rem;
      }
    }
    &:last-child {
      [dir="rtl"] & {
        margin-right: 0.9rem;
      }
    }
  }
`;
