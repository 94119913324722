import React, { useState } from "react";
import { FlexDiv } from "../../globalStyles";
import { Container, QuestionBankContainer } from "./QuestionBanks.style";
import { SvgArrowSimple } from "../../elements/Icons";
import { LightTypography, Typography } from "../../elements/fonts/Fonts";
import QuestionBanksTypes from "../../components/questionBanks/questionBanksTypes/QuestionBanksTypes";
import SelectedQuestion from "../../components/questionBanks/selectedQuestion/SelectedQuestion";
import { MyContextProvider } from "../../contexts/QuestionBanksContext";
import NoQuestionBanksData from "../../components/NoQuestionBanksData/NoQuestionBanksData";
import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TitleHeaderTextIconNavigator from "../../elements/TitleHeaderTextIconNavigator/TitleHeaderTextIconNavigator";

const QuestionBanks = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  // state representing whether there is no data available
  // originates from the quiz creator if there is no data
  const [noData, setNoData] = useState(location.state?.noData || false);

  // two states are passed to the QuestionBanksTypes component from QuestionBanksContent.tsx
  // when the eyeIcon is clicked, the questionToEdit content and the edit mode true are passed
  const { questionToEdit, editMode } = location.state;

  // function to remove the noData view and show the QuestionBankContainer
  const handleNoDataClick = () => {
    setNoData(false);
  };

  return (
    // context provider with current view state
    <MyContextProvider>
      <Container>
        {/* <Link to={`/create-quiz/${id}`}>
        <FlexDiv pointer>
          <SvgArrowSimple />
          <LightTypography text={t("general.Back")} />
        </FlexDiv>
        </Link> */}
        <TitleHeaderTextIconNavigator title={t("question bank.New Question Bank")} navigateTo={`/create-quiz/${id}`}/>
        {/* <Typography text={t("question bank.New Question Bank")} fontSize="1.75rem" /> */}

        {noData ? (
          // If there is no data, show the NoQuestionBanksData component
          <NoQuestionBanksData onClickFunction={handleNoDataClick}/>
        ) : (
          // If there is data, show the QuestionBankContainer to add new questions along with others
          <QuestionBankContainer>
            {/* left side with question types radio buttons to select */}
            <QuestionBanksTypes questionToEdit={questionToEdit} editMode={editMode}/>

            {/* right side to render the question type selected */}
            <SelectedQuestion />
          </QuestionBankContainer>
        )}

      </Container>
    </MyContextProvider>
  );
};

export default QuestionBanks;
