// AssignmentSubmissionHeaderData.tsx

export interface TableHeader {
  name: string;
}

export const AssignmentSubmissionTableHeaderData: TableHeader[] = [
  { name: "Status" },
  { name: "Submission Date" },
  { name: "Last Modified" },
  { name: "Submission" },
  { name: "Apply Penalty" },
];
