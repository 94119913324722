import React, { PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  ComposedChart,
  Layer,
} from "recharts";
import { GraphChartsData } from "./GraphChartData";
import { Typography } from "../../../elements/fonts/Fonts";
import {
  CircleDot,
  FlexBetween,
  GroupA,
  SimiGroupA,
} from "../Subjects/ChartStyles";
import { GraphChatsInterface } from "./GraphChartInterfase";

interface ChartProps {
  dataKeyA: string;
  dataKeyB: string;
  data: GraphChatsInterface[];
  // data: any[];
  ShowdataKeyB?: boolean;
  height?: string;
  Avg?: string;
  dataKey: string;
}

export default class GraphChartCompo extends PureComponent<ChartProps> {
  // static demoUrl = "https://codesandbox.io/s/simple-line-chart-kec3v";

  render() {
    const {
      // dataKey is the name that is render in that chart
      dataKey,
      dataKeyA,
      dataKeyB,
      data,
      Avg,
      ShowdataKeyB = true,
      height = "100%",
    } = this.props;
    return (
      <ResponsiveContainer width="100%" height={height}>
        <ComposedChart
          data={data}
          margin={{
            top: 10,
            right: 20,
            left: 10,
            bottom: 40, // Increased to make space for labels
          }}
        >
          <defs>
            <linearGradient id="colorValA" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#3b3f44" stopOpacity={0.4} />
              <stop offset="99%" stopColor="#3b3f44" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorValB" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#5DD3B3" stopOpacity={0.4} />
              <stop offset="99%" stopColor="#5DD3B3" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="gradientId" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="5%" stopColor="#576371" />
              <stop offset="100%" stopColor="#2D3741" />
            </linearGradient>
          </defs>

          <CartesianGrid
            vertical={false}
            horizontal={true}
            strokeWidth={0.1}
            strokeOpacity={1}
          />
          <XAxis
            padding={{ left: 10, right: 15 }}
            tickLine={false}
            axisLine={false}
            dataKey={dataKey}
            style={{ marginTop: "2rem" }}
            interval={0}
            tick={(props) => {
              const { x, y, payload } = props;
              const words = payload.value.split(" "); // Split label into words
              return (
                <g transform={`translate(${x},${y + 5})`}>
                  <text
                    x={0}
                    y={0}
                    dy={0}
                    fontSize="0.75rem"
                    fontWeight="bold"
                    textAnchor="middle"
                    fill="#D9E4E8"
                  >
                    {words.map((word: string, index: number) => (
                      <tspan
                        key={index}
                        x={0}
                        dy={index === 0 ? "1em" : "1.5em"}
                      >
                        {word}
                      </tspan>
                    ))}
                  </text>
                </g>
              );
            }}
          />
          <YAxis tickLine={false} axisLine={false} />
          {/* <Tooltip /> */}

          <Area
            type="natural"
            dataKey={dataKeyA}
            stroke="#5DD3B3"
            strokeWidth={2}
            fillOpacity={1}
            fill="url(#colorValB)"
            dot={{
              stroke: "#5DD3B3",
              strokeWidth: 0,
              fill: "#fff",
              r: 0,
            }}
          />

          {ShowdataKeyB && (
            <Area
              type="natural"
              dataKey={dataKeyB}
              strokeWidth={2}
              stroke="url(#gradientId)"
              fillOpacity={1}
              fill="url(#colorValA)"
              dot={{
                stroke: "url(#gradientId)",
                strokeWidth: 0,
                fill: "#fff",
                r: 0,
              }}
            />
          )}
          <Line
            type="monotone"
            dataKey={Avg || ""}
            stroke="#0B8FFF"
            strokeDasharray="5 5"
            dot={{
              stroke: "#3DA4FC",
              strokeWidth: 0,
              fill: "#fff",
              r: 0,
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}
