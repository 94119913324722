import React, { useState, FC, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ContentProps } from "../../Views/teacherView/Course-Creator/courseCreatorInterface";
import {
  auth0SubIdSplitter,
  generateUUID,
  uuidSplitter,
  auth0ProviderSplitter
} from "../../utils/StringManipulation";
import axios from "axios";
import { Container } from "../../elements/StyledContainerForm/StyledContainerForm.style";
import {
  LightTypography,
  MediumTypography,
  PrimaryFontBoldText,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  LabelDiv,
  BtnsDiv,
  LabelBtnsDiv,
  ButtonsDiv,
  FlexDiv,
  FlexDivInput,
  FlexBetween,
  ExistingFolder,
  DeleteIcon,
  FirstSection,
} from "./FolderForm.style";
import StyledInput from "../../elements/StyledInput/StyledInput";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { DropZoneForm } from "../../components/MultiDropZone/DropZoneForm";
import {
  SvgRepoiconCarrier,
  SvgClose,
  SvgFolderIcon,
  SvgDelete,
} from "../../elements/Icons";
import Toggle from "../../elements/Toogle/toogle";
import TwoDatePicker from "../../elements/TwoDatePicker/TwoDatePicker";
import CancelButton from "../../elements/StyledButton/CancelButton";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import { useStateContext } from "../../contexts/ContextProvider";
import { S3Upload } from "../../services/S3Put";
import LoaderThreeDot from "../loader/loaderThreeDot/LoaderThreeDot";
import { ContentFormProps } from "../../interfaces/ContentFormProps";
import { FileInterface } from "../../interfaces/ContentFormProps";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { WebSocketConnector } from "../../Views/messages/webSocketConnection";
import { WebsocketContext } from "../../contexts/notificationContext";
import { toast } from "react-toastify";

const FolderForm: FC<ContentFormProps> = ({
  setShow,
  handleSubmit,
  setShowEditContent,
  showEditContent,
  setContentTitleInTopic,
  editContent,
  newContentTitle,
  contentId,
  activeGetModulesWithTopics,
  newTopicRefId,
}) => {
  const { t } = useTranslation();
  const classSourceId = useParams();
  const setClassSourceId = classSourceId.id;
  const { isLoading, user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const [folderName, setfolderName] = useState("");
  const baseURL = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";
  const path = "resource/create";
  const tenantName = localStorage.getItem("tenant");
  const {
    setContent_Title,
    contentID,
    topicRefId,
    contentType,
    setContent_Data,
    getContent_Data,
    contentData,
    contentIdToEdit,
    content_Index,
    setIsPopUpOpen,
  } = useStateContext();

  const [prevData, setPrevData] = useState<any>();
  const [prevDataAttachments, setPrevDataAttachments] = useState<any>();
  const [readyToEdit, setReadyToEdit] = useState(true);
  const [foundRefIdState, setFoundRefIdState] = useState<number>();
  const [visibleStudents, setVisibleStudents] = useState(true);
  const [showTimeVisibility, setShowTimeVisibility] = useState(false);
  const [description, setDescription] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [error, setError] = useState(false);
  const [firstError, setFirstError] = useState(true);
  const [newFile, setNewFile] = useState<FileInterface[]>([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [isFormValid, setFormValid] = useState(false);
  const [FilestoEdit, setFilestoEdit] = useState<string[]>([]);
  const [fileUUId, setFileUUId] = useState("");
  const [readyToPost, setReadyToPost] = useState(true);
  const [isSetLoading, setIsSetLoading] = useState(false);
  const [folderFiles, setFolderFiles] = useState<any[]>([])
  const [folderFilesToGet, setFolderFilesToGet] = useState<any[]>([])
  const [prevFilesPaths, setPrevFilesPaths] = useState<any[]>([])

  const { isReady, value, send } = useContext(WebsocketContext);
  // Function to update form
  const updateFolder = async (path: string, body: any, FolderId: any) => {
    try {
      const response = await axios.put(
        `${baseURL}/${path}/${FolderId}/${tenantName}`,
        { data: body }
      );
      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
      throw error;
    }
  };

  // Function to get data
  const getFolderToEdit = async (path: string, FolderId: number) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `${baseURL}/${path}/${FolderId}/${tenantName}`
      );
      // console.log("data to get", res.data.data)
      setPrevData(res.data.data.folder);
      setFolderFilesToGet(res.data.data.files)
      const fileNameResolved = res.data.data.files;
      if (Array.isArray(fileNameResolved)) {
        setFilestoEdit(fileNameResolved);
      } else {
        console.error("Invalid files format in the response");
      }
      setPrevDataAttachments(fileNameResolved);
      setReadyToEdit(false);
      const toReturn: any[] = res.data.data;
      return toReturn;
    } catch (error) {
      console.log(error);
    }
  };

  const extractFileNameAndPath = (filesArray: any[]) => {
    return filesArray.map(({ fileName, filePath }) => ({
      fileName,
      filePath
    }));
  };

  useEffect(() => {
    const prevFilesAndPaths = extractFileNameAndPath(folderFilesToGet)
    setPrevFilesPaths(prevFilesAndPaths)
  }, [folderFilesToGet]);

  useEffect(() => {
    if (showEditContent && folderName !== "") {
      setReadyToEdit(false);
    }
  }, [folderName]);

  // This useEffect hook is used to fetch data when showEditContent is true.
  useEffect(() => {
    const fetchData = async () => {
      const yourArray = await activeGetModulesWithTopics();
      const solutionArray: any[] = Array.isArray(yourArray) ? yourArray : [];
      function findRefIdInModules(
        modulesArray: any[],
        targetContentId: number
      ) {
        for (let i = 0; i < modulesArray.length; i++) {
          const topicsArray = modulesArray[i].topicsArray;

          for (let j = 0; j < topicsArray.length; j++) {
            const contentsArray = topicsArray[j].contents;

            for (let k = 0; k < contentsArray.length; k++) {
              if (contentsArray[k].contentId === targetContentId) {
                return contentsArray[k].refId;
              }
            }
          }
        }

        return null;
      }
      if (contentIdToEdit) {
        const contentIdToFind = contentIdToEdit;
        const foundRefId = findRefIdInModules(solutionArray, contentIdToFind);
        setFoundRefIdState(foundRefId);

        await getFolderToEdit("resource/folder", foundRefId);
      }
    };
    if (showEditContent) {
      fetchData();
      // setReadyToEdit(false);
    }
  }, []);

  // This useEffect hook is used to update the states with edit data (prevData)
  useEffect(() => {
    if (prevData && showEditContent) {
      setContent_Title(prevData.title);
      setfolderName(prevData.title);
      setDescription(prevData.description);
      setVisibleStudents(prevData.isVisible);
      setShowTimeVisibility(
        prevData.visibleFrom === null || prevData.visibleTo === null
          ? false
          : true
      );
      setFromDate(prevData.visibleFrom ? new Date(prevData.visibleFrom) : null);
      setToDate(prevData.visibleTo ? new Date(prevData.visibleTo) : null);
      setNewFile([{ path: prevData.file }]);
    }
  }, [prevData, prevDataAttachments]);

  // Function to handle description
  const handleDescriptionChange = (value: any) => {
    setDescription(value);
  };

  // Function to handle upload file
  const handleFile = async (value: any[]) => {
    const FolderPath = `class/${setClassSourceId}/resources/folders/${localStorage.getItem("currentFolderName")}`;
    setReadyToPost(false);
    setNewFile(value);
  
    const uploadPromises = value.map(async (file: any) => {
      const fileNameUUid = `${generateUUID()}${file.path}`;
      const response = await S3Upload(FolderPath, file.path, fileNameUUid, file);
  
      return {
        fileName: file.path,
        filePath: `${FolderPath}/${fileNameUUid}`
      };
    });
  
    const uploadedFiles = await Promise.all(uploadPromises);
    setFolderFiles(uploadedFiles);
    setReadyToPost(true)
  };
  

  // handle Toggle Change Visiblity for Students
  const handleToggleChangeVisible = (isChecked: boolean) => {
    setVisibleStudents(isChecked);
  };

  // function to set time visibility interval
  const handleTwoDatePickerChange = (start: Date | null, end: Date | null) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleToggleChangeTime = (isChecked: boolean) => {
    if (isChecked) {
      setShowTimeVisibility(true); // Show Time Visibility when the toggle is on
    } else {
      setShowTimeVisibility(false);
    }
  };

  // useEffect(() => {
  //   if (fileUUId !== "") {
  //     setReadyToPost(true);
  //   }
  // }, [fileUUId]);

  // function to handle validation
  const handleFormValidation = async () => {
    if (
      !folderName ||
      !description ||
      newFile.length === 0 ||
      (showTimeVisibility && (!fromDate || !toDate))
    ) {
      if (!firstError) {
        setError(true);
      }
      setShow(true);
      return false;
    } else {
      setError(false);
      if (contentId !== undefined && newContentTitle !== undefined)
        editContent(contentId, newContentTitle);
      return true;
    }
  };

  useEffect(() => {
    if (
      folderName ||
      description ||
      newFile.length > 0 ||
      (showTimeVisibility && (!fromDate || !toDate))
    ) {
      setFirstError(false);
    }
  }, [folderName, description, newFile, fromDate, toDate]);

  useEffect(() => {
    handleFormValidation();
  }, [folderName, description, newFile, fromDate, toDate]);

  // handle save
  const handleContinueClick = async () => {
    try {
      const isValid = await handleFormValidation();
      if (isValid) {
        if (!showEditContent) {
          handleSubmit();
        }

        const FolderData = {
          _id: contentIdToEdit,
          classSourceId: setClassSourceId,
          userSourceId: subId,
          provider: provider,
          contentIndex: content_Index,
          topicRefId: topicRefId,
          type: contentType,
          title: folderName,
          description: description,
          files: [...folderFiles, ...prevFilesPaths],
          showTimeVisibility: showTimeVisibility,
          visibleStudents: visibleStudents,
          fromDate: fromDate,
          toDate: toDate,
        };

        if (!showEditContent) {
          const response = await axios.post(
            `${baseURL}/${path}/${tenantName}`,
            { data: FolderData }
          );
          // console.log("data to post", FolderData)
          if (isReady && send) {

            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "create",
                  title: folderName,
                  contentType: "Folder",
                  refId: response.data.data.resourceId,
                  refTable: "Folders",
                  subId: subId,
                  provider: provider,
                  type: "content",
                  description: `Title: ${folderName}`,
                },
              })
            );
          }
          setIsSetLoading(false);
        } else if (showEditContent) {
          await updateFolder("resource/folder", FolderData, foundRefIdState);
          // console.log("data to update", FolderData)
          if (isReady && send) {
            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "update",
                  title: folderName,
                  contentType: "Folder",
                  subId: subId,
                  provider: provider,
                  refId: foundRefIdState,
                  refTable: "Folders",
                  type: "content",
                  description: `Title: ${folderName}`,
                },
              })
            );
          }
        }
        setNewFile([]);
        setShow(false);
        setIsPopUpOpen(false);
        await activeGetModulesWithTopics();
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setReadyToPost(true);
      await (activeGetModulesWithTopics && activeGetModulesWithTopics());
    }
  };

  // function to set accepted type for file
  const accF: { [key: string]: string[] } = {
    "*/*": ["*"],
  };

  // function to remove uploaded file
  const handleRemoveFile = () => {
    setNewFile([]);
  };

  // function to remove edited file
  const handleRemoveEditedFile = (indexToRemove: number) => {
    const updatedFiles = [...prevFilesPaths];
    updatedFiles.splice(indexToRemove, 1);
    setPrevFilesPaths(updatedFiles);
  };
  const { backgroundColor, themeTextColor, inputBorderColor, iconCloseColor } =
    useRecoilValue(ColorSelector);
  return (
    <Container
      themetextcolor={themeTextColor}
      backgroundcolor={backgroundColor}
      scrollbar={inputBorderColor}
    >
      {/* header */}
      {/* <FlexBetween>
        <FlexDiv>
          <SvgFolderIcon />
          <PrimaryFontBoldText text={t("folder.Folder")} fontSize="1.125rem" />
        </FlexDiv> */}
        {showEditContent && readyToEdit && <LoaderThreeDot />}
        {/* <SvgClose
          color={iconCloseColor}
          onClick={() => {
            setShow(false);
            setIsPopUpOpen(false);
            setShowEditContent(false);
          }}
          style={{ cursor: "pointer" }}
        /> */}
      {/* </FlexBetween> */}

      {/* title */}
      <LabelDiv>
        <FlexDiv gap="0.25rem">
          <LightTypography text={t("forms.Title")} fontSize="0.875rem" />
          <span>*</span>
        </FlexDiv>
        <StyledInput
          placeholder={t("folder.Enter Folder Title")}
          type="text"
          onChange={(e: any) => {
            setfolderName(e.target.value);
            window.localStorage.setItem("currentFolderName", e.target.value)
            setContent_Title(e.target.value);
            setContentTitleInTopic(e.target.value);
          }}
          value={folderName}
          width="100%"
        />
        {!folderName && error && (
          <ErrorDiv>{`${t("forms.Please")} ${t(
            "folder.Enter Folder Title"
          )}`}</ErrorDiv>
        )}
      </LabelDiv>

      {/* description */}
      <LabelDiv>
        <FlexDiv gap="0.25rem">
          <LightTypography text={t("forms.Description")} fontSize="0.875rem" />
          <span>*</span>
        </FlexDiv>
        <TinyEditor
          onChange={handleDescriptionChange}
          placeholder={t("forms.Description")}
          initialValue={`${prevData && prevData.description ? prevData.description : ""
            }`}
          height="13.25rem"
        />
        {!description && error && (
          <ErrorDiv>{`${t("forms.Please")} ${t(
            "forms.Enter Description"
          )}`}</ErrorDiv>
        )}
      </LabelDiv>

      {prevFilesPaths.map((file: any, index) => (
        <div key={index}>
          <ExistingFolder>
            <FirstSection>
              <SvgRepoiconCarrier />
              <MediumTypography text={file.fileName} />
            </FirstSection>

            <DeleteIcon>
              <SvgDelete
                color="#D85D66"
                onClick={() => handleRemoveEditedFile(index)}
              />
            </DeleteIcon>
          </ExistingFolder>
        </div>
      ))}

      {/* dropzone */}
      <DropZoneForm
        text={localStorage.getItem("currentFolderName") || folderName ? t("course view.Add your Folders") : t("forms.Enter folder title before uploading")}
        onRemoveFile={handleRemoveFile}
        testDrop={handleFile}
        acceptedTypes={accF}
        initialFiles={[]}
        isDisabled={localStorage.getItem("currentFolderName") || folderName ? false : true}
      />
      {newFile.length === 0 && error && (
        <ErrorDiv>{t("course view.Please Select a Folder")}</ErrorDiv>
      )}

      {/* toggles */}
      <BtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleChangeVisible}
            checked={visibleStudents}
            id="roleToggle1"
          />
          <TypographyRegularDescription
            text={t("forms.Make Visible for Students")}
            fontSize="1rem"
          />
        </LabelBtnsDiv>
        <LabelBtnsDiv>
          <Toggle
            onToggle={handleToggleChangeTime}
            checked={showTimeVisibility}
            id="roleToggle2"
          />
          <TypographyRegularDescription
            text={t("forms.Set Time Visibility Interval")}
            fontSize="1rem"
          />
          {showTimeVisibility && (
            <>
              <TwoDatePicker
                onDateChange={handleTwoDatePickerChange}
                prevStartDate={fromDate}
                prevEndDate={toDate}
              />
              {(!fromDate || !toDate) && error && (
                <ErrorDiv>{`${t("forms.Please")} ${t(
                  "forms.Set Time Visibility Interval"
                )}`}</ErrorDiv>
              )}
            </>
          )}
        </LabelBtnsDiv>
      </BtnsDiv>

      {/* actions buttons */}
      <ButtonsDiv>
        <CancelButton
          name={t("forms.Cancel")}
          onClickFunction={() => {
            setShow(false);
            setIsPopUpOpen(false);
            setShowEditContent(false);
            window.localStorage.removeItem("currentFolderName")
          }}
          color="#D85D66"
          borderColor="#D85D66"
          backgroundColor="transparent"
        />
        {readyToPost ? (
          <CancelButton
            name={t("forms.Save")}
            onClickFunction={async () => {
              if (!error) {
                await setIsSetLoading(true);
                setShowEditContent(false);
                await handleContinueClick();
                setContent_Title(folderName);
                window.localStorage.removeItem("currentFolderName")
              }
            }}
            disabled={isSetLoading}
            color="#fff"
            borderColor="#5DD3B3"
            backgroundColor="#5DD3B3"
          />
        ) : (
          <CancelButton
            name="Loading"
            display="block"
            onClickFunction={() => {
              handleFormValidation();
              setContent_Title(folderName);
            }}
            color="#fff"
            borderColor="#5DD3B3"
            backgroundColor="#5DD3B3"
          />
        )}
      </ButtonsDiv>
    </Container>
  );
};

export default FolderForm;
