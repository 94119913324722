import styled from "styled-components";

export const StudentCompletionTracingLayout =styled.div`
display:flex;
flex-direction:column;
width:100%;
border-radius: 0.625rem;
background: linear-gradient(180deg, #415160 0%, #293440 100%);


`;

export const StudentCompletionTracingRow=styled.div`
display:flex;
justify-content:space-between;
width:100%;

padding:1.25rem;


&:nth-child(2){
  margin-top:1.25rem;
  opacity: 0.5;
background: var(--Grey-Gradient, linear-gradient(90deg, #415160 0%, #293440 107.88%));
background-color:red; 
}
`;

export const StudentCompletionTracingHeaderCell=styled.div`
display:flex;
flex:1;
gap:0.4rem;
justify-content:center;
align-items:center;
&:first-child{
  flex:1.4%;
  justify-content:flex-start;
  align-items:center;
}
`;


export const StudentCompletionTracingCell=styled.div`
display:flex;
flex:1;
gap:0.4rem;
justify-content:center;
align-items:center;
&:first-child{
  flex:1.4%;
  justify-content:flex-start;
  align-items:center;
}
`;
export const StudentCompletionTracingIMG=styled.img`
display:cover;
width: 3.125rem;
height: 3.125rem;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius:50%;
`;
