import React, { ReactNode, createContext, useContext, useState } from 'react';


interface CourseCreatorAssignmentProps {
    selectedRadio: string;
    setSelectedRadio: React.Dispatch<React.SetStateAction<string>>;
    selectedTitle: string;
    setSelectedTitle: React.Dispatch<React.SetStateAction<string>>;
    globalAssignmentShowPopup: boolean;
    setGlobalAssignmentShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
    selectedRule: string;
    setSelectedRule: React.Dispatch<React.SetStateAction<string>>;
    showTimeVisibility: boolean;
    setShowTimeVisibility: React.Dispatch<React.SetStateAction<boolean>>;
    showSelectGrouping: boolean;
    setShowSelectGrouping: React.Dispatch<React.SetStateAction<boolean>>;
    showPlagiarismPrevention: boolean;
    setShowPlagiarismPrevention: React.Dispatch<React.SetStateAction<boolean>>;
    selectedGrouping: string;
    setSelectedGrouping: React.Dispatch<React.SetStateAction<string>>;
    assignmentName: string;
    setAssignmentName: React.Dispatch<React.SetStateAction<string>>;
    description: string;
    setDescription: React.Dispatch<React.SetStateAction<string>>;
    maxGrade: string;
    setMaxGrade: React.Dispatch<React.SetStateAction<string>>;
    startDate: Date | null;
    setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
    dueDate: Date | null;
    setDueDate: React.Dispatch<React.SetStateAction<Date | null>>;
    cutOffDate: Date | null;
    setCutOffDate: React.Dispatch<React.SetStateAction<Date | null>>;
    penalty: string;
    setPenalty: React.Dispatch<React.SetStateAction<string>>;
    visibleStudents: boolean;
    setVisibleStudents: React.Dispatch<React.SetStateAction<boolean>>;
    fromDate: Date | null;
    setFromDate: React.Dispatch<React.SetStateAction<Date | null>>;
    toDate: Date | null;
    setToDate: React.Dispatch<React.SetStateAction<Date | null>>;
    answerBoxChecked: boolean;
    setAnswerBoxChecked: React.Dispatch<React.SetStateAction<boolean>>;
    fileSubmissionChecked: boolean;
    setFileSubmissionChecked: React.Dispatch<React.SetStateAction<boolean>>;
    file: FileList | null;
    setFile: React.Dispatch<React.SetStateAction<FileList | null>>;
    urlAttachment: string;
    setUrlAttachment: React.Dispatch<React.SetStateAction<string>>;
    prevDataAttachments:any;
    setPrevDataAttachments:React.Dispatch<React.SetStateAction<any>>;
    isGraded: boolean;
    setIsGraded: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context
const CourseCreatorAssignmentContext = createContext<CourseCreatorAssignmentProps | undefined>(undefined);

// Create a provider component
const CourseCreatorAssignmentProvider = ({ children }: { children: ReactNode }) => {
  const [selectedRadio, setSelectedRadio] = useState("grade");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [globalAssignmentShowPopup, setGlobalAssignmentShowPopup] = useState<boolean>(false);
  const [selectedRule, setSelectedRule] = useState("");
  const [showTimeVisibility, setShowTimeVisibility] = useState(false);
  const [showSelectGrouping, setShowSelectGrouping] = useState(false);
  const [showPlagiarismPrevention, setShowPlagiarismPrevention] =
    useState(false);
  const [selectedGrouping, setSelectedGrouping] = useState("");
  const [assignmentName, setAssignmentName] = useState("");
  const [description, setDescription] = useState("");
  const [maxGrade, setMaxGrade] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [cutOffDate, setCutOffDate] = useState<Date | null>(null);
  const [penalty, setPenalty] = useState("");
  const [visibleStudents, setVisibleStudents] = useState(true);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [answerBoxChecked, setAnswerBoxChecked] = useState(false);
  const [fileSubmissionChecked, setFileSubmissionChecked] = useState(false);
  const [file, setFile] = useState<FileList | null>(null);
  const [urlAttachment, setUrlAttachment] = useState("");
  const [prevDataAttachments, setPrevDataAttachments] = useState<any>();
  const [isGraded, setIsGraded] = useState(false);

    return (
        <CourseCreatorAssignmentContext.Provider
        value={{
            selectedRadio,
            setSelectedRadio,
            selectedTitle,
            setSelectedTitle,
            globalAssignmentShowPopup,
            setGlobalAssignmentShowPopup,
            selectedRule,
            setSelectedRule,
            showTimeVisibility,
            setShowTimeVisibility,
            showSelectGrouping,
            setShowSelectGrouping,
            showPlagiarismPrevention,
            setShowPlagiarismPrevention,
            selectedGrouping,
            setSelectedGrouping,
            assignmentName,
            setAssignmentName,
            description,
            setDescription,
            maxGrade,
            setMaxGrade,
            startDate,
            setStartDate,
            dueDate,
            setDueDate,
            cutOffDate,
            setCutOffDate,
            penalty,
            setPenalty,
            visibleStudents,
            setVisibleStudents,
            fromDate,
            setFromDate,
            toDate,
            setToDate,
            answerBoxChecked,
            setAnswerBoxChecked,
            fileSubmissionChecked,
            setFileSubmissionChecked,
            file,
            setFile,
            urlAttachment,
            setUrlAttachment,
            prevDataAttachments,
            setPrevDataAttachments,
            isGraded,
            setIsGraded
        }}
    >
            {children}
        </CourseCreatorAssignmentContext.Provider>
    );
};

// Create a custom hook to access the context values
const AssignmentContext = () => {
    const context = useContext(CourseCreatorAssignmentContext);
    if (!context) {
        throw new Error('AssignmentContext must be used within a CourseCreatorAssignmentProvider');
    }
    return context;
};

export { CourseCreatorAssignmentProvider, AssignmentContext };
