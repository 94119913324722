import { AnalyticsUserViewCompoHeaderInterface, AnalyticsUserViewCompoInterface } from "./AnalyticsUserViewCompointerface";
export const AnalyticsUserViewCompoHeaderData: AnalyticsUserViewCompoHeaderInterface[] =[
  {id:"1",
  label:"Course Name",},
{id:"2",
label:"Term/Semester",
},
{id:"3",
label:"Start Date",
},
{id:"4",
label:"Close Date ",
},
{id:"5",
label:"Status ",
},

// {id:"6",
// label:"Course Average Grade ",
// },
]


export const AnalyticsUserViewData: AnalyticsUserViewCompoInterface[] =[
  {id:"1",
  FullName:"Shouman",
  role:"teacher",
  Data:[
    {
      id: "1",
      CourseName: "Business and Finance",
      TermSemester: "Fall 2022/2023",
      StartDate: "9 Aug, 2023 | 8:00 AM",
      CloseDate: "26 Aug, 2023 | 8:00 AM",
      Status: "In progress",
      CourseAverageGrade: "-",
    
    },
    {
      id: "2",
      CourseName: "Computer Science",
      TermSemester: "Fall 2022/2023",
      StartDate: "15 Aug, 2023 | 9:30 AM",
      CloseDate: "30 Aug, 2023 | 9:30 AM",
      Status: "In progress",
      CourseAverageGrade: "-",
    },
    {
      id: "3",
      CourseName: "Marketing Strategies",
      TermSemester: "Fall 2022/2023",
      StartDate: "12 Aug, 2023 | 10:15 AM",
      CloseDate: "28 Aug, 2023 | 10:15 AM",
          Status: "Completed",
      CourseAverageGrade: "A",
    },
    {
      id: "4",
      CourseName: "Environmental Science",
      TermSemester: "Fall 2022/2023",
      StartDate: "10 Aug, 2023 | 11:00 AM",
      CloseDate: "27 Aug, 2023 | 11:00 AM",
          Status: "In progress",
      CourseAverageGrade: "",
    },
    {
      id: "5",
      CourseName: "Human Resource Management",
      TermSemester: "Fall 2022/2023",
      StartDate: "11 Aug, 2023 | 1:30 PM",
      CloseDate: "29 Aug, 2023 | 1:30 PM",
          Status: "Completed",
      CourseAverageGrade: "",
    }]
},
{id:"2",
FullName:"Nasser",
role:"student",
Data:[
  {
    id: "1",
    CourseName: "Business and Finance",
    TermSemester: "Fall 2022/2023",
    StartDate: "9 Aug, 2023 | 8:00 AM",
    CloseDate: "26 Aug, 2023 | 8:00 AM",
        Status: "In progress",
      CourseAverageGrade: "",
  },
  {
    id: "2",
    CourseName: "Computer Science",
    TermSemester: "Fall 2022/2023",
    StartDate: "15 Aug, 2023 | 9:30 AM",
    CloseDate: "30 Aug, 2023 | 9:30 AM",
        Status: "",
      CourseAverageGrade: "",
  },
  {
    id: "3",
    CourseName: "Marketing Strategies",
    TermSemester: "Fall 2022/2023",
    StartDate: "12 Aug, 2023 | 10:15 AM",
    CloseDate: "28 Aug, 2023 | 10:15 AM",
        Status: "Completed",
      CourseAverageGrade: "",
  },
  {
    id: "4",
    CourseName: "Environmental Science",
    TermSemester: "Fall 2022/2023",
    StartDate: "10 Aug, 2023 | 11:00 AM",
    CloseDate: "27 Aug, 2023 | 11:00 AM",
        Status: "In progress",
      CourseAverageGrade: "-",
  }]
}
]