import styled from "styled-components";

export const GCSearchItem = styled.div`
  height: 3rem;
  width: 90%;
  margin: auto;
`;

// export const  GC = styled.div`

// `;

export const GCChosenContainer = styled.div`
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.55rem;
  max-width: 15.875rem;
  /* background:green; */
`;

export const GCChosenContact = styled.div<{ bgColor: string }>`
  width: 5.5rem;
  /* height: 1.375rem; */
  display: flex;
  justify-content: space-between;
  border-radius: 1.5625rem;
  /* background: var(--Grey-Gradient, linear-gradient(90deg, #415160 0%, #293440 107.88%)); */
  padding-right: 0.3rem;
  gap: 0.3rem;
  /* background:red; */
  align-items: center;
  background: ${(props) => props.bgColor};
`;

export const GCChosenContactProfilePic = styled.img`
  border: 1px solid #707070;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  border-radius: 50%;
`;

export const GCChosenContactName = styled.div``;

export const GCChosenAction = styled.div`
  padding: 0.2rem;
  color: #5dd3b3;
  cursor: pointer;
`;

export const GCAllUsersRender = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 0.31rem;
  height: "10vh";
  /* background-color:yellow; */

  overflow-x: hidden;
  overflow-y: scroll;
  /* padding-right: 1.69rem; */
  &::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(
      --Green-Gradient,
      linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)
    );
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0.625rem;
  }
`;

export const GCAllUsersRenderCell = styled.div<{ bgColor: string }>`
  width: 100%;
  display: flex;
  height: 4.3125rem;
  flex-shrink: 0;
  align-items: center;
  /* justify-content:center; */
  /* background-color:aqua !important; */
  padding: 1.25rem;
  /* background: rgba(255, 255, 255, 0.03); */
  /* background:red; */
  background: ${(props) => props.bgColor};
`;

export const GCAllInfo = styled.div`
  /* width:100%; */
  display: flex;
  align-items: center;
  gap: 0.81rem;
  &:dir(rtl) {
    margin-right: 0.5rem;
  }
  /* background-color: red; */
  margin-top: -0.5rem;
`;

export const GCCricleAction = styled.div`
  /* width:100%; */
  display: flex;
  align-items: center;
  width: 1.875rem;
  height: 1.875rem;
  flex-shrink: 0;
  background-color: #5dd3b3;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  &:dir(rtl) {
    transform: rotate(180deg);
  }
`;

export const GCLineAction = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const GCBackAction = styled.div`
  display: flex;
  cursor: pointer;
  &:dir(ltr) {
    transform: rotate(180deg);
  }
  &:dir(rtl) {
    transform: rotate(0deg);
  }
`;

export const GCTitleRow = styled.div`
  display: flex;
  width: 90%;
  gap: 1.25rem;
  padding: 1.25em;
  padding-bottom: 0;
`;

export const GCShowForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 19rem;
  gap: 1.25rem;
  height: 75vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 1.69rem;
  &::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(
      --Green-Gradient,
      linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)
    );
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #576371;
    border-radius: 0.625rem;
  }
`;
