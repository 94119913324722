import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GradeItemDetail,
  GradingDeatils,
  HorDividLine,
  QuizCard,
  Quizes,
  RecordAttemptsWrapper,
  StudentDetailsWrapper,
  StudentQuizDetailsRight,
  StudentsDetailsLeft,
  StudentsHeaderContainer,
  StudentsQuizDetailsLeft,
  StudentsQuizzes,
  TextIconStyling,
} from "../AssignmentInstructor.style";
import {
  SvgAttempt,
  SvgEdit,
  SvgEmpty,
  SvgGrade,
  SvgStar,
} from "../../../elements/Icons";
import { LightTypography, Typography } from "../../../elements/fonts/Fonts";
import ReusableProfile from "../../../elements/reusableProfile/ReusableProfile";
import ProgressBar from "../../../elements/progressBar/ProgressBar";
import { get } from "../../../utils/AxiosRequests";
import {
  calculateClassAverage,
  calculateTotalePoints,
} from "../InstructorViewUtils";
import QuizGradesDetails from "./QuizGradesDetails";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import { RPProfilePic } from "../../PostsTimeLine/ViewPost/ViewPost.style";
import { useRecoilValue } from "recoil";
import { CourseColorSelector } from "../../../recoil/ThemeSelectors";
import { ResourceFrom } from "../../../Views/courseView/content/Content";
import { useTranslation } from "react-i18next";
import { toArabicDigits } from "../../../utils/formatDayArabic";
interface ActivityProps {
  id: any;
  resourceR: ResourceFrom;
}
const QuizInstructor = ({ id, resourceR }: ActivityProps) => {
  const [totalNumberOfAttempts, setTotalNumberOfAttempts] = useState<number>(0);
  const [minimumGrade, setMinimumGrade] = useState<number>(0);
  const [maximumGrade, setMaximumGrade] = useState<number>(0);
  const [average, setAverage] = useState<number>(0);

  const [maximumGradeOfQuiz, setMaximumGradeOfQuiz] = useState<number>(0);
  const [cutOfDateTime, setCutOffDateTime] = useState<Date>();
  const [startDateTime, setStartDateTime] = useState<Date>();
  const [studentData, setStudentData] = useState<any[]>([]);
  const [totalPoints, setTotalPoints] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [valid, setValid] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();
  const classId = params.classId;
  const tenantName = localStorage.getItem("tenant");
  const profilrImage = require("../../../assets/userProfile.png");
  const { setUserSelectedIndex } = useContext(ClassContext);
  const { ClassGradientBox } = useRecoilValue(CourseColorSelector);
  const { t } = useTranslation();
  const [NbOfSubbmition, setNbOfSubbmition] = useState<number>(0);
  const [gradedCount, setGradedCount] = useState<number>(0);

  // Function to Fetch the Student Submissions data
  const fetchStudentData = async () => {
    try {
      const studentsData = await get(
        `quiz/get/all/answers/${id}/${tenantName}`
      );
      if (studentsData.data && studentsData.data.length > 0) {
        setStudentData(studentsData.data);
        setNbOfSubbmition(studentsData.data.length);
        const gradedSubmissions = studentsData.data.filter(
          (submission: any) => submission.isGraded === true
        );
        const NbofGradedStudents = gradedSubmissions.length;
        setGradedCount(NbofGradedStudents);
        setLoading(false);
      } else {
        setStudentData([]);
        setLoading(false);
      }
      const questionsData = await get(`quiz/questions/${id}/${tenantName}`);
      setTotalPoints(calculateTotalePoints(questionsData));
      setMaximumGradeOfQuiz(questionsData.data.maxGrade);
      setTotalNumberOfAttempts(questionsData.data.numberOfAttempts);
      // Check If The Quiz is valid to let the instructor to go to the grader
      if (
        questionsData &&
        questionsData.data &&
        questionsData.data.cutOffDateTime
      ) {
        const currentDate = new Date();
        const dueDateTime = new Date(questionsData.data.cutOffDateTime);
        const startDateTime = new Date(questionsData.data.startDateTime);
        setStartDateTime(startDateTime);
        setCutOffDateTime(dueDateTime);
        const isValid = currentDate >= dueDateTime;
        setValid(isValid);
      } else {
        setValid(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Call the fetchStudentData to Fetch the Data
  useEffect(() => {
    fetchStudentData();
    setLoading(true);
  }, [id]);

  //  Call the calculateClassAverage to calculate the Average of the class
  useEffect(() => {
    if (studentData && studentData.length > 0 && maximumGradeOfQuiz !== 0) {
      setAverage(
        calculateClassAverage(studentData, maximumGradeOfQuiz).average
      );
      setMinimumGrade(
        calculateClassAverage(studentData, maximumGradeOfQuiz).minimumGrade
      );
      setMaximumGrade(
        calculateClassAverage(studentData, maximumGradeOfQuiz).maximumGrade
      );
    }
  }, [studentData, maximumGradeOfQuiz]);

  // If there is no student submissions
  if (!loading && studentData && studentData.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <SvgEmpty />
        <Typography text={t("assignment.No submissions yet")} />
      </div>
    );
  } else if (loading) {
    // is the data isn't fetched yet
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography text={`${t("general.loading")}...`} />
      </div>
    );
  }

  const handleGraderNavigate = (userIndex: number) => {
    setUserSelectedIndex(userIndex);
    navigate(`/graderQuiz/${id}/${classId}`, {
      state: {
        activity: resourceR,
        moduleIndex: resourceR.moduleIndex,
        topicIndex: resourceR.topicIndex,
        contentIndex: resourceR.contentIndex,
      },
    });
  };

  return (
    <>
      <>
        {(() => {
          return (
            <>
              {/* The Grades Details of Quiz Component Mount Here */}
              <QuizGradesDetails
                minimumGrade={minimumGrade}
                maximumGrade={maximumGrade}
                average={average}
                totalNumberOfAttempts={totalNumberOfAttempts}
                startDateTime={startDateTime}
                cutOfDateTime={cutOfDateTime}
                gradedCount={gradedCount}
                NbOfSubbmition={NbOfSubbmition}
                loading={loading}
              />
              <HorDividLine></HorDividLine>
              <StudentsQuizzes bgColor={ClassGradientBox}>
                <StudentsHeaderContainer>
                  <Typography text={t("online session.Students")} />
                  {!valid && (
                    <>
                      <div style={{ marginBottom: "1rem" }}></div>
                      <LightTypography
                        fontSize="0.875rem"
                        text={t(
                          "course view.You are unable to correct the assignment submissions prior to cut-off date"
                        )}
                      />
                    </>
                  )}
                </StudentsHeaderContainer>
                {studentData.map((student, index) => {
                  // Here we are mapping the student's submission
                  const countPoints = student.finalGrade;
                  return (
                    <div key={index}>
                      {student ? (
                        <>
                          <StudentDetailsWrapper>
                            <div
                              style={{
                                display: "flex",
                                columnGap: "0.63rem",
                              }}
                            >
                              <RPProfilePic
                                src={
                                  student.userData.profilePicture // This is the profile picture of the student
                                    ? student.userData.profilePicture
                                    : profilrImage
                                }
                              />
                              <StudentsQuizDetailsLeft>
                                <div
                                  style={{
                                    display: "flex",
                                    columnGap: "0.62rem",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    text={student.userData.username}
                                    fontSize="0.875rem"
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    columnGap: "0.62rem",
                                  }}
                                >
                                  <SvgAttempt />
                                  <LightTypography // How much the student has answered on the quiz
                                    text={` ${
                                      student.numberOfAttemptsSubmit || 0
                                    }`.toUpperCase()}
                                    fontSize="0.75rem"
                                  />
                                </div>
                                {/* <LightTypography
                                  text={`Attempt: ${
                                    student.numberOfAttemptsSubmit || 0
                                  }`}
                                  fontSize="0.75rem"
                                  color="#D85D66"
                                  fontStyle="italic"
                                /> */}
                                <LightTypography
                                  text={`${student.commentAttempt || ""}`}
                                  fontSize="0.75rem"
                                  color="#D85D66"
                                  fontStyle="italic"
                                />
                              </StudentsQuizDetailsLeft>
                            </div>
                            <StudentQuizDetailsRight>
                              {/* {index === 1 && (
                                                                        <div
                                                                            style={{
                                                                                alignSelf: "end",
                                                                                textDecorationLine:
                                                                                    "underline",
                                                                            }}
                                                                        >
                                                                            <LightTypography
                                                                                text="Plagiarism"
                                                                                fontSize="0.75rem"
                                                                            />
                                                                        </div>
                                                                    )} */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    columnGap: "0.62rem",
                                    alignItems: "center",
                                  }}
                                >
                                  <LightTypography
                                    text={t("assignment.Student's grade")}
                                    fontSize="0.75rem"
                                  />
                                  {valid ? ( // If the current date is bigger than the cut off date the pen will appear
                                    <SvgEdit
                                      style={{
                                        cursor: "pointer",
                                        color: "#5dd3b3",
                                      }}
                                      onClick={() =>
                                        handleGraderNavigate(index)
                                      }
                                      width="0.75rem"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {maximumGradeOfQuiz !== 0 &&
                                countPoints !== undefined ? (
                                  // The Grade of The student
                                  <Typography
                                    text={toArabicDigits(
                                      countPoints.toFixed(0).toString()
                                    )}
                                    fontSize="0.75rem"
                                    color="#6C9AF0"
                                  />
                                ) : (
                                  <></>
                                )}
                              </div>
                              {maximumGradeOfQuiz !== 0 &&
                              countPoints !== undefined ? (
                                // The Grade of the student on the progress bar
                                <ProgressBar
                                  progress={Number(countPoints.toFixed(2))}
                                  color="#6C9AF0"
                                  // progressColor="#20272E"
                                  maxprogress={maximumGradeOfQuiz}
                                />
                              ) : (
                                <ProgressBar
                                  progress={0}
                                  color="#6C9AF0"
                                  progressColor="#20272E"
                                  maxprogress={maximumGradeOfQuiz}
                                />
                              )}
                            </StudentQuizDetailsRight>
                          </StudentDetailsWrapper>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </StudentsQuizzes>
            </>
          );
        })()}
      </>
    </>
  );
};

export default QuizInstructor;
