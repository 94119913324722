import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const PdfViewrContainer = styled.div<StyleThemeInterface>`
  margin-top: 3.31rem;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  &:dir(ltr) {
    border-radius: 10px 0px 0px 10px;
  }
  &:dir(ltr) {
    border-radius: 0 10px 10px 0px;
  }
  border: 1px solid ${(props) => props.bordercolor};
  width: calc(100% + 1.51rem);
  margin-bottom: 1rem;
  /* box-sizing: border-box; */
  /* flex: 2 1; */
`;

export const Pdfcontainer = styled.div`
  min-width: 20rem;
  height: 100%;
  /* background-color: cyan; */
  &:dir(ltr) {
    margin-left: -0.5rem;
  }
  &:dir(rtl) {
    margin-right: -0.5rem;
  }
  flex: 2.5;
  position: relative;
  #header-bar {
    height: 2.25rem;
    color: white;
    background-color: #384450;
    display: flex;
    justify-content: flex-end !important;
    &:dir(ltr) {
      padding-left: 5rem;
    }
    &:dir(rtl) {
      padding-right: 5rem;
    }
  }
  #pdf-controls {
    background-color: #384450;
    height: 2.75rem;
    /* display: flex;
    justify-content: space-between; */
  }
  #pdf-download {
    display: none;
  }
  #pdf-zoom-reset {
    /* display: none; */
  }
  #pdf-toggle-pagination {
    display: none;
  }
  #pdf-page-info {
    color: white;
  }
`;
export const SideToolsContainer = styled.div`
  height: fit-content;
  flex: 1;
  min-width: 19.5rem;
  /* @media (max-width: 768px) {
    height: 100%;
  } */
`;

export const BottomMarging = styled.div`
  position: absolute;
  &:dir(ltr) {
    left: 0.5rem;
  }
  &:dir(rtl) {
    right: 0.5rem;
  }

  display: flex;
  align-items: center;
  top: 0.2rem;
  gap: 0.5rem;
  z-index: 5;
  cursor: pointer;
  color: #5dd3b3;
  /* @media (max-width: 768px) {
    height: 100%;
  } */
`;
