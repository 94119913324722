import styled from "styled-components";

interface MainHolderProps {
  inputwidth: string;
}
interface InputProps {
  inputheight: string;
  inputborderradius: string;
  inputborder: string;
  padding: string;
  color?: string;
}
interface SearchIconHolderProps {
  display: string;
  theme?: string;
}

export const MainHolder = styled.div<MainHolderProps>`
  position: relative;
  width: ${({ inputwidth }) => inputwidth};
`;
export const InputHolder = styled.input<InputProps>`
  height: ${({ inputheight }) => inputheight};
  border-radius: ${({ inputborderradius }) => inputborderradius};
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
  width: 100%;
  border: ${({ inputborder }) => inputborder};
  opacity: 0.5;
  background-color: transparent;
  color: ${(props) => props.color};
  outline: none;
  padding: ${({ padding }) => padding};
  &:focus {
    caret-color: ${(props) => props.theme.dark.main_color};
    box-shadow: 0 0 5px 2px rgb(84 218 192 / 25%);
  }
`;

export const SearchIconHolder = styled.div<SearchIconHolderProps>`
  position: absolute;
  &:dir(ltr) {
    right: 1.25rem;
  }
  &:dir(rtl) {
    left: 1.25rem;
  }
  top: 0.88rem;
  /* background: ${(props) =>
    props.theme === "light" ? "#FFFFFF" : " #415160 "}; */
  /* padding: -0.3rem; */
  z-index: 5;
  height: 1rem;
  /* width: 1rem; */
  display: ${({ display }) => display};
  column-gap: 0.63rem;
  &:first-child {
    cursor: pointer;
  }
  &:last-child {
    cursor: pointer;
  }
`;
