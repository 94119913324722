import React, { useContext, useEffect, useRef, useState } from "react";
import {
  AddChatCell,
  AddChatCellImg,
  AddChatLayout,
  AddChatRender,
  AddChatSearch,
} from "./AddChatUser.style";
import { LightTypography, MediumTypography } from "../../elements/fonts/Fonts";
import SearchElement from "../../elements/searchElement/SearchElement";
import { get } from "../../utils/AxiosRequests";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { WebSocketConnector } from "../../Views/messages/webSocketConnection";
import { useTranslation } from "react-i18next";
import { auth0SubIdSplitter } from "../../utils/StringManipulation";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import SearchWithFilter from "../../elements/searchWithFilter/SearchWithFilter";
import axios from "axios";

interface AddChatUserCompoProps {
  selectedUserToCreateChat?: any;
  setSelectedUserToCreateChat?: any;
  onUserSelect?: (userId: string) => void;
}

export const AddChatUserCompo = ({
  selectedUserToCreateChat,
  setSelectedUserToCreateChat,
}: AddChatUserCompoProps): JSX.Element => {
  const [searchText, setSearchText] = useState<string>("");
  const [Users, setUsers] = useState<any[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const tenantName = localStorage.getItem("tenant");
  const [mySourceId, setMySourceId] = useState<string>("");
  const UrlId = useParams();
  const classId = UrlId.classId;
  const image = require("../../assets/userProfile.png");
  const { user, getAccessTokenSilently } = useAuth0();
  const myEmail = user?.email?.toLowerCase();
  const { t } = useTranslation();

  const handleUserClick = (userId?: string) => {
    setSelectedUserToCreateChat((prevSelectedUser: any) =>
      prevSelectedUser === userId ? "" : userId || ""
    );
  };

  const fetchUsersData = async () => {
    try {
      const response = await get(`participant/class/${classId}/${tenantName}`);
      const auditors = await axios.get(`https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/classAuditor/auditors/${tenantName}/${classId}`)

      setDataLoading(false);
      const filteredData = response.data.filter(
        (user: any) => user.email.toLowerCase() === myEmail
      );
      const auditorFilteredData = auditors.data.data.filter(
        (user: any) => user.email.toLocaleLowerCase() === myEmail?.toLocaleLowerCase()
      );
      if (filteredData.length > 0)
        setMySourceId(filteredData[0].sourceId);
      else if (auditorFilteredData.length > 0)
        setMySourceId(auditorFilteredData[0].sourceId);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  const fetchAllUsersData = async () => {
    try {
      const response = await get(`participant/class/${classId}/${tenantName}`);
      setDataLoading(false);
      const filteredData = response.data.filter(
        (user: any) => user.email.toLowerCase() !== myEmail
      );
      const modifiedData = filteredData.map((user: any) => ({
        ...user,
        id: user.sourceId,
        username: user.username,
      }));

      // Filter the modifiedData based on the searchText
      const filteredModifiedData = modifiedData.filter((user: any) =>
        user.username.toLowerCase().includes(searchText.toLowerCase())
      );

      setUsers(filteredModifiedData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllUsersData();
  }, [searchText]);


  return (
    <AddChatLayout>
      <AddChatSearch>
        <SearchWithFilter
          withFilter={false}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder={t("dashboard.Search")}
        />
      </AddChatSearch>

      <AddChatRender>
        <>
          {dataLoading ? (
            <LightTypography
              text={`${t("general.loading")}...`}
              fontSize="0.75rem"
            />
          ) : (
            Users.map((val) => (
              <AddChatCell key={val.id} onClick={() => handleUserClick(val.id)}>
                <AddChatCellImg
                  src={val.profilePicture ? val.profilePicture : image}
                  style={{
                    border:
                      selectedUserToCreateChat === val.id
                        ? "2px solid #5DD3B3"
                        : "1px solid #ccc",
                    color:
                      selectedUserToCreateChat === val.id ? "#5DD3B3" : "#ccc",
                  }}
                />
                <MediumTypography
                  text={val.username || ""}
                  fontSize="0.875rem"
                  color={
                    selectedUserToCreateChat === val.id ? " #5DD3B3" : "#ccc"
                  }
                />
              </AddChatCell>
            ))
          )}
        </>
      </AddChatRender>
    </AddChatLayout>
  );
};
