import React from "react";
import { ButtonStyle } from "./Button.style";
import { TypographyRegularDescription } from "../fonts/Fonts";
import { ButtonInterface } from "./ButtonInterface";

export default function ButtonElement({
  text,
  iconPath: IconComponent,
  clickEvent,
  disabledButton,
}: ButtonInterface) {
  return (
    <ButtonStyle onClick={clickEvent} disabledButton={disabledButton}>
      <IconComponent />
      <TypographyRegularDescription
        fontSize="0.75rem"
        fontWeight="400"
        text={text}
      />
    </ButtonStyle>
  );
}
