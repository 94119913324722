import styled from "styled-components";

export const UnstructuredCardLayout = styled.div<{ bgColor: string }>`
  padding: 1.19rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 20.25rem;
  height: 10.2625rem;
  flex-shrink: 0;
  border-radius: 1.25rem;
  opacity: 0.6;
  background:${props => props.bgColor};
`;

export const SubjectCardHolder = styled.div`
  background-color: #000;
  width: max-content;
  height: 1.875rem;
  border-radius: 0.625rem;
  background: #000;
  display: flex;
  color:white;
  align-items: center;
  padding: 0.88rem;
  padding-left: 0.5rem;
  margin-left: -2rem;
  margin-top: -1rem;
`;

export const ActionsCardHolder = styled.div`
  display: flex;
  margin-top: 0.12rem;
  width: 100% !important;
  justify-content: space-between;
  /* align-items:center; */

`;

export const UnstructuredButton = styled.div<{ color: string }>`
color:${props => props.color};
  display: flex;
  /* fill: #384450; */
  width: 1.875rem;
  height: 1.875rem;
  flex-shrink: 0;
  stroke-width: 0.5px;
  /* stroke: #2f3b48; */
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;

export const ActiveUnstructuredButton = styled.div<{ color: string, hoveredColor: string, bgHoveredColor: string, bgColor: string }>`
  cursor: pointer; box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
  border: 0px none;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  color:${props => props.color};
  background-color:${props => props.bgColor};
  /* background-color: #384450; */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.7s ease-in-out;

  &:hover {
    color:${props => props.hoveredColor};
    background-color:${props => props.bgHoveredColor};
    /* color: #384450;
    background-color: #fff; */
  }
`;
