import React, { FC, useEffect, useState } from "react";
import {
  DetailsContainer,
  Enrollement,
  DetailsLine,
  Class,
  Subject,
  OSA,
  Cancel,
} from "./ToDoDetailsHeader.style";
import { ToDoDetailsHeaderProps } from "./ToDoDetailsHeaderInterfaceInterface";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../../utils/StringManipulation";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";

const ToDoDetailsHeader: FC<ToDoDetailsHeaderProps> = ({
  filterTasksByCategory,
  selectedCategory,
}) => {
  const [EnrollmentData, setEnrollmentData] = useState<any[]>([]);
  const { user } = useAuth0();
  const tenantName = localStorage.getItem("tenant");
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const { bghelp } = useRecoilValue(ColorSelector);
  const getEnrollmentData = async () => {
    try {
      const response = await axios.get(
        `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/enrollments/byUser/all/${tenantName}/${subIdSplitted}?provider=${provider}`
      );
      // Extracting only id and title keys from each object in the array
      const formattedData = response.data.data.map((enrollment: any) => ({
        id: enrollment.id,
        name: enrollment.title, // Assuming 'name' is meant to be 'title'
      }));
      setEnrollmentData(formattedData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getEnrollmentData();
  }, []);

  // Push the selected category in the array
  // const handleClick = (category: string) => {
  //   const updatedCategories = [...selectedCategory];

  //   // Check if the category is already in selectedCategory
  //   const categoryIndex = updatedCategories.indexOf(category);

  //   if (categoryIndex === -1) {
  //     // If the category is not already selected, select it
  //     updatedCategories.push(category);
  //   }

  //   filterTasksByCategory(updatedCategories);
  // };

  // remove all the other categories and push the selected one
  const handleClick = (category: string) => {
    // Ensure the array contains only the selected category
    const updatedCategories = [category];

    console.log("Updated categories --->", updatedCategories);
    filterTasksByCategory(updatedCategories);
};

  // Mapping enrollmentData to generate components dynamically
  const renderEnrollmentComponents = () => {
    return EnrollmentData.map((enrollment: any, index: any) => {
      // Determine the type of component based on the index
      switch (index % 4) {
        case 0:
          return (
            <Enrollement
              key={enrollment.id}
              onClick={() => handleClick(enrollment.name)}
            >
              {enrollment.name}
            </Enrollement>
          );
        case 1:
          return (
            <Class
              key={enrollment.id}
              onClick={() => handleClick(enrollment.name)}
            >
              {enrollment.name}
            </Class>
          );
        case 2:
          return (
            <Subject
              key={enrollment.id}
              onClick={() => handleClick(enrollment.name)}
            >
              {enrollment.name}
            </Subject>
          );
        case 3:
          return (
            <OSA
              key={enrollment.id}
              onClick={() => handleClick(enrollment.name)}
            >
              {enrollment.name}
            </OSA>
          );
        default:
          return null;
      }
    });
  };

  return (
    <DetailsContainer style={{ color: "#fff" }}>
      <DetailsLine style={{ background: bghelp }} />
      {renderEnrollmentComponents()}
    </DetailsContainer>
  );
};

export default ToDoDetailsHeader;
