import { CompetencyFrameworksInterface } from "./CompetencyFrameworksInterface";

export const CompetencyFrameworksData : CompetencyFrameworksInterface[] = [
{
  stdCode: "dcfghyjuhgf",
  studentName: "Lana",
  competencies:[
    {
      CompetencyId:"sdefrgtyhuytfrdsd",
      CompetencyName:"BUSINESS AWARENESS",
      CompetencyDescription: "Knows what we do and how we do it. Is aware of our competitors. Up-to-date with general business news. Aware of developments in the sector. Thinks about implications",
      Activities: [
        {activityName:"Assignment" ,coverage:100,ProficiencyLevel:"Advanced",Role:"Grade Scale",PoorRange:"0 - 44 %",IntermediateRange:"45 - 70 %",AdvanceRange:"71- 100 %"},
        {activityName:"Quiz" ,coverage:55,ProficiencyLevel:"Intermediate",Role:"Grade Scale",PoorRange:"0 - 44 %",IntermediateRange:"45 - 70 %",AdvanceRange:"71- 100 %"},
        {activityName:"Forum" ,coverage:100,ProficiencyLevel:"Advanced",Role:"Submitted"},
      
      ],
      StudentsVisibility:true,
    },
    {CompetencyId:"sdfghyju7ytfrd",
      CompetencyName:"CUSTOMER ORIENTATION",
      CompetencyDescription: "Identifies and prioritises customer needs and recognises constraints. Seeks to find out more about customers and provide a better service",
      Activities: [
        {activityName:"Assignment" ,coverage:59,ProficiencyLevel:"Intermediate",Role:"Submitted"},
        {activityName:"Quiz" ,coverage:30,ProficiencyLevel:"Poor",Role:"Submitted"},
      
      ],
    },
    {CompetencyId:"fghyui9o8i7uy6trfgreg",
      CompetencyName:"ANALYSIS/PROBLEM SOLVING",
      CompetencyDescription: "Able to identify and separate out the key components of problems and situations. Able to manipulate and interpret information from a range of sources",
      Activities: [
        {activityName:"Assignment1" ,coverage:59,ProficiencyLevel:"Intermediate",Role:"Grade Scale",PoorRange:"0 - 44 %",IntermediateRange:"45 - 70 %",AdvanceRange:"71- 100 %"},
        {activityName:"Quiz" ,coverage:70,ProficiencyLevel:"Advanced",Role:"Grade Scale",PoorRange:"0 - 44 %",IntermediateRange:"45 - 70 %",AdvanceRange:"71- 100 %"},
      
      ],
      StudentsVisibility:true,
    },
    {CompetencyId:"sfghytiyutyergtrwegtr",
      CompetencyName:"QUICK THINKING",
      CompetencyDescription: "Able to pick up and assimilate relevant information quickly and easily",
      Activities: [
        {activityName:"Assignment34" ,coverage:52,ProficiencyLevel:"Intermediate",Role:"Grade Scale",PoorRange:"0 - 44 %",IntermediateRange:"45 - 70 %",AdvanceRange:"71- 100 %"},
        {activityName:"Quiz" ,coverage:57,ProficiencyLevel:"Intermediate",Role:"Submitted"},
        {activityName:"Forum" ,coverage:"",ProficiencyLevel:"",Role:"Submitted"},
      
      ],
      StudentsVisibility:true,
    },
  
    {CompetencyId:"defrgtyui876yhg",
      CompetencyName:"ANALYSIS/PROBLEM SOLVING",
      CompetencyDescription: "Able to identify and separate out the key components of problems and situations. Able to manipulate and interpret information from a range of sources",
      Activities: [
        {activityName:"Assignment 5" ,coverage:60,ProficiencyLevel:"Intermediate",Role:"Submitted"},
        {activityName:"Quiz" ,coverage:59,ProficiencyLevel:"Intermediate",Role:"Grade Scale",PoorRange:"0 - 44 %",IntermediateRange:"45 - 70 %",AdvanceRange:"71- 100 %"},
      
      ],
      StudentsVisibility:false,
    },
    {CompetencyId:"sdefrgadsfgrfhgfhyhuytfrdsd",
      CompetencyName:"TEAM WORK",
      CompetencyDescription: "Actively participates in team. Encourages co-operation. Aware of the needs of others and responds flexibly",
      Activities: [
        {activityName:"Assignment 5" ,coverage:40,ProficiencyLevel:"Poor",Role:"Submitted"},
        {activityName:"Quiz" ,coverage:35,ProficiencyLevel:"Poor",Role:"Grade Scale",PoorRange:"0 - 44 %",IntermediateRange:"45 - 70 %",AdvanceRange:"71- 100 %"},
      
      ],
      StudentsVisibility:false,
    },

  ]
},
{
  stdCode: "SDFGTHYJUYHGTFD",
  studentName: "Tush Lamba",
  competencies:[

    {CompetencyId:"dfghuiutyrgfdsfasfdadasd",
      CompetencyName:"CUSTOMER ORIENTATION",
      CompetencyDescription: "Identifies and prioritises customer needs and recognises constraints. Seeks to find out more about customers and provide a better service",
      Activities: [
        {activityName:"Assignment" ,coverage:59,ProficiencyLevel:"Intermediate"},
        {activityName:"Quiz" ,coverage:70,ProficiencyLevel:"Intermediate"},
      
      ],
    },
  
  
    {CompetencyId:"sdghjujt5res",
      CompetencyName:"ANALYSIS/PROBLEM SOLVING",
      CompetencyDescription: "Able to identify and separate out the key components of problems and situations. Able to manipulate and interpret information from a range of sources",
      Activities: [
        {activityName:"Assignment 5" ,coverage:60,ProficiencyLevel:"Intermediate"},
        {activityName:"Quiz" ,coverage:59,ProficiencyLevel:"Intermediate"},
      
      ],
    },
    {CompetencyId:"gi9o8iu7y6treds",
      CompetencyName:"TEAM WORK",
      CompetencyDescription: "Actively participates in team. Encourages co-operation. Aware of the needs of others and responds flexibly",
      Activities: [
        {activityName:"Assignment 5" ,coverage:40,ProficiencyLevel:"Poor"},
        {activityName:"Quiz" ,coverage:35,ProficiencyLevel:"Poor"},
      
      ],
    },

  ]
},
{
  stdCode: "sdfghjfcd",
  studentName: "Austin baby",
  competencies:[
    {CompetencyId:"ghyui7uy6tgfr",
      CompetencyName:"BUSINESS AWARENESS",
      CompetencyDescription: "Knows what we do and how we do it. Is aware of our competitors. Up-to-date with general business news. Aware of developments in the sector. Thinks about implications",
      Activities: [
        {activityName:"Assignment" ,coverage:100,ProficiencyLevel:"Advanced"},
        {activityName:"Quiz" ,coverage:100,ProficiencyLevel:"Advanced"},
        {activityName:"Forum" ,coverage:100,ProficiencyLevel:"Advanced"},
      
      ],
      StudentsVisibility:true,
    },
    {CompetencyId:"ghyu7y6t5re",
      CompetencyName:"CUSTOMER ORIENTATION",
      CompetencyDescription: "Identifies and prioritises customer needs and recognises constraints. Seeks to find out more about customers and provide a better service",
      Activities: [
        {activityName:"Assignment" ,coverage:59,ProficiencyLevel:"Intermediate"},
        {activityName:"Quiz" ,coverage:70,ProficiencyLevel:"Intermediate"},
      
      ],
      StudentsVisibility:false,
    },
    {CompetencyId:"ewetytiyujhdfgds",
      CompetencyName:"ANALYSIS/PROBLEM SOLVING",
      CompetencyDescription: "Able to identify and separate out the key components of problems and situations. Able to manipulate and interpret information from a range of sources",
      Activities: [
        {activityName:"Assignment1" ,coverage:59,ProficiencyLevel:"Intermediate"},
        {activityName:"Quiz" ,coverage:70,ProficiencyLevel:"Intermediate"},
      
      ],
      StudentsVisibility:true,
    },
    {CompetencyId:"cdsfgertu5refwede",
      CompetencyName:"QUICK THINKING",
      CompetencyDescription: "Able to pick up and assimilate relevant information quickly and easily",
      Activities: [
        {activityName:"Assignment34" ,coverage:52,ProficiencyLevel:"Intermediate"},
        {activityName:"Quiz" ,coverage:57,ProficiencyLevel:"Intermediate"},
        {activityName:"Forum" ,coverage:"",ProficiencyLevel:""},
      
      ],
      StudentsVisibility:true,
    },
    {CompetencyId:"fgtutredsadsafdsa",
      CompetencyName:"CUSTOMER ORIENTATION",
      CompetencyDescription: "Identifies and prioritises customer needs and recognises constraints. Seeks to find out more about customers and provide a better service",
      Activities: [
        {activityName:"Assignment2" ,coverage:80,ProficiencyLevel:"Advanced"},
        {activityName:"Quiz" ,coverage:75,ProficiencyLevel:"Advanced"},
      
      ],
      StudentsVisibility:true,
    },
    {CompetencyId:"435tgfsvxcvxczxz",
      CompetencyName:"ANALYSIS/PROBLEM SOLVING",
      CompetencyDescription: "Able to identify and separate out the key components of problems and situations. Able to manipulate and interpret information from a range of sources",
      Activities: [
        {activityName:"Assignment 4" ,coverage:70,ProficiencyLevel:"Advanced"},

      
      ],
      StudentsVisibility:true,
    },
    {CompetencyId:"67ygvdvsdvsd",
      CompetencyName:"ANALYSIS/PROBLEM SOLVING",
      CompetencyDescription: "Able to identify and separate out the key components of problems and situations. Able to manipulate and interpret information from a range of sources",
      Activities: [
        {activityName:"Assignment 5" ,coverage:60,ProficiencyLevel:"Intermediate"},
        {activityName:"Quiz" ,coverage:59,ProficiencyLevel:"Intermediate"},
      
      ],StudentsVisibility:true,
    },
    {CompetencyId:"egrhjuyjhgvfcdx",
      CompetencyName:"TEAM WORK",
      CompetencyDescription: "Actively participates in team. Encourages co-operation. Aware of the needs of others and responds flexibly",
      Activities: [
        {activityName:"Assignment 5" ,coverage:40,ProficiencyLevel:"Poor"},
        {activityName:"Quiz" ,coverage:35,ProficiencyLevel:"Poor"},
      
      ],StudentsVisibility:false,
    },

  ]
},
{
  stdCode: "sdfghjnhbgfcdgtrg",
  studentName: "Sishy Lishy",
  competencies:[
    {CompetencyId:"fghjuhygtfrdes",
      CompetencyName:"BUSINESS AWARENESS",
      CompetencyDescription: "Knows what we do and how we do it. Is aware of our competitors. Up-to-date with general business news. Aware of developments in the sector. Thinks about implications",
      Activities: [
        {activityName:"Assignment" ,coverage:100,ProficiencyLevel:"Advanced"},
        {activityName:"Quiz" ,coverage:100,ProficiencyLevel:"Advanced"},
        {activityName:"Forum" ,coverage:100,ProficiencyLevel:"Advanced"},
      
      ],
      StudentsVisibility:true,
    },
    {CompetencyId:"gthyjuykikujhgfd",
      CompetencyName:"CUSTOMER ORIENTATION",
      CompetencyDescription: "Identifies and prioritises customer needs and recognises constraints. Seeks to find out more about customers and provide a better service",
      Activities: [
        {activityName:"Assignment" ,coverage:59,ProficiencyLevel:"Intermediate"},
        {activityName:"Quiz" ,coverage:70,ProficiencyLevel:"Intermediate"},
      
      ],StudentsVisibility:true,
    },
    {CompetencyId:"sdgghkjhtgrfd",
      CompetencyName:"ANALYSIS/PROBLEM SOLVING",
      CompetencyDescription: "Able to identify and separate out the key components of problems and situations. Able to manipulate and interpret information from a range of sources",
      Activities: [
        {activityName:"Assignment1" ,coverage:59,ProficiencyLevel:"Intermediate"},
        {activityName:"Quiz" ,coverage:70,ProficiencyLevel:"Intermediate"},
      
      ],StudentsVisibility:true,
    },
    {CompetencyId:"hytuytreswfgthgf",
      CompetencyName:"QUICK THINKING",
      CompetencyDescription: "Able to pick up and assimilate relevant information quickly and easily",
      Activities: [
        {activityName:"Assignment34" ,coverage:52,ProficiencyLevel:"Intermediate"},
        {activityName:"Quiz" ,coverage:57,ProficiencyLevel:"Intermediate"},
        {activityName:"Forum" ,coverage:"",ProficiencyLevel:""},
      
      ],StudentsVisibility:false,
    },
    {CompetencyId:"dcfvgbnhjmkiujhygtfd",
      CompetencyName:"CUSTOMER ORIENTATION",
      CompetencyDescription: "Identifies and prioritises customer needs and recognises constraints. Seeks to find out more about customers and provide a better service",
      Activities: [
        {activityName:"Assignment2" ,coverage:80,ProficiencyLevel:"Advanced"},
        {activityName:"Quiz" ,coverage:75,ProficiencyLevel:"Advanced"},
      
      ],StudentsVisibility:false,
    },
    {CompetencyId:"dfghjkiuytreds",
      CompetencyName:"ANALYSIS/PROBLEM SOLVING",
      CompetencyDescription: "Able to identify and separate out the key components of problems and situations. Able to manipulate and interpret information from a range of sources",
      Activities: [
        {activityName:"Assignment 4" ,coverage:70,ProficiencyLevel:"Advanced"},

      
      ],StudentsVisibility:true,
    },
    {CompetencyId:"sdfvghjkijhgfd",
      CompetencyName:"ANALYSIS/PROBLEM SOLVING",
      CompetencyDescription: "Able to identify and separate out the key components of problems and situations. Able to manipulate and interpret information from a range of sources",
      Activities: [
        {activityName:"Assignment 5" ,coverage:60,ProficiencyLevel:"Intermediate"},
        {activityName:"Quiz" ,coverage:59,ProficiencyLevel:"Intermediate"},
      
      ],StudentsVisibility:false,
    },
    {CompetencyId:"sfghjhgtfdsazvbghju7yt",
      CompetencyName:"TEAM WORK",
      CompetencyDescription: "Actively participates in team. Encourages co-operation. Aware of the needs of others and responds flexibly",
      Activities: [
        {activityName:"Assignment 5" ,coverage:40,ProficiencyLevel:"Poor"},
        {activityName:"Quiz" ,coverage:35,ProficiencyLevel:"Poor"},
      
      ],StudentsVisibility:true,
    },

  ]
}
 ];