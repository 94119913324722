import { FC, useRef, useState, useEffect } from "react";
import {
  MenuWrapper,
  Frame,
  TextWithIconWrapper,
} from "./DropDownEditDelete.style";
import { DropDownDataInterface } from "../dropDownDataNew";
import { PrimaryFontMediumText } from "../../../elements/fonts/Fonts";

import { useRecoilValue } from "recoil";
import { courseCreatorColorSelector } from "../../../recoil/ThemeSelectors";

import { useStateContext } from "../../../contexts/ContextProvider";
import { useTranslation } from "react-i18next";
interface DropDownEditDeleteProps {
  groupIndex: number;
  setShowDeleteGroup: (showDeleteGroup: boolean) => void;
  setShowEditGroup: (showEditGroup: boolean) => void;
  setShowDropDown: (showDropDown: boolean) => void;
}

const DropDownEditDeleteGroup: FC<DropDownEditDeleteProps> = ({
  groupIndex,
  setShowDeleteGroup,
  setShowEditGroup,
  setShowDropDown,
}) => {
  const {} = useStateContext();
  // Recoil state and selectors
  const { tooltipBgColor, tooltipTextColor, boxShadow } = useRecoilValue(
    courseCreatorColorSelector
  );
  const { t } = useTranslation();
  return (
    <MenuWrapper
      bgColor={tooltipBgColor}
      boxShadow={boxShadow}
      groupindex={groupIndex}
    >
      {DropDownDataInterface.map((item: any, index: number) => (
        <Frame
          textColor={tooltipTextColor}
          key={index}
          style={{
            borderRadius: `${
              index === 0
                ? "0.3125rem 0.3125rem 0rem 0rem"
                : index === DropDownDataInterface.length - 1
                ? " 0rem 0rem 0.3125rem 0.3125rem"
                : ""
            }`,
          }}
        >
          <TextWithIconWrapper
            onClick={() => {
              if (index === 1) {
                setShowDeleteGroup(true);
              } else if (index === 0) {
                setShowEditGroup(true);
                setShowDropDown(false);
              }
            }}
          >
            <item.icon />
            <PrimaryFontMediumText text={t(item.name)} fontSize=" 0.875rem" />
          </TextWithIconWrapper>
        </Frame>
      ))}
    </MenuWrapper>
  );
};

export default DropDownEditDeleteGroup;
