export const toArabicDigits = (str: string) => {
  const language = localStorage.getItem("language");
  const englishToArabic: { [key: string]: string } = {
    "0": "٠",
    "1": "١",
    "2": "٢",
    "3": "٣",
    "4": "٤",
    "5": "٥",
    "6": "٦",
    "7": "٧",
    "8": "٨",
    "9": "٩",
  };
  if (str === undefined || str === null) return "";
  else
    return language === "ar"
      ? str.replace(/\d/g, (digit) => englishToArabic[digit])
      : str;
};

export const toArabicDaysLetter = (str: string) => {
  const language = localStorage.getItem("language");
  const englishToArabic: { [key: string]: string } = {
    M: "إثنـ",
    T: "ثلثـ",
    W: "أربـ",
    TH: "خميـ",
    F: "جمـ",
    S: "سبـ",
    SU: "أحـ",
  };

  return language === "ar" ? englishToArabic[str] : str;
};

export const toArabicLetter = (str: string) => {
  const language = localStorage.getItem("language");
  const englishToArabic: { [key: string]: string } = {
    A: "أ",
    B: "ب",
    C: "ج",
    D: "د",
    E: "هـ",
    F: "و",
    G: "ز",
    H: "ح",
    I: "ط",
    J: "ي",
    K: "ك",
    L: "ل",
    M: "م",
    N: "ن",
    O: "س",
    P: "ع",
    Q: "ف",
    R: "ص",
    S: "ق",
    T: "ر",
    U: "ش",
    V: "ت",
    W: "ث",
    X: "خ",
    Y: "ذ",
    Z: "ض",
  };

  return language === "ar" ? englishToArabic[str] : str;
};

  export const getArabicHourLabel = (hours:any) => {
    const language = localStorage.getItem("language")
    if(language === "ar"){
    const remainder = hours % 100;
    if (remainder > 1 && remainder < 11) {
      return "ساعات";
    } else {
      return "ساعة";
    }
  }
  }


export const formatDayContents = (day: number) => {
  return toArabicDigits(day.toString());
};
