import styled from 'styled-components'
import { theme } from '../../../theme';
import  CheckIcon  from '../../../assets/icons/Group2199.svg';
export const EssayQuestionContainer=styled.div`
display:flex;
flex-direction:column;
gap:1.25rem;
padding: 0 1.5rem 0 1.5rem;
`;



export const CheckBox = styled.input`

  width: 1rem;
  height: 1rem;  
  background: linear-gradient(180deg, #2D3741 0%, #576371 100%),
linear-gradient(0deg, #FFFFFF, #FFFFFF);

  appearance: none;
  border: 1px solid ${theme.dark.primary_text_color};


  transition: background-color 0.3s ease;

  &:checked {

background-image: url(${CheckIcon}); /* Use the CheckIcon component as a URL */
    background-size: cover; /* Adjust this as needed */
    background-repeat: no-repeat;
    background-position: center;
    border:none;
    
  }
`;
export const EnableAttachmentContainer=styled.div`
display: flex;
align-items:center;
gap:0.62rem;
margin-bottom:2.25rem;
`;