import React from "react";
import {
  ResourceBottomHolderNoB,
  ResourceHolderStyle,
  ResourceUpperHolderNoB,
} from "./ResourceCard.style";
import { htmlDecoder } from "../../../../utils/StringManipulation";
import { Typography } from "../../../../elements/fonts/Fonts";
import { Link } from "react-router-dom";
import { SvgDotsIcon } from "../../../../elements/Icons";
import { getFileName } from "./getFileName";
import { ResourceProps } from "../../dashboardContentInterfaces";
import { useRecoilValue } from "recoil";
import { CourseColorSelector } from "../../../../recoil/ThemeSelectors";

export default function ResourceHtmlComponent({
  resourceData,
  hovered,
  allResources,
  courseId,
  resourceR,
}: ResourceProps) {
  const { ContentDocBgColor, theme } = useRecoilValue(CourseColorSelector);
  return (
    <ResourceHolderStyle hovered={hovered} allResources={allResources} style={{ background: allResources ? theme === "light" ? "#F7F7F8" : "linear-gradient(90deg, #415160 0%, #293440 107.88%)" : ContentDocBgColor }}>
      <ResourceUpperHolderNoB>
        <div
          dangerouslySetInnerHTML={{
            __html: `${allResources
              ? htmlDecoder(resourceData.description).length >
                (hovered ? 500 : 350)
                ? `${htmlDecoder(resourceData.description).slice(
                  0,
                  hovered ? 500 : 350
                )} ...`
                : htmlDecoder(resourceData.description)
              : htmlDecoder(resourceData.description).length >
                (hovered ? 322 : 250)
                ? `${htmlDecoder(resourceData.description).slice(
                  0,
                  hovered ? 322 : 250
                )} ...`
                : htmlDecoder(resourceData.description)
              }`,
          }}
          style={{
            paddingTop: hovered ? "0.5rem" : "0",
            fontSize: "0.75rem",
            alignSelf: "left",
            height: "6rem",
            paddingRight: "1rem",
          }}
        />
      </ResourceUpperHolderNoB>
      <ResourceBottomHolderNoB hovered={hovered}>
        <Typography
          text={getFileName(resourceData?.path || "") || resourceData.url}
          fontSize="0.75rem"
        />
        <Link
          to={`/courseView/content/${courseId}`}
          state={{
            activity: resourceR,
            moduleIndex: resourceR.moduleIndex,
            topicIndex: resourceR.topicIndex,
            contentIndex: resourceR.contentIndex,
            contentId: resourceR.contentId,
            isVisible: resourceR.isVisible
          }}
        >
          <div
            style={{
              transform: "rotate(90deg)",
              height: "2REM",
              padding: "0.25rem",
              cursor: "pointer",
              marginRight: "-0.7rem",
            }}
          >
            <SvgDotsIcon color="#5dd3b3" width={"1rem"} />
          </div>
        </Link>
      </ResourceBottomHolderNoB>
    </ResourceHolderStyle>
  );
}
