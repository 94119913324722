import React from "react";

import { NotifcationSting } from "../../../components/ProfileCheckBoxOptions/NotifcationSting";
import MenuItems from "../../../components/ProfileMenueSetting/MenuItems";
import { ProfilesMainRow } from "../ProfileShared.style";
import InstructorInformationComp from "../../../components/InstructorInformationComp/InstructorInformationComp";
import MenuItemsV2 from "../../../components/ProfileMenueSetting/MenueItemsV2";

export const InstructorInformation = (): JSX.Element => {
  return (
    <>
      <MenuItemsV2 childContent={<InstructorInformationComp />} />
    </>
  );
};
