import React, { useCallback } from "react";
import DragAndDropZone from "./main/dropzone";
import { Typography } from "../../elements/fonts/Fonts";

interface Prop {
  text: string;
  testDrop?: (acceptedFiles: File[]) => void;
  acceptedTypes?: { [key: string]: string[] };
  initialFiles?: string[] | undefined;
  onRemoveFile?: () => void;
  isDisabled?:boolean
}
export const DropZoneForm = ({
  text,
  testDrop,
  acceptedTypes,
  initialFiles,
  onRemoveFile,
  isDisabled
}: Prop): JSX.Element => {
  const handleDrop = useCallback((acceptedFiles: File[]) => {
    if (testDrop) {
      testDrop(acceptedFiles);
    }
  }, []);
  const handleFileRemove = useCallback(() => {
  
    if (onRemoveFile) {
      onRemoveFile();
    }
  }, [onRemoveFile]);
  return (
    <div>
      <DragAndDropZone
        onDrop={handleDrop}
        text={text}
        acceptedTypes={acceptedTypes}
        onRemoveFile={handleFileRemove}
        isDisabled={isDisabled}
      />
    </div>
  );
};
