import React from "react";
import { FlexAppContainer } from "../../../../App.style";
import AnalyticsBackPage from "../../../../components/AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticsBackPage";
import { Typography } from "../../../../elements/fonts/Fonts";
import ScrumDefaultTable from "../../../../components/Tables/ScormDefaultTable/ScormDefaultTable";
import { useNavigate } from "react-router-dom";

const AnalyticsScormDefaultTable = () => {
  const navigate = useNavigate();
  const NavigatePage = () => {
    navigate(-1);
  };
  return (
    <FlexAppContainer column={true} padding="0 0 2rem 0" gap="1.25rem">
      <AnalyticsBackPage onClick={NavigatePage} />
      <Typography text="Scorm Analytics" fontSize="1.75rem" />
      <ScrumDefaultTable />
    </FlexAppContainer>
  );
};

export default AnalyticsScormDefaultTable;
