import React, { FC, useState } from "react";
import useClickOutside from "../../../hooks/useClickOutside";
import {
  QuesionContainer,
  QuestionMenuContainer,
  QuestionComposedQuestion,
  DropdownMenu,
  MenuContainer,
  MenuElementContainer,
  MenuItem,
  PartContainer,
} from "./QuestionHeader.style";

import { ReactComponent as QuestionIcon } from "../../../assets/icons/Drag and Drop.svg";

import { ReactComponent as UpIcon } from "../../../assets/icons/Arrow-up-1.svg";
// import { ReactComponent as AddPartIcon } from "../../../assets/icons/Add (1) copy.svg";
import { MediumTypography } from "../../../elements/fonts/Fonts";
import { QuestionHeaderProps } from "./QuestionHeaderInterface";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import {
  SVGMenue,
  SvgAddModuleIcon,
  SvgArrowDownIcon,
  SvgDelete,
  SvgEye,
} from "../../../elements/Icons";
const QuestionHeader: FC<QuestionHeaderProps> = ({
  setIndex,
  setShowParts,
  question,
  setDeleteQuestion,
  addMorePartQuestions,
  index,
  showParts,
  handleShowQuest,
  disableClick,
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const menuRef = useClickOutside(menuOpen, () => setMenuOpen(false)); // Use the custom hook to handle clicks outside the menu

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const { t } = useTranslation();
  const { bg_selectColor } = useRecoilValue(ColorSelector);
  return (
    <QuestionComposedQuestion>
      <QuestionMenuContainer>
        <QuesionContainer
          onClick={() => {
            setIndex(index);
            handleShowQuest();
          }}
        >
          <QuestionIcon />
          <MediumTypography
            text={`${t("quiz.Question")} ${index + 1}`}
            fontSize="0.875rem"
            lineHeight="1.375rem"
            // color={theme.dark.primary_text_color}
          />
        </QuesionContainer>

        <PartContainer>
          <div
            style={{ position: "relative" }}
            ref={menuRef}
            onClick={toggleMenu}
          >
            {!disableClick && (
              <SVGMenue
                style={{
                  cursor: "pointer",
                  marginBottom: "0.1rem",
                  transform: "rotate(90deg)",
                  // rotate: "transform(90deg)",
                }}
              />
            )}

            <MenuContainer>
              <DropdownMenu
                bgColor={bg_selectColor}
                isopen={menuOpen.toString()}
              >
                <MenuElementContainer
                  onClick={() => {
                    setIndex(index);
                    handleShowQuest();
                  }}
                >
                  <SvgEye />
                  <MenuItem>{t("assignment.View")}</MenuItem>
                </MenuElementContainer>
                <MenuElementContainer
                  onClick={() => {
                    setIndex(index);
                    setDeleteQuestion(true);
                  }}
                >
                  <SvgDelete />
                  <MenuItem>{t("User Profile.Remove")}</MenuItem>
                </MenuElementContainer>
              </DropdownMenu>
            </MenuContainer>
          </div>
          {question.questType === "composed" ? (
            <SvgAddModuleIcon
              onClick={() => {
                addMorePartQuestions();
                setShowParts(true);
              }}
              style={{ cursor: "pointer", marginBottom: "0.1rem" }}
            />
          ) : (
            ""
          )}
        </PartContainer>
      </QuestionMenuContainer>
      {question.questType === "composed" && showParts ? (
        <UpIcon
          style={{ cursor: "pointer" }}
          onClick={() => setShowParts(false)}
        />
      ) : question.questType === "composed" && !showParts ? (
        <SvgArrowDownIcon
          style={{ cursor: "pointer" }}
          onClick={() => setShowParts(true)}
        />
      ) : (
        ""
      )}
    </QuestionComposedQuestion>
  );
};

export default QuestionHeader;
