import React from "react";
import { Link } from "react-router-dom";
import { SvgLinkTreeD, SvgDotsIcon } from "../../../../elements/Icons";
import {
  Typography,
  TypographyRegularDescription,
} from "../../../../elements/fonts/Fonts";
import {
  ResourceHolderStyleL,
  ResourceLinkTreeD,
  ResourceLink,
  DotsStyle,
  ResourceLinkTree,
} from "./ResourceCard.style";
import { ResourceProps } from "../../dashboardContentInterfaces";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../../recoil/ThemeSelectors";

export default function ResourceLinkTreeComponent({
  resourceData,
  hovered,
  allResources,
  courseId,
  resourceR,
  lastIndex,
}: ResourceProps) {
  const { themeTextColor } = useRecoilValue(ColorSelector);
  return (
    <ResourceHolderStyleL
      hovered={hovered}
      allResources={allResources}
      lastIndex={lastIndex || false}
      // style={{ background: "red" }}
    >
      <Typography
        text={
          resourceData.linkTree.title.length > 28
            ? `${resourceData.linkTree.title.slice(0, 28)}..`
            : resourceData.linkTree.title
        }
        fontSize="0.875rem"
      />
      <ResourceLinkTreeD>
        {resourceData.result.length > 2 ? (
          <ResourceLinkTree>
            {resourceData.result
              .slice(0, hovered ? 4 : 2)
              .map((item: any, rowIndex: any) => (
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <ResourceLink
                    key={rowIndex}
                    hovered={hovered}
                    allResources={allResources}
                  >
                    <div style={{ marginBottom: "-0.2rem" }}>
                      <SvgLinkTreeD />{" "}
                    </div>
                    <TypographyRegularDescription
                      text={
                        allResources
                          ? hovered
                            ? `${item.title.slice(0, 12)}...`
                            : item.title
                          : hovered
                          ? `${item.title.slice(0, 9)}...`
                          : item.title
                      }
                      fontSize="0.75rem"
                    />
                  </ResourceLink>
                </a>
              ))}
          </ResourceLinkTree>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.63rem",
            }}
          >
            {resourceData.result.map((item: any, rowIndex: any) => (
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <ResourceLink
                  key={rowIndex}
                  hovered={false}
                  allResources={allResources}
                >
                  <div>
                    <SvgLinkTreeD color={themeTextColor} />
                  </div>
                  <TypographyRegularDescription
                    text={`${item.title.slice(0, 15)}...`}
                    fontSize="0.75rem"
                  />
                </ResourceLink>
              </a>
            ))}
          </div>
        )}
        <Link
          to={`/courseView/content/${courseId}`}
          state={{
            activity: resourceR,
            moduleIndex: resourceR.moduleIndex,
            topicIndex: resourceR.topicIndex,
            contentIndex: resourceR.contentIndex,
            contentId: resourceR.contentId,
            isVisible: resourceR.isVisible,
          }}
        >
          <DotsStyle
            style={{
              marginRight: resourceData.result.length <= 2 ? "1rem" : "0.5rem",
            }}
          >
            <SvgDotsIcon color="#5dd3b3" width={"1rem"} />
          </DotsStyle>
        </Link>
      </ResourceLinkTreeD>
    </ResourceHolderStyleL>
  );
}
