  // Calculate the Average of the Class For The Quiz
  export const calculateClassAverage = (studentData:any[],maximumGradeOfQuiz:number) => {
    if (!studentData || studentData.length === 0) {
        return { average: 0, minimumGrade: 0, maximumGrade: 0 }; // Return default values
      }
    // Initialize variables to store the total grade, minimum grade, maximum grade, and the count of submissions
    let totalFinalGrade = 0;
    let minimumFinalGrade = Number.MAX_VALUE; // Initialize to a very large value
    let maximumFinalGrade = 0; // Initialize to a very small value
    let submissionCount = 0;
    // Iterate through each student's submission
    studentData.forEach((studentSubmission) => {
      // Calculate the final grade for the student
      const finalGrade = studentSubmission.finalGrade;
      // Update minimum and maximum final grades
      minimumFinalGrade = Math.min(minimumFinalGrade, finalGrade);
      maximumFinalGrade = Math.max(maximumFinalGrade, finalGrade);
      // Add the final grade to the totalFinalGrade
      totalFinalGrade += finalGrade;
      // Increment the submission count
      submissionCount++;
    });
    // Calculate the average grade
    const classAverage = (totalFinalGrade / (submissionCount * maximumGradeOfQuiz)) * 100;
    const average = Number((classAverage).toFixed(0));
    const minimumGrade = Number((minimumFinalGrade).toFixed(0));
    const maximumGrade = Number((maximumFinalGrade).toFixed(0));
    return {
        average,
        minimumGrade,
        maximumGrade
       }
  };
  // End of Function

  // Filter the Questions array for the Quiz and return the Total Points of the template
  export const calculateTotalePoints = (questionsData:any) => {
    const processedQuestions = questionsData.data.questions.map( // Formatting the Question Array
        (question: any) => {
          if (question.type === "COMPOSITE") {
            const compositeParts = question.questionParts.map((part: any) => {
              const modifiedTitle = `${question.title} ${part.title}`;
              return { ...part, title: modifiedTitle };
            });
            return [...compositeParts];
          } else {
            return [question];
          }
        }
      );
      const flattenedQuestions = processedQuestions.flat();
      const totalPoint = flattenedQuestions.reduce( // Get The Total Points of the Questions Template
        (accumulator: any, question: any) => {
          return accumulator + (question.points || 0);
        },
        0
      );
      return totalPoint
  }
  // End Of Function

  // Format Date
  export const getFormattedDate = (createdAt: string) => {
    const date = new Date(createdAt);
    const language = localStorage.getItem("language")
    
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    };
    
    const formatter = new Intl.DateTimeFormat(`${language === "tr" ? "tr-TR" : "us-US"}`, options);
    const parts = formatter.formatToParts(date);
    
    const dayName = parts.find(part => part.type === 'weekday')?.value;
    const day = parts.find(part => part.type === 'day')?.value;
    const monthName = parts.find(part => part.type === 'month')?.value;
    const year = parts.find(part => part.type === 'year')?.value;
    const hour = parts.find(part => part.type === 'hour')?.value;
    const minute = parts.find(part => part.type === 'minute')?.value;
    const second = parts.find(part => part.type === 'second')?.value;
    const dayPeriod = parts.find(part => part.type === 'dayPeriod')?.value;
    
    const formattedDate = `${dayName} ${day} ${monthName} ${year}, ${language === "en" ? "at" : "saat"} ${hour}:${minute}:${second} ${dayPeriod}`;
    return formattedDate;
  };
  // End Of Function

  // Calculate the Grades for the Forum
  export const calculateForumGrades = (AssignmentsData:any[],forum:any) => {
    const gradedSubmissions = AssignmentsData.filter(
        (submission: any) => submission.grade.length > 0
      );

      // Calculate the minimum grade
      const minGrade = Math.min(
        ...gradedSubmissions.map((submission: any) => submission.grade[0].grade)
      );
      const minGradeValue = isFinite(minGrade) ? minGrade : 0;

      // Calculate the maximum grade
      const maxGrade = Math.max(
        ...gradedSubmissions.map((submission: any) => submission.grade[0].grade)
      );
      const maxGradeValue = isFinite(maxGrade) ? maxGrade : 0;

      // Calculate the average grade
      const sumOfGrades = gradedSubmissions.reduce(
        (acc: number, submission: any) => acc + submission.grade[0].grade,
        0
      );
      const avgGrade = gradedSubmissions.length > 0 && (forum.maxGrade !== 0 && forum.maxGrade !== "" ) ? (sumOfGrades / (gradedSubmissions.length * forum.maxGrade) * 100) : gradedSubmissions.length > 0 && (forum.maxGrade === 0 || forum.maxGrade === "") ? (sumOfGrades / (gradedSubmissions.length * 100) * 100)  : 0;
      const avgGradeValue = !isNaN(avgGrade)
        ? parseFloat(avgGrade.toFixed(2))
        : 0;

      // Count the number of graded submissions
      const countGraded = gradedSubmissions.length;

      // Return the state values
      return {
        minGradeValue,
        maxGradeValue,
        avgGradeValue,
        countGraded
      }
  }
  // End Of Function

  // Calculate The Grades For The Assignment
  export const calculateAssignmentGrades = (AssignmentsData:any[],maximumGradeOfAssignment:any) => {
    const usersArray = AssignmentsData.map(
        (submission: any) => submission.user
      );
      const gradedSubmissions = AssignmentsData.filter(
        (submission: any) => submission.graded === true
      );

      // Calculate the minimum grade
      const minGrade = Math.min(
        ...gradedSubmissions.map((submission: any) => submission.grade.grade)
      );
      const minGradeValue = isFinite(minGrade) ? minGrade : 0;

      // Calculate the maximum grade
      const maxGrade = Math.max(
        ...gradedSubmissions.map((submission: any) => submission.grade.grade)
      );
      const maxGradeValue = isFinite(maxGrade) ? maxGrade : 0;

      // Calculate the average grade
      const sumOfGrades = gradedSubmissions.reduce(
        (acc: number, submission: any) => acc + submission.grade.grade,
        0
      );
      const avgGrade = gradedSubmissions.length > 0 && maximumGradeOfAssignment !== 0 ? (sumOfGrades / (gradedSubmissions.length * maximumGradeOfAssignment) * 100) : gradedSubmissions.length > 0 && maximumGradeOfAssignment === 0 ? (sumOfGrades / (gradedSubmissions.length * 100) * 100)  : 0;
      const avgGradeValue = !isNaN(avgGrade)
        ? parseFloat(avgGrade.toFixed(2))
        : 0;

      // Count the number of graded submissions
      const countGraded = gradedSubmissions.length;

      // Return the state values

      return {
        usersArray,
        minGradeValue,
        maxGradeValue,
        avgGradeValue,
        countGraded
      }
  }
  // End Of Function