import styled from 'styled-components'
import { theme } from '../../../theme';
export const StatisticsContainer=styled.div`
display:flex;
justify-content:space-between;
gap:4rem;

`;

export const GradeContainer=styled.div`
display: flex;
flex-direction:column;
gap:0.62rem;
`;
export const Grade =styled.div`
display: flex;

align-items:center;

`;
export const SeparateLine=styled.div<{show:string}>`
&&{
  border-left: 1px solid #576371;
  height: 4.8125rem;
  ${({ show }) =>
    show==='false' &&
    `
      display: none;
    `}
}
`;
export const GradeNumber=styled.div`
 /* color: ${theme.dark.primary_text_color}; */
  font-family: 'Primary Font-Bold';
  font-size: 2.375rem;
  font-weight:400;
  position:relative;

  &::before {
    content: '';
    position: absolute;
    width: 1.625rem;
    height: 0.1875rem;
    background-color: #5DD3B3;
    top: 0;
  }
`;

export const OverGrade=styled.div`
margin-top:0.5rem;
`;