import React from "react";
import {
  StudentCompletionTracingCell,
  StudentCompletionTracingHeaderCell,
  StudentCompletionTracingIMG,
  StudentCompletionTracingLayout,
  StudentCompletionTracingRow,
} from "./StudentCompletionTracing.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../elements/fonts/Fonts";
interface StudentCompletionTracingInterface {
  StudentTracingData: any[];
}
export const StudentCompletionTracing = ({
  StudentTracingData,
}: StudentCompletionTracingInterface): JSX.Element => {
  return (
    <StudentCompletionTracingLayout>
      <div style={{ padding: "1.25rem 0 0 1.25rem" }}>
        <MediumTypography
          text="Student Course Completion tracking"
          fontSize="1.125rem"
          lineHeight="1.75rem"
        />
      </div>
      <StudentCompletionTracingRow>
        <StudentCompletionTracingHeaderCell>
          <MediumTypography
            text="Student"
            fontSize="1rem"
            lineHeight="1.75rem"
          />
        </StudentCompletionTracingHeaderCell>
        <StudentCompletionTracingHeaderCell>
          <MediumTypography
            text="Time Spent"
            fontSize="1rem"
            lineHeight="1.75rem"
          />
        </StudentCompletionTracingHeaderCell>
        <StudentCompletionTracingHeaderCell>
          <MediumTypography
            text="Last Access"
            fontSize="1rem"
            lineHeight="1.75rem"
          />
        </StudentCompletionTracingHeaderCell>
        <StudentCompletionTracingHeaderCell>
          <MediumTypography text="Grade" fontSize="1rem" lineHeight="1.75rem" />
        </StudentCompletionTracingHeaderCell>
        <StudentCompletionTracingHeaderCell>
          <MediumTypography text="GPA" fontSize="1rem" lineHeight="1.75rem" />
        </StudentCompletionTracingHeaderCell>
      </StudentCompletionTracingRow>
      {StudentTracingData &&
        StudentTracingData.map((con, index) => (
          <StudentCompletionTracingRow key={index}>
            <StudentCompletionTracingCell>
              <StudentCompletionTracingIMG src={con.ProfilePic} alt="no img" />
              <Typography text={con.name} fontSize="0.875rem" />
            </StudentCompletionTracingCell>
            <StudentCompletionTracingCell>
              <LightTypography text={con.TimeSpent} fontSize="0.875rem" />{" "}
            </StudentCompletionTracingCell>
            <StudentCompletionTracingCell>
              <LightTypography text={con.LastAccess} fontSize="0.875rem" />
            </StudentCompletionTracingCell>
            <StudentCompletionTracingCell>
              <LightTypography text={con.Grade} fontSize="0.875rem" />
            </StudentCompletionTracingCell>
            <StudentCompletionTracingCell>
              <LightTypography text={con.GPA} fontSize="0.875rem" />
            </StudentCompletionTracingCell>
          </StudentCompletionTracingRow>
        ))}
    </StudentCompletionTracingLayout>
  );
};
