import React from "react";
import { NormalTextInput } from "../../App.style";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

interface TextInputInterface {
  type?: string;
  value?: string | number;
  onChange?(newValue: string): void;
  placeHolder?: string;
  borderRadius?: string;
  borderColor?: string;
  readOnly?: boolean;
  height?:string;
}

const TextInputElement = ({
  type = "text",
  value,
  placeHolder,
  onChange,
  borderRadius,
  borderColor,
  readOnly = false,
  height,
}: TextInputInterface) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };
  const { SecondaryTextColor, bghelp, theme } = useRecoilValue(ColorSelector);
  return (
    <NormalTextInput
    height={height}
      type={type}
      placeholder={placeHolder}
      value={value}
      onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
      borderColor={borderColor || bghelp}
      borderRadius={borderRadius}
      readOnly={readOnly}
      color={SecondaryTextColor}
    />
  );
};

export default TextInputElement;
