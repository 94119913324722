import React, { FC } from 'react';
import { Typography, MediumTypography } from '../../../../elements/fonts/Fonts';
import { theme } from '../../../../theme';
import { ReactComponent as DowloadIcon } from '../../../../assets/icons/Download.svg'
import { SubmittedContainer, TimeContainer, HiddenButton, Assignment,SubmittedFilesContainer } from './SubmittionSection.style';
import { SubmittionSectionProps } from './SubmittionSectionInterface';
import { useMyContext } from '../../../../contexts/surveyGraderContext';
import { useTranslation } from 'react-i18next';
const SubmittionSection: FC<SubmittionSectionProps> = ({showRating,showLate,student,studentIndex}) => {

    const {submissionDate} = useMyContext()
    const { t } = useTranslation();


    return (
        <div>
            {!showRating?
        <SubmittedContainer>
                <Typography text={t('assignment.SUBMITTED')} fontSize='1rem' color={`${theme.dark.main_color}`} />
                <TimeContainer>
                    {/* <MediumTypography text={`${student && student.length > 0 && getFormattedDate(student[studentIndex].createdAt)}`} fontSize='0.875rem' /> */}
                    <MediumTypography text={submissionDate} fontSize='0.875rem' />
                    {/* <HiddenButton>HIDDEN</HiddenButton> */}
                </TimeContainer>
            </SubmittedContainer>
            :
            ''
      }
      {/* {!showLate && student && student.length > 0 && student[studentIndex].fileName.trim() !== "" ?
            <SubmittedFilesContainer>
                <Typography text='SUBMITTED FILES' fontSize='1rem' color={`${theme.dark.main_color}`} />
                <Assignment>
                    <MediumTypography text='Assignment' fontSize='0.875rem' />
                    {student && student.length > 0 && 
                    <a href={student[studentIndex].submissionFile} download={student[studentIndex].submissionFile} target='_blank'>
                    <DowloadIcon />
                    </a>
                    }
                    
                </Assignment>
            </SubmittedFilesContainer>
:''} */}
            </div>
    )
}
export default SubmittionSection;