import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import {
  ContactMessagesLayout,
  NumOfMessages,
  UserMessagesContainer,
  UserMessagesParentRender,
  UserProfileContainer,
  UserProfileTextColumn,
  UserProfileTextPlace,
  UserProfileTextWidth,
  RightTriangle,
  MessagesTitleRow,
  MessagesAdd,
  MessagesSearchRow,
  MessagesSearchItem,
  ArchivePlace,
  DeleteButton,
  QuestionPart,
  OptionQuestion,
  OptionQuestionText,
  ShowFormAtrr,
  ChatEditBoxTool,
  MessagesAddType,
  MessagesAddCell,
  MessagesNoFriendsBox,
  RightTriangleDiv,
  UserMessagesChildRender,
  NumOfMessagesPlus,
  UserProfileTextPlaceMessage,
  NoConnection,
} from "./ContactMessages.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import MessamessageSenderInfoOnlyImgeSenderInfo from "../../elements/messageSenderInfo/messageSenderInfoOnlyImg";
import {
  GroupChatData,
  RealMessagesData,
  userChatData,
} from "./ContactMessagesData";
import {
  AddSqIcon,
  SvgArrowGroup,
  SvgArrowSimple,
  SvgDelete,
  SvgGroup3311,
  SvgGroupDefaultPicture,
  SvgInfo,
  SvgAboutIcon,
  SvgMessageIcon,
  SvgPaginationLeft,
  SvgQuestionMessage,
  SvgTrianglePolygon,
} from "../../elements/Icons";
import {
  ArrowStyleMessage,
  ShowForm,
} from "../../Views/messages/Messages.style";
import SearchElement from "../../elements/searchElement/SearchElement";
import PopUp from "../../elements/Popup/PopUp";
import { AddChatUserCompo } from "../AddChatUser/AddChatUserCompo";
import {
  ChatCheckboxLabel,
  ChatSeletectOptinToogle,
} from "./ContactCheckBox.style";

import { MessageHelps } from "../MessageHelps/MessageHelps";
import { MessageRealChatInterface } from "./ContactMessagesInterface";
import useClickOutside from "../../hooks/useClickOutside";
import { SelectedFriendContext } from "../../Views/messages/MessageSearch/SelectedFriendcontext/SelectedFriendContext";
import { Messages } from "./UserMessageInterFace";
import GroupCreator from "./GroupGreator/GroupGreator";
import EditContactMessage from "./EditContactMessage";
import {
  formatDateDisplay,
  getLastMessageDate,
} from "./ContactMessagesFunctions";
import { useRecoilValue } from "recoil";
import { ChatsColorSelector, ColorSelector } from "../../recoil/ThemeSelectors";
import { get } from "../../utils/AxiosRequests";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { WebSocketConnector } from "../../Views/messages/webSocketConnection";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { WebsocketContext } from "../../contexts/chatContext";
interface Friend {
  role: string | undefined;
  id: string;
  profilePicture: any;
  username: any;
}
interface GroupConv {
  profile: any;
  _id: string;
  name: string;
  Type: string;
}
export const ContactMessagesCompo = ({
  onFriendSelect,
  onGroupSelect,
  setFriendConversationId,
  GroupConv,
  SetGroupConv,
  convo,
  SetConvo,
  LeaveGroup,
  ConvDeleted,
  SetConvDeleted,
  SetLeaveGroup,
}: any): JSX.Element => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [showChats, setShowChats] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>("");
  const [Services, setServices] = useState<boolean>(false);
  const [AddUser, setAddUser] = useState<boolean>(false);
  const [showUnreadUsers, setShowUnreadUsers] = useState<boolean>(false);
  const [isContactSupport, setIsContactSupport] = useState<boolean>(false);
  const [EditCheckBox, setEditCheckBox] = useState<boolean>(false);
  const [showChatType, setShowChatType] = useState<boolean>(false);
  const [ShowCreateGroup, setShowCreateGroup] = useState<boolean>(false);
  const [showOnlyUnread, setShowOnlyUnread] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [usersWithNoMessages, setUsersWithNoMessages] = useState<string[]>([]);
  const [HaveFriends, setHaveFriends] = useState<boolean>(false);
  const servicesRef = useRef<HTMLDivElement | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [UserFriends, SetUserFriends] = useState<Friend[]>([]);
  const [mySourceId, setMySourceId] = useState<string>("");
  const {
    onlineClients,
    getUnvewedMessageCount,
    globalConversationId,
    setGlobalConversationId,
    getUnvewedConversationsMessageCount,
    conversationsMessagesCount,
  } = useContext(ClassContext);
  const [selectedUserToCreateChat, setSelectedUserToCreateChat] =
    useState<string>("");
  const [LastMessages, setLastMessages] = useState<any[]>([]);
  const [selectedFriendId, setSelectedFriendId] = useState<string | undefined>(
    undefined
  );
  const { user, getAccessTokenSilently } = useAuth0();
  const tenantName = localStorage.getItem("tenant");
  const myEmail = user?.email?.toLowerCase();
  // const connector = new WebSocketConnector();
  // const webSocket = useRef(connector);
  const { t } = useTranslation();

  const UrlId = useParams();
  const classId = UrlId.classId;
  const [connectedToWebsocket, setConnectedToWebsocket] =
    useState<boolean>(false);
  const {
    backgroundColor,
    bg_selectColor,
    themeTextColor,
    SecondaryWhiteTextColor,
    SecondaryTextColor,
    theme,
  } = useRecoilValue(ColorSelector);
  const { isReady, value, send } = useContext(WebsocketContext);
  // const { FriendMessageBoxBG_Color } = useRecoilValue(ChatsColorSelector);
  const {
    InfoSelectBorderColor,
    InfoSelectTextColor,
    FriendMessageBoxBG_Color,
  } = useRecoilValue(ChatsColorSelector);
  const handleShowChats = () => {
    setShowChats(!showChats);
  };
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 5000);

    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
  }, []);

  useEffect(() => {
    const usersWithoutMessages = UserFriends.filter((friend) => {
      const friendId = friend.id;
      const friendChat = userChatData.find(
        (data) => data.FriendId === friendId
      )?.Messages;
      return !friendChat || friendChat.length === 0;
    }).map((friend) => friend.id);

    setUsersWithNoMessages(usersWithoutMessages);
  }, [userChatData, connectedToWebsocket]);
  const [CreatedGroup, SetCreatedGroup] = useState(false);
  const handleButtonClick = (action: string) => {
    // You can now implement logic based on the selected items and the action
    // For example, you can call an API to perform the selected action on the backend
  };

  const handleEditCheckBox = () => {
    if (!HaveFriends) {
      return;
    }
    setEditCheckBox(!EditCheckBox);
  };

  const handleshowChatType = () => {
    setShowChatType(!showChatType);
  };
  const containerRef = useClickOutside(showChatType, () =>
    setShowChatType(false)
  );
  const handleItemSelection = (itemId: string) => {
    if (EditCheckBox) {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.includes(itemId)
          ? prevSelectedItems.filter((id) => id !== itemId)
          : [...prevSelectedItems, itemId]
      );
    } else {
      if (itemId.startsWith("group")) {
        // If it's a group, update selectedFriendId as well
        setSelectedFriendId(itemId);
        onGroupSelect(itemId);
        setSelectedIndex(UserFriends.length); // Assuming groups are rendered after friends
      } else {
        // If it's a friend, update selectedFriendId and selectedIndex
        setSelectedFriendId(itemId);
        onFriendSelect(itemId);
        // Find the index of the selected friend in UserFriends array
        const friendIndex = UserFriends.findIndex(
          (friend) => friend.id === itemId
        );
        setSelectedIndex(friendIndex);
      }
    }
  };

  const handleServices = () => {
    setServices(!Services);
    setIsContactSupport(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      servicesRef.current &&
      !servicesRef.current.contains(event.target as Node)
    ) {
      setServices(false);
    }
  };

  const handleAddUSER = () => {
    setAddUser(!AddUser);
  };
  const handleChangeShowCreateGroup = () => {
    setShowCreateGroup(!ShowCreateGroup);
  };

  const handleToggleUnreadUsers = () => {
    setShowOnlyUnread(!showOnlyUnread);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getUnreadMessagesCount = (
    messages: Messages[] | undefined,
    currentUserId: string
  ) => {
    if (!messages) {
      return 0;
    }

    return messages.filter(
      (message: { senderId: any; read: any }) =>
        message.senderId !== currentUserId && !message.read
    ).length;
  };

  const getLastMessage = (messages: Messages[] | undefined): string => {
    if (!messages || messages.length === 0) {
      return "";
    }

    // Assuming your messages have a timestamp, you can sort them to get the latest
    const sortedMessages = messages.slice().sort((a, b) => {
      const timeA = new Date(a.date).getTime();
      const timeB = new Date(b.date).getTime();
      return timeB - timeA;
    });

    // Return the content of the latest message
    return sortedMessages[0].content;
  };

  const FriendsMapsCount = UserFriends.length;
  const GroupMapsCount = GroupChatData.length;
  useEffect(() => {
    if (FriendsMapsCount !== 0 || GroupMapsCount !== 0) {
      setHaveFriends(true);
    } else setHaveFriends(false);
  }, [UserFriends, connectedToWebsocket]);

  const handleDeleteSelected = () => { };

  const filteredFriends = convo.filter(
    (conversation: any) =>
      conversation.Type === "individual" &&
      conversation.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const filteredGroups = GroupConv.filter((group: any) =>
    group.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleAddNewChat = () => {
    if (selectedUserToCreateChat) {
      // Find the index of the selected friend in UserFriends array based on the ID
      const friendIndex = UserFriends.findIndex(
        (friend) => friend.id === selectedFriendId
      );
      createConversation(selectedUserToCreateChat);
    }
    fetchEveryConv();
    // Close the pop-up or perform other actions
    setAddUser(false);
  };

  const { ContactCardBgColor } = useRecoilValue(ChatsColorSelector);
  const { checkboxBgColor, checkboxBorderColor, checkboxBorderRadius } =
    useRecoilValue(ColorSelector);

  const fetchAllUsersData = async () => {
    try {
      const response = await get(`participant/class/${classId}/${tenantName}`);
      setDataLoading(false);
      const filteredData = response.data.filter(
        (user: any) => user.email.toLowerCase() !== myEmail
      );
      const modifiedData = filteredData.map((user: any) => ({
        ...user,
        id: user.sourceId,
      }));

      SetUserFriends(modifiedData);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchEveryConv = async () => {
    try {
      const response = await axios.get(
        `https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/get/conversations/${classId}/${subIdSplitted}/${tenantName}?provider=${provider}`
      );
      SetConvo(response.data.data);
      const filteredGroups = response.data.data.filter(
        (conversation: { Type: any }) => conversation.Type === "group"
      );
      SetGroupConv(filteredGroups);
    } catch (err) {
      console.log(err);
    }
  };

  const formatDate = (dateString: any) => {
    // Parse the date string
    const date = new Date(dateString);

    // Check if the parsed date is invalid
    if (isNaN(date.getTime())) {
      // Return an empty string for invalid dates
      return "";
    }

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const isToday = date.toDateString() === today.toDateString();
    const isYesterday = date.toDateString() === yesterday.toDateString();

    if (isToday) {
      // Show only hours if it's for today
      return date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else if (isYesterday) {
      // Show "Yesterday" if it's for yesterday
      return t("Chat.Yesterday");
    } else {
      // Show day/month/year for older dates
      const options: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      };
      return date.toLocaleDateString("en-US", options);
    }
  };

  useEffect(() => {
    fetchAllUsersData();
    fetchEveryConv();
  }, [mySourceId, AddUser, LeaveGroup, ConvDeleted]);

  const fetchUsersData = async () => {
    try {
      const response = await get(`participant/class/${classId}/${tenantName}`);
      const auditors = await axios.get(`https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/classAuditor/auditors/${tenantName}/${classId}`)

      setDataLoading(false);
      const filteredData = response.data.filter(
        (user: any) => user.email.toLowerCase() === myEmail
      );
      const auditorFilteredData = auditors.data.data.filter(
        (user: any) => user.email.toLocaleLowerCase() === myEmail?.toLocaleLowerCase()
      );
      if (filteredData.length > 0)
        setMySourceId(filteredData[0].sourceId);
      else if (auditorFilteredData.length > 0)
        setMySourceId(auditorFilteredData[0].sourceId);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const selectedGlobalConversation = filteredFriends.find(
      (conversation: any) => conversation._id === globalConversationId
    );
    if (selectedGlobalConversation) {
      setSelectedFriendId(selectedGlobalConversation.Participants[0]);
      setFriendConversationId(selectedGlobalConversation._id);
      onFriendSelect(selectedGlobalConversation.Participants[0]);
    }
  }, [convo]);

  useEffect(() => {
    fetchUsersData();
    fetchEveryConv();
    getUnvewedConversationsMessageCount(classId || "");
  }, [connectedToWebsocket]);

  useEffect(() => {
    fetchLastMessageForEachConversation();
  }, [connectedToWebsocket, selectedFriendId, ConvDeleted]);
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");

  useEffect(() => {
    var data = JSON.parse(value);
    // console.log("Recieved Message---->",data)
    if (data && data.type && data.type === "message") {
      const receive = data.data;
      fetchLastMessageForEachConversation();
      fetchEveryConv();
      getUnvewedMessageCount(classId || "");
      getUnvewedConversationsMessageCount(classId || "");
    } else if (data && data.type && data.type === "clients") {
    } else if (data && data.type && data.type === "messages") {
    } else if (data && data.type && data.type === "public") {
      const receive = data.data;
      fetchLastMessageForEachConversation();
      getUnvewedMessageCount(classId || "");
      getUnvewedConversationsMessageCount(classId || "");
      fetchEveryConv();
    }
  }, [value]);

  const createConversation = async (friendID: any) => {
    const data = {
      tenantName: tenantName,
      classSourceId: classId, // The sourceId of the class
      participants: [friendID], // My sourceId and the other user's SourceId
      type: "individual", // or group,
      subId: subIdSplitted,
      provider: provider,
    };
    try {
      const response = await axios.post(
        `https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/create/conversations`,
        {
          data: data,
        }
      );
      fetchEveryConv();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLastMessageForEachConversation = async () => {
    try {
      const response = await axios.get(
        `https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/get/conversations/${classId}/${subIdSplitted}/${tenantName}?provider=${provider}`
      );
      const conversations = response.data.data;
      const lastMessages = [];

      // Fetch last message for each conversation
      // if (response && response.data && response.data.data && response.data.data.length > 0) {
      for (const conversation of response.data.data) {
        const lastMessage = await fetchLastMessage(conversation._id);
        if (lastMessage !== null) {
          lastMessages.push(lastMessage);
        }
      }
      // }
      // Update state with last messages
      setLastMessages(lastMessages);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLastMessage = async (conversationId: any) => {
    try {
      const response = await axios.get(
        `https://whexhakppa.execute-api.eu-west-1.amazonaws.com/dev/chat/get/messages/${conversationId}/${tenantName}/${subIdSplitted}?provider=${provider}`
      );
      // if (response && response.data && response.data.data && response.data.data.length > 0) {
      const lastMessage = response.data.data[response.data.data.length - 1];
      return {
        message: lastMessage ? lastMessage.message : "",
        createdAt: lastMessage ? lastMessage.createdAt : "",
        senderId: lastMessage ? lastMessage.senderId : "",
        conversationId: lastMessage ? lastMessage.conversationId : "",
      };
      // }
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const fetchOnlineClients = () => {
    if (send && isReady) {
      send(
        JSON.stringify({
          action: "onlineClient",
          data: {
            tenantName: tenantName,
            classSourceId: classId,
          },
        })
      );
    }
  };

  useEffect(() => {
    if (globalConversationId) {
      fetchOnlineClients();
    }
  }, [isReady]);

  return (
    <>
      {!ShowCreateGroup ? (
        <>
          <ContactMessagesLayout
            bgcolor={backgroundColor}
            // bgColor={"green"}
            style={{ width: showChats ? "100%" : "fit-content" }}
          >
            <MessagesTitleRow
              style={{
                width: showChats ? "90%" : "80%",
                marginBottom: showChats ? " 0" : "1rem",
                color: themeTextColor,
              }}
            >
              <Typography text={t("Chat.Chats")} fontSize="1.125rem" />
              <div
                style={{ display: "flex", gap: "0.7rem", alignSelf: "center" }}
              >
                {showChats && (
                  <MessagesAdd ref={containerRef} onClick={handleshowChatType}>
                    <AddSqIcon color="#fff" />

                    {showChatType && (
                      <MessagesAddType
                        bgColor={bg_selectColor}
                        color={themeTextColor}
                      >
                        <MessagesAddCell onClick={handleAddUSER}>
                          <MediumTypography
                            text={t("Chat.New Chat")}
                            fontSize="0.75rem"
                          />
                        </MessagesAddCell>
                        <MessagesAddCell onClick={handleChangeShowCreateGroup}>
                          <MediumTypography
                            text={t("Chat.New Group Chat")}
                            fontSize="0.75rem"
                          />
                        </MessagesAddCell>
                      </MessagesAddType>
                    )}
                  </MessagesAdd>
                )}
                <ArrowStyleMessage
                  onClick={handleShowChats}
                  // alt="Arrow"
                  // src="/assets/icons/Arrow.svg"
                  style={{
                    transform: showChats ? "rotate(180deg)" : "rotate(0)",
                    transition: "transform 0.3s ease-in-out",
                    color: themeTextColor,
                    // width: "2px",
                    padding: "0.2rem 0.3rem",
                  }}
                >
                  {/* ▲ */}
                  <SvgPaginationLeft />
                </ArrowStyleMessage>
              </div>
            </MessagesTitleRow>
            {showChats && (
              <ShowForm>
                <ShowFormAtrr>
                  {!EditCheckBox && (
                    <>
                      <MessagesTitleRow>
                        <div
                        // style={{ cursor: "pointer" }}
                        // onClick={handleEditCheckBox}
                        >
                          {/* <Typography
                            text={t("dashboard.Edit")}
                            fontSize="0.875rem"
                            color="#5DD3B3"
                          /> */}
                        </div>
                        {/* OLDPlace */}
                        {/* <MessagesAdd ref={containerRef}>
                          <AddSqIcon
                           
                            color="#fff"
                          />
                         

                          {showChatType && (
                            <MessagesAddType
                              bgColor={bg_selectColor}
                              color={themeTextColor}
                              onClick={handleAddUSER}
                            >
                              <MessagesAddCell>
                                <MediumTypography
                                  text={t("Chat.New Chat")}
                                  fontSize="0.75rem"
                                />
                              </MessagesAddCell>
                              <MessagesAddCell
                                onClick={handleChangeShowCreateGroup}
                              >
                                <MediumTypography
                                  text={t("Chat.New Group Chat")}
                                  fontSize="0.75rem"
                                />
                              </MessagesAddCell>
                            </MessagesAddType>
                          )}
                        </MessagesAdd> */}
                      </MessagesTitleRow>

                      {!isReady && showComponent ? (
                        <NoConnection>
                          <SvgAboutIcon
                            width={"4rem"}
                            style={{ marginRight: "0.5rem" }}
                          />
                          <TypographyRegularDescription
                            lineHeight="1.3rem"
                            text={t(
                              "Chat.It looks like there is no internet connection right now. Please check your connection and try refreshing the page."
                            )}
                            fontSize="0.75rem"
                          />{" "}
                        </NoConnection>
                      ) : (
                        // : !isReady ?
                        // (
                        //   <LightTypography text="Connecting ..." />
                        // )
                        ""
                      )}

                      <MessagesSearchRow
                        style={{ marginTop: !isReady ? "1.5rem" : "-1.5rem" }}
                      >
                        <MessagesSearchItem>
                          <SearchElement
                            leftPositionIcon="1.25rem"
                            paddingLeftIcon="1.25rem"
                            topPositionIcon="0.75rem"
                            onChange={(e) => setSearchText(e.target.value)}
                            backGroundPosition="1.1rem 0.7rem"
                          />
                        </MessagesSearchItem>
                        {/* <SvgGroup3311
                          onClick={handleToggleUnreadUsers}
                          style={{ cursor: "pointer" }}
                        /> */}
                      </MessagesSearchRow>
                    </>
                  )}

                  {/* {HaveFriends && (
                    <ArchivePlace
                      style={{ marginTop: EditCheckBox ? "1rem" : "0" }}
                    >
                      {EditCheckBox && (
                        <DeleteButton>
                          <SvgArrowGroup onClick={handleEditCheckBox} />
                        </DeleteButton>
                      )}
                      <MediumTypography
                        text={t("Chat.Archived")}
                        fontSize="0.875rem"
                      />
                      <DeleteButton onClick={handleDeleteSelected}>
                        <SvgDelete />
                      </DeleteButton>
                    </ArchivePlace>
                  )} */}
                </ShowFormAtrr>

                {HaveFriends ? (
                  <>
                    {!EditCheckBox ? (
                      <>
                        <UserMessagesParentRender height="60vh">
                          <UserMessagesChildRender>
                            {filteredFriends.length > 0 &&
                              filteredFriends.map(
                                (friend: any, index: number) => {
                                  const isSelected =
                                    friend.Participants[0] === selectedFriendId;
                                  const isOnline = onlineClients.some(
                                    (client: any) =>
                                      client.userSourceId ===
                                      friend.Participants[0]
                                  );
                                  const UnviewedNumberOfMessage =
                                    conversationsMessagesCount.find(
                                      (conversation) =>
                                        conversation.id === friend._id
                                    ) || 0;
                                  return showOnlyUnread &&
                                    getUnreadMessagesCount(
                                      userChatData[index]?.Messages,
                                      "1"
                                    ) === 0 ? null : (
                                    <UserMessagesContainer
                                      key={friend.Participants[0]}
                                      onClick={() => {
                                        setSelectedFriendId(
                                          friend.Participants[0]
                                        );
                                        fetchEveryConv();
                                        setFriendConversationId(friend._id);
                                        fetchOnlineClients();
                                        onFriendSelect(friend.Participants[0]);
                                        setGlobalConversationId("");
                                        setSelectedIndex(null);
                                      }}
                                      style={{
                                        backgroundColor:
                                          friend.Participants[0] ===
                                            selectedFriendId && !ShowCreateGroup
                                            ? "#5DD3B3"
                                            : ContactCardBgColor,

                                        // boxShadow:
                                        //   friend.id === selectedFriendId
                                        //     ? "0 0 10px rgba(93, 211, 179, 0.8)"
                                        //     : "none",
                                      }}
                                      color="#fff"
                                    >
                                      <UserProfileContainer>
                                        {isSelected && (
                                          <RightTriangle>
                                            <SvgTrianglePolygon />
                                          </RightTriangle>
                                        )}
                                        <MessamessageSenderInfoOnlyImgeSenderInfo
                                          profileImg={
                                            friend.profile &&
                                              friend.profile.trim() !== ""
                                              ? friend.profile
                                              : require("../../assets/userProfile.png")
                                          }
                                          online={isOnline}
                                          OnlineCircleWidth="0.32625rem"
                                          MaskGroupW="2.37713rem"
                                          MaskGroupH="2.37713rem"
                                          ProfileHolderH="2.75rem"
                                          ProfileHolderW="2.75rem"
                                          backGroundColor={
                                            isSelected
                                              ? "#fff"
                                              : FriendMessageBoxBG_Color
                                          }
                                        />
                                      </UserProfileContainer>
                                      <UserProfileTextColumn
                                        style={{ marginLeft: "-0.2rem" }}
                                      >
                                        <UserProfileTextPlace
                                          style={{
                                            width: "92%",
                                            justifyContent: "none",
                                          }}
                                        >
                                          {/* Displaying the username */}
                                          <MediumTypography
                                            text={
                                              friend.name.length > 15
                                                ? `${friend.name.slice(
                                                  0,
                                                  15
                                                )}...`
                                                : friend.name
                                            }
                                            fontSize="0.875rem"
                                            color={
                                              isSelected
                                                ? "#fff"
                                                : themeTextColor
                                            }
                                          />
                                          {/* Displaying a static date */}

                                          <MediumTypography
                                            text={
                                              formatDate(
                                                LastMessages?.find(
                                                  (message: any) =>
                                                    message.conversationId ===
                                                    friend._id
                                                )?.createdAt
                                              ) || ""
                                            }
                                            fontSize=" 0.5rem"
                                            color={
                                              isSelected
                                                ? "#fff"
                                                : SecondaryWhiteTextColor
                                            }
                                          />
                                        </UserProfileTextPlace>
                                        <UserProfileTextPlaceMessage
                                          style={{ width: "93%" }}
                                        >
                                          <UserProfileTextWidth
                                            theme={theme}
                                            opacity={
                                              friend.Participants[0] ===
                                                selectedFriendId &&
                                                !ShowCreateGroup
                                                ? "1"
                                                : theme === "light"
                                                  ? "1"
                                                  : "0.5"
                                            }
                                          >
                                            <LightTypography
                                              text={
                                                LastMessages?.find(
                                                  (message: any) =>
                                                    message.conversationId ===
                                                    friend._id
                                                )?.message || ""
                                              }
                                              fontSize=" 0.75rem"
                                              color={
                                                isSelected
                                                  ? "#fff"
                                                  : SecondaryWhiteTextColor
                                              }
                                            />
                                          </UserProfileTextWidth>

                                          {/* Displaying a dynamic number of unread messages */}
                                          {UnviewedNumberOfMessage.count > 0 ? (
                                            <NumOfMessages>
                                              <MediumTypography
                                                text={
                                                  UnviewedNumberOfMessage.count
                                                }
                                                fontSize="0.5rem"
                                              />
                                            </NumOfMessages>
                                          ) : (
                                            ""
                                          )}
                                        </UserProfileTextPlaceMessage>
                                      </UserProfileTextColumn>
                                    </UserMessagesContainer>
                                  );
                                }
                              )}
                            {GroupConv &&
                              GroupConv.length > 0 &&
                              filteredGroups.map(
                                (group: any, index: number) => {
                                  const isSelected =
                                    selectedIndex ===
                                    index + UserFriends.length;
                                  const UnviewedNumberOfMessage =
                                    conversationsMessagesCount.find(
                                      (conversation) =>
                                        conversation.id === group._id
                                    ) || 0;
                                  return showOnlyUnread &&
                                    getUnreadMessagesCount(
                                      userChatData[index]?.Messages,
                                      "1"
                                    ) === 0 ? null : (
                                    <UserMessagesContainer
                                      key={group._id}
                                      onClick={() => {
                                        setSelectedFriendId("");
                                        onFriendSelect(null);
                                        setSelectedIndex(
                                          index + UserFriends.length
                                        );
                                        fetchEveryConv();
                                        setGlobalConversationId("");
                                        onGroupSelect(group._id);
                                      }}
                                      style={{
                                        backgroundColor:
                                          selectedIndex ===
                                            index + UserFriends.length &&
                                            !ShowCreateGroup
                                            ? "#5DD3B3"
                                            : ContactCardBgColor,
                                        color: "#fff",
                                      }}
                                    >
                                      <UserProfileContainer>
                                        {isSelected && (
                                          <RightTriangle>
                                            <SvgTrianglePolygon />
                                          </RightTriangle>
                                        )}
                                        <MessamessageSenderInfoOnlyImgeSenderInfo
                                          profileImg={
                                            group.profile
                                              ? group.profile
                                              : require("../../assets/GroupDefaultProfile.png")
                                          }
                                          ShowOnline={false}
                                          OnlineCircleWidth="0.32625rem"
                                          backGroundColor={
                                            isSelected
                                              ? "#fff"
                                              : FriendMessageBoxBG_Color
                                          }
                                          MaskGroupW="2.37713rem"
                                          MaskGroupH="2.37713rem"
                                          ProfileHolderH="2.75rem"
                                          ProfileHolderW="2.75rem"
                                        />
                                      </UserProfileContainer>
                                      <UserProfileTextColumn>
                                        <UserProfileTextPlace
                                          style={{ width: "93%" }}
                                        >
                                          <MediumTypography
                                            text={
                                              group.name.length > 15
                                                ? `${group.name.slice(
                                                  0,
                                                  15
                                                )}...`
                                                : group.name
                                            }
                                            fontSize="0.875rem"
                                            color={themeTextColor}
                                          />
                                          <MediumTypography
                                            text={
                                              formatDate(
                                                LastMessages?.find(
                                                  (message: any) =>
                                                    message.conversationId ===
                                                    group._id
                                                )?.createdAt
                                              ) || ""
                                            }
                                            fontSize="8px"
                                            color={themeTextColor}
                                          />
                                        </UserProfileTextPlace>
                                        <UserProfileTextPlaceMessage
                                          style={{ width: "93%" }}
                                        >
                                          <UserProfileTextWidth
                                            opacity={
                                              selectedIndex ===
                                                index + UserFriends.length &&
                                                !ShowCreateGroup
                                                ? "1"
                                                : theme === "light"
                                                  ? "1"
                                                  : "0.5"
                                            }
                                          >
                                            <LightTypography
                                              text={
                                                LastMessages?.find(
                                                  (message: any) =>
                                                    message.conversationId ===
                                                    group._id
                                                )?.message || ""
                                              }
                                              fontSize="0.75rem"
                                              color={themeTextColor}
                                            />
                                          </UserProfileTextWidth>
                                          {UnviewedNumberOfMessage.count > 0 ? (
                                            <NumOfMessages>
                                              <MediumTypography
                                                text={
                                                  UnviewedNumberOfMessage.count
                                                }
                                                fontSize="0.5rem"
                                              />
                                            </NumOfMessages>
                                          ) : (
                                            ""
                                          )}
                                        </UserProfileTextPlaceMessage>
                                      </UserProfileTextColumn>
                                    </UserMessagesContainer>
                                  );
                                }
                              )}
                          </UserMessagesChildRender>
                        </UserMessagesParentRender>
                      </>
                    ) : (
                      <>
                        <UserMessagesParentRender height="68vh">
                          <UserMessagesChildRender>
                            {UserFriends.length > 0 &&
                              UserFriends.map((friend: any, index: number) => (
                                <UserMessagesContainer
                                  key={friend.id}
                                  style={{
                                    backgroundColor:
                                      selectedIndex ===
                                        index + UserFriends.length
                                        ? "#5DD3B3"
                                        : ContactCardBgColor,
                                  }}
                                >
                                  <ChatSeletectOptinToogle
                                    bgColor={checkboxBgColor}
                                    borderColor={checkboxBorderColor}
                                    borderRadius={checkboxBorderRadius}
                                    type="checkbox"
                                    checked={selectedItems.includes(friend.id)}
                                    onChange={() =>
                                      handleItemSelection(friend.id)
                                    }
                                    id={friend.id}
                                    value={friend.id}
                                  />
                                  <ChatCheckboxLabel
                                    bgColor={checkboxBgColor}
                                    borderColor={checkboxBorderColor}
                                    borderRadius={checkboxBorderRadius}
                                    key={friend.id}
                                    htmlFor={friend.id}
                                  >
                                    <UserProfileContainer
                                      style={{ marginTop: "-0.7rem" }}
                                    >
                                      <MessamessageSenderInfoOnlyImgeSenderInfo
                                        profileImg={
                                          friend.profile &&
                                            friend.profile.trim() !== ""
                                            ? friend.profile
                                            : require("../../assets/userProfile.png")
                                        }
                                        online={friend.online}
                                        OnlineCircleWidth="0.32625rem"
                                      />
                                    </UserProfileContainer>
                                    <UserProfileTextColumn
                                      style={{ marginTop: "-0.7rem" }}
                                    >
                                      <UserProfileTextPlace>
                                        <MediumTypography
                                          text={friend.username}
                                          fontSize="0.875rem"
                                        />
                                        <MediumTypography
                                          text={
                                            formatDate(
                                              LastMessages?.find(
                                                (message: any) =>
                                                  message.senderId === friend.id
                                              )?.createdAt
                                            ) || ""
                                          }
                                          fontSize="8px"
                                        />
                                      </UserProfileTextPlace>
                                      <UserProfileTextPlaceMessage>
                                        <UserProfileTextWidth>
                                          <LightTypography
                                            text={
                                              LastMessages?.find(
                                                (message: any) =>
                                                  message.senderId === friend.id
                                              )?.message || ""
                                            }
                                            fontSize="0.75rem"
                                          />
                                        </UserProfileTextWidth>
                                        {getUnreadMessagesCount(
                                          userChatData[index]?.Messages,
                                          "1"
                                        ) > 0 && (
                                            <>
                                              {getUnreadMessagesCount(
                                                userChatData[index]?.Messages,
                                                "1"
                                              ) < 10 ? (
                                                <NumOfMessages
                                                  style={{
                                                    borderRadius: "50%",
                                                    color: "#fff",
                                                  }}
                                                >
                                                  <MediumTypography
                                                    text={getUnreadMessagesCount(
                                                      userChatData[index]
                                                        ?.Messages,
                                                      "1"
                                                    ).toString()}
                                                    fontSize="0.5rem"
                                                    color="#fff"
                                                  />
                                                </NumOfMessages>
                                              ) : (
                                                <NumOfMessagesPlus
                                                  style={{
                                                    borderRadius: "50%",
                                                    color: "#fff",
                                                  }}
                                                >
                                                  <MediumTypography
                                                    text={getUnreadMessagesCount(
                                                      userChatData[index]
                                                        ?.Messages,
                                                      "1"
                                                    ).toString()}
                                                    fontSize="0.5rem"
                                                    color="#fff"
                                                  />
                                                </NumOfMessagesPlus>
                                              )}
                                            </>
                                          )}
                                      </UserProfileTextPlaceMessage>
                                    </UserProfileTextColumn>
                                  </ChatCheckboxLabel>
                                </UserMessagesContainer>
                              ))}
                          </UserMessagesChildRender>
                        </UserMessagesParentRender>
                      </>
                    )}
                  </>
                ) : (
                  <MessagesNoFriendsBox>
                    <Typography text={t("Chat.No Chats")} fontSize="0.875rem" />
                  </MessagesNoFriendsBox>
                )}

                {!EditCheckBox ? (
                  <QuestionPart ref={servicesRef}>
                    {Services && (
                      <OptionQuestion>
                        <MessageHelps />
                      </OptionQuestion>
                    )}
                    <SvgQuestionMessage
                      onClick={handleServices}
                      style={{ cursor: "pointer", color: themeTextColor }}
                    />
                  </QuestionPart>
                ) : (
                  <ChatEditBoxTool
                    bgColor={backgroundColor}
                    color={InfoSelectTextColor}
                    borderColor={InfoSelectBorderColor}
                  >
                    <div onClick={() => handleButtonClick("Unarchive")}>
                      <TypographyRegularDescription
                        text={t("Chat.Unarchive")}
                        fontSize="0.875rem"
                      />
                    </div>

                    <div onClick={() => handleButtonClick("Read")}>
                      <TypographyRegularDescription
                        text={t("Chat.Read")}
                        fontSize="0.875rem"
                        color="#5DD3B3"
                      />
                    </div>

                    <div onClick={() => handleButtonClick("Delete")}>
                      <TypographyRegularDescription
                        text={t("group.Delete")}
                        fontSize="0.875rem"
                      />
                    </div>
                  </ChatEditBoxTool>
                )}
              </ShowForm>
            )}
          </ContactMessagesLayout>
          {AddUser && (
            <PopUp
              show={AddUser}
              justifyContent="flex-end"
              content={
                <AddChatUserCompo
                  selectedUserToCreateChat={selectedUserToCreateChat}
                  setSelectedUserToCreateChat={setSelectedUserToCreateChat}
                />
              }
              setShow={setAddUser}
              buttonName={t("Chat.Chat")}
              title={t("Chat.Chat With")}
              icon={<SvgMessageIcon />}
              showline={false}
              showButton={true}
              showButtonCancel={false}
              handleSubmit={handleAddNewChat}
              descriptionText={t(
                "Chat.Chat with users whom you are enrolled in the same course with"
              )}
            />
          )}
        </>
      ) : (
        <GroupCreator
          setShowCreateGroup={setShowCreateGroup}
          fetchAllConv={fetchEveryConv}
          SetCreatedGroup={SetCreatedGroup}
        />
      )}
    </>
  );
};
