import styled from "styled-components";

export const PostsTimeLineContainer = styled.div<{
  color?: string;
  bgColor?: string;
}>`
  border-radius: 1.25rem;
  /* background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%)); */
  gap: 0.62rem;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
`;

/////////////Poster Profile/////////////////////
export const PTLPosterRows = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.62rem;
`;
export const PTLPosterCols = styled.div`
  flex-direction: column;
  display: flex;
  gap: 0.2rem;
`;

export const PTLPosterImg = styled.img`
  display: flex;
  width: 3.125rem;
  height: 3.125rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
`;

export const ThreeDotsContainer = styled.div`
  position: relative;
`;

export const ThreeDotsContainerAbsolute = styled.div`
  position: absolute;
  &:dir(ltr){
  right: 1rem;
  }
  &:dir(rtl){
  left: 1rem;
  }
`;

//////////////////////////////////

/////////////Poster Text/////////////////////

export const PTLPosterText = styled.div`
  display: inline-block;
`;

//////////////
export const CombinedParagraph = styled.div`
  font-family: "Primary Font-Light";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  display: inline-block;
`;

export const TextDisplay = styled.p`
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin: 0; /* Remove default margin to ensure inline display */
`;

export const ShowsLength = styled.p`
  color: #5dd3b3;
  cursor: pointer;
  display: inline-block;
  margin-left: 5px;
`; ////////Poster Line Actions/////////////////////
export const PTLLineActions = styled.div`
  display: flex;
  width: 100%;
  gap: 1.25rem;
`;

export const PTLActionsCell = styled.div`
  display: flex;
  cursor: pointer;
  gap: 0.31rem;
  /* color:rgba(255, 255, 255, 0.50); */
  /* opacity:0.8; */
`;
////////////////////

export const PTLDisplays = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
`;

export const PTLImg = styled.img`
  display: flex;

  width: fit-content;
  height: fit-content;
  max-width: 100%;
  max-height: 15rem;
  object-fit: cover;
`;

export const PTLPdfDisplay = styled.div`
  display: flex;
  gap: 0.6rem;
  color: #5dd3b3;
  height: 3rem;
  border-radius: 0.625rem;
  background: rgba(255, 255, 255, 0.03);
  align-items: center;
  padding: 1.25rem;
`;

export const FlexShow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
