import styled from "styled-components";
import { breakpoints } from "../../theme";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";
interface TableTdProps {
  color?: string;
}

export const SessionsWrapper = styled.div`
  margin-bottom: 1.25rem;
`;
export const GoBackRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.62rem;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 1.25rem;
`;
export const CourseDetailsWithSearch = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const CourseNameRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.63rem;
  width: fit-content;
`;
export const CourseNameWithSemester = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.63rem;
  margin-bottom: 1.25rem;
  width: fit-content;
`;
export const SessionNbWithDate = styled.div`
  display: flex;
  flex-direction: row;
  width: max-content;

  /* margin-bottom: 1.25rem; */
`;
export const ViewAllWithSearchRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 1.25rem;
  background-color: red;
  & > :first-child {
    cursor: pointer;
  }
`;
export const LogoAndAction = styled.div`
  display: flex;
  align-items: center;
  gap: 0.56rem;
  cursor: pointer;
`;

export const SearchAndLogosDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.81rem;
  justify-content: flex-end;
  align-items: flex-end;
  & > :first-child {
    align-content: flex-end;
  }
`;
export const ActionsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.25rem;
  align-self: end;
  @media (max-width: ${breakpoints.small}) {
    flex-wrap: wrap;
  }
`;

export const TableSection = styled.div`
  width: 100vw;
  display: flex;
  min-height: fit-content;
  /* overflow-x: hidden; */
  margin-top: 1.25rem;
  position: relative;
  /* scroll-behavior: smooth; */
  /* background-color: red; */
`;

export const TableContentHolder = styled.table`
  width: 100vw;

  /* min-width: 800px; */
  border: none;
  /* background-color: yellow; */
`;

export const TableContent = styled.table`
  max-height: 100%;
  height: fit-content;
  /* width: 100%; */
  border-collapse: collapse;
  overflow-x: auto;
  white-space: nowrap;
  border: none;
  width: 100vw;
  justify-content: center;
  align-items: center;
  /* background-color: white; */
`;

export const TableThead = styled.thead`
  display: flex;
  /* grid-template-columns: 18rem auto auto auto 24%; */
  align-items: center;
  /* gap: 6.5rem; */
  border: none;
  white-space: nowrap;
`;

export const TableTh = styled.th`
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
  width: 48%;
  &:dir(ltr) {
    padding: 0 0rem 0rem 1rem;
  }
  &:dir(rtl) {
    padding: 0 1rem 0rem 0rem;
  }
  /* gap: 25.5rem; */
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
`;

export const TableTh2 = styled.th`
  align-items: center;

  /* gap: 25.5rem; */
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
`;

export const TableTr = styled.tr<StyleThemeInterface>`
  /* width: auto; */
  &:nth-child(even) {
    background:${props =>props.nth_child_bgcolor};
    border: none;
  }
`;
export const TableTr1 = styled.tr`
  display: grid;
  grid-template-columns: 16.7rem auto auto auto 22%;

  white-space: nowrap;
  &:dir(ltr) {
    padding-left: 0rem;
  }
  &:dir(rtl) {
    padding-right: 0rem;
  }

  /* padding-right: 50%; */
  align-items: center;
  min-width: 100vw;
  height: 5.625rem;
  &:nth-child(even) {
    background: rgba(255, 255, 255, 0.03);
    border: none;
  }
`;
export const TableTr2 = styled.tr`
  display: flex;
  justify-content: space-between;
  /* gap: 1rem; */
  white-space: nowrap;
  padding-left: 0rem;
  padding-right: 0;
  align-items: flex-start;
  min-width: 100vw;
  height: 3.56rem;
  &:nth-child(even) {
    background: rgba(255, 255, 255, 0.03);
    border: none;
  }
`;

export const TableTbody = styled.tbody`
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
`;

export const TableTd = styled.td<TableTdProps>`
  border: none;
  height: 3.5625rem;
  border-collapse: collapse;
  padding-top: 1rem;
  text-align: start;
  min-width: 13.5rem;
  color: ${({ color }) => color};
  display: flex;
  gap: 1rem;
  align-items: center;
`;
export const TableTd1 = styled.td<TableTdProps>`
  border: none;
  height: 3.5625rem;
  border-collapse: collapse;
  padding-top: 1rem;
  text-align: start;
  min-width: 13.5rem;
  color: ${({ color }) => color};
  display: flex;
  gap: 1rem;
  &:dir(ltr) {
    margin-left: 1rem;
  }
  &:dir(rtl) {
    margin-right: 1rem;
  }
  font-family: "Primary Font-Medium";
`;

export const TableTd3 = styled.td`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
`;

export const ProfileImageHolder = styled.img`
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 50%;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* position: relative; */
`;
export const DivArrowsWrapper = styled.div`
  padding: 0 0.62rem 0 0.63rem;
  width: 2.625rem;
  height: 1.25rem;
  border-radius: 1.25rem;
  background-color: #364350;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 1.25rem;
  &:dir(ltr) {
    left: 95%;
  }
  &:dir(rtl) {
    right: 95%;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${breakpoints.tablets}) {
    &:dir(ltr) {
      left: 46%;
    }
    &:dir(rtl) {
      right: 46%;
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    &:dir(ltr) {
      left: 45%;
    }
    &:dir(rtl) {
      right: 45%;
    }
  }
  & > :first-child {
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.dark.main_color};
      transition: color 0.2s ease-in-out;
    }
  }
  & > :last-child {
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.dark.main_color};
      transition: color 0.2s ease-in-out;
    }
  }
`;

export const FlexedDiv = styled.div`
  align-items: center;
  display: flex;
`;
