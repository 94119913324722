import axios from "axios";
import { useEffect, useState } from "react";

export const useTeamsClass = (classId: any) => {
  const [withTeams, setWithTeams] = useState(false);
  const tenantName = localStorage.getItem("tenant");
  useEffect(() => {
    const checkIfHasTeams = async () => {
      try {
        const response = await axios.get(
          `https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/classHasMs/${classId}/${tenantName}`
        );
        response.data.data === true ? setWithTeams(true) : setWithTeams(false);
      } catch (err) {
        console.log(err);
      }
    };
    checkIfHasTeams();
  }, [classId, tenantName]);

  return { withTeams };
};
