import styled from "styled-components";
import { breakpoints } from "../../theme";
import chooseColorImage from "../../assets/chooseColor.jpg";

interface BackgroundColorProps {
  backgroundcolor: string;
}
interface ColorPickerProps {
  position?: string;
  top?: string;
  left?: string;
  width?: string;
  height?: string;
  flexWrap?: string;
}
export const ColorPickerMainHolder = styled.div`
  background-color: gray;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* justify-content: space-between;
  align-items: center; */
`;

export const ColorPickerHolder = styled.div<ColorPickerProps>`
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  max-width: 18.4375rem;
  min-height: 2.1875rem;
  border-radius: 0.625rem;
  background: linear-gradient(90deg, #576371 0%, #415160 0%, #293440 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.62rem;
  padding: 0.63rem 0.94rem;
  z-index: 25;
  min-width: ${({ width }) => width} !important;
  height: ${({ height }) => height} !important;
  flex-wrap: ${({ flexWrap }) => flexWrap} !important;
  /* @media (max-width: ${breakpoints.tablets}) {
    height: fit-content;
    flex-wrap: wrap;
  } */
`;
export const ColorPickerButton = styled.button<BackgroundColorProps>`
  display: inline-block;
  width: 0.9375rem;
  height: 0.9375rem;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  background: ${(props) => props.backgroundcolor};
  border: none;
  &:hover {
    transform: scale(1.2);
    transition: transform 100ms ease 0s;
  }
`;

export const ColorPickerDiv = styled.div`
  width: 0.9375rem;
  height: 0.9375rem;
  cursor: pointer;
  border-radius: 50%;
  background-image: url(${chooseColorImage});
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: 108%;
  background-position: center;
  position: relative;

  &:hover,
  &:active {
    transform: scale(1.2);
    transition: transform 100ms ease 0s;
  }

  &.rotated {
    transform: rotateZ(45deg) scale(1.2);

    &:hover,
    &:active {
      transform: rotateZ(45deg) scale(1.2);
    }
  }
`;
