import React, { useContext, useEffect } from 'react'
import FolderRecourses from '../FolderResourses/FolderRecourses';
import ImageViewer from '../ImageViewer/ImageViewer';
import AssignmentInstructor from '../ActivitiesInstructorView/Assignment/AssignmentInstructor';
import QuizInstructor from '../ActivitiesInstructorView/Quiz/QuizInstructor';
import SurveyInstructor from '../ActivitiesInstructorView/Survey/SurveyInstructor';
import StudentQuizView from '../StudentQuizView/StudentQuizView';
import SurveyStudentCompo from '../SurveyStudentView/main/SurveyStudentCompo';
import Assignment from '../assignmentActivity/Assignment';
import DiscussionForumView from '../discussionForumnActivity/DiscussionForumView';
import DocumentViewer from '../documentViewer/DocumentViewer';
import FileViewer from '../fileViewer/FileViewer';
import LinkTree from '../linkTreeViewer/LinkTree';
import OnlineSession from '../onlineSession/OnlineSession';
import { RecordingProvider } from '../onlineSession/RecordingContext';
import UrlViewer from '../urlViewer/UrlViewer';
import VideoPlayer from '../videoPlayer/VideoPlayer';
import YoutubeViewer from '../youtubeViewer/YoutubeViewer';
import { ResourceFrom } from '../../Views/courseView/content/Content';
import { ClassContext } from '../../Views/dashboard/UnstructuredContext';
import ScormViewer from '../ScormViewer/ScormViewer';
import ForumInstructor from '../ActivitiesInstructorView/Forum/ForumInstructor';
import ForumTimeLine from '../../Views/ForumTimeLine/ForumTimeLine';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../utils/StringManipulation";
import HtmlViewer from '../htmlViewer/HtmlViewer';
import { useRecoilState } from 'recoil';
import { adminRoleState, adminState } from '../../recoil/RecoilStates';
interface SpecificContentComponentProps {
    selectedResource: ResourceFrom | null;
    closeTimeline: () => void;
    openTimeline: () => void;
    isTimelineOpen: boolean;
    classId: string;
}

export default function SpecificContent({ selectedResource, closeTimeline, openTimeline, isTimelineOpen, classId }: SpecificContentComponentProps) {
    const { user } = useAuth0();
    const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
    const provider = auth0ProviderSplitter(user?.sub || "");
    const { role } = useContext(ClassContext);
    const [isAdmin] = useRecoilState(adminState)
    const [adminRole] = useRecoilState(adminRoleState)
    if (!selectedResource) {
        return null;
    }

    switch (selectedResource.type) {
        case "Picture":
            return (
                <ImageViewer fileId={selectedResource.refId} fileUrl={selectedResource.path || ""} />
            );
        case "Scorm":
            return (
                <ScormViewer />
            );
        case "Video":
            return (
                <VideoPlayer
                    role={role}
                    classId={classId}
                    isTimelineOpen={isTimelineOpen}
                    videoUrl={selectedResource.path || ""}
                    closeTimeline={closeTimeline}
                    openTimeline={openTimeline}
                    transcription={selectedResource.transcription || ""}
                    stretch={true}
                    videoId={selectedResource.refId}
                />
            );
        case "Document":
            return (
                <DocumentViewer role={role} documentUrl={selectedResource.path || ""} documentId={selectedResource.refId} grader={false} classId={classId} />
            );
        case "File":
            return <FileViewer fileId={selectedResource.refId || ""} />;
        case "Folder":
            return <FolderRecourses fileId={selectedResource.refId || ""} />;
        case "Link":
            if (selectedResource.isYoutube) {
                return (<YoutubeViewer linkId={selectedResource.refId} url={selectedResource.path || ""} classId={classId} />);
            } else {
                return (<UrlViewer linkId={selectedResource.refId} url={selectedResource.path || ""} />);
            }
        case "Assignment":
            return role === "student" || (isAdmin === true && adminRole === "student") || role === "auditor" ? (
                <Assignment assignmentId={selectedResource.refId} role={role || ""} />
            ) : (
                <AssignmentInstructor id={selectedResource.refId} resourceR={selectedResource} />
            );
        case "Quiz":
            return role === "student" || (isAdmin === true && adminRole === "student") || role === "auditor" ? (
                <StudentQuizView quizId={selectedResource.refId} />
            ) : (
                <QuizInstructor id={selectedResource.refId} resourceR={selectedResource} />
            );
        case "Survey":
            return role === "student" || (isAdmin === true && adminRole === "student") || role === "auditor" ? (
                <SurveyStudentCompo id={selectedResource.refId} />
            ) : (
                <SurveyInstructor id={selectedResource.refId} resourceR={selectedResource} />
            );
        case "Forum":
            return role === "student" || (isAdmin === true && adminRole === "student") || role === "auditor" ? (
                <ForumTimeLine forumId={selectedResource.refId} />
            ) : (
                <ForumInstructor id={selectedResource.refId} resourceR={selectedResource} />
            );
        case "Link_Tree":
            return (
                <LinkTree linkTreeId={selectedResource.refId} links={selectedResource?.links || []} />
            );
        case "Online_Session":
            return (
                <RecordingProvider> <OnlineSession ReceivedActivityId={selectedResource.refId} type={"Online_Session"} /> </RecordingProvider>
            );
        case "Html":
            return (<HtmlViewer HtmlId={selectedResource.refId} classId={classId} />)
        case "Teams":
            return (<OnlineSession ReceivedActivityId={selectedResource.refId} type={"Teams"} />)
        default:
            return null;
    }

}
