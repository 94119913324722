import React, { FC, useContext, useEffect, useRef, useState } from "react";
import {
  LightTypography,
  PrimaryFontBoldText,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  ForumContainer,
  HeadContainer,
  FlexCenter,
  LabelDiv,
  ForumInput,
  DateAndTimeDiv,
  BtnsDiv,
  LabelBtnsDiv,
  RadioDiv,
  RadioDivLabel,
  FlexDiv,
  GradeInput,
  ButtonsDiv,
  FlexBetween,
} from "./ForumForm.style";
import { useStateContext } from "../../contexts/ContextProvider";
import { TextEditor } from "../../elements/TextEditor/TextEditor";
import SelectDate from "../../elements/DatePicker/inputDate";
import TimePickerComponent from "../../elements/TimePicker/TimePicker";
import Toggle from "../../elements/Toogle/toogle";
import CheckBoxInput from "../../elements/checkbox/CheckBox";
import SelectInput from "../../elements/nestedSelect/SelectInput";
import { Options } from "./ForumForm.data";
import TwoDatePicker from "../../elements/TwoDatePicker/TwoDatePicker";
import CancelButton from "../../elements/StyledButton/CancelButton";
import { ReactComponent as SvgMessagingIcon } from "../../assets/icons/Messaging 1.svg";
import { ReactComponent as SvgAddIcon } from "../../assets/icons/Add.svg";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import { ReactComponent as SvgCloseIcon } from "../../assets/icons/Close1.svg";
import { useNavigate, useParams } from "react-router-dom";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import LoaderThreeDot from "../loader/loaderThreeDot/LoaderThreeDot";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { Container } from "../../elements/StyledContainerForm/StyledContainerForm.style";
import { useTranslation } from "react-i18next";
import StyledInput from "../../elements/StyledInput/StyledInput";
import { WebSocketConnector } from "../../Views/messages/webSocketConnection";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import { SvgEyeIcon } from "../../elements/Icons";
import NestedSelect from "../../elements/nestedSelect/NestedSelect";
import { handleWheel } from "../../utils/OnWheelFunction";
import { WebsocketContext } from "../../contexts/notificationContext";
import { useQuizMyContext } from "../../contexts/CourseCreatorQuizContext";
import { ForumContext } from "../../contexts/ForumContext";
import { DeleteButton } from "../AssignmentForm/AssignmentForm.style";
interface ContentFormProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
  setShowEditContent: React.Dispatch<React.SetStateAction<boolean>>;
  showEditContent: boolean;
  setContentTitleInTopic: (contentTitle: string) => void;
  editContent: (contentId: number, newTitle: string) => void;
  newContentTitle: string | undefined;
  contentId: number | undefined;
  activeGetModulesWithTopics: () => void;
  newTopicRefId: number;
}

const ForumForm: FC<ContentFormProps> = ({
  setShow,
  handleSubmit,
  setShowEditContent,
  showEditContent,
  setContentTitleInTopic,
  editContent,
  newContentTitle,
  contentId,
  activeGetModulesWithTopics,
  newTopicRefId,
}) => {
  const { t } = useTranslation();
  const classSourceId = useParams();
  const navigate = useNavigate();
  const setClassSourceId = classSourceId.id;

  const { isLoading, user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");

  const baseURL = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";
  const path = "activity/create";

  const tenantName = localStorage.getItem("tenant");

  const {
    setContent_Title,
    contentType,
    setContent_Data,
    getContent_Data,
    contentIdToEdit,
    topicRefId,
    content_Index,
    setIsPopUpOpen,
    globalShowEditContent,
    setGlobalShowEditContent
  } = useStateContext();

  const [prevData, setPrevData] = useState<any>();
  const [readyToEdit, setReadyToEdit] = useState(true);
  const [foundRefIdState, setFoundRefIdState] = useState<number>();
  const [refIdLoading, setRefIdLoading] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [error, setError] = useState(false);
  const [rubricError, setRubricError] = useState(false);
  const [rubricOptions, setRubricOptions] = useState<
    { title: string; submenu?: any }[]
  >([]);
  const {
   selectedRadio,
   setForumAttach,
   setForumCutOffDate,
   setForumDescription,
   setForumDueDate,
   setForumEndTime,
   setForumName,
   setForumFromDate,
   setGlobalForumShowPopup,
   setForumToDate,
   setForumMaxGrade,
   setForumPenalty,
   setForumSelectedRadio,
   setForumShowGradingType,
   setForumShowTimeVisibility,
   setForumStartDate,
   setForumStartTime,
   setForumVisibleStudents,
   showGradingType,
   showTimeVisibility,
   startDate,
   startTime,
   forumName,
   attach,
   maxGrade,
   endTime,
   visibleStudents,
   description,
   dueDate,
   toDate,
   fromDate,
   cutOffDate,
   penalty,
   globalForumShowPopup
  } = ForumContext();
  const {
    selectedRubricId,
    setSelectedRubricId,
  } = useQuizMyContext();
  const { isReady, value, send } = useContext(WebsocketContext);

  const formatDate = (dueDate: any) => {
    const date = new Date(dueDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const updateForum = async (path: string, body: any) => {
    try {
      const response = await axios.put(`${baseURL}/${path}/${tenantName}`, {
        data: body,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
      throw error; // Rethrow the error to be handled by the calling service
    }
  };

  const getForumToEdit = async (path: string, forumId: number) => {
    try {
      const tenantName = localStorage.getItem("tenant");
      const res = await axios.get(
        `${baseURL}/${path}/${forumId}/${tenantName}`
      );
      setPrevData(res.data.data);
      setReadyToEdit(false);

      const toReturn: any[] = res.data.forumActivitiesData;
      return toReturn;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (globalShowEditContent && forumName !== "") {
      setReadyToEdit(false);
    }
  }, [forumName]);

  useEffect(() => {
    const fetchData = async () => {
      setRefIdLoading(true)
      const yourArray = await activeGetModulesWithTopics();
      const solutionArray: any[] = Array.isArray(yourArray) ? yourArray : [];
      function findRefIdInModules(
        modulesArray: any[],
        targetContentId: number
      ) {
        for (let i = 0; i < modulesArray.length; i++) {
          const topicsArray = modulesArray[i].topicsArray;

          for (let j = 0; j < topicsArray.length; j++) {
            const contentsArray = topicsArray[j].contents;

            for (let k = 0; k < contentsArray.length; k++) {
              if (contentsArray[k].contentId === targetContentId) {
                return contentsArray[k].refId;
              }
            }
          }
        }
        // Return a default value (e.g., null) if the contentId is not found
        return null;
      }
      if (contentIdToEdit) {
        const contentIdToFind = contentIdToEdit; // Replace with the specific contentId you're looking for
        const foundRefId = findRefIdInModules(solutionArray, contentIdToFind);
        setFoundRefIdState(foundRefId);

        await getForumToEdit("activity/forum", foundRefId);
        setRefIdLoading(false)
      }
    };
    if (globalShowEditContent) {
      fetchData();
      // setReadyToEdit(false);
    }
  }, []);

  useEffect(() => {
    if (prevData && showEditContent) {
      setContent_Title(prevData.title);
      setForumName(prevData.title);
      setForumDescription(prevData.description);
      setForumMaxGrade(prevData.maxGrade);
      setForumShowGradingType(prevData.isGraded);
      setForumPenalty(prevData.penalty);

      setForumStartDate(
        prevData.startDateTime ? new Date(prevData.startDateTime) : null
      );
      setForumDueDate(prevData.dueDateTime ? new Date(prevData.dueDateTime) : null);
      setForumCutOffDate(
        prevData.cutDateTime ? new Date(prevData.cutDateTime) : null
      );

      // setStartTime(prev_Data.startTime);
      // setEndTime(prev_Data.endTime);
      setForumVisibleStudents(prevData.isVisible);
      setForumShowTimeVisibility(
        prevData.visibleFromDateTime === null ||
          prevData.VisibleToDateTime === null ||
          prevData.visibleFromDateTime === "" ||
          prevData.VisibleToDateTime === ""
          ? false
          : true
      );

      setForumFromDate(
        prevData.visibleFromDateTime
          ? new Date(prevData.visibleFromDateTime)
          : null
      );
      setForumToDate(
        prevData.VisibleToDateTime ? new Date(prevData.visibleToDateTime) : null
      );
      setForumAttach(prevData.allowAttachments);
      setSelectedRubricId(prevData.rubricId);
      const foundRubric = rubricOptions.find(
        (rubric) =>
          rubric.submenu &&
          rubric.submenu.find((item: any) => item.id === prevData.rubricId)
      );

      if (foundRubric) {
        const selectedRubric = foundRubric.submenu.find(
          (item: any) => item.id === prevData.rubricId
        );
        setSelectedTitle(selectedRubric.title);
      }
      setForumSelectedRadio(
        prevData.rubricId && prevData.rubricId !== null ? "rubric" : "grade"
      );
    }
  }, [prevData]);

  useEffect(()=>{
    if(selectedRubricId){
      const foundRubric = rubricOptions.find(
        (rubric) =>
          rubric.submenu &&
          rubric.submenu.find((item: any) => item.id === selectedRubricId)
      );

      if (foundRubric) {
        const selectedRubric = foundRubric.submenu.find(
          (item: any) => item.id === selectedRubricId
        );
        setSelectedTitle(selectedRubric.title);
      }
    }
  },[rubricOptions])

  const handleToggleChangeRubric = (value: string) => {
    setForumSelectedRadio(value); // Update the selected radio
    setForumMaxGrade("");
    setSelectedRubricId(0);
    setSelectedTitle("");
  };

  const handleSelectrubricId = (value: any) => {
    setSelectedRubricId(value); // Update the selectedTitle state
  };

  const handleDescriptionChange = (value: any) => {
    setForumDescription(value);
  };

  const handleStartTimeChange = (value: string) => {
    setForumStartTime(value);
  };

  const handleEndTimeChange = (value: string) => {
    setForumEndTime(value);
  };

  const handleToggleChangeVisible = (isChecked: boolean) => {
    setForumVisibleStudents(isChecked);
  };

  const handleToggleChangeGraded = () => {
    setForumShowGradingType(!showGradingType);
    setForumMaxGrade("");
    setSelectedTitle("");
    setSelectedRubricId(0);
    setForumDueDate(null);
    setForumPenalty("");
  };

  const handleToggleChangeAttach = (isChecked: boolean) => {
    setForumAttach(isChecked);
  };

  const handleToggleChangeTime = (isChecked: boolean) => {
    if (isChecked) {
      setForumShowTimeVisibility(true); // Show Time Visibility when the toggle is on
    } else {
      setForumShowTimeVisibility(false);
    }
  };

  const handleSelectChange = (value: any) => {
    setSelectedTitle(value); // Update the selectedTitle state
  };

  const handleTwoDatePickerChange = (start: Date | null, end: Date | null) => {
    setForumFromDate(start);
    setForumToDate(end);
  };

  const handleStartDate = (startDateTime: Date | null) => {
    setForumStartDate(startDateTime);
    setForumFromDate(startDateTime);
  };
  const handleDueDate = (dueDateTime: Date | null) => {
    setForumDueDate(dueDateTime);
  };

  const handleCutOffDate = (cutOffDateTime: Date | null) => {
    setForumCutOffDate(cutOffDateTime);
    setForumToDate(cutOffDateTime);
  };

  const isPositiveNumber = (value: string) => {
    return /^\d+(\.\d+)?$/.test(value) && parseFloat(value) >= 0;
  };

  const handleInputChange = (
    value: string,
    setStateFunction: (value: string) => void
  ) => {
    if (isPositiveNumber(value) || value === "") {
      setStateFunction(value);
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleContinueClick = async () => {
    if (
      forumName.trim() === "" ||
      description.trim() === "" ||
      (showTimeVisibility &&
        (!fromDate || !toDate) &&
        !startDate &&
        !cutOffDate) ||
      (showGradingType && selectedRadio === "grade" && !maxGrade) ||
      (showGradingType && selectedRadio === "rubric" && !selectedTitle) ||
      (startDate && dueDate && startDate >= dueDate) ||
      (startDate && cutOffDate && startDate >= cutOffDate) ||
      (dueDate && cutOffDate && dueDate > cutOffDate) ||
      (!startDate && (dueDate || cutOffDate))
    ) {
      setError(true);
      setShow(true);
      setGlobalForumShowPopup(true)
      return;
    } else {
      setError(false);
      if (!globalShowEditContent) {
        handleSubmit();
      }
      setGlobalForumShowPopup(false)
      setShow(false);
      setIsPopUpOpen(false);

      const forumData: any = {
        _id: contentIdToEdit,
        id: globalShowEditContent ? foundRefIdState : null,
        classSourceId: setClassSourceId,
        userSourceId: subId,
        provider:provider,
        contentIndex: content_Index,
        topicRefId: topicRefId,
        type: contentType,
        title: forumName,
        description: description,
        startDate: startDate,
        dueDate: dueDate,
        cutOffDate: cutOffDate,
        penalty: penalty,
        visibleStudents: visibleStudents,
        showTimeVisibility: showTimeVisibility,
        allowAttachments: attach,
        isGraded: showGradingType,
        maxGrade: maxGrade,
        fromDate: showTimeVisibility ? fromDate : null,
        toDate: showTimeVisibility ? toDate : null,
        rubricId: selectedRubricId,
      };

      try {
        if (!globalShowEditContent) {
          const response = await axios.post(
            `${baseURL}/${path}/${tenantName}`,
            {
              data: forumData,
            }
          );

          if (isReady && send) {

           send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "create",
                  title: forumName,
                  contentType: "Forum",
                  refId: response.data.data,
                  refTable: "ForumActivities",
                  subId: subId,
                  provider:provider,
                  type: "content",
                  description: cutOffDate
                    ? `Due Date: ${formatDate(cutOffDate)}`
                    : "",
                },
              })
            );
          }
        } else if (globalShowEditContent) {
          await updateForum("activity/forum/update", forumData);
          setShowEditContent(false);
          if (isReady && send) {
            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "update",
                  title: forumName,
                  contentType: "Forum",
                  subId: subId,
                  provider:provider,
                  refId: foundRefIdState,
                  refTable: "ForumActivities",
                  type: "content",
                  description: cutOffDate
                    ? `Due Date: ${formatDate(cutOffDate)}`
                    : "",
                },
              })
            );
          }
        }
        setShow(false);
        setIsPopUpOpen(false);
        setGlobalForumShowPopup(false)
        // if (!showEditContent) {
        await activeGetModulesWithTopics();
      } catch (error) {
        console.error("Axios Error:", error);
      }
    }
  };
  const { backgroundColor, themeTextColor, inputBorderColor, iconCloseColor } =
    useRecoilValue(ColorSelector);
  // Fetch quiz templates from the server and update the options for SelectInput component.
  useEffect(() => {
    const getQuizTemplate = async () => {
      try {
        const response = await axios.get(
          `https://0j157bzxcf.execute-api.eu-west-1.amazonaws.com/dev/get/all/${setClassSourceId}/${tenantName}`
        );
        const quizDataArray = response.data.data.rubricsData || [];

        // Transform the quiz data array into the format expected by SelectInput
        const transformedOptions: {
          title: any;
          value: string;
          submenu?: any;
        }[] = [
          {
            title: `${t("forum.Use Existing Rubric")}`,
            value: "Use Existing Rubric",
            submenu: quizDataArray.map((quiz: any) => ({
              title: quiz.title,
              id: quiz.id,
            })),
          },
          {
            title: `${t("forum.create a new one")}`,
            value: "Create a new one",
          },
        ];
        setRubricOptions(transformedOptions);
      } catch (error) {
        console.error("Axios Error:", error);
      } finally {
        await (activeGetModulesWithTopics && activeGetModulesWithTopics());
      }
    };

    getQuizTemplate();
  }, [setClassSourceId, tenantName]);

  const handleEditRubric = () => {
    if (!selectedTitle) {
      setRubricError(true);
    } else {
      setRubricError(false);
      navigate(
        `/courseView/Gradebook/${setClassSourceId}/RubricTableView/${selectedRubricId}`
      );
    }
  };

  return (
    <>
      <Container
        themetextcolor={themeTextColor}
        backgroundcolor={backgroundColor}
        scrollbar={inputBorderColor}
      >
        {/* <FlexBetween>
          <FlexDiv> */}
            {/* <HeadContainer> */}
            {/* The title "Discussion Forum" with a specific font size */}
            {/* <SvgMessagingIcon />
            <PrimaryFontBoldText
              text={t("forum.Discussion Forum")}
              fontSize="1.125rem"
            /> */}
            {/* </HeadContainer> */}
          {/* </FlexDiv> */}

          {/* <SvgCloseIcon
            color={iconCloseColor}
            onClick={() => {
              setShow(false);
              setIsPopUpOpen(false);
              setShowEditContent(false);
            }}
            style={{ cursor: "pointer" }}
          /> */}
        {/* </FlexBetween> */}
        {/* Section with an icon and the title "Discussion Forum" */}
        {/* <SvgMessagingIcon /> */}
        {showEditContent && readyToEdit && <LoaderThreeDot />}

        {/* Input field for the forum name */}
        <LabelDiv>
          <FlexDiv gap="0.25rem">
            <LightTypography text={t("forms.Title")} fontSize="0.875rem" />
            <span>*</span>
          </FlexDiv>
          <StyledInput
            type="text"
            placeholder={t("forum.Enter Forum Title")}
            width="100%"
            value={forumName}
            onChange={(e: any) => {
              setForumName(e.target.value);
              setContent_Title(e.target.value);
              setContentTitleInTopic(e.target.value);
            }}
          />
          {forumName.trim() === "" && error && (
            <ErrorDiv>{`${t("forms.Please")} ${t(
              "forum.Enter Forum Title"
            )}`}</ErrorDiv>
          )}
        </LabelDiv>

        {/* Text editor for the forum description */}
        <LabelDiv>
          <FlexDiv gap="0.25rem">
            <LightTypography
              text={t("forms.Description")}
              fontSize="0.875rem"
            />
            <span>*</span>
          </FlexDiv>
          <div>
            <TinyEditor
              onChange={handleDescriptionChange}
              placeholder={t("forms.Description")}
              initialValue={description}
              height="13.25rem"
            />
          </div>
          {description.trim() === "" && error && (
            <ErrorDiv>{`${t("forms.Please")} ${t(
              "forms.Enter Description"
            )}`}</ErrorDiv>
          )}
        </LabelDiv>
        <LabelBtnsDiv>
          <Toggle
            checked={showGradingType}
            onToggle={handleToggleChangeGraded}
            id="roleToggle2"
          />
          <TypographyRegularDescription
            text={t("forms.Graded")}
            fontSize="1rem"
          />
        </LabelBtnsDiv>
        {showGradingType ? (
          <>
            <PrimaryFontBoldText
              text={t("assignment.Grading type")}
              fontSize="1rem"
            />
            <FlexDiv gap="1.25rem">
              <RadioDivLabel onClick={() => handleToggleChangeRubric("grade")}>
                <CheckBoxInput
                  name="gradeType"
                  value="grade"
                  checked={selectedRadio === "grade"}
                  onChange={() => handleToggleChangeRubric("grade")}
                />
                <LightTypography
                  text={t("assignment.Grade")}
                  fontSize="0.875rem"
                />
              </RadioDivLabel>
              <RadioDivLabel onClick={() => handleToggleChangeRubric("rubric")}>
                <CheckBoxInput
                  name="gradeType"
                  value="rubric"
                  checked={selectedRadio === "rubric"}
                  onChange={() => handleToggleChangeRubric("rubric")}
                />
                <LightTypography
                  text={t("assignment.Rubric")}
                  fontSize="0.875rem"
                />
              </RadioDivLabel>
            </FlexDiv>

            <RadioDiv>
              {selectedRadio === "grade" ? (
                <LabelDiv>
                  <FlexDiv gap="0.25rem">
                    <LightTypography
                      text={t("assignment.Maximum grade")}
                      fontSize="1rem"
                    />
                    <span>*</span>
                  </FlexDiv>
                  <StyledInput
                    // themeTextColor={themeTextColor}
                    // inputBorderColor={inputBorderColor}

                    width="27.5rem"
                    type="number"
                    value={maxGrade}
                    onChange={(e) =>
                      handleInputChange(e.target.value, setForumMaxGrade)
                    }
                    placeholder={t("assignment.Maximum grade")}
                  />
                  {selectedRadio === "grade" && !maxGrade && error && (
                    <ErrorDiv>
                      {t("course view.Please Enter a valid Maximum Grade")}
                    </ErrorDiv>
                  )}
                </LabelDiv>
              ) : (
                ""
              )}

              {selectedRadio === "rubric" ? (
                <LabelDiv>
                  <FlexDiv gap="0.25rem">
                    <LightTypography
                      text={t("Rubric.Rubric")}
                      fontSize="0.875rem"
                    />
                    <span>*</span>
                    <SvgEyeIcon
                      onClick={handleEditRubric}
                      style={{ color: "#5DD3B3", cursor: "pointer" }}
                    />
                  </FlexDiv>
                  <NestedSelect
                    onChange={handleSelectChange}
                    onIdChange={handleSelectrubricId}
                    placeholder="Use Rubric"
                    fontSize="0.875rem"
                    width="27.5rem"
                    paddingRL="1.25rem"
                    options={rubricOptions}
                    value={selectedTitle}
                    redirectUrl={`/courseView/Gradebook/${setClassSourceId}/Rubric`}
                    setGlobalAssignmentShowPopup={setGlobalForumShowPopup}
                  />
                  {selectedRadio === "rubric" &&
                  !selectedTitle &&
                  (error || rubricError) ? (
                    <ErrorDiv>{t("forms.Please select a rubric")}</ErrorDiv>
                  ) : (
                    ""
                  )}
                </LabelDiv>
              ) : (
                ""
              )}
            </RadioDiv>
          </>
        ) : (
          ""
        )}

        {/* Date and time section */}
        <DateAndTimeDiv>
          <LabelDiv>
            <LightTypography text={t("forms.Start Date")} fontSize="0.875rem" />
            {/* <div className="input-container"> */}
            <SelectDate
              width="27.5rem"
              value={startDate}
              onChange={handleStartDate}
            />
            {/* </div> */}
          </LabelDiv>
          <LabelDiv>
            <LightTypography text={t("forms.Cut-off")} fontSize="0.875rem" />
            {/* <div className="input-container"> */}
            <SelectDate
              width="27.5rem"
              value={cutOffDate}
              onChange={handleCutOffDate}
            />
            {/* </div> */}
          </LabelDiv>
        </DateAndTimeDiv>
        {showGradingType ? (
          <DateAndTimeDiv>
            <LabelDiv>
              <LightTypography text={t("forms.Due Date")} fontSize="0.875rem" />

              <SelectDate
                width="27.5rem"
                value={dueDate}
                onChange={handleDueDate}
              />
            </LabelDiv>
            {dueDate && (
              <LabelDiv>
                <LightTypography
                  text={t("assignment.Penalty Percentage")}
                  fontSize="1rem"
                />
                <StyledInput
                  type="number"
                  width="27.5rem"
                  value={penalty}
                  withPercentage
                  rightPlaceholder="%"
                  onChange={(e) =>
                    handleInputChange(e.target.value, setForumPenalty)
                  }
                />
              </LabelDiv>
            )}
            {/* {!dueDate && !penalty && error && (
          <ErrorDiv>Please Enter Penalty percentage</ErrorDiv>
        )} */}
          </DateAndTimeDiv>
        ) : (
          ""
        )}

        {startDate && dueDate && startDate >= dueDate && error && (
          <ErrorDiv>
            {t("course view.Due date must be greater than the start date")}
          </ErrorDiv>
        )}
        {cutOffDate && dueDate && dueDate > cutOffDate && error && (
          <ErrorDiv>
            {t("course view.CutOff Date must be greater than the due date.")}
          </ErrorDiv>
        )}
        {startDate && cutOffDate && startDate >= cutOffDate && error && (
          <ErrorDiv>
            {t("course view.Cut Off Date must be greater than the start date.")}
          </ErrorDiv>
        )}
        {!startDate && (dueDate || cutOffDate) && error && (
          <ErrorDiv>{t("course view.Please Select Start Date.")}</ErrorDiv>
        )}

        {/* Buttons for toggling visibility and grading */}
        <BtnsDiv>
          <LabelBtnsDiv>
            <Toggle
              onToggle={handleToggleChangeVisible}
              id="roleToggle1"
              checked={visibleStudents}
            />
            <TypographyRegularDescription
              text={t("forms.Make Visible for Students")}
              fontSize="1rem"
            />
          </LabelBtnsDiv>
          <LabelBtnsDiv>
            <Toggle
              onToggle={handleToggleChangeTime}
              id="roleToggle4"
              checked={showTimeVisibility}
            />
            <TypographyRegularDescription
              text={t("forms.Set Time Visibility Interval")}
              fontSize="1rem"
            />
            {showTimeVisibility && (
              <>
                <TwoDatePicker
                  onDateChange={handleTwoDatePickerChange}
                  prevStartDate={fromDate}
                  prevEndDate={toDate}
                />
                {(!fromDate || !toDate) &&
                  !startDate &&
                  !cutOffDate &&
                  error && (
                    <ErrorDiv>{`${t("forms.Please")} ${t(
                      "forms.Set Time Visibility Interval"
                    )}`}</ErrorDiv>
                  )}
              </>
            )}
          </LabelBtnsDiv>

          {/* Section for selecting grading type */}

          {/* Buttons for allowing attachments and setting time visibility interval */}

          <LabelBtnsDiv>
            <Toggle
              onToggle={handleToggleChangeAttach}
              id="roleToggle3"
              checked={attach}
            />
            <TypographyRegularDescription
              text={t("forum.Allow Attachments")}
              fontSize="1rem"
            />
          </LabelBtnsDiv>
        </BtnsDiv>
        {/* <button style={{ width: "5rem" }} onClick={handleContinueClick}>
          Continue
        </button> */}
        <ButtonsDiv>
          <CancelButton
            name={t("forms.Cancel")}
            onClickFunction={() => {
              setShow(false);
              setIsPopUpOpen(false);
              setShowEditContent(false);
              setGlobalShowEditContent(false)
              setGlobalForumShowPopup(false);
            }}
            color="#D85D66"
            borderColor="#D85D66"
            backgroundColor="transparent"
          />
          {!refIdLoading ?
          <CancelButton
          name={t("forms.Save")}
          onClickFunction={() => {
            // if (!error) {
            handleContinueClick();
            setGlobalForumShowPopup(false);
            setShowEditContent(false);
            setContent_Title(forumName);
            setGlobalShowEditContent(false)
            // }
          }}
          color="#fff"
          borderColor="#5DD3B3"
          backgroundColor="#5DD3B3"
        />
           :
           <DeleteButton
              onClick={() => {
              }}
            >
              {" "}
              {`${t("general.loading")}...`}
            </DeleteButton>
           }
          
        </ButtonsDiv>
      </Container>
    </>
  );
};

export default ForumForm;
