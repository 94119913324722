import { PaymentsFinallyRenderInterface } from "./PaymentsFinallyRenderInterface";

export const PaymentsFinallyRenderData : PaymentsFinallyRenderInterface[] = [
  {PackageId:"aswdefrt54reds",
  PackageName:"Start Package",
  PackagePrice:19.00,
  Features:[
    {FeaturesText: "Access academic courses"},
    {FeaturesText: "Skill Assessment tools"},
    {FeaturesText: "Access personalized Profile & learning Path "},
    {FeaturesText: "Store/view verified academic certifications: (up to 1 certificate*)"},
    {FeaturesText: "Access Alumni Hub network to virtually connect with fellow learners"},
    {FeaturesText: "Benefit from an early talent recruitment portal "},
    {FeaturesText: "Benefit from an early talent recruitment portal "},
  ]
},

{PackageId:"aDFGR43EDFGBRE",
PackageName:"Growth Package",
PackagePrice:29.00,
Features:[
  {FeaturesText: "All Starter Features +"},
  {FeaturesText: "store/view verified academic certifications (up to 5 certificates)"},
  {FeaturesText: "Premium Internships: Get a chance to work at top organizations"},
  {FeaturesText: "Smart Career Matching: Get notified for opportunities that align with your career Goal)"},
  {FeaturesText: "CV Generator"},
  {FeaturesText: "Premium: Skill Assessment tools"},
]
},

{PackageId:"de3rtghtres",
PackageName:"Scale Package",
PackagePrice:49.00,
Features:[
  {FeaturesText: "All Growth Features +"},
  {FeaturesText: "Unlimited Store/view verified certifications"},
  {FeaturesText: "Unlimited access & downloads to Libraries to research, citations and E-books"},
  {FeaturesText: "Unlimited access to live Classrooms on the LXP™️"},
  {FeaturesText: "Unlimited ZCo-Pilot AI Features:",
  FeaturesData:["Biography Generator",
  "Assignment pre-evaluation and feedback: Deter cheating by generating unique summative assessments for each test-taker and streamline essay grading",
  "Image explanation Generator","AI Summarization online session",
  "AI Key Phrase highlighting",
  "Teacher support: Lesson planning, instructional coaching, grading, personalized feedback",
  "Classroom Material: AI assists in crafting activity specific handouts, customize reading materials and formulating student questions",
  "Student support: students can take notes with AI assistance and receive precise assessment to address literacy needs",
  "Predictive Analytics"]


},

]
},

]