import React, { useContext, useEffect, useState } from "react";
import {
  ContentTab,
  ContentTabCont,
  DashboardContentsHolder,
} from "./DashboardContents.style";
import { Typography } from "../../../elements/fonts/Fonts";
import { Module } from "../../CourseTimeline/utils/CourseResourcesInterface";
import axios from "axios";
import ResourcesPerModule from "../ResourcesPerModule/resourcesPerModuleMain/ResourcesPerModule";
import { LoadingNoResources } from "../ResourcesPerModule/resourcesPerModuleMain/ResourcesPerModule.style";
import { SvgEmpty, SvgNoResult } from "../../../elements/Icons";
import ActivitiesPerModule from "../ActivitiesPerModule/ActivitiesPerModule";
import { auth0SubIdSplitter } from "../../../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import { filterContentsByCategory } from "./DashboardContentUtils";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  CourseColorSelector,
} from "../../../recoil/ThemeSelectors";
import { isDateInRange } from "../isDateInRange";
import { useStateContext } from "../../../contexts/ContextProvider";
interface dashboardContentProps {
  courseId: string;
}
//course content after pressing on content icon in the course card
export default function DashboardContents({ courseId }: dashboardContentProps) {
  function sortModules(data: Module[]) {
    // Sort modules based on moduleOrder
    data.sort((a, b) => a.moduleIndex - b.moduleIndex);

    // Sort topics within each module
    data.forEach((module) => {
      module.topicsArray.sort(
        (a, b) => (a.topicIndex || 0) - (b.topicIndex || 0)
      );

      // Sort and filter contents within each topic
      module.topicsArray.forEach((topic) => {
        const contents = topic.contents || [];

        const filteredContents = contents.filter((content) => {
          const isVisible = isDateInRange(
            content.visibleFrom,
            content.visibleTo,
            content.isVisible
          );
          return isVisible;
        });
        // Sort the remaining contents by contentIndex
        filteredContents.sort(
          (a, b) => (a.contentIndex || 0) - (b.contentIndex || 0)
        );
        // Update the topic's contents with the sorted filtered contents
        topic.contents = [...filteredContents];
      });
    });

    return data;
  }

  const [data, setData] = useState<Module[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState(null);
  const [resourcesData, setResourcesData] = useState<Module[]>([]);
  const [activitiesData, setActivitiesData] = useState<Module[]>([]);
  const [loading2, setLoading2] = useState(true);
  const [ResourcesTab, setResourcesTab] = useState(true);
  const [ActivitiesTab, setActivitiesTab] = useState(false);
  const { user } = useAuth0();
  // const { role, setRole } = useContext(ClassContext);
  const { t } = useTranslation();
  //activate clicked tab (2 tabs activities and resources)
  const handleResourcesClick = (index: any) => {
    setActiveTab(index);
    if (index === 0) {
      setResourcesTab(true);
      setActivitiesTab(false);
    } else {
      setResourcesTab(false);
      setActivitiesTab(true);
    }
  };
  //fetching course content data
  useEffect(() => {
    setActiveTab(null);
    setResourcesTab(true);
    setActivitiesTab(false);
    handleResourcesClick(0);
    // setData([]);
    const tenantName = localStorage.getItem("tenant");
    const fetchData = async () => {
      setLoading(true);
      const isParentClass = await axios.get(
        `https://bo39qdpjrc.execute-api.eu-west-1.amazonaws.com/dev/class/merge/type/${courseId}/${tenantName}`
      );

      try {
        let response;
        if (isParentClass.data.data[0].isChild === true)
          response = await axios.get(
            `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/class/module/all/${isParentClass.data.data[0].parentSourceId}/${tenantName}/`
          );
        else
          response = await axios.get(
            `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/class/module/all/${courseId}/${tenantName}/`
          );

        if (response.data.data) {
          if (
            response.data.data.length === 1 &&
            response.data.data[0].topicsArray.length === 1 &&
            response.data.data[0].topicsArray[0].contents.length === 0
          ) {
            setData([]);
          } else {
            setData(sortModules(response.data.data));
            //filter content based on category resource/activity
            setResourcesData(
              filterContentsByCategory(response.data.data, "Resource")
            );
            setActivitiesData(
              filterContentsByCategory(response.data.data, "Activity")
            );

            setLoading(false);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again.");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    // const fetchData2 = async () => {
    //   try {
    //     const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
    //     console.log("user", subIdSplitted);
    //     const response = await axios.get(
    //       `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/enrollments/user/role/${tenantName}/${courseId}/${subIdSplitted}`
    //     );
    //     console.log("role in dashboardcontent  ", response.data.data.role);

    //     // setData(response.data.data);

    //     setRole(response.data.data.role);

    //     setLoading2(false);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //     setError("Error fetching data. Please try again.");
    //     setLoading2(false);
    //   }
    // };
    fetchData();
    // fetchData2();
  }, [courseId]);
  const { ClassGradientBox, ActiveTabBgColor } =
    useRecoilValue(CourseColorSelector);
  const { Pagination_Item_Color, boxShadowBanner } =
    useRecoilValue(ColorSelector);
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "0" }}>
      {data.length > 0 && (
        <ContentTabCont style={{ display: "flex", paddingLeft: "2.37rem" }}>
          <ContentTab
            activeTab={0}
            onClick={() => handleResourcesClick(0)}
            style={{
              background: activeTab === 0 ? ActiveTabBgColor : "transparent",
              color: activeTab === 0 ? "#5dd3b3" : Pagination_Item_Color,
            }}
          >
            <Typography text={t("dashboard.Resources")} fontSize="0.75rem" />
          </ContentTab>
          <ContentTab
            onClick={() => handleResourcesClick(1)}
            activeTab={1}
            style={{
              background: activeTab === 1 ? ActiveTabBgColor : "transparent",
              color: activeTab === 1 ? "#5dd3b3" : Pagination_Item_Color,
            }}
          >
            <Typography text={t("dashboard.Activities")} fontSize="0.75rem" />
          </ContentTab>
        </ContentTabCont>
      )}
      <DashboardContentsHolder
        bgColor={ClassGradientBox}
        boxShadow={boxShadowBanner}
      >
        {!loading ? (
          <>
            {data.length > 0 ? (
              <>
                {/* resources content */}
                {ResourcesTab && (
                  <ResourcesPerModule
                    Contentdata={resourcesData}
                    courseId={courseId}
                  />
                )}
                {/* activities content */}
                {ActivitiesTab && (
                  <ActivitiesPerModule
                    Contentdata={activitiesData}
                    courseId={courseId}
                  />
                )}
                {!ResourcesTab && !ActivitiesTab && (
                  <LoadingNoResources>
                    <SvgNoResult width={"10rem"} />
                    <Typography text="Please select resources/activities tab to get started" />
                  </LoadingNoResources>
                )}
              </>
            ) : (
              <LoadingNoResources>
                <SvgNoResult width={"10rem"} />
                <Typography text={t("dashboard.No Resources/activities Yet")} />
              </LoadingNoResources>
            )}
          </>
        ) : (
          <LoadingNoResources>
            <SvgNoResult width={"10rem"} />
            <Typography text={`${t("general.loading")}...`} />
          </LoadingNoResources>
        )}
      </DashboardContentsHolder>
    </div>
  );
}
