import styled from 'styled-components'

export const DiscussionContainer=styled.div`
width: 40rem;
border: 1px dashed #576371;
@media (max-width: 700px) {
    width: auto;
  }
`;

export const MainContainer=styled.div`
/* padding: 1.25rem 2.5rem 2.5rem 2.5rem; */
`;

export const DiscussionContentContainer=styled.div`
display: flex;
gap:1.25rem;
padding: 1.25rem;
`; 
export const MessageContainer=styled.div`
display: flex;
flex-direction:column;
gap:0.62rem;
`;

export const ImageElment=styled.img`
width: 3.125rem;
height: 3.125rem;

`;