import { ActionsHeaderText } from "../../../interfaces/AnalyticsCommonInterfaces";

export interface CompetencyStudentFrameworksInterface {
  CompetencyName?: string;
  Competencies?:{
    Module?:string;
    Activity?:string;
    Poor?:any;
    Intermediate?:any;
    Advanced?:any;
    Level?:string;
  }[];
}


export const CompetencyStudentData : CompetencyStudentFrameworksInterface[] = [ 
{CompetencyName:"CUSTOMER ORIENTATION",
Competencies:[
  {Module:"Module 1",
  Activity:"Activity 1",
  Poor:"0 - 44 %",
  Intermediate:"45 - 70 %",
  Advanced:"71- 100 %",
  Level: "Advanced",
},
{Module:"Module 1",
Activity:"Quiz 1",
Poor:"0 - 49 %",
Intermediate:"50 - 75 %",
Advanced:"76 - 100 %",
Level: "Advanced",
},
{Module:"Module 2",
Activity:"Forum 1",
Poor:"0 - 44 %",
Intermediate:"45 - 70 %",
Advanced:"71 - 100 %",
Level: "Advanced",
},
]
},
{CompetencyName:"PROBLEM SOLVING",
Competencies:[
  {Module:"Module 2",
  Activity:"Assignment 1",
  Poor:"0 - 59 %",
  Intermediate:"60 - 79 %",
  Advanced:"80- 100 %",
  Level: "Intermediate",
},
{Module:"Module 3",
Activity:"Quiz 3",
Poor:"0 - 49 %",
Intermediate:"50 - 75 %",
Advanced:"76 - 100 %",
Level: "Intermediate",
},
{Module:"Module 3",
Activity:"Forum 3",
// Poor:"0 - 44 %",
// Intermediate:"45 - 70 %",
// Advanced:"71 - 100 %",
Level: "Poor",
},
]
},
{CompetencyName:"DECISION MAKING",
Competencies:[
  {Module:"Module 1",
  Activity:"Assignment 3",
  Poor:"0 - 59 %",
  Intermediate:"60 - 79 %",
  Advanced:"80- 100 %",
  Level: "Poor",
},
{Module:"Module 1",
Activity:"Survey",
// Poor:"0 - 49 %",
// Intermediate:"50 - 75 %",
// Advanced:"76 - 100 %",
Level: "Advanced",
},

]
},
];

export const CompetencyStudentHeaderData : ActionsHeaderText[] = [
  {id:"sdfrgthytgr",
  text:"Competencies",
},
{id:"swertyukujyhtgr",
text:"Module",
},
{id:"gtyuytr",
text:"Activity",
},
{id:"sdfrgthytgr",
text:"Poor",
},
{id:"sdfghjkiujh",
text:"Intermediate",
},
{id:"defrgtyu7uytergrg",
text:"Advanced",
},
{id:"fghtyjuyt",
text:"Your Level",
},
 ]