import React, { useEffect, useState } from "react";
import { SurveyData } from "../AnalyticsSurveyPiesTwoValues/AnalyticsSurveyPiesTwoValues";
import { AnalyticsSurveyPiesTwoValuesContainer } from "../AnalyticsSurveyPiesTwoValues/AnalyticsSurveyPiesTwoValues.style";
import PieWithText from "../../Charts/PieWithText/PieWithText";
import PieText from "../../Charts/PieText/PieText";

interface AnalyticsSurveyPiesRatingInterface {
  data: SurveyData; // Use the SurveyData interface here
}

const AnalyticsSurveyPiesRating = ({
  data,
}: AnalyticsSurveyPiesRatingInterface) => {
  const [PieChartData, setPieChartData] = useState([
    {
      text: "Answered",
      val: 0,
      bgColor: "#5DD3B3",
    },
    {
      text: "Unattempted",
      val: 0,
      bgColor: "#0B8FFF",
    },
  ]);

  useEffect(() => {
    const OneCount = data.students.filter(
      (item) => typeof item.Answered === "number" && item.Answered === 1
    ).length;
    const TwoCount = data.students.filter(
      (item) => typeof item.Answered === "number" && item.Answered === 2
    ).length;
    const ThreeCount = data.students.filter(
      (item) => typeof item.Answered === "number" && item.Answered === 3
    ).length;
    const FourCount = data.students.filter(
      (item) => typeof item.Answered === "number" && item.Answered === 4
    ).length;
    const FiveCount = data.students.filter(
      (item) => typeof item.Answered === "number" && item.Answered === 5
    ).length;
    const NoAnswerCount = data.students.filter((item) => !item.Answered).length;

    setPieChartData([
      {
        text: "Five Stars",
        val: FiveCount,
        bgColor: "#5DD3B3",
      },
      {
        text: "Four Stars",
        val: FourCount,
        bgColor: "#0B8FFF",
      },
      {
        text: "Three Stars",
        val: ThreeCount,
        bgColor: "#5DC5D3",
      },
      {
        text: "Two Stars",
        val: TwoCount,
        bgColor: "#CBD35D",
      },
      {
        text: "One Star",
        val: OneCount,
        bgColor: "#D85D66",
      },
      {
        text: "No Answer",
        val: NoAnswerCount,
        bgColor: "#8A2BE2",
      },
    ]);
  }, [data]);

  return (
    <AnalyticsSurveyPiesTwoValuesContainer>
      <PieWithText
        data={PieChartData}
        value={"val"}
        getColor={(entry) => entry.bgColor as string}
        Width="65%"
      />
      <PieText
        LoopName={PieChartData}
        ShowMiniInfo={false}
        title={data.QuestionText}
      />
    </AnalyticsSurveyPiesTwoValuesContainer>
  );
};

export default AnalyticsSurveyPiesRating;
