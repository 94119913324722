import React, { useRef } from "react";
import {
  FileInputButtonIcon,
  FileInputButton,
  HiddenInput,
} from "./StyledSelect.style";
import { FileInputProps } from "./StyledSelectInterface";
import { LightTypography } from "../../../elements/fonts/Fonts";

const ProfileFileSelected = ({
  onChange,
  title,
  acceptedFileTypes,
  icon: IconComponent,
}: FileInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    onChange(files);
  };

  return (
    <FileInputButton>
      <FileInputButtonIcon>
        <IconComponent />
      </FileInputButtonIcon>
      <LightTypography text={title} fontSize="0.75rem" />

      <HiddenInput
        type="file"
        ref={inputRef}
        onChange={handleFileChange}
        accept={acceptedFileTypes}
      />
    </FileInputButton>
  );
};

export default ProfileFileSelected;
