import styled from "styled-components";
interface BoxWidth {
  width?: string;
  height?: string;
  justifyContent?: string;
}
export const ModuleEmpty = styled.div<BoxWidth>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  z-index: 1;
  /* background-color:#fff; */
  height: ${({ height }) => height || "fit-content"};
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
`;

export const TextBobSizePlace = styled.div<BoxWidth>`
  display: flex;
  text-align: center;
  width: ${({ width }) => width || "none"};
  text-transform: capitalize;
  justify-content: center;
`;
