import React, { useEffect, useState } from "react";
import { AnalyticsSurveyPiesTwoValuesContainer } from "./AnalyticsSurveyPiesTwoValues.style";
import PieWithText from "../../Charts/PieWithText/PieWithText";
import PieText from "../../Charts/PieText/PieText";

// Define interface for SurveyData
export interface SurveyData {
  QuestionCode: string;
  QuestionTitle: string;
  QuestionText: string;
  QuestionType: string;
  students: {
    StdCode: string;
    StdName: string;
    TimeSpent: number;
    Answered: boolean;
  }[];
}

// Define interface for AnalyticsSurveyPiesTwoValues
interface AnalyticsSurveyPiesTwoValuesInterfaceData {
  data: SurveyData; // Use the SurveyData interface here
}

// AnalyticsSurveyPiesTwoValues Component
const AnalyticsSurveyYesNoTrueFalse = ({
  data,
}: AnalyticsSurveyPiesTwoValuesInterfaceData) => {
  // useEffect to log data
  const QuestionType = data.QuestionType;
  // State for PieChartData
  const [PieChartData, setPieChartData] = useState([
    {
      text: "Answered",
      val: 0,
      bgColor: "#5DD3B3",
    },
    {
      text: "Unattempted",
      val: 0,
      bgColor: "#0B8FFF",
    },
    {
      text: "No Answer",
      val: 0,
      bgColor: "#0B8FFF",
    },
  ]);

  // useEffect to update PieChartData based on data
  useEffect(() => {
    // Calculate attempted count
    const attemptedCount = data.students.filter(
      (item) =>
        item.Answered === true ||
        (typeof item.Answered === "string" &&
          (item.Answered as string) === "Yes") ||
        (typeof item.Answered === "boolean" &&
          (item.Answered as boolean) === true)
    ).length;

    // Calculate unattempted count
    const unattemptedCount = data.students.filter(
      (item) =>
        item.Answered === false ||
        (typeof item.Answered === "boolean" &&
          (item.Answered as boolean) === false) ||
        (typeof item.Answered === "string" &&
          (item.Answered as string) === "No")
    ).length;
    const NoAnswerCount = data.students.filter((item) => !item.Answered).length;

    // Update PieChartData state with new data
    setPieChartData([
      {
        text: QuestionType === "Yes/No" ? "Yes" : "True",
        val: attemptedCount,
        bgColor: "#5DD3B3",
      },
      {
        text: QuestionType === "True/False" ? "No" : "False",
        val: unattemptedCount,
        bgColor: "#0B8FFF",
      },
      {
        text: "Not Answered",
        val: NoAnswerCount,
        bgColor: "#8A2BE2",
      },
    ]);
  }, [data]);

  return (
    <AnalyticsSurveyPiesTwoValuesContainer>
      {/* PieWithText Component */}
      <PieWithText
        data={PieChartData}
        value={"val"}
        getColor={(entry) => entry.bgColor as string}
        Width="65%"
      />

      {/* PieText Component */}
      <PieText
        LoopName={PieChartData}
        ShowMiniInfo={false}
        title={data.QuestionText}
      />
    </AnalyticsSurveyPiesTwoValuesContainer>
  );
};

export default AnalyticsSurveyYesNoTrueFalse;
