import React, { PureComponent, useEffect } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { SvgCertificate } from "../../../elements/Icons";

const RADIAN = Math.PI / 180;

const BIGenderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  attendance,
}: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  attendance: number;
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const svgX = cx + radius * Math.cos(-midAngle * RADIAN) - 15;
  const svgY = cy + radius * Math.sin(-midAngle * RADIAN) - 27;
  const SmallTextX = cx + radius * Math.cos(-midAngle * RADIAN) - 5;
  const SmallTextY = cy + radius * Math.sin(-midAngle * RADIAN) + 18;

  if (percent === 0) {
    return null; // Don't display anything if percent is zero
  }

  return (
    <g textAnchor="middle">
      <text
        x={x}
        y={y}
        fill="#FFF"
        dominantBaseline="central"
        style={{
          pointerEvents: "none",
          fontFamily: "Primary Font-Bold",
          fontSize: "0.875rem",
        }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>

      <g
        transform={`translate(${svgX},${svgY})`}
        style={{ pointerEvents: "none" }}
      >
        <SvgCertificate /> {/* Add SvgAdd component below the text */}
      </g>

      <text
        x={SmallTextX}
        y={SmallTextY}
        fill="#FFF"
        dominantBaseline="central"
        style={{
          pointerEvents: "none",
          fontFamily: "Primary Font",
          fontSize: "0.75rem",
        }}
      >
        {attendance}
      </text>
    </g>
  );
};

const OnlyTextCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (percent === 0) {
    return null; // Don't display anything if percent is zero
  }

  return (
    <g>
      <text
        x={x}
        y={y}
        fill="#FFF"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        style={{
          pointerEvents: "none",
          fontFamily: "Primary Font-Bold",
          fontSize: "0.875rem",
        }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </g>
  );
};

interface DashedChartProps {
  value?: string;
  data: any[];
  getColor: (entry: any) => string;
  Width?: string;
  NormalTextDisplay?: boolean;
  OuterRadius?: number;
  InnerRadius?: number;
  SimpleChoiceTextEquation?: boolean;
}

export default class PieWithText extends PureComponent<DashedChartProps> {
  static demoUrl =
    "https://codesandbox.io/s/pie-chart-with-customized-label-dlhhj";

  // useEffect(() => {
  //   console.log("PeiData  isss");

  //   }, [data])
  render() {
    const {
      data,
      value = "val",
      getColor,
      Width = "100%",
      NormalTextDisplay = true,
      OuterRadius = 150,
      InnerRadius = 50,
    } = this.props;

    return (
      <ResponsiveContainer width={Width} height="100%">
        <PieChart>
          {/* <Tooltip /> */}
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={
              NormalTextDisplay
                ? OnlyTextCustomizedLabel
                : BIGenderCustomizedLabel
            }
            outerRadius={OuterRadius}
            innerRadius={InnerRadius}
            fill="#8A2BE2"
            dataKey={value}
            stroke="none"
            strokeWidth={0}
          >
            {data.map((entry, index) =>
              entry[value] > 0 ? (
                <Cell key={`cell-${index}`} fill={getColor(entry)} />
              ) : null
            )}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
