import styled from "styled-components";

export const RuricCratieriaText = styled.div`
display:flex;
flex-direction:column;
width:100%;
gap:0.63rem;
padding-left:1rem;


`;

export const RuricCratieriaMine = styled.div`
display:flex;
flex-direction:column;
width:100%;
/* gap:0.63rem; */

`;
export const OneRowViewShapeDiv = styled.div`
display:flex;
/* background-color:yellow; */
/* flex:1; */
/* width:5rem; */
height:2rem;
/* margin-top:0.5rem;
margin-left:0.5rem; */
padding-left:1rem;
align-items:center;
`;

export const ShapeDivB = styled.div`
display:flex;
/* background-color:aqua; */
flex-direction:column;
gap:0.60rem;
flex:100%;

/* width:5rem; */
`;
export const ShapeDivBTextFormat = styled.div`
display:flex;

gap:0.60rem;
width:90%;
gap:0.70rem;
justify-content:space-between;


`;
export const ShapeDivBTextFormatColumn = styled.div`
display:flex;
flex-direction:column;
gap:0.60rem;
width:100%;
/* gap:0.70rem; */
justify-content:space-between;

/* width:5rem; */
`;

export const OneRowHeaderDiv = styled.div`
display:flex;
height:2rem;
justify-content:space-between;
align-items:center;
width:90%;
`;

export const OneRowHeaderDeleteDiv = styled.div`
display:flex;
cursor: pointer;
width:2rem;
display: flex;
justify-content:center;
`;

export const RubricTileRowEditLayout = styled.div`
display:flex;
flex:100%;
justify-content:space-between;
gap:0.5rem;

 padding-right:1.3rem;
 /* background-color:yellow; */

`;