import styled from "styled-components";
import { theme } from "../../theme";

export const CircularProgress = styled.div<{progress: number}>`
  position: relative;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: conic-gradient(#5dd3b3 3.6deg, #20272E ${(props) => props.progress}%);
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: ${theme.dark.bg_main_color};
  }
`;

export const ProgressValue = styled.div`
position: relative;
`;
