import styled from "styled-components";
import { breakpoints } from "../../../theme";

export const TimeLineModule = styled.div<{ bgColor?: string }>`
  cursor: pointer;
  /* background-color: #576371; */
  background: ${(props) => props.bgColor || " #576371"};
  /* background-color:green; */
  border-radius: 0rem 0rem 0.625rem 0.625rem;
  /* padding: 1.2rem 1.25rem; */
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  row-gap: 0.44rem;
  @media (max-width: ${breakpoints.mobile}) {
    border-top: none;
    border-radius: 0.5rem;
    background: #384450;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  }
`;

export const TimeLineModuleTop = styled.div`
display: flex;
justify-content: space-between;
flex-direction:column;
  height:4.5rem;
  padding: 0.9rem 1.25rem 0.94rem;
`;

export const TimeLineModuleTopTitle = styled.div`

  display: flex;
  justify-content: space-between;
  align-items: center;
  word-wrap: nowrap;
`

export const TopicsHolder = styled.div`
  @media (max-width: ${breakpoints.mobile}) {
    & > :first-child {
      margin-top: -1rem;
    }
    & > :last-child {
      border-bottom-left-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
    }
  }
`;