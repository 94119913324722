import styled from "styled-components";
import { theme } from "../../../theme";

export const MenuWrapper = styled.div``;

export const Frame = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.94rem;
  height: 1.6rem;

  &:dir(ltr) {
    padding: 1rem 0 1rem 0.5rem;
  }
  &:dir(rtl) {
    padding: 1rem 0.5rem 1rem 0;
  }
  border-radius: 0.5rem;
  &:hover {
    background: linear-gradient(
      to right,
      ${theme.dark.gradient_color_submenu[0]},
      ${theme.dark.gradient_color_submenu[1]}
    );
    color: #fff;
  }
`;

export const Icon = styled.img`
  width: 1rem;
  height: 1rem;
`;
export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  /* row-gap: 0.7rem; */
`;
