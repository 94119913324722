import { FaqHolder, FaqTitle, FaqWrapper } from "./Faq.style";
import FaqRectangle from "../../elements/faqRectangle/FaqRectangle";
import { FaqData } from "./faqData";
import { Typography } from "../../elements/fonts/Fonts";
import Pagination from "../../elements/Pationation/Pagination";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { MainColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { FlexAppContainer } from "../../App.style";

// import { FaqInterface } from "./faqInterface";

// interface FaqProps {
//     faqs: FaqInterface[]; // Define the prop using the interface
//   }

const Faq = () => {
  const { mainBackgroundColor, mainColor } = useRecoilValue(MainColorSelector);
  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentFaqCards = FaqData.slice(startIndex, endIndex);
  const { t } = useTranslation();

  // const halfLength = Math.ceil(FaqData.length / 2);
  // const leftColumn = FaqData.slice(0, halfLength);
  // const rightColumn = FaqData.slice(halfLength);
  return (
    <FlexAppContainer
      height="100%"
      column={true}
      justifyContent="space-between"
      padding=" 0 0 3rem 0"
      gap="2rem"
    >
      <FaqWrapper style={{ background: mainBackgroundColor }}>
        <FaqTitle>
          <Typography
            text={t("help.Frequently Asked Questions")}
            fontSize="1.75rem"
            lineHeight="normal"
            fontWeight="400"
          />
        </FaqTitle>
        <FaqHolder>
          {/* <Column> */}
          {currentFaqCards.map((faq, index) => (
            <FaqRectangle
              key={index}
              question={`${index + 1}. ${t(faq.question)}`}
              answer={t(faq.answer)}
            />
          ))}
          {/* </Column> */}
          {/* <Column>
                    {rightColumn.map((faq, index) => (
                        <FaqRectangle
                            key={index}
                            question={faq.question}
                            answer={faq.answer}
                        />
                    ))}
                </Column> */}
        </FaqHolder>
      </FaqWrapper>
      <Pagination
        currentPage={currentPage}
        total={FaqData.length}
        limit={itemsPerPage}
        onPageChange={(page: number) => setCurrentPage(page)}
        justifyContent="flex-end"
      />
    </FlexAppContainer>
  );
};

export default Faq;
