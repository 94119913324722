import { FC } from "react";
import { IconWithTextInterface } from "../iconWithTextInterface";
import { IconAndTextHolder, Text, IconHolder } from "./IconWithText.style";

const IconWithText: FC<IconWithTextInterface> = ({
  icon: IconComponent,
  textElement,
  margin,
  color,
}) => {
  return (
    <IconAndTextHolder>
      <IconHolder>
        <IconComponent />
      </IconHolder>
      <Text style={{ margin: margin }}>{textElement}</Text>
    </IconAndTextHolder>
  );
};

export default IconWithText;
