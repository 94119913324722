import { FC, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  MainHolder,
  LeftContentHolder,
  TitleHolder,
  CodeHolder,
  Summary,
  HeroLinks,
  CreditNumberWrapper,
  EditIconHolder,
} from "./HeroSectionDetails.style";
import {
  Typography,
  TypographyRegularDescription,
  Credit,
} from "../../../elements/fonts/Fonts";

import { useRecoilState, useRecoilValue } from "recoil";
import { courseBannerColorSelector } from "../../../recoil/ThemeSelectors";

import { SvgGear, SvgInfo } from "../../../elements/Icons";
import { toast } from "react-toastify";
import IconWithText from "../../../elements/iconWithText/main/IconWithText";
import RatingWithText from "../../../elements/ratingWithText/main/RatingWithText";
import CreditNumbers from "../../../elements/creditNumbers/main/CreditNumbers";
import {
  SvgDownloadIcon,
  SvgShareIcon,
  SvgCreditIcon,
  SvgEdit,
} from "../../../elements/Icons";
import LoaderText from "../../loader/loaderText/LoaderText";

import HeroSectionData from "../heroSectionData";
import { useParams } from "react-router-dom";
import axios from "axios";
import { CourseArctCompoEdit } from "../../CourseArct/CourseArctCompoEdit";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import { useTranslation } from "react-i18next";
import { toArabicDigits } from "../../../utils/formatDayArabic";
import { adminRoleState, adminState } from "../../../recoil/RecoilStates";
import { FlexDiv } from "../../../globalStyles";
const { title, courseCode, credit, summary } = HeroSectionData;

interface HeroSectionDetailsProps {
  course: any;
  classStructure: any[];
  isLoading: boolean;
  syllabus: string;
}

const HeroSectionDetails: FC<HeroSectionDetailsProps> = ({
  course,
  classStructure,
  isLoading,
  syllabus,
}) => {
  const { show, setShow } = useContext(ClassContext);
  const { classId, setClassId } = useContext(ClassContext);
  const { bData } = useContext(ClassContext);
  const [showCourseArctCompo, setShowCourseArctCompo] = useState(false);
  // console.log("isLoading: ", isLoading);
  const BaseURI = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";
  const classSourceId = useParams();
  const { t } = useTranslation();
  const [adminRole] = useRecoilState(adminRoleState);
  const [isAdmin] = useRecoilState(adminState);
  const setClassSourceId = classSourceId.classId?.toString();

  const {
    iconColor,
    backgroundIconColor,
    iconHoveredColor,
    backgroundIconHoveredColor,
  } = useRecoilValue(courseBannerColorSelector);

  const handleEditIconClick = async () => {
    if (setClassSourceId) {
      setShow(true);
      setClassId(classSourceId);
      setShowCourseArctCompo(true);
    }
  };
  const { role } = useContext(ClassContext);
  const location = useLocation();
  const currentPath: string = location.pathname;
  const [courseToShare, setCourseToShare] = useState<string>();

  const handleShareCourse = () => {
    const fullURL = window.location.origin + location.pathname; // Combine origin and pathname

    // Use the Clipboard API to copy the URL to the clipboard
    navigator.clipboard
      .writeText(fullURL)
      .then(() => {
        toast.info("URL copied to clipboard", {
          theme: "dark",
          icon: <SvgInfo />,
        });
      })
      .catch((error) => {
        toast.error(error?.data.message || error.error, { theme: "dark" });
        console.error("Error copying to clipboard:", error);
      });

    setCourseToShare(fullURL);
  };

  return (
    <MainHolder>
      <LeftContentHolder>
        {isLoading ? (
          <LoaderText />
        ) : (
          <FlexDiv>
            {course.courseTitle && course.courseCode && (
              <TitleHolder>
                <Typography tag="span" text={course.courseTitle} />

                <CodeHolder>
                  <Typography tag="span" text={`- ${course.courseCode}`} />
                </CodeHolder>
              </TitleHolder>
            )}

            {role === "teacher" && (
              <EditIconHolder
                onClick={handleEditIconClick}
                color={iconColor}
                bgcolor={backgroundIconColor}
                hovercolor={iconHoveredColor}
                bghovercolor={backgroundIconHoveredColor}
              >
                <SvgGear width={"12.8px"} height={"12.8px"} />
              </EditIconHolder>
            )}
          </FlexDiv>
        )}


        <Summary>
          {classStructure &&
            classStructure.map((item) => (
              <Typography
                text={item.headline}
                fontSize="1.375rem"
                lineHeight="2rem"
              />
            ))}
        </Summary>
        {/* <RatingWithText /> */}

        <HeroLinks>
          {syllabus && syllabus !== "" && (
            <IconWithText
              icon={SvgDownloadIcon}
              textElement={
                <a href={syllabus} download>
                  <TypographyRegularDescription
                    text={t("course view.Download Course Syllabus")}
                    fontSize="0.875rem"
                  />
                </a>
                // <a href={syllabus} download>Download Course Syllabus</a>
              }
            />
          )}
          <div onClick={handleShareCourse}>
            <IconWithText
              icon={SvgShareIcon}
              textElement={
                <TypographyRegularDescription
                  text={t("course view.Share This Course")}
                  fontSize="0.875rem"
                />
              }
            />
          </div>
        </HeroLinks>
      </LeftContentHolder>
      <CreditNumberWrapper>
        {course.numberOfCredits && (
          <CreditNumbers
            credit={
              <Credit
                number={toArabicDigits(course.numberOfCredits.toString())}
                fontSize="1.125rem"
              />
            }
            textElement={
              <TypographyRegularDescription
                text={t("course view.credits")}
                fontSize="0.75rem"
                color="#fff"
              />
            }
            icon={SvgCreditIcon}
          />
        )}
      </CreditNumberWrapper>
      {show &&
        classStructure &&
        classStructure.map((item) => (
          <CourseArctCompoEdit Description={item.description} />
        ))}
    </MainHolder>
  );
};

export default HeroSectionDetails;
