import styled from 'styled-components'

export const SubmittedMain=styled.div`
padding: 1rem 1.5rem;
`;

export const SubmittedContainer=styled.div`
border: 1px solid #D85D66;
padding:1.62rem 1.25rem 1.31rem 1.25rem;
width: 100%;
`;
export const SubmittedQuestion=styled.div`
display:flex;
align-items:center;
justify-content:space-between;
`;
export const QuestionContainer=styled.div`
display: flex;
align-items:center;
gap:0.31rem;
`;
export const RedDot=styled.div`
width: 0.625rem;
height: 0.625rem;
border-radius:50%;
background: #D85D66;
`;

export const SubmittedNumber=styled.div`
margin-top:1.25rem;
background: #D85D6690;
padding:0.62rem 1.25rem;
display: flex;
justify-content:space-between;
`;
export const Score =styled.div`
display: flex;
align-items:center;
gap:0.2rem;
`;