import { FC, useState, useEffect } from "react";
import { breakpoints } from "../../theme";
import {
  MainWrapper,
  LineDecoration,
  NumberHolder,
} from "./CourseStatisticElement.style";
import { CourseStatisticElementInterface } from "./courseStatisticElementInterface";
import { PrimaryFontNumber, Typography } from "../fonts/Fonts";

import { useRecoilValue } from "recoil";
import { courseCreatorColorSelector } from "../../recoil/ThemeSelectors";
import { toArabicDigits } from "../../utils/formatDayArabic";

const CourseStatisticElement: FC<CourseStatisticElementInterface> = ({
  activityNumber,
  activityType,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Recoil state and selectors
  const { statisticsTextColor } = useRecoilValue(courseCreatorColorSelector);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const language = localStorage.getItem("language")

  return (
    <MainWrapper>
      <LineDecoration></LineDecoration>
      <NumberHolder>
        <PrimaryFontNumber number={toArabicDigits((activityNumber).toString())} fontSize="1.75rem" />
      </NumberHolder>
      <Typography
        text={activityType.toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}
        fontSize="0.75rem"
        color={statisticsTextColor}
        wordWrap="break-word"
        whiteSpace={`${
          windowWidth >= parseInt(breakpoints.tablets) ? "nowrap" : "pre-wrap"
        } }`}
      />
    </MainWrapper>
  );
};

export default CourseStatisticElement;
