import styled from "styled-components";
import { breakpoints } from "../../theme";

export const GlobalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.63rem;
  width: 100%;
`;
export const AddGroupPageWrapper = styled.div`
  margin-bottom: 1.88rem;
  display: flex;
  gap: 1.25rem;
  @media (max-width: ${breakpoints.mobile}) {
    justify-content: center;
  }
`;
export const PageTitle = styled.div`
  margin-bottom: 1.25rem;
`;
export const ButtonCancel = styled.button`
  width: 13.4375rem;
  height: 3.125rem;
  flex-shrink: 0;
  border-radius: 1.25rem;
  border: 1px solid #d04b4b;
  background: none;
  cursor: pointer;
`;
export const ButtonAdd = styled.button`
  width: 13.4375rem;
  height: 3.125rem;
  flex-shrink: 0;
  border-radius: 1.25rem;
  border: 1px solid;
  background: linear-gradient(to right, #5dd3b3, #2f6a5a);
  cursor: pointer;
`;
export const ButtonsHolder = styled.div`
  display: flex;
  gap: 0.94rem;
  width: 100%;
  justify-content: center;
`;
export const AddGRoupMembersBar = styled.div``;
