import styled from "styled-components";
import { StylesInputWithDropdownProps } from "./NestedSelectInterface";
import { ReactComponent as SvgPlygonIcon } from "../../assets/icons/Arrow.svg";

export const InputContainer = styled.div`
  position: relative;
  font-family: "Primary Font-Medium";
`;

export const Input = styled.div<StylesInputWithDropdownProps>`
  padding: 0 ${(props) => (props.paddingrl ? props.paddingrl : "0.87rem")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "0.75rem")};
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "2.8125rem")};
  cursor: pointer;
  border: ${(props) => (props.border === "none" ? "none" : "1px solid")};
  border-color: ${(props) =>
    props.borderColor ? props.borderColor : props.inputbordercolor};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "0.625rem"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => (props.color ? props.color : props.themetextcolor)};
`;

export const Dropdown = styled.div<StylesInputWithDropdownProps>`
  position: absolute;
  &:dir(ltr){
    left: 0;
  }
  &:dir(rtl){
    right: 0;
  }
  width: ${(props) => (props.width ? props.width : "100%")};
  background-color: ${(props) => props.bg_selectColor};
  border-radius: 0.313rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 99;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "0.75rem")};
`;

export const OptionItem = styled.div<StylesInputWithDropdownProps>`
  padding: 0 ${(props) => (props.paddingrl ? props.paddingrl : "0.87rem")};
  height: 2.3rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #5dd3b3;
    color: #fff;
  }

  &:first-child {
    border-radius: 0.313rem 0.313rem 0 0;
  }

  &:last-child {
    border-radius: 0 0 0.313rem 0.313rem;
  }
`;

export const PolygonDiv = styled.div<StylesInputWithDropdownProps>`
  transform: ${(props) => (props.isopen === "true" ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const SubMenu = styled.div<StylesInputWithDropdownProps>`
  position: absolute;
  top: 0;
  &:dir(ltr){
    left: 100%;
  }
  &:dir(rtl){
    right: 100%;
  }
  border-radius: 0.3125rem;
  background: ${(props) => props.bg_selectColor};
  line-height: 2rem;
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 8rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.themetextcolor === "#fff" ? "linear-gradient(180deg, #5DD3B3 0%, #2D3741 100%)" : "linear-gradient(50deg, #5DD3B3 3.83%, #2F6A5A 95.8%)"};
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background-color: none;
    border-radius: 0.625rem;
  }
`;
export const SubMenuItem = styled.div<StylesInputWithDropdownProps>`
  padding: 0 ${(props) => (props.paddingrl ? props.paddingrl : "0.87rem")};
  height: 2rem;
  border-radius: 0.313rem;

  &:hover {
    background: #5dd3b3;
    color: #fff;
  }
  &:first-child {
    border-radius: 0.313rem 0.313rem 0 0;
  }

  &:last-child {
    border-radius: 0 0 0.313rem 0.313rem;
  }
  cursor: pointer;
`;
export const ItemWithArrow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

// Define the styled component for SvgPlygonIcon
export const StyledSvgPlygonIcon = styled(SvgPlygonIcon)<StylesInputWithDropdownProps>`
  color: ${(props) => props.color ? props.color : props.themetextcolor};
  &:dir(ltr){
    transform: rotate(270deg);
  }
  &:dir(rtl){
    transform: rotate(90deg);
  }
  /* Change the polygon color when hovering over the OptionItem. */
  ${OptionItem}:hover & {
    color: #fff;
  }
`;
