import { useRef, useState, useEffect } from "react";
import {
  MenuWrapper,
  Frame,
  TextWithIconWrapper,
} from "./DropDownEditDelete.style";
import DropDownData from "../dropDownData";
import { PrimaryFontMediumText } from "../../../elements/fonts/Fonts";
import { TopicProps } from "../../../Views/teacherView/Course-Creator/courseCreatorInterface";

import { useStateContext } from "../../../contexts/ContextProvider";
import { useMyContext } from "../../../contexts/CourseCreatorContext";
import { useQuizMyContext } from "../../../contexts/CourseCreatorQuizContext";

import { useRecoilValue } from "recoil";
import { courseCreatorColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { AssignmentContext } from "../../../contexts/CourseCreatorAssignmentContext";
import { ForumContext } from "../../../contexts/ForumContext";

interface DropDownEditDeleteProps {
  removeContent: (topic: TopicProps, index: number) => void;
  contentId: number;
  contentType: string;
  setShowDeleteContent: (showDeleteContent: boolean) => void;
  topic: TopicProps;
  setShowEditContent: (showEditContent: boolean) => void;
}

const DropDownEditDeleteActivity = ({
  removeContent,
  contentId,
  setShowDeleteContent,
  topic,
  setShowEditContent,
  contentType,
}: DropDownEditDeleteProps): JSX.Element => {
  const { t } = useTranslation();
  const { setContent_IdToEdit, setContentType, setGlobalShowEditContent } =
    useStateContext();
  const {
    globalShowPopup,
    setGlobalShowPopup,
    setGlobalDescription,
    setGlobalEndDate,
    setGlobalFromDate,
    setGlobalIsAnonymous,
    setGlobalIsTimeVisible,
    setGlobalIsVisible,
    setGlobalSelectedSurvey,
    setGlobalSelectedSurveyId,
    setGlobalStartDate,
    setGlobalSurveyName,
    setGlobalToDate,
    setEditSurvey,
  } = useMyContext();

  const {
    setGlobalAttempt,
    setGlobalDuration,
    setGlobalMaxGrade,
    setGlobalPassingGrade,
    setGlobalQuizDescription,
    setGlobalQuizEndDate,
    setGlobalQuizFromDate,
    setGlobalQuizIsTimeVisible,
    setGlobalQuizIsVisible,
    setGlobalQuizName,
    setGlobalQuizShowPopup,
    setGlobalQuizStartDate,
    setGlobalQuizToDate,
    setGlobalSelectedQuiz,
    setGlobalSelectedQuizId,
    setGlobalShowGrade,
    setEditQuiz,
    setEditQuizButton,
    setSelectedRubricId
  } = useQuizMyContext();

  const {
    setSelectedRadio,
    setSelectedTitle,
    setGlobalAssignmentShowPopup,
    setSelectedRule,
    setShowTimeVisibility,
    setShowSelectGrouping,
    setShowPlagiarismPrevention,
    setSelectedGrouping,
    setAssignmentName,
    setDescription,
    setMaxGrade,
    setStartDate,
    setDueDate,
    setCutOffDate,
    setPenalty,
    setVisibleStudents,
    setFromDate,
    setToDate,
    setAnswerBoxChecked,
    setFileSubmissionChecked,
    setFile,
    setUrlAttachment,
  } = AssignmentContext();

  const {
    setGlobalForumShowPopup,
    setForumAttach,
    setForumCutOffDate,
    setForumDescription,
    setForumDueDate,
    setForumEndTime,
    setForumName,
    setForumFromDate,
    setForumMaxGrade,
    setForumStartDate,
    setForumPenalty,
    setForumSelectedRadio,
    setForumShowGradingType,
    setForumShowTimeVisibility,
    setForumStartTime,
    setForumToDate,
    setForumVisibleStudents,
  } = ForumContext()

  // Recoil state and selectors
  const { tooltipBgColor, tooltipTextColor, boxShadow } = useRecoilValue(
    courseCreatorColorSelector
  );

  return (
    <MenuWrapper bgColor={tooltipBgColor} boxShadow={boxShadow}>
      {DropDownData.map((item, index) => (
        <Frame
          key={index}
          textColor={tooltipTextColor}
          style={{
            borderRadius: `${
              index === 0
                ? "0.3125rem 0.3125rem 0rem 0rem"
                : index === DropDownData.length - 1
                ? " 0rem 0rem 0.3125rem 0.3125rem"
                : ""
            }`,
          }}
        >
          <TextWithIconWrapper
            onClick={() => {
              if (index === 1) {
                setShowDeleteContent(true);
              } else if (index === 0) {
                setContent_IdToEdit(contentId);
                setContentType(contentType);
                setShowEditContent(true);
                setGlobalShowEditContent(true);
                // For The Survey Form
                setGlobalDescription("");
                setGlobalSurveyName("");
                setGlobalEndDate(null);
                setGlobalStartDate(null);
                setGlobalFromDate(null);
                setGlobalToDate(null);
                setGlobalIsAnonymous(false);
                setGlobalIsTimeVisible(false);
                setGlobalIsVisible(true);
                setGlobalSelectedSurveyId(0);
                setGlobalSelectedSurvey("");
                setEditSurvey(false);
                //For Th Quiz Form
                setGlobalAttempt("");
                setGlobalQuizDescription("");
                setGlobalQuizEndDate(null);
                setGlobalQuizStartDate(null);
                setGlobalQuizFromDate(null);
                setGlobalQuizToDate(null);
                setGlobalQuizName("");
                setGlobalMaxGrade("");
                setGlobalDuration("");
                setGlobalPassingGrade("");
                setGlobalQuizIsTimeVisible(false);
                setGlobalQuizIsVisible(true);
                setGlobalSelectedQuiz("");
                setGlobalSelectedQuizId(0);
                setGlobalShowGrade(false);
                setEditQuiz(false);
                setEditQuizButton(false)
                // For the assignment Form
                setSelectedRadio("grade");
                setSelectedTitle("");
                setSelectedRubricId(0);
                setSelectedRule("");
                setShowTimeVisibility(false);
                setShowSelectGrouping(false);
                setGlobalAssignmentShowPopup(false);
                setShowPlagiarismPrevention(false);
                setSelectedGrouping("");
                setAssignmentName("");
                setDescription("");
                setMaxGrade("");
                setStartDate(null);
                setDueDate(null);
                setCutOffDate(null);
                setPenalty("");
                setVisibleStudents(true);
                setFromDate(null);
                setToDate(null);
                setAnswerBoxChecked(false);
                setFileSubmissionChecked(false);
                setFile(null);
                setUrlAttachment("");
                //for the forum Form
                setGlobalForumShowPopup(false)
                setForumAttach(false);
                setForumCutOffDate(null)
                setForumDescription('')
                setForumDueDate(null)
                setForumEndTime('')
                setForumName('')
                setForumFromDate(null)
                setForumMaxGrade('')
                setForumStartDate(null)
                setForumPenalty('')
                setForumSelectedRadio('grade')
                setForumShowGradingType(false)
                setForumShowTimeVisibility(false)
                setForumStartTime('')
                setForumToDate(null)
                setForumVisibleStudents(true)
              }
            }}
          >
            <item.icon />
            <PrimaryFontMediumText text={t(item.title)} fontSize=" 0.875rem" />
          </TextWithIconWrapper>
        </Frame>
      ))}
    </MenuWrapper>
  );
};

export default DropDownEditDeleteActivity;
