import React from "react";
import {
  AllAttemptContainer,
  CancelButtonContainer,
  IconContainer,
  NumberContainer,
  StudentQuizPagination,
} from "./StudentQuizView.style";
import { Typography } from "../../elements/fonts/Fonts";
import {
  SvgChecked,
  SvgDone,
  SvgEyeIcon,
  SvgFalse,
  SvgEyeSlash,
} from "../../elements/Icons";
import BlueButton from "../../elements/blueButton/BlueButton";
import { useTranslation } from "react-i18next";
import { toArabicDigits } from "../../utils/formatDayArabic";

interface StudentQuizViewBottom {
  totalNumberOfAttempts: number;
  restNumberOfAttempts: number;
  showReSubmitButton: boolean;
  handleClose: () => void;
  valid: boolean;
  submissionLoading: boolean;
  loading: boolean;
  handleReSubmit: () => void;
  handelSave: () => void;
  remainingDataCount: number;
}

const StudentQuizViewBottom = ({
  totalNumberOfAttempts,
  remainingDataCount,
  restNumberOfAttempts,
  showReSubmitButton,
  submissionLoading,
  loading,
  valid,
  handelSave,
  handleClose,
  handleReSubmit,
}: StudentQuizViewBottom) => {
  const { t } = useTranslation();
  return (
    <>
      {/* Show The Number of Attemps of the student that has, and how much he tried */}
      <AllAttemptContainer>
        <Typography text={`${t("quiz.Attempt")}: `} />
        {totalNumberOfAttempts === -1 ? (
          <Typography text={` ${t("quiz.Unlimited")}`} />
        ) : (
          Array.from({ length: totalNumberOfAttempts }, (_, index) => (
            <React.Fragment key={index}>
              {index + 1 <= totalNumberOfAttempts - restNumberOfAttempts ? (
                // Render an icon for submitted attempts
                <IconContainer style={{ color: "#fff", paddingTop: ".18rem" }}>
                  <SvgChecked />
                </IconContainer>
              ) : (
                <NumberContainer key={index}>{toArabicDigits((index + 1).toString())}</NumberContainer>
              )}
            </React.Fragment>
          ))
        )}
      </AllAttemptContainer>
      {showReSubmitButton && (
        <>
          {/* Cancel Button */}
          <CancelButtonContainer>
            <BlueButton
              Width="8.8125rem"
              Height="2.1875rem"
              text={t("forms.Cancel")}
              Border="1px solid var(--Main-Green-color, #D04B4B)"
              BackColor="transparent"
              Color="#D04B4B"
              OnClick={handleClose}
            />
          </CancelButtonContainer>
        </>
      )}
      {valid && !submissionLoading && !loading ? (
        <StudentQuizPagination>
          {/* Re-Submit Button */}
          {showReSubmitButton && restNumberOfAttempts !== 0 ? (
            <BlueButton
              text={t("quiz.Re-Submit")}
              OnClick={() => handleReSubmit()}
              Width="8.8125rem"
              Height="2.1875rem"
              BackColor="transparent"
              Color="#5DD3B3"
              Border="1px solid #5DD3B3"
            />
          ) : restNumberOfAttempts !== 0 ? (
            <BlueButton
              text={remainingDataCount > 0 ? t("quiz.Next") : t("forum.Submit")}
              OnClick={() => handelSave()}
              Width="8.8125rem"
              Height="2.1875rem"
              BackColor="#5DD3B3"
            />
          ) : (
            ""
          )}
        </StudentQuizPagination>
      ) : submissionLoading || loading ? (
        <StudentQuizPagination>
          <BlueButton
            text={`${t('general.loading')}...`}
            Width="8.8125rem"
            Height="2.1875rem"
            BackColor="#5DD3B3"
          />
        </StudentQuizPagination>
      ) : (
        ""
      )}
    </>
  );
};

export default StudentQuizViewBottom;
