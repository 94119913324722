import React from 'react';
import { Events } from '../../../../Views/TeacherCalendarView/TeacherCalendarDataInterface';
import { Flexed, LowerContainer, TextContainer, Image, Titletext, GreenRight, Wholeprofile } from './Events.style'; // Assuming you're importing the correct path for the styled component
import { LightTypography, Typography, TypographyRegularDescription } from '../../../../elements/fonts/Fonts';
import { HorizantalLine } from '../../../dashboardResources/popupResource/PopUpResource.style';
import { SvgCorrectTick, SvgMic } from '../../../../elements/Icons';
import moment from 'moment';
import ButtonElement from '../../../../elements/button/ButtonElement';
import { t } from 'i18next';
import { useRecoilState } from 'recoil';
import { adminState } from '../../../../recoil/RecoilStates';

interface CalendarEventPopupProps {
  event: any;
}

const CalendarEventPopup: React.FC<CalendarEventPopupProps> = ({ event }) => {
  const [isAdmin] = useRecoilState(adminState)
  const formattedStartDate = event && new Date(event.start);
  console.log(event.end,"eventend")
  console.log(event.start,"eventstart")
  const formattedEndtDate = event && new Date(event.end);

  const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const convertToAmPm = (hours: number, minutes: number): string => {
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    return `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
  };
  
  const dayOfWeek = days[formattedStartDate.getDay()];
  const month = months[formattedStartDate.getMonth()];
  const dayOfMonth = formattedStartDate.getDate();
  const year = formattedStartDate.getFullYear();
  const hours = `${formattedStartDate.getHours().toString().padStart(2, '0')}:${formattedStartDate.getMinutes().toString().padStart(2, '0')}`;
  const minutes = formattedStartDate.getMinutes();

  const formattedDate = `${dayOfMonth} ${month} - ${dayOfWeek}`;
  const formattedTime = convertToAmPm(parseInt(hours),minutes);


  const dayOfWeeks = days[formattedEndtDate.getDay()];
  const monthss = months[formattedEndtDate.getMonth()];
  const dayOfMonths = formattedEndtDate.getDate();
  const years = formattedEndtDate.getFullYear();
  const hourss = `${formattedEndtDate.getHours().toString().padStart(2, '0')}:${formattedEndtDate.getMinutes().toString().padStart(2, '0')}`;
  const minutess = formattedEndtDate.getMinutes();
console.log(hours,"startformatevent")
console.log(hourss,"endformatevent")


  const formattedDates = `${dayOfMonth} ${month} - ${dayOfWeek}`;
  const formattedEndTime = convertToAmPm(parseInt(hourss),minutess);

  const profileImage = require('../../../../assets/userProfile.png')
  function getTaskStatus(task: any): string {
    if (task.type === "OnlineSession") {
      const currentDateTime = moment();
      const startDateTime = moment(task.start);
      const endDateTime = moment(task.end);

      if (endDateTime <= currentDateTime) {
        return "ended";
      } else if (startDateTime >= currentDateTime) {
        return "notStarted";
      } else if (startDateTime <= currentDateTime && endDateTime >= currentDateTime) {
        return "onGoing";
      }
    }
    return "default";
  }

  const handleButtonClick = () => {
    // navigate("/to-do");
  };
  const handleJoinSession = (link: any) => {
    // navigate("/to-do");
    window.open(link, "_blank");
  };


  return (
    <>
      {event && (
        <>
          <Flexed>
            <Titletext>
              <Typography text={`${event.type} :${event.title}`} fontSize='1rem' />
              <Typography text={`class :${event.class}`} fontSize='0.8rem' />
            </Titletext>

            <LightTypography text={`${formattedDate}`} fontSize='0.75rem' fontWeight='400' />
            <LightTypography text={` ${formattedTime}-${formattedEndTime}`} fontSize='0.75rem' fontWeight='400' />
            <TypographyRegularDescription text={`${event.description}`} fontSize='0.875rem' lineHeight='1.375rem' />
            {isAdmin === false && (
              <>
                {event.type === "OnlineSession" ? (
                  getTaskStatus(event) === "onGoing" ? (
                    <ButtonElement
                      disabledButton="onGoing"
                      text={t("dashboard.Join Class")}
                      iconPath={SvgMic}
                      clickEvent={() => handleJoinSession(event.link)}
                    />
                  ) : getTaskStatus(event) === "ended" ? (
                    <ButtonElement
                      disabledButton="ended"
                      text={t("dashboard.Session Ended")}
                      iconPath={SvgMic}
                      clickEvent={handleButtonClick}
                    />
                  ) : getTaskStatus(event) === "notStarted" ? (
                    <ButtonElement
                      disabledButton="notStarted"
                      text="Session Not Yet Due"
                      iconPath={SvgMic}
                      clickEvent={handleButtonClick}
                    />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </>
            )}

            <HorizantalLine showline={""} />
            {event.instructors && event.instructors.length > 0 &&
              event.instructors.map((instructor: any, index: number) => (
                <div key={index}>
                  <LowerContainer>
                    <Wholeprofile>

                      <Image src={instructor.profilePicture || profileImage} alt="profile" />
                      {/* <GreenRight>
                <SvgCorrectTick/>
              </GreenRight> */}

                    </Wholeprofile>
                    <TextContainer>
                      <Typography text={instructor.username} fontSize='1rem' />
                      {/* <LightTypography text={`Math Teacher`}  fontSize='0.875rem' fontWeight='400' /> */}
                    </TextContainer>
                  </LowerContainer>

                  {/* <LowerContainer>
              <Image src={event.profile} alt="profile" />
              <TextContainer>
                <Typography text={`Sultan Al Rajhi`} fontSize='1rem'/>
                <LightTypography text={`Math Teacher`}  fontSize='0.875rem' fontWeight='400' />
              </TextContainer>
            </LowerContainer> */}
                </div>
              ))
            }



          </Flexed>

        </>
      )}
    </>
  );
};

export default CalendarEventPopup;
