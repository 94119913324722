import React, { useCallback } from "react";
import DragAndDropZone from "./main/dropzone";
import { Typography } from "../../elements/fonts/Fonts";

interface Prop {
  header: string;
  onFilesChange?: (newFiles: File[]) => void;
  selectedFiles?: File[]; 
  acceptedTypes?: {[key: string]: string[]};
  readonly?: boolean;
  setLoading?: (loading: boolean) => void;
  
}


export const DropZoneForm = ({ header, onFilesChange,setLoading, selectedFiles,acceptedTypes,readonly }: Prop): JSX.Element => {
  const handleDrop = useCallback((acceptedFiles: File[]) => {
    if (onFilesChange) {
      onFilesChange(acceptedFiles);
    }
  }, [onFilesChange]);
   
  return (
    <div>
      <Typography fontSize="1.125rem" text={header} />
      <DragAndDropZone onDrop={handleDrop} readonly={readonly} setLoading={setLoading} initialSelectedFiles={selectedFiles} acceptedTypes={acceptedTypes}/>
    </div>
  );
};