import { CourseTagsHolder } from "./CoursTags.style";
import TagButton from "../../../elements/tagButton/TagButton";
import CourseTagsData from "../courseTagsData";

const CoursTags = () => {
  return (
    <CourseTagsHolder>
      {CourseTagsData &&
        CourseTagsData.map((tag, index) => (
          <TagButton key={index} text={tag.text} />
        ))}
    </CourseTagsHolder>
  );
};

export default CoursTags;
