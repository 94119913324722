import React, { useEffect, useState } from "react";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  ChoicesContainer,
  ChoicesHeader,
  ChoicesInputs,
  Input,
  InputCont,
  LabelDiv,
  QuestionSelected,
  QuestionSelectedContainer,
} from "./AllQuestions.style";
import { AllQuestionsInterface } from "./AllQuestionsInterface";
import { FlexDiv } from "../../globalStyles";
import { SvgAdd, SvgDelete } from "../../elements/Icons";
import { useMyContext } from "../../contexts/QuestionBanksContext";
import ActionButtons from "./ActionButtons";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import {
  auth0SubIdSplitter,
  generateUUID,
  auth0ProviderSplitter
} from "../../utils/StringManipulation";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  CourseColorSelector,
} from "../../recoil/ThemeSelectors";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const ShortAnswer = ({ handleCancel }: AllQuestionsInterface) => {
  const { id } = useParams();
  const { user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const language = localStorage.getItem("language")
  const { savedQuestions, setSavedQuestions, questionToUpdate, updateMode } =
    useMyContext();
  const { t } = useTranslation();
  const feedbackRight = "quiz.Your Answer is correct."
  const feedbackWrong = "quiz.Your Answer is incorrect."
  const [isLoading, setIsLoading] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [feedbackRightError, setFeedbackRightError] = useState(false);
  const [feedbackWrongError, setFeedbackWrongError] = useState(false);
  const [keywordsErrors, setKeywordsErrors] = useState<boolean[]>([]);
  const [prevData, setPrevData] = useState<any>();
  const [shortAnswerData, setShortAnswerData] = useState({
    questionText: "",
    keywords: [""],
    feedbackRight: t(feedbackRight),
    feedbackWrong: t(feedbackWrong),
  });


  // Set initial state based on updateMode and questionToUpdate
  useEffect(() => {
    if (updateMode && questionToUpdate && questionToUpdate.id) {
      setShortAnswerData({
        questionText: questionToUpdate.question,
        keywords: questionToUpdate.keywords,
        feedbackRight: questionToUpdate.feedbackRight,
        feedbackWrong: questionToUpdate.feedbackWrong,
      });
    }
  }, [updateMode, questionToUpdate]);

  // function to handle the question from text editor
  const handleQuestionChange = (value: string) => {
    setShortAnswerData((prevState) => ({ ...prevState, questionText: value }));
    setDescriptionError(value.trim() === ""); // Check if the question is empty
  };

  const handleFeedbackRightChange = (value: string) => {
    setShortAnswerData((prevState) => ({
      ...prevState,
      feedbackRight: value,
    }));
    setFeedbackRightError(value.trim() === "");
  };

  const handleFeedbackWrongChange = (value: string) => {
    setShortAnswerData((prevState) => ({
      ...prevState,
      feedbackWrong: value,
    }));
    setFeedbackWrongError(value.trim() === "");
  };

  // function to add keyword
  const addKeyword = () => {
    setShortAnswerData((prevState) => ({
      ...prevState,
      keywords: [...shortAnswerData.keywords, ""],
    }));
    setKeywordsErrors([...keywordsErrors, false]);
  };

  // function to delete keyword
  const removeKeyword = (index: number) => {
    // Clone the existing choices array to avoid mutating state directly
    const newKeywords = [...shortAnswerData.keywords];
    // Remove the choice at the specified index
    newKeywords.splice(index, 1);
    // Update the state with the modified choices array
    setShortAnswerData((prevState) => ({
      ...prevState,
      keywords: newKeywords,
    }));
    const newKeywordsErrors = [...keywordsErrors];
    newKeywordsErrors.splice(index, 1);
    setKeywordsErrors(newKeywordsErrors);
  };

  // function to handle the value of keyword
  const handleKeywordChange = (value: string, index: number) => {
    // Clone the existing keywords array to avoid mutating state directly
    const newKeywords = [...shortAnswerData.keywords];
    // Update the keyword at the specified index with the new value
    newKeywords[index] = value;
    // Update the state with the modified keywords array
    setShortAnswerData((prevState) => ({
      ...prevState,
      keywords: newKeywords,
    }));
    const newKeywordsErrors = [...keywordsErrors];
    newKeywordsErrors[index] = value.trim() === "";
    setKeywordsErrors(newKeywordsErrors);
  };

  // function to save the data in the context state
  const handleSave = async () => {
    const { questionText, keywords, feedbackRight, feedbackWrong } =
      shortAnswerData;

    // Check if the question is empty
    setDescriptionError(questionText.trim() === "");
    setFeedbackRightError(feedbackRight.trim() === "");
    setFeedbackWrongError(feedbackWrong.trim() === "");

    // Check if any keyword is empty
    const newKeywordsErrors = keywords.map((keyword) => keyword.trim() === "");
    setKeywordsErrors(newKeywordsErrors);

    // If either the question or any keyword is empty, return early without saving
    if (
      questionText.trim() === "" ||
      feedbackRight.trim() === "" ||
      feedbackWrong.trim() === "" ||
      newKeywordsErrors.some((error) => error)
    ) {
      return;
    }

    setIsLoading(true);
    const newShortAnswerQuestion = {
      id: generateUUID(),
      classSourceId: id,
      subId: subId,
      provider:provider,
      contentType: "SHORT_ANSWER",
      question: questionText,
      keywords: keywords.filter((keyword) => keyword.trim() !== ""), // Remove empty keywords
      feedbackRight: feedbackRight,
      feedbackWrong: feedbackWrong,
    };
    try {
      const response = await axios.post(
        `https://0ddea1gj55.execute-api.eu-west-1.amazonaws.com/dev/question/bank/create/${tenantName}`,
        {
          data: newShortAnswerQuestion,
        }
      );
      setIsLoading(false);
      handleCancel();
    } catch (error) {
      console.log(error);
    }
    // }
  };
  const { ClassGradientBox } = useRecoilValue(CourseColorSelector);
  const { themeTextColor, bghelp } = useRecoilValue(ColorSelector);
  return (
    <QuestionSelectedContainer>
      <QuestionSelected style={{ background: ClassGradientBox }}>
        <Typography text={t("quiz.SHORT ANSWER")} fontSize="1.25rem" />

        {/* text editor part */}
        <LabelDiv>
          <Typography text={t("question bank.Question Text")} fontSize="1rem" />
          <TinyEditor
            hasError={descriptionError}
            initialValue={shortAnswerData.questionText}
            onChange={handleQuestionChange}
            placeholder={t("question bank.Type your question")}
            height="17.5625rem"
          />
        </LabelDiv>

        {/* Choices Section */}
        <ChoicesContainer>
          <ChoicesHeader>
            <MediumTypography text={t("question bank.Correct Keywords")} fontSize="0.875rem" />
            <FlexDiv
              gap="0.62rem"
              onClick={addKeyword}
              style={{ cursor: "pointer" }}
            >
              <SvgAdd color="#5DD3B3" />
              <Typography
                text={t("quiz.Add Keywords")}
                fontSize="0.75rem"
                color="#5DD3B3"
              />
            </FlexDiv>
          </ChoicesHeader>

          <ChoicesInputs>
            {shortAnswerData.keywords.map((keyword, index) => (
              <InputCont key={index}>
                <Input
                  borderColor={bghelp}
                  color={themeTextColor}
                  hasError={keywordsErrors[index]}
                  width="100%"
                  placeholder={t("question bank.Write Your Correct Answer")}
                  value={keyword}
                  onChange={(e) => handleKeywordChange(e.target.value, index)}
                />
                {shortAnswerData.keywords.length > 1 && (
                  <SvgDelete
                    onClick={() => removeKeyword(index)}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </InputCont>
            ))}
          </ChoicesInputs>
        </ChoicesContainer>

        <InputCont>
          <LabelDiv style={{ width: "100%" }}>
            <TypographyRegularDescription
              text={t("quiz.Feedback if right")}
              fontSize="0.875rem"
            />
            <Input
              borderColor={bghelp}
              color={themeTextColor}
              hasError={feedbackRightError}
              value={shortAnswerData.feedbackRight}
              onChange={(e) => handleFeedbackRightChange(e.target.value)}
              type="text"
              placeholder={t("quiz.Feedback if right")}
              width="100%"
            />
          </LabelDiv>
          <LabelDiv style={{ width: "100%" }}>
            <TypographyRegularDescription
              text={t("quiz.Feedback if wrong")}
              fontSize="0.875rem"
            />
            <Input
              borderColor={bghelp}
              color={themeTextColor}
              hasError={feedbackWrongError}
              value={shortAnswerData.feedbackWrong}
              onChange={(e) => handleFeedbackWrongChange(e.target.value)}
              type="text"
              placeholder={t("quiz.Feedback if wrong")}
              width="100%"
            />
          </LabelDiv>
        </InputCont>

        {(keywordsErrors.some((error) => error) ||
          descriptionError ||
          feedbackRightError ||
          feedbackWrongError) && (
          <ErrorDiv>{t("quiz.This field is mandatory")}</ErrorDiv>
        )}
      </QuestionSelected>

      {/* action buttons component part*/}
      <ActionButtons
        handleSave={handleSave}
        handleCancel={handleCancel}
        isLoading={isLoading}
      />
    </QuestionSelectedContainer>
  );
};

export default ShortAnswer;
