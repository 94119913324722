import React, { FC, useState } from "react";
import {
  Container,
  Question,
  HorizantalLine,
  SecondHorizantalLine,
  HeaderContainer,
  Text,
  BodyContainer,
  ImagesContainer,
} from "./PreviewQuestion.style";
import { TypographyRegularDescription } from "../../../elements/fonts/Fonts";
import { ViewQuestionProps } from "../PreviewQuestionInterface";

import PreviewMatching from "../PreviewMatching/PreviewMatching";
import PreviewMultipleChoices from "../PreviewMultipleChoices/PreviewMultipleChoices";
import PreviewTrueFalse from "../PreviewTrueFalse/PreviewTrueFalse";
import PreviewShortAnswer from "../PreviewShortAnswer/PreviewShortAnswer";
import PreviewEssay from "../PreviewEssay/PreviewEssay";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  courseCreatorColorSelector,
} from "../../../recoil/ThemeSelectors";
import DOMPurify from "dompurify";
const PreviewQuestion: FC<ViewQuestionProps> = ({ questions, index }) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const handleSelectChoice = (choiceIndex: number, selectedValue: string) => {
    const updatedSelectedValues = [...selectedValues];
    updatedSelectedValues[choiceIndex] = selectedValue;
    setSelectedValues(updatedSelectedValues);
  };

  const countChecked = questions?.choices?.filter(
    (choice) => choice.isChecked === true
  ).length;
  const { bghelp } = useRecoilValue(ColorSelector);
  const { lineIconColor, theme } = useRecoilValue(courseCreatorColorSelector);

  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };
  return (
    <Container>
      {/* <Question>{questions?.question}</Question> */}
      <p
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(decodeHtml(questions?.question)),
        }}
        style={{
          fontSize: "16px",
          fontWeight: "400",
          fontFamily: "Primary Font-Bold",
          marginTop: "1rem",
        }}
      ></p>
      <HorizantalLine lineIconColor={lineIconColor} theme={theme} />
      <HeaderContainer>
        <TypographyRegularDescription
          text={`Question ${index + 1}`}
          fontSize="14px"
          lineHeight="17.3px"
          fontWeight="400"
        />
        <TypographyRegularDescription
          text={`(${questions?.grade}pt)`}
          fontSize="14px"
          lineHeight="17.3px"
          fontWeight="400"
        />
        <SecondHorizantalLine lineiconcolor={lineIconColor} bgcolor={bghelp} />
      </HeaderContainer>
      {questions?.type === "MATCH" ? (
        <Text>You can match the choices</Text>
      ) : (
        ""
      )}
      <BodyContainer>
        {questions?.choices?.map((choice, choiceIndex) => {
          if (questions?.type === "MATCH") {
            return (
              <PreviewMatching
                key={choiceIndex}
                choice={choice}
                choiceIndex={choiceIndex}
                choices={questions?.choices}
                selectedValues={selectedValues}
                onSelectChoice={handleSelectChoice}
              />
            );
          } else if (questions?.type === "MCQ") {
            return (
              <PreviewMultipleChoices
                key={choiceIndex}
                choice={choice}
                countChecked={countChecked ? countChecked : 1}
              />
            );
          } else if (questions?.type === "TF") {
            return <PreviewTrueFalse key={choiceIndex} choice={choice} />;
          } else if (questions?.type === "ESSAY") {
            return <PreviewEssay key={choiceIndex} choice={choice} />;
          } else if (
            questions?.type === "SHORT_ANSWER" ||
            questions?.type === "Numerical"
          ) {
            return <PreviewShortAnswer key={choiceIndex} choice={choice} />;
          } else {
            return null;
          }
        })}
      </BodyContainer>
      <ImagesContainer>{/* Images content */}</ImagesContainer>
    </Container>
  );
};

export default PreviewQuestion;
