import React, { FC } from "react";

import { BodyContainer, TextWrap } from "./DropQuestion.style";
import { DropQuestionProps } from "./DropQuestionInterface";
import { MediumTypography } from "../../elements/fonts/Fonts";
import { theme } from "../../theme";
import { useDrop } from "react-dnd";
import { ReactComponent as AddIcon } from "../../assets/icons/Add (1).svg";
const DropQuestion: FC<DropQuestionProps> = ({
  questions,
  FillBranch,
  qindex,
  OnBranchTypeChange,
}) => {
  const [, drop] = useDrop({
    accept: "ITEMS",
    drop: (droppedItem: any) => {
      const { data } = droppedItem;
      FillBranch && FillBranch(data, qindex || 0);
      OnBranchTypeChange && OnBranchTypeChange(data?.name || "");
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  return (
    <BodyContainer ref={drop}>
      <TextWrap>
        <MediumTypography
          text="Drag and Drop a question type to start editing"
          fontSize="1rem"
        />
      </TextWrap>
    </BodyContainer>
  );
};

export default DropQuestion;
