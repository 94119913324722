import {
  DragDropWrapper,
  LogoAndLabel,
  LogoLabelTextColumn,
  TextRow,
  TopIconsRow,
  GroupsHolder,
  ColorPickerLayout,
} from "./AddGroupdragDrop.style";
import {
  SvgEllipse,
  SvgSave2Icon,
  SvgEdit,
  SvgShuffle,
  SvgAdd,
} from "../../elements/Icons";
import { Typography, LightTypography } from "../../elements/fonts/Fonts";
import GroupCard from "../../components/groupCard/GroupCard";
import useClickOutside from "../../hooks/useClickOutside";
import useToggle from "../../hooks/useToggle";
import ColorPickerMenu from "./colorPickerMenu/ColorPickerMenu";
import { useState, useRef, useEffect } from "react";

import {
  GroupCardDataInterface,
  HitDragDataStudent,
} from "../groupCard/groupCardInterface";
import ColorPicker from "./colorPickerMenu/ColorPickerMenu";

// import { useColor } from './ColorContext';
const AddGroupDragDrop = ({
  toggleMemberbar,
}: {
  toggleMemberbar: () => void;
}): JSX.Element => {
  const [groupCards, setGroupCards] = useState<GroupCardDataInterface[]>([
    // {
    //     name: 'Group A',
    //     borderColor: '#7DC5D3',
    //     number: '0 prtcs',
    //     students: []
    // }
  ]);

  const { isOpen: isDropdownVisible, toggle: toggleDropdown } =
    useToggle(false);
  const dropdownRef = useClickOutside(isDropdownVisible, toggleDropdown);

  const addGroupCard = () => {
    const updateGroup = [...groupCards];
    const groupName = `Group ${String.fromCharCode(65 + groupCards.length)}`;
    const newGroupCard = {
      name: groupName,
      bordercolor: selectedColor,
      number: "0 prtcs",
      students: [],
      text: "Drag and drop students here",
      count: 0,
    };
    updateGroup.push(newGroupCard);
    setGroupCards(updateGroup);
  };
  const [selectedColor, setSelectedColor] = useState("");
  const handleColorChange = (color: string) => {
    setSelectedColor(color);
  };
  const handleStudentDrop = (
    groupIndex: number,
    e: CustomEvent<HitDragDataStudent<any>>
  ) => {
    const data = e.detail.dragData;
    const updatedGroupCards = [...groupCards];
    const student = {
      firstName: data.firstName,
      lastName: data.lastName,
      profilePicture: data.profilePicture,
      // dragged: false, // Include the dragged property
    };
    updatedGroupCards[groupIndex].students.push(student);
    updatedGroupCards[groupIndex].count =
      updatedGroupCards[groupIndex].students.length;

    updatedGroupCards[
      groupIndex
    ].number = `${updatedGroupCards[groupIndex].count} prtcs`;
    // console.log('Updated groupCards:', updatedGroupCards);
    setGroupCards(updatedGroupCards);
  };
  //   const { borderColor } = useColor();
  return (
    //Big Box on the left
    <DragDropWrapper>
      <TopIconsRow>
        <SvgAdd onClick={addGroupCard} />
        <SvgShuffle />
        <div
          // ref={dropdownRef}
          onClick={() => {
            toggleDropdown();
            // setActiveTab((prev:any) => !prev);
          }}
        >
          <SvgEdit />
        </div>
        {/* <SvgEdit /> */}
        <SvgSave2Icon />
      </TopIconsRow>
      <GroupsHolder>
        {isDropdownVisible && (
          <ColorPickerLayout>
            <ColorPickerMenu onColorChange={handleColorChange} />
          </ColorPickerLayout>
        )}
        {/* rendering the group card added to the array*/}

        {groupCards.map((groupCard, index) => (
          <GroupCard
            toggleMemberBar={toggleMemberbar}
            key={index}
            bordercolor={selectedColor}
            name={groupCard.name}
            number={groupCard.number}
            text="Drag and drop students here"
            students={groupCard.students}
            handleStudentDrop={(e: CustomEvent<HitDragDataStudent<any>>) =>
              handleStudentDrop(index, e)
            }
          />
        ))}
      </GroupsHolder>
      {/* if the card was empty from students*/}

      {groupCards.length === 0 && (
        <LogoLabelTextColumn>
          <LogoAndLabel>
            <SvgEllipse onClick={addGroupCard} />
            <Typography
              text="Add group"
              fontSize="1.5rem"
              fontWeight="400"
              lineHeight="normal"
            />
          </LogoAndLabel>
          <TextRow>
            <LightTypography
              text="Then drag and drop students into it, or you can just choose to randomize."
              fontSize="1rem"
              fontWeight="400"
              lineHeight="1.375rem"
            />
          </TextRow>
        </LogoLabelTextColumn>
      )}
    </DragDropWrapper>
  );
};

export default AddGroupDragDrop;
