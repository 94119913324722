import React, { useContext, useEffect, useState } from "react";
import { ContentR } from "../../../CourseTimeline/utils/CourseResourcesInterface";
import axios from "axios";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../../../utils/StringManipulation";
import { useAuth0 } from "@auth0/auth0-react";
import { ClassContext } from "../../../../Views/dashboard/UnstructuredContext";
import {
  LightTypography,
  MediumTypography,
} from "../../../../elements/fonts/Fonts";
import {
  SvgCheck,
  SvgClock,
  SvgFalse,
  SvgPlay,
} from "../../../../elements/Icons";
import { ActivityIconTitle, SubmissionStyle } from "./ActivityCard.style";
import moment from "moment";
import { Link } from "react-router-dom";
import { getResourceIcon } from "../../../../utils/ResourceIcon";
import { ActivityProps } from "../../dashboardContentInterfaces";
import { useTranslation } from "react-i18next";
import { get } from "../../../../utils/AxiosRequests";

const getResourceUrl = (
  activityR: ContentR,
  courseId: string,
  subIdSplitted: string,
  role: string,
  provider: string
) => {
  const tenantName = localStorage.getItem("tenant");

  const baseUrl = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/";
  switch (activityR.type) {
    case "Assignment":
      if (role === "student" || role === "auditor")
        return `${baseUrl}assignment/get/${tenantName}/${activityR.refId}/${subIdSplitted}?provider=${provider}`;
      else
        return `${baseUrl}assignment/get/${tenantName}/${activityR.refId}/${subIdSplitted}?provider=${provider}`;
    case "Quiz":
      return `${baseUrl}quiz/questions/${activityR.refId}/${tenantName}`;
    case "Online_Session":
      return `${baseUrl}activity/onlineSession/${activityR.refId}/${subIdSplitted}/${courseId}/${tenantName}?provider=${provider}`;
    case "Teams":
      return `https://k9jjuskf3i.execute-api.eu-west-1.amazonaws.com/dev/teamsOnlineSession/${activityR.refId}/${subIdSplitted}/${courseId}/${tenantName}?provider=${provider}`;
    case "Survey":
      return `${baseUrl}survey/get/submit/${activityR.refId}/${subIdSplitted}/${tenantName}?provider=${provider}`;
    case "Forum":
      return `${baseUrl}forum/grade/${subIdSplitted}/${activityR.refId}/${tenantName}?provider=${provider}`;
    default:
      return "";
  }
};

export default function ActivityCard({
  activityR,
  courseId,
  moduleIndex,
  topicIndex,
}: ActivityProps) {
  const tenantName = localStorage.getItem("tenant");

  const { role, setRole } = useContext(ClassContext);
  const [resourceData, setResourceData] = useState<any>();
  const [onlineSessionStatus, setOnlineSessionStatus] = useState<any>();
  const [loading, setLoading] = useState<Boolean>(true);
  const [assignmentSubmitted, setAssignmentSubmitted] = useState<Boolean>();
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          getResourceUrl(activityR, courseId, subIdSplitted, role, provider)
        );
        if (response) {
          if (activityR.type === "Online_Session") {
            const status = getSessionStatus(response.data.data, activityR);
            setOnlineSessionStatus(status);
          }
          if (activityR.type === "Teams") {
            const status = getSessionStatus(response.data.data, activityR);
            setOnlineSessionStatus(status);
          }
          if (activityR.type === "Assignment") {
            if (
              response.data.data.message &&
              response.data.data.message.startsWith(
                "Assignment already submitted"
              )
            ) {
              setAssignmentSubmitted(true);
            } else {
              setAssignmentSubmitted(false);
            }
          }
          if (activityR.type === "Survey") {
            if (response.data.data && response.data.data.length > 0)
              setAssignmentSubmitted(true);
            else {
              setAssignmentSubmitted(false);
            }
          }
          if (activityR.type === "Forum") {
            if (response.data.data.Posts && response.data.data.Posts.length > 0 && response.data.data.Posts[0].submissions && response.data.data.Posts[0].submissions.length > 0)
              setAssignmentSubmitted(true);
            else {
              setAssignmentSubmitted(false);
            }
          }
          if (activityR.type === "Quiz") {

            const studentAnswerData = await get(
              `quiz/get/answer/${subIdSplitted}/${activityR.refId}/${tenantName}?provider=${provider}`
            );
            if (studentAnswerData.data && studentAnswerData.data === true)
              setAssignmentSubmitted(true);
            else {
              setAssignmentSubmitted(false);
            }
          }
          setResourceData(response.data.data);
          setLoading(false);
        }
      } catch (error) {
        console.error(`Error fetching ${activityR.type} resource:`, error);
      }
    };

    fetchData();
  }, [activityR, courseId]);
  const icon = assignmentSubmitted ? <SvgCheck width={"1.25rem"} style={{ transform: "translateX(-0.125rem)" }} /> : <SvgFalse />;
  const text = assignmentSubmitted ? `${t('dashboard.Submitted')}` : `${t('dashboard.Not Submitted')}`;
  const color = assignmentSubmitted ? "#5dd3b3" : "var(--Red, #D85D66)";
  const sessionIcon =
    onlineSessionStatus === `${t('dashboard.Not Started')}` ? (
      <SvgClock />
    ) : onlineSessionStatus === `${t('dashboard.Ended')}` ? (
      <SvgFalse />
    ) : onlineSessionStatus === `${t('dashboard.Join Now')}` ? (
      <SvgPlay width={"1.25rem"} />
    ) : (
      ""
    );

  const getSessionStatus = (activity: any, activityR: any) => {

    if (
      activityR.type == "Online_Session" || activityR.type == "Teams" &&
      activity.session &&
      activity.session.startDateTime &&
      activity.session.endDateTime
    ) {
      const currentDateTime = moment();
      const startDateTime = moment(activity.session.startDateTime);
      const endDateTime = moment(activity.session.endDateTime);
      if (endDateTime <= currentDateTime) {

        return `${t('dashboard.Ended')}`;
      } else if (startDateTime >= currentDateTime) {

        return `${t('dashboard.Not Started')}`;
      } else if (
        startDateTime <= currentDateTime &&
        endDateTime >= currentDateTime
      ) {
        return `${t('dashboard.Join Now')}`;
      }
    }
    return "default";
  };
  return !loading ? (
    <>
      <div style={{ width: "100%" }}>
        {resourceData && (
          <div style={{ paddingRight: "1.88rem" }}>
            {(activityR.type === "Assignment" ||
              activityR.type === "Forum" ||
              activityR.type === "Quiz" ||
              activityR.type === "Survey") && (
                <Link
                  to={`/courseView/content/${courseId}`}
                  state={{
                    activity: activityR,
                    moduleIndex: moduleIndex,
                    topicIndex: topicIndex,
                    contentIndex: activityR.contentIndex,
                    contentId: activityR.contentId,
                    isVisible: activityR?.isVisible
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <ActivityIconTitle>
                      {getResourceIcon(activityR.type || "").icon}
                      <MediumTypography
                        text={activityR.contentTitle}
                        fontSize="0.75rem"
                      />{" "}
                    </ActivityIconTitle>
                    <SubmissionStyle
                      style={{
                        // columnGap: assignmentSubmitted ? "2.13rem" : "0.63rem",
                        justifyContent: "space-between",
                        width: "7rem"
                      }}
                    >
                      {icon}
                      <LightTypography
                        text={text}
                        fontSize="0.75rem"
                        color={color}
                      />
                    </SubmissionStyle>
                  </div>
                </Link>
              )}
            {/* {activityR.type === "Quiz" && (
              <Link
                to={`/courseView/content/${courseId}`}
                state={{
                  activity: activityR,
                  moduleIndex: moduleIndex,
                  topicIndex: topicIndex,
                  contentIndex: activityR.contentIndex,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <ActivityIconTitle>
                    {getResourceIcon(activityR.type || "").icon}
                    <MediumTypography
                      text={activityR.contentTitle}
                      fontSize="0.75rem"
                    />
                  </ActivityIconTitle>
                  <SubmissionStyle
                    style={{
                      // columnGap: assignmentSubmitted ? "2.13rem" : "0.63rem",
                      justifyContent: "space-between",
                      width: "7rem"
                    }}
                  >
                    {icon}
                    <LightTypography
                      text={text}
                      fontSize="0.75rem"
                      color={color}
                    />
                  </SubmissionStyle>
                </div>
              </Link>
            )} */}
            {(activityR.type === "Online_Session" || activityR.type === "Teams") && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Link
                  to={`/courseView/content/${courseId}`}
                  state={{
                    activity: activityR,
                    moduleIndex: moduleIndex,
                    topicIndex: topicIndex,
                    contentIndex: activityR.contentIndex,
                    contentId: activityR.contentId,
                    isVisible: activityR?.isVisible
                  }}
                >
                  <ActivityIconTitle>
                    {getResourceIcon(activityR.type || "").icon}
                    <MediumTypography
                      text={activityR.contentTitle}
                      fontSize="0.75rem"
                    />
                  </ActivityIconTitle>
                </Link>
                <SubmissionStyle
                  onlineSessionStatus={onlineSessionStatus}
                  onClick={() => {
                    onlineSessionStatus === `${t('dashboard.Join Now')}` &&
                      window.open(resourceData.path, "_blank");
                  }}
                  style={{
                    justifyContent: "space-between",
                    width: "7rem",
                    // columnGap: onlineSessionStatus === `${t('dashboard.Join Now')}` ? "2.5rem"
                    //   : onlineSessionStatus === `${t('dashboard.Ended')}` ? "3.7rem"
                    //     : onlineSessionStatus === `${t('dashboard.Not Started')}` ? "1.7rem" : "",
                    color: onlineSessionStatus === `${t('dashboard.Join Now')}` ? "#5dd3b3"
                      : onlineSessionStatus === `${t('dashboard.Ended')}` ? "#D85D66"
                        : onlineSessionStatus === `${t('dashboard.Not Started')}` ? "White" : ""
                  }}
                >
                  {sessionIcon}
                  <LightTypography
                    text={onlineSessionStatus}
                    fontSize="0.75rem"

                  />
                </SubmissionStyle>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  ) : (
    <div>{`${t('general.loading')}...`} </div>
  );
}
