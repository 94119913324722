import styled from "styled-components";
import { breakpoints, theme } from "../../theme";

interface InputsHolderProps {
  display?: boolean;
}

interface SketchPickerHolderProps {
  top?: string;
}

export const Overlay = styled.div<{ isvisible: string }>`
  display: ${(props) => (props.isvisible ? "block" : "none")};
  opacity: ${(props) => (props.isvisible ? "1" : "0")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 300vh;
  transition: opacity 500ms;
  overflow: auto;
  z-index: 10;
`;

export const BackgroundOverlay = styled.div`
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  min-height: 100%;
`;

export const PopupContainer = styled.div`
  margin: auto auto;
  width: max-content;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupContentContainer = styled.div`
  min-width: fit-content;
  min-height: fit-content;
  width: 55vw;
  border-left: 4px solid #5dd3b3;
  background-color: #384450;
  border-radius: 0rem 0.625rem 0.625rem 0rem;
  padding: 1.25rem 0.5rem 1.25rem 1.87rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.62rem;
  position: relative;
  @media (min-width: ${breakpoints.large}) {
    width: 52vw;
  }
  @media (max-width: ${breakpoints.medium}) {
    width: 65vw;
  }
  @media (max-width: ${breakpoints.tablets}) {
    width: 85vw;
  }
  @media (max-width: ${breakpoints.mobile}) {
    width: 90vw;
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.62rem;
`;

export const IconsWrapper = styled.div`
  width: 100%;
  height: 3.035rem;
  display: flex;
  justify-content: space-between;
  padding-right: 1.37rem;

  & > :first-child {
    align-self: flex-end;
  }
  & > :last-child {
    cursor: pointer;
    color: ${({ theme }) => theme.dark.main_color};
    align-self: flex-start;
  }
`;

export const EditShareDownloadWrapper = styled.div`
  margin-top: 0.625rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 1.25rem;
  height: fit-content;
  padding-right: 1.37rem;
`;

export const IconWithTextHolder = styled.div`
  color: ${({ theme }) => theme.dark.main_color};
  opacity: 0.9;
  cursor: pointer;
  display: flex;
  column-gap: 0.625rem;
  align-items: center;
  &:hover {
    opacity: 1;
    transition: opacity 0.2s ease-in;
  }
`;

export const ScrollableCertificateWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 75vh;
  padding-right: 0.87rem;
  // background-color: gray;
  &::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(
      --Green-Gradient,
      linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)
    );
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #576371;
    border-radius: 0.625rem;
  }
`;

export const ButtonsHolder = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.62rem;
  padding-right: 1.2rem;
  margin-top: 0.63rem;
`;

export const EditSectionHolder = styled.div`
  margin-top: 1.25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;
export const TextFieldHolder = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 1.25rem;
`;

export const UploadColorHolder = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 1.25rem;
`;

export const UploadsWrapperWithBadge = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  grid-column-gap: 0.438rem;
  grid-row-gap: 1.25rem;
`;

export const GridItem1WithBadge1 = styled.div`
  grid-column: 1/2;
  @media screen and (max-width: 968px) {
    grid-column: 1/4;
  }
`;

export const GridItem1WithBadge2 = styled.div`
  grid-column: 2/3;
  @media screen and (max-width: 968px) {
    grid-column: 1/4;
  }
`;

export const GridItem1WithBadge3 = styled.div`
  grid-column: 3/4;
  @media screen and (max-width: 968px) {
    grid-column: 1/4;
  }
`;

export const UploadsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 1.25rem;
`;

export const TextFieldElementWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 1.25rem;
`;

export const LabelWithInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.625rem;
  min-width: 14.563rem;
`;

export const Input = styled.input`
  width: 100%;
  height: 2.8125rem;
  padding: 0.94rem 1.25rem;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  background-color: transparent;
  color: #fff;
  font-family: ${(props) => props.theme.font.primaryRegular};

  &::placeholder {
    color: #fff;
    opacity: 0.5;
    font-size: 0.75rem;
    font-family: "Primary Font-Light";
  }
  &:focus {
    outline: none;
    caret-color: #5dd3b3;
  }
`;
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 1.25rem;
`;

export const GridItem1 = styled.div`
  grid-column: 1/2;
  @media screen and (max-width: 968px) {
    grid-column: 1/3;
  }
`;

export const GridItem2 = styled.div`
  grid-column: 2/3;

  @media screen and (max-width: 968px) {
    grid-column: 1/3;
  }
`;

export const ColorSectionMainHolder = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
`;

export const ColorSection = styled.div`
  position: relative;
  display: flex;
  column-gap: 2.5rem;
  justify-content: flex-start;
  /* background-color: gray; */
  margin-bottom:0.31rem;
`;

interface ColorCircleProps {
  colorCircle: string;
}

export const ColorCircle = styled.div<ColorCircleProps>`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background-color: ${(props) => props.colorCircle};
  cursor: pointer;
  position: relative;
`;

export const TextWithCircleColor = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.625rem;
  text-align: start;
`;

export const TextWithBorder = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.625rem;
  text-align: start;
  margin-top: 0.625rem;
`;

export const BorderInput = styled.input`
  width: 3.125rem;
  height: 2.813rem;
  text-align: center;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  background-color: transparent;
  color: #fff;
  font-family: ${(props) => props.theme.font.primaryLight};
  font-size: 0.875rem;

  &::placeholder {
    color: #fff;
    opacity: 0.5;
    font-size: 0.625rem;
    font-family: "Primary Font-Light";
  }
  &:focus {
    outline: none;
    caret-color: #5dd3b3 !important;
  }
`;

export const SketchPickerHolder = styled.div<SketchPickerHolderProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: ${({ top }) => (top ? top : `-17.5rem`)};
  z-index: 35;
`;

export const SketchPickerClose = styled.div`
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.dark.bg_secondary_color};
  padding: 0.3rem;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
`;

export const SketchPickerSave = styled.div`
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.dark.bg_secondary_color};
  padding: 0.3rem;
  position: absolute;
  bottom: 0.45rem;
  right: 0.5rem;
`;
