 interface SurveyReusableTableInterface {
    text: string;

}

export const SurveyReusableTableData : SurveyReusableTableInterface[] =[

  {text: "Status"},
  {text: "Time Spent"},

]