import styled from "styled-components";


export const AnalyticMainBoxContainer =styled.div`
display: flex;
width: 100%;
padding:1.25rem 0;
flex-direction:column;
border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
`;
export const ComplexSelectContainer =styled.div`
display: flex;
width: 100%;
flex-direction:column;
`;
export const ComplexSelectRow =styled.div`
display: flex;
width: 100%;
justify-content:space-between;
padding:1.25rem;
align-items:center;


`;
export const ComplexSelectTable =styled.div`
display: flex;
width: 100%;
flex-direction:column;
min-height:20rem;
`;

export const ComplexSelectTableHeaderRow =styled.div`
display: flex;
width: 100%;
gap:0.1rem;
justify-content:space-between;
/* opacity: 0.5; */
background: var(--Grey-Gradient, linear-gradient(90deg, #415160 0%, #293440 107.88%));
/* padding:1.25rem; */
`;

export const ComplexSelectBodyTable =styled.div`
display: flex;
width: 100%;
flex-direction:column;

`;

export const ComplexSelectTableBodyRow =styled.div`
display: flex;
width: 100%;
gap:0.1rem;
justify-content:space-between;
align-items:center;

&:nth-child(odd){
  background: rgba(255, 255, 255, 0.03);
}
`;


export const AnalyticHeader = styled.div`
display: flex;
flex-direction:column;
width:100%;
`;


export const AnalyticFilterLine = styled.div`
display: flex;
gap:0.63rem;
/* width:100%; */
`;

export const ComplexSelectGreenBox =styled.div`
display: flex;
min-width: 7.1875rem;
height: 2.125rem;
color: #5DD3B3;
border:1px solid #5DD3B3;
justify-content:center;
align-items:center;
transition: background-color 0.3s ease;
padding:0.5rem 0.8rem 0.5rem 0.5rem;
border-radius:0.45rem;
cursor:pointer;
&:hover{
  background:#5DD3B3;
  color: #fff;
}
`;





////////////////////SurveyComplexProfileTable styles/////
export const SurveyComplexProfileTableContainer =styled.div`
display:flex;
align-items:center;
gap:0.40rem;
margin-top:-1.1rem;
margin-left:0.3rem;
`;

////////////////////SurveyComplexProfileTable styles/////

export const AnalyticRefreshIcon =styled.div`
display:flex;
color: #5DD3B3;
cursor: pointer;
height:2.1875rem;
width: 2.1875rem;
padding: 0.3rem;
margin-left:0.5rem;
`;
