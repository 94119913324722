import { ActionsHeaderText, IdTableAttributes } from "../../../interfaces/AnalyticsCommonInterfaces";


export const ActionsAnalyticsForumData : ActionsHeaderText[] =[
  {
    id:"sf4356yth",
    text:"Student",
  },
  {
    id:"saf33456ujfds",
    text:"Status",
  },{
    id:"asd43rdesd",
    text:"Submission Date",
  },
  {
    id:"eretruo",
    text:"Last Modified",
  },
  {
    id:"saf45resdcf",
    text:"Submission",
  },
  {
    id:"weretg1354tyh",
    text:"Apply Penalty",
  },
  {
    id:"23t4gbdscasd",
    text:"Grade",
  },
]

export const AnalyticsForumData: IdTableAttributes = {
  id:"Sadsgfds",
StartDate:new Date("2023-10-01T10:30:00Z"),
CutOffDate:new Date("2023-10-02T10:30:00Z"),
DueDate:new Date("2023-15-01T10:30:00Z"),
  Students:[
  {
    StdCode: "sf4gddef324",
    Name: "Nour Mohammad",
    ProfilePic: require("../../../assets/NourInstructor.jpg"),
    Status: "Submitted",
    SubmissionDate: "23 Aug, 2023 | 8:00 AM",
    LastModified: "24 Aug, 2023 | 8:00 AM",
    SubmissionFiles: [{ File: "sdfdg/dsfd.pdf" }, { File: "sdfdg/safgdsfd.pdf" }],
    ApplyPenalty: "No",
  },
  {
    StdCode: "saf43tyhvc",
    Name: "Ali Mohammad",
    ProfilePic: require("../../../assets/AliAhmad.png"),
    Status: "Unsubmitted",
    SubmissionDate: "23 Aug, 2023 | 8:00 AM",
    LastModified: "24 Aug, 2023 | 8:00 AM",
    SubmissionFiles: [{ File: "sdfdg/dsfd.jpeg" }, { File: "sdfdg/safgdsfd.mp4" }],
    ApplyPenalty: "No",
  },
  {
    StdCode: "sdf4gddef324",
    Name: "John Doe",
    ProfilePic: require("../../../assets/AliAhmad.png"),
    Status: "Submitted",
    SubmissionDate: "25 Aug, 2023 | 10:30 AM",
    LastModified: "26 Aug, 2023 | 9:45 AM",
    SubmissionFiles: [{ File: "sdfdg/dsfd.docx" }, { File: "sdfdg/safgdsfd.txt" }],
    ApplyPenalty: "No",
  },
  {
    StdCode: "saf43tyhvc",
    Name: "Emily Smith",
    ProfilePic: require("../../../assets/AyaInstructor.jpg"),
    Status: "Unsubmitted",
    SubmissionDate: "25 Aug, 2023 | 10:30 AM",
    LastModified: "26 Aug, 2023 | 9:45 AM",
    SubmissionFiles: [{ File: "sdfdg/dsfd.png" }, { File: "sdfdg/safgdsfd.doc" }],
    ApplyPenalty: "No",
  },
  {
    StdCode: "sdf23e23h45",
    Name: "Alex Johnson",
    ProfilePic: require("../../../assets/KhaledInstructor.jpg"),
    Status: "Submitted",
    SubmissionDate: "27 Aug, 2023 | 11:15 AM",
    LastModified: "28 Aug, 2023 | 8:30 AM",
    SubmissionFiles: [{ File: "sdfdg/dsfd.pdf" }, { File: "sdfdg/safgdsfd.jpg" }],
    ApplyPenalty: "Yes",
  },
  {
    StdCode: "asf3r32y23",
    Name: "Emma Watson",
    ProfilePic: require("../../../assets/AyaInstructor.jpg"),
    Status: "Submitted",
    SubmissionDate: "29 Aug, 2023 | 9:45 AM",
    LastModified: "30 Aug, 2023 | 10:00 AM",
    SubmissionFiles: [{ File: "sdfdg/dsfd.xlsx" }, { File: "sdfdg/safgdsfd.docx" }],
    ApplyPenalty: "No",
  },
  {
    StdCode: "fghsdf3245",
    Name: "Michael Smith",
    ProfilePic: require("../../../assets/userProfile.png"),
    Status: "Unsubmitted",
    SubmissionDate: "1 Sep, 2023 | 11:30 AM",
    LastModified: "2 Sep, 2023 | 8:15 AM",
    SubmissionFiles: [{ File: "sdfdg/dsfd.jpg" }, { File: "sdfdg/safgdsfd.png" }],
    ApplyPenalty: "Yes",
  },
  {
    StdCode: "23dfg324sd",
    Name: "Sophia Johnson",
    ProfilePic: require("../../../assets/AliAhmad.png"),
    Status: "Submitted",
    SubmissionDate: "5 Sep, 2023 | 3:45 PM",
    LastModified: "6 Sep, 2023 | 10:30 AM",
    SubmissionFiles: [{ File: "sdfdg/dsfd.txt" }, { File: "sdfdg/safgdsfd.mp3" }],
    ApplyPenalty: "No",
  },
  {
    StdCode: "hj234sdvsd",
    Name: "William Brown",
    ProfilePic: require("../../../assets/AliAhmad.png"),
    Status: "Unsubmitted",
    SubmissionDate: "9 Sep, 2023 | 8:00 AM",
    LastModified: "10 Sep, 2023 | 11:45 AM",
    SubmissionFiles: [{ File: "sdfdg/dsfd.mp4" }, { File: "sdfdg/safgdsfd.xlsx" }],
    ApplyPenalty: "Yes",
  },
  {
    StdCode: "7sdfg4sdfg",
    Name: "Olivia Taylor",
    ProfilePic: require("../../../assets/AliAhmad.png"),
    Status: "Submitted",
    SubmissionDate: "13 Sep, 2023 | 1:30 PM",
    LastModified: "14 Sep, 2023 | 9:00 AM",
    SubmissionFiles: [{ File: "sdfdg/dsfd.doc" }, { File: "sdfdg/safgdsfd.png" }],
    ApplyPenalty: "No",
  },
  {
    StdCode: "98sdf4gfdg",
    Name: "Daniel Martinez",
    ProfilePic: require("../../../assets/AliAhmad.png"),
    Status: "Unsubmitted",
    SubmissionDate: "17 Sep, 2023 | 12:15 PM",
    LastModified: "18 Sep, 2023 | 8:45 AM",
    SubmissionFiles: [{ File: "sdfdg/dsfd.mp3" }, { File: "sdfdg/safgdsfd.jpg" }],
    ApplyPenalty: "Yes",
  },
  {
    StdCode: "sdf45gfdfd",
    Name: "Ava White",
    ProfilePic: require("../../../assets/AliAhmad.png"),
    Status: "Submitted",
    SubmissionDate: "21 Sep, 2023 | 4:00 PM",
    LastModified: "22 Sep, 2023 | 10:30 AM",
    SubmissionFiles: [{ File: "sdfdg/dsfd.xlsx" }, { File: "sdfdg/safgdsfd.txt" }],
    ApplyPenalty: "No",
  },
]
};
