import styled from "styled-components";

export const MessageSearchLayout = styled.div`
display: flex;
flex:3;
flex-direction:column;
/* padding:1rem; */
gap:1.25rem;

/* background-color:aqua; */


`;

export const MessageSearchRow = styled.div`
display: flex;
width: 100%;
justify-content:space-between;
align-items:center;
padding:0.5rem 1rem; 
`;

export const SearchCloseView = styled.div`
color:#5DD3B3;
padding:0.3rem;
cursor: pointer;

`;

export const SearchViewElement = styled.div`
height: 3.7rem;
margin-top:-1.4rem;
padding:0.5rem 1rem; 
`;

export const MessageSearchRender = styled.div`
display: flex;
width: 100%;
flex-direction:column;
gap:0.31rem;
overflow-y:auto;
max-height:30rem;
&::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(
      --Green-Gradient,
      linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%)
    );
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0.625rem;
  }
`;
export const MessageSearchCell = styled.div<{bgColor:string}>`
display: flex;
width: 100%;
padding: 0.5rem 1rem 0.56rem 1.25rem;
gap:0.5rem;
flex-direction:column;
/* background: rgba(255, 255, 255, 0.03); */
background:${props =>props.bgColor};
min-height: 3.5625rem;
cursor: pointer;
`;
export const MessageSearchCellText = styled.div`

&:nth-child(1){
  opacity: 1;
  /* background-color:red; */
}
`;