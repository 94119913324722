import React, { useState, FC, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../utils/StringManipulation";
import axios from "axios";
import {
  LightTypography,
  PrimaryFontBoldText,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  QuizContainer,
  HeadContainer,
  FlexCenter,
  LabelDiv,
  QuizInput,
  DateAndTimeDiv,
  RadioDiv,
  RadioDivLabel,
  BtnsDiv,
  LabelBtnsDiv,
  FlexBetween,
  ButtonsDiv,
  DeletePopupMainWrapper,
  CancelButton,
  DeleteButton,
  DeletePopupButtons,
  FlexDiv,
} from "./QuizForm.style";
import { Container } from "../../elements/StyledContainerForm/StyledContainerForm.style";
import { useStateContext } from "../../contexts/ContextProvider";
import SelectDate from "../../elements/DatePicker/inputDate";
import TimePickerComponent from "../../elements/TimePicker/TimePicker";
import CheckBoxInput from "../../elements/checkbox/CheckBox";
import SelectInput from "../../elements/nestedSelect/SelectInput";
import { Options, QuizOptions } from "./QuizForm.data";
import Toggle from "../../elements/Toogle/toogle";
import TwoDatePicker from "../../elements/TwoDatePicker/TwoDatePicker";
import { ReactComponent as SvgAddIcon } from "../../assets/icons/Add.svg";
import { SvgQuiz, SvgClose } from "../../elements/Icons";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import LoaderThreeDot from "../loader/loaderThreeDot/LoaderThreeDot";
import { useQuizMyContext } from "../../contexts/CourseCreatorQuizContext";
import { SvgEyeIcon } from "../../elements/Icons";
import { TrueFalseContainer } from "../QuizCreatorQuestionCRUD/TrueFalseQuestion/TrueFalseQuestion.style";
import { ContentFormProps } from "../../interfaces/ContentFormProps";
import isPositiveNumber from "../../utils/IsPositiveNumber";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import StyledInput from "../../elements/StyledInput/StyledInput";
import { useTranslation } from "react-i18next";
import { WebSocketConnector } from "../../Views/messages/webSocketConnection";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
import NestedSelect from "../../elements/nestedSelect/NestedSelect";
import { WebsocketContext } from "../../contexts/notificationContext";
import { get } from "../../utils/AxiosRequests";

const QuizForm: FC<ContentFormProps> = ({
  setShow,
  handleSubmit,
  setShowEditContent,
  showEditContent,
  setContentTitleInTopic,
  editContent,
  newContentTitle,
  contentId,
  activeGetModulesWithTopics,
  newTopicRefId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classSourceId = useParams();
  const setClassSourceId = classSourceId.id;
  const { isLoading, user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const baseURL = "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev";
  const path2 = "quiz/template";
  const path = "activity/create";
  const tenantName = localStorage.getItem("tenant");
  const {
    setContent_Title,
    contentID,
    contentType,
    setContent_Data,
    getContent_Data,
    contentData,
    contentIdToEdit,
    topicRefId,
    content_Index,
    setGlobalShowEditContent,
    globalShowEditContent,
    setIsPopUpOpen,
  } = useStateContext();
  const {
    globalAttempt,
    globalDuration,
    globalMaxGrade,
    globalPassingGrade,
    globalQuizDescription,
    globalQuizEndDate,
    globalQuizFromDate,
    globalQuizIsTimeVisible,
    globalQuizIsVisible,
    globalQuizName,
    globalQuizShowPopup,
    globalQuizStartDate,
    globalQuizToDate,
    globalSelectedQuiz,
    globalSelectedQuizId,
    globalShowGrade,
    globalUnlimitedAttempts,
    editQuizButton,
    setEditQuiz,
    setGlobalAttempt,
    setGlobalDuration,
    setGlobalMaxGrade,
    setGlobalPassingGrade,
    setGlobalQuizDescription,
    setGlobalQuizEndDate,
    setGlobalQuizFromDate,
    setGlobalQuizIsTimeVisible,
    setGlobalQuizIsVisible,
    setGlobalQuizName,
    setGlobalQuizShowPopup,
    setGlobalQuizStartDate,
    setGlobalQuizToDate,
    setGlobalSelectedQuiz,
    setGlobalSelectedQuizId,
    setGlobalShowGrade,
    setGlobalUnlimitedAttempts,
    setGlobalQuestionsArray,
    setEditQuizButton
  } = useQuizMyContext();

  const [selectedRadio, setSelectedRadio] = useState("grade");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedQuiz, setSelectedQuiz] = useState("");
  const [selectedQuizId, setSelectedQuizId] = useState(0);
  const [showTimeVisibility, setShowTimeVisibility] = useState(false);
  const [quizName, setQuizName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [passingGrade, setPassingGrade] = useState("");
  const [numAttempts, setNumAttempts] = useState("");
  const [duration, setDuration] = useState("");
  const [error, setError] = useState(false);
  const [quizError, setQuizError] = useState(false);
  const [shuffleQuestions, setShuffleQuestions] = useState(false);
  const [visibleStudents, setVisibleStudents] = useState(true);
  const [unlimitedAttempts, setUnlimitedAttempts] = useState(false);
  const [enableReview, setEnableReview] = useState(false);
  const [safeBrowser, setSafeBrowser] = useState(false);
  const [showGrade, setShowGrade] = useState(false);
  const [maxGrade, setMaxGrade] = useState("");
  const [prevData, setPrevData] = useState<any>();
  const [readyToEdit, setReadyToEdit] = useState(true);
  const [foundRefIdState, setFoundRefIdState] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [quizOptions, setQuizOptions] = useState<
    { title: string; submenu?: any }[]
  >([]);
  const { isReady, value, send } = useContext(WebsocketContext);

  // This UseEffect Set the Global Questions Array in the Quiz Creator to default
  useEffect(() => {
    setGlobalQuestionsArray([
      {
        question: "",
        questType: "normal",
        type: "",
        grade: "",
        icon: <></>,
        questPart: [],
        choices: [] as {
          name: string;
          percentage?: string;
          isChecked?: boolean;
        }[],
        feedRight: "Your Answer is correct.",
        feedWrong: "Your Answer is Incorrect.",
        subId: subId,
        classSourceId: setClassSourceId,
      },
    ]);
  }, []);

  const formatDate = (dueDate: any) => {
    const date = new Date(dueDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Function to update form
  const updateQuiz = async (path: string, body: any) => {
    try {
      const response = await axios.put(`${baseURL}/${path}/${tenantName}`, {
        data: body,
      });
      return response.data;
    } catch (error) {
      console.error("Error in PUT request:", error);
      throw error; // Rethrow the error to be handled by the calling service
    }
  };

  // Function to get data
  const getQuizToEdit = async (path: string, quizId: number) => {
    try {
      const res = await axios.get(`${baseURL}/${path}/${quizId}/${tenantName}`);
      setPrevData(res.data.data);
      setReadyToEdit(false);
      const toReturn: any[] = res.data.data;
      return toReturn;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if ((showEditContent || globalShowEditContent) && globalQuizName !== "") {
      setReadyToEdit(false);
    }
  }, [globalQuizName]);

  // This useEffect hook is used to fetch data when showEditContent is true.
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const yourArray = await activeGetModulesWithTopics();
      const solutionArray: any[] = Array.isArray(yourArray) ? yourArray : [];
      function findRefIdInModules(
        modulesArray: any[],
        targetContentId: number
      ) {
        for (let i = 0; i < modulesArray.length; i++) {
          const topicsArray = modulesArray[i].topicsArray;

          for (let j = 0; j < topicsArray.length; j++) {
            const contentsArray = topicsArray[j].contents;

            for (let k = 0; k < contentsArray.length; k++) {
              if (contentsArray[k].contentId === targetContentId) {
                return contentsArray[k].refId;
              }
            }
          }
        }
        // Return a default value (e.g., null) if the contentId is not found
        return null;
      }
      if (contentIdToEdit) {
        const contentIdToFind = contentIdToEdit; // Replace with the specific contentId you're looking for
        const foundRefId = findRefIdInModules(solutionArray, contentIdToFind);
        setFoundRefIdState(foundRefId);

        await getQuizToEdit("quiz/questions", foundRefId);
        setLoading(false);
      }
    };
    if (showEditContent || globalShowEditContent) {
      fetchData();
      // setReadyToEdit(false);
    }
  }, []);

  // This useEffect hook is used to update the states with edit data (prevData)
  useEffect(() => {
    if (prevData && showEditContent) {
      setContent_Title(prevData.titleActivity);
      setQuizName(prevData.titleActivity);
      setGlobalQuizName(prevData.titleActivity);
      setDescription(prevData.Description);
      setGlobalQuizDescription(prevData.Description);
      setMaxGrade(prevData.maxGrade);
      setGlobalMaxGrade(prevData.maxGrade);
      setPassingGrade(prevData.passingGrade);
      setGlobalPassingGrade(prevData.passingGrade);
      setNumAttempts(prevData.numberOfAttempts);
      setGlobalAttempt(prevData.numberOfAttempts);
      setShowGrade(prevData.showGradeAfterSubmission);
      setGlobalShowGrade(prevData.showGradeAfterSubmission);
      setShowTimeVisibility(
        prevData.visibleFrom === null ||
          prevData.visibleTo === null ||
          prevData.visibleFrom === "" ||
          prevData.visibleTo === ""
          ? false
          : true
      );
      setGlobalQuizIsTimeVisible(
        prevData.visibleFrom === null ||
          prevData.visibleTo === null ||
          prevData.visibleFrom === "" ||
          prevData.visibleTo === ""
          ? false
          : true
      );
      setVisibleStudents(prevData.isVisible);
      setGlobalQuizIsVisible(prevData.isVisible);
      setSelectedQuizId(prevData.templateId);
      setGlobalSelectedQuizId(prevData.templateId);
      let foundQuizTitle: string | undefined = undefined;
      if (quizOptions.length > 0) {
        for (const option of quizOptions) {
          if (option.submenu) {
            for (const item of option.submenu) {
              if (item.submenu) {
                const foundQuiz = item.submenu.find(
                  (quiz: any) => quiz.id === prevData.templateId
                );
                if (foundQuiz) {
                  foundQuizTitle = foundQuiz.title;
                  break;
                }
              }
              if (item.id === prevData.templateId) {
                foundQuizTitle = item.title;
                break;
              }
            }
          }
          if (foundQuizTitle) {
            break;
          }
        }

        if (foundQuizTitle) {
          console.log("prevData.title --->", prevData.title)
          setSelectedQuiz(foundQuizTitle);
          setGlobalSelectedQuiz(foundQuizTitle);
        }else{
          setSelectedQuiz(prevData.title);
          setGlobalSelectedQuiz(prevData.title);
        }
      }

      setDuration(prevData.duration);
      setGlobalDuration(prevData.duration);
      setStartDate(
        prevData.startDateTime ? new Date(prevData.startDateTime) : null
      );
      setGlobalQuizStartDate(
        prevData.startDateTime ? new Date(prevData.startDateTime) : null
      );
      setEndDate(
        prevData.cutOffDateTime ? new Date(prevData.cutOffDateTime) : null
      );
      setGlobalQuizEndDate(
        prevData.cutOffDateTime ? new Date(prevData.cutOffDateTime) : null
      );
      setFromDate(prevData.visibleFrom ? new Date(prevData.visibleFrom) : null);
      setGlobalQuizFromDate(
        prevData.visibleFrom ? new Date(prevData.visibleFrom) : null
      );
      setToDate(prevData.visibleTo ? new Date(prevData.visibleTo) : null);
      setGlobalQuizToDate(
        prevData.visibleTo ? new Date(prevData.visibleTo) : null
      );
      setUnlimitedAttempts(prevData.numberOfAttempts === -1 ? true : false);
      setGlobalUnlimitedAttempts(
        prevData.numberOfAttempts === -1 ? true : false
      );
    }
    // getContent_Data(contentID, "Quiz");
  }, [prevData, quizOptions]);

  const handleToggleChangeRubric = (isChecked: boolean) => {
    setSelectedRadio(isChecked ? "rubric" : "grade"); // Update the selected radio
  };

  // Function to handle description
  const handleDescriptionChange = (value: any) => {
    setDescription(value);
    setGlobalQuizDescription(value);
  };

  const handleSelectChange = (value: any) => {
    setSelectedTitle(value); // Update the selectedTitle state
  };

  // Function to set the name of selected quiz
  const handleSelectQuiz = (value: any) => {
    setSelectedQuiz(value); // Update the selectedTitle state
    setGlobalSelectedQuiz(value);
  };

  // Function to set the id of selected quiz
  const handleSelectQuizId = (value: any) => {
    setSelectedQuizId(value);
    setGlobalSelectedQuizId(value);
  };

  const handleShuffleQuestions = (isChecked: boolean) => {
    setShuffleQuestions(isChecked);
  };

  const handleVisibleStudents = (isChecked: boolean) => {
    setVisibleStudents(isChecked);
    setGlobalQuizIsVisible(isChecked);
  };

  // Function to handle enabling/disabling unlimited attempts
  const handleUnlimitedAttempts = (isChecked: boolean) => {
    if (isChecked === true) {
      setUnlimitedAttempts(true);
      setGlobalUnlimitedAttempts(true);
      setGlobalAttempt("-1");
      setNumAttempts("-1");
    } else {
      setUnlimitedAttempts(false);
      setGlobalUnlimitedAttempts(false);
      setGlobalAttempt("1");
      setNumAttempts("1");
    }
  };

  const handleEnableReview = (isChecked: boolean) => {
    setEnableReview(isChecked);
  };

  const handleSafeBrowser = (isChecked: boolean) => {
    setSafeBrowser(isChecked);
  };

  const handleShowGrade = (isChecked: boolean) => {
    setShowGrade(isChecked);
    setGlobalShowGrade(isChecked);
  };

  const handleToggleChangeTime = (isChecked: boolean) => {
    if (isChecked) {
      setShowTimeVisibility(true); // Show Time Visibility when the toggle is on
      setGlobalQuizIsTimeVisible(true);
    } else {
      setShowTimeVisibility(false);
      setGlobalQuizIsTimeVisible(false);
    }
  };

  // Validate and update the duration state
  const handleDurationChange = (e: any) => {
    const value = e.target.value;
    if (value === "" || (value >= 0 && /^\d+(\.\d{0,2})?$/.test(value))) {
      setGlobalDuration(value);
    }
  };

  const handleTwoDatePickerChange = (start: Date | null, end: Date | null) => {
    setFromDate(start);
    setGlobalQuizFromDate(start);
    setToDate(end);
    setGlobalQuizToDate(end);
  };

  // Function to handle startDate
  const handleStartDate = (startDateTime: Date | null) => {
    setStartDate(startDateTime);
    setGlobalQuizStartDate(startDateTime);
    setFromDate(startDateTime);
    setGlobalQuizFromDate(startDateTime);
  };

  // Function to handle enddate
  const handleEndDate = (endDateTime: Date | null) => {
    setEndDate(endDateTime);
    setGlobalQuizEndDate(endDateTime);
    setToDate(endDateTime);
    setGlobalQuizToDate(endDateTime);
  };

  // Function to handle input change, and validating input value.
  const handleInputChange = (
    value: string,
    setStateFunction: (value: string) => void
  ) => {
    if (isPositiveNumber(value) || value === "") {
      setStateFunction(value);
      setError(false);
    } else {
      setError(true);
    }
  };

  // Fetch quiz templates from the server and update the options for SelectInput component.
  useEffect(() => {
    const getQuizTemplate = async () => {
      try {
        const response = await axios.get(
          `https://v5kw4ve350.execute-api.eu-west-1.amazonaws.com/dev/template/class/${setClassSourceId}/${tenantName}`
        );
        const response2 = await axios.get(
          `https://v5kw4ve350.execute-api.eu-west-1.amazonaws.com/dev/template/user/${subId}/${tenantName}?provider=${provider}`
        );
        const quizDataArray1 = response.data.data || [];
        const quizDataArray2 = response2.data.data || [];

        // Transform the quiz data array into the format expected by SelectInput
        const transformedOptions: {
          title: any;
          value: string;
          submenu?: any;
        }[] = [
            {
              title: `${t("quiz.Use Existing Quiz")}`,
              value: `${t("quiz.Use Existing Quiz")}`,
              submenu: quizDataArray1.map((quiz: any) => ({
                title: quiz.title,
                id: quiz.id,
              })),
            },
            {
              title: `${t("forms.Use Templates from other Classes")}`,
              value: `${t("forms.Use Templates from other Classes")}`,
              submenu: quizDataArray2.map((course: any) => ({
                title: course.courseCode || course.courseTitle,
                id: course.courseId,
                submenu: course.template.map((quiz: any) => ({
                  title: quiz.title,
                  id: quiz.id,
                })),
              })),
            },
            {
              title: `${t("forum.create a new one")}`,
              value: "Create a new one",
            },
          ];
        setQuizOptions(transformedOptions);
      } catch (error) {
        console.error("Axios Error:", error);
      }
    };

    getQuizTemplate();
  }, [setClassSourceId, tenantName]);

  // Function to handle editing questions in a quiz.
  // Checks if a quiz is selected, if not, sets an error flag.
  // If selected, navigates to the createSurvey page with the selected source ID,
  // sets edit mode to true, and resets any quiz error flags.
  const handleEditQuestions = () => {
    if (!globalSelectedQuiz) {
      setQuizError(true);
    } else {
      navigate(`/create-quiz/${setClassSourceId}`);
      setEditQuiz(true);
      setEditQuizButton(true)
      setQuizError(false);
    }
  };

  // Function for validation and post data
  const handleContinueClick = async () => {
    if (
      globalQuizName.trim() === "" ||
      globalQuizDescription.trim() === "" ||
      !globalMaxGrade ||
      !globalAttempt ||
      !globalDuration ||
      !globalSelectedQuiz ||
      !globalQuizStartDate ||
      !globalQuizEndDate ||
      (globalQuizIsTimeVisible &&
        (!globalQuizFromDate || !globalQuizToDate) &&
        (!globalQuizStartDate || !globalQuizEndDate)) ||
      (globalQuizStartDate &&
        globalQuizEndDate &&
        globalQuizStartDate >= globalQuizEndDate)
    ) {
      setError(true);
      setGlobalQuizShowPopup(true);
    } else {
      setError(false);
      if (contentId !== undefined && newContentTitle !== undefined)
        editContent(contentId, newContentTitle);
      setShowEditContent(false);
      setGlobalShowEditContent(false);
      setIsPopUpOpen(false);
      setGlobalQuizShowPopup(false);
      if (!showEditContent || !globalShowEditContent) {
        handleSubmit();
      }

      const quizData: any = {
        _id: contentIdToEdit,
        id: globalShowEditContent ? foundRefIdState : null,
        classSourceId: setClassSourceId,
        userSourceId: subId,
        provider: provider,
        contentIndex: content_Index,
        topicRefId: topicRefId,
        type: contentType,
        title: globalQuizName,
        description: globalQuizDescription,
        startDate: globalQuizStartDate,
        endDate: globalQuizEndDate,
        selectedQuiz: globalSelectedQuiz,
        selectedQuizId: globalSelectedQuizId,
        maxGrade: globalMaxGrade,
        passingGrade: globalPassingGrade,
        numAttempts: globalAttempt,
        duration: globalDuration,
        visibleStudents: globalQuizIsVisible,
        showTimeVisibility: globalQuizIsTimeVisible,
        showGrade: globalShowGrade,
        fromDate: globalQuizFromDate,
        toDate: globalQuizToDate,
      };

      try {
        if (!globalShowEditContent) {
          const response = await axios.post(
            `${baseURL}/${path}/${tenantName}`,
            { data: quizData }
          );
          if (isReady && send) {

            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "create",
                  title: globalQuizName,
                  contentType: "Quiz",
                  refId: response.data.data,
                  refTable: "QuizActivities",
                  subId: subId,
                  provider: provider,
                  type: "content",
                  description: globalQuizEndDate
                    ? `Due Date: ${formatDate(globalQuizEndDate)}`
                    : "",
                },
              })
            );
          }
        } else if (globalShowEditContent) {
          await updateQuiz("activity/quiz/update", quizData);
          if (isReady && send) {
            send(
              JSON.stringify({
                action: "sendPublic",
                data: {
                  tenantName: tenantName,
                  classSourceId: setClassSourceId,
                  method: "update",
                  title: globalQuizName,
                  contentType: "Quiz",
                  subId: subId,
                  provider: provider,
                  refId: foundRefIdState,
                  refTable: "QuizActivities",
                  type: "content",
                  description: globalQuizEndDate
                    ? `Due Date: ${formatDate(globalQuizEndDate)}`
                    : "",
                },
              })
            );
          }
        }
        setIsPopUpOpen(false);
        setGlobalQuizShowPopup(false);
        // if (!showEditContent) {
        await activeGetModulesWithTopics();
      } catch (error) {
        console.error("Axios Error:", error);
      } finally {
        await (activeGetModulesWithTopics && activeGetModulesWithTopics());
      }
    }
  };
  const { backgroundColor, themeTextColor, inputBorderColor, iconCloseColor } =
    useRecoilValue(ColorSelector);
  return (
    <Container
      themetextcolor={themeTextColor}
      backgroundcolor={backgroundColor}
      scrollbar={inputBorderColor}
    >
      {/* Header */}
      {/* <HeadContainer>
        <FlexCenter>
          <SvgQuiz />
          <PrimaryFontBoldText text={t("quiz.Quiz")} fontSize="1.125rem" />
        </FlexCenter> */}

        {/* <SvgClose
          color={iconCloseColor}
          onClick={() => {
            setShow(false);
            setIsPopUpOpen(false);
            setShowEditContent(false);
            setGlobalShowEditContent(false);
            setGlobalQuizShowPopup(false);
          }}
          style={{ cursor: "pointer" }}
        /> */}
      {/* </HeadContainer> */}

      {/* Title */}
      {showEditContent && readyToEdit && <LoaderThreeDot />}
      <LabelDiv>
        <FlexDiv gap="0.25rem" style={{ fontWeight: "200px" }}>
          <LightTypography text={t("forms.Title")} fontSize="0.875rem" />
          <span>*</span>
        </FlexDiv>
        <StyledInput
          placeholder={t("quiz.Enter Quiz Title")}
          width="100%"
          onChange={(e: any) => {
            setQuizName(e.target.value);
            setGlobalQuizName(e.target.value);
            setContent_Title(e.target.value);
            setContentTitleInTopic(e.target.value);
          }}
          value={globalQuizName}
        />
        {globalQuizName.trim() === "" && error && (
          <ErrorDiv>{`${t("forms.Please")} ${t(
            "quiz.Enter Quiz Title"
          )}`}</ErrorDiv>
        )}
      </LabelDiv>

      {/* Text editor for the forum description */}
      <LabelDiv>
        <FlexDiv gap="0.25rem">
          <LightTypography text={t("forms.Description")} fontSize="0.875rem" />
          <span>*</span>
        </FlexDiv>
        <TinyEditor
          onChange={handleDescriptionChange}
          placeholder={t("forms.Description")}
          initialValue={`${description || globalQuizDescription}`}
          height="13.25rem"
        />
        {globalQuizDescription.trim() === "" && error && (
          <ErrorDiv>{`${t("forms.Please")} ${t(
            "forms.Enter Description"
          )}`}</ErrorDiv>
        )}
      </LabelDiv>

      {/* Select quiz */}
      <LabelDiv>
        <FlexDiv gap="0.25rem">
          <LightTypography text={t("quiz.Quiz")} fontSize="0.875rem" />
          <span>*</span>
          <SvgEyeIcon
            onClick={handleEditQuestions}
            style={{ color: "#5DD3B3", cursor: "pointer" }}
          />
        </FlexDiv>
        <NestedSelect
          onChange={handleSelectQuiz}
          onIdChange={handleSelectQuizId}
          placeholder={t("quiz.Use Quiz")}
          width="27.5rem"
          fontSize="0.875rem"
          paddingRL="1.25rem"
          options={quizOptions}
          value={globalSelectedQuiz}
          redirectUrl={`/create-quiz/${setClassSourceId}`}
        />
        {!globalSelectedQuiz && (error || quizError) && (
          <ErrorDiv>{t("course view.Please Select a Quiz")}</ErrorDiv>
        )}
      </LabelDiv>

      {/* Date and time section */}
      <DateAndTimeDiv>
        <LabelDiv>
          <LightTypography text={t("forms.Start Date")} fontSize="0.875rem" />
          <SelectDate
            width="27.5rem"
            value={globalQuizStartDate}
            onChange={handleStartDate}
          />
        </LabelDiv>
        <LabelDiv>
          <LightTypography text={t("forms.Cut-off")} fontSize="0.875rem" />
          <SelectDate
            width="27.5rem"
            value={globalQuizEndDate}
            onChange={handleEndDate}
          />
        </LabelDiv>
      </DateAndTimeDiv>
      {globalQuizStartDate &&
        globalQuizEndDate &&
        globalQuizStartDate >= globalQuizEndDate &&
        error && (
          <ErrorDiv>
            {t("course view.Cut Off Date must be greater than the start date.")}
          </ErrorDiv>
        )}
      {(!globalQuizStartDate || !globalQuizEndDate) && error && (
        <ErrorDiv>{t("course view.Dates are mandatory")}</ErrorDiv>
      )}

      {/* passing grade & maximum grade section */}
      <DateAndTimeDiv>
        <LabelDiv>
          <FlexDiv gap="0.25rem">
            <LightTypography
              text={t("assignment.Maximum grade")}
              fontSize="0.875rem"
            />
            <span>*</span>
          </FlexDiv>
          <StyledInput
            type="number"
            placeholder={t("quiz.Enter Maximum Grade")}
            width="27.5rem"
            value={globalMaxGrade}
            onChange={(e) =>
              handleInputChange(e.target.value, setGlobalMaxGrade)
            }
          />
          {!globalMaxGrade && error && (
            <ErrorDiv>{`${t("forms.Please")} ${t(
              "quiz.Enter Maximum Grade"
            )}`}</ErrorDiv>
          )}
        </LabelDiv>
        <LabelDiv>
          <FlexDiv gap="0.25rem">
            <LightTypography
              text={t("quiz.Passing Grade")}
              fontSize="0.875rem"
            />
          </FlexDiv>
          <StyledInput
            type="number"
            placeholder={t("quiz.Enter Passing Grade")}
            width="27.5rem"
            value={globalPassingGrade}
            onChange={(e) =>
              handleInputChange(e.target.value, setGlobalPassingGrade)
            }
          />
        </LabelDiv>
      </DateAndTimeDiv>

      {/* Duration & Number of Attempts section */}
      <DateAndTimeDiv>
        <LabelDiv>
          <FlexDiv gap="0.25rem">
            <LightTypography
              text={t("quiz.Duration (in minutes)")}
              fontSize="0.875rem"
            />
            <span>*</span>
          </FlexDiv>
          <StyledInput
            type="number"
            placeholder={t("quiz.Enter Duration")}
            width="27.5rem"
            onChange={handleDurationChange}
            value={globalDuration}
          />
          {!globalDuration && error && (
            <ErrorDiv>{`${t("forms.Please")} ${t(
              "quiz.Enter Duration"
            )}`}</ErrorDiv>
          )}
        </LabelDiv>
        {!globalUnlimitedAttempts && (
          <LabelDiv>
            <FlexDiv gap="0.25rem">
              <LightTypography
                text={t("quiz.Number of Attempts")}
                fontSize="0.875rem"
              />
              <span>*</span>
            </FlexDiv>
            <StyledInput
              type="number"
              placeholder={t("quiz.Enter Number of Attempts")}
              width="27.5rem"
              value={globalAttempt}
              onChange={(e) =>
                handleInputChange(e.target.value, setGlobalAttempt)
              }
            />
            {!globalAttempt && error && (
              <ErrorDiv>{`${t("forms.Please")} ${t(
                "quiz.Enter Number of Attempts"
              )}`}</ErrorDiv>
            )}
          </LabelDiv>
        )}
      </DateAndTimeDiv>

      {/* <PrimaryFontBoldText text="Grading type" fontSize="1rem" color="#5DD3B3" />
      <RadioDiv>
        <RadioDivLabel>
          <CheckBoxInput name="gradeType" value="grade" checked={selectedRadio === "grade"} onChange={() => handleToggleChangeRubric(false)} />
          <LightTypography text="Grade" fontSize="0.875rem" />
        </RadioDivLabel>
        <RadioDivLabel>
          <CheckBoxInput name="gradeType" value="rubric" checked={selectedRadio === "rubric"} onChange={() => handleToggleChangeRubric(true)} />
          <LightTypography text="Rubric" fontSize="0.875rem" />
        </RadioDivLabel>
      </RadioDiv>

      {selectedRadio === "rubric" && (
        <>
          <LightTypography text="Rubric" fontSize="0.875rem" />
          <SelectInput onChange={handleSelectChange} placeholder="Use Rubric" width="27.8125rem" optionWidth="27.8125rem" options={Options} value={selectedTitle} />
        </>
      )} */}

      {/* Buttons for toggling visibility and grading */}
      <FlexBetween>
        <BtnsDiv>
          {/* <LabelBtnsDiv>
            <Toggle onToggle={handleShuffleQuestions} id="roleToggle1" />
            <TypographyRegularDescription text="Shuffle Questions" fontSize="1rem" />
          </LabelBtnsDiv> */}
          {/* <LabelBtnsDiv>
            <Toggle
              onToggle={handleShowGrade}
              checked={globalShowGrade}
              id="globalShowGrade"
            />
            <TypographyRegularDescription
              text={t("quiz.Show Grade After Submission")}
              fontSize="1rem"
            />
          </LabelBtnsDiv> */}
          <LabelBtnsDiv>
            <Toggle
              onToggle={handleVisibleStudents}
              checked={globalQuizIsVisible}
              id="globalQuizIsVisible"
            />
            <TypographyRegularDescription
              text={t("forms.Make Visible for Students")}
              fontSize="1rem"
            />
          </LabelBtnsDiv>

          <LabelBtnsDiv>
            <Toggle
              onToggle={handleUnlimitedAttempts}
              checked={globalUnlimitedAttempts}
              id="unlimitedAttempts"
            />
            <TypographyRegularDescription
              text={t("quiz.Unlimited Attempts")}
              fontSize="1rem"
            />
          </LabelBtnsDiv>

          <LabelBtnsDiv>
            <Toggle
              onToggle={handleToggleChangeTime}
              checked={globalQuizIsTimeVisible}
              id="globalQuizIsTimeVisible"
            />
            <TypographyRegularDescription
              text={t("forms.Set Time Visibility Interval")}
              fontSize="1rem"
            />
            {globalQuizIsTimeVisible && (
              <>
                <TwoDatePicker
                  prevStartDate={globalQuizFromDate}
                  prevEndDate={globalQuizToDate}
                  onDateChange={handleTwoDatePickerChange}
                />
                {(!globalQuizFromDate || !globalQuizToDate) &&
                  !globalQuizStartDate &&
                  !globalQuizEndDate &&
                  error && (
                    <ErrorDiv>{`${t("forms.Please")} ${t(
                      "forms.Set Time Visibility Interval"
                    )}`}</ErrorDiv>
                  )}
              </>
            )}
          </LabelBtnsDiv>
        </BtnsDiv>
        {/* <BtnsDiv>
          <LabelBtnsDiv>
            <Toggle onToggle={handleEnableReview} id="roleToggle4" />
            <TypographyRegularDescription text="Enable Review Mode" fontSize="1rem" />
          </LabelBtnsDiv>
          <LabelBtnsDiv>
            <Toggle onToggle={handleSafeBrowser} id="roleToggle5" />
            <TypographyRegularDescription text="Start Exam In Safe Browser" fontSize="1rem" />
          </LabelBtnsDiv>
          <LabelBtnsDiv>
            <Toggle onToggle={handleShowGrade} id="roleToggle6" />
            <TypographyRegularDescription text="Show Grade After Submission" fontSize="1rem" />
          </LabelBtnsDiv>
        </BtnsDiv> */}
      </FlexBetween>

      <DeletePopupMainWrapper>
        <DeletePopupButtons>
          <CancelButton
            name="Cancel"
            onClick={() => {
              setIsPopUpOpen(false);
              setShowEditContent(false);
              setGlobalShowEditContent(false);
              setGlobalQuizShowPopup(false);
            }}
          >
            {t("forms.Cancel")}
          </CancelButton>
          {loading ? (
            <DeleteButton onClick={() => { }} disabled={true}>
              {" "}
              {`${t("general.loading")}...`}
            </DeleteButton>
          ) : (
            <DeleteButton
              onClick={() => {
                handleContinueClick();
                setContent_Title(globalQuizName);
              }}
            >
              {t("forms.Save")}
            </DeleteButton>
          )}
        </DeletePopupButtons>
      </DeletePopupMainWrapper>
    </Container>
  );
};

export default QuizForm;
