import React, { FC, useState, useEffect, useContext } from "react";
import GraderStatistics from "../../components/GraderStatistics/main/GraderStatistics";
import { PageHeader } from "../../components/pageHeader/PageHeader";
import GarderNavMenu from "../../components/GraderNavMenu/main/GarderNavMenu";
import PdfViewer from "../../components/GraderTextEditor/main/PdfViewer";
import { Students } from "./GraderViewInterface";
import { get } from "../../utils/AxiosRequests";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Student from "../../components/GraderStatistics/Student/Student";
import { MyContextProvider } from "../../contexts/quizGraderContext";
import { ClassContext } from "../dashboard/UnstructuredContext";
import { Typography } from "../../elements/fonts/Fonts";
import { SvgBackArrow } from "../../elements/Icons";
import { LoadingComp } from "../../components/LoadingComp/LoadingComp";
import { useTranslation } from "react-i18next";
import { FlexDiv } from "../../globalStyles";
import { ResourceFrom } from "../courseView/content/Content";
const GraderView: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const activityId = params.id;
  const classId = params.classId;

  const [resourceR, setResourceR] = useState<ResourceFrom>();

  let { state } = useLocation();
  useEffect(() => {
    if (state) {
      const selectedResource: ResourceFrom = state.activity;
      setResourceR(selectedResource);
    }
  }, [state]);
  const { userSelectedIndex, setUserSelectedIndex } = useContext(ClassContext);
  const [showQuiz, setShowQuiz] = useState<boolean>(true);
  const [showNavIcon, setShowNavIcon] = useState<boolean>(true);
  const [submission, setSubmission] = useState<any[]>([]);
  const [allAssignment, setAllAssignment] = useState<any>({});
  const [usingRubric, setUsingRubric] = useState<boolean>(false);
  const [rubricId, setRubricId] = useState<number>(0);
  const [students, setStudents] = useState<any[]>([]);
  const [enrollements, setEnrollements] = useState<number>(0);
  const [gradedCount, setGradedCount] = useState<number>(0);
  const [isAssignmentGraded, setIsAssignmentGraded] = useState<boolean>(false);
  const tenantName = localStorage.getItem("tenant");
  const [totalSubmissions, setTotalSubmissions] = useState<number>(
    submission.length
  );
  const [avarage, setAvarage] = useState<string | number>(0);
  const [studentIndex, setStudentIndex] = useState<number>(
    userSelectedIndex || 0
  );
  const [showSubmitedTime, setShowSubmitedTime] = useState<boolean>(true);
  const [showLate, setShowLate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  // Get the Number of the students enrolled in the class
  const fetchParticipantData = async () => {
    try {
      const ParticipantData = await get(
        `participant/class/${classId}/${tenantName}`
      );
      // Filter participants based on role
      if (ParticipantData.data.length > 0) {
        const students = ParticipantData.data.filter(
          (participant: any) => participant.role === "Student"
        );
        setEnrollements(students.length);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch the grader data
  const getStudentsData = async () => {
    try {
      const studentsData = await get(
        `assignment/get/submission/${tenantName}/${activityId}`
      );
      setSubmission(studentsData.data.assignmentSubmissions);
      setTotalSubmissions(studentsData.data.assignmentSubmissions.length);
      setAllAssignment(studentsData.data.assignment);
      if (
        studentsData.data.assignment.maxGrade === 0 &&
        (studentsData.data.assignment.rubricId === null ||
          studentsData.data.assignment.rubricId === 0)
      ) {
        setIsAssignmentGraded(false);
      } else {
        setIsAssignmentGraded(true);
      }
      // This condition is for rubric. if the rubric id is not emtpy or null so the assignment is using rubric.
      if (
        studentsData &&
        studentsData.data &&
        studentsData.data.assignment &&
        studentsData.data.assignment.rubricId &&
        studentsData.data.assignment.rubricId !== "" &&
        studentsData.data.assignment.rubricId !== null
      ) {
        setUsingRubric(true);
        setRubricId(studentsData.data.assignment.rubricId);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  // Call the getStudentsData and fetchParticipantData function to fetch the data
  useEffect(() => {
    getStudentsData();
    fetchParticipantData();
  }, [tenantName]); // Assuming tenantName might change and triggers fetching students

  // Use another useEffect to handle the state update for students
  useEffect(() => {
    if (submission && submission.length > 0) {
      const usersArray = submission.map((submission: any) => submission.user);
      setStudents(usersArray);
      const gradedCount = submission.filter(
        (submission) => submission.graded
      ).length;
      setGradedCount(gradedCount);
      // Calculate the average grade
      const sumOfGrades = submission.reduce((acc: number, submission: any) => {
        if (submission.graded) {
          return acc + submission.grade.grade;
        }
        return acc;
      }, 0);

      const gradedSubmissions = submission.filter((sub: any) => sub.graded);
      const avgGrade =
        gradedSubmissions.length > 0 && !usingRubric
          ? (sumOfGrades /
            (gradedSubmissions.length * allAssignment.maxGrade)) *
          100
          : gradedSubmissions.length > 0 && usingRubric
            ? (sumOfGrades / (gradedSubmissions.length * 100)) * 100
            : 0;
      const avgGradeValue = !isNaN(avgGrade) ? Math.floor(avgGrade) : 0;
      setAvarage(avgGradeValue);
    }
  }, [submission, allAssignment]);

  const handleQuizView = () => {
    setShowQuiz(!showQuiz);
    setShowLate(!showLate);
    setShowNavIcon(!showNavIcon);
  };

  const navigateBack = () => {
    navigate(-1);
  };
  const language = localStorage.getItem("language");
  return (
    <>
      {loading && <LoadingComp />}
      <MyContextProvider>
        <Link
          to={`/courseView/content/${classId}`}
        >
          <FlexDiv gap="1.25rem">
            <div
              // onClick={navigateBack}
              style={{
                marginTop: ".2rem",
                cursor: "pointer",
                transform: `${language === "ar" ? "rotate(180deg)" : ""}`,
              }}
            >
              <SvgBackArrow />
            </div>
            <Typography
              text={t("assignment.Assignment")}
              fontSize="1.75rem"
              fontWeight="400"
              lineHeight="normal"
            />
          </FlexDiv>
        </Link>
        {/* This is the above statistics component */}
        <GraderStatistics
          // setSubmitted={setSubmitted}
          // setGraded={setGraded}
          //setAvarage={setAvarage}
          totalSubmissions={totalSubmissions}
          enrollements={enrollements}
          // submitted={submitted}
          avarage={avarage}
          student={submission}
          students={students}
          studentIndex={studentIndex}
          setStudentIndex={setStudentIndex}
          graded={gradedCount}
          allAssignment={allAssignment}
        />
        {/* This is where the download all and the gradeBook excist */}
        <GarderNavMenu
          allAssignment={allAssignment}
          studentIndex={studentIndex}
          student={submission}
          handleQuizView={handleQuizView}
        />
        {/* This is the Middle Component where the pdf viewer and the sideTools excist */}
        <PdfViewer
          student={submission}
          studentIndex={studentIndex}
          showQuiz={showQuiz}
          showSubmitedTime={showSubmitedTime}
          setShowSubmitedTime={setShowSubmitedTime}
          showLate={showLate}
          showNavIcon={showNavIcon}
          allAssignment={allAssignment}
          fetchData={getStudentsData}
          usingRubric={usingRubric}
          rubricId={rubricId}
          resourceR={resourceR}
          isAssignmentGraded={isAssignmentGraded}
        // updateSubmittedByInstructor={updateSubmittedByInstructor}
        />
      </MyContextProvider>
    </>
  );
};
export default GraderView;
