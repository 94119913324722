import React, { FC, useState } from "react";
import {
  MemberBox,
  MemberProfile,
  MemberBoxWrapper,
  TypographyWrapper,
  IdCont,
  IdContHovered,
} from "./MemberInfoBox.style";
import { Typography, TypographyRegularDescription } from "../fonts/Fonts";
import {
  GroupStudent,
  GroupStudentGlobalInterface,
} from "../../components/groupCardNew/groupCardNewInterface";
import { useStateContext } from "../../contexts/ContextProvider";
import userProfile from "../../assets/userProfile.png";

import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";

const MemberInfoBox: FC<GroupStudentGlobalInterface> = ({
  profilePicture,
  username,
  index,
  studentId,
  groupId,
  boxShadow,
  outlineColor,
  id,
}) => {
  const { setStudent_ID, setStudent__ID } = useStateContext();
  const { profileCardTextColor, bg_selectColor, themeTextColor, theme } =
    useRecoilValue(ColorSelector);
  const [showHoveredUserId, setShowHoveredUserId] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyId = (Id: any) => {
    const SECREText = Id; // Combine origin and pathname

    // Use the Clipboard API to copy the URL to the clipboard
    navigator.clipboard
      .writeText(SECREText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000); // Reset copied state after 2 seconds
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  };
  return (
    <MemberBox
      onMouseDown={() => {
        setStudent_ID(id);
        setStudent__ID(studentId);
      }}
      boxshadow={boxShadow ? boxShadow : "none"}
      bordercolor={outlineColor ? outlineColor : "none"}
    >
      <MemberBoxWrapper>
        <MemberProfile src={profilePicture || userProfile} />
        <TypographyWrapper>
          <Typography
            text={`${username}`}
            fontSize="0.75rem"
            color={theme === "light" ? "#364350" : "#D9E4E8"}
            wordWrap="wrap"
          />
          <IdCont
            onMouseEnter={() => setShowHoveredUserId(true)}
            onMouseLeave={() => setShowHoveredUserId(false)}
          >
            ID: {studentId}
          </IdCont>
          <IdContHovered
            show={showHoveredUserId}
            bgColor={bg_selectColor}
            color={themeTextColor}
            theme={theme}
            onMouseEnter={() => setShowHoveredUserId(true)}
            onMouseLeave={() => setShowHoveredUserId(false)}
            onClick={() => handleCopyId(studentId)}
            className={isCopied ? "copied" : ""}
          >
            <TypographyRegularDescription
              text={`ID: ${studentId}`}
              fontSize="0.625rem"
              color={themeTextColor}
            />
          </IdContHovered>
        </TypographyWrapper>
      </MemberBoxWrapper>
    </MemberBox>
  );
};

export default MemberInfoBox;
