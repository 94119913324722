import styled from "styled-components";


export const SPContainer =styled.div`
display: flex;
flex-direction:column;
border-radius: 0.625rem;
background: var(--background-card, linear-gradient(180deg, #415160 0%, #293440 100%));
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
margin-bottom:3rem;
gap:1.25rem;
`;

export const SPContainerRow =styled.div`
display: flex;
justify-content:space-between;
align-items:center;
padding:0 1.25rem 0 1.25rem;
&:first-child{
  padding:1.25rem 1.25rem 0 1.25rem;
  /* background-color:aqua; */
}
`;

export const SPContainerProfile=styled.div`
display: flex;
position:relative;
/* background-color:red; */
`;
export const SPContainerMessagePlace=styled.div`
display: flex;
position:absolute;
gap:0.60rem;
bottom: 0;
right:0;
cursor: pointer;
`;

export const SPTable =styled.div`
display: flex;
flex-direction:column;
width: 100%;
gap:0.2rem;
`;

export const SPTableHeader =styled.div`
display: flex;
width: 100%;
gap:0.2rem;
/* background-color:yellow; */
/* opacity: 0.5; */

`;

export const SPTableHeaderCell =styled.div`
display: flex;
flex:1.1;
padding: 1.25em;
/* padding-left:3%; */
/* background-color:palegreen; */
text-align:center;
justify-content:center;
&:nth-child(1){
  max-width:12rem !important;
}
&:last-child{
  flex:0.7;
}
`;

export const SPTableBody =styled.div`
display: flex;
width: 100%;
/* gap:0.2rem; */
/* background-color:black; */
flex-direction:column;

`;

export const SPTableBigRow =styled.div`
display: flex;
width: 100%;
/* background-color:yellow; */
gap:0.2rem;
border-bottom:1px solid #576371;
&:last-child{
  border-bottom:none;
}
`;

export const SPTableCols =styled.div`
display: flex;
width: 100%;
flex:1;
flex-direction:column;
/* background-color:red; */
/* gap:0.2rem; */

&:nth-child(1){
  max-width:12rem !important;
  padding: 1.25em;
  /* padding-left:3%; */
  /* background-color:yellow !important; */
  text-align:center;
}
`;
export const SPTableRow =styled.div`
display: flex;
width: 100%;
/* gap:0.2rem; */
&:nth-child(even){
  background: rgba(255, 255, 255, 0.03);
}


`;

export const SPTableBodyCells =styled.div`
display: flex;
flex:1.08;
/* background-color:palegreen; */
padding: 1.25em;
padding-left:3.5%;
justify-content:center;
&:first-child{
  border-left:1px solid #576371 !important;
}
&:last-child{
  flex:0.7;
}
`;

