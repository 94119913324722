import React from "react";
import { FilteredButtonShapeContainer } from "./FilteredButtonShape.style";
import { LightTypography } from "../fonts/Fonts";
import { PointerContainer } from "../../components/AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableTable.style";
import { SvgRefreshIcon } from "../Icons";

interface FilteredButtonShapeProps {
  value: string;
  text: string;
  onClick?: () => void;
  setActiveValue: (value: string) => void;
  activeValue: string | null;
}

const FilteredButtonShape: React.FC<FilteredButtonShapeProps> = ({
  value,
  text,
  onClick,
  setActiveValue,
  activeValue,
}) => {
  const handleClick = () => {
    setActiveValue(value); // Set this button's value as active
    if (onClick) {
      onClick(); // Invoke passed onClick function if available
    }
  };
  const handleRefreshValue = () => {
    setActiveValue(""); // Set this button's value as active
    if (onClick) {
      onClick(); // Invoke passed onClick function if available
    }
  };
  return (
    <>
      {text !== "" ? (
        <FilteredButtonShapeContainer
          onClick={handleClick}
          active={value === activeValue} // Check if this button's value matches the activeValue
        >
          <LightTypography text={text} fontSize="1rem" />
        </FilteredButtonShapeContainer>
      ) : (
        <PointerContainer color="#5DD3B3" onClick={handleRefreshValue}>
          <SvgRefreshIcon />
        </PointerContainer>
      )}
    </>
  );
};

export default FilteredButtonShape;

//  example

// import { FilteredDataInterface } from "../../../../../interfaces/FilteredData";

// export const CompetencyFrameworksFilteredData : FilteredDataInterface[] =[

//   {
//     text:"Advanced",
//   value:"Advanced",
//   },
//   {
//     text:"Intermediate",
//   value:"Intermediate",
//   },
//   {
//     text:"Poor",
//   value:"Poor",
//   },

//   {
//     text:"",
//   value:"",
//   },
// ]
