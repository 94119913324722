import React from "react";
import { Row } from "../editPofile/editProfile.style";
import { UpdateEmailForm } from "../../../components/ChngingPrimaryEmailForm/UpadateEmailForm";
import MenuItems from "../../../components/ProfileMenueSetting/MenuItems";
import { PageHeader } from "../../../components/pageHeader/PageHeader";
import { ProfilePageHeader } from "../ProfilePageHeader/ProfilePageHeader";
import MenuItemsV2 from "../../../components/ProfileMenueSetting/MenueItemsV2";

export const ChangingPrimaryEmail = (): JSX.Element => {
  return (
    <>
      <MenuItemsV2 childContent={<UpdateEmailForm />} />
    </>
  );
};
