import React, { useEffect, useState } from "react";
import { CompetencyPopUpContainer } from "./CompetencyPopUp.style";
import { FlexAppContainer } from "../../../App.style";
import {
  LightTypography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import TextInputElement from "../../../elements/TextInput/TextInput";
import TinyEditor from "../../../elements/tinyEditor/TinyEditor";
import { LabelBtnsDiv } from "../../AssignmentForm/AssignmentForm.style";
import Toggle from "../../../elements/Toogle/toogle";
import CompetencyActivityForm from "./CompentencyActivityForm/CompentencyActivityForm";
import { PointerContainer } from "../../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableTable.style";
import { useTranslation } from "react-i18next";
interface CompetencyPopUpInterface {
  CompetencyName: string;
  setCompetencyName?: React.Dispatch<React.SetStateAction<string>>;
  ErrorCompetencyName?: string;
  setErrorCompetencyName?: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription?: React.Dispatch<React.SetStateAction<string>>;
  StudentVisibility: boolean;
  setStudentVisibility?: React.Dispatch<React.SetStateAction<boolean>>;
  activityForms: any;
  setActivityForms: React.Dispatch<React.SetStateAction<any>>;
}

const CompetencyPopUp = ({
  StudentVisibility,
  CompetencyName,
  setCompetencyName,
  ErrorCompetencyName,
  setErrorCompetencyName,
  description,
  setDescription,
  setStudentVisibility,
  activityForms,
  setActivityForms,
}: CompetencyPopUpInterface) => {
  // const [CompetencyName, setCompetencyName] = useState("");
  // const [ErrorCompetencyName, setErrorCompetencyName] = useState("");
  const handleCompetencyNameChange = (newValue: string) => {
    setCompetencyName && setCompetencyName(newValue);
    setErrorCompetencyName && setErrorCompetencyName(""); // Resetting error
  };

  const handleDescription = (newValue: string) => {
    setDescription && setDescription(newValue);
  };

  const handleToggleStudentVisibility = (isChecked: boolean) => {
    if (isChecked) {
      setStudentVisibility && setStudentVisibility(true); // Show Time Visibility when the toggle is on
    } else {
      setStudentVisibility && setStudentVisibility(false);
    }
  };
  const { t } = useTranslation();
  return (
    <CompetencyPopUpContainer>
      <FlexAppContainer column={true} gap="0.62rem">
        <LightTypography text="Competency Name" fontSize="0.875rem" />
        <TextInputElement
          borderRadius="0.625rem"
          height="2.8125rem"
          value={CompetencyName}
          onChange={handleCompetencyNameChange}
          borderColor={ErrorCompetencyName && "#D85D66"}
          placeHolder={
            ErrorCompetencyName ? ErrorCompetencyName : "Enter Competency Name"
          }
        />
      </FlexAppContainer>
      <FlexAppContainer column={true} gap="0.62rem">
        <LightTypography text={t("general.Description")} fontSize="0.875rem" />
        <TinyEditor
          placeholder={t("general.Description")}
          height="17.5625rem"
          initialValue={description}
          onChange={handleDescription}
        />
      </FlexAppContainer>
      <CompetencyActivityForm
        activityForms={activityForms}
        setActivityForms={setActivityForms}
      />
      <LabelBtnsDiv>
        <Toggle
          onToggle={handleToggleStudentVisibility}
          id="roleToggle2"
          checked={StudentVisibility}
        />
        <TypographyRegularDescription
          text="Make Visible For Students"
          fontSize="1rem"
        />
      </LabelBtnsDiv>

      {/* <FlexAppContainer justifyContent="flex-end">
        <PointerContainer onClick={handleWriteInconsole}>
          <TypographyRegularDescription text="console" />
        </PointerContainer>
      </FlexAppContainer> */}
    </CompetencyPopUpContainer>
  );
};

export default CompetencyPopUp;
