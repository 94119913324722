import { FC, useState, useEffect } from "react";
import { breakpoints, theme } from "../../theme";
import {
  CourseScheduleMainWrapper,
  TitleWithArrowWrapper,
  ArrowWrapper,
  DividLine,
  CourseScheduleWrapper,
  MainContent,
  ScheduleDayWrapper,
  ScheduleDay,
  TimeWrapper,
} from "./CourseSchedule.style";
import {
  Typography,
  MediumTypography,
  TypographyRegularDescription,
  LightTypography,
} from "../../elements/fonts/Fonts";
import { SvgArrowDownIcon } from "../../elements/Icons";
import { useRecoilValue } from "recoil";
import { courseBannerColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import {
  getFormattedTime,
  getFormattedTimeTwentyFourHour,
} from "../../utils/GetFormattedDate";

interface CourseScheduleProps {
  classData: any[];
}

const CourseSchedule: FC<CourseScheduleProps> = ({ classData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation();

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const toggleMainContent = () => {
    setIsOpen((prev) => !prev);
  };

  const getArrowTransform = () => {
    return isOpen ? "rotate(180deg)" : "rotate(0deg)";
  };

  const { courseBannerBgColor, textScheduleTimeColor, boxShadow } =
    useRecoilValue(courseBannerColorSelector);

  function renderSchedule(schedule: any) {
    return Object.keys(schedule)
      .sort((a, b) => {
        const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        return daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b);
      })
      .map((day, index) => (
        <ScheduleDayWrapper key={index}>
          <MediumTypography
            text={`${t(`calendar.${day}`)} :`}
            fontSize="0.75rem"
          />
          <ScheduleDay>
            <TimeWrapper>
              <TypographyRegularDescription
                text={`${getFormattedTimeTwentyFourHour(
                  schedule[day].startTime.toString()
                )} - `}
                fontSize="0.75rem"
                color={textScheduleTimeColor}
              />
              <TypographyRegularDescription
                text={getFormattedTimeTwentyFourHour(
                  schedule[day].endTime.toString()
                )}
                fontSize="0.75rem"
                color={textScheduleTimeColor}
              />
            </TimeWrapper>
          </ScheduleDay>
        </ScheduleDayWrapper>
      ));
  }

  return (
    <CourseScheduleMainWrapper
      bgcolor={courseBannerBgColor}
      boxshadow={boxShadow}
      isopen={isOpen.toString()}
    >
      <TitleWithArrowWrapper onClick={toggleMainContent}>
        <Typography
          text={`${t("course view.class_schedule")} :`}
          fontSize="1.25rem"
        />
        <ArrowWrapper>
          <SvgArrowDownIcon
            style={{
              transform: getArrowTransform(),
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </ArrowWrapper>
      </TitleWithArrowWrapper>
      <DividLine />
      <CourseScheduleWrapper
        style={{ gap: "1rem", display: "flex", flexDirection: "column" }}
      >
        {classData && classData.length > 0 ? (
          classData.map((course, index) => (
            <div key={index}>
              {classData.length > 1 && (
                <Typography text={course.title} fontSize="1.125rem" />
              )}
              {course.schedule && Object.keys(course.schedule).length > 0 ? (
                renderSchedule(course.schedule)
              ) : (
                <LightTypography
                  text={t("course view.No Schedule Yet!")}
                  fontSize="1rem"
                />
              )}
            </div>
          ))
        ) : (
          <LightTypography
            text={t("course view.No Classes Available!")}
            fontSize="1rem"
          />
        )}
      </CourseScheduleWrapper>
    </CourseScheduleMainWrapper>
  );
};

export default CourseSchedule;
