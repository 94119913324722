import styled from "styled-components";

interface IconHolderProps {
  margin?: string;
}

export const IconAndTextHolder = styled.div`
  display: flex;
  /* justify-content: start; */
  gap: 0.5rem;
  align-items: center;
`;
interface IconColorInterface {
  color?: string;
}
export const IconHolder = styled.div<IconColorInterface>`
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
`;
export const Text = styled.span<IconHolderProps>`
  margin: ${(props) => props.margin || "0"};
`;
