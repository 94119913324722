import styled from "styled-components";

export const ResourceHolderStyle = styled.div<{
  hovered: boolean;
  allResources: boolean;
  bgImg?: string;
  bgColor?: string;
}>`
  position: relative;

  width: ${({ hovered, allResources }) => {
    if (allResources) {
      return hovered ? "21.2rem" : "18rem";
    } else {
      return hovered ? "18rem" : "16rem";
    }
  }};
  height: ${({ hovered, allResources }) => {
    if (allResources) {
      return hovered ? "13rem" : "10rem";
    } else {
      return hovered ? "10rem" : "10rem";
    }
  }};
  height: ${({ allResources }) => {
    if (allResources) {
      return "10rem";
    } else {
      return "8rem";
    }
  }};
  /* height: ${({ hovered }) => (hovered ? "13rem" : "10rem")}; */
  /* background-image: url(${(props) => props.bgImg});
background: linear-gradient(180deg, #576371, #313c47); */
  ${({ bgImg, bgColor }) =>
    bgImg
      ? `background-image: url(${bgImg});`
      : `${bgColor} ||  linear-gradient(180deg, #576371, #313c47);`}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  flex-direction: column;
  border-left: ${({ hovered, allResources }) => {
    if (allResources) {
      return hovered ? "1.9rem solid transparent" : "0";
    } else {
      return hovered ? "0.1rem solid transparent" : "0";
    }
  }};

  margin-top: ${({ hovered, allResources }) => {
    if (allResources) {
      return hovered ? "3rem" : "0";
    } else {
      return hovered ? "0" : "0";
    }
  }};
  /* padding-bottom: 2rem !important; */
  /* transition: width 1s, height 1s, margin-left 1s, margin-top 1s;
  & > :hover {
    transition: width 1s, height 1s, margin-left 1s, margin-top 1s;
  } */
`;
export const ResourceHolderStyleL = styled.div<{
  hovered: boolean;
  allResources: boolean;
  lastIndex: boolean;
}>`
  border-right: ${(props) => (props.lastIndex ? "none" : "1px solid #576371")};
  position: relative;
  /* background: blue !important; */
  width: ${({ hovered, allResources }) => {
    if (allResources) {
      return hovered ? "21.2rem" : "18rem";
    } else {
      return hovered ? "18rem" : "16rem";
    }
  }};
  height: ${({ hovered, allResources }) => {
    if (allResources) {
      return hovered ? "12rem" : "10rem";
    } else {
      return hovered ? "10rem" : "8rem";
    }
  }};
  display: flex;
  flex-direction: column;
  gap: 0.63rem;
  margin-left: ${({ hovered, allResources }) => {
    if (allResources) {
      return hovered ? "2rem" : "0";
    } else {
      return hovered ? "0.1rem" : "0";
    }
  }};
  margin-top: ${({ hovered, allResources }) => {
    if (allResources) {
      return hovered ? "4.5rem" : "0";
    } else {
      return hovered ? "0" : "-1.5rem";
    }
  }};
`;
export const ResourceLinkTreeD = styled.div`
  display: flex;

  align-items: flex-end;

  justify-content: space-between;
  /* background: red; */
`;
export const ResourceLink = styled.div<{
  hovered: boolean;
  allResources: boolean;
}>`
  display: flex;
  height: 3rem;
  align-items: center;
  border-radius: 0.625rem;
  border: 1px solid #576371;
  padding: 1rem;
  width: ${({ hovered, allResources }) => {
    if (allResources) {
      return hovered ? "9.5rem" : " 14rem";
    } else {
      return hovered ? "7.8rem" : "14rem";
    }
  }};
  width: ${({ hovered }) => (hovered ? "9.5rem" : " 14rem")};
  flex-wrap: wrap;
  align-self: start;
  align-items: center;
  gap: 0.2rem;
`;
export const ResourceLinkTree = styled.div`
  display: flex;
  gap: 0.63rem;
  flex-wrap: wrap;
`;
export const ResourceBottom = styled.div<{ hovered: boolean }>`
  display: flex;
  margin-bottom: ${({ hovered }) => (hovered ? "0.1rem" : " 0")};
  justify-content: space-between;
  align-items: center;
  padding: 0 0.64rem 0 0.94rem;
  /* background: transparent; */
  height: 1.5rem;
`;
export const ResourceBottomHolderNoB = styled.div<{ hovered: boolean }>`
  padding: 0.72rem 0.64rem 0.72rem 0.94rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-items: center;
  margin-top: ${({ hovered }) => (hovered ? "0" : " -1.5rem")};
`;
export const ResourceBottomUp = styled.div`
  height: 7.6rem;
  width: 16.25rem;
  opacity: 1;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ResourceUpperHolderNoB = styled.div<{ bgImg?: string }>`
  height: 9.25rem;
  /* width: 16.25rem; */
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding-top: 0.87rem;
  align-self: left;
  padding-left: 0.63rem;
`;
export const DotsStyle = styled.div`
  transform: rotate(90deg);
  height: 2rem;
  cursor: pointer;
`;
export const IconsPosition = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: center;
`;
export const ResourceUpperHolder = styled.div<{
  bgImg?: string;
  theme: string;
  allResources: boolean;
  bgColor: string;
}>`
  background: ${({ bgImg, theme, allResources, bgColor }) =>
    allResources
      ? theme === "light"
        ? bgImg
          ? `url(${bgImg}), #F7F7F8`
          : "#F7F7F8"
        : bgImg
        ? `url(${bgImg}), linear-gradient(90deg, #415160 0%, #293440 107.88%)`
        : "linear-gradient(90deg, #415160 0%, #293440 107.88%)"
      : bgImg
      ? `url(${bgImg}), ${bgColor}`
      : bgColor};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 1;
`;
export const Overlay = styled.div`
  /* background: linear-gradient(180deg, #2d3741, #313c47); */
  background: transparent;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;
export const ResourceBottomHolder = styled.div<{ hovered: boolean }>`
  display: flex;
  width: 100%;
  background: linear-gradient(180deg, #576371, #313c47);
  flex-direction: column;
  position: absolute;
  justify-content: center;
  z-index: 3;
  bottom: 0;
`;
export const ResourceCenterHolder = styled.div`
  width: 4.75rem;
  height: 4.75rem;
  /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); */

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Unhovered = styled.div<{ allResources: any }>`
  margin-bottom: ${(props) => (props.allResources ? "" : "-0.25rem")};
  margin-top: ${(props) => (props.allResources ? "" : "2rem")};
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  padding: 1rem 0;
  height: fit-content;

  /* background: red; */
`;
