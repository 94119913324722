import { FC } from "react";
import {
  Container,
  MainWrapper,
  StatisticsWrapper,
  DevideLine,
} from "./CourseStatistics.style";
import { CourseStatisticsProps } from "./courseStatisticsInterface";
import { Typography } from "../../elements/fonts/Fonts";
import CourseStatisticElement from "../../elements/courseStatisticElement/CourseStatisticElement";

import { useRecoilValue } from "recoil";
import { courseCreatorColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

const CourseStatistics: FC<CourseStatisticsProps> = ({
  mainTitle,
  mainTitleSecondWord,
  quiz,
  assignment,
  onlineSession,
  survey,
  forum,
}) => {
  // Recoil state and selectors
  const { lineIconColor } = useRecoilValue(courseCreatorColorSelector);
  const { t } = useTranslation();
  const language = localStorage.getItem("language")
  return (
    <Container>
      <MainWrapper>
        <Typography text={mainTitle.toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")} fontSize="1.75rem" />
        <Typography
          text={mainTitleSecondWord.toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}
          fontSize="1.75rem"
        />
      </MainWrapper>
      <StatisticsWrapper>
        <CourseStatisticElement
          activityNumber={assignment}
          activityType={t("assignment.Assignment")}
        />
        <DevideLine lineiconcolor={lineIconColor}></DevideLine>
        <CourseStatisticElement activityNumber={quiz} activityType={t("quiz.Quiz")} />
        <DevideLine lineiconcolor={lineIconColor}></DevideLine>
        <CourseStatisticElement
          activityNumber={onlineSession}
          activityType={t("online session.Online Session")}
        />
        <DevideLine lineiconcolor={lineIconColor}></DevideLine>
        <CourseStatisticElement activityNumber={survey} activityType={t("survey.Survey")} />
        <DevideLine lineiconcolor={lineIconColor}></DevideLine>
        <CourseStatisticElement activityNumber={forum} activityType={t("forum.Forum")} />
      </StatisticsWrapper>
    </Container>
  );
};

export default CourseStatistics;
