import styled from "styled-components";
import { StylesInputWithDropdownProps } from "./SelectDropDownInterface";

export const InputContainer = styled.div`
  position: relative;
  font-family: "Primary Font-Medium";
`;

export const Input = styled.div<StylesInputWithDropdownProps>`
  padding: 0 ${(props) => (props.paddingRL ? props.paddingRL : "0.87rem")};
  font-size: ${(props) => (props.titleFontSize ? props.titleFontSize : props.fontSize)};
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "2.8125rem")};
  cursor: pointer;
  border: ${(props) => (props.border === "none" ? "none" : "1px solid")};
  border-color: ${(props) => (props.borderColor ? props.borderColor : props.inputbordercolor)};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "0.625rem")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => (props.color ? props.color : props.themetextcolor)};
  gap: ${(props) => (props.gap ? props.gap : "0rem")};
`;

export const Dropdown = styled.div<StylesInputWithDropdownProps>`
  position: absolute;
  left: 0;
  width: ${(props) => (props.gap ? "100%": props.width )};
  background-color: ${(props) => (props.bg_selectcolor)};
  border-radius: 0.313rem;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: ${props => props.theme === "light" ? "0px 4px 20px 0px rgba(192, 220, 214, 0.32)" : "none"};
  z-index: 99;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "0.75rem")};
`;

export const OptionItem = styled.div`
  padding: 0.5rem 0.87rem;
  cursor: pointer;

  &:hover {
    background-color: #5dd3b3;
    color: #fff;
  }

  &:first-child {
    border-radius: 0.313rem 0.313rem 0 0;
  }

  &:last-child {
    border-radius: 0 0 0.313rem 0.313rem;
  }
`;

export const PolygonDiv = styled.div<StylesInputWithDropdownProps>`
  transform: ${(props) => (props.isopen === "true" ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
