import React, { useEffect, useState } from "react";
import {
  LightTypography,
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
// import { NotificationDropDataV2 } from '../../notificationDrop/NotificationMenuV2/NotificationDropV2Data';
import { getIconBasedOnType } from "../../../elements/StudentLandingTable/StudentLandingTableFunction";
import { MomentInput } from "moment";
import moment from "moment";
import { SvgClock } from "../../../elements/Icons";
import {
  NormalProfileImgAPP,
  NotificationsContainer,
  NotifyChildRender,
  NotifyHolderDiv,
  NotificationsMainContainer,
  TypographyWrapper,
  SecondTypographyWrapper,
  TypographyClockWrapper,
} from "./DashboardNotifications.style";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  DashBoardColorSelector,
  TableColorSelector,
} from "../../../recoil/ThemeSelectors";
import { t } from "i18next";
import {
  translateTextDisplays,
  translateTitleOrName,
} from "../../Natifications/TranslationFunc";
import { formatDate } from "../../../utils/FormatDate";

interface DashboardNotifications {
  classNotificationsData: any[];
}

const DashboardNotifications = ({
  classNotificationsData,
}: DashboardNotifications) => {
  moment.locale("en");
  // const formatDate = (date: any) => {
  //   const now = moment();
  //   const notificationDate = moment(date);

  //   if (now.isSame(notificationDate, "day")) {
  //     return `Today ${notificationDate.format("LT")}`;
  //   } else if (now.subtract(1, "days").isSame(notificationDate, "day")) {
  //     return `Yesterday ${notificationDate.format("LT")}`;
  //   } else {
  //     return notificationDate.format("MMM D, YYYY | hh:mm A");
  //   }
  // };
  const [selectedNotification, setSelectedNotification] = useState(0);

  const handleNotificationClick = (index: any) => {
    setSelectedNotification(index);
  };
  const { bghelp, theme, SecondaryTextColor } = useRecoilValue(ColorSelector);
  const { SideBarActiveBoxBgColor } = useRecoilValue(DashBoardColorSelector);
  const { Table_nth_child } = useRecoilValue(TableColorSelector);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "12.25rem",
      }}
    >
      <NotificationsMainContainer
        bghelp={bghelp}
        style={{
          width: "37%",

          height: "100%",

          display: "flex",
          flexDirection: "column",
          rowGap: "0.62rem",
        }}
      >
        <TypographyWrapper>
          <Typography text={t("dashboard.Notifications")} fontSize="1rem" />
        </TypographyWrapper>
        <NotificationsContainer
          theme={theme}
          style={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          {classNotificationsData &&
            classNotificationsData.length > 0 &&
            classNotificationsData.map((notification: any, index: number) => (
              <NotifyHolderDiv
                key={index}
                style={{
                  // paddingTop: "1.7rem",
                  // paddingBottom: "1.7rem",

                  // height: "0.1rem",
                  // paddingLeft: "2.56rem",
                  // display: "flex",
                  // alignItems: "center",
                  // cursor: "pointer",
                  background:
                    selectedNotification === index
                      ? SideBarActiveBoxBgColor
                      : "transparent",
                }}
                onClick={() => handleNotificationClick(index)}
              >
                {/* <Typography text={notification.TitleOrName} fontSize='0.9rem' fontWeight={selectedNotification === index ? 'bold' : 'normal'} /> */}

                {notification.type === "missingAttendance" ? (
                  <Typography
                    color="red"
                    text={notification.type}
                    fontSize="0.875rem"
                  />
                ) : (
                  <TypographyRegularDescription
                    color={
                      selectedNotification === index
                        ? "#fff"
                        : SecondaryTextColor
                    }
                    text={translateTitleOrName(notification, t)}
                    fontSize="0.875rem"
                  />
                )}
              </NotifyHolderDiv>
            ))}
        </NotificationsContainer>
      </NotificationsMainContainer>
      <div style={{ width: "63%", padding: "1.88rem 0 1.99rem 0" }}>
        {classNotificationsData[selectedNotification] && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "1.25rem",
            }}
          >
            <SecondTypographyWrapper
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {classNotificationsData[selectedNotification].type ===
              "missingAttendance" ? (
                <Typography
                  text={`${classNotificationsData[selectedNotification].type}`}
                  fontSize="1rem"
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    columnGap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  {classNotificationsData[selectedNotification].ProfilePic && (
                    <NormalProfileImgAPP
                      src={
                        classNotificationsData[selectedNotification]
                          .ProfilePic ||
                        require("../../../assets/userProfile.png")
                      }
                      ImgSize="2.25rem"
                    />
                  )}{" "}
                  <Typography
                    text={`${translateTitleOrName(
                      classNotificationsData[selectedNotification],
                      t
                    )}`}
                    fontSize="1rem"
                  />
                </div>
              )}
              {/* <Typography text={NotificationDropDataV2[selectedNotification].TitleOrName} fontSize='1rem' /> */}
              <TypographyClockWrapper
                style={{
                  display: "flex",
                  columnGap: "0.35rem",
                  alignItems: "center",
                }}
              >
                <SvgClock color="#5dd3b3" />
                <LightTypography
                  text={formatDate(
                    classNotificationsData[
                      selectedNotification
                    ].ArrivalDate.toLocaleString(),
                    t
                  )}
                  fontSize="0.75rem"
                  color="#AAB1B9"
                />
              </TypographyClockWrapper>
            </SecondTypographyWrapper>
            <NotifyChildRender nth_child_bgcolor={Table_nth_child}>
              <div
                style={{
                  width: "1.5rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                {getIconBasedOnType(
                  classNotificationsData[selectedNotification].type,
                  classNotificationsData[selectedNotification].refTable
                    ? classNotificationsData[selectedNotification].refTable
                    : ""
                )}
              </div>
              {/* {NotificationDropDataV2[selectedNotification].TextDisplays && 
                            <Typography text={`${NotificationDropDataV2[selectedNotification].TextDisplays}`} fontSize='1rem' />} */}
              {classNotificationsData[selectedNotification].type ===
                "missingAttendance" ? (
                <LightTypography
                  text={`${translateTitleOrName(classNotificationsData[selectedNotification], t)}`}
                  fontSize="0.875rem"
                />
              ) : classNotificationsData[selectedNotification].TextDisplays ? (
                <LightTypography
                  text={`${translateTextDisplays(classNotificationsData[selectedNotification], t)}`}
                  fontSize="0.875rem"
                />
              ) : (
                <LightTypography
                  text={`${translateTitleOrName(classNotificationsData[selectedNotification], t)}`}
                  fontSize="0.875rem"
                />
              )}
            </NotifyChildRender>

            {/* <Typography text={` ${NotificationDropDataV2[selectedNotification].type}`} fontSize='1rem' /> */}
            {/* {getIconBasedOnType(NotificationDropDataV2[selectedNotification].type)} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardNotifications;
