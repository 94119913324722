import styled from "styled-components";
import { breakpoints } from "../../../theme";

export const EditFormMainRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.8rem;
  /* flex-wrap: wrap; */
  @media (max-width: ${breakpoints.tablets}) {
    flex-direction: column;
  }
`;

export const EditFormMainColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.12rem;
  /* background: yellow !important; */
  width: 100%;
`;

export const EditFormNormalRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.8rem;
  width: 100%;
  flex-wrap: wrap;
`;
