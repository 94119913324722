export const downloadFileFile = async (
  s3Url: any,
  file: {
    path: RequestInfo | URL;
    title: string;
  }
) => {
  if (s3Url) {
    try {
      const response = await fetch(s3Url);
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const pathSegments = file.path.toString().split("/");
      const fileName = pathSegments[pathSegments.length - 1];

      // Remove UUIDv4 from the file name
      const title = fileName.replace(
        /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}/,
        ""
      );

      link.download = title || "download";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the blob URL to free up resources
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  } else {
    console.error("Invalid download link for file:", file);
  }
};
export const handleDownloadAll = (FolderFilesData: Array<any>) => {
  FolderFilesData.forEach(
    (file: { s3Document: RequestInfo | URL; fileName: string }) => {
      downloadFile(file);
    }
  );
};
export const downloadFile = async (file: {
  s3Document: RequestInfo | URL;
  fileName: string;
}) => {
  if (file.s3Document) {
    try {
      const response = await fetch(file.s3Document);
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = file.fileName || "download";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the blob URL to free up resources
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  } else {
    console.error("Invalid download link for file:", file);
  }
};