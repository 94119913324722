import styled from "styled-components";
import { theme } from "../../theme";

export const Overlay = styled.div<{ isvisible: string }>`
  display: ${(props) => (props.isvisible ? "block" : "none")};
  opacity: ${(props) => (props.isvisible ? "1" : "0")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 300vh;
  transition: opacity 500ms;
  overflow: auto;
  z-index: 10;
`;

export const BackgroundOverlay = styled.div`
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  min-height: 100%;
`;

export const PopupContainer = styled.div`
  margin: auto auto;
  border-radius: 0.9375rem;
  width: max-content;
  color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupContentContainer = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  max-width: 35vw;
  /* Replace border left with ::before pseudo-element */
  position: relative;
  &:dir(ltr) {
    left: 0;
  }
  &:dir(ltr) {
    right: 0;
  }

  background-color: ${(props) => props.backgroundColor};
  &:dir(ltr) {
    border-radius: 0 0.9375rem 0.9375rem 0;
    padding: 1.88rem 1.37rem 1.56rem 2.81rem;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      background: #5dd3b3;
      border-radius: 1.25rem;
    }
  }
  &:dir(rtl) {
    border-radius: 0.9375rem 0 0 0.9375rem;
    padding: 1.88rem 2.81rem 1.56rem 1.37rem;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 4px;
      background: #5dd3b3;
      border-radius: 1.25rem;
    }
  }
  min-width: fit-content;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  row-gap: 1.87rem;
`;

export const DeleteButton = styled.button`
  width: 9.25rem;
  height: 3.125rem;
  border: none;
  border-radius: 6.25rem;
  background: linear-gradient(50deg, #5dd3b3 3.83%, #2f6a5a 95.8%);
  font-family: "Primary Font-Medium";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  cursor: pointer;
  color: ${theme.dark.primary_text_color};
`;

export const CancelButton = styled.button`
  width: 9.25rem;
  height: 3.125rem;
  background: transparent;
  border-radius: 6.25rem;
  cursor: pointer;
  border: 1px solid #d04b4b;
  font-family: "Primary Font-Medium";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  color: #d04b4b;
`;

export const DeletePopupButtons = styled.div`
  display: flex;
  /* &:dir(ltr) {
    flex-direction: row;
  }
  &:dir(rtl) {
    flex-direction: row-reverse;
  } */
  justify-content: center;
  align-items: center;
  gap: 0.62rem;
`;
