import React from "react";
import {
  ParticipantInstructorsCardContainer,
  ParticipantInstructorsCardTextDisplay,
} from "./ParticipantInstructorsCard.style";
import { NormalProfileImgAPP } from "../../../App.style";
import { MediumTypography, Typography } from "../../../elements/fonts/Fonts";
import { PointerContainer } from "../../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableTable.style";
import { SvgMore } from "../../../elements/Icons";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";

interface ParticipantInstructorsCardInterface {
  Picture?: any;
  Name: string;
  Email?: any;
  ShowMoreOption?: boolean;
  OnClickShowMore?: () => void;
  key?: any;
  MenuBow?: React.ReactNode; // Change the type to React.ReactNode
  ContainerRef?: any;
}

const ParticipantInstructorsCard: React.FC<
  ParticipantInstructorsCardInterface
> = ({
  Picture,
  Name,
  Email,
  ShowMoreOption = true,
  OnClickShowMore,
  key,
  MenuBow,
  ContainerRef,
}) => {
  const { backgroundColor, SecondaryTextColor, themeTextColor } =
    useRecoilValue(ColorSelector);
  return (
    <ParticipantInstructorsCardContainer key={key} bgColor={backgroundColor}>
      <NormalProfileImgAPP
        src={Picture ? Picture : require("../../../assets/userProfile.png")}
      />
      <ParticipantInstructorsCardTextDisplay>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            gap: "0.5rem",
          }}
        >
          <Typography fontSize="1rem" text={Name} />
          {ShowMoreOption && (
            <PointerContainer onClick={OnClickShowMore} color={themeTextColor}>
              <SvgMore />
            </PointerContainer>
          )}
        </div>

        <MediumTypography
          text={Email || ""}
          fontSize="0.875rem"
          color={SecondaryTextColor}
        />
      </ParticipantInstructorsCardTextDisplay>
      {/* Conditionally render MenuBow based on its existence */}
      {MenuBow && (
        <div
          style={{ position: "absolute", top: "0rem", right: "0rem" }}
          ref={ContainerRef}
        >
          {MenuBow}
        </div>
      )}
      {/* Wrap MenuBow in a div */}
    </ParticipantInstructorsCardContainer>
  );
};

export default ParticipantInstructorsCard;
