import styled from "styled-components";
import { breakpoints } from "../../../theme";

export const MainHolder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  min-height: 16.6875rem;
  height: 100%;
  padding: 2.06rem 2rem 2.31rem 2.87rem;
  @media (max-width: ${breakpoints.tablets}) {
    padding-left: 1.25rem;
  }
`;

export const LeftContentHolder = styled.div`
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  overflow: hidden;
  @media (max-width: ${breakpoints.tablets}) {
    margin-top: 3.38rem;
    height: fit-content;
    & > :first-child {
      margin-bottom: 0.87rem;
    }
    & > :last-child {
      margin-top: 1.31rem;
    }
  }
`;

export const TitleHolder = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.35rem;
  /* background-color: gray; */
  @media (max-width: ${breakpoints.tablets}) {
    flex-wrap: wrap;
  }
`;

export const CodeHolder = styled.span`
  color: #5dd3b3;
  display: inline-block; /* Display as an inline block */
  vertical-align: baseline; /* Align with the baseline of the adjacent text */
`;

export const Summary = styled.div`
  max-width: 90%;
  @media (max-width: ${breakpoints.tablets}) {
    max-width: 100%;
  }
  @media (max-width: ${breakpoints.mobile}) {
    max-width: 100%;
  }
`;

export const HeroLinks = styled.div`
  display: flex;
  /* align-items: center; */
  column-gap: 0.81rem;
  & > :is(:first-child, :last-child):hover {
    cursor: pointer;
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
  @media (max-width: ${breakpoints.tablets}) {
    flex-direction: column;
    row-gap: 1.12rem;
  }
`;

export const CreditNumberWrapper = styled.div`
  position: absolute;
  &:dir(ltr) {
    top: 2rem;
    right: 2.22rem;
  }
  &:dir(rtl) {
    top: 2rem;
    left: 2.22rem;
  }
  @media (max-width: ${breakpoints.tablets}) {
    &:dir(ltr) {
      top: 1.31rem;
      left: 1.31rem;
    }
    &:dir(rtl) {
      top: 1.31rem;
      right: 2.22rem;
    }
  }
`;

interface EditIconHolderProps {
  color: string;
  bgcolor: string;
  hovercolor: string;
  bghovercolor: string;
}

export const EditIconHolder = styled.div<EditIconHolderProps>`
  border-radius: 50%;
  padding: 0.7;
  margin-left: 3px;
  width: 24px;
  height: 24px;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.7s ease-in-out, color 0.7s ease-in-out;
  color: ${(props) => props.color};
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
  background-color: ${(props) => props.bgcolor};
  &:hover {
    background-color: ${(props) => props.bghovercolor}!important;
    color: ${(props) => props.hovercolor}!important;
  }
`;
