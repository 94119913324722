import { useContext, useEffect, useState } from "react";
import {
  CodeHolder,
  CourseCardWrapper,
  MaskGroup,
  TopHolder,
  IconHolder,
  IconsHolder,
  CourseHoverHolder,
  CourseCardDetailHolder,
  CourseCardOverlay,
  StatusDetails,
  MaskProfileDiv,
  DashBoardProfileDot,
  ProfileHolder,
} from "./CourseCard.style";
import { useStateContext } from "../../../contexts/ContextProvider";
import TextElement from "../../../elements/textElement/TextElement";
import ProgressBar from "../../../elements/progressBar/ProgressBar";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
import { Link } from "react-router-dom";
import {
  SvgAnalysisIcon,
  SvgAnnouncement,
  SvgContentIcon,
  SvgEdit,
  SvgGradesIcon,
  SvgNotificationIcon,
} from "../../../elements/Icons";
import { CourseCardHover } from "../courseCardHoverDetails/CourseCardHover";
import { UnstructuredCourseCard } from "../../UnstructureCourseCard/UnstructureCourseCard";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import { fetchData, fetchImage } from "./CourseCardDetailsFunctions";
import IconCardHolder from "./IconsCardHolder";
import { CourseCardProps } from "../CourseCardInterface";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  IconsColorSelector,
  courseBannerColorSelector,
} from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import MainOnHover from "../courseCardHoverDetails/MainOnHover";

export const CourseCard = ({
  id,
  roleList,
  isOpenContent,
  toggleCardContent,
  isOpenAnnouncement,
  toggleCardAnnouncement,
  isOpenAnalytics,
  toggleCardAnalytics,
  isOpenGrades,
  toggleCardGrades,
  isOpenNotifications,
  toggleCardNotifications,
}: CourseCardProps): JSX.Element => {
  const { t } = useTranslation();

  const { backgroundLinearColor, ProfileGradient_BgColor_Both, theme } =
    useRecoilValue(ColorSelector);
  const { backgroundProfileColor } = useRecoilValue(courseBannerColorSelector);
  const { bData } = useContext(ClassContext);
  const { setCourseName } = useStateContext();
  const language = localStorage.getItem("language");

  let course;
  course = bData.find((course: { id: any }) => course.id === id);

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [s3ImageUrl, setS3ImageUrl] = useState<string>("");
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const {
    title,
    courseCode,
    courseStatus,
    gradientColor,
    role,
    bgImage,
    teacherId,
    classTrack,
    teacherProfile,
  } = course;
  // const courseStatusString = courseStatus?.toString();
  const {
    iconcolor,
    backgroundIconColor,
    iconHoveredcolor,
    backgroundIconHoveredColor,
  } = useRecoilValue(IconsColorSelector);
  // let fetchedImage
  useEffect(() => {
    const fetchDataAndImage = async () => {
      try {
        if (role === "teacher") {
          const fetchedImage = await fetchImage(
            bgImage,
            setS3ImageUrl,
            setLoading
          );
          if (fetchedImage) {
            setS3ImageUrl(fetchedImage);
          }
        }
      } catch (error) {
        console.error("Error fetching data or image:", error);
        setError("Error fetching data. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    fetchDataAndImage();
  }, []);

  return (
    <>
      {course.structured === false ? (
        <div>
          <UnstructuredCourseCard unstId={course.id} />{" "}
        </div>
      ) : (
        <CourseCardDetailHolder rolelist={roleList} language={language}>
          {loading ? (
            <></>
          ) : (
            <CourseCardOverlay
              bgimage={s3ImageUrl || ""}
              bgcolor={backgroundLinearColor}
              role={role}
              theme={theme}
              language={language}
            ></CourseCardOverlay>
          )}
          <CourseCardWrapper role={role} language={language}>
            <TopHolder>
              <Link to={`/courseView/about/${id}`}>
                <div style={{ height: "2rem" }}>
                  <TypographyRegularDescription
                    fontSize="1.125rem"
                    text={
                      title.length > 20 ? title.substring(0, 19) + ".." : title
                    }
                  />
                </div>
              </Link>
              {(role === "student" || role === "auditor") && (
                <div style={{ height: "2rem", cursor: "pointer" }}>
                  {/* {teacherId !== undefined && teacherId !== null && teacherId !== "none" && teacherId !== "" ? */}

                  <MaskProfileDiv
                    bgcolor={ProfileGradient_BgColor_Both}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <MaskGroup
                      alt="Profile"
                      src={
                        teacherProfile !== undefined &&
                        teacherProfile !== null &&
                        teacherProfile !== ""
                          ? teacherProfile
                          : "./assets/images/userProfile.svg"
                      }
                      bordercolor={"#707070"}
                    />
                    <CourseHoverHolder
                      ishovered={isHovered.toString()}
                      language={language}
                    >
                      {/* <CourseCardHover isHovered={isHovered} teacherId={teacherId || ""} profilePicture={teacherProfile !== undefined && teacherProfile !== null && teacherProfile !== ""
                          ? teacherProfile
                          : "./assets/images/userProfile.svg"} classId={id} /> */}
                      <MainOnHover
                        teacherId={teacherId}
                        profilePicture={
                          teacherProfile !== undefined &&
                          teacherProfile !== null &&
                          teacherProfile !== ""
                            ? teacherProfile
                            : "./assets/images/userProfile.svg"
                        }
                        classId={id}
                        isHovered={false}
                      />
                    </CourseHoverHolder>
                    {/* <DashBoardProfileDot /> */}
                  </MaskProfileDiv>
                </div>
              )}
              {/* {role === "teacher" && (
                <Link to={`/courseView/about/${id}`}>
                  {" "}
                  <div style={{ height: "100%", width: "4rem" }}></div>{" "}
                </Link>
              )} */}
            </TopHolder>

            <Link to={`/courseView/about/${id}`}>
              {/* <div style={{ display: "flex", flexDirection: "column", }} > */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "0.62rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "-1.5rem",
                  }}
                >
                  <CodeHolder
                    gradientcolor={gradientColor || [""]}
                    language={language}
                  >
                    <MediumTypography
                      fontSize="0.75rem"
                      fontWeight="400"
                      text={courseCode}
                      color="#fff"
                    />
                  </CodeHolder>
                  <div style={{ height: "1rem", width: "13rem" }}></div>
                </div>
                {role === "student" &&
                  classTrack !== null &&
                  classTrack !== undefined && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "0.5rem",
                      }}
                    >
                      <StatusDetails>
                        <LightTypography
                          fontSize="0.75rem"
                          fontWeight="400"
                          text={t("dashboard.class status")}
                        />
                        <Typography
                          fontSize="0.75rem"
                          fontWeight="400"
                          text={Math.floor(classTrack) + "%"}
                        />
                      </StatusDetails>
                      <div>
                        <ProgressBar
                          color={gradientColor[0]}
                          progress={classTrack || 0}
                          Height="0.5rem"
                          maxprogress={100}
                        />
                      </div>
                    </div>
                  )}
                {role === "teacher" && (
                  <div
                    style={{
                      height: "0.2rem",
                      width: "20rem",
                      marginLeft: "-1.3rem",
                      marginBottom: "-0.25rem",
                    }}
                  ></div>
                )}
              </div>
              {/* {/* </div> */}
            </Link>

            <IconsHolder>
              {(role === "student" || role === "auditor") && (
                <IconCardHolder
                  toggleCardClick={toggleCardContent}
                  isOpen={isOpenContent}
                  SvgIcon={SvgContentIcon}
                  IconText={t("dashboard.Content")}
                />
              )}
              <IconCardHolder
                toggleCardClick={toggleCardAnalytics}
                isOpen={isOpenAnalytics}
                SvgIcon={SvgAnalysisIcon}
                IconText={t("dashboard.Analytics")}
              />
              {role === "teacher" && (
                <IconCardHolder
                  toggleCardClick={toggleCardAnnouncement}
                  isOpen={isOpenAnnouncement}
                  SvgIcon={SvgAnnouncement}
                  IconText={t("sidebar.announcements")}
                />
              )}
              {(role === "student" || role === "auditor") && (
                <IconCardHolder
                  toggleCardClick={toggleCardNotifications}
                  isOpen={isOpenNotifications}
                  SvgIcon={SvgNotificationIcon}
                  IconText={t("dashboard.Notifications")}
                />
              )}
              {(role === "student" || role === "teacher") && (
                <IconCardHolder
                  toggleCardClick={toggleCardGrades}
                  isOpen={isOpenGrades}
                  SvgIcon={SvgGradesIcon}
                  IconText={t("dashboard.Grades")}
                />
              )}
              {role === "teacher" && (
                <Link to={`/course-creator/${id}`}>
                  <IconHolder
                    iconcolor={iconcolor}
                    backgroundcolor={backgroundIconColor}
                    iconhoveredcolor={iconHoveredcolor}
                    backgroundhoveredcolor={backgroundIconHoveredColor}
                  >
                    {" "}
                    <SvgEdit
                      onClick={() => {
                        if (title) setCourseName(title);
                      }}
                    />{" "}
                  </IconHolder>{" "}
                </Link>
              )}
            </IconsHolder>
          </CourseCardWrapper>
        </CourseCardDetailHolder>
      )}
    </>
  );
};
