import styled from "styled-components";
import { breakpoints } from "../../../theme";
import { ReactNode } from "react";

interface IconHolderProps {
  icon:ReactNode 
}
export const MenuWrapper = styled.div`
  position: absolute;
  top: 1.7rem;
  right: -8rem;
  border-radius: 0.3125rem;
  background: #1f2731;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  min-height: fit-content;
  min-width: fit-content;
  width: 8.625rem;
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpoints.tablets}) {
    right: 0rem;
  }

`;

export const Frame = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  gap: 0.62rem;
  align-items: center;
  padding: 0.62rem 0 0.62rem 1.25rem;
  text-align: start;
  &:hover {
    background: ${({ theme }) => theme.dark.main_color};
    transition: all 0.2s ease-in;
  }
`;
export const IconHolder = styled.div<IconHolderProps>`
  
`;
export const Div = styled.div`
  
`;

