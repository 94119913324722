import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import {
  ContactMessagesLayout,
  NumOfMessages,
  UserMessagesContainer,
  UserMessagesParentRender,
  UserProfileContainer,
  UserProfileTextColumn,
  UserProfileTextPlace,
  UserProfileTextWidth,
  RightTriangle,
  MessagesTitleRow,
  MessagesAdd,
  MessagesSearchRow,
  MessagesSearchItem,
  ArchivePlace,
  DeleteButton,
  QuestionPart,
  OptionQuestion,
  OptionQuestionText,
  ShowFormAtrr,
  ChatEditBoxTool,
  MessagesAddType,
  MessagesAddCell,
  UserProfileTextPlaceMessage,
} from "./ContactMessages.style";
import {
  LightTypography,
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import MessamessageSenderInfoOnlyImgeSenderInfo from "../../elements/messageSenderInfo/messageSenderInfoOnlyImg";
import {
  GroupChatData,
  RealMessagesData,
  UserFriends,
  userChatData,
} from "./ContactMessagesData";
import {
  AddSqIcon,
  SvgArrowGroup,
  SvgArrowSimple,
  SvgDelete,
  SvgGroup3311,
  SvgMessageIcon,
  SvgQuestionMessage,
} from "../../elements/Icons";
import {
  ArrowStyleMessage,
  ShowForm,
} from "../../Views/messages/Messages.style";
import SearchElement from "../../elements/searchElement/SearchElement";
import PopUp from "../../elements/Popup/PopUp";
import { AddChatUserCompo } from "../AddChatUser/AddChatUserCompo";
import {
  ChatCheckboxLabel,
  ChatSeletectOptinToogle,
} from "./ContactCheckBox.style";
import PageHeaderChats from "../pageHeader/PageHeaderChats/PageHeaderChats";
import { MessageHelps } from "../MessageHelps/MessageHelps";
import { MessageRealChatInterface } from "./ContactMessagesInterface";
import useClickOutside from "../../hooks/useClickOutside";
import { SelectedFriendContext } from "../../Views/messages/MessageSearch/SelectedFriendcontext/SelectedFriendContext";
import { Messages } from "./UserMessageInterFace";
import GroupCreator from "./GroupGreator/GroupGreator";
import EditContactMessage from "./EditContactMessage";
import { FlexedDiv } from "../gradeBookTable/GradeBookTable.style";
import { PHCContainerParent } from "../pageHeader/PageHeaderChats/PageHeaderChats.style";
import { useRecoilValue } from "recoil";
import { ChatsColorSelector, ColorSelector } from "../../recoil/ThemeSelectors";

const formatDateDisplay = (date: Date | undefined): string => {
  if (!date) {
    return "";
  }

  const currentDate = new Date();
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  const optionsToday: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const optionsOther: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  if (
    date.getDate() === currentDate.getDate() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getFullYear() === currentDate.getFullYear()
  ) {
    // Display time if it's the current date
    return date.toLocaleString(undefined, optionsToday);
  } else if (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  ) {
    // Display "Yesterday" if it's the previous day
    return "Yesterday";
  } else {
    // Display full date if it's more than yesterday
    return date.toLocaleString(undefined, optionsOther);
  }
};

export const ContactMessagesPopUp = ({
  PageHeaderclosed,
}: any): JSX.Element => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [showChats, setShowChats] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>("");
  const [Services, setServices] = useState<boolean>(false);
  const [AddUser, setAddUser] = useState<boolean>(false);
  const [showUnreadUsers, setShowUnreadUsers] = useState<boolean>(false);
  const [isContactSupport, setIsContactSupport] = useState<boolean>(false);
  const [EditCheckBox, setEditCheckBox] = useState<boolean>(false);
  const [showChatType, setShowChatType] = useState<boolean>(false);
  const [ShowCreateGroup, setShowCreateGroup] = useState<boolean>(false);
  const [showOnlyUnread, setShowOnlyUnread] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [openChats, setOpenChats] = useState<string[]>([]);
  const servicesRef = useRef<HTMLDivElement | null>(null);
  const [selectedFriendId, setSelectedFriendId] = useState<string | undefined>(
    undefined
  );
  const { SelectedFriend, setSelectedFriend } = useContext(
    SelectedFriendContext
  );

  const handleShowChats = () => {
    setShowChats(!showChats);
  };

  const [onFriendSelect, setOnFriendSelect] = useState<string[]>();

  const [onGroupSelect, setOnGroupSelect] = useState<string[]>();

  const handleEditCheckBox = () => {
    setEditCheckBox(!EditCheckBox);
  };

  const handleshowChatType = () => {
    setShowChatType(!showChatType);
  };
  const containerRef = useClickOutside(showChatType, () =>
    setShowChatType(false)
  );

  const handleItemSelection = (itemId: string) => {
    if (EditCheckBox) {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.includes(itemId)
          ? prevSelectedItems.filter((id) => id !== itemId)
          : [...prevSelectedItems, itemId]
      );
    } else {
      setSelectedFriend(itemId);
    }
  };

  const handleServices = () => {
    setServices(!Services);
    setIsContactSupport(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      servicesRef.current &&
      !servicesRef.current.contains(event.target as Node)
    ) {
      setServices(false);
    }
  };

  const handleAddUSER = () => {
    setAddUser(!AddUser);
  };
  const handleChangeShowCreateGroup = () => {
    setShowCreateGroup(!ShowCreateGroup);
  };

  const handleToggleUnreadUsers = () => {
    setShowOnlyUnread(!showOnlyUnread);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getUnreadMessagesCount = (
    messages: Messages[] | undefined,
    currentUserId: string
  ) => {
    if (!messages) {
      return 0;
    }

    return messages.filter(
      (message: { senderId: any; read: any }) =>
        message.senderId !== currentUserId && !message.read
    ).length;
  };

  const getLastMessage = (messages: Messages[] | undefined): string => {
    if (!messages || messages.length === 0) {
      return "";
    }

    const sortedMessages = messages.slice().sort((a, b) => {
      const timeA = new Date(a.date).getTime();
      const timeB = new Date(b.date).getTime();
      return timeB - timeA;
    });

    // Return the content of the latest message
    return sortedMessages[0].content;
  };

  const getLastMessageDate = (messages: Messages[] | undefined): string => {
    if (!messages || messages.length === 0) {
      return "";
    }

    const sortedMessages = messages.slice().sort((a, b) => {
      const timeA = new Date(a.date).getTime();
      const timeB = new Date(b.date).getTime();
      return timeB - timeA;
    });

    // Return the formatted date of the latest message
    return formatDateDisplay(sortedMessages[0]?.date);
  };

  const filteredUserFriends = UserFriends.filter((friend, index) => {
    const friendId = friend.id;
    const friendChat = userChatData.find(
      (data) => data.FriendId === friendId
    )?.Messages;

    if (selectedFriendId && friendId === selectedFriendId) {
      return true;
    }

    if (friendChat && friendChat.length > 0) {
      const lastMessage = getLastMessage(friendChat).toLowerCase();
      return (
        friend.userName.toLowerCase().includes(searchText.toLowerCase()) ||
        lastMessage.includes(searchText.toLowerCase())
      );
    }

    // Check if it's the newly added friend ID
    if (index === UserFriends.length - 1 && friendId === selectedFriendId) {
      return true;
    }

    return false;
  });

  const filteredGroupChatData = GroupChatData.filter((group) => {
    const groupName = group.name.toLowerCase();
    const lastMessage = getLastMessage(group.ChatData).toLowerCase();
    return (
      groupName.includes(searchText.toLowerCase()) ||
      lastMessage.includes(searchText.toLowerCase())
    );
  });

  const handleDeleteSelected = () => {
  };

  const [totalChatsCount, setTotalChatsCount] = useState(0);

  const handleFriendSelect = (newFriendIds: string[]) => {
    setOnFriendSelect((prevFriendIds) => {
      const updatedFriendIds = Array.from(
        new Set([...(prevFriendIds || []), ...newFriendIds])
      );

      const newTotalChatsCount =
        updatedFriendIds.length + (onGroupSelect?.length || 0);
      if (newTotalChatsCount > 3) {
        const diff = newTotalChatsCount - 3;
        updatedFriendIds.splice(0, diff);
      }

      setOpenChats((prevOpenChats) => {
        const combinedChats = [
          ...prevOpenChats,
          ...updatedFriendIds.filter((id) => !prevOpenChats.includes(id)),
        ];
        return combinedChats.slice(0, 3);
      });

      const limitedFriendChats = updatedFriendIds.slice(-3);
      return limitedFriendChats;
    });
  };

  const handleGroupSelect = (newGroupIds: string[]) => {
    setOnGroupSelect((prevGroupIds) => {
      const updatedGroupIds = Array.from(
        new Set([...(prevGroupIds || []), ...newGroupIds])
      );

      const newTotalChatsCount =
        updatedGroupIds.length + (onFriendSelect?.length || 0);
      if (newTotalChatsCount > 3) {
        const diff = newTotalChatsCount - 3;
        updatedGroupIds.splice(0, diff);
      }

      setOpenChats((prevOpenChats) => {
        const combinedChats = [
          ...prevOpenChats,
          ...updatedGroupIds.filter((id) => !prevOpenChats.includes(id)),
        ];
        return combinedChats.slice(0, 3);
      });

      const limitedGroupChats = updatedGroupIds.slice(-3);
      return limitedGroupChats;
    });
  };

  const PageHeaderClosed = () => {
    PageHeaderclosed();
  };
  const handleScale = () => {
    PageHeaderClosed();
  };
  const handleChatClose = (chatId: string) => {
    setOpenChats((prevOpenChats) =>
      prevOpenChats.filter((id) => id !== chatId)
    );

    // Remove the closed chat from onFriendSelect if it's a friend chat
    setOnFriendSelect((prevFriendSelect) =>
      prevFriendSelect?.filter((id) => id !== chatId)
    );

    // Remove the closed chat from onGroupSelect if it's a group chat
    setOnGroupSelect((prevGroupSelect) =>
      prevGroupSelect?.filter((id) => id !== chatId)
    );
  };

  const handleAddNewChat = () => {
    if (selectedFriendId) {
      setSelectedFriend(selectedFriendId);
      setSelectedIndex(null);
      setOnFriendSelect([selectedFriendId]);
    }
    setAddUser(false); // Close the pop-up or perform other actions
  };
  const [CreatedGroup, SetCreatedGroup] = useState(false);
  const { backgroundColor } = useRecoilValue(ColorSelector);
  const { ContactCardBgColor } = useRecoilValue(ChatsColorSelector);
  return (
    <>
      {!ShowCreateGroup ? (
        <>
          <ContactMessagesLayout
            style={{
              borderRadius: "0rem",
              // marginTop: EditCheckBox ? "-2rem" : "0",
            }}
            bgcolor={backgroundColor}
          >
            {showChats && (
              <ShowForm>
                <ShowFormAtrr>
                  {!EditCheckBox && (
                    <>
                      {/* <MessagesTitleRow
                        style={{ margin: "0.1rem 0 0  0.7rem" }}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={handleEditCheckBox}
                        >
                          <Typography text="Edit" fontSize="0.875rem" />
                        </div>
                        <MessagesAdd ref={containerRef}>
                          <AddSqIcon onClick={handleshowChatType} />

                          {showChatType && (
                            <MessagesAddType>
                              <MessagesAddCell onClick={handleAddUSER}>
                                <MediumTypography
                                  text="New Chat"
                                  fontSize="0.75rem"
                                />
                              </MessagesAddCell>
                              <MessagesAddCell
                                onClick={handleChangeShowCreateGroup}
                              >
                                <MediumTypography
                                  text="New Group Chat"
                                  fontSize="0.75rem"
                                />
                              </MessagesAddCell>
                            </MessagesAddType>
                          )}
                        </MessagesAdd>
                      </MessagesTitleRow> */}

                      <MessagesSearchRow
                        style={{ margin: "-0.5rem 0 0.7rem 0.7rem" }}
                      >
                        <MessagesSearchItem>
                          <SearchElement
                            onChange={(e) => setSearchText(e.target.value)}
                            backGroundPosition="1.1rem 0.7rem"
                          />
                        </MessagesSearchItem>
                        <SvgGroup3311
                          onClick={handleToggleUnreadUsers}
                          style={{ cursor: "pointer" }}
                        />
                      </MessagesSearchRow>
                    </>
                  )}

                  {/* <ArchivePlace
                    style={{ marginTop: EditCheckBox ? "1rem" : "0" }}
                  >
                    {EditCheckBox && (
                      <DeleteButton>
                        <SvgArrowGroup onClick={handleEditCheckBox} />
                      </DeleteButton>
                    )}
                    <MediumTypography text="Archived" fontSize="0.875rem" />
                    <DeleteButton onClick={handleDeleteSelected}>
                      <SvgDelete />
                    </DeleteButton>
                  </ArchivePlace> */}
                </ShowFormAtrr>

                {!EditCheckBox ? (
                  <>
                    <UserMessagesParentRender
                      style={{ width: "100% !important", marginLeft: "0" }}
                      height="65vh"
                    >
                      {filteredUserFriends.map((friend, index) => {
                        return showOnlyUnread &&
                          getUnreadMessagesCount(
                            userChatData[index]?.Messages,
                            "1"
                          ) === 0 ? null : (
                          <UserMessagesContainer
                            bgColor={ContactCardBgColor}
                            key={friend.id}
                            onClick={() => {
                              setSelectedFriendId(friend.id);
                              handleFriendSelect([friend.id]);
                              setSelectedIndex(null);
                            }}
                          >
                            <UserProfileContainer>
                              <MessamessageSenderInfoOnlyImgeSenderInfo
                                profileImg={friend.profile}
                                online={friend.online}
                                OnlineCircleWidth="0.32625rem"
                              />
                            </UserProfileContainer>
                            <UserProfileTextColumn>
                              <UserProfileTextPlace style={{ width: "97%" }}>
                                {/* Displaying the username */}
                                <MediumTypography text={friend.userName} />
                                {/* Displaying a static date */}
                                <MediumTypography
                                  text={getLastMessageDate(
                                    userChatData[index]?.Messages
                                  )}
                                  fontSize="8px"
                                />
                              </UserProfileTextPlace>
                              <UserProfileTextPlaceMessage
                                style={{ width: "97%" }}
                              >
                                <UserProfileTextWidth>
                                  <LightTypography
                                    text={getLastMessage(
                                      userChatData[index]?.Messages
                                    )}
                                    fontSize=" 0.75rem"
                                  />
                                </UserProfileTextWidth>

                                {getUnreadMessagesCount(
                                  userChatData[index]?.Messages,
                                  "1"
                                ) > 0 && (
                                  <NumOfMessages>
                                    <MediumTypography
                                      text={getUnreadMessagesCount(
                                        userChatData[index]?.Messages,
                                        "1"
                                      ).toString()}
                                      fontSize="0.5rem"
                                    />
                                  </NumOfMessages>
                                )}
                              </UserProfileTextPlaceMessage>
                            </UserProfileTextColumn>
                          </UserMessagesContainer>
                        );
                      })}
                      {filteredGroupChatData.map((group, index) => {
                        return showOnlyUnread &&
                          getUnreadMessagesCount(
                            userChatData[index]?.Messages,
                            "1"
                          ) === 0 ? null : (
                          <UserMessagesContainer
                            bgColor={ContactCardBgColor}
                            key={group.id}
                            onClick={() => {
                              setSelectedIndex(index + UserFriends.length);
                              handleGroupSelect([group.id]);
                              setSelectedFriendId("");
                              handleFriendSelect([]);
                            }}
                            // style={{
                            //   backgroundColor:
                            //     selectedIndex === index + UserFriends.length
                            //       ? "#5DD3B3"
                            //       : "",
                            // }}
                          >
                            <UserProfileContainer>
                              <MessamessageSenderInfoOnlyImgeSenderInfo
                                profileImg={
                                  // group.UserProfile &&
                                  // group.UserProfile.trim() !== ""
                                  //   ? group.UserProfile
                                  //   : require("../../assets/GroupDefaultProfile.svg")
                                  require("../../assets/GroupDefaultProfile.png")
                                }
                                ShowOnline={false}
                                OnlineCircleWidth="0.32625rem"
                              />
                            </UserProfileContainer>
                            <UserProfileTextColumn>
                              <UserProfileTextPlace style={{ width: "97%" }}>
                                <MediumTypography text={group.name} />
                                <MediumTypography
                                  text={getLastMessageDate(group.ChatData)}
                                  fontSize="8px"
                                />
                              </UserProfileTextPlace>
                              <UserProfileTextPlaceMessage
                                style={{ width: "97%" }}
                              >
                                <UserProfileTextWidth>
                                  <LightTypography
                                    text={getLastMessage(group.ChatData)}
                                    fontSize="0.75rem"
                                  />
                                </UserProfileTextWidth>
                                {getUnreadMessagesCount(group.ChatData, "1") >
                                  0 && (
                                  <NumOfMessages>
                                    <MediumTypography
                                      text={getUnreadMessagesCount(
                                        group.ChatData,
                                        "1"
                                      ).toString()}
                                      fontSize="0.5rem"
                                    />
                                  </NumOfMessages>
                                )}
                              </UserProfileTextPlaceMessage>
                            </UserProfileTextColumn>
                          </UserMessagesContainer>
                        );
                      })}
                    </UserMessagesParentRender>
                  </>
                ) : (
                  <>
                    <UserMessagesParentRender height="50vh">
                      {GroupChatData.map((group, index) =>
                        getUnreadMessagesCount(group.ChatData, "1") ===
                        0 ? null : (
                          <UserMessagesContainer key={group.id}>
                            <ChatSeletectOptinToogle
                              type="checkbox"
                              checked={selectedItems.includes(group.id)}
                              onChange={() => handleItemSelection(group.id)}
                              id={group.id}
                              value={group.id}
                            />

                            <ChatCheckboxLabel
                              key={group.id}
                              htmlFor={group.id}
                            >
                              <UserProfileContainer
                                style={{ marginTop: "-0.7rem" }}
                              >
                                <MessamessageSenderInfoOnlyImgeSenderInfo
                                  profileImg={
                                    // group.UserProfile &&
                                    // group.UserProfile.trim() !== ""
                                    //   ? group.UserProfile
                                    //   : require("../../assets/GroupDefaultProfile.svg")
                                    require("../../assets/GroupDefaultProfile.png")
                                  }
                                  ShowOnline={false}
                                  OnlineCircleWidth="0.32625rem"
                                />
                              </UserProfileContainer>
                              <UserProfileTextColumn
                                style={{ marginTop: "-0.7rem" }}
                              >
                                <UserProfileTextPlace style={{ width: "97%" }}>
                                  <MediumTypography text={group.name} />
                                  <MediumTypography
                                    text={getLastMessageDate(group.ChatData)}
                                    fontSize="0.5rem"
                                  />
                                </UserProfileTextPlace>
                                <UserProfileTextPlaceMessage
                                  style={{ width: "97%" }}
                                >
                                  <UserProfileTextWidth>
                                    <LightTypography
                                      text={getLastMessage(group.ChatData)}
                                      fontSize="0.75rem"
                                    />
                                  </UserProfileTextWidth>
                                  {getUnreadMessagesCount(group.ChatData, "1") >
                                    0 && (
                                    <NumOfMessages
                                    // style={{ marginRight: "1rem !important" }}
                                    >
                                      <MediumTypography
                                        text={getUnreadMessagesCount(
                                          group.ChatData,
                                          "1"
                                        ).toString()}
                                        fontSize="0.5rem"
                                      />
                                    </NumOfMessages>
                                  )}
                                </UserProfileTextPlaceMessage>
                              </UserProfileTextColumn>
                            </ChatCheckboxLabel>
                          </UserMessagesContainer>
                        )
                      )}

                      {UserFriends.map((friend: any, index: number) => (
                        <UserMessagesContainer
                          key={friend.id}
                          // style={{
                          //   backgroundColor:
                          //     selectedIndex === index + UserFriends.length
                          //       ? "#5DD3B3"
                          //       : "",
                          // }}
                        >
                          <ChatSeletectOptinToogle
                            type="checkbox"
                            checked={selectedItems.includes(friend.id)}
                            onChange={() => handleItemSelection(friend.id)}
                            id={friend.id}
                            value={friend.id}
                          />
                          <ChatCheckboxLabel
                            key={friend.id}
                            htmlFor={friend.id}
                          >
                            <UserProfileContainer
                              style={{ marginTop: "-0.7rem" }}
                            >
                              <MessamessageSenderInfoOnlyImgeSenderInfo
                                profileImg={friend.profile}
                                online={friend.online}
                                OnlineCircleWidth="0.32625rem"
                              />
                            </UserProfileContainer>
                            <UserProfileTextColumn
                              style={{ marginTop: "-0.7rem" }}
                            >
                              <UserProfileTextPlace>
                                <MediumTypography text={friend.userName} />
                                <MediumTypography
                                  text={formatDateDisplay(friend.lastSeen)}
                                  fontSize="8px"
                                />
                              </UserProfileTextPlace>
                              <UserProfileTextPlaceMessage>
                                <UserProfileTextWidth>
                                  <LightTypography
                                    text={getLastMessage(
                                      userChatData[index]?.Messages
                                    )}
                                    fontSize="0.75rem"
                                  />
                                </UserProfileTextWidth>
                                <NumOfMessages>
                                  <MediumTypography
                                    text={getUnreadMessagesCount(
                                      userChatData[index]?.Messages,
                                      "1"
                                    ).toString()}
                                    fontSize="0.5rem"
                                  />
                                </NumOfMessages>
                              </UserProfileTextPlaceMessage>
                            </UserProfileTextColumn>
                          </ChatCheckboxLabel>
                        </UserMessagesContainer>
                      ))}
                    </UserMessagesParentRender>
                  </>
                )}

                {/* {EditCheckBox && (
                  <ChatEditBoxTool>
                    <DeleteButton>
                      <TypographyRegularDescription
                        text="Unarchive"
                        fontSize="0.875rem"
                      />
                    </DeleteButton>
                    <DeleteButton>
                      <TypographyRegularDescription
                        text="Read"
                        fontSize="0.875rem"
                        color="#5DD3B3"
                      />
                    </DeleteButton>
                    <DeleteButton>
                      <TypographyRegularDescription
                        text="Delete"
                        fontSize="0.875rem"
                      />
                    </DeleteButton>
                  </ChatEditBoxTool>
                )} */}
              </ShowForm>
            )}
          </ContactMessagesLayout>
          {AddUser && (
            <PopUp
              show={AddUser}
              content={
                <AddChatUserCompo
                  onUserSelect={(userId) => setSelectedFriendId(userId)}
                />
              }
              setShow={setAddUser}
              title={"Preview"}
              icon={<SvgMessageIcon />}
              showline={false}
              showButton={true}
              showButtonCancel={false}
              buttonName="Chat"
              handleSubmit={handleAddNewChat}
            />
          )}
        </>
      ) : (
        <GroupCreator
          setShowCreateGroup={setShowCreateGroup}
          SetCreatedGroup={SetCreatedGroup}
        />
      )}
      {/* <FlexedDiv> */}
      <PHCContainerParent>
        {onFriendSelect?.slice(0, 3).map((friendId, index, FriendPic) => (
          <PageHeaderChats
            key={friendId}
            friendId={{ id: friendId }}
            onClose={() => handleChatClose(friendId)}
            onScale={() => handleScale()}
          />
        ))}

        {onGroupSelect?.slice(0, 3).map((groupId, index) => (
          <PageHeaderChats
            SenderName={true}
            key={groupId}
            groupId={{ id: groupId }}
            onClose={() => handleChatClose(groupId)}
            onScale={() => handleScale()}
          />
        ))}
      </PHCContainerParent>
      {/* </FlexedDiv> */}
    </>
  );
};
