import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";

const LandingPage: React.FC = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  if (isLoading) {
    // Show a loading spinner while the authentication status is being checked
    return <Loader margin="18% 0 0 0 " />;
  }

  if (isAuthenticated) {
    // Redirect to /dashboard if the user is authenticated
    return <Navigate to="/dashboard" />;
  }

  // Call loginWithRedirect function from Auth0 if the user is not authenticated
  loginWithRedirect();

  return null;
};

export default LandingPage;
