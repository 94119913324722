import styled from "styled-components";
import { StringMappingType } from "typescript";
export const LinkWrapper = styled.div<{ issession: string,borderColor?:string }>`
  border-radius: 0.625rem;
  border: ${(props) =>
    props.issession === "false" ? ` 1px solid ${props.borderColor}` : " 1px solid #d85d66"};
  /* border: 1px solid #d85d66; */
  width: 13.375rem;
  height: 2.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
