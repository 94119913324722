import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";
export const DashboardNotificationsHolder = styled.div<StyleThemeInterface>`
  /* padding: 0.7rem 0 0 0; */
  /* max-height: 24rem !important; */

  height: 100%;
  background:${props => props.bgcolor || "linear-gradient(180deg, #415160 0%, #293440 100%)"};
  box-shadow:${props => props.boxshadow};
  border-radius: 1.56rem;
  margin-bottom: 2rem;
`;
export const NotificationTab = styled.div<{ activeTab: number }>`
  padding: 0.6rem 0.45rem;
  border-radius: 0.625rem 0.625rem 0rem 0rem;
  cursor: pointer;
  text-transform: uppercase;
`;
export const LoadingNoResources = styled.div`
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const NotificationTabCont = styled.div`
  display: flex;
  &:dir(ltr){
    padding-left: 2.37rem;
  }
  &:dir(rtl){
    padding-right: 2.37rem;
  }
`;