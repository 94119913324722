import { DropDownFilterItem } from "./dropDownInterface";
import { SvgDeleteIcon, SvgEditIcon } from "../../elements/Icons";

const DropDownData: DropDownFilterItem[] = [
  {
    title: "dashboard.Edit",
    icon: SvgEditIcon,
  },
  {
    title: "group.Delete",
    icon: SvgDeleteIcon,
  },
];

export default DropDownData;
