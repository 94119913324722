import styled from "styled-components";
import { breakpoints } from "../../../theme";

// export const AccommodationsPopUp = styled.div``;

export const AccommodationsPopUpMainContainer = styled.div`
width:29.25rem;
height:fit-content;
display: flex;
flex-direction:column;
gap:0.62rem;
/* padding:0.5rem; */
padding-left:0;
@media (max-width: ${breakpoints.tablets}) {
    width: 21rem
    /* background-size: 106%; */
  }
`;
export const AccommodationsPopUpMainCheckLine = styled.div`

display: flex;

gap:0.62rem;
`;
export const AccommodationsPopUpMainCheckText = styled.div`

display: flex;
flex-direction:column;
gap:0.62rem;
`;