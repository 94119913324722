import styled from "styled-components";
import { theme } from "../../../theme";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";
export const ChoiceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.38rem;
`;

export const CheckBox = styled.input<StyleThemeInterface>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${(props) =>
      props.bgcolor || "linear-gradient(180deg, #2D3741 0%, #576371 100%)"},
    linear-gradient(0deg, #ffffff, #ffffff);
  appearance: none;
  border: 1px solid #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  cursor: pointer;
  &:checked {
    background: linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%),
      linear-gradient(0deg, #5dd3b3, #5dd3b3);
    border: 1px solid #5dd3b3;
  }
`;

export const ChoiceMainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
`;
export const CustomCheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CustomCheckboxInput = styled.input`
  display: none;
`;

export const CustomCheckbox = styled.span<StyleThemeInterface>`
  width: 1rem;
  height: 1rem;
  border: 2px solid ${theme.dark.primary_text_color};
  background: ${(props) => props.bgcolor};
  border: 1px solid ${(props) => props.bordercolor};
  border-radius: ${(props) => props.borderradius};
`;
