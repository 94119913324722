import styled from "styled-components";

export const TagHolder = styled.button`
  border-radius: 0.3125rem;
  border: 1px solid ${({ theme }) => theme.dark.main_color};
  padding: 0.56rem 1.75rem 0.5rem 1.81rem;
  background-color: transparent;
  color: ${({ theme }) => theme.dark.main_color};
  display: flex;
  text-align: center;
  white-space: nowrap;
  max-height: min-content;
  cursor: pointer;
  overflow: hidden;
  &:hover {
    color: ${({ theme }) => theme.dark.primary_text_color};
    background-color: ${({ theme }) => theme.dark.main_color};
    transition: all 0.2s ease-in;
  }
`;
