import styled from "styled-components";
import { breakpoints } from "../../theme";

interface RectangleHolderProps {
  rectangleColor?: string;
  rectangleBorder?: string;
}

interface BigBorderProps {
  bigLineColor?: string;
  bigLineBorder: string;
}

interface SmallBorderProps {
  smallLineColor?: string;
  smallLineBorder: string;
}

export const CertificateMainHolder = styled.div`
  width: 100%;
  max-width: 47.25rem;
  margin: 0 auto;
  height: 100%;
  border-radius: 0.625rem;
  background: #ffffff;
  padding: 1.88rem 2.06rem;
  margin-top: 1.25rem;
`;

export const RectangleHolder = styled.div<RectangleHolderProps>`
  width: 100%;
  height: 100%;
  border-color: ${(props) => props.rectangleColor};
  border-width: ${(props) => props.rectangleBorder}px;
  border-style: solid;
  border-radius: 0.625rem;
  position: relative;
  padding: 3.25rem 2.063rem 3.75rem 2.063rem;
`;

export const BigBorderFirstPieceDivTop = styled.div<BigBorderProps>`
  width: 34.9%;
  position: absolute;
  right: -${(props) => parseInt(props.bigLineBorder) * 0.4}px;
  top: -${(props) => parseInt(props.bigLineBorder) * 0.4}px;
  height: ${(props) => props.bigLineBorder}px;
  background-color: ${(props) => props.bigLineColor};
  border-top-right-radius: ${(props) => parseInt(props.bigLineBorder) * 0.8}px;
`;

export const BigBorderSecondPieceDivTop = styled.div<BigBorderProps>`
  height: 50.32%;
  position: absolute;
  right: -${(props) => parseInt(props.bigLineBorder) * 0.4}px;
  top: ${(props) => parseInt(props.bigLineBorder) * 0.4}px;
  width: ${(props) => props.bigLineBorder}px;
  background-color: ${(props) => props.bigLineColor};
`;

export const BigBorderFirstPieceDivBottom = styled.div<BigBorderProps>`
  width: 34.9%;
  position: absolute;
  left: -${(props) => parseInt(props.bigLineBorder) * 0.4}px;
  bottom: -${(props) => parseInt(props.bigLineBorder) * 0.4}px;
  height: ${(props) => props.bigLineBorder}px;
  background-color: ${(props) => props.bigLineColor};
  border-bottom-left-radius: ${(props) =>
    parseInt(props.bigLineBorder) * 0.8}px;
`;

export const BigBorderSecondPieceDivBottom = styled.div<BigBorderProps>`
  height: 50.32%;
  position: absolute;
  left: -${(props) => parseInt(props.bigLineBorder) * 0.4}px;
  bottom: ${(props) => parseInt(props.bigLineBorder) * 0.4}px;
  width: ${(props) => props.bigLineBorder}px;
  background-color: ${(props) => props.bigLineColor};
`;

/**********************************************/

export const SmallBorderFirstPieceDivTop = styled.div<SmallBorderProps>`
  width: 21.84%;
  position: absolute;
  right: ${(props) => parseInt(props.smallLineBorder) * 2.092}px;
  top: ${(props) => parseInt(props.smallLineBorder) * 2.108}px;
  height: ${(props) => props.smallLineBorder}px;
  background-color: ${(props) => props.smallLineColor};
  border-top-right-radius: ${(props) =>
    parseInt(props.smallLineBorder) * 0.8}px;
`;

export const SmallBorderSecondPieceDivTop = styled.div<SmallBorderProps>`
  height: 31.92%;
  position: absolute;
  right: ${(props) => parseInt(props.smallLineBorder) * 2.092}px;
  top: ${(props) => parseInt(props.smallLineBorder) * 2.908}px;
  width: ${(props) => props.smallLineBorder}px;
  background-color: ${(props) => props.smallLineColor};
`;

export const SmallBorderFirstPieceDivBottom = styled.div<SmallBorderProps>`
  width: 21.84%;
  position: absolute;
  left: ${(props) => parseInt(props.smallLineBorder) * 2.092}px;
  bottom: ${(props) => parseInt(props.smallLineBorder) * 2.108}px;
  height: ${(props) => props.smallLineBorder}px;
  background-color: ${(props) => props.smallLineColor};
  border-bottom-left-radius: ${(props) =>
    parseInt(props.smallLineBorder) * 0.8}px;
`;

export const SmallBorderSecondPieceDivBottom = styled.div<SmallBorderProps>`
  height: 31.92%;
  position: absolute;
  left: ${(props) => parseInt(props.smallLineBorder) * 2.092}px;
  bottom: ${(props) => parseInt(props.smallLineBorder) * 2.908}px;
  width: ${(props) => props.smallLineBorder}px;
  background-color: ${(props) => props.smallLineColor};
`;

export const QR_Wrapper = styled.img`
  position: absolute;
  top: 2.19rem;
  left: 2.19rem;
  width: 3.813rem;
  height: 3.813rem;
  @media (max-width: 1190px) {
    left: 50% !important;
    transform: translateX(-50%);
  }
`;

export const CertificateInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  justify-content: center;
  text-align: center;
  align-items: center;
  @media (max-width: 1190px) {
    margin-top: 3.25rem;
  }
`;

export const FooterHolder = styled.div`
  width: 85%;
  display: flex;
  gap: 0.62rem;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  @media (max-width: ${breakpoints.tablets}) {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const SignatureNamePositionHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 0.19rem;
  & > :first-child {
    margin-bottom: 0.12rem;
  }
`;

export const ImageWrapper = styled.img``;
export const UniLogoWrapper = styled.img`
  width: 6.438rem;
  height: 4.25rem;
`;
export const StampWrapper = styled.img`
  width: 6.438rem;
  height: 4.375rem;
`;
