import React from "react";
import { FlexAppContainer } from "../../../App.style";
import LandingPagePaymentTitle from "../../../components/LandingPageHeader/LandingPagePaymentTitle/LandingPagePayementTitle";
import PaymentsFinallyRender from "../../../components/PaymentsFinallyRender/PaymentsFinallyRender";

const FinallyGroupDataPage = () => {
  return (
    <FlexAppContainer column={true} gap="0.80rem">
      <LandingPagePaymentTitle
        title="Finally"
        text=" Since you answered the advanced questions, unlock your favorite features for free from the below"
      />
      <PaymentsFinallyRender />
    </FlexAppContainer>
  );
};

export default FinallyGroupDataPage;
