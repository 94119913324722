import React, { useEffect, useState } from "react";
import {
  AttendanceContainer,
  AttendanceHeaderCell,
  AttendanceHeaderRow,
  AttendanceProfileRow,
} from "./AttendancePopup.style";
import ProfileImgHolder from "../../elements/ProfileImgHolder/ProfileImgHolder";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AttendanceItem, Session } from "./interfaces";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { TableColorSelector } from "../../recoil/ThemeSelectors";
import {
  LightTypography,
  MediumTypography,
  Typography,
} from "../../elements/fonts/Fonts";
import {
  countColor,
  downloadExcel,
  formatDateTime,
  getFormattedDateTime,
  handleSelectChangeEnrollment,
  scrollLeft,
  scrollRight,
} from "./AttendanceAllsessionsFunctions";
import ZSpinner from "../../elements/Spinner/ZSpinner/ZSpinner";

interface AddAttendancePopUp {
  onClose: any;
  selectedStudentId?: any;
  setAttendanceLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const AttendancePopupNew = ({
  onClose,
  selectedStudentId,
  setAttendanceLoading,
}: AddAttendancePopUp) => {
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const id = useParams();
  const navigate = useNavigate();

  const handleClickOnSpecificSession = (sessionId: number) => {
    navigate(
      `/courseview/GradeBook/${id.classId}/generateAttendance/${sessionId}`
    );
  };

  const [sessionsData, setSessionsData] = useState<Session[]>([]);
  const [participantData, setParticipantData] = useState<any[]>([]);
  const [attendanceData, setAttendanceData] = useState<AttendanceItem[]>([]);
  const tenantName = localStorage.getItem("tenant"); // Replace 'yourTenantName' with the actual tenant name
  const classSourceId = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchSessionsData = async () => {
      try {
        const response = await axios.get(`
          https://k58wrky089.execute-api.eu-west-1.amazonaws.com/dev/sessions/${tenantName}/${classSourceId.classId}`);
        const sortedSessions = response.data.data.sessions.sort(
          (a: any, b: any) => {
            // Extract numbers from session titles
            const regex = /(\d+)/;
            const aNumber = parseInt(a.title.match(regex)[0]);
            const bNumber = parseInt(b.title.match(regex)[0]);

            return aNumber - bNumber;
          }
        );
        setSessionsData(sortedSessions);
        setAttendanceData(response.data.data.attendance);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching sessions data:", error);
      }
    };

    fetchSessionsData();
  }, [tenantName, classSourceId]);

  useEffect(() => {
    const fetchParticipantData = async () => {
      try {
        const response = await axios.get(`
          https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/participant/class/${id.classId}/${tenantName}`);
        // Filter the participant data to get only the data for the specified student ID
        const studentData = response.data.data.filter(
          (student: { sourceId: string }) =>
            student.sourceId === selectedStudentId
        );
        setParticipantData(studentData);
      } catch (error) {
        console.error("Error fetching participant data:", error);
      }
    };

    fetchParticipantData();
  }, [id, selectedStudentId]);

  const { Table_Head_TR, theme, Table_nth_child } =
    useRecoilValue(TableColorSelector);

  return (
    <>
      {loading ? (
        <ZSpinner containerHeight="7rem" />
      ) : (
        <AttendanceContainer style={{ gap: "0" }}>
          {participantData.map(
            (student: any) =>
              student.role === "Student" &&
              sessionsData.map((session: Session, index: number) => {
                // Find attendance data for the current session and student
                const attendance = attendanceData.find(
                  (a: AttendanceItem) =>
                    a.userSourceId === student.sourceId &&
                    a.sessionId === session.id
                );
                // Check if attendance data exists
                if (attendance) {
                  // Display the attendance status for each session
                  return (
                    <AttendanceHeaderRow
                      nth_child_bgcolor={Table_nth_child}
                      key={session.id}
                    >
                      {/* Display the session and attendance details */}

                      <AttendanceHeaderCell>
                        <MediumTypography
                          text={`${t("gradebook.Session")} ${index + 1}`}
                          fontSize="0.875rem"
                        />
                      </AttendanceHeaderCell>
                      <AttendanceHeaderCell>
                        <LightTypography
                          text={getFormattedDateTime(
                            session.startDateTime,
                            session.endDateTime
                          )}
                          fontSize="0.75rem"
                          lineHeight="1.75rem"
                          whiteSpace="nowrap"
                        />
                        <LightTypography
                          text={session.time || ""}
                          fontSize="0.75rem"
                          lineHeight="1.75rem"
                          whiteSpace="nowrap"
                        />
                      </AttendanceHeaderCell>

                      <AttendanceHeaderCell>
                        <LightTypography
                          text={session.time ?? ""}
                          fontSize="0.75rem"
                          lineHeight="1.75rem"
                          whiteSpace="nowrap"
                        />
                        <MediumTypography
                          text={t(`gradebook.${attendance.status}`)}
                          fontSize="0.875rem"
                          color={countColor(
                            attendance.statusId.toString(),
                            theme
                          )}
                        />
                      </AttendanceHeaderCell>
                    </AttendanceHeaderRow>
                  );
                } else {
                  // Handle case where attendance data is not found
                  return (
                    <AttendanceHeaderRow
                      nth_child_bgcolor={Table_nth_child}
                      key={session.id}
                    >
                      <AttendanceHeaderCell>
                        <MediumTypography
                          text={`${t("gradebook.Session")} ${index + 1}`}
                          fontSize="0.875rem"
                        />
                      </AttendanceHeaderCell>
                      <AttendanceHeaderCell>
                        <LightTypography
                          text={getFormattedDateTime(
                            session.startDateTime,
                            session.endDateTime
                          )}
                          fontSize="0.75rem"
                          lineHeight="1.75rem"
                          whiteSpace="nowrap"
                        />
                      </AttendanceHeaderCell>
                      <AttendanceHeaderCell>
                        <LightTypography text={"-"} fontSize="0.75rem" />
                      </AttendanceHeaderCell>
                    </AttendanceHeaderRow>
                  );
                }
              })
          )}
        </AttendanceContainer>
      )}
    </>
  );
};

export default AttendancePopupNew;
