import React, { useContext, useEffect, useState } from 'react';
import { Module, Topic, ContentR, CourseTimelineProps } from '../utils/CourseResourcesInterface';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ColorSelector } from '../../../recoil/ThemeSelectors';
import ModuleItem from '../moduleItem/ModuleItem';
import { TimeLineModules, TimelineTop, TimelineWrapper, SvgEditDiv, NoTopicAvailable } from './CourseTimelineV2.style';
import { Link, useParams } from 'react-router-dom';
import { Typography } from '../../../elements/fonts/Fonts';
import { SvgArrowUpIcon, SvgEdit, SvgNoResult } from '../../../elements/Icons';
import { adminState, resource, selectedContent, visibilityChange } from '../../../recoil/RecoilStates';
import ProgressBar from '../courseTimelineComponents/ProgressBar';
import { ClassContext } from '../../../Views/dashboard/UnstructuredContext';
import axios from 'axios';
import { auth0SubIdSplitter, auth0ProviderSplitter } from '../../../utils/StringManipulation';
import { useAuth0 } from '@auth0/auth0-react';
// import { NoTopicAvailable } from '../topicItem/TopicItem.style';
import { useTranslation } from 'react-i18next';

const CourseTimelineV2 = ({ role, data, classTitle, onSelectResource, closeTimeline }: CourseTimelineProps): JSX.Element => {
    const { t } = useTranslation()
    const { user } = useAuth0();
    const { classId } = useParams();
    const { backgroundColor, bghelp, themeTextColor, boxShadow } = useRecoilValue(ColorSelector);
    const [isAdmin] = useRecoilState(adminState);
    const { progressChanged, setRole } = useContext(ClassContext)
    const [timeLineData, setTimeLineData] = useState<Module[]>([]);
    const [overallTrackingData, setOverallTrackingData] = useState<any>();
    const [selectedResourceID, setSelectedResourceId] = useRecoilState(selectedContent(classId))
    const [trackLoad, setTrackLoad] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [contentVisibility] = useRecoilState(visibilityChange)

    useEffect(() => {
        setRole(role)
        const savedContent = JSON.parse(localStorage.getItem(`selectedResource-${classId}`) || `{}`)
        if (JSON.stringify(savedContent) === JSON.stringify(selectedResourceID)) {
            // console.log("savedContent", savedContent)
            // console.log("selectedResourceID", selectedResourceID)
        }
        else {
            // console.log("no")
            setSelectedResourceId({ contentId: savedContent.contentId, moduleIndex: savedContent.moduleIndex, topicIndex: savedContent.topicIndex, isVisible: savedContent.isVisible })
        }
    }, [classId])

    useEffect(() => {
        const fetchData = async () => {
            console.log("loading", loading)
            // console.log(contentVisibility)
            try {
                setTrackLoad(true);
                const tenantName = localStorage.getItem("tenant");
                const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
                const provider = auth0ProviderSplitter(user?.sub || "");
                if (isAdmin === false && role === "student") {
                    const overallTrack = await axios.get(
                        `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/completionTracking/class/track/${subIdSplitted}/${tenantName}?provider=${provider}`
                    );
                    // console.log("overall tracking data ", overallTrack)
                    const filteredData = overallTrack.data.data.filter(
                        (item: { classSourceId: string | undefined }) =>
                            item.classSourceId === classId
                    );
                    if (filteredData && filteredData[0] && filteredData[0].classTrack !== null) {
                        setOverallTrackingData(filteredData[0].classTrack);
                    }
                }
                else { setOverallTrackingData(0); }
                const response = await axios.get(
                    `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/completionTracking/contentTrack/${classId}/${subIdSplitted}/${tenantName}?provider=${provider}`
                );
                if (response.data.data !== undefined && response.data.data !== null) {
                    const trackingData = response.data.data;
                    const sortedData = data.sort((a, b) => a.moduleIndex - b.moduleIndex);
                    // Update TimelineData with TrackingData
                    const updatedTimelineData = sortedData.map((module) => {
                        const updatedTopicsArray = module.topicsArray.map((topic) => {
                            const updatedContents = topic.contents?.map((content) => {
                                const trackingItem = trackingData.find(
                                    (trackingItem: {
                                        moduleIndex: number;
                                        refId: string;
                                        contentIndex: number;
                                        topicId: any;
                                    }) =>
                                        trackingItem.moduleIndex === module.moduleIndex &&
                                        trackingItem.refId === content.refId &&
                                        trackingItem.contentIndex === content.contentIndex &&
                                        trackingItem.topicId === topic.topicId
                                );
                                if (trackingItem) {
                                    if (role === "student")
                                        return { ...content, percentage: trackingItem.percentage };
                                    else
                                        return { ...content, visibility: trackingItem.isVisible };
                                } else {
                                    return content;
                                }
                            });
                            return { ...topic, contents: updatedContents };
                        });
                        return { ...module, topicsArray: updatedTopicsArray };
                    });

                    setTimeLineData(updatedTimelineData);
                    setLoading(false)
                } else {
                    setTimeLineData(data);
                    setLoading(false)
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setTrackLoad(false);
                setLoading(false)
            }
        };
        fetchData();
    }, [data, classId, progressChanged, contentVisibility])
    return (
        <TimelineWrapper bgColor={"backgroundColor"} style={{ boxShadow: boxShadow }} >
            <TimelineTop bgColor={backgroundColor} style={{ boxShadow: boxShadow }} >
                <Typography text={loading ? `${t("course view.Loading Class Content")}...` : `${classTitle}`} fontSize="1rem" />

                <div style={{ display: "flex", alignItems: "center", columnGap: "1.25rem", marginTop: "0.25rem", cursor: "pointer" }} >
                    {role === "teacher" && (
                        <Link to={`/course-creator/${classId}`}>
                            <SvgEditDiv> <SvgEdit /> </SvgEditDiv>
                        </Link>
                    )}
                    {isAdmin === false && role === "student" && (trackLoad ? <></> : <ProgressBar progress={overallTrackingData} />)}
                    <SvgArrowUpIcon onClick={closeTimeline} />
                </div>
            </TimelineTop>
            {loading ?
                <NoTopicAvailable bgColor={backgroundColor} borderColor={bghelp}>
                    <SvgNoResult width={"10rem"} />
                    <Typography
                        text={`${t("general.loading")}...`}
                        fontSize="1rem"
                    />

                </NoTopicAvailable>
                : <TimeLineModules bgColor={bghelp}>

                    {timeLineData.length > 0
                        ?
                        (timeLineData.sort((a, b) => a.moduleIndex - b.moduleIndex)
                            .map((module, moduleIndex) => (
                                <ModuleItem key={module.moduleIndex} module={module} onSelectResource={onSelectResource} />
                            ))) :
                        <NoTopicAvailable bgColor={backgroundColor} borderColor={bghelp}>
                            <SvgNoResult width={"10rem"} />
                            <Typography
                                text={`${t("course view.No modules")}/`}
                                fontSize="0.85rem"
                            />
                            <Typography
                                text={t("course view.topics available")}
                                fontSize="0.85rem"
                            />
                        </NoTopicAvailable>
                    }
                </TimeLineModules>}
        </TimelineWrapper>
    );
    // }
};

export default CourseTimelineV2;