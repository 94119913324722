import React, { useEffect, useState } from "react";
import { FlexAppContainer } from "../../App.style";
import {
  CheckboxLabel,
  MainCols,
  SeletectOptinToogle,
} from "../ProfileCheckBoxOptions/NotificationSetting.style";
import { MediumTypography, Typography } from "../../elements/fonts/Fonts";
import {
  InstructorCheckBoxColumn,
  InstructorCheckBoxRow,
  InstructorTimeColumn,
  MainInstructorInformationRow,
  MainInstructorInformationSelectedRow,
} from "./InstructorInformationComp.style";
import {
  ButtonAdd,
  ButtonCancel,
  CheckBoxColumn,
  CheckBoxRow,
  TimeColumn,
  TimePickerHolder,
  TimePickerHolderContent,
  TimePickerHolderContentDelete,
} from "../addAttendancePopUp/AddAttendancePopUp.style";
import TimePickerComponent from "../../elements/TimePicker/TimePicker";
import {
  handleAddTimePicker,
  handleCheckboxChange,
  handleDeleteTimePicker,
  handleUpdateTime,
} from "../addAttendancePopUp/AddAttendanceFunctions";
import { AddSqIcon, SvgDelete } from "../../elements/Icons";
import { PointerContainer } from "../AnalyticComplexSelectedTables/SurveyComplexResponsesTable/SurveyReusableTable/SurveyReusableTable.style";
import { toast } from "react-toastify";
import { handleGenerate } from "./InstructorcompuFunctions";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
} from "../../utils/StringManipulation";
import axios from "axios";
import PopupDelete from "../../elements/PopupDelete/PopupDelete";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { LoadingComp } from "../LoadingComp/LoadingComp";

interface TimeSlot {
  start: string;
  end: string;
}

interface OfficeHour {
  day: string;
  timeSlots: TimeSlot[];
}

const InstructorInformationComp = () => {
  const { t } = useTranslation();
  const tenantName = localStorage.getItem("tenant");
  const [startTime, setStartTime] = useState<string[][]>(Array(7).fill([]));
  const [endTime, setEndTime] = useState<string[][]>(Array(7).fill([]));
  const [ErrorStartTime, setErrorStartTime] = useState<string[][]>(
    Array(7).fill([])
  );
  const [ErrorEndTime, setErrorEndTime] = useState<string[][]>(
    Array(7).fill([])
  );
  const { checkboxBgColor, checkboxBorderColor, checkboxBorderRadius } =
    useRecoilValue(ColorSelector);
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [isCheckedArray, setIsCheckedArray] = useState(
    new Array(daysOfWeek.length).fill(false)
  );
  const [isTimePickerVisible, setIsTimePickerVisible] = useState(
    Array(7).fill(false)
  );
  const [timeColumnCounts, setTimeColumnCounts] = useState<number[]>(
    Array(7).fill(1)
  );
  const { user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const [loadingState, setLoadingState] = useState(false);
  const [officeHours, setOfficeHours] = useState<OfficeHour[]>([]);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [PageLoading, setPageLoading] = useState(false);

  // Function to clear all arrays and reset all checkboxes to unchecked
  const handleClearData = async () => {
    try {
      const deleteResponse = await axios.patch(
        `https://9ws5f31duk.execute-api.eu-west-1.amazonaws.com/dev/teacher/academic/clearData/${tenantName}`,
        {
          data: {
            subId: subId,
            provider: provider,
          },
        }
      );
      getOfficeHours();
      setStartTime(Array(7).fill([]));
      setEndTime(Array(7).fill([]));
      setIsCheckedArray(new Array(daysOfWeek.length).fill(false));
      setIsTimePickerVisible(Array(7).fill(false));
      setTimeColumnCounts(Array(7).fill(1));
    } catch (error) {
      console.log("handleClearData error is", error);
    }
  };

  const convertToComparableTime = (time: {
    split: (arg0: RegExp) => [any, any];
    slice: (arg0: number) => any;
  }) => {
    const [hours, minutes] = time.split(/[:\s]/);
    const period = time.slice(-2);
    let hour = parseInt(hours, 10);
    const minute = parseInt(minutes, 10);
    if (period === "PM" && hour !== 12) {
      hour += 12;
    } else if (period === "AM" && hour === 12) {
      hour = 0;
    }
    return hour * 60 + minute;
  };

  const sortTimeSlots = (timeSlots: any[]) => {
    return timeSlots.sort((a, b) => {
      const startA = convertToComparableTime(a.start);
      const startB = convertToComparableTime(b.start);
      return startA - startB;
    });
  };

  const getOfficeHours = async () => {
    setPageLoading(true);
    try {
      const response = await axios.get(
        `https://9ws5f31duk.execute-api.eu-west-1.amazonaws.com/dev/teacher/officeHours/${tenantName}/${subId}?provider=${provider}`
      );
      const officeHours = response.data.data.officeHours.map(
        (day: { timeSlots: any[] }) => ({
          ...day,
          timeSlots: sortTimeSlots(day.timeSlots),
        })
      );
      setOfficeHours(officeHours);
      setPageLoading(false);
    } catch (error) {
      console.log("Error fetching office hours:", error);
    }
  };

  // Effect to fetch office hours on component mount or when dependencies change
  useEffect(() => {
    getOfficeHours();
  }, [tenantName, subId]);

  useEffect(() => {
    getOfficeHours();
  }, [tenantName, subId]);

  // Effect to update start and end times when office hours change
  useEffect(() => {
    if (officeHours.length > 0) {
      const newStartTime = Array(7)
        .fill([])
        .map(() => [] as string[]);
      const newEndTime = Array(7)
        .fill([])
        .map(() => [] as string[]);
      const newIsCheckedArray = new Array(daysOfWeek.length).fill(false);
      const newIsTimePickerVisible = Array(7).fill(false);
      const newTimeColumnCounts = Array(7).fill(1);

      officeHours.forEach((officeHour) => {
        const dayIndex = daysOfWeek.indexOf(officeHour.day);
        if (dayIndex > -1) {
          newIsCheckedArray[dayIndex] = true;
          newIsTimePickerVisible[dayIndex] = true;
          newStartTime[dayIndex] = officeHour.timeSlots.map(
            (slot) => slot.start
          );
          newEndTime[dayIndex] = officeHour.timeSlots.map((slot) => slot.end);
          newTimeColumnCounts[dayIndex] = officeHour.timeSlots.length;
        }
      });

      setStartTime(newStartTime);
      setEndTime(newEndTime);
      setIsCheckedArray(newIsCheckedArray);
      setIsTimePickerVisible(newIsTimePickerVisible);
      setTimeColumnCounts(newTimeColumnCounts);
    }
  }, [officeHours]);

  if (PageLoading) {
    // console.log("loadingState is :", loadingState);

    return <LoadingComp />;
  }
  return (
    <MainCols style={{ width: "100%" }}>
      <Typography text={t("User Profile.Office Hours")} fontSize="1.75rem" />
      <FlexAppContainer column={true} gap="1.25rem">
        {daysOfWeek.map((day, index) => (
          <InstructorCheckBoxRow key={index}>
            <InstructorCheckBoxColumn>
              <SeletectOptinToogle
                bgColor={checkboxBgColor}
                borderColor={checkboxBorderColor}
                // borderRadius={checkboxBorderRadius}
                type="checkbox"
                id={`${index.toString()} - ${t(`calendar.${day}`)}`}
                value={day}
                onClick={() =>
                  handleCheckboxChange(
                    index,
                    isCheckedArray,
                    setIsCheckedArray,
                    setIsTimePickerVisible,
                    setStartTime,
                    setEndTime
                  )
                }
                checked={isCheckedArray[index]}
              />
              <CheckboxLabel
                bgColor={checkboxBgColor}
                borderColor={checkboxBorderColor}
                borderRadius={checkboxBorderRadius}
                htmlFor={`${index.toString()} - ${t(`calendar.${day}`)}`}
              >
                {t(`calendar.${day}`)}
              </CheckboxLabel>
            </InstructorCheckBoxColumn>

            {isTimePickerVisible[index] && (
              <>
                <InstructorTimeColumn style={{ paddingLeft: "0" }}>
                  {Array.from({ length: timeColumnCounts[index] }).map(
                    (_, timeIndex) => (
                      <TimePickerHolder key={`time_${index}_${timeIndex}`}>
                        <div
                          style={{
                            display: "flex",
                            flex: "1",
                          }}
                        >
                          <TimePickerComponent
                            borderColor={ErrorStartTime[index][timeIndex] || ""}
                            placeholder="Start Time"
                            width="100%"
                            value={startTime[index][timeIndex] || ""}
                            onChange={(newValue: string) =>
                              handleUpdateTime(
                                index,
                                timeIndex,
                                newValue,
                                "start",
                                setStartTime,
                                setEndTime
                              )
                            }
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flex: "1",
                          }}
                        >
                          <TimePickerComponent
                            borderColor={ErrorEndTime[index][timeIndex] || ""}
                            placeholder="End Time"
                            width="100%"
                            value={endTime[index][timeIndex] || ""}
                            onChange={(newValue: string) =>
                              handleUpdateTime(
                                index,
                                timeIndex,
                                newValue,
                                "end",
                                setStartTime,
                                setEndTime
                              )
                            }
                          />
                        </div>
                        {timeIndex === 0 && (
                          <TimePickerHolderContent>
                            <AddSqIcon
                              onClick={() =>
                                handleAddTimePicker(
                                  index,
                                  setTimeColumnCounts,
                                  setStartTime,
                                  setEndTime
                                )
                              }
                            />
                          </TimePickerHolderContent>
                        )}
                        {timeIndex > 0 && (
                          <TimePickerHolderContentDelete>
                            <SvgDelete
                              onClick={() =>
                                handleDeleteTimePicker(
                                  index,
                                  setTimeColumnCounts,
                                  setStartTime,
                                  setEndTime
                                )
                              }
                            />
                          </TimePickerHolderContentDelete>
                        )}
                      </TimePickerHolder>
                    )
                  )}
                </InstructorTimeColumn>
              </>
            )}
          </InstructorCheckBoxRow>
        ))}
      </FlexAppContainer>
      <FlexAppContainer justifyContent="flex-end" gap="1.25rem">
        {officeHours.length > 0 && (
          <ButtonCancel onClick={() => setShowDeletePopUp(!showDeletePopUp)}>
            <MediumTypography
              text={t("User Profile.Clear Data")}
              color="#D04B4B"
            />
          </ButtonCancel>
        )}
        {!loadingState ? (
          <ButtonAdd
            onClick={() =>
              handleGenerate(
                daysOfWeek,
                isCheckedArray,
                startTime,
                endTime,
                toast,
                subId,
                provider,
                setLoadingState,
                getOfficeHours,
                setErrorStartTime,
                setErrorEndTime
              )
            }
          >
            <MediumTypography text={t("forms.Save")} color="#fff" />
          </ButtonAdd>
        ) : (
          <ButtonAdd>
            <MediumTypography text={t("general.loading")} color="#fff" />
          </ButtonAdd>
        )}
      </FlexAppContainer>
      {showDeletePopUp && (
        <PopupDelete
          show={showDeletePopUp}
          setShow={setShowDeletePopUp}
          title={`CLear Your Notifications`}
          handleSubmit={handleClearData}
        />
      )}
    </MainCols>
  );
};

export default InstructorInformationComp;
