import styled from "styled-components";
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";

export const FileContainer = styled.a`
  display: flex;
  column-gap: 1.5rem;
  width: fit-content;
  align-items: center;
`;
export const FileLink = styled.div<StyleThemeInterface>`
  background:${props =>props.bgcolor || "rgb(41, 52, 64)"};
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.625rem;
  box-shadow:${props => props.boxshadow};
  cursor: pointer;
`;
