import React from "react";
import TextHeader from "../../../elements/TextHeader/TextHeader";
import { DiscussionForumCompo } from "../../../components/DiscussionForum/DiscussionForumCompo";

export const DiscussionForum = (): JSX.Element => {


  return (
<> 
<TextHeader titleB="Analytics"/> 
<DiscussionForumCompo />
 </>
  );
};
