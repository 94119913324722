import styled from "styled-components";
import { breakpoints } from "../../../../theme";

interface AddNewModuleHolderProps {
  isloading: string;
  isclicked: string;
  color: string;
}

export const MainHolder = styled.div<{
  contenttype: string;
  overlaybgcolor: string;
}>`
  width: 100%;

  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;

  /* &::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5dd3b3;
    border-radius: 0.625rem;
    box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.25);
  }

  &::-webkit-scrollbar-track {
    background-color: linear-gradient(0deg, #2f6a5a, #5dd3b3);
    border-radius: 0.625rem;
  } */
`;
interface TooltipProps {
  bgcolor: string;
  textcolor: string;
  boxshadow: string;
}
export const Tooltip = styled.div<TooltipProps>`
  visibility: hidden;
  border-radius: 1.25rem;
  background: ${(props) => props.bgcolor};
  color: ${(props) => props.textcolor};
  box-shadow: ${(props) => props.boxshadow};
  width: fit-content;
  padding: 0.5rem 1.38rem 0.63rem 1.25rem;
  text-align: center;
  position: absolute;
  &:dir(ltr) {
    top: 0.8rem;
    left: 2rem;
  }
  &:dir(rtl) {
    top: 0.8rem;
    right: 2rem;
  }
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 5;
  @media (max-width: ${breakpoints.tablets}) {
    &:dir(ltr) {
      top: 3.35rem;
      left: -6.3rem;
    }
    &:dir(rtl) {
      top: 3.35rem;
      right: -6.3rem;
    }
  }
`;

export const Title = styled.div`
  width: 100%;
  align-items: center;
  margin-top: 0.62rem;
  margin-bottom: 1.44rem;
  display: flex;
  column-gap: 0.62rem;
  & > :first-child {
    cursor: pointer;
  }

  & > :last-child:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
`;
interface DivideLineProps {
  lineiconcolor: string;
}
export const BackArrow = styled.div`
  &:dir(ltr) {
    transform: rotate(0deg);
  }
  &:dir(rtl) {
    transform: rotate(180deg);
  }
`;

export const DivideLine = styled.div<DivideLineProps>`
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.lineiconcolor};
  width: 3.125rem;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;
  height: fit-content;
  &:hover {
    ${Tooltip} {
      visibility: visible;
      opacity: 0.9;
      color: #fff;
      transition: color 0.3s;
    }
  }
`;

export const AddNewModuleHolder = styled.button<AddNewModuleHolderProps>`
  margin-top: 0.2rem;

  border: none;
  background: transparent;
  color: ${(props) => (props.isloading === "true" ? "gray" : props.color)};
  &:hover {
    cursor: pointer;
    /* color: ${({ theme }) => theme.dark.main_color}; */
    color: ${(props) => (props.isloading === "true" ? "gray" : "#5DD3B3")};
    transition: color 0.2s ease-in;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  margin: 10% 0 0 -2.15rem;
  @media (max-width: ${breakpoints.tablets}) {
    margin: 15vh 0 0 -0.8rem;
  }
`;
interface DragNDropContainerProps {
  boxshadow: string;
  moduledragndropbgcolor: string;
  bordermodulescontainercolor: number;
  contenttype: string;
  overlaybgcolor: string;
  theme?: string;
}
export const DragNDropContainer = styled.div<DragNDropContainerProps>`
  width: 100%;
  position: relative;

  /* height: fit-content; */
  height: 42.5rem;
  margin-top: 3.44rem;
  &:dir(ltr) {
    padding: 1.56rem 0 5.94rem 3rem;
  }
  &:dir(rtl) {
    padding: 1.56rem 3rem 5.94rem 0rem;
  }
  background-color: ${(props) => props.moduledragndropbgcolor};
  border-radius: 1.5625rem;
  border: ${(props) => props.bordermodulescontainercolor}px solid #576371;
  box-shadow: ${(props) => props.boxshadow};
  margin-bottom: 1.37rem;
  z-index: 5;
`;

export const DragNDropContainerOverlay = styled.div<DragNDropContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  border-radius: 1.5625rem;
  /* background: ${({ overlaybgcolor }) => overlaybgcolor}; */
  /* display: ${({ contenttype }) =>
    contenttype === "Assignment" ||
    contenttype === "Quiz" ||
    contenttype === "Survey"
      ? "block"
      : "none"};
  opacity: ${({ overlaybgcolor, theme, contenttype }) =>
    (contenttype === "Assignment" ||
      contenttype === "Quiz" ||
      contenttype === "Survey") &&
    theme === "light"
      ? 0.85
      : (contenttype === "Assignment" ||
          contenttype === "Quiz" ||
          contenttype === "Survey") &&
        theme === "dark"
      ? 0.5
      : 0}; */
`;

interface DivArrowsWrapperProps {
  bgcolor: string;
}
export const DivArrowsWrapper = styled.div<DivArrowsWrapperProps>`
  padding: 0 0.62rem 0 0.63rem;

  width: 2.625rem;
  height: 1.25rem;
  border-radius: 1.25rem;
  background-color: ${(props) => props.bgcolor};
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 1.25rem;
  left: 48%;
  display: flex;
  background: grid-template-columns;
  &:dir(ltr) {
    flex-direction: row;
  }
  &:dir(rtl) {
    flex-direction: row-reverse;
  }
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${breakpoints.tablets}) {
    left: 46%;
  }
  @media (max-width: ${breakpoints.mobile}) {
    left: 45%;
  }
  & > :first-child {
    cursor: pointer;
    color: #fff;
    &:hover {
      color: ${({ theme }) => theme.dark.main_color};
      transition: color 0.2s ease-in-out;
    }
  }
  & > :last-child {
    cursor: pointer;
    color: #fff;
    &:hover {
      color: ${({ theme }) => theme.dark.main_color};
      transition: color 0.2s ease-in-out;
    }
  }
`;

export const AddModuleWrapper = styled.div`
  /* background: gray; */

  width: 100%;
  display: flex;
  column-gap: 1.25rem;
  justify-content: end;
  align-items: center;
  &:dir(ltr) {
    padding-right: 1.5rem;
  }
  &:dir(rtl) {
    padding-left: 1.5rem;
  }
  /* background-color: gray; */
  & > :first-child:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
  & > :nth-child(2) {
    margin-top: 0.2rem;
  }
  & > :nth-child(2):hover {
    cursor: pointer;
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
`;

export const ModulesContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 0.3rem;
  padding: 1rem 1.25rem 1rem 1.25rem;
  display: flex;
  column-gap: 2.19rem;
  overflow-x: scroll;
  overflow-y: auto;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-ms-overflow-style {
    display: none;
  }

  /* &::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background-image: linear-gradient(180deg, #5dd3b3 0%, #2d3741 100%);
    border-radius: 0.625rem;
    box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.25);
  }

  &::-webkit-scrollbar-track {
    background-color: #2d3741;
    border-radius: 0.625rem;
  } */

  /* &::scrollbar-width {
    display: none;
  } */

  // Hide scrollbar in Firefox
  /* scrollbar-width: thin; // "auto" or "thin" based on your preference 
  scrollbar-color: #5dd3b3 transparent; // Color of thumb and track  */

  /* &::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5dd3b3;
    border-radius: 0.625rem;
    box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.25);
  }

  &::-webkit-scrollbar-track {
    background-color: linear-gradient(0deg, #2f6a5a, #5dd3b3);
    border-radius: 0.625rem;
  } */
`;

export const ModulesContainerDroppable = styled.div`
  display: flex;
  column-gap: 2.19rem;
`;

export const FlexedDiv = styled.div`
  display: flex;
`;
