import styled from "styled-components"
import { StyleThemeInterface } from "../../interfaces/StyleThemeInterface";




export const RubricEmptyCompoDiv = styled.div<StyleThemeInterface>`
width:100%;
/* height:20rem; */
display:flex;
flex-direction:column;
padding:1.25rem;
border-radius: 1.25rem;
background: ${props =>props.bgcolor};
box-shadow: ${props =>props.boxshadow};
`;

export const RubricEmptyDesription = styled.div`
width:100%;
/* height:20rem; */
display:flex;
flex-direction:column;
gap:1.25rem;
margin: 4.5rem 0 ;
align-items:center;
justify-content:center;

`;