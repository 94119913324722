import React, { FC, useState, useEffect } from "react";
import {
  ContentContainer,
  ContentContainerPart,
  CancelButton,
  SubmitButton,
  ButtonsContainer,
} from "./Comments.style";
import { CommentsProps } from "../CommentsInterface.style";
import SubmittionSection from "../SubmmittionSection/SubmittionSection";
import AssesmentSection from "../AssesmentSection/AssesmentSection";
import RatingSection from "../RatingSection/RatingSection";
import AssignmentCommentSection from "../AssignmentCommentSection/AssignmentCommentSection";
import { number } from "yargs";
import { useAuth0 } from "@auth0/auth0-react";
import { post, get } from "../../../../utils/AxiosRequests";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../../../utils/StringManipulation";
import { useMyContext } from "../../../../contexts/surveyGraderContext";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { adminState } from "../../../../recoil/RecoilStates";
const Comments: FC<CommentsProps> = ({
  setShowComment,
  setShowRubric,
  showRating,
  showSubmitedTime,
  showLate,
  student,
  studentIndex,
  updateSubmittedByInstructor,
  allAssignment,
  resourceR,
}) => {
  const { t } = useTranslation();
  // const submissionID = student[studentIndex].submissionID

  // console.log(student[studentIndex].name);
  const [grade, setGrade] = useState(0);
  const [submittedGrade, setSubmittedGrade] = useState<number>(0);
  const [comment, setComment] = useState("");
  const [gradeError, setGradeError] = useState(false);
  const { user } = useAuth0();
  const tenantName = localStorage.getItem("tenant");
  const { answerId, questionComment, commented, setCommented } = useMyContext();
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams();
  const classId = params.classId;
  const [isAdmin] = useRecoilState(adminState)
  // Unused Function.
  const handleGradeChange = (newGrade: number) => {
    setGrade(newGrade);
    setGradeError(false);
  };

  // Unused Function.
  const handleCommentChange = (newComment: string) => {
    setComment(newComment);
  };

  // Handle submit button.
  const handleSubmit = async () => {
    setLoading(true);
    const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
    const provider = auth0ProviderSplitter(user?.sub || "");
    // get the questionComment and the answerId from the context API.
    const newGradeData = {
      comment:
        questionComment === "" || questionComment.toLowerCase() === "no comment"
          ? null
          : questionComment,
      subId: subIdSplitted,
      provider: provider,
      answerId: answerId,
      // assignmentSubmissionId:student[studentIndex].id
    };
    await post(`survey/create/feedback/${tenantName} `, newGradeData);
    setCommented(true);
    setLoading(false);
    // window.location.reload();
  };

  return (
    <ContentContainer>
      {isAdmin === false ?
        <>
          <ContentContainerPart>
            <SubmittionSection
              student={student}
              studentIndex={studentIndex}
              showRating={showRating}
              showLate={showLate}
            />

            {/* <AssesmentSection allAssignment={allAssignment} student={student} studentIndex={studentIndex} setShowRubric={setShowRubric} showLate={showLate} onGradeChange={handleGradeChange} submittedGrade={submittedGrade} grade={grade}/> */}
            {showRating ? <RatingSection /> : ""}
            <AssignmentCommentSection
              showRating={showRating}
              onCommentChange={handleCommentChange}
              comment={comment}
            />
          </ContentContainerPart>
          <ButtonsContainer>
            <Link
              to={`/courseView/content/${classId}`}
              state={{
                activity: resourceR,
                moduleIndex: resourceR?.moduleIndex,
                topicIndex: resourceR?.toIndex,
                contentIndex: resourceR?.contentIndex,
              }}
            >
              <CancelButton>{t("quiz.Cancel")}</CancelButton>
            </Link>
            {commented ? (
              <SubmitButton style={{ opacity: 0.7, cursor: "default" }} disabled>
                {t("dashboard.Submitted")}
              </SubmitButton>
            ) : loading ? (
              <SubmitButton style={{ opacity: 0.7, cursor: "default" }} disabled>
                Loading ..
              </SubmitButton>
            ) : (
              <SubmitButton onClick={() => handleSubmit()}>
                {t("forum.Submit")}
              </SubmitButton>
            )}
          </ButtonsContainer></> : <></>}
    </ContentContainer>
  );
};

export default Comments;
