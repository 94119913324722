import styled from "styled-components";

export const AnalyticsGenerationTextContainer  =styled.div`
display:flex;
gap:1.25rem;
flex-direction:column;
width:100%;
`;

export const AnalyticsGenerationText =styled.div`
display:flex;
gap:1.25rem;
justify-content: space-between;
width:100%;
`;