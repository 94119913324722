import React, { FC } from "react";
import { Container, QuestionsContainer } from "./CreateQuestion.style";
import { CreateQuestionsProps } from "./CreateQuestionInterface";
import NavBar from "../QuizNavBar/main/NavBar";
import QuestionsFilter from "../QuestionsFilter/QuestionsFilter";
import CardQuestions from "../CardQuestions";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useRecoilValue } from "recoil";
const CreateQuestions: FC<CreateQuestionsProps> = ({
  questions,
  setShowCategoryPopup,
  addMoreQuestion,
  setShowshowLoadTemplate,
  setIndex,
  addMorePartQuestions,
  setPartIndex,
  handleShowEditQuest,
  handleShowQuest,
  setDeleteQuestion,
  setDeletePart,
  selectedRandomOption,
  selectedRandomQuestion,
  handleRandomQuestion,
  handleRandomOptions,
  disableClick,
  nameError,
  handleTemplateName,
  showQuest,
  templateName,
}) => {
  const { Container_Gradient_BgColor, boxShadow } =
    useRecoilValue(ColorSelector);
  return (
    <Container bgcolor={Container_Gradient_BgColor} boxshadow={boxShadow}>
      <NavBar
        showQuest={showQuest}
        addMoreQuestion={addMoreQuestion}
        templateName={templateName}
        nameError={nameError}
        handleTemplateName={handleTemplateName}
        setShowshowLoadTemplate={setShowshowLoadTemplate}
        questions={questions}
      />
      <QuestionsFilter
        selectedRandomOption={selectedRandomOption}
        selectedRandomQuestion={selectedRandomQuestion}
        handleRandomQuestion={handleRandomQuestion}
        handleRandomOptions={handleRandomOptions}
      />
      <QuestionsContainer>
        {questions?.map((question, index) => (
          <div key={index}>
            <CardQuestions
              question={question}
              index={index}
              questions={questions}
              setDeleteQuestion={setDeleteQuestion}
              addMorePartQuestions={() => addMorePartQuestions(index)}
              setIndex={setIndex}
              setPartIndex={setPartIndex}
              handleShowEditQuest={handleShowEditQuest}
              handleShowQuest={handleShowQuest}
              setDeletePart={setDeletePart}
              disableClick={disableClick}
            />
          </div>
        ))}
      </QuestionsContainer>
    </Container>
  );
};

export default CreateQuestions;
