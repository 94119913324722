import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GradeItemDetail,
  GradingDeatils,
  HorDividLine,
  QuizCard,
  Quizes,
  RecordAttemptsWrapper,
  StudentDetailsWrapper,
  StudentQuizDetailsRight,
  StudentsDetailsLeft,
  StudentsHeaderContainer,
  StudentsQuizzes,
  TextIconStyling,
} from "../AssignmentInstructor.style";
import { SvgEmpty } from "../../../elements/Icons";
import { LightTypography, Typography } from "../../../elements/fonts/Fonts";
import ReusableProfile from "../../../elements/reusableProfile/ReusableProfile";
import { get } from "../../../utils/AxiosRequests";
import BlueButton from "../../../elements/blueButton/BlueButton";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import { RPProfilePic } from "../../PostsTimeLine/ViewPost/ViewPost.style";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  CourseColorSelector,
} from "../../../recoil/ThemeSelectors";
import { ResourceFrom } from "../../../Views/courseView/content/Content";
import { useTranslation } from "react-i18next";
interface ActivityProps {
  id: any;
  resourceR: ResourceFrom;
}
const SurveyInstructor = ({ id, resourceR }: ActivityProps) => {
  const [profilePictures, setProfilePictures] = useState<any>({});
  const navigate = useNavigate();
  const tenantName = localStorage.getItem("tenant");
  const image = require("../../../assets/userProfile.png");
  const [loading, setLoading] = useState<boolean>(true);
  const [submissionData, setSubmissionData] = useState<any[]>([]);
  const params = useParams();
  const classId = params.classId;
  const { setUserSelectedIndex } = useContext(ClassContext);
  const { ClassGradientBox } = useRecoilValue(CourseColorSelector);
  const { t } = useTranslation();
  // GEt the Submissions By It's ID Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const submissions = await get(
          `survey/get/submitter/${id}/${tenantName}`
        );
        setSubmissionData(submissions.data.students);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  // If there is no data
  if (!loading && submissionData && submissionData.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <SvgEmpty />
        <Typography text={t("assignment.No submissions yet")} />
      </div>
    );
  } else if (loading) {
    // if the data isn't fetched yet
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography text={`${t('general.loading')}...`} />
      </div>
    );
  }

  // Function to navigate to the Grader
  const handleGraderNavigate = (userIndex: number) => {
    setUserSelectedIndex(userIndex);
    navigate(`/graderSurvey/${id}/${classId}`, {
      state: {
        activity: resourceR,
        moduleIndex: resourceR.moduleIndex,
        topicIndex: resourceR.topicIndex,
        contentIndex: resourceR.contentIndex,
      },
    });
  };

  return (
    <>
      <>
        {(() => {
          return (
            <>
              <HorDividLine></HorDividLine>
              <StudentsQuizzes bgColor={ClassGradientBox}>
                <StudentsHeaderContainer>
                  <Typography text={t("online session.Students")} />
                </StudentsHeaderContainer>
                {/* Here We are mapping the submitted student data */}
                {submissionData &&
                  submissionData.length > 0 &&
                  submissionData.map((submission, index) => (
                    <div key={index}>
                      <>
                        <StudentDetailsWrapper>
                          <div
                            style={{ display: "flex", columnGap: "0.63rem" }}
                          >
                            {/* The Profile picture of the student goes Here */}
                            <RPProfilePic
                              src={
                                submission.profilePicture
                                  ? submission.profilePicture
                                  : image
                              }
                            />
                            <StudentsDetailsLeft>
                              <div
                                style={{
                                  display: "flex",
                                  columnGap: "0.62rem",
                                  alignItems: "center",
                                }}
                              >
                                {/* The Full Name of the student Goes Here */}
                                <Typography
                                  text={`${submission.fullName}`}
                                  fontSize="0.875rem"
                                />
                              </div>
                            </StudentsDetailsLeft>
                          </div>
                          <StudentQuizDetailsRight>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  columnGap: "0.62rem",
                                  alignItems: "center",
                                }}
                              >
                                {/* The Button To Navigate To The Grader Goes Here */}
                                <BlueButton
                                  Width="171px"
                                  Height="2.1875rem"
                                  text={t("survey.Student’s Answers")}
                                  Border="1px solid var(--Main-Green-color, #5DD3B3)"
                                  BackColor="#5DD3B3"
                                  OnClick={() => handleGraderNavigate(index)}
                                />
                              </div>
                            </div>
                            {/* {index === 0 && ( */}
                            <RecordAttemptsWrapper>
                              {/* <TextIconStyling>
                                                                                <SvgRecorded />
                                                                                <LightTypography
                                                                                    text={`Recorded 2x`}
                                                                                    fontSize="0.75rem"
                                                                                />
                                                                            </TextIconStyling> */}
                              {/* <TextIconStyling>
                                                                                <SvgEyeIcon color="#6C9AF0" />
                                                                                <LightTypography
                                                                                    text={`Attempted: ${quiz.attemptedQuiz} out of 20`}
                                                                                    fontSize="0.75rem"
                                                                                />
                                                                            </TextIconStyling> */}
                            </RecordAttemptsWrapper>
                            {/* )} */}
                          </StudentQuizDetailsRight>
                        </StudentDetailsWrapper>
                      </>
                    </div>
                  ))}
              </StudentsQuizzes>
            </>
          );
        })()}
      </>
    </>
  );
};

export default SurveyInstructor;
