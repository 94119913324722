import styled from "styled-components";
import { theme } from "../../../theme";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";
export const GradeInputContainer = styled.div<StyleThemeInterface>`
  width: 100%;
  height: 2.5rem;
  border-radius: 0.625rem;
  border: 1px solid ${props =>props.bordercolor};
  display: flex;
  justify-content: center;
  /* margin-top:-0.7rem; */
`;

export const GradeInput = styled.input<StyleThemeInterface>`
  width: 100%;
  height: 2.5rem;
  padding: 0 1rem;
  background-color: transparent;
  border: 1px solid ${props =>props.bordercolor};
  border-radius: 0.625rem;
  /* color: ${theme.dark.primary_text_color}; */
  color: ${(props) => props.color};
  font-size: 0.875rem;
  font-family: "Primary Font-Bold";
  max-width: 10.5rem;
  &::placeholder {
    color: ${(props) => props.color};
    opacity: 0.5;
  }
  &:focus {
    caret-color: ${(props) => props.color};
    outline: none;
  }
`;
export const AssesmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  /* margin-top: 1.88rem; */
  margin: 1.88rem 1.25rem 0 1.25rem;
  /* background:green; */
`;
export const Assesment = styled.div<{ showlate: any }>`
  margin-top: ${(props) => (props.showlate === "true" ? "1.25rem" : "")};
  display: flex;
  justify-content: space-between;
  height: fit-content;
  align-items: center;
`;
export const Ruberic = styled.div`
  color: #5dd3b3;
  font-family: "Primary Font-Bold";
  font-size: 0.75rem;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const GradeLateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const LateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
`;
