import LandingPageStatic from "../../components/landingPageStatic/LandingPageStatic";
import SignInForm from "../../components/SignInForm/SignInForm";
import BlueButton from "../../elements/blueButton/BlueButton";
import TextField from "../../elements/textField/TextField";
import { TypographyRegularDescription, Typography } from "../../elements/fonts/Fonts"
import {InstructionTextRow} from "./ResetPasswordView.style"
const ResetPasswordView = () => {
  return (
    <LandingPageStatic>
    <InstructionTextRow>
        <Typography
            text="Reset Password"
            fontSize="1.25rem"
            lineHeight="normal"
            fontWeight="400"

        />
        <TypographyRegularDescription
            text=""
            fontSize="0.875rem"
            lineHeight="1.375rem"
            fontWeight="400"
        />

    </InstructionTextRow>
    <SignInForm>

        <TextField text="New password" />
        <TextField text="Confirm new password" />
        <BlueButton text="Reset password" />
        
    </SignInForm>

</LandingPageStatic>
  )
}

export default ResetPasswordView