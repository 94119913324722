
import { IdTableAttributes } from "../../../interfaces/AnalyticsCommonInterfaces";

export const AssignmentSubmissionData: IdTableAttributes = {
  id:"Sadsgfds",
  MaxGrade:15,
  StartDate:new Date("2023-10-01T10:30:00Z"),
  CutOffDate:new Date("2023-10-30T10:30:00Z"),
  DueDate:new Date("2023-10-01T15:30:00Z"),
  Students:[
  {
    StdCode: "sf4gddef324",
    Name: "Nour Mohammad",
    ProfilePic: require("../../../assets/NourInstructor.jpg"),
    Status: "Submitted",
    SubmissionDate: new Date("2023-10-01T10:30:00Z"),
    LastModified: new Date("2023-10-02T10:30:00Z"),
    DueDate: new Date("2023-09-20T10:30:00Z"),
    SubmissionFiles: [{ File: "sdfdg/dsfd.pdf" }, { File: "sdfdg/safgdsfd.pdf" }],
    ApplyPenalty: "No",
    Grade: 8,
  },
  {
    StdCode: "sdfgd345rt",
    Name: "John Doe",
    ProfilePic: require("../../../assets/AliAhmad.png"),
    Status: "Unsubmitted",
    SubmissionDate: new Date("2023-10-05T15:45:00Z"),
    LastModified: new Date("2023-10-05T15:45:00Z"),
    DueDate: new Date("2023-09-20T10:30:00Z"),
    SubmissionFiles: [{ File: "sdfdg/assignment.docx" }],
    ApplyPenalty: "Yes",
    // Grade: ,
  },
  {
    StdCode: "sdfgdfg234",
    Name: "Alice Smith",
    ProfilePic: require("../../../assets/AyaInstructor.jpg"),
    Status: "Unsubmitted",
    // SubmissionDate: new Date(""),
    LastModified: new Date("2023-10-02T14:00:00Z"),
    DueDate: new Date("2023-09-20T10:30:00Z"),
    SubmissionFiles: [{ File: "sdfdg/report.pdf" }, { File: "sdfdg/code.zip" }],
    ApplyPenalty: "Yes",
    Grade: 2,
  },
  {
    StdCode: "sdf345sdf",
    Name: "Emily Johnson",
    ProfilePic: require("../../../assets/AyaInstructor.jpg"),
    Status: "Submitted",
    SubmissionDate: new Date("2023-10-03T12:15:00Z"),
    LastModified: new Date("2023-10-03T12:15:00Z"),
    DueDate: new Date("2023-09-20T10:30:00Z"),
    SubmissionFiles: [{ File: "sdfdg/essay.doc" }, { File: "sdfdg/research.pdf" }],
    ApplyPenalty: "No",
    Grade: 4,
  },
  {
    StdCode: "sdfg67dfg",
    Name: "Michael Brown",
    // ProfilePic: require("../../../assets/MichaelBrown.jpg"),
    Status: "Submitted",
    SubmissionDate: new Date("2023-08-06T09:30:00Z"),
    LastModified: new Date("2023-10-06T09:30:00Z"),
    DueDate: new Date("2023-09-20T10:30:00Z"),
    SubmissionFiles: [{ File: "sdfdg/project.zip" }],
    ApplyPenalty: "No",
    // Grade: undefined,
  },
  {
    StdCode: "sdf8sdf324",
    Name: "Sophia Miller",
    ProfilePic: require("../../../assets/KhaledInstructor.jpg"),
    Status: "Unsubmitted",
    SubmissionDate: new Date("2023-10-04T16:45:00Z"),
    LastModified: new Date("2023-15-04T16:45:00Z"),
    DueDate: new Date("2023-11-20T10:30:00Z"),
    SubmissionFiles: [{ File: "sdfdg/report.docx" }],
    ApplyPenalty: "Yes",
    Grade: 1,
  },
  {
    StdCode: "sdfg345dfg",
    Name: "Matthew Turner",
    ProfilePic: require("../../../assets/AyaInstructor.jpg"),
    Status: "Unsubmitted",
    SubmissionDate: new Date("2023-15-7T11:00:00Z"),
    LastModified: new Date("2023-30-7T11:00:00Z"),
    DueDate: new Date("2023-20-20T10:30:00Z"),
    SubmissionFiles: [{ File: "sdfdg/homework.pdf" }],
    ApplyPenalty: "No",
    Grade: 5,
  },
  {
    StdCode: "sdfg34sdf",
    Name: "Olivia White",
    ProfilePic: require("../../../assets/userProfile.png"),
    Status: "Submitted",
    SubmissionDate: new Date("2023-10-08T14:30:00Z"),
    LastModified: new Date("2023-15-08T14:30:00Z"),
    DueDate: new Date("2023-09-20T10:30:00Z"),
    SubmissionFiles: [{ File: "sdfdg/experiment.pdf" }],
    ApplyPenalty: "Yes",
    Grade: 4,
  },
  {
    StdCode: "sdf45sdfg",
    Name: "Daniel Garcia",
    ProfilePic: require("../../../assets/AliAhmad.png"),
    Status: "Submitted",
    SubmissionDate: new Date("2023-10-05T17:20:00Z"),
    LastModified: new Date("2023-10-05T17:20:00Z"),
    DueDate: new Date("2023-10-20T10:30:00Z"),
    SubmissionFiles: [{ File: "sdfdg/code.docx" }],
    ApplyPenalty: "Yes",
    Grade: 2,
  },
  {
    StdCode: "sdfg67sdfg",
    Name: "Ava Robinson",
    ProfilePic: require("../../../assets/AyaInstructor.jpg"),
    Status: "Submitted",
    SubmissionDate: new Date(""),
    LastModified: new Date("2023-10-09T10:00:00Z"),
    DueDate: new Date("2023-09-20T10:30:00Z"),
    SubmissionFiles: [{ File: "sdfdg/presentation.pptx" }],
    ApplyPenalty: "No",
    Grade: 8,
  },
  {
    StdCode: "sdfg89sdf",
    Name: "Ethan Davis",
    ProfilePic: require("../../../assets/KhaledInstructor.jpg"),
    Status: "Submitted",
    SubmissionDate: new Date("2023-12-10T12:45:00Z"),
    LastModified: new Date("2023-15-10T12:45:00Z"),
    DueDate: new Date("2023-11-20T10:30:00Z"),
    SubmissionFiles: [{ File: "sdfdg/analysis.pdf" }],
    ApplyPenalty: "Yes",
    Grade: 2,
  },
]
};

