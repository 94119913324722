import GenerateAttendance from "../../components/generateAttendance/GenerateAttendance"
import NewGenerateAttendance from "../../components/generateAttendance/NewGenerateAttendance"

const GenerateAttendanceView = () => {
  return (
    <>
    <NewGenerateAttendance/>
    {/* <GenerateAttendance/> */}
    </>

  )
}

export default GenerateAttendanceView