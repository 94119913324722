import React, { useEffect, useState } from "react";
import {
  LightTypography,
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import CheckboxSquare from "../../elements/checkboxSquare/CheckboxSquare";
import { FlexDiv } from "../../globalStyles";
import {
  Input,
  InputCont,
  LabelDiv,
  QuestionSelected,
  QuestionSelectedContainer,
} from "./AllQuestions.style";
import { AllQuestionsInterface } from "./AllQuestionsInterface";
import { useMyContext } from "../../contexts/QuestionBanksContext";
import ActionButtons from "./ActionButtons";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import {
  auth0SubIdSplitter,
  auth0ProviderSplitter,
  generateUUID,
} from "../../utils/StringManipulation";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { CourseColorSelector } from "../../recoil/ThemeSelectors";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import DropDownSearchable from "../../elements/dropDownSearchable/DropDownSearchable";
import SearchableDropDown from "../../elements/searchableDropDown/SearchableDropDown";

const Essay = ({ handleCancel }: AllQuestionsInterface) => {
  const { id } = useParams();
  const { user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const { savedQuestions, setSavedQuestions, questionToUpdate, updateMode } =
    useMyContext();
  const [descriptionError, setDescriptionError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [prevData, setPrevData] = useState<any>();
  const [essayData, setEssayData] = useState({
    questionText: "",
    isAttachmentSelected: false,
  });
  const { t } = useTranslation();

  // Set initial state based on updateMode and questionToUpdate
  useEffect(() => {
    if (updateMode && questionToUpdate && questionToUpdate.id) {
      setEssayData({
        questionText: questionToUpdate.question,
        isAttachmentSelected: questionToUpdate.isAttachmentSelected,
      });
    }
  }, [updateMode, questionToUpdate]);

  // function to handle the question from text editor
  const handleQuestionChange = (value: string) => {
    setEssayData((prevState) => ({ ...prevState, questionText: value }));
    setDescriptionError(value.trim() === ""); // Check if the question is empty
  };

  // function to handle the checkbox of attachments
  const handleCheckboxChange = () => {
    setEssayData((prevState) => ({
      ...prevState,
      isAttachmentSelected: !prevState.isAttachmentSelected,
    }));
  };

  //function to save the data in the context state
  const handleSave = async () => {
    const { questionText, isAttachmentSelected } = essayData;

    setDescriptionError(questionText.trim() === "");

    if (questionText.trim() === "") {
      return;
    }

    setIsLoading(true);
    const newEssayQuestion = {
      id: generateUUID(),
      classSourceId: id,
      subId: subId,
      provider:provider,
      contentType: "ESSAY",
      question: questionText,
      isAttachmentSelected: isAttachmentSelected,
    };

    try {
      const response = await axios.post(
        `https://0ddea1gj55.execute-api.eu-west-1.amazonaws.com/dev/question/bank/create/${tenantName}`,
        {
          data: newEssayQuestion,
        }
      );
      setIsLoading(false);
      handleCancel();
    } catch (error) {
      console.log(error);
    }
  };

  const { ClassGradientBox } = useRecoilValue(CourseColorSelector);

  // const data = [
  //   {
  //     name: "data 1",
  //     value: "data 1"
  //   },
  //   {
  //     name: "data 2",
  //     value: "data 2"
  //   },
  //   {
  //     name: "data 3",
  //     value: "data 3"
  //   },
  // ]
  return (
    <QuestionSelectedContainer>
      <QuestionSelected style={{ background: ClassGradientBox }}>
        <Typography text={t("quiz.ESSAY")} fontSize="1.25rem" />

        {/* text editor part */}
        <LabelDiv>
          <Typography text={t("question bank.Question Text")} fontSize="1rem" />
          <TinyEditor
            hasError={descriptionError}
            initialValue={essayData.questionText}
            placeholder={t("question bank.Type your question")}
            height="17.5625rem"
            onChange={handleQuestionChange}
          />
        </LabelDiv>
        
        {/* <DropDownSearchable onChange={()=>{}} value="" options={data}/> */}

        {/* checkbox part */}
        <FlexDiv gap="0.5rem">
          <CheckboxSquare
            name="attachments"
            onChange={handleCheckboxChange}
            value=""
            checked={essayData.isAttachmentSelected}
          />
          <LightTypography text={t("assignment.Attachments")} />
        </FlexDiv>

        {descriptionError && (
          <ErrorDiv>{t("quiz.This field is mandatory")}</ErrorDiv>
        )}
      </QuestionSelected>

      {/* action buttons component part*/}
      <ActionButtons
        handleSave={handleSave}
        handleCancel={handleCancel}
        isLoading={isLoading}
      />
    </QuestionSelectedContainer>
  );
};

export default Essay;
