
// sort the data based on role and creation date
export const customSort = (data: any) => {
    if (!data || !Array.isArray(data)) {
        return [];
    }

    return data.sort((a, b) => {
        // Sorting by role
        if (a.role !== b.role) {
            if (a.role === "teacher") return -1;
            if (b.role === "teacher") return 1;
            return a.role.localeCompare(b.role);
        }

        // If roles are same, sorting by creation date for teachers
        if (a.role === "teacher") {
            if (a.structured !== b.structured) {
                return a.structured ? 1 : -1;
            }
            if (a.createdAt && b.createdAt) {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            }
            return 0;
        }

        // Sorting by creation date for non-teachers
        if (a.createdAt && b.createdAt) {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        }

        return 0;
    });
}
