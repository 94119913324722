import { useDrop  } from 'react-dnd';
import { SvgEdit, SvgDelete } from "../../../elements/Icons"
import TextElement from "../../../elements/textElement/TextElement"
import DraggableGradeBox from "../draggableGradeBox/DraggableGradeBox"
import { SegmentFlex, SegementHolder,TitlePercentageIconsRow  } from "./DroppableTargetSegment.style"
import { TypographyRegularDescription } from '../../../elements/fonts/Fonts';
interface Segment {
    name: string;
    percentage: string;
    gradeBoxes: any[];}
interface DroppableTargetSegmentProps{
    index:number
    segment:Segment,
    calculateTotalPercentage:()=>void;
    handleDrop:(data:any,index:number)=>void
}





const DroppableTargetSegment = ({index,segment,calculateTotalPercentage,handleDrop}:DroppableTargetSegmentProps) => {

    const [,drop] = useDrop({
        accept: 'GRADE_BOX',
        drop: (droppedItem:any) => {
            
          const { data} = droppedItem; 
          handleDrop(data,index)
        },
        collect: (monitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
      })

  return (
<SegementHolder ref={drop}>

<TitlePercentageIconsRow>
    <div style={{ display: "flex", gap: "0.56rem" }}>
        <TextElement
            tag="p"
            text={segment.name}
            fontSize="0.875rem"
            fontWeight="400"
            lineHeight="1.375rem"
            color="#fff"
        />
        <TextElement
            tag="p"
            text={`${segment.percentage}%`}
            fontSize="0.875rem"
            fontWeight="400"
            lineHeight="1.375rem"
            color="#5DD3B3"
        />

    </div>
    <div style={{ display: "flex", gap: "0.56rem" }}>
        <SvgEdit />
        <SvgDelete />
    </div>
</TitlePercentageIconsRow>
<SegmentFlex>
    {segment.gradeBoxes.map((course, gIndex) => (
        <DraggableGradeBox 
            key={gIndex}
            text={course.title}
            gIndex={gIndex}
            index={index}
            >
            <TypographyRegularDescription
                text={course.title}
                color="#fff"
                fontSize="0.875rem"
                fontWeight="400"
                lineHeight="normal"
            />
            
        </DraggableGradeBox >

    ))}
</SegmentFlex>
</SegementHolder >
  )
}

export default DroppableTargetSegment




