import styled from "styled-components";
import { breakpoints } from "../../../theme";

export const CourseViewLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  row-gap: 0.93rem;
  column-gap: 2.19rem;
  position: relative;
`;

export const CourseViewLayoutLoader = styled.div`
  position: absolute;
  top: 2rem;
  left: 45%;
  @media (max-width: ${breakpoints.tablets}) {
    left: 40%;
  }
`;

interface CourseInfoHolderProps {
  bgcolor: string;
  boxshadow: string;
}
export const CourseInfoHolder = styled.div<CourseInfoHolderProps>`
  padding: 2.5rem 1.8rem 2.5rem 2rem;
  border-radius: 0.625rem;
  grid-column: 1/4;
  background: ${({ bgcolor }) => bgcolor};
  box-shadow: ${({ boxshadow }) => boxshadow};
  min-height: fit-content;
  margin-bottom: 1.38rem;
  @media (max-width: ${breakpoints.medium}) {
    order: 4;
    grid-column: 1/5;
  }
  @media (max-width: ${breakpoints.tablets}) {
    border-radius: 1.5625rem;
  }
`;

export const CourseDetailsHolder = styled.div`
  display: flex;
  grid-column: 4/5;
  flex-direction: column;
  column-gap: 1.95rem;
  row-gap: 1.45rem;

  @media (min-width: ${breakpoints.medium}) {
    min-width: 18.625rem;
  }
  @media (max-width: ${breakpoints.medium}) {
    order: 2;
    grid-column: 1/5;
    flex-direction: row;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: ${breakpoints.tablets}) {
    row-gap: 0.63rem;
    & > :last-child {
      display: none;
    }
  }
`;
