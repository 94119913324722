import React from "react";
import { Typography } from "../../elements/fonts/Fonts";
import { PageTitle } from "./CreatePoll.style";
import PollQuestion from "../../components/pollQuestion/PollQuestion";

const CreatePoll = () => {
  return (
    <>
      <PageTitle>
        <Typography text="Poll" fontSize="1.75rem" />
      </PageTitle>

      <PollQuestion/>
    </>
  );
};

export default CreatePoll;
