import styled from "styled-components";
import { breakpoints } from "../../theme";
interface CourseScheduleWrapperProps {
  isopen: string;
  bgcolor: string;
  boxshadow: string;
}

export const CourseScheduleMainWrapper = styled.div<CourseScheduleWrapperProps>`
  padding: 1.31rem 1.06rem 1.31rem 1.25rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
  background: ${({ bgcolor }) => bgcolor};
  box-shadow: ${({ boxshadow }) => boxshadow};
  min-height: fit-content;
  width: 100%;
  @media (max-width: ${breakpoints.medium}) {
    grid-column: 1/3;
  }
  @media (max-width: ${breakpoints.tablets}) {
    grid-column: 1/6;
    padding: 1.37rem 1.25rem 1.44rem 1.19rem;
  }
  & .open {
    max-height: 5000px;
    opacity: 1;
    transform: translateY(0);
    transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
  }

  &.closed {
    max-height: 0;
    opacity: 0;
    transform: translateY(-20px);
    transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
  }
`;
export const TitleWithArrowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${breakpoints.tablets}) {
    cursor: pointer;
  }
`;

export const ArrowWrapper = styled.div`
  display: none;
  cursor: pointer;
  margin-right: -0.4rem;
  &:hover {
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in-out;
  }

  @media (max-width: ${breakpoints.tablets}) {
    display: block;
  }
`;
export const DividLine = styled.div`
  display: none;
  border-style: solid;
  border-width: 1px;
  border-color: #576474;
  width: 101.8%;
  margin-top: 1.44rem;
  margin-bottom: 0.44rem;
  @media (max-width: ${breakpoints.tablets}) {
    display: block;
  }
`;

export const CourseScheduleWrapper = styled.div`
  width: 100%;
  margin-top: 0.81rem;

  @media (max-width: ${breakpoints.tablets}) {
    margin-top: 1.2rem;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;
export const ScheduleDayWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.19rem;
  margin-top: 0.7rem;
  & > :first-child {
    color: ${({ theme }) => theme.dark.main_color};
    margin-bottom: 0.37rem;
  }
`;
export const MainContent = styled.div`
  & .open {
    max-height: 5000px;
    opacity: 1;
    transform: translateY(0);
    transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
  }

  &.closed {
    max-height: 0;
    opacity: 0;
    transform: translateY(-20px);
    transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
  }
`;
export const ScheduleDay = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  /* margin-bottom: 0.2rem; */
  flex-flow: wrap;
  /* row-gap: 0.3rem; */

  /* & > :last-child {
    color: rgba(255, 255, 255, 0.5);
    white-space: wrap;
  } */
`;
export const TimeWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  column-gap: 0.2rem;
  justify-content: space-between;
  align-items: center;
  /* color: cyan !important; */
`;
