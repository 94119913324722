import React, { FC, useState, useEffect } from 'react';
import { CustomCheckboxWrapper, CustomCheckboxInput, CustomCheckbox, MultileQuestionContainer, CheckBox, MultipleInputs } from './MultipleQuestion.style'
import { MultipleQuestionProps } from './MultipleQuestionInterface';
import { ReactComponent as CheckIcon } from '../../../assets/icons/Group2199.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/Delete 1 copy.svg'
import { ErrorDiv } from '../../../elements/ErrorDiv/Errordiv.style';
import DropQuestion from '../../SurveyBranchCreatorDropQuestion/DropQuestion';
import QuestionCRUDTextEditor from "../QuestionCRUDTextEditor/QuestionCRUDTextEditorForBranching";
import SurveyRating from '../../surveyRating/SurveyRating';
import { SurveyLikertScale } from '../../../elements/SurveyCreatorLikertScale/SurveyLikertScale';
import EssayQuestion from '../EssayQuestion/EssayQuestion';
import TrueFalseQuestion from '../TrueFalseQuestion/TrueFalseQuestion';
import { Choice } from '../../generateSurvey/generateSurveyDataInterface';
import {
    
    QuestionIconsContainer,
    QuestionHeaderContainer, 
  } from "../../SurveyCreatorQuestionCRUD/QuestionCRUDTextEditor/QuestionCRUDTextEditor.style";
  import {theme} from '../../../theme'
import { MediumTypography } from '../../../elements/fonts/Fonts';


const MultipleQuestionForBranching: FC<MultipleQuestionProps> = ({ questionError,
    changeBranchName ,
    showQuest, 
    qindex, 
    choice, 
    handleChoiceMatchingQuestion, 
    handleChoiceChecked, 
    allChoices,
     setChoiceIndex, 
    setDeleteChoice,
    quest,
    FillBranch,
    questions,
    index,
    partIndex,
    branchchoice,
    branchChoiceIndex,
    handleChoiceMultipleQuestionForBranching
 }) => {

    const [isChecked, setIsChecked] = useState(choice?.isChecked);
    const [countChecked, setCountChecked] = useState(1);
    const [errorShown, setErrorShown] = useState(false);
    const [isBranched, setIsBranched] = useState<any>(false);
    const [branchType, setBranchType] = useState('');
    const [branchChoiceType, setBranchChoiceType] = useState('');
    const [branchChoices, setBranchChoices] = useState<Choice[]>([]);

    // const handleSingleBranchChoice = () => {
    //     const safeIndex = index ?? 0;
    //     // Ensure allChoices exists and has valid data before accessing its properties
    //     if (allChoices && allChoices[safeIndex]?.branch?.choices) {
    //         setBranchChoices(allChoices[safeIndex]?.branch?.choices || []);
    //     }
    // };


    //   useEffect(() => {
    //     handleSingleBranchChoice();
    //   }, [questions, index, partIndex,branchType]);

    // useEffect(()=>{
    //     setIsBranched(quest?.branching)
        
    //   },[quest?.branching]) 

    // const handleCheckboxChange = () => {
    //     const newValue = !isChecked;
    //     setIsChecked(newValue);
    //     handleChoiceChecked && handleChoiceChecked(qindex || 0, newValue);
    // };

    // useEffect(() => {
    //     if(allChoices)
    //     setCountChecked(allChoices.filter((choice) => choice.isChecked === true).length);
    //     setErrorShown(false); // Reset errorShown when allChoices changes
    // }, [allChoices]);

    // const handleBranchTypeChange = (type:any) => {
    //     setBranchType(type);
    // };

    // useEffect(() => {
    //     if (allChoices) {
    //         setBranchChoiceType(allChoices[qindex || 0]?.branch?.type || '');
    //         console.log("Branch type changed:" , allChoices)
    //     }
    // }, [branchType]);

    return (
        <>
            <MultileQuestionContainer>
                {/* {!showQuest ?

                    countChecked <= 1 ?
                        <CheckBox type='radio' checked={isChecked} onChange={handleCheckboxChange} />
                        : countChecked > 1 ?
                            <CustomCheckboxWrapper>
                                <CustomCheckboxInput type="checkbox" />
                                {isChecked ? (
                                    <CheckIcon id="isChecked" onClick={handleCheckboxChange} />
                                ) : (
                                    <CustomCheckbox id="isChecked" onClick={handleCheckboxChange} />
                                )}
                            </CustomCheckboxWrapper> : ''
                    : ''
                } */}
                <MultipleInputs center='no' value={branchchoice?.name} show={showQuest?.toString()} type='text' width='100%' placeholder='write answer'
                    readOnly={showQuest ? true : false}
                    onChange={(e) => handleChoiceMultipleQuestionForBranching && handleChoiceMultipleQuestionForBranching(qindex || 0,branchChoiceIndex || 0, e)}
                    id='name'
                    hasError={(qindex === 0 || qindex === 1) && !!questionError && !branchchoice?.name}
                />
                {/* <MultipleInputs center='yes' value={choice.percentage} show={showQuest.toString()} type='number' width='10%' placeholder='%'
                    readOnly={showQuest ? true : false}
                    onChange={(e) => handleChoiceMatchingQuestion(qindex, e)}
                    id='percentage'
                    hasError={(qindex === 0 || qindex === 1) && !!questionError && !choice.percentage}
                /> */}
                {/* {allChoices && allChoices.length > 2 ?
                    <DeleteIcon onClick={() => { setDeleteChoice && setDeleteChoice(true); setChoiceIndex && setChoiceIndex(qindex || 0) }} /> :
                    ''
                } */}

            </MultileQuestionContainer>
            {/* {(questionError && !errorShown && qindex === 1) && <ErrorDiv style={{marginLeft:"3.37rem"}}>These fields are mandatory</ErrorDiv>} */}
            {/* {(isBranched && qindex ===0) && (
            <QuestionHeaderContainer>
            <QuestionIconsContainer>
            <MediumTypography
                text="Create the set of questions when student chooses this answer"
                color={theme.dark.primary_text_color}
              />
            </QuestionIconsContainer>
    
          </QuestionHeaderContainer>
        ) } */}

            
        </>
    )
}

export default MultipleQuestionForBranching;
