import styled,{css} from "styled-components";

interface BlueButtonProps {
  justifyContent?: string ;
  backgrourdcolor?: string ;
  borderraduis?: string ;
  padding?:string;
  color?:string;
}

export const ButtonIconAndTextStyle = styled.button<BlueButtonProps>`
  background-color: transparent;
  border:none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 0.63rem;
  width: fit-content;
  height: 1.75rem;
  color: ${(props) => props.color || "#fff"} ;;
  background: ${(props) => props.backgrourdcolor || ""} ;
border-radius:${(props) => props.borderraduis || ""};
padding:${(props) => props.padding || "0" };

  ${(props) =>
    props.justifyContent &&
    css`
        justify-content: ${props.justifyContent};
    `}
  /* transition: box-shadow 0.3s ease-in-out; */

  /* &:hover {
    box-shadow: 0 0 10px rgba(42, 243, 156, 0.3);
  } */
`;
