import React, { useEffect, useState } from "react";
import { FlexBetween, FlexDiv } from "../CompletionTracking.style";
import { getResourceIcon } from "../../CourseTimeline/courseTimelineComponents/ResourceIcon";
import { LightTypography, Typography } from "../../../elements/fonts/Fonts";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../../utils/StringManipulation";
import axios from "axios";
import moment from "moment";
import { SvgCheck } from "../../../elements/Icons";
import CancelButton from "../../../elements/StyledButton/CancelButton";
import { Link } from "react-router-dom";

const Activity = ({ activityR, courseId, moduleIndex, topicIndex }: any) => {
  const [onlineSessionStatus, setOnlineSessionStatus] = useState<any>();
  const [assignmentSubmitted, setAssignmentSubmitted] =
    useState<boolean>(false);
  const [assignmentSubmissionDate, setAssignmentSubmissionDate] = useState("");
  const [resourceData, setResourceData] = useState<any>();

  const { isLoading, user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");

  const getResourceUrl = (
    activityR: any,
    classId: any,
    subIdSplitted: string
  ) => {
    const tenantName = localStorage.getItem("tenant");

    const baseUrl =
      "https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/";
    switch (activityR.type) {
      case "Assignment":
        return `${baseUrl}assignment/get/${tenantName}/${activityR.refId}/${subIdSplitted}?provider=${provider}`;
      case "Quiz":
        return `${baseUrl}quiz/questions/${activityR.refId}/${tenantName}`;
      case "Online_Session":
        return `${baseUrl}activity/onlineSession/${activityR.refId}/${subIdSplitted}/${classId}/${tenantName}?provider=${provider}`;
      case "Survey":
        return `${baseUrl}survey/get/submit/${activityR.refId}/${subIdSplitted}/${tenantName}?provider=${provider}`;
      default:
        return "";
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          getResourceUrl(activityR, courseId, subIdSplitted)
        );
        if (response) {
          if (activityR.type === "Online_Session") {
            const status = getSessionStatus(response.data.data, activityR);
            setOnlineSessionStatus(status);
          }
          if (activityR.type === "Assignment") {
            if (
              response.data.data.message &&
              response.data.data.message.startsWith(
                "Assignment already submitted"
              )
            ) {
              const dateMatch = response.data.data.message.match(
                /\b[A-Za-z]{3} (\w{3} \d{1,2} \d{4})\b/
              );
              const submissionDate = dateMatch ? dateMatch[1] : null;

              setAssignmentSubmitted(true);
              setAssignmentSubmissionDate(submissionDate);
            } else {
              setAssignmentSubmitted(false);
            }
          }
          if (activityR.type === "Survey") {
            if (response.data.data && response.data.data.length > 0)
              setAssignmentSubmitted(true);
            else {
              setAssignmentSubmitted(false);
            }
          }

          setResourceData(response.data.data);
        }
      } catch (error) {
        console.error(`Error fetching ${activityR.type} resource:`, error);
      }
    };

    fetchData();
  }, [activityR, courseId]);

  const getSessionStatus = (activity: any, activityR: any) => {

    if (
      activityR.type == "Online_Session" &&
      activity.session &&
      activity.session.startDateTime &&
      activity.session.endDateTime
    ) {
      const currentDateTime = moment();
      const startDateTime = moment(activity.session.startDateTime);
      const endDateTime = moment(activity.session.endDateTime);
      if (endDateTime <= currentDateTime) {

        return "Ended";
      } else if (startDateTime >= currentDateTime) {

        return "Not Started";
      } else if (
        startDateTime <= currentDateTime &&
        endDateTime >= currentDateTime
      ) {
        return "Join Now";
      }
    }
    return "default";
  };
  return (
    <>
      <FlexBetween>
        <FlexDiv gap="0.63rem" style={{ alignItems: "flex-start" }}>
          {getResourceIcon(activityR.type).icon}
          <div>
            <Typography text={activityR.contentTitle} fontSize="0.875rem" />
            <FlexDiv gap="0.63rem" style={{ marginTop: "0.5rem" }}>
              {/* {activity.isMandatory && (
                            <FlexDiv gap="0.5rem">
                              <SvgStarIcon />
                              <LightTypography
                                text="Mandatory"
                                fontSize="0.75rem"
                              />
                            </FlexDiv>
                          )} */}

              {/* <LightTypography
                            text={`${activity.pages} pages | ${activity.duration} min`}
                            color="#ffffff80"
                            opacity="0.5"
                            fontSize="0.625rem"
                          /> */}
            </FlexDiv>
          </div>
        </FlexDiv>
        <FlexDiv gap="1.25rem">
          {activityR.type === "Assignment" && assignmentSubmissionDate && (
            <FlexDiv>
              <SvgCheck />
              <LightTypography
                text={`Submitted in ${assignmentSubmissionDate}`}
                color="#5DD3B3"
                fontSize="0.75rem"
              />
            </FlexDiv>
          )}
          {activityR.type === "Assignment" && (
            <Link
              to={`/courseView/content/${courseId}`}
              state={{
                activity: activityR,
                moduleIndex: moduleIndex,
                topicIndex: topicIndex,
                contentIndex: activityR.contentIndex,
              }}
            >
              <CancelButton
                name={assignmentSubmissionDate ? "View" : "Start Now"}
                color="#fff"
                backgroundColor={assignmentSubmissionDate ? "#5DD3B3" : "#D85D66"}
                borderColor={assignmentSubmissionDate ? "#5DD3B3" : "#D85D66"}
                onClickFunction={() => { }}
                width="6.9375rem"
              /></Link>
          )}
          {activityR.type === "Survey" && (
            <Link
              to={`/courseView/content/${courseId}`}
              state={{
                activity: activityR,
                moduleIndex: moduleIndex,
                topicIndex: topicIndex,
                contentIndex: activityR.contentIndex,
              }}
            >
              <CancelButton
                name={"View"}
                color="#fff"
                backgroundColor={"#5DD3B3"}
                borderColor={"#5DD3B3"}
                onClickFunction={() => { }}
                width="6.9375rem"
              /></Link>
          )}
          {activityR.type === "Online_Session" && (
            <CancelButton
              name={onlineSessionStatus === "Join Now" ? "Join Now" : "Not Started"}
              color="#fff"
              backgroundColor={onlineSessionStatus === "Join Now" ? "#5DD3B3" : "#D85D66"}
              borderColor={onlineSessionStatus === "Join Now" ? "#5DD3B3" : "#D85D66"}
              onClickFunction={() => {
                onlineSessionStatus === "Join Now" &&
                  window.open(resourceData.path, "_blank");
              }}
              width="6.9375rem"
            />
          )}
        </FlexDiv>
      </FlexBetween>
    </>
  );
};

export default Activity;
