import React, { FC } from "react";
import { theme } from "../../../theme";
import { TypographyRegularDescription } from "../../../elements/fonts/Fonts";
import {
  GradePointsContainer,
  PointsGradeLine,
} from "./QuestionComposedStat.style";
import { QuestionComposedStatProps } from "./QuestionComposedStatInterface";
import { useRecoilValue } from "recoil";
import { ChatsColorSelector } from "../../../recoil/ThemeSelectors";
import { t } from "i18next";
import { toArabicDigits } from "../../../utils/formatDayArabic";
const QuestionComposedStat: FC<QuestionComposedStatProps> = ({
  question,
  showParts,
}) => {
  const { InfoSelectBorderColor } = useRecoilValue(ChatsColorSelector);

  const pointTotal = question.questPart.reduce((sum: number, part) => {
    const gradeAsNumber = parseFloat(part.grade);
    return sum + (isNaN(gradeAsNumber) ? 0 : gradeAsNumber);
  }, 0)

  return (
    <>
      <GradePointsContainer>
        <TypographyRegularDescription
          text={`${toArabicDigits(question.questPart.length.toString())} ${t("quiz.Parts")}`}
          fontSize="0.75rem"
          lineHeight="1.125rem"
          color={theme.dark.main_color}
        />
        <TypographyRegularDescription
          text={`${toArabicDigits(pointTotal.toString())} ${t("quiz.pts")}`}
          fontSize="0.75rem"
          lineHeight="1.125rem"
          color={theme.dark.main_color}
        />
      </GradePointsContainer>
      {showParts ? (
        <PointsGradeLine style={{ background: InfoSelectBorderColor }} />
      ) : (
        ""
      )}
    </>
  );
};
export default QuestionComposedStat;
