import TextElement from "../textElement/TextElement";
import { Props } from "./textInterface";
import { NumberProps } from "./numberInterface";
import NumberElement from "../numberElement/NumberElement";

export function Typography({
  tag = "p",
  text,
  fontSize = "1rem",
  fontWeight,
  lineHeight,
  color,
  wordWrap,
  whiteSpace,
  overflowWrap,
  opacity,
}: Props) {
  return (
    <TextElement
      tag={tag}
      text={text}
      fontSize={fontSize}
      lineHeight={lineHeight}
      fontFamily="Primary Font-Bold"
      fontWeight={fontWeight}
      color={color}
      wordWrap={wordWrap}
      whiteSpace={whiteSpace}
      overflowWrap={overflowWrap}
      opacity={opacity || "1"}
    />
  );
}

export function MediumTypography({
  text,
  fontSize,
  fontWeight,
  lineHeight,
  color,
  whiteSpace,
}: Props) {
  return (
    <TextElement
      tag="p"
      text={text}
      fontSize={fontSize}
      fontFamily="Primary Font-Medium"
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      color={color}
      whiteSpace={whiteSpace}
    />
  );
}
export function LightTypography({
  text,
  fontSize,
  lineHeight,
  fontWeight,
  color,
  fontStyle,
  whiteSpace,
  wordWrap,
}: Props) {
  return (
    <TextElement
      tag="p"
      text={text}
      fontSize={fontSize}
      lineHeight={lineHeight}
      fontFamily="Primary Font-Light"
      fontWeight={fontWeight}
      color={color}
      fontStyle={fontStyle}
      whiteSpace={whiteSpace}
      word-wrap={wordWrap}
    />
  );
}

export function TypographyRegularDescription({
  text,
  fontSize,
  lineHeight,
  fontWeight,
  color,
  whiteSpace,
}: Props) {
  return (
    <TextElement
      tag="p"
      text={text}
      fontSize={fontSize}
      lineHeight={lineHeight}
      fontFamily="Primary Font"
      word-wrap="break-word"
      fontWeight={fontWeight}
      color={color}
      whiteSpace={whiteSpace}
    />
  );
}

export function TypographyRegularLink({
  text,
  fontSize,
  lineHeight,
  fontWeight,
  color,
  whiteSpace,
}: Props) {
  return (
    <TextElement
      tag="a"
      text={text}
      fontSize={fontSize}
      lineHeight={lineHeight}
      fontFamily="Primary Font"
      word-wrap="break-word"
      fontWeight={fontWeight}
      color={color}
      whiteSpace={whiteSpace}
    />
  );
}

export function Credit({ number, fontSize }: NumberProps) {
  return (
    <NumberElement
      tag="p"
      number={number}
      fontSize={fontSize}
      fontFamily="Primary Font-Bold"
      fontWeight="400"
    />
  );
}

export function MediumNumber({ number, fontSize }: NumberProps) {
  return (
    <NumberElement
      tag="p"
      number={number}
      fontSize={fontSize}
      fontFamily="Primary Font-Medium"
      fontWeight="400"
    />
  );
}

export function PrimaryFontNumber({
  number,
  fontSize,
  color,
  wordBreak,
}: NumberProps) {
  return (
    <NumberElement
      tag="p"
      number={number}
      fontSize={fontSize}
      fontFamily="Primary Font-Bold"
      fontWeight="400"
      color={color}
      wordBreak={wordBreak}
    />
  );
}

export function PrimaryLightNumber({
  number,
  fontSize,
  color,
  wordBreak,
}: NumberProps) {
  return (
    <NumberElement
      tag="p"
      number={number}
      fontSize={fontSize}
      fontFamily="Primary Font-Light"
      fontWeight="400"
      color={color}
      wordBreak={wordBreak}
    />
  );
}

export function PrimaryFontText({
  text,
  fontSize,
  fontWeight,
  lineHeight,
}: Props) {
  return (
    <TextElement
      tag="p"
      text={text}
      fontSize={fontSize}
      fontFamily="Primary Font-Light"
      fontWeight={fontWeight}
      word-wrap="break-word"
      lineHeight={lineHeight}
    />
  );
}

export function PrimaryNumber({
  number,
  fontSize,
  color,
  fontWeight,
}: NumberProps) {
  return (
    <NumberElement
      tag="p"
      number={number}
      fontSize={fontSize}
      fontFamily="Primary Font"
      word-wrap="break-word"
      fontWeight={fontWeight}
      color={color}
    />
  );
}

export function PrimaryFontLightText({
  text,
  fontSize,
  fontWeight,
  lineHeight,
  color,
}: Props) {
  return (
    <TextElement
      tag="p"
      text={text}
      fontSize={fontSize}
      fontFamily="Primary Font-Light"
      fontWeight={fontWeight}
      word-wrap="break-word"
      lineHeight={lineHeight}
      color={color}
    />
  );
}

export function PrimaryFontMediumText({
  text,
  fontSize,
  fontWeight,
  lineHeight,
  color,
}: Props) {
  return (
    <TextElement
      tag="p"
      text={text}
      fontSize={fontSize}
      fontFamily="Primary Font-Medium"
      fontWeight={fontWeight}
      word-wrap="break-word"
      lineHeight={lineHeight}
      color={color}
    />
  );
}

export function PrimaryFontBoldText({
  text,
  fontSize,
  fontWeight,
  lineHeight,
  color,
}: Props) {
  return (
    <TextElement
      tag="p"
      text={text}
      fontSize={fontSize}
      fontFamily="Primary Font-Bold"
      fontWeight={fontWeight}
      word-wrap="break-word"
      lineHeight={lineHeight}
      color={color}
    />
  );
}

export function CertificateTypography({
  text,
  fontSize,
  fontWeight,
  lineHeight,
  color,
  whiteSpace,
}: Props) {
  return (
    <TextElement
      tag="p"
      text={text}
      fontSize={fontSize}
      fontFamily="Primary regular Corinthia"
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      color={color}
      whiteSpace={whiteSpace}
    />
  );
}
