interface CourseTagsInterface {
  text: string;
}

const CourseTagsData: CourseTagsInterface[] = [
  // {
  //   text: "Machine Learning",
  // },
  // {
  //   text: "Electrical",
  // },
  // {
  //   text: "Artifical Intelligence",
  // },
  // {
  //   text: "Maths",
  // },
  // {
  //   text: "Mechatronics",
  // },
  // {
  //   text: "AI",
  // },
  // {
  //   text: "Computer Science",
  // },
];

export default CourseTagsData;
