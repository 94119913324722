import React, { useState } from "react";
import {
  FooterGradeChangerContainer,
  FooterGradeChangerRow,
  FooterGradeChangerTextWidth,
  FooterGradeChangerTextPointer,
} from "./FooterGradeChanger.style";
import { MediumTypography, Typography } from "../../../elements/fonts/Fonts";
import { SvgSubstract, AddSqIcon } from "../../../elements/Icons";
import BlueButton from "../../../elements/blueButton/BlueButton";
import { FlexAppContainer } from "../../../App.style";
interface FooterGradeChangerInterface {
  GradeAdditionValue: number;
  setGradeAdditionValue: React.Dispatch<React.SetStateAction<number>>;
  setActiveNewGrade: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateGraph?: () => void;
}
const FooterGradeChanger = ({
  GradeAdditionValue,
  setGradeAdditionValue,
  setActiveNewGrade,
  handleUpdateGraph,
}: FooterGradeChangerInterface) => {
  const handleChangeValueNumber = (text: string) => {
    if (text === "Add") {
      setActiveNewGrade(true);
      setGradeAdditionValue(GradeAdditionValue + 0.5);
    } else if (text === "Revert") {
      setGradeAdditionValue(0);
      setActiveNewGrade(false);
    } else {
      if (GradeAdditionValue === 0.5) {
        setActiveNewGrade(false);
      }
      if (GradeAdditionValue > 0) {
        setGradeAdditionValue(GradeAdditionValue - 0.5);
      }
    }
  };

  return (
    <FooterGradeChangerContainer>
      <FooterGradeChangerRow>
        <MediumTypography text="Grade curve adjustment" />
        <FooterGradeChangerRow
          gap="0.75rem"
          style={{
            width: "6rem",
            justifyContent: "space-between",
          }}
        >
          <FooterGradeChangerTextPointer>
            <SvgSubstract
              onClick={() => handleChangeValueNumber("xxxxx")}
              width={"1.25rem"}
            />
          </FooterGradeChangerTextPointer>
          {/* <FooterGradeChangerTextWidth> */}
          <Typography
            text={GradeAdditionValue.toString()}
            fontSize="1.125rem"
          />
          {/* </FooterGradeChangerTextWidth> */}
          <FooterGradeChangerTextPointer>
            <AddSqIcon
              width={"1.25rem"}
              onClick={() => handleChangeValueNumber("Add")}
              fill="#959BA1"
            />
          </FooterGradeChangerTextPointer>
        </FooterGradeChangerRow>
      </FooterGradeChangerRow>{" "}
      <FlexAppContainer
        column={true}
        // backgroundColor="yellow"
        width="fit-content"
        gap="1rem"
        style={{ marginTop: "-1.5rem !important" }}
      >
        <FlexAppContainer justifyContent={"flex-end"}>
          <Typography text={"Occurency"} fontSize="0.75rem" />
        </FlexAppContainer>

        {GradeAdditionValue > 0 && (
          <FooterGradeChangerRow>
            <BlueButton
              text="Confirm"
              BackColor="#5DD3B3"
              Width="11rem"
              Border="1px solid #5DD3B3"
              Height="2.125rem"
              OnClick={handleUpdateGraph}
            />
            <BlueButton
              text="Revert"
              BackColor="transparent"
              Width="11rem"
              Border="1px solid #D85D66"
              Color="#D85D66"
              Height="2.125rem"
              OnClick={() => handleChangeValueNumber("Revert")}
            />
          </FooterGradeChangerRow>
        )}
      </FlexAppContainer>
    </FooterGradeChangerContainer>
  );
};

export default FooterGradeChanger;
