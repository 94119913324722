import React from "react";
import { AnalyticsPageStylesLayout } from "../../AnalyticsPageStyles";
import AnalyticsBackPage from "../../../../components/AnalyticsTopParticipantForum/AnalyticForumIdShadowHeader/AnalyticsBackPage";
import AssignmentDefaultTable from "../../../../components/Tables/AssigmentDefaultTable/AssigmentDefaultTable";
import { useNavigate } from "react-router-dom";

const AnalyticsAssignmentDefaultTable = () => {
  const navigate = useNavigate();
  const NavigatePage = () => {
    navigate(-1);
  };
  return (
    <AnalyticsPageStylesLayout>
      <AnalyticsBackPage onClick={NavigatePage} />
      <AssignmentDefaultTable />
    </AnalyticsPageStylesLayout>
  );
};

export default AnalyticsAssignmentDefaultTable;
