import styled from "styled-components";
import { theme, breakpoints } from "../../theme";

interface InputsHolderProps {
  display?: boolean;
}

export const Overlay = styled.div<{ isvisible: string }>`
  display: ${(props) => (props.isvisible ? "block" : "none")};
  opacity: ${(props) => (props.isvisible ? "1" : "0")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 300vh;
  transition: opacity 500ms;
  overflow: auto;
  z-index: 10;
`;

export const BackgroundOverlay = styled.div`
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  min-height: 100%;
`;

export const PopupContainer = styled.div`
  margin: auto auto;
  width: max-content;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupContentContainer = styled.div<{ bgColor?: string }>`
  width: 100%;
  max-width: 60vw;
  /* background-color: #384450; */
  background: ${(props) => props.bgColor || "#384450"};
  &:dir(ltr) {
    border-left: 4px solid #5dd3b3;
    border-radius: 0rem 0.625rem 0.625rem 0rem;
  }
  &:dir(rtl) {
    border-right: 4px solid #5dd3b3;
    border-radius: 0.625rem 0rem 0rem 0.625rem;
  }
  min-width: fit-content;
  min-height: fit-content;
  padding: 1.88rem 1.87rem 1.25rem 1.87rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.62rem;
  @media (max-width: ${breakpoints.mobile}) {
    width: 30vw;
  }
`;

export const IconsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 1rem;
  & > :last-child:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.dark.main_color};
    /* color: red !important; */
    transition: color 0.2s ease-in;
  }
`;

export const SubtitleWrapper = styled.div`
  width: 100%;
  height: fit-content;
`;

export const InputsHolder = styled.div<InputsHolderProps>`
  display: ${(props) => (props.display ? "flex" : "none")};
  width: 100%;
  margin: 0.63rem 0;
  flex-direction: column;
  row-gap: 1.25rem;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.63rem;
`;

export const TextEditorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.63rem;
  gap: 1.25rem;
  & > :last-child {
    margin-top: -0.7rem !important;
  }
`;

export const ButtonsHolder = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.62rem;
`;
