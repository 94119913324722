import styled from "styled-components";

export const ParticipantInstructorsCardContainer = styled.div<{ bgColor?: string }>`
display: flex;
/* width: 18.4375rem; */
width: 20rem;
height: 4.3125rem;
flex-shrink: 0;
border-radius: 0.625rem;
background: ${(props) => props.bgColor || "#384450"};
padding: 0.62rem 1rem 0.62rem 0.8rem;
gap:0.6rem;
position: relative;
`;
export const ParticipantInstructorsCardTextDisplay = styled.div`
display: flex;
flex-direction: column;
justify-content:space-between;
width: 100%;
`;