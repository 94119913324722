import AlertSummaryChart from "../../components/AlertSummaryChart/AlertSummaryChart";
import GradeAnalyticsTable from "../../components/GradesAnalyticsTable/GradeAnalyticsTable";
import GradesSchema from "../../components/GradesSchema/GradesSchema";
import StudentActivity from "../../components/StudentActivity/StudentActivity";
import AttendanceAllSessions from "../../components/attendanceAllSessions/AttendanceAllSessions";
import GenerateAttendance from "../../components/generateAttendance/GenerateAttendance";
import GradebookTableV2 from "../../components/gradeBookTable/main/NewVersion/GradebookTableV2";
import NewGradeBookTable from "../../components/gradeBookTable/main/NewGradeBookTable";
import EmptyView from "../../elements/EmptyView/EmptyView";
import { SvgEmpty } from "../../elements/Icons";

const GradeBookTableView = () => {
  return (
    <>
      {/* <NewGradeBookTable/> */}
      <GradebookTableV2 dashboardGrades={false}/>
    </>

    // <EmptyView icon={SvgEmpty} />
  );
};

export default GradeBookTableView;
