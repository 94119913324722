import React, { useContext, useEffect, useState } from "react";
import {
  RubicViewView,
  RubicFlexView,
  RubricClacificationDiv,
  RubricTablenDiv,
  TitleRubricView,
} from "../RubricView/RibricView.style";
import { RubricTableEdition } from "../../components/RubricTableV2/RubricTable";
import { Typography } from "../../elements/fonts/Fonts";
import { RubricUpdateCreatorTab } from "../../components/RubricCreatorTab/RubricUpadteCreatorTab";
import { RubricTableViewData } from "../../components/RubricTableView/RubricTableViewData";
import { RubricUpdateTable } from "../../components/RubricUpdate/RubricUpdateTable";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

export const RubricUpdateView = (): JSX.Element => {
  const { t } = useTranslation();
  const [ColNum, setColNum] = useState<number>(0);
  const [RowsNum, setRowsNum] = useState<number>(0);
  const [minColNum, setminColNum] = useState<number>(0);
  const [minRowsNum, setminRowsNum] = useState<number>(0);
  const [title, settitle] = useState<string>("");
  const [Description, setDescription] = useState<string>("");
  const { id } = useParams();
  const tenantName = localStorage.getItem("tenant");
  const [rubricData, setRubricData] = useState<any>(null);
  const GetRubricById = async () => {
    try {
      const response = await axios.get(`https://0j157bzxcf.execute-api.eu-west-1.amazonaws.com/dev/get/${id}/${tenantName}`)
      setRubricData(response.data.data)
      settitle(response.data.data.title)
      setDescription(response.data.data.description)
      setColNum(response.data.data.levels.length)
      setRowsNum(response.data.data.criteria.length)
    } catch (error) {
      console.log("Error", error)
    }
  }
  // Effect to fetch rubricData from localStorage based on id
  useEffect(() => {
    // const rubricsFromLocalStorage = JSON.parse(localStorage.getItem("rubrics") || "[]");
    // const rubric = rubricsFromLocalStorage.find((item: any) => item.id === id);
    GetRubricById()
    // setRubricData(rubricData)

  }, [id]);

  // Check if rubricData is null before accessing its properties
  if (!rubricData) {
    return <div>{`${t('general.loading')}...`}</div>;
  }


  return (
    <RubicViewView>
      <TitleRubricView>
        <Typography fontSize="1.75rem" text={t("Rubric.Generate Rubric")} />
      </TitleRubricView>
      <RubicFlexView>
        <RubricClacificationDiv>
          <RubricUpdateCreatorTab
            ColNum={ColNum}
            setColNum={setColNum}
            RowsNum={RowsNum}
            setRowsNum={setRowsNum}
            title={title}
            settitle={settitle}
            Description={Description}
            setDescription={setDescription}
          />
        </RubricClacificationDiv>
        <RubricTablenDiv>
          <RubricUpdateTable
            ColNum={ColNum}
            RowsNum={RowsNum}
            title={title}
            Description={Description}
            rubricData={rubricData}
          />
        </RubricTablenDiv>
      </RubicFlexView>
    </RubicViewView>
  );
};
