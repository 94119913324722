import React from "react";
import { SvgDone, SvgFalse } from "../elements/Icons";

// This function returns correct and wrong svgs based on the value (true or false)
export const handleCorrectQuestionSvg = (
  value: boolean | null
): React.ReactElement => {
  if (value === true) {
    return <SvgDone />;
  } else {
    return <SvgFalse />;
  }
};

export const SubmissionDateColor = (
  SubmissionDate: Date | string | undefined,
  DueDate: Date | string | undefined
): string => {
  if (SubmissionDate === undefined || SubmissionDate === null) {
    return "#D85D66";
  } else if (
    SubmissionDate instanceof Date &&
    (DueDate === undefined ||
      DueDate === null ||
      SubmissionDate > new Date(DueDate))
  ) {
    return "#FFF";
  } else if (
    SubmissionDate instanceof Date &&
    (DueDate === undefined ||
      DueDate === null ||
      SubmissionDate < new Date(DueDate))
  ) {
    return "#D85D66";
  } else {
    return "#D85D66";
  }
};

export const SurveyhandleCorrectQuestionSvg = (
  value: any
): React.ReactElement => {
  if (
    (typeof value === "string" && (value as string) === "") ||
    (typeof value === "string" && (value as string).trim() === "") ||
    value === undefined ||
    value === null
  ) {
    return <SvgFalse />;
  } else {
    return <SvgDone />;
  }
};

export const GradeHandleColor = (Grade?: number, MaxGrade?: number) => {
  const AverageGrade = MaxGrade ? MaxGrade / 2 : 20;
  if (Grade && Grade >= AverageGrade) {
    return "#5DD3B3";
  }
  if (Grade && Grade < AverageGrade) {
    return "#D85D66";
  } else {
    return "#576474";
  }
};

export const TrueFalseToSubmittedText = (value?: boolean) => {
  if (value === true) {
    return "Submitted";
  } else {
    return "Not Submitted";
  }
};

export const handleSelectedIndex = (
  index: number,
  selectedIndex: number | null,
  setSelectedIndex: (index: number | null) => void
) => {
  if (selectedIndex === index) {
    setSelectedIndex(null);
  } else {
    setSelectedIndex(index);
  }
};
