import React, { FC, useEffect } from "react";
import {
  QuestionMain,
  QuestionCommentContainer,
  QuestionName,
  OverContainer,
  HeaderQuestionContainer,
  GradeInput,
  QuestionAnswer,
  CommentSection,
} from "./QuestionComment.style";
import { QuestionCommentProps } from "./QuestionCommentInterface";
import {
  TypographyRegularDescription,
  Typography,
  LightTypography,
} from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import { useMyContext } from "../../../contexts/surveyGraderContext";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { adminState } from "../../../recoil/RecoilStates";
const QuestionComment: FC<QuestionCommentProps> = ({
  questions,
  questionIndex,
  submission,
  studentIndex,
  handleQuestionIndexChange,
}) => {
  const [isAdmin] = useRecoilState(adminState)
  const { questionComment, setQuestionComment, setAnswerId, setCommented } =
    useMyContext();
  const { t } = useTranslation();

  const findStudentAnswer = (SurveyQuestionsId: number) => {
    if (
      submission &&
      submission.length > 0 &&
      submission[studentIndex].answers &&
      submission[studentIndex].answers.length > 0 &&
      questions &&
      questions.length > 0
    ) {
      const studentAnswer = submission[studentIndex].answers.find(
        (answer: any) => answer.SurveyQuestionsId === SurveyQuestionsId
      );
      return studentAnswer
        ? studentAnswer.answer.map((answer: any) => answer.choice)
        : "No Answer";
    } else {
      return "No Answer";
    }
  };
  const findAnswerId = (SurveyQuestionsId: number) => {
    if (
      submission &&
      submission.length > 0 &&
      submission[studentIndex].answers &&
      submission[studentIndex].answers.length > 0 &&
      questions &&
      questions.length > 0
    ) {
      const studentAnswer = submission[studentIndex].answers.find(
        (answer: any) => answer.SurveyQuestionsId === SurveyQuestionsId
      );
      return studentAnswer ? studentAnswer.answer[0].id : 0;
    } else {
      return [0];
    }
  };

  const findCommentForAnswer = (SurveyQuestionsId: number) => {
    if (
      submission &&
      submission.length > 0 &&
      submission[studentIndex].answers &&
      submission[studentIndex].answers.length > 0 &&
      questions &&
      questions.length > 0
    ) {
      const studentAnswer = submission[studentIndex].answers.find(
        (answer: any) => answer.SurveyQuestionsId === SurveyQuestionsId
      );
      if (
        studentAnswer &&
        studentAnswer.comment &&
        studentAnswer.comment.comment.trim() !== "No Comment"
      ) {
        setCommented(true);
      } else if (!studentAnswer) {
        setCommented(true);
      } else {
        setCommented(false);
      }
      return studentAnswer && studentAnswer.comment
        ? studentAnswer.comment.comment
        : "";
    } else {
      setCommented(false);
      return "";
    }
  };

  useEffect(() => {
    setQuestionComment(
      findCommentForAnswer(
        questions &&
        questions.length > 0 &&
        questions[questionIndex].SurveyQuestionsId
      )
    );
    handleQuestionIndexChange(questionIndex);
  }, [questions, questionIndex, studentIndex]);

  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };

  const handleCommentChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    answerId: number
  ) => {
    const commentValue = e.target.value;
    setQuestionComment(commentValue);
    setAnswerId(answerId); // Assuming setAnswerId expects an argument, provide the answerId here
  };

  const numberPart =
    questions &&
    questions.length > 0 &&
    questions[questionIndex].title.split(" ")[1];
  const { themeTextColor } = useRecoilValue(ColorSelector);
  return (
    <QuestionMain>
      <QuestionCommentContainer>
        <HeaderQuestionContainer>
          <QuestionName>
            <TypographyRegularDescription
              text={`${t("quiz.Question")} ${numberPart}`}
              fontSize="0.875rem"
              lineHeight="1.375rem"
            />
            {/* <Typography
              text={`${
                questions &&
                questions.length > 0 &&
                questions[questionIndex].question
              }`}
              fontSize="1rem"
            /> */}
            <p
              style={{
                fontSize: "1rem",
              }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  decodeHtml(
                    questions &&
                    questions.length > 0 &&
                    questions[questionIndex].question
                  )
                ),
              }}
            ></p>
          </QuestionName>
          {/* <OverContainer>
                    <GradeInput type='text'/>
                 <LightTypography text='/50' color={theme.dark.primary_text_color} fontSize='0.75rem' lineHeight='1.375rem'/>
                </OverContainer> */}
        </HeaderQuestionContainer>
        <QuestionAnswer>
          {/* <LightTypography text={`${findStudentAnswer(questions && questions.length > 0 && questions[questionIndex].SurveyQuestionsId)}`} color={theme.dark.primary_text_color} fontSize='0.875rem' lineHeight='1.375rem'/> */}
          <p
            style={{
              wordBreak: "break-all",
              marginBottom: 20,
            }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                decodeHtml(
                  findStudentAnswer(
                    questions &&
                    questions.length > 0 &&
                    questions[questionIndex].SurveyQuestionsId
                  )
                )
              ),
            }}
          ></p>
        </QuestionAnswer>
        <CommentSection
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleCommentChange(
              e,
              findAnswerId(
                questions &&
                questions.length > 0 &&
                questions[questionIndex].SurveyQuestionsId
              )
            )
          }
          disabled={isAdmin === true ? true : false}
          placeholder={t("grader.Write a comment")}
          value={questionComment}
          color={themeTextColor}
        />
      </QuestionCommentContainer>
    </QuestionMain>
  );
};

export default QuestionComment;
