import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const TwoTablesFlex = styled.div`
display: flex;
flex-direction: row;

`
export const TablesFlex = styled.div`
display: flex;
gap: 0rem;
`
export const SchemaLink = styled.div`
display: flex;
border-bottom:1px solid #5dd3b3;
color:#5dd3b3;
cursor: pointer;
`

export const Righticons=styled.div`
gap:0.25rem;
position:relative;
:hover{
    background-color:#5DD3B3;
    cursor:pointer;
}
`
export const MessageDiv=styled.div`
align-items:center;
gap:0.5rem;
width:12.5rem;
position:absolute;
display:flex;
top:1.5rem;
right:0;
border-radius: 0.3125rem;
background: #1F2731;
height: 2.325rem;
padding:0.81rem 1.06rem ;
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);

`


export const DropdownContainer = styled.div<StyleThemeInterface>`
  position: absolute;
  top: 100%;
  margin-top:0.3rem;
  left: 0;
  width:fit-content;
  /* background-color: #1F2731; */
  background:${props =>props.bgcolor};
  border: none;
  border-radius: 0.25rem;

  z-index: 1;
`;

export const DropdownItem = styled.div`
  padding: 0.5rem;
  cursor: pointer;
  width:100%;
  
  &:hover {
    background-color: #5DD3B3;
    color:#fff;
  }
  &:first-child{
    border-radius: 0.25rem 0.25rem 0 0;
  }
  &:last-child{
    border-radius:0 0 0.25rem 0.25rem ;
  }
`;

export const ColumnDiv=styled.div`
display:flex;
flex-direction:column;
position:relative;
`