import styled from "styled-components";

export const PollContainer = styled.div`
  width: 100%;
  height: fit-content;
  background: linear-gradient(180deg, #415160 0%, #293440 100%);
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2.5rem 0;
  gap: 1.88rem;
`;
export const QuestionCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.62rem;
`;
export const ChoicesCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.25rem;
`;
export const OneChoices = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.62rem;
`;
export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ProgressBar = styled.div`
  width: 19.625rem;
  height: 0.625rem;
  border-radius: 0.3125rem;
  border: 1px solid #576474;
`;
export const Progress = styled.div<{ width?: string; background?: string }>`
  width: ${({ width }) => (width ? width : "0%")};
  height: 0.5rem;
  border-radius: 0.3125rem;
  background: ${({ background }) => (background ? background : "#5DD3B3")};
  border-radius: 0.3125rem;
`;

export const AllUsers = styled.div<{ bgimage?: string }>`
  display: flex;
  flex-direction: row-reverse;
`;

// To render each ProfileContainer with a higher z-index than the previous one
let zIndexCounter = 1;

export const ProfileContainer = styled.div<{ bgimage?: string }>`
  width: 1.5625rem;
  height: 1.5625rem;
  border-radius: 50%;
  background-image: url(${(props) => props.bgimage});
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* Apply margin-right only to non-first children */
  &:not(:first-child) {
    margin-right: -0.6225rem;
  }
  z-index: ${(props) => zIndexCounter++};
`;

export const FlexDiv = styled.div<{ gap?: string }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : "0.63rem")};
  user-select: none;
`;
