import React, { FC } from "react";
import { PaginationContainer, PageButton, PaginationRender, PaginationBox } from "./Pagination.style";
import { PaginationProps } from "./PaginationInterface";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { SvgArrowDownIcon } from "../Icons";
import { toArabicDigits } from "../../utils/formatDayArabic";

const formatNumber = (num: number) => (num < 10 ? `0${num}` : `${num}`);

const Pagination: FC<PaginationProps> = ({
  totalPosts,
  postsPerPage,
  setCurrentPage,
  currentPage,
  justifyContent = "center",
}) => {
  const totalPages = Math.ceil(totalPosts / postsPerPage);
  const { Pagination_Item_Color, theme } = useRecoilValue(ColorSelector);
  const language = localStorage.getItem("language")
  return (
    <PaginationContainer style={{ justifyContent }}>
      <PaginationBox
        bordercolor={Pagination_Item_Color}
        cursor= {currentPage === 1 ? "no-drop" : "pointer"}
        onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}
        style={{
          color: theme === "light" ? "#2D3741" : "#fff",
          opacity: currentPage === 1 ? 0.4 : 1,
        }}
      >
        <SvgArrowDownIcon style={{ transform: language === "ar" ? 'rotate(-90deg)' : 'rotate(90deg)' }} />
      </PaginationBox>
      <PaginationRender>
        {[...Array(totalPages)].map((_, index) => {
          const page = index + 1;
          return (
            <PageButton
              color={Pagination_Item_Color}
              key={page}
              active={page === currentPage ? 1 : 0}
              onClick={() => setCurrentPage(page)}
              borderColor={Pagination_Item_Color}
            >
              {toArabicDigits(formatNumber(page))}
            </PageButton>
          );
        })}
      </PaginationRender>
      <PaginationBox
        bordercolor={Pagination_Item_Color}
          cursor= {currentPage === totalPages ? "no-drop" : "pointer"}
        onClick={() => setCurrentPage(Math.min(currentPage + 1, totalPages))}
        style={{
          color: theme === "light" ? "#2D3741" : "#fff",
          opacity: currentPage === totalPages ? 0.4 : 1,
        
        }}
      >
        <SvgArrowDownIcon style={{ transform: language === "ar" ? 'rotate(90deg)' : 'rotate(-90deg)' }} />
      </PaginationBox>
    </PaginationContainer>
  );
};

export default Pagination;
