import styled from "styled-components";
import { breakpoints } from "../../theme";

interface AddNewSchemeHolderProps {
  role: string;
}

interface SchemeWrapperProps {
  schemenumber: number;
}

export const ParentContainer = styled.div`
display: flex;
flex-direction: column;
gap: 1.25rem;
`;
export const SchemeGroupsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;

  @media screen and (max-width: ${breakpoints.mobile}) {
    margin-top: -3.25rem !important;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;

  margin-top: 10%;
  @media (max-width: ${breakpoints.tablets}) {
    margin-top: 15vh;
  }
`;

export const EmptyViewWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: gray; */
`;

export const TitleWithAddSchemeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.94rem;
  & > :last-child:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.dark.main_color};
    transition: color 0.2s ease-in;
  }
`;

export const AddNewSchemeHolder = styled.button<AddNewSchemeHolderProps>`
  margin-top: 0.2rem;
  border: none;
  background: transparent;
  color: ${(props) => (props.role === "teacher" ? "#fff" : "gray")};
  &:hover {
    cursor: ${(props) =>
      props.role === "teacher" ? "pointer" : "not-allowed"};
    /* color: ${({ theme }) => theme.dark.main_color}; */
    color: ${(props) => (props.role === "teacher" ? "#5DD3B3" : "gray")};
    transition: color 0.2s ease-in;
  }
`;

export const SchemesWrapper = styled.div<SchemeWrapperProps>`
  columns: ${(props) => (props.schemenumber == 0 ? 1 : 4)};
  width: 100%;

  column-gap: 0;
  @media screen and (max-width: 1780px) {
    columns: ${(props) => (props.schemenumber == 0 ? 1 : 3)};
  }
  @media screen and (max-width: 1385px) {
    columns: ${(props) => (props.schemenumber == 0 ? 1 : 2)};
  }

  @media screen and (min-width: 882px) {
    column-gap: 2.81rem;
  }
  @media screen and (max-width: 882px) {
    columns: 1;
  }

  @media screen and (max-width: ${breakpoints.tablets}) {
    width: 100%;
  }
  @media screen and (max-width: 882px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 0;
  }
  padding-bottom: 1.25rem;
`;
