import React, { useEffect, useState } from "react";
import {
  Typography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import {
  Input,
  InputCont,
  LabelDiv,
  QuestionSelected,
  QuestionSelectedContainer,
} from "./AllQuestions.style";
import { AllQuestionsInterface } from "./AllQuestionsInterface";
import { useMyContext } from "../../contexts/QuestionBanksContext";
import ActionButtons from "./ActionButtons";
import { ErrorDiv } from "../../elements/ErrorDiv/Errordiv.style";
import {
  auth0SubIdSplitter,
  generateUUID,
  auth0ProviderSplitter
} from "../../utils/StringManipulation";
import TinyEditor from "../../elements/tinyEditor/TinyEditor";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  CourseColorSelector,
} from "../../recoil/ThemeSelectors";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const Numerical = ({ handleCancel }: AllQuestionsInterface) => {
  const { id } = useParams();
  const { user } = useAuth0();
  const subId = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const tenantName = localStorage.getItem("tenant");
  const { savedQuestions, setSavedQuestions, questionToUpdate, updateMode } =
    useMyContext();
  const { t } = useTranslation();
  const feedbackRight = "quiz.Your Answer is correct."
  const feedbackWrong = "quiz.Your Answer is incorrect."
  const [isLoading, setIsLoading] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [feedbackRightError, setFeedbackRightError] = useState(false);
  const [feedbackWrongError, setFeedbackWrongError] = useState(false);
  const [correctAnswerError, setCorrectAnswerError] = useState(false);
  const [errorMarginError, setErrorMarginError] = useState(false);
  const [prevData, setPrevData] = useState<any>();
  const [numericalData, setNumericalData] = useState({
    questionText: "",
    correctAnswer: "",
    errorMargin: "",
    feedbackRight: t(feedbackRight),
    feedbackWrong: t(feedbackWrong),
  });

  // Set initial state based on updateMode and questionToUpdate
  useEffect(() => {
    if (updateMode && questionToUpdate && questionToUpdate.id) {
      setNumericalData({
        questionText: questionToUpdate.question,
        correctAnswer: questionToUpdate.correctAnswer,
        errorMargin: questionToUpdate.errorMargin,
        feedbackRight: questionToUpdate.feedbackRight,
        feedbackWrong: questionToUpdate.feedbackWrong,
      });
    }
  }, [updateMode, questionToUpdate]);

  // function to handle the question from text editor
  const handleQuestionChange = (value: string) => {
    setNumericalData((prevState) => ({ ...prevState, questionText: value }));
    setDescriptionError(value.trim() === ""); // Check if the question is empty
  };

  const handleFeedbackRightChange = (value: string) => {
    setNumericalData((prevState) => ({
      ...prevState,
      feedbackRight: value,
    }));
    setFeedbackRightError(value.trim() === "");
  };

  const handleFeedbackWrongChange = (value: string) => {
    setNumericalData((prevState) => ({
      ...prevState,
      feedbackWrong: value,
    }));
    setFeedbackWrongError(value.trim() === "");
  };

  // function to handle the answer
  const handleCorrectAnswer = (value: string) => {
    setNumericalData((prevState) => ({ ...prevState, correctAnswer: value }));
    setCorrectAnswerError(value.trim() === "");
  };

  // function to handle the error margin
  const handleErrorMargin = (value: string) => {
    setNumericalData((prevState) => ({ ...prevState, errorMargin: value }));
    setErrorMarginError(value.trim() === "");
  };

  //function to save the data in the context state
  const handleSave = async () => {
    const {
      questionText,
      correctAnswer,
      errorMargin,
      feedbackRight,
      feedbackWrong,
    } = numericalData;

    setDescriptionError(questionText.trim() === "");
    setCorrectAnswerError(correctAnswer.trim() === "");
    setErrorMarginError(errorMargin.trim() === "");
    setFeedbackRightError(feedbackRight.trim() === "");
    setFeedbackWrongError(feedbackWrong.trim() === "");

    if (
      questionText.trim() === "" ||
      correctAnswer.trim() === "" ||
      errorMargin.trim() === "" ||
      feedbackRight.trim() === "" ||
      feedbackWrong.trim() === ""
    ) {
      return;
    }

    setIsLoading(true);
    const newNumericalQuestion = {
      id: generateUUID(),
      classSourceId: id,
      subId: subId,
      provider:provider,
      contentType: "NUMERIC",
      question: questionText,
      correctAnswer,
      errorMargin,
      feedbackRight: feedbackRight,
      feedbackWrong: feedbackWrong,
    };
    try {
      const response = await axios.post(
        `https://0ddea1gj55.execute-api.eu-west-1.amazonaws.com/dev/question/bank/create/${tenantName}`,
        {
          data: newNumericalQuestion,
        }
      );
      setIsLoading(false);
      handleCancel();
    } catch (error) {
      console.log(error);
    }
    // }
  };

  const { ClassGradientBox } = useRecoilValue(CourseColorSelector);
  const { themeTextColor, bghelp } = useRecoilValue(ColorSelector);
  return (
    <QuestionSelectedContainer>
      <QuestionSelected style={{ background: ClassGradientBox }}>
        <Typography text={t("quiz.NUMERICAL")} fontSize="1.25rem" />
        {/* text editor part */}
        <LabelDiv>
          <Typography text={t("question bank.Question Text")} fontSize="1rem" />
          <TinyEditor
            hasError={descriptionError}
            initialValue={numericalData.questionText}
            placeholder={t("question bank.Type your question")}
            height="17.5625rem"
            onChange={handleQuestionChange}
          />
        </LabelDiv>

        {/* inputs part */}
        <InputCont>
          <LabelDiv style={{ width: "100%" }}>
            <TypographyRegularDescription
              text={t("quiz.Correct Answer")}
              fontSize="0.875rem"
            />
            <Input
              borderColor={bghelp}
              color={themeTextColor}
              hasError={correctAnswerError}
              value={numericalData.correctAnswer}
              onChange={(e) => handleCorrectAnswer(e.target.value)}
              type="number"
              placeholder={t("question bank.Enter Here Correct Answer")}
              width="100%"
            />
          </LabelDiv>
          <LabelDiv style={{ width: "100%" }}>
            <TypographyRegularDescription
              text={t("quiz.Error Margin")}
              fontSize="0.875rem"
            />
            <Input
              borderColor={bghelp}
              color={themeTextColor}
              hasError={errorMarginError}
              value={numericalData.errorMargin}
              onChange={(e) => handleErrorMargin(e.target.value)}
              type="number"
              placeholder={t("question bank.Enter Here Error Margin")}
              width="100%"
            />
          </LabelDiv>
        </InputCont>

        <InputCont>
          <LabelDiv style={{ width: "100%" }}>
            <TypographyRegularDescription
              text={t("quiz.Feedback if right")}
              fontSize="0.875rem"
            />
            <Input
              borderColor={bghelp}
              color={themeTextColor}
              hasError={feedbackRightError}
              value={numericalData.feedbackRight}
              onChange={(e) => handleFeedbackRightChange(e.target.value)}
              type="text"
              placeholder={t("quiz.Feedback if right")}
              width="100%"
            />
          </LabelDiv>
          <LabelDiv style={{ width: "100%" }}>
            <TypographyRegularDescription
              text={t("quiz.Feedback if wrong")}
              fontSize="0.875rem"
            />
            <Input
              borderColor={bghelp}
              color={themeTextColor}
              hasError={feedbackWrongError}
              value={numericalData.feedbackWrong}
              onChange={(e) => handleFeedbackWrongChange(e.target.value)}
              type="text"
              placeholder={t("quiz.Feedback if wrong")}
              width="100%"
            />
          </LabelDiv>
        </InputCont>

        {(descriptionError ||
          correctAnswerError ||
          errorMarginError ||
          feedbackRightError ||
          feedbackWrongError) && (
          <ErrorDiv>{t("quiz.This field is mandatory")}</ErrorDiv>
        )}
      </QuestionSelected>

      {/* action buttons component part*/}
      <ActionButtons
        handleSave={handleSave}
        handleCancel={handleCancel}
        isLoading={isLoading}
      />
    </QuestionSelectedContainer>
  );
};

export default Numerical;
