import React, { useContext } from "react";
import {
  ChartsHeadContainerCell,
  ChartsHeadContainerCellAction,
  ChartsHeadContainerColumn,
  ChartsHeadContainerRow,
  ChartsHeadTitleContainer,
  CircleDot,
} from "./ChartsHeadTitle.style";
import { Typography } from "../../../elements/fonts/Fonts";
import { SvgAvgLine, SvgDownload } from "../../../elements/Icons";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import { FlexAppContainer } from "../../../App.style";
interface ChartsHeadTitleInterface {
  Title: string;
  text?: string;
  Padding?: string;
  ShowDownloadButton?: boolean;
  handleDownload?: () => void;
  ShowAVGLabel?: boolean;
  CircleDoteText?: string;
  ShowCircleDote?: boolean;
  titleClick?: () => void;
}
const ChartsHeadTitle = ({
  Title,
  Padding = "1.50rem 1.25rem 1.25rem 1.25rem",
  ShowDownloadButton = true,
  handleDownload,
  ShowAVGLabel = true,
  ShowCircleDote = true,
  CircleDoteText = "Student Grade",
  text,
  titleClick,
}: ChartsHeadTitleInterface) => {
  const { role } = useContext(ClassContext);
  return (
    <ChartsHeadTitleContainer Padding={Padding}>
      <FlexAppContainer
        column={true}
        gap="1.25rem"
        backgroundColor="transparent"
        width="50%"
      >
        <div
          style={{ cursor: "pointer" }}
          onClick={titleClick ? titleClick : undefined}
        >
          <Typography text={Title} fontSize="1.125rem" />
        </div>
        <Typography text={text || ""} fontSize="0.75rem" />
      </FlexAppContainer>

      <ChartsHeadContainerColumn>
        {/* {ShowDownloadButton && role === "teacher" && ( */}
        {ShowDownloadButton && (
          <ChartsHeadContainerCellAction onClick={handleDownload}>
            <SvgDownload />
            <Typography text="Download" fontSize="0.75rem" />
          </ChartsHeadContainerCellAction>
        )}
        <ChartsHeadContainerRow>
          {ShowAVGLabel && (
            <ChartsHeadContainerCell>
              {" "}
              <SvgAvgLine />
              <Typography text="Average" fontSize="0.75rem" />
            </ChartsHeadContainerCell>
          )}
          {ShowCircleDote && (
            <ChartsHeadContainerCell>
              <CircleDot bgColor="#5DD3B3" />
              <Typography text={CircleDoteText} fontSize="0.75rem" />
            </ChartsHeadContainerCell>
          )}
        </ChartsHeadContainerRow>
      </ChartsHeadContainerColumn>
    </ChartsHeadTitleContainer>
  );
};

export default ChartsHeadTitle;
