import React from "react";
import {
  LoadinPageLayout,
  LoadingContainer,
  LoadingContainerContent,
  LoadingContainerLine,
  LoadingContainerSpace,
  LoadingContainerSvg,
} from "./LodingStyle";
import { LightTypography, Typography } from "../../elements/fonts/Fonts";
import { SvgloadingGroup } from "../../elements/Icons";
import { useRecoilValue } from "recoil";
import { ColorSelector, MainColorSelector } from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";

// interface Loading {
//   Loading
// }

export const LoadingComp = (): JSX.Element => {
  const { t } = useTranslation();
  const { mainBackgroundColor } = useRecoilValue(MainColorSelector);
  const { backgroundColor } = useRecoilValue(ColorSelector);
  return (
    <LoadinPageLayout bgcolor={mainBackgroundColor}>
      <LoadingContainer bgcolor={backgroundColor}>
        <LoadingContainerLine />
        <LoadingContainerContent>
          <LoadingContainerSpace>
            <LoadingContainerSvg>
              <SvgloadingGroup />
            </LoadingContainerSvg>
          </LoadingContainerSpace>

          <Typography
            text={t('general.please wait')}
            fontSize="1rem"
            fontWeight="400"
            lineHeight="normal"
          />
          <LightTypography
            text={t('general.loading your data')}
            fontSize="0.875rem"
            fontWeight="400"
            lineHeight="normal"
          />
        </LoadingContainerContent>
      </LoadingContainer>
    </LoadinPageLayout>
  );
};
