import { DropDownEditDeleteItem } from "./dropDownInterface";
import { SvgDeleteIcon, SvgEditIcon } from "../../elements/Icons";

export const DropDownDataInterface: DropDownEditDeleteItem[] = [
  {
    name: "group.Edit Groups",
    title: "Edit Groups",
    icon: SvgEditIcon,
  },
  {
    name: "group.Edit Schema",
    title: "Edit Schema",
    icon: SvgEditIcon,
  },
  {
    name: "group.Delete",
    title: "Delete",
    icon: SvgDeleteIcon,
  },
];
