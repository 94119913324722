import { TableTrInterface, TitleDataInterface } from "./gradesSchemaInterface"

export const TitleData: TitleDataInterface[] =[ 
  
 
    {
      Title: "gradebook.Grade Name",
    },
    {
      Title: "gradebook.From",
    },
    {
      Title: "gradebook.To",
    },  
  ]

  export const TableTrData: TableTrInterface[] = [

    {
      GradeSchema: [
        {
          GradeName: "A+",
          From: 97,
          To: 100,
        },
        {
          GradeName: "A",
          From: 94,
          To: 97,
        },
        {
          GradeName: "A-",
          From: 90,
          To: 94,
        },
        {
          GradeName: "B+",
          From: 87,
          To: 90,
        },
        {
          GradeName: "B",
          From: 84,
          To: 87,
        },
        {
          GradeName: "B-",
          From: 80,
          To: 84,
        },
        {
          GradeName: "C+",
          From: 76,
          To: 80,
        },
        {
          GradeName: "C",
          From: 72,
          To: 76,
        },
        {
          GradeName: "C-",
          From: 68,
          To: 72,
        },
       
      
      ],
    }
]