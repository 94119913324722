import React from "react";
import { MenuStudentsBox, MenuStudentsRow } from "./MenueOption.style";
import { SvgAccommodations, SvgEmail } from "../../../../elements/Icons";
import { Typography } from "../../../../elements/fonts/Fonts";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../../recoil/ThemeSelectors";
export interface MenuOptionStudentsInterface {
  AccommodationsClick?: () => void;
  MessageClick?: () => void;
}
const MenuOptionStudents = ({
  AccommodationsClick,
  MessageClick,
}: MenuOptionStudentsInterface) => {
  const { bg_selectColor, themeTextColor } = useRecoilValue(ColorSelector);
  return (
    <MenuStudentsBox bgColor={bg_selectColor}>
      <MenuStudentsRow onClick={AccommodationsClick}>
        <SvgAccommodations color={themeTextColor} />
        <Typography text="Accommodations" fontSize="0.875rem" />
      </MenuStudentsRow>
      <MenuStudentsRow onClick={MessageClick}>
        <SvgEmail width="1rem" height="0.75rem" color={themeTextColor} />
        <Typography text="Messages" fontSize="0.875rem" />
      </MenuStudentsRow>
    </MenuStudentsBox>
  );
};

export default MenuOptionStudents;
