import { styled } from "styled-components";



interface CircleColor {
  bgColor?: string; // Define the prop here
} 
interface Flexx {
  FlexDirec?: string; // Define the prop here
  diplays?:string;
  MinWidth?:string;
  color?:string; // Define the prop here
} 


export const PieGrid = styled.div`
display: flex;
    /* place-items: center; */
gap:0.7rem;
flex-direction:column;


  /* background-color:red; */

`;

export const PieflexParent = styled.div<Flexx>`
display:flex;
gap:1rem;
/* color: #D9E4E8; */

/* height:2rem; */
align-items:center;
flex-direction: ${(props) => props.FlexDirec || "row"}; 
/* justify-content:c; */
`;

export const Pieflex = styled.div<Flexx>`
display:flex;
gap:0.63rem;
color:${props =>props.color};
/* background-color:yellow; */
/* height:2rem; */
align-items:center;
flex-direction: ${(props) => props.FlexDirec || "row"}; 
min-width:6.5rem;
min-width:${(props) => props.MinWidth || "6.5rem"};
max-width:12rem;
/* justify-content:c; */
`;

export const PieDot = styled.div<CircleColor>`
width:0.50rem;
height:0.50rem;
border-radius: 50%;
background-color: ${props => props.bgColor }
`;