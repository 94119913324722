import styled from "styled-components";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

export const QuizContainer = styled.div<StyleThemeInterface>`
  &:dir(ltr) {
    border-top-left-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    border-right: 0;
  }
  &:dir(rtl) {
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-left: 0;
  }
  border: 1px solid ${(props) => props.bordercolor || "#576371"};
  padding-bottom: 1rem;
  /* min-height: 34rem; */
  height: 100%;
`;
