import { GradeBookWrapper, InputMaxHolder, GradeBookBody, ModalOverlay, ModalContent, ExitRow, TitleRow, InputSegmentName, SegmentNameLabel, InputPercentage, PercLabel, ButtonsRow, ButtonCancel, ButtonAdd, LeftBorderColor, TitleGroupLogo, MaxGradeLabel, InputMaxGrade, SelectNbOfGrades, SelectNbOfGradesLabel, OptionsNbOfGrades, TitleRadio, RadioButtonsRow, RadioButton1, CheckboxLabel, PercentageLabel } from "./GradeBookModal.style"
import { SvgAdd, SvgClose, SvgCloseWhite } from "../../../elements/Icons";
import { Typography, LightTypography } from "../../../elements/fonts/Fonts";
import { useState } from "react";
import { GradeBookModalProps } from "./gradeBookModalInterface";
import SelectInput from "../../../elements/SelectInput/SelectInput";
const GradeBookModal: React.FC<GradeBookModalProps> = ({ onClose, onSave }) => {
    const [value, setValue] = useState('');
    const [value2, setValue2] = useState('');
    const [segmentName, setSegmentName] = useState("");
    const [segmentPercentage, setSegmentPercentage] = useState("");

    const handleSegmentNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSegmentName(e.target.value);
    };

    const handleSegmentPercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;

        // Check if the input value is greater than 100
        if (parseInt(value) > 100) {
            // If it's greater, set it to 100
            value = '100';
        }

        setSegmentPercentage(value);
    };

    const handleSave = () => {
        onSave?.(segmentName, segmentPercentage);
        onClose?.();
    };

    const options = [
        { text: 'Select the number of grades to be dropped', value: '' }, // Placeholder option
        ...Array.from({ length: 8 }, (_, index) => ({ text: `${index + 1}`, value: index + 1 })),
    ];

    const ebrollmentOptions = [{ name: '1' }, { name: '2' }, { name: '3' }, { name: '4' }]
    const priorityOptions = [{ name: 'High' }, { name: 'Medium' }, { name: 'Low' }]
    const handleSelectChangeEnrollment = (newValue: string) => {
        // setEditTask({...task, enrollment: newValue});
    };
    // const handleInputChange = (event: any) => {
    //     let inputValue = event.target.value;

    //     // Check if the input value is greater than 100
    //     if (inputValue > 100) {
    //         // If it's greater, set it to 100
    //         inputValue = 100;
    //     }

    //     // Update the state with the validated value
    //     setValue(inputValue);
    // };
    const handleInputChange2 = (event: any) => {
        let inputValue2 = event.target.value;

        // Check if the input value is greater than 100

        if (inputValue2 > 100) {
            // If it's greater, set it to 100
            inputValue2 = 100;
        }

        // Update the state with the validated value
        setValue2(inputValue2);
    };
    return (
        <ModalOverlay>
            <ModalContent>
                <GradeBookWrapper>
                    <GradeBookBody>
                        <ExitRow>
                            <SvgCloseWhite style={{ cursor: "pointer" }} onClick={onClose} />
                        </ExitRow>
                        <TitleRow>
                            <TitleGroupLogo>
                                <SvgAdd />
                                <Typography
                                    text='Add New Segment'
                                    fontSize='1rem'
                                    fontWeight='400'
                                    lineHeight='normal'
                                />
                            </TitleGroupLogo>

                        </TitleRow>
                        <SegmentNameLabel>
                            <LightTypography
                                text='Segment Name'
                                fontSize='0.875rem'
                                fontWeight='400'
                                lineHeight='normal'
                            />
                        </SegmentNameLabel>
                        <InputSegmentName placeholder='Assignments' value={segmentName} onChange={handleSegmentNameChange} />

                        <PercLabel>

                            <LightTypography
                                text='Percentage'
                                fontSize='0.875rem'
                                fontWeight='400'
                                lineHeight='normal'
                            />
                        </PercLabel>
                        {/* <PercentageLabel>

                            <LightTypography
                                    text="%"
                                    fontSize='0.875rem'
                                    fontWeight='400'
                                    lineHeight='normal'
                                />
                              
                            </PercentageLabel> */}
                        <InputMaxHolder>

                            <InputPercentage
                                placeholder='40'
                                min="0"
                                max="100"
                                value={segmentPercentage}
                                onChange={handleSegmentPercentageChange}

                            />
                            <LightTypography
                                text='%'
                                fontSize='0.875rem'
                                fontWeight='400'
                                lineHeight='normal'
                            />
                        </InputMaxHolder>
                        <SelectNbOfGradesLabel htmlFor="dropdown">
                            <LightTypography
                                text='Drop the lowest'
                                fontSize='0.875rem'
                                fontWeight='400'
                                lineHeight='normal'

                            />

                        </SelectNbOfGradesLabel>
                        <SelectInput
                            border="none"
                            width="99%"
                            optionWidth='105.3%'
                            value="Select the number of grades to be dropped" options={ebrollmentOptions}
                            onChange={handleSelectChangeEnrollment}
                        />
                        <MaxGradeLabel>
                            <LightTypography
                                text='Max Grade'
                                fontSize='0.875rem'
                                fontWeight='400'
                                lineHeight='normal'
                            />
                        </MaxGradeLabel>
                        <InputMaxHolder>
                            <InputMaxGrade
                                placeholder='100'
                                min="0"
                                max="100"
                                value={value2}
                                onChange={handleInputChange2}

                            />
                            <LightTypography
                                text='%'
                                fontSize='0.875rem'
                                fontWeight='400'
                                lineHeight='normal'
                            />
                        </InputMaxHolder>
                        <TitleRadio>
                            <LightTypography
                                text='Aggregation'
                                fontSize='0.875rem'
                                fontWeight='400'
                                lineHeight='normal'
                            />

                        </TitleRadio>

                        <RadioButtonsRow>
                            <CheckboxLabel htmlFor="average"  >
                                <RadioButton1 type="radio" name="radio1" id="average" />
                                <LightTypography
                                    text='Average'
                                    fontSize='0.875rem'
                                    fontWeight='400'
                                    lineHeight='normal'
                                />
                            </CheckboxLabel>
                            <CheckboxLabel htmlFor="sum"  >
                                <RadioButton1 type="radio" name="radio1" id="sum" />
                                <LightTypography
                                    text='sum'
                                    fontSize='0.875rem'
                                    fontWeight='400'
                                    lineHeight='normal'
                                />

                            </CheckboxLabel>

                        </RadioButtonsRow>

                        <ButtonsRow>
                            <ButtonCancel onClick={onClose}>
                                <Typography
                                    text='Cancel'
                                    color='#D85D66'
                                    fontSize='0.875rem'
                                    fontWeight='400'
                                    lineHeight='normal'
                                />
                            </ButtonCancel>
                            <ButtonAdd onClick={handleSave} >
                                <Typography
                                    text='Save'
                                    color='#fff'
                                    fontSize='0.875rem'
                                    fontWeight='400'
                                    lineHeight='normal'

                                />
                            </ButtonAdd>
                        </ButtonsRow>


                        <LeftBorderColor />

                    </GradeBookBody>


                </GradeBookWrapper>
            </ModalContent>
        </ModalOverlay>
    )
}

export default GradeBookModal