import Styled from "styled-components";

export const SenderInfo = Styled.div`
display:flex;
max-width:50rem;
column-gap:0.88rem;
align-items:start;`;

export const ProfileHolder = Styled.div<{ backGroundColor?: string }>`
position:relative;
margin-top:-0.2rem;
  border-radius: 50%;
  background:${(props) =>
    props.backGroundColor ||
    "linear-gradient(180deg, #2d3741 0%, #576371 100%);"} ;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MaskGroup = Styled.img`
  border-radius: 2.125rem;
  border: 1px solid #707070;
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 50%;
  object-fit: cover;
`;
export const OnlineDiv = Styled.div<{
  online?: boolean;
  OnlineCircleWidth?: string;
}>`
position:absolute;
top:65%;
right:0;
background-color: ${(props) => (props.online ? "#5DD3B3" : "#D85D66")};
border-radius:50%;
width:${(props) => props.OnlineCircleWidth || "0.625rem;"};
height:${(props) => props.OnlineCircleWidth || "0.625rem;"};  

`;

export const GroupAttributes = Styled.div`
display: flex;
flex-direction:column;
gap:0.20rem;
`;



export const ParticipantHolder = Styled.div`
font-family: "Primary Font-Medium";
color:#AAB1B9;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 1.125rem; 
  overflow: hidden;           
  white-space: nowrap;        
  text-overflow: ellipsis;    
  max-width: 50rem;             
  /* Adjust max-width based on viewport size */
  @media (min-width: 576px) {   /* For small devices (landscape phones, 576px and up) */
    max-width: 25rem;
  }

  @media (min-width: 768px) {   /* For medium devices (tablets, 768px and up) */
    max-width: 35rem;
  }

  @media (min-width: 992px) {   /* For large devices (desktops, 992px and up) */
    max-width: 45rem;
  }

  @media (min-width: 1200px) {  /* For extra large devices (large desktops, 1200px and up) */
    max-width: 50rem;
  }
`;
