import React, { useState, useEffect, useContext, useRef } from "react";
import {
  StudentSurveyBox,
  StudentSurveyInstructor,
  StudentSurveyInstructorReply,
  StudentSurveyLayout,
  StudentSurveyPagination,
  StudentSurveyText,
} from "./StudentSurvey.style";
import {
  MediumTypography,
  Typography,
  TypographyRegularDescription,
} from "../../../elements/fonts/Fonts";
// import { SurveyStudentViewData } from "./SurveyStudentViewData";
import { SurverStuMainInterface } from "./SurveyStudentView.interface";
import { SurveyStarQuestion } from "../../../elements/SurveyStarQuestion/SurveyStarQuestion";
import { SurveyRadoiButton } from "../../../elements/SurveyRadoiButtonSelect/SurveyRadoiButton";
import { SurveyLikertScale } from "../../../elements/SurveyLikertScale/SurveyLikertScale";
import { TextEditor } from "../../../elements/TextEditor/TextEditor";
import Pagination from "../../../elements/Pagination/Pagination";
import SubmitButton from "../../../elements/SubmitSaveButton/SubimtButton";
import BlueButton from "../../../elements/blueButton/BlueButton";
import {
  CheckboxLabel,
  SeletectOptinToogle,
} from "../../ProfileCheckBoxOptions/NotificationSetting.style";
import {
  CheckBox,
  StudentCheckboxRowSurvey,
} from "../../../elements/StudentsQuizElements/StudentQuizCheckbox/StudentQuizCheckbox.style";
import { get, post } from "../../../utils/AxiosRequests";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter, auth0ProviderSplitter } from "../../../utils/StringManipulation";
import {
  findCommentForAnswer,
  findStudentAnswer,
  formatingAnswerData,
  returnInitialiseStates,
} from "./surveyStudentCompoUtils";
import SurveyStudentBottom from "./SurveyStudentBottom";
import TinyEditor from "../../../elements/tinyEditor/TinyEditor";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  ColorSelector,
  CourseColorSelector,
} from "../../../recoil/ThemeSelectors";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../../Views/dashboard/UnstructuredContext";
import { WebSocketConnector } from "../../../Views/messages/webSocketConnection";
import DOMPurify from "dompurify";
import { WebsocketContext } from "../../../contexts/notificationContext";
import SurveyEssay from "./SurveyEssay";
import SurveyMCQ from "./SurveyMCQ";
import SurveyMRQ from "./SurveyMRQ";
import { toast } from "react-toastify";
import { adminState } from "../../../recoil/RecoilStates";
interface CheckboxItem {
  id: number;
  choice: string;
  isChecked?: boolean;
}
interface ActivityProps {
  id: any;
}

const SurveyStudentCompo = ({ id }: ActivityProps) => {
  const { progressChanged, setProgressChange } = useContext(ClassContext)

  const [descriptions, setDescriptions] = useState<{ [key: string]: string }>(
    {}
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [PerPage, setPerPage] = useState<number>(2);
  const [starRatings, setStarRatings] = useState<{ [key: string]: number }>({});
  const [classSelections, setClassSelections] = useState<{
    [key: string]: string;
  }>({});
  const [lineValues, setLineValues] = useState<{ [key: string]: string }>({});
  const [selectedChoices, setSelectedChoices] = useState<{
    [key: string]: CheckboxItem[] | undefined;
  }>({});
  const [surveyQuestionId, setSurveyQuestionId] = useState<{
    [key: string]: string | number;
  }>({});
  const [ShowSave, setShowSave] = useState<boolean>(true);
  const [SurveyStudentViewData, setSurveyStudentViewData] = useState<
    SurverStuMainInterface[]
  >([]);
  const [studentAnswerArray, setStudentAnswerArray] = useState<any[]>([]);
  const tenantName = localStorage.getItem("tenant");
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  const [loading, setLoading] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [activity, setActivity] = useState<any>({});
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [surveyTitle, setSurveyTitle] = useState<string>("");
  const { ClassGradientBox } = useRecoilValue(CourseColorSelector);
  const params = useParams();
  const classId = params.classId;
  const { isReady, value, send, connect } = useContext(WebsocketContext);
  const [isAdmin] = useRecoilState(adminState)
  const { checkboxBgColor, checkboxBorderColor, checkboxBorderRadius, CorrectTick_Content_Color, RadioButtonGradientColor, themeTextColor, theme } =
    useRecoilValue(ColorSelector);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isReady && tenantName !== null && tenantName !== undefined && subIdSplitted !== null && subIdSplitted !== undefined) {
      connect();
    }
  }, [tenantName, subIdSplitted])

  // Check if the survey is valid
  useEffect(() => {
    if (activity && activity.startDateTime && activity.endDateTime) {
      const currentDate = new Date();
      const startDateTime = new Date(activity.startDateTime);
      const endDateTime = new Date(activity.endDateTime);
      const isValid =
        currentDate >= startDateTime && currentDate <= endDateTime;
      setValid(isValid);
    } else if (activity && activity.startDateTime) {
      const currentDate = new Date();
      const startDateTime = new Date(activity.startDateTime);
      const isValid = currentDate >= startDateTime;
      setValid(isValid);
    } else if (activity && activity.endDateTime) {
      const currentDate = new Date();
      const endDateTime = new Date(activity.endDateTime);
      const isValid = currentDate <= endDateTime;
      setValid(isValid);
    } else {
      setValid(true);
    }
  }, [SurveyStudentViewData, id]);

  // Fetch the data of the survey
  const fetchData = async () => {
    setDataLoading(true);
    const tenantName = localStorage.getItem("tenant");
    try {
      const surveyData = await get(`survey/questions/${id}/${tenantName}`);
      const studentAnswer = await get(
        `survey/get/submit/${id}/${subIdSplitted}/${tenantName}?provider=${provider}`
      );
      setSurveyStudentViewData(surveyData.data.questions);
      setActivity(surveyData.data);
      setSurveyTitle(surveyData.data.title);
      if (studentAnswer.data && studentAnswer.data.length > 0) {
        // The Student Has an answer
        setShowSave(false);
        setStudentAnswerArray(studentAnswer.data);
        setShowPreview(true);
      } else {
        // The Student doesnt Has an answer
        setShowSave(true);
        setShowPreview(false);
        setStudentAnswerArray([]);
        if(isAdmin === false){
          toast.info(t("course view.Your responses will be saved automatically as you enter them."))
        }
      }
      setDataLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    setStarRatings({});
    setClassSelections({});
    setSelectedChoices({});
    setLineValues({});
    fetchData();

  }, [id]);

  // Initialize the objects states for the answer by calling the returnInitialiseStates function
  useEffect(() => {
    setStarRatings(
      returnInitialiseStates(SurveyStudentViewData).initialStarRatings
    );
    setDescriptions(
      returnInitialiseStates(SurveyStudentViewData).initialDescriptions
    );
    setClassSelections(
      returnInitialiseStates(SurveyStudentViewData).initialClassSelections
    );
    setLineValues(
      returnInitialiseStates(SurveyStudentViewData).initialLineValues
    );
  }, []);

  // Set The answer for the rating Question
  const handleStarSelection = (
    value: number,
    questionIndex: number,
    partIndex?: number
  ) => {
    if (
      questionIndex !== undefined &&
      SurveyStudentViewData[questionIndex] !== undefined
    ) {
      const question = SurveyStudentViewData[questionIndex];
      if (partIndex !== undefined && question.questionParts !== undefined) {
        const part = question.questionParts[partIndex];
        if (part !== undefined) {
          const key = `${part.title}`;
          setStarRatings((prevRatings) => ({
            ...prevRatings,
            [key]: value,
          }));
        }
      } else {
        const key = `${question.title}`;
        const idKey = `${key}_ID`;
        // Retrieve the quizAnswers array from local storage
        let quizAnswers = JSON.parse(localStorage.getItem('localStudentSurveyResponse') || '[]');

        // Find the index of the existing quiz object
        const index = quizAnswers.findIndex((item: any) =>
          item.classSourceId === classId &&
          item.subId === subIdSplitted &&
          item.refId === id
        );

        if (index !== -1) {
          // Retrieve the existing localStudentResponse
          let existingResponse = quizAnswers[index].localStudentResponse || {};

          // Merge the new key-value pair with the existing localStudentResponse
          existingResponse[key] = value;

          // Update the existing object with the merged localStudentResponse
          quizAnswers[index].localStudentResponse = existingResponse;
        } else {
          // Add a new object if it doesn't exist
          const data = {
            subId: subIdSplitted,
            provider: provider,
            classSourceId: classId,
            refId: id,
            localStudentResponse: { [key]: value }
          };
          quizAnswers.push(data);
        }

        // Save the updated array back to local storage
        localStorage.setItem('localStudentSurveyResponse', JSON.stringify(quizAnswers));

        setStarRatings((prevRatings) => ({
          ...prevRatings,
          [key]: value,
        }));
        setSurveyQuestionId((prevId) => ({
          ...prevId,
          [idKey]: question.SurveyQuestionsId || 0,
        }));
      }
    }
  };

  // Set The answer for the Binary Question
  const handleClassSelection = (
    value: string,
    questionIndex: number,
    partIndex?: number
  ) => {
    if (
      questionIndex !== undefined &&
      SurveyStudentViewData[questionIndex] !== undefined
    ) {
      const question = SurveyStudentViewData[questionIndex];

      if (partIndex !== undefined && question.questionParts !== undefined) {
        const part = question.questionParts[partIndex];

        if (part !== undefined) {
          const key = `${part.title}`;
          setClassSelections((prevSelections) => ({
            ...prevSelections,
            [key]: value,
          }));
        }
      } else {
        const key = `${question.title}`;
        const idKey = `${key}_ID`;

        // Retrieve the quizAnswers array from local storage
        let quizAnswers = JSON.parse(localStorage.getItem('localStudentSurveyResponse') || '[]');

        // Find the index of the existing quiz object
        const index = quizAnswers.findIndex((item: any) =>
          item.classSourceId === classId &&
          item.subId === subIdSplitted &&
          item.refId === id
        );

        if (index !== -1) {
          // Retrieve the existing localStudentResponse
          let existingResponse = quizAnswers[index].localStudentResponse || {};

          // Merge the new key-value pair with the existing localStudentResponse
          existingResponse[key] = value;

          // Update the existing object with the merged localStudentResponse
          quizAnswers[index].localStudentResponse = existingResponse;
        } else {
          // Add a new object if it doesn't exist
          const data = {
            subId: subIdSplitted,
            provider: provider,
            classSourceId: classId,
            refId: id,
            localStudentResponse: { [key]: value }
          };
          quizAnswers.push(data);
        }

        // Save the updated array back to local storage
        localStorage.setItem('localStudentSurveyResponse', JSON.stringify(quizAnswers));


        setClassSelections((prevSelections) => ({
          ...prevSelections,
          [key]: value,
        }));
        setSurveyQuestionId((prevId) => ({
          ...prevId,
          [idKey]: question.SurveyQuestionsId || 0,
        }));
      }
    }
  };

  // Set the answer for the Scale Question
  const handleLineValueSelection = (
    value: string,
    questionIndex: number,
    partIndex?: number
  ) => {
    if (questionIndex !== undefined) {
      const question = SurveyStudentViewData[questionIndex];
      if (question) {
        if (partIndex !== undefined && question.questionParts) {
          const part = question.questionParts[partIndex];
          if (part) {
            const key = `${part.title}`;
            setLineValues((prevLineValues) => ({
              ...prevLineValues,
              [key]: value,
            }));
          }
        } else {
          const key = `${question.title}`;
          const idKey = `${key}_ID`;

          // Retrieve the quizAnswers array from local storage
          let quizAnswers = JSON.parse(localStorage.getItem('localStudentSurveyResponse') || '[]');

          // Find the index of the existing quiz object
          const index = quizAnswers.findIndex((item: any) =>
            item.classSourceId === classId &&
            item.subId === subIdSplitted &&
            item.refId === id
          );

          if (index !== -1) {
            // Retrieve the existing localStudentResponse
            let existingResponse = quizAnswers[index].localStudentResponse || {};

            // Merge the new key-value pair with the existing localStudentResponse
            existingResponse[key] = value;

            // Update the existing object with the merged localStudentResponse
            quizAnswers[index].localStudentResponse = existingResponse;
          } else {
            // Add a new object if it doesn't exist
            const data = {
              subId: subIdSplitted,
              provider: provider,
              classSourceId: classId,
              refId: id,
              localStudentResponse: { [key]: value }
            };
            quizAnswers.push(data);
          }

          // Save the updated array back to local storage
          localStorage.setItem('localStudentSurveyResponse', JSON.stringify(quizAnswers));

          setLineValues((prevLineValues) => ({
            ...prevLineValues,
            [key]: value,
          }));
          setSurveyQuestionId((prevId) => ({
            ...prevId,
            [idKey]: question.SurveyQuestionsId || 0,
          }));
        }
      }
    }
  };

  // Set the answer for the essay Question
  const handleDescriptionChange = (
    value: string,
    questionIndex: number,
    partIndex?: number
  ) => {
    if (questionIndex !== undefined) {
      if (partIndex !== undefined) {
        const question = SurveyStudentViewData[questionIndex];
        const part = question?.questionParts?.[partIndex];
        const key = part?.title;

        if (key) {
          setDescriptions((prevDescriptions) => ({
            ...prevDescriptions,
            [key]: value,
          }));
        }
      } else {
        const question = SurveyStudentViewData[questionIndex];
        const key = `${question.title}`;
        const idKey = `${key}_ID`;

        // Retrieve the quizAnswers array from local storage
        let quizAnswers = JSON.parse(localStorage.getItem('localStudentSurveyResponse') || '[]');

        // Find the index of the existing quiz object
        const index = quizAnswers.findIndex((item: any) =>
          item.classSourceId === classId &&
          item.subId === subIdSplitted &&
          item.refId === id
        );

        if (index !== -1) {
          // Retrieve the existing localStudentResponse
          let existingResponse = quizAnswers[index].localStudentResponse || {};

          // Merge the new key-value pair with the existing localStudentResponse
          existingResponse[key] = value;

          // Update the existing object with the merged localStudentResponse
          quizAnswers[index].localStudentResponse = existingResponse;
        } else {
          // Add a new object if it doesn't exist
          const data = {
            subId: subIdSplitted,
            provider: provider,
            classSourceId: classId,
            refId: id,
            localStudentResponse: { [key]: value }
          };
          quizAnswers.push(data);
        }

        // Save the updated array back to local storage
        localStorage.setItem('localStudentSurveyResponse', JSON.stringify(quizAnswers));


        if (key) {
          setDescriptions((prevDescriptions) => ({
            ...prevDescriptions,
            [key]: value,
          }));
          setSurveyQuestionId((prevId) => ({
            ...prevId,
            [idKey]: question.SurveyQuestionsId || 0,
          }));
        }
      }
    }
  };

  // Set the answer for the MCQ Question
  const handleCheckboxChange = (
    item: any,
    questionIndex: number,
    partIndex?: number
  ) => {
    let key: string;
    setSelectedChoices &&
      setSelectedChoices((prevSelectedChoices) => {
        let updatedChoices = prevSelectedChoices
          ? { ...prevSelectedChoices }
          : {};

        if (questionIndex !== undefined) {
          const question = SurveyStudentViewData[questionIndex];

          if (
            (question.composed || question.type === "COMPOSITE") &&
            question.questionParts &&
            question.questionParts.length > 0
          ) {
            const part = question.questionParts[partIndex || 0];
            key = `${question.title}_${part.title}`;

            if (part.type === "MCQ") {
              // For radio buttons
              updatedChoices[key] = [item];
            } else if (part.type === "MRQ" && item && item.id) {


              // For checkboxes
              const existingChoices = updatedChoices[key] || [];
              const existingItemIndex = existingChoices.findIndex(
                (choice: CheckboxItem) => choice.id === item.id
              );
              if (existingItemIndex === -1) {
                updatedChoices[key] = [...existingChoices, item];
              } else {
                updatedChoices[key] = existingChoices.filter(
                  (choice: CheckboxItem) => choice.id !== item.id
                );
              }
            }
          } else {
            // For uncomposed questions (radio buttons)
            key = `${SurveyStudentViewData &&
              SurveyStudentViewData[questionIndex].title
              }`;
            const idKey = `${key}_ID`;
            if (question.type === "MCQ") {
              // If there are only two options (radio buttons)
              updatedChoices[key] = [item];
            } else if (question.type === "MRQ" && item && item.id) {
              // For checkboxes
              const existingChoices = updatedChoices[key] || [];
              const existingItemIndex = existingChoices.findIndex(
                (choice: CheckboxItem) => choice.id === item.id
              );

              if (existingItemIndex === -1) {
                updatedChoices[key] = [...existingChoices, item];
              } else {
                updatedChoices[key] = existingChoices.filter(
                  (choice: CheckboxItem) => choice.id !== item.id
                );
              }
            }
            setSurveyQuestionId((prevId) => ({
              ...prevId,
              [idKey]: question.SurveyQuestionsId || 0,
            }));
          }
        }

        // Retrieve the quizAnswers array from local storage
        let quizAnswers = JSON.parse(localStorage.getItem('localStudentSurveyResponse') || '[]');

        // Find the index of the existing quiz object
        const index = quizAnswers.findIndex((item: any) =>
          item.classSourceId === classId &&
          item.subId === subIdSplitted &&
          item.refId === id
        );

        if (index !== -1) {
          // Retrieve the existing localStudentResponse
          let existingResponse = quizAnswers[index].localStudentResponse || {};

          // Merge the new key-value pair with the existing localStudentResponse
          existingResponse[key] = updatedChoices[key];

          // Update the existing object with the merged localStudentResponse
          quizAnswers[index].localStudentResponse = existingResponse;
        } else {
          // Add a new object if it doesn't exist
          const data = {
            subId: subIdSplitted,
            provider: provider,
            classSourceId: classId,
            refId: id,
            localStudentResponse: { [key]: updatedChoices[key] }
          };
          quizAnswers.push(data);
        }

        // Save the updated array back to local storage
        localStorage.setItem('localStudentSurveyResponse', JSON.stringify(quizAnswers));

        // Return the updated choices dictionary
        return updatedChoices;
      });
  };

  // Handle The Pagination of the Page
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };

  const totalDataCount = SurveyStudentViewData.length;
  const indexOfLast = currentPage * PerPage;
  const remainingDataCount = totalDataCount - indexOfLast;

  // Handle the submission of the answer
  const handelSave = async () => {
    // If The student isn't in the last page he will go to the next page
    if (remainingDataCount > 0) {
      setCurrentPage(currentPage + 1);
    } else {
      // if he is in the last page he will submit the answer

      const timerData = {
        subId: subIdSplitted,
        propvider: provider,
        classSourceId: classId,
        refId: id,
        // No need to include `time` since we are just identifying the object to remove
      };

      let quizAnswers = JSON.parse(localStorage.getItem('localStudentSurveyResponse') || '[]');

      // Find the index of the object that matches the criteria
      const Answersindex = quizAnswers.findIndex((item: any) =>
        item.classSourceId === timerData.classSourceId &&
        item.subId === timerData.subId &&
        item.refId === timerData.refId
      );

      // If the object exists, remove it from the array
      if (Answersindex !== -1) {
        quizAnswers.splice(Answersindex, 1);
      }

      // Save the updated array back to local storage
      localStorage.setItem('localStudentSurveyResponse', JSON.stringify(quizAnswers));

      setLoading(true);
      setCurrentPage(1);
      const transformedArray = formatingAnswerData(
        selectedChoices,
        descriptions,
        starRatings,
        classSelections,
        lineValues,
        surveyQuestionId,
        subIdSplitted,
        id,
        provider
      );
      const trackingData = {
        classSourceId: classId,
        length: "",
        percentage: 100,
        refId: id,
        type: "Survey",
        subId: subIdSplitted,
        provider: provider,
      };
      try {
        await post(`survey/create/submit/${tenantName}`, transformedArray);
        const trackinResponse = await post(
          `completionTracking/create/${tenantName}`,
          trackingData
        );
        setProgressChange(!progressChanged);
        if (isReady && send) {

          send(
            JSON.stringify({
              action: "sendPublic",
              data: {
                tenantName: tenantName,
                classSourceId: classId,
                title: surveyTitle,
                activityType: "Survey",
                refId: id,
                refTable: "SurveyActivities",
                subId: subIdSplitted,
                provider: provider,
                type: "createSubmit",
              },
            })
          );
        }
        setLoading(false);
        setDescriptions({});
        setClassSelections({});
        setLineValues({});
        setStarRatings({});
        fetchData();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const indexOfFirst = indexOfLast - PerPage;

  // Handle the preview Button
  const hanldeShowPreview = () => {
    setShowPreview(false);
  };

  if (dataLoading) {
    // Loading will apear when the data doesnt fetched yet
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography text={`${t('general.loading')}...`} />
      </div>
    );
  }
  //handle the close button
  const handleClose = () => {
    setShowPreview(true);
  };
  if (showPreview) {
    // hanlde if the user clicks on the preview button
    return (
      <StudentSurveyPagination>
        <BlueButton
          text={t("assignment.preview")}
          OnClick={hanldeShowPreview}
          Width="8.8125rem"
          Height="2.1875rem"
          BackColor="#5DD3B3"
        />
      </StudentSurveyPagination>
    );
  }

  return (
    <>
      {/* The Questions Rendering start here */}
      {isAdmin === false && (
      <StudentSurveyLayout>
        <StudentSurveyText>
          {SurveyStudentViewData.slice(indexOfFirst, indexOfLast).map(
            (question, index) => (
              <StudentSurveyBox key={index} bgColor={ClassGradientBox}>
                <Typography
                  text={`${t("quiz.Question")} ${index + 1}` || ""}
                  fontSize="1rem"
                  color="#5DD3B3"
                />
                {/* ////////////////////////////////COMPOSITE TYPE////////////////////////////// */}
                {/* Commented Code in the utils file */}

                {/* ////////////////////////////////////NORMAL TYPE////////////////////////////////// */}

                {question.type === "RATE" ? ( // Rating Question
                  <SurveyStarQuestion
                    // PartTitle={question.title || ""}
                    text={question.question || ""}
                    onChange={(value: number) => {
                      if (question.id) {
                        handleStarSelection(value, indexOfFirst + index);
                      }
                    }}
                    showStarDisplay={true}
                    studentAnswer={
                      findStudentAnswer(
                        question.SurveyQuestionsId || 0,
                        studentAnswerArray,
                        question.title || '',
                        classId || '',
                        subIdSplitted,
                        id,
                        question.type
                      )[0].choice
                    }
                    setStarRatings={setStarRatings}
                    setSurveyQuestionId={setSurveyQuestionId}
                    id={id}
                    question={question}
                    studentAnswerArray={studentAnswerArray}
                    comment={findCommentForAnswer(
                      question.SurveyQuestionsId || 0,
                      studentAnswerArray
                    )}
                    disabled={!ShowSave}
                  />
                ) : question.type === "BINARY" ? ( // Binary Question
                  <SurveyRadoiButton
                    // PartTitle={question.title || ""}
                    text={question.question || ""}
                    onChange={(value: string) => {
                      if (question.id) {
                        handleClassSelection(value, indexOfFirst + index);
                      }
                    }}
                    studentAnswer={
                      findStudentAnswer(
                        question.SurveyQuestionsId || 0,
                        studentAnswerArray,
                        question.title || '',
                        classId || '',
                        subIdSplitted,
                        id,
                        question.type
                      )[0].choice
                    }
                    comment={findCommentForAnswer(
                      question.SurveyQuestionsId || 0,
                      studentAnswerArray
                    )}
                    setSurveyQuestionId={setSurveyQuestionId}
                    setClassSelections={setClassSelections}
                    id={id}
                    question={question}
                    studentAnswerArray={studentAnswerArray}
                    disabled={!ShowSave}
                  />
                ) : question.type === "SCALE" ? ( // Scale Question
                  <SurveyLikertScale
                    // PartTitle={question.title || ""}
                    text={question.question || ""}
                    onChange={(value: string) => {
                      if (question.id) {
                        handleLineValueSelection(value, indexOfFirst + index);
                      }
                    }}
                    showText={true}
                    disabled={!ShowSave} // Disable if studentAnswer is present
                    studentAnswer={
                      findStudentAnswer(
                        question.SurveyQuestionsId || 0,
                        studentAnswerArray,
                        question.title || '',
                        classId || '',
                        subIdSplitted,
                        id,
                        question.type
                      )[0].choice
                    } // Pass the studentAnswer as a prop
                    setLineValues={setLineValues}
                    setSurveyQuestionId={setSurveyQuestionId}
                    id={id}
                    question={question}
                    studentAnswerArray={studentAnswerArray}
                    comment={findCommentForAnswer(
                      question.SurveyQuestionsId || 0,
                      studentAnswerArray
                    )}
                  />
                ) : question.type === "ESSAY" ? ( // Essay Question
                  <SurveyEssay
                    ShowSave={ShowSave}
                    studentAnswerArray={studentAnswerArray}
                    handleDescriptionChange={handleDescriptionChange}
                    question={question}
                    index={index}
                    indexOfFirst={indexOfFirst}
                    setDescriptions={setDescriptions}
                    id={id}
                    setSurveyQuestionId={setSurveyQuestionId}
                  />
                ) : question.type === "MCQ" ? ( // MCQ Question
                  <SurveyMCQ
                    question={question}
                    studentAnswerArray={studentAnswerArray}
                    handleCheckboxChange={handleCheckboxChange}
                    index={index}
                    indexOfFirst={indexOfFirst}
                    selectedChoices={selectedChoices}
                    id={id}
                    ShowSave={ShowSave}
                    setSelectedChoices={setSelectedChoices}
                    setSurveyQuestionId={setSurveyQuestionId}
                  />
                ) : question.type === "MRQ" ? ( // MRQ Question
                  <SurveyMRQ
                    handleCheckboxChange={handleCheckboxChange}
                    question={question}
                    ShowSave={ShowSave}
                    studentAnswerArray={studentAnswerArray}
                    index={index}
                    indexOfFirst={indexOfFirst}
                    id={id}
                    selectedChoices={selectedChoices}
                    setSelectedChoices={setSelectedChoices}
                    setSurveyQuestionId={setSurveyQuestionId}
                  />
                ) : (
                  ""
                )}
              </StudentSurveyBox>
            )
          )}
        </StudentSurveyText>

        <StudentSurveyPagination>
          {/* The Buttons Container goes Here */}
          {isAdmin === false ?
            <SurveyStudentBottom
              valid={valid}
              remainingDataCount={remainingDataCount}
              handelSave={handelSave}
              handleClose={handleClose}
              loading={loading}
              ShowSave={ShowSave}
            /> : ""}
          {/* The Pagination of the page goes Here */}
          {SurveyStudentViewData.length > PerPage &&
            <Pagination
              postsPerPage={PerPage}
              currentPage={currentPage}
              totalPosts={SurveyStudentViewData.length}
              setCurrentPage={handlePageChange}
            />}
        </StudentSurveyPagination>
      </StudentSurveyLayout>
      )}
    </>
  );
};

export default SurveyStudentCompo;
