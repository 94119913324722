import ParticipantCard from "../../components/participantCard/ParticipantCard";
import { useParams } from "react-router";
import { useState, useEffect, useContext } from "react";
import {
  InstructorsRow,
  InstructorsWrapper,
  StudentsWrapper,
  StudentsRow,
  TextHolder,
  AuditorWrapper,
} from "./Participants.style";
import { Typography } from "../../elements/fonts/Fonts";
import { get } from "../../utils/AxiosRequests";
import { AddSqIcon, SvgNoResult } from "../../elements/Icons";
import { useTranslation } from "react-i18next";
import { FlexBetween } from "../../globalStyles";
import ButtonIconAndText from "../../elements/ButtonIconAndText/ButtonIconAndText";
import { ColorSelector } from "../../recoil/ThemeSelectors";
import { useRecoilValue } from "recoil";
import PopUp from "../../elements/Popup/PopUp";
import AddAuditors from "../../components/addAuditors/AddAuditors";
import axios from "axios";
import { toast } from "react-toastify";
import { set } from "lodash";
import { toArabicDigits } from "../../utils/formatDayArabic";
import { ClassContext } from "../dashboard/UnstructuredContext";
export interface ParticipantsInterface {
  profilePicture: string;
  username: string;
  email: string;
  role: string;
  sourceId: string;
}
const Participants = (): JSX.Element => {
  const [participantData, setParticipantData] = useState<
    ParticipantsInterface[]
  >([]);
  const [ParticipantsStudentsData, setParticipantsStudentsData] = useState<
    ParticipantsInterface[]
  >([]);
  const [ParticipantsInstructorsData, setParticipantsInstructorsData] =
    useState<ParticipantsInterface[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const image = require("../../assets/userProfile.png");
  const params = useParams();
  const classId = params.classId;
  const tenantName = localStorage.getItem("tenant");
  const { t } = useTranslation();
  const { themeTextColor } = useRecoilValue(ColorSelector);
  const [showAuditorsPopup, setShowAuditorsPopup] = useState<boolean>(false);
  const [auditorsData, setAuditorsData] = useState<any[]>([]);
  const [auditors, setAuditors] = useState<any[]>([]);
  const { role } = useContext(ClassContext);

  // Fetch Participants Data from the database
  const fetchParticipantData = async () => {
    try {
      const ParticipantData = await get(
        `participant/class/${classId}/${tenantName}`
      );
      setDataLoading(false);

      // Filter participants based on role
      if (ParticipantData.data.length > 0) {
        setParticipantData(ParticipantData.data);
        const students = ParticipantData.data.filter(
          (participant: ParticipantsInterface) => participant.role === "Student"
        );
        const instructors = ParticipantData.data.filter(
          (participant: ParticipantsInterface) => participant.role === "Teacher"
        );
        // set participants to students and instructor arrays
        setParticipantsStudentsData(students);
        setParticipantsInstructorsData(instructors);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //Call the fecthNotesData function when the component mount
  useEffect(() => {
    fetchParticipantData();
  }, []);

  const handleAddAuditor = () => {
    setShowAuditorsPopup(true);
    setAuditorsData([]);
  };

  const splitResponse = (responseMessage: string) => {
    const [firstPart, secondPart] = responseMessage
      .split(":")
      .map((str: string) => str.trim());
    return `${t(`participants.${firstPart}`)}: ${secondPart}`;
  };

  const handleSaveAuditors = async () => {
    try {
      const response = await axios.post(
        `https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/classAuditor/auditors`,
        auditorsData
      );
      response.data.data.includes("successfully")
        ? toast.success(t(`participants.${response.data.data}`))
        : response.data.data.includes("These users could not be added")
        ? toast.info(splitResponse(response.data.data))
        : toast.info(t(`participants.${response.data.data}`));
      fetchAuditors();
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAuditors = async () => {
    try {
      const response = await axios.get(
        `https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/classAuditor/auditors/${tenantName}/${classId}`
      );
      setAuditors(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteAuditor = async (id: number) => {
    try {
      const response = await axios.delete(
        `https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/classAuditor/auditors/delete/${tenantName}/${id}`
      );
      toast.success(t(`participants.${response.data.data}`));
    } catch (err) {
      console.log(err);
    }
    fetchAuditors();
  };

  useEffect(() => {
    fetchAuditors();
  }, []);

  if (dataLoading) {
    // if data is Loading
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SvgNoResult width={"10rem"} />
          <Typography text={`${t("general.loading")}...`} />
        </div>
      </>
    );
  }

  return (
    <>
      {/* The Intsructors is Mapping Here */}
      <InstructorsWrapper>
        <FlexBetween>
          <Typography
            text={t("participants.Instructors")}
            fontSize="1.75rem"
            fontWeight="400"
            lineHeight="normal"
          />
          {role === "teacher" ? (
            <ButtonIconAndText
              fontSize="0.75rem"
              text={t("participants.Add Auditor")}
              iconPath={AddSqIcon}
              onClick={handleAddAuditor}
              color={themeTextColor}
            />
          ) : ("")}
        </FlexBetween>
        <InstructorsRow>
          {ParticipantsInstructorsData.map((participants, index) => (
            <ParticipantCard
              key={index}
              name={participants.username}
              email={participants.email}
              imgPath={
                participants.profilePicture
                  ? participants.profilePicture
                  : image
              }
            />
          ))}
        </InstructorsRow>
      </InstructorsWrapper>

      {auditors.length > 0 ? (
        <AuditorWrapper>
          <TextHolder>
            <Typography
              text={t("participants.Auditors")}
              fontSize="1.75rem"
              fontWeight="400"
              lineHeight="normal"
            />
            <Typography
              text={`(${toArabicDigits(auditors.length.toString())} ${t(
                "participants.Auditors"
              ).toLowerCase()})`}
              fontSize="0.875rem"
              fontWeight="400"
              lineHeight="normal"
              color="#5DD3B3"
            />
          </TextHolder>
          <StudentsRow>
            {auditors.map((participants, index) => (
              <ParticipantCard
                key={index}
                name={participants.username}
                email={participants.email}
                sourceId={participants.sourceId}
                imgPath={
                  participants.profilePicture
                    ? participants.profilePicture
                    : image
                }
                isAuditor={true}
                auditorId={participants.auditorId}
                handleDeleteAuditor={handleDeleteAuditor}
              />
            ))}
          </StudentsRow>
        </AuditorWrapper>
      ) : (
        ""
      )}

      {/* The Students is Mapping Here */}
      <StudentsWrapper>
        <TextHolder>
          <Typography
            text={t("participants.Students")}
            fontSize="1.75rem"
            fontWeight="400"
            lineHeight="normal"
          />
          <Typography
            text={`(${toArabicDigits(
              ParticipantsStudentsData.length.toString()
            )} ${t("participants.Students").toLowerCase()})`}
            fontSize="0.875rem"
            fontWeight="400"
            lineHeight="normal"
            color="#5DD3B3"
          />
        </TextHolder>
        <StudentsRow>
          {ParticipantsStudentsData.map((participants, index) => (
            <ParticipantCard
              key={index}
              name={participants.username}
              email={participants.email}
              sourceId={participants.sourceId}
              imgPath={
                participants.profilePicture
                  ? participants.profilePicture
                  : image
              }
            />
          ))}
        </StudentsRow>
      </StudentsWrapper>
      {showAuditorsPopup && (
        <PopUp
          content={<AddAuditors setAuditorsData={setAuditorsData} />}
          title={t("participants.Add Auditor")}
          icon={<AddSqIcon />}
          show={showAuditorsPopup}
          setShow={setShowAuditorsPopup}
          showline={false}
          buttonName={t("forms.Save")}
          justifyContent="flex-end"
          handleSubmit={handleSaveAuditors}
        />
      )}
    </>
  );
};

export default Participants;
