import React, { useEffect, useState } from "react";
import TextElement from "../../elements/textElement/TextElement";
import {
  AnnouncementText,
  NavigationHolder,
  AnnouncementFooter,
  AnnouncementFoot,
  Announcer,
  AnnouncementDetails,
  InnerAnnouncementCard,
  AnnouncementCardHolder,
  AnnouncementDesc,
  ButtonsDiv,
  ButtonDiv,
} from "./AnnouncementCard.style";
import DateHolder from "../../elements/DateHolder/DateHolder";
import {
  LightTypography,
  TypographyRegularDescription,
} from "../../elements/fonts/Fonts";
import { SvgNextIcon } from "../../elements/Icons";
// import { AnnouncementsData } from "./AnnouncementData";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0ProviderSplitter, auth0SubIdSplitter } from "../../utils/StringManipulation";
import { get } from "../../utils/AxiosRequests";
import DOMPurify from "dompurify";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  DashBoardColorSelector,
} from "../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../utils/FormatDate";
import axios from "axios";
import { toArabicDigits } from "../../utils/formatDayArabic";

export default function AnnouncementCard() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [AnnouncementsData, setAnnouncementsData] = useState<any[]>([]);
  const { user } = useAuth0();
  const { AnnouncementCardBgColor, AnnouncementColor } = useRecoilValue(
    DashBoardColorSelector
  );
  const tenantName = localStorage.getItem("tenant");
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const language = localStorage.getItem("language")

  const { backgroundSpecial, specialColor } = useRecoilValue(ColorSelector);

  const fetchAnnounecmenttData = async () => {
    try {
      setIsLoading(true);
      const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
      const provider = auth0ProviderSplitter(user?.sub || "");
      const announcmentData = await axios.get(
        `https://yhntwosoqe.execute-api.eu-west-1.amazonaws.com/dev/announcement/all/${subIdSplitted}/${tenantName}?provider=${provider}`
      );

      // Sort announcements by createdAt in descending order
      const sortedAnnouncements = announcmentData.data.data.sort(
        (a: any, b: any) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        }
      );

      setAnnouncementsData(sortedAnnouncements);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  //Call the fecthNotesData function when the component mount
  useEffect(() => {
    fetchAnnounecmenttData();
  }, []);

  // const formatDate = (createdDate: string) => {
  //   const currentDate = new Date();
  //   const announcementDate = new Date(createdDate);
  //   const timeDifference = currentDate.getTime() - announcementDate.getTime();
  //   const minutesDifference = Math.floor(timeDifference / (1000 * 60));

  //   if (minutesDifference < 1) {
  //     return "Now";
  //   } else if (minutesDifference < 60) {
  //     return `${minutesDifference} minute${minutesDifference > 1 ? "s" : ""
  //       } ago`;
  //   } else if (minutesDifference < 1440) {
  //     // 1440 minutes in a day (24 hours)
  //     const hoursDifference = Math.floor(minutesDifference / 60);
  //     return `${hoursDifference} hour${hoursDifference > 1 ? "s" : ""} ago`;
  //   } else if (minutesDifference < 2880) {
  //     // 2880 minutes in two days
  //     return `Yesterday at ${announcementDate.toLocaleTimeString("en-US", {
  //       hour: "2-digit",
  //       minute: "2-digit",
  //     })}`;
  //   } else {
  //     return announcementDate.toLocaleString();
  //   }
  // };

  const decodeHtml = (value: any) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = value;
    return textarea.value;
  };

  // Check if there are no announcements
  if (AnnouncementsData.length === 0) {
    return (
      <AnnouncementCardHolder>
        <InnerAnnouncementCard
          bgcolor={AnnouncementCardBgColor}
          style={{ color: AnnouncementColor }}
        >
          <AnnouncementText>
            <TypographyRegularDescription
              fontSize="1rem"
              fontWeight="400"
              text={
                isLoading
                  ? `${t("general.loading")}...`
                  : t("view all.No announcements available")
              }
              // color="#fff"
            />
          </AnnouncementText>
        </InnerAnnouncementCard>
      </AnnouncementCardHolder>
    );
  }

  const AnnouncementData = AnnouncementsData[currentIndex];

  const goToAnnouncement = (index: number) => {
    setCurrentIndex(index);
  };

  const goToNextAnnouncement = () => {
    if (currentIndex < AnnouncementsData.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const goToPreviousAnnouncement = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const isAtFirstAnnouncement = currentIndex === 0;
  const isAtLastAnnouncement = currentIndex === AnnouncementsData.length - 1;

  return (
    <AnnouncementCardHolder>
      <InnerAnnouncementCard bgcolor={AnnouncementCardBgColor}>
        <AnnouncementText>
          <TypographyRegularDescription
            fontSize="1rem"
            fontWeight="400"
            text={AnnouncementData.title}
            color={specialColor}
          />
        </AnnouncementText>
        <AnnouncementDesc>
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(decodeHtml(AnnouncementData.content)),
            }}
            style={{
              fontSize: "0.875rem",
              fontWeight: "400",
              fontFamily: "Primary Font-Light",
            }}
          ></p>
          {/* <LightTypography
            tag="p"
            text={DOMPurify.sanitize(decodeHtml(AnnouncementData.content))}
            fontSize="0.875rem"
            fontWeight="400"
          /> */}
        </AnnouncementDesc>
        <AnnouncementFoot>
          <AnnouncementDetails>
            <Announcer>
              <img src="assets/icons/Profile - Icon 1.svg" alt="" />
              <TextElement
                tag="p"
                text={AnnouncementData.teacher.username || ""}
                fontSize="0.75rem"
                fontFamily="Primary Font"
                fontWeight="400"
              />
            </Announcer>
            <DateHolder date={formatDate(AnnouncementData.createdAt, t)} />
          </AnnouncementDetails>
          <AnnouncementFooter>
            <NavigationHolder>
              <LightTypography
                fontSize="0.625rem"
                fontWeight="400"
                text={`${toArabicDigits((currentIndex + 1).toString())} / ${toArabicDigits(AnnouncementsData.length.toString())}`}
              />
              <ButtonsDiv>
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    transform: language === "ar" ? "none" : "rotate(180deg)",
                    cursor: "pointer",
                    color: isAtFirstAnnouncement ? "gray" : "white",
                    marginTop: language === "ar" ? "0.3rem" : "-0.2rem",
                  }}
                  onClick={goToPreviousAnnouncement}
                  disabled={isAtFirstAnnouncement}
                >
                  <SvgNextIcon width={"1rem"} />
                </button>
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    transform: language === "ar" ? "rotate(180deg)" : "none",
                    cursor: "pointer",
                    marginTop: language === "ar" ? "-0.2rem" : "0.3rem",
                    color: isAtLastAnnouncement ? "gray" : "white",
                  }}
                  onClick={goToNextAnnouncement}
                  disabled={isAtLastAnnouncement}
                >
                  <SvgNextIcon width={"1rem"} />
                </button>
              </ButtonsDiv>
            </NavigationHolder>
            {/* <ButtonElement text="Set A Reminder" iconPath={SvgAlarmIcon} clickEvent={}/> */}
          </AnnouncementFooter>
        </AnnouncementFoot>
      </InnerAnnouncementCard>
    </AnnouncementCardHolder>
  );
}
