// SubmitButton.tsx
import styled from "styled-components";

interface SubmitButtomEditProfileProps {
  widths?: string;
  heights?: string;
  color?: string;
  background?: string;
  border?: string;
  fontFamily?: string;
  value?: string; 
  
}

export const SubmitButtomEditProfile = styled.input<SubmitButtomEditProfileProps>`
  background: ${({ background }) => background || "#5DD3B3"};
  border : ${({ background }) => background || "none"};
  border-radius: 1.5625rem;
  width: ${({ widths }) => widths || "5.5rem"};
  height: ${({ heights }) => heights || " 1.9rem"};
  color: ${({ color }) => color || "#fff"};
  border: 0.1rem solid ${({ border }) => border || "#5DD3B3"};
  text-align: center;
  font-family: ${({ fontFamily }) => fontFamily || "Primary Font-Bold"};
  font-weight: 400;
  cursor: pointer;
  /* Use the value prop as the button's content */
  /* value: ${({ value }) => value || "Submit"}; */
`;
