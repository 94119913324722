import styled from "styled-components";
import { breakpoints } from "../../../theme";
import { StyleThemeInterface } from "../../../interfaces/StyleThemeInterface";

interface ContainerProps {
  minheight?: string;
  inputbordercolor?: string;
}

export const Container = styled.div<ContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  border-width: 2px;
  border-radius: 0.63rem;
  border-color: ${(props) => props.inputbordercolor};
  border-style: dashed;
  background-color: transparent;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  min-height: ${(props) => props.minheight};
  /* margin-top: 0.62rem; */
  cursor: grab;
  flex-wrap: wrap;

  /* justify-content: center; */
  @media (max-width: calc(${breakpoints.mobile} +25px )) {
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0;
`;
export const SelectedFileDiv = styled.div<StyleThemeInterface>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.3125rem;
  border: 1px solid ${props => props.bordercolor};
  padding: 0.8rem;
  /* padding-right: 3rem; */
  width: 13.5rem;
  flex-wrap: wrap;
`;

export const FileNameDiv = styled.div`
  width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
