import React, { FC } from "react";
import {
  Container,
  StatHeader,
  QuestionsContainer,
  QuestionsNumber,
  OptionContainer,
  OptionsItemsOne,
  OptionsItemsTwo,
  VerticalLine,
} from "./Statistics.style";
import { StatisticsProps } from "../StatisticsInterface";
import { Typography } from "../../../elements/fonts/Fonts";
import { theme } from "../../../theme";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector, courseCreatorColorSelector } from "../../../recoil/ThemeSelectors";

const Statistics: FC<StatisticsProps> = ({
  questions,
  multipleChoice,
  shortAnswer,
  numerical,
  essay,
  grade,
  matching,
  trueFalse,
  totalGrade,
}) => {
  const { t } = useTranslation();
  const { bghelp, themeTextColor, SecondaryTextColor } =
    useRecoilValue(ColorSelector);
    const { lineIconColor } = useRecoilValue(courseCreatorColorSelector);
    const language = localStorage.getItem("language")
  return (
    <Container>
      <StatHeader>
        <Typography
          text={t("quiz.SUMMARY")}
          fontSize="1.75rem"
          color={themeTextColor}
        />
      </StatHeader>
      <QuestionsContainer style={{ textTransform: "uppercase" }}>
        <QuestionsNumber>{questions}</QuestionsNumber>
        <Typography
          text={t("quiz.Questions")}
          fontSize="0.75rem"
          color={SecondaryTextColor}
        />
      </QuestionsContainer>

      <OptionsItemsOne>
        <OptionContainer>
          <Typography
            text={t("quiz.MULTIPLE CHOICE").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
          <Typography
            text={`${multipleChoice}`}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
        </OptionContainer>
        <OptionContainer>
          <Typography
            text={`${t("quiz.TRUE").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")} - ${t("quiz.FALSE").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}`}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
          <Typography text={`${trueFalse}`} fontSize="0.625rem" />
        </OptionContainer>
        <OptionContainer>
          <Typography
            text={t("quiz.SHORT ANSWER").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
          <Typography
            text={`${shortAnswer}`}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
        </OptionContainer>
      </OptionsItemsOne>

      <OptionsItemsTwo>
        <OptionContainer>
          <Typography
            text={t("quiz.NUMERICAL").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
          <Typography
            text={`${numerical}`}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
        </OptionContainer>
        <OptionContainer>
          <Typography
            text={t("quiz.ESSAY").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
          <Typography
            text={`${essay}`}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
        </OptionContainer>
        <OptionContainer>
          <Typography
            text={t("quiz.Matching").toLocaleUpperCase(language === "tr" ? "tr-TR" : "us-US")}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
          <Typography
            text={`${matching}`}
            fontSize="0.625rem"
            color={SecondaryTextColor}
          />
        </OptionContainer>
      </OptionsItemsTwo>

      <VerticalLine bordercolor={lineIconColor} />
      <QuestionsContainer>
        <QuestionsNumber>{totalGrade}</QuestionsNumber>
        <Typography
          color={themeTextColor}
          text={t("quiz.GRADE")}
          fontSize="0.75rem"
        />
      </QuestionsContainer>
    </Container>
  );
};

export default Statistics;
