import React, { FC, useState, useEffect } from "react";
import {
  CustomCheckboxWrapper,
  CustomCheckboxInput,
  CustomCheckbox,
  MultileQuestionContainer,
  CheckBox,
  MultipleInputs,
  BranchName,
} from "./MultipleQuestion.style";
import { MultipleQuestionProps } from "./MultipleQuestionInterface";
import { ReactComponent as CheckIcon } from "../../../assets/icons/Group2199.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete 1 copy.svg";
import { ErrorDiv } from "../../../elements/ErrorDiv/Errordiv.style";
import DropQuestion from "../../SurveyBranchCreatorDropQuestion/DropQuestion";
import QuestionCRUDTextEditor from "../QuestionCRUDTextEditor/QuestionCRUDTextEditorForBranching";
import SurveyRating from "../../surveyRating/SurveyRating";
import { SurveyLikertScale } from "../../../elements/SurveyCreatorLikertScale/SurveyLikertScale";
import EssayQuestion from "../EssayQuestion/EssayQuestion";
import TrueFalseQuestion from "../TrueFalseQuestion/TrueFalseQuestion";
import { Choice } from "../../generateSurvey/generateSurveyDataInterface";
import MultipleQuestionForBranching from "./MultipleQuestionForBranching";

import {
  QuestionIconsContainer,
  QuestionHeaderContainer,
} from "../../SurveyCreatorQuestionCRUD/QuestionCRUDTextEditor/QuestionCRUDTextEditor.style";
import { theme } from "../../../theme";
import { MediumTypography } from "../../../elements/fonts/Fonts";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";
import { useTranslation } from "react-i18next";
import { mapErrorType } from "../../../utils/QuizErrorTypes";

const MultipleQuestion: FC<MultipleQuestionProps> = ({
  questionError,
  changeBranchName,
  showQuest,
  qindex,
  choice,
  handleChoiceMatchingQuestion,
  handleChoiceChecked,
  allChoices,
  setChoiceIndex,
  setDeleteChoice,
  quest,
  FillBranch,
  questions,
  index,
  partIndex,
  handleChoiceMultipleQuestionForBranching,
  handleQIndexFromChild,
  emptyBranch,
}) => {
  const [isChecked, setIsChecked] = useState(choice?.isChecked);
  const [countChecked, setCountChecked] = useState(1);
  const [errorShown, setErrorShown] = useState(false);
  const [isBranched, setIsBranched] = useState<any>(false);
  const [branchType, setBranchType] = useState("");
  const [branchChoiceTypes, setBranchChoiceTypes] = useState<
    Record<number, string>
  >({});
  const [branchChoices, setBranchChoices] = useState<Choice[]>([]);
  const {t} = useTranslation()

  const handleSingleBranchChoice = () => {
    const safeIndex = qindex ?? 0;
    // Ensure allChoices exists and has valid data before accessing its properties
    if (allChoices && allChoices[safeIndex]?.branch?.choices) {
      setBranchChoices(allChoices[safeIndex]?.branch?.choices || []);
    }
  };

  useEffect(() => {
    handleSingleBranchChoice();
    handleQIndexChange(qindex || 0);
  }, [questions, index, partIndex, branchChoiceTypes]);

  const handleQIndexChange = (qindex: number) => {
    // Call the function received from props and pass the qindex value
    handleQIndexFromChild && handleQIndexFromChild(qindex);
  };

  useEffect(() => {
    setIsBranched(quest?.branching);
  }, [quest?.branching]);

  // const handleCheckboxChange = () => {
  //     const newValue = !isChecked;
  //     setIsChecked(newValue);
  //     handleChoiceChecked && handleChoiceChecked(qindex || 0, newValue);
  // };

  useEffect(() => {
    if (allChoices)
      setCountChecked(
        allChoices.filter((choice) => choice.isChecked === true).length
      );
    setErrorShown(false); // Reset errorShown when allChoices changes
  }, [allChoices]);

  const handleBranchTypeChange = (type: any) => {
    const updatedBranchChoiceTypes = { ...branchChoiceTypes };
    updatedBranchChoiceTypes[qindex || 0] = type;
    setBranchChoiceTypes(updatedBranchChoiceTypes);
  };

  useEffect(() => {
    if (allChoices) {
      const currentBranchChoiceType =
        allChoices[qindex || 0]?.branch?.type || "";
      const updatedBranchChoiceTypes = { ...branchChoiceTypes };
      updatedBranchChoiceTypes[qindex || 0] = currentBranchChoiceType;
      setBranchChoiceTypes(updatedBranchChoiceTypes);
    }
  }, [allChoices]);
  const {
    themeTextColor,
    readOnlyBgColor,
    inputBorderColor, theme
  } = useRecoilValue(ColorSelector);
  const errorType = mapErrorType(questionError || "", t);
  return (
    <>
      <MultileQuestionContainer>
        {/* {!showQuest ?

                    countChecked <= 1 ?
                        <CheckBox type='radio' checked={isChecked} onChange={handleCheckboxChange} />
                        : countChecked > 1 ?
                            <CustomCheckboxWrapper>
                                <CustomCheckboxInput type="checkbox" />
                                {isChecked ? (
                                    <CheckIcon id="isChecked" onClick={handleCheckboxChange} />
                                ) : (
                                    <CustomCheckbox id="isChecked" onClick={handleCheckboxChange} />
                                )}
                            </CustomCheckboxWrapper> : ''
                    : ''
                } */}
        <MultipleInputs
          center="no"
          value={choice?.name}
          show={showQuest?.toString()}
          type="text"
          width="100%"
          color={themeTextColor}
          placeholder={t('quiz.write answer')}
          readOnly={showQuest ? true : false}
          onChange={(e) =>
            handleChoiceMatchingQuestion &&
            handleChoiceMatchingQuestion(qindex || 0, e)
          }
          id="name"
          hasError={
            (qindex === 0 || qindex === 1) && !!questionError && !choice?.name
          }
          bgColor={readOnlyBgColor}
          borderColor={inputBorderColor}
        />
        {/* <MultipleInputs center='yes' value={choice.percentage} show={showQuest.toString()} type='number' width='10%' placeholder='%'
                    readOnly={showQuest ? true : false}
                    onChange={(e) => handleChoiceMatchingQuestion(qindex, e)}
                    id='percentage'
                    hasError={(qindex === 0 || qindex === 1) && !!questionError && !choice.percentage}
                /> */}
        {allChoices && allChoices.length > 2 ? (
          <DeleteIcon
            onClick={() => {
              setDeleteChoice && setDeleteChoice(true);
              setChoiceIndex && setChoiceIndex(qindex || 0);
            }}
            style={{ cursor: "pointer" }}
            color={theme === "light" ? "#D85D66" : "#fff"}
          />
        ) : (
          ""
        )}
      </MultileQuestionContainer>
      {questionError && !errorShown && qindex === 1 && (
        <ErrorDiv style={{ marginLeft: "1.5rem" }}>{errorType}</ErrorDiv>
      )}
      {isBranched && qindex === 0 && (
        <QuestionHeaderContainer>
          <QuestionIconsContainer>
            <MediumTypography
              text="Create the set of questions when student chooses this answer"
              color={themeTextColor}
            />
          </QuestionIconsContainer>
        </QuestionHeaderContainer>
      )}

      {isBranched && !branchChoiceTypes[qindex || 0] && (
        <DropQuestion
          qindex={qindex}
          FillBranch={FillBranch}
          OnBranchTypeChange={handleBranchTypeChange}
        />
      )}
      {isBranched && branchChoiceTypes[qindex || 0] && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginRight: "2rem",
              alignItems: "center",
            }}
          >
            <BranchName style={{ marginLeft: "2rem", marginTop: "1rem" }}>
              Branch: {(qindex && qindex + 1) || 1}
            </BranchName>
            {!showQuest && (
              <DeleteIcon
                style={{ cursor: "pointer" }}
                onClick={() => emptyBranch && emptyBranch(qindex || 0)}
              >
                Delete
              </DeleteIcon>
            )}
          </div>
          <div style={{ padding: "2rem", marginTop: "-1.5rem" }}>
            <QuestionCRUDTextEditor
              questionError={questionError}
              qindex={qindex}
              allChoices={allChoices}
              showQuest={showQuest}
              quest={quest}
              changeBranchName={changeBranchName}
            />
          </div>
        </>
      )}
      {isBranched &&
        branchChoiceTypes[qindex || 0] &&
        branchChoiceTypes[qindex || 0] === "Multiple choice" &&
        branchChoices.map((branchchoice, branchChoiceIndex) => (
          <div style={{ padding: "2rem", marginTop: "-3rem" }}>
            <MultipleQuestionForBranching
              key={branchChoiceIndex}
              showQuest={showQuest}
              branchchoice={branchchoice}
              handleChoiceMultipleQuestionForBranching={
                handleChoiceMultipleQuestionForBranching
              }
              qindex={qindex}
              branchChoiceIndex={branchChoiceIndex}
            />
          </div>
        ))}
      {isBranched &&
        branchChoiceTypes[qindex || 0] &&
        branchChoiceTypes[qindex || 0] === "Yes-No" && (
          <div style={{ padding: "2rem", marginTop: "-3rem" }}>
            <TrueFalseQuestion />
          </div>
        )}
      {isBranched &&
        branchChoiceTypes[qindex || 0] &&
        branchChoiceTypes[qindex || 0] === "Rating" && (
          <div style={{ padding: "2rem", marginTop: "-3rem" }}>
            <SurveyRating />
          </div>
        )}
      {isBranched &&
        branchChoiceTypes[qindex || 0] &&
        branchChoiceTypes[qindex || 0] === "Scale" && (
          <div style={{ padding: "2rem", marginTop: "-3rem" }}>
            <SurveyLikertScale />
          </div>
        )}
      {isBranched &&
        branchChoiceTypes[qindex || 0] &&
        branchChoiceTypes[qindex || 0] === "Essay" && <EssayQuestion />}
    </>
  );
};

export default MultipleQuestion;
