import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import YouTube, { YouTubeProps } from "react-youtube";
import { post } from "../../utils/AxiosRequests";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SubIdSplitter,auth0ProviderSplitter } from "../../utils/StringManipulation";
import { useTranslation } from "react-i18next";
import { ClassContext } from "../../Views/dashboard/UnstructuredContext";
interface YoutubeViewerProps {
  url: string;
  linkId: string;
  classId: string;
}

const extractVideoIdFromUrl = (url: string) => {
  const match = url.match(
    /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^&]+)/i
  );
  return match ? match[1] : null;
};

export default function YoutubeViewer({ url, linkId, classId }: YoutubeViewerProps) {
  const { progressChanged, setProgressChange } = useContext(ClassContext)

  const { t } = useTranslation();
  const tenantName = localStorage.getItem("tenant");
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [percentage, setPercentage] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);

  const playerRef = useRef<any>(null); // Ref for the YouTube player instance
  const progressRef = useRef(percentage); // Initialize a ref with the current progress value
  progressRef.current = percentage;
  const { user } = useAuth0();
  const subIdSplitted = auth0SubIdSplitter(user?.sub || "");
  const provider = auth0ProviderSplitter(user?.sub || "");
  useEffect(() => {
    setPercentage(0);
    setDuration(0);
    setCurrentTime(0);
    const fetchDocument = async () => {
      try {
        const response = await axios.get(
          `https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/resource/youtube/${linkId}/${tenantName}`
        );
        const response2 = await axios.get(`https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/completionTracking/get/${Number(linkId)}/Youtube/${subIdSplitted}/${tenantName}?provider=${provider}`)

        if (response2.data.data[0] && response2.data.data[0].length) {

          setCurrentTime(Number(response2.data.data[0].length) * response2.data.data[0].percentage / 100);
          setPercentage(response2.data.data[0].percentage)
          setDuration(response2.data.data[0].length);
        }
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching document:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchDocument();
  }, [linkId]);

  useEffect(() => {
    const interval = setInterval(() => {
      const createTrack = async () => {
        try {
          const tenantName = localStorage.getItem("tenant");
          // setCurrentTime(playerRef.current.getCurrentTime());
          let int = playerRef.current;
          const updateProgressRef = async () => {
            const response = await axios.get(`https://0h9rj7bgt8.execute-api.eu-west-1.amazonaws.com/dev/completionTracking/get/${Number(linkId)}/Youtube/${subIdSplitted}/${tenantName}?provider=${provider}`)
            // console.log("response before tracking percentage", response.data.data[0].percentage)

            if (response && response.data.data && response.data.data[0]) {
              if (response.data.data[0].percentage === undefined || response.data.data[0].percentage === null) {
                int = 0;
              }
              else {
                int = response.data.data[0].percentage
              }
            }
            else {
              int = 0;
            }
            const currentPercentage = (playerRef.current.getCurrentTime() * 100 / playerRef.current.getDuration()).toFixed(3);

            // setProgressChange((prev) => !prev);
            if (parseFloat(currentPercentage) > parseFloat(int.toFixed(3)) || int === 0) {
              setProgressChange((prev) => !prev);
              const trackingData = {
                classSourceId: classId,
                length: playerRef.current.getDuration().toString(),
                percentage: playerRef.current.getCurrentTime() * 100 / playerRef.current.getDuration() < percentage ? percentage : playerRef.current.getCurrentTime() * 100 / playerRef.current.getDuration(),
                refId: linkId,
                type: "Youtube",
                subId: subIdSplitted,
              };
              const trackingResponse = await post(`completionTracking/create/${tenantName}`, trackingData)
              setProgressChange((prev) => !prev);
            }
          }

          updateProgressRef();
        }
        catch (error) {
          console.error("Error fetching document:", error);
        }
      };
      createTrack();
    }, 10000);

    return () => clearInterval(interval);

  }, [linkId]);

  return (
    <>
      {loading ? (
        <div>{`${t('general.loading')}...`} </div>
      ) : (
        <div style={{ width: "100%", height: "100%" }}>
          {data && data.url && (
            <YouTube

              videoId={extractVideoIdFromUrl(data.url) || undefined}
              opts={{
                width: "100%", height: "600", playerVars: {
                  start: currentTime
                }
              }}
              onReady={(event: { target: { getDuration: () => React.SetStateAction<number>; seekTo: (arg0: number) => void; pauseVideo: () => void; }; }) => {
                playerRef.current = event.target;
                setDuration(event.target.getDuration());
                const startTimeInSeconds = currentTime;
                event.target.seekTo(startTimeInSeconds);
                event.target.pauseVideo();
              }}
              onStateChange={(event: any) => {
                // setCurrentTime(event.target.getCurrentTime());
              }}
            />
          )}
        </div>
      )}
    </>
  );
}
