import React, { useEffect } from "react";
import LandingPageStatic from "../../components/landingPageStatic/LandingPageStatic";
import {
  LanginUniCol,
  LanginUniTanentsBox,
  LanginUniTanentsButton,
  LanginUniTanentsGroups,
  LanginUniTanentsImg,
} from "./LandingPageUni.style";
import { TypographyRegularDescription } from "../../elements/fonts/Fonts";
import { LanginGTantetsData } from "./LanginPageUniData";
import BlueButton from "../../elements/blueButton/BlueButton";
import { Link, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { LoadingComp } from "../LoadingComp/LoadingComp";
import { useNavigate } from "react-router-dom";
import { auth0ProviderSplitter } from "../../utils/StringManipulation";

const LandingPageUni = () => {
  const basURL =
    "https://6g25zsbnm2.execute-api.eu-west-1.amazonaws.com/dev/user/tenants/";
  const { user } = useAuth0();
  const provider = auth0ProviderSplitter(user?.sub || "");
  const navigate = useNavigate();

  const [tenants, setTenants] = React.useState<string[]>([]);

  const getUserTenants = async (subId: string) => {
    const response = provider === "waad" ? await axios.get(`${basURL}${subId}?azureUser=true`):await axios.get(`${basURL}${subId}`);
    setTenants(response.data.data);
  };

  const setTenantInSession = (tenant: string) => {
    // sessionStorage.setItem('tenant', tenant);
    localStorage.setItem("tenant", tenant);
    navigate("/dashboard");
  };

  useEffect(() => {
    if (user?.sub) {
      const authSub = user.sub;
      const authSubSplit = authSub.split("|");
      const subId = authSubSplit[1];
      getUserTenants(`${subId}`);
    }
  }, [user?.sub]);

  return (
    <LandingPageStatic>
      <LanginUniCol>
        <TypographyRegularDescription
          text="Select Your Tenant"
          fontSize="0.875rem"
          lineHeight="normal"
          fontWeight="400"
          color="#576474"
        />
        <LanginUniTanentsGroups>
          {tenants ? (
            tenants.map((t: string, index) => (
              <LanginUniTanentsBox
                key={index}
                onClick={() => setTenantInSession(t)}
              >
                {/* <img src={tenant.TanentImg} /> */}
                <TypographyRegularDescription
                  text={t}
                  fontSize="0.875rem"
                  lineHeight="normal"
                  fontWeight="400"
                />
              </LanginUniTanentsBox>
            ))
          ) : (
            <LoadingComp />
          )}
          {/* <LanginUniTanentsButton>
            <Link to="/dashboard">
              <BlueButton text="Continue" />
            </Link>
          </LanginUniTanentsButton> */}
        </LanginUniTanentsGroups>
      </LanginUniCol>
    </LandingPageStatic>
  );
};

export default LandingPageUni;
