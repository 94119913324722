import axios from "axios";
import TeamsIntegration from "../../components/teamsPage/teamsIntegration";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MediumTypography } from "../../elements/fonts/Fonts";

const TeamsView = () => {
  const [teams, setTeams] = useState<any[]>([]);
  const { classId } = useParams();
  const tenantName = localStorage.getItem("tenant");

  
  const FetchTeamIds = async () => {
    try {
      const response = await axios.get(
        `https://kzj7vl7f1i.execute-api.eu-west-1.amazonaws.com/dev/class/teamsId/${classId}/${tenantName}`
      );
      setTeams(response.data.data);
      console.log(response.data.data)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchTeamIds();
  }, []);

  return (
    <>
      {teams.map((teamId) => (
        <>
        <MediumTypography text={teamId.classTitle}/>
        <TeamsIntegration key={teamId} teamId={teamId} />
        </>
      ))}
    </>
  );
};

export default TeamsView;
