import React from "react";
import { Logo } from "../../../elements/logo/Logo";
import { SubMenu } from "../SidebarMenu/SubMenu";
import { Footer } from "../sidebarfooter/Footer";
import { SvgDotsIcon } from "../../../elements/Icons";
import { useStateContext } from "../../../contexts/ContextProvider";
import {
  Rectangle,
  FooterStyle,
  Footerdots,
  Foot,
  ZidyiaLogoStyle,
  DividLine,
  DividLineHolder,
} from "./Sidebar.style";
import { ZidyiaLogo } from "../../../elements/zidyiaLogo/ZidyiaLogo";
import { useAuth0 } from "@auth0/auth0-react";
import { useRecoilValue } from "recoil";
import {
  ColorSelector,
  courseCreatorColorSelector,
} from "../../../recoil/ThemeSelectors";

interface SidebarProps {
  // closeSidebar?: () => void;
  sidebarMini: boolean;
}
export const Sidebar = ({ sidebarMini }: SidebarProps): JSX.Element => {
  const { isAuthenticated } = useAuth0();
  const { contentType, pathNameState } = useStateContext();
  const { backgroundColor, overlayBgColor } = useRecoilValue(ColorSelector);
  const { boxShadow, theme } = useRecoilValue(courseCreatorColorSelector);
  const dir = document.dir;

  if (isAuthenticated) {
    return (
      <Rectangle
        style={{ backgroundColor: backgroundColor }}
        boxshadow={boxShadow}
        backgroundcolor={backgroundColor}
        contenttype={contentType}
        overlaybgcolor={overlayBgColor}
        theme={theme}
        includescoursecreator={(pathNameState.includes("course-creator")).toString()}
      >
        <Logo sidebarMini={sidebarMini} />
        <SubMenu sidebarMini={sidebarMini} />
        <Foot sidebarmini={sidebarMini.toString()}>
          <ZidyiaLogoStyle>
            <ZidyiaLogo />
          </ZidyiaLogoStyle>
          {/* <DividLineHolder>
            <DividLine />
          </DividLineHolder> */}
          {/* <FooterStyle Sidebarmini={sidebarMini}>
            <Footer />
          </FooterStyle> */}
          <Footerdots sidebarmini={sidebarMini.toString()}>
            <SvgDotsIcon />
          </Footerdots>
        </Foot>
      </Rectangle>
    );
  }
  return <div></div>;
};
