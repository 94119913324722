import styled from "styled-components";

import { breakpoints, theme } from "../../theme";
interface TableTdProps {
  color?: string;
}

export const TitleRowBeforeTable = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0rem 1.25rem;
  align-items: center;
  gap: 0.63rem;
`;
export const LogoAndAction = styled.div`
  display: flex;
  align-items: center;
  gap: 0.56rem;
  cursor: pointer;
`;
export const OverAllGradeRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.63rem;
  padding-right: 1.25rem;
`;
export const GradeAnalyticsWrapper = styled.div`
  width: 100%;
  height: fit-content;
  background: var(
    --background-card,
    linear-gradient(180deg, #415160 0%, #293440 100%)
  );
  padding-top: 1.25rem;
  margin: 0rem 0rem 1.25rem 0rem;
  width: 100%;
  border-radius: 1rem;
`;
export const TableSection = styled.div`
  width: 100%;
  display: flex;
  min-height: fit-content;
  overflow-x: hidden;
  margin-top: 1.25rem;
  position: relative;
  scroll-behavior: smooth;
  /* background-color: red; */
`;

export const TableContentHolder = styled.table`
  width: 100%;
  /* min-width: 800px; */
  border: none;
  /* background-color: yellow; */
`;

export const TableContent = styled.table`
  max-height: 100%;
  height: fit-content;
  /* width: 100%; */
  border-collapse: collapse;
  overflow-x: auto;
  white-space: nowrap;
  border: none;
  width: 100%;
  /* background-color: white; */
`;

export const TableThead = styled.thead`
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  align-items: center;
  /* gap: 6.5rem; */
  border: none;
  white-space: nowrap;
`;

export const TableTh = styled.th`
  display: flex;
  flex-direction: row;
  width: 48%;
  justify-content: flex-start;
  padding: 0 2.5rem 0rem 1rem;
  /* gap: 25.5rem; */
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
`;

export const TableTr = styled.tr`
  &:nth-child(even) {
    /* background: rgba(255, 255, 255, 0.03); */
    border: none;
  }
`;
export const TableTr1 = styled.tr`
  display: flex;
  /* justify-content: space-between; */
  white-space: nowrap;
  /* padding-left: 1.5rem; */
  /* padding-right: 50%; */
  align-items: center;
  /* min-width: 100vw; */
  height: 5.625rem;
  &:nth-child(even) {
    background: rgba(255, 255, 255, 0.03);
    border: none;
  }
`;

export const TableTbody = styled.tbody`
  border-collapse: collapse;
  border: none;
  white-space: nowrap;
`;

export const TableTd = styled.td<TableTdProps>`
  border: none;
  width: 48%;
  display: flex;
  justify-content: flex-start;
  height: 3.5625rem;
  border-collapse: collapse;
  padding: 1rem 2rem 0rem 5rem;
  text-align: center;
  /* max-width: 23.1rem; */
  color: ${({ color }) => color};
  &:first-child {
    padding: 1rem 2rem 0rem 1rem;
  }
  &:nth-child(2) {
    padding: 1rem 2rem 0rem 4rem;
  }
`;
export const TableTd1 = styled.td<TableTdProps>`
  border: none;
  height: 3.5625rem;
  border-collapse: collapse;
  padding: 0rem 2rem 0rem 0rem;
  text-align: center;
  /* min-width: 10.1rem; */
  color: ${({ color }) => color};
`;
// export const Input = styled.input`
//   border:none;
//   color: white;
//   font-weight:400;
//   width: 4.1875rem;
// height: 2.1875rem;
//   background: transparent;
//   border-radius: 0.625rem;
//   outline: none;
//   font-family: ${theme.font.primaryRegular};
//   font-size: 0.875rem;
//   text-align: center;
//   &:focus {
//     border: 1px solid #576371;

// box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
//   }
//   &::placeholder {
//     text-align: center;
//     color:white;

//   }
// `;
// export const ButtonsRow = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 0.62rem;
//   margin: 2.12rem 0rem;
// `;
// export const ButtonCancel = styled.button`
//   width: 13.4375rem;
//   height: 3.125rem;
//   border-radius: 6.25rem;
//   border: 1px solid #d85d66;
//   background: none;
//   cursor: pointer;
// `;
// export const ButtonAdd = styled.button`
//   outline: none;
//   border: none;
//   width: 13.4375rem;
//   height: 3.125rem;
//   border-radius: 6.25rem;
//   background: #5dd3b3;
//   cursor: pointer;
// `;
export const ProfileImageHolder = styled.img`
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* position: relative; */
`;
export const SearchAndLogosDiv = styled.div`
display: flex;
flex-direction: column;
gap: 1.81rem;
justify-content: flex-end;
/* align-items: flex-end; */
/* padding-right: 1.25rem; */
&>:first-child{
  align-content: flex-end;
}
`
export const TitleAndSearch = styled.div`
display: flex;
gap: 1.81rem;
justify-content: space-between;
/* align-items: flex-end; */
padding: 0rem 1.25rem;
&>:first-child{
  align-content: flex-end;
}
`
export const ActionsDiv = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
gap: 1.25rem;
align-self: end;
@media (max-width: ${breakpoints.small}) {
flex-wrap: wrap;
 }
`
