// AnswerCorrection.tsx
import React, { FC, useState, useEffect } from "react";
import {
  AnswerCorrectionAction,
  AnswerCorrectionContainer,
  AnswerCorrectionLayout,
  AnswerCorrectionLine,
  AnswerCorrectionMappedContainer,
  AnswerCorrectionObject,
} from "./AnswerCorrection.style";

import { LightTypography, Typography } from "../../../elements/fonts/Fonts";
import MatchesAfterSubmission from "../../../elements/MatchesAfterSubmission/MatchesAfterSubmission";
import RadioAfterSubmission from "./RadioAfterSubmitsson/RadioAfterSubmission";
import TextEditorAnswerReader from "./TextEditorAnswerReader/TextEditorAnswerReader";
import { AnswerCorrectionDataV2 } from "./AnswerCorrectionData";
import { handleCorrectQuestionSvg } from "../../../utils/Function";
import MCQAfterSubmission from "./RadioAfterSubmitsson/MCQAfterSubmission";
import CheckBoxAfterCorrection from "./CheckBoxAfterCorrection/CheckBoxAfterCorrection";
import { DisplayContentDisplay } from "../../PostsTimeLine/main/Function";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ColorSelector } from "../../../recoil/ThemeSelectors";

interface QuestionCommentProps {
  questions: any[];
  questionIndex: number;
  submission?: any[];
  studentIndex: number;
}

const AnswerCorrection: FC<QuestionCommentProps> = ({
  questions,
  questionIndex,
  submission,
  studentIndex,
}) => {
  const [questionId, setquestionId] = useState<any>("");
  const [questionType, setquestionType] = useState<any>("");
  const { t } = useTranslation();
  const { backgroundColor } = useRecoilValue(ColorSelector);
  useEffect(() => {
    if (questions && questions.length > 0) {
      setquestionId(
        questions[questionIndex].questionId
          ? questions[questionIndex].questionId.toString()
          : questions[questionIndex].id.toString()
      );
      setquestionType(questions[questionIndex].type);
    }
  }, [questions, questionIndex]);

  // Filter the lastAnswersOfStudent array based on questionId and type
  const filteredAnswers =
    submission && submission.length > 0
      ? submission[studentIndex].lastAnswersOfStudent.filter(
          (val: any) =>
            val.questionId.toString() === questionId.toString() &&
            val.type === questionType
        )
      : [];
  return (
    <AnswerCorrectionLayout>
      {filteredAnswers.length === 0 && questionType !== "ESSAY" && (
        <Typography text={t("assignment.No Answer")} />
      )}
      {submission &&
        submission.length > 0 &&
        filteredAnswers.map((val: any) => (
          <AnswerCorrectionContainer bgcolor={backgroundColor}>
            <div style={{ marginLeft: "1.2rem", paddingTop: "0.5rem" }}>
              <Typography
                text={`${t("quiz.Attempt")} ${
                  submission[studentIndex].numberOfAttemptsSubmit
                }:`}
                // color="#fff"
              />
            </div>

            {val.answers &&
              val.answers.map((answer: any, index: any) => (
                <AnswerCorrectionMappedContainer key={index}>
                  <AnswerCorrectionObject>
                    {val.type === "MATCH" && (
                      <MatchesAfterSubmission
                        index={index}
                        answer={answer.answer}
                      />
                    )}
                    {val.type === "TF" && answer.answer && (
                      <RadioAfterSubmission
                        key={index}
                        answer={answer.answer}
                        IsCorrect={answer.isCorrect}
                      />
                    )}
                    {val.type === "ESSAY" &&
                    answer.answer &&
                    answer.answer.file ? (
                      DisplayContentDisplay(
                        answer.file,
                        answer.answer.file.replace(/^(quiz\/submissions\/)/, "")
                      )
                    ) : val.type === "ESSAY" &&
                      answer.answer &&
                      !answer.answer.file ? (
                      <LightTypography text={t("grader.No File")} />
                    ) : (
                      ""
                    )}
                    {val.type === "MCQ" && answer.answer && (
                      <MCQAfterSubmission
                        answer={answer.answer?.choice}
                        IsCorrect={answer.isCorrect}
                      />
                    )}
                    <div style={{ margin: "0.625rem 0" }}></div>
                    {(val.type === "SHORT_ANSWER" || val.type === "NUMERIC") &&
                      answer.answer && (
                        <TextEditorAnswerReader
                          key={index}
                          answer={answer.answer}
                        />
                      )}
                    {val.type === "MRQ" && (
                      <CheckBoxAfterCorrection
                        answer={answer.answer?.choice}
                        IsCorrect={answer.isCorrect}
                      />
                    )}
                  </AnswerCorrectionObject>
                  {val.type !== "ESSAY" && (
                    <AnswerCorrectionAction>
                      <AnswerCorrectionLine />
                      {handleCorrectQuestionSvg(answer.isCorrect)}
                    </AnswerCorrectionAction>
                  )}
                </AnswerCorrectionMappedContainer>
              ))}
          </AnswerCorrectionContainer>
        ))}
    </AnswerCorrectionLayout>
  );
};

export default AnswerCorrection;
