/**
 * Filters an array of student submissions based on search text and submission status within a specific range of indices.
 * @param {any[]} studentData - The array of student submissions to be filtered.
 * @param {string} searchText - The text to be searched within the student submission properties.
 * @param {string} SubmittedSearchText - The submission status search text.
 * @param {number} startIndex - The start index for slicing the filtered data.
 * @param {number} endIndex - The end index for slicing the filtered data.
 * @returns {any[]} - The filtered array of student submissions.
 */
export const filterSubmissionDataGet = (
  studentData: any[],
  searchText: string,
  SubmittedSearchText: string,
  startIndex: number,
  endIndex: number
): any[] => {
  return studentData?.filter((item) => {
    const matchesSearchText = Object.values(item)
      .join(" ")
      .toLowerCase()
      .includes(searchText.toLowerCase());

    let matchesSubmittedSearchText = true;

    if (SubmittedSearchText === "Submitted") {
      matchesSubmittedSearchText = item.SubmissionDate !== undefined;
    } else if (SubmittedSearchText === "Unattempted") {
      matchesSubmittedSearchText =
        item.SubmissionDate === undefined ||
        (Array.isArray(item.SubmissionDate) &&
          item.SubmissionDate.length === 0);
    } else if (SubmittedSearchText === "Submission after Due date") {
      matchesSubmittedSearchText =
        item.SubmissionDate !== undefined &&
        item.DueDate !== undefined &&
        item.SubmissionDate > item.DueDate;
    }

    return matchesSubmittedSearchText && matchesSearchText;
  }).slice(startIndex, endIndex);
};
